import { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { Heading } from '../../../../components/Heading';
import { useTheme } from '../../../../contexts/ThemeContext';
import styles from './styles.module.css';

function AboutSection({ section }) {
  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  const [biography, setBiography] = useState([]);

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      setBiography(section);
    }
  }, [themeMarketplace, isLoadingThemeMarketplace, section, biography]);

  if (!biography.active) return '';

  return (
    <>
      {biography.active && (
        <Box as="section" padding={{ base: '24px 15px', md: '40px 107px', lg: '40px 107px' }}>
          {biography?.themeBiography.map(biography => (
            <div className={`${styles.aboutContainer}`} key={biography.id}>
              {biography.photo ? (
                <div className="col-lg-4 col-md-4 px-0 mx-0">
                  <img style={{ width: '100%' }} src={biography.photo} alt="Sobre" />
                </div>
              ) : (
                <div
                  style={{
                    width: '700px',
                    height: '500px',
                    background: themeMarketplace.primaryColor,
                    borderRadius: '4px',
                  }}
                ></div>
              )}
              <div className={`${styles.aboutContent} col-sm col-md col-lg-8`}>
                <Heading
                  fontSize={{ base: '24px', md: '32px', lg: '32px' }}
                  fontWeight={{ base: 500, md: 600, lg: 600 }}
                >
                  {section?.nameSection}
                </Heading>

                <p>{biography.description}</p>
              </div>
            </div>
          ))}
        </Box>
      )}
    </>
  );
}

export default AboutSection;
