import { axiosBackend } from './Configuration';

const endPoint = '/coupons';

const CouponAPI = {
  store: async coupon => {
    try {
      const { data: couponData } = await axiosBackend().post(endPoint, coupon);

      if (couponData) {
        return couponData.data;
      }
    } catch (error) {
      throw error;
    }
  },

  index: async ({ page, per_page, search = '' }) => {
    try {
      const { data: couponData } = await axiosBackend().get(
        `/coupons?page=${page}${per_page && `&per_page=${per_page}`}${
          search && `&search=${search}`
        }`
      );

      return couponData;
    } catch (error) {
      throw error;
    }
  },

  courseList: async () => {
    try {
      const { data: coursesList } = await axiosBackend().get('/coupons/course-list');

      return coursesList;
    } catch (error) {
      throw error;
    }
  },

  show: async coupon_id => {
    try {
      const { data: couponData } = await axiosBackend().get(`${endPoint}/${coupon_id}`);

      return couponData;
    } catch (error) {
      throw error;
    }
  },

  showByName: async (courseId, couponName) => {
    try {
      const { data: couponData } = await axiosBackend().get(`/coupons/${courseId}/course`, {
        params: {
          name: couponName,
        },
      });

      return couponData;
    } catch (error) {
      throw error;
    }
  },

  update: async (coupon_id, coupon) => {
    try {
      const couponData = await axiosBackend().patch(`${endPoint}/${coupon_id}`, coupon);

      if (couponData) {
        return couponData.data;
      }
    } catch (error) {
      throw error;
    }
  },
};

export default CouponAPI;
