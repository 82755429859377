import {
  Box,
  Button,
  Heading,
  HStack,
  Select,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import Signatures from '../../../../api/Signatures';
import Toast from '../../../../components/Toast';
import convertToBrazilianCurrency from '../../../../helpers/convertToBrazilianCurrency';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import useFetch from '../../../../hooks/useFetch';
import ConfirmCancelModal from '../components/ConfirmCancelModal';
import StatusPayment from '../components/StatusPayment';
import getPaymentMethod from '../utils/getPaymentMethod';

type TransactionsStudentsDataResult = {
  id: number;
  planName: string;
  amount: number;
  boletoExpirationDate: string;
  boletoUrl: string;
  createdAt: string;
  paymentMethod: string;
  status: string;
  pagarmeIdentifier: number;
  paymentOrder: number;
};

type CourseListType = {
  courseName: string;
  pagarmeIdentifier: number;
};

export default function SubscriptionList({ toggleModal }) {
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');

  const {
    isOpen: isOpenCancellation,
    onOpen: onOpenCancellation,
    onClose: onCloseCancellation,
  } = useDisclosure();

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<CourseListType[]>>({
    method: 'get',
    url: `/course-subscription-list`,
    authenticated: true,
    autoFetch: true,
  });

  const subscriptionList = response?.data;

  const {
    data: transactionsStudentsData,
    loading: isLoadingTransactionsStudents,
    fetchData: fetchDataTransactionsStudents,
  } = useFetch<UnificadaFront.ResponseJSON<TransactionsStudentsDataResult[]>>({
    method: 'get',
    url: `/course-subscription/${subscriptionId}/transactions-student`,
    authenticated: true,
    autoFetch: false,
  });

  const transactionsStudents = transactionsStudentsData?.data;

  useEffect(() => {
    if (subscriptionId) {
      fetchDataTransactionsStudents();
    }
  }, [fetchDataTransactionsStudents, subscriptionId]);

  function handleChangeSelectedProduct(event) {
    const { value } = event.target;

    if (value) {
      setSubscriptionId(parseInt(value));
    } else {
      setSubscriptionId(null);
    }
  }

  async function cancelSubscription(subscriptionId: number) {
    try {
      if (subscriptionId) {
        const cancelSubscription = await Signatures.studentCancelSubscription({
          subscriptionId: subscriptionId,
        });

        if (cancelSubscription.data) {
          setSubscriptionStatus(cancelSubscription.data.subscriptionStatus);
        }

        Toast('Assinatura cancelada com sucesso!', 'success');
        onCloseCancellation();
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  let paymentOrder = 0;

  return (
    <Stack spacing="30px">
      <Heading
        py={10}
        textAlign="center"
        fontSize="md"
        fontWeight="400"
        color="#20212380"
        hidden={!!subscriptionList?.length}
      >
        Você não possui produtos por assinatura.
      </Heading>

      <HStack justifyContent="space-between" hidden={!subscriptionList?.length}>
        <HStack>
          <Text fontWeight={500}>Escolha o produto:</Text>
          <Select
            size="sm"
            borderRadius={5}
            width="250px"
            placeholder="Selecione"
            focusBorderColor="#D86826"
            value={subscriptionId}
            onChange={handleChangeSelectedProduct}
            isDisabled={loading}
          >
            {subscriptionList?.map(product => (
              <option key={nanoid()} value={product.pagarmeIdentifier}>
                {product.courseName}
              </option>
            ))}
          </Select>
        </HStack>

        <Button
          hidden={!subscriptionId}
          style={{ outline: 'none', boxShadow: 'none' }}
          size="sm"
          isDisabled={subscriptionStatus === 'canceled'}
          variant={subscriptionStatus === 'canceled' ? 'solid' : 'outline'}
          colorScheme="red"
          onClick={onOpenCancellation}
        >
          {subscriptionStatus === 'canceled' ? 'Assinatura cancelada' : 'Cancelar assinatura'}
        </Button>
      </HStack>

      <Text hidden={!transactionsStudents?.length || !subscriptionId}>Histórico de Pagamento</Text>

      <TableContainer
        hidden={!transactionsStudents?.length || !subscriptionId}
        border="1px solid rgba(0, 0, 0, 0.1)"
        borderRadius="5px"
      >
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Pagamento</Th>
              <Th>Nome do Plano</Th>
              <Th>Tipo</Th>
              <Th>Data Venc.</Th>
              <Th>Valor</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>

          {transactionsStudents?.map((productFiltered, index) => (
            <Tbody>
              <Tr key={index}>
                <Td>{transactionsStudents?.length - paymentOrder++}</Td>
                <Td>{productFiltered.planName}</Td>
                <Td>{getPaymentMethod(productFiltered.paymentMethod)}</Td>
                <Td>{dayjs(productFiltered.boletoExpirationDate).format('DD/MM/YYYY')}</Td>
                <Td>{convertToBrazilianCurrency(productFiltered.amount / 100)}</Td>
                <Td>
                  <StatusPayment
                    paymentMethod={productFiltered.paymentMethod}
                    paymentStatus={productFiltered.status}
                    link={productFiltered.boletoUrl}
                    onClick={() => toggleModal(null)}
                  />
                </Td>
              </Tr>
            </Tbody>
          ))}
        </Table>
      </TableContainer>

      {isLoadingTransactionsStudents && (
        <Box width="full" display="flex" justifyContent="center" alignItems="center" padding={10}>
          <Spinner size="xl" color="orange.500" />
        </Box>
      )}

      {!transactionsStudents?.length && !isLoadingTransactionsStudents && subscriptionId && (
        <Box width="full" display="flex" justifyContent="center" alignItems="center">
          <Heading fontSize="md">Você não possui assinaturas.</Heading>
        </Box>
      )}

      <ConfirmCancelModal
        cancelSubmit={cancelSubscription}
        isOpenCancellation={isOpenCancellation}
        onCloseCancellation={onCloseCancellation}
        cancelId={subscriptionId}
      />
    </Stack>
  );
}
