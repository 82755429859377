import {
  Button,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AffiliationDetails } from '..';

const tabListStyles = {
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': { display: 'none' },
  '-webkit-overflow-scrolling': 'touch',
  button: {
    _focus: { outline: 'none', boxShadow: 'none' },
    fontSize: 'lg',
    px: 2,
    fontWeight: 'medium',
    flexShrink: 0,
    py: 1,
  },
};

const location = window.location;

function getCheckoutLink(productId: number, identifier: string, CheckoutType: string) {
  if (CheckoutType === 'LAUNCH') {
    return `${location.origin}/launch-checkout/${productId}/personal-data?affiliation=${identifier}`;
  }
  return `${location.origin}/student-checkout/v3?productId=${productId}&affiliation=${identifier}`;
}

function getSalesPageLink(productId: number, identifier: string) {
  return `${location.origin}/product/${productId}?affiliation=${identifier}`;
}

type ProductTabsProps = Pick<
  AffiliationDetails,
  'courseId' | 'identifier' | 'informationForAffiliates' | 'checkoutType'
>;

export default function ProductTabs({
  courseId,
  identifier,
  informationForAffiliates,
  checkoutType,
}: ProductTabsProps) {
  const [tabIndex, setTabIndex] = useState(0);

  const {
    value: salesPageLink,
    hasCopied: hasSalesPageLinkCopied,
    onCopy: handleSalesPageLinkCopy,
  } = useClipboard(getSalesPageLink(courseId, identifier));

  const {
    value: checkoutPageLink,
    hasCopied: hasCheckoutPageLinkCopied,
    onCopy: handleCheckoutPageLinkCopy,
  } = useClipboard(getCheckoutLink(courseId, identifier, checkoutType));

  // Faz o scroll do tab selecionado ficar no centro da tela no mobile
  useEffect(() => {
    const activeTabButton = document.querySelector(`[aria-selected="true"]`);
    activeTabButton?.scrollIntoView({ block: 'center', inline: 'center' });
  }, [tabIndex]);

  function handleTabIndexChange(index: number) {
    setTabIndex(index);
  }

  return (
    <Tabs onChange={handleTabIndexChange} colorScheme="primary" marginTop="5" w="full">
      <TabList
        borderBottom="1px solid"
        borderColor="#00000080"
        overflowX={{ base: 'scroll', md: 'hidden' }}
        py="2px"
        sx={tabListStyles}
      >
        <Tab>Informações para os afiliados</Tab>
        <Tab>Detalhes do produto</Tab>
        <Tab>Links</Tab>
      </TabList>

      <TabPanels sx={{ '[role=tabpanel]': { px: 0, py: 6 } }}>
        <TabPanel dangerouslySetInnerHTML={{ __html: informationForAffiliates }} />
        <TabPanel>
          <Flex direction="column" p={4} boxShadow="1px 1px 4px 0px #00000040" borderRadius="2xl">
            <Text as="label" fontWeight="medium" fontSize="md" color="#202123">
              Página de vendas
            </Text>

            <Flex
              direction={{ base: 'column', md: 'row' }}
              align="center"
              justify="space-between"
              gap={2}
              mt={3}
            >
              <Flex w="full" align="center" bg="#EDF2F7" px={3} py={1} borderRadius="md">
                <Text noOfLines={1} color="#20212340">
                  {`${location.origin}/product/${courseId}`}
                </Text>
              </Flex>

              <Stack direction={{ base: 'column', md: 'row' }} w={{ base: 'full', md: 'unset' }}>
                <Button
                  as={Link}
                  to={`/product/${courseId}`}
                  variant="outline"
                  colorScheme="primary"
                  size="sm"
                  target="_blank"
                >
                  Acessar link
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </TabPanel>

        <TabPanel py={6}>
          <Flex direction="column" gap={{ base: 8, md: 10 }}>
            <Flex direction="column" p={4} boxShadow="1px 1px 4px 0px #00000040" borderRadius="2xl">
              <Text as="label" fontWeight="medium" fontSize="md" color="#202123">
                Link da Página de Vendas
              </Text>

              <Flex
                direction={{ base: 'column', md: 'row' }}
                align="center"
                justify="space-between"
                gap={2}
                mt={3}
              >
                <Flex w="full" align="center" bg="#EDF2F7" px={3} py={1} borderRadius="md">
                  <Text noOfLines={1} color="#202123">
                    {salesPageLink}
                  </Text>
                </Flex>

                <Stack direction={{ base: 'column', md: 'row' }} w={{ base: 'full', md: 'unset' }}>
                  <Button
                    as={Link}
                    to={`/product/${courseId}?affiliation=${identifier}`}
                    variant="outline"
                    colorScheme="primary"
                    size="sm"
                    target="_blank"
                  >
                    Acessar link
                  </Button>

                  <Button
                    value={salesPageLink}
                    color="secondary.500"
                    colorScheme="primary"
                    onClick={handleSalesPageLinkCopy}
                    size="sm"
                  >
                    {hasSalesPageLinkCopied ? 'Copiado!' : 'Copiar link'}
                  </Button>
                </Stack>
              </Flex>
            </Flex>
          </Flex>

          <Flex direction="column" gap={{ base: 8, md: 10 }} mt={{ base: 2, md: 6 }}>
            <Flex direction="column" p={4} boxShadow="1px 1px 4px 0px #00000040" borderRadius="2xl">
              <Text as="label" fontWeight="medium" fontSize="md" color="#202123">
                Link do Checkout
              </Text>

              <Flex
                direction={{ base: 'column', md: 'row' }}
                align="center"
                justify="space-between"
                gap={2}
                mt={3}
              >
                <Flex w="full" align="center" bg="#EDF2F7" px={3} py={1} borderRadius="md">
                  <Text noOfLines={1} color="#202123">
                    {checkoutPageLink}
                  </Text>
                </Flex>

                <Stack direction={{ base: 'column', md: 'row' }} w={{ base: 'full', md: 'unset' }}>
                  <Button
                    value={checkoutPageLink}
                    color="secondary.500"
                    colorScheme="primary"
                    onClick={handleCheckoutPageLinkCopy}
                    size="sm"
                  >
                    {hasCheckoutPageLinkCopied ? 'Copiado!' : 'Copiar link'}
                  </Button>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
