import {
  Accordion,
  Box,
  Center,
  Circle,
  Flex,
  Heading,
  Image,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Container from '../../components/ContainerSite/ContainerSite';
import FullPageLoading from '../../components/FullPageLoading';
import useFetch from '../../hooks/useFetch';
import FrequentlyAskedQuestions from './Components/FrequentlyAskedQuestions';
import PlanCard from './Components/PlanCard';
import ProductCard from './Components/ProductCard';
import TestimonialCard from './Components/TestimonialCard';
import { MdChevronLeft as LeftIcon, MdChevronRight as RightIcon } from 'react-icons/md';

export interface IRecurrencePlanTestimony {
  id: number;
  name: string;
  thumbnail: string;
  testimony: string;
}

export interface IRecurrencePlanFrequentlyQuestions {
  id: number;
  question: string;
  answer: string;
}

export interface IRecurrencePlanCourse {
  id: number;
  name: string;
  thumbnail: string;
}

export interface IRecurrencePlan {
  id: number;
  name: string;
  value: string;
  recurrenceDay: number;
  description: string;
  mainBanner: string;
  willUsePlanPage: boolean;
  linkToYourPlan: string;
  pageType: string;
  recurrencePlanCourse: IRecurrencePlanCourse[];
  recurrencePlanTestimonies: IRecurrencePlanTestimony[];
  recurrencePlanFrequentlyQuestions: IRecurrencePlanFrequentlyQuestions[];
}

export default function RecurrencePlan() {
  const { recurrencePlanId } = useParams<{ recurrencePlanId: string }>();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const {
    data: response,
    loading,
    fetchData: fetchRecurrencePlan,
  } = useFetch<UnificadaFront.ResponseJSON<IRecurrencePlan>>({
    url: `/marketplace/recurrence-plan/${recurrencePlanId}`,
    method: 'get',
    autoFetch: false,
  });

  useEffect(() => {
    if (recurrencePlanId) {
      fetchRecurrencePlan();
    }
  }, [recurrencePlanId, fetchRecurrencePlan]);

  const recurrencePlan = response?.data;
  const courses = recurrencePlan?.recurrencePlanCourse;
  const testimonies = recurrencePlan?.recurrencePlanTestimonies;
  const frequentlyQuestions = recurrencePlan?.recurrencePlanFrequentlyQuestions;

  const PrevArrow = props => {
    const { onClick, currentSlide } = props;

    if (currentSlide === 0) return null;

    return (
      <Center
        hidden={isMobile}
        height="80%"
        position="absolute"
        top="50%"
        left="-40px"
        zIndex="1"
        transform="translateY(-50%)"
        onClick={onClick}
        cursor="pointer"
      >
        <Circle p="3px" transition="background-color 0.3s">
          <LeftIcon
            size={42}
            color="#20212350"
            style={{ transition: 'transform 0.3s ease-in-out' }}
          />
        </Circle>
      </Center>
    );
  };

  const NextArrow = props => {
    const { onClick } = props;

    return (
      <Center
        hidden={isMobile}
        height="80%"
        position="absolute"
        top="50%"
        right="-40px"
        zIndex="1"
        transform="translateY(-50%)"
        onClick={onClick}
        cursor="pointer"
      >
        <Circle p="3px">
          <RightIcon
            size={42}
            color="#20212350"
            style={{ transition: 'transform 0.3s ease-in-out' }}
          />
        </Circle>
      </Center>
    );
  };

  const sliderSettings: Settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: isMobile ? 2.1 : 4.2,
    slidesToScroll: 4,
    autoplay: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Container>
      <VStack as="section" align="start" mx={{ base: '15px', md: '108px' }} spacing="20px">
        <Skeleton
          isLoaded={!loading}
          textAlign="center"
          width="full"
          mt={{ base: '100px', md: '180px' }}
        >
          <Heading fontSize={{ base: '20px', md: '48px' }}>{recurrencePlan?.name}</Heading>
        </Skeleton>

        <Skeleton width="full" isLoaded={!loading}>
          <Image
            width="full"
            h={{ base: '151px', md: '503px' }}
            objectFit="cover"
            src={recurrencePlan?.mainBanner}
            alt="banner"
            borderRadius="20px"
          />
        </Skeleton>

        <SkeletonText noOfLines={4} skeletonHeight="5" isLoaded={!loading}>
          <Text dangerouslySetInnerHTML={{ __html: recurrencePlan?.description }} />
        </SkeletonText>
      </VStack>

      <Box
        as="section"
        my={{ base: '60px', md: '100px' }}
        display="flex"
        flexDirection="column"
        gap={{ base: '60px', md: '108px' }}
      >
        <VStack spacing="20px" hidden={!testimonies?.length}>
          <Skeleton isLoaded={!loading}>
            <Text
              textAlign="center"
              fontSize={{ base: '20px', md: '30px' }}
              fontWeight="600"
              lineHeight="normal"
            >
              Depoimentos
            </Text>
          </Skeleton>

          <Flex gap="20px" justify="center" flexWrap="wrap">
            {testimonies?.map(testimony => (
              <Skeleton isLoaded={!loading}>
                <TestimonialCard key={testimony.id} {...testimony} />
              </Skeleton>
            ))}
          </Flex>
        </VStack>

        <Box
          as="section"
          display="flex"
          flexDirection="column"
          gap="20px"
          hidden={!courses?.length}
        >
          <Skeleton isLoaded={!loading}>
            <Text
              textAlign="center"
              fontSize={{ base: '20px', md: '30px' }}
              fontWeight="600"
              lineHeight="normal"
            >
              Produtos da assinatura
            </Text>
          </Skeleton>

          {courses && courses.length > 8 ? (
            <Box
              as="section"
              mx={{ base: '15px', md: '108px' }}
              sx={{
                '& .slick-prev:before, .slick-next:before': { color: 'gray.500', fontSize: '30px' },
              }}
            >
              <Slider {...sliderSettings}>
                {courses.map(course => (
                  <Skeleton isLoaded={!loading}>
                    <ProductCard {...course} />
                  </Skeleton>
                ))}
              </Slider>
            </Box>
          ) : (
            <Flex gap={{ base: '13px', md: '42px' }} justify="center" flexWrap="wrap">
              {courses?.map(course => (
                <Skeleton key={course.id} isLoaded={!loading}>
                  <Box key={course.id} pr={{ base: 3.5, lg: 5 }}>
                    <ProductCard {...course} />
                  </Box>
                </Skeleton>
              ))}
            </Flex>
          )}
        </Box>

        <VStack
          as="section"
          bgColor="primary.400"
          width="full"
          py="40px"
          px={{ base: '15px', md: '108px' }}
          spacing="20px"
        >
          <Skeleton isLoaded={!loading}>
            <Heading
              textAlign-="center"
              color="secondary.500"
              fontSize={{ base: '24px', md: '48px' }}
              fontWeight="600"
              lineHeight="normal"
              letterSpacing="1px"
            >
              Plano de assinatura
            </Heading>
          </Skeleton>

          <Skeleton isLoaded={!loading}>
            <PlanCard {...recurrencePlan} />
          </Skeleton>
        </VStack>

        <VStack as="section" spacing="20px" width="full" hidden={!frequentlyQuestions?.length}>
          <Skeleton isLoaded={!loading}>
            <Text
              textAlign="center"
              fontSize={{ base: '20px', md: '30px' }}
              fontWeight="600"
              lineHeight="normal"
            >
              Perguntas frequentes
            </Text>
          </Skeleton>

          <Accordion
            allowMultiple
            width="full"
            px={{ base: '15px', md: '108px' }}
            as={Stack}
            gap="10px"
          >
            {frequentlyQuestions?.map(frequentlyQuestion => (
              <Skeleton isLoaded={!loading}>
                <FrequentlyAskedQuestions key={frequentlyQuestion.id} {...frequentlyQuestion} />
              </Skeleton>
            ))}
          </Accordion>
        </VStack>
      </Box>

      <FullPageLoading isLoading={loading} />
    </Container>
  );
}
