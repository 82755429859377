import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import Joi from 'joi';
import { nanoid } from 'nanoid';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { Link, useHistory, useParams } from 'react-router-dom';
import EditorRichText from '../../../../components/EditorRichText';
import useFetch from '../../../../hooks/useFetch';
import useHandleChange, { TargetType } from '../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';

type AffiliationSchemaType = {
  courseId: number;
  informationForAffiliates: string;
  automaticAffiliation: boolean;
  commissionValue: number;
};

const AffiliationSchema = Joi.object<AffiliationSchemaType>().keys({
  courseId: Joi.number().positive().required().messages({ 'number.base': 'Informe o produto.' }),
  informationForAffiliates: Joi.string()
    .required()
    .messages({ 'string.empty': 'Adicione informações para afiliados.' }),
  automaticAffiliation: Joi.boolean().default(false),
  commissionValue: Joi.number()
    .positive()
    .required()
    .messages({ 'number.base': 'Informe a comissão do afiliado.' }),
});

export default function Affiliation() {
  const history = useHistory();

  const { id: courseAffiliationId } = useParams<{ id: string }>();

  const {
    data,
    loading: courseAffiliationLoading,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<AffiliationSchemaType>>({
    method: 'get',
    url: `/affiliations/products/${courseAffiliationId}/course-affiliation`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (courseAffiliationId) {
      fetchData().finally(() => {
        setOnChanged(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseAffiliationId, fetchData]);

  const { form, handleChange, onChanged, handleCancel, setOnChanged } =
    useHandleChange<AffiliationSchemaType>(
      {
        courseId: null,
        informationForAffiliates: '',
        automaticAffiliation: false,
        commissionValue: null,
      },
      data?.data && {
        automaticAffiliation: data?.data?.automaticAffiliation,
        courseId: data?.data?.courseId,
        commissionValue: data?.data?.commissionValue,
        informationForAffiliates: data?.data?.informationForAffiliates,
      }
    );

  const { data: courseList, loading: courseListLoading } = useFetch<
    UnificadaFront.ResponseJSON<{ id: number; name: string }[]>
  >({
    method: 'get',
    url: '/affiliations/list-of-products-for-affiliation',
    authenticated: true,
    autoFetch: true,
  });

  const {
    data: revenueSplitPercentageAvailableByCourseResponse,
    loading: revenueSplitPercentageAvailableByCourseLoading,
    fetchData: revenueSplitPercentageAvailableByCourse,
  } = useFetch<
    UnificadaFront.ResponseJSON<{ availablePercentage: string; prolunoPercentage: string }>
  >({
    method: 'get',
    url: `/revenue-split/percentage-available-by-course/${form.courseId}/course?revenueType=DEFAULT`,
    authenticated: true,
  });

  useEffect(() => {
    if (!form.courseId) return;

    revenueSplitPercentageAvailableByCourse();
  }, [form.courseId, revenueSplitPercentageAvailableByCourse]);

  const { isLoading, handleSubmit, formValidation } = useHandleSubmit({
    isLoading: courseAffiliationLoading,
    data: form,
    schemaValidator: AffiliationSchema,
    method: courseAffiliationId ? 'patch' : 'post',
    url: '/affiliations/products',
    onSuccess: {
      message: `Produto ${courseAffiliationId ? 'atualizado' : 'cadastrado'} com sucesso`,
    },
    authenticated: true,
  });

  const [maxCommission, setMaxCommission] = useState(true);

  useEffect(() => {
    if (!revenueSplitPercentageAvailableByCourseResponse?.data) return;

    const maxCommission =
      form?.commissionValue?.toString() ===
      revenueSplitPercentageAvailableByCourseResponse?.data?.availablePercentage;

    setMaxCommission(maxCommission);
  }, [form.commissionValue, revenueSplitPercentageAvailableByCourseResponse?.data]);

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    const result = await handleSubmit();

    if (result) {
      history.push('/affiliations/products');
    }
  }

  function availablePercentage() {
    const availablePercentage =
      revenueSplitPercentageAvailableByCourseResponse?.data?.availablePercentage;

    if (form.commissionValue && availablePercentage) {
      return parseInt(availablePercentage) - parseInt(form.commissionValue?.toString());
    }

    return availablePercentage && form.courseId ? availablePercentage : '0';
  }

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Link to="/affiliations/products">
          <IoMdArrowBack size="20px" />
        </Link>
        <Heading fontSize="24px" fontWeight="semibold">
          {courseAffiliationId ? 'Atualizar' : 'Cadastrar'} produto para afiliação
        </Heading>
      </Stack>
      <Box marginTop="4">
        <Box as="form" onSubmit={onSubmit}>
          <VStack spacing={4} alignItems="flex-end">
            <FormControl isInvalid={formValidation?.courseId.isInvalid}>
              <FormLabel>Selecione um produto:</FormLabel>
              <Select
                isDisabled={!!courseAffiliationId}
                name="courseId"
                colorScheme="primary"
                value={form.courseId || ''}
                onChange={event =>
                  handleChange(event, { name: 'courseId', value: parseInt(event.target.value) })
                }
              >
                <option value="" hidden>
                  {courseListLoading ? 'Carregando...' : 'Nenhum produto selecionado'}
                </option>
                {courseList?.data.map(course => (
                  <option key={nanoid()} value={course.id}>
                    {course.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{formValidation?.courseId.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.commissionValue.isInvalid}>
              <FormLabel>Comissão:</FormLabel>
              <InputGroup>
                <Input
                  type="number"
                  value={form.commissionValue || ''}
                  name="commissionValue"
                  placeholder="Exemplo: 25"
                  onChange={handleChange}
                  max={
                    revenueSplitPercentageAvailableByCourseResponse?.data?.availablePercentage || 0
                  }
                />
                <InputRightAddon children="%" />
              </InputGroup>
              <FormHelperText>
                Porcentagem disponível:{' '}
                <Box as="span" fontWeight="bold">
                  {!revenueSplitPercentageAvailableByCourseResponse?.data ? (
                    revenueSplitPercentageAvailableByCourseLoading ? (
                      'Carregando...'
                    ) : (
                      '-'
                    )
                  ) : (
                    <Text as="span">
                      {availablePercentage()}%
                      {maxCommission &&
                        ' - Você não receberá nenhum valor quando um afiliado desse produto realizar uma venda.'}
                    </Text>
                  )}
                </Box>
              </FormHelperText>
              <FormErrorMessage>{formValidation?.commissionValue.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.informationForAffiliates.isInvalid}>
              <FormLabel>Informações para afiliados:</FormLabel>
              <EditorRichText
                value={form.informationForAffiliates}
                onChange={(value: string) => {
                  const event = {
                    target: {
                      name: 'informationForAffiliates',
                      value,
                    },
                  };

                  handleChange(event as unknown as ChangeEvent<TargetType>);
                }}
              />
              <FormErrorMessage>
                {formValidation?.informationForAffiliates.message}
              </FormErrorMessage>
            </FormControl>

            <HStack spacing={2}>
              <Button
                size="sm"
                hidden={!onChanged}
                bgColor="secondary.500"
                color="primary.500"
                borderColor="primary"
                _hover={{ bgColor: 'secondary.200' }}
                border="0.5px solid"
                isLoading={isLoading}
                onClick={handleCancel}
              >
                Cancelar
              </Button>

              <Button
                size="sm"
                bgColor="primary.500"
                color="secondary.500"
                _hover={{ bgColor: 'primary.200' }}
                type="submit"
                isDisabled={!onChanged}
                isLoading={isLoading}
              >
                {courseAffiliationId ? 'Atualizar produto' : 'Cadastrar produto'}
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Box>
    </>
  );
}
