import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { FormEvent, useState } from 'react';
import { axiosBackend } from '../../../../api/Configuration';
import Platform from '../../../../api/Platform';
import ColorPicker from '../../../../components/ColorPickChakra';
import Toast from '../../../../components/Toast';
import { useTheme } from '../../../../contexts/ThemeContext';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { ColorPickerChange } from '../../../DashboardAdmin/Courses/CourseManager/Configuration/Certificate/types';
import BackButton from '../Buttons/BackButton';
import NextButton from '../Buttons/NextButton';

const inicialColors = {
  primaryColor: '',
  textColor: '',
};

export default function TabColors({ onGoToNextTab, onGoToPreviousTab }) {
  const { updateColors, themeMarketplace } = useTheme();

  const [colors, setColors] = useState(inicialColors);
  const [loading, setLoading] = useState(false);

  function handleChangeColor(props: ColorPickerChange) {
    const { name, value } = props;

    setColors({ ...colors, [name]: value });
  }

  async function getTheme() {
    const { data } = await axiosBackend().get('/themes');
    const theme = data.data;

    return theme;
  }

  async function onSubmitColors(event: FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault();

    try {
      setLoading(true);

      if (colors.primaryColor || colors.textColor) {
        const payload = {
          primaryColor: colors.primaryColor || themeMarketplace.primaryColor,
          textColor: colors.textColor || themeMarketplace.textColor,
        };

        await Platform.integrationColors(payload);

        const themeData = await getTheme();
        updateColors(themeData.id, themeData.primaryColor, themeData.textColor);

        Toast('Cores atualizadas!');
      }

      setLoading(false);

      onGoToNextTab();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Flex grow={1} direction="column" justify="space-between" as="form" onSubmit={onSubmitColors}>
      <Box>
        <Heading size="lg" fontWeight={{ base: 'semibold', md: 'bold' }}>
          Cores
        </Heading>

        <Text mt={2} fontSize={{ base: 'sm', md: 'md' }} color="#20212350" fontWeight="medium">
          Defina as cores que sua plataforma de ensino terá.
        </Text>

        <Flex direction={{ base: 'column', md: 'row' }} mt={5} gap={4}>
          <Flex direction="column" justify="space-between" gap={1}>
            <Box>
              <Heading fontWeight="500" size="md">
                Cor primária
              </Heading>

              <Text color="#20212380" fontWeight="400" fontSize="sm" mt={1}>
                Está será a cor principais do seu menu superior.
              </Text>
            </Box>

            <ColorPicker
              name="primaryColor"
              value={colors.primaryColor || themeMarketplace.primaryColor}
              onChange={handleChangeColor}
              hasInput
            />
          </Flex>

          <Flex direction="column" justify="space-between" gap={1}>
            <Box>
              <Heading fontWeight="500" size="md">
                Cor secundária
              </Heading>

              <Text color="#20212380" fontWeight="400" fontSize="sm" mt={1}>
                Está será a cor dos textos que estarão presentes sobre a cor principal.
              </Text>
            </Box>

            <ColorPicker
              name="textColor"
              value={colors.textColor || themeMarketplace.textColor}
              onChange={handleChangeColor}
              hasInput
            />
          </Flex>
        </Flex>
      </Box>

      <Flex as="footer" justify={{ base: 'space-between', md: 'flex-end' }} gap={4}>
        <BackButton onClick={onGoToPreviousTab} isLoading={loading} />
        <NextButton type="submit" isLoading={loading} />
      </Flex>
    </Flex>
  );
}
