import { Button, Modal as BootstrapModal } from 'react-bootstrap';

import { Box, Heading } from '@chakra-ui/react';
import { memo } from 'react';
import { MdClose as CloseButton } from 'react-icons/md';

interface IModalProps {
  show: boolean;
  data: {
    question: string;
    questionText: string;
    commentary: string;
  };
  onHide: () => void;
}

function Modal(props: IModalProps) {
  const { question, questionText, commentary } = props.data;

  return (
    <BootstrapModal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <BootstrapModal.Header>
        <BootstrapModal.Title id="contained-modal-title-vcenter">
          <Heading fontSize="lg">{question}</Heading>
        </BootstrapModal.Title>

        <Button
          onClick={props.onHide}
          variant="light"
          style={{
            backgroundColor: 'transparent',
            border: 0,
            fontSize: '1rem',
            marginRight: '-20px',
          }}
        >
          <CloseButton color="#20212380" />
        </Button>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div>
          <Heading as="h3" fontSize="md">
            Pergunta
          </Heading>

          <Box
            w="full"
            dangerouslySetInnerHTML={{ __html: questionText }}
            sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
            className="ql-editor"
          />
        </div>

        <div className="mt-4">
          <Heading as="h3" fontSize="md">
            Comentário
          </Heading>

          <Box
            w="full"
            dangerouslySetInnerHTML={{ __html: commentary }}
            sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
            className="ql-editor"
          />
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
}
export default memo(Modal);
