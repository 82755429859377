import { MdEdit } from 'react-icons/md';
import styles from './styles.module.css';

interface IProps {
  preview: string;
  mediaType: 'image' | 'video';
  onClick: () => void;
}

function MediaPreview({ preview, mediaType, onClick }: IProps) {
  return (
    <div className={styles.container}>
      {mediaType === 'image' && (
        <div style={{ backgroundImage: `url("${preview}")` }} className={styles.image} />
      )}

      {mediaType === 'video' && (
        <video
          className={styles.image}
          style={{ background: '#000', objectFit: 'cover' }}
          preload="metadata"
        >
          <source src={preview} />
        </video>
      )}

      <button onClick={onClick} className={`${styles.headerLogoButton}`}>
        <MdEdit />
        Alterar
      </button>
    </div>
  );
}

export default MediaPreview;
