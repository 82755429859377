import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import CopyButton from './CopyButton';
import useNewUtm from './useNewUtm';

export default function NewUtm() {
  const {
    form,
    isSubmitting,
    utmLink,
    hasChange,
    formValidation,
    onSubmit,
    handleChange,
    onCancel,
    handleKeyDown,
  } = useNewUtm();

  return (
    <>
      <HStack spacing={{ base: 0.25, xl: 0.5 }} ml={{ base: -2, xl: 0 }}>
        <IconButton
          as={Link}
          to={'/utm-generator'}
          icon={<ChevronLeftIcon size="1.75rem" />}
          aria-label="Voltar"
          variant="ghost"
          colorScheme="gray"
          size="sm"
        />

        <Heading as="h1" fontSize={{ base: 'lg', lg: '2xl' }} fontWeight="semibold" m={0}>
          Novo UTM
        </Heading>
      </HStack>

      <VStack
        as="form"
        onSubmit={onSubmit}
        mt={10}
        noValidate
        sx={{
          '.chakra-form__label': {
            fontSize: 'sm',
            color: '#202123',
            fontWeight: 'medium',
          },
          '.chakra-form__helper-text': {
            color: '#20212380',
          },
          '.chakra-input::placeholder': {
            color: '#0000005C',
          },
          '.chakra-input': {
            color: '#202123',
          },
        }}
        spacing={10}
      >
        <VStack spacing={8} w="100%">
          <FormControl isInvalid={formValidation?.link?.isInvalid}>
            <FormLabel>
              URL do Site
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>

            <FormHelperText>URL completo do Site (e.g. https://www.exemplo.com)</FormHelperText>

            <Input
              name="link"
              value={form.link}
              onChange={handleChange}
              placeholder="https://www.exemplo.com"
              mt={4}
              focusBorderColor="gray.300"
              onKeyDown={handleKeyDown}
            />

            <FormErrorMessage>{formValidation?.link?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.utmId?.isInvalid}>
            <FormLabel>ID da Campanha (utm_id)</FormLabel>

            <FormHelperText>ID da Campanha do ADS</FormHelperText>

            <Input
              name="utmId"
              value={form.utmId}
              onChange={handleChange}
              placeholder="Digite aqui"
              mt={4}
              focusBorderColor="gray.300"
              onKeyDown={handleKeyDown}
            />

            <FormErrorMessage>{formValidation?.utmId?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.utmSource?.isInvalid}>
            <FormLabel>
              Origem da Campanha (utm_source)
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>

            <FormHelperText>Ex: google, facebook</FormHelperText>

            <Input
              name="utmSource"
              value={form.utmSource}
              onChange={handleChange}
              placeholder="Digite aqui"
              mt={4}
              focusBorderColor="gray.300"
              onKeyDown={handleKeyDown}
            />

            <FormErrorMessage>{formValidation?.utmSource?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.utmMedium?.isInvalid}>
            <FormLabel>
              Mídia da Campanha (utm_medium)
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>

            <FormHelperText>Ex: banner, e-mail</FormHelperText>

            <Input
              name="utmMedium"
              value={form.utmMedium}
              onChange={handleChange}
              placeholder="Digite aqui"
              mt={4}
              focusBorderColor="gray.300"
              onKeyDown={handleKeyDown}
            />

            <FormErrorMessage>{formValidation?.utmMedium?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.utmCampaign?.isInvalid}>
            <FormLabel>Campanha (utm_campaign)</FormLabel>

            <FormHelperText>Ex: campanhadelancamento</FormHelperText>

            <Input
              name="utmCampaign"
              value={form.utmCampaign}
              onChange={handleChange}
              placeholder="Digite aqui"
              mt={4}
              focusBorderColor="gray.300"
              onKeyDown={handleKeyDown}
            />

            <FormErrorMessage>{formValidation?.utmCampaign?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.utmTerm?.isInvalid}>
            <FormLabel>Termo da Campanha (utm_term)</FormLabel>

            <FormHelperText>Identifique as palavras-chave pagas</FormHelperText>

            <Input
              name="utmTerm"
              value={form.utmTerm}
              onChange={handleChange}
              placeholder="Digite aqui"
              mt={4}
              focusBorderColor="gray.300"
              onKeyDown={handleKeyDown}
            />

            <FormErrorMessage>{formValidation?.utmTerm?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.utmContent?.isInvalid}>
            <FormLabel>Conteúdo da Campanha (utm_content)</FormLabel>

            <FormHelperText>Ex: bannerpromocao</FormHelperText>

            <Input
              name="utmContent"
              value={form.utmContent}
              onChange={handleChange}
              placeholder="Digite aqui"
              mt={4}
              focusBorderColor="gray.300"
              onKeyDown={handleKeyDown}
            />
          </FormControl>

          <FormErrorMessage>{formValidation?.utmContent?.message}</FormErrorMessage>
        </VStack>

        <VStack align="start" w="100%">
          <Heading as="h2" fontSize="xl" fontWeight="medium" color="#202123">
            Visualização do Link
          </Heading>

          <InputGroup bg="#e9e9e9" borderRadius="base">
            <Input
              value={utmLink}
              bg="#e9e9e9"
              color="#959595 !important"
              borderRadius="base"
              focusBorderColor="transparent"
              isDisabled={!form.link}
              isTruncated
            />

            <InputRightAddon
              bg="#2021231A"
              children={<CopyButton textToCopy={utmLink} isDisabled={!form.link} />}
              p={0}
            />
          </InputGroup>
        </VStack>

        {/* RODAPÉ */}
        <HStack w="full" as="footer" justify="end" spacing={5} mb={6}>
          <Button
            onClick={onCancel}
            bgColor="#e5e5e5"
            border="1px solid #20212340"
            _hover={{ bgColor: '#c2c2c2' }}
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isDisabled={isSubmitting}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            colorScheme="primary"
            color="secondary.500"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isLoading={isSubmitting}
            isDisabled={!hasChange}
          >
            Salvar
          </Button>
        </HStack>
      </VStack>
    </>
  );
}
