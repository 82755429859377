import { Button, HStack, Select, Show, Stack, StackDivider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import { FiltersType, Plan } from '..';
import DateRangePicker from '../../../../../../components/DateRangePicker';
import DateRangePickerMobile from '../../../../../../components/DateRangePickerMobile';
import FilterButtons from './FilterButtons';

interface FiltersProps {
  plans: Plan[];
  onFilterChange: (newFilters: Partial<FiltersType>) => void;
  onFilter: () => void;
}

export default function Filters({ plans, onFilterChange, onFilter }: FiltersProps) {
  const [isCustomDateRange, setIsCustomDateRange] = useState(false);
  function handleDateRangeChange(filter: string) {
    setIsCustomDateRange(false);

    const today = dayjs();

    switch (filter) {
      case 'lastSevenDays':
        return onFilterChange({
          startDate: today.subtract(7, 'day').format('YYYY-MM-DD'),
          endDate: today.format('YYYY-MM-DD'),
        });

      case 'lastFifteenDays':
        return onFilterChange({
          startDate: today.subtract(15, 'day').format('YYYY-MM-DD'),
          endDate: today.format('YYYY-MM-DD'),
        });

      case 'lastMonth':
        return onFilterChange({
          startDate: today.subtract(1, 'month').format('YYYY-MM-DD'),
          endDate: today.format('YYYY-MM-DD'),
        });

      default:
        onFilterChange({
          startDate: today.format('YYYY-MM-DD'),
          endDate: today.format('YYYY-MM-DD'),
        });
    }
  }

  function handleDateRangePickerChange(startDate: string, endDate: string) {
    setIsCustomDateRange(true);

    onFilterChange({
      startDate,
      endDate,
    });
  }

  function handlePlanChange(value: string) {
    onFilterChange({
      planId: value,
    });
  }

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      align="center"
      sx={{ '.chakra-menu__menu-button svg': { color: '#202123' } }}
      divider={
        <StackDivider
          borderColor="#202123"
          height={6}
          alignSelf="center"
          display={{ base: 'none', md: 'block' }}
        />
      }
    >
      <FilterButtons
        onChange={handleDateRangeChange}
        initialValue="today"
        isCustomDateRange={isCustomDateRange}
      />

      <Show above="md">
        <DateRangePicker onChange={handleDateRangePickerChange} hideSelect />
      </Show>

      <HStack
        mt={{ base: 3, md: 0 }}
        spacing={{ base: 0, md: 4 }}
        divider={
          <StackDivider
            borderColor="#202123"
            height={6}
            display={{ base: 'block', md: 'none' }}
            alignSelf="center"
          />
        }
        align="center"
      >
        <Show below="md">
          <DateRangePickerMobile onChange={handleDateRangePickerChange} />
        </Show>

        <HStack>
          <Select
            onChange={({ target: { value } }) => handlePlanChange(value)}
            focusBorderColor="gray.300"
            size="sm"
            ml={{ base: 3, md: 0 }}
          >
            <option value="" hidden>
              Selecione um plano
            </option>

            {plans?.map(plan => (
              <option key={plan.id} value={plan.id}>
                {plan.name}
              </option>
            ))}
          </Select>

          <Button onClick={onFilter} colorScheme="primary" color="secondary.500" size="xs" px={4}>
            Filtrar
          </Button>
        </HStack>
      </HStack>
    </Stack>
  );
}
