import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioProps,
  Text,
  useRadio,
  useRadioGroup,
  VStack,
} from '@chakra-ui/react';
import delay from '../../../../../../utils/delay';

const OPTIONS = [
  { label: 'Produtos', value: 'products' },
  { label: 'Planos', value: 'plans' },
];

interface SelectShowcaseTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateProductShowcaseModalOpen: () => void;
  onSelectPlanShowcaseTypeModalOpen: () => void;
}

function ShowcaseTypeRadioCard(props: RadioProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label" w="100%">
      <input {...input} />
      <Box
        {...radio}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        borderColor="gray.200"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        _checked={{
          borderColor: 'primary.500',
          bg: 'transparent',
        }}
        p={2.5}
        w="100%"
      >
        <Flex alignItems="flex-start" pointerEvents="none">
          <Radio isChecked={props.isChecked} colorScheme="primary" mr="0.375rem" mt={0.5} />
          {props.children}
        </Flex>
      </Box>
    </Box>
  );
}

export function SelectShowcaseTypeModal({
  isOpen,
  onClose,
  onCreateProductShowcaseModalOpen,
  onSelectPlanShowcaseTypeModalOpen,
}: SelectShowcaseTypeModalProps) {
  const { getRootProps, getRadioProps, value } = useRadioGroup({
    name: 'showcaseType',
    defaultValue: OPTIONS[0].value,
  });

  const group = getRootProps();

  async function handleClose(value: string) {
    onClose();

    await delay(500);

    if (value === 'products') {
      return onCreateProductShowcaseModalOpen();
    }

    onSelectPlanShowcaseTypeModalOpen();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
      <ModalOverlay />

      <ModalContent maxWidth={{ base: '348px', md: '612px' }}>
        <ModalHeader color="#202123" fontWeight="bold" fontSize="lg">
          Adicionar uma vitrine
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl>
            <FormLabel color="#0000007D" fontWeight="normal" fontSize="sm">
              Selecione o tipo de vitrine.
            </FormLabel>

            <VStack {...group} spacing={4} align="flex-start">
              {OPTIONS.map(({ label, value }) => {
                const radio = getRadioProps({ value });

                return (
                  <ShowcaseTypeRadioCard key={value} {...radio} isChecked={radio.isChecked}>
                    <VStack align="flex-start" fontSize="sm" spacing={0}>
                      <Text as="strong" fontWeight="semibold">
                        {label}
                      </Text>
                      {value === 'products' ? (
                        <Text color="gray.700">
                          Crie uma vitrine usando produtos já cadastrados em sua plataforma.
                        </Text>
                      ) : (
                        <Text color="gray.700">Crie uma vitrine usando um ou mais planos.</Text>
                      )}
                    </VStack>
                  </ShowcaseTypeRadioCard>
                );
              })}
            </VStack>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            Cancelar
          </Button>

          <Button
            colorScheme="primary"
            color="secondary.500"
            onClick={() => handleClose(value.toString())}
          >
            Próximo
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
