import { ChevronDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Link as ChakraLink, ListItem } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import useFilters from '../../useFilters';
import FiltersList from './FiltersList';
import FilterListItem from './FiltersList/FilterListItem';

import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  StackDivider,
  useDisclosure,
} from '@chakra-ui/react';

interface ProductsMenuProps {
  menuLabel: string;
}

export default function ProductsMenu({ menuLabel }: ProductsMenuProps) {
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
  const history = useHistory();

  const {
    filtersList,
    selectedArea,
    selectedDiscipline,
    selectedSubject,
    subjectsOptions,
    disciplinesOptions,
    handleAreaChange,
    handleDisciplineChange,
    handleSubjectChange,
  } = useFilters();

  function toggleMenu() {
    onToggle();
  }

  function handleFilterClick() {
    const params = new URLSearchParams();

    const filterParams = {
      area: selectedArea?.id,
      discipline: selectedDiscipline?.id,
      subject: selectedSubject?.id,
    };

    for (const [key, value] of Object.entries(filterParams)) {
      if (value) {
        params.append(`${key}`, `["${value}"]`);
      }
    }

    onToggle();

    history.push({
      pathname: '/all-products',
      search: `?${params.toString()}`,
    });
  }

  function filterByAllProducts() {
    history.push('/all-products');
  }

  const hasSomeFilter = Object.values(filtersList).some(filter => Boolean(filter));
  const isDisciplinesColumnVisible = !!selectedArea && !!disciplinesOptions?.length;
  const isSubjectColumnVisible = !!selectedDiscipline && !!subjectsOptions?.length;

  if (!hasSomeFilter) {
    return (
      <ChakraLink
        as={Link}
        to="/all-products"
        color="secondary.500"
        fontSize="lg"
        fontWeight="medium"
        transition="0.2s all"
        _hover={{ filter: 'brightness(0.88)' }}
      >
        {menuLabel}
      </ChakraLink>
    );
  }

  return (
    <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <MenuButton
        as={Button}
        fontSize="lg"
        fontWeight="medium"
        variant="link"
        border="none"
        rightIcon={<ChevronDownIcon />}
        px={0}
        color="secondary.500"
        transition="0.2s all"
        _hover={{
          bgColor: 'none',
          filter: 'brightness(0.88)',
        }}
        _active={{
          bgColor: 'transparent',
          filter: 'brightness(0.88)',
        }}
        sx={{ '.chakra-button__icon': { ml: 0 } }}
        onMouseEnter={toggleMenu}
      >
        Produtos
      </MenuButton>

      <MenuList
        position="relative"
        mt={2}
        box-shadow="0px 0px 4px 0px #00000040"
        px={3}
        py={2.5}
        onMouseLeave={toggleMenu}
        zIndex="9999"
      >
        <TriangleUpIcon
          position="absolute"
          top="-20px"
          left="10px"
          color="#ffffff"
          border="none"
          boxSize="33.625px" //28px de largura
        />

        <HStack spacing="1.875rem" divider={<StackDivider bgColor="#0000000D" />} align="start">
          <FiltersList title="Áreas">
            <ListItem
              onClick={filterByAllProducts}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              transition="0.2s all"
              _hover={{ color: 'primary.500' }}
              cursor="pointer"
            >
              Todos
            </ListItem>

            {filtersList.areas?.map(area => (
              <FilterListItem
                key={area.id}
                filterId={area.id}
                name={area.name}
                isHovered={area.id === selectedArea?.id}
                onMouseEnter={() => handleAreaChange(area.id)}
                onClick={handleFilterClick}
                hasIcon={!!disciplinesOptions?.length}
              />
            ))}
          </FiltersList>

          {isDisciplinesColumnVisible && (
            <FiltersList title="Disciplinas">
              {disciplinesOptions?.map(discipline => (
                <FilterListItem
                  key={discipline.id}
                  filterId={discipline.id}
                  name={discipline.name}
                  onMouseEnter={() => handleDisciplineChange(discipline.id)}
                  isHovered={discipline.id === selectedDiscipline?.id}
                  onClick={handleFilterClick}
                  hasIcon={!!subjectsOptions?.length}
                />
              ))}
            </FiltersList>
          )}

          {isSubjectColumnVisible && (
            <FiltersList title="Assuntos">
              {subjectsOptions?.map(subjects => (
                <FilterListItem
                  key={subjects.id}
                  filterId={subjects.id}
                  name={subjects.name}
                  onMouseEnter={() => handleSubjectChange(subjects.id)}
                  isHovered={subjects.id === selectedSubject?.id}
                  onClick={handleFilterClick}
                  hasIcon={false}
                />
              ))}
            </FiltersList>
          )}
        </HStack>
      </MenuList>
    </Menu>
  );
}
