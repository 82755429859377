import { memo } from 'react';
import styles from './styles.module.css';

function Badge({ background, color, className, children }) {
  return (
    <div
      style={{
        background,
        color,
        border: `1px solid ${background}`,
      }}
      className={`${styles.div} ${className}`}
    >
      {children}
    </div>
  );
}

function Badges(props) {
  return (
    <>
      {props.title && (
        <Badge background={props.background} color={props.color} className={props.className}>
          {props.title}
        </Badge>
      )}
    </>
  );
}

export default memo(Badges);
