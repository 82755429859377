import { axiosBackend } from './Configuration';

const RDStationIntegrationAPI = {
  index: async () => {
    try {
      const { data: response } = await axiosBackend().get('/platforms/rd-station');

      return response;
    } catch (error) {
      throw error;
    }
  },

  store: async (client_id, client_secret) => {
    try {
      const { data: response } = await axiosBackend().post('/platforms/rd-station', {
        clientId: client_id,
        clientSecret: client_secret,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  update: async (id, client_id, client_secret) => {
    try {
      const { data: response } = await axiosBackend().patch(`/platforms/rd-station/${id}`, {
        clientId: client_id,
        clientSecret: client_secret,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default RDStationIntegrationAPI;
