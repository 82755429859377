import { Avatar, Box, Button, Card, Heading, Stack, Text } from '@chakra-ui/react';
import convertToBrazilianCurrency from '../../helpers/convertToBrazilianCurrency';
import Toast from '../Toast';
import { IParams } from '../../hooks/useShoppingCart';

export type OrderBumpType = {
  id: number;
  courseId: number;
  title: string;
  description: string;
  coursePrice: number;
  courseName: string;
  thumbnail: string;
  buttonText: string;
  buttonColor: string;
  buttonTextColor: string;
};

export function OrderBump({
  orderBumps,
  updateShoppingCartInLocalStorage,
  onChangeShoppingCart,
}: {
  orderBumps: OrderBumpType[];
  updateShoppingCartInLocalStorage: (params: Partial<IParams>, orderBumpId?: number) => void;
  onChangeShoppingCart: () => void;
}) {
  function handleAddToCartButtonClick(courseId: number, orderBumpId: number) {
    try {
      updateShoppingCartInLocalStorage({ id: courseId }, orderBumpId);
      onChangeShoppingCart();
    } catch (error) {
      Toast(error.message, 'error');
    }
  }

  return (
    <Stack direction="column" spacing={2} marginTop={2} hidden={!orderBumps?.length}>
      {orderBumps?.length &&
        orderBumps?.map(orderBump => (
          <Card
            key={orderBump?.id}
            width="full"
            maxH="355px"
            borderRadius={5}
            backgroundColor="#FFFFFF"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          >
            <Heading
              fontSize={{ base: '16px', md: '18px', lg: '18px' }}
              fontWeight={600}
              color="#202123"
              textAlign="start"
              marginY="15px"
              marginX="17px"
              width={{ base: '326px', md: '375px', lg: '375px' }}
              isTruncated
            >
              {orderBump?.title}
            </Heading>
            <Text
              fontSize={{ base: '14px', md: '16px', lg: '16px' }}
              fontWeight={400}
              margin="0 17px 17px 17px"
              width={{ base: '95%', md: '375px', lg: '375px' }}
              height="auto"
              maxH="118px"
              lineHeight="19.5px"
            >
              {orderBump?.description}
            </Text>

            <Stack direction="row" margin="0 17px 17px 17px" spacing={7}>
              {orderBump?.thumbnail ? (
                <Box
                  boxSize="130px"
                  backgroundImage={`url("${orderBump?.thumbnail}")`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  borderRadius={4}
                />
              ) : (
                <Avatar
                  name={orderBump?.courseName || orderBump?.title}
                  backgroundColor="primary.400"
                  color="secondary.400"
                  size="2xl"
                  boxSize="130px"
                  borderRadius={4}
                />
              )}

              <Stack direction="column" justifyContent="space-between" width="200px">
                <Stack direction="column" spacing={3}>
                  <Text fontSize="18px" fontWeight={500} color="#202123" lineHeight="22px">
                    {orderBump?.courseName}
                  </Text>
                  <Text fontSize="16px" fontWeight={600} color="#202123">
                    {orderBump?.coursePrice === 0
                      ? 'Grátis'
                      : convertToBrazilianCurrency(orderBump?.coursePrice)}
                  </Text>
                </Stack>

                <Button
                  onClick={() => handleAddToCartButtonClick(orderBump?.courseId, orderBump?.id)}
                  size="xs"
                  minW="109px"
                  width="auto"
                  backgroundColor={orderBump?.buttonColor}
                  color={orderBump?.buttonTextColor}
                  _hover={{
                    backgroundColor: orderBump?.buttonColor.concat('95'),
                    color: orderBump?.buttonTextColor.concat('99'),
                  }}
                >
                  {orderBump?.buttonText}
                </Button>
              </Stack>
            </Stack>
          </Card>
        ))}
    </Stack>
  );
}
