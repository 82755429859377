import { MdExitToApp as ExitIcon, MdMenu as MenuIcon } from 'react-icons/md';
import NavMenu from './NavMenu';
import UserProfile from './UserProfile';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  IconButtonProps,
  useDisclosure,
} from '@chakra-ui/react';
import { useAuth } from '../../../../contexts/AuthContext';

export const scrollbarStyle = {
  '::-webkit-scrollbar': {
    width: '0.5rem',
    height: '0.5rem',
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'gray.600',
  },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: 'md',
    backgroundColor: 'gray.400',
  },
};

export default function MobileMenu({ ...rest }: Partial<IconButtonProps>) {
  const { isOpen, onToggle } = useDisclosure();
  const { signOut } = useAuth();

  return (
    <>
      <IconButton
        onClick={onToggle}
        variant="ghost"
        color="secondary.500"
        bg="transparent"
        aria-label="Menu da aplicação"
        icon={<MenuIcon />}
        size="xs"
        sx={{ svg: { boxSize: '1.5rem' } }}
        p={0}
        {...rest}
      />

      <Drawer isOpen={isOpen} placement="left" onClose={onToggle}>
        <DrawerOverlay />

        <DrawerContent
          bg="#202123"
          color="secondary.500"
          maxWidth={{ base: '72.5%', md: '33.33%' }}
        >
          <DrawerHeader boxShadow="lg" display="flex" py={6} bg="#37393D">
            <UserProfile onToggleSidebar={onToggle} />
          </DrawerHeader>

          <DrawerBody px={4} py={8} sx={scrollbarStyle}>
            <NavMenu onClose={onToggle} />
          </DrawerBody>

          <DrawerFooter p={0} py={10}>
            <Flex w="full">
              <Button
                onClick={signOut}
                fontSize="xs"
                variant="ghost"
                leftIcon={<ExitIcon size="16.68px" />} // 16.68px = 14px conf. layout
                color="#CC595B"
              >
                Encerrar sessão
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
