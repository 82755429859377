import { useHistory } from 'react-router-dom';
import { Box, MenuItem, Menu, MenuButton, MenuList } from '@chakra-ui/react';

import { useDisclosure } from '@chakra-ui/react';

import { HiDotsVertical } from 'react-icons/hi';

interface IDropdownProps {
  handleDeletePixel: () => void;
  pixelId: number;
}

function Dropdown({ handleDeletePixel, pixelId }: IDropdownProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();

  return (
    <Box
      position="absolute"
      top="3"
      right="3"
      sx={{ '& > .css-1qq679y': { inset: '0px auto auto -83px !important' } }}
    >
      <Menu isOpen={isOpen}>
        <MenuButton fontWeight="normal" onClick={onOpen} onMouseLeave={onClose}>
          <HiDotsVertical size={16} color="rgba(32, 33, 35, 0.5)" />
        </MenuButton>
        <MenuList
          minWidth="28"
          background="white"
          borderRadius="md"
          boxShadow="none"
          color="#979CA6"
          filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          zIndex={10}
        >
          <MenuItem onClick={() => history.push(`/integrations/facebook/${pixelId}/edit-pixel`)}>
            Editar
          </MenuItem>
          <Box width="90%" marginY="0" marginX="auto" border="0.5px solid rgba(32, 33, 35, 0.05)" />
          <MenuItem onClick={handleDeletePixel} color="#BB2124">
            Excluir
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}

export default Dropdown;
