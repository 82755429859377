export function getStatusText(status: string) {
  const statusText = {
    processing: 'Processando',
    authorized: 'Autorizado',
    paid: 'Paga',
    refunded: 'Estornado',
    waiting_payment: 'Aguardando pagamento',
    pending_refund: 'Pendente de reembolso',
    refused: 'Recusado',
    chargedback: 'Chargedback',
    analyzing: 'Analisando',
    pending_review: 'Pendente de revisão',
  };

  return statusText[status];
}
