import { FormEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../../utils/scrollToInvalidInput';
import { PlanResponse } from '../types';
import { RecurrencePlanPaymentFormSchemaValidator } from './schemaValidator';
import { RecurrencePlanDescriptionForm } from './types';
import { useDisclosure, useToast } from '@chakra-ui/react';
import UploadHelper from '../../../../../../helpers/UploadHelper';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import path from 'path';
import useFetch from '../../../../../../hooks/useFetch';
import { THREE_MEGABYTES } from './constants';

const INITIAL_STATE = {
  willUsePlanPage: false,
  pageType: null,
  linkToYourPlan: null,
  description: null,
  thumbnail: null,
  mainBanner: null,
} as RecurrencePlanDescriptionForm;

interface ImageFile {
  preview: string;
  file?: File;
}

export interface usePlanManagementEditDescriptionProps {
  planData: PlanResponse;
}

export default function usePlanManagementEditDescription({
  planData,
}: usePlanManagementEditDescriptionProps) {
  const [thumbnail, setThumbnail] = useState<ImageFile>(null);
  const [mainBanner, setMainBanner] = useState<ImageFile>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageFieldToDelete, setImageFieldToDelete] = useState('');

  const { push } = useHistory();
  const { planId } = useParams<{ planId: string }>();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { fetchData: deleteImage, loading: isDeletingImage } = useFetch({
    method: 'patch',
    url: `/recurrence-plan/${planId}/remove-image`,
    authenticated: true,
    autoFetch: false,
    data: { imageToDelete: imageFieldToDelete },
  });

  const {
    form,
    setForm,
    handleChange,
    handleCancel,
    onChanged: hasChange,
    setOnChanged: setHasChange,
  } = useHandleChange<RecurrencePlanDescriptionForm>(
    INITIAL_STATE,
    !!planData && {
      willUsePlanPage: planData.willUsePlanPage,
      pageType: planData.pageType,
      linkToYourPlan: planData.linkToYourPlan,
      description: planData.description,
      thumbnail: planData.thumbnail,
      mainBanner: planData.mainBanner,
    }
  );

  function handleRadioChange(name: 'willUsePlanPage', value: boolean) {
    setForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }));

    setHasChange(true);
  }

  async function uploadFile(file: File) {
    return await UploadHelper.upload(file, 'banners');
  }

  function handleUploadError(error) {
    toast({
      title: ErrorResponse(error),
      status: 'error',
    });
  }

  async function handleDropFile(name: 'thumbnail' | 'mainBanner', acceptedFiles) {
    try {
      setHasChange(true);
      setIsUploading(true);

      const [file] = acceptedFiles;

      if (file.size > THREE_MEGABYTES) {
        return toast({
          title: 'Imagem muito grande!',
          description: 'O tamanho máximo deve ser igual ou inferior a 5 MB.',
          status: 'error',
          position: 'top',
        });
      }

      const preview = URL.createObjectURL(file);

      const { newFileName } = await uploadFile(file);

      setForm({ ...form, [name]: newFileName });

      switch (name) {
        case 'thumbnail':
          setThumbnail({ preview, file });
          break;

        case 'mainBanner':
          setMainBanner({ preview, file });
          break;

        default:
          break;
      }
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
    }
  }

  function handleDropRejected() {
    toast({
      title: 'Arquivo inválido!',
      description: 'Formatos suportados: JPG, PNG, JPEG',
      status: 'error',
    });
  }

  function handleSelectNewImage(name: 'thumbnail' | 'mainBanner') {
    setHasChange(true);

    setForm({ ...form, [name]: null });
  }

  function handleCancelButtonClick() {
    if (!hasChange) {
      return push('/recurrence/plan-management');
    }

    handleCancel();
  }

  function handleOpenImageDeleteModal(imageField: 'thumbnail' | 'thumbnail') {
    onOpen();
    setImageFieldToDelete(imageField);
  }

  async function handleDeleteImage() {
    try {
      await deleteImage();
      toast({
        title: 'Imagem excluída com sucesso!',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
      });
    }

    setForm({ ...form, [imageFieldToDelete]: null });

    onClose();
  }

  function handleDescriptionChange(value: string, length: number) {
    setForm(prevForm => ({
      ...prevForm,
      description: length > 0 ? value : '',
    }));

    setHasChange(true);
  }

  function generatePayload(
    form: Partial<RecurrencePlanDescriptionForm>
  ): Partial<RecurrencePlanDescriptionForm> {
    if (!form.willUsePlanPage) {
      return {
        willUsePlanPage: false,
        thumbnail: null,
        mainBanner: null,
      };
    }

    if (form.pageType === 'EXTERNAL') {
      return {
        ...form,
        thumbnail: null,
        mainBanner: null,
        description: null,
      };
    }

    return {
      ...form,
      willUsePlanPage: true,
      pageType: 'INTERNAL',
      linkToYourPlan: null,
    };
  }

  const payload = generatePayload(form);

  payload?.thumbnail && (payload.thumbnail = path.basename(payload.thumbnail));
  payload?.mainBanner && (payload.mainBanner = path.basename(payload.mainBanner));

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: payload,
    url: `/recurrence-plan/${planId}/description`,
    method: 'patch',
    authenticated: true,
    schemaValidator: RecurrencePlanPaymentFormSchemaValidator,
    onSuccess: {
      redirect: {
        to: `/recurrence/plan-management/edit/${planId}/customization`,
      },
    },
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  const location = window.location;
  const checkoutLink = `${location.origin}/recurrence-checkout/${planId}/personal-data`;
  const internalSalesPageLink = `${location.origin}/recurrence-plan/${planId}`;

  return {
    form,
    isSubmitting,
    formValidation,
    hasChange,
    checkoutLink,
    internalSalesPageLink,
    thumbnail,
    mainBanner,
    isUploading,
    isDeletingImage,
    isOpen,
    onClose,
    handleDeleteImage,
    handleOpenImageDeleteModal,
    handleSelectNewImage,
    handleDropFile,
    handleDropRejected,
    handleChange,
    handleRadioChange,
    handleCancelButtonClick,
    handleDescriptionChange,
    onSubmit,
  };
}
