import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  List,
  ListItem,
  ListProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Payment } from '../..';
import { getPaymentMethodText } from '../../../SubscriberManaGement/utils/getPaymentMethodText';
import { getStatusColor } from '../../utils/getStatusColor';
import { getStatusText } from '../../utils/getStatusText';

interface MobileSubscriberListProps extends ListProps {
  paymentHistory: Payment[];
}

export default function MobilePaymentList({ paymentHistory, ...rest }: MobileSubscriberListProps) {
  return (
    <List spacing={2.5} {...rest}>
      {paymentHistory.map(payment => (
        <Card
          key={payment.transactionId}
          as={ListItem}
          border="1px solid #2021230D"
          boxShadow="0px 4px 4px 0px #00000040"
        >
          <CardHeader p={4} pb={0}>
            <HStack justify="space-between" align="start" alignItems="start">
              <VStack align="start" spacing={1}>
                <Text isTruncated fontWeight="medium">
                  {payment.fullName}
                </Text>
              </VStack>
            </HStack>
          </CardHeader>

          <CardBody
            mt={4}
            p={4}
            pt={0}
            color="#20212380"
            sx={{ strong: { fontWeight: 'semibold' } }}
          >
            <List spacing={3}>
              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Status:</Text>

                <Text color={getStatusColor(payment.status)}>{getStatusText(payment.status)}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Forma de pagamento:</Text>
                <Text>{getPaymentMethodText(payment.paymentMethod)}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Data de criação::</Text>
                <Text>{dayjs(payment.createdAt).format('DD/MM/YYYY')}</Text>
              </HStack>
            </List>
          </CardBody>
        </Card>
      ))}
    </List>
  );
}
