import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BsTrash } from 'react-icons/bs';
import CustomDropzone from '../../../../../../../components/CustomDropzone';
import DotsFalling from '../../../../../../../components/Loadings/DotsFalling';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import Question from '../Question';
import QuestionAPI from '../../../../../../../api/Question';
import Spinner from '../../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../../components/Toast';
import fileDownload from '../../../../../../../assets/file-xlsx/Template_Questions.xlsx';
import path from 'path';
import reorder from '../../utils/reorder';
import { sortByOrder } from '../../../../../../../helpers/SortHelper';
import styles from './styles.module.css';
import { useCourse } from '../../../../../../../contexts/CourseContext';
import { Box, Button, Stack, Text } from '@chakra-ui/react';
import ContentAPI from '../../../../../../../api/Content';

const ADD_WITH_CURRENT_ORDER = 1;
const FIRST_FILE = 0;
const MAX_LENGTH_FILE_XLSX = 10485760;

const initialValues = {
  id: 0,
  questionText: 'SEM ENUNCIADO',
  commentary: '',
  order: 1,
};

function DragDropQuestions({ values, setIsDisabledSubmit }) {
  const { id: courseId, moduleId, contentId } = useParams();
  const [questions, setQuestions] = useState([]);

  const [reorderedQuestions, setReorderedQuestions] = useState([]);

  const [file, setFile] = useState(null);

  const [isShowDropzone, setIsShowDropzone] = useState(false);
  const [, setIsLoading] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  const { course, setCourse, getModules } = useCourse();

  const history = useHistory();

  const fetchAllQuestions = useCallback(async () => {
    try {
      setIsLoadingQuestions(true);

      if (parseInt(courseId) && parseInt(moduleId) && parseInt(contentId)) {
        const { data: questionsData } = await QuestionAPI.index(
          parseInt(courseId),
          parseInt(moduleId),
          parseInt(contentId)
        );

        if (questionsData) {
          setQuestions(questionsData);
          setIsDisabledSubmit(false);
        }
        if (!questionsData) {
          setQuestions([]);
          setIsDisabledSubmit(true);
        }

        return questionsData;
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingQuestions(false);
    }
  }, [courseId, moduleId, contentId, setIsDisabledSubmit]);

  useEffect(() => {
    if (!contentId) {
      setQuestions([]);
    }

    if (contentId) {
      setQuestions([]);
      fetchAllQuestions();
    }
  }, [contentId, fetchAllQuestions]);

  function onReorderQuestions(reorderedQuestions) {
    setQuestions(reorderedQuestions);
  }

  function handleDropFile(acceptedFiles) {
    const fileXLSX = acceptedFiles[FIRST_FILE] || '';

    setFile(fileXLSX);
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const updatedQuestions = reorder(questions, result.source.index, result.destination.index);

    onReorderQuestions(updatedQuestions);
    setReorderedQuestions(updatedQuestions);
  }

  const handleUpdateQuestionsOrder = useCallback(async () => {
    if (!reorderedQuestions.length) return;

    const payload = reorderedQuestions.map(({ id, order }) => ({
      questionId: id,
      order,
    }));

    try {
      await QuestionAPI.updateQuestionsOrder(courseId, moduleId, contentId, payload);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }, [courseId, moduleId, contentId, reorderedQuestions]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleUpdateQuestionsOrder();
    }, 1000);

    return () => clearTimeout(timer);
  }, [handleUpdateQuestionsOrder]);

  async function handleSubmitFile() {
    if (file && file.size > MAX_LENGTH_FILE_XLSX) {
      return Toast('O tamanho máximo do arquivo deve ser igual ou inferior a 10MB.', 'error');
    }

    try {
      setIsLoadingFile(true);
      let payload = file;

      const { title, contentType } = values;

      const currentModule = course.modules.find(module => module.id === Number(moduleId));

      const { contents } = currentModule;

      if (contents.length) {
        const orders = contents.map(content => content.order);

        values.order = orders.length
          ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
          : initialValues.order;
      }

      const contentData = {
        title,
        contentType,
        status: 'EM_EDICAO',
      };

      const { data: responseContentData } = await QuestionAPI.importQuestions(
        courseId,
        moduleId,
        contentId,
        payload,
        values,
        contentData
      );

      setFile('');
      setIsShowDropzone(false);
      setIsDisabledSubmit(false);
      fetchAllQuestions();

      history.push(
        `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${responseContentData.id}/edit-question`
      );
      Toast('Importação concluída!');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingFile(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setIsLoading(true);

      const orders = questions.map(question => question.order);

      let order = 1;

      if (questions.length) {
        order = initialValues.order = orders.length
          ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
          : initialValues.order;
      }
      const payload = {
        questionText: initialValues.questionText,
        commentary: initialValues.commentary,
        order: order,
      };

      const { title, contentType } = values;

      const contentData = {
        title,
        contentType,
        status: 'EM_EDICAO',
      };

      if (values && !values.id) {
        payload.contentData = contentData;
      }

      const currentModule = getModules(moduleId);

      const { data: questionData } = await QuestionAPI.store(
        courseId,
        moduleId,
        contentId,
        payload
      );

      if (questionData && !questionData.newContent) {
        Toast('Questão criada com sucesso!');

        const newQuestions = [
          ...questions,
          { ...questionData, options: [], questionText: 'SEM ENUNCIADO' },
        ];

        setIsDisabledSubmit(true);
        setQuestions(newQuestions);

        return;
      }
      const newContents = [...currentModule.contents, questionData.newContent];

      const moduleUpdated = { ...currentModule, contents: newContents };
      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({ ...course, modules: newModules });
      setIsDisabledSubmit(false);

      history.push(
        `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${questionData.newContent.id}/edit-question`
      );
      Toast('Questão criada com sucesso!');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  function PreviewXLSX() {
    function handleDiscardFile() {
      setFile('');
    }

    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Text>{file?.name || path.basename(decodeURI(file))}</Text>

        <Box cursor="pointer" onClick={handleDiscardFile}>
          <BsTrash size={18} color="#bb2124" />
        </Box>
      </Stack>
    );
  }

  async function updateStatus() {
    try {
      const payload = {
        title: values.title,
        order: values.order,
        contentType: values.contentType,
        numberOfTimesAnswerQuestion: values.numberOfTimesAnswerQuestion,
        status: 'EM_EDICAO',
      };

      const { data: updatedContent } = await ContentAPI.update(
        courseId,
        moduleId,
        contentId,
        payload
      );

      const currentModule = course.modules.find(module => module.id === Number(moduleId));

      const newContents = currentModule.contents.map(content => {
        if (content.id === Number(contentId)) {
          return {
            ...content,
            title: updatedContent.title,
            order: updatedContent.order,
            numberOfTimesAnswerQuestion: updatedContent.numberOfTimesAnswerQuestion,
            contentType: updatedContent.contentType,
            status: updatedContent.status,
          };
        }

        return content;
      });

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({ ...course, modules: newModules });
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function handleDeleteQuestion(questionId) {
    try {
      setIsLoading(true);

      await QuestionAPI.delete(Number(courseId), Number(moduleId), Number(contentId), questionId);

      const data = await fetchAllQuestions();

      if (data) {
        const payload = data.map(({ id }, index) => ({
          questionId: id,
          order: index + 1,
        }));

        await QuestionAPI.updateQuestionsOrder(courseId, moduleId, contentId, payload);

        Toast('Questão excluída com sucesso!');

        return;
      }

      updateStatus();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  function ListQuestions() {
    if (isLoadingQuestions) {
      return (
        <div className="my-1 d-flex justify-content-center align-items-center">
          <DotsFalling />
        </div>
      );
    }

    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="questions">
          {provided => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {questions.sort(sortByOrder)?.map((question, index) => {
                return (
                  <Draggable
                    key={String(question.id)}
                    draggableId={String(question.id)}
                    index={index}
                    isDragDisabled={question.length < 2}
                  >
                    {provided => (
                      <li
                        className={styles.dragDropContent}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Question
                          question={question}
                          questionId={question.id}
                          fetchAllQuestions={fetchAllQuestions}
                          onDeleteQuestion={handleDeleteQuestion}
                          setIsDisabledSubmit={setIsDisabledSubmit}
                        />
                      </li>
                    )}
                  </Draggable>
                );
              })}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return (
    <>
      <div className={styles.dragDropContainer}>
        <ListQuestions />

        <Stack direction="row" alignItems="center" spacing={5} marginY={5}>
          <Button size="sm" colorScheme="orange" onClick={handleSubmit} isDisabled={!values.title}>
            Adicionar Questão
          </Button>
          <Button
            size="sm"
            border="1px solid #e2e8f0"
            onClick={() => {
              setIsShowDropzone(prevDropzone => !prevDropzone);
            }}
            isDisabled={!values.title}
          >
            Importar Questão
          </Button>

          <span className={styles.fileDownload}>
            <a href={fileDownload} download="Template_Questions">
              Clique aqui
            </a>
            para baixar o modelo de importação.
          </span>
        </Stack>

        <div className="mt-3">
          {isShowDropzone && (
            <div className="d-flex flex-column">
              {!file ? (
                <CustomDropzone
                  file={file}
                  accept={[
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/excel',
                  ]}
                  onDrop={handleDropFile}
                  onDropRejected={() => Toast('Arquivo inválido!', 'error')}
                  extensions="xlsx"
                  maxFileSize="10MB"
                />
              ) : (
                <PreviewXLSX />
              )}

              <Box marginY={2}>
                <Button size="sm" width={150} colorScheme="orange" onClick={handleSubmitFile}>
                  {isLoadingFile ? <Spinner /> : 'Salvar'}
                </Button>
              </Box>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DragDropQuestions;
