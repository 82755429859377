import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useFetch from '../hooks/useFetch';

import {
  IContent,
  ICourseModulesAndContentsResponse,
  IModule,
} from '../pages/NetflixMemberArea/pages/ContentsView/types';

const CourseStudentContext = createContext<any>(null);

export default function CourseStudentProvider({ children }) {
  const [course, setCourse] = useState<ICourseModulesAndContentsResponse>(null);
  const [currentModule, setCurrentModule] = useState<IModule>(null);
  const [currentContent, setCurrentContent] = useState<IContent>(null);

  const location = useLocation();

  const pathname = location.pathname;

  const courseRegex = /\/course\/(\d+)/;
  const courseMatch = pathname.match(courseRegex);
  const courseId = courseMatch ? courseMatch[1] : null;

  const moduleRegex = /\/module\/(\d+)/;
  const moduleMatch = pathname.match(moduleRegex);
  const moduleId = moduleMatch ? moduleMatch[1] : null;

  const contentRegex = /\/content\/(\d+)/;
  const contentMatch = pathname.match(contentRegex);
  const contentId = contentMatch ? contentMatch[1] : null;

  const {
    data: courseModulesAndContentsResponse,
    loading: courseModulesAndContentsLoading,
    fetchData: getCourseModulesAndContents,
  } = useFetch<UnificadaFront.ResponseJSON<ICourseModulesAndContentsResponse>>({
    url: `/members-area/${courseId}/course/modules-and-contents`,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  const courseData = courseModulesAndContentsResponse?.data;
  const isSidebarVisible = courseData?.showModulesAndContentsBar;
  const highlightColor = courseData?.highlightColor;
  const showProgress = courseData?.showProgressBar;
  const hasActiveModulesPage = courseData?.hasActiveModulesPage;
  const shouldMoveToTerms = courseData?.termsOfUse && !courseData?.acceptTermsUser;

  useEffect(() => {
    if (courseId) {
      getCourseModulesAndContents();
    }
  }, [getCourseModulesAndContents, courseId]);

  useEffect(() => {
    if (courseData) {
      setCourse(courseData);
    }
  }, [courseData]);

  useEffect(() => {
    if (contentId && moduleId && course?.modules.length) {
      const currentModule = course?.modules?.find(module => module.id === parseInt(moduleId));

      if (currentModule) {
        setCurrentModule(currentModule);
      }

      const content = currentModule?.contents?.find(content => content.id === parseInt(contentId));
      if (content) {
        setCurrentContent(content);
      }
    }
  }, [contentId, moduleId, course?.modules]);

  function onUpdateCurrentContent(moduleId, contentId, newContent) {
    const currentModule = course?.modules?.find(module => module.id === parseInt(moduleId));
    const content = currentModule?.contents?.find(content => content.id === parseInt(contentId));

    if (content) {
      const updatedModules = course?.modules?.map(module => {
        if (module.id !== Number(moduleId)) {
          return module;
        }

        const updatedContents = module.contents.map(moduleContent => {
          if (moduleContent.id === Number(contentId)) {
            return { ...moduleContent, markAsDone: newContent.markAsDone };
          }

          return moduleContent;
        });

        return { ...module, contents: updatedContents };
      });

      setCourse(prevStudentCourse => ({ ...prevStudentCourse, modules: updatedModules }));
    }
  }

  return (
    <CourseStudentContext.Provider
      value={{
        course,
        currentModule,
        setCurrentContent,
        currentContent,
        isSidebarVisible,
        highlightColor,
        showProgress,
        hasActiveModulesPage,
        shouldMoveToTerms,
        courseModulesAndContentsLoading,
        onUpdateCurrentContent,
        getCourseModulesAndContents,
      }}
    >
      {children}
    </CourseStudentContext.Provider>
  );
}

export function useCourseStudent() {
  const context = useContext(CourseStudentContext);

  if (!context)
    throw new Error('useStudentCourse deve ser usado dentro de um StudentCourseContext.Provider.');

  return {
    course: context.course,
    currentModule: context.currentModule,
    currentContent: context.currentContent,
    setCurrentContent: context.setCurrentContent,
    isSidebarVisible: context.isSidebarVisible,
    highlightColor: context.highlightColor,
    showProgress: context.showProgress,
    hasActiveModulesPage: context.hasActiveModulesPage,
    shouldMoveToTerms: context.shouldMoveToTerms,
    courseModulesAndContentsLoading: context.courseModulesAndContentsLoading,
    onUpdateCurrentContent: context.onUpdateCurrentContent,
    getCourseModulesAndContents: context.getCourseModulesAndContents,
  };
}
