import { MdLock } from 'react-icons/md';
import React from 'react';
import styles from './styles.module.css';

function SecurityInformation() {
  return (
    <div className={styles.container}>
      <div>
        <MdLock />
      </div>

      <div>
        <span>Suas informações estão seguras</span>
        <p>
          Todos os dados deste site são criptografados com protocolo SSL ao trafegarem pela
          internet.
        </p>
      </div>
    </div>
  );
}

export default SecurityInformation;
