import { Box, Flex, Radio, RadioProps, useRadio } from '@chakra-ui/react';

export function ShowcaseTypeRadioCard(props: RadioProps) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label" w="100%">
      <input {...input} />
      <Box
        {...radio}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        borderColor="gray.200"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        _checked={{
          borderColor: 'primary.500',
          bg: 'transparent',
        }}
        p={2.5}
        w="100%"
      >
        <Flex alignItems="flex-start" pointerEvents="none">
          <Radio isChecked={props.isChecked} colorScheme="primary" mr="0.375rem" mt={0.5} />

          {props.children}
        </Flex>
      </Box>
    </Box>
  );
}
