import { useToast } from '@chakra-ui/react';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { axiosBackend } from '../../../../../api/Configuration';
import useHandleChange from '../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../utils/scrollToInvalidInput';
import { JoiSchemasType } from '../../../../../validation/EntitySchema';
import { EventName, Webhook, WebhookView } from '../types';

interface useInstructorFormProps {
  url?: string;
  method?: 'post' | 'patch';
  data?: WebhookView;
  successMessage?: string;
  schemaValidator: JoiSchemasType<Partial<Webhook>>;
}

interface SelectedProducts {
  value: number;
  label: string;
}

export default function useWebhookRecurrenceForm({
  url,
  method,
  data,
  successMessage,
  schemaValidator,
}: useInstructorFormProps) {
  const [selectedPlans, setSelectedPlans] = useState<SelectedProducts[] | null>(null);

  const [plans, setPlans] = useState([]);

  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    async function getProducts() {
      const { data: plansResponse } = await axiosBackend().get('/recurrence-plan/list-plan');

      if (!plansResponse) {
        toast({
          status: 'error',
          title: 'Erro ao tentar cadastrar Webhook',
          description: 'Você não possui produtos com os status: Ativo ou Não listado',
        });

        return history.push('/integrations/webhook');
      }

      const mappedProducts = [
        {
          value: 0,
          label: 'TODOS',
        },
        ...(plansResponse?.data?.map(plan => ({
          value: plan.id,
          label: plan.name,
        })) || []),
      ];

      setPlans(mappedProducts);
    }

    getProducts();
  }, [history, toast]);

  const {
    form,
    setForm,
    onChanged: hasChange,
    handleChange,
    setOnChanged: setHasChange,
    handleCancel,
  } = useHandleChange<Webhook>(
    method === 'patch'
      ? {
          webhookName: data.webhookName,
          events: data.events,
          webhookType: 'recurrence_plan',
          recurrencePlansIds: data.plans.map(plan => plan.id),
          webhookUrl: data?.webhookUrl,
          status: data.status,
        }
      : {
          webhookName: '',
          events: [],
          webhookType: 'recurrence_plan',
          recurrencePlansIds: [],
          webhookUrl: '',
          status: 'ATIVO',
        }
  );

  useEffect(() => {
    if (method === 'patch') {
      setSelectedPlans(
        Array.isArray(data?.plans) && data?.plans.length
          ? data.plans.map(plan => ({ value: plan.id, label: plan.name }))
          : [{ value: 0, label: 'TODOS' }]
      );
    }
  }, [data?.plans, method]);

  function handleEventsSelect(events: EventName[]) {
    setHasChange(true);

    setForm(prevForm => ({
      ...prevForm,
      events,
    }));
  }

  function handleSelectProducts(selectedPlans: SelectedProducts[]) {
    setHasChange(true);

    if (selectedPlans.map(({ value }) => value).includes(0)) {
      setForm(prevForm => ({
        ...prevForm,
        recurrencePlansIds: [0],
      }));

      setSelectedPlans([{ value: 0, label: 'TODOS' }]);

      return;
    }

    setSelectedPlans(selectedPlans);

    setForm(prevForm => ({
      ...prevForm,
      recurrencePlansIds: selectedPlans.map(({ value }) => value),
    }));
  }

  function onCancel() {
    if (!hasChange) {
      return history.push('/integrations/webhook');
    }

    handleCancel();
  }

  const payload = {
    ...form,
    recurrencePlansIds: form.recurrencePlansIds?.includes(0) ? null : form.recurrencePlansIds,
    ...(method === 'patch' && { status: data.status }),
  };

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: payload,
    schemaValidator,
    url,
    method,
    authenticated: true,
    onSuccess: {
      message: successMessage,
      callback: () => history.push('/integrations/webhook'),
    },
    removeNullProps: false,
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  return {
    form,
    formValidation,
    isSubmitting,
    hasChange,
    plans,
    selectedPlans,
    handleChange,
    onSubmit,
    handleSelectProducts,
    handleCancel: onCancel,
    setSelectedPlans,
    handleEventsSelect,
  };
}
