import { responsiveBannerToShow, settingsBanner } from '../slickSettings';

import Carousel from 'react-slick';
import { sortByOrder } from '../../../../../helpers/SortHelper';
import styles from '../styles.module.css';

export default function BannerMobile({ bannerHeroSection, themeMarketplace }) {
  return (
    <>
      {bannerHeroSection.active && (
        <section className={styles.bannerSection}>
          <Carousel
            {...settingsBanner}
            responsive={responsiveBannerToShow(themeMarketplace.bannerToShow)}
            slidesToShow={bannerHeroSection.sectionsToShow}
            slidesToScroll={bannerHeroSection.sectionsToShow}
          >
            {bannerHeroSection?.themeBannerHero
              .sort(sortByOrder)
              .map((bannerHeroItem, index: number) => {
                return bannerHeroItem.link ? (
                  <a
                    key={bannerHeroItem.id}
                    href={bannerHeroItem.link || null}
                    target="_blank"
                    rel="noopener noreferrer"
                    role="button"
                    title={bannerHeroItem.title}
                    style={{ display: 'inline' }}
                    className={styles.bannerLink}
                  >
                    <img
                      src={bannerHeroItem?.bannerMobile || bannerHeroItem?.banner}
                      style={{
                        backgroundColor: themeMarketplace.primaryColor,
                      }}
                      className={styles.bannerItem}
                      alt={`Banner ${index + 1}`}
                    />
                  </a>
                ) : (
                  <img
                    src={bannerHeroItem.bannerMobile || bannerHeroItem?.banner}
                    style={{
                      backgroundColor: themeMarketplace.primaryColor,
                    }}
                    className={styles.bannerItem}
                    alt=""
                  />
                );
              })}
          </Carousel>
        </section>
      )}
    </>
  );
}
