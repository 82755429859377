import { axiosBackend } from './Configuration';

const studentPaymentHistory = {
  index: async function () {
    try {
      const { data: paymentHistoryData } = await axiosBackend().get('/students/purchases-historic');

      return paymentHistoryData;
    } catch (error) {
      throw error;
    }
  },
  getPaymentSubscription: async function () {
    try {
      const { data: paymentHistorySubscription } = await axiosBackend().get(
        '/students-subscription-payment'
      );

      return paymentHistorySubscription;
    } catch (error) {
      throw error;
    }
  },
};

export default studentPaymentHistory;
