import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect } from 'react';
import { MdInsertDriveFile as BoletoIcon, MdCreditCard as CreditCardIcon } from 'react-icons/md';
import { ReactComponent as PixIcon } from '../../../../../assets/icons/pixIcon.svg';
import { FormValidation } from '../../../../../hooks/useHandleSubmit';
import { IPayment as IInstallment, MethodPaymentType } from '../../../../../hooks/useShoppingCart';
import { CreditCardType, IPayment, UserCard } from '../../types';

import { IUserResponse } from '../../..';
import { TargetType } from '../../../../../hooks/useHandleChange';
import Boleto from '../Boleto';
import BoletoForm from '../BoletoForm';
import CreditCard from '../CreditCard';
import Pix from '../Pix';
import Loading from '../../Loading';

type PaymentMethod = {
  name: string;
  icon: JSX.Element;
  value: string;
  component?: JSX.Element;
};

interface PaymentTabProps {
  value: string;
  creditCard: CreditCardType;
  formValidation?: FormValidation<Partial<IPayment>>;
  installments: number;
  payments: IInstallment[];
  availablePaymentMethods: MethodPaymentType[];

  isPurchaseByPixConfirmed: boolean;
  saveCreditCardForFuturePurchases: boolean;
  userCreditCardsList: UserCard[];
  onPaymentMethodChange: (value: string) => void;
  onCreditCardChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onInstallmentChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  userData: IUserResponse;
  isInternational: boolean;
  form: Partial<IPayment>;
  cities: any;
  states: any;
  setForm: (params: any) => void;
  handleChange: (event: ChangeEvent<TargetType>) => void;
  handleZipCodeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleStateChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  hidePixQRCode: boolean;
  isLoadingZipCode: boolean;
}

function findPaymentMethodByValue(value: string, paymentMethods: PaymentMethod[]) {
  return paymentMethods?.findIndex(option => option.value === value);
}

function findPaymentMethodByIndex(index: number, paymentMethods: PaymentMethod[]) {
  return paymentMethods[index]?.value;
}

export default function PaymentTabs({
  value,
  creditCard,
  form,
  formValidation,
  payments,
  availablePaymentMethods,
  installments,
  saveCreditCardForFuturePurchases,
  userData,
  isPurchaseByPixConfirmed,
  userCreditCardsList,
  isInternational,
  cities,
  states,
  hidePixQRCode,
  isLoadingZipCode,
  setForm,
  handleChange,
  handleZipCodeChange,
  handleStateChange,
  onPaymentMethodChange,
  onCreditCardChange,
  onInstallmentChange,
}: PaymentTabProps) {
  const isMobile = useBreakpointValue({ base: true, xl: false });

  useEffect(() => {
    if (userData?.address) {
      setForm(prevForm => ({ ...prevForm, address: userData?.address }));
    }
  }, [setForm, userData?.address]);

  function getPaymentMethodValues(paymentMethodName: string): PaymentMethod {
    switch (paymentMethodName) {
      case 'boleto':
        return {
          name: 'Boleto',
          value: 'boleto',
          icon: <BoletoIcon />,
          component: <Boleto onPaymentMethodChange={onPaymentMethodChange} />,
        };
      case 'credit_card':
        return {
          name: 'Cartão de crédito',
          icon: <CreditCardIcon />,
          value: 'credit_card',
          component: (
            <CreditCard
              creditCard={creditCard}
              formValidation={formValidation?.creditCard}
              payments={payments}
              installments={installments}
              saveCreditCardForFuturePurchases={saveCreditCardForFuturePurchases}
              userCreditCardsList={userCreditCardsList}
              onChange={onCreditCardChange}
              onInstallmentChange={onInstallmentChange}
            />
          ),
        };
      case 'pix':
        return {
          name: 'Pix',
          icon: <PixIcon />,
          value: 'pix',
          component: <Pix />,
        };
    }
  }

  const paymentMethods = availablePaymentMethods
    ?.map((availablePaymentMethod, index) => {
      return {
        id: index,
        name: getPaymentMethodValues(availablePaymentMethod).name,
        icon: getPaymentMethodValues(availablePaymentMethod).icon,
        value: getPaymentMethodValues(availablePaymentMethod).value,
        component: getPaymentMethodValues(availablePaymentMethod).component,
      };
    })
    .sort((a, b) => {
      const order = ['credit_card', 'boleto', 'pix'];
      return order.indexOf(a.value) - order.indexOf(b.value);
    });

  const selectedPaymentMethod = findPaymentMethodByValue(value, paymentMethods);

  // Faz o scroll do tab selecionado ficar no centro da tela no mobile
  useEffect(() => {
    if (isMobile) {
      const activeTabButton = document.querySelector(`[aria-selected="true"]`);
      activeTabButton?.scrollIntoView({ block: 'center', inline: 'center' });
    }
  }, [isMobile, value]);

  function handlePaymentMethodChange(index: number) {
    onPaymentMethodChange(findPaymentMethodByIndex(index, paymentMethods));
  }

  function areAllFieldsFilled(address) {
    // eslint-disable-next-line no-undefined
    if (typeof address !== 'object' || address === null || address === undefined) {
      return false;
    }

    // Verifica se o objeto tem propriedades
    if (!Object.keys(address)?.length) {
      return false;
    }

    for (const key in address) {
      if (!address[key]) {
        return false;
      }
    }
    return true; // Retorna verdadeiro se todos os campos estiverem preenchidos
  }

  return (
    <Tabs
      index={selectedPaymentMethod}
      onChange={handlePaymentMethodChange}
      overflowX="auto"
      variant="unstyled"
      isLazy
      p={0}
      mt="29px"
    >
      {!isPurchaseByPixConfirmed && (
        <TabList
          gap={{ base: 3, xl: '19px' }}
          scrollSnapType="x mandatory"
          overflowX="auto"
          sx={{ '::-webkit-scrollbar': { display: 'none' } }}
        >
          {paymentMethods?.map((option, index) => (
            <Tab
              hidden={isInternational && option.name !== 'Cartão de crédito'}
              as={Button}
              key={option?.id}
              p={0}
              scrollSnapAlign="start"
              variant="outline"
              onClick={() => handlePaymentMethodChange(index)}
              isActive={selectedPaymentMethod === index}
              pointerEvents={selectedPaymentMethod === index ? 'none' : 'auto'}
              whiteSpace="normal"
              fontWeight="normal"
              fontSize={{ base: '0.625rem', xl: 'sm' }}
              w={{ base: '6.5rem', xl: '8rem' }}
              maxW="8rem"
              px={{ base: 3, xl: 3.5 }}
              py={3}
              minH="3.5rem"
              justifyContent="center"
              border="0.84px solid #20212340"
              color="#20212380"
              _active={{
                bg: 'transparent',
                color: 'default.500',
                borderColor: 'default.500',
              }}
              textAlign="start"
              leftIcon={option?.icon}
              sx={{ svg: { width: { base: '18px', xl: '20.25px' } } }}
              flexShrink={0}
            >
              {option?.name}
            </Tab>
          ))}
        </TabList>
      )}

      <TabPanels>
        {paymentMethods?.map(option => (
          <TabPanel key={option?.id} px={0.5} pt={isPurchaseByPixConfirmed ? 0 : 8}>
            {option.name === 'Boleto' && !areAllFieldsFilled(userData?.address) && (
              <BoletoForm
                isInternational={isInternational}
                form={form}
                setForm={setForm}
                formValidation={formValidation}
                userData={userData}
                handleChange={handleChange}
                handleZipCodeChange={handleZipCodeChange}
                handleStateChange={handleStateChange}
                cities={cities}
                states={states}
              />
            )}

            {option?.component}

            <Loading isLoading={isLoadingZipCode} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
