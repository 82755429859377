import {
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Select as SelectMulti } from 'chakra-react-select';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import useFetch from '../../../../../../hooks/useFetch';
import useHandleChange, { TargetType } from '../../../../../../hooks/useHandleChange';
import useHandleSubmit, { Validation } from '../../../../../../hooks/useHandleSubmit';
import { AddProductSchemaType, addProductSchemaValidator } from './addProductSchemaValidator';
import { IProduct, IShowcase } from '..';
import { CHAKRA_REACT_SELECT_STYLES } from '../constants';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedShowcase: {
    id: number;
    name: string;
    status: string;
  };
  showcases: IShowcase[];
  setShowcases: (showcases: IShowcase[]) => void;
}

export function AddProductModal({
  isOpen,
  selectedShowcase,
  showcases,
  onClose,
  setShowcases,
}: IProps) {
  const [options, setOptions] = useState<{ value: number; label: string }[]>([]);

  const { form, handleChange, setForm } = useHandleChange<AddProductSchemaType>({
    productsIds: [],
  });

  const {
    data: courseListResponse,
    loading: courseListLoading,
    fetchData: getCourseList,
  } = useFetch<UnificadaFront.ResponseJSON<{ id: number; name: string }[]>>({
    method: 'get',
    url: `/showcase/courses?showcaseId=${selectedShowcase.id}`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (selectedShowcase.id) {
      getCourseList();
    }
  }, [getCourseList, selectedShowcase.id]);

  useEffect(() => {
    const courseListFiltered = courseListResponse?.data?.filter(
      course => !form.productsIds.includes(course.id)
    );

    const options = courseListFiltered?.map(course => ({
      value: course.id,
      label: course.name,
    }));

    setOptions(options);
  }, [courseListResponse?.data, form.productsIds]);

  function afterAddProductsConfirm(response) {
    if (!response.data.data) return;

    const products = response.data.data.products as IProduct[];

    const showcaseIndex = showcases.findIndex(
      showcaseData => showcaseData.showcaseId === selectedShowcase.id
    );

    if (showcaseIndex === -1) {
      return onClose();
    }

    const currentShowcases = [...showcases];

    currentShowcases[showcaseIndex].products = products;

    setShowcases(currentShowcases);

    setForm({ productsIds: [] });

    getCourseList();

    onClose();
  }

  function onChange(newValues) {
    if (!newValues || newValues.length === 0) return; // Verificar se há valores novos

    const chosenCourses = form.productsIds;

    const newArrayValue = chosenCourses.concat(newValues.map(newValue => newValue.value));

    const event = {
      target: {
        name: 'productsIds',
        value: newArrayValue,
      },
    };

    handleChange(event as unknown as ChangeEvent<TargetType>);
  }

  function onClickButton(productId) {
    const productsIds = form.productsIds?.filter(course => course !== productId);
    setForm({ ...form, productsIds });
  }

  const payload = {
    ...form,
    showcaseId: selectedShowcase.id,
    showcaseType: 'products',
  };

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit({
    data: payload,
    schemaValidator: addProductSchemaValidator,
    url: `/showcase/add-product`,
    method: 'post',
    authenticated: true,
    removeNullProps: false,
    onSuccess: {
      message: 'Produtos adicionados com sucesso!',
      callback(response) {
        afterAddProductsConfirm(response);
      },
    },
  });

  const isInvalidSelect = (formValidation?.productsIds as unknown as Validation)?.isInvalid;

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Adicionar produtos ({selectedShowcase.name})</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl
            isInvalid={(formValidation?.productsIds as unknown as Validation)?.isInvalid}
          >
            <FormLabel>Produtos</FormLabel>

            <SelectMulti
              menuPlacement="bottom"
              placeholder="Selecione os produtos"
              isMulti={true}
              focusBorderColor="primary.500"
              closeMenuOnSelect={true}
              noOptionsMessage={() => 'Nenhum produto encontrado.'}
              options={options}
              value={[]}
              isLoading={courseListLoading}
              isInvalid={isInvalidSelect}
              onChange={onChange}
              chakraStyles={CHAKRA_REACT_SELECT_STYLES}
            />

            <FormErrorMessage>
              {(formValidation?.productsIds as unknown as Validation)?.message}
            </FormErrorMessage>

            <Stack direction="row" alignItems="center" flexWrap="wrap" flex={1} marginY={2}>
              {form.productsIds.length &&
                form.productsIds.map(productId => (
                  <Flex
                    key={productId}
                    justify="center"
                    align="center"
                    paddingX={2}
                    paddingY={0.5}
                    gap={1}
                    maxWidth="13rem"
                    backgroundColor="#20212325"
                    borderRadius={6}
                  >
                    <Text fontWeight="medium" fontSize="xs" color="#202123" isTruncated>
                      {courseListResponse?.data?.find(course => course.id === productId)?.name}
                    </Text>

                    <CloseButton
                      size="sm"
                      boxSize={4}
                      sx={{ svg: { boxSize: '6.36px' } }}
                      onClick={() => onClickButton(productId)}
                    />
                  </Flex>
                ))}
            </Stack>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button size="sm" onClick={onClose} colorScheme="gray">
              Cancelar
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              color="secondary.500"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Adicionar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
