import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FormEvent, useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import { axiosBackend } from '../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../../../helpers/UploadHelper';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import Header from '../../Header';
import ImagePreview from './ImagePreview';
import ImageUploadLoading from './ImageUploadLoading';
import { scrollbarStyle } from '../..';
import useFetch from '../../../../../../hooks/useFetch';
import CustomDropzoneCovers from './CustomDropzoneCovers';
import DeleteImageModal from './DeleteImageModal';

const FIVE_MEGABYTES = 5 * 1024 * 1024;

interface ImageFile {
  preview: string;
  file?: File;
}

export type FormatMediaType = 'simple-image' | 'video';
type OpenLinkInType = '_self' | '_blank';

export interface ICoverResponse {
  id: number;
  name: string;
  banner: string | null;
  bannerMobile: string | null;
  bannerType: FormatMediaType;
  link: string | null;
  videoLink: string | null;
  openLinkIn: OpenLinkInType;
  order: number;
  active: number;
}
export interface ICoverCustomization {
  id?: number;
  name: string;
  formatMedia: FormatMediaType;
  banner: string | null;
  bannerMobile: string | null;
  videoLink: string | null;
  link: string;
  openLinkIn: string;
}

const initialState = {
  name: '',
  formatMedia: 'simple-image' as FormatMediaType,
  banner: null,
  bannerMobile: null,
  videoLink: null,
  link: null,
  openLinkIn: '_self' as OpenLinkInType,
};

export default function EditOrNewCovers() {
  const history = useHistory();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false });
  const { coverId } = useParams<{ coverId: string }>();

  const [isUploading, setIsUploading] = useState(false);
  const [banner, setBanner] = useState<ImageFile>(null);
  const [bannerMobile, setBannerMobile] = useState<ImageFile>(null);
  const [imageFieldToDelete, setImageFieldToDelete] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: response, fetchData } = useFetch<UnificadaFront.ResponseJSON<ICoverResponse>>({
    url: `/banner-hero-members-area/${coverId}`,
    method: 'get',
    authenticated: true,
    autoFetch: false,
  });

  const { fetchData: deleteImage, loading: isDeletingImage } = useFetch({
    method: 'patch',
    url: `/banner-hero-members-area/${coverId}/remove-banner-mobile`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (coverId) {
      fetchData();
    }
  }, [coverId, fetchData]);

  const cover = response?.data;

  const { form, setForm, onChanged, setOnChanged, handleChange } =
    useHandleChange<ICoverCustomization>(
      initialState,
      cover && {
        id: cover.id,
        name: cover.name,
        formatMedia: cover.bannerType,
        banner: cover.banner,
        bannerMobile: cover.bannerMobile,
        link: cover.link,
        openLinkIn: cover.openLinkIn,
        videoLink: cover.videoLink,
      }
    );

  async function handleDropFile(name: 'banner' | 'bannerMobile', acceptedFiles) {
    try {
      setOnChanged(true);

      const [file] = acceptedFiles;

      if (file.size > FIVE_MEGABYTES) {
        return toast({
          title: 'Imagem muito grande!',
          description: 'O tamanho máximo deve ser igual ou inferior a 5 MB.',
          status: 'error',
          position: 'top',
        });
      }

      const preview = URL.createObjectURL(file);

      setForm({ ...form, [name]: preview });

      switch (name) {
        case 'banner':
          setBanner({ preview, file });
          break;
        case 'bannerMobile':
          setBannerMobile({ preview, file });
          break;

        default:
          break;
      }
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
      });
    }
  }

  function handleSelectNewImage(name: 'banner' | 'bannerMobile') {
    setOnChanged(true);

    setForm({ ...form, [name]: null });

    switch (name) {
      case 'banner':
        setBanner({ preview: null, file: null });
        break;
      case 'bannerMobile':
        setBannerMobile({ preview: null, file: null });
        break;

      default:
        break;
    }
  }

  function handleOpenImageDeleteModal(imageField: 'banner' | 'bannerMobile') {
    onOpen();
    setImageFieldToDelete(imageField);
  }

  async function handleDeleteImage() {
    try {
      await deleteImage();
      toast({
        title: 'Imagem excluída com sucesso!',
        status: 'success',
      });

      setForm({ ...form, [imageFieldToDelete]: null });
      setBannerMobile({ preview: null, file: null });

      cover.bannerMobile = null;
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
      });
    }

    onClose();
  }

  function handleDropRejected() {
    toast({
      title: 'Arquivo inválido!',
      description: 'Formatos suportados: JPG, PNG, JPEG',
      status: 'error',
    });
  }

  function toGoBack() {
    return history.push('/members-area-management/netflix?tab=1');
  }

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    try {
      setIsUploading(true);

      let payload = { ...form, banner: null, bannerMobile: null };

      if (form.formatMedia === 'simple-image') {
        if (banner?.file) {
          const { newFileName } = await UploadHelper.upload(banner.file, 'banners');

          payload.banner = newFileName;
        }

        if (bannerMobile?.file) {
          const { newFileName } = await UploadHelper.upload(bannerMobile.file, 'banners');

          payload.bannerMobile = newFileName;
        }
      }

      for (const key of Object.keys(payload)) {
        if (!payload[key]) {
          payload[key] = null;
        }
      }

      if (coverId) {
        await axiosBackend().patch(`/banner-hero-members-area/${coverId}`, payload);
      } else {
        await axiosBackend().post(`/banner-hero-members-area`, payload);
      }

      return history.push('/members-area-management/netflix?tab=1');
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <Box maxH="100vh" overflowY="auto" sx={scrollbarStyle}>
      <Header />

      <Container
        as="main"
        w="full"
        maxW="container.xl"
        padding={isMobile ? '32px 20px 40px 20px' : '56px 0px 40px 0px'}
      >
        <HStack
          spacing={1}
          fontSize="12px"
          fontWeight="400"
          color="rgba(32, 33, 35, 0.50)"
          cursor="pointer"
          onClick={toGoBack}
          width={{ base: 'full', lg: '125px' }}
        >
          <IoIosArrowBack />

          <Text fontStyle="normal" lineHeight="20px" textDecorationLine="underline">
            Voltar para capas
          </Text>
        </HStack>

        <Text
          fontSize="20px"
          fontWeight="500"
          lineHeight="normal"
          marginTop="21px"
          marginBottom="40px"
        >
          {cover?.name ?? 'Nova capa'}
        </Text>

        <Stack spacing="30px" as="form" onSubmit={onSubmit}>
          <Text fontSize="20px" fontWeight="500" lineHeight="normal">
            Definições
          </Text>

          <Stack direction={{ base: 'column', lg: 'row' }} width="full">
            <FormControl>
              <FormLabel fontSize="14px" fontWeight="500" lineHeight="normal">
                Nome
              </FormLabel>
              <Input
                size="sm"
                borderRadius={5}
                name="name"
                value={form.name}
                onChange={handleChange}
                focusBorderColor="primary.500"
                placeholder="Digite aqui"
              />
            </FormControl>

            <FormControl>
              <FormLabel fontSize="14px" fontWeight="500" lineHeight="normal">
                Formato
              </FormLabel>
              <Select
                size="sm"
                borderRadius={5}
                name="formatMedia"
                value={form.formatMedia}
                onChange={handleChange}
                focusBorderColor="primary.500"
              >
                <option value="simple-image">Imagem</option>
                <option value="video">Video</option>
              </Select>
            </FormControl>
          </Stack>

          <Stack
            direction="row"
            width="full"
            spacing="17px"
            hidden={form.formatMedia === 'simple-image'}
          >
            <Stack width="full">
              <Text fontSize="20px" fontWeight="500" lineHeight="normal">
                Conteúdo
              </Text>
              <FormControl>
                <FormLabel fontSize="14px" fontWeight="500" lineHeight="normal">
                  Link do vídeo
                </FormLabel>

                <FormHelperText
                  color="rgba(0, 0, 0, 0.49)"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="normal"
                  marginBottom="8px"
                >
                  Insira o link de um vídeo hospedado externamente no YouTube ou Vimeo, com
                  dimensões: 1366x500.
                </FormHelperText>

                <Input
                  name="videoLink"
                  value={form.videoLink}
                  onChange={handleChange}
                  focusBorderColor="primary.500"
                  placeholder="Digite aqui"
                />
              </FormControl>
            </Stack>
          </Stack>

          <Stack
            direction={{ base: 'column', lg: 'row' }}
            width="full"
            spacing="17px"
            hidden={form.formatMedia === 'video'}
          >
            <Stack width="full">
              <Text fontSize="14px" fontWeight="500" lineHeight="normal">
                Desktop
              </Text>

              {!banner?.preview && !form.banner ? (
                <Box width="full" display="flex" justifyContent="center">
                  <Box
                    width="full"
                    bgColor="red.200"
                    height={{ base: '126px', lg: '342px' }}
                    flexShrink="0"
                  >
                    <CustomDropzoneCovers
                      file={banner?.file}
                      isDisabled={isUploading}
                      accept={['image/jpg', 'image/png', 'image/jpeg']}
                      dimensions="1366x500"
                      height="full"
                      onDrop={acceptedFiles => handleDropFile('banner', acceptedFiles)}
                      onDropRejected={handleDropRejected}
                      extensions="JPG, PNG, JPEG e WEBP"
                    />
                    <ImageUploadLoading position="absolute" isLoading={isUploading} />
                  </Box>
                </Box>
              ) : (
                <VStack width="full" display="flex" justifyContent="center">
                  <Box width="full" height={{ base: '126px', lg: '342px' }}>
                    <ImagePreview
                      imageField="banner"
                      preview={banner?.preview ?? form.banner}
                      onSelectNewImage={handleSelectNewImage}
                      cannotDelete={true}
                    />
                  </Box>

                  <Button
                    display={{ base: 'flex', xl: 'none' }}
                    onClick={() => handleSelectNewImage('banner')}
                    w="full"
                    variant="outline"
                    colorScheme="primary"
                    size="sm"
                    zIndex="2"
                  >
                    Trocar Imagem
                  </Button>
                </VStack>
              )}
            </Stack>

            <Stack width={{ base: 'full', lg: '195px' }}>
              <Text fontSize="14px" fontWeight="500" lineHeight="normal">
                Mobile
              </Text>

              {!bannerMobile?.preview && !form.bannerMobile ? (
                <Box width="full" display="flex" justifyContent="center">
                  <Box
                    width={{ base: 'full', lg: '195px' }}
                    bgColor="red.200"
                    height={{ base: '606px', lg: '342px' }}
                    flexShrink="0"
                  >
                    <CustomDropzoneCovers
                      file={bannerMobile?.file}
                      height="full"
                      isDisabled={isUploading}
                      accept={['image/jpg', 'image/png', 'image/jpeg']}
                      dimensions="1920x1080"
                      onDrop={acceptedFiles => handleDropFile('bannerMobile', acceptedFiles)}
                      onDropRejected={handleDropRejected}
                      extensions="JPG, PNG, JPEG e WEBP"
                    />
                    <ImageUploadLoading position="absolute" isLoading={isUploading} />
                  </Box>
                </Box>
              ) : (
                <VStack width="full" display="flex" justifyContent="center">
                  <Box
                    width={{ base: 'full', lg: '195px' }}
                    height={{ base: '606px', lg: '342px' }}
                  >
                    <ImagePreview
                      imageField="bannerMobile"
                      preview={bannerMobile?.preview ?? form.bannerMobile}
                      onSelectNewImage={handleSelectNewImage}
                      onOpenDeleteImageModal={handleOpenImageDeleteModal}
                      cannotDelete={!cover?.bannerMobile || !form.bannerMobile}
                    />
                  </Box>

                  <Button
                    display={{ base: 'flex', xl: 'none' }}
                    onClick={() => handleSelectNewImage('bannerMobile')}
                    w="full"
                    variant="outline"
                    colorScheme="primary"
                    size="sm"
                    zIndex="2"
                  >
                    Trocar Imagem
                  </Button>

                  <Button
                    hidden={!cover?.bannerMobile || !form.bannerMobile}
                    display={{ base: 'flex', xl: 'none' }}
                    onClick={() => handleOpenImageDeleteModal('bannerMobile')}
                    w="full"
                    colorScheme="red"
                    size="sm"
                    zIndex="2"
                  >
                    Excluir Imagem
                  </Button>
                </VStack>
              )}
            </Stack>
          </Stack>

          <Stack width="full" hidden={form.formatMedia === 'video'}>
            <Text fontSize="20px" fontWeight="500" lineHeight="normal">
              Redirecionamento
            </Text>

            <Stack direction={{ base: 'column', lg: 'row' }} width="full">
              <FormControl>
                <FormLabel fontSize="14px" fontWeight="500" lineHeight="normal">
                  Destino
                </FormLabel>
                <Input
                  size="sm"
                  borderRadius={5}
                  name="link"
                  value={form.link}
                  onChange={handleChange}
                  focusBorderColor="primary.500"
                  placeholder="Cole o link aqui"
                />
              </FormControl>

              <FormControl>
                <FormLabel fontSize="14px" fontWeight="500" lineHeight="normal">
                  Abrir em:
                </FormLabel>
                <Select
                  size="sm"
                  borderRadius={5}
                  name="openLinkIn"
                  value={form.openLinkIn}
                  onChange={handleChange}
                  focusBorderColor="primary.500"
                >
                  <option value="_self">Mesma aba</option>
                  <option value="_blank">Outra aba</option>
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <HStack width="full" spacing="20px" justifyContent="flex-end">
            <Button width={{ base: 'full', lg: '100px' }} size="sm" onClick={toGoBack}>
              Cancelar
            </Button>

            <Button
              width={{ base: 'full', lg: '100px' }}
              size="sm"
              type="submit"
              colorScheme="primary"
              color="secondary.500"
              isDisabled={!onChanged}
            >
              Salvar
            </Button>
          </HStack>
        </Stack>
      </Container>

      <DeleteImageModal
        isOpen={isOpen}
        isLoading={isDeletingImage}
        onConfirm={handleDeleteImage}
        onClose={onClose}
      />
    </Box>
  );
}
