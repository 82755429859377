import {
  AsProps,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  OmitCommonProps,
} from '@chakra-ui/react';
import { DetailedHTMLProps, InputHTMLAttributes, useState } from 'react';
import { MdVisibilityOff, MdVisibility } from 'react-icons/md';

function PasswordInput(
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<
      DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
      keyof InputProps
    > &
    InputProps &
    OmitCommonProps<any, keyof InputProps> & { as?: AsProps | undefined }
): JSX.Element {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input type={show ? 'text' : 'password'} {...props} />
      <InputRightElement width="3rem" _hover={{ cursor: 'pointer' }} onClick={handleClick}>
        {!show ? <Icon as={MdVisibility} /> : <Icon as={MdVisibilityOff} />}
      </InputRightElement>
    </InputGroup>
  );
}

export default PasswordInput;
