import { Box, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';

import { nanoid } from 'nanoid';
import VideoItem from '../VideoItem';
import { FIlteredVideosType } from '..';

type Params = {
  videos: FIlteredVideosType[];
  updateStatusTranscode: ({ id, transcode, thumbnail }) => void;
  onDeleteVideoList: (id: number) => void;
  onOpenPreviewVideo: (id: number) => void;
};

function TableVideoLibrary({
  videos,
  updateStatusTranscode,
  onDeleteVideoList,
  onOpenPreviewVideo,
}: Params): JSX.Element {
  if (!videos.length) return <></>;

  return (
    <>
      <Box marginY={5}>
        <Table variant="striped">
          <Thead height="40px">
            <Tr>
              <Th></Th>
              <Th>NOME DO VÍDEO</Th>
              <Th>TAMANHO</Th>
              <Th>DURAÇÃO</Th>
              <Th>DATA DE UPLOAD</Th>
              <Th>STATUS</Th>
              <Th>AÇÕES</Th>
            </Tr>
          </Thead>
          <Tbody>
            {videos.map(video => (
              <VideoItem
                key={nanoid()}
                video={video}
                updateStatusTranscode={updateStatusTranscode}
                onDeleteVideoList={onDeleteVideoList}
                onOpenPreviewVideo={onOpenPreviewVideo}
              />
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

export default TableVideoLibrary;
