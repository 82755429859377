import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  EMAIL_LOCAL_STORAGE,
  ID_LOCAL_STORAGE,
  NAME_LOCAL_STORAGE,
  PHOTO_LOCAL_STORAGE,
  ROLE_LOCAL_STORAGE,
  TOKEN_LOCAL_STORAGE,
  TOKEN_ORIGIN_LOGIN,
  TOKEN_PURCHASE,
} from '../helpers/LocalStorageHelper';

import Authentication from '../api/Authentication';
import { routesWithoutValidation } from '../api/Configuration';
import { SHOPPING_CART } from '../helpers/LocalStorageHelper';
import useQuery from '../hooks/useQuery';

const routesRoleAluno = ['members-area', 'student-checkout'];

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [membersArea, setMembersArea] = useState('');
  const [totalItemsInShoppingCart, setTotalItemsInShoppingCart] = useState();

  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  const verifyAuthentication = useCallback(async () => {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

    if (token && !isAuthenticated) {
      const result = await Authentication.validateToken(token);

      if (result) {
        setIsAuthenticated(true);
        setMembersArea(result.data.memberArea);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const tokenQuery = query.get('token');

    let basePath =
      location.pathname
        .split('/')
        .filter(path => path)
        .shift() || '/';

    const matchRoute = routesWithoutValidation.includes(basePath);

    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

    const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

    if (role === 'ALUNO' && !matchRoute && !routesRoleAluno.includes(basePath)) {
      history.push('/login');
    }

    if (!matchRoute && !token && !tokenQuery) {
      localStorage.removeItem(TOKEN_LOCAL_STORAGE);
      localStorage.removeItem(ROLE_LOCAL_STORAGE);
      localStorage.removeItem(NAME_LOCAL_STORAGE);
      localStorage.removeItem(EMAIL_LOCAL_STORAGE);

      history.push('/login');
    }
  }, [location, history, query]);

  useEffect(() => {
    async function logInAutomatically(token) {
      const authenticationData = await Authentication.validateToken(token);
      if (authenticationData) {
        setIsAuthenticated(true);
        setMembersArea(authenticationData.data.memberArea);
      }
    }

    const query = new URLSearchParams(window.location.search);

    const token = query.get('token') || localStorage.getItem(TOKEN_LOCAL_STORAGE);

    if (token && !isAuthenticated && window.location.pathname === '/login') {
      logInAutomatically(token);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      verifyAuthentication();
    }
  }, [isAuthenticated, verifyAuthentication]);

  function signOut() {
    const currentToken = localStorage.getItem(TOKEN_ORIGIN_LOGIN);

    if (currentToken) {
      Authentication.logoutAsUser(history);
      return;
    }

    localStorage.removeItem(ID_LOCAL_STORAGE);
    localStorage.removeItem(NAME_LOCAL_STORAGE);
    localStorage.removeItem(TOKEN_LOCAL_STORAGE);
    localStorage.removeItem(ROLE_LOCAL_STORAGE);
    localStorage.removeItem(EMAIL_LOCAL_STORAGE);
    localStorage.removeItem(PHOTO_LOCAL_STORAGE);

    localStorage.removeItem(TOKEN_PURCHASE);

    setIsAuthenticated(false);

    history.push('/login');
  }

  const onUpdateTotalItemsInShoppingCart = useCallback(total => {
    setTotalItemsInShoppingCart(total);
  }, []);

  useEffect(() => {
    const values = JSON.parse(localStorage.getItem(SHOPPING_CART));

    const totalItems = values?.products?.reduce((totalItems, item) => item.amount + totalItems, 0);

    setTotalItemsInShoppingCart(totalItems);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        verifyAuthentication,
        signOut,
        membersArea,
        totalItemsInShoppingCart,
        onUpdateTotalItemsInShoppingCart,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth deve ser usado dentro de um AuthProvider.');

  return {
    isAuthenticated: context.isAuthenticated,
    verifyAuthentication: context.verifyAuthentication,
    signOut: context.signOut,
    membersArea: context.membersArea,
    totalItemsInShoppingCart: context.totalItemsInShoppingCart,
    onUpdateTotalItemsInShoppingCart: context.onUpdateTotalItemsInShoppingCart,
  };
}
