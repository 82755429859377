import { memo } from 'react';
import dayjs from 'dayjs';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { CheckoutSettings } from '../../types';

import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface CheckoutSettingsTableRowProps {
  checkoutSettingsItem: CheckoutSettings;
  onGetCheckoutLink: (productId: number, checkoutType: CheckoutSettings['checkoutType']) => string;
  onCheckoutLinkCopy: (_, result: boolean) => void;
  onProductToChangeCheckoutTypeClick: (productId: number) => void;
}

function CheckoutSettingsTableRow({
  checkoutSettingsItem,
  onGetCheckoutLink,
  onCheckoutLinkCopy,
  onProductToChangeCheckoutTypeClick,
}: CheckoutSettingsTableRowProps) {
  const formattedUpdatedAt = dayjs(checkoutSettingsItem.updatedAt).format('DD/MM/YYYY HH:mm');

  const checkoutLink = onGetCheckoutLink(
    checkoutSettingsItem.courseId,
    checkoutSettingsItem.checkoutType
  );

  return (
    <Tr color="gray.700" sx={{ td: { p: 6 } }}>
      <Td maxWidth="18.0625rem" whiteSpace="normal" fontWeight="medium">
        {checkoutSettingsItem.courseName}
      </Td>

      <Td maxWidth="17.375rem">
        {checkoutSettingsItem.checkoutType === 'DEFAULT' ? 'Padrão' : 'Lançamento'}
      </Td>

      <Td>
        <CopyToClipboard text={checkoutLink} onCopy={onCheckoutLinkCopy}>
          <Button
            variant="link"
            textDecoration="underline"
            fontWeight="normal"
            size="sm"
            color="#759AED"
          >
            Copiar link
          </Button>
        </CopyToClipboard>
      </Td>

      <Td maxWidth="14.125rem">
        <Text color="gray.700">{formattedUpdatedAt}</Text>
        <Text color="#20212380">{checkoutSettingsItem.updatedBy ?? ''}</Text>
      </Td>

      <Td textAlign="center">
        <Menu size="sm" arrowPadding={0}>
          <MenuButton
            size="xs"
            as={IconButton}
            icon={<MoreIcon size="1.125rem" />}
            w="27px"
            h="24px"
            borderRadius={5}
            bgColor="#2021231F"
            _hover={{ bgColor: '#b6b5b5' }}
            _active={{ bgColor: '#b6b5b5' }}
          />

          <MenuList
            sx={{
              '.chakra-menu__menuitem': {
                fontWeight: 'medium',
                fontSize: 'xs',
                color: '#20212380',
                _hover: { bgColor: '#2021230D' },
                _focus: { bgColor: '#2021230D' },
              },
            }}
          >
            <MenuItem onClick={() => onProductToChangeCheckoutTypeClick(checkoutSettingsItem.id)}>
              Alterar tipo de checkout
            </MenuItem>

            {checkoutSettingsItem.checkoutType === 'LAUNCH' && (
              <MenuItem
                as={Link}
                to={{
                  pathname: `/checkout-settings/edit/${checkoutSettingsItem.id}/payment`,
                  search: `?productId=${checkoutSettingsItem.courseId}&productName=${checkoutSettingsItem.courseName}`,
                }}
              >
                Editar
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default memo(CheckoutSettingsTableRow);
