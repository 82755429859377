import React from 'react';
import './Alerts.css';

export function Alert({ className, color = 'danger', text = '...' }) {
  return <div className={`alert alert-${color} ${className}`}>{text}</div>;
}

function Alerts(props) {
  return (
    <>
      <div className={'alert alert-' + props.class_name}>
        {props.icon}
        {props.text}
      </div>
    </>
  );
}

export default Alerts;
