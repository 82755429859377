import { Icon, IconProps } from '@chakra-ui/react';

export function QuestionStatisticsIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 14" {...props}>
      <path
        d="M11.2 6.22222V0H4.8V4.66667H0V14H16V6.22222H11.2ZM6.4 1.55556H9.6V12.4444H6.4V1.55556ZM1.6 6.22222H4.8V12.4444H1.6V6.22222ZM14.4 12.4444H11.2V7.77778H14.4V12.4444Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export function StudentPerformanceIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path
        d="M7 1.75C7.9625 1.75 8.75 2.5375 8.75 3.5C8.75 4.4625 7.9625 5.25 7 5.25C6.0375 5.25 5.25 4.4625 5.25 3.5C5.25 2.5375 6.0375 1.75 7 1.75ZM7 9.625C9.3625 9.625 12.075 10.7537 12.25 11.375V12.25H1.75V11.3837C1.925 10.7537 4.6375 9.625 7 9.625ZM7 0C5.06625 0 3.5 1.56625 3.5 3.5C3.5 5.43375 5.06625 7 7 7C8.93375 7 10.5 5.43375 10.5 3.5C10.5 1.56625 8.93375 0 7 0ZM7 7.875C4.66375 7.875 0 9.0475 0 11.375V14H14V11.375C14 9.0475 9.33625 7.875 7 7.875Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export function QuestionPerformanceIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path
        d="M12.4444 0H1.55556C0.7 0 0 0.7 0 1.55556V12.4444C0 13.3 0.7 14 1.55556 14H12.4444C13.3 14 14 13.3 14 12.4444V1.55556C14 0.7 13.3 0 12.4444 0ZM12.4444 12.4444H1.55556V1.55556H12.4444V12.4444ZM3.11111 5.44444H4.66667V10.8889H3.11111V5.44444ZM6.22222 3.11111H7.77778V10.8889H6.22222V3.11111ZM9.33333 7.77778H10.8889V10.8889H9.33333V7.77778Z"
        fill="currentColor"
      />
    </Icon>
  );
}
