import {
  Button,
  Heading,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { TabPanelArea } from './TabPanelArea';
import { TabPanelDiscipline } from './TabPanelDiscipline';
import { TabPanelInstitution } from './TabPanelInstitution';
import { TabPanelSubject } from './TabPanelSubject';

export default function Filter() {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const {
    isOpen: isOpenModalAddOrEditArea,
    onOpen: onOpenModalAddOrEditArea,
    onClose: onCloseModalAddOrEditArea,
  } = useDisclosure();

  const {
    isOpen: isOpenModalAddOrEditDiscipline,
    onOpen: onOpenModalAddOrEditDiscipline,
    onClose: onCloseModalAddOrEditDiscipline,
  } = useDisclosure();
  const {
    isOpen: isOpenModalAddOrEditSubject,
    onOpen: onOpenAddOrEditSubject,
    onClose: onCloseModalAddOrEditSubject,
  } = useDisclosure();

  const {
    isOpen: isOpenModalAddOrEditInstitution,
    onOpen: onOpenModalAddOrEditInstitution,
    onClose: onCloseModalAddOrEditInstitution,
  } = useDisclosure();

  function modalGenerate(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        return onOpenModalAddOrEditArea;
      case 1:
        return onOpenModalAddOrEditDiscipline;
      case 2:
        return onOpenAddOrEditSubject;
      case 3:
        return onOpenModalAddOrEditInstitution;
      default:
        return () => '';
    }
  }

  function buttonTextGenerate(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        return 'Adicionar Área';
      case 1:
        return 'Adicionar Disciplina';
      case 2:
        return 'Adicionar Assunto';
      case 3:
        return 'Adicionar Banca';
      default:
        return 'Adicionar';
    }
  }

  return (
    <>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
        alignItems="center"
      >
        <Heading fontWeight={600}>Filtros</Heading>
        <Button
          leftIcon={<BiPlus />}
          size="sm"
          width={{ base: '100%', md: 'auto', lg: 'auto' }}
          colorScheme="orange"
          onClick={modalGenerate(tabIndex)}
        >
          {buttonTextGenerate(tabIndex)}
        </Button>
      </Stack>

      <Tabs
        marginTop={2}
        marginBottom={10}
        colorScheme="orange"
        onChange={index => setTabIndex(index)}
      >
        <TabList color="#20212380">
          <Tab
            color="rgba(32, 33, 35, 0.50)"
            _active={{ color: '#EB7129' }}
            _selected={{ color: '#EB7129' }}
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
          >
            Áreas
          </Tab>
          <Tab
            color="rgba(32, 33, 35, 0.50)"
            _active={{ color: '#EB7129' }}
            _selected={{ color: '#EB7129' }}
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
          >
            Disciplinas
          </Tab>
          <Tab
            color="rgba(32, 33, 35, 0.50)"
            _active={{ color: '#EB7129' }}
            _selected={{ color: '#EB7129' }}
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
          >
            Assuntos
          </Tab>
          <Tab
            color="rgba(32, 33, 35, 0.50)"
            _active={{ color: '#EB7129' }}
            _selected={{ color: '#EB7129' }}
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
          >
            Bancas
          </Tab>
        </TabList>

        <TabIndicator mt="-1.5px" height="3px" bg="#EB7129" borderRadius="2px" />

        <TabPanels>
          <TabPanelArea
            isOpen={isOpenModalAddOrEditArea}
            onOpen={onOpenModalAddOrEditArea}
            onClose={onCloseModalAddOrEditArea}
            tabIndex={tabIndex}
          />

          <TabPanelDiscipline
            isOpen={isOpenModalAddOrEditDiscipline}
            onOpen={onOpenModalAddOrEditDiscipline}
            onClose={onCloseModalAddOrEditDiscipline}
            tabIndex={tabIndex}
          />

          <TabPanelSubject
            isOpen={isOpenModalAddOrEditSubject}
            onOpen={onOpenAddOrEditSubject}
            onClose={onCloseModalAddOrEditSubject}
            tabIndex={tabIndex}
          />

          <TabPanelInstitution
            isOpen={isOpenModalAddOrEditInstitution}
            onOpen={onOpenModalAddOrEditInstitution}
            onClose={onCloseModalAddOrEditInstitution}
            tabIndex={tabIndex}
          />
        </TabPanels>
      </Tabs>
    </>
  );
}
