import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Heading, HStack, IconButton, Input, Link, Text, Button } from '@chakra-ui/react';
import dayjs from 'dayjs';
import path from 'path';
import { Modal } from 'react-bootstrap';
import { MdDeleteOutline } from 'react-icons/md';
import { baseURLBackend } from '../../../../../../api/Configuration';
import ContentAPI from '../../../../../../api/Content';
import CustomDropzone from '../../../../../../components/CustomDropzone';
import EditorRichText from '../../../../../../components/EditorRichText';
import DataLoading from '../../../../../../components/Loadings/DataLoading';
import Spinner from '../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { TOKEN_LOCAL_STORAGE } from '../../../../../../helpers/LocalStorageHelper';
import UploadHelper from '../../../../../../helpers/UploadHelper';

const initialValues = {
  title: '',
  contentType: 'TAREFA',
  status: 'ATIVO',
  hasGrade: 'false',
  deliveryDate: '',
  order: 0,
  file: '',
  content: '',
  download: '',
};

const MAX_LENGTH_FILE_DOWNLOAD = 52428800;
const ADD_WITH_CURRENT_ORDER = 1;

export default function TaskForm() {
  const history = useHistory();

  const { course, setCourse, getContent } = useCourse();

  const { id: courseId, moduleId, contentId } = useParams();

  const [form, setForm] = useState(initialValues);

  const [editorState, setEditorState] = useState('');

  const [isShowModal, setIsShowModal] = useState(false);
  const [changed, setChanged] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [archive, setArchive] = useState('');

  const errorMessageRef = useRef(null);

  useEffect(() => {
    const content = getContent(moduleId, contentId);

    if (!contentId) {
      return setForm(initialValues);
    }

    if (content) {
      const currentEditorState = content.content || '';
      setEditorState(currentEditorState);

      let deliveryDate = '';

      if (content.deliveryDate) {
        deliveryDate = dayjs(content.deliveryDate).format('YYYY-MM-DD');
      }

      setArchive(content.download);

      setForm({
        title: content.title,
        contentType: content.contentType,
        order: content.order,
        file: content.download,
        content: content.content,
        hasGrade: String(content.hasGrade),
        deliveryDate: deliveryDate,
      });
    }
  }, [contentId, getContent, moduleId]);

  function handleChange(event) {
    const { name, value } = event.target;

    if (name === 'archive') {
      const [file] = event.target.files || '';
      return setForm({ ...form, file });
    }

    setForm({ ...form, [name]: value });

    setChanged(true);
  }

  function handleEditorStateChange(editorState) {
    setEditorState(editorState);
    setErrorMessage('');
    setForm(prevForm => ({ ...prevForm, content: editorState }));
    setChanged(true);
  }

  function handleDropFile(acceptedFiles) {
    setErrorMessage('');

    const [file] = acceptedFiles || '';

    setArchive(file);

    setForm({ ...form, file });
  }

  function PreviewFile({ file }) {
    function handleDiscardFile() {
      setArchive('');
      setForm({ ...form, file: null });
    }

    return (
      <HStack justify="space-between" spacing={4}>
        <Text wordBreak="break-all" noOfLines={1}>
          {file?.name || path.basename(decodeURI(form.file))}
        </Text>

        <HStack flexShrink={0}>
          {!file && (
            <Button
              as={Link}
              variant="ghost"
              href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${localStorage.getItem(
                TOKEN_LOCAL_STORAGE
              )}`}
              target="_blank"
              rel="noreferrer"
              download
            >
              Download
            </Button>
          )}

          <IconButton
            colorScheme="orange"
            icon={<MdDeleteOutline size="1.5rem" />}
            onClick={handleDiscardFile}
            variant="outline"
          />
        </HStack>
      </HStack>
    );
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    setArchive('');
    setForm(initialValues);
    setEditorState('');
    setErrorMessage('');
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setErrorMessage('');

    if (!form.title || !form.content || form.content === '<p></p>↵') {
      handleFocus();
      throw new Error('Por favor, preencha os campos');
    }

    setIsLoading(true);

    try {
      if (!contentId) {
        if (archive && archive.size > MAX_LENGTH_FILE_DOWNLOAD) {
          throw new Error('O tamanho máximo do arquivo deve ser igual ou inferior a 50 MB.');
        }

        const currentModule = course.modules.find(module => module.id === parseInt(moduleId));
        const { contents } = currentModule;

        if (contents.length) {
          const orders = contents.map(content => content.order);
          form.order = form.order = orders.length
            ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
            : initialValues.order;
        }

        let archiveName;

        if (archive) {
          const { newFileName } = await UploadHelper.upload(archive, 'archives');

          setForm({ ...form, file: encodeURI(newFileName) });

          archiveName = newFileName;
        }

        const payload = {
          ...form,
          filePDF: archiveName ? archiveName : form.archive ? form.archive : null,
        };

        delete payload.file;
        delete payload.download;

        const { data: createdContent } = await ContentAPI.store(courseId, moduleId, payload);

        const newContents = [...currentModule.contents, createdContent];
        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({
          ...course,
          modules: newModules,
        });

        Toast(`Conteúdo cadastrado com sucesso`);

        history.push(
          `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${createdContent.id}/edit-task`
        );
      }

      if (contentId) {
        const dateFormatted = dayjs(form.deliveryDate + '20:59:58').format('YYYY-MM-DDTHH:mm:ss');

        setForm({ ...form, deliveryDate: dateFormatted });

        let archiveName;

        if (typeof archive !== 'string') {
          const { newFileName } = await UploadHelper.upload(archive, 'archives');

          setForm({ ...form, file: encodeURI(newFileName) });

          archiveName = newFileName;
        } else {
          archiveName = archive;
        }

        const payload = { ...form, filePDF: archiveName };

        delete payload.file;
        delete payload.download;

        const { data: updatedContent } = await ContentAPI.update(
          courseId,
          moduleId,
          contentId,
          payload
        );

        const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

        const newContents = currentModule.contents.map(content => {
          if (content.id === parseInt(contentId)) {
            return {
              ...content,
              title: updatedContent.title,
              contentType: updatedContent.contentType,
              order: updatedContent.order,
              download: updatedContent.download,
              content: updatedContent.content,
              hasGrade: updatedContent.hasGrade,
              deliveryDate: dayjs(updatedContent.deliveryDate).format('YYYY-MM-DD'),
            };
          }

          return content;
        });

        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({
          ...course,
          modules: newModules,
        });

        setArchive(updatedContent.download);
      }

      Toast(`Conteúdo ${!contentId ? 'cadastrado' : 'atualizado'} com sucesso`);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();

    try {
      setIsLoading(true);
      await ContentAPI.delete(courseId, moduleId, contentId);

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      const newContents = currentModule.contents.filter(
        module => module.id !== parseInt(contentId)
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      history.push(`/courses/${courseId}/course-manager/course`);
      setIsLoading(false);
      setIsShowModal(false);
      Toast(`Conteúdo excluído com sucesso`);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsShowModal(false);
      setIsLoading(false);
      hideErrorMessage();
    }
  }

  function handleFocus() {
    errorMessageRef.current &&
      errorMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
  }

  function hideErrorMessage() {
    setTimeout(function () {
      setErrorMessage('');
    }, 5000);
  }

  return (
    <div className="mx-5">
      <Heading as="h4" mt={1} mb={4} fontWeight="medium">
        {form.title || 'Nova Tarefa'}
      </Heading>

      <form onSubmit={handleSubmit}>
        <div className="form-background p-5 round-xl shadow-sm border border-gray bg-white mb-5">
          <div className="mb-4">
            <p className="info" style={{ wordBreak: 'break-word', lineHeight: '1.5' }}>
              Disponibilize suas tarefas aqui.
              <b style={{ fontWeight: 600 }}> Atividades, trabalhos e afins</b>, etc, sejam elas em
              <b style={{ fontWeight: 600 }}>
                {' '}
                arquivos no formato PDF, JPG, PNG, Excel, Word, MP3, WAV, MPEG, WEBP, BMP,
                PowerPoint
              </b>
              , e que estarão disponíveis para download.
            </p>
          </div>

          <div className="form-group">
            <label className="h6" htmlFor="title">
              Criação de Tarefas
            </label>
            <input
              value={form.title}
              onChange={handleChange}
              placeholder="Digite um título para a tarefa"
              name="title"
              id="title"
              type="text"
              className="form-control input-content"
              required
              minLength={1}
              maxLength={255}
            ></input>
          </div>

          <div className="form-group mb-4">
            <label className="h6">Descrição:</label>
            <EditorRichText value={editorState} onChange={handleEditorStateChange} />
          </div>

          {!form.file && (
            <CustomDropzone
              file={archive}
              isDisabled={isLoading}
              accept={[
                'application/pdf',
                'application/vnd.mspowerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/excel',
                'audio/mp3',
                'audio/mpeg',
                'audio/wav',
                'audio/ogg',
                'text/plain',
                'image/jpeg',
                'image/png',
                'image/webp',
                'image/bmp',
              ]}
              onDrop={handleDropFile}
              onDropRejected={() => setErrorMessage('Arquivo inválido!')}
              extensions="PDF, JPG, PNG, Excel, Word, MP3, WAV, MPEG, WEBP, BMP, PowerPoint."
              maxFileSize="25 MB"
            />
          )}

          {form.file && <PreviewFile file={archive} />}

          <DataLoading loading={isLoading} className="d-flex justify-content-center mt-4">
            {errorMessage && !isLoading && (
              <div className="mt-4">
                <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
                  <div className="text-center">{errorMessage}</div>
                </div>
              </div>
            )}
          </DataLoading>

          <div className="container">
            <div className="row justify-content-between">
              <div className="mt-2">
                <label className="h6 mt-2" htmlFor="eventType">
                  Data de Entrega{' '}
                  <span style={{ color: 'gray' }}>
                    {' '}
                    <i> (limite para entrega de avaliação) </i>{' '}
                  </span>{' '}
                  :
                </label>
                <Input
                  type="date"
                  name="deliveryDate"
                  value={form.deliveryDate}
                  onChange={handleChange}
                  min={dayjs().format('YYYY-MM-DD')}
                />
              </div>
              <div className="mt-3">
                <label className="h6" htmlFor="eventType">
                  Haverá nota?
                </label>
                <div className="row" style={{ fontSize: 12, marginTop: -8 }}>
                  <div className="mt-md-3 mt-lg-1 col-md-10 col-lg-6">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        onClick={handleChange}
                        name="hasGrade"
                        id="inlineRadio1"
                        value={'true'}
                        checked={form.hasGrade === 'true'}
                      />
                      <label className="ml-1 form-check-label" htmlFor="inlineRadio1">
                        <strong className="d-flex">Sim</strong>
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="hasGrade"
                        onClick={handleChange}
                        id="inlineRadio2"
                        value={'false'}
                        checked={form.hasGrade === 'false'}
                      />
                      <label className="ml-1 form-check-label" htmlFor="inlineRadio1">
                        <strong className="d-flex">Não</strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-4">
            <div>
              {contentId && (
                <button
                  onClick={event => {
                    event.preventDefault();
                    setIsShowModal(true);
                  }}
                  className="btn btn-outline-danger"
                >
                  Excluir conteúdo
                </button>
              )}
            </div>
            <div className="d-flex align-center justify-content-end">
              <button onClick={handleDiscardChanges} className="btn btn-light ml-5">
                Descartar mudanças
              </button>
              <button type="submit" className="btn btn-primary ml-4" disabled={!changed}>
                Salvar
              </button>
            </div>
          </div>
        </div>
      </form>

      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="mb-1 py-4">
            <h3 className="text-center">Você tem certeza que deseja excluir o conteúdo?</h3>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <button onClick={() => setIsShowModal(false)} className="btn btn-light mr-4">
              Cancelar
            </button>
            <button onClick={handleDelete} className="btn btn-danger" disabled={isLoading}>
              {isLoading ? <Spinner small /> : 'Excluir'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
