import { Box, Image, Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import GridView from '../../../../assets/images/buttonsView/GridView.svg';
import ListView from '../../../../assets/images/buttonsView/ListView.svg';
import Spinner from '../../../../components/Loadings/Spinner';
import Toast from '../../../../components/Toast';
import { useTheme } from '../../../../contexts/ThemeContext';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import ErrorMessage from '../components/ErrorMessage';
import Line from '../components/Line';
import Tooltip from '../components/Tooltip';
import Section from '../Section';
import styles from './styles.module.css';

function Products({ section }) {
  const [isActiveSection, setIsActiveSection] = useState(null);

  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [viewMode, setViewMode] = useState('list');
  const [tabIndex, setTabIndex] = useState(0);

  const { theme, isLoading: isLoadingTheme, updateProductsSection } = useTheme();

  useEffect(() => {
    if (!isLoadingTheme) {
      if (section) {
        setViewMode(section.viewMode);
        setTabIndex(section.viewMode === 'grid' ? 1 : 0);
        setIsActiveSection(section.active);
      }
    }
  }, [isLoadingTheme, section]);

  function handleDiscardChanges(event) {
    event.preventDefault();

    setHasChanged(false);

    setIsActiveSection(section.active);
  }

  function toggleVisibilitySection(event) {
    const { checked } = event.target;

    setHasChanged(true);

    setIsActiveSection(!checked);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    setErrorMessage('');

    const theme_id = theme.id;

    const payload = {
      activeSection: Boolean(isActiveSection),
      viewMode,
    };

    try {
      await updateProductsSection(theme_id, payload);

      Toast('Seção de produtos atualizada com sucesso');
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
      setIsLoading(false);
    }
  }

  function viewModeHandleChange(index) {
    if (index === 1) {
      setViewMode('grid');
      setTabIndex(index);
      setHasChanged(true);
      return;
    }

    setViewMode('list');
    setTabIndex(index);
    setHasChanged(true);
  }

  return (
    <Section>
      <Box display="flex" justifyContent="space-between">
        <Box w="100%" display="flex" justifyContent="left" alignItems="center">
          <Text fontSize="2xl" fontWeight="600" pb={2}>
            Todos os Produtos
          </Text>
          <Line />
          <Tooltip link="https://youtu.be/hTMrAN0LlGg" />
        </Box>

        <Box w="100%" display="flex" justifyContent="right" alignItems="center">
          <Text fontSize="lg" fontWeight="600" mr={5}>
            Modo de exibição:
          </Text>
          <Box className={styles.cardView}>
            <Tabs
              index={tabIndex}
              onChange={index => viewModeHandleChange(index)}
              align="center"
              variant="unstyled"
            >
              <TabList>
                <Tab _selected={{ bg: 'rgb(235, 113, 41);' }}>
                  <Image src={ListView} boxSize="20px" alt="ListView" />
                </Tab>
                <Tab _selected={{ bg: 'rgb(235, 113, 41);' }}>
                  <Image src={GridView} boxSize="20px" alt="GridView" />
                </Tab>
              </TabList>
            </Tabs>
          </Box>
        </Box>
      </Box>

      <div className={styles.productsContainer}>
        <div className={styles.containerHeader}>
          <p className={styles.headerText}>
            Nesta seção irão aparecer os novos produtos cadastrados em sua plataforma. Você pode
            oculta-lá caso não deseje que essa seção apareça em sua plataforma.
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <div className={styles.actions}>
            <button
              className={styles.cancelButton}
              onClick={event => handleDiscardChanges(event)}
              disabled={!hasChanged || isLoading}
            >
              Cancelar
            </button>

            <button type="submit" disabled={!hasChanged || isLoading}>
              {isLoading ? <Spinner small /> : 'Salvar alterações'}
            </button>
          </div>

          <div className={styles.footer}>
            <div>
              <input
                type="checkbox"
                id="isActiveSectionProducts"
                name="isActiveSectionProducts"
                onChange={toggleVisibilitySection}
                checked={!isActiveSection}
              />
              <label htmlFor="isActiveSectionProducts">Ocultar seção da página inicial</label>
            </div>
          </div>
        </form>
      </div>

      {errorMessage && !hasChanged && <ErrorMessage message={errorMessage} />}
    </Section>
  );
}

export default Products;
