import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { MdKeyboardArrowLeft as ArrowLeft } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import ContentAPI from '../../../../../api/Content';
import CourseAPI from '../../../../../api/Course';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import useQuery from '../../../../../hooks/useQuery';
import DataLoading from '../../../../../components/Loadings/DataLoading';
import DotsFalling from '../../../../../components/Loadings/DotsFalling';
import Pagination from '../../../../../components/Pagination';
import Toast from '../../../../../components/Toast';
import Search from './components/Search';
import TaskItem from './TaskItem';
import { Container, Controls, Description, Dot, Header, Table } from './styles';
import ModuleAPI from '../../../../../api/Module';
import { Task } from './types';

type Module = {
  id: number;
  name: string;
};

interface ICourse {
  name: string;
  modules: Module[];
}

interface ContentType {
  id: number;
  title: string;
}

type Params = {
  id: string;
};

const CONTENT_TYPE = 'TAREFA';
const PER_PAGE = 10;

const initialStateTasks = {
  filteredTasks: [],
};

function CorrectionTask() {
  const { id: courseId } = useParams<Params>();
  const history = useHistory();
  const query = useQuery();

  const [course, setCourse] = useState<ICourse>();

  const [tasks, setTasks] = useState<Task[]>([]);
  const [modules, setModules] = useState([]);
  const [contentTypes, setContentTypes] = useState<ContentType[]>([]);

  const [filter, setFilter] = useState(initialStateTasks);

  const [search, setSearch] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [moduleId, setModuleId] = useState<number>(0);
  const [contentId, setContentId] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModules, setIsLoadingModules] = useState(false);
  const [isLoadingContents, setIsLoadingContents] = useState(false);

  useEffect(() => {
    if (!modules[0]?.id) return;
    setModuleId(modules[0]?.id);
  }, [modules]);

  async function fetchCourse(id: string) {
    try {
      const { data: courseData } = await CourseAPI.show(Number(id));

      if (courseData) {
        setCourse(courseData);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  useEffect(() => {
    if (courseId) {
      fetchCourse(courseId);
    }
  }, [courseId]);

  async function fetchModules(courseId: string) {
    try {
      setIsLoadingModules(true);
      const { data: moduleData } = await ModuleAPI.getModules(Number(courseId));

      if (moduleData) {
        setModules(moduleData);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingModules(false);
    }
  }

  useEffect(() => {
    if (courseId) {
      fetchModules(courseId);
    }
  }, [courseId]);

  const fetchContentType = useCallback(
    async (moduleId: number, courseId: string, contentType: string) => {
      try {
        setIsLoadingContents(true);
        const { data: contentData } = await ContentAPI.contentType.index(
          moduleId,
          Number(courseId),
          contentType
        );

        if (contentData) {
          setContentTypes(contentData);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoadingContents(false);
      }
    },
    []
  );

  useEffect(() => {
    if (moduleId) {
      fetchContentType(moduleId, courseId, CONTENT_TYPE);
    }
  }, [moduleId, courseId, fetchContentType]);

  const fetchTasks = useCallback(
    async () => {
      try {
        setIsLoading(true);
        const { data: taskData } = await ContentAPI.tasks.index(
          contentId,
          moduleId,
          Number(courseId),
          currentPage,
          search,
          PER_PAGE,
          currentStatus
        );

        if (taskData) {
          setTasks(taskData.tasks);
          setPageCount(taskData.total / taskData.per_page);
          setFilter(prevFilter => ({ ...prevFilter, filteredTasks: taskData.tasks }));
        } else {
          setFilter({ ...filter, filteredTasks: [] });
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentId, moduleId, courseId, search, currentPage, currentStatus]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!moduleId) return '';
      if (tasks || search) {
        fetchTasks();
      } else {
        setFilter(prevFilter => ({ ...prevFilter, filteredTasks: tasks.sort() }));
      }
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId, search, fetchTasks]);

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setSearch(value);
  }

  function comparedModulesId(value: string) {
    const modules = course?.modules.map(module => module);
    const currentModule = modules?.find(currentModule => currentModule.id === Number(value));

    return currentModule.id;
  }

  function comparedContentsId(value: string) {
    if (!value) return '';

    const contents = contentTypes.map(content => content);
    const currentContent = contents?.find(currentContent => currentContent.id === Number(value));

    return String(currentContent.id);
  }

  function comparedStatus(value: string) {
    const currentStatus = tasks?.find(task => task.status === value);

    return currentStatus;
  }

  function handleChangeModule(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    const moduleId = comparedModulesId(value);

    setModuleId(moduleId);
  }

  function handleChangeContent(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    const contentId = comparedContentsId(value);

    setContentId(contentId);
  }

  function handleChangeStatus(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    if (value === 'ATIVO') {
      comparedStatus(value);
      setCurrentStatus(value);
    }

    if (value === 'EM_EDICAO') {
      comparedStatus(value);
      setCurrentStatus(value);
    }
  }

  useEffect(() => {
    let page: string | number = query.get('page');

    if (page) {
      try {
        page = Number(page);

        if (page < 1) {
          return history.push('/courses');
        }
        setCurrentPage(page);
      } catch (error) {
        history.push('/courses');
      }
    }
  }, [history, query]);

  function handlePageChange({ selected: selectedPage }) {
    history.push(`/courses/${courseId}/correction-task?page=${selectedPage + 1}`);
  }

  function TaskList() {
    if (isLoading) {
      return (
        <Dot>
          <DotsFalling />
        </Dot>
      );
    }

    return (
      <>
        {filter.filteredTasks?.map(task => (
          <TaskItem key={task.id} task={task} fetchTasks={fetchTasks} />
        ))}
      </>
    );
  }

  return (
    <>
      <Header to="/courses">
        <ArrowLeft />
        <h3>{course?.name}</h3>
      </Header>
      <Description>
        Esse é um ambiente para você corrigir as atividades enviadas pelos alunos.
      </Description>
      <Controls>
        <Search
          id="search"
          name="search"
          placeholder="Pesquisar"
          value={search}
          onChange={handleChangeSearch}
        />
        <DataLoading className="data-loading" loading={isLoadingModules}>
          <select name="modules" id="modules" onChange={handleChangeModule}>
            {modules?.map(module => (
              <option key={module.id} value={module.id}>
                {module.name}
              </option>
            ))}
          </select>
        </DataLoading>
        <DataLoading className="data-loading" loading={isLoadingContents}>
          <select name="contents" id="contents" onChange={handleChangeContent}>
            <option value="">Tudo</option>
            {contentTypes.map(content => (
              <option key={content.id} value={content.id}>
                {content.title}
              </option>
            ))}
          </select>
        </DataLoading>
        <select name="status" id="status" onChange={handleChangeStatus}>
          <option value="ATIVO">ATIVO</option>
          <option value="EM_EDICAO">EM EDIÇÃO</option>
        </select>
      </Controls>

      <Container>
        <Table>
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Módulo</th>
              <th scope="col">Conteúdo</th>
              <th scope="col">Status</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>
            <TaskList />
          </tbody>
        </Table>
      </Container>

      <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
    </>
  );
}

export default CorrectionTask;
