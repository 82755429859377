import { axiosBackend } from './Configuration';

interface Payload {
  visibility: string;
  automaticAffiliation: boolean;
  typeOfAffiliateCommission: string | number;
  valueByTheAffiliateCommission: number;
  instructionsForAffiliates: string;
  hasRecruitmentCommission: boolean;
  typeOfRecruitmentCommission: string | number;
  valueByTheRecruitmentCommission: number;
  maximumQuantityOfSales: number;
  salesPageUrl: string;
  checkoutPageUrl: string;
}

interface IQuerys {
  productId: string;
  page: number;
  search: string;
  per_page: number;
  statusName: string;
}

interface ISendMail {
  productId: string;
  email: string;
  userId: string;
}

const KursusAPI = {
  getUserInfos: async () => {
    try {
      const { data: userInfoData } = await axiosBackend().get('/kursus/user-infos');

      return userInfoData;
    } catch (error) {
      throw error;
    }
  },

  createProduct: async (course_id: string, payload: Payload) => {
    try {
      const { data: productAffiliationData } = await axiosBackend().post(
        `/kursus/products/${course_id}`,
        payload
      );

      return productAffiliationData;
    } catch (error) {
      throw error;
    }
  },

  updateProduct: async (course_id: string, payload: Payload) => {
    try {
      const { data: productAffiliationData } = await axiosBackend().patch(
        `/kursus/products/${course_id}`,
        payload
      );

      return productAffiliationData;
    } catch (error) {
      throw error;
    }
  },

  findProducts: async ({
    page = 1,
    search,
    per_page = 10,
  }: Omit<IQuerys, 'productId' | 'statusName'>) => {
    try {
      const { data: productAffiliationsData } = await axiosBackend().get(
        `/kursus/products?page=${page}${search ? `&search=${search}` : ``}${
          per_page && `&per_page=${per_page}`
        }`
      );

      return productAffiliationsData;
    } catch (error) {
      throw error;
    }
  },

  findProductById: async (productId: string) => {
    try {
      const { data: productAffiliationData } = await axiosBackend().get(
        `/kursus/products/${productId}`
      );

      return productAffiliationData;
    } catch (error) {
      throw error;
    }
  },

  sendAffiliateInvitation: async ({ productId, email, userId }: ISendMail) => {
    try {
      const { data: sendMailData } = await axiosBackend().post(
        '/kursus/affiliations/send-affiliate-invitation',
        { productId, email, recruiterAffiliateId: userId }
      );

      return sendMailData;
    } catch (error) {
      throw error;
    }
  },

  affiliatesByProduct: async ({
    productId = '',
    page = 1,
    search = '',
    per_page = 10,
    statusName = '',
  }: IQuerys) => {
    try {
      const { data: affiliatesData } = await axiosBackend().get(
        `/kursus/affiliation-by-product/${productId}/course?page=${page}${
          search ? `&search=${search}` : ``
        }${per_page && `&per_page=${per_page}`}${statusName && `&statusName=${statusName}`}`
      );

      return affiliatesData;
    } catch (error) {
      throw error;
    }
  },

  affiliateAcceptOrReject: async (affiliationId: string, status: string) => {
    try {
      const { data: affiliatesData } = await axiosBackend().patch(
        `/kursus/affiliation-accept-or-reject/${affiliationId}/affiliation`,
        { status }
      );

      return affiliatesData;
    } catch (error) {
      throw error;
    }
  },
};

export default KursusAPI;
