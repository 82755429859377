import React, { memo } from 'react';
import styles from './styles.module.css';

import noDataImg from '../../../../assets/images/illustrations/dashboard-no-data.svg';

function NoData() {
  return (
    <div className={`${styles.noDataContainer} animate-left`}>
      <img src={noDataImg} alt='Sem dados' />
      <h6>Você ainda não possui transações.</h6>
    </div>
  );
}

export default memo(NoData);
