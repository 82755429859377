import { CopyIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  useClipboard,
} from '@chakra-ui/react';
import { FaRegCheckCircle } from 'react-icons/fa';
import backgroundImage from '../../../assets/images/backgrounds/box_colors.png';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import { TOKEN_PURCHASE, UTM_LOCAL_STORAGE } from '../../../helpers/LocalStorageHelper';

const DownloadButton = ({ boletoLink }) => {
  const handleDownload = () => {
    // Cria um elemento de link temporário
    const link = document.createElement('a');
    link.href = boletoLink;
    link.setAttribute('target', '_blank');
    link.setAttribute('download', 'boleto.pdf');

    // Simula um clique no elemento de link temporário para iniciar o download
    document.body.appendChild(link);
    link.click();

    // Remove o elemento de link temporário
    document.body.removeChild(link);
  };

  return (
    <Button
      width="180px"
      bgColor="#EC7117"
      _hover={{ bgColor: '#c15000' }}
      color="#FFF"
      onClick={handleDownload}
    >
      Baixar boleto
    </Button>
  );
};

export default function BoletoGeneratedPage() {
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const boleto_code = searchParams.get('boleto_code');
  const boleto_expiration_date = searchParams.get('boleto_expiration_date');
  const boleto_link = searchParams.get('boleto_link');
  const total_value = searchParams.get('total_value');

  const purchaseToken = localStorage.getItem(TOKEN_PURCHASE);

  const { onCopy, value, setValue, hasCopied } = useClipboard(boleto_code);

  const fontSize: string = useBreakpointValue({
    base: '70px',
    lg: '80px',
  });

  useEffect(() => {
    if (purchaseToken) {
      localStorage.removeItem(TOKEN_PURCHASE);
    }
  }, [purchaseToken]);

  useEffect(() => {
    const utmInitialParams = {
      utm_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null,
      utm_content: null,
    };

    localStorage.setItem(UTM_LOCAL_STORAGE, JSON.stringify(utmInitialParams));
  }, []);

  return (
    <Stack minHeight="100dvh">
      <Flex
        flex={1}
        px={5}
        paddingTop={{ base: '15px' }}
        backgroundImage={backgroundImage}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="cover"
        justify="center"
        align="center"
      >
        <Stack
          width="full"
          alignItems="center"
          spacing="30px"
          maxWidth={{ base: 'container.sm', lg: 'container.md' }}
        >
          <Stack alignItems="center">
            <FaRegCheckCircle fontSize={fontSize} color="#EC7117" />

            <Heading
              as="h1"
              color="#EC7117"
              fontSize={{ base: '32px', lg: '40px' }}
              textAlign="center"
              mx={{ base: 5 }}
            >
              Pedido realizado com sucesso!
            </Heading>
          </Stack>

          <Stack fontWeight="500" spacing="15px" fontSize="md" textAlign="center">
            <Text>
              Seu pedido foi{' '}
              <Text as="span" fontWeight="600">
                processado e o boleto bancário para pagamento foi gerado.
              </Text>{' '}
              Após o pagamento{' '}
              <Text as="span" fontWeight="600">
                você receberá um e-mail
              </Text>{' '}
              com as informações de confirmação de compra e orientações de acesso ao seu produto.
            </Text>
            <Text>Para sua maior comodidade, o boleto será enviado para o seu e-mail também.</Text>
            <Text fontWeight="600">Consulte seu e-mail para obter detalhes sobre sua compra.</Text>
          </Stack>

          <Stack width="full" spacing={{ base: '10px', lg: '15px' }}>
            <Stack width="full" spacing={{ base: '50px', lg: '30px' }} direction="row">
              <Stack width="full" fontWeight="600" alignItems="end">
                <Text fontSize={{ base: '16px', lg: '20px' }}>Valor a pagar:</Text>
                <Text fontSize={{ base: '24px', lg: '32px' }} color="#EC7117">
                  {convertToBrazilianCurrency(total_value)}
                </Text>
              </Stack>
              <Stack width="full" fontWeight="600" alignItems="start">
                <Text fontSize={{ base: '16px', lg: '20px' }}>Vencimento:</Text>
                <Text fontSize={{ base: '24px', lg: '32px' }} color="#EC7117">
                  {dayjs(boleto_expiration_date).format('DD/MM/YYYY')}
                </Text>
              </Stack>
            </Stack>

            <Divider />

            <Flex>
              <Input
                bgColor="#e9e9e9"
                value={value}
                isReadOnly
                border="none"
                onChange={e => {
                  setValue(e.target.value);
                }}
                borderRightRadius="0"
                mr="1px"
              />
              <Button
                borderLeftRadius="0"
                width="200px"
                fontWeight="400"
                bgColor="#e9e9e9"
                leftIcon={<CopyIcon boxSize="14px" />}
                onClick={onCopy}
                fontSize={{ base: 'small', lg: 'medium' }}
              >
                {hasCopied ? 'Copiado!' : 'Copiar código'}
              </Button>
            </Flex>

            <Box width="full" display="flex" justifyContent="center" padding="5px 0">
              {DownloadButton({ boletoLink: boleto_link })}
            </Box>
          </Stack>
        </Stack>
      </Flex>

      {/* FOOTER */}
      <Flex as="footer" justify="center" w="full">
        <Link
          fontSize="md"
          w="full"
          h="full"
          textAlign="center"
          py={5}
          href="https://proluno.me/"
          target="_blank"
          isExternal
        >
          Tecnologia 🧡 Proluno - Ltda.
        </Link>
      </Flex>
    </Stack>
  );
}
