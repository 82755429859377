import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../../hooks/useWindowSize';
import TabOfFreeQuestions from './TabOfFreeQuestions';

export default function ExternalQuestion() {
  const [tabIndex] = useState(0);
  const { width } = useWindowSize();
  const hiddenButton = width < 767;

  return (
    <Box>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
        alignItems={{ base: 'self-start', md: 'center', lg: 'center' }}
        width="full"
      >
        <Heading color="#202123" fontWeight={600}>
          Questões externas
        </Heading>

        <Button
          size="sm"
          colorScheme="orange"
          as={Link}
          to="/tools/external-question/add-questions"
          hidden={hiddenButton}
        >
          <Stack direction="row" alignItems="center">
            <BiPlus size={14} />
            <Text>Adicionar questões</Text>
          </Stack>
        </Button>
      </Stack>

      <Text
        marginTop="20px"
        marginBottom="30px"
        color="rgba(32, 33, 35, 0.50)"
        textAlign="justify"
        fontSize="18px"
        fontWeight="400"
        lineHeight="normal"
      >
        Selecione as questões que irão ficar disponíveis de forma gratuita para os seus alunos na
        página inicial do seu site, na aba “questões”.
      </Text>

      <Button
        marginBottom="32px"
        size="sm"
        colorScheme="orange"
        as={Link}
        to="/tools/external-question/add-questions"
        width="full"
        hidden={!hiddenButton}
      >
        <Stack direction="row" alignItems="center">
          <BiPlus size={14} />
          <Text>Adicionar questões</Text>
        </Stack>
      </Button>

      <TabOfFreeQuestions tabIndex={tabIndex} />

      {/* <Tabs colorScheme="orange" onChange={index => setTabIndex(index)} isLazy>
        <TabList>
          <Tab
            color="rgba(32, 33, 35, 0.50)"
            _active={{ color: '#EB7129' }}
            _selected={{ color: '#EB7129' }}
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
          >
            Gratuitas
          </Tab>
          <Tab
            color="rgba(32, 33, 35, 0.50)"
            _active={{ color: '#EB7129' }}
            _selected={{ color: '#EB7129' }}
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
          >
            Pagas
          </Tab>
        </TabList>

        <TabIndicator mt="-1.5px" height="3px" bg="#EB7129" borderRadius="2px" />

        <TabPanels>
          <TabOfFreeQuestions tabIndex={tabIndex} />
          <TabOfPaidQuestions tabIndex={tabIndex} />
        </TabPanels>
      </Tabs> */}
    </Box>
  );
}
