import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const defaultStyles = css`
  color: #202123;
`;

export const Header = styled(Link)`
  ${defaultStyles};
  margin: 1.5rem 0 0 0;

  display: flex;
  align-items: center;

  > svg {
    width: 2rem;
    flex-shrink: 0;
  }
`;

export const Description = styled.p`
  ${defaultStyles};
  margin: 1rem 0 0 0;

  font-size: 1.15rem;
  line-height: 1.4;
`;

export const Controls = styled.div`
  margin: 2.5rem 0;

  display: grid;
  align-items: stretch;
  grid-gap: 1.5rem;

  > select,
  .data-loading {
    height: 2.75rem;
    padding: 0.5rem 0.4rem;
    min-width: 8rem;
    background: #f6f5f5;
    border: 1px solid #20212340;
  }

  .data-loading {
    padding-left: 1rem;
  }

  > select {
    color: #20212380;

    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 100%;
    max-width: 1024px;
    display: flex;

    > input {
      width: 16rem;
    }

    > select {
      width: 10rem;
      height: auto;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: block;
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  padding: 0 0 1rem 0;

  border-collapse: separate;
  border-spacing: 0 0.5rem;

  thead {
    height: 3.4rem;
    margin: 0 0 0.5rem 0;
    padding: 0 1rem;

    font-size: 1rem;

    color: #ffffff;
    background: #eb7129;

    tr {
      > th {
        font-weight: 500;

        &:first-child {
          padding: 0 0 0 3.8rem;
        }
      }
    }
  }

  tbody {
    position: relative;
  }
`;

export const Dot = styled.div`
  position: absolute;
  left: 50%;
`;
