const states = [
  {
    id: 11,
    sigla: 'RO',
    nome: 'Rondônia',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 12,
    sigla: 'AC',
    nome: 'Acre',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 13,
    sigla: 'AM',
    nome: 'Amazonas',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 14,
    sigla: 'RR',
    nome: 'Roraima',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 15,
    sigla: 'PA',
    nome: 'Pará',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 16,
    sigla: 'AP',
    nome: 'Amapá',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 17,
    sigla: 'TO',
    nome: 'Tocantins',
    regiao: {
      id: 1,
      sigla: 'N',
      nome: 'Norte',
    },
  },
  {
    id: 21,
    sigla: 'MA',
    nome: 'Maranhão',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 22,
    sigla: 'PI',
    nome: 'Piauí',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 23,
    sigla: 'CE',
    nome: 'Ceará',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 24,
    sigla: 'RN',
    nome: 'Rio Grande do Norte',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 25,
    sigla: 'PB',
    nome: 'Paraíba',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 26,
    sigla: 'PE',
    nome: 'Pernambuco',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 27,
    sigla: 'AL',
    nome: 'Alagoas',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 28,
    sigla: 'SE',
    nome: 'Sergipe',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 29,
    sigla: 'BA',
    nome: 'Bahia',
    regiao: {
      id: 2,
      sigla: 'NE',
      nome: 'Nordeste',
    },
  },
  {
    id: 31,
    sigla: 'MG',
    nome: 'Minas Gerais',
    regiao: {
      id: 3,
      sigla: 'SE',
      nome: 'Sudeste',
    },
  },
  {
    id: 32,
    sigla: 'ES',
    nome: 'Espírito Santo',
    regiao: {
      id: 3,
      sigla: 'SE',
      nome: 'Sudeste',
    },
  },
  {
    id: 33,
    sigla: 'RJ',
    nome: 'Rio de Janeiro',
    regiao: {
      id: 3,
      sigla: 'SE',
      nome: 'Sudeste',
    },
  },
  {
    id: 35,
    sigla: 'SP',
    nome: 'São Paulo',
    regiao: {
      id: 3,
      sigla: 'SE',
      nome: 'Sudeste',
    },
  },
  {
    id: 41,
    sigla: 'PR',
    nome: 'Paraná',
    regiao: {
      id: 4,
      sigla: 'S',
      nome: 'Sul',
    },
  },
  {
    id: 42,
    sigla: 'SC',
    nome: 'Santa Catarina',
    regiao: {
      id: 4,
      sigla: 'S',
      nome: 'Sul',
    },
  },
  {
    id: 43,
    sigla: 'RS',
    nome: 'Rio Grande do Sul',
    regiao: {
      id: 4,
      sigla: 'S',
      nome: 'Sul',
    },
  },
  {
    id: 50,
    sigla: 'MS',
    nome: 'Mato Grosso do Sul',
    regiao: {
      id: 5,
      sigla: 'CO',
      nome: 'Centro-Oeste',
    },
  },
  {
    id: 51,
    sigla: 'MT',
    nome: 'Mato Grosso',
    regiao: {
      id: 5,
      sigla: 'CO',
      nome: 'Centro-Oeste',
    },
  },
  {
    id: 52,
    sigla: 'GO',
    nome: 'Goiás',
    regiao: {
      id: 5,
      sigla: 'CO',
      nome: 'Centro-Oeste',
    },
  },
  {
    id: 53,
    sigla: 'DF',
    nome: 'Distrito Federal',
    regiao: {
      id: 5,
      sigla: 'CO',
      nome: 'Centro-Oeste',
    },
  },
];

const cities = [
  {
    id: 1100015,
    nome: "Alta Floresta D'Oeste",
    microrregiao: {
      id: 11006,
      nome: 'Cacoal',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100023,
    nome: 'Ariquemes',
    microrregiao: {
      id: 11003,
      nome: 'Ariquemes',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110002,
      nome: 'Ariquemes',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100031,
    nome: 'Cabixi',
    microrregiao: {
      id: 11008,
      nome: 'Colorado do Oeste',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110006,
      nome: 'Vilhena',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100049,
    nome: 'Cacoal',
    microrregiao: {
      id: 11006,
      nome: 'Cacoal',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100056,
    nome: 'Cerejeiras',
    microrregiao: {
      id: 11008,
      nome: 'Colorado do Oeste',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110006,
      nome: 'Vilhena',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100064,
    nome: 'Colorado do Oeste',
    microrregiao: {
      id: 11008,
      nome: 'Colorado do Oeste',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110006,
      nome: 'Vilhena',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100072,
    nome: 'Corumbiara',
    microrregiao: {
      id: 11008,
      nome: 'Colorado do Oeste',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110006,
      nome: 'Vilhena',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100080,
    nome: 'Costa Marques',
    microrregiao: {
      id: 11002,
      nome: 'Guajará-Mirim',
      mesorregiao: {
        id: 1101,
        nome: 'Madeira-Guaporé',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100098,
    nome: "Espigão D'Oeste",
    microrregiao: {
      id: 11006,
      nome: 'Cacoal',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100106,
    nome: 'Guajará-Mirim',
    microrregiao: {
      id: 11002,
      nome: 'Guajará-Mirim',
      mesorregiao: {
        id: 1101,
        nome: 'Madeira-Guaporé',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110001,
      nome: 'Porto Velho',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100114,
    nome: 'Jaru',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110003,
      nome: 'Jaru',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100122,
    nome: 'Ji-Paraná',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100130,
    nome: "Machadinho D'Oeste",
    microrregiao: {
      id: 11003,
      nome: 'Ariquemes',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110003,
      nome: 'Jaru',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100148,
    nome: "Nova Brasilândia D'Oeste",
    microrregiao: {
      id: 11005,
      nome: "Alvorada D'Oeste",
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100155,
    nome: 'Ouro Preto do Oeste',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100189,
    nome: 'Pimenta Bueno',
    microrregiao: {
      id: 11007,
      nome: 'Vilhena',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100205,
    nome: 'Porto Velho',
    microrregiao: {
      id: 11001,
      nome: 'Porto Velho',
      mesorregiao: {
        id: 1101,
        nome: 'Madeira-Guaporé',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110001,
      nome: 'Porto Velho',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100254,
    nome: 'Presidente Médici',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100262,
    nome: 'Rio Crespo',
    microrregiao: {
      id: 11003,
      nome: 'Ariquemes',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110002,
      nome: 'Ariquemes',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100288,
    nome: 'Rolim de Moura',
    microrregiao: {
      id: 11006,
      nome: 'Cacoal',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100296,
    nome: "Santa Luzia D'Oeste",
    microrregiao: {
      id: 11006,
      nome: 'Cacoal',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100304,
    nome: 'Vilhena',
    microrregiao: {
      id: 11007,
      nome: 'Vilhena',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110006,
      nome: 'Vilhena',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100320,
    nome: 'São Miguel do Guaporé',
    microrregiao: {
      id: 11005,
      nome: "Alvorada D'Oeste",
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100338,
    nome: 'Nova Mamoré',
    microrregiao: {
      id: 11001,
      nome: 'Porto Velho',
      mesorregiao: {
        id: 1101,
        nome: 'Madeira-Guaporé',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110001,
      nome: 'Porto Velho',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100346,
    nome: "Alvorada D'Oeste",
    microrregiao: {
      id: 11005,
      nome: "Alvorada D'Oeste",
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100379,
    nome: 'Alto Alegre dos Parecis',
    microrregiao: {
      id: 11006,
      nome: 'Cacoal',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100403,
    nome: 'Alto Paraíso',
    microrregiao: {
      id: 11003,
      nome: 'Ariquemes',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110002,
      nome: 'Ariquemes',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100452,
    nome: 'Buritis',
    microrregiao: {
      id: 11001,
      nome: 'Porto Velho',
      mesorregiao: {
        id: 1101,
        nome: 'Madeira-Guaporé',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110002,
      nome: 'Ariquemes',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100502,
    nome: 'Novo Horizonte do Oeste',
    microrregiao: {
      id: 11006,
      nome: 'Cacoal',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100601,
    nome: 'Cacaulândia',
    microrregiao: {
      id: 11003,
      nome: 'Ariquemes',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110002,
      nome: 'Ariquemes',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100700,
    nome: 'Campo Novo de Rondônia',
    microrregiao: {
      id: 11001,
      nome: 'Porto Velho',
      mesorregiao: {
        id: 1101,
        nome: 'Madeira-Guaporé',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110002,
      nome: 'Ariquemes',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100809,
    nome: 'Candeias do Jamari',
    microrregiao: {
      id: 11001,
      nome: 'Porto Velho',
      mesorregiao: {
        id: 1101,
        nome: 'Madeira-Guaporé',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110001,
      nome: 'Porto Velho',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100908,
    nome: 'Castanheiras',
    microrregiao: {
      id: 11006,
      nome: 'Cacoal',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100924,
    nome: 'Chupinguaia',
    microrregiao: {
      id: 11007,
      nome: 'Vilhena',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110006,
      nome: 'Vilhena',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1100940,
    nome: 'Cujubim',
    microrregiao: {
      id: 11001,
      nome: 'Porto Velho',
      mesorregiao: {
        id: 1101,
        nome: 'Madeira-Guaporé',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110002,
      nome: 'Ariquemes',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101005,
    nome: 'Governador Jorge Teixeira',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110003,
      nome: 'Jaru',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101104,
    nome: 'Itapuã do Oeste',
    microrregiao: {
      id: 11001,
      nome: 'Porto Velho',
      mesorregiao: {
        id: 1101,
        nome: 'Madeira-Guaporé',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110001,
      nome: 'Porto Velho',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101203,
    nome: 'Ministro Andreazza',
    microrregiao: {
      id: 11006,
      nome: 'Cacoal',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101302,
    nome: 'Mirante da Serra',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101401,
    nome: 'Monte Negro',
    microrregiao: {
      id: 11003,
      nome: 'Ariquemes',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110002,
      nome: 'Ariquemes',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101435,
    nome: 'Nova União',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101450,
    nome: 'Parecis',
    microrregiao: {
      id: 11007,
      nome: 'Vilhena',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101468,
    nome: 'Pimenteiras do Oeste',
    microrregiao: {
      id: 11008,
      nome: 'Colorado do Oeste',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110006,
      nome: 'Vilhena',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101476,
    nome: 'Primavera de Rondônia',
    microrregiao: {
      id: 11007,
      nome: 'Vilhena',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101484,
    nome: "São Felipe D'Oeste",
    microrregiao: {
      id: 11007,
      nome: 'Vilhena',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110005,
      nome: 'Cacoal',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101492,
    nome: 'São Francisco do Guaporé',
    microrregiao: {
      id: 11002,
      nome: 'Guajará-Mirim',
      mesorregiao: {
        id: 1101,
        nome: 'Madeira-Guaporé',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101500,
    nome: 'Seringueiras',
    microrregiao: {
      id: 11005,
      nome: "Alvorada D'Oeste",
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101559,
    nome: 'Teixeirópolis',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101609,
    nome: 'Theobroma',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110003,
      nome: 'Jaru',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101708,
    nome: 'Urupá',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101757,
    nome: 'Vale do Anari',
    microrregiao: {
      id: 11003,
      nome: 'Ariquemes',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110003,
      nome: 'Jaru',
      'regiao-intermediaria': {
        id: 1101,
        nome: 'Porto Velho',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1101807,
    nome: 'Vale do Paraíso',
    microrregiao: {
      id: 11004,
      nome: 'Ji-Paraná',
      mesorregiao: {
        id: 1102,
        nome: 'Leste Rondoniense',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 110004,
      nome: 'Ji-Paraná',
      'regiao-intermediaria': {
        id: 1102,
        nome: 'Ji-Paraná',
        UF: { id: 11, sigla: 'RO', nome: 'Rondônia', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200013,
    nome: 'Acrelândia',
    microrregiao: {
      id: 12004,
      nome: 'Rio Branco',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120001,
      nome: 'Rio Branco',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200054,
    nome: 'Assis Brasil',
    microrregiao: {
      id: 12005,
      nome: 'Brasiléia',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120002,
      nome: 'Brasiléia',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200104,
    nome: 'Brasiléia',
    microrregiao: {
      id: 12005,
      nome: 'Brasiléia',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120002,
      nome: 'Brasiléia',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200138,
    nome: 'Bujari',
    microrregiao: {
      id: 12004,
      nome: 'Rio Branco',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120001,
      nome: 'Rio Branco',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200179,
    nome: 'Capixaba',
    microrregiao: {
      id: 12004,
      nome: 'Rio Branco',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120001,
      nome: 'Rio Branco',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200203,
    nome: 'Cruzeiro do Sul',
    microrregiao: {
      id: 12001,
      nome: 'Cruzeiro do Sul',
      mesorregiao: {
        id: 1201,
        nome: 'Vale do Juruá',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120004,
      nome: 'Cruzeiro do Sul',
      'regiao-intermediaria': {
        id: 1202,
        nome: 'Cruzeiro do Sul',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200252,
    nome: 'Epitaciolândia',
    microrregiao: {
      id: 12005,
      nome: 'Brasiléia',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120002,
      nome: 'Brasiléia',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200302,
    nome: 'Feijó',
    microrregiao: {
      id: 12002,
      nome: 'Tarauacá',
      mesorregiao: {
        id: 1201,
        nome: 'Vale do Juruá',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120005,
      nome: 'Tarauacá',
      'regiao-intermediaria': {
        id: 1202,
        nome: 'Cruzeiro do Sul',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200328,
    nome: 'Jordão',
    microrregiao: {
      id: 12002,
      nome: 'Tarauacá',
      mesorregiao: {
        id: 1201,
        nome: 'Vale do Juruá',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120005,
      nome: 'Tarauacá',
      'regiao-intermediaria': {
        id: 1202,
        nome: 'Cruzeiro do Sul',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200336,
    nome: 'Mâncio Lima',
    microrregiao: {
      id: 12001,
      nome: 'Cruzeiro do Sul',
      mesorregiao: {
        id: 1201,
        nome: 'Vale do Juruá',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120004,
      nome: 'Cruzeiro do Sul',
      'regiao-intermediaria': {
        id: 1202,
        nome: 'Cruzeiro do Sul',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200344,
    nome: 'Manoel Urbano',
    microrregiao: {
      id: 12003,
      nome: 'Sena Madureira',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120003,
      nome: 'Sena Madureira',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200351,
    nome: 'Marechal Thaumaturgo',
    microrregiao: {
      id: 12001,
      nome: 'Cruzeiro do Sul',
      mesorregiao: {
        id: 1201,
        nome: 'Vale do Juruá',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120004,
      nome: 'Cruzeiro do Sul',
      'regiao-intermediaria': {
        id: 1202,
        nome: 'Cruzeiro do Sul',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200385,
    nome: 'Plácido de Castro',
    microrregiao: {
      id: 12004,
      nome: 'Rio Branco',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120001,
      nome: 'Rio Branco',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200393,
    nome: 'Porto Walter',
    microrregiao: {
      id: 12001,
      nome: 'Cruzeiro do Sul',
      mesorregiao: {
        id: 1201,
        nome: 'Vale do Juruá',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120004,
      nome: 'Cruzeiro do Sul',
      'regiao-intermediaria': {
        id: 1202,
        nome: 'Cruzeiro do Sul',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200401,
    nome: 'Rio Branco',
    microrregiao: {
      id: 12004,
      nome: 'Rio Branco',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120001,
      nome: 'Rio Branco',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200427,
    nome: 'Rodrigues Alves',
    microrregiao: {
      id: 12001,
      nome: 'Cruzeiro do Sul',
      mesorregiao: {
        id: 1201,
        nome: 'Vale do Juruá',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120004,
      nome: 'Cruzeiro do Sul',
      'regiao-intermediaria': {
        id: 1202,
        nome: 'Cruzeiro do Sul',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200435,
    nome: 'Santa Rosa do Purus',
    microrregiao: {
      id: 12003,
      nome: 'Sena Madureira',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120003,
      nome: 'Sena Madureira',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200450,
    nome: 'Senador Guiomard',
    microrregiao: {
      id: 12004,
      nome: 'Rio Branco',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120001,
      nome: 'Rio Branco',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200500,
    nome: 'Sena Madureira',
    microrregiao: {
      id: 12003,
      nome: 'Sena Madureira',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120003,
      nome: 'Sena Madureira',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200609,
    nome: 'Tarauacá',
    microrregiao: {
      id: 12002,
      nome: 'Tarauacá',
      mesorregiao: {
        id: 1201,
        nome: 'Vale do Juruá',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120005,
      nome: 'Tarauacá',
      'regiao-intermediaria': {
        id: 1202,
        nome: 'Cruzeiro do Sul',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200708,
    nome: 'Xapuri',
    microrregiao: {
      id: 12005,
      nome: 'Brasiléia',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120002,
      nome: 'Brasiléia',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1200807,
    nome: 'Porto Acre',
    microrregiao: {
      id: 12004,
      nome: 'Rio Branco',
      mesorregiao: {
        id: 1202,
        nome: 'Vale do Acre',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 120001,
      nome: 'Rio Branco',
      'regiao-intermediaria': {
        id: 1201,
        nome: 'Rio Branco',
        UF: { id: 12, sigla: 'AC', nome: 'Acre', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300029,
    nome: 'Alvarães',
    microrregiao: {
      id: 13005,
      nome: 'Tefé',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130005,
      nome: 'Tefé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300060,
    nome: 'Amaturá',
    microrregiao: {
      id: 13003,
      nome: 'Alto Solimões',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130006,
      nome: 'Tabatinga',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300086,
    nome: 'Anamã',
    microrregiao: {
      id: 13006,
      nome: 'Coari',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130004,
      nome: 'Manacapuru',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300102,
    nome: 'Anori',
    microrregiao: {
      id: 13006,
      nome: 'Coari',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130003,
      nome: 'Coari',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300144,
    nome: 'Apuí',
    microrregiao: {
      id: 13013,
      nome: 'Madeira',
      mesorregiao: {
        id: 1304,
        nome: 'Sul Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130009,
      nome: 'Manicoré',
      'regiao-intermediaria': {
        id: 1303,
        nome: 'Lábrea',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300201,
    nome: 'Atalaia do Norte',
    microrregiao: {
      id: 13003,
      nome: 'Alto Solimões',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130006,
      nome: 'Tabatinga',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300300,
    nome: 'Autazes',
    microrregiao: {
      id: 13007,
      nome: 'Manaus',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130001,
      nome: 'Manaus',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300409,
    nome: 'Barcelos',
    microrregiao: {
      id: 13001,
      nome: 'Rio Negro',
      mesorregiao: {
        id: 1301,
        nome: 'Norte Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130002,
      nome: 'São Gabriel da Cachoeira',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300508,
    nome: 'Barreirinha',
    microrregiao: {
      id: 13010,
      nome: 'Parintins',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130010,
      nome: 'Parintins',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300607,
    nome: 'Benjamin Constant',
    microrregiao: {
      id: 13003,
      nome: 'Alto Solimões',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130006,
      nome: 'Tabatinga',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300631,
    nome: 'Beruri',
    microrregiao: {
      id: 13006,
      nome: 'Coari',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130003,
      nome: 'Coari',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300680,
    nome: 'Boa Vista do Ramos',
    microrregiao: {
      id: 13010,
      nome: 'Parintins',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130010,
      nome: 'Parintins',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300706,
    nome: 'Boca do Acre',
    microrregiao: {
      id: 13011,
      nome: 'Boca do Acre',
      mesorregiao: {
        id: 1304,
        nome: 'Sul Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130008,
      nome: 'Lábrea',
      'regiao-intermediaria': {
        id: 1303,
        nome: 'Lábrea',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300805,
    nome: 'Borba',
    microrregiao: {
      id: 13013,
      nome: 'Madeira',
      mesorregiao: {
        id: 1304,
        nome: 'Sul Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130001,
      nome: 'Manaus',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300839,
    nome: 'Caapiranga',
    microrregiao: {
      id: 13006,
      nome: 'Coari',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130004,
      nome: 'Manacapuru',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1300904,
    nome: 'Canutama',
    microrregiao: {
      id: 13012,
      nome: 'Purus',
      mesorregiao: {
        id: 1304,
        nome: 'Sul Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130008,
      nome: 'Lábrea',
      'regiao-intermediaria': {
        id: 1303,
        nome: 'Lábrea',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301001,
    nome: 'Carauari',
    microrregiao: {
      id: 13004,
      nome: 'Juruá',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130005,
      nome: 'Tefé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301100,
    nome: 'Careiro',
    microrregiao: {
      id: 13007,
      nome: 'Manaus',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130001,
      nome: 'Manaus',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301159,
    nome: 'Careiro da Várzea',
    microrregiao: {
      id: 13007,
      nome: 'Manaus',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130001,
      nome: 'Manaus',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301209,
    nome: 'Coari',
    microrregiao: {
      id: 13006,
      nome: 'Coari',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130003,
      nome: 'Coari',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301308,
    nome: 'Codajás',
    microrregiao: {
      id: 13006,
      nome: 'Coari',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130003,
      nome: 'Coari',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301407,
    nome: 'Eirunepé',
    microrregiao: {
      id: 13004,
      nome: 'Juruá',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130007,
      nome: 'Eirunepé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301506,
    nome: 'Envira',
    microrregiao: {
      id: 13004,
      nome: 'Juruá',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130007,
      nome: 'Eirunepé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301605,
    nome: 'Fonte Boa',
    microrregiao: {
      id: 13003,
      nome: 'Alto Solimões',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130005,
      nome: 'Tefé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301654,
    nome: 'Guajará',
    microrregiao: {
      id: 13004,
      nome: 'Juruá',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130007,
      nome: 'Eirunepé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301704,
    nome: 'Humaitá',
    microrregiao: {
      id: 13013,
      nome: 'Madeira',
      mesorregiao: {
        id: 1304,
        nome: 'Sul Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130009,
      nome: 'Manicoré',
      'regiao-intermediaria': {
        id: 1303,
        nome: 'Lábrea',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301803,
    nome: 'Ipixuna',
    microrregiao: {
      id: 13004,
      nome: 'Juruá',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130007,
      nome: 'Eirunepé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301852,
    nome: 'Iranduba',
    microrregiao: {
      id: 13007,
      nome: 'Manaus',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130001,
      nome: 'Manaus',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301902,
    nome: 'Itacoatiara',
    microrregiao: {
      id: 13009,
      nome: 'Itacoatiara',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130011,
      nome: 'Itacoatiara',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1301951,
    nome: 'Itamarati',
    microrregiao: {
      id: 13004,
      nome: 'Juruá',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130007,
      nome: 'Eirunepé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302009,
    nome: 'Itapiranga',
    microrregiao: {
      id: 13009,
      nome: 'Itacoatiara',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130011,
      nome: 'Itacoatiara',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302108,
    nome: 'Japurá',
    microrregiao: {
      id: 13002,
      nome: 'Japurá',
      mesorregiao: {
        id: 1301,
        nome: 'Norte Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130005,
      nome: 'Tefé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302207,
    nome: 'Juruá',
    microrregiao: {
      id: 13004,
      nome: 'Juruá',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130005,
      nome: 'Tefé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302306,
    nome: 'Jutaí',
    microrregiao: {
      id: 13003,
      nome: 'Alto Solimões',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130005,
      nome: 'Tefé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302405,
    nome: 'Lábrea',
    microrregiao: {
      id: 13012,
      nome: 'Purus',
      mesorregiao: {
        id: 1304,
        nome: 'Sul Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130008,
      nome: 'Lábrea',
      'regiao-intermediaria': {
        id: 1303,
        nome: 'Lábrea',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302504,
    nome: 'Manacapuru',
    microrregiao: {
      id: 13007,
      nome: 'Manaus',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130004,
      nome: 'Manacapuru',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302553,
    nome: 'Manaquiri',
    microrregiao: {
      id: 13007,
      nome: 'Manaus',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130001,
      nome: 'Manaus',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302603,
    nome: 'Manaus',
    microrregiao: {
      id: 13007,
      nome: 'Manaus',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130001,
      nome: 'Manaus',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302702,
    nome: 'Manicoré',
    microrregiao: {
      id: 13013,
      nome: 'Madeira',
      mesorregiao: {
        id: 1304,
        nome: 'Sul Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130009,
      nome: 'Manicoré',
      'regiao-intermediaria': {
        id: 1303,
        nome: 'Lábrea',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302801,
    nome: 'Maraã',
    microrregiao: {
      id: 13002,
      nome: 'Japurá',
      mesorregiao: {
        id: 1301,
        nome: 'Norte Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130005,
      nome: 'Tefé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1302900,
    nome: 'Maués',
    microrregiao: {
      id: 13010,
      nome: 'Parintins',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130010,
      nome: 'Parintins',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303007,
    nome: 'Nhamundá',
    microrregiao: {
      id: 13010,
      nome: 'Parintins',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130010,
      nome: 'Parintins',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303106,
    nome: 'Nova Olinda do Norte',
    microrregiao: {
      id: 13009,
      nome: 'Itacoatiara',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130001,
      nome: 'Manaus',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303205,
    nome: 'Novo Airão',
    microrregiao: {
      id: 13001,
      nome: 'Rio Negro',
      mesorregiao: {
        id: 1301,
        nome: 'Norte Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130004,
      nome: 'Manacapuru',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303304,
    nome: 'Novo Aripuanã',
    microrregiao: {
      id: 13013,
      nome: 'Madeira',
      mesorregiao: {
        id: 1304,
        nome: 'Sul Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130009,
      nome: 'Manicoré',
      'regiao-intermediaria': {
        id: 1303,
        nome: 'Lábrea',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303403,
    nome: 'Parintins',
    microrregiao: {
      id: 13010,
      nome: 'Parintins',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130010,
      nome: 'Parintins',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303502,
    nome: 'Pauini',
    microrregiao: {
      id: 13011,
      nome: 'Boca do Acre',
      mesorregiao: {
        id: 1304,
        nome: 'Sul Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130008,
      nome: 'Lábrea',
      'regiao-intermediaria': {
        id: 1303,
        nome: 'Lábrea',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303536,
    nome: 'Presidente Figueiredo',
    microrregiao: {
      id: 13008,
      nome: 'Rio Preto da Eva',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130001,
      nome: 'Manaus',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303569,
    nome: 'Rio Preto da Eva',
    microrregiao: {
      id: 13008,
      nome: 'Rio Preto da Eva',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130001,
      nome: 'Manaus',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303601,
    nome: 'Santa Isabel do Rio Negro',
    microrregiao: {
      id: 13001,
      nome: 'Rio Negro',
      mesorregiao: {
        id: 1301,
        nome: 'Norte Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130002,
      nome: 'São Gabriel da Cachoeira',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303700,
    nome: 'Santo Antônio do Içá',
    microrregiao: {
      id: 13003,
      nome: 'Alto Solimões',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130006,
      nome: 'Tabatinga',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303809,
    nome: 'São Gabriel da Cachoeira',
    microrregiao: {
      id: 13001,
      nome: 'Rio Negro',
      mesorregiao: {
        id: 1301,
        nome: 'Norte Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130002,
      nome: 'São Gabriel da Cachoeira',
      'regiao-intermediaria': {
        id: 1301,
        nome: 'Manaus',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303908,
    nome: 'São Paulo de Olivença',
    microrregiao: {
      id: 13003,
      nome: 'Alto Solimões',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130006,
      nome: 'Tabatinga',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1303957,
    nome: 'São Sebastião do Uatumã',
    microrregiao: {
      id: 13010,
      nome: 'Parintins',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130011,
      nome: 'Itacoatiara',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1304005,
    nome: 'Silves',
    microrregiao: {
      id: 13009,
      nome: 'Itacoatiara',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130011,
      nome: 'Itacoatiara',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1304062,
    nome: 'Tabatinga',
    microrregiao: {
      id: 13003,
      nome: 'Alto Solimões',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130006,
      nome: 'Tabatinga',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1304104,
    nome: 'Tapauá',
    microrregiao: {
      id: 13012,
      nome: 'Purus',
      mesorregiao: {
        id: 1304,
        nome: 'Sul Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130008,
      nome: 'Lábrea',
      'regiao-intermediaria': {
        id: 1303,
        nome: 'Lábrea',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1304203,
    nome: 'Tefé',
    microrregiao: {
      id: 13005,
      nome: 'Tefé',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130005,
      nome: 'Tefé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1304237,
    nome: 'Tonantins',
    microrregiao: {
      id: 13003,
      nome: 'Alto Solimões',
      mesorregiao: {
        id: 1302,
        nome: 'Sudoeste Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130006,
      nome: 'Tabatinga',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1304260,
    nome: 'Uarini',
    microrregiao: {
      id: 13005,
      nome: 'Tefé',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130005,
      nome: 'Tefé',
      'regiao-intermediaria': {
        id: 1302,
        nome: 'Tefé',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1304302,
    nome: 'Urucará',
    microrregiao: {
      id: 13010,
      nome: 'Parintins',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130011,
      nome: 'Itacoatiara',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1304401,
    nome: 'Urucurituba',
    microrregiao: {
      id: 13009,
      nome: 'Itacoatiara',
      mesorregiao: {
        id: 1303,
        nome: 'Centro Amazonense',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 130011,
      nome: 'Itacoatiara',
      'regiao-intermediaria': {
        id: 1304,
        nome: 'Parintins',
        UF: { id: 13, sigla: 'AM', nome: 'Amazonas', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400027,
    nome: 'Amajari',
    microrregiao: {
      id: 14001,
      nome: 'Boa Vista',
      mesorregiao: {
        id: 1401,
        nome: 'Norte de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140002,
      nome: 'Pacaraima',
      'regiao-intermediaria': {
        id: 1401,
        nome: 'Boa Vista',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400050,
    nome: 'Alto Alegre',
    microrregiao: {
      id: 14001,
      nome: 'Boa Vista',
      mesorregiao: {
        id: 1401,
        nome: 'Norte de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140001,
      nome: 'Boa Vista',
      'regiao-intermediaria': {
        id: 1401,
        nome: 'Boa Vista',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400100,
    nome: 'Boa Vista',
    microrregiao: {
      id: 14001,
      nome: 'Boa Vista',
      mesorregiao: {
        id: 1401,
        nome: 'Norte de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140001,
      nome: 'Boa Vista',
      'regiao-intermediaria': {
        id: 1401,
        nome: 'Boa Vista',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400159,
    nome: 'Bonfim',
    microrregiao: {
      id: 14002,
      nome: 'Nordeste de Roraima',
      mesorregiao: {
        id: 1401,
        nome: 'Norte de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140001,
      nome: 'Boa Vista',
      'regiao-intermediaria': {
        id: 1401,
        nome: 'Boa Vista',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400175,
    nome: 'Cantá',
    microrregiao: {
      id: 14002,
      nome: 'Nordeste de Roraima',
      mesorregiao: {
        id: 1401,
        nome: 'Norte de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140001,
      nome: 'Boa Vista',
      'regiao-intermediaria': {
        id: 1401,
        nome: 'Boa Vista',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400209,
    nome: 'Caracaraí',
    microrregiao: {
      id: 14003,
      nome: 'Caracaraí',
      mesorregiao: {
        id: 1402,
        nome: 'Sul de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140004,
      nome: 'Caracaraí',
      'regiao-intermediaria': {
        id: 1402,
        nome: 'Rorainópolis - Caracaraí',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400233,
    nome: 'Caroebe',
    microrregiao: {
      id: 14004,
      nome: 'Sudeste de Roraima',
      mesorregiao: {
        id: 1402,
        nome: 'Sul de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140003,
      nome: 'Rorainópolis',
      'regiao-intermediaria': {
        id: 1402,
        nome: 'Rorainópolis - Caracaraí',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400282,
    nome: 'Iracema',
    microrregiao: {
      id: 14003,
      nome: 'Caracaraí',
      mesorregiao: {
        id: 1402,
        nome: 'Sul de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140004,
      nome: 'Caracaraí',
      'regiao-intermediaria': {
        id: 1402,
        nome: 'Rorainópolis - Caracaraí',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400308,
    nome: 'Mucajaí',
    microrregiao: {
      id: 14003,
      nome: 'Caracaraí',
      mesorregiao: {
        id: 1402,
        nome: 'Sul de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140001,
      nome: 'Boa Vista',
      'regiao-intermediaria': {
        id: 1401,
        nome: 'Boa Vista',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400407,
    nome: 'Normandia',
    microrregiao: {
      id: 14002,
      nome: 'Nordeste de Roraima',
      mesorregiao: {
        id: 1401,
        nome: 'Norte de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140002,
      nome: 'Pacaraima',
      'regiao-intermediaria': {
        id: 1401,
        nome: 'Boa Vista',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400456,
    nome: 'Pacaraima',
    microrregiao: {
      id: 14001,
      nome: 'Boa Vista',
      mesorregiao: {
        id: 1401,
        nome: 'Norte de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140002,
      nome: 'Pacaraima',
      'regiao-intermediaria': {
        id: 1401,
        nome: 'Boa Vista',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400472,
    nome: 'Rorainópolis',
    microrregiao: {
      id: 14004,
      nome: 'Sudeste de Roraima',
      mesorregiao: {
        id: 1402,
        nome: 'Sul de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140003,
      nome: 'Rorainópolis',
      'regiao-intermediaria': {
        id: 1402,
        nome: 'Rorainópolis - Caracaraí',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400506,
    nome: 'São João da Baliza',
    microrregiao: {
      id: 14004,
      nome: 'Sudeste de Roraima',
      mesorregiao: {
        id: 1402,
        nome: 'Sul de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140003,
      nome: 'Rorainópolis',
      'regiao-intermediaria': {
        id: 1402,
        nome: 'Rorainópolis - Caracaraí',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400605,
    nome: 'São Luiz',
    microrregiao: {
      id: 14004,
      nome: 'Sudeste de Roraima',
      mesorregiao: {
        id: 1402,
        nome: 'Sul de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140003,
      nome: 'Rorainópolis',
      'regiao-intermediaria': {
        id: 1402,
        nome: 'Rorainópolis - Caracaraí',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1400704,
    nome: 'Uiramutã',
    microrregiao: {
      id: 14002,
      nome: 'Nordeste de Roraima',
      mesorregiao: {
        id: 1401,
        nome: 'Norte de Roraima',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 140002,
      nome: 'Pacaraima',
      'regiao-intermediaria': {
        id: 1401,
        nome: 'Boa Vista',
        UF: { id: 14, sigla: 'RR', nome: 'Roraima', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500107,
    nome: 'Abaetetuba',
    microrregiao: {
      id: 15011,
      nome: 'Cametá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150003,
      nome: 'Abaetetuba',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500131,
    nome: 'Abel Figueiredo',
    microrregiao: {
      id: 15017,
      nome: 'Paragominas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500206,
    nome: 'Acará',
    microrregiao: {
      id: 15012,
      nome: 'Tomé-Açu',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500305,
    nome: 'Afuá',
    microrregiao: {
      id: 15005,
      nome: 'Furos de Breves',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150020,
      nome: 'Breves',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500347,
    nome: 'Água Azul do Norte',
    microrregiao: {
      id: 15019,
      nome: 'Parauapebas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150014,
      nome: 'Xinguara',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500404,
    nome: 'Alenquer',
    microrregiao: {
      id: 15002,
      nome: 'Santarém',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150015,
      nome: 'Santarém',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500503,
    nome: 'Almeirim',
    microrregiao: {
      id: 15003,
      nome: 'Almeirim',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150019,
      nome: 'Almeirim - Porto de Moz',
      'regiao-intermediaria': {
        id: 1506,
        nome: 'Altamira',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500602,
    nome: 'Altamira',
    microrregiao: {
      id: 15015,
      nome: 'Altamira',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150018,
      nome: 'Altamira',
      'regiao-intermediaria': {
        id: 1506,
        nome: 'Altamira',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500701,
    nome: 'Anajás',
    microrregiao: {
      id: 15005,
      nome: 'Furos de Breves',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150020,
      nome: 'Breves',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500800,
    nome: 'Ananindeua',
    microrregiao: {
      id: 15007,
      nome: 'Belém',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500859,
    nome: 'Anapu',
    microrregiao: {
      id: 15015,
      nome: 'Altamira',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150018,
      nome: 'Altamira',
      'regiao-intermediaria': {
        id: 1506,
        nome: 'Altamira',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500909,
    nome: 'Augusto Corrêa',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150005,
      nome: 'Bragança',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1500958,
    nome: 'Aurora do Pará',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150007,
      nome: 'Paragominas',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501006,
    nome: 'Aveiro',
    microrregiao: {
      id: 15014,
      nome: 'Itaituba',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150016,
      nome: 'Itaituba',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501105,
    nome: 'Bagre',
    microrregiao: {
      id: 15004,
      nome: 'Portel',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150020,
      nome: 'Breves',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501204,
    nome: 'Baião',
    microrregiao: {
      id: 15011,
      nome: 'Cametá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150011,
      nome: 'Tucuruí',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501253,
    nome: 'Bannach',
    microrregiao: {
      id: 15018,
      nome: 'São Félix do Xingu',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150012,
      nome: 'Redenção',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501303,
    nome: 'Barcarena',
    microrregiao: {
      id: 15007,
      nome: 'Belém',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501402,
    nome: 'Belém',
    microrregiao: {
      id: 15007,
      nome: 'Belém',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501451,
    nome: 'Belterra',
    microrregiao: {
      id: 15002,
      nome: 'Santarém',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150015,
      nome: 'Santarém',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501501,
    nome: 'Benevides',
    microrregiao: {
      id: 15007,
      nome: 'Belém',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501576,
    nome: 'Bom Jesus do Tocantins',
    microrregiao: {
      id: 15017,
      nome: 'Paragominas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501600,
    nome: 'Bonito',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150006,
      nome: 'Capanema',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501709,
    nome: 'Bragança',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150005,
      nome: 'Bragança',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501725,
    nome: 'Brasil Novo',
    microrregiao: {
      id: 15015,
      nome: 'Altamira',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150018,
      nome: 'Altamira',
      'regiao-intermediaria': {
        id: 1506,
        nome: 'Altamira',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501758,
    nome: 'Brejo Grande do Araguaia',
    microrregiao: {
      id: 15020,
      nome: 'Marabá',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501782,
    nome: 'Breu Branco',
    microrregiao: {
      id: 15016,
      nome: 'Tucuruí',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150011,
      nome: 'Tucuruí',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501808,
    nome: 'Breves',
    microrregiao: {
      id: 15005,
      nome: 'Furos de Breves',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150020,
      nome: 'Breves',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501907,
    nome: 'Bujaru',
    microrregiao: {
      id: 15008,
      nome: 'Castanhal',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1501956,
    nome: 'Cachoeira do Piriá',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150005,
      nome: 'Bragança',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502004,
    nome: 'Cachoeira do Arari',
    microrregiao: {
      id: 15006,
      nome: 'Arari',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150021,
      nome: 'Soure-Salvaterra',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502103,
    nome: 'Cametá',
    microrregiao: {
      id: 15011,
      nome: 'Cametá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150002,
      nome: 'Cametá',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502152,
    nome: 'Canaã dos Carajás',
    microrregiao: {
      id: 15019,
      nome: 'Parauapebas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150010,
      nome: 'Parauapebas',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502202,
    nome: 'Capanema',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150006,
      nome: 'Capanema',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502301,
    nome: 'Capitão Poço',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150008,
      nome: 'Capitão Poço',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502400,
    nome: 'Castanhal',
    microrregiao: {
      id: 15008,
      nome: 'Castanhal',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502509,
    nome: 'Chaves',
    microrregiao: {
      id: 15006,
      nome: 'Arari',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150020,
      nome: 'Breves',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502608,
    nome: 'Colares',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502707,
    nome: 'Conceição do Araguaia',
    microrregiao: {
      id: 15022,
      nome: 'Conceição do Araguaia',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150012,
      nome: 'Redenção',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502756,
    nome: 'Concórdia do Pará',
    microrregiao: {
      id: 15012,
      nome: 'Tomé-Açu',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502764,
    nome: 'Cumaru do Norte',
    microrregiao: {
      id: 15018,
      nome: 'São Félix do Xingu',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150012,
      nome: 'Redenção',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502772,
    nome: 'Curionópolis',
    microrregiao: {
      id: 15019,
      nome: 'Parauapebas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150010,
      nome: 'Parauapebas',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502806,
    nome: 'Curralinho',
    microrregiao: {
      id: 15005,
      nome: 'Furos de Breves',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150020,
      nome: 'Breves',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502855,
    nome: 'Curuá',
    microrregiao: {
      id: 15002,
      nome: 'Santarém',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150017,
      nome: 'Oriximiná',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502905,
    nome: 'Curuçá',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502939,
    nome: 'Dom Eliseu',
    microrregiao: {
      id: 15017,
      nome: 'Paragominas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150007,
      nome: 'Paragominas',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1502954,
    nome: 'Eldorado do Carajás',
    microrregiao: {
      id: 15019,
      nome: 'Parauapebas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150010,
      nome: 'Parauapebas',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503002,
    nome: 'Faro',
    microrregiao: {
      id: 15001,
      nome: 'Óbidos',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150017,
      nome: 'Oriximiná',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503044,
    nome: 'Floresta do Araguaia',
    microrregiao: {
      id: 15022,
      nome: 'Conceição do Araguaia',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150012,
      nome: 'Redenção',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503077,
    nome: 'Garrafão do Norte',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150008,
      nome: 'Capitão Poço',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503093,
    nome: 'Goianésia do Pará',
    microrregiao: {
      id: 15017,
      nome: 'Paragominas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150011,
      nome: 'Tucuruí',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503101,
    nome: 'Gurupá',
    microrregiao: {
      id: 15004,
      nome: 'Portel',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150020,
      nome: 'Breves',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503200,
    nome: 'Igarapé-Açu',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503309,
    nome: 'Igarapé-Miri',
    microrregiao: {
      id: 15011,
      nome: 'Cametá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150003,
      nome: 'Abaetetuba',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503408,
    nome: 'Inhangapi',
    microrregiao: {
      id: 15008,
      nome: 'Castanhal',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503457,
    nome: 'Ipixuna do Pará',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150007,
      nome: 'Paragominas',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503507,
    nome: 'Irituia',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503606,
    nome: 'Itaituba',
    microrregiao: {
      id: 15014,
      nome: 'Itaituba',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150016,
      nome: 'Itaituba',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503705,
    nome: 'Itupiranga',
    microrregiao: {
      id: 15016,
      nome: 'Tucuruí',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503754,
    nome: 'Jacareacanga',
    microrregiao: {
      id: 15014,
      nome: 'Itaituba',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150016,
      nome: 'Itaituba',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503804,
    nome: 'Jacundá',
    microrregiao: {
      id: 15016,
      nome: 'Tucuruí',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1503903,
    nome: 'Juruti',
    microrregiao: {
      id: 15001,
      nome: 'Óbidos',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150017,
      nome: 'Oriximiná',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504000,
    nome: 'Limoeiro do Ajuru',
    microrregiao: {
      id: 15011,
      nome: 'Cametá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150002,
      nome: 'Cametá',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504059,
    nome: 'Mãe do Rio',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150007,
      nome: 'Paragominas',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504109,
    nome: 'Magalhães Barata',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504208,
    nome: 'Marabá',
    microrregiao: {
      id: 15020,
      nome: 'Marabá',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504307,
    nome: 'Maracanã',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504406,
    nome: 'Marapanim',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504422,
    nome: 'Marituba',
    microrregiao: {
      id: 15007,
      nome: 'Belém',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504455,
    nome: 'Medicilândia',
    microrregiao: {
      id: 15015,
      nome: 'Altamira',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150018,
      nome: 'Altamira',
      'regiao-intermediaria': {
        id: 1506,
        nome: 'Altamira',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504505,
    nome: 'Melgaço',
    microrregiao: {
      id: 15004,
      nome: 'Portel',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150020,
      nome: 'Breves',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504604,
    nome: 'Mocajuba',
    microrregiao: {
      id: 15011,
      nome: 'Cametá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150002,
      nome: 'Cametá',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504703,
    nome: 'Moju',
    microrregiao: {
      id: 15012,
      nome: 'Tomé-Açu',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150003,
      nome: 'Abaetetuba',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504752,
    nome: 'Mojuí dos Campos',
    microrregiao: {
      id: 15002,
      nome: 'Santarém',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150015,
      nome: 'Santarém',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504802,
    nome: 'Monte Alegre',
    microrregiao: {
      id: 15002,
      nome: 'Santarém',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150015,
      nome: 'Santarém',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504901,
    nome: 'Muaná',
    microrregiao: {
      id: 15006,
      nome: 'Arari',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150021,
      nome: 'Soure-Salvaterra',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504950,
    nome: 'Nova Esperança do Piriá',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150008,
      nome: 'Capitão Poço',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1504976,
    nome: 'Nova Ipixuna',
    microrregiao: {
      id: 15016,
      nome: 'Tucuruí',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505007,
    nome: 'Nova Timboteua',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150006,
      nome: 'Capanema',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505031,
    nome: 'Novo Progresso',
    microrregiao: {
      id: 15014,
      nome: 'Itaituba',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150016,
      nome: 'Itaituba',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505064,
    nome: 'Novo Repartimento',
    microrregiao: {
      id: 15016,
      nome: 'Tucuruí',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150011,
      nome: 'Tucuruí',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505106,
    nome: 'Óbidos',
    microrregiao: {
      id: 15001,
      nome: 'Óbidos',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150017,
      nome: 'Oriximiná',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505205,
    nome: 'Oeiras do Pará',
    microrregiao: {
      id: 15011,
      nome: 'Cametá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150002,
      nome: 'Cametá',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505304,
    nome: 'Oriximiná',
    microrregiao: {
      id: 15001,
      nome: 'Óbidos',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150017,
      nome: 'Oriximiná',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505403,
    nome: 'Ourém',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150008,
      nome: 'Capitão Poço',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505437,
    nome: 'Ourilândia do Norte',
    microrregiao: {
      id: 15018,
      nome: 'São Félix do Xingu',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150013,
      nome: 'Tucumã - São Félix do Xingu',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505486,
    nome: 'Pacajá',
    microrregiao: {
      id: 15015,
      nome: 'Altamira',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150011,
      nome: 'Tucuruí',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505494,
    nome: 'Palestina do Pará',
    microrregiao: {
      id: 15020,
      nome: 'Marabá',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505502,
    nome: 'Paragominas',
    microrregiao: {
      id: 15017,
      nome: 'Paragominas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150007,
      nome: 'Paragominas',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505536,
    nome: 'Parauapebas',
    microrregiao: {
      id: 15019,
      nome: 'Parauapebas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150010,
      nome: 'Parauapebas',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505551,
    nome: "Pau D'Arco",
    microrregiao: {
      id: 15021,
      nome: 'Redenção',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150012,
      nome: 'Redenção',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505601,
    nome: 'Peixe-Boi',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150006,
      nome: 'Capanema',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505635,
    nome: 'Piçarra',
    microrregiao: {
      id: 15021,
      nome: 'Redenção',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505650,
    nome: 'Placas',
    microrregiao: {
      id: 15002,
      nome: 'Santarém',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150016,
      nome: 'Itaituba',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505700,
    nome: 'Ponta de Pedras',
    microrregiao: {
      id: 15006,
      nome: 'Arari',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150021,
      nome: 'Soure-Salvaterra',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505809,
    nome: 'Portel',
    microrregiao: {
      id: 15004,
      nome: 'Portel',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150020,
      nome: 'Breves',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1505908,
    nome: 'Porto de Moz',
    microrregiao: {
      id: 15003,
      nome: 'Almeirim',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150019,
      nome: 'Almeirim - Porto de Moz',
      'regiao-intermediaria': {
        id: 1506,
        nome: 'Altamira',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506005,
    nome: 'Prainha',
    microrregiao: {
      id: 15002,
      nome: 'Santarém',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150015,
      nome: 'Santarém',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506104,
    nome: 'Primavera',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150006,
      nome: 'Capanema',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506112,
    nome: 'Quatipuru',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150006,
      nome: 'Capanema',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506138,
    nome: 'Redenção',
    microrregiao: {
      id: 15021,
      nome: 'Redenção',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150012,
      nome: 'Redenção',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506161,
    nome: 'Rio Maria',
    microrregiao: {
      id: 15021,
      nome: 'Redenção',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150014,
      nome: 'Xinguara',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506187,
    nome: 'Rondon do Pará',
    microrregiao: {
      id: 15017,
      nome: 'Paragominas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506195,
    nome: 'Rurópolis',
    microrregiao: {
      id: 15014,
      nome: 'Itaituba',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150016,
      nome: 'Itaituba',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506203,
    nome: 'Salinópolis',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150006,
      nome: 'Capanema',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506302,
    nome: 'Salvaterra',
    microrregiao: {
      id: 15006,
      nome: 'Arari',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150021,
      nome: 'Soure-Salvaterra',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506351,
    nome: 'Santa Bárbara do Pará',
    microrregiao: {
      id: 15007,
      nome: 'Belém',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506401,
    nome: 'Santa Cruz do Arari',
    microrregiao: {
      id: 15006,
      nome: 'Arari',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150021,
      nome: 'Soure-Salvaterra',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506500,
    nome: 'Santa Izabel do Pará',
    microrregiao: {
      id: 15008,
      nome: 'Castanhal',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506559,
    nome: 'Santa Luzia do Pará',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150005,
      nome: 'Bragança',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506583,
    nome: 'Santa Maria das Barreiras',
    microrregiao: {
      id: 15022,
      nome: 'Conceição do Araguaia',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150012,
      nome: 'Redenção',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506609,
    nome: 'Santa Maria do Pará',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506708,
    nome: 'Santana do Araguaia',
    microrregiao: {
      id: 15022,
      nome: 'Conceição do Araguaia',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150012,
      nome: 'Redenção',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506807,
    nome: 'Santarém',
    microrregiao: {
      id: 15002,
      nome: 'Santarém',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150015,
      nome: 'Santarém',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1506906,
    nome: 'Santarém Novo',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150006,
      nome: 'Capanema',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507003,
    nome: 'Santo Antônio do Tauá',
    microrregiao: {
      id: 15008,
      nome: 'Castanhal',
      mesorregiao: {
        id: 1503,
        nome: 'Metropolitana de Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507102,
    nome: 'São Caetano de Odivelas',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507151,
    nome: 'São Domingos do Araguaia',
    microrregiao: {
      id: 15020,
      nome: 'Marabá',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507201,
    nome: 'São Domingos do Capim',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507300,
    nome: 'São Félix do Xingu',
    microrregiao: {
      id: 15018,
      nome: 'São Félix do Xingu',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150013,
      nome: 'Tucumã - São Félix do Xingu',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507409,
    nome: 'São Francisco do Pará',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507458,
    nome: 'São Geraldo do Araguaia',
    microrregiao: {
      id: 15021,
      nome: 'Redenção',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507466,
    nome: 'São João da Ponta',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507474,
    nome: 'São João de Pirabas',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150006,
      nome: 'Capanema',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507508,
    nome: 'São João do Araguaia',
    microrregiao: {
      id: 15020,
      nome: 'Marabá',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150009,
      nome: 'Marabá',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507607,
    nome: 'São Miguel do Guamá',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507706,
    nome: 'São Sebastião da Boa Vista',
    microrregiao: {
      id: 15005,
      nome: 'Furos de Breves',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150020,
      nome: 'Breves',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507755,
    nome: 'Sapucaia',
    microrregiao: {
      id: 15021,
      nome: 'Redenção',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150014,
      nome: 'Xinguara',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507805,
    nome: 'Senador José Porfírio',
    microrregiao: {
      id: 15015,
      nome: 'Altamira',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150018,
      nome: 'Altamira',
      'regiao-intermediaria': {
        id: 1506,
        nome: 'Altamira',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507904,
    nome: 'Soure',
    microrregiao: {
      id: 15006,
      nome: 'Arari',
      mesorregiao: {
        id: 1502,
        nome: 'Marajó',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150021,
      nome: 'Soure-Salvaterra',
      'regiao-intermediaria': {
        id: 1507,
        nome: 'Breves',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507953,
    nome: 'Tailândia',
    microrregiao: {
      id: 15012,
      nome: 'Tomé-Açu',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150003,
      nome: 'Abaetetuba',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507961,
    nome: 'Terra Alta',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150004,
      nome: 'Castanhal',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1507979,
    nome: 'Terra Santa',
    microrregiao: {
      id: 15001,
      nome: 'Óbidos',
      mesorregiao: {
        id: 1501,
        nome: 'Baixo Amazonas',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150017,
      nome: 'Oriximiná',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508001,
    nome: 'Tomé-Açu',
    microrregiao: {
      id: 15012,
      nome: 'Tomé-Açu',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508035,
    nome: 'Tracuateua',
    microrregiao: {
      id: 15010,
      nome: 'Bragantina',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150005,
      nome: 'Bragança',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508050,
    nome: 'Trairão',
    microrregiao: {
      id: 15014,
      nome: 'Itaituba',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150016,
      nome: 'Itaituba',
      'regiao-intermediaria': {
        id: 1505,
        nome: 'Santarém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508084,
    nome: 'Tucumã',
    microrregiao: {
      id: 15018,
      nome: 'São Félix do Xingu',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150013,
      nome: 'Tucumã - São Félix do Xingu',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508100,
    nome: 'Tucuruí',
    microrregiao: {
      id: 15016,
      nome: 'Tucuruí',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150011,
      nome: 'Tucuruí',
      'regiao-intermediaria': {
        id: 1503,
        nome: 'Marabá',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508126,
    nome: 'Ulianópolis',
    microrregiao: {
      id: 15017,
      nome: 'Paragominas',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150007,
      nome: 'Paragominas',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508159,
    nome: 'Uruará',
    microrregiao: {
      id: 15015,
      nome: 'Altamira',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150018,
      nome: 'Altamira',
      'regiao-intermediaria': {
        id: 1506,
        nome: 'Altamira',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508209,
    nome: 'Vigia',
    microrregiao: {
      id: 15009,
      nome: 'Salgado',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150001,
      nome: 'Belém',
      'regiao-intermediaria': {
        id: 1501,
        nome: 'Belém',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508308,
    nome: 'Viseu',
    microrregiao: {
      id: 15013,
      nome: 'Guamá',
      mesorregiao: {
        id: 1504,
        nome: 'Nordeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150005,
      nome: 'Bragança',
      'regiao-intermediaria': {
        id: 1502,
        nome: 'Castanhal',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508357,
    nome: 'Vitória do Xingu',
    microrregiao: {
      id: 15015,
      nome: 'Altamira',
      mesorregiao: {
        id: 1505,
        nome: 'Sudoeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150018,
      nome: 'Altamira',
      'regiao-intermediaria': {
        id: 1506,
        nome: 'Altamira',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1508407,
    nome: 'Xinguara',
    microrregiao: {
      id: 15021,
      nome: 'Redenção',
      mesorregiao: {
        id: 1506,
        nome: 'Sudeste Paraense',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 150014,
      nome: 'Xinguara',
      'regiao-intermediaria': {
        id: 1504,
        nome: 'Redenção',
        UF: { id: 15, sigla: 'PA', nome: 'Pará', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600055,
    nome: 'Serra do Navio',
    microrregiao: {
      id: 16003,
      nome: 'Macapá',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160004,
      nome: 'Porto Grande',
      'regiao-intermediaria': {
        id: 1602,
        nome: 'Oiapoque - Porto Grande',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600105,
    nome: 'Amapá',
    microrregiao: {
      id: 16002,
      nome: 'Amapá',
      mesorregiao: {
        id: 1601,
        nome: 'Norte do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160003,
      nome: 'Oiapoque',
      'regiao-intermediaria': {
        id: 1602,
        nome: 'Oiapoque - Porto Grande',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600154,
    nome: 'Pedra Branca do Amapari',
    microrregiao: {
      id: 16003,
      nome: 'Macapá',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160004,
      nome: 'Porto Grande',
      'regiao-intermediaria': {
        id: 1602,
        nome: 'Oiapoque - Porto Grande',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600204,
    nome: 'Calçoene',
    microrregiao: {
      id: 16001,
      nome: 'Oiapoque',
      mesorregiao: {
        id: 1601,
        nome: 'Norte do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160003,
      nome: 'Oiapoque',
      'regiao-intermediaria': {
        id: 1602,
        nome: 'Oiapoque - Porto Grande',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600212,
    nome: 'Cutias',
    microrregiao: {
      id: 16003,
      nome: 'Macapá',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160003,
      nome: 'Oiapoque',
      'regiao-intermediaria': {
        id: 1602,
        nome: 'Oiapoque - Porto Grande',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600238,
    nome: 'Ferreira Gomes',
    microrregiao: {
      id: 16003,
      nome: 'Macapá',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160004,
      nome: 'Porto Grande',
      'regiao-intermediaria': {
        id: 1602,
        nome: 'Oiapoque - Porto Grande',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600253,
    nome: 'Itaubal',
    microrregiao: {
      id: 16003,
      nome: 'Macapá',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160001,
      nome: 'Macapá',
      'regiao-intermediaria': {
        id: 1601,
        nome: 'Macapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600279,
    nome: 'Laranjal do Jari',
    microrregiao: {
      id: 16004,
      nome: 'Mazagão',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160002,
      nome: 'Laranjal do Jari',
      'regiao-intermediaria': {
        id: 1601,
        nome: 'Macapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600303,
    nome: 'Macapá',
    microrregiao: {
      id: 16003,
      nome: 'Macapá',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160001,
      nome: 'Macapá',
      'regiao-intermediaria': {
        id: 1601,
        nome: 'Macapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600402,
    nome: 'Mazagão',
    microrregiao: {
      id: 16004,
      nome: 'Mazagão',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160001,
      nome: 'Macapá',
      'regiao-intermediaria': {
        id: 1601,
        nome: 'Macapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600501,
    nome: 'Oiapoque',
    microrregiao: {
      id: 16001,
      nome: 'Oiapoque',
      mesorregiao: {
        id: 1601,
        nome: 'Norte do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160003,
      nome: 'Oiapoque',
      'regiao-intermediaria': {
        id: 1602,
        nome: 'Oiapoque - Porto Grande',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600535,
    nome: 'Porto Grande',
    microrregiao: {
      id: 16003,
      nome: 'Macapá',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160004,
      nome: 'Porto Grande',
      'regiao-intermediaria': {
        id: 1602,
        nome: 'Oiapoque - Porto Grande',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600550,
    nome: 'Pracuúba',
    microrregiao: {
      id: 16002,
      nome: 'Amapá',
      mesorregiao: {
        id: 1601,
        nome: 'Norte do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160003,
      nome: 'Oiapoque',
      'regiao-intermediaria': {
        id: 1602,
        nome: 'Oiapoque - Porto Grande',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600600,
    nome: 'Santana',
    microrregiao: {
      id: 16003,
      nome: 'Macapá',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160001,
      nome: 'Macapá',
      'regiao-intermediaria': {
        id: 1601,
        nome: 'Macapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600709,
    nome: 'Tartarugalzinho',
    microrregiao: {
      id: 16002,
      nome: 'Amapá',
      mesorregiao: {
        id: 1601,
        nome: 'Norte do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160003,
      nome: 'Oiapoque',
      'regiao-intermediaria': {
        id: 1602,
        nome: 'Oiapoque - Porto Grande',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1600808,
    nome: 'Vitória do Jari',
    microrregiao: {
      id: 16004,
      nome: 'Mazagão',
      mesorregiao: {
        id: 1602,
        nome: 'Sul do Amapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
    'regiao-imediata': {
      id: 160002,
      nome: 'Laranjal do Jari',
      'regiao-intermediaria': {
        id: 1601,
        nome: 'Macapá',
        UF: { id: 16, sigla: 'AP', nome: 'Amapá', regiao: { id: 1, sigla: 'N', nome: 'Norte' } },
      },
    },
  },
  {
    id: 1700251,
    nome: 'Abreulândia',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1700301,
    nome: 'Aguiarnópolis',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170008,
      nome: 'Tocantinópolis',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1700350,
    nome: 'Aliança do Tocantins',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1700400,
    nome: 'Almas',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1700707,
    nome: 'Alvorada',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1701002,
    nome: 'Ananás',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1701051,
    nome: 'Angico',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1701101,
    nome: 'Aparecida do Rio Negro',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170001,
      nome: 'Palmas',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1701309,
    nome: 'Aragominas',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1701903,
    nome: 'Araguacema',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1702000,
    nome: 'Araguaçu',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1702109,
    nome: 'Araguaína',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1702158,
    nome: 'Araguanã',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1702208,
    nome: 'Araguatins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1702307,
    nome: 'Arapoema',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1702406,
    nome: 'Arraias',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1702554,
    nome: 'Augustinópolis',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1702703,
    nome: 'Aurora do Tocantins',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1702901,
    nome: 'Axixá do Tocantins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703008,
    nome: 'Babaçulândia',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703057,
    nome: 'Bandeirantes do Tocantins',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170007,
      nome: 'Colinas do Tocantins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703073,
    nome: 'Barra do Ouro',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703107,
    nome: 'Barrolândia',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703206,
    nome: 'Bernardo Sayão',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170007,
      nome: 'Colinas do Tocantins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703305,
    nome: 'Bom Jesus do Tocantins',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703602,
    nome: 'Brasilândia do Tocantins',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170007,
      nome: 'Colinas do Tocantins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703701,
    nome: 'Brejinho de Nazaré',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703800,
    nome: 'Buriti do Tocantins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703826,
    nome: 'Cachoeirinha',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170008,
      nome: 'Tocantinópolis',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703842,
    nome: 'Campos Lindos',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703867,
    nome: 'Cariri do Tocantins',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703883,
    nome: 'Carmolândia',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703891,
    nome: 'Carrasco Bonito',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1703909,
    nome: 'Caseara',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1704105,
    nome: 'Centenário',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1704600,
    nome: 'Chapada de Areia',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1705102,
    nome: 'Chapada da Natividade',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1705508,
    nome: 'Colinas do Tocantins',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170007,
      nome: 'Colinas do Tocantins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1705557,
    nome: 'Combinado',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1705607,
    nome: 'Conceição do Tocantins',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1706001,
    nome: 'Couto Magalhães',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1706100,
    nome: 'Cristalândia',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1706258,
    nome: 'Crixás do Tocantins',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1706506,
    nome: 'Darcinópolis',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1707009,
    nome: 'Dianópolis',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1707108,
    nome: 'Divinópolis do Tocantins',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1707207,
    nome: 'Dois Irmãos do Tocantins',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170004,
      nome: 'Miracema do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1707306,
    nome: 'Dueré',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1707405,
    nome: 'Esperantina',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1707553,
    nome: 'Fátima',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1707652,
    nome: 'Figueirópolis',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1707702,
    nome: 'Filadélfia',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1708205,
    nome: 'Formoso do Araguaia',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1708254,
    nome: 'Tabocão',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1708304,
    nome: 'Goianorte',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1709005,
    nome: 'Goiatins',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1709302,
    nome: 'Guaraí',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1709500,
    nome: 'Gurupi',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1709807,
    nome: 'Ipueiras',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1710508,
    nome: 'Itacajá',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170007,
      nome: 'Colinas do Tocantins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1710706,
    nome: 'Itaguatins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1710904,
    nome: 'Itapiratins',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170007,
      nome: 'Colinas do Tocantins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1711100,
    nome: 'Itaporã do Tocantins',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1711506,
    nome: 'Jaú do Tocantins',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1711803,
    nome: 'Juarina',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170007,
      nome: 'Colinas do Tocantins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1711902,
    nome: 'Lagoa da Confusão',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1711951,
    nome: 'Lagoa do Tocantins',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170001,
      nome: 'Palmas',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1712009,
    nome: 'Lajeado',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170001,
      nome: 'Palmas',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1712157,
    nome: 'Lavandeira',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1712405,
    nome: 'Lizarda',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170001,
      nome: 'Palmas',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1712454,
    nome: 'Luzinópolis',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170008,
      nome: 'Tocantinópolis',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1712504,
    nome: 'Marianópolis do Tocantins',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1712702,
    nome: 'Mateiros',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170001,
      nome: 'Palmas',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1712801,
    nome: 'Maurilândia do Tocantins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170008,
      nome: 'Tocantinópolis',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1713205,
    nome: 'Miracema do Tocantins',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170004,
      nome: 'Miracema do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1713304,
    nome: 'Miranorte',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170004,
      nome: 'Miracema do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1713601,
    nome: 'Monte do Carmo',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1713700,
    nome: 'Monte Santo do Tocantins',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1713809,
    nome: 'Palmeiras do Tocantins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170008,
      nome: 'Tocantinópolis',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1713957,
    nome: 'Muricilândia',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1714203,
    nome: 'Natividade',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1714302,
    nome: 'Nazaré',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170008,
      nome: 'Tocantinópolis',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1714880,
    nome: 'Nova Olinda',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1715002,
    nome: 'Nova Rosalândia',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1715101,
    nome: 'Novo Acordo',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170001,
      nome: 'Palmas',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1715150,
    nome: 'Novo Alegre',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1715259,
    nome: 'Novo Jardim',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1715507,
    nome: 'Oliveira de Fátima',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1715705,
    nome: 'Palmeirante',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170007,
      nome: 'Colinas do Tocantins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1715754,
    nome: 'Palmeirópolis',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1716109,
    nome: 'Paraíso do Tocantins',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1716208,
    nome: 'Paranã',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1716307,
    nome: "Pau D'Arco",
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1716505,
    nome: 'Pedro Afonso',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1716604,
    nome: 'Peixe',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1716653,
    nome: 'Pequizeiro',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1716703,
    nome: 'Colméia',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1717008,
    nome: 'Pindorama do Tocantins',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1717206,
    nome: 'Piraquê',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1717503,
    nome: 'Pium',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1717800,
    nome: 'Ponte Alta do Bom Jesus',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1717909,
    nome: 'Ponte Alta do Tocantins',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718006,
    nome: 'Porto Alegre do Tocantins',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718204,
    nome: 'Porto Nacional',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718303,
    nome: 'Praia Norte',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718402,
    nome: 'Presidente Kennedy',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718451,
    nome: 'Pugmil',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170003,
      nome: 'Paraíso do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718501,
    nome: 'Recursolândia',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718550,
    nome: 'Riachinho',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718659,
    nome: 'Rio da Conceição',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718709,
    nome: 'Rio dos Bois',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170004,
      nome: 'Miracema do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718758,
    nome: 'Rio Sono',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170001,
      nome: 'Palmas',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718808,
    nome: 'Sampaio',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718840,
    nome: 'Sandolândia',
    microrregiao: {
      id: 17004,
      nome: 'Rio Formoso',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718865,
    nome: 'Santa Fé do Araguaia',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718881,
    nome: 'Santa Maria do Tocantins',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718899,
    nome: 'Santa Rita do Tocantins',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1718907,
    nome: 'Santa Rosa do Tocantins',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1719004,
    nome: 'Santa Tereza do Tocantins',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170001,
      nome: 'Palmas',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720002,
    nome: 'Santa Terezinha do Tocantins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170008,
      nome: 'Tocantinópolis',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720101,
    nome: 'São Bento do Tocantins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720150,
    nome: 'São Félix do Tocantins',
    microrregiao: {
      id: 17007,
      nome: 'Jalapão',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170001,
      nome: 'Palmas',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720200,
    nome: 'São Miguel do Tocantins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720259,
    nome: 'São Salvador do Tocantins',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720309,
    nome: 'São Sebastião do Tocantins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720499,
    nome: 'São Valério',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720655,
    nome: 'Silvanópolis',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170002,
      nome: 'Porto Nacional',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720804,
    nome: 'Sítio Novo do Tocantins',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170009,
      nome: 'Araguatins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720853,
    nome: 'Sucupira',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720903,
    nome: 'Taguatinga',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720937,
    nome: 'Taipas do Tocantins',
    microrregiao: {
      id: 17008,
      nome: 'Dianópolis',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170011,
      nome: 'Dianópolis',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1720978,
    nome: 'Talismã',
    microrregiao: {
      id: 17005,
      nome: 'Gurupi',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170010,
      nome: 'Gurupi',
      'regiao-intermediaria': {
        id: 1703,
        nome: 'Gurupi',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1721000,
    nome: 'Palmas',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170001,
      nome: 'Palmas',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1721109,
    nome: 'Tocantínia',
    microrregiao: {
      id: 17006,
      nome: 'Porto Nacional',
      mesorregiao: {
        id: 1702,
        nome: 'Oriental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170004,
      nome: 'Miracema do Tocantins',
      'regiao-intermediaria': {
        id: 1701,
        nome: 'Palmas',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1721208,
    nome: 'Tocantinópolis',
    microrregiao: {
      id: 17001,
      nome: 'Bico do Papagaio',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170008,
      nome: 'Tocantinópolis',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1721257,
    nome: 'Tupirama',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170006,
      nome: 'Guaraí',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1721307,
    nome: 'Tupiratins',
    microrregiao: {
      id: 17003,
      nome: 'Miracema do Tocantins',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170007,
      nome: 'Colinas do Tocantins',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1722081,
    nome: 'Wanderlândia',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 1722107,
    nome: 'Xambioá',
    microrregiao: {
      id: 17002,
      nome: 'Araguaína',
      mesorregiao: {
        id: 1701,
        nome: 'Ocidental do Tocantins',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
    'regiao-imediata': {
      id: 170005,
      nome: 'Araguaína',
      'regiao-intermediaria': {
        id: 1702,
        nome: 'Araguaína',
        UF: {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
      },
    },
  },
  {
    id: 2100055,
    nome: 'Açailândia',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210021,
      nome: 'Açailândia',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100105,
    nome: 'Afonso Cunha',
    microrregiao: {
      id: 21016,
      nome: 'Coelho Neto',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210013,
      nome: 'Caxias',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100154,
    nome: 'Água Doce do Maranhão',
    microrregiao: {
      id: 21013,
      nome: 'Baixo Parnaíba Maranhense',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210007,
      nome: 'Tutóia - Araioses',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100204,
    nome: 'Alcântara',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100303,
    nome: 'Aldeias Altas',
    microrregiao: {
      id: 21016,
      nome: 'Coelho Neto',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210013,
      nome: 'Caxias',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100402,
    nome: 'Altamira do Maranhão',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100436,
    nome: 'Alto Alegre do Maranhão',
    microrregiao: {
      id: 21015,
      nome: 'Codó',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100477,
    nome: 'Alto Alegre do Pindaré',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100501,
    nome: 'Alto Parnaíba',
    microrregiao: {
      id: 21020,
      nome: 'Gerais de Balsas',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100550,
    nome: 'Amapá do Maranhão',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100600,
    nome: 'Amarante do Maranhão',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100709,
    nome: 'Anajatuba',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210004,
      nome: 'Itapecuru Mirim',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100808,
    nome: 'Anapurus',
    microrregiao: {
      id: 21014,
      nome: 'Chapadinha',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210003,
      nome: 'Chapadinha',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100832,
    nome: 'Apicum-Açu',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210008,
      nome: 'Cururupu',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100873,
    nome: 'Araguanã',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100907,
    nome: 'Araioses',
    microrregiao: {
      id: 21013,
      nome: 'Baixo Parnaíba Maranhense',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210007,
      nome: 'Tutóia - Araioses',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2100956,
    nome: 'Arame',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210020,
      nome: 'Barra do Corda',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101004,
    nome: 'Arari',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210005,
      nome: 'Viana',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101103,
    nome: 'Axixá',
    microrregiao: {
      id: 21003,
      nome: 'Rosário',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101202,
    nome: 'Bacabal',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101251,
    nome: 'Bacabeira',
    microrregiao: {
      id: 21003,
      nome: 'Rosário',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101301,
    nome: 'Bacuri',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210008,
      nome: 'Cururupu',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101350,
    nome: 'Bacurituba',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101400,
    nome: 'Balsas',
    microrregiao: {
      id: 21020,
      nome: 'Gerais de Balsas',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101509,
    nome: 'Barão de Grajaú',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101608,
    nome: 'Barra do Corda',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210020,
      nome: 'Barra do Corda',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101707,
    nome: 'Barreirinhas',
    microrregiao: {
      id: 21004,
      nome: 'Lençóis Maranhenses',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210006,
      nome: 'Barreirinhas',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101731,
    nome: 'Belágua',
    microrregiao: {
      id: 21014,
      nome: 'Chapadinha',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210003,
      nome: 'Chapadinha',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101772,
    nome: 'Bela Vista do Maranhão',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101806,
    nome: 'Benedito Leite',
    microrregiao: {
      id: 21021,
      nome: 'Chapadas das Mangabeiras',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101905,
    nome: 'Bequimão',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101939,
    nome: 'Bernardo do Mearim',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2101970,
    nome: 'Boa Vista do Gurupi',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102002,
    nome: 'Bom Jardim',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102036,
    nome: 'Bom Jesus das Selvas',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210021,
      nome: 'Açailândia',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102077,
    nome: 'Bom Lugar',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102101,
    nome: 'Brejo',
    microrregiao: {
      id: 21014,
      nome: 'Chapadinha',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210003,
      nome: 'Chapadinha',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102150,
    nome: 'Brejo de Areia',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102200,
    nome: 'Buriti',
    microrregiao: {
      id: 21014,
      nome: 'Chapadinha',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210003,
      nome: 'Chapadinha',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102309,
    nome: 'Buriti Bravo',
    microrregiao: {
      id: 21017,
      nome: 'Caxias',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210014,
      nome: 'Timon',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102325,
    nome: 'Buriticupu',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210021,
      nome: 'Açailândia',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102358,
    nome: 'Buritirana',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102374,
    nome: 'Cachoeira Grande',
    microrregiao: {
      id: 21003,
      nome: 'Rosário',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102408,
    nome: 'Cajapió',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210005,
      nome: 'Viana',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102507,
    nome: 'Cajari',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210005,
      nome: 'Viana',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102556,
    nome: 'Campestre do Maranhão',
    microrregiao: {
      id: 21019,
      nome: 'Porto Franco',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102606,
    nome: 'Cândido Mendes',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102705,
    nome: 'Cantanhede',
    microrregiao: {
      id: 21006,
      nome: 'Itapecuru Mirim',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210004,
      nome: 'Itapecuru Mirim',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102754,
    nome: 'Capinzal do Norte',
    microrregiao: {
      id: 21015,
      nome: 'Codó',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102804,
    nome: 'Carolina',
    microrregiao: {
      id: 21019,
      nome: 'Porto Franco',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2102903,
    nome: 'Carutapera',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103000,
    nome: 'Caxias',
    microrregiao: {
      id: 21017,
      nome: 'Caxias',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210013,
      nome: 'Caxias',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103109,
    nome: 'Cedral',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210008,
      nome: 'Cururupu',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103125,
    nome: 'Central do Maranhão',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210008,
      nome: 'Cururupu',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103158,
    nome: 'Centro do Guilherme',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103174,
    nome: 'Centro Novo do Maranhão',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103208,
    nome: 'Chapadinha',
    microrregiao: {
      id: 21014,
      nome: 'Chapadinha',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210003,
      nome: 'Chapadinha',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103257,
    nome: 'Cidelândia',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103307,
    nome: 'Codó',
    microrregiao: {
      id: 21015,
      nome: 'Codó',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210015,
      nome: 'Codó',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103406,
    nome: 'Coelho Neto',
    microrregiao: {
      id: 21016,
      nome: 'Coelho Neto',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210013,
      nome: 'Caxias',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103505,
    nome: 'Colinas',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210018,
      nome: 'Colinas',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103554,
    nome: 'Conceição do Lago-Açu',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103604,
    nome: 'Coroatá',
    microrregiao: {
      id: 21015,
      nome: 'Codó',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210015,
      nome: 'Codó',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103703,
    nome: 'Cururupu',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210008,
      nome: 'Cururupu',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103752,
    nome: 'Davinópolis',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103802,
    nome: 'Dom Pedro',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2103901,
    nome: 'Duque Bacelar',
    microrregiao: {
      id: 21016,
      nome: 'Coelho Neto',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210013,
      nome: 'Caxias',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104008,
    nome: 'Esperantinópolis',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104057,
    nome: 'Estreito',
    microrregiao: {
      id: 21019,
      nome: 'Porto Franco',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104073,
    nome: 'Feira Nova do Maranhão',
    microrregiao: {
      id: 21020,
      nome: 'Gerais de Balsas',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104081,
    nome: 'Fernando Falcão',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210020,
      nome: 'Barra do Corda',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104099,
    nome: 'Formosa da Serra Negra',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210020,
      nome: 'Barra do Corda',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104107,
    nome: 'Fortaleza dos Nogueiras',
    microrregiao: {
      id: 21021,
      nome: 'Chapadas das Mangabeiras',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104206,
    nome: 'Fortuna',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104305,
    nome: 'Godofredo Viana',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104404,
    nome: 'Gonçalves Dias',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104503,
    nome: 'Governador Archer',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104552,
    nome: 'Governador Edison Lobão',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104602,
    nome: 'Governador Eugênio Barros',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104628,
    nome: 'Governador Luiz Rocha',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104651,
    nome: 'Governador Newton Bello',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104677,
    nome: 'Governador Nunes Freire',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104701,
    nome: 'Graça Aranha',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104800,
    nome: 'Grajaú',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210020,
      nome: 'Barra do Corda',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2104909,
    nome: 'Guimarães',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210008,
      nome: 'Cururupu',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105005,
    nome: 'Humberto de Campos',
    microrregiao: {
      id: 21004,
      nome: 'Lençóis Maranhenses',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210006,
      nome: 'Barreirinhas',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105104,
    nome: 'Icatu',
    microrregiao: {
      id: 21003,
      nome: 'Rosário',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105153,
    nome: 'Igarapé do Meio',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105203,
    nome: 'Igarapé Grande',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105302,
    nome: 'Imperatriz',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105351,
    nome: 'Itaipava do Grajaú',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210020,
      nome: 'Barra do Corda',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105401,
    nome: 'Itapecuru Mirim',
    microrregiao: {
      id: 21006,
      nome: 'Itapecuru Mirim',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210004,
      nome: 'Itapecuru Mirim',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105427,
    nome: 'Itinga do Maranhão',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210021,
      nome: 'Açailândia',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105450,
    nome: 'Jatobá',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210018,
      nome: 'Colinas',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105476,
    nome: 'Jenipapo dos Vieiras',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210020,
      nome: 'Barra do Corda',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105500,
    nome: 'João Lisboa',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105609,
    nome: 'Joselândia',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105658,
    nome: 'Junco do Maranhão',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105708,
    nome: 'Lago da Pedra',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105807,
    nome: 'Lago do Junco',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105906,
    nome: 'Lago Verde',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105922,
    nome: 'Lagoa do Mato',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105948,
    nome: 'Lago dos Rodrigues',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105963,
    nome: 'Lagoa Grande do Maranhão',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2105989,
    nome: 'Lajeado Novo',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106003,
    nome: 'Lima Campos',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106102,
    nome: 'Loreto',
    microrregiao: {
      id: 21021,
      nome: 'Chapadas das Mangabeiras',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106201,
    nome: 'Luís Domingues',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106300,
    nome: 'Magalhães de Almeida',
    microrregiao: {
      id: 21013,
      nome: 'Baixo Parnaíba Maranhense',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210007,
      nome: 'Tutóia - Araioses',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106326,
    nome: 'Maracaçumé',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106359,
    nome: 'Marajá do Sena',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106375,
    nome: 'Maranhãozinho',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106409,
    nome: 'Mata Roma',
    microrregiao: {
      id: 21014,
      nome: 'Chapadinha',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210003,
      nome: 'Chapadinha',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106508,
    nome: 'Matinha',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210005,
      nome: 'Viana',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106607,
    nome: 'Matões',
    microrregiao: {
      id: 21017,
      nome: 'Caxias',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210014,
      nome: 'Timon',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106631,
    nome: 'Matões do Norte',
    microrregiao: {
      id: 21006,
      nome: 'Itapecuru Mirim',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210004,
      nome: 'Itapecuru Mirim',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106672,
    nome: 'Milagres do Maranhão',
    microrregiao: {
      id: 21014,
      nome: 'Chapadinha',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210003,
      nome: 'Chapadinha',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106706,
    nome: 'Mirador',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210018,
      nome: 'Colinas',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106755,
    nome: 'Miranda do Norte',
    microrregiao: {
      id: 21006,
      nome: 'Itapecuru Mirim',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210004,
      nome: 'Itapecuru Mirim',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106805,
    nome: 'Mirinzal',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210008,
      nome: 'Cururupu',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2106904,
    nome: 'Monção',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107001,
    nome: 'Montes Altos',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107100,
    nome: 'Morros',
    microrregiao: {
      id: 21003,
      nome: 'Rosário',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107209,
    nome: 'Nina Rodrigues',
    microrregiao: {
      id: 21006,
      nome: 'Itapecuru Mirim',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210004,
      nome: 'Itapecuru Mirim',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107258,
    nome: 'Nova Colinas',
    microrregiao: {
      id: 21021,
      nome: 'Chapadas das Mangabeiras',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107308,
    nome: 'Nova Iorque',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107357,
    nome: 'Nova Olinda do Maranhão',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107407,
    nome: "Olho d'Água das Cunhãs",
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107456,
    nome: 'Olinda Nova do Maranhão',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210005,
      nome: 'Viana',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107506,
    nome: 'Paço do Lumiar',
    microrregiao: {
      id: 21002,
      nome: 'Aglomeração Urbana de São Luís',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107605,
    nome: 'Palmeirândia',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107704,
    nome: 'Paraibano',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107803,
    nome: 'Parnarama',
    microrregiao: {
      id: 21017,
      nome: 'Caxias',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210014,
      nome: 'Timon',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2107902,
    nome: 'Passagem Franca',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108009,
    nome: 'Pastos Bons',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108058,
    nome: 'Paulino Neves',
    microrregiao: {
      id: 21004,
      nome: 'Lençóis Maranhenses',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210007,
      nome: 'Tutóia - Araioses',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108108,
    nome: 'Paulo Ramos',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108207,
    nome: 'Pedreiras',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108256,
    nome: 'Pedro do Rosário',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108306,
    nome: 'Penalva',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210005,
      nome: 'Viana',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108405,
    nome: 'Peri Mirim',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108454,
    nome: 'Peritoró',
    microrregiao: {
      id: 21015,
      nome: 'Codó',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210015,
      nome: 'Codó',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108504,
    nome: 'Pindaré-Mirim',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108603,
    nome: 'Pinheiro',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108702,
    nome: 'Pio XII',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108801,
    nome: 'Pirapemas',
    microrregiao: {
      id: 21006,
      nome: 'Itapecuru Mirim',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210004,
      nome: 'Itapecuru Mirim',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2108900,
    nome: 'Poção de Pedras',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109007,
    nome: 'Porto Franco',
    microrregiao: {
      id: 21019,
      nome: 'Porto Franco',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109056,
    nome: 'Porto Rico do Maranhão',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210008,
      nome: 'Cururupu',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109106,
    nome: 'Presidente Dutra',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109205,
    nome: 'Presidente Juscelino',
    microrregiao: {
      id: 21003,
      nome: 'Rosário',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109239,
    nome: 'Presidente Médici',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109270,
    nome: 'Presidente Sarney',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109304,
    nome: 'Presidente Vargas',
    microrregiao: {
      id: 21006,
      nome: 'Itapecuru Mirim',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210004,
      nome: 'Itapecuru Mirim',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109403,
    nome: 'Primeira Cruz',
    microrregiao: {
      id: 21004,
      nome: 'Lençóis Maranhenses',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210006,
      nome: 'Barreirinhas',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109452,
    nome: 'Raposa',
    microrregiao: {
      id: 21002,
      nome: 'Aglomeração Urbana de São Luís',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109502,
    nome: 'Riachão',
    microrregiao: {
      id: 21020,
      nome: 'Gerais de Balsas',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109551,
    nome: 'Ribamar Fiquene',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109601,
    nome: 'Rosário',
    microrregiao: {
      id: 21003,
      nome: 'Rosário',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109700,
    nome: 'Sambaíba',
    microrregiao: {
      id: 21021,
      nome: 'Chapadas das Mangabeiras',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109759,
    nome: 'Santa Filomena do Maranhão',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109809,
    nome: 'Santa Helena',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2109908,
    nome: 'Santa Inês',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110005,
    nome: 'Santa Luzia',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110039,
    nome: 'Santa Luzia do Paruá',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210011,
      nome: 'Governador Nunes Freire',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110104,
    nome: 'Santa Quitéria do Maranhão',
    microrregiao: {
      id: 21013,
      nome: 'Baixo Parnaíba Maranhense',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210003,
      nome: 'Chapadinha',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110203,
    nome: 'Santa Rita',
    microrregiao: {
      id: 21003,
      nome: 'Rosário',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110237,
    nome: 'Santana do Maranhão',
    microrregiao: {
      id: 21013,
      nome: 'Baixo Parnaíba Maranhense',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210007,
      nome: 'Tutóia - Araioses',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110278,
    nome: 'Santo Amaro do Maranhão',
    microrregiao: {
      id: 21004,
      nome: 'Lençóis Maranhenses',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210006,
      nome: 'Barreirinhas',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110302,
    nome: 'Santo Antônio dos Lopes',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110401,
    nome: 'São Benedito do Rio Preto',
    microrregiao: {
      id: 21014,
      nome: 'Chapadinha',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210003,
      nome: 'Chapadinha',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110500,
    nome: 'São Bento',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110609,
    nome: 'São Bernardo',
    microrregiao: {
      id: 21013,
      nome: 'Baixo Parnaíba Maranhense',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210007,
      nome: 'Tutóia - Araioses',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110658,
    nome: 'São Domingos do Azeitão',
    microrregiao: {
      id: 21021,
      nome: 'Chapadas das Mangabeiras',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110708,
    nome: 'São Domingos do Maranhão',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110807,
    nome: 'São Félix de Balsas',
    microrregiao: {
      id: 21021,
      nome: 'Chapadas das Mangabeiras',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110856,
    nome: 'São Francisco do Brejão',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210021,
      nome: 'Açailândia',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2110906,
    nome: 'São Francisco do Maranhão',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111003,
    nome: 'São João Batista',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210005,
      nome: 'Viana',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111029,
    nome: 'São João do Carú',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111052,
    nome: 'São João do Paraíso',
    microrregiao: {
      id: 21019,
      nome: 'Porto Franco',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111078,
    nome: 'São João do Soter',
    microrregiao: {
      id: 21017,
      nome: 'Caxias',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210013,
      nome: 'Caxias',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111102,
    nome: 'São João dos Patos',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111201,
    nome: 'São José de Ribamar',
    microrregiao: {
      id: 21002,
      nome: 'Aglomeração Urbana de São Luís',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111250,
    nome: 'São José dos Basílios',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111300,
    nome: 'São Luís',
    microrregiao: {
      id: 21002,
      nome: 'Aglomeração Urbana de São Luís',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210001,
      nome: 'São Luís',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111409,
    nome: 'São Luís Gonzaga do Maranhão',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111508,
    nome: 'São Mateus do Maranhão',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111532,
    nome: 'São Pedro da Água Branca',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111573,
    nome: 'São Pedro dos Crentes',
    microrregiao: {
      id: 21019,
      nome: 'Porto Franco',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210020,
      nome: 'Barra do Corda',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111607,
    nome: 'São Raimundo das Mangabeiras',
    microrregiao: {
      id: 21021,
      nome: 'Chapadas das Mangabeiras',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111631,
    nome: 'São Raimundo do Doca Bezerra',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111672,
    nome: 'São Roberto',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111706,
    nome: 'São Vicente Ferrer',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210005,
      nome: 'Viana',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111722,
    nome: 'Satubinha',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111748,
    nome: 'Senador Alexandre Costa',
    microrregiao: {
      id: 21012,
      nome: 'Presidente Dutra',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111763,
    nome: 'Senador La Rocque',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111789,
    nome: 'Serrano do Maranhão',
    microrregiao: {
      id: 21001,
      nome: 'Litoral Ocidental Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210008,
      nome: 'Cururupu',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111805,
    nome: 'Sítio Novo',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210020,
      nome: 'Barra do Corda',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111904,
    nome: 'Sucupira do Norte',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210018,
      nome: 'Colinas',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2111953,
    nome: 'Sucupira do Riachão',
    microrregiao: {
      id: 21018,
      nome: 'Chapadas do Alto Itapecuru',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210017,
      nome: 'São João dos Patos',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112001,
    nome: 'Tasso Fragoso',
    microrregiao: {
      id: 21020,
      nome: 'Gerais de Balsas',
      mesorregiao: {
        id: 2105,
        nome: 'Sul Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210022,
      nome: 'Balsas',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112100,
    nome: 'Timbiras',
    microrregiao: {
      id: 21015,
      nome: 'Codó',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210015,
      nome: 'Codó',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112209,
    nome: 'Timon',
    microrregiao: {
      id: 21017,
      nome: 'Caxias',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210014,
      nome: 'Timon',
      'regiao-intermediaria': {
        id: 2103,
        nome: 'Caxias',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112233,
    nome: 'Trizidela do Vale',
    microrregiao: {
      id: 21010,
      nome: 'Médio Mearim',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210012,
      nome: 'Pedreiras',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112274,
    nome: 'Tufilândia',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112308,
    nome: 'Tuntum',
    microrregiao: {
      id: 21011,
      nome: 'Alto Mearim e Grajaú',
      mesorregiao: {
        id: 2103,
        nome: 'Centro Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210016,
      nome: 'Presidente Dutra',
      'regiao-intermediaria': {
        id: 2104,
        nome: 'Presidente Dutra',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112407,
    nome: 'Turiaçu',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112456,
    nome: 'Turilândia',
    microrregiao: {
      id: 21007,
      nome: 'Gurupi',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210002,
      nome: 'Pinheiro',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112506,
    nome: 'Tutóia',
    microrregiao: {
      id: 21004,
      nome: 'Lençóis Maranhenses',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210007,
      nome: 'Tutóia - Araioses',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112605,
    nome: 'Urbano Santos',
    microrregiao: {
      id: 21014,
      nome: 'Chapadinha',
      mesorregiao: {
        id: 2104,
        nome: 'Leste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210003,
      nome: 'Chapadinha',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112704,
    nome: 'Vargem Grande',
    microrregiao: {
      id: 21006,
      nome: 'Itapecuru Mirim',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210004,
      nome: 'Itapecuru Mirim',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112803,
    nome: 'Viana',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210005,
      nome: 'Viana',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112852,
    nome: 'Vila Nova dos Martírios',
    microrregiao: {
      id: 21009,
      nome: 'Imperatriz',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210019,
      nome: 'Imperatriz',
      'regiao-intermediaria': {
        id: 2105,
        nome: 'Imperatriz',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2112902,
    nome: 'Vitória do Mearim',
    microrregiao: {
      id: 21005,
      nome: 'Baixada Maranhense',
      mesorregiao: {
        id: 2101,
        nome: 'Norte Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210005,
      nome: 'Viana',
      'regiao-intermediaria': {
        id: 2101,
        nome: 'São Luís',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2113009,
    nome: 'Vitorino Freire',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210010,
      nome: 'Bacabal',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2114007,
    nome: 'Zé Doca',
    microrregiao: {
      id: 21008,
      nome: 'Pindaré',
      mesorregiao: {
        id: 2102,
        nome: 'Oeste Maranhense',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 210009,
      nome: 'Santa Inês',
      'regiao-intermediaria': {
        id: 2102,
        nome: 'Santa Inês - Bacabal',
        UF: {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200053,
    nome: 'Acauã',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220010,
      nome: 'Paulistana',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200103,
    nome: 'Agricolândia',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200202,
    nome: 'Água Branca',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200251,
    nome: 'Alagoinha do Piauí',
    microrregiao: {
      id: 22014,
      nome: 'Pio IX',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200277,
    nome: 'Alegrete do Piauí',
    microrregiao: {
      id: 22014,
      nome: 'Pio IX',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200301,
    nome: 'Alto Longá',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200400,
    nome: 'Altos',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200459,
    nome: 'Alvorada do Gurguéia',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220016,
      nome: 'Bom Jesus',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200509,
    nome: 'Amarante',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200608,
    nome: 'Angical do Piauí',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200707,
    nome: 'Anísio de Abreu',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200806,
    nome: 'Antônio Almeida',
    microrregiao: {
      id: 22008,
      nome: 'Bertolínia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220018,
      nome: 'Uruçuí',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200905,
    nome: 'Aroazes',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220004,
      nome: 'Valença do Piauí',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2200954,
    nome: 'Aroeiras do Itaim',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201002,
    nome: 'Arraial',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201051,
    nome: 'Assunção do Piauí',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201101,
    nome: 'Avelino Lopes',
    microrregiao: {
      id: 22012,
      nome: 'Chapadas do Extremo Sul Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201150,
    nome: 'Baixa Grande do Ribeiro',
    microrregiao: {
      id: 22007,
      nome: 'Alto Parnaíba Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220018,
      nome: 'Uruçuí',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201176,
    nome: "Barra D'Alcântara",
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220004,
      nome: 'Valença do Piauí',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201200,
    nome: 'Barras',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220005,
      nome: 'Barras',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201309,
    nome: 'Barreiras do Piauí',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201408,
    nome: 'Barro Duro',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201507,
    nome: 'Batalha',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220008,
      nome: 'Esperantina',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201556,
    nome: 'Bela Vista do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220012,
      nome: 'Simplício Mendes',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201572,
    nome: 'Belém do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201606,
    nome: 'Beneditinos',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201705,
    nome: 'Bertolínia',
    microrregiao: {
      id: 22008,
      nome: 'Bertolínia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220018,
      nome: 'Uruçuí',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201739,
    nome: 'Betânia do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220010,
      nome: 'Paulistana',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201770,
    nome: 'Boa Hora',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220005,
      nome: 'Barras',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201804,
    nome: 'Bocaina',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201903,
    nome: 'Bom Jesus',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220016,
      nome: 'Bom Jesus',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201919,
    nome: 'Bom Princípio do Piauí',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201929,
    nome: 'Bonfim do Piauí',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201945,
    nome: 'Boqueirão do Piauí',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201960,
    nome: 'Brasileira',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220007,
      nome: 'Piripiri',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2201988,
    nome: 'Brejo do Piauí',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220019,
      nome: 'Canto do Buriti',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202000,
    nome: 'Buriti dos Lopes',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202026,
    nome: 'Buriti dos Montes',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202059,
    nome: 'Cabeceiras do Piauí',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220005,
      nome: 'Barras',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202075,
    nome: 'Cajazeiras do Piauí',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220011,
      nome: 'Oeiras',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202083,
    nome: 'Cajueiro da Praia',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202091,
    nome: 'Caldeirão Grande do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202109,
    nome: 'Campinas do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220012,
      nome: 'Simplício Mendes',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202117,
    nome: 'Campo Alegre do Fidalgo',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220014,
      nome: 'São João do Piauí',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202133,
    nome: 'Campo Grande do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202174,
    nome: 'Campo Largo do Piauí',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220005,
      nome: 'Barras',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202208,
    nome: 'Campo Maior',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202251,
    nome: 'Canavieira',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202307,
    nome: 'Canto do Buriti',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220019,
      nome: 'Canto do Buriti',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202406,
    nome: 'Capitão de Campos',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220007,
      nome: 'Piripiri',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202455,
    nome: 'Capitão Gervásio Oliveira',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220014,
      nome: 'São João do Piauí',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202505,
    nome: 'Caracol',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202539,
    nome: 'Caraúbas do Piauí',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202554,
    nome: 'Caridade do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220010,
      nome: 'Paulistana',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202604,
    nome: 'Castelo do Piauí',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202653,
    nome: 'Caxingó',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202703,
    nome: 'Cocal',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202711,
    nome: 'Cocal de Telha',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202729,
    nome: 'Cocal dos Alves',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202737,
    nome: 'Coivaras',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202752,
    nome: 'Colônia do Gurguéia',
    microrregiao: {
      id: 22008,
      nome: 'Bertolínia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220016,
      nome: 'Bom Jesus',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202778,
    nome: 'Colônia do Piauí',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220011,
      nome: 'Oeiras',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202802,
    nome: 'Conceição do Canindé',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220012,
      nome: 'Simplício Mendes',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202851,
    nome: 'Coronel José Dias',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2202901,
    nome: 'Corrente',
    microrregiao: {
      id: 22012,
      nome: 'Chapadas do Extremo Sul Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203008,
    nome: 'Cristalândia do Piauí',
    microrregiao: {
      id: 22012,
      nome: 'Chapadas do Extremo Sul Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203107,
    nome: 'Cristino Castro',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220016,
      nome: 'Bom Jesus',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203206,
    nome: 'Curimatá',
    microrregiao: {
      id: 22012,
      nome: 'Chapadas do Extremo Sul Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203230,
    nome: 'Currais',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220016,
      nome: 'Bom Jesus',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203255,
    nome: 'Curralinhos',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203271,
    nome: 'Curral Novo do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220010,
      nome: 'Paulistana',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203305,
    nome: 'Demerval Lobão',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203354,
    nome: 'Dirceu Arcoverde',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203404,
    nome: 'Dom Expedito Lopes',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203420,
    nome: 'Domingos Mourão',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220007,
      nome: 'Piripiri',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203453,
    nome: 'Dom Inocêncio',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203503,
    nome: 'Elesbão Veloso',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203602,
    nome: 'Eliseu Martins',
    microrregiao: {
      id: 22008,
      nome: 'Bertolínia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220019,
      nome: 'Canto do Buriti',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203701,
    nome: 'Esperantina',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220008,
      nome: 'Esperantina',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203750,
    nome: 'Fartura do Piauí',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203800,
    nome: 'Flores do Piauí',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220019,
      nome: 'Canto do Buriti',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203859,
    nome: 'Floresta do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220011,
      nome: 'Oeiras',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2203909,
    nome: 'Floriano',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204006,
    nome: 'Francinópolis',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204105,
    nome: 'Francisco Ayres',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204154,
    nome: 'Francisco Macedo',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204204,
    nome: 'Francisco Santos',
    microrregiao: {
      id: 22014,
      nome: 'Pio IX',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204303,
    nome: 'Fronteiras',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204352,
    nome: 'Geminiano',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204402,
    nome: 'Gilbués',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204501,
    nome: 'Guadalupe',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204550,
    nome: 'Guaribas',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204600,
    nome: 'Hugo Napoleão',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204659,
    nome: 'Ilha Grande',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204709,
    nome: 'Inhuma',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220004,
      nome: 'Valença do Piauí',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204808,
    nome: 'Ipiranga do Piauí',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220004,
      nome: 'Valença do Piauí',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2204907,
    nome: 'Isaías Coelho',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205003,
    nome: 'Itainópolis',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205102,
    nome: 'Itaueira',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205151,
    nome: 'Jacobina do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220010,
      nome: 'Paulistana',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205201,
    nome: 'Jaicós',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205250,
    nome: 'Jardim do Mulato',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205276,
    nome: 'Jatobá do Piauí',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205300,
    nome: 'Jerumenha',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205359,
    nome: 'João Costa',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220014,
      nome: 'São João do Piauí',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205409,
    nome: 'Joaquim Pires',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220008,
      nome: 'Esperantina',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205458,
    nome: 'Joca Marques',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220008,
      nome: 'Esperantina',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205508,
    nome: 'José de Freitas',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205516,
    nome: 'Juazeiro do Piauí',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205524,
    nome: 'Júlio Borges',
    microrregiao: {
      id: 22012,
      nome: 'Chapadas do Extremo Sul Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205532,
    nome: 'Jurema',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205540,
    nome: 'Lagoinha do Piauí',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205557,
    nome: 'Lagoa Alegre',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205565,
    nome: 'Lagoa do Barro do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220014,
      nome: 'São João do Piauí',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205573,
    nome: 'Lagoa de São Francisco',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220007,
      nome: 'Piripiri',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205581,
    nome: 'Lagoa do Piauí',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205599,
    nome: 'Lagoa do Sítio',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220004,
      nome: 'Valença do Piauí',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205607,
    nome: 'Landri Sales',
    microrregiao: {
      id: 22008,
      nome: 'Bertolínia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205706,
    nome: 'Luís Correia',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205805,
    nome: 'Luzilândia',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220008,
      nome: 'Esperantina',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205854,
    nome: 'Madeiro',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220008,
      nome: 'Esperantina',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205904,
    nome: 'Manoel Emídio',
    microrregiao: {
      id: 22008,
      nome: 'Bertolínia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220018,
      nome: 'Uruçuí',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2205953,
    nome: 'Marcolândia',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206001,
    nome: 'Marcos Parente',
    microrregiao: {
      id: 22008,
      nome: 'Bertolínia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206050,
    nome: 'Massapê do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206100,
    nome: 'Matias Olímpio',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220008,
      nome: 'Esperantina',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206209,
    nome: 'Miguel Alves',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206308,
    nome: 'Miguel Leão',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206357,
    nome: 'Milton Brandão',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220007,
      nome: 'Piripiri',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206407,
    nome: 'Monsenhor Gil',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206506,
    nome: 'Monsenhor Hipólito',
    microrregiao: {
      id: 22014,
      nome: 'Pio IX',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206605,
    nome: 'Monte Alegre do Piauí',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206654,
    nome: 'Morro Cabeça no Tempo',
    microrregiao: {
      id: 22012,
      nome: 'Chapadas do Extremo Sul Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206670,
    nome: 'Morro do Chapéu do Piauí',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220008,
      nome: 'Esperantina',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206696,
    nome: 'Murici dos Portelas',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206704,
    nome: 'Nazaré do Piauí',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206720,
    nome: 'Nazária',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206753,
    nome: 'Nossa Senhora de Nazaré',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206803,
    nome: 'Nossa Senhora dos Remédios',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220005,
      nome: 'Barras',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206902,
    nome: 'Novo Oriente do Piauí',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220004,
      nome: 'Valença do Piauí',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2206951,
    nome: 'Novo Santo Antônio',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207009,
    nome: 'Oeiras',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220011,
      nome: 'Oeiras',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207108,
    nome: "Olho D'Água do Piauí",
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207207,
    nome: 'Padre Marcos',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207306,
    nome: 'Paes Landim',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220012,
      nome: 'Simplício Mendes',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207355,
    nome: 'Pajeú do Piauí',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220019,
      nome: 'Canto do Buriti',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207405,
    nome: 'Palmeira do Piauí',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220016,
      nome: 'Bom Jesus',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207504,
    nome: 'Palmeirais',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207553,
    nome: 'Paquetá',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207603,
    nome: 'Parnaguá',
    microrregiao: {
      id: 22012,
      nome: 'Chapadas do Extremo Sul Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207702,
    nome: 'Parnaíba',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220006,
      nome: 'Parnaíba',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207751,
    nome: 'Passagem Franca do Piauí',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207777,
    nome: 'Patos do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220010,
      nome: 'Paulistana',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207793,
    nome: "Pau D'Arco do Piauí",
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207801,
    nome: 'Paulistana',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220010,
      nome: 'Paulistana',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207850,
    nome: 'Pavussu',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220019,
      nome: 'Canto do Buriti',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207900,
    nome: 'Pedro II',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220007,
      nome: 'Piripiri',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207934,
    nome: 'Pedro Laurentino',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220014,
      nome: 'São João do Piauí',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2207959,
    nome: 'Nova Santa Rita',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220014,
      nome: 'São João do Piauí',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208007,
    nome: 'Picos',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208106,
    nome: 'Pimenteiras',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220004,
      nome: 'Valença do Piauí',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208205,
    nome: 'Pio IX',
    microrregiao: {
      id: 22014,
      nome: 'Pio IX',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208304,
    nome: 'Piracuruca',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220007,
      nome: 'Piripiri',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208403,
    nome: 'Piripiri',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220007,
      nome: 'Piripiri',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208502,
    nome: 'Porto',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220005,
      nome: 'Barras',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208551,
    nome: 'Porto Alegre do Piauí',
    microrregiao: {
      id: 22008,
      nome: 'Bertolínia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208601,
    nome: 'Prata do Piauí',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208650,
    nome: 'Queimada Nova',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220010,
      nome: 'Paulistana',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208700,
    nome: 'Redenção do Gurguéia',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220016,
      nome: 'Bom Jesus',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208809,
    nome: 'Regeneração',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208858,
    nome: 'Riacho Frio',
    microrregiao: {
      id: 22012,
      nome: 'Chapadas do Extremo Sul Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208874,
    nome: 'Ribeira do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220014,
      nome: 'São João do Piauí',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2208908,
    nome: 'Ribeiro Gonçalves',
    microrregiao: {
      id: 22007,
      nome: 'Alto Parnaíba Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220018,
      nome: 'Uruçuí',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209005,
    nome: 'Rio Grande do Piauí',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220019,
      nome: 'Canto do Buriti',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209104,
    nome: 'Santa Cruz do Piauí',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209153,
    nome: 'Santa Cruz dos Milagres',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220004,
      nome: 'Valença do Piauí',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209203,
    nome: 'Santa Filomena',
    microrregiao: {
      id: 22007,
      nome: 'Alto Parnaíba Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209302,
    nome: 'Santa Luz',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220016,
      nome: 'Bom Jesus',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209351,
    nome: 'Santana do Piauí',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209377,
    nome: 'Santa Rosa do Piauí',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220011,
      nome: 'Oeiras',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209401,
    nome: 'Santo Antônio de Lisboa',
    microrregiao: {
      id: 22014,
      nome: 'Pio IX',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209450,
    nome: 'Santo Antônio dos Milagres',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209500,
    nome: 'Santo Inácio do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220011,
      nome: 'Oeiras',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209559,
    nome: 'São Braz do Piauí',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209609,
    nome: 'São Félix do Piauí',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209658,
    nome: 'São Francisco de Assis do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220012,
      nome: 'Simplício Mendes',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209708,
    nome: 'São Francisco do Piauí',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209757,
    nome: 'São Gonçalo do Gurguéia',
    microrregiao: {
      id: 22010,
      nome: 'Alto Médio Gurguéia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209807,
    nome: 'São Gonçalo do Piauí',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209856,
    nome: 'São João da Canabrava',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209872,
    nome: 'São João da Fronteira',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220007,
      nome: 'Piripiri',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209906,
    nome: 'São João da Serra',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209955,
    nome: 'São João da Varjota',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220011,
      nome: 'Oeiras',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2209971,
    nome: 'São João do Arraial',
    microrregiao: {
      id: 22001,
      nome: 'Baixo Parnaíba Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220008,
      nome: 'Esperantina',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210003,
    nome: 'São João do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220014,
      nome: 'São João do Piauí',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210052,
    nome: 'São José do Divino',
    microrregiao: {
      id: 22002,
      nome: 'Litoral Piauiense',
      mesorregiao: {
        id: 2201,
        nome: 'Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220007,
      nome: 'Piripiri',
      'regiao-intermediaria': {
        id: 2202,
        nome: 'Parnaíba',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210102,
    nome: 'São José do Peixe',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220017,
      nome: 'Floriano',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210201,
    nome: 'São José do Piauí',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210300,
    nome: 'São Julião',
    microrregiao: {
      id: 22014,
      nome: 'Pio IX',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210359,
    nome: 'São Lourenço do Piauí',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210375,
    nome: 'São Luis do Piauí',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210383,
    nome: 'São Miguel da Baixa Grande',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210391,
    nome: 'São Miguel do Fidalgo',
    microrregiao: {
      id: 22009,
      nome: 'Floriano',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220011,
      nome: 'Oeiras',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210409,
    nome: 'São Miguel do Tapuio',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210508,
    nome: 'São Pedro do Piauí',
    microrregiao: {
      id: 22005,
      nome: 'Médio Parnaíba Piauiense',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210607,
    nome: 'São Raimundo Nonato',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210623,
    nome: 'Sebastião Barros',
    microrregiao: {
      id: 22012,
      nome: 'Chapadas do Extremo Sul Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220015,
      nome: 'Corrente',
      'regiao-intermediaria': {
        id: 2205,
        nome: 'Corrente - Bom Jesus',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210631,
    nome: 'Sebastião Leal',
    microrregiao: {
      id: 22008,
      nome: 'Bertolínia',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220018,
      nome: 'Uruçuí',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210656,
    nome: 'Sigefredo Pacheco',
    microrregiao: {
      id: 22004,
      nome: 'Campo Maior',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220003,
      nome: 'Campo Maior',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210706,
    nome: 'Simões',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220010,
      nome: 'Paulistana',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210805,
    nome: 'Simplício Mendes',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220012,
      nome: 'Simplício Mendes',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210904,
    nome: 'Socorro do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220012,
      nome: 'Simplício Mendes',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210938,
    nome: 'Sussuapara',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210953,
    nome: 'Tamboril do Piauí',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220019,
      nome: 'Canto do Buriti',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2210979,
    nome: 'Tanque do Piauí',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220011,
      nome: 'Oeiras',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2211001,
    nome: 'Teresina',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2211100,
    nome: 'União',
    microrregiao: {
      id: 22003,
      nome: 'Teresina',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220001,
      nome: 'Teresina',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2211209,
    nome: 'Uruçuí',
    microrregiao: {
      id: 22007,
      nome: 'Alto Parnaíba Piauiense',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220018,
      nome: 'Uruçuí',
      'regiao-intermediaria': {
        id: 2206,
        nome: 'Floriano',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2211308,
    nome: 'Valença do Piauí',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220004,
      nome: 'Valença do Piauí',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2211357,
    nome: 'Várzea Branca',
    microrregiao: {
      id: 22011,
      nome: 'São Raimundo Nonato',
      mesorregiao: {
        id: 2203,
        nome: 'Sudoeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220013,
      nome: 'São Raimundo Nonato',
      'regiao-intermediaria': {
        id: 2204,
        nome: 'São Raimundo Nonato',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2211407,
    nome: 'Várzea Grande',
    microrregiao: {
      id: 22006,
      nome: 'Valença do Piauí',
      mesorregiao: {
        id: 2202,
        nome: 'Centro-Norte Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220002,
      nome: 'Amarante - Água Branca - Regeneração',
      'regiao-intermediaria': {
        id: 2201,
        nome: 'Teresina',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2211506,
    nome: 'Vera Mendes',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2211605,
    nome: 'Vila Nova do Piauí',
    microrregiao: {
      id: 22015,
      nome: 'Alto Médio Canindé',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2211704,
    nome: 'Wall Ferraz',
    microrregiao: {
      id: 22013,
      nome: 'Picos',
      mesorregiao: {
        id: 2204,
        nome: 'Sudeste Piauiense',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 220009,
      nome: 'Picos',
      'regiao-intermediaria': {
        id: 2203,
        nome: 'Picos',
        UF: {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300101,
    nome: 'Abaiara',
    microrregiao: {
      id: 23033,
      nome: 'Brejo Santo',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230012,
      nome: 'Brejo Santo',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300150,
    nome: 'Acarape',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300200,
    nome: 'Acaraú',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230017,
      nome: 'Acaraú',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300309,
    nome: 'Acopiara',
    microrregiao: {
      id: 23021,
      nome: 'Sertão de Senador Pompeu',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230009,
      nome: 'Iguatu',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300408,
    nome: 'Aiuaba',
    microrregiao: {
      id: 23020,
      nome: 'Sertão de Inhamuns',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300507,
    nome: 'Alcântaras',
    microrregiao: {
      id: 23004,
      nome: 'Meruoca',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300606,
    nome: 'Altaneira',
    microrregiao: {
      id: 23030,
      nome: 'Caririaçu',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300705,
    nome: 'Alto Santo',
    microrregiao: {
      id: 23023,
      nome: 'Baixo Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300754,
    nome: 'Amontada',
    microrregiao: {
      id: 23008,
      nome: 'Itapipoca',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230002,
      nome: 'Itapipoca',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300804,
    nome: 'Antonina do Norte',
    microrregiao: {
      id: 23027,
      nome: 'Várzea Alegre',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2300903,
    nome: 'Apuiarés',
    microrregiao: {
      id: 23011,
      nome: 'Médio Curu',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230005,
      nome: 'Itapagé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301000,
    nome: 'Aquiraz',
    microrregiao: {
      id: 23016,
      nome: 'Fortaleza',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301109,
    nome: 'Aracati',
    microrregiao: {
      id: 23022,
      nome: 'Litoral de Aracati',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230008,
      nome: 'Aracati',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301208,
    nome: 'Aracoiaba',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301257,
    nome: 'Ararendá',
    microrregiao: {
      id: 23018,
      nome: 'Sertão de Cratéus',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230013,
      nome: 'Crateús',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301307,
    nome: 'Araripe',
    microrregiao: {
      id: 23029,
      nome: 'Chapada do Araripe',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301406,
    nome: 'Aratuba',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301505,
    nome: 'Arneiroz',
    microrregiao: {
      id: 23020,
      nome: 'Sertão de Inhamuns',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230014,
      nome: 'Tauá',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301604,
    nome: 'Assaré',
    microrregiao: {
      id: 23029,
      nome: 'Chapada do Araripe',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301703,
    nome: 'Aurora',
    microrregiao: {
      id: 23031,
      nome: 'Barro',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301802,
    nome: 'Baixio',
    microrregiao: {
      id: 23028,
      nome: 'Lavras da Mangabeira',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230010,
      nome: 'Icó',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301851,
    nome: 'Banabuiú',
    microrregiao: {
      id: 23019,
      nome: 'Sertão de Quixeramobim',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301901,
    nome: 'Barbalha',
    microrregiao: {
      id: 23032,
      nome: 'Cariri',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2301950,
    nome: 'Barreira',
    microrregiao: {
      id: 23014,
      nome: 'Chorozinho',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302008,
    nome: 'Barro',
    microrregiao: {
      id: 23031,
      nome: 'Barro',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230012,
      nome: 'Brejo Santo',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302057,
    nome: 'Barroquinha',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230018,
      nome: 'Camocim',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302107,
    nome: 'Baturité',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302206,
    nome: 'Beberibe',
    microrregiao: {
      id: 23015,
      nome: 'Cascavel',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302305,
    nome: 'Bela Cruz',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230017,
      nome: 'Acaraú',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302404,
    nome: 'Boa Viagem',
    microrregiao: {
      id: 23019,
      nome: 'Sertão de Quixeramobim',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230004,
      nome: 'Canindé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302503,
    nome: 'Brejo Santo',
    microrregiao: {
      id: 23033,
      nome: 'Brejo Santo',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230012,
      nome: 'Brejo Santo',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302602,
    nome: 'Camocim',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230018,
      nome: 'Camocim',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302701,
    nome: 'Campos Sales',
    microrregiao: {
      id: 23029,
      nome: 'Chapada do Araripe',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302800,
    nome: 'Canindé',
    microrregiao: {
      id: 23012,
      nome: 'Canindé',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230004,
      nome: 'Canindé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2302909,
    nome: 'Capistrano',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303006,
    nome: 'Caridade',
    microrregiao: {
      id: 23012,
      nome: 'Canindé',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230004,
      nome: 'Canindé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303105,
    nome: 'Cariré',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303204,
    nome: 'Caririaçu',
    microrregiao: {
      id: 23030,
      nome: 'Caririaçu',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303303,
    nome: 'Cariús',
    microrregiao: {
      id: 23027,
      nome: 'Várzea Alegre',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230009,
      nome: 'Iguatu',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303402,
    nome: 'Carnaubal',
    microrregiao: {
      id: 23002,
      nome: 'Ibiapaba',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303501,
    nome: 'Cascavel',
    microrregiao: {
      id: 23015,
      nome: 'Cascavel',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303600,
    nome: 'Catarina',
    microrregiao: {
      id: 23020,
      nome: 'Sertão de Inhamuns',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230009,
      nome: 'Iguatu',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303659,
    nome: 'Catunda',
    microrregiao: {
      id: 23007,
      nome: 'Santa Quitéria',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303709,
    nome: 'Caucaia',
    microrregiao: {
      id: 23016,
      nome: 'Fortaleza',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303808,
    nome: 'Cedro',
    microrregiao: {
      id: 23026,
      nome: 'Iguatu',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230009,
      nome: 'Iguatu',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303907,
    nome: 'Chaval',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230018,
      nome: 'Camocim',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303931,
    nome: 'Choró',
    microrregiao: {
      id: 23019,
      nome: 'Sertão de Quixeramobim',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2303956,
    nome: 'Chorozinho',
    microrregiao: {
      id: 23014,
      nome: 'Chorozinho',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304004,
    nome: 'Coreaú',
    microrregiao: {
      id: 23003,
      nome: 'Coreaú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304103,
    nome: 'Crateús',
    microrregiao: {
      id: 23018,
      nome: 'Sertão de Cratéus',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230013,
      nome: 'Crateús',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304202,
    nome: 'Crato',
    microrregiao: {
      id: 23032,
      nome: 'Cariri',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304236,
    nome: 'Croatá',
    microrregiao: {
      id: 23002,
      nome: 'Ibiapaba',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304251,
    nome: 'Cruz',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230017,
      nome: 'Acaraú',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304269,
    nome: 'Deputado Irapuan Pinheiro',
    microrregiao: {
      id: 23021,
      nome: 'Sertão de Senador Pompeu',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304277,
    nome: 'Ereré',
    microrregiao: {
      id: 23025,
      nome: 'Serra do Pereiro',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304285,
    nome: 'Eusébio',
    microrregiao: {
      id: 23016,
      nome: 'Fortaleza',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304301,
    nome: 'Farias Brito',
    microrregiao: {
      id: 23030,
      nome: 'Caririaçu',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304350,
    nome: 'Forquilha',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304400,
    nome: 'Fortaleza',
    microrregiao: {
      id: 23016,
      nome: 'Fortaleza',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304459,
    nome: 'Fortim',
    microrregiao: {
      id: 23022,
      nome: 'Litoral de Aracati',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230008,
      nome: 'Aracati',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304509,
    nome: 'Frecheirinha',
    microrregiao: {
      id: 23003,
      nome: 'Coreaú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304608,
    nome: 'General Sampaio',
    microrregiao: {
      id: 23011,
      nome: 'Médio Curu',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230005,
      nome: 'Itapagé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304657,
    nome: 'Graça',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304707,
    nome: 'Granja',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230018,
      nome: 'Camocim',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304806,
    nome: 'Granjeiro',
    microrregiao: {
      id: 23030,
      nome: 'Caririaçu',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304905,
    nome: 'Groaíras',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2304954,
    nome: 'Guaiúba',
    microrregiao: {
      id: 23016,
      nome: 'Fortaleza',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305001,
    nome: 'Guaraciaba do Norte',
    microrregiao: {
      id: 23002,
      nome: 'Ibiapaba',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305100,
    nome: 'Guaramiranga',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305209,
    nome: 'Hidrolândia',
    microrregiao: {
      id: 23007,
      nome: 'Santa Quitéria',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305233,
    nome: 'Horizonte',
    microrregiao: {
      id: 23017,
      nome: 'Pacajus',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305266,
    nome: 'Ibaretama',
    microrregiao: {
      id: 23019,
      nome: 'Sertão de Quixeramobim',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305308,
    nome: 'Ibiapina',
    microrregiao: {
      id: 23002,
      nome: 'Ibiapaba',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305332,
    nome: 'Ibicuitinga',
    microrregiao: {
      id: 23023,
      nome: 'Baixo Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305357,
    nome: 'Icapuí',
    microrregiao: {
      id: 23022,
      nome: 'Litoral de Aracati',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230008,
      nome: 'Aracati',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305407,
    nome: 'Icó',
    microrregiao: {
      id: 23026,
      nome: 'Iguatu',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230010,
      nome: 'Icó',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305506,
    nome: 'Iguatu',
    microrregiao: {
      id: 23026,
      nome: 'Iguatu',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230009,
      nome: 'Iguatu',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305605,
    nome: 'Independência',
    microrregiao: {
      id: 23018,
      nome: 'Sertão de Cratéus',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230013,
      nome: 'Crateús',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305654,
    nome: 'Ipaporanga',
    microrregiao: {
      id: 23018,
      nome: 'Sertão de Cratéus',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230013,
      nome: 'Crateús',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305704,
    nome: 'Ipaumirim',
    microrregiao: {
      id: 23028,
      nome: 'Lavras da Mangabeira',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230010,
      nome: 'Icó',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305803,
    nome: 'Ipu',
    microrregiao: {
      id: 23006,
      nome: 'Ipu',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2305902,
    nome: 'Ipueiras',
    microrregiao: {
      id: 23006,
      nome: 'Ipu',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306009,
    nome: 'Iracema',
    microrregiao: {
      id: 23025,
      nome: 'Serra do Pereiro',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306108,
    nome: 'Irauçuba',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230005,
      nome: 'Itapagé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306207,
    nome: 'Itaiçaba',
    microrregiao: {
      id: 23022,
      nome: 'Litoral de Aracati',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230008,
      nome: 'Aracati',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306256,
    nome: 'Itaitinga',
    microrregiao: {
      id: 23016,
      nome: 'Fortaleza',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306306,
    nome: 'Itapajé',
    microrregiao: {
      id: 23010,
      nome: 'Uruburetama',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230005,
      nome: 'Itapagé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306405,
    nome: 'Itapipoca',
    microrregiao: {
      id: 23008,
      nome: 'Itapipoca',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230002,
      nome: 'Itapipoca',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306504,
    nome: 'Itapiúna',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306553,
    nome: 'Itarema',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230017,
      nome: 'Acaraú',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306603,
    nome: 'Itatira',
    microrregiao: {
      id: 23012,
      nome: 'Canindé',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230004,
      nome: 'Canindé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306702,
    nome: 'Jaguaretama',
    microrregiao: {
      id: 23024,
      nome: 'Médio Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306801,
    nome: 'Jaguaribara',
    microrregiao: {
      id: 23024,
      nome: 'Médio Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2306900,
    nome: 'Jaguaribe',
    microrregiao: {
      id: 23024,
      nome: 'Médio Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307007,
    nome: 'Jaguaruana',
    microrregiao: {
      id: 23023,
      nome: 'Baixo Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230008,
      nome: 'Aracati',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307106,
    nome: 'Jardim',
    microrregiao: {
      id: 23032,
      nome: 'Cariri',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307205,
    nome: 'Jati',
    microrregiao: {
      id: 23033,
      nome: 'Brejo Santo',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230012,
      nome: 'Brejo Santo',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307254,
    nome: 'Jijoca de Jericoacoara',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230017,
      nome: 'Acaraú',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307304,
    nome: 'Juazeiro do Norte',
    microrregiao: {
      id: 23032,
      nome: 'Cariri',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307403,
    nome: 'Jucás',
    microrregiao: {
      id: 23027,
      nome: 'Várzea Alegre',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230009,
      nome: 'Iguatu',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307502,
    nome: 'Lavras da Mangabeira',
    microrregiao: {
      id: 23028,
      nome: 'Lavras da Mangabeira',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307601,
    nome: 'Limoeiro do Norte',
    microrregiao: {
      id: 23023,
      nome: 'Baixo Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307635,
    nome: 'Madalena',
    microrregiao: {
      id: 23019,
      nome: 'Sertão de Quixeramobim',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230004,
      nome: 'Canindé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307650,
    nome: 'Maracanaú',
    microrregiao: {
      id: 23016,
      nome: 'Fortaleza',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307700,
    nome: 'Maranguape',
    microrregiao: {
      id: 23016,
      nome: 'Fortaleza',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307809,
    nome: 'Marco',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230017,
      nome: 'Acaraú',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2307908,
    nome: 'Martinópole',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308005,
    nome: 'Massapê',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308104,
    nome: 'Mauriti',
    microrregiao: {
      id: 23031,
      nome: 'Barro',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230012,
      nome: 'Brejo Santo',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308203,
    nome: 'Meruoca',
    microrregiao: {
      id: 23004,
      nome: 'Meruoca',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308302,
    nome: 'Milagres',
    microrregiao: {
      id: 23033,
      nome: 'Brejo Santo',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230012,
      nome: 'Brejo Santo',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308351,
    nome: 'Milhã',
    microrregiao: {
      id: 23021,
      nome: 'Sertão de Senador Pompeu',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308377,
    nome: 'Miraíma',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230002,
      nome: 'Itapipoca',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308401,
    nome: 'Missão Velha',
    microrregiao: {
      id: 23032,
      nome: 'Cariri',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308500,
    nome: 'Mombaça',
    microrregiao: {
      id: 23021,
      nome: 'Sertão de Senador Pompeu',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230009,
      nome: 'Iguatu',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308609,
    nome: 'Monsenhor Tabosa',
    microrregiao: {
      id: 23018,
      nome: 'Sertão de Cratéus',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230013,
      nome: 'Crateús',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308708,
    nome: 'Morada Nova',
    microrregiao: {
      id: 23023,
      nome: 'Baixo Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308807,
    nome: 'Moraújo',
    microrregiao: {
      id: 23003,
      nome: 'Coreaú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2308906,
    nome: 'Morrinhos',
    microrregiao: {
      id: 23001,
      nome: 'Litoral de Camocim e Acaraú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309003,
    nome: 'Mucambo',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309102,
    nome: 'Mulungu',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309201,
    nome: 'Nova Olinda',
    microrregiao: {
      id: 23032,
      nome: 'Cariri',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309300,
    nome: 'Nova Russas',
    microrregiao: {
      id: 23018,
      nome: 'Sertão de Cratéus',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230013,
      nome: 'Crateús',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309409,
    nome: 'Novo Oriente',
    microrregiao: {
      id: 23018,
      nome: 'Sertão de Cratéus',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230013,
      nome: 'Crateús',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309458,
    nome: 'Ocara',
    microrregiao: {
      id: 23014,
      nome: 'Chorozinho',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309508,
    nome: 'Orós',
    microrregiao: {
      id: 23026,
      nome: 'Iguatu',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230010,
      nome: 'Icó',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309607,
    nome: 'Pacajus',
    microrregiao: {
      id: 23017,
      nome: 'Pacajus',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309706,
    nome: 'Pacatuba',
    microrregiao: {
      id: 23016,
      nome: 'Fortaleza',
      mesorregiao: {
        id: 2303,
        nome: 'Metropolitana de Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309805,
    nome: 'Pacoti',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2309904,
    nome: 'Pacujá',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310001,
    nome: 'Palhano',
    microrregiao: {
      id: 23023,
      nome: 'Baixo Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310100,
    nome: 'Palmácia',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310209,
    nome: 'Paracuru',
    microrregiao: {
      id: 23009,
      nome: 'Baixo Curu',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310258,
    nome: 'Paraipaba',
    microrregiao: {
      id: 23009,
      nome: 'Baixo Curu',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310308,
    nome: 'Parambu',
    microrregiao: {
      id: 23020,
      nome: 'Sertão de Inhamuns',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230014,
      nome: 'Tauá',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310407,
    nome: 'Paramoti',
    microrregiao: {
      id: 23012,
      nome: 'Canindé',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230004,
      nome: 'Canindé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310506,
    nome: 'Pedra Branca',
    microrregiao: {
      id: 23021,
      nome: 'Sertão de Senador Pompeu',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310605,
    nome: 'Penaforte',
    microrregiao: {
      id: 23033,
      nome: 'Brejo Santo',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230012,
      nome: 'Brejo Santo',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310704,
    nome: 'Pentecoste',
    microrregiao: {
      id: 23011,
      nome: 'Médio Curu',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230005,
      nome: 'Itapagé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310803,
    nome: 'Pereiro',
    microrregiao: {
      id: 23025,
      nome: 'Serra do Pereiro',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310852,
    nome: 'Pindoretama',
    microrregiao: {
      id: 23015,
      nome: 'Cascavel',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310902,
    nome: 'Piquet Carneiro',
    microrregiao: {
      id: 23021,
      nome: 'Sertão de Senador Pompeu',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230009,
      nome: 'Iguatu',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2310951,
    nome: 'Pires Ferreira',
    microrregiao: {
      id: 23006,
      nome: 'Ipu',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311009,
    nome: 'Poranga',
    microrregiao: {
      id: 23006,
      nome: 'Ipu',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230013,
      nome: 'Crateús',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311108,
    nome: 'Porteiras',
    microrregiao: {
      id: 23032,
      nome: 'Cariri',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230012,
      nome: 'Brejo Santo',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311207,
    nome: 'Potengi',
    microrregiao: {
      id: 23029,
      nome: 'Chapada do Araripe',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311231,
    nome: 'Potiretama',
    microrregiao: {
      id: 23025,
      nome: 'Serra do Pereiro',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311264,
    nome: 'Quiterianópolis',
    microrregiao: {
      id: 23018,
      nome: 'Sertão de Cratéus',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230013,
      nome: 'Crateús',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311306,
    nome: 'Quixadá',
    microrregiao: {
      id: 23019,
      nome: 'Sertão de Quixeramobim',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311355,
    nome: 'Quixelô',
    microrregiao: {
      id: 23026,
      nome: 'Iguatu',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230009,
      nome: 'Iguatu',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311405,
    nome: 'Quixeramobim',
    microrregiao: {
      id: 23019,
      nome: 'Sertão de Quixeramobim',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311504,
    nome: 'Quixeré',
    microrregiao: {
      id: 23023,
      nome: 'Baixo Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311603,
    nome: 'Redenção',
    microrregiao: {
      id: 23013,
      nome: 'Baturité',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230003,
      nome: 'Redenção-Acarape',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311702,
    nome: 'Reriutaba',
    microrregiao: {
      id: 23006,
      nome: 'Ipu',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311801,
    nome: 'Russas',
    microrregiao: {
      id: 23023,
      nome: 'Baixo Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311900,
    nome: 'Saboeiro',
    microrregiao: {
      id: 23020,
      nome: 'Sertão de Inhamuns',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230009,
      nome: 'Iguatu',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2311959,
    nome: 'Salitre',
    microrregiao: {
      id: 23029,
      nome: 'Chapada do Araripe',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2312007,
    nome: 'Santana do Acaraú',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2312106,
    nome: 'Santana do Cariri',
    microrregiao: {
      id: 23032,
      nome: 'Cariri',
      mesorregiao: {
        id: 2307,
        nome: 'Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2312205,
    nome: 'Santa Quitéria',
    microrregiao: {
      id: 23007,
      nome: 'Santa Quitéria',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2312304,
    nome: 'São Benedito',
    microrregiao: {
      id: 23002,
      nome: 'Ibiapaba',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2312403,
    nome: 'São Gonçalo do Amarante',
    microrregiao: {
      id: 23009,
      nome: 'Baixo Curu',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2312502,
    nome: 'São João do Jaguaribe',
    microrregiao: {
      id: 23023,
      nome: 'Baixo Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2312601,
    nome: 'São Luís do Curu',
    microrregiao: {
      id: 23011,
      nome: 'Médio Curu',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230001,
      nome: 'Fortaleza',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2312700,
    nome: 'Senador Pompeu',
    microrregiao: {
      id: 23021,
      nome: 'Sertão de Senador Pompeu',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2312809,
    nome: 'Senador Sá',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2312908,
    nome: 'Sobral',
    microrregiao: {
      id: 23005,
      nome: 'Sobral',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313005,
    nome: 'Solonópole',
    microrregiao: {
      id: 23021,
      nome: 'Sertão de Senador Pompeu',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230006,
      nome: 'Quixadá',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313104,
    nome: 'Tabuleiro do Norte',
    microrregiao: {
      id: 23023,
      nome: 'Baixo Jaguaribe',
      mesorregiao: {
        id: 2305,
        nome: 'Jaguaribe',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230007,
      nome: 'Russas - Limoeiro do Norte',
      'regiao-intermediaria': {
        id: 2302,
        nome: 'Quixadá',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313203,
    nome: 'Tamboril',
    microrregiao: {
      id: 23018,
      nome: 'Sertão de Cratéus',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230013,
      nome: 'Crateús',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313252,
    nome: 'Tarrafas',
    microrregiao: {
      id: 23027,
      nome: 'Várzea Alegre',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313302,
    nome: 'Tauá',
    microrregiao: {
      id: 23020,
      nome: 'Sertão de Inhamuns',
      mesorregiao: {
        id: 2304,
        nome: 'Sertões Cearenses',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230014,
      nome: 'Tauá',
      'regiao-intermediaria': {
        id: 2305,
        nome: 'Crateús',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313351,
    nome: 'Tejuçuoca',
    microrregiao: {
      id: 23011,
      nome: 'Médio Curu',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230005,
      nome: 'Itapagé',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313401,
    nome: 'Tianguá',
    microrregiao: {
      id: 23002,
      nome: 'Ibiapaba',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313500,
    nome: 'Trairi',
    microrregiao: {
      id: 23008,
      nome: 'Itapipoca',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230002,
      nome: 'Itapipoca',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313559,
    nome: 'Tururu',
    microrregiao: {
      id: 23010,
      nome: 'Uruburetama',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230002,
      nome: 'Itapipoca',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313609,
    nome: 'Ubajara',
    microrregiao: {
      id: 23002,
      nome: 'Ibiapaba',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313708,
    nome: 'Umari',
    microrregiao: {
      id: 23028,
      nome: 'Lavras da Mangabeira',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230010,
      nome: 'Icó',
      'regiao-intermediaria': {
        id: 2303,
        nome: 'Iguatu',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313757,
    nome: 'Umirim',
    microrregiao: {
      id: 23010,
      nome: 'Uruburetama',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230002,
      nome: 'Itapipoca',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313807,
    nome: 'Uruburetama',
    microrregiao: {
      id: 23010,
      nome: 'Uruburetama',
      mesorregiao: {
        id: 2302,
        nome: 'Norte Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230002,
      nome: 'Itapipoca',
      'regiao-intermediaria': {
        id: 2301,
        nome: 'Fortaleza',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313906,
    nome: 'Uruoca',
    microrregiao: {
      id: 23003,
      nome: 'Coreaú',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2313955,
    nome: 'Varjota',
    microrregiao: {
      id: 23006,
      nome: 'Ipu',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230015,
      nome: 'Sobral',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2314003,
    nome: 'Várzea Alegre',
    microrregiao: {
      id: 23027,
      nome: 'Várzea Alegre',
      mesorregiao: {
        id: 2306,
        nome: 'Centro-Sul Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230011,
      nome: 'Juazeiro do Norte',
      'regiao-intermediaria': {
        id: 2304,
        nome: 'Juazeiro do Norte',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2314102,
    nome: 'Viçosa do Ceará',
    microrregiao: {
      id: 23002,
      nome: 'Ibiapaba',
      mesorregiao: {
        id: 2301,
        nome: 'Noroeste Cearense',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 230016,
      nome: 'São Benedito - Ipu - Guaraciaba do Norte - Tianguá',
      'regiao-intermediaria': {
        id: 2306,
        nome: 'Sobral',
        UF: {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2400109,
    nome: 'Acari',
    microrregiao: {
      id: 24012,
      nome: 'Seridó Oriental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240008,
      nome: 'Currais Novos',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2400208,
    nome: 'Açu',
    microrregiao: {
      id: 24004,
      nome: 'Vale do Açu',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2400307,
    nome: 'Afonso Bezerra',
    microrregiao: {
      id: 24009,
      nome: 'Angicos',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2400406,
    nome: 'Água Nova',
    microrregiao: {
      id: 24005,
      nome: 'Serra de São Miguel',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2400505,
    nome: 'Alexandria',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2400604,
    nome: 'Almino Afonso',
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2400703,
    nome: 'Alto do Rodrigues',
    microrregiao: {
      id: 24004,
      nome: 'Vale do Açu',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2400802,
    nome: 'Angicos',
    microrregiao: {
      id: 24009,
      nome: 'Angicos',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2400901,
    nome: 'Antônio Martins',
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401008,
    nome: 'Apodi',
    microrregiao: {
      id: 24002,
      nome: 'Chapada do Apodi',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401107,
    nome: 'Areia Branca',
    microrregiao: {
      id: 24001,
      nome: 'Mossoró',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401206,
    nome: 'Arês',
    microrregiao: {
      id: 24019,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240003,
      nome: 'Canguaretama',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401305,
    nome: 'Campo Grande',
    microrregiao: {
      id: 24003,
      nome: 'Médio Oeste',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401404,
    nome: 'Baía Formosa',
    microrregiao: {
      id: 24019,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240003,
      nome: 'Canguaretama',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401453,
    nome: 'Baraúna',
    microrregiao: {
      id: 24001,
      nome: 'Mossoró',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401503,
    nome: 'Barcelona',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240006,
      nome: 'São Paulo do Potengi',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401602,
    nome: 'Bento Fernandes',
    microrregiao: {
      id: 24013,
      nome: 'Baixa Verde',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401651,
    nome: 'Bodó',
    microrregiao: {
      id: 24010,
      nome: 'Serra de Santana',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240008,
      nome: 'Currais Novos',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401701,
    nome: 'Bom Jesus',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401800,
    nome: 'Brejinho',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401859,
    nome: 'Caiçara do Norte',
    microrregiao: {
      id: 24008,
      nome: 'Macau',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240005,
      nome: 'João Câmara',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2401909,
    nome: 'Caiçara do Rio do Vento',
    microrregiao: {
      id: 24009,
      nome: 'Angicos',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240006,
      nome: 'São Paulo do Potengi',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2402006,
    nome: 'Caicó',
    microrregiao: {
      id: 24011,
      nome: 'Seridó Ocidental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2402105,
    nome: 'Campo Redondo',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240004,
      nome: 'Santa Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2402204,
    nome: 'Canguaretama',
    microrregiao: {
      id: 24019,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240003,
      nome: 'Canguaretama',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2402303,
    nome: 'Caraúbas',
    microrregiao: {
      id: 24002,
      nome: 'Chapada do Apodi',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2402402,
    nome: 'Carnaúba dos Dantas',
    microrregiao: {
      id: 24012,
      nome: 'Seridó Oriental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240008,
      nome: 'Currais Novos',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2402501,
    nome: 'Carnaubais',
    microrregiao: {
      id: 24004,
      nome: 'Vale do Açu',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2402600,
    nome: 'Ceará-Mirim',
    microrregiao: {
      id: 24017,
      nome: 'Macaíba',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2402709,
    nome: 'Cerro Corá',
    microrregiao: {
      id: 24010,
      nome: 'Serra de Santana',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240008,
      nome: 'Currais Novos',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2402808,
    nome: 'Coronel Ezequiel',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240004,
      nome: 'Santa Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2402907,
    nome: 'Coronel João Pessoa',
    microrregiao: {
      id: 24005,
      nome: 'Serra de São Miguel',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403004,
    nome: 'Cruzeta',
    microrregiao: {
      id: 24012,
      nome: 'Seridó Oriental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403103,
    nome: 'Currais Novos',
    microrregiao: {
      id: 24012,
      nome: 'Seridó Oriental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240008,
      nome: 'Currais Novos',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403202,
    nome: 'Doutor Severiano',
    microrregiao: {
      id: 24005,
      nome: 'Serra de São Miguel',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403251,
    nome: 'Parnamirim',
    microrregiao: {
      id: 24018,
      nome: 'Natal',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403301,
    nome: 'Encanto',
    microrregiao: {
      id: 24005,
      nome: 'Serra de São Miguel',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403400,
    nome: 'Equador',
    microrregiao: {
      id: 24012,
      nome: 'Seridó Oriental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403509,
    nome: 'Espírito Santo',
    microrregiao: {
      id: 24019,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240003,
      nome: 'Canguaretama',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403608,
    nome: 'Extremoz',
    microrregiao: {
      id: 24018,
      nome: 'Natal',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403707,
    nome: 'Felipe Guerra',
    microrregiao: {
      id: 24002,
      nome: 'Chapada do Apodi',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403756,
    nome: 'Fernando Pedroza',
    microrregiao: {
      id: 24009,
      nome: 'Angicos',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403806,
    nome: 'Florânia',
    microrregiao: {
      id: 24010,
      nome: 'Serra de Santana',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240008,
      nome: 'Currais Novos',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2403905,
    nome: 'Francisco Dantas',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404002,
    nome: 'Frutuoso Gomes',
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404101,
    nome: 'Galinhos',
    microrregiao: {
      id: 24008,
      nome: 'Macau',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240005,
      nome: 'João Câmara',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404200,
    nome: 'Goianinha',
    microrregiao: {
      id: 24019,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240003,
      nome: 'Canguaretama',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404309,
    nome: 'Governador Dix-Sept Rosado',
    microrregiao: {
      id: 24002,
      nome: 'Chapada do Apodi',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404408,
    nome: 'Grossos',
    microrregiao: {
      id: 24001,
      nome: 'Mossoró',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404507,
    nome: 'Guamaré',
    microrregiao: {
      id: 24008,
      nome: 'Macau',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404606,
    nome: 'Ielmo Marinho',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404705,
    nome: 'Ipanguaçu',
    microrregiao: {
      id: 24004,
      nome: 'Vale do Açu',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404804,
    nome: 'Ipueira',
    microrregiao: {
      id: 24011,
      nome: 'Seridó Ocidental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404853,
    nome: 'Itajá',
    microrregiao: {
      id: 24004,
      nome: 'Vale do Açu',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2404903,
    nome: 'Itaú',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2405009,
    nome: 'Jaçanã',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240004,
      nome: 'Santa Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2405108,
    nome: 'Jandaíra',
    microrregiao: {
      id: 24013,
      nome: 'Baixa Verde',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240005,
      nome: 'João Câmara',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2405207,
    nome: 'Janduís',
    microrregiao: {
      id: 24003,
      nome: 'Médio Oeste',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2405306,
    nome: 'Januário Cicco',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2405405,
    nome: 'Japi',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240004,
      nome: 'Santa Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2405504,
    nome: 'Jardim de Angicos',
    microrregiao: {
      id: 24009,
      nome: 'Angicos',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240005,
      nome: 'João Câmara',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2405603,
    nome: 'Jardim de Piranhas',
    microrregiao: {
      id: 24011,
      nome: 'Seridó Ocidental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2405702,
    nome: 'Jardim do Seridó',
    microrregiao: {
      id: 24012,
      nome: 'Seridó Oriental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2405801,
    nome: 'João Câmara',
    microrregiao: {
      id: 24013,
      nome: 'Baixa Verde',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240005,
      nome: 'João Câmara',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2405900,
    nome: 'João Dias',
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406007,
    nome: 'José da Penha',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406106,
    nome: 'Jucurutu',
    microrregiao: {
      id: 24004,
      nome: 'Vale do Açu',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406155,
    nome: 'Jundiá',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406205,
    nome: "Lagoa d'Anta",
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406304,
    nome: 'Lagoa de Pedras',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406403,
    nome: 'Lagoa de Velhos',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240006,
      nome: 'São Paulo do Potengi',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406502,
    nome: 'Lagoa Nova',
    microrregiao: {
      id: 24010,
      nome: 'Serra de Santana',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240008,
      nome: 'Currais Novos',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406601,
    nome: 'Lagoa Salgada',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406700,
    nome: 'Lajes',
    microrregiao: {
      id: 24009,
      nome: 'Angicos',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406809,
    nome: 'Lajes Pintadas',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240004,
      nome: 'Santa Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2406908,
    nome: 'Lucrécia',
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407005,
    nome: 'Luís Gomes',
    microrregiao: {
      id: 24005,
      nome: 'Serra de São Miguel',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407104,
    nome: 'Macaíba',
    microrregiao: {
      id: 24017,
      nome: 'Macaíba',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407203,
    nome: 'Macau',
    microrregiao: {
      id: 24008,
      nome: 'Macau',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407252,
    nome: 'Major Sales',
    microrregiao: {
      id: 24005,
      nome: 'Serra de São Miguel',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407302,
    nome: 'Marcelino Vieira',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407401,
    nome: 'Martins',
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407500,
    nome: 'Maxaranguape',
    microrregiao: {
      id: 24016,
      nome: 'Litoral Nordeste',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407609,
    nome: 'Messias Targino',
    microrregiao: {
      id: 24003,
      nome: 'Médio Oeste',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407708,
    nome: 'Montanhas',
    microrregiao: {
      id: 24019,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240003,
      nome: 'Canguaretama',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407807,
    nome: 'Monte Alegre',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2407906,
    nome: 'Monte das Gameleiras',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408003,
    nome: 'Mossoró',
    microrregiao: {
      id: 24001,
      nome: 'Mossoró',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408102,
    nome: 'Natal',
    microrregiao: {
      id: 24018,
      nome: 'Natal',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408201,
    nome: 'Nísia Floresta',
    microrregiao: {
      id: 24017,
      nome: 'Macaíba',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408300,
    nome: 'Nova Cruz',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408409,
    nome: "Olho d'Água do Borges",
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408508,
    nome: 'Ouro Branco',
    microrregiao: {
      id: 24012,
      nome: 'Seridó Oriental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408607,
    nome: 'Paraná',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408706,
    nome: 'Paraú',
    microrregiao: {
      id: 24003,
      nome: 'Médio Oeste',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408805,
    nome: 'Parazinho',
    microrregiao: {
      id: 24013,
      nome: 'Baixa Verde',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240005,
      nome: 'João Câmara',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408904,
    nome: 'Parelhas',
    microrregiao: {
      id: 24012,
      nome: 'Seridó Oriental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2408953,
    nome: 'Rio do Fogo',
    microrregiao: {
      id: 24016,
      nome: 'Litoral Nordeste',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2409100,
    nome: 'Passa e Fica',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2409209,
    nome: 'Passagem',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2409308,
    nome: 'Patu',
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2409332,
    nome: 'Santa Maria',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240006,
      nome: 'São Paulo do Potengi',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2409407,
    nome: 'Pau dos Ferros',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2409506,
    nome: 'Pedra Grande',
    microrregiao: {
      id: 24016,
      nome: 'Litoral Nordeste',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240005,
      nome: 'João Câmara',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2409605,
    nome: 'Pedra Preta',
    microrregiao: {
      id: 24009,
      nome: 'Angicos',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240005,
      nome: 'João Câmara',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2409704,
    nome: 'Pedro Avelino',
    microrregiao: {
      id: 24009,
      nome: 'Angicos',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240005,
      nome: 'João Câmara',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2409803,
    nome: 'Pedro Velho',
    microrregiao: {
      id: 24019,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240003,
      nome: 'Canguaretama',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2409902,
    nome: 'Pendências',
    microrregiao: {
      id: 24004,
      nome: 'Vale do Açu',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410009,
    nome: 'Pilões',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410108,
    nome: 'Poço Branco',
    microrregiao: {
      id: 24013,
      nome: 'Baixa Verde',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410207,
    nome: 'Portalegre',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410256,
    nome: 'Porto do Mangue',
    microrregiao: {
      id: 24004,
      nome: 'Vale do Açu',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410306,
    nome: 'Serra Caiada',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410405,
    nome: 'Pureza',
    microrregiao: {
      id: 24016,
      nome: 'Litoral Nordeste',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410504,
    nome: 'Rafael Fernandes',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410603,
    nome: 'Rafael Godeiro',
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410702,
    nome: 'Riacho da Cruz',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410801,
    nome: 'Riacho de Santana',
    microrregiao: {
      id: 24005,
      nome: 'Serra de São Miguel',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2410900,
    nome: 'Riachuelo',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240006,
      nome: 'São Paulo do Potengi',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411007,
    nome: 'Rodolfo Fernandes',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411056,
    nome: 'Tibau',
    microrregiao: {
      id: 24001,
      nome: 'Mossoró',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411106,
    nome: 'Ruy Barbosa',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240006,
      nome: 'São Paulo do Potengi',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411205,
    nome: 'Santa Cruz',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240004,
      nome: 'Santa Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411403,
    nome: 'Santana do Matos',
    microrregiao: {
      id: 24010,
      nome: 'Serra de Santana',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411429,
    nome: 'Santana do Seridó',
    microrregiao: {
      id: 24012,
      nome: 'Seridó Oriental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411502,
    nome: 'Santo Antônio',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411601,
    nome: 'São Bento do Norte',
    microrregiao: {
      id: 24008,
      nome: 'Macau',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240005,
      nome: 'João Câmara',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411700,
    nome: 'São Bento do Trairí',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240004,
      nome: 'Santa Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411809,
    nome: 'São Fernando',
    microrregiao: {
      id: 24011,
      nome: 'Seridó Ocidental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2411908,
    nome: 'São Francisco do Oeste',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412005,
    nome: 'São Gonçalo do Amarante',
    microrregiao: {
      id: 24017,
      nome: 'Macaíba',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412104,
    nome: 'São João do Sabugi',
    microrregiao: {
      id: 24011,
      nome: 'Seridó Ocidental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412203,
    nome: 'São José de Mipibu',
    microrregiao: {
      id: 24017,
      nome: 'Macaíba',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412302,
    nome: 'São José do Campestre',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412401,
    nome: 'São José do Seridó',
    microrregiao: {
      id: 24012,
      nome: 'Seridó Oriental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412500,
    nome: 'São Miguel',
    microrregiao: {
      id: 24005,
      nome: 'Serra de São Miguel',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412559,
    nome: 'São Miguel do Gostoso',
    microrregiao: {
      id: 24016,
      nome: 'Litoral Nordeste',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412609,
    nome: 'São Paulo do Potengi',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240006,
      nome: 'São Paulo do Potengi',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412708,
    nome: 'São Pedro',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240006,
      nome: 'São Paulo do Potengi',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412807,
    nome: 'São Rafael',
    microrregiao: {
      id: 24004,
      nome: 'Vale do Açu',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2412906,
    nome: 'São Tomé',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240006,
      nome: 'São Paulo do Potengi',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413003,
    nome: 'São Vicente',
    microrregiao: {
      id: 24010,
      nome: 'Serra de Santana',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240008,
      nome: 'Currais Novos',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413102,
    nome: 'Senador Elói de Souza',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240006,
      nome: 'São Paulo do Potengi',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413201,
    nome: 'Senador Georgino Avelino',
    microrregiao: {
      id: 24019,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413300,
    nome: 'Serra de São Bento',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413359,
    nome: 'Serra do Mel',
    microrregiao: {
      id: 24001,
      nome: 'Mossoró',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413409,
    nome: 'Serra Negra do Norte',
    microrregiao: {
      id: 24011,
      nome: 'Seridó Ocidental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413508,
    nome: 'Serrinha',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413557,
    nome: 'Serrinha dos Pintos',
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413607,
    nome: 'Severiano Melo',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413706,
    nome: 'Sítio Novo',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240004,
      nome: 'Santa Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413805,
    nome: 'Taboleiro Grande',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2413904,
    nome: 'Taipu',
    microrregiao: {
      id: 24016,
      nome: 'Litoral Nordeste',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414001,
    nome: 'Tangará',
    microrregiao: {
      id: 24014,
      nome: 'Borborema Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240004,
      nome: 'Santa Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414100,
    nome: 'Tenente Ananias',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414159,
    nome: 'Tenente Laurentino Cruz',
    microrregiao: {
      id: 24010,
      nome: 'Serra de Santana',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240008,
      nome: 'Currais Novos',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414209,
    nome: 'Tibau do Sul',
    microrregiao: {
      id: 24019,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240003,
      nome: 'Canguaretama',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414308,
    nome: 'Timbaúba dos Batistas',
    microrregiao: {
      id: 24011,
      nome: 'Seridó Ocidental',
      mesorregiao: {
        id: 2402,
        nome: 'Central Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240007,
      nome: 'Caicó',
      'regiao-intermediaria': {
        id: 2402,
        nome: 'Caicó',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414407,
    nome: 'Touros',
    microrregiao: {
      id: 24016,
      nome: 'Litoral Nordeste',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414456,
    nome: 'Triunfo Potiguar',
    microrregiao: {
      id: 24003,
      nome: 'Médio Oeste',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240011,
      nome: 'Açu',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414506,
    nome: 'Umarizal',
    microrregiao: {
      id: 24007,
      nome: 'Umarizal',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414605,
    nome: 'Upanema',
    microrregiao: {
      id: 24003,
      nome: 'Médio Oeste',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240009,
      nome: 'Mossoró',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414704,
    nome: 'Várzea',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240002,
      nome: 'Santo Antônio - Passa e Fica - Nova Cruz',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414753,
    nome: 'Venha-Ver',
    microrregiao: {
      id: 24005,
      nome: 'Serra de São Miguel',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414803,
    nome: 'Vera Cruz',
    microrregiao: {
      id: 24015,
      nome: 'Agreste Potiguar',
      mesorregiao: {
        id: 2403,
        nome: 'Agreste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240001,
      nome: 'Natal',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2414902,
    nome: 'Viçosa',
    microrregiao: {
      id: 24006,
      nome: 'Pau dos Ferros',
      mesorregiao: {
        id: 2401,
        nome: 'Oeste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240010,
      nome: 'Pau dos Ferros',
      'regiao-intermediaria': {
        id: 2403,
        nome: 'Mossoró',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2415008,
    nome: 'Vila Flor',
    microrregiao: {
      id: 24019,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2404,
        nome: 'Leste Potiguar',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 240003,
      nome: 'Canguaretama',
      'regiao-intermediaria': {
        id: 2401,
        nome: 'Natal',
        UF: {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500106,
    nome: 'Água Branca',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500205,
    nome: 'Aguiar',
    microrregiao: {
      id: 25005,
      nome: 'Piancó',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500304,
    nome: 'Alagoa Grande',
    microrregiao: {
      id: 25015,
      nome: 'Brejo Paraibano',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500403,
    nome: 'Alagoa Nova',
    microrregiao: {
      id: 25015,
      nome: 'Brejo Paraibano',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500502,
    nome: 'Alagoinha',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500536,
    nome: 'Alcantil',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500577,
    nome: 'Algodão de Jandaíra',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500601,
    nome: 'Alhandra',
    microrregiao: {
      id: 25023,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500700,
    nome: 'São João do Rio do Peixe',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500734,
    nome: 'Amparo',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250008,
      nome: 'Sumé',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500775,
    nome: 'Aparecida',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500809,
    nome: 'Araçagi',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2500908,
    nome: 'Arara',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501005,
    nome: 'Araruna',
    microrregiao: {
      id: 25013,
      nome: 'Curimataú Oriental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501104,
    nome: 'Areia',
    microrregiao: {
      id: 25015,
      nome: 'Brejo Paraibano',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501153,
    nome: 'Areia de Baraúnas',
    microrregiao: {
      id: 25004,
      nome: 'Patos',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501203,
    nome: 'Areial',
    microrregiao: {
      id: 25014,
      nome: 'Esperança',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501302,
    nome: 'Aroeiras',
    microrregiao: {
      id: 25019,
      nome: 'Umbuzeiro',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501351,
    nome: 'Assunção',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501401,
    nome: 'Baía da Traição',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250003,
      nome: 'Mamanguape - Rio Tinto',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501500,
    nome: 'Bananeiras',
    microrregiao: {
      id: 25015,
      nome: 'Brejo Paraibano',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501534,
    nome: 'Baraúna',
    microrregiao: {
      id: 25009,
      nome: 'Seridó Oriental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250006,
      nome: 'Cuité - Nova Floresta',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501575,
    nome: 'Barra de Santana',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501609,
    nome: 'Barra de Santa Rosa',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250006,
      nome: 'Cuité - Nova Floresta',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501708,
    nome: 'Barra de São Miguel',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501807,
    nome: 'Bayeux',
    microrregiao: {
      id: 25022,
      nome: 'João Pessoa',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2501906,
    nome: 'Belém',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502003,
    nome: 'Belém do Brejo do Cruz',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250011,
      nome: 'Catolé do Rocha - São Bento',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502052,
    nome: 'Bernardino Batista',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502102,
    nome: 'Boa Ventura',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502151,
    nome: 'Boa Vista',
    microrregiao: {
      id: 25017,
      nome: 'Campina Grande',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502201,
    nome: 'Bom Jesus',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502300,
    nome: 'Bom Sucesso',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250011,
      nome: 'Catolé do Rocha - São Bento',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502409,
    nome: 'Bonito de Santa Fé',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502508,
    nome: 'Boqueirão',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502607,
    nome: 'Igaracy',
    microrregiao: {
      id: 25005,
      nome: 'Piancó',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502706,
    nome: 'Borborema',
    microrregiao: {
      id: 25015,
      nome: 'Brejo Paraibano',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502805,
    nome: 'Brejo do Cruz',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250011,
      nome: 'Catolé do Rocha - São Bento',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2502904,
    nome: 'Brejo dos Santos',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250011,
      nome: 'Catolé do Rocha - São Bento',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503001,
    nome: 'Caaporã',
    microrregiao: {
      id: 25023,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503100,
    nome: 'Cabaceiras',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503209,
    nome: 'Cabedelo',
    microrregiao: {
      id: 25022,
      nome: 'João Pessoa',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503308,
    nome: 'Cachoeira dos Índios',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503407,
    nome: 'Cacimba de Areia',
    microrregiao: {
      id: 25004,
      nome: 'Patos',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503506,
    nome: 'Cacimba de Dentro',
    microrregiao: {
      id: 25013,
      nome: 'Curimataú Oriental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503555,
    nome: 'Cacimbas',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503605,
    nome: 'Caiçara',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503704,
    nome: 'Cajazeiras',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503753,
    nome: 'Cajazeirinhas',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250012,
      nome: 'Pombal',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503803,
    nome: 'Caldas Brandão',
    microrregiao: {
      id: 25018,
      nome: 'Itabaiana',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2503902,
    nome: 'Camalaú',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250007,
      nome: 'Monteiro',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504009,
    nome: 'Campina Grande',
    microrregiao: {
      id: 25017,
      nome: 'Campina Grande',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504033,
    nome: 'Capim',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250003,
      nome: 'Mamanguape - Rio Tinto',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504074,
    nome: 'Caraúbas',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504108,
    nome: 'Carrapateira',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504157,
    nome: 'Casserengue',
    microrregiao: {
      id: 25013,
      nome: 'Curimataú Oriental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504207,
    nome: 'Catingueira',
    microrregiao: {
      id: 25005,
      nome: 'Piancó',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504306,
    nome: 'Catolé do Rocha',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250011,
      nome: 'Catolé do Rocha - São Bento',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504355,
    nome: 'Caturité',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504405,
    nome: 'Conceição',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504504,
    nome: 'Condado',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250012,
      nome: 'Pombal',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504603,
    nome: 'Conde',
    microrregiao: {
      id: 25022,
      nome: 'João Pessoa',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504702,
    nome: 'Congo',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250008,
      nome: 'Sumé',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504801,
    nome: 'Coremas',
    microrregiao: {
      id: 25005,
      nome: 'Piancó',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504850,
    nome: 'Coxixola',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250008,
      nome: 'Sumé',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2504900,
    nome: 'Cruz do Espírito Santo',
    microrregiao: {
      id: 25021,
      nome: 'Sapé',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505006,
    nome: 'Cubati',
    microrregiao: {
      id: 25009,
      nome: 'Seridó Oriental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505105,
    nome: 'Cuité',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250006,
      nome: 'Cuité - Nova Floresta',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505204,
    nome: 'Cuitegi',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505238,
    nome: 'Cuité de Mamanguape',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505279,
    nome: 'Curral de Cima',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250003,
      nome: 'Mamanguape - Rio Tinto',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505303,
    nome: 'Curral Velho',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505352,
    nome: 'Damião',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250006,
      nome: 'Cuité - Nova Floresta',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505402,
    nome: 'Desterro',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505501,
    nome: 'Vista Serrana',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505600,
    nome: 'Diamante',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505709,
    nome: 'Dona Inês',
    microrregiao: {
      id: 25013,
      nome: 'Curimataú Oriental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505808,
    nome: 'Duas Estradas',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2505907,
    nome: 'Emas',
    microrregiao: {
      id: 25005,
      nome: 'Piancó',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506004,
    nome: 'Esperança',
    microrregiao: {
      id: 25014,
      nome: 'Esperança',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506103,
    nome: 'Fagundes',
    microrregiao: {
      id: 25017,
      nome: 'Campina Grande',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506202,
    nome: 'Frei Martinho',
    microrregiao: {
      id: 25009,
      nome: 'Seridó Oriental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250006,
      nome: 'Cuité - Nova Floresta',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506251,
    nome: 'Gado Bravo',
    microrregiao: {
      id: 25019,
      nome: 'Umbuzeiro',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506301,
    nome: 'Guarabira',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506400,
    nome: 'Gurinhém',
    microrregiao: {
      id: 25018,
      nome: 'Itabaiana',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506509,
    nome: 'Gurjão',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506608,
    nome: 'Ibiara',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506707,
    nome: 'Imaculada',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506806,
    nome: 'Ingá',
    microrregiao: {
      id: 25018,
      nome: 'Itabaiana',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2506905,
    nome: 'Itabaiana',
    microrregiao: {
      id: 25018,
      nome: 'Itabaiana',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2507002,
    nome: 'Itaporanga',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2507101,
    nome: 'Itapororoca',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250003,
      nome: 'Mamanguape - Rio Tinto',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2507200,
    nome: 'Itatuba',
    microrregiao: {
      id: 25018,
      nome: 'Itabaiana',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2507309,
    nome: 'Jacaraú',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250003,
      nome: 'Mamanguape - Rio Tinto',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2507408,
    nome: 'Jericó',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250011,
      nome: 'Catolé do Rocha - São Bento',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2507507,
    nome: 'João Pessoa',
    microrregiao: {
      id: 25022,
      nome: 'João Pessoa',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2507606,
    nome: 'Juarez Távora',
    microrregiao: {
      id: 25018,
      nome: 'Itabaiana',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2507705,
    nome: 'Juazeirinho',
    microrregiao: {
      id: 25009,
      nome: 'Seridó Oriental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2507804,
    nome: 'Junco do Seridó',
    microrregiao: {
      id: 25008,
      nome: 'Seridó Ocidental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2507903,
    nome: 'Juripiranga',
    microrregiao: {
      id: 25021,
      nome: 'Sapé',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508000,
    nome: 'Juru',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250013,
      nome: 'Princesa Isabel',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508109,
    nome: 'Lagoa',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250012,
      nome: 'Pombal',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508208,
    nome: 'Lagoa de Dentro',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508307,
    nome: 'Lagoa Seca',
    microrregiao: {
      id: 25017,
      nome: 'Campina Grande',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508406,
    nome: 'Lastro',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508505,
    nome: 'Livramento',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250008,
      nome: 'Sumé',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508554,
    nome: 'Logradouro',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508604,
    nome: 'Lucena',
    microrregiao: {
      id: 25022,
      nome: 'João Pessoa',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508703,
    nome: "Mãe d'Água",
    microrregiao: {
      id: 25004,
      nome: 'Patos',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508802,
    nome: 'Malta',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2508901,
    nome: 'Mamanguape',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250003,
      nome: 'Mamanguape - Rio Tinto',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509008,
    nome: 'Manaíra',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250013,
      nome: 'Princesa Isabel',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509057,
    nome: 'Marcação',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250003,
      nome: 'Mamanguape - Rio Tinto',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509107,
    nome: 'Mari',
    microrregiao: {
      id: 25021,
      nome: 'Sapé',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509156,
    nome: 'Marizópolis',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509206,
    nome: 'Massaranduba',
    microrregiao: {
      id: 25017,
      nome: 'Campina Grande',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509305,
    nome: 'Mataraca',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250003,
      nome: 'Mamanguape - Rio Tinto',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509339,
    nome: 'Matinhas',
    microrregiao: {
      id: 25015,
      nome: 'Brejo Paraibano',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509370,
    nome: 'Mato Grosso',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250011,
      nome: 'Catolé do Rocha - São Bento',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509396,
    nome: 'Maturéia',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509404,
    nome: 'Mogeiro',
    microrregiao: {
      id: 25018,
      nome: 'Itabaiana',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509503,
    nome: 'Montadas',
    microrregiao: {
      id: 25014,
      nome: 'Esperança',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509602,
    nome: 'Monte Horebe',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509701,
    nome: 'Monteiro',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250007,
      nome: 'Monteiro',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509800,
    nome: 'Mulungu',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2509909,
    nome: 'Natuba',
    microrregiao: {
      id: 25019,
      nome: 'Umbuzeiro',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510006,
    nome: 'Nazarezinho',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510105,
    nome: 'Nova Floresta',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250006,
      nome: 'Cuité - Nova Floresta',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510204,
    nome: 'Nova Olinda',
    microrregiao: {
      id: 25005,
      nome: 'Piancó',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510303,
    nome: 'Nova Palmeira',
    microrregiao: {
      id: 25009,
      nome: 'Seridó Oriental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250006,
      nome: 'Cuité - Nova Floresta',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510402,
    nome: "Olho d'Água",
    microrregiao: {
      id: 25005,
      nome: 'Piancó',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510501,
    nome: 'Olivedos',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510600,
    nome: 'Ouro Velho',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250007,
      nome: 'Monteiro',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510659,
    nome: 'Parari',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250008,
      nome: 'Sumé',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510709,
    nome: 'Passagem',
    microrregiao: {
      id: 25004,
      nome: 'Patos',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510808,
    nome: 'Patos',
    microrregiao: {
      id: 25004,
      nome: 'Patos',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2510907,
    nome: 'Paulista',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250012,
      nome: 'Pombal',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2511004,
    nome: 'Pedra Branca',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2511103,
    nome: 'Pedra Lavrada',
    microrregiao: {
      id: 25009,
      nome: 'Seridó Oriental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250006,
      nome: 'Cuité - Nova Floresta',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2511202,
    nome: 'Pedras de Fogo',
    microrregiao: {
      id: 25023,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2511301,
    nome: 'Piancó',
    microrregiao: {
      id: 25005,
      nome: 'Piancó',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2511400,
    nome: 'Picuí',
    microrregiao: {
      id: 25009,
      nome: 'Seridó Oriental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250006,
      nome: 'Cuité - Nova Floresta',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2511509,
    nome: 'Pilar',
    microrregiao: {
      id: 25021,
      nome: 'Sapé',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2511608,
    nome: 'Pilões',
    microrregiao: {
      id: 25015,
      nome: 'Brejo Paraibano',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2511707,
    nome: 'Pilõezinhos',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2511806,
    nome: 'Pirpirituba',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2511905,
    nome: 'Pitimbu',
    microrregiao: {
      id: 25023,
      nome: 'Litoral Sul',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512002,
    nome: 'Pocinhos',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512036,
    nome: 'Poço Dantas',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512077,
    nome: 'Poço de José de Moura',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512101,
    nome: 'Pombal',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250012,
      nome: 'Pombal',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512200,
    nome: 'Prata',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250007,
      nome: 'Monteiro',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512309,
    nome: 'Princesa Isabel',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250013,
      nome: 'Princesa Isabel',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512408,
    nome: 'Puxinanã',
    microrregiao: {
      id: 25017,
      nome: 'Campina Grande',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512507,
    nome: 'Queimadas',
    microrregiao: {
      id: 25017,
      nome: 'Campina Grande',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512606,
    nome: 'Quixaba',
    microrregiao: {
      id: 25004,
      nome: 'Patos',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512705,
    nome: 'Remígio',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512721,
    nome: 'Pedro Régis',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250003,
      nome: 'Mamanguape - Rio Tinto',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512747,
    nome: 'Riachão',
    microrregiao: {
      id: 25013,
      nome: 'Curimataú Oriental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512754,
    nome: 'Riachão do Bacamarte',
    microrregiao: {
      id: 25018,
      nome: 'Itabaiana',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512762,
    nome: 'Riachão do Poço',
    microrregiao: {
      id: 25021,
      nome: 'Sapé',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512788,
    nome: 'Riacho de Santo Antônio',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512804,
    nome: 'Riacho dos Cavalos',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250011,
      nome: 'Catolé do Rocha - São Bento',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2512903,
    nome: 'Rio Tinto',
    microrregiao: {
      id: 25020,
      nome: 'Litoral Norte',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250003,
      nome: 'Mamanguape - Rio Tinto',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513000,
    nome: 'Salgadinho',
    microrregiao: {
      id: 25008,
      nome: 'Seridó Ocidental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513109,
    nome: 'Salgado de São Félix',
    microrregiao: {
      id: 25018,
      nome: 'Itabaiana',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513158,
    nome: 'Santa Cecília',
    microrregiao: {
      id: 25019,
      nome: 'Umbuzeiro',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513208,
    nome: 'Santa Cruz',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513307,
    nome: 'Santa Helena',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513356,
    nome: 'Santa Inês',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513406,
    nome: 'Santa Luzia',
    microrregiao: {
      id: 25008,
      nome: 'Seridó Ocidental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513505,
    nome: 'Santana de Mangueira',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513604,
    nome: 'Santana dos Garrotes',
    microrregiao: {
      id: 25005,
      nome: 'Piancó',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513653,
    nome: 'Joca Claudino',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513703,
    nome: 'Santa Rita',
    microrregiao: {
      id: 25022,
      nome: 'João Pessoa',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513802,
    nome: 'Santa Teresinha',
    microrregiao: {
      id: 25004,
      nome: 'Patos',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513851,
    nome: 'Santo André',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513901,
    nome: 'São Bento',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250011,
      nome: 'Catolé do Rocha - São Bento',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513927,
    nome: 'São Bentinho',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250012,
      nome: 'Pombal',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513943,
    nome: 'São Domingos do Cariri',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513968,
    nome: 'São Domingos',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250012,
      nome: 'Pombal',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2513984,
    nome: 'São Francisco',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514008,
    nome: 'São João do Cariri',
    microrregiao: {
      id: 25011,
      nome: 'Cariri Oriental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514107,
    nome: 'São João do Tigre',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250007,
      nome: 'Monteiro',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514206,
    nome: 'São José da Lagoa Tapada',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514305,
    nome: 'São José de Caiana',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250010,
      nome: 'Itaporanga',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514404,
    nome: 'São José de Espinharas',
    microrregiao: {
      id: 25004,
      nome: 'Patos',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514453,
    nome: 'São José dos Ramos',
    microrregiao: {
      id: 25021,
      nome: 'Sapé',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514503,
    nome: 'São José de Piranhas',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514552,
    nome: 'São José de Princesa',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250013,
      nome: 'Princesa Isabel',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514602,
    nome: 'São José do Bonfim',
    microrregiao: {
      id: 25004,
      nome: 'Patos',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514651,
    nome: 'São José do Brejo do Cruz',
    microrregiao: {
      id: 25001,
      nome: 'Catolé do Rocha',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250011,
      nome: 'Catolé do Rocha - São Bento',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514701,
    nome: 'São José do Sabugi',
    microrregiao: {
      id: 25008,
      nome: 'Seridó Ocidental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514800,
    nome: 'São José dos Cordeiros',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250008,
      nome: 'Sumé',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2514909,
    nome: 'São Mamede',
    microrregiao: {
      id: 25008,
      nome: 'Seridó Ocidental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515005,
    nome: 'São Miguel de Taipu',
    microrregiao: {
      id: 25021,
      nome: 'Sapé',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515104,
    nome: 'São Sebastião de Lagoa de Roça',
    microrregiao: {
      id: 25014,
      nome: 'Esperança',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515203,
    nome: 'São Sebastião do Umbuzeiro',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250007,
      nome: 'Monteiro',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515302,
    nome: 'Sapé',
    microrregiao: {
      id: 25021,
      nome: 'Sapé',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515401,
    nome: 'São Vicente do Seridó',
    microrregiao: {
      id: 25009,
      nome: 'Seridó Oriental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515500,
    nome: 'Serra Branca',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250008,
      nome: 'Sumé',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515609,
    nome: 'Serra da Raiz',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515708,
    nome: 'Serra Grande',
    microrregiao: {
      id: 25006,
      nome: 'Itaporanga',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515807,
    nome: 'Serra Redonda',
    microrregiao: {
      id: 25017,
      nome: 'Campina Grande',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515906,
    nome: 'Serraria',
    microrregiao: {
      id: 25015,
      nome: 'Brejo Paraibano',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515930,
    nome: 'Sertãozinho',
    microrregiao: {
      id: 25016,
      nome: 'Guarabira',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2515971,
    nome: 'Sobrado',
    microrregiao: {
      id: 25021,
      nome: 'Sapé',
      mesorregiao: {
        id: 2504,
        nome: 'Mata Paraibana',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250001,
      nome: 'João Pessoa',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516003,
    nome: 'Solânea',
    microrregiao: {
      id: 25013,
      nome: 'Curimataú Oriental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516102,
    nome: 'Soledade',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516151,
    nome: 'Sossêgo',
    microrregiao: {
      id: 25012,
      nome: 'Curimataú Ocidental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250006,
      nome: 'Cuité - Nova Floresta',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516201,
    nome: 'Sousa',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516300,
    nome: 'Sumé',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250008,
      nome: 'Sumé',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516409,
    nome: 'Tacima',
    microrregiao: {
      id: 25013,
      nome: 'Curimataú Oriental',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250002,
      nome: 'Guarabira',
      'regiao-intermediaria': {
        id: 2501,
        nome: 'João Pessoa',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516508,
    nome: 'Taperoá',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516607,
    nome: 'Tavares',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250013,
      nome: 'Princesa Isabel',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516706,
    nome: 'Teixeira',
    microrregiao: {
      id: 25007,
      nome: 'Serra do Teixeira',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516755,
    nome: 'Tenório',
    microrregiao: {
      id: 25009,
      nome: 'Seridó Oriental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516805,
    nome: 'Triunfo',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250015,
      nome: 'Cajazeiras',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2516904,
    nome: 'Uiraúna',
    microrregiao: {
      id: 25002,
      nome: 'Cajazeiras',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2517001,
    nome: 'Umbuzeiro',
    microrregiao: {
      id: 25019,
      nome: 'Umbuzeiro',
      mesorregiao: {
        id: 2503,
        nome: 'Agreste Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250005,
      nome: 'Campina Grande',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2517100,
    nome: 'Várzea',
    microrregiao: {
      id: 25008,
      nome: 'Seridó Ocidental Paraibano',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250009,
      nome: 'Patos',
      'regiao-intermediaria': {
        id: 2503,
        nome: 'Patos',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2517209,
    nome: 'Vieirópolis',
    microrregiao: {
      id: 25003,
      nome: 'Sousa',
      mesorregiao: {
        id: 2501,
        nome: 'Sertão Paraibano',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250014,
      nome: 'Sousa',
      'regiao-intermediaria': {
        id: 2504,
        nome: 'Sousa - Cajazeiras',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2517407,
    nome: 'Zabelê',
    microrregiao: {
      id: 25010,
      nome: 'Cariri Ocidental',
      mesorregiao: {
        id: 2502,
        nome: 'Borborema',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 250007,
      nome: 'Monteiro',
      'regiao-intermediaria': {
        id: 2502,
        nome: 'Campina Grande',
        UF: {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2600054,
    nome: 'Abreu e Lima',
    microrregiao: {
      id: 26017,
      nome: 'Recife',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2600104,
    nome: 'Afogados da Ingazeira',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2600203,
    nome: 'Afrânio',
    microrregiao: {
      id: 26005,
      nome: 'Petrolina',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260015,
      nome: 'Petrolina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2600302,
    nome: 'Agrestina',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2600401,
    nome: 'Água Preta',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260003,
      nome: 'Palmares',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2600500,
    nome: 'Águas Belas',
    microrregiao: {
      id: 26007,
      nome: 'Vale do Ipanema',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2600609,
    nome: 'Alagoinha',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260012,
      nome: 'Belo Jardim - Pesqueira',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2600708,
    nome: 'Aliança',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2600807,
    nome: 'Altinho',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2600906,
    nome: 'Amaraji',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260018,
      nome: 'Escada - Ribeirão',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601003,
    nome: 'Angelim',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601052,
    nome: 'Araçoiaba',
    microrregiao: {
      id: 26016,
      nome: 'Itamaracá',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601102,
    nome: 'Araripina',
    microrregiao: {
      id: 26001,
      nome: 'Araripina',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260016,
      nome: 'Araripina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601201,
    nome: 'Arcoverde',
    microrregiao: {
      id: 26004,
      nome: 'Sertão do Moxotó',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601300,
    nome: 'Barra de Guabiraba',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601409,
    nome: 'Barreiros',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260007,
      nome: 'Barreiros - Sirinhaém',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601508,
    nome: 'Belém de Maria',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260003,
      nome: 'Palmares',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601607,
    nome: 'Belém do São Francisco',
    microrregiao: {
      id: 26006,
      nome: 'Itaparica',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260017,
      nome: 'Salgueiro',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601706,
    nome: 'Belo Jardim',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260012,
      nome: 'Belo Jardim - Pesqueira',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601805,
    nome: 'Betânia',
    microrregiao: {
      id: 26004,
      nome: 'Sertão do Moxotó',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2601904,
    nome: 'Bezerros',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2602001,
    nome: 'Bodocó',
    microrregiao: {
      id: 26001,
      nome: 'Araripina',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260016,
      nome: 'Araripina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2602100,
    nome: 'Bom Conselho',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2602209,
    nome: 'Bom Jardim',
    microrregiao: {
      id: 26010,
      nome: 'Médio Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260004,
      nome: 'Limoeiro',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2602308,
    nome: 'Bonito',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2602407,
    nome: 'Brejão',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2602506,
    nome: 'Brejinho',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2602605,
    nome: 'Brejo da Madre de Deus',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2602704,
    nome: 'Buenos Aires',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260006,
      nome: 'Carpina',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2602803,
    nome: 'Buíque',
    microrregiao: {
      id: 26007,
      nome: 'Vale do Ipanema',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2602902,
    nome: 'Cabo de Santo Agostinho',
    microrregiao: {
      id: 26018,
      nome: 'Suape',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603009,
    nome: 'Cabrobó',
    microrregiao: {
      id: 26005,
      nome: 'Petrolina',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260017,
      nome: 'Salgueiro',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603108,
    nome: 'Cachoeirinha',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603207,
    nome: 'Caetés',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603306,
    nome: 'Calçado',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603405,
    nome: 'Calumbi',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603454,
    nome: 'Camaragibe',
    microrregiao: {
      id: 26017,
      nome: 'Recife',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603504,
    nome: 'Camocim de São Félix',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603603,
    nome: 'Camutanga',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603702,
    nome: 'Canhotinho',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603801,
    nome: 'Capoeiras',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603900,
    nome: 'Carnaíba',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2603926,
    nome: 'Carnaubeira da Penha',
    microrregiao: {
      id: 26006,
      nome: 'Itaparica',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604007,
    nome: 'Carpina',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260006,
      nome: 'Carpina',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604106,
    nome: 'Caruaru',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604155,
    nome: 'Casinhas',
    microrregiao: {
      id: 26009,
      nome: 'Alto Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260008,
      nome: 'Surubim',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604205,
    nome: 'Catende',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260003,
      nome: 'Palmares',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604304,
    nome: 'Cedro',
    microrregiao: {
      id: 26002,
      nome: 'Salgueiro',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260017,
      nome: 'Salgueiro',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604403,
    nome: 'Chã de Alegria',
    microrregiao: {
      id: 26014,
      nome: 'Vitória de Santo Antão',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260005,
      nome: 'Vitória de Santo Antão',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604502,
    nome: 'Chã Grande',
    microrregiao: {
      id: 26014,
      nome: 'Vitória de Santo Antão',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604601,
    nome: 'Condado',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604700,
    nome: 'Correntes',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604809,
    nome: 'Cortês',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260018,
      nome: 'Escada - Ribeirão',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2604908,
    nome: 'Cumaru',
    microrregiao: {
      id: 26010,
      nome: 'Médio Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605004,
    nome: 'Cupira',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605103,
    nome: 'Custódia',
    microrregiao: {
      id: 26004,
      nome: 'Sertão do Moxotó',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605152,
    nome: 'Dormentes',
    microrregiao: {
      id: 26005,
      nome: 'Petrolina',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260015,
      nome: 'Petrolina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605202,
    nome: 'Escada',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260018,
      nome: 'Escada - Ribeirão',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605301,
    nome: 'Exu',
    microrregiao: {
      id: 26001,
      nome: 'Araripina',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260016,
      nome: 'Araripina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605400,
    nome: 'Feira Nova',
    microrregiao: {
      id: 26010,
      nome: 'Médio Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260004,
      nome: 'Limoeiro',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605459,
    nome: 'Fernando de Noronha',
    microrregiao: {
      id: 26019,
      nome: 'Fernando de Noronha',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605509,
    nome: 'Ferreiros',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605608,
    nome: 'Flores',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605707,
    nome: 'Floresta',
    microrregiao: {
      id: 26006,
      nome: 'Itaparica',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605806,
    nome: 'Frei Miguelinho',
    microrregiao: {
      id: 26009,
      nome: 'Alto Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260008,
      nome: 'Surubim',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2605905,
    nome: 'Gameleira',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260003,
      nome: 'Palmares',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2606002,
    nome: 'Garanhuns',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2606101,
    nome: 'Glória do Goitá',
    microrregiao: {
      id: 26014,
      nome: 'Vitória de Santo Antão',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260005,
      nome: 'Vitória de Santo Antão',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2606200,
    nome: 'Goiana',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2606309,
    nome: 'Granito',
    microrregiao: {
      id: 26001,
      nome: 'Araripina',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260016,
      nome: 'Araripina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2606408,
    nome: 'Gravatá',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2606507,
    nome: 'Iati',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2606606,
    nome: 'Ibimirim',
    microrregiao: {
      id: 26004,
      nome: 'Sertão do Moxotó',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2606705,
    nome: 'Ibirajuba',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2606804,
    nome: 'Igarassu',
    microrregiao: {
      id: 26016,
      nome: 'Itamaracá',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2606903,
    nome: 'Iguaracy',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607000,
    nome: 'Inajá',
    microrregiao: {
      id: 26004,
      nome: 'Sertão do Moxotó',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607109,
    nome: 'Ingazeira',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607208,
    nome: 'Ipojuca',
    microrregiao: {
      id: 26018,
      nome: 'Suape',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607307,
    nome: 'Ipubi',
    microrregiao: {
      id: 26001,
      nome: 'Araripina',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260016,
      nome: 'Araripina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607406,
    nome: 'Itacuruba',
    microrregiao: {
      id: 26006,
      nome: 'Itaparica',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260017,
      nome: 'Salgueiro',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607505,
    nome: 'Itaíba',
    microrregiao: {
      id: 26007,
      nome: 'Vale do Ipanema',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607604,
    nome: 'Ilha de Itamaracá',
    microrregiao: {
      id: 26016,
      nome: 'Itamaracá',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607653,
    nome: 'Itambé',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607703,
    nome: 'Itapetim',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607752,
    nome: 'Itapissuma',
    microrregiao: {
      id: 26016,
      nome: 'Itamaracá',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607802,
    nome: 'Itaquitinga',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607901,
    nome: 'Jaboatão dos Guararapes',
    microrregiao: {
      id: 26017,
      nome: 'Recife',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2607950,
    nome: 'Jaqueira',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260003,
      nome: 'Palmares',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608008,
    nome: 'Jataúba',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608057,
    nome: 'Jatobá',
    microrregiao: {
      id: 26006,
      nome: 'Itaparica',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608107,
    nome: 'João Alfredo',
    microrregiao: {
      id: 26010,
      nome: 'Médio Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260004,
      nome: 'Limoeiro',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608206,
    nome: 'Joaquim Nabuco',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260003,
      nome: 'Palmares',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608255,
    nome: 'Jucati',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608305,
    nome: 'Jupi',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608404,
    nome: 'Jurema',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608453,
    nome: 'Lagoa do Carro',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260006,
      nome: 'Carpina',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608503,
    nome: 'Lagoa de Itaenga',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260006,
      nome: 'Carpina',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608602,
    nome: 'Lagoa do Ouro',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608701,
    nome: 'Lagoa dos Gatos',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608750,
    nome: 'Lagoa Grande',
    microrregiao: {
      id: 26005,
      nome: 'Petrolina',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260015,
      nome: 'Petrolina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608800,
    nome: 'Lajedo',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2608909,
    nome: 'Limoeiro',
    microrregiao: {
      id: 26010,
      nome: 'Médio Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260004,
      nome: 'Limoeiro',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609006,
    nome: 'Macaparana',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609105,
    nome: 'Machados',
    microrregiao: {
      id: 26010,
      nome: 'Médio Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260004,
      nome: 'Limoeiro',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609154,
    nome: 'Manari',
    microrregiao: {
      id: 26004,
      nome: 'Sertão do Moxotó',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609204,
    nome: 'Maraial',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260003,
      nome: 'Palmares',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609303,
    nome: 'Mirandiba',
    microrregiao: {
      id: 26002,
      nome: 'Salgueiro',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609402,
    nome: 'Moreno',
    microrregiao: {
      id: 26017,
      nome: 'Recife',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609501,
    nome: 'Nazaré da Mata',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260006,
      nome: 'Carpina',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609600,
    nome: 'Olinda',
    microrregiao: {
      id: 26017,
      nome: 'Recife',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609709,
    nome: 'Orobó',
    microrregiao: {
      id: 26010,
      nome: 'Médio Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260004,
      nome: 'Limoeiro',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609808,
    nome: 'Orocó',
    microrregiao: {
      id: 26005,
      nome: 'Petrolina',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260015,
      nome: 'Petrolina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2609907,
    nome: 'Ouricuri',
    microrregiao: {
      id: 26001,
      nome: 'Araripina',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260016,
      nome: 'Araripina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2610004,
    nome: 'Palmares',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260003,
      nome: 'Palmares',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2610103,
    nome: 'Palmeirina',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2610202,
    nome: 'Panelas',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2610301,
    nome: 'Paranatama',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2610400,
    nome: 'Parnamirim',
    microrregiao: {
      id: 26002,
      nome: 'Salgueiro',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260017,
      nome: 'Salgueiro',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2610509,
    nome: 'Passira',
    microrregiao: {
      id: 26010,
      nome: 'Médio Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260004,
      nome: 'Limoeiro',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2610608,
    nome: 'Paudalho',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2610707,
    nome: 'Paulista',
    microrregiao: {
      id: 26017,
      nome: 'Recife',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2610806,
    nome: 'Pedra',
    microrregiao: {
      id: 26007,
      nome: 'Vale do Ipanema',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2610905,
    nome: 'Pesqueira',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260012,
      nome: 'Belo Jardim - Pesqueira',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611002,
    nome: 'Petrolândia',
    microrregiao: {
      id: 26006,
      nome: 'Itaparica',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611101,
    nome: 'Petrolina',
    microrregiao: {
      id: 26005,
      nome: 'Petrolina',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260015,
      nome: 'Petrolina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611200,
    nome: 'Poção',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260012,
      nome: 'Belo Jardim - Pesqueira',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611309,
    nome: 'Pombos',
    microrregiao: {
      id: 26014,
      nome: 'Vitória de Santo Antão',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260005,
      nome: 'Vitória de Santo Antão',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611408,
    nome: 'Primavera',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260018,
      nome: 'Escada - Ribeirão',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611507,
    nome: 'Quipapá',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611533,
    nome: 'Quixaba',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611606,
    nome: 'Recife',
    microrregiao: {
      id: 26017,
      nome: 'Recife',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611705,
    nome: 'Riacho das Almas',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611804,
    nome: 'Ribeirão',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260018,
      nome: 'Escada - Ribeirão',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2611903,
    nome: 'Rio Formoso',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260007,
      nome: 'Barreiros - Sirinhaém',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612000,
    nome: 'Sairé',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612109,
    nome: 'Salgadinho',
    microrregiao: {
      id: 26010,
      nome: 'Médio Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260004,
      nome: 'Limoeiro',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612208,
    nome: 'Salgueiro',
    microrregiao: {
      id: 26002,
      nome: 'Salgueiro',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260017,
      nome: 'Salgueiro',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612307,
    nome: 'Saloá',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612406,
    nome: 'Sanharó',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260012,
      nome: 'Belo Jardim - Pesqueira',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612455,
    nome: 'Santa Cruz',
    microrregiao: {
      id: 26001,
      nome: 'Araripina',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260016,
      nome: 'Araripina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612471,
    nome: 'Santa Cruz da Baixa Verde',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612505,
    nome: 'Santa Cruz do Capibaribe',
    microrregiao: {
      id: 26009,
      nome: 'Alto Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612554,
    nome: 'Santa Filomena',
    microrregiao: {
      id: 26001,
      nome: 'Araripina',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260016,
      nome: 'Araripina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612604,
    nome: 'Santa Maria da Boa Vista',
    microrregiao: {
      id: 26005,
      nome: 'Petrolina',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260015,
      nome: 'Petrolina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612703,
    nome: 'Santa Maria do Cambucá',
    microrregiao: {
      id: 26009,
      nome: 'Alto Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260008,
      nome: 'Surubim',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612802,
    nome: 'Santa Terezinha',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2612901,
    nome: 'São Benedito do Sul',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260003,
      nome: 'Palmares',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2613008,
    nome: 'São Bento do Una',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260012,
      nome: 'Belo Jardim - Pesqueira',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2613107,
    nome: 'São Caitano',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2613206,
    nome: 'São João',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2613305,
    nome: 'São Joaquim do Monte',
    microrregiao: {
      id: 26012,
      nome: 'Brejo Pernambucano',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2613404,
    nome: 'São José da Coroa Grande',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260007,
      nome: 'Barreiros - Sirinhaém',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2613503,
    nome: 'São José do Belmonte',
    microrregiao: {
      id: 26002,
      nome: 'Salgueiro',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2613602,
    nome: 'São José do Egito',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2613701,
    nome: 'São Lourenço da Mata',
    microrregiao: {
      id: 26017,
      nome: 'Recife',
      mesorregiao: {
        id: 2605,
        nome: 'Metropolitana de Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260001,
      nome: 'Recife',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2613800,
    nome: 'São Vicente Férrer',
    microrregiao: {
      id: 26010,
      nome: 'Médio Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2613909,
    nome: 'Serra Talhada',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2614006,
    nome: 'Serrita',
    microrregiao: {
      id: 26002,
      nome: 'Salgueiro',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260017,
      nome: 'Salgueiro',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2614105,
    nome: 'Sertânia',
    microrregiao: {
      id: 26004,
      nome: 'Sertão do Moxotó',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2614204,
    nome: 'Sirinhaém',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260007,
      nome: 'Barreiros - Sirinhaém',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2614303,
    nome: 'Moreilândia',
    microrregiao: {
      id: 26001,
      nome: 'Araripina',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260016,
      nome: 'Araripina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2614402,
    nome: 'Solidão',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2614501,
    nome: 'Surubim',
    microrregiao: {
      id: 26009,
      nome: 'Alto Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260008,
      nome: 'Surubim',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2614600,
    nome: 'Tabira',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2614709,
    nome: 'Tacaimbó',
    microrregiao: {
      id: 26008,
      nome: 'Vale do Ipojuca',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260012,
      nome: 'Belo Jardim - Pesqueira',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2614808,
    nome: 'Tacaratu',
    microrregiao: {
      id: 26006,
      nome: 'Itaparica',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2614857,
    nome: 'Tamandaré',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260007,
      nome: 'Barreiros - Sirinhaém',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2615003,
    nome: 'Taquaritinga do Norte',
    microrregiao: {
      id: 26009,
      nome: 'Alto Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2615102,
    nome: 'Terezinha',
    microrregiao: {
      id: 26011,
      nome: 'Garanhuns',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260010,
      nome: 'Garanhuns',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2615201,
    nome: 'Terra Nova',
    microrregiao: {
      id: 26005,
      nome: 'Petrolina',
      mesorregiao: {
        id: 2602,
        nome: 'São Francisco Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260017,
      nome: 'Salgueiro',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2615300,
    nome: 'Timbaúba',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2615409,
    nome: 'Toritama',
    microrregiao: {
      id: 26009,
      nome: 'Alto Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260009,
      nome: 'Caruaru',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2615508,
    nome: 'Tracunhaém',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260006,
      nome: 'Carpina',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2615607,
    nome: 'Trindade',
    microrregiao: {
      id: 26001,
      nome: 'Araripina',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260016,
      nome: 'Araripina',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2615706,
    nome: 'Triunfo',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260013,
      nome: 'Serra Talhada',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2615805,
    nome: 'Tupanatinga',
    microrregiao: {
      id: 26007,
      nome: 'Vale do Ipanema',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2615904,
    nome: 'Tuparetama',
    microrregiao: {
      id: 26003,
      nome: 'Pajeú',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260014,
      nome: 'Afogados da Ingazeira',
      'regiao-intermediaria': {
        id: 2603,
        nome: 'Serra Talhada',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2616001,
    nome: 'Venturosa',
    microrregiao: {
      id: 26007,
      nome: 'Vale do Ipanema',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260011,
      nome: 'Arcoverde',
      'regiao-intermediaria': {
        id: 2602,
        nome: 'Caruaru',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2616100,
    nome: 'Verdejante',
    microrregiao: {
      id: 26002,
      nome: 'Salgueiro',
      mesorregiao: {
        id: 2601,
        nome: 'Sertão Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260017,
      nome: 'Salgueiro',
      'regiao-intermediaria': {
        id: 2604,
        nome: 'Petrolina',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2616183,
    nome: 'Vertente do Lério',
    microrregiao: {
      id: 26009,
      nome: 'Alto Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260008,
      nome: 'Surubim',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2616209,
    nome: 'Vertentes',
    microrregiao: {
      id: 26009,
      nome: 'Alto Capibaribe',
      mesorregiao: {
        id: 2603,
        nome: 'Agreste Pernambucano',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260008,
      nome: 'Surubim',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2616308,
    nome: 'Vicência',
    microrregiao: {
      id: 26013,
      nome: 'Mata Setentrional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260002,
      nome: 'Goiana - Timbaúba',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2616407,
    nome: 'Vitória de Santo Antão',
    microrregiao: {
      id: 26014,
      nome: 'Vitória de Santo Antão',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260005,
      nome: 'Vitória de Santo Antão',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2616506,
    nome: 'Xexéu',
    microrregiao: {
      id: 26015,
      nome: 'Mata Meridional Pernambucana',
      mesorregiao: {
        id: 2604,
        nome: 'Mata Pernambucana',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 260003,
      nome: 'Palmares',
      'regiao-intermediaria': {
        id: 2601,
        nome: 'Recife',
        UF: {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2700102,
    nome: 'Água Branca',
    microrregiao: {
      id: 27001,
      nome: 'Serrana do Sertão Alagoano',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270009,
      nome: 'Delmiro Gouveia',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2700201,
    nome: 'Anadia',
    microrregiao: {
      id: 27012,
      nome: 'São Miguel dos Campos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270004,
      nome: 'São Miguel dos Campos',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2700300,
    nome: 'Arapiraca',
    microrregiao: {
      id: 27006,
      nome: 'Arapiraca',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2700409,
    nome: 'Atalaia',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270006,
      nome: 'Atalaia',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2700508,
    nome: 'Barra de Santo Antônio',
    microrregiao: {
      id: 27011,
      nome: 'Maceió',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2700607,
    nome: 'Barra de São Miguel',
    microrregiao: {
      id: 27011,
      nome: 'Maceió',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2700706,
    nome: 'Batalha',
    microrregiao: {
      id: 27004,
      nome: 'Batalha',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270011,
      nome: "Pão de Açúcar - Olho d'Água das Flores - Batalha",
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2700805,
    nome: 'Belém',
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270008,
      nome: 'Palmeira dos Índios',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2700904,
    nome: 'Belo Monte',
    microrregiao: {
      id: 27004,
      nome: 'Batalha',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270011,
      nome: "Pão de Açúcar - Olho d'Água das Flores - Batalha",
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701001,
    nome: 'Boca da Mata',
    microrregiao: {
      id: 27012,
      nome: 'São Miguel dos Campos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270004,
      nome: 'São Miguel dos Campos',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701100,
    nome: 'Branquinha',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270005,
      nome: 'União dos Palmares',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701209,
    nome: 'Cacimbinhas',
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270008,
      nome: 'Palmeira dos Índios',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701308,
    nome: 'Cajueiro',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270006,
      nome: 'Atalaia',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701357,
    nome: 'Campestre',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701407,
    nome: 'Campo Alegre',
    microrregiao: {
      id: 27012,
      nome: 'São Miguel dos Campos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270004,
      nome: 'São Miguel dos Campos',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701506,
    nome: 'Campo Grande',
    microrregiao: {
      id: 27006,
      nome: 'Arapiraca',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701605,
    nome: 'Canapi',
    microrregiao: {
      id: 27001,
      nome: 'Serrana do Sertão Alagoano',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270010,
      nome: 'Santana do Ipanema',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701704,
    nome: 'Capela',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270006,
      nome: 'Atalaia',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701803,
    nome: 'Carneiros',
    microrregiao: {
      id: 27003,
      nome: 'Santana do Ipanema',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270010,
      nome: 'Santana do Ipanema',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2701902,
    nome: 'Chã Preta',
    microrregiao: {
      id: 27008,
      nome: 'Serrana dos Quilombos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270006,
      nome: 'Atalaia',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702009,
    nome: 'Coité do Nóia',
    microrregiao: {
      id: 27006,
      nome: 'Arapiraca',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702108,
    nome: 'Colônia Leopoldina',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702207,
    nome: 'Coqueiro Seco',
    microrregiao: {
      id: 27011,
      nome: 'Maceió',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702306,
    nome: 'Coruripe',
    microrregiao: {
      id: 27012,
      nome: 'São Miguel dos Campos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270003,
      nome: 'Penedo',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702355,
    nome: 'Craíbas',
    microrregiao: {
      id: 27006,
      nome: 'Arapiraca',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702405,
    nome: 'Delmiro Gouveia',
    microrregiao: {
      id: 27002,
      nome: 'Alagoana do Sertão do São Francisco',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270009,
      nome: 'Delmiro Gouveia',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702504,
    nome: 'Dois Riachos',
    microrregiao: {
      id: 27003,
      nome: 'Santana do Ipanema',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270010,
      nome: 'Santana do Ipanema',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702553,
    nome: 'Estrela de Alagoas',
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270008,
      nome: 'Palmeira dos Índios',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702603,
    nome: 'Feira Grande',
    microrregiao: {
      id: 27006,
      nome: 'Arapiraca',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702702,
    nome: 'Feliz Deserto',
    microrregiao: {
      id: 27013,
      nome: 'Penedo',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270003,
      nome: 'Penedo',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702801,
    nome: 'Flexeiras',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2702900,
    nome: 'Girau do Ponciano',
    microrregiao: {
      id: 27006,
      nome: 'Arapiraca',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703007,
    nome: 'Ibateguara',
    microrregiao: {
      id: 27008,
      nome: 'Serrana dos Quilombos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270005,
      nome: 'União dos Palmares',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703106,
    nome: 'Igaci',
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270008,
      nome: 'Palmeira dos Índios',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703205,
    nome: 'Igreja Nova',
    microrregiao: {
      id: 27013,
      nome: 'Penedo',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270003,
      nome: 'Penedo',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703304,
    nome: 'Inhapi',
    microrregiao: {
      id: 27001,
      nome: 'Serrana do Sertão Alagoano',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270009,
      nome: 'Delmiro Gouveia',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703403,
    nome: 'Jacaré dos Homens',
    microrregiao: {
      id: 27004,
      nome: 'Batalha',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270011,
      nome: "Pão de Açúcar - Olho d'Água das Flores - Batalha",
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703502,
    nome: 'Jacuípe',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703601,
    nome: 'Japaratinga',
    microrregiao: {
      id: 27010,
      nome: 'Litoral Norte Alagoano',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703700,
    nome: 'Jaramataia',
    microrregiao: {
      id: 27004,
      nome: 'Batalha',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703759,
    nome: 'Jequiá da Praia',
    microrregiao: {
      id: 27012,
      nome: 'São Miguel dos Campos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270004,
      nome: 'São Miguel dos Campos',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703809,
    nome: 'Joaquim Gomes',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2703908,
    nome: 'Jundiá',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2704005,
    nome: 'Junqueiro',
    microrregiao: {
      id: 27012,
      nome: 'São Miguel dos Campos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2704104,
    nome: 'Lagoa da Canoa',
    microrregiao: {
      id: 27006,
      nome: 'Arapiraca',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2704203,
    nome: 'Limoeiro de Anadia',
    microrregiao: {
      id: 27006,
      nome: 'Arapiraca',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2704302,
    nome: 'Maceió',
    microrregiao: {
      id: 27011,
      nome: 'Maceió',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2704401,
    nome: 'Major Isidoro',
    microrregiao: {
      id: 27004,
      nome: 'Batalha',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270008,
      nome: 'Palmeira dos Índios',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2704500,
    nome: 'Maragogi',
    microrregiao: {
      id: 27010,
      nome: 'Litoral Norte Alagoano',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2704609,
    nome: 'Maravilha',
    microrregiao: {
      id: 27003,
      nome: 'Santana do Ipanema',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270010,
      nome: 'Santana do Ipanema',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2704708,
    nome: 'Marechal Deodoro',
    microrregiao: {
      id: 27011,
      nome: 'Maceió',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2704807,
    nome: 'Maribondo',
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2704906,
    nome: 'Mar Vermelho',
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270006,
      nome: 'Atalaia',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2705002,
    nome: 'Mata Grande',
    microrregiao: {
      id: 27001,
      nome: 'Serrana do Sertão Alagoano',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270009,
      nome: 'Delmiro Gouveia',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2705101,
    nome: 'Matriz de Camaragibe',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2705200,
    nome: 'Messias',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2705309,
    nome: 'Minador do Negrão',
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270008,
      nome: 'Palmeira dos Índios',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2705408,
    nome: 'Monteirópolis',
    microrregiao: {
      id: 27004,
      nome: 'Batalha',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270011,
      nome: "Pão de Açúcar - Olho d'Água das Flores - Batalha",
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2705507,
    nome: 'Murici',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270005,
      nome: 'União dos Palmares',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2705606,
    nome: 'Novo Lino',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2705705,
    nome: "Olho d'Água das Flores",
    microrregiao: {
      id: 27004,
      nome: 'Batalha',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270011,
      nome: "Pão de Açúcar - Olho d'Água das Flores - Batalha",
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2705804,
    nome: "Olho d'Água do Casado",
    microrregiao: {
      id: 27002,
      nome: 'Alagoana do Sertão do São Francisco',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270009,
      nome: 'Delmiro Gouveia',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2705903,
    nome: "Olho d'Água Grande",
    microrregiao: {
      id: 27007,
      nome: 'Traipu',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706000,
    nome: 'Olivença',
    microrregiao: {
      id: 27004,
      nome: 'Batalha',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270010,
      nome: 'Santana do Ipanema',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706109,
    nome: 'Ouro Branco',
    microrregiao: {
      id: 27003,
      nome: 'Santana do Ipanema',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270010,
      nome: 'Santana do Ipanema',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706208,
    nome: 'Palestina',
    microrregiao: {
      id: 27003,
      nome: 'Santana do Ipanema',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270011,
      nome: "Pão de Açúcar - Olho d'Água das Flores - Batalha",
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706307,
    nome: 'Palmeira dos Índios',
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270008,
      nome: 'Palmeira dos Índios',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706406,
    nome: 'Pão de Açúcar',
    microrregiao: {
      id: 27003,
      nome: 'Santana do Ipanema',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270011,
      nome: "Pão de Açúcar - Olho d'Água das Flores - Batalha",
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706422,
    nome: 'Pariconha',
    microrregiao: {
      id: 27001,
      nome: 'Serrana do Sertão Alagoano',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270009,
      nome: 'Delmiro Gouveia',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706448,
    nome: 'Paripueira',
    microrregiao: {
      id: 27011,
      nome: 'Maceió',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706505,
    nome: 'Passo de Camaragibe',
    microrregiao: {
      id: 27010,
      nome: 'Litoral Norte Alagoano',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706604,
    nome: 'Paulo Jacinto',
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270008,
      nome: 'Palmeira dos Índios',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706703,
    nome: 'Penedo',
    microrregiao: {
      id: 27013,
      nome: 'Penedo',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270003,
      nome: 'Penedo',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706802,
    nome: 'Piaçabuçu',
    microrregiao: {
      id: 27013,
      nome: 'Penedo',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270003,
      nome: 'Penedo',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2706901,
    nome: 'Pilar',
    microrregiao: {
      id: 27011,
      nome: 'Maceió',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2707008,
    nome: 'Pindoba',
    microrregiao: {
      id: 27008,
      nome: 'Serrana dos Quilombos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270006,
      nome: 'Atalaia',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2707107,
    nome: 'Piranhas',
    microrregiao: {
      id: 27002,
      nome: 'Alagoana do Sertão do São Francisco',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270009,
      nome: 'Delmiro Gouveia',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2707206,
    nome: 'Poço das Trincheiras',
    microrregiao: {
      id: 27003,
      nome: 'Santana do Ipanema',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270010,
      nome: 'Santana do Ipanema',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2707305,
    nome: 'Porto Calvo',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2707404,
    nome: 'Porto de Pedras',
    microrregiao: {
      id: 27010,
      nome: 'Litoral Norte Alagoano',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2707503,
    nome: 'Porto Real do Colégio',
    microrregiao: {
      id: 27013,
      nome: 'Penedo',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270003,
      nome: 'Penedo',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2707602,
    nome: 'Quebrangulo',
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270008,
      nome: 'Palmeira dos Índios',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2707701,
    nome: 'Rio Largo',
    microrregiao: {
      id: 27011,
      nome: 'Maceió',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2707800,
    nome: 'Roteiro',
    microrregiao: {
      id: 27012,
      nome: 'São Miguel dos Campos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270004,
      nome: 'São Miguel dos Campos',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2707909,
    nome: 'Santa Luzia do Norte',
    microrregiao: {
      id: 27011,
      nome: 'Maceió',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708006,
    nome: 'Santana do Ipanema',
    microrregiao: {
      id: 27003,
      nome: 'Santana do Ipanema',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270010,
      nome: 'Santana do Ipanema',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708105,
    nome: 'Santana do Mundaú',
    microrregiao: {
      id: 27008,
      nome: 'Serrana dos Quilombos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270005,
      nome: 'União dos Palmares',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708204,
    nome: 'São Brás',
    microrregiao: {
      id: 27007,
      nome: 'Traipu',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270003,
      nome: 'Penedo',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708303,
    nome: 'São José da Laje',
    microrregiao: {
      id: 27008,
      nome: 'Serrana dos Quilombos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270005,
      nome: 'União dos Palmares',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708402,
    nome: 'São José da Tapera',
    microrregiao: {
      id: 27003,
      nome: 'Santana do Ipanema',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270011,
      nome: "Pão de Açúcar - Olho d'Água das Flores - Batalha",
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708501,
    nome: 'São Luís do Quitunde',
    microrregiao: {
      id: 27009,
      nome: 'Mata Alagoana',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708600,
    nome: 'São Miguel dos Campos',
    microrregiao: {
      id: 27012,
      nome: 'São Miguel dos Campos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270004,
      nome: 'São Miguel dos Campos',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708709,
    nome: 'São Miguel dos Milagres',
    microrregiao: {
      id: 27010,
      nome: 'Litoral Norte Alagoano',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270002,
      nome: 'Porto Calvo - São Luís do Quitunde',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708808,
    nome: 'São Sebastião',
    microrregiao: {
      id: 27006,
      nome: 'Arapiraca',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708907,
    nome: 'Satuba',
    microrregiao: {
      id: 27011,
      nome: 'Maceió',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270001,
      nome: 'Maceió',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2708956,
    nome: 'Senador Rui Palmeira',
    microrregiao: {
      id: 27003,
      nome: 'Santana do Ipanema',
      mesorregiao: {
        id: 2701,
        nome: 'Sertão Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270010,
      nome: 'Santana do Ipanema',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2709004,
    nome: "Tanque d'Arca",
    microrregiao: {
      id: 27005,
      nome: 'Palmeira dos Índios',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2709103,
    nome: 'Taquarana',
    microrregiao: {
      id: 27006,
      nome: 'Arapiraca',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2709152,
    nome: 'Teotônio Vilela',
    microrregiao: {
      id: 27012,
      nome: 'São Miguel dos Campos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2709202,
    nome: 'Traipu',
    microrregiao: {
      id: 27007,
      nome: 'Traipu',
      mesorregiao: {
        id: 2702,
        nome: 'Agreste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270007,
      nome: 'Arapiraca',
      'regiao-intermediaria': {
        id: 2702,
        nome: 'Arapiraca',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2709301,
    nome: 'União dos Palmares',
    microrregiao: {
      id: 27008,
      nome: 'Serrana dos Quilombos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270005,
      nome: 'União dos Palmares',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2709400,
    nome: 'Viçosa',
    microrregiao: {
      id: 27008,
      nome: 'Serrana dos Quilombos',
      mesorregiao: {
        id: 2703,
        nome: 'Leste Alagoano',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 270006,
      nome: 'Atalaia',
      'regiao-intermediaria': {
        id: 2701,
        nome: 'Maceió',
        UF: {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2800100,
    nome: 'Amparo do São Francisco',
    microrregiao: {
      id: 28007,
      nome: 'Propriá',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2800209,
    nome: 'Aquidabã',
    microrregiao: {
      id: 28003,
      nome: 'Nossa Senhora das Dores',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2800308,
    nome: 'Aracaju',
    microrregiao: {
      id: 28011,
      nome: 'Aracaju',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2800407,
    nome: 'Arauá',
    microrregiao: {
      id: 28012,
      nome: 'Boquim',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280002,
      nome: 'Estância',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2800506,
    nome: 'Areia Branca',
    microrregiao: {
      id: 28004,
      nome: 'Agreste de Itabaiana',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2800605,
    nome: 'Barra dos Coqueiros',
    microrregiao: {
      id: 28011,
      nome: 'Aracaju',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2800670,
    nome: 'Boquim',
    microrregiao: {
      id: 28012,
      nome: 'Boquim',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280002,
      nome: 'Estância',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2800704,
    nome: 'Brejo Grande',
    microrregiao: {
      id: 28007,
      nome: 'Propriá',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2801009,
    nome: 'Campo do Brito',
    microrregiao: {
      id: 28004,
      nome: 'Agreste de Itabaiana',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2801108,
    nome: 'Canhoba',
    microrregiao: {
      id: 28007,
      nome: 'Propriá',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2801207,
    nome: 'Canindé de São Francisco',
    microrregiao: {
      id: 28001,
      nome: 'Sergipana do Sertão do São Francisco',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280006,
      nome: 'Nossa Senhora da Glória',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2801306,
    nome: 'Capela',
    microrregiao: {
      id: 28008,
      nome: 'Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2801405,
    nome: 'Carira',
    microrregiao: {
      id: 28002,
      nome: 'Carira',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2801504,
    nome: 'Carmópolis',
    microrregiao: {
      id: 28010,
      nome: 'Baixo Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2801603,
    nome: 'Cedro de São João',
    microrregiao: {
      id: 28007,
      nome: 'Propriá',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2801702,
    nome: 'Cristinápolis',
    microrregiao: {
      id: 28012,
      nome: 'Boquim',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280002,
      nome: 'Estância',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2801900,
    nome: 'Cumbe',
    microrregiao: {
      id: 28003,
      nome: 'Nossa Senhora das Dores',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2802007,
    nome: 'Divina Pastora',
    microrregiao: {
      id: 28008,
      nome: 'Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2802106,
    nome: 'Estância',
    microrregiao: {
      id: 28013,
      nome: 'Estância',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280002,
      nome: 'Estância',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2802205,
    nome: 'Feira Nova',
    microrregiao: {
      id: 28001,
      nome: 'Sergipana do Sertão do São Francisco',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280006,
      nome: 'Nossa Senhora da Glória',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2802304,
    nome: 'Frei Paulo',
    microrregiao: {
      id: 28002,
      nome: 'Carira',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2802403,
    nome: 'Gararu',
    microrregiao: {
      id: 28001,
      nome: 'Sergipana do Sertão do São Francisco',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280006,
      nome: 'Nossa Senhora da Glória',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2802502,
    nome: 'General Maynard',
    microrregiao: {
      id: 28010,
      nome: 'Baixo Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2802601,
    nome: 'Gracho Cardoso',
    microrregiao: {
      id: 28001,
      nome: 'Sergipana do Sertão do São Francisco',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280006,
      nome: 'Nossa Senhora da Glória',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2802700,
    nome: 'Ilha das Flores',
    microrregiao: {
      id: 28007,
      nome: 'Propriá',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2802809,
    nome: 'Indiaroba',
    microrregiao: {
      id: 28013,
      nome: 'Estância',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280002,
      nome: 'Estância',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2802908,
    nome: 'Itabaiana',
    microrregiao: {
      id: 28004,
      nome: 'Agreste de Itabaiana',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2803005,
    nome: 'Itabaianinha',
    microrregiao: {
      id: 28012,
      nome: 'Boquim',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280002,
      nome: 'Estância',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2803104,
    nome: 'Itabi',
    microrregiao: {
      id: 28001,
      nome: 'Sergipana do Sertão do São Francisco',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280006,
      nome: 'Nossa Senhora da Glória',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2803203,
    nome: "Itaporanga d'Ajuda",
    microrregiao: {
      id: 28013,
      nome: 'Estância',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2803302,
    nome: 'Japaratuba',
    microrregiao: {
      id: 28009,
      nome: 'Japaratuba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2803401,
    nome: 'Japoatã',
    microrregiao: {
      id: 28009,
      nome: 'Japaratuba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2803500,
    nome: 'Lagarto',
    microrregiao: {
      id: 28006,
      nome: 'Agreste de Lagarto',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280005,
      nome: 'Lagarto',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2803609,
    nome: 'Laranjeiras',
    microrregiao: {
      id: 28010,
      nome: 'Baixo Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2803708,
    nome: 'Macambira',
    microrregiao: {
      id: 28004,
      nome: 'Agreste de Itabaiana',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2803807,
    nome: 'Malhada dos Bois',
    microrregiao: {
      id: 28003,
      nome: 'Nossa Senhora das Dores',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2803906,
    nome: 'Malhador',
    microrregiao: {
      id: 28004,
      nome: 'Agreste de Itabaiana',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804003,
    nome: 'Maruim',
    microrregiao: {
      id: 28010,
      nome: 'Baixo Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804102,
    nome: 'Moita Bonita',
    microrregiao: {
      id: 28004,
      nome: 'Agreste de Itabaiana',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804201,
    nome: 'Monte Alegre de Sergipe',
    microrregiao: {
      id: 28001,
      nome: 'Sergipana do Sertão do São Francisco',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280006,
      nome: 'Nossa Senhora da Glória',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804300,
    nome: 'Muribeca',
    microrregiao: {
      id: 28003,
      nome: 'Nossa Senhora das Dores',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804409,
    nome: 'Neópolis',
    microrregiao: {
      id: 28007,
      nome: 'Propriá',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804458,
    nome: 'Nossa Senhora Aparecida',
    microrregiao: {
      id: 28002,
      nome: 'Carira',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804508,
    nome: 'Nossa Senhora da Glória',
    microrregiao: {
      id: 28001,
      nome: 'Sergipana do Sertão do São Francisco',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280006,
      nome: 'Nossa Senhora da Glória',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804607,
    nome: 'Nossa Senhora das Dores',
    microrregiao: {
      id: 28003,
      nome: 'Nossa Senhora das Dores',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804706,
    nome: 'Nossa Senhora de Lourdes',
    microrregiao: {
      id: 28007,
      nome: 'Propriá',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804805,
    nome: 'Nossa Senhora do Socorro',
    microrregiao: {
      id: 28011,
      nome: 'Aracaju',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2804904,
    nome: 'Pacatuba',
    microrregiao: {
      id: 28009,
      nome: 'Japaratuba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2805000,
    nome: 'Pedra Mole',
    microrregiao: {
      id: 28002,
      nome: 'Carira',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2805109,
    nome: 'Pedrinhas',
    microrregiao: {
      id: 28012,
      nome: 'Boquim',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280002,
      nome: 'Estância',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2805208,
    nome: 'Pinhão',
    microrregiao: {
      id: 28002,
      nome: 'Carira',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2805307,
    nome: 'Pirambu',
    microrregiao: {
      id: 28009,
      nome: 'Japaratuba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2805406,
    nome: 'Poço Redondo',
    microrregiao: {
      id: 28001,
      nome: 'Sergipana do Sertão do São Francisco',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280006,
      nome: 'Nossa Senhora da Glória',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2805505,
    nome: 'Poço Verde',
    microrregiao: {
      id: 28005,
      nome: 'Tobias Barreto',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280005,
      nome: 'Lagarto',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2805604,
    nome: 'Porto da Folha',
    microrregiao: {
      id: 28001,
      nome: 'Sergipana do Sertão do São Francisco',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280006,
      nome: 'Nossa Senhora da Glória',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2805703,
    nome: 'Propriá',
    microrregiao: {
      id: 28007,
      nome: 'Propriá',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2805802,
    nome: 'Riachão do Dantas',
    microrregiao: {
      id: 28006,
      nome: 'Agreste de Lagarto',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280005,
      nome: 'Lagarto',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2805901,
    nome: 'Riachuelo',
    microrregiao: {
      id: 28010,
      nome: 'Baixo Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2806008,
    nome: 'Ribeirópolis',
    microrregiao: {
      id: 28002,
      nome: 'Carira',
      mesorregiao: {
        id: 2801,
        nome: 'Sertão Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2806107,
    nome: 'Rosário do Catete',
    microrregiao: {
      id: 28010,
      nome: 'Baixo Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2806206,
    nome: 'Salgado',
    microrregiao: {
      id: 28012,
      nome: 'Boquim',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280005,
      nome: 'Lagarto',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2806305,
    nome: 'Santa Luzia do Itanhy',
    microrregiao: {
      id: 28013,
      nome: 'Estância',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280002,
      nome: 'Estância',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2806404,
    nome: 'Santana do São Francisco',
    microrregiao: {
      id: 28007,
      nome: 'Propriá',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2806503,
    nome: 'Santa Rosa de Lima',
    microrregiao: {
      id: 28008,
      nome: 'Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2806602,
    nome: 'Santo Amaro das Brotas',
    microrregiao: {
      id: 28010,
      nome: 'Baixo Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2806701,
    nome: 'São Cristóvão',
    microrregiao: {
      id: 28011,
      nome: 'Aracaju',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2806800,
    nome: 'São Domingos',
    microrregiao: {
      id: 28004,
      nome: 'Agreste de Itabaiana',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2806909,
    nome: 'São Francisco',
    microrregiao: {
      id: 28009,
      nome: 'Japaratuba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2807006,
    nome: 'São Miguel do Aleixo',
    microrregiao: {
      id: 28003,
      nome: 'Nossa Senhora das Dores',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280004,
      nome: 'Itabaiana',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2807105,
    nome: 'Simão Dias',
    microrregiao: {
      id: 28005,
      nome: 'Tobias Barreto',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280005,
      nome: 'Lagarto',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2807204,
    nome: 'Siriri',
    microrregiao: {
      id: 28008,
      nome: 'Cotinguiba',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280001,
      nome: 'Aracaju',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2807303,
    nome: 'Telha',
    microrregiao: {
      id: 28007,
      nome: 'Propriá',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280003,
      nome: 'Propriá',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2807402,
    nome: 'Tobias Barreto',
    microrregiao: {
      id: 28005,
      nome: 'Tobias Barreto',
      mesorregiao: {
        id: 2802,
        nome: 'Agreste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280005,
      nome: 'Lagarto',
      'regiao-intermediaria': {
        id: 2802,
        nome: 'Itabaiana',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2807501,
    nome: 'Tomar do Geru',
    microrregiao: {
      id: 28012,
      nome: 'Boquim',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280002,
      nome: 'Estância',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2807600,
    nome: 'Umbaúba',
    microrregiao: {
      id: 28012,
      nome: 'Boquim',
      mesorregiao: {
        id: 2803,
        nome: 'Leste Sergipano',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 280002,
      nome: 'Estância',
      'regiao-intermediaria': {
        id: 2801,
        nome: 'Aracaju',
        UF: {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2900108,
    nome: 'Abaíra',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2900207,
    nome: 'Abaré',
    microrregiao: {
      id: 29005,
      nome: 'Paulo Afonso',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290024,
      nome: 'Paulo Afonso',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2900306,
    nome: 'Acajutiba',
    microrregiao: {
      id: 29017,
      nome: 'Alagoinhas',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2900355,
    nome: 'Adustina',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290027,
      nome: 'Cícero Dantas',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2900405,
    nome: 'Água Fria',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2900504,
    nome: 'Érico Cardoso',
    microrregiao: {
      id: 29025,
      nome: 'Livramento do Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2900603,
    nome: 'Aiquara',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2900702,
    nome: 'Alagoinhas',
    microrregiao: {
      id: 29017,
      nome: 'Alagoinhas',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2900801,
    nome: 'Alcobaça',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2900900,
    nome: 'Almadina',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901007,
    nome: 'Amargosa',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901106,
    nome: 'Amélia Rodrigues',
    microrregiao: {
      id: 29019,
      nome: 'Catu',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901155,
    nome: 'América Dourada',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901205,
    nome: 'Anagé',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901304,
    nome: 'Andaraí',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901353,
    nome: 'Andorinha',
    microrregiao: {
      id: 29008,
      nome: 'Senhor do Bonfim',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290023,
      nome: 'Senhor do Bonfim',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901403,
    nome: 'Angical',
    microrregiao: {
      id: 29002,
      nome: 'Cotegipe',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901502,
    nome: 'Anguera',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901601,
    nome: 'Antas',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290027,
      nome: 'Cícero Dantas',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901700,
    nome: 'Antônio Cardoso',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901809,
    nome: 'Antônio Gonçalves',
    microrregiao: {
      id: 29008,
      nome: 'Senhor do Bonfim',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290023,
      nome: 'Senhor do Bonfim',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901908,
    nome: 'Aporá',
    microrregiao: {
      id: 29017,
      nome: 'Alagoinhas',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2901957,
    nome: 'Apuarema',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902005,
    nome: 'Aracatu',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902054,
    nome: 'Araçás',
    microrregiao: {
      id: 29017,
      nome: 'Alagoinhas',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902104,
    nome: 'Araci',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290033,
      nome: 'Serrinha',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902203,
    nome: 'Aramari',
    microrregiao: {
      id: 29017,
      nome: 'Alagoinhas',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902252,
    nome: 'Arataca',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290010,
      nome: 'Camacan',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902302,
    nome: 'Aratuípe',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290006,
      nome: 'Nazaré - Maragogipe',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902401,
    nome: 'Aurelino Leal',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902500,
    nome: 'Baianópolis',
    microrregiao: {
      id: 29001,
      nome: 'Barreiras',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902609,
    nome: 'Baixa Grande',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902658,
    nome: 'Banzaê',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290025,
      nome: 'Ribeira do Pombal',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902708,
    nome: 'Barra',
    microrregiao: {
      id: 29006,
      nome: 'Barra',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290021,
      nome: 'Xique-Xique - Barra',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902807,
    nome: 'Barra da Estiva',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2902906,
    nome: 'Barra do Choça',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903003,
    nome: 'Barra do Mendes',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903102,
    nome: 'Barra do Rocha',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903201,
    nome: 'Barreiras',
    microrregiao: {
      id: 29001,
      nome: 'Barreiras',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903235,
    nome: 'Barro Alto',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903276,
    nome: 'Barrocas',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290033,
      nome: 'Serrinha',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903300,
    nome: 'Barro Preto',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903409,
    nome: 'Belmonte',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290009,
      nome: 'Eunápolis - Porto Seguro',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903508,
    nome: 'Belo Campo',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903607,
    nome: 'Biritinga',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290033,
      nome: 'Serrinha',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903706,
    nome: 'Boa Nova',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903805,
    nome: 'Boa Vista do Tupim',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903904,
    nome: 'Bom Jesus da Lapa',
    microrregiao: {
      id: 29007,
      nome: 'Bom Jesus da Lapa',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290017,
      nome: 'Bom Jesus da Lapa',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2903953,
    nome: 'Bom Jesus da Serra',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904001,
    nome: 'Boninal',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290034,
      nome: 'Seabra',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904050,
    nome: 'Bonito',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904100,
    nome: 'Boquira',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290017,
      nome: 'Bom Jesus da Lapa',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904209,
    nome: 'Botuporã',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904308,
    nome: 'Brejões',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904407,
    nome: 'Brejolândia',
    microrregiao: {
      id: 29002,
      nome: 'Cotegipe',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904506,
    nome: 'Brotas de Macaúbas',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290021,
      nome: 'Xique-Xique - Barra',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904605,
    nome: 'Brumado',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904704,
    nome: 'Buerarema',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904753,
    nome: 'Buritirama',
    microrregiao: {
      id: 29006,
      nome: 'Barra',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290021,
      nome: 'Xique-Xique - Barra',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904803,
    nome: 'Caatiba',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904852,
    nome: 'Cabaceiras do Paraguaçu',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2904902,
    nome: 'Cachoeira',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905008,
    nome: 'Caculé',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905107,
    nome: 'Caém',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905156,
    nome: 'Caetanos',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905206,
    nome: 'Caetité',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905305,
    nome: 'Cafarnaum',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905404,
    nome: 'Cairu',
    microrregiao: {
      id: 29030,
      nome: 'Valença',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290005,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905503,
    nome: 'Caldeirão Grande',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905602,
    nome: 'Camacan',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290010,
      nome: 'Camacan',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905701,
    nome: 'Camaçari',
    microrregiao: {
      id: 29021,
      nome: 'Salvador',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905800,
    nome: 'Camamu',
    microrregiao: {
      id: 29030,
      nome: 'Valença',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290005,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2905909,
    nome: 'Campo Alegre de Lourdes',
    microrregiao: {
      id: 29004,
      nome: 'Juazeiro',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290022,
      nome: 'Juazeiro',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906006,
    nome: 'Campo Formoso',
    microrregiao: {
      id: 29008,
      nome: 'Senhor do Bonfim',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290023,
      nome: 'Senhor do Bonfim',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906105,
    nome: 'Canápolis',
    microrregiao: {
      id: 29003,
      nome: 'Santa Maria da Vitória',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290019,
      nome: 'Santa Maria da Vitoria',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906204,
    nome: 'Canarana',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906303,
    nome: 'Canavieiras',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290010,
      nome: 'Camacan',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906402,
    nome: 'Candeal',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906501,
    nome: 'Candeias',
    microrregiao: {
      id: 29021,
      nome: 'Salvador',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906600,
    nome: 'Candiba',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906709,
    nome: 'Cândido Sales',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906808,
    nome: 'Cansanção',
    microrregiao: {
      id: 29014,
      nome: 'Euclides da Cunha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290026,
      nome: 'Euclides da Cunha',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906824,
    nome: 'Canudos',
    microrregiao: {
      id: 29014,
      nome: 'Euclides da Cunha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290026,
      nome: 'Euclides da Cunha',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906857,
    nome: 'Capela do Alto Alegre',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906873,
    nome: 'Capim Grosso',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906899,
    nome: 'Caraíbas',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2906907,
    nome: 'Caravelas',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907004,
    nome: 'Cardeal da Silva',
    microrregiao: {
      id: 29018,
      nome: 'Entre Rios',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907103,
    nome: 'Carinhanha',
    microrregiao: {
      id: 29007,
      nome: 'Bom Jesus da Lapa',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907202,
    nome: 'Casa Nova',
    microrregiao: {
      id: 29004,
      nome: 'Juazeiro',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290022,
      nome: 'Juazeiro',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907301,
    nome: 'Castro Alves',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907400,
    nome: 'Catolândia',
    microrregiao: {
      id: 29001,
      nome: 'Barreiras',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907509,
    nome: 'Catu',
    microrregiao: {
      id: 29019,
      nome: 'Catu',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907558,
    nome: 'Caturama',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907608,
    nome: 'Central',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907707,
    nome: 'Chorrochó',
    microrregiao: {
      id: 29005,
      nome: 'Paulo Afonso',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290024,
      nome: 'Paulo Afonso',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907806,
    nome: 'Cícero Dantas',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290027,
      nome: 'Cícero Dantas',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2907905,
    nome: 'Cipó',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290025,
      nome: 'Ribeira do Pombal',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2908002,
    nome: 'Coaraci',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2908101,
    nome: 'Cocos',
    microrregiao: {
      id: 29003,
      nome: 'Santa Maria da Vitória',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290019,
      nome: 'Santa Maria da Vitoria',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2908200,
    nome: 'Conceição da Feira',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2908309,
    nome: 'Conceição do Almeida',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2908408,
    nome: 'Conceição do Coité',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290032,
      nome: 'Conceição do Coité',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2908507,
    nome: 'Conceição do Jacuípe',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2908606,
    nome: 'Conde',
    microrregiao: {
      id: 29018,
      nome: 'Entre Rios',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2908705,
    nome: 'Condeúba',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2908804,
    nome: 'Contendas do Sincorá',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2908903,
    nome: 'Coração de Maria',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2909000,
    nome: 'Cordeiros',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2909109,
    nome: 'Coribe',
    microrregiao: {
      id: 29003,
      nome: 'Santa Maria da Vitória',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290019,
      nome: 'Santa Maria da Vitoria',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2909208,
    nome: 'Coronel João Sá',
    microrregiao: {
      id: 29013,
      nome: 'Jeremoabo',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290028,
      nome: 'Jeremoabo',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2909307,
    nome: 'Correntina',
    microrregiao: {
      id: 29003,
      nome: 'Santa Maria da Vitória',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290019,
      nome: 'Santa Maria da Vitoria',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2909406,
    nome: 'Cotegipe',
    microrregiao: {
      id: 29002,
      nome: 'Cotegipe',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2909505,
    nome: 'Cravolândia',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2909604,
    nome: 'Crisópolis',
    microrregiao: {
      id: 29017,
      nome: 'Alagoinhas',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2909703,
    nome: 'Cristópolis',
    microrregiao: {
      id: 29002,
      nome: 'Cotegipe',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2909802,
    nome: 'Cruz das Almas',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2909901,
    nome: 'Curaçá',
    microrregiao: {
      id: 29004,
      nome: 'Juazeiro',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290022,
      nome: 'Juazeiro',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910008,
    nome: 'Dário Meira',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910057,
    nome: "Dias d'Ávila",
    microrregiao: {
      id: 29021,
      nome: 'Salvador',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910107,
    nome: 'Dom Basílio',
    microrregiao: {
      id: 29025,
      nome: 'Livramento do Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910206,
    nome: 'Dom Macedo Costa',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910305,
    nome: 'Elísio Medrado',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910404,
    nome: 'Encruzilhada',
    microrregiao: {
      id: 29029,
      nome: 'Itapetinga',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910503,
    nome: 'Entre Rios',
    microrregiao: {
      id: 29018,
      nome: 'Entre Rios',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910602,
    nome: 'Esplanada',
    microrregiao: {
      id: 29018,
      nome: 'Entre Rios',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910701,
    nome: 'Euclides da Cunha',
    microrregiao: {
      id: 29014,
      nome: 'Euclides da Cunha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290026,
      nome: 'Euclides da Cunha',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910727,
    nome: 'Eunápolis',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290009,
      nome: 'Eunápolis - Porto Seguro',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910750,
    nome: 'Fátima',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290027,
      nome: 'Cícero Dantas',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910776,
    nome: 'Feira da Mata',
    microrregiao: {
      id: 29007,
      nome: 'Bom Jesus da Lapa',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910800,
    nome: 'Feira de Santana',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910859,
    nome: 'Filadélfia',
    microrregiao: {
      id: 29008,
      nome: 'Senhor do Bonfim',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290023,
      nome: 'Senhor do Bonfim',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2910909,
    nome: 'Firmino Alves',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911006,
    nome: 'Floresta Azul',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911105,
    nome: 'Formosa do Rio Preto',
    microrregiao: {
      id: 29001,
      nome: 'Barreiras',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911204,
    nome: 'Gandu',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911253,
    nome: 'Gavião',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911303,
    nome: 'Gentio do Ouro',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290021,
      nome: 'Xique-Xique - Barra',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911402,
    nome: 'Glória',
    microrregiao: {
      id: 29005,
      nome: 'Paulo Afonso',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290024,
      nome: 'Paulo Afonso',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911501,
    nome: 'Gongogi',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911600,
    nome: 'Governador Mangabeira',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911659,
    nome: 'Guajeru',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911709,
    nome: 'Guanambi',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911808,
    nome: 'Guaratinga',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290009,
      nome: 'Eunápolis - Porto Seguro',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911857,
    nome: 'Heliópolis',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290027,
      nome: 'Cícero Dantas',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2911907,
    nome: 'Iaçu',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2912004,
    nome: 'Ibiassucê',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2912103,
    nome: 'Ibicaraí',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2912202,
    nome: 'Ibicoara',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2912301,
    nome: 'Ibicuí',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2912400,
    nome: 'Ibipeba',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2912509,
    nome: 'Ibipitanga',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290017,
      nome: 'Bom Jesus da Lapa',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2912608,
    nome: 'Ibiquera',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2912707,
    nome: 'Ibirapitanga',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2912806,
    nome: 'Ibirapuã',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2912905,
    nome: 'Ibirataia',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913002,
    nome: 'Ibitiara',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290034,
      nome: 'Seabra',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913101,
    nome: 'Ibititá',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913200,
    nome: 'Ibotirama',
    microrregiao: {
      id: 29006,
      nome: 'Barra',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290021,
      nome: 'Xique-Xique - Barra',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913309,
    nome: 'Ichu',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913408,
    nome: 'Igaporã',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913457,
    nome: 'Igrapiúna',
    microrregiao: {
      id: 29030,
      nome: 'Valença',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290005,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913507,
    nome: 'Iguaí',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913606,
    nome: 'Ilhéus',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913705,
    nome: 'Inhambupe',
    microrregiao: {
      id: 29017,
      nome: 'Alagoinhas',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913804,
    nome: 'Ipecaetá',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2913903,
    nome: 'Ipiaú',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914000,
    nome: 'Ipirá',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914109,
    nome: 'Ipupiara',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290021,
      nome: 'Xique-Xique - Barra',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914208,
    nome: 'Irajuba',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914307,
    nome: 'Iramaia',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914406,
    nome: 'Iraquara',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290034,
      nome: 'Seabra',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914505,
    nome: 'Irará',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914604,
    nome: 'Irecê',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914653,
    nome: 'Itabela',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290009,
      nome: 'Eunápolis - Porto Seguro',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914703,
    nome: 'Itaberaba',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914802,
    nome: 'Itabuna',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2914901,
    nome: 'Itacaré',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915007,
    nome: 'Itaeté',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915106,
    nome: 'Itagi',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915205,
    nome: 'Itagibá',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915304,
    nome: 'Itagimirim',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290009,
      nome: 'Eunápolis - Porto Seguro',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915353,
    nome: 'Itaguaçu da Bahia',
    microrregiao: {
      id: 29006,
      nome: 'Barra',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915403,
    nome: 'Itaju do Colônia',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915502,
    nome: 'Itajuípe',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915601,
    nome: 'Itamaraju',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915700,
    nome: 'Itamari',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915809,
    nome: 'Itambé',
    microrregiao: {
      id: 29029,
      nome: 'Itapetinga',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2915908,
    nome: 'Itanagra',
    microrregiao: {
      id: 29019,
      nome: 'Catu',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916005,
    nome: 'Itanhém',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916104,
    nome: 'Itaparica',
    microrregiao: {
      id: 29021,
      nome: 'Salvador',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290006,
      nome: 'Nazaré - Maragogipe',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916203,
    nome: 'Itapé',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916302,
    nome: 'Itapebi',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290009,
      nome: 'Eunápolis - Porto Seguro',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916401,
    nome: 'Itapetinga',
    microrregiao: {
      id: 29029,
      nome: 'Itapetinga',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290015,
      nome: 'Itapetinga',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916500,
    nome: 'Itapicuru',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916609,
    nome: 'Itapitanga',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916708,
    nome: 'Itaquara',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916807,
    nome: 'Itarantim',
    microrregiao: {
      id: 29029,
      nome: 'Itapetinga',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290015,
      nome: 'Itapetinga',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916856,
    nome: 'Itatim',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2916906,
    nome: 'Itiruçu',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917003,
    nome: 'Itiúba',
    microrregiao: {
      id: 29008,
      nome: 'Senhor do Bonfim',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290023,
      nome: 'Senhor do Bonfim',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917102,
    nome: 'Itororó',
    microrregiao: {
      id: 29029,
      nome: 'Itapetinga',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290015,
      nome: 'Itapetinga',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917201,
    nome: 'Ituaçu',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917300,
    nome: 'Ituberá',
    microrregiao: {
      id: 29030,
      nome: 'Valença',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290005,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917334,
    nome: 'Iuiu',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917359,
    nome: 'Jaborandi',
    microrregiao: {
      id: 29003,
      nome: 'Santa Maria da Vitória',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290019,
      nome: 'Santa Maria da Vitoria',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917409,
    nome: 'Jacaraci',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917508,
    nome: 'Jacobina',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917607,
    nome: 'Jaguaquara',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917706,
    nome: 'Jaguarari',
    microrregiao: {
      id: 29008,
      nome: 'Senhor do Bonfim',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290023,
      nome: 'Senhor do Bonfim',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917805,
    nome: 'Jaguaripe',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290006,
      nome: 'Nazaré - Maragogipe',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2917904,
    nome: 'Jandaíra',
    microrregiao: {
      id: 29018,
      nome: 'Entre Rios',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918001,
    nome: 'Jequié',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918100,
    nome: 'Jeremoabo',
    microrregiao: {
      id: 29013,
      nome: 'Jeremoabo',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290028,
      nome: 'Jeremoabo',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918209,
    nome: 'Jiquiriçá',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918308,
    nome: 'Jitaúna',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918357,
    nome: 'João Dourado',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918407,
    nome: 'Juazeiro',
    microrregiao: {
      id: 29004,
      nome: 'Juazeiro',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290022,
      nome: 'Juazeiro',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918456,
    nome: 'Jucuruçu',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918506,
    nome: 'Jussara',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918555,
    nome: 'Jussari',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290010,
      nome: 'Camacan',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918605,
    nome: 'Jussiape',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918704,
    nome: 'Lafaiete Coutinho',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918753,
    nome: 'Lagoa Real',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918803,
    nome: 'Laje',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2918902,
    nome: 'Lajedão',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919009,
    nome: 'Lajedinho',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919058,
    nome: 'Lajedo do Tabocal',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919108,
    nome: 'Lamarão',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919157,
    nome: 'Lapão',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919207,
    nome: 'Lauro de Freitas',
    microrregiao: {
      id: 29021,
      nome: 'Salvador',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919306,
    nome: 'Lençóis',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290034,
      nome: 'Seabra',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919405,
    nome: 'Licínio de Almeida',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919504,
    nome: 'Livramento de Nossa Senhora',
    microrregiao: {
      id: 29025,
      nome: 'Livramento do Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919553,
    nome: 'Luís Eduardo Magalhães',
    microrregiao: {
      id: 29001,
      nome: 'Barreiras',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919603,
    nome: 'Macajuba',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919702,
    nome: 'Macarani',
    microrregiao: {
      id: 29029,
      nome: 'Itapetinga',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290015,
      nome: 'Itapetinga',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919801,
    nome: 'Macaúbas',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290017,
      nome: 'Bom Jesus da Lapa',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919900,
    nome: 'Macururé',
    microrregiao: {
      id: 29005,
      nome: 'Paulo Afonso',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290024,
      nome: 'Paulo Afonso',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919926,
    nome: 'Madre de Deus',
    microrregiao: {
      id: 29021,
      nome: 'Salvador',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2919959,
    nome: 'Maetinga',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920007,
    nome: 'Maiquinique',
    microrregiao: {
      id: 29029,
      nome: 'Itapetinga',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290015,
      nome: 'Itapetinga',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920106,
    nome: 'Mairi',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920205,
    nome: 'Malhada',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920304,
    nome: 'Malhada de Pedras',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920403,
    nome: 'Manoel Vitorino',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920452,
    nome: 'Mansidão',
    microrregiao: {
      id: 29002,
      nome: 'Cotegipe',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920502,
    nome: 'Maracás',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920601,
    nome: 'Maragogipe',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290006,
      nome: 'Nazaré - Maragogipe',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920700,
    nome: 'Maraú',
    microrregiao: {
      id: 29030,
      nome: 'Valença',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920809,
    nome: 'Marcionílio Souza',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2920908,
    nome: 'Mascote',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290010,
      nome: 'Camacan',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921005,
    nome: 'Mata de São João',
    microrregiao: {
      id: 29019,
      nome: 'Catu',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921054,
    nome: 'Matina',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921104,
    nome: 'Medeiros Neto',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921203,
    nome: 'Miguel Calmon',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921302,
    nome: 'Milagres',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921401,
    nome: 'Mirangaba',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921450,
    nome: 'Mirante',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921500,
    nome: 'Monte Santo',
    microrregiao: {
      id: 29014,
      nome: 'Euclides da Cunha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290026,
      nome: 'Euclides da Cunha',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921609,
    nome: 'Morpará',
    microrregiao: {
      id: 29006,
      nome: 'Barra',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290021,
      nome: 'Xique-Xique - Barra',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921708,
    nome: 'Morro do Chapéu',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921807,
    nome: 'Mortugaba',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2921906,
    nome: 'Mucugê',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290034,
      nome: 'Seabra',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922003,
    nome: 'Mucuri',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922052,
    nome: 'Mulungu do Morro',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922102,
    nome: 'Mundo Novo',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922201,
    nome: 'Muniz Ferreira',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922250,
    nome: 'Muquém do São Francisco',
    microrregiao: {
      id: 29006,
      nome: 'Barra',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290021,
      nome: 'Xique-Xique - Barra',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922300,
    nome: 'Muritiba',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922409,
    nome: 'Mutuípe',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922508,
    nome: 'Nazaré',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290006,
      nome: 'Nazaré - Maragogipe',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922607,
    nome: 'Nilo Peçanha',
    microrregiao: {
      id: 29030,
      nome: 'Valença',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290005,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922656,
    nome: 'Nordestina',
    microrregiao: {
      id: 29014,
      nome: 'Euclides da Cunha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290032,
      nome: 'Conceição do Coité',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922706,
    nome: 'Nova Canaã',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922730,
    nome: 'Nova Fátima',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922755,
    nome: 'Nova Ibiá',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922805,
    nome: 'Nova Itarana',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922854,
    nome: 'Nova Redenção',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2922904,
    nome: 'Nova Soure',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290025,
      nome: 'Ribeira do Pombal',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923001,
    nome: 'Nova Viçosa',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923035,
    nome: 'Novo Horizonte',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290034,
      nome: 'Seabra',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923050,
    nome: 'Novo Triunfo',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290028,
      nome: 'Jeremoabo',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923100,
    nome: 'Olindina',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290025,
      nome: 'Ribeira do Pombal',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923209,
    nome: 'Oliveira dos Brejinhos',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290021,
      nome: 'Xique-Xique - Barra',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923308,
    nome: 'Ouriçangas',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923357,
    nome: 'Ourolândia',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923407,
    nome: 'Palmas de Monte Alto',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923506,
    nome: 'Palmeiras',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290034,
      nome: 'Seabra',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923605,
    nome: 'Paramirim',
    microrregiao: {
      id: 29025,
      nome: 'Livramento do Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923704,
    nome: 'Paratinga',
    microrregiao: {
      id: 29007,
      nome: 'Bom Jesus da Lapa',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290017,
      nome: 'Bom Jesus da Lapa',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923803,
    nome: 'Paripiranga',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290027,
      nome: 'Cícero Dantas',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2923902,
    nome: 'Pau Brasil',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290010,
      nome: 'Camacan',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924009,
    nome: 'Paulo Afonso',
    microrregiao: {
      id: 29005,
      nome: 'Paulo Afonso',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290024,
      nome: 'Paulo Afonso',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924058,
    nome: 'Pé de Serra',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924108,
    nome: 'Pedrão',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924207,
    nome: 'Pedro Alexandre',
    microrregiao: {
      id: 29013,
      nome: 'Jeremoabo',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290028,
      nome: 'Jeremoabo',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924306,
    nome: 'Piatã',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290034,
      nome: 'Seabra',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924405,
    nome: 'Pilão Arcado',
    microrregiao: {
      id: 29004,
      nome: 'Juazeiro',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290022,
      nome: 'Juazeiro',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924504,
    nome: 'Pindaí',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924603,
    nome: 'Pindobaçu',
    microrregiao: {
      id: 29008,
      nome: 'Senhor do Bonfim',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290023,
      nome: 'Senhor do Bonfim',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924652,
    nome: 'Pintadas',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924678,
    nome: 'Piraí do Norte',
    microrregiao: {
      id: 29030,
      nome: 'Valença',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924702,
    nome: 'Piripá',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924801,
    nome: 'Piritiba',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2924900,
    nome: 'Planaltino',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925006,
    nome: 'Planalto',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925105,
    nome: 'Poções',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925204,
    nome: 'Pojuca',
    microrregiao: {
      id: 29019,
      nome: 'Catu',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925253,
    nome: 'Ponto Novo',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290023,
      nome: 'Senhor do Bonfim',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925303,
    nome: 'Porto Seguro',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290009,
      nome: 'Eunápolis - Porto Seguro',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925402,
    nome: 'Potiraguá',
    microrregiao: {
      id: 29029,
      nome: 'Itapetinga',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290015,
      nome: 'Itapetinga',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925501,
    nome: 'Prado',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925600,
    nome: 'Presidente Dutra',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925709,
    nome: 'Presidente Jânio Quadros',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925758,
    nome: 'Presidente Tancredo Neves',
    microrregiao: {
      id: 29030,
      nome: 'Valença',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290005,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925808,
    nome: 'Queimadas',
    microrregiao: {
      id: 29014,
      nome: 'Euclides da Cunha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290032,
      nome: 'Conceição do Coité',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925907,
    nome: 'Quijingue',
    microrregiao: {
      id: 29014,
      nome: 'Euclides da Cunha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290026,
      nome: 'Euclides da Cunha',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925931,
    nome: 'Quixabeira',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2925956,
    nome: 'Rafael Jambeiro',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926004,
    nome: 'Remanso',
    microrregiao: {
      id: 29004,
      nome: 'Juazeiro',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290022,
      nome: 'Juazeiro',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926103,
    nome: 'Retirolândia',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290032,
      nome: 'Conceição do Coité',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926202,
    nome: 'Riachão das Neves',
    microrregiao: {
      id: 29001,
      nome: 'Barreiras',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926301,
    nome: 'Riachão do Jacuípe',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926400,
    nome: 'Riacho de Santana',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926509,
    nome: 'Ribeira do Amparo',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290025,
      nome: 'Ribeira do Pombal',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926608,
    nome: 'Ribeira do Pombal',
    microrregiao: {
      id: 29015,
      nome: 'Ribeira do Pombal',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290025,
      nome: 'Ribeira do Pombal',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926657,
    nome: 'Ribeirão do Largo',
    microrregiao: {
      id: 29029,
      nome: 'Itapetinga',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926707,
    nome: 'Rio de Contas',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926806,
    nome: 'Rio do Antônio',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2926905,
    nome: 'Rio do Pires',
    microrregiao: {
      id: 29025,
      nome: 'Livramento do Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290013,
      nome: 'Brumado',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2927002,
    nome: 'Rio Real',
    microrregiao: {
      id: 29017,
      nome: 'Alagoinhas',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2927101,
    nome: 'Rodelas',
    microrregiao: {
      id: 29005,
      nome: 'Paulo Afonso',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290024,
      nome: 'Paulo Afonso',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2927200,
    nome: 'Ruy Barbosa',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2927309,
    nome: 'Salinas da Margarida',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290006,
      nome: 'Nazaré - Maragogipe',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2927408,
    nome: 'Salvador',
    microrregiao: {
      id: 29021,
      nome: 'Salvador',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2927507,
    nome: 'Santa Bárbara',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2927606,
    nome: 'Santa Brígida',
    microrregiao: {
      id: 29013,
      nome: 'Jeremoabo',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290024,
      nome: 'Paulo Afonso',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2927705,
    nome: 'Santa Cruz Cabrália',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290009,
      nome: 'Eunápolis - Porto Seguro',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2927804,
    nome: 'Santa Cruz da Vitória',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2927903,
    nome: 'Santa Inês',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290012,
      nome: 'Jequié',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928000,
    nome: 'Santaluz',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290032,
      nome: 'Conceição do Coité',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928059,
    nome: 'Santa Luzia',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290010,
      nome: 'Camacan',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928109,
    nome: 'Santa Maria da Vitória',
    microrregiao: {
      id: 29003,
      nome: 'Santa Maria da Vitória',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290019,
      nome: 'Santa Maria da Vitoria',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928208,
    nome: 'Santana',
    microrregiao: {
      id: 29003,
      nome: 'Santa Maria da Vitória',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928307,
    nome: 'Santanópolis',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928406,
    nome: 'Santa Rita de Cássia',
    microrregiao: {
      id: 29002,
      nome: 'Cotegipe',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928505,
    nome: 'Santa Terezinha',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928604,
    nome: 'Santo Amaro',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928703,
    nome: 'Santo Antônio de Jesus',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928802,
    nome: 'Santo Estêvão',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928901,
    nome: 'São Desidério',
    microrregiao: {
      id: 29001,
      nome: 'Barreiras',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2928950,
    nome: 'São Domingos',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290032,
      nome: 'Conceição do Coité',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929008,
    nome: 'São Félix',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929057,
    nome: 'São Félix do Coribe',
    microrregiao: {
      id: 29003,
      nome: 'Santa Maria da Vitória',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290019,
      nome: 'Santa Maria da Vitoria',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929107,
    nome: 'São Felipe',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929206,
    nome: 'São Francisco do Conde',
    microrregiao: {
      id: 29021,
      nome: 'Salvador',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929255,
    nome: 'São Gabriel',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929305,
    nome: 'São Gonçalo dos Campos',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929354,
    nome: 'São José da Vitória',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929370,
    nome: 'São José do Jacuípe',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929404,
    nome: 'São Miguel das Matas',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929503,
    nome: 'São Sebastião do Passé',
    microrregiao: {
      id: 29019,
      nome: 'Catu',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929602,
    nome: 'Sapeaçu',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290004,
      nome: 'Cruz das Almas',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929701,
    nome: 'Sátiro Dias',
    microrregiao: {
      id: 29017,
      nome: 'Alagoinhas',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929750,
    nome: 'Saubara',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929800,
    nome: 'Saúde',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2929909,
    nome: 'Seabra',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290034,
      nome: 'Seabra',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930006,
    nome: 'Sebastião Laranjeiras',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930105,
    nome: 'Senhor do Bonfim',
    microrregiao: {
      id: 29008,
      nome: 'Senhor do Bonfim',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290023,
      nome: 'Senhor do Bonfim',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930154,
    nome: 'Serra do Ramalho',
    microrregiao: {
      id: 29007,
      nome: 'Bom Jesus da Lapa',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290017,
      nome: 'Bom Jesus da Lapa',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930204,
    nome: 'Sento Sé',
    microrregiao: {
      id: 29004,
      nome: 'Juazeiro',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290022,
      nome: 'Juazeiro',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930303,
    nome: 'Serra Dourada',
    microrregiao: {
      id: 29003,
      nome: 'Santa Maria da Vitória',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930402,
    nome: 'Serra Preta',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930501,
    nome: 'Serrinha',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290033,
      nome: 'Serrinha',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930600,
    nome: 'Serrolândia',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930709,
    nome: 'Simões Filho',
    microrregiao: {
      id: 29021,
      nome: 'Salvador',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930758,
    nome: 'Sítio do Mato',
    microrregiao: {
      id: 29007,
      nome: 'Bom Jesus da Lapa',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290017,
      nome: 'Bom Jesus da Lapa',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930766,
    nome: 'Sítio do Quinto',
    microrregiao: {
      id: 29013,
      nome: 'Jeremoabo',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290028,
      nome: 'Jeremoabo',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930774,
    nome: 'Sobradinho',
    microrregiao: {
      id: 29004,
      nome: 'Juazeiro',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290022,
      nome: 'Juazeiro',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930808,
    nome: 'Souto Soares',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290034,
      nome: 'Seabra',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2930907,
    nome: 'Tabocas do Brejo Velho',
    microrregiao: {
      id: 29002,
      nome: 'Cotegipe',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931004,
    nome: 'Tanhaçu',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931053,
    nome: 'Tanque Novo',
    microrregiao: {
      id: 29022,
      nome: 'Boquira',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931103,
    nome: 'Tanquinho',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931202,
    nome: 'Taperoá',
    microrregiao: {
      id: 29030,
      nome: 'Valença',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290005,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931301,
    nome: 'Tapiramutá',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931350,
    nome: 'Teixeira de Freitas',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931400,
    nome: 'Teodoro Sampaio',
    microrregiao: {
      id: 29012,
      nome: 'Feira de Santana',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290002,
      nome: 'Alagoinhas',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931509,
    nome: 'Teofilândia',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290033,
      nome: 'Serrinha',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931608,
    nome: 'Teolândia',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931707,
    nome: 'Terra Nova',
    microrregiao: {
      id: 29019,
      nome: 'Catu',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290001,
      nome: 'Salvador',
      'regiao-intermediaria': {
        id: 2901,
        nome: 'Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931806,
    nome: 'Tremedal',
    microrregiao: {
      id: 29027,
      nome: 'Brumado',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2931905,
    nome: 'Tucano',
    microrregiao: {
      id: 29014,
      nome: 'Euclides da Cunha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290025,
      nome: 'Ribeira do Pombal',
      'regiao-intermediaria': {
        id: 2909,
        nome: 'Paulo Afonso',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932002,
    nome: 'Uauá',
    microrregiao: {
      id: 29014,
      nome: 'Euclides da Cunha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290022,
      nome: 'Juazeiro',
      'regiao-intermediaria': {
        id: 2908,
        nome: 'Juazeiro',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932101,
    nome: 'Ubaíra',
    microrregiao: {
      id: 29024,
      nome: 'Jequié',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932200,
    nome: 'Ubaitaba',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932309,
    nome: 'Ubatã',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932408,
    nome: 'Uibaí',
    microrregiao: {
      id: 29009,
      nome: 'Irecê',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290020,
      nome: 'Irecê',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932457,
    nome: 'Umburanas',
    microrregiao: {
      id: 29008,
      nome: 'Senhor do Bonfim',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932507,
    nome: 'Una',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290010,
      nome: 'Camacan',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932606,
    nome: 'Urandi',
    microrregiao: {
      id: 29026,
      nome: 'Guanambi',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290016,
      nome: 'Guanambi',
      'regiao-intermediaria': {
        id: 2905,
        nome: 'Guanambi',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932705,
    nome: 'Uruçuca',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290007,
      nome: 'Ilhéus - Itabuna',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932804,
    nome: 'Utinga',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2932903,
    nome: 'Valença',
    microrregiao: {
      id: 29030,
      nome: 'Valença',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290005,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933000,
    nome: 'Valente',
    microrregiao: {
      id: 29016,
      nome: 'Serrinha',
      mesorregiao: {
        id: 2904,
        nome: 'Nordeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290032,
      nome: 'Conceição do Coité',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933059,
    nome: 'Várzea da Roça',
    microrregiao: {
      id: 29011,
      nome: 'Itaberaba',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290029,
      nome: 'Feira de Santana',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933109,
    nome: 'Várzea do Poço',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933158,
    nome: 'Várzea Nova',
    microrregiao: {
      id: 29010,
      nome: 'Jacobina',
      mesorregiao: {
        id: 2903,
        nome: 'Centro Norte Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290030,
      nome: 'Jacobina',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933174,
    nome: 'Varzedo',
    microrregiao: {
      id: 29020,
      nome: 'Santo Antônio de Jesus',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290003,
      nome: 'Santo Antônio de Jesus',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933208,
    nome: 'Vera Cruz',
    microrregiao: {
      id: 29021,
      nome: 'Salvador',
      mesorregiao: {
        id: 2905,
        nome: 'Metropolitana de Salvador',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290006,
      nome: 'Nazaré - Maragogipe',
      'regiao-intermediaria': {
        id: 2902,
        nome: 'Santo Antônio de Jesus',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933257,
    nome: 'Vereda',
    microrregiao: {
      id: 29032,
      nome: 'Porto Seguro',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290008,
      nome: 'Teixeira de Freitas',
      'regiao-intermediaria': {
        id: 2903,
        nome: 'Ilhéus - Itabuna',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933307,
    nome: 'Vitória da Conquista',
    microrregiao: {
      id: 29028,
      nome: 'Vitória da Conquista',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290011,
      nome: 'Vitória da Conquista',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933406,
    nome: 'Wagner',
    microrregiao: {
      id: 29023,
      nome: 'Seabra',
      mesorregiao: {
        id: 2906,
        nome: 'Centro Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290031,
      nome: 'Itaberaba',
      'regiao-intermediaria': {
        id: 2910,
        nome: 'Feira de Santana',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933455,
    nome: 'Wanderley',
    microrregiao: {
      id: 29002,
      nome: 'Cotegipe',
      mesorregiao: {
        id: 2901,
        nome: 'Extremo Oeste Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290018,
      nome: 'Barreiras',
      'regiao-intermediaria': {
        id: 2906,
        nome: 'Barreiras',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933505,
    nome: 'Wenceslau Guimarães',
    microrregiao: {
      id: 29031,
      nome: 'Ilhéus-Itabuna',
      mesorregiao: {
        id: 2907,
        nome: 'Sul Baiano',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290014,
      nome: 'Ipiaú',
      'regiao-intermediaria': {
        id: 2904,
        nome: 'Vitória da Conquista',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 2933604,
    nome: 'Xique-Xique',
    microrregiao: {
      id: 29006,
      nome: 'Barra',
      mesorregiao: {
        id: 2902,
        nome: 'Vale São-Franciscano da Bahia',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 290021,
      nome: 'Xique-Xique - Barra',
      'regiao-intermediaria': {
        id: 2907,
        nome: 'Irecê',
        UF: {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
      },
    },
  },
  {
    id: 3100104,
    nome: 'Abadia dos Dourados',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310061,
      nome: 'Monte Carmelo',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3100203,
    nome: 'Abaeté',
    microrregiao: {
      id: 31024,
      nome: 'Três Marias',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310070,
      nome: 'Abaeté',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3100302,
    nome: 'Abre Campo',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3100401,
    nome: 'Acaiaca',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3100500,
    nome: 'Açucena',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3100609,
    nome: 'Água Boa',
    microrregiao: {
      id: 31036,
      nome: 'Peçanha',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310014,
      nome: 'Capelinha',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3100708,
    nome: 'Água Comprida',
    microrregiao: {
      id: 31022,
      nome: 'Uberaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310055,
      nome: 'Uberaba',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3100807,
    nome: 'Aguanil',
    microrregiao: {
      id: 31045,
      nome: 'Campo Belo',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310048,
      nome: 'Campo Belo',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3100906,
    nome: 'Águas Formosas',
    microrregiao: {
      id: 31016,
      nome: 'Nanuque',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310019,
      nome: 'Águas Formosas',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101003,
    nome: 'Águas Vermelhas',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310018,
      nome: 'Pedra Azul',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101102,
    nome: 'Aimorés',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310023,
      nome: 'Aimorés - Resplendor',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101201,
    nome: 'Aiuruoca',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310054,
      nome: 'Caxambu - Baependi',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101300,
    nome: 'Alagoa',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101409,
    nome: 'Albertina',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101508,
    nome: 'Além Paraíba',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310036,
      nome: 'Além Paraíba',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101607,
    nome: 'Alfenas',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101631,
    nome: 'Alfredo Vasconcelos',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101706,
    nome: 'Almenara',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101805,
    nome: 'Alpercata',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3101904,
    nome: 'Alpinópolis',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102001,
    nome: 'Alterosa',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102050,
    nome: 'Alto Caparaó',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102100,
    nome: 'Alto Rio Doce',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102209,
    nome: 'Alvarenga',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102308,
    nome: 'Alvinópolis',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102407,
    nome: 'Alvorada de Minas',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102506,
    nome: 'Amparo do Serra',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102605,
    nome: 'Andradas',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310051,
      nome: 'Poços de Caldas',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102704,
    nome: 'Cachoeira de Pajeú',
    microrregiao: {
      id: 31013,
      nome: 'Pedra Azul',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310018,
      nome: 'Pedra Azul',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102803,
    nome: 'Andrelândia',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102852,
    nome: 'Angelândia',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310014,
      nome: 'Capelinha',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3102902,
    nome: 'Antônio Carlos',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103009,
    nome: 'Antônio Dias',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103108,
    nome: 'Antônio Prado de Minas',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103207,
    nome: 'Araçaí',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103306,
    nome: 'Aracitaba',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103405,
    nome: 'Araçuaí',
    microrregiao: {
      id: 31012,
      nome: 'Araçuaí',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310017,
      nome: 'Araçuaí',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103504,
    nome: 'Araguari',
    microrregiao: {
      id: 31018,
      nome: 'Uberlândia',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103603,
    nome: 'Arantina',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103702,
    nome: 'Araponga',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103751,
    nome: 'Araporã',
    microrregiao: {
      id: 31018,
      nome: 'Uberlândia',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103801,
    nome: 'Arapuá',
    microrregiao: {
      id: 31020,
      nome: 'Patos de Minas',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3103900,
    nome: 'Araújos',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104007,
    nome: 'Araxá',
    microrregiao: {
      id: 31023,
      nome: 'Araxá',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310056,
      nome: 'Araxá',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104106,
    nome: 'Arceburgo',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310044,
      nome: 'Guaxupé',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104205,
    nome: 'Arcos',
    microrregiao: {
      id: 31044,
      nome: 'Formiga',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310066,
      nome: 'Formiga',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104304,
    nome: 'Areado',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104403,
    nome: 'Argirita',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310032,
      nome: 'Cataguases',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104452,
    nome: 'Aricanduva',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310014,
      nome: 'Capelinha',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104502,
    nome: 'Arinos',
    microrregiao: {
      id: 31001,
      nome: 'Unaí',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104601,
    nome: 'Astolfo Dutra',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310032,
      nome: 'Cataguases',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104700,
    nome: 'Ataléia',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104809,
    nome: 'Augusto de Lima',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3104908,
    nome: 'Baependi',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310054,
      nome: 'Caxambu - Baependi',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3105004,
    nome: 'Baldim',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3105103,
    nome: 'Bambuí',
    microrregiao: {
      id: 31042,
      nome: 'Piuí',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310066,
      nome: 'Formiga',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3105202,
    nome: 'Bandeira',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3105301,
    nome: 'Bandeira do Sul',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310051,
      nome: 'Poços de Caldas',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3105400,
    nome: 'Barão de Cocais',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310003,
      nome: 'Santa Bárbara - Ouro Preto',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3105509,
    nome: 'Barão de Monte Alto',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3105608,
    nome: 'Barbacena',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3105707,
    nome: 'Barra Longa',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3105905,
    nome: 'Barroso',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106002,
    nome: 'Bela Vista de Minas',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310026,
      nome: 'João Monlevade',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106101,
    nome: 'Belmiro Braga',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106200,
    nome: 'Belo Horizonte',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106309,
    nome: 'Belo Oriente',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106408,
    nome: 'Belo Vale',
    microrregiao: {
      id: 31032,
      nome: 'Itaguara',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106507,
    nome: 'Berilo',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310017,
      nome: 'Araçuaí',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106606,
    nome: 'Bertópolis',
    microrregiao: {
      id: 31016,
      nome: 'Nanuque',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310019,
      nome: 'Águas Formosas',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106655,
    nome: 'Berizal',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106705,
    nome: 'Betim',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106804,
    nome: 'Bias Fortes',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3106903,
    nome: 'Bicas',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310035,
      nome: 'São João Nepomuceno - Bicas',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3107000,
    nome: 'Biquinhas',
    microrregiao: {
      id: 31024,
      nome: 'Três Marias',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310070,
      nome: 'Abaeté',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3107109,
    nome: 'Boa Esperança',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310046,
      nome: 'Três Pontas - Boa Esperança',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3107208,
    nome: 'Bocaina de Minas',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3107307,
    nome: 'Bocaiúva',
    microrregiao: {
      id: 31009,
      nome: 'Bocaiúva',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3107406,
    nome: 'Bom Despacho',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310067,
      nome: 'Dores do Indaiá',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3107505,
    nome: 'Bom Jardim de Minas',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3107604,
    nome: 'Bom Jesus da Penha',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3107703,
    nome: 'Bom Jesus do Amparo',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310005,
      nome: 'Itabira',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3107802,
    nome: 'Bom Jesus do Galho',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3107901,
    nome: 'Bom Repouso',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108008,
    nome: 'Bom Sucesso',
    microrregiao: {
      id: 31046,
      nome: 'Oliveira',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108107,
    nome: 'Bonfim',
    microrregiao: {
      id: 31032,
      nome: 'Itaguara',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310069,
      nome: 'Oliveira',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108206,
    nome: 'Bonfinópolis de Minas',
    microrregiao: {
      id: 31001,
      nome: 'Unaí',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108255,
    nome: 'Bonito de Minas',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310009,
      nome: 'Januária',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108305,
    nome: 'Borda da Mata',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108404,
    nome: 'Botelhos',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310051,
      nome: 'Poços de Caldas',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108503,
    nome: 'Botumirim',
    microrregiao: {
      id: 31008,
      nome: 'Grão Mogol',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108552,
    nome: 'Brasilândia de Minas',
    microrregiao: {
      id: 31002,
      nome: 'Paracatu',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108602,
    nome: 'Brasília de Minas',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108701,
    nome: 'Brás Pires',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108800,
    nome: 'Braúnas',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3108909,
    nome: 'Brazópolis',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109006,
    nome: 'Brumadinho',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109105,
    nome: 'Bueno Brandão',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109204,
    nome: 'Buenópolis',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109253,
    nome: 'Bugre',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109303,
    nome: 'Buritis',
    microrregiao: {
      id: 31001,
      nome: 'Unaí',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109402,
    nome: 'Buritizeiro',
    microrregiao: {
      id: 31006,
      nome: 'Pirapora',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310010,
      nome: 'Pirapora',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109451,
    nome: 'Cabeceira Grande',
    microrregiao: {
      id: 31001,
      nome: 'Unaí',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109501,
    nome: 'Cabo Verde',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310044,
      nome: 'Guaxupé',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109600,
    nome: 'Cachoeira da Prata',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109709,
    nome: 'Cachoeira de Minas',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109808,
    nome: 'Cachoeira Dourada',
    microrregiao: {
      id: 31017,
      nome: 'Ituiutaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310060,
      nome: 'Ituiutaba',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3109907,
    nome: 'Caetanópolis',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3110004,
    nome: 'Caeté',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3110103,
    nome: 'Caiana',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310034,
      nome: 'Carangola',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3110202,
    nome: 'Cajuri',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3110301,
    nome: 'Caldas',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310051,
      nome: 'Poços de Caldas',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3110400,
    nome: 'Camacho',
    microrregiao: {
      id: 31044,
      nome: 'Formiga',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3110509,
    nome: 'Camanducaia',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3110608,
    nome: 'Cambuí',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3110707,
    nome: 'Cambuquira',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310045,
      nome: 'Três Corações',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3110806,
    nome: 'Campanário',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3110905,
    nome: 'Campanha',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310045,
      nome: 'Três Corações',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111002,
    nome: 'Campestre',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310051,
      nome: 'Poços de Caldas',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111101,
    nome: 'Campina Verde',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111150,
    nome: 'Campo Azul',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111200,
    nome: 'Campo Belo',
    microrregiao: {
      id: 31045,
      nome: 'Campo Belo',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310048,
      nome: 'Campo Belo',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111309,
    nome: 'Campo do Meio',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111408,
    nome: 'Campo Florido',
    microrregiao: {
      id: 31022,
      nome: 'Uberaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310055,
      nome: 'Uberaba',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111507,
    nome: 'Campos Altos',
    microrregiao: {
      id: 31023,
      nome: 'Araxá',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310056,
      nome: 'Araxá',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111606,
    nome: 'Campos Gerais',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111705,
    nome: 'Canaã',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111804,
    nome: 'Canápolis',
    microrregiao: {
      id: 31018,
      nome: 'Uberlândia',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3111903,
    nome: 'Cana Verde',
    microrregiao: {
      id: 31045,
      nome: 'Campo Belo',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112000,
    nome: 'Candeias',
    microrregiao: {
      id: 31045,
      nome: 'Campo Belo',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310048,
      nome: 'Campo Belo',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112059,
    nome: 'Cantagalo',
    microrregiao: {
      id: 31036,
      nome: 'Peçanha',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112109,
    nome: 'Caparaó',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112208,
    nome: 'Capela Nova',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112307,
    nome: 'Capelinha',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310014,
      nome: 'Capelinha',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112406,
    nome: 'Capetinga',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112505,
    nome: 'Capim Branco',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112604,
    nome: 'Capinópolis',
    microrregiao: {
      id: 31017,
      nome: 'Ituiutaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310060,
      nome: 'Ituiutaba',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112653,
    nome: 'Capitão Andrade',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112703,
    nome: 'Capitão Enéas',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112802,
    nome: 'Capitólio',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310049,
      nome: 'Piumhi',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3112901,
    nome: 'Caputira',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3113008,
    nome: 'Caraí',
    microrregiao: {
      id: 31012,
      nome: 'Araçuaí',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3113107,
    nome: 'Caranaíba',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3113206,
    nome: 'Carandaí',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3113305,
    nome: 'Carangola',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310034,
      nome: 'Carangola',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3113404,
    nome: 'Caratinga',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3113503,
    nome: 'Carbonita',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3113602,
    nome: 'Careaçu',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3113701,
    nome: 'Carlos Chagas',
    microrregiao: {
      id: 31016,
      nome: 'Nanuque',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3113800,
    nome: 'Carmésia',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310005,
      nome: 'Itabira',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3113909,
    nome: 'Carmo da Cachoeira',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310045,
      nome: 'Três Corações',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114006,
    nome: 'Carmo da Mata',
    microrregiao: {
      id: 31046,
      nome: 'Oliveira',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114105,
    nome: 'Carmo de Minas',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114204,
    nome: 'Carmo do Cajuru',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114303,
    nome: 'Carmo do Paranaíba',
    microrregiao: {
      id: 31020,
      nome: 'Patos de Minas',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114402,
    nome: 'Carmo do Rio Claro',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114501,
    nome: 'Carmópolis de Minas',
    microrregiao: {
      id: 31046,
      nome: 'Oliveira',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310069,
      nome: 'Oliveira',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114550,
    nome: 'Carneirinho',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310058,
      nome: 'Iturama',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114600,
    nome: 'Carrancas',
    microrregiao: {
      id: 31057,
      nome: 'Lavras',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114709,
    nome: 'Carvalhópolis',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114808,
    nome: 'Carvalhos',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310054,
      nome: 'Caxambu - Baependi',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3114907,
    nome: 'Casa Grande',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115003,
    nome: 'Cascalho Rico',
    microrregiao: {
      id: 31018,
      nome: 'Uberlândia',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115102,
    nome: 'Cássia',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115201,
    nome: 'Conceição da Barra de Minas',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115300,
    nome: 'Cataguases',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310032,
      nome: 'Cataguases',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115359,
    nome: 'Catas Altas',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310003,
      nome: 'Santa Bárbara - Ouro Preto',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115409,
    nome: 'Catas Altas da Noruega',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115458,
    nome: 'Catuji',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115474,
    nome: 'Catuti',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310012,
      nome: 'Espinosa',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115508,
    nome: 'Caxambu',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310054,
      nome: 'Caxambu - Baependi',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115607,
    nome: 'Cedro do Abaeté',
    microrregiao: {
      id: 31024,
      nome: 'Três Marias',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310070,
      nome: 'Abaeté',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115706,
    nome: 'Central de Minas',
    microrregiao: {
      id: 31038,
      nome: 'Mantena',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310022,
      nome: 'Mantena',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115805,
    nome: 'Centralina',
    microrregiao: {
      id: 31018,
      nome: 'Uberlândia',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3115904,
    nome: 'Chácara',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116001,
    nome: 'Chalé',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116100,
    nome: 'Chapada do Norte',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310014,
      nome: 'Capelinha',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116159,
    nome: 'Chapada Gaúcha',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310011,
      nome: 'São Francisco',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116209,
    nome: 'Chiador',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116308,
    nome: 'Cipotânea',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116407,
    nome: 'Claraval',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116506,
    nome: 'Claro dos Poções',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116605,
    nome: 'Cláudio',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116704,
    nome: 'Coimbra',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116803,
    nome: 'Coluna',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3116902,
    nome: 'Comendador Gomes',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310057,
      nome: 'Frutal',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117009,
    nome: 'Comercinho',
    microrregiao: {
      id: 31013,
      nome: 'Pedra Azul',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310018,
      nome: 'Pedra Azul',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117108,
    nome: 'Conceição da Aparecida',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117207,
    nome: 'Conceição das Pedras',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117306,
    nome: 'Conceição das Alagoas',
    microrregiao: {
      id: 31022,
      nome: 'Uberaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310055,
      nome: 'Uberaba',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117405,
    nome: 'Conceição de Ipanema',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117504,
    nome: 'Conceição do Mato Dentro',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117603,
    nome: 'Conceição do Pará',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117702,
    nome: 'Conceição do Rio Verde',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117801,
    nome: 'Conceição dos Ouros',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117836,
    nome: 'Cônego Marinho',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310009,
      nome: 'Januária',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117876,
    nome: 'Confins',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3117900,
    nome: 'Congonhal',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3118007,
    nome: 'Congonhas',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3118106,
    nome: 'Congonhas do Norte',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3118205,
    nome: 'Conquista',
    microrregiao: {
      id: 31022,
      nome: 'Uberaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310055,
      nome: 'Uberaba',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3118304,
    nome: 'Conselheiro Lafaiete',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3118403,
    nome: 'Conselheiro Pena',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3118502,
    nome: 'Consolação',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3118601,
    nome: 'Contagem',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3118700,
    nome: 'Coqueiral',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310046,
      nome: 'Três Pontas - Boa Esperança',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3118809,
    nome: 'Coração de Jesus',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3118908,
    nome: 'Cordisburgo',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119005,
    nome: 'Cordislândia',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310040,
      nome: 'Varginha',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119104,
    nome: 'Corinto',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119203,
    nome: 'Coroaci',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119302,
    nome: 'Coromandel',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310064,
      nome: 'Patrocínio',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119401,
    nome: 'Coronel Fabriciano',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119500,
    nome: 'Coronel Murta',
    microrregiao: {
      id: 31012,
      nome: 'Araçuaí',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310017,
      nome: 'Araçuaí',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119609,
    nome: 'Coronel Pacheco',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119708,
    nome: 'Coronel Xavier Chaves',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119807,
    nome: 'Córrego Danta',
    microrregiao: {
      id: 31042,
      nome: 'Piuí',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310066,
      nome: 'Formiga',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119906,
    nome: 'Córrego do Bom Jesus',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3119955,
    nome: 'Córrego Fundo',
    microrregiao: {
      id: 31044,
      nome: 'Formiga',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310066,
      nome: 'Formiga',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120003,
    nome: 'Córrego Novo',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120102,
    nome: 'Couto de Magalhães de Minas',
    microrregiao: {
      id: 31010,
      nome: 'Diamantina',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120151,
    nome: 'Crisólita',
    microrregiao: {
      id: 31016,
      nome: 'Nanuque',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310019,
      nome: 'Águas Formosas',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120201,
    nome: 'Cristais',
    microrregiao: {
      id: 31045,
      nome: 'Campo Belo',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310048,
      nome: 'Campo Belo',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120300,
    nome: 'Cristália',
    microrregiao: {
      id: 31008,
      nome: 'Grão Mogol',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120409,
    nome: 'Cristiano Otoni',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120508,
    nome: 'Cristina',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120607,
    nome: 'Crucilândia',
    microrregiao: {
      id: 31032,
      nome: 'Itaguara',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310069,
      nome: 'Oliveira',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120706,
    nome: 'Cruzeiro da Fortaleza',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310064,
      nome: 'Patrocínio',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120805,
    nome: 'Cruzília',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310054,
      nome: 'Caxambu - Baependi',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120839,
    nome: 'Cuparaque',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310023,
      nome: 'Aimorés - Resplendor',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120870,
    nome: 'Curral de Dentro',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3120904,
    nome: 'Curvelo',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121001,
    nome: 'Datas',
    microrregiao: {
      id: 31010,
      nome: 'Diamantina',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121100,
    nome: 'Delfim Moreira',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121209,
    nome: 'Delfinópolis',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121258,
    nome: 'Delta',
    microrregiao: {
      id: 31022,
      nome: 'Uberaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310055,
      nome: 'Uberaba',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121308,
    nome: 'Descoberto',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310035,
      nome: 'São João Nepomuceno - Bicas',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121407,
    nome: 'Desterro de Entre Rios',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121506,
    nome: 'Desterro do Melo',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121605,
    nome: 'Diamantina',
    microrregiao: {
      id: 31010,
      nome: 'Diamantina',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121704,
    nome: 'Diogo de Vasconcelos',
    microrregiao: {
      id: 31033,
      nome: 'Ouro Preto',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121803,
    nome: 'Dionísio',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3121902,
    nome: 'Divinésia',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122009,
    nome: 'Divino',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310034,
      nome: 'Carangola',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122108,
    nome: 'Divino das Laranjeiras',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122207,
    nome: 'Divinolândia de Minas',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122306,
    nome: 'Divinópolis',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122355,
    nome: 'Divisa Alegre',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310018,
      nome: 'Pedra Azul',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122405,
    nome: 'Divisa Nova',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122454,
    nome: 'Divisópolis',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310018,
      nome: 'Pedra Azul',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122470,
    nome: 'Dom Bosco',
    microrregiao: {
      id: 31001,
      nome: 'Unaí',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122504,
    nome: 'Dom Cavati',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122603,
    nome: 'Dom Joaquim',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122702,
    nome: 'Dom Silvério',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122801,
    nome: 'Dom Viçoso',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3122900,
    nome: 'Dona Euzébia',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310032,
      nome: 'Cataguases',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123007,
    nome: 'Dores de Campos',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123106,
    nome: 'Dores de Guanhães',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123205,
    nome: 'Dores do Indaiá',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310067,
      nome: 'Dores do Indaiá',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123304,
    nome: 'Dores do Turvo',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123403,
    nome: 'Doresópolis',
    microrregiao: {
      id: 31042,
      nome: 'Piuí',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310049,
      nome: 'Piumhi',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123502,
    nome: 'Douradoquara',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310061,
      nome: 'Monte Carmelo',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123528,
    nome: 'Durandé',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123601,
    nome: 'Elói Mendes',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310040,
      nome: 'Varginha',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123700,
    nome: 'Engenheiro Caldas',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123809,
    nome: 'Engenheiro Navarro',
    microrregiao: {
      id: 31009,
      nome: 'Bocaiúva',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123858,
    nome: 'Entre Folhas',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3123908,
    nome: 'Entre Rios de Minas',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3124005,
    nome: 'Ervália',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3124104,
    nome: 'Esmeraldas',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3124203,
    nome: 'Espera Feliz',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310034,
      nome: 'Carangola',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3124302,
    nome: 'Espinosa',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310012,
      nome: 'Espinosa',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3124401,
    nome: 'Espírito Santo do Dourado',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3124500,
    nome: 'Estiva',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3124609,
    nome: 'Estrela Dalva',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310036,
      nome: 'Além Paraíba',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3124708,
    nome: 'Estrela do Indaiá',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310067,
      nome: 'Dores do Indaiá',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3124807,
    nome: 'Estrela do Sul',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310061,
      nome: 'Monte Carmelo',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3124906,
    nome: 'Eugenópolis',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125002,
    nome: 'Ewbank da Câmara',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125101,
    nome: 'Extrema',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125200,
    nome: 'Fama',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125309,
    nome: 'Faria Lemos',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310034,
      nome: 'Carangola',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125408,
    nome: 'Felício dos Santos',
    microrregiao: {
      id: 31010,
      nome: 'Diamantina',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125507,
    nome: 'São Gonçalo do Rio Preto',
    microrregiao: {
      id: 31010,
      nome: 'Diamantina',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125606,
    nome: 'Felisburgo',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125705,
    nome: 'Felixlândia',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125804,
    nome: 'Fernandes Tourinho',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125903,
    nome: 'Ferros',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310005,
      nome: 'Itabira',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3125952,
    nome: 'Fervedouro',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126000,
    nome: 'Florestal',
    microrregiao: {
      id: 31029,
      nome: 'Pará de Minas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126109,
    nome: 'Formiga',
    microrregiao: {
      id: 31044,
      nome: 'Formiga',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310066,
      nome: 'Formiga',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126208,
    nome: 'Formoso',
    microrregiao: {
      id: 31001,
      nome: 'Unaí',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126307,
    nome: 'Fortaleza de Minas',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126406,
    nome: 'Fortuna de Minas',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126505,
    nome: 'Francisco Badaró',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310017,
      nome: 'Araçuaí',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126604,
    nome: 'Francisco Dumont',
    microrregiao: {
      id: 31009,
      nome: 'Bocaiúva',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126703,
    nome: 'Francisco Sá',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126752,
    nome: 'Franciscópolis',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126802,
    nome: 'Frei Gaspar',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126901,
    nome: 'Frei Inocêncio',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3126950,
    nome: 'Frei Lagonegro',
    microrregiao: {
      id: 31036,
      nome: 'Peçanha',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127008,
    nome: 'Fronteira',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310057,
      nome: 'Frutal',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127057,
    nome: 'Fronteira dos Vales',
    microrregiao: {
      id: 31016,
      nome: 'Nanuque',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310019,
      nome: 'Águas Formosas',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127073,
    nome: 'Fruta de Leite',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127107,
    nome: 'Frutal',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310057,
      nome: 'Frutal',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127206,
    nome: 'Funilândia',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127305,
    nome: 'Galiléia',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127339,
    nome: 'Gameleiras',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310012,
      nome: 'Espinosa',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127354,
    nome: 'Glaucilândia',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127370,
    nome: 'Goiabeira',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127388,
    nome: 'Goianá',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127404,
    nome: 'Gonçalves',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127503,
    nome: 'Gonzaga',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127602,
    nome: 'Gouveia',
    microrregiao: {
      id: 31010,
      nome: 'Diamantina',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127701,
    nome: 'Governador Valadares',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127800,
    nome: 'Grão Mogol',
    microrregiao: {
      id: 31008,
      nome: 'Grão Mogol',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3127909,
    nome: 'Grupiara',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310061,
      nome: 'Monte Carmelo',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128006,
    nome: 'Guanhães',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128105,
    nome: 'Guapé',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128204,
    nome: 'Guaraciaba',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128253,
    nome: 'Guaraciama',
    microrregiao: {
      id: 31009,
      nome: 'Bocaiúva',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128303,
    nome: 'Guaranésia',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310044,
      nome: 'Guaxupé',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128402,
    nome: 'Guarani',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128501,
    nome: 'Guarará',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310035,
      nome: 'São João Nepomuceno - Bicas',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128600,
    nome: 'Guarda-Mor',
    microrregiao: {
      id: 31002,
      nome: 'Paracatu',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128709,
    nome: 'Guaxupé',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310044,
      nome: 'Guaxupé',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128808,
    nome: 'Guidoval',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3128907,
    nome: 'Guimarânia',
    microrregiao: {
      id: 31020,
      nome: 'Patos de Minas',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310064,
      nome: 'Patrocínio',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129004,
    nome: 'Guiricema',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129103,
    nome: 'Gurinhatã',
    microrregiao: {
      id: 31017,
      nome: 'Ituiutaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310060,
      nome: 'Ituiutaba',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129202,
    nome: 'Heliodora',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129301,
    nome: 'Iapu',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129400,
    nome: 'Ibertioga',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129509,
    nome: 'Ibiá',
    microrregiao: {
      id: 31023,
      nome: 'Araxá',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310056,
      nome: 'Araxá',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129608,
    nome: 'Ibiaí',
    microrregiao: {
      id: 31006,
      nome: 'Pirapora',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310010,
      nome: 'Pirapora',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129657,
    nome: 'Ibiracatu',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129707,
    nome: 'Ibiraci',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129806,
    nome: 'Ibirité',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3129905,
    nome: 'Ibitiúra de Minas',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310051,
      nome: 'Poços de Caldas',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130002,
    nome: 'Ibituruna',
    microrregiao: {
      id: 31046,
      nome: 'Oliveira',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130051,
    nome: 'Icaraí de Minas',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310011,
      nome: 'São Francisco',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130101,
    nome: 'Igarapé',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130200,
    nome: 'Igaratinga',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310068,
      nome: 'Pará de Minas',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130309,
    nome: 'Iguatama',
    microrregiao: {
      id: 31042,
      nome: 'Piuí',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310066,
      nome: 'Formiga',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130408,
    nome: 'Ijaci',
    microrregiao: {
      id: 31057,
      nome: 'Lavras',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130507,
    nome: 'Ilicínea',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310046,
      nome: 'Três Pontas - Boa Esperança',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130556,
    nome: 'Imbé de Minas',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130606,
    nome: 'Inconfidentes',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130655,
    nome: 'Indaiabira',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130705,
    nome: 'Indianópolis',
    microrregiao: {
      id: 31018,
      nome: 'Uberlândia',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130804,
    nome: 'Ingaí',
    microrregiao: {
      id: 31057,
      nome: 'Lavras',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3130903,
    nome: 'Inhapim',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131000,
    nome: 'Inhaúma',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131109,
    nome: 'Inimutaba',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131158,
    nome: 'Ipaba',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131208,
    nome: 'Ipanema',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131307,
    nome: 'Ipatinga',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131406,
    nome: 'Ipiaçu',
    microrregiao: {
      id: 31017,
      nome: 'Ituiutaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310060,
      nome: 'Ituiutaba',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131505,
    nome: 'Ipuiúna',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131604,
    nome: 'Iraí de Minas',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310061,
      nome: 'Monte Carmelo',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131703,
    nome: 'Itabira',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310005,
      nome: 'Itabira',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131802,
    nome: 'Itabirinha',
    microrregiao: {
      id: 31038,
      nome: 'Mantena',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310022,
      nome: 'Mantena',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3131901,
    nome: 'Itabirito',
    microrregiao: {
      id: 31033,
      nome: 'Ouro Preto',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310003,
      nome: 'Santa Bárbara - Ouro Preto',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3132008,
    nome: 'Itacambira',
    microrregiao: {
      id: 31008,
      nome: 'Grão Mogol',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3132107,
    nome: 'Itacarambi',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310009,
      nome: 'Januária',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3132206,
    nome: 'Itaguara',
    microrregiao: {
      id: 31032,
      nome: 'Itaguara',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310069,
      nome: 'Oliveira',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3132305,
    nome: 'Itaipé',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3132404,
    nome: 'Itajubá',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3132503,
    nome: 'Itamarandiba',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310014,
      nome: 'Capelinha',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3132602,
    nome: 'Itamarati de Minas',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310032,
      nome: 'Cataguases',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3132701,
    nome: 'Itambacuri',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3132800,
    nome: 'Itambé do Mato Dentro',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310005,
      nome: 'Itabira',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3132909,
    nome: 'Itamogi',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310047,
      nome: 'São Sebastião do Paraíso',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133006,
    nome: 'Itamonte',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133105,
    nome: 'Itanhandu',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133204,
    nome: 'Itanhomi',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133303,
    nome: 'Itaobim',
    microrregiao: {
      id: 31013,
      nome: 'Pedra Azul',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133402,
    nome: 'Itapagipe',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310057,
      nome: 'Frutal',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133501,
    nome: 'Itapecerica',
    microrregiao: {
      id: 31044,
      nome: 'Formiga',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133600,
    nome: 'Itapeva',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133709,
    nome: 'Itatiaiuçu',
    microrregiao: {
      id: 31032,
      nome: 'Itaguara',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133758,
    nome: 'Itaú de Minas',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133808,
    nome: 'Itaúna',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3133907,
    nome: 'Itaverava',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3134004,
    nome: 'Itinga',
    microrregiao: {
      id: 31012,
      nome: 'Araçuaí',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310017,
      nome: 'Araçuaí',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3134103,
    nome: 'Itueta',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310023,
      nome: 'Aimorés - Resplendor',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3134202,
    nome: 'Ituiutaba',
    microrregiao: {
      id: 31017,
      nome: 'Ituiutaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310060,
      nome: 'Ituiutaba',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3134301,
    nome: 'Itumirim',
    microrregiao: {
      id: 31057,
      nome: 'Lavras',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3134400,
    nome: 'Iturama',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310058,
      nome: 'Iturama',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3134509,
    nome: 'Itutinga',
    microrregiao: {
      id: 31057,
      nome: 'Lavras',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3134608,
    nome: 'Jaboticatubas',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3134707,
    nome: 'Jacinto',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3134806,
    nome: 'Jacuí',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310047,
      nome: 'São Sebastião do Paraíso',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3134905,
    nome: 'Jacutinga',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135001,
    nome: 'Jaguaraçu',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135050,
    nome: 'Jaíba',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135076,
    nome: 'Jampruca',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135100,
    nome: 'Janaúba',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135209,
    nome: 'Januária',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310009,
      nome: 'Januária',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135308,
    nome: 'Japaraíba',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135357,
    nome: 'Japonvar',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135407,
    nome: 'Jeceaba',
    microrregiao: {
      id: 31032,
      nome: 'Itaguara',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135456,
    nome: 'Jenipapo de Minas',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310017,
      nome: 'Araçuaí',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135506,
    nome: 'Jequeri',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135605,
    nome: 'Jequitaí',
    microrregiao: {
      id: 31006,
      nome: 'Pirapora',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135704,
    nome: 'Jequitibá',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135803,
    nome: 'Jequitinhonha',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3135902,
    nome: 'Jesuânia',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136009,
    nome: 'Joaíma',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136108,
    nome: 'Joanésia',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136207,
    nome: 'João Monlevade',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310026,
      nome: 'João Monlevade',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136306,
    nome: 'João Pinheiro',
    microrregiao: {
      id: 31002,
      nome: 'Paracatu',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136405,
    nome: 'Joaquim Felício',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136504,
    nome: 'Jordânia',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136520,
    nome: 'José Gonçalves de Minas',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310017,
      nome: 'Araçuaí',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136553,
    nome: 'José Raydan',
    microrregiao: {
      id: 31036,
      nome: 'Peçanha',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136579,
    nome: 'Josenópolis',
    microrregiao: {
      id: 31008,
      nome: 'Grão Mogol',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136603,
    nome: 'Nova União',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136652,
    nome: 'Juatuba',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136702,
    nome: 'Juiz de Fora',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136801,
    nome: 'Juramento',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136900,
    nome: 'Juruaia',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310044,
      nome: 'Guaxupé',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3136959,
    nome: 'Juvenília',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310009,
      nome: 'Januária',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137007,
    nome: 'Ladainha',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137106,
    nome: 'Lagamar',
    microrregiao: {
      id: 31002,
      nome: 'Paracatu',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137205,
    nome: 'Lagoa da Prata',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137304,
    nome: 'Lagoa dos Patos',
    microrregiao: {
      id: 31006,
      nome: 'Pirapora',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137403,
    nome: 'Lagoa Dourada',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137502,
    nome: 'Lagoa Formosa',
    microrregiao: {
      id: 31020,
      nome: 'Patos de Minas',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137536,
    nome: 'Lagoa Grande',
    microrregiao: {
      id: 31002,
      nome: 'Paracatu',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137601,
    nome: 'Lagoa Santa',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137700,
    nome: 'Lajinha',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137809,
    nome: 'Lambari',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3137908,
    nome: 'Lamim',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138005,
    nome: 'Laranjal',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310032,
      nome: 'Cataguases',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138104,
    nome: 'Lassance',
    microrregiao: {
      id: 31006,
      nome: 'Pirapora',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310010,
      nome: 'Pirapora',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138203,
    nome: 'Lavras',
    microrregiao: {
      id: 31057,
      nome: 'Lavras',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138302,
    nome: 'Leandro Ferreira',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138351,
    nome: 'Leme do Prado',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310014,
      nome: 'Capelinha',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138401,
    nome: 'Leopoldina',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310032,
      nome: 'Cataguases',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138500,
    nome: 'Liberdade',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138609,
    nome: 'Lima Duarte',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138625,
    nome: 'Limeira do Oeste',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310058,
      nome: 'Iturama',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138658,
    nome: 'Lontra',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138674,
    nome: 'Luisburgo',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138682,
    nome: 'Luislândia',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138708,
    nome: 'Luminárias',
    microrregiao: {
      id: 31057,
      nome: 'Lavras',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138807,
    nome: 'Luz',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310067,
      nome: 'Dores do Indaiá',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3138906,
    nome: 'Machacalis',
    microrregiao: {
      id: 31016,
      nome: 'Nanuque',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310019,
      nome: 'Águas Formosas',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139003,
    nome: 'Machado',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139102,
    nome: 'Madre de Deus de Minas',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139201,
    nome: 'Malacacheta',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139250,
    nome: 'Mamonas',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310012,
      nome: 'Espinosa',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139300,
    nome: 'Manga',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139409,
    nome: 'Manhuaçu',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139508,
    nome: 'Manhumirim',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139607,
    nome: 'Mantena',
    microrregiao: {
      id: 31038,
      nome: 'Mantena',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310022,
      nome: 'Mantena',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139706,
    nome: 'Maravilhas',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310068,
      nome: 'Pará de Minas',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139805,
    nome: 'Mar de Espanha',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310035,
      nome: 'São João Nepomuceno - Bicas',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3139904,
    nome: 'Maria da Fé',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140001,
    nome: 'Mariana',
    microrregiao: {
      id: 31033,
      nome: 'Ouro Preto',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310003,
      nome: 'Santa Bárbara - Ouro Preto',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140100,
    nome: 'Marilac',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140159,
    nome: 'Mário Campos',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140209,
    nome: 'Maripá de Minas',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310035,
      nome: 'São João Nepomuceno - Bicas',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140308,
    nome: 'Marliéria',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140407,
    nome: 'Marmelópolis',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140506,
    nome: 'Martinho Campos',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310067,
      nome: 'Dores do Indaiá',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140530,
    nome: 'Martins Soares',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140555,
    nome: 'Mata Verde',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140605,
    nome: 'Materlândia',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140704,
    nome: 'Mateus Leme',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140803,
    nome: 'Matias Barbosa',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140852,
    nome: 'Matias Cardoso',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3140902,
    nome: 'Matipó',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3141009,
    nome: 'Mato Verde',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310012,
      nome: 'Espinosa',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3141108,
    nome: 'Matozinhos',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3141207,
    nome: 'Matutina',
    microrregiao: {
      id: 31020,
      nome: 'Patos de Minas',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3141306,
    nome: 'Medeiros',
    microrregiao: {
      id: 31042,
      nome: 'Piuí',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310066,
      nome: 'Formiga',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3141405,
    nome: 'Medina',
    microrregiao: {
      id: 31013,
      nome: 'Pedra Azul',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310018,
      nome: 'Pedra Azul',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3141504,
    nome: 'Mendes Pimentel',
    microrregiao: {
      id: 31038,
      nome: 'Mantena',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310022,
      nome: 'Mantena',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3141603,
    nome: 'Mercês',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3141702,
    nome: 'Mesquita',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3141801,
    nome: 'Minas Novas',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310014,
      nome: 'Capelinha',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3141900,
    nome: 'Minduri',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310054,
      nome: 'Caxambu - Baependi',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142007,
    nome: 'Mirabela',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142106,
    nome: 'Miradouro',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142205,
    nome: 'Miraí',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142254,
    nome: 'Miravânia',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142304,
    nome: 'Moeda',
    microrregiao: {
      id: 31032,
      nome: 'Itaguara',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142403,
    nome: 'Moema',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310067,
      nome: 'Dores do Indaiá',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142502,
    nome: 'Monjolos',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142601,
    nome: 'Monsenhor Paulo',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310040,
      nome: 'Varginha',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142700,
    nome: 'Montalvânia',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310009,
      nome: 'Januária',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142809,
    nome: 'Monte Alegre de Minas',
    microrregiao: {
      id: 31018,
      nome: 'Uberlândia',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3142908,
    nome: 'Monte Azul',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310012,
      nome: 'Espinosa',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143005,
    nome: 'Monte Belo',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310044,
      nome: 'Guaxupé',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143104,
    nome: 'Monte Carmelo',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310061,
      nome: 'Monte Carmelo',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143153,
    nome: 'Monte Formoso',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143203,
    nome: 'Monte Santo de Minas',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310047,
      nome: 'São Sebastião do Paraíso',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143302,
    nome: 'Montes Claros',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143401,
    nome: 'Monte Sião',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143450,
    nome: 'Montezuma',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310012,
      nome: 'Espinosa',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143500,
    nome: 'Morada Nova de Minas',
    microrregiao: {
      id: 31024,
      nome: 'Três Marias',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310070,
      nome: 'Abaeté',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143609,
    nome: 'Morro da Garça',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143708,
    nome: 'Morro do Pilar',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143807,
    nome: 'Munhoz',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3143906,
    nome: 'Muriaé',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144003,
    nome: 'Mutum',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144102,
    nome: 'Muzambinho',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310044,
      nome: 'Guaxupé',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144201,
    nome: 'Nacip Raydan',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144300,
    nome: 'Nanuque',
    microrregiao: {
      id: 31016,
      nome: 'Nanuque',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144359,
    nome: 'Naque',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144375,
    nome: 'Natalândia',
    microrregiao: {
      id: 31001,
      nome: 'Unaí',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144409,
    nome: 'Natércia',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144508,
    nome: 'Nazareno',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144607,
    nome: 'Nepomuceno',
    microrregiao: {
      id: 31057,
      nome: 'Lavras',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144656,
    nome: 'Ninheira',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144672,
    nome: 'Nova Belém',
    microrregiao: {
      id: 31038,
      nome: 'Mantena',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310022,
      nome: 'Mantena',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144706,
    nome: 'Nova Era',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310026,
      nome: 'João Monlevade',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144805,
    nome: 'Nova Lima',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3144904,
    nome: 'Nova Módica',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145000,
    nome: 'Nova Ponte',
    microrregiao: {
      id: 31023,
      nome: 'Araxá',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310055,
      nome: 'Uberaba',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145059,
    nome: 'Nova Porteirinha',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145109,
    nome: 'Nova Resende',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310044,
      nome: 'Guaxupé',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145208,
    nome: 'Nova Serrana',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145307,
    nome: 'Novo Cruzeiro',
    microrregiao: {
      id: 31012,
      nome: 'Araçuaí',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145356,
    nome: 'Novo Oriente de Minas',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145372,
    nome: 'Novorizonte',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145406,
    nome: 'Olaria',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145455,
    nome: "Olhos-d'Água",
    microrregiao: {
      id: 31009,
      nome: 'Bocaiúva',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145505,
    nome: 'Olímpio Noronha',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145604,
    nome: 'Oliveira',
    microrregiao: {
      id: 31046,
      nome: 'Oliveira',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310069,
      nome: 'Oliveira',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145703,
    nome: 'Oliveira Fortes',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145802,
    nome: 'Onça de Pitangui',
    microrregiao: {
      id: 31029,
      nome: 'Pará de Minas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310068,
      nome: 'Pará de Minas',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145851,
    nome: 'Oratórios',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145877,
    nome: 'Orizânia',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310034,
      nome: 'Carangola',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3145901,
    nome: 'Ouro Branco',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146008,
    nome: 'Ouro Fino',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146107,
    nome: 'Ouro Preto',
    microrregiao: {
      id: 31033,
      nome: 'Ouro Preto',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310003,
      nome: 'Santa Bárbara - Ouro Preto',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146206,
    nome: 'Ouro Verde de Minas',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146255,
    nome: 'Padre Carvalho',
    microrregiao: {
      id: 31008,
      nome: 'Grão Mogol',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146305,
    nome: 'Padre Paraíso',
    microrregiao: {
      id: 31012,
      nome: 'Araçuaí',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146404,
    nome: 'Paineiras',
    microrregiao: {
      id: 31024,
      nome: 'Três Marias',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310070,
      nome: 'Abaeté',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146503,
    nome: 'Pains',
    microrregiao: {
      id: 31044,
      nome: 'Formiga',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310066,
      nome: 'Formiga',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146552,
    nome: 'Pai Pedro',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146602,
    nome: 'Paiva',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146701,
    nome: 'Palma',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310032,
      nome: 'Cataguases',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146750,
    nome: 'Palmópolis',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3146909,
    nome: 'Papagaios',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310068,
      nome: 'Pará de Minas',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147006,
    nome: 'Paracatu',
    microrregiao: {
      id: 31002,
      nome: 'Paracatu',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147105,
    nome: 'Pará de Minas',
    microrregiao: {
      id: 31029,
      nome: 'Pará de Minas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310068,
      nome: 'Pará de Minas',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147204,
    nome: 'Paraguaçu',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147303,
    nome: 'Paraisópolis',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147402,
    nome: 'Paraopeba',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147501,
    nome: 'Passabém',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310005,
      nome: 'Itabira',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147600,
    nome: 'Passa Quatro',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147709,
    nome: 'Passa Tempo',
    microrregiao: {
      id: 31046,
      nome: 'Oliveira',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310069,
      nome: 'Oliveira',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147808,
    nome: 'Passa Vinte',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147907,
    nome: 'Passos',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3147956,
    nome: 'Patis',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148004,
    nome: 'Patos de Minas',
    microrregiao: {
      id: 31020,
      nome: 'Patos de Minas',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148103,
    nome: 'Patrocínio',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310064,
      nome: 'Patrocínio',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148202,
    nome: 'Patrocínio do Muriaé',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148301,
    nome: 'Paula Cândido',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148400,
    nome: 'Paulistas',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148509,
    nome: 'Pavão',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148608,
    nome: 'Peçanha',
    microrregiao: {
      id: 31036,
      nome: 'Peçanha',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148707,
    nome: 'Pedra Azul',
    microrregiao: {
      id: 31013,
      nome: 'Pedra Azul',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310018,
      nome: 'Pedra Azul',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148756,
    nome: 'Pedra Bonita',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310034,
      nome: 'Carangola',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148806,
    nome: 'Pedra do Anta',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3148905,
    nome: 'Pedra do Indaiá',
    microrregiao: {
      id: 31044,
      nome: 'Formiga',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149002,
    nome: 'Pedra Dourada',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310034,
      nome: 'Carangola',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149101,
    nome: 'Pedralva',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149150,
    nome: 'Pedras de Maria da Cruz',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310009,
      nome: 'Januária',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149200,
    nome: 'Pedrinópolis',
    microrregiao: {
      id: 31023,
      nome: 'Araxá',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310056,
      nome: 'Araxá',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149309,
    nome: 'Pedro Leopoldo',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149408,
    nome: 'Pedro Teixeira',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149507,
    nome: 'Pequeri',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310035,
      nome: 'São João Nepomuceno - Bicas',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149606,
    nome: 'Pequi',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310068,
      nome: 'Pará de Minas',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149705,
    nome: 'Perdigão',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149804,
    nome: 'Perdizes',
    microrregiao: {
      id: 31023,
      nome: 'Araxá',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310056,
      nome: 'Araxá',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149903,
    nome: 'Perdões',
    microrregiao: {
      id: 31045,
      nome: 'Campo Belo',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3149952,
    nome: 'Periquito',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150000,
    nome: 'Pescador',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150109,
    nome: 'Piau',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150158,
    nome: 'Piedade de Caratinga',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150208,
    nome: 'Piedade de Ponte Nova',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150307,
    nome: 'Piedade do Rio Grande',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150406,
    nome: 'Piedade dos Gerais',
    microrregiao: {
      id: 31032,
      nome: 'Itaguara',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310069,
      nome: 'Oliveira',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150505,
    nome: 'Pimenta',
    microrregiao: {
      id: 31044,
      nome: 'Formiga',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310066,
      nome: 'Formiga',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150539,
    nome: "Pingo d'Água",
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150570,
    nome: 'Pintópolis',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310011,
      nome: 'São Francisco',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150604,
    nome: 'Piracema',
    microrregiao: {
      id: 31046,
      nome: 'Oliveira',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310069,
      nome: 'Oliveira',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150703,
    nome: 'Pirajuba',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310057,
      nome: 'Frutal',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150802,
    nome: 'Piranga',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3150901,
    nome: 'Piranguçu',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3151008,
    nome: 'Piranguinho',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3151107,
    nome: 'Pirapetinga',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310036,
      nome: 'Além Paraíba',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3151206,
    nome: 'Pirapora',
    microrregiao: {
      id: 31006,
      nome: 'Pirapora',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310010,
      nome: 'Pirapora',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3151305,
    nome: 'Piraúba',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3151404,
    nome: 'Pitangui',
    microrregiao: {
      id: 31029,
      nome: 'Pará de Minas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3151503,
    nome: 'Piumhi',
    microrregiao: {
      id: 31042,
      nome: 'Piuí',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310049,
      nome: 'Piumhi',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3151602,
    nome: 'Planura',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310057,
      nome: 'Frutal',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3151701,
    nome: 'Poço Fundo',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3151800,
    nome: 'Poços de Caldas',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310051,
      nome: 'Poços de Caldas',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3151909,
    nome: 'Pocrane',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152006,
    nome: 'Pompéu',
    microrregiao: {
      id: 31024,
      nome: 'Três Marias',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310067,
      nome: 'Dores do Indaiá',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152105,
    nome: 'Ponte Nova',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152131,
    nome: 'Ponto Chique',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310010,
      nome: 'Pirapora',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152170,
    nome: 'Ponto dos Volantes',
    microrregiao: {
      id: 31012,
      nome: 'Araçuaí',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152204,
    nome: 'Porteirinha',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152303,
    nome: 'Porto Firme',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152402,
    nome: 'Poté',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152501,
    nome: 'Pouso Alegre',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152600,
    nome: 'Pouso Alto',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152709,
    nome: 'Prados',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152808,
    nome: 'Prata',
    microrregiao: {
      id: 31018,
      nome: 'Uberlândia',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3152907,
    nome: 'Pratápolis',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3153004,
    nome: 'Pratinha',
    microrregiao: {
      id: 31023,
      nome: 'Araxá',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310056,
      nome: 'Araxá',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3153103,
    nome: 'Presidente Bernardes',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3153202,
    nome: 'Presidente Juscelino',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3153301,
    nome: 'Presidente Kubitschek',
    microrregiao: {
      id: 31010,
      nome: 'Diamantina',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3153400,
    nome: 'Presidente Olegário',
    microrregiao: {
      id: 31002,
      nome: 'Paracatu',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3153509,
    nome: 'Alto Jequitibá',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3153608,
    nome: 'Prudente de Morais',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3153707,
    nome: 'Quartel Geral',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310067,
      nome: 'Dores do Indaiá',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3153806,
    nome: 'Queluzito',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3153905,
    nome: 'Raposos',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154002,
    nome: 'Raul Soares',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154101,
    nome: 'Recreio',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310032,
      nome: 'Cataguases',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154150,
    nome: 'Reduto',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154200,
    nome: 'Resende Costa',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154309,
    nome: 'Resplendor',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310023,
      nome: 'Aimorés - Resplendor',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154408,
    nome: 'Ressaquinha',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154457,
    nome: 'Riachinho',
    microrregiao: {
      id: 31006,
      nome: 'Pirapora',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154507,
    nome: 'Riacho dos Machados',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154606,
    nome: 'Ribeirão das Neves',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154705,
    nome: 'Ribeirão Vermelho',
    microrregiao: {
      id: 31057,
      nome: 'Lavras',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154804,
    nome: 'Rio Acima',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3154903,
    nome: 'Rio Casca',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3155009,
    nome: 'Rio Doce',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3155108,
    nome: 'Rio do Prado',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3155207,
    nome: 'Rio Espera',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3155306,
    nome: 'Rio Manso',
    microrregiao: {
      id: 31032,
      nome: 'Itaguara',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310069,
      nome: 'Oliveira',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3155405,
    nome: 'Rio Novo',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3155504,
    nome: 'Rio Paranaíba',
    microrregiao: {
      id: 31020,
      nome: 'Patos de Minas',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3155603,
    nome: 'Rio Pardo de Minas',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3155702,
    nome: 'Rio Piracicaba',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310026,
      nome: 'João Monlevade',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3155801,
    nome: 'Rio Pomba',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3155900,
    nome: 'Rio Preto',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156007,
    nome: 'Rio Vermelho',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156106,
    nome: 'Ritápolis',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156205,
    nome: 'Rochedo de Minas',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310035,
      nome: 'São João Nepomuceno - Bicas',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156304,
    nome: 'Rodeiro',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156403,
    nome: 'Romaria',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310061,
      nome: 'Monte Carmelo',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156452,
    nome: 'Rosário da Limeira',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156502,
    nome: 'Rubelita',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156601,
    nome: 'Rubim',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156700,
    nome: 'Sabará',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156809,
    nome: 'Sabinópolis',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3156908,
    nome: 'Sacramento',
    microrregiao: {
      id: 31023,
      nome: 'Araxá',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310055,
      nome: 'Uberaba',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157005,
    nome: 'Salinas',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157104,
    nome: 'Salto da Divisa',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157203,
    nome: 'Santa Bárbara',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310003,
      nome: 'Santa Bárbara - Ouro Preto',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157252,
    nome: 'Santa Bárbara do Leste',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157278,
    nome: 'Santa Bárbara do Monte Verde',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157302,
    nome: 'Santa Bárbara do Tugúrio',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157336,
    nome: 'Santa Cruz de Minas',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157377,
    nome: 'Santa Cruz de Salinas',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157401,
    nome: 'Santa Cruz do Escalvado',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157500,
    nome: 'Santa Efigênia de Minas',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157609,
    nome: 'Santa Fé de Minas',
    microrregiao: {
      id: 31006,
      nome: 'Pirapora',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310010,
      nome: 'Pirapora',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157658,
    nome: 'Santa Helena de Minas',
    microrregiao: {
      id: 31016,
      nome: 'Nanuque',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310019,
      nome: 'Águas Formosas',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157708,
    nome: 'Santa Juliana',
    microrregiao: {
      id: 31023,
      nome: 'Araxá',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310055,
      nome: 'Uberaba',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157807,
    nome: 'Santa Luzia',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3157906,
    nome: 'Santa Margarida',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158003,
    nome: 'Santa Maria de Itabira',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310005,
      nome: 'Itabira',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158102,
    nome: 'Santa Maria do Salto',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158201,
    nome: 'Santa Maria do Suaçuí',
    microrregiao: {
      id: 31036,
      nome: 'Peçanha',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158300,
    nome: 'Santana da Vargem',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310046,
      nome: 'Três Pontas - Boa Esperança',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158409,
    nome: 'Santana de Cataguases',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310032,
      nome: 'Cataguases',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158508,
    nome: 'Santana de Pirapama',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158607,
    nome: 'Santana do Deserto',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158706,
    nome: 'Santana do Garambéu',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158805,
    nome: 'Santana do Jacaré',
    microrregiao: {
      id: 31045,
      nome: 'Campo Belo',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310048,
      nome: 'Campo Belo',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158904,
    nome: 'Santana do Manhuaçu',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3158953,
    nome: 'Santana do Paraíso',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159001,
    nome: 'Santana do Riacho',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159100,
    nome: 'Santana dos Montes',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159209,
    nome: 'Santa Rita de Caldas',
    microrregiao: {
      id: 31051,
      nome: 'Poços de Caldas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310051,
      nome: 'Poços de Caldas',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159308,
    nome: 'Santa Rita de Jacutinga',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159357,
    nome: 'Santa Rita de Minas',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159407,
    nome: 'Santa Rita de Ibitipoca',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159506,
    nome: 'Santa Rita do Itueto',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310023,
      nome: 'Aimorés - Resplendor',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159605,
    nome: 'Santa Rita do Sapucaí',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159704,
    nome: 'Santa Rosa da Serra',
    microrregiao: {
      id: 31020,
      nome: 'Patos de Minas',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310056,
      nome: 'Araxá',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159803,
    nome: 'Santa Vitória',
    microrregiao: {
      id: 31017,
      nome: 'Ituiutaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310060,
      nome: 'Ituiutaba',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3159902,
    nome: 'Santo Antônio do Amparo',
    microrregiao: {
      id: 31046,
      nome: 'Oliveira',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310043,
      nome: 'Lavras',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160009,
    nome: 'Santo Antônio do Aventureiro',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310036,
      nome: 'Além Paraíba',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160108,
    nome: 'Santo Antônio do Grama',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160207,
    nome: 'Santo Antônio do Itambé',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160306,
    nome: 'Santo Antônio do Jacinto',
    microrregiao: {
      id: 31014,
      nome: 'Almenara',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310015,
      nome: 'Almenara',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160405,
    nome: 'Santo Antônio do Monte',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160454,
    nome: 'Santo Antônio do Retiro',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310012,
      nome: 'Espinosa',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160504,
    nome: 'Santo Antônio do Rio Abaixo',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310005,
      nome: 'Itabira',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160603,
    nome: 'Santo Hipólito',
    microrregiao: {
      id: 31025,
      nome: 'Curvelo',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160702,
    nome: 'Santos Dumont',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160801,
    nome: 'São Bento Abade',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310045,
      nome: 'Três Corações',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160900,
    nome: 'São Brás do Suaçuí',
    microrregiao: {
      id: 31034,
      nome: 'Conselheiro Lafaiete',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3160959,
    nome: 'São Domingos das Dores',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161007,
    nome: 'São Domingos do Prata',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310026,
      nome: 'João Monlevade',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161056,
    nome: 'São Félix de Minas',
    microrregiao: {
      id: 31038,
      nome: 'Mantena',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310022,
      nome: 'Mantena',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161106,
    nome: 'São Francisco',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310011,
      nome: 'São Francisco',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161205,
    nome: 'São Francisco de Paula',
    microrregiao: {
      id: 31046,
      nome: 'Oliveira',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310069,
      nome: 'Oliveira',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161304,
    nome: 'São Francisco de Sales',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310058,
      nome: 'Iturama',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161403,
    nome: 'São Francisco do Glória',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161502,
    nome: 'São Geraldo',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161601,
    nome: 'São Geraldo da Piedade',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161650,
    nome: 'São Geraldo do Baixio',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161700,
    nome: 'São Gonçalo do Abaeté',
    microrregiao: {
      id: 31002,
      nome: 'Paracatu',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161809,
    nome: 'São Gonçalo do Pará',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3161908,
    nome: 'São Gonçalo do Rio Abaixo',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310026,
      nome: 'João Monlevade',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162005,
    nome: 'São Gonçalo do Sapucaí',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310040,
      nome: 'Varginha',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162104,
    nome: 'São Gotardo',
    microrregiao: {
      id: 31020,
      nome: 'Patos de Minas',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162203,
    nome: 'São João Batista do Glória',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162252,
    nome: 'São João da Lagoa',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162302,
    nome: 'São João da Mata',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162401,
    nome: 'São João da Ponte',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162450,
    nome: 'São João das Missões',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310009,
      nome: 'Januária',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162500,
    nome: 'São João del Rei',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162559,
    nome: 'São João do Manhuaçu',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162575,
    nome: 'São João do Manteninha',
    microrregiao: {
      id: 31038,
      nome: 'Mantena',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310022,
      nome: 'Mantena',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162609,
    nome: 'São João do Oriente',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162658,
    nome: 'São João do Pacuí',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162708,
    nome: 'São João do Paraíso',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162807,
    nome: 'São João Evangelista',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162906,
    nome: 'São João Nepomuceno',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310035,
      nome: 'São João Nepomuceno - Bicas',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162922,
    nome: 'São Joaquim de Bicas',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162948,
    nome: 'São José da Barra',
    microrregiao: {
      id: 31047,
      nome: 'Passos',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310041,
      nome: 'Passos',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3162955,
    nome: 'São José da Lapa',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3163003,
    nome: 'São José da Safira',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3163102,
    nome: 'São José da Varginha',
    microrregiao: {
      id: 31029,
      nome: 'Pará de Minas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310068,
      nome: 'Pará de Minas',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3163201,
    nome: 'São José do Alegre',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3163300,
    nome: 'São José do Divino',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3163409,
    nome: 'São José do Goiabal',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3163508,
    nome: 'São José do Jacuri',
    microrregiao: {
      id: 31036,
      nome: 'Peçanha',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3163607,
    nome: 'São José do Mantimento',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3163706,
    nome: 'São Lourenço',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3163805,
    nome: 'São Miguel do Anta',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3163904,
    nome: 'São Pedro da União',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310044,
      nome: 'Guaxupé',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164001,
    nome: 'São Pedro dos Ferros',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164100,
    nome: 'São Pedro do Suaçuí',
    microrregiao: {
      id: 31036,
      nome: 'Peçanha',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164209,
    nome: 'São Romão',
    microrregiao: {
      id: 31006,
      nome: 'Pirapora',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310011,
      nome: 'São Francisco',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164308,
    nome: 'São Roque de Minas',
    microrregiao: {
      id: 31042,
      nome: 'Piuí',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310049,
      nome: 'Piumhi',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164407,
    nome: 'São Sebastião da Bela Vista',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164431,
    nome: 'São Sebastião da Vargem Alegre',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164472,
    nome: 'São Sebastião do Anta',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164506,
    nome: 'São Sebastião do Maranhão',
    microrregiao: {
      id: 31036,
      nome: 'Peçanha',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164605,
    nome: 'São Sebastião do Oeste',
    microrregiao: {
      id: 31043,
      nome: 'Divinópolis',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310065,
      nome: 'Divinópolis',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164704,
    nome: 'São Sebastião do Paraíso',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310047,
      nome: 'São Sebastião do Paraíso',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164803,
    nome: 'São Sebastião do Rio Preto',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310005,
      nome: 'Itabira',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3164902,
    nome: 'São Sebastião do Rio Verde',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165008,
    nome: 'São Tiago',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165107,
    nome: 'São Tomás de Aquino',
    microrregiao: {
      id: 31048,
      nome: 'São Sebastião do Paraíso',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310047,
      nome: 'São Sebastião do Paraíso',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165206,
    nome: 'São Tomé das Letras',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310045,
      nome: 'Três Corações',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165305,
    nome: 'São Vicente de Minas',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165404,
    nome: 'Sapucaí-Mirim',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165503,
    nome: 'Sardoá',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165537,
    nome: 'Sarzedo',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165552,
    nome: 'Setubinha',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165560,
    nome: 'Sem-Peixe',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165578,
    nome: 'Senador Amaral',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165602,
    nome: 'Senador Cortes',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310035,
      nome: 'São João Nepomuceno - Bicas',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165701,
    nome: 'Senador Firmino',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165800,
    nome: 'Senador José Bento',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3165909,
    nome: 'Senador Modestino Gonçalves',
    microrregiao: {
      id: 31010,
      nome: 'Diamantina',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166006,
    nome: 'Senhora de Oliveira',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310038,
      nome: 'Conselheiro Lafaiete',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166105,
    nome: 'Senhora do Porto',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166204,
    nome: 'Senhora dos Remédios',
    microrregiao: {
      id: 31059,
      nome: 'Barbacena',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310037,
      nome: 'Barbacena',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166303,
    nome: 'Sericita',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166402,
    nome: 'Seritinga',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310054,
      nome: 'Caxambu - Baependi',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166501,
    nome: 'Serra Azul de Minas',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166600,
    nome: 'Serra da Saudade',
    microrregiao: {
      id: 31026,
      nome: 'Bom Despacho',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310067,
      nome: 'Dores do Indaiá',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166709,
    nome: 'Serra dos Aimorés',
    microrregiao: {
      id: 31016,
      nome: 'Nanuque',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166808,
    nome: 'Serra do Salitre',
    microrregiao: {
      id: 31019,
      nome: 'Patrocínio',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310064,
      nome: 'Patrocínio',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166907,
    nome: 'Serrania',
    microrregiao: {
      id: 31049,
      nome: 'Alfenas',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310042,
      nome: 'Alfenas',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3166956,
    nome: 'Serranópolis de Minas',
    microrregiao: {
      id: 31004,
      nome: 'Janaúba',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3167004,
    nome: 'Serranos',
    microrregiao: {
      id: 31055,
      nome: 'Andrelândia',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310054,
      nome: 'Caxambu - Baependi',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3167103,
    nome: 'Serro',
    microrregiao: {
      id: 31028,
      nome: 'Conceição do Mato Dentro',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310016,
      nome: 'Diamantina',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3167202,
    nome: 'Sete Lagoas',
    microrregiao: {
      id: 31027,
      nome: 'Sete Lagoas',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310002,
      nome: 'Sete Lagoas',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3167301,
    nome: 'Silveirânia',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3167400,
    nome: 'Silvianópolis',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3167509,
    nome: 'Simão Pereira',
    microrregiao: {
      id: 31065,
      nome: 'Juiz de Fora',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310027,
      nome: 'Juiz de Fora',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3167608,
    nome: 'Simonésia',
    microrregiao: {
      id: 31061,
      nome: 'Manhuaçu',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3167707,
    nome: 'Sobrália',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3167806,
    nome: 'Soledade de Minas',
    microrregiao: {
      id: 31054,
      nome: 'São Lourenço',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3167905,
    nome: 'Tabuleiro',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168002,
    nome: 'Taiobeiras',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168051,
    nome: 'Taparuba',
    microrregiao: {
      id: 31041,
      nome: 'Aimorés',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310028,
      nome: 'Manhuaçu',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168101,
    nome: 'Tapira',
    microrregiao: {
      id: 31023,
      nome: 'Araxá',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310056,
      nome: 'Araxá',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168200,
    nome: 'Tapiraí',
    microrregiao: {
      id: 31042,
      nome: 'Piuí',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310066,
      nome: 'Formiga',
      'regiao-intermediaria': {
        id: 3113,
        nome: 'Divinópolis',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168309,
    nome: 'Taquaraçu de Minas',
    microrregiao: {
      id: 31031,
      nome: 'Itabira',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168408,
    nome: 'Tarumirim',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168507,
    nome: 'Teixeiras',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168606,
    nome: 'Teófilo Otoni',
    microrregiao: {
      id: 31015,
      nome: 'Teófilo Otoni',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310013,
      nome: 'Teófilo Otoni',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168705,
    nome: 'Timóteo',
    microrregiao: {
      id: 31039,
      nome: 'Ipatinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310024,
      nome: 'Ipatinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168804,
    nome: 'Tiradentes',
    microrregiao: {
      id: 31058,
      nome: 'São João Del Rei',
      mesorregiao: {
        id: 3111,
        nome: 'Campo das Vertentes',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310039,
      nome: 'São João del Rei',
      'regiao-intermediaria': {
        id: 3107,
        nome: 'Barbacena',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3168903,
    nome: 'Tiros',
    microrregiao: {
      id: 31020,
      nome: 'Patos de Minas',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169000,
    nome: 'Tocantins',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169059,
    nome: 'Tocos do Moji',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169109,
    nome: 'Toledo',
    microrregiao: {
      id: 31052,
      nome: 'Pouso Alegre',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169208,
    nome: 'Tombos',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310034,
      nome: 'Carangola',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169307,
    nome: 'Três Corações',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310045,
      nome: 'Três Corações',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169356,
    nome: 'Três Marias',
    microrregiao: {
      id: 31024,
      nome: 'Três Marias',
      mesorregiao: {
        id: 3106,
        nome: 'Central Mineira',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310004,
      nome: 'Curvelo',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169406,
    nome: 'Três Pontas',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310046,
      nome: 'Três Pontas - Boa Esperança',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169505,
    nome: 'Tumiritinga',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169604,
    nome: 'Tupaciguara',
    microrregiao: {
      id: 31018,
      nome: 'Uberlândia',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169703,
    nome: 'Turmalina',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310014,
      nome: 'Capelinha',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169802,
    nome: 'Turvolândia',
    microrregiao: {
      id: 31053,
      nome: 'Santa Rita do Sapucaí',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310050,
      nome: 'Pouso Alegre',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3169901,
    nome: 'Ubá',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170008,
    nome: 'Ubaí',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310011,
      nome: 'São Francisco',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170057,
    nome: 'Ubaporanga',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170107,
    nome: 'Uberaba',
    microrregiao: {
      id: 31022,
      nome: 'Uberaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310055,
      nome: 'Uberaba',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170206,
    nome: 'Uberlândia',
    microrregiao: {
      id: 31018,
      nome: 'Uberlândia',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310059,
      nome: 'Uberlândia',
      'regiao-intermediaria': {
        id: 3111,
        nome: 'Uberlândia',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170305,
    nome: 'Umburatiba',
    microrregiao: {
      id: 31016,
      nome: 'Nanuque',
      mesorregiao: {
        id: 3104,
        nome: 'Vale do Mucuri',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310019,
      nome: 'Águas Formosas',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170404,
    nome: 'Unaí',
    microrregiao: {
      id: 31001,
      nome: 'Unaí',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170438,
    nome: 'União de Minas',
    microrregiao: {
      id: 31021,
      nome: 'Frutal',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310058,
      nome: 'Iturama',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170479,
    nome: 'Uruana de Minas',
    microrregiao: {
      id: 31001,
      nome: 'Unaí',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170503,
    nome: 'Urucânia',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310030,
      nome: 'Ponte Nova',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170529,
    nome: 'Urucuia',
    microrregiao: {
      id: 31003,
      nome: 'Januária',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310063,
      nome: 'Unaí',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170578,
    nome: 'Vargem Alegre',
    microrregiao: {
      id: 31040,
      nome: 'Caratinga',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170602,
    nome: 'Vargem Bonita',
    microrregiao: {
      id: 31042,
      nome: 'Piuí',
      mesorregiao: {
        id: 3109,
        nome: 'Oeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310049,
      nome: 'Piumhi',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170651,
    nome: 'Vargem Grande do Rio Pardo',
    microrregiao: {
      id: 31005,
      nome: 'Salinas',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310008,
      nome: 'Salinas',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170701,
    nome: 'Varginha',
    microrregiao: {
      id: 31050,
      nome: 'Varginha',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310040,
      nome: 'Varginha',
      'regiao-intermediaria': {
        id: 3108,
        nome: 'Varginha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170750,
    nome: 'Varjão de Minas',
    microrregiao: {
      id: 31002,
      nome: 'Paracatu',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170800,
    nome: 'Várzea da Palma',
    microrregiao: {
      id: 31006,
      nome: 'Pirapora',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310010,
      nome: 'Pirapora',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3170909,
    nome: 'Varzelândia',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310006,
      nome: 'Montes Claros',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171006,
    nome: 'Vazante',
    microrregiao: {
      id: 31002,
      nome: 'Paracatu',
      mesorregiao: {
        id: 3101,
        nome: 'Noroeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310062,
      nome: 'Patos de Minas',
      'regiao-intermediaria': {
        id: 3112,
        nome: 'Patos de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171030,
    nome: 'Verdelândia',
    microrregiao: {
      id: 31007,
      nome: 'Montes Claros',
      mesorregiao: {
        id: 3102,
        nome: 'Norte de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310007,
      nome: 'Janaúba',
      'regiao-intermediaria': {
        id: 3102,
        nome: 'Montes Claros',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171071,
    nome: 'Veredinha',
    microrregiao: {
      id: 31011,
      nome: 'Capelinha',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310014,
      nome: 'Capelinha',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171105,
    nome: 'Veríssimo',
    microrregiao: {
      id: 31022,
      nome: 'Uberaba',
      mesorregiao: {
        id: 3105,
        nome: 'Triângulo Mineiro/Alto Paranaíba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310055,
      nome: 'Uberaba',
      'regiao-intermediaria': {
        id: 3110,
        nome: 'Uberaba',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171154,
    nome: 'Vermelho Novo',
    microrregiao: {
      id: 31060,
      nome: 'Ponte Nova',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310025,
      nome: 'Caratinga',
      'regiao-intermediaria': {
        id: 3105,
        nome: 'Ipatinga',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171204,
    nome: 'Vespasiano',
    microrregiao: {
      id: 31030,
      nome: 'Belo Horizonte',
      mesorregiao: {
        id: 3107,
        nome: 'Metropolitana de Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310001,
      nome: 'Belo Horizonte',
      'regiao-intermediaria': {
        id: 3101,
        nome: 'Belo Horizonte',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171303,
    nome: 'Viçosa',
    microrregiao: {
      id: 31062,
      nome: 'Viçosa',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310033,
      nome: 'Viçosa',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171402,
    nome: 'Vieiras',
    microrregiao: {
      id: 31063,
      nome: 'Muriaé',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310031,
      nome: 'Muriaé',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171501,
    nome: 'Mathias Lobato',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171600,
    nome: 'Virgem da Lapa',
    microrregiao: {
      id: 31012,
      nome: 'Araçuaí',
      mesorregiao: {
        id: 3103,
        nome: 'Jequitinhonha',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310017,
      nome: 'Araçuaí',
      'regiao-intermediaria': {
        id: 3103,
        nome: 'Teófilo Otoni',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171709,
    nome: 'Virgínia',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310053,
      nome: 'São Lourenço',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171808,
    nome: 'Virginópolis',
    microrregiao: {
      id: 31035,
      nome: 'Guanhães',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310021,
      nome: 'Guanhães',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3171907,
    nome: 'Virgolândia',
    microrregiao: {
      id: 31037,
      nome: 'Governador Valadares',
      mesorregiao: {
        id: 3108,
        nome: 'Vale do Rio Doce',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310020,
      nome: 'Governador Valadares',
      'regiao-intermediaria': {
        id: 3104,
        nome: 'Governador Valadares',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3172004,
    nome: 'Visconde do Rio Branco',
    microrregiao: {
      id: 31064,
      nome: 'Ubá',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310029,
      nome: 'Ubá',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3172103,
    nome: 'Volta Grande',
    microrregiao: {
      id: 31066,
      nome: 'Cataguases',
      mesorregiao: {
        id: 3112,
        nome: 'Zona da Mata',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310036,
      nome: 'Além Paraíba',
      'regiao-intermediaria': {
        id: 3106,
        nome: 'Juíz de Fora',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3172202,
    nome: 'Wenceslau Braz',
    microrregiao: {
      id: 31056,
      nome: 'Itajubá',
      mesorregiao: {
        id: 3110,
        nome: 'Sul/Sudoeste de Minas',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 310052,
      nome: 'Itajubá',
      'regiao-intermediaria': {
        id: 3109,
        nome: 'Pouso Alegre',
        UF: {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200102,
    nome: 'Afonso Cláudio',
    microrregiao: {
      id: 32007,
      nome: 'Afonso Cláudio',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200136,
    nome: 'Águia Branca',
    microrregiao: {
      id: 32002,
      nome: 'Nova Venécia',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200169,
    nome: 'Água Doce do Norte',
    microrregiao: {
      id: 32001,
      nome: 'Barra de São Francisco',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320006,
      nome: 'Nova Venécia',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200201,
    nome: 'Alegre',
    microrregiao: {
      id: 32011,
      nome: 'Alegre',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200300,
    nome: 'Alfredo Chaves',
    microrregiao: {
      id: 32010,
      nome: 'Guarapari',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320001,
      nome: 'Vitória',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200359,
    nome: 'Alto Rio Novo',
    microrregiao: {
      id: 32003,
      nome: 'Colatina',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200409,
    nome: 'Anchieta',
    microrregiao: {
      id: 32010,
      nome: 'Guarapari',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320001,
      nome: 'Vitória',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200508,
    nome: 'Apiacá',
    microrregiao: {
      id: 32012,
      nome: 'Cachoeiro de Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200607,
    nome: 'Aracruz',
    microrregiao: {
      id: 32006,
      nome: 'Linhares',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320004,
      nome: 'Linhares',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200706,
    nome: 'Atílio Vivacqua',
    microrregiao: {
      id: 32012,
      nome: 'Cachoeiro de Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200805,
    nome: 'Baixo Guandu',
    microrregiao: {
      id: 32003,
      nome: 'Colatina',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3200904,
    nome: 'Barra de São Francisco',
    microrregiao: {
      id: 32001,
      nome: 'Barra de São Francisco',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320006,
      nome: 'Nova Venécia',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201001,
    nome: 'Boa Esperança',
    microrregiao: {
      id: 32002,
      nome: 'Nova Venécia',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320003,
      nome: 'São Mateus',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201100,
    nome: 'Bom Jesus do Norte',
    microrregiao: {
      id: 32012,
      nome: 'Cachoeiro de Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201159,
    nome: 'Brejetuba',
    microrregiao: {
      id: 32007,
      nome: 'Afonso Cláudio',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201209,
    nome: 'Cachoeiro de Itapemirim',
    microrregiao: {
      id: 32012,
      nome: 'Cachoeiro de Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201308,
    nome: 'Cariacica',
    microrregiao: {
      id: 32009,
      nome: 'Vitória',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320001,
      nome: 'Vitória',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201407,
    nome: 'Castelo',
    microrregiao: {
      id: 32012,
      nome: 'Cachoeiro de Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201506,
    nome: 'Colatina',
    microrregiao: {
      id: 32003,
      nome: 'Colatina',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201605,
    nome: 'Conceição da Barra',
    microrregiao: {
      id: 32005,
      nome: 'São Mateus',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320003,
      nome: 'São Mateus',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201704,
    nome: 'Conceição do Castelo',
    microrregiao: {
      id: 32007,
      nome: 'Afonso Cláudio',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201803,
    nome: 'Divino de São Lourenço',
    microrregiao: {
      id: 32011,
      nome: 'Alegre',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3201902,
    nome: 'Domingos Martins',
    microrregiao: {
      id: 32007,
      nome: 'Afonso Cláudio',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202009,
    nome: 'Dores do Rio Preto',
    microrregiao: {
      id: 32011,
      nome: 'Alegre',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202108,
    nome: 'Ecoporanga',
    microrregiao: {
      id: 32001,
      nome: 'Barra de São Francisco',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320006,
      nome: 'Nova Venécia',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202207,
    nome: 'Fundão',
    microrregiao: {
      id: 32006,
      nome: 'Linhares',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320001,
      nome: 'Vitória',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202256,
    nome: 'Governador Lindenberg',
    microrregiao: {
      id: 32003,
      nome: 'Colatina',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202306,
    nome: 'Guaçuí',
    microrregiao: {
      id: 32011,
      nome: 'Alegre',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202405,
    nome: 'Guarapari',
    microrregiao: {
      id: 32010,
      nome: 'Guarapari',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320001,
      nome: 'Vitória',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202454,
    nome: 'Ibatiba',
    microrregiao: {
      id: 32011,
      nome: 'Alegre',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202504,
    nome: 'Ibiraçu',
    microrregiao: {
      id: 32006,
      nome: 'Linhares',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320004,
      nome: 'Linhares',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202553,
    nome: 'Ibitirama',
    microrregiao: {
      id: 32011,
      nome: 'Alegre',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202603,
    nome: 'Iconha',
    microrregiao: {
      id: 32010,
      nome: 'Guarapari',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202652,
    nome: 'Irupi',
    microrregiao: {
      id: 32011,
      nome: 'Alegre',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202702,
    nome: 'Itaguaçu',
    microrregiao: {
      id: 32008,
      nome: 'Santa Teresa',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202801,
    nome: 'Itapemirim',
    microrregiao: {
      id: 32013,
      nome: 'Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3202900,
    nome: 'Itarana',
    microrregiao: {
      id: 32008,
      nome: 'Santa Teresa',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203007,
    nome: 'Iúna',
    microrregiao: {
      id: 32011,
      nome: 'Alegre',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203056,
    nome: 'Jaguaré',
    microrregiao: {
      id: 32005,
      nome: 'São Mateus',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320003,
      nome: 'São Mateus',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203106,
    nome: 'Jerônimo Monteiro',
    microrregiao: {
      id: 32012,
      nome: 'Cachoeiro de Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203130,
    nome: 'João Neiva',
    microrregiao: {
      id: 32006,
      nome: 'Linhares',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320004,
      nome: 'Linhares',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203163,
    nome: 'Laranja da Terra',
    microrregiao: {
      id: 32007,
      nome: 'Afonso Cláudio',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203205,
    nome: 'Linhares',
    microrregiao: {
      id: 32006,
      nome: 'Linhares',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320004,
      nome: 'Linhares',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203304,
    nome: 'Mantenópolis',
    microrregiao: {
      id: 32001,
      nome: 'Barra de São Francisco',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203320,
    nome: 'Marataízes',
    microrregiao: {
      id: 32013,
      nome: 'Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203346,
    nome: 'Marechal Floriano',
    microrregiao: {
      id: 32007,
      nome: 'Afonso Cláudio',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203353,
    nome: 'Marilândia',
    microrregiao: {
      id: 32003,
      nome: 'Colatina',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203403,
    nome: 'Mimoso do Sul',
    microrregiao: {
      id: 32012,
      nome: 'Cachoeiro de Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203502,
    nome: 'Montanha',
    microrregiao: {
      id: 32004,
      nome: 'Montanha',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320003,
      nome: 'São Mateus',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203601,
    nome: 'Mucurici',
    microrregiao: {
      id: 32004,
      nome: 'Montanha',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320003,
      nome: 'São Mateus',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203700,
    nome: 'Muniz Freire',
    microrregiao: {
      id: 32011,
      nome: 'Alegre',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203809,
    nome: 'Muqui',
    microrregiao: {
      id: 32012,
      nome: 'Cachoeiro de Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3203908,
    nome: 'Nova Venécia',
    microrregiao: {
      id: 32002,
      nome: 'Nova Venécia',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320006,
      nome: 'Nova Venécia',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204005,
    nome: 'Pancas',
    microrregiao: {
      id: 32003,
      nome: 'Colatina',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204054,
    nome: 'Pedro Canário',
    microrregiao: {
      id: 32005,
      nome: 'São Mateus',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320003,
      nome: 'São Mateus',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204104,
    nome: 'Pinheiros',
    microrregiao: {
      id: 32004,
      nome: 'Montanha',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320003,
      nome: 'São Mateus',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204203,
    nome: 'Piúma',
    microrregiao: {
      id: 32010,
      nome: 'Guarapari',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320001,
      nome: 'Vitória',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204252,
    nome: 'Ponto Belo',
    microrregiao: {
      id: 32004,
      nome: 'Montanha',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320003,
      nome: 'São Mateus',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204302,
    nome: 'Presidente Kennedy',
    microrregiao: {
      id: 32013,
      nome: 'Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204351,
    nome: 'Rio Bananal',
    microrregiao: {
      id: 32006,
      nome: 'Linhares',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320004,
      nome: 'Linhares',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204401,
    nome: 'Rio Novo do Sul',
    microrregiao: {
      id: 32010,
      nome: 'Guarapari',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204500,
    nome: 'Santa Leopoldina',
    microrregiao: {
      id: 32008,
      nome: 'Santa Teresa',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204559,
    nome: 'Santa Maria de Jetibá',
    microrregiao: {
      id: 32008,
      nome: 'Santa Teresa',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204609,
    nome: 'Santa Teresa',
    microrregiao: {
      id: 32008,
      nome: 'Santa Teresa',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204658,
    nome: 'São Domingos do Norte',
    microrregiao: {
      id: 32003,
      nome: 'Colatina',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204708,
    nome: 'São Gabriel da Palha',
    microrregiao: {
      id: 32002,
      nome: 'Nova Venécia',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204807,
    nome: 'São José do Calçado',
    microrregiao: {
      id: 32012,
      nome: 'Cachoeiro de Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320008,
      nome: 'Alegre',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204906,
    nome: 'São Mateus',
    microrregiao: {
      id: 32005,
      nome: 'São Mateus',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320003,
      nome: 'São Mateus',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3204955,
    nome: 'São Roque do Canaã',
    microrregiao: {
      id: 32008,
      nome: 'Santa Teresa',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3205002,
    nome: 'Serra',
    microrregiao: {
      id: 32009,
      nome: 'Vitória',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320001,
      nome: 'Vitória',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3205010,
    nome: 'Sooretama',
    microrregiao: {
      id: 32006,
      nome: 'Linhares',
      mesorregiao: {
        id: 3202,
        nome: 'Litoral Norte Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320004,
      nome: 'Linhares',
      'regiao-intermediaria': {
        id: 3202,
        nome: 'São Mateus',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3205036,
    nome: 'Vargem Alta',
    microrregiao: {
      id: 32012,
      nome: 'Cachoeiro de Itapemirim',
      mesorregiao: {
        id: 3204,
        nome: 'Sul Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320007,
      nome: 'Cachoeiro de Itapemirim',
      'regiao-intermediaria': {
        id: 3204,
        nome: 'Cachoeiro do Itapemirim',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3205069,
    nome: 'Venda Nova do Imigrante',
    microrregiao: {
      id: 32007,
      nome: 'Afonso Cláudio',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320002,
      nome: 'Afonso Cláudio - Venda Nova do Imigrante - Santa Maria de Jetibá',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3205101,
    nome: 'Viana',
    microrregiao: {
      id: 32009,
      nome: 'Vitória',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320001,
      nome: 'Vitória',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3205150,
    nome: 'Vila Pavão',
    microrregiao: {
      id: 32002,
      nome: 'Nova Venécia',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320006,
      nome: 'Nova Venécia',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3205176,
    nome: 'Vila Valério',
    microrregiao: {
      id: 32002,
      nome: 'Nova Venécia',
      mesorregiao: {
        id: 3201,
        nome: 'Noroeste Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320005,
      nome: 'Colatina',
      'regiao-intermediaria': {
        id: 3203,
        nome: 'Colatina',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3205200,
    nome: 'Vila Velha',
    microrregiao: {
      id: 32009,
      nome: 'Vitória',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320001,
      nome: 'Vitória',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3205309,
    nome: 'Vitória',
    microrregiao: {
      id: 32009,
      nome: 'Vitória',
      mesorregiao: {
        id: 3203,
        nome: 'Central Espírito-santense',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 320001,
      nome: 'Vitória',
      'regiao-intermediaria': {
        id: 3201,
        nome: 'Vitória',
        UF: {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300100,
    nome: 'Angra dos Reis',
    microrregiao: {
      id: 33013,
      nome: 'Baía da Ilha Grande',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330002,
      nome: 'Angra dos Reis',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300159,
    nome: 'Aperibé',
    microrregiao: {
      id: 33002,
      nome: 'Santo Antônio de Pádua',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330012,
      nome: 'Santo Antônio de Pádua',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300209,
    nome: 'Araruama',
    microrregiao: {
      id: 33010,
      nome: 'Lagos',
      mesorregiao: {
        id: 3304,
        nome: 'Baixadas',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330013,
      nome: 'Cabo Frio',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300225,
    nome: 'Areal',
    microrregiao: {
      id: 33005,
      nome: 'Três Rios',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330007,
      nome: 'Petrópolis',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300233,
    nome: 'Armação dos Búzios',
    microrregiao: {
      id: 33010,
      nome: 'Lagos',
      mesorregiao: {
        id: 3304,
        nome: 'Baixadas',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330013,
      nome: 'Cabo Frio',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300258,
    nome: 'Arraial do Cabo',
    microrregiao: {
      id: 33010,
      nome: 'Lagos',
      mesorregiao: {
        id: 3304,
        nome: 'Baixadas',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330013,
      nome: 'Cabo Frio',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300308,
    nome: 'Barra do Piraí',
    microrregiao: {
      id: 33012,
      nome: 'Barra do Piraí',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330004,
      nome: 'Volta Redonda - Barra Mansa',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300407,
    nome: 'Barra Mansa',
    microrregiao: {
      id: 33011,
      nome: 'Vale do Paraíba Fluminense',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330004,
      nome: 'Volta Redonda - Barra Mansa',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300456,
    nome: 'Belford Roxo',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300506,
    nome: 'Bom Jardim',
    microrregiao: {
      id: 33007,
      nome: 'Nova Friburgo',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300605,
    nome: 'Bom Jesus do Itabapoana',
    microrregiao: {
      id: 33001,
      nome: 'Itaperuna',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330011,
      nome: 'Itaperuna',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300704,
    nome: 'Cabo Frio',
    microrregiao: {
      id: 33010,
      nome: 'Lagos',
      mesorregiao: {
        id: 3304,
        nome: 'Baixadas',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330013,
      nome: 'Cabo Frio',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300803,
    nome: 'Cachoeiras de Macacu',
    microrregiao: {
      id: 33016,
      nome: 'Macacu-Caceribu',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330003,
      nome: 'Rio Bonito',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300902,
    nome: 'Cambuci',
    microrregiao: {
      id: 33002,
      nome: 'Santo Antônio de Pádua',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330012,
      nome: 'Santo Antônio de Pádua',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300936,
    nome: 'Carapebus',
    microrregiao: {
      id: 33004,
      nome: 'Macaé',
      mesorregiao: {
        id: 3302,
        nome: 'Norte Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330014,
      nome: 'Macaé - Rio das Ostras',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3300951,
    nome: 'Comendador Levy Gasparian',
    microrregiao: {
      id: 33005,
      nome: 'Três Rios',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330009,
      nome: 'Três Rios - Paraíba do Sul',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301009,
    nome: 'Campos dos Goytacazes',
    microrregiao: {
      id: 33003,
      nome: 'Campos dos Goytacazes',
      mesorregiao: {
        id: 3302,
        nome: 'Norte Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330010,
      nome: 'Campos dos Goytacazes',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301108,
    nome: 'Cantagalo',
    microrregiao: {
      id: 33006,
      nome: 'Cantagalo-Cordeiro',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301157,
    nome: 'Cardoso Moreira',
    microrregiao: {
      id: 33003,
      nome: 'Campos dos Goytacazes',
      mesorregiao: {
        id: 3302,
        nome: 'Norte Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330010,
      nome: 'Campos dos Goytacazes',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301207,
    nome: 'Carmo',
    microrregiao: {
      id: 33006,
      nome: 'Cantagalo-Cordeiro',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301306,
    nome: 'Casimiro de Abreu',
    microrregiao: {
      id: 33009,
      nome: 'Bacia de São João',
      mesorregiao: {
        id: 3304,
        nome: 'Baixadas',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330014,
      nome: 'Macaé - Rio das Ostras',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301405,
    nome: 'Conceição de Macabu',
    microrregiao: {
      id: 33004,
      nome: 'Macaé',
      mesorregiao: {
        id: 3302,
        nome: 'Norte Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330014,
      nome: 'Macaé - Rio das Ostras',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301504,
    nome: 'Cordeiro',
    microrregiao: {
      id: 33006,
      nome: 'Cantagalo-Cordeiro',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301603,
    nome: 'Duas Barras',
    microrregiao: {
      id: 33007,
      nome: 'Nova Friburgo',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301702,
    nome: 'Duque de Caxias',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301801,
    nome: 'Engenheiro Paulo de Frontin',
    microrregiao: {
      id: 33014,
      nome: 'Vassouras',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330004,
      nome: 'Volta Redonda - Barra Mansa',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301850,
    nome: 'Guapimirim',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301876,
    nome: 'Iguaba Grande',
    microrregiao: {
      id: 33010,
      nome: 'Lagos',
      mesorregiao: {
        id: 3304,
        nome: 'Baixadas',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330013,
      nome: 'Cabo Frio',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3301900,
    nome: 'Itaboraí',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302007,
    nome: 'Itaguaí',
    microrregiao: {
      id: 33017,
      nome: 'Itaguaí',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302056,
    nome: 'Italva',
    microrregiao: {
      id: 33001,
      nome: 'Itaperuna',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330010,
      nome: 'Campos dos Goytacazes',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302106,
    nome: 'Itaocara',
    microrregiao: {
      id: 33002,
      nome: 'Santo Antônio de Pádua',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330012,
      nome: 'Santo Antônio de Pádua',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302205,
    nome: 'Itaperuna',
    microrregiao: {
      id: 33001,
      nome: 'Itaperuna',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330011,
      nome: 'Itaperuna',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302254,
    nome: 'Itatiaia',
    microrregiao: {
      id: 33011,
      nome: 'Vale do Paraíba Fluminense',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330005,
      nome: 'Resende',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302270,
    nome: 'Japeri',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302304,
    nome: 'Laje do Muriaé',
    microrregiao: {
      id: 33001,
      nome: 'Itaperuna',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330011,
      nome: 'Itaperuna',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302403,
    nome: 'Macaé',
    microrregiao: {
      id: 33004,
      nome: 'Macaé',
      mesorregiao: {
        id: 3302,
        nome: 'Norte Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330014,
      nome: 'Macaé - Rio das Ostras',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302452,
    nome: 'Macuco',
    microrregiao: {
      id: 33006,
      nome: 'Cantagalo-Cordeiro',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302502,
    nome: 'Magé',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302601,
    nome: 'Mangaratiba',
    microrregiao: {
      id: 33017,
      nome: 'Itaguaí',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302700,
    nome: 'Maricá',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302809,
    nome: 'Mendes',
    microrregiao: {
      id: 33014,
      nome: 'Vassouras',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330004,
      nome: 'Volta Redonda - Barra Mansa',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302858,
    nome: 'Mesquita',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3302908,
    nome: 'Miguel Pereira',
    microrregiao: {
      id: 33014,
      nome: 'Vassouras',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330006,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303005,
    nome: 'Miracema',
    microrregiao: {
      id: 33002,
      nome: 'Santo Antônio de Pádua',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330012,
      nome: 'Santo Antônio de Pádua',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303104,
    nome: 'Natividade',
    microrregiao: {
      id: 33001,
      nome: 'Itaperuna',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330011,
      nome: 'Itaperuna',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303203,
    nome: 'Nilópolis',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303302,
    nome: 'Niterói',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303401,
    nome: 'Nova Friburgo',
    microrregiao: {
      id: 33007,
      nome: 'Nova Friburgo',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303500,
    nome: 'Nova Iguaçu',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303609,
    nome: 'Paracambi',
    microrregiao: {
      id: 33014,
      nome: 'Vassouras',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303708,
    nome: 'Paraíba do Sul',
    microrregiao: {
      id: 33005,
      nome: 'Três Rios',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330009,
      nome: 'Três Rios - Paraíba do Sul',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303807,
    nome: 'Paraty',
    microrregiao: {
      id: 33013,
      nome: 'Baía da Ilha Grande',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330002,
      nome: 'Angra dos Reis',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303856,
    nome: 'Paty do Alferes',
    microrregiao: {
      id: 33014,
      nome: 'Vassouras',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330006,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303906,
    nome: 'Petrópolis',
    microrregiao: {
      id: 33015,
      nome: 'Serrana',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330007,
      nome: 'Petrópolis',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3303955,
    nome: 'Pinheiral',
    microrregiao: {
      id: 33011,
      nome: 'Vale do Paraíba Fluminense',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330004,
      nome: 'Volta Redonda - Barra Mansa',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304003,
    nome: 'Piraí',
    microrregiao: {
      id: 33011,
      nome: 'Vale do Paraíba Fluminense',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330004,
      nome: 'Volta Redonda - Barra Mansa',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304102,
    nome: 'Porciúncula',
    microrregiao: {
      id: 33001,
      nome: 'Itaperuna',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330011,
      nome: 'Itaperuna',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304110,
    nome: 'Porto Real',
    microrregiao: {
      id: 33011,
      nome: 'Vale do Paraíba Fluminense',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330005,
      nome: 'Resende',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304128,
    nome: 'Quatis',
    microrregiao: {
      id: 33011,
      nome: 'Vale do Paraíba Fluminense',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330005,
      nome: 'Resende',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304144,
    nome: 'Queimados',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304151,
    nome: 'Quissamã',
    microrregiao: {
      id: 33004,
      nome: 'Macaé',
      mesorregiao: {
        id: 3302,
        nome: 'Norte Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330014,
      nome: 'Macaé - Rio das Ostras',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304201,
    nome: 'Resende',
    microrregiao: {
      id: 33011,
      nome: 'Vale do Paraíba Fluminense',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330005,
      nome: 'Resende',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304300,
    nome: 'Rio Bonito',
    microrregiao: {
      id: 33016,
      nome: 'Macacu-Caceribu',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330003,
      nome: 'Rio Bonito',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304409,
    nome: 'Rio Claro',
    microrregiao: {
      id: 33011,
      nome: 'Vale do Paraíba Fluminense',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330004,
      nome: 'Volta Redonda - Barra Mansa',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304508,
    nome: 'Rio das Flores',
    microrregiao: {
      id: 33012,
      nome: 'Barra do Piraí',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330006,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304524,
    nome: 'Rio das Ostras',
    microrregiao: {
      id: 33009,
      nome: 'Bacia de São João',
      mesorregiao: {
        id: 3304,
        nome: 'Baixadas',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330014,
      nome: 'Macaé - Rio das Ostras',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304557,
    nome: 'Rio de Janeiro',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304607,
    nome: 'Santa Maria Madalena',
    microrregiao: {
      id: 33008,
      nome: 'Santa Maria Madalena',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304706,
    nome: 'Santo Antônio de Pádua',
    microrregiao: {
      id: 33002,
      nome: 'Santo Antônio de Pádua',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330012,
      nome: 'Santo Antônio de Pádua',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304755,
    nome: 'São Francisco de Itabapoana',
    microrregiao: {
      id: 33003,
      nome: 'Campos dos Goytacazes',
      mesorregiao: {
        id: 3302,
        nome: 'Norte Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330010,
      nome: 'Campos dos Goytacazes',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304805,
    nome: 'São Fidélis',
    microrregiao: {
      id: 33003,
      nome: 'Campos dos Goytacazes',
      mesorregiao: {
        id: 3302,
        nome: 'Norte Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330010,
      nome: 'Campos dos Goytacazes',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3304904,
    nome: 'São Gonçalo',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305000,
    nome: 'São João da Barra',
    microrregiao: {
      id: 33003,
      nome: 'Campos dos Goytacazes',
      mesorregiao: {
        id: 3302,
        nome: 'Norte Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330010,
      nome: 'Campos dos Goytacazes',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305109,
    nome: 'São João de Meriti',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305133,
    nome: 'São José de Ubá',
    microrregiao: {
      id: 33002,
      nome: 'Santo Antônio de Pádua',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330011,
      nome: 'Itaperuna',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305158,
    nome: 'São José do Vale do Rio Preto',
    microrregiao: {
      id: 33015,
      nome: 'Serrana',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330007,
      nome: 'Petrópolis',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305208,
    nome: 'São Pedro da Aldeia',
    microrregiao: {
      id: 33010,
      nome: 'Lagos',
      mesorregiao: {
        id: 3304,
        nome: 'Baixadas',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330013,
      nome: 'Cabo Frio',
      'regiao-intermediaria': {
        id: 3305,
        nome: 'Macaé - Rio das Ostras - Cabo Frio',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305307,
    nome: 'São Sebastião do Alto',
    microrregiao: {
      id: 33008,
      nome: 'Santa Maria Madalena',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305406,
    nome: 'Sapucaia',
    microrregiao: {
      id: 33005,
      nome: 'Três Rios',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330009,
      nome: 'Três Rios - Paraíba do Sul',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305505,
    nome: 'Saquarema',
    microrregiao: {
      id: 33010,
      nome: 'Lagos',
      mesorregiao: {
        id: 3304,
        nome: 'Baixadas',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305554,
    nome: 'Seropédica',
    microrregiao: {
      id: 33017,
      nome: 'Itaguaí',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305604,
    nome: 'Silva Jardim',
    microrregiao: {
      id: 33009,
      nome: 'Bacia de São João',
      mesorregiao: {
        id: 3304,
        nome: 'Baixadas',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330003,
      nome: 'Rio Bonito',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305703,
    nome: 'Sumidouro',
    microrregiao: {
      id: 33007,
      nome: 'Nova Friburgo',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305752,
    nome: 'Tanguá',
    microrregiao: {
      id: 33018,
      nome: 'Rio de Janeiro',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330001,
      nome: 'Rio de Janeiro',
      'regiao-intermediaria': {
        id: 3301,
        nome: 'Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305802,
    nome: 'Teresópolis',
    microrregiao: {
      id: 33015,
      nome: 'Serrana',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330007,
      nome: 'Petrópolis',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3305901,
    nome: 'Trajano de Moraes',
    microrregiao: {
      id: 33008,
      nome: 'Santa Maria Madalena',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330008,
      nome: 'Nova Friburgo',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3306008,
    nome: 'Três Rios',
    microrregiao: {
      id: 33005,
      nome: 'Três Rios',
      mesorregiao: {
        id: 3303,
        nome: 'Centro Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330009,
      nome: 'Três Rios - Paraíba do Sul',
      'regiao-intermediaria': {
        id: 3303,
        nome: 'Petrópolis',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3306107,
    nome: 'Valença',
    microrregiao: {
      id: 33012,
      nome: 'Barra do Piraí',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330006,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3306156,
    nome: 'Varre-Sai',
    microrregiao: {
      id: 33001,
      nome: 'Itaperuna',
      mesorregiao: {
        id: 3301,
        nome: 'Noroeste Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330011,
      nome: 'Itaperuna',
      'regiao-intermediaria': {
        id: 3304,
        nome: 'Campos dos Goytacazes',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3306206,
    nome: 'Vassouras',
    microrregiao: {
      id: 33014,
      nome: 'Vassouras',
      mesorregiao: {
        id: 3306,
        nome: 'Metropolitana do Rio de Janeiro',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330006,
      nome: 'Valença',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3306305,
    nome: 'Volta Redonda',
    microrregiao: {
      id: 33011,
      nome: 'Vale do Paraíba Fluminense',
      mesorregiao: {
        id: 3305,
        nome: 'Sul Fluminense',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 330004,
      nome: 'Volta Redonda - Barra Mansa',
      'regiao-intermediaria': {
        id: 3302,
        nome: 'Volta Redonda - Barra Mansa',
        UF: {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500105,
    nome: 'Adamantina',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350019,
      nome: 'Adamantina - Lucélia',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500204,
    nome: 'Adolfo',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500303,
    nome: 'Aguaí',
    microrregiao: {
      id: 35029,
      nome: 'Pirassununga',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350044,
      nome: 'São João da Boa Vista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500402,
    nome: 'Águas da Prata',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350044,
      nome: 'São João da Boa Vista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500501,
    nome: 'Águas de Lindóia',
    microrregiao: {
      id: 35033,
      nome: 'Amparo',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350048,
      nome: 'Amparo',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500550,
    nome: 'Águas de Santa Bárbara',
    microrregiao: {
      id: 35022,
      nome: 'Avaré',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500600,
    nome: 'Águas de São Pedro',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500709,
    nome: 'Agudos',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500758,
    nome: 'Alambari',
    microrregiao: {
      id: 35042,
      nome: 'Itapetininga',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350006,
      nome: 'Itapetininga',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500808,
    nome: 'Alfredo Marcondes',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3500907,
    nome: 'Altair',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501004,
    nome: 'Altinópolis',
    microrregiao: {
      id: 35015,
      nome: 'Batatais',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501103,
    nome: 'Alto Alegre',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501152,
    nome: 'Alumínio',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501202,
    nome: 'Álvares Florence',
    microrregiao: {
      id: 35003,
      nome: 'Votuporanga',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501301,
    nome: 'Álvares Machado',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501400,
    nome: 'Álvaro de Carvalho',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501509,
    nome: 'Alvinlândia',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501608,
    nome: 'Americana',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501707,
    nome: 'Américo Brasiliense',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501806,
    nome: 'Américo de Campos',
    microrregiao: {
      id: 35003,
      nome: 'Votuporanga',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3501905,
    nome: 'Amparo',
    microrregiao: {
      id: 35033,
      nome: 'Amparo',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350048,
      nome: 'Amparo',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502002,
    nome: 'Analândia',
    microrregiao: {
      id: 35025,
      nome: 'São Carlos',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350046,
      nome: 'Rio Claro',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502101,
    nome: 'Andradina',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502200,
    nome: 'Angatuba',
    microrregiao: {
      id: 35042,
      nome: 'Itapetininga',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350006,
      nome: 'Itapetininga',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502309,
    nome: 'Anhembi',
    microrregiao: {
      id: 35023,
      nome: 'Botucatu',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350011,
      nome: 'Botucatu',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502408,
    nome: 'Anhumas',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502507,
    nome: 'Aparecida',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350052,
      nome: 'Guaratinguetá',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502606,
    nome: "Aparecida d'Oeste",
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502705,
    nome: 'Apiaí',
    microrregiao: {
      id: 35044,
      nome: 'Capão Bonito',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502754,
    nome: 'Araçariguama',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502804,
    nome: 'Araçatuba',
    microrregiao: {
      id: 35017,
      nome: 'Araçatuba',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3502903,
    nome: 'Araçoiaba da Serra',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503000,
    nome: 'Aramina',
    microrregiao: {
      id: 35011,
      nome: 'Ituverava',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350035,
      nome: 'Ituverava',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503109,
    nome: 'Arandu',
    microrregiao: {
      id: 35022,
      nome: 'Avaré',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503158,
    nome: 'Arapeí',
    microrregiao: {
      id: 35052,
      nome: 'Bananal',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350053,
      nome: 'Cruzeiro',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503208,
    nome: 'Araraquara',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503307,
    nome: 'Araras',
    microrregiao: {
      id: 35027,
      nome: 'Limeira',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350045,
      nome: 'Araras',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503356,
    nome: 'Arco-Íris',
    microrregiao: {
      id: 35037,
      nome: 'Tupã',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350016,
      nome: 'Tupã',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503406,
    nome: 'Arealva',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503505,
    nome: 'Areias',
    microrregiao: {
      id: 35052,
      nome: 'Bananal',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350053,
      nome: 'Cruzeiro',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503604,
    nome: 'Areiópolis',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350011,
      nome: 'Botucatu',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503703,
    nome: 'Ariranha',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503802,
    nome: 'Artur Nogueira',
    microrregiao: {
      id: 35031,
      nome: 'Mogi Mirim',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503901,
    nome: 'Arujá',
    microrregiao: {
      id: 35059,
      nome: 'Guarulhos',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3503950,
    nome: 'Aspásia',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3504008,
    nome: 'Assis',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3504107,
    nome: 'Atibaia',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3504206,
    nome: 'Auriflama',
    microrregiao: {
      id: 35006,
      nome: 'Auriflama',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3504305,
    nome: 'Avaí',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3504404,
    nome: 'Avanhandava',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3504503,
    nome: 'Avaré',
    microrregiao: {
      id: 35022,
      nome: 'Avaré',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3504602,
    nome: 'Bady Bassitt',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3504701,
    nome: 'Balbinos',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3504800,
    nome: 'Bálsamo',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3504909,
    nome: 'Bananal',
    microrregiao: {
      id: 35052,
      nome: 'Bananal',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350053,
      nome: 'Cruzeiro',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505005,
    nome: 'Barão de Antonina',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505104,
    nome: 'Barbosa',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505203,
    nome: 'Bariri',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505302,
    nome: 'Barra Bonita',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505351,
    nome: 'Barra do Chapéu',
    microrregiao: {
      id: 35044,
      nome: 'Capão Bonito',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505401,
    nome: 'Barra do Turvo',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505500,
    nome: 'Barretos',
    microrregiao: {
      id: 35009,
      nome: 'Barretos',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505609,
    nome: 'Barrinha',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505708,
    nome: 'Barueri',
    microrregiao: {
      id: 35057,
      nome: 'Osasco',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505807,
    nome: 'Bastos',
    microrregiao: {
      id: 35037,
      nome: 'Tupã',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350016,
      nome: 'Tupã',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3505906,
    nome: 'Batatais',
    microrregiao: {
      id: 35015,
      nome: 'Batatais',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506003,
    nome: 'Bauru',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506102,
    nome: 'Bebedouro',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506201,
    nome: 'Bento de Abreu',
    microrregiao: {
      id: 35017,
      nome: 'Araçatuba',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506300,
    nome: 'Bernardino de Campos',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506359,
    nome: 'Bertioga',
    microrregiao: {
      id: 35063,
      nome: 'Santos',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506409,
    nome: 'Bilac',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506508,
    nome: 'Birigui',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506607,
    nome: 'Biritiba Mirim',
    microrregiao: {
      id: 35062,
      nome: 'Mogi das Cruzes',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506706,
    nome: 'Boa Esperança do Sul',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506805,
    nome: 'Bocaina',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3506904,
    nome: 'Bofete',
    microrregiao: {
      id: 35023,
      nome: 'Botucatu',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350011,
      nome: 'Botucatu',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507001,
    nome: 'Boituva',
    microrregiao: {
      id: 35043,
      nome: 'Tatuí',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507100,
    nome: 'Bom Jesus dos Perdões',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507159,
    nome: 'Bom Sucesso de Itararé',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507209,
    nome: 'Borá',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507308,
    nome: 'Boracéia',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507407,
    nome: 'Borborema',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507456,
    nome: 'Borebi',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507506,
    nome: 'Botucatu',
    microrregiao: {
      id: 35023,
      nome: 'Botucatu',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350011,
      nome: 'Botucatu',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507605,
    nome: 'Bragança Paulista',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507704,
    nome: 'Braúna',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507753,
    nome: 'Brejo Alegre',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507803,
    nome: 'Brodowski',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3507902,
    nome: 'Brotas',
    microrregiao: {
      id: 35026,
      nome: 'Rio Claro',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3508009,
    nome: 'Buri',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3508108,
    nome: 'Buritama',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3508207,
    nome: 'Buritizal',
    microrregiao: {
      id: 35011,
      nome: 'Ituverava',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350035,
      nome: 'Ituverava',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3508306,
    nome: 'Cabrália Paulista',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3508405,
    nome: 'Cabreúva',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350039,
      nome: 'Jundiaí',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3508504,
    nome: 'Caçapava',
    microrregiao: {
      id: 35050,
      nome: 'São José dos Campos',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350049,
      nome: 'São José dos Campos',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3508603,
    nome: 'Cachoeira Paulista',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350053,
      nome: 'Cruzeiro',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3508702,
    nome: 'Caconde',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350047,
      nome: 'São José do Rio Pardo - Mococa',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3508801,
    nome: 'Cafelândia',
    microrregiao: {
      id: 35019,
      nome: 'Lins',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350012,
      nome: 'Lins',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3508900,
    nome: 'Caiabu',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509007,
    nome: 'Caieiras',
    microrregiao: {
      id: 35058,
      nome: 'Franco da Rocha',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509106,
    nome: 'Caiuá',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350021,
      nome: 'Presidente Epitácio-Presidente Venceslau',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509205,
    nome: 'Cajamar',
    microrregiao: {
      id: 35057,
      nome: 'Osasco',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509254,
    nome: 'Cajati',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509304,
    nome: 'Cajobi',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509403,
    nome: 'Cajuru',
    microrregiao: {
      id: 35015,
      nome: 'Batatais',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509452,
    nome: 'Campina do Monte Alegre',
    microrregiao: {
      id: 35042,
      nome: 'Itapetininga',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350006,
      nome: 'Itapetininga',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509502,
    nome: 'Campinas',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509601,
    nome: 'Campo Limpo Paulista',
    microrregiao: {
      id: 35047,
      nome: 'Jundiaí',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350039,
      nome: 'Jundiaí',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509700,
    nome: 'Campos do Jordão',
    microrregiao: {
      id: 35049,
      nome: 'Campos do Jordão',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350050,
      nome: 'Taubaté - Pindamonhangaba',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509809,
    nome: 'Campos Novos Paulista',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509908,
    nome: 'Cananéia',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3509957,
    nome: 'Canas',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350052,
      nome: 'Guaratinguetá',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510005,
    nome: 'Cândido Mota',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510104,
    nome: 'Cândido Rodrigues',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510153,
    nome: 'Canitar',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510203,
    nome: 'Capão Bonito',
    microrregiao: {
      id: 35044,
      nome: 'Capão Bonito',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510302,
    nome: 'Capela do Alto',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510401,
    nome: 'Capivari',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510500,
    nome: 'Caraguatatuba',
    microrregiao: {
      id: 35054,
      nome: 'Caraguatatuba',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350051,
      nome: 'Caraguatatuba - Ubatuba - São Sebastião',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510609,
    nome: 'Carapicuíba',
    microrregiao: {
      id: 35057,
      nome: 'Osasco',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510708,
    nome: 'Cardoso',
    microrregiao: {
      id: 35003,
      nome: 'Votuporanga',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510807,
    nome: 'Casa Branca',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350044,
      nome: 'São João da Boa Vista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3510906,
    nome: 'Cássia dos Coqueiros',
    microrregiao: {
      id: 35015,
      nome: 'Batatais',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3511003,
    nome: 'Castilho',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3511102,
    nome: 'Catanduva',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3511201,
    nome: 'Catiguá',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3511300,
    nome: 'Cedral',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3511409,
    nome: 'Cerqueira César',
    microrregiao: {
      id: 35022,
      nome: 'Avaré',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3511508,
    nome: 'Cerquilho',
    microrregiao: {
      id: 35043,
      nome: 'Tatuí',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3511607,
    nome: 'Cesário Lange',
    microrregiao: {
      id: 35043,
      nome: 'Tatuí',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350008,
      nome: 'Tatuí',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3511706,
    nome: 'Charqueada',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3511904,
    nome: 'Clementina',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3512001,
    nome: 'Colina',
    microrregiao: {
      id: 35009,
      nome: 'Barretos',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3512100,
    nome: 'Colômbia',
    microrregiao: {
      id: 35009,
      nome: 'Barretos',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3512209,
    nome: 'Conchal',
    microrregiao: {
      id: 35027,
      nome: 'Limeira',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350045,
      nome: 'Araras',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3512308,
    nome: 'Conchas',
    microrregiao: {
      id: 35023,
      nome: 'Botucatu',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350011,
      nome: 'Botucatu',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3512407,
    nome: 'Cordeirópolis',
    microrregiao: {
      id: 35027,
      nome: 'Limeira',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350042,
      nome: 'Limeira',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3512506,
    nome: 'Coroados',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3512605,
    nome: 'Coronel Macedo',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3512704,
    nome: 'Corumbataí',
    microrregiao: {
      id: 35026,
      nome: 'Rio Claro',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350046,
      nome: 'Rio Claro',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3512803,
    nome: 'Cosmópolis',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3512902,
    nome: 'Cosmorama',
    microrregiao: {
      id: 35003,
      nome: 'Votuporanga',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513009,
    nome: 'Cotia',
    microrregiao: {
      id: 35060,
      nome: 'Itapecerica da Serra',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513108,
    nome: 'Cravinhos',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513207,
    nome: 'Cristais Paulista',
    microrregiao: {
      id: 35012,
      nome: 'Franca',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350033,
      nome: 'Franca',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513306,
    nome: 'Cruzália',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513405,
    nome: 'Cruzeiro',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350053,
      nome: 'Cruzeiro',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513504,
    nome: 'Cubatão',
    microrregiao: {
      id: 35063,
      nome: 'Santos',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513603,
    nome: 'Cunha',
    microrregiao: {
      id: 35053,
      nome: 'Paraibuna/Paraitinga',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350052,
      nome: 'Guaratinguetá',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513702,
    nome: 'Descalvado',
    microrregiao: {
      id: 35025,
      nome: 'São Carlos',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350037,
      nome: 'São Carlos',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513801,
    nome: 'Diadema',
    microrregiao: {
      id: 35061,
      nome: 'São Paulo',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513850,
    nome: 'Dirce Reis',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3513900,
    nome: 'Divinolândia',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350047,
      nome: 'São José do Rio Pardo - Mococa',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514007,
    nome: 'Dobrada',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514106,
    nome: 'Dois Córregos',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514205,
    nome: 'Dolcinópolis',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514304,
    nome: 'Dourado',
    microrregiao: {
      id: 35025,
      nome: 'São Carlos',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350037,
      nome: 'São Carlos',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514403,
    nome: 'Dracena',
    microrregiao: {
      id: 35034,
      nome: 'Dracena',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514502,
    nome: 'Duartina',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514601,
    nome: 'Dumont',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514700,
    nome: 'Echaporã',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514809,
    nome: 'Eldorado',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514908,
    nome: 'Elias Fausto',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514924,
    nome: 'Elisiário',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3514957,
    nome: 'Embaúba',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515004,
    nome: 'Embu das Artes',
    microrregiao: {
      id: 35060,
      nome: 'Itapecerica da Serra',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515103,
    nome: 'Embu-Guaçu',
    microrregiao: {
      id: 35060,
      nome: 'Itapecerica da Serra',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515129,
    nome: 'Emilianópolis',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515152,
    nome: 'Engenheiro Coelho',
    microrregiao: {
      id: 35031,
      nome: 'Mogi Mirim',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350042,
      nome: 'Limeira',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515186,
    nome: 'Espírito Santo do Pinhal',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350044,
      nome: 'São João da Boa Vista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515194,
    nome: 'Espírito Santo do Turvo',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515202,
    nome: "Estrela d'Oeste",
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515301,
    nome: 'Estrela do Norte',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515350,
    nome: 'Euclides da Cunha Paulista',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515400,
    nome: 'Fartura',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350017,
      nome: 'Piraju',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515509,
    nome: 'Fernandópolis',
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515608,
    nome: 'Fernando Prestes',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515657,
    nome: 'Fernão',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515707,
    nome: 'Ferraz de Vasconcelos',
    microrregiao: {
      id: 35062,
      nome: 'Mogi das Cruzes',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515806,
    nome: 'Flora Rica',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3515905,
    nome: 'Floreal',
    microrregiao: {
      id: 35006,
      nome: 'Auriflama',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516002,
    nome: 'Flórida Paulista',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350019,
      nome: 'Adamantina - Lucélia',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516101,
    nome: 'Florínea',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516200,
    nome: 'Franca',
    microrregiao: {
      id: 35012,
      nome: 'Franca',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350033,
      nome: 'Franca',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516309,
    nome: 'Francisco Morato',
    microrregiao: {
      id: 35058,
      nome: 'Franco da Rocha',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516408,
    nome: 'Franco da Rocha',
    microrregiao: {
      id: 35058,
      nome: 'Franco da Rocha',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516507,
    nome: 'Gabriel Monteiro',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516606,
    nome: 'Gália',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516705,
    nome: 'Garça',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516804,
    nome: 'Gastão Vidigal',
    microrregiao: {
      id: 35006,
      nome: 'Auriflama',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516853,
    nome: 'Gavião Peixoto',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3516903,
    nome: 'General Salgado',
    microrregiao: {
      id: 35006,
      nome: 'Auriflama',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3517000,
    nome: 'Getulina',
    microrregiao: {
      id: 35019,
      nome: 'Lins',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3517109,
    nome: 'Glicério',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3517208,
    nome: 'Guaiçara',
    microrregiao: {
      id: 35019,
      nome: 'Lins',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350012,
      nome: 'Lins',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3517307,
    nome: 'Guaimbê',
    microrregiao: {
      id: 35019,
      nome: 'Lins',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3517406,
    nome: 'Guaíra',
    microrregiao: {
      id: 35010,
      nome: 'São Joaquim da Barra',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3517505,
    nome: 'Guapiaçu',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3517604,
    nome: 'Guapiara',
    microrregiao: {
      id: 35044,
      nome: 'Capão Bonito',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3517703,
    nome: 'Guará',
    microrregiao: {
      id: 35011,
      nome: 'Ituverava',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350035,
      nome: 'Ituverava',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3517802,
    nome: 'Guaraçaí',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3517901,
    nome: 'Guaraci',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518008,
    nome: "Guarani d'Oeste",
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518107,
    nome: 'Guarantã',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350012,
      nome: 'Lins',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518206,
    nome: 'Guararapes',
    microrregiao: {
      id: 35017,
      nome: 'Araçatuba',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518305,
    nome: 'Guararema',
    microrregiao: {
      id: 35062,
      nome: 'Mogi das Cruzes',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518404,
    nome: 'Guaratinguetá',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350052,
      nome: 'Guaratinguetá',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518503,
    nome: 'Guareí',
    microrregiao: {
      id: 35042,
      nome: 'Itapetininga',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350006,
      nome: 'Itapetininga',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518602,
    nome: 'Guariba',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518701,
    nome: 'Guarujá',
    microrregiao: {
      id: 35063,
      nome: 'Santos',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518800,
    nome: 'Guarulhos',
    microrregiao: {
      id: 35059,
      nome: 'Guarulhos',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518859,
    nome: 'Guatapará',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3518909,
    nome: 'Guzolândia',
    microrregiao: {
      id: 35006,
      nome: 'Auriflama',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519006,
    nome: 'Herculândia',
    microrregiao: {
      id: 35037,
      nome: 'Tupã',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350016,
      nome: 'Tupã',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519055,
    nome: 'Holambra',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519071,
    nome: 'Hortolândia',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519105,
    nome: 'Iacanga',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519204,
    nome: 'Iacri',
    microrregiao: {
      id: 35037,
      nome: 'Tupã',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350016,
      nome: 'Tupã',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519253,
    nome: 'Iaras',
    microrregiao: {
      id: 35022,
      nome: 'Avaré',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519303,
    nome: 'Ibaté',
    microrregiao: {
      id: 35025,
      nome: 'São Carlos',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350037,
      nome: 'São Carlos',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519402,
    nome: 'Ibirá',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519501,
    nome: 'Ibirarema',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519600,
    nome: 'Ibitinga',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519709,
    nome: 'Ibiúna',
    microrregiao: {
      id: 35045,
      nome: 'Piedade',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519808,
    nome: 'Icém',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3519907,
    nome: 'Iepê',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520004,
    nome: 'Igaraçu do Tietê',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520103,
    nome: 'Igarapava',
    microrregiao: {
      id: 35011,
      nome: 'Ituverava',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350035,
      nome: 'Ituverava',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520202,
    nome: 'Igaratá',
    microrregiao: {
      id: 35050,
      nome: 'São José dos Campos',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350049,
      nome: 'São José dos Campos',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520301,
    nome: 'Iguape',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520400,
    nome: 'Ilhabela',
    microrregiao: {
      id: 35054,
      nome: 'Caraguatatuba',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350051,
      nome: 'Caraguatatuba - Ubatuba - São Sebastião',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520426,
    nome: 'Ilha Comprida',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520442,
    nome: 'Ilha Solteira',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520509,
    nome: 'Indaiatuba',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520608,
    nome: 'Indiana',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520707,
    nome: 'Indiaporã',
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520806,
    nome: 'Inúbia Paulista',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350019,
      nome: 'Adamantina - Lucélia',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3520905,
    nome: 'Ipaussu',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521002,
    nome: 'Iperó',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521101,
    nome: 'Ipeúna',
    microrregiao: {
      id: 35026,
      nome: 'Rio Claro',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350046,
      nome: 'Rio Claro',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521150,
    nome: 'Ipiguá',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521200,
    nome: 'Iporanga',
    microrregiao: {
      id: 35044,
      nome: 'Capão Bonito',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521309,
    nome: 'Ipuã',
    microrregiao: {
      id: 35010,
      nome: 'São Joaquim da Barra',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350034,
      nome: 'São Joaquim da Barra - Orlândia',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521408,
    nome: 'Iracemápolis',
    microrregiao: {
      id: 35027,
      nome: 'Limeira',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350042,
      nome: 'Limeira',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521507,
    nome: 'Irapuã',
    microrregiao: {
      id: 35008,
      nome: 'Novo Horizonte',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521606,
    nome: 'Irapuru',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521705,
    nome: 'Itaberá',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521804,
    nome: 'Itaí',
    microrregiao: {
      id: 35022,
      nome: 'Avaré',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3521903,
    nome: 'Itajobi',
    microrregiao: {
      id: 35008,
      nome: 'Novo Horizonte',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522000,
    nome: 'Itaju',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522109,
    nome: 'Itanhaém',
    microrregiao: {
      id: 35056,
      nome: 'Itanhaém',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522158,
    nome: 'Itaoca',
    microrregiao: {
      id: 35044,
      nome: 'Capão Bonito',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522208,
    nome: 'Itapecerica da Serra',
    microrregiao: {
      id: 35060,
      nome: 'Itapecerica da Serra',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522307,
    nome: 'Itapetininga',
    microrregiao: {
      id: 35042,
      nome: 'Itapetininga',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350006,
      nome: 'Itapetininga',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522406,
    nome: 'Itapeva',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522505,
    nome: 'Itapevi',
    microrregiao: {
      id: 35057,
      nome: 'Osasco',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522604,
    nome: 'Itapira',
    microrregiao: {
      id: 35031,
      nome: 'Mogi Mirim',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350043,
      nome: 'Mogi Guaçu',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522653,
    nome: 'Itapirapuã Paulista',
    microrregiao: {
      id: 35044,
      nome: 'Capão Bonito',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522703,
    nome: 'Itápolis',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522802,
    nome: 'Itaporanga',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3522901,
    nome: 'Itapuí',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3523008,
    nome: 'Itapura',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3523107,
    nome: 'Itaquaquecetuba',
    microrregiao: {
      id: 35062,
      nome: 'Mogi das Cruzes',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3523206,
    nome: 'Itararé',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3523305,
    nome: 'Itariri',
    microrregiao: {
      id: 35056,
      nome: 'Itanhaém',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3523404,
    nome: 'Itatiba',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350039,
      nome: 'Jundiaí',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3523503,
    nome: 'Itatinga',
    microrregiao: {
      id: 35022,
      nome: 'Avaré',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350011,
      nome: 'Botucatu',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3523602,
    nome: 'Itirapina',
    microrregiao: {
      id: 35026,
      nome: 'Rio Claro',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350037,
      nome: 'São Carlos',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3523701,
    nome: 'Itirapuã',
    microrregiao: {
      id: 35012,
      nome: 'Franca',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350033,
      nome: 'Franca',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3523800,
    nome: 'Itobi',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350047,
      nome: 'São José do Rio Pardo - Mococa',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3523909,
    nome: 'Itu',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3524006,
    nome: 'Itupeva',
    microrregiao: {
      id: 35047,
      nome: 'Jundiaí',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350039,
      nome: 'Jundiaí',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3524105,
    nome: 'Ituverava',
    microrregiao: {
      id: 35011,
      nome: 'Ituverava',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350035,
      nome: 'Ituverava',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3524204,
    nome: 'Jaborandi',
    microrregiao: {
      id: 35010,
      nome: 'São Joaquim da Barra',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3524303,
    nome: 'Jaboticabal',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3524402,
    nome: 'Jacareí',
    microrregiao: {
      id: 35050,
      nome: 'São José dos Campos',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350049,
      nome: 'São José dos Campos',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3524501,
    nome: 'Jaci',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3524600,
    nome: 'Jacupiranga',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3524709,
    nome: 'Jaguariúna',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3524808,
    nome: 'Jales',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3524907,
    nome: 'Jambeiro',
    microrregiao: {
      id: 35053,
      nome: 'Paraibuna/Paraitinga',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350049,
      nome: 'São José dos Campos',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525003,
    nome: 'Jandira',
    microrregiao: {
      id: 35057,
      nome: 'Osasco',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525102,
    nome: 'Jardinópolis',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525201,
    nome: 'Jarinu',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350039,
      nome: 'Jundiaí',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525300,
    nome: 'Jaú',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525409,
    nome: 'Jeriquara',
    microrregiao: {
      id: 35012,
      nome: 'Franca',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350033,
      nome: 'Franca',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525508,
    nome: 'Joanópolis',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525607,
    nome: 'João Ramalho',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525706,
    nome: 'José Bonifácio',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525805,
    nome: 'Júlio Mesquita',
    microrregiao: {
      id: 35019,
      nome: 'Lins',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525854,
    nome: 'Jumirim',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3525904,
    nome: 'Jundiaí',
    microrregiao: {
      id: 35047,
      nome: 'Jundiaí',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350039,
      nome: 'Jundiaí',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3526001,
    nome: 'Junqueirópolis',
    microrregiao: {
      id: 35034,
      nome: 'Dracena',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3526100,
    nome: 'Juquiá',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3526209,
    nome: 'Juquitiba',
    microrregiao: {
      id: 35060,
      nome: 'Itapecerica da Serra',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3526308,
    nome: 'Lagoinha',
    microrregiao: {
      id: 35053,
      nome: 'Paraibuna/Paraitinga',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350050,
      nome: 'Taubaté - Pindamonhangaba',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3526407,
    nome: 'Laranjal Paulista',
    microrregiao: {
      id: 35043,
      nome: 'Tatuí',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3526506,
    nome: 'Lavínia',
    microrregiao: {
      id: 35017,
      nome: 'Araçatuba',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3526605,
    nome: 'Lavrinhas',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350053,
      nome: 'Cruzeiro',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3526704,
    nome: 'Leme',
    microrregiao: {
      id: 35027,
      nome: 'Limeira',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350045,
      nome: 'Araras',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3526803,
    nome: 'Lençóis Paulista',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3526902,
    nome: 'Limeira',
    microrregiao: {
      id: 35027,
      nome: 'Limeira',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350042,
      nome: 'Limeira',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527009,
    nome: 'Lindóia',
    microrregiao: {
      id: 35033,
      nome: 'Amparo',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350048,
      nome: 'Amparo',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527108,
    nome: 'Lins',
    microrregiao: {
      id: 35019,
      nome: 'Lins',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350012,
      nome: 'Lins',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527207,
    nome: 'Lorena',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350052,
      nome: 'Guaratinguetá',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527256,
    nome: 'Lourdes',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527306,
    nome: 'Louveira',
    microrregiao: {
      id: 35047,
      nome: 'Jundiaí',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350039,
      nome: 'Jundiaí',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527405,
    nome: 'Lucélia',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350019,
      nome: 'Adamantina - Lucélia',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527504,
    nome: 'Lucianópolis',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527603,
    nome: 'Luís Antônio',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527702,
    nome: 'Luiziânia',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527801,
    nome: 'Lupércio',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3527900,
    nome: 'Lutécia',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528007,
    nome: 'Macatuba',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528106,
    nome: 'Macaubal',
    microrregiao: {
      id: 35007,
      nome: 'Nhandeara',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528205,
    nome: 'Macedônia',
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528304,
    nome: 'Magda',
    microrregiao: {
      id: 35006,
      nome: 'Auriflama',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528403,
    nome: 'Mairinque',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528502,
    nome: 'Mairiporã',
    microrregiao: {
      id: 35058,
      nome: 'Franco da Rocha',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528601,
    nome: 'Manduri',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528700,
    nome: 'Marabá Paulista',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350021,
      nome: 'Presidente Epitácio-Presidente Venceslau',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528809,
    nome: 'Maracaí',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528858,
    nome: 'Marapoama',
    microrregiao: {
      id: 35008,
      nome: 'Novo Horizonte',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3528908,
    nome: 'Mariápolis',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350019,
      nome: 'Adamantina - Lucélia',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529005,
    nome: 'Marília',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529104,
    nome: 'Marinópolis',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529203,
    nome: 'Martinópolis',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529302,
    nome: 'Matão',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529401,
    nome: 'Mauá',
    microrregiao: {
      id: 35061,
      nome: 'São Paulo',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529500,
    nome: 'Mendonça',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529609,
    nome: 'Meridiano',
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529658,
    nome: 'Mesópolis',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529708,
    nome: 'Miguelópolis',
    microrregiao: {
      id: 35010,
      nome: 'São Joaquim da Barra',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350035,
      nome: 'Ituverava',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529807,
    nome: 'Mineiros do Tietê',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3529906,
    nome: 'Miracatu',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3530003,
    nome: 'Mira Estrela',
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3530102,
    nome: 'Mirandópolis',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3530201,
    nome: 'Mirante do Paranapanema',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3530300,
    nome: 'Mirassol',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3530409,
    nome: 'Mirassolândia',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3530508,
    nome: 'Mococa',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350047,
      nome: 'São José do Rio Pardo - Mococa',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3530607,
    nome: 'Mogi das Cruzes',
    microrregiao: {
      id: 35062,
      nome: 'Mogi das Cruzes',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3530706,
    nome: 'Mogi Guaçu',
    microrregiao: {
      id: 35031,
      nome: 'Mogi Mirim',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350043,
      nome: 'Mogi Guaçu',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3530805,
    nome: 'Mogi Mirim',
    microrregiao: {
      id: 35031,
      nome: 'Mogi Mirim',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350043,
      nome: 'Mogi Guaçu',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3530904,
    nome: 'Mombuca',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3531001,
    nome: 'Monções',
    microrregiao: {
      id: 35007,
      nome: 'Nhandeara',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3531100,
    nome: 'Mongaguá',
    microrregiao: {
      id: 35056,
      nome: 'Itanhaém',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3531209,
    nome: 'Monte Alegre do Sul',
    microrregiao: {
      id: 35033,
      nome: 'Amparo',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350048,
      nome: 'Amparo',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3531308,
    nome: 'Monte Alto',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3531407,
    nome: 'Monte Aprazível',
    microrregiao: {
      id: 35007,
      nome: 'Nhandeara',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3531506,
    nome: 'Monte Azul Paulista',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3531605,
    nome: 'Monte Castelo',
    microrregiao: {
      id: 35034,
      nome: 'Dracena',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3531704,
    nome: 'Monteiro Lobato',
    microrregiao: {
      id: 35049,
      nome: 'Campos do Jordão',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350049,
      nome: 'São José dos Campos',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3531803,
    nome: 'Monte Mor',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3531902,
    nome: 'Morro Agudo',
    microrregiao: {
      id: 35010,
      nome: 'São Joaquim da Barra',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350034,
      nome: 'São Joaquim da Barra - Orlândia',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532009,
    nome: 'Morungaba',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350039,
      nome: 'Jundiaí',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532058,
    nome: 'Motuca',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532108,
    nome: 'Murutinga do Sul',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532157,
    nome: 'Nantes',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532207,
    nome: 'Narandiba',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532306,
    nome: 'Natividade da Serra',
    microrregiao: {
      id: 35053,
      nome: 'Paraibuna/Paraitinga',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350050,
      nome: 'Taubaté - Pindamonhangaba',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532405,
    nome: 'Nazaré Paulista',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532504,
    nome: 'Neves Paulista',
    microrregiao: {
      id: 35007,
      nome: 'Nhandeara',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532603,
    nome: 'Nhandeara',
    microrregiao: {
      id: 35007,
      nome: 'Nhandeara',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532702,
    nome: 'Nipoã',
    microrregiao: {
      id: 35007,
      nome: 'Nhandeara',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532801,
    nome: 'Nova Aliança',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532827,
    nome: 'Nova Campina',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532843,
    nome: 'Nova Canaã Paulista',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350030,
      nome: 'Santa Fé do Sul',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532868,
    nome: 'Nova Castilho',
    microrregiao: {
      id: 35006,
      nome: 'Auriflama',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3532900,
    nome: 'Nova Europa',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533007,
    nome: 'Nova Granada',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533106,
    nome: 'Nova Guataporanga',
    microrregiao: {
      id: 35034,
      nome: 'Dracena',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533205,
    nome: 'Nova Independência',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533254,
    nome: 'Novais',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533304,
    nome: 'Nova Luzitânia',
    microrregiao: {
      id: 35006,
      nome: 'Auriflama',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533403,
    nome: 'Nova Odessa',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533502,
    nome: 'Novo Horizonte',
    microrregiao: {
      id: 35008,
      nome: 'Novo Horizonte',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533601,
    nome: 'Nuporanga',
    microrregiao: {
      id: 35010,
      nome: 'São Joaquim da Barra',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350034,
      nome: 'São Joaquim da Barra - Orlândia',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533700,
    nome: 'Ocauçu',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533809,
    nome: 'Óleo',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3533908,
    nome: 'Olímpia',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534005,
    nome: 'Onda Verde',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534104,
    nome: 'Oriente',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534203,
    nome: 'Orindiúva',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534302,
    nome: 'Orlândia',
    microrregiao: {
      id: 35010,
      nome: 'São Joaquim da Barra',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350034,
      nome: 'São Joaquim da Barra - Orlândia',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534401,
    nome: 'Osasco',
    microrregiao: {
      id: 35057,
      nome: 'Osasco',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534500,
    nome: 'Oscar Bressane',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534609,
    nome: 'Osvaldo Cruz',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350019,
      nome: 'Adamantina - Lucélia',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534708,
    nome: 'Ourinhos',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534757,
    nome: 'Ouroeste',
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534807,
    nome: 'Ouro Verde',
    microrregiao: {
      id: 35034,
      nome: 'Dracena',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3534906,
    nome: 'Pacaembu',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350019,
      nome: 'Adamantina - Lucélia',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3535002,
    nome: 'Palestina',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3535101,
    nome: 'Palmares Paulista',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3535200,
    nome: "Palmeira d'Oeste",
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3535309,
    nome: 'Palmital',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3535408,
    nome: 'Panorama',
    microrregiao: {
      id: 35034,
      nome: 'Dracena',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3535507,
    nome: 'Paraguaçu Paulista',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3535606,
    nome: 'Paraibuna',
    microrregiao: {
      id: 35053,
      nome: 'Paraibuna/Paraitinga',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350049,
      nome: 'São José dos Campos',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3535705,
    nome: 'Paraíso',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3535804,
    nome: 'Paranapanema',
    microrregiao: {
      id: 35022,
      nome: 'Avaré',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3535903,
    nome: 'Paranapuã',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536000,
    nome: 'Parapuã',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350016,
      nome: 'Tupã',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536109,
    nome: 'Pardinho',
    microrregiao: {
      id: 35023,
      nome: 'Botucatu',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350011,
      nome: 'Botucatu',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536208,
    nome: 'Pariquera-Açu',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536257,
    nome: 'Parisi',
    microrregiao: {
      id: 35003,
      nome: 'Votuporanga',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536307,
    nome: 'Patrocínio Paulista',
    microrregiao: {
      id: 35012,
      nome: 'Franca',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350033,
      nome: 'Franca',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536406,
    nome: 'Paulicéia',
    microrregiao: {
      id: 35034,
      nome: 'Dracena',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536505,
    nome: 'Paulínia',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536570,
    nome: 'Paulistânia',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536604,
    nome: 'Paulo de Faria',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536703,
    nome: 'Pederneiras',
    microrregiao: {
      id: 35021,
      nome: 'Jaú',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536802,
    nome: 'Pedra Bela',
    microrregiao: {
      id: 35033,
      nome: 'Amparo',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3536901,
    nome: 'Pedranópolis',
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537008,
    nome: 'Pedregulho',
    microrregiao: {
      id: 35012,
      nome: 'Franca',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350033,
      nome: 'Franca',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537107,
    nome: 'Pedreira',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537156,
    nome: 'Pedrinhas Paulista',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537206,
    nome: 'Pedro de Toledo',
    microrregiao: {
      id: 35056,
      nome: 'Itanhaém',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537305,
    nome: 'Penápolis',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537404,
    nome: 'Pereira Barreto',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537503,
    nome: 'Pereiras',
    microrregiao: {
      id: 35043,
      nome: 'Tatuí',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350008,
      nome: 'Tatuí',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537602,
    nome: 'Peruíbe',
    microrregiao: {
      id: 35056,
      nome: 'Itanhaém',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537701,
    nome: 'Piacatu',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537800,
    nome: 'Piedade',
    microrregiao: {
      id: 35045,
      nome: 'Piedade',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3537909,
    nome: 'Pilar do Sul',
    microrregiao: {
      id: 35045,
      nome: 'Piedade',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3538006,
    nome: 'Pindamonhangaba',
    microrregiao: {
      id: 35050,
      nome: 'São José dos Campos',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350050,
      nome: 'Taubaté - Pindamonhangaba',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3538105,
    nome: 'Pindorama',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3538204,
    nome: 'Pinhalzinho',
    microrregiao: {
      id: 35033,
      nome: 'Amparo',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3538303,
    nome: 'Piquerobi',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350021,
      nome: 'Presidente Epitácio-Presidente Venceslau',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3538501,
    nome: 'Piquete',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350052,
      nome: 'Guaratinguetá',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3538600,
    nome: 'Piracaia',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3538709,
    nome: 'Piracicaba',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3538808,
    nome: 'Piraju',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350017,
      nome: 'Piraju',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3538907,
    nome: 'Pirajuí',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3539004,
    nome: 'Pirangi',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3539103,
    nome: 'Pirapora do Bom Jesus',
    microrregiao: {
      id: 35057,
      nome: 'Osasco',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3539202,
    nome: 'Pirapozinho',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3539301,
    nome: 'Pirassununga',
    microrregiao: {
      id: 35029,
      nome: 'Pirassununga',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350037,
      nome: 'São Carlos',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3539400,
    nome: 'Piratininga',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3539509,
    nome: 'Pitangueiras',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3539608,
    nome: 'Planalto',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3539707,
    nome: 'Platina',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3539806,
    nome: 'Poá',
    microrregiao: {
      id: 35062,
      nome: 'Mogi das Cruzes',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3539905,
    nome: 'Poloni',
    microrregiao: {
      id: 35007,
      nome: 'Nhandeara',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540002,
    nome: 'Pompéia',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540101,
    nome: 'Pongaí',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350012,
      nome: 'Lins',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540200,
    nome: 'Pontal',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540259,
    nome: 'Pontalinda',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540309,
    nome: 'Pontes Gestal',
    microrregiao: {
      id: 35003,
      nome: 'Votuporanga',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540408,
    nome: 'Populina',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540507,
    nome: 'Porangaba',
    microrregiao: {
      id: 35043,
      nome: 'Tatuí',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350008,
      nome: 'Tatuí',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540606,
    nome: 'Porto Feliz',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540705,
    nome: 'Porto Ferreira',
    microrregiao: {
      id: 35029,
      nome: 'Pirassununga',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350037,
      nome: 'São Carlos',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540754,
    nome: 'Potim',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350052,
      nome: 'Guaratinguetá',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540804,
    nome: 'Potirendaba',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540853,
    nome: 'Pracinha',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350019,
      nome: 'Adamantina - Lucélia',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3540903,
    nome: 'Pradópolis',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541000,
    nome: 'Praia Grande',
    microrregiao: {
      id: 35063,
      nome: 'Santos',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541059,
    nome: 'Pratânia',
    microrregiao: {
      id: 35023,
      nome: 'Botucatu',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350011,
      nome: 'Botucatu',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541109,
    nome: 'Presidente Alves',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541208,
    nome: 'Presidente Bernardes',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541307,
    nome: 'Presidente Epitácio',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350021,
      nome: 'Presidente Epitácio-Presidente Venceslau',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541406,
    nome: 'Presidente Prudente',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541505,
    nome: 'Presidente Venceslau',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350021,
      nome: 'Presidente Epitácio-Presidente Venceslau',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541604,
    nome: 'Promissão',
    microrregiao: {
      id: 35019,
      nome: 'Lins',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350012,
      nome: 'Lins',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541653,
    nome: 'Quadra',
    microrregiao: {
      id: 35043,
      nome: 'Tatuí',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350008,
      nome: 'Tatuí',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541703,
    nome: 'Quatá',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541802,
    nome: 'Queiroz',
    microrregiao: {
      id: 35037,
      nome: 'Tupã',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350016,
      nome: 'Tupã',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3541901,
    nome: 'Queluz',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350053,
      nome: 'Cruzeiro',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3542008,
    nome: 'Quintana',
    microrregiao: {
      id: 35037,
      nome: 'Tupã',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3542107,
    nome: 'Rafard',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3542206,
    nome: 'Rancharia',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3542305,
    nome: 'Redenção da Serra',
    microrregiao: {
      id: 35053,
      nome: 'Paraibuna/Paraitinga',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350050,
      nome: 'Taubaté - Pindamonhangaba',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3542404,
    nome: 'Regente Feijó',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3542503,
    nome: 'Reginópolis',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3542602,
    nome: 'Registro',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3542701,
    nome: 'Restinga',
    microrregiao: {
      id: 35012,
      nome: 'Franca',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350033,
      nome: 'Franca',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3542800,
    nome: 'Ribeira',
    microrregiao: {
      id: 35044,
      nome: 'Capão Bonito',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3542909,
    nome: 'Ribeirão Bonito',
    microrregiao: {
      id: 35025,
      nome: 'São Carlos',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350037,
      nome: 'São Carlos',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543006,
    nome: 'Ribeirão Branco',
    microrregiao: {
      id: 35044,
      nome: 'Capão Bonito',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543105,
    nome: 'Ribeirão Corrente',
    microrregiao: {
      id: 35012,
      nome: 'Franca',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350033,
      nome: 'Franca',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543204,
    nome: 'Ribeirão do Sul',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543238,
    nome: 'Ribeirão dos Índios',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543253,
    nome: 'Ribeirão Grande',
    microrregiao: {
      id: 35044,
      nome: 'Capão Bonito',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543303,
    nome: 'Ribeirão Pires',
    microrregiao: {
      id: 35061,
      nome: 'São Paulo',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543402,
    nome: 'Ribeirão Preto',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543501,
    nome: 'Riversul',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543600,
    nome: 'Rifaina',
    microrregiao: {
      id: 35012,
      nome: 'Franca',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350033,
      nome: 'Franca',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543709,
    nome: 'Rincão',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543808,
    nome: 'Rinópolis',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350016,
      nome: 'Tupã',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3543907,
    nome: 'Rio Claro',
    microrregiao: {
      id: 35026,
      nome: 'Rio Claro',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350046,
      nome: 'Rio Claro',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544004,
    nome: 'Rio das Pedras',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544103,
    nome: 'Rio Grande da Serra',
    microrregiao: {
      id: 35061,
      nome: 'São Paulo',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544202,
    nome: 'Riolândia',
    microrregiao: {
      id: 35003,
      nome: 'Votuporanga',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544251,
    nome: 'Rosana',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544301,
    nome: 'Roseira',
    microrregiao: {
      id: 35051,
      nome: 'Guaratinguetá',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350052,
      nome: 'Guaratinguetá',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544400,
    nome: 'Rubiácea',
    microrregiao: {
      id: 35017,
      nome: 'Araçatuba',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544509,
    nome: 'Rubinéia',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350030,
      nome: 'Santa Fé do Sul',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544608,
    nome: 'Sabino',
    microrregiao: {
      id: 35019,
      nome: 'Lins',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350012,
      nome: 'Lins',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544707,
    nome: 'Sagres',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350019,
      nome: 'Adamantina - Lucélia',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544806,
    nome: 'Sales',
    microrregiao: {
      id: 35008,
      nome: 'Novo Horizonte',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3544905,
    nome: 'Sales Oliveira',
    microrregiao: {
      id: 35010,
      nome: 'São Joaquim da Barra',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350034,
      nome: 'São Joaquim da Barra - Orlândia',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3545001,
    nome: 'Salesópolis',
    microrregiao: {
      id: 35062,
      nome: 'Mogi das Cruzes',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3545100,
    nome: 'Salmourão',
    microrregiao: {
      id: 35035,
      nome: 'Adamantina',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350019,
      nome: 'Adamantina - Lucélia',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3545159,
    nome: 'Saltinho',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3545209,
    nome: 'Salto',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3545308,
    nome: 'Salto de Pirapora',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3545407,
    nome: 'Salto Grande',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3545506,
    nome: 'Sandovalina',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3545605,
    nome: 'Santa Adélia',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3545704,
    nome: 'Santa Albertina',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3545803,
    nome: "Santa Bárbara d'Oeste",
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546009,
    nome: 'Santa Branca',
    microrregiao: {
      id: 35050,
      nome: 'São José dos Campos',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350049,
      nome: 'São José dos Campos',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546108,
    nome: "Santa Clara d'Oeste",
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350030,
      nome: 'Santa Fé do Sul',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546207,
    nome: 'Santa Cruz da Conceição',
    microrregiao: {
      id: 35027,
      nome: 'Limeira',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350045,
      nome: 'Araras',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546256,
    nome: 'Santa Cruz da Esperança',
    microrregiao: {
      id: 35015,
      nome: 'Batatais',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546306,
    nome: 'Santa Cruz das Palmeiras',
    microrregiao: {
      id: 35029,
      nome: 'Pirassununga',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350044,
      nome: 'São João da Boa Vista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546405,
    nome: 'Santa Cruz do Rio Pardo',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546504,
    nome: 'Santa Ernestina',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546603,
    nome: 'Santa Fé do Sul',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350030,
      nome: 'Santa Fé do Sul',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546702,
    nome: 'Santa Gertrudes',
    microrregiao: {
      id: 35027,
      nome: 'Limeira',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350046,
      nome: 'Rio Claro',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546801,
    nome: 'Santa Isabel',
    microrregiao: {
      id: 35059,
      nome: 'Guarulhos',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3546900,
    nome: 'Santa Lúcia',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547007,
    nome: 'Santa Maria da Serra',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547106,
    nome: 'Santa Mercedes',
    microrregiao: {
      id: 35034,
      nome: 'Dracena',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547205,
    nome: 'Santana da Ponte Pensa',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350030,
      nome: 'Santa Fé do Sul',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547304,
    nome: 'Santana de Parnaíba',
    microrregiao: {
      id: 35057,
      nome: 'Osasco',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547403,
    nome: "Santa Rita d'Oeste",
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350030,
      nome: 'Santa Fé do Sul',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547502,
    nome: 'Santa Rita do Passa Quatro',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350037,
      nome: 'São Carlos',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547601,
    nome: 'Santa Rosa de Viterbo',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547650,
    nome: 'Santa Salete',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547700,
    nome: 'Santo Anastácio',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547809,
    nome: 'Santo André',
    microrregiao: {
      id: 35061,
      nome: 'São Paulo',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3547908,
    nome: 'Santo Antônio da Alegria',
    microrregiao: {
      id: 35015,
      nome: 'Batatais',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548005,
    nome: 'Santo Antônio de Posse',
    microrregiao: {
      id: 35031,
      nome: 'Mogi Mirim',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548054,
    nome: 'Santo Antônio do Aracanguá',
    microrregiao: {
      id: 35017,
      nome: 'Araçatuba',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548104,
    nome: 'Santo Antônio do Jardim',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350044,
      nome: 'São João da Boa Vista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548203,
    nome: 'Santo Antônio do Pinhal',
    microrregiao: {
      id: 35049,
      nome: 'Campos do Jordão',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350050,
      nome: 'Taubaté - Pindamonhangaba',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548302,
    nome: 'Santo Expedito',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548401,
    nome: 'Santópolis do Aguapeí',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548500,
    nome: 'Santos',
    microrregiao: {
      id: 35063,
      nome: 'Santos',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548609,
    nome: 'São Bento do Sapucaí',
    microrregiao: {
      id: 35049,
      nome: 'Campos do Jordão',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350050,
      nome: 'Taubaté - Pindamonhangaba',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548708,
    nome: 'São Bernardo do Campo',
    microrregiao: {
      id: 35061,
      nome: 'São Paulo',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548807,
    nome: 'São Caetano do Sul',
    microrregiao: {
      id: 35061,
      nome: 'São Paulo',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3548906,
    nome: 'São Carlos',
    microrregiao: {
      id: 35025,
      nome: 'São Carlos',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350037,
      nome: 'São Carlos',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549003,
    nome: 'São Francisco',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549102,
    nome: 'São João da Boa Vista',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350044,
      nome: 'São João da Boa Vista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549201,
    nome: 'São João das Duas Pontes',
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549250,
    nome: 'São João de Iracema',
    microrregiao: {
      id: 35006,
      nome: 'Auriflama',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350029,
      nome: 'Fernandópolis',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549300,
    nome: "São João do Pau d'Alho",
    microrregiao: {
      id: 35034,
      nome: 'Dracena',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549409,
    nome: 'São Joaquim da Barra',
    microrregiao: {
      id: 35010,
      nome: 'São Joaquim da Barra',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350034,
      nome: 'São Joaquim da Barra - Orlândia',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549508,
    nome: 'São José da Bela Vista',
    microrregiao: {
      id: 35012,
      nome: 'Franca',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350033,
      nome: 'Franca',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549607,
    nome: 'São José do Barreiro',
    microrregiao: {
      id: 35052,
      nome: 'Bananal',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350053,
      nome: 'Cruzeiro',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549706,
    nome: 'São José do Rio Pardo',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350047,
      nome: 'São José do Rio Pardo - Mococa',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549805,
    nome: 'São José do Rio Preto',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549904,
    nome: 'São José dos Campos',
    microrregiao: {
      id: 35050,
      nome: 'São José dos Campos',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350049,
      nome: 'São José dos Campos',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3549953,
    nome: 'São Lourenço da Serra',
    microrregiao: {
      id: 35060,
      nome: 'Itapecerica da Serra',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3550001,
    nome: 'São Luiz do Paraitinga',
    microrregiao: {
      id: 35053,
      nome: 'Paraibuna/Paraitinga',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350050,
      nome: 'Taubaté - Pindamonhangaba',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3550100,
    nome: 'São Manuel',
    microrregiao: {
      id: 35023,
      nome: 'Botucatu',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350011,
      nome: 'Botucatu',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3550209,
    nome: 'São Miguel Arcanjo',
    microrregiao: {
      id: 35045,
      nome: 'Piedade',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350006,
      nome: 'Itapetininga',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3550308,
    nome: 'São Paulo',
    microrregiao: {
      id: 35061,
      nome: 'São Paulo',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3550407,
    nome: 'São Pedro',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350040,
      nome: 'Piracicaba',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3550506,
    nome: 'São Pedro do Turvo',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3550605,
    nome: 'São Roque',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3550704,
    nome: 'São Sebastião',
    microrregiao: {
      id: 35054,
      nome: 'Caraguatatuba',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350051,
      nome: 'Caraguatatuba - Ubatuba - São Sebastião',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3550803,
    nome: 'São Sebastião da Grama',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350047,
      nome: 'São José do Rio Pardo - Mococa',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3550902,
    nome: 'São Simão',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3551009,
    nome: 'São Vicente',
    microrregiao: {
      id: 35063,
      nome: 'Santos',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350002,
      nome: 'Santos',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3551108,
    nome: 'Sarapuí',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3551207,
    nome: 'Sarutaiá',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350017,
      nome: 'Piraju',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3551306,
    nome: 'Sebastianópolis do Sul',
    microrregiao: {
      id: 35007,
      nome: 'Nhandeara',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3551405,
    nome: 'Serra Azul',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3551504,
    nome: 'Serrana',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3551603,
    nome: 'Serra Negra',
    microrregiao: {
      id: 35033,
      nome: 'Amparo',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350048,
      nome: 'Amparo',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3551702,
    nome: 'Sertãozinho',
    microrregiao: {
      id: 35014,
      nome: 'Ribeirão Preto',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350031,
      nome: 'Ribeirão Preto',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3551801,
    nome: 'Sete Barras',
    microrregiao: {
      id: 35055,
      nome: 'Registro',
      mesorregiao: {
        id: 3514,
        nome: 'Litoral Sul Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350005,
      nome: 'Registro',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3551900,
    nome: 'Severínia',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552007,
    nome: 'Silveiras',
    microrregiao: {
      id: 35052,
      nome: 'Bananal',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350053,
      nome: 'Cruzeiro',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552106,
    nome: 'Socorro',
    microrregiao: {
      id: 35033,
      nome: 'Amparo',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552205,
    nome: 'Sorocaba',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552304,
    nome: 'Sud Mennucci',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350024,
      nome: 'Andradina',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552403,
    nome: 'Sumaré',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552502,
    nome: 'Suzano',
    microrregiao: {
      id: 35062,
      nome: 'Mogi das Cruzes',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552551,
    nome: 'Suzanápolis',
    microrregiao: {
      id: 35016,
      nome: 'Andradina',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552601,
    nome: 'Tabapuã',
    microrregiao: {
      id: 35005,
      nome: 'Catanduva',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552700,
    nome: 'Tabatinga',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552809,
    nome: 'Taboão da Serra',
    microrregiao: {
      id: 35060,
      nome: 'Itapecerica da Serra',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3552908,
    nome: 'Taciba',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553005,
    nome: 'Taguaí',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553104,
    nome: 'Taiaçu',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553203,
    nome: 'Taiúva',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553302,
    nome: 'Tambaú',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350044,
      nome: 'São João da Boa Vista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553401,
    nome: 'Tanabi',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553500,
    nome: 'Tapiraí',
    microrregiao: {
      id: 35045,
      nome: 'Piedade',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553609,
    nome: 'Tapiratiba',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350047,
      nome: 'São José do Rio Pardo - Mococa',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553658,
    nome: 'Taquaral',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553708,
    nome: 'Taquaritinga',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553807,
    nome: 'Taquarituba',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350007,
      nome: 'Avaré',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553856,
    nome: 'Taquarivaí',
    microrregiao: {
      id: 35041,
      nome: 'Itapeva',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350004,
      nome: 'Itapeva',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553906,
    nome: 'Tarabai',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3553955,
    nome: 'Tarumã',
    microrregiao: {
      id: 35039,
      nome: 'Assis',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350014,
      nome: 'Assis',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554003,
    nome: 'Tatuí',
    microrregiao: {
      id: 35043,
      nome: 'Tatuí',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350008,
      nome: 'Tatuí',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554102,
    nome: 'Taubaté',
    microrregiao: {
      id: 35050,
      nome: 'São José dos Campos',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350050,
      nome: 'Taubaté - Pindamonhangaba',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554201,
    nome: 'Tejupá',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350017,
      nome: 'Piraju',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554300,
    nome: 'Teodoro Sampaio',
    microrregiao: {
      id: 35036,
      nome: 'Presidente Prudente',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350018,
      nome: 'Presidente Prudente',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554409,
    nome: 'Terra Roxa',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554508,
    nome: 'Tietê',
    microrregiao: {
      id: 35028,
      nome: 'Piracicaba',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554607,
    nome: 'Timburi',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350017,
      nome: 'Piraju',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554656,
    nome: 'Torre de Pedra',
    microrregiao: {
      id: 35043,
      nome: 'Tatuí',
      mesorregiao: {
        id: 3511,
        nome: 'Itapetininga',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350008,
      nome: 'Tatuí',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554706,
    nome: 'Torrinha',
    microrregiao: {
      id: 35026,
      nome: 'Rio Claro',
      mesorregiao: {
        id: 3506,
        nome: 'Piracicaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350010,
      nome: 'Jaú',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554755,
    nome: 'Trabiju',
    microrregiao: {
      id: 35024,
      nome: 'Araraquara',
      mesorregiao: {
        id: 3505,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350036,
      nome: 'Araraquara',
      'regiao-intermediaria': {
        id: 3509,
        nome: 'Araraquara',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554805,
    nome: 'Tremembé',
    microrregiao: {
      id: 35050,
      nome: 'São José dos Campos',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350050,
      nome: 'Taubaté - Pindamonhangaba',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554904,
    nome: 'Três Fronteiras',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350030,
      nome: 'Santa Fé do Sul',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3554953,
    nome: 'Tuiuti',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555000,
    nome: 'Tupã',
    microrregiao: {
      id: 35037,
      nome: 'Tupã',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350016,
      nome: 'Tupã',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555109,
    nome: 'Tupi Paulista',
    microrregiao: {
      id: 35034,
      nome: 'Dracena',
      mesorregiao: {
        id: 3508,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350020,
      nome: 'Dracena',
      'regiao-intermediaria': {
        id: 3505,
        nome: 'Presidente Prudente',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555208,
    nome: 'Turiúba',
    microrregiao: {
      id: 35018,
      nome: 'Birigui',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555307,
    nome: 'Turmalina',
    microrregiao: {
      id: 35002,
      nome: 'Fernandópolis',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555356,
    nome: 'Ubarana',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555406,
    nome: 'Ubatuba',
    microrregiao: {
      id: 35054,
      nome: 'Caraguatatuba',
      mesorregiao: {
        id: 3513,
        nome: 'Vale do Paraíba Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350051,
      nome: 'Caraguatatuba - Ubatuba - São Sebastião',
      'regiao-intermediaria': {
        id: 3511,
        nome: 'São José dos Campos',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555505,
    nome: 'Ubirajara',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350009,
      nome: 'Bauru',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555604,
    nome: 'Uchoa',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555703,
    nome: 'União Paulista',
    microrregiao: {
      id: 35007,
      nome: 'Nhandeara',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555802,
    nome: 'Urânia',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3555901,
    nome: 'Uru',
    microrregiao: {
      id: 35020,
      nome: 'Bauru',
      mesorregiao: {
        id: 3504,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350012,
      nome: 'Lins',
      'regiao-intermediaria': {
        id: 3503,
        nome: 'Bauru',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556008,
    nome: 'Urupês',
    microrregiao: {
      id: 35008,
      nome: 'Novo Horizonte',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350025,
      nome: 'São José do Rio Preto',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556107,
    nome: 'Valentim Gentil',
    microrregiao: {
      id: 35003,
      nome: 'Votuporanga',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556206,
    nome: 'Valinhos',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556305,
    nome: 'Valparaíso',
    microrregiao: {
      id: 35017,
      nome: 'Araçatuba',
      mesorregiao: {
        id: 3503,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350022,
      nome: 'Araçatuba',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556354,
    nome: 'Vargem',
    microrregiao: {
      id: 35048,
      nome: 'Bragança Paulista',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350041,
      nome: 'Bragança Paulista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556404,
    nome: 'Vargem Grande do Sul',
    microrregiao: {
      id: 35030,
      nome: 'São João da Boa Vista',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350044,
      nome: 'São João da Boa Vista',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556453,
    nome: 'Vargem Grande Paulista',
    microrregiao: {
      id: 35060,
      nome: 'Itapecerica da Serra',
      mesorregiao: {
        id: 3515,
        nome: 'Metropolitana de São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350001,
      nome: 'São Paulo',
      'regiao-intermediaria': {
        id: 3501,
        nome: 'São Paulo',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556503,
    nome: 'Várzea Paulista',
    microrregiao: {
      id: 35047,
      nome: 'Jundiaí',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350039,
      nome: 'Jundiaí',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556602,
    nome: 'Vera Cruz',
    microrregiao: {
      id: 35038,
      nome: 'Marília',
      mesorregiao: {
        id: 3509,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350013,
      nome: 'Marília',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556701,
    nome: 'Vinhedo',
    microrregiao: {
      id: 35032,
      nome: 'Campinas',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350038,
      nome: 'Campinas',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556800,
    nome: 'Viradouro',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350032,
      nome: 'Barretos',
      'regiao-intermediaria': {
        id: 3508,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556909,
    nome: 'Vista Alegre do Alto',
    microrregiao: {
      id: 35013,
      nome: 'Jaboticabal',
      mesorregiao: {
        id: 3502,
        nome: 'Ribeirão Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350026,
      nome: 'Catanduva',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3556958,
    nome: 'Vitória Brasil',
    microrregiao: {
      id: 35001,
      nome: 'Jales',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350028,
      nome: 'Jales',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3557006,
    nome: 'Votorantim',
    microrregiao: {
      id: 35046,
      nome: 'Sorocaba',
      mesorregiao: {
        id: 3512,
        nome: 'Macro Metropolitana Paulista',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350003,
      nome: 'Sorocaba',
      'regiao-intermediaria': {
        id: 3502,
        nome: 'Sorocaba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3557105,
    nome: 'Votuporanga',
    microrregiao: {
      id: 35003,
      nome: 'Votuporanga',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350027,
      nome: 'Votuporanga',
      'regiao-intermediaria': {
        id: 3507,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3557154,
    nome: 'Zacarias',
    microrregiao: {
      id: 35004,
      nome: 'São José do Rio Preto',
      mesorregiao: {
        id: 3501,
        nome: 'São José do Rio Preto',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350023,
      nome: 'Birigui - Penápolis',
      'regiao-intermediaria': {
        id: 3506,
        nome: 'Araçatuba',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3557204,
    nome: 'Chavantes',
    microrregiao: {
      id: 35040,
      nome: 'Ourinhos',
      mesorregiao: {
        id: 3510,
        nome: 'Assis',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350015,
      nome: 'Ourinhos',
      'regiao-intermediaria': {
        id: 3504,
        nome: 'Marília',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 3557303,
    nome: 'Estiva Gerbi',
    microrregiao: {
      id: 35031,
      nome: 'Mogi Mirim',
      mesorregiao: {
        id: 3507,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 350043,
      nome: 'Mogi Guaçu',
      'regiao-intermediaria': {
        id: 3510,
        nome: 'Campinas',
        UF: {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
      },
    },
  },
  {
    id: 4100103,
    nome: 'Abatiá',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4100202,
    nome: 'Adrianópolis',
    microrregiao: {
      id: 41035,
      nome: 'Cerro Azul',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4100301,
    nome: 'Agudos do Sul',
    microrregiao: {
      id: 41039,
      nome: 'Rio Negro',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4100400,
    nome: 'Almirante Tamandaré',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4100459,
    nome: 'Altamira do Paraná',
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4100509,
    nome: 'Altônia',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4100608,
    nome: 'Alto Paraná',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4100707,
    nome: 'Alto Piquiri',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4100806,
    nome: 'Alvorada do Sul',
    microrregiao: {
      id: 41007,
      nome: 'Porecatu',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4100905,
    nome: 'Amaporã',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101002,
    nome: 'Ampére',
    microrregiao: {
      id: 41025,
      nome: 'Capanema',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101051,
    nome: 'Anahy',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101101,
    nome: 'Andirá',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101150,
    nome: 'Ângulo',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101200,
    nome: 'Antonina',
    microrregiao: {
      id: 41038,
      nome: 'Paranaguá',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410002,
      nome: 'Paranaguá',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101309,
    nome: 'Antônio Olinto',
    microrregiao: {
      id: 41034,
      nome: 'São Mateus do Sul',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410003,
      nome: 'União da Vitória',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101408,
    nome: 'Apucarana',
    microrregiao: {
      id: 41010,
      nome: 'Apucarana',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101507,
    nome: 'Arapongas',
    microrregiao: {
      id: 41010,
      nome: 'Apucarana',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101606,
    nome: 'Arapoti',
    microrregiao: {
      id: 41020,
      nome: 'Jaguariaíva',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101655,
    nome: 'Arapuã',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101705,
    nome: 'Araruna',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101804,
    nome: 'Araucária',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101853,
    nome: 'Ariranha do Ivaí',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4101903,
    nome: 'Assaí',
    microrregiao: {
      id: 41014,
      nome: 'Assaí',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102000,
    nome: 'Assis Chateaubriand',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102109,
    nome: 'Astorga',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102208,
    nome: 'Atalaia',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102307,
    nome: 'Balsa Nova',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102406,
    nome: 'Bandeirantes',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102505,
    nome: 'Barbosa Ferraz',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102604,
    nome: 'Barracão',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102703,
    nome: 'Barra do Jacaré',
    microrregiao: {
      id: 41016,
      nome: 'Jacarezinho',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102752,
    nome: 'Bela Vista da Caroba',
    microrregiao: {
      id: 41025,
      nome: 'Capanema',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102802,
    nome: 'Bela Vista do Paraíso',
    microrregiao: {
      id: 41007,
      nome: 'Porecatu',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4102901,
    nome: 'Bituruna',
    microrregiao: {
      id: 41033,
      nome: 'União da Vitória',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410003,
      nome: 'União da Vitória',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103008,
    nome: 'Boa Esperança',
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103024,
    nome: 'Boa Esperança do Iguaçu',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410012,
      nome: 'Dois Vizinhos',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103040,
    nome: 'Boa Ventura de São Roque',
    microrregiao: {
      id: 41028,
      nome: 'Pitanga',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410005,
      nome: 'Pitanga',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103057,
    nome: 'Boa Vista da Aparecida',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103107,
    nome: 'Bocaiúva do Sul',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103156,
    nome: 'Bom Jesus do Sul',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103206,
    nome: 'Bom Sucesso',
    microrregiao: {
      id: 41012,
      nome: 'Faxinal',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103222,
    nome: 'Bom Sucesso do Sul',
    microrregiao: {
      id: 41027,
      nome: 'Pato Branco',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103305,
    nome: 'Borrazópolis',
    microrregiao: {
      id: 41012,
      nome: 'Faxinal',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103354,
    nome: 'Braganey',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103370,
    nome: 'Brasilândia do Sul',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103404,
    nome: 'Cafeara',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103453,
    nome: 'Cafelândia',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103479,
    nome: 'Cafezal do Sul',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103503,
    nome: 'Califórnia',
    microrregiao: {
      id: 41010,
      nome: 'Apucarana',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103602,
    nome: 'Cambará',
    microrregiao: {
      id: 41016,
      nome: 'Jacarezinho',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103701,
    nome: 'Cambé',
    microrregiao: {
      id: 41011,
      nome: 'Londrina',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103800,
    nome: 'Cambira',
    microrregiao: {
      id: 41010,
      nome: 'Apucarana',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103909,
    nome: 'Campina da Lagoa',
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4103958,
    nome: 'Campina do Simão',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104006,
    nome: 'Campina Grande do Sul',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104055,
    nome: 'Campo Bonito',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104105,
    nome: 'Campo do Tenente',
    microrregiao: {
      id: 41039,
      nome: 'Rio Negro',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104204,
    nome: 'Campo Largo',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104253,
    nome: 'Campo Magro',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104303,
    nome: 'Campo Mourão',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104402,
    nome: 'Cândido de Abreu',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104428,
    nome: 'Candói',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104451,
    nome: 'Cantagalo',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104501,
    nome: 'Capanema',
    microrregiao: {
      id: 41025,
      nome: 'Capanema',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104600,
    nome: 'Capitão Leônidas Marques',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104659,
    nome: 'Carambeí',
    microrregiao: {
      id: 41021,
      nome: 'Ponta Grossa',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104709,
    nome: 'Carlópolis',
    microrregiao: {
      id: 41018,
      nome: 'Wenceslau Braz',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104808,
    nome: 'Cascavel',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4104907,
    nome: 'Castro',
    microrregiao: {
      id: 41021,
      nome: 'Ponta Grossa',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4105003,
    nome: 'Catanduvas',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4105102,
    nome: 'Centenário do Sul',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4105201,
    nome: 'Cerro Azul',
    microrregiao: {
      id: 41035,
      nome: 'Cerro Azul',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4105300,
    nome: 'Céu Azul',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4105409,
    nome: 'Chopinzinho',
    microrregiao: {
      id: 41027,
      nome: 'Pato Branco',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4105508,
    nome: 'Cianorte',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4105607,
    nome: 'Cidade Gaúcha',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4105706,
    nome: 'Clevelândia',
    microrregiao: {
      id: 41030,
      nome: 'Palmas',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4105805,
    nome: 'Colombo',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4105904,
    nome: 'Colorado',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106001,
    nome: 'Congonhinhas',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106100,
    nome: 'Conselheiro Mairinck',
    microrregiao: {
      id: 41017,
      nome: 'Ibaiti',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410026,
      nome: 'Ibaiti',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106209,
    nome: 'Contenda',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106308,
    nome: 'Corbélia',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106407,
    nome: 'Cornélio Procópio',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106456,
    nome: 'Coronel Domingos Soares',
    microrregiao: {
      id: 41030,
      nome: 'Palmas',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106506,
    nome: 'Coronel Vivida',
    microrregiao: {
      id: 41027,
      nome: 'Pato Branco',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106555,
    nome: 'Corumbataí do Sul',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106571,
    nome: 'Cruzeiro do Iguaçu',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410012,
      nome: 'Dois Vizinhos',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106605,
    nome: 'Cruzeiro do Oeste',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106704,
    nome: 'Cruzeiro do Sul',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106803,
    nome: 'Cruz Machado',
    microrregiao: {
      id: 41033,
      nome: 'União da Vitória',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410003,
      nome: 'União da Vitória',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106852,
    nome: 'Cruzmaltina',
    microrregiao: {
      id: 41012,
      nome: 'Faxinal',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4106902,
    nome: 'Curitiba',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107009,
    nome: 'Curiúva',
    microrregiao: {
      id: 41017,
      nome: 'Ibaiti',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410028,
      nome: 'Telêmaco Borba',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107108,
    nome: 'Diamante do Norte',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107124,
    nome: 'Diamante do Sul',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107157,
    nome: "Diamante D'Oeste",
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107207,
    nome: 'Dois Vizinhos',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410012,
      nome: 'Dois Vizinhos',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107256,
    nome: 'Douradina',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107306,
    nome: 'Doutor Camargo',
    microrregiao: {
      id: 41008,
      nome: 'Floraí',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107405,
    nome: 'Enéas Marques',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107504,
    nome: 'Engenheiro Beltrão',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107520,
    nome: 'Esperança Nova',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107538,
    nome: 'Entre Rios do Oeste',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410013,
      nome: 'Marechal Cândido Rondon',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107546,
    nome: 'Espigão Alto do Iguaçu',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410011,
      nome: 'Laranjeiras do Sul - Quedas do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107553,
    nome: 'Farol',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107603,
    nome: 'Faxinal',
    microrregiao: {
      id: 41012,
      nome: 'Faxinal',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107652,
    nome: 'Fazenda Rio Grande',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107702,
    nome: 'Fênix',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107736,
    nome: 'Fernandes Pinheiro',
    microrregiao: {
      id: 41031,
      nome: 'Prudentópolis',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410029,
      nome: 'Irati',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107751,
    nome: 'Figueira',
    microrregiao: {
      id: 41017,
      nome: 'Ibaiti',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410026,
      nome: 'Ibaiti',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107801,
    nome: 'Floraí',
    microrregiao: {
      id: 41008,
      nome: 'Floraí',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107850,
    nome: 'Flor da Serra do Sul',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4107900,
    nome: 'Floresta',
    microrregiao: {
      id: 41008,
      nome: 'Floraí',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108007,
    nome: 'Florestópolis',
    microrregiao: {
      id: 41007,
      nome: 'Porecatu',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108106,
    nome: 'Flórida',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108205,
    nome: 'Formosa do Oeste',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108304,
    nome: 'Foz do Iguaçu',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410007,
      nome: 'Foz do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108320,
    nome: 'Francisco Alves',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108403,
    nome: 'Francisco Beltrão',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108452,
    nome: 'Foz do Jordão',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108502,
    nome: 'General Carneiro',
    microrregiao: {
      id: 41033,
      nome: 'União da Vitória',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410003,
      nome: 'União da Vitória',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108551,
    nome: 'Godoy Moreira',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108601,
    nome: 'Goioerê',
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108650,
    nome: 'Goioxim',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108700,
    nome: 'Grandes Rios',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108809,
    nome: 'Guaíra',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108908,
    nome: 'Guairaçá',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4108957,
    nome: 'Guamiranga',
    microrregiao: {
      id: 41031,
      nome: 'Prudentópolis',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109005,
    nome: 'Guapirama',
    microrregiao: {
      id: 41018,
      nome: 'Wenceslau Braz',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109104,
    nome: 'Guaporema',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109203,
    nome: 'Guaraci',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109302,
    nome: 'Guaraniaçu',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109401,
    nome: 'Guarapuava',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109500,
    nome: 'Guaraqueçaba',
    microrregiao: {
      id: 41038,
      nome: 'Paranaguá',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410002,
      nome: 'Paranaguá',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109609,
    nome: 'Guaratuba',
    microrregiao: {
      id: 41038,
      nome: 'Paranaguá',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410002,
      nome: 'Paranaguá',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109658,
    nome: 'Honório Serpa',
    microrregiao: {
      id: 41030,
      nome: 'Palmas',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109708,
    nome: 'Ibaiti',
    microrregiao: {
      id: 41017,
      nome: 'Ibaiti',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410026,
      nome: 'Ibaiti',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109757,
    nome: 'Ibema',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109807,
    nome: 'Ibiporã',
    microrregiao: {
      id: 41011,
      nome: 'Londrina',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4109906,
    nome: 'Icaraíma',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110003,
    nome: 'Iguaraçu',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110052,
    nome: 'Iguatu',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110078,
    nome: 'Imbaú',
    microrregiao: {
      id: 41019,
      nome: 'Telêmaco Borba',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410028,
      nome: 'Telêmaco Borba',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110102,
    nome: 'Imbituva',
    microrregiao: {
      id: 41031,
      nome: 'Prudentópolis',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410029,
      nome: 'Irati',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110201,
    nome: 'Inácio Martins',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110300,
    nome: 'Inajá',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110409,
    nome: 'Indianópolis',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110508,
    nome: 'Ipiranga',
    microrregiao: {
      id: 41031,
      nome: 'Prudentópolis',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110607,
    nome: 'Iporã',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110656,
    nome: 'Iracema do Oeste',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110706,
    nome: 'Irati',
    microrregiao: {
      id: 41032,
      nome: 'Irati',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410029,
      nome: 'Irati',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110805,
    nome: 'Iretama',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110904,
    nome: 'Itaguajé',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4110953,
    nome: 'Itaipulândia',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410007,
      nome: 'Foz do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111001,
    nome: 'Itambaracá',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111100,
    nome: 'Itambé',
    microrregiao: {
      id: 41008,
      nome: 'Floraí',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111209,
    nome: "Itapejara d'Oeste",
    microrregiao: {
      id: 41027,
      nome: 'Pato Branco',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111258,
    nome: 'Itaperuçu',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111308,
    nome: 'Itaúna do Sul',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111407,
    nome: 'Ivaí',
    microrregiao: {
      id: 41031,
      nome: 'Prudentópolis',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111506,
    nome: 'Ivaiporã',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111555,
    nome: 'Ivaté',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111605,
    nome: 'Ivatuba',
    microrregiao: {
      id: 41008,
      nome: 'Floraí',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111704,
    nome: 'Jaboti',
    microrregiao: {
      id: 41017,
      nome: 'Ibaiti',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410026,
      nome: 'Ibaiti',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111803,
    nome: 'Jacarezinho',
    microrregiao: {
      id: 41016,
      nome: 'Jacarezinho',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4111902,
    nome: 'Jaguapitã',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112009,
    nome: 'Jaguariaíva',
    microrregiao: {
      id: 41020,
      nome: 'Jaguariaíva',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112108,
    nome: 'Jandaia do Sul',
    microrregiao: {
      id: 41010,
      nome: 'Apucarana',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112207,
    nome: 'Janiópolis',
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112306,
    nome: 'Japira',
    microrregiao: {
      id: 41017,
      nome: 'Ibaiti',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410026,
      nome: 'Ibaiti',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112405,
    nome: 'Japurá',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112504,
    nome: 'Jardim Alegre',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112603,
    nome: 'Jardim Olinda',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112702,
    nome: 'Jataizinho',
    microrregiao: {
      id: 41014,
      nome: 'Assaí',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112751,
    nome: 'Jesuítas',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112801,
    nome: 'Joaquim Távora',
    microrregiao: {
      id: 41018,
      nome: 'Wenceslau Braz',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112900,
    nome: 'Jundiaí do Sul',
    microrregiao: {
      id: 41016,
      nome: 'Jacarezinho',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4112959,
    nome: 'Juranda',
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113007,
    nome: 'Jussara',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113106,
    nome: 'Kaloré',
    microrregiao: {
      id: 41012,
      nome: 'Faxinal',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113205,
    nome: 'Lapa',
    microrregiao: {
      id: 41036,
      nome: 'Lapa',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113254,
    nome: 'Laranjal',
    microrregiao: {
      id: 41028,
      nome: 'Pitanga',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410005,
      nome: 'Pitanga',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113304,
    nome: 'Laranjeiras do Sul',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410011,
      nome: 'Laranjeiras do Sul - Quedas do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113403,
    nome: 'Leópolis',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113429,
    nome: 'Lidianópolis',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113452,
    nome: 'Lindoeste',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113502,
    nome: 'Loanda',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410020,
      nome: 'Loanda',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113601,
    nome: 'Lobato',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113700,
    nome: 'Londrina',
    microrregiao: {
      id: 41011,
      nome: 'Londrina',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113734,
    nome: 'Luiziana',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113759,
    nome: 'Lunardelli',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113809,
    nome: 'Lupionópolis',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4113908,
    nome: 'Mallet',
    microrregiao: {
      id: 41032,
      nome: 'Irati',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410029,
      nome: 'Irati',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114005,
    nome: 'Mamborê',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114104,
    nome: 'Mandaguaçu',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114203,
    nome: 'Mandaguari',
    microrregiao: {
      id: 41009,
      nome: 'Maringá',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114302,
    nome: 'Mandirituba',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114351,
    nome: 'Manfrinópolis',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114401,
    nome: 'Mangueirinha',
    microrregiao: {
      id: 41030,
      nome: 'Palmas',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114500,
    nome: 'Manoel Ribas',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114609,
    nome: 'Marechal Cândido Rondon',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410013,
      nome: 'Marechal Cândido Rondon',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114708,
    nome: 'Maria Helena',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114807,
    nome: 'Marialva',
    microrregiao: {
      id: 41009,
      nome: 'Maringá',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4114906,
    nome: 'Marilândia do Sul',
    microrregiao: {
      id: 41010,
      nome: 'Apucarana',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115002,
    nome: 'Marilena',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115101,
    nome: 'Mariluz',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115200,
    nome: 'Maringá',
    microrregiao: {
      id: 41009,
      nome: 'Maringá',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115309,
    nome: 'Mariópolis',
    microrregiao: {
      id: 41027,
      nome: 'Pato Branco',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115358,
    nome: 'Maripá',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115408,
    nome: 'Marmeleiro',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115457,
    nome: 'Marquinho',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410011,
      nome: 'Laranjeiras do Sul - Quedas do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115507,
    nome: 'Marumbi',
    microrregiao: {
      id: 41012,
      nome: 'Faxinal',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115606,
    nome: 'Matelândia',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115705,
    nome: 'Matinhos',
    microrregiao: {
      id: 41038,
      nome: 'Paranaguá',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410002,
      nome: 'Paranaguá',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115739,
    nome: 'Mato Rico',
    microrregiao: {
      id: 41028,
      nome: 'Pitanga',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410005,
      nome: 'Pitanga',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115754,
    nome: 'Mauá da Serra',
    microrregiao: {
      id: 41010,
      nome: 'Apucarana',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115804,
    nome: 'Medianeira',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410007,
      nome: 'Foz do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115853,
    nome: 'Mercedes',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410013,
      nome: 'Marechal Cândido Rondon',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4115903,
    nome: 'Mirador',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116000,
    nome: 'Miraselva',
    microrregiao: {
      id: 41007,
      nome: 'Porecatu',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116059,
    nome: 'Missal',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410007,
      nome: 'Foz do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116109,
    nome: 'Moreira Sales',
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116208,
    nome: 'Morretes',
    microrregiao: {
      id: 41038,
      nome: 'Paranaguá',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410002,
      nome: 'Paranaguá',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116307,
    nome: 'Munhoz de Melo',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116406,
    nome: 'Nossa Senhora das Graças',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116505,
    nome: 'Nova Aliança do Ivaí',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116604,
    nome: 'Nova América da Colina',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116703,
    nome: 'Nova Aurora',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116802,
    nome: 'Nova Cantu',
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116901,
    nome: 'Nova Esperança',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4116950,
    nome: 'Nova Esperança do Sudoeste',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117008,
    nome: 'Nova Fátima',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117057,
    nome: 'Nova Laranjeiras',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410011,
      nome: 'Laranjeiras do Sul - Quedas do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117107,
    nome: 'Nova Londrina',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117206,
    nome: 'Nova Olímpia',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117214,
    nome: 'Nova Santa Bárbara',
    microrregiao: {
      id: 41014,
      nome: 'Assaí',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117222,
    nome: 'Nova Santa Rosa',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410013,
      nome: 'Marechal Cândido Rondon',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117255,
    nome: 'Nova Prata do Iguaçu',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410012,
      nome: 'Dois Vizinhos',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117271,
    nome: 'Nova Tebas',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410005,
      nome: 'Pitanga',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117297,
    nome: 'Novo Itacolomi',
    microrregiao: {
      id: 41010,
      nome: 'Apucarana',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117305,
    nome: 'Ortigueira',
    microrregiao: {
      id: 41019,
      nome: 'Telêmaco Borba',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410028,
      nome: 'Telêmaco Borba',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117404,
    nome: 'Ourizona',
    microrregiao: {
      id: 41008,
      nome: 'Floraí',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117453,
    nome: 'Ouro Verde do Oeste',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117503,
    nome: 'Paiçandu',
    microrregiao: {
      id: 41009,
      nome: 'Maringá',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117602,
    nome: 'Palmas',
    microrregiao: {
      id: 41030,
      nome: 'Palmas',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117701,
    nome: 'Palmeira',
    microrregiao: {
      id: 41021,
      nome: 'Ponta Grossa',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117800,
    nome: 'Palmital',
    microrregiao: {
      id: 41028,
      nome: 'Pitanga',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410005,
      nome: 'Pitanga',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4117909,
    nome: 'Palotina',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118006,
    nome: 'Paraíso do Norte',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118105,
    nome: 'Paranacity',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118204,
    nome: 'Paranaguá',
    microrregiao: {
      id: 41038,
      nome: 'Paranaguá',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410002,
      nome: 'Paranaguá',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118303,
    nome: 'Paranapoema',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118402,
    nome: 'Paranavaí',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118451,
    nome: 'Pato Bragado',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410013,
      nome: 'Marechal Cândido Rondon',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118501,
    nome: 'Pato Branco',
    microrregiao: {
      id: 41027,
      nome: 'Pato Branco',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118600,
    nome: 'Paula Freitas',
    microrregiao: {
      id: 41033,
      nome: 'União da Vitória',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410003,
      nome: 'União da Vitória',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118709,
    nome: 'Paulo Frontin',
    microrregiao: {
      id: 41033,
      nome: 'União da Vitória',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410003,
      nome: 'União da Vitória',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118808,
    nome: 'Peabiru',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118857,
    nome: 'Perobal',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4118907,
    nome: 'Pérola',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119004,
    nome: "Pérola d'Oeste",
    microrregiao: {
      id: 41025,
      nome: 'Capanema',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119103,
    nome: 'Piên',
    microrregiao: {
      id: 41039,
      nome: 'Rio Negro',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119152,
    nome: 'Pinhais',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119202,
    nome: 'Pinhalão',
    microrregiao: {
      id: 41017,
      nome: 'Ibaiti',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410026,
      nome: 'Ibaiti',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119251,
    nome: 'Pinhal de São Bento',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119301,
    nome: 'Pinhão',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119400,
    nome: 'Piraí do Sul',
    microrregiao: {
      id: 41020,
      nome: 'Jaguariaíva',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119509,
    nome: 'Piraquara',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119608,
    nome: 'Pitanga',
    microrregiao: {
      id: 41028,
      nome: 'Pitanga',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410005,
      nome: 'Pitanga',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119657,
    nome: 'Pitangueiras',
    microrregiao: {
      id: 41011,
      nome: 'Londrina',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119707,
    nome: 'Planaltina do Paraná',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119806,
    nome: 'Planalto',
    microrregiao: {
      id: 41025,
      nome: 'Capanema',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119905,
    nome: 'Ponta Grossa',
    microrregiao: {
      id: 41021,
      nome: 'Ponta Grossa',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4119954,
    nome: 'Pontal do Paraná',
    microrregiao: {
      id: 41038,
      nome: 'Paranaguá',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410002,
      nome: 'Paranaguá',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120002,
    nome: 'Porecatu',
    microrregiao: {
      id: 41007,
      nome: 'Porecatu',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120101,
    nome: 'Porto Amazonas',
    microrregiao: {
      id: 41036,
      nome: 'Lapa',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120150,
    nome: 'Porto Barreiro',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410011,
      nome: 'Laranjeiras do Sul - Quedas do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120200,
    nome: 'Porto Rico',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410020,
      nome: 'Loanda',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120309,
    nome: 'Porto Vitória',
    microrregiao: {
      id: 41033,
      nome: 'União da Vitória',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410003,
      nome: 'União da Vitória',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120333,
    nome: 'Prado Ferreira',
    microrregiao: {
      id: 41007,
      nome: 'Porecatu',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120358,
    nome: 'Pranchita',
    microrregiao: {
      id: 41025,
      nome: 'Capanema',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120408,
    nome: 'Presidente Castelo Branco',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120507,
    nome: 'Primeiro de Maio',
    microrregiao: {
      id: 41007,
      nome: 'Porecatu',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120606,
    nome: 'Prudentópolis',
    microrregiao: {
      id: 41031,
      nome: 'Prudentópolis',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120655,
    nome: 'Quarto Centenário',
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120705,
    nome: 'Quatiguá',
    microrregiao: {
      id: 41018,
      nome: 'Wenceslau Braz',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120804,
    nome: 'Quatro Barras',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120853,
    nome: 'Quatro Pontes',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410013,
      nome: 'Marechal Cândido Rondon',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4120903,
    nome: 'Quedas do Iguaçu',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410011,
      nome: 'Laranjeiras do Sul - Quedas do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121000,
    nome: 'Querência do Norte',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410020,
      nome: 'Loanda',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121109,
    nome: 'Quinta do Sol',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121208,
    nome: 'Quitandinha',
    microrregiao: {
      id: 41039,
      nome: 'Rio Negro',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121257,
    nome: 'Ramilândia',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121307,
    nome: 'Rancho Alegre',
    microrregiao: {
      id: 41014,
      nome: 'Assaí',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121356,
    nome: "Rancho Alegre D'Oeste",
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121406,
    nome: 'Realeza',
    microrregiao: {
      id: 41025,
      nome: 'Capanema',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121505,
    nome: 'Rebouças',
    microrregiao: {
      id: 41032,
      nome: 'Irati',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410029,
      nome: 'Irati',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121604,
    nome: 'Renascença',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121703,
    nome: 'Reserva',
    microrregiao: {
      id: 41019,
      nome: 'Telêmaco Borba',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410028,
      nome: 'Telêmaco Borba',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121752,
    nome: 'Reserva do Iguaçu',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121802,
    nome: 'Ribeirão Claro',
    microrregiao: {
      id: 41016,
      nome: 'Jacarezinho',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4121901,
    nome: 'Ribeirão do Pinhal',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122008,
    nome: 'Rio Azul',
    microrregiao: {
      id: 41032,
      nome: 'Irati',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410029,
      nome: 'Irati',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122107,
    nome: 'Rio Bom',
    microrregiao: {
      id: 41012,
      nome: 'Faxinal',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122156,
    nome: 'Rio Bonito do Iguaçu',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410011,
      nome: 'Laranjeiras do Sul - Quedas do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122172,
    nome: 'Rio Branco do Ivaí',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122206,
    nome: 'Rio Branco do Sul',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122305,
    nome: 'Rio Negro',
    microrregiao: {
      id: 41039,
      nome: 'Rio Negro',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122404,
    nome: 'Rolândia',
    microrregiao: {
      id: 41011,
      nome: 'Londrina',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122503,
    nome: 'Roncador',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122602,
    nome: 'Rondon',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122651,
    nome: 'Rosário do Ivaí',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122701,
    nome: 'Sabáudia',
    microrregiao: {
      id: 41010,
      nome: 'Apucarana',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122800,
    nome: 'Salgado Filho',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4122909,
    nome: 'Salto do Itararé',
    microrregiao: {
      id: 41018,
      nome: 'Wenceslau Braz',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123006,
    nome: 'Salto do Lontra',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410012,
      nome: 'Dois Vizinhos',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123105,
    nome: 'Santa Amélia',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123204,
    nome: 'Santa Cecília do Pavão',
    microrregiao: {
      id: 41014,
      nome: 'Assaí',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123303,
    nome: 'Santa Cruz de Monte Castelo',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410020,
      nome: 'Loanda',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123402,
    nome: 'Santa Fé',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123501,
    nome: 'Santa Helena',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123600,
    nome: 'Santa Inês',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123709,
    nome: 'Santa Isabel do Ivaí',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410020,
      nome: 'Loanda',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123808,
    nome: 'Santa Izabel do Oeste',
    microrregiao: {
      id: 41025,
      nome: 'Capanema',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123824,
    nome: 'Santa Lúcia',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123857,
    nome: 'Santa Maria do Oeste',
    microrregiao: {
      id: 41028,
      nome: 'Pitanga',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410005,
      nome: 'Pitanga',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123907,
    nome: 'Santa Mariana',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4123956,
    nome: 'Santa Mônica',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410020,
      nome: 'Loanda',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124004,
    nome: 'Santana do Itararé',
    microrregiao: {
      id: 41018,
      nome: 'Wenceslau Braz',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124020,
    nome: 'Santa Tereza do Oeste',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124053,
    nome: 'Santa Terezinha de Itaipu',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410007,
      nome: 'Foz do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124103,
    nome: 'Santo Antônio da Platina',
    microrregiao: {
      id: 41016,
      nome: 'Jacarezinho',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124202,
    nome: 'Santo Antônio do Caiuá',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124301,
    nome: 'Santo Antônio do Paraíso',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124400,
    nome: 'Santo Antônio do Sudoeste',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124509,
    nome: 'Santo Inácio',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410019,
      nome: 'Paranacity - Colorado',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124608,
    nome: 'São Carlos do Ivaí',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124707,
    nome: 'São Jerônimo da Serra',
    microrregiao: {
      id: 41014,
      nome: 'Assaí',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124806,
    nome: 'São João',
    microrregiao: {
      id: 41027,
      nome: 'Pato Branco',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4124905,
    nome: 'São João do Caiuá',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125001,
    nome: 'São João do Ivaí',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410025,
      nome: 'Ivaiporã',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125100,
    nome: 'São João do Triunfo',
    microrregiao: {
      id: 41034,
      nome: 'São Mateus do Sul',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125209,
    nome: "São Jorge d'Oeste",
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410012,
      nome: 'Dois Vizinhos',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125308,
    nome: 'São Jorge do Ivaí',
    microrregiao: {
      id: 41008,
      nome: 'Floraí',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125357,
    nome: 'São Jorge do Patrocínio',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125407,
    nome: 'São José da Boa Vista',
    microrregiao: {
      id: 41018,
      nome: 'Wenceslau Braz',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125456,
    nome: 'São José das Palmeiras',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125506,
    nome: 'São José dos Pinhais',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125555,
    nome: 'São Manoel do Paraná',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125605,
    nome: 'São Mateus do Sul',
    microrregiao: {
      id: 41034,
      nome: 'São Mateus do Sul',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410003,
      nome: 'União da Vitória',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125704,
    nome: 'São Miguel do Iguaçu',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410007,
      nome: 'Foz do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125753,
    nome: 'São Pedro do Iguaçu',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125803,
    nome: 'São Pedro do Ivaí',
    microrregiao: {
      id: 41013,
      nome: 'Ivaiporã',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410023,
      nome: 'Apucarana',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4125902,
    nome: 'São Pedro do Paraná',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410020,
      nome: 'Loanda',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126009,
    nome: 'São Sebastião da Amoreira',
    microrregiao: {
      id: 41014,
      nome: 'Assaí',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126108,
    nome: 'São Tomé',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126207,
    nome: 'Sapopema',
    microrregiao: {
      id: 41017,
      nome: 'Ibaiti',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126256,
    nome: 'Sarandi',
    microrregiao: {
      id: 41009,
      nome: 'Maringá',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126272,
    nome: 'Saudade do Iguaçu',
    microrregiao: {
      id: 41027,
      nome: 'Pato Branco',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126306,
    nome: 'Sengés',
    microrregiao: {
      id: 41020,
      nome: 'Jaguariaíva',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410027,
      nome: 'Ponta Grossa',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126355,
    nome: 'Serranópolis do Iguaçu',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410007,
      nome: 'Foz do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126405,
    nome: 'Sertaneja',
    microrregiao: {
      id: 41015,
      nome: 'Cornélio Procópio',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126504,
    nome: 'Sertanópolis',
    microrregiao: {
      id: 41007,
      nome: 'Porecatu',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126603,
    nome: 'Siqueira Campos',
    microrregiao: {
      id: 41018,
      nome: 'Wenceslau Braz',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126652,
    nome: 'Sulina',
    microrregiao: {
      id: 41027,
      nome: 'Pato Branco',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126678,
    nome: 'Tamarana',
    microrregiao: {
      id: 41011,
      nome: 'Londrina',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410021,
      nome: 'Londrina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126702,
    nome: 'Tamboara',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126801,
    nome: 'Tapejara',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4126900,
    nome: 'Tapira',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127007,
    nome: 'Teixeira Soares',
    microrregiao: {
      id: 41031,
      nome: 'Prudentópolis',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410029,
      nome: 'Irati',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127106,
    nome: 'Telêmaco Borba',
    microrregiao: {
      id: 41019,
      nome: 'Telêmaco Borba',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410028,
      nome: 'Telêmaco Borba',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127205,
    nome: 'Terra Boa',
    microrregiao: {
      id: 41005,
      nome: 'Campo Mourão',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127304,
    nome: 'Terra Rica',
    microrregiao: {
      id: 41001,
      nome: 'Paranavaí',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410017,
      nome: 'Paranavaí',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127403,
    nome: 'Terra Roxa',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127502,
    nome: 'Tibagi',
    microrregiao: {
      id: 41019,
      nome: 'Telêmaco Borba',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410028,
      nome: 'Telêmaco Borba',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127601,
    nome: 'Tijucas do Sul',
    microrregiao: {
      id: 41039,
      nome: 'Rio Negro',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127700,
    nome: 'Toledo',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127809,
    nome: 'Tomazina',
    microrregiao: {
      id: 41018,
      nome: 'Wenceslau Braz',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127858,
    nome: 'Três Barras do Paraná',
    microrregiao: {
      id: 41023,
      nome: 'Cascavel',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127882,
    nome: 'Tunas do Paraná',
    microrregiao: {
      id: 41037,
      nome: 'Curitiba',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127908,
    nome: 'Tuneiras do Oeste',
    microrregiao: {
      id: 41003,
      nome: 'Cianorte',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410018,
      nome: 'Cianorte',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127957,
    nome: 'Tupãssi',
    microrregiao: {
      id: 41022,
      nome: 'Toledo',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410008,
      nome: 'Toledo',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4127965,
    nome: 'Turvo',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410004,
      nome: 'Guarapuava',
      'regiao-intermediaria': {
        id: 4102,
        nome: 'Guarapuava',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128005,
    nome: 'Ubiratã',
    microrregiao: {
      id: 41004,
      nome: 'Goioerê',
      mesorregiao: {
        id: 4102,
        nome: 'Centro Ocidental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410015,
      nome: 'Campo Mourão',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128104,
    nome: 'Umuarama',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128203,
    nome: 'União da Vitória',
    microrregiao: {
      id: 41033,
      nome: 'União da Vitória',
      mesorregiao: {
        id: 4109,
        nome: 'Sudeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410003,
      nome: 'União da Vitória',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128302,
    nome: 'Uniflor',
    microrregiao: {
      id: 41006,
      nome: 'Astorga',
      mesorregiao: {
        id: 4103,
        nome: 'Norte Central Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410014,
      nome: 'Maringá',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128401,
    nome: 'Uraí',
    microrregiao: {
      id: 41014,
      nome: 'Assaí',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410024,
      nome: 'Cornélio Procópio - Bandeirantes',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128500,
    nome: 'Wenceslau Braz',
    microrregiao: {
      id: 41018,
      nome: 'Wenceslau Braz',
      mesorregiao: {
        id: 4104,
        nome: 'Norte Pioneiro Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410022,
      nome: 'Santo Antônio da Platina',
      'regiao-intermediaria': {
        id: 4105,
        nome: 'Londrina',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128534,
    nome: 'Ventania',
    microrregiao: {
      id: 41019,
      nome: 'Telêmaco Borba',
      mesorregiao: {
        id: 4105,
        nome: 'Centro Oriental Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410028,
      nome: 'Telêmaco Borba',
      'regiao-intermediaria': {
        id: 4106,
        nome: 'Ponta Grossa',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128559,
    nome: 'Vera Cruz do Oeste',
    microrregiao: {
      id: 41024,
      nome: 'Foz do Iguaçu',
      mesorregiao: {
        id: 4106,
        nome: 'Oeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410006,
      nome: 'Cascavel',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128609,
    nome: 'Verê',
    microrregiao: {
      id: 41026,
      nome: 'Francisco Beltrão',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410009,
      nome: 'Francisco Beltrão',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128625,
    nome: 'Alto Paraíso',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128633,
    nome: 'Doutor Ulysses',
    microrregiao: {
      id: 41035,
      nome: 'Cerro Azul',
      mesorregiao: {
        id: 4110,
        nome: 'Metropolitana de Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410001,
      nome: 'Curitiba',
      'regiao-intermediaria': {
        id: 4101,
        nome: 'Curitiba',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128658,
    nome: 'Virmond',
    microrregiao: {
      id: 41029,
      nome: 'Guarapuava',
      mesorregiao: {
        id: 4108,
        nome: 'Centro-Sul Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410011,
      nome: 'Laranjeiras do Sul - Quedas do Iguaçu',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128708,
    nome: 'Vitorino',
    microrregiao: {
      id: 41027,
      nome: 'Pato Branco',
      mesorregiao: {
        id: 4107,
        nome: 'Sudoeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410010,
      nome: 'Pato Branco',
      'regiao-intermediaria': {
        id: 4103,
        nome: 'Cascavel',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4128807,
    nome: 'Xambrê',
    microrregiao: {
      id: 41002,
      nome: 'Umuarama',
      mesorregiao: {
        id: 4101,
        nome: 'Noroeste Paranaense',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
    'regiao-imediata': {
      id: 410016,
      nome: 'Umuarama',
      'regiao-intermediaria': {
        id: 4104,
        nome: 'Maringá',
        UF: { id: 41, sigla: 'PR', nome: 'Paraná', regiao: { id: 4, sigla: 'S', nome: 'Sul' } },
      },
    },
  },
  {
    id: 4200051,
    nome: 'Abdon Batista',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200101,
    nome: 'Abelardo Luz',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200200,
    nome: 'Agrolândia',
    microrregiao: {
      id: 42014,
      nome: 'Ituporanga',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200309,
    nome: 'Agronômica',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200408,
    nome: 'Água Doce',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200507,
    nome: 'Águas de Chapecó',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200556,
    nome: 'Águas Frias',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200606,
    nome: 'Águas Mornas',
    microrregiao: {
      id: 42017,
      nome: 'Tabuleiro',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200705,
    nome: 'Alfredo Wagner',
    microrregiao: {
      id: 42017,
      nome: 'Tabuleiro',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200754,
    nome: 'Alto Bela Vista',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200804,
    nome: 'Anchieta',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4200903,
    nome: 'Angelina',
    microrregiao: {
      id: 42015,
      nome: 'Tijucas',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201000,
    nome: 'Anita Garibaldi',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201109,
    nome: 'Anitápolis',
    microrregiao: {
      id: 42017,
      nome: 'Tabuleiro',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201208,
    nome: 'Antônio Carlos',
    microrregiao: {
      id: 42016,
      nome: 'Florianópolis',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201257,
    nome: 'Apiúna',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201273,
    nome: 'Arabutã',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201307,
    nome: 'Araquari',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201406,
    nome: 'Araranguá',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201505,
    nome: 'Armazém',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201604,
    nome: 'Arroio Trinta',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420015,
      nome: 'Videira',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201653,
    nome: 'Arvoredo',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201703,
    nome: 'Ascurra',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201802,
    nome: 'Atalanta',
    microrregiao: {
      id: 42014,
      nome: 'Ituporanga',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201901,
    nome: 'Aurora',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4201950,
    nome: 'Balneário Arroio do Silva',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202008,
    nome: 'Balneário Camboriú',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202057,
    nome: 'Balneário Barra do Sul',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202073,
    nome: 'Balneário Gaivota',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202081,
    nome: 'Bandeirante',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202099,
    nome: 'Barra Bonita',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202107,
    nome: 'Barra Velha',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202131,
    nome: 'Bela Vista do Toldo',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420017,
      nome: 'Mafra',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202156,
    nome: 'Belmonte',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202206,
    nome: 'Benedito Novo',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202305,
    nome: 'Biguaçu',
    microrregiao: {
      id: 42016,
      nome: 'Florianópolis',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202404,
    nome: 'Blumenau',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202438,
    nome: 'Bocaina do Sul',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202453,
    nome: 'Bombinhas',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202503,
    nome: 'Bom Jardim da Serra',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202537,
    nome: 'Bom Jesus',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202578,
    nome: 'Bom Jesus do Oeste',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420012,
      nome: 'Maravilha',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202602,
    nome: 'Bom Retiro',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202701,
    nome: 'Botuverá',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420021,
      nome: 'Brusque',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202800,
    nome: 'Braço do Norte',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202859,
    nome: 'Braço do Trombudo',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202875,
    nome: 'Brunópolis',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420006,
      nome: 'Curitibanos',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4202909,
    nome: 'Brusque',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420021,
      nome: 'Brusque',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203006,
    nome: 'Caçador',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420014,
      nome: 'Caçador',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203105,
    nome: 'Caibi',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203154,
    nome: 'Calmon',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420014,
      nome: 'Caçador',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203204,
    nome: 'Camboriú',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203253,
    nome: 'Capão Alto',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203303,
    nome: 'Campo Alegre',
    microrregiao: {
      id: 42007,
      nome: 'São Bento do Sul',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420018,
      nome: 'São Bento do Sul - Rio Negrinho',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203402,
    nome: 'Campo Belo do Sul',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203501,
    nome: 'Campo Erê',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420013,
      nome: 'São Lourenço do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203600,
    nome: 'Campos Novos',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203709,
    nome: 'Canelinha',
    microrregiao: {
      id: 42015,
      nome: 'Tijucas',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420021,
      nome: 'Brusque',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203808,
    nome: 'Canoinhas',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420017,
      nome: 'Mafra',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203907,
    nome: 'Capinzal',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4203956,
    nome: 'Capivari de Baixo',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204004,
    nome: 'Catanduvas',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204103,
    nome: 'Caxambu do Sul',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204152,
    nome: 'Celso Ramos',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204178,
    nome: 'Cerro Negro',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204194,
    nome: 'Chapadão do Lageado',
    microrregiao: {
      id: 42014,
      nome: 'Ituporanga',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420024,
      nome: 'Ituporanga',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204202,
    nome: 'Chapecó',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204251,
    nome: 'Cocal do Sul',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204301,
    nome: 'Concórdia',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204350,
    nome: 'Cordilheira Alta',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204400,
    nome: 'Coronel Freitas',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204459,
    nome: 'Coronel Martins',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204509,
    nome: 'Corupá',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204558,
    nome: 'Correia Pinto',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204608,
    nome: 'Criciúma',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204707,
    nome: 'Cunha Porã',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420012,
      nome: 'Maravilha',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204756,
    nome: 'Cunhataí',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204806,
    nome: 'Curitibanos',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420006,
      nome: 'Curitibanos',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4204905,
    nome: 'Descanso',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205001,
    nome: 'Dionísio Cerqueira',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205100,
    nome: 'Dona Emma',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420023,
      nome: 'Ibirama - Presidente Getúlio',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205159,
    nome: 'Doutor Pedrinho',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205175,
    nome: 'Entre Rios',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205191,
    nome: 'Ermo',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205209,
    nome: 'Erval Velho',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205308,
    nome: 'Faxinal dos Guedes',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205357,
    nome: 'Flor do Sertão',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205407,
    nome: 'Florianópolis',
    microrregiao: {
      id: 42016,
      nome: 'Florianópolis',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205431,
    nome: 'Formosa do Sul',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205456,
    nome: 'Forquilhinha',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205506,
    nome: 'Fraiburgo',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420015,
      nome: 'Videira',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205555,
    nome: 'Frei Rogério',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420006,
      nome: 'Curitibanos',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205605,
    nome: 'Galvão',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420013,
      nome: 'São Lourenço do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205704,
    nome: 'Garopaba',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205803,
    nome: 'Garuva',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4205902,
    nome: 'Gaspar',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206009,
    nome: 'Governador Celso Ramos',
    microrregiao: {
      id: 42016,
      nome: 'Florianópolis',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206108,
    nome: 'Grão-Pará',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206207,
    nome: 'Gravatal',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206306,
    nome: 'Guabiruba',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420021,
      nome: 'Brusque',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206405,
    nome: 'Guaraciaba',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206504,
    nome: 'Guaramirim',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206603,
    nome: 'Guarujá do Sul',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206652,
    nome: 'Guatambú',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206702,
    nome: "Herval d'Oeste",
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206751,
    nome: 'Ibiam',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420015,
      nome: 'Videira',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206801,
    nome: 'Ibicaré',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4206900,
    nome: 'Ibirama',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420023,
      nome: 'Ibirama - Presidente Getúlio',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207007,
    nome: 'Içara',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207106,
    nome: 'Ilhota',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207205,
    nome: 'Imaruí',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207304,
    nome: 'Imbituba',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207403,
    nome: 'Imbuia',
    microrregiao: {
      id: 42014,
      nome: 'Ituporanga',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420024,
      nome: 'Ituporanga',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207502,
    nome: 'Indaial',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207577,
    nome: 'Iomerê',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420015,
      nome: 'Videira',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207601,
    nome: 'Ipira',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207650,
    nome: 'Iporã do Oeste',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207684,
    nome: 'Ipuaçu',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207700,
    nome: 'Ipumirim',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207759,
    nome: 'Iraceminha',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420012,
      nome: 'Maravilha',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207809,
    nome: 'Irani',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207858,
    nome: 'Irati',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4207908,
    nome: 'Irineópolis',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420017,
      nome: 'Mafra',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208005,
    nome: 'Itá',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208104,
    nome: 'Itaiópolis',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420017,
      nome: 'Mafra',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208203,
    nome: 'Itajaí',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208302,
    nome: 'Itapema',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208401,
    nome: 'Itapiranga',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208450,
    nome: 'Itapoá',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208500,
    nome: 'Ituporanga',
    microrregiao: {
      id: 42014,
      nome: 'Ituporanga',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420024,
      nome: 'Ituporanga',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208609,
    nome: 'Jaborá',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208708,
    nome: 'Jacinto Machado',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208807,
    nome: 'Jaguaruna',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208906,
    nome: 'Jaraguá do Sul',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4208955,
    nome: 'Jardinópolis',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209003,
    nome: 'Joaçaba',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209102,
    nome: 'Joinville',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209151,
    nome: 'José Boiteux',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420023,
      nome: 'Ibirama - Presidente Getúlio',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209177,
    nome: 'Jupiá',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420013,
      nome: 'São Lourenço do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209201,
    nome: 'Lacerdópolis',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209300,
    nome: 'Lages',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209409,
    nome: 'Laguna',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209458,
    nome: 'Lajeado Grande',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209508,
    nome: 'Laurentino',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209607,
    nome: 'Lauro Müller',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209706,
    nome: 'Lebon Régis',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420014,
      nome: 'Caçador',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209805,
    nome: 'Leoberto Leal',
    microrregiao: {
      id: 42015,
      nome: 'Tijucas',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420024,
      nome: 'Ituporanga',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209854,
    nome: 'Lindóia do Sul',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4209904,
    nome: 'Lontras',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210001,
    nome: 'Luiz Alves',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210035,
    nome: 'Luzerna',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210050,
    nome: 'Macieira',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420014,
      nome: 'Caçador',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210100,
    nome: 'Mafra',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420017,
      nome: 'Mafra',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210209,
    nome: 'Major Gercino',
    microrregiao: {
      id: 42015,
      nome: 'Tijucas',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420021,
      nome: 'Brusque',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210308,
    nome: 'Major Vieira',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420017,
      nome: 'Mafra',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210407,
    nome: 'Maracajá',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210506,
    nome: 'Maravilha',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420012,
      nome: 'Maravilha',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210555,
    nome: 'Marema',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210605,
    nome: 'Massaranduba',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210704,
    nome: 'Matos Costa',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420014,
      nome: 'Caçador',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210803,
    nome: 'Meleiro',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210852,
    nome: 'Mirim Doce',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4210902,
    nome: 'Modelo',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211009,
    nome: 'Mondaí',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211058,
    nome: 'Monte Carlo',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420015,
      nome: 'Videira',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211108,
    nome: 'Monte Castelo',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420017,
      nome: 'Mafra',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211207,
    nome: 'Morro da Fumaça',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211256,
    nome: 'Morro Grande',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211306,
    nome: 'Navegantes',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211405,
    nome: 'Nova Erechim',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211454,
    nome: 'Nova Itaberaba',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211504,
    nome: 'Nova Trento',
    microrregiao: {
      id: 42015,
      nome: 'Tijucas',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420021,
      nome: 'Brusque',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211603,
    nome: 'Nova Veneza',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211652,
    nome: 'Novo Horizonte',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420013,
      nome: 'São Lourenço do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211702,
    nome: 'Orleans',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211751,
    nome: 'Otacílio Costa',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211801,
    nome: 'Ouro',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211850,
    nome: 'Ouro Verde',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211876,
    nome: 'Paial',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211892,
    nome: 'Painel',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4211900,
    nome: 'Palhoça',
    microrregiao: {
      id: 42016,
      nome: 'Florianópolis',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212007,
    nome: 'Palma Sola',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212056,
    nome: 'Palmeira',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212106,
    nome: 'Palmitos',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212205,
    nome: 'Papanduva',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420017,
      nome: 'Mafra',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212239,
    nome: 'Paraíso',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212254,
    nome: 'Passo de Torres',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212270,
    nome: 'Passos Maia',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212304,
    nome: 'Paulo Lopes',
    microrregiao: {
      id: 42016,
      nome: 'Florianópolis',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212403,
    nome: 'Pedras Grandes',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212502,
    nome: 'Penha',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212601,
    nome: 'Peritiba',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212650,
    nome: 'Pescaria Brava',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212700,
    nome: 'Petrolândia',
    microrregiao: {
      id: 42014,
      nome: 'Ituporanga',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420024,
      nome: 'Ituporanga',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212809,
    nome: 'Balneário Piçarras',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4212908,
    nome: 'Pinhalzinho',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213005,
    nome: 'Pinheiro Preto',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420015,
      nome: 'Videira',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213104,
    nome: 'Piratuba',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213153,
    nome: 'Planalto Alegre',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213203,
    nome: 'Pomerode',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213302,
    nome: 'Ponte Alta',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213351,
    nome: 'Ponte Alta do Norte',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420006,
      nome: 'Curitibanos',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213401,
    nome: 'Ponte Serrada',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213500,
    nome: 'Porto Belo',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213609,
    nome: 'Porto União',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420017,
      nome: 'Mafra',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213708,
    nome: 'Pouso Redondo',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213807,
    nome: 'Praia Grande',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4213906,
    nome: 'Presidente Castello Branco',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214003,
    nome: 'Presidente Getúlio',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420023,
      nome: 'Ibirama - Presidente Getúlio',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214102,
    nome: 'Presidente Nereu',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214151,
    nome: 'Princesa',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214201,
    nome: 'Quilombo',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214300,
    nome: 'Rancho Queimado',
    microrregiao: {
      id: 42017,
      nome: 'Tabuleiro',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214409,
    nome: 'Rio das Antas',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420015,
      nome: 'Videira',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214508,
    nome: 'Rio do Campo',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214607,
    nome: 'Rio do Oeste',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214706,
    nome: 'Rio dos Cedros',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214805,
    nome: 'Rio do Sul',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4214904,
    nome: 'Rio Fortuna',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215000,
    nome: 'Rio Negrinho',
    microrregiao: {
      id: 42007,
      nome: 'São Bento do Sul',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420018,
      nome: 'São Bento do Sul - Rio Negrinho',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215059,
    nome: 'Rio Rufino',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215075,
    nome: 'Riqueza',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215109,
    nome: 'Rodeio',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215208,
    nome: 'Romelândia',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215307,
    nome: 'Salete',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215356,
    nome: 'Saltinho',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420012,
      nome: 'Maravilha',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215406,
    nome: 'Salto Veloso',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420015,
      nome: 'Videira',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215455,
    nome: 'Sangão',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215505,
    nome: 'Santa Cecília',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420006,
      nome: 'Curitibanos',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215554,
    nome: 'Santa Helena',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215604,
    nome: 'Santa Rosa de Lima',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215653,
    nome: 'Santa Rosa do Sul',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215679,
    nome: 'Santa Terezinha',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215687,
    nome: 'Santa Terezinha do Progresso',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420012,
      nome: 'Maravilha',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215695,
    nome: 'Santiago do Sul',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215703,
    nome: 'Santo Amaro da Imperatriz',
    microrregiao: {
      id: 42016,
      nome: 'Florianópolis',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215752,
    nome: 'São Bernardino',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420013,
      nome: 'São Lourenço do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215802,
    nome: 'São Bento do Sul',
    microrregiao: {
      id: 42007,
      nome: 'São Bento do Sul',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420018,
      nome: 'São Bento do Sul - Rio Negrinho',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4215901,
    nome: 'São Bonifácio',
    microrregiao: {
      id: 42017,
      nome: 'Tabuleiro',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216008,
    nome: 'São Carlos',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216057,
    nome: 'São Cristóvão do Sul',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420006,
      nome: 'Curitibanos',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216107,
    nome: 'São Domingos',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216206,
    nome: 'São Francisco do Sul',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216255,
    nome: 'São João do Oeste',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216305,
    nome: 'São João Batista',
    microrregiao: {
      id: 42015,
      nome: 'Tijucas',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420021,
      nome: 'Brusque',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216354,
    nome: 'São João do Itaperiú',
    microrregiao: {
      id: 42013,
      nome: 'Itajaí',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216404,
    nome: 'São João do Sul',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216503,
    nome: 'São Joaquim',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216602,
    nome: 'São José',
    microrregiao: {
      id: 42016,
      nome: 'Florianópolis',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216701,
    nome: 'São José do Cedro',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216800,
    nome: 'São José do Cerrito',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4216909,
    nome: 'São Lourenço do Oeste',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420013,
      nome: 'São Lourenço do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217006,
    nome: 'São Ludgero',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217105,
    nome: 'São Martinho',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217154,
    nome: 'São Miguel da Boa Vista',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420012,
      nome: 'Maravilha',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217204,
    nome: 'São Miguel do Oeste',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217253,
    nome: 'São Pedro de Alcântara',
    microrregiao: {
      id: 42016,
      nome: 'Florianópolis',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420001,
      nome: 'Florianópolis',
      'regiao-intermediaria': {
        id: 4201,
        nome: 'Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217303,
    nome: 'Saudades',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217402,
    nome: 'Schroeder',
    microrregiao: {
      id: 42008,
      nome: 'Joinville',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420016,
      nome: 'Joinville',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217501,
    nome: 'Seara',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420010,
      nome: 'Concórdia',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217550,
    nome: 'Serra Alta',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217600,
    nome: 'Siderópolis',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217709,
    nome: 'Sombrio',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217758,
    nome: 'Sul Brasil',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217808,
    nome: 'Taió',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217907,
    nome: 'Tangará',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420015,
      nome: 'Videira',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4217956,
    nome: 'Tigrinhos',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420012,
      nome: 'Maravilha',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218004,
    nome: 'Tijucas',
    microrregiao: {
      id: 42015,
      nome: 'Tijucas',
      mesorregiao: {
        id: 4205,
        nome: 'Grande Florianópolis',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420020,
      nome: 'Itajaí',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218103,
    nome: 'Timbé do Sul',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218202,
    nome: 'Timbó',
    microrregiao: {
      id: 42012,
      nome: 'Blumenau',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420019,
      nome: 'Blumenau',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218251,
    nome: 'Timbó Grande',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420014,
      nome: 'Caçador',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218301,
    nome: 'Três Barras',
    microrregiao: {
      id: 42006,
      nome: 'Canoinhas',
      mesorregiao: {
        id: 4202,
        nome: 'Norte Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420017,
      nome: 'Mafra',
      'regiao-intermediaria': {
        id: 4206,
        nome: 'Joinville',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218350,
    nome: 'Treviso',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218400,
    nome: 'Treze de Maio',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218509,
    nome: 'Treze Tílias',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218608,
    nome: 'Trombudo Central',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420022,
      nome: 'Rio do Sul',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218707,
    nome: 'Tubarão',
    microrregiao: {
      id: 42018,
      nome: 'Tubarão',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420003,
      nome: 'Tubarão',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218756,
    nome: 'Tunápolis',
    microrregiao: {
      id: 42001,
      nome: 'São Miguel do Oeste',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420009,
      nome: 'São Miguel do Oeste',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218806,
    nome: 'Turvo',
    microrregiao: {
      id: 42020,
      nome: 'Araranguá',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420004,
      nome: 'Araranguá',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218855,
    nome: 'União do Oeste',
    microrregiao: {
      id: 42002,
      nome: 'Chapecó',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218905,
    nome: 'Urubici',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4218954,
    nome: 'Urupema',
    microrregiao: {
      id: 42010,
      nome: 'Campos de Lages',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420005,
      nome: 'Lages',
      'regiao-intermediaria': {
        id: 4203,
        nome: 'Lages',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219002,
    nome: 'Urussanga',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219101,
    nome: 'Vargeão',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219150,
    nome: 'Vargem',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219176,
    nome: 'Vargem Bonita',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219200,
    nome: 'Vidal Ramos',
    microrregiao: {
      id: 42014,
      nome: 'Ituporanga',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420024,
      nome: 'Ituporanga',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219309,
    nome: 'Videira',
    microrregiao: {
      id: 42004,
      nome: 'Joaçaba',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420015,
      nome: 'Videira',
      'regiao-intermediaria': {
        id: 4205,
        nome: 'Caçador',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219358,
    nome: 'Vitor Meireles',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420023,
      nome: 'Ibirama - Presidente Getúlio',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219408,
    nome: 'Witmarsum',
    microrregiao: {
      id: 42011,
      nome: 'Rio do Sul',
      mesorregiao: {
        id: 4204,
        nome: 'Vale do Itajaí',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420023,
      nome: 'Ibirama - Presidente Getúlio',
      'regiao-intermediaria': {
        id: 4207,
        nome: 'Blumenau',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219507,
    nome: 'Xanxerê',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219606,
    nome: 'Xavantina',
    microrregiao: {
      id: 42005,
      nome: 'Concórdia',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420011,
      nome: 'Xanxerê',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219705,
    nome: 'Xaxim',
    microrregiao: {
      id: 42003,
      nome: 'Xanxerê',
      mesorregiao: {
        id: 4201,
        nome: 'Oeste Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420007,
      nome: 'Chapecó',
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4219853,
    nome: 'Zortéa',
    microrregiao: {
      id: 42009,
      nome: 'Curitibanos',
      mesorregiao: {
        id: 4203,
        nome: 'Serrana',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420008,
      nome: "Joaçaba - Herval d'Oeste",
      'regiao-intermediaria': {
        id: 4204,
        nome: 'Chapecó',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4220000,
    nome: 'Balneário Rincão',
    microrregiao: {
      id: 42019,
      nome: 'Criciúma',
      mesorregiao: {
        id: 4206,
        nome: 'Sul Catarinense',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 420002,
      nome: 'Criciúma',
      'regiao-intermediaria': {
        id: 4202,
        nome: 'Criciúma',
        UF: {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300034,
    nome: 'Aceguá',
    microrregiao: {
      id: 43031,
      nome: 'Campanha Meridional',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430010,
      nome: 'Bagé',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300059,
    nome: 'Água Santa',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300109,
    nome: 'Agudo',
    microrregiao: {
      id: 43019,
      nome: 'Restinga Seca',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300208,
    nome: 'Ajuricaba',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300307,
    nome: 'Alecrim',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300406,
    nome: 'Alegrete',
    microrregiao: {
      id: 43029,
      nome: 'Campanha Ocidental',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430015,
      nome: 'Uruguaiana',
      'regiao-intermediaria': {
        id: 4304,
        nome: 'Uruguaiana',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300455,
    nome: 'Alegria',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430023,
      nome: 'Três de Maio',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300471,
    nome: 'Almirante Tamandaré do Sul',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300505,
    nome: 'Alpestre',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430035,
      nome: 'Nonoai',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300554,
    nome: 'Alto Alegre',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300570,
    nome: 'Alto Feliz',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300604,
    nome: 'Alvorada',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300638,
    nome: 'Amaral Ferrador',
    microrregiao: {
      id: 43032,
      nome: 'Serras de Sudeste',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430005,
      nome: 'Camaquã',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300646,
    nome: 'Ametista do Sul',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300661,
    nome: 'André da Rocha',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300703,
    nome: 'Anta Gorda',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430043,
      nome: 'Encantado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300802,
    nome: 'Antônio Prado',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300851,
    nome: 'Arambaré',
    microrregiao: {
      id: 43028,
      nome: 'Camaquã',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430005,
      nome: 'Camaquã',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300877,
    nome: 'Araricá',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4300901,
    nome: 'Aratiba',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301008,
    nome: 'Arroio do Meio',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301057,
    nome: 'Arroio do Sal',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430008,
      nome: 'Torres',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301073,
    nome: 'Arroio do Padre',
    microrregiao: {
      id: 43033,
      nome: 'Pelotas',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301107,
    nome: 'Arroio dos Ratos',
    microrregiao: {
      id: 43025,
      nome: 'São Jerônimo',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301206,
    nome: 'Arroio do Tigre',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430042,
      nome: 'Sobradinho',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301305,
    nome: 'Arroio Grande',
    microrregiao: {
      id: 43034,
      nome: 'Jaguarão',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301404,
    nome: 'Arvorezinha',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430031,
      nome: 'Soledade',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301503,
    nome: 'Augusto Pestana',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301552,
    nome: 'Áurea',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301602,
    nome: 'Bagé',
    microrregiao: {
      id: 43031,
      nome: 'Campanha Meridional',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430010,
      nome: 'Bagé',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301636,
    nome: 'Balneário Pinhal',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430003,
      nome: 'Tramandaí - Osório',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301651,
    nome: 'Barão',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301701,
    nome: 'Barão de Cotegipe',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301750,
    nome: 'Barão do Triunfo',
    microrregiao: {
      id: 43025,
      nome: 'São Jerônimo',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301800,
    nome: 'Barracão',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430033,
      nome: 'Lagoa Vermelha',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301859,
    nome: 'Barra do Guarita',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301875,
    nome: 'Barra do Quaraí',
    microrregiao: {
      id: 43029,
      nome: 'Campanha Ocidental',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430015,
      nome: 'Uruguaiana',
      'regiao-intermediaria': {
        id: 4304,
        nome: 'Uruguaiana',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301909,
    nome: 'Barra do Ribeiro',
    microrregiao: {
      id: 43028,
      nome: 'Camaquã',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301925,
    nome: 'Barra do Rio Azul',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4301958,
    nome: 'Barra Funda',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302006,
    nome: 'Barros Cassal',
    microrregiao: {
      id: 43013,
      nome: 'Soledade',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430031,
      nome: 'Soledade',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302055,
    nome: 'Benjamin Constant do Sul',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302105,
    nome: 'Bento Gonçalves',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302154,
    nome: 'Boa Vista das Missões',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302204,
    nome: 'Boa Vista do Buricá',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430023,
      nome: 'Três de Maio',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302220,
    nome: 'Boa Vista do Cadeado',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302238,
    nome: 'Boa Vista do Incra',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302253,
    nome: 'Boa Vista do Sul',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302303,
    nome: 'Bom Jesus',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430039,
      nome: 'Vacaria',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302352,
    nome: 'Bom Princípio',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302378,
    nome: 'Bom Progresso',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302402,
    nome: 'Bom Retiro do Sul',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302451,
    nome: 'Boqueirão do Leão',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302501,
    nome: 'Bossoroca',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430022,
      nome: 'São Luiz Gonzaga',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302584,
    nome: 'Bozano',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302600,
    nome: 'Braga',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302659,
    nome: 'Brochier',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430007,
      nome: 'Montenegro',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302709,
    nome: 'Butiá',
    microrregiao: {
      id: 43025,
      nome: 'São Jerônimo',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430006,
      nome: 'Charqueadas - Triunfo - São Jerônimo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302808,
    nome: 'Caçapava do Sul',
    microrregiao: {
      id: 43032,
      nome: 'Serras de Sudeste',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430012,
      nome: 'São Gabriel - Caçapava do Sul',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4302907,
    nome: 'Cacequi',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303004,
    nome: 'Cachoeira do Sul',
    microrregiao: {
      id: 43022,
      nome: 'Cachoeira do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430013,
      nome: 'Cachoeira do Sul',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303103,
    nome: 'Cachoeirinha',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303202,
    nome: 'Cacique Doble',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430033,
      nome: 'Lagoa Vermelha',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303301,
    nome: 'Caibaté',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430022,
      nome: 'São Luiz Gonzaga',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303400,
    nome: 'Caiçara',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303509,
    nome: 'Camaquã',
    microrregiao: {
      id: 43028,
      nome: 'Camaquã',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430005,
      nome: 'Camaquã',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303558,
    nome: 'Camargo',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303608,
    nome: 'Cambará do Sul',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303673,
    nome: 'Campestre da Serra',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303707,
    nome: 'Campina das Missões',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303806,
    nome: 'Campinas do Sul',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4303905,
    nome: 'Campo Bom',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304002,
    nome: 'Campo Novo',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304101,
    nome: 'Campos Borges',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304200,
    nome: 'Candelária',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304309,
    nome: 'Cândido Godói',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304358,
    nome: 'Candiota',
    microrregiao: {
      id: 43032,
      nome: 'Serras de Sudeste',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430010,
      nome: 'Bagé',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304408,
    nome: 'Canela',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304507,
    nome: 'Canguçu',
    microrregiao: {
      id: 43033,
      nome: 'Pelotas',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304606,
    nome: 'Canoas',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304614,
    nome: 'Canudos do Vale',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304622,
    nome: 'Capão Bonito do Sul',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430033,
      nome: 'Lagoa Vermelha',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304630,
    nome: 'Capão da Canoa',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430003,
      nome: 'Tramandaí - Osório',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304655,
    nome: 'Capão do Cipó',
    microrregiao: {
      id: 43017,
      nome: 'Santiago',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430014,
      nome: 'Santiago',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304663,
    nome: 'Capão do Leão',
    microrregiao: {
      id: 43033,
      nome: 'Pelotas',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304671,
    nome: 'Capivari do Sul',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304689,
    nome: 'Capela de Santana',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304697,
    nome: 'Capitão',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304705,
    nome: 'Carazinho',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304713,
    nome: 'Caraá',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304804,
    nome: 'Carlos Barbosa',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304853,
    nome: 'Carlos Gomes',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304903,
    nome: 'Casca',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4304952,
    nome: 'Caseiros',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430033,
      nome: 'Lagoa Vermelha',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305009,
    nome: 'Catuípe',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305108,
    nome: 'Caxias do Sul',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305116,
    nome: 'Centenário',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305124,
    nome: 'Cerrito',
    microrregiao: {
      id: 43033,
      nome: 'Pelotas',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305132,
    nome: 'Cerro Branco',
    microrregiao: {
      id: 43022,
      nome: 'Cachoeira do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430013,
      nome: 'Cachoeira do Sul',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305157,
    nome: 'Cerro Grande',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430034,
      nome: 'Palmeira das Missões',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305173,
    nome: 'Cerro Grande do Sul',
    microrregiao: {
      id: 43028,
      nome: 'Camaquã',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430005,
      nome: 'Camaquã',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305207,
    nome: 'Cerro Largo',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430024,
      nome: 'Cerro Largo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305306,
    nome: 'Chapada',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305355,
    nome: 'Charqueadas',
    microrregiao: {
      id: 43025,
      nome: 'São Jerônimo',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430006,
      nome: 'Charqueadas - Triunfo - São Jerônimo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305371,
    nome: 'Charrua',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305405,
    nome: 'Chiapetta',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305439,
    nome: 'Chuí',
    microrregiao: {
      id: 43035,
      nome: 'Litoral Lagunar',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305447,
    nome: 'Chuvisca',
    microrregiao: {
      id: 43028,
      nome: 'Camaquã',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430005,
      nome: 'Camaquã',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305454,
    nome: 'Cidreira',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430003,
      nome: 'Tramandaí - Osório',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305504,
    nome: 'Ciríaco',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305587,
    nome: 'Colinas',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305603,
    nome: 'Colorado',
    microrregiao: {
      id: 43012,
      nome: 'Não-Me-Toque',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305702,
    nome: 'Condor',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305801,
    nome: 'Constantina',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305835,
    nome: 'Coqueiro Baixo',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305850,
    nome: 'Coqueiros do Sul',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305871,
    nome: 'Coronel Barros',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305900,
    nome: 'Coronel Bicaco',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305934,
    nome: 'Coronel Pilar',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305959,
    nome: 'Cotiporã',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4305975,
    nome: 'Coxilha',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306007,
    nome: 'Crissiumal',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306056,
    nome: 'Cristal',
    microrregiao: {
      id: 43033,
      nome: 'Pelotas',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430005,
      nome: 'Camaquã',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306072,
    nome: 'Cristal do Sul',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306106,
    nome: 'Cruz Alta',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306130,
    nome: 'Cruzaltense',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306205,
    nome: 'Cruzeiro do Sul',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306304,
    nome: 'David Canabarro',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306320,
    nome: 'Derrubadas',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306353,
    nome: 'Dezesseis de Novembro',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430022,
      nome: 'São Luiz Gonzaga',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306379,
    nome: 'Dilermando de Aguiar',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306403,
    nome: 'Dois Irmãos',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306429,
    nome: 'Dois Irmãos das Missões',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430034,
      nome: 'Palmeira das Missões',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306452,
    nome: 'Dois Lajeados',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306502,
    nome: 'Dom Feliciano',
    microrregiao: {
      id: 43028,
      nome: 'Camaquã',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430005,
      nome: 'Camaquã',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306551,
    nome: 'Dom Pedro de Alcântara',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430008,
      nome: 'Torres',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306601,
    nome: 'Dom Pedrito',
    microrregiao: {
      id: 43031,
      nome: 'Campanha Meridional',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430010,
      nome: 'Bagé',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306700,
    nome: 'Dona Francisca',
    microrregiao: {
      id: 43019,
      nome: 'Restinga Seca',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306734,
    nome: 'Doutor Maurício Cardoso',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430023,
      nome: 'Três de Maio',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306759,
    nome: 'Doutor Ricardo',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430043,
      nome: 'Encantado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306767,
    nome: 'Eldorado do Sul',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306809,
    nome: 'Encantado',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430043,
      nome: 'Encantado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306908,
    nome: 'Encruzilhada do Sul',
    microrregiao: {
      id: 43032,
      nome: 'Serras de Sudeste',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306924,
    nome: 'Engenho Velho',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306932,
    nome: 'Entre-Ijuís',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430020,
      nome: 'Santo Ângelo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306957,
    nome: 'Entre Rios do Sul',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4306973,
    nome: 'Erebango',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307005,
    nome: 'Erechim',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307054,
    nome: 'Ernestina',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307104,
    nome: 'Herval',
    microrregiao: {
      id: 43034,
      nome: 'Jaguarão',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307203,
    nome: 'Erval Grande',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307302,
    nome: 'Erval Seco',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307401,
    nome: 'Esmeralda',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430039,
      nome: 'Vacaria',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307450,
    nome: 'Esperança do Sul',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307500,
    nome: 'Espumoso',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307559,
    nome: 'Estação',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307609,
    nome: 'Estância Velha',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307708,
    nome: 'Esteio',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307807,
    nome: 'Estrela',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307815,
    nome: 'Estrela Velha',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430042,
      nome: 'Sobradinho',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307831,
    nome: 'Eugênio de Castro',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430020,
      nome: 'Santo Ângelo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307864,
    nome: 'Fagundes Varela',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4307906,
    nome: 'Farroupilha',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308003,
    nome: 'Faxinal do Soturno',
    microrregiao: {
      id: 43019,
      nome: 'Restinga Seca',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308052,
    nome: 'Faxinalzinho',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308078,
    nome: 'Fazenda Vilanova',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308102,
    nome: 'Feliz',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308201,
    nome: 'Flores da Cunha',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308250,
    nome: 'Floriano Peixoto',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308300,
    nome: 'Fontoura Xavier',
    microrregiao: {
      id: 43013,
      nome: 'Soledade',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430031,
      nome: 'Soledade',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308409,
    nome: 'Formigueiro',
    microrregiao: {
      id: 43019,
      nome: 'Restinga Seca',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308433,
    nome: 'Forquetinha',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308458,
    nome: 'Fortaleza dos Valos',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308508,
    nome: 'Frederico Westphalen',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308607,
    nome: 'Garibaldi',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308656,
    nome: 'Garruchos',
    microrregiao: {
      id: 43029,
      nome: 'Campanha Ocidental',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430022,
      nome: 'São Luiz Gonzaga',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308706,
    nome: 'Gaurama',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308805,
    nome: 'General Câmara',
    microrregiao: {
      id: 43025,
      nome: 'São Jerônimo',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430006,
      nome: 'Charqueadas - Triunfo - São Jerônimo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308854,
    nome: 'Gentil',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4308904,
    nome: 'Getúlio Vargas',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309001,
    nome: 'Giruá',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430020,
      nome: 'Santo Ângelo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309050,
    nome: 'Glorinha',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309100,
    nome: 'Gramado',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309126,
    nome: 'Gramado dos Loureiros',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430035,
      nome: 'Nonoai',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309159,
    nome: 'Gramado Xavier',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309209,
    nome: 'Gravataí',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309258,
    nome: 'Guabiju',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309308,
    nome: 'Guaíba',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309407,
    nome: 'Guaporé',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309506,
    nome: 'Guarani das Missões',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430020,
      nome: 'Santo Ângelo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309555,
    nome: 'Harmonia',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309571,
    nome: 'Herveiras',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309605,
    nome: 'Horizontina',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430023,
      nome: 'Três de Maio',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309654,
    nome: 'Hulha Negra',
    microrregiao: {
      id: 43031,
      nome: 'Campanha Meridional',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430010,
      nome: 'Bagé',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309704,
    nome: 'Humaitá',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309753,
    nome: 'Ibarama',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430042,
      nome: 'Sobradinho',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309803,
    nome: 'Ibiaçá',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309902,
    nome: 'Ibiraiaras',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430033,
      nome: 'Lagoa Vermelha',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4309951,
    nome: 'Ibirapuitã',
    microrregiao: {
      id: 43013,
      nome: 'Soledade',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430031,
      nome: 'Soledade',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310009,
    nome: 'Ibirubá',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310108,
    nome: 'Igrejinha',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430004,
      nome: 'Taquara - Parobé - Igrejinha',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310207,
    nome: 'Ijuí',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310306,
    nome: 'Ilópolis',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430043,
      nome: 'Encantado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310330,
    nome: 'Imbé',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430003,
      nome: 'Tramandaí - Osório',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310363,
    nome: 'Imigrante',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310405,
    nome: 'Independência',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430023,
      nome: 'Três de Maio',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310413,
    nome: 'Inhacorá',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310439,
    nome: 'Ipê',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310462,
    nome: 'Ipiranga do Sul',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310504,
    nome: 'Iraí',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310538,
    nome: 'Itaara',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310553,
    nome: 'Itacurubi',
    microrregiao: {
      id: 43017,
      nome: 'Santiago',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430014,
      nome: 'Santiago',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310579,
    nome: 'Itapuca',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430031,
      nome: 'Soledade',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310603,
    nome: 'Itaqui',
    microrregiao: {
      id: 43029,
      nome: 'Campanha Ocidental',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430017,
      nome: 'São Borja',
      'regiao-intermediaria': {
        id: 4304,
        nome: 'Uruguaiana',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310652,
    nome: 'Itati',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430003,
      nome: 'Tramandaí - Osório',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310702,
    nome: 'Itatiba do Sul',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310751,
    nome: 'Ivorá',
    microrregiao: {
      id: 43019,
      nome: 'Restinga Seca',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310801,
    nome: 'Ivoti',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310850,
    nome: 'Jaboticaba',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310876,
    nome: 'Jacuizinho',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4310900,
    nome: 'Jacutinga',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311007,
    nome: 'Jaguarão',
    microrregiao: {
      id: 43034,
      nome: 'Jaguarão',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311106,
    nome: 'Jaguari',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311122,
    nome: 'Jaquirana',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430039,
      nome: 'Vacaria',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311130,
    nome: 'Jari',
    microrregiao: {
      id: 43017,
      nome: 'Santiago',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311155,
    nome: 'Jóia',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311205,
    nome: 'Júlio de Castilhos',
    microrregiao: {
      id: 43017,
      nome: 'Santiago',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311239,
    nome: 'Lagoa Bonita do Sul',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430042,
      nome: 'Sobradinho',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311254,
    nome: 'Lagoão',
    microrregiao: {
      id: 43013,
      nome: 'Soledade',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430042,
      nome: 'Sobradinho',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311270,
    nome: 'Lagoa dos Três Cantos',
    microrregiao: {
      id: 43012,
      nome: 'Não-Me-Toque',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311304,
    nome: 'Lagoa Vermelha',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430033,
      nome: 'Lagoa Vermelha',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311403,
    nome: 'Lajeado',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311429,
    nome: 'Lajeado do Bugre',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430034,
      nome: 'Palmeira das Missões',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311502,
    nome: 'Lavras do Sul',
    microrregiao: {
      id: 43031,
      nome: 'Campanha Meridional',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430012,
      nome: 'São Gabriel - Caçapava do Sul',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311601,
    nome: 'Liberato Salzano',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311627,
    nome: 'Lindolfo Collor',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311643,
    nome: 'Linha Nova',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311700,
    nome: 'Machadinho',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430033,
      nome: 'Lagoa Vermelha',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311718,
    nome: 'Maçambará',
    microrregiao: {
      id: 43029,
      nome: 'Campanha Ocidental',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430017,
      nome: 'São Borja',
      'regiao-intermediaria': {
        id: 4304,
        nome: 'Uruguaiana',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311734,
    nome: 'Mampituba',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430008,
      nome: 'Torres',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311759,
    nome: 'Manoel Viana',
    microrregiao: {
      id: 43029,
      nome: 'Campanha Ocidental',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430015,
      nome: 'Uruguaiana',
      'regiao-intermediaria': {
        id: 4304,
        nome: 'Uruguaiana',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311775,
    nome: 'Maquiné',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430003,
      nome: 'Tramandaí - Osório',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311791,
    nome: 'Maratá',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430007,
      nome: 'Montenegro',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311809,
    nome: 'Marau',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311908,
    nome: 'Marcelino Ramos',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4311981,
    nome: 'Mariana Pimentel',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312005,
    nome: 'Mariano Moro',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312054,
    nome: 'Marques de Souza',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312104,
    nome: 'Mata',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312138,
    nome: 'Mato Castelhano',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312153,
    nome: 'Mato Leitão',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312179,
    nome: 'Mato Queimado',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430024,
      nome: 'Cerro Largo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312203,
    nome: 'Maximiliano de Almeida',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312252,
    nome: 'Minas do Leão',
    microrregiao: {
      id: 43025,
      nome: 'São Jerônimo',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430006,
      nome: 'Charqueadas - Triunfo - São Jerônimo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312302,
    nome: 'Miraguaí',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312351,
    nome: 'Montauri',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312377,
    nome: 'Monte Alegre dos Campos',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430039,
      nome: 'Vacaria',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312385,
    nome: 'Monte Belo do Sul',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312401,
    nome: 'Montenegro',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430007,
      nome: 'Montenegro',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312427,
    nome: 'Mormaço',
    microrregiao: {
      id: 43013,
      nome: 'Soledade',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430031,
      nome: 'Soledade',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312443,
    nome: 'Morrinhos do Sul',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430008,
      nome: 'Torres',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312450,
    nome: 'Morro Redondo',
    microrregiao: {
      id: 43033,
      nome: 'Pelotas',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312476,
    nome: 'Morro Reuter',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312500,
    nome: 'Mostardas',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312609,
    nome: 'Muçum',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430043,
      nome: 'Encantado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312617,
    nome: 'Muitos Capões',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430039,
      nome: 'Vacaria',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312625,
    nome: 'Muliterno',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312658,
    nome: 'Não-Me-Toque',
    microrregiao: {
      id: 43012,
      nome: 'Não-Me-Toque',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312674,
    nome: 'Nicolau Vergueiro',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312708,
    nome: 'Nonoai',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430035,
      nome: 'Nonoai',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312757,
    nome: 'Nova Alvorada',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312807,
    nome: 'Nova Araçá',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312906,
    nome: 'Nova Bassano',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4312955,
    nome: 'Nova Boa Vista',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313003,
    nome: 'Nova Bréscia',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430043,
      nome: 'Encantado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313011,
    nome: 'Nova Candelária',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430023,
      nome: 'Três de Maio',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313037,
    nome: 'Nova Esperança do Sul',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430014,
      nome: 'Santiago',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313060,
    nome: 'Nova Hartz',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313086,
    nome: 'Nova Pádua',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313102,
    nome: 'Nova Palma',
    microrregiao: {
      id: 43019,
      nome: 'Restinga Seca',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313201,
    nome: 'Nova Petrópolis',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313300,
    nome: 'Nova Prata',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313334,
    nome: 'Nova Ramada',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313359,
    nome: 'Nova Roma do Sul',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313375,
    nome: 'Nova Santa Rita',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313391,
    nome: 'Novo Cabrais',
    microrregiao: {
      id: 43022,
      nome: 'Cachoeira do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430013,
      nome: 'Cachoeira do Sul',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313409,
    nome: 'Novo Hamburgo',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313425,
    nome: 'Novo Machado',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313441,
    nome: 'Novo Tiradentes',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313466,
    nome: 'Novo Xingu',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313490,
    nome: 'Novo Barreiro',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430034,
      nome: 'Palmeira das Missões',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313508,
    nome: 'Osório',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430003,
      nome: 'Tramandaí - Osório',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313607,
    nome: 'Paim Filho',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313656,
    nome: 'Palmares do Sul',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313706,
    nome: 'Palmeira das Missões',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430034,
      nome: 'Palmeira das Missões',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313805,
    nome: 'Palmitinho',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313904,
    nome: 'Panambi',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4313953,
    nome: 'Pantano Grande',
    microrregiao: {
      id: 43022,
      nome: 'Cachoeira do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314001,
    nome: 'Paraí',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314027,
    nome: 'Paraíso do Sul',
    microrregiao: {
      id: 43022,
      nome: 'Cachoeira do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430013,
      nome: 'Cachoeira do Sul',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314035,
    nome: 'Pareci Novo',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430007,
      nome: 'Montenegro',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314050,
    nome: 'Parobé',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430004,
      nome: 'Taquara - Parobé - Igrejinha',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314068,
    nome: 'Passa Sete',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430042,
      nome: 'Sobradinho',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314076,
    nome: 'Passo do Sobrado',
    microrregiao: {
      id: 43022,
      nome: 'Cachoeira do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314100,
    nome: 'Passo Fundo',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314134,
    nome: 'Paulo Bento',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314159,
    nome: 'Paverama',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314175,
    nome: 'Pedras Altas',
    microrregiao: {
      id: 43032,
      nome: 'Serras de Sudeste',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430010,
      nome: 'Bagé',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314209,
    nome: 'Pedro Osório',
    microrregiao: {
      id: 43033,
      nome: 'Pelotas',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314308,
    nome: 'Pejuçara',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314407,
    nome: 'Pelotas',
    microrregiao: {
      id: 43033,
      nome: 'Pelotas',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314423,
    nome: 'Picada Café',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314456,
    nome: 'Pinhal',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314464,
    nome: 'Pinhal da Serra',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430039,
      nome: 'Vacaria',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314472,
    nome: 'Pinhal Grande',
    microrregiao: {
      id: 43017,
      nome: 'Santiago',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314498,
    nome: 'Pinheirinho do Vale',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314506,
    nome: 'Pinheiro Machado',
    microrregiao: {
      id: 43032,
      nome: 'Serras de Sudeste',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430010,
      nome: 'Bagé',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314548,
    nome: 'Pinto Bandeira',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314555,
    nome: 'Pirapó',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430022,
      nome: 'São Luiz Gonzaga',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314605,
    nome: 'Piratini',
    microrregiao: {
      id: 43032,
      nome: 'Serras de Sudeste',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314704,
    nome: 'Planalto',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430035,
      nome: 'Nonoai',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314753,
    nome: 'Poço das Antas',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314779,
    nome: 'Pontão',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314787,
    nome: 'Ponte Preta',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314803,
    nome: 'Portão',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4314902,
    nome: 'Porto Alegre',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315008,
    nome: 'Porto Lucena',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315057,
    nome: 'Porto Mauá',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315073,
    nome: 'Porto Vera Cruz',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315107,
    nome: 'Porto Xavier',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430024,
      nome: 'Cerro Largo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315131,
    nome: 'Pouso Novo',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315149,
    nome: 'Presidente Lucena',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315156,
    nome: 'Progresso',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315172,
    nome: 'Protásio Alves',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315206,
    nome: 'Putinga',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430043,
      nome: 'Encantado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315305,
    nome: 'Quaraí',
    microrregiao: {
      id: 43029,
      nome: 'Campanha Ocidental',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430016,
      nome: 'Santana do Livramento',
      'regiao-intermediaria': {
        id: 4304,
        nome: 'Uruguaiana',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315313,
    nome: 'Quatro Irmãos',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315321,
    nome: 'Quevedos',
    microrregiao: {
      id: 43017,
      nome: 'Santiago',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315354,
    nome: 'Quinze de Novembro',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315404,
    nome: 'Redentora',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315453,
    nome: 'Relvado',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430043,
      nome: 'Encantado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315503,
    nome: 'Restinga Sêca',
    microrregiao: {
      id: 43019,
      nome: 'Restinga Seca',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315552,
    nome: 'Rio dos Índios',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430035,
      nome: 'Nonoai',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315602,
    nome: 'Rio Grande',
    microrregiao: {
      id: 43035,
      nome: 'Litoral Lagunar',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315701,
    nome: 'Rio Pardo',
    microrregiao: {
      id: 43022,
      nome: 'Cachoeira do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315750,
    nome: 'Riozinho',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430004,
      nome: 'Taquara - Parobé - Igrejinha',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315800,
    nome: 'Roca Sales',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430043,
      nome: 'Encantado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315909,
    nome: 'Rodeio Bonito',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4315958,
    nome: 'Rolador',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430022,
      nome: 'São Luiz Gonzaga',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316006,
    nome: 'Rolante',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430004,
      nome: 'Taquara - Parobé - Igrejinha',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316105,
    nome: 'Ronda Alta',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316204,
    nome: 'Rondinha',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316303,
    nome: 'Roque Gonzales',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430024,
      nome: 'Cerro Largo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316402,
    nome: 'Rosário do Sul',
    microrregiao: {
      id: 43030,
      nome: 'Campanha Central',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430016,
      nome: 'Santana do Livramento',
      'regiao-intermediaria': {
        id: 4304,
        nome: 'Uruguaiana',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316428,
    nome: 'Sagrada Família',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430034,
      nome: 'Palmeira das Missões',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316436,
    nome: 'Saldanha Marinho',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316451,
    nome: 'Salto do Jacuí',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316477,
    nome: 'Salvador das Missões',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430024,
      nome: 'Cerro Largo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316501,
    nome: 'Salvador do Sul',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430007,
      nome: 'Montenegro',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316600,
    nome: 'Sananduva',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316709,
    nome: 'Santa Bárbara do Sul',
    microrregiao: {
      id: 43011,
      nome: 'Cruz Alta',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316733,
    nome: 'Santa Cecília do Sul',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316758,
    nome: 'Santa Clara do Sul',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316808,
    nome: 'Santa Cruz do Sul',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316907,
    nome: 'Santa Maria',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316956,
    nome: 'Santa Maria do Herval',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4316972,
    nome: 'Santa Margarida do Sul',
    microrregiao: {
      id: 43030,
      nome: 'Campanha Central',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430012,
      nome: 'São Gabriel - Caçapava do Sul',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317004,
    nome: 'Santana da Boa Vista',
    microrregiao: {
      id: 43032,
      nome: 'Serras de Sudeste',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430012,
      nome: 'São Gabriel - Caçapava do Sul',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317103,
    nome: "Sant'Ana do Livramento",
    microrregiao: {
      id: 43030,
      nome: 'Campanha Central',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430016,
      nome: 'Santana do Livramento',
      'regiao-intermediaria': {
        id: 4304,
        nome: 'Uruguaiana',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317202,
    nome: 'Santa Rosa',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317251,
    nome: 'Santa Tereza',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317301,
    nome: 'Santa Vitória do Palmar',
    microrregiao: {
      id: 43035,
      nome: 'Litoral Lagunar',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317400,
    nome: 'Santiago',
    microrregiao: {
      id: 43017,
      nome: 'Santiago',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430014,
      nome: 'Santiago',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317509,
    nome: 'Santo Ângelo',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430020,
      nome: 'Santo Ângelo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317558,
    nome: 'Santo Antônio do Palma',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317608,
    nome: 'Santo Antônio da Patrulha',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317707,
    nome: 'Santo Antônio das Missões',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430022,
      nome: 'São Luiz Gonzaga',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317756,
    nome: 'Santo Antônio do Planalto',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317806,
    nome: 'Santo Augusto',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317905,
    nome: 'Santo Cristo',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4317954,
    nome: 'Santo Expedito do Sul',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318002,
    nome: 'São Borja',
    microrregiao: {
      id: 43029,
      nome: 'Campanha Ocidental',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430017,
      nome: 'São Borja',
      'regiao-intermediaria': {
        id: 4304,
        nome: 'Uruguaiana',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318051,
    nome: 'São Domingos do Sul',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318101,
    nome: 'São Francisco de Assis',
    microrregiao: {
      id: 43029,
      nome: 'Campanha Ocidental',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318200,
    nome: 'São Francisco de Paula',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318309,
    nome: 'São Gabriel',
    microrregiao: {
      id: 43030,
      nome: 'Campanha Central',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430012,
      nome: 'São Gabriel - Caçapava do Sul',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318408,
    nome: 'São Jerônimo',
    microrregiao: {
      id: 43025,
      nome: 'São Jerônimo',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430006,
      nome: 'Charqueadas - Triunfo - São Jerônimo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318424,
    nome: 'São João da Urtiga',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318432,
    nome: 'São João do Polêsine',
    microrregiao: {
      id: 43019,
      nome: 'Restinga Seca',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318440,
    nome: 'São Jorge',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318457,
    nome: 'São José das Missões',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430034,
      nome: 'Palmeira das Missões',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318465,
    nome: 'São José do Herval',
    microrregiao: {
      id: 43013,
      nome: 'Soledade',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430031,
      nome: 'Soledade',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318481,
    nome: 'São José do Hortêncio',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318499,
    nome: 'São José do Inhacorá',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430023,
      nome: 'Três de Maio',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318507,
    nome: 'São José do Norte',
    microrregiao: {
      id: 43035,
      nome: 'Litoral Lagunar',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318606,
    nome: 'São José do Ouro',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430033,
      nome: 'Lagoa Vermelha',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318614,
    nome: 'São José do Sul',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430007,
      nome: 'Montenegro',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318622,
    nome: 'São José dos Ausentes',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430039,
      nome: 'Vacaria',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318705,
    nome: 'São Leopoldo',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318804,
    nome: 'São Lourenço do Sul',
    microrregiao: {
      id: 43033,
      nome: 'Pelotas',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4318903,
    nome: 'São Luiz Gonzaga',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430022,
      nome: 'São Luiz Gonzaga',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319000,
    nome: 'São Marcos',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319109,
    nome: 'São Martinho',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319125,
    nome: 'São Martinho da Serra',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319158,
    nome: 'São Miguel das Missões',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430020,
      nome: 'Santo Ângelo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319208,
    nome: 'São Nicolau',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430022,
      nome: 'São Luiz Gonzaga',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319307,
    nome: 'São Paulo das Missões',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430024,
      nome: 'Cerro Largo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319356,
    nome: 'São Pedro da Serra',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430007,
      nome: 'Montenegro',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319364,
    nome: 'São Pedro das Missões',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430034,
      nome: 'Palmeira das Missões',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319372,
    nome: 'São Pedro do Butiá',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430024,
      nome: 'Cerro Largo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319406,
    nome: 'São Pedro do Sul',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319505,
    nome: 'São Sebastião do Caí',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319604,
    nome: 'São Sepé',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319703,
    nome: 'São Valentim',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319711,
    nome: 'São Valentim do Sul',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319737,
    nome: 'São Valério do Sul',
    microrregiao: {
      id: 43008,
      nome: 'Ijuí',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430018,
      nome: 'Ijuí',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319752,
    nome: 'São Vendelino',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319802,
    nome: 'São Vicente do Sul',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4319901,
    nome: 'Sapiranga',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320008,
    nome: 'Sapucaia do Sul',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320107,
    nome: 'Sarandi',
    microrregiao: {
      id: 43009,
      nome: 'Carazinho',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430028,
      nome: 'Carazinho',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320206,
    nome: 'Seberi',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320230,
    nome: 'Sede Nova',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320263,
    nome: 'Segredo',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430042,
      nome: 'Sobradinho',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320305,
    nome: 'Selbach',
    microrregiao: {
      id: 43012,
      nome: 'Não-Me-Toque',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320321,
    nome: 'Senador Salgado Filho',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320354,
    nome: 'Sentinela do Sul',
    microrregiao: {
      id: 43028,
      nome: 'Camaquã',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430005,
      nome: 'Camaquã',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320404,
    nome: 'Serafina Corrêa',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320453,
    nome: 'Sério',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320503,
    nome: 'Sertão',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320552,
    nome: 'Sertão Santana',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320578,
    nome: 'Sete de Setembro',
    microrregiao: {
      id: 43006,
      nome: 'Cerro Largo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430020,
      nome: 'Santo Ângelo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320602,
    nome: 'Severiano de Almeida',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320651,
    nome: 'Silveira Martins',
    microrregiao: {
      id: 43019,
      nome: 'Restinga Seca',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320677,
    nome: 'Sinimbu',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320701,
    nome: 'Sobradinho',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430042,
      nome: 'Sobradinho',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320800,
    nome: 'Soledade',
    microrregiao: {
      id: 43013,
      nome: 'Soledade',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430031,
      nome: 'Soledade',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320859,
    nome: 'Tabaí',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4320909,
    nome: 'Tapejara',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321006,
    nome: 'Tapera',
    microrregiao: {
      id: 43012,
      nome: 'Não-Me-Toque',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321105,
    nome: 'Tapes',
    microrregiao: {
      id: 43028,
      nome: 'Camaquã',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430005,
      nome: 'Camaquã',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321204,
    nome: 'Taquara',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430004,
      nome: 'Taquara - Parobé - Igrejinha',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321303,
    nome: 'Taquari',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321329,
    nome: 'Taquaruçu do Sul',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321352,
    nome: 'Tavares',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321402,
    nome: 'Tenente Portela',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321436,
    nome: 'Terra de Areia',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430003,
      nome: 'Tramandaí - Osório',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321451,
    nome: 'Teutônia',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321469,
    nome: 'Tio Hugo',
    microrregiao: {
      id: 43012,
      nome: 'Não-Me-Toque',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321477,
    nome: 'Tiradentes do Sul',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321493,
    nome: 'Toropi',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430011,
      nome: 'Santa Maria',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321501,
    nome: 'Torres',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430008,
      nome: 'Torres',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321600,
    nome: 'Tramandaí',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430003,
      nome: 'Tramandaí - Osório',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321626,
    nome: 'Travesseiro',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321634,
    nome: 'Três Arroios',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321667,
    nome: 'Três Cachoeiras',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430008,
      nome: 'Torres',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321709,
    nome: 'Três Coroas',
    microrregiao: {
      id: 43024,
      nome: 'Gramado-Canela',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430004,
      nome: 'Taquara - Parobé - Igrejinha',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321808,
    nome: 'Três de Maio',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430023,
      nome: 'Três de Maio',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321832,
    nome: 'Três Forquilhas',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430008,
      nome: 'Torres',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321857,
    nome: 'Três Palmeiras',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321907,
    nome: 'Três Passos',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4321956,
    nome: 'Trindade do Sul',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430035,
      nome: 'Nonoai',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322004,
    nome: 'Triunfo',
    microrregiao: {
      id: 43025,
      nome: 'São Jerônimo',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430006,
      nome: 'Charqueadas - Triunfo - São Jerônimo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322103,
    nome: 'Tucunduva',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322152,
    nome: 'Tunas',
    microrregiao: {
      id: 43013,
      nome: 'Soledade',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430042,
      nome: 'Sobradinho',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322186,
    nome: 'Tupanci do Sul',
    microrregiao: {
      id: 43005,
      nome: 'Sananduva',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430033,
      nome: 'Lagoa Vermelha',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322202,
    nome: 'Tupanciretã',
    microrregiao: {
      id: 43017,
      nome: 'Santiago',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430027,
      nome: 'Cruz Alta',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322251,
    nome: 'Tupandi',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430002,
      nome: 'Novo Hamburgo - São Leopoldo',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322301,
    nome: 'Tuparendi',
    microrregiao: {
      id: 43001,
      nome: 'Santa Rosa',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430019,
      nome: 'Santa Rosa',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322327,
    nome: 'Turuçu',
    microrregiao: {
      id: 43033,
      nome: 'Pelotas',
      mesorregiao: {
        id: 4307,
        nome: 'Sudeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430009,
      nome: 'Pelotas',
      'regiao-intermediaria': {
        id: 4302,
        nome: 'Pelotas',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322343,
    nome: 'Ubiretama',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430024,
      nome: 'Cerro Largo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322350,
    nome: 'União da Serra',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322376,
    nome: 'Unistalda',
    microrregiao: {
      id: 43017,
      nome: 'Santiago',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430014,
      nome: 'Santiago',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322400,
    nome: 'Uruguaiana',
    microrregiao: {
      id: 43029,
      nome: 'Campanha Ocidental',
      mesorregiao: {
        id: 4306,
        nome: 'Sudoeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430015,
      nome: 'Uruguaiana',
      'regiao-intermediaria': {
        id: 4304,
        nome: 'Uruguaiana',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322509,
    nome: 'Vacaria',
    microrregiao: {
      id: 43015,
      nome: 'Vacaria',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430039,
      nome: 'Vacaria',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322525,
    nome: 'Vale Verde',
    microrregiao: {
      id: 43025,
      nome: 'São Jerônimo',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322533,
    nome: 'Vale do Sol',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322541,
    nome: 'Vale Real',
    microrregiao: {
      id: 43023,
      nome: 'Montenegro',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430036,
      nome: 'Caxias do Sul',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322558,
    nome: 'Vanini',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322608,
    nome: 'Venâncio Aires',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322707,
    nome: 'Vera Cruz',
    microrregiao: {
      id: 43020,
      nome: 'Santa Cruz do Sul',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430040,
      nome: 'Santa Cruz do Sul',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322806,
    nome: 'Veranópolis',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322855,
    nome: 'Vespasiano Corrêa',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430043,
      nome: 'Encantado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4322905,
    nome: 'Viadutos',
    microrregiao: {
      id: 43004,
      nome: 'Erechim',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430026,
      nome: 'Erechim',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323002,
    nome: 'Viamão',
    microrregiao: {
      id: 43026,
      nome: 'Porto Alegre',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430001,
      nome: 'Porto Alegre',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323101,
    nome: 'Vicente Dutra',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323200,
    nome: 'Victor Graeff',
    microrregiao: {
      id: 43012,
      nome: 'Não-Me-Toque',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430025,
      nome: 'Passo Fundo',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323309,
    nome: 'Vila Flores',
    microrregiao: {
      id: 43016,
      nome: 'Caxias do Sul',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430037,
      nome: 'Bento Gonçalves',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323358,
    nome: 'Vila Lângaro',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430032,
      nome: 'Tapejara - Sananduva',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323408,
    nome: 'Vila Maria',
    microrregiao: {
      id: 43010,
      nome: 'Passo Fundo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430030,
      nome: 'Marau',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323457,
    nome: 'Vila Nova do Sul',
    microrregiao: {
      id: 43018,
      nome: 'Santa Maria',
      mesorregiao: {
        id: 4303,
        nome: 'Centro Ocidental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430012,
      nome: 'São Gabriel - Caçapava do Sul',
      'regiao-intermediaria': {
        id: 4303,
        nome: 'Santa Maria',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323507,
    nome: 'Vista Alegre',
    microrregiao: {
      id: 43003,
      nome: 'Frederico Westphalen',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430029,
      nome: 'Frederico Westphalen',
      'regiao-intermediaria': {
        id: 4306,
        nome: 'Passo Fundo',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323606,
    nome: 'Vista Alegre do Prata',
    microrregiao: {
      id: 43014,
      nome: 'Guaporé',
      mesorregiao: {
        id: 4302,
        nome: 'Nordeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430038,
      nome: 'Nova Prata - Guaporé',
      'regiao-intermediaria': {
        id: 4307,
        nome: 'Caxias do Sul',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323705,
    nome: 'Vista Gaúcha',
    microrregiao: {
      id: 43002,
      nome: 'Três Passos',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430021,
      nome: 'Três Passos',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323754,
    nome: 'Vitória das Missões',
    microrregiao: {
      id: 43007,
      nome: 'Santo Ângelo',
      mesorregiao: {
        id: 4301,
        nome: 'Noroeste Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430020,
      nome: 'Santo Ângelo',
      'regiao-intermediaria': {
        id: 4305,
        nome: 'Ijuí',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323770,
    nome: 'Westfália',
    microrregiao: {
      id: 43021,
      nome: 'Lajeado-Estrela',
      mesorregiao: {
        id: 4304,
        nome: 'Centro Oriental Rio-grandense',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430041,
      nome: 'Lajeado',
      'regiao-intermediaria': {
        id: 4308,
        nome: 'Santa Cruz do Sul - Lajeado',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 4323804,
    nome: 'Xangri-lá',
    microrregiao: {
      id: 43027,
      nome: 'Osório',
      mesorregiao: {
        id: 4305,
        nome: 'Metropolitana de Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
    'regiao-imediata': {
      id: 430003,
      nome: 'Tramandaí - Osório',
      'regiao-intermediaria': {
        id: 4301,
        nome: 'Porto Alegre',
        UF: {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
      },
    },
  },
  {
    id: 5000203,
    nome: 'Água Clara',
    microrregiao: {
      id: 50007,
      nome: 'Três Lagoas',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500002,
      nome: 'Três Lagoas',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5000252,
    nome: 'Alcinópolis',
    microrregiao: {
      id: 50003,
      nome: 'Alto Taquari',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500004,
      nome: 'Coxim',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5000609,
    nome: 'Amambai',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500009,
      nome: 'Amambai',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5000708,
    nome: 'Anastácio',
    microrregiao: {
      id: 50002,
      nome: 'Aquidauana',
      mesorregiao: {
        id: 5001,
        nome: 'Pantanais Sul Mato-grossense',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500012,
      nome: 'Aquidauana - Anastácio',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5000807,
    nome: 'Anaurilândia',
    microrregiao: {
      id: 50008,
      nome: 'Nova Andradina',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500007,
      nome: 'Nova Andradina',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5000856,
    nome: 'Angélica',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500007,
      nome: 'Nova Andradina',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5000906,
    nome: 'Antônio João',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500008,
      nome: 'Ponta Porã',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5001003,
    nome: 'Aparecida do Taboado',
    microrregiao: {
      id: 50006,
      nome: 'Paranaíba',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500003,
      nome: 'Paranaíba - Chapadão do Sul - Cassilândia',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5001102,
    nome: 'Aquidauana',
    microrregiao: {
      id: 50002,
      nome: 'Aquidauana',
      mesorregiao: {
        id: 5001,
        nome: 'Pantanais Sul Mato-grossense',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500012,
      nome: 'Aquidauana - Anastácio',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5001243,
    nome: 'Aral Moreira',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500008,
      nome: 'Ponta Porã',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5001508,
    nome: 'Bandeirantes',
    microrregiao: {
      id: 50004,
      nome: 'Campo Grande',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5001904,
    nome: 'Bataguassu',
    microrregiao: {
      id: 50008,
      nome: 'Nova Andradina',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500002,
      nome: 'Três Lagoas',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002001,
    nome: 'Batayporã',
    microrregiao: {
      id: 50008,
      nome: 'Nova Andradina',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500007,
      nome: 'Nova Andradina',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002100,
    nome: 'Bela Vista',
    microrregiao: {
      id: 50009,
      nome: 'Bodoquena',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500011,
      nome: 'Jardim',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002159,
    nome: 'Bodoquena',
    microrregiao: {
      id: 50009,
      nome: 'Bodoquena',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500012,
      nome: 'Aquidauana - Anastácio',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002209,
    nome: 'Bonito',
    microrregiao: {
      id: 50009,
      nome: 'Bodoquena',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500011,
      nome: 'Jardim',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002308,
    nome: 'Brasilândia',
    microrregiao: {
      id: 50007,
      nome: 'Três Lagoas',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500002,
      nome: 'Três Lagoas',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002407,
    nome: 'Caarapó',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002605,
    nome: 'Camapuã',
    microrregiao: {
      id: 50003,
      nome: 'Alto Taquari',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002704,
    nome: 'Campo Grande',
    microrregiao: {
      id: 50004,
      nome: 'Campo Grande',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002803,
    nome: 'Caracol',
    microrregiao: {
      id: 50009,
      nome: 'Bodoquena',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500011,
      nome: 'Jardim',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002902,
    nome: 'Cassilândia',
    microrregiao: {
      id: 50005,
      nome: 'Cassilândia',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500003,
      nome: 'Paranaíba - Chapadão do Sul - Cassilândia',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5002951,
    nome: 'Chapadão do Sul',
    microrregiao: {
      id: 50005,
      nome: 'Cassilândia',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500003,
      nome: 'Paranaíba - Chapadão do Sul - Cassilândia',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003108,
    nome: 'Corguinho',
    microrregiao: {
      id: 50004,
      nome: 'Campo Grande',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003157,
    nome: 'Coronel Sapucaia',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500009,
      nome: 'Amambai',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003207,
    nome: 'Corumbá',
    microrregiao: {
      id: 50001,
      nome: 'Baixo Pantanal',
      mesorregiao: {
        id: 5001,
        nome: 'Pantanais Sul Mato-grossense',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500010,
      nome: 'Corumbá',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003256,
    nome: 'Costa Rica',
    microrregiao: {
      id: 50005,
      nome: 'Cassilândia',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500004,
      nome: 'Coxim',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003306,
    nome: 'Coxim',
    microrregiao: {
      id: 50003,
      nome: 'Alto Taquari',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500004,
      nome: 'Coxim',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003454,
    nome: 'Deodápolis',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003488,
    nome: 'Dois Irmãos do Buriti',
    microrregiao: {
      id: 50002,
      nome: 'Aquidauana',
      mesorregiao: {
        id: 5001,
        nome: 'Pantanais Sul Mato-grossense',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003504,
    nome: 'Douradina',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003702,
    nome: 'Dourados',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003751,
    nome: 'Eldorado',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500006,
      nome: 'Naviraí - Mundo Novo',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003801,
    nome: 'Fátima do Sul',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5003900,
    nome: 'Figueirão',
    microrregiao: {
      id: 50003,
      nome: 'Alto Taquari',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500004,
      nome: 'Coxim',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5004007,
    nome: 'Glória de Dourados',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5004106,
    nome: 'Guia Lopes da Laguna',
    microrregiao: {
      id: 50009,
      nome: 'Bodoquena',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500011,
      nome: 'Jardim',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5004304,
    nome: 'Iguatemi',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500006,
      nome: 'Naviraí - Mundo Novo',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5004403,
    nome: 'Inocência',
    microrregiao: {
      id: 50006,
      nome: 'Paranaíba',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500003,
      nome: 'Paranaíba - Chapadão do Sul - Cassilândia',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5004502,
    nome: 'Itaporã',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5004601,
    nome: 'Itaquiraí',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500006,
      nome: 'Naviraí - Mundo Novo',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5004700,
    nome: 'Ivinhema',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500007,
      nome: 'Nova Andradina',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5004809,
    nome: 'Japorã',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500006,
      nome: 'Naviraí - Mundo Novo',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5004908,
    nome: 'Jaraguari',
    microrregiao: {
      id: 50004,
      nome: 'Campo Grande',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5005004,
    nome: 'Jardim',
    microrregiao: {
      id: 50009,
      nome: 'Bodoquena',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500011,
      nome: 'Jardim',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5005103,
    nome: 'Jateí',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5005152,
    nome: 'Juti',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5005202,
    nome: 'Ladário',
    microrregiao: {
      id: 50001,
      nome: 'Baixo Pantanal',
      mesorregiao: {
        id: 5001,
        nome: 'Pantanais Sul Mato-grossense',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500010,
      nome: 'Corumbá',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5005251,
    nome: 'Laguna Carapã',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5005400,
    nome: 'Maracaju',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5005608,
    nome: 'Miranda',
    microrregiao: {
      id: 50002,
      nome: 'Aquidauana',
      mesorregiao: {
        id: 5001,
        nome: 'Pantanais Sul Mato-grossense',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500012,
      nome: 'Aquidauana - Anastácio',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5005681,
    nome: 'Mundo Novo',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500006,
      nome: 'Naviraí - Mundo Novo',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5005707,
    nome: 'Naviraí',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500006,
      nome: 'Naviraí - Mundo Novo',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5005806,
    nome: 'Nioaque',
    microrregiao: {
      id: 50009,
      nome: 'Bodoquena',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500011,
      nome: 'Jardim',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5006002,
    nome: 'Nova Alvorada do Sul',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5006200,
    nome: 'Nova Andradina',
    microrregiao: {
      id: 50008,
      nome: 'Nova Andradina',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500007,
      nome: 'Nova Andradina',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5006259,
    nome: 'Novo Horizonte do Sul',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500007,
      nome: 'Nova Andradina',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5006275,
    nome: 'Paraíso das Águas',
    microrregiao: {
      id: 50005,
      nome: 'Cassilândia',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500003,
      nome: 'Paranaíba - Chapadão do Sul - Cassilândia',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5006309,
    nome: 'Paranaíba',
    microrregiao: {
      id: 50006,
      nome: 'Paranaíba',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500003,
      nome: 'Paranaíba - Chapadão do Sul - Cassilândia',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5006358,
    nome: 'Paranhos',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500009,
      nome: 'Amambai',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5006408,
    nome: 'Pedro Gomes',
    microrregiao: {
      id: 50003,
      nome: 'Alto Taquari',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500004,
      nome: 'Coxim',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5006606,
    nome: 'Ponta Porã',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500008,
      nome: 'Ponta Porã',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5006903,
    nome: 'Porto Murtinho',
    microrregiao: {
      id: 50001,
      nome: 'Baixo Pantanal',
      mesorregiao: {
        id: 5001,
        nome: 'Pantanais Sul Mato-grossense',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500011,
      nome: 'Jardim',
      'regiao-intermediaria': {
        id: 5003,
        nome: 'Corumbá',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007109,
    nome: 'Ribas do Rio Pardo',
    microrregiao: {
      id: 50007,
      nome: 'Três Lagoas',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007208,
    nome: 'Rio Brilhante',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007307,
    nome: 'Rio Negro',
    microrregiao: {
      id: 50004,
      nome: 'Campo Grande',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007406,
    nome: 'Rio Verde de Mato Grosso',
    microrregiao: {
      id: 50003,
      nome: 'Alto Taquari',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500004,
      nome: 'Coxim',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007505,
    nome: 'Rochedo',
    microrregiao: {
      id: 50004,
      nome: 'Campo Grande',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007554,
    nome: 'Santa Rita do Pardo',
    microrregiao: {
      id: 50007,
      nome: 'Três Lagoas',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500002,
      nome: 'Três Lagoas',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007695,
    nome: 'São Gabriel do Oeste',
    microrregiao: {
      id: 50003,
      nome: 'Alto Taquari',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007703,
    nome: 'Sete Quedas',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500009,
      nome: 'Amambai',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007802,
    nome: 'Selvíria',
    microrregiao: {
      id: 50006,
      nome: 'Paranaíba',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500002,
      nome: 'Três Lagoas',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007901,
    nome: 'Sidrolândia',
    microrregiao: {
      id: 50004,
      nome: 'Campo Grande',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007935,
    nome: 'Sonora',
    microrregiao: {
      id: 50003,
      nome: 'Alto Taquari',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500004,
      nome: 'Coxim',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007950,
    nome: 'Tacuru',
    microrregiao: {
      id: 50011,
      nome: 'Iguatemi',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500009,
      nome: 'Amambai',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5007976,
    nome: 'Taquarussu',
    microrregiao: {
      id: 50008,
      nome: 'Nova Andradina',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500007,
      nome: 'Nova Andradina',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5008008,
    nome: 'Terenos',
    microrregiao: {
      id: 50004,
      nome: 'Campo Grande',
      mesorregiao: {
        id: 5002,
        nome: 'Centro Norte de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500001,
      nome: 'Campo Grande',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5008305,
    nome: 'Três Lagoas',
    microrregiao: {
      id: 50007,
      nome: 'Três Lagoas',
      mesorregiao: {
        id: 5003,
        nome: 'Leste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500002,
      nome: 'Três Lagoas',
      'regiao-intermediaria': {
        id: 5001,
        nome: 'Campo Grande',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5008404,
    nome: 'Vicentina',
    microrregiao: {
      id: 50010,
      nome: 'Dourados',
      mesorregiao: {
        id: 5004,
        nome: 'Sudoeste de Mato Grosso do Sul',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 500005,
      nome: 'Dourados',
      'regiao-intermediaria': {
        id: 5002,
        nome: 'Dourados',
        UF: {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5100102,
    nome: 'Acorizal',
    microrregiao: {
      id: 51016,
      nome: 'Rosário Oeste',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5100201,
    nome: 'Água Boa',
    microrregiao: {
      id: 51010,
      nome: 'Canarana',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510015,
      nome: 'Água Boa',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5100250,
    nome: 'Alta Floresta',
    microrregiao: {
      id: 51002,
      nome: 'Alta Floresta',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510010,
      nome: 'Alta Floresta',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5100300,
    nome: 'Alto Araguaia',
    microrregiao: {
      id: 51022,
      nome: 'Alto Araguaia',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510016,
      nome: 'Rondonópolis',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5100359,
    nome: 'Alto Boa Vista',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5100409,
    nome: 'Alto Garças',
    microrregiao: {
      id: 51022,
      nome: 'Alto Araguaia',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510016,
      nome: 'Rondonópolis',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5100508,
    nome: 'Alto Paraguai',
    microrregiao: {
      id: 51015,
      nome: 'Alto Paraguai',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510003,
      nome: 'Diamantino',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5100607,
    nome: 'Alto Taquari',
    microrregiao: {
      id: 51022,
      nome: 'Alto Araguaia',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510016,
      nome: 'Rondonópolis',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5100805,
    nome: 'Apiacás',
    microrregiao: {
      id: 51002,
      nome: 'Alta Floresta',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510010,
      nome: 'Alta Floresta',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5101001,
    nome: 'Araguaiana',
    microrregiao: {
      id: 51011,
      nome: 'Médio Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510013,
      nome: 'Barra do Garças',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5101209,
    nome: 'Araguainha',
    microrregiao: {
      id: 51020,
      nome: 'Tesouro',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510016,
      nome: 'Rondonópolis',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5101258,
    nome: 'Araputanga',
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510006,
      nome: "Mirassol D'oeste",
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5101308,
    nome: 'Arenápolis',
    microrregiao: {
      id: 51015,
      nome: 'Alto Paraguai',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510003,
      nome: 'Diamantino',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5101407,
    nome: 'Aripuanã',
    microrregiao: {
      id: 51001,
      nome: 'Aripuanã',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510009,
      nome: 'Juína',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5101605,
    nome: 'Barão de Melgaço',
    microrregiao: {
      id: 51018,
      nome: 'Alto Pantanal',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5101704,
    nome: 'Barra do Bugres',
    microrregiao: {
      id: 51013,
      nome: 'Tangará da Serra',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510002,
      nome: 'Tangará da Serra',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5101803,
    nome: 'Barra do Garças',
    microrregiao: {
      id: 51011,
      nome: 'Médio Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510013,
      nome: 'Barra do Garças',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5101852,
    nome: 'Bom Jesus do Araguaia',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5101902,
    nome: 'Brasnorte',
    microrregiao: {
      id: 51001,
      nome: 'Aripuanã',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510002,
      nome: 'Tangará da Serra',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5102504,
    nome: 'Cáceres',
    microrregiao: {
      id: 51018,
      nome: 'Alto Pantanal',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510004,
      nome: 'Cáceres',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5102603,
    nome: 'Campinápolis',
    microrregiao: {
      id: 51010,
      nome: 'Canarana',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510015,
      nome: 'Água Boa',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5102637,
    nome: 'Campo Novo do Parecis',
    microrregiao: {
      id: 51004,
      nome: 'Parecis',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510002,
      nome: 'Tangará da Serra',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5102678,
    nome: 'Campo Verde',
    microrregiao: {
      id: 51019,
      nome: 'Primavera do Leste',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5102686,
    nome: 'Campos de Júlio',
    microrregiao: {
      id: 51004,
      nome: 'Parecis',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510005,
      nome: 'Pontes e Lacerda - Comodoro',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5102694,
    nome: 'Canabrava do Norte',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5102702,
    nome: 'Canarana',
    microrregiao: {
      id: 51010,
      nome: 'Canarana',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510015,
      nome: 'Água Boa',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5102793,
    nome: 'Carlinda',
    microrregiao: {
      id: 51002,
      nome: 'Alta Floresta',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510010,
      nome: 'Alta Floresta',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5102850,
    nome: 'Castanheira',
    microrregiao: {
      id: 51001,
      nome: 'Aripuanã',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510009,
      nome: 'Juína',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103007,
    nome: 'Chapada dos Guimarães',
    microrregiao: {
      id: 51017,
      nome: 'Cuiabá',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103056,
    nome: 'Cláudia',
    microrregiao: {
      id: 51007,
      nome: 'Sinop',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103106,
    nome: 'Cocalinho',
    microrregiao: {
      id: 51011,
      nome: 'Médio Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510015,
      nome: 'Água Boa',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103205,
    nome: 'Colíder',
    microrregiao: {
      id: 51003,
      nome: 'Colíder',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103254,
    nome: 'Colniza',
    microrregiao: {
      id: 51001,
      nome: 'Aripuanã',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510009,
      nome: 'Juína',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103304,
    nome: 'Comodoro',
    microrregiao: {
      id: 51004,
      nome: 'Parecis',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510005,
      nome: 'Pontes e Lacerda - Comodoro',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103353,
    nome: 'Confresa',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103361,
    nome: "Conquista D'Oeste",
    microrregiao: {
      id: 51012,
      nome: 'Alto Guaporé',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510005,
      nome: 'Pontes e Lacerda - Comodoro',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103379,
    nome: 'Cotriguaçu',
    microrregiao: {
      id: 51001,
      nome: 'Aripuanã',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510009,
      nome: 'Juína',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103403,
    nome: 'Cuiabá',
    microrregiao: {
      id: 51017,
      nome: 'Cuiabá',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103437,
    nome: 'Curvelândia',
    microrregiao: {
      id: 51018,
      nome: 'Alto Pantanal',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510004,
      nome: 'Cáceres',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103452,
    nome: 'Denise',
    microrregiao: {
      id: 51013,
      nome: 'Tangará da Serra',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510002,
      nome: 'Tangará da Serra',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103502,
    nome: 'Diamantino',
    microrregiao: {
      id: 51004,
      nome: 'Parecis',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510003,
      nome: 'Diamantino',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103601,
    nome: 'Dom Aquino',
    microrregiao: {
      id: 51021,
      nome: 'Rondonópolis',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510018,
      nome: 'Jaciara',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103700,
    nome: 'Feliz Natal',
    microrregiao: {
      id: 51007,
      nome: 'Sinop',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103809,
    nome: "Figueirópolis D'Oeste",
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510006,
      nome: "Mirassol D'oeste",
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103858,
    nome: 'Gaúcha do Norte',
    microrregiao: {
      id: 51008,
      nome: 'Paranatinga',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510015,
      nome: 'Água Boa',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103908,
    nome: 'General Carneiro',
    microrregiao: {
      id: 51020,
      nome: 'Tesouro',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510013,
      nome: 'Barra do Garças',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5103957,
    nome: "Glória D'Oeste",
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510006,
      nome: "Mirassol D'oeste",
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5104104,
    nome: 'Guarantã do Norte',
    microrregiao: {
      id: 51003,
      nome: 'Colíder',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510011,
      nome: 'Peixoto de Azevedo - Guarantã do Norte',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5104203,
    nome: 'Guiratinga',
    microrregiao: {
      id: 51020,
      nome: 'Tesouro',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510016,
      nome: 'Rondonópolis',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5104500,
    nome: 'Indiavaí',
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510006,
      nome: "Mirassol D'oeste",
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5104526,
    nome: 'Ipiranga do Norte',
    microrregiao: {
      id: 51006,
      nome: 'Alto Teles Pires',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510008,
      nome: 'Sorriso',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5104542,
    nome: 'Itanhangá',
    microrregiao: {
      id: 51006,
      nome: 'Alto Teles Pires',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510008,
      nome: 'Sorriso',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5104559,
    nome: 'Itaúba',
    microrregiao: {
      id: 51007,
      nome: 'Sinop',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5104609,
    nome: 'Itiquira',
    microrregiao: {
      id: 51021,
      nome: 'Rondonópolis',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510016,
      nome: 'Rondonópolis',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5104807,
    nome: 'Jaciara',
    microrregiao: {
      id: 51021,
      nome: 'Rondonópolis',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510018,
      nome: 'Jaciara',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5104906,
    nome: 'Jangada',
    microrregiao: {
      id: 51016,
      nome: 'Rosário Oeste',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105002,
    nome: 'Jauru',
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510006,
      nome: "Mirassol D'oeste",
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105101,
    nome: 'Juara',
    microrregiao: {
      id: 51005,
      nome: 'Arinos',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510012,
      nome: 'Juara',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105150,
    nome: 'Juína',
    microrregiao: {
      id: 51001,
      nome: 'Aripuanã',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510009,
      nome: 'Juína',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105176,
    nome: 'Juruena',
    microrregiao: {
      id: 51001,
      nome: 'Aripuanã',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510009,
      nome: 'Juína',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105200,
    nome: 'Juscimeira',
    microrregiao: {
      id: 51021,
      nome: 'Rondonópolis',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510018,
      nome: 'Jaciara',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105234,
    nome: "Lambari D'Oeste",
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510004,
      nome: 'Cáceres',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105259,
    nome: 'Lucas do Rio Verde',
    microrregiao: {
      id: 51006,
      nome: 'Alto Teles Pires',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510008,
      nome: 'Sorriso',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105309,
    nome: 'Luciara',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105507,
    nome: 'Vila Bela da Santíssima Trindade',
    microrregiao: {
      id: 51012,
      nome: 'Alto Guaporé',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510005,
      nome: 'Pontes e Lacerda - Comodoro',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105580,
    nome: 'Marcelândia',
    microrregiao: {
      id: 51007,
      nome: 'Sinop',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105606,
    nome: 'Matupá',
    microrregiao: {
      id: 51003,
      nome: 'Colíder',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510011,
      nome: 'Peixoto de Azevedo - Guarantã do Norte',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105622,
    nome: "Mirassol d'Oeste",
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510006,
      nome: "Mirassol D'oeste",
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5105903,
    nome: 'Nobres',
    microrregiao: {
      id: 51006,
      nome: 'Alto Teles Pires',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106000,
    nome: 'Nortelândia',
    microrregiao: {
      id: 51015,
      nome: 'Alto Paraguai',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510003,
      nome: 'Diamantino',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106109,
    nome: 'Nossa Senhora do Livramento',
    microrregiao: {
      id: 51017,
      nome: 'Cuiabá',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106158,
    nome: 'Nova Bandeirantes',
    microrregiao: {
      id: 51002,
      nome: 'Alta Floresta',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510010,
      nome: 'Alta Floresta',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106174,
    nome: 'Nova Nazaré',
    microrregiao: {
      id: 51010,
      nome: 'Canarana',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510015,
      nome: 'Água Boa',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106182,
    nome: 'Nova Lacerda',
    microrregiao: {
      id: 51012,
      nome: 'Alto Guaporé',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510005,
      nome: 'Pontes e Lacerda - Comodoro',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106190,
    nome: 'Nova Santa Helena',
    microrregiao: {
      id: 51007,
      nome: 'Sinop',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106208,
    nome: 'Nova Brasilândia',
    microrregiao: {
      id: 51008,
      nome: 'Paranatinga',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106216,
    nome: 'Nova Canaã do Norte',
    microrregiao: {
      id: 51003,
      nome: 'Colíder',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106224,
    nome: 'Nova Mutum',
    microrregiao: {
      id: 51006,
      nome: 'Alto Teles Pires',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510008,
      nome: 'Sorriso',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106232,
    nome: 'Nova Olímpia',
    microrregiao: {
      id: 51013,
      nome: 'Tangará da Serra',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510002,
      nome: 'Tangará da Serra',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106240,
    nome: 'Nova Ubiratã',
    microrregiao: {
      id: 51006,
      nome: 'Alto Teles Pires',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510008,
      nome: 'Sorriso',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106257,
    nome: 'Nova Xavantina',
    microrregiao: {
      id: 51010,
      nome: 'Canarana',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510013,
      nome: 'Barra do Garças',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106265,
    nome: 'Novo Mundo',
    microrregiao: {
      id: 51003,
      nome: 'Colíder',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510011,
      nome: 'Peixoto de Azevedo - Guarantã do Norte',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106273,
    nome: 'Novo Horizonte do Norte',
    microrregiao: {
      id: 51005,
      nome: 'Arinos',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510012,
      nome: 'Juara',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106281,
    nome: 'Novo São Joaquim',
    microrregiao: {
      id: 51010,
      nome: 'Canarana',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510013,
      nome: 'Barra do Garças',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106299,
    nome: 'Paranaíta',
    microrregiao: {
      id: 51002,
      nome: 'Alta Floresta',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510010,
      nome: 'Alta Floresta',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106307,
    nome: 'Paranatinga',
    microrregiao: {
      id: 51008,
      nome: 'Paranatinga',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510017,
      nome: 'Primavera do Leste',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106315,
    nome: 'Novo Santo Antônio',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106372,
    nome: 'Pedra Preta',
    microrregiao: {
      id: 51021,
      nome: 'Rondonópolis',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510016,
      nome: 'Rondonópolis',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106422,
    nome: 'Peixoto de Azevedo',
    microrregiao: {
      id: 51003,
      nome: 'Colíder',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510011,
      nome: 'Peixoto de Azevedo - Guarantã do Norte',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106455,
    nome: 'Planalto da Serra',
    microrregiao: {
      id: 51008,
      nome: 'Paranatinga',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106505,
    nome: 'Poconé',
    microrregiao: {
      id: 51018,
      nome: 'Alto Pantanal',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106653,
    nome: 'Pontal do Araguaia',
    microrregiao: {
      id: 51020,
      nome: 'Tesouro',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510013,
      nome: 'Barra do Garças',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106703,
    nome: 'Ponte Branca',
    microrregiao: {
      id: 51020,
      nome: 'Tesouro',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510013,
      nome: 'Barra do Garças',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106752,
    nome: 'Pontes e Lacerda',
    microrregiao: {
      id: 51012,
      nome: 'Alto Guaporé',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510005,
      nome: 'Pontes e Lacerda - Comodoro',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106778,
    nome: 'Porto Alegre do Norte',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106802,
    nome: 'Porto dos Gaúchos',
    microrregiao: {
      id: 51005,
      nome: 'Arinos',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510012,
      nome: 'Juara',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106828,
    nome: 'Porto Esperidião',
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510006,
      nome: "Mirassol D'oeste",
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5106851,
    nome: 'Porto Estrela',
    microrregiao: {
      id: 51013,
      nome: 'Tangará da Serra',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510002,
      nome: 'Tangará da Serra',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107008,
    nome: 'Poxoréu',
    microrregiao: {
      id: 51020,
      nome: 'Tesouro',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510017,
      nome: 'Primavera do Leste',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107040,
    nome: 'Primavera do Leste',
    microrregiao: {
      id: 51019,
      nome: 'Primavera do Leste',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510017,
      nome: 'Primavera do Leste',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107065,
    nome: 'Querência',
    microrregiao: {
      id: 51010,
      nome: 'Canarana',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510015,
      nome: 'Água Boa',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107107,
    nome: 'São José dos Quatro Marcos',
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510006,
      nome: "Mirassol D'oeste",
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107156,
    nome: 'Reserva do Cabaçal',
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510006,
      nome: "Mirassol D'oeste",
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107180,
    nome: 'Ribeirão Cascalheira',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510015,
      nome: 'Água Boa',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107198,
    nome: 'Ribeirãozinho',
    microrregiao: {
      id: 51020,
      nome: 'Tesouro',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510013,
      nome: 'Barra do Garças',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107206,
    nome: 'Rio Branco',
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510004,
      nome: 'Cáceres',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107248,
    nome: 'Santa Carmem',
    microrregiao: {
      id: 51007,
      nome: 'Sinop',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107263,
    nome: 'Santo Afonso',
    microrregiao: {
      id: 51015,
      nome: 'Alto Paraguai',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510003,
      nome: 'Diamantino',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107297,
    nome: 'São José do Povo',
    microrregiao: {
      id: 51021,
      nome: 'Rondonópolis',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510016,
      nome: 'Rondonópolis',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107305,
    nome: 'São José do Rio Claro',
    microrregiao: {
      id: 51005,
      nome: 'Arinos',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510003,
      nome: 'Diamantino',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107354,
    nome: 'São José do Xingu',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107404,
    nome: 'São Pedro da Cipa',
    microrregiao: {
      id: 51021,
      nome: 'Rondonópolis',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510018,
      nome: 'Jaciara',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107578,
    nome: 'Rondolândia',
    microrregiao: {
      id: 51001,
      nome: 'Aripuanã',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510009,
      nome: 'Juína',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107602,
    nome: 'Rondonópolis',
    microrregiao: {
      id: 51021,
      nome: 'Rondonópolis',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510016,
      nome: 'Rondonópolis',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107701,
    nome: 'Rosário Oeste',
    microrregiao: {
      id: 51016,
      nome: 'Rosário Oeste',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107743,
    nome: 'Santa Cruz do Xingu',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107750,
    nome: 'Salto do Céu',
    microrregiao: {
      id: 51014,
      nome: 'Jauru',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510004,
      nome: 'Cáceres',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107768,
    nome: 'Santa Rita do Trivelato',
    microrregiao: {
      id: 51006,
      nome: 'Alto Teles Pires',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510008,
      nome: 'Sorriso',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107776,
    nome: 'Santa Terezinha',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107792,
    nome: 'Santo Antônio do Leste',
    microrregiao: {
      id: 51010,
      nome: 'Canarana',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510017,
      nome: 'Primavera do Leste',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107800,
    nome: 'Santo Antônio do Leverger',
    microrregiao: {
      id: 51017,
      nome: 'Cuiabá',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107859,
    nome: 'São Félix do Araguaia',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107875,
    nome: 'Sapezal',
    microrregiao: {
      id: 51004,
      nome: 'Parecis',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510002,
      nome: 'Tangará da Serra',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107883,
    nome: 'Serra Nova Dourada',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107909,
    nome: 'Sinop',
    microrregiao: {
      id: 51007,
      nome: 'Sinop',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107925,
    nome: 'Sorriso',
    microrregiao: {
      id: 51006,
      nome: 'Alto Teles Pires',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510008,
      nome: 'Sorriso',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107941,
    nome: 'Tabaporã',
    microrregiao: {
      id: 51005,
      nome: 'Arinos',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510012,
      nome: 'Juara',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5107958,
    nome: 'Tangará da Serra',
    microrregiao: {
      id: 51013,
      nome: 'Tangará da Serra',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510002,
      nome: 'Tangará da Serra',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108006,
    nome: 'Tapurah',
    microrregiao: {
      id: 51006,
      nome: 'Alto Teles Pires',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510008,
      nome: 'Sorriso',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108055,
    nome: 'Terra Nova do Norte',
    microrregiao: {
      id: 51003,
      nome: 'Colíder',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108105,
    nome: 'Tesouro',
    microrregiao: {
      id: 51020,
      nome: 'Tesouro',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510016,
      nome: 'Rondonópolis',
      'regiao-intermediaria': {
        id: 5105,
        nome: 'Rondonópolis',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108204,
    nome: 'Torixoréu',
    microrregiao: {
      id: 51020,
      nome: 'Tesouro',
      mesorregiao: {
        id: 5105,
        nome: 'Sudeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510013,
      nome: 'Barra do Garças',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108303,
    nome: 'União do Sul',
    microrregiao: {
      id: 51007,
      nome: 'Sinop',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108352,
    nome: 'Vale de São Domingos',
    microrregiao: {
      id: 51012,
      nome: 'Alto Guaporé',
      mesorregiao: {
        id: 5103,
        nome: 'Sudoeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510005,
      nome: 'Pontes e Lacerda - Comodoro',
      'regiao-intermediaria': {
        id: 5102,
        nome: 'Cáceres',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108402,
    nome: 'Várzea Grande',
    microrregiao: {
      id: 51017,
      nome: 'Cuiabá',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510001,
      nome: 'Cuiabá',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108501,
    nome: 'Vera',
    microrregiao: {
      id: 51007,
      nome: 'Sinop',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510008,
      nome: 'Sorriso',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108600,
    nome: 'Vila Rica',
    microrregiao: {
      id: 51009,
      nome: 'Norte Araguaia',
      mesorregiao: {
        id: 5102,
        nome: 'Nordeste Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510014,
      nome: 'Confresa - Vila Rica',
      'regiao-intermediaria': {
        id: 5104,
        nome: 'Barra do Garças',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108808,
    nome: 'Nova Guarita',
    microrregiao: {
      id: 51003,
      nome: 'Colíder',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510007,
      nome: 'Sinop',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108857,
    nome: 'Nova Marilândia',
    microrregiao: {
      id: 51015,
      nome: 'Alto Paraguai',
      mesorregiao: {
        id: 5104,
        nome: 'Centro-Sul Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510003,
      nome: 'Diamantino',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108907,
    nome: 'Nova Maringá',
    microrregiao: {
      id: 51005,
      nome: 'Arinos',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510003,
      nome: 'Diamantino',
      'regiao-intermediaria': {
        id: 5101,
        nome: 'Cuiabá',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5108956,
    nome: 'Nova Monte Verde',
    microrregiao: {
      id: 51002,
      nome: 'Alta Floresta',
      mesorregiao: {
        id: 5101,
        nome: 'Norte Mato-grossense',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 510010,
      nome: 'Alta Floresta',
      'regiao-intermediaria': {
        id: 5103,
        nome: 'Sinop',
        UF: {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200050,
    nome: 'Abadia de Goiás',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200100,
    nome: 'Abadiânia',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200134,
    nome: 'Acreúna',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200159,
    nome: 'Adelândia',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200175,
    nome: 'Água Fria de Goiás',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520022,
      nome: 'Flores de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200209,
    nome: 'Água Limpa',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520008,
      nome: 'Caldas Novas-Morrinhos',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200258,
    nome: 'Águas Lindas de Goiás',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520020,
      nome: 'Águas Lindas de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200308,
    nome: 'Alexânia',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200506,
    nome: 'Aloândia',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520007,
      nome: 'Itumbiara',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200555,
    nome: 'Alto Horizonte',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520017,
      nome: 'Uruaçu - Niquelândia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200605,
    nome: 'Alto Paraíso de Goiás',
    microrregiao: {
      id: 52005,
      nome: 'Chapada dos Veadeiros',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520022,
      nome: 'Flores de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200803,
    nome: 'Alvorada do Norte',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200829,
    nome: 'Amaralina',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520017,
      nome: 'Uruaçu - Niquelândia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200852,
    nome: 'Americano do Brasil',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5200902,
    nome: 'Amorinópolis',
    microrregiao: {
      id: 52008,
      nome: 'Iporá',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5201108,
    nome: 'Anápolis',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5201207,
    nome: 'Anhanguera',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520004,
      nome: 'Catalão',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5201306,
    nome: 'Anicuns',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5201405,
    nome: 'Aparecida de Goiânia',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5201454,
    nome: 'Aparecida do Rio Doce',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5201504,
    nome: 'Aporé',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520011,
      nome: 'Jataí-Mineiros',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5201603,
    nome: 'Araçu',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5201702,
    nome: 'Aragarças',
    microrregiao: {
      id: 52003,
      nome: 'Aragarças',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5201801,
    nome: 'Aragoiânia',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5202155,
    nome: 'Araguapaz',
    microrregiao: {
      id: 52002,
      nome: 'Rio Vermelho',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5202353,
    nome: 'Arenópolis',
    microrregiao: {
      id: 52003,
      nome: 'Aragarças',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5202502,
    nome: 'Aruanã',
    microrregiao: {
      id: 52002,
      nome: 'Rio Vermelho',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5202601,
    nome: 'Aurilândia',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520013,
      nome: 'São Luís de Montes Belos',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5202809,
    nome: 'Avelinópolis',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203104,
    nome: 'Baliza',
    microrregiao: {
      id: 52003,
      nome: 'Aragarças',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203203,
    nome: 'Barro Alto',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203302,
    nome: 'Bela Vista de Goiás',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203401,
    nome: 'Bom Jardim de Goiás',
    microrregiao: {
      id: 52003,
      nome: 'Aragarças',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203500,
    nome: 'Bom Jesus de Goiás',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520007,
      nome: 'Itumbiara',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203559,
    nome: 'Bonfinópolis',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203575,
    nome: 'Bonópolis',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203609,
    nome: 'Brazabrantes',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203807,
    nome: 'Britânia',
    microrregiao: {
      id: 52002,
      nome: 'Rio Vermelho',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203906,
    nome: 'Buriti Alegre',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520007,
      nome: 'Itumbiara',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203939,
    nome: 'Buriti de Goiás',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520013,
      nome: 'São Luís de Montes Belos',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5203962,
    nome: 'Buritinópolis',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204003,
    nome: 'Cabeceiras',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520020,
      nome: 'Águas Lindas de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204102,
    nome: 'Cachoeira Alta',
    microrregiao: {
      id: 52018,
      nome: 'Quirinópolis',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204201,
    nome: 'Cachoeira de Goiás',
    microrregiao: {
      id: 52008,
      nome: 'Iporá',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520013,
      nome: 'São Luís de Montes Belos',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204250,
    nome: 'Cachoeira Dourada',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520007,
      nome: 'Itumbiara',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204300,
    nome: 'Caçu',
    microrregiao: {
      id: 52018,
      nome: 'Quirinópolis',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204409,
    nome: 'Caiapônia',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520011,
      nome: 'Jataí-Mineiros',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204508,
    nome: 'Caldas Novas',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520008,
      nome: 'Caldas Novas-Morrinhos',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204557,
    nome: 'Caldazinha',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204607,
    nome: 'Campestre de Goiás',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204656,
    nome: 'Campinaçu',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204706,
    nome: 'Campinorte',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520017,
      nome: 'Uruaçu - Niquelândia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204805,
    nome: 'Campo Alegre de Goiás',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520004,
      nome: 'Catalão',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204854,
    nome: 'Campo Limpo de Goiás',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204904,
    nome: 'Campos Belos',
    microrregiao: {
      id: 52005,
      nome: 'Chapada dos Veadeiros',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5204953,
    nome: 'Campos Verdes',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205000,
    nome: 'Carmo do Rio Verde',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205059,
    nome: 'Castelândia',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205109,
    nome: 'Catalão',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520004,
      nome: 'Catalão',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205208,
    nome: 'Caturaí',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205307,
    nome: 'Cavalcante',
    microrregiao: {
      id: 52005,
      nome: 'Chapada dos Veadeiros',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520022,
      nome: 'Flores de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205406,
    nome: 'Ceres',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205455,
    nome: 'Cezarina',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205471,
    nome: 'Chapadão do Céu',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520011,
      nome: 'Jataí-Mineiros',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205497,
    nome: 'Cidade Ocidental',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520019,
      nome: 'Luziânia',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205513,
    nome: 'Cocalzinho de Goiás',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520020,
      nome: 'Águas Lindas de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205521,
    nome: 'Colinas do Sul',
    microrregiao: {
      id: 52005,
      nome: 'Chapada dos Veadeiros',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520017,
      nome: 'Uruaçu - Niquelândia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205703,
    nome: 'Córrego do Ouro',
    microrregiao: {
      id: 52008,
      nome: 'Iporá',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520013,
      nome: 'São Luís de Montes Belos',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205802,
    nome: 'Corumbá de Goiás',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5205901,
    nome: 'Corumbaíba',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520008,
      nome: 'Caldas Novas-Morrinhos',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5206206,
    nome: 'Cristalina',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520019,
      nome: 'Luziânia',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5206305,
    nome: 'Cristianópolis',
    microrregiao: {
      id: 52016,
      nome: 'Pires do Rio',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520009,
      nome: 'Piracanjuba',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5206404,
    nome: 'Crixás',
    microrregiao: {
      id: 52001,
      nome: 'São Miguel do Araguaia',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5206503,
    nome: 'Cromínia',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520009,
      nome: 'Piracanjuba',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5206602,
    nome: 'Cumari',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520004,
      nome: 'Catalão',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5206701,
    nome: 'Damianópolis',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5206800,
    nome: 'Damolândia',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5206909,
    nome: 'Davinópolis',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520004,
      nome: 'Catalão',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5207105,
    nome: 'Diorama',
    microrregiao: {
      id: 52003,
      nome: 'Aragarças',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5207253,
    nome: 'Doverlândia',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520011,
      nome: 'Jataí-Mineiros',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5207352,
    nome: 'Edealina',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520009,
      nome: 'Piracanjuba',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5207402,
    nome: 'Edéia',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5207501,
    nome: 'Estrela do Norte',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5207535,
    nome: 'Faina',
    microrregiao: {
      id: 52002,
      nome: 'Rio Vermelho',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5207600,
    nome: 'Fazenda Nova',
    microrregiao: {
      id: 52008,
      nome: 'Iporá',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520013,
      nome: 'São Luís de Montes Belos',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5207808,
    nome: 'Firminópolis',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5207907,
    nome: 'Flores de Goiás',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520022,
      nome: 'Flores de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5208004,
    nome: 'Formosa',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520020,
      nome: 'Águas Lindas de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5208103,
    nome: 'Formoso',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5208152,
    nome: 'Gameleira de Goiás',
    microrregiao: {
      id: 52016,
      nome: 'Pires do Rio',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5208301,
    nome: 'Divinópolis de Goiás',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5208400,
    nome: 'Goianápolis',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5208509,
    nome: 'Goiandira',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520004,
      nome: 'Catalão',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5208608,
    nome: 'Goianésia',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5208707,
    nome: 'Goiânia',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5208806,
    nome: 'Goianira',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5208905,
    nome: 'Goiás',
    microrregiao: {
      id: 52002,
      nome: 'Rio Vermelho',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209101,
    nome: 'Goiatuba',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520007,
      nome: 'Itumbiara',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209150,
    nome: 'Gouvelândia',
    microrregiao: {
      id: 52018,
      nome: 'Quirinópolis',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520012,
      nome: 'Quirinópolis',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209200,
    nome: 'Guapó',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209291,
    nome: 'Guaraíta',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209408,
    nome: 'Guarani de Goiás',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209457,
    nome: 'Guarinos',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209606,
    nome: 'Heitoraí',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209705,
    nome: 'Hidrolândia',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209804,
    nome: 'Hidrolina',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520017,
      nome: 'Uruaçu - Niquelândia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209903,
    nome: 'Iaciara',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209937,
    nome: 'Inaciolândia',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520012,
      nome: 'Quirinópolis',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5209952,
    nome: 'Indiara',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5210000,
    nome: 'Inhumas',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5210109,
    nome: 'Ipameri',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520004,
      nome: 'Catalão',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5210158,
    nome: 'Ipiranga de Goiás',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5210208,
    nome: 'Iporá',
    microrregiao: {
      id: 52008,
      nome: 'Iporá',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5210307,
    nome: 'Israelândia',
    microrregiao: {
      id: 52008,
      nome: 'Iporá',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5210406,
    nome: 'Itaberaí',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5210562,
    nome: 'Itaguari',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5210604,
    nome: 'Itaguaru',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5210802,
    nome: 'Itajá',
    microrregiao: {
      id: 52018,
      nome: 'Quirinópolis',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5210901,
    nome: 'Itapaci',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5211008,
    nome: 'Itapirapuã',
    microrregiao: {
      id: 52002,
      nome: 'Rio Vermelho',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5211206,
    nome: 'Itapuranga',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5211305,
    nome: 'Itarumã',
    microrregiao: {
      id: 52018,
      nome: 'Quirinópolis',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5211404,
    nome: 'Itauçu',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5211503,
    nome: 'Itumbiara',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520007,
      nome: 'Itumbiara',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5211602,
    nome: 'Ivolândia',
    microrregiao: {
      id: 52008,
      nome: 'Iporá',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520013,
      nome: 'São Luís de Montes Belos',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5211701,
    nome: 'Jandaia',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5211800,
    nome: 'Jaraguá',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5211909,
    nome: 'Jataí',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520011,
      nome: 'Jataí-Mineiros',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212006,
    nome: 'Jaupaci',
    microrregiao: {
      id: 52008,
      nome: 'Iporá',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212055,
    nome: 'Jesúpolis',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212105,
    nome: 'Joviânia',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520007,
      nome: 'Itumbiara',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212204,
    nome: 'Jussara',
    microrregiao: {
      id: 52002,
      nome: 'Rio Vermelho',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212253,
    nome: 'Lagoa Santa',
    microrregiao: {
      id: 52018,
      nome: 'Quirinópolis',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212303,
    nome: 'Leopoldo de Bulhões',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212501,
    nome: 'Luziânia',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520019,
      nome: 'Luziânia',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212600,
    nome: 'Mairipotaba',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520009,
      nome: 'Piracanjuba',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212709,
    nome: 'Mambaí',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212808,
    nome: 'Mara Rosa',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520017,
      nome: 'Uruaçu - Niquelândia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212907,
    nome: 'Marzagão',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520008,
      nome: 'Caldas Novas-Morrinhos',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5212956,
    nome: 'Matrinchã',
    microrregiao: {
      id: 52002,
      nome: 'Rio Vermelho',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213004,
    nome: 'Maurilândia',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213053,
    nome: 'Mimoso de Goiás',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520020,
      nome: 'Águas Lindas de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213087,
    nome: 'Minaçu',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213103,
    nome: 'Mineiros',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520011,
      nome: 'Jataí-Mineiros',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213400,
    nome: 'Moiporá',
    microrregiao: {
      id: 52008,
      nome: 'Iporá',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520013,
      nome: 'São Luís de Montes Belos',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213509,
    nome: 'Monte Alegre de Goiás',
    microrregiao: {
      id: 52005,
      nome: 'Chapada dos Veadeiros',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213707,
    nome: 'Montes Claros de Goiás',
    microrregiao: {
      id: 52003,
      nome: 'Aragarças',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213756,
    nome: 'Montividiu',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213772,
    nome: 'Montividiu do Norte',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213806,
    nome: 'Morrinhos',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520008,
      nome: 'Caldas Novas-Morrinhos',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213855,
    nome: 'Morro Agudo de Goiás',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5213905,
    nome: 'Mossâmedes',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214002,
    nome: 'Mozarlândia',
    microrregiao: {
      id: 52001,
      nome: 'São Miguel do Araguaia',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214051,
    nome: 'Mundo Novo',
    microrregiao: {
      id: 52001,
      nome: 'São Miguel do Araguaia',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214101,
    nome: 'Mutunópolis',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214408,
    nome: 'Nazário',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214507,
    nome: 'Nerópolis',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214606,
    nome: 'Niquelândia',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520017,
      nome: 'Uruaçu - Niquelândia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214705,
    nome: 'Nova América',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214804,
    nome: 'Nova Aurora',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520004,
      nome: 'Catalão',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214838,
    nome: 'Nova Crixás',
    microrregiao: {
      id: 52001,
      nome: 'São Miguel do Araguaia',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214861,
    nome: 'Nova Glória',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214879,
    nome: 'Nova Iguaçu de Goiás',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520017,
      nome: 'Uruaçu - Niquelândia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5214903,
    nome: 'Nova Roma',
    microrregiao: {
      id: 52005,
      nome: 'Chapada dos Veadeiros',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215009,
    nome: 'Nova Veneza',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215207,
    nome: 'Novo Brasil',
    microrregiao: {
      id: 52008,
      nome: 'Iporá',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215231,
    nome: 'Novo Gama',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520019,
      nome: 'Luziânia',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215256,
    nome: 'Novo Planalto',
    microrregiao: {
      id: 52001,
      nome: 'São Miguel do Araguaia',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215306,
    nome: 'Orizona',
    microrregiao: {
      id: 52016,
      nome: 'Pires do Rio',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520006,
      nome: 'Pires do Rio',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215405,
    nome: 'Ouro Verde de Goiás',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215504,
    nome: 'Ouvidor',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520004,
      nome: 'Catalão',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215603,
    nome: 'Padre Bernardo',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520020,
      nome: 'Águas Lindas de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215652,
    nome: 'Palestina de Goiás',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215702,
    nome: 'Palmeiras de Goiás',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215801,
    nome: 'Palmelo',
    microrregiao: {
      id: 52016,
      nome: 'Pires do Rio',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520006,
      nome: 'Pires do Rio',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5215900,
    nome: 'Palminópolis',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5216007,
    nome: 'Panamá',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520007,
      nome: 'Itumbiara',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5216304,
    nome: 'Paranaiguara',
    microrregiao: {
      id: 52018,
      nome: 'Quirinópolis',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520012,
      nome: 'Quirinópolis',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5216403,
    nome: 'Paraúna',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5216452,
    nome: 'Perolândia',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520011,
      nome: 'Jataí-Mineiros',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5216809,
    nome: 'Petrolina de Goiás',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5216908,
    nome: 'Pilar de Goiás',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5217104,
    nome: 'Piracanjuba',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520009,
      nome: 'Piracanjuba',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5217203,
    nome: 'Piranhas',
    microrregiao: {
      id: 52003,
      nome: 'Aragarças',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520014,
      nome: 'Iporá',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5217302,
    nome: 'Pirenópolis',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5217401,
    nome: 'Pires do Rio',
    microrregiao: {
      id: 52016,
      nome: 'Pires do Rio',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520006,
      nome: 'Pires do Rio',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5217609,
    nome: 'Planaltina',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520020,
      nome: 'Águas Lindas de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5217708,
    nome: 'Pontalina',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520009,
      nome: 'Piracanjuba',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218003,
    nome: 'Porangatu',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218052,
    nome: 'Porteirão',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218102,
    nome: 'Portelândia',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520011,
      nome: 'Jataí-Mineiros',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218300,
    nome: 'Posse',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218391,
    nome: 'Professor Jamil',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520009,
      nome: 'Piracanjuba',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218508,
    nome: 'Quirinópolis',
    microrregiao: {
      id: 52018,
      nome: 'Quirinópolis',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520012,
      nome: 'Quirinópolis',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218607,
    nome: 'Rialma',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218706,
    nome: 'Rianápolis',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218789,
    nome: 'Rio Quente',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520008,
      nome: 'Caldas Novas-Morrinhos',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218805,
    nome: 'Rio Verde',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5218904,
    nome: 'Rubiataba',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219001,
    nome: 'Sanclerlândia',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520013,
      nome: 'São Luís de Montes Belos',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219100,
    nome: 'Santa Bárbara de Goiás',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219209,
    nome: 'Santa Cruz de Goiás',
    microrregiao: {
      id: 52016,
      nome: 'Pires do Rio',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520006,
      nome: 'Pires do Rio',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219258,
    nome: 'Santa Fé de Goiás',
    microrregiao: {
      id: 52002,
      nome: 'Rio Vermelho',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520005,
      nome: 'Goiás - Itapuranga',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219308,
    nome: 'Santa Helena de Goiás',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219357,
    nome: 'Santa Isabel',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219407,
    nome: 'Santa Rita do Araguaia',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520011,
      nome: 'Jataí-Mineiros',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219456,
    nome: 'Santa Rita do Novo Destino',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219506,
    nome: 'Santa Rosa de Goiás',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219605,
    nome: 'Santa Tereza de Goiás',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219704,
    nome: 'Santa Terezinha de Goiás',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219712,
    nome: 'Santo Antônio da Barra',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219738,
    nome: 'Santo Antônio de Goiás',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219753,
    nome: 'Santo Antônio do Descoberto',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520019,
      nome: 'Luziânia',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219803,
    nome: 'São Domingos',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5219902,
    nome: 'São Francisco de Goiás',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220009,
    nome: "São João d'Aliança",
    microrregiao: {
      id: 52005,
      nome: 'Chapada dos Veadeiros',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520022,
      nome: 'Flores de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220058,
    nome: 'São João da Paraúna',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220108,
    nome: 'São Luís de Montes Belos',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520013,
      nome: 'São Luís de Montes Belos',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220157,
    nome: 'São Luiz do Norte',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520017,
      nome: 'Uruaçu - Niquelândia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220207,
    nome: 'São Miguel do Araguaia',
    microrregiao: {
      id: 52001,
      nome: 'São Miguel do Araguaia',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220264,
    nome: 'São Miguel do Passa Quatro',
    microrregiao: {
      id: 52016,
      nome: 'Pires do Rio',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220280,
    nome: 'São Patrício',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220405,
    nome: 'São Simão',
    microrregiao: {
      id: 52018,
      nome: 'Quirinópolis',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520012,
      nome: 'Quirinópolis',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220454,
    nome: 'Senador Canedo',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220504,
    nome: 'Serranópolis',
    microrregiao: {
      id: 52013,
      nome: 'Sudoeste de Goiás',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520011,
      nome: 'Jataí-Mineiros',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220603,
    nome: 'Silvânia',
    microrregiao: {
      id: 52016,
      nome: 'Pires do Rio',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220686,
    nome: 'Simolândia',
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5220702,
    nome: "Sítio d'Abadia",
    microrregiao: {
      id: 52011,
      nome: 'Vão do Paranã',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520021,
      nome: 'Posse-Campos Belos',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221007,
    nome: 'Taquaral de Goiás',
    microrregiao: {
      id: 52007,
      nome: 'Anápolis',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520003,
      nome: 'Inhumas - Itaberaí - Anicuns',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221080,
    nome: 'Teresina de Goiás',
    microrregiao: {
      id: 52005,
      nome: 'Chapada dos Veadeiros',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520022,
      nome: 'Flores de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221197,
    nome: 'Terezópolis de Goiás',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221304,
    nome: 'Três Ranchos',
    microrregiao: {
      id: 52017,
      nome: 'Catalão',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520004,
      nome: 'Catalão',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221403,
    nome: 'Trindade',
    microrregiao: {
      id: 52010,
      nome: 'Goiânia',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221452,
    nome: 'Trombas',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520016,
      nome: 'Porangatu',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221502,
    nome: 'Turvânia',
    microrregiao: {
      id: 52009,
      nome: 'Anicuns',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520015,
      nome: 'Palmeiras de Goiás',
      'regiao-intermediaria': {
        id: 5204,
        nome: 'São Luís de Montes Belos - Iporá',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221551,
    nome: 'Turvelândia',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520010,
      nome: 'Rio Verde',
      'regiao-intermediaria': {
        id: 5203,
        nome: 'Rio Verde',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221577,
    nome: 'Uirapuru',
    microrregiao: {
      id: 52001,
      nome: 'São Miguel do Araguaia',
      mesorregiao: {
        id: 5201,
        nome: 'Noroeste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221601,
    nome: 'Uruaçu',
    microrregiao: {
      id: 52004,
      nome: 'Porangatu',
      mesorregiao: {
        id: 5202,
        nome: 'Norte Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520017,
      nome: 'Uruaçu - Niquelândia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221700,
    nome: 'Uruana',
    microrregiao: {
      id: 52006,
      nome: 'Ceres',
      mesorregiao: {
        id: 5203,
        nome: 'Centro Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221809,
    nome: 'Urutaí',
    microrregiao: {
      id: 52016,
      nome: 'Pires do Rio',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520006,
      nome: 'Pires do Rio',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221858,
    nome: 'Valparaíso de Goiás',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520019,
      nome: 'Luziânia',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5221908,
    nome: 'Varjão',
    microrregiao: {
      id: 52014,
      nome: 'Vale do Rio dos Bois',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520001,
      nome: 'Goiânia',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5222005,
    nome: 'Vianópolis',
    microrregiao: {
      id: 52016,
      nome: 'Pires do Rio',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520002,
      nome: 'Anápolis',
      'regiao-intermediaria': {
        id: 5201,
        nome: 'Goiânia',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5222054,
    nome: 'Vicentinópolis',
    microrregiao: {
      id: 52015,
      nome: 'Meia Ponte',
      mesorregiao: {
        id: 5205,
        nome: 'Sul Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520009,
      nome: 'Piracanjuba',
      'regiao-intermediaria': {
        id: 5202,
        nome: 'Itumbiara',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5222203,
    nome: 'Vila Boa',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520022,
      nome: 'Flores de Goiás',
      'regiao-intermediaria': {
        id: 5206,
        nome: 'Luziânia - Águas Lindas de Goiás',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5222302,
    nome: 'Vila Propício',
    microrregiao: {
      id: 52012,
      nome: 'Entorno de Brasília',
      mesorregiao: {
        id: 5204,
        nome: 'Leste Goiano',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 520018,
      nome: 'Ceres - Rialma - Goianésia',
      'regiao-intermediaria': {
        id: 5205,
        nome: 'Porangatu - Uruaçu',
        UF: {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
  {
    id: 5300108,
    nome: 'Brasília',
    microrregiao: {
      id: 53001,
      nome: 'Brasília',
      mesorregiao: {
        id: 5301,
        nome: 'Distrito Federal',
        UF: {
          id: 53,
          sigla: 'DF',
          nome: 'Distrito Federal',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
    'regiao-imediata': {
      id: 530001,
      nome: 'Distrito Federal',
      'regiao-intermediaria': {
        id: 5301,
        nome: 'Distrito Federal',
        UF: {
          id: 53,
          sigla: 'DF',
          nome: 'Distrito Federal',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      },
    },
  },
];

const getStates = () => {
  try {
    return states;
  } catch (error) {
    throw new Error(error);
  }
};

const getCities = stateId => {
  try {
    return cities.filter(city => Number(city.microrregiao.mesorregiao.UF.id) === Number(stateId));
  } catch (error) {
    throw new Error(error);
  }
};

const convertIdToName = stateId => {
  try {
    return states.filter(state => Number(state.id) === Number(stateId));
  } catch (error) {
    throw new Error(error);
  }
};

export default {
  getStates,
  getCities,
  convertIdToName,
};
