import './style.css';

import { useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

import { Modal } from 'react-bootstrap';
import EditorRichText from '../../../../../../components/EditorRichText';
import { Heading } from '../../../../../../components/Heading';
import DataLoading from '../../../../../../components/Loadings/DataLoading';
import Spinner from '../../../../../../components/Loadings/Spinner';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import Banner from './Banner';
import Section from './components/Section';
import Thumbnail from './Thumbnail';

const imageDescriptionInitialState = {
  thumbnail: 'https://via.placeholder.com/1080x1080',
  banner: 'https://via.placeholder.com/1280x720',
  description: '',
};

function ImageDescription() {
  const [imageDescription, setImageDescription] = useState(imageDescriptionInitialState);
  const [thumbnail, setThumbnail] = useState('https://via.placeholder.com/1080x1080');
  const [formType, setFormType] = useState('');
  const [loading, setLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [activeBannersNumber, setActiveBannersNumber] = useState('');

  const { course, loading: isLoadingCourse, updateCourse, deleteBanner } = useCourse();

  useEffect(() => {
    if (course && course.banners) {
      const activeBanners = course?.banners.filter(banner => banner.active);

      setActiveBannersNumber(activeBanners.length);
    }

    setImageDescription({
      banner: course.banner,
      thumbnail: course.thumbnail,
      description: course.description,
    });

    if (course.thumbnail) {
      setThumbnail(course.thumbnail);
    }

    setLoading(false);
  }, [course]);

  function handleThumbnailChange(file) {
    setImageDescription({
      ...imageDescription,
      thumbnail: file,
    });

    setFormType('formThumbnail');
  }

  function handleBannerChange(file, currentBanner) {
    setImageDescription({
      ...imageDescription,
      banner: file,
      bannerId: currentBanner.id,
      bannerOrder: currentBanner.order,
      bannerActive: currentBanner.active ? 1 : 0,
      bannerToShow: course.bannerToShow,
    });

    setFormType('formBanner');
  }

  function handleOrderBannerChange(order, currentBanner) {
    setImageDescription({
      ...imageDescription,
      banner: currentBanner.banner,
      bannerId: currentBanner.id,
      bannerOrder: order,
      bannerActive: currentBanner.active ? 1 : 0,
      bannerToShow: course.bannerToShow,
    });

    setFormType('formBanner');
  }

  function handleAmountBannersToShow(amount, currentBanner) {
    setImageDescription({
      ...imageDescription,
      banner: currentBanner.banner,
      bannerId: currentBanner.id,
      bannerOrder: currentBanner.order,
      bannerActive: currentBanner.active ? 1 : 0,
      bannerToShow: amount,
    });

    setFormType('formBanner');
  }

  function handleChangeVisibilityBanner(checked, currentBanner) {
    setImageDescription({
      ...imageDescription,
      banner: currentBanner.banner,
      bannerId: currentBanner.id,
      bannerOrder: currentBanner.order,
      bannerActive: checked ? 1 : 0,
      bannerToShow: course.bannerToShow,
    });

    setFormType('formBanner');
  }

  function onEditorStateChange(value) {
    if (value) {
      setImageDescription(prevDescription => ({
        ...prevDescription,
        description: value,
      }));
    }

    setFormType('formDescription');
  }

  async function handleDeleteBanner(bannerId) {
    try {
      await deleteBanner(bannerId);
      setMessage('Banner excluído com sucesso');
      setTypeMessage('sucesso');
      setIsShowModal(true);
    } catch (error) {
      setMessage(ErrorResponse(error));
      setTypeMessage('erro');
      setIsShowModal(true);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!imageDescription.bannerId) {
      delete imageDescription.bannerId;
    }

    const activeBanners = course.banners.filter(banner => banner.active);

    if (activeBanners.length < 2 && imageDescription.bannerToShow > 1 && activeBanners.length > 0) {
      setMessage(
        'Você precisa ter mais de 1 banner marcado como "Exibir este banner na página de vendas" para poder exibir 2 ou mais banners simultâneos'
      );
      setTypeMessage('erro');
      setIsShowModal(true);
      return;
    }

    setMessage(null);

    let payload = {};

    if (formType === 'formThumbnail') {
      payload.thumbnail = imageDescription.thumbnail;
    } else if (formType === 'formBanner') {
      if (imageDescription.bannerId) {
        payload.bannerId = imageDescription.bannerId;
      }

      payload.bannerOrder = imageDescription.bannerOrder;
      payload.bannerActive = imageDescription.bannerActive;
      payload.bannerToShow = imageDescription.bannerToShow;
      payload.banner = imageDescription.banner;
    } else if (formType === 'formDescription') {
      payload.description = imageDescription.description;
    } else {
      payload = { ...imageDescription };
    }

    try {
      await updateCourse(payload);
      setMessage('Curso alterado com sucesso');
      setTypeMessage('sucesso');
      setIsShowModal(true);
    } catch (error) {
      setMessage(ErrorResponse(error));
      setTypeMessage('erro');
      setIsShowModal(true);
    }
  }

  return (
    <div className="tab-pane" id="tabItemImagemDescription">
      <div className="row justify-content-center mb-5">
        <div className="col">
          <form onSubmit={handleSubmit}>
            <Thumbnail
              onChange={handleThumbnailChange}
              thumbnail={thumbnail}
              isLoadingCourse={isLoadingCourse}
            />

            <Banner
              data={course.banners}
              onChange={handleBannerChange}
              onChangeOrder={handleOrderBannerChange}
              onChangeAmountBannersToShow={handleAmountBannersToShow}
              onChangeVisibilityBanner={handleChangeVisibilityBanner}
              onDeleteBanner={handleDeleteBanner}
              amountBannersToShow={course.bannerToShow}
              activeBannersNumber={activeBannersNumber}
              isLoadingCourse={isLoadingCourse}
            />

            <Section>
              <div className="mt-2">
                <div className="mb-4">
                  <Heading as="h4" fontSize="2xl">
                    Descrição do produto
                  </Heading>
                </div>

                <p
                  className="mb-4"
                  style={{
                    fontSize: '1.125rem',
                    fontWeight: 400,
                    lineHeight: 1.5,
                    color: '#20212380',
                  }}
                >
                  O uso desta breve descrição varia dependendo do seu tema. Por exemplo, pode ser
                  usado em cartões de cursos e no checkout. Inclua uma breve descrição do seu curso.
                </p>
                <DataLoading className="form-control" loading={loading}>
                  <EditorRichText
                    value={imageDescription?.description || ''}
                    onChange={onEditorStateChange}
                  />
                </DataLoading>
              </div>

              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary my-5 col-3"
                  style={{ fontSize: '0.875rem' }}
                  disabled={isLoadingCourse}
                >
                  {isLoadingCourse ? <Spinner small /> : 'Salvar alterações'}
                </button>
              </div>
            </Section>
          </form>
          <Modal
            centered
            size="sm"
            show={isShowModal}
            onHide={() => setIsShowModal(false)}
            style={{
              backdropFilter: 'blur(4px)',
            }}
          >
            <Modal.Body>
              <div className="row d-flex flex-column align-center justify-content-center mb-1 py-4">
                {typeMessage === 'sucesso' && (
                  <FaCheckCircle
                    className="d-flex justify-content-center mb-2"
                    style={{ color: 'green', height: '50px', width: '50px' }}
                  />
                )}

                {typeMessage === 'erro' && (
                  <FaExclamationCircle
                    className="d-flex justify-content-center mb-2"
                    style={{
                      color: 'red',
                      height: '50px',
                      width: '50px',
                    }}
                  />
                )}
                <h3 className="text-center">{message}</h3>
              </div>
              <div className="d-flex justify-content-center mb-4 ">
                <button
                  onClick={() => setIsShowModal(false)}
                  className="btn btn-light"
                  style={{
                    backgroundColor: '#EB7129',
                    color: 'white',
                    alignItems: 'center',
                  }}
                >
                  Retornar a tela de descrição
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default ImageDescription;
