import {
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IFilter, IFilterParams } from '../..';
import FilterBox from './FilterBox';

interface IParams {
  filters: IFilter;
  setFilters: (filters: IFilter) => void;
  handleCheckboxFilter: (params: IFilterParams) => void;
  areas: any;
  disciplines: any;
  subjects: any;
  isLoading: boolean;
  hasFilters?: boolean;
  drawer?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  btnRef?: any;
  total: number;
}

export default function Filter({
  filters,
  setFilters,
  handleCheckboxFilter,
  drawer,
  isOpen,
  onClose,
  btnRef,
  areas,
  disciplines,
  subjects,
  isLoading,
  hasFilters,
  total,
}: IParams) {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  function clearFilters() {
    setFilters({ area: [], discipline: [], subject: [], quantityOfProducts: [] });

    searchParams.set('page', '1');
    searchParams.delete('search');
    searchParams.delete('area');
    searchParams.delete('discipline');
    searchParams.delete('subject');
    searchParams.delete('quantityOfProducts');
    searchParams.delete('orderBy');

    history.push({ search: searchParams.toString() });
  }

  if (drawer) {
    return (
      <Drawer isOpen={isOpen} size="xs" placement="right" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay backdropFilter="blur(4px)" bg="blackAlpha.700" />
        <DrawerContent>
          <Stack direction="row" margin="15px 15px 5px 15px" alignItems="flex-start" padding={0}>
            <CloseButton
              bgColor="#FFF"
              boxSize="22px"
              fontSize="8px"
              borderRadius="full"
              onClick={onClose}
              position="absolute"
              marginLeft="-25px"
            />

            <Stack
              direction="row"
              marginX="9px"
              width="full"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text color="#202123" fontSize="14px" fontWeight="500" lineHeight="40px">
                {total} {total === 1 ? 'resultado' : 'resultados'}
              </Text>
              <Text
                color="#759AED"
                textAlign="right"
                fontSize="14px"
                fontWeight="400"
                lineHeight="28px"
                textDecorationLine="underline"
                onClick={() => {
                  clearFilters();
                  onClose();
                }}
              >
                Limpar filtros
              </Text>
            </Stack>
          </Stack>

          <DrawerBody>
            <FilterBox
              filters={filters}
              areas={areas}
              disciplines={disciplines}
              subjects={subjects}
              isLoading={isLoading}
              handleCheckboxFilter={handleCheckboxFilter}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <FilterBox
      filters={filters}
      areas={areas}
      disciplines={disciplines}
      subjects={subjects}
      isLoading={isLoading}
      handleCheckboxFilter={handleCheckboxFilter}
      clearFilters={clearFilters}
      hasFilters={hasFilters}
    />
  );
}
