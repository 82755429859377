import { Link } from 'react-router-dom';

import enem from '../../../assets/images/enem.png';
import contests from '../../../assets/images/contests.png';
import legal from '../../../assets/images/legal.png';
import health from '../../../assets/images/health.png';

import CardItem from './CardItem';

import './styles.css';

function CardDestaques() {
  return (
    <section className="section-destaque-main">
      <div className="container-fluid">
        <div className="row justify-content-center section-destaque">
          <Link to="/" className="col col-lg-3 col-xl-3 col-md-6">
            <CardItem content="Enem" image={enem} />
          </Link>

          <Link to="/" className="col-lg-3 col-xl-3 col-md-6">
            <CardItem content="Concursos" image={contests} />
          </Link>

          <Link to="/" className="col-lg-3 col-xl-3 col-md-6">
            <CardItem content="Prática jurídica" image={legal} />
          </Link>

          <Link to="/" className="col-lg-3 col-xl-3 col-md-6">
            <CardItem content="Saúde" image={health} />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default CardDestaques;
