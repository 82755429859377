import { axiosBackend } from './Configuration';

type SectionType = {
  name: string;
  active?: boolean;
  nameSection?: string;
};

const ThemeAPI = {
  createSection: async (theme_id: number, section: SectionType) => {
    try {
      const { data: themeSectionData } = await axiosBackend().post(
        `/themes/${theme_id}/sections`,
        section
      );

      return themeSectionData;
    } catch (error) {
      throw error;
    }
  },
  sectionHeader: async (theme_id: number, payload: any) => {
    try {
      await axiosBackend().patch(`/themes/${theme_id}/section-header`, payload);
    } catch (error) {
      throw error;
    }
  },
  createBannerHero: async (theme_id, payload) => {
    try {
      await axiosBackend().post(`themes/${theme_id}/banner-hero`, payload);
    } catch (error) {
      throw error;
    }
  },
  updateBannerHero: async (theme_id, theme_section_banner_id, banner_id, payload) => {
    try {
      await axiosBackend().patch(
        `/themes/${theme_id}/theme/${theme_section_banner_id}/section-banner/${banner_id}/banner-hero`,
        payload
      );
    } catch (error) {
      throw error;
    }
  },
  updateAbout: async (
    theme_id: number,
    theme_section_biography_id: number,
    biography_id: number,
    payload: any
  ) => {
    try {
      await axiosBackend().patch(
        `/themes/${theme_id}/theme/${theme_section_biography_id}/section-biography/${biography_id}/biography`,
        payload
      );
    } catch (error) {
      throw error;
    }
  },
  storeTestimony: async (theme_id, section_id, payload) => {
    try {
      await axiosBackend().post(
        `/themes/${theme_id}/testimonials/${section_id}/section-testimony`,
        payload
      );
    } catch (error) {
      throw error;
    }
  },
  updateTestimonials: async (theme_id, theme_section_testimony_id, testimony_id, payload) => {
    try {
      await axiosBackend().patch(
        `/themes/${theme_id}/theme/${theme_section_testimony_id}/section-testimony/${testimony_id}/testimony`,
        payload
      );
    } catch (error) {
      throw error;
    }
  },
  deleteTestimony: async (theme_id, theme_section_testimony_id, testimony_id) => {
    try {
      await axiosBackend().delete(
        `/themes/${theme_id}/theme/${theme_section_testimony_id}/section-testimony/${testimony_id}/testimony`
      );
    } catch (error) {
      throw error;
    }
  },
  updateMedias: async (theme_id, theme_section_multimedia_id, multimedia_id, payload) => {
    try {
      await axiosBackend().patch(
        `/themes/${theme_id}/theme/${theme_section_multimedia_id}/section-multimedia/${multimedia_id}/multimedia`,
        payload
      );
    } catch (error) {
      throw error;
    }
  },
  storeMedia: async (theme_id, theme_section_multimedia_id, payload) => {
    try {
      await axiosBackend().post(
        `/themes/${theme_id}/theme/${theme_section_multimedia_id}/section-multimedia/multimedia`,
        payload
      );
    } catch (error) {
      throw error;
    }
  },
};

export default ThemeAPI;
