import process from 'process/browser';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container as ChakraContainer,
} from '@chakra-ui/react';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { NavLink, Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import ProductAPI from '../../api/Course';
import FacebookIntegration from '../../api/FacebookIntegration';
import MarketplaceAPI from '../../api/Marketplace';
import StudentAPI from '../../api/Student';
import UserAPI from '../../api/User';
import Container, { AuthenticationContext } from '../../components/ContainerSite/ContainerSite';
import Toast from '../../components/Toast';
import { useAuth } from '../../contexts/AuthContext';
import ErrorResponse from '../../helpers/ErrorResponse';
import FacebookPixel from '../../helpers/FacebookPixel';
import {
  IS_COOKIES_ACCEPTED,
  ROLE_LOCAL_STORAGE,
  TOKEN_LOCAL_STORAGE,
} from '../../helpers/LocalStorageHelper';
import useQuery from '../../hooks/useQuery';
import Form from './Form';
import PaymentMethod from './pages/PaymentMethod';
import PersonalData from './pages/PersonalData';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import PurchaseDetails from './PurchaseDetails';
import styles from './styles.module.css';
import { IFormData, IProduct, ParamsType, PaymentMethodType } from './types';
import CookieBanner from '../../components/CookieBanner';

export type PlanType = {
  description: string;
  id: number;
  name: string;
  paymentMethod: string;
  recurrence: string;
  value: string;
};

const BRAZILIAN_COUNTRIES = ['br', 'BR', 'Brazil', 'Brasil'];

const initialStateInputsRequired = {
  fullName: {
    isInvalid: false,
  },
  document: {
    number: {
      isInvalid: false,
    },
  },
  email: {
    isInvalid: false,
  },
  ddi: {
    isInvalid: false,
  },
  ddd: {
    isInvalid: false,
  },
  phone: {
    isInvalid: false,
  },
  address: {
    zipCode: {
      isInvalid: false,
    },
    country: {
      isInvalid: false,
    },
    state: {
      isInvalid: false,
    },
    city: {
      isInvalid: false,
    },
    neighborhood: {
      isInvalid: false,
    },
    street: {
      isInvalid: false,
    },
    streetNumber: {
      isInvalid: false,
    },
    alternateAddress: {
      isInvalid: false,
    },
  },
};

const initialStateFormData: IFormData = {
  paymentMethod: 'creditCard',
  fullName: '',
  email: '',
  installments: 1,
  document: {
    type: 'cpf',
    number: '',
  },
  address: {
    street: '',
    streetNumber: '',
    neighborhood: '',
    state: '',
    city: '',
    country: 'br',
    zipCode: '',
    complementary: '',
    alternateAddress: '',
  },
  creditCard: {
    cardHolderName: '',
    cardExpirationDate: '',
    cardNumber: '',
    cardCVV: '',
    cardId: '',
    month: '',
    year: '',
  },
  ddi: '',
  ddd: '',
  phone: '',
  saveRegistrationData: false,
  saveDataForFuturePurchases: false,
  useSavedCreditCard: false,
  subscriptionPlanId: null,
};

function getProducts(products: any[]) {
  return JSON.stringify(
    products.map(({ productId, productOriginalPrice }) => ({
      productId,
      productOriginalPrice,
    }))
  );
}

function hasInvalidValue(obj: any): boolean {
  for (const [, value] of Object.entries(obj)) {
    if (typeof value === 'object' && value !== null) {
      if ('isInvalid' in value && value.isInvalid === true) {
        return true;
      }
      if (hasInvalidValue(value)) {
        return true;
      }
    }
  }

  return false;
}

function SubscriptionCheckout() {
  const [formData, setFormData] = useState(initialStateFormData);
  const [creditCards, setCreditCards] = useState([]);
  const [product, setProduct] = useState<IProduct>({} as IProduct);
  const [standardPaymentValue, setStandardPaymentValue] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [paymentValue, setPaymentValue] = useState<string>('');
  const [courseSubscriptionPlan, setCourseSubscriptionPlan] = useState('');
  const [planSelected, setPlanSelected] = useState<PlanType>();
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inputsRequired, setInputsRequired] = useState(initialStateInputsRequired);
  const [ddi, setDDI] = useState('55');

  const query = useQuery();
  const history = useHistory();
  const { id } = useParams<ParamsType>();
  const { isAuthenticated } = useAuth();

  const country = formData.address.country;
  const payment_method = formData?.paymentMethod.replace('creditCard', 'credit-card');
  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);
  const pathname = window.location.pathname;

  const studentInformation = {
    fullName: formData.fullName,
    email: formData.email,
    ddi: formData.ddi || '55',
    ddd: formData.ddd,
    phone: formData.phone,
    document: {
      number: formData.document.number,
      type: getDocumentType(formData.document.number),
    },
    address: {
      street: formData.address.street,
      streetNumber: formData.address.streetNumber,
      neighborhood: formData.address.neighborhood,
      state: formData.address.state,
      city: formData.address.city,
      country: formData.address.country,
      zipCode: formData.address.zipCode,
      complementary: formData.address.complementary,
      alternateAddress: formData.address.alternateAddress,
    },
  };

  const hasAddress = Object.values(studentInformation).every(value => !!value);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const head = document.getElementsByTagName('head').item(0);

    async function insertPixels() {
      try {
        const { data: pixelData } = await FacebookIntegration.getPixelByProduct(parseInt(id));

        if (pixelData && pixelData.length) {
          let currentPixel;
          let script;

          for (let pixel of pixelData) {
            currentPixel = document.getElementById(pixel.identifier);

            if (currentPixel) continue;

            currentPixel = document.createElement('script');
            currentPixel.id = pixel.courseId;
            currentPixel.setAttribute('prolunofbp', pixel.courseId);

            currentPixel.id = pixel.identifier;
            script = FacebookPixel.baseCode(currentPixel.id);

            currentPixel.innerHTML = script;
            head.appendChild(currentPixel);
          }
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }

    if (head && isAuthenticated && process?.env.NODE_ENV !== 'development') {
      insertPixels();
    }

    return () => {
      const pixels = document.querySelectorAll('[prolunofbp]');

      if (pixels.length) {
        for (let pixel of Array.from(pixels)) {
          head?.removeChild(pixel);
        }
      }
    };
  }, [id, isAuthenticated, pathname, payment_method, isCookiesAccepted]);

  useEffect(() => {
    const courseSubscriptionPlan = query.get('courseSubscriptionPlan');

    if (courseSubscriptionPlan) {
      setCourseSubscriptionPlan(courseSubscriptionPlan);
    }
  }, [query]);

  useEffect(() => {
    (async () => {
      try {
        if (role !== 'ALUNO') {
          return;
        }

        if (!token) {
          return history.push(
            `/product/${product.id}/subscription-checkout/sign-up${
              courseSubscriptionPlan ? `?courseSubscriptionPlan=${courseSubscriptionPlan}` : ''
            }`
          );
        }

        const { data: studentData } = await StudentAPI.getStudentsDataCheckout();

        if (studentData) {
          setFormData(prevFormData => ({
            ...prevFormData,
            fullName: studentData.fullName ?? null,
            email: studentData.email ?? null,
            ddi: studentData.ddi,
            ddd: studentData.ddd,
            phone: studentData.phone,
            document: { ...studentData.document, number: studentData.documentNumber ?? null },
            address: {
              ...studentData.address,
              street: studentData.address.streetAddress ?? null,
              streetNumber: studentData.address.streetNumber ?? null,
              neighborhood: studentData.address.neighborhood ?? null,
              state: studentData.address.state ?? null,
              city: studentData.address.city ?? null,
              zipCode: studentData.address.zipCode ?? null,
              country: BRAZILIAN_COUNTRIES.includes(studentData.address.country)
                ? 'br'
                : studentData.address.country ?? 'br',
              complementary: studentData.address.complementary ?? null,
              alternateAddress: studentData.address.alternateAddress ?? null,
            },
          }));
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    })();
  }, [token, courseSubscriptionPlan, history, product.id, role]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

    if (token) {
      (async () => {
        try {
          const { data: creditCardData } = await UserAPI.creditCard();

          if (creditCardData) {
            setCreditCards(creditCardData);
          }
        } catch (error) {
          Toast(ErrorResponse(error), 'error');
        }
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data: course } = await MarketplaceAPI.show(id);

        if (course) {
          setStandardPaymentValue(course.standardPaymentValue);
          setProduct(course);
        }
      } catch (error) {
        history.push('/');
      }
    })();
  }, [history, id]);

  useEffect(() => {
    const courseSubscriptionPlan = query.get('courseSubscriptionPlan');

    if (product && product.subscription && courseSubscriptionPlan) {
      const plan = product?.plans?.find(
        plan => plan.id === parseInt(courseSubscriptionPlan)
      ) as PlanType;
      setPlanSelected(plan);

      if (planSelected?.paymentMethod) {
        try {
          const paymentMethods = JSON.parse(planSelected?.paymentMethod);

          const currentPaymentMethod = paymentMethods.shift();

          setCurrentPaymentMethod(currentPaymentMethod.replace('credit_card', 'credit-card'));
        } catch {
          Toast('Método de pagamento não identificado!', 'error');
        }
      }
    }
  }, [planSelected?.paymentMethod, product, query]);

  useEffect(() => {
    if (!hasAddress && token) {
      history.push(
        `/product/${id}/subscription-checkout/personal-data${
          courseSubscriptionPlan ? `?courseSubscriptionPlan=${courseSubscriptionPlan}` : ''
        }`
      );
    }
  }, [courseSubscriptionPlan, hasAddress, history, id, token]);

  function handlePaymentMethod(paymentMethod: PaymentMethodType) {
    if (paymentMethod !== formData.paymentMethod) {
      setFormData({
        ...initialStateFormData,
        fullName: formData.fullName,
        ddi: formData.ddi,
        ddd: formData.ddd,
        phone: formData.phone,
        email: formData.email,
        paymentMethod,
        coupon: formData.coupon,
        document: {
          number: formData.document.number,
          type: formData.document.type,
        },
        address: {
          city: formData.address.city,
          country: formData.address.country,
          neighborhood: formData.address.neighborhood,
          state: formData.address.state,
          street: formData.address.street,
          streetNumber: formData.address.streetNumber,
          zipCode: formData.address.zipCode,
          complementary: formData.address.complementary,
          alternateAddress: formData.address.alternateAddress,
        },
      });

      setPaymentValue(standardPaymentValue?.toString() ?? null);

      setErrorMessage('');
    }
  }

  function handleCreditCard(cardCreditData) {
    const cardCredit = {
      ...formData.creditCard,
    };

    setFormData({
      ...formData,
      creditCard: {
        ...cardCredit,
        ...cardCreditData,
        cardExpirationDate: cardCreditData.month.concat(cardCreditData.year),
      },
    });
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const {
      name,
      value,
      checked,
      dataset: { inputgroup },
    } = event.target;

    switch (inputgroup) {
      case 'document':
        const document = { ...formData.document };
        setFormData({ ...formData, document: { ...document, [name]: value } });
        break;
      case 'address':
        const address = { ...formData.address };
        setFormData({ ...formData, address: { ...address, [name]: value } });
        break;
      case 'creditCard':
        const creditCard = { ...formData.creditCard };
        setFormData({ ...formData, creditCard: { ...creditCard, [name]: value } });
        break;
      case 'checkbox':
        setFormData({ ...formData, [name]: checked });
        break;
      default:
        setFormData({ ...formData, [name]: value });
        break;
    }

    if (name === 'installments' && formData.paymentMethod === 'creditCard') {
      const tax = product.baseAmountFeesAndCommissions.find(
        tax => tax.installment === parseInt(value)
      );

      const standardPaymentValueWithTaxes = tax.amount;

      product.standardPaymentValue = standardPaymentValueWithTaxes;
      setPaymentValue(String(product.standardPaymentValue));
    }
  }

  function getDocumentType(value: string) {
    if (cpf.isValid(value)) {
      return 'cpf';
    }

    if (cnpj.isValid(value)) {
      return 'cnpj';
    }

    return 'other';
  }

  function handleRestored() {
    const inputsRequiredUpdated = { ...inputsRequired };
    for (const [key, values] of Object.entries(inputsRequired)) {
      if (key === 'address') {
        for (const [keyValue] of Object.entries(values)) {
          inputsRequiredUpdated[key][keyValue].isInvalid = false;
        }
      } else if (key === 'document') {
        for (const [keyValue] of Object.entries(values)) {
          inputsRequiredUpdated[key][keyValue].isInvalid = false;
        }
      } else if (studentInformation[key]) {
        inputsRequiredUpdated[key].isInvalid = false;
      }
    }
    setInputsRequired(inputsRequiredUpdated);
  }

  async function handleSubmitPersonalData() {
    const inputsRequiredUpdated = {
      ...inputsRequired,
      address: { ...inputsRequired.address },
      document: { ...inputsRequired.document },
    };

    let inputRequiredChange = false;

    for (const [key, values] of Object.entries(inputsRequired)) {
      if (key === 'address') {
        for (const [keyValue] of Object.entries(values)) {
          if (country === 'br') {
            if (!studentInformation[key][keyValue] && keyValue !== 'alternateAddress') {
              inputsRequiredUpdated[key][keyValue].isInvalid = true;
              inputRequiredChange = true;
            }
            if (
              keyValue === 'zipCode' &&
              studentInformation[key][keyValue] &&
              studentInformation[key][keyValue].length < 8
            ) {
              inputsRequiredUpdated[key][keyValue].isInvalid = true;
              inputRequiredChange = true;
            }
          } else if (
            country !== 'br' &&
            keyValue === 'alternateAddress' &&
            !studentInformation[key][keyValue]
          ) {
            inputsRequiredUpdated[key][keyValue].isInvalid = true;
            inputRequiredChange = true;
          }
        }
      } else if (key === 'document') {
        for (const [keyValue] of Object.entries(values)) {
          if (!studentInformation[key][keyValue]) {
            inputsRequiredUpdated[key][keyValue].isInvalid = true;
            inputRequiredChange = true;
          }
        }
      } else if (!studentInformation[key]) {
        inputsRequiredUpdated[key].isInvalid = true;

        inputRequiredChange = true;
      }
    }

    if (studentInformation.ddi !== '55') {
      inputsRequiredUpdated.ddd.isInvalid = false;
      inputsRequiredUpdated.address.city.isInvalid = false;
      inputsRequiredUpdated.address.neighborhood.isInvalid = false;
      inputsRequiredUpdated.address.state.isInvalid = false;
      inputsRequiredUpdated.address.street.isInvalid = false;
      inputsRequiredUpdated.address.streetNumber.isInvalid = false;
      inputsRequiredUpdated.address.zipCode.isInvalid = false;

      delete studentInformation.ddd;
    }

    if (inputRequiredChange) {
      setInputsRequired(inputsRequiredUpdated);

      if (hasInvalidValue(inputsRequiredUpdated)) {
        return;
      }
    }

    try {
      if (studentInformation) {
        await StudentAPI.updateStudentsDataCheckout(studentInformation);

        history.push(
          `/product/${id}/subscription-checkout/payment/${
            currentPaymentMethod ? currentPaymentMethod : `${payment_method}`
          }${courseSubscriptionPlan ? `?courseSubscriptionPlan=${courseSubscriptionPlan}` : ''}`
        );
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  function redirectToThanksPage(params: any) {
    let query = { ...params };

    // Remove parâmetros indefinidos
    for (const key in query) {
      if (!query[key]) {
        delete query[key];
      }
    }

    const queryString = new URLSearchParams(query).toString();

    history.push(`/student-checkout/thanks?${queryString}`);
  }

  async function handleSubmit(event: FormEvent<HTMLDivElement>): Promise<void> {
    event.preventDefault();
    setIsLoading(true);

    try {
      const documentNumber = formData?.document?.number;

      const formattedValues = {
        paymentMethod: formData.paymentMethod,
        fullName: formData.fullName,
        email: formData.email,
        installments: parseInt(formData.installments.toString()),
        document: {
          ...formData.document,
          number: documentNumber,
          type: getDocumentType(documentNumber),
        },
        creditCard: {
          ...formData.creditCard,
          cardHolderName: formData.creditCard?.cardHolderName,
          cardExpirationDate: formData.creditCard?.cardExpirationDate,
          cardNumber: formData?.creditCard?.cardNumber?.replace(/[\D+]/g, ''),
        },
        ddi: formData.ddi,
        ddd: formData.ddd,
        phone: formData.phone,
        saveRegistrationData: false,
        saveDataForFuturePurchases: formData.saveDataForFuturePurchases,
        useSavedCreditCard: formData.creditCard.cardId !== '' ? true : false,
        subscriptionPlanId: parseInt(courseSubscriptionPlan) ?? null,
      };

      delete formattedValues.email;

      if (formData.paymentMethod === 'creditCard') {
        delete formattedValues.creditCard.month;
        delete formattedValues.creditCard.year;
      }

      if (formData.paymentMethod === 'boleto') {
        delete formattedValues.creditCard;
      }

      if (formData.creditCard.cardId) {
        delete formattedValues.creditCard.cardHolderName;
        delete formattedValues.creditCard.cardExpirationDate;
        delete formattedValues.creditCard.cardNumber;
        delete formattedValues.creditCard.cardCVV;
      }

      if (formData.saveDataForFuturePurchases || !formData.creditCard.cardId) {
        delete formattedValues?.creditCard?.cardId;
      }

      const { data: purchaseData } = await ProductAPI.purchaseSubscription(id, formattedValues);

      redirectToThanksPage({
        paymentMethod: formData.paymentMethod,
        standardPaymentValue,
        products: getProducts(purchaseData),
        memberArea: purchaseData?.memberArea,
        emailSupport: purchaseData?.emailSupport,
      });
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <ChakraContainer maxW="container.xl" marginTop="6.25rem" paddingTop={{ base: 0, lg: 14 }}>
        <AuthenticationContext.Consumer>
          {verifyAuthentication => (
            <Form productId={id} onSubmit={handleSubmit}>
              <Box gridArea="breadcrumb" height="2rem">
                <Breadcrumb
                  marginBottom="6"
                  paddingY="1"
                  paddingX="4"
                  fontSize={{ base: 'xs', lg: 'sm' }}
                  fontWeight="bold"
                  separator={<AiOutlineRight style={{ color: 'dark.500' }} />}
                  background="rgba(32, 33, 35, 0.05)"
                  sx={{
                    ol: {
                      display: 'flex',
                      justifyContent: 'space-between',
                    },

                    'ol > li': {
                      display: 'contents',
                    },

                    'ol > li > span': {
                      margin: 0,
                    },

                    'ol > li > a': {
                      margin: 0,
                    },
                  }}
                >
                  <BreadcrumbItem color="dark.500">
                    <BreadcrumbLink
                      as="span"
                      _hover={{ '& > a:hover': { filter: 'none' }, textDecoration: 'none' }}
                    >
                      <NavLink
                        to={
                          `/product/${id}/subscription-checkout/sign-up${
                            courseSubscriptionPlan
                              ? `?courseSubscriptionPlan=${courseSubscriptionPlan}`
                              : ''
                          }` ||
                          `/product/${id}/subscription-checkout/sign-in${
                            courseSubscriptionPlan
                              ? `?courseSubscriptionPlan=${courseSubscriptionPlan}`
                              : ''
                          }`
                        }
                        className={styles.breadcrumbLink}
                        activeClassName={styles.breadcrumbActiveLink}
                      >
                        1. Login/cadastro
                      </NavLink>
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem color="dark.500">
                    <BreadcrumbLink
                      as="span"
                      _hover={{ '& > a:hover': { filter: 'none' }, textDecoration: 'none' }}
                    >
                      <NavLink
                        to={
                          `/product/${id}/subscription-checkout/personal-data${
                            courseSubscriptionPlan
                              ? `?courseSubscriptionPlan=${courseSubscriptionPlan}`
                              : ''
                          }` ||
                          `/product/${id}/subscription-checkout/sign-in${
                            courseSubscriptionPlan
                              ? `?courseSubscriptionPlan=${courseSubscriptionPlan}`
                              : ''
                          }`
                        }
                        className={styles.breadcrumbLink}
                        activeClassName={styles.breadcrumbActiveLink}
                      >
                        2. Dados pessoais
                      </NavLink>
                    </BreadcrumbLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem color="dark.500">
                    <BreadcrumbLink
                      as="span"
                      _hover={{ '& > a:hover': { filter: 'none' }, textDecoration: 'none' }}
                    >
                      <Box
                        onClick={handleSubmitPersonalData}
                        className={styles.breadcrumbLink}
                        color={
                          pathname ===
                          `/product/${id}/subscription-checkout/payment/${
                            currentPaymentMethod ? currentPaymentMethod : `${payment_method}`
                          }${
                            courseSubscriptionPlan
                              ? `?courseSubscriptionPlan=${courseSubscriptionPlan}`
                              : ''
                          }`
                            ? '#EE6924'
                            : 'none'
                        }
                      >
                        3. Pagamento
                      </Box>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Box>

              <Box gridArea="pages">
                <Switch>
                  <Route path={`/product/${id}/subscription-checkout/sign-up*`}>
                    <SignUp
                      productId={id}
                      verifyAuthentication={verifyAuthentication}
                      courseSubscriptionPlan={courseSubscriptionPlan}
                    />
                  </Route>
                  <Route path={`/product/${id}/subscription-checkout/sign-in*`}>
                    <SignIn
                      productId={id}
                      verifyAuthentication={verifyAuthentication}
                      courseSubscriptionPlan={courseSubscriptionPlan}
                    />
                  </Route>
                  <Route path={`/product/${id}/subscription-checkout/personal-data*`}>
                    <PersonalData
                      productId={id}
                      verifyAuthentication={verifyAuthentication}
                      formData={formData}
                      paymentMethod={payment_method}
                      setFormData={setFormData}
                      courseSubscriptionPlan={courseSubscriptionPlan}
                      planPaymentMethod={currentPaymentMethod}
                      hasAddress={hasAddress}
                      handleSubmitPersonalData={handleSubmitPersonalData}
                      inputsRequired={inputsRequired}
                      handleRestored={handleRestored}
                      ddi={ddi}
                      setDDI={setDDI}
                    />
                  </Route>
                  <Route path={`/product/${id}/subscription-checkout/payment/:payment_method*`}>
                    <PaymentMethod
                      productId={id}
                      productName={product.name}
                      values={formData}
                      baseAmountFeesAndCommissions={product.baseAmountFeesAndCommissions}
                      handleChange={handleChange}
                      handleCreditCard={handleCreditCard}
                      handlePaymentMethod={handlePaymentMethod}
                      creditCards={creditCards}
                      isLoading={isLoading}
                      setCreditCards={setCreditCards}
                      courseSubscriptionPlan={courseSubscriptionPlan}
                      planSelected={planSelected}
                      subscription={product.subscription}
                      product={product}
                      studentInformations={studentInformation}
                    />
                  </Route>
                  <Route
                    path="*"
                    component={() => <Redirect to={`/product/${id}v2/student-checkout/sign-up*`} />}
                  />
                </Switch>
              </Box>

              <Box gridArea="purchaseDetails">
                <PurchaseDetails
                  product={product}
                  errorMessage={errorMessage}
                  loading={isLoading}
                  paymentMethod={formData.paymentMethod}
                  paymentValue={paymentValue}
                  setPaymentValue={setPaymentValue}
                  planSelected={planSelected}
                  hasAddress={hasAddress}
                />
              </Box>
            </Form>
          )}
        </AuthenticationContext.Consumer>
      </ChakraContainer>

      <CookieBanner />
    </Container>
  );
}

export default SubscriptionCheckout;
