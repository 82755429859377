import { Box, Button, CloseButton, Stack, Text } from '@chakra-ui/react';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useHandleSubmit from '../../hooks/useHandleSubmit';
const fileDownload = require('../../assets/file-xlsx/Template_Questions_Library.xlsx');

interface IPros {
  onClose: () => void;
  fetchingQuestions: () => Promise<void>;
}

export default function SelectWorksheet({ onClose, fetchingQuestions }: IPros) {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];

    if (file.size > 40 * 1024 * 1024) {
      setError('Tamanho máximo do arquivo excedido (40MB)');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    setFile(formData);

    setUploadedFile(file);

    setError(null);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xls, .xlsx',
    maxSize: 40 * 1024 * 1024,
  });

  const {
    isLoading: isSubmitting,
    error: importError,
    setError: setImportError,
    handleSubmit,
  } = useHandleSubmit({
    data: file,
    url: '/question-library/import-questions',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Importação concluída com sucesso!',
      callback() {
        onClose();
        fetchingQuestions();
      },
    },
    getError: true,
  });

  useEffect(() => {
    if (importError) {
      setError(importError);
    }
  }, [importError]);

  const disabledButton = !uploadedFile || isSubmitting;

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Box as="form" onSubmit={onSubmit}>
      <Stack spacing="4px">
        <Stack spacing="15px">
          <Text>
            <Text
              color="#759AED"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="normal"
              textDecorationLine="underline"
              as="a"
              cursor="pointer"
              href={fileDownload}
              download="Template_Questions_Library.xlsx"
            >
              Clique aqui
            </Text>{' '}
            para baixar o modelo de importação.
          </Text>
          <Text
            color="#202123)"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="20px"
          >
            Selecione sua planilha
          </Text>
        </Stack>

        <Box
          width={{ base: 'full', md: '565px', lg: '565px' }}
          height={{ base: 'auto', md: '189px', lg: '189px' }}
          flexShrink="0"
          border-radius="5px"
          border="1px dashed #AEADAD"
          background="rgba(32, 33, 35, 0.05)"
          hidden={uploadedFile}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            margin="20px"
            alignItems="center"
            {...getRootProps()}
          >
            <Box
              textAlign="center"
              color="rgba(0, 0, 0, 0.49)"
              fontSize="14px"
              fontWeight="400"
              lineHeight="normal"
            >
              <Text>Arraste e solte o arquivo de vídeo aqui</Text>
              <Text>ou</Text>
            </Box>
            <input {...getInputProps()} />
            <Button size="sm" colorScheme="default" variant="outline">
              Selecionar arquivo
            </Button>
            <Text color="#EB7129" fontSize="14px" fontWeight="400" lineHeight="normal">
              Você pode enviar arquivos com a extensão: xlsx.
            </Text>
            <Text color="#EB7129" fontSize="14px" fontWeight="400" lineHeight="normal">
              Tamanho máximo do arquivo: 40MB
            </Text>
          </Box>
        </Box>

        {uploadedFile && (
          <Box
            width={{ base: 'full', md: '564px', lg: '564px' }}
            height={{ base: 'auto', md: '49px', lg: '49px' }}
            borderRadius="6px"
            border="1px solid rgba(0, 0, 0, 0.05)"
            boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
            marginBottom="8px"
          >
            <Stack
              margin="8px 11px"
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              spacing={0}
            >
              <Stack spacing={0} maxW="90%">
                <Text
                  color="#202123"
                  fontSize="14px"
                  noOfLines={1}
                  fontWeight="500"
                  lineHeight="20px"
                >
                  {uploadedFile.name}
                </Text>
                <Text color="#202123" fontSize="12px" fontWeight="400" lineHeight="20px">
                  {(uploadedFile.size / (1024 * 1024)).toFixed(2)} MB
                </Text>
              </Stack>
              <CloseButton
                color="rgba(32, 33, 35, 0.50)"
                onClick={() => {
                  setUploadedFile(null);
                  setError(null);
                  setImportError(null);
                }}
              />
            </Stack>
          </Box>
        )}

        {error && (
          <Text
            color="#BB2124"
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            lineHeight="20px"
          >
            {error}
          </Text>
        )}
      </Stack>

      <Stack
        width="full"
        justifyContent="flex-end"
        direction="row"
        alignItems="center"
        paddingY="16px"
      >
        <Button onClick={onClose}>Cancelar</Button>
        <Button colorScheme="default" type="submit" isDisabled={disabledButton}>
          Importar
        </Button>
      </Stack>
    </Box>
  );
}
