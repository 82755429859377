import Joi from 'joi';
import { ProfileForm } from './types';

export const profileSchemaValidator = Joi.object<ProfileForm>().keys({
  photo: Joi.string().allow(null, ''),
  fullName: Joi.string().required().min(2).max(80).messages({
    'string.empty': 'Nome não pode ficar vazio',
    'string.min': 'Nome deve ter no mínimo 2 caracteres',
    'string.max': 'Nome deve ter no máximo 80 caracteres',
  }),
  email: Joi.string().email({ tlds: false }).required().min(2).max(255).messages({
    'string.email': 'Seu e-mail deve ser nesse formato “nome@email.com”.',
    'string.empty': 'O email não pode ficar vazio.',
    'string.min': 'O email deve ter no mínimo 2 caracteres',
    'string.max': 'O email deve ter no máximo 255 caracteres',
  }),
  country: Joi.string().required().messages({
    'string.empty': 'País não pode ficar vazio',
  }),
  ddi: Joi.string().max(5).required().messages({
    'string.empty': 'DDI não pode ficar vazio',
    'string.max': 'DDI deve ter no máximo 5 caracteres',
  }),
  ddd: Joi.when('ddi', {
    is: '55',
    then: Joi.string()
      .length(2)
      .required()
      .messages({
        'string.length': 'Deve ser 2 dígitos.',
        'any.required': 'DDD é obrigatório.',
        'string.empty': 'Informe o DDD.',
        'string.base': 'Informe o DDD.',
      } as JoiMessages.LanguageMessages),
    otherwise: Joi.string().optional().allow(null, ''),
  }),
  phone: Joi.when('ddi', {
    is: '55',
    then: Joi.string()
      .min(8)
      .max(20)
      .required()
      .messages({
        'string.min': 'Deve conter no mínimo 8 dígitos.',
        'string.max': 'Deve conter no máximo 20 dígitos.',
        'any.required': 'Telefone é obrigatório.',
        'string.empty': 'Informe um telefone.',
        'string.base': 'Informe um telefone.',
      } as JoiMessages.LanguageMessages),
    otherwise: Joi.string()
      .max(20)
      .required()
      .messages({
        'string.max': 'Deve conter no máximo 20 dígitos.',
        'any.required': 'Telefone é obrigatório.',
        'string.empty': 'Informe um telefone.',
        'string.base': 'Informe um telefone.',
      } as JoiMessages.LanguageMessages),
  }),
  dateOfBirth: Joi.string().required().messages({
    'string.empty': 'Data de nascimento não pode ficar vazia',
    'string.base': 'Data de nascimento não pode ficar vazia',
  }),
  documentNumber: Joi.string().required().messages({
    'string.empty': 'Número do documento não pode ficar vazio',
    'string.base': 'Número do documento não pode ficar vazio',
  }),
  zipCode: Joi.when('country', {
    is: 'br',
    then: Joi.string().length(8).required().messages({
      'string.empty': 'CEP não pode ficar vazio',
      'string.base': 'CEP não pode ficar vazio',
      'string.length': 'CEP Deve 8 dígitos.',
    }),
    otherwise: Joi.string().allow(null),
  }),
  streetAddress: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': 'Endereço não pode ficar vazio',
      'string.base': 'Endereço não pode ficar vazio',
    }),
    otherwise: Joi.string().allow(null),
  }),
  streetNumber: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': 'Número da rua não pode ficar vazio',
      'string.base': 'Número da rua não pode ficar vazio',
    }),
    otherwise: Joi.string().allow(null),
  }),
  neighborhood: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': 'Bairro não pode ficar vazio',
      'string.base': 'Bairro não pode ficar vazio',
    }),
    otherwise: Joi.string().allow(null),
  }),
  complementary: Joi.string().allow(null).messages({
    'string.base': 'Complemento deve ser um texto válido',
    'string.empty': 'Complemento deve ser um texto válido',
  }),
  state: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': 'Estado não pode ficar vazio',
      'string.base': 'Estado não pode ficar vazio',
    }),
    otherwise: Joi.string().allow(null),
  }),
  city: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': 'Cidade não pode ficar vazia',
      'string.base': 'Cidade não pode ficar vazia',
    }),
    otherwise: Joi.string().allow(null),
  }),
  alternateAddress: Joi.when('country', {
    is: 'br',
    then: Joi.string().allow(null),
    otherwise: Joi.string().required().messages({
      'string.empty': 'Endereço não pode ficar vazio',
      'string.base': 'Endereço não pode ficar vazio',
    }),
  }),
});
