import { ReactNode } from 'react';
import styles from './styles.module.css';

interface IList {
  children: ReactNode;
}

function List({ children }: IList) {
  return <ul className={styles.questionsList}>{children}</ul>;
}

export default List;
