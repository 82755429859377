import {
  Box,
  Center,
  Heading,
  Spinner,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { useEffect, useMemo, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from '../../../components/Pagination';
import { useAuth } from '../../../contexts/AuthContext';
import useFetch from '../../../hooks/useFetch';
import useHandleSubmit, { ResponseJSON } from '../../../hooks/useHandleSubmit';
import {
  IInitialFilters,
  IQuestionLibraryAreaHasPaginateResult,
  IQuestionLibraryDisciplineHasPaginateResult,
  IQuestionLibraryInstitutionHasPaginateResult,
  IQuestionLibraryQuestionResult,
  IQuestionLibrarySubjectHasPaginateResult,
} from '../../DashboardAdmin/Courses/CourseManager/ModulesManager/QuestionLibrary/types';
import QuestionCard from '../../HomeAdmin/pages/Question/QuestionCard';
import FilterQuestions from './FilterQuestions';
import StatisticsView from './StatisticsView';

const initialFilters = {
  areaId: '',
  disciplineId: '',
  subjectId: '',
  institutionId: '',
};

export interface IUserAnswer {
  questionId: number;
  answerUserOptionId: number;
}
interface ISubmit {
  questionId: number;
  answerUserOptionId: number;
}

export default function QuestionStudent() {
  const [filters, setFilters] = useState<IInitialFilters>(initialFilters);

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const { membersArea } = useAuth();
  const hasNetflixMemberArea = membersArea === 'netflix';
  const bgColor = useColorModeValue('#FFFFFF', '#37393D');
  const textColor = useColorModeValue('#202123', '#FFFFFF');
  const alternateTextColor = useColorModeValue('#20212390', '#AFAFAF');
  const location = useLocation();
  const history = useHistory();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    setFilters({
      areaId: urlSearchParams.get('filterAreaId') || '',
      disciplineId: urlSearchParams.get('filterDisciplineId') || '',
      subjectId: urlSearchParams.get('filterSubjectId') || '',
      institutionId: urlSearchParams.get('filterInstitutionId') || '',
    });
  }, [urlSearchParams]);

  const searchParams = urlSearchParams.toString();

  const url = searchParams
    ? `/question-library-student/members-area/question-external?${searchParams}`
    : '/question-library-student/members-area/question-external';

  const {
    data: questionLibraryQuestionData,
    loading: isFetching,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<IQuestionLibraryQuestionResult>>({
    url,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const { data: areaData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryAreaHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-area?hasPaginate=false`,
    autoFetch: true,
    authenticated: true,
  });

  const { data: disciplineData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryDisciplineHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-discipline?hasPaginate=false`,
    autoFetch: true,
    authenticated: true,
  });

  const { data: subjectData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibrarySubjectHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-subject?hasPaginate=false`,
    autoFetch: true,
    authenticated: true,
  });

  const { data: institutionData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryInstitutionHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-institution?hasPaginate=false`,
    autoFetch: true,
    authenticated: true,
  });

  let payload: IUserAnswer = {
    questionId: 0,
    answerUserOptionId: 0,
  };

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit<ISubmit, ResponseJSON<any>>({
    data: payload,
    url: '/question-library-student/members-area/question-external/question/answer',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Questão respondida com sucesso!',
      callback(response) {
        const questionAnswered = questions?.find(question => question.id === payload.questionId);

        if (questionAnswered) {
          questionAnswered.userOptionAnswer = {
            optionId: payload.answerUserOptionId,
            answerResult: response.data.data.answerResult,
            rightAnswer: response.data.data.rightAnswer,
          };
        }
      },
    },
  });

  const currentPage = Number(urlSearchParams.get('page')) || 1;

  const response = questionLibraryQuestionData?.data;
  const questions = response?.data;
  const pageCount =
    questionLibraryQuestionData?.data?.total / questionLibraryQuestionData?.data?.per_page;
  const isLoading = isFetching;
  const isSearching = !!searchParams;
  const isQuestionsVisible = !isLoading && questions?.length > 0;
  const isNotFoundVisible = !isLoading && !questions?.length && isSearching;
  const isEmpty = !isLoading && !questions?.length && !isSearching;
  const shouldShowPagination = !isLoading && pageCount > 1;

  async function onSubmit(questionId: number, optionId: number) {
    payload.questionId = questionId;
    payload.answerUserOptionId = optionId;
    handleSubmit();
  }

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  function QuestionView() {
    return (
      <>
        <Box marginX="auto" maxWidth="71.875rem" paddingX={{ base: 4, lg: 0 }}>
          <Heading
            fontSize={{ base: '2xl', lg: '4xl' }}
            marginY="18px"
            color={textColor}
            fontWeight="500"
          >
            Questões
          </Heading>

          <Box
            display="flex"
            alignItems="center"
            gap={2}
            color={textColor}
            fontSize="14px"
            fontWeight="600"
            lineHeight="20px"
            cursor="pointer"
            paddingBottom="18px"
            onClick={onToggle}
          >
            <Text>Filtros</Text>
            <BsChevronDown size={12} />
          </Box>

          <Box hidden={!isOpen}>
            <FilterQuestions
              areaListData={areaData?.data?.data}
              disciplineListData={disciplineData?.data?.data}
              subjectListData={subjectData?.data?.data}
              institutionData={institutionData?.data?.data}
              filters={filters}
              questionsLength={questions?.length}
            />
          </Box>
        </Box>

        <Box mt={10} marginX="auto" maxWidth="71.875rem" paddingX={{ base: 4, lg: 0 }}>
          {isQuestionsVisible &&
            questions.map((question, index) => (
              <Box marginBottom="32px" key={index}>
                <QuestionCard
                  question={question}
                  isSubmitting={isSubmitting}
                  onSubmit={onSubmit}
                  textColor={textColor}
                  alternateTextColor={alternateTextColor}
                  bgColor={bgColor}
                  fetchData={fetchData}
                />
              </Box>
            ))}

          {isEmpty && (
            <Heading fontSize="md" color={textColor} width="full" textAlign="center">
              Ainda não foram adicionadas questões públicas.
            </Heading>
          )}

          {isNotFoundVisible && (
            <Stack width="full" alignItems="center">
              <Heading fontSize="md" color={textColor}>
                Nenhum resultado encontrado
              </Heading>
            </Stack>
          )}

          <Box paddingY={5}>
            {shouldShowPagination && (
              <Pagination
                pageCount={Math.ceil(pageCount)}
                onPageActive={currentPage - 1}
                onPageChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      </>
    );
  }

  if (isLoading) {
    return (
      <Center height="80vh">
        <Spinner color="secondary.500" size="xl" thickness="4px" speed="1s" emptyColor="gray.200" />
      </Center>
    );
  }

  if (!hasNetflixMemberArea) return <QuestionView />;

  return (
    <Box
      marginX="auto"
      marginTop={{ base: 8, lg: 16 }}
      maxWidth="71.875rem"
      minHeight="100vh"
      paddingX={{ base: 4, lg: 0 }}
    >
      <Tabs colorScheme="orange" isLazy>
        <TabList>
          <Tab
            color={textColor}
            _active={{ color: '#EB7129' }}
            _selected={{ color: '#EB7129' }}
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
            paddingX={{ base: '10px', md: '15px', lg: '20px' }}
          >
            Questões
          </Tab>
          <Tab
            color={textColor}
            _active={{ color: '#EB7129' }}
            _selected={{ color: '#EB7129' }}
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
            paddingX={{ base: '10px', md: '15px', lg: '20px' }}
          >
            Estatísticas
          </Tab>
          <Tab
            color={textColor}
            _active={{ color: '#EB7129' }}
            _selected={{ color: '#EB7129' }}
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
            paddingX={{ base: '10px', md: '15px', lg: '20px' }}
            hidden
          >
            Relatórios
          </Tab>
        </TabList>

        <TabIndicator mt="-1.5px" height="3px" bg="#EB7129" borderRadius="2px" />

        <TabPanels>
          <TabPanel padding={0}>
            <QuestionView />
          </TabPanel>

          <TabPanel padding={0}>
            <StatisticsView />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
