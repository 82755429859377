import { Menu, MenuButton, Stack, MenuList, MenuItem } from '@chakra-ui/react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { ICover } from '../..';
import { FormatMediaType } from '../../EditOrNewCovers';
import { ICoverSelectProps } from '..';

interface ICoverProps {
  cover: ICover;
  onOpenEditStatus: () => void;
  onOpenRemoveCover: () => void;
  formatMedia: FormatMediaType;
  setCoverSelect: ({ id, formatMedia, active }: ICoverSelectProps) => void;
}

export default function ActionButton({
  cover,
  onOpenEditStatus,
  onOpenRemoveCover,
  formatMedia,
  setCoverSelect,
}: ICoverProps) {
  const history = useHistory();

  const isImage = formatMedia === 'simple-image';
  const isActive = !!cover.active;

  return (
    <Menu>
      <MenuButton
        width="27px"
        height="24px"
        borderRadius={5}
        bgColor="#20212312"
        _hover={{ bgColor: '#20212330' }}
        _active={{ bgColor: '#20212330' }}
      >
        <Stack justifyContent="center" alignItems="center">
          <HiDotsHorizontal size="18px" color="#00000080" />
        </Stack>
      </MenuButton>
      <MenuList>
        <MenuItem
          color="#979CA6"
          fontWeight={500}
          onClick={() => history.push(`/members-area-management/netflix/${cover.id}/edit-cover`)}
        >
          Editar
        </MenuItem>

        <MenuItem
          color="#979CA6"
          fontWeight={500}
          onClick={() => {
            setCoverSelect({
              id: cover.id,
              active: isActive,
              formatMedia: formatMedia,
            });
            onOpenEditStatus();
          }}
        >
          {isActive ? 'Inativar' : 'Ativar'} {isImage ? 'banner' : 'vídeo'}
        </MenuItem>

        <MenuItem
          color="#BB2124"
          fontWeight={500}
          onClick={() => {
            setCoverSelect({
              id: cover.id,
              active: isActive,
              formatMedia: formatMedia,
            });
            onOpenRemoveCover();
          }}
        >
          Excluir
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
