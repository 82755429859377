import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { MdReorder } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Heading } from '../../../components/Heading';
import DotsFalling from '../../../components/Loadings/DotsFalling';
import { useTheme } from '../../../contexts/ThemeContext';
import ColorSelector from './ColorSelector';
import DraggableSectionsList from './components/DraggableSectionsList';
import ReorderingModal from './components/ReorderingModal';
import Footer from './Footer';
import Header from './Header';
import styles from './styles.module.css';

function PageBuilder() {
  const { getTheme, isLoading, theme } = useTheme();
  const [sections, setSections] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    getTheme();
  }, [getTheme]);

  useEffect(() => {
    const themeSections = theme?.themeSections;
    setSections(themeSections);
  }, [theme]);

  function toggleModal(event) {
    event.preventDefault();
    setIsShowModal(!isShowModal);
  }

  return (
    <>
      {isLoading && (
        <div className="container d-flex justify-content-center mt-5">
          <DotsFalling color="white" />
        </div>
      )}
      {!isLoading && (
        <Box className={styles.pageBuilderContainer}>
          <Flex justifyContent="space-between" my="5">
            <div>
              <Heading>Construa seu site</Heading>
            </div>

            <div>
              <button onClick={toggleModal} className="btn btn-primary py-2">
                <MdReorder />
                Ordenar seções
              </button>

              <Link className="btn btn-primary btn-lg ml-3" to="/">
                Visualizar home
              </Link>
            </div>
          </Flex>
          <div className="mb-5">
            <p className={styles.paragraphHome}>
              Crie o seu próprio site, começando com a página inicial da sua plataforma{' '}
              <b style={{ fontWeight: 600 }}> (www.seunome.com.br)</b>. <br /> Imprima sua
              identidade, escolhendo suas cores, logo e muito mais. Cadastre, duplique e configure
              as seções que vai querer exibir na sua página principal.
            </p>
          </div>

          <ColorSelector />
          <Header />

          <DraggableSectionsList sections={sections} />
          <Footer />
        </Box>
      )}

      <ReorderingModal
        showModal={isShowModal}
        onHideModal={() => setIsShowModal(false)}
        actionButton={{ nameActionButton: 'Salvar' }}
        actionButtonClassName="btn-primary"
        cancelableButton={{
          nameCancelableButton: 'cancelar',
          onCancelableButton: () => setIsShowModal(false),
        }}
        sections={sections}
      />
    </>
  );
}

export default PageBuilder;
