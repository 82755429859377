export const TAG_LOCAL_STORAGE = 'unificada@';
export const ID_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'id';
export const NAME_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'fullName';
export const EMAIL_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'email';
export const TOKEN_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'token';
export const ROLE_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'profile';
export const PHOTO_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'photo';
export const THEME_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'theme';
export const EXPIRES_THEME_IN_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'expires_theme_in';
export const FIRST_ACCESS_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'first_access';
export const LAST_CONTENT_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'last_content';
export const SHOPPING_CART = TAG_LOCAL_STORAGE + 'shopping_cart';
export const IS_COOKIES_ACCEPTED = TAG_LOCAL_STORAGE + 'is_cookies_accepted';
export const SELECTED_COLOR_MODE = TAG_LOCAL_STORAGE + 'selected_color_mode';
export const FILLED_PERSONAL_INFORMATION_TIME =
  TAG_LOCAL_STORAGE + 'filled_personal_information_time';

export const ORIGIN_LOGIN = 'origin-login-';
export const ID_ORIGIN_LOGIN = TAG_LOCAL_STORAGE + ORIGIN_LOGIN + 'id';
export const NAME_ORIGIN_LOGIN = TAG_LOCAL_STORAGE + ORIGIN_LOGIN + 'fullName';
export const TOKEN_ORIGIN_LOGIN = TAG_LOCAL_STORAGE + ORIGIN_LOGIN + 'token';
export const ROLE_ORIGIN_LOGIN = TAG_LOCAL_STORAGE + ORIGIN_LOGIN + 'profile';
export const EMAIL_ORIGIN_LOGIN = TAG_LOCAL_STORAGE + ORIGIN_LOGIN + 'email';
export const PHOTO_ORIGIN_LOGIN = TAG_LOCAL_STORAGE + ORIGIN_LOGIN + 'photo';

const TOKEN_FOR_PURCHASE = 'token-for-purchase';
export const TOKEN_PURCHASE = TAG_LOCAL_STORAGE + TOKEN_FOR_PURCHASE;

const UTM_DATA = 'utm_data';
export const UTM_LOCAL_STORAGE = TAG_LOCAL_STORAGE + UTM_DATA;
export const PRODUCTS_FILTER = TAG_LOCAL_STORAGE + 'products_filter';
export const SUBSCRIPTION_LOCAL_STORAGE = TAG_LOCAL_STORAGE + 'subscription';

export function saveLastContent(
  courseId: number,
  moduleId: number,
  contentId: number,
  contentType: string
): void {
  const lastContentCoursesJSON = localStorage.getItem(LAST_CONTENT_LOCAL_STORAGE);

  if (lastContentCoursesJSON) {
    try {
      const lastContentCourses = JSON.parse(lastContentCoursesJSON);

      const currentCourse = lastContentCourses.find(
        content => content.courseId === courseId.toString()
      );

      if (currentCourse) {
        lastContentCourses.forEach(lastContentCourse => {
          if (lastContentCourse.courseId === courseId) {
            lastContentCourse.moduleId = moduleId;
            lastContentCourse.contentId = contentId;
            lastContentCourse.contentType = contentType;
          }
        });
      } else {
        lastContentCourses.push({ courseId, moduleId, contentId, contentType });
      }

      localStorage.setItem(LAST_CONTENT_LOCAL_STORAGE, JSON.stringify(lastContentCourses));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  } else {
    localStorage.setItem(
      LAST_CONTENT_LOCAL_STORAGE,
      JSON.stringify([{ courseId, moduleId, contentId }])
    );
  }
}

export function getLastContent(courseId: number): any {
  const lastContentCoursesJSON = localStorage.getItem(LAST_CONTENT_LOCAL_STORAGE);

  if (lastContentCoursesJSON) {
    try {
      const lastContentCourses = JSON.parse(lastContentCoursesJSON);

      return lastContentCourses.find(lastContent => lastContent.courseId === courseId.toString());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
}
