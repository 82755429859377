import 'react-quill/dist/quill.snow.css';
import { Box, Spinner } from '@chakra-ui/react';
import process from 'process/browser';
import htmlEditButton from 'quill-html-edit-button';
import ImageUpload from 'quill-image-uploader';
import { Component } from 'react';
import Editor, { Quill } from 'react-quill';
import ErrorResponse from '../../helpers/ErrorResponse';
import UploadHelper from '../../helpers/UploadHelper';
import Toast from '../Toast';

const MAX_IMAGE_FILE_SIZE = 3145728;

Quill.register('modules/imageUploader', ImageUpload);
Quill.register('modules/htmlEditButton', htmlEditButton);

const BASE_API_URL = process.env.REACT_APP_BASE_URL_API;

const isDevelopment = process.env.NODE_ENV === 'development';

export default class EditorRichText extends Component {
  constructor(props) {
    super(props);
    this.quillRef = null;
    this.reactQuillRef = null;
  }

  onIsLoadingChange = isLoading => {
    if (this.props.onIsLoadingChange) {
      this.props.onIsLoadingChange(isLoading);
    }
  };

  formats = [
    'header',
    '|',
    'bold',
    'italic',
    'underline',
    'blockquote',
    'code-block',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'align',
  ];

  modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
          { align: [] },
        ],
        ['link', 'image', 'video'],
      ],
    },

    clipboard: {
      matchVisual: false,
    },

    htmlEditButton: {
      debug: false,
      msg: 'Edite o conteúdo no formato HTML',
      okText: 'Aplicar',
      cancelText: 'Cancelar',
      buttonHTML: '🖥️',
      buttonTitle: 'Mostrar código HTML ',
      syntax: false,
      editorModules: {},
    },

    imageUploader: {
      upload: async file => {
        this.onIsLoadingChange(true);

        return new Promise((resolve, reject) => {
          if (file.size > MAX_IMAGE_FILE_SIZE) {
            reject();
            this.onIsLoadingChange(false);

            return Toast('O tamanho da imagem deve ser de no máximo 3MB.', 'error');
          }

          UploadHelper.upload(file, 'drive')
            .then(response => {
              const { link, newFileName, platformId } = response;

              if (isDevelopment) {
                const link = `${BASE_API_URL}/drive/${newFileName}`;
                return resolve(link);
              }

              if (link) {
                return resolve(link);
              }

              return resolve(
                `https://unificada.s3.amazonaws.com/${platformId}/drive/${newFileName}`
              );
            })
            .catch(error => {
              Toast(ErrorResponse(error), 'error');
              reject(error);
            })
            .finally(() => {
              setTimeout(() => {
                this.onIsLoadingChange(false);
              }, 3000);
            });
        });
      },
    },
  };

  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef?.getEditor();
  };

  onChange = () => {
    const unprivilegedEditor = this.reactQuillRef?.makeUnprivilegedEditor(
      this.reactQuillRef?.getEditor()
    );

    this.props?.onChange(unprivilegedEditor?.getHTML());
  };

  render() {
    const { isLoading } = this.props;

    return (
      <Box position="relative">
        <Editor
          ref={el => {
            this.reactQuillRef = el;
          }}
          theme="snow"
          modules={this?.modules}
          formats={this.formats}
          forwardedRef={this.editorRef}
          value={this?.props?.value}
          onChange={this?.onChange}
          position="relative"
        />
        <Box
          inset={0}
          display={isLoading ? 'flex' : 'none'}
          position="absolute"
          zIndex={999}
          overflow="hidden"
          alignItems="center"
          justifyContent="center"
          background="hsla(0,0%,100%,0.75)"
        >
          <Spinner color="orange.500" height="5rem" width="5rem" />
        </Box>
      </Box>
    );
  }
}
