import dayjs from 'dayjs';
import { PromotionView } from '../types';

export function formatDate(date: Date) {
  return dayjs(date).format('DD/MM/YYYY HH:mm');
}

export function getDate(date: string) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function getTime(date: string) {
  return dayjs(date).format('HH:mm');
}

export function getActionTextByStatus(status: PromotionView['status']) {
  if (status === 'ATIVO') return 'Inativar';
  return 'Ativar';
}

export function getStatusColor(status: PromotionView['status']) {
  if (status === 'ATIVO') return '#22BB33';
  return '#BB2124';
}
