import { memo, useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { HiDotsVertical } from 'react-icons/hi';
import CategoryApi from '../../../api/Category';
import { Heading } from '../../../components/Heading';
import DotsFalling from '../../../components/Loadings/DotsFalling';
import NotFound from '../../../components/NotFound';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';

function Categories() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModal2, setIsShowModal2] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [id, setId] = useState('');
  const [value, setValue] = useState('');
  const [filter, setFilter] = useState('');
  const history = useHistory();

  useEffect(() => {
    async function getCategories() {
      try {
        const { data: categoriesData } = await CategoryApi.index();

        if (categoriesData) {
          setCategories(categoriesData);
          setFilteredCategories(categoriesData);
        }
      } catch (error) {
        setMessage(ErrorResponse(error, history));
      } finally {
        setLoading(false);
      }
    }

    if (true) {
      getCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCategoryFilter(event) {
    const { value } = event.target;

    setFilter(value);

    if (!value) {
      return setFilteredCategories([...categories]);
    }

    const regexCategoryName = new RegExp(`(.)?${value}(.)?`, 'ig');

    const newCategories = categories.filter(category => regexCategoryName.test(category.name));

    setFilteredCategories(newCategories);
  }

  async function deleteCategory() {
    setLoading(true);

    if (value === 'Excluir') {
      try {
        await CategoryApi.delete(id);

        const { data: newResponse } = await CategoryApi.index();
        setCategories(newResponse);

        const categories = filteredCategories.filter(category => category.id !== id);

        setFilteredCategories(categories);

        setIsShowModal(false);
        setIsShowModal2(true);
      } catch (error) {
        Toast(Error(error));
      } finally {
        setLoading(false);
      }
    }
  }

  function ListCategories({ categories }) {
    return categories.map(({ id, name, courses }) => {
      return (
        <tr key={`categoria${id}`} className="video-library-table-row">
          <td className="h6 mr-2">
            <span style={{ fontWeight: 500 }}>{name}</span>
          </td>
          <td className="h6 mr-2">
            <span style={{ fontWeight: 500 }}>{courses.length}</span>
          </td>
          <td className="h6">
            <div className="categories-btn-editar">
              <div className="btn-group">
                <Link
                  className="btn btn-outline-gray border border-gray"
                  to={`/categories/${id}/edit`}
                  role="button"
                >
                  Editar
                </Link>
                <button
                  type="button"
                  className="btn btn-outline-gray border border-gray px-1 dropdown-toggle dropdown-toggle-split"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <HiDotsVertical />
                </button>
                <div className="dropdown-menu">
                  <button
                    className="dropdown-item"
                    id={id}
                    value={'Excluir'}
                    type="button"
                    onClick={() => {
                      setId(id);
                      setValue('Excluir');
                      setIsShowModal(true);
                    }}
                  >
                    Excluir
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      );
    });
  }

  if (loading && !message) {
    return (
      <div className="container d-flex justify-content-center mt-5">
        <DotsFalling color="white" />
      </div>
    );
  }

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <>
          {message && (
            <div
              className="alert alert-danger"
              role="alert"
              style={{ borderRadius: '0', wordBreak: 'break-all' }}
            >
              <div className="text-center">{message}</div>
            </div>
          )}
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="d-flex justify-content-between border-bottom pb-2">
                <div>
                  <Heading>Categorias</Heading>
                </div>
                <div className="d-flex">
                  <div className="nk-header-search d-none d-sm-none d-xl-flex mr-3">
                    {/* <em className="icon ni ni-search icon-positive"></em> */}
                    <input
                      type="text"
                      className="form-control bg-white-search form-focus-none"
                      placeholder="Pesquisar categoria por nome"
                      name="filter"
                      value={filter}
                      onChange={event => handleCategoryFilter(event)}
                    />
                  </div>
                  <Link to="/categories/new" className="btn btn-primary">
                    <em className="icon ni ni-plus-circle"></em>
                    <span style={{ color: '#fff' }}>Nova Categoria</span>
                  </Link>
                </div>
              </div>
              <p className="paragraph-categories my-4" style={{ fontSize: '1.125rem' }}>
                Nesta seção você poderá agrupar seus produtos com determinada característica em
                categorias, como por exemplo, Produtos Gratuitos, Produtos de determinada área,
                Produtos de até 29,90. Além disso, em{' '}
                <b style={{ fontWeight: 600 }}>Criar seu site</b>, será possível deixar em destaque
                as categorias criadas na sua página inicial.
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12">
              <div className="d-flex justify-content-center align-items-center">
                {!categories.length ? (
                  <div className="d-flex flex-column align-items-center">
                    <NotFound message="Você ainda não possui categorias cadastradas. Clique abaixo para cadastrar uma nova categoria !" />
                    <Link className={`btn btn-primary btn_category`} to="/categories/new">
                      Cadastrar categoria
                    </Link>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th className="mr-5" style={{ fontSize: '1.125rem', fontWeight: 600 }}>
                            Nome
                          </th>

                          <th className="mr-5" style={{ fontSize: '1.125rem', fontWeight: 600 }}>
                            Quantidade de cursos
                          </th>
                          <th style={{ fontSize: '1.125rem', fontWeight: 600 }}>Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ListCategories categories={filteredCategories} />
                      </tbody>
                    </table>
                    <hr />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal
            centered
            size="md"
            show={isShowModal}
            onHide={() => setIsShowModal(false)}
            style={{
              backdropFilter: 'blur(4px)',
            }}
          >
            <Modal.Body>
              <div className="row d-flex justify-content-center mb-1 py-4">
                <FaExclamationCircle
                  className="d-flex justify-content-center mb-2"
                  style={{
                    color: '#EB7129',
                    height: '60px',
                    width: '60px',
                  }}
                />
                <h3 className="text-center">
                  Você deseja excluir a categoria ? Após a exclusão, todas as informações referentes
                  a essa categoria também serão excluídas !
                </h3>
              </div>
              <div className="row d-flex justify-content-end mb-1 ">
                <button
                  type="button"
                  onClick={() => setIsShowModal(false)}
                  className="btn btn-danger "
                  style={{
                    color: 'white',
                  }}
                  disabled={loading}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={() => deleteCategory()}
                  className="btn btn-light ml-5"
                  style={{
                    backgroundColor: '#EB7129',
                    color: 'white',
                    marginRight: '100px',
                  }}
                  disabled={loading}
                >
                  Confirmar
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            size="sm"
            show={isShowModal2}
            onHide={() => setIsShowModal2(false)}
            style={{
              backdropFilter: 'blur(4px)',
            }}
          >
            <Modal.Body>
              <div className="row d-flex justify-content-center mb-1 py-4">
                <FaCheckCircle
                  className="d-flex justify-content-center mb-2"
                  style={{ color: 'green', height: '50px', width: '50px' }}
                />

                <h3 className="text-center">Categoria deletada com sucesso !</h3>
              </div>
              <div className="d-flex justify-content-center mb-4 ">
                <button
                  onClick={() => {
                    setIsShowModal2(false);
                  }}
                  className="btn btn-light"
                  style={{
                    backgroundColor: '#EB7129',
                    color: 'white',
                    alignItems: 'center',
                  }}
                >
                  Retornar a tela de categorias
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </div>
    </div>
  );
}

export default memo(Categories);
