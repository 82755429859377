import { Box, BoxProps, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { Promotion } from '../../types';
import Timer from './Timer';
import usePreview from './usePreview';

interface PreviewProps extends BoxProps {
  imagePreview: string;
  promotion: Partial<Promotion>;
}

export default function Preview({ imagePreview, promotion, ...rest }: PreviewProps) {
  const { timeLeft } = usePreview(promotion);

  const primaryColor = promotion?.primaryColor ?? '#eb7129';
  const secondaryColor = promotion?.secondaryColor ?? '#ffffff';
  const urlPreview = imagePreview ?? promotion.logoMain;

  return (
    <Box as="section" {...rest}>
      <Heading fontSize="xl" fontWeight="medium">
        Preview
      </Heading>

      <Text mt={2} color="#20212380">
        Sua faixa de promoção está assim.
      </Text>

      <HStack
        justify="space-between"
        align="center"
        bgColor={primaryColor}
        w="full"
        px={{ base: 4, xl: 10 }}
        py={4}
        mt={2}
        justifyContent="space-between"
        zIndex={1}
      >
        {/* MOBILE */}
        <VStack display={{ base: 'flex', xl: 'none' }} align="start" maxWidth="56%">
          {promotion.typeMain === 'image' ? (
            <Box
              display={{ base: 'block', xl: 'none' }}
              backgroundSize="contain"
              backgroundRepeat="no-repeat"
              backgroundPosition="left"
              width="full"
              maxWidth="5.5rem"
              height="2.5rem"
              backgroundImage={`url("${urlPreview}")`}
            />
          ) : (
            <Text fontSize="md" fontWeight="bold" color={secondaryColor}>
              {promotion?.textMain || 'Texto Principal'}
            </Text>
          )}

          <Text
            fontSize="sm"
            fontWeight="normal"
            color={promotion?.secondaryColor}
            dangerouslySetInnerHTML={{ __html: promotion?.textHighlight || 'Texto Secundário' }}
            sx={{ p: { textAlign: 'left !important' } }}
          />
        </VStack>

        {/* DESKTOP */}
        {promotion.typeMain === 'image' ? (
          <Box
            display={{ base: 'none', xl: 'block' }}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="left"
            width="full"
            maxWidth="8.75rem"
            height="4.5rem"
            backgroundImage={`url("${urlPreview}")`}
          />
        ) : (
          <Text
            display={{ base: 'none', xl: 'block' }}
            fontSize="2xl"
            fontWeight="bold"
            color={secondaryColor}
            maxWidth="21.875rem"
          >
            {promotion?.textMain || 'Texto Principal'}
          </Text>
        )}

        <Text
          display={{ base: 'none', xl: 'block' }}
          fontSize="md"
          fontWeight="medium"
          dangerouslySetInnerHTML={{ __html: promotion?.textHighlight || 'Texto Secundário' }}
          color={secondaryColor}
          maxWidth="30rem"
        />

        <Timer
          timeLeft={timeLeft}
          indicateStartOfPromotion={promotion?.indicateStartOfPromotion}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      </HStack>
    </Box>
  );
}
