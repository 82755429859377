import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';

import { Select as MultiSelect } from 'chakra-react-select';
import CurrencyInput from '../../../../../../components/CurrencyInput';
import { onlyNumber } from '../../../../../../hooks/useHandleChange';
import { MULTI_SELECT_CHAKRA_STYLES } from './constants';
import { PlanManagementEditPaymentProps } from './types';
import useCheckoutSettingsEditPayment from './usePlanManagementEditPayment';
import { Validation } from '../../../../../../hooks/useHandleSubmit';

export default function PlanManagementEditPayment({
  planData,
  onIsLoadingChange,
}: PlanManagementEditPaymentProps) {
  const {
    form,
    isSubmitting,
    formValidation,
    hasChange,
    courseListOptions,
    selectedCourseList,
    isUploading,
    isUpdating,
    handleCourseListChange,
    handleChange,
    handlePlanValueChange,
    handlePlanDurationRadioChange,
    handleStatusRadioChange,
    handleLosingAccessAfterLastPaymentChange,
    handleCancelButtonClick,
    onSubmit,
  } = useCheckoutSettingsEditPayment({
    planData,
    onIsLoadingChange,
  });

  return (
    <>
      <VStack
        as="form"
        onSubmit={onSubmit}
        noValidate
        id="planManagementEditPaymentForm"
        spacing="1.125rem"
        mt={10}
        sx={{
          '.chakra-checkbox__label, .chakra-form__label': { fontSize: 'sm', color: '#202123' },
          '.chakra-radio__control': { mt: '1.5px' },
          input: {
            _placeholder: { color: '#0000005C', fontsize: 'md' },
          },
        }}
      >
        <FormControl isInvalid={formValidation?.name?.isInvalid}>
          <FormLabel>Nome do plano</FormLabel>
          <Input
            name="name"
            value={form?.name}
            onChange={handleChange}
            placeholder="Digite aqui"
            focusBorderColor="gray.300"
          />

          <FormErrorMessage>{formValidation?.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={(formValidation?.courses as unknown as Validation)?.isInvalid}>
          <FormLabel>Produtos</FormLabel>
          <FormHelperText>Selecione os produtos que farão parte do seu plano.</FormHelperText>

          <MultiSelect
            options={courseListOptions}
            value={selectedCourseList}
            onChange={handleCourseListChange}
            menuPlacement="bottom"
            placeholder="Selecione"
            isMulti
            focusBorderColor="gray.300"
            closeMenuOnSelect={true}
            noOptionsMessage={() => 'Nenhum produto encontrado.'}
            menuPortalTarget={document.body}
            chakraStyles={MULTI_SELECT_CHAKRA_STYLES}
          />

          <FormErrorMessage>
            {(formValidation?.courses as unknown as Validation)?.message}
          </FormErrorMessage>
        </FormControl>

        <HStack spacing={5} width="100%" align="start">
          <FormControl isInvalid={formValidation?.value?.isInvalid}>
            <FormLabel>Preço</FormLabel>

            <InputGroup>
              <InputLeftAddon>R$</InputLeftAddon>

              <CurrencyInput
                name="value"
                value={form?.value}
                onChange={handlePlanValueChange}
                focusBorderColor="gray.300"
                placeholder="Digite um valor"
                isDisabled={isUpdating}
              />
            </InputGroup>

            <FormErrorMessage>{formValidation?.value?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={(formValidation?.paymentMethod as unknown as Validation)?.isInvalid}
          >
            <FormLabel>Forma de pagamento</FormLabel>

            <Select
              name="paymentMethod"
              focusBorderColor="gray.300"
              sx={{ option: { color: '#2D374880' } }}
              color="#2D374880"
              isDisabled
            >
              <option>Cartão</option>
            </Select>

            <FormErrorMessage>
              {(formValidation?.courses as unknown as Validation)?.message}
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <FormControl isInvalid={formValidation?.recurrence?.isInvalid}>
          <FormLabel>Recorrência</FormLabel>

          <Select
            name="recurrence"
            value={form?.recurrence}
            onChange={handleChange}
            focusBorderColor="gray.300"
            sx={{ option: { color: '#2D374880' } }}
            color="#2D374880"
            isDisabled={isUpdating}
          >
            <option value="daily">Diário (1 dia)</option>
            <option value="biweekly">Quinzenal (15 dias)</option>
            <option value="monthly">Mensal (30 dias)</option>
            <option value="bimonthly">Bimestral (60 dias)</option>
            <option value="quarterly">Trimestral (60 dias)</option>
            <option value="semiannual">Semestral (180 dias)</option>
            <option value="yearly">Anual (360 dias)</option>
          </Select>

          <FormErrorMessage>{formValidation?.recurrence?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.planDuration?.isInvalid}>
          <FormLabel>Duração do plano</FormLabel>

          <RadioGroup
            name="planDuration"
            value={form?.planDuration}
            onChange={value => handlePlanDurationRadioChange(value)}
            isDisabled={isUpdating}
            sx={{
              '.chakra-radio__control': { mt: '0.25rem' },
              '.chakra-stack': { fontSize: 'sm' },
              color: '#0000007D',
              strong: { fontWeight: 'medium', color: '#202123' },
            }}
          >
            <VStack
              sx={{
                color: '#0000007D',
                fontSize: 'sm',
                strong: { fontWeight: 'medium', color: '#202123' },
              }}
            >
              <Radio
                value="ATE_O_CANCELAMENTO"
                width="100%"
                alignItems="start"
                colorScheme="primary"
              >
                <VStack width="100%" flex={1} spacing={0} justify="end" align="start">
                  <Text as="strong">Até o aluno realizar o cancelamento</Text>
                  <Text>
                    As cobranças irão ocorrer sem limites (de acordo com a recorrência escolhida).
                  </Text>
                </VStack>
              </Radio>

              <Radio
                value="NUMERO_MAXIMO_DE_PAGAMENTOS"
                width="100%"
                alignItems="start"
                colorScheme="primary"
              >
                <VStack width="100%" flex={1} spacing="0.375rem" justify="end" align="start">
                  <Text as="strong">Definir um número máximo de pagamentos</Text>
                  <Text>
                    Defina um número máximo de cobranças, e se o aluno perderá o acesso ou não aos
                    produtos do plano.
                  </Text>
                </VStack>
              </Radio>

              {form?.planDuration === 'NUMERO_MAXIMO_DE_PAGAMENTOS' && (
                <VStack width="100%" pl={6} align="start">
                  <FormControl isInvalid={formValidation?.maxPayments?.isInvalid}>
                    <Input
                      name="maxPayments"
                      value={form?.maxPayments}
                      onChange={handleChange}
                      placeholder="Quantidade de pagamentos"
                      focusBorderColor="gray.300"
                      onInput={onlyNumber}
                      isDisabled={isUpdating}
                    />

                    <FormErrorMessage>{formValidation?.maxPayments?.message}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    w={{ base: 'full', md: 'auto' }}
                    display="flex"
                    flexDirection="column"
                    isInvalid={formValidation?.losingAccessAfterLastPayment?.isInvalid}
                  >
                    <FormLabel as="strong" mb="0" fontSize="sm" fontWeight="semi" color="gray.500">
                      Os alunos devem perder acesso após a última cobrança?
                    </FormLabel>

                    <HStack align="center" mt={2}>
                      <Switch
                        colorScheme="primary"
                        name="losingAccessAfterLastPayment"
                        isChecked={form.losingAccessAfterLastPayment}
                        onChange={handleLosingAccessAfterLastPaymentChange}
                      />

                      <Text>{form.losingAccessAfterLastPayment ? 'Sim' : 'Não'}</Text>
                    </HStack>
                  </FormControl>
                </VStack>
              )}
            </VStack>
          </RadioGroup>

          <FormErrorMessage>{formValidation?.planDuration?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.status?.isInvalid}>
          <FormLabel>Status do plano</FormLabel>

          <RadioGroup
            name="status"
            value={form?.status}
            onChange={value => handleStatusRadioChange(value)}
            sx={{
              '.chakra-stack': { fontSize: 'sm' },
              color: '#0000007D',
              strong: { fontWeight: 'medium', color: '#202123' },
            }}
          >
            <VStack>
              <Radio value="ATIVO" width="100%" alignItems="start" colorScheme="primary">
                <VStack width="100%" flex={1} spacing={0} justify="end" align="start" fontSize="sm">
                  <Text as="strong">Ativo</Text>
                  <Text>O plano ficará visível na plataforma para todos.</Text>
                </VStack>
              </Radio>

              <Radio value="INATIVO" width="100%" alignItems="start" colorScheme="primary">
                <VStack width="100%" flex={1} spacing={0} justify="end" align="start">
                  <Text as="strong">Inativo</Text>
                  <Text>
                    Não será possível fazer nada com o plano, e os alunos perdem o acesso ao plano.
                  </Text>
                </VStack>
              </Radio>

              <Radio value="NAO_LISTADO" width="100%" alignItems="start" colorScheme="primary">
                <VStack width="100%" flex={1} spacing={0} justify="end" align="start">
                  <Text as="strong">Não listado</Text>
                  <Text>
                    O plano não ficará visível na plataforma, e só quem tem acesso link poderá
                    comprar ou adquirir o plano.
                  </Text>
                </VStack>
              </Radio>

              <Radio value="EM_EDICAO" width="100%" alignItems="start" colorScheme="primary">
                <VStack width="100%" flex={1} spacing={0} justify="end" align="start">
                  <Text as="strong">Em edição</Text>
                  <Text>
                    Não será possível fazer nada com o plano, mas os alunos NÃO perdem acesso ao
                    plano.
                  </Text>
                </VStack>
              </Radio>
            </VStack>
          </RadioGroup>

          <FormErrorMessage>{formValidation?.status?.message}</FormErrorMessage>
        </FormControl>
      </VStack>

      <HStack as="footer" w="full" justify="end" spacing={3} mt={10}>
        <Button
          onClick={handleCancelButtonClick}
          variant="ghost"
          background="gray.200"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isDisabled={isUploading || isSubmitting}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          form="planManagementEditPaymentForm"
          colorScheme="primary"
          color="secondary.500"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isDisabled={!isUpdating && !hasChange}
          isLoading={isUploading || isSubmitting}
        >
          Avançar
        </Button>
      </HStack>
    </>
  );
}
