import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Circle,
  Heading,
  HStack,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { PieTooltipProps, ResponsivePie } from '@nivo/pie';
import convertToBrazilianCurrency from '../../../../../../../helpers/convertToBrazilianCurrency';
import { PlansBestSellingType } from '../..';

const COLORS = ['#7FBBB6', '#E85347', '#EFB6E8', '#EDCA32', '#000000'];

interface MostSoldPlansChartProps extends CardProps {
  data: PlansBestSellingType[];
}

const CustomTooltip = ({ datum }: PieTooltipProps<any>) => (
  <Box p={2} bg="white" border="1px solid #ccc" borderRadius="md">
    <strong>{datum.id}</strong>: {String(datum.value)}%
  </Box>
);

export default function MostSoldPlansChart({ data, ...rest }: MostSoldPlansChartProps) {
  const formattedData = data?.map((plan, index) => ({
    id: plan.planId,
    planName: plan.planName,
    subscriptionsNumber: plan.numberOfRecurrences,
    invoicing: plan.invoicing,
    color: COLORS[index],
    percentage: plan.percentage,
  }));

  const chartData = formattedData?.map((plan, index) => ({
    id: plan.planName,
    value: plan.percentage,
    color: plan.color,
  }));

  return (
    <Card
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      padding={4}
      border="1px solid rgba(32, 33, 35, 0.25)"
      {...rest}
    >
      <CardHeader padding={0}>
        <HStack justify="space-between" color="primary.500">
          <Heading as="h3" fontSize="sm" fontWeight="semibold" m={0}>
            Gráfico de Planos Mais Vendidos
          </Heading>
        </HStack>
      </CardHeader>

      <CardBody padding={0}>
        {!chartData?.length ? (
          <Text color="#0000007D">Nenhum resultado encontrado</Text>
        ) : (
          <>
            {' '}
            <Stack
              direction={{ base: 'column', xl: 'row' }}
              align={{ base: 'center', xl: 'start' }}
              justify={{ base: 'start', xl: 'space-between' }}
              spacing={{ base: '2.25rem', xl: '8rem' }}
            >
              <TableContainer w="100%" mt={7} p={0}>
                <Table variant="unstyled" p={0}>
                  <Thead textTransform="none">
                    <Tr
                      sx={{
                        th: {
                          textTransform: 'none',
                          color: '#20212380',
                          fontWeight: 'medium',
                          fontSize: '0.8125rem',
                        },
                      }}
                    >
                      <Th pl={6}>Plano</Th>
                      <Th>Assinaturas</Th>
                      <Th>Faturamento</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {formattedData?.map(plan => (
                      <Tr key={plan.id} sx={{ color: '#202123', fontSize: 'xs' }}>
                        <Td as={Td} px={0}>
                          <HStack align="center">
                            <Circle size="1rem" bg={plan.color} />
                            <Text>{plan.planName}</Text>
                          </HStack>
                        </Td>
                        <Td fontWeight="semibold">{plan.subscriptionsNumber}</Td>
                        <Td fontWeight="semibold">{convertToBrazilianCurrency(plan.invoicing)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              {chartData?.length > 0 && (
                <Box boxSize="18rem" flexShrink={0} order={{ base: -1, xl: 1 }}>
                  <ResponsivePie
                    data={chartData}
                    innerRadius={0.6}
                    colors={{ datum: 'data.color' }}
                    arcLabel={null}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    activeOuterRadiusOffset={8}
                    margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
                    tooltip={CustomTooltip}
                  />
                </Box>
              )}
            </Stack>
          </>
        )}
      </CardBody>
    </Card>
  );
}
