import { axiosBackend } from './Configuration';

const RolesAPI = {
  store: async (email, role) => {
    try {
      await axiosBackend().post(`/roles/send-invitation`, {
        email,
        role,
      });
    } catch (error) {
      throw error;
    }
  },

  update: async (role, user_id) => {
    try {
      await axiosBackend().put('/roles/users', { role, user_id });
    } catch (error) {
      throw error;
    }
  },

  storeNewUser: async user => {
    try {
      await axiosBackend().post(`/roles/user/new`, user);
    } catch (error) {
      throw error;
    }
  },

  delete: async role_id => {
    try {
      await axiosBackend().delete(`/roles/${role_id}`);
    } catch (error) {
      throw error;
    }
  },

  deleteInvite: async user_id => {
    try {
      await axiosBackend().delete(`/users/${user_id}/delete-guest-user`);
    } catch (error) {
      throw error;
    }
  },
};

export default RolesAPI;
