import { Button, ButtonGroup, ButtonProps } from '@chakra-ui/react';
import { useState, MouseEvent } from 'react';

interface FilterButtonsProps {
  initialValue: string;
  isCustomDateRange?: boolean;
  onChange?: (id: string) => void;
}

const BUTTON_OPTIONS = [
  { value: 'today', label: 'Hoje' },
  { value: 'lastSevenDays', label: '7 dias' },
  { value: 'lastFifteenDays', label: '15 dias' },
  { value: 'lastMonth', label: '30 dias' },
];

function getButtonProps(id: string, filter: string, isCustomDateRange: boolean): ButtonProps {
  const isSelected = id === filter && !isCustomDateRange;

  return {
    variant: isSelected ? 'solid' : 'ghost',
    pointerEvents: isSelected ? 'none' : 'auto',
    colorScheme: isSelected ? 'primary' : 'gray',
    color: isSelected ? 'secondary.500' : '#202123',
  };
}

export default function FilterButtons({
  initialValue,
  isCustomDateRange,
  onChange,
}: FilterButtonsProps) {
  const [selectedValue, setSelectedValue] = useState(initialValue);

  function handleChange(event: MouseEvent<HTMLButtonElement>) {
    const newValue = event.currentTarget.value;
    setSelectedValue(newValue);
    onChange?.(newValue);
  }

  return (
    <ButtonGroup
      w={{ base: 'full', md: 'auto' }}
      justifyContent="space-between"
      spacing={0.5}
      sx={{
        button: {
          borderRadius: 'full',
          variant: 'ghost',
          outline: 'none',
          _focus: { boxShadow: 'none', outline: 'none' },
        },
      }}
    >
      {BUTTON_OPTIONS.map(({ value, label }) => (
        <Button
          key={value}
          value={value}
          onClick={handleChange}
          {...getButtonProps(value, selectedValue, isCustomDateRange)}
          size="sm"
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
