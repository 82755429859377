const BASE_UNIT_PRICE = 100;

const Number = {
  normalize: (value: string) => parseInt(value.replace(/([^0-9])/g, '')),

  convertUnitPriceToFloat: (value: number) => {
    return value / BASE_UNIT_PRICE;
  },
  convertFloatToUnitPrice: (value: number) => {
    const unitValue = value * BASE_UNIT_PRICE;

    return Math.round(unitValue);
  },
  subtractByPercentage: (value: number, percentage: number) => value - (percentage / 100) * value,
  getValueByPercentage: (value: number, percentage: number) => (percentage / 100) * value,
  calculateCompoundInterestAndMonthlyCapitalization: (
    amount: number,
    fee: number,
    installmentIndex: number
  ) => {
    return parseFloat(
      (
        (Number.convertUnitPriceToFloat(amount) * fee) /
        (1 - Math.pow(1 + fee, -installmentIndex))
      ).toFixed(2)
    );
  },
};

export default { Number };
