import { Box, Flex, List as ChakraList, ListItem, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Heading } from '../../../../../components/Heading';
import Dropdown from '../../components/Dropdown';
import { Subscriber } from '../../types';
import { convertPaymentMethod, convertSubscriptionStatus } from '../../utils';

function List({ id, subscribers, onOpen, user, setUser }) {
  return (
    <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))" gap="5">
      {subscribers?.length === 0 && (
        <Box
          marginY="4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Heading as="h2" fontSize={{ base: 'md', lg: 'lg' }}>
            Ainda não há informações disponíveis.
          </Heading>
        </Box>
      )}

      {subscribers?.map((subscriber: Subscriber) => (
        <Box
          width="full"
          paddingX="4"
          paddingTop="5"
          paddingBottom="1.5"
          background="white"
          boxShadow="1px 1px 4px rgba(0, 0, 0, 0.25)"
          borderRadius="6px"
          position="relative"
        >
          <Box position="absolute" top="4" right="4">
            <Dropdown
              id={id}
              subscriptionId={subscriber?.subscriptionId}
              userFullName={subscriber?.fullName}
              onOpen={onOpen}
              user={user}
              setUser={setUser}
              subscriptionStatus={subscriber?.subscriptionStatus}
            />
          </Box>
          <Flex gap="4">
            <Box>
              <Text fontSize="md" color="dark.500" fontWeight={600}>
                {subscriber.fullName}
              </Text>
            </Box>
          </Flex>

          <ChakraList
            color="dark.500"
            marginTop="4"
            sx={{ '& > li:not(first-child)': { padding: '0.75rem 0' } }}
          >
            <ListItem
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="1px solid rgba(32, 33, 35, 0.15)"
            >
              <Heading fontSize="sm" color="textColor">
                Forma de <br /> pagamento
              </Heading>
              <Text color="textColor">{convertPaymentMethod(subscriber.paymentMethod)}</Text>
            </ListItem>
            <ListItem
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="1px solid rgba(32, 33, 35, 0.15)"
            >
              <Heading fontSize="sm" color="textColor">
                Plano:
              </Heading>
              <Text color="textColor">{subscriber.subscriptionPlanName}</Text>
            </ListItem>
            <ListItem
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="1px solid rgba(32, 33, 35, 0.15)"
            >
              <Heading fontSize="sm" color="textColor">
                Início do <br /> Período:
              </Heading>
              <Text color="textColor">
                {dayjs(subscriber.currentPeriodStart).format('DD/MM/YYYY')}
              </Text>
            </ListItem>
            <ListItem
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="1px solid rgba(32, 33, 35, 0.15)"
            >
              <Heading fontSize="sm" color="textColor">
                Fim do <br /> Período:
              </Heading>
              <Text color="textColor">
                {dayjs(subscriber.currentPeriodEnd).format('DD/MM/YYYY')}
              </Text>
            </ListItem>
            <ListItem
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderBottom="1px solid rgba(32, 33, 35, 0.15)"
            >
              <Heading fontSize="sm" color="textColor">
                Último <br /> atualização:
              </Heading>
              <Text color="textColor">{dayjs(subscriber.updatedAt).format('DD/MM/YYYY')}</Text>
            </ListItem>
            <ListItem display="flex" justifyContent="space-between">
              <Heading fontSize="sm" color="textColor">
                Status:
              </Heading>
              <Text color="textColor">
                {convertSubscriptionStatus(subscriber.subscriptionStatus)}
              </Text>
            </ListItem>
          </ChakraList>
        </Box>
      ))}
    </Box>
  );
}

export default List;
