export function GetDefaultSalesPageUrl(courseId: string) {
  const location = window.location;
  const salesPageUrl = location.protocol.concat('//', location.hostname, `/product/${courseId}`);

  return salesPageUrl;
}

export function GetDefaultCheckoutPageUrl(courseId: string) {
  const location = window.location;
  const checkoutPageUrl = location.protocol.concat(
    '//',
    location.hostname,
    `/product/${courseId}/student-checkout/payment/credit-card`
  );

  return checkoutPageUrl;
}
