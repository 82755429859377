export function toQueryString(obj: { [key: string]: any }): string {
  const searchParams = new URLSearchParams();

  for (const key in obj) {
    const value = obj[key];

    if (value) {
      searchParams.append(key, value);
    }
  }

  return searchParams.toString();
}
