import convert from '../../../../../../helpers/convertToBrazilianCurrency';
import day from 'dayjs';
import styles from './styles.module.css';
import { translatePurchaseStatus } from '../../../helpers/translatePurchaseStatus';

type Installment = {
  amount: number;
  anticipationFee: number;
  chargeRemainder: boolean;
  fee: number;
  splitAmount: number;
  splitDate: string;
  status: string;
};

function Table({ installments }) {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead className={styles.tableTHeader}>
          <tr>
            <th scope="col" className={styles.borderNone}>
              Parcela
            </th>
            <th scope="col">Status</th>
            <th scope="col">Data</th>
            <th scope="col">Total Bruto</th>
            <th scope="col">Taxa</th>
            <th scope="col">Serviços de antecipação</th>
            <th scope="col" className={styles.borderNone}>
              Responsável pelo estorno
            </th>
          </tr>
        </thead>
        <tbody className={styles.tableTBody}>
          {installments.map((installment: Installment, index: number) => {
            const takePositionOneArray = index + 1;

            return (
              <tr key={index}>
                <td>{takePositionOneArray}</td>
                <td>{translatePurchaseStatus(installment.status)}</td>
                <td>{day(installment.splitDate).format('DD/MM/YYYY')}</td>
                <td>{installment.amount ? convert(installment.amount) : '-'}</td>
                <td>{installment.fee ? `- ${convert(installment.fee)}` : '-'}</td>
                <td>{convert(installment.anticipationFee)}</td>
                <td>{installment.chargeRemainder === true ? 'Sim' : 'Não'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
