import { Avatar, Box, Button, HStack, Image, Stack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { memo } from 'react';
import { AiOutlineShoppingCart as ShoppingCartIcon } from 'react-icons/ai';
import { MdCheckCircle } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { platformsWithFillInMainCard } from '../..';
import { hostname, platformsWithKnowMore, platformsWithPrimaryColorButton } from '../../App';
import { axiosBackend } from '../../api/Configuration';
import { useTheme } from '../../contexts/ThemeContext';
import { TOKEN_LOCAL_STORAGE } from '../../helpers/LocalStorageHelper';
import convertToBrazilianCurrency from '../../helpers/convertToBrazilianCurrency';
import useShoppingCart from '../../hooks/useShoppingCart';
import CategoriesSkeleton from '../../pages/DashboardAdmin/Courses/CourseManager/Configuration/ImageDescription/components/Carousel/Skeletons/Categories';
import Toast from '../Toast';
import { googleTagManagerIds, newEventGTM, removeEventGTM } from '../../helpers/ga-tag-custom';
import { IS_COOKIES_ACCEPTED } from '../../helpers/LocalStorageHelper';

function MainCard({ loading, course, grid = false, checkoutType = 'DEFAULT' }) {
  const { themeMarketplace } = useTheme();
  const history = useHistory();

  const launchCheckout = checkoutType === 'LAUNCH';

  const {
    getShoppingCartFromLocalStorage,
    updateShoppingCartInLocalStorage,
    productExistsInShoppingCart,
  } = useShoppingCart();

  const shoppingCart = getShoppingCartFromLocalStorage();
  const productsInShoppingCart = shoppingCart?.products?.map(product => product.id);

  const isFree = course.coursePriceDefault?.formOfPayment === 'FREE';
  const hasKnowMoreButton = platformsWithKnowMore.includes(hostname);
  const hasPrimaryColorButton = platformsWithPrimaryColorButton.includes(hostname);
  const isSubscription = !!course?.subscription;
  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

  const isTooltipDisabled = course.name.length < 36;

  async function addToShoppingCart() {
    if (shoppingCart?.affiliation) return;

    try {
      const isAuthenticated = !!localStorage.getItem(TOKEN_LOCAL_STORAGE);

      if (isAuthenticated) {
        await axiosBackend().post(`/conversions/${course.id}/add-card`);
      }

      updateShoppingCartInLocalStorage({ id: +course.id });

      Toast('Produto adicionado ao seu carrinho!');
    } catch (error) {
      Toast(error.message, 'error');
    }
  }

  async function addToShoppingCartEventGoogleTagManager() {
    if (isCookiesAccepted === 'false') return;

    const idsGTM = googleTagManagerIds();

    if (course.id) {
      for (const id of idsGTM) {
        newEventGTM({
          id,
          data: {
            currency: 'BRL',
            event: 'addToCart',
            items: {
              item_id: course.id,
              item_name: course.name,
              value: course?.standardPaymentValue,
              quantity: 1,
            },
            total_value: course?.standardPaymentValue,
          },
        });

        removeEventGTM(id, 'addToCart');
      }
    }
  }

  function redirectToSalesPage() {
    history.push(`/product/${course.id}`);
  }

  async function handleAddToCartButtonClick() {
    try {
      await addToShoppingCartEventGoogleTagManager();
      addToShoppingCart();
    } catch (error) {
      Toast(error.message, 'error');
    }
  }

  return (
    <>
      {loading ? (
        <CategoriesSkeleton />
      ) : (
        <Box
          width={{ base: grid ? '166px' : '220px', md: '256px', lg: '256px' }}
          height={{ base: grid ? '300px' : '360px', md: '400px', lg: '400px' }}
          borderRadius={{ base: '6px', md: '10px', lg: '10px' }}
          boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.25)"
          zIndex={10}
          overflow="hidden"
          id="main-card"
        >
          <Link style={{ textDecoration: 'none', margin: 0 }} to={`/product/${course.id}`}>
            {!course.thumbnail ? (
              <Avatar
                name={course.name}
                bg="primary.500"
                size="2xl"
                fontWeight={600}
                fontFamily="montserrat"
                color="secondary.500"
                borderRadius="6px"
                boxSize={{ base: grid ? '166px' : '220px', md: '256px', lg: '256px' }}
              />
            ) : (
              <Image
                borderRadius="6px"
                objectFit={!platformsWithFillInMainCard.includes(hostname) ? 'cover' : 'fill'}
                boxSize={{ base: grid ? '166px' : '220px', md: '256px', lg: '256px' }}
                src={course.thumbnail}
              />
            )}
            <Stack alignItems="center">
              <Tooltip
                label={course.name}
                hasArrow
                placement="top"
                p={2}
                textAlign="center"
                borderRadius="6px"
                bg={themeMarketplace.primaryColor}
                color={themeMarketplace.textColor}
                isDisabled={isTooltipDisabled}
              >
                <Text
                  width={{ base: grid ? '160px' : '190px', md: '230px', lg: '230px' }}
                  fontSize={{ base: '12px', md: '14px', lg: '14px' }}
                  fontWeight={600}
                  noOfLines={1}
                  margin="15px"
                  textAlign="center"
                >
                  {course.name}
                </Text>
              </Tooltip>
            </Stack>
          </Link>

          <Stack flexDirection="column" alignItems="center">
            {!hasKnowMoreButton ? (
              <>
                {isSubscription ? (
                  <>
                    <VStack spacing={0} mt={4}>
                      <Text fontSize="xs" fontWeight="normal">
                        A partir de:
                      </Text>

                      <HStack>
                        <Text
                          as="span"
                          fontSize={{ base: '12px', md: '14px', lg: '14px' }}
                          fontWeight="semibold"
                        >
                          {`${convertToBrazilianCurrency(
                            course?.cheapestSubscriptionPlan?.value
                          )}/mês`}
                        </Text>
                      </HStack>
                    </VStack>
                  </>
                ) : (
                  <Text fontSize="14px" fontWeight={600} margin="8px 8px 0 8px">
                    {isFree
                      ? 'Grátis'
                      : `${convertToBrazilianCurrency(course.coursePriceDefault?.purchasePrice)}`}
                  </Text>
                )}
              </>
            ) : (
              <>
                {isSubscription ? (
                  <>
                    <VStack spacing={0} mt={4}>
                      <Text fontSize="xs" fontWeight="normal">
                        A partir de:
                      </Text>

                      <HStack>
                        <Text as="span" fontSize="sm" fontWeight="semibold">
                          {`${convertToBrazilianCurrency(
                            course?.cheapestSubscriptionPlan?.value
                          )}/mês`}
                        </Text>
                      </HStack>
                    </VStack>
                  </>
                ) : (
                  <Text
                    fontSize={{ base: '10px', md: 'xs', lg: 'xs' }}
                    fontWeight={600}
                    margin="15px 15px 0 15px"
                  >
                    {isFree
                      ? 'Grátis'
                      : `${
                          course.coursePriceDefault?.installments
                        }x de ${convertToBrazilianCurrency(course.valueWithTariffs)}`}
                  </Text>
                )}
              </>
            )}

            <VStack spacing={1} pb={5} width="100%" paddingX={{ base: '13px', md: 3, lg: 3 }}>
              <Button
                onClick={redirectToSalesPage}
                _hover="none"
                bg={hasPrimaryColorButton ? themeMarketplace.primaryColor : '#202123'}
                color={hasPrimaryColorButton ? themeMarketplace.textColor : 'white'}
                w="100%"
                size="xs"
                variant="solid"
                outline="0px"
                _focus={{
                  '&:focus': {
                    borderColor: 'none',
                  },
                }}
                fontSize={{ base: '10px', md: 'small', lg: 'small' }}
                borderRadius="4px"
              >
                Ver mais
              </Button>

              {!isSubscription && !isFree && !shoppingCart?.affiliation && !launchCheckout && (
                <Button
                  onClick={handleAddToCartButtonClick}
                  colorScheme="secondary"
                  color={hasPrimaryColorButton ? themeMarketplace.primaryColor : '#202123'}
                  variant="outline"
                  w="100%"
                  size="xs"
                  outline="0px"
                  _focus={{
                    '&:focus': {
                      borderColor: 'none',
                    },
                  }}
                  leftIcon={
                    productExistsInShoppingCart(course.id, productsInShoppingCart) ? (
                      <MdCheckCircle />
                    ) : (
                      <ShoppingCartIcon style={{ marginTop: '-1.5px' }} />
                    )
                  }
                  fontSize={{ base: '10px', md: 'xs', lg: 'xs' }}
                  borderRadius="4px"
                  isDisabled={productExistsInShoppingCart(course.id, productsInShoppingCart)}
                >
                  {`${
                    productExistsInShoppingCart(course.id, productsInShoppingCart)
                      ? 'Produto adicionado ao carrinho'
                      : 'Adicionar ao carrinho'
                  }`}
                </Button>
              )}

              {!isFree && launchCheckout && (
                <Button
                  as={Link}
                  to={`/launch-checkout/${course.id}/personal-data`}
                  colorScheme="secondary"
                  color={hasPrimaryColorButton ? themeMarketplace.primaryColor : '#202123'}
                  variant="outline"
                  w="100%"
                  size="xs"
                  outline="0px"
                  _focus={{
                    '&:focus': {
                      borderColor: 'none',
                    },
                  }}
                  fontSize={{ base: '10px', md: 'xs', lg: 'xs' }}
                  borderRadius="4px"
                >
                  Comprar agora
                </Button>
              )}
            </VStack>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default memo(MainCard);
