import { InputHTMLAttributes } from 'react';

import { MdSearch as SearchIcon } from 'react-icons/md';

import { Container } from './styles';

type ISearchProps = InputHTMLAttributes<HTMLInputElement> & {};

function Search({ id, name, placeholder, ...rest }: ISearchProps) {
  return (
    <Container>
      <SearchIcon />
      <input id={id} name={name} placeholder={placeholder} {...rest} />
    </Container>
  );
}

export default Search;
