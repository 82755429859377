import { Box, Spinner } from '@chakra-ui/react';

export type LoadingFullPageProps = {
  showLogo?: boolean;
};

export default function LoadingFullPage() {
  return (
    <Box
      inset={0}
      display="flex"
      position="fixed"
      zIndex={999}
      alignItems="center"
      justifyContent="center"
      background="#ffffff"
      opacity={0.88}
    >
      <Spinner color="primary.500" size="xl" thickness="4px" speed="1s" emptyColor="gray.200" />
    </Box>
  );
}
