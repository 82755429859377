import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { PromotionView } from '../types';
import PromotionsTableRow from './PromotionsTableRow';

interface PromotionsTableProps extends TableContainerProps {
  promotions: PromotionView[];
  onPromotionStatusChange: () => void;
  onSelectPromotionToEdit: (promotion: PromotionView) => void;
}

export default function PromotionsTable({
  promotions,
  onPromotionStatusChange,
  onSelectPromotionToEdit,
  ...rest
}: PromotionsTableProps) {
  return (
    <TableContainer {...rest}>
      <Table>
        <Thead>
          <Tr sx={{ th: { color: '#202123BF' } }}>
            <Th>Nome</Th>
            <Th maxWidth="8.875rem" whiteSpace="initial" hidden>
              Qtd. de compras
            </Th>
            <Th>Início</Th>
            <Th>Fim</Th>
            <Th>Status</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>

        <Tbody sx={{ 'tr:nth-of-type(odd)': { background: '#2021230D' } }}>
          {promotions?.map(promotion => (
            <PromotionsTableRow
              key={promotion.id}
              promotion={promotion}
              onPromotionStatusChange={onPromotionStatusChange}
              onSelectPromotionToEdit={onSelectPromotionToEdit}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
