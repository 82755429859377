import React, { ChangeEvent } from 'react';
import { MdSearch } from 'react-icons/md';

import {
  CloseButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';

interface SearchInputProps extends InputProps {
  onFilter: () => void;
}

export default function SearchInput({ value, onChange, onFilter, ...rest }: SearchInputProps) {
  function clearSearch() {
    const syntheticEvent = {
      target: { value: '' },
    } as ChangeEvent<HTMLInputElement>;

    onChange(syntheticEvent);
    onFilter();
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Escape') {
      return clearSearch();
    }

    if (event.key === 'Enter') {
      onFilter();
    }
  }

  return (
    <InputGroup width={{ base: 'full', lg: '20rem' }} size="sm" {...rest}>
      <InputLeftElement
        pointerEvents="none"
        children={<MdSearch color="#2021235C" size={27.45} />}
        ml={2}
        mt={0.5}
      />

      <Input
        type="text"
        borderRadius={6}
        focusBorderColor="gray.300"
        placeholder="Buscar por integração"
        borderColor="gray.300"
        fontSize="sm"
        _placeholder={{ color: '#0000005C' }}
        pl={10}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />

      {value && (
        <InputRightElement
          children={<CloseButton onClick={clearSearch} size="sm" color="gray.500" />}
        />
      )}
    </InputGroup>
  );
}
