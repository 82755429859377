import {
  Box,
  Button,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FormEvent } from 'react';
import { IoMdAlert } from 'react-icons/io';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import { FormatMediaType } from '../EditOrNewCovers';
import { ICover } from '..';

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  covers: ICover[];
  setCovers: (covers: ICover[]) => void;
  coverSelect: {
    id: number;
    formatMedia: FormatMediaType;
    active: boolean;
  };
}

export default function EditCoverStatusModal({
  isOpen,
  onClose,
  covers,
  setCovers,
  coverSelect,
}: IProps) {
  const isImage = coverSelect.formatMedia === 'simple-image';
  const isActive = coverSelect.active;

  const isFalse = 0;
  const isTrue = 1;

  const payload = { active: isActive ? isFalse : isTrue };

  function afterHandleStatus() {
    const coverIndex = covers.findIndex(cover => cover.id === coverSelect.id);

    if (coverIndex === -1) {
      return onClose();
    }

    const currentCovers = [...covers];

    currentCovers[coverIndex].active = payload.active;

    const isActivatingVideo =
      currentCovers[coverIndex].bannerType === 'video' && !coverSelect.active;

    const isActivatingImage =
      currentCovers[coverIndex].bannerType === 'simple-image' && !coverSelect.active;

    if (isActivatingVideo) {
      const updatedCovers = covers.map(cover => {
        if (cover.active === payload.active && cover.id !== currentCovers[coverIndex].id) {
          return { ...cover, active: 0 };
        }
        return cover;
      });

      return setCovers(updatedCovers);
    }

    if (isActivatingImage) {
      const updatedCovers = covers.map(cover => {
        if (cover.active === payload.active && cover.bannerType === 'video') {
          return { ...cover, active: 0 };
        }
        return cover;
      });

      return setCovers(updatedCovers);
    }

    setCovers(currentCovers);

    onClose();
  }

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    url: `/banner-hero-members-area/${coverSelect.id}/update-status`,
    data: payload,
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Status atualizado com sucesso!',
      callback: afterHandleStatus,
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(4px)" />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader textAlign="center">
          <Box width="full" display="flex" justifyContent="center" color="primary.500">
            <IoMdAlert fontSize="48px" />
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center">
          <Heading fontSize="18px" fontStyle="normal" fontWeight="500" lineHeight="28px">
            {isActive ? 'Inativar' : 'Ativar'} {isImage ? 'banner' : 'video'}?
          </Heading>

          {isImage && !isActive && (
            <Text
              color="#718096"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="20px"
            >
              Caso ative esse banner e exista video ativo, ele será inativado.{' '}
            </Text>
          )}

          {!isImage && !isActive && (
            <Text
              color="#718096"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="20px"
            >
              Caso ative esse video e exista outro video ativo, ele será inativado.{' '}
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <HStack width="full" spacing="20px">
            <Button width="full" size="sm" onClick={onClose}>
              Cancelar
            </Button>

            <Button
              width="full"
              size="sm"
              type="submit"
              colorScheme="primary"
              color="secondary.500"
              isDisabled={isSubmitting}
            >
              {isActive ? 'Inativar' : 'Ativar'}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
