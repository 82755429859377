import { useToast } from '@chakra-ui/react';
import { ChangeEvent, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../../utils/scrollToInvalidInput';
import { PlanResponse } from '../types';
import schemaValidator from './schemaValidator';
import { IPlanManagementEditPageThanks } from './types';

export interface UseCheckoutSettingsEditPageThanksProps {
  planData: PlanResponse;
}

export default function useCheckoutSettingsEditPageThanks({
  planData,
}: UseCheckoutSettingsEditPageThanksProps) {
  const { planId } = useParams<{ planId: string }>();
  const { push } = useHistory();
  const toast = useToast();

  const {
    form,
    setForm,
    onChanged: hasChange,
    setOnChanged: setHasChange,
    handleCancel,
    handleChange,
  } = useHandleChange<IPlanManagementEditPageThanks>(
    {},
    planData && {
      thankPageType: planData.thankPageType ?? 'DEFAULT',
      thankPageForApprovedPurchases: planData.thankPageForApprovedPurchases ?? null,
      thankPageForPurchasesAwaitingPayment: planData.thankPageForPurchasesAwaitingPayment ?? null,
      thankPageForPurchasesAwaitingAnalysis: planData.thankPageForPurchasesAwaitingAnalysis ?? null,
    }
  );

  function handleThankPageTypeChange(event: ChangeEvent<HTMLSelectElement>) {
    setHasChange(true);

    const { value } = event.target;

    const newValue = value as IPlanManagementEditPageThanks['thankPageType'];

    if (value === 'DEFAULT') {
      setForm(prevForm => ({ ...prevForm, thankPageType: newValue }));

      delete form.thankPageForApprovedPurchases;
      delete form.thankPageForPurchasesAwaitingAnalysis;
      delete form.thankPageForPurchasesAwaitingPayment;

      return;
    }

    setForm(prevForm => ({
      ...prevForm,
      thankPageType: newValue,
      thankPageForApprovedPurchases: null,
      thankPageForPurchasesAwaitingAnalysis: null,
      thankPageForPurchasesAwaitingPayment: null,
    }));
  }

  function handleCancelButtonClick() {
    if (!hasChange) {
      return push('/recurrence/plan-management');
    }

    handleCancel();
  }

  function handleCopy(_text: string, result: boolean) {
    if (result) {
      toast({ title: 'Script copiado com sucesso!', status: 'success', position: 'top' });
    }
  }

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: form,
    url: `/recurrence-plan/${planId}/page-thanks`,
    method: 'patch',
    authenticated: true,
    schemaValidator,
    onSuccess: {
      callback: () => push('/recurrence/plan-management'),
    },
    removeNullProps: false,
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  return {
    form,
    isSubmitting,
    formValidation,
    hasChange,
    handleThankPageTypeChange,
    handleCopy,
    handleChange,
    handleCancelButtonClick,
    onSubmit,
  };
}
