import { Flex, Box, Button, HStack, Text } from '@chakra-ui/react';

import SelectImageButton from '../SelectImageButton';

function AvatarPreviewMembersArea({ preview, handlePhotoChange, handleDiscardImage }) {
  return (
    <Flex alignItems="center" flexDirection={{ base: 'column', md: 'column', lg: 'row' }} gap={5}>
      {preview && (
        <Box
          boxSize={{ base: '8.25rem', md: '8.25rem' }}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundImage={`url("${preview}")`}
          borderRadius="5px"
          flexShrink={0}
          overflow="hidden"
        />
      )}

      <Box
        flexDirection={{ base: 'column-reverse', md: 'column-reverse', lg: 'column' }}
        display="flex"
        h={{ base: 'auto', md: 'auto', lg: '132px' }}
      >
        <Box
          display="flex"
          justifyContent={{ base: 'center', md: 'center', lg: 'flex-start' }}
          flexDirection="column"
        >
          <Text mb={2} fontSize={{ base: 'md', md: 'md', lg: 'sm' }}>
            Se você deseja excluir ou alterar a imagem atual basta clicar em um dos botões que estão
            logo abaixo.
          </Text>

          <Text fontSize={{ base: 'md', md: 'md', lg: 'sm' }}>
            Extensões válidas: .jpg, .png ou .jpeg
          </Text>
        </Box>
        <HStack
          display="flex"
          flexDirection={{ base: 'column-reverse', md: 'row', lg: 'row' }}
          justifyContent={{ base: 'center', md: 'center', lg: 'flex-start' }}
          spacing={{ base: 0, md: 5, lg: 5 }}
          my={{ base: 2, md: 2, lg: 0 }}
          alignItems="end"
          h={{ base: 'auto', md: 'auto', lg: '132px' }}
        >
          <Button
            onClick={() => handleDiscardImage()}
            variant="outline"
            colorScheme="red"
            size="sm"
            width={{ base: 'full', md: 'auto', lg: 'auto' }}
          >
            Excluir imagem atual
          </Button>

          <SelectImageButton
            preview={preview}
            onDrop={handlePhotoChange}
            onChangeImage={handleDiscardImage}
          />
        </HStack>
      </Box>
    </Flex>
  );
}

export default AvatarPreviewMembersArea;
