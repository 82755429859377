import { Flex, Heading, HStack, IconButton } from '@chakra-ui/react';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import InstructorForm from '../InstructorForm';
import { updateInstructorSchemaValidator } from '../InstructorForm/schemaValidator';
import Loading from '../Loading';
import { Instructor } from '../types';

export default function EditInstructor() {
  const { id: instructorId } = useParams<{ id: string }>();
  const url = `/users/${instructorId}/user-instructor`;

  const { data: response, loading: isLoading } = useFetch<UnificadaFront.ResponseJSON<Instructor>>({
    method: 'get',
    url,
    authenticated: true,
    autoFetch: true,
  });

  const instructor = response?.data;
  const isFormVisible = !isLoading && response?.data;

  return (
    <>
      <Flex justify="space-between" w="full" wrap="wrap">
        <HStack spacing={{ base: 0.25, xl: 0.5 }} ml={{ base: -2, xl: 0 }}>
          <IconButton
            as={Link}
            to={'/instructors'}
            icon={<ChevronLeftIcon size="1.75rem" />}
            aria-label="Voltar"
            variant="ghost"
            colorScheme="gray"
            size="sm"
          />

          <Heading as="h1" fontSize={{ base: 'lg', lg: '2xl' }} fontWeight="semibold" m={0}>
            Editar Instrutor
          </Heading>
        </HStack>
      </Flex>

      {isLoading && <Loading />}

      {isFormVisible && (
        <InstructorForm
          instructor={instructor}
          url={`/users/${instructorId}/instructor`}
          method="patch"
          successMessage="Instrutor atualizado com sucesso!"
          schemaValidator={updateInstructorSchemaValidator}
          mt={10}
        />
      )}
    </>
  );
}
