import { Box, Button, Heading, Link, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';

import path from 'path';
import { HiDownload as DownloadIcon } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import { baseURLBackend } from '../../../../../api/Configuration';
import CourseAPI from '../../../../../api/Course';
import Toast from '../../../../../components/Toast';
import { useStudentCourse } from '../../../../../contexts/CourseStudentContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE, TOKEN_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import PDFViewer from './PDFViewer';
import { scrollbarStyle } from '..';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ViewPdf() {
  const { studentCourse, getContent } = useStudentCourse();
  const { courseId, moduleId, contentId } = useParams<{
    courseId: string;
    moduleId: string;
    contentId: string;
  }>();

  const [content, setContent] = useState({} as any);
  const [hasChange, setHasChange] = useState(false);

  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  useEffect(() => {
    if (studentCourse) {
      const currentContent = getContent(studentCourse, moduleId, contentId);
      if (currentContent) {
        setContent(currentContent);
      }
    }
  }, [contentId, getContent, moduleId, studentCourse]);

  const updateLastVisit = useCallback(async () => {
    try {
      setHasChange(true);
      await CourseAPI.updateLastVisit(courseId, moduleId, contentId, 'PDF');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setHasChange(false);
    }
  }, [contentId, courseId, moduleId]);

  useEffect(() => {
    if (role === 'ALUNO' && studentCourse) {
      updateLastVisit();
    }
  }, [updateLastVisit, role, studentCourse]);

  return (
    <Box
      w="full"
      overflow="auto"
      sx={scrollbarStyle}
      margin={{ base: '0', md: '20px 70px', lg: '20px 70px' }}
    >
      <Box as="section" pb={10} m="auto">
        <Heading size="lg" fontWeight="normal" textAlign="center">
          {content?.title || 'Conteúdo sem título'}
        </Heading>

        <Box mt={4}>{content && content?.download && <PDFViewer pdfUrl={content?.download} />}</Box>

        {content?.download && content?.downloadAvailable && (
          <Box
            h="auto"
            border="2px"
            borderColor="gray.500"
            borderRadius={10}
            display="flex"
            flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            mt={10}
            maxW={{ base: '90%', md: '720px' }}
            marginX="auto"
          >
            <Box m={2}>
              <Text
                isTruncated
                whiteSpace="normal"
                wordBreak="break-all"
                textAlign={{ base: 'center' }}
                noOfLines={1}
              >
                {path.basename(decodeURI(content?.download))}
              </Text>
            </Box>

            <Box m={2}>
              <Link
                _hover={{ textDecoration: 'none' }}
                href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${localStorage.getItem(
                  TOKEN_LOCAL_STORAGE
                )}`}
                download={true}
              >
                <Button colorScheme="primary" size="sm" variant="outline" isDisabled={hasChange}>
                  Fazer o Download
                  <DownloadIcon style={{ marginLeft: 2 }} color="#d55411" />
                </Button>
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ViewPdf;
