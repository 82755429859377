import styles from './styles.module.css';

function Status({ paymentMethod, paymentStatus, link, onClick }) {
  const paymentStatuses = {
    Cancelado: <div className={styles.statusCanceled}>{paymentStatus}</div>,
    default: <div>{paymentStatus}</div>,
    paid: <div className={styles.statusPaid}>Pago</div>,
    refunded: <div className={styles.statusRefunded}>Estornada</div>,

    waiting_payment: (
      <div>
        {paymentMethod === 'pix' && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a className={styles.boletoLink} onClick={onClick} href="#" rel="noopener noreferrer">
            Visualizar código
          </a>
        )}

        {paymentMethod === 'boleto' && (
          <a className={styles.boletoLink} href={link} target="_blank" rel="noopener noreferrer">
            Link do boleto
          </a>
        )}
      </div>
    ),
  };

  return paymentStatuses[paymentStatus] || paymentStatuses.default;
}

export default Status;
