import styles from './styles.module.css';

function StatusPayment({ paymentMethod, paymentStatus, link, onClick }) {
  switch (paymentStatus) {
    case 'paid':
      return <div className={styles.statusPaid}>Pago</div>;
    case 'refused':
      return <div className={styles.statusCanceled}>Recusado</div>;
    case 'waiting_payment':
      return (
        <div>
          {paymentMethod === 'pix' && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a className={styles.boletoLink} onClick={onClick} href="#" rel="noopener noreferrer">
              Visualizar código
            </a>
          )}

          {paymentMethod === 'boleto' && (
            <a className={styles.boletoLink} href={link} target="_blank" rel="noopener noreferrer">
              Link do boleto
            </a>
          )}
        </div>
      );
    case 'refunded':
      return <div className={styles.statusRefunded}>Estornada</div>;
    case 'canceled':
      return <div className={styles.statusCanceled}>Cancelada</div>;
    case 'chargedback':
      return <div>chargedback</div>;
    default:
      return <div>-</div>;
  }
}

export default StatusPayment;
