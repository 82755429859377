export default function getBuyButtonText(paymentMethod: string) {
  if (paymentMethod === 'pix') {
    return 'Gerar QR Code';
  }

  if (paymentMethod === 'boleto') {
    return 'Gerar boleto';
  }

  return 'Confirmar compra';
}
