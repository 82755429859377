import { IoChevronDown } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import { useTheme } from '../../../../contexts/ThemeContext';

import {
  EMAIL_LOCAL_STORAGE,
  NAME_LOCAL_STORAGE,
  PHOTO_LOCAL_STORAGE,
  ROLE_LOCAL_STORAGE,
} from '../../../../helpers/LocalStorageHelper';

import {
  Avatar,
  Box,
  BoxProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  Link as ChakraLink,
} from '@chakra-ui/react';
import useExternalLinkHeader from '../../../../useExternalLinkHeader';

export default function DesktopMenu({ ...rest }: BoxProps) {
  const { themeMarketplace: theme } = useTheme();
  const { membersArea, signOut } = useAuth();
  const { pathname } = useLocation();
  const { setColorMode } = useColorMode();

  const isStudent = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'ALUNO';
  const isAffiliate = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'AFILIADO';
  const isOwner = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'DONO';
  const fullName = localStorage.getItem(NAME_LOCAL_STORAGE);
  const email = localStorage.getItem(EMAIL_LOCAL_STORAGE);
  const photo = localStorage.getItem(PHOTO_LOCAL_STORAGE);

  const hasNetflixMemberArea = membersArea === 'netflix';
  const isDashboardV2 = hasNetflixMemberArea || pathname.includes('/v2');
  const version = isDashboardV2 ? 'v2' : 'v1';

  const externalLink = useExternalLinkHeader();

  function getRoute() {
    if (isAffiliate) return '/dashboard';

    return '/products';
  }

  function handleSignOut() {
    setColorMode('light');
    signOut();
  }

  return (
    <Box {...rest}>
      <Menu>
        <MenuButton>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              bg={theme.textColor}
              color={theme.primaryColor}
              boxSize={10}
              name={fullName}
              src={photo}
            />

            <IoChevronDown size={20} color={theme.textColor} />
          </Stack>
        </MenuButton>

        <MenuList
          bgColor="#37393D"
          zIndex="dropdown"
          color="white"
          overflow="hidden"
          border="none"
          py={0}
          sx={{ '.chakra-menu__menuitem': { bgColor: '#202123' } }}
        >
          <Box padding="10px 20px" bgColor="#37393D">
            <Text>{fullName}</Text>
            <Text fontSize="xs">{email}</Text>
          </Box>

          {!isStudent &&
            !isOwner &&
            (externalLink ? (
              <MenuItem
                as={ChakraLink}
                href={externalLink}
                style={{ textDecoration: 'none' }}
                fontWeight={700}
                padding="10px 20px"
              >
                Página Inicial
              </MenuItem>
            ) : (
              <MenuItem as={Link} to="/" fontWeight={700} padding="10px 20px">
                Página Inicial
              </MenuItem>
            ))}

          {isOwner && (
            <>
              {externalLink ? (
                <MenuItem
                  as={ChakraLink}
                  href={externalLink}
                  style={{ textDecoration: 'none' }}
                  fontWeight={700}
                  padding="10px 20px"
                >
                  Acessar Homepage
                </MenuItem>
              ) : (
                <MenuItem as={Link} to="/" fontWeight={700} padding="10px 20px">
                  Acessar Homepage
                </MenuItem>
              )}
              <MenuItem as={Link} to={'/home'} fontWeight={700} padding="10px 20px">
                Início
              </MenuItem>
            </>
          )}

          {!isStudent && (
            <MenuItem as={Link} to={getRoute()} fontWeight={700} padding="10px 20px">
              {isAffiliate ? 'Dashboard' : 'Meus Produtos'}
            </MenuItem>
          )}

          {isOwner && (
            <MenuItem as={Link} to={'/profile/user'} fontWeight={700} padding="10px 20px">
              Meu Perfil
            </MenuItem>
          )}

          {isStudent && (
            <>
              <MenuItem
                as={Link}
                to={`/members-area/${version}`}
                fontWeight={700}
                padding="10px 20px"
              >
                Meus Produtos
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/members-area/${version}/payments`}
                fontWeight={700}
                padding="10px 20px"
              >
                Pagamentos
              </MenuItem>

              <MenuItem
                as={Link}
                to={`/members-area/${version}/questions`}
                fontWeight={700}
                padding="10px 20px"
              >
                Questões
              </MenuItem>
              <MenuItem
                hidden={isDashboardV2}
                as={Link}
                to={`/members-area/v1/statistics`}
                fontWeight={700}
                padding="10px 20px"
              >
                Estatísticas
              </MenuItem>
              <MenuItem
                hidden
                as={Link}
                to={`/members-area/v1/reports`}
                fontWeight={700}
                padding="10px 20px"
              >
                Relatórios
              </MenuItem>

              <MenuItem
                as={Link}
                to={`/members-area/${version}/profile/user`}
                fontWeight={700}
                padding="10px 20px"
              >
                Perfil
              </MenuItem>
            </>
          )}

          <MenuDivider m={0} borderColor="#37393D" />

          <MenuItem onClick={handleSignOut} fontWeight={700} padding="10px 20px" color="red.500">
            Encerrar sessão
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
