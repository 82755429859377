import { Box, Td, Text, Tr } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { CurrentUserType } from '../..';
import Dropdown from '../../../components/Dropdown';
import { Subscriber } from '../../../types';
import { convertPaymentMethod, convertSubscriptionStatus } from '../../../utils';

interface ITdItemProps {
  id: number;
  subscriber: Subscriber;
  onOpen: () => boolean;
  user: CurrentUserType;
  setUser: (user: CurrentUserType) => CurrentUserType;
}

function TdItem({ id, subscriber, onOpen, user, setUser }: ITdItemProps) {
  return (
    <Tr
      sx={{
        '& > td': {
          borderColor: 'transparent',
        },
      }}
    >
      <Td>
        <Box display="flex" justifyContent="center">
          {subscriber.fullName}
        </Box>
        <Text></Text>
      </Td>
      <Td>{convertPaymentMethod(subscriber.paymentMethod)}</Td>
      <Td>{subscriber.subscriptionPlanName}</Td>
      <Td>
        {subscriber.currentPeriodStart
          ? dayjs(subscriber.currentPeriodStart).format('DD/MM/YYYY')
          : '-'}
      </Td>
      <Td>
        {subscriber.currentPeriodEnd
          ? dayjs(subscriber.currentPeriodEnd).format('DD/MM/YYYY')
          : '-'}
      </Td>
      <Td>{dayjs(subscriber.updatedAt).format('DD/MM/YYYY')}</Td>
      <Td>{convertSubscriptionStatus(subscriber.subscriptionStatus)}</Td>
      <Td>
        <Box
          width="1.7rem"
          paddingY="0.125rem"
          background="rgba(32, 33, 35, 0.12)"
          borderRadius="md"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Dropdown
            id={id}
            subscriptionId={subscriber?.subscriptionId}
            userFullName={subscriber?.fullName}
            onOpen={onOpen}
            user={user}
            setUser={setUser}
            subscriptionStatus={subscriber?.subscriptionStatus}
          />
        </Box>
      </Td>
    </Tr>
  );
}

export default TdItem;
