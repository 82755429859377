import { axiosBackend } from './Configuration';

export default {
  async findAllPoles({ page, perPage }) {
    try {
      const { data: findAllPolesData } = await axiosBackend().get(
        `/v2/find-all-poles${page ? `?page=${page}` : ''}${perPage ? `&perPage=${perPage}` : ''}`
      );

      return findAllPolesData;
    } catch (error) {
      throw error;
    }
  },

  async createPole(payload) {
    try {
      const { data: createPoleData } = await axiosBackend().post('/v2/create-pole', payload);

      return createPoleData;
    } catch (error) {
      throw error;
    }
  },

  async findCoProducersPole() {
    try {
      const { data: coProducersPoleData } = await axiosBackend().get('/users/co-producers-sede');

      return coProducersPoleData;
    } catch (error) {
      throw error;
    }
  },

  async updatedPole(payload) {
    try {
      const { data: updatedPoleData } = await axiosBackend().patch('/v2/update-pole', payload);

      return updatedPoleData;
    } catch (error) {
      throw error;
    }
  },

  async deletePole(id: number) {
    try {
      await axiosBackend().delete(`/v2/delete-pole/${id}`);
    } catch (error) {
      throw error;
    }
  },
};
