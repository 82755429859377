import { Box, Button, Heading, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import QuestionsAPI from '../../../../../../api/Question';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import Toast from '../../../../../../components/Toast';
import { toFixedPercentage } from '../../../../../DashboardAdmin/Courses/CourseManager/Reporting/utils';
import Card from './Card';
import Result from './Result';
import Table from './Table';

interface IReport {
  contentTitle: string;
  courseId: number | string;
  moduleId: number | string;
  contentId: number | string;
  isDisabledButton: boolean;
  hiddenButtonRedoContent: boolean;
  onOpenDisabledButton: () => void;
  onOpen: () => void;
}

interface IStatistics {
  rightQuestions: number;
  wrongQuestions: number;
  studentUseStatistics: number;
}

function Report({
  contentTitle,
  courseId,
  moduleId,
  contentId,
  isDisabledButton,
  onOpenDisabledButton,
  onOpen,
  hiddenButtonRedoContent,
}: IReport) {
  const [statistics, setStatistics] = useState<IStatistics | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const statistics = await QuestionsAPI.student.getStatistics(courseId, moduleId, contentId);

        setStatistics(statistics);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [contentId, courseId, moduleId]);

  return (
    <Stack spacing={{ base: '9px', md: '55px', lg: '55px' }}>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        alignItems="center"
        marginBottom={2}
        justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
      >
        <Heading
          color="#FFF"
          fontSize={{ base: '18px', md: '24px', lg: '24px' }}
          margin="0"
          fontWeight="500"
        >
          {contentTitle}
        </Heading>

        <Button
          onClick={isDisabledButton ? onOpenDisabledButton : onOpen}
          colorScheme={isDisabledButton ? 'gray' : 'orange'}
          hidden={hiddenButtonRedoContent}
          size="sm"
          width={{ base: 'full', md: 'auto', lg: 'auto' }}
        >
          Refazer conteúdo
        </Button>
      </Stack>

      <Box display="flex" flexDirection="column" gap="15px">
        {!isLoading && (
          <>
            <Box
              width="full"
              flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
              display="flex"
              justifyContent="space-between"
              gap="15px"
            >
              <Card
                title="Questões certas"
                data={statistics?.rightQuestions}
                color="#22BB33"
                gridArea="correct"
              />

              <Card
                title="Questões erradas"
                data={statistics?.wrongQuestions}
                color="#BB2124BF"
                gridArea="incorrect"
              />

              <Card
                title="Aproveitamento"
                data={`${toFixedPercentage(statistics?.studentUseStatistics)}%`}
                color="#759AEDBF"
                gridArea="successRate"
              />
            </Box>

            <Box width="full" display="flex" justifyContent="space-between" gap="15px">
              <Result
                rightQuestions={statistics?.rightQuestions}
                wrongQuestions={statistics?.wrongQuestions}
                studentUseStatistics={statistics?.studentUseStatistics}
              />

              <Table courseId={courseId} moduleId={moduleId} contentId={contentId} />
            </Box>
          </>
        )}
      </Box>
    </Stack>
  );
}

export default Report;
