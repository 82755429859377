import { IoMdAlarm as AlarmIcon } from 'react-icons/io';
import { MdContentCopy as CopyIcon } from 'react-icons/md';
import qrCodeImage from '../../../../../../assets/images/qrcode-example.svg';

import { Button, HStack, Image, ListItem, OrderedList, Text, VStack } from '@chakra-ui/react';

export default function PixPreviewWidget() {
  return (
    <HStack
      w="full"
      minHeight="9rem"
      bg="#202123"
      p={5}
      pointerEvents="none"
      align="start"
      spacing={5}
    >
      <VStack maxWidth="11.4375rem" spacing={6}>
        <VStack spacing="0.875rem" w="full">
          <Image src={qrCodeImage} />

          <Button
            size="sm"
            w="full"
            background="#7fcf69"
            color="#ffffff"
            leftIcon={<CopyIcon size={14} />}
          >
            <Text>Copiar código</Text>
          </Button>
        </VStack>

        <HStack
          w="full"
          spacing={3}
          fontWeight="medium"
          lineHeight="1rem"
          borderRadius="md"
          borderWidth={1}
          sx={{ svg: { width: '23px', flexShrink: 0 } }}
          background="#EC711780"
          border="1px solid #EC711780"
          px={3}
          py={4}
          color="#ffffff"
        >
          <AlarmIcon />
          <Text as="span">
            O Qr Code expira em <Text as="strong">20 minutos.</Text>{' '}
          </Text>
        </HStack>
      </VStack>

      <VStack color="#ffffff" justify="start" align="start">
        <Text fontWeight="semibold">
          Ok! Vamos lá. Você obterá acesso ao produto imediatamente após o pagamento.
        </Text>

        <VStack align="start">
          <Text fontWeight="semibold">Passo a passo pelo celular:</Text>

          <OrderedList spacing={2} pl={4}>
            <ListItem>Clique no botão “Copiar código”;</ListItem>
            <ListItem>Abra o aplicativo do seu banco e vá na opção "Pix Copia e Cola";</ListItem>
            <ListItem>Cole o código, confira o valor da transação e confirme o pagamento.</ListItem>
          </OrderedList>
        </VStack>

        <VStack align="start">
          <Text fontWeight="semibold">Passo a passo pelo computador:</Text>

          <OrderedList spacing={2} pl={4}>
            <ListItem>Abra o aplicativo do seu banco e vá na opção "QR Code";</ListItem>
            <ListItem>
              Direcione a câmera do seu celular para o Qr Code que está do lado esquerdo desse
              texto;
            </ListItem>
            <ListItem>confira o valor da transação e confirme o pagamento..</ListItem>
          </OrderedList>

          <Text fontWeight="semibold">Pronto! Agora você terá acesso ao seu produto.</Text>
        </VStack>
      </VStack>
    </HStack>
  );
}
