import { Box } from '@chakra-ui/react';

function PlaybackRateSelect({ value, onChange, highlightColor }) {
  return (
    <Box
      width={{ base: '30px', md: 'full' }}
      display="flex"
      justifyContent={{ base: 'flex-start', lg: 'flex-end' }}
    >
      <Box
        position="relative"
        width="35px"
        height="28px"
        overflow="hidden"
        background="transparent"
        cursor="pointer"
      >
        <Box
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="14px"
          fontWeight={600}
          color={highlightColor}
        >
          {value}x
        </Box>
        <select
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            width: '100%',
            opacity: '0',
            cursor: 'pointer',
          }}
          onChange={onChange}
        >
          <option value={1}>1x</option>
          <option value={1.25}>1.25X</option>
          <option value={1.5}>1.5X</option>
          <option value={1.75}>1.75X</option>
          <option value={2}>2x</option>
        </select>
      </Box>
    </Box>
  );
}

export default PlaybackRateSelect;
