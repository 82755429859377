import React, { useState } from 'react';

import InputMask from 'react-input-mask';
import styles from './styles.module.css';

function CardInput({ form, onChange, isLoading }) {
  const [cardMask, setCardMask] = useState('');

  function handleCardMask(event) {
    const { value } = event.target;
    const isAmexCard = /^3[47]/.test(value);

    const mask = isAmexCard ? '9999 999999 99999' : '9999 9999 9999 9999';

    setCardMask(mask);

    onChange(event);
  }

  return (
    <div className="form-row">
      <div className="col-12 col-md-6">
        <label className="form-label" htmlFor="cardNumber">
          Número do cartão
        </label>
        <InputMask
          name="cardNumber"
          id="cardNumber"
          type="tel"
          required
          mask={cardMask}
          maskChar=""
          alwaysShowMask={false}
          value={form.cardNumber}
          onChange={handleCardMask}
          autoComplete="cc-number"
          className={`form-control ${styles.input}`}
          disabled={isLoading}
        />
        Iniciar minha assinatura
      </div>

      <div className="col-6 col-md-4">
        <label className="form-label" htmlFor="cardExpirationDate">
          Validade
        </label>
        <InputMask
          name="cardExpirationDate"
          id="cardExpirationDate"
          value={form.cardExpirationDate}
          onChange={onChange}
          type="tel"
          placeholder="MM / AA"
          required
          mask="99 / 99"
          maskChar=""
          alwaysShowMask={false}
          className={`form-control ${styles.input}`}
          disabled={isLoading}
        />
      </div>

      <div className="col-6 col-md-2">
        <label className="form-label" htmlFor="cardCVV">
          CVV
        </label>
        <InputMask
          name="cardCVV"
          id="cardCVV"
          value={form.cardCVV}
          onChange={onChange}
          type="tel"
          placeholder="CVV"
          mask="999"
          maskChar=""
          alwaysShowMask={false}
          required
          className={`form-control ${styles.input}`}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}

export default CardInput;
