import {
  Box,
  Button,
  Link as ChakraLink,
  Flex,
  HStack,
  Image,
  useTheme,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import useExternalLinkHeader from '../../../useExternalLinkHeader';
import LoginAsUser from '../../LoginAsUser';
import MenuDropdown from '../../MenuDropdown/MenuDropdown';
import PromotionBanner from '../../PromotionBanner';
import SearchSite from '../../SearchSite';
import ShoppingCartIcon from '../../ShoppingCartIcon';
import { HEADER_DESKTOP_HEIGHT } from '../constants';
import NavMenu from '../NavMenu';
import NotificationsDropDown from '../NotificationsDropDown';
import { IHeaderProps } from '../types';
import useShouldModifyHeader from '../useShouldModifyHeader';

export default function HeaderDesktop({ themeMarketplace, isAuthenticated }: IHeaderProps) {
  const shouldModifyHeader = useShouldModifyHeader();
  const theme = useTheme();
  const externalLink = useExternalLinkHeader();

  return (
    <Box
      as="header"
      background="primary.500"
      position="fixed"
      zIndex={800}
      minWidth="320px"
      width="full"
    >
      <LoginAsUser />

      <Box w="full" mx="auto">
        <Flex
          align="center"
          margin="0 -0.25rem"
          boxShadow="0 0.125rem 0.25rem rgba(43, 55, 72, 0.15)"
          paddingTop={{ base: 1, md: 0 }}
          height={HEADER_DESKTOP_HEIGHT}
        >
          <Box
            margin={shouldModifyHeader && 'auto'}
            flexShrink={0}
            paddingTop="14px"
            paddingBottom="14px"
          >
            {shouldModifyHeader ? (
              <Image
                w={132}
                h={50}
                objectFit="contain"
                src={themeMarketplace.logo}
                alt="logo"
                margin="0 1rem 0 2rem"
              />
            ) : externalLink ? (
              <ChakraLink href={externalLink} style={{ margin: 0 }}>
                <Image
                  w={132}
                  h={50}
                  objectFit="contain"
                  src={themeMarketplace.logo}
                  alt="logo"
                  margin="0 1rem 0 2rem"
                />
              </ChakraLink>
            ) : (
              <Link to="/" style={{ margin: 0 }}>
                <Image
                  w={132}
                  h={50}
                  objectFit="contain"
                  src={themeMarketplace.logo}
                  alt="logo"
                  margin="0 1rem 0 2rem"
                />
              </Link>
            )}
          </Box>

          {!shouldModifyHeader && (
            <Flex w="full" align="center">
              <NavMenu />
              <SearchSite />
              <NotificationsDropDown />

              <VStack ml={isAuthenticated && 1}>
                <ShoppingCartIcon />
              </VStack>

              {!isAuthenticated ? (
                <HStack spacing={5} mr="2.75rem" ml="1.5rem">
                  <Button
                    as={Link}
                    to="/login"
                    variant="outline"
                    colorScheme="primary"
                    color="secondary.500"
                    borderColor="secondary.500"
                    fontSize="md"
                    _hover={{
                      bgColor: theme.colors.secondary?.[500],
                      color: theme.colors.primary?.[500],
                    }}
                  >
                    Entrar
                  </Button>

                  <Button
                    as={Link}
                    to="/cadastre-se"
                    bg="secondary.500"
                    borderColor="secondary.500"
                    fontSize="md"
                    color="primary.500"
                  >
                    cadastrar
                  </Button>
                </HStack>
              ) : (
                <MenuDropdown />
              )}
            </Flex>
          )}
        </Flex>

        <PromotionBanner />
      </Box>
    </Box>
  );
}
