import { useEffect, useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as PixIcon } from '../../../../../assets/icons/pixIcon.svg';
import day from 'dayjs';
import mastercardIcon from '../../../../../assets/icons/mastercard.svg';
import styles from '../styles.module.css';
import visaIcon from '../../../../../assets/icons/visa.png';

export function cardBrand(card) {
  switch (card) {
    case 'visa':
      return <img src={visaIcon} alt={`Bandeira do cartão ${card.brand}`} />;
    case 'mastercard':
      return <img src={mastercardIcon} alt={`Bandeira do cartão ${card.brand}`} />;
    default:
      break;
  }
}

function addSpaceString(string) {
  return string.substr(0, 4) + ' ' + string.substr(4, 5);
}

function PaymentMethod({ transaction }) {
  const [isCopied, setIsCopied] = useState(false);

  const { boleto, card, paymentMethod, pix, purchaseStatus } = transaction;

  useEffect(() => {
    setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 2000);
  }, [isCopied]);

  function handleCopy() {
    setIsCopied(prevCopied => !prevCopied);
  }

  return (
    <div>
      {paymentMethod === 'credit_card' && (
        <>
          <div className={styles.boxHeader}>
            <span className={styles.boxHeading}>Cartão de crédito</span>
            {cardBrand(card.brand)}
          </div>
          <div className={styles.boxBody}>
            <span>{`${addSpaceString(card.firstDigits)}** **** ${card.lastDigits}`}</span>
          </div>
          <div className={styles.boxFooter}>
            <span className={styles.textingFooter}>{card.holderName}</span>
            <span className={styles.textingFooter}>{day(card.expirationDate).format('DD/MM')}</span>
          </div>
        </>
      )}

      {paymentMethod === 'boleto' && (
        <>
          <div className={styles.boxHeader}>
            <span className={styles.boxHeading}>Boleto</span>

            <a href={boleto.boletoUrl} target="_blank" rel="noopener noreferrer">
              Ver boleto
            </a>
          </div>
          <div className={styles.boxBody}>
            <p>{boleto.boletoBarcode}</p>
          </div>
          <div className={styles.boxFooter}>
            <span className={styles.textingFooter}>
              Vencimento: {day(boleto.boletoExpirationDate).format('DD/MM/YYYY')}
            </span>
            <CopyToClipboard text={boleto.boletoBarcode} onCopy={handleCopy}>
              <button className={styles.buttonBoxFooter}>
                Copiar código
                {isCopied && <small className={styles.copy}>Código copiado</small>}
              </button>
            </CopyToClipboard>
          </div>
        </>
      )}

      {paymentMethod === 'pix' && (
        <>
          <div className={styles.boxHeader}>
            <span className={styles.boxHeading}>PIX</span>

            <PixIcon />
          </div>

          <div className={styles.boxBody}></div>

          <div className={styles.boxFooter}>
            <span className={styles.textingFooter}>
              Vencimento: <br />
              {day(pix.pixExpirationDate).format('DD/MM/YYYY')}
            </span>

            {purchaseStatus !== 'paid' && (
              <CopyToClipboard text={pix.pixQrCode} onCopy={handleCopy}>
                <button className={styles.buttonBoxFooter}>
                  Copiar código
                  {isCopied && <small className={styles.copy}>Código copiado</small>}
                </button>
              </CopyToClipboard>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default PaymentMethod;
