import { List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { MdOutlineCheckCircle as CheckIcon } from 'react-icons/md';

export default function PlanFeatures({ planFeatures }) {
  return (
    <List spacing={1} position="relative">
      {planFeatures?.map(feature => (
        <ListItem key={feature.name}>
          <ListIcon as={CheckIcon} color="orange.500" sx={{ fontSize: '1rem' }} />
          <Text as="span" fontSize="md" color="#202123">
            {feature.value && feature.value}
            {feature.name}
          </Text>
        </ListItem>
      ))}
    </List>
  );
}
