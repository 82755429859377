import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface UtmTableProps extends TableContainerProps {
  children: ReactNode;
}

export default function UtmTable({ children, ...rest }: UtmTableProps) {
  return (
    <TableContainer
      border="1px solid rgba(32, 33, 35, 0.05)"
      mt={4}
      p={3}
      borderRadius="base"
      {...rest}
    >
      <Table>
        <Thead>
          <Tr sx={{ th: { color: '#202123BF', whiteSpace: 'break-spaces', py: 2 } }}>
            <Th width="11.4375rem">UTM_CAMPAIGN</Th>

            <Th width="11.4375rem">UTM_SOURCE</Th>

            <Th width="11.4375rem">UTM_MEDIUM</Th>

            <Th width="6.4375rem">URL com UTM</Th>

            <Th width="5.75rem">QNT. DE VENDAS</Th>

            <Th textAlign="center">Ações</Th>
          </Tr>
        </Thead>

        <Tbody sx={{ 'tr:nth-of-type(odd)': { background: '#2021230D' } }}>{children}</Tbody>
      </Table>
    </TableContainer>
  );
}
