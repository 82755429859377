import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useCourse } from '../../../../../../../contexts/CourseContext';
import CardCoursePrice from './CardCoursePrice';
import { useEffect } from 'react';
import { CoursePriceType } from '../../../../../../../validation/CoursePriceSchema';
import { MdAdd } from 'react-icons/md';

const sortCoursePricesByDefault = (a: CoursePriceType, b: CoursePriceType) => {
  if (a.default && !b.default) {
    return -1;
  }

  if (!a.default && b.default) {
    return 1;
  }

  return 0;
};

export default function ListCoursePrices() {
  const { course, loading, coursePrices, setCoursePrices } = useCourse();

  useEffect(() => {
    if (course && course?.coursePrices?.length && !coursePrices?.length) {
      setCoursePrices(course.coursePrices.sort(sortCoursePricesByDefault));
    }
  }, [course, coursePrices?.length, setCoursePrices]);

  function onCorsePriceChange(data: CoursePriceType) {
    const coursePricesFiltered = coursePrices.filter(
      (coursePrice: CoursePriceType) => coursePrice.id !== data.id
    );

    coursePricesFiltered.forEach((coursePrice: CoursePriceType) => {
      if (coursePrice.default) {
        coursePrice.default = false;
      }
    });

    const coursePricesUpdated = [...coursePricesFiltered, data].sort(sortCoursePricesByDefault);

    setCoursePrices(coursePricesUpdated);
  }

  function onCoursePriceDelete(id: number) {
    const coursePrice = coursePrices.find(coursePrice => coursePrice.id === id);

    if (!coursePrice) return;

    const coursePricesFiltered = coursePrices.filter(
      (currentCoursePrice: CoursePriceType) => currentCoursePrice.id !== coursePrice.id
    );

    if (coursePrice?.default) {
      coursePricesFiltered.forEach((coursePrice, index) => {
        if (index === 0) {
          coursePrice.default = true;
        }
      });
    }

    setCoursePrices(coursePricesFiltered.sort(sortCoursePricesByDefault));
  }

  if (loading) {
    return <Text>Carregando...</Text>;
  }

  return (
    <Box width="full">
      <Heading as="h4" fontWeight="600" fontSize="2xl">
        Preço do produto:
      </Heading>

      <Card width="full" marginTop="10px" shadow="lg" padding="5px" border="1px solid #20212350">
        <CardHeader paddingY={5}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'flex-end' }}
            justifyContent={{ base: 'space-between', md: 'space-between' }}
          >
            <VStack alignItems="flex-start">
              <Text fontWeight="medium" fontSize="xl">
                Preços e ofertas
              </Text>
              <Text fontWeight="medium" fontSize="xs" color="blackAlpha.500">
                Aqui você poderá cadastrar os preços e as ofertas para o seu produto.
              </Text>
            </VStack>
            <Button
              as={Link}
              to={`/courses/${course.id}/course-manager/price/primary-prices/new`}
              size="xs"
              leftIcon={<MdAdd />}
              variant="outline"
              colorScheme="default"
              width={{ base: 'full', md: 'auto' }}
            >
              Novo preço
            </Button>
          </Stack>
        </CardHeader>
        <CardBody paddingY="15px">
          {coursePrices?.length ? (
            coursePrices
              .sort(sortCoursePricesByDefault)
              .map((coursePrice: CoursePriceType) => (
                <CardCoursePrice
                  key={coursePrice.id}
                  courseId={course.id}
                  data={coursePrice}
                  onCorsePriceChange={onCorsePriceChange}
                  onCoursePriceDelete={onCoursePriceDelete}
                />
              ))
          ) : (
            <Text>Nenhum preço cadastrado</Text>
          )}
        </CardBody>
      </Card>
    </Box>
  );
}
