import { Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { BiDotsHorizontal } from 'react-icons/bi';
import { BsTag } from 'react-icons/bs';
import CouponAPI from '../../../../api/Coupon';
import Toast from '../../../../components/Toast';
import convertToBrazilianCurrency from '../../../../helpers/convertToBrazilianCurrency';
import ErrorResponse from '../../../../helpers/ErrorResponse';

type CouponProducts = {
  id: number;
  name: string;
};

type CouponType = {
  id?: number;
  name?: string;
  status?: string;
  discountType?: string;
  discount?: string;
  value?: string;
  percentage?: string;
  startDate?: string;
  endDate?: string;
  uses?: string;
  amount?: string;
  invoicing?: string;
  products?: CouponProducts[];
};

export function getProductNameOrProductsLength(couponProducts: CouponProducts[]): string | number {
  if (couponProducts.length === 1) {
    return couponProducts.find(value => !!value).name;
  }

  return couponProducts.length;
}

export function CardList({
  coupons,
  currentPage,
  per_page,
  search,
  paginationCoupons,
  onOpen,
  setCouponId,
}) {
  async function changeStatus(coupon) {
    if (coupon) {
      try {
        let updatedCoupons: CouponType = {
          name: coupon.name,
          status: coupon.status === 'ATIVO' ? 'INATIVO' : 'ATIVO',
        };

        if (coupon.value) {
          updatedCoupons.discountType = 'value';
          updatedCoupons.discount = coupon.value;
        }
        if (coupon.percentage) {
          updatedCoupons.discountType = 'percentage';
          updatedCoupons.discount = coupon.percentage;
        }

        updatedCoupons.products = coupon.products.map(
          (products: { id: number; name: string }) => products.id
        );

        await CouponAPI.update(coupon.id, updatedCoupons);

        Toast('Cupom atualizado!', 'success');
        await paginationCoupons(currentPage, per_page, search);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }
  }

  return (
    <Stack direction="column" spacing={2}>
      {coupons
        ?.filter(
          (coupon: CouponType) =>
            coupon?.name.toLowerCase().includes(search?.toLowerCase()) ||
            coupon?.status.toLowerCase().includes(search?.toLowerCase())
        )
        .map((coupon: CouponType) => (
          <Stack
            direction="column"
            bg="#FFFFFF"
            p={4}
            borderRadius={6}
            boxShadow="1px 1px 4px rgba(0, 0, 0, 0.25)"
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center" my={1}>
              <Stack direction="row" alignItems="center" fontWeight={600}>
                <BsTag />
                <Text>{coupon.name}</Text>
              </Stack>

              <Menu>
                <MenuButton
                  style={{ outline: 'none', boxShadow: 'none', marginBottom: '15px' }}
                  bg="#20212312"
                  color="#202123"
                  p="1px"
                  borderRadius={3}
                >
                  <BiDotsHorizontal />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setCouponId(coupon.id);
                      onOpen();
                    }}
                    style={{ outline: 'none', boxShadow: 'none' }}
                  >
                    Editar
                  </MenuItem>
                  <MenuItem
                    style={{ outline: 'none', boxShadow: 'none' }}
                    onClick={() => changeStatus(coupon)}
                  >
                    {coupon.status === 'ATIVO' ? 'Inativar' : 'Ativar'}
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>

            <Stack spacing={2} direction="column">
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text fontWeight={600} color="#20212380">
                  Valor:
                </Text>
                <Text>
                  {coupon.value
                    ? convertToBrazilianCurrency(coupon.value)
                    : `${coupon.percentage}%`}
                </Text>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text fontWeight={600} color="#20212380">
                  Produtos:
                </Text>
                <Text>{getProductNameOrProductsLength(coupon.products)}</Text>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text fontWeight={600} color="#20212380">
                  Perídodo
                </Text>
                <Text>{`${dayjs(coupon.startDate).format('DD/MM/YYYY')} - ${dayjs(
                  coupon.endDate
                ).format('DD/MM/YYYY')}`}</Text>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text fontWeight={600} color="#20212380">
                  Usos:
                </Text>
                <Text>{coupon.uses}</Text>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text fontWeight={600} color="#20212380">
                  Max. de Uso:
                </Text>
                <Text>{coupon.amount}</Text>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text fontWeight={600} color="#20212380">
                  Faturamento:
                </Text>
                <Text>{convertToBrazilianCurrency(coupon.invoicing)}</Text>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text fontWeight={600} color="#20212380">
                  Status:
                </Text>
                <Text fontWeight={400} color={coupon.status === 'ATIVO' ? '#22bb33' : '#bb2124'}>
                  {coupon.status}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
}
