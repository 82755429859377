export function convertPaymentMethod(paymentMethod: string) {
  switch (paymentMethod) {
    case 'credit_card':
      return 'Cartão de crédito';
    case 'boleto':
      return 'Boleto';
    case 'pix':
      return 'Pix';
    default:
      return 'Cartão de crédito';
  }
}

export function convertPaymentStatus(purchaseStatus: string) {
  switch (purchaseStatus) {
    case 'paid':
      return 'Pago';

    case 'unpaid':
      return 'Não pago';

    case 'refused':
      return 'Recusada';

    case 'waiting_payment':
      return 'Aguardando pagamento';

    case 'refunded':
      return 'Estornada';
    case 'chargedback':
      return 'Chargedback';
    default:
      return 'Pago';
  }
}
export function bgByTransaction(purchaseStatus: string) {
  switch (purchaseStatus) {
    case 'paid':
      return '#4ca64c';

    case 'unpaid':
      return '#000000';

    case 'refused':
      return '#cc0000';

    case 'waiting_payment':
      return '#FFD750';

    case 'refunded':
      return '#4B0082';
    case 'chargedback':
      return '#2F4F4F';
    default:
      return '#363636';
  }
}

export function convertSubscriptionStatus(subscriptionStatus: string) {
  switch (subscriptionStatus) {
    case 'paid':
      return 'Pago';

    case 'unpaid':
      return 'Não pago';
    case 'canceled':
      return 'Cancelado';
    default:
      return 'Pago';
  }
}
