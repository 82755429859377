import {
  Box,
  Button,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import ContentAPI from '../../../../../../api/Content';
import EditorRichText from '../../../../../../components/EditorRichText';
import DataLoading from '../../../../../../components/Loadings/DataLoading';
import Spinner from '../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../components/Toast';
import Tooltip from '../../../../../../components/Tooltip';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import onlyNumber from '../../../../../../helpers/onlyNumber';
import Timezones from '../../../../../../helpers/Timezones';

const initialValues = {
  title: '',
  order: 1,
  contentType: 'AO_VIVO_ZOOM',
  broadcastType: 'MEETING',
  broadcastTopic: '',
  content: '',
  broadcastTimezone: 'America/Sao_Paulo',
  broadcastDate: '',
  broadcastDuration: '',
  broadcastPassword: '',
};

const ADD_WITH_CURRENT_ORDER = 1;

const ZoomLiveForm = () => {
  const [values, setValues] = useState(initialValues);
  const [date, setDate] = useState('');
  const [hour, setHour] = useState('');
  const [currentTimezone, setCurrentTimezone] = useState({});
  const [editorState, setEditorState] = useState('');
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [changed, setChanged] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [zoomTokenNotFound, setZoomTokenNotFound] = useState(false);
  const [broadcastType, setBroadcastType] = useState('MEETING');

  const { course, setCourse, getContent } = useCourse();

  const history = useHistory();
  const { id, moduleId, contentId } = useParams();

  useEffect(() => {
    const content = getContent(moduleId, contentId);

    if (!contentId) {
      setValues(initialValues);
      const timezone = Timezones.find(timezone => timezone.code === 'America/Sao_Paulo');
      return setCurrentTimezone({ value: timezone.code, text: timezone.name });
    }

    if (content) {
      setEditorState(content.content || '');

      const parsedDate = dayjs(content?.broadcastDate).utc().format('YYYY-MM-DD');

      setDate(parsedDate);

      const parsedHour = dayjs(content?.broadcastDate).utc().format('HH:mm');

      setHour(parsedHour);

      const timezone = Timezones.find(timezone => timezone.code === content.broadcastTimezone);

      if (timezone) {
        setCurrentTimezone({ value: timezone.code, text: timezone.name });
      }

      setValues({
        title: content.title,
        order: content.order,
        broadcastType: content.broadcastType,
        broadcastTopic: content.broadcastTopic,
        contentType: content.contentType,
        content: content.content,
        broadcastPassword: content.broadcastPassword,
        broadcastDuration: content.broadcastDuration,
      });
    }
  }, [contentId, getContent, moduleId]);

  useEffect(() => {
    if (contentId && values.broadcastType === 'MEETING') {
      setIsDisabled(true);
    }
  }, [contentId, values.broadcastType]);

  function handleChange(event) {
    const { name, value } = event.target;

    setErrorMessage('');

    setValues({ ...values, [name]: value });
  }

  function handleDateChange(event) {
    const { name, value } = event.target;

    setErrorMessage('');

    if (name === 'date') setDate(value);
    if (name === 'hour') setHour(value);
  }

  function handleTimezoneChange(broadcastTimezone) {
    setErrorMessage('');
    setCurrentTimezone({ value: broadcastTimezone.value, text: broadcastTimezone.text });
  }

  function handleEditorStateChange(editorState) {
    setChanged(true);
    setErrorMessage('');
    setEditorState(editorState);
    setValues(prevValues => ({ ...prevValues, content: editorState }));
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const formattedDate = dayjs(`${date} ${hour}`).format('YYYY-MM-DDTHH:mm:ss[Z]');

    const formattedValues = {
      ...values,
      broadcastType,
      broadcastDate: formattedDate,
      broadcastDuration: parseInt(values.broadcastDuration),
      broadcastTimezone: currentTimezone.value,
    };

    setErrorMessage(null);
    setIsLoading(true);

    if (!contentId) {
      try {
        const currentModule = course.modules.find(module => module.id === Number(moduleId));
        const { contents } = currentModule;

        if (contents.length) {
          const orders = contents.map(content => content.order);
          values.order = values.order = orders.length
            ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
            : initialValues.order;
        }

        const newContent = {
          ...formattedValues,
          order: values.order,
        };

        const dataCreatedContent = await ContentAPI.store(id, moduleId, newContent);

        if (dataCreatedContent && dataCreatedContent?.data) {
          const createdContent = { ...dataCreatedContent.data, broadcastDate: formattedDate };

          const newContents = [...currentModule.contents, createdContent];

          const moduleUpdated = { ...currentModule, contents: newContents };

          const newModules = course.modules.map(module =>
            module.id === moduleUpdated.id ? moduleUpdated : module
          );

          setCourse({
            ...course,
            modules: newModules,
          });

          setIsLoading(false);
          Toast('Conteúdo cadastrado com sucesso');

          history.push(
            `/courses/${id}/course-manager/course/modules/${moduleId}/contents/${createdContent.id}/edit-live-zoom-content`
          );
        }
      } catch (error) {
        if (error?.response && error.response?.data) {
          const zoomTokenNotFoundMessage = 'Token de integração com Zoom não foi encontrado.';

          if (error.response.data.message === zoomTokenNotFoundMessage) setZoomTokenNotFound(true);
        }

        setErrorMessage(ErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
      return;
    }

    try {
      const updatedContent = { ...formattedValues };

      await ContentAPI.update(id, moduleId, contentId, updatedContent);

      const currentModule = course.modules.find(module => module.id === Number(moduleId));

      const newContents = currentModule.contents.map(content =>
        content.id === Number(contentId) ? values : content
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      Toast('Conteúdo atualizado com sucesso');
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();

    try {
      setIsLoading(true);
      await ContentAPI.delete(id, moduleId, contentId);

      const currentModule = course.modules.find(module => module.id === Number(moduleId));

      const newContents = currentModule.contents.filter(module => module.id !== Number(contentId));

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      setIsShowModal(false);
      Toast('Conteúdo excluído com sucesso');
      history.push(`/courses/${id}/course-manager/course/modules/${moduleId}`);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    } finally {
      setIsShowModal(false);
    }
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    if (contentId) {
      const content = getContent(moduleId, contentId);

      setEditorState(content.content || '');

      const [date, hour] = content.broadcastDate.split('T');
      setDate(date);
      setHour(hour.substring(0, 5));

      const timezone = Timezones.find(timezone => timezone.code === content.broadcastTimezone);
      setCurrentTimezone(timezone);

      return setValues(content);
    }

    setEditorState(initialValues.content);

    setValues(initialValues);
    setDate('');
    setHour('');
    setErrorMessage('');
  }

  return (
    <Box mx={{ base: 2, md: 2, lg: 10 }} my={{ base: 2, md: 2, lg: 5 }}>
      <Heading fontSize="lg" my={3}>
        {values.title || 'Novo conteúdo ao vivo (Zoom)'}
      </Heading>

      <Box
        as="form"
        onSubmit={handleSubmit}
        border="1px"
        borderRadius={6}
        borderColor="gray"
        p={{ base: 5, md: 5, lg: 10 }}
      >
        <Stack direction="column" spacing={4}>
          <Stack direction="column" spacing={2}>
            <Heading fontSize="md" fontWeight={500}>
              Título
            </Heading>
            <Input
              value={values.title}
              placeholder="Digite um título para o seu conteúdo"
              onChange={handleChange}
              name="title"
              id="title"
              type="text"
              minLength={1}
              maxLength={255}
              focusBorderColor="#e06721"
              isRequired
            />
          </Stack>

          <Heading fontSize="md">Configurações do Zoom</Heading>

          <Stack direction="column" spacing={2}>
            <Heading fontSize="md" fontWeight={500}>
              Tópico
            </Heading>
            <Input
              value={values.broadcastTopic}
              onChange={handleChange}
              name="broadcastTopic"
              id="broadcastTopic"
              type="text"
              minLength="6"
              maxLength="80"
              focusBorderColor="#e06721"
              isRequired
            />
          </Stack>

          <Stack direction="column" spacing={2}>
            <Heading fontSize="md" fontWeight={500}>
              Descrição <Text as="span">(opcional)</Text>
            </Heading>
            <EditorRichText value={editorState} onChange={handleEditorStateChange} />
          </Stack>

          <Stack direction="column" spacing={2}>
            <Heading fontSize="md" fontWeight={500}>
              Tipo de evento
            </Heading>

            <RadioGroup onChange={setBroadcastType} value={broadcastType}>
              <Stack
                direction={{ base: 'column', md: 'row', lg: 'row' }}
                spacing={5}
                alignItems="flex-start"
              >
                <Stack direction="column" w="100%">
                  <Radio
                    name="broadcastType"
                    id="inlineRadio2"
                    value="MEETING"
                    colorScheme="orange"
                  >
                    Meet
                  </Radio>

                  <Text
                    style={{
                      fontSize: '0.75rem',
                      textAlign: 'justify',
                      textJustify: 'inter-character',
                    }}
                  >
                    As reuniões são projetadas para ser um evento colaborativo em que todos os
                    participantes podem compartilhar a tela, ativar seu vídeo e áudio e ver quem
                    mais está presente
                  </Text>
                </Stack>

                <Stack direction="column" w="100%">
                  <Radio
                    name="broadcastType"
                    id="inlineRadio1"
                    value="WEBINAR"
                    isRequired
                    isDisabled={isDisabled}
                    style={{ cursor: isDisabled && 'not-allowed' }}
                    colorScheme="orange"
                  >
                    <Stack direction="row">
                      <Text
                        as="span"
                        fontWeight={600}
                        style={{ cursor: isDisabled && 'not-allowed' }}
                      >
                        Webinar
                      </Text>
                      <Tooltip
                        content="Webinars só estão disponíveis em planos pagos, como Pró, Corporativo ou Educação"
                        background="#eb7129"
                        style={{ cursor: isDisabled && 'not-allowed' }}
                      />
                    </Stack>
                  </Radio>

                  <Text
                    style={{
                      fontSize: '0.75rem',
                      textAlign: 'justify',
                      textJustify: 'inter-character',
                      color: isDisabled && '#8094ae',
                      cursor: isDisabled && 'not-allowed',
                    }}
                  >
                    Os webinars são projetados para que o anfitrião e qualquer membro do painel
                    designado possa compartilhar seu vídeo, áudio e tela. Os webinars permitem
                    participantes apenas para visualização. Eles têm a capacidade de interagir por
                    meio de perguntas e respostas, bate-papo e responder a perguntas de pesquisas. O
                    host também pode ativar o som dos participantes.
                  </Text>
                </Stack>
              </Stack>
            </RadioGroup>
          </Stack>

          <Heading fontSize="md">Quando será?</Heading>

          <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} spacing={2}>
            <Stack direction="column" spacing={2} w="100%">
              <Text>Data</Text>
              <Input
                id="date"
                name="date"
                type="date"
                value={date}
                onChange={handleDateChange}
                min={dayjs().format('YYYY-MM-DD')}
                focusBorderColor="#e06721"
                isRequired
              />
            </Stack>

            <Stack direction="column" spacing={2} w="100%">
              <Text>
                Hora <Text as="span">(hr:min)</Text>
              </Text>
              <Input
                id="hour"
                name="hour"
                type="time"
                onChange={handleDateChange}
                focusBorderColor="#e06721"
                value={hour}
                isRequired
              />
            </Stack>
            <Stack direction="column" spacing={2} w="100%">
              <Text>
                Duração <Text as="span">(min)</Text>
              </Text>
              <Input
                id="broadcastDuration"
                type="number"
                value={values.broadcastDuration}
                onChange={handleChange}
                name="broadcastDuration"
                onInput={onlyNumber}
                focusBorderColor="#e06721"
                isRequired
              />
            </Stack>
          </Stack>

          <Stack direction="column" spacing={2}>
            <Text>Fuso horário</Text>
            <Select
              name="broadcastTimezone"
              placeholder="Escolha um fuso horário"
              value={currentTimezone.value}
              onChange={handleTimezoneChange}
              focusBorderColor="#e06721"
            >
              {Timezones.map(timezone => (
                <option value={timezone.code}>{timezone.name}</option>
              ))}
            </Select>
          </Stack>

          <Heading fontSize="md">Segurança</Heading>

          <Stack direction="column" spacing={2}>
            <Text>Senha do evento</Text>
            <Input
              value={values.broadcastPassword}
              placeholder="1232456"
              onChange={handleChange}
              name="broadcastPassword"
              id="broadcastPassword"
              type="text"
              minLength="6"
              maxLength="10"
            />
          </Stack>

          <DataLoading loading={isLoading} className="d-flex justify-content-center mt-4">
            {errorMessage && !isLoading && (
              <div className="mt-4">
                <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
                  <div className="text-center">
                    {errorMessage}{' '}
                    {zoomTokenNotFound && (
                      <div>
                        <Link
                          to="/integrations"
                          style={{
                            color: 'currentcolor',
                            textDecoration: 'underline',
                          }}
                        >
                          Clique aqui
                        </Link>{' '}
                        para adicionar o token da sua conta.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <Stack
              direction={{ base: 'column', md: 'row', lg: 'row' }}
              spacing={2}
              justifyContent="flex-end"
            >
              {contentId && (
                <Button
                  onClick={event => {
                    event.preventDefault();
                    setIsShowModal(true);
                  }}
                  colorScheme="red"
                  variant="solid"
                >
                  Excluir conteúdo
                </Button>
              )}

              <Button onClick={handleDiscardChanges} colorScheme="gray" variant="solid">
                Descartar mudanças
              </Button>

              <Button type="submit" variant="solid" colorScheme="orange" disabled={!changed}>
                Salvar
              </Button>
            </Stack>
          </DataLoading>
        </Stack>
      </Box>

      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="mb-1 py-4">
            <h3 className="text-center">Você tem certeza que deseja excluir o conteúdo?</h3>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <button onClick={() => setIsShowModal(false)} className="btn btn-light mr-4">
              Cancelar
            </button>
            <button onClick={handleDelete} className="btn btn-danger" disabled={isLoading}>
              {isLoading ? <Spinner small /> : 'Excluir'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default ZoomLiveForm;
