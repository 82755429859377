import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ContentAPI from '../../../../../../api/Content';
import DataLoading from '../../../../../../components/Loadings/DataLoading';
import EditorRichText from '../../../../../../components/EditorRichText';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { Modal } from 'react-bootstrap';
import Spinner from '../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';

const initialValues = {
  title: '',
  contentType: 'TEXTO',
  content: '',
  order: 1,
};

const ADD_WITH_CURRENT_ORDER = 1;

const TextForm = () => {
  const { course, setCourse, getContent } = useCourse();
  const { id, moduleId, contentId } = useParams();
  const [values, setValues] = useState(initialValues);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    const content = getContent(moduleId, contentId);

    if (!contentId) {
      return setValues(initialValues);
    }

    if (content) {
      setValues(content);
    }
  }, [contentId, course, getContent, moduleId]);

  function handleChange(event) {
    const { name, value } = event.target;

    setValues({ ...values, [name]: value });
  }

  function onEditorStateChange(value) {
    setValues({ ...values, content: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setErrorMessage('');
    setIsLoading(true);

    if (!values.title || !values.content || values.content === '<p></p>↵') {
      return setErrorMessage('Por favor, preencha os campos');
    }

    if (!contentId) {
      try {
        const currentModule = course.modules.find(module => module.id === parseInt(moduleId));
        const { contents } = currentModule;

        if (contents.length) {
          const orders = contents.map(content => content.order);
          values.order = values.order = orders.length
            ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
            : initialValues.order;
        }

        const newContent = {
          title: values.title,
          order: values.order,
          contentType: values.contentType,
          content: values.content,
        };

        const { data: createdContent } = await ContentAPI.store(id, moduleId, newContent);

        const newContents = [...currentModule.contents, createdContent];

        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({
          ...course,
          modules: newModules,
        });

        setIsLoading(false);
        Toast('Conteúdo cadastrado com sucesso');

        history.push(
          `/courses/${id}/course-manager/course/modules/${moduleId}/contents/${createdContent.id}/edit-text`
        );
      } catch (error) {
        setErrorMessage(ErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
      return;
    }

    try {
      const updatedContent = {
        title: values.title,
        order: values.order,
        contentType: values.contentType,
        content: values.content,
      };

      await ContentAPI.update(id, moduleId, contentId, updatedContent);

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      const newContents = currentModule.contents.map(content =>
        content.id === parseInt(contentId) ? values : content
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      Toast('Conteúdo atualizado com sucesso');
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();

    try {
      setIsLoading(true);
      await ContentAPI.delete(id, moduleId, contentId);

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      const newContents = currentModule.contents.filter(
        module => module.id !== parseInt(contentId)
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      setIsShowModal(false);
      Toast('Conteúdo excluído com sucesso');
      history.push(`/courses/${id}/course-manager/course/modules/${moduleId}`);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    }
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    if (contentId) {
      const content = getContent(moduleId, contentId);

      return setValues(content);
    }

    setValues(initialValues);
    setErrorMessage('');
  }

  return (
    <div className="mx-5">
      <div className="mb-4" style={{ wordBreak: 'break-all' }}>
        <h4 className="mt-1 mb-4">{values.title || 'Novo conteúdo de texto'}</h4>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="form-background p-5 round-xl shadow-sm border border-gray bg-white">
          <div className="mb-4">
            <p className="info" style={{ wordBreak: 'break-word', lineHeight: '1.5' }}>
              Cadastre textos que irão facilitar e auxiliar o aprendizado e a organização de seus
              alunos, como por exemplo, textos de boas-vindas, introdução, índices ou sumários,
              relação bibliográfica e muito mais.
            </p>
          </div>

          <div className="form-group">
            <label className="h6" htmlFor="title">
              Título - Texto
            </label>
            <input
              value={values.title}
              placeholder="Digite um título para o seu conteúdo"
              onChange={handleChange}
              name="title"
              id="title"
              type="text"
              className="form-control input-content"
              minLength={1}
              maxLength={255}
              required
            ></input>
          </div>

          <label className="h6">Conteúdo</label>
          <EditorRichText value={values.content} onChange={onEditorStateChange} />

          <DataLoading loading={isLoading} className="d-flex justify-content-center mt-4">
            {errorMessage && !isLoading && (
              <div className="mt-4">
                <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
                  <div className="text-center">{errorMessage}</div>
                </div>
              </div>
            )}

            <div className="d-flex align-center justify-content-between mt-4">
              <div>
                {contentId && (
                  <button
                    onClick={event => {
                      event.preventDefault();
                      setIsShowModal(true);
                    }}
                    className="btn btn-outline-danger"
                  >
                    Excluir conteúdo
                  </button>
                )}
              </div>

              <div className="d-flex flex-nowrap">
                <button onClick={handleDiscardChanges} className="btn btn-light ml-3">
                  Descartar mudanças
                </button>
                {!errorMessage && (
                  <button type="submit" className="btn btn-primary ml-3">
                    Salvar
                  </button>
                )}
              </div>
            </div>
          </DataLoading>
        </div>
      </form>
      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="mb-1 py-4">
            <h3 className="text-center">Você tem certeza que deseja excluir o conteúdo?</h3>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <button onClick={() => setIsShowModal(false)} className="btn btn-light mr-4">
              Cancelar
            </button>
            <button onClick={handleDelete} className="btn btn-danger" disabled={isLoading}>
              {isLoading ? <Spinner small /> : 'Excluir'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TextForm;
