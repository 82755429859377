import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import FilterType from '../../types/Filter';
import Filter from './Filter';
import FiltersBy from './FiltersBy';
import useFilters from './useFilters';
import { useHistory, useLocation } from 'react-router-dom';

function getValueByName(name: FilterType['name'], filters: Partial<FilterType>[]) {
  return filters.find(item => item.name === name)?.id || '';
}

interface FiltersProps {
  onFilter: (filters: Partial<FilterType>[]) => void;
}

export default function Filters({ onFilter }: FiltersProps) {
  const location = useLocation();
  const history = useHistory();

  const { filters, filtersList, handleAddFilter, handleRemoveFilter, clearFilters, fetchFilters } =
    useFilters();

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  function clearSearchParams() {
    history.replace(location.pathname);
  }

  function handleFilter() {
    onFilter(filters);
  }

  function handleClearFilters() {
    clearFilters();

    clearSearchParams();
  }

  return (
    <Accordion w="full" allowToggle pb={2} px={0} border="none" mt={5}>
      <AccordionItem border="none" w="full">
        <AccordionButton
          _focus={{ boxShadow: 'none', outline: 'none' }}
          _hover={{ bg: 'transparent' }}
          border="none"
          p={0}
          color="#20212380"
          fontSize="sm"
          fontWeight="semibold"
        >
          <Box as="span" flex="1" textAlign="left">
            Filtros
            <AccordionIcon />
          </Box>
        </AccordionButton>

        <AccordionPanel px={0} py={5}>
          <Stack direction={{ base: 'column', md: 'row' }} w="full" spacing={4} px={0.5}>
            <Filter
              name="area"
              label="Área"
              value={getValueByName('area', filters)}
              onAddFilter={handleAddFilter}
              filtersList={filtersList?.areas}
            />
            <Filter
              name="discipline"
              label="Disciplina"
              value={getValueByName('discipline', filters)}
              onAddFilter={handleAddFilter}
              filtersList={filtersList?.disciplines}
            />
            <Filter
              name="subject"
              label="Assunto"
              value={getValueByName('subject', filters)}
              onAddFilter={handleAddFilter}
              filtersList={filtersList?.subjects}
            />
            <Filter
              name="institution"
              label="Banca"
              value={getValueByName('institution', filters)}
              onAddFilter={handleAddFilter}
              filtersList={filtersList?.institutions}
            />
          </Stack>

          <FiltersBy
            filters={filters}
            onRemoveFilter={handleRemoveFilter}
            onClearFilters={handleClearFilters}
          />

          <HStack w="full" justify="end" mt={5}>
            <HStack spacing={2}>
              {filters.length > 0 && (
                <Button
                  onClick={handleClearFilters}
                  size="md"
                  variant="ghost"
                  color="#7397EF"
                  _hover={{ bg: '#E8EEFC' }}
                  textDecoration="underline"
                  px={2}
                >
                  Limpar filtro
                </Button>
              )}

              <Button onClick={handleFilter} size="md" colorScheme="default">
                Filtrar
              </Button>
            </HStack>
          </HStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
