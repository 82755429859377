import { useTheme } from '../../contexts/ThemeContext';
import Header from '../Header/Header';
import MenuDropdown from '../MenuDropdown/MenuDropdown';
import styles from './styles.module.css';

function Container({ header = true, children }) {
  const { themeMarketplace } = useTheme();

  return (
    <div className="nk-wrap" style={{ position: 'relative' }}>
      <div
        className={`nk-header nk-header-fixed is-light shadow-sm ${styles.fixedContainerHeader}`}
        style={{
          background: themeMarketplace.primaryColor,
          height: '4.906rem',
          maxHeight: '4.906rem',
        }}
      >
        <div className="container-fluid">
          <div className="nk-header-wrap" style={{ height: '4.906rem', maxHeight: '4.906rem' }}>
            <div className="nk-menu-trigger d-xl-none ml-n1">
              <a
                href="dashboard/sidebar"
                className="nk-nav-toggle nk-quick-nav-icon"
                data-target="sidebarMenu"
              >
                <em
                  className="icon ni ni-menu"
                  style={{
                    color: themeMarketplace.textColor,
                  }}
                ></em>
              </a>
            </div>

            <Header visibility={header}>
              <MenuDropdown
                userName={localStorage.getItem('username')}
                email={localStorage.getItem('email')}
              />
            </Header>
          </div>
        </div>
      </div>
      <div className="nk-content ">
        <div className="container-fluid">{children}</div>
      </div>
    </div>
  );
}

export default Container;
