import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toQueryString } from '../../../../helpers/ToQueryString';
import useFetch from '../../../../hooks/useFetch';
import hasFilterParams from '../../../../utils/hasFilterParams';
import { Plan } from './types';

interface PlanListResponse {
  recurrencePlanIndexResult: Plan[];
  page: number;
  per_page: number;
  total: number;
}

export default function usePlanManagement() {
  const location = useLocation();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const searchParamsString = urlSearchParams.toString();

  const [startDate, setStartDate] = useState(urlSearchParams.get('startDate') || '');
  const [endDate, setEndDate] = useState(urlSearchParams.get('endDate') || '');
  const [status, setStatus] = useState(urlSearchParams.get('filterStatus') || '');
  const [search, setSearch] = useState(urlSearchParams.get('search') || '');

  function handleRangeChange(startDate: string, endDate: string) {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const { data: response, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<PlanListResponse>
  >({
    method: 'get',
    url: `/recurrence-plan?${searchParamsString}`,
    authenticated: true,
    autoFetch: true,
  });

  const handleSearchChange = useCallback(
    (value: string) => {
      if (!value) return push(url);

      setSearch(value);

      const queryString = toQueryString({
        page: 1,
        search: value,
        filterStatus: status,
        startDate,
        endDate,
      });

      push({
        search: queryString,
      });
    },
    [endDate, push, startDate, status, url]
  );

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;
    urlSearchParams.set('page', newPage.toString());
    push({ search: urlSearchParams.toString() });
  }

  function handleStatusFilterChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setStatus(value);
  }

  function clearFilters() {
    setStatus('');
    setStartDate('');
    setEndDate('');
    push(url);
  }

  function handleFilter() {
    const queryString = toQueryString({
      search,
      filterStatus: status,
      startDate,
      endDate,
    });
    push({
      search: queryString,
    });
  }

  const planList = response?.data?.recurrencePlanIndexResult;
  const isFiltering = hasFilterParams(urlSearchParams);
  const isNotFoundVisible = !isLoading && !planList?.length && isFiltering;
  const isEmpty = !isLoading && !planList?.length && !isFiltering;
  const total = response?.data?.total;
  const perPage = response?.data?.per_page || 1;
  const pageCount = Math.ceil(total / perPage);
  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const isPaginationVisible = !isLoading && pageCount > 1;
  const isPlanListVisible = !isLoading && !!planList?.length;
  const searchQuery = urlSearchParams.get('search');

  return {
    isNotFoundVisible,
    isEmpty,
    currentPage,
    isPaginationVisible,
    isPlanListVisible,
    isLoading,
    planList,
    pageCount,
    searchQuery,
    status,
    isFiltering,
    startDate,
    endDate,
    handleFilter,
    handleRangeChange,
    clearFilters,
    handleSearchChange,
    handlePageChange,
    handleStatusFilterChange,
  };
}
