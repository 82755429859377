import {
  Box,
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th as ChakraTh,
  Text,
} from '@chakra-ui/react';
import TdItem from './TdItem';
import { Heading } from '../../../../../components/Heading';
import DotsFalling from '../../../../../components/Loadings/DotsFalling';

import { Subscriber } from '../../types';
import { X_LARGE_SCREEN_SIZE } from '../../constants';

function Th({ ...props }) {
  return (
    <ChakraTh
      fontSize="sm"
      fontWeight="bold"
      lineHeight="shorter"
      textTransform="capitalize"
      color="rgba(32, 33, 35, 0.75)"
    >
      {props.children}
    </ChakraTh>
  );
}

function Table({ id, subscribers, isLoading, width, onOpen, user, setUser }) {
  return (
    <Box
      overflow="auto"
      borderRadius="md"
      borderWidth="0.0625rem"
      borderStyle="solid"
      borderColor="rgba(32, 33, 35, 0.05)"
      background="white"
      paddingLeft="3"
      paddingBottom="3"
    >
      <ChakraTable variant="striped" size={width <= X_LARGE_SCREEN_SIZE ? 'sm' : 'md'}>
        <Thead>
          <Tr borderBottom="none" sx={{ '& > th': { borderColor: 'transparent' } }}>
            <Th>
              <Text w={61} isTruncated>
                Usuário
              </Text>
            </Th>
            <Th>
              <Text w={width <= X_LARGE_SCREEN_SIZE ? 61 : 'auto'} isTruncated>
                Forma de {width >= X_LARGE_SCREEN_SIZE ? <br /> : ''} Pagamento
              </Text>
            </Th>
            <Text w={width <= X_LARGE_SCREEN_SIZE ? 61 : 'auto'} isTruncated>
              <Th>Plano</Th>
            </Text>
            <Th>
              <Text w={width <= X_LARGE_SCREEN_SIZE ? 61 : 'auto'} isTruncated>
                Início do {width >= X_LARGE_SCREEN_SIZE ? <br /> : ''} período
              </Text>
            </Th>
            <Th>
              <Text w={width <= X_LARGE_SCREEN_SIZE ? 61 : 'auto'} isTruncated>
                Fim do {width >= X_LARGE_SCREEN_SIZE ? <br /> : ''} período
              </Text>
            </Th>
            <Th>
              <Text w={width <= X_LARGE_SCREEN_SIZE ? 61 : 'auto'} isTruncated>
                Última {width >= X_LARGE_SCREEN_SIZE ? <br /> : ''} atualização
              </Text>
            </Th>
            <Th>
              <Text w={55} isTruncated>
                Status
              </Text>
            </Th>
            <Th>
              <Text w={55} isTruncated>
                AÇÕES
              </Text>
            </Th>
          </Tr>
        </Thead>

        <Tbody
          sx={{
            '& > tr:nth-of-type(odd) td': {
              background: 'rgba(32, 33, 35, 0.05)',
              borderColor: 'transparent',
            },
          }}
        >
          {subscribers?.map((subscriber: Subscriber) => (
            <TdItem
              id={id}
              key={subscriber.email}
              subscriber={subscriber}
              onOpen={onOpen}
              user={user}
              setUser={setUser}
            />
          ))}
        </Tbody>
      </ChakraTable>

      {isLoading && (
        <Box
          marginTop="8"
          marginBottom="4"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <DotsFalling />
        </Box>
      )}

      {subscribers?.length === 0 && !isLoading && (
        <Box
          marginTop="8"
          marginBottom="4"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Heading as="h2" fontSize="lg">
            Ainda não há informações disponíveis.
          </Heading>
        </Box>
      )}
    </Box>
  );
}

export default Table;
