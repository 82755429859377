import { useState } from 'react';
import { MdMenu as MenuIcon, MdSearch as SearchIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import ShoppingCartIcon from '../../ShoppingCartIcon';
import { HEADER_MOBILE_HEIGHT } from '../constants';
import { IHeaderProps } from '../types';
import useShouldModifyHeader from '../useShouldModifyHeader';
import ProductsMenu from './NavMenu/ProductsMenu';
import SearchDrawer from './SearchDrawer';
import Sidebar from './Sidebar';

import {
  Button,
  Link as ChakraLink,
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  IconButton,
  Image,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { TOKEN_ORIGIN_LOGIN } from '../../../helpers/LocalStorageHelper';
import LoginAsUser from '../../LoginAsUser';
import PromotionBanner from '../../PromotionBanner';
import useExternalLinkHeader from '../../../useExternalLinkHeader';

export default function HeaderMobile({ themeMarketplace, isAuthenticated }: IHeaderProps) {
  const [tabIndex, setTabIndex] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const externalLink = useExternalLinkHeader();

  function handleToggleIsSearching() {
    setIsSearching(prevState => !prevState);
  }

  const shouldModifyHeader = useShouldModifyHeader();
  const { isOpen, onToggle } = useDisclosure();
  const { signOut } = useAuth();
  const tokenOrigin = localStorage.getItem(TOKEN_ORIGIN_LOGIN);

  function handleSignOut() {
    signOut();
  }

  function handleChangeToNavMenuTab() {
    setTabIndex(0);
  }

  function handleChangeToProductsMenusTab() {
    setTabIndex(1);
  }

  return (
    <>
      <VStack
        position="fixed"
        inset={0}
        width="full"
        spacing={0}
        background={themeMarketplace.primaryColor}
        zIndex="99"
        height={tokenOrigin ? '96.2px' : HEADER_MOBILE_HEIGHT}
      >
        <LoginAsUser />

        <Flex
          as="header"
          justify={'center'}
          width="full"
          alignItems="center"
          direction="column"
          px={4}
          height={HEADER_MOBILE_HEIGHT}
          id="mobileHeader"
        >
          {!shouldModifyHeader ? (
            <Flex
              width="full"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
            >
              <IconButton
                onClick={onToggle}
                variant="ghost"
                color="secondary.500"
                bg="transparent"
                aria-label="Menu da aplicação"
                icon={<MenuIcon />}
                size="xs"
                sx={{ svg: { boxSize: '1.5rem' } }}
                p={0}
              />

              {externalLink ? (
                <ChakraLink
                  href={externalLink}
                  position="absolute"
                  left="50%"
                  transform="translateX(-50%)"
                >
                  <Image
                    height="full"
                    maxW="8rem"
                    objectFit="contain"
                    maxHeight="3.45rem"
                    src={themeMarketplace.logo}
                    alt="logo"
                  />
                </ChakraLink>
              ) : (
                <ChakraLink
                  as={Link}
                  to="/"
                  position="absolute"
                  left="50%"
                  transform="translateX(-50%)"
                >
                  <Image
                    height="full"
                    maxW="8rem"
                    objectFit="contain"
                    maxHeight="3.45rem"
                    src={themeMarketplace.logo}
                    alt="logo"
                  />
                </ChakraLink>
              )}

              <Flex align="center" gap={4}>
                <ShoppingCartIcon blockMarginLeft />

                <IconButton
                  onClick={handleToggleIsSearching}
                  variant="ghost"
                  color="secondary.500"
                  bg="transparent"
                  aria-label=""
                  icon={<SearchIcon />}
                  size="xs"
                  sx={{ svg: { boxSize: '1.5rem' } }}
                  p={0}
                />
              </Flex>
            </Flex>
          ) : (
            <Image
              height="full"
              width="8rem"
              objectFit="contain"
              margin="0 1rem 0 2rem"
              maxHeight="3.45rem"
              src={themeMarketplace.logo}
              alt="logo"
            />
          )}
        </Flex>
      </VStack>

      <Drawer isOpen={isOpen} placement="left" onClose={onToggle}>
        <DrawerOverlay />
        <DrawerContent maxWidth={{ base: '75%', md: '33.33%' }}>
          <Tabs index={tabIndex} h="full" isLazy>
            <TabPanels>
              <TabPanel p={0}>
                <Sidebar
                  isAuthenticated={isAuthenticated}
                  onChangeToProductsMenusTab={handleChangeToProductsMenusTab}
                />
              </TabPanel>

              <TabPanel p={0}>
                <ProductsMenu onChangeToNavMenuTab={handleChangeToNavMenuTab} onToggle={onToggle} />
              </TabPanel>
            </TabPanels>
          </Tabs>

          {isAuthenticated && (
            <DrawerFooter bg="#ffffff" pb="1.875rem" px={4}>
              <Button
                onClick={handleSignOut}
                w="full"
                variant="outline"
                color="#BB2124"
                borderColor="#BB2124"
              >
                Encerrar sessão
              </Button>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>

      <SearchDrawer isOpen={isSearching} onToggleIsSearching={handleToggleIsSearching} />

      {!isOpen && <PromotionBanner />}
    </>
  );
}
