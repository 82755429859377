import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormEvent, useEffect } from 'react';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import { EditShowcaseSchemaType, editShowcaseSchemaValidator } from './editShowcaseSchemaValidator';
import { IShowcase } from '..';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedShowcase: {
    id: number;
    name: string;
    status: string;
  };
  showcases: IShowcase[];
  setShowcases: (showcases: IShowcase[]) => void;
}

const initialForm = {
  name: '',
  status: '',
};

export function EditShowcaseModal({
  isOpen,
  onClose,
  selectedShowcase,
  showcases,
  setShowcases,
}: IProps) {
  const { form, handleChange, setForm } = useHandleChange<EditShowcaseSchemaType>(initialForm);

  function afterUpdate() {
    const showcaseIndex = showcases.findIndex(
      showcaseData => showcaseData.showcaseId === selectedShowcase.id
    );

    if (showcaseIndex === -1) {
      return onClose();
    }

    const updatedShowcases = [...showcases];

    updatedShowcases[showcaseIndex].name = form.name;

    setShowcases(updatedShowcases);
  }

  useEffect(() => {
    if (selectedShowcase.name) {
      setForm({
        name: selectedShowcase.name,
        status: selectedShowcase.status,
      });
    }
  }, [setForm, selectedShowcase.name, selectedShowcase.status]);

  const payload = { ...form };

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit({
    data: payload,
    schemaValidator: editShowcaseSchemaValidator,
    url: `/showcase/${selectedShowcase.id}`,
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Vitrine atualizada com sucesso!',
      callback() {
        afterUpdate();
        onClose();
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Editar vitrine</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={formValidation?.name?.isInvalid}>
            <FormLabel>Nome da vitrine</FormLabel>

            <Input
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="Digite aqui"
              focusBorderColor="gray.300"
            />

            <FormErrorMessage>{formValidation?.name.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button size="sm" onClick={onClose} colorScheme="gray">
              Cancelar
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              color="secondary.500"
              type="submit"
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
