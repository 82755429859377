import { MdChevronLeft } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ConfigurationsTabPanel from './ConfigurationsTabPanel';

import {
  Box,
  HStack,
  Heading,
  IconButton,
  Link,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';

const SUPPORT_LINK =
  'https://proluno.freshdesk.com/support/solutions/articles/150000176523-ativar-integrac%C3%A3o-com-o-active-campaign';

export default function ENotas() {
  const { goBack } = useHistory();

  return (
    <>
      <Box>
        <HStack spacing={{ base: 0, xl: 0.5 }} ml={-2}>
          <IconButton
            onClick={goBack}
            icon={<MdChevronLeft size="1.75rem" />}
            aria-label="Voltar"
            variant="ghost"
            colorScheme="gray"
            size="sm"
          />

          <Heading
            as="h1"
            fontSize={{ base: 'lg', xl: '2xl' }}
            fontWeight="semibold"
            m={0}
            color="#202123"
          >
            eNotas
          </Heading>
        </HStack>

        <Text
          mt={{ base: 1, xl: 4 }}
          color="#20212380"
          fontSize={{ base: 'sm', xl: 'lg' }}
          whiteSpace="pre-wrap"
        >
          Para saber como ativar a sua integração,{' '}
          <Link href={SUPPORT_LINK} isExternal textDecoration="underline">
            clique aqui.
          </Link>
        </Text>
      </Box>

      <Tabs mt={{ base: 6, xl: 10 }} w="full" isLazy>
        <TabList
          color="#20212380"
          borderBottom="0.5px solid"
          borderColor="#0000001A"
          sx={{
            '.chakra-tabs__tab': {
              fontSize: 'lg',
              pt: 0,
              pb: 1,
              _selected: {
                borderBottomWidth: '0px',
                borderBottomColor: 'none',
                color: 'primary.500',
              },
            },
          }}
        >
          <Tab>Configurações</Tab>
          <Tab>Monitoramento</Tab>
        </TabList>

        <TabIndicator mt="-2px" height="3px" bg="primary.500" borderRadius="2px" />

        <TabPanels sx={{ '.chakra-tabs__tab-panel': { px: 0, py: { base: 6, xl: 8 } } }}>
          <ConfigurationsTabPanel />
        </TabPanels>
      </Tabs>
    </>
  );
}
