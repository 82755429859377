import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  IconButton,
  List,
  ListItem,
  ListProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Subscriber } from '../..';
import convertToBrazilianCurrency from '../../../../../../../../helpers/convertToBrazilianCurrency';
import { getPaymentMethodText } from '../../utils/getPaymentMethodText';
import { getStatusColor } from '../../utils/getStatusColor';
import { getStatusText } from '../../utils/getStatusText';

interface MobileSubscriberListProps extends ListProps {
  subscriberList: Subscriber[];
  onSelectSubscriberToBeCanceled: (subscriber: Subscriber) => void;
}

export default function MobileSubscriberList({
  onSelectSubscriberToBeCanceled,
  subscriberList,
  ...rest
}: MobileSubscriberListProps) {
  return (
    <List spacing={2.5} {...rest}>
      {subscriberList.map(subscriber => (
        <Card
          key={subscriber.id}
          as={ListItem}
          border="1px solid #2021230D"
          boxShadow="0px 4px 4px 0px #00000040"
        >
          <CardHeader p={4} pb={0}>
            <HStack justify="space-between" align="start" alignItems="start">
              <VStack align="start" spacing={1}>
                <Text isTruncated fontWeight="medium">
                  {subscriber.fullName}
                </Text>
                <Text isTruncated> {subscriber.email}</Text>
              </VStack>

              <Menu size="sm" arrowPadding={0}>
                <MenuButton
                  size="xs"
                  as={IconButton}
                  icon={<MoreIcon size="0.75rem" />}
                  w="1.125rem"
                  h="1rem"
                  minW="unset"
                  borderRadius={5}
                  bgColor="#2021231F"
                  _hover={{ bgColor: '#b6b5b5' }}
                  _active={{ bgColor: '#b6b5b5' }}
                />

                <MenuList
                  sx={{
                    '.chakra-menu__menuitem': {
                      fontWeight: 'medium',
                      fontSize: 'xs',
                      color: '#20212380',
                      _hover: { bgColor: '#2021230D' },
                      _focus: { bgColor: '#2021230D' },
                    },
                  }}
                >
                  <MenuItem
                    as={Link}
                    to={{
                      pathname: `/recurrence/subscriber-management/payment-history/${subscriber.id}`,
                      state: {
                        subscriberName: subscriber.fullName,
                      },
                    }}
                  >
                    Histórico de Pagamento
                  </MenuItem>

                  <MenuItem onClick={() => onSelectSubscriberToBeCanceled(subscriber)}>
                    Cancelar assinatura
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </CardHeader>

          <CardBody
            mt={4}
            p={4}
            pt={0}
            color="#20212380"
            sx={{ strong: { fontWeight: 'semibold' } }}
          >
            <List spacing={3}>
              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Forma de pagamento:</Text>

                <VStack align="end" spacing={0}>
                  <Text>{getPaymentMethodText(subscriber.paymentMethod)}</Text>
                  <Text> {convertToBrazilianCurrency(subscriber.price)}</Text>
                </VStack>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Período:</Text>

                <VStack spacing={0}>
                  <Text>
                    De{' '}
                    <Text as="strong" fontWeight="medium">
                      {dayjs(subscriber.currentPeriodStart).format('DD/MM/YYYY')}
                    </Text>
                  </Text>
                  <Text>
                    até{' '}
                    <Text as="strong" fontWeight="medium">
                      {dayjs(subscriber.currentPeriodEnd).format('DD/MM/YYYY')}
                    </Text>
                  </Text>
                </VStack>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Atualização:</Text>
                <Text> {dayjs(subscriber.updatedAt).format('DD/MM/YYYY HH:mm')}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Status:</Text>
                <Text color={getStatusColor(subscriber.status)}>
                  {getStatusText(subscriber.status)}
                </Text>
              </HStack>
            </List>
          </CardBody>
        </Card>
      ))}
    </List>
  );
}
