import { Box, Heading, Stack } from '@chakra-ui/react';
import { useCourseStudent } from '../../../../../contexts/CourseStudentContextV2';
import { PagingContents } from '../../../components/PagingContents';

function ViewText() {
  const { currentContent: content } = useCourseStudent();

  return (
    <Stack spacing={{ base: '9px', md: '55px', lg: '55px' }}>
      <Heading
        color="#FFF"
        fontSize={{ base: '18px', md: '24px', lg: '24px' }}
        margin="0"
        fontWeight="500"
      >
        {content?.title}
      </Heading>

      <Stack spacing={{ base: '22px', md: '30px', lg: '30px' }}>
        <Box
          color="#FFF"
          minH="420px"
          fontSize={{ base: '14px', md: '18px', lg: '18px' }}
          dangerouslySetInnerHTML={{ __html: content?.content }}
          className="ql-editor"
          sx={{
            iframe: {
              width: '100%',
              aspectRatio: '16/9',
            },
            a: { color: '#06c', textDecoration: 'underline' },
            blockquote: { borderLeft: '5px solid #ccc', paddingLeft: '30px' }, // Estilo para citação
          }}
        />

        <PagingContents />
      </Stack>
    </Stack>
  );
}

export default ViewText;
