import { Select, SelectProps } from '@chakra-ui/react';

const options = [
  { label: 'Mais recente', value: 'desc' },
  { label: 'Ordem alfabética A - Z', value: 'alphabetical-asc' },
  { label: 'Ordem alfabética Z - A', value: 'alphabetical-desc' },
];

interface SortSelectProps extends SelectProps {}

export default function SortSelect({ ...rest }: SortSelectProps) {
  return (
    <Select
      maxWidth={{ base: 'unset', xl: '14rem' }}
      size="sm"
      borderRadius="md"
      focusBorderColor="gray.300"
      color="#20212380"
      {...rest}
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}
