import { Box, Button, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { MdClose as CloseIcon, MdChevronRight } from 'react-icons/md';
import FilterType from '../../../types/Filter';

interface FiltersByProps {
  filters: Partial<FilterType>[];
  onRemoveFilter: (filterName: FilterType['name']) => void;
  onClearFilters: () => void;
}

export default function FiltersBy({ filters, onRemoveFilter, onClearFilters }: FiltersByProps) {
  const [hoveredButton, setHoveredButton] = useState(null);

  const firstFilter = filters.find(filter => filter.name === 'area');
  const secondFilter = filters.find(filter => filter.name === 'discipline');
  const thirdFilter = filters.find(filter => filter.name === 'subject');
  const fourthFilter = filters.find(filter => filter.name === 'institution');

  const sortedFilters = [firstFilter, secondFilter, thirdFilter, fourthFilter].filter(Boolean);
  const hasOnlyOneItem = sortedFilters.length === 1;

  function RightIcon(filterId: number, index: number) {
    const isLast = index === sortedFilters.length - 1;

    if (isHovered(filterId) && isLast) {
      return <CloseIcon size="1rem" color="#EC7117" />;
    }

    if (!isLast) {
      return <MdChevronRight size="1rem" />;
    }

    return null;
  }

  function handleButtonHover(filterId) {
    setHoveredButton(filterId);
  }

  function handleButtonLeave() {
    setHoveredButton(null);
  }

  function handleRemoveFilter(filterName: FilterType['name']) {
    onRemoveFilter(filterName);
  }

  function isHovered(filterId: number) {
    return hoveredButton === filterId;
  }

  function handleClick(filterName: FilterType['name'], isLastItem: boolean) {
    if (!isLastItem) return;
    if (hasOnlyOneItem) return onClearFilters();

    handleRemoveFilter(filterName);
  }

  if (!filters.length) return null;

  return (
    <Box mt={5}>
      <Text as="span">
        <Text as="strong" fontWeight="semibold">
          Filtra por:{'  '}
        </Text>

        {sortedFilters.map((filter, index) => {
          const isLastItem = index === sortedFilters.length - 1;

          return (
            <Button
              onClick={() => handleClick(filter.name, isLastItem)}
              id={String(filter.id)}
              key={filter.name + filter.id}
              variant="ghost"
              borderRadius="md"
              size="xs"
              onMouseEnter={() => isLastItem && handleButtonHover(filter.id)}
              onMouseLeave={handleButtonLeave}
              outline={isLastItem && isHovered(filter.id) ? '1px solid #EB7129' : 'none'}
              color={isLastItem && isHovered(filter.id) ? '#EB7129' : '#AFAFAF'}
              rightIcon={RightIcon(filter.id, index)}
              alignItems="center"
              pr={1}
              fontWeight={isHovered(filter.id) ? 'medium' : 'normal'}
              _hover={{ bg: 'transparent' }}
              cursor={isLastItem ? 'pointer' : 'default !important'}
            >
              {filter.value}
            </Button>
          );
        })}
      </Text>
    </Box>
  );
}
