import { Badge, Heading, Stack, Text } from '@chakra-ui/react';

import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { HiDotsVertical } from 'react-icons/hi';
import RolesAPI from '../../../../api/Roles';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';

function setBadgeStatus(status, subscriberSuspended = false) {
  const STATUS = {
    ATIVO: {
      colorScheme: 'green',
      text: 'Ativo',
    },
    BLOQUEADO: {
      colorScheme: 'red',
      text: 'Bloqueado',
    },
    EM_EDICAO: {
      colorScheme: 'orange',
      text: 'Em edição',
    },
    INATIVO: {
      colorScheme: 'gray',
      text: 'Inativo',
    },
    CONVIDADO: {
      colorScheme: 'blue',
      text: 'Convite Enviado',
    },
  };

  if (subscriberSuspended) {
    return STATUS.INATIVO;
  }

  return (
    STATUS[status] || {
      colorScheme: 'blue',
      text: status,
    }
  );
}

function TableUsers({
  handleDeleteUser,
  users,
  getUsers,
  deleteUser,
  loginAs,
  isUnificada,
  styles,
  showModalAdmin,
  setUserId,
  setRoleName,
  onOpenEditModal,
  isLoading,
  subscriberSuspended,
  isEmpty,
  isNotFoundVisible,
  isUsersVisible,
}) {
  async function deleteInvite(id) {
    try {
      await RolesAPI.deleteInvite(id);
      handleDeleteUser(id);
      Toast('Convite excluído com sucesso.');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function reinviteUser(email, role) {
    try {
      await RolesAPI.store(email, role);
      Toast('Convite reenviado com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      getUsers();
    }
  }

  return (
    <>
      {isEmpty && (
        <Stack direction="row" my={5} justifyContent="center" width="full">
          <Heading fontSize="lg">Você não tem usuários cadastrados</Heading>
        </Stack>
      )}

      {isNotFoundVisible && (
        <Stack direction="row" my={5} justifyContent="center" width="full">
          <Heading fontSize="lg">Não foi encontrado resultado para essa busca.</Heading>
        </Stack>
      )}

      {isUsersVisible && (
        <div className="row mt-5">
          <div className="col-lg-12">
            <div className="d-flex justify-content-between">
              <div style={{ width: '100%', overflow: 'auto' }}>
                {!isLoading && (
                  <table className="table">
                    <thead>
                      <tr>
                        {isUnificada && (
                          <th hidden className={styles.style_thead}>
                            Id Usuário
                          </th>
                        )}
                        <th className={styles.style_thead}>Usuário</th>
                        {isUnificada && (
                          <>
                            <th hidden className={styles.style_thead}>
                              Id Plataforma
                            </th>
                            <th className={styles.style_thead}>Plataforma</th>
                          </>
                        )}
                        {!isUnificada && <th className={styles.style_thead}>Tipo de acesso</th>}
                        <th className={styles.style_thead}>Último acesso</th>
                        <th className={styles.style_thead}>Status</th>
                        <th className={styles.style_thead}>Ações</th>
                      </tr>
                    </thead>

                    {users?.map(user => {
                      const statusBadge = setBadgeStatus(user?.status, subscriberSuspended);

                      return (
                        <tr key={nanoid()}>
                          {isUnificada && (
                            <td hidden className={`h6 mr-2 ${styles.td}`}>
                              <span className={styles.style_tbody}>{user?.id}</span>
                            </td>
                          )}
                          <td className={`h6 mr-2 ${styles.td}`}>
                            <span className={styles.style_tbody}>{user?.email}</span>
                          </td>
                          {isUnificada && (
                            <>
                              <td hidden className={`mr-2 ${styles.td}`}>
                                <span className={styles.style_tbody}>{user?.platform.id}</span>
                              </td>
                              <td className={`mr-2 ${styles.td}`}>
                                <span className={styles.style_tbody}>
                                  <a
                                    href={`https://${user?.platform.url}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {`https://${user?.platform.url}`}
                                  </a>
                                </span>
                              </td>
                            </>
                          )}
                          {!isUnificada && (
                            <td className={`h6 mr-2 ${styles.td}`}>
                              <span className={styles.style_tbody}>{user?.role.name}</span>
                            </td>
                          )}

                          <td className={`h6 mr-2 ${styles.td}`}>
                            <span className={styles.style_tbody}>
                              {dayjs(user?.lastAccess).format('DD/MM/YYYY')}
                            </span>
                          </td>
                          <td className={`h6 mr-2 ${styles.td}`}>
                            <span className={styles.style_tbody}>
                              <Badge colorScheme={statusBadge.colorScheme}>
                                {statusBadge.text}
                              </Badge>
                            </span>
                          </td>
                          <td className={`h6 ${styles.td}`}>
                            <div className="">
                              <div className="btn-group">
                                {!isUnificada &&
                                  user?.role.name !== 'DONO' &&
                                  user?.role.name !== 'ADMIN' && (
                                    <>
                                      <Text
                                        className="btn btn-outline-gray border border-gray"
                                        role="button"
                                        onClick={() => {
                                          setRoleName(user?.role?.name);
                                          setUserId(user?.id);
                                          onOpenEditModal();
                                        }}
                                      >
                                        Editar
                                      </Text>
                                      <button
                                        type="button"
                                        className="btn btn-outline-gray border border-gray px-1 dropdown-toggle dropdown-toggle-split"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <HiDotsVertical />
                                      </button>
                                      <div className="dropdown-menu">
                                        {user?.status === 'CONVIDADO' ? (
                                          <>
                                            <button
                                              className="dropdown-item"
                                              type="button"
                                              onClick={() => deleteInvite(user?.id)}
                                            >
                                              Deletar convite
                                            </button>
                                            <button
                                              className="dropdown-item"
                                              type="button"
                                              onClick={() =>
                                                reinviteUser(user?.email, user?.role.name)
                                              }
                                            >
                                              Reenviar convite
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              className="dropdown-item"
                                              type="button"
                                              onClick={() => loginAs(user?.id)}
                                            >
                                              Acessar como usuário
                                            </button>
                                            <button
                                              className="dropdown-item"
                                              type="button"
                                              onClick={() => deleteUser(user?.id)}
                                            >
                                              Excluir
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    </>
                                  )}

                                {isUnificada && (
                                  <>
                                    <button
                                      className={`btn btn-outline-gray border border-gray rounded ${styles.cursor}`}
                                      onClick={() => showModalAdmin(user?.id)}
                                    >
                                      Editar
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                    <tbody></tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TableUsers;
