import { Box, Button, Heading, Switch, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import BlogAPI from '../../../../api/Blog';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../helpers/UploadHelper';
import useFetch from '../../../../hooks/useFetch';
import ErrorMessage from '../components/ErrorMessage';
import ImageInput from '../components/ImageInput';
import ImagePreview from '../components/ImagePreview';

type NewBlogType = {
  id?: number;
  banner: any;
  showTitle: boolean;
  hideDate: boolean;
};

const initialState: NewBlogType = {
  id: null,
  banner: null,
  showTitle: true,
  hideDate: false,
};

const MAX_SIZE_FILE_PHOTO = 3145728;

export default function SettingsTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorUploadPhoto, setErrorUploadPhoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [newBlog, setNewBlog] = useState<NewBlogType>(initialState);
  const [fileBanner, setFileBanner] = useState('');
  const [hasChange, setHasChange] = useState(false);

  const { data: editBlogSectionResponse, loading } = useFetch<
    UnificadaFront.ResponseJSON<NewBlogType>
  >({
    url: `/blog-setting`,
    method: 'get',
    autoFetch: true,
    authenticated: false,
  });

  useEffect(() => {
    if (editBlogSectionResponse?.data) {
      setNewBlog({
        id: editBlogSectionResponse?.data?.id,
        banner: editBlogSectionResponse?.data?.banner,
        hideDate: editBlogSectionResponse?.data?.hideDate,
        showTitle: editBlogSectionResponse?.data?.showTitle,
      });
      setPreview(editBlogSectionResponse?.data?.banner);
    }
  }, [editBlogSectionResponse?.data]);

  function handleChangeSwitchShowTitle(event) {
    setHasChange(true);
    const { checked } = event.target;
    setNewBlog({ ...newBlog, showTitle: checked });
  }

  function handleChangeSwitchHideDate(event) {
    setHasChange(true);
    const { checked } = event.target;
    setNewBlog({ ...newBlog, hideDate: checked });
  }

  function handleDropChange(acceptedFiles) {
    setHasChange(true);

    const [firstFile] = acceptedFiles;

    if (firstFile?.size > MAX_SIZE_FILE_PHOTO) {
      return setErrorUploadPhoto('O tamanho da imagem deve ser de no máximo 3MB.');
    }

    if (firstFile) {
      setPreview(URL.createObjectURL(firstFile));

      setErrorUploadPhoto(null);

      setNewBlog(prevPost => ({ ...prevPost, banner: firstFile }));
      setFileBanner(firstFile);
    }
  }

  function handleImageDropRejected() {
    setErrorUploadPhoto('Arquivo inválido!');
  }

  function handleImageChange() {
    setHasChange(true);
    setPreview(null);
    setFileBanner('');
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    let payload = { ...newBlog, showTitle: newBlog.showTitle, hideDate: newBlog.hideDate };

    try {
      if (fileBanner && typeof fileBanner !== 'string') {
        const { newFileName } = await UploadHelper.upload(fileBanner, 'banners');

        const banner = encodeURI(newFileName);

        payload = { ...newBlog, banner: banner };
      } else {
        payload = { ...newBlog, banner: null };
      }

      await BlogAPI.blogSetting.createOrUpdate(payload);

      Toast('Blog atualizado com sucesso!', 'success');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);

      setHasChange(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Heading mb={5} size="lg">
        Banner inicial Blog
      </Heading>
      <Box>
        {!preview ? (
          <ImageInput
            isDisabled={false}
            imageDimensions={'1366x244'}
            onDrop={handleDropChange}
            onDropRejected={handleImageDropRejected}
            onChangeImage={handleImageChange}
          />
        ) : (
          <ImagePreview preview={preview} onClick={handleImageChange} />
        )}

        {errorUploadPhoto && <ErrorMessage message={errorUploadPhoto} />}

        <Heading size="md" mt={5}>
          Título da Seção
        </Heading>
        <Text fontSize="xs" color="gray">
          O nome do Blog fica visível na seção de blog da sua plataforma para os alunos.
        </Text>
        <Switch
          mt={2}
          size="sm"
          colorScheme="orange"
          fontWeight="bold"
          isChecked={newBlog.showTitle}
          onChange={handleChangeSwitchShowTitle}
        >
          Exibir título da Seção
        </Switch>
        <Heading size="md" mt={5}>
          Data das Postagens
        </Heading>
        <Text fontSize="xs" color="gray">
          A data das postagens dos artigos NÃO ficará visível para os demais usuários.
        </Text>
        <Switch
          mt={2}
          size="sm"
          colorScheme="orange"
          fontWeight="bold"
          isChecked={newBlog.hideDate}
          onChange={handleChangeSwitchHideDate}
        >
          Ocultar data das postagens
        </Switch>

        <Box
          display="flex"
          justifyContent={{ base: 'center', md: 'flex-end', lg: 'flex-end' }}
          my={5}
        >
          <Button
            type="submit"
            colorScheme="orange"
            style={{ outline: 'none', boxShadow: 'none' }}
            disabled={!hasChange}
            isLoading={isLoading || loading}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </form>
  );
}
