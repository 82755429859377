import {
  Box,
  Button,
  Center,
  Circle,
  Heading,
  Image,
  Stack,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { MdChevronLeft as LeftIcon, MdChevronRight as RightIcon } from 'react-icons/md';
import Slider, { Settings } from 'react-slick';
import { ShowcasePlan } from '../../../types';
import { Link } from 'react-router-dom';

interface HighlightPlanShowcaseProps {
  isPreview: boolean;
  plans: ShowcasePlan[];
}

export function HighlightPlanShowcase({ isPreview, plans }: HighlightPlanShowcaseProps) {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const PrevArrow = props => {
    const { onClick } = props;

    return (
      <Center
        height="80%"
        position="absolute"
        top="50%"
        left="1.5%"
        zIndex="1"
        transform="translateY(-50%)"
        onClick={onClick}
        cursor="pointer"
        _hover={{
          '& > :first-child': { bg: 'blackAlpha.500' },
          '& svg': { transform: 'scale(1.2)' },
        }}
      >
        <Circle p="3px" transition="background-color 0.3s">
          <LeftIcon
            size={32}
            color="#ffffff"
            style={{ transition: 'transform 0.3s ease-in-out' }}
          />
        </Circle>
      </Center>
    );
  };

  const NextArrow = props => {
    const { onClick } = props;

    return (
      <Center
        height="80%"
        position="absolute"
        top="50%"
        right="1.5%"
        zIndex="1"
        transform="translateY(-50%)"
        onClick={onClick}
        cursor="pointer"
        _hover={{
          '& > :first-child': { bg: 'blackAlpha.500' },
          '& svg': { transform: 'scale(1.2)' },
        }}
      >
        <Circle p="3px">
          <RightIcon
            size={32}
            color="#ffffff"
            style={{ transition: 'transform 0.3s ease-in-out' }}
          />
        </Circle>
      </Center>
    );
  };

  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: !isMobile && <PrevArrow />,
    nextArrow: !isMobile && <NextArrow />,
    autoplay: isMobile,
    autoplaySpeed: 5000,
  };

  return (
    <Slider {...settings}>
      {plans?.map(plan => (
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          align={{ base: 'center', lg: 'center' }}
          w="100%"
          key={plan.id}
          pointerEvents={isPreview ? 'none' : 'initial'}
          spacing={12}
          display="flex !important"
          px={{ base: 4, lg: '5.5rem' }}
        >
          <VStack spacing={{ base: 4, lg: 10 }} align={{ base: 'center', lg: 'flex-start' }}>
            <Heading
              fontWeight="medium"
              fontSize={{ base: 'xl', lg: '4xl' }}
              lineHeight="39.01px"
              m={0}
            >
              {plan.titleHighlight}
            </Heading>

            <Image
              display={{ base: 'block', lg: 'none !important' }}
              src={isMobile && plan.imageMobile ? plan.imageMobile : plan.imageDesktop}
              alt={plan.titleHighlight}
              boxSize="100%"
              maxWidth="100%"
              objectFit="cover"
            />

            <Box
              dangerouslySetInnerHTML={{ __html: plan.description }}
              wordBreak="break-all"
              whiteSpace="pre-wrap"
            />

            <Button
              as={Link}
              to={`/recurrence-checkout/${plan.recurrencePlan.id}/personal-data`}
              colorScheme="gray"
              bg="#ffffff"
              color="#202123"
              size={{ base: 'sm', lg: 'md' }}
            >
              Contratar plano
            </Button>
          </VStack>

          <Image
            display={{ base: 'none !important', lg: 'block !important' }}
            src={plan.imageDesktop}
            alt={plan.titleHighlight}
            boxSize="64%"
            maxWidth="723px"
            objectFit="cover"
          />
        </Stack>
      ))}
    </Slider>
  );
}
