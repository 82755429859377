import { URLSearchParams } from 'url';

export default function hasFilterParams(urlSearchParams: URLSearchParams) {
  // Obtém todos os parâmetros da URLSearchParams
  const params = new Set(urlSearchParams.keys());

  // Remove o parâmetro "page" do conjunto de parâmetros
  params.delete('page');

  // Verifica se ainda existem outros parâmetros além de "page"
  return params.size > 0;
}
