import { Box, Flex, Heading, Input, Select, Text, VStack } from '@chakra-ui/react';
import Platform from '../../../../api/Platform';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import BackButton from '../Buttons/BackButton';
import NextButton from '../Buttons/NextButton';
import { ChangeEvent, FormEvent, useState } from 'react';

const inicialStateUsers = {
  firstRole: '',
  firstEmail: '',
  secondRole: '',
  secondEmail: '',
  thirdRole: '',
  thirdEmail: '',
};

export default function TabUsers({ onGoToNextTab, onGoToPreviousTab }) {
  const [users, setUsers] = useState(inicialStateUsers);
  const [loading, setLoading] = useState(false);

  function handleUsersChange(event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) {
    const { name, value } = event.target;

    setUsers(prevPost => ({ ...prevPost, [name]: value }));
  }

  async function onSubmitUsers(event: FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault();

    let invitations = [];

    if (users.firstRole && users.firstEmail) {
      const newInvitation = [...invitations];

      newInvitation.push({
        role: users?.firstRole,
        email: users?.firstEmail,
      });
      invitations = newInvitation;
    }

    if (users.secondRole && users.secondEmail) {
      const newInvitation = [...invitations];

      newInvitation.push({
        role: users?.secondRole,
        email: users?.secondEmail,
      });
      invitations = newInvitation;
    }

    if (users.thirdRole && users.thirdEmail) {
      const newInvitation = [...invitations];

      newInvitation.push({
        role: users?.thirdRole,
        email: users?.thirdEmail,
      });
      invitations = newInvitation;
    }

    try {
      setLoading(true);

      if (invitations.length) {
        await Platform.integrationUsers({ invitations });
        setLoading(true);
        Toast('Convites Enviados!', 'success');
      }

      setLoading(false);
      onGoToNextTab();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Flex
      grow={1}
      direction="column"
      justify="space-between"
      as="form"
      onSubmit={onSubmitUsers}
      overflow={{ base: 'scroll', md: 'unset' }}
      maxHeight={{ base: '72vh', md: 'unset' }}
      pb={{ base: 4, md: 'unset' }}
      sx={{ '::-webkit-scrollbar': { display: 'none' } }}
    >
      <Box>
        <Heading size="lg" fontWeight={{ base: 'semibold', md: 'bold' }}>
          Usuários
        </Heading>

        <Text mt={2} fontSize={{ base: 'sm', md: 'md' }} color="#20212350" fontWeight="medium">
          Convide até 3 usuários para ajudar na gestão de sua plataforma de ensino, você poderá
          adicionar mais usuários depois. Fique atento ao tipo de acesso dos convidados.
        </Text>

        <VStack align="flex-start" mt={{ base: 5, md: 8 }} spacing={6}>
          <Heading fontWeight="500" size="sm" lineHeight={0}>
            Usuário 1
          </Heading>

          <Flex direction={{ base: 'column', md: 'row' }} align="flex-start" gap={2} w="full">
            <Flex grow={{ base: 1, md: 'unset' }} w={{ base: 'full', md: 'unset' }}>
              <Select
                placeholder="Tipo de Acesso"
                focusBorderColor="#EB7129"
                name="firstRole"
                id="firstRole"
                onChange={handleUsersChange}
                value={users.firstRole}
              >
                <option value="GESTOR">GESTOR</option>
                <option value="FINANCEIRO">FINANCEIRO</option>
                <option value="MARKETING">MARKETING</option>
                <option value="BLOGUEIRO">BLOGUEIRO</option>
                <option value="INSTRUTOR">INSTRUTOR</option>
                <option value="SUPORTE">SUPORTE</option>
              </Select>
            </Flex>

            <Flex grow={1} w={{ base: 'full', md: 'unset' }}>
              <Input
                type="text"
                placeholder="E-mail"
                focusBorderColor="#EB7129"
                name="firstEmail"
                id="firstEmail"
                onChange={handleUsersChange}
                value={users.firstEmail}
              />
            </Flex>
          </Flex>

          <Heading fontWeight="500" size="sm" lineHeight={0}>
            Usuário 2
          </Heading>

          <Flex direction={{ base: 'column', md: 'row' }} align="center" gap={2} w="full">
            <Flex grow={{ base: 1, md: 'unset' }} w={{ base: 'full', md: 'unset' }}>
              <Select
                placeholder="Tipo de Acesso"
                focusBorderColor="#EB7129"
                name="secondRole"
                id="secondRole"
                onChange={handleUsersChange}
                value={users.secondRole}
                isDisabled={users.firstRole === '' || users.firstEmail === ''}
              >
                <option value="GESTOR">GESTOR</option>
                <option value="FINANCEIRO">FINANCEIRO</option>
                <option value="MARKETING">MARKETING</option>
                <option value="BLOGUEIRO">BLOGUEIRO</option>
                <option value="INSTRUTOR">INSTRUTOR</option>
                <option value="SUPORTE">SUPORTE</option>
              </Select>
            </Flex>

            <Flex grow={1} w={{ base: 'full', md: 'unset' }}>
              <Input
                type="text"
                placeholder="E-mail"
                focusBorderColor="#EB7129"
                value={users.secondEmail}
                name="secondEmail"
                id="secondEmail"
                onChange={handleUsersChange}
                isDisabled={users.firstRole === '' || users.firstEmail === ''}
              />
            </Flex>
          </Flex>

          <Heading fontWeight="500" size="sm" lineHeight={0}>
            Usuário 3
          </Heading>

          <Flex direction={{ base: 'column', md: 'row' }} align="center" gap={2} w="full">
            <Flex grow={{ base: 1, md: 'unset' }} w={{ base: 'full', md: 'unset' }}>
              <Select
                placeholder="Tipo de Acesso"
                focusBorderColor="#EB7129"
                name="thirdRole"
                id="thirdRole"
                onChange={handleUsersChange}
                value={users.thirdRole}
                isDisabled={
                  users.firstRole === '' ||
                  users.firstEmail === '' ||
                  users.secondRole === '' ||
                  users.secondEmail === ''
                }
              >
                <option value="GESTOR">GESTOR</option>
                <option value="FINANCEIRO">FINANCEIRO</option>
                <option value="MARKETING">MARKETING</option>
                <option value="BLOGUEIRO">BLOGUEIRO</option>
                <option value="INSTRUTOR">INSTRUTOR</option>
                <option value="SUPORTE">SUPORTE</option>
              </Select>
            </Flex>

            <Flex grow={1} w={{ base: 'full', md: 'unset' }}>
              <Input
                type="text"
                placeholder="E-mail"
                focusBorderColor="#EB7129"
                value={users.thirdEmail}
                name="thirdEmail"
                id="thirdEmail"
                onChange={handleUsersChange}
                isDisabled={
                  users.firstRole === '' ||
                  users.firstEmail === '' ||
                  users.secondRole === '' ||
                  users.secondEmail === ''
                }
              />
            </Flex>
          </Flex>
        </VStack>
      </Box>

      <Flex
        as="footer"
        justify={{ base: 'space-between', md: 'flex-end' }}
        gap={4}
        mt={{ base: 10, md: 0 }}
      >
        <BackButton onClick={onGoToPreviousTab} isLoading={loading} />
        <NextButton type="submit" isLoading={loading} />
      </Flex>
    </Flex>
  );
}
