export function getModuleId(modulesContents) {
  const module = modulesContents[0]?.id;
  return module;
}

export function getContentId(modulesContents) {
  const contents = modulesContents[0]?.contents;

  const currentContent = contents?.find(content => content);

  return currentContent?.id;
}
