import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';

import Joi from 'joi';
import { FormEvent, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  EMAIL_LOCAL_STORAGE,
  ID_LOCAL_STORAGE,
  NAME_LOCAL_STORAGE,
  PHOTO_LOCAL_STORAGE,
  ROLE_LOCAL_STORAGE,
  TOKEN_LOCAL_STORAGE,
} from '../../../helpers/LocalStorageHelper';

import useHandleChange from '../../../hooks/useHandleChange';
import useHandleSubmit from '../../../hooks/useHandleSubmit';
import useQuery from '../../../hooks/useQuery';
import { ResponseJSON } from '../../../utils/CreateToastAlert';
import UserSchema, { UserSchemaType } from '../../../validation/UserSchema';
import PasswordInput from '../PasswordInput';
import { FORM_STYLES, INPUT_STYLES, LINK_BUTTON_STYLES } from '../constants';

type LoginResultType = {
  id: string;
  fullName: string;
  token: string;
  role: string;
  email: string;
  photo: string;
  phoneNumber: string;
};

function saveUserInfoToLocalStorage(response) {
  const authenticationData: LoginResultType = response?.data?.data;

  const { id, fullName, token, role, email, photo } = authenticationData;

  localStorage.setItem(ID_LOCAL_STORAGE, id);
  localStorage.setItem(NAME_LOCAL_STORAGE, fullName);
  localStorage.setItem(TOKEN_LOCAL_STORAGE, token);
  localStorage.setItem(ROLE_LOCAL_STORAGE, role);
  localStorage.setItem(EMAIL_LOCAL_STORAGE, email);
  localStorage.setItem(PHOTO_LOCAL_STORAGE, photo);
}

type LoginSchemaType = Pick<UserSchemaType, 'email' | 'password'>;

const schema = Joi.object<LoginSchemaType>().keys({
  email: UserSchema.email.required(),
  password: UserSchema.password.required(),
});

export default function Login() {
  const query = useQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const redirectToPaymentPage = useCallback(async () => {
    urlSearchParams.set('added-to-cart-authentication', 'true');

    history.push({
      pathname: '/student-checkout/v3/payment',
      search: urlSearchParams.toString(),
    });
  }, [history, urlSearchParams]);

  const { form, handleChange } = useHandleChange<LoginSchemaType>({
    email: query.get('email') || '',
    password: '',
  });

  const {
    handleSubmit,
    formValidation,
    isLoading,
    error: submittingError,
  } = useHandleSubmit({
    data: form,
    schemaValidator: schema,
    method: 'post',
    url: '/login',
    onSuccess: {
      callback: redirectToPaymentPage,
    },
  });

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      const response = await handleSubmit<ResponseJSON<LoginResultType>>();

      if (response) {
        saveUserInfoToLocalStorage(response);
      }
    },
    [handleSubmit]
  );

  return (
    <Flex
      align="center"
      direction="column"
      w="full"
      as="form"
      onSubmit={onSubmit}
      noValidate
      mt={{ base: 6, md: 'none' }}
    >
      <Heading fontSize="lg" fontWeight="semibold">
        Login
      </Heading>

      <VStack w="full" sx={FORM_STYLES} spacing={10} mt={4}>
        <FormControl isInvalid={formValidation?.email.isInvalid || !!submittingError}>
          <FormLabel>Email</FormLabel>
          <Input
            name="email"
            value={form.email}
            onChange={handleChange}
            placeholder="Digite seu email"
            type="email"
            {...INPUT_STYLES}
          />
          <FormErrorMessage color="#BB2124">
            {formValidation?.email.message || submittingError}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.password.isInvalid || !!submittingError}>
          <FormLabel>Senha</FormLabel>
          <PasswordInput
            name="password"
            value={form.password}
            onChange={handleChange}
            placeholder="Digite sua senha"
            {...INPUT_STYLES}
          />
          <FormErrorMessage color="#BB2124">{formValidation?.password.message}</FormErrorMessage>
        </FormControl>
      </VStack>

      <Button
        to={`/reset-password${window.location.search}`}
        color="#202123"
        alignSelf="end"
        mt={2}
        {...LINK_BUTTON_STYLES}
      >
        Esqueceu a senha?
      </Button>

      <VStack w="full" spacing={5} mt={8}>
        <Button
          type="submit"
          colorScheme="orange"
          bg="#EC7117"
          px="4.5rem"
          fontSize="md"
          fontWeight="semibold"
          isLoading={isLoading}
        >
          Entrar
        </Button>

        <VStack spacing={0}>
          <Text color="#202123" fontWeight="medium">
            Não possui cadastro?
          </Text>

          <Button
            to={`/student-checkout/v3/register${window.location.search}`}
            colorScheme="orange"
            color="#eb7129"
            isDisabled={isLoading}
            {...LINK_BUTTON_STYLES}
          >
            Criar uma conta
          </Button>
        </VStack>
      </VStack>
    </Flex>
  );
}
