import { Box, Button, Flex, Image, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { memo } from 'react';
import { IoChevronDown } from 'react-icons/io5';
import Country from './Country';
import countries from './countries';
import DDIHelper from '../../helpers/DDIHelper';

export function getDDI(country: string) {
  return DDIHelper.find(item => item.initials.toLowerCase() === country?.toLowerCase())?.ddi;
}

export function getCountryByDDI(ddi: string) {
  return DDIHelper.find(item => item.ddi === ddi)?.initials?.toLowerCase();
}

const scrollbarStyle = {
  '::-webkit-scrollbar': { width: '0.5rem', background: 'transparent' },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: '0.25rem',
    backgroundColor: `gray.200`,
    height: '50%',
  },
};

interface CountrySelectProps {
  value?: string;
  onChange?: (country: string) => void;
  isInvalid?: boolean;
}

function CountrySelect({ value, onChange, isInvalid }: CountrySelectProps) {
  function handleCountryChange(country: string) {
    onChange(country.toLowerCase());
  }

  const currentCountry = countries.find(
    country => country.initials?.toLowerCase() === value?.toLowerCase()
  );

  return (
    <Menu id="country" matchWidth size="sm">
      <MenuButton
        as={Button}
        rightIcon={<IoChevronDown color="#2D3748" />}
        justifyContent="space-between"
        bg="transparent"
        borderRadius="md"
        color="#2D374880"
        fontSize="md"
        flexShrink={0}
        width="full"
        border="1px solid #20212326"
        _expanded={{ borderColor: '#eb7129', boxShadow: `0 0 0 1px #eb7129` }}
        _focus={{ borderColor: '#eb7129', boxShadow: `0 0 0 1px #eb7129` }}
        _hover={{
          bg: 'transparent',
          borderColor: isInvalid ? '#EB5757' : 'gray.300',
        }}
        _active={{ bg: 'transparent' }}
        sx={{ span: { color: '#2D374880', fontWeight: 'normal', flex: 'initial' } }}
        borderColor={isInvalid && '#EB5757'}
        borderWidth={isInvalid && '2px'}
      >
        <Flex>
          {currentCountry?.flag ? (
            <Image
              src={currentCountry?.flag}
              w="28px"
              h="18px"
              mr={2}
              alt={currentCountry?.country}
              borderRadius="base"
              objectFit="cover"
            />
          ) : (
            <Box bg="#D9D9D9" w="28px" h="18px" mr={2} borderRadius="base" />
          )}

          {currentCountry?.country || 'Código inválido'}
        </Flex>
      </MenuButton>

      <MenuList maxH="300px" overflowY="auto" sx={scrollbarStyle}>
        {countries.map(country => (
          <Country key={country.initials} country={country} onClick={handleCountryChange} />
        ))}
      </MenuList>
    </Menu>
  );
}

export default memo(CountrySelect);
