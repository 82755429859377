import { Card, Center, Heading, HStack, ListItem as ChakraListItem, Text } from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';

interface ListItemProps {
  title: string;
  description: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  to?: LinkProps['to'];
}

export default function ListItem({ title, description, icon, isDisabled, to }: ListItemProps) {
  return (
    <ChakraListItem>
      <Card
        p={4}
        w="full"
        as={Link}
        to={!isDisabled ? to : '#'}
        border="1px solid #2021230D"
        boxShadow="0px 4px 4px 0px #00000040"
        _hover={{ bg: !isDisabled && 'gray.50' }}
        transition="all 0.2s"
        bg={isDisabled ? 'gray.100' : 'white'}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
      >
        <HStack>
          <Center bg="#8F8F90" boxSize={6} borderRadius="md" color="#ffffff" p={1}>
            {icon}
          </Center>

          <Heading fontSize="sm" fontWeight="semibold" as="h2">
            {title}
          </Heading>
        </HStack>

        <Text lineHeight="17.07px" color="#828282" mt={4}>
          {description}
        </Text>
      </Card>
    </ChakraListItem>
  );
}
