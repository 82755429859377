import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UseDisclosureProps,
} from '@chakra-ui/react';
import ModalContentBlock from './ModalContents/ModalContentBlock';
import ModalContentSubscription from './ModalContents/ModalContentSubscription';
import ModalContentSuccess from './ModalContents/ModalContentSuccess';

import { ActiveModalContentType, useSubscription } from '../../contexts/SubscriptionContext';

export function capitalizePlanName(plan: string) {
  return plan
    ?.split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
}

interface SubscriptionBlockModalProps extends UseDisclosureProps {
  activeModalContent: ActiveModalContentType;
  onChangeActiveModalContent(activeModalContent: ActiveModalContentType): void;
}

export function SubscriptionBlockModal({
  isOpen,
  onClose,
  activeModalContent,
  onChangeActiveModalContent,
}: SubscriptionBlockModalProps) {
  const { getSubscriptionStatus } = useSubscription();

  function goToModalContentSubscription() {
    onChangeActiveModalContent('subscription');
  }

  function goToModalContentSuccess() {
    onChangeActiveModalContent('success');
  }

  async function onCloseModal() {
    onClose();

    onChangeActiveModalContent('block');

    if (activeModalContent === 'success') {
      await getSubscriptionStatus();
    }
  }

  function ModalContentSteps({ currentStep }) {
    const contents = {
      block: <ModalContentBlock onActionButtonClick={goToModalContentSubscription} />,
      subscription: <ModalContentSubscription onActionButtonClick={goToModalContentSuccess} />,
      success: <ModalContentSuccess />,
    };

    return contents[currentStep];
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onCloseModal} motionPreset="none">
      <ModalOverlay bg="rgba(32, 33, 35, 0.15)" backdropFilter="blur(3.5px)" />

      <ModalContent>
        <ModalCloseButton mt={3} mr={4} _focus={{ outline: 'none', boxShadow: 'none' }} />

        <ModalContentSteps currentStep={activeModalContent} />
      </ModalContent>
    </Modal>
  );
}
