import { Avatar, Box, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ActionButton, ProductsAffiliate } from '../../..';
import { ITheme } from '../../../../../../../contexts/ThemeContext';
import convertToBrazilianCurrency from '../../../../../../../helpers/convertToBrazilianCurrency';

type CardType = {
  themeMarketplace: ITheme;
  affiliateProduct: ProductsAffiliate;
  setAffiliationId: (affiliationId: string) => void;
  setCourseAffiliationName: (productName: string) => void;
  onOpenRemoveProduct: () => void;
};

export default function Card({
  themeMarketplace,
  affiliateProduct,
  onOpenRemoveProduct,
  setAffiliationId,
  setCourseAffiliationName,
}: CardType) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      background="#FFFFFF"
      boxShadow="1px 1px 4px rgba(0, 0, 0, 0.25)"
      borderRadius="6px"
      maxW={390}
      py={4}
      my={3}
    >
      <Stack
        direction="column"
        alignItems="center"
        mx={3}
        as={Link}
        to={`/affiliations/my-affiliations/${affiliateProduct.courseAffiliationId}`}
      >
        {affiliateProduct.thumbnail ? (
          <Image
            src={affiliateProduct.thumbnail}
            boxSize={330}
            borderRadius={5}
            objectFit="contain"
          />
        ) : (
          <Avatar
            name={'Nome do produto'}
            bg={themeMarketplace.primaryColor}
            color={themeMarketplace.textColor}
            boxSize={330}
            borderRadius={5}
            objectFit="contain"
          />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mx={5} my={2}>
        <Heading fontSize="md" w="100%" fontWeight={500} isTruncated>
          {affiliateProduct.productName}
        </Heading>
        <ActionButton
          affiliationId={affiliateProduct.affiliationId}
          courseAffiliationId={affiliateProduct.courseAffiliationId}
          productName={affiliateProduct.productName}
          onOpenRemoveProduct={onOpenRemoveProduct}
          setAffiliationId={setAffiliationId}
          setCourseAffiliationName={setCourseAffiliationName}
        />
      </Stack>

      <Stack direction="column" spacing={5} mx={5}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text color="#20212390" fontWeight={600}>
            Preço/Comissão
          </Text>
          <Stack direction="column" alignItems="center" spacing={0} fontWeight={500}>
            <Text fontSize="xs" color="#20212390">
              {convertToBrazilianCurrency(affiliateProduct.productValue)}
            </Text>
            <Text fontSize="xx-small" color="#20212390">
              <Text as="span" color="#22BB33">{`${affiliateProduct.commissionValue}%`}</Text> de
              Comissão
            </Text>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text color="#20212390" fontWeight={600}>
            Nº de vendas:
          </Text>
          <Text color="#20212390" fontWeight={500}>
            {affiliateProduct.amountOfSales}
          </Text>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text color="#20212390" fontWeight={600}>
            Ganhos:
          </Text>
          <Text color="#22BB33" fontWeight={500}>
            {convertToBrazilianCurrency(affiliateProduct.winnings)}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
}
