import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button, ListItem, ListItemProps } from '@chakra-ui/react';

interface FilterListItemProps extends ListItemProps {
  filterId: number;
  name: string;
  hasIcon?: boolean;
}

export default function FilterListItem({
  name,
  filterId,
  hasIcon = true,
  ...props
}: FilterListItemProps) {
  return (
    <ListItem {...props}>
      <Button
        variant="link"
        size="sm"
        color="#202123"
        w="full"
        justifyContent="space-between"
        rightIcon={hasIcon && <ChevronRightIcon boxSize={4} />}
        fontWeight="normal"
        textDecoration="none"
        _focus={{ textDecoration: 'none' }}
      >
        {name}
      </Button>
    </ListItem>
  );
}
