import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  IconButton,
  ListItem,
  Stack,
  Tooltip,
} from '@chakra-ui/react';

import { MdContentCopy, MdDeleteOutline } from 'react-icons/md';
import { FormValidation } from '../../../../../../hooks/useHandleSubmit';
import FilterType from '../../types/Filter';
import Question from '../../types/Question';
import { UploadQuestionPayload } from '../../types/UploadQuestionPayload';
import FiltersDisplay from './FiltersDisplay';
import FiltersMenu from './FiltersMenu';
import QuestionForm from './QuestionForm';

interface QuestionItemProps {
  question: Question;
  isEditing: boolean;
  filtersList: any;
  filters: any;
  isFetching?: boolean;
  formValidation?: FormValidation<UploadQuestionPayload>;
  questionBeingUpdated?: Question;
  isLoading: boolean;
  onAddFilter: (filter: FilterType) => void;
  onDuplicate: (id: number) => void;
  onDelete: (id: number) => void;
  onClick: (id: number) => void;
  onUpdate: () => void;
  onQuestionBeingUpdatedChange: (question: Partial<Question>) => void;
  onQuestionsListChange: (question: Question) => void;
}

export default function QuestionItem({
  question,
  isEditing,
  filtersList,
  filters,
  isFetching,
  formValidation,
  questionBeingUpdated,
  isLoading,
  onQuestionsListChange,
  onDuplicate,
  onDelete,
  onClick,
  onUpdate,
  onQuestionBeingUpdatedChange,
  onAddFilter,
}: QuestionItemProps) {
  function handleClick() {
    if (isEditing) return;

    onClick(question.id);
  }

  return (
    <ListItem>
      <Card
        borderColor="#2021230D"
        boxShadow="0px 4px 4px 0px #00000040"
        onClick={handleClick}
        cursor={isEditing ? 'default' : 'pointer'}
      >
        <CardHeader pb={2.5}>
          <Stack direction="row" align={{ base: 'start', lg: 'center' }} justify="space-between">
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              align={{ base: 'start', lg: 'center' }}
            >
              <Badge
                id={String(question.id)}
                colorScheme="default"
                bg="default.500"
                color="#ffffff"
                borderRadius="md"
                px={2}
                py={0.5}
                fontWeight="semibold"
                lineHeight="16px"
              >
                ID: {question.id}
              </Badge>

              {!isEditing ? (
                <FiltersDisplay question={question} />
              ) : (
                <FiltersMenu
                  question={questionBeingUpdated}
                  isLoading={isFetching}
                  filters={filters}
                  filtersList={filtersList}
                  onAddFilter={onAddFilter}
                  menuItems={filtersList}
                  onQuestionBeingUpdatedChange={onQuestionBeingUpdatedChange}
                  formValidation={formValidation}
                />
              )}
            </Stack>

            <HStack align="center" spacing={0}>
              <Tooltip hasArrow placement="top" label="Duplicar questão" fontSize="xs">
                <IconButton
                  onClick={() => onDuplicate(question.id)}
                  m={0}
                  variant="ghost"
                  aria-label="Duplicar questão"
                  icon={<MdContentCopy size="1rem" color="#AFAFAF" />}
                  size="sm"
                />
              </Tooltip>

              <Tooltip hasArrow placement="top" label="Excluir questão" fontSize="xs">
                <IconButton
                  onClick={() => onDelete(question.id)}
                  m={0}
                  variant="ghost"
                  aria-label="Excluir questão"
                  icon={<MdDeleteOutline size="1.25rem" color="#AFAFAF" />}
                  size="sm"
                />
              </Tooltip>
            </HStack>
          </Stack>
        </CardHeader>

        {!isEditing ? (
          <CardBody pt={2.5}>
            <Box
              color="#202123"
              dangerouslySetInnerHTML={{ __html: question.questionText }}
              sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
            />
          </CardBody>
        ) : (
          <QuestionForm
            question={question}
            isLoading={isLoading}
            onUpdate={onUpdate}
            onQuestionBeingUpdatedChange={onQuestionBeingUpdatedChange}
            onQuestionsListChange={onQuestionsListChange}
          />
        )}
      </Card>
    </ListItem>
  );
}
