import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Toast from '../../../components/Toast';
import { FaExclamationCircle, FaEye, FaEyeSlash } from 'react-icons/fa';
import StudentApi from '../../../api/Student';
import { Box, Heading, Container } from '@chakra-ui/react';

function EditPassword() {
  const [typeIcon, setTypeIcon] = useState(true);
  const [typeIcon2, setTypeIcon2] = useState(true);
  const [typeIcon3, setTypeIcon3] = useState(true);

  const [disabled, setDisabled] = useState(true);

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [isShowModal, setIsShowModal] = useState(false);

  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  function handleChange(event) {
    const { name, value } = event.target;

    if (name === 'oldPassword') {
      setOldPassword(value);
    }
    if (name === 'password') {
      setPassword(value);
    }
    if (name === 'newPassword') {
      setNewPassword(value);
    }

    if (
      oldPassword !== null &&
      oldPassword !== '' &&
      password !== '' &&
      password !== null &&
      newPassword !== '' &&
      newPassword !== null
    ) {
      setDisabled(false);
    }
  }

  async function onSubmit(event) {
    event.preventDefault();
    setDisabled(true);
    try {
      if (oldPassword === '' || oldPassword === null) {
        setTypeMessage('erro');
        setMessage('Você precisa informar a sua senha atual');
        return setIsShowModal(true);
      }
      if (password === '' || password === null) {
        setTypeMessage('erro');
        setMessage('Você precisa informar a sua nova senha');
        return setIsShowModal(true);
      }
      if (newPassword === '' || newPassword === null) {
        setTypeMessage('erro');
        setMessage('Você precisa confirmar a sua nova senha');
        return setIsShowModal(true);
      }
      if (password !== newPassword) {
        setTypeMessage('erro');
        setMessage('As senhas não coincidem, por favor verifique-as');
        return setIsShowModal(true);
      }

      const updatedStudent = {
        oldPassword: oldPassword,
        newPassword: password,
      };

      await StudentApi.redefinePassword(updatedStudent);
      setPassword('');
      setOldPassword('');
      setNewPassword('');
      setDisabled(false);
      return Toast('Senha atualizada com sucesso');
    } catch (error) {
      setMessage('A sua senha atual foi digitada incorretamente');
      setTypeMessage('erro');
      setDisabled(false);
      return setIsShowModal(true);
    }
  }

  function show(id) {
    const senha = document.getElementById(id);
    if (id === 'oldPassword') {
      if (senha.type === 'password') {
        senha.type = 'text';
        setTypeIcon(false);
      } else {
        senha.type = 'password';
        setTypeIcon(true);
      }
    }
    if (id === 'password') {
      if (senha.type === 'password') {
        senha.type = 'text';
        setTypeIcon2(false);
      } else {
        senha.type = 'password';
        setTypeIcon2(true);
      }
    }
    if (id === 'newPassword') {
      if (senha.type === 'password') {
        senha.type = 'text';
        setTypeIcon3(false);
      } else {
        senha.type = 'password';
        setTypeIcon3(true);
      }
    }
  }

  return (
    <Container maxW="container.xl" pt={70}>
      <div className="nk-app-root" style={{ overflow: 'hidden' }}>
        <div className="nk-main">
          <div className="row">
            <div className="col-lg-12 ml-5">
              <Box
                display="flex"
                my={5}
                justifyContent={{ base: 'center', md: 'center', lg: 'flex-start' }}
              >
                <Heading size="xl">Redefinir senha</Heading>
              </Box>
            </div>
          </div>

          <div className="justify-content-center ">
            <center className="mt-1">
              <form className="col-md-6 mt-1" onSubmit={onSubmit}>
                <div className="form-group mt-1">
                  <div className="label-form-group">
                    <div className="column">
                      <div className="col-md-12 mr-3">
                        <label
                          className="form-label d-flex justify-content-start"
                          style={{ fontSize: '20px' }}
                          htmlFor="oldPassword"
                        >
                          Digite a sua senha atual
                        </label>

                        <div className="input-group">
                          <input
                            value={oldPassword}
                            name="oldPassword"
                            type="password"
                            className="form-control form-control-lg"
                            id="oldPassword"
                            placeholder="Insira senha atual"
                            onChange={handleChange}
                          />
                          <span
                            style={{
                              background: '#D8E3E3',
                              width: '30px',
                              cursor: 'pointer',
                              borderRadius: '2px',
                            }}
                          >
                            {typeIcon && (
                              <FaEye
                                onClick={() => {
                                  show('oldPassword');
                                }}
                                style={{ color: 'grey' }}
                              />
                            )}
                            {!typeIcon && (
                              <FaEyeSlash
                                onClick={() => {
                                  show('oldPassword');
                                }}
                                style={{ color: 'grey' }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12 mt-2 mr-3">
                        <label
                          className="form-label d-flex justify-content-start"
                          htmlFor="password"
                          style={{ fontSize: '20px' }}
                        >
                          Digite sua nova senha
                        </label>

                        <div className="input-group">
                          <input
                            value={password}
                            name="password"
                            type="password"
                            className="form-control form-control-lg"
                            id="password"
                            placeholder="Insira a nova senha"
                            onChange={handleChange}
                          />
                          <span
                            style={{
                              background: '#D8E3E3',
                              width: '30px',
                              cursor: 'pointer',
                              borderRadius: '2px',
                            }}
                          >
                            {typeIcon2 && (
                              <FaEye
                                onClick={() => {
                                  show('password');
                                }}
                                style={{ color: 'grey' }}
                              />
                            )}
                            {!typeIcon2 && (
                              <FaEyeSlash
                                onClick={() => {
                                  show('password');
                                }}
                                style={{ color: 'grey' }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12 mt-2 mr-3">
                        <label
                          className="form-label d-flex justify-content-start"
                          htmlFor="newPassword"
                          style={{ fontSize: '20px' }}
                        >
                          Confirme sua nova senha
                        </label>

                        <div className="input-group">
                          <input
                            value={newPassword}
                            name="newPassword"
                            type="password"
                            className="form-control form-control-lg"
                            id="newPassword"
                            placeholder="Confirme a nova senha"
                            onChange={handleChange}
                          />
                          <span
                            style={{
                              background: '#D8E3E3',
                              width: '30px',
                              cursor: 'pointer',
                              borderRadius: '2px',
                            }}
                          >
                            {typeIcon3 && (
                              <FaEye
                                onClick={() => {
                                  show('newPassword');
                                }}
                                style={{ color: 'grey' }}
                              />
                            )}
                            {!typeIcon3 && (
                              <FaEyeSlash
                                onClick={() => {
                                  show('newPassword');
                                }}
                                style={{ color: 'grey' }}
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-end my-4 col-md-12 mr-3">
                        <Link className="btn btn-secondary text-center" to="/profile">
                          Voltar para tela de perfil
                        </Link>
                        <button
                          type="submit"
                          className=" btn btn-primary text-center ml-3"
                          disabled={disabled}
                          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                        >
                          Alterar senha
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </center>
          </div>

          <Modal
            centered
            size="sm"
            show={isShowModal}
            onHide={() => setIsShowModal(false)}
            style={{
              backdropFilter: 'blur(4px)',
            }}
          >
            <Modal.Body>
              <div className="row d-flex justify-content-center mb-1 py-4">
                {typeMessage === 'erro' && (
                  <FaExclamationCircle
                    className="d-flex justify-content-center mb-2"
                    style={{
                      color: 'red',
                      height: '50px',
                      width: '50px',
                    }}
                  />
                )}
                <h3 className="text-center">{message}</h3>
              </div>
              <div className="d-flex justify-content-center mb-4 ">
                <button
                  onClick={() => setIsShowModal(false)}
                  className="btn btn-light"
                  style={{
                    backgroundColor: '#EB7129',
                    color: 'white',
                    alignItems: 'center',
                  }}
                >
                  Voltar para redefinição de senha
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Container>
  );
}

export default EditPassword;
