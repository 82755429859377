import { Image, ImageProps } from '@chakra-ui/react';

import brands from '../../../assets/images/brands.png';

interface IBrands extends ImageProps {}

function Brands({ ...rest }: IBrands) {
  return (
    <Image
      src={brands}
      alt="Imagem com cartões aceitos para compra(visa, mastercard, elo, american express e diners club)"
      {...rest}
    />
  );
}

export default Brands;
