import { Box, Image, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { GoArrowRight } from 'react-icons/go';
import { HiDotsHorizontal } from 'react-icons/hi';
import { MdDragIndicator, MdOutlineImageNotSupported } from 'react-icons/md';
import { IProduct, IShowcase } from '../../..';
import { StatusSelect } from '../StatusSelect';

export function getStatusColorByName(status: string) {
  switch (status) {
    case 'ATIVO':
      return '#22BB33';
    case 'INATIVO':
      return '#BB2124';
    case 'EM_EDICAO':
      return '#E8C536';
    default:
      return '#202123';
  }
}

interface ShowCaseProductListItemProps {
  products: IProduct[];
  showcase: IShowcase;
  isMobile: boolean;
  handleOnDragEnd: (result: DropResult) => void;
  setShowcaseItemId: (id: number) => void;
  onAddBannerClick(showcaseCourseId: number): void;
  removeBanner(showcaseCourseId: number): void;
  editModules(productId: number, showcaseId: number, showcaseCourseId: number): void;
  deleteProduct(showcaseCourseId: number, showcase: IShowcase): void;
  onStatusChange: (
    event: ChangeEvent<HTMLSelectElement>,
    id: number,
    showcaseType: IShowcase['showcaseType']
  ) => void;
}

export function ShowCaseProductListItem({
  products,
  showcase,
  isMobile,
  handleOnDragEnd,
  setShowcaseItemId,
  onStatusChange,
  onAddBannerClick,
  removeBanner,
  editModules,
  deleteProduct,
}: ShowCaseProductListItemProps) {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="showcase-products">
        {provided => (
          <Box hidden={!products.length} {...provided.droppableProps} ref={provided.innerRef}>
            {products?.map((product, index) => {
              const hiddenRemoveBanner =
                !product.hasCourseThumbnailNetflix || !product.courseThumbnail;

              const bgColor = index % 2 === 0 ? '#f4f4f4' : '#ffffff';

              return (
                <Draggable
                  key={String(product.showcaseCourseId)}
                  draggableId={String(product.showcaseCourseId)}
                  index={index}
                  isDragDisabled={products?.length < 2}
                >
                  {provided => (
                    <Box
                      padding={{ base: '24px 20px 24px 20px', lg: '24px 30px 24px 30px' }}
                      height="67px"
                      display="flex"
                      gap={{ base: '10px', lg: '30px' }}
                      bgColor={bgColor}
                      alignItems="center"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onMouseEnter={() => setShowcaseItemId(product.showcaseCourseId)}
                    >
                      <Box width={isMobile ? '80px' : '135px'}>
                        <MdDragIndicator
                          fontSize="24px"
                          color={products.length <= 1 ? 'transparent' : '#202123'}
                        />
                      </Box>
                      <Box width="150px" padding={0} margin={0}>
                        <Box
                          hidden={!!product.courseThumbnail}
                          width="49px"
                          height="62px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          bgColor="#D9D9D9"
                        >
                          <MdOutlineImageNotSupported color="#757475" fontSize="20px" />
                        </Box>

                        {product.courseThumbnail && (
                          <Image
                            width="49px"
                            height="62px"
                            alt="Banner"
                            src={product.courseThumbnail}
                          />
                        )}
                      </Box>

                      <Stack width="full" alignItems="flex-start" spacing={0} textAlign="start">
                        <Text
                          width={{ base: '150px', lg: 'full' }}
                          isTruncated
                          fontSize="14px"
                          fontWeight={{ base: 'semibold', xl: 'medium' }}
                        >
                          {product.courseName}
                        </Text>
                        <Box hidden={!isMobile}>
                          <StatusSelect
                            showcaseItemId={product.showcaseCourseId}
                            value={product.statusName}
                            onChange={onStatusChange}
                            showcaseType="products"
                          />
                        </Box>
                      </Stack>

                      <Box width="full" hidden={isMobile}>
                        {product.contContent > 1
                          ? `${product.contContent} conteúdos`
                          : `${product.contContent} conteúdo`}
                      </Box>

                      <Box width="400px" hidden={isMobile}>
                        <StatusSelect
                          showcaseItemId={product.showcaseCourseId}
                          value={product.statusName}
                          onChange={onStatusChange}
                          showcaseType="products"
                        />
                      </Box>

                      <Box width={isMobile ? '80px' : '60px'}>
                        <Menu>
                          <MenuButton
                            w="27px"
                            h="24px"
                            borderRadius={5}
                            bgColor="#20212312"
                            _hover={{ bgColor: '#20212330' }}
                            _active={{ bgColor: '#20212330' }}
                          >
                            <Stack justifyContent="center" alignItems="center">
                              <HiDotsHorizontal size="18px" color="#00000080" />
                            </Stack>
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              color="#979CA6"
                              fontWeight={500}
                              onClick={() => onAddBannerClick(product.showcaseCourseId)}
                            >
                              Adicionar banner
                            </MenuItem>
                            <MenuItem
                              color="#979CA6"
                              fontWeight={500}
                              hidden={hiddenRemoveBanner}
                              onClick={() => removeBanner(product.showcaseCourseId)}
                            >
                              Remover banner
                            </MenuItem>
                            <MenuItem
                              color="#979CA6"
                              fontWeight={500}
                              onClick={() =>
                                editModules(
                                  product.courseId,
                                  showcase.showcaseId,
                                  product.showcaseCourseId
                                )
                              }
                            >
                              Editar módulos
                            </MenuItem>

                            <MenuItem
                              color="#BB2124"
                              fontWeight={500}
                              onClick={() => deleteProduct(product.showcaseCourseId, showcase)}
                            >
                              Remover da vitrine
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Box>

                      <Box flexShrink={0}>
                        <GoArrowRight
                          fontSize="24px"
                          color="#20212380"
                          cursor="pointer"
                          onClick={() =>
                            editModules(
                              product.courseId,
                              showcase.showcaseId,
                              product.showcaseCourseId
                            )
                          }
                        />
                      </Box>
                    </Box>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}
