import { Box } from '@chakra-ui/react';
import { useRef } from 'react';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import VideoLibraryProvider from '../../contexts/VideoLibraryContext';
import EditorToolbar, { formats, modules } from './EditorToolbar';
import './styles.css';

interface EditorProps {
  value: string;
  hasVideoLibrary?: boolean;
  defaultValue?: string;
  onChange?: (value: string, length?: number) => void;
}

export default function Editor({ value, hasVideoLibrary, defaultValue, onChange }: EditorProps) {
  function handleChange(value: string, _delta: any, _source: any, editor: UnprivilegedEditor) {
    const length = editor.getLength();

    onChange(value, length - 1);
  }
  const quillRef = useRef(null);

  return (
    <VideoLibraryProvider>
      <Box className="text-editor">
        <EditorToolbar quillRef={quillRef} hasVideoLibrary={hasVideoLibrary} />
        <ReactQuill
          defaultValue={defaultValue}
          theme="snow"
          value={value}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          ref={quillRef}
        />
      </Box>
    </VideoLibraryProvider>
  );
}
