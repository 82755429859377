import Card from '../Card';
import CategoriesSkeleton from '../Skeletons/Categories';
import DefaultImage from '../DefaultImage';
import { memo } from 'react';
import styles from '../styles.module.css';

function Products({ loading, course }) {
  return (
    <Card>
      {loading ? (
        <CategoriesSkeleton />
      ) : (
        <div className={styles.cardProducts}>
          {!course.thumbnail ? (
            <DefaultImage title={course.name.substr(0, 1)} />
          ) : (
            <img src={course.thumbnail} alt="Thumbnail" />
          )}
          <div className={styles.cardContent}>
            <span>{course.name}</span>
          </div>
        </div>
      )}
    </Card>
  );
}

export default memo(Products);
