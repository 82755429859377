import { Button, Flex } from '@chakra-ui/react';

function LogoPreview({ preview, handleDiscardImage }) {
  return (
    <Flex direction="column" grow={1} gap={2}>
      {preview && (
        <Flex
          grow={1}
          align="center"
          justify="center"
          bgColor="#20212320"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="contain"
          backgroundImage={`url("${preview}")`}
          width="full"
          height="full"
          minHeight={{ base: 32, md: 'unset' }}
        />
      )}

      <Button
        onClick={() => handleDiscardImage()}
        variant="outline"
        colorScheme="red"
        size="sm"
        width="full"
        hasChange={true}
      >
        Excluir imagem atual
      </Button>
    </Flex>
  );
}

export default LogoPreview;
