import { Flex, Spinner } from '@chakra-ui/react';

export default function LoadingOverlay({ isLoading }: { isLoading: boolean }) {
  return (
    <Flex
      position="absolute"
      inset={0}
      bg="white"
      opacity={0.8}
      zIndex={10}
      align="center"
      justify="center"
      display={isLoading ? 'flex' : 'none'}
    >
      <Spinner color="orange.500" height="5rem" width="5rem" />
    </Flex>
  );
}
