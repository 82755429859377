import process from 'process/browser';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from '../../components/ContainerSite/ContainerSite';
import './style.css';

function SelectTypeRegister() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container>
        <div className="container-fluid m-auto pt-sm-5 ">
          <div className="row justify-content-center align-items-center mt-5">
            <div className="col-lg-8 pt-sm-5 pt-md-0">
              <h2 className="text-center pb-2">Como deseja atuar na plataforma?</h2>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 mb-3">
              <div className="card shadow-sm ">
                <div className="card-inner">
                  <h3 className="text-center">Aluno</h3>
                  <center>
                    <img src="/images/student.png" alt="" />
                  </center>
                  <div className="d-flex justify-content-center mt-4">
                    <Link to="/student-signup" className="btn btn-primary btn-lg">
                      Continuar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 mb-3">
              <div className="card shadow-sm ">
                <div className="card-inner">
                  <h3 className="text-center">Instrutor</h3>
                  <center>
                    <img src="/images/teacher.png" alt="" />
                  </center>
                  <div className="d-flex justify-content-center mt-4">
                    {process?.env.NODE_ENV === 'development' ? (
                      <Link
                        to="/instructor-signup?plan=Iniciante&recurrence=mês"
                        className="btn btn-primary btn-lg"
                      >
                        Continuar
                      </Link>
                    ) : (
                      <a
                        rel="noopener noreferrer"
                        role="button"
                        title="Continuar"
                        className="btn btn-primary btn-lg"
                        href="https://ensine.proluno.com.br/subscribe.html"
                      >
                        Continuar
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default SelectTypeRegister;
