import { Box, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { HiDotsVertical } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { LARGE_SCREEN_SIZE } from '../../constants';
import { CurrentUserType } from '../../Subscribers';
interface IDropdownProps {
  id: number;
  subscriptionId: number;
  userFullName: string;
  subscriptionStatus: string;
  onOpen: () => boolean;
  user: CurrentUserType;
  setUser: (user: CurrentUserType) => CurrentUserType;
}
function Dropdown({
  id,
  subscriptionId,
  userFullName,
  subscriptionStatus,
  onOpen: onOpenModalConfirmation,
  user,
  setUser,
}: IDropdownProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { width } = useWindowSize();

  return (
    <Menu isOpen={isOpen}>
      <MenuButton
        fontWeight="normal"
        onClick={onOpen}
        onMouseLeave={onClose}
        style={{ outline: 'none', boxShadow: 'none' }}
      >
        <Box transform={width >= LARGE_SCREEN_SIZE && `rotate(90deg)`}>
          <HiDotsVertical size={18} color="rgba(32, 33, 35, 0.5)" />
        </Box>
      </MenuButton>
      <MenuList
        minWidth="40"
        background="white"
        borderRadius="md"
        boxShadow="none"
        color="#979CA6"
        filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        <Link
          style={{ margin: 0 }}
          to={`/signatures/${id}/payment-history/${subscriptionId}/subscription`}
        >
          <MenuItem style={{ outline: 'none', boxShadow: 'none' }}>
            Histórico de Pagamentos
          </MenuItem>
        </Link>
        <MenuItem
          isDisabled={subscriptionStatus === 'canceled'}
          onClick={() => {
            setUser({ ...user, subscriptionId: subscriptionId, name: userFullName });
            onOpenModalConfirmation();
          }}
          style={{ outline: 'none', boxShadow: 'none' }}
        >
          {subscriptionStatus === 'canceled' ? 'Cancelada' : 'Cancelar assinatura'}
        </MenuItem>

        {/* <Box width="90%" marginY="0" marginX="auto" border="0.5px solid rgba(32, 33, 35, 0.05)" />
        <MenuItem onClick={onModal}>Suspender assinatura</MenuItem> */}
      </MenuList>
    </Menu>
  );
}

export default Dropdown;
