import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Flex,
  Heading,
  HStack,
  Skeleton,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import useShoppingCart, {
  IPayment,
  IProductCart,
  IShoppingCart,
} from '../../../hooks/useShoppingCart';
import Product from './Product';

interface PurchaseSummaryProps extends CardProps {
  products: IProductCart[];
  isLoading: boolean;
  isPurchaseByPixConfirmed?: boolean;
  payments: IPayment[];
  paymentMethod: string;
  currentInstallment: number;
  hasUserSelectedInstallment: boolean;
  onChangeShoppingCart: () => Promise<IShoppingCart>;
  onPoleChange: (productId: number, poleId: number) => void;
  onIsFreePurchaseChange: (value: boolean) => void;
  onStandardPaymentValueChange: (value: number) => void;
}

export default function PurchaseSummary({
  products,
  isLoading,
  isPurchaseByPixConfirmed,
  payments,
  paymentMethod,
  currentInstallment,
  hasUserSelectedInstallment,
  onChangeShoppingCart,
  onPoleChange,
  onIsFreePurchaseChange,
  onStandardPaymentValueChange,
  ...rest
}: PurchaseSummaryProps) {
  const { deleteProductFromLocalStorage, getShoppingCartFromLocalStorage } = useShoppingCart();
  const { onUpdateTotalItemsInShoppingCart } = useAuth();
  const history = useHistory();

  async function deleteProduct(productId: number) {
    deleteProductFromLocalStorage(productId);

    const hasProductsInShoppingCart = !!getShoppingCartFromLocalStorage();

    if (!hasProductsInShoppingCart) {
      onUpdateTotalItemsInShoppingCart(0);
      return history.push('/all-products');
    }

    await onChangeShoppingCart();
  }

  const subtotal = useMemo(
    () =>
      products?.reduce(
        (total, { productPrice, couponDiscount }) => total + productPrice + couponDiscount,
        0
      ),
    [products]
  );

  const total = useMemo(
    () => products?.reduce((acc, product) => acc + product.productPrice, 0),
    [products]
  );

  const discount = useMemo(() => {
    return convertToBrazilianCurrency(
      products?.reduce((acc, product) => acc + product.couponDiscount, 0)
    );
  }, [products]);

  useEffect(() => {
    onIsFreePurchaseChange(!!total ? false : true);
    onStandardPaymentValueChange(subtotal);
  }, [onIsFreePurchaseChange, onStandardPaymentValueChange, subtotal, total]);

  const maxInstallment = useMemo(() => {
    return payments?.length ? payments[payments.length - 1] : null;
  }, [payments]);

  return (
    <Card
      w="full"
      h="full"
      maxWidth="26rem"
      pb={4}
      boxShadow="0px 4px 4px 0px #00000040"
      border="1px solid #2021230D"
      color="#202123"
      bgColor="#FFF"
      {...rest}
    >
      <CardHeader textAlign="center">
        <Heading size="md" fontWeight="semibold">
          Resumo da compra
        </Heading>
      </CardHeader>

      <CardBody py={4} pb={3} px={{ base: 4, xl: 5 }}>
        <VStack spacing={3} divider={<StackDivider borderColor="gray.300" />}>
          {products?.map(product => (
            <Product
              key={product.productId}
              product={product}
              isLoading={isLoading}
              onDelete={deleteProduct}
              onPoleChange={onPoleChange}
              isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
            />
          ))}
        </VStack>
      </CardBody>

      <CardFooter px={{ base: 4, xl: 5 }}>
        <Flex direction="column" w="full" sx={{ strong: { fontWeight: 'semibold' } }}>
          <Skeleton w="full" isLoaded={!isLoading} maxH={5}>
            <HStack w="full" justify="space-between">
              <Text as="span">Subtotal:</Text>
              <Text as="strong">{convertToBrazilianCurrency(subtotal)}</Text>
            </HStack>
          </Skeleton>

          <Skeleton w="full" isLoaded={!isLoading} maxH={5} mt={{ base: '0.422rem', xl: 3 }}>
            <HStack w="full" justify="space-between">
              <Text as="span">Cupom de desconto:</Text>
              <Text as="strong">{discount}</Text>
            </HStack>
          </Skeleton>

          <Skeleton
            w="full"
            isLoaded={!isLoading}
            startColor="default.400"
            endColor="default.400"
            maxH={8}
            mt={7}
          >
            <HStack
              w="full"
              justify="space-between"
              fontSize={{ base: 'md', xl: 'xl' }}
              color="#EB7129"
            >
              <Text as="span" fontWeight="medium">
                Total:
              </Text>

              {paymentMethod === 'credit_card' &&
                (!hasUserSelectedInstallment ? (
                  <Text as="strong">
                    {maxInstallment?.installment}X de{' '}
                    {convertToBrazilianCurrency(maxInstallment?.installmentPrice)}
                  </Text>
                ) : (
                  <Text as="strong">
                    {currentInstallment}X de{' '}
                    {convertToBrazilianCurrency(
                      payments?.find(({ installment }) => installment === currentInstallment)
                        ?.installmentPrice
                    )}
                  </Text>
                ))}

              {paymentMethod !== 'credit_card' && (
                <Text as="strong">{convertToBrazilianCurrency(total)}</Text>
              )}
            </HStack>
          </Skeleton>
        </Flex>
      </CardFooter>
    </Card>
  );
}
