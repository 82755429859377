import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useState } from 'react';
import BootStrapModal from './components/Modal';
import ProductsList from './ProductsList';
import RecurrenceList from './RecurrenceList';
import SubscriptionList from './SubscriptionList';
import { useHistory } from 'react-router-dom';

function Payments() {
  const [isShowModal, setIsShowModal] = useState(false);
  const [pixCode, setPixCode] = useState('');
  const history = useHistory();

  function toggleModal(pixQrCode: string) {
    if (pixQrCode) setPixCode(pixQrCode);

    setIsShowModal(prevState => !prevState);
  }

  function handleTabsChange() {
    return history.push(`/members-area/v1/payments`);
  }

  return (
    <>
      <Tabs colorScheme="primary" onChange={handleTabsChange} isLazy>
        <TabList>
          <Tab>Produtos Online</Tab>
          <Tab>Recorrência</Tab>
          <Tab>Assinaturas</Tab>
        </TabList>

        <TabIndicator mt="-1.9px" height="3px" bg="primary.500" />

        <TabPanels>
          <TabPanel>
            <ProductsList toggleModal={toggleModal} />
          </TabPanel>
          <TabPanel>
            <RecurrenceList />
          </TabPanel>

          <TabPanel>
            <SubscriptionList toggleModal={toggleModal} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <BootStrapModal isShow={isShowModal} pixCode={pixCode} onHide={toggleModal} />
    </>
  );
}

export default Payments;
