import { Box, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Platform from '../../../../api/Platform';
import LoadingPage from '../../../../assets/images/backgrounds/loading-page.png';
import logoImage from '../../../../assets/images/logos/proluno_branca.png';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import AnimatedBoxesLoading from './AnimatedBoxesLoading';

function getBgGradient(degree: number) {
  return `linear-gradient(${degree}deg, #202123 49.48%, rgba(34, 35, 37, 0.988666) 57.29%, rgba(53, 54, 56, 0.883959) 66.15%, rgba(77, 78, 80, 0.754755) 76.56%, rgba(217, 217, 217, 0) 100%), url(${LoadingPage})`;
}

const bgGradient = {
  base: getBgGradient(180),
  lg: getBgGradient(90),
};

export function LoadingIntegrationForm() {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const path = searchParams.get('path');

  async function checkDomainAvailability(domain: string, primary_url: string) {
    try {
      const { data: checkDomain } = await Platform.checkDomain(domain, primary_url);

      if (checkDomain) {
        return true;
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  useEffect(() => {
    let url = path;

    async function updateFirstAccess() {
      try {
        await Platform.firstAccessUpdate();

        const { data: platformData } = await Platform.getCurrentPlatform();

        if (platformData.alternateUrl) {
          let intervalId: any;

          const check = async () => {
            const domainChecked = await checkDomainAvailability(platformData.alternateUrl, 'true');

            if (domainChecked) {
              if (intervalId) {
                clearInterval(intervalId);
              }

              setTimeout(() => {
                window.location.href = `https://${platformData.alternateUrl}/login?token=${platformData.token}&path=${path}`;
              }, 4000);
            }
          };

          intervalId = setInterval(check, 1500);
        } else {
          const timer = setTimeout(() => {
            window.location.href = url;
          }, 5000);

          return () => clearTimeout(timer);
        }
      } catch (error) {
        return Toast(ErrorResponse(error), 'error');
      }
    }

    updateFirstAccess();
  }, [path]);

  return (
    <Flex
      minH="100vh"
      backgroundRepeat="no-repeat"
      bgSize="contain"
      bgGradient={bgGradient}
      backgroundPosition={{ base: 'bottom', lg: 'right' }}
    >
      <Flex grow={1} pt={{ base: 10, lg: 10 }} pb={{ base: 20, lg: 20 }} px={{ base: 16, lg: 24 }}>
        <Flex
          grow={1}
          direction="column"
          justify={{ base: 'flex-start', lg: 'space-between' }}
          align={{ base: 'center', lg: 'flex-start' }}
        >
          <Flex justify={{ base: 'center', lg: 'flex-start' }}>
            <Image src={logoImage} w={172} h={90} />
          </Flex>

          <VStack align={{ base: 'center', lg: 'flex-start' }} mt={{ base: 16, lg: 0 }} maxW={96}>
            <Box mb={16} textAlign={{ base: 'center', lg: 'left' }}>
              <Heading color="#FFF">Aguarde</Heading>
              <Text mt={4} color="#FFF" fontSize={{ base: 'md', md: '2xl' }} maxW={96}>
                Estamos criando um ambiente incrível para você criar e vender seus produtos online.
              </Text>
            </Box>

            <AnimatedBoxesLoading />
          </VStack>
        </Flex>
      </Flex>
    </Flex>
  );
}
