import {
  Box,
  CardProps,
  Flex,
  HStack,
  Skeleton,
  SkeletonText,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { IRecurrencePlanResponse } from '..';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import { recurrenceText } from '../../../helpers/recurrenceText';

interface PurchaseSummaryProps extends CardProps {
  plan: IRecurrencePlanResponse;
  isLoading: boolean;
}

export default function PurchaseSummaryMobile({ plan, isLoading, ...rest }: PurchaseSummaryProps) {
  return (
    <Box width="full" minH="153px" height="auto" padding="10px 15px" bgColor="#F4F4F4" {...rest}>
      <Stack spacing="32px">
        <VStack spacing={3} divider={<StackDivider borderColor="gray.300" />}>
          <SkeletonText noOfLines={2} isLoaded={true} w="full" skeletonHeight={4}>
            <VStack spacing={0} justify="start" align="start">
              <Stack
                width="full"
                height="auto"
                paddingY="10px"
                borderBottom="1px solid rgba(0, 0, 0, 0.1)"
              >
                <Text
                  color="#202123"
                  fontSize="20px"
                  width="full"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  {plan?.name}
                </Text>
              </Stack>
            </VStack>
          </SkeletonText>
        </VStack>

        <Flex direction="column" w="full" gap="12px" sx={{ strong: { fontWeight: 'semibold' } }}>
          <Skeleton
            w="full"
            isLoaded={!false}
            startColor="default.400"
            endColor="default.400"
            maxH={8}
          >
            <HStack
              w="full"
              justify="space-between"
              fontSize={{ base: '20px', xl: 'xl' }}
              color="#EB7129"
            >
              <Text as="strong">
                {convertToBrazilianCurrency(plan?.value)}
                <Text as="span" fontWeight="400">
                  /{recurrenceText(plan?.recurrence)}
                </Text>
              </Text>
            </HStack>
          </Skeleton>
        </Flex>
      </Stack>
    </Box>
  );
}
