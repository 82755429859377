import { Box, Image, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import FacebookIntegrationAPI from '../../../../api/FacebookIntegration';

import Button from '../components/Button';
import Header from '../components/Header';

import Card from '../components/Card';

import noFacebookPixels from '../../../../assets/images/integrations/noData/facebook-pixel.svg';

import { useHistory } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import useQuery from '../../../../hooks/useQuery';
import { PixelType } from '../types';
import Skeleton from './Skeleton';

function Facebook() {
  const [pixels, setPixels] = useState<PixelType[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const query = useQuery();

  const [currentPage, setCurrentPage] = useState(parseInt(query.get('page')) || 1);

  const [pageCount, setPageCount] = useState(0);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { data: pixelsData } = await FacebookIntegrationAPI.index({
          page: currentPage,
          per_page: 10,
        });

        if (pixelsData) {
          setPageCount(pixelsData.total / 10);
          setPixels(pixelsData?.tasks);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [currentPage]);

  function deletePixel(id: number) {
    const newArrayPixels = pixels.filter(pixel => pixel.id !== id);

    setPixels(newArrayPixels);
  }

  useEffect(() => {
    let page: string | number = query.get('page');

    if (page) {
      try {
        page = parseInt(page);

        if (page < 1) {
          return history.push('/integrations/facebook');
        }

        setCurrentPage(page);
      } catch (error) {
        history.push('/integrations/facebook');
      }
    }
  }, [history, query, currentPage]);

  function handlePageChange({ selected: selectedPage }) {
    history.push(`/integrations/facebook?page=${selectedPage + 1}`);
  }

  return (
    <Stack display="flex" flexDirection="column">
      <Box
        display="grid"
        gridTemplateAreas={{
          base: `
            'header'
            'pixels'
            'button'
          `,
          lg: `
            'header button'
            'pixels pixels'
          `,
        }}
        gap={4}
      >
        <Box gridArea="header">
          <Header
            title="Facebook pixel"
            tutorialLink="https://www.youtube.com/"
            description={
              'Adicione o Pixel do Facebook aos seus produtos para realizar o rastreamento dos seus clientes.'
            }
          />
        </Box>
        <Box
          marginTop={{ base: 2, lg: 0 }}
          gridArea="button"
          display="flex"
          justifyContent="flex-end"
        >
          <Button title="Adicionar pixel" />
        </Box>

        <Box gridArea="pixels">
          {isLoading && <Skeleton />}

          {pixels?.length === 0 && !isLoading && (
            <Box
              marginTop={{ base: 0, lg: 28 }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Image src={noFacebookPixels} alt="Nenhum pixel" />
            </Box>
          )}

          <Box
            marginTop={{ base: 0, lg: 4 }}
            width="100%"
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
            gap="4"
          >
            {pixels?.length !== 0 &&
              !isLoading &&
              pixels?.map(pixel => {
                return <Card key={pixel.id} pixel={pixel} deletePixel={deletePixel} />;
              })}
          </Box>
        </Box>
      </Box>
      <Box py="5">
        <Pagination pageCount={pageCount} onPageActive={currentPage -1} onPageChange={handlePageChange} />
      </Box>
    </Stack>
  );
}

export default Facebook;
