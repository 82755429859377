import { Link, useLocation } from 'react-router-dom';

import { List, ListItem, Text, VStack } from '@chakra-ui/react';

interface NavMenuProps {
  onClose: () => void;
}

export default function NavMenu({ onClose }: NavMenuProps) {
  const location = useLocation();

  function handleClick() {
    onClose();
  }

  const menu = [
    {
      label: 'Produtos',
      path: `/members-area/v2`,
    },
    {
      label: 'Pagamentos',
      path: `/members-area/v2/payments`,
    },
    {
      label: 'Questões',
      path: `/members-area/v2/questions`,
    },
    {
      label: 'Perfil',
      path: `/members-area/v2/profile/user`,
    },
  ];

  return (
    <VStack as="nav" spacing={5} w="full" align="start">
      <List spacing={2.5} w="full">
        {menu.map(menuItem => {
          return (
            <ListItem key={menuItem.path}>
              <Text
                onClick={handleClick}
                as={Link}
                to={menuItem.path}
                textDecoration="none"
                fontWeight={location.pathname === menuItem.path ? 'semibold' : 'normal'}
                color="#ffffff"
              >
                {menuItem.label}
              </Text>
            </ListItem>
          );
        })}
      </List>
    </VStack>
  );
}
