import { Input } from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { InputProps } from 'react-select';
import { FocusEvent } from 'react';

const isAmexCard = /^3[47]/;
const isDinersClub = /^3[068]/;

const cardMask = {
  amex: '9999 999999 99999',
  dinersClub: '9999 999999 9999',
  other: '9999 9999 9999 9999',
};

export default function CreditCardInput(props: Partial<InputProps>) {
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  function getCardType(value: any) {
    if (isAmexCard.test(value)) {
      return 'amex';
    }

    if (isDinersClub.test(value)) {
      return 'dinersClub';
    }

    return 'other';
  }

  return (
    <InputMask
      onFocus={handleFocus}
      mask={cardMask[getCardType(props.value)] || '9999 9999 9999 9999'}
      maskChar=""
      {...props}
    >
      {inputProps => <Input {...inputProps} />}
    </InputMask>
  );
}
