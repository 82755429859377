// import { Link } from 'react-router-dom';

import styles from './styles.module.css';

function Hero() {
  return (
    <header className={styles.container}>
      <div className={styles.content}>
        <h2>
          Insights para ajudar você a <b className={styles.fw700}>crescer o seu negócio</b>
        </h2>
        <span>
          Seja bem-vindo ao blog da Proluno. Aprenda sobre como criar produtos online, divulgar e
          monetizar seu conhecimento.
        </span>

        {/* <Link to="" className="btn btn-primary">
          Inscreva-se
        </Link> */}
      </div>
    </header>
  );
}

export default Hero;
