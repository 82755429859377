const LESS_THEN = -1;
const GREATER_THEN = 1;
const EQUAL = 0;

export function sortByOrder(orderA, orderB) {
  return orderA.order < orderB.order
    ? LESS_THEN
    : orderA.order > orderB.order
    ? GREATER_THEN
    : EQUAL;
}
