import axios from 'axios';
import base64 from 'base-64';
import process from 'process/browser';
import {
  EMAIL_LOCAL_STORAGE,
  NAME_LOCAL_STORAGE,
  ROLE_LOCAL_STORAGE,
  TOKEN_LOCAL_STORAGE,
} from '../helpers/LocalStorageHelper';
import LogRequest from '../helpers/LogRequest';

const ONE_MINUTE = 60 * 1000;

const REGEX_IDENTIFY_PURCHASE_ROUTE = /^\/v2\/purchases$/g;

const isDevelopment = process?.env?.NODE_ENV === 'development';

const TOKEN_PAYLOAD = 1;

function detectBrowser() {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  } else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
    return 'IE'; //crap
  }

  return 'Unknown';
}

export function detectDevice() {
  const userAgent = navigator.userAgent;
  const regex = /\(([^)]+)\)/; // Pega o conteúdo entre parênteses
  const match = regex.exec(userAgent);

  if (match && match.length > 1) {
    const content = match[1];
    const result = content.replace(/;/g, ' -');

    return result;
  }

  return 'Other';
}

const axiosLog = axios.create({
  baseURL: process?.env.REACT_APP_BASE_URL_API,
});

const baseURLBackend = process?.env.REACT_APP_BASE_URL_API;

export const routesWithoutValidation = [
  '/',
  'login',
  'product',
  'all-products',
  'blog',
  'about',
  'not-found',
  'forget-password',
  'reset-password',
  'instructor-signup',
  'all-instructors',
  'signup',
  'sign-up',
  'student-signup',
  'cadastre-se',
  'product-categories',
  'plans',
  'custom-instructor-signup',
  'privacy-policy',
  'term-of-use',
  'student-checkout',
  'launch-checkout',
  'recurrence-checkout',
  'internal-profile',
  'trial-period-registration',
  'trial-period-loading',
  'instructor-loading',
  'certificate',
  'question',
  'termos-e-politicas',
  'launch-checkout-thanks',
  'recurrence-checkout-thanks',
  'recurrence-plan',
];

export const request = config => {
  const newConfig = { ...config };
  newConfig.metadata = { startTime: new Date().getTime() };
  return newConfig;
};

export const requestError = error => Promise.reject(error);

export const success = response => {
  const newResponse = { ...response };

  if (newResponse.config?.metadata) {
    newResponse.config.metadata = { ...newResponse.config?.metadata };
  } else {
    newResponse.config = { metadata: {} };
  }

  newResponse.config.metadata.endTime = new Date().getTime();
  newResponse.config.metadata.duration =
    newResponse.config.metadata.endTime - newResponse.config.metadata.startTime;

  let configData = newResponse.config?.data;

  if (configData && REGEX_IDENTIFY_PURCHASE_ROUTE.test(newResponse.config.url)) {
    const configDataParsed = JSON.parse(configData);

    configDataParsed.creditCard = 'ocultado';
    configDataParsed.document = 'ocultado';

    configData = JSON.stringify(configDataParsed);
  }

  const log = {
    hostname: window.location.hostname,
    pathname: window.location.pathname,
    href: window.location.href,
    method: newResponse.config.method,
    statusCode: newResponse.status,
    statusText: newResponse.statusText,
    configData: configData && JSON.stringify(configData),
    message: newResponse?.message || newResponse?.data?.message,
    url: newResponse.config.url,
    startTime: newResponse.config.metadata.startTime,
    endTime: newResponse.config.metadata.endTime,
    duration: newResponse.config.metadata.duration,
    timeout: newResponse.request.timeout,
    data: newResponse?.data && JSON.stringify(newResponse.data),
    headers: JSON.stringify(newResponse.config.headers),
    browser: detectBrowser(),
    appVersion: navigator.appVersion,
  };

  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

  if (token) {
    const payloadString = token.split('.')[TOKEN_PAYLOAD];

    const payload = JSON.parse(base64.decode(payloadString));

    log.userId = payload.id;
    log.fullName = decodeURIComponent(payload.fullName);
    log.role = payload.role;

    axiosLog.defaults.headers['Authorization'] = `bearer ${token}`;
  }

  if (configData instanceof FormData) {
    const data = {};
    for (let [key, value] of configData.entries()) {
      data[key] = value;
    }

    log.configData = JSON.stringify(data);
  } else {
    log.configData = JSON.stringify(configData);
  }

  if (log.method !== 'get' && log.url !== '/validateToken') {
    const identifier = JSON.stringify({
      startTime: log.startTime,
      method: log.method,
      statusCode: log.statusCode,
      configData,
      pathname: log.pathname,
      url: log.url,
      appVersion: log.appVersion,
      userId: log.userId,
    });

    log.identifier = identifier;

    const validLog = LogRequest.instance.send(log);

    if (validLog && !isDevelopment) {
      axiosLog
        .post('/logs', validLog)
        .then(() => {
          LogRequest.instance.remove(identifier);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    } /* else {
      // eslint-disable-next-line no-console
      console.log({ SuccessLog: log });
    }*/
  }

  return newResponse;
};

export const error = err => {
  const newErrorResponse = { ...err };

  if (newErrorResponse.config?.metadata) {
    newErrorResponse.config.metadata = { ...newErrorResponse.config?.metadata };
  } else {
    newErrorResponse.config = { metadata: {} };
  }

  newErrorResponse.config.metadata.endTime = new Date().getTime();
  newErrorResponse.config.metadata.duration =
    newErrorResponse.config.metadata.endTime - newErrorResponse.config.metadata.startTime;

  const errorKeys = Object.keys(err);

  if (
    errorKeys.length &&
    Object.keys(err).find(key => key === 'toJSON') &&
    err.message !== 'Network Error'
  ) {
    const errorJSON = err.toJSON();

    let configData = errorJSON.config?.data;

    if (configData && REGEX_IDENTIFY_PURCHASE_ROUTE.test(errorJSON.config.url)) {
      const configDataParsed = JSON.parse(configData);

      configDataParsed.creditCard = 'ocultado';
      configDataParsed.document = 'ocultado';

      configData = JSON.stringify(configDataParsed);
    }

    const responseData = errorJSON?.response?.data;
    const headers = errorJSON.config.headers;

    const log = {
      hostname: window.location.hostname,
      pathname: window.location.pathname,
      href: window.location.href,
      method: errorJSON.config.method,
      statusCode: err.request.status,
      statusText: err.request.statusText,
      message: err?.response?.data?.message || err?.message,
      url: errorJSON.config.url,
      startTime: errorJSON.config.metadata.startTime,
      endTime: errorJSON.config.metadata.endTime,
      duration: errorJSON.config.metadata.duration,
      timeout: err.request.timeout,
      stack: errorJSON?.stack,
      responseData: responseData && JSON.stringify(errorJSON.response.data),
      headers: headers && JSON.stringify(errorJSON.config.headers),
      browser: detectBrowser(),
      appVersion: navigator.appVersion,
    };

    if (configData instanceof FormData) {
      const data = {};

      for (let [key, value] of configData.entries()) {
        data[key] = value;
      }

      log.configData = JSON.stringify(data);
    } else {
      log.configData = JSON.stringify(configData);
    }

    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

    if (token) {
      const payloadString = token.split('.')[TOKEN_PAYLOAD];

      const payload = JSON.parse(base64.decode(payloadString));

      log.userId = payload.id;
      log.fullName = decodeURIComponent(payload.fullName);
      log.role = payload.role;
    }

    const identifier = JSON.stringify({
      startTime: log.startTime,
      method: log.method,
      statusCode: log.statusCode,
      configData,
      pathname: log.pathname,
      url: log.url,
      appVersion: log.appVersion,
      userId: log.userId,
    });

    log.identifier = identifier;

    const validLog = LogRequest.instance.send(log);

    if (validLog && !isDevelopment) {
      axiosLog
        .post('/logs', validLog)
        .then(() => {
          LogRequest.instance.remove(identifier);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    } /* else {
      // eslint-disable-next-line no-console
      console.log({ ErrorLog: log });
    }*/
  }

  let basePath =
    window.location.pathname
      .split('/')
      .filter(path => path)
      .shift() || '/';

  const matchRoute = routesWithoutValidation.includes(basePath);

  if (err?.response?.status === 401) {
    localStorage.removeItem(TOKEN_LOCAL_STORAGE);
    localStorage.removeItem(ROLE_LOCAL_STORAGE);
    localStorage.removeItem(NAME_LOCAL_STORAGE);
    localStorage.removeItem(EMAIL_LOCAL_STORAGE);
    localStorage.removeItem(TOKEN_LOCAL_STORAGE);

    if (err.response.data.message === 'Esse usuário já está logado em outro dispositivo.') {
      return (window.location.href = '/login?multiple_sessions=true');
    }

    if (!matchRoute) {
      return (window.location.href = '/login?session_expire=true');
    }
  }

  if (err?.response?.status === 403 && err?.response?.data?.message && !matchRoute) {
    const redirect = err.response?.data?.data?.redirect;

    if (redirect) return (window.location = redirect);

    const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

    return role === 'ALUNO'
      ? (window.location = '/login')
      : (window.location = '/user-without-access');
  }

  if (err?.response?.config?.url.match(/marketplace/g)) {
    return Promise.resolve(err);
  }

  return Promise.reject(err);
};

function baseURL() {
  return process?.env.REACT_APP_BASE_URL_API;
}

export const axiosBackend = (timeout = ONE_MINUTE) => {
  const BASE_URL_API = process?.env.REACT_APP_BASE_URL_API;

  const axiosBackendInstance = axios.create({
    baseURL: BASE_URL_API,
    timeout,
  });

  axiosBackendInstance.interceptors.request.use(request, requestError);
  axiosBackendInstance.interceptors.response.use(success, error);

  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

  if (!token) {
    return axiosBackendInstance;
  }

  axiosBackendInstance.defaults.baseURL = BASE_URL_API;
  axiosBackendInstance.defaults.headers['Authorization'] = `bearer ${token}`;
  axiosBackendInstance.interceptors.request.use(request, requestError);
  axiosBackendInstance.interceptors.response.use(success, error);

  return axiosBackendInstance;
};

export { baseURLBackend, baseURL };
