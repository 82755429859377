import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Radio,
  RadioGroup,
  Select,
  Stack,
  VStack,
  Text,
} from '@chakra-ui/react';
import CopyButton from './CopyButton';
import { PlanManagementEditDescriptionProps } from './types';
import usePlanManagementEditDescription from './usePlanManagementEditDescription';
import CustomDropzone from '../../../../../../components/CustomDropzone';
import ImageUploadLoading from './ImageUploadLoading';
import ImagePreview from './ImagePreview';
import DeleteImageModal from './DeleteImageModal';
import Editor from '../../../../../../components/Editor';
import { TEXT_EDITOR_STYLES } from './constants';
import FAQ from './FAQ';
import Testimonials from './Testimonials';

export default function PlanManagementEditDescription({
  planData,
}: PlanManagementEditDescriptionProps) {
  const {
    form,
    isSubmitting,
    formValidation,
    checkoutLink,
    internalSalesPageLink,
    thumbnail,
    mainBanner,
    isUploading,
    isDeletingImage,
    isOpen,
    onClose,
    handleDeleteImage,
    handleOpenImageDeleteModal,
    handleSelectNewImage,
    handleDropFile,
    handleDropRejected,
    handleChange,
    handleRadioChange,
    handleCancelButtonClick,
    handleDescriptionChange,
    onSubmit,
  } = usePlanManagementEditDescription({
    planData,
  });

  return (
    <>
      <VStack
        as="form"
        onSubmit={onSubmit}
        noValidate
        id="planManagementEditDescriptionForm"
        spacing={4}
        mt={10}
        sx={{
          '.chakra-checkbox__label, .chakra-form__label': { fontSize: 'sm', color: '#202123' },
          '.chakra-radio__control': { mt: '1.5px' },
          input: {
            _placeholder: { color: '#0000005C', fontsize: 'md' },
          },
        }}
      >
        <FormControl isInvalid={formValidation?.willUsePlanPage?.isInvalid}>
          <FormLabel>Você utilizará uma página de plano?</FormLabel>

          <RadioGroup
            value={form.willUsePlanPage ? 'true' : 'false'}
            onChange={value =>
              handleRadioChange('willUsePlanPage', value === 'true' ? true : false)
            }
          >
            <VStack
              sx={{
                color: '#202123',
                fontSize: 'sm',
                fontWeight: 'medium',
                span: { fontSize: 'sm' },
              }}
            >
              <Radio value="false" width="100%" alignItems="start" colorScheme="primary">
                Não, quero que o usuário siga direto para o checkout.
              </Radio>

              <Radio value="true" width="100%" alignItems="start" colorScheme="primary">
                Sim
              </Radio>
            </VStack>
          </RadioGroup>

          <FormErrorMessage>{formValidation?.willUsePlanPage?.message}</FormErrorMessage>
        </FormControl>

        {form.willUsePlanPage && (
          <>
            <FormControl>
              <FormLabel>Escolha o formato que irá usar:</FormLabel>

              <Select
                name="pageType"
                value={form.pageType}
                onChange={handleChange}
                focusBorderColor="gray.200"
                borderRadius="md"
                color="#2D374880"
              >
                <option value="" hidden>
                  Selecione
                </option>
                <option value="INTERNAL">Página interna</option>
                <option value="EXTERNAL">Página externa</option>
              </Select>
            </FormControl>

            {form.pageType === 'EXTERNAL' && (
              <FormControl isInvalid={formValidation?.linkToYourPlan?.isInvalid}>
                <FormLabel>Link da sua página</FormLabel>

                <Input
                  name="linkToYourPlan"
                  value={form.linkToYourPlan}
                  onChange={handleChange}
                  placeholder="Cole aqui"
                  focusBorderColor="gray.200"
                />

                <FormErrorMessage>{formValidation?.linkToYourPlan?.message}</FormErrorMessage>
              </FormControl>
            )}
          </>
        )}

        <FormControl>
          <FormLabel>Link do checkout</FormLabel>

          <InputGroup borderRadius="md">
            <Input
              value={checkoutLink}
              color="#959595 !important"
              isTruncated
              isDisabled
              _disabled={{ color: '#959595', bg: '#e9e9e9' }}
              _focus={{ borderColor: 'none' }}
              _hover={{ borderColor: 'none' }}
            />

            <InputRightAddon
              bg="#e9e9e9"
              children={<CopyButton textToCopy={checkoutLink} />}
              p={0}
              ml={0.5}
            />
          </InputGroup>
        </FormControl>

        {form.willUsePlanPage && (
          <>
            {/* FORMULÁRIO DE PÁGINA INTERNA */}
            {form.pageType === 'INTERNAL' && (
              <>
                <FormControl>
                  <FormLabel>Link da página de vendas</FormLabel>

                  <InputGroup borderRadius="md">
                    <Input
                      value={internalSalesPageLink}
                      color="#959595 !important"
                      isTruncated
                      isDisabled
                      _disabled={{ color: '#959595', bg: '#e9e9e9' }}
                      _focus={{ borderColor: 'none' }}
                      _hover={{ borderColor: 'none' }}
                    />

                    <InputRightAddon
                      bg="#e9e9e9"
                      children={<CopyButton textToCopy={internalSalesPageLink} />}
                      p={0}
                      ml={0.5}
                    />
                  </InputGroup>
                </FormControl>

                <Box as="section" w="full">
                  <Heading fontSize="xl" fontWeight="medium" color="#202123">
                    Thumbnail
                  </Heading>

                  <Text className="chakra-form__helper-text" color="#20212380">
                    Imagem que ficará visível no destaque do plano.
                  </Text>

                  <Stack direction={{ base: 'column', xl: 'row' }} spacing={4} mt={2}>
                    <FormControl isInvalid={formValidation?.thumbnail?.isInvalid}>
                      <Flex position="relative" height={{ base: '23.5625rem', xl: '16.75rem' }}>
                        {!form.thumbnail ? (
                          <>
                            <CustomDropzone
                              file={thumbnail?.file}
                              isDisabled={isSubmitting}
                              accept={['image/jpg', 'image/png', 'image/jpeg', 'image/webp']}
                              dimensions="1080x1080"
                              onDrop={acceptedFiles => handleDropFile('thumbnail', acceptedFiles)}
                              onDropRejected={handleDropRejected}
                              extensions="JPG, PNG, JPEG e WEBP"
                              maxFileSize="3 MB"
                            />

                            <ImageUploadLoading position="absolute" isLoading={isUploading} />
                          </>
                        ) : (
                          <VStack w="full">
                            <Box height={{ base: '21.5625rem', xl: '16.75rem' }} w="full">
                              <ImagePreview
                                imageField="thumbnail"
                                preview={thumbnail?.preview ?? form.thumbnail}
                                onSelectNewImage={handleSelectNewImage}
                                onOpenDeleteImageModal={handleOpenImageDeleteModal}
                              />
                            </Box>

                            <Button
                              display={{ base: 'flex', xl: 'none' }}
                              onClick={() => handleSelectNewImage('thumbnail')}
                              w="full"
                              variant="outline"
                              colorScheme="primary"
                              size="sm"
                              zIndex="2"
                            >
                              Trocar Imagem
                            </Button>
                          </VStack>
                        )}
                      </Flex>

                      <FormErrorMessage>{formValidation?.thumbnail?.message}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Box>

                <Box as="section" w="full">
                  <Heading fontSize="xl" fontWeight="medium" color="#202123">
                    Banner principal
                  </Heading>

                  <Text className="chakra-form__helper-text" color="#20212380">
                    Imagem principal da página de vendas do plano
                  </Text>

                  <Stack direction={{ base: 'column', xl: 'row' }} spacing={4} mt={2}>
                    <FormControl isInvalid={formValidation?.mainBanner?.isInvalid}>
                      <Flex position="relative" height={{ base: '23.5625rem', xl: '16.75rem' }}>
                        {!form.mainBanner ? (
                          <>
                            <CustomDropzone
                              file={mainBanner?.file}
                              isDisabled={isSubmitting}
                              accept={['image/jpg', 'image/png', 'image/jpeg', 'image/webp']}
                              dimensions="1010x440"
                              onDrop={acceptedFiles => handleDropFile('mainBanner', acceptedFiles)}
                              onDropRejected={handleDropRejected}
                              extensions="JPG, PNG, JPEG e WEBP"
                              maxFileSize="3 MB"
                            />

                            <ImageUploadLoading position="absolute" isLoading={isUploading} />
                          </>
                        ) : (
                          <VStack w="full">
                            <Box height={{ base: '21.5625rem', xl: '16.75rem' }} w="full">
                              <ImagePreview
                                imageField="mainBanner"
                                preview={mainBanner?.preview ?? form.mainBanner}
                                onSelectNewImage={handleSelectNewImage}
                                onOpenDeleteImageModal={handleOpenImageDeleteModal}
                              />
                            </Box>

                            <Button
                              display={{ base: 'flex', xl: 'none' }}
                              onClick={() => handleSelectNewImage('mainBanner')}
                              w="full"
                              variant="outline"
                              colorScheme="primary"
                              size="sm"
                              zIndex="2"
                            >
                              Trocar Imagem
                            </Button>
                          </VStack>
                        )}
                      </Flex>

                      <FormErrorMessage>{formValidation?.mainBanner?.message}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Box>

                <FormControl isInvalid={formValidation?.description?.isInvalid} mt={8}>
                  <FormLabel>Descrição</FormLabel>

                  <Text color="#20212380" mb={2}>
                    Crie um texto chamativo e direto, lembre-se que será ele que irá convencer seus
                    futuros alunos a comprarem seu plano.
                  </Text>

                  <Box mt={2} sx={TEXT_EDITOR_STYLES}>
                    <Editor value={form?.description} onChange={handleDescriptionChange} />
                  </Box>

                  <FormErrorMessage>{formValidation?.description?.message}</FormErrorMessage>
                </FormControl>
              </>
            )}
          </>
        )}
      </VStack>

      {form.willUsePlanPage && (
        <>
          {form.pageType === 'INTERNAL' && (
            <>
              <FAQ />
              <Testimonials />
            </>
          )}
        </>
      )}

      {/* RODAPÉ */}
      <HStack as="footer" w="full" justify="end" spacing={3} mt={10}>
        <Button
          onClick={handleCancelButtonClick}
          variant="ghost"
          background="gray.200"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isDisabled={isSubmitting}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          form="planManagementEditDescriptionForm"
          colorScheme="primary"
          color="secondary.500"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isLoading={isSubmitting}
        >
          Avançar
        </Button>
      </HStack>
      <DeleteImageModal
        isOpen={isOpen}
        isLoading={isDeletingImage}
        onConfirm={handleDeleteImage}
        onClose={onClose}
      />
    </>
  );
}
