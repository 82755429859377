import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Show,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { memo } from 'react';
import { IoChevronDown } from 'react-icons/io5';
import DDIHelper from '../../helpers/DDIHelper';
import countries from './countries';
import Country from './Country';

export function getDDI(country: string) {
  return DDIHelper.find(item => item.initials.toLowerCase() === country.toLowerCase())?.ddi;
}

export function getCountryByDDI(ddi: string) {
  return DDIHelper.find(item => item.ddi === ddi)?.initials;
}

const scrollbarStyle = {
  '::-webkit-scrollbar': { width: '0.5rem', background: 'transparent' },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: '0.25rem',
    backgroundColor: `gray.200`,
    height: '50%',
  },
};

interface CountrySelectProps {
  value?: string;
  disableChangeCountry?: boolean;
  onChange?: (country: string) => void;
}

function CountrySelectMenu({ value, onChange, disableChangeCountry = false }: CountrySelectProps) {
  function handleCountryChange(country: string) {
    onChange(country.toLowerCase());
  }

  const currentCountry = countries.find(
    country => country.initials.toLowerCase() === value.toLowerCase()
  );

  const isMobile = useBreakpointValue({ base: true, md: false, lg: false, xl: false });

  const placement = isMobile ? 'left' : 'bottom';

  const priorityCountries = [
    'Brasil',
    'United States (USA)',
    'Mexico',
    'Portugal',
    'Spain (España)',
    'Argentina',
    'Colombia',
    'Canada',
    'Italy (Italia)',
    'Germany (Deutschland)',
    'United Kingdom',
    'France',
  ];

  // Ordene o array original para colocar os países de prioridade no início
  const sortedCountries = [...countries].sort((a, b) => {
    const aPriority = priorityCountries.includes(a.country)
      ? priorityCountries.indexOf(a.country)
      : Infinity;
    const bPriority = priorityCountries.includes(b.country)
      ? priorityCountries.indexOf(b.country)
      : Infinity;

    return aPriority - bPriority;
  });

  return (
    <Menu id="country" matchWidth size="sm" placement={placement}>
      <MenuButton
        as={Button}
        isDisabled={disableChangeCountry}
        rightIcon={<IoChevronDown color="#2D3748" />}
        justifyContent="space-between"
        bg="transparent"
        color="#2D374880"
        fontSize="sm"
        flexShrink={0}
        width={{ base: 'auto', md: '180px', lg: '180px' }}
        height={{ base: '24px', md: '28px', lg: '28px' }}
        _active={{ bg: 'transparent' }}
        sx={{ span: { color: '#2D374880', fontWeight: 'normal', flex: 'initial' } }}
      >
        <Flex
          width="full"
          color="rgba(32, 33, 35, 0.50)"
          fontSize={{ base: '10px', md: '14px', lg: '14px' }}
          fontWeight="700"
          lineHeight="normal"
          gap={{ base: '2px', md: 2, lg: 2 }}
        >
          {currentCountry?.flag ? (
            <Image
              src={currentCountry?.flag}
              w={{ base: '22px', md: '28px', lg: '28px' }}
              h={{ base: '16px', md: '18px', lg: '18px' }}
              alt={currentCountry?.country}
              borderRadius="base"
              objectFit="cover"
            />
          ) : (
            <Box bg="#D9D9D9" w="28px" h="18px" borderRadius="base" />
          )}

          <Show above="md">
            <Text fontSize={{ base: '10px', md: '14px', lg: '14px' }}>
              {currentCountry?.country ? 'Alterar país' : 'Código inválido'}
            </Text>
          </Show>
        </Flex>
      </MenuButton>

      <MenuList maxH="300px" overflowY="auto" sx={scrollbarStyle}>
        {sortedCountries.map(country => (
          <Country key={country.initials} country={country} onClick={handleCountryChange} />
        ))}
      </MenuList>
    </Menu>
  );
}

export default memo(CountrySelectMenu);
