import { Box, Center, Heading, Select, Stack, Button, HStack } from '@chakra-ui/react';
import Loading from './Loading';
import PlanManagementHeader from './PlanManagementHeader';
import PlanManagementMobileList from './PlanManagementMobileList';
import PlanManagementTable from './PlanManagementTable';
import SearchInput from './SearchInput';
import usePlanManagement from './usePlanManagement';
import { MdClose as CloseIcon } from 'react-icons/md';
import dayjs from 'dayjs';
import DateRangePicker from '../../../../components/DateRangePicker';
import Paginate from '../../../../components/Paginate';

export default function PlanManagement() {
  const {
    isNotFoundVisible,
    isEmpty,
    currentPage,
    isPaginationVisible,
    isPlanListVisible,
    isLoading,
    planList,
    pageCount,
    searchQuery,
    status,
    isFiltering,
    startDate,
    endDate,
    handleFilter,
    handleRangeChange,
    clearFilters,
    handleSearchChange,
    handlePageChange,
    handleStatusFilterChange,
  } = usePlanManagement();

  return (
    <Box>
      <PlanManagementHeader />

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        justify={{ base: 'initial', lg: 'space-between' }}
        align="center"
        mt={8}
      >
        <SearchInput
          value={searchQuery}
          onSearch={handleSearchChange}
          marginTop={{ base: 2.5, xl: 'unset' }}
        />

        <HStack order={{ base: '-1', xl: 2 }}>
          <DateRangePicker
            startDate={startDate && dayjs(startDate).format('YYYY-MM-DD')}
            endDate={endDate && dayjs(endDate).format('YYYY-MM-DD')}
            onChange={handleRangeChange}
            iconColor="#20212350"
            selectPlaceholder="Data de Criação"
            hideIcon
          />

          <Select
            value={status}
            onChange={handleStatusFilterChange}
            maxWidth={{ base: 'unset', xl: '15.6875rem' }}
            size="sm"
            borderRadius="md"
            focusBorderColor="gray.300"
            color="#20212380"
          >
            <option value="">Todos os Status</option>
            <option value="ATIVO">Ativo</option>
            <option value="INATIVO">Inativo</option>
            <option value="NAO_LISTADO">Não Listado</option>
            <option value="EM_EDICAO">Em Edição</option>
          </Select>

          <Button
            onClick={handleFilter}
            flexShrink={0}
            size="xs"
            colorScheme="primary"
            color="secondary.500"
          >
            Filtrar
          </Button>

          {isFiltering && (
            <Button
              onClick={clearFilters}
              variant="outline"
              flexShrink={0}
              size="xs"
              colorScheme="gray"
              rightIcon={<CloseIcon size="1rem" />}
            >
              Limpar Filtros
            </Button>
          )}
        </HStack>
      </Stack>

      {isLoading && <Loading />}

      {isNotFoundVisible && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.200">
            Nenhum resultado encontrado...
          </Heading>
        </Center>
      )}

      {isEmpty && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.200" textAlign="center">
            Você não possui nenhum produto cadastrado.
          </Heading>
        </Center>
      )}

      {isPlanListVisible && (
        <>
          <PlanManagementTable
            display={{ base: 'none', xl: 'block' }}
            planList={planList}
            mt="0.875rem"
          />

          <PlanManagementMobileList
            display={{ base: 'block', xl: 'none' }}
            planList={planList}
            mt={2.5}
          />
        </>
      )}

      {isPaginationVisible && (
        <Paginate
          pageCount={pageCount}
          initialPage={currentPage}
          onPageChange={handlePageChange}
          mt={{ base: 8, xl: 4 }}
        />
      )}
    </Box>
  );
}
