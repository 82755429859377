import {
  Box,
  Card,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Progress,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import prettyBytes from 'pretty-bytes';
import { useEffect, useState } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { BsPlayFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { UpdateStatusTranscodeType, Video } from '../..';
import VideoLibraryAPI from '../../../../../../api/VideoLibrary';
import Toast from '../../../../../../components/Toast';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { ModalDeleteVideo } from '../../ModalDeleteVideo';
import ThumbnailDefault from '../../ThumbnailDefault';

type CardVideoLibraryMobileProps = {
  video: Video;
  updateStatusTranscode: ({ id, transcode, thumbnail }: UpdateStatusTranscodeType) => void;
  onDeleteVideoList: (id: number) => void;
  onOpenPreviewVideo: (id: number) => void;
};

function getStatus({ transcode, archive, status }) {
  if (status.state === 'starting' && status.progress === 0) {
    return 'Na fila para otimização';
  }

  if (status.state === 'active') {
    return 'Otimizando';
  }

  if (archive) {
    return 'Arquivado';
  }

  switch (transcode) {
    case 'complete':
      return 'Ativo';
    case 'error':
      return 'Erro no processamento';
    default:
      return 'Processando';
  }
}

export function CardVideoLibraryMobileList({
  video,
  updateStatusTranscode,
  onDeleteVideoList,
  onOpenPreviewVideo,
}: CardVideoLibraryMobileProps) {
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState({
    state: '',
    progress: 0,
    time_left: 0,
  });

  useEffect(() => {
    let interval;

    async function getStatusTranscode() {
      VideoLibraryAPI.getTranscodeStatus(video.id)
        .then(({ data: transcodeData }) => {
          if (transcodeData?.status) {
            setStatus(transcodeData.status);
          }

          if (
            transcodeData?.transcode?.status === 'complete' ||
            transcodeData?.transcode?.status === 'error'
          ) {
            updateStatusTranscode({
              id: video.id,
              transcode: transcodeData.transcode.status,
              thumbnail: transcodeData.pictures?.base_link,
              duration: transcodeData.duration,
              name: video.name,
            });
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(ErrorResponse(error));
        })
        .finally(() => {
          if (video?.transcode === 'complete' && interval) {
            clearInterval(interval);
          }
        });
    }

    interval = setInterval(() => {
      if (video.transcode === 'complete' || video.transcode === 'error') {
        clearInterval(interval);
      } else {
        getStatusTranscode();
      }
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, [updateStatusTranscode, video.id, video.name, video.thumbnail, video.transcode]);

  async function downloadVideo() {
    try {
      const { data: videoData } = await VideoLibraryAPI.download(video?.id);

      if (videoData) {
        window.open(videoData);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function deleteVideo(id) {
    try {
      setIsLoading(true);

      await VideoLibraryAPI.delete(id);

      onDeleteVideoList(id);
      Toast('Vídeo excluído com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Card
        width="full"
        height="75px"
        marginTop="10px"
        borderRadius={6}
        boxShadow="1px 1px 4px rgba(0, 0, 0, 0.25)"
      >
        <Stack direction="row" alignItems="center">
          <Box overflow="hidden" zIndex={1} borderRadius={6} minW="132px" minH="75px">
            {video.thumbnail ? (
              <Flex
                width="132px"
                height="75px"
                _hover={{ cursor: 'pointer' }}
                justifyContent="center"
                alignItems="center"
                onClick={() => onOpenPreviewVideo(video.id)}
                backgroundImage={`url(${video.thumbnail})`}
                backgroundSize="cover"
                backgroundPosition="center"
                position="relative"
              >
                <Icon
                  as={BsPlayFill}
                  position="absolute"
                  height="35px"
                  width="35px"
                  zIndex={5}
                  color="textColor"
                  filter="drop-shadow(2px 2px 2px #666666)"
                />
              </Flex>
            ) : (
              <ThumbnailDefault width="100%" height="143px" />
            )}
          </Box>
          <Stack spacing="2px" width="full" paddingX="6px">
            <Stack
              direction="row"
              width="full"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Text fontSize="8.71px" fontWeight={500} noOfLines={1} width="75%">
                {video.name}
              </Text>
              <Menu>
                <MenuButton
                  bgColor="rgba(32, 33, 35, 0.12)"
                  width="18.09px"
                  height="18.09px"
                  borderRadius={6}
                >
                  <Icon as={BiDotsHorizontalRounded} boxSize={6} />
                </MenuButton>
                <MenuList zIndex={6}>
                  <MenuItem
                    isDisabled={video.transcode !== 'complete'}
                    onClick={() => history.push(`/video-library/${video.id}`)}
                  >
                    Editar
                  </MenuItem>
                  <MenuItem isDisabled={video.transcode !== 'complete'} onClick={downloadVideo}>
                    Download
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem color="red" onClick={onOpen}>
                    Excluir
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
            <Text fontSize="8.04px" color="#20212380" fontWeight={400}>
              Tamanho: {video.size ? prettyBytes(video.size) : 0}
            </Text>
            <Text fontSize="8.04px" color="#20212380" fontWeight={400}>
              Data upload: {dayjs(video.uploadAt).format('DD/MM/YYYY')}
            </Text>
            {video.transcode === 'complete' && (
              <Text fontSize="8.04px" color="#20212380" fontWeight={400}>
                Status:{' '}
                <Text as="span">
                  {getStatus({
                    transcode: video.transcode,
                    archive: video.archive,
                    status: status,
                  })}
                </Text>
              </Text>
            )}
            {video.transcode !== 'complete' && status.progress < 100 && (
              <Stack direction="row" alignItems="center" height="10px" spacing={1}>
                <Progress
                  hidden={video.transcode === 'complete' || video.transcode === 'error'}
                  width="full"
                  height="6px"
                  borderRadius={6}
                  isIndeterminate={!status.progress}
                  value={status.progress}
                  size="45px"
                  colorScheme="orange"
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Card>

      <ModalDeleteVideo
        key={video.id}
        videoId={video.id}
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        deleteVideo={deleteVideo}
      />
    </>
  );
}
