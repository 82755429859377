import Joi from 'joi';

export type SubscriptionSchemaType = {
  cardNumber: string;
  cardCVV: string;
  cardExpirationDate: string;
  month: string;
  year: string;
};

export const subscriptionSchemaValidator = Joi.object<SubscriptionSchemaType>().keys({
  cardNumber: Joi.string()
    .min(14)
    .max(16)
    .required()
    .messages({
      'string.min': 'Deve ser no mínimo 14 dígitos.',
      'string.max': 'Deve ser no máximo 16 dígitos.',
      'any.required': 'O número do cartão é obrigatório.',
      'string.empty': 'O número do cartão é obrigatório.',
    } as JoiMessages.LanguageMessages),
  month: Joi.string().required(),
  year: Joi.string().required(),
  cardCVV: Joi.string().required().min(3).messages({
    'any.required': 'É obrigatório.',
    'string.empty': 'Informe o CVV.',
    'string.min': 'Mínimo 3 dígitos.',
  }),
  cardExpirationDate: Joi.string().regex(/^\d{4}$/),
});
