import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { HiDotsHorizontal } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Lead } from '..';
import Paginate from '../../../../components/Paginate';

interface LeadListMobileProps {
  leads: Lead[];
  pageCount: number;
  currentPage: number;
  onPageChange: (selected: { selected: number }) => void;
}

export default function LeadListMobile({
  leads,
  currentPage,
  pageCount,
  onPageChange,
}: LeadListMobileProps) {
  return (
    <List spacing={3} display={{ base: 'initial', md: 'none' }}>
      {leads?.map(lead => (
        <ListItem key={lead.id}>
          <Card>
            <CardHeader>
              <HStack justifyContent="space-between">
                <Text fontWeight="bold" color="blackAlpha.600">
                  {lead.fullName}
                </Text>
                <Menu size="sm" arrowPadding={0}>
                  <MenuButton
                    w="27px"
                    h="24px"
                    borderRadius={5}
                    bgColor="#20212310"
                    _hover={{ bgColor: '#20212350' }}
                    _active={{ bgColor: '#20212350' }}
                    style={{ boxShadow: 'none', outline: 'none' }}
                  >
                    <Stack justifyContent="center" alignItems="center">
                      <HiDotsHorizontal size="18px" color="#20212350" />
                    </Stack>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      as={Link}
                      to={`/lead-base/${lead.id}/conversions`}
                      fontWeight={500}
                      color="#20212380"
                      style={{ boxShadow: 'none', outline: 'none' }}
                    >
                      Ver conversões
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </CardHeader>
            <CardBody>
              <Stack spacing={4} color="blackAlpha.600">
                <HStack justifyContent="space-between">
                  <Text>E-mail</Text>
                  <Text>{lead.email}</Text>
                </HStack>
                <HStack justifyContent="space-between">
                  <Text>Telefone</Text>
                  <Text>{lead.phone || '-'}</Text>
                </HStack>
                <HStack justifyContent="space-between">
                  <Text>Conversões</Text>
                  <Text>{lead.amountConversions}</Text>
                </HStack>
                <HStack justifyContent="space-between">
                  <Text>Última conversão</Text>
                  <Text>
                    {dayjs(lead.lastConversion, {
                      locale: 'pt-br',
                    }).fromNow()}
                  </Text>
                </HStack>
              </Stack>
            </CardBody>
          </Card>
        </ListItem>
      ))}

      <Paginate
        pageCount={pageCount}
        onPageChange={onPageChange}
        initialPage={currentPage}
        pageRangeDisplayed={0.5}
      />
    </List>
  );
}
