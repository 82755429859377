const FIRST_PHONE = 0;

function MaskPhone(value) {
  if (!value) return '-';

  if (Array.isArray(value) && !value.length) return '-';

  if (Array.isArray(value) && value.length) value = value[FIRST_PHONE];

  value = value.replace(/\D/g, '');

  const regex = /^(\+?55|0)(\d{2})(\d{2,5})(\d{4})$/;
  const match = value.match(regex);

  if (match) {
    const ddi = match[1];
    const ddd = match[2];
    const prefixo = match[3];
    const sufixo = match[4];

    let numeroFormatado = `${ddi ? `+${ddi}` : ''} (${ddd}) ${prefixo}`;

    if (sufixo.length > 0) {
      numeroFormatado += `-${sufixo}`;
    }

    return numeroFormatado;
  }

  return value;
}

export default MaskPhone;
