/* eslint-disable no-useless-escape */
export function convertVideoLinkToEmbed(link: string) {
  const YouTubeLinkPattern =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/;
  const VimeoLinkPattern = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)/;

  // Check for YouTube link
  let result = YouTubeLinkPattern.exec(link);
  if (result) {
    const videoId = result[1];
    const embedLink = `https://www.youtube.com/embed/${videoId}`;
    return embedLink;
  }

  // Check for Vimeo link
  result = VimeoLinkPattern.exec(link);
  if (result) {
    const videoId = result[1];
    const embedLink = `https://player.vimeo.com/video/${videoId}`;
    return embedLink;
  }

  // If the link is not valid for either YouTube or Vimeo, return null
  return null;
}
