import { Avatar, Image, Stack, Td, Text, Tr } from '@chakra-ui/react';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { ActionButton, CourseAffiliateType } from '../../..';
import convertToBrazilianCurrency from '../../../../../../../helpers/convertToBrazilianCurrency';

interface ITableRow {
  courseAffiliate: CourseAffiliateType;
  onOpenAddAffiliate: () => void;
  onOpenRemoveProduct: () => void;
  setCourseAffiliationId: (id: number) => void;
  setProductName: (name: string) => void;
  setProductId: (id: number) => void;
}

function TableRow({
  courseAffiliate,
  onOpenAddAffiliate,
  onOpenRemoveProduct,
  setCourseAffiliationId,
  setProductName,
  setProductId,
}: ITableRow) {
  return (
    <Tr>
      <Td p={0} borderLeftRadius={10}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          as={Link}
          to={`/affiliations/products/${courseAffiliate.courseAffiliationId}/edit`}
        >
          {!courseAffiliate.thumbnail ? (
            <Avatar
              name={courseAffiliate.productName}
              colorScheme="primary"
              color="textColor"
              boxSize={51}
              borderRadius={5}
              objectFit="contain"
            />
          ) : (
            <Image
              src={courseAffiliate.thumbnail}
              boxSize={51}
              borderRadius={5}
              objectFit="contain"
            />
          )}
          <Text maxW={175} isTruncated>
            {courseAffiliate.productName}
          </Text>
        </Stack>
      </Td>
      <Td p={0} textAlign="center">
        <Stack direction="column" alignItems="flex-start" spacing={0}>
          <Text fontSize="16px"> {convertToBrazilianCurrency(courseAffiliate.productValue)}</Text>
          <Text fontSize="12px">
            <Text as="span" color="#22BB33">
              {courseAffiliate.commissionValue}%
            </Text>{' '}
            de comissão
          </Text>
        </Stack>
      </Td>
      <Td p={0} textAlign="center">
        {courseAffiliate.amountOfAffiliates}
      </Td>
      <Td p={0} textAlign="center">
        {courseAffiliate.amountOfSales}
      </Td>
      <Td p={0} textAlign="center" color="#22BB33">
        {convertToBrazilianCurrency(courseAffiliate.winnings)}
      </Td>
      <Td p={0} textAlign="center" borderRightRadius={10}>
        <ActionButton
          courseAffiliateId={courseAffiliate.courseAffiliationId}
          productId={courseAffiliate.productId}
          productName={courseAffiliate.productName}
          onOpenAddAffiliate={onOpenAddAffiliate}
          onOpenRemoveProduct={onOpenRemoveProduct}
          setCourseAffiliationId={setCourseAffiliationId}
          setProductName={setProductName}
          setProductId={setProductId}
        />
      </Td>
    </Tr>
  );
}

export default memo(TableRow);
