import { Card, Image, Text } from '@chakra-ui/react';
import { IRecurrencePlanCourse } from '../..';

export default function ProductCard({ name, thumbnail }: IRecurrencePlanCourse) {
  return (
    <Card
      borderRadius="10px"
      border="1px solid rgba(32, 33, 35, 0.05)"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      width={{ base: '166px', md: '255px' }}
      height="auto"
    >
      <Image
        alt={name}
        src={thumbnail}
        boxSize={{ base: '166px', md: '255px' }}
        borderRadius="10px"
        objectFit="cover"
      />

      <Text
        fontSize={{ base: '9px', md: '14px' }}
        fontWeight="600"
        lineHeight="normal"
        p={{ base: '10px', md: '20px' }}
        textAlign="center"
      >
        {name}
      </Text>
    </Card>
  );
}
