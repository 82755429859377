import { Box, Button, Flex, Heading, useBreakpointValue } from '@chakra-ui/react';
import { FaEye } from 'react-icons/fa';
import { MdChevronLeft } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';

interface HeaderProps {
  isViewModulesButtonVisible?: boolean;
  backUrl?: string;
}

export default function Header({
  isViewModulesButtonVisible = true,
  backUrl = '/members-area-management',
}: HeaderProps) {
  const { pathname, search } = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { showcaseId, showcaseCourseId } = useParams<{
    showcaseId: string;
    showcaseCourseId: string;
  }>();

  const isModulesManagementPath = pathname.includes('modules');
  const urlSearchParams = new URLSearchParams(search);
  const productId = urlSearchParams.get('productId');

  const visualizationLink = isModulesManagementPath
    ? {
        pathname: `/members-area/v2/course/${productId}/modules`,
        search: `showcaseId=${showcaseId}&showcaseCourseId=${showcaseCourseId}&preview=true`,
      }
    : {
        pathname: '/members-area/v2',
        search: 'preview=true',
      };

  const ViewModulesButtonVisibility = isViewModulesButtonVisible ? 'initial' : 'hidden';

  return (
    <Flex
      as="header"
      alignItems="center"
      bg="#202123"
      padding="15px 20px"
      gap="15px"
      height={isMobile ? '56px' : '64px'}
      position="relative"
      justifyContent="space-between"
    >
      <Button
        as={Link}
        hidden={isMobile}
        to={backUrl}
        leftIcon={<MdChevronLeft size="1.5rem" />}
        color="white"
        colorScheme="black"
        title="Voltar para a página inicial"
        size="sm"
      >
        Voltar
      </Button>

      <Button
        as={Link}
        hidden={!isMobile}
        to={backUrl}
        color="white"
        colorScheme="black"
        title="Voltar para a página inicial"
        size="sm"
      >
        <MdChevronLeft size="1.3rem" />
      </Button>

      <Box>
        <Heading fontSize={{ base: 'md', xl: 'xl' }} fontWeight="bold" color="white">
          Área de membros - Netflix
        </Heading>
      </Box>

      <Button
        visibility={ViewModulesButtonVisibility}
        as={Link}
        hidden={!isMobile}
        to={visualizationLink}
        color="white"
        colorScheme="black"
        title={`Visualizar ${isModulesManagementPath ? 'Módulos' : 'Vitrines'}`}
        size="sm"
        variant="outline"
      >
        <FaEye size="0.9rem" />
      </Button>

      <Button
        visibility={ViewModulesButtonVisibility}
        as={Link}
        hidden={isMobile}
        to={visualizationLink}
        leftIcon={<FaEye />}
        color="white"
        colorScheme="black"
        title="Visualizar vitrine"
        size="sm"
        variant="outline"
      >
        {`Visualizar ${isModulesManagementPath ? 'Módulos' : 'Vitrines'}`}
      </Button>
    </Flex>
  );
}
