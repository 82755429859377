import { Link, useParams } from 'react-router-dom';

import { HStack } from '@chakra-ui/react';
import { Heading } from '../../../../../components/Heading';

function ModuleCertificate() {
  const { id: courseId } = useParams<{ id: string }>();

  return (
    <HStack
      as={Link}
      to={`/members-area/v1/products/${courseId}/content-view/certificate`}
      margin="18px"
      borderRadius="10px"
      border="1px solid rgba(32, 33, 35, 0.50)"
      background="#FFF"
      width={{ base: '90%', md: '250px', lg: '250px' }}
      height="52px"
      padding="7px 14px"
      justifyContent="center"
      alignItems="center"
      _hover={{
        filter: 'none',
      }}
    >
      <Heading as="h5" fontSize="14px" fontWeight="500">
        Certificado
      </Heading>
    </HStack>
  );
}

export default ModuleCertificate;
