import { Box, Heading, List, Stack, Text } from '@chakra-ui/react';
import ListItem from './ListItem';
import {
  QuestionPerformanceIcon,
  QuestionStatisticsIcon,
  StudentPerformanceIcon,
} from './ListItem/icons';

export default function QuestionStatistics() {
  return (
    <Box>
      <Heading fontWeight="semibold" as="h1" fontSize={{ base: '2xl', lg: '4xl' }}>
        Estatísticas de questões
      </Heading>

      <Text
        mt={{ base: 2.5, lg: 5 }}
        lineHeight={{ base: 'normal', lg: '22px' }}
        color="#20212380"
        fontSize={{ base: 'sm', lg: 'lg' }}
      >
        Veja os resultados dos seus alunos acompanhe suas respostas com análises detalhadas. Analise
        questões corretas, erros, total respondido e desempenho.
      </Text>

      <Stack
        as={List}
        direction={{ base: 'column', lg: 'row' }}
        mt={{ base: 8, lg: '1.875rem' }}
        spacing={5}
      >
        <ListItem
          icon={<QuestionStatisticsIcon />}
          title="Estatísticas de questões"
          description="Visualize as questões e suas estatísticas de forma individual."
          to="/tools/question-statistics/questions"
        />

        <ListItem
          icon={<StudentPerformanceIcon />}
          title="Desempenho de alunos"
          description="Dados dos alunos com quantidade de erros, acertos e rendimento."
          isDisabled
        />

        <ListItem
          icon={<QuestionPerformanceIcon />}
          title="Desempenho de questões"
          description="Analise as questões por área, assuntos, bancas, disciplinas e etc."
          isDisabled
        />
      </Stack>
    </Box>
  );
}
