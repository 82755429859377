import useQuery from '../../hooks/useQuery';
import useShoppingCart from '../../hooks/useShoppingCart';

export default function useHasAffiliation(): boolean {
  const query = useQuery();
  const { getShoppingCartFromLocalStorage } = useShoppingCart();

  const affiliationQuery = query.get('affiliation');
  const shoppingCart = getShoppingCartFromLocalStorage();

  const hasAffiliation = !!shoppingCart?.affiliation || !!affiliationQuery;

  return hasAffiliation;
}
