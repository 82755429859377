import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import SelectWorksheet from '../../../../../components/SelectWorksheet';

interface IPros {
  isOpen: boolean;
  onClose: () => void;
  fetchingQuestions: () => Promise<void>;
}

export default function ModalImportQuestions({ isOpen, onClose, fetchingQuestions }: IPros) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="auto" isCentered>
      <ModalOverlay />
      <ModalContent width={{ base: 'full', md: '612px', lg: '612px' }} margin="20px" height="auto">
        <ModalHeader
          color="#202123"
          fontSize="18px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="28px"
          paddingY="10px"
        >
          Importe suas questões
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SelectWorksheet onClose={onClose} fetchingQuestions={fetchingQuestions} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
