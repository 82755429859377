import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import Collapse from 'react-collapsible';
import { MdKeyboardArrowRight as ArrowRight } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import QuestionAPI from '../../../../../../../api/Question';
import Spinner from '../../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../../components/Toast';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import { convertHTMLToText } from '../../../../../../../helpers/convertHTMLToText';
import { IQuestionsProps } from '../types';
import Content from './Content';
import styles from './styles.module.css';

type Params = {
  id: string;
  moduleId: string;
  contentId: string;
};

function Question({
  question,
  questionId,
  fetchAllQuestions,
  onDeleteQuestion,
  setIsDisabledSubmit,
}: IQuestionsProps) {
  const { id: courseId, moduleId, contentId } = useParams<Params>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [rotateIcon, setRotateIcon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);

  function handleCollapse() {
    setRotateIcon(prevRotate => !prevRotate);
    setCollapse(prevCollapse => !prevCollapse);
  }

  function handleDelete() {
    onDeleteQuestion(questionId);
    onClose();
  }

  async function handleDuplicateQuestion() {
    try {
      setIsLoading(true);

      await QuestionAPI.duplicate(
        Number(courseId),
        Number(moduleId),
        Number(contentId),
        questionId
      );

      await fetchAllQuestions();
      Toast('Questão duplicada com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className={`accordion ${styles.accordionQuestion}`}>
        <div className={`card ${styles.card}`}>
          <div className={`card-header ${styles.cardHeader}`}>
            <button
              className={`d-flex align-items-center justify-content-between ${styles.buttonCollapse}`}
              onClick={handleCollapse}
              type="button"
            >
              <div className={styles.leftColumn}>
                <div className={styles.iconContainer}>
                  <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 23"
                    fill="#202123"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.6 20.125C5.6 21.7062 4.34 23 2.8 23C1.26 23 0 21.7062 0 20.125C0 18.5438 1.26 17.25 2.8 17.25C4.34 17.25 5.6 18.5438 5.6 20.125ZM2.8 8.625C1.26 8.625 0 9.91875 0 11.5C0 13.0813 1.26 14.375 2.8 14.375C4.34 14.375 5.6 13.0813 5.6 11.5C5.6 9.91875 4.34 8.625 2.8 8.625ZM2.8 0C1.26 0 0 1.29375 0 2.875C0 4.45625 1.26 5.75 2.8 5.75C4.34 5.75 5.6 4.45625 5.6 2.875C5.6 1.29375 4.34 0 2.8 0ZM11.2 5.75C12.74 5.75 14 4.45625 14 2.875C14 1.29375 12.74 0 11.2 0C9.66 0 8.4 1.29375 8.4 2.875C8.4 4.45625 9.66 5.75 11.2 5.75ZM11.2 8.625C9.66 8.625 8.4 9.91875 8.4 11.5C8.4 13.0813 9.66 14.375 11.2 14.375C12.74 14.375 14 13.0813 14 11.5C14 9.91875 12.74 8.625 11.2 8.625ZM11.2 17.25C9.66 17.25 8.4 18.5438 8.4 20.125C8.4 21.7062 9.66 23 11.2 23C12.74 23 14 21.7062 14 20.125C14 18.5438 12.74 17.25 11.2 17.25Z"
                      fill="current"
                    />
                  </svg>
                </div>
                <span
                  className={styles.questionText}
                  title={convertHTMLToText(question?.questionText)}
                >
                  {convertHTMLToText(question?.questionText)}
                </span>
              </div>
            </button>
            <div className={styles.rightColumn}>
              <button type="button" className={styles.deleteButton} onClick={onOpen}>
                {isLoading ? <Spinner /> : 'Excluir'}
              </button>
              <button
                type="button"
                onClick={handleDuplicateQuestion}
                className={styles.duplicateButton}
              >
                {isLoading ? <Spinner /> : 'Duplicar'}
              </button>
            </div>
            <div className="mr-2">
              <ArrowRight
                color="#202123"
                size={18}
                className={rotateIcon && `${styles.rotateIcon}`}
              />
            </div>
          </div>

          <Collapse trigger="" open={collapse}>
            <div id={`accordionQuestion${question?.id}`}>
              <div className="card-body">
                {question && collapse && (
                  <Content
                    questionId={questionId}
                    question={question}
                    fetchAllQuestions={fetchAllQuestions}
                    setIsDisabledSubmit={setIsDisabledSubmit}
                  />
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={600}>Excluir questão?</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontWeight={500}>
            Após a exclusão, todos os itens relacionados a essa questão também serão excluídos.
          </ModalBody>

          <ModalFooter>
            <Stack direction="row" spacing={2}>
              <Button colorScheme="gray" onClick={onClose} size="sm">
                Cancelar
              </Button>
              <Button
                size="sm"
                colorScheme="red"
                onClick={handleDelete}
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Excluir
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Question;
