import { Avatar, Button, HStack, Skeleton, SkeletonText, Text, VStack } from '@chakra-ui/react';
import { IProductCart } from '../../../../hooks/useShoppingCart';

interface ProductProps {
  product: IProductCart;
  isLoading: boolean;
  onDelete: (productId: number) => void;
  isPurchaseByPixConfirmed: boolean;
  mainProductId: number;
}

export default function Product({
  product,
  isLoading,
  onDelete,
  isPurchaseByPixConfirmed,
  mainProductId,
}: ProductProps) {
  const isLoaded = !isLoading;

  function handleDelete() {
    onDelete(product.productId);
  }

  const isMainProduct = product.productId === mainProductId;

  return (
    <HStack w="full" align="flex-start" spacing={{ base: 3, xl: 4 }}>
      <Skeleton startColor="default.400" endColor="default.400" isLoaded={isLoaded}>
        <Avatar
          src={product.productThumbnail}
          name={product.productName}
          borderRadius="base"
          background="default.500"
          color="white"
          boxSize={{ base: '4.25rem', xl: '5.875rem' }}
        />
      </Skeleton>

      <SkeletonText noOfLines={2} isLoaded={isLoaded} w="full" skeletonHeight={4}>
        <VStack spacing={0} justify="start" align="start">
          <Text
            color="#202123"
            fontSize="20px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
          >
            {product.productName}
          </Text>

          {!isPurchaseByPixConfirmed && !isMainProduct && (
            <Skeleton isLoaded={isLoaded}>
              <Button
                onClick={handleDelete}
                size="sm"
                variant="link"
                colorScheme="red"
                fontSize="0.625rem"
                color="#BB2124BF"
                textDecoration="underLine"
              >
                Remover produto
              </Button>
            </Skeleton>
          )}
        </VStack>
      </SkeletonText>
    </HStack>
  );
}
