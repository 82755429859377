import { Link, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Alert } from 'react-bootstrap';
import ApiPlans from '../../../../api/Plan';
import Container from '../../../../components/Container/Container';
import DotsFalling from '../../../../components/Loadings/DotsFalling';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import InputCheckbox from '../../../../components/InputCheckbox';
import Sidebar from '../../../../components/Sidebar';
import Toast from '../../../../components/Toast';
import { Heading } from '../../../../components/Heading';
import styles from './styles.module.css';

const planInitialValue = {
  name: '',
  value: 0,
  recurrence: '',
  status: 'ATIVO',
  trial: 0,
  customerPercentage: 50,
  marketplacePercentage: 50,
  marketplaceIntegration: false,
  audienceInformation: false,
  withdrawalsWhenYouWish: false,
  anticipations: false,
  customerSuccess: false,
  designer: false,
  discountCoupons: false,
  durationOfTheCourse: false,
  videoCourses: false,
  pdfCourses: false,
  userBase: false,
  testimonials: false,
  viewLimiter: false,
  watermarks: false,
  salesReport: false,
  financialStatement: false,
  creditCardPayment: false,
  boletoPayment: false,
  internationalPayments: false,
  automatedSplits: false,
  homologationBy: '',
  homologation: false,
  discountsMarketingServices: false,
  amountProducts: 0,
  amountStudents: 0,
  prolunoClouds: 1,
  showInPrices: false,
};

function NewPlans() {
  const { id } = useParams();

  const history = useHistory();

  const [plan, setPlans] = useState(planInitialValue);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (id) {
      async function getPlanById() {
        try {
          const { data: planData } = await ApiPlans.show(id);
          setPlans(planData);
        } catch (error) {
          setMessage(ErrorResponse(error));
        }
      }

      getPlanById();
    }
  }, [id]);

  const handleChange = event => {
    const { name } = event.target;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    const newPlan = {
      ...plan,
      [name]: value,
    };

    setPlans(newPlan);
  };

  async function onSubmit(event) {
    event.preventDefault();
    setMessage('');

    if (!id) {
      try {
        setLoading(true);

        await ApiPlans.store(plan);

        setPlans(planInitialValue);

        Toast('Plano criado com sucesso');
      } catch (err) {
        setMessage(ErrorResponse(err));

        history.push('internal-manager/plans');
      } finally {
        setLoading(false);
      }

      return;
    }

    try {
      setLoading(true);

      await ApiPlans.update(id, plan);

      Toast(`O plano ${plan.name} foi atualizado`);

      setLoading(false);

      history.push('/internal-manager/plans');
    } catch (err) {
      setMessage(ErrorResponse(err));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Sidebar />
      <Container>
        <div className={styles.container}>
          <header>
            <div>
              <Heading>{!id ? 'Cadastro de planos' : `Editar plano ${plan.name}`}</Heading>
            </div>
          </header>
          <div className={`mt-5 ${styles.content}`}>
            <form onSubmit={onSubmit}>
              <div className={styles.row}>
                <div className={styles.column__left}>
                  <div className={styles.preco}>
                    <span className={styles.symbol}>R$</span>
                    <span className={styles.value}>{plan.value || '00'}</span>
                  </div>
                  <div className={styles.field}>
                    <label htmlFor="name">Nome do plano</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Ex: Profissional"
                      onChange={handleChange}
                      value={plan.name}
                      required
                    />
                  </div>
                  <div className={styles.field}>
                    <label htmlFor="value">Valor do plano</label>
                    <input
                      type="number"
                      name="value"
                      placeholder="Ex: 380.00"
                      step="0.01"
                      min="1"
                      max="99999.99"
                      maxLength="8"
                      onChange={handleChange}
                      value={plan.value}
                      required
                    />
                  </div>

                  <div className={styles.field}>
                    <label htmlFor="trial">Período de teste</label>
                    <input
                      type="number"
                      name="trial"
                      id="trial"
                      placeholder="Ex: 10"
                      onChange={handleChange}
                      value={plan.trial}
                      min="0"
                      required
                    />
                  </div>
                  <div className={styles.field}>
                    <label htmlFor="recurrence">Duração do plano</label>
                    <select
                      type="text"
                      name="recurrence"
                      id="recurrence"
                      onChange={handleChange}
                      value={plan.recurrence}
                      required
                    >
                      <option value="" hidden>
                        Selecione a duração do plano
                      </option>
                      <option value="MÊS">MÊS</option>
                      <option value="ANO">ANO</option>
                    </select>
                  </div>
                  <div className={styles.field}>
                    <label htmlFor="amountProducts">Quantidade de produtos</label>
                    <input
                      type="number"
                      name="amountProducts"
                      id="amountProducts"
                      onChange={handleChange}
                      value={plan.amountProducts}
                      placeholder="Digite a quantidade de produtos"
                      min="1"
                    ></input>
                  </div>
                  <div className={styles.field}>
                    <label htmlFor="amountStudents">Quantidade de alunos</label>
                    <input
                      type="number"
                      name="amountStudents"
                      id="amountStudents"
                      onChange={handleChange}
                      value={plan.amountStudents}
                      placeholder="Digite a quantidade de alunos"
                      min="1"
                    ></input>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.field__header}>
                      <label htmlFor="prolunoClouds">Proluno Cloud</label>
                      <span>{plan.prolunoClouds} GB</span>
                    </div>
                    <input
                      type="range"
                      name="prolunoClouds"
                      step="1"
                      min="1"
                      max="1000"
                      onChange={handleChange}
                      value={plan.prolunoClouds}
                      className={styles.input__ranger}
                    />
                  </div>
                  <div className={styles.field}>
                    <div className={styles.field__header}>
                      <label htmlFor="customerPercentage">Porcentagem das Vendas</label>
                      <span>{plan.customerPercentage} %</span>
                    </div>
                    <input
                      type="range"
                      step="1"
                      min="1"
                      max="100"
                      name="customerPercentage"
                      className={styles.input__ranger}
                      onChange={handleChange}
                      value={plan.customerPercentage}
                    />
                  </div>
                  <div className={styles.field}>
                    <div className={styles.field__header}>
                      <label htmlFor="marketplacePercentage">Porcentagem do marketplace</label>
                      <span>{plan.marketplacePercentage} %</span>
                    </div>
                    <input
                      type="range"
                      step="1"
                      min="1"
                      max="100"
                      name="marketplacePercentage"
                      className={styles.input__ranger}
                      onChange={handleChange}
                      value={plan.marketplacePercentage}
                      required
                    />
                  </div>

                  <div className="d-flex flex-column">
                    <div className={styles.field}>
                      <label htmlFor="status">Status</label>
                      <select name="status" id="status" onChange={handleChange} value={plan.status}>
                        <option value="ATIVO">ATIVO</option>
                        <option value="BLOQUEADO">BLOQUEADO</option>
                        <option value="EM_EDICAO">EM EDIÇÃO</option>
                        <option value="INATIVO">INATIVO</option>
                      </select>
                    </div>

                    {id && (
                      <>
                        <div className={`${styles.checkBoxShowPlan} m`}>
                          <InputCheckbox
                            name="showInPrices"
                            id="showInPrices"
                            onChange={handleChange}
                            checked={plan.showInPrices}
                          />
                          <label for="showInPrices">Mostrar na página Preços</label>
                        </div>
                        <div className={`${styles.checkBoxShowPlan}`}>
                          <InputCheckbox
                            name="homologation"
                            id="homologation"
                            onChange={handleChange}
                            checked={plan.homologation}
                          />
                          <label for="homologation">Homologar</label>
                        </div>
                        <p className={styles.tip}>
                          Ao marcar como homologado,{' '}
                          <b style={{ fontWeight: 600 }}>o plano será criado na Iugu</b>.
                        </p>
                      </>
                    )}
                  </div>

                  <Alert className="mt-4" variant={message ? 'danger' : ''}>
                    <span>{message}</span>
                  </Alert>
                </div>

                <div className={styles.column__right}>
                  <div className={styles.table__container}>
                    <h4>Funcionalidades disponíveis</h4>
                    <div className="table-responsive">
                      <table className={`mt-3 ${styles.table}`}>
                        <tbody>
                          <tr className={styles.header}>
                            <th>Produtos</th>
                            <th></th>
                          </tr>
                          <tr>
                            <td>Curso em vídeo</td>
                            <td>
                              <InputCheckbox
                                name="videoCourses"
                                id="videoCourses"
                                onChange={handleChange}
                                checked={plan.videoCourses}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Curso em PDF</td>
                            <td>
                              <InputCheckbox
                                name="pdfCourses"
                                id="pdfCourses"
                                onChange={handleChange}
                                checked={plan.pdfCourses}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Integração com o marketplace</td>
                            <td>
                              <InputCheckbox
                                name="marketplaceIntegration"
                                id="marketplaceIntegration"
                                onChange={handleChange}
                                checked={plan.marketplaceIntegration}
                              />
                            </td>
                          </tr>
                          <tr className={styles.header}>
                            <th>Marketing</th>
                            <th></th>
                          </tr>
                          <tr>
                            <td>Base de usuários</td>
                            <td>
                              <InputCheckbox
                                name="userBase"
                                id="userBase"
                                checked={plan.userBase}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Cupons de desconto</td>
                            <td>
                              <InputCheckbox
                                name="discountCoupons"
                                id="discountCoupons"
                                onChange={handleChange}
                                checked={plan.discountCoupons}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Depoimentos</td>
                            <td>
                              <InputCheckbox
                                name="testimonials"
                                id="testimonials"
                                onChange={handleChange}
                                checked={plan.testimonials}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Informações sobre o público</td>
                            <td>
                              <InputCheckbox
                                name="audienceInformation"
                                id="audienceInformation"
                                onChange={handleChange}
                                checked={plan.audienceInformation}
                              />
                            </td>
                          </tr>
                          <tr className={styles.header}>
                            <th>Segurança</th>
                            <th></th>
                          </tr>
                          <tr>
                            <td>Limitador de visualizações</td>
                            <td>
                              <InputCheckbox
                                name="viewLimiter"
                                id="viewLimiter"
                                onChange={handleChange}
                                checked={plan.viewLimiter}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Marca D'agua nos PDFs</td>
                            <td>
                              <InputCheckbox
                                name="watermarks"
                                id="watermarks"
                                onChange={handleChange}
                                checked={plan.watermarks}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Duração de acesso do curso</td>
                            <td>
                              <InputCheckbox
                                name="durationOfTheCourse"
                                id="durationOfTheCourse"
                                onChange={handleChange}
                                checked={plan.durationOfTheCourse}
                              />
                            </td>
                          </tr>
                          <tr className={styles.header}>
                            <th>Financeiro</th>
                            <th></th>
                          </tr>
                          <tr>
                            <td>Relatório de vendas</td>
                            <td>
                              <InputCheckbox
                                name="salesReport"
                                id="salesReport"
                                onChange={handleChange}
                                checked={plan.salesReport}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Extrato financeiro</td>
                            <td>
                              <InputCheckbox
                                name="financialStatement"
                                id="financialStatement"
                                onChange={handleChange}
                                checked={plan.financialStatement}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Recebimento cartão</td>
                            <td>
                              <InputCheckbox
                                name="creditCardPayment"
                                id="creditCardPayment"
                                onChange={handleChange}
                                checked={plan.creditCardPayment}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Recebimento boleto</td>
                            <td>
                              <InputCheckbox
                                name="boletoPayment"
                                id="boletoPayment"
                                onChange={handleChange}
                                checked={plan.boletoPayment}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Pagamentos internacionais</td>
                            <td>
                              <InputCheckbox
                                name="internationalPayments"
                                id="internationalPayments"
                                onChange={handleChange}
                                checked={plan.internationalPayments}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Splits automatizados</td>
                            <td>
                              <InputCheckbox
                                name="automatedSplits"
                                id="automatedSplits"
                                onChange={handleChange}
                                checked={plan.automatedSplits}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Saques quando desejar</td>
                            <td>
                              <InputCheckbox
                                name="withdrawalsWhenYouWish"
                                id="withdrawalsWhenYouWish"
                                onChange={handleChange}
                                checked={plan.withdrawalsWhenYouWish}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Antecipações</td>
                            <td>
                              <InputCheckbox
                                name="anticipations"
                                id="anticipations"
                                onChange={handleChange}
                                checked={plan.anticipations}
                              />
                            </td>
                          </tr>
                          <tr className={styles.header}>
                            <th>Serviços</th>
                            <th></th>
                          </tr>
                          <tr>
                            <td>Customer Success</td>
                            <td>
                              <InputCheckbox
                                name="customerSuccess"
                                id="customerSuccess"
                                onChange={handleChange}
                                checked={plan.customerSuccess}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Designer</td>
                            <td>
                              <InputCheckbox
                                name="designer"
                                id="designer"
                                onChange={handleChange}
                                checked={plan.designer}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Descontos (serviços de marketing)</td>
                            <td>
                              <InputCheckbox
                                name="discountsMarketingServices"
                                id="discountsMarketingServices"
                                onChange={handleChange}
                                checked={plan.discountsMarketingServices}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`mt-5 ${styles.button__group}`}>
                <Link to="/internal-manager/plans">Cancelar</Link>
                <button type="submit">{loading ? <DotsFalling /> : 'Salvar'}</button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
}

export default NewPlans;
