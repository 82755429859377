import { axiosBackend } from './Configuration';

export const ContentUserAPI = {
  store: async (content_id, module_id, course_id, newContentUser) => {
    try {
      const { data: contentData } = await axiosBackend().post(
        `/contents/${content_id}/content/${module_id}/module/${course_id}/course/task`,
        newContentUser
      );
      return contentData;
    } catch (error) {
      throw error;
    }
  },

  show: async (content_id, module_id, course_id) => {
    try {
      const { data: contentData } = await axiosBackend().get(
        `/contents/${module_id}/module/${course_id}/course/${content_id}/content/user`
      );
      return contentData;
    } catch (error) {
      throw error;
    }
  },

  taskCorrection: {
    update: async (module_id, course_id, content_id, user_id, taskCorrection) => {
      try {
        const { data: taskData } = await axiosBackend().patch(
          `/contents/${module_id}/module/${course_id}/course/${content_id}/content/${user_id}/user`,
          taskCorrection
        );

        return taskData;
      } catch (error) {
        throw error;
      }
    },
  },
};
