import styled from 'styled-components';

export const Container = styled.a`
  position: relative;

  display: flex;

  img {
    height: 1.2rem;
    width: 1.2rem;
    margin-bottom: 0.5rem;
  }

  &:hover > div {
    position: absolute;
    left: 2.5rem;
    opacity: 1;
    visibility: visible;
  }
`;

export const Content = styled.div`
  position: absolute;
  top: -0.4rem;
  left: 0;
  min-width: 160px;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;

  text-align: center;

  background: rgba(0, 0, 0, 0.8);

  opacity: 0;
  visibility: hidden;

  transition: opacity 100ms, transform 100ms, left 100ms, 380ms ease-in-out;

  display: block;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -0.5rem;
    border-bottom: 6px solid transparent;
    border-right: 8px solid rgba(0, 0, 0, 0.8);
    border-top: 6px solid transparent;

    transform: translateY(-50%);
  }

  span {
    color: #ffffff;
    font-weight: 600;
    font-size: 0.8rem;
  }
`;
