import { Stack, useBreakpointValue } from '@chakra-ui/react';
import { Location } from 'history';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { axiosBackend } from '../../api/Configuration';
import FacebookIntegration from '../../api/FacebookIntegration';
import CookieBanner from '../../components/CookieBanner';
import FullPageLoading from '../../components/FullPageLoading';
import { OrderBump } from '../../components/OrderBump';
import Toast from '../../components/Toast';
import { useAuth } from '../../contexts/AuthContext';
import { UTMData } from '../../contexts/UTMContext';
import ErrorResponse from '../../helpers/ErrorResponse';
import facebookPixelHelper from '../../helpers/FacebookPixel';
import {
  IS_COOKIES_ACCEPTED,
  SHOPPING_CART,
  UTM_LOCAL_STORAGE,
} from '../../helpers/LocalStorageHelper';
import { googleTagManagerIds, newEventGTM, removeEventGTM } from '../../helpers/ga-tag-custom';
import useHotjarScript from '../../hooks/useHotjarScript';
import useQuery from '../../hooks/useQuery';
import useShoppingCart, { IShoppingCart } from '../../hooks/useShoppingCart';
import Container from './Container';
import Login from './Login';
import Payment from './Payment';
import PurchaseSummary from './PurchaseSummary';
import PurchaseSummaryMobile from './PurchaseSummaryMobile';
import Register from './Register';
import CheckoutSkeleton from './Skeleton';

const IS_DEVELOPMENT_ENV = process.env.NODE_ENV === 'development';

const initialState: UTMData = {
  utm_id: null,
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_term: null,
  utm_content: null,
};

export default function CheckoutV3() {
  const [shoppingCartData, setShoppingCartData] = useState<IShoppingCart>({} as IShoppingCart);
  const [isLoading, setIsLoading] = useState(true);
  const [isFreePurchase, setIsFreePurchase] = useState(false);
  const [standardPaymentValue, setStandardPaymentValue] = useState(0);
  const [isPurchaseByPixConfirmed, setIsPurchaseByPixConfirmed] = useState(false);
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [utmData, setUtmData] = useState<UTMData>(initialState);
  const utmDataLocalStorage = localStorage.getItem(UTM_LOCAL_STORAGE);

  useHotjarScript([{ domain: 'presets.liviabrasil.com.br', hjid: 3276099, hjsv: 6 }]);

  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const isDesktopScreen = useBreakpointValue({ base: false, md: false, lg: true, xl: true });

  const query = useQuery();
  const productId = query.get('productId');
  const affiliation = query.get('affiliation');

  const {
    addToShoppingCart,
    getShoppingCartFromLocalStorage,
    clearShoppingCart,
    updateShoppingCartInLocalStorage,
  } = useShoppingCart();

  const purchaseSummaryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const head = document.getElementsByTagName('head').item(0);

    async function insertPixels() {
      const shoppingCartFromLocalStorage = getShoppingCartFromLocalStorage();
      const products = shoppingCartFromLocalStorage?.products.map(product => product.id);

      if (!products?.length) return;

      const { data: pixelData } = await FacebookIntegration.findMany(
        products,
        shoppingCartFromLocalStorage?.affiliation
      );

      if (pixelData?.length) {
        let currentPixel: HTMLElement;
        let script: string;

        if (!IS_DEVELOPMENT_ENV) {
          for (let pixel of pixelData) {
            currentPixel = document.createElement('script');
            currentPixel.id = pixel.identifierPixel;
            currentPixel.setAttribute('prolunofbp', pixel.courseId);

            script = facebookPixelHelper.baseCode(
              currentPixel.id,
              facebookPixelHelper.initiateCheckout(pixel.courseId)
            );

            const pixelExists = head.querySelectorAll(
              `[id="${pixel.identifier}"][prolunofbp="${pixel.courseId}"]`
            );

            if (!pixelExists.length) {
              currentPixel.innerHTML = script;

              head?.appendChild(currentPixel);
            }
          }
        }
      }
    }

    insertPixels();

    return () => {
      const pixels = document.querySelectorAll('[prolunofbp]');

      if (pixels.length) {
        for (let pixel of Array.from(pixels)) {
          head?.removeChild(pixel);
        }
      }
    };
  }, [
    isAuthenticated,
    getShoppingCartFromLocalStorage,
    shoppingCartData.productCart?.length,
    isCookiesAccepted,
  ]);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const idsGTM = googleTagManagerIds();

    let cartProducts = [];

    if (shoppingCartData?.productCart?.length) {
      cartProducts = shoppingCartData?.productCart;

      for (const id of idsGTM) {
        newEventGTM({
          id,
          data: {
            currency: 'BRL',
            event: 'begin_checkout',
            items: cartProducts.map(product => ({
              item_id: product.productId,
              item_name: product.productName,
              value: product.productPrice,
              quantity: 1,
            })),
            total_value: cartProducts.reduce((total, product) => total + product.productPrice, 0),
          },
        });

        removeEventGTM(id, 'begin_checkout');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCookiesAccepted, shoppingCartData?.productCart?.length]);

  useEffect(() => {
    if (productId) {
      try {
        const item = { id: +productId };

        const shoppingCartFromLocalStorage = getShoppingCartFromLocalStorage();

        if (affiliation) {
          clearShoppingCart();
          item['affiliation'] = affiliation;
          item['coupon'] = shoppingCartFromLocalStorage?.coupon;
          item['methodPaymentType'] = shoppingCartFromLocalStorage?.methodPaymentType;
          item['installments'] = shoppingCartFromLocalStorage?.installments;
        }

        updateShoppingCartInLocalStorage(item);
      } catch (error) {
        Toast(error.message, 'error');
      }
    }
  }, [
    affiliation,
    productId,
    clearShoppingCart,
    getShoppingCartFromLocalStorage,
    updateShoppingCartInLocalStorage,
  ]);

  const onChangeShoppingCart = useCallback(async () => {
    const shoppingCartInLocalStorage = getShoppingCartFromLocalStorage();

    if (shoppingCartInLocalStorage) {
      try {
        setIsLoading(true);
        const response = await addToShoppingCart();

        setShoppingCartData(response);

        return response;
      } catch (error) {
        if (!error?.response?.data?.message.includes('Cupom')) {
          Toast(ErrorResponse(error), 'error');
        }

        if (shoppingCartInLocalStorage.coupon && error?.response?.data?.message.includes('Cupom')) {
          const response = await addToShoppingCart();

          setShoppingCartData(response);
          return;
        }

        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  }, [addToShoppingCart, getShoppingCartFromLocalStorage]);

  function onPoleChange(productId: number, poleId: number) {
    setShoppingCartData(prevShoppingCartData => {
      const productCart = prevShoppingCartData.productCart.map(product => {
        if (product.productId === productId) {
          // Se for informado poleId com zero, então é deletado da lista
          if (!poleId) {
            delete product.poleId;
            return product;
          }
          return { ...product, poleId };
        }

        return product;
      });

      return {
        ...prevShoppingCartData,
        productCart,
      };
    });
  }

  function onIsFreePurchaseChange(value: boolean) {
    setIsFreePurchase(value);
  }

  function onStandardPaymentValueChange(value: number) {
    setStandardPaymentValue(value);
  }

  function onIsLoadingChange(value: boolean) {
    setIsLoading(value);
  }

  function onPurchaseByPixConfirmedChange(value: boolean) {
    setIsPurchaseByPixConfirmed(value);
  }

  useEffect(() => {
    onChangeShoppingCart();
  }, [onChangeShoppingCart]);

  const loginOrRegisterToCheckoutWithProducts = useCallback(async () => {
    const shoppingCart = localStorage.getItem(SHOPPING_CART);
    const authenticationCheckout = urlSearchParams.get('added-to-cart-authentication');

    if (!shoppingCart || !authenticationCheckout) return;

    const parsedShoppingCart = JSON.parse(shoppingCart);

    if (parsedShoppingCart.products.length) {
      for (const product of parsedShoppingCart.products) {
        await axiosBackend().post(`/conversions/${product.id}/add-card`);
      }
    }

    urlSearchParams.delete('added-to-cart-authentication');
    history.push({ search: urlSearchParams.toString() });
  }, [history, urlSearchParams]);

  useEffect(() => {
    loginOrRegisterToCheckoutWithProducts();
  }, [loginOrRegisterToCheckoutWithProducts]);

  useEffect(() => {
    if (isAuthenticated) {
      return history.push({
        pathname: '/student-checkout/v3/payment',
        search: window.location.search,
      });
    }

    if (!isAuthenticated && location.pathname === '/student-checkout/v3/login') {
      return history.push({
        pathname: '/student-checkout/v3/login',
        search: window.location.search,
      });
    }

    return history.push({
      pathname: '/student-checkout/v3/register',
      search: window.location.search,
    });
  }, [history, isAuthenticated, location.pathname]);

  useEffect(() => {
    const shoppingCartFromLocalStorage = getShoppingCartFromLocalStorage();
    if (!shoppingCartFromLocalStorage) return history.push('/');
  }, [getShoppingCartFromLocalStorage, history]);

  useEffect(() => {
    if (!isDesktopScreen) return;

    function handleScroll() {
      const header = document.querySelector('.nk-header-fixed');
      const headerHeight = header?.clientHeight;
      const marginTop = headerHeight + 32;

      if (!purchaseSummaryRef?.current?.style) return;

      purchaseSummaryRef.current.style.top = marginTop + 'px';
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isDesktopScreen]);

  useEffect(() => {
    if (!utmDataLocalStorage) return;

    const utmData: {
      utm_id: string;
      utm_source: string;
      utm_medium: string;
      utm_campaign: string;
      utm_term: string;
      utm_content: string;
    } = JSON.parse(utmDataLocalStorage);

    setUtmData(utmData);
  }, [utmDataLocalStorage]);

  const isPurchaseSummaryVisible =
    location.pathname.includes('payment') || location.pathname.includes('login') || isDesktopScreen;

  const isOrderBumpVisible = location.pathname.includes('payment');

  const productsInCart = shoppingCartData?.productCart;

  const isSkeletonVisible = isLoading && !productsInCart?.length;

  const payments = shoppingCartData?.payments;
  const paymentMethod = getShoppingCartFromLocalStorage()?.methodPaymentType;
  const installmentInLocalStorage = getShoppingCartFromLocalStorage()?.installments;
  const hasUserSelectedInstallment = getShoppingCartFromLocalStorage()?.hasUserSelectedInstallment;

  return (
    <Container>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row', xl: 'row' }}
        mt={{ base: 8, xl: 14 }}
        spacing={5}
      >
        <Switch>
          <Route
            path="/student-checkout/v3/login"
            location={{ search: window.location.search } as Location}
          >
            {isSkeletonVisible ? <CheckoutSkeleton /> : <Login />}
          </Route>
          <Route
            path="/student-checkout/v3/register"
            location={{ search: window.location.search } as Location}
          >
            {isSkeletonVisible ? <CheckoutSkeleton /> : <Register />}
          </Route>
          <Route
            path="/student-checkout/v3/payment"
            location={{ search: window.location.search } as Location}
          >
            {isSkeletonVisible ? (
              <CheckoutSkeleton />
            ) : (
              <Payment
                products={productsInCart}
                payments={payments}
                standardPaymentValue={standardPaymentValue}
                isFreePurchase={isFreePurchase}
                isLoading={isLoading}
                isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
                onChangeShoppingCart={onChangeShoppingCart}
                onIsLoadingChange={onIsLoadingChange}
                onPurchaseByPixConfirmedChange={onPurchaseByPixConfirmedChange}
                utmData={utmData}
                shoppingCartData={shoppingCartData}
              />
            )}
          </Route>
        </Switch>

        {isPurchaseSummaryVisible && (
          <Stack
            order={{ base: -1, md: 'initial', lg: 'initial', xl: 'initial' }}
            spacing={{ base: 8, md: 5, lg: 5, xl: 5 }}
            width={{ base: '100%', md: '70%', lg: '70%', xl: '70%' }}
            height="full"
            position={{ xl: 'sticky' }}
            top={0}
            ref={purchaseSummaryRef}
          >
            {isDesktopScreen ? (
              <PurchaseSummary
                products={productsInCart}
                isLoading={isLoading}
                isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
                payments={payments}
                paymentMethod={paymentMethod}
                hasUserSelectedInstallment={hasUserSelectedInstallment}
                currentInstallment={installmentInLocalStorage}
                onChangeShoppingCart={onChangeShoppingCart}
                onPoleChange={onPoleChange}
                onIsFreePurchaseChange={onIsFreePurchaseChange}
                onStandardPaymentValueChange={onStandardPaymentValueChange}
              />
            ) : (
              <PurchaseSummaryMobile
                products={productsInCart}
                isLoading={isLoading}
                isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
                payments={payments}
                paymentMethod={paymentMethod}
                hasUserSelectedInstallment={hasUserSelectedInstallment}
                currentInstallment={installmentInLocalStorage}
                onChangeShoppingCart={onChangeShoppingCart}
                onPoleChange={onPoleChange}
                onIsFreePurchaseChange={onIsFreePurchaseChange}
                onStandardPaymentValueChange={onStandardPaymentValueChange}
              />
            )}

            {isOrderBumpVisible && (
              <OrderBump
                orderBumps={shoppingCartData?.orderBumps}
                updateShoppingCartInLocalStorage={updateShoppingCartInLocalStorage}
                onChangeShoppingCart={onChangeShoppingCart}
              />
            )}
          </Stack>
        )}
      </Stack>

      <FullPageLoading isLoading={isLoading} />

      <CookieBanner />
    </Container>
  );
}
