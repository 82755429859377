import { Table as TableChakra, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { CourseAffiliateType } from '../..';
import TableRow from './TableRow';

interface ITable {
  courseAffiliates: CourseAffiliateType[];
  onOpenAddAffiliate: () => void;
  onOpenRemoveProduct: () => void;
  setCourseAffiliationId: (id: number) => void;
  setProductName: (name: string) => void;
  setProductId: (id: number) => void;
}

function Table({
  courseAffiliates,
  onOpenAddAffiliate,
  onOpenRemoveProduct,
  setCourseAffiliationId,
  setProductName,
  setProductId,
}: ITable) {
  return (
    <TableContainer>
      <TableChakra
        variant="unstyled"
        style={{
          borderCollapse: 'separate',
          borderSpacing: '0 8px',
        }}
      >
        <Thead bgColor="primary.500" color="textColor" h={46}>
          <Tr>
            <Th
              textAlign="center"
              borderLeftRadius={10}
              fontSize="14px"
              fontWeight={500}
              textTransform="none"
            >
              Nome do Produto
            </Th>
            <Th textAlign="center" fontSize="14px" fontWeight={500} textTransform="none">
              Preço
            </Th>
            <Th textAlign="center" fontSize="14px" fontWeight={500} textTransform="none">
              Nº de afiliados
            </Th>
            <Th textAlign="center" fontSize="14px" fontWeight={500} textTransform="none">
              Nº de vendas
            </Th>
            <Th textAlign="center" fontSize="14px" fontWeight={500} textTransform="none">
              Ganhos
            </Th>
            <Th
              textAlign="center"
              borderRightRadius={10}
              fontSize="14px"
              fontWeight={500}
              textTransform="none"
            >
              Ações
            </Th>
          </Tr>
        </Thead>
        <Tbody bgColor="#20212310">
          {courseAffiliates.length > 0 &&
            courseAffiliates.map(courseAffiliate => (
              <TableRow
                key={courseAffiliate.affiliationId}
                courseAffiliate={courseAffiliate}
                onOpenAddAffiliate={onOpenAddAffiliate}
                onOpenRemoveProduct={onOpenRemoveProduct}
                setCourseAffiliationId={setCourseAffiliationId}
                setProductName={setProductName}
                setProductId={setProductId}
              />
            ))}
        </Tbody>
      </TableChakra>
    </TableContainer>
  );
}

export default Table;
