import {
  Avatar,
  Box,
  Center,
  Circle,
  Flex,
  Icon,
  Progress,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
  MdChevronLeft as LeftIcon,
  MdLockOutline as LockIcon,
  MdChevronRight as RightIcon,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import getRouteToShow from '../../../../helpers/getRouteToShow';
import delay from '../../../../utils/delay';
import { Course, IShowcase, ShowcaseCourse, ShowcasePlan } from '../../types';
import { RecurrencePlanTooltip } from './RecurrencePlanTooltip';

function getExpirationDate(date: string) {
  return dayjs(date).format('DD/MM/YYYY');
}

function isExpired(courseStatus: string) {
  return courseStatus === 'EXPIRADO';
}

interface ShowcaseProps {
  products?: Course[];
  showProgressBar?: boolean;
  plans?: ShowcasePlan[];
  showcaseType?: IShowcase['showcaseType'];
  isPreview: boolean;
}

export default function Showcase({
  products,
  showProgressBar,
  plans,
  showcaseType,
  isPreview = false,
}: ShowcaseProps) {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [isToAdjustLastSlide, setIsToAdjustLastSlide] = useState(false);

  const PrevArrow = props => {
    const { onClick, currentSlide } = props;

    if (currentSlide === 0) return null;

    return (
      <Center
        height="80%"
        position="absolute"
        top="50%"
        left="0"
        zIndex="1"
        transform="translateY(-50%)"
        onClick={onClick}
        cursor="pointer"
        _hover={{
          '& > :first-child': { bg: 'blackAlpha.500' },
          '& svg': { transform: 'scale(1.2)' },
        }}
      >
        <Circle p="3px" transition="background-color 0.3s">
          <LeftIcon
            size={32}
            color="#ffffff"
            style={{ transition: 'transform 0.3s ease-in-out' }}
          />
        </Circle>
      </Center>
    );
  };

  const NextArrow = props => {
    const { onClick } = props;

    async function adjustLastSlide() {
      await delay(150);
      setIsToAdjustLastSlide(true);
    }

    if (!onClick) {
      adjustLastSlide();
      return null;
    }

    setIsToAdjustLastSlide(false);

    return (
      <Center
        height="80%"
        position="absolute"
        top="50%"
        right="0"
        zIndex="1"
        transform="translateY(-50%)"
        onClick={onClick}
        cursor="pointer"
        _hover={{
          '& > :first-child': { bg: 'blackAlpha.500' },
          '& svg': { transform: 'scale(1.2)' },
        }}
      >
        <Circle p="3px">
          <RightIcon
            size={32}
            color="#ffffff"
            style={{ transition: 'transform 0.3s ease-in-out' }}
          />
        </Circle>
      </Center>
    );
  };

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 750,
    slidesToShow: 5.25,
    slidesToScroll: 4,
    autoplay: false,
    prevArrow: !isMobile && <PrevArrow />,
    nextArrow: !isMobile && <NextArrow />,
    useTransform: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4.75,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1268,
        settings: {
          slidesToShow: 3.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.55,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function getRedirectLink(course: ShowcaseCourse['course']) {
    if (course.lock) {
      return `/product/${course.id}`;
    }

    if (isExpired(course.statusCourse)) {
      return `/product/${course.id}/extend`;
    }

    if (course.hasActiveModulesPage) {
      return `/members-area/v2/course/${course.id}/modules`;
    }

    const firstModule = course.modules?.[0];
    const firstContent = firstModule?.firstContent;

    const moduleId = course?.lastModuleId ?? firstModule.id;
    const contentId = course.lastContentId ?? firstContent.id;

    const path = getRouteToShow(course.lastContentType ?? firstContent.contentType);

    return `/members-area/v2/course/${course.id}/module/${moduleId}/content/${contentId}/content-view/${path}`;
  }

  // VITRINE DE PLANOS PADRÃO
  if (showcaseType === 'plans') {
    return (
      <Box
        as="section"
        sx={{
          '.slick-track': {
            marginLeft: 0,
            marginRight: 0,
          },
        }}
      >
        <Slider {...settings}>
          {plans.map(plan => (
            <Box
              key={plan.id}
              ml={{ base: 4, lg: isToAdjustLastSlide ? '-0.25rem' : '5.5rem' }}
              pr={{ base: 3.5, lg: 5 }}
              pointerEvents={isPreview ? 'none' : 'initial'}
              position="relative"
            >
              <Flex
                as={Link}
                to={`/recurrence-checkout/${plan.id}/personal-data`}
                flex={1}
                h="full"
                direction="column"
                borderRadius={10}
                overflow="hidden"
                boxShadow="0px 4px 4px 0px #00000040"
                width={{ base: '8.4375rem', lg: '16.875rem' }}
              >
                <Box
                  w="full"
                  position="relative"
                  height={{ base: '10.546875rem', lg: '21.09375rem' }}
                >
                  <Avatar
                    name={plan?.recurrencePlan?.name}
                    src={plan?.imageDesktop}
                    borderRadius={0}
                    bg="primary.500"
                    color="secondary.500"
                    sx={{ '.chakra-avatar__initials': { fontSize: '7xl', fontWeight: 900 } }}
                    boxSize="full"
                  />

                  {/* <Image
                    display={{ base: 'block', lg: 'none !important' }}
                    src={isMobile && plan.imageMobile ? plan.imageMobile : plan.imageDesktop}
                    alt={plan.titleHighlight}
                    boxSize="100%"
                    maxWidth="100%"
                    objectFit="cover"
                  /> */}
                </Box>

                <Box px={5} py={{ base: 2.5, lg: 5 }} bg="#37393D">
                  <Text
                    align="center"
                    fontSize={{ base: '0.5rem', lg: 'md' }}
                    color="#ffffff"
                    isTruncated
                  >
                    {plan.titleHighlight}
                  </Text>
                </Box>
              </Flex>
            </Box>
          ))}
        </Slider>
      </Box>
    );
  }

  // VITRINE DE PRODUTOS
  return (
    <Box
      as="section"
      sx={{
        '.slick-track': {
          marginLeft: 0,
          marginRight: 0,
        },
      }}
    >
      <Slider {...settings}>
        {products.map(product => (
          <Box
            key={product.id}
            ml={{ base: 4, lg: isToAdjustLastSlide ? '-0.25rem' : '5.5rem' }}
            pr={{ base: 3.5, lg: 5 }}
            pointerEvents={isPreview ? 'none' : 'initial'}
            position="relative"
          >
            {/* Caso o produto esteja incluso em um plano de recorrência */}
            {!!product.courseRecurrencePlanName && (
              <RecurrencePlanTooltip recurrencePlanName={product.courseRecurrencePlanName} />
            )}

            <Flex
              as={Link}
              to={getRedirectLink(product)}
              flex={1}
              h="full"
              direction="column"
              borderRadius={10}
              overflow="hidden"
              boxShadow="0px 4px 4px 0px #00000040"
              width={{ base: '8.4375rem', lg: '16.875rem' }}
            >
              <Box
                w="full"
                position="relative"
                height={{ base: '10.546875rem', lg: '21.09375rem' }}
              >
                <Avatar
                  name={product.name}
                  borderRadius={0}
                  src={product.bannerCourseNetflix ?? product.thumbnail}
                  bg="primary.500"
                  color="secondary.500"
                  sx={{ '.chakra-avatar__initials': { fontSize: '7xl', fontWeight: 900 } }}
                  boxSize="full"
                />

                {!isPreview && (
                  <>
                    {product.lock && (
                      <Center position="absolute" inset={0}>
                        <Circle size={{ base: '2rem', lg: '4rem' }} bg="#20212380" color="white">
                          <Icon as={LockIcon} boxSize={{ base: 4, lg: 8 }} />
                        </Circle>
                      </Center>
                    )}

                    {isExpired(product.statusCourse) && (
                      <Center
                        position="absolute"
                        backgroundColor="#202123BF"
                        filter="grayscale(100%)"
                        inset={0}
                      >
                        <Flex direction="column" align="center">
                          <Circle size={{ base: '2rem', lg: '4rem' }} bg="#20212380" color="white">
                            <Icon as={LockIcon} boxSize={{ base: 4, lg: 8 }} />
                          </Circle>

                          <Text
                            fontSize={{ base: 'xs', lg: 'sm' }}
                            color="white"
                            maxWidth="8.5rem"
                            textAlign="center"
                          >
                            Expirado em: {getExpirationDate(product.expiresIn)}
                          </Text>
                        </Flex>
                      </Center>
                    )}
                  </>
                )}
              </Box>

              {showProgressBar && (
                <Progress
                  size="sm"
                  value={product.progress}
                  height="3.5px"
                  bg="#37393D"
                  sx={{ '> div': { backgroundColor: 'secondary.500' } }}
                />
              )}

              <Box px={5} py={{ base: 2.5, lg: 5 }} bg="#37393D">
                <Text
                  align="center"
                  fontSize={{ base: '0.5rem', lg: 'md' }}
                  color="#ffffff"
                  isTruncated
                >
                  {isExpired(product.statusCourse) ? 'Estender Acesso' : product.name}
                </Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
