import { Link, Text } from '@chakra-ui/react';
import { createContext } from 'react';
import { IS_REACT_APP_HOSTNAME } from '../../App';
import { AuthContext } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import useWindowSize from '../../hooks/useWindowSize';
import FooterHomeAdmin from '../../pages/HomeAdmin/FooterHomeAdmin';
import { routesWithoutHeaderAndFooter } from '../../routes/AdminRoutes';
import Footer from '../FooterSite';
import DotFalling from '../Loadings/DotsFalling';
import SidebarSite from '../SidebarSite/SidebarSite';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

const LARGE_SCREEN_SIZE = 1024;
interface IContainerSiteProps {
  children: React.ReactNode;
}

export const AuthenticationContext = createContext(null);

function ContainerSite({ children }: IContainerSiteProps) {
  const hostname = window.location.hostname;
  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();
  const { width } = useWindowSize();

  const withoutSidebar = routesWithoutHeaderAndFooter.some(route =>
    window.location.pathname?.includes(route)
  );

  if (isLoadingThemeMarketplace) {
    return (
      <div className="d-flex justify-content-center mt-5">
        <DotFalling />
      </div>
    );
  }

  return (
    <AuthContext.Consumer>
      {props => {
        props.verifyAuthentication();

        return (
          <div style={{ position: 'relative', backgroundColor: '#FFF' }}>
            <div className="nk-wrap">
              {width <= LARGE_SCREEN_SIZE && !withoutSidebar && (
                <HeaderMobile
                  themeMarketplace={themeMarketplace}
                  isAuthenticated={props.isAuthenticated}
                />
              )}

              {width > LARGE_SCREEN_SIZE && !withoutSidebar && (
                <HeaderDesktop
                  themeMarketplace={themeMarketplace}
                  isAuthenticated={props.isAuthenticated}
                />
              )}

              <AuthenticationContext.Provider value={props.verifyAuthentication}>
                {children}
              </AuthenticationContext.Provider>
            </div>
            <SidebarSite />

            {withoutSidebar ? (
              <Link
                display="flex"
                width="full"
                alignItems="center"
                justifyContent="center"
                padding="20px"
                href="https://proluno.me/"
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Text>Tecnologia 🧡 Proluno - Ltda.</Text>
              </Link>
            ) : IS_REACT_APP_HOSTNAME(hostname) ? (
              <Footer />
            ) : (
              <FooterHomeAdmin />
            )}
          </div>
        );
      }}
    </AuthContext.Consumer>
  );
}

export default ContainerSite;
