import { Heading, HStack, IconButton, VStack, Text } from '@chakra-ui/react';
import { MdOutlineChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

export interface RecurrencePaymentHistoryHeaderProps {
  subscriberName: string;
  isEmpty: boolean;
}

export default function RecurrencePaymentHistoryHeader({
  subscriberName,
  isEmpty,
}: RecurrencePaymentHistoryHeaderProps) {
  const { goBack } = useHistory();

  return (
    <VStack w="100%" spacing={10} align="start">
      <HStack w="100%" spacing={{ base: 0.25, xl: 0.5 }} ml={{ base: -2, xl: 0 }}>
        <IconButton
          onClick={() => goBack()}
          icon={<ChevronLeftIcon size="1.75rem" />}
          aria-label="Voltar"
          variant="ghost"
          colorScheme="gray"
          size="sm"
        />

        <Heading as="h1" fontSize={{ base: 'lg', lg: '2xl' }} fontWeight="semibold" m={0}>
          Histórico de pagamento - {subscriberName}
        </Heading>
      </HStack>

      {isEmpty && (
        <Text fontSize="sm" color="#0000007D">
          Você ainda não possui assinantes neste plano.
        </Text>
      )}
    </VStack>
  );
}
