import { Avatar, Box, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ActionButton, CourseAffiliateType } from '../../../..';
import convertToBrazilianCurrency from '../../../../../../../../helpers/convertToBrazilianCurrency';

export type CardType = {
  courseAffiliate: CourseAffiliateType;
  onOpenAddAffiliate: () => void;
  onOpenRemoveProduct: () => void;
  setCourseAffiliationId: (id: number) => void;
  setProductName: (name: string) => void;
  setProductId: (id: number) => void;
};

function Card({
  courseAffiliate,
  onOpenAddAffiliate,
  onOpenRemoveProduct,
  setCourseAffiliationId,
  setProductName,
  setProductId,
}: CardType) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      background="#FFFFFF"
      boxShadow="1px 1px 4px rgba(0, 0, 0, 0.25)"
      borderRadius="6px"
      py={4}
      my={3}
    >
      <Stack
        direction="column"
        alignItems="center"
        mx={3}
        as={Link}
        to={`/affiliations/products/${courseAffiliate.courseAffiliationId}/edit`}
      >
        {!courseAffiliate.thumbnail ? (
          <Avatar
            name={courseAffiliate.productName}
            colorScheme="primary"
            color="textColor"
            boxSize={330}
            borderRadius={5}
            objectFit="contain"
          />
        ) : (
          <Image
            src={courseAffiliate.thumbnail}
            boxSize={330}
            borderRadius={5}
            objectFit="contain"
          />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mx={5} my={2}>
        <Heading fontSize="md" w="100%" fontWeight={500} isTruncated>
          {courseAffiliate.productName}
        </Heading>
        <ActionButton
          courseAffiliateId={courseAffiliate.courseAffiliationId}
          productId={courseAffiliate.productId}
          productName={courseAffiliate.productName}
          onOpenAddAffiliate={onOpenAddAffiliate}
          onOpenRemoveProduct={onOpenRemoveProduct}
          setCourseAffiliationId={setCourseAffiliationId}
          setProductName={setProductName}
          setProductId={setProductId}
        />
      </Stack>

      <Stack direction="column" spacing={5} mx={5}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text color="#20212390" fontWeight={600}>
            Preço:
          </Text>
          <Stack direction="column" alignItems="center" spacing={0} fontWeight={500}>
            <Text fontSize="xs" color="#20212390">
              {convertToBrazilianCurrency(courseAffiliate.productValue)}
            </Text>
            <Text fontSize="xx-small" color="#20212390">
              <Text as="span" color="#22BB33">{`${courseAffiliate.commissionValue}%`}</Text> de
              Comissão
            </Text>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text color="#20212390" fontWeight={600}>
            Nº de afiliados:
          </Text>
          <Text color="#20212390" fontWeight={500}>
            {courseAffiliate.amountOfAffiliates}
          </Text>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text color="#20212390" fontWeight={600}>
            Nº de vendas:
          </Text>
          <Text color="#20212390" fontWeight={500}>
            {courseAffiliate.amountOfSales}
          </Text>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Text color="#20212390" fontWeight={600}>
            Ganhos:
          </Text>
          <Text color="#22BB33" fontWeight={500}>
            {convertToBrazilianCurrency(courseAffiliate.winnings)}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Card;
