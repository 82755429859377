import { useDisclosure, useToast } from '@chakra-ui/react';
import path from 'path';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../../helpers/UploadHelper';
import useFetch from '../../../../../hooks/useFetch';
import useHandleChange from '../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../utils/scrollToInvalidInput';
import schemaValidator from './schemaValidator';

import {
  ICheckoutSettingsEditCustomization,
  useCheckoutSettingsEditCustomizationProps,
} from './types';

const FIVE_MEGABYTES = 5 * 1024 * 1024;

interface ImageFile {
  preview: string;
  file?: File;
}

export default function useCheckoutSettingsEditCustomization({
  onIsLoadingChange,
}: useCheckoutSettingsEditCustomizationProps) {
  const [desktopTopImage, setDesktopTopImage] = useState<ImageFile>(null);
  const [mobileTopImage, setMobileTopImage] = useState<ImageFile>(null);
  const [desktopBottomImage, setDesktopBottomImage] = useState<ImageFile>(null);
  const [mobileBottomImage, setMobileBottomImage] = useState<ImageFile>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageFieldToDelete, setImageFieldToDelete] = useState('');

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const toast = useToast();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: response, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<ICheckoutSettingsEditCustomization>
  >({
    method: 'get',
    url: `/course-checkout-settings/${id}/customization`,
    authenticated: true,
    autoFetch: true,
  });

  const { fetchData: deleteImage, loading: isDeletingImage } = useFetch({
    method: 'patch',
    url: `/course-checkout-settings/${id}/remove-image`,
    authenticated: true,
    autoFetch: false,
    data: { imageToDelete: imageFieldToDelete },
  });

  const registeredCustomizationSettings = response?.data;

  useEffect(() => {
    onIsLoadingChange(isLoading);
  }, [isLoading, onIsLoadingChange]);

  const {
    form,
    setForm,
    onChanged: hasChange,
    setOnChanged: setHasChange,
    handleCancel,
    handleChange,
  } = useHandleChange<ICheckoutSettingsEditCustomization>(
    {},
    registeredCustomizationSettings && {
      countdown: registeredCustomizationSettings.countdown ?? 0,
      desktopTopImage: registeredCustomizationSettings.desktopTopImage ?? '',
      mobileTopImage: registeredCustomizationSettings.mobileTopImage ?? '',
      desktopBottomImage: registeredCustomizationSettings.desktopBottomImage ?? '',
      mobileBottomImage: registeredCustomizationSettings.mobileBottomImage ?? '',
      useTopDesktopImageOnMobile:
        registeredCustomizationSettings.useTopDesktopImageOnMobile ?? false,
      useBottomDesktopImageOnMobile:
        registeredCustomizationSettings.useBottomDesktopImageOnMobile ?? false,
    }
  );

  function handleCancelButtonClick() {
    if (!hasChange) {
      return history.push({
        pathname: `/checkout-settings/edit/${id}/payment`,
        search: location.search,
      });
    }

    handleCancel();
  }

  async function uploadFile(file: File) {
    return await UploadHelper.upload(file, 'banners');
  }

  function handleUploadError(error) {
    toast({
      title: ErrorResponse(error),
      status: 'error',
    });
  }

  async function handleDropFile(
    name: 'desktopTopImage' | 'mobileTopImage' | 'desktopBottomImage' | 'mobileBottomImage',
    acceptedFiles
  ) {
    try {
      setHasChange(true);
      setIsUploading(true);

      const [file] = acceptedFiles;

      if (file.size > FIVE_MEGABYTES) {
        return toast({
          title: 'Imagem muito grande!',
          description: 'O tamanho máximo deve ser igual ou inferior a 5 MB.',
          status: 'error',
          position: 'top',
        });
      }

      const preview = URL.createObjectURL(file);

      const { newFileName } = await uploadFile(file);

      setForm({ ...form, [name]: newFileName });

      switch (name) {
        case 'desktopTopImage':
          setDesktopTopImage({ preview, file });
          break;
        case 'mobileTopImage':
          setMobileTopImage({ preview, file });
          break;
        case 'desktopBottomImage':
          setDesktopBottomImage({ preview, file });
          break;
        case 'mobileBottomImage':
          setMobileBottomImage({ preview, file });
          break;
        default:
          break;
      }
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
    }
  }

  function handleDropRejected() {
    toast({
      title: 'Arquivo inválido!',
      description: 'Formatos suportados: JPG, PNG, JPEG',
      status: 'error',
    });
  }

  function handleSelectNewImage(
    name: 'desktopTopImage' | 'mobileTopImage' | 'desktopBottomImage' | 'mobileBottomImage'
  ) {
    setHasChange(true);

    setForm({ ...form, [name]: '' });
  }

  function handleOpenImageDeleteModal(
    imageField: 'desktopTopImage' | 'mobileTopImage' | 'desktopBottomImage' | 'mobileBottomImage'
  ) {
    onOpen();
    setImageFieldToDelete(imageField);
  }

  async function handleDeleteImage() {
    try {
      await deleteImage();
      toast({
        title: 'Imagem excluída com sucesso!',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
      });
    }

    setForm({ ...form, [imageFieldToDelete]: '' });

    onClose();
  }

  const payload = { ...form };

  payload.desktopTopImage && (payload.desktopTopImage = path.basename(payload.desktopTopImage));
  payload.mobileTopImage && (payload.mobileTopImage = path.basename(payload.mobileTopImage));
  payload.desktopBottomImage &&
    (payload.desktopBottomImage = path.basename(payload.desktopBottomImage));
  payload.mobileBottomImage &&
    (payload.mobileBottomImage = path.basename(payload.mobileBottomImage));

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: payload,
    url: `/course-checkout-settings/${id}/customization`,
    method: 'patch',
    authenticated: true,
    schemaValidator,
    onSuccess: {
      callback: () =>
        history.push({
          pathname: `/checkout-settings/edit/${id}/page-thanks`,
          search: location.search,
        }),
    },
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    if (!hasChange) {
      history.push({
        pathname: `/checkout-settings/edit/${id}/page-thanks`,
        search: location.search,
      });
      return;
    }

    scrollToInvalidInput();

    await handleSubmit();
  }

  return {
    form,
    isSubmitting,
    formValidation,
    desktopTopImage,
    mobileTopImage,
    desktopBottomImage,
    mobileBottomImage,
    isUploading,
    isDeletingImage,
    isOpen,
    onClose,
    handleOpenImageDeleteModal,
    handleSelectNewImage,
    handleDeleteImage,
    handleChange,
    handleCancelButtonClick,
    handleDropRejected,
    handleDropFile,
    onSubmit,
  };
}
