import {
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';

import { ptBR } from 'date-fns/locale';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useState } from 'react';
import { DateRangePicker as ReactDateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import CalendarIcon from './CalendarIcon';
import './styles.css';
import useDateRangePickerCustomStyles from './useDateRangePickerCustomStyles';

type GetRangeTextsType = {
  selection: {
    startDate: Date;
    endDate: Date;
  };
};

function formatDate(date: Date | string) {
  return dayjs(date).format('YYYY-MM-DD');
}

function getRangeText(ranges: GetRangeTextsType, selectPlaceholder = 'Selecione um período') {
  const startDate = dayjs(ranges.selection.startDate)?.format('DD/MM/YYYY');
  const endDate = dayjs(ranges.selection.endDate)?.format('DD/MM/YYYY');

  if (startDate === endDate) {
    return startDate;
  }

  return `${startDate} - ${endDate}` || selectPlaceholder;
}

interface DateRangePickerProps {
  startDate?: string;
  endDate?: string;
  hideIcon?: boolean;
  hideSelect?: boolean;
  selectPlaceholder?: string;
  iconColor?: string;
  onChange: (startDate: string, endDate: string) => void;
}

function getInitialState(startDate: string, endDate: string) {
  return [
    {
      startDate: startDate && dayjs(startDate).toDate(),
      endDate: endDate && dayjs(endDate).toDate(),
      key: 'selection',
    },
  ];
}

export default function DateRangePicker({
  startDate,
  endDate,
  hideIcon = false,
  hideSelect = false,
  selectPlaceholder = 'Selecione um período',
  iconColor = '#20212380',
  onChange,
}: DateRangePickerProps) {
  const [state, setState] = useState(getInitialState(startDate, endDate));
  const [startDateState, setStartDateState] = useState('');
  const [endDateState, setEndDateState] = useState('');
  const [rangeText, setRangeText] = useState('');
  const textColor = useColorModeValue('#202123', '#FFFFFF');
  const bgColor = useColorModeValue('#FFFFFF', '#37393D');

  const { colors } = useTheme();
  const grayColor = colors.gray[300];

  const { isOpen, onOpen, onClose } = useDisclosure();
  useDateRangePickerCustomStyles();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (startDate && endDate) {
        setStartDateState(startDate);
        setEndDateState(endDate);
        setRangeText(
          getRangeText(
            {
              selection: { startDate: dayjs(startDate).toDate(), endDate: dayjs(endDate).toDate() },
            },
            selectPlaceholder
          )
        );
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [startDate, endDate, selectPlaceholder]);

  useEffect(() => {
    setState([
      {
        startDate: startDate && dayjs(startDate).toDate(),
        endDate: endDate && dayjs(endDate).toDate(),
        key: 'selection',
      },
    ]);
  }, [startDate, endDate]);

  function handleDateRangePickerChange(ranges) {
    setState([ranges.selection]);
    setRangeText(getRangeText(ranges, selectPlaceholder));
    onChange(formatDate(ranges.selection.startDate), formatDate(ranges.selection.endDate));
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    if (name === 'startDate') {
      setStartDateState(value);
    } else {
      setEndDateState(value);
    }

    if (!value) return;

    if (dayjs(value).isValid()) {
      const ranges = {
        selection: {
          ...state[0],
          [name]: dayjs(value).toDate(),
        },
      };

      handleDateRangePickerChange(ranges);
    }
  }

  const rightIcon = rangeText && (
    <CloseButton
      size="sm"
      onClick={() => {
        setRangeText('');
        onChange('', '');
        onClose();
      }}
    />
  );

  const isArrowIconVisible = !startDate && !endDate;

  function getArrowIcon() {
    if (!isArrowIconVisible) return;

    if (isOpen) return <IoChevronUp />;

    return <IoChevronDown />;
  }

  return (
    <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {!hideIcon && (
        <MenuButton m={0} as={IconButton} colorScheme="gray" variant="ghost" borderRadius="full">
          <CalendarIcon color={iconColor} />
        </MenuButton>
      )}

      {!hideSelect && (
        <MenuButton
          as={Button}
          variant="outline"
          rightIcon={rightIcon}
          flexShrink={0}
          fontWeight="normal"
          fontSize="sm"
          color="gray.500"
          size="sm"
          minW="14rem"
          textAlign="start"
          _expanded={{ borderColor: 'gray.300', boxShadow: `0 0 0 1px ${grayColor}` }}
          _focus={{ borderColor: 'gray.300', boxShadow: `0 0 0 1px ${grayColor}` }}
          _focusWithin={{ borderColor: 'gray.300', boxShadow: `0 0 0 1px ${grayColor}` }}
          _hover={{
            bg: 'transparent',
            borderColor: 'gray.300',
          }}
          _active={{ bg: 'transparent' }}
        >
          <Flex justify="space-between" align="center">
            {rangeText || selectPlaceholder}

            {getArrowIcon()}
          </Flex>
        </MenuButton>
      )}

      <MenuList
        boxShadow="0px 4px 4px 0px #00000040"
        p={0}
        overflow="hidden"
        position="relative"
        sx={{
          '&:before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '1px',
            height: '100%',
            backgroundColor: { textColor },
          },
        }}
      >
        <HStack p={4} spacing={8} bgColor={bgColor}>
          <FormControl>
            <FormLabel fontSize="xs" color={textColor}>
              Data inicial
            </FormLabel>
            <Input
              name="startDate"
              value={startDateState}
              onChange={handleChange}
              size="sm"
              focusBorderColor="gray.300"
              type="date"
              min="2020-01-01"
              max={dayjs().format('YYYY-MM-DD')}
              tabIndex={1}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontSize="xs" color={textColor}>
              Data final
            </FormLabel>
            <Input
              name="endDate"
              value={endDateState}
              onChange={handleChange}
              size="sm"
              focusBorderColor="gray."
              type="date"
              min="2020-01-01"
              max={dayjs().format('YYYY-MM-DD')}
              tabIndex={2}
            />
          </FormControl>
        </HStack>

        <ReactDateRangePicker
          onChange={handleDateRangePickerChange}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
          showDateDisplay={false}
          locale={ptBR}
          weekdayDisplayFormat="EEEEE"
          monthDisplayFormat="MMMM yyyy"
          rangeColors={['#ec7117']}
          color="#ec7117"
        />
      </MenuList>
    </Menu>
  );
}
