import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Link,
  Spinner,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  MdAdd as AddIcon,
  MdFullscreen as FullscreenIcon,
  MdKeyboardArrowLeft as ArrowLeftIcon,
  MdKeyboardArrowRight as ArrowRightIcon,
  MdOutlineGetApp as DownloadIcon,
  MdRemove as MinusIcon,
  MdFullscreenExit as FullscreenExitIcon,
} from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { baseURLBackend } from '../../../../../../api/Configuration';
import { TOKEN_LOCAL_STORAGE } from '../../../../../../helpers/LocalStorageHelper';
import { IParams } from '../../types';

const scrollbarStyle = {
  '::-webkit-scrollbar': {
    width: '0.45rem',
    height: '0.45rem',
    background: 'transparent',
  },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'gray.300',
  },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: 'md',
    backgroundColor: 'gray.200',
  },
};

const documentStyle = {
  canvas: { maxWidth: 'none !important' },
  '.react-pdf__Document': {
    minHeight: 'unset',
  },
};

export default function PDFViewer({ pdfUrl, hasDownload, highlightColor }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [orientation, setOrientation] = useState({ width: null, height: null });
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false });

  const { courseId, moduleId, contentId } = useParams<IParams>();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    const pdfDocument = pdfjs.getDocument(pdfUrl);
    pdfDocument.promise.then(pdf => {
      pdf.getPage(1).then(page => {
        const { width, height } = page.getViewport({ scale: 1 });

        if (isMobile) {
          return setOrientation({
            width: 330,
            height,
          });
        }
        setOrientation({ width, height });
      });
    });
  }

  function handleZoomIn() {
    setScale(Math.min(scale + 0.1, 2));
  }

  function handleZoomOut() {
    setScale(prevScale => Math.max(prevScale - 0.1, 0.1));
  }

  const handlePrevPage = useCallback(() => {
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
  }, [numPages]);

  function toggleFullscreen() {
    setIsFullscreen(prevState => {
      // Reseta o zoom ao voltar para tela cheia
      if (prevState) {
        setScale(1);
      }

      return !prevState;
    });
  }

  // Implementação de teclas de atalho
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'ArrowLeft') {
        handlePrevPage();
      }

      if (event.key === 'ArrowRight') {
        handleNextPage();
      }

      if (event.key === 'Escape' && isFullscreen) {
        toggleFullscreen();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleNextPage, handlePrevPage, isFullscreen]);

  const containerRef = useRef(null);
  const outContainerRef = useRef(null);

  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
  const linkToDownload = `${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${token}`;

  return (
    <Stack
      spacing={{ base: '9px', md: '13px' }}
      ref={outContainerRef}
      position={isFullscreen ? 'fixed' : 'relative'}
      top={0}
      left={0}
      width="100%"
      height={isFullscreen ? '100vh' : 'auto'}
      zIndex={isFullscreen ? 1000 : 'auto'}
      bgColor={isFullscreen ? '#37393D' : 'transparent'}
    >
      <HStack
        width="full"
        height="40px"
        padding={{ base: '5px', md: '12px', lg: '12px' }}
        boxShadow="0px 0px 10px 0px #0000001A"
        borderRadius="base"
        bgColor="#37393D"
        justify="space-between"
        position="relative"
        spacing={0}
      >
        <HStack spacing={{ base: 0.25, xl: 2 }}>
          <IconButton
            aria-label="Diminuir zoom"
            onClick={handleZoomOut}
            icon={<MinusIcon size={8.75} />}
            size="xs"
            variant="ghost"
            color="#FFFFFF"
            _hover={{ bgColor: 'transparent' }}
            isDisabled={scale < 0.2}
          />

          <Box
            borderRadius="6px"
            border="1px solid rgba(255, 255, 255, 0.52)"
            background="#37393D"
            padding={{ base: '4px 10px', md: '4px 12px', lg: '4px 12px' }}
            color="#FFFFFF"
            fontSize={{ base: '0.6875rem', xl: '13px' }}
          >
            {(scale * 100).toFixed(0)}%
          </Box>

          <IconButton
            aria-label="Aumentar zoom"
            onClick={handleZoomIn}
            icon={<AddIcon size={15} />}
            size="xs"
            variant="ghost"
            color="#FFFFFF"
            _hover={{ bgColor: 'transparent' }}
            isDisabled={scale === 2}
          />
        </HStack>

        <HStack
          justify="center"
          spacing={{ base: 0.5, xl: 4 }}
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
        >
          <IconButton
            aria-label="Página anterior"
            onClick={handlePrevPage}
            icon={<ArrowLeftIcon size={26} />}
            size="xs"
            variant="ghost"
            color="#FFFFFF"
            _hover={{ bgColor: 'transparent' }}
          />

          <Text fontSize="xs" color="#FFFFFF">
            Página {pageNumber} de {numPages}
          </Text>

          <IconButton
            aria-label="Próxima página"
            onClick={handleNextPage}
            icon={<ArrowRightIcon size={26} />}
            size="xs"
            variant="ghost"
            color="#FFFFFF"
            _hover={{ bgColor: 'transparent' }}
          />
        </HStack>

        <HStack>
          {hasDownload && (
            <Box>
              <Link
                hidden={!isMobile}
                href={linkToDownload}
                download
                target="_blank"
                _hover={{ textDecoration: 'none' }}
              >
                <Button
                  variant="ghost"
                  size="sm"
                  fontWeight="normal"
                  _hover={{ bgColor: 'transparent' }}
                  color="#FFFFFF"
                >
                  <DownloadIcon />
                </Button>
              </Link>

              <Link
                hidden={isMobile}
                href={linkToDownload}
                download
                target="_blank"
                _hover={{ textDecoration: 'none' }}
              >
                <Button
                  _hover={{ bgColor: 'transparent' }}
                  color="#FFFFFF"
                  variant="ghost"
                  size="sm"
                  fontSize="13px"
                  fontWeight="normal"
                  rightIcon={<DownloadIcon />}
                >
                  Download
                </Button>
              </Link>
            </Box>
          )}

          <IconButton
            aria-label="Tela cheia"
            onClick={toggleFullscreen}
            icon={isFullscreen ? <FullscreenExitIcon size={26} /> : <FullscreenIcon size={26} />}
            size="xs"
            variant="ghost"
            color="#FFFFFF"
            _hover={{ bgColor: 'transparent' }}
          />
        </HStack>
      </HStack>

      <VStack
        width="full"
        bgColor={isFullscreen ? '#37393D' : { base: 'transparent', md: '#37393D', lg: '#37393D' }}
        padding={{ base: '12px', md: '0', lg: '0' }}
        overflow="auto"
        sx={scrollbarStyle}
        height={isFullscreen ? 'calc(100vh - 40px)' : 'auto'}
      >
        <VStack ref={containerRef} sx={documentStyle}>
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <Center height="auto" bgColor="#37393D">
                <Spinner size="xl" color={highlightColor} />
              </Center>
            }
          >
            <Page pageNumber={pageNumber} scale={scale} width={orientation.width} />
          </Document>
        </VStack>
      </VStack>
    </Stack>
  );
}
