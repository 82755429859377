import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  List,
  ListItem,
  Text,
  useDisclosure,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { MdAdd as AddIcon } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { axiosBackend } from '../../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import useFetch from '../../../../../../../hooks/useFetch';
import QuestionFormModal from './QuestionFormModal';

export interface Question {
  id?: number;
  question: string;
  answer: string;
}

interface QuestionListResponse {
  frequentlyQuestions: Question[];
}

export default function FAQ() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { planId } = useParams<{ planId: string }>();
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const toast = useToast();

  const {
    data: response,
    fetchData,
    loading: isFetching,
  } = useFetch<UnificadaFront.ResponseJSON<QuestionListResponse>>({
    method: 'get',
    url: `/recurrence-plan/${planId}/frequently-questions`,
    authenticated: true,
    autoFetch: true,
  });

  async function handleQuestionDelete(questionId: number) {
    setIsDeleting(true);

    try {
      await axiosBackend().delete(`/recurrence-plan/${planId}/frequently-questions/${questionId}`);
      toast({
        title: 'Pergunta excluída com sucesso',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
      });
    } finally {
      setIsDeleting(false);
      await fetchData();
    }
  }

  const questions = response?.data?.frequentlyQuestions || [];

  function handleQuestionToEditClick(question: Question) {
    setSelectedQuestion(question);
    onOpen();
  }

  async function handleClose() {
    setSelectedQuestion(null);
    onClose();
  }

  const isLoading = isFetching || isDeleting;

  return (
    <>
      <VStack align="start" mt={10} w="100%" spacing={4}>
        <Box>
          <Heading as="h2" color="#202123" fontSize="xl" fontWeight="medium" m={0}>
            Perguntas frequentes
          </Heading>

          <Text color="#20212380" fontSize="sm" mt={2}>
            Crie uma seção de perguntas e respostas do seu plano. Use essa função para tirar a maior
            quantidade de dúvidas dos seus alunos.{' '}
          </Text>
        </Box>

        <Button
          onClick={onOpen}
          w="100%"
          variant="outline"
          colorScheme="primary"
          leftIcon={<AddIcon />}
          size="sm"
          isLoading={isLoading}
        >
          Adicionar pergunta
        </Button>
      </VStack>

      {!isLoading && questions.length > 0 && (
        <Accordion allowToggle mt={6} as={List} w="100%">
          {questions.map(question => (
            <AccordionItem key={question.id} as={ListItem}>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="medium">
                  {question.question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text>{question.answer}</Text>

                <HStack justify="flex-end" mt={4}>
                  <ButtonGroup gap={0}>
                    <Button
                      onClick={() => handleQuestionToEditClick(question)}
                      size="xs"
                      colorScheme="gray"
                      variant="link"
                      textDecoration="underline"
                      isDisabled={isLoading}
                    >
                      Editar
                    </Button>

                    <Button
                      onClick={() => handleQuestionDelete(question.id)}
                      size="xs"
                      colorScheme="red"
                      variant="outline"
                      isLoading={isDeleting}
                    >
                      Excluir
                    </Button>
                  </ButtonGroup>
                </HStack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}

      <QuestionFormModal
        question={selectedQuestion}
        isOpen={isOpen}
        onClose={handleClose}
        fetchData={fetchData}
        isLoading={false}
      />
    </>
  );
}
