export function getScript(identifierPixel: string, productId: number, productPrice: number) {
  return `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${identifierPixel}');
    fbq('track', 'PageView');
    `;
}

const FacebookPixel = {
  baseCode: (identifierPixel: string, events = '') => {
    return `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${identifierPixel}');
      fbq('track', 'PageView');
      ${events}`;
  },
  initiateCheckout: (productId: number) => {
    return `fbq('track', 'InitiateCheckout', { 'product_id': ${productId} });`;
  },
  purchaseEvent: (productIds: number[], value: number) => {
    return `fbq('track', 'Purchase', {
        'value': ${value},
        'currency': 'BRL',
        'content_ids': ['${productIds.toString()}']
      });`;
  },
};

export default FacebookPixel;
