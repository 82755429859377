import { Filter } from '../pages/DashboardAdmin/Home/InformationsSection/FilterButtons';
import { axiosBackend } from './Configuration';

function getInterval(filter: Filter) {
  const intervals = {
    today: '1',
    week: '7',
    fortnight: '15',
    month: '30',
  };

  return intervals[filter];
}

export default {
  show: async (filter: Filter) => {
    try {
      const { data } = await axiosBackend().get(`/homepage?interval=${getInterval(filter)}`);

      return data;
    } catch (error) {
      throw error;
    }
  },
};
