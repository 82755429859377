import { useEffect, useState } from 'react';

import ErrorResponse from '../../../../helpers/ErrorResponse';
import Section from '../Section';
import Line from '../components/Line';
import Tooltip from '../components/Tooltip';
import Spinner from '../../../../components/Loadings/Spinner';
import { Heading } from '../../../../components/Heading';

import styles from './styles.module.css';
import { useTheme } from '../../../../contexts/ThemeContext';

function ColorSelector() {
  const [primaryColor, setPrimaryColor] = useState('');
  const [textColor, setTextColor] = useState('');

  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { theme, updateColors } = useTheme();

  useEffect(() => {
    if (theme) {
      setPrimaryColor(theme.primaryColor);
      setTextColor(theme.textColor);
    }
  }, [theme, theme.primaryColor, theme.textColor]);

  function handleChange(event) {
    const { name, value } = event.target;

    setHasChanged(true);

    if (name === 'primaryColor') setPrimaryColor(value);
    if (name === 'textColor') setTextColor(value);
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    setPrimaryColor(theme.primaryColor);
    setTextColor(theme.textColor);
    setErrorMessage(null);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);

    try {
      await updateColors(theme.id, primaryColor, textColor);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
      setIsLoading(false);
    }
  }

  return (
    <Section>
      <div className={styles.sectionHeader}>
        <div className="d-flex align-items-center">
          <Heading as="h4" fontSize="2xl">
            Seleção de cores
          </Heading>
          <Line />
          <Tooltip link="https://youtu.be/tDdgthOwbQI" />
        </div>
      </div>
      <div className={styles.colorSelectorContainer}>
        <div className={styles.colorSelectorContent}>
          <section>
            <div>
              <label htmlFor="mainColor">Cor principal</label>

              <input
                id="primaryColor"
                name="primaryColor"
                value={primaryColor}
                type="color"
                onChange={handleChange}
              />
            </div>

            <p>
              Está será a cor dos principais locais de sua plataforma, como o menu superior e os
              itens em destaque.
            </p>
          </section>

          <section>
            <div>
              <label htmlFor="textColor">Cor dos textos</label>

              <input
                id="textColor"
                name="textColor"
                value={textColor}
                type="color"
                onChange={handleChange}
              />
            </div>

            <p>Está será a cor dos textos que estarão presentes sobre a cor principal. </p>
          </section>
        </div>

        <div className={styles.colorSelectorFooter}>
          <div>
            <button onClick={handleDiscardChanges} disabled={!hasChanged || isLoading}>
              Cancelar
            </button>

            <button onClick={handleSubmit} disabled={!hasChanged || isLoading}>
              {isLoading ? <Spinner small /> : 'Salvar alterações'}
            </button>
          </div>
        </div>
      </div>

      {errorMessage && !hasChanged && (
        <div
          className="alert alert-danger ml-2 mt-3"
          role="alert"
          style={{ borderRadius: '0', wordWrap: 'break-word' }}
        >
          <div className="text-center">{errorMessage}</div>
        </div>
      )}
    </Section>
  );
}

export default ColorSelector;
