export function getContentType(contentType) {
  return {
    TEXTO: 'view-text',
    PDF: 'view-pdf',
    DOWNLOAD: 'view-download',
    MULTIMIDIA: 'view-multimedia',
    VIDEO: 'view-video',
    AO_VIVO_ZOOM: 'view-live-zoom',
    AO_VIVO_YOUTUBE: 'view-live-youtube',
    AUDIO: 'view-audio',
    QUESTOES: 'view-questions',
    BANCO_DE_QUESTOES: 'view-question-library',
    TAREFA: 'view-task',
  }[contentType];
}
