import { Flex, AspectRatio, Box } from '@chakra-ui/react';

export default function VideoBanner({ link }: { link: string }) {
  return (
    <Flex
      height={{ base: '12rem', md: '24rem', lg: '31.25rem' }}
      position="relative"
      justify="center"
      align="center"
      overflow="hidden"
      direction="column"
    >
      <AspectRatio w="full" ratio={1}>
        <iframe
          title="Link do video"
          src={link?.concat('?rel=0&amp;&loop=1&autoplay=1&controls=0&showinfo=0&mute=1&muted=1')}
          allowFullScreen
        />
      </AspectRatio>

      {/* Degradê da parte de baixo do banner */}
      <Box
        bg="linear-gradient(0deg, #202123 0%, rgba(32, 33, 35, 0.00) 8.4%)"
        position="absolute"
        inset={0}
      />
    </Flex>
  );
}
