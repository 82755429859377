import {
  Badge,
  Button,
  Heading,
  HStack,
  Modal,
  ModalContent,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';

export default function ModalEditUser({
  isOpen,
  onClose,
  setUserId,
  setRoleName,
  size,
  handleChange,
  roleName,
  handleEditUser,
  isLoading,
}) {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setUserId(null);
        setRoleName(null);
      }}
    >
      <ModalContent padding={5}>
        <Heading fontSize="18px" fontWeight={700} marginY={2} paddingBottom={5}>
          Editar usuário
        </Heading>
        <RadioGroup onChange={setRoleName} value={roleName} colorScheme="orange">
          <Stack direction="column" spacing={5}>
            <Radio onChange={handleChange} name="role" value="AFILIADO" size="md">
              <Text fontWeight={700} fontSize="14px">
                Afiliado:{' '}
                <Text as="span" fontWeight={400}>
                  Acesso ao Extratos, Transações, Edição de Perfil, Dados Bancários e menu de
                  afiliados.
                </Text>
                <Badge marginX="2" colorScheme="green">
                  Novo
                </Badge>
              </Text>
            </Radio>

            <Radio onChange={handleChange} name="role" value="GESTOR" size="md">
              <Text fontWeight={700} fontSize="14px">
                Gestor:{' '}
                <Text as="span" fontWeight={400}>
                  Acesso e liberdade de criação e edição total em todas as funcionalidades da
                  ferramenta. Com exceção da exclusão de usuários do tipo Dono.
                </Text>
              </Text>
            </Radio>

            <Radio onChange={handleChange} name="role" value="FINANCEIRO" size="md">
              <Text fontWeight={700} fontSize="14px">
                Financeiro:{' '}
                <Text as="span" fontWeight={400}>
                  Acesso à Dashboard, todas as funções do Financeiro & Vendas e Histórico de
                  Pagamentos.
                </Text>
              </Text>
            </Radio>

            <Radio onChange={handleChange} name="role" value="MARKETING" size="md">
              <Text fontWeight={700} fontSize="14px">
                Marketing:{' '}
                <Text as="span" fontWeight={400}>
                  Acesso a todas as funções do Marketing, ao Gerenciamento de Produtos e Construa
                  Seu Site.
                </Text>
              </Text>
            </Radio>

            <Radio onChange={handleChange} name="role" value="SUPORTE" size="md">
              <Text fontWeight={700} fontSize="14px">
                Suporte:{' '}
                <Text as="span" fontWeight={400}>
                  Acesso a todas funções do Gerenciamento de Produtos, Transações, Todas as funções
                  do Marketing, Base de Alunos.
                </Text>
              </Text>
            </Radio>

            <Radio onChange={handleChange} name="role" value="BLOGUEIRO" size="md">
              <Text fontWeight={700} fontSize="14px">
                Blogueiro:{' '}
                <Text as="span" fontWeight={400}>
                  Acesso ao Blog
                </Text>
              </Text>
            </Radio>

            <Radio onChange={handleChange} name="role" value="INSTRUTOR" size="md">
              <Text fontWeight={700} fontSize="14px">
                Instrutor:{' '}
                <Text as="span" fontWeight={400}>
                  Acesso a um produto na qual ele foi selecionado como responsável e
                  compartilhamento de receitas.
                </Text>
              </Text>
            </Radio>

            <Radio onChange={handleChange} name="role" value="SEDE" size="md">
              <Text fontWeight={700} fontSize="14px">
                Gestor de Polo/Sede:{' '}
                <Text as="span" fontWeight={400}>
                  Acesso aos produtos no qual foi definido como responsável, Extratos, Transações,
                  Edição de Perfil, Dados Bancários e compartilhamento de receitas.
                </Text>
              </Text>
            </Radio>
          </Stack>

          <HStack marginTop={10} justifyContent={{ base: 'center', md: 'end', lg: 'end' }}>
            <Button
              size="sm"
              width={{ base: 'full', md: 'auto', lg: 'auto' }}
              colorScheme="gray"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              size="sm"
              width={{ base: 'full', md: 'auto', lg: 'auto' }}
              colorScheme="primary"
              onClick={handleEditUser}
              color="secondary.500"
              isLoading={isLoading}
            >
              Salvar
            </Button>
          </HStack>
        </RadioGroup>
      </ModalContent>
    </Modal>
  );
}
