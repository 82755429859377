import { HStack, Heading, IconButton, Link, Stack, Text } from '@chakra-ui/react';
import { MdChevronLeft } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

export default function Header() {
  const history = useHistory();

  function navigateToBack() {
    history.push('/integrations');
  }

  return (
    <Stack align="start" spacing={{ base: '10px', lg: '15px' }}>
      <HStack spacing={0.5}>
        <IconButton
          onClick={navigateToBack}
          icon={<MdChevronLeft size="1.75rem" />}
          aria-label="Voltar"
          variant="ghost"
          colorScheme="gray"
          size="sm"
        />
        <Heading as="h1" fontSize={{ base: 'xl', xl: '2xl' }} fontWeight="semibold">
          Google Tag Manager
        </Heading>
      </HStack>

      <Text
        marginX="5px"
        color="#20212380"
        fontSize={{ base: 'sm', xl: 'lg' }}
        whiteSpace="pre-wrap"
        lineHeight={1.25}
      >
        Recomendamos centralizar a ativação de todos os pixels para otimização de campanhas e
        análises através do Google Tag Manager (GTM).{' '}
        <Link
          href="https://proluno.freshdesk.com/support/solutions/articles/150000176516-ativac%C3%A3o-do-google-tag-manager"
          target="_blank"
          textDecoration="underline"
        >
          Clique aqui
        </Link>{' '}
        para entender como utilizar o GTM com a Proluno.
      </Text>
    </Stack>
  );
}
