import {
  Center,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  StackDivider,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { MdSearch as SearchIcon } from 'react-icons/md';
import ReactQuill, { Quill } from 'react-quill';
import { toast } from 'react-toastify';
import VideoLibraryAPI from '../../../api/VideoLibrary';
import { useVideoLibrary } from '../../../contexts/VideoLibraryContext';
import ErrorResponse from '../../../helpers/ErrorResponse';
import UploadHelper from '../../../helpers/UploadHelper';
import ImageUpload from 'quill-image-uploader';

const MAX_IMAGE_FILE_SIZE = 3145728;
const BASE_API_URL = process.env.REACT_APP_BASE_URL_API;
const isDevelopment = process.env.NODE_ENV === 'development';

Quill.register('modules/imageUploader', ImageUpload);

const scrollbarStyle = {
  '::-webkit-scrollbar': { width: '0.5rem', background: 'transparent' },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'gray.600',
  },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: 'md',
    backgroundColor: 'gray.500',
  },
};

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path className="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9" />
  </svg>
);

const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path className="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5" />
  </svg>
);

function VideoLibraryIcon({ quillRef }) {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const { videoLibrary } = useVideoLibrary();
  const toast = useToast();

  useEffect(() => {
    setVideos(videoLibrary);
  }, [videoLibrary]);

  useEffect(() => {
    async function fetchVideosByName() {
      try {
        setIsLoading(true);

        const { data: videoSearchData } = await VideoLibraryAPI.indexByName({
          search,
          page: 1,
          per_page: 10,
          orderBy: 'desc',
        });

        setVideos(videoSearchData?.videos || []);
      } catch (error) {
        setVideos([]);

        toast({
          title: 'Erro ao buscar vídeos',
          description: ErrorResponse(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }

    const timer = setTimeout(() => {
      if (search) return fetchVideosByName();

      setVideos(videoLibrary);
    }, 1000);

    return () => clearTimeout(timer);
  }, [search, toast, videoLibrary]);

  function insertVideoInEditor(videoUrl: string) {
    const quill = quillRef.current;
    const editor = quill?.getEditor();

    if (editor) {
      const range = editor.getSelection();
      editor.insertEmbed(range ? range.index : 0, 'video', videoUrl, 'user');

      // Pula uma linha após inserir o iframe de vídeo
      const nextLineIndex = range ? range.index + 2 : 2;
      quill.getEditor().setSelection(nextLineIndex);
    }
  }

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  const isDisplayNoResultsVisible = !isLoading && !videos.length;
  const isVideoListVisible = !isLoading && videos.length > 0;

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.68182 3.18182H5.59091V1.90909H3.68182V0H2.40909V1.90909H0.5V3.18182H2.40909V5.09091H3.68182V3.18182Z"
              fill="currentColor"
            />
            <path
              d="M6.86361 10.5002V4.77295L10.6818 7.63659L6.86361 10.5002ZM14.5 7.63659C14.5 11.1493 11.6491 14.0002 8.13634 14.0002C4.62361 14.0002 1.77271 11.1493 1.77271 7.63659C1.77271 6.87931 1.91271 6.16022 2.15452 5.48568L3.35089 5.9184C3.15361 6.45295 3.04543 7.03204 3.04543 7.63659C3.04543 10.443 5.32998 12.7275 8.13634 12.7275C10.9427 12.7275 13.2273 10.443 13.2273 7.63659C13.2273 4.83022 10.9427 2.54568 8.13634 2.54568C7.5318 2.54568 6.95907 2.65386 6.42452 2.85113L5.9918 1.6484C6.66634 1.41295 7.38543 1.27295 8.13634 1.27295C11.6491 1.27295 14.5 4.12386 14.5 7.63659Z"
              fill="currentColor"
            />
          </svg>
        }
        variant="outline"
      />

      <Portal>
        <MenuList boxShadow="0px 4px 4px 0px #00000040" py={4} w="21.125rem">
          <HStack px={2.5}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="#20212380"
                fontSize="1.5rem"
                ml={2}
                children={<SearchIcon />}
              />
              <Input
                value={search}
                onChange={handleSearchChange}
                pl={12}
                placeholder="Pesquisar"
                mb={2}
                focusBorderColor="default.500"
                _placeholder={{
                  color: '#20212380',
                }}
              />
            </InputGroup>
          </HStack>

          <VStack
            maxH="140px"
            overflow="auto"
            spacing={0}
            divider={<StackDivider />}
            px={0}
            sx={scrollbarStyle}
          >
            {isLoading && (
              <Center py={2}>
                <Spinner size="sm" color="default.500" />
              </Center>
            )}

            {isDisplayNoResultsVisible && (
              <Center py={2}>
                <Text fontSize="sm" color="#979CA6">
                  Nenhum resultado encontrado
                </Text>
              </Center>
            )}

            {isVideoListVisible &&
              videos?.map(video => (
                <MenuItem
                  key={video.id}
                  onClick={() => insertVideoInEditor(video.player)}
                  my={0}
                  px={3}
                >
                  <HStack spacing={3}>
                    <Image src={video.thumbnail} objectFit="cover" w={8} h={5} />
                    <Text fontSize="xs" color="#979CA6" noOfLines={1}>
                      {video.name}
                    </Text>
                  </HStack>
                </MenuItem>
              ))}
          </VStack>
        </MenuList>
      </Portal>
    </Menu>
  );
}

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}

function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  imageUploader: {
    upload: async file => {
      return new Promise((resolve, reject) => {
        if (file.size > MAX_IMAGE_FILE_SIZE) {
          reject();
          return toast({
            title: 'O tamanho da imagem deve ser de no máximo 3MB.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }

        UploadHelper.upload(file, 'drive')
          .then(response => {
            const { link, newFileName } = response;
            if (isDevelopment) {
              const link = `${BASE_API_URL}/drive/${newFileName}`;
              return resolve(link);
            }

            if (link) {
              return resolve(link);
            }

            return resolve(newFileName);
          })
          .catch(error => {
            toast({
              title: 'Ocorreu um erro ao enviar a imagem.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            reject(error);
          });
      });
    },
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  '|',
  'bold',
  'italic',
  'underline',
  'blockquote',
  'code-block',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'align',
];

interface EditorProps {
  quillRef: React.MutableRefObject<ReactQuill | undefined>;
  hasVideoLibrary?: boolean;
}

export default function EditorToolbar({ quillRef, hasVideoLibrary }: EditorProps) {
  return (
    <div id="toolbar">
      <span className="ql-formats">
        <select className="ql-header" defaultValue="" onChange={e => e.persist()}>
          <option value="1" />
          <option value="2" />
          <option />
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-blockquote" />
      </span>
      <span className="ql-formats">
        <button className="ql-code-block" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
        <select className="ql-align" />
      </span>
      <span className="ql-formats">
        <button className="ql-link" />
        <button className="ql-image" />
        <button className="ql-video" />
        {hasVideoLibrary && <VideoLibraryIcon quillRef={quillRef} />}
      </span>
      <span className="ql-formats">
        <button className="ql-undo">
          <CustomUndo />
        </button>
        <button className="ql-redo">
          <CustomRedo />
        </button>
      </span>
    </div>
  );
}
