import { useEffect } from 'react';

import process from 'process/browser';
import { IS_COOKIES_ACCEPTED } from '../helpers/LocalStorageHelper';
const HOSTNAME = window.location.hostname;
const IS_DEVELOPMENT_ENV = process.env.NODE_ENV === 'development';

type HotJarScriptType = { domain: string; hjid: number; hjsv: number };

function addScript(script: HotJarScriptType) {
  // Script fornecido pela própria Hotjar
  (function (h: any, o, t: string, j: string, a?: HTMLHeadElement, r?: HTMLScriptElement) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: script.hjid, hjsv: script.hjsv };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = true;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    r.id = `hotjarScript.${script.domain}`;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

export default function useHotjarScript(scripts: HotJarScriptType[]) {
  useEffect(() => {
    const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

    if (isCookiesAccepted === 'false') return;

    if (!IS_DEVELOPMENT_ENV) {
      for (const script of scripts) {
        if (script.domain === HOSTNAME) {
          addScript(script);
        }
      }
    }

    // Remove todos os scripts quando o componente é desmontado
    return () => {
      const hotjarScripts = Array.from(document.querySelectorAll("[id*='hotjarScript']"));

      for (const script of hotjarScripts) {
        script?.parentNode?.removeChild(script);
      }
    };
  }, [scripts]);
}
