import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';
import { IoMdEyeOff as EyeOffIcon } from 'react-icons/io';
import { MdOutlineRemoveRedEye as EyeIcon } from 'react-icons/md';

export default function PasswordInput(props: InputProps) {
  const [isShow, setIsShow] = useBoolean();

  function handleClick() {
    setIsShow.toggle();
  }

  return (
    <InputGroup size="md">
      <Input pr={10} type={isShow ? 'text' : 'password'} {...props} />
      <InputRightElement>
        <Button
          size="sm"
          boxSize="full"
          onClick={handleClick}
          variant="ghost"
          color="#20212380"
          borderRadius={0}
          borderTopRightRadius="md"
          borderBottomRightRadius="md"
        >
          {isShow ? <EyeOffIcon /> : <EyeIcon />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
