import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Container } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useLocation, useParams } from 'react-router-dom';
import FullPageLoading from '../../../../../components/FullPageLoading';
import useFetch from '../../../../../hooks/useFetch';
import { PAGE_KEYWORDS } from './constants';
import Header from './Header';
import PlanManagementEditCustomization from './PlanManagementEditCustomization';
import PlanManagementEditDescription from './PlanManagementEditDescription';
import PlanManagementEditPageThanks from './PlanManagementEditPageThanks';
import PlanManagementEditPayment from './PlanManagementEditPayment';
import { PlanResponse } from './types';
import { checkPathnameIncludes } from './utils';

export default function PlanManagementEdit() {
  const [isLoading, setIsLoading] = useState(false);

  const { pathname, search } = useLocation();
  const { planId, tab } = useParams<{ planId: string; tab: string }>();

  const searchParams = new URLSearchParams(search);

  const {
    data: response,
    loading: isFetching,
    fetchData: fetchPlanData,
  } = useFetch<UnificadaFront.ResponseJSON<PlanResponse>>({
    method: 'get',
    url: `/recurrence-plan/${planId}`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    !!planId && fetchPlanData();
  }, [fetchPlanData, planId, tab]);

  const handleIsLoadingChange = useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  const isDescriptionPageActive = checkPathnameIncludes(pathname, PAGE_KEYWORDS.description);
  const isCustomizationPageActive = checkPathnameIncludes(pathname, PAGE_KEYWORDS.customization);
  const isPageThanksActive = checkPathnameIncludes(pathname, PAGE_KEYWORDS.thanks);
  const planData = response?.data;
  const isDuplicating = searchParams.get('duplicate') === 'true';
  const isTabDisabled = isDuplicating ? 'none' : 'auto';

  return (
    <>
      <Box minHeight="100vh" pb={10}>
        {!isLoading && <Header planId={Number(planId)} planName={planData?.name} />}

        <Container as="main" w="full" maxW="790px" px={{ base: 4, xl: 0 }}>
          <Breadcrumb
            mt={{ base: '1.875rem', xl: 14 }}
            spacing="8px"
            separator={<ChevronRightIcon />}
            color="#20212380"
            fontSize={{ base: 'xs', xl: 'lg' }}
            sx={{
              '.chakra-breadcrumb__list-item': {
                _hover: {
                  color: 'blackAlpha.900',
                },
              },
              '[aria-current="page"]': {
                color: 'blackAlpha.900',
                '+ span[role="presentation"]': {
                  color: 'blackAlpha.900',
                },
              },
            }}
          >
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink
                as={Link}
                to={`/recurrence/plan-management/edit/${planId}/payment`}
                pointerEvents={isTabDisabled}
              >
                Pagamento
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage={isDescriptionPageActive} pointerEvents={isTabDisabled}>
              <BreadcrumbLink
                as={Link}
                to={`/recurrence/plan-management/edit/${planId}/description`}
                pointerEvents={isTabDisabled}
              >
                Descrição
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage={isCustomizationPageActive} pointerEvents={isTabDisabled}>
              <BreadcrumbLink
                as={Link}
                to={`/recurrence/plan-management/edit/${planId}/customization`}
              >
                Personalização
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage={isPageThanksActive} pointerEvents={isTabDisabled}>
              <BreadcrumbLink
                as={Link}
                to={`/recurrence/plan-management/edit/${planId}/page-thanks`}
              >
                Página de obrigado
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Switch>
            <Route
              path={[
                '/recurrence/plan-management/edit/:planId/payment',
                '/recurrence/plan-management/new',
              ]}
            >
              <PlanManagementEditPayment
                planData={planData}
                onIsLoadingChange={handleIsLoadingChange}
              />
            </Route>

            <Route path="/recurrence/plan-management/edit/:planId/description">
              <PlanManagementEditDescription planData={planData} />
            </Route>

            <Route path="/recurrence/plan-management/edit/:planId/customization">
              <PlanManagementEditCustomization planData={planData} />
            </Route>

            <Route path="/recurrence/plan-management/edit/:planId/page-thanks">
              <PlanManagementEditPageThanks planData={planData} />
            </Route>
          </Switch>
        </Container>
      </Box>

      <FullPageLoading isLoading={isLoading || isFetching} />
    </>
  );
}
