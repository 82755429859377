import { Box } from '@chakra-ui/react';
import { Route, Switch } from 'react-router-dom';
import EditPrice from './EditPrice';
import ListCoursePrices from './ListCoursePrices';

export default function PrimaryPrices() {
  return (
    <Box width="full">
      <Switch>
        <Route
          exact
          path="/courses/:id/course-manager/price/primary-prices"
          component={ListCoursePrices}
        />
        <Route
          exact
          path="/courses/:id/course-manager/price/primary-prices/new"
          component={EditPrice}
        />
        <Route
          exact
          path="/courses/:id/course-manager/price/primary-prices/:coursePriceId/edit"
          component={EditPrice}
        />
        <Route path="*" component={ListCoursePrices} />
      </Switch>
    </Box>
  );
}
