import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';

interface IParams {
  isOpen: boolean;
  onClose: () => void;
  isUnlinkQuestion: boolean;
  unlinkQuestion: () => void;
}

export function ModalUnlinkQuestion({
  isOpen,
  onClose,
  unlinkQuestion,
  isUnlinkQuestion,
}: IParams) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" fontWeight={600}>
          Tem certeza que deseja desvincular essa questão do conteúdo?
        </ModalHeader>
        <ModalCloseButton />

        <ModalFooter>
          <Stack
            width="full"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Button colorScheme="red" onClick={onClose}>
              Não
            </Button>
            <Button
              onClick={unlinkQuestion}
              isDisabled={isUnlinkQuestion}
              isLoading={isUnlinkQuestion}
            >
              Sim
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
