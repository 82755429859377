import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { Plan } from '../..';
import PlanListTableRow from './PlanListTableRow';

interface PlanTableProps extends TableContainerProps {
  planList: Plan[];
}

export default function PlanListTable({ planList, ...rest }: PlanTableProps) {
  return (
    <TableContainer
      border="1px solid rgba(32, 33, 35, 0.05)"
      mt={4}
      p={3}
      borderRadius="base"
      {...rest}
    >
      <Table>
        <Thead>
          <Tr sx={{ th: { color: '#202123BF', whiteSpace: 'break-spaces', py: 2 } }}>
            <Th maxWidth="323px">Nome</Th>

            <Th maxWidth="190px">
              Qtd. de <br />
              assinaturas
            </Th>

            <Th maxWidth="190px">
              Qtd. de <br />
              cancelamentos
            </Th>

            <Th maxWidth="223px">Faturamento</Th>

            <Th textAlign="center">Ações</Th>
          </Tr>
        </Thead>

        <Tbody sx={{ 'tr:nth-of-type(odd)': { background: '#2021230D' } }}>
          {planList.map(plan => (
            <PlanListTableRow key={plan.id} plan={plan} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
