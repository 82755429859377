import { ChangeEvent } from 'react';
import { FormControl, FormLabel, InputGroup, Spinner, Stack } from '@chakra-ui/react';

import { Input } from './styled';

interface IInputMaskProps {
  ref?: any;
  id: string;
  name: string;
  label: string;
  mask: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  minLength?: number;
  maxLength?: number;
  isRequired?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

function InputMask({
  ref,
  id,
  name,
  label,
  mask,
  placeholder,
  onChange,
  value,
  onClick,
  isDisabled,
  isLoading,
  minLength,
  maxLength,
  isRequired,
  onFocus,
  onBlur,
  ...rest
}: IInputMaskProps) {
  const inputId = id ?? name;

  return (
    <FormControl id={inputId}>
      <Stack spacing="1">
        {label && (
          <FormLabel
            marginBottom="0"
            fontSize="sm"
            fontWeight="medium"
            htmlFor={inputId}
            display="flex"
            alignItems="center"
          >
            {label}
          </FormLabel>
        )}

        <InputGroup position="relative">
          <Input
            ref={ref}
            id={inputId}
            type="tel"
            name={name}
            mask={mask}
            alwaysShowMask={false}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            onClick={onClick}
            onKeyPress={event => !/[0-9]/.test(event.key) && event.preventDefault()}
            // onKeyUp={handleKeyUp}
            minLength={minLength}
            maxLength={maxLength}
            disabled={isDisabled || isLoading}
            required={isRequired}
            onFocus={onFocus}
            onBlur={onBlur}
            {...rest}
          />

          {isLoading && (
            <Spinner
              position="absolute"
              top="0.7rem"
              right="0.7rem"
              size="sm"
              color="primary.400"
            />
          )}
        </InputGroup>
      </Stack>
    </FormControl>
  );
}

export default InputMask;
