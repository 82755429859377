import Joi from 'joi';

const NAME_MESSAGES = {
  'string.min': 'Deve ser no mínimo 3 dígitos.',
  'string.max': 'Deve ser no máximo 255 dígitos.',
  'any.required': 'O nome da vitrine é obrigatório.',
  'string.empty': 'O nome da vitrine é obrigatório.',
};

const PRODUCTS_IDS_MESSAGES = {
  'array.min': 'Selecione pelo menos um produto.',
  'any.required': 'A lista de produtos é obrigatória.',
};

const PLANS_IDS_MESSAGES = {
  'array.min': 'Selecione pelo menos um plano.',
  'any.required': 'A lista de planos é obrigatória.',
};

const TITLE_HIGHLIGHT_MESSAGES = {
  'any.required': 'O título do destaque é obrigatório.',
  'string.empty': 'O título do destaque não pode estar vazio.',
};

const DESCRIPTION_MESSAGES = {
  'any.required': 'A descrição do destaque é obrigatória.',
  'string.empty': 'A descrição do destaque não pode estar vazia.',
};

const IMAGE_DESKTOP_MESSAGES = {
  'any.required': 'A imagem para desktop é obrigatória.',
  'string.empty': 'A URL da imagem para desktop não pode estar vazia.',
};

const NAME_VALIDATION = Joi.string().required().messages(NAME_MESSAGES).min(3).max(255);

export type CreateShowcaseSchemaType = {
  showcaseType: 'products' | 'plans';
  name: string;
  showcaseViewType?: 'standard' | 'highlight';
  plansIds?: number[];
  productsIds?: number[];
  titleHighlight?: string;
  imageDesktop?: string;
  imageMobile?: string;
  description?: string;
};

export const createShowcaseSchemaValidator = Joi.object<CreateShowcaseSchemaType>().keys({
  showcaseType: Joi.string().valid('products', 'plans').required(),
  name: Joi.when('showcaseType', {
    is: 'products',
    then: NAME_VALIDATION,
    otherwise: Joi.when('showcaseViewType', {
      is: 'standard',
      then: NAME_VALIDATION,
      otherwise: Joi.forbidden(),
    }),
  }),
  showcaseViewType: Joi.when('showcaseType', {
    is: 'plans',
    then: Joi.string().valid('standard', 'highlight').required(),
    otherwise: Joi.forbidden(),
  }),
  productsIds: Joi.when('showcaseType', {
    is: 'products',
    then: Joi.array()
      .items(Joi.number().integer().positive())
      .min(1)
      .required()
      .messages(PRODUCTS_IDS_MESSAGES),
    otherwise: Joi.forbidden(),
  }),
  plansIds: Joi.when('showcaseType', {
    is: 'plans',
    then: Joi.array()
      .items(Joi.number().integer().positive())
      .min(1)
      .required()
      .messages(PLANS_IDS_MESSAGES),
    otherwise: Joi.forbidden(),
  }),
  titleHighlight: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().required().messages(TITLE_HIGHLIGHT_MESSAGES),
    otherwise: Joi.forbidden(),
  }),
  description: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().required().messages(DESCRIPTION_MESSAGES),
    otherwise: Joi.forbidden(),
  }),
  imageDesktop: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().required().messages(IMAGE_DESKTOP_MESSAGES),
    otherwise: Joi.forbidden(),
  }),
  imageMobile: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().optional(),
    otherwise: Joi.forbidden(),
  }),
});
