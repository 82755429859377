import { axiosBackend } from './Configuration';

export default {
  async getscripts() {
    const { data: scripts } = await axiosBackend().get(`/scripts`);
    return scripts;
  },

  async uploadFile(file) {
    let token = localStorage.getItem('token');
    let authorization = `Bearer ${token}`;

    const { data: post } = await axiosBackend().post('/files', file, {
      headers: { Authorization: authorization },
    });
    return post;
  },

  async create(dados) {
    try {
      const { data: post } = await axiosBackend().post('/plataforms', {
        name: dados.titleSite,
        logo: dados.file,
        cpf_cnpj: dados.cpf_cnpj,
        url: dados.urlSite,
        primary_color: dados.colorPrimary,
        secondary_color: dados.colorSecondary,
        fk_status_id: 1,
      });
      return post;
    } catch (error) {
      return {
        error: 'Erros acontecem. Tente novamente.',
      };
    }
  },

  async getCurrentPlatform() {
    try {
      const { data: currentPlatformData } = await axiosBackend().get('/platforms');
      return currentPlatformData;
    } catch (error) {
      throw error;
    }
  },

  async firstAccess() {
    try {
      const { data: firstAccessData } = await axiosBackend().get(
        '/platforms/integration-form/get-first-access'
      );
      return firstAccessData;
    } catch (error) {
      throw error;
    }
  },
  async integrationDomain(subdomain) {
    try {
      const { data: domainData } = await axiosBackend().patch(
        '/platforms/integration-form/domain',
        { subdomain }
      );
      return domainData;
    } catch (error) {
      throw error;
    }
  },
  async integrationLogo(logoData) {
    try {
      const { data: themeLogo } = await axiosBackend().patch(
        '/platforms/integration-form/logo',
        logoData
      );

      return themeLogo;
    } catch (error) {
      throw error;
    }
  },
  async integrationColors(payload) {
    try {
      const { data: colors } = await axiosBackend().patch(
        '/platforms/integration-form/colors',
        payload
      );
      return colors;
    } catch (error) {
      throw error;
    }
  },
  async integrationUsers(payload) {
    try {
      const { data: invitations } = await axiosBackend().patch(
        '/platforms/integration-form/users',
        payload
      );
      return invitations;
    } catch (error) {
      throw error;
    }
  },

  async integrationBankData(payload) {
    try {
      const { data: bankData } = await axiosBackend().patch(
        '/platforms/integration-form/bank-data',
        payload
      );
      return bankData;
    } catch (error) {
      throw error;
    }
  },

  async getFirstAccess() {
    try {
      const { data: firstAccess } = await axiosBackend().get(
        '/platforms/integration-form/get-first-access'
      );
      return firstAccess;
    } catch (error) {
      throw error;
    }
  },
  async firstAccessUpdate() {
    try {
      await axiosBackend().patch('/platforms/integration-form/first-access');
    } catch (error) {
      throw error;
    }
  },

  async createPresignedURLToUpload(key, folder) {
    try {
      const { data: presignedURLToUpload } = await axiosBackend().get(
        `/presigned-url-to-upload?folder=${folder}&key=${key}`
      );
      return presignedURLToUpload;
    } catch (error) {
      throw error;
    }
  },

  async deleteFileByKey(key) {
    try {
      await axiosBackend().delete(`/delete-file-by-key?key=${key}`);
    } catch (error) {
      throw error;
    }
  },

  async checkStatusNewPlatform(url) {
    try {
      const { data: newPlatformData } = await axiosBackend().get(`/get-status-domain/${url}`, {
        // eslint-disable-next-line no-useless-computed-key
        headers: { ['x-new-domain']: url },
      });

      return newPlatformData;
    } catch (error) {
      throw error;
    }
  },

  async createSubscriptionPlatform(formData) {
    try {
      const { data: response } = await axiosBackend().post(
        '/create-subscription-platform',
        formData
      );

      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  },

  async createFreeOrTrialSubscriptionPlatform(payload) {
    try {
      const { data: response } = await axiosBackend().post(
        `/create-free-subscription-platform`,
        payload
      );

      return response;
    } catch (error) {
      throw error;
    }
  },

  async getAlternativesByQuestion({ formId, formQuestionId }) {
    try {
      const { data: alternatives } = await axiosBackend().get(
        `/platforms/initial-form/${formId}/form_id/${formQuestionId}/form_question_id`
      );

      return alternatives;
    } catch (error) {
      throw error;
    }
  },

  async formAnswers(payload) {
    try {
      const { data: formAnswersData } = await axiosBackend().post(
        `/platforms/initial-form/answers`,
        payload
      );

      return formAnswersData;
    } catch (error) {
      throw error;
    }
  },

  async checkDomain(domain, primary_url = 'false') {
    try {
      const { data: domainData } = await axiosBackend().get(
        `/check-domain-availability/${domain}${
          primary_url === 'true' ? `?primary_url=${primary_url}` : ''
        }`
      );

      return domainData;
    } catch (error) {
      throw error;
    }
  },
};
