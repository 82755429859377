import DotFalling from '../../../../../components/Loadings/DotsFalling';

function Loading() {
  return (
    <div>
      <div className="container my-5">
        <DotFalling />
      </div>
    </div>
  );
}

export default Loading;
