import {
  Center,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  List,
  ListItem,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { UtmItem } from '../useUtmGenerator';
import { generateUTMLink } from '../utils/generateUTMLink';
import CopyButton from './CopyButton';

export default function UtmDetails() {
  const { utmId } = useParams<{ utmId: string }>();

  const { loading: isLoading, data: response } = useFetch<UnificadaFront.ResponseJSON<UtmItem>>({
    url: `/utm/${utmId}`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const utmDetails = response?.data;

  const utmLink = generateUTMLink(utmDetails);

  if (isLoading) {
    return (
      <Center height="80vh">
        <Spinner
          color="primary.500"
          size="xl"
          thickness="4px"
          speed="1s"
          emptyColor="secondary.200"
        />
      </Center>
    );
  }

  return (
    <VStack spacing={8} align="start">
      <HStack spacing={{ base: 0.25, xl: 0.5 }} ml={{ base: -2, xl: 0 }}>
        <IconButton
          as={Link}
          to={'/utm-generator'}
          icon={<ChevronLeftIcon size="1.75rem" />}
          aria-label="Voltar"
          variant="ghost"
          colorScheme="gray"
          size="sm"
        />

        <Heading as="h1" fontSize={{ base: 'lg', lg: '2xl' }} fontWeight="semibold" m={0}>
          Parâmetros - {utmDetails?.utmCampaign}
        </Heading>
      </HStack>

      <SimpleGrid
        as={List}
        columns={{ base: 1, xl: 2 }}
        spacing={8}
        w="100%"
        color="#202123"
        sx={{
          strong: {
            fontWeight: 'semibold',
          },
        }}
      >
        <GridItem as={ListItem}>
          <Text as="strong">Origem da Campanha (utm_source)</Text>
          <Text>{utmDetails.utmSource}</Text>
        </GridItem>

        <GridItem>
          <Text as="strong">Mídia da Campanha (utm_medium)</Text>
          <Text>{utmDetails.utmMedium}</Text>
        </GridItem>

        <GridItem>
          <Text as="strong">Conteúdo da Campanha (utm_content)</Text>
          <Text>{utmDetails.utmContent ?? 'Não definido'}</Text>
        </GridItem>

        <GridItem>
          <Text as="strong">Campanha (utm_campaign)</Text>
          <Text>{utmDetails?.utmCampaign}</Text>
        </GridItem>
      </SimpleGrid>

      <VStack align="start" w="100%">
        <Heading as="h2" fontSize="sm" fontWeight="medium" color="#202123">
          Visualização do Link
        </Heading>

        <InputGroup bg="#e9e9e9" borderRadius="base">
          <Input
            value={utmLink}
            bg="#e9e9e9"
            color="#959595 !important"
            borderRadius="base"
            focusBorderColor="transparent"
            isTruncated
          />

          <InputRightAddon bg="#2021231A" children={<CopyButton textToCopy={utmLink} />} p={0} />
        </InputGroup>
      </VStack>
    </VStack>
  );
}
