import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import AlertIcon from '../../../../../../assets/icons/alertIcon.svg';

export function ModalDeleteArea({
  isOpenModalDeleteArea,
  onCloseModalDeleteArea,
  handleSubmitDeleteArea,
  setAreaId,
  areaName,
  setAreaName,
  isDeleting,
}) {
  return (
    <Modal
      isOpen={isOpenModalDeleteArea}
      onClose={() => {
        setAreaId(0);
        setAreaName('');
        onCloseModalDeleteArea();
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent padding={3}>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5} justifyContent="center" alignItems="center">
            <Image src={AlertIcon} width="40px" />
            <Text fontWeight={700} fontSize="18px">
              Deseja excluir a área {areaName}?
            </Text>
          </Stack>
          <Text fontWeight={400} fontSize="14px" color="#20212380" marginY={2} textAlign="center">
            Caso você exclua está área todas as disciplinas e assuntos ligados à ela também serão
            apagadas
          </Text>
        </ModalBody>

        <ModalFooter justifyContent="center" gap={3}>
          <Button
            colorScheme="gray"
            size="sm"
            onClick={() => {
              setAreaId(0);
              setAreaName('');
              onCloseModalDeleteArea();
            }}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            isLoading={isDeleting}
            isDisabled={isDeleting}
            onClick={() => {
              handleSubmitDeleteArea();
              onCloseModalDeleteArea();
            }}
          >
            Excluir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
