import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  Textarea,
} from '@chakra-ui/react';
import Joi from 'joi';
import { FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Question } from '..';
import useHandleChange from '../../../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../../../hooks/useHandleSubmit';

const initialState = {
  question: '',
  answer: '',
};

const schemaValidator = Joi.object<Question>().keys({
  question: Joi.string().required().messages({
    'any.required': 'Pergunta obrigatória',
    'string.empty': 'Pergunta obrigatória',
  }),
  answer: Joi.string().required().messages({
    'any.required': 'Resposta obrigatória',
    'string.empty': 'Resposta obrigatória',
  }),
});

interface QuestionFormModalProps {
  isOpen: ModalProps['isOpen'];
  isLoading: boolean;
  question?: Question;
  onClose: ModalProps['onClose'];
  fetchData: () => Promise<void>;
}

export default function QuestionFormModal({
  question,
  isOpen,
  onClose,
  fetchData,
}: QuestionFormModalProps) {
  const { form, setForm, handleChange } = useHandleChange<Question>(
    initialState,
    !!question && {
      question: question.question,
      answer: question.answer,
    }
  );

  useEffect(() => {
    if (question) {
      setForm({
        question: question.question,
        answer: question.answer,
      });
    }
  }, [question, setForm]);

  function handleClose() {
    setForm(initialState);
    onClose();
  }

  const { planId } = useParams<{ planId: string }>();

  const isUpdating = !!question?.id;

  const {
    handleSubmit,
    isLoading: isSubmitting,
    formValidation,
  } = useHandleSubmit({
    method: isUpdating ? 'patch' : 'post',
    url: isUpdating
      ? `/recurrence-plan/${planId}/frequently-questions/${question.id}`
      : `/recurrence-plan/${planId}/frequently-questions`,
    authenticated: true,
    data: form,
    schemaValidator,
    onSuccess: {
      callback: async () => {
        handleClose();
        await fetchData();
      },
    },
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      closeOnEsc={!isSubmitting}
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={onSubmit}
        noValidate
        maxWidth={{ base: '94%', md: '790px' }}
        px={{ base: 4, xl: 0 }}
      >
        <ModalHeader fontSize="xl" fontWeight="medium">
          Adicionando uma pergunta frequente
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isRequired isInvalid={formValidation?.question?.isInvalid}>
            <HStack justify="space-between">
              <FormLabel fontSize="sm">Pergunta</FormLabel>

              <Text color={form?.question?.length > 150 ? 'red.500' : 'blackAlpha.500'}>
                Caracteres: {form?.question?.length}/200
              </Text>
            </HStack>

            <Input
              name="question"
              value={form?.question}
              onChange={handleChange}
              focusBorderColor="gray.300"
              maxLength={200}
              placeholder="Digite a pergunta"
              _placeholder={{ color: 'blackAlpha.500' }}
            />

            <FormErrorMessage>{formValidation?.question?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired mt={5} isInvalid={formValidation?.answer?.isInvalid}>
            <HStack justify="space-between">
              <FormLabel fontSize="sm">Resposta</FormLabel>

              <Text color={form?.answer?.length > 450 ? 'red.500' : 'blackAlpha.500'}>
                Caracteres: {form?.answer?.length}/500
              </Text>
            </HStack>

            <Textarea
              name="answer"
              value={form?.answer}
              onChange={handleChange}
              focusBorderColor="gray.300"
              maxLength={500}
              placeholder="Digite a resposta"
              _placeholder={{ color: 'blackAlpha.500' }}
              rows={10}
            />

            <FormErrorMessage>{formValidation?.answer?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter justifyContent="end" gap={3}>
          <Button
            size="sm"
            onClick={onClose}
            bg="#e9e9e9"
            border="1px solid #20212340"
            isDisabled={isSubmitting}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            size="sm"
            colorScheme="primary"
            color="secondary.500"
            isLoading={isSubmitting}
          >
            Adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
