import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import studentPaymentHistoryAPI from '../../../../api/StudentPaymentHistory';
import Toast from '../../../../components/Toast';
import convertToBrazilianCurrency from '../../../../helpers/convertToBrazilianCurrency';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import useQuery from '../../../../hooks/useQuery';
import PixModal from './components/ProductsTab/PixModal';
import ProductsListMobile from './components/ProductsTab/ProductsListMobile';
import ProductsTable from './components/ProductsTab/ProductsTable';
import SubscriptionsListMobile from './components/SubscriptionsTab/SubscriptionsListMobile';
import SubscriptionsTable from './components/SubscriptionsTab/SubscriptionsTable';

import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';

export interface IPayment {
  id: number;
  products: {
    id: number;
    name: string;
    price: string;
    amount: number;
  }[];
  value: number;
  paymentMethod: string;
  installments: number;
  date: Date;
  status: string;
  link: string;
  pixQrCode: string;
}

export type SubscriptionPaymentType = {
  courseSubscriptionId: number;
  createdAt: string;
  installment: number;
  products: {
    id: number;
    name: string;
    price: string;
    amount: number;
  }[];
  paymentMethod: string;
  planName: string;
  purchasePrice: number;
  purchaseStatus: string;
  transactionId: number;
  link: string;
};

export default function Payments() {
  const [paymentList, setPaymentList] = useState<IPayment[]>([]);
  const [loading, setLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [pixCode, setPixCode] = useState('');
  const [selectedProductId, setSelectedProductId] = useState();
  const [transactionFiltered, setTransactionFiltered] = useState([]);
  const [subscriptionPaymentHistory, setSubscriptionPaymentHistory] = useState<
    SubscriptionPaymentType[]
  >([]);

  const query = useQuery();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    async function getStudentsSubscriptionPayment() {
      try {
        const { data: paymentHistorySubscriptionData } =
          await studentPaymentHistoryAPI.getPaymentSubscription();

        if (paymentHistorySubscriptionData) {
          setSubscriptionPaymentHistory(paymentHistorySubscriptionData.data);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    }

    getStudentsSubscriptionPayment();
  }, []);

  useEffect(() => {
    const popState = query.get('popstate');

    if (popState) {
      window.addEventListener('popstate', () => history.go(1));
    }
  });

  useEffect(() => {
    async function fetchPayments() {
      const { data: paymentHistoryData } = await studentPaymentHistoryAPI.index();

      if (paymentHistoryData) {
        setPaymentList(paymentHistoryData);
      }

      setLoading(false);
    }

    fetchPayments();
  }, []);

  function toggleModal(pixQrCode: string) {
    if (pixQrCode) setPixCode(pixQrCode);

    setIsShowModal(prevState => !prevState);
  }

  function handleChangeSelectedProduct(event) {
    const { value } = event.target;

    setSelectedProductId(value);
  }

  const selectedProductPaymentHistory = subscriptionPaymentHistory.filter(
    product => product.courseSubscriptionId === parseInt(selectedProductId)
  );

  const filteredProductId = new Map();

  for (const product of subscriptionPaymentHistory) {
    if (!filteredProductId.get(product.courseSubscriptionId))
      filteredProductId.set(product.courseSubscriptionId, {
        id: product.courseSubscriptionId,
        name: product.products.map(item => item.name).shift(),
      });
  }

  const filteredProductIdData = Array.from(filteredProductId, ([, value]) => value);

  const bgColor = '#202123';
  const textColor = '#ffffff';

  return (
    <>
      <Flex
        mt={{ base: 8, lg: 16 }}
        direction="column"
        paddingX={{ base: '1rem', md: '6rem' }}
        flex={1}
        bg={bgColor}
        height="100vh"
        color={textColor}
      >
        <Box>
          <Heading as="h1" fontSize={{ base: 'lg', md: '3xl' }} fontWeight="medium" mb={5}>
            Pagamentos
          </Heading>

          <Tabs colorScheme="default">
            <TabList mb="1em" fontWeight="normal">
              <Tab>Produtos Online</Tab>
              <Tab>Assinaturas</Tab>
            </TabList>

            <TabPanels>
              {/* PRODUTOS ONLINE */}
              <TabPanel px={0}>
                <ProductsListMobile
                  display={{ base: 'flex', lg: 'none' }}
                  paymentList={paymentList}
                  setTransactionFiltered={setTransactionFiltered}
                  onOpen={onOpen}
                  toggleModal={toggleModal}
                />

                <ProductsTable
                  display={{ base: 'none', lg: 'block' }}
                  onOpen={onOpen}
                  paymentList={paymentList}
                  setTransactionFiltered={setTransactionFiltered}
                  toggleModal={toggleModal}
                />
              </TabPanel>

              {/* ASSINATURAS */}
              <TabPanel px={0}>
                {!loading && subscriptionPaymentHistory.length ? (
                  <>
                    <VStack
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Text fontSize="md" fontWeight={600}>
                        Escolha o produto:
                      </Text>
                      <Select
                        w={350}
                        placeholder="Selecione"
                        focusBorderColor="#D86826"
                        value={selectedProductId}
                        onChange={handleChangeSelectedProduct}
                      >
                        {filteredProductIdData.map(product => (
                          <option key={product.id} value={product.id}>
                            {product.name}
                          </option>
                        ))}
                      </Select>
                    </VStack>

                    <Heading fontSize="lg" my={5}>
                      Histórico de Pagamento
                    </Heading>

                    <SubscriptionsListMobile
                      display={{ base: 'flex', lg: 'none' }}
                      paymentHistory={selectedProductPaymentHistory}
                      toggleModal={toggleModal}
                    />

                    <SubscriptionsTable
                      display={{ base: 'none', lg: 'block' }}
                      paymentHistory={selectedProductPaymentHistory}
                      toggleModal={toggleModal}
                    />
                  </>
                ) : (
                  <Box w="100%" display="flex" justifyContent="center" alignItems="center">
                    <Heading fontSize="md">Você não possui assinaturas.</Heading>
                  </Box>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent p={5}>
            <ModalHeader>Resumo da Compra</ModalHeader>
            <ModalCloseButton style={{ outline: 'none', boxShadow: 'none' }} />
            <ModalBody>
              <TableContainer>
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th>Produto</Th>
                      <Th>Valor</Th>
                      <Th>Quantidade</Th>
                    </Tr>
                  </Thead>
                  {transactionFiltered.length > 0 &&
                    transactionFiltered?.map(item => (
                      <Tbody>
                        <Tr>
                          <Td>{item?.name}</Td>
                          <Td> {convertToBrazilianCurrency(item?.price)}</Td>
                          <Td>{item?.amount}</Td>
                        </Tr>
                      </Tbody>
                    ))}
                </Table>
              </TableContainer>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>

      <PixModal isOpen={isShowModal} pixQrCode={pixCode} onClose={toggleModal} />
    </>
  );
}
