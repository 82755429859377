import { Container, Flex } from '@chakra-ui/react';
import Header from '../../../Header';
import { HighlightPlanShowcaseForm } from '../HighlightPlanShowcaseForm';

export function NewHighlightPlanShowcase() {
  return (
    <Flex minHeight="100vh" flexDirection="column">
      <Header />

      <Container maxWidth="1150px" px={{ base: 4, xl: 0 }}>
        <HighlightPlanShowcaseForm action="new" url="/showcase" />
      </Container>
    </Flex>
  );
}
