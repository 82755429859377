import {
  Box,
  Button,
  Container,
  HStack,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import process from 'process/browser';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AiOutlineRight } from 'react-icons/ai';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import PlanAPI from '../../api/Plan';
import PlatformAPI from '../../api/Platform';
import freeRegistration from '../../assets/images/free-registration.png';
import logoProluno from '../../assets/images/logos/proluno_cor.png';
import Toast from '../../components/Toast';
import DDIHelperBR from '../../helpers/DDIHelperBR';
import ErrorResponse from '../../helpers/ErrorResponse';
import { IS_COOKIES_ACCEPTED } from '../../helpers/LocalStorageHelper';
import onlyNumber from '../../helpers/onlyNumber';
import useQuery from '../../hooks/useQuery';
import useWindowSize from '../../hooks/useWindowSize';
import { MEDIUM_SCREEN_SIZE } from '../TrialPeriodForm';

const initialState = {
  fullName: '',
  email: '',
  password: '',
  country: '',
  ddi: '',
  ddd: '',
  phone: '',
};

type NewUserFormType = {
  fullName: string;
  email: string;
  password: string;
  country: string;
  ddi: string;
  ddd: string;
  phone: string;
};

const head = document.getElementsByTagName('head').item(0);

export function TrialPeriodRegistration() {
  const { width } = useWindowSize();
  const history = useHistory();
  const query = useQuery();

  const [show, setShow] = useState(false);
  const showOrHidePassword = () => setShow(!show);

  const [newUserForm, setNewUserForm] = useState<NewUserFormType>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState(null);
  const [planIdentifier, setPlanIdentifier] = useState(null);
  const [flag, setFlag] = useState<string>('');
  const [country, setCountry] = useState<string>('br');
  const [ddi, setDDI] = useState<string>('55');
  const [codeError, setCodeError] = useState(null);

  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    if (document.getElementById('metaPixelAssinatura')) return;

    const metaPixel = document.createElement('script');
    metaPixel.id = 'metaPixelAssinatura';

    const metaPixelScript = `
      !function(f,b,e,v,n,t,s)

      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      
      n.queue=[];t=b.createElement(e);t.async=!0;
      
      t.src=v;s=b.getElementsByTagName(e)[0];
      
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      
      'https://connect.facebook.net/en_US/fbevents.js');
      
      fbq('init', '524432899269170');
      
      fbq('track', 'Lead');
      
      fbq('trackCustom', 'typ-comecegratis')
      `;

    metaPixel.innerHTML = metaPixelScript;

    head.appendChild(metaPixel);

    return () => {
      const metaPixel = document.getElementById('metaPixelAssinatura');

      if (metaPixel) {
        head.removeChild(metaPixel);
      }
    };
  }, [isCookiesAccepted]);

  useEffect(() => {
    async function getFlag(initial: string) {
      try {
        const endPoint = `https://flagcdn.com/w40`;

        const data = await axios.get(`${endPoint}/${initial.toLowerCase()}.png`);

        setFlag(data?.config?.url);
      } catch (error) {
        setFlag('');
      }
    }

    getFlag(country);
  }, [country]);

  const getPlanByIdentifier = useCallback(async () => {
    const planIdentifier = query.get('plan');

    if (!planIdentifier) return;

    setPlanIdentifier(planIdentifier);

    try {
      const { data: planData } = await PlanAPI.getPlanByIdentifier(planIdentifier);

      if (planData) {
        setPlan(planData);
      }

      if (!planData) {
        window.location.href = 'https://checkout.proluno.me/precos';
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }, [query]);

  useEffect(() => {
    if (!plan) {
      getPlanByIdentifier();
    }
  }, [getPlanByIdentifier, plan]);

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    if (name === 'country') {
      const currentCountry = DDIHelperBR.find(country => country.initials.toLowerCase() === value);

      if (currentCountry) {
        setCountry(value);
        setDDI(currentCountry?.ddi);
        setCodeError(null);
      } else {
        setDDI('');
        setCountry('');
      }
    }

    setNewUserForm(prevPost => ({ ...prevPost, [name]: value }));
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setIsLoading(true);

      if (ddi !== '55') {
        delete newUserForm.ddd;
      }

      const payload = {
        ...newUserForm,
        country: country,
        ddi,
        plan: planIdentifier,
      };

      const { data: newUser } = await PlatformAPI.createFreeOrTrialSubscriptionPlatform(payload);

      if (newUser) {
        history.push(
          `/trial-period-loading?plan=${query.get('plan')}&value=${Number(plan.value)}&platform=${
            newUser.platform
          }&token=${newUser.token}`
        );
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
      setIsLoading(false);
    }
  }

  function getPlanMessage(planName: string) {
    switch (planName) {
      case 'Freemium':
        return `Inicie sua experiência na Proluno em nosso `;
      case 'Festival das Imparáveis':
        return 'Assuma o controle total sobre seu conteúdo, com o nosso ';
      default:
        return `Inicie seu teste de 15 dias grátis em nosso `;
    }
  }

  return (
    <Stack w="100%" direction="row" minH="100vh">
      {process?.env.NODE_ENV !== 'development' && (
        <Helmet>
          {/* RD Station Code */}
          <script
            type="text/javascript"
            async
            src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/9af8db5a-a9aa-4540-8513-74dbfe7a9532-loader.js"
          ></script>
          {/* End RD Station Code */}
        </Helmet>
      )}
      <Stack w="100%" direction="column" spacing={{ base: 0, md: 10, lg: 10 }}>
        <Box
          mx={{ base: 0, md: 10, lg: 10 }}
          mt={{ base: 0, md: 10, lg: 10 }}
          display="flex"
          w="100%"
          justifyContent={{ base: 'center', md: 'flex-start', lg: 'flex-start' }}
        >
          <Image src={logoProluno} w={150} />
        </Box>

        <Stack w="100%" direction="row" alignItems="center">
          <Container maxW="2xl" as="form" onSubmit={handleSubmit}>
            <Stack
              textAlign={{ base: 'center', md: 'start', lg: 'start' }}
              direction="column"
              spacing={3}
              my={2}
            >
              <Heading fontSize="3xl" fontWeight={700}>
                {getPlanMessage(plan?.name)}
                <Text as="span" color="#EB7129">
                  Plano {plan?.name}
                </Text>
              </Heading>
              <Text fontSize="sm" fontWeight={500} color="#20212370">
                Você não precisa cadastrar um cartão de crédito.
              </Text>
            </Stack>

            <Box my={2}>
              <Stack direction="column">
                <Stack direction="column">
                  <Text fontWeight={600}>Seu nome</Text>
                  <Input
                    name="fullName"
                    placeholder="Digite aqui"
                    value={newUserForm?.fullName}
                    onChange={handleChange}
                    focusBorderColor="#EB7129"
                    isRequired
                  />
                </Stack>
                <Stack direction="column">
                  <Text fontWeight={600}>Email</Text>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Digite aqui"
                    value={newUserForm?.email}
                    onChange={handleChange}
                    focusBorderColor="#EB7129"
                    isRequired
                  />
                </Stack>

                <Stack
                  spacing={5}
                  direction={{ base: 'column', md: 'row', lg: 'row' }}
                  w="100%"
                  alignItems="center"
                >
                  <Stack direction="column">
                    <Text fontWeight={600} my={-1}>
                      País
                    </Text>

                    {flag ? (
                      <Box display="flex" justifyContent="flex-start">
                        <Image
                          position="absolute"
                          src={flag}
                          mt={5}
                          ml={3}
                          w="24px"
                          h="18px"
                          display="flex"
                          justifyContent="flex-end"
                        />
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="flex-start">
                        <Box
                          position="absolute"
                          mt={5}
                          ml={3}
                          display="flex"
                          justifyContent="flex-start"
                          bgColor="#D9D9D9"
                          w="24px"
                          h="18px"
                        />
                      </Box>
                    )}

                    <Select
                      name="country"
                      placeholder={codeError ? 'Código inválido' : 'Selecione uma opção'}
                      focusBorderColor="#EB7129"
                      value={country}
                      onChange={handleChange}
                      isRequired
                      style={{ padding: '0 45px' }}
                    >
                      {DDIHelperBR.map(ddi => (
                        <option key={ddi.initials} value={ddi.initials.toLowerCase()}>
                          {ddi.country}
                        </option>
                      ))}
                    </Select>
                  </Stack>

                  <Stack width="full">
                    <Text fontWeight={600}>Telefone</Text>

                    <HStack width="full">
                      <Input
                        width="100px"
                        hidden={ddi !== '55'}
                        name="ddd"
                        onChange={handleChange}
                        value={newUserForm.ddd}
                        placeholder="Ex: 85"
                        focusBorderColor="#EB7129"
                        isInvalid={codeError}
                        isRequired={country === 'br'}
                        maxLength={2}
                        onInput={onlyNumber}
                      />

                      <Input
                        id="phone"
                        name="phone"
                        placeholder="----- ----"
                        onChange={handleChange}
                        value={newUserForm.phone}
                        isRequired
                        maxLength={20}
                        onInput={onlyNumber}
                      />
                    </HStack>
                  </Stack>
                </Stack>

                <Stack direction="row">
                  <Box w={{ base: '0', md: '80%', lg: '80%' }}></Box>
                  {codeError && (
                    <Text fontSize="xs" w="100%" color="#BB2124">
                      {codeError}
                    </Text>
                  )}
                </Stack>

                <Stack direction="column">
                  <Text fontWeight={600}>Crie uma senha</Text>
                  <InputGroup>
                    <Input
                      name="password"
                      type={show ? 'text' : 'password'}
                      placeholder="Digite aqui"
                      value={newUserForm?.password}
                      minLength={6}
                      onChange={handleChange}
                      focusBorderColor="#EB7129"
                      isRequired
                    />

                    <InputRightElement>
                      {show ? (
                        <BsEye color="#20212370" onClick={showOrHidePassword} cursor="pointer" />
                      ) : (
                        <BsEyeSlash
                          color="#20212370"
                          onClick={showOrHidePassword}
                          cursor="pointer"
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </Stack>

                <Text fontSize="xs" fontWeight={500}>
                  Ao clicar em 'Continuar', você concorda com o <Text as="u">termo geral</Text>, e
                  com a <Text as="u">política de privacidade</Text> da Proluno. Para mais
                  informações,
                  <Text
                    as="a"
                    target="_blank"
                    href="https://api.whatsapp.com/send/?phone=5585992652741&text&type=phone_number&app_absent=0"
                    color="#EB7129"
                  >
                    {' '}
                    clique aqui.
                  </Text>
                </Text>
              </Stack>
            </Box>

            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={{ base: 'center', md: 'end', lg: 'end' }}
              >
                <Button
                  colorScheme="orange"
                  my={5}
                  w={{ base: '100%', md: 200, lg: 200 }}
                  type="submit"
                  isDisabled={
                    isLoading ||
                    ![
                      newUserForm.fullName,
                      newUserForm.email,
                      country,
                      ddi,
                      newUserForm.phone,
                      newUserForm.password,
                    ].every(value => !!value)
                  }
                  isLoading={isLoading}
                  rightIcon={<AiOutlineRight size="15px" />}
                  style={{ outline: 'none', boxShadow: 'none' }}
                >
                  Continuar
                </Button>
              </Stack>
            </Box>
          </Container>
        </Stack>
      </Stack>

      <Stack
        w="100%"
        backgroundImage={freeRegistration}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        direction="row"
        hidden={width < MEDIUM_SCREEN_SIZE}
        minH="100vh"
      />
    </Stack>
  );
}
