import React, { useState, ChangeEvent } from 'react';
import { MdSearch } from 'react-icons/md';

import {
  CloseButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';

interface SearchInputProps extends InputProps {
  value: string;
  onSearch: (search: string) => void;
}

export default function SearchInput({ value, onSearch, ...rest }: SearchInputProps) {
  const [searchText, setSearchText] = useState(value);
  const [delay, setDelay] = useState<NodeJS.Timeout | null>(null);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const text = event.target.value;

    setSearchText(text);

    // Limpa o timeout anterior, se existir
    if (delay) clearTimeout(delay);

    // Define um novo timeout para chamar a função onSearch após 500ms
    const newTimeout = setTimeout(() => onSearch(text), 500);

    setDelay(newTimeout);
  }

  function clearSearch() {
    setSearchText('');
    onSearch(''); // Chamada da função onSearch ao limpar o texto
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Escape') {
      clearSearch();
    }
  }

  return (
    <InputGroup width={{ base: 'full', lg: '20rem' }} size="sm" {...rest}>
      <InputLeftElement
        pointerEvents="none"
        children={<MdSearch color="#2021235C" size={27.45} />}
        ml={2}
        mt={0.5}
      />

      <Input
        type="text"
        borderRadius={6}
        focusBorderColor="gray.300"
        placeholder="Buscar webhook"
        borderColor="gray.300"
        fontSize="sm"
        color="#0000005C"
        _placeholder={{ color: '#0000005C' }}
        pl={10}
        value={searchText}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />

      {searchText && (
        <InputRightElement
          children={<CloseButton onClick={clearSearch} size="sm" color="gray.500" />}
        />
      )}
    </InputGroup>
  );
}
