export const imageFileTypes = ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'];

export const videoFileTypes = [
  'video/3g2',
  'video/3gp',
  'video/3gpp',
  'video/3gpp2',
  'video/asf',
  'video/asx',
  'video/avi',
  'video/dv',
  'video/f4p',
  'video/f4v',
  'video/flv',
  'video/mjpeg',
  'video/mjpg',
  'video/mkv',
  'video/mov',
  'video/filme',
  'video/mp2',
  'video/mp3g',
  'video/mp4',
  'video/mpe',
  'video/mpeg',
  'video/mpg',
  'video/mpg4',
  'video/ogg',
  'video/ogv',
  'video/ogx',
  'video/qt',
  'video/rm',
  'video/viv',
  'video/vivo',
  'video/webm',
  'video/wm',
  'video/wmv',
  'video/wmx',
  'video/wvx',
  'video/m4v',
];
