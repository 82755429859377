import './index.css';

import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

import { ChakraProvider, ColorModeProvider, ColorModeScript } from '@chakra-ui/react';
import ThemeProvider, { ThemeContext } from './contexts/ThemeContext';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AuthProvider from './contexts/AuthContext';
import theme from './theme';

export const platformsWithFillInMainCard = ['lumencursos.com.br'];

export const platformsWithCopyModules = [
  'alexandrapedinotti.com.br',
  'paulacfrocha.com.br',
  'profcacardoso.com.br',
  'cursopreparar.com.br',
  'professorsoares.com.br',
  'presets.liviabrasil.com.br',
  'ifbplay.com.br',
  'cpha.com.br',
  'ensinedoseujeito.proluno.com.br',
  'cibeleaguiar.proluno.com.br',
  'local.proluno.com.br',
  'sandbox-eudesjr.proluno.com.br',
  'eudesjr.proluno.com.br',
  'cursos.liviabrasil.com.br',
  'cursos.vorticeconcursos.com.br',
  'voceaft.proluno.com.br',
  'alunosemescala.proluno.com.br',
  'pollylyra.com',
  'ronicelani.proluno.com.br',
  'metodosid.com',
  'professorgiovannydias.proluno.com.br',
  'geise.proluno.com.br',
  'litterartcursos.proluno.com.br',
  'doutorequilibrio.proluno.com.br',
  'qblitz.com.br',
  'juliasartini.proluno.com.br',
  'paulavasarini.proluno.com.br',
  'leticiarodrigues.proluno.com.br',
  'josivalensoela.proluno.com.br',
  'filtrodasblogueiras.proluno.com.br',
  'crescimentolucrativonodigital.proluno.com.br',
  'carolinamagon.proluno.com.br',
  'daniaconfortini.com.br',
  'marcellemartins.proluno.com.br',
  'ivyaugusto.proluno.com.br',
  'educacionalonline.com.br',
  'academiadodinheiro.proluno.com.br',
  'louiza.proluno.com.br',
  'rhskills.com.br',
  'carolinamoreno.proluno.com.br',
];

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider>
          <ThemeContext.Consumer>
            {props => (
              <ChakraProvider theme={props.themeChakra}>
                <ColorModeScript initialColorMode={theme.config.inicialColorMode} />
                <ColorModeProvider options={{ initialColorMode: theme.config.initialColorMode }}>
                  <App />
                </ColorModeProvider>
              </ChakraProvider>
            )}
          </ThemeContext.Consumer>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
