import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { FormEvent, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IParams, IRecurrencePlanResponse, IUserResponse } from '..';
import { GTMEventType } from '../../../ExternalScripts';
import { useAuth } from '../../../contexts/AuthContext';
import {
  FILLED_PERSONAL_INFORMATION_TIME,
  ROLE_LOCAL_STORAGE,
  TOKEN_PURCHASE,
} from '../../../helpers/LocalStorageHelper';
import { googleTagManagerIds, newEventGTM, removeEventGTM } from '../../../helpers/ga-tag-custom';
import useHandleChange from '../../../hooks/useHandleChange';
import useHandleSubmit, { ResponseJSON } from '../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../utils/scrollToInvalidInput';
import { RegisterSchemaType } from '../Register/schemaValidator';
import { FORM_STYLES } from '../constants';
import PaymentMethodsTabs from './PaymentMethods/PaymentMethodsTabs';
import SectionTitle from './SectionTitle';
import schemaValidator from './schemaValidator';
import { IPayment } from './types';
import getBuyButtonText from './utils/getBuyButtonText';
import redirectToThanksPage from './utils/redirectToThanksPage';

const origin = window.location.origin;
const termOfUseLink = `${origin}/term-of-use`;

interface PaymentProps {
  plan: IRecurrencePlanResponse;
  userData: IUserResponse;
  isLoading?: boolean;
  utmData?: {
    utm_id?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
  };
  thankPageType: string;
  thankPageForApprovedPurchases: string;
  personalData: Partial<RegisterSchemaType>;
}

interface IPurchaseResponse {
  data: {
    data: {
      purchaseId?: number;
      pixExpirationDate?: string;
      pixQrCode?: string;
      boletoCode?: string;
      boletoExpirationDate?: string;
      boletoLink?: string;
      totalValue?: number;
    };
  };
}

export default function Payment({
  plan,
  userData,
  thankPageType,
  thankPageForApprovedPurchases,
  isLoading,
  utmData,
  personalData,
}: PaymentProps) {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const { onUpdateTotalItemsInShoppingCart } = useAuth();
  const history = useHistory();
  const { idRecurrencePlan }: IParams = useParams();

  const initialForm: Partial<IPayment> = useMemo(
    () => ({
      paymentMethod: 'credit_card',
      useSavedCreditCard: false,
      saveDataForFuturePurchases: false,
      creditCard: {
        cardNumber: '',
        cardHolderName: '',
        cardCVV: '',
        cardExpirationMonth: '',
        cardExpirationYear: '',
      },
      UTMData: {
        utm_id: utmData?.utm_id,
        utm_source: utmData?.utm_source,
        utm_medium: utmData?.utm_medium,
        utm_campaign: utmData?.utm_campaign,
        utm_term: utmData?.utm_term,
        utm_content: utmData?.utm_content,
      },
    }),
    [
      utmData?.utm_campaign,
      utmData?.utm_content,
      utmData?.utm_id,
      utmData?.utm_medium,
      utmData?.utm_source,
      utmData?.utm_term,
    ]
  );

  const { form, setForm, handleChange } = useHandleChange<Partial<IPayment>>(initialForm);

  useEffect(() => window.scrollTo(0, 0), []);

  const GTMEventName = useCallback(
    async (event: GTMEventType, transaction_id?: number) => {
      const idsGTM = googleTagManagerIds();

      if (plan) {
        for (const id of idsGTM) {
          newEventGTM({
            id,
            data: {
              currency: 'BRL',
              event,
              transaction_id,
              name: personalData?.fullName,
              email: personalData?.email,
              phone: personalData?.phone ?? null,
              zip_code: null,
              payment_method: 'credit_card',

              items: plan.courses?.map(course => {
                return {
                  item_id: course?.id,
                  item_name: course?.name,
                };
              }),
              planName: plan?.name,
              total_value: plan?.value,
            },
          });

          removeEventGTM(id, event);
        }
      }
    },
    [personalData?.email, personalData?.fullName, personalData?.phone, plan]
  );

  const payload = {
    ...form,
    idRecurrencePlan: parseInt(idRecurrencePlan),
  };

  const {
    handleSubmit,
    formValidation,
    isLoading: isSubmitting,
  } = useHandleSubmit({
    schemaValidator: schemaValidator,
    data: payload,
    method: 'post',
    url: `/recurrence/purchase`,
    authenticated: true,
    onSuccess: {
      callback: (response: IPurchaseResponse) => {
        //envio de eventos
        if (payload.paymentMethod === 'credit_card') {
          GTMEventName('purchase', response?.data?.data?.purchaseId);
        }

        localStorage.removeItem(FILLED_PERSONAL_INFORMATION_TIME);
        onUpdateTotalItemsInShoppingCart(0);

        window.scrollTo({ top: 0 });

        if (thankPageType === 'EXTERNAL') {
          if (thankPageForApprovedPurchases) {
            localStorage.removeItem(TOKEN_PURCHASE);

            return (window.location.href = thankPageForApprovedPurchases);
          }
        }

        redirectToThanksPage({
          purchase_id: String(response.data.data.purchaseId) && null,
          payment_method: form.paymentMethod,
          pix_expiration_date: response.data.data.pixExpirationDate,
          pix_qr_code: response.data.data.pixQrCode,
          boleto_code: response.data.data.boletoCode,
          boleto_expiration_date: response.data.data.boletoExpirationDate,
          boleto_link: response.data.data.boletoLink,
          total_value: String(response.data.data.totalValue) && null,
          utm_id: utmData.utm_id,
          utm_source: utmData.utm_source,
          utm_medium: utmData.utm_medium,
          utm_campaign: utmData.utm_campaign,
          utm_term: utmData.utm_term,
          utm_content: utmData.utm_content,
          history,
        });
      },
    },
  });

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      scrollToInvalidInput();

      try {
        GTMEventName('processing_payment');

        await handleSubmit<ResponseJSON<any>>();
      } catch (error) {
        GTMEventName('cancelled_payment');
      }
    },
    [GTMEventName, handleSubmit]
  );

  return (
    <VStack
      align="center"
      w="full"
      as="form"
      pt="5"
      autoComplete="off"
      position="relative"
      onSubmit={onSubmit}
      noValidate
    >
      <Box as="section" w="full" sx={FORM_STYLES}>
        <SectionTitle number={0} title="Forma de Pagamento" />

        <PaymentMethodsTabs
          creditCard={form.creditCard}
          setForm={setForm}
          formValidation={formValidation}
          onCreditCardChange={handleChange}
          userData={userData}
        />
      </Box>

      <VStack w="full" spacing={1} align="start">
        {role && role !== 'ALUNO' && (
          <Text fontWeight="bold" textAlign="center" color="red.500">
            Seu perfil de usuário não permite a compra de produtos, para continuar, acesse como
            Aluno.
          </Text>
        )}

        <Button
          type="submit"
          colorScheme="green"
          px="4.5rem"
          fontSize="md"
          fontWeight="bold"
          w="full"
          bg="#7fcf69"
          _hover={{
            bgColor: '#66c64c',
            borderColor: '#5ec342',
          }}
          isDisabled={isLoading}
          isLoading={isSubmitting}
        >
          {getBuyButtonText(form?.paymentMethod)}
        </Button>

        <Text as="small" fontSize="xs" color="#202123">
          Ao concluir a compra você concorda com estes{' '}
          <Text
            as="a"
            href={termOfUseLink}
            color="default.500"
            target="_blank"
            rel="noopener noreferrer"
            textDecoration="underline"
            _hover={{ textDecoration: 'underline' }}
          >
            Termos de Serviços
          </Text>
          .
        </Text>
      </VStack>
    </VStack>
  );
}
