import { axiosBackend } from './Configuration';

export default {
  async getSettingsSEO() {
    try {
      const { data: getPlatfomrSettingsData } = await axiosBackend().get(
        '/platform-manager/find-settings-seo'
      );

      return getPlatfomrSettingsData;
    } catch (error) {
      throw error;
    }
  },
  async updateSettingsSEO(payload) {
    try {
      const { data: getPlatfomrSettingsData } = await axiosBackend().patch(
        '/platform-manager/update-settings-seo',
        payload
      );

      return getPlatfomrSettingsData;
    } catch (error) {
      throw error;
    }
  },
};
