import {
  Button,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import Poles from '../../../api/Poles';
import Pagination from '../../../components/Pagination';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';
import useQuery from '../../../hooks/useQuery';
import { onlyNumber } from '../../../hooks/useHandleChange';

type PolesType = {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  user: { id: number; name: string };
  state: string;
  city: string;
  neighborhood: string;
  streetAddress: string;
  streetNumber: string;
  zipCode: string;
  complementary: string;
};

const initialState = {
  name: '',
  email: '',
  phoneNumber: '',
  userId: '',
  addressPole: {
    state: '',
    city: '',
    neighborhood: '',
    streetAddress: '',
    streetNumber: '',
    zipCode: '',
    complementary: '',
  },
};

const initialStatePoleSelected = {
  id: null,
  name: '',
  email: '',
  phoneNumber: '',
  addressPole: {
    state: '',
    city: '',
    neighborhood: '',
    streetAddress: '',
    streetNumber: '',
    zipCode: '',
    complementary: '',
  },
  userId: '',
};

function PoleManagement() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [poles, setPoles] = useState<PolesType[]>([]);
  const [coProducers, setCoProducers] = useState([]);
  const [newPole, setNewPole] = useState(initialState);
  const [poleSelected, setPoleSelected] = useState(initialStatePoleSelected);
  const [isPoleEdit, setIsPoleEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [loadingAllPoles, setLoadingAllPoles] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const history = useHistory();
  const query = useQuery();

  async function getAllPoles() {
    try {
      setLoadingAllPoles(true);

      const { data: allPolesData } = await Poles.findAllPoles({ page: currentPage, perPage: 10 });

      if (allPolesData) {
        setPoles(allPolesData.data);
        setPageCount(allPolesData.total / allPolesData.perPage);
      }

      setLoadingAllPoles(false);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function getCopProducersSede() {
    const { data: coProducersSede } = await Poles.findCoProducersPole();

    if (coProducersSede) {
      setCoProducers(coProducersSede);
    }
  }

  async function deletePoleId(id) {
    try {
      await Poles.deletePole(id);
      await getAllPoles();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  function handlePageChange({ selected: selectedPage }) {
    history.push(`/pole-management?page=${selectedPage + 1}`);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = event.target;
    if (isPoleEdit) {
      setPoleSelected({ ...poleSelected, [name]: value });
    } else {
      setNewPole({ ...newPole, [name]: value });
    }
  }

  function handleChangeAddress(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = event.target;

    if (isPoleEdit) {
      setPoleSelected({
        ...poleSelected,
        addressPole: { ...poleSelected.addressPole, [name]: value },
      });
    } else {
      setNewPole({ ...newPole, addressPole: { ...newPole.addressPole, [name]: value } });
    }
  }

  function handleChangeSelect(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    if (isPoleEdit) {
      setPoleSelected({ ...poleSelected, [name]: parseInt(value) });
    } else {
      setNewPole({ ...newPole, [name]: parseInt(value) });
    }
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      setLoadingSubmit(true);
      if (!isPoleEdit) {
        const createData = { ...newPole };

        await Poles.createPole(createData);
      } else {
        const updatedData = { ...poleSelected };

        await Poles.updatedPole(updatedData);
      }

      onClose();

      await getAllPoles();

      setNewPole(initialState);

      setLoadingSubmit(false);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  useEffect(() => {
    getAllPoles();
    getCopProducersSede();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    let page: string | number = query.get('page');

    if (page) {
      try {
        page = parseInt(page);

        if (page < 1) {
          return history.push('/pole-management');
        }

        setCurrentPage(page);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }
  }, [history, query]);

  async function handleClick(pole) {
    setPoleSelected({
      id: pole.id,
      name: pole.name,
      email: pole.email,
      userId: pole.user?.id,
      phoneNumber: pole.phoneNumber,
      addressPole: {
        city: pole.city,
        neighborhood: pole.neighborhood,
        zipCode: pole.zipCode,
        complementary: pole.complementary,
        state: pole.state,
        streetAddress: pole.streetAddress,
        streetNumber: pole.streetNumber,
      },
    });
    setIsPoleEdit(true);
    onOpen();
  }

  async function handleDiscard() {
    setIsPoleEdit(false);
    setNewPole(initialState);
    setPoleSelected(initialStatePoleSelected);
    onClose();
  }

  return (
    <>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        alignItems="center"
        justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
        w="100%"
        textAlign="center"
      >
        <Heading fontWeight={600}>Gestão de Polos</Heading>
        <Button
          colorScheme="orange"
          size="sm"
          w={150}
          style={{ outline: 'none', boxShadow: 'none' }}
          onClick={onOpen}
          leftIcon={<AiOutlinePlus />}
        >
          <Text>Adicionar polo</Text>
        </Button>
      </Stack>

      <Stack direction="column" w="100%" my={5}>
        <Heading
          fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
          fontWeight={500}
          color="#20212350"
          hidden={poles.length ? true : false}
        >
          Faça a gestão dos polos da sua instituição. Cadastre as principais informações, como
          endereço e informações de contato.
        </Heading>

        <Heading
          fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
          fontWeight={500}
          color="#20212350"
          hidden={!poles.length ? true : false}
        >
          Compartilhe as receitas de seus cursos com seus professores, funcionários (profissional de
          marketing, social media, contadores, suportes administrativos e etc) e parceiros.
        </Heading>
      </Stack>

      {loadingAllPoles || loadingSubmit ? (
        <Stack direction="row" w="100%" justifyContent="center">
          <Spinner color="orange.400" size="md" />
        </Stack>
      ) : (
        ''
      )}

      {!poles.length && !loadingAllPoles && (
        <Stack direction="row" w="100%" justifyContent="center">
          <Heading fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} fontWeight="semibold">
            Você não tem polos cadastrados!
          </Heading>
        </Stack>
      )}

      {!loadingAllPoles &&
        poles.length > 0 &&
        poles.map(pole => (
          <Stack
            direction="column"
            alignItems="center"
            w="100%"
            bgColor="rgba(32, 33, 35, 0.05)"
            borderRadius={10}
            p={4}
            my={5}
          >
            <Stack direction="row" justifyContent="space-between" w="100%" alignItems="center">
              <Heading fontWeight={600} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>
                {pole.name}
              </Heading>

              <Menu>
                <MenuButton
                  colorScheme="gray "
                  color="rgba(32, 33, 35, 0.5)"
                  bgColor="rgba(32, 33, 35, 0.12)"
                  size="xs"
                  style={{ outline: 'none', boxShadow: 'none' }}
                  as={Button}
                >
                  <BsThreeDots />
                </MenuButton>
                <MenuList>
                  <MenuItem
                    style={{ outline: 'none', boxShadow: 'none' }}
                    color="#979CA6"
                    onClick={() => handleClick(pole)}
                  >
                    Editar
                  </MenuItem>
                  <MenuItem
                    style={{ outline: 'none', boxShadow: 'none' }}
                    color="#BB2124"
                    onClick={() => deletePoleId(pole.id)}
                  >
                    Excluir
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>

            <Stack
              direction={{ base: 'column', md: 'row', lg: 'row' }}
              spacing={{ base: 0, md: 5, lg: 5 }}
              justifyContent="space-between"
              w="100%"
              my={2}
            >
              <Stack direction="column" spacing={2} w={{ base: '100%', md: '34%', lg: '34%' }}>
                <Heading
                  fontWeight={500}
                  title={pole.user.name}
                  fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
                  isTruncated
                >
                  Gestor do polo:{' '}
                  <Text as="line" fontWeight={400}>
                    {pole.user.name}
                  </Text>
                </Heading>

                <Heading fontWeight={500} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} isTruncated>
                  End.:{' '}
                  <Text as="line" fontWeight={400}>
                    {pole.streetAddress ? pole.streetAddress.concat(',') : ''}{' '}
                    {pole.streetNumber ? pole.streetNumber.concat('-') : ''}{' '}
                    {pole.neighborhood ?? ''}
                    {!pole.streetAddress &&
                      !pole.streetNumber &&
                      !pole.neighborhood &&
                      'Endereço não informado'}
                  </Text>
                </Heading>
              </Stack>

              <Stack direction="column" spacing={2} w={{ base: '100%', md: '33%', lg: '33%' }}>
                <Heading fontWeight={500} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} isTruncated>
                  Cidade:{' '}
                  <Text as="line" fontWeight={400}>
                    {pole.city ?? 'Cidade não informada'}
                  </Text>
                </Heading>
                <Heading fontWeight={500} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>
                  Estado:{' '}
                  <Text as="line" fontWeight={400}>
                    {pole.state ?? 'Estado não informado'}
                  </Text>
                </Heading>
              </Stack>

              <Stack direction="column" spacing={2} w={{ base: '100%', md: '33%', lg: '33%' }}>
                <Heading
                  fontWeight={500}
                  fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
                  title={pole.email}
                  isTruncated
                >
                  E-mail:{' '}
                  <Text as="line" fontWeight={400}>
                    {pole.email}
                  </Text>
                </Heading>
                <Heading fontWeight={500} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }} isTruncated>
                  Telefone:{' '}
                  <Text as="line" fontWeight={400}>
                    {pole.phoneNumber || 'Não informado'}
                  </Text>
                </Heading>
              </Stack>
            </Stack>
          </Stack>
        ))}

      {poles.length > 10 && (
        <Pagination
          pageCount={pageCount}
          onPageActive={currentPage - 1}
          onPageChange={handlePageChange}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <form onSubmit={handleSubmit}>
          <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="3px" />
          <ModalContent>
            <ModalHeader fontWeight={800} fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}>
              {isPoleEdit ? 'Editar polo' : 'Adicione um polo'}
            </ModalHeader>
            <ModalCloseButton
              style={{ outline: 'none', boxShadow: 'none' }}
              onClick={handleDiscard}
            />
            <ModalBody>
              <Stack direction="column" spacing={5} w="100%">
                <Stack direction="column" spacing={2} w="100%">
                  <Text fontWeight={600} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>
                    Nome do polo
                  </Text>
                  <Input
                    focusBorderColor="orange.400"
                    placeholder="Digite aqui"
                    name="name"
                    value={isPoleEdit ? poleSelected.name : newPole.name}
                    onChange={handleChange}
                    isRequired
                  />
                </Stack>
                <Stack direction="column" spacing={2} w="100%">
                  <Text fontWeight={600} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>
                    Gestor do polo
                  </Text>
                  <Select
                    name="userId"
                    placeholder="Selecione um gestor"
                    focusBorderColor="orange.400"
                    isRequired
                    value={isPoleEdit ? poleSelected.userId : newPole.userId}
                    onChange={handleChangeSelect}
                  >
                    {coProducers &&
                      coProducers.map(coProducer => (
                        <option value={coProducer.id}>{coProducer.fullName}</option>
                      ))}
                  </Select>
                  <Text fontWeight={400} color="#20212350">
                    OBS: Para seleciona-ló é necessário cadastra-lo. Para isso vá no menu lateral em
                    Usuários {'>'} usuários da conta.
                  </Text>
                </Stack>

                <Stack direction="column" spacing={2} w="100%">
                  <Text fontWeight={600} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>
                    Endereço do polo:
                  </Text>
                  <Stack direction="row" spacing={5} w="100%">
                    <Stack direction="column" spacing={2} w={300}>
                      <Text fontWeight={600}>CEP</Text>
                      <Input
                        placeholder="00000-000"
                        focusBorderColor="orange.400"
                        name="zipCode"
                        value={
                          isPoleEdit
                            ? poleSelected.addressPole.zipCode
                            : newPole.addressPole.zipCode
                        }
                        onChange={handleChangeAddress}
                      />
                    </Stack>
                    <Stack direction="column" spacing={2} w="100%">
                      <Text fontWeight={600}>Rua</Text>
                      <Input
                        placeholder="Digite aqui"
                        focusBorderColor="orange.400"
                        name="streetAddress"
                        value={
                          isPoleEdit
                            ? poleSelected.addressPole.streetAddress
                            : newPole.addressPole.streetAddress
                        }
                        onChange={handleChangeAddress}
                      />
                    </Stack>

                    <Stack direction="column" spacing={2} w={200}>
                      <Text fontWeight={600}>Nº</Text>
                      <Input
                        placeholder="Digite"
                        focusBorderColor="orange.400"
                        name="streetNumber"
                        value={
                          isPoleEdit
                            ? poleSelected.addressPole.streetNumber
                            : newPole.addressPole.streetNumber
                        }
                        onChange={handleChangeAddress}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={5} w="100%">
                    <Stack direction="column" spacing={2} w="100%">
                      <Text fontWeight={600}>Bairro</Text>
                      <Input
                        placeholder="Digite aqui"
                        focusBorderColor="orange.400"
                        name="neighborhood"
                        value={
                          isPoleEdit
                            ? poleSelected.addressPole.neighborhood
                            : newPole.addressPole.neighborhood
                        }
                        onChange={handleChangeAddress}
                      />
                    </Stack>

                    <Stack direction="column" spacing={2} w="100%">
                      <Text fontWeight={600}>Cidade</Text>
                      <Input
                        placeholder="Digite aqui"
                        focusBorderColor="orange.400"
                        name="city"
                        value={
                          isPoleEdit ? poleSelected.addressPole.city : newPole.addressPole.city
                        }
                        onChange={handleChangeAddress}
                      />
                    </Stack>

                    <Stack direction="column" spacing={2} w="100%">
                      <Text fontWeight={600}>Estado</Text>
                      <Input
                        placeholder="Digite aqui"
                        focusBorderColor="orange.400"
                        name="state"
                        value={
                          isPoleEdit ? poleSelected.addressPole.state : newPole.addressPole.state
                        }
                        onChange={handleChangeAddress}
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction="column" spacing={2} w="100%">
                  <Text fontWeight={600} fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}>
                    Informações para contato:
                  </Text>
                  <Stack direction="row" spacing={5} w="100%">
                    <Stack direction="column" spacing={2} w={300}>
                      <Text fontWeight={600}>Telefone</Text>
                      <Input
                        placeholder="Digite aqui"
                        focusBorderColor="orange.400"
                        name="phoneNumber"
                        value={isPoleEdit ? poleSelected.phoneNumber : newPole.phoneNumber}
                        onChange={handleChange}
                        onInput={onlyNumber}
                        maxLength={20}
                      />
                    </Stack>
                    <Stack direction="column" spacing={2} w="100%">
                      <Text fontWeight={600}>E-mail</Text>
                      <Input
                        placeholder="Digite aqui"
                        focusBorderColor="orange.400"
                        name="email"
                        value={isPoleEdit ? poleSelected.email : newPole.email}
                        onChange={handleChange}
                        isRequired
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter justifyContent={{ base: 'center', md: 'flex-end', lg: 'flex-end' }}>
              <Button
                style={{ outline: 'none', boxShadow: 'none' }}
                colorScheme="gray"
                variant="solid"
                mr={3}
                onClick={handleDiscard}
              >
                Cancelar
              </Button>
              <Button
                style={{ outline: 'none', boxShadow: 'none' }}
                variant="solid"
                colorScheme="orange"
                type="submit"
              >
                {isPoleEdit ? 'Salvar' : 'Adicionar'}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

export default PoleManagement;
