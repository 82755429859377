import { Card, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { BiDotsVertical } from 'react-icons/bi';

interface ICardsInstitutionProps {
  institution: {
    id: number;
    name: string;
  };
  setInstitutionId: (value: number) => void;
  setInstitutionName: (value: string) => void;
  onOpenModalAddOrEditInstitution: () => void;
  onOpenModalDeleteInstitution: () => void;
}

export function CardInstitution({
  institution,
  setInstitutionId,
  setInstitutionName,
  onOpenModalAddOrEditInstitution,
  onOpenModalDeleteInstitution,
}: ICardsInstitutionProps) {
  function handleClick(action: string): void {
    setInstitutionId(institution?.id);
    setInstitutionName(institution?.name);

    if (action === 'delete') {
      onOpenModalDeleteInstitution();
      return;
    }

    onOpenModalAddOrEditInstitution();
  }

  return (
    <Card
      width={{ base: 'full', md: '230px', lg: '230px' }}
      height="49px"
      borderRadius={5}
      padding={3}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 4px 4px 0px #00000040"
      border="1px solid #2021230D"
    >
      <Text fontSize="12px" fontWeight={600} width="180px" isTruncated>
        {institution.name}
      </Text>
      <Stack height="full">
        <Menu>
          <MenuButton>
            <BiDotsVertical size={14} />
          </MenuButton>
          <MenuList>
            <MenuItem
              fontSize="12px"
              fontWeight={500}
              color="#20212380"
              onClick={() => handleClick('edit')}
            >
              Editar
            </MenuItem>
            <MenuItem
              fontSize="12px"
              fontWeight={500}
              color="#BB2124"
              onClick={() => handleClick('delete')}
            >
              Excluir
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
    </Card>
  );
}
