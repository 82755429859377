import { Box, Button, Container, Text } from '@chakra-ui/react';
import { FormEvent, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import CourseAPI from '../../../api/Course';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';
import useWindowSize from '../../../hooks/useWindowSize';

import {
  FiChevronLeft as ChevronLeftIcon,
  FiChevronRight as ChevronRightIcon,
} from 'react-icons/fi';
import { getContentType } from '../../NetflixMemberArea/utils/getContentType';

export default function StudentTerm() {
  const [numberPages, setNumberPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [showTermsOfUse, setShowTermsOfUse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { width } = useWindowSize();

  const { id: courseId, courseUserId } = useParams<{
    id: string;
    courseUserId: string;
  }>();
  const history = useHistory();
  const location = useLocation();
  const memberAreaVersion = location.pathname.split('/').includes('v2') ? 'v2' : 'v1';
  const isNetflixMemberArea = memberAreaVersion === 'v2';

  function onDocumentLoadSuccess({ numPages }) {
    setNumberPages(numPages);
  }

  useEffect(() => {
    async function showTerms(id: string) {
      if (id) {
        try {
          setIsLoading(true);

          const { data: courseData } = await CourseAPI.show(id);

          if (courseData?.termsOfUse) {
            setShowTermsOfUse(courseData?.termsOfUse);
          } else {
            const v = location.pathname.split('/').includes('v2') ? 'v2' : 'v1';

            if (v === 'v1') localStorage.removeItem('chakra-ui-color-mode');
            history.push(`/members-area/${v}/products/${courseId}/content-view`);
          }
        } catch (error) {
          Toast(ErrorResponse(error), 'error');
        } finally {
          setIsLoading(false);
        }
      }
    }
    showTerms(courseId);
  }, [courseId, history, location.pathname]);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const v = location.pathname.split('/').includes('v2') ? 'v2' : 'v1';
    setIsLoading(true);

    try {
      const { data: courseData } = await CourseAPI.show(courseId);

      if (courseData?.termsOfUse) {
        await CourseAPI.acceptTerms(courseUserId);
      }

      Toast('Você aceitou os termos de uso!');

      if (isNetflixMemberArea) {
        const firstModule = courseData?.modules?.[0];
        const firstContent = firstModule?.contents?.[0];
        const contentType = getContentType(firstContent?.contentType);

        return history.push(
          `/members-area/v2/course/${courseId}/module/${firstModule.id}/content/${firstContent.id}/content-view/${contentType}`
        );
      }

      history.push(`/members-area/${v}/products/${courseId}/content-view`);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box as="section" position="relative" width="100vw" height="100vh" background="white">
      <form onSubmit={handleSubmit}>
        <Container maxWidth="container.xl" paddingBottom="18vh">
          <Document file={showTermsOfUse} onLoadSuccess={onDocumentLoadSuccess} renderMode="svg">
            <Page
              pageNumber={pageNumber}
              renderAnnotationLayer={true}
              width={width - 16}
              height={width - 16}
            />
          </Document>

          <Box width="full" display="flex" justifyContent="center" alignItems="center">
            <Button
              leftIcon={<ChevronLeftIcon />}
              size={{ base: 'sm', md: 'md', lg: 'md' }}
              padding="0rem"
              marginRight="0.8rem"
              background="transparent"
              cursor="pointer"
              display="flex"
              alignItems="center"
              justifyContent="center"
              _hover={{
                background: 'none',
              }}
              _focus={{
                background: 'none',
                outline: 'none',
              }}
              onClick={() => {
                setPageNumber(pageNumber !== 1 ? pageNumber - 1 : pageNumber);
              }}
              isDisabled={pageNumber === 1}
            />
            <Text fontWeight="semibold" fontSize={{ base: 'sm', md: 'md', lg: 'md' }}>
              {pageNumber} de {numberPages}
            </Text>
            <Button
              rightIcon={<ChevronRightIcon />}
              padding="0rem"
              marginLeft="0.8rem"
              background="transparent"
              cursor="pointer"
              display="flex"
              size={{ base: 'sm', md: 'md', lg: 'md' }}
              alignItems="center"
              justifyContent="center"
              _hover={{
                background: 'none',
              }}
              _focus={{
                background: 'none',
                outline: 'none',
              }}
              onClick={() => {
                setPageNumber(pageNumber < numberPages ? pageNumber + 1 : pageNumber);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              isDisabled={pageNumber === numberPages}
            />
          </Box>
        </Container>

        <Box
          gap={5}
          paddingX="8"
          position="fixed"
          height="16vh"
          bottom="0"
          right="0"
          left="0"
          background="#E5E5E5"
          display="flex"
          alignItems="center"
          justifyContent={{ base: 'center', md: 'flex-end', lg: 'flex-end' }}
        >
          <Button
            as={Link}
            width="10rem"
            z-index={10}
            color="black"
            boxShadow="2px 2px 2px rgba(0, 0, 0, 0.25)"
            _hover={{
              background: '#E5E5E580',
            }}
            type="submit"
            isLoading={isLoading}
            isDisabled={isLoading}
            colorScheme="gray"
            to={memberAreaVersion === 'v1' ? '/members-area/v1/products' : '/members-area/v2'}
          >
            Voltar
          </Button>

          <Button
            width="10rem"
            colorScheme="orange"
            z-index={10}
            type="submit"
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            Aceitar
          </Button>
        </Box>
      </form>
    </Box>
  );
}
