import { Box, Button, Collapse, Heading, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CorrectIcon from '../../assets/icons/correct-icon.svg';
import { useTheme } from '../../contexts/ThemeContext';
import convertToBrazilianCurrency from '../../helpers/convertToBrazilianCurrency';

export default function CardSubscription({ plan, modules, courseId, course }) {
  const { themeMarketplace } = useTheme();
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <>
      <Box
        w={250}
        bg="white"
        h="auto"
        textAlign="center"
        borderRadius={10}
        m={5}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Heading size="lg" m={2} noOfLines={1} mt={5}>
          {plan.name}
        </Heading>
        <Box
          display="flex"
          h="auto"
          mx={3}
          my={2}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Collapse startingHeight={100} in={show}>
            {plan.description}
          </Collapse>
          {plan.description.length > 120 && (
            <Text
              mx={3}
              fontSize={10}
              onClick={handleToggle}
              mt="1rem"
              fontWeight="bold"
              cursor="pointer"
            >
              Ver {show ? 'Menos' : 'Mais'}
            </Text>
          )}
        </Box>

        <hr />
        {course.deadlineForReimbursement !== null && (
          <>
            <Box display="flex" justifyContent="center" alignItems="center">
              <>
                <Image src={CorrectIcon} mr={2} />
                <Text py={3}>Garantia de {course.deadlineForReimbursement} dias</Text>
              </>
            </Box>
            <hr />
          </>
        )}
        <Box display="flex" justifyContent="center" alignItems="center">
          <Image src={CorrectIcon} mr={2} />
          <Text py={3}>
            {modules} {modules === 1 ? 'Módulo' : 'Módulos'}
          </Text>
        </Box>
        <hr />
        <Box py={3}>
          <Heading as="line" size="lg" color="black">
            {`${convertToBrazilianCurrency(plan.value)}`}
          </Heading>
          <Text as="line" fontWeight="bold">
            /mês
          </Text>
        </Box>

        <Link
          to={`/product/${courseId}/subscription-checkout/personal-data?courseSubscriptionPlan=${plan.id}`}
        >
          <Button
            pointerEvents="none"
            size="sm"
            w={180}
            h="40px"
            bg={themeMarketplace.primaryColor}
            mb={5}
            color={themeMarketplace.textColor}
            _hover={{ color: 'gray' }}
          >
            Assinar
          </Button>
        </Link>
      </Box>
    </>
  );
}
