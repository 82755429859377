import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Spinner,
  TabPanel,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import useFetch from '../../../../hooks/useFetch';
import useHandleChange from '../../../../hooks/useHandleChange';
import DocumentInput from '../../../CheckoutV3/Payment/DocumentInput';
import { getBankNameByCode } from '../../utils/getBankNameByCode';

export interface Bank {
  id: number;
  bankCode: string;
  agencia: string;
  agenciaDv: string;
  conta: string;
  contaDv: string;
  legalName: string;
  bankAccountDefault: boolean;
}

export interface Response {
  documentNumber: string;
  banks: Bank[];
}

export default function SelectAccountTabPanel({ bankList }) {
  const toast = useToast();
  const { push } = useHistory();

  const { data: response, loading: isLoading } = useFetch<UnificadaFront.ResponseJSON<Response>>({
    url: '/recipient/bank-accounts-list',
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const bankAccountList = response?.data.banks;

  const formattedBankAccountList = bankAccountList?.map(bankAccount => {
    const agencyNumber = bankAccount.agenciaDv
      ? `${bankAccount.agencia}-${bankAccount.agenciaDv}`
      : bankAccount.agencia;

    const accountNumber = bankAccount.contaDv
      ? `${bankAccount.conta}-${bankAccount.contaDv}`
      : bankAccount.conta;

    return {
      id: bankAccount.id,
      name: `${bankAccount.legalName} | ${agencyNumber} | ${accountNumber} | ${getBankNameByCode(
        Number(bankAccount?.bankCode),
        bankList
      )}`,
      bankAccountDefault: bankAccount.bankAccountDefault,
    };
  });

  const defaultAccount = formattedBankAccountList?.find(account => account.bankAccountDefault);

  const {
    form,
    handleChange,
    onChanged: hasChange,
  } = useHandleChange<{ bankAccountId: number }>(
    { bankAccountId: null },
    !!response?.data.banks.length &&
      !isLoading && {
        bankAccountId: defaultAccount?.id,
      }
  );

  const { fetchData: handleSubmit, loading: isSubmitting } = useFetch<
    UnificadaFront.ResponseJSON<any>
  >({
    url: `/recipient/link-bank-account-to-recipient/${form.bankAccountId}`,
    method: 'patch',
    authenticated: true,
  });

  async function onSubmit(event) {
    event.preventDefault();

    try {
      await handleSubmit();

      toast({
        status: 'success',
        title: 'Conta alterada com sucesso!',
      });

      push('/profile/bank-accounts');
    } catch (error) {
      toast({
        status: 'error',
      });
    }
  }

  if (isLoading) {
    return (
      <Center height="64vh">
        <Spinner color="primary.500" size="xl" thickness="4px" speed="1s" emptyColor="gray.200" />
      </Center>
    );
  }

  return (
    <TabPanel>
      <VStack as="form" onSubmit={onSubmit} px={0.5} spacing={8}>
        <FormControl>
          <FormLabel color="#20212380">Documento</FormLabel>
          <DocumentInput
            value={response?.data?.documentNumber}
            focusBorderColor="gray.300"
            _placeholder={{ color: '#20212380' }}
            isDisabled
          />
        </FormControl>

        <FormControl>
          <FormLabel color="#202123">Selecione uma conta</FormLabel>
          <Select
            name="bankAccountId"
            value={form.bankAccountId}
            onChange={handleChange}
            focusBorderColor="gray.300"
            isDisabled={bankAccountList?.length < 2}
          >
            {formattedBankAccountList?.map(bankAccount => (
              <option value={Number(bankAccount.id)} key={bankAccount.id}>
                {bankAccount.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <HStack width="full" justifyContent={{ base: 'center', md: 'flex-end' }}>
          <Button
            type="submit"
            colorScheme="primary"
            color="secondary.500"
            width={{ base: '100%', lg: 'initial' }}
            isDisabled={!hasChange}
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </HStack>
      </VStack>
    </TabPanel>
  );
}
