import { Box, Spinner, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Paginate from '../../../../components/Paginate';
import useFetch from '../../../../hooks/useFetch';
import { IBlogPost, IPost } from '../../../Blog';
import Card from '../Card';

export default function PublicationsTab() {
  const [blogPosts, setBlogPosts] = useState<IPost[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const queryParams = new URLSearchParams();

  const addQueryParam = (paramName: string, paramValue: string | undefined) => {
    if (paramValue) {
      queryParams.append(paramName, paramValue);
    }
  };

  addQueryParam('page', currentPage.toString());

  const searchParams = queryParams.toString();

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<IBlogPost>>({
    url: `/blog?${searchParams}`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const posts = response?.data?.posts;
  const pageCount = response?.data?.total / response?.data?.per_page;

  useEffect(() => {
    if (posts) {
      setBlogPosts(posts);
    }
  }, [posts]);

  function handlePageChange({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  function deletePost(id: number) {
    const newArrayPosts = blogPosts.filter(post => post.id !== id);

    setBlogPosts(newArrayPosts);
  }

  return (
    <Box>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" width="full" height="300px">
          <Spinner size="lg" color="orange.500" />
        </Box>
      )}

      <Box display="flex" width="full" flexWrap="wrap" gap="20px">
        {blogPosts.map(post => (
          <Card key={post.id} post={post} deletePost={deletePost} />
        ))}
      </Box>

      {blogPosts?.length !== 0 && (
        <Stack width="full" justifyContent="center" alignItems="center" marginY={5}>
          <Paginate
            pageCount={pageCount}
            initialPage={currentPage}
            onPageChange={handlePageChange}
            mt={{ base: 8, xl: 4 }}
          />
        </Stack>
      )}
    </Box>
  );
}
