import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { IS_REACT_APP_HOSTNAME } from '../../../App';
import AdminAPI from '../../../api/Admin';
import Authentication from '../../../api/Authentication';
import RolesAPI from '../../../api/Roles';
import { Heading } from '../../../components/Heading';
import DotsFalling from '../../../components/Loadings/DotsFalling';
import Pagination from '../../../components/Pagination';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';
import useFetch from '../../../hooks/useFetch';
import useWindowSize from '../../../hooks/useWindowSize';
import { CardUsers } from './CardUsers';
import TableUsers from './TableUsers';
import ModalEditUser from './components/ModalEditUser';
import ModalNewUser from './components/ModalNewUser';
import ModalUserInformation from './components/ModalUserInformations';
import styles from './styles.module.css';
import { useHistory } from 'react-router-dom';

const switchStyle = {
  'span[data-checked]': {
    background: '#eb7129',
  },
  'span[data-checked]>span[data-checked]': {
    background: '#fff',
  },
};

export type UserType = {
  id: number;
  email: string;
  platform: {
    id: number;
    url: string;
  };
  role: {
    id: number;
    name: string;
  };
  status: string;
  lastAccess: string;
  subscriberSuspended: boolean;
};

export type UserData = {
  users: UserType[];
  total: number;
  page: number;
  per_page: number;
};

const initialValues = {
  id: 0,
  email: '',
  platform: {
    id: 0,
    url: '',
  },
  role: {
    id: 0,
    name: '',
  },
  status: '',
  lastAccess: '',
  subscriberSuspended: false,
};

const hostname = window.location.hostname;

export const LARGE_SCREEN_SIZE = 900;

const isUnificada = IS_REACT_APP_HOSTNAME(hostname);

function UserBase() {
  const [isLoading, setIsLoading] = useState(false);
  const [newUser, setNewUser] = useState(initialValues);
  const [userId, setUserId] = useState(newUser.id);
  const [roleName, setRoleName] = useState(newUser.role.name);
  const [loadingModal, setLoadingModal] = useState(false);
  const [onChanged, setOnChanged] = useState(true);
  const [updatedUser, setUpdatedUser] = useState(initialValues);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();

  const {
    isOpen: isOpenEditModal,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();

  const {
    isOpen: isOpenModalAdmin,
    onOpen: onOpenModalAdmin,
    onClose: onCloseModalAdmin,
  } = useDisclosure();

  const { width } = useWindowSize();
  const size = width < LARGE_SCREEN_SIZE ? 'sm' : '2xl';
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [timer, setTimer] = useState(0);

  const queryParams = new URLSearchParams();
  const addQueryParam = (paramName: string, paramValue: string | undefined) => {
    if (paramValue) {
      queryParams.append(paramName, paramValue);
    }
  };

  addQueryParam('page', currentPage.toString());
  addQueryParam('search', search);

  const searchParams = queryParams.toString();

  const {
    data: response,
    loading: isFetching,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<UserData>>({
    url: `/roles/users?${searchParams}`,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  const users = response?.data?.users;
  const total = response?.data?.total;
  const perPage = response?.data?.per_page;
  const pageCount = total / perPage;

  const isSearching = !!searchParams;
  const isUsersVisible = !isFetching && users?.length > 0;
  const isNotFoundVisible = !isFetching && !users?.length && isSearching;
  const isEmpty = !isFetching && !users?.length && !isSearching;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        fetchData();

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
  }, [fetchData, timer]);

  function handleSearchUser(event) {
    const { value } = event.target;

    setSearch(value);
    setCurrentPage(1);
    setTimer(2);
  }

  function handleChange(event) {
    const { value } = event.target;
    setRoleName(value);
  }

  function onChange(event) {
    const { name, value } = event.target;

    if (name === 'role') {
      setNewUser({ ...newUser, role: { ...newUser.role, name: value } });
      return;
    }

    setNewUser({ ...newUser, [name]: value });
  }

  function handlePageChange({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }

  async function handleDeleteUser(id: number) {
    try {
      users.filter(user => user.id !== id);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  function onChangeChecked(event) {
    const { name, checked } = event.target;

    setUpdatedUser({ ...updatedUser, [name]: !checked });
    setOnChanged(false);
  }

  async function expirePassword() {
    onOpenModalAdmin();

    try {
      const responseData = await AdminAPI.expirePassword(updatedUser.id);
      Toast(responseData.message);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      onCloseModalAdmin();
    }
  }

  async function showModalAdmin(id) {
    onOpenModalAdmin();
    const user = users?.find(user => user.id === id);

    if (user) {
      setUpdatedUser(user);
    } else {
      Toast('Usuário não encontrado.', 'error');
    }
  }

  function onChangeUpdate(event) {
    const { name, value, checked } = event.target;

    if (name === 'subscriberSuspended') {
      setUpdatedUser({ ...updatedUser, [name]: !checked });
    } else if (name === 'platform' && updatedUser?.platform?.url.includes('.proluno.com.br')) {
      setUpdatedUser({ ...updatedUser, [name]: { url: value.concat('.proluno.com.br') } });
    } else {
      setUpdatedUser({ ...updatedUser, [name]: value });
    }

    setOnChanged(false);
  }

  async function handleUpdateUser() {
    setLoadingModal(true);

    try {
      await AdminAPI.updateUser(updatedUser.id, updatedUser.email, false);

      users?.map(user => {
        if (user.id === updatedUser.id) {
          user.email = updatedUser.email;
          user.subscriberSuspended = updatedUser.subscriberSuspended;
          return user;
        }
        return user;
      });

      Toast('Alterado com sucesso.');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setLoadingModal(false);
      setOnChanged(true);
      onCloseModalAdmin();
    }
  }

  async function handleEditUser(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await RolesAPI.update(roleName, userId);
      Toast('Permissão de usuário alterada');
      setIsLoading(false);
      onCloseEditModal();
      fetchData();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
      setIsLoading(false);
    }
  }

  async function handleNewUser(event) {
    event.preventDefault();
    setLoadingModal(true);

    try {
      await RolesAPI.store(newUser.email, newUser.role.name);

      Toast('Convite enviado com sucesso', 'success');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
      setNewUser(initialValues);
    } finally {
      setIsLoading(false);
      onClose();
      setLoadingModal(false);
      fetchData();
    }
  }

  async function handleDomainUpdate() {
    setLoadingModal(true);

    try {
      const { data: response } = await AdminAPI.updateDomain(
        updatedUser.id,
        updatedUser.platform.url
      );

      users?.map(user => {
        if (user.id === updatedUser.id) {
          user.platform = response.url;
          return user;
        }

        return user;
      });

      Toast('Domínio atualizado com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
      setNewUser(initialValues);
    } finally {
      setIsLoading(false);
      onClose();
      setLoadingModal(false);
    }
  }

  async function deleteUser(id: number) {
    try {
      await RolesAPI.delete(id);
      Toast('Acesso de usuário revogado');
      users?.filter(user => user.id !== id);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function loginAs(userId: number) {
    try {
      const response = await Authentication.loginAsUser(userId);

      if (response) {
        return history.push('/login');
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  return (
    <div className="nk-app-root">
      <div className="nk-main">
        {isFetching ? (
          <div className="container d-flex justify-content-center mt-5">
            <DotsFalling />
          </div>
        ) : (
          <>
            <Stack direction="column" spacing={{ base: 2, md: 5, lg: 5 }}>
              <Heading fontSize={{ base: '24px', md: '36px', lg: '36px' }}>
                Usuários da conta
              </Heading>

              <Text
                fontSize={{ base: '14px', md: '18px', lg: '18px' }}
                color="#20212380"
                paddingBottom={{ base: '30px', md: 0, lg: 0 }}
              >
                Aqui você pode cadastrar perfis de usuários para auxiliar no gerenciamento da sua
                plataforma.
              </Text>

              <Stack
                direction={{ base: 'column-reverse', md: 'row', lg: 'row' }}
                spacing={{ base: '30px', md: 2, lg: 2 }}
                width="full"
                justifyContent="flex-end"
              >
                <InputGroup width={{ base: 'full', md: '338px', lg: '338px' }} size="sm">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<AiOutlineSearch color="gray" size="20px" />}
                  />
                  <Input
                    focusBorderColor="orange.400"
                    type="text"
                    borderRadius={6}
                    placeholder="Pesquise pelo tipo de acesso e e-mail"
                    name="filter"
                    value={search}
                    onChange={handleSearchUser}
                  />
                </InputGroup>

                {!IS_REACT_APP_HOSTNAME(hostname) && (
                  <Button
                    width={{ base: 'full', md: 150, lg: 150 }}
                    size="sm"
                    colorScheme="orange"
                    onClick={onOpen}
                  >
                    Cadastrar usuário
                  </Button>
                )}
              </Stack>
            </Stack>

            {width <= LARGE_SCREEN_SIZE && (
              <CardUsers
                users={users}
                setUserId={setUserId}
                setRoleName={setRoleName}
                onOpenEditModal={onOpenEditModal}
                isUnificada={isUnificada}
                subscriberSuspended={false}
                handleDeleteUser={handleDeleteUser}
                getUsers={() => []}
                isEmpty={isEmpty}
                isNotFoundVisible={isNotFoundVisible}
                isUsersVisible={isUsersVisible}
                deleteUser={deleteUser}
                loginAs={loginAs}
              />
            )}

            {width > LARGE_SCREEN_SIZE && (
              <TableUsers
                users={users}
                setUserId={setUserId}
                setRoleName={setRoleName}
                onOpenEditModal={onOpenEditModal}
                isUnificada={isUnificada}
                deleteUser={deleteUser}
                loginAs={loginAs}
                showModalAdmin={showModalAdmin}
                styles={styles}
                handleDeleteUser={handleDeleteUser}
                getUsers={() => []}
                isLoading={isFetching}
                subscriberSuspended={false}
                isEmpty={isEmpty}
                isNotFoundVisible={isNotFoundVisible}
                isUsersVisible={isUsersVisible}
              />
            )}

            <Box py="5">
              <Pagination
                pageCount={pageCount}
                onPageActive={currentPage - 1}
                onPageChange={handlePageChange}
              />
            </Box>
          </>
        )}

        <ModalNewUser
          handleNewUser={handleNewUser}
          initialValues={initialValues}
          isOpen={isOpen}
          onChange={onChange}
          loadingModal={loadingModal}
          newUser={newUser}
          onClose={onClose}
          setNewUser={setNewUser}
          setRoleName={setRoleName}
          setUserId={setUserId}
          size={size}
        />

        <ModalEditUser
          handleChange={handleChange}
          handleEditUser={handleEditUser}
          isLoading={isLoading}
          isOpen={isOpenEditModal}
          onClose={onCloseEditModal}
          roleName={roleName}
          setRoleName={setRoleName}
          setUserId={setUserId}
          size={size}
        />

        <ModalUserInformation
          expirePassword={expirePassword}
          handleDomainUpdate={handleDomainUpdate}
          handleUpdateUser={handleUpdateUser}
          isOpen={isOpenModalAdmin}
          onClose={onCloseModalAdmin}
          loadingModal={loadingModal}
          onChangeChecked={onChangeChecked}
          onChangeUpdate={onChangeUpdate}
          onChanged={onChanged}
          size={size}
          switchStyle={switchStyle}
          updatedUser={updatedUser}
        />
      </div>
    </div>
  );
}

export default UserBase;
