import { ChevronRightIcon } from '@chakra-ui/icons';
import { ListIcon, ListItem, ListItemProps } from '@chakra-ui/react';

interface FilterListItemProps extends ListItemProps {
  filterId: number;
  name: string;
  hasIcon?: boolean;
  isHovered?: boolean;
}

export default function FilterListItem({
  name,
  filterId,
  hasIcon = true,
  isHovered,
  ...props
}: FilterListItemProps) {
  return (
    <ListItem
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      transition="0.2s all"
      _hover={{ color: 'primary.500' }}
      color={isHovered && 'primary.500'}
      cursor="pointer"
      {...props}
    >
      {name} {hasIcon && <ListIcon as={ChevronRightIcon} boxSize={4} />}
    </ListItem>
  );
}
