import { Box, Stack, Text, useColorModeValue } from '@chakra-ui/react';

export default function Lines({ hiddenNumbers = true, hiddenBar = true }) {
  const textColor = useColorModeValue('#202123', '#FFFFFF');

  return (
    <>
      <Stack
        hidden={hiddenBar}
        width="full"
        direction="row"
        alignItems="center"
        height={4}
        borderRadius={5}
        spacing={0}
        paddingRight={{ base: '10px', lg: '25px' }}
      >
        <Text
          width={{ base: '22.5px', lg: '80px' }}
          padding={{ base: '11px', lg: '10px 60px 10px 0px' }}
        />

        {/* Linhas verticais */}
        <Box
          width="full"
          display="flex"
          justifyContent="space-between"
          sx={{ div: { width: '0.79px', height: 4, bgColor: '#cccccc' } }}
        >
          <Box bgColor="#000000 !important" />
          <Box />
          <Box />
          <Box />
          <Box />
        </Box>
      </Stack>

      <Stack
        width="full"
        direction="row"
        alignItems="center"
        height="15px"
        borderRadius={5}
        spacing={0}
        hidden={hiddenNumbers}
      >
        <Text
          width={{ base: '20px', lg: '80px' }}
          padding={{ base: '10px', md: '10px 30px', lg: '10px 30px' }}
        />

        <Box
          width="full"
          display="flex"
          justifyContent="space-between"
          pl={{ base: 1, lg: 0 }}
          pt={1.5}
        >
          <Text color={textColor} fontSize={{ base: '12px', lg: '16px' }} fontWeight="500" ml={-1}>
            0
          </Text>
          <Text color={textColor} fontSize={{ base: '12px', lg: '16px' }} fontWeight="500" ml={3}>
            25%
          </Text>
          <Text
            color={textColor}
            fontSize={{ base: '12px', lg: '16px' }}
            fontWeight="500"
            ml={{ base: 2.5, lg: 0 }}
          >
            50%
          </Text>
          <Text
            color={textColor}
            fontSize={{ base: '12px', lg: '16px' }}
            fontWeight="500"
            ml={{ base: 2.5, lg: 0 }}
          >
            75%
          </Text>
          <Text color={textColor} fontSize={{ base: '12px', lg: '16px' }} fontWeight="500">
            100%
          </Text>
        </Box>
      </Stack>
    </>
  );
}
