import styled, { css } from 'styled-components';

import { Box } from '@chakra-ui/react';

const styles = css`
  color: white;
  background: linear-gradient(360deg, #426bb6 0%, #426bb6 0.01%, #26458c 100%);
  border-radius: 0.625rem;
  transition: 700ms;
  box-shadow: 3px 3px 12px 0px #00000073, -3px -3px 12px 0px #ffffff14;
`;

export const Container = styled(Box)`
  ${() => css`
    perspective: 58rem;
    cursor: pointer;

    &:hover .card-front {
      transform: rotateY(180deg);
      z-index: 0;
    }

    &:hover .card-back {
      transform: rotateY(0);
      z-index: 1;
    }
  `}
`;

export const CardFront = styled(Box)`
  ${() => css`
    ${styles};
    z-index: 1;

    &.front-rotate-click {
      transform: rotateY(180deg);
      z-index: 0;
    }
  `}
`;

export const CardBack = styled(Box)`
  ${() => css`
    ${styles};
    transform: rotateY(-180deg);
    backface-visibility: hidden;

    display: flex;
    align-items: flex-end;

    &.back-rotate-click {
      transform: rotateY(0);
      z-index: 1;
    }
  `}
`;
