import { IRecurrencePayload } from '../pages/DashboardAdmin/Financial/Transaction';
import { axiosBackend } from './Configuration';

const TransactionAPI = {
  index: async ({
    page = '1',
    search = '',
    startDate = '',
    endDate = '',
    paymentMethod,
    status,
  }) => {
    try {
      const urlSearchParams = new URLSearchParams();

      if (page) urlSearchParams.set('page', page);
      if (search) urlSearchParams.set('search', search);
      if (startDate) urlSearchParams.set('startDate', startDate);
      if (endDate) urlSearchParams.set('endDate', endDate);
      if (paymentMethod.length > 0) urlSearchParams.set('paymentMethod', paymentMethod);
      if (status.length > 0) urlSearchParams.set('status', status);

      const { data: transactionData } = await axiosBackend().get(
        `/purchases?${urlSearchParams.toString()}`
      );

      return transactionData;
    } catch (error) {
      throw error;
    }
  },
  show: async purchase_id => {
    try {
      const { data: transactionData } = await axiosBackend().get(`/purchases/${purchase_id}`);
      return transactionData;
    } catch (error) {
      throw error;
    }
  },

  showPurchasesCompany: async () => {
    try {
      const { data: purchasesData } = await axiosBackend().get('/purchases/company');

      return purchasesData;
    } catch (error) {
      throw error;
    }
  },

  createWithdrawal: async data => {
    try {
      const { data: purchasesData } = await axiosBackend().post('/purchases/transfer', data);

      return purchasesData;
    } catch (error) {
      throw error;
    }
  },

  verifyStatus: async (verifyRecurrences: IRecurrencePayload[]) => {
    try {
      const recurrences = verifyRecurrences.map(recurrence => ({
        idRecurrencePagarme: recurrence.idRecurrencePagarme,
        transactionIds: recurrence.transactionIds,
      }));

      await axiosBackend().patch(`/recurrence/verify-transactions-status`, { recurrences });
    } catch (error) {
      throw error;
    }
  },
};

export default TransactionAPI;
