import styles from './styles.module.css';

const Badge = ({ status, children }) => {
  let background = '#778899';

  switch (status) {
    case 'waiting_payment':
      background = '#EDCA32';
      break;
    case 'paid':
      background = '#22BB33';
      break;
    case 'refused':
      background = '#E85347';
      break;
    case 'refunded':
      background = '#202123';
      break;
    case 'chargedback':
      background = '#202123	';
      break;
    case 'manual':
      background = '#464688	';
      break;
    default:
      background = '#778899	';
      break;
  }

  return (
    <span className={styles.badge} style={{ background }}>
      {children}
    </span>
  );
};

export default Badge;
