import { HStack, Text, VStack, Image } from '@chakra-ui/react';
import Timer from './Timer';
import usePromotionBanner from './usePromotionBanner';
import { TOKEN_ORIGIN_LOGIN } from '../../helpers/LocalStorageHelper';

export default function PromotionBanner() {
  const { isVisible, pathname, timeLeft, promotion, promotionStarted } = usePromotionBanner();

  const primaryColor = promotion?.primaryColor ?? '#eb7129';
  const secondaryColor = promotion?.secondaryColor ?? '#ffffff';
  const isMemberArea = pathname.includes('members-area');
  const tokenOrigin = localStorage.getItem(TOKEN_ORIGIN_LOGIN);

  if (!isVisible) return null;

  return (
    <HStack
      id="PromotionBanner"
      justify="space-between"
      align="center"
      bgColor={primaryColor}
      position="fixed"
      w="full"
      px={{ base: 4, xl: 12 }}
      pb={tokenOrigin ? { base: 8, xl: 4 } : { base: 4, xl: 4 }}
      mt={isMemberArea ? 0 : { base: '3.45rem', xl: 0 }}
      justifyContent="space-between"
      zIndex="banner"
      minHeight="6.5rem"
      pt={tokenOrigin ? { base: 12, xl: 2 } : { base: 0, xl: 2 }}
    >
      {/* MOBILE */}
      <VStack display={{ base: 'flex', xl: 'none' }} align="start" maxWidth="56%" spacing={0}>
        {promotion.typeMain === 'image' ? (
          <Image
            src={promotion?.logoMain}
            maxWidth="6.25rem"
            maxHeight="2.75rem"
            objectFit="contain"
            alt={promotion?.title || promotion?.description}
          />
        ) : (
          <Text fontSize="2xl" fontWeight="bold" color={secondaryColor} maxWidth="9.5rem">
            {promotion?.textMain || 'Texto Principal'}
          </Text>
        )}

        <Text
          fontSize="sm"
          fontWeight="normal"
          color={promotion?.secondaryColor}
          dangerouslySetInnerHTML={{ __html: promotion?.textHighlight || 'Texto Secundário' }}
          sx={{ p: { textAlign: 'left !important' } }}
        />
      </VStack>

      {/* DESKTOP */}
      {promotion.typeMain === 'image' ? (
        <Image
          display={{ base: 'none', xl: 'block' }}
          src={promotion?.logoMain}
          maxWidth="17.5rem"
          maxHeight="4.5rem"
          objectFit="contain"
          alt={promotion.title || promotion.description}
        />
      ) : (
        <Text
          display={{ base: 'none', xl: 'block' }}
          fontSize="2rem"
          fontWeight="bold"
          color={secondaryColor}
          maxWidth="21.875rem"
        >
          {promotion?.textMain || 'Texto Principal'}
        </Text>
      )}

      <Text
        display={{ base: 'none', xl: 'block' }}
        fontSize="xl"
        fontWeight="medium"
        dangerouslySetInnerHTML={{ __html: promotion?.textHighlight || 'Texto Secundário' }}
        color={secondaryColor}
        maxWidth="30rem"
      />

      <Timer
        timeLeft={timeLeft}
        promotionStarted={promotionStarted}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
    </HStack>
  );
}
