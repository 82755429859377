import { TabPanel, TabPanels, Tabs, useBreakpointValue } from '@chakra-ui/react';
import { useEffect } from 'react';
import { MdCreditCard as CreditCardIcon } from 'react-icons/md';
import { IUserResponse } from '../../..';
import { FormValidation } from '../../../../../hooks/useHandleSubmit';
import { CreditCardType, IPayment } from '../../types';
import CreditCard from '../CreditCard';

interface PaymentTabProps {
  creditCard: CreditCardType;
  formValidation?: FormValidation<Partial<IPayment>>;
  onCreditCardChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  userData: IUserResponse;
  setForm: (params: any) => void;
}

export default function PaymentTabs({
  creditCard,
  formValidation,
  userData,
  setForm,
  onCreditCardChange,
}: PaymentTabProps) {
  const isMobile = useBreakpointValue({ base: true, xl: false });

  const paymentMethods = [
    {
      id: 1,
      name: 'Cartão de crédito',
      icon: <CreditCardIcon />,
      value: 'credit_card',
      component: (
        <CreditCard
          creditCard={creditCard}
          formValidation={formValidation?.creditCard}
          onChange={onCreditCardChange}
        />
      ),
    },
  ];

  // Faz o scroll do tab selecionado ficar no centro da tela no mobile
  useEffect(() => {
    if (isMobile) {
      const activeTabButton = document.querySelector(`[aria-selected="true"]`);
      activeTabButton?.scrollIntoView({ block: 'center', inline: 'center' });
    }
  }, [isMobile]);

  return (
    <Tabs index={0} overflowX="auto" variant="unstyled" isLazy p={0}>
      <TabPanels>
        {paymentMethods?.map(option => (
          <TabPanel key={option?.id} px={0.5} pt={8}>
            {option?.component}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
