import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormEvent, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import CourseSubscriptionPlanAPI from '../../../../../../api/CourseSubscriptionPlan';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import Spinner from '../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../components/Toast';

const initialState = {
  name: '',
  description: '',
  value: '',
  recurrence: 'MÊS',
  paymentMethod: 'credit_card',
  planDuration: '',
  hasTestPeriod: false,
  maxPayments: 0,
  trialDays: 0,
};

export default function Plan() {
  const { id: courseId, planId } = useParams<{ id: string; planId: string }>();

  const [newPlan, setNewPlan] = useState(initialState);
  const [hasChanged, setHasChanged] = useState(false);
  const [planDuration, setPlanDuration] = useState('ATE_O_CANCELAMENTO');
  const [freeTest] = useState('MOMENTO_DA_ASSINATURA');
  const [isOpen, setIsOpen] = useState(false);
  const [hasTestPeriod] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const payload = {
        ...newPlan,
        planDuration,
        freeTest,
        hasTestPeriod,
        paymentMethod: newPlan.paymentMethod.split(','),
        maxPayments: Number(newPlan.maxPayments),
      };

      if (!planId) {
        await CourseSubscriptionPlanAPI.store(payload);
        Toast('Plano Cadastrado', 'success');
        history.push(`/courses/${courseId}/course-manager/price/subscription`);
        return;
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setNewPlan(prevPost => ({ ...prevPost, [name]: value }));
    setHasChanged(true);
  }

  // function handleChangeSwitch(event) {
  //   const { checked } = event.target;

  //   if (!checked) {
  //     setFreeTest('MOMENTO_DA_ASSINATURA');
  //   }

  //   setHasTestPeriod(checked);
  // }

  return (
    <form onSubmit={handleSubmit} style={{ paddingBottom: '5rem' }}>
      <Heading as="h4" fontSize="2xl" fontWeight="bold">
        Criar Plano:
      </Heading>
      <Box borderRadius={10} border="solid 1px rgba(32, 33, 35, 0.5)" boxSizing="border-box" p={5}>
        <Text fontSize="xl" fontWeight="bold">
          Informações Principais
        </Text>
        <Stack spacing={2}>
          <Box display="flex" justifyContent="space-between">
            <Text fontSize="sm" fontWeight="bold">
              Nome
            </Text>
            <Box>Caracteres: ({newPlan.name.length} / 15)</Box>
          </Box>
          <Input
            isRequired
            name="name"
            value={newPlan.name}
            onChange={handleChange}
            focusBorderColor="default.400"
            type="text"
            maxLength={15}
            minLength={1}
            placeholder="Digite o nome do plano"
          />
          <Box display="flex" justifyContent="space-between">
            <Text fontSize="sm" fontWeight="bold">
              Descrição
            </Text>
            <Box>Caracteres: ({newPlan.description.length} / 150)</Box>
          </Box>
          <Input
            isRequired
            name="description"
            onChange={handleChange}
            value={newPlan.description}
            focusBorderColor="default.400"
            type="text"
            placeholder="Descreva este plano"
            maxLength={150}
            minLength={5}
          />
        </Stack>
        <Stack mt="5">
          <Text fontSize="xl" fontWeight="bold">
            Precificação
          </Text>
          <HStack>
            <FormControl>
              <FormLabel fontSize="sm" fontWeight="bold">
                Valor do Plano
              </FormLabel>
              <InputGroup size="sm">
                <Input
                  isRequired
                  name="value"
                  onChange={handleChange}
                  value={newPlan.value}
                  focusBorderColor="default.400"
                  placeholder="Digite um valor"
                  size="md"
                  type="number"
                  step="0.01"
                  min="0.01"
                  max="10000.00"
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel fontSize="sm" fontWeight="bold">
                Recorrência
              </FormLabel>

              <Select
                isRequired
                name="recurrence"
                onChange={handleChange}
                value={newPlan.recurrence}
                focusBorderColor="default.400"
                placeholder="Selecione uma opção"
              >
                <option value="MÊS">Mensal</option>
                {/* <option value="option 2">Bimestral</option>
              <option value="option 3">Semestral</option>
              <option value="option 4">Anual</option> */}
              </Select>
            </FormControl>
          </HStack>
        </Stack>

        <Stack spacing={2} mt="5">
          <FormControl>
            <FormLabel fontSize="sm" fontWeight="bold">
              Forma de Pagamento
            </FormLabel>
            <Select
              isRequired
              name="paymentMethod"
              onChange={handleChange}
              value={newPlan.paymentMethod}
              focusBorderColor="default.400"
              placeholder="Selecione uma forma de pagamento"
            >
              <option value="credit_card">Cartão de Crédito</option>
              <option value="boleto">Boleto</option>
              <option value="credit_card,boleto">Cartão de Crédito e Boleto</option>
            </Select>
          </FormControl>
        </Stack>

        <Stack spacing={2} mt="5">
          <FormControl>
            <FormLabel fontSize="sm" fontWeight="bold">
              Duração do Plano
            </FormLabel>
            <RadioGroup onChange={setPlanDuration} value={planDuration} mb={5}>
              <Stack direction="column">
                <Box onClick={() => setIsOpen(false)}>
                  <Radio value="ATE_O_CANCELAMENTO" borderColor="gray" colorScheme="default">
                    Até o cliente realizar o cancelamento
                  </Radio>
                </Box>
                <Box onClick={() => setIsOpen(true)}>
                  <Radio
                    value="NUMERO_MAXIMO_DE_PAGAMENTOS"
                    borderColor="gray"
                    colorScheme="default"
                  >
                    Definir um número máximo de pagamento
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
            {isOpen ? (
              <NumberInput borderColor="gray">
                <NumberInputField
                  placeholder="Defina um número máximo de pagamento"
                  name="maxPayments"
                  onChange={handleChange}
                  value={newPlan.maxPayments}
                />
              </NumberInput>
            ) : (
              ''
            )}
          </FormControl>
        </Stack>
        {/* <Stack spacing={2}>
          <Box>
            <Text fontSize="xl" fontWeight="bold">
              Período de Teste
            </Text>
            <FormControl display="flex">
              <HStack>
                <Switch
                  name="hasTestPeriod"
                  defaultChecked={false}
                  isChecked={hasTestPeriod}
                  onChange={handleChangeSwitch}
                  id="isTrial"
                  size="md"
                  colorScheme="default"
                />
                <FormLabel htmlFor="isTrial">Oferecer período de teste</FormLabel>
              </HStack>
            </FormControl>
          </Box>
        </Stack>

        <Stack spacing={2} mt="5">
          {hasTestPeriod && (
            <Box>
              <Text fontWeight="bold">Tipo de Teste gratuito</Text>
              <RadioGroup onChange={setFreeTest} value={freeTest} colorScheme="default">
                <Stack direction="column">
                  <Radio value="MOMENTO_DA_ASSINATURA" borderColor="gray" colorScheme="default">
                    Pagamento no momento da assinatura
                  </Radio>
                  <Radio value="APOS_O_TESTE" borderColor="gray">
                    Pagamento após o teste gratuito
                  </Radio>
                </Stack>
              </RadioGroup>
              <NumberInput
                onChange={(_valueAsString, valueAsNumber) =>
                  setNewPlan({ ...newPlan, trialDays: valueAsNumber })
                }
                value={newPlan.trialDays}
                mt="5"
                min={1}

                // borderColor="gray"
              >
                <NumberInputField disabled={freeTest === 'MOMENTO_DA_ASSINATURA'} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>
          )}
        </Stack> */}
        <Box mt="5">
          <HStack display="flex" justifyContent="flex-end">
            <Link to={`/courses/${courseId}/course-manager/price/subscription`}>
              <Button size="sm" colorScheme="default" variant="outline">
                Voltar
              </Button>
            </Link>
            <Button isDisabled={!hasChanged} type="submit" size="sm" colorScheme="default">
              {isLoading ? <Spinner /> : 'Salvar Plano'}
            </Button>
          </HStack>
        </Box>
      </Box>
    </form>
  );
}
