import { Input, InputProps } from '@chakra-ui/react';
import InputMask from 'react-input-mask';

function unmask(value: string) {
  return value.replace(/\D/g, '');
}

export default function ZipCodeInput(props: InputProps) {
  const { value, onChange, ...rest } = props;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const unmaskedValue = unmask(event.target.value);
    event.target.value = unmaskedValue;
    onChange(event);
  }

  return (
    <InputMask
      mask="99999-999"
      value={value}
      onChange={handleChange}
      inputMode="numeric"
      maskChar=""
    >
      {(inputProps: InputProps) => (
        <Input autoComplete="new-zipcode" placeholder="Digite o CEP" {...rest} {...inputProps} />
      )}
    </InputMask>
  );
}
