import {
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import CouponAPI from '../../../api/Coupon';
import Pagination from '../../../components/Pagination';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';
import useQuery from '../../../hooks/useQuery';
import { CardList } from './CardList';
import { ModalNewOrEditCoupon } from './ModalNewOrEditCoupon';
import NoData from './NoData';
import { TableList } from './TableList';

const per_page = 10;

function Coupons() {
  const query = useQuery();
  const history = useHistory();

  const [coupons, setCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [couponId, setCouponId] = useState(null);
  const [timer, setTimer] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [search, setSearch] = useState('');

  const paginationCoupons = useCallback(
    async (page, per_page, search) => {
      if (!page) return;

      setIsLoading(true);

      try {
        if (search && timer > 0) return;

        const { data: couponsData } = await CouponAPI.index({
          page,
          per_page,
          search,
        });

        if (couponsData) {
          setCoupons(couponsData?.data);
          setPageCount(couponsData?.total / couponsData?.per_page);
        } else {
          setCoupons([]);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    },
    [timer]
  );

  useEffect(() => {
    let page = query.get('page');

    const pushRoute = '/coupons?page=1';

    if (page) {
      try {
        page = parseInt(page);

        if (page < 1) {
          return history.push(pushRoute);
        }

        if (page === currentPage) return;

        setCurrentPage(page);
      } catch (error) {
        history.push(pushRoute);
      }
    } else {
      return history.push(pushRoute);
    }
  }, [currentPage, history, query]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer > 0) {
        setTimer(prevState => (prevState < 0 ? 0 : --prevState));
      }
    }, 800);

    return () => clearTimeout(timeout);
  }, [timer]);

  useEffect(() => {
    paginationCoupons(currentPage, per_page, search);
  }, [currentPage, paginationCoupons, search]);

  function handlePageChange({ selected: selectedPage }) {
    history.push(`/coupons?page=${selectedPage + 1}`);
  }

  function onChangeSearch(event) {
    const { value } = event.target;

    setSearch(value);
    setTimer(2);
  }

  return (
    <>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        justifyContent={{ base: 'start', md: 'space-between', lg: 'space-between' }}
        w="100%"
      >
        <Stack direction="column" alignItems="center" spacing={5} mb={{ base: 5, md: 3, lg: 3 }}>
          <Heading fontSize={{ base: '2xl', lg: '4xl' }} fontWeight="semibold" w="100%">
            Cupons de desconto
          </Heading>

          <Text color="#20212390" fontWeight={400} fontSize={{ base: 'sm', md: 'md', lg: 'md' }}>
            Oferecer descontos é uma excelente estratégia para promover seus produtos online e
            aumentar as vendas. Nesta seção você cria e gerencia todos os seus cupons.
          </Text>
        </Stack>

        <Button
          onClick={onOpen}
          colorScheme="orange"
          style={{ outline: 'none', boxShadow: 'none' }}
          w={{ base: '100%', md: 220, lg: 250 }}
          size="sm"
        >
          + Novo cupom
        </Button>
      </Stack>

      <Stack
        spacing={4}
        direction="row"
        w="100%"
        alignItems="center"
        justifyContent="flex-end"
        my={{ base: 5, md: 0, lg: 0 }}
      >
        <InputGroup w={{ base: '100%', md: '30%', lg: '30%' }} size="sm" hidden={!coupons.length}>
          <InputLeftElement
            pointerEvents="none"
            children={
              <Box mt={3}>
                <BsSearch color="gray" />
              </Box>
            }
          />
          <Input
            type="text"
            placeholder="Pesquisar por nome ou status"
            onChange={event => onChangeSearch(event)}
            value={search}
            focusBorderColor="orange.500"
            my={2}
            borderRadius={6}
          />
        </InputGroup>
      </Stack>

      {!coupons.length && !isLoading && <NoData />}

      {isLoading && (
        <Stack direction="row" w="100%" justifyContent="center" my={10}>
          <Spinner size="lg" color="orange.500" />
        </Stack>
      )}

      {coupons.length > 0 && !isLoading && (
        <>
          <Box display={{ base: 'none', md: 'block', lg: 'block' }}>
            <TableList
              coupons={coupons}
              currentPage={currentPage}
              per_page={per_page}
              search={search}
              paginationCoupons={paginationCoupons}
              onOpen={onOpen}
              setCouponId={setCouponId}
            />
          </Box>
          <Box display={{ base: 'block', md: 'none', lg: 'none' }}>
            <CardList
              coupons={coupons}
              currentPage={currentPage}
              per_page={per_page}
              search={search}
              paginationCoupons={paginationCoupons}
              onOpen={onOpen}
              setCouponId={setCouponId}
            />
          </Box>

          {coupons?.length !== 0 && (
            <div className="mt-4">
              <Pagination
                pageCount={pageCount}
                onPageChange={handlePageChange}
                onPageActive={currentPage - 1}
              />
            </div>
          )}
        </>
      )}

      <ModalNewOrEditCoupon
        isOpen={isOpen}
        onClose={onClose}
        couponId={couponId}
        setCouponId={setCouponId}
        paginationCoupons={paginationCoupons}
        page={currentPage}
        per_page={per_page}
        search={search}
      />
    </>
  );
}

export default Coupons;
