import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import HomepageAPI from '../../../../api/Homepage';
import knowYourPlanImage from '../../../../assets/images/know-your-plan.png';
import Toast from '../../../../components/Toast';
import { useSubscription } from '../../../../contexts/SubscriptionContext';
import convertToBrazilianCurrency from '../../../../helpers/convertToBrazilianCurrency';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { WHATSAPP_LINK } from '../constants';
import Card from './Card';
import FilterButtons, { Filter } from './FilterButtons';
import InformationsItem from './InformationsItem';
import LastRegisteredProduct from './LastRegisteredProduct';
import MoreInformationsButton from './MoreInformationsButton';
import PlanFeatures from './PlanFeatures';
import WithoutProductRegistered from './WithoutProductRegistered';

type StatusType = 'ATIVO' | 'INATIVO' | 'EM_EDICAO';

export interface CurrentPlan {
  planName: string;
  totalProducts: number;
  totalStudent: number;
  storage: number;
  totalWebSites: number;
  isOldPlan: boolean;
}

export interface LastRegisteredProductProps {
  id: number;
  productName: string;
  description: string;
  thumbnail: string;
  productStatus: StatusType;
}

export interface Informations {
  amountOfSales: string;
  totalStudents: string;
  invoicing: string;
  currentPlan: CurrentPlan;
  lastRegisteredProduct: LastRegisteredProductProps;
}

function translateFeature(key: string, value: any) {
  const features = {
    totalProducts: !value ? 'Produtos ilimitados' : value > 1 ? ' produtos' : ' produto',
    totalStudent: ' alunos ativos',
    storage: ' GB de armazenamento',
    totalWebSites: ' website',
  };

  return features[key];
}

function InformationsSection(props: BoxProps) {
  const [filter, setFilter] = useState<Filter>('week');
  const [informations, setInformations] = useState<Informations>();
  const [isLoading, setIsLoading] = useState(true);

  const { subscriptionStatus, openModal, onChangeActiveModalContent } = useSubscription();

  useEffect(() => {
    (async () => {
      try {
        const response = await HomepageAPI.show(filter);
        setInformations(response?.data);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [filter]);

  function handleFilterChange(filter: Filter) {
    setIsLoading(true);
    setFilter(filter);
  }

  function openSubscriptionModal() {
    onChangeActiveModalContent('subscription');
    openModal();
  }

  const isSubscribed = subscriptionStatus?.isSubscribed;
  const currentPlan = informations?.currentPlan;

  const planFeatures =
    currentPlan &&
    Object.entries(currentPlan)
      .filter(([key]) => key !== 'planName' && key !== 'isOldPlan')
      .map(([key, value]) => ({
        name: translateFeature(key, value),
        value: value ? value : '',
      }));

  return (
    <Box as="section" {...props}>
      <Heading
        as="h2"
        fontSize={{ base: 'xl', md: '3xl' }}
        fontFamily="Montserrat"
        fontWeight="semibold"
      >
        Informações
      </Heading>

      <HStack justify="space-between" align="center" mt={4}>
        <FilterButtons value={filter} onChange={handleFilterChange} />

        <MoreInformationsButton display={{ base: 'none', md: 'block' }} />
      </HStack>

      <Stack direction={{ base: 'column', md: 'row' }} spacing={6} mt={4} textAlign="center">
        <InformationsItem
          title="Alunos"
          icon="students"
          value={informations?.totalStudents}
          isLoading={isLoading}
        />

        <InformationsItem
          title="Quantidade de vendas"
          icon="shoppingCart"
          value={informations?.amountOfSales}
          isLoading={isLoading}
        />

        {informations?.invoicing && (
          <InformationsItem
            title="Faturamento"
            icon="billing"
            value={`${convertToBrazilianCurrency(informations?.invoicing)}`}
            isLoading={isLoading}
          />
        )}

        <MoreInformationsButton margin="0 auto" display={{ base: 'block', md: 'none' }} />
      </Stack>

      {/* Planos */}
      <Stack direction={{ base: 'column', md: 'row' }} spacing={6} mt={10}>
        <Card
          title={`${isSubscribed ? 'Seu plano' : 'Conhecer plano'}`}
          subtitle={informations?.currentPlan?.planName}
          image={knowYourPlanImage}
          bgSize="cover"
        >
          <Flex direction="column" justify="space-between" mt={5} flex={1}>
            {!informations?.currentPlan?.isOldPlan ? (
              <PlanFeatures planFeatures={planFeatures} />
            ) : (
              <Text as="span" fontSize="md" color="#202123" maxW={48}>
                Você possui um plano personalizado. Se tiver alguma dúvida ou precisar de ajuda
                clique em saiba mais.
              </Text>
            )}

            {isSubscribed ? (
              <Button
                as="a"
                href={WHATSAPP_LINK}
                target="_blank"
                mt={6}
                colorScheme="orange"
                size="sm"
                maxW={24}
                _focus={{ outline: 'none' }}
              >
                Saiba mais
              </Button>
            ) : (
              <ButtonGroup mt={6}>
                <Button
                  onClick={openSubscriptionModal}
                  variant="solid"
                  colorScheme="orange"
                  size="sm"
                >
                  Assinar agora
                </Button>
                <Button
                  as="a"
                  href={WHATSAPP_LINK}
                  target="_blank"
                  variant="outline"
                  colorScheme="orange"
                  bg="white"
                  size="sm"
                >
                  Saiba mais
                </Button>
              </ButtonGroup>
            )}
          </Flex>
        </Card>

        {informations?.lastRegisteredProduct ? (
          <LastRegisteredProduct
            id={informations.lastRegisteredProduct.id}
            productName={informations?.lastRegisteredProduct?.productName}
            thumbnail={informations?.lastRegisteredProduct?.thumbnail}
            description={informations?.lastRegisteredProduct?.description}
            productStatus={informations?.lastRegisteredProduct?.productStatus}
          />
        ) : (
          <WithoutProductRegistered />
        )}
      </Stack>
    </Box>
  );
}

export default InformationsSection;
