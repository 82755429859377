import { HStack, Heading, Square } from '@chakra-ui/react';

interface SectionTitleProps {
  number?: number;
  title: string;
}

export default function SectionTitle({ number, title }: SectionTitleProps) {
  return (
    <HStack w="full" spacing="6px">
      <Square
        hidden={!number}
        bg="default.500"
        fontSize="sm"
        fontWeight="bold"
        color="white"
        size={6}
        borderRadius="5px"
      >
        {number}.
      </Square>
      <Heading fontSize="sm" fontWeight="medium" color="default.500" textTransform="uppercase">
        {title}
      </Heading>
    </HStack>
  );
}
