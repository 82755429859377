import { Component } from 'react';
import Slider, { ResponsiveObject } from 'react-slick';
import MainCard from '../../../../../../components/MainCard';
import { settingInstructor } from '../../../../Arrows/slickSettings';

interface SimpleSliderProps {
  courses: any;
  isLoading: boolean;
}

export default class ReactSlider extends Component<SimpleSliderProps> {
  render() {
    const { courses, isLoading } = this.props;

    const responsive: ResponsiveObject[] | undefined = [
      {
        breakpoint: 1340,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
          centerMode: true,
          arrows: false,
        },
      },
    ];

    if (courses?.length) {
      return (
        <Slider {...settingInstructor} responsive={responsive}>
          {courses?.map(course => (
            <MainCard course={course} checkoutType={course.checkoutType} loading={isLoading} />
          ))}
        </Slider>
      );
    }
  }
}
