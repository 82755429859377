import DotFalling from '../DotsFalling';

interface IDataLoading {
  className?: any;
  loading: boolean;
  children: React.ReactNode;
}

export default function DataLoading({ className, loading, children }: IDataLoading) {
  if (loading) {
    return (
      <div className={className}>
        <DotFalling className="mt-1 ml-1" />
      </div>
    );
  }

  return <>{children}</>;
}
