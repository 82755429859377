import {
  CloseButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import SearchIcon from '../../../../../Affiliations/Affiliates/icons/SearchIcon';
import useDebounce from '../../../../../../../hooks/useDebounce';
import { useState, ChangeEvent, useEffect } from 'react';

interface SearchInputProps {
  value: string;
  onSearch: (value: string) => void;
}

export default function SearchInput({ value, onSearch }: SearchInputProps) {
  const [search, setSearch] = useState(value || '');

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    onSearch(debouncedSearch);
  }, [debouncedSearch, onSearch]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;

    setSearch(value);
  }

  function clearSearch() {
    setSearch('');
  }

  return (
    <InputGroup maxW={{ lg: '20rem' }} size="sm">
      <InputLeftElement
        pointerEvents="none"
        children={<SearchIcon color="#2021235C" boxSize="20px" />}
        ml={2.5}
      />

      <Input
        value={search}
        onChange={handleChange}
        borderRadius="md"
        placeholder="Buscar por questão"
        focusBorderColor="primary.500"
        _placeholder={{ fontSize: 'sm', color: '#0000005C' }}
        pl="2.75rem"
        borderColor="#CBD5E0"
      />

      {search && (
        <InputRightElement
          children={<CloseButton onClick={clearSearch} size="sm" color="gray.500" />}
        />
      )}
    </InputGroup>
  );
}
