import { axiosBackend } from './Configuration';

const BlogAPI = {
  store: async post => {
    try {
      const { data: postData } = await axiosBackend().post('/blog', post);

      return postData;
    } catch (error) {
      throw error;
    }
  },

  show: async postId => {
    try {
      const { data: postsData } = await axiosBackend().get(`/blog/${postId}`);

      return postsData;
    } catch (error) {
      throw error;
    }
  },

  update: async (postId, post) => {
    try {
      const { data: postData } = await axiosBackend().patch(`/blog/${postId}`, post);

      return postData;
    } catch (error) {
      throw error;
    }
  },

  delete: async postId => {
    try {
      await axiosBackend().delete(`/blog/${postId}`);
    } catch (error) {
      throw error;
    }
  },

  categories: {
    index: async () => {
      try {
        const { data: categoriesData } = await axiosBackend().get('/blog/categories');

        return categoriesData;
      } catch (error) {
        throw error;
      }
    },
  },

  blogSetting: {
    createOrUpdate: async payload => {
      try {
        const { data: blogData } = await axiosBackend().post('/blog-setting', payload);
        return blogData;
      } catch (error) {
        throw error;
      }
    },
  },
};

export default BlogAPI;
