import { Box, HStack, Heading, Text, Image, GridItem, Badge } from '@chakra-ui/react';
import { Integration } from '..';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface IntegrationItemProps {
  integration: Partial<Integration>;
}

export default function IntegrationItem({ integration }: IntegrationItemProps) {
  const { logo, path, title, status, categoryTitle } = integration;

  const { url: currentUrl } = useRouteMatch();
  const history = useHistory();

  function handleClick() {
    if (integration.isImplemented) {
      history.push(`${currentUrl}/${path}`);
    }
  }

  return (
    <GridItem color={integration.isImplemented ? 'auto' : '#00000040'}>
      <HStack
        onClick={handleClick}
        bgColor="#ffffff"
        boxShadow="0px 4px 4px 0px #00000040"
        border="1px solid #2021230D"
        borderRadius="0.3125rem"
        p={2.5}
        gap={2.5}
        cursor="pointer"
      >
        <Image boxSize={12} src={logo} alt={`Logo ${path}`} />

        <Box overflow="hidden">
          <Heading as="h3" size="sm" fontWeight="semibold" mb={0} isTruncated>
            {title}
          </Heading>

          <Text color="#00000040" fontWeight="medium" mt={1} isTruncated>
            {categoryTitle}
          </Text>

          <Text
            color={status === 'ACTIVE' ? '#22bb33' : '#bb2124'}
            fontWeight="medium"
            hidden={!integration.isImplemented}
          >
            {status === 'ACTIVE' ? 'Integrado' : 'Não integrado'}
          </Text>

          <Badge fontWeight="medium" hidden={integration.isImplemented}>
            Em breve
          </Badge>
        </Box>
      </HStack>
    </GridItem>
  );
}
