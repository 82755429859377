import '../styles.css';
import { ChangeEvent, memo, useEffect, useState } from 'react';
import Collapse from 'react-collapsible';
import EditorRichText from '../../../../../../../../../components/EditorRichText';
import ErrorResponse from '../../../../../../../../../helpers/ErrorResponse';
import QuestionAPI from '../../../../../../../../../api/Question';
import Spinner from '../../../../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../../../../components/Toast';
import { convertHTMLToText } from '../../../../../../../../../helpers/convertHTMLToText';
import { useParams } from 'react-router-dom';
import { useToggle } from '../../../../../../../../../hooks/useToggle';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';

type Params = {
  id: string;
  moduleId: string;
  contentId: string;
};

function Item({ questionId, option, OnDeleteOption }) {
  const { id: optionId } = option;

  const { id: courseId, moduleId, contentId } = useParams<Params>();

  const [currentOption, setCurrentOption] = useState({
    id: '',
    option: '',
    answerOption: '',
    correct: false,
  });

  const [isShowModal, setIsShowModal] = useToggle(false);

  const [isLoading, setIsLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    setCurrentOption(option);
  }, [option]);

  function handleChecked(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;

    setCurrentOption({ ...currentOption, correct: checked });
  }

  function onEditorStateChangeAnswerOption(value) {
    const newAnswerOption = { ...currentOption, answerOption: value };

    setCurrentOption(newAnswerOption);
  }

  async function handleUpdated() {
    try {
      setIsLoading(true);

      const payload = {
        answerOption: currentOption.answerOption as string,
        correct: !!currentOption.correct,
      };

      await QuestionAPI.optionQuestion.update(
        Number(courseId),
        Number(moduleId),
        Number(contentId),
        questionId,
        optionId,
        payload
      );

      Toast('Item atualizado com sucesso!');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDelete() {
    try {
      setIsLoading(true);

      await QuestionAPI.optionQuestion.delete(
        Number(courseId),
        Number(moduleId),
        Number(contentId),
        questionId,
        optionId
      );

      setIsShowModal();
      Toast('Item excluído com sucesso!');
      OnDeleteOption();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  function handleCollapse() {
    setCollapse(prevCollapse => !prevCollapse);
  }

  return (
    <div className="item-dragdrop">
      <div className="accordion accordion-item">
        <div className="card card-item">
          <div className="card-header card-header-item d-flex">
            <button className="accordion-button" type="button" onClick={handleCollapse}>
              <div className="d-flex">
                <div className="icon-dragdrop">
                  {/* <svg
                    width="12"
                    height="16"
                    viewBox="0 0 14 23"
                    fill="#202123"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.6 20.125C5.6 21.7062 4.34 23 2.8 23C1.26 23 0 21.7062 0 20.125C0 18.5438 1.26 17.25 2.8 17.25C4.34 17.25 5.6 18.5438 5.6 20.125ZM2.8 8.625C1.26 8.625 0 9.91875 0 11.5C0 13.0813 1.26 14.375 2.8 14.375C4.34 14.375 5.6 13.0813 5.6 11.5C5.6 9.91875 4.34 8.625 2.8 8.625ZM2.8 0C1.26 0 0 1.29375 0 2.875C0 4.45625 1.26 5.75 2.8 5.75C4.34 5.75 5.6 4.45625 5.6 2.875C5.6 1.29375 4.34 0 2.8 0ZM11.2 5.75C12.74 5.75 14 4.45625 14 2.875C14 1.29375 12.74 0 11.2 0C9.66 0 8.4 1.29375 8.4 2.875C8.4 4.45625 9.66 5.75 11.2 5.75ZM11.2 8.625C9.66 8.625 8.4 9.91875 8.4 11.5C8.4 13.0813 9.66 14.375 11.2 14.375C12.74 14.375 14 13.0813 14 11.5C14 9.91875 12.74 8.625 11.2 8.625ZM11.2 17.25C9.66 17.25 8.4 18.5438 8.4 20.125C8.4 21.7062 9.66 23 11.2 23C12.74 23 14 21.7062 14 20.125C14 18.5438 12.74 17.25 11.2 17.25Z"
                      fill="current"
                    />
                  </svg> */}
                </div>
                <span
                  title={convertHTMLToText(currentOption.answerOption)}
                  className="item-title answer-option"
                >
                  {currentOption.option}. {convertHTMLToText(currentOption.answerOption)}
                </span>
              </div>
            </button>
            <button
              type="button"
              onClick={event => {
                setIsShowModal();
                event.preventDefault();
              }}
              className="trash-button"
            >
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.12 7.47L7 9.59L4.87 7.47L3.46 8.88L5.59 11L3.47 13.12L4.88 14.53L7 12.41L9.12 14.53L10.53 13.12L8.41 11L10.53 8.88L9.12 7.47ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6Z"
                  fill="#BB2124"
                />
              </svg>
            </button>
          </div>

          <Collapse trigger="" open={collapse}>
            <div id={`accordionItem${optionId}`}>
              <div className="card-body">
                <div style={{ cursor: 'initial' }}>
                  <div className="form-group" style={{ marginBottom: '24px' }}>
                    <EditorRichText
                      value={currentOption.answerOption}
                      onChange={onEditorStateChangeAnswerOption}
                    />
                  </div>

                  <div className="d-flex align-items-center">
                    <label className="mb-0 order-1" htmlFor={`correct-${currentOption.id}`}>
                      Resposta correta.
                    </label>
                    <input
                      type="checkbox"
                      id={`correct-${currentOption.id}`}
                      name="correct"
                      checked={currentOption.correct}
                      onChange={handleChecked}
                      className="mr-1 order-0"
                    />
                  </div>

                  <div className="form-group form-group-my">
                    <button
                      type="button"
                      className="btn btn-primary save-button"
                      onClick={handleUpdated}
                    >
                      {isLoading ? <Spinner /> : 'Salvar'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <Modal isOpen={isShowModal} onClose={() => setIsShowModal()} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Excluir item?</ModalHeader>

          <ModalBody>Após exclusão, você não terá mas informações sobre o item.</ModalBody>

          <ModalFooter>
            <Stack direction="row" spacing={2}>
              <Button colorScheme="gray" onClick={() => setIsShowModal()} size="sm">
                Cancelar
              </Button>

              <Button
                size="sm"
                colorScheme="red"
                onClick={handleDelete}
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Excluir
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default memo(Item);
