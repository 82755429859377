import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';

interface EditModalProps {
  onConfirm: (event) => void;
  onClose: ModalProps['onClose'];
  isOpen: ModalProps['isOpen'];
  affiliateName: string;
  isEditing: boolean;
  affiliationId: number;
  setPercentage: (value: number) => void;
  percentage: number;
  editing: boolean;
}

export default function EditModal(props: EditModalProps) {
  const toast = useToast();
  const isCentered = useBreakpointValue({ base: true, md: false });
  const { onClose, percentage, setPercentage, isEditing, affiliationId, editing } = props;

  const {
    data: percentageResponse,
    loading,
    error,
    fetchData: getPercentage,
  } = useFetch<
    UnificadaFront.ResponseJSON<{ percentageAffiliate: number; splitAvailableAffiliation: number }>
  >({
    method: 'get',
    url: `/affiliations/${affiliationId}/split-available-affiliation`,
    authenticated: true,
    autoFetch: false,
  });

  const percentageAffiliate = percentageResponse?.data?.percentageAffiliate;
  const splitAvailable = percentageResponse?.data?.splitAvailableAffiliation;
  const [splitAvailableAffiliation, setSplitAvailableAffiliation] = useState(0);

  useEffect(() => {
    if (percentageResponse?.data) {
      setPercentage(percentageResponse?.data?.percentageAffiliate);
      setSplitAvailableAffiliation(percentageResponse?.data?.splitAvailableAffiliation);
    } else {
      setPercentage(0);
      setSplitAvailableAffiliation(0);
    }
  }, [percentageResponse?.data, setPercentage]);

  useEffect(() => {
    if (error) {
      setPercentage(0);
      setSplitAvailableAffiliation(0);
    }
  }, [error, setPercentage, toast]);

  useEffect(() => {
    if (affiliationId && editing) {
      getPercentage();
    }
  }, [affiliationId, getPercentage, editing]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    let inputValue = 0;

    if (!value) {
      setPercentage(0);
    } else {
      setPercentage(parseFloat(value));
      inputValue = parseFloat(value);

      if (inputValue > splitAvailable + percentageAffiliate) {
        inputValue = splitAvailable + percentageAffiliate;
        setPercentage(splitAvailable + percentageAffiliate);
      }
    }

    setSplitAvailableAffiliation(splitAvailable + percentageAffiliate - inputValue);
  }

  return (
    <Modal
      {...props}
      isCentered={isCentered}
      onClose={() => {
        setPercentage(percentageAffiliate);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={props.onConfirm}
        px={{ base: 4, md: 5 }}
        maxW={{ base: '92%', md: '38.25rem' }}
      >
        <ModalHeader display="flex" justifyContent="start" px={0} fontWeight="bold" fontSize="md">
          Editar Afiliação
        </ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} mr={1} mt={1} />
        <ModalBody px={0}>
          <FormControl>
            <FormLabel
              htmlFor="commission"
              fontSize="sm"
              color="#202123"
              fontWeight="medium"
              mb={0}
            >
              Comissão
            </FormLabel>

            <Text
              fontSize="sm"
              color="#20212380"
              fontWeight="normal"
              mt={1}
              display={{ base: 'none', md: 'initial' }}
            >
              Porcentagem dada aos seus afiliados após a realização de um venda. Lembre-se esse
              valor será retirado da porcentagem referente ao seu plano de assinatura.
            </Text>

            <InputGroup mt={1}>
              <Input
                id="commission"
                type="number"
                onChange={handleChange}
                value={percentage.toString()}
                maxLength={5}
                max={splitAvailable + percentageAffiliate}
                focusBorderColor="primary.500"
              />
              <InputRightAddon bg="#e9e9e9" color="#2D3748" children="%" />
            </InputGroup>

            <FormHelperText>
              <Text>
                Porcentagem disponível:
                <Text as="strong">
                  {' '}
                  {loading ? <Spinner size="xs" /> : `${splitAvailableAffiliation}%`}
                </Text>
              </Text>
            </FormHelperText>
          </FormControl>
        </ModalBody>
        <ModalFooter justifyContent="end" gap={3} px={0} pb={8}>
          <Button
            size="sm"
            isDisabled={isEditing}
            onClick={() => {
              setPercentage(percentageAffiliate);
              onClose();
            }}
          >
            Cancelar
          </Button>

          <Button
            size="sm"
            colorScheme="primary"
            isDisabled={isEditing}
            isLoading={isEditing}
            color="textColor"
            type="submit"
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
