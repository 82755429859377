import { Box, Button, Flex, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { settingsCategories } from '../../Arrows/slickSettings';
import MainCard from '../../../../components/MainCard';
import { Heading } from '../../../../components/Heading';
import { useTheme } from '../../../../contexts/ThemeContext';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

function CategoriesSection({ section }) {
  const [categories, setCategories] = useState([]);

  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      setCategories(section);
    }
  }, [themeMarketplace, isLoadingThemeMarketplace, section]);

  if (!categories.active) return '';

  return (
    <>
      {categories.active && (
        <Box as="section" padding={{ base: '24px 15px', md: '40px 107px', lg: '40px 107px' }}>
          <Heading
            fontSize={{ base: '24px', md: '32px', lg: '32px' }}
            fontWeight={{ base: 500, md: 600, lg: 600 }}
          >
            {categories?.themeCategory?.name}
          </Heading>

          {section?.themeCategory?.viewMode === 'list' ? (
            <Slider
              {...settingsCategories}
              responsive={[
                {
                  breakpoint: 1366,
                  settings: {
                    swipeToSlide: 4,
                    slidesToShow: 4,
                  },
                },
                {
                  breakpoint: 1270,
                  settings: {
                    swipeToSlide: 3,
                    slidesToShow: 3,
                  },
                },
                {
                  breakpoint: 990,
                  settings: {
                    swipeToSlide: 2,
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    swipeToSlide: 1,
                    slidesToShow: 1,
                    centerMode: true,
                  },
                },
                {
                  breakpoint: 450,
                  settings: {
                    swipeToSlide: 1,
                    slidesToShow: 1,
                    centerMode: true,
                    nextArrow: <></>,
                    prevArrow: <></>,
                  },
                },
              ]}
            >
              {categories?.themeCategory.courses.map((course, index) => (
                <Stack direction="row" marginY={5} key={index}>
                  <MainCard
                    key={course.id}
                    course={course}
                    loading={isLoadingThemeMarketplace}
                    checkoutType={course.checkoutType}
                  />
                </Stack>
              ))}
            </Slider>
          ) : (
            <>
              <Box
                display="flex"
                flexDirection="row"
                gap={{ base: 2, md: 8, lg: 8 }}
                alignItems="center"
                width="full"
                justifyContent={{ base: 'center', md: 'space-evenly', lg: 'space-evenly' }}
                marginY={5}
                flexWrap="wrap"
              >
                {categories?.themeCategory.courses.slice(0, 4).map(course => {
                  return (
                    <MainCard
                      key={course.id}
                      course={course}
                      loading={isLoadingThemeMarketplace}
                      checkoutType={course.checkoutType}
                      grid={true}
                    />
                  );
                })}
              </Box>
              <Flex
                justifyContent="center"
                hidden={categories?.themeCategory?.courses?.length === 1}
              >
                <Button
                  as={Link}
                  to={`/all-products?category=${section?.themeCategory?.name}&categoryId=${section?.themeCategory?.categoryId}`}
                  background={themeMarketplace.primaryColor}
                  color={themeMarketplace.textColor}
                  _hover={{ background: themeMarketplace.primaryColor.concat('80') }}
                  borderColor={themeMarketplace.primaryColor.concat('80')}
                  border="0.5px solid"
                >
                  Ver mais
                </Button>
              </Flex>
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default CategoriesSection;
