import CopyToClipboard from 'react-copy-to-clipboard';
import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { CheckoutSettings } from '../types';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  IconButton,
  List,
  ListItem,
  ListProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

interface CheckoutSettingsMobileListProps extends ListProps {
  checkoutSettingsList: CheckoutSettings[];
  onGetCheckoutLink: (productId: number, checkoutType: CheckoutSettings['checkoutType']) => string;
  onCheckoutLinkCopy: (_, result: boolean) => void;
  onProductToChangeCheckoutTypeClick: (productId: number) => void;
}

export default function CheckoutSettingsMobileList({
  checkoutSettingsList,
  onGetCheckoutLink,
  onCheckoutLinkCopy,
  onProductToChangeCheckoutTypeClick,
  ...rest
}: CheckoutSettingsMobileListProps) {
  return (
    <List {...rest} spacing={2.5}>
      {checkoutSettingsList.map(checkoutSettingsItem => (
        <Card
          key={checkoutSettingsItem.id}
          as={ListItem}
          border="1px solid #2021230D"
          boxShadow="0px 4px 4px 0px #00000040"
        >
          <CardHeader p={4} pb={0}>
            <HStack justify="space-between" align="start" alignItems="start">
              <Text maxWidth="16.25rem" color="gray.700" lineHeight="normal" fontWeight="medium">
                {checkoutSettingsItem.courseName}
              </Text>

              <Menu size="sm" arrowPadding={0}>
                <MenuButton
                  size="xs"
                  as={IconButton}
                  icon={<MoreIcon size="0.75rem" />}
                  w="1.125rem"
                  h="1rem"
                  minW="unset"
                  borderRadius={5}
                  bgColor="#2021231F"
                  _hover={{ bgColor: '#b6b5b5' }}
                  _active={{ bgColor: '#b6b5b5' }}
                />

                <MenuList
                  sx={{
                    '.chakra-menu__menuitem': {
                      fontWeight: 'medium',
                      fontSize: 'xs',
                      color: '#20212380',
                      _hover: { bgColor: '#2021230D' },
                      _focus: { bgColor: '#2021230D' },
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => onProductToChangeCheckoutTypeClick(checkoutSettingsItem.id)}
                  >
                    Alterar tipo de checkout
                  </MenuItem>

                  {checkoutSettingsItem.checkoutType === 'LAUNCH' && (
                    <MenuItem
                      as={Link}
                      to={{
                        pathname: `/checkout-settings/edit/${checkoutSettingsItem.id}/payment`,
                        search: `?productId=${checkoutSettingsItem.courseId}&productName=${checkoutSettingsItem.courseName}`,
                      }}
                    >
                      Editar
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </HStack>
          </CardHeader>

          <CardBody
            mt={4}
            p={4}
            pt={0}
            color="#20212380"
            sx={{ strong: { fontWeight: 'semibold' } }}
          >
            <List spacing={1}>
              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Tipo de checkout:</Text>
                <Text>
                  {checkoutSettingsItem.checkoutType === 'DEFAULT' ? 'Padrão' : 'Lançamento'}
                </Text>
              </HStack>

              <HStack as={ListItem} align="center" justify="space-between">
                <Text as="strong">Link do Checkout:</Text>

                <CopyToClipboard
                  text={onGetCheckoutLink(
                    checkoutSettingsItem.courseId,
                    checkoutSettingsItem.checkoutType
                  )}
                  onCopy={onCheckoutLinkCopy}
                >
                  <Button
                    variant="link"
                    textDecoration="underline"
                    fontWeight="normal"
                    size="sm"
                    color="#759AED"
                  >
                    Copiar link
                  </Button>
                </CopyToClipboard>
              </HStack>

              <HStack as={ListItem} align="center" justify="space-between">
                <Text as="strong">Última atualização:</Text>

                <VStack align="end" spacing={0.5}>
                  <Text color="gray.700">
                    {dayjs(checkoutSettingsItem.updatedAt).format('DD/MM/YYYY HH:mm')}
                  </Text>

                  <Text>{checkoutSettingsItem.updatedBy}</Text>
                </VStack>
              </HStack>
            </List>
          </CardBody>
        </Card>
      ))}
    </List>
  );
}
