import { Box, Progress, Text } from '@chakra-ui/react';

import { useProgressContext } from '../../../../../contexts/ProgressContext';

function ProgressBar() {
  const {
    progress: { progress, countContent },
    amountContentViewed,
  } = useProgressContext();

  return (
    <Box maxWidth="100%" backgroundColor="white">
      <Text fontSize="lg" fontWeight="700" marginBottom={2}>
        Progresso
      </Text>
      <Progress
        size="xs"
        colorScheme="primary"
        height="0.3rem"
        borderRadius="0.156rem"
        value={progress}
        sx={{
          '& div': {
            background: 'primary.500',
          },
        }}
      />
      <Box display="flex" justifyContent="space-between">
        <Text fontSize="xs" fontWeight="500" color="primary.500">
          {progress?.toFixed(2)}%
        </Text>
        <Text fontSize="xs" fontWeight="500" color="#979CA6">
          {amountContentViewed}/{countContent} Conteúdos
        </Text>
      </Box>
    </Box>
  );
}

export default ProgressBar;
