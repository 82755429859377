import CustomDropzone from '../../../../../components/CustomDropzone';
import DeleteImageModal from './DeleteImageModal';
import ImagePreview from './ImagePreview';
import ImageUploadLoading from './ImageUploadLoading';
import { useCheckoutSettingsEditCustomizationProps } from './types';
import useCheckoutSettingsEditCustomization from './useCheckoutSettingsEditCustomization';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';

export default function CheckoutSettingsEditCustomization({
  onIsLoadingChange,
}: useCheckoutSettingsEditCustomizationProps) {
  const {
    form,
    isSubmitting,
    formValidation,
    desktopTopImage,
    mobileTopImage,
    desktopBottomImage,
    mobileBottomImage,
    isUploading,
    isDeletingImage,
    isOpen,
    onClose,
    handleOpenImageDeleteModal,
    handleSelectNewImage,
    handleDeleteImage,
    handleChange,
    handleCancelButtonClick,
    handleDropRejected,
    handleDropFile,
    onSubmit,
  } = useCheckoutSettingsEditCustomization({
    onIsLoadingChange,
  });

  return (
    <Box mt={10}>
      <VStack
        as="form"
        align="start"
        mt="1.125rem"
        spacing={10}
        sx={{
          '.chakra-form__label': {
            fontSize: 'sm',
            color: '#20212380',
            fontWeight: 'normal',
          },
          '.chakra-form__helper-text': {
            color: '#20212380',
            mb: 2,
          },
          '.chakra-input, .chakra-input::placeholder': {
            color: '#0000005C',
          },
        }}
        noValidate
        onSubmit={onSubmit}
      >
        <Box as="section" w="full">
          <Heading fontSize="xl" fontWeight="medium" color="#202123">
            Imagem superior
          </Heading>

          <Text className="chakra-form__helper-text">
            Imagem localizada na parte de cima da página, antes do checkout.
          </Text>
          <Stack direction={{ base: 'column', xl: 'row' }} spacing={4}>
            <FormControl isInvalid={false}>
              <FormLabel>Versão desktop</FormLabel>

              <Flex position="relative" height={{ base: '23.5625rem', xl: '16.75rem' }}>
                {!form.desktopTopImage ? (
                  <>
                    <CustomDropzone
                      file={desktopTopImage?.file}
                      isDisabled={isSubmitting}
                      accept={['image/jpg', 'image/png', 'image/jpeg', 'image/webp']}
                      dimensions="1010x440"
                      onDrop={acceptedFiles => handleDropFile('desktopTopImage', acceptedFiles)}
                      onDropRejected={handleDropRejected}
                      extensions="JPG, PNG, JPEG e WEBP"
                      maxFileSize="5MB"
                    />

                    <ImageUploadLoading position="absolute" isLoading={isUploading} />
                  </>
                ) : (
                  <VStack w="full">
                    <Box height={{ base: '21.5625rem', xl: '16.75rem' }} w="full">
                      <ImagePreview
                        imageField="desktopTopImage"
                        preview={desktopTopImage?.preview ?? form.desktopTopImage}
                        onSelectNewImage={handleSelectNewImage}
                        onOpenDeleteImageModal={handleOpenImageDeleteModal}
                      />
                    </Box>

                    <Button
                      display={{ base: 'flex', xl: 'none' }}
                      onClick={() => handleSelectNewImage('desktopTopImage')}
                      w="full"
                      variant="outline"
                      colorScheme="primary"
                      size="sm"
                      zIndex="2"
                    >
                      Trocar Imagem
                    </Button>
                  </VStack>
                )}
              </Flex>
            </FormControl>

            <FormControl isInvalid={false} maxWidth={{ base: 'unset', xl: '30%' }}>
              <FormLabel>Versão mobile</FormLabel>

              <Flex position="relative" height={{ base: '23.5625rem', xl: '16.75rem' }}>
                {!form.mobileTopImage ? (
                  <>
                    <CustomDropzone
                      file={mobileTopImage?.file}
                      isDisabled={isSubmitting}
                      accept={['image/jpg', 'image/png', 'image/jpeg', 'image/webp']}
                      dimensions="1080x1080"
                      onDrop={acceptedFiles => handleDropFile('mobileTopImage', acceptedFiles)}
                      onDropRejected={handleDropRejected}
                      extensions="JPG, PNG, JPEG e WEBP"
                      maxFileSize="5MB"
                    />

                    <ImageUploadLoading position="absolute" isLoading={isUploading} />
                  </>
                ) : (
                  <VStack>
                    <Box height={{ base: '21.5625rem', xl: '16.75rem' }}>
                      <ImagePreview
                        imageField="mobileTopImage"
                        preview={mobileTopImage?.preview ?? form.mobileTopImage}
                        onSelectNewImage={handleSelectNewImage}
                        onOpenDeleteImageModal={handleOpenImageDeleteModal}
                      />
                    </Box>

                    <Button
                      display={{ base: 'flex', xl: 'none' }}
                      onClick={() => handleSelectNewImage('mobileTopImage')}
                      w="full"
                      variant="outline"
                      colorScheme="primary"
                      size="sm"
                      zIndex="2"
                    >
                      Trocar Imagem
                    </Button>
                  </VStack>
                )}
              </Flex>
            </FormControl>
          </Stack>

          <Box mt={4}>
            <Text className="chakra-form__helper-text">
              Usar imagem do desktop também no mobile?
            </Text>

            <FormControl
              mt={4}
              display="flex"
              alignItems="center"
              isInvalid={formValidation?.useTopDesktopImageOnMobile?.isInvalid}
              gap={2}
            >
              <Switch
                name="useTopDesktopImageOnMobile"
                onChange={handleChange}
                colorScheme="primary"
                isChecked={form.useTopDesktopImageOnMobile}
              />

              <FormLabel m={0} sx={{ color: 'gray.700 !important' }}>
                {form.useTopDesktopImageOnMobile ? 'Ativado' : 'Desativado'}
              </FormLabel>
            </FormControl>
          </Box>
        </Box>

        <Box as="section" w="full">
          <Heading fontSize="xl" fontWeight="medium" color="#202123">
            Imagem inferior
          </Heading>

          <Text className="chakra-form__helper-text">
            Imagem localizada na parte de baixo da página, após o checkout.
          </Text>

          <Stack direction={{ base: 'column', xl: 'row' }} spacing={4}>
            <FormControl isInvalid={false}>
              <FormLabel>Versão desktop</FormLabel>

              <Flex position="relative" height={{ base: '23.5625rem', xl: '16.75rem' }}>
                {!form.desktopBottomImage ? (
                  <>
                    <CustomDropzone
                      file={desktopBottomImage?.file}
                      isDisabled={isSubmitting}
                      accept={['image/jpg', 'image/png', 'image/jpeg', 'image/webp']}
                      dimensions="1010x440"
                      onDrop={acceptedFiles => handleDropFile('desktopBottomImage', acceptedFiles)}
                      onDropRejected={handleDropRejected}
                      extensions="JPG, PNG, JPEG e WEBP"
                      maxFileSize="5MB"
                    />

                    <ImageUploadLoading position="absolute" isLoading={isUploading} />
                  </>
                ) : (
                  <VStack w="full">
                    <Box height={{ base: '21.5625rem', xl: '16.75rem' }} w="full">
                      <ImagePreview
                        imageField="desktopBottomImage"
                        preview={desktopBottomImage?.preview ?? form.desktopBottomImage}
                        onSelectNewImage={handleSelectNewImage}
                        onOpenDeleteImageModal={handleOpenImageDeleteModal}
                      />
                    </Box>

                    <Button
                      display={{ base: 'flex', xl: 'none' }}
                      onClick={() => handleSelectNewImage('desktopBottomImage')}
                      w="full"
                      variant="outline"
                      colorScheme="primary"
                      size="sm"
                      zIndex="2"
                    >
                      Trocar Imagem
                    </Button>
                  </VStack>
                )}
              </Flex>
            </FormControl>

            <FormControl isInvalid={false} maxWidth={{ base: 'unset', xl: '30%' }}>
              <FormLabel>Versão mobile</FormLabel>

              <Flex position="relative" height={{ base: '23.5625rem', xl: '16.75rem' }}>
                {!form.mobileBottomImage ? (
                  <>
                    <CustomDropzone
                      file={mobileBottomImage?.file}
                      isDisabled={isSubmitting}
                      accept={['image/jpg', 'image/png', 'image/jpeg', 'image/webp']}
                      dimensions="1080x1080"
                      onDrop={acceptedFiles => handleDropFile('mobileBottomImage', acceptedFiles)}
                      onDropRejected={handleDropRejected}
                      extensions="JPG, PNG, JPEG e WEBP"
                      maxFileSize="5MB"
                    />

                    <ImageUploadLoading position="absolute" isLoading={isUploading} />
                  </>
                ) : (
                  <VStack>
                    <Box height={{ base: '21.5625rem', xl: '16.75rem' }} w="full">
                      <ImagePreview
                        imageField="mobileBottomImage"
                        preview={mobileBottomImage?.preview ?? form.mobileBottomImage}
                        onSelectNewImage={handleSelectNewImage}
                        onOpenDeleteImageModal={handleOpenImageDeleteModal}
                      />
                    </Box>

                    <Button
                      display={{ base: 'flex', xl: 'none' }}
                      onClick={() => handleSelectNewImage('mobileTopImage')}
                      w="full"
                      variant="outline"
                      colorScheme="primary"
                      size="sm"
                      zIndex="2"
                    >
                      Trocar Imagem
                    </Button>
                  </VStack>
                )}
              </Flex>
            </FormControl>
          </Stack>

          <Box mt={4}>
            <Text className="chakra-form__helper-text">
              Usar imagem do desktop também no mobile?
            </Text>

            <FormControl
              mt={4}
              display="flex"
              alignItems="center"
              isInvalid={formValidation?.useBottomDesktopImageOnMobile?.isInvalid}
              gap={2}
            >
              <Switch
                name="useBottomDesktopImageOnMobile"
                onChange={handleChange}
                colorScheme="primary"
                isChecked={form.useBottomDesktopImageOnMobile}
              />

              <FormLabel m={0} sx={{ color: 'gray.700 !important' }}>
                {form.useBottomDesktopImageOnMobile ? 'Ativado' : 'Desativado'}
              </FormLabel>
            </FormControl>
          </Box>
        </Box>

        <HStack as="footer" w="full" justify="end" spacing={1}>
          <Button
            onClick={handleCancelButtonClick}
            variant="ghost"
            mr={3}
            background="gray.200"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isDisabled={isSubmitting}
          >
            Voltar
          </Button>

          <Button
            type="submit"
            colorScheme="primary"
            color="secondary.500"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isLoading={isSubmitting}
          >
            Avançar
          </Button>
        </HStack>
      </VStack>

      <DeleteImageModal
        isOpen={isOpen}
        isLoading={isDeletingImage}
        onConfirm={handleDeleteImage}
        onClose={onClose}
      />
    </Box>
  );
}
