import { useToast } from '@chakra-ui/react';
import path from 'path';
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../helpers/UploadHelper';
import useFetch from '../../../../hooks/useFetch';
import useHandleChange from '../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../utils/scrollToInvalidInput';
import { JoiSchemasType } from '../../../../validation/EntitySchema';
import { getDDI } from '../../../StudentSignUp/CountrySelect';
import { Discipline, Instructor, InstructorForm, SocialNetwork } from '../types';

interface useInstructorFormProps {
  url: string;
  method: 'post' | 'patch';
  instructor?: Instructor;
  successMessage?: string;
  schemaValidator: JoiSchemasType<Partial<InstructorForm>>;
}

interface ImageFile {
  preview: string;
  file?: File;
}

interface DisciplinesResponse {
  data: Discipline[];
  page: number;
  total: number;
}

export default function useInstructorForm({
  url,
  method,
  instructor,
  successMessage,
  schemaValidator,
}: useInstructorFormProps) {
  const [photo, setPhoto] = useState<ImageFile>({ preview: instructor?.photo });
  const [coverBanner, setCoverBanner] = useState<ImageFile>({ preview: instructor?.coverBanner });
  const [isUploading, setIsUploading] = useState(false);

  const [selectedDisciplines, setSelectedDisciplines] = useState<Discipline[]>(
    instructor?.disciplines ?? []
  );

  const history = useHistory();
  const toast = useToast();

  const { data: disciplineResponse } = useFetch<UnificadaFront.ResponseJSON<DisciplinesResponse>>({
    method: 'get',
    url: '/filter/question-library-discipline?hasPaginate=false',
    authenticated: true,
    autoFetch: true,
  });

  // Filtra as que não foram selecionadas
  const disciplines = disciplineResponse?.data?.data
    ?.filter(discipline => !selectedDisciplines?.some(({ id }) => id === discipline?.id))
    .map(discipline => ({
      value: discipline.id,
      label: discipline.name,
    }));

  const {
    form,
    onChanged: hasChange,
    setForm,
    handleChange,
    setOnChanged: setHasChange,
    handleCancel,
  } = useHandleChange<InstructorForm>(
    method === 'post'
      ? {
          fullName: instructor?.fullName,
          email: instructor?.email,
          ddi: '55',
        }
      : {
          photo: instructor?.photo,
          fullName: instructor?.fullName,
          email: instructor?.email,
          ddd: instructor?.ddd,
          ddi: instructor?.ddi,
          phone: instructor?.phone,
          description: instructor?.description,
          coverBanner: instructor?.coverBanner,
          socialNetwork: instructor.socialNetwork,
        }
  );

  const handleCountryChange = useCallback(
    (country: string) => {
      setForm(prevForm => ({
        ...prevForm,
        ddi: getDDI(country),
        ddd: null,
      }));
    },
    [setForm]
  );

  function onCancel() {
    if (!hasChange) {
      return history.push('/instructors');
    }

    handleCancel();
  }

  function handleSelectDiscipline(newValue) {
    setHasChange(true);

    setSelectedDisciplines(prevState => [
      ...prevState,
      {
        id: newValue[0].value,
        name: newValue[0].label,
      },
    ]);
  }

  function handleDescriptionChange(value: string) {
    setForm(prevForm => ({
      ...prevForm,
      photo: prevForm.photo && path.basename(prevForm.photo),
      coverBanner: prevForm.coverBanner && path.basename(prevForm.coverBanner),
      description: value,
    }));

    setHasChange(true);
  }

  function handleDisciplineRemove(disciplineId) {
    setHasChange(true);

    // Remover a disciplina do estado selectedDisciplines
    setSelectedDisciplines(prevDisciplines =>
      prevDisciplines?.filter(({ id }) => id !== disciplineId)
    );

    // Remover a disciplina do estado form
    setForm(prevForm => ({
      ...prevForm,
      photo: prevForm.photo && path.basename(prevForm.photo),
      coverBanner: prevForm.coverBanner && path.basename(prevForm.coverBanner),
      disciplines: prevForm.disciplines?.filter(discipline => discipline !== disciplineId),
    }));
  }

  function handleSocialNetworkChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setHasChange(true);

    // Verifica se form.socialNetwork está definido e se já existe uma rede social com o mesmo tipo
    const networkExists =
      form?.socialNetwork && form?.socialNetwork?.some(network => network.type === name);

    if (networkExists) {
      // Se já existir uma rede social com o mesmo tipo, atualize-a
      setForm({
        ...form,
        socialNetwork: form.socialNetwork.map(network =>
          network.type === name ? { ...network, url: value } : network
        ),
      });
      return;
    }

    // Se não existir, adicione-a
    const newSocialNetwork = { type: name, url: value } as SocialNetwork;
    setForm({ ...form, socialNetwork: [...(form.socialNetwork || []), newSocialNetwork] });
  }

  async function uploadFile(file: File) {
    return await UploadHelper.upload(file, 'profiles');
  }

  function handleUploadError(error) {
    toast({
      title: ErrorResponse(error),
      status: 'error',
    });
  }

  async function handleDropFile(name: 'coverBanner' | 'photo', acceptedFiles) {
    try {
      setHasChange(true);
      setIsUploading(true);

      const [file] = acceptedFiles;
      const preview = URL.createObjectURL(file);

      const { newFileName } = await uploadFile(file);

      setForm({ ...form, [name]: newFileName });

      if (name === 'coverBanner') {
        setCoverBanner({ preview, file });
      } else {
        setPhoto({ preview, file });
      }
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
    }
  }

  function handleDropRejected() {
    toast({
      title: 'Arquivo inválido!',
      description: 'Formatos suportados: JPG, PNG, JPEG',
      status: 'error',
    });
  }

  function handleSelectNewImage(name: 'coverBanner' | 'photo') {
    setHasChange(true);

    setForm({ ...form, [name]: '' });
  }

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: form,
    schemaValidator,
    url,
    method,
    authenticated: true,
    onSuccess: {
      message: successMessage,
      callback: () => history.push('/instructors'),
    },
    removeNullProps: false,
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    setForm(prevForm => {
      prevForm.disciplines = !!selectedDisciplines.length
        ? selectedDisciplines.map(({ id }) => id)
        : [];

      prevForm.photo = prevForm.photo && path.basename(prevForm.photo);
      prevForm.coverBanner = prevForm.coverBanner && path.basename(prevForm.coverBanner);

      // Deleta todas props vazias ou que estiverem com array vazio
      for (const key in prevForm) {
        if (
          prevForm.hasOwnProperty(key) &&
          key !== 'fullName' &&
          key !== 'email' &&
          key !== 'ddi' &&
          key !== 'ddd' &&
          key !== 'phone' &&
          (!prevForm[key] || (Array.isArray(prevForm[key]) && prevForm[key].length === 0))
        ) {
          delete prevForm[key];
        }
      }

      return prevForm;
    });

    scrollToInvalidInput();

    await handleSubmit();
  }
  return {
    form,
    formValidation,
    isSubmitting,
    isUploading,
    photo,
    coverBanner,
    hasChange,
    disciplines,
    selectedDisciplines,
    handleChange,
    onSubmit,
    handleSelectNewImage,
    handleDropFile,
    handleDropRejected,
    handleSelectDiscipline,
    handleDescriptionChange,
    handleDisciplineRemove,
    handleSocialNetworkChange,
    handleCancel: onCancel,
    handleCountryChange,
  };
}
