import { Box, Flex, HStack, Image, Link as ChakraLink, List, ListItem } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '../../../../contexts/ThemeContext';
import DesktopMenu from '../DesktopMenu';
import MobileMenu from '../MobileMenu';
import useExternalLinkHeader from '../../../../useExternalLinkHeader';

export default function NetflixMemberAreaHeader() {
  const { themeMarketplace: theme } = useTheme();
  const { pathname } = useLocation();
  const externalLink = useExternalLinkHeader();

  const isContentViewPath = pathname.includes('/content-view');
  const logo = theme.logo;

  return (
    <Flex
      as="header"
      justify="space-between"
      align="center"
      w="full"
      height={{ base: '3rem', md: '4.5rem' }}
      px={{ base: 4, md: '2.625rem' }}
      position="relative"
      flexShrink={0}
      zIndex="docked"
      backgroundColor="#202123"
      boxShadow="0px 6px 6px 0px rgba(0, 0, 0, 0.25)"
      transition="background-color 0.3s"
    >
      {externalLink ? (
        <Box
          order={{ base: 2, md: 1 }}
          position={{ base: 'absolute', md: 'static' }}
          left={{ base: '50%', md: 0 }}
          transform={{ base: 'translateX(-50%)', md: 'translateX(0)' }}
          as={ChakraLink}
          href={externalLink}
        >
          <Image ml={0} h={{ base: 6, md: 9 }} objectFit="contain" src={logo} alt="logo" />
        </Box>
      ) : (
        <Box
          order={{ base: 2, md: 1 }}
          position={{ base: 'absolute', md: 'static' }}
          left={{ base: '50%', md: 0 }}
          transform={{ base: 'translateX(-50%)', md: 'translateX(0)' }}
          as={Link}
          to="/"
        >
          <Image ml={0} h={{ base: 6, md: 9 }} objectFit="contain" src={logo} alt="logo" />
        </Box>
      )}

      {!isContentViewPath && (
        <HStack
          display={{ base: 'none', md: 'flex' }}
          as={List}
          textTransform="uppercase"
          order={{ base: 1, md: 2 }}
          spacing={7}
          color="white"
          position="absolute"
          transform="translateX(-50%)"
          left="50%"
        >
          <ListItem>
            <ChakraLink as={Link} to="/members-area/v2" _hover={{ textDecoration: 'none' }}>
              Produtos
            </ChakraLink>
          </ListItem>

          <ListItem>
            <ChakraLink
              as={Link}
              to="/members-area/v2/payments"
              _hover={{ textDecoration: 'none' }}
            >
              Pagamentos
            </ChakraLink>
          </ListItem>

          <ListItem>
            <ChakraLink
              as={Link}
              to="/members-area/v2/questions"
              _hover={{ textDecoration: 'none' }}
            >
              Questões
            </ChakraLink>
          </ListItem>

          <ListItem>
            <ChakraLink
              as={Link}
              to="/members-area/v2/profile/user"
              _hover={{ textDecoration: 'none' }}
            >
              Perfil
            </ChakraLink>
          </ListItem>
        </HStack>
      )}

      <Flex align="center" order={{ base: 1, md: 3 }}>
        <MobileMenu display={{ base: 'initial', md: 'none' }} />
        <DesktopMenu display={{ base: 'none', md: 'block' }} />
      </Flex>
    </Flex>
  );
}
