import { Heading, ModalBody, VStack, Text, ModalFooter, Button } from '@chakra-ui/react';
import { IoMdAlert } from 'react-icons/io';
import { capitalizePlanName } from '../..';
import { useSubscription } from '../../../../contexts/SubscriptionContext';

export default function ModalContentBlock({ onActionButtonClick }) {
  const { subscriptionStatus } = useSubscription();

  const planName = capitalizePlanName(subscriptionStatus?.plan);

  return (
    <>
      <ModalBody>
        <VStack mt={4} textAlign="center">
          <IoMdAlert size="3.0775rem" color="#EB7129" />

          <Heading as="h6" fontSize="lg" fontWeight="medium">
            O seu período de teste gratuito acabou!
          </Heading>
          <Text fontSize="sm" fontWeight="normal" color="gray.500">
            Mas não se preocupe, você pode evitar o bloqueio da sua plataforma clicando no botão
            abaixo.
          </Text>
        </VStack>
      </ModalBody>

      <ModalFooter pb={6}>
        <Button
          onClick={onActionButtonClick}
          w="full"
          colorScheme="orange"
          fontSize="sm"
          _focus={{ outline: 'none', boxShadow: 'none' }}
        >
          Assinar plano {planName}
        </Button>
      </ModalFooter>
    </>
  );
}
