import { Button, Link, Heading, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { BsTrash } from 'react-icons/bs';
import ContentAPI from '../../../../../../api/Content';
import CustomDropzone from '../../../../../../components/CustomDropzone';
import DataLoading from '../../../../../../components/Loadings/DataLoading';
import DownloadImage from '../../../../../../helpers/DownloadImage';
import EditorRichText from '../../../../../../components/EditorRichText';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { Modal } from 'react-bootstrap';
import Platform from '../../../../../../api/Platform';
import Spinner from '../../../../../../components/Loadings/Spinner';
import { TOKEN_LOCAL_STORAGE } from '../../../../../../helpers/LocalStorageHelper';
import Toast from '../../../../../../components/Toast';
import UploadHelper from '../../../../../../helpers/UploadHelper';
import { baseURLBackend } from '../../../../../../api/Configuration';
import path from 'path';
import { useCourse } from '../../../../../../contexts/CourseContext';

const initialValues = {
  title: '',
  order: 1,
  contentType: 'AUDIO',
  fileThumbnail: '',
  content: '',
  fileAudio: '',
};

const MAX_LENGTH_IMAGE = 26214400;
const MAX_SIZE_AUDIO_IN_BYTES = 25728640;
const ADD_WITH_CURRENT_ORDER = 1;

const AudioForm = () => {
  const [values, setValues] = useState(initialValues);
  const [file, setFile] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fileThumbnail, setFileThumbnail] = useState('');
  const [editorState, setEditorState] = useState('');

  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getContent, course, setCourse } = useCourse();

  const { id: courseId, moduleId, contentId } = useParams();

  const history = useHistory();

  useEffect(() => {
    const content = getContent(moduleId, contentId);

    if (!contentId) {
      setValues(initialValues);
      setEditorState('');
      return;
    }

    if (content) {
      const currentEditorState = content.content || '';
      setEditorState(currentEditorState);

      setValues({
        title: content.title,
        order: content.order,
        contentType: content.contentType,
        fileAudio: content.download,
        content: content.content,

        fileThumbnail: content.thumbnail,
      });
    }
  }, [contentId, getContent, moduleId]);

  function handleChange(event) {
    const { name, value } = event.target;

    if (name === 'image') {
      const [fileThumbnail] = event.target.files || '';
      return setValues({ ...values, fileThumbnail: fileThumbnail });
    }

    setValues({ ...values, [name]: value });
    setErrorMessage('');
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    setFile(null);
    setValues(initialValues);
    setErrorMessage('');
  }

  function handleEditorStateChange(editorState) {
    setErrorMessage('');
    setEditorState(editorState);
    setValues({ ...values, content: editorState });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    let audioKey = '';
    let thumbnailKey = '';

    // * CRIAÇÃO
    if (!contentId) {
      if (file && file.size > MAX_SIZE_AUDIO_IN_BYTES)
        return setErrorMessage(
          'O tamanho máximo do arquivo de áudio deve ser igual ou inferior a 25 MB.'
        );

      if (fileThumbnail && fileThumbnail.size > MAX_LENGTH_IMAGE) {
        return setErrorMessage('O tamanho máximo da imagem deve ser igual ou inferior a 25 MB.');
      }

      try {
        setIsLoading(true);

        const currentModule = course.modules.find(module => module.id === Number(moduleId));
        const { contents } = currentModule;

        if (contents.length) {
          const orders = contents.map(content => content.order);
          values.order = orders.length
            ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
            : initialValues.order;
        }

        let nameAudio;
        let nameAudioThumbnail;

        if (file && typeof file !== 'string') {
          const { newFileName, link } = await UploadHelper.upload(file, 'archives');

          setValues({ ...values, fileAudio: link });

          nameAudio = newFileName;

          audioKey = `archives/${newFileName}`;
        }

        if (fileThumbnail) {
          const { newFileName, link } = await UploadHelper.upload(fileThumbnail, 'archives');

          setValues({ ...values, fileThumbnail: link });

          nameAudioThumbnail = newFileName;

          thumbnailKey = `archives/${newFileName}`;
        }

        const payload = {
          ...values,
          fileAudio: nameAudio ? nameAudio : values.fileAudio ? values.fileAudio : null,
          fileThumbnail: nameAudioThumbnail
            ? nameAudioThumbnail
            : values.fileThumbnail
            ? values.fileThumbnail
            : null,
        };

        if (!payload.fileThumbnail) {
          delete payload.fileThumbnail;
        }

        const { data: response } = await ContentAPI.store(courseId, moduleId, payload);

        const newContents = [...currentModule.contents, response];
        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({ ...course, modules: newModules });

        setFile(null);
        setFileThumbnail('');

        setIsLoading(false);
        Toast('Conteúdo cadastrado com sucesso');

        history.push(
          `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${response.id}/edit-audio`
        );
      } catch (error) {
        setErrorMessage(ErrorResponse(error));
        setIsLoading(false);

        if (audioKey) {
          await Platform.deleteFileByKey(audioKey);
        }

        if (thumbnailKey) {
          await Platform.deleteFileByKey(thumbnailKey);
        }
      }
      return;
    }

    // * ATUALIZAÇÃO
    try {
      setIsLoading(true);

      let nameAudio = values.fileAudio;
      let nameAudioThumbnail = values.fileThumbnail;

      if (!nameAudio) {
        setIsLoading(false);
        return setErrorMessage('Informe um áudio.');
      }

      if (!nameAudioThumbnail) {
        setIsLoading(false);
        return setErrorMessage('Informe uma imagem.');
      }

      if (file && typeof file !== 'string') {
        if (file && file.size > MAX_SIZE_AUDIO_IN_BYTES)
          return setErrorMessage(
            'O tamanho máximo do arquivo de áudio deve ser igual ou inferior a 25 MB.'
          );

        if (file) {
          const { newFileName, link } = await UploadHelper.upload(file, 'archives');

          setValues({ ...values, fileAudio: link });

          nameAudio = newFileName;

          audioKey = `archives/${newFileName}`;
        }
      }

      if (fileThumbnail && typeof fileThumbnail !== 'string') {
        if (fileThumbnail && fileThumbnail.size > MAX_LENGTH_IMAGE) {
          return setErrorMessage('O tamanho máximo da imagem deve ser igual ou inferior a 25 MB.');
        }

        if (fileThumbnail) {
          const { newFileName, link } = await UploadHelper.upload(fileThumbnail, 'archives');

          setValues({ ...values, fileThumbnail: link });

          nameAudioThumbnail = newFileName;

          thumbnailKey = `archives/${newFileName}`;
        }
      }

      const payload = {
        ...values,
        fileAudio: nameAudio,
        fileThumbnail: nameAudioThumbnail,
      };

      const { data: updatedContent } = await ContentAPI.update(
        courseId,
        moduleId,
        contentId,
        payload
      );

      const currentModule = course.modules.find(module => module.id === Number(moduleId));

      const newContents = currentModule.contents.map(content => {
        if (content.id === Number(contentId)) {
          return {
            ...content,
            title: updatedContent.title,
            order: updatedContent.order,
            contentType: updatedContent.contentType,
            fileThumbnail: updatedContent.fileThumbnail,
            content: updatedContent.content,
            audioFile: updatedContent.download,
            download: updatedContent.download,
          };
        }
        return content;
      });

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({ ...course, modules: newModules });

      setIsLoading(false);
      Toast('Conteúdo atualizado com sucesso');

      history.push(`/courses/${courseId}/course-manager/course`);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);

      if (audioKey) {
        Platform.deleteFileByKey(audioKey);
      }

      if (thumbnailKey) {
        Platform.deleteFileByKey(thumbnailKey);
      }
    }
  }

  async function handleDelete(event) {
    event.preventDefault();

    try {
      setIsLoading(true);
      await ContentAPI.delete(courseId, moduleId, contentId);

      const currentModule = course.modules.find(module => module.id === Number(moduleId));

      const newContents = currentModule.contents.filter(module => module.id !== Number(contentId));

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({ ...course, modules: newModules });

      setIsLoading(false);
      setIsShowModal(false);
      Toast('Conteúdo excluído com sucesso');
      history.push(`/courses/${courseId}/course-manager/course/modules/${moduleId}`);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    }
  }
  function handleDropFileThumbnail(acceptedFiles) {
    setErrorMessage('');

    const [fileThumbnail] = acceptedFiles || '';

    setFileThumbnail(fileThumbnail);

    setValues({
      ...values,
      fileThumbnail: fileThumbnail,
    });
  }

  function PreviewImage({ fileThumbnail }) {
    function handleDiscardFileThumbnail() {
      setFileThumbnail('');
      setValues({
        ...values,
        fileThumbnail: '',
      });
    }

    return (
      <div className="d-flex align-center justify-content-between mt-2">
        <div>{fileThumbnail?.name || path.basename(decodeURI(values.fileThumbnail))}</div>

        <div>
          <div>
            {!fileThumbnail && (
              <Button
                variant="link"
                fontWeight="light"
                color="black"
                fontSize="medium"
                fontFamily="Montserrat"
                onClick={() =>
                  DownloadImage(
                    values.fileThumbnail,
                    path.basename(decodeURI(values.fileThumbnail))
                  )
                }
              >
                Download
              </Button>
            )}

            <button
              onClick={handleDiscardFileThumbnail}
              type="button"
              className="btn btn-outline-primary"
            >
              <BsTrash />
            </button>
          </div>
        </div>
      </div>
    );
  }

  function handleDropAudioFile(acceptedFiles) {
    setErrorMessage('');

    const [file] = acceptedFiles || '';

    setFile(file);

    setValues({
      ...values,
      fileAudio: file,
    });
  }

  function Preview({ file }) {
    function handleDiscard() {
      setFile(null);
      setValues({ ...values, fileAudio: '' });
    }

    return (
      <div className="d-flex align-center justify-content-between mt-2">
        <div>{file?.name || path.basename(decodeURI(values.fileAudio))}</div>

        <div>
          <div>
            {!file && (
              <Link
                href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${localStorage.getItem(
                  TOKEN_LOCAL_STORAGE
                )}`}
                download
                fontSize="medium"
                color="black"
                fontWeight="light"
              >
                Download
              </Link>
            )}

            <button onClick={handleDiscard} type="button" className="btn btn-outline-primary">
              <BsTrash />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-5" style={{ wordBreak: 'break-all' }}>
      <h4 className="mt-1 mb-4">{values.title || 'Novo conteúdo de Áudio'}</h4>

      <form onSubmit={handleSubmit}>
        <div className="form-background p-5 round-xl shadow-sm border border-gray bg-white">
          <div className="form-group">
            <label className="h6" htmlFor="title">
              Título - Áudio
            </label>
            <input
              id="title"
              name="title"
              value={values.title}
              placeholder="Digite um título para o seu conteúdo"
              onChange={handleChange}
              type="text"
              className="form-control input-content"
              required
              maxLength={255}
              minLength={1}
            />
          </div>

          <Heading as="h6" fontSize="md">
            Carregar arquivo de Áudio:
          </Heading>

          {!values.fileAudio ? (
            <CustomDropzone
              isDisabled={isLoading}
              accept={[
                'audio/aac',
                'audio/mpeg',
                'audio/ogg',
                'audio/x-wav',
                'audio/m4a',
                'audio/x-m4a',
                'audio/mp4',
              ]}
              onDrop={handleDropAudioFile}
              onDropRejected={() => setErrorMessage('Arquivo inválido!')}
              extensions="aac, mp2, mp3, mpga, ogg, wav e m4a."
              maxFileSize="25 MB"
            />
          ) : (
            <Preview file={file} />
          )}

          <Heading as="h6" mt={6} fontSize="md">
            Carregar imagem:
          </Heading>

          {!values.fileThumbnail ? (
            <CustomDropzone
              fileThumbnail={fileThumbnail}
              isDisabled={isLoading}
              accept={['image/jpg', 'image/png', 'image/jpeg']}
              onDrop={handleDropFileThumbnail}
              onDropRejected={() => setErrorMessage('Arquivo inválido!')}
              extensions="JPG, JPEG, PNG"
              maxFileSize="25 MB"
            />
          ) : (
            <PreviewImage fileThumbnail={fileThumbnail} />
          )}

          <div className="form-group">
            <Heading as="label" htmlFor="description" mt={6} fontSize="md" color="#202123">
              Descrição <Text as="em">(opcional)</Text>
            </Heading>

            <div id="description">
              <EditorRichText value={editorState} onChange={handleEditorStateChange} />
            </div>
          </div>

          <DataLoading loading={isLoading} className="d-flex justify-content-center mt-4">
            {errorMessage && !isLoading && (
              <div className="mt-4">
                <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
                  <div className="text-center">{errorMessage}</div>
                </div>
              </div>
            )}

            <div className="d-flex justify-content-between mt-4">
              <div>
                {contentId && (
                  <button
                    onClick={event => {
                      event.preventDefault();
                      setIsShowModal(true);
                    }}
                    className="btn btn-outline-danger"
                  >
                    Excluir conteúdo
                  </button>
                )}
              </div>
              <div className="d-flex align-center justify-content-end">
                <button onClick={handleDiscardChanges} className="btn btn-light">
                  Descartar mudanças
                </button>

                <button type="submit" className="btn btn-primary ml-3">
                  Salvar
                </button>
              </div>
            </div>
          </DataLoading>
        </div>
      </form>
      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="mb-1 py-4">
            <h3 className="text-center">Você tem certeza que deseja excluir o conteúdo?</h3>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <button onClick={() => setIsShowModal(false)} className="btn btn-light mr-4">
              Cancelar
            </button>
            <button onClick={handleDelete} className="btn btn-danger" disabled={isLoading}>
              {isLoading ? <Spinner small /> : 'Excluir'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AudioForm;
