import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { CheckoutSettings } from '../types';
import CheckoutSettingsTableRow from './CheckoutSettingsTableRow';

interface CheckoutSettingsTableProps extends TableContainerProps {
  checkoutSettingsList: CheckoutSettings[];
  onGetCheckoutLink: (productId: number, checkoutType: CheckoutSettings['checkoutType']) => string;
  onCheckoutLinkCopy: (_, result: boolean) => void;
  onProductToChangeCheckoutTypeClick: (productId: number) => void;
}

export default function CheckoutSettingsTable({
  checkoutSettingsList,
  onGetCheckoutLink,
  onCheckoutLinkCopy,
  onProductToChangeCheckoutTypeClick,
  ...rest
}: CheckoutSettingsTableProps) {
  return (
    <TableContainer {...rest}>
      <Table>
        <Thead>
          <Tr sx={{ th: { color: '#202123BF' } }}>
            <Th>Nome do produto</Th>

            <Th maxWidth="6.9375rem" whiteSpace="break-spaces">
              Tipo de checkout
            </Th>

            <Th maxWidth="7.6875rem" whiteSpace="break-spaces">
              Link do checkout
            </Th>

            <Th>Última Atualização </Th>

            <Th textAlign="center">Ações</Th>
          </Tr>
        </Thead>

        <Tbody sx={{ 'tr:nth-of-type(odd)': { background: '#2021230D' } }}>
          {checkoutSettingsList?.map(checkoutSettingsItem => (
            <CheckoutSettingsTableRow
              key={checkoutSettingsItem.id}
              checkoutSettingsItem={checkoutSettingsItem}
              onGetCheckoutLink={onGetCheckoutLink}
              onCheckoutLinkCopy={onCheckoutLinkCopy}
              onProductToChangeCheckoutTypeClick={onProductToChangeCheckoutTypeClick}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
