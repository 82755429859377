import Joi from 'joi';
import { ICheckoutSettingsEditPageThanks } from './types';

const INVALID_URI_MESSAGE = 'Por favor, forneça uma URL válida. Exemplo: https://www.example.com';

export default Joi.object<ICheckoutSettingsEditPageThanks>().keys({
  thankPageType: Joi.string().valid('DEFAULT', 'EXTERNAL').default('DEFAULT').required(),
  thankPageForApprovedPurchases: Joi.when('thankPageType', {
    is: 'EXTERNAL',
    then: Joi.string().uri().allow(null).required().messages({
      'string.uri': INVALID_URI_MESSAGE,
      'string.empty': 'URL não pode ficar vazia.',
      'any.required': 'URL não pode ficar vazia.',
    }),
  }),
  thankPageForPurchasesAwaitingPayment: Joi.when('thankPageType', {
    is: 'EXTERNAL',
    then: Joi.string().uri().allow(null).required().messages({
      'string.uri': INVALID_URI_MESSAGE,
      'string.empty': 'URL não pode ficar vazia.',
      'any.required': 'URL não pode ficar vazia.',
    }),
  }),
  thankPageForPurchasesAwaitingAnalysis: Joi.when('thankPageType', {
    is: 'EXTERNAL',
    then: Joi.string().uri().allow(null).required().messages({
      'string.uri': INVALID_URI_MESSAGE,
      'string.empty': 'URL não pode ficar vazia.',
      'any.required': 'URL não pode ficar vazia.',
    }),
  }),
});
