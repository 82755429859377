import { Button, Container, Heading, Link, VStack } from '@chakra-ui/react';

export default function NotFound() {
  return (
    <Container display="flex" justifyContent="center" height="100vh" alignItems="center">
      <VStack>
        <Heading fontSize="8xl">404</Heading>
        <Heading fontSize="4xl">Página não encontrada.</Heading>
        <Link href='/'>
          <Button borderRadius="full" variant="outline" colorScheme="primary">
            Ir para início
          </Button>
        </Link>
      </VStack>
    </Container>
  );
}
