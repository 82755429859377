import 'react-h5-audio-player/lib/styles.css';
import './styles.css';

import { ChangeEvent, useRef } from 'react';
import { MdForward10, MdReplay10 } from 'react-icons/md';

import AudioPlayer from 'react-h5-audio-player';
import PlaybackRateSelect from './PlaybackRateSelect';
import { useColorModeValue } from '@chakra-ui/react';

function Player({ src }) {
  const bgColor = useColorModeValue('#fff', '#202123');
  const textColor = useColorModeValue('#202123', '#FFF');


  const playerRef = useRef(null);

  function handleChangePlaybackRate(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    playerRef.current.audio.current.playbackRate = value;
  }

  return (
    <AudioPlayer
      style={{ background: bgColor }}
      layout="stacked"
      customAdditionalControls={[<PlaybackRateSelect onChange={handleChangePlaybackRate} />]}
      customVolumeControls={[]}
      customIcons={{
        forward: <MdForward10 color={textColor} size={32} />,
        rewind: <MdReplay10 color={textColor} size={32} />,
      }}
      progressJumpSteps={{ backward: 10000, forward: 10000 }}
      ref={playerRef}
      src={src}
    />
  );
}

export default Player;
