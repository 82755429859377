import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { IoAlertCircleOutline } from 'react-icons/io5';

export default function ConfirmDeletionModal({ isOpen, onClose, isSubmitting, onSubmit }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'sm', lg: 'lg' }} isCentered>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit} textAlign="center" padding="15px" margin="15px">
        <ModalCloseButton />

        <Box width="full" display="flex" justifyContent="center" color="red.500">
          <IoAlertCircleOutline fontSize="80px" />
        </Box>

        <ModalHeader fontSize="18px" fontWeight={600}>
          Exclusão de compartilhamento
        </ModalHeader>

        <ModalBody fontSize="14px" fontWeight={500}>
          Tem certeza que deseja excluir esse compartilhamento?
        </ModalBody>

        <ModalFooter>
          <Stack
            width="full"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Button colorScheme="red" width="150px" onClick={onClose}>
              Não
            </Button>
            <Button
              colorScheme="gray"
              width="150px"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Sim
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
