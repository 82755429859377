import { Box } from '@chakra-ui/react';

interface CustomTooltipProps {
  children: React.ReactNode;
}

export default function CustomTooltip({ children }: CustomTooltipProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      bg="#202123"
      color="white"
      borderRadius="md"
      px={2}
      fontSize="sm"
      position="relative"
      border="none"
      sx={{
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 0,
          height: 0,
          borderTop: 'solid 8px #202123',
          borderLeft: 'solid 8px transparent',
          borderRight: 'solid 8px transparent',
          zIndex: -1,
        },
      }}
    >
      {children}
    </Box>
  );
}
