import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { FIlteredVideosType } from '..';
import { CardVideoLibraryMobileList } from './CardVideoLibraryMobileList';

type Params = {
  videos: FIlteredVideosType[];
  updateStatusTranscode: ({ id, transcode, thumbnail }) => void;
  onDeleteVideoList: (id: number) => void;
  onOpenPreviewVideo: (id: number) => void;
};

export function CardVideoLibraryMobileListView({
  onDeleteVideoList,
  updateStatusTranscode,
  videos,
  onOpenPreviewVideo,
}: Params) {
  if (!videos.length) return <></>;

  return (
    <Box display="flex" flexWrap="wrap">
      {videos.map(video => (
        <CardVideoLibraryMobileList
          key={nanoid()}
          video={video}
          updateStatusTranscode={updateStatusTranscode}
          onDeleteVideoList={onDeleteVideoList}
          onOpenPreviewVideo={onOpenPreviewVideo}
        />
      ))}
    </Box>
  );
}
