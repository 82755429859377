import { Table, TableContainer, TableContainerProps, Th, Thead, Tr, Tbody } from '@chakra-ui/react';
import { InstructorView } from '../types';
import InstructorsTableRow from './InstructorsTableRow';

interface PromotionsTableProps extends TableContainerProps {
  instructors: InstructorView[];
  onPromotionStatusChange?: () => void;
  onSelectInstructorToEdit?: (instructor: InstructorView) => void;
  enableOrDisableInstructor(instructorId: number): void;
}

export default function InstructorsTablet({
  instructors,
  onPromotionStatusChange,
  onSelectInstructorToEdit,
  enableOrDisableInstructor,
  ...rest
}: PromotionsTableProps) {
  return (
    <TableContainer {...rest}>
      <Table>
        <Thead>
          <Tr sx={{ th: { color: '#202123BF' } }}>
            <Th>Nome</Th>
            <Th>Email</Th>
            <Th>Telefone</Th>
            <Th>Disciplinas</Th>
            <Th>Status</Th>
            <Th textAlign="center">Ações</Th>
          </Tr>
        </Thead>

        <Tbody sx={{ 'tr:nth-of-type(odd)': { background: '#2021230D' } }}>
          {instructors?.map(instructor => (
            <InstructorsTableRow
              key={instructor.id}
              instructor={instructor}
              enableOrDisableInstructor={enableOrDisableInstructor}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
