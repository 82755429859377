import { Center, Spinner, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { axiosBackend } from '../../../../../api/Configuration';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import Monitoring from '../../components/Monitoring';

export default function MonitoringTabPanel() {
  const [monitoringList, setMonitoringList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    async function loadInitialItems() {
      try {
        const { data: response } = await axiosBackend().get(
          `/active-campaign/monitoring?page=${page}`
        );

        setMonitoringList(response?.data?.data || []);
        setTotalItems(response?.data?.total || 0);
      } catch (error) {
        toast({
          title: 'Erro ao buscar dados de monitoramento',
          description: ErrorResponse(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (page === 1) {
      loadInitialItems();
    }
  }, [page, toast]);

  async function loadMoreItems() {
    try {
      setIsLoading(true);
      const { data: response } = await axiosBackend().get(
        `/active-campaign/monitoring?page=${page + 1}`
      );

      setMonitoringList(prevItems => [...(prevItems || []), ...(response?.data?.data || [])]);
      setTotalItems(response?.data?.total || 0);
      setPage(prevPage => prevPage + 1);
    } catch (error) {
      toast({
        title: 'Erro ao buscar dados de monitoramento',
        description: ErrorResponse(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <Center my={20}>
        <Spinner size="xl" color="primary.500" />
      </Center>
    );
  }

  return (
    <>
      {!!monitoringList.length ? (
        <Monitoring items={monitoringList} total={totalItems} onLoadMore={loadMoreItems} />
      ) : (
        <Text color="#20212380" fontSize={{ base: 'sm', xl: 'lg' }}>
          Nenhuma atividade registrada.
        </Text>
      )}
    </>
  );
}
