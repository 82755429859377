import { Box, Button, Heading, Link, Stack, Text, Toast } from '@chakra-ui/react';
import process from 'process/browser';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CourseAPI from '../../../../../api/Course';
import { useCourseStudent } from '../../../../../contexts/CourseStudentContextV2';
import { useStudentProgress } from '../../../../../contexts/ProgressContextV2';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { ID_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import { IParams } from '../types';

const BASE_URL = process.env.REACT_APP_BASE_URL_API;

function ViewCertificate() {
  const { course, highlightColor } = useCourseStudent();
  const { progress } = useStudentProgress();

  const { courseId } = useParams<IParams>();
  const studentId = localStorage.getItem(ID_LOCAL_STORAGE);
  const [certificateData, setCertificateData] = useState(null);
  const hideCertificate = progress.progress < course?.certificate?.emissionCondition;

  useEffect(() => {
    async function generateCertificate() {
      try {
        const { data: certificateData } = await CourseAPI.certificate.generate(courseId, studentId);

        if (certificateData) {
          setCertificateData(certificateData);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }

    generateCertificate();
  }, [courseId, studentId]);

  const scrollbarStyle = {
    '::-webkit-scrollbar': {
      width: '0.5rem',
      height: '0.5rem',
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb:hover': {
      bg: 'gray.600',
    },
    '::-webkit-scrollbar-thumb': {
      paddingRight: '0.5rem',
      paddingLeft: '0.5rem',
      borderRadius: 'md',
      backgroundColor: 'gray.400',
    },
  };

  return (
    <Stack maxW="full" maxH="100vh">
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        alignItems={{ base: 'center', md: 'baseline', lg: 'baseline' }}
        justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
      >
        <Heading
          color="#FFF"
          fontSize={{ base: '18px', md: '24px', lg: '24px' }}
          margin="0"
          fontWeight="500"
        >
          Certificado
        </Heading>

        <Button
          as={Link}
          href={`${BASE_URL}/certificate/${courseId}/course/${studentId}/user?download=true`}
          target="_blank"
          size="sm"
          width={{ base: 'full', md: 'auto', lg: 'auto' }}
          color="black"
          bg={highlightColor}
          _hover={{ bgColor: highlightColor?.concat('90') }}
          isDisabled={hideCertificate}
          style={
            hideCertificate
              ? {
                  pointerEvents: 'none',
                  textDecoration: 'none',
                }
              : {
                  pointerEvents: 'visible',
                  textDecoration: 'none',
                }
          }
        >
          Emitir certificado
        </Button>
      </Stack>

      {progress.progress < 100 && (
        <Text fontSize="md" color="#FFF">
          Para emitir o certificado você precisa concluir{' '}
          <Text as="span">{course?.certificate?.emissionCondition}% do conteúdo</Text>, continue
          progredindo no curso.
        </Text>
      )}

      <Box
        overflowY="auto"
        bg="#FFF"
        sx={scrollbarStyle}
        filter={hideCertificate && 'blur(0.5rem)'}
        dangerouslySetInnerHTML={{ __html: certificateData?.certificateTemplete }}
      />
    </Stack>
  );
}

export default ViewCertificate;
