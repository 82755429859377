import { axiosBackend } from './Configuration';

const cademiIntegrationAPI = {
  store: async ({ apiKey, urlPostback }) => {
    try {
      const { data: cademiIntegrationData } = await axiosBackend().post('/cademi/api-key', {
        apiKey,
        urlPostback,
      });

      return cademiIntegrationData;
    } catch (error) {
      throw error;
    }
  },

  show: async () => {
    try {
      const { data: cademiIntegrationData } = await axiosBackend().get('/cademi/api-key');

      return cademiIntegrationData;
    } catch (error) {
      throw error;
    }
  },

  update: async (id, { apiKey, urlPostback }) => {
    try {
      const { data: cademiIntegrationData } = await axiosBackend().patch(`/cademi/api-key/${id}`, {
        apiKey,
        urlPostback,
      });

      return cademiIntegrationData;
    } catch (error) {
      throw error;
    }
  },
};

export default cademiIntegrationAPI;
