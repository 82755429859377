import { Td, Tr } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { memo } from 'react';
import { Payment } from '../../..';
import { getStatusColor } from '../../../utils/getStatusColor';
import { getStatusText } from '../../../utils/getStatusText';
import { getPaymentMethodText } from '../../../../SubscriberManaGement/utils/getPaymentMethodText';

interface PaymentTableRowProps {
  payment: Payment;
}

function PaymentTableRow({ payment }: PaymentTableRowProps) {
  return (
    <Tr color="#202123" sx={{ td: { p: 6 } }}>
      {/* ID */}
      <Td maxWidth="173px" fontWeight="semibold" color="gray.700">
        {payment.transactionId}
      </Td>

      {/* STATUS */}
      <Td maxWidth="190px" color={getStatusColor(payment.status)} fontWeight="medium">
        {getStatusText(payment.status)}
      </Td>

      {/* NOME */}
      <Td maxWidth="246px" isTruncated>
        {payment.fullName}
      </Td>

      {/* FORMA DE PAGAMENTO */}
      <Td maxWidth="189px" isTruncated>
        {getPaymentMethodText(payment.paymentMethod)}
      </Td>

      <Td maxWidth="223px" isTruncated>
        {dayjs(payment.createdAt).format('DD/MM/YYYY')}
      </Td>
    </Tr>
  );
}

export default memo(PaymentTableRow);
