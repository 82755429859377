import { Avatar, Box, HStack, Text } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';

import { LinkBox, LinkOverlay } from '@chakra-ui/react';

import { NAME_LOCAL_STORAGE, PHOTO_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';

const fullName = localStorage.getItem(NAME_LOCAL_STORAGE);
const photo = localStorage.getItem(PHOTO_LOCAL_STORAGE);

export default function UserProfile({ onToggleSidebar }) {
  const history = useHistory();

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();

    onToggleSidebar();

    history.push('/profile/user');
  }

  return (
    <LinkBox as="div">
      <LinkOverlay as={Link} to="/profile/user" onClick={handleClick}>
        <HStack spacing={4}>
          <Avatar src={photo} bg="secondary.500" color="primary.500" name={fullName} />
          <Box>
            <Text fontSize="small">{fullName}</Text>
            <Text fontWeight="normal" fontSize="small">
              Bem-vindo!
            </Text>
          </Box>
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
}
