import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
} from '@chakra-ui/react';
import { FormEvent } from 'react';

interface ModalAddOrEditDisciplineProps {
  id: number;
  isOpen: boolean;
  onClose: () => void;
  areaId: number;
  setAreaId: (id: number) => void;
  disciplineName: string;
  setDisciplineName: (value: string) => void;
  onSubmit: (event: FormEvent) => void;
  isSubmitting: boolean;
  areaData: { id: number; name: string }[];
  loading: boolean;
  formValidation?: {
    name: {
      isInvalid: boolean;
      message: string;
    };
    areaId: {
      isInvalid: boolean;
      message: string;
    };
  };
}

export function ModalAddOrEditDiscipline({
  id,
  isOpen,
  onClose,
  areaId,
  setAreaId,
  disciplineName,
  setDisciplineName,
  onSubmit,
  isSubmitting,
  formValidation,
  areaData,
  loading,
}: ModalAddOrEditDisciplineProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setDisciplineName('');
        setAreaId(0);
        onClose();
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader fontWeight={700} fontSize="18px">
          {id ? 'Editar disciplina' : 'Adicionar uma disciplina'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody marginX={1}>
          <Stack spacing={2}>
            <FormControl isInvalid={formValidation?.areaId?.isInvalid}>
              <FormLabel fontWeight={600} fontSize="14px">
                Area
              </FormLabel>

              <FormHelperText fontSize="14px">
                Selecione uma área que sua disciplina será vinculada
              </FormHelperText>
              <Select
                placeholder="Selecione uma área"
                size="sm"
                borderRadius={6}
                focusBorderColor="orange.500"
                color="#20212380"
                isDisabled={loading}
                value={areaId}
                onChange={e => setAreaId(Number(e.target.value))}
              >
                {areaData?.map(area => (
                  <option key={area.id} value={area.id}>
                    {area.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {formValidation?.areaId?.isInvalid && formValidation?.areaId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.name?.isInvalid}>
              <FormLabel fontWeight={600} fontSize="14px">
                Nome da disciplina
              </FormLabel>
              <Input
                placeholder="Digite aqui"
                size="sm"
                borderRadius={6}
                focusBorderColor="orange.500"
                color="#20212380"
                value={disciplineName}
                onChange={e => setDisciplineName(e.target.value)}
                maxLength={50}
              />
              <FormErrorMessage>
                {formValidation?.name?.isInvalid && formValidation?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            size="sm"
            mr={3}
            onClick={() => {
              setDisciplineName('');
              setAreaId(0);
              onClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="orange"
            size="sm"
            type="submit"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {id ? 'Salvar' : 'Adicionar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
