import { CopyIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  useClipboard,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import QRCode from 'qrcode.react';
import { useEffect, useMemo, useState } from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';
import { IoMdAlarm } from 'react-icons/io';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import pixQrCodePlaceholder from '../../../assets/images/QRCode.png';
import backgroundImage from '../../../assets/images/backgrounds/box_colors.png';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';
import { TOKEN_PURCHASE, UTM_LOCAL_STORAGE } from '../../../helpers/LocalStorageHelper';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import useFetch from '../../../hooks/useFetch';

interface IPurchaseStatusResponse {
  purchaseId: number;
  purchaseStatus: 'waiting_payment' | 'paid' | 'expired' | 'default';
}

export default function PixGeneratedPage() {
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const history = useHistory();
  const purchaseToken = localStorage.getItem(TOKEN_PURCHASE);
  const [isPixCodeExpired, setIsPixCodeExpired] = useState(false);
  const purchase_id = searchParams.get('purchase_id');
  const pix_expiration_date = searchParams.get('pix_expiration_date');
  const pix_qr_code = searchParams.get('pix_qr_code');
  const total_value = searchParams.get('total_value');
  const bgButton = isPixCodeExpired ? '#dd9091' : '#f5b88b';
  const buttonText = isPixCodeExpired
    ? 'Código do pix expirado. Gere um novo código para continuar.'
    : 'O Qr Code expira em 20 minutos.';
  const buttonIcon = isPixCodeExpired ? (
    <IoCloseCircleOutline size="30px" />
  ) : (
    <IoMdAlarm size="30px" />
  );

  const { onCopy, value, setValue, hasCopied } = useClipboard(pix_qr_code);

  const fontSize: string = useBreakpointValue({
    base: '70px',
    lg: '80px',
  });

  const { data: response, fetchData: getPurchaseStatus } = useFetch<
    UnificadaFront.ResponseJSON<IPurchaseStatusResponse>
  >({
    url: `/purchases/${purchase_id}/checkout`,
    method: 'get',
    autoFetch: false,
  });

  useEffect(() => {
    if (purchaseToken) {
      localStorage.removeItem(TOKEN_PURCHASE);
    }
  }, [purchaseToken]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    async function verifyPaymentStatus() {
      try {
        if (purchase_id) {
          await getPurchaseStatus();

          if (response) {
            if (response?.data.purchaseStatus === 'paid') {
              const utmInitialParams = {
                utm_id: null,
                utm_source: null,
                utm_medium: null,
                utm_campaign: null,
                utm_term: null,
                utm_content: null,
              };

              localStorage.setItem(UTM_LOCAL_STORAGE, JSON.stringify(utmInitialParams));

              const paymentMethod = 'payment_method=pix';

              history.push(`/launch-checkout-thanks/payment-completed?${paymentMethod}`);
            }
          }
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }

    interval = setInterval(() => {
      verifyPaymentStatus();
    }, 3000);

    return () => clearInterval(interval);
  }, [getPurchaseStatus, history, purchase_id, response]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    async function verifyPixExpired() {
      const isExpired = dayjs(pix_expiration_date).format() < dayjs().format();

      if (isExpired) {
        setIsPixCodeExpired(isExpired);
      }
    }

    interval = setInterval(() => {
      verifyPixExpired();
    }, 15000);

    return () => clearInterval(interval);
  }, [pix_expiration_date]);

  return (
    <Stack minHeight="100dvh">
      <Flex
        flex={1}
        px={5}
        paddingTop={{ base: '15px', lg: '50px' }}
        backgroundImage={backgroundImage}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize="cover"
        justify="center"
        align="center"
      >
        <Stack
          width="full"
          alignItems="center"
          spacing="30px"
          maxWidth={{ base: 'container.sm', lg: 'container.md' }}
        >
          <Stack alignItems="center">
            <FaRegCheckCircle fontSize={fontSize} color="#EC7117" />

            <Heading
              as="h1"
              color="#EC7117"
              fontSize={{ base: '32px', lg: '40px' }}
              textAlign="center"
              mx={{ base: 5 }}
            >
              Pedido realizado com sucesso!
            </Heading>
          </Stack>

          <Stack fontWeight="500" spacing="15px" fontSize="md" textAlign="center">
            <Text>
              Seu pedido foi processado. Escaneie o QR Code ou copie e cole o código abaixo para
              pagar utilizando o PIX.{' '}
              <Text as="span" fontWeight="600">
                {' '}
                Após o pagamento, aguarde nesta página a confirmação do pagamento.
              </Text>{' '}
              Em seguida você receberá um e-mail com as informações de confirmação de compra e
              orientações de acesso ao seu produto.
            </Text>
            <Text>
              Para sua maior comodidade, o QR Code/Código será enviado para o seu e-mail também.
            </Text>
            <Text fontWeight="600">Consulte seu e-mail para obter detalhes sobre sua compra.</Text>
          </Stack>

          <Stack width="full" spacing={{ base: '10px', lg: '15px' }}>
            <Stack width="full" fontWeight="600" alignItems="center">
              <Text fontSize={{ base: '16px', lg: '20px' }}>Valor a pagar:</Text>
              <Text fontSize={{ base: '24px', lg: '32px' }} color="#EC7117">
                {convertToBrazilianCurrency(total_value)}
              </Text>
            </Stack>

            <Stack hidden={isPixCodeExpired} width="full" alignItems="center">
              <QRCode value={pix_qr_code} size={182} />
            </Stack>

            <Stack hidden={!isPixCodeExpired} width="full" alignItems="center">
              <Flex position="relative" align="center" justify="center">
                <Image src={pixQrCodePlaceholder} />
                <Circle
                  position="absolute"
                  size="8.25rem"
                  bg="default.500"
                  color="white"
                  textAlign="center"
                  fontSize="xs"
                >
                  Código do pix expirado. Gere um novo código para continuar.
                </Circle>
              </Flex>
            </Stack>

            <Stack width="full" alignItems="center" spacing="30px">
              <Text fontWeight="600">
                Ok! Vamos lá. Você obterá acesso ao produto imediatamente após o pagamento.
              </Text>
              <Stack width="full" alignItems="center" spacing={0} fontWeight="500">
                <Text>- Abra o aplicativo do banco</Text>
                <Text>- Procure pelo PIX</Text>
                <Text>- Selecionar "Ler QR Code" ou copie e cole o código PIX</Text>
              </Stack>
            </Stack>

            <Flex>
              <Input
                bgColor="#e9e9e9"
                value={value}
                isReadOnly
                border="none"
                onChange={e => {
                  setValue(e.target.value);
                }}
                borderRightRadius="0"
                mr="1px"
              />
              <Button
                borderLeftRadius="0"
                width="200px"
                fontWeight="400"
                bgColor="#e9e9e9"
                leftIcon={<CopyIcon boxSize="14px" />}
                onClick={onCopy}
                fontSize={{ base: 'small', lg: 'medium' }}
              >
                {hasCopied ? 'Copiado!' : 'Copiar código'}
              </Button>
            </Flex>

            <Stack
              direction="row"
              width="full"
              padding="3px 5px"
              fontWeight="600"
              justifyContent="center"
              alignItems="center"
              bgColor={bgButton}
              borderRadius={5}
              color="#202123"
            >
              <Box width={{ base: '50px', md: '35px', lg: '35px' }}>{buttonIcon}</Box>
              <Text display="flex" noOfLines={2} flexWrap="wrap">
                {buttonText}
              </Text>
            </Stack>

            <Stack width="full" alignItems="center" hidden={!isPixCodeExpired}>
              <Button
                onClick={() => window.close()}
                bgColor="#EC7117"
                color="#FFF"
                _hover={{ bgColor: '#aa4a05' }}
              >
                Gerar QR Code
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Flex>

      {/* FOOTER */}
      <Flex as="footer" justify="center" w="full">
        <Link
          fontSize="md"
          w="full"
          h="full"
          textAlign="center"
          py={5}
          href="https://proluno.me/"
          target="_blank"
          isExternal
        >
          Tecnologia 🧡 Proluno - Ltda.
        </Link>
      </Flex>
    </Stack>
  );
}
