import { Center, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function PreviewHeader() {
  return (
    <Center
      position="fixed"
      w="full"
      bg="#E85347"
      px={4}
      py={3}
      zIndex="banner"
      color="white"
      textAlign={{ base: 'start', lg: 'center' }}
      height="3rem"
    >
      <Text
        fontSize={{ base: 'xs', lg: 'sm' }}
        whiteSpace="pre-wrap"
        fontWeight="medium"
        lineHeight="normal"
      >
        Modo visualização. Para retornar ao gerenciamento de área de membros,{' '}
        <Text
          as={Link}
          to="/members-area-management/netflix"
          cursor="pointer"
          textDecoration="underline"
          fontWeight={700}
        >
          clique aqui.
        </Text>
      </Text>
    </Center>
  );
}
