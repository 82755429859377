import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { FormEvent } from 'react';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { IShowcase } from '..';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import { SelectedShowcase } from '../Showcase';

interface IProps {
  isOpen: boolean;
  selectedShowcase: SelectedShowcase;
  showcaseItemId: number;
  showcases: IShowcase[];
  onClose: () => void;
  setShowcases: (showcases: IShowcase[]) => void;
}

export function RemoveShowcaseItemModal({
  isOpen,
  selectedShowcase,
  showcaseItemId,
  showcases,
  onClose,
  setShowcases,
}: IProps) {
  function afterRemoveItemConfirm() {
    const showcaseIndex = showcases.findIndex(
      showcaseData => showcaseData.showcaseId === selectedShowcase.id
    );

    if (showcaseIndex === -1) {
      onClose();
      return;
    }

    const currentShowcases = [...showcases];
    const showcase = currentShowcases[showcaseIndex];

    if (selectedShowcase.showcaseType === 'products') {
      showcase.products = showcase.products.filter(
        product => product.showcaseCourseId !== showcaseItemId
      );
    }

    if (selectedShowcase.showcaseType === 'plans') {
      showcase.plans = showcase.plans.filter(plan => plan.showcasePlanId !== showcaseItemId);
    }

    setShowcases(currentShowcases);
    onClose();
  }

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    url: `/showcase-course/${showcaseItemId}`,
    data: { showcaseType: selectedShowcase.showcaseType },
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Item removido com sucesso!',
      callback: afterRemoveItemConfirm,
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'sm', lg: 'lg' }} isCentered>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit} textAlign="center" padding="15px" margin="15px">
        <ModalCloseButton />

        <Box width="full" display="flex" justifyContent="center" color="red.500">
          <IoAlertCircleOutline fontSize="80px" />
        </Box>

        <ModalHeader fontSize="18px" fontWeight={600}>
          Exclusão de {selectedShowcase.showcaseType === 'plans' ? 'plano' : 'produto'}
        </ModalHeader>

        <ModalBody fontSize="14px" fontWeight={500}>
          Tem certeza que deseja excluir o{' '}
          {selectedShowcase.showcaseType === 'plans' ? 'plano' : 'produto'} da vitrine?
        </ModalBody>

        <ModalFooter>
          <Stack
            width="full"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Button colorScheme="red" width="150px" onClick={onClose}>
              Não
            </Button>
            <Button
              colorScheme="gray"
              width="150px"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Sim
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
