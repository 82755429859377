import {
  Box,
  Button,
  HStack,
  Input,
  VStack,
  Text,
  useClipboard,
  Stack,
  Heading,
  Badge,
} from '@chakra-ui/react';
import Header from '../components/Header';
import useFetch from '../../../../hooks/useFetch';
import { ResponseJSON } from '../../../../utils/CreateToastAlert';
import { useEffect, useState } from 'react';
import base64 from 'base-64';
import dayjs from 'dayjs';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import process from 'process/browser';

function getExpiresIn(token: string) {
  const payload = JSON.parse(base64.decode(token.split('.')[1]));

  const expiresIn = payload.exp - Math.floor(Date.now() / 1000);

  return dayjs().add(expiresIn, 'second').format('DD/MM/YYYY');
}

function Proluno() {
  const { onCopy, value, setValue, hasCopied } = useClipboard('');

  const [expiresIn, setExpiresIn] = useState('' as string | number);

  const { data, loading } = useFetch<ResponseJSON<{ apiToken: string }>>({
    method: 'get',
    url: '/platforms/api-token',
    authenticated: true,
    autoFetch: true,
  });

  const { handleSubmit, isLoading } = useHandleSubmit<null, ResponseJSON<{ apiToken: string }>>({
    method: 'post',
    url: '/platforms/api-token',
    authenticated: true,
    onSuccess: {
      callback: response => {
        if (!response.data) return;

        const { data } = response.data;

        setValue(data.apiToken);
        setExpiresIn(getExpiresIn(data.apiToken));
      },
    },
  });

  useEffect(() => {
    if (data?.data) {
      setExpiresIn(getExpiresIn(data.data.apiToken));

      setValue(data.data.apiToken);
    }
  }, [data, setValue]);

  return (
    <Box>
      <Header
        title="Proluno"
        description="Integre com a API do Proluno e tenha acesso a todos os dados dos seus alunos e muito mais."
      />
      <VStack spacing={6} marginTop={8} alignItems="flex-start">
        <Text fontSize="lg">
          Gere seu token de integração clicando no botão abaixo, o token tem a validade de 1 ano.
        </Text>
        <HStack mb={2} width="full" alignItems="flex-start">
          <VStack width="full">
            <Input
              placeholder="Você ainda não possui sua API Token"
              value={loading ? '...' : value}
              isReadOnly
              focusBorderColor="orange.400"
            />
            <Text hidden={!value}>Seu token expira em: {expiresIn}</Text>
          </VStack>
          <Button isLoading={loading || isLoading} onClick={onCopy} isDisabled={!value}>
            {hasCopied ? 'Copiado!' : 'Copiar'}
          </Button>
        </HStack>
        <Button
          colorScheme="orange"
          isLoading={loading || isLoading}
          isDisabled={!!value}
          onClick={() => handleSubmit()}
        >
          Gerar API Token
        </Button>
      </VStack>
      <Stack marginTop={8} spacing={8}>
        <Box>
          <Text fontSize="lg">Rotas disponíveis:</Text>
          <Text>
            Endpoint: <Text as="b">{process?.env.REACT_APP_BASE_URL_API}</Text>
          </Text>
        </Box>
        <Heading as="h2" fontSize="lg">
          Conversões
        </Heading>
        <Box>
          <Text>Salvando leads em páginas externas:</Text>
          <HStack marginTop={4}>
            <Badge variant="subtle" colorScheme="blue">
              POST
            </Badge>
            <Text>/conversions/external-form</Text>
          </HStack>
          <VStack alignItems="flex-start" marginTop={4}>
            <Text>
              Informe o sua API Token por query com `?token=api_token` ou pela header
              `Authorization=Bearer api_token`
            </Text>
            <Text>Body:</Text>
            <Text>
              name: <Text as="b">string</Text>, email: <Text as="b">string</Text>, phone?:{' '}
              <Text as="b">string</Text>, productId?: <Text as="b">number</Text>, redirectUrl?:{' '}
              <Text as="b">string</Text>
            </Text>
          </VStack>
        </Box>
      </Stack>
    </Box>
  );
}

export default Proluno;
