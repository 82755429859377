import Joi from 'joi';
import { UTM } from './types';

const noSpace = /^[^\s]+$/;

const REQUIRED_MESSAGES = {
  'string.empty': 'Não pode ficar vazio.',
  'string.base': 'Não pode ficar vazio.',
};

const INVALID_URI_MESSAGE =
  'Por favor, forneça uma URL válida. Exemplo: https://www.examplo.com.br';

const NO_SPACE_MESSAGE = 'Não pode conter espaços.';

export default Joi.object<UTM>().keys({
  link: Joi.string()
    .uri()
    .required()
    .messages({
      ...REQUIRED_MESSAGES,
      'string.uri': INVALID_URI_MESSAGE,
    }),
  utmId: Joi.string().regex(noSpace).allow(null).messages({
    'string.pattern.base': NO_SPACE_MESSAGE,
  }),
  utmSource: Joi.string()
    .regex(noSpace)
    .required()
    .messages({
      ...REQUIRED_MESSAGES,
      'string.pattern.base': NO_SPACE_MESSAGE,
    }),
  utmMedium: Joi.string()
    .regex(noSpace)
    .required()
    .messages({
      ...REQUIRED_MESSAGES,
      'string.pattern.base': NO_SPACE_MESSAGE,
    }),
  utmCampaign: Joi.string()
    .regex(noSpace)
    .allow(null)
    .messages({
      ...REQUIRED_MESSAGES,
      'string.pattern.base': NO_SPACE_MESSAGE,
    }),
  utmContent: Joi.string().regex(noSpace).allow(null).messages({
    'string.pattern.base': NO_SPACE_MESSAGE,
  }),
  utmTerm: Joi.string().regex(noSpace).allow(null).messages({
    'string.pattern.base': NO_SPACE_MESSAGE,
  }),
});
