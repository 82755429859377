import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useState } from 'react';
import Platform from '../../../../api/Platform';

import Toast from '../../../../components/Toast';
import { useTheme } from '../../../../contexts/ThemeContext';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../helpers/UploadHelper';
import BackButton from '../Buttons/BackButton';
import NextButton from '../Buttons/NextButton';
import LogoPreview from '../LogoPreview';
import SelectLogo from '../SelectLogo';

export default function TabLogo({ onGoToNextTab, onGoToPreviousTab }) {
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [preview, setPreview] = useState('');
  const { themeMarketplace, getTheme } = useTheme();

  function handlePhotoChange(acceptedFiles, event: ChangeEvent<HTMLFormElement>) {
    const file = acceptedFiles[0];

    if (file) {
      setPreview(URL.createObjectURL(file));
      setLogo(file);
    }
  }

  async function onSubmitLogo(event: FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault();

    let logoKey = '';

    try {
      setLoading(true);

      if (logo && typeof logo !== 'string') {
        const { newFileName, link } = await UploadHelper.upload(logo, 'logos');

        await Platform.integrationLogo({ logoData: encodeURI(newFileName) });

        setLogo(link);

        logoKey = `logos/${newFileName}`;

        await getTheme();
        Toast('Logo atualizada!', 'success');
        setLoading(false);
      }
      onGoToNextTab();
    } catch (error) {
      if (logoKey) {
        await Platform.deleteFileByKey(logoKey);
      }

      Toast(ErrorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function handleDiscardImage() {
    setPreview(null);
    setLogo(null);
  }

  return (
    <Flex direction="column" justify="space-between" as="form" onSubmit={onSubmitLogo} flex={1}>
      <Box>
        <Heading size="lg" fontWeight={{ base: 'semibold', md: 'bold' }}>
          Logo
        </Heading>

        <Text mt={2} fontSize={{ base: 'sm', md: 'md' }} color="#20212350" fontWeight="medium">
          Inicie a customização da sua plataforma de ensino adicionando sua logo.
        </Text>

        <Flex direction={{ base: 'column', md: 'row' }} mt={5} gap={4}>
          <Flex flex={2}>
            {!preview ? (
              <SelectLogo onDrop={handlePhotoChange} isDisabled={''} />
            ) : (
              <LogoPreview
                preview={preview || themeMarketplace.logo}
                handleDiscardImage={handleDiscardImage}
              />
            )}
          </Flex>

          <Flex flex={3} direction="column" fontSize="xs" color="#20212380">
            <Text>Arquivos suportados: jpg, pgn, jpeg.</Text>
            <Text>Dimensões: 132x64 e tamanho de até 3MB.</Text>

            <Text mt={4} maxWidth={{ base: 'none', md: 80 }}>
              Se você não possuir uma logo, deixe essa opção vazia. Você poderá adicionar a sua logo
              em outro momento.
            </Text>
          </Flex>
        </Flex>
      </Box>

      <Flex justify={{ base: 'space-between', md: 'flex-end' }} gap={4}>
        <BackButton onClick={onGoToPreviousTab} isLoading={loading} />
        <NextButton type="submit" isLoading={loading} />
      </Flex>
    </Flex>
  );
}
