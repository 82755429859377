import React from 'react';
import styles from './styles.module.css';

function InputCheckbox({ id, name, onChange, value, children, ...res }) {
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        value={value}
        {...res}
        className="custom-control-input"
        id={id}
      />
      <label className={`custom-control-label ${styles.checkbox}`} htmlFor={id}>
        {children}
      </label>
    </div>
  );
}

export default InputCheckbox;
