const DDIHelper = [
  {
    country: 'Afghanistan',
    ddi: '93',
    initials: 'AF',
  },
  {
    country: 'Aland Islands',
    ddi: '358',
    initials: 'AX',
  },
  {
    country: 'Albania',
    ddi: '355',
    initials: 'AL',
  },
  {
    country: 'Algeria',
    ddi: '213',
    initials: 'DZ',
  },
  {
    country: 'AmericanSamoa',
    ddi: '1684',
    initials: 'AS',
  },
  {
    country: 'Andorra',
    ddi: '376',
    initials: 'AD',
  },
  {
    country: 'Angola',
    ddi: '244',
    initials: 'AO',
  },
  {
    country: 'Anguilla',
    ddi: '1264',
    initials: 'AI',
  },
  {
    country: 'Antarctica',
    ddi: '672',
    initials: 'AQ',
  },
  {
    country: 'Antigua and Barbuda',
    ddi: '1268',
    initials: 'AG',
  },
  {
    country: 'Argentina',
    ddi: '54',
    initials: 'AR',
  },
  {
    country: 'Armenia',
    ddi: '374',
    initials: 'AM',
  },
  {
    country: 'Aruba',
    ddi: '297',
    initials: 'AW',
  },
  {
    country: 'Ascension Island',
    ddi: '247',
    initials: 'AC',
  },
  {
    country: 'Australia',
    ddi: '61',
    initials: 'AU',
  },
  {
    country: 'Austria',
    ddi: '43',
    initials: 'AT',
  },
  {
    country: 'Azerbaijan',
    ddi: '994',
    initials: 'AZ',
  },
  {
    country: 'Bahamas',
    ddi: '1242',
    initials: 'BS',
  },
  {
    country: 'Bahrain',
    ddi: '973',
    initials: 'BH',
  },
  {
    country: 'Bangladesh',
    ddi: '880',
    initials: 'BD',
  },
  {
    country: 'Barbados',
    ddi: '1246',
    initials: 'BB',
  },
  {
    country: 'Belarus',
    ddi: '375',
    initials: 'BY',
  },
  {
    country: 'Belgium',
    ddi: '32',
    initials: 'BE',
  },
  {
    country: 'Belize',
    ddi: '501',
    initials: 'BZ',
  },
  {
    country: 'Benin',
    ddi: '229',
    initials: 'BJ',
  },
  {
    country: 'Bermuda',
    ddi: '1441',
    initials: 'BM',
  },
  {
    country: 'Bhutan',
    ddi: '975',
    initials: 'BT',
  },
  {
    country: 'Bolivia',
    ddi: '591',
    initials: 'BO',
  },
  {
    country: 'Bosnia and Herzegovina',
    ddi: '387',
    initials: 'BA',
  },
  {
    country: 'Botswana',
    ddi: '267',
    initials: 'BW',
  },
  {
    country: 'Brazil',
    ddi: '55',
    initials: 'BR',
  },
  {
    country: 'British Indian Ocean Territory',
    ddi: '246',
    initials: 'IO',
  },
  {
    country: 'Brunei Darussalam',
    ddi: '673',
    initials: 'BN',
  },
  {
    country: 'Bulgaria',
    ddi: '359',
    initials: 'BG',
  },
  {
    country: 'Burkina Faso',
    ddi: '226',
    initials: 'BF',
  },
  {
    country: 'Burundi',
    ddi: '257',
    initials: 'BI',
  },
  {
    country: 'Cambodia',
    ddi: '855',
    initials: 'KH',
  },
  {
    country: 'Cameroon',
    ddi: '237',
    initials: 'CM',
  },
  {
    country: 'Canada',
    ddi: '1',
    initials: 'CA',
  },
  {
    country: 'Cape Verde',
    ddi: '238',
    initials: 'CV',
  },
  {
    country: 'Cayman Islands',
    ddi: '1345',
    initials: 'KY',
  },
  {
    country: 'Central African Republic',
    ddi: '236',
    initials: 'CF',
  },
  {
    country: 'Chad',
    ddi: '235',
    initials: 'TD',
  },
  {
    country: 'Chile',
    ddi: '56',
    initials: 'CL',
  },
  {
    country: 'China',
    ddi: '86',
    initials: 'CN',
  },
  {
    country: 'Christmas Island',
    ddi: '61',
    initials: 'CX',
  },
  {
    country: 'Cocos (Keeling) Islands',
    ddi: '61',
    initials: 'CC',
  },
  {
    country: 'Colombia',
    ddi: '57',
    initials: 'CO',
  },
  {
    country: 'Comoros',
    ddi: '269',
    initials: 'KM',
  },
  {
    country: 'Congo',
    ddi: '242',
    initials: 'CG',
  },
  {
    country: 'Cook Islands',
    ddi: '682',
    initials: 'CK',
  },
  {
    country: 'Costa Rica',
    ddi: '506',
    initials: 'CR',
  },
  {
    country: 'Croatia',
    ddi: '385',
    initials: 'HR',
  },
  {
    country: 'Cuba',
    ddi: '53',
    initials: 'CU',
  },
  {
    country: 'Cyprus',
    ddi: '357',
    initials: 'CY',
  },
  {
    country: 'Czech Republic',
    ddi: '420',
    initials: 'CZ',
  },
  {
    country: 'Democratic Republic of the Congo',
    ddi: '243',
    initials: 'CD',
  },
  {
    country: 'Denmark',
    ddi: '45',
    initials: 'DK',
  },
  {
    country: 'Djibouti',
    ddi: '253',
    initials: 'DJ',
  },
  {
    country: 'Dominica',
    ddi: '1767',
    initials: 'DM',
  },
  {
    country: 'Dominican Republic',
    ddi: '1849',
    initials: 'DO',
  },
  {
    country: 'Ecuador',
    ddi: '593',
    initials: 'EC',
  },
  {
    country: 'Egypt',
    ddi: '20',
    initials: 'EG',
  },
  {
    country: 'El Salvador',
    ddi: '503',
    initials: 'SV',
  },
  {
    country: 'Equatorial Guinea',
    ddi: '240',
    initials: 'GQ',
  },
  {
    country: 'Eritrea',
    ddi: '291',
    initials: 'ER',
  },
  {
    country: 'Estonia',
    ddi: '372',
    initials: 'EE',
  },
  {
    country: 'Eswatini',
    ddi: '268',
    initials: 'SZ',
  },
  {
    country: 'Ethiopia',
    ddi: '251',
    initials: 'ET',
  },
  {
    country: 'Falkland Islands (Malvinas)',
    ddi: '500',
    initials: 'FK',
  },
  {
    country: 'Faroe Islands',
    ddi: '298',
    initials: 'FO',
  },
  {
    country: 'Fiji',
    ddi: '679',
    initials: 'FJ',
  },
  {
    country: 'Finland',
    ddi: '358',
    initials: 'FI',
  },
  {
    country: 'France',
    ddi: '33',
    initials: 'FR',
  },
  {
    country: 'French Guiana',
    ddi: '594',
    initials: 'GF',
  },
  {
    country: 'French Polynesia',
    ddi: '689',
    initials: 'PF',
  },
  {
    country: 'Gabon',
    ddi: '241',
    initials: 'GA',
  },
  {
    country: 'Gambia',
    ddi: '220',
    initials: 'GM',
  },
  {
    country: 'Georgia',
    ddi: '995',
    initials: 'GE',
  },
  {
    country: 'Germany',
    ddi: '49',
    initials: 'DE',
  },
  {
    country: 'Ghana',
    ddi: '233',
    initials: 'GH',
  },
  {
    country: 'Gibraltar',
    ddi: '350',
    initials: 'GI',
  },
  {
    country: 'Greece',
    ddi: '30',
    initials: 'GR',
  },
  {
    country: 'Greenland',
    ddi: '299',
    initials: 'GL',
  },
  {
    country: 'Grenada',
    ddi: '1473',
    initials: 'GD',
  },
  {
    country: 'Guadeloupe',
    ddi: '590',
    initials: 'GP',
  },
  {
    country: 'Guam',
    ddi: '1671',
    initials: 'GU',
  },
  {
    country: 'Guatemala',
    ddi: '502',
    initials: 'GT',
  },
  {
    country: 'Guernsey',
    ddi: '44',
    initials: 'GG',
  },
  {
    country: 'Guinea',
    ddi: '224',
    initials: 'GN',
  },
  {
    country: 'Guinea-Bissau',
    ddi: '245',
    initials: 'GW',
  },
  {
    country: 'Guyana',
    ddi: '592',
    initials: 'GY',
  },
  {
    country: 'Haiti',
    ddi: '509',
    initials: 'HT',
  },
  {
    country: 'Holy See (Vatican City State)',
    ddi: '379',
    initials: 'VA',
  },
  {
    country: 'Honduras',
    ddi: '504',
    initials: 'HN',
  },
  {
    country: 'Hong Kong',
    ddi: '852',
    initials: 'HK',
  },
  {
    country: 'Hungary',
    ddi: '36',
    initials: 'HU',
  },
  {
    country: 'Iceland',
    ddi: '354',
    initials: 'IS',
  },
  {
    country: 'India',
    ddi: '91',
    initials: 'IN',
  },
  {
    country: 'Indonesia',
    ddi: '62',
    initials: 'ID',
  },
  {
    country: 'Iran',
    ddi: '98',
    initials: 'IR',
  },
  {
    country: 'Iraq',
    ddi: '964',
    initials: 'IQ',
  },
  {
    country: 'Ireland',
    ddi: '353',
    initials: 'IE',
  },
  {
    country: 'Isle of Man',
    ddi: '44',
    initials: 'IM',
  },
  {
    country: 'Israel',
    ddi: '972',
    initials: 'IL',
  },
  {
    country: 'Italy',
    ddi: '39',
    initials: 'IT',
  },
  {
    country: "Ivory Coast / Cote d'Ivoire",
    ddi: '225',
    initials: 'CI',
  },
  {
    country: 'Jamaica',
    ddi: '1876',
    initials: 'JM',
  },
  {
    country: 'Japan',
    ddi: '81',
    initials: 'JP',
  },
  {
    country: 'Jersey',
    ddi: '44',
    initials: 'JE',
  },
  {
    country: 'Jordan',
    ddi: '962',
    initials: 'JO',
  },
  {
    country: 'Kazakhstan',
    ddi: '77',
    initials: 'KZ',
  },
  {
    country: 'Kenya',
    ddi: '254',
    initials: 'KE',
  },
  {
    country: 'Kiribati',
    ddi: '686',
    initials: 'KI',
  },
  {
    country: "Korea, Democratic People's Republic of Korea",
    ddi: '850',
    initials: 'KP',
  },
  {
    country: 'Korea, Republic of South Korea',
    ddi: '82',
    initials: 'KR',
  },
  {
    country: 'Kosovo',
    ddi: '383',
    initials: 'XK',
  },
  {
    country: 'Kuwait',
    ddi: '965',
    initials: 'KW',
  },
  {
    country: 'Kyrgyzstan',
    ddi: '996',
    initials: 'KG',
  },
  {
    country: 'Laos',
    ddi: '856',
    initials: 'LA',
  },
  {
    country: 'Latvia',
    ddi: '371',
    initials: 'LV',
  },
  {
    country: 'Lebanon',
    ddi: '961',
    initials: 'LB',
  },
  {
    country: 'Lesotho',
    ddi: '266',
    initials: 'LS',
  },
  {
    country: 'Liberia',
    ddi: '231',
    initials: 'LR',
  },
  {
    country: 'Libya',
    ddi: '218',
    initials: 'LY',
  },
  {
    country: 'Liechtenstein',
    ddi: '423',
    initials: 'LI',
  },
  {
    country: 'Lithuania',
    ddi: '370',
    initials: 'LT',
  },
  {
    country: 'Luxembourg',
    ddi: '352',
    initials: 'LU',
  },
  {
    country: 'Macau',
    ddi: '853',
    initials: 'MO',
  },
  {
    country: 'Madagascar',
    ddi: '261',
    initials: 'MG',
  },
  {
    country: 'Malawi',
    ddi: '265',
    initials: 'MW',
  },
  {
    country: 'Malaysia',
    ddi: '60',
    initials: 'MY',
  },
  {
    country: 'Maldives',
    ddi: '960',
    initials: 'MV',
  },
  {
    country: 'Mali',
    ddi: '223',
    initials: 'ML',
  },
  {
    country: 'Malta',
    ddi: '356',
    initials: 'MT',
  },
  {
    country: 'Marshall Islands',
    ddi: '692',
    initials: 'MH',
  },
  {
    country: 'Martinique',
    ddi: '596',
    initials: 'MQ',
  },
  {
    country: 'Mauritania',
    ddi: '222',
    initials: 'MR',
  },
  {
    country: 'Mauritius',
    ddi: '230',
    initials: 'MU',
  },
  {
    country: 'Mayotte',
    ddi: '262',
    initials: 'YT',
  },
  {
    country: 'Mexico',
    ddi: '52',
    initials: 'MX',
  },
  {
    country: 'Micronesia, Federated States of Micronesia',
    ddi: '691',
    initials: 'FM',
  },
  {
    country: 'Moldova',
    ddi: '373',
    initials: 'MD',
  },
  {
    country: 'Monaco',
    ddi: '377',
    initials: 'MC',
  },
  {
    country: 'Mongolia',
    ddi: '976',
    initials: 'MN',
  },
  {
    country: 'Montenegro',
    ddi: '382',
    initials: 'ME',
  },
  {
    country: 'Montserrat',
    ddi: '1664',
    initials: 'MS',
  },
  {
    country: 'Morocco',
    ddi: '212',
    initials: 'MA',
  },
  {
    country: 'Mozambique',
    ddi: '258',
    initials: 'MZ',
  },
  {
    country: 'Myanmar',
    ddi: '95',
    initials: 'MM',
  },
  {
    country: 'Namibia',
    ddi: '264',
    initials: 'NA',
  },
  {
    country: 'Nauru',
    ddi: '674',
    initials: 'NR',
  },
  {
    country: 'Nepal',
    ddi: '977',
    initials: 'NP',
  },
  {
    country: 'Netherlands',
    ddi: '31',
    initials: 'NL',
  },
  {
    country: 'Netherlands Antilles',
    ddi: '599',
    initials: 'AN',
  },
  {
    country: 'New Caledonia',
    ddi: '687',
    initials: 'NC',
  },
  {
    country: 'New Zealand',
    ddi: '64',
    initials: 'NZ',
  },
  {
    country: 'Nicaragua',
    ddi: '505',
    initials: 'NI',
  },
  {
    country: 'Niger',
    ddi: '227',
    initials: 'NE',
  },
  {
    country: 'Nigeria',
    ddi: '234',
    initials: 'NG',
  },
  {
    country: 'Niue',
    ddi: '683',
    initials: 'NU',
  },
  {
    country: 'Norfolk Island',
    ddi: '672',
    initials: 'NF',
  },
  {
    country: 'North Macedonia',
    ddi: '389',
    initials: 'MK',
  },
  {
    country: 'Northern Mariana Islands',
    ddi: '1670',
    initials: 'MP',
  },
  {
    country: 'Norway',
    ddi: '47',
    initials: 'NO',
  },
  {
    country: 'Oman',
    ddi: '968',
    initials: 'OM',
  },
  {
    country: 'Pakistan',
    ddi: '92',
    initials: 'PK',
  },
  {
    country: 'Palau',
    ddi: '680',
    initials: 'PW',
  },
  {
    country: 'Palestine',
    ddi: '970',
    initials: 'PS',
  },
  {
    country: 'Panama',
    ddi: '507',
    initials: 'PA',
  },
  {
    country: 'Papua New Guinea',
    ddi: '675',
    initials: 'PG',
  },
  {
    country: 'Paraguay',
    ddi: '595',
    initials: 'PY',
  },
  {
    country: 'Peru',
    ddi: '51',
    initials: 'PE',
  },
  {
    country: 'Philippines',
    ddi: '63',
    initials: 'PH',
  },
  {
    country: 'Pitcairn',
    ddi: '872',
    initials: 'PN',
  },
  {
    country: 'Poland',
    ddi: '48',
    initials: 'PL',
  },
  {
    country: 'Portugal',
    ddi: '351',
    initials: 'PT',
  },
  {
    country: 'Puerto Rico',
    ddi: '1939',
    initials: 'PR',
  },
  {
    country: 'Qatar',
    ddi: '974',
    initials: 'QA',
  },
  {
    country: 'Reunion',
    ddi: '262',
    initials: 'RE',
  },
  {
    country: 'Romania',
    ddi: '40',
    initials: 'RO',
  },
  {
    country: 'Russia',
    ddi: '7',
    initials: 'RU',
  },
  {
    country: 'Rwanda',
    ddi: '250',
    initials: 'RW',
  },
  {
    country: 'Saint Barthelemy',
    ddi: '590',
    initials: 'BL',
  },
  {
    country: 'Saint Helena, Ascension and Tristan Da Cunha',
    ddi: '290',
    initials: 'SH',
  },
  {
    country: 'Saint Kitts and Nevis',
    ddi: '1869',
    initials: 'KN',
  },
  {
    country: 'Saint Lucia',
    ddi: '1758',
    initials: 'LC',
  },
  {
    country: 'Saint Martin',
    ddi: '590',
    initials: 'MF',
  },
  {
    country: 'Saint Pierre and Miquelon',
    ddi: '508',
    initials: 'PM',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    ddi: '1784',
    initials: 'VC',
  },
  {
    country: 'Samoa',
    ddi: '685',
    initials: 'WS',
  },
  {
    country: 'San Marino',
    ddi: '378',
    initials: 'SM',
  },
  {
    country: 'Sao Tome and Principe',
    ddi: '239',
    initials: 'ST',
  },
  {
    country: 'Saudi Arabia',
    ddi: '966',
    initials: 'SA',
  },
  {
    country: 'Senegal',
    ddi: '221',
    initials: 'SN',
  },
  {
    country: 'Serbia',
    ddi: '381',
    initials: 'RS',
  },
  {
    country: 'Seychelles',
    ddi: '248',
    initials: 'SC',
  },
  {
    country: 'Sierra Leone',
    ddi: '232',
    initials: 'SL',
  },
  {
    country: 'Singapore',
    ddi: '65',
    initials: 'SG',
  },
  {
    country: 'Sint Maarten',
    ddi: '1721',
    initials: 'SX',
  },
  {
    country: 'Slovakia',
    ddi: '421',
    initials: 'SK',
  },
  {
    country: 'Slovenia',
    ddi: '386',
    initials: 'SI',
  },
  {
    country: 'Solomon Islands',
    ddi: '677',
    initials: 'SB',
  },
  {
    country: 'Somalia',
    ddi: '252',
    initials: 'SO',
  },
  {
    country: 'South Africa',
    ddi: '27',
    initials: 'ZA',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    ddi: '500',
    initials: 'GS',
  },
  {
    country: 'South Sudan',
    ddi: '211',
    initials: 'SS',
  },
  {
    country: 'Spain',
    ddi: '34',
    initials: 'ES',
  },
  {
    country: 'Sri Lanka',
    ddi: '94',
    initials: 'LK',
  },
  {
    country: 'Sudan',
    ddi: '249',
    initials: 'SD',
  },
  {
    country: 'Suricountry',
    ddi: '597',
    initials: 'SR',
  },
  {
    country: 'Svalbard and Jan Mayen',
    ddi: '47',
    initials: 'SJ',
  },
  {
    country: 'Sweden',
    ddi: '46',
    initials: 'SE',
  },
  {
    country: 'Switzerland',
    ddi: '41',
    initials: 'CH',
  },
  {
    country: 'Syrian Arab Republic',
    ddi: '963',
    initials: 'SY',
  },
  {
    country: 'Taiwan',
    ddi: '886',
    initials: 'TW',
  },
  {
    country: 'Tajikistan',
    ddi: '992',
    initials: 'TJ',
  },
  {
    country: 'Tanzania, United Republic of Tanzania',
    ddi: '255',
    initials: 'TZ',
  },
  {
    country: 'Thailand',
    ddi: '66',
    initials: 'TH',
  },
  {
    country: 'Timor-Leste',
    ddi: '670',
    initials: 'TL',
  },
  {
    country: 'Togo',
    ddi: '228',
    initials: 'TG',
  },
  {
    country: 'Tokelau',
    ddi: '690',
    initials: 'TK',
  },
  {
    country: 'Tonga',
    ddi: '676',
    initials: 'TO',
  },
  {
    country: 'Trinidad and Tobago',
    ddi: '1868',
    initials: 'TT',
  },
  {
    country: 'Tunisia',
    ddi: '216',
    initials: 'TN',
  },
  {
    country: 'Turkey',
    ddi: '90',
    initials: 'TR',
  },
  {
    country: 'Turkmenistan',
    ddi: '993',
    initials: 'TM',
  },
  {
    country: 'Turks and Caicos Islands',
    ddi: '1649',
    initials: 'TC',
  },
  {
    country: 'Tuvalu',
    ddi: '688',
    initials: 'TV',
  },
  {
    country: 'Uganda',
    ddi: '256',
    initials: 'UG',
  },
  {
    country: 'Ukraine',
    ddi: '380',
    initials: 'UA',
  },
  {
    country: 'United Arab Emirates',
    ddi: '971',
    initials: 'AE',
  },
  {
    country: 'United Kingdom',
    ddi: '44',
    initials: 'GB',
  },
  {
    country: 'United States',
    ddi: '1',
    initials: 'US',
  },
  {
    country: 'Uruguay',
    ddi: '598',
    initials: 'UY',
  },
  {
    country: 'Uzbekistan',
    ddi: '998',
    initials: 'UZ',
  },
  {
    country: 'Vanuatu',
    ddi: '678',
    initials: 'VU',
  },
  {
    country: 'Venezuela, Bolivarian Republic of Venezuela',
    ddi: '58',
    initials: 'VE',
  },
  {
    country: 'Vietnam',
    ddi: '84',
    initials: 'VN',
  },
  {
    country: 'Virgin Islands, British',
    ddi: '1284',
    initials: 'VG',
  },
  {
    country: 'Virgin Islands, U.S.',
    ddi: '1340',
    initials: 'VI',
  },
  {
    country: 'Wallis and Futuna',
    ddi: '681',
    initials: 'WF',
  },
  {
    country: 'Yemen',
    ddi: '967',
    initials: 'YE',
  },
  {
    country: 'Zambia',
    ddi: '260',
    initials: 'ZM',
  },
  {
    country: 'Zimbabwe',
    ddi: '263',
    initials: 'ZW',
  },
];

export default DDIHelper;
