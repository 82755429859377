import {
  Box,
  Heading,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import convertToBrazilianCurrency from '../../../../../helpers/convertToBrazilianCurrency';
import { X_LARGE_SCREEN_SIZE } from '../../constants';
import { bgByTransaction, convertPaymentStatus, convertPaymentMethod } from '../../utils';

export default function PaymentHistoryTable({ transactions, isLoading, width }) {
  return (
    <>
      {isLoading && (
        <Stack w="100%" direction="row" justifyContent="center">
          <Spinner size="md" color="orange.500" />
        </Stack>
      )}

      {transactions?.length === 0 && !isLoading && (
        <Box
          marginY="4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Heading as="h2" fontSize="md">
            Ainda não há informações disponíveis.
          </Heading>
        </Box>
      )}

      <TableContainer hidden={isLoading || !transactions?.length}>
        <Table
          size="sm"
          variant="striped"
          style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
        >
          <Thead>
            <Tr>
              <Th>
                <Text display="flex" w={width <= X_LARGE_SCREEN_SIZE ? 61 : 'auto'} isTruncated>
                  ID
                </Text>
              </Th>
              <Th>
                <Text display="flex" w={width <= X_LARGE_SCREEN_SIZE ? 100 : 200} isTruncated>
                  Status
                </Text>
              </Th>
              <Th>
                <Text display="flex" w={width <= X_LARGE_SCREEN_SIZE ? 61 : 'auto'} isTruncated>
                  Nome
                </Text>
              </Th>
              <Th>
                <Text display="flex" w={width <= X_LARGE_SCREEN_SIZE ? 61 : 'auto'} isTruncated>
                  Valor
                </Text>
              </Th>
              <Th>
                <Text display="flex" w={width <= X_LARGE_SCREEN_SIZE ? 61 : 'auto'} isTruncated>
                  Pagamento
                </Text>
              </Th>
              <Th>
                <Text display="flex" isTruncated>
                  Data de Criação
                </Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions?.length > 0 &&
              !isLoading &&
              transactions?.map(transaction => (
                <Tr fontWeight={400} key={transaction.transactionId}>
                  <Td fontWeight={600}>
                    <Box display="flex">{transaction.transactionId}</Box>
                  </Td>
                  <Td>
                    <Box
                      display="flex"
                      justifyContent="center"
                      p={1}
                      borderRadius={6}
                      fontWeight={600}
                      color="whiteAlpha.900"
                      w={width <= X_LARGE_SCREEN_SIZE ? 100 : 200}
                      bg={bgByTransaction(transaction.status)}
                    >
                      <Text isTruncated>{convertPaymentStatus(transaction.status)}</Text>
                    </Box>
                  </Td>
                  <Td>
                    <Box display="flex">{transaction.name}</Box>
                  </Td>
                  <Td>
                    <Box display="flex">{convertToBrazilianCurrency(transaction.amount)}</Box>
                  </Td>
                  <Td>
                    <Box display="flex">{convertPaymentMethod(transaction.paymentMethod)}</Box>
                  </Td>
                  <Td>
                    <Box display="flex">{dayjs(transaction.createdAt).format('DD/MM/YYYY')}</Box>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
