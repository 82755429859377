import {
  Box,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import process from 'process/browser';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import Carousel from 'react-slick';
import { SCRIPTS_PROLUNO } from '../../ExternalScripts';
import plansApi from '../../api/Plan';
import PlatformAPI from '../../api/Platform';
import logoProluno from '../../assets/images/logos/logo-proluno.svg';
import livia from '../../assets/images/profile/livia.png';
import marcio from '../../assets/images/profile/marcio.png';
import soares from '../../assets/images/profile/soares.jpg';
import AccordionMarketplace from '../../components/AccordionMarketplace';
import Alert from '../../components/Alerts/Alerts';
import Spinner from '../../components/Loadings/Spinner';
import Toast from '../../components/Toast';
import DDIHelperBR from '../../helpers/DDIHelperBR';
import ErrorResponse from '../../helpers/ErrorResponse';
import { IS_COOKIES_ACCEPTED } from '../../helpers/LocalStorageHelper';
import convertToBrazilianCurrency from '../../helpers/convertToBrazilianCurrency';
import onlyNumber from '../../helpers/onlyNumber';
import useQuery from '../../hooks/useQuery';
import CardInput from './CardInput';
import CardsBrand from './CardsBrand';
import InputPassword from './InputPassword';
import SecurityInformation from './SecurityInformation';
import Testimonials from './Testimonials';
import { settingsTestimonials } from './Testimonials/slickSettings';
import styles from './styles.module.css';

const initialStateForm = {
  fullName: '',
  email: '',
  password: '',
  confirmationPassword: '',
  plan: '',
  cardNumber: '',
  cardCVV: '',
  cardExpirationDate: '',
  ddi: '',
  ddd: '',
  phone: '',
};

const plansLinks = {
  beginner: {
    path: '/beginner-plan',
  },
  professional: {
    path: '/professional-plan',
  },
  premium: {
    path: '/premium-plan',
  },
};

const initialStatePlan = {
  id: null,
  name: '',
  value: '',
  recurrence: '',
  description: '',
  customerPercentage: '',
  marketplacePercentage: null,
  marketplaceIntegration: null,
  trial: null,
  homologatedBy: '',
  homologation: null,
  statusName: '',
};

const head = document.getElementsByTagName('head').item(0);

function CustomInstructorSignUp() {
  const [form, setForm] = useState(initialStateForm);
  const [emptyField, setEmptyField] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [planData, setPlanData] = useState(initialStatePlan);
  const [errorMessage, setErrorMessage] = useState(null);

  const [flag, setFlag] = useState('');
  const [country, setCountry] = useState('br');
  const [ddi, setDDI] = useState('55');
  const [codeError, setCodeError] = useState(null);

  const query = useQuery();
  const history = useHistory();
  const selectedPlan = query.get('plan');

  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const googleTagManagerLink = document.createElement('script');

    googleTagManagerLink.id = 'googleTagManagerLink';

    googleTagManagerLink.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${SCRIPTS_PROLUNO.GOOGLE_TAG}');
    `;

    if (!document.getElementById('googleTagManagerLink')) {
      head.appendChild(googleTagManagerLink);
    }

    const googleTagManager = document.createElement('script');

    googleTagManager.id = 'googleTagManager';

    googleTagManager.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${SCRIPTS_PROLUNO.GOOGLE_TAG}', {
      page_path: window.location.pathname,
    });
    `;

    if (!document.getElementById('googleTagManager')) {
      head.appendChild(googleTagManager);
    }

    if (document.getElementById('metaPixelAssinatura')) return;

    const metaPixel = document.createElement('script');
    metaPixel.id = 'metaPixelAssinatura';

    const metaPixelScript = `
      !function(f,b,e,v,n,t,s)

      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      
      n.queue=[];t=b.createElement(e);t.async=!0;
      
      t.src=v;s=b.getElementsByTagName(e)[0];
      
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      
      'https://connect.facebook.net/en_US/fbevents.js');
      
      fbq('init', '524432899269170');
      
      fbq('track', 'Lead');
      
      fbq('trackCustom', 'typ-mensal-iniciante')
      `;

    metaPixel.innerHTML = metaPixelScript;

    head.appendChild(metaPixel);

    return () => {
      const metaPixel = document.getElementById('metaPixelAssinatura');

      if (metaPixel) {
        head.removeChild(metaPixel);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getFlag(initial) {
      try {
        const endPoint = `https://flagcdn.com/w40`;

        const data = await axios.get(`${endPoint}/${initial.toLowerCase()}.png`);

        setFlag(data?.config?.url);
      } catch (error) {
        setFlag('');
      }
    }

    getFlag(country);
  }, [country]);

  useEffect(() => {
    (async () => {
      try {
        const response = await plansApi.showByIdentifier(selectedPlan);

        setPlanData(response);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    })();
  }, [selectedPlan]);

  function handleChange(event) {
    setErrorMessage('');

    const { name, value } = event.target;

    if (name === 'country') {
      const currentCountry = DDIHelperBR.find(country => country.initials.toLowerCase() === value);

      if (currentCountry) {
        setCountry(value);

        setDDI(currentCountry?.ddi);
        setCodeError(null);
      } else {
        setDDI('');
        setCountry('');
      }
    }

    if (name === 'ddi') {
      const currentCountry = DDIHelperBR.find(country => country.ddi.toLowerCase() === value);

      setDDI(currentCountry.ddi);

      if (currentCountry) {
        setCountry(currentCountry?.initials.toLowerCase());
        setCodeError(null);
      } else {
        setCountry('');
        setCodeError('Digite um código válido');
      }
    }

    let formattedValue = value;

    const names = ['cardNumber', 'cardCVV', 'cardExpirationDate'];

    if (names.includes(name)) {
      formattedValue = formattedValue.replace(/\D/g, '');
    }

    const formData = { ...form, [name]: formattedValue };

    if (
      formData?.cardNumber?.length >= 14 &&
      formData?.cardExpirationDate?.length === 4 &&
      formData?.cardCVV?.length >= 3
    ) {
      const emptyField = Object.keys(form).some(
        key => key !== 'plan' && key !== 'ddi' && key !== 'ddd' && !formData[key]
      );

      setEmptyField(emptyField);
    } else {
      setEmptyField(true);
    }

    setForm(formData);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setErrorMessage('');
    setIsLoading(true);

    try {
      if (form.password !== form.confirmationPassword) {
        throw new Error('As senhas não conferem.');
      }

      delete form.confirmationPassword;

      if (ddi !== '55') {
        delete form.ddd;
      }

      const formData = {
        ...form,
        country: country,
        ddi,
        plan: selectedPlan,
      };

      setIsLoading(true);

      const { data: userData } = await PlatformAPI.createSubscriptionPlatform(formData);

      if (userData) {
        history.push(
          `instructor-loading?value=${Number(planData.value)}&platform=${userData.platform}&token=${
            userData.token
          }`
        );
      }
    } catch (error) {
      setIsLoading(false);
      form.confirmationPassword = form.password;
      Toast(ErrorResponse(error), 'error');
    }
  }

  function planLink(selectedPlan) {
    switch (selectedPlan) {
      case 'Iniciante':
        return (
          <Link to={plansLinks.beginner.path} target="_blank">
            Termo de Assinatura
          </Link>
        );
      case 'Profissional':
        return (
          <Link to={plansLinks.professional.path} target="_blank">
            Termo de Assinatura
          </Link>
        );
      case 'Premium':
        return (
          <Link to={plansLinks.premium.path} target="_blank">
            Termo de Assinatura
          </Link>
        );
      default:
        return (
          <Link to="#" style={{ cursor: 'not-allowed' }}>
            Termo de Assinatura
          </Link>
        );
    }
  }

  return (
    <div className={styles.container}>
      {process?.env.NODE_ENV !== 'development' && (
        <Helmet>
          {/* RD Station Code */}
          <script
            type="text/javascript"
            async
            src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/9af8db5a-a9aa-4540-8513-74dbfe7a9532-loader.js"
          ></script>
          {/* End RD Station Code */}
        </Helmet>
      )}

      <header>
        <img src={logoProluno} alt="" />
        <div className={styles.greenSquare}></div>
        <div className={styles.blueSquare}></div>
      </header>

      <section className={styles.checkout}>
        <div className={styles.orangeSquare}></div>

        <div className={styles.planInformationSmall}>
          <h2>{planData?.name ? `Plano ${planData?.name}` : ''}</h2>
          <p>Assuma o controle total sobre seu conteúdo, criando e vendendo produtos on-line.</p>
        </div>

        <Text fontSize={32} fontWeight={600} textAlign="center" color="#EB7129" mt={10}>
          {convertToBrazilianCurrency(planData?.value)}/{planData?.recurrence}
        </Text>

        <Container maxWidth="container.md">
          <div className={styles.formContainer}>
            <div>
              <form onSubmit={handleSubmit}>
                <div className="text-center">
                  <h3>
                    Comece agora a <span>ensinar do seu jeito</span>
                  </h3>
                </div>

                <Stack>
                  <FormControl>
                    <FormLabel htmlFor="name">Nome</FormLabel>
                    <Input
                      type="text"
                      name="fullName"
                      id="name"
                      value={form.fullName}
                      onChange={handleChange}
                      isRequired
                      isDisabled={isLoading}
                      size="sm"
                      borderRadius={6}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="name">E-mail</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={form.email}
                      onChange={handleChange}
                      isRequired
                      isDisabled={isLoading}
                      size="sm"
                      borderRadius={6}
                    />
                  </FormControl>

                  <Stack
                    spacing={5}
                    direction={{ base: 'column', md: 'row', lg: 'row' }}
                    w="100%"
                    alignItems="center"
                  >
                    <Stack spacing={2} direction="column">
                      <Text fontWeight={600} my={-1}>
                        País
                      </Text>

                      {flag ? (
                        <Box display="flex" justifyContent="flex-start">
                          <Image
                            position="absolute"
                            src={flag}
                            mt={5}
                            ml={3}
                            w="24px"
                            h="18px"
                            display="flex"
                            justifyContent="flex-end"
                          />
                        </Box>
                      ) : (
                        <Box display="flex" justifyContent="flex-start">
                          <Box
                            position="absolute"
                            mt={5}
                            ml={3}
                            display="flex"
                            justifyContent="flex-start"
                            bgColor="#D9D9D9"
                            w="24px"
                            h="18px"
                          />
                        </Box>
                      )}

                      <Select
                        name="country"
                        placeholder={codeError ? 'Código inválido' : 'Selecione uma opção'}
                        focusBorderColor="#EB7129"
                        value={country}
                        onChange={handleChange}
                        isRequired
                        style={{ padding: '0 45px' }}
                        borderRadius={0}
                        borderColor="#20212350"
                      >
                        {DDIHelperBR.map(ddi => (
                          <option key={ddi.initials} value={ddi.initials.toLowerCase()}>
                            {ddi.country}
                          </option>
                        ))}
                      </Select>
                    </Stack>

                    <Stack spacing={2} direction="row" w="100%">
                      <Stack spacing={2} direction="column" width="full">
                        <Text fontWeight={600}>Telefone</Text>
                        <HStack width="full">
                          <Input
                            type="tel"
                            hidden={country !== 'br'}
                            name="ddd"
                            onChange={handleChange}
                            value={form.ddd}
                            placeholder="Ex: 85"
                            focusBorderColor="#EB7129"
                            isInvalid={codeError}
                            isRequired={country === 'br'}
                            borderRadius={0}
                            borderColor="#20212350"
                            width="100px"
                            maxLength={2}
                            onInput={onlyNumber}
                          />

                          <Input
                            id="phone"
                            name="phone"
                            placeholder="----- ----"
                            onChange={handleChange}
                            value={form.phone}
                            isRequired={true}
                            maxLength={20}
                            onInput={onlyNumber}
                          />
                        </HStack>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack direction="row" my={1}>
                    <Box w={{ base: '0', md: '80%', lg: '75%' }}></Box>
                    {codeError && (
                      <Text fontSize="xs" w="100%" color="#BB2124">
                        {codeError}
                      </Text>
                    )}
                  </Stack>

                  <InputPassword form={form} onChange={handleChange} isLoading={isLoading} />

                  <CardsBrand />

                  <CardInput form={form} onChange={handleChange} isLoading={isLoading} />

                  <SecurityInformation />

                  <div className={styles.unsubscribeMessage}>
                    <p>
                      Você pode cancelar sua assinatura a qualquer momento para evitar cobranças
                      futuras; seu cancelamento entrará em vigor no final do período de cobrança
                      atual. Para cancelar, envie um e-mail para support@proluno.com.br. Ao clicar
                      em 'Comece Grátis', você concorda com os{' '}
                      <Link to="/term-of-use" target="_blank">
                        termos de serviço
                      </Link>
                      , com a{' '}
                      <Link to="/privacy-policy" target="_blank">
                        política de privacidade
                      </Link>
                      da Proluno e com o do {planLink(selectedPlan)} plano que escolher.
                    </p>
                  </div>

                  <button
                    className={emptyField ? styles.btnDisabled : 'btn-block'}
                    disabled={emptyField || isLoading}
                  >
                    {!isLoading ? 'Iniciar minha assinatura' : <Spinner />}
                  </button>
                </Stack>
              </form>
              {errorMessage ? (
                <Alert class_name="danger mb-2 text-center" text={errorMessage} />
              ) : (
                ''
              )}
            </div>

            {/* <Link to="/plans">Conheça todos os planos</Link> */}
          </div>
        </Container>
      </section>

      {/** TESTIMONIALS */}
      <section className={styles.testimonialsSection} style={{ background: '' }}>
        <div className={styles.heading}>
          <h2>Garantia de devolução do dinheiro em 30 dias</h2>
          <p>
            Nós garantimos nosso software. Se você não estiver 100% satisfeito com sua compra, nós o
            reembolsaremos sem perguntas. Estamos aqui para ajudá-lo 24 horas por dia, 7 dias por
            semana, com suporte por e-mail e chat ao vivo.
          </p>
        </div>

        <div className={styles.cardCarousel}>
          <Carousel {...settingsTestimonials}>
            <Testimonials
              name="Prof. Soares"
              paragraph="Sempre ministrei aulas presenciais e não sabia como vender meus produtos no online. A Proluno me permitiu ter alunos de diversas cidades do Brasil e alcançar a escala que eu buscava."
              avatar={soares}
              job="Concurso público"
            />
            <Testimonials
              name="Lívia"
              paragraph="Eu sei o quanto é importante fortalecer o nome no mundo digital. E com a Proluno consegui o que há muito tempo procurava: criar o meu próprio site, cadastrar e vender meus produtos de uma forma muito fácil e rápida 🤩"
              avatar={livia}
              job="Digital influencer"
            />
            <Testimonials
              name="Márcio"
              paragraph="Gerenciar tantas ferramentas para dar aulas on-line estava tomando todo o meu tempo. Ter tudo em um só lugar permite que eu foque em produzir conteúdo e ter um negócio online de sucesso."
              avatar={marcio}
              job="Especialista em concursos Públicos"
            />
          </Carousel>
        </div>
      </section>

      <section className={styles.faqSection}>
        <h4>Perguntas frequentes</h4>
        <div className={styles.faqList}>
          <AccordionMarketplace
            title="O que são alunos ativos e alunos cadastrados?"
            content={
              <>
                <p>
                  Alunos <b style={{ fontWeight: 600 }}>Ativos</b> são alunos que possuem inscrição
                  válida para ao <b style={{ fontWeight: 600 }}>menos um curso</b> na plataforma.
                  Isto é, quando um aluno é matriculado em um curso, por meio de pagamento ou
                  atribuição, e seu acesso é liberado, este aluno é marcado como ativo. O valor do{' '}
                  <b style={{ fontWeight: 600 }}>ativo</b> é o mesmo, independentemente da
                  quantidade de cursos que ele possui. Ex: Um aluno com dez cursos é contabilizado
                  uma única vez.{' '}
                  <b style={{ fontWeight: 600 }}>
                    Você pode aumentar o número de alunos ativos fazendo o upgrade do seu plano.
                  </b>{' '}
                  .
                </p>

                <p>
                  Alunos <b style={{ fontWeight: 600 }}>Cadastrados</b> são alunos que se
                  inscreveram em seu site e não realizaram nenhuma compra ou que adquiriram um
                  produto gratuito. Ex: Um aluno que baixou um E-Book gratuito usado em uma ação
                  para atrair futuros clientes.
                </p>
              </>
            }
          />
          <AccordionMarketplace
            title="O que ocorre se eu atingir o limite de produtos?"
            content={
              <>
                Ao atingir o número máximo de produtos ativos, você não conseguirá realizar o
                cadastro de novos produtos em seu site. Nessa situação, você pode inativar produtos
                que não estejam mais disponíveis à venda e acesso dos alunos (ao inativar um
                produto, os alunos matriculados deixam de ter acesso a ele){' '}
                <b style={{ fontWeight: 600 }}>
                  ou pode aumentar o número de produtos ativos, fazendo o upgrade do seu plano.
                </b>
              </>
            }
          />
          <AccordionMarketplace
            title="O que ocorre se eu atingir o limite de armazenamento?"
            content={
              <>
                Ao atingir o seu limite de armazenamento de vídeos, você não conseguirá realizar o
                upload (carregamento de novos vídeos no site). Nessa situação, você pode excluir
                vídeos da sua biblioteca que não estejam mais sendo utilizados nos seus produtos (os
                vídeos excluídos da biblioteca serão apagados dos produtos em que estiverem
                cadastrados)
                <b style={{ fontWeight: 600 }}>
                  ou aumentar a sua capacidade de armazenamento, fazendo o upgrade do seu plano.{' '}
                </b>
              </>
            }
          />
          <AccordionMarketplace
            title="Como eu posso mudar o plano contratado?"
            content={
              <p>
                Você pode realizar o upgrade (aumento) ou downgrade (diminuição) do seu plano a
                qualquer momento. Para alterá-lo, basta enviar um e-mail para{' '}
                <span>support@proluno.com.br</span>
              </p>
            }
          />
          <AccordionMarketplace
            title="Eu sou empresa, qual plano é indicado para mim?"
            content="Para empresas temos o Plano Empresarial. Crie a sua empresa online, com todas as ferramentas e soluções que você precisa para proporcionar a melhor experiência para os seus alunos. Fale com um especialista e encontre a solução personalizada para você."
          />
          <AccordionMarketplace
            title="O que é o Marketplace Proluno?"
            content={
              <>
                Nossa meta é fazer você vender mais. Então, criamos um portal de vendas online para
                que os seus produtos sejam vistos por mais gente e você aumente o seu faturamento.
                Utilizamos diversas ferramentas e inteligência artificial para que os alunos
                encontrem mais facilmente o que procuram, além de estarmos constantemente melhorando
                nosso posicionamento nos sites de busca.{' '}
                <a
                  href="https://checkout.proluno.me/"
                  role="button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Conheça o Marketplace Proluno
                </a>
                .
              </>
            }
          />
          <AccordionMarketplace
            title="Como é o suporte da Proluno?"
            content={
              <>
                <p>
                  Fornecemos suporte para todos os clientes independente do plano. Você poderá ser
                  atendido por áreas como suporte técnico, financeiro, comercial e consultores.
                  Basta enviar um e-mail para <span>support@proluno.com.br</span>. Além disso, você
                  terá acesso a vídeos que ensinam o passo a passo de como usar e gerenciar a
                  plataforma.
                </p>
                <p>
                  Se você for{' '}
                  <b style={{ fontWeight: 600 }}>
                    assinante do Plano Premium terá um suporte prioritário
                  </b>
                  , com preferência na ordem de prioridade das chamadas e um contato de whatsapp
                  exclusivo para que não tenha que aguardar em espera enquanto nós ajudamos outros
                  clientes.
                </p>
              </>
            }
          />
          <AccordionMarketplace
            title="O que ocorre se eu quiser cancelar meu plano?"
            content={
              <>
                Não se preocupe que não existem complicações. Você pode cancelar sua assinatura a
                qualquer momento para evitar cobranças futuras e o seu cancelamento entrará em vigor
                no final do período de cobrança atual. Nos planos anuais, você ficará com acesso até
                o período final de cobrança e não será realizada a renovação automática. Para
                cancelar, basta enviar um e-mail para <span>support@proluno.com.br</span>.
              </>
            }
          />
          <AccordionMarketplace
            title="Como funcionam as taxas por transação?"
            content="Quando você vende os seus produtos, utiliza o sistema de pagamento integrado da Proluno e é cobrada uma taxa por transação, a depender do seu plano contratado. Você pode consultar todas as taxas de transação e processamento. "
          />
        </div>
      </section>

      <footer>
        <h4>Políticas, Termos de Serviço e Divulgação de Renda</h4>
      </footer>
    </div>
  );
}

export default CustomInstructorSignUp;
