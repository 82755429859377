import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import ContentAPI from '../api/Content';
import ErrorResponse from '../helpers/ErrorResponse';
import Toast from '../components/Toast';
import { useParams } from 'react-router-dom';
import { useStudentCourse } from '../contexts/CourseStudentContext';

type MarkAsDoneType = {
  contentId: string;
  moduleId: string;
  markAsDone: boolean;
};

type ParamsType = {
  id: string;
};

type ProgressType = { progress: number; countContent: number };

type ContentType = {
  markAsDone: boolean;
};

interface IModule {
  contents: ContentType[];
}
interface IAuthContextData {
  amountContentViewed: number;
  markAsDoneHandleChange: (markAsDone: MarkAsDoneType) => void;
  progress: ProgressType;
}

const BASE_FORMULA = 100;

const ProgressContext = createContext<IAuthContextData>({
  amountContentViewed: 0,
  markAsDoneHandleChange: () => '',
  progress: { progress: 0, countContent: 0 },
});

export default function ProgressContextProvider({ children }) {
  const [progress, setProgress] = useState<ProgressType>({ progress: 0, countContent: 0 });
  const [amountContentViewed, setAmountContentViewed] = useState(0);

  const { id: courseId } = useParams<ParamsType>();
  const { currentContent, onUpdateCurrentContent, studentCourse, getStudentCourse } =
    useStudentCourse();

  useEffect(() => {
    if (studentCourse && studentCourse?.modules?.length) {
      const modulesWithContentViewed = studentCourse.modules.map((module: IModule) =>
        module.contents?.filter((content: ContentType) => content?.markAsDone)
      );

      if (modulesWithContentViewed.length) {
        const totalContentViewed = modulesWithContentViewed?.map((content: any) => content?.length);

        if (totalContentViewed.length) {
          const amountContentViewed = totalContentViewed.reduce(
            (total: number, value: number) => (total += value)
          );

          setAmountContentViewed(amountContentViewed);
        }
      }
    }
  }, [studentCourse]);

  useEffect(() => {
    async function onProgressSubmit() {
      const { data: progressData } = await ContentAPI.progress.show(courseId);

      if (progressData) {
        setProgress({
          countContent: progressData.countContent,
          progress: parseFloat(progressData.progress?.toFixed(2) ?? 0),
        });
      }
    }

    onProgressSubmit();
  }, [courseId]);

  const markAsDoneSubmit = useCallback(
    async (newMarkAsDone: MarkAsDoneType) => {
      await ContentAPI.progress.update(courseId, newMarkAsDone.contentId, newMarkAsDone.markAsDone);
    },

    [courseId]
  );

  async function markAsDoneHandleChange(newMarkAsDone: MarkAsDoneType) {
    if (!studentCourse) return;

    if (studentCourse && !studentCourse?.modules?.length) return;

    const modulesWithContents = studentCourse.modules.map(module => module.content?.length);

    if (!modulesWithContents.length) return;

    try {
      onUpdateCurrentContent(newMarkAsDone.moduleId, newMarkAsDone.contentId, {
        ...currentContent,
        markAsDone: newMarkAsDone.markAsDone,
      });

      await markAsDoneSubmit(newMarkAsDone);

      const amountContentViewed = studentCourse.modules
        .map(module => module.contents.length)
        .reduce((total, value) => (total += value), 0);

      let newProgress = progress.progress;

      const increment = BASE_FORMULA / (amountContentViewed ? amountContentViewed : 1);

      newProgress = newMarkAsDone.markAsDone ? newProgress + increment : newProgress - increment;

      newProgress = newProgress < 0 ? 0 : newProgress;

      newProgress = newProgress > BASE_FORMULA ? BASE_FORMULA : newProgress;

      if (progress.progress === 100 && newProgress < 100) {
        getStudentCourse(courseId);
      } else if (newProgress === 100) {
        getStudentCourse(courseId);
      }

      setProgress({ ...progress, progress: Number(newProgress.toFixed(2)) });
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  return (
    <ProgressContext.Provider
      value={{
        markAsDoneHandleChange,
        progress,
        amountContentViewed,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
}

export function useProgressContext() {
  const context = useContext(ProgressContext);

  if (!context)
    throw new Error('useProgressContext deve ser usada dentro de um ProgressContext.Provider.');

  return {
    amountContentViewed: context.amountContentViewed,
    markAsDoneHandleChange: context.markAsDoneHandleChange,
    progress: context.progress,
  };
}
