import { axiosBackend } from './Configuration';

export default {
  index: async (data = { page: 1, search: '', category: '' }) => {
    try {
      const { data: dataCourse } = await axiosBackend().get(
        `/marketplace/courses?page=${data.page}&per_page=12${
          data.search && `&search=${data.search}`
        }${data.category && `&category=${data.category}`}`
      );

      return dataCourse;
    } catch (error) {
      throw error;
    }
  },
  show: async id => {
    try {
      const dataCourse = await axiosBackend().get(`/marketplace/course/${id}`);
      if (dataCourse instanceof Error) {
        throw dataCourse;
      }

      if (dataCourse && dataCourse.data) {
        return dataCourse.data;
      }
    } catch (error) {
      throw error;
    }
  },

  indexAreas: async () => {
    try {
      const { data: areas } = await axiosBackend().get('/marketplace/areas');

      return areas;
    } catch (error) {
      throw error;
    }
  },

  indexDisciplinas: async () => {
    try {
      const { data: subjects } = await axiosBackend().get('/marketplace/subjects');

      return subjects;
    } catch (error) {
      throw error;
    }
  },

  users: async role => {
    try {
      const { data: dataInstructors } = await axiosBackend().get(`/marketplace/users?role=${role}`);

      return dataInstructors;
    } catch (error) {
      throw error;
    }
  },
  themes: async () => {
    try {
      const { data: dataTheme } = await axiosBackend().get('/marketplace/themes');

      return dataTheme;
    } catch (error) {
      throw error;
    }
  },

  blog: {
    index: async () => {
      try {
        const { data: postsData } = await axiosBackend().get('/marketplace/posts');

        return postsData;
      } catch (error) {
        throw error;
      }
    },

    show: async postId => {
      try {
        const { data: postData } = await axiosBackend().get(`/marketplace/posts/${postId}`);

        if (!postData) {
          throw new Error('Post não encontrado');
        }

        return postData;
      } catch (error) {
        throw error;
      }
    },
  },
};
