import { useToast } from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import process from 'process/browser';
import { useCallback, useEffect, useState } from 'react';
import ErrorResponse from '../helpers/ErrorResponse';
import { TOKEN_LOCAL_STORAGE } from '../helpers/LocalStorageHelper';

const api = axios.create({
  baseURL: process?.env.REACT_APP_BASE_URL_API,
  timeout: 60 * 1000, // 1 minuto
});

type UseFetchPropsTypeType<T> = {
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  url: string;
  data?: T;
  authenticated?: boolean;
  token?: string;
  autoFetch?: boolean;
  responseType?: 'json' | 'blob' | 'arraybuffer' | 'document' | 'text' | 'stream';
};

export default function useFetch<T, E = unknown>(props: UseFetchPropsTypeType<T>) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(props.autoFetch);
  const [error, setError] = useState<AxiosError<E> | null>(null);
  const [isMounted, setIsMounted] = useState(false);

  const toast = useToast();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

      const response = await api({
        method: props.method,
        url: props.url,
        data: props.data,
        headers: props.authenticated
          ? {
              Authorization: `Bearer ${token ? token : props.token}`,
            }
          : {},
        responseType: props.responseType || 'json',
      });

      setData(response.data);
    } catch (error) {
      setError(error as AxiosError<E>);

      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } finally {
      setLoading(false);
    }
  }, [
    props.method,
    props.url,
    props.data,
    props.authenticated,
    props.token,
    props.responseType,
    toast,
  ]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && props.autoFetch) {
      fetchData();
    }
  }, [fetchData, isMounted, props.autoFetch]);

  return { data, loading, error, fetchData };
}
