export const PAYMENT_METHOD_OPTIONS = [
  { label: 'Cartão de crédito', value: 'credit_card' },
  { label: 'Boleto', value: 'boleto' },
  { label: 'Pix', value: 'pix' },
];

export const BOLETO_DAYS_OPTIONS = [
  { label: 'domingo', value: '1' },
  { label: 'segunda-feira', value: '2' },
  { label: 'terça-feira', value: '3' },
  { label: 'quarta-feira', value: '4' },
  { label: 'quinta-feira', value: '5' },
  { label: 'sexta-feira', value: '6' },
  { label: 'sábado', value: '7' },
];

export const USER_FORM_OPTIONS = [
  { label: 'Endereço', value: 'END' },
  { label: 'Telefone', value: 'TEL' },
  { label: 'Instagram', value: 'INS' },
];
