import 'react-date-range/dist/styles.css';
import './styles.css';

import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ptBR } from 'date-fns/locale';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import CalendarIcon from './CalendarIcon';
import useDateRangePickerCustomStyles from './useDateRangePickerCustomStyles';

type GetRangeTextsType = {
  selection: {
    startDate: Date;
    endDate: Date;
  };
};

function formatDate(date: Date | string) {
  return dayjs(date).format('YYYY-MM-DD');
}

function getRangeText(ranges: GetRangeTextsType) {
  const startDate = dayjs(ranges.selection.startDate)?.format('DD/MM/YYYY');
  const endDate = dayjs(ranges.selection.endDate)?.format('DD/MM/YYYY');

  if (startDate === endDate) {
    return startDate;
  }

  return `${startDate} - ${endDate}`;
}

interface DateRangePickerProps {
  startDate?: string;
  endDate?: string;
  hideIcon?: boolean;
  hideSelect?: boolean;
  selectPlaceholder?: string;
  iconColor?: string;
  onChange: (startDate: string, endDate: string) => void;
}

function getInitialState(startDate: string, endDate: string) {
  return [
    {
      startDate: startDate && dayjs(startDate).toDate(),
      endDate: endDate && dayjs(endDate).toDate(),
      key: 'selection',
    },
  ];
}

export default function DateRangePickerMobile({
  startDate,
  endDate,
  hideIcon = false,
  onChange,
}: DateRangePickerProps) {
  const [state, setState] = useState(getInitialState(startDate, endDate));
  const { isOpen, onOpen, onClose } = useDisclosure();

  useDateRangePickerCustomStyles();
  const textColor = useColorModeValue('#202123', '#FFFFFF');

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (startDate && endDate) {
        getRangeText({
          selection: { startDate: dayjs(startDate).toDate(), endDate: dayjs(endDate).toDate() },
        });
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [startDate, endDate]);

  useEffect(() => {
    setState([
      {
        startDate: startDate && dayjs(startDate).toDate(),
        endDate: endDate && dayjs(endDate).toDate(),
        key: 'selection',
      },
    ]);
  }, [startDate, endDate]);

  function handleDateRangePickerChange(ranges) {
    setState([ranges.selection]);
    getRangeText(ranges);
    onChange(formatDate(ranges.selection.startDate), formatDate(ranges.selection.endDate));
  }

  return (
    <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {!hideIcon && (
        <MenuButton m={0} as={IconButton} colorScheme="gray" variant="ghost" borderRadius="full">
          <CalendarIcon color={textColor} />
        </MenuButton>
      )}

      <MenuList boxShadow="0px 4px 4px 0px #00000040" p={0} overflow="hidden" position="relative">
        <DateRangePicker
          onChange={handleDateRangePickerChange}
          ranges={state}
          showDateDisplay={false}
          showMonthArrow={true}
          direction="horizontal"
          locale={ptBR}
          weekdayDisplayFormat="EEEEE"
          monthDisplayFormat="MMMM yyyy"
          rangeColors={['#ec7117']}
          color="#ec7117"
        />
      </MenuList>
    </Menu>
  );
}
