import { CloseButton, HStack, Text } from '@chakra-ui/react';

interface CouponBannerAppliedProps {
  code?: string;
  onClose: () => void;
}

export default function CouponBannerApplied({ code, onClose }: CouponBannerAppliedProps) {
  return (
    <HStack
      justify="space-between"
      px={4}
      py={6}
      borderColor="default.500"
      borderWidth="1px"
      borderRadius="md"
      mt={7}
    >
      <Text>
        Cupom aplicado:{' '}
        <Text as="strong" color="default.500" textTransform="uppercase">
          {code}
        </Text>
      </Text>

      <CloseButton
        name="coupon"
        value=""
        size="sm"
        position="relative"
        right={-2}
        color="#202123"
        onClick={onClose}
        style={{ outline: 'none', boxShadow: 'none' }}
      />
    </HStack>
  );
}
