import {
  Flex,
  Heading,
  Image,
  Progress,
  ProgressProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
} from '@chakra-ui/react';

import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Platform from '../../../api/Platform';
import backgroundFormIntegration from '../../../assets/images/backgrounds/background-integration-form.svg';
import digitaBankImage from '../../../assets/images/digital-bank.png';
import firstStepsImage from '../../../assets/images/first-steps.png';
import customizeDomainImage from '../../../assets/images/illustrations/customize-domain.png';
import logoExampleImage from '../../../assets/images/logo-create.png';
import logoImage from '../../../assets/images/logos/proluno_cor.png';
import selectColorsImage from '../../../assets/images/select-colors.png';
import workImage from '../../../assets/images/work.png';
import TabColors from './TabColors';
import TabDomain from './TabDomain';
import TabFirstSteps from './TabFirstSteps';
import TabLogo from './TabLogo';
import TabUsers from './TabUsers';

const TABS = [0, 1, 2, 3, 4];

function getProgress(value: number, tabIndex: number) {
  if (value === tabIndex) {
    return 0.5;
  }

  if (tabIndex > value) {
    return 1;
  }
}

export function IntegrationForm() {
  const [tabIndex, setTabIndex] = useState(0);
  const history = useHistory();

  function goToNextTab() {
    setTabIndex(prevTabIndex => prevTabIndex < TABS.length && prevTabIndex + 1);
  }

  function goToPreviousTab() {
    setTabIndex(prevTabIndex => prevTabIndex > 0 && prevTabIndex - 1);
  }

  function handleTabsChange(index: number) {
    setTabIndex(index);
  }

  useEffect(() => {
    async function firstAccess() {
      const { data: getFirstAccess } = await Platform.getFirstAccess();

      if (getFirstAccess.firstAccess) {
        return history.push('/courses');
      }
    }

    firstAccess();
  }, [history]);

  const selectedTabStyle = {
    backgroundColor: '#EB7129',
    border: '1px solid #EB7129',
    color: '#FFFFFF',
    outline: 'none',
  };

  const tabProps: TabProps = {
    _selected: { ...selectedTabStyle },
    borderRadius: 'full',
    border: '1px solid #20212350',
    _focus: { boxShadow: 'none' },
    boxSize: '1.75rem',
    color: '#20212350',
    fontWeight: 'medium',
    paddingX: '0.75rem',
  };

  const progressProps: ProgressProps = {
    w: '100%',
    h: '1px',
    min: 0,
    max: 1,
    colorScheme: 'orange',
    size: 'sm',
    bg: '#20212350',
  };

  const tabPanelsList = [
    <TabDomain onGoToNextTab={goToNextTab} />,
    <TabLogo onGoToNextTab={goToNextTab} onGoToPreviousTab={goToPreviousTab} />,
    <TabColors onGoToNextTab={goToNextTab} onGoToPreviousTab={goToPreviousTab} />,
    <TabUsers onGoToNextTab={goToNextTab} onGoToPreviousTab={goToPreviousTab} />,
    <TabFirstSteps goToPreviousTab={goToPreviousTab} />,
  ];

  return (
    <Flex width="full" height="100vh">
      <Flex flex={6} direction="column" pb={{ base: 10, md: 20 }} width="full">
        <Flex
          as="header"
          justify={{ base: 'center', md: 'center', lg: 'flex-start' }}
          w="100%"
          px={16}
          pt={6}
          pb={{ base: 5, md: 10 }}
        >
          <Image src={logoImage} w={125} />
        </Flex>

        <Flex flex={1} px={{ base: 4, md: 28 }}>
          <Tabs
            variant="solid-rounded"
            colorScheme="orange"
            index={tabIndex}
            onChange={handleTabsChange}
            display="flex"
            flex={1}
            flexDirection="column"
          >
            <TabList alignItems="center">
              {TABS.map(value => (
                <Fragment key={value}>
                  <Tab {...tabProps} sx={tabIndex >= value && selectedTabStyle}>
                    {value + 1}
                  </Tab>

                  <Progress
                    {...progressProps}
                    value={getProgress(value, tabIndex)}
                    sx={{ ':last-child': { display: 'none' } }}
                  />
                </Fragment>
              ))}
            </TabList>

            <TabPanels display="flex" flex={1} mt={{ base: 8, md: 10 }} p={0}>
              {tabPanelsList.map((tabPanel, index) => (
                <TabPanel key={index} display="flex" flex={1} flexDirection="column" p={0}>
                  {tabPanel}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>

      <Flex
        display={{ base: 'none', xl: 'flex' }}
        flex={4}
        direction="column"
        align="center"
        justify="center"
        bgImage={backgroundFormIntegration}
        bgRepeat="no-repeat"
        bgSize="cover"
      >
        {tabIndex === 0 && (
          <>
            <Image src={customizeDomainImage} />
            <Heading size="md" color="#FFFFFF" w={400} textAlign="center" fontWeight="medium">
              Crie o domínio que deseja que seu site possua.
            </Heading>
          </>
        )}

        {tabIndex === 1 && (
          <>
            <Heading
              size="md"
              color="#FFFFFF"
              maxWidth="32ch"
              textAlign="center"
              fontWeight="medium"
            >
              Vamos começar a deixar a sua plataforma de ensino do seu jeito
            </Heading>

            <Image src={logoExampleImage} mt={16} />
          </>
        )}

        {tabIndex === 2 && (
          <>
            <Heading
              size="md"
              color="#FFFFFF"
              maxWidth={400}
              textAlign="center"
              fontWeight="medium"
            >
              Que tal selecionar as suas cores e deixar a plataforma de ensino do seu jeito?
            </Heading>

            <Image src={selectColorsImage} />
          </>
        )}

        {tabIndex === 3 && (
          <>
            <Image src={workImage} px={10} />

            <Heading
              size="md"
              color="#FFFFFF"
              w={400}
              textAlign="center"
              fontWeight="medium"
              my={10}
            >
              Trabalhe em equipe: Sozinho você vai mais rápido, mas com a sua equipe você vai mais
              longe
            </Heading>
          </>
        )}

        {tabIndex === 4 && (
          <>
            <Image src={digitaBankImage} />
            <Heading size="md" color="#FFFFFF" w={400} textAlign="center" fontWeight="medium">
              Você está quase lá e com o cadastro dos dados bancários já será possível vender os
              seus produtos.
            </Heading>
          </>
        )}

        {tabIndex === 5 && (
          <>
            <Image src={firstStepsImage} />
            <Heading
              mt={12}
              size="md"
              color="#FFFFFF"
              w={400}
              textAlign="center"
              fontWeight="medium"
            >
              Decida por onde você irá dar seus primeiros passos aqui na Proluno.
            </Heading>
          </>
        )}
      </Flex>
    </Flex>
  );
}
