import process from 'process/browser';
import {
  Box,
  Button,
  Switch as ChakraSwitch,
  Flex,
  HStack,
  Stack,
  Text,
  Tooltip,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { ColorPickerChange, ICertificate, ISwitchProps, ParamsType } from './types';

import ColorPicker from '../../../../../../components/ColorPickChakra';
import CourseAPI from '../../../../../../api/Course';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { Heading } from '../../../../../../components/Heading';
import { MdInfoOutline as InfoOutlineIcon } from 'react-icons/md';
import Input from '../../../../../../components/Input';
import Select from '../../../../../../components/Select';
import SelectFonts from './SelectFonts';
import TemplateImage from './TemplateImage';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import { Link, useParams } from 'react-router-dom';
import UploadHelper from '../../../../../../helpers/UploadHelper';
import Platform from '../../../../../../api/Platform';

const initialState: ICertificate = {
  certificateImage: '',
  certificateImageBack: '',
  certificateType: '',
  courseNameColor: '#202123',
  courseNameFont: 'Montserrat',
  emissionCondition: '',
  institutionName: '',
  institutionNameColor: '#202123',
  institutionNameFont: 'Montserrat',
  instructorName: '',
  instructorNameColor: '#202123',
  instructorNameFont: 'Montserrat',
  mainTextColor: '#202123',
  mainTextFont: 'Montserrat',
  studentNameColor: '#202123',
  studentNameFont: 'Montserrat',
  workload: '',
  workloadNameColor: '#202123',
  workloadNameFont: 'Montserrat',
  emissionDateColor: '#202123',
  emissionDateFont: 'Montserrat',
  enabled: false,
};

const previewDefault = process?.env.REACT_APP_BASE_URL_API?.concat(
  '/',
  'certificates/',
  'certificate_template.png'
);

const previewBackDefault = process?.env.REACT_APP_BASE_URL_API?.concat(
  '/',
  'certificates/',
  'certificate_template_back.png'
);

function Certificate() {
  const { id: courseId } = useParams<ParamsType>();

  const { course } = useCourse();

  const [checked, setChecked] = useState<boolean>(false);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [previewCertificate, setPreviewCertificate] = useState(previewDefault);

  const [previewCertificateBack, setPreviewCertificateBack] = useState(previewBackDefault);

  const [certificate, setCertificate] = useState<ICertificate>(initialState);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingData(true);
        const { data: certificateData } = await CourseAPI.certificate.show(courseId);

        if (certificateData) {
          setChecked(certificateData.enabled);
          setCertificate(certificateData);
          setPreviewCertificate(certificateData.certificateImage);
          setPreviewCertificateBack(certificateData.certificateImageBack);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoadingData(false);
      }
    })();
  }, [courseId]);

  function handleChangeSwitch(event: ISwitchProps) {
    const { checked } = event.target;

    setChecked(checked);
    setCertificate({ ...certificate, enabled: checked });
  }

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    const values = {
      ...certificate,
      [name]: value,
    };

    setCertificate(values);
  }

  function handleChangeColor(props: ColorPickerChange) {
    const { name, value } = props;

    setCertificate({ ...certificate, [name]: value });
  }

  function handleDrop(acceptedFiles: any[]) {
    const file = acceptedFiles[0];

    if (file) {
      setPreviewCertificate(URL.createObjectURL(file));

      setCertificate({ ...certificate, certificateImage: file });
    }
  }

  function handleDropCertificateBack(acceptedFiles: any[]) {
    const file = acceptedFiles[0];

    if (file) {
      setPreviewCertificateBack(URL.createObjectURL(file));

      setCertificate({ ...certificate, certificateImageBack: file });
    }
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement | HTMLDivElement>) {
    event.preventDefault();

    let certificateImageKey = '';
    let certificateImageBackKey = '';

    try {
      setIsLoading(true);

      if (courseId) {
        if (typeof certificate.certificateImage === 'string' || !certificate.certificateImage) {
          delete certificate.certificateImage;
        }

        if (
          typeof certificate.certificateImageBack === 'string' ||
          !certificate.certificateImageBack
        ) {
          delete certificate.certificateImageBack;
        }

        if (certificate.certificateImage && typeof certificate.certificateImage !== 'string') {
          const { newFileName } = await UploadHelper.upload(
            certificate.certificateImage,
            'certificates'
          );

          certificate.certificateImage = newFileName;

          certificateImageKey = `certificates/${newFileName}`;
        }

        if (
          certificate.certificateImageBack &&
          typeof certificate.certificateImageBack !== 'string'
        ) {
          const { newFileName } = await UploadHelper.upload(
            certificate.certificateImageBack,
            'certificates'
          );

          certificate.certificateImageBack = newFileName;

          certificateImageBackKey = `certificates/${newFileName}`;
        }

        await CourseAPI.certificate.patch(courseId, certificate);

        return Toast('Certificado configurado!');
      }
    } catch (error) {
      if (certificateImageKey) {
        await Platform.deleteFileByKey(certificateImageKey);
      }
      if (certificateImageBackKey) {
        await Platform.deleteFileByKey(certificateImageBackKey);
      }
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoadingData && (
        <Stack width="100%" direction="row" justifyContent="center">
          <Spinner color="orange.500" />
        </Stack>
      )}

      <Stack width="100%" hidden={isLoadingData}>
        <Heading fontSize="2xl" fontWeight="500" marginBottom="2">
          Certificado
        </Heading>

        <Box
          as="form"
          onSubmit={handleSubmit}
          paddingY="7"
          paddingX="6"
          marginBottom="2rem !important"
          border="1px solid rgba(32, 33, 35, 0.5)"
          borderRadius="0.625rem"
          boxShadow="0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)"
        >
          <VStack width="full" alignItems="flex-start">
            <Tooltip
              label="Seu produto vai possuir certificado: Recomendamos que você ative o certificado apenas quando o curso estiver concluído. Pois o aluno poderá solicitar o certificado assim que comprir a condição para emissão."
              hasArrow
              placement="right"
            >
              <HStack>
                <Text color="dark.500" fontWeight="500" fontSize="lg" lineHeight="0">
                  Seu produto vai possuir um certificado?
                </Text>

                <InfoOutlineIcon width="3" height="3" color="#202123" opacity="0.5" />
              </HStack>
            </Tooltip>

            <Box marginTop="2.5">
              <ChakraSwitch
                size="sm"
                id="isChecked"
                colorScheme="primary"
                isChecked={checked}
                onChange={handleChangeSwitch}
              />
            </Box>
          </VStack>

          {checked && (
            <Stack direction="column" spacing={2}>
              <Heading fontSize="md" fontWeight="400" my={2}>
                Selecione o Formato:
              </Heading>
              <Select
                name="certificateType"
                placeholder="Selecione o formato do seu certificado"
                value={certificate.certificateType}
                onChange={handleChange}
                isRequired
              >
                <option value="Front">Frente (Padrão)</option>
                <option value="FrontAndBack">Frente e Verso</option>
              </Select>
            </Stack>
          )}

          {checked && certificate.certificateType !== '' && (
            <>
              <VStack width="full" marginTop="5" spacing="5" alignItems="flex-start">
                <Text color="dark.500" fontWeight="500" fontSize="lg">
                  Configure seu certificado:
                </Text>

                <Stack width="full">
                  <Text fontSize="md" fontWeight="medium" color="dark.500">
                    Condição para emissão
                  </Text>
                  <Select
                    name="emissionCondition"
                    placeholder="Selecione a condição"
                    description="Defina a condição para que o certificado fique disponível para o aluno."
                    value={certificate?.emissionCondition}
                    onChange={handleChange}
                    isRequired
                  >
                    <option value="25">25% do curso concluído</option>
                    <option value="50">50% do curso concluído</option>
                    <option value="75">75% do curso concluído</option>
                    <option value="100">100% do curso concluído</option>
                  </Select>
                </Stack>

                <Stack width="full" hidden={certificate.certificateType === 'FrontAndBack'}>
                  <Text fontSize="md" fontWeight="medium" color="dark.500">
                    Ministrado por
                  </Text>
                  <Input
                    name="instructorName"
                    placeholder="Digite aqui"
                    description="Nome do instrutor que ministrou o produto."
                    value={certificate.instructorName}
                    onChange={handleChange}
                    isRequired={certificate.certificateType === 'Front'}
                  />
                </Stack>

                <Stack width="full" hidden={certificate.certificateType === 'FrontAndBack'}>
                  <Text fontSize="md" fontWeight="medium" color="dark.500">
                    Carga horária
                  </Text>
                  <Input
                    name="workload"
                    placeholder="Digite aqui"
                    description="Informe a carga horária do produto. (Ex: 12h, 96d)"
                    value={certificate.workload}
                    onChange={handleChange}
                    isRequired={certificate.certificateType === 'Front'}
                  />
                </Stack>

                <Stack width="full" hidden={certificate.certificateType === 'FrontAndBack'}>
                  <Text fontSize="md" fontWeight="medium" color="dark.500">
                    Inserir instituição de ensino
                  </Text>

                  <Input
                    name="institutionName"
                    placeholder="Digite aqui"
                    value={certificate.institutionName}
                    onChange={handleChange}
                    isRequired={certificate.certificateType === 'Front'}
                  />
                </Stack>
              </VStack>

              {certificate.certificateType === 'Front' && (
                <TemplateImage
                  name="Imagem"
                  preview={previewCertificate}
                  onDrop={handleDrop}
                  to={`/certificate-preview?certificateImage=${previewCertificate}&mainTextColor=${
                    certificate?.mainTextColor?.split('#')[1]
                  }&mainTextFont=${certificate?.mainTextFont}&instructorName=${
                    certificate?.instructorName
                  }&instructorNameColor=${
                    certificate?.instructorNameColor?.split('#')[1]
                  }&instructorNameFont=${certificate?.instructorNameFont}&workload=${
                    certificate?.workload
                  }&studentNameColor=${
                    certificate?.studentNameColor?.split('#')[1]
                  }&studentNameFont=${certificate?.studentNameFont}&courseName=${
                    course?.name
                  }&courseNameColor=${certificate?.courseNameColor?.split('#')[1]}&courseNameFont=${
                    certificate?.courseNameFont
                  }&institutionName=${certificate?.institutionName}&institutionNameColor=${
                    certificate?.institutionNameColor?.split('#')[1]
                  }&institutionNameFont=${certificate?.institutionNameFont}&emissionDateColor=${
                    certificate?.emissionDateColor?.split('#')[1]
                  }&emissionDateFont=${certificate?.emissionDateFont}`}
                />
              )}

              {certificate.certificateType === 'FrontAndBack' && (
                <>
                  <TemplateImage
                    name="Imagem frente"
                    preview={previewCertificate}
                    onDrop={handleDrop}
                    to={`/certificate-preview?certificateImage=${previewCertificate}`}
                  />

                  <TemplateImage
                    name="Verso"
                    preview={previewCertificateBack}
                    onDrop={handleDropCertificateBack}
                    to={`/certificate-preview?certificateImage=${previewCertificateBack}`}
                  />
                </>
              )}

              <VStack width="full" alignItems="flex-start" marginBottom="8">
                <Heading marginBottom="0" fontSize="lg" fontWeight="500">
                  Textos
                </Heading>

                <Text
                  marginTop="0rem !important"
                  marginBottom="0.5rem !important"
                  fontSize="sm"
                  fontWeight="normal"
                  lineHeight="5"
                  color="rgba(32, 33, 35, 0.5)"
                >
                  Cores e fontes dos textos.
                </Text>

                <Box width="full" display="flex" flexDirection="column" gap="5">
                  <Stack width="full" hidden={certificate.certificateType === 'FrontAndBack'}>
                    <Text fontSize="md" fontWeight="medium" color="dark.500">
                      Texto principal
                    </Text>

                    <Box marginTop="0.25rem !important">
                      <Text
                        marginBottom="0.25rem"
                        fontSize="sm"
                        fontWeight="normal"
                        lineHeight="5"
                        color="rgba(32, 33, 35, 0.5)"
                      >
                        Texto padrão usado no certificado.
                      </Text>
                      <Flex gap="4">
                        <ColorPicker
                          name="mainTextColor"
                          defaultColor={'#202123'}
                          value={certificate.mainTextColor}
                          onChange={handleChangeColor}
                          hasInput
                        />

                        <SelectFonts
                          name="mainTextFont"
                          value={certificate.mainTextFont}
                          onChange={handleChange}
                        />
                      </Flex>
                    </Box>
                  </Stack>

                  <Stack width="full">
                    <Text fontSize="md" fontWeight="medium" color="dark.500">
                      Nome do aluno
                    </Text>

                    <Flex gap="4">
                      <ColorPicker
                        name="studentNameColor"
                        defaultColor={'#202123'}
                        value={certificate.studentNameColor}
                        onChange={handleChangeColor}
                        hasInput
                      />

                      <SelectFonts
                        name="studentNameFont"
                        value={certificate.studentNameFont}
                        onChange={handleChange}
                      />
                    </Flex>
                  </Stack>

                  <Stack width="full" hidden={certificate.certificateType === 'Front'}>
                    <Text fontSize="md" fontWeight="medium" color="dark.500">
                      Data de Emissão
                    </Text>

                    <Flex gap="4">
                      <ColorPicker
                        name="emissionDateColor"
                        defaultColor={'#202123'}
                        value={certificate.emissionDateColor}
                        onChange={handleChangeColor}
                        hasInput
                      />

                      <SelectFonts
                        name="emissionDateFont"
                        value={certificate.emissionDateFont}
                        onChange={handleChange}
                      />
                    </Flex>
                  </Stack>

                  <Stack width="full" hidden={certificate.certificateType === 'FrontAndBack'}>
                    <Text fontSize="md" fontWeight="medium" color="dark.500">
                      Nome do curso
                    </Text>

                    <Flex gap="4">
                      <ColorPicker
                        name="courseNameColor"
                        defaultColor={'#202123'}
                        value={certificate.courseNameColor}
                        onChange={handleChangeColor}
                        hasInput
                      />

                      <SelectFonts
                        name="courseNameFont"
                        value={certificate.courseNameFont}
                        onChange={handleChange}
                      />
                    </Flex>
                  </Stack>

                  <Stack width="full" hidden={certificate.certificateType === 'FrontAndBack'}>
                    <Text fontSize="md" fontWeight="medium" color="dark.500">
                      Nome do instrutor
                    </Text>

                    <Flex gap="4">
                      <ColorPicker
                        name="instructorNameColor"
                        defaultColor={'#202123'}
                        value={certificate.instructorNameColor}
                        onChange={handleChangeColor}
                        hasInput
                      />

                      <SelectFonts
                        name="instructorNameFont"
                        value={certificate.instructorNameFont}
                        onChange={handleChange}
                      />
                    </Flex>
                  </Stack>

                  <Stack width="full" hidden={certificate.certificateType === 'FrontAndBack'}>
                    <Text fontSize="md" fontWeight="medium" color="dark.500">
                      Nome da instituição
                    </Text>

                    <Flex gap="4">
                      <ColorPicker
                        name="institutionNameColor"
                        defaultColor={'#202123'}
                        value={certificate.institutionNameColor}
                        onChange={handleChangeColor}
                        hasInput
                      />

                      <SelectFonts
                        name="institutionNameFont"
                        value={certificate.institutionNameFont}
                        onChange={handleChange}
                      />
                    </Flex>
                  </Stack>
                </Box>
              </VStack>
            </>
          )}

          <HStack marginTop="10" spacing="4" width="full" justifyContent="flex-end">
            <Button
              size="sm"
              borderRadius="md"
              border="1px solid rgba(32, 33, 35, 0.25)"
              isDisabled={isLoading}
              as={Link}
              to="/courses/3401/course-manager/course"
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              size="sm"
              borderRadius="md"
              background="#eb7129"
              color="white"
              _hover={{
                background: '#eb712980',
              }}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Salvar
            </Button>
          </HStack>
        </Box>
      </Stack>
    </>
  );
}

export default Certificate;
