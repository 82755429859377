import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  FiCheck as CheckIcon,
  FiChevronLeft as ChevronLeftIcon,
  FiChevronRight as ChevronRightIcon,
} from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { useCourseStudent } from '../../../../contexts/CourseStudentContextV2';
import { useStudentProgress } from '../../../../contexts/ProgressContextV2';
import getRouteToShow from '../../../../helpers/getRouteToShow';
import { IParams } from '../../pages/ContentsView/types';
import MarkBlockingModal from './MarkBlockingModal';

interface IProps {
  canMarkAsCompleted?: boolean;
}

export function PagingContents({ canMarkAsCompleted = true }: IProps) {
  const { courseId, moduleId, contentId } = useParams<IParams>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();

  const { course, currentContent, highlightColor } = useCourseStudent();
  const { markAsDoneHandleChange } = useStudentProgress();

  const [previousContent, setPreviousContent] = useState(null);
  const [nextContent, setNextContent] = useState(null);

  useEffect(() => {
    if (course?.modules) {
      const currentModule = course?.modules?.find(module => module.id === parseInt(moduleId));

      const indexCurrentContent = currentModule?.contents?.findIndex(
        content => content.id === parseInt(contentId)
      );

      const previousContent = currentModule?.contents[indexCurrentContent - 1];

      setPreviousContent(previousContent);

      const nextContent = currentModule?.contents[indexCurrentContent + 1];
      setNextContent(nextContent);
    }
  }, [contentId, moduleId, courseId, course?.modules]);

  function changeContent(contentId: number, contentType: string) {
    const baseURL = `/members-area/v2/course/${courseId}/module/${moduleId}/content/${contentId}/content-view`;
    const path = getRouteToShow(contentType);

    history.push(`${baseURL}/${path}`);
  }

  function handleMarkAsComplete() {
    markAsDoneHandleChange({
      contentId,
      moduleId,
      markAsDone: !currentContent?.markAsDone,
    });
  }

  return (
    <HStack width="full" justifyContent="space-between">
      <Button
        leftIcon={<CheckIcon />}
        background="transparent"
        color={currentContent?.markAsDone ? highlightColor : '#FFF'}
        _hover={{ color: highlightColor }}
        _active={{ color: 'none' }}
        sx={{ '&:hover svg': { color: highlightColor } }}
        px="0"
        onClick={!canMarkAsCompleted ? onOpen : handleMarkAsComplete}
        flexShrink="0"
        fontSize={{ base: '11.051px', lg: '16px' }}
      >
        {currentContent?.markAsDone ? `Marcado` : `Marcar`} como concluído
      </Button>

      <HStack spacing={{ base: '13px', lg: '20px' }}>
        <Button
          leftIcon={<ChevronLeftIcon />}
          background="transparent"
          color="#FFF"
          _hover={{ color: highlightColor }}
          _active={{ color: 'none' }}
          sx={{ '&:hover svg': { color: highlightColor } }}
          px={0}
          iconSpacing="1px"
          size="sm"
          onClick={() => changeContent(previousContent?.id, previousContent?.contentType)}
          isDisabled={!previousContent}
          flexShrink="0"
          fontSize={{ base: '11.051px', lg: '16px' }}
        >
          Anterior
        </Button>

        <Button
          rightIcon={<ChevronRightIcon />}
          background="transparent"
          color="#FFF"
          _hover={{ color: highlightColor }}
          _active={{ color: 'none' }}
          sx={{ '&:hover svg': { color: highlightColor } }}
          px={0}
          iconSpacing="1px"
          size="sm"
          onClick={() => changeContent(nextContent?.id, nextContent?.contentType)}
          isDisabled={!nextContent}
          flexShrink="0"
          fontWeight="600"
          fontSize={{ base: '11.051px', lg: '16px' }}
        >
          Próximo
        </Button>
      </HStack>

      <MarkBlockingModal isOpen={isOpen} onClose={onClose} />
    </HStack>
  );
}
