import {
  HStack,
  AccordionItem,
  Stack,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Accordion,
  Text,
} from '@chakra-ui/react';
import { QuestionStatistic } from '../..';
import QuestionStatistics from './QuestionStatistics';

export default function QuestionFooter({
  questionStatistic,
}: {
  questionStatistic: QuestionStatistic;
}) {
  const { correct, wrong, performance } = questionStatistic;

  const hasStatistics = correct || wrong;

  return (
    <HStack
      as="footer"
      w="full"
      align="center"
      justify={{ base: 'space-between', md: 'start' }}
      sx={{ strong: { fontWeight: 'semibold' } }}
    >
      <Accordion allowToggle w="full" border="1px solid transparent">
        <AccordionItem w="full">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            align={{ base: 'start', md: 'center' }}
            justify="space-between"
            w="full"
          >
            <Stack
              direction={{ base: 'row' }}
              align={{ base: 'start', md: 'center' }}
              justify={{ base: 'space-between', md: 'start' }}
              w="full"
              spacing={6}
            >
              <Stack direction={{ base: 'column', md: 'row' }} spacing={1}>
                <Text>Acertos:</Text>
                <Text as="strong">{correct}</Text>
              </Stack>

              <Stack direction={{ base: 'column', md: 'row' }} spacing={1}>
                <Text>Erros:</Text>
                <Text as="strong">{wrong}</Text>
              </Stack>

              <Stack direction={{ base: 'column', md: 'row' }} spacing={1}>
                <Text>Aproveitamento:</Text>
                <Text as="strong">{performance || 0}%</Text>
              </Stack>
            </Stack>

            {hasStatistics && (
              <AccordionButton
                color="#20212380"
                maxWidth="11rem"
                p={0}
                m={0}
                justifyContent={{ base: 'start', md: 'end' }}
                _hover={{ bg: 'transparent' }}
              >
                <Text as="span" fontWeight="semibold" fontSize="sm">
                  Mais estatísticas
                </Text>

                <AccordionIcon />
              </AccordionButton>
            )}
          </Stack>

          <AccordionPanel px={0} py="1.125rem">
            <QuestionStatistics questionStatistic={questionStatistic} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </HStack>
  );
}
