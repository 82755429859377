const DDIHelperBR = [
  {
    country: 'Afeganistão',
    ddi: '93',
    initials: 'AF',
  },
  {
    country: 'Ilhas Aland',
    ddi: '358',
    initials: 'AX',
  },
  {
    country: 'Albânia',
    ddi: '355',
    initials: 'AL',
  },
  {
    country: 'Argélia',
    ddi: '213',
    initials: 'DZ',
  },
  {
    country: 'AmericanSamoa',
    ddi: '1684',
    initials: 'AS',
  },
  {
    country: 'Andorra',
    ddi: '376',
    initials: 'AD',
  },
  {
    country: 'Angola',
    ddi: '244',
    initials: 'AO',
  },
  {
    country: 'Anguilla',
    ddi: '1264',
    initials: 'AI',
  },
  {
    country: 'Antártica',
    ddi: '672',
    initials: 'AQ',
  },
  {
    country: 'Antígua e Barbuda',
    ddi: '1268',
    initials: 'AG',
  },
  {
    country: 'Argentina',
    ddi: '54',
    initials: 'AR',
  },
  {
    country: 'Armênia',
    ddi: '374',
    initials: 'AM',
  },
  {
    country: 'Aruba',
    ddi: '297',
    initials: 'AW',
  },
  {
    country: 'Ilha de Ascensão',
    ddi: '247',
    initials: 'AC',
  },
  {
    country: 'Austrália',
    ddi: '61',
    initials: 'AU',
  },
  {
    country: 'Áustria',
    ddi: '43',
    initials: 'AT',
  },
  {
    country: 'Azerbaijão',
    ddi: '994',
    initials: 'AZ',
  },
  {
    country: 'Bahamas',
    ddi: '1242',
    initials: 'BS',
  },
  {
    country: 'Bahrein',
    ddi: '973',
    initials: 'BH',
  },
  {
    country: 'Bangladesh',
    ddi: '880',
    initials: 'BD',
  },
  {
    country: 'Barbados',
    ddi: '1246',
    initials: 'BB',
  },
  {
    country: 'Bielorrússia',
    ddi: '375',
    initials: 'BY',
  },
  {
    country: 'Bélgica',
    ddi: '32',
    initials: 'BE',
  },
  {
    country: 'Belize',
    ddi: '501',
    initials: 'BZ',
  },
  {
    country: 'Benin',
    ddi: '229',
    initials: 'BJ',
  },
  {
    country: 'Bermudas',
    ddi: '1441',
    initials: 'BM',
  },
  {
    country: 'Butão',
    ddi: '975',
    initials: 'BT',
  },
  {
    country: 'Bolívia',
    ddi: '591',
    initials: 'BO',
  },
  {
    country: 'Bósnia e Herzegovina',
    ddi: '387',
    initials: 'BA',
  },
  {
    country: 'Botsuana',
    ddi: '267',
    initials: 'BW',
  },
  {
    country: 'Brasil',
    ddi: '55',
    initials: 'BR',
  },
  {
    country: 'Território Britânico do Oceano Índico',
    ddi: '246',
    initials: 'IO',
  },
  {
    country: 'Brunei Darussalam',
    ddi: '673',
    initials: 'BN',
  },
  {
    country: 'Bulgária',
    ddi: '359',
    initials: 'BG',
  },
  {
    country: 'Burkina Faso',
    ddi: '226',
    initials: 'BF',
  },
  {
    country: 'Burundi',
    ddi: '257',
    initials: 'BI',
  },
  {
    country: 'Camboja',
    ddi: '855',
    initials: 'KH',
  },
  {
    country: 'Camarões',
    ddi: '237',
    initials: 'CM',
  },
  {
    country: 'Estados Unidos',
    ddi: '1',
    initials: 'US',
  },
  {
    country: 'Canadá',
    ddi: '1',
    initials: 'CA',
  },
  {
    country: 'Cabo Verde',
    ddi: '238',
    initials: 'CV',
  },
  {
    country: 'Ilhas Cayman',
    ddi: '1345',
    initials: 'KY',
  },
  {
    country: 'República Centro-Africana',
    ddi: '236',
    initials: 'CF',
  },
  {
    country: 'Chade',
    ddi: '235',
    initials: 'TD',
  },
  {
    country: 'Chile',
    ddi: '56',
    initials: 'CL',
  },
  {
    country: 'China',
    ddi: '86',
    initials: 'CN',
  },
  {
    country: 'Ilha do Natal',
    ddi: '61',
    initials: 'CX',
  },
  {
    country: 'Ilhas Cocos (Keeling)',
    ddi: '61',
    initials: 'CC',
  },
  {
    country: 'Colômbia',
    ddi: '57',
    initials: 'CO',
  },
  {
    country: 'Comores',
    ddi: '269',
    initials: 'KM',
  },
  {
    country: 'Congo',
    ddi: '242',
    initials: 'CG',
  },
  {
    country: 'Ilhas Cook',
    ddi: '682',
    initials: 'CK',
  },
  {
    country: 'Costa Rica',
    ddi: '506',
    initials: 'CR',
  },
  {
    country: 'Croácia',
    ddi: '385',
    initials: 'HR',
  },
  {
    country: 'Cuba',
    ddi: '53',
    initials: 'CU',
  },
  {
    country: 'Chipre',
    ddi: '357',
    initials: 'CY',
  },
  {
    country: 'República Checa',
    ddi: '420',
    initials: 'CZ',
  },
  {
    country: 'República Democrática do Congo',
    ddi: '243',
    initials: 'CD',
  },
  {
    country: 'Dinamarca',
    ddi: '45',
    initials: 'DK',
  },
  {
    country: 'Djibuti',
    ddi: '253',
    initials: 'DJ',
  },
  {
    country: 'Dominica',
    ddi: '1767',
    initials: 'DM',
  },
  {
    country: 'República Dominicana',
    ddi: '1849',
    initials: 'DO',
  },
  {
    country: 'Equador',
    ddi: '593',
    initials: 'CE',
  },
  {
    country: 'Egito',
    ddi: '20',
    initials: 'EG',
  },
  {
    country: 'El Salvador',
    ddi: '503',
    initials: 'SV',
  },
  {
    country: 'Guiné Equatorial',
    ddi: '240',
    initials: 'GQ',
  },
  {
    country: 'Eritreia',
    ddi: '291',
    initials: 'ER',
  },
  {
    country: 'Estônia',
    ddi: '372',
    initials: 'EE',
  },
  {
    country: 'Suazilândia',
    ddi: '268',
    initials: 'SZ',
  },
  {
    country: 'Etiópia',
    ddi: '251',
    initials: 'E',
  },
  {
    country: 'Ilhas Falkland (Falcons)',
    ddi: '500',
    initials: 'FK',
  },
  {
    country: 'Ilhas Faroé',
    ddi: '298',
    initials: 'FO',
  },
  {
    country: 'Fiji',
    ddi: '679',
    initials: 'FJ',
  },
  {
    country: 'Finlândia',
    ddi: '358',
    initials: 'FI',
  },
  {
    country: 'França',
    ddi: '33',
    initials: 'FR',
  },
  {
    country: 'Guiana Francesa',
    ddi: '594',
    initials: 'GF',
  },
  {
    country: 'Polinésia Francesa',
    ddi: '689',
    initials: 'PF',
  },
  {
    country: 'Gabão',
    ddi: '241',
    initials: 'GA',
  },
  {
    country: 'Gâmbia',
    ddi: '220',
    initials: 'GM',
  },
  {
    country: 'Geórgia',
    ddi: '995',
    initials: 'GE',
  },
  {
    country: 'Alemanha',
    ddi: '49',
    initials: 'DE',
  },
  {
    country: 'Gana',
    ddi: '233',
    initials: 'GH',
  },
  {
    country: 'Gibraltar',
    ddi: '350',
    initials: 'GI',
  },
  {
    country: 'Grécia',
    ddi: '30',
    initials: 'GR',
  },
  {
    country: 'Groenlândia',
    ddi: '299',
    initials: 'GL',
  },
  {
    country: 'Granada',
    ddi: '1473',
    initials: 'GD',
  },
  {
    country: 'Guadalupe',
    ddi: '590',
    initials: 'GP',
  },
  {
    country: 'Guam',
    ddi: '1671',
    initials: 'GU',
  },
  {
    country: 'Guatemala',
    ddi: '502',
    initials: 'GT',
  },
  {
    country: 'Guernsey',
    ddi: '44',
    initials: 'GG',
  },
  {
    country: 'Guiné',
    ddi: '224',
    initials: 'GN',
  },
  {
    country: 'Guiné-Bissau',
    ddi: '245',
    initials: 'GW',
  },
  {
    country: 'Guiana',
    ddi: '592',
    initials: 'GY',
  },
  {
    country: 'Haiti',
    ddi: '509',
    initials: 'HT',
  },
  {
    country: 'Santa Sé (Estado da Cidade do Vaticano)',
    ddi: '379',
    initials: 'VA',
  },
  {
    country: 'Honduras',
    ddi: '504',
    initials: 'HN',
  },
  {
    country: 'Hong Kong',
    ddi: '852',
    initials: 'HK',
  },
  {
    country: 'Hungria',
    ddi: '36',
    initials: 'HU',
  },
  {
    country: 'Islândia',
    ddi: '354',
    initials: 'IS',
  },
  {
    country: 'Índia',
    ddi: '91',
    initials: 'IN',
  },
  {
    country: 'Indonésia',
    ddi: '62',
    initials: 'ID',
  },
  {
    country: 'Irã',
    ddi: '98',
    initials: 'IR',
  },
  {
    country: 'Iraque',
    ddi: '964',
    initials: 'QI',
  },
  {
    country: 'Irlanda',
    ddi: '353',
    initials: 'IE',
  },
  {
    country: 'Ilha de Man',
    ddi: '44',
    initials: 'IM',
  },
  {
    country: 'Israel',
    ddi: '972',
    initials: 'IL',
  },
  {
    country: 'Itália',
    ddi: '39',
    initials: 'IT',
  },
  {
    country: 'Costa do Marfim / Costa do Marfim',
    ddi: '225',
    initials: 'CI',
  },
  {
    country: 'Jamaica',
    ddi: '1876',
    initials: 'JM',
  },
  {
    country: 'Japão',
    ddi: '81',
    initials: 'JP',
  },
  {
    country: 'Jersey',
    ddi: '44',
    initials: 'JE',
  },
  {
    country: 'Jordânia',
    ddi: '962',
    initials: 'JO',
  },
  {
    country: 'Cazaquistão',
    ddi: '77',
    initials: 'KZ',
  },
  {
    country: 'Quênia',
    ddi: '254',
    initials: 'KE',
  },
  {
    country: 'Kiribati',
    ddi: '686',
    initials: 'KI',
  },
  {
    country: 'Coreia, República Popular Democrática da Coreia',
    ddi: '850',
    initials: 'KP',
  },
  {
    country: 'Coreia, República da Coreia do Sul',
    ddi: '82',
    initials: 'KR',
  },
  {
    country: 'Kosovo',
    ddi: '383',
    initials: 'XK',
  },
  {
    country: 'Kuwait',
    ddi: '965',
    initials: 'KW',
  },
  {
    country: 'Quirguistão',
    ddi: '996',
    initials: 'KG',
  },
  {
    country: 'Laos',
    ddi: '856',
    initials: 'O',
  },
  {
    country: 'Letónia',
    ddi: '371',
    initials: 'LV',
  },
  {
    country: 'Líbano',
    ddi: '961',
    initials: 'LB',
  },
  {
    country: 'Lesoto',
    ddi: '266',
    initials: 'LS',
  },
  {
    country: 'Libéria',
    ddi: '231',
    initials: 'LR',
  },
  {
    country: 'Líbia',
    ddi: '218',
    initials: 'LY',
  },
  {
    country: 'Liechtenstein',
    ddi: '423',
    initials: 'LI',
  },
  {
    country: 'Lituânia',
    ddi: '370',
    initials: 'LT',
  },
  {
    country: 'Luxemburgo',
    ddi: '352',
    initials: 'LU',
  },
  {
    country: 'Macau',
    ddi: '853',
    initials: 'MO',
  },
  {
    country: 'Madagascar',
    ddi: '261',
    initials: 'MG',
  },
  {
    country: 'Malauí',
    ddi: '265',
    initials: 'MW',
  },
  {
    country: 'Malásia',
    ddi: '60',
    initials: 'MY',
  },
  {
    country: 'Maldivas',
    ddi: '960',
    initials: 'MV',
  },
  {
    country: 'Mali',
    ddi: '223',
    initials: 'ML',
  },
  {
    country: 'Malta',
    ddi: '356',
    initials: 'MT',
  },
  {
    country: 'Ilhas Marshall',
    ddi: '692',
    initials: 'MH',
  },
  {
    country: 'Martinica',
    ddi: '596',
    initials: 'MQ',
  },
  {
    country: 'Mauritânia',
    ddi: '222',
    initials: 'MR',
  },
  {
    country: 'Maurício',
    ddi: '230',
    initials: 'MU',
  },
  {
    country: 'Mayotte',
    ddi: '262',
    initials: 'YT',
  },
  {
    country: 'México',
    ddi: '52',
    initials: 'MX',
  },
  {
    country: 'Micronésia, Estados Federados da Micronésia',
    ddi: '691',
    initials: 'FM',
  },
  {
    country: 'Moldávia',
    ddi: '373',
    initials: 'MD',
  },
  {
    country: 'Mônaco',
    ddi: '377',
    initials: 'MC',
  },
  {
    country: 'Mongólia',
    ddi: '976',
    initials: 'MN',
  },
  {
    country: 'Montenegro',
    ddi: '382',
    initials: 'EU',
  },
  {
    country: 'Montserrat',
    ddi: '1664',
    initials: 'MS',
  },
  {
    country: 'Marrocos',
    ddi: '212',
    initials: 'MA',
  },
  {
    country: 'Moçambique',
    ddi: '258',
    initials: 'MZ',
  },
  {
    country: 'Mianmar',
    ddi: '95',
    initials: 'MM',
  },
  {
    country: 'Namíbia',
    ddi: '264',
    initials: 'NA',
  },
  {
    country: 'Nauru',
    ddi: '674',
    initials: 'NR',
  },
  {
    country: 'Nepal',
    ddi: '977',
    initials: 'NP',
  },
  {
    country: 'Holanda',
    ddi: '31',
    initials: 'NL',
  },
  {
    country: 'Antilhas Holandesas',
    ddi: '599',
    initials: 'AN',
  },
  {
    country: 'Nova Caledônia',
    ddi: '687',
    initials: 'NC',
  },
  {
    country: 'Nova Zelândia',
    ddi: '64',
    initials: 'NZ',
  },
  {
    country: 'Nicarágua',
    ddi: '505',
    initials: 'NI',
  },
  {
    country: 'Níger',
    ddi: '227',
    initials: 'NE',
  },
  {
    country: 'Nigéria',
    ddi: '234',
    initials: 'NG',
  },
  {
    country: 'Niue',
    ddi: '683',
    initials: 'NU',
  },
  {
    country: 'Ilha Norfolk',
    ddi: '672',
    initials: 'NF',
  },
  {
    country: 'Macedônia do Norte',
    ddi: '389',
    initials: 'MK',
  },
  {
    country: 'Ilhas Marianas do Norte',
    ddi: '1670',
    initials: 'MP',
  },
  {
    country: 'Noruega',
    ddi: '47',
    initials: 'NO',
  },
  {
    country: 'Omã',
    ddi: '968',
    initials: 'OM',
  },
  {
    country: 'Paquistão',
    ddi: '92',
    initials: 'PK',
  },
  {
    country: 'Palau',
    ddi: '680',
    initials: 'PW',
  },
  {
    country: 'Palestina',
    ddi: '970',
    initials: 'PS',
  },
  {
    country: 'Panamá',
    ddi: '507',
    initials: 'PA',
  },
  {
    country: 'Papua Nova Guiné',
    ddi: '675',
    initials: 'PG',
  },
  {
    country: 'Paraguai',
    ddi: '595',
    initials: 'PY',
  },
  {
    country: 'Peru',
    ddi: '51',
    initials: 'PE',
  },
  {
    country: 'Filipinas',
    ddi: '63',
    initials: 'PH',
  },
  {
    country: 'Pitcairn',
    ddi: '872',
    initials: 'PN',
  },
  {
    country: 'Polônia',
    ddi: '48',
    initials: 'PL',
  },
  {
    country: 'Portugal',
    ddi: '351',
    initials: 'PT',
  },
  {
    country: 'Porto Rico',
    ddi: '1939',
    initials: 'PR',
  },
  {
    country: 'Catar',
    ddi: '974',
    initials: 'QA',
  },
  {
    country: 'Reunião',
    ddi: '262',
    initials: 'RE',
  },
  {
    country: 'Romênia',
    ddi: '40',
    initials: 'RO',
  },
  {
    country: 'Rússia',
    ddi: '7',
    initials: 'RU',
  },
  {
    country: 'Ruanda',
    ddi: '250',
    initials: 'RW',
  },
  {
    country: 'Saint Barthelemy',
    ddi: '590',
    initials: 'BL',
  },
  {
    country: 'Santa Helena, Ascensão e Tristão da Cunha',
    ddi: '290',
    initials: 'SH',
  },
  {
    country: 'São Cristóvão e Nevis',
    ddi: '1869',
    initials: 'KN',
  },
  {
    country: 'Santa Lúcia',
    ddi: '1758',
    initials: 'LC',
  },
  {
    country: 'São Martinho',
    ddi: '590',
    initials: 'MF',
  },
  {
    country: 'São Pedro e Miquelon',
    ddi: '508',
    initials: 'PM',
  },
  {
    country: 'São Vicente e Granadinas',
    ddi: '1784',
    initials: 'VC',
  },
  {
    country: 'Samoa',
    ddi: '685',
    initials: 'WS',
  },
  {
    country: 'San Marino',
    ddi: '378',
    initials: 'SM',
  },
  {
    country: 'São Tomé e Príncipe',
    ddi: '239',
    initials: 'ST',
  },
  {
    country: 'Arábia Saudita',
    ddi: '966',
    initials: 'SA',
  },
  {
    country: 'Senegal',
    ddi: '221',
    initials: 'SN',
  },
  {
    country: 'Sérvia',
    ddi: '381',
    initials: 'RS',
  },
  {
    country: 'Seychelles',
    ddi: '248',
    initials: 'SC',
  },
  {
    country: 'Serra Leoa',
    ddi: '232',
    initials: 'SL',
  },
  {
    country: 'Singapura',
    ddi: '65',
    initials: 'SG',
  },
  {
    country: 'Sint Maarten',
    ddi: '1721',
    initials: 'SX',
  },
  {
    country: 'Eslováquia',
    ddi: '421',
    initials: 'SK',
  },
  {
    country: 'Eslovênia',
    ddi: '386',
    initials: 'SI',
  },
  {
    country: 'Ilhas Salomão',
    ddi: '677',
    initials: 'SB',
  },
  {
    country: 'Somália',
    ddi: '252',
    initials: 'SO',
  },
  {
    country: 'África do Sul',
    ddi: '27',
    initials: 'ZA',
  },
  {
    country: 'Geórgia do Sul e Ilhas Sandwich do Sul',
    ddi: '500',
    initials: 'GS',
  },
  {
    country: 'Sudão do Sul',
    ddi: '211',
    initials: 'SS',
  },
  {
    country: 'Espanha',
    ddi: '34',
    initials: 'ES',
  },
  {
    country: 'Sri Lanka',
    ddi: '94',
    initials: 'LK',
  },
  {
    country: 'Sudão',
    ddi: '249',
    initials: 'SD',
  },
  {
    country: 'Suripaís',
    ddi: '597',
    initials: 'SR',
  },
  {
    country: 'Svalbard e Jan Mayen',
    ddi: '47',
    initials: 'SJ',
  },
  {
    country: 'Suécia',
    ddi: '46',
    initials: 'SE',
  },
  {
    country: 'Suíça',
    ddi: '41',
    initials: 'CH',
  },
  {
    country: 'República Árabe Síria',
    ddi: '963',
    initials: 'SY',
  },
  {
    country: 'Taiwan',
    ddi: '886',
    initials: 'TW',
  },
  {
    country: 'Tadjiquistão',
    ddi: '992',
    initials: 'TJ',
  },
  {
    country: 'Tanzânia, República Unida da Tanzânia',
    ddi: '255',
    initials: 'TZ',
  },
  {
    country: 'Tailândia',
    ddi: '66',
    initials: 'TH',
  },
  {
    country: 'Timor-Leste',
    ddi: '670',
    initials: 'TL',
  },
  {
    country: 'Togo',
    ddi: '228',
    initials: 'TG',
  },
  {
    country: 'Tokelau',
    ddi: '690',
    initials: 'TK',
  },
  {
    country: 'Tonga',
    ddi: '676',
    initials: 'TO',
  },
  {
    country: 'Trinidad e Tobago',
    ddi: '1868',
    initials: 'TT',
  },
  {
    country: 'Tunísia',
    ddi: '216',
    initials: 'TN',
  },
  {
    country: 'Turquia',
    ddi: '90',
    initials: 'TR',
  },
  {
    country: 'Turquemenistão',
    ddi: '993',
    initials: 'TM',
  },
  {
    country: 'Ilhas Turks e Caicos',
    ddi: '1649',
    initials: 'TC',
  },
  {
    country: 'Tuvalu',
    ddi: '688',
    initials: 'TV',
  },
  {
    country: 'Uganda',
    ddi: '256',
    initials: 'UG',
  },
  {
    country: 'Ucrânia',
    ddi: '380',
    initials: 'UA',
  },
  {
    country: 'Emirados Árabes Unidos',
    ddi: '971',
    initials: 'AE',
  },
  {
    country: 'Reino Unido',
    ddi: '44',
    initials: 'GB',
  },
  {
    country: 'Uruguai',
    ddi: '598',
    initials: 'UY',
  },
  {
    country: 'Uzbequistão',
    ddi: '998',
    initials: 'UZ',
  },
  {
    country: 'Vanuatu',
    ddi: '678',
    initials: 'VU',
  },
  {
    country: 'Venezuela, República Bolivariana da Venezuela',
    ddi: '58',
    initials: 'VE',
  },
  {
    country: 'Vietnã',
    ddi: '84',
    initials: 'VN',
  },
  {
    country: 'Ilhas Virgens Britânicas',
    ddi: '1284',
    initials: 'VG',
  },
  {
    country: 'Ilhas Virgens, EUA',
    ddi: '1340',
    initials: 'VI',
  },
  {
    country: 'Wallis e Futuna',
    ddi: '681',
    initials: 'WF',
  },
  {
    country: 'Iêmen',
    ddi: '967',
    initials: 'HE',
  },
  {
    country: 'Zâmbia',
    ddi: '260',
    initials: 'ZM',
  },
  {
    country: 'Zimbabwe',
    ddi: '263',
    initials: 'ZW',
  },
];

export default DDIHelperBR;
