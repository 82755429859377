import { axiosBackend } from './Configuration';

const SubscriptionAPI = {
  show: async () => {
    try {
      const { data: subscriptionData } = await axiosBackend().get('/status-subscription');

      return subscriptionData;
    } catch (error) {
      throw error;
    }
  },
};

export default SubscriptionAPI;
