import { Container, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { WHATSAPP_LINK } from '../constants';

interface ExpirationAlertProps {
  isVisible: boolean;
  trial: number;
}

function trialMessage(trial: number) {
  if (trial <= 0) return `terminou!`;

  const days = trial === 1 ? 'dia' : 'dias';

  return `termina em ${trial} ${days}!`;
}

export default function ExpirationAlert({ isVisible, trial }: ExpirationAlertProps) {
  if (!isVisible) return null;

  return (
    <Flex
      position="absolute"
      left={0}
      top={{ base: 10, lg: '95px' }}
      w="full"
      bg="#E85347"
      py="0.344rem"
      pl={{ lg: '18.25rem', '2xl': '36rem' }}
    >
      <Container maxWidth="8xl">
        <Text color="#ffffff" sx={{ a: { fontWeight: 'bold', textDecoration: 'underline' } }}>
          Seu período de teste {trialMessage(trial)}{' '}
          <Link to={{ pathname: WHATSAPP_LINK }} target="_blank">
            Clique aqui
          </Link>{' '}
          para mais informações sobre os planos.
        </Text>
      </Container>
    </Flex>
  );
}
