import { Heading, Stack } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Card from '../../components/Card';

function RelatedPosts({ listPosts, postId }) {
  return (
    <Stack spacing={5}>
      <Heading size="md" textAlign={{ base: 'center', md: 'left', lg: 'left' }}>
        Posts relacionados:
      </Heading>
      {listPosts
        .filter(postItem => postItem.id !== parseInt(postId))
        .slice(0, 2)
        .map(post => (
          <Card key={nanoid()} post={post} />
        ))}
    </Stack>
  );
}

export default RelatedPosts;
