import { Box, Button, FormControl, FormLabel, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { IoChevronForward } from 'react-icons/io5';
import { IInitialFilters } from '../../pages/DashboardAdmin/Courses/CourseManager/ModulesManager/QuestionLibrary/types';
import { IQuestionLibraryAreaData } from '../../pages/DashboardAdmin/Tools/Filters/TabPanelArea';
import { IQuestionLibraryDisciplineData } from '../../pages/DashboardAdmin/Tools/Filters/TabPanelDiscipline';
import { IQuestionLibrarySubjectData } from '../../pages/DashboardAdmin/Tools/Filters/TabPanelSubject';
import FilterSelect from './FilterSelect';
interface IParams {
  areaListData: IQuestionLibraryAreaData[];
  disciplineListData: IQuestionLibraryDisciplineData[];
  subjectListData: IQuestionLibrarySubjectData[];
  institutionData: { id: number; name: string }[];
  isLoadingAreaData: boolean;
  isLoadingDisciplineData: boolean;
  isLoadingSubjectData: boolean;
  isLoadingInstitutionData: boolean;
  filters: IInitialFilters;
  questionsLength?: number;
  buttonVariant?: string;
  textColor?: string;
  alternateTextColor?: string;
  bgColor?: string;
  setFilters: ({ areaId, disciplineId, subjectId }: IInitialFilters) => void;
  setCurrentPage: (page: number) => void;
}

const initialStateFilters = {
  area: {
    id: '',
    name: '',
  },
  discipline: {
    id: '',
    name: '',
  },
  subject: {
    id: '',
    name: '',
  },
  institution: {
    id: '',
    name: '',
  },
};

export default function FilterQuestions({
  areaListData,
  disciplineListData,
  subjectListData,
  institutionData,
  isLoadingAreaData,
  isLoadingDisciplineData,
  isLoadingSubjectData,
  isLoadingInstitutionData,
  filters,
  questionsLength,
  buttonVariant,
  textColor,
  bgColor,
  alternateTextColor,
  setFilters,
  setCurrentPage,
}: IParams) {
  const [selectFilters, setSelectFilters] = useState(initialStateFilters);
  const [disciplineListDataFiltered, setDisciplineListDataFiltered] = useState([]);
  const [subjectListDataFiltered, setSubjectListDataFiltered] = useState([]);

  useEffect(() => {
    if (disciplineListData?.length) {
      setDisciplineListDataFiltered(disciplineListData);
    }

    if (subjectListData?.length) {
      setSubjectListDataFiltered(subjectListData);
    }
  }, [disciplineListData, subjectListData]);

  function onChangeFilters({ name, value }: { name: string; value: string }) {
    if (name === 'areaId') {
      if (!value) {
        setSelectFilters(initialStateFilters);
        setDisciplineListDataFiltered(disciplineListData);
        setSubjectListDataFiltered(subjectListData);
        return;
      }

      const area = areaListData.find(area => area.id === parseInt(value));

      const disciplineList = disciplineListData.filter(
        discipline => discipline.area.id === parseInt(value)
      );

      if (disciplineList) {
        const subjectList = subjectListData.filter(subject => subject.area.id === parseInt(value));
        setDisciplineListDataFiltered(disciplineList);
        setSubjectListDataFiltered(subjectList);
      }

      setSelectFilters({
        ...selectFilters,
        area: {
          ...selectFilters.area,
          id: value,
          name: area.name,
        },
        discipline: {
          ...selectFilters.area,
          id: '',
          name: '',
        },
        subject: {
          ...selectFilters.area,
          id: '',
          name: '',
        },
      });
    }
    if (name === 'disciplineId') {
      if (!value) {
        setSelectFilters({
          ...selectFilters,
          discipline: {
            id: '',
            name: '',
          },
          subject: {
            ...selectFilters.subject,
            id: '',
            name: '',
          },
        });
        setSubjectListDataFiltered(subjectListData);
        return;
      }

      const discipline = disciplineListData.find(discipline => discipline.id === parseInt(value));

      const area = areaListData.find(areaId => areaId.id === discipline.area.id);

      const subjectList = subjectListData.filter(
        subject => subject.discipline.id === parseInt(value)
      );

      setSubjectListDataFiltered(subjectList);

      setSelectFilters({
        ...selectFilters,
        area: {
          id: area.id.toString(),
          name: area.name,
        },
        discipline: {
          id: value,
          name: discipline.name,
        },
      });
    }
    if (name === 'subjectId') {
      if (!value) {
        setSelectFilters({
          ...selectFilters,
          subject: {
            id: '',
            name: '',
          },
        });
        return;
      }
      const subject = subjectListData.find(subject => subject.id === parseInt(value));

      const discipline = disciplineListData.find(
        discipline => discipline.id === subject.discipline.id
      );

      const area = areaListData.find(areaId => areaId.id === discipline.area.id);

      setSelectFilters({
        ...selectFilters,
        area: {
          id: area.id.toString(),
          name: area.name,
        },
        discipline: {
          id: discipline.id.toString(),
          name: discipline.name,
        },
        subject: {
          id: value,
          name: subject.name,
        },
      });

      setDisciplineListDataFiltered([discipline]);
    }

    if (name === 'institutionId') {
      if (!value) {
        setSelectFilters({
          ...selectFilters,
          institution: {
            id: '',
            name: '',
          },
        });
        return;
      }

      const institution = institutionData.find(institution => institution.id === parseInt(value));

      setSelectFilters({
        ...selectFilters,

        institution: {
          id: value,
          name: institution.name,
        },
      });
    }
  }

  function handleClickFiltered() {
    setFilters({
      ...filters,
      areaId: selectFilters.area.id,
      disciplineId: selectFilters.discipline.id,
      subjectId: selectFilters.subject.id,
      institutionId: selectFilters.institution.id,
    });

    setCurrentPage(1);
  }

  function removeFilter(filter: string) {
    if (filter === 'area') {
      setSelectFilters({
        ...selectFilters,
        area: {
          ...selectFilters.area,
          id: '',
          name: '',
        },
        discipline: {
          ...selectFilters.discipline,
          id: '',
          name: '',
        },
        subject: {
          ...selectFilters.subject,
          id: '',
          name: '',
        },
      });
    }
    if (filter === 'discipline') {
      setSelectFilters({
        ...selectFilters,
        discipline: {
          ...selectFilters.discipline,
          id: '',
          name: '',
        },
        subject: {
          ...selectFilters.subject,
          id: '',
          name: '',
        },
      });
    }
    if (filter === 'subject') {
      setSelectFilters({
        ...selectFilters,
        subject: {
          ...selectFilters.subject,
          id: '',
          name: '',
        },
      });
    }
    if (filter === 'institution') {
      setSelectFilters({
        ...selectFilters,
        institution: {
          ...selectFilters.institution,
          id: '',
          name: '',
        },
      });
    }
  }

  const hasAreaFilter = !!selectFilters.area.id;

  return (
    <Box>
      <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} width="full" alignItems="center">
        <Stack direction="row" width="full" alignItems="center">
          <FormControl>
            <FormLabel fontSize="14px">Área</FormLabel>

            <FilterSelect
              label="Área"
              name="areaId"
              value={selectFilters.area.id}
              onChange={onChangeFilters}
              options={areaListData}
              isDisabled={questionsLength === 0 || isLoadingAreaData}
              color={textColor ? textColor : '#20212390'}
              bgColor={bgColor ? bgColor : '#FFF'}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontSize="14px">Disciplina</FormLabel>

            <FilterSelect
              label="Disciplina"
              name="disciplineId"
              value={selectFilters.discipline.id}
              onChange={onChangeFilters}
              options={hasAreaFilter ? disciplineListDataFiltered : disciplineListData}
              isDisabled={questionsLength === 0 || isLoadingDisciplineData}
              color={textColor ? textColor : '#20212390'}
              bgColor={bgColor ? bgColor : '#FFF'}
            />
          </FormControl>
        </Stack>

        <Stack direction="row" width="full" alignItems="center">
          <FormControl>
            <FormLabel fontSize="14px">Assunto</FormLabel>

            <FilterSelect
              label="Assunto"
              name="subjectId"
              value={selectFilters.subject.id}
              onChange={onChangeFilters}
              options={hasAreaFilter ? subjectListDataFiltered : subjectListData}
              isDisabled={questionsLength === 0 || isLoadingSubjectData}
              color={textColor ? textColor : '#20212390'}
              bgColor={bgColor ? bgColor : '#FFF'}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontSize="14px">Banca</FormLabel>

            <FilterSelect
              label="Banca"
              name="institutionId"
              value={selectFilters.institution.id}
              onChange={onChangeFilters}
              options={institutionData}
              isDisabled={questionsLength === 0 || isLoadingInstitutionData}
              color={textColor ? textColor : '#20212390'}
              bgColor={bgColor ? bgColor : '#FFF'}
            />
          </FormControl>
        </Stack>
      </Stack>

      <Box marginY="18px">
        <Stack direction="row" justifyContent="space-between" spacing={1} alignItems="center">
          <Stack
            direction="row"
            flex={1}
            spacing={0}
            gap={2}
            alignItems="center"
            width="full"
            flexWrap="wrap"
          >
            <Text fontWeight={600} fontSize="14px">
              Filtrar por:
            </Text>

            <Stack
              direction="row"
              alignItems="center"
              maxWidth={250}
              onClick={() => removeFilter('area')}
              color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
              fontWeight={400}
              paddingY="2px"
              paddingX="6px"
              fontSize="12px"
              borderRadius={7}
              border="1px solid #20212380"
              hidden={selectFilters.area.name === ''}
              _hover={{
                fontWeight: 500,
                color: '#EC7117',
                border: '1px solid #EC7117',
                cursor: 'pointer',
                transition: 'all 0.4s ease',
              }}
            >
              <Text isTruncated>{selectFilters.area.name}</Text>
              <IoIosClose size={20} />
            </Stack>

            <Text
              color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
              fontWeight={400}
              fontSize="12px"
              hidden={
                (selectFilters.discipline.name === '' && selectFilters.institution.name === '') ||
                selectFilters.area.name === ''
              }
            >
              <IoChevronForward size={15} />
            </Text>

            <Stack
              direction="row"
              alignItems="center"
              maxWidth={250}
              onClick={() => removeFilter('discipline')}
              color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
              fontWeight={400}
              paddingY="2px"
              paddingX="6px"
              fontSize="12px"
              border="1px solid #20212380"
              borderRadius={7}
              hidden={selectFilters.discipline.name === ''}
              _hover={{
                fontWeight: 500,
                color: '#EC7117',
                border: '1px solid #EC7117',
                cursor: 'pointer',
                transition: 'all 0.4s ease',
              }}
            >
              <Text isTruncated>{selectFilters.discipline.name}</Text>
              <IoIosClose size={20} />
            </Stack>

            <Text
              color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
              fontWeight={400}
              fontSize="12px"
              hidden={
                (selectFilters.subject.name === '' && selectFilters.institution.name === '') ||
                selectFilters.discipline.name === ''
              }
            >
              <IoChevronForward size={15} />
            </Text>

            <Stack
              direction="row"
              alignItems="center"
              maxWidth={250}
              onClick={() => removeFilter('subject')}
              color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
              fontWeight={400}
              paddingY="2px"
              paddingX="6px"
              fontSize="12px"
              borderRadius={7}
              border="1px solid #20212380"
              hidden={selectFilters.subject.name === ''}
              _hover={{
                fontWeight: 500,
                color: '#EC7117',
                border: '1px solid #EC7117',
                cursor: 'pointer',
                transition: 'all 0.4s ease',
              }}
            >
              <Text isTruncated>{selectFilters.subject.name}</Text>
              <IoIosClose size={20} />
            </Stack>

            <Text
              color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
              fontWeight={400}
              fontSize="12px"
              hidden={
                selectFilters.subject.name === '' ||
                selectFilters.discipline.name === '' ||
                selectFilters.area.name === '' ||
                selectFilters.institution.name === ''
              }
            >
              <IoChevronForward size={15} />
            </Text>

            <Stack
              direction="row"
              maxWidth={250}
              alignItems="center"
              onClick={() => removeFilter('institution')}
              color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
              fontWeight={400}
              paddingY="2px"
              paddingX="6px"
              fontSize="12px"
              border="1px solid #20212380"
              borderRadius={7}
              hidden={selectFilters.institution.name === ''}
              _hover={{
                fontWeight: 500,
                color: '#EC7117',
                border: '1px solid #EC7117',
                cursor: 'pointer',
                transition: 'all 0.4s ease',
              }}
            >
              <Text isTruncated>{selectFilters.institution.name}</Text>
              <IoIosClose size={20} />
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <Box display="flex" justifyContent="flex-end" width="full">
        <Button
          bgColor="#EC7117"
          color="#FFFFFF"
          size="sm"
          fontSize="16px"
          fontWeight="600"
          lineHeight="24px"
          variant={buttonVariant ? buttonVariant : 'solid'}
          isDisabled={questionsLength === 0}
          onClick={handleClickFiltered}
        >
          Filtrar
        </Button>
      </Box>
    </Box>
  );
}
