function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  const orderedItems = result.map((item, index) => ({ ...item, order: index + 1 }));

  return orderedItems;
}

export default reorder;
