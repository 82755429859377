import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import path from 'path';
import { FormEvent, useEffect, useState } from 'react';
import PlatformManager from '../../../../api/PlatformManager';
import SelectImage from '../../../../assets/icons/select-image.png';
import SelectImageButton from '../../../../components/SelectImageButton';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../helpers/UploadHelper';

const MAX_SIZE_FILE_PHOTO_THUMBNAIL = 4000000;
const MAX_SIZE_FILE_PHOTO_FAVICON = 2000000;

export default function SEOConfig() {
  const initialStatePlatformSettings = {
    title: '',
    description: '',
    thumbnailShareLink: '',
    faviconLink: '',
  };

  const [platformSettingsSEO, setPlatformSettingsSEO] = useState(initialStatePlatformSettings);

  const [previewThumbnail, setPreviewThumbnail] = useState('');
  const [previewFavicon, setPreviewFavicon] = useState('');
  const [fileThumbnail, setFileThumbnail] = useState(null);
  const [fileFavicon, setFileFavicon] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [errorUploadPhotoThumbnail, setErrorUploadPhotoThumbnail] = useState('');
  const [errorUploadPhotoFavicon, setErrorUploadPhotoFavicon] = useState('');

  useEffect(() => {
    async function getPlatformSettingsSEO() {
      const getPlatformSettingsSEOData = await PlatformManager.getSettingsSEO();

      if (getPlatformSettingsSEOData) {
        setPlatformSettingsSEO(getPlatformSettingsSEOData.data);
        setPreviewThumbnail(getPlatformSettingsSEOData.data.thumbnailShareLink);
        setPreviewFavicon(getPlatformSettingsSEOData.data.faviconLink);
      }
    }
    getPlatformSettingsSEO();
  }, []);

  function handlePhotoChangeThumbnail(acceptedFiles: any[]) {
    const file = acceptedFiles[0];
    setErrorUploadPhotoThumbnail('');

    if (file?.size > MAX_SIZE_FILE_PHOTO_THUMBNAIL) {
      return setErrorUploadPhotoThumbnail('O tamanho da thumbnail deve ser de no máximo 4MB.');
    }

    if (file) {
      setFileThumbnail(file);

      setPreviewThumbnail(URL.createObjectURL(file));

      const updatedThumbnail = {
        ...platformSettingsSEO,
        thumbnailShareLink: file,
      };
      setPlatformSettingsSEO(updatedThumbnail);
      setHasChange(true);
    }
  }

  function handleDiscardImageThumbnail() {
    setPreviewThumbnail(null);

    setPlatformSettingsSEO({ ...platformSettingsSEO, thumbnailShareLink: null });

    setHasChange(true);
  }

  function handlePhotoChangeFavicon(acceptedFiles: any[]) {
    const file = acceptedFiles[0];
    setErrorUploadPhotoFavicon('');

    if (file?.size > MAX_SIZE_FILE_PHOTO_FAVICON) {
      return setErrorUploadPhotoFavicon('O tamanho do favicon deve ser de no máximo 2MB.');
    }

    if (file) {
      setFileFavicon(file);

      setPreviewFavicon(URL.createObjectURL(file));

      const updateFavicon = { ...platformSettingsSEO, faviconLink: file };

      setPlatformSettingsSEO(updateFavicon);

      setHasChange(true);
    }
  }

  function handleDiscardImageFavicon() {
    setPreviewFavicon(null);

    setPlatformSettingsSEO({ ...platformSettingsSEO, faviconLink: null });

    setHasChange(true);
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setPlatformSettingsSEO(prevPost => ({ ...prevPost, [name]: value }));

    setHasChange(true);
  }

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    try {
      setLoading(true);

      let newFileNameThumbnail: string;

      let newFileNameFavicon: string;

      if (fileThumbnail) {
        const { newFileName } = await UploadHelper.upload(fileThumbnail, 'thumbnails');
        newFileNameThumbnail = encodeURI(newFileName);
      }

      if (fileFavicon) {
        const { newFileName } = await UploadHelper.upload(fileFavicon, 'favicons');
        newFileNameFavicon = encodeURI(newFileName);
      }

      const hasThumbnailShareLink = newFileNameThumbnail || platformSettingsSEO.thumbnailShareLink;

      const hasFaviconLink = newFileNameFavicon || platformSettingsSEO.faviconLink;

      const payload = {
        title: platformSettingsSEO?.title,
        description: platformSettingsSEO?.description,
        thumbnailShareLink: !!hasThumbnailShareLink
          ? newFileNameThumbnail || path.basename(platformSettingsSEO.thumbnailShareLink)
          : null,
        faviconLink: !!hasFaviconLink
          ? newFileNameFavicon || path.basename(platformSettingsSEO.faviconLink)
          : null,
      };

      const { data: platformSettingsData } = await PlatformManager.updateSettingsSEO(payload);

      if (platformSettingsData) {
        setPlatformSettingsSEO(platformSettingsData);
      }

      Toast('Configurações atualizadas!', 'success');

      setLoading(false);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Stack direction="column" spacing={5} as="form" onSubmit={onSubmit}>
        <Stack direction="column" spacing={2} alignItems="flex-start">
          <Heading fontSize={{ base: '18px', md: '20px', lg: '20px' }} fontWeight={500}>
            Título do site
          </Heading>
          <Text color="#0000007D" fontSize="14px">
            O título do site aparece nos mecanismos de pesquisa, redes sociais e na guiado
            navegador. Se deixado em branco, ele permanecerá com o nome da Proluno.
          </Text>

          <Stack direction="column" w="100%">
            <Text textAlign="end" color="#0000007D">
              Caracteres: ({platformSettingsSEO.title.length} / 64)
            </Text>
            <Input
              placeholder="Escreva o título"
              focusBorderColor="#EB7129"
              onChange={handleChange}
              name="title"
              value={platformSettingsSEO?.title}
              maxLength={64}
            />
          </Stack>
        </Stack>

        <Stack direction="column" spacing={2} alignItems="flex-start">
          <Heading fontSize={{ base: '18px', md: '20px', lg: '20px' }} fontWeight={500}>
            Descrição do site
          </Heading>
          <Text color="#0000007D" fontSize="14px">
            A descrição do site aparecerá nos mecanismos de pesquisa e quando compartilhado nas
            redes sociais.
          </Text>
          <Stack direction="column" w="100%">
            <Text textAlign="end" color="#0000007D">
              Caracteres: ({platformSettingsSEO.description.length} / 150)
            </Text>
            <Textarea
              maxLength={150}
              resize="none"
              placeholder="Descreva seu site"
              focusBorderColor="#EB7129"
              onChange={handleChange}
              name="description"
              value={platformSettingsSEO?.description}
              rows={4}
            />
          </Stack>
        </Stack>

        <Heading fontSize={{ base: '18px', md: '20px', lg: '20px' }} fontWeight={500}>
          Thumbnail do site
        </Heading>
        <Stack
          spacing={5}
          alignItems={{ base: 'center', md: 'self-start', lg: 'self-start' }}
          direction={{ base: 'column-reverse', md: 'row', lg: 'row' }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            width={{ base: '346px', md: '357px', lg: '357px' }}
            height={{ base: '203px', md: '209px', lg: '209px' }}
            border="1px"
            borderStyle="dashed"
            borderColor="#AEADAD"
            bgColor="#F6F5F5"
          >
            {previewThumbnail === null ? (
              <Image src={SelectImage} />
            ) : (
              <Box
                width="100%"
                height="100%"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundImage={`url("${previewThumbnail}")`}
              />
            )}
          </Stack>

          <Stack direction="column" fontSize="14px" color="#0000007D" fontWeight={400}>
            <Text>A imagem aparecerá quando compartilhada em redes sociais.</Text>
            <Text>Formatos suportados: JPG, PNG*, JPEG</Text>
            <Text>Dimensões: 1920x1080</Text>
            <Text>
              *OBS: caso você escolha o formato “PNG” não use uma Thumbnail na cor branca.
            </Text>

            <Box display={{ base: 'none', md: 'block', lg: 'block' }}>
              {previewThumbnail === null ? (
                <HStack>
                  <SelectImageButton isDisabled={loading} onDrop={handlePhotoChangeThumbnail} />
                </HStack>
              ) : (
                <Button
                  onClick={handleDiscardImageThumbnail}
                  colorScheme="orange"
                  size="sm"
                  width={{ base: 'full', md: 'auto', lg: 'auto' }}
                >
                  Alterar imagem atual
                </Button>
              )}
            </Box>
          </Stack>
        </Stack>

        {errorUploadPhotoThumbnail && (
          <Alert status="error" display="flex" flexDirection="column">
            <AlertTitle>Tamanho máximo excedido</AlertTitle>
            <AlertDescription>{errorUploadPhotoThumbnail}</AlertDescription>
          </Alert>
        )}

        <Box display={{ base: 'block', md: 'none', lg: 'none' }}>
          {previewThumbnail === null ? (
            <HStack>
              <SelectImageButton isDisabled={loading} onDrop={handlePhotoChangeThumbnail} />
            </HStack>
          ) : (
            <Button
              onClick={handleDiscardImageThumbnail}
              colorScheme="orange"
              size="sm"
              w="100%"
              my={3}
            >
              Alterar imagem atual
            </Button>
          )}
        </Box>

        <Heading fontSize={{ base: '18px', md: '20px', lg: '20px' }} fontWeight={500}>
          Favicon do site
        </Heading>

        <Stack
          spacing={5}
          alignItems={{ base: 'center', md: 'self-start', lg: 'self-start' }}
          direction={{ base: 'column-reverse', md: 'row', lg: 'row' }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            width={{ base: '345px', md: '209px', lg: '209px' }}
            height={{ base: '345px', md: '209px', lg: '209px' }}
            border="1px"
            borderStyle="dashed"
            borderColor="#AEADAD"
            bgColor="#F6F5F5"
          >
            {previewFavicon === null ? (
              <Image src={SelectImage} />
            ) : (
              <Box
                width="100%"
                height="100%"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundImage={`url("${previewFavicon}")`}
              />
            )}
          </Stack>

          <Stack
            direction={{ base: 'column-reverse', md: 'column', lg: 'column' }}
            fontSize="14px"
            color="#0000007D"
            fontWeight={400}
          >
            <Text>
              O Favicon é um pequeno ícone que é exibido na aba e na tela inicial dos navegadores de
              internet.
            </Text>
            <Text>Formatos suportados: JPG, PNG*, JPEG</Text>
            <Text>Dimensões: 40x40</Text>
            <Text>*OBS: caso você escolha o formato “PNG” não use um Favicon na cor branca.</Text>

            <Box display={{ base: 'none', md: 'block', lg: 'block' }}>
              {previewFavicon === null ? (
                <HStack>
                  <SelectImageButton isDisabled={loading} onDrop={handlePhotoChangeFavicon} />
                </HStack>
              ) : (
                <Button onClick={handleDiscardImageFavicon} colorScheme="orange" size="sm" my={3}>
                  Alterar imagem atual
                </Button>
              )}
            </Box>
          </Stack>
        </Stack>

        {errorUploadPhotoFavicon && (
          <Alert status="error" display="flex" flexDirection="column">
            <AlertTitle>Tamanho máximo excedido</AlertTitle>
            <AlertDescription>{errorUploadPhotoFavicon}</AlertDescription>
          </Alert>
        )}

        <Box display={{ base: 'block', md: 'none', lg: 'none' }}>
          {previewFavicon === null ? (
            <HStack>
              <SelectImageButton isDisabled={loading} onDrop={handlePhotoChangeFavicon} />
            </HStack>
          ) : (
            <Button onClick={handleDiscardImageFavicon} colorScheme="orange" size="sm" w="100%">
              Alterar imagem atual
            </Button>
          )}
        </Box>
        <Stack w="100%" alignItems={{ base: 'center', md: 'center', lg: 'flex-end' }}>
          <Button
            type="submit"
            w={{ base: '100%', md: 200, lg: 200 }}
            my={{ base: 10, md: 5, lg: 5 }}
            size="sm"
            colorScheme="orange"
            isDisabled={loading || !hasChange}
          >
            {loading ? <Spinner /> : 'Salvar'}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
