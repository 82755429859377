import { CSSProperties } from 'react';
import { FiAlertCircle as AlertIcon } from 'react-icons/fi';

interface IAlertProps {
  message: string;
}

const container: CSSProperties = {
  padding: '4px 6px',
  marginBottom: '16px',
  fontWeight: 600,
  background: '#fce7dc',
  verticalAlign: 'middle',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
};

const alertMessage: CSSProperties = {
  marginLeft: '6px',
  fontSize: '14px',
  fontWeight: 600,
  color: '#eb7129',
};

const Alert = ({ message }: IAlertProps) => {
  return (
    <div style={container}>
      <AlertIcon size={20} color="#eb7129" />
      <span style={alertMessage}>{message}</span>
    </div>
  );
};

export default Alert;
