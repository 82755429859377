import {
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Select as SelectMulti } from 'chakra-react-select';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { IPlan, IShowcase } from '..';
import useFetch from '../../../../../../hooks/useFetch';
import useHandleChange, { TargetType } from '../../../../../../hooks/useHandleChange';
import useHandleSubmit, { Validation } from '../../../../../../hooks/useHandleSubmit';
import { CHAKRA_REACT_SELECT_STYLES } from '../constants';
import { AddPlanSchemaType, AddPlanSchemaValidator } from './AddPlanSchemaValidator';

interface IProps {
  isOpen: boolean;
  selectedShowcase: {
    id: number;
    name: string;
    status: string;
    showcaseViewType: string;
  };
  showcases: IShowcase[];
  onClose: () => void;
  setShowcases: (showcases: IShowcase[]) => void;
}

export function AddPlanModal({
  isOpen,
  selectedShowcase,
  showcases,
  onClose,
  setShowcases,
}: IProps) {
  const [options, setOptions] = useState<{ value: number; label: string }[]>([]);

  const { form, handleChange, setForm } = useHandleChange<AddPlanSchemaType>({
    plansIds: [],
  });

  const {
    data: courseListResponse,
    loading: courseListLoading,
    fetchData: getCourseList,
  } = useFetch<UnificadaFront.ResponseJSON<{ id: number; name: string }[]>>({
    method: 'get',
    url: `/showcase/plans?showcaseId=${selectedShowcase.id}`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (selectedShowcase.id) {
      getCourseList();
    }
  }, [getCourseList, selectedShowcase.id]);

  useEffect(() => {
    const courseListFiltered = courseListResponse?.data?.filter(
      course => !form.plansIds.includes(course.id)
    );

    const options = courseListFiltered?.map(course => ({
      value: course.id,
      label: course.name,
    }));

    setOptions(options);
  }, [courseListResponse?.data, form.plansIds]);

  function afterAddProductsConfirm(response) {
    if (!response.data.data) return;

    const plans = response.data.data.plans as IPlan[];

    const showcaseIndex = showcases.findIndex(
      showcaseData => showcaseData.showcaseId === selectedShowcase.id
    );

    if (showcaseIndex === -1) {
      return onClose();
    }

    const currentShowcases = [...showcases];

    currentShowcases[showcaseIndex].plans = plans;

    setShowcases(currentShowcases);

    setForm({ plansIds: [] });

    onClose();
  }

  function onChange(newValues) {
    if (!newValues || newValues.length === 0) return; // Verificar se há valores novos

    const chosenCourses = form.plansIds;

    const newArrayValue = chosenCourses.concat(newValues.map(newValue => newValue.value));

    const event = {
      target: {
        name: 'plansIds',
        value: newArrayValue,
      },
    };

    handleChange(event as unknown as ChangeEvent<TargetType>);
  }

  function onClickButton(productId) {
    const plansIds = form.plansIds?.filter(course => course !== productId);
    setForm({ ...form, plansIds });
  }

  const payload = {
    ...form,
    showcaseId: selectedShowcase.id,
    showcaseType: 'plans',
    showcaseViewType: selectedShowcase.showcaseViewType,
  };

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit({
    data: payload,
    schemaValidator: AddPlanSchemaValidator,
    url: `/showcase/add-product`,
    method: 'post',
    authenticated: true,
    removeNullProps: false,
    onSuccess: {
      message: 'Planos adicionados com sucesso!',
      callback(response) {
        afterAddProductsConfirm(response);
      },
    },
  });

  const isInvalidSelect = (formValidation?.plansIds as unknown as Validation)?.isInvalid;

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Adicionar planos ({selectedShowcase.name})</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={(formValidation?.plansIds as unknown as Validation)?.isInvalid}>
            <FormLabel>Planos</FormLabel>

            <SelectMulti
              menuPlacement="bottom"
              placeholder="Selecione os planos"
              isMulti={true}
              focusBorderColor="primary.500"
              closeMenuOnSelect={true}
              noOptionsMessage={() => 'Nenhum plano encontrado.'}
              options={options}
              value={[]}
              isLoading={courseListLoading}
              isInvalid={isInvalidSelect}
              onChange={onChange}
              chakraStyles={CHAKRA_REACT_SELECT_STYLES}
            />

            <FormErrorMessage>
              {(formValidation?.plansIds as unknown as Validation)?.message}
            </FormErrorMessage>

            <Stack direction="row" alignItems="center" flexWrap="wrap" flex={1} marginY={2}>
              {form.plansIds.length &&
                form.plansIds.map(productId => (
                  <Flex
                    key={productId}
                    justify="center"
                    align="center"
                    paddingX={2}
                    paddingY={0.5}
                    gap={1}
                    maxWidth="13rem"
                    backgroundColor="#20212325"
                    borderRadius={6}
                  >
                    <Text fontWeight="medium" fontSize="xs" color="#202123" isTruncated>
                      {courseListResponse?.data?.find(course => course.id === productId)?.name}
                    </Text>

                    <CloseButton
                      size="sm"
                      boxSize={4}
                      sx={{ svg: { boxSize: '6.36px' } }}
                      onClick={() => onClickButton(productId)}
                    />
                  </Flex>
                ))}
            </Stack>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button size="sm" onClick={onClose} colorScheme="gray">
              Cancelar
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              color="secondary.500"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Adicionar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
