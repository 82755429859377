import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';

const CHATBOT_LINK =
  'https://api.whatsapp.com/send/?phone=5585992652741&text=Oi,+minha+plataforma+se+encontra+inativa+no+momento&app_absent=0';

interface BlockModalProps extends Partial<ModalProps> {}

export default function BlockModal({ isOpen, onClose }: BlockModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Sua plataforma está <Text as="strong">INATIVA!</Text>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Text>
            Por favor, entre em contato rapidamente para normalizar o seu acesso.{' '}
            <Link href={CHATBOT_LINK} textDecoration="underline !important" fontWeight="semibold">
              Clique aqui
            </Link>{' '}
            para falar com o nosso financeiro.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" variant="ghost" mr={2} onClick={onClose}>
            Fechar
          </Button>

          <Button
            as={Link}
            href={CHATBOT_LINK}
            colorScheme="red"
            _hover={{ textDecoration: 'none', bg: 'red.600' }}
          >
            Falar com o financeiro
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
