import { Box, Button } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

type SelectImageProps = {
  isDisabled: boolean;
  onDrop: (event) => void;
  hiddenBox?: boolean;
  width?: string | number | { base: string | number; md: string | number; lg: string | number };
  height?: string | number;
  size?: string;
};

function SelectImage({
  isDisabled,
  onDrop,
  hiddenBox = false,
  width,
  height,
  size,
}: SelectImageProps) {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'],
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  function handleClick(event) {
    event.preventDefault();
    if (isDisabled) return;
    open();
  }

  return (
    <Box
      {...getRootProps()}
      border={hiddenBox ? 'auto' : '1px dashed  #AEADAD'}
      width={{
        base: hiddenBox ? 'auto' : '344px',
        md: hiddenBox ? 'auto' : '100%',
        lg: hiddenBox ? 'auto' : '100%',
      }}
      height={{
        base: hiddenBox ? 'auto' : '344px',
        md: hiddenBox ? 'auto' : '132px',
        lg: hiddenBox ? 'auto' : '132px',
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginY={hiddenBox ? 0 : 2}
    >
      <input {...getInputProps()} />
      <Button
        colorScheme="orange"
        onClick={handleClick}
        disabled={isDisabled}
        width={width ?? 'auto'}
        height={height ?? 'auto'}
        size={size ?? 'auto'}
      >
        Selecionar Imagem
      </Button>
    </Box>
  );
}

export default SelectImage;
