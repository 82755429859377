import Joi from 'joi';

export type AddAffiliateInProductSchemaType = {
  courseAffiliationId: number;
  userId: number;
};

export const addAffiliateInProductSchemaValidator =
  Joi.object<AddAffiliateInProductSchemaType>().keys({
    courseAffiliationId: Joi.number()
      .required()
      .messages({
        'any.required': 'O produto é obrigatório.',
      } as JoiMessages.LanguageMessages),
    userId: Joi.number()
      .required()
      .messages({
        'any.required': 'O usuário é obrigatório.',
      } as JoiMessages.LanguageMessages),
  });
