import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link, useRouteMatch } from 'react-router-dom';
import { WebhookView } from '../types';
import Events from './Events';

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  IconButton,
  List,
  ListItem,
  ListProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';

interface WebhooksMobileListProps extends ListProps {
  webhooks: Partial<WebhookView>[];
  onDeleteWebhook: (webhookId: number) => void;
  onChangeStatus: (webhookId: number) => Promise<void>;
}

export default function WebhooksMobileList({
  onDeleteWebhook,
  onChangeStatus,
  webhooks,
  ...rest
}: WebhooksMobileListProps) {
  const { url: currenUrl } = useRouteMatch();

  return (
    <List {...rest} spacing={2.5}>
      {webhooks.map(webhook => (
        <Card
          key={webhook.id}
          as={ListItem}
          border="1px solid #2021230D"
          boxShadow="0px 4px 4px 0px #00000040"
        >
          <CardHeader p={4} pb={0}>
            <HStack justify="space-between" align="start">
              <VStack spacing={1} align="start">
                <Heading
                  as="h2"
                  fontSize="md"
                  fontWeight="medium"
                  color="#202123"
                  m={0}
                  isTruncated
                  textTransform="capitalize"
                >
                  {webhook.webhookType === 'product' ? 'Produto' : 'Assinatura'}
                </Heading>
              </VStack>

              <Menu size="sm" arrowPadding={0} placement="bottom-end">
                <MenuButton
                  size="xs"
                  as={IconButton}
                  icon={<MoreIcon size="1.125rem" />}
                  w="27px"
                  h="24px"
                  borderRadius={5}
                  bgColor="#2021231F"
                  _hover={{ bgColor: '#b6b5b5' }}
                  _active={{ bgColor: '#b6b5b5' }}
                />

                <MenuList
                  minWidth="101px"
                  boxShadow="0px 4px 4px 0px #00000040"
                  sx={{
                    '.chakra-menu__menuitem': {
                      fontWeight: 'medium',
                      fontSize: 'xs',
                      color: '#20212380',
                      _hover: { bgColor: '#2021230D' },
                      _focus: { bgColor: '#2021230D' },
                      minWidth: '101px',
                    },
                  }}
                >
                  <MenuItem as={Link} to={`${currenUrl}/edit/${webhook.id}`}>
                    Editar
                  </MenuItem>

                  <MenuItem onClick={() => onChangeStatus(webhook.id)}>
                    {webhook.status === 'ATIVO' ? 'Inativar' : 'Ativar'}
                  </MenuItem>

                  <MenuItem onClick={() => onDeleteWebhook(webhook.id)}>
                    <Text color="#BB2124">Excluir</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </CardHeader>

          <CardBody
            mt={4}
            p={4}
            pt={0}
            color="#20212380"
            sx={{ strong: { fontWeight: 'semibold' } }}
          >
            <List spacing={2}>
              <HStack as={ListItem} justifyContent="space-between">
                <Text as="strong">Webhook:</Text>
                <Text>{webhook.webhookName}</Text>
              </HStack>

              <VStack as={ListItem} align="start">
                <Text as="strong">Eventos:</Text>

                <Events webhookId={webhook.id} events={webhook.events} />
              </VStack>

              <HStack as={ListItem} justifyContent="space-between">
                <Text as="strong">URL:</Text>
                <Text>{webhook.webhookUrl}</Text>
              </HStack>

              <HStack as={ListItem} justifyContent="space-between">
                <Text as="strong">Status:</Text>

                <Text
                  color={webhook.status === 'ATIVO' ? '#22BB33' : '#BB2124'}
                  textTransform="capitalize"
                >
                  {webhook.status.toLowerCase()}
                </Text>
              </HStack>
            </List>
          </CardBody>
        </Card>
      ))}
    </List>
  );
}
