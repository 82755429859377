import { Flex, Heading } from '@chakra-ui/react';

interface Props {
  title: string;
}

function DefaultImageProductList({ title = 'Sem título' }: Props) {
  return (
    <Flex
      align="center"
      justify="center"
      bgColor="primary.500"
      boxSize={{ base: '75px', md: '106px' }}
      borderRadius="lg"
    >
      <Heading size="2xl" color="secondary.500">
        {title.substring(0, 1).toUpperCase()}
      </Heading>
    </Flex>
  );
}

export default DefaultImageProductList;
