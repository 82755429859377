import useFetch from '../../../../hooks/useFetch';

interface useFetchAllCoursesToAffiliationsResponse {
  data: { id: number; name: string }[];
}

export default function useFetchAllCoursesToAffiliations() {
  const {
    data: response,
    loading,
    error,
  } = useFetch<UnificadaFront.ResponseJSON<useFetchAllCoursesToAffiliationsResponse>>({
    method: 'get',
    url: '/affiliations/products/all',
    authenticated: true,
    autoFetch: true,
  });

  const courses = response?.data?.data;

  return {
    courses,
    isLoading: loading,
    error,
  };
}
