import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import AlertIcon from '../../../../../../assets/icons/alertIcon.svg';

export function ModalDeleteInstitution({
  isOpenModalDeleteInstitution,
  onCloseModalDeleteInstitution,
  handleSubmitDeleteInstitution,
  setInstitutionId,
  institutionName,
  setInstitutionName,
  isDeleting,
}) {
  return (
    <Modal
      isOpen={isOpenModalDeleteInstitution}
      onClose={() => {
        setInstitutionId(0);
        setInstitutionName('');
        onCloseModalDeleteInstitution();
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent padding={3}>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5} justifyContent="center" alignItems="center">
            <Image src={AlertIcon} width="40px" />
            <Text fontWeight={700} fontSize="18px">
              Deseja excluir a banca {institutionName}?
            </Text>
          </Stack>
          <Text fontWeight={400} fontSize="14px" color="#20212380" marginY={2} textAlign="center">
            Caso você exclua, todas as questões vinculadas a essa banca serão desvinculadas.
          </Text>
        </ModalBody>

        <ModalFooter justifyContent="center" gap={3}>
          <Button
            colorScheme="gray"
            size="sm"
            onClick={() => {
              setInstitutionId(0);
              setInstitutionName('');
              onCloseModalDeleteInstitution();
            }}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            isLoading={isDeleting}
            isDisabled={isDeleting}
            onClick={() => {
              handleSubmitDeleteInstitution();
              onCloseModalDeleteInstitution();
            }}
          >
            Excluir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
