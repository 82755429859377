import {
  Flex,
  GridItem,
  GridItemProps,
  Image,
  SkeletonText,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MdChevronRight as RightIcon } from 'react-icons/md';

interface PathCardItemProps {
  onClick: (path: string) => void;
  path: string;
  image: string;
  description: string;
  title: string;
  isLoading: boolean;
}

const gridItemProps: GridItemProps = {
  w: 'full',
  h: 'full',
  minH: '5.5rem',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25);',
  rounded: 6,
  pb: 2,
  _hover: {
    outline: '2px solid #EB7129',
    cursor: 'pointer',
  },
};

export default function PathCardItem({
  onClick,
  path,
  image,
  description,
  title,
  isLoading,
}: PathCardItemProps) {
  function handleClick() {
    onClick(path);
  }

  return (
    <GridItem
      onClick={handleClick}
      key={path}
      {...gridItemProps}
      pointerEvents={isLoading ? 'none' : 'auto'}
    >
      <Stack h="full" direction="row" pl={5} spacing={2}>
        {isLoading ? (
          <SkeletonText
            mt="4"
            noOfLines={3}
            spacing="4"
            skeletonHeight="4"
            w="full"
            maxW="96%"
            startColor="orange.400"
            endColor="orange.600"
          />
        ) : (
          <>
            <Image w={25} h={37} src={image} />

            <Flex w="full">
              <VStack flex={11} align="flex-start" spacing={2} m={0}>
                <Text fontSize="md" fontWeight="semibold" pt={4} lineHeight="20px">
                  {title}
                </Text>

                <Text fontSize="xs" lineHeight="14.63px">
                  {description}
                </Text>
              </VStack>

              <Flex flex={1} direction="column" align="flex-start" justify="flex-start" pr={2}>
                <Flex align="center" justify="center" w="full" pt={5}>
                  <RightIcon color="#AFAFAF" />
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </Stack>
    </GridItem>
  );
}
