import { Button, Menu, MenuButton, MenuItem, MenuList, Stack, useToast } from '@chakra-ui/react';
import { ChangeEvent, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SearchInput from './components/SearchInput';
import SortSelect from './components/SortSelect';
import StatusSelect from './components/StatusSelect';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { axiosBackend } from '../../../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../../../helpers/ErrorResponse';

export default function SubscriberManagementFilters() {
  const [isExporting, setIsExporting] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const params = useParams<{ planId: string }>();
  const toast = useToast();

  const planName = (location.state as { planName: string })?.planName;

  function updateUrlSearchParams(key: string, value: string | null) {
    if (value) {
      urlSearchParams.set(key, value);
    } else {
      urlSearchParams.delete(key);
    }

    history.push({ search: urlSearchParams.toString(), state: { planName } });
  }

  function handleSearchChange(value: string) {
    updateUrlSearchParams('search', value || null);
  }

  function handleOrderByChange(event: ChangeEvent<HTMLSelectElement>) {
    updateUrlSearchParams('orderBy', event.target.value || null);
  }

  function handleStatusChange(event: ChangeEvent<HTMLSelectElement>) {
    updateUrlSearchParams('status', event.target.value || null);
  }

  async function handleExport(fileType: 'CSV' | 'Excel') {
    const urlDownload = `/recurrence/${params.planId}/subscriber-list/export?typeFile=${fileType}`;

    try {
      setIsExporting(true);

      const axios = axiosBackend();

      const params = Object.fromEntries(urlSearchParams.entries());

      const response = await axios({
        url: urlDownload,
        method: 'GET',
        responseType: 'blob',
        params,
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');

      link.href = url;

      const fileExtension = fileType === 'CSV' ? 'csv' : 'xlsx';

      link.setAttribute('download', `lista-de-assinantes.${fileExtension}`);

      document.body.appendChild(link);

      link.click();
      link.remove();
    } catch (error) {
      toast({
        title: 'Erro ao exportar lista de assinantes',
        description: ErrorResponse(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsExporting(false);
    }
  }

  return (
    <Stack
      direction={{ base: 'column', xl: 'row' }}
      justify="space-between"
      w="100%"
      pointerEvents={isExporting ? 'none' : 'auto'}
    >
      <SearchInput value="" onSearch={handleSearchChange} />

      <Stack direction={{ base: 'column', xl: 'row' }} w={{ base: '100%', xl: 'initial' }}>
        <SortSelect value={urlSearchParams.get('orderBy')} onChange={handleOrderByChange} />

        <StatusSelect value={urlSearchParams.get('status')} onChange={handleStatusChange} />

        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            size="sm"
            isLoading={isExporting}
            colorScheme="primary"
            color="secondary.500"
            flexShrink={0}
            mr={3}
          >
            Exportar
          </MenuButton>

          <MenuList>
            <MenuItem onClick={() => handleExport('CSV')} fontSize="xs">
              Exportar CSV
            </MenuItem>

            <MenuItem onClick={() => handleExport('Excel')} fontSize="xs">
              Exportar Excel
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
    </Stack>
  );
}
