import { Link } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { routesWithoutValidation } from '../../api/Configuration';
import { useTheme } from '../../contexts/ThemeContext';

const showRoutesButton = ['members-area'];

export default function WhatsAppButton() {
  const { themeMarketplace } = useTheme();
  const [whatsAppLink, setWhatsAppLink] = useState();
  const [whatsAppFloatingButton, setWhatsAppFloatingButton] = useState();

  const location = useLocation();

  let basePath =
    location.pathname
      .split('/')
      .filter(path => path)
      .shift() || '/';

  const matchRoute = routesWithoutValidation.some(route => route.match(basePath));

  useEffect(() => {
    async function getInfo() {
      const themeFooter = themeMarketplace?.themeSections?.filter(
        themeName => themeName.name === 'footer'
      );

      const themeFooterFiltered = themeFooter?.find(footer => footer.themeFooter);

      const getWhatsAppLink = themeFooterFiltered?.themeFooter.urlSocialMediaWhatsapp;
      const getWhatsAppFloatingButton = themeFooterFiltered?.themeFooter.whatsappFloatingButton;

      setWhatsAppLink(getWhatsAppLink);
      setWhatsAppFloatingButton(getWhatsAppFloatingButton);
    }
    getInfo();
  }, [themeMarketplace?.themeSections, whatsAppFloatingButton, whatsAppLink]);

  return whatsAppFloatingButton && whatsAppLink ? (
    <Link
      hidden={!(matchRoute || showRoutesButton.includes(basePath))}
      href={whatsAppLink}
      style={{
        position: 'fixed',
        width: '60px',
        height: '60px',
        bottom: '30px',
        right: '40px',
        backgroundColor: '#25d366',
        color: '#FFF',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '30px',
        boxShadow: '1px 1px 2px #888',
        zIndex: 1000,
      }}
      target="_blank"
      rel="noreferrer"
    >
      <FaWhatsapp />
    </Link>
  ) : (
    <></>
  );
}
