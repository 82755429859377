import dayjs from 'dayjs';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import Skeleton from '../Skeleton';

import { Avatar, Box, Flex, Image, Text } from '@chakra-ui/react';

type Post = {
  id?: number;
  articleName: string;
  subtitle: string;
  userName: string;
  userPhoto: string;
  status: string;
  category: string;
  content: string;
  banner: string;
  createdAt: Date;
  publicationDate: Date;
};

interface ICardProps {
  post: Post;
  isLoading?: boolean;
  hideDate?: boolean;
}

function Card({ post, isLoading, hideDate }: ICardProps) {
  return (
    <div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Link to={`/blog/${post.id}`} title={post.articleName}>
          <Flex
            direction="column"
            width={{ base: '350px', md: '400px', lg: '400px' }}
            height="100%"
            bg="#FFFFFF"
            boxShadow="0px 4px 4px rgba(167, 166, 166, 0.25)"
            borderRadius={10}
          >
            <Box
              width={{ base: '350px', md: '400px', lg: '400px' }}
              height={{ base: '160px', md: '180px', lg: '185px' }}
            >
              <Image
                height="100%"
                width="full"
                objectFit="cover"
                borderTopRadius={10}
                src={post.banner}
                alt="Imagem do post"
                marginBottom={2}
              />
            </Box>

            <Flex direction="column" flex={1} my={5} paddingX={4}>
              <Text
                marginBottom={2}
                noOfLines={2}
                fontWeight="bold"
                fontSize="lg"
                sx={{ hyphens: 'auto' }}
                dangerouslySetInnerHTML={{ __html: post.articleName }}
              />

              <Text
                marginBottom={2}
                noOfLines={5}
                fontSize="md"
                sx={{ hyphens: 'auto' }}
                dangerouslySetInnerHTML={{ __html: post.subtitle }}
              />
            </Flex>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="full"
              paddingX={2}
              paddingBottom={2}
            >
              <Flex align="center">
                <Avatar
                  size="xs"
                  margin={2}
                  src={post.userPhoto}
                  name={post.userName}
                  bgColor="secondary.500"
                  color="primary.500"
                  fontWeight="bold"
                />

                <Text fontWeight="semibold" fontSize="xs" color="#202123">
                  {post.userName}
                </Text>
              </Flex>

              <Text hidden={hideDate} color="gray" marginRight={2} fontSize="xs">
                {dayjs(post.createdAt).format('DD/MM/YYYY HH:mm')}
              </Text>
            </Box>
          </Flex>
        </Link>
      )}
    </div>
  );
}

export default memo(Card);
