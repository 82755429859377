import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Promotion } from '../../types';

const MILLISECONDS_IN_SECOND = 1000;

export type TimeLeftType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

interface PromotionPreview {
  timeLeft: TimeLeftType;
}

function calculateTimeLeft(promotionStarted, promotion): TimeLeftType {
  const today = dayjs();
  const timeToCalculate = dayjs(promotionStarted ? promotion?.startTime : promotion?.endTime);
  const difference = timeToCalculate.diff(today);

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
}

export default function usePreview(promotion: Partial<Promotion>): PromotionPreview {
  const [timeLeft, setTimeLeft] = useState<TimeLeftType>();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(promotion.indicateStartOfPromotion, promotion));
    }, MILLISECONDS_IN_SECOND);

    return () => clearTimeout(timer);
  });

  return { timeLeft };
}
