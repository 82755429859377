import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { FormEvent } from 'react';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { IShowcase } from '..';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedShowcase: {
    id: number;
    name: string;
    status: string;
  };
  showcases: IShowcase[];
  setShowcases: (showcases: IShowcase[]) => void;
}

export function DeleteShowcaseModal({
  isOpen,
  onClose,
  showcases,
  setShowcases,
  selectedShowcase,
}: IProps) {
  function afterDeleteConfirm() {
    if (!selectedShowcase.id) return;

    const updatedShowcases = showcases.filter(
      showcase => showcase.showcaseId !== selectedShowcase.id
    );

    setShowcases(updatedShowcases);

    onClose();
  }

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    url: `/showcase/${selectedShowcase.id}`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Vitrine excluída com sucesso!',
      callback: afterDeleteConfirm,
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'sm', lg: 'lg' }} isCentered>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit} textAlign="center" padding="15px" margin="15px">
        <ModalCloseButton />

        <Box width="full" display="flex" justifyContent="center" color="red.500">
          <IoAlertCircleOutline fontSize="80px" />
        </Box>

        <ModalHeader fontSize="18px" fontWeight={600}>
          Exclusão de vitrine
        </ModalHeader>

        <ModalBody fontSize="14px" fontWeight={500}>
          Tem certeza que deseja excluir a vitrine {selectedShowcase.name}?
        </ModalBody>

        <ModalFooter>
          <Stack
            width="full"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Button colorScheme="red" width="150px" onClick={onClose}>
              Não
            </Button>
            <Button
              colorScheme="gray"
              width="150px"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Sim
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
