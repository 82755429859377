import Container from '../../components/Container/Container';
import { Link } from 'react-router-dom';
import NotAccess from '../../assets/images/not_access.svg';
import Sidebar from '../Sidebar';
import styles from './styles.module.css';

function ModalAccess() {
  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar logo={'../../images/logos/proluno_branca.png'} />
        <Container>
          <div className="column">
            <div className={styles.div_imagem}>
              <img src={NotAccess} alt="Acesso negado" className={styles.imagem} />
            </div>

            <div className={styles.div_text}>
              <h3 className={styles.text_not_access}>
                Desculpe, mas você chegou ao limite do seu plano! Clique no botão abaixo para mudar
                o seu plano e obter o melhor da PROLUNO.
              </h3>
            </div>
            <div className={styles.div_btn}>
              <Link to="/support" className={`btn btn-primary ${styles.btn}`}>
                Quero alterar o meu plano
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ModalAccess;
