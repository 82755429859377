import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Flex, Tag, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { InstructorView } from '../../types';

interface DisciplinesProps {
  instructorId: number;
  disciplines: InstructorView['disciplines'];
}

function getGridColumn(visibleDisciplinesLength: number, currentIndex: number): string {
  if (visibleDisciplinesLength === 3) {
    return currentIndex === 0 ? 'span 2' : 'span 1';
  }

  if (visibleDisciplinesLength === 2) {
    return 'span 2';
  }

  return 'span 1';
}

export default function Disciplines({ instructorId, disciplines }: DisciplinesProps) {
  const visibleDisciplines = disciplines.slice(0, 4);

  return (
    <Flex wrap="wrap" gap={2.5}>
      <>
        {visibleDisciplines.map((discipline, index) => (
          <Tag
            size="sm"
            variant="solid"
            bg="#20212340"
            color="#202123"
            justifyContent="center"
            gridColumn={getGridColumn(visibleDisciplines.length, index)}
          >
            <Text isTruncated>{discipline.name}</Text>
          </Tag>
        ))}

        {disciplines.length > 4 && (
          <Button
            as={Link}
            to={`/instructors/edit/${instructorId}`}
            size="xs"
            variant="ghost"
            color="#759AED"
            fontWeight="normal"
            rightIcon={<ChevronDownIcon />}
            px={0}
            mt={0}
            sx={{ '.chakra-button__icon': { ml: 1 } }}
          >
            Mostrar mais
          </Button>
        )}
      </>
    </Flex>
  );
}
