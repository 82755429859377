import { AspectRatio, Box, Heading, Image, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import aoVivoBackground from '../../../../../assets/images/backgrounds/ao_vivo_background.png';
import { useCourseStudent } from '../../../../../contexts/CourseStudentContextV2';
import { PagingContents } from '../../../components/PagingContents';

function ViewZoom() {
  const { currentContent: content } = useCourseStudent();

  const date = dayjs(content?.broadcastData).utc().format('DD/MM/YYYY');
  const time = dayjs(content?.broadcastData).utc().format('h:mm A');
  const convertDateTimeZone = dayjs(content?.broadcastData).add(3, 'hours');
  const isZoomIframeVisible = dayjs().isSameOrAfter(convertDateTimeZone);

  return (
    <Stack spacing={{ base: '9px', md: '55px', lg: '55px' }}>
      <Stack spacing={{ base: '9px', md: '16px', lg: '16px' }}>
        <Heading
          color="#FFF"
          fontSize={{ base: '18px', md: '24px', lg: '24px' }}
          margin="0"
          fontWeight="500"
        >
          {content?.title}
        </Heading>

        <Stack spacing={{ base: '10px', md: '30px', lg: '30px' }}>
          <AspectRatio maxWidth="100%" ratio={16 / 9}>
            {!isZoomIframeVisible ? (
              <Box>
                <Image
                  height="100%"
                  width="100%"
                  src={aoVivoBackground}
                  alt="Imagem borrada com pessoas em um escritório."
                />
                <Box
                  position="absolute"
                  textAlign="center"
                  color="#FFF"
                  fontSize="24px"
                  fontWeight="600"
                  lineHeight="normal"
                >
                  <Text>Data do evento:</Text>
                  <Text>
                    {date} ás {time}
                  </Text>
                </Box>
              </Box>
            ) : (
              <iframe
                src={content?.link}
                title={content?.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </AspectRatio>

          <PagingContents />
        </Stack>
      </Stack>

      <Box hidden={!content?.content}>
        <Box
          color="#FFF"
          fontSize={{ base: '14px', md: '18px', lg: '18px' }}
          dangerouslySetInnerHTML={{ __html: content?.content }}
          className="ql-editor"
          sx={{
            iframe: {
              width: '100%',
              aspectRatio: '16/9',
            },
            a: { color: '#06c', textDecoration: 'underline' },
            blockquote: { borderLeft: '5px solid #ccc', paddingLeft: '30px' }, // Estilo para citação
          }}
        />
      </Box>
    </Stack>
  );
}

export default ViewZoom;
