import { nanoid } from 'nanoid';
import { ChangeEvent } from 'react';
import Select from '../../../../../../../components/Select';

import { fonts } from '../fonts';

interface ISelectFontsProps {
  name?: string;
  value?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

function SelectFonts({ name, value, onChange }: ISelectFontsProps) {
  return (
    <Select width="18rem" height="2rem" name={name} value={value} onChange={onChange}>
      {fonts.map(font => (
        <>
          <option value="" hidden>
            Selecione uma fonte
          </option>

          <option key={nanoid()} value={font.label}>
            {font.label}
          </option>
        </>
      ))}
    </Select>
  );
}

export default SelectFonts;
