import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import CourseAPI from '../../../../../api/Course';
import Toast from '../../../../../components/Toast';
import { useStudentCourse } from '../../../../../contexts/CourseStudentContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import styles from './styles.module.css';
import { Box } from '@chakra-ui/react';

import 'react-quill/dist/quill.snow.css';
import { scrollbarStyle } from '..';

function ViewText() {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const { studentCourse, getContent } = useStudentCourse();
  const [content, setContent] = useState();

  const { courseId, moduleId, contentId } = useParams();

  useEffect(() => {
    if (studentCourse) {
      const currentContent = getContent(studentCourse, moduleId, contentId);
      if (currentContent) {
        setContent(currentContent);
      }
    }
  }, [contentId, getContent, moduleId, studentCourse]);

  const updateLastVisit = useCallback(async () => {
    try {
      await CourseAPI.updateLastVisit(courseId, moduleId, contentId, 'TEXTO');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }, [contentId, courseId, moduleId]);

  useEffect(() => {
    if (role === 'ALUNO' && studentCourse) {
      updateLastVisit();
    }
  }, [updateLastVisit, role, studentCourse]);

  return (
    <Box
      w="full"
      overflow="auto"
      sx={scrollbarStyle}
      margin={{ base: '0', md: '20px 70px', lg: '20px 70px' }}
    >
      <section className={styles.section__text}>
        <div className="d-flex justify-content-md-center h-100 ">
          <div className="col-sm-12 col-md-10 col-lg-10 p-0 ">
            <div className="card shadow-sm pb-4">
              <div>
                {content && (
                  <div>
                    {content.title !== null && content.title !== '' && (
                      <h3 style={{ fontSize: '30px' }}>{content.title}</h3>
                    )}

                    {content.title === null && content.title === '' && (
                      <h3 style={{ fontSize: '30px' }}>Conteúdo sem título</h3>
                    )}

                    {content.content !== null && content.content !== '' && (
                      <Box
                        className="ql-editor"
                        dangerouslySetInnerHTML={{ __html: content.content }}
                        sx={{
                          iframe: {
                            width: '100%',
                            aspectRatio: '16/9',
                          },
                          a: { color: '#06c', textDecoration: 'underline' },
                        }}
                      />
                    )}

                    {content.content === null && content.content === '' && (
                      <h4>Conteúdo sem descrição</h4>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Box>
  );
}

export default ViewText;
