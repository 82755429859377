import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react';

import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Notifications from './Notifications';
import SEOConfig from './SEOConfig';
import URLSite from './URLSite';

export default function PlatformSettings() {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const tabsData = [
    {
      label: 'Configurações de SEO',
      component: <SEOConfig />,
    },
    {
      label: 'URL do site',
      component: <URLSite />,
    },
    {
      label: 'Notificações',
      component: <Notifications />,
    },
  ];

  function getTabIndex(): number {
    switch (pathname) {
      case '/settings/seo':
        return 0;
      case '/settings/url':
        return 1;
      case '/settings/notifications':
        return 2;
      default:
        return 0;
    }
  }

  function handleTabsChange(index: number) {
    switch (index) {
      case 0:
        push('/settings/seo');
        break;
      case 1:
        push('/settings/url');
        break;
      case 2:
        push('/settings/notifications');
        break;
      default:
        push('/settings/seo');
    }
  }

  const isMobile = useBreakpointValue({ base: true, xl: false });

  // Faz o scroll do tab selecionado ficar no centro da tela no mobile
  useEffect(() => {
    if (isMobile) {
      const activeTabButton = document.querySelector(`[aria-selected="true"]`);
      activeTabButton?.scrollIntoView({ block: 'center', inline: 'center' });
    }
  }, [isMobile, pathname]);

  return (
    <>
      <Heading
        my={{ base: 2, md: 5, lg: 5 }}
        fontSize={{ base: '24px', md: '30px', lg: '36px' }}
        fontWeight="semibold"
      >
        Configurações
      </Heading>

      <Tabs isLazy overflowX="auto" index={getTabIndex()} onChange={handleTabsChange}>
        <TabList
          scrollSnapType="x mandatory"
          overflowX="auto"
          sx={{ '::-webkit-scrollbar': { display: 'none' } }}
        >
          {tabsData.map((tab, index) => (
            <Tab
              key={index}
              _selected={{ color: 'default.500', borderBottomColor: 'default.500' }}
              style={{ outline: 'none', boxShadow: 'none' }}
              color="#20212380"
              flexShrink={0}
              fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
              px={{ base: 3, md: 4 }}
            >
              {tab.label}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabsData.map((tab, index) => (
            <TabPanel py={6} key={index}>
              {tab.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
}
