import { Modal as BootstrapModal, Button } from 'react-bootstrap';

import { MdClose as CloseButton } from 'react-icons/md';
import { memo } from 'react';
import parse from 'html-react-parser';

interface IModalProps {
  show: boolean;
  data: {
    question: string;
    questionText: string;
    commentary: string;
    status: string;
    correctItem: {
      option: string;
      optionText: string;
    };
    answerUser: {
      option: string;
      optionText: string;
    };
  };
  onHide: () => void;
}

function ModalQuestion(props: IModalProps) {
  const { question, questionText, status, correctItem, answerUser } = props.data;

  return (
    <BootstrapModal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <BootstrapModal.Header>
        <BootstrapModal.Title id="contained-modal-title-vcenter">{question}</BootstrapModal.Title>
        <BootstrapModal.Title id="contained-modal-title-vcenter">
          Status:{' '}
          <span style={{ color: status ? '#22BB33' : '#BB2124BF', fontWeight: 700 }}>
            {' '}
            {status ? 'Correto' : 'Incorreto'}
          </span>
        </BootstrapModal.Title>
        <Button
          onClick={props.onHide}
          variant="light"
          style={{
            backgroundColor: 'transparent',
            border: 0,
            fontSize: '1rem',
            marginRight: '-20px',
          }}
        >
          <CloseButton color="#20212380" />
        </Button>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div>
          <h5>Pergunta</h5>
          {parse(String(questionText))}
        </div>
        <div className="mt-4">
          <h5>Item Marcado</h5>
          <div style={{ display: 'flex' }}>
            <span style={{ paddingRight: '5px' }}>{answerUser?.option})</span>
            <span dangerouslySetInnerHTML={{ __html: answerUser?.optionText }} />
          </div>
        </div>
        <div className="mt-4">
          <h5>Item Correto</h5>
          <div style={{ display: 'flex' }}>
            <span style={{ paddingRight: '5px' }}>{correctItem?.option})</span>
            <span dangerouslySetInnerHTML={{ __html: correctItem?.optionText }} />
          </div>
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
}
export default memo(ModalQuestion);
