import { Box, CircularProgress, HStack, Text } from '@chakra-ui/react';

import { memo } from 'react';
import styles from './styles.module.css';

function VideoItem({ video }) {
  return (
    <div className={styles.videoItem}>
      <div
        style={{
          backgroundImage: `url("${video.thumbnail}")`,
        }}
        className={styles.thumbnail}
      ></div>
      <Box>
        <Text>{video.label}</Text>
        <HStack hidden={video.transcode === 'complete' || video.transcode === 'error'}>
          <CircularProgress color="green.400" py="3" isIndeterminate size="25px" />
          <Text>Processando</Text>
        </HStack>
      </Box>
    </div>
  );
}

export default memo(VideoItem);
