import Joi from 'joi';
import { UserProfile } from '../types';

export default Joi.object<UserProfile>().keys({
  fullName: Joi.string().min(2).max(80).required(),
  photo: Joi.string().allow('', null),
  documentNumber: Joi.string().min(11).max(11),
  email: Joi.string().email({ tlds: false }).required().min(2).max(255).messages({
    'string.email': 'Seu e-mail deve ser nesse formato “nome@email.com”.',
    'string.empty': 'O email não pode ficar vazio.',
    'string.base': 'O email não pode ficar vazio.',
    'any.required': 'O email não pode ficar vazio.',
  }),
  biography: Joi.string().allow('', null),
  ddi: Joi.string()
    .min(1)
    .max(5)
    .messages({
      'string.empty': 'Seu ddi não pode ficar vazio.',
      'string.base': 'Seu ddi não pode ficar vazio.',
      'string.min': 'Seu ddi deve ter no mínimo 1 dígitos.',
      'string.max': 'Seu ddi deve ter no máximo 5 dígitos.',
    } as JoiMessages.LanguageMessages),
  ddd: Joi.when('ddi', {
    is: '55',
    then: Joi.string()
      .length(2)
      .required()
      .messages({
        'string.length': 'Deve ser 2 dígitos.',
        'string.empty': 'Informe o DDD.',
        'string.base': 'Informe o DDD.',
        'any.required': 'DDD é obrigatório.',
      } as JoiMessages.LanguageMessages),
    otherwise: Joi.string().optional().allow(null),
  }),
  phone: Joi.string().max(20).required().messages({
    'string.empty': `Seu telefone não pode ficar vazio.`,
    'string.base': `Seu telefone não pode ficar vazio.`,
    'string.max': `O seu telefone deve ter no máximo 20 números.`,
  }),
  country: Joi.string().required().messages({
    'string.empty': `O país não pode ficar vazio.`,
    'string.base': `O país não pode ficar vazio.`,
    'any.required': 'O país não pode ficar vazio.',
  }),
  state: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': `O estado não pode ficar vazio.`,
      'string.base': `O estado não pode ficar vazio.`,
      'any.required': 'O estado não pode ficar vazio.',
    }),
    otherwise: Joi.string().allow(null),
  }),
  city: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': `O campo cidade não pode ficar vazio.`,
      'string.base': `O campo cidade não pode ficar vazio.`,
      'any.required': 'O campo cidade não pode ficar vazio.',
    }),
    otherwise: Joi.string().allow(null),
  }),
  neighborhood: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': `O campo bairro não pode ficar vazio.`,
      'string.base': `O campo bairro não pode ficar vazio.`,
      'any.required': 'O campo bairro não pode ficar vazio.',
    }),
    otherwise: Joi.string().allow(null),
  }),
  streetAddress: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': `O campo rua não pode ficar vazio.`,
      'string.base': `O campo rua não pode ficar vazio.`,
      'any.required': 'O campo rua não pode ficar vazio.',
    }),
    otherwise: Joi.string().allow(null),
  }),
  streetNumber: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': `O campo número não pode ficar vazio.`,
      'string.base': `O campo número não pode ficar vazio.`,
      'any.required': 'O campo número não pode ficar vazio.',
    }),
    otherwise: Joi.string().allow(null),
  }),
  zipCode: Joi.when('country', {
    is: 'br',
    then: Joi.string().required().messages({
      'string.empty': `O CEP não pode ficar vazio.`,
      'string.base': `O CEP não pode ficar vazio.`,
      'any.required': 'O CEP não pode ficar vazio.',
    }),
    otherwise: Joi.string().allow(null),
  }),
  complementary: Joi.string().allow(null),
  alternateAddress: Joi.when('country', {
    is: 'br',
    then: Joi.string().allow(null),
    otherwise: Joi.string().required().messages({
      'string.empty': `O endereço não pode ficar vazio.`,
      'string.base': `O endereço não pode ficar vazio.`,
      'any.required': 'O endereço não pode ficar vazio.',
    }),
  }),
  socialNetwork: Joi.array().items(
    Joi.object()
      .keys({
        type: Joi.string().valid('FACEBOOK', 'INSTAGRAM', 'YOUTUBE', 'LINKEDIN').required(),
        url: Joi.string().required().allow('', null),
      })
      .required()
  ),
  coverBanner: Joi.string().allow(null),
});
