import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { IoIosClose } from 'react-icons/io';
import { IoChevronForward } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import {
  IQuestionLibraryAreaData,
  IQuestionLibraryDisciplineData,
  IQuestionLibrarySubjectData,
} from '../../../types';

interface IParams {
  areaListData: IQuestionLibraryAreaData[];
  disciplineListData: IQuestionLibraryDisciplineData[];
  subjectListData: IQuestionLibrarySubjectData[];
  institutionData: { id: number; name: string }[];
  isLoadingAreaData: boolean;
  isLoadingDisciplineData: boolean;
  isLoadingSubjectData: boolean;
  isLoadingInstitutionData: boolean;
}

const initialStateFilters = {
  area: {
    id: '',
    name: '',
  },
  discipline: {
    id: '',
    name: '',
  },
  subject: {
    id: '',
    name: '',
  },
  institution: {
    id: '',
    name: '',
  },
  search: '',
};

export function HeaderFilter({
  areaListData,
  disciplineListData,
  subjectListData,
  institutionData,
  isLoadingAreaData,
  isLoadingDisciplineData,
  isLoadingSubjectData,
  isLoadingInstitutionData,
}: IParams) {
  const [filters, setFilters] = useState(initialStateFilters);
  const [disciplineListDataFiltered, setDisciplineListDataFiltered] = useState([]);
  const [subjectListDataFiltered, setSubjectListDataFiltered] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setFilters({ ...filters, search: value });
  }

  useEffect(() => {
    if (disciplineListData?.length) {
      setDisciplineListDataFiltered(disciplineListData);
    }
    if (subjectListData?.length) {
      setSubjectListDataFiltered(subjectListData);
    }
  }, [disciplineListData, subjectListData]);

  function onChangeFilters(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;

    if (name === 'areaId') {
      if (!value) {
        setFilters(initialStateFilters);
        setDisciplineListDataFiltered(subjectListData);
        setSubjectListDataFiltered(subjectListData);
        return;
      }

      const area = areaListData.find(area => area.id === parseInt(value));

      const disciplineList = disciplineListData.filter(
        discipline => discipline.area.id === parseInt(value)
      );

      const subjectList = subjectListData.filter(subject => subject.area.id === parseInt(value));
      setDisciplineListDataFiltered(disciplineList);
      setSubjectListDataFiltered(subjectList);

      setFilters({
        ...filters,
        area: {
          ...filters.area,
          id: value,
          name: area.name,
        },
        discipline: {
          ...filters.area,
          id: '',
          name: '',
        },
        subject: {
          ...filters.area,
          id: '',
          name: '',
        },
      });
    }
    if (name === 'disciplineId') {
      if (!value) {
        setFilters({
          ...filters,
          discipline: {
            id: '',
            name: '',
          },
          subject: {
            ...filters.subject,
            id: '',
            name: '',
          },
        });
        setSubjectListDataFiltered(subjectListData);
        return;
      }

      const discipline = disciplineListData.find(discipline => discipline.id === parseInt(value));

      const area = areaListData.find(areaId => areaId.id === discipline.area.id);

      const subjectList = subjectListData.filter(
        subject => subject.discipline.id === parseInt(value)
      );

      setSubjectListDataFiltered(subjectList);

      setFilters({
        ...filters,
        area: {
          id: area.id.toString(),
          name: area.name,
        },
        discipline: {
          id: value,
          name: discipline.name,
        },
      });
    }
    if (name === 'subjectId') {
      if (!value) {
        setFilters({
          ...filters,
          subject: {
            id: '',
            name: '',
          },
        });
        return;
      }
      const subject = subjectListData.find(subject => subject.id === parseInt(value));

      const discipline = disciplineListData.find(
        discipline => discipline.id === subject.discipline.id
      );

      const area = areaListData.find(areaId => areaId.id === discipline.area.id);

      setFilters({
        ...filters,
        area: {
          id: area.id.toString(),
          name: area.name,
        },
        discipline: {
          id: discipline.id.toString(),
          name: discipline.name,
        },
        subject: {
          id: value,
          name: subject.name,
        },
      });

      setDisciplineListDataFiltered([discipline]);
    }

    if (name === 'institutionId') {
      if (!value) {
        setFilters({
          ...filters,
          institution: {
            id: '',
            name: '',
          },
        });
        return;
      }

      const institution = institutionData.find(institution => institution.id === parseInt(value));

      setFilters({
        ...filters,
        institution: {
          id: value,
          name: institution.name,
        },
      });
    }
  }

  function handleClickFiltered() {
    const params: Record<string, string> = {
      page: '1',
      filterAreaId: filters?.area?.id,
      filterDisciplineId: filters?.discipline?.id,
      filterSubjectId: filters?.subject?.id,
      filterInstitutionId: filters?.institution?.id,
      search: filters?.search,
    };

    for (const [key, value] of Object.entries(params)) {
      if (!value) {
        urlSearchParams.delete(key);
        continue;
      }

      if (value) {
        urlSearchParams.set(key, value);
      }
    }

    history.push({ search: urlSearchParams.toString() });
  }

  function removeFilter(filter: string) {
    if (filter === 'area') {
      setFilters({
        ...filters,
        area: {
          ...filters.area,
          id: '',
          name: '',
        },
        discipline: {
          ...filters.discipline,
          id: '',
          name: '',
        },
        subject: {
          ...filters.subject,
          id: '',
          name: '',
        },
      });
    }
    if (filter === 'discipline') {
      setFilters({
        ...filters,
        discipline: {
          ...filters.discipline,
          id: '',
          name: '',
        },
        subject: {
          ...filters.subject,
          id: '',
          name: '',
        },
      });
    }
    if (filter === 'subject') {
      setFilters({
        ...filters,
        subject: {
          ...filters.subject,
          id: '',
          name: '',
        },
      });
    }
    if (filter === 'institution') {
      setFilters({
        ...filters,
        institution: {
          ...filters.institution,
          id: '',
          name: '',
        },
      });
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      backgroundColor="#20212310"
      borderRadius={5}
      padding={5}
    >
      <InputGroup>
        <Input
          placeholder="Busque pelo título da seção"
          backgroundColor="#FFFFFF"
          focusBorderColor="orange.500"
          value={filters.search}
          onChange={handleSearchChange}
        />
        <InputRightElement>
          <BiSearch size={20} color="gray" />
        </InputRightElement>
      </InputGroup>
      <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} spacing={3}>
        <FormControl>
          <FormLabel>Área</FormLabel>
          <Select
            size="sm"
            backgroundColor="#FFFFFF"
            borderRadius={5}
            focusBorderColor="orange.500"
            color="#20212390"
            value={filters.area.id}
            name="areaId"
            onChange={onChangeFilters}
            isDisabled={isLoadingAreaData}
          >
            <option hidden value="">
              Selecione
            </option>

            <option value="">Todas</option>

            {areaListData?.map(areaList => (
              <option value={areaList.id}>{areaList.name}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Disciplina</FormLabel>
          <Select
            size="sm"
            backgroundColor="#FFFFFF"
            borderRadius={5}
            focusBorderColor="orange.500"
            color="#20212390"
            value={filters.discipline.id}
            name="disciplineId"
            onChange={onChangeFilters}
            isDisabled={isLoadingDisciplineData}
          >
            <option hidden value="">
              Selecione
            </option>

            <option value="">Todas</option>

            {disciplineListDataFiltered?.map(disciplineList => (
              <option value={disciplineList.id}>{disciplineList.name}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Assunto</FormLabel>
          <Select
            size="sm"
            backgroundColor="#FFFFFF"
            borderRadius={5}
            focusBorderColor="orange.500"
            color="#20212390"
            value={filters.subject.id}
            name="subjectId"
            onChange={onChangeFilters}
            isDisabled={isLoadingSubjectData}
          >
            <option hidden value="">
              Selecione
            </option>

            <option value="">Todos</option>

            {subjectListDataFiltered?.map(subjectList => (
              <option value={subjectList.id}>{subjectList.name}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Banca</FormLabel>
          <Select
            size="sm"
            backgroundColor="#FFFFFF"
            borderRadius={5}
            focusBorderColor="orange.500"
            color="#20212390"
            value={filters.institution.id}
            name="institutionId"
            onChange={onChangeFilters}
            isDisabled={isLoadingInstitutionData}
          >
            <option hidden value="">
              Selecione
            </option>

            <option value="">Todas</option>

            {institutionData?.map(institutionList => (
              <option value={institutionList.id}>{institutionList.name}</option>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Stack direction="row" justifyContent="space-between" spacing={1} alignItems="center">
        <Stack
          direction="row"
          flex={1}
          spacing={0}
          gap={2}
          alignItems="center"
          width="full"
          flexWrap="wrap"
        >
          <Text fontWeight={600} fontSize="14px">
            Filtrar por:
          </Text>

          <Stack
            direction="row"
            alignItems="center"
            maxWidth={250}
            onClick={() => removeFilter('area')}
            color="#AFAFAF"
            fontWeight={400}
            paddingY="2px"
            paddingX="6px"
            fontSize="12px"
            borderRadius={7}
            border="1px solid #20212380"
            hidden={filters.area.name === ''}
            _hover={{
              fontWeight: 500,
              color: '#EC7117',
              border: '1px solid #EC7117',
              cursor: 'pointer',
              transition: 'all 0.4s ease',
            }}
          >
            <Text isTruncated>{filters.area.name}</Text>
            <IoIosClose size={20} />
          </Stack>

          <Text
            color="#AFAFAF"
            fontWeight={400}
            fontSize="12px"
            hidden={
              (filters.discipline.name === '' && filters.institution.name === '') ||
              filters.area.name === ''
            }
          >
            <IoChevronForward size={15} />
          </Text>

          <Stack
            direction="row"
            alignItems="center"
            maxWidth={250}
            onClick={() => removeFilter('discipline')}
            color="#AFAFAF"
            fontWeight={400}
            paddingY="2px"
            paddingX="6px"
            fontSize="12px"
            border="1px solid #20212380"
            borderRadius={7}
            hidden={filters.discipline.name === ''}
            _hover={{
              fontWeight: 500,
              color: '#EC7117',
              border: '1px solid #EC7117',
              cursor: 'pointer',
              transition: 'all 0.4s ease',
            }}
          >
            <Text isTruncated>{filters.discipline.name}</Text>
            <IoIosClose size={20} />
          </Stack>

          <Text
            color="#AFAFAF"
            fontWeight={400}
            fontSize="12px"
            hidden={
              (filters.subject.name === '' && filters.institution.name === '') ||
              filters.discipline.name === ''
            }
          >
            <IoChevronForward size={15} />
          </Text>

          <Stack
            direction="row"
            alignItems="center"
            maxWidth={250}
            onClick={() => removeFilter('subject')}
            color="#AFAFAF"
            fontWeight={400}
            paddingY="2px"
            paddingX="6px"
            fontSize="12px"
            borderRadius={7}
            border="1px solid #20212380"
            hidden={filters.subject.name === ''}
            _hover={{
              fontWeight: 500,
              color: '#EC7117',
              border: '1px solid #EC7117',
              cursor: 'pointer',
              transition: 'all 0.4s ease',
            }}
          >
            <Text isTruncated>{filters.subject.name}</Text>
            <IoIosClose size={20} />
          </Stack>

          <Text
            color="#AFAFAF"
            fontWeight={400}
            fontSize="12px"
            hidden={
              filters.subject.name === '' ||
              filters.discipline.name === '' ||
              filters.area.name === '' ||
              filters.institution.name === ''
            }
          >
            <IoChevronForward size={15} />
          </Text>

          <Stack
            direction="row"
            maxWidth={250}
            alignItems="center"
            onClick={() => removeFilter('institution')}
            color="#AFAFAF"
            fontWeight={400}
            paddingY="2px"
            paddingX="6px"
            fontSize="12px"
            border="1px solid #20212380"
            borderRadius={7}
            hidden={filters.institution.name === ''}
            _hover={{
              fontWeight: 500,
              color: '#EC7117',
              border: '1px solid #EC7117',
              cursor: 'pointer',
              transition: 'all 0.4s ease',
            }}
          >
            <Text isTruncated>{filters.institution.name}</Text>
            <IoIosClose size={20} />
          </Stack>
        </Stack>

        <Button size="sm" colorScheme="orange" onClick={handleClickFiltered}>
          Filtrar
        </Button>
      </Stack>
    </Box>
  );
}
