import { Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function InstructorsHeader() {
  return (
    <VStack as="header" spacing={5}>
      <Flex justify="space-between" w="full" wrap="wrap">
        <Heading as="h1" fontSize={{ base: '2xl', xl: '4xl' }} fontWeight="semibold" m={0} w="50%">
          Instrutores
        </Heading>

        <Flex order={{ base: 3, xl: 0 }} justify="end" w={{ base: 'full', xl: '50%' }}>
          <Button
            as={Link}
            to="/instructors/new"
            colorScheme="primary"
            color="secondary.500"
            w={{ base: 'full', xl: 'initial' }}
            mt={{ base: 8, xl: 'initial' }}
            size="sm"
          >
            + Cadastrar instrutor
          </Button>
        </Flex>

        <Text
          order={{ base: 2, xl: 'initial' }}
          w="full"
          color="#20212380"
          fontSize={{ base: 'sm', xl: 'lg' }}
          mt={{ base: 2.5, xl: 5 }}
          lineHeight="1.25"
        >
          Cadastre os instrutores de sua plataforma.
        </Text>
      </Flex>
    </VStack>
  );
}
