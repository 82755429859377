import { Flex, FlexProps } from '@chakra-ui/react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import { useEffect } from 'react';
import {
  MdKeyboardArrowLeft as ArrowLeft,
  MdKeyboardArrowRight as ArrowRight,
} from 'react-icons/md';

type PaginateProps = FlexProps & ReactPaginateProps;

export default function Paginate(props: PaginateProps) {
  useEffect(() => {
    // seleciona todos os links da paginação
    const pageLinks = document.querySelectorAll('.react-paginate__container  a');

    // adiciona um zero a esquerda nos números de página menores que 10
    pageLinks.forEach(function (link) {
      const pageNumberText = link.textContent;
      if (pageNumberText !== null) {
        const pageNumber = parseInt(pageNumberText);
        if (pageNumber <= 9) {
          link.textContent = '0' + pageNumber;
        }
      }
    });
  }, []);

  const flexProps = { ...props };
  delete flexProps.pageCount;
  delete flexProps.pageRangeDisplayed;
  delete flexProps.containerClassName;
  delete flexProps.activeLinkClassName;
  delete flexProps.breakLabel;
  delete flexProps.nextLabel;
  delete flexProps.previousLabel;
  delete flexProps.renderOnZeroPageCount;

  const reactPaginateProps = {
    pageCount: props.pageCount,
    pageRangeDisplayed: props.pageRangeDisplayed || 10,
    containerClassName: props.containerClassName,
    activeLinkClassName: props.activeLinkClassName,
    breakLabel: props.breakLabel,
    nextLabel: props.nextLabel,
    previousLabel: props.previousLabel,
    initialPage: props.initialPage ? props.initialPage - 1 : 0,
    onPageChange: props.onPageChange,
    renderOnZeroPageCount: props.renderOnZeroPageCount,
  };

  return (
    <Flex
      sx={{
        '.react-paginate__container': {
          display: 'flex',
          alignItems: 'center',
          listStyle: 'none',
          margin: 'auto',
          color: 'dark.500',
          fontSize: { base: 'xs', lg: 'sm' },
          fontWeight: 'medium',

          li: {
            boxSize: { base: '1.75rem', lg: '2.25rem' },
            borderRadius: 'full',
            overflow: 'hidden',

            ':hover': {
              bg: 'primary.100',
              color: 'secondary.500',
            },

            a: {
              cursor: 'pointer',
              padding: { base: '0.15rem', lg: '0.35rem' },
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },

          '.selected': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bg: 'primary.100',
            color: 'secondary.500',
          },
        },

        '.react-paginate--active-link': {
          fontWeight: 'semibold',
          bg: 'primary.100',
          color: 'secondary.500',
          boxSize: { base: '1.75rem', lg: '2.25rem' },
          borderRadius: 'full',
        },
      }}
      {...flexProps}
    >
      <ReactPaginate
        renderOnZeroPageCount={null}
        breakLabel="..."
        {...reactPaginateProps}
        containerClassName="react-paginate__container"
        activeLinkClassName="react-paginate--active-link"
        nextLabel={<ArrowRight size={26} color="currentColor" />}
        previousLabel={<ArrowLeft size={26} color="currentColor" />}
        disableInitialCallback
      />
    </Flex>
  );
}
