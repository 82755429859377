import React, { useState, useRef } from 'react';
import { IoIosArrowForward as ArrowRigth } from 'react-icons/io';

import { useTheme } from '../../contexts/ThemeContext';

import styles from './styles.module.css';

function Accordion({ title, content }) {
  const [active, setActive] = useState('');
  const [height, setHeight] = useState('0px');

  const containerRef = useRef(null);

  const { themeMarketplace } = useTheme();

  function toggleAccordion() {
    setActive(active === '' ? 'active' : '');
    setHeight(active === 'active' ? '0px' : `${containerRef.current.scrollHeight}px`);
  }

  return (
    <div className={styles.accordionItem}>
      <button
        type="button"
        className={`${styles.accordionButton} ${active}`}
        onClick={toggleAccordion}
        style={{background: themeMarketplace.primaryColor }}
      >
        <ArrowRigth size={24} color={themeMarketplace.textColor} className={active && `${styles.rotate}`} />
        <span style={{color: themeMarketplace.textColor }}>{title}</span>
      </button>
      <div
        ref={containerRef}
        className={styles.accordionItemContainer}
        style={{ maxHeight: height }}
      >
        <div className={styles.accordionContent}>
          <div>{content}</div>
        </div>
      </div>
    </div>
  );
}

export default Accordion;
