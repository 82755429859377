import './SidebarSite.css';

import MenuItem from '../Sidebar/MenuItem';

function SidebarSite() {
  return (
    <div className="nk-sidebar nk-sidebar-fixed is-dark d-xl-none" data-content="sidebarMenu">
      <div className="nk-sidebar-element nk-sidebar-head  mt-5">
        <div className="nk-menu-trigger mr-n2 d-lg-none d-xl-none">
          <a
            href="dashboard/sidebar"
            className="nk-nav-toggle nk-quick-nav-icon"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left"></em>
          </a>
        </div>
      </div>
      <div>
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <div className="nk-sidebar-menu" data-simplebar>
              <ul className="nk-menu">
                {/* Link do menu 
                                    A propriedade ICON(icon) recebe um html
                                */}
                <MenuItem
                  title="Entrar"
                  route="/login/"
                  //icon={<><em className="icon ni ni-dashlite"></em></>}
                />

                <MenuItem
                  title="Quero ensinar"
                  route="/ensine/"
                  //icon={<><em className="icon ni ni-layers-fill"></em></>}
                />

                <MenuItem
                  title="Cursos"
                  route=""
                  //icon={<><em className="icon ni ni-laptop"></em></>}
                />

                <MenuItem
                  title="Professores"
                  route=""
                  //icon={<><em className="icon ni ni-user-alt-fill"></em></>}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarSite;
