import {
  Avatar,
  Box,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  Link as ChakraLink,
} from '@chakra-ui/react';

import { IoChevronDown } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';

import {
  EMAIL_LOCAL_STORAGE,
  NAME_LOCAL_STORAGE,
  PHOTO_LOCAL_STORAGE,
  ROLE_LOCAL_STORAGE,
} from '../../helpers/LocalStorageHelper';
import useExternalLinkHeader from '../../useExternalLinkHeader';

export default function MenuDropdown() {
  const { themeMarketplace } = useTheme();
  const { membersArea, signOut } = useAuth();
  const { pathname } = useLocation();
  const { setColorMode } = useColorMode();
  const externalLink = useExternalLinkHeader();

  const bgColor = useColorModeValue('#ffffff', '#202123');
  const bgColorFullName = useColorModeValue('gray.200', 'gray.900');

  const isStudent = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'ALUNO';
  const isAffiliate = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'AFILIADO';
  const isOwner = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'DONO';
  const fullName = localStorage.getItem(NAME_LOCAL_STORAGE);
  const email = localStorage.getItem(EMAIL_LOCAL_STORAGE);
  const photo = localStorage.getItem(PHOTO_LOCAL_STORAGE);

  const hasNetflixMemberArea = membersArea === 'netflix';
  const isDashboardV2 = hasNetflixMemberArea || pathname.includes('/v2');
  const version = isDashboardV2 ? 'v2' : 'v1';
  const redirectToMemberAreaUrl = isDashboardV2 ? '/members-area/v2' : '/members-area/v1/products';

  function getRoute() {
    if (isAffiliate) return '/dashboard';

    return '/products';
  }

  function handleSignOut() {
    setColorMode('light');
    signOut();
  }

  return (
    <Box width="120px" marginX={5}>
      <Menu>
        <MenuButton>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              bg={themeMarketplace.textColor}
              color={themeMarketplace.primaryColor}
              size="md"
              name={fullName}
              src={photo}
            />

            <IoChevronDown size={20} color={themeMarketplace.textColor} />
          </Stack>
        </MenuButton>
        <MenuList bgColor={bgColor} zIndex="9999">
          <Box padding="10px 20px" bgColor={bgColorFullName}>
            <Text>{fullName}</Text>
            <Text fontSize="xs">{email}</Text>
          </Box>

          {!isStudent &&
            !isOwner &&
            (externalLink ? (
              <MenuItem
                as={ChakraLink}
                href={externalLink}
                style={{ textDecoration: 'none' }}
                fontWeight={700}
                padding="10px 20px"
              >
                Página Inicial
              </MenuItem>
            ) : (
              <MenuItem as={Link} to="/" fontWeight={700} padding="10px 20px">
                Página Inicial
              </MenuItem>
            ))}

          {isOwner && (
            <>
              {externalLink ? (
                <MenuItem
                  as={ChakraLink}
                  href={externalLink}
                  style={{ textDecoration: 'none' }}
                  fontWeight={700}
                  padding="10px 20px"
                >
                  Acessar Homepage
                </MenuItem>
              ) : (
                <MenuItem as={Link} to="/" fontWeight={700} padding="10px 20px">
                  Acessar Homepage
                </MenuItem>
              )}
              <MenuItem as={Link} to={'/home'} fontWeight={700} padding="10px 20px">
                Início
              </MenuItem>
            </>
          )}

          {!isStudent && (
            <MenuItem as={Link} to={getRoute()} fontWeight={700} padding="10px 20px">
              {isAffiliate ? 'Dashboard' : 'Meus Produtos'}
            </MenuItem>
          )}

          {isOwner && (
            <MenuItem as={Link} to={'/profile/user'} fontWeight={700} padding="10px 20px">
              Meu Perfil
            </MenuItem>
          )}

          {isStudent && (
            <>
              <MenuItem as={Link} to={redirectToMemberAreaUrl} fontWeight={700} padding="10px 20px">
                Meus Produtos
              </MenuItem>
              <MenuItem
                as={Link}
                to={`/members-area/${version}/payments`}
                fontWeight={700}
                padding="10px 20px"
              >
                Pagamentos
              </MenuItem>

              <MenuItem
                as={Link}
                to={`/members-area/${version}/questions`}
                fontWeight={700}
                padding="10px 20px"
              >
                Questões
              </MenuItem>
              <MenuItem
                hidden={isDashboardV2}
                as={Link}
                to={`/members-area/v1/statistics`}
                fontWeight={700}
                padding="10px 20px"
              >
                Estatísticas
              </MenuItem>
              <MenuItem
                hidden
                as={Link}
                to={`/members-area/v1/reports`}
                fontWeight={700}
                padding="10px 20px"
              >
                Relatórios
              </MenuItem>

              <MenuItem
                as={Link}
                to={`/members-area/${version}/profile/user`}
                fontWeight={700}
                padding="10px 20px"
              >
                Perfil
              </MenuItem>
            </>
          )}

          <MenuDivider />

          <MenuItem onClick={handleSignOut} fontWeight={700} padding="10px 20px" color="red.500">
            Encerrar sessão
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
