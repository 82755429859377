import {
  Box,
  Stack,
  Step,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
  MdAddShoppingCart,
  MdAttachMoney,
  MdDoDisturb,
  MdLocalOffer,
  MdOutlineAccountCircle,
  MdOutlineDeviceUnknown,
  MdOutlineInsertDriveFile,
  MdOutlinePageview,
  MdOutlinePix,
  MdOutlineShoppingCart,
  MdOutlineStickyNote2,
} from 'react-icons/md';
import { ConversionType } from '..';

function getIconByType(type: ConversionType) {
  switch (type) {
    case 'ADD_TO_CART':
      return <MdAddShoppingCart size={24} />;

    case 'PAGE_VIEW':
      return <MdOutlinePageview size={24} />;

    case 'PURCHASE':
      return <MdAttachMoney size={24} />;

    case 'FREE_SIGN_UP':
      return <MdLocalOffer size={24} />;

    case 'EXTERNAL_FORM':
      return <MdOutlineStickyNote2 size={24} />;

    case 'REGISTER_DEFAULT':
      return <MdOutlineAccountCircle size={24} />;

    case 'REGISTER_CHECKOUT':
      return <MdOutlineAccountCircle size={24} />;

    case 'REGISTER_QUESTION':
      return <MdOutlineAccountCircle size={24} />;

    case 'ISSUANCE_BOLETO':
      return <MdOutlineInsertDriveFile size={24} />;

    case 'ISSUANCE_PIX':
      return <MdOutlinePix size={24} />;

    case 'PURCHASE_REFUSED':
      return <MdDoDisturb size={24} />;

    case 'BEGIN_CHECKOUT':
      return <MdOutlineShoppingCart size={24} />;

    default:
      return <MdOutlineDeviceUnknown size={24} />;
  }
}

export default function LeadList({ conversions, isMobile }) {
  return (
    <Stepper
      index={conversions?.length}
      orientation="vertical"
      gap={{ base: '12px', lg: '20px' }}
      size="lg"
      colorScheme="default"
      width="full"
    >
      {conversions?.map((event, index) => (
        <Step key={index} style={{ width: '95%' }}>
          <StepIndicator>
            <StepStatus complete={getIconByType(event.type)} />
          </StepIndicator>

          <Box flexShrink="0" width="full">
            <StepTitle>
              <Stack
                direction={{ base: 'column', lg: 'row' }}
                width="full"
                fontSize="16px"
                justify="space-between"
                marginTop={{ base: '5px', lg: '7px' }}
                spacing={{ base: '15px', lg: '50px' }}
              >
                <Text width={{ base: '90%', lg: '70%' }} noOfLines={{ base: 2, lg: 1 }}>
                  {event.name}
                </Text>
                <Text
                  color="#20212380"
                  width={{ base: 'full', lg: '280px' }}
                  textAlign={{ base: 'start', lg: 'end' }}
                  fontSize={{ base: '14px', lg: '16px' }}
                >
                  {dayjs(event.createdAt).format('DD/MM/YYYY hh:mm:ss').concat(' (GMT -03:00)')}
                </Text>
              </Stack>
            </StepTitle>
          </Box>

          <StepSeparator
            style={{
              maxHeight: 'none',
              height: isMobile ? '80px' : '20px',
              width: '1px',
              top: '40px',
            }}
          />
        </Step>
      ))}
    </Stepper>
  );
}
