import { MdEdit } from 'react-icons/md';
import styles from './styles.module.css';

interface IProps {
  preview: string;
  onClick: () => void;
}

function ImagePreview({ preview, onClick }: IProps) {
  return (
    <div className={styles.container}>
      <div style={{ backgroundImage: `url("${preview}")` }} className={styles.image} />
      <button onClick={onClick} className={`${styles.headerLogoButton}`}>
        <MdEdit />
        Alterar
      </button>
    </div>
  );
}

export default ImagePreview;
