import { useEffect, useState } from 'react';

import BannerDesktop from './BannerDesktop';
import BannerMobile from './BannerMobile';
import { useTheme } from '../../../../contexts/ThemeContext';
import useWindowSize from '../../../../hooks/useWindowSize';

type IBannerHeroItem = {
  id: number;
  title: string;
  link: string;
  banner: string;
};

interface IBannerHeroSection {
  active: boolean;
  name: string;
  order: number;
  sectionsToShow: number;
  themeBannerHero: IBannerHeroItem[];
}
const LARGE_SCREEN_SIZE = 768;

function BannerSection({ section }) {
  const { width } = useWindowSize();

  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  const [bannerHeroSection, setBannerHeroSection] = useState({} as IBannerHeroSection);

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      setBannerHeroSection(section);
    }
  }, [themeMarketplace, isLoadingThemeMarketplace, section]);

  if (!bannerHeroSection.active) return '';
  return (
    <>
      {width > LARGE_SCREEN_SIZE && (
        <BannerDesktop bannerHeroSection={bannerHeroSection} themeMarketplace={themeMarketplace} />
      )}
      {width < LARGE_SCREEN_SIZE && (
        <BannerMobile bannerHeroSection={bannerHeroSection} themeMarketplace={themeMarketplace} />
      )}
    </>
  );
}

export default BannerSection;
