import { Icon, IconProps } from '@chakra-ui/react';

export default function SearchIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 19 19" {...props}>
      <path
        d="M14.9604 13.2452H14.057L13.7368 12.9365C14.8575 11.6329 15.5321 9.9405 15.5321 8.09944C15.5321 3.99424 12.2045 0.666626 8.09932 0.666626C3.99412 0.666626 0.666504 3.99424 0.666504 8.09944C0.666504 12.2046 3.99412 15.5323 8.09932 15.5323C9.94037 15.5323 11.6328 14.8576 12.9364 13.737L13.2451 14.0571V14.9605L18.9627 20.6666L20.6665 18.9628L14.9604 13.2452ZM8.09932 13.2452C5.25198 13.2452 2.95353 10.9468 2.95353 8.09944C2.95353 5.2521 5.25198 2.95365 8.09932 2.95365C10.9467 2.95365 13.2451 5.2521 13.2451 8.09944C13.2451 10.9468 10.9467 13.2452 8.09932 13.2452Z"
        fill="currentColor"
      />
    </Icon>
  );
}
