import Joi from 'joi';
import { ICredentials } from '.';

const schemaValidator = Joi.object<ICredentials>({
  clientId: Joi.string()
    .required()
    .messages({
      'any.required': 'Digite o Client ID.',
      'string.empty': 'Digite o Client ID.',
    } as JoiMessages.LanguageMessages),
  clientSecret: Joi.string()
    .required()
    .messages({
      'any.required': 'Digite o Client Secret.',
      'string.empty': 'Digite o Client Secret.',
    } as JoiMessages.LanguageMessages),
});

export default schemaValidator;
