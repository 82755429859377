import { useEffect, useState } from 'react';

import { MdEdit } from 'react-icons/md';
import ThemeAPI from '../../../../api/Theme';
import CustomDropzone from '../../../../components/CustomDropzone';
import { Heading } from '../../../../components/Heading';
import InputCheckbox from '../../../../components/InputCheckbox';
import Spinner from '../../../../components/Loadings/Spinner';
import Toast from '../../../../components/Toast';
import { useTheme } from '../../../../contexts/ThemeContext';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../helpers/UploadHelper';
import ErrorMessage from '../components/ErrorMessage';
import Line from '../components/Line';
import Tooltip from '../components/Tooltip';
import Section from '../Section';
import styles from './styles.module.css';

const FIRST_FILE = 0;

const MAX_SIZE_FILE_LOGO = 3145728;

function getMenus(theme) {
  const header = theme.themeSections.find(section => section.name === 'header');
  const menus = header.themeHeaderMenu.filter(menu => !menu.path.match(/\/free-contents/));
  return menus;
}

function Header() {
  const [logo, setLogo] = useState(null);
  const [menus, setMenus] = useState([]);
  const [preview, setPreview] = useState(null);

  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorUploadPhoto, setErrorUploadPhoto] = useState(null);

  const {
    theme,
    isLoading: isLoadingTheme,
    errorMessage,
    setErrorMessage,
    getThemeMarketplace,
  } = useTheme();

  const shouldDisplayLoader = !errorMessage && isLoadingTheme;

  useEffect(() => {
    if (errorMessage) {
      Toast(errorMessage, 'error');
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!isLoadingTheme) {
      const menus = getMenus(theme);

      setMenus(menus);

      setPreview(theme.logo);
      setLogo(theme.logo);
    }
  }, [isLoadingTheme, theme]);

  function handleDropFile(acceptedFiles) {
    const file = acceptedFiles[FIRST_FILE];

    setErrorMessage(null);
    setHasChanged(true);

    if (file?.size > MAX_SIZE_FILE_LOGO) {
      return setErrorUploadPhoto('O tamanho da imagem deve ser de no máximo 3 MB.');
    }

    setLogo(file);
    setPreview(URL.createObjectURL(file));
  }

  function handleMenusChange(event) {
    const { name, checked } = event.target;

    setHasChanged(true);

    const selectedMenu = menus.find(menu => menu.name === name);
    const changedMenu = { ...selectedMenu, active: checked };

    const updatedMenus = menus.map(menu => (menu.name === name ? changedMenu : menu));

    setMenus(updatedMenus);
  }

  function handleImageChange() {
    setHasChanged(true);
    setLogo(null);
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    setHasChanged(false);

    setLogo(theme.logo);
    setPreview(theme.logo);

    const menus = getMenus(theme);
    setMenus(menus);

    setErrorMessage(null);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setErrorMessage(null);
    setIsLoading(true);

    if (logo.size > MAX_SIZE_FILE_LOGO) {
      return setErrorMessage('O tamanho da imagem deve ser de no máximo 3 MB.');
    }

    try {
      const menusObject = menus.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.active }), {});

      let payload = {
        ...menusObject,
        logo,
      };

      if (logo && typeof logo !== 'string') {
        const { newFileName, link } = await UploadHelper.upload(payload.logo, 'logos');

        payload.logo = encodeURI(newFileName);

        await ThemeAPI.sectionHeader(theme.id, payload);

        setLogo(link);
      } else {
        delete payload.logo;
        await ThemeAPI.sectionHeader(theme.id, payload);
      }

      await getThemeMarketplace();
      Toast('Menu superior atualizado com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setHasChanged(false);
      setIsLoading(false);
    }

    setHasChanged(false);
  }

  function PreviewLogo() {
    return (
      <div
        className="d-flex flex-column align-center justify-content-between mt-2 p-4"
        style={{
          background: theme.primaryColor,
        }}
      >
        <img src={preview} alt="" style={{ maxHeight: '132px' }} className={styles.logoWrapper} />

        <button onClick={handleImageChange} className={styles.headerLogoButton}>
          <MdEdit />
          Alterar
        </button>
      </div>
    );
  }

  return (
    <Section>
      <div className={styles.sectionHeader}>
        <div className="d-flex align-items-center">
          <Heading as="h4" fontSize="2xl">
            Menu superior
          </Heading>
          <Line />
          <Tooltip link="https://youtu.be/4mPP6hT3yiw" />
        </div>
      </div>
      <div className={styles.headerContainer}>
        <form onSubmit={handleSubmit}>
          <div>
            <section className={styles.headerLogo}>
              {shouldDisplayLoader && (
                <div className="align-self-center justify-center">
                  <Spinner color={theme.textColor} />
                </div>
              )}

              {!shouldDisplayLoader && (
                <>
                  <h4>Logo</h4>

                  <div className="form-group">
                    <CustomDropzone
                      file={logo}
                      onDropRejected={() => setErrorMessage('Arquivo inválido!')}
                      onDrop={handleDropFile}
                      accept={['image/jpg', 'image/png', 'image/jpeg']}
                      extensions="jpg, pgn, jpeg. Dimensões: 132x64"
                      maxFileSize="3 MB "
                      Preview={PreviewLogo}
                    />

                    {errorUploadPhoto && <ErrorMessage message={errorUploadPhoto} />}
                  </div>
                </>
              )}
            </section>
            <section className={styles.headerMenus}>
              <h4>Itens do menu</h4>
              <p>Selecione os itens que vão compor seu menu superior.</p>

              <div>
                {menus &&
                  menus.length > 0 &&
                  menus.map(menu => (
                    <div key={`menu${menu.id}`}>
                      <InputCheckbox
                        id={menu.name}
                        name={menu.name}
                        onChange={handleMenusChange}
                        checked={menu.active}
                      >
                        <label htmlFor={menu.name}>{menu.label}</label>
                      </InputCheckbox>
                    </div>
                  ))}
              </div>
            </section>
          </div>

          <div className={styles.formFooter}>
            <div>
              <button
                onClick={handleDiscardChanges}
                disabled={!hasChanged | isLoadingTheme | isLoading}
              >
                Cancelar
              </button>

              <button type="submit" disabled={!hasChanged || isLoading}>
                {isLoading ? <Spinner small /> : 'Salvar alterações'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Section>
  );
}

export default Header;
