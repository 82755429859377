import { useEffect, useState } from 'react';
import BanksApi from '../../api/Banks';

const sortBanksByCode = (bankA, bankB) =>
  bankA.code < bankB.code ? -1 : bankA.code > bankB.code ? 1 : 0;

export const useGetRemoteBanks = () => {
  const [error, setError] = useState();
  const [banks, setBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getBanks() {
      try {
        const { data: banksData } = await BanksApi.index();
        setBanks(banksData.sort(sortBanksByCode));
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }

    getBanks();
  }, []);

  return { banks, isLoading, error };
};
