/* eslint-disable no-undef */
import { useEffect } from 'react';
import { IS_COOKIES_ACCEPTED } from '../helpers/LocalStorageHelper';

function isCookiesAccepted() {
  return localStorage.getItem(IS_COOKIES_ACCEPTED) !== 'false';
}

const useFacebookPixel = pixelDataArray => {
  useEffect(() => {
    if (!isCookiesAccepted()) return;

    const currentHostname = window.location.hostname;

    const loadFacebookPixel = pixelData => {
      const { pixelId, event, hostname } = pixelData;

      if (hostname !== currentHostname) return;

      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        t.id = `facebook-pixel-${pixelId}`;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', pixelId);
      fbq('track', event);
    };

    for (const pixelData of pixelDataArray) {
      loadFacebookPixel(pixelData);
    }

    return () => {
      for (const { pixelId } of pixelDataArray) {
        const pixelScript = document.querySelector(`#facebook-pixel-${pixelId}`);
        if (pixelScript) pixelScript.remove();

        const scriptsWithPixelId = document.querySelectorAll(`script[src*="${pixelId}"]`);

        for (const script of scriptsWithPixelId) {
          script.remove();
        }
      }

      delete window.fbq;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useFacebookPixel;
