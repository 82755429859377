import { Flex, Circle, Image } from '@chakra-ui/react';
import pixQrCodePlaceholder from '../../../../../../assets/images/QRCode.png';

export default function PixPlaceHolder() {
  return (
    <Flex position="relative" align="center" justify="center">
      <Image src={pixQrCodePlaceholder} />
      <Circle
        position="absolute"
        size="8.25rem"
        bg="default.500"
        color="white"
        textAlign="center"
        fontSize="xs"
      >
        Clique no botão “Gerar Qr code” liberar o código
      </Circle>
    </Flex>
  );
}
