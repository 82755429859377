import { ReactComponent as Facebook } from '../../../../assets/icons/social/facebook.svg';
import { ReactComponent as Linkedin } from '../../../../assets/icons/social/linkedin.svg';
import { ReactComponent as Telegram } from '../../../../assets/icons/social/telegram.svg';
import { ReactComponent as Twitter } from '../../../../assets/icons/social/twitter.svg';
import { ReactComponent as Whatsapp } from '../../../../assets/icons/social/whatsapp.svg';
import { useTheme } from '../../../../contexts/ThemeContext';
import styles from './styles.module.css';

function SocialLinks() {
  const { themeMarketplace } = useTheme();

  return (
    <div className={styles.container}>
      <h5>Compartilhe:</h5>

      <ul className={styles.socialLinksItem}>
        <li style={{ background: themeMarketplace.primaryColor }}>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u"
            title="facebook"
            target="__blank"
            rel="noopener noreferrer"
          >
            <Facebook color={themeMarketplace.textColor} width={20} height={20} />
          </a>
        </li>
        <li style={{ background: themeMarketplace.primaryColor }}>
          <a
            href="https://telegram.me/share/url?url"
            title="facebook"
            target="__blank"
            rel="noopener noreferrer"
          >
            <Telegram color={themeMarketplace.textColor} width={20} height={20} />
          </a>
        </li>
        <li style={{ background: themeMarketplace.primaryColor }}>
          <a
            href="https://www.linkedin.com/shareArticle?mini=true&url"
            title="facebook"
            target="__blank"
            rel="noopener noreferrer"
          >
            <Linkedin color={themeMarketplace.textColor} width={20} height={20} />
          </a>
        </li>
        <li style={{ background: themeMarketplace.primaryColor }}>
          <a
            href="https://twitter.com/intent/tweet?url"
            title="facebook"
            target="__blank"
            rel="noopener noreferrer"
          >
            <Twitter color={themeMarketplace.textColor} width={20} height={20} />
          </a>
        </li>
        <li style={{ background: themeMarketplace.primaryColor }}>
          <a
            href="https://api.whatsapp.com/send?text"
            title="facebook"
            target="__blank"
            rel="noopener noreferrer"
          >
            <Whatsapp color={themeMarketplace.textColor} width={20} height={20} />
          </a>
        </li>
        {/* <a
                href={`${link.url}=${currentURL}`}
                title={link.label}
                target="__blank"
                rel="noopener noreferrer"
              >
                
              </a> */}
      </ul>
    </div>
  );
}

export default SocialLinks;
