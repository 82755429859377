import { BoxProps, HStack, Image, Stack, Text } from '@chakra-ui/react';
import path from 'path';
// import { ReactComponent as Download } from '../../assets/icons/download.svg';
import pdf from '../../assets/icons/pdf.svg';

interface IFileDownloadProps extends BoxProps {
  name: string;
  highlightColor: string;
}

export default function FileDownloadStudent({ name, highlightColor }: IFileDownloadProps) {
  // const { courseId, moduleId, contentId } = useParams<IParams>();

  // const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
  // const link = `${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${token}`;

  // const isMobile = useBreakpointValue({ base: true, md: false, lg: false });

  // const widthIcon = isMobile ? '10px' : '17px';
  // const heightIcon = isMobile ? '13px' : '21px';

  return (
    <>
      <HStack
        width="full"
        padding="22px"
        borderRadius="5px"
        background="#37393D"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        style={{ textDecoration: 'none' }}
        sx={{ '&:hover svg': { fill: highlightColor, transition: '0.15s ease-in-out' } }}
        // cursor="pointer"
        // as={Link}
        // href={link}
        // download
        // target="_blank"
        justifyContent="space-between"
      >
        <HStack width="full">
          <Image src={pdf} alt="arquivo" boxSize={{ base: '43px', lg: '70px' }} />

          <Stack justifyContent="flex-start" spacing="8px">
            <Text
              title={name}
              width={{ base: '200px', md: '400px', lg: '500px' }}
              fontSize={{ base: '11.072px', lg: '18px' }}
              fontWeight="500"
              color="#F3F3F3"
              noOfLines={2}
            >
              {path.basename(decodeURI(name))}
            </Text>

            {/* <Text color="#979CA6" fontSize={{ base: '8.612px', lg: '14px' }} fontWeight="500">
              5,9 MB
            </Text> */}
          </Stack>
        </HStack>

        {/* <Download fill="#F3F3F3" width={widthIcon} height={heightIcon} /> */}
      </HStack>
    </>
  );
}
