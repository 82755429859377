import { Box, Button, Flex, Heading, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { ChangeEvent, FormEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toQueryString } from '../../../../helpers/ToQueryString';
import useFetch from '../../../../hooks/useFetch';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import useQuery from '../../../../hooks/useQuery';
import useWindowSize from '../../../../hooks/useWindowSize';
import AffiliatesCard from './AffiliatesCard';
import AffiliatesTable from './AffiliatesTable';
import CourseFilterSelect from './CourseFilterSelect';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import FilterSelect from './FilterSelect';
import FiltersMobile from './FiltersMobile';
import Pagination from './Pagination';
import SearchInput from './SearchInput';
import { Affiliate } from './types';
import useFetchAllCoursesToAffiliations from './useFetchAllCoursesToAffiliations';

interface AffiliateResponse {
  data: Affiliate[];
  total: number;
  per_page: number;
  page: number;
}

export default function Affiliates() {
  const query = useQuery();
  const queryString = query.toString();

  const orderByQuery = query.get('orderBy') || 'newer';
  const searchQuery = query.get('search');
  const courseIdQuery = Number(query.get('courseId'));
  const pageQuery = Number(query.get('page'));

  const [filter, setFilter] = useState(orderByQuery);
  const [search, setSearch] = useState(searchQuery);
  const [selectedCourse, setSelectedCourse] = useState<number | null>(courseIdQuery);
  const [page, setPage] = useState(pageQuery);
  const [percentage, setPercentage] = useState<number>(0);

  const [affiliates, setAffiliates] = useState<Affiliate[]>();
  const [selectedAffiliate, setSelectedAffiliate] = useState<Affiliate | null>(null);
  const [editing, setEditing] = useState<boolean>(false);

  const history = useHistory();

  const { width } = useWindowSize();

  const {
    isOpen: isDeleteModalOpen,
    onClose: onCloseDeleteModal,
    onOpen: onOpenDeleteModal,
  } = useDisclosure();

  const {
    isOpen: isUpdateModalOpen,
    onClose: onCloseUpdateModal,
    onOpen: onOpenUpdateModal,
  } = useDisclosure();

  const {
    data: response,
    loading: isLoading,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<AffiliateResponse>>({
    method: 'get',
    url: `/affiliates?${queryString}`,
    authenticated: true,
  });

  const { courses, isLoading: isCoursesLoading } = useFetchAllCoursesToAffiliations();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  useEffect(() => {
    setAffiliates(response?.data?.data);
  }, [response?.data?.data]);

  const { handleSubmit: removeAffiliation, isLoading: isDeleting } = useHandleSubmit({
    method: 'delete',
    url: `/affiliations/affiliates/${selectedAffiliate?.affiliationId}/affiliation`,
    onSuccess: {
      message: `Afiliado removido com sucesso`,
    },
    authenticated: true,
  });

  const { handleSubmit: editAffiliation, isLoading: isEditing } = useHandleSubmit({
    method: 'patch',
    url: `affiliations/${selectedAffiliate?.affiliationId}/edit-split-affiliation`,
    data: { percentage: percentage },
    onSuccess: {
      message: `Afiliação atualizada com sucesso`,
    },
    authenticated: true,
  });

  function handleFilterChange(value: string) {
    setFilter(value);
  }

  function handleSelectAffiliate(id: number) {
    setSelectedAffiliate(affiliates.find(affiliate => affiliate.affiliationId === id) || null);
  }

  function handleDelete(id: number) {
    handleSelectAffiliate(id);
    onOpenDeleteModal();
  }

  async function handleConfirmDelete() {
    await removeAffiliation();
    const deleteFilter = ({ affiliationId }) => affiliationId !== selectedAffiliate?.affiliationId;
    setAffiliates(prev => prev.filter(deleteFilter));
    onCloseDeleteModal();
    setSelectedAffiliate(null);
    setPage(1);
    history.push({
      search: queryString,
    });
  }

  function handleEdit(id: number) {
    handleSelectAffiliate(id);
    setEditing(true);
    onOpenUpdateModal();
  }

  async function handleConfirmEdit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    await editAffiliation();
    onCloseUpdateModal();
    setSelectedAffiliate(null);
    setEditing(false);
  }

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  async function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    history.push({
      search: toQueryString({
        search,
        orderBy: filter,
        courseId: selectedCourse,
        page: newPage,
      }),
    });

    setPage(newPage);
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleFilterButtonClick();
    }
  }

  function handleCourseFilterChange(courseId: number) {
    setSelectedCourse(courseId);
  }

  async function handleFilterButtonClick() {
    setPage(1);

    history.push({
      search: toQueryString({ search, orderBy: filter, courseId: selectedCourse, page: 1 }),
    });
  }

  const pageCount = response?.data?.total / response?.data?.per_page;

  const isLoaderShow = isLoading;
  const isNoResultsShow = !isLoading && !affiliates?.length;
  const isShowAffiliates = !isLoading && affiliates?.length > 0;
  const showTable = width > 750;
  const showCard = width <= 750;
  const isShowPagination = pageCount > 1 && !isLoading && response?.data?.total >= 10;

  return (
    <>
      <Box as="header">
        <Heading as="h1" fontSize={{ base: '2xl', md: '4xl' }} fontWeight="semibold">
          Afiliados
        </Heading>

        <Text
          color="#20212380"
          fontSize={{ base: 'md', md: 'lg' }}
          mt={{ base: 2, md: 5 }}
          lineHeight="1.25"
        >
          Gerencie os afiliados dos seus produtos, visualize as comissões, vendas e ganhos.
          {affiliates?.length < 1 && (
            <Text as="span">
              Você não possui nenhum afiliado, adicione um afiliado em um dos seus produtos no menu
              Afiliados {'>'} Produtos para afiliação.
            </Text>
          )}
        </Text>
      </Box>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        gap={{ base: 3, md: 8 }}
        as="section"
        mt={8}
        justify="space-between"
        align="center"
      >
        <SearchInput value={search} onChange={handleSearchChange} onKeyDown={handleKeyDown} />

        <Stack
          display={{ base: 'none', lg: 'flex' }}
          w={{ md: '48%' }}
          direction={{ base: 'column', md: 'row' }}
          align="center"
        >
          <FilterSelect value={filter} onChange={handleFilterChange} />

          <CourseFilterSelect
            courses={courses}
            onChange={handleCourseFilterChange}
            isLoading={isCoursesLoading}
          />

          <Button
            onClick={handleFilterButtonClick}
            flexShrink={0}
            size="xs"
            colorScheme="primary"
            color="secondary.500"
          >
            Filtrar
          </Button>
        </Stack>

        <FiltersMobile
          filter={filter}
          onFilterChange={handleFilterChange}
          onFilterCourseChange={handleCourseFilterChange}
          onFilterButtonClick={handleFilterButtonClick}
          courses={courses}
          isLoading={isCoursesLoading}
        />
      </Flex>

      {isLoaderShow && <Spinner size="xl" color="primary" m="auto" my={32} />}

      {isNoResultsShow && (
        <Heading
          as="h2"
          fontSize={{ base: 'md', md: '2xl' }}
          fontWeight="normal"
          m="auto"
          color="#20212350"
          my={32}
        >
          Nenhum afiliado encontrado...
        </Heading>
      )}

      {isShowAffiliates && showTable && (
        <Box mt={4} mb={10}>
          <AffiliatesTable affiliates={affiliates} onDelete={handleDelete} onEdit={handleEdit} />
        </Box>
      )}

      {isShowAffiliates && showCard && (
        <Box mt={4} mb={10}>
          <AffiliatesCard affiliates={affiliates} onDelete={handleDelete} onEdit={handleEdit} />
        </Box>
      )}

      {isShowPagination && (
        <Pagination onPageChange={handlePageChange} pageCount={pageCount} initialPage={page} />
      )}

      <DeleteModal
        isOpen={isDeleteModalOpen}
        isLoading={isDeleting}
        affiliateName={selectedAffiliate?.name}
        onClose={onCloseDeleteModal}
        onConfirm={handleConfirmDelete}
      />

      <EditModal
        isOpen={isUpdateModalOpen}
        affiliateName={selectedAffiliate?.name}
        onClose={onCloseUpdateModal}
        onConfirm={handleConfirmEdit}
        affiliationId={selectedAffiliate?.affiliationId}
        isEditing={isEditing}
        percentage={percentage}
        setPercentage={setPercentage}
        editing={editing}
      />
    </>
  );
}
