import { useCallback, useState } from 'react';

import CouponAPI from '../../api/Coupon';
import ErrorResponse from '../../helpers/ErrorResponse';

function useApplyCoupon(productValue) {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [couponValue, setCouponValue] = useState(0);

  function calculateCouponValue(productValue, coupon) {
    if (coupon.percentage) {
      return (productValue * coupon.percentage) / 100;
    }

    return coupon.value;
  }

  const applyCoupon = useCallback(
    async (productId, couponName) => {
      try {
        setErrorMessage('');
        setIsLoading(true);

        const { data: couponData } = await CouponAPI.showByName(productId, couponName);

        setCoupon(couponData);

        const discount = calculateCouponValue(productValue, couponData);
        setCouponValue(discount);
      } catch (error) {
        setErrorMessage(ErrorResponse(error));
        setCouponValue(0);
      } finally {
        setIsLoading(false);
      }
    },
    [productValue]
  );

  return { applyCoupon, couponValue, isLoading, errorMessage, coupon, setCouponValue };
}

export default useApplyCoupon;
