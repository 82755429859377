import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { memo, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';

interface SearchInputProps {
  value?: string;
  onChange: (value: string) => void;
}

function SearchInput({ value, onChange }: SearchInputProps) {
  const [search, setSearch] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (search !== value) {
        onChange(search);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [onChange, search, value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <InputGroup size="sm">
      <InputLeftElement
        pointerEvents="none"
        fontSize={{ base: 'md', md: 'lg', lg: 'lg' }}
        children={<BiSearch color="#20212330" />}
      />
      <Input
        name="search"
        value={search}
        onChange={handleChange}
        borderRadius={6}
        w="100%"
        type="text"
        placeholder="Digite o nome do produto"
        focusBorderColor="primary.500"
      />
    </InputGroup>
  );
}

export default memo(SearchInput);
