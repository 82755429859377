import { memo, useState } from 'react';

import { Button } from '@chakra-ui/react';
import { useEffect } from 'react';
import { AiFillEye } from 'react-icons/ai';
import QuestionsAPI from '../../../../../../../api/Question';
import Loader from '../../../../../../../components/Loadings/DotsFalling';
import Pagination from '../../../../../../../components/Pagination';
import Toast from '../../../../../../../components/Toast';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import Modal from './Modal/index';
import ModalQuestion from './ModalQuestion';
import styles from './styles.module.css';

interface ITable {
  courseId: number | string;
  moduleId: number | string;
  contentId: number | string;
}

interface IQuestion {
  id: number;
  question: string;
  order: number;
  isCorrect: boolean;
  questionText: string;
  commentary: string;
  status: string;
  correctItem: {
    option: string;
    optionText: string;
  };
  answerUser: {
    option: string;
    optionText: string;
  };
}

function Table({ courseId, moduleId, contentId }: ITable) {
  const [resultByQuestion, setResultByQuestion] = useState<IQuestion[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState({} as IQuestion);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModaQuestion, setIsShowModalQuestion] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await QuestionsAPI.student.verifyIsFinished(courseId, moduleId, contentId);

        const { finished } = response?.data;

        if (finished === true) {
          const { reportIndividualStudents, total, limit } =
            await QuestionsAPI.student.getResultByQuestions(
              courseId,
              moduleId,
              contentId,
              currentPage + 1
            );

          setResultByQuestion(reportIndividualStudents);
          setPageCount(total / limit);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [contentId, courseId, currentPage, moduleId]);

  function toggleModal(question: IQuestion) {
    setIsShowModal(prevState => !prevState);
    setSelectedQuestion(question);
  }

  function toggleModalQuestion(question: IQuestion) {
    setIsShowModalQuestion(prevState => !prevState);
    setSelectedQuestion(question);
  }

  function handlePageChange({ selected: selectedPage }) {
    setIsLoading(true);

    setCurrentPage(selectedPage);
  }

  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <div>
            <span className={styles.title}>Resultado por questões</span>
          </div>
        </header>

        {isLoading && (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        )}

        {!isLoading && (
          <table className={styles.table}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>Questão</th>
                <th style={{ textAlign: 'center' }}>Status</th>
                <th style={{ textAlign: 'center' }}>Comentário</th>
              </tr>
            </thead>

            <tbody>
              {resultByQuestion.map((question, index) => (
                <tr key={`question${question.id}`}>
                  <td
                    className="d-flex"
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleModalQuestion(question)}
                  >
                    <span className="mx-1">
                      <AiFillEye />
                    </span>
                    <span>Questão {currentPage * 10 + index + 1} </span>
                  </td>

                  <td
                    onClick={() => toggleModalQuestion(question)}
                    style={{
                      color: question.status ? '#22BB33' : '#BB2124BF',
                      fontWeight: 700,
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    {question.status ? 'Correto' : 'Incorreto'}
                  </td>

                  <td style={{ textAlign: 'center' }}>
                    {!question.commentary ? (
                      '-'
                    ) : (
                      <Button
                        onClick={() => toggleModal(question)}
                        style={{ boxShadow: 'none', outline: 'none' }}
                        variant="link"
                        textDecoration="underline"
                        color="#8eaae9"
                        fontFamily="unset"
                        fontWeight="bold"
                      >
                        Visualizar
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {!isLoading && pageCount > 1 && (
          <Pagination
            onPageActive={currentPage}
            pageCount={pageCount}
            onPageChange={handlePageChange}
          />
        )}
      </div>

      <Modal show={isShowModal} onHide={() => setIsShowModal(false)} data={selectedQuestion} />
      <ModalQuestion
        show={isShowModaQuestion}
        onHide={() => setIsShowModalQuestion(false)}
        data={selectedQuestion}
      />
    </>
  );
}

export default memo(Table);
