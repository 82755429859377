import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FiHelpCircle } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

export function ModalAlertMultipleSessions({ isOpen, onClose }) {
  const history = useHistory();

  return (
    <Modal
      isCentered
      size="lg"
      isOpen={isOpen}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" fontSize="4xl">
          Aviso
        </ModalHeader>
        <ModalBody textAlign="center">
          <Stack spacing={3}>
            <Text fontSize="xl" fontWeight={500}>
              Sua sessão na plataforma foi encerrada devido à outro acesso em sua conta.
            </Text>
            <Text fontSize="md">
              Para garantir a segurança e a integridade, implementamos medidas para encerrar
              automaticamente sessões duplicadas ou simultâneas.
            </Text>

            <Stack direction="row" spacing={3} justifyContent="center">
              <Link
                href="https://proluno.freshdesk.com/support/solutions/articles/150000091663-como-funciona-o-bloqueio-de-acessos-simult%C3%A2neos"
                target="_blank"
                color="blue.400"
                fontSize="sm"
              >
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                  <Text>Saiba mais</Text>
                  <FiHelpCircle />
                </Stack>
              </Link>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Stack direction="row" spacing={3}>
            <Button
              colorScheme="gray"
              onClick={() => {
                history.push('/reset-password');
                onClose();
              }}
            >
              Redefinir senha
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                history.push('/');
                onClose();
              }}
            >
              Fui eu
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
