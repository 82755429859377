import { Box, Image, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../../../contexts/ThemeContext';
import DropdownPost from '../components/DropdownPost';

type Post = {
  id: number;
  articleName: string;
  subtitle: string;
  userName: string;
  userPhoto: string;
  status: string;
  category: string;
  content: string;
  banner: string;
  createdAt: Date;
  publicationDate: Date;
};

interface ICardProps {
  post: Post;
  deletePost: (id: number) => void;
}

function getPostStatus(status: string) {
  switch (status) {
    case 'ATIVO':
      return '#22BB33';
    case 'EM_EDICAO':
      return '#000';
    default:
      break;
  }
}

function getPostLabel(post: Post) {
  switch (post.status) {
    case 'ATIVO':
      return 'Publicado';
    case 'EM_EDICAO':
      return 'Agendado';
    default:
      break;
  }
}

function Card({ post, deletePost }: ICardProps) {
  const { theme } = useTheme();

  return (
    <Box
      width={{ base: 'full', md: '250px', lg: '300px' }}
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      borderRadius="10px"
    >
      <Stack as={Link} to={`internal-blog/post/${post.id}/edit`}>
        {post.banner ? (
          <Image
            width="full"
            height="230px"
            objectFit="cover"
            src={post.banner}
            alt={`Banner do post ${post.articleName}`}
          />
        ) : (
          <Box backgroundColor={theme.secondaryColor} color={theme.primaryColor}>
            {post.articleName.substring(0, 1).toUpperCase()}
          </Box>
        )}

        <Stack padding={5} spacing={3}>
          <Text
            noOfLines={2}
            fontWeight={700}
            height="30px"
            fontSize="13px"
            lineHeight="4"
            fontStyle="normal"
          >
            {post.articleName.toUpperCase()}
          </Text>

          <Text noOfLines={2} height="30px" fontSize="12px" lineHeight="4">
            {post.subtitle}
          </Text>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="45px"
        backgroundColor="#E5E5E5"
        paddingX={4}
        fontSize="13px"
      >
        <Text fontWeight={600} color={getPostStatus(post.status)}>
          {getPostLabel(post)}
        </Text>
        <Box display="flex" alignItems="center">
          <Text>{dayjs(post.createdAt).format('DD/MM/YYYY HH:mm')}</Text>
          <DropdownPost postId={post.id} deletePost={deletePost} />
        </Box>
      </Stack>
    </Box>
  );
}

export default memo(Card);
