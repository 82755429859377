import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { IoMdAlert } from 'react-icons/io';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isDisabled: boolean;
  onSubmit: () => void;
}

export default function DeleteCourseModal({ isOpen, onClose, isDisabled, onSubmit }: Props) {
  return (
    <Modal isCentered size="sm" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="0%" backdropBlur="2px" />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>
          <HStack width="full" justifyContent="center" padding="5px">
            <IoMdAlert size="5rem" color="red" />
          </HStack>
        </ModalHeader>

        <ModalBody>
          <Text fontSize="lg" textAlign="center" fontWeight="500">
            Após a exclusão, todos os módulos e arquivos referentes a esse curso também serão
            excluídos.
          </Text>
        </ModalBody>

        <ModalFooter>
          <HStack width="full" justifyContent="center">
            <Button
              width="full"
              size="sm"
              isDisabled={isDisabled}
              colorScheme="red"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button width="full" size="sm" isDisabled={isDisabled} onClick={onSubmit}>
              Excluir curso
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
