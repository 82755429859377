import { SearchIcon } from '@chakra-ui/icons';
import { HEADER_MOBILE_HEIGHT } from '../../constants';

import { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useSearch } from '../../../../contexts/SearchContext';

import {
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Input,
} from '@chakra-ui/react';

interface SearchDrawerProps {
  isOpen: boolean;
  onToggleIsSearching: () => void;
}

export default function SearchDrawer({ isOpen, onToggleIsSearching }: SearchDrawerProps) {
  const { search, onSearchChange } = useSearch();
  const history = useHistory();

  function redirectToProductsPage() {
    if (!search) return;

    onToggleIsSearching();

    history.push(`/all-products?page=1&search=${search}`);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') redirectToProductsPage();
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onSearchChange(event.target.value);
  }

  return (
    <Drawer onClose={onToggleIsSearching} isOpen={isOpen} size="full">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader p={0}>
          <HStack
            direction="column"
            width="full"
            height={HEADER_MOBILE_HEIGHT}
            position="fixed"
            background="primary.500"
            justify={'center'}
            alignItems="center"
            zIndex="99"
            pl={4}
            pr="0.875rem"
            id="mobileHeader"
          >
            <IconButton
              variant="unstyled"
              color="secondary.500"
              bg="transparent"
              aria-label="Buscar por produto"
              icon={<SearchIcon />}
              size="xs"
              sx={{ svg: { boxSize: '1.1rem' } }}
              p={0}
            />

            <Input
              value={search}
              onChange={handleChange}
              onKeyUp={handleKeyPress}
              placeholder="Pesquisar produto..."
              color="secondary.500"
              size="xs"
              fontSize="xs"
              border="none"
              _placeholder={{
                color: 'secondary.500',
              }}
              _focus={{
                border: 'none',
                outline: 'none',
                boxShadow: 'none',
              }}
              autoFocus
            />

            <CloseButton onClick={onToggleIsSearching} color="secondary.500" />
          </HStack>
        </DrawerHeader>
      </DrawerContent>
    </Drawer>
  );
}
