import { axiosBackend } from './Configuration';

interface IQuerys {
  courseId: string;
  startDate?: string;
  endDate?: string;
  page?: string | number;
  perPage?: string | number;
  search?: string;
  status?: string;
}

export default {
  getSubscription: async ({ courseId, startDate, endDate, page, perPage: per_page }: IQuerys) => {
    try {
      const { data: getSubscriptionData } = await axiosBackend().get(
        `/course-subscription/resume-subscriptions${courseId ? `?courseId=${courseId}` : '?'}${
          startDate && !courseId
            ? `startDate=${startDate}`
            : startDate
            ? `&startDate=${startDate}`
            : ''
        }${endDate ? `&endDate=${endDate}` : ''}${page ? `&page=${page}` : ''}${
          per_page ? `&per_page=${per_page}` : ''
        }`
      );

      return getSubscriptionData;
    } catch (error) {
      throw error;
    }
  },

  getGraphics: async ({ courseId, startDate, endDate }: IQuerys) => {
    try {
      const { data: graphicsData } = await axiosBackend().get(
        `/course-subscription/graphic${courseId ? `?courseId=${courseId}` : '?'}${
          startDate && !courseId
            ? `startDate=${startDate}`
            : startDate
            ? `&startDate=${startDate}`
            : ''
        }${endDate ? `&endDate=${endDate}` : ''}`
      );

      return graphicsData;
    } catch (error) {
      throw error;
    }
  },

  getSubscriptionList: async ({ page = 1, perPage: per_page, courseId }: IQuerys) => {
    try {
      const { data: getSubscriptionListData } = await axiosBackend().get(
        `/course-subscription/subscription-list?page=${page}${
          per_page ? `&per_page=${per_page}` : ''
        }${courseId ? `&courseId=${courseId}` : ''}`
      );

      return getSubscriptionListData;
    } catch (error) {
      throw error;
    }
  },

  getSubscribersList: async ({ courseId, status, search, page, perPage: per_page }: IQuerys) => {
    try {
      const { data: getSubscribersListData } = await axiosBackend().get(
        `/course-subscription/subscribers-list${courseId ? `?courseId=${courseId}` : '?'}${
          status && !courseId ? `status=${status}` : status ? `&status=${status}` : ''
        }${search ? `&search=${search}` : ''}${page ? `&page=${page}` : ''}${
          per_page ? `&per_page=${per_page}` : ''
        }`
      );

      return getSubscribersListData;
    } catch (error) {
      throw error;
    }
  },

  cancelSubscription: async ({ subscriptionId }: { subscriptionId: number }) => {
    try {
      const { data: cancelSubscriptionData } = await axiosBackend().post(
        `/course-subscription/${subscriptionId}/cancel-subscription`
      );

      return cancelSubscriptionData;
    } catch (error) {
      throw error;
    }
  },

  studentCancelSubscription: async ({ subscriptionId }: { subscriptionId: number }) => {
    try {
      const { data: cancelSubscriptionData } = await axiosBackend().post(
        `/course-subscription/${subscriptionId}/student-cancel-subscription`
      );

      return cancelSubscriptionData;
    } catch (error) {
      throw error;
    }
  },
};
