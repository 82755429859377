import { Avatar, Box, Card, HStack, Text } from '@chakra-ui/react';
import { IRecurrencePlanTestimony } from '../..';

export default function TestimonialCard({ name, thumbnail, testimony }: IRecurrencePlanTestimony) {
  return (
    <Card
      borderRadius="10px"
      border="1px solid rgba(32, 33, 35, 0.05)"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      width="370px"
      height="auto"
      padding="15px"
    >
      <Box display="flex" flexDirection="column" gap="17px">
        <HStack textAlign="start">
          <Avatar color="primary.500" bgColor="secondary.500" name={name} src={thumbnail} />
          <Text fontSize={{ base: '16px', md: '18px' }} fontWeight="600" lineHeight="normal">
            {name}
          </Text>
        </HStack>

        <Text fontSize={{ base: '14px', md: '18px' }} fontWeight="400" lineHeight="normal">
          {testimony}
        </Text>
      </Box>
    </Card>
  );
}
