import { AspectRatio, Box, Flex, Heading, HStack, Image, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { ICourse } from '../..';
import DefaultImageProduct from '../../../../../components/DefaultImageProduct';
import ActionsButton from '../ActionsButton';

type StatusType = 'ATIVO' | 'INATIVO' | 'EM_EDICAO';

interface Props {
  course: ICourse;
  onOpenConfirmDelete: () => void;
  setCourseIdToDelete: (id: number) => void;
  onOpenCourseDuplicate: () => void;
  setCourseIdToDuplicate: (id: number) => void;
}

const fontSize = {
  base: 'x-small',
  sm: 'sm',
};

export function setClassesStatus(status: StatusType) {
  const STATUS = {
    ATIVO: 'h7 text-success pt-1',
    INATIVO: 'h7 text-secondary pt-1',
    EM_EDICAO: 'h7 text-warning pt-1',
    BLOQUEADO: 'h7 text-danger pt-1',
    NAO_LISTADO: 'h7 text-dark pt-1',
  };

  return STATUS[status] || 'h6 text-warning pt-1';
}

export function formatNameStatus(status: StatusType) {
  const STATUS_VALUE = {
    ATIVO: 'Ativo',
    INATIVO: 'Inativo',
    EM_EDICAO: 'Em edição',
    BLOQUEADO: 'Bloqueado',
    NAO_LISTADO: 'Não Listado',
  };

  return STATUS_VALUE[status] || '...';
}

const CardGrid = ({
  course,
  onOpenConfirmDelete,
  setCourseIdToDelete,
  onOpenCourseDuplicate,
  setCourseIdToDuplicate,
}: Props) => {
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);

  return (
    <>
      <Box
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        bg="transparent"
        maxWidth="256px"
        borderRadius="10px"
      >
        <Link
          to={`/courses/${course.productId}/course-manager/course?${urlSearchParams.toString()}`}
        >
          <Flex direction="column" align="center" justify="center">
            <Box overflow="hidden" width="100%" borderRadius="10px 10px 0 0">
              <AspectRatio maxWidth={{ base: '244px', sm: 'unset' }} ratio={1}>
                {course.productThumbnail ? (
                  <Image src={course.productThumbnail} alt="Imagem do curso" objectFit="cover" />
                ) : (
                  <DefaultImageProduct title={course.productName} />
                )}
              </AspectRatio>
            </Box>
          </Flex>
        </Link>

        <HStack justifyContent="space-between" alignItems="center" p={2} mx={1}>
          <Heading noOfLines={2} fontSize={fontSize} color="#202123">
            {course.productName}
          </Heading>

          <ActionsButton
            id={course.productId}
            onOpenConfirmDelete={onOpenConfirmDelete}
            setCourseIdToDelete={setCourseIdToDelete}
            onOpenCourseDuplicate={onOpenCourseDuplicate}
            setCourseIdToDuplicate={setCourseIdToDuplicate}
          />
        </HStack>

        <Box p={2} mx={1}>
          <Heading fontSize={fontSize} color="#20212350">
            Status:{' '}
            <Text
              fontSize={fontSize}
              className={`${setClassesStatus(course.productStatus)}`}
              fontWeight={500}
              as="line"
            >
              {formatNameStatus(course.productStatus)}
            </Text>
          </Heading>
        </Box>
      </Box>
    </>
  );
};

export default CardGrid;
