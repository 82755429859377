// * Essa função deve ser passada no "onKeyPress" do input
// * Exemplo de uso: <input type='tel' onKeyPress={HandleInputPhoneMask} />

function HandleInputPhoneMask(event) {
  let { value } = event.target;

  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})/, '($1) ');
  const isCellPhone = /^[(][0-9][0-9][)][\s][9]/.test(value);

  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }

  if (isCellPhone) {
    event.currentTarget.maxLength = 15;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d{1})/, '($1) $2');
    value = value.replace(/(\d{5})/, '$1-');
    value = value.replace(/(\d{5})/, '$1');
  } else {
    event.currentTarget.maxLength = 14;
    value = value.replace(/(\d{4})/, '$1-');
    value = value.replace(/(\d{4})/, '$1');
  }

  event.currentTarget.value = value;
}

export default HandleInputPhoneMask;
