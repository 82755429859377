export default function findState(value: string, states) {
  const stateBySigla = states.find(
    state => state.sigla?.toLowerCase() === value?.toLowerCase()
  )?.sigla;

  const stateByName = states.find(
    state => state.nome?.toLowerCase() === value?.toLowerCase()
  )?.sigla;

  return stateBySigla || stateByName;
}
