import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import path from 'path';
import { Modal } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import { platformsWith200MbUploadLimit } from '../../../../../../App';
import { baseURLBackend } from '../../../../../../api/Configuration';
import ContentAPI from '../../../../../../api/Content';
import CustomDropzone from '../../../../../../components/CustomDropzone';
import DataLoading from '../../../../../../components/Loadings/DataLoading';
import Spinner from '../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { TOKEN_LOCAL_STORAGE } from '../../../../../../helpers/LocalStorageHelper';
import UploadHelper from '../../../../../../helpers/UploadHelper';

const MB = 1024 * 1024; // 1 megabyte em bytes
const SIZE_200_MB = 200 * MB; // 200 megabytes em bytes
const SIZE_25_MB = 25 * MB; // 25 megabytes em bytes
const FIRST_FILE = 0;
const ADD_WITH_CURRENT_ORDER = 1;

const initialValues = {
  title: '',
  order: 1,
  contentType: 'PDF',
  filePDF: '',
  downloadAvailable: 'false',
};

const PdfForm = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [values, setValues] = useState(initialValues);
  const [filePDF, setFilePDF] = useState('');
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getContent, course, setCourse } = useCourse();
  const { id: courseId, moduleId, contentId } = useParams();
  const history = useHistory();

  const hostname = window.location.hostname;

  const isPlatformWith200MbUploadLimit = platformsWith200MbUploadLimit.includes(hostname);

  useEffect(() => {
    const content = getContent(moduleId, contentId);
    if (!contentId) {
      return setValues(initialValues);
    }
    if (content) {
      setValues({
        title: content.title,
        order: content.order,
        contentType: content.contentType,
        filePDF: content.download,
        downloadAvailable: String(content.downloadAvailable),
      });
    }
  }, [contentId, getContent, moduleId]);

  function handleChange(event) {
    const { name, value } = event.target;

    if (name === 'pdf') {
      const filePDF = event.target.files[FIRST_FILE] || '';

      return setValues({ ...values, filePDF: filePDF });
    }

    setValues({
      ...values,
      [name]: value,
    });
    setErrorMessage('');
  }

  function handleDropFilePDF(acceptedFiles) {
    setErrorMessage('');

    const filePDF = acceptedFiles[FIRST_FILE] || '';
    setFilePDF(filePDF);

    setValues({
      ...values,
      filePDF: filePDF,
    });
  }

  function PreviewPDF({ filePDF }) {
    function handleDiscardFilePDF() {
      setFilePDF('');
      setValues({
        ...values,
        filePDF: '',
      });
    }

    return (
      <div className="d-flex align-center justify-content-between mt-2">
        <div>{filePDF?.name || path.basename(decodeURI(values.filePDF))}</div>

        <div>
          <div>
            {!filePDF && (
              <a
                target="_blank"
                href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${localStorage.getItem(
                  TOKEN_LOCAL_STORAGE
                )}`}
                download={true}
                rel="noreferrer"
              >
                Download
              </a>
            )}

            <button
              onClick={handleDiscardFilePDF}
              type="button"
              className="btn btn-outline-primary"
            >
              <BsTrash />
            </button>
          </div>
        </div>
      </div>
    );
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    setFilePDF('');
    setValues(initialValues);
    setErrorMessage('');
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (isPlatformWith200MbUploadLimit && filePDF && filePDF.size > SIZE_200_MB) {
      return setErrorMessage('O tamanho máximo do pdf deve ser igual ou inferior a 200 MB.');
    }

    if (!isPlatformWith200MbUploadLimit && filePDF.size > SIZE_25_MB) {
      return setErrorMessage('O tamanho máximo do pdf deve ser igual ou inferior a 25 MB.');
    }

    if (!contentId) {
      try {
        setIsLoading(true);

        const currentModule = course.modules.find(module => module.id === parseInt(moduleId));
        const { contents } = currentModule;

        if (contents.length) {
          const orders = contents.map(content => content.order);
          values.order = orders.length
            ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
            : initialValues.order;
        }

        let namePDF;

        if (filePDF) {
          const { newFileName, link } = await UploadHelper.upload(filePDF, 'archives');

          setValues({ ...values, filePDF: link });

          namePDF = newFileName;
        }

        const payload = {
          ...values,
          filePDF: namePDF ? namePDF : null,
        };

        const { data: pdfContent } = await ContentAPI.store(courseId, moduleId, payload);

        const newContents = [...currentModule.contents, pdfContent];

        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({
          ...course,
          modules: newModules,
        });

        setFilePDF('');

        setIsLoading(false);
        Toast('Conteúdo cadastrado com sucesso');

        history.push(
          `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${pdfContent.id}/edit-pdf`
        );
      } catch (error) {
        setErrorMessage(ErrorResponse(error));
        setIsLoading(false);
      }
      return;
    }

    try {
      setIsLoading(true);

      let pdfName;

      if (filePDF) {
        const { newFileName } = await UploadHelper.upload(filePDF, 'archives');

        setValues({ ...values, filePDF: encodeURI(newFileName) });

        pdfName = newFileName;
      }

      const payload = {
        ...values,
        filePDF: pdfName ? pdfName : values.filePDF ? values.filePDF : null,
      };

      const { data: updatedContent } = await ContentAPI.update(
        courseId,
        moduleId,
        contentId,
        payload
      );

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      const newContents = currentModule.contents.map(content => {
        if (content.id === parseInt(contentId)) {
          return {
            ...content,
            title: updatedContent.title,
            order: updatedContent.order,
            contentType: updatedContent.contentType,
            filePDF: updatedContent.download,
            download: updatedContent.download,
            downloadAvailable: updatedContent.downloadAvailable,
          };
        }

        return content;
      });

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      Toast('Conteúdo atualizado com sucesso');

      history.push(
        `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${updatedContent.id}/edit-pdf`
      );
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();

    try {
      setIsLoading(true);
      await ContentAPI.delete(courseId, moduleId, contentId);

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      const newContents = currentModule.contents.filter(
        module => module.id !== parseInt(contentId)
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      setIsShowModal(false);
      Toast('Conteúdo excluído com sucesso');
      history.push(`/courses/${courseId}/course-manager/course/modules/${moduleId}`);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setIsLoading(false);
      setErrorMessage('');
    }
  }

  return (
    <div className="mx-5" style={{ wordBreak: 'break-all' }}>
      <h4 className="mt-1 mb-4">{values.title || 'Novo conteúdo em PDF'}</h4>

      <form onSubmit={handleSubmit}>
        <div className="form-background p-5 round-xl shadow-sm border border-gray bg-white">
          <div className="mb-4">
            <p className="info" style={{ wordBreak: 'break-word', lineHeight: '1.5' }}>
              Cadastre PDFs para que seus alunos possam ler diretamente no computador ou celular,
              sem precisar fazer download.
            </p>
          </div>

          <div className="form-group">
            <label className="h6" htmlFor="title">
              Título - PDF
            </label>
            <input
              id="title"
              name="title"
              value={values.title}
              placeholder="Digite um título para o seu conteúdo"
              onChange={handleChange}
              type="text"
              className="form-control input-content"
              required
              maxLength={255}
              minLength={1}
            ></input>
          </div>

          <div className="form-group">
            <label className="h6">Carregar arquivo PDF:</label>
          </div>
          {!values.filePDF && (
            <CustomDropzone
              filePDF={filePDF}
              isDisabled={isLoading}
              accept="application/pdf"
              onDrop={handleDropFilePDF}
              onDropRejected={() => setErrorMessage('Arquivo inválido!')}
              extensions="PDF"
              maxFileSize="25 MB"
            />
          )}

          {values.filePDF && <PreviewPDF filePDF={filePDF} />}

          <DataLoading loading={isLoading} className="d-flex justify-content-center mt-4">
            {errorMessage && !isLoading && (
              <div className="mt-4">
                <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
                  <div className="text-center">{errorMessage}</div>
                </div>
              </div>
            )}

            <div className="col-md-6 pl-0 ">
              <div className="form-group mt-2">
                <label className="h6 mt-2" htmlFor="eventType">
                  Disponível para download?
                </label>
                <div className="row" style={{ fontSize: 12, marginTop: -8 }}>
                  <div className="mt-md-3 mt-lg-1 col-md-12 col-lg-6">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        onClick={handleChange}
                        name="downloadAvailable"
                        id="inlineRadio1"
                        value={'true'}
                        checked={values.downloadAvailable === 'true'}
                      />
                      <label className="ml-1 form-check-label" htmlFor="inlineRadio1">
                        <strong className="d-flex">Sim</strong>
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="downloadAvailable"
                        onClick={handleChange}
                        id="inlineRadio2"
                        value={'false'}
                        checked={values.downloadAvailable === 'false'}
                      />
                      <label className="ml-1 form-check-label" htmlFor="inlineRadio2">
                        <strong className="d-flex">Não</strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-4">
              <div>
                {contentId && (
                  <button
                    onClick={event => {
                      event.preventDefault();
                      setIsShowModal(true);
                    }}
                    className="btn btn-outline-danger"
                  >
                    Excluir conteúdo
                  </button>
                )}
              </div>
              <div className="d-flex align-center justify-content-end">
                <button onClick={handleDiscardChanges} className="btn btn-light">
                  Descartar mudanças
                </button>

                <button type="submit" className="btn btn-primary ml-3">
                  Salvar
                </button>
              </div>
            </div>
          </DataLoading>
        </div>
      </form>
      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="mb-1 py-4">
            <h3 className="text-center">Você tem certeza que deseja excluir o conteúdo?</h3>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <button onClick={() => setIsShowModal(false)} className="btn btn-light mr-4">
              Cancelar
            </button>
            <button onClick={handleDelete} className="btn btn-danger" disabled={isLoading}>
              {isLoading ? <Spinner small /> : 'Excluir'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PdfForm;
