import Joi from 'joi';
import { RecurrencePlanCustomizationForm } from './types';

export default Joi.object<RecurrencePlanCustomizationForm>().keys({
  userFormForPurchase: Joi.array()
    .items(Joi.string().valid('END', 'TEL', 'INS'))
    .min(1)
    .required()
    .messages({
      'array.min': 'Selecione pelo menos uma opção.',
    }),
  topImage: Joi.string().default(null).allow(null),
  bottomImage: Joi.string().default(null).allow(null),
});
