import { Heading, List, ListItem } from '@chakra-ui/react';
import { Banner, Course, IShowcase } from '../../types';
import Showcase from '../Showcase';
import { HighlightPlanShowcase } from '../Showcase/HighlightPlanShowcase';

function hasShowcaseItems(showcase: IShowcase) {
  return showcase.showcaseCourse?.length || showcase.showcasePlan?.length;
}

interface HomeProps {
  hasProducts: boolean;
  myProducts: Course[];
  showcases: IShowcase[];
  banners: Banner[];
  isPreview?: boolean;
}

export default function Home({
  hasProducts,
  myProducts,
  showcases,
  banners,
  isPreview = false,
}: HomeProps) {
  const hasBanner = !!banners?.length;

  return (
    <>
      {hasProducts && (
        <>
          <Heading
            mt={{ base: hasBanner ? 6 : '5rem', xl: hasBanner ? 6 : '7rem' }}
            ml={{ base: 4, lg: '5.5rem' }}
            mb={3}
            color="#ffffff"
            fontSize={{ base: 'xl', md: '3xl' }}
            fontWeight="medium"
          >
            Meus produtos
          </Heading>

          <Showcase products={myProducts} showProgressBar isPreview={isPreview} />
        </>
      )}

      <List
        mt={hasProducts ? { base: 8, lg: '4rem' } : { base: 4, lg: '8rem' }}
        spacing={{ base: 8, lg: '4rem' }}
      >
        {showcases?.map(
          showcase =>
            hasShowcaseItems(showcase) && (
              <ListItem key={showcase.id}>
                {showcase.showcaseType === 'plans' && showcase.viewType === 'highlight' ? (
                  <HighlightPlanShowcase isPreview={isPreview} plans={showcase.showcasePlan} />
                ) : (
                  <>
                    <Heading
                      ml={{ base: 4, lg: '5.5rem' }}
                      mb={3}
                      color="#ffffff"
                      fontSize={{ base: 'xl', md: '3xl' }}
                      fontWeight="medium"
                    >
                      {showcase.name}
                    </Heading>

                    <Showcase
                      products={showcase.showcaseCourse?.map(showcaseItem => showcaseItem.course)}
                      plans={showcase.showcasePlan}
                      isPreview={isPreview}
                      showcaseType={showcase.showcaseType}
                    />
                  </>
                )}
              </ListItem>
            )
        )}
      </List>
    </>
  );
}
