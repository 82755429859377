import styled, { css } from 'styled-components';

const defaultStyles = css`
  color: #202123;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1024px) {
    justify-content: space-between;
  }

  & + & {
    margin: 1rem 0 0 0;
    width: 100%;
    display: block;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: stretch;
  }
`;

export const FormControl = styled.div`
  display: flex;
  flex-direction: column;

  label {
    ${defaultStyles};
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
  }

  input {
    height: 2.75rem;
    width: 7.8rem;
    padding: 0 0.8rem;

    background: #f6f5f5;
    border: 1px solid #aeadad;
  }

  textarea {
    height: 11.5rem;
    padding: 0.8rem;

    background: #f6f5f5;
    border: 1px solid #aeadad;
  }
`;

export const Preview = styled.div`
  width: 100%;
  height: auto;

  padding: 0.8rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #ffffff;
  border: 1px solid rgba(32, 33, 35, 0.5);
  border-radius: 10px;

  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);

  @media only screen and (min-width: 768px) {
    width: 0%;
    min-width: 33rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FileContent = styled.div`
  display: flex;

  > img {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 0.5rem 0 0;
  }

  > span {
    ${defaultStyles};
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

export const DownloadButton = styled.div`
  > a {
    padding: 0 0.5rem;
    font-size: 0.875rem;
    color: #eb7129;
    background: 0;
    border: 0;
    text-decoration: underline;
  }
`;

export const ButtonGroup = styled.div`
  width: 100%;
  margin: 1rem 0 0 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  > button {
    display: block;
    height: 2.5rem;
    width: 100%;

    font-size: 1rem;
    font-weight: 600;

    &.cancel {
      ${defaultStyles};
      background: #e5e5e5;
      border: 1px solid rgba(32, 33, 35, 0.5);
    }

    &.finish {
      margin: 0 0 0 1.25rem;
      background: #eb7129;
      color: #ffffff;
      border: 1px solid rgba(235, 113, 41, 1);
    }

    @media only screen and (min-width: 1024px) {
      width: 0%;
      min-width: 7.5rem;
    }
  }
`;

export const ContainerPreview = styled.div`
  display: flex;
  align-items: center;

  span {
    ${defaultStyles};
    font-size: 1rem;
  }

  > button {
    margin: 0 0.2rem 0 0;
    background: transparent;
    border: 0;
  }
`;
