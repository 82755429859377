type StatusType = 'ATIVO' | 'INATIVO' | 'EM_EDICAO';

export default function formatStatusName(status: StatusType) {
  const STATUS_VALUE = {
    ATIVO: 'Ativo',
    INATIVO: 'Inativo',
    EM_EDICAO: 'Em edição',
    BLOQUEADO: 'Bloqueado',
    NAO_LISTADO: 'Não Listado',
  };

  return STATUS_VALUE[status] || '...';
}
