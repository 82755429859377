import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 0.4rem;
  min-width: 8rem;
  background: #f6f5f5;
  border: 1px solid #20212340;

  svg {
    width: 24px;
    height: 24px;
    color: #20212340;
  }

  input {
    margin: 0 0 0 0.5rem;
    flex: 1;
    background: transparent;
    border: 0;
    color: #202123;

    &::placeholder {
      color: #20212380;
    }

    &:focus {
      outline: none;
    }
  }

  &:focus-within {
    border: 1px solid #eb7129;
  }
`;
