import { Button, Tooltip, useClipboard } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdOutlineContentCopy } from 'react-icons/md';

interface CopyButtonProps {
  textToCopy: string;
  onCopy: (hasCopied: boolean) => void;
}

export default function CopyButton({ textToCopy, onCopy }: CopyButtonProps) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const { onCopy: copyText } = useClipboard(textToCopy);

  // Fecha o tooltip após 3 segundos  `
  useEffect(() => {
    if (isTooltipOpen) {
      const timeout = setTimeout(() => {
        setIsTooltipOpen(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [isTooltipOpen]);

  function handleCopy() {
    copyText();
    setIsTooltipOpen(true);
    onCopy(true);
  }

  return (
    <Tooltip label="Copiado" placement="top" hasArrow isOpen={isTooltipOpen} closeOnPointerDown>
      <Button
        onClick={handleCopy}
        variant="ghost"
        leftIcon={<MdOutlineContentCopy size="1.25rem" />}
        borderColor="transparent"
        p={0}
      >
        Copiar
      </Button>
    </Tooltip>
  );
}
