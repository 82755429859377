import { Box, Button, Container, Input, Text, Textarea, VStack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Link, useHistory, useParams } from 'react-router-dom';
import CategoryApi from '../../../../api/Category';
import CourseApi from '../../../../api/Course';
import DotFalling from '../../../../components/Loadings/DotsFalling';
import { ReactSelectStyles } from '../../../../components/ReactSelect';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { sortByOrder } from '../../../../helpers/SortHelper';

const initialStateCategory = {
  name: '',
  description: '',
  courses: [],
};

function Category() {
  const { id } = useParams();

  const [category, setCategory] = useState(initialStateCategory);
  const [courses, setCourses] = useState([]);
  const [categoryCourses, setCategoryCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getCategoryById(id);
    }
  }, [id]);

  useEffect(() => {
    getCourses();
  }, []);

  async function getCategoryById(category_id) {
    try {
      const { data: categoryData } = await CategoryApi.show(category_id);
      setCategory(categoryData);
      setCategoryCourses(
        categoryData.courses
          .map(course => ({
            value: course.id,
            label: course.name,
            order: course.order,
          }))
          .sort(sortByOrder)
      );
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  async function getCourses() {
    try {
      const { data: dataCourse } = await CourseApi.list({ status: 'ATIVO' });

      if (dataCourse) {
        setCourses(dataCourse);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  function handleChange(event) {
    const { value, name } = event.target;

    const newCategory = { ...category, [name]: value };

    setCategory(newCategory);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setSubmitLoading(true);

      let coursesResponse = [];

      if (category.description.length < 5) {
        throw new Error('A descrição deve ter no mínimo 5 caracteres.');
      }

      for (const category of categoryCourses) {
        coursesResponse.push(parseInt(category.id));
      }

      if (!coursesResponse.length || coursesResponse == null) {
        throw new Error('Você precisa selecionar pelo menos um curso para a categoria');
      }

      let courses = [];

      categoryCourses.forEach(course => {
        courses.push(course.value);
      });

      const dataCategory = {
        name: category.name,
        description: category.description,
        courses,
      };

      const orderedData = categoryCourses.map((course, index) => {
        return {
          courseId: course.value,
          order: index + 1,
        };
      });

      if (id) {
        await CategoryApi.update(id, dataCategory);
        Toast('Categoria atualizada com sucesso');
        await CategoryApi.order({ categoryId: parseInt(id), data: orderedData });
        Toast('Categoria ordenada com sucesso');
      } else {
        await CategoryApi.store(dataCategory);
        Toast('Categoria criada com sucesso');
      }

      history.push('/categories');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setSubmitLoading(false);
    }
  }

  function onChangeProducts(products) {
    if (products.length > categoryCourses.length) {
      const productsOrder = [...categoryCourses];

      productsOrder.splice(0, 0, products[products.length - 1]);

      setCategoryCourses(productsOrder);
    } else {
      setCategoryCourses(products);
    }
  }

  function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  // function getLabelOrder(label) {
  //   const categoryCoursesLabels = categoryCourses.map(categoryCourse => categoryCourse.label);

  //   return categoryCoursesLabels.indexOf(label) + 1;
  // }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(categoryCourses, oldIndex, newIndex);
    setCategoryCourses(newValue);
  };

  return (
    <>
      <Link to="/categories">
        <Box
          my={10}
          display="flex"
          alignItems="center"
          border="1px"
          borderColor="gray.300"
          w={200}
          bgColor="gray.200"
          p={2}
        >
          <AiOutlineArrowLeft />
          <Text mx={1}> Voltar para Categorias</Text>
        </Box>
      </Link>
      <Container maxW="container.lg" h="100vh">
        {isLoading ? (
          <Box display="flex" textAlign="center" justifyContent="center">
            <DotFalling />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" justifyContent="center">
            <form onSubmit={handleSubmit}>
              <VStack spacing={5} alignItems="start">
                <Text>Nome da categoria</Text>
                <Input
                  focusBorderColor="orange.400"
                  type="text"
                  name="name"
                  placeholder="Digite o nome da categoria"
                  value={category.name}
                  onChange={handleChange}
                  autoComplete="off"
                  minLength="2"
                  required
                  disabled={submitLoading}
                />
                <Text>Descrição da categoria</Text>
                <Textarea
                  focusBorderColor="orange.400"
                  onChange={handleChange}
                  value={category.description || ''}
                  name="description"
                  id="descriptionCategory"
                  cols="25"
                  rows="10"
                  placeholder="Digite a descrição da categoria"
                  required
                  disabled={submitLoading}
                ></Textarea>
                <Box>
                  <Text>Selecione os produtos referente a está categoria</Text>
                  <Text>
                    Apenas produtos com o status{' '}
                    <Text as="line" style={{ color: '#D36625' }}>
                      ATIVO
                    </Text>{' '}
                    são listados aqui.
                  </Text>
                </Box>
              </VStack>

              <Box my={10}>
                <Select
                  distance={12}
                  menuPlacement="top"
                  isMulti={true}
                  name="products"
                  options={courses.map(course => ({ value: course.id, label: course.name }))}
                  placeholder="Escolha os produtos"
                  styles={ReactSelectStyles}
                  value={categoryCourses}
                  onChange={onChangeProducts}
                  isDisabled={submitLoading}
                  axis="xy"
                  onSortEnd={onSortEnd}
                  getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                />
              </Box>

              <Box display="flex" w="100%" alignItems="center" justifyContent="flex-end" my={10}>
                <Button type="submit" colorScheme="orange" isLoading={submitLoading}>
                  Salvar alterações
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Container>
    </>
  );
}

export default Category;
