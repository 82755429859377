import { RecurrenceType } from '../pages/RecurrenceCheckout';

export function recurrenceText(recurrence: RecurrenceType) {
  switch (recurrence) {
    case 'daily':
      return 'diário';
    case 'biweekly':
      return 'quinzenal';
    case 'monthly':
      return 'por mês';
    case 'bimonthly':
      return 'bimestral';
    case 'quarterly':
      return 'trimestral';
    case 'semiannual':
      return 'semestral';
    case 'yearly':
      return 'anual';
  }
}
