import { HStack } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import FiltersDropDown from './FiltersDropDown';
import SearchInput from './SearchInput';

type UrlParams = Record<string, string | number | undefined>;

export type QueryFilter = {
  id: number;
  label: 'Área' | 'Disciplina' | 'Assunto' | 'Banca';
  name:
    | 'area'
    | 'discipline'
    | 'subject'
    | 'institution'
    | 'viewOrder'
    | 'viewType'
    | 'startDate'
    | 'endDate';
  value: string;
  options: any;
};

export default function SearchContainer() {
  const history = useHistory();
  const location = useLocation();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [search, setSearch] = useState(urlSearchParams.get('search'));

  useEffect(() => {
    setSearch(urlSearchParams.get('search'));
  }, [urlSearchParams]);

  const updateUrlSearchParams = useCallback(
    (params: UrlParams) => {
      for (const [key, value] of Object.entries(params)) {
        value ? urlSearchParams.set(key, value.toString()) : urlSearchParams.delete(key);
      }

      history.push({ search: urlSearchParams.toString() });
    },

    [history, urlSearchParams]
  );

  useEffect(() => {
    updateUrlSearchParams({ search });
  }, [search, updateUrlSearchParams]);

  function handleSearch(value: string) {
    setSearch(value);
  }

  function clearFilters() {
    urlSearchParams.forEach((_, key) => {
      urlSearchParams.delete(key);
    });

    history.replace(location.pathname);
  }

  function handleFilter(filters?: QueryFilter[]) {
    const params: Record<string, string | number> = {
      search,
      page: '1',
      filterAreaId: filters.find(filter => filter.name === 'area')?.id || '',
      filterDisciplineId: filters?.find(filter => filter.name === 'discipline')?.id || '',
      filterSubjectId: filters?.find(filter => filter.name === 'subject')?.id || '',
      filterInstitutionId: filters.find(filter => filter.name === 'institution')?.id || '',
      viewOrder: filters?.find(filter => filter.name === 'viewOrder')?.value || '',
      viewType: filters?.find(filter => filter.name === 'viewType')?.value || '',
      startDate: filters?.find(filter => filter.name === 'startDate')?.value || '',
      endDate: filters?.find(filter => filter.name === 'endDate')?.value || '',
    };

    updateUrlSearchParams(params);
  }

  return (
    <HStack as="section" w="full" align="center" justify="space-between" mt={10} spacing={4}>
      <SearchInput onSearch={handleSearch} value={urlSearchParams.get('search')} />

      <FiltersDropDown
        onFilter={handleFilter}
        onClearFilters={clearFilters}
        urlSearchParams={urlSearchParams}
      />
    </HStack>
  );
}
