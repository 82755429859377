import { Box, Button, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { IStatistics } from '..';

interface IProps {
  statistics: IStatistics[];
  setQuestionsIdsReset: (ids: number[]) => void;
  onOpen: () => void;
}

export default function CardView({ statistics, setQuestionsIdsReset, onOpen }: IProps) {
  const textColor = useColorModeValue('#202123', '#FFFFFF');
  const textColorSubItems = useColorModeValue('rgba(32, 33, 35, 0.50)', '#FFFFFF');
  const bgColor = useColorModeValue('#FFFFFF', '#37393D');

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      {statistics?.map(statistic => (
        <Box
          borderRadius="5px"
          border="1px solid rgba(0, 0, 0, 0.05)"
          boxShadow="0px 3px 0px rgba(0, 0, 0, 0.20)"
          background="#FFF"
          padding="20px 12px"
          bgColor={bgColor}
        >
          <Text
            fontSize="16px"
            color={textColor}
            fontStyle="normal"
            fontWeight="500"
            lineHeight="24px"
            noOfLines={1}
          >
            {statistic.name}
          </Text>

          <Stack
            color={textColorSubItems}
            bgColor={bgColor}
            spacing="8px"
            fontSize="14px"
            lineHeight="normal"
            paddingY="24px"
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Text fontWeight="600">Certo:</Text>
              <Text>{statistic.correct}</Text>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Text fontWeight="600">Errado:</Text>
              <Text>{statistic.wrong}</Text>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Text fontWeight="600">Total:</Text>
              <Text>{statistic.total}</Text>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Text fontWeight="600">Rendimento:</Text>
              <Text>{statistic.performance}%</Text>
            </Stack>
          </Stack>

          <Button
            width="full"
            variant="outline"
            size="sm"
            bgColor="transparent"
            borderColor="#ec7117"
            color="#ec7117"
            onClick={() => {
              setQuestionsIdsReset(statistic.questionId);
              onOpen();
            }}
          >
            Zerar
          </Button>
        </Box>
      ))}
    </Box>
  );
}
