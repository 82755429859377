import { Box } from '@chakra-ui/react';
import { ParamsType } from '../..';
import Card from './Card';

export default function CardList({
  themeMarketplace,
  affiliateProducts,
  setAffiliationId,
  setCourseAffiliationName,
  onOpenRemoveProduct,
}: ParamsType) {
  return (
    <Box>
      {affiliateProducts.length > 0 &&
        affiliateProducts.map(affiliateProduct => (
          <Card
            key={affiliateProduct.affiliationId}
            affiliateProduct={affiliateProduct}
            themeMarketplace={themeMarketplace}
            onOpenRemoveProduct={onOpenRemoveProduct}
            setAffiliationId={setAffiliationId}
            setCourseAffiliationName={setCourseAffiliationName}
          />
        ))}
    </Box>
  );
}
