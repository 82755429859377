import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import React, { useState } from 'react';

import styles from './styles.module.css';

function InputPassword({ form, onChange, isLoading }) {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmationPassword, setIsShowConfirmationPassword] = useState(false);

  function togglePasswordShow() {
    setIsShowPassword(prevState => !prevState);
  }

  function toggleConfirmationPasswordShow() {
    setIsShowConfirmationPassword(prevState => !prevState);
  }

  return (
    <>
      <div className="form-group mt-1">
        <div className="form-label-group">
          <label className={`form-label`} htmlFor="name">
            Senha
          </label>
        </div>

        <div className={styles.inputPasswordWrapper}>
          <input
            name="password"
            id="password"
            value={form.password}
            onChange={onChange}
            type={isShowPassword ? 'text' : 'password'}
            required
            className={`form-control ${styles.input}`}
            disabled={isLoading}
          />
          <i onClick={togglePasswordShow}>{isShowPassword ? <IoMdEye /> : <IoMdEyeOff />}</i>
        </div>
      </div>

      <div className="form-group mt-1">
        <div className="form-label-group">
          <label className={`form-label`} htmlFor="name">
            Confirme sua Senha
          </label>
        </div>

        <div className={styles.inputPasswordWrapper}>
          <input
            name="confirmationPassword"
            id="confirmationPassword"
            value={form.confirmationPassword}
            onChange={onChange}
            type={isShowConfirmationPassword ? 'text' : 'password'}
            className={`form-control ${styles.input}`}
            required
          />
          <i onClick={toggleConfirmationPasswordShow}>
            {isShowConfirmationPassword ? <IoMdEye /> : <IoMdEyeOff />}
          </i>
        </div>
      </div>
    </>
  );
}

export default InputPassword;
