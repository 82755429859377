import { CSSProperties, MouseEvent, useMemo } from 'react';

import { useDropzone } from 'react-dropzone';

interface IProps {
  isDisabled: boolean;
  imageDimensions: string;
  onDrop: () => void;

  onDropRejected: () => void;
}

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '18rem',

  padding: '1.25rem',
  marginLeft: '0.35rem',
  borderWidth: 2,
  borderColor: '#AEADAD',
  borderStyle: 'dashed',
  backgroundColor: '#f6f5f5',
  outline: 'none',
  transition: 'all .24s ease-in-out',
};

const acceptStyle: CSSProperties = {
  borderColor: '#19bc90',
  backgroundColor: '#e4fbf5',
};

const rejectStyle: CSSProperties = {
  borderColor: '#c3463c',
  backgroundColor: '#fceae9',
  borderStyle: 'solid',
  color: '#c3463c',
};

function BannerMobileInput({ isDisabled, imageDimensions, onDrop, onDropRejected }: IProps) {
  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    accept: ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'],
    noClick: true,
    noKeyboard: true,
    onDrop,
    onDropRejected,
  });

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (isDisabled) return;
    open();
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragReject, isDragAccept]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />

      <p className="text-center">Arraste e solte um arquivo aqui ou</p>

      <button
        onClick={handleClick}
        className="mb-2"
        style={{ fontSize: '0.75rem' }}
        disabled={isDisabled}
      >
        Selecionar arquivo
      </button>

      <p className="text-center" style={{ color: '#EB7129' }}>
        Extensões válidas: <br /> jpg, png ou jpeg. <br /> Dimensões: {imageDimensions}. <br />{' '}
        Tamanho máx. 3 MB
      </p>
    </div>
  );
}

export default BannerMobileInput;
