import { Heading, useColorModeValue } from '@chakra-ui/react';

interface ChartTitleProps {
  title: string;
}

export default function ChartTitle({ title }: ChartTitleProps) {
  const textColor = useColorModeValue('#202123', '#FFFFFF');

  return (
    <Heading as="h3" color={textColor} fontSize="sm" fontWeight="medium">
      {title}
    </Heading>
  );
}
