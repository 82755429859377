import { Box, HStack, VStack, Text } from '@chakra-ui/react';
import ChartTitle from '../ChartTitle';
import { Performance } from '../..';
import { ResponsiveBar } from '@nivo/bar';
import CustomTooltip from '../CustomTooltip';

interface PerformanceChartProps {
  performance: Performance;
}

export default function PerformanceChart({ performance }: PerformanceChartProps) {
  const data = [
    {
      label: 'Rendimento',
      Acertos: performance?.correct,
      Erros: performance?.wrong,
    },
  ];

  function getPercentage(value: number) {
    const total = performance?.correct + performance?.wrong;
    const percentage = (value / total) * 100;
    const parsedPercentage = percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(2);

    return `${parsedPercentage}%`;
  }

  return (
    <VStack align="start" spacing={2.5} w="full">
      <ChartTitle title="Rendimento" />

      <VStack w="full" align="start" spacing={2.5}>
        <HStack spacing={2.5}>
          <HStack spacing={0.5}>
            <Box bg="#4BBE58" boxSize={2.5} borderRadius="sm" />
            <Text as="span">
              Acertos:
              <Text as="strong" fontWeight="semibold">
                {' '}
                {performance?.correct}
              </Text>
            </Text>
          </HStack>

          <HStack spacing={0.5}>
            <Box bg="#BB2124" boxSize={2.5} borderRadius="sm" />
            <Text as="span">
              Erros:
              <Text as="strong" fontWeight="semibold">
                {' '}
                {performance?.wrong}
              </Text>
            </Text>
          </HStack>
        </HStack>

        <Box width="100%" height={10}>
          <ResponsiveBar
            data={data}
            keys={['Acertos', 'Erros']}
            indexBy="label"
            layout="horizontal"
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            padding={0}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridX={false}
            enableGridY={false}
            colors={['#4BBE58', '#BB2124']}
            enableLabel={false}
            tooltip={data => {
              return (
                <CustomTooltip>
                  <Box bg={data.color} boxSize={2.5} borderRadius="sm" />
                  <Text as="span">
                    {data.label}:
                    <Text as="strong" ml={1}>
                      {data.value} | {getPercentage(data.value)}{' '}
                    </Text>
                  </Text>
                </CustomTooltip>
              );
            }}
            tooltipLabel={data => String(data.id)}
          />
        </Box>
      </VStack>
    </VStack>
  );
}
