import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';

export default function useDateRangePickerCustomStyles() {
  useEffect(() => {
    const nextButton = document.querySelector('.rdrNextButton i');
    const prevButton = document.querySelector('.rdrPprevButton i');

    if (nextButton) {
      const nextIcon = document.createElement('div');
      ReactDOM.render(<MdOutlineChevronRight />, nextIcon);
      nextButton.innerHTML = '';
      nextButton.appendChild(nextIcon);
    }

    if (prevButton) {
      const prevIcon = document.createElement('div');
      ReactDOM.render(<MdOutlineChevronLeft />, prevIcon);
      prevButton.innerHTML = '';
      prevButton.appendChild(prevIcon);
    }
  }, []);
}
