import { CheckIcon, ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const scrollbarStyle = {
  '::-webkit-scrollbar': { width: '0.5rem', background: 'transparent' },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: 'md',
    backgroundColor: 'gray.300',
  },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'gray.400',
  },
};

interface ISelectFilters {
  area: {
    id: string;
    name: string;
  };
  discipline: {
    id: string;
    name: string;
  };
  subject: {
    id: string;
    name: string;
  };
  institution: {
    id: string;
    name: string;
  };
}

interface SelectListProps {
  title: string;
  filterData: {
    id: number;
    name: string;
  }[];
  selectName: string;
  selectFilters: ISelectFilters;
  setSelectFilters: (selectFilters: ISelectFilters) => void;
  onFilterChange: (name: string, value: string) => void;
}

const SelectList: React.FC<SelectListProps> = ({
  title,
  filterData,
  selectName,
  selectFilters,
  setSelectFilters,
  onFilterChange,
}) => {
  const [filterName, setFilterName] = useState('');
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const [search, setSearch] = useState('');

  const bgColor = useColorModeValue('#FFFFFF', '#37393D');
  const alternateBgColor = useColorModeValue('#20212350', '#AFAFAF');
  const textColor = useColorModeValue('#202123', '#FFFFFF');
  const alternateTextColor = useColorModeValue('#20212390', '#AFAFAF');

  useEffect(() => {
    if (selectName === 'area') {
      setFilterName(selectFilters.area.name);
    }
    if (selectName === 'discipline') {
      setFilterName(selectFilters.discipline.name);
    }
    if (selectName === 'subject') {
      setFilterName(selectFilters.subject.name);
    }
  }, [
    selectFilters.area.name,
    selectFilters.discipline.name,
    selectFilters.subject.name,
    selectName,
  ]);

  const Row = () => (
    <Box
      maxHeight="12rem"
      overflowY="auto"
      paddingY={2}
      borderRadius={5}
      bgColor={bgColor}
      color="#AFAFB0"
      sx={scrollbarStyle}
    >
      {filteredMenuItems?.length === 0 ? (
        <Text marginX={2} color={textColor} bgColor={bgColor}>
          Nenhum resultado encontrado...
        </Text>
      ) : (
        filteredMenuItems
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map(filter => (
            <MenuItem key={filter.id} bgColor={bgColor} _hover={{ bgColor: alternateBgColor }}>
              <Stack direction="row" alignItems="center" cursor="pointer" spacing={2} marginX={2}>
                <Box width={5}>
                  <CheckIcon
                    boxSize="14px"
                    color="#EC7117"
                    display={filter.name === filterName ? 'block' : 'none'}
                  />
                </Box>
                <Text
                  fontSize="14px"
                  textAlign="start"
                  color={textColor}
                  width={220}
                  isTruncated
                  onClick={() => {
                    filters(filter.id.toString(), filter.name);
                    onFilterChange(selectName, filter.id.toString());
                  }}
                >
                  {filter.name}
                </Text>
              </Stack>
            </MenuItem>
          ))
      )}
    </Box>
  );

  const filters = (filterId: string, filterName: string) => {
    if (selectName === 'area') {
      setSelectFilters({
        ...selectFilters,
        area: {
          id: filterId,
          name: filterName,
        },
      });

      setFilterName(filterName);

      return;
    }
    if (selectName === 'discipline') {
      setSelectFilters({
        ...selectFilters,
        discipline: {
          id: filterId,
          name: filterName,
        },
      });

      setFilterName(filterName);

      return;
    }
    if (selectName === 'subject') {
      setSelectFilters({
        ...selectFilters,
        subject: {
          id: filterId,
          name: filterName,
        },
      });

      setFilterName(filterName);

      return;
    }
    if (selectName === 'institution') {
      setSelectFilters({
        ...selectFilters,
        institution: {
          id: filterId,
          name: filterName,
        },
      });

      setFilterName(filterName);

      return;
    }
  };

  useEffect(() => {
    const handleSearch = setTimeout(() => {
      const filteredItems = filterData?.filter(item =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );

      setFilteredMenuItems(filteredItems);
    }, 500);

    return () => clearTimeout(handleSearch);
  }, [search, filterData]);

  return (
    <Menu>
      <MenuButton
        bgColor={bgColor}
        color={textColor}
        border="1px solid #E2E8F0"
        _hover={{ bgColor: bgColor, color: alternateTextColor }}
        _active={{ bgColor: bgColor, color: alternateTextColor }}
        width="full"
        height="32px"
        as={Button}
        rightIcon={<ChevronDownIcon />}
      >
        <Text textAlign="start" fontSize="14px" fontWeight={400} color={textColor} isTruncated>
          {filterName ? filterName : title}
        </Text>
      </MenuButton>

      <MenuList
        maxHeight={150}
        borderBottom="1px"
        borderTop="0"
        borderLeft="1px"
        borderRight="1px"
        borderColor="#20212320"
        bgColor={bgColor}
      >
        <InputGroup size="sm" margin={2} width="270px">
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input
            type="text"
            placeholder="Buscar"
            focusBorderColor="orange.500"
            borderRadius={6}
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
          />
        </InputGroup>
        <Row />
      </MenuList>
    </Menu>
  );
};

export default SelectList;
