import Toast from '../components/Toast';
import ErrorResponse from '../helpers/ErrorResponse';
import { axiosBackend } from './Configuration';

const PurchaseAPI = {
  store: {
    purchaseFreeProduct: async course_id => {
      try {
        const { data: purchaseData } = await axiosBackend().post(
          `/purchases/${course_id}/course/free`
        );

        return purchaseData;
      } catch (error) {
        throw error;
      }
    },
  },
  purchaseRefund: async transaction_id => {
    try {
      await axiosBackend().patch(`/purchases/${transaction_id}/refund`);
    } catch (error) {
      throw error;
    }
  },

  purchaseExportTransaction: async ({
    typeFile = '',
    startDate = '',
    endDate = '',
    search = '',
    paymentMethod = [],
    status = [],
    checkoutType = [],
  }) => {
    const params = new URLSearchParams();

    if (typeFile) params.append('typeFile', typeFile);
    if (search) params.append('search', search);
    if (startDate) params.append('startDate', startDate);
    if (endDate) params.append('endDate', endDate);
    if (paymentMethod.length > 0) params.append('paymentMethod', paymentMethod);
    if (status.length > 0) params.append('status', status);
    if (checkoutType) params.append('checkoutType', checkoutType);

    const axios = axiosBackend();

    try {
      const response = await axios({
        url: `/purchases/export-transaction?${params.toString()}`,
        method: 'GET',
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', typeFile === 'CSV' ? 'transacoes.csv' : 'transacoes.xlsx'); // or any other extension
      document.body.appendChild(link);
      link.click();

      // Clean up the URL object after download
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      Toast(ErrorResponse(error));
    }
  },

  purchasePaymentInternational: async course_id => {
    try {
      await axiosBackend().post(`/purchases/${course_id}/course/purchase`);
    } catch (error) {
      throw error;
    }
  },

  purchaseFutureReleases: async (filterStartDateFutureReleases, filterEndDateFutureReleases) => {
    try {
      const params = new URLSearchParams();

      if (filterStartDateFutureReleases) {
        params.append('startDate', filterStartDateFutureReleases);
      }
      if (filterEndDateFutureReleases) {
        params.append('endDate', filterEndDateFutureReleases);
      }

      const { data: futureReleasesData } = await axiosBackend().get(
        `/purchases/payable-days?${params.toString()}`
      );

      return futureReleasesData;
    } catch (error) {
      throw error; // ou você pode logar o erro ou tratá-lo de outra maneira
    }
  },
};

export default PurchaseAPI;
