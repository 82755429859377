import { Heading, Stack } from '@chakra-ui/react';
import { pdfjs } from 'react-pdf';
import PDFViewer from './PDFViewer';
import { useCourseStudent } from '../../../../../contexts/CourseStudentContextV2';
import { PagingContents } from '../../../components/PagingContents';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ViewPDF() {
  const { currentContent: content, highlightColor } = useCourseStudent();
  return (
    <Stack spacing={{ base: '9px', md: '50px', lg: '50px' }}>
      <Heading
        color="#FFF"
        fontSize={{ base: '18px', md: '24px', lg: '24px' }}
        margin="0"
        fontWeight="500"
      >
        {content?.title}
      </Heading>

      {content?.download && (
        <Stack spacing={{ base: '9px', md: '30px', lg: '30px' }}>
          <PDFViewer
            pdfUrl={content?.download}
            hasDownload={content.downloadAvailable}
            highlightColor={highlightColor}
          />

          <PagingContents />
        </Stack>
      )}
    </Stack>
  );
}

export default ViewPDF;
