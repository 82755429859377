import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FullPageLoading from '../../../../components/FullPageLoading';
import Paginate from '../../../../components/Paginate';
import convertToBrazilianCurrency from '../../../../helpers/convertToBrazilianCurrency';
import useFetch from '../../../../hooks/useFetch';
import hasFilterParams from '../../../../utils/hasFilterParams';
import Status from '../components/Status';
import getPaymentMethod from '../utils/getPaymentMethod';
import ConfirmCancelModal from '../components/ConfirmCancelModal';
import { axiosBackend } from '../../../../api/Configuration';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';

type StatusType =
  | 'processing'
  | 'authorized'
  | 'paid'
  | 'refunded'
  | 'waiting_payment'
  | 'pending_refund'
  | 'refused'
  | 'canceled'
  | 'chargedback';

interface IRecurrenceResponse {
  recurrenceId: number;
  name: string;
}
interface IRecurrencePaymentHistory {
  id: number;
  transactionId: string;
  purchasePrice: string;
  paymentMethod: string;
  purchaseStatus: StatusType;
  createdAt: Date;
}
interface IPaymentHistoricResponse {
  recurrencePaymentHistory: IRecurrencePaymentHistory[];
  recurrenceStatus: string;
  page: number;
  per_page: number;
  total: number;
}

export default function RecurrenceList() {
  const [recurrenceId, setRecurrenceId] = useState(null);
  const history = useHistory();
  const location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const urlSearchParams: URLSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  let paymentOrder = 0;

  const { data: recurrenceListResponse, loading: recurrenceListLoading } = useFetch<
    UnificadaFront.ResponseJSON<IRecurrenceResponse[]>
  >({
    method: 'get',
    url: `/student-recurrence`,
    authenticated: true,
    autoFetch: true,
  });

  const recurrenceList = recurrenceListResponse?.data;

  const {
    fetchData: fetchPaymentHistory,
    data: paymentHistoryResponse,
    loading: paymentHistoryLoading,
  } = useFetch<UnificadaFront.ResponseJSON<IPaymentHistoricResponse>>({
    method: 'get',
    url: `/student-recurrence/${recurrenceId}/payment-history?${urlSearchParams}`,
    authenticated: true,
    autoFetch: false,
  });

  const paymentHistory = paymentHistoryResponse?.data?.recurrencePaymentHistory;
  const recurrenceStatus = paymentHistoryResponse?.data?.recurrenceStatus;
  const isCanceled = recurrenceStatus === 'canceled';

  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const pageCount = paymentHistoryResponse?.data?.total / paymentHistoryResponse?.data?.per_page;
  const shouldShowPagination = !paymentHistoryLoading && pageCount > 1;
  const isLoading = recurrenceListLoading || paymentHistoryLoading;

  const isFiltering = hasFilterParams(urlSearchParams);
  const isNotFoundVisible = !isLoading && !paymentHistory?.length && isFiltering;
  const isEmpty = !isLoading && !recurrenceList?.length && !isFiltering;

  useEffect(() => {
    if (recurrenceId) {
      fetchPaymentHistory();
    }
  }, [fetchPaymentHistory, recurrenceId]);

  function handleChangeSelectedProduct(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    if (!value) {
      setRecurrenceId(null);
      return;
    }

    setRecurrenceId(value);
  }

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  function handleStatusChange(status: StatusType) {
    urlSearchParams.set('status', status);
    history.push({ search: urlSearchParams.toString() });
  }

  async function recurrenceCancellation(recurrenceId: number) {
    try {
      if (recurrenceId) {
        await axiosBackend().patch(`/student-recurrence/${recurrenceId}/cancellation`);
        Toast('Recorrência cancelada com sucesso!', 'success');
        onClose();
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
      onClose();
    }
  }

  return (
    <Stack spacing="30px">
      <HStack hidden={!recurrenceList?.length}>
        <Text fontWeight="500">Selecione um plano:</Text>
        <Select
          size="sm"
          borderRadius={5}
          width="250px"
          placeholder="Selecione"
          focusBorderColor="#D86826"
          value={recurrenceId}
          onChange={handleChangeSelectedProduct}
          isDisabled={isLoading}
        >
          {recurrenceList?.map(recurrence => (
            <option key={recurrence.recurrenceId} value={recurrence.recurrenceId}>
              {recurrence.name}
            </option>
          ))}
        </Select>
      </HStack>

      <Heading
        py={10}
        textAlign="center"
        fontSize="md"
        fontWeight="400"
        color="#20212380"
        hidden={!isEmpty}
      >
        Você não possui produtos por assinatura.
      </Heading>

      <Stack justifyContent="center" alignItems="center" hidden={!isNotFoundVisible}>
        <Heading fontSize="md" fontWeight="400" color="#20212380">
          Nenhum pagamento encontrado com esse status.
        </Heading>
        <Heading fontSize="md" fontWeight="400" color="#20212380">
          <Text as="u" cursor="pointer" onClick={() => history.push('/members-area/v1/payments')}>
            Clique aqui
          </Text>{' '}
          para voltar para o histórico completo
        </Heading>
      </Stack>

      <HStack justifyContent="space-between" hidden={!paymentHistory?.length}>
        <Text fontWeight="500">Histórico de pagamento</Text>

        <Button size="sm" colorScheme="red" variant="outline" onClick={onOpen} hidden={isCanceled}>
          Cancelar recorrência
        </Button>

        <Badge colorScheme="red" borderRadius="6" hidden={!isCanceled}>
          Recorrência cancelada
        </Badge>
      </HStack>

      <TableContainer
        hidden={!paymentHistory?.length}
        border="1px solid rgba(0, 0, 0, 0.1)"
        borderRadius="5px"
      >
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Pagamento</Th>
              <Th>Forma de pagamento</Th>
              <Th>Data</Th>
              <Th>Valor</Th>
              <Th>
                <Menu>
                  <MenuButton>
                    <HStack fontWeight="700">
                      <Text>STATUS</Text>
                      <ChevronDownIcon />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => handleStatusChange('paid')}>Pago</MenuItem>
                    <MenuItem onClick={() => handleStatusChange('refused')}>Recusado</MenuItem>
                    <MenuItem onClick={() => handleStatusChange('waiting_payment')}>
                      Aguardando pagamento
                    </MenuItem>
                    <MenuItem onClick={() => handleStatusChange('refunded')}>Estornado</MenuItem>
                    <MenuItem onClick={() => handleStatusChange('canceled')}>Cancelado</MenuItem>
                    <MenuItem onClick={() => handleStatusChange('chargedback')}>
                      Chargedback
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {paymentHistory?.map(payment => (
              <Tr key={payment.id}>
                <Td>{paymentHistory?.length - paymentOrder++}</Td>
                <Td>{getPaymentMethod(payment.paymentMethod)}</Td>
                <Td>{dayjs(payment.createdAt).format('DD/MM/YYYY')}</Td>
                <Td>{convertToBrazilianCurrency(payment.purchasePrice)}</Td>
                <Td>
                  <Status
                    paymentMethod={payment.paymentMethod}
                    paymentStatus={payment.purchaseStatus}
                    link=""
                    onClick={() => ''}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {shouldShowPagination && (
          <Paginate
            pageCount={pageCount}
            initialPage={currentPage}
            onPageChange={handlePageChange}
            mt={{ base: 8, xl: 4 }}
          />
        )}

        <FullPageLoading isLoading={isLoading} />

        <ConfirmCancelModal
          isOpenCancellation={isOpen}
          onCloseCancellation={onClose}
          cancelId={recurrenceId}
          cancelSubmit={recurrenceCancellation}
        />
      </TableContainer>
    </Stack>
  );
}
