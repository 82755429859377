import { BoxProps, Box as ChakraBox, Text } from '@chakra-ui/react';

import styled, { css } from 'styled-components';

interface ICardProps {
  title?: string;
  data?: string | number;
  color?: string;
  gridArea?: string;
}

interface IBoxProps extends BoxProps {}

const Box = styled(ChakraBox)<IBoxProps>`
  ${() => css`
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0.75rem;
      background: currentColor;
      border-radius: 0.5rem 0.5rem 0 0;
    }
  `}
`;

function Card({ title, data, color, gridArea }: ICardProps) {
  return (
    <Box
      width="full"
      height="100px"
      pt="4"
      pb="1"
      px="4"
      position="relative"
      border="1px solid rgba(32, 33, 35, 0.25)"
      borderRadius="0.5rem"
      bg="#37393D"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      style={{ color, gridArea }}
    >
      <Text
        as="span"
        display="block"
        color="#FFF"
        fontSize={{ base: 'lg', md: 'lg', lg: 'md' }}
        fontWeight="700"
      >
        {title}
      </Text>
      <Text color="#FFF" as="span" display="block" fontSize="2xl" fontWeight="700">
        {data}
      </Text>
    </Box>
  );
}

export default Card;
