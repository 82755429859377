import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormEvent } from 'react';

interface ModalAddOrEditAreaProps {
  id?: number;
  areaName?: string;
  setAreaId?: (value: number) => void;
  setAreaName?: (value: string) => void;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (event: FormEvent) => void;
  isSubmitting?: boolean;
  isEditingArea?: boolean;
  formValidation?: {
    name: {
      isInvalid: boolean;
      message: string;
    };
  };
}

export function ModalAddOrEditArea({
  id,
  isOpen,
  onClose,
  areaName,
  setAreaName,
  onSubmit,
  isSubmitting,
  isEditingArea,
  setAreaId,
  formValidation,
}: ModalAddOrEditAreaProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setAreaId(0);
        setAreaName('');
        onClose();
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader fontWeight={700} fontSize="18px">
          {id ? 'Editar área' : 'Adicionar uma área'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody marginX={1}>
          <FormControl isInvalid={formValidation?.name.isInvalid}>
            <FormLabel fontWeight={600} fontSize="14px">
              Nome da área
            </FormLabel>
            <Input
              placeholder="Digite aqui"
              size="sm"
              borderRadius={6}
              focusBorderColor="orange.500"
              color="#20212380"
              value={areaName}
              onChange={e => setAreaName(e.target.value)}
              maxLength={50}
            />
            <FormErrorMessage>
              {formValidation?.name.isInvalid && formValidation?.name.message}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            size="sm"
            mr={3}
            onClick={() => {
              setAreaId(0);
              setAreaName('');
              onClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="orange"
            size="sm"
            type="submit"
            isDisabled={isSubmitting || isEditingArea}
            isLoading={isSubmitting || isEditingArea}
          >
            {id ? 'Salvar' : 'Adicionar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
