const scrollIntoView = (id = '', top = false) => {
  const section = document.querySelector(id);

  if (section) {
    if (top) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    section.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export default scrollIntoView;
