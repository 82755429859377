import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { IoMdAlert } from 'react-icons/io';

export default function MarkBlockingModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        display="flex"
        width="512px"
        padding="24px"
        flexDirection="column"
        alignItems="center"
        background="#37393D"
        boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
      >
        <ModalCloseButton color="#FFF" />

        <ModalHeader display="flex" alignItems="center" justifyContent="center" color="#DD6B20">
          <IoMdAlert fontSize="48px" />
        </ModalHeader>

        <ModalBody
          color="#FFF"
          textAlign="center"
          fontSize="18px"
          fontWeight="500"
          lineHeight="28px"
        >
          Esse conteúdo só poderá ser marcado como “concluído” quando o professor enviar a correção.
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
