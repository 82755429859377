import { axiosBackend } from './Configuration';

const APIPageBuilder = {
  bannerHero: {
    store: async (theme_id: number, section_id: number) => {
      try {
        const { data: bannerHeroData } = await axiosBackend().post(
          `/themes/${theme_id}/theme/${section_id}/section/banner-hero/duplicate`
        );

        return bannerHeroData;
      } catch (error) {
        throw error;
      }
    },

    delete: async (theme_id: number, section_id: number) => {
      try {
        const { data: bannerHeroData } = await axiosBackend().delete(
          `/themes/${theme_id}/theme/${section_id}/section/banner-hero`
        );

        return bannerHeroData;
      } catch (error) {
        throw error;
      }
    },
  },

  category: {
    store: async (theme_id: number, section_id: number) => {
      try {
        const { data: categoryData } = await axiosBackend().post(
          `/themes/${theme_id}/theme/${section_id}/section/category/duplicate`
        );

        return categoryData;
      } catch (error) {
        throw error;
      }
    },

    delete: async (theme_id: number, section_id: number) => {
      try {
        const { data: categoryData } = await axiosBackend().delete(
          `/themes/${theme_id}/theme/${section_id}/section/category`
        );

        return categoryData;
      } catch (error) {
        throw error;
      }
    },
  },

  biography: {
    store: async (theme_id: number, section_id: number) => {
      try {
        const { data: biographyData } = await axiosBackend().post(
          `/themes/${theme_id}/theme/${section_id}/section/biography/duplicate`
        );

        return biographyData;
      } catch (error) {
        throw error;
      }
    },

    delete: async (theme_id: number, section_id: number) => {
      try {
        const { data: biographyData } = await axiosBackend().delete(
          `/themes/${theme_id}/theme/${section_id}/section/biography`
        );

        return biographyData;
      } catch (error) {
        throw error;
      }
    },
  },

  multimedia: {
    store: async (theme_id: number, section_id: number) => {
      try {
        const { data: multimediaData } = await axiosBackend().post(
          `/themes/${theme_id}/theme/${section_id}/section/multimedia/duplicate`
        );

        return multimediaData;
      } catch (error) {
        throw error;
      }
    },

    delete: async (theme_id: number, section_id: number) => {
      try {
        const { data: multimediaData } = await axiosBackend().delete(
          `/themes/${theme_id}/theme/${section_id}/section/multimedia`
        );

        return multimediaData;
      } catch (error) {
        throw error;
      }
    },
  },

  testimony: {
    store: async (theme_id: number, section_id: number) => {
      try {
        const { data: testimonyData } = await axiosBackend().post(
          `/themes/${theme_id}/theme/${section_id}/section/testimony/duplicate`
        );

        return testimonyData;
      } catch (error) {
        throw error;
      }
    },

    delete: async (theme_id: number, section_id: number) => {
      try {
        const { data: testimonyData } = await axiosBackend().delete(
          `/themes/${theme_id}/theme/${section_id}/section/testimony`
        );

        return testimonyData;
      } catch (error) {
        throw error;
      }
    },
  },
};

export default APIPageBuilder;
