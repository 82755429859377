import { Box, Flex, Stack, Text } from '@chakra-ui/react';

import dayjs from 'dayjs';
import { Heading } from '../../../../../../../components/Heading';
import useQuery from '../../../../../../../hooks/useQuery';

function CertificatePreview() {
  const query = useQuery();

  const certificateImage = query.get('certificateImage');

  const mainTextColor = query.get('mainTextColor');
  const mainTextFont = query.get('mainTextFont');

  const instructorName = query.get('instructorName');
  const instructorNameColor = query.get('instructorNameColor');
  const instructorNameFont = query.get('instructorNameFont');

  const workload = query.get('workload');

  const studentNameColor = query.get('studentNameColor');
  const studentNameFont = query.get('studentNameFont');

  const courseName = query.get('courseName');
  const courseNameColor = query.get('courseNameColor');
  const courseNameFont = query.get('courseNameFont');

  const institutionName = query.get('institutionName');
  const institutionNameColor = query.get('institutionNameColor');
  const institutionNameFont = query.get('institutionNameFont');

  const emissionDateColor = query.get('emissionDateColor');
  const emissionDateFont = query.get('emissionDateFont');

  const hostname = window.location.hostname;
  const isProfectumPlatform = hostname.includes('profectum.proluno.com.br');

  return (
    <Stack
      width="83.5rem"
      height="60.4375rem"
      margin="0 auto"
      position="relative"
      color={`#${mainTextColor}`}
      fontFamily={mainTextFont}
      backgroundImage={`url('${certificateImage}')`}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      display="flex"
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        fontFamily={mainTextFont}
        hidden={!instructorName && !institutionName}
        spacing={10}
      >
        {!isProfectumPlatform && (
          <Heading as="h1" fontSize="77.97px" fontFamily={mainTextFont} color={`#${mainTextColor}`}>
            CERTIFICADO
          </Heading>
        )}

        <Box
          textAlign="center"
          mt={{ base: '2', md: '5', lg: '10' }}
          px={{ base: '10', md: '20', lg: '100' }}
          w={900}
          fontSize={{ base: '0.6rem', md: 'md', lg: 'xl' }}
        >
          <Text lineHeight="9" fontSize="25.99px">
            Certificamos que o{' '}
            <Text fontFamily={studentNameFont} color={`#${studentNameColor}`} display="inline">
              Nome do aluno
            </Text>
            , concluiu com sucesso o curso{' '}
            <Text fontFamily={courseNameFont} color={`#${courseNameColor}`} display="inline">
              {courseName?.trim()}
            </Text>
            , com carga horaria de {workload?.trim()}, no dia{' '}
            <Text as="span" color={`#${emissionDateColor}`} fontFamily={emissionDateFont}>
              {dayjs().format('DD/MM/YYYY')}
            </Text>
          </Text>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          textAlign="center"
          fontSize={{ base: '0.6rem', md: 'md', lg: 'lg' }}
          mt={{ base: '2', md: '5', lg: '10' }}
          w={900}
        >
          <Text fontSize="25.99px" hidden={!instructorName} color={`#${mainTextColor}`}>
            INSTRUTOR
          </Text>
          <Text
            fontSize="25.99px"
            color={`#${instructorNameColor}`}
            fontFamily={instructorNameFont}
          >
            {instructorName?.toUpperCase().trim()}
          </Text>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          textAlign="center"
          fontSize={{ base: '0.6rem', md: 'md', lg: 'lg' }}
          w={900}
        >
          <Text fontSize="25.99px" hidden={!institutionName} color={`#${mainTextColor}`}>
            INSTITUIÇÃO
          </Text>
          <Text
            fontSize="25.99px"
            color={`#${institutionNameColor}`}
            fontFamily={institutionNameFont}
          >
            {institutionName?.toUpperCase().trim()}
          </Text>
        </Box>

        <Flex
          mt={{ base: '2', md: '5', lg: '10' }}
          width={{ base: '40px', md: '80px', lg: '100px' }}
          height={{ base: '40px', md: '80px', lg: '100px' }}
          borderColor="black"
          color="black"
          borderWidth={2}
          textAlign="center"
          justifyContent="center"
          flexDirection="column"
          fontSize={{ base: '0.6rem', md: 'md', lg: 'lg' }}
        >
          QR Code
        </Flex>
      </Stack>
    </Stack>
  );
}

export default CertificatePreview;
