import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CourseAPI from '../../api/Course';
import Toast from '../../components/Toast';
import ErrorResponse from '../../helpers/ErrorResponse';

function PreviewCertificate() {
  const { courseId, userId } = useParams<{ courseId: string; userId: string }>();

  const [certificateData, setCertificateData] = useState(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function generateCertificate() {
      try {
        const { data: certificateData } = await CourseAPI.certificate.generate(courseId, userId);

        if (certificateData) {
          setCertificateData(certificateData);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    }

    generateCertificate();
  }, [courseId, userId]);

  return (
    <>
      {isLoading ? (
        <Box
          width="100vw"
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text fontSize="2xl" fontWeight="semibold" color="primary.500">
            Carregando certificado...
          </Text>
        </Box>
      ) : (
        <Box
          display="flex"
          id="certificateId"
          overflow="auto"
          dangerouslySetInnerHTML={{ __html: certificateData?.certificateTemplete }}
        />
      )}
    </>
  );
}

export default PreviewCertificate;
