import React, { memo } from 'react';

import noDataImg from '../../../../assets/images/illustrations/dashboard-no-data.svg';
import styles from './styles.module.css';

function NoData() {
  return (
    <div className={`${styles.noDataContainer} animate-left`}>
      <img src={noDataImg} alt="Sem dados" />
      <h6>Nenhum cupom encontrado.</h6>
      <p>Crie o seu primeiro cupom clicando em "Novo cupom".</p>
    </div>
  );
}

export default memo(NoData);
