import { HStack, Text, Image, Box } from '@chakra-ui/react';

import masterCard from '../../../../../../assets/icons/mastercard-xs.svg';
import visa from '../../../../../../assets/card-brands/v2/visa.svg';

import { Container, CardBack, CardFront } from './styled';

function getFlag(value) {
  if (value?.startsWith('4')) {
    return 'visa';
  } else if (value?.startsWith('5')) {
    return 'master';
  }
  return 'other';
}

type ExpirationType = {
  month: string;
  year: string;
};

interface CreditCardIllustrationProps {
  isShowBack: boolean;
  cardNumber: string;
  cardHolderName: string;
  cardExpirationDate: ExpirationType;
  cardCVV: string;
}

export default function CreditCardIllustration({
  isShowBack,
  cardNumber,
  cardHolderName,
  cardExpirationDate,
  cardCVV,
}: CreditCardIllustrationProps) {
  const { month, year } = cardExpirationDate;

  const formattedCardNumber = cardNumber?.match(/.{1,4}/g);

  const firstGroup = formattedCardNumber?.[0];
  const secondGroup = formattedCardNumber?.[1];
  const thirdGroup = formattedCardNumber?.[2];
  const fourthGroup = formattedCardNumber?.[3];

  const flag = getFlag(cardNumber);

  return (
    <Container
      width="100%"
      height="11.5rem"
      fontSize="sm"
      lineHeight="shorter"
      fontWeight="medium"
      position="relative"
    >
      <CardFront
        position="absolute"
        width="100%"
        height="100%"
        paddingTop="5"
        paddingX="3.5"
        paddingBottom="7"
        zIndex="1"
        className={`card-front ${isShowBack && 'front-rotate-click'}`}
      >
        <HStack
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{
            img: {
              width: '2rem',
            },
          }}
        >
          <Text>
            Cartão de <br />
            crédito
          </Text>

          {flag === 'visa' && (
            <Box padding="0.5rem 0.25rem" background="white" borderRadius="sm">
              <Image src={visa} alt="bandeira cartão visa" />
            </Box>
          )}

          {flag === 'master' && <Image src={masterCard} alt="bandeira cartão Mastercard" />}

          {flag === 'other' && ''}
        </HStack>

        <HStack marginTop="2rem !important" marginBottom="2.5rem !important" gap="2">
          <Text>{firstGroup ? firstGroup : '****'}</Text>
          <Text>{secondGroup ? secondGroup : '****'}</Text>
          <Text>{thirdGroup ? thirdGroup : '****'}</Text>
          <Text>{fourthGroup ? fourthGroup : '****'}</Text>
        </HStack>

        <HStack alignItems="center" justifyContent="space-between">
          <Text textTransform="uppercase">
            {cardHolderName ? cardHolderName : '******* ******* ******'}
          </Text>

          <Text>
            {month ? month : '**'}/{year ? year : '**'}
          </Text>
        </HStack>
      </CardFront>

      <CardBack
        position="absolute"
        width="100%"
        height="100%"
        paddingTop="5"
        paddingX="3.5"
        paddingBottom="7"
        className={`card-back ${isShowBack && 'back-rotate-click'}`}
      >
        <Box
          width="100%"
          height="2.5rem"
          paddingRight="2"
          background="white"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Text color="gray.900" fontWeight="semibold">
            {cardCVV ? cardCVV : 'CVV'}
          </Text>
        </Box>
      </CardBack>
    </Container>
  );
}
