import { useEffect, useState } from 'react';

import { HStack, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { IS_REACT_APP_HOSTNAME } from '../../../App';
import { useSearch } from '../../../contexts/SearchContext';
import { useTheme } from '../../../contexts/ThemeContext';
import { ROLE_LOCAL_STORAGE } from '../../../helpers/LocalStorageHelper';
import ProductsMenu from './ProductsMenu';

function getMenu(themeMarketplace) {
  const header = themeMarketplace?.themeSections?.find(section => section.name === 'header');
  const activeMenuItem = header?.themeHeaderMenu?.filter(menuItem => menuItem.active);

  return activeMenuItem;
}

export default function NavMenu() {
  const hostname = window.location.hostname;
  const isStudent = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'ALUNO';
  const [menu, setMenu] = useState([]);

  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();
  const { onSearchChange } = useSearch();

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      const menu = getMenu(themeMarketplace);

      setMenu(menu);
    }
  }, [themeMarketplace, isLoadingThemeMarketplace]);

  function handleLinkClick(event, path) {
    if (path.startsWith('#', 0)) {
      event.preventDefault();
      window.location = window.location.origin + path;
    }

    if (path === '/all-products') {
      onSearchChange('');
    }
  }

  const linkStyle = {
    color: themeMarketplace.textColor,
    fontSize: 'lg',
    fontWeight: 'medium',
    transition: '0.2s all',
    _hover: { filter: 'brightness(0.88)' },
  };

  return (
    <>
      {IS_REACT_APP_HOSTNAME(hostname) ? (
        <HStack direction={{ base: 'column', md: 'row' }} spacing={{ base: 5, md: 7 }}>
          <ChakraLink to="/" as={Link} {...linkStyle}>
            Cursos
          </ChakraLink>

          <ChakraLink to="/product-categories" as={Link} {...linkStyle}>
            Categorias
          </ChakraLink>

          <ChakraLink to="/blog" as={Link} {...linkStyle}>
            Blog
          </ChakraLink>
        </HStack>
      ) : (
        <HStack
          as="nav"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 5, md: 7 }}
          align="center"
        >
          {menu?.map(menuItem => {
            let path = menuItem.path;

            switch (menuItem.name) {
              case 'home':
                path = '/';
                break;
              case 'about':
                path = '/about';
                break;
              case 'courses':
                path = '/all-products';
                break;
              case 'blog':
                path = '/blog';
                break;
              case 'question':
                if (isStudent) {
                  path = '/members-area/v1/questions';
                } else {
                  path = '/question';
                }
                break;
              default:
                menuItem.path = path;
            }

            menuItem.path = path;

            if (menuItem.name === 'courses') {
              return <ProductsMenu key={menuItem.name} menuLabel={menuItem.label} />;
            }

            return (
              <ChakraLink
                key={menuItem.name}
                as={Link}
                to={menuItem.path}
                onClick={event => handleLinkClick(event, menuItem.path)}
                {...linkStyle}
              >
                {menuItem.label}
              </ChakraLink>
            );
          })}
        </HStack>
      )}
    </>
  );
}
