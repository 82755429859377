import { Box, Heading, VStack, Text, Button, HStack } from '@chakra-ui/react';
import { ReactComponent as PixIcon } from '../../../../../assets/icons/pixIcon.svg';
import { IoMdAlarm as AlarmIcon } from 'react-icons/io';
import { MdOutlineAccountBalanceWallet as WalletIcon } from 'react-icons/md';

interface BoletoProps {
  onPaymentMethodChange: (value: string) => void;
}

export default function Boleto({ onPaymentMethodChange }: BoletoProps) {
  function handlePaymentMethodChange() {
    onPaymentMethodChange('pix');
  }

  return (
    <VStack
      align="flex-start"
      spacing={8}
      sx={{
        h3: {
          fontWeight: 'medium',
          fontSize: 'md',
          color: '#202123',
        },
        p: { fontWeight: 'normal', fontsize: 'sm', color: ' #0000005C', mt: 1 },
        svg: { flexShrink: 0 },
      }}
    >
      <Box>
        <HStack spacing={3} sx={{ svg: { boxSize: '24px' } }}>
          <WalletIcon />
          <Heading as="h3">Pague até a data de vencimento</Heading>
        </HStack>

        <Text>
          Faço o pagamento até o dia do vencimento do boleto para garantir acesso ao produto.
        </Text>
      </Box>

      <Box>
        <HStack spacing={3} sx={{ svg: { boxSize: '23.75px' } }}>
          <AlarmIcon />

          <Heading as="h3">Você precisa aguardar a aprovação do pagamento</Heading>
        </HStack>

        <Text>Os pagamento por boletos bancários levam até 3 dias úteis para serem validados.</Text>
      </Box>

      <Box>
        <HStack spacing={3} sx={{ svg: { boxSize: '24px' } }}>
          <PixIcon />
          <Heading as="h3">Quer ter acesso imediato ao produto? Pague usando o PIX.</Heading>
        </HStack>

        <Text>
          Com o PIX o pagamento é feito em apenas alguns segundos e imediatamente após a compra você
          já terá acesso ao produto.{' '}
          <Button
            onClick={handlePaymentMethodChange}
            variant="link"
            textDecoration="underline"
            fontSize="sm"
            colorScheme="default"
          >
            Clique aqui
          </Button>{' '}
          e pague com o pix.
        </Text>
      </Box>
    </VStack>
  );
}
