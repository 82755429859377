import './style.css';

import { IoIosArrowForward as ArrowRigth } from 'react-icons/io';
import Content from '../Content';
import { useState } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';

function Module({ id, title, contents }) {
  const { themeMarketplace } = useTheme();
  const [rotate, setRotate] = useState(false);

  // const lessons = contents.length;

  function rotateArrow() {
    setRotate(prevRotate => !prevRotate);
  }

  return (
    <div className="accordion accordion-module">
      <div className="card">
        <button
          className="button-collapse"
          style={{ color: themeMarketplace.textColor }}
          type="button"
          onClick={rotateArrow}
          data-toggle="collapse"
          data-target={`#accordionModule${id}`}
          aria-expanded="false"
          aria-controls={`accordionModule${id}`}
        >
          <div
            className="card-header card-header-module"
            style={{ background: themeMarketplace.primaryColor }}
          >
            <div className="mb-0 lessons-between" style={{ color: themeMarketplace.textColor }}>
              <div className="container-title">
                <ArrowRigth
                  size={24}
                  style={{ color: themeMarketplace.textColor }}
                  className={rotate && 'rotate-icon'}
                />
                <span>{title}</span>
              </div>

              {/* <div className="number-lessons">
                {lessons}
                <span style={{ color:themeMarketplace.textColor }}>Aulas</span>
              </div> */}
            </div>
          </div>
        </button>

        <div className="collapse multi-collapse" id={`accordionModule${id}`}>
          <div className="card-body card-body-module">
            {contents.length ? (
              contents
                .sort((a, b) => a.order - b.order)
                .map(content => (
                  <Content
                    key={content.id}
                    title={content.title}
                    contentType={content.contentType}
                  />
                ))
            ) : (
              <div className="pl-1">
                <h6 className="text-black">Nenhum conteúdo cadastrado</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Module;
