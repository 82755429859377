import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { RiDeleteBin6Line as DeleteIcon } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import JivoChatIntegration from '../../../../api/JivoChatIntegration';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import Header from '../components/Header';

const initialState = {
  id: '',
  src: '',
};

function JivoChat() {
  const [jivoChat, setJivoChat] = useState(initialState);
  const [jivoChatCode, setJivoChatCode] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();

  useEffect(() => {
    async function getCodeJivo() {
      const jivoChatCodeData = await JivoChatIntegration.index();

      if (jivoChatCodeData.data) {
        setJivoChat(jivoChatCodeData.data);

        setJivoChatCode(jivoChatCodeData.data.src);
      } else {
        setJivoChat(null);

        setJivoChatCode(null);
      }
    }
    getCodeJivo();
  }, []);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const jivoChatCodeId = jivoChat?.id;

    let method = jivoChatCodeId ? 'update' : 'store';

    if (jivoChatCode && jivoChatCode !== '') {
      const codeConvertInitial = jivoChatCode.replace('<script src="', '');

      const codeConvert = codeConvertInitial.replace('" async></script>', '');

      let jivoChatCodeData;

      try {
        if (method === 'store') {
          jivoChatCodeData = await JivoChatIntegration.post({ jivoChatCode: codeConvert });
        } else {
          jivoChatCodeData = await JivoChatIntegration.patch({ jivoChatCode: codeConvert });
        }

        if (jivoChatCodeData.data) {
          setJivoChat(jivoChatCodeData.data);

          setJivoChatCode(jivoChatCodeData.data.src);

          Toast(
            `JivoChat ${method === 'store' ? 'cadastrado' : 'atualizado'} com sucesso!`,
            'success'
          );

          window.location.href = '/integrations';
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }
  }

  async function handleDiscardChanges() {
    setJivoChat(jivoChat);

    setJivoChatCode(jivoChatCode);

    history.push('/integrations');
  }

  async function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setJivoChatCode(value);
  }

  async function deleteJivoChat() {
    try {
      await JivoChatIntegration.delete();

      Toast('JivoChat deletado com sucesso!', 'success');

      onClose();

      setJivoChat(null);

      setJivoChatCode('');

      window.location.href = '/integrations';
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Header
          title="JivoChat"
          description="Não deixe seu clientes esperando, ative esse sistema de mensagem e conecte-se diretamente com todos."
        />

        <Box my={5}>
          <Text color="#20212380" fontWeight={400} fontSize="lg">
            O script deve ser semelhante ao exemplo abaixo.
          </Text>
          <Text color="#20212380" fontWeight={500} fontSize="lg">
            {'<script src="//code.jivosite.com/widget/EXeMpLe123" async></script>'}
          </Text>
        </Box>

        <Stack direction="row" alignItems="center">
          <Input
            name="code"
            value={jivoChatCode}
            focusBorderColor="orange.400"
            onChange={handleChange}
            placeholder="Cole o aqui o cógido fornecido pela equipe do JivoChat"
            isRequired
          />

          <Box
            hidden={!jivoChat}
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="1px"
            w={6}
            h={6}
            borderRadius="full"
            borderColor="red.500"
          >
            <DeleteIcon width={10} height={10} color="red" cursor="pointer" onClick={onOpen} />
          </Box>
        </Stack>

        <Stack direction="row" my={10} justifyContent="flex-end">
          <Button
            colorScheme="gray"
            style={{ outline: 'none', boxShadow: 'none' }}
            onClick={() => handleDiscardChanges()}
            // disabled={isDisabled}
          >
            Cancelar
          </Button>

          <Button
            colorScheme="orange"
            style={{ outline: 'none', boxShadow: 'none' }}
            type="submit"
            //  disabled={isDisabled}
          >
            Salvar
          </Button>
        </Stack>
      </form>

      <>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader display="flex" justifyContent="center" textAlign="center">
              <Text w={250}>Você tem certeza que deseja excluir?</Text>
            </ModalHeader>
            <ModalCloseButton style={{ outline: 'none', boxShadow: 'none' }} />
            <ModalBody>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  style={{ outline: 'none', boxShadow: 'none' }}
                  size="sm"
                  variant="outline"
                  colorScheme="gray"
                  mr={3}
                  onClick={onClose}
                >
                  Não
                </Button>
                <Button
                  style={{ outline: 'none', boxShadow: 'none' }}
                  size="sm"
                  variant="solid"
                  colorScheme="red"
                  onClick={deleteJivoChat}
                >
                  Sim
                </Button>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </Box>
  );
}

export default JivoChat;
