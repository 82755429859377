export const OPTIONS = [
  {
    label: 'Padrão',
    value: 'default',
    description:
      'Crie uma vitrine que exibe múltiplos planos em formato de cards, organizados em carrossel. Ideal para apresentar várias opções de forma dinâmica e atraente.',
  },
  {
    label: 'Destaque',
    value: 'featured',
    description:
      'Crie uma vitrine em destaque que exibe um plano de cada vez, garantindo maior visibilidade para cada opção. Perfeito para destacar ofertas específicas ou planos principais.',
  },
];
