import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { GrSearch as SearchIcon } from 'react-icons/gr';
import { MdKeyboardArrowLeft as ArrowLeft } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';

import useQuery from '../../../../hooks/useQuery';
import useWindowSize from '../../../../hooks/useWindowSize';

import CourseAPI from '../../../../api/Course';
import SignaturesAPI from '../../../../api/Signatures';

import ErrorResponse from '../../../../helpers/ErrorResponse';

import { Heading } from '../../../../components/Heading';
import Pagination from '../../../../components/Pagination';
import Toast from '../../../../components/Toast';

import { LARGE_SCREEN_SIZE } from '../constants';

import { FiAlertCircle } from 'react-icons/fi';
import List from './List';
import Table from './Table';

const perPage = 10;

const statusList = [
  {
    id: 1,
    name: 'Ativo',
    value: 'paid',
  },
  {
    id: 2,
    name: 'Cancelado',
    value: 'canceled',
  },
];

export type CurrentUserType = {
  subscriptionId: number;
  name: string;
};

const initialStateUser: CurrentUserType = {
  subscriptionId: null,
  name: '',
};

function Subscribers() {
  const history = useHistory();

  const query = useQuery();

  const { width } = useWindowSize();

  const { id } = useParams<{ id: string }>();

  const [subscribers, setSubscribers] = useState([]);

  const [course, setCourse] = useState<{ name: string }>({ name: '' });

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingCancel, setIsLoadingCancel] = useState(false);

  const [status, setStatus] = useState<string>('');

  const [search, setSearch] = useState<string>('');

  const [pageCount, setPageCount] = useState<number>(0);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [timer, setTimer] = useState<number>(0);

  const [user, setUser] = useState<CurrentUserType>(initialStateUser);

  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setSearch(value);
    setTimer(2);
  }

  function handleStatus(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    setStatus(value);
  }

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const { data: courseData } = await CourseAPI.show(Number(id));

          if (courseData) {
            setCourse(courseData);
          }
        } catch (error) {
          Toast(ErrorResponse(error), 'error');
        }
      })();
    }
  }, [id]);

  const getSubscribersList = useCallback(
    async (status: string) => {
      if (id) {
        try {
          setIsLoading(true);

          const { data: getSubscribersListData } = await SignaturesAPI.getSubscribersList({
            courseId: id,
            status,
            search,
            page: currentPage,
            perPage,
          });

          if (getSubscribersListData) {
            setSubscribers(getSubscribersListData.subscribersList);
            setPageCount(getSubscribersListData.total / getSubscribersListData.per_page);
          }
        } catch (error) {
          Toast(ErrorResponse(error), 'error');
        } finally {
          setIsLoading(false);
        }
      }
    },
    [id, search, currentPage]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        getSubscribersList(status);

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
  }, [currentPage, getSubscribersList, id, search, status, timer]);

  useEffect(() => {
    let page: string | number = query.get('page');

    if (page) {
      try {
        page = Number(page);

        if (page < 1) {
          return history.push(`/signatures/${id}/subscribers`);
        }
        setCurrentPage(page);
      } catch (error) {
        history.push(`/signatures/${id}/subscribers`);
      }
    }
  }, [history, query, id]);

  async function cancelSubscription(subscriptionId: number) {
    try {
      setIsLoadingCancel(true);
      const { data: cancel } = await SignaturesAPI.cancelSubscription({
        subscriptionId: subscriptionId,
      });

      if (cancel) {
        const updatedSubscribers = subscribers.map(subscriber => {
          if (subscriber.subscriptionId === subscriptionId) {
            return { ...subscriber, subscriptionStatus: cancel.subscriptionStatus };
          }
          return subscriber;
        });

        setSubscribers(updatedSubscribers);
        onClose();
        Toast('Assinatura cancelada!');
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  function handlePageChange({ selected: selectedPage }) {
    history.push(`/signatures/${id}/subscribers?page=${selectedPage + 1}`);
  }

  async function handleFilter() {
    await getSubscribersList(status);
  }

  return (
    <>
      <VStack as="section" marginTop="4" marginBottom="9" alignItems="flex-start">
        <Heading
          onClick={() => history.push('/signatures')}
          as="h3"
          fontSize="xl"
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          cursor="pointer"
        >
          <ArrowLeft size={30} color="#202123" />
          Lista de assinantes - {course?.name}
        </Heading>
      </VStack>

      <VStack
        as="section"
        gap="4"
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'none', lg: 'space-between' }}
      >
        <InputGroup justifyContent={{ base: 'auto', md: 'center', lg: 'flex-start' }}>
          <InputLeftElement
            display={{ base: 'none', lg: 'flex' }}
            pointerEvents="none"
            children={<SearchIcon color="textColor" />}
          />
          <Input
            id="search"
            name="search"
            placeholder="Pesquise aqui"
            onChange={handleSearch}
            value={search}
            size={width <= LARGE_SCREEN_SIZE ? 'sm' : 'md'}
            variant="outline"
            maxWidth={{ base: '100%', md: '25rem' }}
            paddingLeft={{ base: '3', lg: '10' }}
            fontSize={{ base: 'sm', lg: 'md' }}
            fontWeight="medium"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="borderInput.100"
            borderRadius="md"
            focusBorderColor="primary.400"
            _placeholder={{ color: 'blackAlpha.500' }}
            _focus={{
              borderColor: 'primary.500',
              boxShadow: '0 0 0 1px #ee6924',
            }}
            _hover={{
              borderColor: 'none',
            }}
          />
        </InputGroup>

        <Stack
          direction="row"
          width={{ base: '100%', md: '25rem' }}
          marginTop="0rem !important"
          display="flex"
          alignItems="center"
          gap="4"
        >
          <Select
            id="status"
            name="status"
            onChange={handleStatus}
            value={status}
            size={width <= LARGE_SCREEN_SIZE ? 'sm' : 'md'}
            variant="outline"
            color="textColor"
            fontSize="sm"
            fontWeight="medium"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="borderInput.100"
            borderRadius="md"
            focusBorderColor="primary.500"
            _placeholder={{ color: 'blackAlpha.500' }}
            _focus={{
              borderColor: 'primary.500',
              boxShadow: '0 0 0 1px #ee6924',
            }}
            _hover={{
              borderColor: 'none',
            }}
          >
            <option value="">Status</option>
            {statusList?.map(status => (
              <option key={status.id} value={status.value}>
                {status.name}
              </option>
            ))}
          </Select>

          <Button
            paddingY={{ base: '0.25rem', lg: '0.5rem' }}
            paddingX="4"
            colorScheme="primary"
            size={width <= LARGE_SCREEN_SIZE ? 'xs' : 'sm'}
            fontSize={{ base: 'xs', lg: 'sm' }}
            fontWeight="normal"
            onClick={handleFilter}
          >
            Filtrar
          </Button>
        </Stack>
      </VStack>

      <Stack as="section" marginTop="8">
        {width <= LARGE_SCREEN_SIZE && (
          <List id={id} subscribers={subscribers} onOpen={onOpen} user={user} setUser={setUser} />
        )}
        {width > LARGE_SCREEN_SIZE && (
          <Table
            id={id}
            subscribers={subscribers}
            isLoading={isLoading}
            width={width}
            onOpen={onOpen}
            user={user}
            setUser={setUser}
          />
        )}
      </Stack>

      <Stack as="section" marginY="5">
        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems="center" justifyContent="center">
            Cancelamento de Assinatura
          </ModalHeader>
          <ModalCloseButton
            style={{ outline: 'none', boxShadow: 'none' }}
            onClick={() => {
              setUser(initialStateUser);
              onClose();
            }}
          />

          <ModalBody
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <FiAlertCircle size="30px" color="#C53030" />
            <Text fontWeight={500} fontSize="md" my={5} textAlign="center">
              Deseja realmente cancelar a assinatura de{' '}
              <Text as="span" fontWeight={600}>
                {user?.name}
              </Text>
              ?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Stack direction="row" w="100%" justifyContent="center" spacing={3}>
              <Button
                style={{ outline: 'none', boxShadow: 'none' }}
                colorScheme="red"
                onClick={() => {
                  setUser(initialStateUser);
                  onClose();
                }}
              >
                Não
              </Button>
              <Button
                style={{ outline: 'none', boxShadow: 'none' }}
                colorScheme="red"
                variant="outline"
                onClick={() => cancelSubscription(user?.subscriptionId)}
                isDisabled={isLoadingCancel}
                isLoading={isLoadingCancel}
              >
                Sim
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Subscribers;
