import { Box, Button, Stack, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdArrowForwardIos } from 'react-icons/md';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CountrySelectMenu, { getDDI } from '../../../../components/CountrySelectMenu';

interface IProps {
  country: string;
  setCountry: (country: string) => void;
  setDDI: (ddi: string) => void;
  disableChangeCountry: boolean;
}

export default function StepBox({ country, setCountry, setDDI, disableChangeCountry }: IProps) {
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { productId }: { productId: string } = useParams();
  const [activeLink, setActiveLink] = useState('');
  const history = useHistory();

  const personalDataPage = `/launch-checkout/${productId}/personal-data?${urlSearchParams.toString()}`;
  const paymentPage = `/launch-checkout/${productId}/payment?${urlSearchParams.toString()}`;

  const isPaymentPage = activeLink === paymentPage;

  const steps = [
    { title: '1. Dados pessoais', path: personalDataPage },
    { title: '2. Pagamento', path: paymentPage },
  ];

  useEffect(() => {
    setActiveLink(location.pathname.concat(`?${urlSearchParams.toString()}`));
  }, [location, urlSearchParams]);

  const handleCountryChange = useCallback(
    (country: string) => {
      setDDI(getDDI(country));
      setCountry(country);
    },
    [setCountry, setDDI]
  );

  function onClick(path: string) {
    if (isPaymentPage) {
      return history.push(path);
    }
  }

  return (
    <Box
      bgColor="transparent"
      width={{ base: 'full', md: '572px', lg: '572px' }}
      display="flex"
      height={{ base: '24px', md: '28px', lg: '28px' }}
      padding="2px 16px"
      borderRadius="5px"
      background="#F4F4F4"
      textAlign="center"
    >
      <Box display="flex" width="full" alignItems="center" bgColor="transparent">
        {steps.map((step, index) => (
          <Stack
            as={Button}
            onClick={() => onClick(step.path)}
            size="xs"
            _hover={{ background: 'transparent' }}
            key={index}
            direction="row"
            alignItems="center"
            width="full"
            fontSize="14px"
            fontWeight="700"
            lineHeight="normal"
            spacing={{ base: '16px', md: '32px', lg: '32px' }}
            color={isPaymentPage || step.path === activeLink ? '#EB7129' : '#2D374880'}
            rightIcon={
              <Box width="24px" hidden={steps.length - 1 === index}>
                <MdArrowForwardIos />
              </Box>
            }
          >
            <Text
              width={{ base: '100px', md: '132px', lg: '132px' }}
              fontSize={{ base: '10px', md: '14px', lg: '14px' }}
            >
              {step.title}
            </Text>
          </Stack>
        ))}

        <CountrySelectMenu
          value={country}
          onChange={handleCountryChange}
          disableChangeCountry={disableChangeCountry}
        />
      </Box>
    </Box>
  );
}
