import { Select, SelectProps } from '@chakra-ui/react';
import { memo } from 'react';
import useLocales from '../../../../hooks/useLocales';

interface StateSelectProps extends SelectProps {
  value: string;
}

function StateSelect({ value, ...rest }: StateSelectProps) {
  const { states } = useLocales(value ?? '');

  return (
    <Select
      name="state"
      focusBorderColor="gray.300"
      borderRadius={6}
      placeholder="Selecione um Estado"
      value={value}
      {...rest}
    >
      {states.map(state => (
        <option key={state.id} value={state.sigla}>
          {state.nome}
        </option>
      ))}
    </Select>
  );
}

export default memo(StateSelect);
