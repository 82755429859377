import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { IoMdAlert as AlertIcon } from 'react-icons/io';

const ALERT_ICON_SIZE = '3.077rem'; // 40px

interface AlertModalProps extends Partial<ModalProps> {}

export default function AlertModal({ isOpen, onClose }: AlertModalProps) {
  const isCentered = useBreakpointValue({ base: true, md: false });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered} closeOnEsc={false}>
      <ModalOverlay bg="rgba(32, 33, 35, 0.15)" backdropFilter="auto" backdropBlur="3.5px" />

      <ModalContent maxW={{ base: '94%', md: '430px' }}>
        <ModalHeader display="flex" justifyContent="center">
          <AlertIcon color="#c05621" width="40px" height="40px" fontSize={ALERT_ICON_SIZE} />
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Heading fontSize="lg" fontWeight="medium" lineHeight="28px" textAlign="center">
            Parabéns por preencher o formulário!
          </Heading>

          <Text textAlign="center">
            Mas ainda não acabou, valide a sua biometria para conseguir realizar movimentações
            financeiras.
          </Text>
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}
