import styles from './styles.module.css';

function PlaybackRateSelect({ onChange }) {
  return (
    <select onChange={onChange} className={styles.customSelect}>
      <option value={1}>1x</option>
      <option value={1.25}>1.25X</option>
      <option value={1.5}>1.5X</option>
      <option value={1.75}>1.75X</option>
      <option value={2}>2x</option>
    </select>
  );
}

export default PlaybackRateSelect;
