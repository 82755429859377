import { Link } from 'react-router-dom';

const border = '1px solid #20212326';
const ERROR_COLOR = '#BB2124';

export const INPUT_STYLES = {
  focusBorderColor: 'default.500',
  errorBorderColor: ERROR_COLOR,
  color: '#202123',
};

export const FORM_STYLES = {
  label: { fontSize: 'sm', color: '#202123' },
  input: {
    _placeholder: { color: '#0000005C', fontsize: 'md' },
    border,
  },
  select: {
    border,
  },
  '.chakra-form__error-message': {
    color: ERROR_COLOR,
  },
};

export const LINK_BUTTON_STYLES = {
  as: Link,
  textDecoration: 'underline',
  fontWeight: 'medium',
  size: 'sm',
  variant: 'link',
};
