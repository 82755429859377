import dayjs from 'dayjs';
import { SubscriptionPaymentType } from '../../..';
import getPaymentMethod from '../../../utils/getPaymentMethod';
import Status from './Status';

import {
  Table,
  TableContainer,
  TableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import convertToBrazilianCurrency from '../../../../../../../helpers/convertToBrazilianCurrency';

interface ProductsTableProps extends TableProps {
  toggleModal: (pixQrCode: string) => void;
  paymentHistory: SubscriptionPaymentType[];
}

export default function SubscriptionsTable({
  paymentHistory: selectedProduct,
  toggleModal,
  ...rest
}: ProductsTableProps) {
  const tableBackground = useColorModeValue('#FFFFFF', '#37393D');
  const tableColor = useColorModeValue('#202123', '#FFFFFF');
  const oddRowColor = useColorModeValue('#2021230D', '#00000040');

  return (
    <TableContainer
      border="1px solid #2021230D"
      p={5}
      borderRadius="md"
      bg={tableBackground}
      {...rest}
    >
      <Table>
        <Thead fontWeight="bold">
          <Tr sx={{ th: { color: tableColor } }}>
            <Th>Pagamento</Th>
            <Th>Nome do Plano</Th>
            <Th>Tipo</Th>
            <Th>Data</Th>
            <Th>Valor</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>

        <Tbody
          sx={{
            'tr:nth-of-type(odd)': {
              background: oddRowColor,
            },
          }}
        >
          {selectedProduct.map(payment => (
            <Tr key={payment.transactionId}>
              <Td>{payment.installment}</Td>
              <Td>{payment.planName}</Td>
              <Td>{getPaymentMethod(payment.paymentMethod)}</Td>
              <Td>{dayjs(payment.createdAt).format('DD/MM/YYYY')}</Td>
              <Td> {convertToBrazilianCurrency(payment.purchasePrice)}</Td>
              <Td>
                <Status
                  paymentMethod={payment.paymentMethod}
                  paymentStatus={payment.purchaseStatus}
                  link={payment.link}
                  onClick={() => toggleModal(payment.link)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
