import { Button, HStack, Select, Stack, StackProps } from '@chakra-ui/react';
import { ChangeEvent, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SearchInput from './SearchInput';

export default function IntegrationsFilter({ ...props }: StackProps) {
  const { push } = useHistory();
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [search, setSearch] = useState(queryParams.get('search') ?? '');
  const [status, setStatus] = useState(queryParams.get('status') ?? '');

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    queryParams.set('search', value);

    setSearch(value);
  }

  function handleStatusFilterChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    queryParams.set('status', value);

    setStatus(value);
  }

  function handleFilter() {
    queryParams.forEach((value, key) => {
      !value && queryParams.delete(key);
    });

    push({ search: queryParams.toString() });
  }

  return (
    <Stack
      direction={{ base: 'column', xl: 'row' }}
      align="center"
      justify="space-between"
      {...props}
    >
      <SearchInput value={search} onChange={handleSearchChange} onFilter={handleFilter} />

      <HStack w="full" maxWidth={{ base: 'unset', xl: '11rem' }}>
        <Select
          value={status}
          onChange={handleStatusFilterChange}
          focusBorderColor="gray.300"
          borderColor="gray.300"
          borderWidth="1px"
          fontSize="sm"
          size="sm"
          borderRadius={6}
          color="#0000005C"
        >
          <option value="">Todas</option>
          <option value="ACTIVE">Ativas</option>
          <option value="DISABLED">Inativos</option>
        </Select>

        <Button
          onClick={handleFilter}
          flexShrink={0}
          size="xs"
          colorScheme="primary"
          color="secondary.500"
        >
          Filtrar
        </Button>
      </HStack>
    </Stack>
  );
}
