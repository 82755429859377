import Joi from 'joi';
import { EntitySchema } from './EntitySchema';

export type FormOfPaymentType = 'FREE' | 'INSTALLMENTS_WITH_FEE' | 'INSTALLMENTS_WITHOUT_FEE';

export type CoursePriceType = {
  default: boolean;
  formOfPayment: FormOfPaymentType;
  id: number;
  installments: number;
  name: string;
  purchasePrice: number;
  pricesVariedStatus: boolean;
  purchasePriceBoleto?: number;
  purchasePricePix?: number;
};

const CoursePriceSchema: EntitySchema<CoursePriceType> = {
  id: Joi.number().messages({
    'number.base': 'Informe um valor válido para o campo "id"',
  }),
  default: Joi.boolean().default(false).messages({
    'boolean.base': 'Informe um valor válido para o campo "default"',
  }),
  formOfPayment: Joi.string()
    .valid('FREE', 'INSTALLMENTS_WITH_FEE', 'INSTALLMENTS_WITHOUT_FEE')
    .messages({
      'string.empty': 'O campo "formOfPayment" não pode ficar vazio.',
      'any.only': 'Informe a forma de pagamento',
    }),
  installments: Joi.number().default(1).messages({
    'number.base': 'Informe a quantidade de parcelas',
  }),
  name: Joi.string().messages({
    'string.empty': 'O campo nome não pode ficar vazio.',
    'string.base': 'Informe um valor válido para o campo "name"',
  }),
  purchasePrice: Joi.number()
    .min(1)
    .max(999999)
    .messages({
      'number.base': 'Informe um valor válido para o produto',
      'number.min': 'O valor mínimo do produto é R$ 1,00',
      'number.max': 'O valor máximo do produto é R$ 999.999,00',
      'number.unsafe': 'Informe um valor válido para o produto',
    } as JoiMessages.LanguageMessages),
  pricesVariedStatus: Joi.boolean().default(false),
  purchasePriceBoleto: Joi.number()
    .positive()
    .min(1)
    .max(999999)
    .messages({
      'number.positive': 'Informe um valor válido para o produto no boleto.',
      'any.required': 'Informe um valor válido para o produto no boleto.',
      'number.base': 'Informe um valor válido para o produto.',
      'number.min': 'O valor mínimo do produto é R$ 1,00.',
      'number.max': 'O valor máximo do produto é R$ 999.999,00.',
      'number.unsafe': 'Informe um valor válido para o produto.',
    } as JoiMessages.LanguageMessages),
  purchasePricePix: Joi.number()
    .positive()
    .min(1)
    .max(999999)
    .messages({
      'any.required': 'Informe um valor válido para o produto no pix.',
      'number.base': 'Informe um valor válido para o produto.',
      'number.min': 'O valor mínimo do produto é R$ 1,00.',
      'number.max': 'O valor máximo do produto é R$ 999.999,00.',
      'number.unsafe': 'Informe um valor válido para o produto.',
    } as JoiMessages.LanguageMessages),
};

export default CoursePriceSchema;
