import { useEffect, useState } from 'react';

import { Heading } from '../../../../../../../components/Heading';
import ImageInput from '../components/ImageInput';
import ImagePreview from '../components/ImagePreview';
import Section from '../components/Section';
import Spinner from '../../../../../../../components/Loadings/Spinner';
import styles from './styles.module.css';

const FIRST_FILE = 0;
const MAX_SIZE_FILE_PHOTO = 3145728;

function Thumbnail({ onChange, thumbnail, isLoadingCourse }) {
  const [preview, setPreview] = useState(thumbnail);

  const [hasChanged, setHasChanged] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (thumbnail) setPreview(thumbnail);
  }, [thumbnail]);

  function handlePhotoChange(acceptedFiles) {
    const file = acceptedFiles[FIRST_FILE];

    if (file?.size > MAX_SIZE_FILE_PHOTO) {
      return setErrorMessage('O tamanho da imagem deve ser de no máximo 3 MB.');
    }

    if (file) {
      setPreview(URL.createObjectURL(file));

      setErrorMessage(null);
      setHasChanged(true);

      onChange(file);
    }
  }

  function handleImageDropRejected() {
    setErrorMessage('Arquivo inválido!');
  }

  function handleImageChange() {
    setHasChanged(true);
    setPreview(null);
  }

  return (
    <Section>
      <div className="mb-4">
        <Heading as="h4" fontSize="2xl">
          Thumbnail
        </Heading>
      </div>
      <div className={styles.thumbnailContainer}>
        <section className={styles.description}>
          <p>
            Faça o upload da imagem do seu curso aqui. <br /> Escolha uma imagem bonita e chamativa,
            lembre-se que ela será o primeiro contato que seus alunos terão com seu produto. Suas
            dimensões devem ser: <strong>1080x1080 pixels</strong>, e devem está nos formatos:{' '}
            <strong>.jpg</strong>, <strong>.jpeg</strong> ou <strong>.png</strong>.
          </p>
        </section>

        <section className={styles.thumbnail}>
          <div className="form-group">
            {!preview ? (
              <ImageInput
                onDrop={handlePhotoChange}
                onDropRejected={handleImageDropRejected}
                onChangeImage={handleImageChange}
                imageDimensions="1080x1080"
              />
            ) : (
              <ImagePreview preview={preview} onClick={handleImageChange} />
            )}
          </div>
          <div>
            {hasChanged && (
              <button
                className="btn btn-primary w-100"
                style={{ fontSize: '0.875rem' }}
                disabled={isLoadingCourse}
              >
                {isLoadingCourse ? <Spinner small /> : 'Salvar alterações'}
              </button>
            )}
          </div>
        </section>
      </div>

      {errorMessage && !hasChanged && (
        <div className="alert alert-danger mb-4" role="alert" style={{ borderRadius: '0' }}>
          <div className="text-center">{errorMessage}</div>
        </div>
      )}
    </Section>
  );
}

export default Thumbnail;
