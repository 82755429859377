import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Flex,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react';
import { ResponsiveLine, Serie } from '@nivo/line';
import dayjs from 'dayjs';
import { PaymentEvolutionOfRecurrencesAndCancellationsType } from '../..';

interface SalesVsCancellationsChartProps extends CardProps {
  data: PaymentEvolutionOfRecurrencesAndCancellationsType[];
  diffInDays: number;
}

function formatData(data: PaymentEvolutionOfRecurrencesAndCancellationsType[], format: string) {
  if (!data.length) return [];

  const sales = {
    id: 'Vendas',
    data: [] as { x: string; y: number; realValue: number }[],
  };

  const cancellations = {
    id: 'Cancelamentos',
    data: [] as { x: string; y: number; realValue: number }[],
  };

  data.forEach(item => {
    const formattedDate = dayjs(item.date).format(format);

    sales.data.push({ x: formattedDate, y: item.numberOfSales, realValue: item.numberOfSales });

    // Verifica se o valor de vendas e cancelamentos são iguais
    let yValue = item.numberOfRecurrencesCanceled;
    if (item.numberOfSales === item.numberOfRecurrencesCanceled) {
      yValue = item.numberOfRecurrencesCanceled - 0.075; // Aplica o offset se forem iguais para uma melhor visualização
    }

    cancellations.data.push({
      x: formattedDate,
      y: yValue,
      realValue: item.numberOfRecurrencesCanceled,
    });
  });

  return [sales, cancellations];
}

const CustomTooltip = ({ point }: { point: any }) => {
  const realValue = point.data.realValue ?? point.data.y;

  return (
    <Flex
      position="relative"
      justify="center"
      direction="column"
      align="center"
      p={2}
      bg="white"
      border="1px solid #20212340"
      borderRadius="md"
      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
    >
      <Box as="strong" display="block" mb={1}>
        {point.serieId}
      </Box>

      {realValue}

      <Box
        position="absolute"
        bottom="-10px"
        left="50%"
        transform="translateX(-50%)"
        width="0"
        height="0"
        borderStyle="solid"
        borderWidth="10px 10px 0 10px"
        borderColor="white transparent transparent transparent"
        boxShadow="md"
      />
    </Flex>
  );
};

const colors = ['#7FBBB6', '#E85347'];

const theme = {
  axis: {
    ticks: {
      text: {
        fontSize: 18,
        fontWeight: 500,
        fontFamily: 'Montserrat, sans-serif',
        fill: '#20212380',
      },
    },
  },
};

export default function SalesVsCancellationsChart({
  data,
  diffInDays,
  ...rest
}: SalesVsCancellationsChartProps) {
  const format = diffInDays > 30 ? 'MMM' : 'DD/MM';
  const formattedData: Serie[] = formatData(data, format);

  return (
    <Card
      border="1px solid rgba(32, 33, 35, 0.25)"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      padding={4}
      minHeight="22rem"
      {...rest}
    >
      <CardHeader padding={0}>
        <HStack justify="space-between" color="primary.500">
          <Heading as="h3" fontSize="sm" fontWeight="semibold" m={0}>
            Gráfico de Evolução de Pagamento de Assinaturas e Cancelamentos
          </Heading>
        </HStack>
      </CardHeader>

      <CardBody padding={0}>
        {formattedData.length === 0 ? (
          <Text color="#0000007D">Nenhum resultado encontrado</Text>
        ) : (
          <Box height="22rem">
            <ResponsiveLine
              data={formattedData}
              margin={{ top: 48, right: 20, bottom: 50, left: 64 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 28,
                tickRotation: 0,
                tickValues: 7,
              }}
              colors={colors}
              pointSize={10}
              pointColor={{ from: 'color' }}
              pointBorderWidth={0}
              pointLabelYOffset={-12}
              useMesh={true}
              tooltip={CustomTooltip}
              theme={theme}
              enableGridX={false}
              legends={[
                {
                  anchor: 'top-left',
                  direction: 'row',
                  justify: false,
                  translateX: -64,
                  translateY: -40,
                  itemsSpacing: 32,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </Box>
        )}
      </CardBody>
    </Card>
  );
}
