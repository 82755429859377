import {
  Avatar,
  Image,
  Stack,
  Table as TableChakra,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ActionButton, ParamsType } from '../..';
import convertToBrazilianCurrency from '../../../../../../helpers/convertToBrazilianCurrency';

export default function Table({
  themeMarketplace,
  affiliateProducts,
  setAffiliationId,
  setCourseAffiliationName,
  onOpenRemoveProduct,
}: ParamsType) {
  return (
    <TableContainer>
      <TableChakra
        variant="unstyled"
        style={{
          borderCollapse: 'separate',
          borderSpacing: '0 8px',
        }}
      >
        <Thead bgColor={themeMarketplace.primaryColor} color={themeMarketplace.textColor} h={46}>
          <Tr>
            <Th borderLeftRadius={10} fontSize="14px" fontWeight={500} textTransform="none">
              Nome do Produto
            </Th>
            <Th fontSize="14px" fontWeight={500} textTransform="none">
              Preço/Comissão
            </Th>
            <Th fontSize="14px" fontWeight={500} textTransform="none">
              Nº de vendas
            </Th>
            <Th fontSize="14px" fontWeight={500} textTransform="none">
              Ganhos
            </Th>
            <Th borderRightRadius={10} fontSize="14px" fontWeight={500} textTransform="none">
              Ações
            </Th>
          </Tr>
        </Thead>
        {affiliateProducts.length > 0 &&
          affiliateProducts.map(affiliateProduct => (
            <Tbody bgColor="#20212310" key={affiliateProduct.affiliationId}>
              <Tr>
                <Td p={0} borderLeftRadius={10}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    as={Link}
                    to={`/affiliations/my-affiliations/${affiliateProduct.courseAffiliationId}`}
                  >
                    {affiliateProduct?.thumbnail ? (
                      <Image
                        src={affiliateProduct?.thumbnail}
                        boxSize={51}
                        borderRadius={5}
                        objectFit="contain"
                      />
                    ) : (
                      <Avatar
                        name={affiliateProduct?.productName}
                        bg={themeMarketplace.primaryColor}
                        color={themeMarketplace.textColor}
                        boxSize={51}
                        borderRadius={5}
                        objectFit="contain"
                      />
                    )}

                    <Text maxW={175} isTruncated>
                      {affiliateProduct?.productName}
                    </Text>
                  </Stack>
                </Td>
                <Td p={0} textAlign="center">
                  <Stack direction="column" alignItems="flex-start" spacing={0}>
                    <Text fontSize="16px">
                      {' '}
                      {convertToBrazilianCurrency(affiliateProduct?.productValue)}
                    </Text>
                    <Text fontSize="12px">
                      <Text as="span" color="#22BB33">
                        {affiliateProduct?.commissionValue}%
                      </Text>{' '}
                      de comissão
                    </Text>
                  </Stack>
                </Td>

                <Td p={0} textAlign="center">
                  {affiliateProduct?.amountOfSales}
                </Td>
                <Td p={0} textAlign="center" color="#22BB33">
                  {convertToBrazilianCurrency(affiliateProduct?.winnings)}
                </Td>
                <Td p={0} textAlign="center" borderRightRadius={10}>
                  <ActionButton
                    affiliationId={affiliateProduct.affiliationId}
                    courseAffiliationId={affiliateProduct.courseAffiliationId}
                    productName={affiliateProduct.productName}
                    onOpenRemoveProduct={onOpenRemoveProduct}
                    setAffiliationId={setAffiliationId}
                    setCourseAffiliationName={setCourseAffiliationName}
                  />
                </Td>
              </Tr>
            </Tbody>
          ))}
      </TableChakra>
    </TableContainer>
  );
}
