import { FormEvent, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import useHandleChange from '../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../utils/scrollToInvalidInput';
import schemaValidator from './schemaValidator';
import { CheckoutSettingsEditPaymentForm, CheckoutSettingsEditPaymentResponse } from './types';

export interface UseCheckoutSettingsEditPaymentProps {
  onIsLoadingChange: (isLoading: boolean) => void;
}

export default function useCheckoutSettingsEditPayment({
  onIsLoadingChange,
}: UseCheckoutSettingsEditPaymentProps) {
  const { id } = useParams<{ id: string }>();
  const { push, goBack } = useHistory();
  const location = useLocation();

  const {
    data: response,
    loading: isLoading,
    error,
  } = useFetch<UnificadaFront.ResponseJSON<CheckoutSettingsEditPaymentResponse>>({
    method: 'get',
    url: `/course-checkout-settings/${id}/payment`,
    authenticated: true,
    autoFetch: true,
  });

  useEffect(() => {
    if (!!error) goBack();
  }, [error, goBack]);

  const registeredPaymentSettings = response?.data;

  useEffect(() => {
    onIsLoadingChange(isLoading);
  }, [isLoading, onIsLoadingChange]);

  const {
    form,
    onChanged: hasChange,
    setForm,
    handleCancel,
    setOnChanged: setHasChange,
  } = useHandleChange<CheckoutSettingsEditPaymentForm>(
    {},
    registeredPaymentSettings && {
      paymentMethod: registeredPaymentSettings?.paymentMethod ?? [],
      daysThatCanGenerateBoleto: registeredPaymentSettings?.daysThatCanGenerateBoleto ?? [],
      userFormForPurchase: registeredPaymentSettings?.userFormForPurchase ?? [],
    }
  );

  function handleChange(name: string, value: Array<string | number>) {
    setForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }));

    setHasChange(true);
  }

  function handleCancelButtonClick() {
    if (!hasChange) {
      return push('/checkout-settings');
    }

    handleCancel();
  }

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: form,
    url: `/course-checkout-settings/${id}/payment`,
    method: 'patch',
    authenticated: true,
    schemaValidator,
    onSuccess: {
      callback: () =>
        push({
          pathname: `/checkout-settings/edit/${id}/customization`,
          search: location.search,
        }),
    },
  });

  const parsedValidation = formValidation as any;

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  return {
    form,
    isSubmitting,
    formValidation: parsedValidation,
    hasChange,
    handleChange,
    handleCancelButtonClick,
    onSubmit,
  };
}
