import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Container } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import FullPageLoading from '../../../../components/FullPageLoading';
import CheckoutSettingsEditCustomization from './CheckoutSettingsEditCustomization';
import CheckoutSettingsEditPageThanks from './CheckoutSettingsEditPageThanks';
import CheckoutSettingsEditPayment from './CheckoutSettingsEditPayment';
import Header from './Header';

export default function CheckoutSettingsEdit() {
  const [isLoading, setIsLoading] = useState(false);

  const match = useRouteMatch();
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const productName = queryParams.get('productName');
  const productId = queryParams.get('productId');

  const handleIsLoadingChange = useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  const isCustomizationPageActive =
    pathname.includes('customization') || pathname.includes('page-thanks');

  const isPageThanksActive = pathname.includes('page-thanks');

  const queryString = queryParams.toString();

  return (
    <>
      <Box minHeight="100vh" pb={10}>
        {!isLoading && <Header productId={Number(productId)} productName={productName} />}

        <Container as="main" w="full" maxW="790px" px={{ base: 4, xl: 'none' }}>
          <Breadcrumb
            mt={{ base: '1.875rem', xl: 14 }}
            spacing="8px"
            separator={<ChevronRightIcon />}
            color="#20212380"
            fontSize={{ base: 'xs', xl: 'lg' }}
            sx={{
              '.chakra-breadcrumb__list-item': {
                _hover: {
                  color: 'blackAlpha.900',
                },
              },
              '[aria-current="page"]': {
                color: 'blackAlpha.900',
                '+ span[role="presentation"]': {
                  color: 'blackAlpha.900',
                },
              },
            }}
          >
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink as={Link} to={`${match.url}/payment`}>
                Pagamento
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage={isCustomizationPageActive}>
              <BreadcrumbLink as={Link} to={`${match.url}/customization?${queryString}`}>
                Personalização
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage={isPageThanksActive}>
              <BreadcrumbLink as={Link} to={`${match.url}/page-thanks?${queryString}`}>
                Página de obrigado
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Switch>
            <Route path={`${match.path}/payment`}>
              <CheckoutSettingsEditPayment onIsLoadingChange={handleIsLoadingChange} />
            </Route>

            <Route path={`${match.path}/customization`}>
              <CheckoutSettingsEditCustomization onIsLoadingChange={handleIsLoadingChange} />
            </Route>

            <Route path={`${match.path}/page-thanks`}>
              <CheckoutSettingsEditPageThanks onIsLoadingChange={handleIsLoadingChange} />
            </Route>
          </Switch>
        </Container>
      </Box>

      <FullPageLoading isLoading={isLoading} />
    </>
  );
}
