import Joi from 'joi';
import { EventName, Webhook, WebhookType } from './types';

const INVALID_URI_MESSAGE = 'Por favor, forneça uma URL válida. Exemplo: https://www.example.com';

const eventsWebhook = [
  { description: 'Adicionou no carrinho', name: 'ADD_TO_CART' },
  { description: 'Visitou página de vendas', name: 'PAGE_VIEW' },
  { description: 'Inscrição gratuita', name: 'FREE_SIGN_UP' },

  { description: 'Emissão de Boleto', name: 'ISSUANCE_BOLETO' },
  { description: 'Emissão de Pix', name: 'ISSUANCE_PIX' },

  { description: 'Compra Aprovada', name: 'PURCHASE_APPROVED' },
  { description: 'Pagamento Recusado', name: 'PAYMENT_REFUSED' },
  { description: 'Estorno', name: 'REFUNDED' },
  { description: 'Pagamento Estornado', name: 'CHARGEDBACK' },

  { description: 'Assinatura Paga', name: 'RECURRENCE_PAID' },
  { description: 'Assinatura Cancelada', name: 'RECURRENCE_CANCELED' },
  { description: 'Assinatura Finalizada', name: 'RECURRENCE_ENDED' },
  { description: 'Assinatura Pendente de Pagamento', name: 'RECURRENCE_PENDING_PAYMENT' },
  { description: 'Assinatura não Paga', name: 'RECURRENCE_UNPAID' },
];

const eventsNameWebhook = eventsWebhook.map(event => event.name) as EventName[];

export const webhookSchemaValidator = Joi.object<Webhook>().keys({
  webhookName: Joi.string().min(3).max(255).required().messages({
    'string.empty': 'Nome do webhook não pode ficar vazio.',
    'string.min': 'Deve ter pelo menos 3 caracteres.',
  }),
  events: Joi.array()
    .items(
      Joi.string()
        .valid(...eventsNameWebhook)
        .required()
    )
    .min(1)
    .required()
    .messages({
      'array.includesRequiredUnknowns': 'Selecione pelo menos 1 evento.',
    }),
  webhookType: Joi.string<WebhookType>().valid('product', 'recurrence_plan').required(),
  coursesIds: Joi.when('webhookType', {
    is: 'product',
    then: Joi.array()
      .items(Joi.number().integer().positive().required())
      .required()
      .allow(null)
      .required()
      .messages({
        'array.base': 'Selecione pelo menos um produto.',
        'any.required': 'Selecione pelo menos um produto.',
        'any.allow': 'Selecione pelo menos um produto.',
      }),
    otherwise: Joi.forbidden(),
  }),
  recurrencePlansIds: Joi.when('webhookType', {
    is: 'recurrence_plan',
    then: Joi.array()
      .items(Joi.number().integer().positive().required())
      .allow(null)
      .required()
      .messages({
        'array.base': 'Selecione pelo menos um plano.',
        'any.required': 'Selecione pelo menos um plano.',
        'any.allow': 'Selecione pelo menos um plano.',
      }),
    otherwise: Joi.forbidden(),
  }),
  webhookUrl: Joi.string().min(3).max(255).uri().required().messages({
    'string.uri': INVALID_URI_MESSAGE,
    'string.empty': 'URL não pode ficar vazia.',
    'any.required': 'URL não pode ficar vazia.',
  }),
  status: Joi.string(),
});
