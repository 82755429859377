import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import useFetch from '../../../../../../hooks/useFetch';

export function ModalAddAffiliate({
  isOpenAddAffiliate,
  onCloseAddAffiliate,
  handleSelectUser,
  sizeAddAffiliateModal,
  userId,
  productId,
  isSubmitting,
  onSubmit,
}) {
  const {
    loading,
    data: response,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON>({
    method: 'get',
    url: `/affiliations/list-users?productId=${productId}`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (productId) {
      fetchData();
    }
  }, [fetchData, productId]);

  const affiliateRoles = response?.data;
  const fetchOrSubmitting = loading || isSubmitting;

  return (
    <Modal
      isOpen={isOpenAddAffiliate}
      onClose={onCloseAddAffiliate}
      size={sizeAddAffiliateModal}
      isCentered
    >
      <ModalOverlay />
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalHeader>Adicionar afiliado</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading && (
              <Stack w="100%" justifyContent="center" alignItems="center" my={2}>
                <Spinner size="lg" color="#EB7129" />
              </Stack>
            )}

            {!loading && affiliateRoles?.length > 0 && (
              <Stack>
                <Text fontWeight={500}>Selecione um afiliado</Text>
                <Text fontWeight={400} color="#718096">
                  O usuário deve ser do tipo “Afiliado” e precisa ter cadastrado os dados bancários.
                </Text>
                <Select
                  placeholder="Selecionar"
                  size="sm"
                  onChange={handleSelectUser}
                  value={userId}
                  borderRadius={6}
                  isRequired
                  focusBorderColor="primaryColor"
                >
                  {affiliateRoles?.map(user => (
                    <option key={user.id} value={user.id}>
                      {user.fullName}
                    </option>
                  ))}
                </Select>
              </Stack>
            )}

            {!loading && !affiliateRoles?.length && (
              <Stack>
                <Text fontWeight={500} color="#718096">
                  Todos os afiliados já estão adicionados a esse produto, ou você não possui
                  usuários do tipo “Afiliado”.
                </Text>
              </Stack>
            )}
          </ModalBody>

          <ModalFooter>
            <Stack direction="row" alignItems="center" justifyContent="end" spacing={3}>
              <Button
                colorScheme="gray"
                variant="solid"
                isDisabled={isSubmitting}
                onClick={onCloseAddAffiliate}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                isLoading={fetchOrSubmitting}
                isDisabled={fetchOrSubmitting || !userId}
                colorScheme="primary"
                color="textColor"
              >
                Adicionar
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
