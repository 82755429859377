import QRCodeReact from 'qrcode.react';

const PROLUNO_ICON =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAwFBMVEUZGx3///9YurX/cQAAAABbwr3/cwAAEx4MGB0ZGBsVBAv/dQBhMxlsNxgWGBoxMjMNEBPNXQ1cXF1FtK/0+vkfKywSAADm5+cSFRft7e34+Pjl5eXPz9C8vLyMjI0AAAaysrNfysQpKyzW1tfFxsZsbW4hIyVNTk88Pj//aACampukpaWRkZJtbW6Dg4QkJihBQkN5eXr/8urKUgBQLRr/7eT/2Mb/sYv/gzf/y7P/eBv/jEk5SE4ABhZjZGUvIRwCHX+vAAAEnklEQVR4nO3ce3vaNhTHcbQqTdZutkO3xcY2UKCYEsit7brskr3/dzWT5lnpVnx8hnyO5P2+/9cPn8rxRUgMBgghhBBCCCGEEEIIIYQQQgghhBBCCCH01b7lpP1h/1t9B754fdK+1z8GiHxx8k37TiD0MQgh9D8IIfQ/CCH0Pwgh9D8I/3fCNGpI2/L1mMLDB4rSif3UJPHKyhK+Mo2NRllelJfjh9nOmWrTnuIJf2gmfi4vx5Unyo6EjxWb2Fp1ZJfC3ViuY5v0Wlg339pJv4XGZFea4yghrI1bvb9HGWF9rsa250JjxlbnQUBOaAqdM1VQaLJrjQuOpNCMVgr3DVGhMSv5URQWjmbiRGGhycSvqNLC+orad2F9X+y70MSyt0UF4Vx2EBWE5o3o9VRDmIkOoobQ3EoOooowlxxEFaGpBAdRR1gKDuI/17W9asyV0Eiepj99kSU+WZZleT6fF8WiriynnyoXRZ6xhFu1qSlKGNnDxdvLeVuh5GnKEzY9caVJzXyzaCUcBSncFSV2OW1DvNGaXTxWWBfZVU4Lx1rz4HZ0tHAwSCx9qqr9IToR1sNIEi/CFtZE8uahND/sSjhIKkq4UrrUuBIObEEIZV8S9z4YcXa1FiZDQqh1MXUmJM+GS6VLjUNh2XwkrduFO+Fk03wk8XnTp9wJk4fmIwnPuP2dO2G6aj6S6EzGXu6E0bL3wqT5SLJTip+DkCGMPBVeOBPOmo+k9XLhTpjeNB9J7UrjTOjt/dCZ0NtnGmKKpQfPpc7GkLoqT0MXUhcas9F6P3QltNSsqdo7viMh9USjOE/jSEhdZ2S/ffrik7kR2lsKqHXDd3S3sNQ0lOactwNhaq9IoLlS+97iaGFiZ9RUadv/qk46Thgl1lbkNWaX1rsTLZwl6VP72/LSNEl2O9eih2nLL7u1nmho4bCqqpvVKo5ns+vlcln/i2iwvI5vhrebacH4Kn+o9z0+cbdwlN6X3FJCrSl9OeFMb8+ejFDr7VdOKLmsTUWoOYQyQrW1NI/CFkthjk3vbv8o7H4MR1qrMMSEeo8zQkLFm72McG5PD3QmJOQthGV39+78UO9liB0L754/e36gl9/1QVgDD9YL4YfDvn4If37bc+HHRmD4wg+/NAODFxIDGLzw/hkJDFp4/yvtExQSq0LZ3X1sMX7hCu/uf3vbzhekcL7+/V1bXnDCvNxU1to/zhue0tSE1MCUi6KY5/lFtmu0V5Zd5MViur4aPv4e3e419zRE4bphd96/flMwSCHrDR1CCDuJErLmOiGEsJN6LySXo7EW+ngpTHsvJDa6mEXwQmILAe/LTR+FaUwIWSvsvRRSa5fDF1JbsIMXkpvMWQtfvRRSS19Zu5WCFLJWTXopJDYr8ZbceSncEkLWEnsvheQa+9CFE1I4YCyG8VJIrrLnrED3UjimhJzNPD4KLbEtkrdw0kvhmhI+hC68pIScH+YMU8jZzeOlkPw1uTXjhuilkPwVMs5k2+n5y0NrvNRWfaXVkIizgvns/feM/uyOtV+aELGWaJ9x6oqEEEIIIYQQQgghhBBCCCGEEEIIIYQQCr2/ADs5qfWhd4/TAAAAAElFTkSuQmCC';

type PixCodeType = {
  value: string;
};

function QRCode({ value }: PixCodeType) {
  return (
    <QRCodeReact
      value={value}
      renderAs="svg"
      imageSettings={{
        src: `${PROLUNO_ICON}`,
        x: null,
        y: null,
        height: 24,
        width: 24,
        excavate: true,
      }}
    />
  );
}

export default QRCode;
