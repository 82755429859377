import { Box, Flex, Image, MenuItem, Text } from '@chakra-ui/react';
import { memo } from 'react';

interface CountryProps {
  country: {
    country: string;
    initials: string;
    flag: string;
  };
  onClick?: (country: string) => void;
}

const Country = memo(({ country, onClick }: CountryProps) => {
  function handleClick() {
    onClick(country.initials);
  }

  return (
    <MenuItem onClick={handleClick}>
      <Flex align="center" gap="5px">
        {country.flag ? (
          <Image
            src={country?.flag}
            w={{ base: '22px', md: '28px', lg: '28px' }}
            h={{ base: '16px', md: '18px', lg: '18px' }}
            alt={country.country}
            borderRadius="base"
            objectFit="cover"
          />
        ) : (
          <Box
            bg="gray.200"
            w={{ base: '22px', md: '28px', lg: '28px' }}
            h={{ base: '16px', md: '18px', lg: '18px' }}
            borderRadius="base"
          />
        )}

        <Text width={{ base: 'auto', md: '160px', lg: '160px' }} isTruncated>
          {country.country}
        </Text>
      </Flex>
    </MenuItem>
  );
});

export default Country;
