import { Card, CardBody, CardHeader, Heading, HStack, Icon, Text } from '@chakra-ui/react';
import {
  MdAttachMoney as MoneyIcon,
  MdCreditCard as RecurrenceIcon,
  MdBlock as CancellationIcon,
} from 'react-icons/md';
import { IconType } from 'react-icons';

const iconMap: { [key: string]: IconType } = {
  money: MoneyIcon,
  recurrence: RecurrenceIcon,
  cancellation: CancellationIcon,
};

interface InfoCardProps {
  title: string;
  value: string | number;
  icon: 'money' | 'recurrence' | 'cancellation';
}

export default function InfoCard({ title, icon, value }: InfoCardProps) {
  return (
    <Card
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      padding={4}
      border="1px solid rgba(32, 33, 35, 0.25)"
    >
      <CardHeader padding={0}>
        <HStack justify="space-between" color="primary.500">
          <Heading as="h3" fontSize="sm" fontWeight="medium" m={0}>
            {title}
          </Heading>

          <Icon as={iconMap[icon]} w={6} h={6} mr={2} />
        </HStack>
      </CardHeader>

      <CardBody padding={0} mt={5}>
        <Text color="#202123" fontSize="2xl" fontWeight="semibold">
          {value}
        </Text>
      </CardBody>
    </Card>
  );
}
