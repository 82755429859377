import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  IconButton,
  List,
  ListItem,
  ListProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { formatDate, getActionTextByStatus, getStatusColor } from '../utils';
import { Link } from 'react-router-dom';
import { PromotionView } from '../types';

interface PromotionsMobileListProps extends ListProps {
  promotions: PromotionView[];
  onPromotionStatusChange: () => void;
  onSelectPromotionToEdit: (promotion: PromotionView) => void;
}

export default function PromotionsMobileList({
  promotions,
  onPromotionStatusChange,
  onSelectPromotionToEdit,
  ...rest
}: PromotionsMobileListProps) {
  function handlePromotionStatusChange() {
    onPromotionStatusChange();
  }

  function handleSelectPromotionToEdit(promotion: PromotionView) {
    onSelectPromotionToEdit(promotion);
  }

  return (
    <List {...rest} spacing={2.5}>
      {promotions.map(promotion => (
        <Card
          key={promotion.id}
          as={ListItem}
          border="1px solid #2021230D"
          boxShadow="0px 4px 4px 0px #00000040"
        >
          <CardHeader p={4} pb={0}>
            <HStack justify="space-between">
              <Heading as="h2" fontSize="md" fontWeight="medium" color="#202123" m={0} isTruncated>
                {promotion.name}
              </Heading>

              <Menu size="sm" arrowPadding={0}>
                <MenuButton
                  onClick={() => handleSelectPromotionToEdit(promotion)}
                  size="xs"
                  as={IconButton}
                  icon={<MoreIcon size="0.75rem" />}
                  w="1.125rem"
                  h="1rem"
                  minW="unset"
                  borderRadius={5}
                  bgColor="#2021231F"
                  _hover={{ bgColor: '#b6b5b5' }}
                  _active={{ bgColor: '#b6b5b5' }}
                />

                <MenuList
                  sx={{
                    '.chakra-menu__menuitem': {
                      fontWeight: 'medium',
                      fontSize: 'xs',
                      color: '#20212380',
                      _hover: { bgColor: '#2021230D' },
                      _focus: { bgColor: '#2021230D' },
                    },
                  }}
                >
                  <MenuItem as={Link} to={`/promotions/edit/${promotion.id}`}>
                    Editar
                  </MenuItem>
                  <MenuItem onClick={handlePromotionStatusChange}>
                    {getActionTextByStatus(promotion.status)}
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </CardHeader>

          <CardBody
            mt={6}
            p={4}
            pt={0}
            color="#20212380"
            sx={{ strong: { fontWeight: 'semibold' } }}
          >
            <List spacing={2}>
              <HStack as={ListItem} justifyContent="space-between" hidden>
                <Text as="strong">Qtd. de compras:</Text>
                <Text>{promotion.numberOfPurchases}</Text>
              </HStack>

              <HStack as={ListItem} justifyContent="space-between">
                <Text as="strong">Início:</Text>
                <Text>{formatDate(promotion.startTime)}</Text>
              </HStack>

              <HStack as={ListItem} justifyContent="space-between">
                <Text as="strong">Fim:</Text>
                <Text>{formatDate(promotion.endTime)}</Text>
              </HStack>

              <HStack as={ListItem} justifyContent="space-between">
                <Text as="strong">Status:</Text>
                <Text color={getStatusColor(promotion.status)} textTransform="capitalize">
                  {promotion.status.toLowerCase()}
                </Text>
              </HStack>
            </List>
          </CardBody>
        </Card>
      ))}
    </List>
  );
}
