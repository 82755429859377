import { Link } from 'react-router-dom';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';

import BlogAPI from '../../../../../api/Blog';

import ErrorResponse from '../../../../../helpers/ErrorResponse';

import Toast from '../../../../../components/Toast';

import styles from './styles.module.css';

function DropdownPost({ postId, deletePost }) {
  async function handleDeletePost() {
    try {
      await BlogAPI.delete(postId);

      deletePost(postId);
      Toast('Post excluído com sucesso', 'success');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  return (
    <DropdownButton
      title={<FaEllipsisV />}
      variant="transaparent"
      id="dropdown-button-drop-up"
      as={ButtonGroup}
      drop="up"
      className={styles.dropdownButton}
    >
      <Link to={`internal-blog/post/${postId}/edit`}>Editar</Link>
      <Dropdown.Divider className="my-0"></Dropdown.Divider>
      <button className={styles.submitButton} type="button" onClick={handleDeletePost}>
        Excluir
      </button>
    </DropdownButton>
  );
}

export default DropdownPost;
