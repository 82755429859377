import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';

export default function ModalUserInformation({
  isOpen,
  onClose,
  size,
  onChangeUpdate,
  loadingModal,
  updatedUser,
  handleDomainUpdate,
  onChangeChecked,
  switchStyle,
  handleUpdateUser,
  onChanged,
  expirePassword,
}) {
  return (
    <Modal size={size} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Informações do usuário</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <HStack width="full" alignItems="center">
              <FormControl>
                <FormLabel htmlFor="platform">Domínio</FormLabel>
                <InputGroup>
                  <InputLeftAddon children="https://" />
                  <Input
                    id="platform"
                    name="platform"
                    textTransform="lowercase"
                    placeholder="meusiteincrivel"
                    onChange={onChangeUpdate}
                    value={updatedUser?.platform?.url.replace('.proluno.com.br', '')}
                  />
                  {updatedUser?.platform?.url.includes('proluno') && (
                    <InputRightAddon children=".proluno.com" />
                  )}
                </InputGroup>
                <FormHelperText>
                  <Text fontWeight="bold" display="inline">
                    Não
                  </Text>{' '}
                  utilize espaços, pontos "." ou caracteres especiais (@ # $ % &).
                </FormHelperText>
              </FormControl>
              <Button
                bg="#eb7129"
                color="#fff"
                _hover={{ background: '#eb712980' }}
                disabled={!updatedUser?.platform?.url.includes('.proluno.com.br')}
                isLoading={loadingModal}
                onClick={() => handleDomainUpdate()}
              >
                Alterar domínio
              </Button>
            </HStack>
            <FormControl>
              <FormLabel htmlFor="email">E-mail</FormLabel>
              <Input
                id="email"
                name="email"
                placeholder="example@example.com"
                value={updatedUser?.email}
                onChange={onChangeUpdate}
              />
              <FormHelperText>
                Ao clicar em expirar senha, o usuário poderá alterar sua senha na próxima vez que
                tentar entrar na plataforma.
              </FormHelperText>
            </FormControl>
            <Divider />
            <FormControl>
              <FormLabel htmlFor="subscriberSuspended">Status Assinatura</FormLabel>
              <Switch
                sx={switchStyle}
                id="subscriberSuspended"
                name="subscriberSuspended"
                py="2"
                isChecked={!false}
                onChange={onChangeChecked}
              />
              <Text display="inline" mx="1">
                {false ? 'Suspensa' : 'Ativa'}
              </Text>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter bg="#fff">
          <HStack width="full" justifyContent="space-between">
            <Button colorScheme="blue" isLoading={loadingModal} onClick={() => expirePassword()}>
              Expirar senha
            </Button>
            <Button
              px="10"
              bg="#eb7129"
              _hover={{ background: '#eb712980' }}
              color="#fff"
              disabled={onChanged}
              isLoading={loadingModal}
              onClick={() => handleUpdateUser()}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
