import {
  Alert,
  AlertDescription,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  HStack,
  ListItem,
  Text,
} from '@chakra-ui/react';

import {} from '@chakra-ui/react';
import { QuestionStatistic } from '..';
import QuestionFooter from './QuestionFooter';

interface QuestionProps {
  statistics: QuestionStatistic;
  isFiltering: boolean;
}

export default function Question({ statistics, isFiltering }: QuestionProps) {
  const hasAnswer = !!statistics.wrong || !!statistics.correct;
  const alertStatus = !hasAnswer ? (isFiltering ? 'warning' : 'error') : null;

  return (
    <Card
      as={ListItem}
      borderRadius="10px"
      border="1px solid #2021230D"
      background="#FFF"
      boxShadow=" 0px 4px 4px 0px #00000040"
      padding="20px"
      direction="column"
      alignItems="flex-start"
      gap="10px"
    >
      <HStack
        width="full"
        justifyContent="space-between"
        alignItems={{ base: 'self-start', md: 'center' }}
      >
        <HStack direction="row" spacing={0} alignItems="center" gap="8px" flexWrap="wrap">
          <Badge
            display="flex"
            height="20px"
            padding="0px 8px"
            alignItems="center"
            gap="6px"
            borderRadius="6px"
            color="#fff"
            background="#EB7129"
          >
            <Text
              fontSize="12px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="16px"
              color="#FFF"
            >
              ID: {statistics?.id}
            </Text>
          </Badge>

          <Breadcrumb
            spacing="8px"
            separator={'/'}
            color="#AFAFAF"
            fontFamily="Inter"
            fontSize="12px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="24px"
          >
            <BreadcrumbItem hidden={false}>
              <Text noOfLines={1}>{statistics?.area}</Text>
            </BreadcrumbItem>

            <BreadcrumbItem hidden={false}>
              <Text noOfLines={1}>{statistics?.discipline}</Text>
            </BreadcrumbItem>

            <BreadcrumbItem hidden={false}>
              <Text noOfLines={1}>{statistics?.subject}</Text>
            </BreadcrumbItem>

            <BreadcrumbItem hidden={false}>
              <Text noOfLines={1}>{statistics?.institution}</Text>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
      </HStack>

      <Text
        color="#202123"
        dangerouslySetInnerHTML={{ __html: statistics?.questionText }}
        sx={{ iframe: { width: '100%', aspectRatio: '16/9', hyphens: 'auto' } }}
        wordBreak="break-word"
        noOfLines={2}
      />

      {alertStatus && (
        <Alert status={alertStatus}>
          <AlertDescription>
            {isFiltering
              ? 'A questão não possui respostas no período selecionado.'
              : 'A questão não possui respostas ainda.'}
          </AlertDescription>
        </Alert>
      )}

      {hasAnswer && <QuestionFooter questionStatistic={statistics} />}
    </Card>
  );
}
