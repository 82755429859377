import Joi from 'joi';
import { RecurrencePlanDescriptionForm } from './types';

export const RecurrencePlanPaymentFormSchemaValidator =
  Joi.object<RecurrencePlanDescriptionForm>().keys({
    willUsePlanPage: Joi.boolean().required().default(false).messages({
      'any.required': 'Por favor, selecione uma opção para continuar.',
      'boolean.base': 'Ops, parece que você selecionou uma opção inválida.',
    }),
    pageType: Joi.when('willUsePlanPage', {
      is: true,
      then: Joi.string().valid('INTERNAL', 'EXTERNAL').required().messages({
        'any.required': 'Por favor, selecione o tipo de página que você deseja usar.',
        'string.base': 'Ops, parece que você selecionou uma opção inválida.',
        'any.only': 'Por favor, selecione apenas uma das opções disponíveis.',
      }),
    }),
    linkToYourPlan: Joi.when('pageType', {
      is: 'EXTERNAL',
      then: Joi.string().uri().required().messages({
        'any.required': 'Por favor, informe o link da sua página.',
        'string.base': 'Ops, parece que você informou um link inválido.',
        'string.uri': 'Informe um link válido. Exemplo: https://www.example.com',
        'string.empty': 'Por favor, informe o link da sua página.',
      }),
      otherwise: Joi.string().uri().allow(null).messages({
        'string.base': 'Ops, parece que você informou um link inválido.',
        'string.allowNull': 'Você pode deixar este campo em branco se não tiver um link.',
      }),
    }),
    thumbnail: Joi.when('pageType', {
      is: 'INTERNAL',
      then: Joi.string().allow(null).required().messages({
        'any.required': 'Por favor, selecione uma imagem para o seu plano.',
        'string.base': 'Ops, parece que você selecionou uma imagem inválida.',
        'string.allowNull': 'Você pode deixar este campo em branco se não tiver uma imagem.',
      }),
      otherwise: Joi.string().allow(null).messages({
        'string.base': 'Ops, parece que você selecionou uma imagem inválida.',
        'string.allowNull': 'Você pode deixar este campo em branco se não tiver uma imagem.',
      }),
    }),
    mainBanner: Joi.when('pageType', {
      is: 'INTERNAL',
      then: Joi.string().allow(null).required().messages({
        'any.required': 'Por favor, selecione uma imagem para o seu plano.',
        'string.base': 'Ops, parece que você selecionou uma imagem inválida.',
        'string.allowNull': 'Você pode deixar este campo em branco se não tiver uma imagem.',
      }),
      otherwise: Joi.string().allow(null).messages({
        'string.base': 'Ops, parece que você selecionou uma imagem inválida.',
        'string.allowNull': 'Você pode deixar este campo em branco se não tiver uma imagem.',
      }),
    }),
    description: Joi.when('pageType', {
      is: 'INTERNAL',
      then: Joi.string().required().messages({
        'any.required': 'Por favor, informe uma descrição para o seu plano.',
        'string.base': 'Ops, parece que você informou uma descrição inválida.',
        'string.empty': 'Por favor, escreva uma descrição para o seu plano.',
      }),
      otherwise: Joi.string().allow(null).messages({
        'string.base': 'Ops, parece que você informou uma descrição inválida.',
        'string.allowNull': 'Você pode deixar este campo em branco se não tiver uma descrição.',
      }),
    }),
  });
