import { axiosBackend } from './Configuration';

export default {
  store: async category => {
    try {
      const { data: categoryData } = await axiosBackend().post('/categories', category);

      return categoryData;
    } catch (error) {
      throw error;
    }
  },

  index: async () => {
    try {
      const { data: categories } = await axiosBackend().get('/v2/categories');

      return categories;
    } catch (error) {
      throw error;
    }
  },

  show: async category_id => {
    try {
      const { data: categories } = await axiosBackend().get(`/v2/categories/${category_id}`);

      return categories;
    } catch (error) {
      throw error;
    }
  },

  update: async (category_id, category) => {
    try {
      await axiosBackend().patch(`/categories/${category_id}`, category);
    } catch (error) {
      throw error;
    }
  },

  delete: async category_id => {
    try {
      await axiosBackend().delete(`/categories/${category_id}`);
    } catch (error) {
      throw error;
    }
  },

  order: async order => {
    try {
      await axiosBackend().patch(`/order-product-category`, order);
    } catch (error) {
      throw error;
    }
  },
};
