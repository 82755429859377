import {
  Box,
  Button,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { HiDotsHorizontal } from 'react-icons/hi';
import { MdDragIndicator } from 'react-icons/md';
import { IShowcase } from '..';
import { axiosBackend } from '../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { AddPlanModal } from '../AddPlanModal';
import { AddProductModal } from '../AddProductModal';
import { CreateBannerModal } from '../CreateBannerModal';
import { RemoveShowcaseItemModal } from '../RemoveShowcaseItemModal';
import { DeleteShowcaseModal } from '../DeleteShowcaseModal';
import { EditShowcaseModal } from '../EditShowcaseModal';
import { RemoveProductBannerModal } from '../RemoveProductBannerModal';
import ShowcaseList from './List';
import { useHistory } from 'react-router-dom';

interface IProps {
  showcaseCount: number;
  showcase: IShowcase;
  showcases: IShowcase[];
  setShowcases: (showcases: IShowcase[]) => void;
}

type ActionType = 'create-banner' | 'edit' | 'duplicate' | 'delete' | 'add-products' | 'add-plans';

export interface SelectedShowcase {
  id: number | null;
  name: string;
  status: string;
  showcaseType: string;
  showcaseViewType: string;
}

export function getStatusColorByName(status: string) {
  switch (status) {
    case 'ATIVO':
      return '#22BB33';
    case 'INATIVO':
      return '#BB2124';
    case 'EM_EDICAO':
      return '#E8C536';
    default:
      return '#202123';
  }
}

export function Showcase({ showcase, showcaseCount, showcases, setShowcases }: IProps) {
  const [showcaseItemId, setShowcaseItemId] = useState<number | null>(null);
  const [selectedShowcase, setselectedShowcase] = useState<SelectedShowcase>({
    id: null,
    name: '',
    status: '',
    showcaseType: '',
    showcaseViewType: '',
  });

  const toast = useToast();
  const { push } = useHistory();

  const {
    isOpen: isOpenAddProductsModal,
    onOpen: onOpenAddProductsModal,
    onClose: onCloseAddProductsModal,
  } = useDisclosure();

  const {
    isOpen: isAddPlanModalOpen,
    onOpen: onAddPlanModalOpen,
    onClose: onAddPlanModalClose,
  } = useDisclosure();

  const {
    isOpen: isOpenEditShowcaseModal,
    onOpen: onOpenEditShowcaseModal,
    onClose: onCloseEditShowcaseModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteShowcaseModal,
    onOpen: onOpenDeleteShowcaseModal,
    onClose: onCloseDeleteShowcaseModal,
  } = useDisclosure();

  const {
    isOpen: isAddBannerModalOpen,
    onOpen: onAddBannerModalOpen,
    onClose: onAddBannerModalClose,
  } = useDisclosure();

  const {
    isOpen: isOpenRemoveShowcaseItemModal,
    onOpen: onOpenRemoveShowcaseItemModal,
    onClose: onCloseRemoveShowcaseItemModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteProductBannerModal,
    onOpen: onOpenDeleteProductBannerModal,
    onClose: onCloseDeleteProductBannerModal,
  } = useDisclosure();

  function onClickAction(showcase: IShowcase, action: ActionType) {
    if (showcase.viewType === 'highlight' && action === 'add-plans') {
      push(`/members-area-management/netflix/showcase-higlight-type/${showcase.showcaseId}/add`);
      return;
    }

    setselectedShowcase({
      id: showcase.showcaseId,
      name: showcase.name,
      status: showcase.statusName,
      showcaseType: showcase.showcaseType,
      showcaseViewType: showcase.viewType,
    });

    switch (action) {
      case 'create-banner':
        onAddBannerModalOpen();
        break;
      case 'add-products':
        onOpenAddProductsModal();
        break;
      case 'add-plans':
        onAddPlanModalOpen();
        break;
      case 'edit':
        onOpenEditShowcaseModal();
        break;
      case 'duplicate':
        duplicateShowcase();
        break;
      case 'delete':
        onOpenDeleteShowcaseModal();
        break;
    }
  }

  async function duplicateShowcase() {
    try {
      const response = await axiosBackend().post(`/showcase/${showcase.showcaseId}/duplicate`);

      const { data } = response.data;

      const showcaseDuplicate = data as IShowcase;

      if (!showcaseDuplicate) return;

      setShowcases([...showcases, showcaseDuplicate]);

      toast({
        title: 'A vitrine foi duplicada com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  }

  async function handleStatusChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    if (!value) return;

    const payload = {
      name: showcase.name,
      status: value,
    };

    try {
      await axiosBackend().patch(`/showcase/${showcase.showcaseId}`, payload);

      const showcaseIndex = showcases.findIndex(
        showcaseData => showcaseData.showcaseId === showcase.showcaseId
      );

      if (showcaseIndex === -1) return;

      const updatedShowcases = [...showcases];

      updatedShowcases[showcaseIndex].statusName = value;

      setShowcases(updatedShowcases);

      toast({
        title: 'O status da vitrine foi atualizado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  }

  function isEmptyShowcase(showcase: IShowcase): boolean {
    if (showcase.showcaseType === 'products') {
      return !showcase.products?.length;
    }

    return !showcase.plans?.length;
  }

  return (
    <Box
      width="full"
      display="flex"
      flexDirection="column"
      borderRadius={6}
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
    >
      <Stack
        width="full"
        direction="row"
        padding="24px 13px 24px 13px"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack>
          <MdDragIndicator
            fontSize="24px"
            color={showcaseCount <= 1 ? 'transparent' : '#202123'}
            style={{ flexShrink: 0 }}
          />

          <VStack align="flex-start" spacing={0}>
            <Heading fontSize="18px" fontWeight="medium" color="#202123" m={0}>
              {showcase.name}
            </Heading>

            <Text fontSize="sm" fontWeight="normal" color="#202123">
              Tipo: {showcase?.showcaseType === 'products' ? 'Produtos' : 'Planos'} (
              {showcase?.viewType === 'standard' ? 'Padrão' : 'Destaque'})
            </Text>
          </VStack>
        </HStack>

        <HStack>
          <Select
            fontSize="14px"
            fontWeight={400}
            width={showcase.statusName === 'ATIVO' ? '75px' : '90px'}
            color={getStatusColorByName(showcase.statusName)}
            focusBorderColor="transparent"
            border="none"
            name="status"
            size="xs"
            value={showcase.statusName}
            onChange={handleStatusChange}
          >
            <option value="ATIVO">Ativo</option>
            <option value="INATIVO">Inativo</option>
          </Select>

          <Menu>
            <MenuButton
              w="27px"
              h="24px"
              borderRadius={5}
              bgColor="#20212312"
              _hover={{ bgColor: '#20212330' }}
              _active={{ bgColor: '#20212330' }}
            >
              <Stack justifyContent="center" alignItems="center">
                <HiDotsHorizontal size="18px" color="#00000080" />
              </Stack>
            </MenuButton>
            <MenuList>
              <MenuItem
                color="#979CA6"
                fontWeight={500}
                onClick={() => onClickAction(showcase, 'edit')}
              >
                Editar vitrine
              </MenuItem>
              <MenuItem
                color="#979CA6"
                fontWeight={500}
                onClick={() => onClickAction(showcase, 'duplicate')}
              >
                Duplicar
              </MenuItem>
              <MenuItem
                color="#BB2124"
                fontWeight={500}
                onClick={() => onClickAction(showcase, 'delete')}
              >
                Excluir
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Stack>

      <ShowcaseList
        products={showcase.products}
        plans={showcase.plans}
        showcase={showcase}
        showcases={showcases}
        showcaseItemId={showcaseItemId}
        setShowcases={setShowcases}
        setShowcaseItemId={setShowcaseItemId}
        onAddBannerModalOpen={onAddBannerModalOpen}
        onAddPlanModalOpen={onAddPlanModalOpen}
        onOpenRemoveShowcaseItemModal={onOpenRemoveShowcaseItemModal}
        onOpenDeleteProductBannerModal={onOpenDeleteProductBannerModal}
        setselectedShowcase={setselectedShowcase}
      />

      {isEmptyShowcase(showcase) && (
        <Box width="full" padding="24px 13px 24px 13px" display="flex" justifyContent="center">
          <Heading fontSize="14px" color="#20212380">
            Nenhum {showcase?.showcaseType === 'products' ? 'produto' : 'plano'} adicionado a esse
            vitrine
          </Heading>
        </Box>
      )}

      <Box width="full" padding="20px" display="flex" justifyContent="flex-end">
        <Button
          leftIcon={<FaPlus />}
          size="sm"
          variant="outline"
          bgColor="#FFF"
          color="primary.500"
          borderColor="primary.500"
          _hover={{ bgColor: 'primary.100' }}
          onClick={() =>
            onClickAction(
              showcase,
              showcase.showcaseType === 'products' ? 'add-products' : 'add-plans'
            )
          }
        >
          Adicionar {showcase?.showcaseType === 'products' ? 'Produtos' : 'Planos'}
        </Button>
      </Box>

      <EditShowcaseModal
        showcases={showcases}
        isOpen={isOpenEditShowcaseModal}
        selectedShowcase={selectedShowcase}
        onClose={onCloseEditShowcaseModal}
        setShowcases={setShowcases}
      />

      <DeleteShowcaseModal
        showcases={showcases}
        isOpen={isOpenDeleteShowcaseModal}
        selectedShowcase={selectedShowcase}
        onClose={onCloseDeleteShowcaseModal}
        setShowcases={setShowcases}
      />

      <CreateBannerModal
        showcase={showcase}
        showcases={showcases}
        isOpen={isAddBannerModalOpen}
        showcaseItemId={showcaseItemId}
        onClose={onAddBannerModalClose}
        setShowcases={setShowcases}
      />

      <AddProductModal
        showcases={showcases}
        isOpen={isOpenAddProductsModal}
        selectedShowcase={selectedShowcase}
        onClose={onCloseAddProductsModal}
        setShowcases={setShowcases}
      />

      <AddPlanModal
        showcases={showcases}
        isOpen={isAddPlanModalOpen}
        selectedShowcase={selectedShowcase}
        onClose={onAddPlanModalClose}
        setShowcases={setShowcases}
      />

      <RemoveShowcaseItemModal
        showcases={showcases}
        isOpen={isOpenRemoveShowcaseItemModal}
        selectedShowcase={selectedShowcase}
        showcaseItemId={showcaseItemId}
        onClose={onCloseRemoveShowcaseItemModal}
        setShowcases={setShowcases}
      />

      <RemoveProductBannerModal
        showcase={showcase}
        showcases={showcases}
        isOpen={isOpenDeleteProductBannerModal}
        showcaseCourseId={showcaseItemId}
        onClose={onCloseDeleteProductBannerModal}
        setShowcases={setShowcases}
      />
    </Box>
  );
}
