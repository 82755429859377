import { Box, Flex, FlexProps, Heading, Text } from '@chakra-ui/react';

interface CardProps extends FlexProps {
  title: string;
  subtitle: string;
  image?: string;
  gradientsize?: 'small' | 'large';
  to?: string;
  children?: React.ReactNode;
}

function getLinearGradient(gradientSize) {
  const gradientEnd = gradientSize === 'large' ? '66%' : '33%';

  return `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) ${gradientEnd}, rgba(0,212,255,0) 100%)`;
}

const Card = (props: CardProps) => {
  const { image, title, subtitle, gradientsize = 'small', children } = props;

  return (
    <Flex
      direction="column"
      flex={1}
      p={5}
      bg={`url(${image})`}
      position="relative"
      bgPosition="right"
      bgRepeat="no-repeat"
      borderRadius="6px"
      overflow="hidden"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      minH={56}
      sx={{
        button: {
          _focus: { outline: 'none', boxShadow: 'none' },
        },
      }}
      {...props}
    >
      {/* Gradient */}
      <Box
        background={getLinearGradient(gradientsize)}
        position="absolute"
        inset={0}
        h="full"
        w={{
          base: `${gradientsize === 'small' && '120%'}`,
          xl: `${gradientsize === 'small' && '50%'}`,
        }}
      />

      <Box pos="relative" maxW={72}>
        <Heading fontWeight="semibold" fontSize="sm" color="orange.500">
          {title}
        </Heading>

        <Text fontWeight="semibold" fontSize="2xl" noOfLines={2} sx={{ hyphens: 'auto' }}>
          {subtitle}
        </Text>
      </Box>

      <Flex flex={1} direction="column" position="relative">
        {children}
      </Flex>
    </Flex>
  );
};

export default Card;
