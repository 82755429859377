import {
  Text,
  FormLabel,
  FormControl,
  Input as ChakraInput,
  InputGroup,
  InputProps,
  Stack,
  InputLeftAddon,
} from '@chakra-ui/react';

interface IInputProps extends InputProps {
  label?: string;
  isOptional?: boolean;
  isPrice?: boolean;
  isLoading?: boolean;
  labelWeight?: string;
  description?: string;
}

function Input({
  id,
  name,
  type,
  label,
  labelWeight = 'medium',
  description,
  placeholder,
  isOptional = false,
  isPrice = false,
  ...rest
}: IInputProps) {
  const inputId = id ?? name;

  return (
    <FormControl id={inputId}>
      <Stack spacing="1">
        {label && (
          <FormLabel
            marginBottom="0"
            fontSize="sm"
            fontWeight={labelWeight}
            htmlFor={inputId}
            display="flex"
            alignItems="center"
          >
            {label}
            {isOptional && (
              <Text color="blackAlpha.500" ml="1">
                (Opcional)
              </Text>
            )}
          </FormLabel>
        )}

        {description && (
          <Text
            marginTop="0rem !important"
            fontSize="sm"
            fontWeight="500"
            color="rgba(32, 33, 35, 0.5)"
          >
            {description}
          </Text>
        )}

        <InputGroup position="relative">
          {isPrice && (
            <InputLeftAddon
              color="gray.700"
              borderWidth="1px"
              borderStyle="solid"
              borderColor="borderInput.100"
              children="R$"
            />
          )}
          <ChakraInput
            id={inputId}
            size="md"
            name={name}
            type={type}
            placeholder={placeholder}
            variant="outline"
            color="blackAlpha.500"
            fontSize="md"
            fontWeight="medium"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="borderInput.100"
            borderRadius="md"
            focusBorderColor="primary.400"
            _placeholder={{ color: 'blackAlpha.500' }}
            _hover={{
              '&:focus': {
                borderColor: 'none',
              },
            }}
            {...rest}
          />
        </InputGroup>
      </Stack>
    </FormControl>
  );
}

export default Input;
