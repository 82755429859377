import {
  Box,
  Button,
  HStack,
  List,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useBreakpointValue,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useCallback, useRef, useState } from 'react';
import { MdOutlineFilterAlt as FilterIcon } from 'react-icons/md';
import PreviewFilter from '../PreviewFilter';
import DateFilter from './DateFilter';
import DateRangePicker from './DateRangePicker';
import DateRangePickerMobile from './DateRangePickerMobile';

export type PreviewFilterOption = {
  label: string;
  value: string;
  name?: 'date' | 'viewOrder';
};

interface FiltersDropDownProps {
  urlSearchParams?: URLSearchParams;
  onFilter: (filters: any[]) => void;
  onClearFilters: () => void;
}

interface PreviewFilters {
  startDate?: string;
  endDate?: string;
  viewOrder?: 'biggest_first' | 'smallest_first' | undefined;
}

export default function FiltersDropDown({
  urlSearchParams,
  onFilter,
  onClearFilters,
}: FiltersDropDownProps) {
  const [previewFilters, setPreviewFilters] = useState<PreviewFilters | null>({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isCustomDateFilter, setIsCustomDateFilter] = useState(false);
  const [currentFilterDate, setCurrentFilterDate] = useState('');

  const { isOpen, onToggle, onClose } = useDisclosure();

  const menuRef = useRef();

  useOutsideClick({
    ref: menuRef,
    handler: onClose,
    enabled: !isCustomDateFilter,
  });

  function handlePreviewFilterChange(previewFilterOption: Partial<PreviewFilterOption>) {
    urlSearchParams.set(previewFilterOption.name, previewFilterOption.value);

    setPreviewFilters(prevState => ({
      ...prevState,
      [previewFilterOption.name]: previewFilterOption.value,
    }));
  }

  const handleDateFilterChange = useCallback((dateFilter: Partial<PreviewFilterOption>) => {
    const today = dayjs().format('YYYY-MM-DD');
    const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    const lastSevenDays = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
    const lastMonth = dayjs().subtract(30, 'day').format('YYYY-MM-DD');

    setCurrentFilterDate(dateFilter.value);

    if (!dateFilter.value) {
      setStartDate('');
      setEndDate('');
      return;
    }

    if (dateFilter.value === 'custom') {
      return setIsCustomDateFilter(true);
    }

    setIsCustomDateFilter(false);

    if (dateFilter.value === 'today') {
      setStartDate(today);
      setEndDate(today);
      return;
    }

    if (dateFilter.value === 'yesterday') {
      setStartDate(yesterday);
      setEndDate(yesterday);
      return;
    }

    if (dateFilter.value === 'lastSevenDays') {
      setStartDate(lastSevenDays);
      setEndDate(today);
      return;
    }

    setStartDate(lastMonth);
    setEndDate(today);
  }, []);

  function handleFilter() {
    onFilter([
      { name: 'viewOrder', value: previewFilters.viewOrder },
      { name: 'startDate', value: startDate },
      { name: 'endDate', value: endDate },
    ]);

    onToggle();
  }

  function handleClearFilters() {
    onClearFilters();
    setStartDate('');
    setEndDate('');
    setCurrentFilterDate('');
    setIsCustomDateFilter(false);

    onToggle();
  }

  function handleCustomRangeChange(startDate: string, endDate: string) {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Menu placement="bottom-end" isOpen={isOpen}>
      <MenuButton
        onClick={onToggle}
        as={Button}
        variant="outline"
        border="1px solid #8F8F90"
        color="#8F8F90"
        size="sm"
        minWidth="92px"
        alignItems="stretch"
      >
        <HStack h="full" sx={{ svg: { fontSize: '16px' } }}>
          <Text fontSize="sm">Filtros</Text>

          <FilterIcon />
        </HStack>
      </MenuButton>

      <MenuList
        p={2.5}
        borderRadius="5px"
        border="0.5px solid #0000000D"
        boxShadow="0px 4px 4px 0px #00000040"
        color="#202123"
        w="15.625rem"
        ref={menuRef}
      >
        <Text>Visualização</Text>

        <List mt={2.5} display="flex" flexDirection="column">
          <DateFilter
            name="calendar"
            value={currentFilterDate}
            onChange={handleDateFilterChange}
            label="Data"
            options={[
              { label: 'Todo o tempo', value: '' },
              { label: 'Hoje', value: 'today' },
              { label: 'Ontem', value: 'yesterday' },
              { label: 'Últimos 7 dias', value: 'lastSevenDays' },
              { label: 'Últimos 30 dias', value: 'lastMonth' },
              { label: 'Personalizado', value: 'custom' },
            ]}
            offset={[0, -32]}
          />

          {isCustomDateFilter && (
            <>
              {isMobile ? (
                <DateRangePickerMobile onChange={handleCustomRangeChange} />
              ) : (
                <DateRangePicker onChange={handleCustomRangeChange} />
              )}
            </>
          )}

          <Box>
            <PreviewFilter
              name="viewOrder"
              value={urlSearchParams.get('viewOrder')}
              onChange={handlePreviewFilterChange}
              label="Ordenar"
              offset={[0, -32]}
              options={[
                { label: 'Recentes', value: 'recent', name: 'viewOrder' },
                { label: 'Maior ganho', value: 'highest_gain', name: 'viewOrder' },
                { label: 'Menor ganho', value: 'lowest_gain', name: 'viewOrder' },
                { label: 'Ordem A-Z', value: 'asc', name: 'viewOrder' },
                { label: 'Ordem Z-A', value: 'desc', name: 'viewOrder' },
                { label: 'Maior venda', value: 'biggest_sale', name: 'viewOrder' },
                { label: 'Menor venda', value: 'lowest_sale', name: 'viewOrder' },
              ]}
            />
          </Box>
        </List>

        <HStack as="footer" justify="end" mt={4}>
          <HStack>
            <Button
              onClick={handleClearFilters}
              size="xs"
              variant="link"
              textDecoration="underline"
              fontWeight="normal"
              color="#AFAFAF"
            >
              Limpar filtro
            </Button>

            <Button onClick={handleFilter} size="xs" colorScheme="primary">
              Filtrar
            </Button>
          </HStack>
        </HStack>
      </MenuList>
    </Menu>
  );
}
