import { Card, Image, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { BiDotsVertical } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { ISegmentationResponse, ISegmentationSelect } from '../..';
import SegmentationIcon from '../../../../../assets/icons/segmentation-icons.svg';
import { exportLeads } from '../../../../../helpers/exportLeads';

interface IProps {
  segmentation: ISegmentationResponse;
  setSegmentationSelect: (currentSegmentation: ISegmentationSelect) => void;
}

export function SegmentationCard({ segmentation, setSegmentationSelect }: IProps) {
  return (
    <Card
      width={{ base: 'full', md: '243px', lg: '243px' }}
      height="96px"
      padding="10px"
      borderRadius="5px"
      border="1px solid rgba(32, 33, 35, 0.05)"
      background="#FFF"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
    >
      <Stack direction="row" spacing="11px" alignItems="self-start" justifyContent="center">
        <Image src={SegmentationIcon} boxSize="24px" />

        <Stack
          spacing={0}
          justifyContent="center"
          width={{ base: '250px', md: '165px', lg: '165px' }}
        >
          <Text fontSize="12px" fontWeight={600} isTruncated>
            {segmentation.name}
          </Text>
          <Text fontSize="12px" fontWeight={500} color="rgba(32, 33, 35, 0.50)">
            Última atualização:
          </Text>
          <Text fontSize="12px" fontWeight={500} color="rgba(32, 33, 35, 0.50)">
            {dayjs(segmentation.updatedAt, { utc: true }).format('DD/MM/YYYY HH:mm')}
          </Text>
          <Text fontSize="12px" fontWeight={500} color="rgba(32, 33, 35, 0.50)">
            Nº de leads: {segmentation.numberOfLeads}
          </Text>
        </Stack>

        <Menu>
          <MenuButton>
            <BiDotsVertical size={14} />
          </MenuButton>
          <MenuList color="#20212380" fontSize="12px">
            <MenuItem fontWeight={500} as={Link} to={`/segmentation/${segmentation.id}/edit`}>
              Editar
            </MenuItem>
            <MenuItem fontWeight={500} as={Link} to={`/segmentation/${segmentation.id}/lead-list`}>
              Lista de Leads
            </MenuItem>
            <MenuItem
              fontWeight={500}
              onClick={() =>
                exportLeads({
                  segmentationId: segmentation.id,
                  segmentationName: segmentation.name,
                })
              }
            >
              Exportar leads
            </MenuItem>
            <MenuItem
              color="#BB2124"
              fontWeight={500}
              onClick={() =>
                setSegmentationSelect({ id: segmentation.id, name: segmentation.name })
              }
            >
              Excluir
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
    </Card>
  );
}
