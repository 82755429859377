import dayjs from 'dayjs';
import Joi from 'joi';
import { FormEvent, useEffect, useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { useAuth } from '../../../../contexts/AuthContext';
import useFetch from '../../../../hooks/useFetch';
import useHandleChange from '../../../../hooks/useHandleChange';
import useHandleSubmit, { ResponseJSON } from '../../../../hooks/useHandleSubmit';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';

type URLSiteFormType = {
  url: string;
};

const URLSiteFormSchema = Joi.object<URLSiteFormType>().keys({
  url: Joi.string()
    .required()
    .regex(/^[a-zA-Z0-9-]+\.proluno\.com\.br(\/[^\s]*)?$/)
    .messages({
      'string.empty': 'Informe um subdomínio válido',
      'string.pattern.base': 'Informe um subdomínio válido',
      'any.required': 'Informe um subdomínio válido',
    }),
});

const PROLUNO_DOMAIN = '.proluno.com.br';

const isProlunoDomain = (url?: string) => url?.includes(PROLUNO_DOMAIN);

type PlatformUrlType = { url: string; alternativeUrl: string; lastUrlChange?: Date };

export default function URLSite() {
  const [platformUrl, setPlatformUrl] = useState<PlatformUrlType>({
    url: '',
    alternativeUrl: '',
    lastUrlChange: null,
  });

  const { signOut } = useAuth();

  const { data, loading } = useFetch<ResponseJSON<PlatformUrlType>>({
    method: 'get',
    url: '/platforms/domain',
    authenticated: true,
    autoFetch: true,
  });

  const { form, handleChange, onChanged, handleCancel, setOnChanged } =
    useHandleChange<URLSiteFormType>(
      {
        url: '',
      },
      data?.data
    );

  useEffect(() => {
    if (!loading && data) {
      setPlatformUrl(data.data);
    }
  }, [data, loading]);

  const { formValidation, handleSubmit, isLoading } = useHandleSubmit<
    URLSiteFormType,
    ResponseJSON<PlatformUrlType>
  >({
    url: '/platforms/domain',
    method: 'patch',
    authenticated: true,
    data: {
      url: form.url.concat(PROLUNO_DOMAIN),
    },
    schemaValidator: URLSiteFormSchema,
    onSuccess: {
      message: 'Domínio alterado com sucesso',
      callback: response => {
        setPlatformUrl(response.data.data);
        setOnChanged(false);

        signOut();
      },
    },
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <VStack as="form" spacing={4} alignItems="flex-start" onSubmit={onSubmit}>
      <Box>
        <Text as="h2" fontSize="xl" fontWeight="medium">
          URL do site
        </Text>
        <Text color="#0000007D" fontSize="sm">
          Esse é endereço para acessar o site. Por favor, verificar se tiver alterado, a URL
          anterior não irá funcionar mais.{' '}
          <Text as="span" fontWeight="bold">
            Você só poderá fazer apenas uma alteração por dia.
          </Text>
        </Text>

        <Alert mt={4} status="warning">
          <AlertIcon />
          <AlertDescription>
            Após a mudança de domínio, você será <Text as="strong">desconectado</Text> da
            plataforma. Não se preocupe, apenas aguarde um momento e, em seguida, acesse novamente
            usando o novo domínio. Estamos aqui para ajudar se precisar de suporte!
          </AlertDescription>
        </Alert>
      </Box>

      <FormControl isInvalid={formValidation?.url.isInvalid}>
        <FormLabel>
          {isProlunoDomain(platformUrl.url) ? 'Meu domínio proluno' : 'Seu domínio é personalizado'}
          <Text as="span" color="red.500">
            *
          </Text>
        </FormLabel>
        <InputGroup>
          <InputLeftAddon children="https://" />
          <Input
            name="url"
            focusBorderColor="orange.500"
            value={
              isProlunoDomain(platformUrl.url)
                ? platformUrl.url.replace(PROLUNO_DOMAIN, '')
                : platformUrl.url
            }
            onChange={event => {
              setPlatformUrl({
                ...platformUrl,
                url: event.target.value.concat(PROLUNO_DOMAIN),
              });

              handleChange(event);
            }}
            placeholder="seudominio"
            isDisabled={!isProlunoDomain(platformUrl.url)}
          />
          <InputRightAddon hidden={!isProlunoDomain(platformUrl.url)} children="proluno.com.br" />
          <InputRightAddon
            hidden={!isProlunoDomain(platformUrl.url)}
            children={
              <Link href={`https://${platformUrl?.url}`} target="__blank" rel="noopener noreferrer">
                <BiLinkExternal />
              </Link>
            }
          />
          <InputRightAddon
            hidden={isProlunoDomain(platformUrl.url)}
            children={
              <Link href={`https://${platformUrl?.url}`} target="__blank" rel="noopener noreferrer">
                <BiLinkExternal />
              </Link>
            }
          />
        </InputGroup>
        <FormErrorMessage>{formValidation?.url.message}</FormErrorMessage>
        <FormHelperText fontSize="xs">
          <Text as="span" fontWeight="bold">
            Não
          </Text>{' '}
          utilize espaços, pontos, ou caracteres especiais. Utilize apenas letras e números.
        </FormHelperText>
      </FormControl>

      <Divider />
      <HStack fontSize="md">
        <Text>Seu domínio alternativo: </Text>
        <Link
          href={platformUrl?.alternativeUrl ? 'https://'.concat(platformUrl?.alternativeUrl) : '#'}
          target="__blank"
          rel="noopener noreferrer"
          fontWeight="bold"
          color="blue.500"
        >
          {platformUrl?.alternativeUrl ? 'https://'.concat(platformUrl?.alternativeUrl) : ''}
        </Link>
      </HStack>
      <Text>
        Última modificação:{' '}
        {platformUrl?.lastUrlChange ? dayjs(platformUrl.lastUrlChange).format('DD/MM/YYYY') : '-'}
      </Text>
      <HStack justifyContent="flex-end" width="full">
        <Button
          colorScheme="gray"
          isDisabled={!onChanged || !isProlunoDomain(platformUrl.url)}
          isLoading={isLoading}
          width="52"
          alignSelf="flex-end"
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          colorScheme="orange"
          isDisabled={!onChanged || !isProlunoDomain(platformUrl.url)}
          isLoading={isLoading}
          width="52"
        >
          Salvar
        </Button>
      </HStack>
    </VStack>
  );
}
