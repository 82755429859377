import {
  Badge,
  Box,
  Button,
  Card,
  chakra,
  Spinner,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { useState } from 'react';
import Toast from '../../../../../components/Toast';
import { ROLE_LOCAL_STORAGE, TOKEN_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import { Question } from '../../../../DashboardAdmin/Courses/CourseManager/ModulesManager/QuestionLibrary/types';
import ResetQuestion from '../../../../DashboardStudent/Questions/ResetQuestion';
import QuestionCardFooter from '../QuestionCardFooter';

interface IProps {
  question: Question;
  isSubmitting?: boolean;
  onSubmit?: (questionId: number, optionId: number) => void;
  onOpen?: () => void;
  textColor?: string;
  bgColor?: string;
  alternateTextColor?: string;
  fetchData?(): Promise<any>;
}

function CustomRadio(props) {
  const { option, optionText, ...radioProps } = props;
  const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } = useRadio(radioProps);

  return (
    <chakra.label
      display="flex"
      flexDirection="row"
      alignItems="center"
      gridColumnGap={2}
      rounded="lg"
      py={1}
      cursor="pointer"
      {...htmlProps}
    >
      <input {...getInputProps({})} hidden />
      <Box
        {...getRadioProps()}
        width="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        border="1px solid"
        borderColor={state.isChecked ? 'orange.500' : props.textColor ? props.textColor : '#202123'}
        borderRadius="full"
        boxSize="25px"
        fontSize="16px"
        fontWeight={500}
      >
        <Box
          {...getLabelProps()}
          rounded="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
          boxSize="23px"
          color={state.isChecked ? '#FFF' : props.textColor ? props.textColor : '#202123'}
          bg={state.isChecked ? 'orange.500' : props.bgColor ? props.bgColor : '#FFF'}
        >
          {option.toLowerCase()}.
        </Box>
      </Box>
      <Text>{optionText}</Text>
    </chakra.label>
  );
}

export default function QuestionCard({
  question,
  onSubmit,
  isSubmitting,
  onOpen,
  textColor,
  bgColor,
  alternateTextColor,
  fetchData,
}: IProps) {
  const [hasChange, setHasChange] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');

  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
  const isStudent = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'ALUNO';
  const hasUserAnswer = question?.userOptionAnswer;

  async function handleClick() {
    if (!token) return onOpen();

    if (!isStudent) {
      Toast('Apenas alunos podem responder as questões!', 'warn');
      return;
    }

    onSubmit(question.id, Number(userAnswer));
    setHasChange(false);
  }

  function handleChange(value: string) {
    setHasChange(true);

    if (!isStudent) return;

    setUserAnswer(value);
  }

  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: question?.userOptionAnswer?.optionId.toString(),
    onChange: handleChange,
  });

  const { isLoading, handleSubmit } = useHandleSubmit({
    data: { questionsLibraryIds: [question.id] },
    url: '/question-library-student/members-area/question-external/reset-answers',
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Questão disponível para nova resposta!',
      callback: () => {
        fetchData();
      },
    },
  });

  return (
    <Card
      borderRadius="10px"
      color={textColor ? textColor : '#202123'}
      bgColor={bgColor ? bgColor : '#FFF'}
      boxShadow="0px 4px 4px 0px #00000040"
      border="1px solid #2021230D"
    >
      <Stack padding={{ base: '20px', md: '30px', lg: '30px' }} spacing="18px">
        <Box
          display="flex"
          flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
          alignItems={{ base: 'self-start', md: 'center', lg: 'center' }}
          gap={2}
        >
          <Text
            color="#EB7129"
            fontSize="12px"
            fontWeight="500"
            lineHeight="16px"
            borderRadius="6px"
            border="1px solid #EB7129"
            padding="0px 8px"
          >
            ID: {question?.id}
          </Text>
          <Stack
            direction="row"
            color={alternateTextColor ? alternateTextColor : '#20212390'}
            textAlign="center"
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="400"
            lineHeight="24px"
            flexWrap="wrap"
            spacing={0}
            gap={2}
          >
            <Text>{question?.questionLibraryArea?.name ?? ''}</Text>
            <Text hidden={!question?.questionLibraryDiscipline?.name}>/</Text>
            <Text>{question?.questionLibraryDiscipline?.name ?? ''}</Text>
            <Text hidden={!question?.questionLibrarySubject?.name}>/</Text>
            <Text>{question?.questionLibrarySubject?.name ?? ''}</Text>
            <Text hidden={!question?.questionLibraryInstitution?.name}>/</Text>
            <Text>{question?.questionLibraryInstitution?.name ?? ''}</Text>
          </Stack>
        </Box>

        <Text
          fontSize="18px"
          fontWeight="400"
          lineHeight="20px"
          dangerouslySetInnerHTML={{ __html: question?.questionText }}
        />

        <Stack {...getRootProps()} spacing="18px">
          {question?.options.length > 0 &&
            question?.options?.map((option, index) => {
              return (
                <CustomRadio
                  key={index}
                  option={option?.option}
                  optionText={option?.optionText}
                  textColor={textColor}
                  bgColor={bgColor}
                  {...getRadioProps({
                    value: option?.id.toString(),
                  })}
                  isDisabled={hasUserAnswer}
                />
              );
            })}

          <Stack direction="row" alignItems="center" spacing="15px">
            <Button
              onClick={handleClick}
              colorScheme="orange"
              bgColor="#EC7117"
              color="#FFFFFF"
              size="sm"
              isDisabled={isSubmitting || !hasChange}
              isLoading={isSubmitting}
            >
              Responder
            </Button>

            <ResetQuestion
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              rightAnswer={!question?.userOptionAnswer?.rightAnswer}
            />
          </Stack>

          <Box hidden={!isSubmitting}>
            <Spinner color="orange.500" size="sm" />
          </Box>

          <Box hidden={!question?.userOptionAnswer?.rightAnswer || isSubmitting}>
            <Badge
              padding="0 4px"
              colorScheme={question?.userOptionAnswer?.answerResult ? 'green' : 'red'}
              borderRadius={2}
            >
              {question.userOptionAnswer?.answerResult
                ? `Você acertou, parabéns!`
                : `Você errou! Resposta: ${question?.userOptionAnswer?.rightAnswer}`}
            </Badge>
          </Box>
        </Stack>

        {hasUserAnswer && <QuestionCardFooter questionId={question.id} />}
      </Stack>
    </Card>
  );
}
