import { axiosBackend } from './Configuration';

const AdminAPI = {
  releaseAccess: async (purchase_id: number) => {
    try {
      return await axiosBackend().patch(`/releaseAccess/${purchase_id}/purchase`);
    } catch (error) {
      throw error;
    }
  },

  expirePassword: async userId => {
    try {
      const { data: userData } = await axiosBackend().patch(
        `/subscriber/${userId}/user/expire-password`
      );

      return userData;
    } catch (error) {
      throw error;
    }
  },

  updateUser: async (userId: number, email: string, subscriberSuspended: boolean) => {
    try {
      const { data: userData } = await axiosBackend().patch(`/subscriber/${userId}`, {
        email,
        subscriberSuspended,
      });

      return userData;
    } catch (error) {
      throw error;
    }
  },

  updateDomain: async (userId: number, subdomain: string, content?: string, type?: string) => {
    try {
      const { data: userData } = await axiosBackend().patch(`/platform/subscriber/${userId}`, {
        subdomain,
        content,
        type,
      });

      return userData;
    } catch (error) {
      throw error;
    }
  },
};

export default AdminAPI;
