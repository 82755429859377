import { Box, Hide, Show, Stack, useBreakpointValue } from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import FullPageLoading from '../../../../components/FullPageLoading';
import { useCourseStudent } from '../../../../contexts/CourseStudentContextV2';
import { useStudentProgress } from '../../../../contexts/ProgressContextV2';
import { filterModulesAndContentByName } from '../../../DashboardStudent/Products/ContentsView';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import ModuleDesktopSidebar from './components/ModuleDesktopSidebar';
import ModuleMobileSidebar from './components/ModuleMobileSidebar';
import NoContentView from './NoContentView';
import ViewAudio from './ViewAudio';
import ViewCertificate from './ViewCertificate';
import ViewDownload from './ViewDownload';
import ViewMultimedia from './ViewMultimedia';
import ViewPDF from './ViewPDF';
import ViewQuestions from './ViewQuestions';
import ViewTask from './ViewTask';
import ViewText from './ViewText';
import ViewVideo from './ViewVideo';
import ViewYouTube from './ViewYoutube';
import ViewZoom from './ViewZoom';

export default function ContentsView() {
  const history = useHistory();
  const hiddenBreadCrumb = useBreakpointValue({ base: false, lg: true });

  const {
    course,
    isSidebarVisible,
    highlightColor,
    showProgress,
    shouldMoveToTerms,
    courseModulesAndContentsLoading,
    hasActiveModulesPage,
  } = useCourseStudent();

  const { amountContentViewed, progress } = useStudentProgress();

  const [modulesFilters, setModulesFilters] = useState([]);
  const [search, setSearch] = useState('');
  const [timer, setTimer] = useState(null);

  useScrollToTop();

  useEffect(() => {
    if (course?.modules?.length) {
      setModulesFilters(course?.modules);
    }
  }, [course?.modules]);

  useEffect(() => {
    if (shouldMoveToTerms) {
      const timer = setTimeout(() => {
        history.push(
          `/members-area/v2/accept-term/${course?.id}/course/${course?.courseUserId}/course-user-id`
        );
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [course?.courseUserId, course?.id, history, shouldMoveToTerms]);

  const handleInputChange = event => {
    const { value } = event.target;
    setSearch(value);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimeout = setTimeout(() => {
      const filteredModules = filterModulesAndContentByName(value, course?.modules);
      setModulesFilters(filteredModules);
    }, 500);

    setTimer(newTimeout);
  };

  return (
    <Box width="full" bg="#202123" height="100vh" overflow="hidden">
      <Stack
        width="full"
        height="full"
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        spacing={{ base: 0, lg: '50px' }}
        marginTop={{ base: '22px', md: 0 }}
        padding={{ base: '0 15px 0 15px', md: '108px 50px 0 50px' }}
      >
        {isSidebarVisible && (
          <Box minW="250px">
            <Hide below="lg">
              <ModuleDesktopSidebar
                title={course?.name}
                modulesFilters={modulesFilters}
                handleInputChange={handleInputChange}
                search={search}
                highlightColor={highlightColor}
                showProgress={showProgress}
                progress={progress.progress}
                countContent={progress.countContent}
                amountContentViewed={amountContentViewed}
                hasActiveModulesPage={hasActiveModulesPage}
              />
            </Hide>

            <Show below="lg">
              <ModuleMobileSidebar
                modulesFilters={modulesFilters}
                handleInputChange={handleInputChange}
                search={search}
                highlightColor={highlightColor}
                showProgress={showProgress}
                progress={progress.progress}
                countContent={progress.countContent}
                amountContentViewed={amountContentViewed}
              />
            </Show>
          </Box>
        )}

        <Box
          width="full"
          height="100%"
          maxHeight={{ base: '92vh', md: '82vh' }}
          overflow="auto"
          sx={{ '::-webkit-scrollbar': { width: '0', background: 'transparent' } }}
        >
          <Box margin="28px 0 25px 0" hidden={hiddenBreadCrumb}>
            <Breadcrumb title={course?.name} hasActiveModulesPage={hasActiveModulesPage} />
          </Box>

          <Switch>
            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-video"
              component={ViewVideo}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-multimedia"
              component={ViewMultimedia}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-text"
              component={ViewText}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-pdf"
              component={ViewPDF}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-download"
              component={ViewDownload}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-live-youtube"
              component={ViewYouTube}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-live-zoom"
              component={ViewZoom}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-audio"
              component={ViewAudio}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-task"
              component={ViewTask}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-questions"
              component={ViewQuestions}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content/:contentId/content-view/view-question-library"
              component={ViewQuestions}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId/content-view/certificate"
              component={ViewCertificate}
            />

            <Route
              path="/members-area/v2/course/:courseId/module/:moduleId"
              component={NoContentView}
            />
          </Switch>
        </Box>

        <FullPageLoading isLoading={courseModulesAndContentsLoading} />
      </Stack>
    </Box>
  );
}
