import {
  EMAIL_LOCAL_STORAGE,
  EMAIL_ORIGIN_LOGIN,
  ID_LOCAL_STORAGE,
  ID_ORIGIN_LOGIN,
  NAME_LOCAL_STORAGE,
  NAME_ORIGIN_LOGIN,
  PHOTO_LOCAL_STORAGE,
  PHOTO_ORIGIN_LOGIN,
  ROLE_LOCAL_STORAGE,
  ROLE_ORIGIN_LOGIN,
  TOKEN_LOCAL_STORAGE,
  TOKEN_ORIGIN_LOGIN,
} from '../helpers/LocalStorageHelper';
import { axiosBackend, detectDevice } from './Configuration';

function subscriptionServiceWorker() {
  navigator.serviceWorker
    .register('/service-worker.js', { scope: '/' })
    .then(async serviceWorker => {
      let subscription = await serviceWorker.pushManager.getSubscription();

      const currentUserId = localStorage.getItem(ID_LOCAL_STORAGE);

      if (!subscription && currentUserId) {
        try {
          const { data: response } = await axiosBackend().get('/notification/push/public-key');

          const key = response.data;

          subscription = await serviceWorker.pushManager.subscribe({
            applicationServerKey: key,
            userVisibleOnly: true,
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }

      const device = detectDevice();

      await axiosBackend().post(`/notification/push/register`, {
        ...subscription.toJSON(),
        device,
      });
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error('Error Service Worker:', error);
    });
}

const requestNotificationPermission = async () => {
  if ('Notification' in window) {
    Notification.requestPermission()
      .then(permission => {
        if (permission === 'granted') {
          subscriptionServiceWorker();
        } else if (permission === 'denied') {
          // eslint-disable-next-line no-console
          console.log('Permissão de notificação negada');
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('Erro ao solicitar permissão de notificação:', error);
      });
  }
};

export default {
  validateToken: async token => {
    if (!token) return false;

    try {
      const { data: authentication } = await axiosBackend().post('/validateToken', {
        token,
      });

      localStorage.setItem(ID_LOCAL_STORAGE, authentication.data.id);
      localStorage.setItem(NAME_LOCAL_STORAGE, authentication.data.fullName);
      localStorage.setItem(TOKEN_LOCAL_STORAGE, authentication.data.token);
      localStorage.setItem(ROLE_LOCAL_STORAGE, authentication.data.role);
      localStorage.setItem(EMAIL_LOCAL_STORAGE, authentication.data.email);
      localStorage.setItem(PHOTO_LOCAL_STORAGE, authentication.data.photo);

      if (authentication.data.role !== 'ALUNO') {
        requestNotificationPermission();
      }

      return authentication;
    } catch (error) {
      //remove usuário padrão
      localStorage.removeItem(ID_LOCAL_STORAGE);
      localStorage.removeItem(TOKEN_LOCAL_STORAGE);
      localStorage.removeItem(NAME_LOCAL_STORAGE);
      localStorage.removeItem(ROLE_LOCAL_STORAGE);
      localStorage.removeItem(EMAIL_LOCAL_STORAGE);
      localStorage.removeItem(PHOTO_LOCAL_STORAGE);

      //remove login alternativo
      localStorage.removeItem(ID_ORIGIN_LOGIN);
      localStorage.removeItem(NAME_ORIGIN_LOGIN);
      localStorage.removeItem(TOKEN_ORIGIN_LOGIN);
      localStorage.removeItem(ROLE_ORIGIN_LOGIN);
      localStorage.removeItem(EMAIL_ORIGIN_LOGIN);
      localStorage.removeItem(PHOTO_ORIGIN_LOGIN);
    }
  },

  login: async (email = '', password = '') => {
    try {
      const responseAuthentication = await axiosBackend().post('/login', {
        email,
        password,
      });

      if (responseAuthentication) {
        const authentication = responseAuthentication?.data;

        if (authentication && authentication.data.token) {
          localStorage.setItem(ID_LOCAL_STORAGE, authentication.data.id);
          localStorage.setItem(NAME_LOCAL_STORAGE, authentication.data.fullName);
          localStorage.setItem(TOKEN_LOCAL_STORAGE, authentication.data.token);
          localStorage.setItem(ROLE_LOCAL_STORAGE, authentication.data.role);
          localStorage.setItem(EMAIL_LOCAL_STORAGE, authentication.data.email);
          localStorage.setItem(PHOTO_LOCAL_STORAGE, authentication.data.photo);

          if (authentication.data.role !== 'ALUNO') {
            requestNotificationPermission();
          }

          return authentication;
        }
      }
    } catch (error) {
      throw error;
    }
  },

  loginAsUser: async userId => {
    try {
      const response = await axiosBackend().post('/login-as', { userId });

      if (response) {
        const authenticationAs = response?.data.data;

        if (authenticationAs && authenticationAs.token) {
          const currentUserId = localStorage.getItem(ID_LOCAL_STORAGE);
          const currentFullName = localStorage.getItem(NAME_LOCAL_STORAGE);
          const currentToken = localStorage.getItem(TOKEN_LOCAL_STORAGE);
          const currentRole = localStorage.getItem(ROLE_LOCAL_STORAGE);
          const currentEmail = localStorage.getItem(EMAIL_LOCAL_STORAGE);
          const currentPhoto = localStorage.getItem(PHOTO_LOCAL_STORAGE);

          localStorage.setItem(ID_ORIGIN_LOGIN, currentUserId);
          localStorage.setItem(NAME_ORIGIN_LOGIN, currentFullName);
          localStorage.setItem(TOKEN_ORIGIN_LOGIN, currentToken);
          localStorage.setItem(ROLE_ORIGIN_LOGIN, currentRole);
          localStorage.setItem(EMAIL_ORIGIN_LOGIN, currentEmail);
          localStorage.setItem(PHOTO_ORIGIN_LOGIN, currentPhoto);

          localStorage.setItem(ID_LOCAL_STORAGE, authenticationAs.id);
          localStorage.setItem(NAME_LOCAL_STORAGE, authenticationAs.fullName);
          localStorage.setItem(TOKEN_LOCAL_STORAGE, authenticationAs.token);
          localStorage.setItem(ROLE_LOCAL_STORAGE, authenticationAs.role);
          localStorage.setItem(EMAIL_LOCAL_STORAGE, authenticationAs.email);
          localStorage.setItem(PHOTO_LOCAL_STORAGE, authenticationAs.photo);

          if (authenticationAs.role !== 'ALUNO') {
            requestNotificationPermission();
          }

          return authenticationAs;
        }
      }
    } catch (error) {
      throw error;
    }
  },

  logoutAsUser: async history => {
    const currentToken = localStorage.getItem(TOKEN_ORIGIN_LOGIN);

    if (currentToken) {
      const currentUserId = localStorage.getItem(ID_ORIGIN_LOGIN);
      const currentFullName = localStorage.getItem(NAME_ORIGIN_LOGIN);
      const currentToken = localStorage.getItem(TOKEN_ORIGIN_LOGIN);
      const currentRole = localStorage.getItem(ROLE_ORIGIN_LOGIN);
      const currentEmail = localStorage.getItem(EMAIL_ORIGIN_LOGIN);
      const currentPhoto = localStorage.getItem(PHOTO_ORIGIN_LOGIN);

      localStorage.setItem(ID_LOCAL_STORAGE, currentUserId);
      localStorage.setItem(NAME_LOCAL_STORAGE, currentFullName);
      localStorage.setItem(TOKEN_LOCAL_STORAGE, currentToken);
      localStorage.setItem(ROLE_LOCAL_STORAGE, currentRole);
      localStorage.setItem(EMAIL_LOCAL_STORAGE, currentEmail);
      localStorage.setItem(PHOTO_LOCAL_STORAGE, currentPhoto);

      localStorage.removeItem(ID_ORIGIN_LOGIN);
      localStorage.removeItem(NAME_ORIGIN_LOGIN);
      localStorage.removeItem(TOKEN_ORIGIN_LOGIN);
      localStorage.removeItem(ROLE_ORIGIN_LOGIN);
      localStorage.removeItem(EMAIL_ORIGIN_LOGIN);
      localStorage.removeItem(PHOTO_ORIGIN_LOGIN);

      history.push('/user-base');
      return;
    }

    localStorage.removeItem(ID_LOCAL_STORAGE);
    localStorage.removeItem(NAME_LOCAL_STORAGE);
    localStorage.removeItem(TOKEN_LOCAL_STORAGE);
    localStorage.removeItem(ROLE_LOCAL_STORAGE);
    localStorage.removeItem(EMAIL_LOCAL_STORAGE);
    localStorage.removeItem(PHOTO_LOCAL_STORAGE);

    history.push('/login');
  },
};
