import './style.css';

import { ReactComponent as ActivityIcon } from '../../../assets/icons/activityIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/downloadIcon.svg';
import { ReactComponent as MultimediaIcon } from '../../../assets/icons/multimediaIcon.svg';
import { ReactComponent as PdfIcon } from '../../../assets/icons/pdfIcon.svg';
import { ReactComponent as QuestionsIcon } from '../../../assets/icons/questionsIcon.svg';
import { ReactComponent as TextIcon } from '../../../assets/icons/textIcon.svg';
import { ReactComponent as VideoIcon } from '../../../assets/icons/videoIcon.svg';
import { ReactComponent as YouTubeLiveIcon } from '../../../assets/icons/youtubeLiveIcon.svg';
import { ReactComponent as ZoomLiveIcon } from '../../../assets/icons/zoomLiveIcon.svg';
import { ReactComponent as AudioIcon } from '../../../assets/icons/audioIcon.svg';

function getIcon(contentType) {
  return {
    VIDEO: <VideoIcon fill="#202123" className="ml-1" />,
    QUESTOES: <QuestionsIcon fill="#202123" className="ml-1" />,
    MULTIMIDIA: <MultimediaIcon fill="#202123" className="ml-1" />,
    TEXTO: <TextIcon fill="#202123" className="ml-1" />,
    PDF: <PdfIcon fill="#202123" className="ml-1" />,
    DOWNLOAD: <DownloadIcon fill="#202123" className="ml-1" />,
    TAREFA: <ActivityIcon fill="#202123" className="ml-1" />,
    AO_VIVO_ZOOM: <ZoomLiveIcon fill="#202123" className="ml-1" />,
    AO_VIVO_YOUTUBE: <YouTubeLiveIcon fill="#202123" className="ml-1" />,
    AUDIO: <AudioIcon fill="#202123" className="ml-1" />,
  }[contentType];
}

function getNameContentType(contentType) {
  switch (contentType) {
    case 'TEXTO':
      return 'Texto';
    case 'MULTIMIDIA':
      return 'Multimídia';
    case 'PDF':
      return 'PDF';
    case 'DOWNLOAD':
      return 'Download';
    case 'VIDEO':
      return 'Vídeo';
    case 'AO_VIVO_ZOOM':
      return 'Ao vivo Zoom';
    case 'AO_VIVO_YOUTUBE':
      return 'Ao vivo YouTube';
    case 'TAREFA':
      return 'Tarefa';
    case 'AUDIO':
      return 'Áudio';
    default:
      break;
  }
}

function Content({ title, contentType }) {
  return (
    <div className="d-flex align-center mb-2">
      <div className="d-flex align-items-center justify-between w-100">
        <span className="sale-content-title">{title}</span>
        <div className="d-flex">
          <div>
            <span className="formatted-contentType">{getNameContentType(contentType)}</span>
          </div>
          <div className="ml-2">{getIcon(contentType)}</div>
        </div>
      </div>
    </div>
  );
}

export default Content;
