export const CHAKRA_REACT_SELECT_STYLES = {
  container: provided => ({
    ...provided,
    width: '100%',
    color: '#20212380',
    fontSize: 'sm',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 1000000,
  }),
  menuList: provided => ({
    ...provided,
    zIndex: 1000000,
  }),
  placeholder: provided => ({
    ...provided,
    color: '#20212380',
    fontSize: 'sm',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    bg: 'transparent',
    color: '#202123',
    px: 2.5,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  option: provided => ({
    ...provided,
    color: '#20212380',
    fontSize: 'sm',
  }),
  valueContainer: provided => ({
    ...provided,
    px: 3,
    color: '#20212380',
    fontSize: 'sm',
  }),
};

export const TEXT_EDITOR_STYLES = {
  '.ql-toolbar': {
    borderRadius: '6px 6px 0px 0px',
    borderColor: '#D2D2D2 !important',
  },
  '.ql-container': {
    borderRadius: '0px 0px 6px 6px',
    borderColor: '#D2D2D2 !important',
  },
};

export const TEXT_EDITOR_STYLES_INVALID = {
  '.ql-toolbar': {
    borderRadius: '6px 6px 0px 0px',
    borderColor: 'red.500 !important',
  },
  '.ql-container': {
    borderRadius: '0px 0px 6px 6px',
    borderColor: 'red.500 !important',
  },
};

export const FIVE_MEGABYTES = 5 * 1024 * 1024;
