import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Stack,
  Text,
  Tooltip,
  Icon,
} from '@chakra-ui/react';

import { CheckIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ActivityIcon } from '../../../../../../assets/icons/activityIcon.svg';
import { ReactComponent as AudioIcon } from '../../../../../../assets/icons/audioIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../../../../assets/icons/downloadIcon.svg';
import { ReactComponent as MultimediaIcon } from '../../../../../../assets/icons/multimediaIcon.svg';
import { ReactComponent as PdfIcon } from '../../../../../../assets/icons/pdfIcon.svg';
import { ReactComponent as QuestionLibraryIcon } from '../../../../../../assets/icons/question-library-icon.svg';
import { ReactComponent as QuestionsIcon } from '../../../../../../assets/icons/questionsIcon.svg';
import { ReactComponent as TextIcon } from '../../../../../../assets/icons/textIcon.svg';
import { ReactComponent as VideoIcon } from '../../../../../../assets/icons/videoIcon.svg';
import { ReactComponent as YouTubeLiveIcon } from '../../../../../../assets/icons/youtubeLiveIcon.svg';
import { ReactComponent as ZoomLiveIcon } from '../../../../../../assets/icons/zoomLiveIcon.svg';
import { useStudentCourse } from '../../../../../../contexts/CourseStudentContext';
import { useProgressContext } from '../../../../../../contexts/ProgressContext';
import getRouteToShow from '../../../../../../helpers/getRouteToShow';
import { MdLockOutline as LockIcon } from 'react-icons/md';

const today = dayjs();

function getReleaseDateText(releaseDate) {
  return `Liberação: ${dayjs(releaseDate).utc().format('DD/MM/YYYY')}`;
}

function isModuleAvailable(moduleReleaseDate) {
  if (!moduleReleaseDate) return true;

  return today.isSameOrAfter(moduleReleaseDate);
}

function getContentsText(contents) {
  if (!contents.length) {
    return 'Módulo sem conteúdos';
  }

  return `Total de conteúdos: ${contents.length}`;
}

function ContentList({ courseId, moduleId, contents, contentIdSelect, setContentIdSelect }) {
  const { getCurrentContent } = useStudentCourse();
  const { markAsDoneHandleChange } = useProgressContext();
  const history = useHistory();
  const sortedContents = contents.sort((a, b) => a.order - b.order);

  function getIcon(contentType) {
    return {
      VIDEO: <VideoIcon fill="#202123" width="16px" />,
      QUESTOES: <QuestionsIcon fill="#202123" width="16px" />,
      BANCO_DE_QUESTOES: <QuestionLibraryIcon fill="#202123" width="16px" />,
      MULTIMIDIA: <MultimediaIcon fill="#202123" width="16px" />,
      TEXTO: <TextIcon fill="#202123" width="16px" />,
      PDF: <PdfIcon fill="#202123" width="16px" />,
      DOWNLOAD: <DownloadIcon fill="#202123" width="16px" />,
      TAREFA: <ActivityIcon fill="#202123" width="16px" />,
      AO_VIVO_ZOOM: <ZoomLiveIcon fill="#202123" width="16px" />,
      AO_VIVO_YOUTUBE: <YouTubeLiveIcon fill="#202123" width="16px" />,
      AUDIO: <AudioIcon fill="#202123" width="16px" />,
    }[contentType];
  }

  function handleContentClick(content) {
    const baseURL = `/members-area/v1/products/${courseId}/content-view/module/${moduleId}/content/${content.id}`;

    const path = getRouteToShow(content.contentType);
    history.push(`${baseURL}/${path}`);

    getCurrentContent(moduleId, content.id);
    window.scrollTo(0, 0);
  }

  if (!contents.length)
    return (
      <>
        {!contents?.length && (
          <Text
            margin="5px 18px"
            color="#20212380"
            fontWeight={600}
            width={{ base: 'full', md: '250px', lg: '250px' }}
          >
            Modulo sem conteúdos
          </Text>
        )}
      </>
    );

  return sortedContents.map(content => {
    return (
      <Stack
        key={nanoid()}
        onClick={() => {
          handleContentClick(content);
          setContentIdSelect(content.id);
        }}
        spacing="0"
        cursor="pointer"
        padding="7px 20px"
        bgColor={content.id === contentIdSelect ? 'rgba(32, 33, 35, 0.15)' : '#FFF'}
        _hover={{ bgColor: 'rgba(32, 33, 35, 0.15)', transition: '0.3s all ease' }}
        minH="38px"
      >
        <Box width="full" display="flex" justifyContent="space-between" alignItems="center" gap="2">
          <Box display="flex" gap="2">
            <Box width="16px">{getIcon(content.contentType)}</Box>

            <Text
              width="160px"
              wordBreak="break-word"
              fontSize="12px"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {content.title}
            </Text>
          </Box>

          <Box
            id={`checkbox${content.id}`}
            padding={0}
            name={`checkbox${content.id}`}
            background={content?.markAsDone ? 'primary.500' : 'transparent'}
            border="1px solid"
            boxSize="14px"
            borderColor={content?.markAsDone ? 'primary.500' : 'black'}
            borderRadius="0"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              markAsDoneHandleChange({
                contentId: content.id,
                moduleId,
                markAsDone: !content?.markAsDone,
              });
            }}
            sx={{
              '&:hover': {
                background: 'primary.500',
              },
            }}
          >
            {content?.markAsDone && (
              <CheckIcon color={content?.markAsDone ? 'white' : 'textColor'} boxSize="12px" />
            )}
          </Box>
        </Box>
      </Stack>
    );
  });
}

function Module({
  courseId,
  moduleId,
  title,
  contents,
  contentIdSelect,
  setContentIdSelect,
  releaseDate,
}) {
  return (
    <AccordionItem
      margin="18px"
      borderRadius="10px"
      border="1px solid rgba(32, 33, 35, 0.50)"
      background="#FFF"
      width={{ base: '90%', md: '250px', lg: '250px' }}
      isDisabled={!isModuleAvailable(releaseDate)}
    >
      <AccordionButton padding="7px 14px" height="52px" gap={2} alignItems="self-start">
        <HStack spacing={1} flex={1} justify="space-between" align="start" maxWidth="100%">
          <Stack textAlign="start" spacing={0} flex={1} overflow="hidden">
            <Tooltip isDisabled={title.length < 15} label={title ?? 'Módulo sem título'}>
              <HStack spacing={1} overflow="hidden">
                {!isModuleAvailable(releaseDate) && (
                  <Icon as={LockIcon} boxSize={{ base: 4, xl: 4 }} color="#202123" />
                )}

                <Text
                  height="19px"
                  flexShrink="0"
                  color="#202123"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="normal"
                  maxWidth="99%"
                  isTruncated
                >
                  {title ?? 'Módulo sem título'}
                </Text>
              </HStack>
            </Tooltip>

            <Text
              width={{ base: 'full', md: '186px', lg: '186px' }}
              color="#202123"
              fontSize="10px"
              fontWeight="400"
              lineHeight="normal"
            >
              {isModuleAvailable(releaseDate)
                ? getContentsText(contents)
                : getReleaseDateText(releaseDate)}
            </Text>
          </Stack>

          <AccordionIcon />
        </HStack>
      </AccordionButton>
      <AccordionPanel padding={0} marginY="7px">
        <ContentList
          courseId={courseId}
          moduleId={moduleId}
          contents={contents}
          contentIdSelect={contentIdSelect}
          setContentIdSelect={setContentIdSelect}
        />
      </AccordionPanel>
    </AccordionItem>
  );
}

export default memo(Module);
