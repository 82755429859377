import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { Suspense } from 'react';
import CountrySelect from '../../../../components/CountrySelect';
import CustomDropzone from '../../../../components/CustomDropzone';
import Editor from '../../../../components/Editor';
import { onlyNumber } from '../../../../hooks/useHandleChange';
import findState from '../../../../utils/findState';
import { UserProfile } from '../types';
import ImagePreview from './ImagePreview';
import ImageUploadLoading from './ImageUploadLoading';
import schemaValidator from './schemaValidator';
import useProfileForm from './useProfileForm';

const TEXT_EDITOR_STYLES = {
  '.ql-toolbar': {
    borderRadius: '6px 6px 0px 0px',
    borderColor: '#D2D2D2 !important',
  },
  '.ql-container': {
    borderRadius: '0px 0px 6px 6px',
    borderColor: '#D2D2D2 !important',
  },
};

interface ProfileFormProps extends BoxProps {
  user?: UserProfile;
}

export default function ProfileForm({ user, ...rest }: ProfileFormProps) {
  const {
    form,
    formValidation,
    isSubmitting,
    isUploading,
    coverBanner,
    photo,
    hasChange,
    isInstructor,
    cities,
    states,
    country,
    handleCountryChange,
    handleChange,
    onSubmit,
    handleSelectNewImage,
    handleDropFile,
    handleDropRejected,
    handleSocialNetworkChange,
    handleCancel,
    handleStateChange,
    handleDescriptionChange,
  } = useProfileForm({
    user,
    successMessage: 'Perfil atualizado com sucesso!',
    schemaValidator,
  });

  const isBrazilian = country === 'br';

  return (
    <Box
      as="form"
      onSubmit={onSubmit}
      {...rest}
      sx={{
        label: { fontSize: 'sm', color: '#202123' },
        'input::placeholder,textarea::placeholder': { color: '#2D374880' },
      }}
      noValidate
    >
      <Box as="section">
        <Stack direction={{ base: 'column', xl: 'row' }} gap={5}>
          <FormControl
            isInvalid={!!formValidation?.photo?.isInvalid}
            maxWidth={{ base: 'unset', xl: '16.75rem' }}
          >
            <Flex position="relative" height={{ base: '23.5625rem', xl: '16.75rem' }}>
              {!form?.photo ? (
                <>
                  <CustomDropzone
                    file={photo?.file}
                    isDisabled={isSubmitting}
                    accept={['image/jpg', 'image/png', 'image/jpeg']}
                    dimensions="1080x1080"
                    onDrop={acceptedFiles => handleDropFile('photo', acceptedFiles)}
                    onDropRejected={handleDropRejected}
                    extensions="JPG, PNG, JPEG"
                    maxFileSize="5MB"
                  />

                  <ImageUploadLoading position="absolute" isLoading={isUploading} />
                </>
              ) : (
                <VStack w="full">
                  <Box
                    height={{ base: '21.5625rem', xl: '16.75rem' }}
                    w="full"
                    borderRadius="md"
                    overflow="hidden"
                  >
                    <ImagePreview
                      name="photo"
                      preview={photo?.preview}
                      onSelectNewImage={handleSelectNewImage}
                    />
                  </Box>

                  <Button
                    display={{ base: 'flex', xl: 'none' }}
                    onClick={() => handleSelectNewImage('photo')}
                    w="full"
                    variant="outline"
                    colorScheme="primary"
                    size="sm"
                    zIndex="2"
                  >
                    Trocar Imagem
                  </Button>
                </VStack>
              )}
            </Flex>

            <FormErrorMessage>{formValidation?.photo?.message}</FormErrorMessage>
          </FormControl>

          <VStack w="full" justify="space-between">
            <FormControl isInvalid={!!formValidation?.fullName?.isInvalid}>
              <FormLabel>
                Nome
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <Input
                name="fullName"
                value={form?.fullName}
                onChange={handleChange}
                placeholder="Digite aqui"
                mt={2}
                focusBorderColor="gray.200"
              />

              <FormErrorMessage>{formValidation?.fullName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formValidation?.email?.isInvalid}>
              <FormLabel>
                Email{' '}
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <Input
                name="email"
                value={form?.email}
                onChange={handleChange}
                placeholder="Digite aqui"
                mt={2}
                focusBorderColor="gray.200"
                type="email"
              />

              <FormErrorMessage>{formValidation?.email?.message}</FormErrorMessage>
            </FormControl>

            <Stack width="full" direction={{ base: 'column', md: 'row' }}>
              <FormControl mt={2}>
                <FormLabel htmlFor="country">País</FormLabel>
                <Suspense fallback={<Skeleton w="full" h={10} />}>
                  <CountrySelect value={country} onChange={handleCountryChange} />
                </Suspense>
              </FormControl>

              <HStack width="full">
                <FormControl
                  width="150px"
                  isInvalid={!!formValidation?.ddd?.isInvalid}
                  hidden={!isBrazilian}
                >
                  <FormLabel>
                    DDD{' '}
                    <Text as="span" color="red.500">
                      *
                    </Text>
                  </FormLabel>

                  <Input
                    name="ddd"
                    value={form?.ddd}
                    onChange={handleChange}
                    onInput={onlyNumber}
                    mt={2}
                    type="ddd"
                    maxLength={2}
                    focusBorderColor="gray.200"
                  />

                  <FormErrorMessage>{formValidation?.ddd?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!formValidation?.phone?.isInvalid}>
                  <FormLabel>
                    Telefone{' '}
                    <Text as="span" color="red.500">
                      *
                    </Text>
                  </FormLabel>

                  <Input
                    name="phone"
                    value={form?.phone}
                    onChange={handleChange}
                    onInput={onlyNumber}
                    placeholder="Digite aqui"
                    mt={2}
                    type="phone"
                    maxLength={20}
                    focusBorderColor="gray.200"
                  />

                  <FormErrorMessage>{formValidation?.phone?.message}</FormErrorMessage>
                </FormControl>
              </HStack>
            </Stack>
          </VStack>
        </Stack>

        <FormControl isInvalid={!!formValidation?.biography?.isInvalid} mt={8}>
          <FormLabel>Descrição</FormLabel>

          <Text color="#20212380" mb={2}>
            Faça uma breve descrição.
          </Text>

          <Box mt={2} sx={TEXT_EDITOR_STYLES}>
            <Editor value={form?.biography} onChange={handleDescriptionChange} />
          </Box>

          <FormErrorMessage>{formValidation?.description?.message}</FormErrorMessage>
        </FormControl>

        {isInstructor && (
          <>
            <FormControl isInvalid={!!formValidation?.coverBanner?.isInvalid} mt={8}>
              <FormLabel>Capa</FormLabel>

              <Text color="#20212380" mb={2}>
                Imagem que será adicionada como capa na página de instrutores.
              </Text>

              <Flex position="relative">
                {!form?.coverBanner ? (
                  <>
                    <CustomDropzone
                      file={coverBanner?.file}
                      isDisabled={isSubmitting}
                      accept={['image/jpg', 'image/png', 'image/jpeg']}
                      dimensions="1366x768"
                      onDrop={acceptedFiles => handleDropFile('coverBanner', acceptedFiles)}
                      onDropRejected={handleDropRejected}
                      extensions="JPG, PNG, JPEG"
                      maxFileSize="5MB"
                    />

                    <ImageUploadLoading position="absolute" isLoading={isUploading} />
                  </>
                ) : (
                  <VStack w="full">
                    <Box height={{ base: '4.5rem', xl: '13rem' }} w="full">
                      <ImagePreview
                        name="coverBanner"
                        preview={coverBanner?.preview}
                        onSelectNewImage={handleSelectNewImage}
                      />
                    </Box>
                    <Button
                      display={{ base: 'flex', xl: 'none' }}
                      onClick={() => handleSelectNewImage('coverBanner')}
                      w="full"
                      variant="outline"
                      colorScheme="primary"
                      size="sm"
                      zIndex="2"
                    >
                      Trocar Imagem
                    </Button>
                  </VStack>
                )}
              </Flex>

              <FormErrorMessage>{formValidation?.coverBanner?.message}</FormErrorMessage>
            </FormControl>
          </>
        )}
      </Box>

      {/* DOCUMENTO E ENDEREÇO */}
      <VStack as="section" justify="space-between" w="full" spacing={8} mt={8}>
        <FormControl isInvalid={!!formValidation?.documentNumber?.isInvalid}>
          <FormLabel>Documento</FormLabel>

          <Input
            name="documentNumber"
            value={form?.documentNumber}
            onChange={handleChange}
            onInput={onlyNumber}
            placeholder="xxx.xxx.xxx-xx"
            mt={2}
            focusBorderColor="gray.200"
          />

          <FormErrorMessage>{formValidation?.documentNumber?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formValidation?.alternateAddress?.isInvalid} hidden={isBrazilian}>
          <FormLabel>Endereço</FormLabel>

          <Input
            name="alternateAddress"
            value={form?.alternateAddress}
            onChange={handleChange}
            placeholder="Digite aqui"
            mt={2}
            focusBorderColor="gray.200"
          />

          <FormErrorMessage>{formValidation?.alternateAddress?.message}</FormErrorMessage>
        </FormControl>

        <Stack
          direction={{ base: 'column', xl: 'row' }}
          w="full"
          spacing={{ base: 8, xl: 5 }}
          hidden={!isBrazilian}
        >
          <FormControl isInvalid={!!formValidation?.zipCode?.isInvalid}>
            <FormLabel>CEP</FormLabel>

            <Input
              name="zipCode"
              value={form?.zipCode}
              onChange={handleChange}
              onInput={onlyNumber}
              placeholder="Digite aqui"
              mt={2}
              focusBorderColor="gray.200"
            />

            <FormErrorMessage>{formValidation?.zipCode?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formValidation?.streetAddress?.isInvalid}>
            <FormLabel>Rua</FormLabel>

            <Input
              name="streetAddress"
              value={form?.streetAddress}
              onChange={handleChange}
              placeholder="Digite aqui"
              mt={2}
              focusBorderColor="gray.200"
            />

            <FormErrorMessage>{formValidation?.streetAddress?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        <Stack
          direction={{ base: 'column', xl: 'row' }}
          w="full"
          spacing={{ base: 8, xl: 5 }}
          hidden={!isBrazilian}
        >
          <FormControl isInvalid={!!formValidation?.streetNumber?.isInvalid}>
            <FormLabel>Nº</FormLabel>

            <Input
              name="streetNumber"
              value={form?.streetNumber}
              onChange={handleChange}
              onInput={onlyNumber}
              placeholder="XXX"
              mt={2}
              focusBorderColor="gray.200"
              inputMode="numeric"
            />

            <FormErrorMessage>{formValidation?.streetNumber?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formValidation?.neighborhood?.isInvalid}>
            <FormLabel>Bairro</FormLabel>

            <Input
              name="neighborhood"
              value={form?.neighborhood}
              onChange={handleChange}
              placeholder="Digite aqui"
              mt={2}
              focusBorderColor="gray.200"
            />

            <FormErrorMessage>{formValidation?.neighborhood?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formValidation?.complementary?.isInvalid}>
            <FormLabel>
              Complemento{' '}
              <Text as="span" color="#20212380">
                {'(Opcional)'}
              </Text>
            </FormLabel>

            <Input
              name="complementary"
              value={form?.complementary}
              onChange={handleChange}
              placeholder="Digite aqui"
              mt={2}
              focusBorderColor="gray.200"
            />

            <FormErrorMessage>{formValidation?.complementary?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        <Stack
          direction={{ base: 'column', xl: 'row' }}
          w="full"
          spacing={{ base: 8, xl: 5 }}
          hidden={!isBrazilian}
        >
          <FormControl isInvalid={!!formValidation?.city?.isInvalid} flex={1}>
            <FormLabel>Cidade</FormLabel>
            <Select name="city" value={form?.city} onChange={handleChange} isDisabled={!form.state}>
              <option value="" hidden>
                Selecione
              </option>

              {cities.map(city => (
                <option key={city.id}>{city.nome}</option>
              ))}
            </Select>
            <FormErrorMessage>{formValidation?.city?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formValidation?.state?.isInvalid} flex={1}>
            <FormLabel>Estado</FormLabel>
            <Select
              name="state"
              value={findState(form?.state, states)}
              onChange={handleStateChange}
            >
              <option value="" hidden>
                Selecione
              </option>

              {states.map(state => (
                <option key={state.id} value={state.sigla}>
                  {state.nome}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formValidation?.state?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </VStack>

      {/* REDES SOCIAIS */}
      {isInstructor && (
        <Accordion mt={8} allowToggle gap={8}>
          <AccordionItem as="section" border="none">
            <AccordionButton px={0} _hover={{ bg: 'transparent' }}>
              <VStack align="start" w="full">
                <HStack spacing={0}>
                  <Text fontSize="xl" fontWeight="medium">
                    Redes Sociais
                  </Text>
                  <AccordionIcon />
                </HStack>
              </VStack>
            </AccordionButton>

            <AccordionPanel p={0}>
              <Text color="#20212380" textAlign="left">
                Essas informações irão aparecer na página de instrutor desse instrutor.
              </Text>

              <Stack direction={{ base: 'column', xl: 'row' }} spacing={{ base: 8, xl: 5 }} mt={8}>
                <FormControl>
                  <FormLabel>Instagram</FormLabel>

                  <Input
                    name="INSTAGRAM"
                    value={form.socialNetwork?.find(({ type }) => type === 'INSTAGRAM')?.url}
                    onChange={handleSocialNetworkChange}
                    placeholder="Digite aqui"
                    mt={2}
                    focusBorderColor="gray.200"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Facebook</FormLabel>

                  <Input
                    name="FACEBOOK"
                    value={form.socialNetwork?.find(({ type }) => type === 'FACEBOOK')?.url}
                    onChange={handleSocialNetworkChange}
                    placeholder="Digite aqui"
                    mt={2}
                    focusBorderColor="gray.200"
                  />
                </FormControl>
              </Stack>

              <Stack direction={{ base: 'column', xl: 'row' }} spacing={{ base: 8, xl: 5 }} mt={8}>
                <FormControl>
                  <FormLabel>YouTube</FormLabel>

                  <Input
                    name="YOUTUBE"
                    value={form.socialNetwork?.find(({ type }) => type === 'YOUTUBE')?.url}
                    onChange={handleSocialNetworkChange}
                    placeholder="Digite aqui"
                    mt={2}
                    focusBorderColor="gray.200"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Linkedin</FormLabel>

                  <Input
                    name="LINKEDIN"
                    value={form.socialNetwork?.find(({ type }) => type === 'LINKEDIN')?.url}
                    onChange={handleSocialNetworkChange}
                    placeholder="Digite aqui"
                    mt={2}
                    focusBorderColor="gray.200"
                  />
                </FormControl>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}

      {/* RODAPÉ */}
      <HStack as="footer" justify="end" my={10} spacing={3}>
        <Button
          onClick={handleCancel}
          bgColor="#e5e5e5"
          border="1px solid #20212340"
          _hover={{ bgColor: '#c2c2c2' }}
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isDisabled={isSubmitting || isUploading}
        >
          Cancelar
        </Button>

        <Button
          colorScheme="primary"
          type="submit"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isLoading={isSubmitting}
          isDisabled={isUploading || !hasChange}
        >
          Salvar alterações
        </Button>
      </HStack>
    </Box>
  );
}
