import { Route, Switch } from 'react-router-dom';

import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import StudentCourseProvider from '../contexts/CourseStudentContext';
import Main from '../pages/DashboardStudent/Main';
import ContentsView from '../pages/DashboardStudent/Products/ContentsView';

export default function MembersAreaClassicRoutes() {
  const history = useHistory();
  const location = useLocation();
  const { membersArea } = useAuth();

  useEffect(() => {
    const pathname = location.pathname;

    if (membersArea === 'netflix' && pathname.includes('v2')) return;

    if (membersArea === 'netflix' && pathname.includes('v1')) {
      const newPath = pathname.replace('v1', 'v2');
      history.push(newPath);
    }
  }, [history, location.pathname, membersArea]);

  return (
    <StudentCourseProvider>
      <Switch>
        <Route exact path="/members-area/v1/products/:id/*" component={ContentsView} />
        <Route exact path="/members-area/v1/*" component={Main} />
      </Switch>
    </StudentCourseProvider>
  );
}
