import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';

export function ModalDeleteVideo({ isOpen, onClose, isLoading, deleteVideo, videoId }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={!isLoading}
      size={{ base: 'xs', md: 'md', lg: 'lg' }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={700} fontSize={{ base: 'md', md: 'md', lg: 'lg' }}>
          Confirmação de exclusão
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody fontWeight={500}>Tem certeza que deseja excluir?</ModalBody>

        <ModalFooter>
          <Stack
            direction="row"
            width="full"
            justifyContent={{ base: 'center', md: 'flex-end', lg: 'flex-end' }}
            alignItems="center"
          >
            <Button size="sm" onClick={onClose} isDisabled={isLoading}>
              Cancelar
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              onClick={() => deleteVideo(videoId)}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Excluir
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
