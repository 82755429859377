import { Avatar, Box, Center, Circle, Flex, Icon, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { MdLockOutline as LockIcon } from 'react-icons/md';
import { Module } from '../../../types';

interface ModuleCardProps {
  module: Module;
  isAvailable?: boolean;
}

export default function ModuleCard({ module, isAvailable }: ModuleCardProps) {
  return (
    <Flex
      flex={1}
      h="full"
      direction="column"
      borderRadius={10}
      overflow="hidden"
      boxShadow="0px 4px 4px 0px #00000040"
      width={{ base: '8.4375rem', lg: '16.875rem' }}
    >
      <Box width="100%" position="relative" height={{ base: '10.546875rem', lg: '21.09375rem' }}>
        <Avatar
          name={module.name}
          boxSize="full"
          borderRadius={0}
          src={module.thumbnail}
          bg="primary.500"
          color="secondary.500"
          sx={{ '.chakra-avatar__initials': { fontSize: '7xl', fontWeight: 900 } }}
        />

        {!isAvailable && (
          <>
            <Center
              position="absolute"
              backgroundColor="#202123BF"
              filter="grayscale(100%)"
              inset={0}
            >
              <Flex direction="column" align="center">
                <Circle size={{ base: '2rem', lg: '4rem' }} bg="#20212380" color="white">
                  <Icon as={LockIcon} boxSize={{ base: 4, lg: 8 }} />
                </Circle>

                <Text
                  fontSize={{ base: 'xs', lg: 'sm' }}
                  color="white"
                  maxWidth="8.5rem"
                  textAlign="center"
                >
                  Liberação: {dayjs(module.releaseDateModule).utc().format('DD/MM/YYYY')}
                </Text>
              </Flex>
            </Center>
          </>
        )}
      </Box>

      <Box px={5} py={{ base: 2.5, lg: 5 }} bg="#37393D">
        <Text align="center" fontSize={{ base: '0.5rem', lg: 'md' }} color="#ffffff" isTruncated>
          {module.name}
        </Text>
      </Box>
    </Flex>
  );
}
