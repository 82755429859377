import {
  Heading,
  HStack,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetRemoteBanks } from '../useGetRemoteBanks';
import SelectAccountTabPanel from './SelectAccountTabPanel';
import BankAccountForm from '../components/BankAccountForm';

export default function BankDetailsConfig() {
  const [tabIndex, setTabIndex] = useState(0);

  const { banks: bankList } = useGetRemoteBanks();

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const isMobile = useBreakpointValue({ base: true, xl: false });

  // Faz o scroll do tab selecionado ficar no centro da tela no mobile
  useEffect(() => {
    if (isMobile) {
      const activeTabButton = document.querySelector(`[aria-selected="true"]`);
      activeTabButton?.scrollIntoView({ block: 'center', inline: 'center' });
    }
  }, [tabIndex, isMobile]);

  return (
    <>
      <HStack spacing={{ base: 0.25, xl: 0.5 }} ml={{ base: -2, xl: 0 }}>
        <IconButton
          as={Link}
          to={'/profile/bank-accounts'}
          icon={<ChevronLeftIcon boxSize="1.75rem" />}
          aria-label="Voltar"
          variant="ghost"
          colorScheme="gray"
          size="sm"
        />

        <Heading as="h1" fontSize={{ base: 'lg', lg: '2xl' }} fontWeight="semibold" m={0}>
          Configurações de conta bancária
        </Heading>
      </HStack>

      <Tabs
        isLazy
        overflowX="auto"
        index={tabIndex}
        onChange={handleTabsChange}
        sx={{ '::-webkit-scrollbar': { display: 'none' } }}
        marginTop={{ base: 8, xl: 10 }}
      >
        <TabList
          overflowY="hidden"
          color="#20212380"
          borderBottom="0.5px solid"
          borderColor="#0000001A"
          scrollSnapType="x mandatory"
          overflowX="auto"
          sx={{
            '.chakra-tabs__tab': {
              fontSize: 'lg',
              pt: 0,
              pb: 1,
              flexShrink: 0,
              outline: 'none',
              boxShadow: 'none',
              color: '#20212380',
              px: { base: 3, md: 4 },
              _selected: {
                color: 'primary.500',
                borderBottomColor: 'primary.500',
                borderBottomWidth: '5px',
              },
              '::-webkit-scrollbar': { display: 'none' },
            },
          }}
        >
          <Tab>Selecione uma conta</Tab>
          <Tab>Adicionar conta</Tab>
        </TabList>

        <TabPanels sx={{ '.chakra-tabs__tab-panel': { px: 0, py: { base: 6, xl: 10 } } }}>
          <SelectAccountTabPanel bankList={bankList} />

          <TabPanel>
            <BankAccountForm bankList={bankList} hasBankAccountRegistered={true} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
