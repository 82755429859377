import { axiosBackend } from './Configuration';

export default {
  getModules: async course_id => {
    try {
      const { data: moduleData } = await axiosBackend().get(`/modules/${course_id}/course`);

      return moduleData;
    } catch (error) {
      throw error;
    }
  },

  store: async (courseId, module) => {
    try {
      const { data: newModule } = await axiosBackend().post(`/modules/${courseId}/courseId`, {
        ...module,
      });

      return newModule;
    } catch (error) {
      throw error;
    }
  },

  update: async (courseId, moduleId, module) => {
    try {
      const { data: moduleUpdated } = await axiosBackend().put(
        `/modules/${courseId}/courseId/${moduleId}/moduleId`,
        module
      );
      return moduleUpdated;
    } catch (error) {
      throw error;
    }
  },

  updateModulesOrder: async (courseId, modulesOrders) => {
    try {
      await axiosBackend().patch(`/modules/${courseId}/course/order-modules`, { modulesOrders });
    } catch (error) {
      throw error;
    }
  },

  delete: async (courseId, moduleId) => {
    try {
      await axiosBackend().delete(`/modules/${courseId}/courseId/${moduleId}/moduleId`);
    } catch (error) {
      throw error;
    }
  },

  copy: async (courseId, payload) => {
    try {
      const { data: copyModuleData } = await axiosBackend().post(
        `/contents/${courseId}/course/copy-module`,
        payload
      );

      return copyModuleData;
    } catch (error) {
      throw error;
    }
  },
};
