import { Alert, Box, Button as ChakraButton, Flex, Stack, Text } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  default as Authentication,
  default as AuthenticationAPI,
} from '../../../../api/Authentication';
import Button from '../../../../components/Button';
import { Heading } from '../../../../components/Heading';
import Input from '../../../../components/Input';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { TOKEN_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import { ISignProps } from '../../types';
import { signInSchema } from './signInSchema';

interface IForm {
  email: string;
  password: string;
}

const initialFormValues: IForm = {
  email: '',
  password: '',
};

function SignIn({ productId, verifyAuthentication, courseSubscriptionPlan }: ISignProps) {
  const [form, setForm] = useState<IForm>(initialFormValues);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [redirect, setRedirect] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
      const authentication = await AuthenticationAPI.validateToken(token);

      if (authentication) {
        history.push(
          `/product/${productId}/subscription-checkout/personal-data${
            courseSubscriptionPlan ? `?courseSubscriptionPlan=${courseSubscriptionPlan}` : ''
          }`
        );
      }
    })();
  }, [courseSubscriptionPlan, history, productId]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setForm({
      ...form,
      [name]: value,
    });
  }

  async function handleSignIn(event) {
    event.preventDefault();

    try {
      setIsLoading(true);
      setErrorMessage('');

      const validateValues = signInSchema.validate(form);

      if (validateValues.error) throw validateValues.error;

      await Authentication.login(form.email, form.password);
      verifyAuthentication();
      history.push(
        `/product/${productId}/subscription-checkout/personal-data${
          courseSubscriptionPlan ? `?courseSubscriptionPlan=${courseSubscriptionPlan}` : ''
        }`
      );
    } catch (error) {
      const errorResponse = error?.response?.data;

      if (errorResponse) {
        setRedirect(errorResponse?.data?.link);
        setErrorMessage(errorResponse?.message);
      } else {
        setErrorMessage(ErrorResponse(error));
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box as="section" display="flex" flexDirection="column" alignItems="center">
      <Heading as="h2" fontSize="lg" marginBottom="4">
        Login
      </Heading>

      <Stack width="full" sx={{ 'div + div': { marginTop: '1.75rem' } }}>
        <Input
          id="email"
          type="email"
          name="email"
          label="E-mail"
          placeholder="Digite seu e-mail"
          onChange={handleChange}
          isRequired
        />
        <Input
          id="password"
          type="password"
          name="password"
          label="Senha"
          placeholder="Digite sua senha"
          onChange={handleChange}
          autoComplete="current-password"
          isRequired
        />
      </Stack>

      <Flex
        marginTop="0.5rem"
        marginX="0"
        marginBottom={{ base: '8', lg: '4' }}
        w="full"
        justifyContent="flex-end"
      >
        <ChakraButton
          fontSize="sm"
          fontWeight="medium"
          fontFamily="Montserrat"
          colorScheme="dark"
          variant="link"
          textDecoration="underline"
          onClick={() => history.push('/')}
        >
          Esqueceu a senha?
        </ChakraButton>
      </Flex>

      <Stack display="flex" flexDirection="column" justifyItems="center">
        <Button
          type="submit"
          colorScheme="orange"
          onClick={handleSignIn}
          isDisabled={isLoading}
          isLoading={isLoading}
          style={{ outline: 'none', boxShadow: 'none' }}
        >
          Entrar
        </Button>

        <Box marginTop="4" textAlign="center">
          <Text fontSize="sm" fontWeight="medium" color="dark.500">
            Não possui cadastro?
          </Text>

          <ChakraButton
            style={{ outline: 'none', boxShadow: 'none' }}
            fontSize="sm"
            fontWeight="medium"
            fontFamily="Montserrat"
            colorScheme="orange"
            variant="link"
            textDecoration="underline"
            onClick={() =>
              history.push(
                `/product/${productId}/subscription-checkout/sign-up${
                  courseSubscriptionPlan ? `?courseSubscriptionPlan=${courseSubscriptionPlan}` : ''
                }`
              )
            }
          >
            Criar uma conta
          </ChakraButton>
        </Box>
      </Stack>

      {errorMessage && (
        <Alert
          status="error"
          variant="subtle"
          maxWidth="100%"
          marginTop="6"
          borderRadius="sm"
          fontWeight="semibold"
          color="blackAlpha.500"
          wordBreak="break-word"
        >
          {errorMessage}
          {redirect && (
            <Text
              fontSize="sm"
              marginLeft="0.25rem"
              sx={{
                a: { textDecoration: 'underline' },
              }}
            >
              <a href={`${redirect}&from=product&productId=${productId}`}> Clique aqui</a> para
              atualizar sua senha.
            </Text>
          )}
        </Alert>
      )}
    </Box>
  );
}

export default SignIn;
