import {
  Box,
  Button,
  Heading,
  List,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { ListItemFileType, MAX_LENGTH_VIDEO_FILE, VideoLibraryItem } from '..';
import Toast from '../../../../../components/Toast';
import ListItemFile from './ListItemFile';
import UploadDropZone from './UploadDropZone';

interface Params {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  getLinkLoading: boolean;
  setGetLinkLoading: (value: boolean) => void;
  dispatch: (value: any) => void;
  listItemFiles: ListItemFileType[];
  videos?: VideoLibraryItem[];
  onSubmitFiles: () => void;
}

function ModalVideoUpload({
  isOpen,
  onClose,
  isLoading,
  getLinkLoading,
  setGetLinkLoading,
  dispatch,
  listItemFiles,
  onSubmitFiles,
}: Params) {
  useEffect(() => {
    if (isOpen) {
      dispatch({ type: 'clear' });
    }
  }, [dispatch, isOpen]);

  function onDropAccepted(filesAccepted: File[]) {
    setGetLinkLoading(true);

    for (const file of filesAccepted) {
      if (file.size > MAX_LENGTH_VIDEO_FILE) {
        Toast('O tamanho máximo é de 1GB.', 'error');
        setGetLinkLoading(false);
      } else {
        dispatch({
          listItemFile: {
            identifier: nanoid(),
            file,
            upload: {
              fail: { isError: false, message: '' },
              inProgress: false,
              isComplete: false,
              progress: 0,
            },
          },
          type: 'add',
        });
      }
    }

    setGetLinkLoading(false);
  }

  async function submitFiles() {
    onSubmitFiles();
    // onClose();
  }

  const { getRootProps, getInputProps, open, isDragReject } = useDropzone({
    noClick: !getLinkLoading,
    accept: ['video/mp4'],
    onDropAccepted,
  });

  function onCloseModal() {
    onClose();
    dispatch({ type: 'clear' });
  }

  return (
    <Modal
      onClose={onCloseModal}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      isCentered
      size={{ base: 'xs', md: 'lg', lg: 'lg' }}
    >
      <ModalOverlay />
      <ModalContent pt="5" px="5">
        <Stack>
          <Heading fontSize="18px" fontWeight={700}>
            Adicionar vídeos
          </Heading>
          <Box hidden={listItemFiles.length > 0}>
            <UploadDropZone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              open={open}
              isDragReject={isDragReject}
              isLoading={getLinkLoading}
              hasItem={listItemFiles.length > 0}
            />
          </Box>

          <List
            spacing={3}
            maxHeight="272px"
            overflowY="auto"
            overflowX="hidden"
            padding={5}
            backgroundColor="#20212310"
            hidden={!listItemFiles.length}
          >
            {listItemFiles.map(listItemFile => (
              <ListItemFile
                key={listItemFile.identifier}
                listItemFile={listItemFile}
                dispatch={dispatch}
              />
            ))}
          </List>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          marginY={2}
          hidden={!listItemFiles.length}
        >
          <Text color="#20212380">
            {listItemFiles.length} {listItemFiles.length === 1 ? 'arquivo' : 'arquivos'}
          </Text>
          <Text color="primary.500" textDecoration="underline" cursor="pointer" onClick={open}>
            Carregar mais arquivos
          </Text>
        </Stack>

        <ModalFooter display="flex" padding={0} marginY={5} justifyContent="end">
          <Stack direction="row" spacing={2}>
            <Button size="sm" width={112} isDisabled={isLoading} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              size="sm"
              width={112}
              colorScheme="primary"
              color="secondary.500"
              hidden={!listItemFiles.length}
              isDisabled={isLoading}
              onClick={() => submitFiles()}
            >
              Enviar
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalVideoUpload;
