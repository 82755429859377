import { Box, HStack, SkeletonCircle, Skeleton as ChakraSkeleton, Stack } from '@chakra-ui/react';

function Skeleton() {
  return (
    <Box
      marginTop={{ base: 2, lg: 4 }}
      width="100%"
      display="grid"
      gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
      gap="4"
    >
      {Array.from(Array(4).keys()).map(n => (
        <HStack
          key={n}
          position="relative"
          paddingY="4"
          paddingX="3.5"
          display="flex"
          background="white"
          border="1px solid rgba(32, 33, 35, 0.05)"
          filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
          borderRadius="base"
          gap="3"
          zIndex={0}
        >
          <SkeletonCircle size="8" flexShrink={0} />
          <Stack width="100%" spacing="4">
            <ChakraSkeleton width="5rem" height="0.8rem" />
            <ChakraSkeleton width="10rem" height="0.8rem" />
          </Stack>
        </HStack>
      ))}
    </Box>
  );
}

export default Skeleton;
