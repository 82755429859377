import { cnpj, cpf } from 'cpf-cnpj-validator';
import Joi from 'joi';

export type RegisterSchemaType = {
  fullName: string;
  document: { type: string; number: string };
  email: string;
  confirmEmail: string;
  ddi?: string;
  ddd?: string;
  phone?: string;
  instagram?: string;
  country: string;
  address?: {
    zipCode?: string;
    streetAddress?: string;
    streetNumber?: string;
    neighborhood?: string;
    state?: string;
    city?: string;
    country?: string;
    complementary?: string;
    alternateAddress?: string;
  };
};

function validateCPF(value: string, helpers) {
  if (!cpf.isValid(value)) {
    return helpers.message('CPF inválido');
  }

  return value;
}

function validateCNPJ(value: string, helpers) {
  if (!cnpj.isValid(value)) {
    return helpers.error('any.invalid');
  }

  return value;
}

const schema = Joi.object<RegisterSchemaType>().keys({
  fullName: Joi.string()
    .min(3)
    .max(50)
    .required()
    .messages({
      'string.min': 'Seu nome deve ter no mínimo 3 caracteres.',
      'string.max': 'Seu nome deve ter no máximo 50 caracteres.',
      'string.empty': 'Seu nome não pode ficar vazio.',
    } as JoiMessages.LanguageMessages),
  document: Joi.object().keys({
    type: Joi.string().required(),
    number: Joi.when(Joi.ref('...country'), {
      is: 'br',
      then: Joi.when(Joi.ref('type'), {
        is: 'cpf',
        then: Joi.string().custom(validateCPF, 'validateCPF').min(11).max(11).messages({
          'string.empty': 'Digite o CPF ou CNPJ para continuar',
          'any.invalid': 'CPF inválido',
          'string.min': 'CPF deve ter no mínimo 11 caracteres.',
          'string.max': 'CPF deve ter no máximo 11 caracteres.',
        }),
        otherwise: Joi.string().custom(validateCNPJ, 'validateCNPJ').min(14).max(14).messages({
          'string.empty': 'Digite o CPF ou CNPJ para continuar',
          'any.invalid': 'CNPJ inválido',
          'string.min': 'CNPJ deve ter no mínimo 14 caracteres.',
          'string.max': 'CNPJ deve ter no máximo 14 caracteres.',
        }),
      }),
      otherwise: Joi.string().max(14).required().messages({
        'string.empty': 'Digite o documento para continuar',
        'string.max': 'Documento deve ter no máximo 14 caracteres.',
      }),
    }),
  }),
  email: Joi.string()
    .email({ tlds: false })
    .min(3)
    .max(66)
    .required()
    .messages({
      'string.empty': 'Seu e-mail não pode ficar vazio.',
      'string.email': 'Seu e-mail deve ser nesse formato “nome@email.com”.',
      'string.max': 'O e-mail deve ter no máximo 66 caracteres.',
      'string.min': 'O e-mail deve ter no mínimo 3 caracteres.',
    } as JoiMessages.LanguageMessages),
  confirmEmail: Joi.string()
    .email({ tlds: false })
    .min(3)
    .max(66)
    .required()
    .valid(Joi.ref('email')) // Validação para confirmEmail seja igual a email
    .messages({
      'string.empty': 'Sua confirmação de e-mail não pode ficar vazio.',
      'string.email': 'Sua confirmação de e-mail deve ser igual ao formato “nome@email.com”.',
      'string.max': 'Sua confirmação de e-mail deve ter no máximo 66 caracteres.',
      'string.min': 'O e-mail deve ter no mínimo 3 caracteres.',
      'any.only': 'A confirmação de e-mail deve ser igual ao e-mail.',
    } as JoiMessages.LanguageMessages),
  ddi: Joi.string()
    .min(1)
    .max(5)
    .messages({
      'string.empty': 'Seu ddi não pode ficar vazio.',
      'string.min': 'Seu ddi deve ter no mínimo 1 dígitos.',
      'string.max': 'Seu ddi deve ter no máximo 5 dígitos.',
    } as JoiMessages.LanguageMessages),
  ddd: Joi.string()
    .min(1)
    .max(5)
    .messages({
      'string.empty': 'DDD não pode ficar vazio.',
      'string.min': 'DDD deve ter no mínimo 1 dígitos.',
      'string.max': 'DDD deve ter no máximo 3 dígitos.',
    } as JoiMessages.LanguageMessages),
  phone: Joi.string()
    .min(8)
    .max(20)
    .messages({
      'string.empty': 'Seu telefone não pode ficar vazio.',
      'string.min': 'Seu telefone deve ter no mínimo 11 dígitos.',
      'string.max': 'Seu telefone deve ter no máximo 20 dígitos.',
    } as JoiMessages.LanguageMessages),
  instagram: Joi.string()
    .min(3)
    .max(30)
    .messages({
      'string.empty': 'Seu instagram não pode ficar vazio.',
      'string.min': 'Seu instagram deve ter no mínimo 3 caracteres.',
      'string.max': 'O instagram deve ter no máximo 30 caracteres.',
    } as JoiMessages.LanguageMessages),
  country: Joi.string()
    .min(2)
    .max(5)
    .messages({
      'string.empty': 'Seu país não pode ficar vazio.',
      'string.min': 'Seu país deve ter no mínimo 3 caracteres.',
      'string.max': 'O país deve ter no máximo 5 caracteres.',
    } as JoiMessages.LanguageMessages),
  address: Joi.object().keys({
    country: Joi.string().required().length(2).messages({
      'string.empty': 'Selecione o país para continuar',
      'string.length': 'Selecione o país para continuar',
    }),
    zipCode: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().length(8).messages({
        'string.empty': 'Digite o CEP para continuar',
        'string.length': 'O CEP deve ter 8 números',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    streetAddress: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Digite o endereço para continuar',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    streetNumber: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Digite o Nº para continuar',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    neighborhood: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Digite o bairro para continuar',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    state: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Selecione o estado para continuar',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    city: Joi.when('country', {
      is: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Digite a cidade para continuar',
      }),
      otherwise: Joi.string().allow(null, ''),
    }),
    complementary: Joi.string().allow(null, ''),
    alternateAddress: Joi.when('country', {
      not: 'br',
      then: Joi.string().required().messages({
        'string.empty': 'Digite o endereço para continuar',
      }),
      otherwise: Joi.string().allow(null, '').optional(),
    }),
  }),
});

export default schema;
