import { createContext, useContext, useEffect, useState } from 'react';

import CourseAPI from '../api/Course';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

const CourseContext = createContext();

export default function CourseProvide({ children }) {
  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState({ name: '', modules: [] });
  const [isAddingModule, setIsAddingModule] = useState(false);
  const [coursePrices, setCoursePrices] = useState([]);

  const { id } = useParams();

  const getCourse = useCallback(async () => {
    try {
      const { data: dataCourse } = await CourseAPI.show(id);
      setCourse(dataCourse);

      setCoursePrices(dataCourse.coursePrices);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  function onChangeCourse(value) {
    setCourse(value);
  }

  function onReorderModules(reorderedModules) {
    setCourse(course => ({ ...course, modules: reorderedModules }));
  }

  async function updateCourse(dataCourse) {
    setLoading(true);
    try {
      await CourseAPI.update(id, dataCourse);
      await getCourse();
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function deleteBanner(bannerId) {
    setLoading(true);

    try {
      await CourseAPI.deleteBanner(id, bannerId);
      await getCourse();
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  function getModules(moduleId) {
    if (!moduleId) return course.modules;

    return course.modules.find(module => module.id === Number(moduleId));
  }

  function getContent(moduleId, contentId) {
    if (!moduleId || !contentId) return course.modules.contents;

    const currentModule = getModules(moduleId);

    const integerContentId = parseInt(contentId);

    if (currentModule?.contents) {
      return currentModule.contents.find(content => content.id === integerContentId);
    }
  }

  return (
    <CourseContext.Provider
      value={{
        course,
        loading,
        isAddingModule,
        setCourse,
        updateCourse,
        deleteBanner,
        getCourse,
        getModules,
        getContent,
        setLoading,
        setIsAddingModule,
        onChangeCourse,
        onReorderModules,
        coursePrices,
        setCoursePrices,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
}

export function useCourse() {
  const context = useContext(CourseContext);

  if (!context) throw new Error('useCourse deve ser usado dentro de um CourseProvider.');

  return {
    course: context.course,
    setCourse: context.setCourse,
    updateCourse: context.updateCourse,
    deleteBanner: context.deleteBanner,
    getCourse: context.getCourse,
    getModules: context.getModules,
    getContent: context.getContent,
    loading: context.loading,
    setLoading: context.setLoading,
    isAddingModule: context.isAddingModule,
    setIsAddingModule: context.setIsAddingModule,
    onChangeCourse: context.onChangeCourse,
    onReorderModules: context.onReorderModules,
    coursePrices: context.coursePrices,
    setCoursePrices: context.setCoursePrices,
  };
}
