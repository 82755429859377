import { IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import useFilters from '../Filters/useFilters';
import FilterType from '../../types/Filter';

interface SearchInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilter: (filters: Partial<FilterType>[]) => void;
}

export default function SearchInput({ onChange, onFilter }: SearchInputProps) {
  const { filters } = useFilters();

  function handleFilter() {
    onFilter(filters);
  }

  function handleSearchInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      handleFilter();
    }
  }

  return (
    <InputGroup>
      <Input
        onChange={onChange}
        onKeyDown={handleSearchInputKeyDown}
        placeholder="Busque pelo título da questão"
        border="1px solid #20212326"
        bg="white"
        color="blackAlpha.500"
        focusBorderColor="default.500"
        _placeholder={{ color: 'blackAlpha.500' }}
      />

      <InputRightElement
        overflow="hidden"
        fontSize="2xl"
        children={
          <IconButton
            onClick={handleFilter}
            variant="ghost"
            _hover={{ bg: 'gray.50' }}
            aria-label="Buscar"
            icon={<MdSearch color="#A0AEC0" />}
          />
        }
      />
    </InputGroup>
  );
}
