import { Select as SelectMulti } from 'chakra-react-select';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Link, useHistory, useParams } from 'react-router-dom';
import ColorPicker, { IColorPickerChange } from '../../../../components/ColorPickChakra';
import convertToBrazilianCurrency from '../../../../helpers/convertToBrazilianCurrency';
import useFetch from '../../../../hooks/useFetch';
import useHandleChange, { TargetType } from '../../../../hooks/useHandleChange';
import useWindowSize from '../../../../hooks/useWindowSize';
import { JoiSchemasType } from '../../../../validation/EntitySchema';
import CardPreview from './CardPreview';

import useHandleSubmit, {
  UseHandleSubmitPropsType,
  Validation,
} from '../../../../hooks/useHandleSubmit';

import {
  OrderBumpSchemaType,
  createOrderBumpSchemaValidator,
  updateOrderBumpSchemaValidator,
} from './orderBumpSchemaValidator';

import {
  Box,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  Spinner,
  Stack,
  Switch,
  Text,
  Textarea,
  Flex,
} from '@chakra-ui/react';

const MEDIUM_SCREEN_SIZE = 800;

export type OrderBumpType = {
  id: number;
  name: string;
  courseId: number;
  coursePriceId: number;
  productIds: number[];
  buttonText: string;
  buttonColor: string;
  buttonTextColor: string;
  title: string;
  description: string;
  enabled: boolean;
};

export type ProductListType = {
  id: number;
  name: string;
  thumbnail: string;
};

export type ProductPriceType = {
  id: number;
  name: string;
  formOfPayment: string;
  default: boolean;
  installments: string;
  purchasePrice: string;
};

function AddOrEditOrderBump<T extends object>({
  props,
  id,
}: {
  props: Partial<UseHandleSubmitPropsType<T>>;
  id: string;
}) {
  const { width } = useWindowSize();

  const history = useHistory();

  const [options, setOptions] = useState<{ value: number; label: string }[]>([]);

  const { form, setForm, handleChange } = useHandleChange<OrderBumpType>({
    enabled: false,
    name: '',
    courseId: 0,
    coursePriceId: 0,
    productIds: [],
    buttonText: '',
    buttonColor: '#eb7129',
    buttonTextColor: '#ffffff',
    title: '',
    description: '',
  });

  const { isLoading, handleSubmit, formValidation } = useHandleSubmit({
    data: form,
    schemaValidator: props.schemaValidator as JoiSchemasType<OrderBumpSchemaType>,
    url: props.url,
    method: props.method,
    authenticated: true,
    onSuccess: {
      message: props.onSuccess.message,
      callback: () => history.push('/order-bump'),
    },
  });

  const { data: courseListData, loading: courseListLoading } = useFetch<
    UnificadaFront.ResponseJSON<ProductListType[]>
  >({
    method: 'get',
    url: '/courses/list?status=ATIVO_E_NLISTADO_E_INATIVO&typePayableCourse=PAYABLE',
    authenticated: true,
    autoFetch: true,
  });

  const {
    data: coursePriceData,
    loading: coursePriceLoading,
    fetchData: fetchCoursePrice,
  } = useFetch<UnificadaFront.ResponseJSON<ProductPriceType[]>>({
    method: 'get',
    url: `/courses/${form.courseId}/course/course-price?paidOnly=true`,
    authenticated: true,
    autoFetch: false,
  });

  const {
    data: editOrderBumpData,
    loading: editOrderBumpLoading,
    fetchData: editOrderBumpFetchData,
  } = useFetch<UnificadaFront.ResponseJSON<OrderBumpSchemaType>>({
    method: 'get',
    url: `/order-bumps/${id}`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (form.productIds.length) {
      const chosenCourseIsInTheProductIds = form.productIds.includes(
        parseInt(form.courseId.toString())
      );

      if (chosenCourseIsInTheProductIds) {
        form.productIds = form.productIds.filter(
          productId => productId !== parseInt(form.courseId.toString())
        );
      }
    }
  }, [form]);

  useEffect(() => {
    if (id) {
      editOrderBumpFetchData();
    }
  }, [editOrderBumpFetchData, id]);

  useEffect(() => {
    if (editOrderBumpData?.data) {
      setForm({
        enabled: editOrderBumpData.data.enabled,
        name: editOrderBumpData.data.name,
        courseId: editOrderBumpData.data.courseId,
        productIds: editOrderBumpData.data.productIds,
        coursePriceId: editOrderBumpData.data.coursePriceId,
        buttonText: editOrderBumpData.data.buttonText,
        buttonColor: editOrderBumpData.data.buttonColor,
        buttonTextColor: editOrderBumpData.data.buttonTextColor,
        title: editOrderBumpData.data.title,
        description: editOrderBumpData.data.description,
      });
    }
  }, [editOrderBumpData, setForm]);

  useEffect(() => {
    if (form.courseId) {
      fetchCoursePrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCoursePrice, form.courseId, setForm]);

  useEffect(() => {
    const courseListFiltered = courseListData?.data?.filter(
      course =>
        course.id !== parseInt(form.courseId.toString()) && !form.productIds.includes(course.id)
    );

    const options = courseListFiltered?.map(course => ({
      value: course.id,
      label: course.name,
    }));

    setOptions(options);
  }, [courseListData?.data, form.courseId, form.productIds]);

  function handleChangeColor(props: IColorPickerChange) {
    const { name, value } = props;

    setForm({ ...form, [name]: value });
  }

  function cleanChanges() {
    setForm({
      enabled: false,
      name: '',
      courseId: 0,
      coursePriceId: 0,
      productIds: [],
      buttonText: '',
      buttonColor: '#eb7129',
      buttonTextColor: '#ffffff',
      title: '',
      description: '',
    });
  }

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <>
      <Stack
        as={Link}
        to="/order-bump"
        direction="row"
        spacing={1}
        alignItems="center"
        marginBottom={10}
      >
        <IoIosArrowBack size="20px" />
        <Heading fontSize="24px" fontWeight={600}>
          {id ? 'Editar Order Bump' : 'Novo Order Bump'}
        </Heading>
      </Stack>

      <Box width="100%" marginY={5} textAlign="center" hidden={!editOrderBumpLoading}>
        <Spinner colorScheme="primary" size="lg" />
      </Box>

      <Stack
        direction="column"
        spacing={7}
        as="form"
        onSubmit={onSubmit}
        hidden={editOrderBumpLoading}
      >
        <FormControl>
          <FormLabel fontWeight={500} fontSize="20px">
            Status do Order Bump
          </FormLabel>
          <Switch
            onChange={handleChange}
            name="enabled"
            isChecked={form.enabled}
            size="sm"
            colorScheme="primary"
            display="flex"
            alignItems="center"
          >
            {form.enabled ? 'Ativado' : 'Desativado'}
          </Switch>
        </FormControl>

        <Stack direction="column" spacing={5}>
          <Text fontWeight={500} fontSize="20px">
            Configurações
          </Text>

          <FormControl isInvalid={formValidation?.name.isInvalid}>
            <FormLabel fontWeight={500} fontSize="14px">
              Nome
            </FormLabel>
            <FormHelperText fontWeight={500} fontSize="14px" color="#20212380" marginY={2}>
              Defina um nome para o seu Order Bump.
            </FormHelperText>
            <Input
              name="name"
              onChange={handleChange}
              value={form.name}
              maxLength={30}
              focusBorderColor="primary.500"
              placeholder="Digite aqui"
            />
            <FormErrorMessage>{formValidation?.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.courseId.isInvalid}>
            <FormLabel fontWeight={500} fontSize="14px">
              Produto
            </FormLabel>
            <FormHelperText fontWeight={500} fontSize="14px" color="#20212380" marginY={2}>
              Selecione o produto que será ofertado no order bump.
            </FormHelperText>
            <Select
              name="courseId"
              onChange={event => {
                setForm({ ...form, courseId: parseInt(event.target.value), coursePriceId: 0 });
              }}
              value={form.courseId}
              focusBorderColor="primary.500"
              isDisabled={courseListLoading}
            >
              <option hidden value="">
                Selecione
              </option>
              {courseListData?.data?.map(course => (
                <option key={course.id} value={course.id}>
                  {course.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formValidation?.courseId?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.coursePriceId.isInvalid}>
            <FormLabel fontWeight={500} fontSize="14px">
              Qual será o preço?
            </FormLabel>
            <FormHelperText fontWeight={500} fontSize="14px" color="#20212380" marginY={2}>
              Selecione um dos preços cadastrados para esse produto. Caso queira cadastrar um novo
              preço ou oferta clique aqui.
            </FormHelperText>
            <Select
              name="coursePriceId"
              onChange={handleChange}
              value={form.coursePriceId}
              focusBorderColor="primary.500"
              isDisabled={!coursePriceData || coursePriceLoading || !form.courseId}
            >
              <option hidden value="">
                Selecione
              </option>
              {coursePriceData?.data?.map(coursePrice => (
                <option key={coursePrice.id} value={coursePrice.id}>
                  {coursePrice.name} -{' '}
                  {coursePrice.formOfPayment === 'FREE'
                    ? 'Grátis'
                    : convertToBrazilianCurrency(coursePrice.purchasePrice)}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{formValidation?.coursePriceId?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={(formValidation?.productIds as unknown as Validation)?.isInvalid}>
            <FormLabel fontWeight={500} fontSize="14px">
              Onde seu Order Bump irá aparecer?
            </FormLabel>
            <FormHelperText fontWeight={500} fontSize="14px" color="#20212380" marginY={2}>
              Você pode selecionar um ou mais produtos. Seu Order Bump irá aparecer no checkout dos
              produtos selecionados.
            </FormHelperText>
            <SelectMulti
              menuPlacement="top"
              placeholder="Selecione"
              isMulti={true}
              focusBorderColor="primary.500"
              hasStickyGroupHeaders
              closeMenuOnSelect={true}
              selectedOptionColor="primary"
              noOptionsMessage={() => 'Nenhum produto encontrado.'}
              options={options}
              value={[]}
              isLoading={isLoading}
              menuPortalTarget={document.body}
              isInvalid={
                !form.productIds.length &&
                (formValidation?.productIds as unknown as Validation)?.isInvalid
              }
              onChange={newValues => {
                const newCourse = newValues.map(newValue => newValue.value);

                const chosenCourses = form.productIds;

                const newArrayValue = [...chosenCourses, ...newCourse];

                const event = {
                  target: {
                    name: 'productIds',
                    value: newArrayValue,
                  },
                };
                handleChange(event as unknown as ChangeEvent<TargetType>);
              }}
              chakraStyles={{
                container: provided => ({
                  ...provided,
                  width: '100%',
                  color: '#20212380',
                  fontSize: 'sm',
                }),
                placeholder: provided => ({
                  ...provided,
                  color: '#20212380',
                  fontSize: 'sm',
                }),
                dropdownIndicator: provided => ({
                  ...provided,
                  bg: 'transparent',
                  color: '#202123',
                  px: 2.5,
                }),
                indicatorSeparator: provided => ({
                  ...provided,
                  display: 'none',
                }),
                option: provided => ({
                  ...provided,
                  color: '#20212380',
                  fontSize: 'sm',
                }),
                valueContainer: provided => ({
                  ...provided,
                  px: 3,
                  color: '#20212380',
                  fontSize: 'sm',
                }),
              }}
            />
            <FormErrorMessage>
              {(formValidation?.productIds as unknown as Validation)?.message}
            </FormErrorMessage>

            <Stack direction="row" alignItems="center" flexWrap="wrap" flex={1} marginY={2}>
              {form.productIds.length &&
                form.productIds.map(productId => (
                  <Flex
                    key={productId}
                    justify="center"
                    align="center"
                    paddingX={2}
                    paddingY={0.5}
                    gap={1}
                    maxWidth="13rem"
                    backgroundColor="#20212325"
                    borderRadius={6}
                  >
                    <Text fontWeight="medium" fontSize="xs" color="#202123" isTruncated>
                      {courseListData?.data?.find(course => course.id === productId)?.name}
                    </Text>

                    <CloseButton
                      onClick={() => {
                        const productIds = form.productIds?.filter(course => course !== productId);
                        setForm({ ...form, productIds });
                      }}
                      size="sm"
                      sx={{ svg: { boxSize: '6.36px' } }}
                      boxSize={4}
                    />
                  </Flex>
                ))}
            </Stack>
          </FormControl>
        </Stack>

        <Stack direction={width < MEDIUM_SCREEN_SIZE ? 'column' : 'row'} spacing={7}>
          <Stack width="100%" direction="column" spacing={5}>
            <Text fontWeight={500} fontSize="20px">
              Personalização
            </Text>

            <FormControl isInvalid={formValidation?.buttonText.isInvalid}>
              <FormLabel fontWeight={500} fontSize="14px">
                Texto do botão
              </FormLabel>
              <FormHelperText fontWeight={500} fontSize="14px" textAlign="end" color="#20212380">
                Caracteres: {form.buttonText.length}/30
              </FormHelperText>
              <Input
                name="buttonText"
                maxLength={30}
                onChange={handleChange}
                value={form.buttonText}
                focusBorderColor="primary.500"
                placeholder="Digite aqui"
              />
              <FormErrorMessage>{formValidation?.buttonText?.message}</FormErrorMessage>
            </FormControl>

            <Stack direction={'row'} spacing={5} justifyContent="space-between">
              <FormControl isInvalid={formValidation?.buttonColor.isInvalid}>
                <FormLabel fontWeight={500} fontSize="14px" isTruncated>
                  Cor do botão
                </FormLabel>
                <ColorPicker
                  name="buttonColor"
                  onChange={handleChangeColor}
                  value={form.buttonColor}
                  hasInput
                  buttonSize="2.5rem"
                  inputSize="100%"
                />
                <FormErrorMessage>{formValidation?.buttonColor?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formValidation?.buttonTextColor.isInvalid}>
                <FormLabel fontWeight={500} fontSize="14px" isTruncated>
                  Cor do texto do botão
                </FormLabel>
                <ColorPicker
                  name="buttonTextColor"
                  onChange={handleChangeColor}
                  value={form.buttonTextColor}
                  hasInput
                  buttonSize="2.5rem"
                  inputSize="100%"
                />
                <FormErrorMessage>{formValidation?.buttonTextColor?.message}</FormErrorMessage>
              </FormControl>
            </Stack>

            <FormControl isInvalid={formValidation?.title.isInvalid}>
              <FormLabel fontWeight={500} fontSize="14px">
                Título de destaque
              </FormLabel>
              <FormHelperText fontWeight={500} fontSize="14px" textAlign="end" color="#20212380">
                Caracteres: {form.title.length}/50
              </FormHelperText>
              <Textarea
                name="title"
                maxLength={50}
                onChange={handleChange}
                value={form.title}
                focusBorderColor="primary.500"
                placeholder="Digite aqui"
              />
              <FormErrorMessage>{formValidation?.title?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.description.isInvalid}>
              <FormLabel fontWeight={500} fontSize="14px">
                Texto auxiliar
              </FormLabel>
              <FormHelperText fontWeight={500} fontSize="14px" textAlign="end" color="#20212380">
                Caracteres: {form.description.length}/150
              </FormHelperText>
              <Textarea
                name="description"
                maxLength={150}
                onChange={handleChange}
                value={form.description}
                focusBorderColor="primary.500"
                placeholder="Digite aqui"
              />
              <FormErrorMessage>{formValidation?.description?.message}</FormErrorMessage>
            </FormControl>
          </Stack>

          <Stack width={width > MEDIUM_SCREEN_SIZE ? '80%' : '100%'} direction="column" spacing={2}>
            <Text fontWeight={500} fontSize="20px">
              Preview
            </Text>

            <CardPreview
              form={form as OrderBumpType}
              courseId={form?.courseId}
              coursePriceId={form?.coursePriceId}
              courseListData={courseListData?.data}
              coursePriceData={coursePriceData?.data}
            />
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent={width < MEDIUM_SCREEN_SIZE ? 'center' : 'flex-end'}>
          <Button
            colorScheme="gray"
            variant="solid"
            width={width < MEDIUM_SCREEN_SIZE ? '100%' : 'auto'}
            onClick={cleanChanges}
          >
            Cancelar
          </Button>
          <Button
            isLoading={isLoading}
            isDisabled={isLoading}
            colorScheme="primary"
            color="secondary.500"
            width={width < MEDIUM_SCREEN_SIZE ? '100%' : 'auto'}
            type="submit"
          >
            Salvar
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

export default function OrderBumpManager() {
  const { id } = useParams<{ id: string }>();

  if (id) {
    return (
      <AddOrEditOrderBump
        id={id}
        props={{
          schemaValidator: updateOrderBumpSchemaValidator,
          url: `/order-bumps/${id}`,
          method: 'patch',
          onSuccess: {
            message: 'Order Bump atualizado com sucesso!',
          },
        }}
      />
    );
  }

  return (
    <AddOrEditOrderBump
      id={id}
      props={{
        schemaValidator: createOrderBumpSchemaValidator,
        url: '/order-bumps',
        method: 'post',
        onSuccess: {
          message: 'Order Bump criado com sucesso!',
        },
      }}
    />
  );
}
