import { Box, Button } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

function TemplateImageButton({
  preview,
  onDrop,
  isDisabled,
}: {
  preview: string;
  isDisabled?: boolean;
  onDrop: () => void;
}) {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'],
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  function handleClick(event) {
    event.preventDefault();
    if (isDisabled) return;
    open();
  }

  return (
    <Box {...getRootProps()} w={{ base: 'full', md: 'auto', lg: 'auto' }}>
      <input {...getInputProps()} />
      <Button
        size="sm"
        colorScheme="primary"
        fontSize="xs"
        lineHeight="4"
        onClick={handleClick}
        isDisabled={isDisabled}
      >
        Adicionar template
      </Button>
    </Box>
  );
}

export default TemplateImageButton;
