import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { memo } from 'react';
import { HiDotsVertical as DotsVertical } from 'react-icons/hi';
import CouponAPI from '../../../../api/Coupon';
import Toast from '../../../../components/Toast';
import convert from '../../../../helpers/convertToBrazilianCurrency';
import ErrorResponse from '../../../../helpers/ErrorResponse';

function CouponItem({ coupon, paginationCoupons, page, perPage, search, onOpen, setCouponId }) {
  async function changeStatus(coupon) {
    if (coupon) {
      try {
        let updatedCoupons = {
          name: coupon.name,
          status: coupon.status === 'ATIVO' ? 'INATIVO' : 'ATIVO',
        };

        if (coupon.value) {
          updatedCoupons.discountType = 'value';
          updatedCoupons.discount = coupon.value;
        }
        if (coupon.percentage) {
          updatedCoupons.discountType = 'percentage';
          updatedCoupons.discount = coupon.percentage;
        }

        updatedCoupons.products = coupon.products.map(products => products.id);

        await CouponAPI.update(coupon.id, updatedCoupons);

        Toast('Cupom atualizado!', 'success');
        await paginationCoupons(page, perPage, search);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }
  }

  return (
    <tr key={coupon.id}>
      <td>{coupon.name}</td>
      <td>{coupon.value ? convert(coupon.value) : `${coupon.percentage} %`}</td>
      <td>{coupon.products.length}</td>
      <td>
        {dayjs(coupon.startDate).add(1, 'day').format('DD/MM/YYYY')}
        <br />
        {dayjs(coupon.endDate).format('DD/MM/YYYY')}
      </td>
      <td>{coupon.uses}</td>
      <td>{coupon.amount}</td>
      <td>{convert(coupon.invoicing)}</td>

      <td>
        <span
          style={coupon.status === 'ATIVO' ? { background: '#22bb33' } : { background: '#bb2124' }}
        >
          {coupon.status}
        </span>
      </td>
      <td>
        <div className="btn-group">
          <span
            onClick={() => {
              setCouponId(coupon.id);
              onOpen();
            }}
            className="btn btn-outline-gray border border-gray"
          >
            Editar
          </span>
          <Menu>
            <MenuButton
              border="1px solid"
              borderLeftColor="transparent"
              _hover={{ background: 'gray.500' }}
              w={5}
              style={{ outline: 'none', boxShadow: 'none' }}
            >
              <DotsVertical />
            </MenuButton>
            <MenuList>
              <MenuItem
                fontWeight={500}
                style={{ outline: 'none', boxShadow: 'none' }}
                onClick={() => changeStatus(coupon)}
              >
                {coupon.status === 'ATIVO' ? 'Inativar' : 'Ativar'}
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </td>
    </tr>
  );
}

export default memo(CouponItem);
