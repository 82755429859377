import { ChangeEvent } from 'react';

import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useBreakpointValue,
  UseModalProps,
} from '@chakra-ui/react';
import { CheckoutSettings } from '../types';

interface TypeChangeModalProps extends UseModalProps {
  isLoading: boolean;
  checkoutTypeToUpdate: CheckoutSettings['checkoutType'];
  onCheckoutTypeToUpdateChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  onConfirm: () => void;
}

export default function TypeChangeModal({
  isOpen,
  isLoading,
  checkoutTypeToUpdate,
  onClose,
  onCheckoutTypeToUpdateChange,
  onConfirm,
}: TypeChangeModalProps) {
  const isCentered = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap isCentered={isCentered}>
        <ModalOverlay />
        <ModalContent
          minWidth={{ base: 'unset', xl: '38.25rem' }}
          maxWidth={{ base: '92.11%', xl: '38.25rem' }}
        >
          <ModalHeader fontWeight="bold" fontSize="lg">
            Alterar checkout
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel color="gray.500" fontSize="sm" fontWeight="normal">
                Selecione o tipo de checkout que você deseja que seu produto tenha.
              </FormLabel>

              <Select
                value={checkoutTypeToUpdate}
                onChange={onCheckoutTypeToUpdateChange}
                focusBorderColor="gray.300"
                color="#20212380"
              >
                <option value="DEFAULT">Padrão</option>
                <option value="LAUNCH">Lançamento</option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={onClose}
              background="gray.200"
              isDisabled={isLoading}
            >
              Cancelar
            </Button>
            <Button
              onClick={onConfirm}
              colorScheme="primary"
              color="secondary.500"
              isLoading={isLoading}
            >
              Alterar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
