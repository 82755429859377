import { Heading, Box, Text } from '@chakra-ui/react';

export default function IntegrationsHeader() {
  return (
    <Box>
      <Heading as="h1" fontWeight="semibold" color="#202123">
        Integrações
      </Heading>

      <Text color="#20212380" fontSize="lg" lineHeight="1.25" mt={5}>
        Potencialize seus produtos com as integrações da Proluno, adicionando funcionalidades para
        melhorar a experiência dos alunos e a eficácia de seus cursos.
      </Text>
    </Box>
  );
}
