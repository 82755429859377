import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

import { IoMdAlert } from 'react-icons/io';

interface DeleteModalProps {
  onConfirm: () => void;
  onClose: ModalProps['onClose'];
  isOpen: ModalProps['isOpen'];
  isLoading?: boolean;
  questionId: number | null;
}

const ALERT_ICON_SIZE = '3.077rem'; // 40px

export default function DeleteModal(props: DeleteModalProps) {
  function handleConfirm() {
    props.onConfirm();
    props.onClose();
  }

  return (
    <Modal {...props} isCentered={true} size="xl" motionPreset="none">
      <ModalOverlay bg="rgba(32, 33, 35, 0.15)" backdropFilter="auto" backdropBlur="3.5px" />

      <ModalContent>
        <ModalHeader display="flex" justifyContent="center">
          <IoMdAlert color="#E85347" width="40px" height="40px" fontSize={ALERT_ICON_SIZE} />
        </ModalHeader>

        <ModalCloseButton _focus={{ boxShadow: 'none' }} mr={4} mt={4} />

        <ModalBody textAlign="center" px={0} py={0}>
          <Heading fontSize="lg" fontWeight="medium" lineHeight="28px">
            {`Deseja excluir a questão com id:${props.questionId}?`}
          </Heading>
        </ModalBody>

        <ModalFooter justifyContent="center" gap={3} px={0} pb={6}>
          <Button
            onClick={props.onClose}
            bg="#e9e9e9"
            border="1px solid #20212340"
            isDisabled={props.isLoading}
          >
            Cancelar
          </Button>

          <Button
            bg="#E85347"
            colorScheme="red"
            onClick={handleConfirm}
            isLoading={props.isLoading}
          >
            Excluir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
