import {
  CustomNextArrow,
  CustomPrevArrow,
  NextArrow,
  NextArrowInstructor,
  PrevArrow,
  PrevArrowInstructor,
} from './arrows';

const responsive = [
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 668,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

export const settingsBanner = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
};

export const settingsCatProd = {
  dots: false,
  infinite: false,
  slidesToShow: 5.25,
  slidesToScroll: 5,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};

export const settingInstructor = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  prevArrow: <PrevArrowInstructor />,
  nextArrow: <NextArrowInstructor />,
};

export const settingsCategories = {
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 500,
  lazyLoad: true,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};

export const settingsInstructors = {
  dots: true,
  infinite: false,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: responsive,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};

export const settingsTestimonials = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
};
