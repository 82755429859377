import styles from '../styles.module.css';
import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logos/proluno_cor.png';

function TermUse() {
  return (
    <div className="container">
      <div className={styles.policyHead}>
        <div className={styles.policyContentHead}>
          <img src={logo} alt="Logo proluno" />
          <h3>Termo geral de uso</h3>
        </div>
        <p>
          O presente “Termo Geral de Uso”, também denominado de “Termos de Uso” tem sua
          aplicabilidade para os usuários dos serviços e produtos oferecidos pela PROLUNO TECNOLOGIA
          PARA EDUCACAO LTDA (CNPJ 24.641.922/0001-46).
          <br />
          <br />
          Leia com atenção, pois ele disciplinará os seus direitos e obrigações no uso dos produtos
          e serviços digitais disponibilizados pela Proluno.
          <br />
          <br />
          Nossa Política de Privacidade pode ser encontrada em{' '}
          <Link
            to="/privacy-policy"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Política de Privacidade.
          </Link>
        </p>
      </div>

      <div className={styles.content}>
        <ul>
          <h3>1. DAS CONSIDERAÇÕES INICIAIS</h3>

          <li>
            <b>1.1</b> Para a utilização de produtos ou serviços digitais da Proluno, é necessário a
            aceitação de suas condições e cláusulas. Caso não concorde com os termos, interrompa
            imediatamente o uso do serviço ou produto. A utilização prolongada pressupõe a aceitação
            tácita deste Termo de Uso e dos demais correlacionados;
          </li>
          <li>
            <b>1.2</b> O presente termo de uso poderá ser alterado a qualquer momento, a exclusivo
            critério da Proluno, sem que gere qualquer direito adquirido aos usuários ou membros dos
            serviços ou produtos disponibilizados pela Proluno.
          </li>
          <li>
            <b>1.3</b> Qualquer alteração ou modificação a estes Termos de Uso entrará em vigor
            imediatamente após a publicação. Você é responsável por analisar periodicamente a mais
            atualizada versão destes Termos de Uso.
          </li>
          <li>
            <b>1.4</b> Os Termos de Uso e a Política de Privacidade são válidos para todos os
            usuários dos serviços e produtos Proluno.
          </li>
          <li>
            <b>1.5</b> O uso continuado dos nossos serviços constitui a aceitação de quaisquer
            alterações ou modificações feitas neste documento.
          </li>
        </ul>
        <ul>
          <h3>2. DOS CONCEITOS</h3>
          <div className="mb-4">
            <span>
              O conhecimento dos conceitos tratados neste documento são importantes para a correta
              compreensão dos seus direitos e obrigações em sua relação com a Proluno.
            </span>
          </div>
          <li>
            <b>2.1. Produto:</b> é qualquer bem, material ou imaterial, disponibilizado pela Proluno
            ao mercado para consumo.
          </li>
          <li>
            <b>2.2. Serviço:</b> é qualquer atividade prestada pela Proluno a um usuário.
          </li>
          <li>
            <b>2.4. Marketplace:</b> é o espaço digital, sob a gestão da Proluno, onde o membro
            poderá disponibilizar os seus produtos ou serviços para venda.
          </li>
          <li>
            <b>2.5. Usuários:</b> são pessoas que utilizam qualquer serviço ou produto da Proluno.
          </li>
          <li>
            <b>2.6. Membros:</b> são pessoas físicas ou jurídicas, nacionais ou internacionais, que
            possuem cadastro na Proluno.
          </li>
          <li>
            <b>2.7. Assinantes:</b> são membros que estão ativos em um dos planos de assinatura
            disponíveis.
          </li>
          <li>
            <b>2.8 Compradores:</b> são membros que adquiriram um produto ou serviço, de forma
            gratuita ou onerosa, de um assinante.
          </li>
          <li>
            <b>2.9 Terceiros:</b> pessoas físicas ou jurídicas que não integram o ecossistema criado
            pela Proluno através da sua tecnologia.
          </li>
        </ul>
        <ul>
          <h3>3. DOS SERVIÇOS E PRODUTOS DISPONIBILIZADOS</h3>
          <div className="mb-4">
            <span>
              A PROLUNO TECNOLOGIA PARA EDUCAÇÃO disponibiliza ao mercado os seguintes produtos ou
              serviços:
            </span>
          </div>

          <li>
            <b>3.1. Plataforma Digital Saas (Software as a Service):</b>A Proluno fornece à membro,
            mediante pagamento de assinatura, licença de uso de uma plataforma digital para o ensino
            e comercialização de produtos digitais. As transações eventualmente ocorridas no uso da
            tecnologia licenciada são de exclusiva responsabilidade do mesmo, e o contrato será
            firmado entre ele e o cliente, sendo que a Proluno não será parte, interveniente ou
            garantidora, e nem funcionará como distribuidora do seu conteúdo.
          </li>

          <li>
            <b>3.2. Marketplace: </b> A Proluno disponibiliza{' '}
            <a
              href="https://checkout.proluno.me/"
              role="button"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              espaço digital
            </a>{' '}
            onde os membros poderão disponibilizar os seus produtos ou serviços para venda, segundo
            critérios e regras próprias definidas na Política de Marketplace.
          </li>

          <li>
            <b>3.3. Serviços e Produtos adicionais: </b> A Proluno poderá disponibilizar aos seus
            membros serviços ou produtos adicionais, os quais se regerão pelas políticas próprias,
            as quais devem ser consultadas pelos interessados.
          </li>
        </ul>
        <ul>
          <h3>4. DAS CONDIÇÕES GERAIS DE USO:</h3>

          <div className="mb-4">
            <span>
              Aqui você encontrará os dispositivos que regerão a sua relação contratual com a
              Proluno.
            </span>
          </div>

          <li>
            <b>4.1.</b> Quando você completar o seu processo de registro para se tornar membro, você
            criará uma senha que habilitará seu acesso à nossa Plataforma. Você concorda em manter a
            confidencialidade da sua senha e é inteiramente responsável por qualquer dano resultante
            da não manutenção dessa confidencialidade e de todas as atividades que ocorrerem através
            do uso de sua senha. Você concorda em nos notificar imediatamente de qualquer acesso não
            autorizado de sua senha ou outra quebra de segurança no e-mail{' '}
            <span className={styles.link}>support@proluno.zendesk.com</span>. Você concorda que a
            Proluno não será responsabilizada por qualquer perda ou dano que ocorra a partir do não
            cumprimento das regras deste Termo de Uso.
          </li>
          <li>
            <b>4.2</b> O membro é o único responsável pelas informações por ele fornecidas quando de
            seu cadastro na Plataforma, estando ciente de que a PROLUNO não se responsabiliza por
            informações incorretas ou inverídicas apresentadas pelo membro, o qual será responsável,
            também, por manter atualizadas todas as informações pessoais e de contato fornecidas,
            especialmente o seu correio eletrônico (e-mail), telefone e endereço.
          </li>
          <li>
            <b>4.3</b> O Membro reconhece que, caso seja constatado que este forneceu informações
            incorretas ou inverídicas em seu cadastro, seu acesso à Plataforma poderá ser cancelado,
            independentemente de qualquer formalidade, sem que nada seja devido pela PROLUNO em
            razão de indenização, em razão de tal cancelamento.
          </li>
          <li>
            <b>4.4</b> Ao interagir como membro da Proluno, você garante que suas informações
            prestadas: (a) não são fraudulentas; (b) não infringem nenhum direito autoral de
            terceiros, patente, marca registrada, direito de distribuição ou outro direito de
            propriedade intelectual, de publicação ou privacidade; (c) não violam nenhuma lei,
            estatuto, ordenação ou regulamento; (d) não são difamatórias, caluniosas, ameaçadoras ou
            abusivas; (e) não são obscenas ou conter pornografia, pornografia infantil, ou fotos de
            pessoas despidas; (f) não contém vírus, cavalos de tróia, worms, time bombs, cancelbots,
            easter eggs ou outras rotinas de programação que possam danificar, interferir,
            interceptar ou desapropriar qualquer sistema, dado ou informação pessoal; (g) não devem
            criar links direta ou indiretamente a qualquer material que você não tenha direito de
            incluir ou linkar.
          </li>
          <li>
            <b>4.5</b> Você autoriza, ao aceitar este documento, que a Proluno reporte às
            autoridades competentes qualquer ato que considere ter indício de irregularidade ou
            ilegalidade, bem como autoriza o fornecimento das suas informações quando solicitadas
            pelas autoridades. Você como usuário não poderá exigir da Proluno qualquer indenização,
            por dano material ou imaterial, em decorrência dessas ações.
          </li>
          <li>
            <b>4.6</b> As relações prestacionais realizadas por meio da Plataforma são firmadas
            entre os membros, sendo que a PROLUNO atuará como mero instrumento de disponibilização e
            integração, razão pela qual não se responsabiliza pelo conteúdo transmitido, pelo mau
            uso do espaço disponibilizado, pelo desvio da finalidade pretendida, e ainda por e-mails
            e/ou informações diversas das solicitadas e interações em contrariedade ao objeto fim
            desta.
          </li>
          <li>
            <b>4.7 </b> É de exclusiva responsabilidade dos membros o conteúdo ministrado em suas
            “aulas virtuais”, acompanhamentos personalizados e demais serviços comercializados
            através da Plataforma, devendo qualquer divergência de conteúdo ou erro nas explicações
            serem imputados exclusivamente a este, não se responsabilizando a PROLUNO por qualquer
            uma das ocorrências mencionadas.
          </li>
          <li>
            <b>4.8</b> Os direitos e obrigações dos compradores constam na Política de Compradores,
            a qual pode ser consultada{' '}
            <a
              href="https://ensine.proluno.com.br/"
              role="button"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              aqui
            </a>
            .
          </li>
          <li>
            <b>4.9</b> Ao utilizar os serviços da Plataforma Proluno, como membro assinante ou
            membro Comprador, você é um contratante de serviços de intermediação, e não se torna
            empregado, colaborador, representante, agente, sócio, associado ou parceiro.
          </li>
          <li>
            <b>4.10</b> A Proluno não cria, elabora, controla, endossa ou fornece qualquer Produto
            ou serviço. Os membros assinantes são integralmente responsáveis pelo conteúdo e pelas
            informações relativas aos seus produtos ou serviços.
          </li>
          <li>
            <b>4.11</b> A PROLUNO não se responsabiliza por quaisquer obrigações tributárias
            eventualmente incidentes sobre os valores auferidos pelos membros através da
            comercialização de seus produtos na Plataforma, sendo deste a responsabilidade pela
            correta declaração e informação às autoridades fiscais.
          </li>
          <li>
            <b>4.12</b> Como forma de garantir a qualidade dos serviços prestados, a PROLUNO poderá
            fazer pesquisa de satisfação com qualquer usuário, sendo que os resultados poderão ser
            divulgados aos membros da Plataforma.
          </li>
          <li>
            <b>4.13</b> Os dados pessoais dos usuários são tratados pela Proluno de acordo com a
            legislação e as melhores políticas de governança. Não vendemos, alugaremos ou
            divulgaremos para terceiros nenhuma parte das informações pessoais fornecidas à Proluno
            pelos usuários.
          </li>
        </ul>
        <ul>
          <h3>5. DA POLÍTICA DE MARKETPLACE: </h3>
          <div className="mb-4">
            <span>
              A Proluno disponibiliza ao membro assinante acesso ao Marketplace, que é o espaço
              digital onde o membro poderá disponibilizar os seus produtos ou serviços para venda.
            </span>
          </div>

          <li>
            <b>5.1</b> As condições de uso, comercialização e taxas do Marketplace estão previstas
            na{' '}
            <a
              href="https://ensine.proluno.com.br/"
              role="button"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Política de Marketplace
            </a>{' '}
            da Proluno, e se incorporam ao contrato de todos os membros que utilizam o serviço.
          </li>
          <li>
            <b>5.2</b> Ao aceitar este Termos de Uso, o membro autoriza a disponibilização imediata
            dos seus produtos no Marketplace Proluno, sendo que a sua retirada desse espaço digital
            só ocorrerá mediante solicitação.
          </li>
        </ul>
        <ul>
          <h3>6. DA POLÍTICA DE INTERMEDIAÇÃO DE PAGAMENTOS</h3>

          <div className="mb-4">
            <span>
              A Proluno utiliza gateway de pagamento de parceiros, sendo que as suas condições de
              pagamento, repasse e taxas são determinadas por elas.
            </span>
          </div>

          <li>
            <b>6.1</b> Para conhecimento das condições aplicáveis ao seu negócio digital, consulte a
            nossa{' '}
            <a
              href="https://ensine.proluno.com.br/"
              role="button"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Política de Intermediação de Pagamentos
            </a>
            .
          </li>
        </ul>
        <ul>
          <h3>7. DAS CONSIDERAÇÕES FINAIS</h3>
          <li>
            <b>7.1</b> A PROLUNO reserva-se a o direito, em casos omissos, de decidir segundo seus
            critérios de oportunidade e conveniência as situações não previstas neste Termo e
            Condição de Uso, valendo esta decisão como meio de resolução de dúvidas evidenciadas.
          </li>
          <li>
            <b>7.2</b> É competente para conhecer as questões comerciais e processuais o foro
            brasileiro, que prepondera sobre qualquer outro, sendo eleito nesta oportunidade o foro
            do Estado do Ceará, Comarca da Capital, para conhecer as demandas que porventura se
            mostrarem ajuizadas, renunciando a qualquer outro por mais privilegiado que seja ou
            venha a ser.
          </li>
          <li>
            <b>7.3</b> O acesso à Plataforma por membros é regido por este documento e pelas
            Políticas de Assinante e de Compradores.
          </li>

          <li>
            <b>7.4</b> A PROLUNO, ainda, se reserva o direito de recusar ou retirar o acesso à
            Plataforma, a qualquer momento, e sem necessidade de prévio aviso ou justificativa.
          </li>
          <li>
            <b>7.5</b> Para eventuais dúvidas sobre esses termos ou termos adicionais, entre em
            contato via e-mail com o endereço:{' '}
            <span className={styles.link}>support@proluno.zendesk.com</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TermUse;
