import Joi from 'joi';

export const signInSchema = Joi.object().keys({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .min(2)
    .max(255)
    .messages({
      'string.empty': `Por favor, digite o seu e-mail.`,
      'string.email': `Você deve digitar um e-mail válido. Digite o e-mail que você cadastrou anteriormente na plataforma`,
      'string.min': `O seu e-mail deve ter pelo menos 2 caracteres.`,
    }),
  password: Joi.string().required().min(6).max(60).messages({
    'string.empty': `A senha não pode estar vazia.`,
    'string.min': `A sua senha dever ter pelo menos 6 caracteres.`,
  }),
});
