import './style.css';

import axios from 'axios';
import path from 'path';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { BsChevronDown as ArrowDown, BsTrash } from 'react-icons/bs';
import { ImFileVideo } from 'react-icons/im';
import { useHistory, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import {
  PLATFORMS_WITH_UPLOAD_LIMITATION,
  platformsWith200MbUploadLimit,
  platformsWithoutUploadVideo,
} from '../../../../../../App';
import { baseURLBackend } from '../../../../../../api/Configuration';
import ContentAPI from '../../../../../../api/Content';
import VideoLibraryAPI from '../../../../../../api/VideoLibrary';
import CustomDropzone from '../../../../../../components/CustomDropzone';
import EditorRichText from '../../../../../../components/EditorRichText';
import DotsFalling from '../../../../../../components/Loadings/DotsFalling';
import Spinner from '../../../../../../components/Loadings/Spinner';
import { ReactSelectStyles } from '../../../../../../components/ReactSelect';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import { useVideoLibrary } from '../../../../../../contexts/VideoLibraryContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { TOKEN_LOCAL_STORAGE } from '../../../../../../helpers/LocalStorageHelper';
import UploadHelper from '../../../../../../helpers/UploadHelper';
import VideoItem from './VideoItem';

import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  HStack,
  Progress,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

const axiosUpload = axios.create();

const initialValues = {
  title: '',
  content: '',
  contentType: 'VIDEO',
  fileDownload: '',
  order: 1,
  link: '',
};

const initialStateUploadProgress = {
  inProgress: false,
  progress: 0,
  isComplete: false,
};

const MB = 1024 * 1024; // 1 megabyte em bytes
const SIZE_200_MB = 200 * MB; // 200 megabytes em bytes
const SIZE_25_MB = 25 * MB; // 25 megabytes em bytes
const MAX_LENGTH_VIDEO_FILE = 1073741824;
const ADD_WITH_CURRENT_ORDER = 1;

export default function VideoForm() {
  const { videoLibrary, getVideos, isLoadingVideoLibrary } = useVideoLibrary();

  const [uploadProgress, setUploadProgress] = useState(initialStateUploadProgress);
  const [form, setForm] = useState(initialValues);
  const [searchVideo, setSearchVideo] = useState('');
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [status, setStatus] = useState({
    state: '',
    progress: 0,
    time_left: 0,
  });

  const [editorState, setEditorState] = useState('');

  const [videoFile, setVideoFile] = useState('');

  const [fileDownload, setFileDownload] = useState('');

  const [isShowModal, setIsShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [changed, setChanged] = useState(true);
  const [searching, setSearching] = useState(false);

  const { getContent, course, setCourse } = useCourse();

  const { id: courseId, moduleId, contentId } = useParams();

  const [filterByNameVideoLibrary, setFilterByNameVideoLibrary] = useState([]);

  const [getLinkLoading, setGetLinkLoading] = useState(false);

  const hostname = window.location.hostname;
  const isPlatformWith200MbUploadLimit = platformsWith200MbUploadLimit.includes(hostname);

  const { getRootProps, getInputProps, open, isDragReject } = useDropzone({
    noClick: !getLinkLoading,
    accept: ['video/mp4'],
    onDropAccepted: filesAccepted => {
      setGetLinkLoading(true);

      setVideoFile(filesAccepted.shift());

      setGetLinkLoading(false);
    },
  });

  const history = useHistory();

  useEffect(() => {
    if (videoLibrary.length) {
      setFilterByNameVideoLibrary(videoLibrary);
    }
  }, [videoLibrary]);

  useEffect(() => {
    let interval;

    async function getStatusTranscode(videoSelected) {
      try {
        const { data: transcodeData } = await VideoLibraryAPI.getTranscodeStatus(videoSelected.id);

        if (transcodeData?.status) {
          setStatus(transcodeData.status);
        }

        if (
          transcodeData?.transcode?.status === 'complete' ||
          transcodeData?.transcode?.status === 'error'
        ) {
          const videosAdded = selectedVideos.map(video => {
            if (videoSelected.id === video.value) {
              return {
                ...video,
                transcode: transcodeData.transcode.status,
                thumbnail: transcodeData.thumbnail,
                duration: transcodeData.duration,
              };
            }

            return video;
          });

          const videosUpdated = filterByNameVideoLibrary.map(currentVideo => {
            if (videoSelected.id === currentVideo.id) {
              const videoUpdated = {
                ...currentVideo,
                transcode: transcodeData.transcode.status,
                thumbnail: transcodeData.thumbnail,
              };

              return videoUpdated;
            }

            return currentVideo;
          });

          setSelectedVideos(videosAdded);

          setFilterByNameVideoLibrary(videosUpdated);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        if (videoSelected?.transcode === 'complete' && interval) {
          clearInterval(interval);
        }
      }
    }

    if (selectedVideos.length) {
      const videoSelected = { ...selectedVideos[0], id: selectedVideos[0].value };

      interval = setInterval(() => {
        if (videoSelected?.transcode === 'complete' || videoSelected?.transcode === 'error') {
          clearInterval(interval);
        } else {
          getStatusTranscode(videoSelected);
        }
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [filterByNameVideoLibrary, selectedVideos]);

  function getStatus({ transcode, status }) {
    if (status.state === 'starting' && status.progress === 0) {
      return <Text>Na fila para otimização</Text>;
    }

    if (status.state === 'active') {
      return <Text>Otimizando</Text>;
    }

    switch (transcode) {
      case 'complete':
        return <Text>ATIVO</Text>;
      case 'error':
        return (
          <Text fontWeight="bold" color="red">
            Erro no processamento
          </Text>
        );
      default:
        return <Text>Processando</Text>;
    }
  }

  function updateStatusTranscode({ id, transcode, name, thumbnail }) {
    const videosAdded = selectedVideos.map(video => {
      if (id === video.value) {
        const videoUpdated = { id, label: video.label, transcode, thumbnail };

        return videoUpdated;
      }

      return video;
    });

    setSelectedVideos(videosAdded);

    const videosUpdated = filterByNameVideoLibrary.map(currentVideo => {
      if (id === currentVideo.value) {
        const videoUpdated = {
          id: currentVideo.id,
          label: name,
          transcode,
          thumbnail,
        };

        return videoUpdated;
      }

      return currentVideo;
    });

    setFilterByNameVideoLibrary(videosUpdated);
  }

  useEffect(() => {
    getVideos({ page: 1, per_page: 1000 });
  }, [getVideos]);

  useEffect(() => {
    async function fetchVideosByName(name) {
      try {
        setSearching(true);
        const { data: videoSearchData } = await VideoLibraryAPI.indexByName({ search: name });

        if (videoSearchData) {
          setFilterByNameVideoLibrary(videoSearchData.videos);
        } else {
          setFilterByNameVideoLibrary([]);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setSearching(false);
      }
    }

    const timer = setTimeout(() => {
      if (searchVideo) {
        fetchVideosByName(searchVideo);
      } else {
        setFilterByNameVideoLibrary(videoLibrary);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchVideo, videoLibrary]);

  useEffect(() => {
    if (contentId) {
      const content = getContent(moduleId, contentId);

      if (content) {
        const currentEditorState = content.content || '';
        setEditorState(currentEditorState);

        setForm({
          title: content.title,
          content: content.content,
          contentType: content.contentType,
          fileDownload: content.download,
          order: content.order,
          link: content.link,
        });
      }
    }
  }, [contentId, getContent, moduleId, videoLibrary]);

  useEffect(() => {
    async function fetchVideosByUri(link) {
      try {
        const { data: videoSearchByUriData } = await VideoLibraryAPI.indexByPlayer(link);

        if (videoSearchByUriData) {
          setSelectedVideos(
            videoSearchByUriData.videos.map(video => ({
              value: video.id,
              label: video.name,
              thumbnail: video.thumbnail,
              transcode: video.transcode,
            }))
          );
        }
      } catch (error) {
        setErrorMessage(ErrorResponse(error));
      }
    }

    if (!isLoadingVideoLibrary) {
      const content = getContent(moduleId, contentId);

      if (contentId && content) {
        const currentEditorState = content.content || '';

        setEditorState(currentEditorState);
        setForm({ ...content, fileDownload: content.download });

        fetchVideosByUri(content.link);
      }

      setIsLoading(false);
    }
  }, [contentId, getContent, isLoadingVideoLibrary, moduleId, videoLibrary]);

  if (isLoadingVideoLibrary) {
    return (
      <div className="container d-flex justify-content-center mt-5">
        <DotsFalling color="white" />
      </div>
    );
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setForm({
      ...form,
      [name]: value,
    });

    setChanged(true);
  }

  function handleDiscardVideo() {
    setSelectedVideos([]);
    setErrorMessage('');
    setForm({
      ...form,
      link: '',
    });
    setVideoFile(null);
    setUploadProgress(initialStateUploadProgress);
  }

  function handleSearchVideo(data) {
    setSearchVideo(data);
  }

  function onChangeLibraryVideos(video) {
    setSelectedVideos([video]);

    setVideoFile(null);

    setForm({
      ...form,
      link: video.link,
    });

    setChanged(true);
  }

  function handleEditorStateChange(newEditorState) {
    setErrorMessage('');
    setChanged(true);
    setEditorState(newEditorState);
    setForm(prevForm => ({ ...prevForm, content: newEditorState }));
  }

  function handleDropFileDownload(acceptedFiles) {
    setErrorMessage('');

    const [fileDownload] = acceptedFiles || '';

    setFileDownload(fileDownload);
    setForm({
      ...form,
      fileDownload: fileDownload,
    });
  }

  function PreviewDownload({ fileDownload }) {
    function handleDiscardFileDownload() {
      setFileDownload('');
      setForm({
        ...form,
        fileDownload: '',
      });
    }

    return (
      <div className="d-flex align-center justify-content-between mt-2">
        <div>{fileDownload.name || path.basename(decodeURI(form.fileDownload))}</div>
        <div>
          <div>
            {!fileDownload && (
              <a
                href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${localStorage.getItem(
                  TOKEN_LOCAL_STORAGE
                )}`}
                download={true}
              >
                Download
              </a>
            )}

            <button
              onClick={handleDiscardFileDownload}
              type="button"
              className="btn btn-outline-primary"
            >
              <BsTrash />
            </button>
          </div>
        </div>
      </div>
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    delete form.markAsDone;

    if (!contentId) {
      try {
        if (isPlatformWith200MbUploadLimit && fileDownload && fileDownload.size > SIZE_200_MB) {
          return Toast('O tamanho máximo do arquivo deve ser igual ou inferior a 100 MB.', 'error');
        }

        if (!isPlatformWith200MbUploadLimit && fileDownload && fileDownload.size > SIZE_25_MB) {
          return Toast('O tamanho máximo do arquivo deve ser igual ou inferior a 25 MB.', 'error');
        }

        const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

        const { contents } = currentModule;

        if (contents.length) {
          const orders = contents.map(content => content.order);
          form.order = orders.length
            ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
            : initialValues.order;
        }
        const video = selectedVideos[0];

        if (!video) {
          Toast('Selecione um vídeo', 'error');
          return;
        }

        const videoId = video.value;

        let videoContent;

        if (typeof form.fileDownload !== 'string') {
          const { newFileName } = await UploadHelper.upload(form.fileDownload, 'archives');

          if (!video) return Toast('Selecione um vídeo.', 'error');

          const { data: videoContentData } = await ContentAPI.video.store(
            courseId,
            moduleId,
            videoId,
            {
              ...form,
              fileDownload: newFileName,
            }
          );

          videoContent = videoContentData;
        } else {
          const { data: videoContentData } = await ContentAPI.video.store(
            courseId,
            moduleId,
            videoId,
            form
          );

          videoContent = videoContentData;
        }

        const newContents = [...currentModule.contents, videoContent];

        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({
          ...course,
          modules: newModules,
        });

        setFileDownload('');

        setIsLoading(false);
        Toast('Conteúdo cadastrado com sucesso');

        history.push(
          `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${videoContent.id}/edit-video`
        );
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
      return;
    }

    try {
      if (isPlatformWith200MbUploadLimit && fileDownload && fileDownload.size > SIZE_200_MB) {
        return Toast('O tamanho máximo do arquivo deve ser igual ou inferior a 100 MB.', 'error');
      }

      if (!isPlatformWith200MbUploadLimit && fileDownload && fileDownload.size > SIZE_25_MB) {
        return Toast('O tamanho máximo do arquivo deve ser igual ou inferior a 25 MB.', 'error');
      }

      const formData = new FormData();

      delete form.download;

      Object.keys(form).forEach(key => formData.append(key, form[key] || ''));

      const video = selectedVideos[0];

      if (!video) return Toast('Selecione um vídeo.', 'error');

      const videoId = video.value;

      let updatedContent;

      if (form?.fileDownload && typeof form.fileDownload !== 'string') {
        const { newFileName } = await UploadHelper.upload(form.fileDownload, 'archives');

        if (!video) return Toast('Selecione um vídeo.', 'error');

        const { data: updatedContentData } = await ContentAPI.video.update(
          contentId,
          moduleId,
          courseId,
          videoId,
          {
            ...form,
            fileDownload: newFileName,
          }
        );

        updatedContent = updatedContentData;
      } else {
        const { data: updatedContentData } = await ContentAPI.video.update(
          contentId,
          moduleId,
          courseId,
          videoId,
          form
        );

        updatedContent = updatedContentData;
      }

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      const newContents = currentModule.contents.map(content => {
        if (content.id === parseInt(contentId)) {
          return {
            ...content,
            title: updatedContent.title,
            content: updatedContent.content,
            contentType: updatedContent.contentType,
            download: updatedContent.download,
            order: updatedContent.order,
            link: updatedContent.link,
          };
        }

        return content;
      });

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      setFileDownload('');
      Toast(`Conteúdo atualizado com sucesso`);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  function handleDiscardChanges() {
    const content = getContent(moduleId, contentId);

    setEditorState(content.content || '');
    setForm({ ...content, fileDownload: content.download });

    const video = videoLibrary.find(video => video.player === content.link);

    if (video) {
      setSelectedVideos([video]);
    }
  }

  async function handleDelete() {
    try {
      setIsLoading(true);

      await ContentAPI.delete(courseId, moduleId, contentId);

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      const newContents = currentModule.contents.filter(
        module => module.id !== parseInt(contentId)
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      Toast('Conteúdo excluído com sucesso');
      history.push(`/courses/${courseId}/course-manager/course`);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  function onAddVideoList(video) {
    if (!video) return;

    const videosAdded = [video, ...filterByNameVideoLibrary];

    setFilterByNameVideoLibrary(videosAdded);

    setSelectedVideos([
      {
        value: video.id,
        label: video.name,
        thumbnail: video.thumbnail,
      },
    ]);

    setForm({
      ...form,
      link: video.player,
    });

    setVideoFile(null);
  }

  async function getPlatformStorage() {
    try {
      setIsLoading(true);
      const response = await VideoLibraryAPI.getPlatformStorage();

      return response.data;
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  async function onSubmitFiles() {
    if (!videoFile) return;

    const uploadLimitPlatform = PLATFORMS_WITH_UPLOAD_LIMITATION.find(
      platforms => platforms.url === hostname
    );

    if (uploadLimitPlatform) {
      const { sizeUsage } = await getPlatformStorage();

      if (sizeUsage > uploadLimitPlatform.limit) {
        Toast('Você atingiu o limite máximo de upload de vídeos da sua plataforma.', 'error');
        return;
      }
    }

    if (videoFile.size > MAX_LENGTH_VIDEO_FILE) {
      return Toast('O tamanho máximo do vídeo deve ser igual ou inferior a 1 GB.', 'error');
    }

    const file = videoFile;

    setUploadProgress({
      ...uploadProgress,
      inProgress: true,
    });

    VideoLibraryAPI.getUploadLink({
      name: encodeURI(file.name),
      size: file.size,
    }).then(({ data: videoWithUploadLink }) => {
      axiosUpload
        .put(videoWithUploadLink.url, file, {
          onUploadProgress: progressEvent => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

            setUploadProgress({
              ...uploadProgress,
              inProgress: true,
              progress,
            });
          },
          headers: {
            'Content-Type': 'video/mp4',
          },
        })
        .then(() => {
          VideoLibraryAPI.uploadVideo({
            name: encodeURI(videoFile.name),
            size: videoFile.size,
            link: videoWithUploadLink.url,
          })
            .then(({ data: video }) => {
              setUploadProgress({
                ...uploadProgress,
                isComplete: true,
                progress: 0,
              });

              onAddVideoList({
                name: video.name,
                thumbnail: video.thumbnail,
                uploadAt: video.uploadAt,
                archive: false,
                duration: video.duration,
                size: video.size,
                transcode: video.transcode,
                id: video.id,
                player: video.player,
              });
            })
            .catch(error => {
              Toast(ErrorResponse(error), 'error');
            });
        })
        .catch(error => {
          Toast(ErrorResponse(error), 'error');
        });
    });
  }

  return (
    <div className="mx-5" style={{ wordBreak: 'break-all' }}>
      <h4 className="mt-1 mb-4">{form.title || 'Novo conteúdo de vídeo'}</h4>

      <form
        onSubmit={handleSubmit}
        className="p-5 round-xl shadow-sm border border-gray bg-white mb-5"
      >
        <div className="d-flex align-center justify-content-between mb-4">
          <div>
            <p className="info" style={{ wordBreak: 'break-word', lineHeight: '1.5' }}>
              Escolha os vídeos que irão integrar cada módulo dentre os que você cadastrou na sua
              Biblioteca de Vídeos. Ainda não fez isso? Basta ir em{' '}
              <b style={{ fontWeight: 600 }}>Gerenciamento de Produto – Biblioteca de vídeos</b>.
            </p>
          </div>
        </div>

        <div className="form-background">
          <div className="form-group">
            <label className="h6" htmlFor="title">
              Título - Vídeos
            </label>
            <input
              type="text"
              name="title"
              id="title"
              placeholder="Digite o título do vídeo"
              className="form-control"
              onChange={handleChange}
              value={form.title}
              maxLength={255}
              minLength={1}
              required
            />
          </div>

          <div className="form-group">
            <label className="h6">Selecionar vídeo da sua biblioteca</label>
            <Select
              name="products"
              options={filterByNameVideoLibrary
                .filter(video => video.transcode !== 'error')
                .map(video => ({
                  id: video.id,
                  value: video.id,
                  label: video.name,
                  thumbnail: video.thumbnail,
                  link: video.player,
                  transcode: video.transcode,
                }))}
              placeholder="Selecionar vídeo"
              styles={ReactSelectStyles}
              onInputChange={handleSearchVideo}
              value={[...selectedVideos]}
              onChange={onChangeLibraryVideos}
              components={{
                Option: ({ children, data, ...rest }) => (
                  <components.Option {...rest}>
                    <VideoItem video={data} updateStatusTranscode={updateStatusTranscode} />
                  </components.Option>
                ),
              }}
              noOptionsMessage={() =>
                searching ? (
                  <Flex justifyContent="center">
                    <DotsFalling />
                  </Flex>
                ) : (
                  'Nenhum vídeo encontrado'
                )
              }
            />
          </div>

          {errorMessage && (
            <div className="mt-4">
              <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
                <div id="alertError" className="text-center">
                  {errorMessage}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="my-3 d-flex justify-content-center flex-column align-items-center">
          {form?.link && (
            <div className="d-flex justify-content-center flex-column align-items-center">
              {selectedVideos[0]?.transcode !== 'complete' && selectedVideos[0]?.thumbnail ? (
                <Box>
                  <Box
                    width="640px"
                    height="336px"
                    background={`url("${selectedVideos[0]?.thumbnail}")`}
                    backgroundSize="cover"
                  ></Box>
                  <HStack>
                    <CircularProgress
                      color="green.400"
                      py="3"
                      isIndeterminate={!status.progress}
                      value={status.progress}
                    >
                      <CircularProgressLabel>{status.progress}%</CircularProgressLabel>
                    </CircularProgress>
                    <Text fontWeight="bold" my="3">
                      {getStatus({ transcode: selectedVideos[0]?.transcode, status })}
                    </Text>
                  </HStack>
                </Box>
              ) : (
                <iframe
                  width="640px"
                  height="336px"
                  src={form.link}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title={form.title}
                ></iframe>
              )}
              <div className="mt-2">
                <button
                  onClick={handleDiscardVideo}
                  type="button"
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  Remover vídeo
                </button>
              </div>
            </div>
          )}
          {!videoFile && !form.link && (
            <Box
              hidden={platformsWithoutUploadVideo.includes(hostname)}
              borderColor="gray"
              borderStyle="dashed"
              borderWidth="medium"
              textAlign="center"
              cursor="default"
              py={2}
              height="336px"
              width="640px"
              {...getRootProps({
                style: isDragReject
                  ? {
                      borderColor: '#c3463c',
                      backgroundColor: '#fceae9',
                      borderStyle: 'solid',
                      color: '#c3463c',
                    }
                  : {},
              })}
            >
              <Stack spacing={4} flexDirection="column" alignItems="center" justifyContent="center">
                <input {...getInputProps()} id="file" name="file_data" />
                <Text>
                  Apenas vídeos com a extensão <strong>.mp4</strong> são permitidos.
                </Text>
                <ImFileVideo size="64px" />
                <Text fontSize="lg">
                  Arraste e solte seus vídeos aqui <br /> ou
                </Text>
                {isDragReject ? (
                  <Text fontSize="lg" fontWeight="bold">
                    Tipo de arquivo inválido.
                  </Text>
                ) : (
                  <Button
                    minH="2rem"
                    my="4"
                    colorScheme="primary"
                    borderColor="secondary.500"
                    color="secondary.500"
                    borderWidth="thin"
                    onClick={() => open()}
                    isLoading={isLoading}
                  >
                    Selecionar arquivo
                  </Button>
                )}
              </Stack>
            </Box>
          )}

          {videoFile && (
            <VStack justifyContent="center">
              <Box bg="black" height="336px" width="640px" preload="metadata" as="video">
                <source src={URL.createObjectURL(videoFile)} />
              </Box>
              <Box width="full">
                <Box width="full" hidden={!uploadProgress.inProgress}>
                  <Text textAlign="center" fontSize="2xl">
                    {uploadProgress.progress}%
                  </Text>
                  <Progress colorScheme="green" hasStripe value={uploadProgress.progress} />
                </Box>
                <HStack justifyContent="center">
                  <Button
                    minH="2rem"
                    my="4"
                    colorScheme="primary"
                    borderColor="secondary.500"
                    color="secondary.500"
                    borderWidth="thin"
                    onClick={() => handleDiscardVideo()}
                    isLoading={uploadProgress.inProgress}
                  >
                    Selecionar outro vídeo
                  </Button>
                  <Button
                    colorScheme="secondary"
                    borderColor="primary.500"
                    color="primary.500"
                    borderWidth="thin"
                    onClick={() => onSubmitFiles()}
                    isLoading={uploadProgress.inProgress}
                    hidden={uploadProgress.isComplete}
                  >
                    Enviar vídeo
                  </Button>
                </HStack>
              </Box>
            </VStack>
          )}
        </div>
        <span>
          O campo de texto é extremamente útil para ajudar os alunos a entender o conteúdo que será
          tratado em cada vídeo, para disponibilizar um breve resumo, dar alguma instrução
          importante, indicar materiais, e muito mais.
        </span>
        <label
          className="form-control d-flex my-2 border-0 pl-0"
          htmlFor="optionalText"
          data-toggle="collapse"
          data-target="#optionalText"
          aria-expanded="false"
          aria-controls="optionalText"
        >
          <span className="h6">
            Adicionar texto <span className="text-gray font-italic">(opcional)</span>
          </span>
          <ArrowDown className="ml-2" />
        </label>

        <div id="optionalText" className="collapse">
          <EditorRichText value={editorState} onChange={handleEditorStateChange} />
        </div>

        <label
          className="form-control d-flex my-2 border-0 pl-0"
          htmlFor="optionalFile"
          data-toggle="collapse"
          data-target="#optionalFile"
          aria-expanded="false"
          aria-controls="optionalFile"
        >
          <span className="h6">
            Adicionar download <span className="text-gray font-italic">(opcional)</span>
          </span>
          <ArrowDown className="ml-2" />
        </label>
        {!form.fileDownload && (
          <div id="optionalFile" className="collapse">
            <div className="form-group">
              <CustomDropzone
                file={fileDownload}
                isDisabled={isLoading}
                accept="application/pdf"
                onDrop={handleDropFileDownload}
                onDropRejected={() => setErrorMessage('Arquivo inválido!')}
                extensions="PDF"
                maxFileSize="25 MB"
              />
            </div>
          </div>
        )}
        {form.fileDownload && <PreviewDownload fileDownload={fileDownload} />}
        <div className="d-flex justify-content-between mt-4">
          <div>
            {contentId && (
              <button
                onClick={event => {
                  event.preventDefault();
                  setIsShowModal(true);
                }}
                className="btn btn-outline-danger"
                disabled={isLoading}
              >
                Excluir conteúdo
              </button>
            )}
          </div>
          <div className="d-flex align-center justify-content-end">
            <button
              type="button"
              onClick={handleDiscardChanges}
              className="btn btn-light"
              disabled={isLoading}
            >
              Descartar mudanças
            </button>

            <button type="submit" className="btn btn-primary ml-3" disabled={!changed || isLoading}>
              {isLoading ? <Spinner small /> : 'Salvar'}
            </button>
          </div>
        </div>
      </form>

      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backgroundFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="mb-1 py-4">
            <h3 className="text-center">Você tem certeza que deseja excluir o conteúdo?</h3>
          </div>

          <div className="d-flex justify-content-center mb-4">
            <button onClick={() => setIsShowModal(false)} className="btn btn-light mr-4">
              Cancelar
            </button>
            <button
              type="button"
              onClick={handleDelete}
              className="btn btn-danger"
              disabled={isLoading}
            >
              {isLoading ? <Spinner small /> : 'Excluir'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
