import { Flex, Hide, IconButton, Image, Show, VStack, Link as LinkChakra } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext';
import NotificationsDropDown from '../../ContainerSite/NotificationsDropDown';
import LoginAsUser from '../../LoginAsUser';
import MenuDropdown from '../../MenuDropdown/MenuDropdown';
import { TOKEN_ORIGIN_LOGIN } from '../../../helpers/LocalStorageHelper';
import useExternalLinkHeader from '../../../useExternalLinkHeader';

function Header({ onToggleSidebar, hidden }) {
  const { themeMarketplace } = useTheme();
  const { logo } = themeMarketplace;
  const tokenOrigin = localStorage.getItem(TOKEN_ORIGIN_LOGIN);
  const externalLink = useExternalLinkHeader();

  return (
    <VStack
      position="fixed"
      as="header"
      inset={0}
      zIndex="11"
      width="full"
      spacing={0}
      height={tokenOrigin ? '135px' : '102px'}
    >
      <LoginAsUser />

      <Flex
        width="full"
        align="center"
        justify="space-between"
        pl={3}
        pr={{ md: 5, lg: 12 }}
        background={themeMarketplace.primaryColor}
        hidden={hidden}
      >
        <Hide below="md">
          <Flex as="header" justifyContent="center" pl={12} pr={6} py={2} height={20}>
            {externalLink ? (
              <LinkChakra href={externalLink}>
                <Image height="100%" maxW={132} objectFit="contain" src={logo} alt="Logo" />
              </LinkChakra>
            ) : (
              <Link to="/">
                <Image height="100%" maxW={132} objectFit="contain" src={logo} alt="Logo" />
              </Link>
            )}
          </Flex>
        </Hide>

        <IconButton
          icon={<MdMenu size={24} color={themeMarketplace.textColor} />}
          onClick={onToggleSidebar}
          variant="unstyled"
          sx={{ outline: '0px solid transparent', boxShadow: 'none' }}
          _focus={{ boxShadow: 'none', outline: 'none' }}
          display={{ base: 'box', lg: 'none' }}
        />

        <Show below="md">
          <NotificationsDropDown mr={3} justifySelf="end" />
        </Show>

        {/* DESKTOP */}
        <Show above="md">
          <Flex align="center" py={{ base: 0, lg: 6 }}>
            <NotificationsDropDown mr={3} justifySelf="end" />

            <MenuDropdown
              userName={localStorage.getItem('username')}
              email={localStorage.getItem('email')}
            />
          </Flex>
        </Show>
      </Flex>
    </VStack>
  );
}

export default Header;
