import { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import CourseStudentProvider from '../contexts/CourseStudentContextV2';
import StudentProgressContextProvider from '../contexts/ProgressContextV2';
import NetflixMemberArea from '../pages/NetflixMemberArea';
// import ContentsView from '../pages/NetflixMemberArea/pages/ContentsView';

export default function MembersAreaNetflixRoutes() {
  const history = useHistory();
  const location = useLocation();
  const { membersArea } = useAuth();

  useEffect(() => {
    const pathname = location.pathname;

    if (membersArea === 'netflix' && pathname.includes('v2')) return;

    if (membersArea === 'netflix' && pathname.includes('v1')) {
      const newPath = pathname.replace('v1', 'v2');
      history.push(newPath);
    }
  }, [history, location.pathname, membersArea]);

  return (
    <Switch>
      <CourseStudentProvider>
        <StudentProgressContextProvider>
          <Route exact path="/members-area/v2*" component={NetflixMemberArea} />
        </StudentProgressContextProvider>
      </CourseStudentProvider>
    </Switch>
  );
}
