import { axiosBackend } from './Configuration';

export default {
  index: async () => {
    try {
      const { data: jivoChatData } = await axiosBackend().get('/jivo-chat');

      return jivoChatData;
    } catch (error) {
      throw error;
    }
  },

  post: async jivoChatCode => {
    try {
      const { data: jivoChatData } = await axiosBackend().post('/jivo-chat', jivoChatCode);

      return jivoChatData;
    } catch (error) {
      throw error;
    }
  },

  patch: async jivoChatCode => {
    try {
      const { data: jivoChatData } = await axiosBackend().patch('/jivo-chat', jivoChatCode);

      return jivoChatData;
    } catch (error) {
      throw error;
    }
  },

  delete: async () => {
    try {
      const { data: jivoChatData } = await axiosBackend().delete('/jivo-chat');

      return jivoChatData;
    } catch (error) {
      throw error;
    }
  },
};
