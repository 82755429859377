import { Select } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { IShowcase } from '../../..';
import { getStatusColorByName } from '../ShowCaseProductListItem';

interface StatusSelectProps {
  showcaseItemId: number;
  value: string;
  showcaseType: IShowcase['showcaseType'];
  onChange: (
    event: ChangeEvent<HTMLSelectElement>,
    id: number,
    showcaseType: IShowcase['showcaseType']
  ) => void;
}

export function StatusSelect({ showcaseItemId, value, onChange, showcaseType }: StatusSelectProps) {
  return (
    <Select
      fontSize="14px"
      fontWeight={400}
      focusBorderColor="transparent"
      border="none"
      width={value === 'ATIVO' ? '75px' : '90px'}
      size="xs"
      ml={-2}
      color={getStatusColorByName(value)}
      value={value}
      onChange={event => onChange(event, showcaseItemId, showcaseType)}
    >
      <option value="ATIVO">Ativo</option>
      <option value="INATIVO">Inativo</option>
    </Select>
  );
}
