import Joi from 'joi';
import { UploadQuestionPayload } from '../../types/UploadQuestionPayload';

const updateQuestionValidator = Joi.object<UploadQuestionPayload>().keys({
  questionText: Joi.string().min(3).required(),
  commentary: Joi.string().allow(''),
  areaId: Joi.number().positive().required().messages({
    'any.required': 'Selecione uma área.',
  }),
  disciplineId: Joi.number().positive().required().messages({
    'any.required': 'Selecione uma disciplina.',
  }),
  subjectId: Joi.number().positive().required().messages({
    'any.required': 'Selecione uma assunto.',
  }),
  institutionId: Joi.number().positive(),
});

export default updateQuestionValidator;
