import { Button, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import FilterSelect from './FilterSelect';
import useFilters from './useFilters';

type Filter = {
  questionLibraryAreaId: number;
  questionLibraryDisciplineId: number;
  questionLibrarySubjectId: number;
};

interface FiltersListProps {
  areaId: number;
  disciplineId: number;
  subjectId: number;
  onChange: (filter: Filter) => void;
  setHasChange: (hasChange: boolean) => void;
}

export default function FiltersList({
  areaId,
  disciplineId,
  subjectId,
  onChange,
  setHasChange,
}: FiltersListProps) {
  const {
    filtersList,
    disciplinesOptions,
    subjectsOptions,
    selectedArea,
    selectedDiscipline,
    selectedSubject,
    isFetching,
    handleAreaChange,
    handleDisciplineChange,
    handleSubjectChange,
  } = useFilters();

  useEffect(() => {
    onChange({
      questionLibraryAreaId: selectedArea?.id,
      questionLibraryDisciplineId: selectedDiscipline?.id,
      questionLibrarySubjectId: selectedSubject?.id,
    });
  }, [selectedArea, selectedDiscipline, selectedSubject, onChange]);

  return (
    <Stack direction={{ base: 'column', md: 'row' }} width="full" alignItems="flex-end">
      <Stack
        direction={{ base: 'column', md: 'row' }}
        width="full"
        alignItems="center"
        sx={{ label: { color: '#202123', fontSize: 'sm' } }}
      >
        <FilterSelect
          value={selectedArea?.id || areaId}
          label="Área"
          name="areaId"
          options={filtersList.areas}
          isDisabled={isFetching}
          onChange={handleAreaChange}
        />

        <FilterSelect
          value={selectedDiscipline?.id || disciplineId}
          label="Disciplina"
          name="disciplineId"
          options={disciplinesOptions}
          isDisabled={isFetching}
          onChange={handleDisciplineChange}
        />

        <FilterSelect
          value={selectedSubject?.id || subjectId}
          label="Assunto"
          name="subjectId"
          options={subjectsOptions}
          isDisabled={isFetching}
          onChange={handleSubjectChange}
        />
      </Stack>

      <Button
        width={{ base: 'full', md: 'auto' }}
        variant="outline"
        colorScheme="default"
        size="sm"
        fontSize="xs"
        onClick={() => {
          handleAreaChange(null);
          handleDisciplineChange(null);
          handleSubjectChange(null);
          onChange({
            questionLibraryAreaId: null,
            questionLibraryDisciplineId: null,
            questionLibrarySubjectId: null,
          });
          setHasChange(true);
        }}
      >
        Remover filtros
      </Button>
    </Stack>
  );
}
