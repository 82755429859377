import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import querystring from 'querystring';
import { platformsWithKnowMore } from '../../../App';
import PurchaseAPI from '../../../api/Purchase';
import CardSubscription from '../../../components/CardSubscription';
import Toast from '../../../components/Toast';
import { useTheme } from '../../../contexts/ThemeContext';
import ErrorResponse from '../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE, TOKEN_LOCAL_STORAGE } from '../../../helpers/LocalStorageHelper';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import useShoppingCart from '../../../hooks/useShoppingCart';
import { axiosBackend } from '../../../api/Configuration';
import { googleTagManagerIds, newEventGTM, removeEventGTM } from '../../../helpers/ga-tag-custom';
import { IS_COOKIES_ACCEPTED } from '../../../helpers/LocalStorageHelper';
import { useAuth } from '../../../contexts/AuthContext';
import FacebookIntegration from '../../../api/FacebookIntegration';

const HOSTNAME = window.location.hostname;

function getPriceText(coursePriceDefault, platformsWithKnowMore) {
  if (coursePriceDefault?.formOfPayment === 'FREE') {
    return 'Grátis';
  }

  const formattedPrice = convertToBrazilianCurrency(coursePriceDefault?.purchasePrice);

  if (platformsWithKnowMore) {
    return `ou ${formattedPrice} à vista`;
  }

  return `Por apenas ${formattedPrice}`;
}

function getInstallmentText(installmentMaxFee) {
  const formattedPrice = convertToBrazilianCurrency(installmentMaxFee?.installmentAmount);

  if (platformsWithKnowMore.includes(HOSTNAME)) {
    return `Por apenas ${installmentMaxFee.installment}x de ${formattedPrice}`;
  }

  return `${installmentMaxFee?.installment}x de ${formattedPrice}`;
}

function PurchaseButton({
  priceFree,
  productId,
  productName,
  productPrice,
  onClick,
  themeMarketplace,
  extend,
  affiliation,
  isLoadingPurchaseFree,
  launchCheckout = false,
}) {
  const {
    getShoppingCartFromLocalStorage,
    updateShoppingCartInLocalStorage,
    productExistsInShoppingCart,
    clearShoppingCart,
  } = useShoppingCart();

  const shoppingCart = getShoppingCartFromLocalStorage();
  const productsInShoppingCart = shoppingCart?.products?.map(product => product.id);
  const hasAffiliationInShoppingCart = shoppingCart?.affiliation;
  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const isDisabledButton = role && role !== 'ALUNO';

  function addToShoppingCart(hasSuccessMessage = false) {
    try {
      if (hasAffiliationInShoppingCart && productsInShoppingCart.length === 1) {
        clearShoppingCart();
      }

      if (!hasAffiliationInShoppingCart && affiliation) {
        clearShoppingCart();
      }

      updateShoppingCartInLocalStorage({ id: +productId, affiliation });

      if (hasSuccessMessage) {
        Toast('Produto adicionado ao seu carrinho!');
      }
    } catch (error) {
      Toast(error.message, 'error');
    }
  }

  async function addToShoppingCartEventGoogleTagManager() {
    if (isCookiesAccepted === 'false') return;

    const idsGTM = googleTagManagerIds();

    if (productId) {
      for (const id of idsGTM) {
        newEventGTM({
          id,
          data: {
            event: 'addToCart',
            items: [
              {
                item_id: productId,
                item_name: productName,
                value: productPrice,
                quantity: 1,
              },
            ],
          },
        });

        removeEventGTM(id, 'addToCart');
      }
    }
  }

  function handlePurchaseButtonClick() {
    if (launchCheckout) {
      return;
    }

    if (!productExistsInShoppingCart(productId, productsInShoppingCart)) {
      addToShoppingCart();
    }
  }

  async function handleAddToCartButtonClick(event) {
    event.preventDefault();

    if (!productExistsInShoppingCart(productId, productsInShoppingCart)) {
      try {
        await addToShoppingCartEventGoogleTagManager();

        await axiosBackend().post(`/conversions/${productId}/add-card`);

        addToShoppingCart({ hasSuccessMessage: true });
      } catch (error) {
        Toast(error.message, 'error');
      }
    }
  }

  if (priceFree) {
    return (
      <Button
        onClick={() => onClick()}
        minWidth="25rem"
        minHeight="3.875rem"
        fontWeight={600}
        fontSize="1.8rem"
        style={{ background: themeMarketplace.textColor }}
        isDisabled={isLoadingPurchaseFree || isDisabledButton}
      >
        <span style={{ color: themeMarketplace.primaryColor }}>Adquirir agora</span>
      </Button>
    );
  }

  let checkoutUrl = launchCheckout
    ? `/launch-checkout/${productId}/personal-data`
    : `/student-checkout/v3/sign-up`;

  if (extend) {
    checkoutUrl = checkoutUrl.concat('/extend');
  }

  if (affiliation) {
    checkoutUrl = checkoutUrl.concat('?affiliation=', affiliation, '&productId=', productId);
  }

  return (
    <VStack gap={4}>
      <Link
        to={checkoutUrl}
        onClick={handlePurchaseButtonClick}
        className="button-purchase"
        style={{ background: themeMarketplace.textColor }}
      >
        <Text
          fontSize={{ base: '2xl', lg: '3xl' }}
          fontWeight="semibold"
          color={themeMarketplace.primaryColor}
        >
          Comprar agora
        </Text>
      </Link>

      <Link
        hidden={affiliation || hasAffiliationInShoppingCart || launchCheckout}
        to={checkoutUrl}
        onClick={handleAddToCartButtonClick}
        className="button-purchase"
        style={{
          border: `1px solid ${themeMarketplace.textColor}`,
          pointerEvents: productExistsInShoppingCart(+productId, productsInShoppingCart) && 'none',
        }}
      >
        {extend ? (
          <Text
            fontSize={{ base: '2xl', lg: '3xl' }}
            fontWeight="semibold"
            color={themeMarketplace.textColor}
          >
            Estender Acesso
          </Text>
        ) : (
          <Text
            fontSize={{ base: '2xl', lg: '3xl' }}
            fontWeight="semibold"
            color={themeMarketplace.textColor}
          >
            {`${
              productExistsInShoppingCart(productId, productsInShoppingCart)
                ? 'Adicionado ao carrinho'
                : 'Adicionar ao carrinho'
            }`}
          </Text>
        )}
      </Link>
    </VStack>
  );
}

function Purchase({ course, id }) {
  const { coursePriceDefault, baseAmountFeesAndCommissions, standardPaymentValue, name } = course;

  const installmentMaxFee = baseAmountFeesAndCommissions?.[baseAmountFeesAndCommissions.length - 1];

  const launchCheckout = course.checkoutType === 'LAUNCH';

  const { themeMarketplace } = useTheme();

  const history = useHistory();

  const { membersArea } = useAuth();

  const routeMatch = useRouteMatch();

  const [extend, setExtend] = useState(false);

  const [isLoadingPurchaseFree, setIsLoadingPurchaseFree] = useState(false);

  const [affiliation, setAffiliation] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const params = useParams();

  const platformWithKnowMore = platformsWithKnowMore.includes(HOSTNAME);

  const mustShowPriceInstallments =
    platformWithKnowMore &&
    coursePriceDefault?.formOfPayment !== 'FREE' &&
    installmentMaxFee?.installment > 1;

  useEffect(() => {
    setExtend(routeMatch.url.match(/extend/g));

    const query = querystring.parse(window.location.search, '?');

    setAffiliation(query.affiliation);

    window.scrollTo(0, 0);
  }, [params, routeMatch]);

  async function registersFreeProductPurchasePixelEvent() {
    const { data } = await FacebookIntegration.getPixelByProduct(id, affiliation);

    if (data.length) {
      // Criação do script do Facebook Pixel
      const facebookPixelScript = document.createElement('script');

      const pixelId = data[0].identifier;

      facebookPixelScript.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', ${pixelId});
    fbq('track', 'PageView');
  `;

      // Adiciona o script ao final do body
      document.body.appendChild(facebookPixelScript);

      window.fbq('track', 'Purchase', {
        value: 0,
        currency: 'BRL',
        content_ids: [`${id}`],
      });

      // Remove o script após a execução
      document.body.removeChild(facebookPixelScript);
    }
  }

  async function acquireProduct() {
    try {
      setIsLoadingPurchaseFree(true);

      const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

      await registersFreeProductPurchasePixelEvent();

      if (!token) {
        return history.push(`/cadastre-se?from=product&productId=${id}&priceFree=true`);
      }

      await PurchaseAPI.store.purchaseFreeProduct(id);

      await registersFreeProductPurchasePixelEvent();

      const hasNetflixMemberArea = membersArea === 'netflix';

      const memberAreaPathname = hasNetflixMemberArea
        ? '/members-area/v2'
        : '/members-area/v1/products';

      return history.push(memberAreaPathname);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoadingPurchaseFree(false);
    }
  }

  return (
    <>
      {course.subscription ? (
        <Box bg={themeMarketplace.primaryColor}>
          <Heading mt={5} color={themeMarketplace.textColor} textAlign="center">
            Planos de Assinatura
          </Heading>
          <Box display="flex" justifyContent="center" flexWrap="wrap">
            {course.plans.length
              ? course.plans.map(plan => (
                  <CardSubscription
                    key={id}
                    courseId={id}
                    plan={plan}
                    modules={course.modules.length}
                    course={course}
                  />
                ))
              : ''}
          </Box>
          {errorMessage && (
            <div className="mt-4 d-flex justify-content-center">
              <div
                className="alert alert-danger"
                role="alert"
                style={{ borderRadius: '0', wordBreak: 'break-all' }}
              >
                <div className="text-center">{errorMessage}</div>
              </div>
            </div>
          )}
        </Box>
      ) : (
        <section
          className="section-content-purchase"
          style={{ background: themeMarketplace.primaryColor }}
        >
          <div className="container-fluid">
            <div className="row justify-content-center align-items-center flex-column">
              <div className="container-purchase">
                <Box fontSize="3rem" lineHeight="1.1" color={themeMarketplace.textColor}>
                  {mustShowPriceInstallments && (
                    <Text fontWeight="700" textAlign="center" fontSize="3rem">
                      {getInstallmentText(installmentMaxFee)}
                    </Text>
                  )}
                  <Text
                    textAlign="center"
                    fontWeight={platformWithKnowMore ? '500' : '700'}
                    fontSize={platformWithKnowMore ? '2.25rem' : '3rem'}
                  >
                    {getPriceText(coursePriceDefault, platformWithKnowMore)}
                  </Text>
                </Box>
              </div>

              <div className="container-purchase-button">
                <PurchaseButton
                  extend={extend}
                  affiliation={affiliation}
                  onClick={acquireProduct}
                  priceFree={coursePriceDefault?.formOfPayment === 'FREE'}
                  productId={id}
                  productName={name}
                  productPrice={standardPaymentValue}
                  themeMarketplace={themeMarketplace}
                  isLoadingPurchaseFree={isLoadingPurchaseFree}
                  launchCheckout={launchCheckout}
                />
              </div>
            </div>
          </div>

          {errorMessage && (
            <div className="mt-4 d-flex justify-content-center">
              <div
                className="alert alert-danger"
                role="alert"
                style={{ borderRadius: '0', wordBreak: 'break-all' }}
              >
                <div className="text-center">{errorMessage}</div>
              </div>
            </div>
          )}
        </section>
      )}
    </>
  );
}

export default Purchase;
