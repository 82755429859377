import { ChangeEvent, useRef, useState } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { FaCirclePause, FaCirclePlay } from 'react-icons/fa6';
import { MdForward10, MdReplay10 } from 'react-icons/md';
import PlaybackRateSelect from './PlaybackRateSelect';
import './styles.css';
import { useBreakpointValue } from '@chakra-ui/react';

function Player({ src, highlightColor }) {
  const playerRef = useRef(null);
  const [value, setValue] = useState('1');

  const isMobile = useBreakpointValue({ base: true, lg: false });

  function handleChangePlaybackRate(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    playerRef.current.audio.current.playbackRate = value;

    setValue(value);
  }

  return (
    <AudioPlayer
      src={src}
      autoPlay={false}
      style={{ background: 'transparent', padding: 0 }}
      layout="stacked"
      header={
        !isMobile && (
          <PlaybackRateSelect
            value={value}
            highlightColor={highlightColor}
            onChange={handleChangePlaybackRate}
          />
        )
      }
      footer={
        isMobile && (
          <PlaybackRateSelect
            value={value}
            highlightColor={highlightColor}
            onChange={handleChangePlaybackRate}
          />
        )
      }
      customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
      customAdditionalControls={[RHAP_UI.CURRENT_TIME]}
      customVolumeControls={[RHAP_UI.DURATION]}
      customIcons={{
        forward: <MdForward10 color="#FFF" />,
        rewind: <MdReplay10 color="#FFF" />,
        play: <FaCirclePlay color={highlightColor} />,
        pause: <FaCirclePause color={highlightColor} />,
      }}
      progressJumpSteps={{ backward: 10000, forward: 10000 }}
      ref={playerRef}
    />
  );
}

export default Player;
