import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';

interface IProps extends TooltipProps {
  children: JSX.Element;
}

export default function Tooltip({ children, ...props }: IProps) {
  return (
    <ChakraTooltip
      fontSize="sm"
      p={8}
      borderRadius="lg"
      color="gray.500"
      bg="white"
      hasArrow
      label="O seu carrinho está vazio"
      openDelay={500}
      closeDelay={500}
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
}
