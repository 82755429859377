import { Center, CenterProps, Spinner } from '@chakra-ui/react';

interface ImageUploadLoadingProps extends CenterProps {
  isLoading: boolean;
}

export default function ImageUploadLoading({ isLoading, ...rest }: ImageUploadLoadingProps) {
  return (
    <Center
      inset={0}
      display={isLoading ? 'flex' : 'none'}
      zIndex={999}
      overflow="hidden"
      background="hsla(0,0%,100%,0.75)"
      cursor="not-allowed"
      {...rest}
    >
      <Spinner size="xl" color="primary.500" />
    </Center>
  );
}
