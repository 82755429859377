import cademiLogo from '../../assets/images/integrations/cademi.svg';
import facebookLogo from '../../assets/images/integrations/facebook.svg';
import jivoLogo from '../../assets/images/integrations/jivo-chat.svg';
import rdStationLogo from '../../assets/images/integrations/rd-station.svg';
import zoomLogo from '../../assets/images/integrations/zoom.svg';
import googleTagManagerLogo from '../../assets/images/integrations/google-tag-manager.svg';
import activeCampaignLogo from '../../assets/images/integrations/active-campaign.svg';
import eNotasLogo from '../../assets/images/integrations/eNotas.svg';
import hotzappLogo from '../../assets/images/integrations/hotzapp.svg';

export default [
  {
    id: 1,
    name: 'zoom',
    logo: zoomLogo,
    category: 'learning',
    categoryTitle: 'Aprendizado',
    title: 'Zoom',
    description:
      'Crie aulas ao vivo por meio da maior e melhor ferramenta de webinar e reuniões do mundo.',
    isImplemented: true,
    path: 'zoom',
  },
  {
    id: 2,
    name: 'facebook',
    logo: facebookLogo,
    category: 'campaignAd',
    categoryTitle: 'Anúncio de Campanhas',
    title: 'Facebook',
    description:
      'Metrifique, otimize e influencie a criação de púbicos focados para as suas campanhas.',
    isImplemented: true,
    path: 'facebook',
  },
  {
    id: 3,
    name: 'rdStation',
    logo: rdStationLogo,
    category: 'marketing',
    categoryTitle: 'Marketing Digital',
    title: 'RD Station',
    description:
      'Ferramenta de e-mail marketing que irá ajudar você a engajar ainda mais seus alunos.',
    isImplemented: true,
    path: 'rd-station',
  },
  {
    id: 4,
    name: 'activeCampaign',
    logo: activeCampaignLogo,
    category: 'marketing',
    categoryTitle: 'Marketing Digital',
    title: 'Active Campaign',
    description:
      'Ferramenta de e-mail marketing que irá ajudar você a engajar ainda mais seus alunos.',
    isImplemented: true,
    path: 'active-campaign',
  },
  {
    id: 5,
    name: 'jivoChat',
    logo: jivoLogo,
    category: 'other',
    title: 'Jivo Chat',
    categoryTitle: 'Outros',
    description:
      'Não deixe seu clientes esperando, ative esse sistema de mensagem e conecte-se diretamente com todos.',
    isImplemented: true,
    path: 'jivo-chat',
  },
  {
    id: 6,
    name: 'cademi',
    logo: cademiLogo,
    category: 'learning',
    categoryTitle: 'Aprendizado',
    title: 'Cademí',
    description: 'Utilize uma área de membros mais estilizada para os seus alunos.',
    isImplemented: true,
    path: 'cademi',
  },
  {
    id: 7,
    name: 'googleTagManager',
    logo: googleTagManagerLogo,
    category: 'campaignAd',
    categoryTitle: 'Anúncio de Campanhas',
    title: 'Google Tag Manager',
    description:
      'Adicione e atualizar facilmente tags, como códigos de rastreamento de análise, pixels de remarketing e tags de conversões, sem depender de desenvolvedores.',
    isImplemented: true,
    path: 'google-tag-manager',
  },
  {
    id: 8,
    logo: eNotasLogo,
    name: 'enotas',
    category: 'other',
    categoryTitle: 'Outros',
    title: 'eNotas',
    description: 'Serviço do eNotas de emissão de notas de fiscais.',
    isImplemented: false,
    path: 'e-notas',
  },
  {
    id: 9,
    name: 'hotzapp',
    logo: hotzappLogo,
    category: 'other',
    categoryTitle: 'Outros',
    title: 'Hotzapp',
    isImplemented: false,
    path: 'hotzapp',
  },
];
