import { Box } from '@chakra-ui/react';
import styles from './styles.module.css';

interface ICardProps {
  title?: string;
  data?: string | number;
  color?: string;
  gridArea?: string;
}

function Card({ title, data, color, gridArea }: ICardProps) {
  return (
    <Box
      className={styles.container}
      style={{ color, gridArea }}
      mb={5}
      mx={{ base: '0', md: '2', lg: '2' }}
    >
      <span className={styles.title}>{title}</span>
      <span className={styles.data}>{data}</span>
    </Box>
  );
}

export default Card;
