import {
  Alert,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
  Link as ChakraLink,
} from '@chakra-ui/react';

import { ChangeEvent, useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Authentication from '../../../../api/Authentication';
import FacebookIntegration from '../../../../api/FacebookIntegration';
import PurchaseAPI from '../../../../api/Purchase';
import { ModalAlertMultipleSessions } from '../../../../components/ModalAlertMultipleSessions';
import Toast from '../../../../components/Toast';
import { useTheme } from '../../../../contexts/ThemeContext';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import {
  SUBSCRIPTION_LOCAL_STORAGE,
  TOKEN_LOCAL_STORAGE,
} from '../../../../helpers/LocalStorageHelper';
import useQuery from '../../../../hooks/useQuery';
import BlockModal from '../BlockModal';
import SubscriptionAPI from '../../../../api/Subscription';
import useExternalLinkHeader from '../../../../useExternalLinkHeader';

type UserLoginType = {
  id: number;
  fullName: string;
  email: string;
  role: string;
  photo: string;
  token: string;
  memberArea: string;
};

export default function SignInV2() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [redirect, setRedirect] = useState('');
  const [onOpenModal, setOnOpenModal] = useState(false);

  const {
    isOpen: isBlockModalOpen,
    onOpen: onBlockModalOpen,
    onClose: onBlockModalClose,
  } = useDisclosure();

  const externalLink = useExternalLinkHeader();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { themeMarketplace } = useTheme();
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const searchParams = queryParams.toString();
  const query = useQuery();
  const from = query.get('from');
  const priceFree = query.get('priceFree');
  const productId = query.get('productId');
  const affiliation = query.get('affiliation');
  const couponName = query.get('coupon');
  const token = query.get('token') || localStorage.getItem(TOKEN_LOCAL_STORAGE);
  const path = query.get('path');
  const email = query.get('email');

  const [form, setForm] = useState({
    email,
    password: '',
  });

  const handleClick = () => setShow(!show);

  useEffect(() => {
    const session_expire = query.get('session_expire');

    if (session_expire?.match(/true/)) {
      Toast('Sua sessão expirou', 'error');

      history.push('/login');
    }
  }, [history, query]);

  useEffect(() => {
    const multiple_sessions = query.get('multiple_sessions');

    if (multiple_sessions?.match(/true/)) {
      setOnOpenModal(true);
    }
  }, [onOpen, query]);

  useEffect(() => {
    if (onOpenModal) {
      onOpen();
    }
  }, [onOpen, onOpenModal]);

  useEffect(() => {
    async function logInAutomatically(token: string) {
      const authenticationData = await Authentication.validateToken(token);

      if (authenticationData) {
        const { data: authentication } = authenticationData;

        if (authentication.isValidToken) {
          if (productId) {
            const userData = {
              id: authentication.id,
              token: authentication.token,
              fullName: authentication.fullName,
              role: authentication.role,
              photo: authentication.photo,
              email: authentication.email,
              memberArea: authentication.memberArea,
            };

            redirectAfterLogin(userData);
          }

          if (path) {
            return history.push(path);
          }

          if (authentication.role === 'ALUNO') {
            const isDashboardV2 =
              authentication.memberArea === 'netflix' || location.pathname.includes('/v2');

            if (isDashboardV2) {
              return history.push(`/members-area/v2`);
            }

            return history.push(`/members-area/v1/products`);
          }

          return history.push('/');
        }
      }
    }

    if (token) {
      logInAutomatically(token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function registersFreeProductPurchasePixelEvent() {
    const { data } = await FacebookIntegration.getPixelByProduct(Number(productId), affiliation);

    if (data.length) {
      // Criação do script do Facebook Pixel
      const facebookPixelScript = document.createElement('script');

      const pixelId = data[0].identifier;

      facebookPixelScript.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', ${pixelId});
    fbq('track', 'PageView');
  `;

      // Adiciona o script ao final do body
      document.body.appendChild(facebookPixelScript);

      window.fbq('track', 'Purchase', {
        value: 0,
        currency: 'BRL',
        content_ids: [`${productId}`],
      });

      // Remove o script após a execução
      document.body.removeChild(facebookPixelScript);
    }
  }

  async function redirectAfterLogin(userLogin: UserLoginType) {
    const affiliationId = affiliation ? `?affiliation=${affiliation}` : '';
    const hasNetflixMemberArea = userLogin.memberArea === 'netflix';
    const memberAreaPathname = hasNetflixMemberArea
      ? '/members-area/v2'
      : '/members-area/v1/products';

    switch (from) {
      case 'student-checkout':
        if (couponName) {
          return history.push(
            `product/${productId}/${from}/payment/credit-card/?coupon=${couponName}${
              affiliationId ? affiliation.replace('?', '&') : ''
            }`
          );
        }

        return history.push(`product/${productId}/${from}/payment/credit-card/${affiliationId}`);
      case 'product':
        if (priceFree) {
          try {
            await PurchaseAPI.store.purchaseFreeProduct(productId);
            await registersFreeProductPurchasePixelEvent();

            return history.push(memberAreaPathname);
          } catch (error) {
            toast({
              title: 'Não foi possível adquirir o produto.',
              description: ErrorResponse(error),
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        }
        return history.push(`${from}/${productId}${affiliationId}`);
      default:
        if (userLogin.role === 'ALUNO') {
          return history.push(memberAreaPathname);
        }

        if (userLogin.role === 'INSTRUTOR') {
          history.push('/products');

          return;
        }

        if (userLogin.role === 'AFILIADO') {
          history.push('/dashboard/invoicing');

          return;
        }

        history.push('/home');

        break;
    }
  }

  async function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(event: ChangeEvent<HTMLDivElement>) {
    event.preventDefault();

    try {
      setLoading(true);

      const { data: userLogin } = await Authentication.login(form.email, form.password);

      const result = await Authentication.validateToken(userLogin.token);

      const isOwner = result?.data?.role === 'DONO';

      if (isOwner) {
        const { data: subscriptionStatus } = await SubscriptionAPI.show();

        if (subscriptionStatus) {
          localStorage.setItem(SUBSCRIPTION_LOCAL_STORAGE, JSON.stringify(subscriptionStatus));
        }
      }

      const userData = {
        ...userLogin,
        memberArea: result?.data?.memberArea,
      };

      redirectAfterLogin(userData);
    } catch (error) {
      const errorResponse = error?.response?.data;

      if (errorResponse?.message === 'Plataforma Inativa') {
        return onBlockModalOpen();
      }

      setRedirect(errorResponse?.data?.link);
      setMessage(errorResponse?.message);
      Toast(ErrorResponse(errorResponse), 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Flex
      height="full"
      justifyContent="center"
      alignItems="center"
      bgGradient="linear(to-r, primary.500, primary.200)"
      color="secondary.500"
      overflowY="auto"
      paddingY="8"
      padding={{ base: 16, md: 'initial' }}
    >
      <Stack
        spacing={7}
        display="flex"
        justifyContent="center"
        width="26rem"
        px={8}
        py={6}
        as="form"
        onSubmit={handleSubmit}
      >
        <Flex justifyContent="center">
          {externalLink ? (
            <ChakraLink href={externalLink} style={{ margin: 0 }}>
              <Image src={themeMarketplace?.logo} maxWidth="264px" />
            </ChakraLink>
          ) : (
            <Link to="/">
              <Image src={themeMarketplace?.logo} maxWidth="264px" />
            </Link>
          )}
        </Flex>

        <FormControl>
          <FormLabel htmlFor="email" color="textColor">
            Email
          </FormLabel>
          <Input
            id="email"
            type="email"
            name="email"
            value={form.email}
            placeholder="Insira seu e-mail"
            _placeholder={{ color: 'secondary.600' }}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="password" color="textColor">
            Senha
          </FormLabel>
          <InputGroup size="md">
            <Input
              id="password"
              name="password"
              pr="4.5rem"
              type={show ? 'text' : 'password'}
              placeholder="Insira sua senha"
              _placeholder={{ color: 'secondary.600' }}
              onChange={handleChange}
              disabled={loading}
              required
            />
            <InputRightElement
              onClick={handleClick}
              children={show ? <AiFillEye /> : <AiFillEyeInvisible />}
            />
          </InputGroup>
        </FormControl>
        <VStack>
          <Button
            colorScheme="secondary"
            color="primary.500"
            isLoading={loading}
            type="submit"
            width="full"
          >
            Entrar
          </Button>
          <Alert hidden={!!!redirect} variant="danger" style={{ fontWeight: 'normal' }}>
            <Box
              w="100%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              bg="primary.600"
              p={5}
              borderRadius={5}
              color="red"
            >
              <Text>{message}</Text>
              {redirect && (
                <Text>
                  <Link to={redirect} style={{ textDecoration: 'none' }}>
                    <Text as="u" fontWeight="bold" mr={1}>
                      Clique aqui
                    </Text>
                  </Link>
                  para atualizar sua senha.
                </Text>
              )}
            </Box>
          </Alert>
        </VStack>
        <Text textAlign="center" fontWeight="bold" color="textColor">
          <Link to="/reset-password" style={{ pointerEvents: loading ? 'none' : 'all' }}>
            Esqueceu a senha?
          </Link>
        </Text>

        <Text textAlign="center" color="textColor">
          Não possui cadastro?{' '}
          <Link
            to={`/cadastre-se?${searchParams}`}
            style={{
              textDecoration: 'underline',
              fontWeight: 'bold',
              pointerEvents: loading ? 'none' : 'all',
            }}
          >
            Criar uma conta
          </Link>
        </Text>
      </Stack>

      <ModalAlertMultipleSessions isOpen={isOpen} onClose={onClose} />
      <BlockModal isOpen={isBlockModalOpen} onClose={onBlockModalClose} />
    </Flex>
  );
}
