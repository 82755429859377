import locales from '../helpers/Locales';

const stateList = locales.getStates();
const sortedStateList = stateList.sort((a, b) => a.nome.localeCompare(b.nome));

export default function useLocales(uf: string) {
  const stateId = stateList.find(state => state.sigla?.toLowerCase() === uf?.toLowerCase())?.id;
  const cities = locales.getCities(stateId);
  const sortedCities = cities.sort((a, b) => a.nome.localeCompare(b.nome));

  return { cities: sortedCities, states: sortedStateList };
}
