import { Select, SelectProps } from '@chakra-ui/react';
import { memo } from 'react';
import useLocales from '../../../../hooks/useLocales';

interface CitySelectProps extends SelectProps {
  state: string;
  value: string;
}

function CitySelect({ state, value, ...rest }: CitySelectProps) {
  const { cities } = useLocales(state ?? '');

  return (
    <Select
      name="city"
      focusBorderColor="gray.300"
      borderRadius={6}
      placeholder="Selecione uma Cidade"
      value={value}
      {...rest}
    >
      {cities.map(city => (
        <option key={city.id} value={city.sigla}>
          {city.nome}
        </option>
      ))}
    </Select>
  );
}

export default memo(CitySelect);
