import { Icon, IconProps } from '@chakra-ui/react';

export default function UpArrowIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 7 10" {...props}>
      <path
        d="M4.08333 10L4.08333 2.12778L6.1775 4.11667L7 3.33333L3.5 -1.5299e-07L-6.22542e-07 3.33333L0.822499 4.11667L2.91667 2.12778L2.91667 10L4.08333 10Z"
        fill="currentColor"
      />
    </Icon>
  );
}
