import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Toast,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MdOutlineFilterAlt } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import Container from '../../../../components/ContainerSite/ContainerSite';
import CookieBanner from '../../../../components/CookieBanner';
import Paginate from '../../../../components/Paginate';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import useFetch from '../../../../hooks/useFetch';
import {
  IQuestionLibraryAreaData,
  IQuestionLibraryAreaHasPaginateResult,
  IQuestionLibraryDisciplineData,
  IQuestionLibraryDisciplineHasPaginateResult,
  IQuestionLibrarySubjectData,
  IQuestionLibrarySubjectHasPaginateResult,
} from '../../../DashboardAdmin/Courses/CourseManager/ModulesManager/QuestionLibrary/types';
import Filter from './components/Filter';
import CardInstructs from './components/CardInstructs';

type FilterNameType = 'area' | 'discipline' | 'subject' | 'quantityOfProducts' | 'orderBy';

export interface IFilterParams {
  id: string;
  name: FilterNameType;
  displayName: string;
  isChecked: boolean;
}
export interface FilterProperties {
  id: string;
  name: string;
  displayName: string;
}

export interface IFilter {
  area: FilterProperties[];
  discipline: FilterProperties[];
  subject: FilterProperties[];
  quantityOfProducts: FilterProperties[];
}

interface IQueryFilters {
  area: string[];
  discipline: string[];
  subject: string[];
  quantityOfProducts: string[];
}

export async function populateFiltersByQueryInstructor(
  queryFilters: IQueryFilters,
  setFilters: React.Dispatch<React.SetStateAction<IFilter>>,
  availableAreas: IQuestionLibraryAreaData[],
  availableDisciplines: IQuestionLibraryDisciplineData[],
  availableSubjects: IQuestionLibrarySubjectData[]
) {
  // Função auxiliar para adicionar itens a um filtro específico, apenas se não existir
  const addToFilter = (filterName: string, itemId: string, itemName: string) => {
    setFilters(prevState => {
      // Verifique se o filtro já existe no estado
      if (!prevState[filterName]?.some(item => item.id === itemId)) {
        return {
          ...prevState,
          [filterName]: [
            ...(prevState[filterName] || []),
            {
              id: itemId,
              name: filterName,
              displayName: itemName,
            },
          ],
        };
      }
      return prevState; // Retorna o estado inalterado se o filtro já existir
    });
  };

  // Processar filtro de áreas
  for (const areaQuery of queryFilters.area) {
    const foundArea = availableAreas?.find(area => area.id === parseInt(areaQuery));
    if (foundArea) {
      addToFilter('area', foundArea.id.toString(), foundArea.name);
    }
  }

  // Processar filtro de disciplinas
  for (const disciplineQuery of queryFilters.discipline) {
    const foundDiscipline = availableDisciplines?.find(
      discipline => discipline.id === parseInt(disciplineQuery)
    );
    if (foundDiscipline) {
      addToFilter('discipline', foundDiscipline.id.toString(), foundDiscipline.name);
    }
  }

  // Processar filtro de assuntos
  for (const subjectQuery of queryFilters.subject) {
    const foundSubject = availableSubjects?.find(subject => subject.id === parseInt(subjectQuery));
    if (foundSubject) {
      addToFilter('subject', foundSubject.id.toString(), foundSubject.name);
    }
  }

  // Processar filtro de por quantidade de produtos
  for (const quantityOfProductsQuery of queryFilters.quantityOfProducts) {
    let displayName = '';

    switch (quantityOfProductsQuery) {
      case JSON.stringify({ gte: 0, lte: 3 }):
        displayName = 'Até 3 cursos';
        break;
      case JSON.stringify({ gte: 3, lte: 5 }):
        displayName = '3 a 5 cursos';
        break;
      case JSON.stringify({ gte: 5, lte: 7 }):
        displayName = '5 a 7 cursos';
        break;
      case JSON.stringify({ gte: 7, lte: 9 }):
        displayName = '7 a 9 cursos';
        break;
      case JSON.stringify({ gte: 9, lte: null }):
        displayName = 'Acima de 9';
        break;
      default:
        displayName = '';
    }
    addToFilter('quantityOfProducts', quantityOfProductsQuery, displayName);
  }
}

export default function Instructors() {
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false, xl: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const refreshURLSearchParams = () => new URLSearchParams(location.search);
  const urlSearchParams = useMemo(refreshURLSearchParams, [location.search]);
  const searchParams = urlSearchParams.toString();

  const queryFilters = useMemo(() => {
    return {
      area: JSON.parse(urlSearchParams.get('area')) || [],
      discipline: JSON.parse(urlSearchParams.get('discipline')) || [],
      subject: JSON.parse(urlSearchParams.get('subject')) || [],
      quantityOfProducts: JSON.parse(urlSearchParams.get('quantityOfProducts')) || [],
    };
  }, [urlSearchParams]);

  const [filters, setFilters] = useState<IFilter>({
    area: [],
    discipline: [],
    subject: [],
    quantityOfProducts: [],
  });

  const hasFilters = !!(
    filters.area.length ||
    filters.discipline.length ||
    filters.subject.length ||
    filters.quantityOfProducts.length
  );

  const {
    data: instructorsData,
    loading: isFetching,
    error,
  } = useFetch<UnificadaFront.ResponseJSON<any>>({
    url: `/users/responsible-profiles?${searchParams}`,
    method: 'get',
    autoFetch: true,
    authenticated: false,
  });

  const {
    data: areaData,
    loading: isLoadingAreaData,
    error: questionLibraryAreaError,
  } = useFetch<UnificadaFront.ResponseJSON<IQuestionLibraryAreaHasPaginateResult>>({
    method: 'get',
    url: `/filter/home/question-library-area?hasPaginate=false&courseWithInstructors=true`,
    autoFetch: true,
  });

  useEffect(() => {
    if (questionLibraryAreaError) {
      Toast(ErrorResponse(questionLibraryAreaError), 'error');
    }
  }, [questionLibraryAreaError]);

  const {
    data: disciplineData,
    loading: isLoadingDisciplineData,
    error: questionLibraryDisciplineError,
  } = useFetch<UnificadaFront.ResponseJSON<IQuestionLibraryDisciplineHasPaginateResult>>({
    method: 'get',
    url: `/filter/home/question-library-discipline?hasPaginate=false&courseWithInstructors=true`,
    autoFetch: true,
  });

  useEffect(() => {
    if (questionLibraryDisciplineError) {
      Toast(ErrorResponse(questionLibraryDisciplineError), 'error');
    }
  }, [questionLibraryDisciplineError]);

  const {
    data: subjectData,
    loading: isLoadingSubjectData,
    error: questionLibrarySubjectError,
  } = useFetch<UnificadaFront.ResponseJSON<IQuestionLibrarySubjectHasPaginateResult>>({
    method: 'get',
    url: `/filter/home/question-library-subject?hasPaginate=false&courseWithInstructors=true`,
    autoFetch: true,
  });

  useEffect(() => {
    if (questionLibrarySubjectError) {
      Toast(ErrorResponse(questionLibrarySubjectError), 'error');
    }
  }, [questionLibrarySubjectError]);

  const response = instructorsData?.data;
  const instructors = response?.instructor;
  const total = response?.total || 0;
  const perPage = response?.limit || 1;

  const isLoading = isFetching;
  const isInstructorsVisible = !isLoading && instructors?.length;

  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const category = urlSearchParams.get('category');
  const search = urlSearchParams.get('search');
  const isSearching = !!searchParams;
  const showNameInstructors =
    Object.values(filters).every(filter => filter.length === 0) && !category && !search;
  const isNotFoundVisible = !isLoading && !instructors?.length && isSearching;
  const isEmpty = !isLoading && !instructors?.length && !isSearching;
  const pageCount = Math.ceil(total / perPage);
  const shouldShowPagination = !isLoading && pageCount > 1;

  const areas = areaData?.data?.data;
  const disciplines = disciplineData?.data?.data;
  const subjects = subjectData?.data?.data;
  const isLoadingFilters = isLoadingAreaData || isLoadingDisciplineData || isLoadingSubjectData;
  const loadedAllFilters =
    (areas?.length > 0 && disciplines?.length > 0 && subjects?.length > 0 && !!queryFilters) ||
    false;

  useEffect(() => {
    if (error) {
      toast({
        title: 'Erro ao buscar instrutores',
        description: ErrorResponse(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }));

  useEffect(() => {
    if (!isSearching || search) {
      setFilters({ area: [], discipline: [], subject: [], quantityOfProducts: [] });
    }
  }, [isSearching, search]);

  // Converter cada array em uma string e juntá-los com '+'
  const searchFilter = Object.values(filters)
    .map(filter => {
      if (filter.length > 0) {
        return filter.map((name: { displayName: string }) => name.displayName).join(' + ');
      }
      return '';
    })
    .filter(str => str !== '')
    .join(' + ');

  const hiddenResults = !searchFilter && !category && !search;

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({
      search: urlSearchParams.toString(),
    });
  }

  const handleCheckboxFilter = ({ id, name, displayName, isChecked }: IFilterParams) => {
    urlSearchParams.set('page', '1');

    if (name) {
      const findFilterId = filters?.[name]?.find(filter => filter.id === id);

      const existingValues = urlSearchParams.get(name);

      if (!isChecked && !findFilterId) {
        if (existingValues) {
          // Se existirem valores, converta-os de volta em um array
          const existingArray = JSON.parse(existingValues);

          // Adicione o novo valor ao array existente, se ele ainda não estiver presente
          if (!existingArray.includes(id)) {
            existingArray.push(id);
          }

          // Defina o parâmetro da consulta com o array atualizado
          urlSearchParams.set(name, JSON.stringify(existingArray));
        } else {
          // Se não houver valores existentes, crie um novo array com o valor
          urlSearchParams.set(name, JSON.stringify([id]));
        }

        setFilters(prevState => {
          return {
            ...prevState,
            [name]: [...(prevState[name] || []), { id, name, displayName }],
          };
        });
      } else {
        const existingArray = JSON.parse(existingValues);

        // Verifique se o valor a ser removido está presente no array
        const indexToRemove = existingArray.indexOf(id);

        if (indexToRemove !== -1) {
          // O valor está no array, remova-o
          existingArray.splice(indexToRemove, 1);
        }

        if (existingArray.length === 0) {
          // Se o array estiver vazio, remova a chave do parâmetro de consulta
          urlSearchParams.delete(name);
        } else {
          // Caso contrário, atualize o parâmetro de consulta com o array modificado
          urlSearchParams.set(name, JSON.stringify(existingArray));
        }

        const removeFilter = filters?.[name]?.filter(filter => filter.id !== id);
        setFilters(prevState => ({ ...prevState, [name]: removeFilter }));
      }

      history.push({
        search: urlSearchParams.toString(),
      });
    }
  };

  useEffect(() => {
    if (loadedAllFilters) {
      populateFiltersByQueryInstructor(queryFilters, setFilters, areas, disciplines, subjects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedAllFilters, queryFilters]);

  return (
    <Container>
      <Box
        marginTop={{ base: '70px', md: '70px', lg: '100px' }}
        marginX={{ base: '15px', md: '40px', lg: '108px' }}
        id="Instructors"
      >
        <Stack
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          spacing={{ base: '5px', md: 'auto', lg: 'auto' }}
          justifyContent={{ base: 'flex-start', md: 'space-between', lg: 'space-between' }}
          margin={{
            base: '30px 0 15px 0',
            md: '20px 0 20px 0',
            lg: '40px 0 40px 0',
          }}
          alignItems={{ base: 'flex-start', md: 'center', lg: 'center' }}
        >
          <Text
            color="#202123"
            fontWeight="500"
            fontSize={{ base: '20px', md: '24px', lg: '24px' }}
            lineHeight={{ base: '20px', md: '40px', lg: '40px' }}
            hidden={!showNameInstructors}
          >
            Instrutores
          </Text>

          <Text
            color="#202123"
            fontSize={{ base: '20px', md: '24px', lg: '24px' }}
            fontWeight="400"
            lineHeight={{ base: '20px', md: '40px', lg: '40px' }}
            width="full"
            isTruncated
            hidden={hiddenResults}
          >
            Resultado para:{' '}
            <Text as="span" fontWeight="500">
              {category ? `"${category}"` : search ? `"${search}"` : ''}{' '}
              {searchFilter ? `"${searchFilter}"` : ''}
            </Text>
          </Text>

          <Text
            color="#202123"
            fontSize={{ base: '14px', md: '16px', lg: '16px' }}
            fontWeight="500"
            lineHeight={{ base: '20px', md: '40px', lg: '40px' }}
          >
            {total} {total === 1 ? 'resultado' : 'resultados'}
          </Text>
        </Stack>

        {isMobile && (
          <Stack direction="row" width="full" spacing={0} justifyContent="flex-end">
            <Button
              size="sm"
              ref={btnRef}
              variant="outline"
              bgColor="#FFF"
              borderColor="#8F8F90"
              _selected={{ bgColor: '#8F8F9050' }}
              color="#8F8F90"
              onClick={onOpen}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Text>Filtros</Text>
                <MdOutlineFilterAlt size="16px" color="#8F8F90" />
              </Stack>
            </Button>
          </Stack>
        )}

        <Filter
          filters={filters}
          handleCheckboxFilter={handleCheckboxFilter}
          areas={areas}
          disciplines={disciplines}
          subjects={subjects}
          isLoading={isLoadingFilters}
          btnRef={btnRef}
          isOpen={isOpen}
          onClose={onClose}
          setFilters={setFilters}
          total={total}
          drawer
        />

        <Stack
          direction="row"
          spacing={{ base: 0, md: '52px', lg: '52px' }}
          width="full"
          marginBottom="30px"
        >
          {!isMobile && (
            <Filter
              filters={filters}
              setFilters={setFilters}
              handleCheckboxFilter={handleCheckboxFilter}
              areas={areas}
              disciplines={disciplines}
              subjects={subjects}
              isLoading={isLoadingFilters}
              hasFilters={hasFilters}
              total={total}
            />
          )}

          {isNotFoundVisible && (
            <Box width="full" justifyContent="center" margin="15px" display="flex" flex={1}>
              <Heading fontSize="lg" color="#20212380">
                Nenhum resultado encontrado
              </Heading>
            </Box>
          )}

          {isEmpty && (
            <Box width="full" justifyContent="center" margin="15px" display="flex" flex={1}>
              <Heading fontSize="lg" color="#20212380">
                Nenhum instrutor cadastrado!
              </Heading>
            </Box>
          )}

          <Flex
            justify="start"
            flexWrap="wrap"
            gap={{ base: '14px', md: '10px', lg: '10px' }}
            width="full"
            marginTop={{ base: '13px', md: '0', lg: '0' }}
            hidden={!isInstructorsVisible}
            marginBottom="30px"
          >
            {instructors?.map(instructor => (
              <CardInstructs key={instructor.id} instructor={instructor} />
            ))}
          </Flex>
        </Stack>

        {shouldShowPagination && (
          <Stack width="full" justifyContent="center">
            <Paginate
              marginBottom="30px"
              pageCount={pageCount}
              initialPage={currentPage}
              onPageChange={handlePageChange}
            />
          </Stack>
        )}
      </Box>

      <CookieBanner />
    </Container>
  );
}
