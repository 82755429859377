import { Heading } from '@chakra-ui/react';
import useFetch from '../../../hooks/useFetch';
import Loading from './Loading';
import ProfileForm from './ProfileForm';
import { UserProfile } from './types';

export default function EditProfile() {
  const { data: response, loading: isLoading } = useFetch<UnificadaFront.ResponseJSON<UserProfile>>(
    {
      url: `/users`,
      method: 'get',
      autoFetch: true,
      authenticated: true,
    }
  );

  const user = response?.data;
  const isFormVisible = !isLoading && response?.data;

  return (
    <>
      <Heading
        as="h1"
        fontSize={{ base: 'lg', xl: '4xl' }}
        fontWeight="semibold"
        color="#202123"
        m={0}
      >
        Editar Perfil
      </Heading>

      {isLoading && <Loading />}

      {isFormVisible && <ProfileForm user={user} mt="1.875rem" />}
    </>
  );
}
