import { Icon } from '@chakra-ui/react';

export const StudentsIcon = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.5859 8.48828C15.7847 9.30203 16.6247 10.4045 16.6247 11.8745V14.4995H20.1247V11.8745C20.1247 9.96703 17.0009 8.83828 14.5859 8.48828Z"
      fill="currentColor"
    />
    <path
      d="M13.1247 7.5C15.0584 7.5 16.6247 5.93375 16.6247 4C16.6247 2.06625 15.0584 0.5 13.1247 0.5C12.7134 0.5 12.3284 0.5875 11.9609 0.71C12.6872 1.61125 13.1247 2.7575 13.1247 4C13.1247 5.2425 12.6872 6.38875 11.9609 7.29C12.3284 7.4125 12.7134 7.5 13.1247 7.5Z"
      fill="currentColor"
    />
    <path
      d="M7.875 7.5C9.80875 7.5 11.375 5.93375 11.375 4C11.375 2.06625 9.80875 0.5 7.875 0.5C5.94125 0.5 4.375 2.06625 4.375 4C4.375 5.93375 5.94125 7.5 7.875 7.5ZM7.875 2.25C8.8375 2.25 9.625 3.0375 9.625 4C9.625 4.9625 8.8375 5.75 7.875 5.75C6.9125 5.75 6.125 4.9625 6.125 4C6.125 3.0375 6.9125 2.25 7.875 2.25Z"
      fill="currentColor"
    />
    <path
      d="M7.875 8.375C5.53875 8.375 0.875 9.5475 0.875 11.875V14.5H14.875V11.875C14.875 9.5475 10.2113 8.375 7.875 8.375ZM13.125 12.75H2.625V11.8838C2.8 11.2538 5.5125 10.125 7.875 10.125C10.2375 10.125 12.95 11.2538 13.125 11.875V12.75Z"
      fill="currentColor"
    />
  </Icon>
);

export const ShoppingCartIcon = props => (
  <Icon viewBox="0 0 31 31" {...props}>
    <path
      d="M22.5441 17.05C23.7062 17.05 24.7288 16.4145 25.2556 15.4535L30.8026 5.394C31.3758 4.371 30.6321 3.1 29.4546 3.1H6.52308L5.06662 0H0V3.1H3.09885L8.67678 14.8645L6.58505 18.6465C5.45397 20.7235 6.94142 23.25 9.29655 23.25H27.8896V20.15H9.29655L11.0009 17.05H22.5441ZM7.99503 6.2H26.8205L22.5441 13.95H11.6672L7.99503 6.2ZM9.29655 24.8C7.59218 24.8 6.21319 26.195 6.21319 27.9C6.21319 29.605 7.59218 31 9.29655 31C11.0009 31 12.3954 29.605 12.3954 27.9C12.3954 26.195 11.0009 24.8 9.29655 24.8ZM24.7908 24.8C23.0864 24.8 21.7074 26.195 21.7074 27.9C21.7074 29.605 23.0864 31 24.7908 31C26.4952 31 27.8896 29.605 27.8896 27.9C27.8896 26.195 26.4952 24.8 24.7908 24.8Z"
      fill="currentColor"
    />
  </Icon>
);

export const MoneyIcon = props => (
  <Icon viewBox="0 0 31 31" {...props}>
    <path
      d="M24.5417 18.0833V7.75001C24.5417 6.32917 23.3792 5.16667 21.9584 5.16667H3.87502C2.45419 5.16667 1.29169 6.32917 1.29169 7.75001V18.0833C1.29169 19.5042 2.45419 20.6667 3.87502 20.6667H21.9584C23.3792 20.6667 24.5417 19.5042 24.5417 18.0833ZM21.9584 18.0833H3.87502V7.75001H21.9584V18.0833ZM12.9167 9.04167C10.7725 9.04167 9.04169 10.7725 9.04169 12.9167C9.04169 15.0608 10.7725 16.7917 12.9167 16.7917C15.0609 16.7917 16.7917 15.0608 16.7917 12.9167C16.7917 10.7725 15.0609 9.04167 12.9167 9.04167ZM29.7084 9.04167V23.25C29.7084 24.6708 28.5459 25.8333 27.125 25.8333H5.16669C5.16669 24.5417 5.16669 24.6708 5.16669 23.25H27.125V9.04167C28.5459 9.04167 28.4167 9.04167 29.7084 9.04167Z"
      fill="currentColor"
    />
  </Icon>
);
