import React from 'react';
import { PrevArrow, NextArrow, CustomPrevArrow, CustomNextArrow } from './arrows';

const responsive = [
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 668,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

export const settingsBanner = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 4000,

  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
};

export const settingsCatProd = {
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: responsive,

  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};

export const settingsInstructors = {
  dots: true,
  infinite: false,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 3500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: responsive,

  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};

export const settingsTestimonials = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
};
