import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { Subscriber } from '../..';
import SubscriberTableRow from './SubscriberTableRow';

interface SubscriberTableProps extends TableContainerProps {
  subscriberList: Subscriber[];
  onSelectSubscriberToBeCanceled: (subscriber: Subscriber) => void;
}

export default function SubscriberTable({
  subscriberList,
  onSelectSubscriberToBeCanceled,
  ...rest
}: SubscriberTableProps) {
  return (
    <TableContainer
      border="1px solid rgba(32, 33, 35, 0.05)"
      mt={4}
      p={3}
      borderRadius="base"
      {...rest}
    >
      <Table>
        <Thead>
          <Tr sx={{ th: { color: '#202123BF', whiteSpace: 'break-spaces', py: 2 } }}>
            <Th maxWidth="264px">Nome</Th>

            <Th maxWidth="168px">Forma de pagamento</Th>

            <Th maxWidth="221px">Período</Th>

            <Th maxWidth="139px">Atualização</Th>

            <Th maxWidth="131px">Status</Th>

            <Th textAlign="center" maxWidth="95px">
              Ações
            </Th>
          </Tr>
        </Thead>

        <Tbody sx={{ 'tr:nth-of-type(odd)': { background: '#2021230D' } }}>
          {subscriberList.map(subscriber => (
            <SubscriberTableRow
              key={subscriber.id}
              subscriber={subscriber}
              onSelectSubscriberToBeCanceled={onSelectSubscriberToBeCanceled}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
