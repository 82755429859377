import './styles.css';

import { BsChevronDown as ArrowDown } from 'react-icons/bs';
import Collapse from 'react-collapsible';
import EditorRichText from '../../../../../../../../components/EditorRichText';
import ErrorResponse from '../../../../../../../../helpers/ErrorResponse';
import Item from './Item';
import QuestionAPI from '../../../../../../../../api/Question';
import Spinner from '../../../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../../../components/Toast';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Question } from '../../types';
import Editor from './Editor';

interface IProps {
  questionId: number;
  question: Question;
  fetchAllQuestions?: () => Promise<void>;
  setIsDisabledSubmit: (value: React.SetStateAction<boolean>) => void;
}

type Params = {
  id: string;
  moduleId: string;
  contentId: string;
};

function Content({ questionId, question, fetchAllQuestions, setIsDisabledSubmit }: IProps) {
  const { id: courseId, moduleId, contentId } = useParams<Params>();

  const [isLoading, setIsLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(question);

  function onEditorStateChange(value) {
    const newQuestionText = { ...currentQuestion, questionText: value };
    setCurrentQuestion(newQuestionText);
  }

  function onEditorStateChangeCommentary(value) {
    const newCommentary = { ...currentQuestion, commentary: value };
    setCurrentQuestion(newCommentary);
  }

  async function getQuestion() {
    try {
      const { data: questionData } = await QuestionAPI.show(
        Number(courseId),
        Number(moduleId),
        Number(contentId),
        Number(questionId)
      );

      return questionData;
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  function handleCollapse() {
    setCollapse(prevCollapse => !prevCollapse);
  }

  async function OnDeleteOption() {
    const question = await getQuestion();

    setCurrentQuestion({ ...question, questionText: currentQuestion.questionText });
  }

  async function handleUpdate() {
    try {
      setIsLoading(true);

      const payload = {
        questionText: currentQuestion.questionText || question.questionText,
        commentary: currentQuestion.commentary,
      };

      await QuestionAPI.update(
        Number(courseId),
        Number(moduleId),
        Number(contentId),
        questionId,
        payload
      );

      await fetchAllQuestions();

      Toast('Questão atualizada com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmit() {
    try {
      setIsLoading(true);
      setIsDisabledSubmit(false);

      const payload = {
        answerOption: 'SEM ENUNCIADO',
        correct: false,
      };

      await QuestionAPI.optionQuestion.store(
        Number(courseId),
        Number(moduleId),
        Number(contentId),
        questionId,
        payload
      );

      const question = await getQuestion();

      setCurrentQuestion({ ...question, questionText: currentQuestion.questionText });

      Toast('Item cadastrado com sucesso!');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  function handleIsLoadingChange(value) {
    setIsLoading(value);
  }

  return (
    <div className="item-container">
      <div className="form-group form-group-my">
        <h5 className="heading">Enunciado:</h5>
        <EditorRichText
          value={currentQuestion?.questionText || question?.questionText}
          onChange={onEditorStateChange}
          onIsLoadingChange={handleIsLoadingChange}
          isLoading={isLoading}
        />
      </div>

      <hr />
      <h5 className="heading">Alternativas:</h5>

      {currentQuestion.options.map(option => (
        <Item
          key={option.id}
          questionId={currentQuestion.id}
          option={option}
          OnDeleteOption={OnDeleteOption}
        />
      ))}

      <div className="d-flex align-items-center justify-content-between">
        <div className="d-block">
          <button className="btn btn-primary add-item" type="button" onClick={handleSubmit}>
            {isLoading ? <Spinner /> : 'Adicionar um item'}
          </button>
        </div>
      </div>

      <div className="my-2">
        <label className="form-control d-flex my-1 border-0 pl-0" onClick={handleCollapse}>
          <span className="h6">
            Comentário do professor: <span className="text-gray font-italic">(opcional)</span>
          </span>
          <ArrowDown className="ml-2" />
        </label>

        <Collapse trigger="" open={collapse}>
          <div className="form-group form-group-my">
            <Editor
              id={questionId}
              hasVideoLibrary
              value={currentQuestion?.commentary || question?.commentary}
              isLoading={isLoading}
              onChange={onEditorStateChangeCommentary}
              onIsLoadingChange={handleIsLoadingChange}
            />
          </div>
        </Collapse>
        <div className="w-100 d-flex align-items-center justify-content-end">
          <button
            type="button"
            onClick={handleUpdate}
            className="add-item mt-2"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : 'Salvar'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Content;
