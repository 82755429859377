import { Button, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft as ArrowLeft } from 'react-icons/md';
import { Link, useHistory, useParams } from 'react-router-dom';
import BlogAPI from '../../../../api/Blog';
import EditorRichText from '../../../../components/EditorRichText';
import FullPageLoading from '../../../../components/FullPageLoading';
import Toast from '../../../../components/Toast';
import Tooltip from '../../../../components/Tooltip';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../helpers/UploadHelper';
import useFetch from '../../../../hooks/useFetch';
import ErrorMessage from '../components/ErrorMessage';
import ImageInput from '../components/ImageInput';
import ImagePreview from '../components/ImagePreview';
import styles from './styles.module.css';

interface IPost {
  id: number;
  articleName: string;
  subtitle: string;
  userName: string;
  userPhoto: string;
  status: string;
  category: string;
  content: string;
  banner: string;
  publicationDate: string;
  createdAt: string;
}

const MAX_SIZE_FILE_PHOTO = 3145728;
const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
const TODAY = dayjs().format(DATE_FORMAT);

const initialState = {
  articleName: '',
  subtitle: '',
  banner: null,
  content: '',
  publicationDate: '',
};

function composeSubmitButtonText(publicationDate) {
  const isToday = dayjs().isSame(publicationDate, 'minute');

  const dateReportedLowerThanNow =
    dayjs(publicationDate).format(DATE_FORMAT) < dayjs().format(DATE_FORMAT);

  const withoutTime = publicationDate === '';

  const showPublishButton = isToday || dateReportedLowerThanNow || withoutTime;

  if (showPublishButton) {
    return 'Publicar imediatamente';
  }

  return 'Agendar';
}

function PostBlog() {
  const { id: postId } = useParams<{ id: string }>();
  const history = useHistory();

  const [post, setNewPost] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState('');
  const [errorUploadPhoto, setErrorUploadPhoto] = useState(null);

  const {
    data: response,
    loading,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<IPost>>({
    url: `/blog/${postId}`,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  const postBlog = response?.data;

  useEffect(() => {
    if (postId) {
      fetchData();
    }
  }, [fetchData, postId]);

  useEffect(() => {
    if (postBlog) {
      setNewPost({
        ...postBlog,
        publicationDate: dayjs(postBlog.publicationDate).format(DATE_FORMAT),
      });
      setPreview(postBlog?.banner);
    }
  }, [postBlog]);

  function handleDropChange(acceptedFiles) {
    const [firstFile] = acceptedFiles;

    if (firstFile?.size > MAX_SIZE_FILE_PHOTO) {
      return setErrorUploadPhoto('O tamanho da imagem deve ser de no máximo 3MB.');
    }

    if (firstFile) {
      setPreview(URL.createObjectURL(firstFile));
      setErrorUploadPhoto(null);
      setNewPost({ ...post, banner: firstFile });
    }
  }

  function handleImageDropRejected() {
    setErrorUploadPhoto('Arquivo inválido!');
  }

  function handleImageChange() {
    setPreview(null);
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setNewPost(prevPost => ({ ...prevPost, [name]: value }));
  }

  function onEditorStateChange(value) {
    setNewPost(prevPost => ({ ...prevPost, content: value }));
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!post.banner) {
      Toast('Adicione um banner para a sua publicação.', 'error');
      return;
    }

    if (!post.content) {
      Toast('Adicione um conteúdo para a sua publicação.', 'error');
      return;
    }

    const method = postId ? 'update' : 'create';

    const message = method === 'create' ? 'cadastrado' : 'atualizado';

    let payload = {
      articleName: post.articleName,
      subtitle: post.subtitle,
      content: post.content,
      banner: post.banner,
      publicationDate: dayjs(post.publicationDate).format() || '',
    };

    try {
      setIsLoading(true);

      if (post.banner && typeof post.banner !== 'string') {
        payload = { ...payload, banner: post.banner };
        const { newFileName: newFileNameBanner } = await UploadHelper.upload(
          payload.banner,
          'banners'
        );
        payload.banner = encodeURI(newFileNameBanner);
      } else {
        delete payload.banner;
      }

      if (method === 'create') {
        await BlogAPI.store(payload);
      }

      if (method === 'update') {
        await BlogAPI.update(postId, payload);
      }

      Toast(`Post ${message} com sucesso`);

      history.push('/internal-blog');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <Link to="/internal-blog" className={styles.pageTitle}>
          <ArrowLeft size={32} color="#202123" />
          {postId ? 'Editar publicação' : 'Cadastro de publicação'}
        </Link>
      </div>

      <div className={styles.container}>
        <form className="w-100" onSubmit={handleSubmit}>
          <div
            className={`d-flex algin-items-center flex-sm-column flex-md-row w-100 mb-3 ${styles.flexColumn}`}
          >
            <div className="d-flex flex-column w-100 mr-sm-0 mr-md-2 mr-lg-2 mb-sm-3 mb-md-0">
              <label className={styles.inputLabel} htmlFor="articleName">
                Título:
              </label>
              <Text fontSize="xs"> Caracteres: {`${post.articleName.length}/120`}</Text>
              <input
                type="text"
                name="articleName"
                id="articleName"
                value={post.articleName}
                required
                minLength={5}
                maxLength={120}
                onChange={handleChange}
                placeholder="Escreva o título"
                className={styles.titleInput}
              />
            </div>
            <div className="d-flex flex-column w-100 mr-sm-0 mr-md-2 mr-lg-2 mb-md-0">
              <label className={styles.inputLabel} htmlFor="subtitle">
                Subtítulo:
              </label>
              <Text fontSize="xs"> Caracteres: {`${post.subtitle.length}/250`}</Text>
              <input
                type="text"
                name="subtitle"
                id="subtitle"
                minLength={7}
                maxLength={250}
                value={post.subtitle}
                onChange={handleChange}
                placeholder="Escreva o subtítulo"
                className={styles.subtitleInput}
              />
            </div>
          </div>
          <div className="d-flex algin-items-center flex-sm-column flex-md-row w-100 mb-3">
            <div className="d-flex flex-column w-100">
              <label className={styles.inputLabel} htmlFor="banner">
                Banner Principal:
              </label>
              <section>
                {!preview ? (
                  <ImageInput
                    onDrop={handleDropChange}
                    onDropRejected={handleImageDropRejected}
                    onChangeImage={handleImageChange}
                    imageDimensions="900x409"
                    isDisabled={isLoading}
                  />
                ) : (
                  <ImagePreview preview={preview} onClick={handleImageChange} />
                )}

                {errorUploadPhoto && <ErrorMessage message={errorUploadPhoto} />}
              </section>
            </div>
          </div>
          <div className={styles.textEditor}>
            <div className="d-flex flex-column">
              <label className={styles.inputLabel} htmlFor="content">
                Conteúdo:
              </label>

              <EditorRichText value={post?.content} onChange={onEditorStateChange} />
            </div>
          </div>
          <div className="w-100 d-flex align-items-end justify-content-between my-3">
            <div className={styles.publishContainer}>
              <div>
                <div className="d-flex">
                  <label className={styles.inputLabel} htmlFor="publicationDate">
                    Programar publicação (Opcional)
                  </label>
                  <Tooltip
                    content="Ao definir uma data, sua publicação será publicada automaticamente."
                    background="#eb7129"
                  />
                </div>

                <input
                  id="publicationDate"
                  name="publicationDate"
                  className={`form-control input-content mr-2 ${styles.inputSchedule}`}
                  placeholder="Selecione o dia e a hora"
                  type="datetime-local"
                  value={post.publicationDate}
                  onChange={handleChange}
                  min={TODAY}
                />
              </div>
            </div>
            <Button
              type="submit"
              colorScheme="primary"
              color="secondary.500"
              size="sm"
              isLoading={isLoading}
            >
              {composeSubmitButtonText(post.publicationDate)}
            </Button>
          </div>
        </form>
      </div>

      <FullPageLoading isLoading={loading} />
    </>
  );
}

export default PostBlog;
