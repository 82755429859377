import { axiosBackend } from './Configuration';

const PlanAPI = {
  internalManager: {
    index: async () => {
      try {
        const { data: planData } = await axiosBackend().get('/plans/internal-manager');
        return planData;
      } catch (error) {
        throw error;
      }
    },
  },

  store: async plan => {
    try {
      delete plan.homologationBy;

      const { data: planData } = await axiosBackend().post('/plans', plan);

      return planData;
    } catch (error) {
      throw error;
    }
  },

  index: async () => {
    try {
      const { data: planData } = await axiosBackend().get('/plans/prices');
      return planData;
    } catch (error) {
      throw error;
    }
  },

  indexCadastro: async () => {
    try {
      const { data: planData } = await axiosBackend().get('/plans');
      return planData;
    } catch (error) {
      throw error;
    }
  },

  show: async plan_id => {
    try {
      const { data: planData } = await axiosBackend().get(`/plans/${plan_id}`);

      return planData;
    } catch (error) {
      throw error;
    }
  },

  showByIdentifier: async (plan_identifier) => {
    try {
      const { data: planData } = await axiosBackend().get(
        `/plans/checkout?plan_identifier=${plan_identifier}`
      );

      return planData.data;
    } catch (error) {
      throw error;
    }
  },

  getPlanByIdentifier: async plan => {
    try {
      const { data: planData } = await axiosBackend().get(`/plans?plan=${plan}`);

      return planData;
    } catch (error) {
      throw error;
    }
  },

  update: async (plan_id, plan) => {
    try {
      delete plan.id;
      delete plan.image;
      delete plan.homologatedBy;

      await axiosBackend().patch(`/plans/${plan_id}`, plan);
    } catch (error) {
      throw error;
    }
  },
};

export default PlanAPI;
