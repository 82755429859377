import './Header.css';

function Header({ children, visibility }) {
  if (!visibility) return <></>;

  return (
    <div className="w-100">
      <ul className="w-100 d-flex align-items-center justify-content-end">{children}</ul>
    </div>
  );
}

export default Header;
