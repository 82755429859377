import { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import CourseAPI from '../../../../../api/Course';
import Toast from '../../../../../components/Toast';
import { useStudentCourse } from '../../../../../contexts/CourseStudentContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import styles from './styles.module.css';
import { Box } from '@chakra-ui/react';
import { scrollbarStyle } from '..';

function ViewLiveYouTube() {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);
  const { studentCourse, getContent } = useStudentCourse();
  const [content, setContent] = useState();

  const { courseId, moduleId, contentId } = useParams();

  useEffect(() => {
    if (studentCourse) {
      const currentContent = getContent(studentCourse, moduleId, contentId);
      if (currentContent) {
        setContent(currentContent);
      }
    }
  }, [contentId, getContent, moduleId, studentCourse]);

  const updateLastVisit = useCallback(async () => {
    try {
      await CourseAPI.updateLastVisit(courseId, moduleId, contentId, 'AO_VIVO_YOUTUBE');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }, [contentId, courseId, moduleId]);

  useEffect(() => {
    if (role === 'ALUNO' && studentCourse) {
      updateLastVisit();
    }
  }, [updateLastVisit, role, studentCourse]);

  return (
    <Box
      w="full"
      overflow="auto"
      sx={scrollbarStyle}
      margin={{ base: '0', md: '20px 70px', lg: '20px 70px' }}
    >
      <div className={styles.container}>
        <div className="d-flex justify-content-center h-100 p-4 p-lg-0">
          <div className="col-sm-12 col-lg-11 p-0">
            <div className="card shadow-sm pb-4 mb-5">
              <div className={styles.header}>
                <div>
                  <h3 style={{ fontSize: '30px' }}> {content?.title}</h3>
                </div>

                <div className="d-flex flex-column flex-md-row text-center mt-2">
                  <h6>Data do evento: {dayjs(content?.broadcastDate).format('DD/MM/YYYY')}</h6>
                  <h6 className="ml-1">
                    Horário: {dayjs(content?.broadcastDate, { utc: true }).format('HH:mm')}h
                  </h6>
                </div>
              </div>

              <div className={styles.videoWrapper}>
                <iframe
                  width="100%"
                  height="100%"
                  src={content && content?.link}
                  title={content?.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>

              <div className={styles.content}>
                {content?.content !== null && content?.content !== '' && (
                  <Box
                    my={4}
                    dangerouslySetInnerHTML={{ __html: content?.content }}
                    className="ql-editor"
                    sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
                  />
                )}

                {content?.content === null && content?.content === '' && (
                  <h4>Conteúdo sem descrição</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default ViewLiveYouTube;
