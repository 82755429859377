import { List, useDisclosure, useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FullPageLoading from '../../../../../components/FullPageLoading';
import Paginate from '../../../../../components/Paginate';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import useFilters from '../SearchContainer/Filters/useFilters';
import QuestionService from '../services/QuestionService';
import Question from '../types/Question';
import DeleteModal from './DeleteModal';
import QuestionItem from './QuestionItem';
import updateQuestionValidator from './QuestionItem/updateQuestionValidator';

function hasValidationError(payload) {
  return !payload?.areaId || !payload?.disciplineId || !payload?.subjectId;
}

interface QuestionsProps {
  questions: Question[];
  total: number;
  page: number;
  per_page: number;
  isLoading: boolean;
  fetchQuestions: () => Promise<void>;
}

export default function Questions({
  questions,
  total,
  per_page,
  isLoading,
  fetchQuestions,
}: QuestionsProps) {
  const [questionsList, setQuestionsList] = useState<Question[]>(questions);
  const [questionBeingDeleted, setQuestionBeingDeleted] = useState(null);
  const [questionIdBeingUpdated, setQuestionIdBeingUpdated] = useState(null);
  const [urlToDeleteQuestion, setUrlToDeleteQuestion] = useState('');
  const [urlToUpdateQuestion, setUrlToUpdateQuestion] = useState('');
  const [questionBeingUpdated, setQuestionBeingUpdated] = useState(null);

  const toast = useToast();
  const location = useLocation();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { filters, filtersList, fetchFilters, handleAddFilter } = useFilters();

  const searchParams = new URLSearchParams(location.search);
  const currentPage = Number(searchParams.get('page')) || 1;

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  const { isLoading: isRemoving, handleSubmit: removeQuestion } = useHandleSubmit({
    method: 'delete',
    url: urlToDeleteQuestion,
    authenticated: true,
    onSuccess: {
      message: 'Questão removida com sucesso!',
      callback: async () => {
        setQuestionsList(prevState =>
          prevState.filter(question => question.id !== questionBeingDeleted)
        );

        await fetchQuestions();

        setQuestionBeingDeleted(null);
      },
    },
  });

  const areaId = questionBeingUpdated?.questionLibraryArea?.id;
  const disciplineId = questionBeingUpdated?.questionLibraryDiscipline?.id;
  const subjectId = questionBeingUpdated?.questionLibrarySubject?.id;
  const institutionId = questionBeingUpdated?.questionLibraryInstitution?.id;

  const payload = {
    questionText: questionBeingUpdated?.questionText,
    commentary: questionBeingUpdated?.commentary,
    areaId,
    disciplineId,
    subjectId,
    institutionId,
  };

  const {
    isLoading: isUpdating,
    handleSubmit: updateQuestion,
    formValidation,
  } = useHandleSubmit({
    data: payload,
    schemaValidator: updateQuestionValidator,
    method: 'patch',
    url: urlToUpdateQuestion,
    authenticated: true,
    onSuccess: {
      callback: async () => {
        setQuestionIdBeingUpdated(null);
        setQuestionBeingUpdated(null);

        await fetchQuestions();
      },
    },
  });

  function showToast({ title, description }: { title?: string; description: string }) {
    toast({
      title: title || 'Erro ao salvar questão!',
      description,
      status: 'error',
      position: 'top',
      duration: 3000,
      isClosable: true,
    });
  }

  function hasSomeOptionCorrect() {
    return questionBeingUpdated.options.some(option => option.correct);
  }

  function handleUpdate() {
    if (questionBeingUpdated.options.length < 2) {
      showToast({ description: 'A questão deve ter pelo menos duas opções.' });
      return;
    }

    if (!hasSomeOptionCorrect()) {
      showToast({ description: 'A questão deve ter pelo menos uma opção correta.' });
      return;
    }

    if (hasValidationError(payload)) {
      showToast({ description: 'A questão deve ter uma área, disciplina e assunto selecionados.' });
      scrollToClickedQuestion(questionIdBeingUpdated);
    }

    updateQuestion();
  }

  function handleDelete(id: number) {
    setQuestionBeingDeleted(id);
    setUrlToDeleteQuestion(`/question-library/${id}/question`);
    onOpen();
  }

  async function handleDuplicate(questionId: number) {
    try {
      await QuestionService.duplicateQuestion(questionId);
      await fetchQuestions();
    } catch (error) {
      const errorMessage = ErrorResponse(error);

      showToast({
        title: 'Erro ao duplicar questão',
        description: errorMessage,
      });
    }
  }

  function scrollToClickedQuestion(id: number) {
    const element = document.getElementById(`${id}`);
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  function handleClick(id: number) {
    setQuestionIdBeingUpdated(id);
    setQuestionBeingUpdated(questionsList.find(question => question.id === id));
    setUrlToUpdateQuestion(`/question-library/${id}/question`);
    scrollToClickedQuestion(id);
  }

  const onQuestionBeingUpdatedChange = useCallback((question: Partial<Question>) => {
    setQuestionBeingUpdated(prevState => ({ ...prevState, ...question }));
  }, []);

  const handleQuestionsListChange = useCallback((question: Question) => {
    setQuestionsList(prevState =>
      prevState.map(prevQuestion => {
        if (prevQuestion.id === question.id) {
          return question;
        }

        return prevQuestion;
      })
    );
  }, []);

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    searchParams.set('page', newPage.toString());

    const newSearch = searchParams.toString();

    history.push(`?${newSearch}`);
  }

  const pageCount = Math.ceil(total / per_page);
  const shouldShowPagination = !isLoading && pageCount > 1;

  return (
    <>
      <List mt={8} spacing={4}>
        {questionsList.map(question => (
          <QuestionItem
            filtersList={filtersList}
            filters={filters}
            question={question}
            questionBeingUpdated={questionBeingUpdated}
            key={question.id}
            formValidation={formValidation}
            isLoading={isLoading}
            isEditing={questionIdBeingUpdated === question.id}
            onAddFilter={handleAddFilter}
            onDuplicate={handleDuplicate}
            onDelete={handleDelete}
            onClick={handleClick}
            onUpdate={handleUpdate}
            onQuestionBeingUpdatedChange={onQuestionBeingUpdatedChange}
            onQuestionsListChange={handleQuestionsListChange}
          />
        ))}
      </List>

      <DeleteModal
        isOpen={isOpen}
        questionId={questionBeingDeleted}
        onClose={onClose}
        onConfirm={removeQuestion}
      />

      {shouldShowPagination && (
        <Paginate
          my={6}
          pageCount={pageCount}
          initialPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}

      <FullPageLoading isLoading={isRemoving || isUpdating} />
    </>
  );
}
