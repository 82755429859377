import path from 'path';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CourseAPI from '../../../../../../api/Course';
import UserAPI from '../../../../../../api/User';
import CustomDropzone from '../../../../../../components/CustomDropzone';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import convertNullToEmptyString from '../../../../../../helpers/ConvertNullToEmptyString';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import FiltersList from './FiltersList';
import InstructorsSelect from './InstructorsSelect';
import PDFFilePreview from './PDFFilePreview';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';

const FIRST_FILE = 0;

function BasicSettings() {
  const [isLoadingInstructors, setIsLoadingInstructors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [isTermsActive, setIsTermsActive] = useState(false);
  const [filePDF, setFilePDF] = useState('');
  const [basicSetting, setBasicSetting] = useState({});
  const [allInstructors, setAllInstructors] = useState([]);
  const [selectedInstructors, setSelectedInstructors] = useState([]);

  const { id: courseId } = useParams();

  const { course } = useCourse();

  useEffect(() => {
    async function fetchInstructors() {
      try {
        setIsLoadingInstructors(true);

        const { data } = await UserAPI.responsibleProfiles.all();

        setAllInstructors(
          data?.instructor?.map(instructor => ({
            name: instructor.fullName,
            id: instructor.id,
          }))
        );
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoadingInstructors(false);
      }
    }

    fetchInstructors();
  }, []);

  useEffect(() => {
    if (courseId) {
      const currentBasicSettings = {
        name: course.name,
        instructor: course.instructor,
        termsOfUse: course.termsOfUse,
        questionLibraryAreaId: course.area?.id,
        questionLibraryDisciplineId: course.discipline?.id,
        questionLibrarySubjectId: course.subject?.id,
      };

      setBasicSetting(currentBasicSettings);
      setSelectedInstructors(course.courseInstructor);
    }
  }, [courseId, course]);

  useEffect(() => {
    if (course.termsOfUse) {
      setFilePDF(course.termsOfUse);
      setIsTermsActive(!!course.termsOfUse);
    }
  }, [course.termsOfUse]);

  function handleProductChange(event) {
    const { name, value } = event.target;

    setHasChange(true);

    setBasicSetting({
      ...basicSetting,
      [name]: value,
    });
  }

  function handleIsTermsActiveChange(event) {
    const { checked } = event.target;

    setHasChange(true);

    setIsTermsActive(checked);

    if (!checked) {
      setFilePDF('');
    }
  }

  function handleDropFilePDF(acceptedFiles) {
    const filePDF = acceptedFiles[FIRST_FILE] || '';

    setHasChange(true);

    setFilePDF(filePDF);
  }

  function handleDiscardFile() {
    setHasChange(true);

    setFilePDF('');
    setIsTermsActive(false);
  }

  function handleInstructorSelect(newInstructor) {
    setHasChange(true);

    setSelectedInstructors(prevState => [
      ...prevState,
      { id: newInstructor.value, name: newInstructor.label },
    ]);
  }

  function handleInstructorRemove(instructorId) {
    setHasChange(true);

    setSelectedInstructors(prevState =>
      prevState.filter(instructor => instructor.id !== instructorId)
    );
  }

  const handleFilterChange = useCallback(filters => {
    const hasSomeValue = Object.values(filters).some(Boolean);

    hasSomeValue && setHasChange(true);

    setBasicSetting(preventDefault => ({ ...preventDefault, ...filters }));
  }, []);

  async function onSubmit(event) {
    event.preventDefault();

    try {
      setIsSubmitting(true);

      if (!isTermsActive) {
        basicSetting.termsOfUse = '';
      } else {
        basicSetting.termsOfUse = filePDF;
      }

      if (isTermsActive && !filePDF) {
        Toast('Selecione um arquivo!', 'warn');
        return;
      }

      const payload = {
        name: basicSetting.name.trim(),
        questionLibraryAreaId: basicSetting.questionLibraryAreaId,
        questionLibraryDisciplineId: basicSetting.questionLibraryDisciplineId,
        questionLibrarySubjectId: basicSetting.questionLibrarySubjectId,
        termsActive: isTermsActive,
        termsOfUse: filePDF,
        instructors: selectedInstructors.map(instructor => instructor.id),
      };

      await CourseAPI.updateBasicSettings(courseId, payload);

      Toast('Curso alterado com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <VStack align="start">
      <Heading as="h4" fontWeight="600" fontSize="2xl" textAlign="start">
        Configurações básicas
      </Heading>

      <Box
        padding={6}
        marginBottom={4}
        background="white"
        border="1px solid #0000000D"
        borderRadius="0.3125rem"
        boxShadow="0px 4px 4px 0px #00000040"
        overflow="auto"
      >
        <VStack as="form" onSubmit={onSubmit} gap={8}>
          <FormControl>
            <FormLabel
              htmlFor="name"
              fontSize="sm"
              fontWeight="medium"
              lineHeight="5"
              color="#202123"
            >
              Nome do produto
            </FormLabel>

            <Input
              id="name"
              name="name"
              autoComplete="off"
              placeholder="Digite aqui"
              value={convertNullToEmptyString(basicSetting?.name)}
              onChange={handleProductChange}
              fontSize="sm"
              size="sm"
              borderRadius="6px"
              focusBorderColor="default.500"
              border="1px solid #E2E8F0"
              _placeholder={{ color: '#2D374880' }}
            />
          </FormControl>

          <FiltersList
            areaId={basicSetting?.questionLibraryAreaId}
            disciplineId={basicSetting?.questionLibraryDisciplineId}
            subjectId={basicSetting?.questionLibrarySubjectId}
            onChange={handleFilterChange}
            setHasChange={setHasChange}
          />

          <InstructorsSelect
            allInstructors={allInstructors}
            selectedInstructors={selectedInstructors}
            isLoading={isLoadingInstructors}
            onInstructorRemove={handleInstructorRemove}
            onInstructorSelect={handleInstructorSelect}
          />

          <Stack direction={{ base: 'column', lg: 'row' }}></Stack>

          <Stack>
            <VStack spacing={0} align="start">
              <FormLabel
                htmlFor="url"
                fontSize="sm"
                fontWeight="medium"
                lineHeight="5"
                color="#202123"
              >
                Termos de uso
              </FormLabel>

              <Text
                marginBottom="0.5rem !important"
                fontSize="sm"
                fontWeight="normal"
                color="#0000007D"
              >
                Ao ativar os termos de uso, adicione o contrato/termo no campo abaixo, apenas
                arquivos em PDF. No momento em que o aluno for acessar o produto pela primeira vez,
                aparecerá o contrato e um botão para o aluno aceitar os termos. Para visualizar quem
                aceitou, acesse a lista de alunos do produto.
              </Text>
            </VStack>

            <FormControl display="flex" alignItems="center" gap="2">
              <Switch
                id="checked"
                colorScheme="default"
                isChecked={isTermsActive}
                onChange={handleIsTermsActiveChange}
              />

              <FormLabel htmlFor="checked" margin="0" fontWeight="normal" fontSize="sm">
                Ativar termo?
              </FormLabel>
            </FormControl>

            {isTermsActive && (
              <Box marginY="1rem !important">
                {filePDF === '' ? (
                  <CustomDropzone
                    filePDF={filePDF}
                    isDisabled={isSubmitting}
                    accept="application/pdf"
                    onDrop={handleDropFilePDF}
                    onDropRejected={() => Toast('Arquivo inválido!', 'error')}
                    extensions="PDF"
                    maxFileSize="25 MB"
                  />
                ) : (
                  <PDFFilePreview
                    name={filePDF?.name || path.basename(decodeURI(basicSetting?.termsOfUse))}
                    file={filePDF instanceof File ? '' : filePDF}
                    handleDiscardFile={handleDiscardFile}
                  />
                )}
              </Box>
            )}
          </Stack>

          <FormControl display="flex" justifyContent="flex-end">
            <Button
              size="sm"
              fontSize="sm"
              colorScheme="default"
              type="submit"
              isDisabled={isSubmitting || !hasChange}
              isLoading={isSubmitting}
            >
              Salvar
            </Button>
          </FormControl>
        </VStack>
      </Box>
    </VStack>
  );
}

export default BasicSettings;
