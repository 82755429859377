import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { IoIosArrowDown, IoMdAlert } from 'react-icons/io';
import { MdOutlinePublishedWithChanges, MdSearch } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import Pagination from '../../../components/Pagination';
import useFetch from '../../../hooks/useFetch';
import useHandleSubmit from '../../../hooks/useHandleSubmit';
import useQuery from '../../../hooks/useQuery';
import useWindowSize from '../../../hooks/useWindowSize';
import OrderBumpCard from './OrderBumpCard';
import { ProductListType } from './OrderBumpManager';

const MEDIUM_SCREEN_SIZE = 800;

type OrderBumpDataType = {
  data: {
    id: number;
    name: string;
    enabled: boolean;
    courseName: string;
    coursePrice: number;
  }[];
  page: number;
  per_page: number;
  total: number;
};

export default function OrderBump() {
  const { width } = useWindowSize();
  const { isOpen, onToggle } = useDisclosure();

  const [filteredOrderBumpData, setFilteredOrderBumpData] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [courseId, setCourseId] = useState(0);
  const [timer, setTimer] = useState(0);

  const [orderBumpId, seOrderBumpId] = useState(0);
  const [orderBumpActive, setOrderBumpActive] = useState(false);
  const {
    isOpen: isOpenModalDeleteOrderBump,
    onOpen: onOpenModalDeleteOrderBump,
    onClose: onCloseModalDeleteOrderBump,
  } = useDisclosure();
  const {
    isOpen: isOpenModalActiveOrderBump,
    onOpen: onOpenModalActiveOrderBump,
    onClose: onCloseModalActiveOrderBump,
  } = useDisclosure();

  const history = useHistory();
  const query = useQuery();

  const {
    data: orderBump,
    loading: orderBumpLoading,
    fetchData: orderBumpFetchData,
  } = useFetch<UnificadaFront.ResponseJSON<OrderBumpDataType>>({
    method: 'get',
    url: `/order-bumps?page=${currentPage}&per_page=${12}${
      courseId ? `&courseId=${courseId}` : ''
    }${search ? `&search=${search}` : ''}`,
    authenticated: true,
    autoFetch: false,
  });

  const { data: courseListData, loading: courseListLoading } = useFetch<
    UnificadaFront.ResponseJSON<ProductListType[]>
  >({
    method: 'get',
    url: '/courses/list?status=ATIVO_E_NLISTADO',
    authenticated: true,
    autoFetch: true,
  });

  useEffect(() => {
    let page = query.get('page');

    if (page) {
      try {
        if (Number(page) < 1) {
          return history.push(`/order-bumps?page=1`);
        }

        setCurrentPage(Number(page));
      } catch (error) {
        history.push(`/order-bumps?page=1`);
      }
    }
  }, [history, query]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        orderBumpFetchData();

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, timer]);

  useEffect(() => {
    if (orderBump?.data?.data) {
      setFilteredOrderBumpData(orderBump?.data?.data);
      setPageCount(orderBump?.data?.total / orderBump?.data?.per_page);
    } else {
      setFilteredOrderBumpData([]);
    }
  }, [orderBump]);

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearch(value);
    setTimer(2);
  }

  function handlePageChange({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }

  const { handleSubmit: deleteOrderBump, isLoading: isDeletingOrderBump } = useHandleSubmit({
    method: 'delete',
    url: `/order-bumps/${orderBumpId}`,
    onSuccess: {
      message: `Order bump removido com sucesso`,
    },
    authenticated: true,
  });

  async function handleDeleteOrderBump() {
    const result = await deleteOrderBump();

    if (result) {
      const newArrayRemovedOrderBump = orderBump?.data?.data?.filter(
        orderBump => orderBump.id !== orderBumpId
      );

      if (newArrayRemovedOrderBump) {
        setFilteredOrderBumpData([...newArrayRemovedOrderBump]);
      }

      onCloseModalDeleteOrderBump();
    }
  }

  const { handleSubmit: activeOrderBump, isLoading: isLoadingActiveOrderBump } = useHandleSubmit({
    data: { enabled: !orderBumpActive },
    method: 'patch',
    url: `/order-bumps/${orderBumpId}`,
    onSuccess: {
      message: `Order bump atualizado com sucesso`,
    },
    authenticated: true,
  });

  async function handleActiveOrderBump() {
    const result = await activeOrderBump();

    if (result) {
      const alterStatusOrderBump = orderBump?.data?.data?.find(
        orderBump => orderBump.id === orderBumpId
      );

      alterStatusOrderBump.enabled = !orderBumpActive;

      onCloseModalActiveOrderBump();
    }
  }

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Heading fontWeight={600}>Order Bump</Heading>
          <Button
            as={Link}
            to="order-bump/new"
            size="sm"
            colorScheme="primary"
            color="secondary.500"
            hidden={width < MEDIUM_SCREEN_SIZE}
          >
            + Adicionar Order Bump
          </Button>
        </Stack>
        <Text color="#20212380" fontSize="18px" paddingBottom={2}>
          Ofereça um produto complementar na página de checkout, ou seja, quando seu aluno estiver
          realizando uma compra.
        </Text>

        <Button
          as={Link}
          to="order-bump/new"
          size="sm"
          colorScheme="primary"
          color="secondary.500"
          hidden={width > MEDIUM_SCREEN_SIZE}
        >
          + Adicionar Order Bump
        </Button>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <InputGroup
            width={width > MEDIUM_SCREEN_SIZE ? '320px' : '100%'}
            size="sm"
            marginTop={width > MEDIUM_SCREEN_SIZE ? 0 : 5}
          >
            <InputLeftElement
              pointerEvents="none"
              children={<MdSearch color="#20212350" size={22} />}
            />
            <Input
              type="text"
              borderRadius={6}
              focusBorderColor="primary.500"
              placeholder="Buscar order bump ou produto"
              onChange={handleChangeSearch}
              value={search}
            />
          </InputGroup>

          <Stack direction="row" alignItems="center" hidden={width < MEDIUM_SCREEN_SIZE}>
            <Select
              size="sm"
              color="#20212380"
              width={250}
              borderRadius={6}
              placeholder="Selecione um produto"
              focusBorderColor="primary.500"
              value={courseId}
              onChange={event => setCourseId(Number(event.target.value))}
              isDisabled={courseListLoading}
            >
              {courseListData?.data?.map(course => (
                <option key={course.id} value={course.id}>
                  {course.name}
                </option>
              ))}
            </Select>
            <Button
              size="xs"
              colorScheme="primary"
              color="secondary.500"
              onClick={orderBumpFetchData}
            >
              Filtrar
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Box marginY={3} hidden={width > MEDIUM_SCREEN_SIZE}>
        <Stack direction="row" alignItems="center" onClick={onToggle}>
          <Text fontWeight={600} fontSize="14px" color="#20212380">
            Filtros
          </Text>
          <IoIosArrowDown size="10px" color="#20212380" />
        </Stack>

        <Collapse in={isOpen} animateOpacity>
          <Stack direction="column" mt="1" rounded="md" shadow="md" width="100%" spacing={2}>
            <Select
              size="sm"
              color="#20212380"
              width={width > MEDIUM_SCREEN_SIZE ? 250 : '100%'}
              borderRadius={6}
              placeholder="Selecione um produto"
              focusBorderColor="primary.500"
              value={courseId}
              onChange={event => setCourseId(Number(event.target.value))}
              isDisabled={courseListLoading}
            >
              {courseListData?.data?.map(course => (
                <option key={course.id} value={course.id}>
                  {course.name}
                </option>
              ))}
            </Select>
            <Button
              size="xs"
              colorScheme="primary"
              color="secondary.500"
              onClick={orderBumpFetchData}
            >
              Filtrar
            </Button>
          </Stack>
        </Collapse>
      </Box>

      <Flex flex={1} flexWrap="wrap" justify="center" align="center" gap={5} marginY={5}>
        {!orderBumpLoading &&
          filteredOrderBumpData?.length > 0 &&
          filteredOrderBumpData.map(orderBump => (
            <OrderBumpCard
              key={orderBump.id}
              orderBump={orderBump}
              width={width}
              seOrderBumpId={seOrderBumpId}
              isDeletingOrderBump={isDeletingOrderBump}
              onOpenModalDeleteOrderBump={onOpenModalDeleteOrderBump}
              onOpenModalActiveOrderBump={onOpenModalActiveOrderBump}
              setOrderBumpActive={setOrderBumpActive}
            />
          ))}

        <Box width="100%" marginY={5} textAlign="center" hidden={!orderBumpLoading}>
          <Spinner colorScheme="primary" size="lg" />
        </Box>

        <Box width="100%" marginY={5} hidden={filteredOrderBumpData.length > 0 || orderBumpLoading}>
          <Text color="#20212380" fontSize="18px" textAlign="center">
            Nenhum order bump encontrado
          </Text>
        </Box>
      </Flex>

      <Stack
        width="full"
        justifyContent="center"
        alignItems="center"
        my={2}
        hidden={!filteredOrderBumpData.length}
      >
        <Pagination
          pageCount={pageCount}
          onPageChange={handlePageChange}
          onPageActive={currentPage - 1}
        />
      </Stack>

      <Modal isOpen={isOpenModalDeleteOrderBump} onClose={onCloseModalDeleteOrderBump} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stack direction="row" w="100%" alignItems="center" justifyContent="center">
              <IoMdAlert color="#BB2124" size="50px" />
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign="center" fontWeight={500} fontSize="16px">
              Tem certeza que deseja excluir esse Order Bump?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Stack direction="row" width="100%" justifyContent="center" spacing={5}>
              <Button
                variant="solid"
                colorScheme="gray"
                onClick={onCloseModalDeleteOrderBump}
                isDisabled={isDeletingOrderBump}
              >
                Não
              </Button>
              <Button
                variant="solid"
                colorScheme="red"
                onClick={handleDeleteOrderBump}
                isLoading={isDeletingOrderBump}
                isDisabled={isDeletingOrderBump}
              >
                Sim
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenModalActiveOrderBump} onClose={onCloseModalActiveOrderBump} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Stack direction="row" w="100%" alignItems="center" justifyContent="center">
              <MdOutlinePublishedWithChanges color="#1A202C" size="50px" />
            </Stack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign="center" fontWeight={500} fontSize="16px">
              Deseja {orderBumpActive ? 'desativar' : 'ativar'} esse Order Bump?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Stack direction="row" width="100%" justifyContent="center" spacing={5}>
              <Button
                variant="solid"
                colorScheme="gray"
                onClick={onCloseModalActiveOrderBump}
                isDisabled={isLoadingActiveOrderBump}
              >
                Não
              </Button>
              <Button
                variant="solid"
                colorScheme="primary"
                onClick={handleActiveOrderBump}
                isLoading={isLoadingActiveOrderBump}
                isDisabled={isLoadingActiveOrderBump}
              >
                Sim
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
