import { Box } from '@chakra-ui/react';

interface ImagePreviewProps {
  preview: string;
}

export default function ImagePreview({ preview }: ImagePreviewProps) {
  return (
    <Box
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      backgroundPosition="left"
      height="10rem"
      width="22.5rem"
      backgroundImage={`url("${preview}")`}
    />
  );
}
