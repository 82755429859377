import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { ChangeEvent, KeyboardEvent } from 'react';
import SearchIcon from '../icons/SearchIcon';

interface SearchInputProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export default function SearchInput({ onChange, onKeyDown, value }: SearchInputProps) {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event);
  }

  return (
    <InputGroup maxW={{ lg: '33%' }}>
      <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
      <Input
        value={value}
        borderRadius="md"
        py={4}
        placeholder="Buscar por nome ou e-mail"
        focusBorderColor="primary.500"
        _placeholder={{ fontSize: 'sm' }}
        onChange={handleChange}
        onKeyDown={onKeyDown}
      />
    </InputGroup>
  );
}
