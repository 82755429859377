import { Select as MultiSelect } from 'chakra-react-select';
import { JoiSchemasType } from '../../../../../validation/EntitySchema';
import { Webhook, WebhookView } from '../types';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  BoxProps,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import useWebhookProductsForm from './useWebhookProductsForm';
import { Validation } from '../../../../../hooks/useHandleSubmit';

interface WebhookFormProps extends BoxProps {
  url: string;
  method: 'post' | 'patch';
  successMessage: string;
  data?: WebhookView;
  schemaValidator?: JoiSchemasType<Partial<Webhook>>;
}

export default function WebhookProductsForm({
  url,
  method,
  successMessage,
  data,
  schemaValidator,
  ...rest
}: WebhookFormProps) {
  const {
    form,
    formValidation,
    isSubmitting,
    hasChange,
    products,
    selectedProducts,
    handleChange,
    onSubmit,
    handleSelectProducts,
    handleCancel,
    handleEventsSelect,
  } = useWebhookProductsForm({
    method,
    url,
    data,
    successMessage,
    schemaValidator,
  });

  return (
    <VStack
      as="form"
      spacing={8}
      onSubmit={onSubmit}
      {...rest}
      sx={{
        label: { fontSize: 'sm', color: '#202123' },
        'input::placeholder,textarea::placeholder': { color: '#2D374880' },
      }}
      noValidate
    >
      <FormControl isInvalid={!!formValidation?.webhookName.isInvalid}>
        <FormLabel>
          Nome do Webhook{' '}
          <Text as="span" color="red.500">
            *
          </Text>
        </FormLabel>

        <Input
          name="webhookName"
          value={form?.webhookName}
          onChange={handleChange}
          placeholder="Digite aqui"
          mt={2}
          focusBorderColor="gray.200"
          maxLength={255}
        />

        <FormErrorMessage>{formValidation?.webhookName?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!formValidation?.events[0]?.isInvalid}>
        <Text fontWeight="medium" color="#202123">
          Eventos a serem adicionados{' '}
          <Text as="span" color="red.500">
            *
          </Text>
        </Text>

        <FormErrorMessage>{formValidation?.events[0]?.message}</FormErrorMessage>

        <Accordion
          allowToggle
          gap={8}
          sx={{ '.chakra-accordion__item': { border: 'none' } }}
          defaultIndex={method === 'patch' ? [0, 1] : []}
        >
          <CheckboxGroup
            onChange={handleEventsSelect}
            defaultValue={form.events}
            colorScheme="primary"
          >
            <AccordionItem as="section">
              <AccordionButton px={0} _hover={{ bg: 'transparent' }}>
                <VStack align="start" w="full">
                  <HStack spacing={0}>
                    <Text fontSize="sm" fontWeight="medium">
                      Compras
                    </Text>
                    <AccordionIcon />
                  </HStack>
                </VStack>
              </AccordionButton>

              <AccordionPanel p={0}>
                <VStack align="start" spacing={2}>
                  <Checkbox value="FREE_SIGN_UP">Inscrição Gratuita</Checkbox>
                  <Checkbox value="ISSUANCE_PIX">Emissão de PIX</Checkbox>
                  <Checkbox value="ISSUANCE_BOLETO">Emissão de Boleto</Checkbox>
                  <Checkbox value="PURCHASE_APPROVED">Compra Aprovada</Checkbox>
                  <Checkbox value="PAYMENT_REFUSED">Pagamento Recusado</Checkbox>
                  <Checkbox value="REFUNDED">Estorno</Checkbox>
                  <Checkbox value="CHARGEDBACK">Chargeback</Checkbox>
                </VStack>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem as="section">
              <AccordionButton px={0} _hover={{ bg: 'transparent' }}>
                <VStack align="start" w="full">
                  <HStack spacing={0}>
                    <Text fontSize="sm" fontWeight="medium">
                      Outros
                    </Text>
                    <AccordionIcon />
                  </HStack>
                </VStack>
              </AccordionButton>

              <AccordionPanel p={0}>
                <VStack align="start" spacing={2}>
                  <Checkbox value="ADD_TO_CART">Adicionou Produto ao Carrinho</Checkbox>
                  <Checkbox value="PAGE_VIEW">Visitou Página de Vendas</Checkbox>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </CheckboxGroup>
        </Accordion>
      </FormControl>

      <FormControl isInvalid={!!(formValidation?.coursesIds as unknown as Validation)?.isInvalid}>
        <FormLabel>
          Produtos{' '}
          <Text as="span" color="red.500">
            *
          </Text>
        </FormLabel>

        <MultiSelect
          menuPlacement="bottom"
          placeholder="Busque ou selecione"
          focusBorderColor="gray.200"
          isMulti={true}
          noOptionsMessage={() => 'Nenhum produto encontrado.'}
          value={selectedProducts}
          options={products}
          onChange={handleSelectProducts}
          menuPortalTarget={document.body}
          chakraStyles={{
            container: provided => ({
              ...provided,
              width: '100%',
              color: '#20212380',
              fontSize: 'sm',
              _focusWithin: {
                zIndex: 1,
                borderColor: 'gray.200',
                boxShadow: '0 0 0 1px var(--chakra-colors-primary-200)',
                borderRadius: 'md',
              },
            }),
            placeholder: provided => ({
              ...provided,
              color: '#20212380',
              fontSize: 'sm',
            }),
            dropdownIndicator: provided => ({
              ...provided,
              bg: 'transparent',
              color: '#202123',
              px: 2.5,
            }),
            indicatorSeparator: provided => ({
              ...provided,
              display: 'none',
            }),
            option: provided => ({
              ...provided,
              color: '#20212380',
              fontSize: 'sm',
            }),
            valueContainer: provided => ({
              ...provided,
              px: 3,
              color: '#20212380',
              fontSize: 'sm',
            }),
            menuList: provided => ({
              ...provided,
              height: '12rem',
            }),
            menu: provided => ({
              ...provided,
              boxShadow: '0px 4px 4px 0px #00000040',
              height: '12rem',
              borderRadius: '0.375rem',
            }),
          }}
        />

        <FormErrorMessage>
          {(formValidation?.coursesIds as unknown as Validation)?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!formValidation?.webhookUrl.isInvalid}>
        <FormLabel>
          Enviar para a URL{' '}
          <Text as="span" color="red.500">
            *
          </Text>
        </FormLabel>

        <Input
          name="webhookUrl"
          value={form?.webhookUrl}
          onChange={handleChange}
          placeholder="Cole aqui"
          mt={2}
          focusBorderColor="gray.200"
        />

        <FormErrorMessage>{formValidation?.webhookUrl.message}</FormErrorMessage>
      </FormControl>

      {/* RODAPÉ */}
      <HStack w="full" as="footer" justify="end" my={10} spacing={5}>
        <Button
          onClick={handleCancel}
          bgColor="#e5e5e5"
          border="1px solid #20212340"
          _hover={{ bgColor: '#c2c2c2' }}
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isDisabled={isSubmitting}
        >
          Cancelar
        </Button>

        <Button
          colorScheme="primary"
          color="secondary.500"
          type="submit"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isLoading={isSubmitting}
          isDisabled={!hasChange}
        >
          Salvar
        </Button>
      </HStack>
    </VStack>
  );
}
