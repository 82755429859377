import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import AlertIcon from '../../../../../../assets/icons/alertIcon.svg';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  subjectName: string;
  handleSubmit: () => void;
  isDeleting: boolean;
}

export function ModalDeleteSubject({
  isOpen,
  onClose,
  subjectName,
  handleSubmit,
  isDeleting,
}: IProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent padding={3}>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5} justifyContent="center" alignItems="center">
            <Image src={AlertIcon} width="40px" />
            <Text fontWeight={700} fontSize="18px">
              Deseja excluir o assunto {subjectName}?
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter justifyContent="center" gap={3}>
          <Button colorScheme="gray" size="sm" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            isDisabled={isDeleting}
            isLoading={isDeleting}
            onClick={() => {
              handleSubmit();
              onClose();
            }}
          >
            Excluir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
