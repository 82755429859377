import { Alert, Avatar, Box, Flex, List, ListItem, Stack, Text } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PlanType } from '..';
import Button from '../../../components/Button';
import { Heading } from '../../../components/Heading';
import { useTheme } from '../../../contexts/ThemeContext';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import Brands from '../Brands';
import Card from '../Card';
import { IProduct } from '../types';
import useApplyCoupon from '../useApplyCoupon';
interface IPurchaseDetails {
  product: IProduct;
  errorMessage: string;
  loading: boolean;
  paymentMethod: string;
  paymentValue: string;
  setPaymentValue: Dispatch<SetStateAction<string | number>>;
  planSelected?: PlanType;
  hasAddress: boolean;
}

function PurchaseDetails({
  product,
  errorMessage,
  loading,
  paymentMethod,
  paymentValue,
  setPaymentValue,
  planSelected,
  hasAddress,
}: IPurchaseDetails) {
  const { isLoading } = useApplyCoupon(paymentValue);

  const { themeMarketplace } = useTheme();
  const [textButton, setTextButton] = useState('');
  const pathname = window.location.pathname;

  useEffect(() => {
    if (paymentMethod === 'creditCard') {
      const baseAmountFeesAndCommissions = product.baseAmountFeesAndCommissions
        ? [...product.baseAmountFeesAndCommissions]
        : [];

      const baseAmountFeesAndCommission = baseAmountFeesAndCommissions.length
        ? baseAmountFeesAndCommissions.shift()
        : null;

      if (baseAmountFeesAndCommission) {
        setPaymentValue(baseAmountFeesAndCommission.amount);
      } else {
        setPaymentValue(product.standardPaymentValue);
      }
    }
  }, [paymentMethod, product, product.baseAmountFeesAndCommissions, setPaymentValue]);

  function textPurchase(paymentMethod: string) {
    switch (paymentMethod) {
      case 'creditCard':
        setTextButton('Comprar Agora');
        break;
      case 'boleto':
        setTextButton('Gerar Boleto');
        break;
      case 'pix':
        setTextButton('Gerar QR Code');
        break;
    }
  }

  useEffect(() => {
    textPurchase(paymentMethod);
  }, [paymentMethod]);

  const instructors = product?.instructorsNames || [product?.owner];

  return (
    <Card>
      <Box paddingTop="6" paddingX="5" paddingBottom="5">
        <Heading as="h2" fontSize="xl" width="full" textAlign="center">
          Resumo da compra
        </Heading>

        <Flex flexDirection="row" alignItems="flex-start" gap="4" marginY="6">
          <Avatar
            src={product?.thumbnail}
            name={product?.name}
            borderRadius="sm"
            background={themeMarketplace.primaryColor}
            color={themeMarketplace.textColor}
            size="xl"
          />

          <Box alignItems="flex-start">
            <Heading as="h2" fontSize="xl" fontWeight="medium">
              {product.name}
            </Heading>

            {instructors.map(instructor => (
              <Text
                key={instructor}
                as="span"
                fontSize="sm"
                fontWeight="medium"
                color="dark.600"
                sx={{ span: { marginTop: '0.5rem' } }}
              >
                {instructor}
              </Text>
            ))}
          </Box>
        </Flex>

        <List
          flexDirection="row"
          sx={{
            'li:first-child': {
              marginBottom: '1rem',
            },
            'li + li': {
              marginTop: '0.375rem',
            },
            'li:last-child': {
              marginTop: '1rem',
            },
          }}
        >
          <ListItem
            fontSize="sm"
            color="dark.500"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Valor:
            <Text as="span" fontWeight="semibold">
              {planSelected
                ? convertToBrazilianCurrency(planSelected.value)
                : convertToBrazilianCurrency(paymentValue)}
            </Text>
          </ListItem>

          <ListItem
            fontSize="xl"
            fontWeight="semibold"
            color="orange.400"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Total:
            <Heading as="span" fontSize="xl" color="orange.400">
              {planSelected && convertToBrazilianCurrency(planSelected.value)}
            </Heading>
          </ListItem>
        </List>

        <Stack
          direction="row"
          w="100%"
          justifyContent="center"
          alignItems="center"
          spacing={5}
          my={5}
        >
          <Button
            type="submit"
            w="100%"
            colorScheme="orange"
            style={{ outline: 'none', boxShadow: 'none' }}
            isLoading={isLoading}
            isDisabled={
              !hasAddress ||
              isLoading ||
              loading ||
              pathname === `/product/${product.id}/subscription-checkout/personal-data`
            }
          >
            {textButton}
          </Button>
        </Stack>

        <Box display="flex" gap="2" marginTop="6">
          {/* <LockIcon w={4} h={4} color="primary.500" /> */}
          <Flex flexDirection="column" alignItems="flex-start">
            <Text as="span" fontSize="sm" fontWeight="light" color="primary.500">
              Suas informações estão seguras
            </Text>
            <Text as="span" fontSize="sm" fontWeight="light" color="dark.500">
              Todos os dados deste site são criptografados com protocolo SSL ao trafegarem pela
              internet.
            </Text>
          </Flex>
        </Box>
      </Box>

      {errorMessage && (
        <Alert wordBreak="break-word" status="error" role="alert">
          <Box textAlign="center">
            <Text>{errorMessage}</Text>
          </Box>
        </Alert>
      )}

      <Box
        padding="4"
        borderRadius="base"
        borderTop="1px solid rgba(32, 33, 35, 0.05)"
        background="rgba(32, 33, 35, 0.05)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Heading as="h4" marginBottom="4" fontSize="md">
          Formas de pagamento
        </Heading>

        <Brands />
      </Box>
    </Card>
  );
}

export default PurchaseDetails;
