import { MdChevronLeft as ChevronLeft, MdChevronRight as ChevronRight } from 'react-icons/md';
import styles from '../styles.module.css';
import { Box } from '@chakra-ui/react';

interface IParams {
  className?: string;
  onClick?: () => void;
}

export function PrevArrow({ className, onClick }: IParams) {
  return (
    <div>
      <ChevronLeft
        className={`${className} ${styles.prevArrow}`}
        onClick={onClick}
        color="#202123"
      />
    </div>
  );
}

export function NextArrow({ className, onClick }: IParams) {
  return (
    <div>
      <ChevronRight
        className={`${className} ${styles.nextArrow}`}
        onClick={onClick}
        color="#202123"
      />
    </div>
  );
}

export function CustomPrevArrow({ className, onClick }: IParams) {
  return (
    <Box
      className={`${className}`}
      onClick={onClick}
      textAlign="center"
      border="none !important"
      backgroundColor="transparent !important"
      cursor="pointer !important"
      left="-55px"
    >
      <ChevronLeft size="80px" color="#202123" />
    </Box>
  );
}

export function CustomNextArrow({ className, onClick }: IParams) {
  return (
    <Box
      className={`${className}`}
      onClick={onClick}
      textAlign="center"
      border="none !important"
      backgroundColor="transparent !important"
      cursor="pointer !important"
      right="-30px"
    >
      <ChevronRight size="80px" color="#202123" />
    </Box>
  );
}

export function PrevArrowInstructor({ className, onClick }: IParams) {
  return (
    <Box
      className={`${className}`}
      onClick={onClick}
      textAlign="center"
      border="none !important"
      backgroundColor="transparent !important"
      cursor="pointer !important"
      left="-60px"
    >
      <ChevronLeft color="#202123" />
    </Box>
  );
}

export function NextArrowInstructor({ className, onClick }: IParams) {
  return (
    <Box
      className={`${className}`}
      onClick={onClick}
      textAlign="center"
      border="none !important"
      backgroundColor="transparent !important"
      cursor="pointer !important"
      right="-45px"
    >
      <ChevronRight color="#202123" />
    </Box>
  );
}
