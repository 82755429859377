import { NextArrow, PrevArrow } from './arrows';

const lg = 3;
const md = 2;
const sm = 1;

export const responsiveBannerToShow = (bannerToShow = 1) => {
  return [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: bannerToShow > lg ? lg : bannerToShow,
        slidesToScroll: bannerToShow > lg ? lg : bannerToShow,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: bannerToShow > md ? md : bannerToShow,
        slidesToScroll: bannerToShow > md ? md : bannerToShow,
      },
    },
    {
      breakpoint: 668,
      settings: {
        slidesToShow: bannerToShow > sm ? sm : bannerToShow,
        slidesToScroll: bannerToShow > sm ? sm : bannerToShow,
      },
    },
  ];
};

const responsive = [
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
    breakpoint: 992,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 668,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

export const settingsBanner = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
};
