import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button, Link as ChakraLink, List, ListItem, Text, VStack } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import { useTheme } from '../../../../contexts/ThemeContext';
import { ROLE_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import dayjs from 'dayjs';

function getMenu(themeMarketplace) {
  return (
    themeMarketplace?.themeSections
      ?.find(section => section.name === 'header')
      ?.themeHeaderMenu?.filter(menuItem => menuItem.active) || []
  );
}

function getMenuItemPath(menuItem, isStudent) {
  const pathMap = {
    home: '/',
    about: '/about',
    courses: '/all-products',
    blog: '/blog',
    question: isStudent ? '/members-area/v1/questions' : '/question',
  };

  return pathMap[menuItem.name] || menuItem.path;
}

function getRole() {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const roles = {
    ALUNO: 'student',
    AFILIADO: 'affiliate',
    DONO: 'owner',
  };

  return roles[role] || 'owner';
}

export default function NavMenu({ isAuthenticated, onChangeToProductsMenusTab }) {
  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();
  const location = useLocation();
  const { membersArea } = useAuth();

  const menu = isLoadingThemeMarketplace ? [] : getMenu(themeMarketplace);
  const hasNetflixMemberArea = membersArea === 'netflix';

  const isDashboardV2 = hasNetflixMemberArea || location.pathname.includes('/v2');
  const version = isDashboardV2 ? 'v2' : 'v1';

  const role = getRole();

  const authenticatedDefaultMenu = [
    {
      label: 'Acessar Homepage',
      path: '/',
    },
    {
      label: 'Início',
      path: '/home',
    },
    {
      label: 'Meus Produtos',
      path: role === 'affiliate' ? '/dashboard' : '/products',
    },
    {
      label: 'Meu Perfil',
      path: '/profile',
    },
  ];

  const authenticatedStudentMenu = [
    {
      label: 'Meus produtos',
      path: `/members-area/${version}/products`,
    },
    {
      label: 'Pagamentos',
      path: `/members-area/${version}/payments`,
    },
    {
      label: 'Questões',
      path: `/members-area/${version}/questions`,
    },
    !isDashboardV2 && {
      label: 'Estatísticas',
      path: `/members-area/v1/statistics`,
    },
    {
      label: 'Perfil',
      path: `/members-area/${version}/profile`,
    },
  ];

  const authenticatedAffiliateMenu = [
    {
      label: 'Página Inicial',
      path: `/members-area/${version}/payments`,
    },
    {
      label: 'Dashboard',
      path: `/dashboard?startDate=${dayjs()
        .subtract(30, 'days')
        .format('YYYY-MM-DD')}&endDate=${dayjs().format('YYYY-MM-DD')}`,
    },
  ];

  function getAuthenticatedMenu() {
    const menus = {
      student: authenticatedStudentMenu,
      owner: authenticatedDefaultMenu,
      affiliate: authenticatedAffiliateMenu,
    };

    return menus[role] || authenticatedDefaultMenu;
  }

  function getMemberAreaName() {
    const memberAreas = {
      student: 'Sua Área de Aluno',
      owner: 'Sua Área de Assinante',
      affiliate: 'Sua Área de Afiliado',
    };

    return memberAreas[role] || 'Sua Área de Assinante';
  }

  return (
    <VStack as="nav" spacing={5}>
      <VStack w="full" align="start">
        <Text as="h3" color="#20212380" fontSize="sm" fontWeight="semibold">
          Páginas
        </Text>

        <List color="#202123" spacing={2.5} w="full" pl="1.875rem">
          {menu.map(menuItem => {
            const path = getMenuItemPath(menuItem, role);

            if (path === '/all-products') {
              return (
                <ListItem key={menuItem.path}>
                  <Button
                    variant="link"
                    onClick={location.pathname === path ? null : onChangeToProductsMenusTab}
                    size="sm"
                    color="#202123"
                    rightIcon={location.pathname === path ? null : <ChevronRightIcon />}
                    w="full"
                    flex={1}
                    justifyContent="space-between"
                    fontWeight={location.pathname === path ? 'bold' : 'normal'}
                    pointerEvents={location.pathname === path ? 'none' : 'auto'}
                  >
                    Produtos
                  </Button>
                </ListItem>
              );
            }

            return (
              <ListItem key={menuItem.path}>
                <ChakraLink
                  as={Link}
                  to={path}
                  textDecoration="none"
                  fontWeight={location.pathname === path ? 'bold' : 'normal'}
                >
                  {menuItem.label}
                </ChakraLink>
              </ListItem>
            );
          })}
        </List>
      </VStack>

      {isAuthenticated && (
        <VStack w="full" align="start">
          <Text as="h3" color="#20212380" fontSize="sm" fontWeight="semibold">
            {getMemberAreaName()}
          </Text>

          <List color="#202123" spacing={2.5} w="full" pl="1.875rem">
            {getAuthenticatedMenu().map(menuItem => {
              return (
                <ListItem key={menuItem.path}>
                  <ChakraLink
                    as={Link}
                    to={menuItem.path}
                    textDecoration="none"
                    fontWeight={location.pathname === menuItem.path ? 'bold' : 'normal'}
                  >
                    {menuItem.label}
                  </ChakraLink>
                </ListItem>
              );
            })}
          </List>
        </VStack>
      )}
    </VStack>
  );
}
