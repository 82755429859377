import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MdAdd, MdOutlineUpload } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import FullPageLoading from '../../../../components/FullPageLoading';
import useFetch from '../../../../hooks/useFetch';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import Header from './Header';
import Questions from './Questions';
import SearchContainer from './SearchContainer';
import Question from './types/Question';
import ModalImportQuestions from './ModalImportQuestions';
import LoginAsUser from '../../../../components/LoginAsUser';

const FETCH_CONFIG = {
  perPage: '10',
  hasPaginate: 'true',
};

export interface IResponse {
  data: Question[];
  total: number;
  page: number;
  per_page: number;
}

export default function QuestionLibrary() {
  const location = useLocation();
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryParams = new URLSearchParams(location.search);
  queryParams.set('perPage', FETCH_CONFIG.perPage);
  queryParams.set('hasPaginate', FETCH_CONFIG.hasPaginate);

  const searchParams = queryParams.toString();

  function clearQueries() {
    history.replace(location.pathname);
  }

  const {
    data,
    loading: isFetching,
    fetchData: fetchQuestions,
  } = useFetch<UnificadaFront.ResponseJSON<IResponse>>({
    url: `/question-library/question?${searchParams}`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const { handleSubmit: registerQuestion, isLoading: isSubmitting } = useHandleSubmit({
    data: { questionText: 'Texto de exemplo' },
    method: 'post',
    url: '/question-library/question',
    getError: true,
    authenticated: true,
    onSuccess: {
      callback: async () => {
        clearQueries();

        await fetchQuestions();
      },
    },
  });

  const response = data?.data;
  const questions = response?.data;
  const total = response?.total || 0;
  const perPage = response?.per_page || 1;

  const isLoading = isFetching || isSubmitting;
  const isSearching = !!searchParams;
  const isCounterVisible = total > 0 && isSearching;
  const isQuestionsVisible = !isLoading && questions?.length;
  const isNotFoundVisible = !isLoading && !questions?.length && isSearching;
  const isEmpty = !isLoading && !questions?.length && !isSearching;

  return (
    <Box minH="100vh">
      <LoginAsUser />

      <Header />

      <SearchContainer />

      <Container as="main" w="full" maxW="790px" px={{ base: 4, xl: 'none' }} py={2.5}>
        {isCounterVisible && (
          <Text color="#202123">
            {total > 1 ? 'Foram encontradas ' : 'Foi encontrada '}
            <Text as="strong" fontWeight="semibold">
              {total}
            </Text>{' '}
            {total > 1 ? 'questões' : 'questão'}.
          </Text>
        )}

        <Stack
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          spacing="10px"
          alignItems="center"
          w="full"
          mb={2}
          mt={8}
        >
          <Button
            onClick={registerQuestion}
            w="full"
            variant="outline"
            colorScheme="default"
            leftIcon={<MdAdd />}
          >
            Adicionar questão
          </Button>

          <Button
            onClick={onOpen}
            w="full"
            variant="outline"
            color="rgba(32, 33, 35, 0.50)"
            borderColor="rgba(32, 33, 35, 0.50)"
            colorScheme="gray"
            leftIcon={<MdOutlineUpload />}
          >
            Importar questões
          </Button>
        </Stack>

        {isNotFoundVisible && (
          <Center mt={32}>
            <Heading size="md" fontWeight="semibold" color="gray.300">
              Nenhuma questão encontrada...
            </Heading>
          </Center>
        )}

        {isEmpty && (
          <Text color="#0000007D" lineHeight="17px">
            Você não possui nenhum questão cadastrada. Clique no botão “Adicionar questão” e comece
            agora a adicionar novas questões. Essas questões serão usadas no conteúdo do tipo{' '}
            <Text as="strong" fontWeight="semibold">
              Questões.
            </Text>
          </Text>
        )}

        {isQuestionsVisible && (
          <Questions
            questions={questions}
            page={response.page}
            per_page={perPage}
            total={total}
            isLoading={isLoading}
            fetchQuestions={fetchQuestions}
          />
        )}
      </Container>

      <ModalImportQuestions isOpen={isOpen} onClose={onClose} fetchingQuestions={fetchQuestions} />

      <FullPageLoading isLoading={isLoading} />
    </Box>
  );
}
