import { CALLBACK_URL, composeUrlToRedirect } from './utils/composeUrlToRedirect';
import {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import Header from '../components/Header';
import Loading from '../components/Loading';
import { MdContentCopy } from 'react-icons/md';
import RDStationIntegrationAPI from '../../../../api/RDStationIntegration';
import Toast from '../../../../components/Toast';
import styles from './styles.module.css';

interface IDataRDToken {
  id: string;
  clientId: string;
  clientSecret: string;
  isAuthenticated: boolean;
}

function RDStation() {
  const [dataToken, setDataToken] = useState<IDataRDToken>({} as IDataRDToken);
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const shouldDisplayLoader = !errorMessage && isLoading;
  const shouldDisplayCongratulations = !errorMessage && !isLoading && dataToken.isAuthenticated;
  const shouldDisplayForm = !dataToken.isAuthenticated && !isLoading;

  const copyInputRef = useRef<HTMLDivElement>(null);

  const fetchDataToken = useCallback(async () => {
    try {
      const { data: response } = await RDStationIntegrationAPI.index();

      if (response && response.isAuthenticated) {
        setDataToken(response);
      }
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDataToken();
  }, [fetchDataToken]);

  function handleCopy(text: string, result: boolean) {
    if (text) setIsCopied(true);

    copyInputRef.current.style.border = 'none';

    if (result) Toast(`Link copiado com sucesso`);
  }

  function scrollToCopyInput() {
    copyInputRef.current.style.border = '2px solid #eb7129';
    copyInputRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  function handleRedirect(event: MouseEvent<HTMLAnchorElement>) {
    if (!isCopied) {
      event.preventDefault();
      scrollToCopyInput();
      Toast('Você precisa primeiro copiar o link', 'warn');
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;

    setIsDisabled(false);

    setDataToken(values => ({ ...values, [name]: value }));
  }

  function handleDiscardChanges(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    fetchDataToken();

    setIsDisabled(true);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const { id, clientId, clientSecret } = dataToken;

    const method = dataToken.id ? 'update' : 'create';

    const successMessage = method === 'create' ? 'realizada' : 'atualizada';

    try {
      if (method === 'create') await RDStationIntegrationAPI.store(clientId, clientSecret);

      if (method === 'update') await RDStationIntegrationAPI.update(id, clientId, clientSecret);

      Toast(`Integração ${successMessage} com sucesso`);

      setTimeout(() => {
        window.location.replace(composeUrlToRedirect(clientId));
      }, 1000);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setIsDisabled(true);
    }
  }

  return (
    <div className={styles.container}>
      <Header
        title="RD Station"
        tutorialLink="https://www.youtube.com/watch?v=uzG3FS7Gasc"
        description={
          'Ao integrar a sua plataforma ao RD Station, será possível acompanhar as ações dos alunos na própria plataforma.\nAs conversões geram eventos que permitem a identificação e o rastreio de suas atividades. Assim, é possível saber quando um usuário se registrou, comprou um produto.'
        }
      />

      {shouldDisplayLoader && <Loading />}

      {shouldDisplayCongratulations && (
        <>
          <h6>Parabéns você já fez a sua integração</h6>
          <p>Aqui estão as suas credenciais:</p>
        </>
      )}

      {shouldDisplayForm && (
        <>
          <h5>1º Passo</h5>

          <p>Copie a URL que está logo no campo abaixo: </p>

          <div ref={copyInputRef} className={styles.copyInput}>
            <div>
              <p>{CALLBACK_URL}</p>
            </div>

            <CopyToClipboard text={CALLBACK_URL} onCopy={handleCopy}>
              <button className={`${styles.copyButton}`}>
                <MdContentCopy size={14} />
                Copiar
              </button>
            </CopyToClipboard>
          </div>

          <p>
            Para acessar a sua conta RD Station Marketing,{' '}
            <strong>
              <a
                onClick={handleRedirect}
                target="_blank"
                href="https://appstore.rdstation.com/pt-BR/publisher"
                rel="noreferrer"
              >
                Clique aqui{' '}
              </a>
            </strong>{' '}
            , faça o login e depois clique na opção{' '}
            <strong style={{ color: '#202123' }}>Criar app</strong>. Insira a URL no campo{' '}
            <strong style={{ color: '#202123' }}>URLs de Callback na RD Station</strong> . Depois
            clique em Salvar e Avançar.
          </p>

          <h5>2º Passo</h5>

          <p>
            Após clicar em Salvar e Avançar na RD Station, ainda lá você deverá copiar os tokens{' '}
            <strong style={{ color: '#202123' }}>Client ID</strong> e{' '}
            <strong style={{ color: '#202123' }}>Client Secret.</strong> Em seguida cole esses
            tokens nos respectivos campos abaixo. Clique em Salvar para Solicitar a integração do
            serviço.
          </p>
          <p>
            Após ter o seu acesso liberado, a opção para autenticar o seu acesso aparecerá na mesma
            tela. Clique nela para concluir sua integração. Você será direcionado para a tela de
            login do RD Station Marketing. Selecione a sua conta e clique em{' '}
            <strong style={{ color: '#202123' }}>Continuar.</strong> Depois, clique em{' '}
            <strong style={{ color: '#202123' }}>Conectar</strong>. Pronto, com isso a sua
            integração com o RD Station Marketing estará finalizada.
          </p>
        </>
      )}

      {!isLoading && (
        <form onSubmit={handleSubmit}>
          <input
            name="clientId"
            value={dataToken.clientId}
            onChange={handleChange}
            placeholder="Client ID"
          />

          <input
            name="clientSecret"
            value={dataToken.clientSecret}
            onChange={handleChange}
            placeholder="Client Secret"
          />

          {errorMessage && (
            <div className="my-4">
              <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
                <div className="text-center">{errorMessage}</div>
              </div>
            </div>
          )}

          <div className={styles.formActions}>
            <button
              onClick={handleDiscardChanges}
              disabled={isDisabled}
              className={`btn btn-primary ${styles.cancelButton}`}
            >
              Cancelar
            </button>

            <button type="submit" disabled={isDisabled} className="btn btn-primary">
              Salvar
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default RDStation;
