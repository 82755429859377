import { Badge, Box, Breadcrumb, BreadcrumbItem, Checkbox, Stack, Text } from '@chakra-ui/react';
import Question from '../../QuestionLibrary/types/Question';

interface IProps {
  questions: Question[];
  allChecked: boolean;
  isIndeterminate: boolean;
  allCheckedOrUnchecked: (ids: number[]) => void;
  selectedCount: number;
  handleCheckboxChange: (questionIdChecked: number) => void;
  questionsIds: number[];
}

export default function QuestionCardToAdd({
  questions,
  allChecked,
  isIndeterminate,
  allCheckedOrUnchecked,
  selectedCount,
  handleCheckboxChange,
  questionsIds,
}: IProps) {
  return (
    <>
      <Box
        display="flex"
        padding="9px 0px 10px 22px"
        alignItems="center"
        width="full"
        marginBottom="10px"
        justifyContent="space-between"
        borderBottom="1px solid #E2E8F0"
      >
        <Checkbox
          isChecked={allChecked}
          isIndeterminate={isIndeterminate}
          colorScheme="orange"
          style={{ borderColor: '#EC7117', borderRadius: '2px' }}
          onChange={() => allCheckedOrUnchecked(questions?.map(question => question.id))}
          size="lg"
        >
          <Text
            color="#202123"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
            letterSpacing="0.6px"
          >
            Selecionar todas
          </Text>
        </Checkbox>

        <Stack
          color="#202123"
          textAlign="right"
          fontSize="12px"
          fontStyle="normal"
          lineHeight="16px"
          letterSpacing="0.6px"
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          alignItems="center"
          spacing={0}
          gap="4px"
        >
          <Text>
            <Text as="span" fontWeight="600">
              {selectedCount}{' '}
            </Text>
            questões
          </Text>
          <Text>selecionadas</Text>
        </Stack>
      </Box>

      <Stack spacing="10px">
        {questions?.length > 0 &&
          questions?.map((question, index) => (
            <Stack
              key={index}
              padding="20px"
              direction="column"
              alignItems="flex-start"
              gap="10px"
              borderRadius="10px"
              border="1px solid rgba(32, 33, 35, 0.05)"
              background="#FFF"
              boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
            >
              <Stack direction="row" spacing={0} alignItems="center" gap="8px" flexWrap="wrap">
                <Checkbox
                  // isChecked={checkedItems[index]}
                  isChecked={questionsIds.includes(question.id)}
                  onChange={() => handleCheckboxChange(question.id)}
                  colorScheme="orange"
                  style={{ borderColor: '#EC7117', borderRadius: '2px' }}
                  size="lg"
                />

                <Badge
                  display="flex"
                  height="20px"
                  padding="0px 8px"
                  alignItems="center"
                  gap="6px"
                  borderRadius="6px"
                  color="#fff"
                  background="#EB7129"
                >
                  <Text
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="16px"
                    color="#FFF"
                  >
                    ID: {question.id}
                  </Text>
                </Badge>

                <Breadcrumb
                  spacing="8px"
                  separator={'/'}
                  color="#AFAFAF"
                  fontFamily="Inter"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="24px"
                >
                  <BreadcrumbItem hidden={!question?.questionLibraryArea}>
                    <Text noOfLines={1}>{question?.questionLibraryArea?.name}</Text>
                  </BreadcrumbItem>
                  <BreadcrumbItem hidden={!question?.questionLibraryDiscipline}>
                    <Text noOfLines={1}>{question?.questionLibraryDiscipline?.name}</Text>
                  </BreadcrumbItem>
                  <BreadcrumbItem hidden={!question?.questionLibrarySubject}>
                    <Text noOfLines={1}>{question?.questionLibrarySubject?.name}</Text>
                  </BreadcrumbItem>

                  {question?.questionLibraryInstitution && (
                    <BreadcrumbItem>
                      <Text noOfLines={1}>{question?.questionLibraryInstitution?.name}</Text>
                    </BreadcrumbItem>
                  )}
                </Breadcrumb>
              </Stack>

              <Text
                color="#202123"
                fontSize="14px"
                fontWeight="400"
                lineHeight="20px"
                height="40px"
                noOfLines={2}
                dangerouslySetInnerHTML={{ __html: question.questionText }}
              />
            </Stack>
          ))}
      </Stack>
    </>
  );
}
