import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';

interface IParams {
  isOpen: boolean;
  onClose: () => void;
  handleDeleteContent: () => void;
}

export function ModalDeleteContent({ isOpen, onClose, handleDeleteContent }: IParams) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" fontWeight={700}>
          Tem certeza que deseja excluir esse conteúdo?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" fontWeight={500}>
          Todas as questões desse conteúdo também serão desvinculadas.
        </ModalBody>

        <ModalFooter>
          <Stack
            width="full"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Button colorScheme="red" onClick={onClose}>
              Não
            </Button>
            <Button onClick={handleDeleteContent}>Sim</Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
