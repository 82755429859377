import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FormEvent, useEffect, useState } from 'react';
import FrequentlyQuestionApi from '../../../../../../api/FrequentlyQuestion';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import EditQuestionModal from './EditQuestionModal';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import useFetch from '../../../../../../hooks/useFetch';

interface FrequentlyQuestion {
  id: number;
  question: string;
  questionAnswer: string;
}

export default function FrequentlyQuestions() {
  const { course } = useCourse();
  const toast = useToast();

  const [questionId, setQuestionId] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const {
    isOpen: isOpenEditQuestionModal,
    onOpen: onOpenEditQuestionModal,
    onClose: onCloseEditQuestionModal,
  } = useDisclosure();
  const {
    isOpen: isOpenConfirmDeleteModal,
    onOpen: onOpenConfirmDeleteModal,
    onClose: onCloseConfirmDeleteModal,
  } = useDisclosure();

  const { data: response, fetchData: getQuestions } = useFetch<
    UnificadaFront.ResponseJSON<FrequentlyQuestion[]>
  >({
    url: `/frequently-questions/${course.id}/course`,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  const questions = response?.data;

  const { form, setForm, handleChange } = useHandleChange({
    question: '',
    questionAnswer: '',
  });

  useEffect(() => {
    if (course.id) {
      getQuestions();
    }
  }, [course.id, getQuestions]);

  async function updateQuestion() {
    setIsEditing(true);
    try {
      await FrequentlyQuestionApi.update(course.id, questionId, form.question, form.questionAnswer);

      toast({
        title: 'Sua pergunta foi atualizada com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });

      setForm({
        question: '',
        questionAnswer: '',
      });

      getQuestions();
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } finally {
      onCloseEditQuestionModal();
      setIsEditing(false);
    }
  }

  async function deleteQuestion() {
    setIsDeleting(true);

    try {
      await FrequentlyQuestionApi.delete(course.id, questionId);

      toast({
        title: 'Sua pergunta foi excluída com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });

      getQuestions();
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } finally {
      setIsDeleting(false);
      onCloseConfirmDeleteModal();
    }
  }

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    try {
      await FrequentlyQuestionApi.store(course.id, form.question, form.questionAnswer);

      setForm({
        question: '',
        questionAnswer: '',
      });

      toast({
        title: 'Sua pergunta foi adicionada com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });

      getQuestions();
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  }

  return (
    <Box width="full">
      <Card width="full" as="form" onSubmit={onSubmit}>
        <CardHeader>
          <Heading as="h4" fontSize="2xl">
            Perguntas frequentes
          </Heading>
        </CardHeader>

        <CardBody width="full" display="flex" flexDirection="column" gap={2}>
          <FormControl>
            <FormLabel> Digite a sua pergunta</FormLabel>
            <Input
              name="question"
              fontSize="sm"
              size="sm"
              focusBorderColor="default.500"
              borderRadius="6px"
              placeholder="Ex: Não estou tendo acesso aos meus cursos"
              value={form.question}
              onChange={handleChange}
              minLength={6}
              maxLength={350}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Digite a sua pergunta</FormLabel>
            <Textarea
              onChange={handleChange}
              value={form.questionAnswer}
              name="questionAnswer"
              borderRadius="6px"
              fontSize="sm"
              size="sm"
              cols={25}
              rows={10}
              focusBorderColor="default.500"
              placeholder="Ex: basta clicar no menu de acesso aos clientes"
              minLength={6}
              maxLength={350}
            />
          </FormControl>

          <Box width="full" display="flex" justifyContent="flex-end" py={2}>
            <Button type="submit" size="sm" colorScheme="default">
              Salvar
            </Button>
          </Box>
        </CardBody>

        <CardFooter width="full" hidden={!questions?.length}>
          <VStack align="start" width="full">
            {questions?.map(({ id, question, questionAnswer }) => {
              return (
                <HStack width="full" justifyContent="space-between" key={id}>
                  <VStack align="start" width="full" spacing="0">
                    <Heading size="sm">{question}</Heading>
                    <Text fontSize="sm">{questionAnswer}</Text>
                  </VStack>

                  <HStack width="full" justifyContent="flex-end">
                    <Button
                      name="edit"
                      size="sm"
                      colorScheme="default"
                      variant="outline"
                      onClick={() => {
                        setQuestionId(id);
                        setForm({ question, questionAnswer });
                        onOpenEditQuestionModal();
                      }}
                    >
                      Editar
                    </Button>
                    <Button
                      name="delete"
                      size="sm"
                      colorScheme="red"
                      onClick={() => {
                        setQuestionId(id);
                        onOpenConfirmDeleteModal();
                      }}
                    >
                      Excluir
                    </Button>
                  </HStack>
                </HStack>
              );
            })}
          </VStack>
        </CardFooter>
      </Card>

      <EditQuestionModal
        isOpen={isOpenEditQuestionModal}
        onClose={onCloseEditQuestionModal}
        handleChange={handleChange}
        form={form}
        isEditing={isEditing}
        updateQuestion={updateQuestion}
      />

      <ConfirmDeleteModal
        isOpen={isOpenConfirmDeleteModal}
        onClose={onCloseConfirmDeleteModal}
        isDeleting={isDeleting}
        onSubmit={deleteQuestion}
      />
    </Box>
  );
}
