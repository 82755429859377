import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link as ChakraLink,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  FaFacebookSquare as Facebook,
  FaHeart as Heart,
  FaInstagram as Instagram,
  FaLinkedin as Linkedin,
  FaTelegramPlane as Telegram,
  FaWhatsapp as Whatsapp,
  FaYoutube as YouTube,
} from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext';

function getFooter(themeMarketplace) {
  const footer = themeMarketplace?.themeSections?.find(section => section.name === 'footer');
  return footer?.themeFooter;
}

function getMenu(themeMarketplace) {
  const header = themeMarketplace?.themeSections?.find(section => section.name === 'header');
  return header?.themeHeaderMenu?.filter(menuItem => menuItem.active);
}

function getDocumentType(document) {
  return document.length > 11 ? 'CNPJ' : 'CPF';
}

function maskDocument(value) {
  return value.length > 11
    ? value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    : value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

const initialStateFooter = {
  title: '',
  mission: '',
  informationPhone: '',
  informationEmail: '',
  urlSocialMediaFacebook: '',
  urlSocialMediaInstagram: '',
  urlSocialMediaWhatsapp: '',
  urlSocialMediaYouTube: '',
  urlSocialMediaTelegram: '',
};

function FooterHomeAdmin() {
  const [footer, setFooter] = useState(initialStateFooter);
  const [menu, setMenu] = useState([]);

  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      const themeFooter = getFooter(themeMarketplace);
      const menu = getMenu(themeMarketplace);

      setFooter(themeFooter);
      setMenu(menu);
    }
  }, [themeMarketplace, isLoadingThemeMarketplace]);

  function Pages() {
    return (
      <UnorderedList
        styleType="none"
        m={0}
        p={0}
        w={{ base: 'full', md: 'initial' }}
        maxW={{ base: '100%', md: '20%' }}
      >
        <Heading as="h3" size="md" mb={4} color="white">
          Páginas
        </Heading>
        {menu?.length > 0 &&
          menu.map(menuItem => {
            const pathMap = {
              '/home': '#',
              '/about': 'about',
              '/courses': 'all-products',
              '/blog': 'blog',
              '/all-instructors': 'all-instructors',
            };

            const path = pathMap[menuItem.path] || menuItem.path;

            return (
              <ListItem key={menuItem.id}>
                <Link to={path} style={{ color: '#fff', fontSize: '16px' }}>
                  {menuItem.label}
                </Link>
              </ListItem>
            );
          })}
      </UnorderedList>
    );
  }

  return (
    <Box
      as="footer"
      bg="#202123"
      px={{ base: 4, md: 8, lg: '6.75rem' }}
      pt={{ base: '2.75rem', md: 20 }}
      pb={0}
    >
      <Flex justify="space-between" wrap="wrap" gap={10} align="stretch">
        <UnorderedList styleType="none" m={0} p={0} w="full" maxW={{ base: '100%', md: '25%' }}>
          <Heading as="h3" size="md" mb={4} color="white">
            {footer?.title || 'Título'}
          </Heading>

          <Text fontSize="md" color="white">
            {footer?.mission || 'Missão'}
          </Text>
        </UnorderedList>

        <Pages />

        <UnorderedList
          styleType="none"
          m={0}
          p={0}
          w={{ base: 'full', md: 'initial' }}
          maxW={{ base: '100%', md: '20%' }}
        >
          <Heading as="h3" size="md" mb={4} color="white">
            Contato
          </Heading>

          <ListItem color="white" fontSize="md">
            {footer?.informationPhone}
          </ListItem>
          <ListItem color="white" fontSize="md">
            {footer?.informationEmail}
          </ListItem>
        </UnorderedList>

        <UnorderedList
          styleType="none"
          m={0}
          p={0}
          w={{ base: 'full', md: 'initial' }}
          maxW={{ base: '100%', md: '20%' }}
        >
          <Heading as="h3" size="md" mb={4} color="white">
            Redes Sociais
          </Heading>

          <Flex gap={4}>
            {footer?.urlSocialMediaInstagram && (
              <IconButton
                as="a"
                href={footer.urlSocialMediaInstagram}
                target="_blank"
                rel="noopener noreferrer"
                icon={<Instagram fontSize="20px" />}
                aria-label="Instagram"
                color="white"
                bg="transparent"
                _hover={{ color: 'white' }}
                minWidth="none"
              />
            )}

            {footer?.urlSocialMediaFacebook && (
              <IconButton
                as="a"
                href={footer.urlSocialMediaFacebook}
                target="_blank"
                rel="noopener noreferrer"
                icon={<Facebook fontSize="20px" />}
                aria-label="Facebook"
                color="white"
                bg="transparent"
                _hover={{ color: 'white' }}
                minWidth="none"
              />
            )}

            {footer?.urlSocialMediaTelegram && (
              <IconButton
                as="a"
                href={footer.urlSocialMediaTelegram}
                target="_blank"
                rel="noopener noreferrer"
                icon={<Telegram fontSize="20px" />}
                aria-label="Telegram"
                color="white"
                bg="transparent"
                _hover={{ color: 'white' }}
                minWidth="none"
              />
            )}

            {footer?.urlSocialMediaYouTube && (
              <IconButton
                as="a"
                href={footer.urlSocialMediaYouTube}
                target="_blank"
                rel="noopener noreferrer"
                icon={<YouTube fontSize="20px" />}
                aria-label="YouTube"
                color="white"
                bg="transparent"
                _hover={{ color: 'white' }}
                minWidth="none"
              />
            )}

            {footer?.urlSocialMediaLinkedin && (
              <IconButton
                as="a"
                href={footer.urlSocialMediaLinkedin}
                target="_blank"
                rel="noopener noreferrer"
                icon={<Linkedin fontSize="20px" />}
                aria-label="LinkedIn"
                color="white"
                bg="transparent"
                _hover={{ color: 'white' }}
                minWidth="none"
              />
            )}

            {footer?.urlSocialMediaWhatsapp && (
              <IconButton
                as="a"
                href={footer.urlSocialMediaWhatsapp}
                target="_blank"
                rel="noopener noreferrer"
                icon={<Whatsapp fontSize="20px" />}
                aria-label="WhatsApp"
                color="white"
                bg="transparent"
                _hover={{ color: 'white' }}
                minWidth="none"
              />
            )}
          </Flex>
        </UnorderedList>
      </Flex>

      <HStack mt={10} justify="start">
        <VStack color="white" align="start">
          {footer?.documentNumber && (
            <Box>
              <Text as="strong">{getDocumentType(footer?.documentNumber)}:</Text>{' '}
              <Text as="span">{maskDocument(footer?.documentNumber)}</Text>
            </Box>
          )}
          {footer?.termsAndPoliciesLink && (
            <ChakraLink
              as={Link}
              to="/termos-e-politicas"
              textDecoration="underline !important"
              fontWeight="medium"
              isExternal
            >
              Termos e Políticas
            </ChakraLink>
          )}
        </VStack>
      </HStack>

      <Flex mt="4.375rem" justify="center" align="center" py={5}>
        <ChakraLink
          href="https://ensine.proluno.com.br/"
          target="_blank"
          rel="noopener noreferrer"
          display="flex"
          alignItems="center"
          color="white"
        >
          Tecnologia
          <Box mx={2}>
            <Heart size={20} color="#EB7129" />
          </Box>
          Proluno - Ltda.
        </ChakraLink>
      </Flex>
    </Box>
  );
}

export default FooterHomeAdmin;
