type FontType = {
  id: number;
  label: string;
  family: string;
  weight?: number;
  style?: string;
};

export const fonts: FontType[] = [
  {
    id: 1,
    label: 'Arial',
    family: "'Arial', sans-serif",
    weight: 500,
  },

  // {
  //   id: 2,
  //   label: 'Arial Bold',
  //   family: "'Arial', sans-serif",
  //   weight: 700,
  // },

  {
    id: 4,
    label: 'Georgia',
    family: "'Georgia', sans-serif",
    weight: 500,
  },

  // {
  //   id: 5,
  //   label: 'Georgia Bold',
  //   family: "'Georgia', sans-serif",
  //   weight: 700,
  // },

  {
    id: 7,
    label: 'Montserrat',
    family: "'Montserrat', sans-serif",
    weight: 500,
  },

  // {
  //   id: 8,
  //   label: 'Montserrat Bold',
  //   family: "'Montserrat', sans-serif",
  //   weight: 700,
  // },

  // {
  //   id: 11,
  //   label: 'Open Sans Bold',
  //   family: "'Open Sans', sans-serif",
  //   weight: 700,
  // },
];
