import adImage from '../../assets/images/flags/ad.png';
import afImage from '../../assets/images/flags/af.png';
import agImage from '../../assets/images/flags/ag.png';
import aiImage from '../../assets/images/flags/ai.png';
import alImage from '../../assets/images/flags/al.png';
import amImage from '../../assets/images/flags/am.png';
import aoImage from '../../assets/images/flags/ao.png';
import aqImage from '../../assets/images/flags/aq.png';
import arImage from '../../assets/images/flags/ar.png';
import asImage from '../../assets/images/flags/as.png';
import awImage from '../../assets/images/flags/aw.png';
import axImage from '../../assets/images/flags/ax.png';
import dzImage from '../../assets/images/flags/dz.png';
// import acImage from '../../assets/images/flags/ac.png';
import atImage from '../../assets/images/flags/at.png';
import auImage from '../../assets/images/flags/au.png';
import azImage from '../../assets/images/flags/az.png';
import baImage from '../../assets/images/flags/ba.png';
import bbImage from '../../assets/images/flags/bb.png';
import bdImage from '../../assets/images/flags/bd.png';
import beImage from '../../assets/images/flags/be.png';
import bfImage from '../../assets/images/flags/bf.png';
import bgImage from '../../assets/images/flags/bg.png';
import bhImage from '../../assets/images/flags/bh.png';
import biImage from '../../assets/images/flags/bi.png';
import bjImage from '../../assets/images/flags/bj.png';
import bmImage from '../../assets/images/flags/bm.png';
import bnImage from '../../assets/images/flags/bn.png';
import boImage from '../../assets/images/flags/bo.png';
import brImage from '../../assets/images/flags/br.png';
import bsImage from '../../assets/images/flags/bs.png';
import btImage from '../../assets/images/flags/bt.png';
import bwImage from '../../assets/images/flags/bw.png';
import byImage from '../../assets/images/flags/by.png';
import bzImage from '../../assets/images/flags/bz.png';
import caImage from '../../assets/images/flags/ca.png';
import ccImage from '../../assets/images/flags/cc.png';
import cdImage from '../../assets/images/flags/cd.png';
import cfImage from '../../assets/images/flags/cf.png';
import cgImage from '../../assets/images/flags/cg.png';
import ckImage from '../../assets/images/flags/ck.png';
import clImage from '../../assets/images/flags/cl.png';
import cmImage from '../../assets/images/flags/cm.png';
import cnImage from '../../assets/images/flags/cn.png';
import coImage from '../../assets/images/flags/co.png';
import crImage from '../../assets/images/flags/cr.png';
import cuImage from '../../assets/images/flags/cu.png';
import cvImage from '../../assets/images/flags/cv.png';
import cxImage from '../../assets/images/flags/cx.png';
import cyImage from '../../assets/images/flags/cy.png';
import czImage from '../../assets/images/flags/cz.png';
import djImage from '../../assets/images/flags/dj.png';
import dkImage from '../../assets/images/flags/dk.png';
import dmImage from '../../assets/images/flags/dm.png';
import doImage from '../../assets/images/flags/do.png';
import hrImage from '../../assets/images/flags/hr.png';
import ioImage from '../../assets/images/flags/io.png';
import khImage from '../../assets/images/flags/kh.png';
import kmImage from '../../assets/images/flags/km.png';
import kyImage from '../../assets/images/flags/ky.png';
import tdImage from '../../assets/images/flags/td.png';
import usImage from '../../assets/images/flags/us.png';
// import ceImage from '../../assets/images/flags/ce.png';
import eeImage from '../../assets/images/flags/ee.png';
import egImage from '../../assets/images/flags/eg.png';
import erImage from '../../assets/images/flags/er.png';
import gqImage from '../../assets/images/flags/gq.png';
import svImage from '../../assets/images/flags/sv.png';
import szImage from '../../assets/images/flags/sz.png';
// import eImage from '../../assets/images/flags/e.png';
import deImage from '../../assets/images/flags/de.png';
import fiImage from '../../assets/images/flags/fi.png';
import fjImage from '../../assets/images/flags/fj.png';
import fkImage from '../../assets/images/flags/fk.png';
import foImage from '../../assets/images/flags/fo.png';
import frImage from '../../assets/images/flags/fr.png';
import gaImage from '../../assets/images/flags/ga.png';
import gdImage from '../../assets/images/flags/gd.png';
import geImage from '../../assets/images/flags/ge.png';
import gfImage from '../../assets/images/flags/gf.png';
import ggImage from '../../assets/images/flags/gg.png';
import ghImage from '../../assets/images/flags/gh.png';
import giImage from '../../assets/images/flags/gi.png';
import glImage from '../../assets/images/flags/gl.png';
import gmImage from '../../assets/images/flags/gm.png';
import gnImage from '../../assets/images/flags/gn.png';
import gpImage from '../../assets/images/flags/gp.png';
import grImage from '../../assets/images/flags/gr.png';
import gtImage from '../../assets/images/flags/gt.png';
import guImage from '../../assets/images/flags/gu.png';
import gwImage from '../../assets/images/flags/gw.png';
import gyImage from '../../assets/images/flags/gy.png';
import hkImage from '../../assets/images/flags/hk.png';
import hnImage from '../../assets/images/flags/hn.png';
import htImage from '../../assets/images/flags/ht.png';
import huImage from '../../assets/images/flags/hu.png';
import idImage from '../../assets/images/flags/id.png';
import inImage from '../../assets/images/flags/in.png';
import irImage from '../../assets/images/flags/ir.png';
import isImage from '../../assets/images/flags/is.png';
import pfImage from '../../assets/images/flags/pf.png';
import vaImage from '../../assets/images/flags/va.png';
// import qiImage from '../../assets/images/flags/qi.png';
import ciImage from '../../assets/images/flags/ci.png';
import ieImage from '../../assets/images/flags/ie.png';
import ilImage from '../../assets/images/flags/il.png';
import imImage from '../../assets/images/flags/im.png';
import itImage from '../../assets/images/flags/it.png';
import jeImage from '../../assets/images/flags/je.png';
import jmImage from '../../assets/images/flags/jm.png';
import joImage from '../../assets/images/flags/jo.png';
import jpImage from '../../assets/images/flags/jp.png';
import keImage from '../../assets/images/flags/ke.png';
import kgImage from '../../assets/images/flags/kg.png';
import kiImage from '../../assets/images/flags/ki.png';
import kpImage from '../../assets/images/flags/kp.png';
import krImage from '../../assets/images/flags/kr.png';
import kwImage from '../../assets/images/flags/kw.png';
import kzImage from '../../assets/images/flags/kz.png';
import xkImage from '../../assets/images/flags/xk.png';
// import oImage from '../../assets/images/flags/o.png';
import fmImage from '../../assets/images/flags/fm.png';
import lbImage from '../../assets/images/flags/lb.png';
import liImage from '../../assets/images/flags/li.png';
import lrImage from '../../assets/images/flags/lr.png';
import lsImage from '../../assets/images/flags/ls.png';
import ltImage from '../../assets/images/flags/lt.png';
import luImage from '../../assets/images/flags/lu.png';
import lvImage from '../../assets/images/flags/lv.png';
import lyImage from '../../assets/images/flags/ly.png';
import mcImage from '../../assets/images/flags/mc.png';
import mdImage from '../../assets/images/flags/md.png';
import mgImage from '../../assets/images/flags/mg.png';
import mhImage from '../../assets/images/flags/mh.png';
import mlImage from '../../assets/images/flags/ml.png';
import mnImage from '../../assets/images/flags/mn.png';
import moImage from '../../assets/images/flags/mo.png';
import mqImage from '../../assets/images/flags/mq.png';
import mrImage from '../../assets/images/flags/mr.png';
import mtImage from '../../assets/images/flags/mt.png';
import muImage from '../../assets/images/flags/mu.png';
import mvImage from '../../assets/images/flags/mv.png';
import mwImage from '../../assets/images/flags/mw.png';
import mxImage from '../../assets/images/flags/mx.png';
import myImage from '../../assets/images/flags/my.png';
import ytImage from '../../assets/images/flags/yt.png';
// import euImage from '../../assets/images/flags/eu.png';
import maImage from '../../assets/images/flags/ma.png';
import mmImage from '../../assets/images/flags/mm.png';
import msImage from '../../assets/images/flags/ms.png';
import mzImage from '../../assets/images/flags/mz.png';
import naImage from '../../assets/images/flags/na.png';
import nlImage from '../../assets/images/flags/nl.png';
import npImage from '../../assets/images/flags/np.png';
import nrImage from '../../assets/images/flags/nr.png';
// import anImage from '../../assets/images/flags/an.png';
import aeImage from '../../assets/images/flags/ae.png';
import blImage from '../../assets/images/flags/bl.png';
import chImage from '../../assets/images/flags/ch.png';
import esImage from '../../assets/images/flags/es.png';
import gbImage from '../../assets/images/flags/gb.png';
import gsImage from '../../assets/images/flags/gs.png';
import knImage from '../../assets/images/flags/kn.png';
import lcImage from '../../assets/images/flags/lc.png';
import lkImage from '../../assets/images/flags/lk.png';
import mfImage from '../../assets/images/flags/mf.png';
import mkImage from '../../assets/images/flags/mk.png';
import mpImage from '../../assets/images/flags/mp.png';
import ncImage from '../../assets/images/flags/nc.png';
import neImage from '../../assets/images/flags/ne.png';
import nfImage from '../../assets/images/flags/nf.png';
import ngImage from '../../assets/images/flags/ng.png';
import niImage from '../../assets/images/flags/ni.png';
import noImage from '../../assets/images/flags/no.png';
import nuImage from '../../assets/images/flags/nu.png';
import nzImage from '../../assets/images/flags/nz.png';
import omImage from '../../assets/images/flags/om.png';
import paImage from '../../assets/images/flags/pa.png';
import peImage from '../../assets/images/flags/pe.png';
import pgImage from '../../assets/images/flags/pg.png';
import phImage from '../../assets/images/flags/ph.png';
import pkImage from '../../assets/images/flags/pk.png';
import plImage from '../../assets/images/flags/pl.png';
import pmImage from '../../assets/images/flags/pm.png';
import pnImage from '../../assets/images/flags/pn.png';
import prImage from '../../assets/images/flags/pr.png';
import psImage from '../../assets/images/flags/ps.png';
import ptImage from '../../assets/images/flags/pt.png';
import pwImage from '../../assets/images/flags/pw.png';
import pyImage from '../../assets/images/flags/py.png';
import qaImage from '../../assets/images/flags/qa.png';
import reImage from '../../assets/images/flags/re.png';
import roImage from '../../assets/images/flags/ro.png';
import rsImage from '../../assets/images/flags/rs.png';
import ruImage from '../../assets/images/flags/ru.png';
import rwImage from '../../assets/images/flags/rw.png';
import saImage from '../../assets/images/flags/sa.png';
import sbImage from '../../assets/images/flags/sb.png';
import scImage from '../../assets/images/flags/sc.png';
import sdImage from '../../assets/images/flags/sd.png';
import seImage from '../../assets/images/flags/se.png';
import sgImage from '../../assets/images/flags/sg.png';
import shImage from '../../assets/images/flags/sh.png';
import siImage from '../../assets/images/flags/si.png';
import sjImage from '../../assets/images/flags/sj.png';
import skImage from '../../assets/images/flags/sk.png';
import slImage from '../../assets/images/flags/sl.png';
import smImage from '../../assets/images/flags/sm.png';
import snImage from '../../assets/images/flags/sn.png';
import soImage from '../../assets/images/flags/so.png';
import srImage from '../../assets/images/flags/sr.png';
import ssImage from '../../assets/images/flags/ss.png';
import stImage from '../../assets/images/flags/st.png';
import sxImage from '../../assets/images/flags/sx.png';
import syImage from '../../assets/images/flags/sy.png';
import tcImage from '../../assets/images/flags/tc.png';
import tgImage from '../../assets/images/flags/tg.png';
import thImage from '../../assets/images/flags/th.png';
import tjImage from '../../assets/images/flags/tj.png';
import tkImage from '../../assets/images/flags/tk.png';
import tlImage from '../../assets/images/flags/tl.png';
import tmImage from '../../assets/images/flags/tm.png';
import tnImage from '../../assets/images/flags/tn.png';
import toImage from '../../assets/images/flags/to.png';
import trImage from '../../assets/images/flags/tr.png';
import ttImage from '../../assets/images/flags/tt.png';
import tvImage from '../../assets/images/flags/tv.png';
import twImage from '../../assets/images/flags/tw.png';
import tzImage from '../../assets/images/flags/tz.png';
import uaImage from '../../assets/images/flags/ua.png';
import ugImage from '../../assets/images/flags/ug.png';
import uyImage from '../../assets/images/flags/uy.png';
import uzImage from '../../assets/images/flags/uz.png';
import vcImage from '../../assets/images/flags/vc.png';
import veImage from '../../assets/images/flags/ve.png';
import vgImage from '../../assets/images/flags/vg.png';
import viImage from '../../assets/images/flags/vi.png';
import vnImage from '../../assets/images/flags/vn.png';
import vuImage from '../../assets/images/flags/vu.png';
import wfImage from '../../assets/images/flags/wf.png';
import wsImage from '../../assets/images/flags/ws.png';
import zaImage from '../../assets/images/flags/za.png';
// import heImage from '../../assets/images/flags/he.png';
import zmImage from '../../assets/images/flags/zm.png';
import zwImage from '../../assets/images/flags/zw.png';

const data = [
  { country: 'Afeganistão', ddi: '93', initials: 'AF', flag: afImage },
  { country: 'Ilhas Aland', ddi: '358', initials: 'AX', flag: axImage },
  { country: 'Albânia', ddi: '355', initials: 'AL', flag: alImage },
  { country: 'Argélia', ddi: '213', initials: 'DZ', flag: dzImage },
  { country: 'AmericanSamoa', ddi: '1684', initials: 'AS', flag: asImage },
  { country: 'Andorra', ddi: '376', initials: 'AD', flag: adImage },
  { country: 'Angola', ddi: '244', initials: 'AO', flag: aoImage },
  { country: 'Anguilla', ddi: '1264', initials: 'AI', flag: aiImage },
  { country: 'Antártica', ddi: '672', initials: 'AQ', flag: aqImage },
  { country: 'Antígua e Barbuda', ddi: '1268', initials: 'AG', flag: agImage },
  { country: 'Argentina', ddi: '54', initials: 'AR', flag: arImage },
  { country: 'Armênia', ddi: '374', initials: 'AM', flag: amImage },
  { country: 'Aruba', ddi: '297', initials: 'AW', flag: awImage },
  { country: 'Ilha de Ascensão', ddi: '247', initials: 'AC', flag: null },
  { country: 'Austrália', ddi: '61', initials: 'AU', flag: auImage },
  { country: 'Áustria', ddi: '43', initials: 'AT', flag: atImage },
  { country: 'Azerbaijão', ddi: '994', initials: 'AZ', flag: azImage },
  { country: 'Bahamas', ddi: '1242', initials: 'BS', flag: bsImage },
  { country: 'Bahrein', ddi: '973', initials: 'BH', flag: bhImage },
  { country: 'Bangladesh', ddi: '880', initials: 'BD', flag: bdImage },
  { country: 'Barbados', ddi: '1246', initials: 'BB', flag: bbImage },
  { country: 'Bielorrússia', ddi: '375', initials: 'BY', flag: byImage },
  { country: 'Bélgica', ddi: '32', initials: 'BE', flag: beImage },
  { country: 'Belize', ddi: '501', initials: 'BZ', flag: bzImage },
  { country: 'Benin', ddi: '229', initials: 'BJ', flag: bjImage },
  { country: 'Bermudas', ddi: '1441', initials: 'BM', flag: bmImage },
  { country: 'Butão', ddi: '975', initials: 'BT', flag: btImage },
  { country: 'Bolívia', ddi: '591', initials: 'BO', flag: boImage },
  { country: 'Bósnia e Herzegovina', ddi: '387', initials: 'BA', flag: baImage },
  { country: 'Botsuana', ddi: '267', initials: 'BW', flag: bwImage },
  { country: 'Brasil', ddi: '55', initials: 'BR', flag: brImage },
  { country: 'Território Britânico do Oceano Índico', ddi: '246', initials: 'IO', flag: ioImage },
  { country: 'Brunei Darussalam', ddi: '673', initials: 'BN', flag: bnImage },
  { country: 'Bulgária', ddi: '359', initials: 'BG', flag: bgImage },
  { country: 'Burkina Faso', ddi: '226', initials: 'BF', flag: bfImage },
  { country: 'Burundi', ddi: '257', initials: 'BI', flag: biImage },
  { country: 'Camboja', ddi: '855', initials: 'KH', flag: khImage },
  { country: 'Camarões', ddi: '237', initials: 'CM', flag: cmImage },
  { country: 'Estados Unidos', ddi: '1', initials: 'US', flag: usImage },
  { country: 'Canadá', ddi: '1', initials: 'CA', flag: caImage },
  { country: 'Cabo Verde', ddi: '238', initials: 'CV', flag: cvImage },
  { country: 'Ilhas Cayman', ddi: '1345', initials: 'KY', flag: kyImage },
  { country: 'República Centro-Africana', ddi: '236', initials: 'CF', flag: cfImage },
  { country: 'Chade', ddi: '235', initials: 'TD', flag: tdImage },
  { country: 'Chile', ddi: '56', initials: 'CL', flag: clImage },
  { country: 'China', ddi: '86', initials: 'CN', flag: cnImage },
  { country: 'Ilha do Natal', ddi: '61', initials: 'CX', flag: cxImage },
  { country: 'Ilhas Cocos (Keeling)', ddi: '61', initials: 'CC', flag: ccImage },
  { country: 'Colômbia', ddi: '57', initials: 'CO', flag: coImage },
  { country: 'Comores', ddi: '269', initials: 'KM', flag: kmImage },
  { country: 'Congo', ddi: '242', initials: 'CG', flag: cgImage },
  { country: 'Ilhas Cook', ddi: '682', initials: 'CK', flag: ckImage },
  { country: 'Costa Rica', ddi: '506', initials: 'CR', flag: crImage },
  { country: 'Croácia', ddi: '385', initials: 'HR', flag: hrImage },
  { country: 'Cuba', ddi: '53', initials: 'CU', flag: cuImage },
  { country: 'Chipre', ddi: '357', initials: 'CY', flag: cyImage },
  { country: 'República Checa', ddi: '420', initials: 'CZ', flag: czImage },
  { country: 'República Democrática do Congo', ddi: '243', initials: 'CD', flag: cdImage },
  { country: 'Dinamarca', ddi: '45', initials: 'DK', flag: dkImage },
  { country: 'Djibuti', ddi: '253', initials: 'DJ', flag: djImage },
  { country: 'Dominica', ddi: '1767', initials: 'DM', flag: dmImage },
  { country: 'República Dominicana', ddi: '1849', initials: 'DO', flag: doImage },
  { country: 'Equador', ddi: '593', initials: 'CE', flag: null },
  { country: 'Egito', ddi: '20', initials: 'EG', flag: egImage },
  { country: 'El Salvador', ddi: '503', initials: 'SV', flag: svImage },
  { country: 'Guiné Equatorial', ddi: '240', initials: 'GQ', flag: gqImage },
  { country: 'Eritreia', ddi: '291', initials: 'ER', flag: erImage },
  { country: 'Estônia', ddi: '372', initials: 'EE', flag: eeImage },
  { country: 'Suazilândia', ddi: '268', initials: 'SZ', flag: szImage },
  { country: 'Etiópia', ddi: '251', initials: 'E', flag: null },
  { country: 'Ilhas Falkland (Falcons)', ddi: '500', initials: 'FK', flag: fkImage },
  { country: 'Ilhas Faroé', ddi: '298', initials: 'FO', flag: foImage },
  { country: 'Fiji', ddi: '679', initials: 'FJ', flag: fjImage },
  { country: 'Finlândia', ddi: '358', initials: 'FI', flag: fiImage },
  { country: 'França', ddi: '33', initials: 'FR', flag: frImage },
  { country: 'Guiana Francesa', ddi: '594', initials: 'GF', flag: gfImage },
  { country: 'Polinésia Francesa', ddi: '689', initials: 'PF', flag: pfImage },
  { country: 'Gabão', ddi: '241', initials: 'GA', flag: gaImage },
  { country: 'Gâmbia', ddi: '220', initials: 'GM', flag: gmImage },
  { country: 'Geórgia', ddi: '995', initials: 'GE', flag: geImage },
  { country: 'Alemanha', ddi: '49', initials: 'DE', flag: deImage },
  { country: 'Gana', ddi: '233', initials: 'GH', flag: ghImage },
  { country: 'Gibraltar', ddi: '350', initials: 'GI', flag: giImage },
  { country: 'Grécia', ddi: '30', initials: 'GR', flag: grImage },
  { country: 'Groenlândia', ddi: '299', initials: 'GL', flag: glImage },
  { country: 'Granada', ddi: '1473', initials: 'GD', flag: gdImage },
  { country: 'Guadalupe', ddi: '590', initials: 'GP', flag: gpImage },
  { country: 'Guam', ddi: '1671', initials: 'GU', flag: guImage },
  { country: 'Guatemala', ddi: '502', initials: 'GT', flag: gtImage },
  { country: 'Guernsey', ddi: '44', initials: 'GG', flag: ggImage },
  { country: 'Guiné', ddi: '224', initials: 'GN', flag: gnImage },
  { country: 'Guiné-Bissau', ddi: '245', initials: 'GW', flag: gwImage },
  { country: 'Guiana', ddi: '592', initials: 'GY', flag: gyImage },
  { country: 'Haiti', ddi: '509', initials: 'HT', flag: htImage },
  {
    country: 'Santa Sé (Estado da Cidade do Vaticano)',
    ddi: '379',
    initials: 'VA',
    flag: vaImage,
  },
  { country: 'Honduras', ddi: '504', initials: 'HN', flag: hnImage },
  { country: 'Hong Kong', ddi: '852', initials: 'HK', flag: hkImage },
  { country: 'Hungria', ddi: '36', initials: 'HU', flag: huImage },
  { country: 'Islândia', ddi: '354', initials: 'IS', flag: isImage },
  { country: 'Índia', ddi: '91', initials: 'IN', flag: inImage },
  { country: 'Indonésia', ddi: '62', initials: 'ID', flag: idImage },
  { country: 'Irã', ddi: '98', initials: 'IR', flag: irImage },
  { country: 'Iraque', ddi: '964', initials: 'QI', flag: null },
  { country: 'Irlanda', ddi: '353', initials: 'IE', flag: ieImage },
  { country: 'Ilha de Man', ddi: '44', initials: 'IM', flag: imImage },
  { country: 'Israel', ddi: '972', initials: 'IL', flag: ilImage },
  { country: 'Itália', ddi: '39', initials: 'IT', flag: itImage },
  { country: 'Costa do Marfim / Costa do Marfim', ddi: '225', initials: 'CI', flag: ciImage },
  { country: 'Jamaica', ddi: '1876', initials: 'JM', flag: jmImage },
  { country: 'Japão', ddi: '81', initials: 'JP', flag: jpImage },
  { country: 'Jersey', ddi: '44', initials: 'JE', flag: jeImage },
  { country: 'Jordânia', ddi: '962', initials: 'JO', flag: joImage },
  { country: 'Cazaquistão', ddi: '77', initials: 'KZ', flag: kzImage },
  { country: 'Quênia', ddi: '254', initials: 'KE', flag: keImage },
  { country: 'Kiribati', ddi: '686', initials: 'KI', flag: kiImage },
  {
    country: 'Coreia, República Popular Democrática da Coreia',
    ddi: '850',
    initials: 'KP',
    flag: kpImage,
  },
  { country: 'Coreia, República da Coreia do Sul', ddi: '82', initials: 'KR', flag: krImage },
  { country: 'Kosovo', ddi: '383', initials: 'XK', flag: xkImage },
  { country: 'Kuwait', ddi: '965', initials: 'KW', flag: kwImage },
  { country: 'Quirguistão', ddi: '996', initials: 'KG', flag: kgImage },
  { country: 'Laos', ddi: '856', initials: 'O', flag: null },
  { country: 'Letónia', ddi: '371', initials: 'LV', flag: lvImage },
  { country: 'Líbano', ddi: '961', initials: 'LB', flag: lbImage },
  { country: 'Lesoto', ddi: '266', initials: 'LS', flag: lsImage },
  { country: 'Libéria', ddi: '231', initials: 'LR', flag: lrImage },
  { country: 'Líbia', ddi: '218', initials: 'LY', flag: lyImage },
  { country: 'Liechtenstein', ddi: '423', initials: 'LI', flag: liImage },
  { country: 'Lituânia', ddi: '370', initials: 'LT', flag: ltImage },
  { country: 'Luxemburgo', ddi: '352', initials: 'LU', flag: luImage },
  { country: 'Macau', ddi: '853', initials: 'MO', flag: moImage },
  { country: 'Madagascar', ddi: '261', initials: 'MG', flag: mgImage },
  { country: 'Malauí', ddi: '265', initials: 'MW', flag: mwImage },
  { country: 'Malásia', ddi: '60', initials: 'MY', flag: myImage },
  { country: 'Maldivas', ddi: '960', initials: 'MV', flag: mvImage },
  { country: 'Mali', ddi: '223', initials: 'ML', flag: mlImage },
  { country: 'Malta', ddi: '356', initials: 'MT', flag: mtImage },
  { country: 'Ilhas Marshall', ddi: '692', initials: 'MH', flag: mhImage },
  { country: 'Martinica', ddi: '596', initials: 'MQ', flag: mqImage },
  { country: 'Mauritânia', ddi: '222', initials: 'MR', flag: mrImage },
  { country: 'Maurício', ddi: '230', initials: 'MU', flag: muImage },
  { country: 'Mayotte', ddi: '262', initials: 'YT', flag: ytImage },
  { country: 'México', ddi: '52', initials: 'MX', flag: mxImage },
  {
    country: 'Micronésia, Estados Federados da Micronésia',
    ddi: '691',
    initials: 'FM',
    flag: fmImage,
  },
  { country: 'Moldávia', ddi: '373', initials: 'MD', flag: mdImage },
  { country: 'Mônaco', ddi: '377', initials: 'MC', flag: mcImage },
  { country: 'Mongólia', ddi: '976', initials: 'MN', flag: mnImage },
  { country: 'Montenegro', ddi: '382', initials: 'EU', flag: null },
  { country: 'Montserrat', ddi: '1664', initials: 'MS', flag: msImage },
  { country: 'Marrocos', ddi: '212', initials: 'MA', flag: maImage },
  { country: 'Moçambique', ddi: '258', initials: 'MZ', flag: mzImage },
  { country: 'Mianmar', ddi: '95', initials: 'MM', flag: mmImage },
  { country: 'Namíbia', ddi: '264', initials: 'NA', flag: naImage },
  { country: 'Nauru', ddi: '674', initials: 'NR', flag: nrImage },
  { country: 'Nepal', ddi: '977', initials: 'NP', flag: npImage },
  { country: 'Holanda', ddi: '31', initials: 'NL', flag: nlImage },
  { country: 'Antilhas Holandesas', ddi: '599', initials: 'AN', flag: null },
  { country: 'Nova Caledônia', ddi: '687', initials: 'NC', flag: ncImage },
  { country: 'Nova Zelândia', ddi: '64', initials: 'NZ', flag: nzImage },
  { country: 'Nicarágua', ddi: '505', initials: 'NI', flag: niImage },
  { country: 'Níger', ddi: '227', initials: 'NE', flag: neImage },
  { country: 'Nigéria', ddi: '234', initials: 'NG', flag: ngImage },
  { country: 'Niue', ddi: '683', initials: 'NU', flag: nuImage },
  { country: 'Ilha Norfolk', ddi: '672', initials: 'NF', flag: nfImage },
  { country: 'Macedônia do Norte', ddi: '389', initials: 'MK', flag: mkImage },
  { country: 'Ilhas Marianas do Norte', ddi: '1670', initials: 'MP', flag: mpImage },
  { country: 'Noruega', ddi: '47', initials: 'NO', flag: noImage },
  { country: 'Omã', ddi: '968', initials: 'OM', flag: omImage },
  { country: 'Paquistão', ddi: '92', initials: 'PK', flag: pkImage },
  { country: 'Palau', ddi: '680', initials: 'PW', flag: pwImage },
  { country: 'Palestina', ddi: '970', initials: 'PS', flag: psImage },
  { country: 'Panamá', ddi: '507', initials: 'PA', flag: paImage },
  { country: 'Papua Nova Guiné', ddi: '675', initials: 'PG', flag: pgImage },
  { country: 'Paraguai', ddi: '595', initials: 'PY', flag: pyImage },
  { country: 'Peru', ddi: '51', initials: 'PE', flag: peImage },
  { country: 'Filipinas', ddi: '63', initials: 'PH', flag: phImage },
  { country: 'Pitcairn', ddi: '872', initials: 'PN', flag: pnImage },
  { country: 'Polônia', ddi: '48', initials: 'PL', flag: plImage },
  { country: 'Portugal', ddi: '351', initials: 'PT', flag: ptImage },
  { country: 'Porto Rico', ddi: '1939', initials: 'PR', flag: prImage },
  { country: 'Catar', ddi: '974', initials: 'QA', flag: qaImage },
  { country: 'Reunião', ddi: '262', initials: 'RE', flag: reImage },
  { country: 'Romênia', ddi: '40', initials: 'RO', flag: roImage },
  { country: 'Rússia', ddi: '7', initials: 'RU', flag: ruImage },
  { country: 'Ruanda', ddi: '250', initials: 'RW', flag: rwImage },
  { country: 'Saint Barthelemy', ddi: '590', initials: 'BL', flag: blImage },
  {
    country: 'Santa Helena, Ascensão e Tristão da Cunha',
    ddi: '290',
    initials: 'SH',
    flag: shImage,
  },
  { country: 'São Cristóvão e Nevis', ddi: '1869', initials: 'KN', flag: knImage },
  { country: 'Santa Lúcia', ddi: '1758', initials: 'LC', flag: lcImage },
  { country: 'São Martinho', ddi: '590', initials: 'MF', flag: mfImage },
  { country: 'São Pedro e Miquelon', ddi: '508', initials: 'PM', flag: pmImage },
  { country: 'São Vicente e Granadinas', ddi: '1784', initials: 'VC', flag: vcImage },
  { country: 'Samoa', ddi: '685', initials: 'WS', flag: wsImage },
  { country: 'San Marino', ddi: '378', initials: 'SM', flag: smImage },
  { country: 'São Tomé e Príncipe', ddi: '239', initials: 'ST', flag: stImage },
  { country: 'Arábia Saudita', ddi: '966', initials: 'SA', flag: saImage },
  { country: 'Senegal', ddi: '221', initials: 'SN', flag: snImage },
  { country: 'Sérvia', ddi: '381', initials: 'RS', flag: rsImage },
  { country: 'Seychelles', ddi: '248', initials: 'SC', flag: scImage },
  { country: 'Serra Leoa', ddi: '232', initials: 'SL', flag: slImage },
  { country: 'Singapura', ddi: '65', initials: 'SG', flag: sgImage },
  { country: 'Sint Maarten', ddi: '1721', initials: 'SX', flag: sxImage },
  { country: 'Eslováquia', ddi: '421', initials: 'SK', flag: skImage },
  { country: 'Eslovênia', ddi: '386', initials: 'SI', flag: siImage },
  { country: 'Ilhas Salomão', ddi: '677', initials: 'SB', flag: sbImage },
  { country: 'Somália', ddi: '252', initials: 'SO', flag: soImage },
  { country: 'África do Sul', ddi: '27', initials: 'ZA', flag: zaImage },
  {
    country: 'Geórgia do Sul e Ilhas Sandwich do Sul',
    ddi: '500',
    initials: 'GS',
    flag: gsImage,
  },
  { country: 'Sudão do Sul', ddi: '211', initials: 'SS', flag: ssImage },
  { country: 'Espanha', ddi: '34', initials: 'ES', flag: esImage },
  { country: 'Sri Lanka', ddi: '94', initials: 'LK', flag: lkImage },
  { country: 'Sudão', ddi: '249', initials: 'SD', flag: sdImage },
  { country: 'Suripaís', ddi: '597', initials: 'SR', flag: srImage },
  { country: 'Svalbard e Jan Mayen', ddi: '47', initials: 'SJ', flag: sjImage },
  { country: 'Suécia', ddi: '46', initials: 'SE', flag: seImage },
  { country: 'Suíça', ddi: '41', initials: 'CH', flag: chImage },
  { country: 'República Árabe Síria', ddi: '963', initials: 'SY', flag: syImage },
  { country: 'Taiwan', ddi: '886', initials: 'TW', flag: twImage },
  { country: 'Tadjiquistão', ddi: '992', initials: 'TJ', flag: tjImage },
  { country: 'Tanzânia, República Unida da Tanzânia', ddi: '255', initials: 'TZ', flag: tzImage },
  { country: 'Tailândia', ddi: '66', initials: 'TH', flag: thImage },
  { country: 'Timor-Leste', ddi: '670', initials: 'TL', flag: tlImage },
  { country: 'Togo', ddi: '228', initials: 'TG', flag: tgImage },
  { country: 'Tokelau', ddi: '690', initials: 'TK', flag: tkImage },
  { country: 'Tonga', ddi: '676', initials: 'TO', flag: toImage },
  { country: 'Trinidad e Tobago', ddi: '1868', initials: 'TT', flag: ttImage },
  { country: 'Tunísia', ddi: '216', initials: 'TN', flag: tnImage },
  { country: 'Turquia', ddi: '90', initials: 'TR', flag: trImage },
  { country: 'Turquemenistão', ddi: '993', initials: 'TM', flag: tmImage },
  { country: 'Ilhas Turks e Caicos', ddi: '1649', initials: 'TC', flag: tcImage },
  { country: 'Tuvalu', ddi: '688', initials: 'TV', flag: tvImage },
  { country: 'Uganda', ddi: '256', initials: 'UG', flag: ugImage },
  { country: 'Ucrânia', ddi: '380', initials: 'UA', flag: uaImage },
  { country: 'Emirados Árabes Unidos', ddi: '971', initials: 'AE', flag: aeImage },
  { country: 'Reino Unido', ddi: '44', initials: 'GB', flag: gbImage },
  { country: 'Uruguai', ddi: '598', initials: 'UY', flag: uyImage },
  { country: 'Uzbequistão', ddi: '998', initials: 'UZ', flag: uzImage },
  { country: 'Vanuatu', ddi: '678', initials: 'VU', flag: vuImage },
  {
    country: 'Venezuela, República Bolivariana da Venezuela',
    ddi: '58',
    initials: 'VE',
    flag: veImage,
  },
  { country: 'Vietnã', ddi: '84', initials: 'VN', flag: vnImage },
  { country: 'Ilhas Virgens Britânicas', ddi: '1284', initials: 'VG', flag: vgImage },
  { country: 'Ilhas Virgens, EUA', ddi: '1340', initials: 'VI', flag: viImage },
  { country: 'Wallis e Futuna', ddi: '681', initials: 'WF', flag: wfImage },
  { country: 'Iêmen', ddi: '967', initials: 'HE', flag: null },
  { country: 'Zâmbia', ddi: '260', initials: 'ZM', flag: zmImage },
  { country: 'Zimbabwe', ddi: '263', initials: 'ZW', flag: zwImage },
];

export default data;
