import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { FormEvent } from 'react';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { IShowcase } from '..';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';

interface IProps {
  isOpen: boolean;
  showcaseCourseId: number;
  showcase: IShowcase;
  showcases: IShowcase[];
  onClose: () => void;
  setShowcases: (showcases: IShowcase[]) => void;
}

export function RemoveProductBannerModal({
  isOpen,
  showcaseCourseId,
  showcase,
  showcases,
  onClose,
  setShowcases,
}: IProps) {
  function afterRemoveProductBannerConfirm() {
    const showcaseIndex = showcases.findIndex(
      showcaseData => showcaseData.showcaseId === showcase.showcaseId
    );

    if (showcaseIndex === -1) {
      return onClose();
    }

    const updatedShowcases = [...showcases];

    const showcaseCourse = updatedShowcases[showcaseIndex].products.find(
      product => product.showcaseCourseId === showcaseCourseId
    );

    showcaseCourse.courseThumbnail = null;
    showcaseCourse.hasCourseThumbnailNetflix = false;

    setShowcases(updatedShowcases);

    onClose();
  }

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    url: `/showcase-course/${showcaseCourseId}/remove-banner`,
    data: { showcaseType: showcase.showcaseType },
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Banner do produto removido com sucesso!',
      callback: afterRemoveProductBannerConfirm,
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'sm', lg: 'lg' }} isCentered>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit} textAlign="center" padding="15px" margin="15px">
        <ModalCloseButton />

        <Box width="full" display="flex" justifyContent="center" color="red.500">
          <IoAlertCircleOutline fontSize="80px" />
        </Box>

        <ModalHeader fontSize="18px" fontWeight={600}>
          Exclusão de banner
        </ModalHeader>

        <ModalBody fontSize="14px" fontWeight={500}>
          Tem certeza que deseja excluir o banner desse produto?
        </ModalBody>

        <ModalFooter>
          <Stack
            width="full"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Button colorScheme="red" width="150px" onClick={onClose}>
              Não
            </Button>
            <Button
              colorScheme="gray"
              width="150px"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              Sim
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
