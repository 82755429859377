import { Box, HStack, Heading, Text, Image } from '@chakra-ui/react';
import prolunoLogo from './proluno-logo.svg';
import { Link } from 'react-router-dom';

export default function WebhookBanner() {
  return (
    <Box as="section" mt={38}>
      <Heading fontWeight="medium" fontSize="xl">
        Webhook
      </Heading>

      <Link to="/integrations/webhook">
        <HStack
          bgColor="#EC7117"
          borderRadius="5px"
          boxShadow="0px 4px 4px 0px #00000040"
          color="#ffffff"
          mt={5}
          p={3}
          gap={2.5}
        >
          <Image src={prolunoLogo} alt="Logo da Proluno" />

          <Box>
            <Heading as="h3" fontWeight="medium" fontSize="xl">
              Proluno
            </Heading>

            <Text fontWeight="medium" fontSize="sm">
              Configure webhooks para sincronizar dados de vendas em tempo real com outros apps ou
              sistemas, automatizando atualizações e ações de acordo com o status da venda. Esse
              recurso é útil quando você precisa atualizar dados em outro sistema e/ou tomar uma
              ação conforme o status da venda em questão.
            </Text>
          </Box>
        </HStack>
      </Link>
    </Box>
  );
}
