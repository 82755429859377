import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { IoAlertCircleOutline } from 'react-icons/io5';

export default function MaximumAmountReachedRedoContent({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bgColor="#37393D" color="#FFF">
        <ModalHeader fontWeight={600}>
          <Stack width="full" alignItems="center">
            <IoAlertCircleOutline color="#e53e3e" size={70} />
          </Stack>
        </ModalHeader>

        <ModalBody textAlign="center" fontSize="xl" fontWeight={600} paddingBottom={5}>
          Você atingiu a quantidade máxima para refazer o conteúdo.
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}
