import { ReactNode } from 'react';
import { Heading as ChakraHeading } from '@chakra-ui/react';
import { HeadingProps } from '@chakra-ui/react';

interface IHeadingProps extends HeadingProps {
  children: ReactNode;
}

export const Heading = ({ children, ...rest }: IHeadingProps) => {
  return (
    <ChakraHeading as="h3" fontSize="4xl" fontWeight="600" {...rest}>
      {children}
    </ChakraHeading>
  );
};
