import { Link } from 'react-router-dom';
import noDataImg from '../../../../../assets/images/illustrations/dashboard-no-data.svg';
import styles from './styles.module.css';

function NoData() {
  return (
    <div className={styles.noDataContainer}>
      <img src={noDataImg} alt="Sem dados" />

      <h6>Nenhuma categoria encontrada</h6>

      <p>
        Você não possui categorias para exibir nesta seção. <br /> Vá até nossa página de{' '}
        <Link to="/categories">Categorias</Link> para cadastrar uma nova categoria.
      </p>
    </div>
  );
}

export default NoData;
