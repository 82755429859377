import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HelperText from './HelperText';
import MemberAreaImage from './MemberAreaImage';
import MemberAreaSelect from './MemberAreaSelect';
import useFetch from '../../../hooks/useFetch';
import FullPageLoading from '../../../components/FullPageLoading';
import dayjs from 'dayjs';
import { axiosBackend } from '../../../api/Configuration';
import { Box, Button, Flex, Heading, Stack, Text, useToast } from '@chakra-ui/react';
import ErrorResponse from '../../../helpers/ErrorResponse';

export type MemberArea = 'proluno' | 'netflix';

interface MemberAreaResponse {
  membersArea: MemberArea;
  lastUpdate: string;
  hasMembersAreaNetflix: boolean;
}

export default function MembersAreaManagement() {
  const [memberAreaType, setMemberAreaType] = useState<MemberArea>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const toast = useToast();

  const {
    data: response,
    loading: isFetching,
    fetchData: fetchMemberAreaData,
  } = useFetch<UnificadaFront.ResponseJSON<MemberAreaResponse>>({
    method: 'get',
    url: '/platform/members-area/show',
    autoFetch: false,
    authenticated: true,
  });

  useEffect(() => {
    fetchMemberAreaData();
    setMemberAreaType(response?.data.membersArea);
  }, [fetchMemberAreaData, response?.data.membersArea]);

  async function handleMemberAreaChange(value: MemberArea) {
    // Caso escolha a mesma área que a atual:
    if (value === memberAreaType) return;

    setIsUpdating(true);

    try {
      await axiosBackend().patch('/platform/members-area/update', {
        typeMembersArea: value,
      });

      setMemberAreaType(value);
    } catch (error) {
      if (ErrorResponse(error) === 'Você não tem acesso a essa funcionalidade.') {
        return toast({
          title: ErrorResponse(error),
          description: 'Procure o suporte da Proluno para ter acesso a Area de Membros Netflix.',
          status: 'info',
          position: 'bottom-right',
        });
      }

      toast({
        title: 'Erro ao atualizar área de membros!',
        description: ErrorResponse(error),
        status: 'error',
        position: 'bottom-right',
      });
    } finally {
      setIsUpdating(false);
    }
  }

  const lastUpdated = dayjs(response?.data.lastUpdate).format('DD/MM/YYYY');
  const hasMembersAreaNetflix = response?.data?.hasMembersAreaNetflix;

  return (
    <Box>
      <Heading as="h1" fontWeight="semibold" fontSize={{ base: '2xl', xl: '4xl' }}>
        Área de membros
      </Heading>

      <Stack
        direction={{ base: 'column', xl: 'row' }}
        mt="1.875rem"
        spacing={{ base: 8, xl: 12 }}
        align="stretch"
        minHeight="19.5rem"
      >
        <Flex direction="column" justify="space-between" flex={1}>
          <Box>
            <Text fontSize={{ base: 'md', xl: 'xl' }} color="#20212380" fontWeight="normal">
              Sua área de membros é a:
            </Text>

            <MemberAreaSelect
              value={memberAreaType}
              lastUpdated={lastUpdated}
              onChange={handleMemberAreaChange}
            />

            {memberAreaType === 'netflix' && (
              <Button
                mt={4}
                colorScheme="primary"
                color="secondary.500"
                as={Link}
                to="/members-area-management/netflix"
              >
                Editar
              </Button>
            )}
          </Box>

          <HelperText
            display={{ base: 'none', xl: 'block' }}
            memberAreaType={memberAreaType}
            hasMembersAreaNetflix={hasMembersAreaNetflix}
          />
        </Flex>

        <MemberAreaImage memberAreaType={memberAreaType} />

        <HelperText
          display={{ base: 'block', xl: 'none' }}
          memberAreaType={memberAreaType}
          hasMembersAreaNetflix={hasMembersAreaNetflix}
        />
      </Stack>

      <FullPageLoading isLoading={isFetching || isUpdating} />
    </Box>
  );
}
