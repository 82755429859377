import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormEvent } from 'react';

interface ModalAddOrEditAreaProps {
  id?: number;
  institutionName?: string;
  setInstitutionId?: (value: number) => void;
  setInstitutionName?: (value: string) => void;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (event: FormEvent) => void;
  isSubmitting?: boolean;
  isEditingInstitution?: boolean;
  formValidation?: {
    name: {
      isInvalid: boolean;
      message: string;
    };
  };
}

export function ModalAddOrEditInstitution({
  id,
  isOpen,
  onClose,
  institutionName,
  setInstitutionName,
  onSubmit,
  isSubmitting,
  isEditingInstitution,
  setInstitutionId,
  formValidation,
}: ModalAddOrEditAreaProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setInstitutionId(0);
        setInstitutionName('');
        onClose();
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader fontWeight={700} fontSize="18px">
          {id ? 'Editar banca' : 'Adicionar uma banca'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody marginX={1}>
          <FormControl isInvalid={formValidation?.name.isInvalid}>
            <FormLabel fontWeight={600} fontSize="14px">
              Nome da banca
            </FormLabel>
            <Input
              placeholder="Digite aqui"
              size="sm"
              borderRadius={6}
              focusBorderColor="orange.500"
              color="#20212380"
              value={institutionName}
              onChange={e => setInstitutionName(e.target.value)}
              maxLength={100}
            />
            <FormErrorMessage>
              {formValidation?.name.isInvalid && formValidation?.name.message}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            size="sm"
            mr={3}
            onClick={() => {
              setInstitutionId(0);
              setInstitutionName('');
              onClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="orange"
            size="sm"
            type="submit"
            isDisabled={isSubmitting || isEditingInstitution}
            isLoading={isSubmitting || isEditingInstitution}
          >
            {id ? 'Salvar' : 'Adicionar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
