import { responsiveBannerToShow, settingsBanner } from './Arrows/slickSettings';
import { useEffect, useState } from 'react';

import Carousel from 'react-slick';
import { sortByOrder } from '../../../helpers/SortHelper';
import styles from './styles.module.css';
import { useTheme } from '../../../contexts/ThemeContext';
import { nanoid } from 'nanoid';

// const bannerStyle = (bannerToShow: number): CSSProperties => {
//   let style: CSSProperties = {
//     aspectRatio: '16/9',
//     height: '840px',
//     width: '100%',
//   };

//   if (bannerToShow > 1) {
//     style = {
//       aspectRatio: '1/1',
//       backgroundSize: 'cover',
//     };
//   }

//   return style;
// };

interface IBannerHeroItem {
  id: number;
  title: string;
  link: string;
  banner: string;
}

function Banner({ course }) {
  const [banners, setBanners] = useState<any>();

  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      setBanners(course.banners);
    }
  }, [course.banners, isLoadingThemeMarketplace]);

  return (
    <>
      {banners && (
        <section className={styles.bannerSection}>
          <Carousel
            {...settingsBanner}
            responsive={responsiveBannerToShow(course?.bannerToShow)}
            slidesToShow={course?.bannerToShow}
            slidesToScroll={course?.bannerToShow}
          >
            {banners.sort(sortByOrder).map((bannerHeroItem: IBannerHeroItem, index: number) => {
              return bannerHeroItem.link ? (
                <a
                  key={nanoid()}
                  href={bannerHeroItem.link || null}
                  target="_blank"
                  rel="noopener noreferrer"
                  role="button"
                  title={bannerHeroItem.title}
                >
                  <img
                    src={bannerHeroItem.banner}
                    style={{
                      backgroundColor: themeMarketplace.primaryColor,
                    }}
                    alt={`Banner ${index + 1}`}
                    className={styles.bannerItem}
                    id="bannerItem"
                  />
                </a>
              ) : (
                <div key={nanoid()}>
                  <img
                    src={bannerHeroItem.banner}
                    style={{
                      backgroundColor: themeMarketplace.primaryColor,
                    }}
                    alt={`Banner ${index + 1}`}
                    className={styles.bannerItem}
                    id="bannerItem"
                  />
                </div>
              );
            })}
          </Carousel>
        </section>
      )}
    </>
  );
}

export default Banner;
