import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import convertToBrazilianCurrency from '../../../../../helpers/convertToBrazilianCurrency';
import { Affiliate } from '../types';

interface IProps {
  affiliates: Affiliate[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

export default function AffiliatesTable({ affiliates, onEdit, onDelete }: IProps) {
  return (
    <TableContainer>
      <Table
        variant="striped"
        colorScheme="blackAlpha"
        padding="12px"
        borderRadius="5px"
        border="1px solid rgba(32, 33, 35, 0.05)"
        background="#FFF"
      >
        <Thead>
          <Tr>
            <Th>NOME</Th>
            <Th>EMAIL</Th>
            <Th>TELEFONE</Th>
            <Th>PRODUTO/COMISSÃO</Th>
            <Th>
              GANHOS E <br />
              VENDAS
            </Th>
            <Th>AÇÕES</Th>
          </Tr>
        </Thead>
        <Tbody>
          {affiliates.map(affiliate => (
            <Tr>
              <Td margin="24px 14px" width="168px">
                <Text
                  width="113px"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20px"
                  noOfLines={2}
                  whiteSpace="break-spaces"
                >
                  {affiliate.name}
                </Text>
              </Td>
              <Td margin="24px 14px" width="180px">
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                  fontWeight="400"
                  width="132px"
                  noOfLines={2}
                  whiteSpace="break-spaces"
                >
                  {affiliate.email}
                </Text>
              </Td>
              <Td margin="24px 14px" width="168px">
                <Text
                  fontSize="12px"
                  lineHeight="20px"
                  fontWeight="400"
                  width="120px"
                  noOfLines={2}
                  whiteSpace="break-spaces"
                  textAlign="center"
                >
                  {affiliate.phoneNumber ?? '-'}
                </Text>
              </Td>
              <Td margin="24px 14px" width="276px">
                <Stack width="228px" spacing="1px" fontSize="14px" lineHeight="20px">
                  <Text fontWeight="400" whiteSpace="pre-wrap">
                    {affiliate.courseName}
                  </Text>
                  <Text color="#EB7129" fontWeight="500">
                    {affiliate.commissionValue}% de comissão
                  </Text>
                </Stack>
              </Td>
              <Td margin="24px 14px" width="130px">
                <Stack width="82px" spacing="1px" fontSize="12px" lineHeight="20px">
                  <Text color="#EB7129" fontWeight="500">
                    {convertToBrazilianCurrency(affiliate.winnings)}
                  </Text>
                  <Text fontWeight="400">
                    {affiliate.amountOfSales} {affiliate.amountOfSales === 1 ? 'venda' : 'vendas'}
                  </Text>
                </Stack>
              </Td>
              <Td margin="24px 14px" width="95px">
                <Menu>
                  <MenuButton
                    width="27px"
                    height="24px"
                    bgColor="#2021231F"
                    _active={{ bgColor: '#2021233F' }}
                    transition="ease-in-out 0.3s"
                    borderRadius="5px"
                  >
                    <Box display="flex" justifyContent="center">
                      <BiDotsHorizontalRounded size="18px" color="#20212380" />
                    </Box>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      color="#979CA6"
                      fontSize="12px"
                      fontWeight="500"
                      lineHeight="normal"
                      onClick={() => onEdit(affiliate.affiliationId)}
                    >
                      Editar afiliação
                    </MenuItem>
                    <MenuItem
                      color="#979CA6"
                      fontSize="12px"
                      fontWeight="500"
                      lineHeight="normal"
                      hidden
                    >
                      Ver como afiliado
                    </MenuItem>
                    <MenuItem
                      color="#BB2124"
                      fontSize="12px"
                      fontWeight="500"
                      lineHeight="normal"
                      onClick={() => onDelete(affiliate.affiliationId)}
                    >
                      Remover afiliado
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
