import { Button, Heading, Text, VStack, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function UtmGeneratorHeader() {
  return (
    <VStack spacing={5}>
      <Flex justify="space-between" w="full" wrap="wrap">
        <Heading as="h1" fontSize={{ base: '2xl', xl: '4xl' }} fontWeight="semibold" m={0}>
          Gerador de UTM
        </Heading>

        <Flex order={{ base: 3, xl: 0 }} justify="end" w={{ base: 'full', xl: '50%' }}>
          <Button
            as={Link}
            to="/utm-generator/new"
            colorScheme="primary"
            color="secondary.500"
            w={{ base: 'full', xl: 'initial' }}
            mt={{ base: 8, xl: 'initial' }}
            size="sm"
          >
            Gerar UTM
          </Button>
        </Flex>

        <Text
          order={{ base: 2, xl: 'initial' }}
          w="full"
          color="#20212380"
          fontSize="lg"
          mt={{ base: 2.5, xl: 5 }}
          lineHeight="normal"
        >
          Gerencie e configure as suas UTM's, que são links de rastreamento que adicionam extensões
          específicas ao final de cada link para entender a origem do tráfego.
        </Text>
      </Flex>
    </VStack>
  );
}
