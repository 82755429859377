import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Card,
  CloseButton,
  Heading,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineCheck } from 'react-icons/ai';
import { Question } from '../../types';
import { ModalUnlinkQuestion } from '../ModalUnlinkQuestion';

interface IParams {
  question: Question;
  questionIdsToAdd?: number[];
  setQuestionIdsToAdd?: (id: number[]) => void;
  hiddenSelect?: boolean;
  setQuestionId?: (id: number) => void;
  unlinkQuestion?: () => void;
  isUnlinkQuestion?: boolean;
}

export function QuestionCard({
  question,
  questionIdsToAdd,
  setQuestionIdsToAdd,
  hiddenSelect = false,
  setQuestionId,
  isUnlinkQuestion,
  unlinkQuestion,
}: IParams) {
  const {
    isOpen: isOpenUnlinkQuestion,
    onOpen: onOpenUnlinkQuestion,
    onClose: onCloseUnlinkQuestion,
  } = useDisclosure();

  function filtersIdsToAdd(id: number) {
    const idExist = questionIdsToAdd.find(questionId => questionId === id);

    if (!idExist) {
      setQuestionIdsToAdd([...questionIdsToAdd, id]);
    } else {
      const idToRemove = questionIdsToAdd.filter(questionId => questionId !== id);
      setQuestionIdsToAdd(idToRemove);
    }
  }

  return (
    <>
      <Card
        padding={5}
        gap={2}
        border={
          questionIdsToAdd?.includes(question.id) ? '2px solid #DD6B20' : '0.5px solid #20212350'
        }
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15)"
      >
        <Stack direction="row" justifyContent="space-between" width="full" paddingX={4}>
          <Stack direction="row" spacing={1}>
            <Badge variant="solid" colorScheme="orange" height="17px" borderRadius={5}>
              <Text fontSize="12px">ID:{question?.id}</Text>
            </Badge>
            <Text fontSize="12px" color="#20212380">
              {question?.questionLibraryArea?.name
                ? `${question?.questionLibraryArea?.name} /`
                : ''}
            </Text>
            <Text fontSize="12px" color="#20212380">
              {question?.questionLibraryDiscipline?.name
                ? `${question?.questionLibraryDiscipline?.name} /`
                : ''}
            </Text>
            <Text fontSize="12px" color="#20212380">
              {question?.questionLibrarySubject?.name
                ? `${question?.questionLibrarySubject?.name}`
                : ''}
            </Text>
            <Text fontSize="12px" color="#20212380">
              {question?.questionLibraryInstitution?.name
                ? `/ ${question?.questionLibraryInstitution?.name}`
                : ''}
            </Text>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            onClick={() => {
              setQuestionId(question.id);
              onOpenUnlinkQuestion();
            }}
            cursor="pointer"
            hidden={!hiddenSelect}
          >
            <CloseButton width={17} />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            onClick={() => filtersIdsToAdd(question.id)}
            cursor="pointer"
            hidden={hiddenSelect}
            color={questionIdsToAdd?.includes(question.id) ? '#DD6B20' : '#20212350'}
          >
            <AiOutlineCheck size={20} />
          </Stack>
        </Stack>
        <Accordion allowMultiple>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                {isExpanded ? (
                  <AccordionButton borderRadius={5}>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading
                        fontSize="md"
                        fontWeight={500}
                        dangerouslySetInnerHTML={{ __html: question?.questionText }}
                      />
                    </Box>

                    <AccordionIcon />
                  </AccordionButton>
                ) : (
                  <AccordionButton borderRadius={5}>
                    <Box as="span" flex="1" textAlign="left">
                      <Heading
                        fontSize="md"
                        fontWeight={500}
                        noOfLines={2}
                        dangerouslySetInnerHTML={{ __html: question?.questionText }}
                      />
                    </Box>

                    <AccordionIcon />
                  </AccordionButton>
                )}

                <AccordionPanel>
                  <Box margin={5}>
                    <Heading fontSize="lg" fontWeight={500}>
                      Alternativas:
                    </Heading>
                    {question?.options.map((option, index) => (
                      <Text
                        key={index}
                        fontSize="md"
                        fontWeight={500}
                        borderRadius={5}
                        padding="5px 10px"
                        bgColor={option?.correct ? 'green.200' : 'transparent'}
                      >
                        {option?.option}. {option?.optionText}
                      </Text>
                    ))}
                  </Box>

                  {question?.commentary && (
                    <Box>
                      <Heading fontSize="md" fontWeight={500}>
                        Comentários:
                      </Heading>
                      <Box
                        fontSize="sm"
                        dangerouslySetInnerHTML={{ __html: question?.commentary }}
                      />
                    </Box>
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Card>

      <ModalUnlinkQuestion
        isOpen={isOpenUnlinkQuestion}
        onClose={onCloseUnlinkQuestion}
        isUnlinkQuestion={isUnlinkQuestion}
        unlinkQuestion={unlinkQuestion}
      />
    </>
  );
}
