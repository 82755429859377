import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select as SelectChakra,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FormEvent, useEffect, useState } from 'react';
import Select from 'react-select';
import CouponAPI from '../../../../api/Coupon';
import { ReactSelectStyles } from '../../../../components/ReactSelect';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import useWindowSize from '../../../../hooks/useWindowSize';

const DiscountTypes = {
  PERCENTAGE: 'percentage',
  VALUE: 'value',
};

const dateFormat = 'YYYY-MM-DD';

type CouponType = {
  name: string;
  discountType: string;
  discount: string | number;
  status: string;
  amount: string;
  startDate: string;
  endDate: string;
  products: any[];
};

const initialStateCoupons = {
  name: '',
  discountType: DiscountTypes.PERCENTAGE,
  discount: 0,
  status: '',
  amount: '',
  startDate: '',
  endDate: '',
  products: [],
};

export function ModalNewOrEditCoupon({
  isOpen,
  onClose,
  couponId,
  setCouponId,
  paginationCoupons,
  page,
  per_page,
  search,
}) {
  const [courses, setCourse] = useState([]);
  const [coupon, setCoupon] = useState<CouponType>(initialStateCoupons);
  const { width } = useWindowSize();
  const fittedSize = width < 500 ? 'xs' : '3xl';

  useEffect(() => {
    async function getCouponsById() {
      if (couponId) {
        try {
          const { data: dataCoupons } = await CouponAPI.show(couponId);

          const startDate = dayjs(dataCoupons.startDate).add(1, 'day').format(dateFormat);

          const endDate = dayjs(dataCoupons.endDate).format(dateFormat);

          setCoupon({
            name: dataCoupons.name,
            discountType: dataCoupons.value ? DiscountTypes.VALUE : DiscountTypes.PERCENTAGE,
            discount: dataCoupons.value || dataCoupons.percentage,
            startDate,
            endDate,
            products: dataCoupons.products.map(course => ({
              value: course.id,
              label: course.name,
            })),
            status: dataCoupons.status,
            amount: dataCoupons.amount,
          });
        } catch (error) {
          Toast(ErrorResponse(error), 'error');
        }
      } else {
        setCoupon(initialStateCoupons);
      }
    }
    getCouponsById();
  }, [couponId]);

  useEffect(() => {
    async function getCourses() {
      try {
        const { data: courseData } = await CouponAPI.courseList();

        if (courseData) {
          const mappedProducts = [
            ...(courseData?.map(product => ({
              value: product.id,
              label: product.name,
            })) || []),
          ];
          setCourse(mappedProducts);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }

    getCourses();
  }, []);

  function onChange(event) {
    const { name, value } = event.target;

    const newCoupon = { ...coupon, [name]: value };

    setCoupon(newCoupon);
  }

  function handleSelectProducts(products) {
    if (products.map(({ value }) => value).includes(0)) {
      setCoupon(prevForm => ({
        ...prevForm,
        products: courses,
      }));
      return;
    }

    setCoupon(prevForm => ({
      ...prevForm,
      products,
    }));
  }

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const productId = coupon.products.map(product => product.value);
    const newCoupon = { ...coupon, products: productId };

    try {
      if (newCoupon.discountType === DiscountTypes.VALUE) {
        newCoupon.discount = parseFloat(newCoupon.discount as string);
      }

      if (newCoupon.startDate > newCoupon.endDate) {
        Toast('A data final não pode ser inferior a data de início', 'error');
        return;
      }

      if (!couponId) {
        await CouponAPI.store(newCoupon);
        await paginationCoupons(page, per_page, search);
        onClose();

        Toast('Cupom criado com sucesso!');

        return;
      }

      await CouponAPI.update(couponId, newCoupon);

      await paginationCoupons(page, per_page, search);
      onClose();

      Toast('Cupom atualizado com sucesso!');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }
  return (
    <>
      <Modal
        size={fittedSize}
        isOpen={isOpen}
        onClose={() => {
          setCouponId(null);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent as="form" onSubmit={onSubmit}>
          <ModalHeader fontWeight="bold" fontSize="lg">
            {!couponId ? 'Novo Cupom de Desconto' : `Editar Cupom ${coupon.name}`}
          </ModalHeader>
          <ModalCloseButton style={{ outline: 'none', boxShadow: 'none' }} />
          <ModalBody>
            <Stack direction="column" spacing={4}>
              <Stack direction="column" spacing={2}>
                <Text fontWeight="medium" fontSize="14px">
                  Nome do Cupom
                </Text>
                <Input
                  focusBorderColor="#e06721"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nome do cupom"
                  onChange={onChange}
                  value={coupon.name}
                  isRequired
                  minLength={5}
                />
              </Stack>

              <Stack direction="column" spacing={2}>
                <Text fontWeight="medium" fontSize="14px">
                  Valor
                </Text>
                <Stack direction="row" spacing={0}>
                  <Input
                    focusBorderColor="#e06721"
                    type="number"
                    id="discount"
                    name="discount"
                    step="0.01"
                    min="0.01"
                    minLength={1}
                    max="99999.99"
                    maxLength={8}
                    placeholder="Valor (99.99) ou porcentagem"
                    onChange={onChange}
                    value={coupon.discount}
                    borderRightRadius={0}
                    mr="-1px"
                    isRequired
                  />
                  <SelectChakra
                    focusBorderColor="#e06721"
                    w={{ base: '30%', md: '10%', lg: '10%' }}
                    borderLeftRadius={0}
                    name="discountType"
                    onChange={onChange}
                    value={coupon.discountType}
                  >
                    <option value="value">$</option>
                    <option value="percentage">%</option>
                  </SelectChakra>
                </Stack>

                <Text color="#20212380" fontSize="sm">
                  Adicione o valor do desconto e escolha o tipo de desconto, uma porcentagem de
                  preço total ou um valor fixo.
                </Text>
              </Stack>

              <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} spacing={2}>
                <Stack direction="column" spacing={2} w="100%">
                  <Text fontWeight="medium" fontSize="14px">
                    Quantidade máxima
                  </Text>
                  <NumberInput
                    focusBorderColor="#e06721"
                    id="amount"
                    name="amount"
                    placeholder="Quantidade de cupons"
                    min={0}
                    max={100000}
                    isRequired
                    step={1}
                    value={coupon.amount}
                    onChange={value => setCoupon({ ...coupon, amount: value })}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Stack>

                <Stack direction="column" spacing={2} w="100%">
                  <Text fontWeight="medium" fontSize="14px">
                    Status
                  </Text>
                  <SelectChakra
                    focusBorderColor="#e06721"
                    name="status"
                    id="status"
                    placeholder="Selecione um status"
                    onChange={onChange}
                    defaultValue={coupon.status}
                    value={coupon.status}
                    isRequired
                    _placeholder={{ color: '#2D374880' }}
                  >
                    <option value="ATIVO">Ativo</option>
                    <option value="INATIVO">Inativo</option>
                  </SelectChakra>
                </Stack>
              </Stack>

              <Stack direction="column" spacing={2}>
                <Text fontWeight="medium" fontSize="14px">
                  Data inicial
                </Text>
                <Input
                  focusBorderColor="#e06721"
                  type="date"
                  id="startDate"
                  name="startDate"
                  placeholder="Número da agência"
                  onChange={onChange}
                  value={coupon.startDate}
                  min={dayjs().format('YYYY-MM-DD')}
                  _placeholder={{ color: '#2D374880' }}
                  isRequired
                />
              </Stack>

              <Stack direction="column" spacing={2}>
                <Text fontWeight="medium" fontSize="14px">
                  Data final
                </Text>
                <Input
                  focusBorderColor="#e06721"
                  type="date"
                  name="endDate"
                  id="endDate"
                  placeholder="Número da agência"
                  onChange={onChange}
                  value={coupon.endDate}
                  min={dayjs().format('YYYY-MM-DD')}
                  isRequired
                />
              </Stack>

              <Stack direction="column" spacing={2}>
                <Text fontWeight="medium" fontSize="14px">
                  Selecionar produtos
                </Text>
                <Select
                  menuPlacement="top"
                  isMulti={true}
                  name="products"
                  options={[{ value: 0, label: 'Todos os produtos' }, ...courses]}
                  placeholder="Escolha os produtos"
                  styles={ReactSelectStyles}
                  value={[...coupon.products]}
                  onChange={handleSelectProducts}
                />
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Stack direction="row" w="100%" justifyContent="center">
              <Button
                w="100%"
                colorScheme="gray"
                mr={3}
                onClick={() => {
                  setCouponId(null);
                  onClose();
                }}
              >
                Cancelar
              </Button>
              <Button w="100%" variant="solid" colorScheme="orange" type="submit">
                {!couponId ? 'Adicionar' : `Salvar`}
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
