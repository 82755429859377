import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from 'react';

import ErrorResponse from '../../../../helpers/ErrorResponse';
import Header from '../components/Header';
import Toast from '../../../../components/Toast';
import cademiIntegrationAPI from '../../../../api/CademiIntegration';
import styles from './styles.module.css';

interface IIntegration {
  id?: number;
  apiKey: string;
  urlPostback: string;
}

function CademiIntegration() {
  const [integration, setIntegration] = useState<IIntegration>({ apiKey: '', urlPostback: '' });
  const [integrationTemp, setIntegrationTemp] = useState<IIntegration>({
    apiKey: '',
    urlPostback: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    async function getIntegration() {
      try {
        const { data: integrationData } = await cademiIntegrationAPI.show();

        if (integrationData) {
          setIntegration(integrationData);
          setIntegrationTemp(integrationData);
        }
      } catch (error) {
        setErrorMessage(ErrorResponse(error));
      }
    }

    getIntegration();
  }, []);

  function handleChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const { name, value } = event.target;

    setIsDisabled(false);

    setIntegration(values => ({ ...values, [name]: value }));
  }

  function handleDiscardChanges(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    setIsDisabled(true);
    setIntegration(integrationTemp);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const method = integration.id ? 'update' : 'create';

    try {
      if (method === 'create') {
        await cademiIntegrationAPI.store({
          apiKey: integration.apiKey,
          urlPostback: integration.urlPostback,
        });

        Toast(`Credenciais salvas com sucesso`);
      }

      if (method === 'update') {
        await cademiIntegrationAPI.update(integration.id, {
          apiKey: integration.apiKey,
          urlPostback: integration.urlPostback,
        });

        Toast(`Credenciais atualizadas com sucesso`);
      }

      setIsDisabled(true);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsDisabled(false);
    } finally {
      setErrorMessage('');
    }
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <Header
          title="Cademí"
          tutorialLink="https://www.youtube.com/channel/UCoCooVSpZIrPi6X3l1Zi9-g"
          description=""
        />

        <label htmlFor="">
          A integração da Cademí te dará a possibilidade de utilizar uma área de membros estilizada.
          Um local agradável onde os seus alunos poderão acessar os conteúdos do seu produto. Para
          realizar a integração com a Cademí, você deve primeiro se tornar um assinante da
          plataformas deles.{' '}
          <a href="https://cademi.com.br/" target="_blank" rel="noopener noreferrer">
            Clique aqui
          </a>{' '}
          para saber mais. Após se tornar um assinante, você precisará adicionar o Token de
          Integração no campo abaixo. Você encontrará o Token na sua área logada da Cademí indo menu
          superior no ícone de engrenagem, em seguida em configurações.
        </label>

        <textarea
          className="mt-4"
          name="apiKey"
          value={integration.apiKey}
          onChange={handleChange}
          cols={30}
          rows={4}
          placeholder="Cole o Token Cademí aqui"
          required
        />

        <label className="mt-4" htmlFor="urlPostback">
          Para concluir a integração você deve informar o seu <strong>URL Postback Proluno</strong>.
          Você encontrará na sua área logada da Cademí, indo menu superior em "Vitrines", em seguida
          na aba "Entregas", clique no botão "Nova Entrega" e dentre as opções apresentadas
          selecione a Proluno.
        </label>

        <input
          id="urlPostback"
          className="mt-2 py-1"
          name="urlPostback"
          value={integration.urlPostback}
          onChange={handleChange}
          placeholder="https://exemplo.cademi.com.br/api/postback/proluno"
          type="url"
          required
        />

        {errorMessage && (
          <div className="my-4">
            <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
              <div className="text-center">{errorMessage}</div>
            </div>
          </div>
        )}

        <div className={styles.formActions}>
          <button
            onClick={handleDiscardChanges}
            disabled={isDisabled}
            className={`btn btn-primary ${styles.cancelButton}`}
          >
            Cancelar
          </button>

          <button type="submit" disabled={isDisabled} className="btn btn-primary">
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
}

export default CademiIntegration;
