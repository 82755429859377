import { Button, ButtonGroup, Flex, Spacer } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Card from '../Card';

function WithoutProductRegistered() {
  return (
    <Card title="Último produto cadastrado" subtitle="Você ainda não cadastrou um produto">
      <Flex direction="column" flex={1}>
        <Spacer />

        <ButtonGroup mt={6}>
          <Button variant="solid" colorScheme="orange" size="sm">
            <Link to="/courses/new">Criar um produto</Link>
          </Button>

          <Button variant="outline" colorScheme="orange" bg="white" size="sm">
            <Link to="/courses/new">Subir minhas aulas</Link>
          </Button>
        </ButtonGroup>
      </Flex>
    </Card>
  );
}

export default WithoutProductRegistered;
