import './styles.css';

import { Link } from 'react-router-dom';
import { memo } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';

function MenuItem(props) {
  const { themeMarketplace } = useTheme();

  return (
    <>
      <li className="nk-menu-item my-2">
        <Link to={props.route} className="nk-menu-link">
          <span
            className="nk-menu-text ml-5"
            style={{ color: themeMarketplace.textColor, fontSize: '12.5px' }}
          >
            {props.title}
          </span>

          {props.route === '/coming-soon' && (
            <span
              className="badge ml-3"
              style={{
                pointerEvents: 'none',
                background: 'transparent',
                borderColor: themeMarketplace.textColor,
                color: themeMarketplace.textColor,
                fontSize: '0.5rem',
              }}
            >
              EM BREVE
            </span>
          )}
        </Link>
      </li>
    </>
  );
}

export default memo(MenuItem);
