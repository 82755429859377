import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { IFilter, IFilterParams } from '../../..';

interface IFiltersData {
  id: number;
  name: string;
}

interface IFilterBoxProps {
  filters: IFilter;
  areas: IFiltersData[];
  disciplines: IFiltersData[];
  subjects: IFiltersData[];
  isLoading: boolean;
  handleCheckboxFilter: (params: IFilterParams) => void;
  clearFilters?: () => void;
  hasFilters?: boolean;
}

// A const openInitially abre os index setados. Cada index representa um AccordionButton, respectivamente.
const openInitially = [0, 1, 2, 3, 4];

const checkBoxStyle = {
  size: 'sm',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  colorScheme: 'primary',
};

function ShowFilters({ items, initialCount = 5, increment = 5, renderItem }) {
  const [showCount, setShowCount] = useState(initialCount);

  const itemsToShow = items?.slice(0, showCount);

  const handleShowMore = () => {
    setShowCount(showCount + increment);
  };

  const handleShowLess = () => {
    setShowCount(initialCount);
  };

  return (
    <>
      <Stack spacing="8px">{itemsToShow?.map((item, index) => renderItem(item, index))}</Stack>

      {items?.length > showCount && (
        <Text
          marginTop="8px"
          color="#759AED"
          fontSize="14px"
          fontWeight="400"
          lineHeight="20px"
          textDecorationLine="underline"
          cursor="pointer"
          onClick={handleShowMore}
        >
          Mostrar mais
        </Text>
      )}

      {items?.length > 5 && showCount >= items?.length && (
        <Text
          marginTop="8px"
          color="#759AED"
          fontSize="14px"
          fontWeight="400"
          lineHeight="20px"
          textDecorationLine="underline"
          cursor="pointer"
          onClick={handleShowLess}
        >
          Mostrar menos
        </Text>
      )}
    </>
  );
}

export default function FilterBox({
  filters,
  areas,
  disciplines,
  subjects,
  isLoading,
  handleCheckboxFilter,
  clearFilters,
  hasFilters,
}: IFilterBoxProps) {
  return (
    <Accordion width="310px" defaultIndex={openInitially} allowMultiple>
      <Text
        color="#759AED"
        textAlign="left"
        fontSize="14px"
        fontWeight="400"
        lineHeight="28px"
        cursor="pointer"
        textDecorationLine="underline"
        onClick={clearFilters}
        hidden={!hasFilters}
      >
        Limpar filtros
      </Text>
      <AccordionItem hidden={!areas?.length || isLoading}>
        <AccordionButton padding="8px 0">
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontSize="16px"
            fontWeight="500"
            lineHeight="28px"
          >
            Área
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel padding="8px 0">
          <ShowFilters
            items={areas}
            renderItem={(area, index) => (
              <Checkbox
                key={index}
                {...checkBoxStyle}
                isChecked={filters.area.some(filter => filter.id === area.id.toString())}
                onChange={() => {
                  handleCheckboxFilter({
                    id: area.id.toString(),
                    name: 'area',
                    displayName: area.name.toString(),
                    isChecked: filters.area.some(filter => filter.id === area.id.toString()),
                  });
                }}
              >
                {area.name}
              </Checkbox>
            )}
          />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem hidden={!disciplines?.length || isLoading}>
        <h2>
          <AccordionButton padding="8px 0">
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontSize="16px"
              fontWeight="500"
              lineHeight="28px"
            >
              Disciplina
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel padding="8px 0">
          <ShowFilters
            items={disciplines}
            renderItem={(discipline, index) => (
              <Checkbox
                key={index}
                {...checkBoxStyle}
                isChecked={filters.discipline.some(
                  filter => filter.id === discipline.id.toString()
                )}
                onChange={() => {
                  handleCheckboxFilter({
                    id: discipline.id.toString(),
                    name: 'discipline',
                    displayName: discipline.name.toString(),
                    isChecked: filters.discipline.some(
                      filter => filter.id === discipline.id.toString()
                    ),
                  });
                }}
              >
                {discipline.name}
              </Checkbox>
            )}
          />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem hidden={!subjects?.length || isLoading}>
        <h2>
          <AccordionButton padding="8px 0">
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontSize="16px"
              fontWeight="500"
              lineHeight="28px"
            >
              Assunto
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel padding="8px 0">
          <ShowFilters
            items={subjects}
            renderItem={(subject, index) => (
              <Checkbox
                key={index}
                {...checkBoxStyle}
                isChecked={filters.subject.some(filter => filter.id === subject.id.toString())}
                onChange={() => {
                  handleCheckboxFilter({
                    id: subject.id.toString(),
                    name: 'subject',
                    displayName: subject.name.toString(),
                    isChecked: filters.subject.some(filter => filter.id === subject.id.toString()),
                  });
                }}
              >
                {subject.name}
              </Checkbox>
            )}
          />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton padding="8px 0">
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontSize="16px"
              fontWeight="500"
              lineHeight="28px"
            >
              Tipo de pagamento
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel padding="8px 0">
          <Stack spacing="8px">
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.typeOfPayment.some(filter => filter.id === 'free')}
              onChange={() => {
                handleCheckboxFilter({
                  id: 'free',
                  name: 'typeOfPayment',
                  displayName: 'Grátis',
                  isChecked: filters.typeOfPayment.some(filter => filter.id === 'free'),
                });
              }}
            >
              Gratuito
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.typeOfPayment.some(filter => filter.id === 'paid')}
              onChange={() => {
                handleCheckboxFilter({
                  id: 'paid',
                  name: 'typeOfPayment',
                  displayName: 'Pago',
                  isChecked: filters.typeOfPayment.some(filter => filter.id === 'paid'),
                });
              }}
            >
              Pago
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.typeOfPayment.some(filter => filter.id === 'subscription')}
              onChange={() => {
                handleCheckboxFilter({
                  id: 'subscription',
                  name: 'typeOfPayment',
                  displayName: 'Assinatura',
                  isChecked: filters.typeOfPayment.some(filter => filter.id === 'subscription'),
                });
              }}
            >
              Assinatura
            </Checkbox>
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton padding="8px 0">
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontSize="16px"
              fontWeight="500"
              lineHeight="28px"
            >
              Preço
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel padding="8px 0">
          <Stack spacing="8px">
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.price.some(
                filter => filter.id === JSON.stringify({ gte: 0, lte: 100 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 0, lte: 100 }),
                  name: 'price',
                  displayName: 'R$0-R$100',
                  isChecked: filters.price.some(
                    filter => filter.id === JSON.stringify({ gte: 0, lte: 100 })
                  ),
                });
              }}
            >
              Até R$100
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.price.some(
                filter => filter.id === JSON.stringify({ gte: 100, lte: 200 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 100, lte: 200 }),
                  name: 'price',
                  displayName: 'R$100-R$200',
                  isChecked: filters.price.some(
                    filter => filter.id === JSON.stringify({ gte: 100, lte: 200 })
                  ),
                });
              }}
            >
              R$100 a R$200
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.price.some(
                filter => filter.id === JSON.stringify({ gte: 200, lte: 300 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 200, lte: 300 }),
                  name: 'price',
                  displayName: 'R$200-R$300',
                  isChecked: filters.price.some(
                    filter => filter.id === JSON.stringify({ gte: 200, lte: 300 })
                  ),
                });
              }}
            >
              R$200 a R$300
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.price.some(
                filter => filter.id === JSON.stringify({ gte: 300, lte: 400 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 300, lte: 400 }),
                  name: 'price',
                  displayName: 'R$300-R$400',
                  isChecked: filters.price.some(
                    filter => filter.id === JSON.stringify({ gte: 300, lte: 400 })
                  ),
                });
              }}
            >
              R$300 a R$400
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.price.some(
                filter => filter.id === JSON.stringify({ gte: 400, lte: 500 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 400, lte: 500 }),
                  name: 'price',
                  displayName: 'R$400-R$500',
                  isChecked: filters.price.some(
                    filter => filter.id === JSON.stringify({ gte: 400, lte: 500 })
                  ),
                });
              }}
            >
              R$400 a R$500
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.price.some(
                filter => filter.id === JSON.stringify({ gte: 500, lte: 1000 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 500, lte: 1000 }),
                  name: 'price',
                  displayName: 'R$500-R$1000',
                  isChecked: filters.price.some(
                    filter => filter.id === JSON.stringify({ gte: 500, lte: 1000 })
                  ),
                });
              }}
            >
              R$500 a R$1.000
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.price.some(
                filter => filter.id === JSON.stringify({ gte: 1000, lte: null })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 1000, lte: null }),
                  name: 'price',
                  displayName: 'R$1000+',
                  isChecked: filters.price.some(
                    filter => filter.id === JSON.stringify({ gte: 1000, lte: null })
                  ),
                });
              }}
            >
              R$1.000 +
            </Checkbox>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
