import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  IconButton,
  List,
  ListItem,
  ListProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { UtmItem } from '../../useUtmGenerator';
import { generateUTMLink } from '../../utils/generateUTMLink';
import CopyButton from '../CopyButton';

interface MobileUtmListProps extends ListProps {
  utmList: UtmItem[];
  onDelete: (id: number) => void;
  onExportInformation: (id: number) => void;
}

export default function MobileUtmList({
  utmList,
  onDelete,
  onExportInformation,
  ...rest
}: MobileUtmListProps) {
  return (
    <List {...rest} spacing={2.5}>
      {utmList.map(utmItem => (
        <Card
          key={utmItem.id}
          as={ListItem}
          border="1px solid #2021230D"
          boxShadow="0px 4px 4px 0px #00000040"
        >
          <CardHeader p={4} pb={0}>
            <HStack justify="space-between" align="start">
              <VStack spacing={1} align="start">
                <Heading
                  as="h2"
                  fontSize="md"
                  fontWeight="medium"
                  color="#2D3748"
                  m={0}
                  isTruncated
                  textTransform="capitalize"
                >
                  {utmItem.utmCampaign}
                </Heading>
              </VStack>

              <Menu size="sm" arrowPadding={0} placement="bottom-end">
                <MenuButton
                  size="xs"
                  as={IconButton}
                  icon={<MoreIcon size="1.125rem" />}
                  w="27px"
                  h="24px"
                  borderRadius={5}
                  bgColor="#2021231F"
                  _hover={{ bgColor: '#b6b5b5' }}
                  _active={{ bgColor: '#b6b5b5' }}
                />

                <MenuList
                  minWidth="101px"
                  boxShadow="0px 4px 4px 0px #00000040"
                  sx={{
                    '.chakra-menu__menuitem': {
                      fontWeight: 'medium',
                      fontSize: 'xs',
                      color: '#20212380',
                      _hover: { bgColor: '#2021230D' },
                      _focus: { bgColor: '#2021230D' },
                      minWidth: '101px',
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => onExportInformation(utmItem.id)}
                    isDisabled={utmItem.salesQuantity < 1}
                  >
                    Exportar informações
                  </MenuItem>

                  <MenuItem as={Link} to={`/utm-generator/${utmItem.id}`}>
                    Visualizar parâmetros
                  </MenuItem>

                  <MenuItem onClick={() => onDelete(utmItem.id)}>
                    <Text color="#BB2124">Excluir</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </CardHeader>

          <CardBody
            mt={4}
            p={4}
            pt={0}
            color="#20212380"
            sx={{ strong: { fontWeight: 'semibold' } }}
          >
            <List spacing={2}>
              <HStack as={ListItem} justifyContent="space-between">
                <Text as="strong">UTM_SOURCE:</Text>
                <Text>{utmItem.utmSource}</Text>
              </HStack>

              <HStack as={ListItem} justifyContent="space-between">
                <Text as="strong">UTM_MEDIUM:</Text>
                <Text>{utmItem.utmMedium}</Text>
              </HStack>

              <HStack as={ListItem} justifyContent="space-between">
                <Text as="strong">URL com UTM:</Text>

                <CopyButton textToCopy={generateUTMLink(utmItem)} />
              </HStack>

              <HStack as={ListItem} justifyContent="space-between">
                <Text as="strong">QTD de vendas:</Text>
                <Text>{utmItem.salesQuantity}</Text>
              </HStack>
            </List>
          </CardBody>
        </Card>
      ))}
    </List>
  );
}
