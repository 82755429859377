import MenuItem from './MenuItem';

import { Accordion } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  platformsToRemoveInstructorTransactionsMenu,
  platformsWithoutVideoLibrary,
  platformsWithSubscription,
} from '../../../../App';
import { ROLE_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import { affiliateMenuList, defaultMenuList, instructorMenuList } from '../menus';

const hostname = window.location.hostname;

function shouldFilterFinancial(menuItem, hostname, platformsWithSubscription) {
  return menuItem.name === 'Financeiro e vendas' && !platformsWithSubscription.includes(hostname);
}

function shouldFilterProductManager(menuItem, hostname, platformsWithoutVideoLibrary) {
  return (
    menuItem.name === 'Gerenciamento de produto' && platformsWithoutVideoLibrary.includes(hostname)
  );
}
function shouldFilterTransaction(menuItem, hostname, platformsToRemoveInstructorTransactionsMenu) {
  return (
    menuItem.name === 'Financeiro e vendas' &&
    platformsToRemoveInstructorTransactionsMenu.includes(hostname)
  );
}

function filterSubMenuItems(subMenuItems, path) {
  return subMenuItems.filter(submenuItem => submenuItem.path !== path);
}

function findIndexByPath(filteredMenuItemsList, path) {
  const itemIndex = filteredMenuItemsList.findIndex(item => item.path === path);

  if (itemIndex !== -1) {
    return itemIndex;
  }

  const subitemIndex = filteredMenuItemsList.findIndex(
    item =>
      Array.isArray(item.submenuItems) && item.submenuItems.some(subitem => subitem.path === path)
  );

  return subitemIndex;
}

export default function MenuList() {
  const { pathname } = useLocation();

  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const menuItemsList = useMemo(() => {
    const isInstructor = role === 'INSTRUTOR';
    const isAffiliate = role === 'AFILIADO';

    if (isInstructor) {
      return instructorMenuList;
    }

    if (isAffiliate) {
      return affiliateMenuList;
    }

    return defaultMenuList;
  }, [role]);

  const filteredMenuItemsList = useMemo(() => {
    return menuItemsList?.filter(menuItem => {
      if (shouldFilterFinancial(menuItem, hostname, platformsWithSubscription)) {
        menuItem.submenuItems = filterSubMenuItems(menuItem.submenuItems, '/signatures');
      }

      if (shouldFilterProductManager(menuItem, hostname, platformsWithoutVideoLibrary)) {
        menuItem.submenuItems = filterSubMenuItems(menuItem.submenuItems, '/video-library');
      }
      if (
        shouldFilterTransaction(menuItem, hostname, platformsToRemoveInstructorTransactionsMenu) &&
        role === 'INSTRUTOR'
      ) {
        menuItem.submenuItems = filterSubMenuItems(menuItem.submenuItems, '/financial');
      }

      return menuItem;
    });
  }, [menuItemsList, role]);

  const indexOfOpenMenu = findIndexByPath(filteredMenuItemsList, pathname);

  return (
    <Accordion flex={1} defaultIndex={[indexOfOpenMenu]} allowToggle>
      {filteredMenuItemsList.map((menuItem, index) => (
        <MenuItem
          key={`${menuItem.name}${index}`}
          name={menuItem.name}
          icon={menuItem.icon}
          path={menuItem.path}
          href={menuItem.href}
          submenuItems={menuItem.submenuItems?.length ? menuItem.submenuItems : []}
        />
      ))}
    </Accordion>
  );
}
