import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

export const Input = styled(InputMask)`
  ${() => css`
    position: relative;
    height: 2.5rem;
    width: 100%;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.36);
    background: inherit;
    border: 1px solid rgba(32, 33, 35, 0.15);
    border-radius: 0.375rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
      transform;
    transition-duration: 200ms;

    flex: 1;

    &::placeholder {
      color: rgba(0, 0, 0, 0.36);
    }

    &:focus,
    &[data-focus] {
      z-index: 1;
      border-color: #ec7117;
      box-shadow: 0 0 0 1px #ec7117;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `}
`;
