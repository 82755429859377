import { axiosBackend } from './Configuration';

export default {
  filterUsers: {
    index: async (search = '') => {
      try {
        const { data: userData } = await axiosBackend().get(
          `/users?role=ALUNO${search && `&search=${search}`}`
        );

        return userData;
      } catch (error) {
        throw error;
      }
    },
  },
  index: async ({ page = 1 }) => {
    try {
      const { data: userData } = await axiosBackend().get(
        `/users?role=ALUNO${page && `&page=${page}`}`
      );

      return userData;
    } catch (error) {
      throw error;
    }
  },

  update: async (id, email) => {
    try {
      await axiosBackend().patch(`/users/${id}/student`, { email });
    } catch (error) {
      throw error;
    }
  },

  sendEmail: async email => {
    try {
      await axiosBackend().post(`/users/${email}/forgot-password`);
    } catch (error) {
      throw error;
    }
  },

  forgotPassword: async (password, token) => {
    try {
      await axiosBackend().put(`/users/reset-password`, {
        password,
        token,
      });
    } catch (error) {
      throw error;
    }
  },

  expirePassword: async userId => {
    try {
      const { data: userData } = await axiosBackend().patch(
        `/users/${userId}/user/expire-password`
      );

      return userData;
    } catch (error) {
      throw error;
    }
  },
  exportBaseStudents: async ({ typeFile = '', search = '' }) => {
    const urlDownload = `/users/export${typeFile ? `?typeFile=${typeFile}` : ''}${
      search ? `&search=${search}` : ''
    }`;

    const axios = axiosBackend();

    const response = await axios({
      url: urlDownload,
      method: 'GET',
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', typeFile === 'CSV' ? 'Base-alunos.csv' : 'Base-alunos.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();
  },
};
