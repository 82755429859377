import { TabPanel, VStack } from '@chakra-ui/react';
import { Statistics } from '..';
import AnswerHistoric from './AnswerHistoric';
import AnswersByAlternativeChart from './AnswersByAlternativeChart';
import PerformanceChart from './PerformanceChart';

interface StatisticsTabPanelProps {
  statistics: Statistics;
}

export default function StatisticsTabPanel({ statistics }: StatisticsTabPanelProps) {
  return (
    <TabPanel py="1.125rem" px={0} _focus={{ boxShadow: 'none' }}>
      <VStack spacing="1.125rem" w="full" align="start">
        <PerformanceChart performance={statistics?.performance} />
        <AnswersByAlternativeChart answersByAlternative={statistics?.answersByAlternative} />

        {!!statistics?.answerHistoric.length && (
          <AnswerHistoric historic={statistics?.answerHistoric} />
        )}
      </VStack>
    </TabPanel>
  );
}
