import { Card, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { BiDotsVertical } from 'react-icons/bi';
import { IQuestionLibraryDisciplineData } from '../../TabPanelDiscipline';

interface IProps {
  discipline: IQuestionLibraryDisciplineData;
  setDisciplineId: (id: number) => void;
  setDisciplineName: (name: string) => void;
  onOpenModalAddOrEditDiscipline: () => void;
  onOpenModalDeleteDiscipline: () => void;
  setAreaId: (id: number) => void;
}

export function CardDiscipline({
  discipline,
  setDisciplineId,
  setDisciplineName,
  onOpenModalAddOrEditDiscipline,
  onOpenModalDeleteDiscipline,
  setAreaId,
}: IProps) {
  function handleClick(action: string): void {
    setDisciplineId(discipline?.id);
    setDisciplineName(discipline?.name);
    setAreaId(discipline?.area?.id);
    if (action === 'delete') {
      onOpenModalDeleteDiscipline();
      return;
    }

    onOpenModalAddOrEditDiscipline();
  }

  return (
    <Card
      width={{ base: 'full', md: '242px', lg: '242px' }}
      height="95px"
      borderRadius={5}
      padding={3}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 4px 4px 0px #00000040"
      border="1px solid #2021230D"
    >
      <Stack spacing={2} alignItems="center" height="full">
        <Text
          fontSize="12px"
          fontWeight={600}
          width="150px"
          lineHeight="1.1"
          height="27px"
          noOfLines={2}
        >
          {discipline?.name}
        </Text>
        <Text
          fontSize="12px"
          fontWeight={500}
          width="150px"
          height="27px"
          lineHeight="1.1"
          noOfLines={2}
          color="#20212380"
        >
          Área: {discipline?.area.name}
        </Text>
      </Stack>

      <Stack height="full">
        <Menu>
          <MenuButton>
            <BiDotsVertical size={14} />
          </MenuButton>
          <MenuList>
            <MenuItem
              fontSize="12px"
              fontWeight={500}
              color="#20212380"
              onClick={() => handleClick('edit')}
            >
              Editar
            </MenuItem>
            <MenuItem
              fontSize="12px"
              fontWeight={500}
              color="#BB2124"
              onClick={() => handleClick('delete')}
            >
              Excluir
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
    </Card>
  );
}
