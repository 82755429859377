import { VStack, Text } from '@chakra-ui/react';

import QRCode from '../../../../../../../assets/images/QRCode.png';

function Box({ ...props }) {
  return <div style={props}>{props.children}</div>;
}

function NoCode() {
  return (
    <Box
      position="relative"
      backgroundImage={`url(${QRCode})`}
      width="100%"
      height="90%"
      backgroundRepeat="round"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack
        width="10rem"
        height="10rem"
        padding="2"
        background="primary.500"
        textAlign="center"
        borderRadius="50%"
        opacity="0.8"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="sm" fontWeight="normal" color="white">
          Clique no botão “Gerar Qr code” para liberar o código.
        </Text>
      </VStack>
    </Box>
  );
}

export default NoCode;
