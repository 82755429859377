import { Box, Button, Stack, TabPanel, Text, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import FullPageLoading from '../../../../../components/FullPageLoading';
import useFetch from '../../../../../hooks/useFetch';
import Cover from './Cover';
import EditCoverStatusModal from './EditCoverStatusModal';
import { FormatMediaType } from './EditOrNewCovers';
import reorder from '../../../Courses/CourseManager/ModulesManager/utils/reorder';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import RemoveCoverModal from './RemoveCoverModal';

export interface ICover {
  id: number;
  name: string;
  banner: string;
  bannerMobile: string;
  bannerType: FormatMediaType;
  videoLink: string;
  order: number;
  active: number;
}

export default function Covers() {
  const history = useHistory();

  const [covers, setCovers] = useState<ICover[]>([]);
  const [reorderCovers, setReorderCovers] = useState(false);
  const [coverSelect, setCoverSelect] = useState({
    id: 0,
    formatMedia: '' as FormatMediaType,
    active: false,
  });

  const {
    isOpen: isOpenEditStatus,
    onOpen: onOpenEditStatus,
    onClose: onCloseEditStatus,
  } = useDisclosure();

  const {
    isOpen: isOpenRemoveCover,
    onOpen: onOpenRemoveCover,
    onClose: onCloseRemoveCover,
  } = useDisclosure();

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<ICover[]>>({
    method: 'get',
    url: '/banner-hero-members-area',
    authenticated: true,
    autoFetch: true,
  });

  const coversData = response?.data;
  const hasCover = coversData?.length;

  useEffect(() => {
    if (coversData) {
      setCovers(coversData);
    }
  }, [coversData]);

  const coversOrders = covers.map(cover => {
    return {
      id: cover.id,
      order: cover.order,
    };
  });

  const payloadNewOrder = {
    bannersOrders: coversOrders,
  };

  const { handleSubmit: updateCoversOrder } = useHandleSubmit({
    method: 'patch',
    data: payloadNewOrder,
    url: `/order-banner-hero-members-area`,
    authenticated: true,
    onSuccess: {
      message: 'Capas ordenadas com sucesso!',
    },
  });

  useEffect(() => {
    if (reorderCovers) {
      updateCoversOrder();
      setReorderCovers(false);
    }
  }, [reorderCovers, updateCoversOrder]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const updatedProductsInShowcases = reorder(
      covers,
      result.source.index,
      result.destination.index
    );

    setCovers(updatedProductsInShowcases);

    setReorderCovers(true);
  }

  return (
    <TabPanel marginTop="30px">
      <Stack spacing="30px">
        <Text color="#0000007D" fontSize="14px" fontWeight={400}>
          Banners ou vídeos que ficarão na página inicial da sua área de membros.
        </Text>

        <Box>
          <Button
            leftIcon={<FaPlus />}
            size="sm"
            colorScheme="primary"
            color="secondary.500"
            onClick={() => history.push('/members-area-management/netflix/new-cover')}
          >
            Adicionar capa
          </Button>
        </Box>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="covers">
            {provided => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                hidden={!hasCover}
                spacing="10px"
              >
                {covers?.map((cover, index) => {
                  return (
                    <Draggable
                      key={String(cover.id)}
                      draggableId={String(cover.id)}
                      index={index}
                      isDragDisabled={covers?.length < 2}
                    >
                      {provided => (
                        <Stack
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Cover
                            cover={cover}
                            index={index}
                            coversCount={covers?.length}
                            onOpenEditStatus={onOpenEditStatus}
                            onOpenRemoveCover={onOpenRemoveCover}
                            setCoverSelect={setCoverSelect}
                          />
                        </Stack>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Stack>

      <FullPageLoading isLoading={loading} />

      <EditCoverStatusModal
        isOpen={isOpenEditStatus}
        onClose={onCloseEditStatus}
        coverSelect={coverSelect}
        covers={covers}
        setCovers={setCovers}
      />

      <RemoveCoverModal
        isOpen={isOpenRemoveCover}
        onClose={onCloseRemoveCover}
        coverSelect={coverSelect}
        covers={covers}
        setCovers={setCovers}
      />
    </TabPanel>
  );
}
