import React from 'react';

import stylesBagde from './styles.module.css';

const Badge = ({ children, status }) => (
  <span
    className={`${stylesBagde.bedge}`}
    style={
      (status === 'ATIVO' && { background: '#22BB33' }) ||
      (status === 'BLOQUEADO' && { background: '#eb7129' }) ||
      (status === 'INATIVO' && { background: '#bb2124' }) ||
      (status === 'EM_EDICAO' && { background: '#2E8BC0' })
    }
  >
    {children}
  </span>
);

export default Badge;
