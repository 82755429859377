import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
  Switch,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BiPlus } from 'react-icons/bi';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useCourse } from '../../../../../../../contexts/CourseContext';
import { sortByOrder } from '../../../../../../../helpers/SortHelper';
import useFetch from '../../../../../../../hooks/useFetch';
import useHandleSubmit, { ResponseJSON } from '../../../../../../../hooks/useHandleSubmit';
import reorder from '../../utils/reorder';
import { ModalDeleteContent } from '../components/ModalDeleteContent';
import { QuestionCard } from '../components/QuestionCard';
import { questionLibrarySchema, questionOrderSchema } from '../questionLibrarySchemaValidator';
import {
  IQuestionLibrarySchemaPayload,
  ISubmitResponse,
  Params,
  QuestionLibraryResult,
} from '../types';

const initialValues = {
  title: '',
  order: 1,
  contentType: 'BANCO_DE_QUESTOES',
  status: 'EM_EDICAO',
  numberOfTimesAnswerQuestion: 0,
};

export function AddOrEditQuestionLibrary() {
  const { course, setCourse, getContent } = useCourse();
  const { id: courseId, moduleId, contentId } = useParams<Params>();
  const [values, setValues] = useState(initialValues);
  const [valuesRestored, setValuesRestored] = useState(initialValues);
  const [checked, setChecked] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [reorderQuestions, setReorderQuestions] = useState(false);
  const [questionListUpdate, setQuestionListUpdate] = useState<QuestionLibraryResult[]>([]);
  const [questionId, setQuestionId] = useState<number>(0);

  const {
    isOpen: isOpenDeleteContent,
    onOpen: onOpenDeleteContent,
    onClose: onCloseDeleteContent,
  } = useDisclosure();
  const history = useHistory();

  const {
    data: response,
    loading,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<QuestionLibraryResult[]>>({
    url: `/question-library/question/${courseId}/course/${moduleId}/module/${contentId}/content`,
    method: 'get',
    authenticated: true,
    autoFetch: false,
  });

  const questionList: QuestionLibraryResult[] = response?.data;

  const questionVisible = !loading && questionList?.length > 0 && contentId;
  const shouldShowButton = !contentId && !loading && !questionList?.length;
  const noQuestion = !loading && !questionList?.length;

  useEffect(() => {
    const content = getContent(Number(moduleId), Number(contentId));

    if (!contentId) {
      return setValues(initialValues);
    }

    if (content) {
      setValues({
        title: content.title,
        order: content.order,
        contentType: content.contentType,
        numberOfTimesAnswerQuestion: content.numberOfTimesAnswerQuestion,
        status: content.status,
      });

      setValuesRestored({
        title: content.title,
        order: content.order,
        contentType: content.contentType,
        numberOfTimesAnswerQuestion: content.numberOfTimesAnswerQuestion,
        status: content.status,
      });

      setChecked(content.numberOfTimesAnswerQuestion > 0);

      fetchData();
    }
  }, [contentId, fetchData, getContent, moduleId]);

  function handleSwitch(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;

    setHasChanges(true);
    setChecked(checked);

    if (!checked) {
      setValues({ ...values, numberOfTimesAnswerQuestion: 0 });
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setHasChanges(true);

    setValues({ ...values, [name]: value });
  }

  function bgTag(status: string) {
    switch (status) {
      case 'ATIVO':
        return 'green';
      case 'EM_EDICAO':
        return '#f4bd0e';
      default:
        return '#3ac94f';
    }
  }
  function translateStatusTag(status: string) {
    switch (status) {
      case 'ATIVO':
        return 'Ativo';
      case 'EM_EDICAO':
        return 'Em edição';
      default:
        return 'Em edição';
    }
  }

  function handleDiscardChanges() {
    setValues(valuesRestored);
    setChecked(false);
  }

  const payload = values;

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit<IQuestionLibrarySchemaPayload, ResponseJSON<ISubmitResponse>>({
    data: payload,
    url: `/contents/${moduleId}/module/${courseId}/course`,
    method: 'post',
    schemaValidator: questionLibrarySchema,
    authenticated: true,
    onSuccess: {
      message: 'Biblioteca de Questões criada com sucesso!',
      callback(response) {
        const newContentId = response?.data?.data?.id;

        const currentModule = course.modules.find(module => module.id === Number(moduleId));

        if (currentModule.contents.length) {
          const orders = currentModule.contents.map(content => content.order);

          values.order = orders.length ? Math.max(...orders) + 1 : initialValues.order;
        }

        const newContents = [
          ...currentModule.contents,
          { id: newContentId, order: values.order, ...values },
        ];

        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({ ...course, modules: newModules });

        setHasChanges(false);

        return history.push(
          `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${newContentId}/edit-question-library`
        );
      },
    },
  });

  const {
    isLoading: isUpdating,
    formValidation: formValidationUpdated,
    handleSubmit: handleUpdate,
  } = useHandleSubmit<IQuestionLibrarySchemaPayload, ResponseJSON<ISubmitResponse>>({
    data: payload,
    schemaValidator: questionLibrarySchema,
    url: `/contents/${contentId}/content/${moduleId}/module/${courseId}/course`,
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Biblioteca de Questões atualizada com sucesso!',
      callback() {
        const currentModule = course.modules.find(module => module.id === Number(moduleId));

        const newContents = currentModule.contents.map(content => {
          if (content.id === Number(contentId)) {
            return {
              ...content,
              title: values.title,
              order: values.order,
              numberOfTimesAnswerQuestion: values.numberOfTimesAnswerQuestion,
              contentType: values.contentType,
              status: values.status,
            };
          }

          return content;
        });

        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({ ...course, modules: newModules });

        setHasChanges(false);
      },
    },
  });

  const { isLoading: isDeleting, handleSubmit: handleDeleteContent } = useHandleSubmit({
    url: `/contents/${contentId}/content/${moduleId}/module/${courseId}/course`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Conteúdo excluído com sucesso!',
      callback() {
        const currentModule = course.modules.find(module => module.id === Number(moduleId));

        const newContents = currentModule.contents.filter(
          module => module.id !== Number(contentId)
        );

        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({
          ...course,
          modules: newModules,
        });

        return history.push(`/courses/${courseId}/course-manager/course`);
      },
    },
  });

  const { isLoading: isUnlinkQuestion, handleSubmit: unlinkQuestion } = useHandleSubmit({
    method: 'delete',
    url: `/question-library/${contentId}/content/${questionId}/question-library-content`,
    authenticated: true,
    onSuccess: {
      callback: () => {
        const newQuestionList = questionList?.filter(question => question.id !== questionId);

        fetchData();

        if (newQuestionList?.length === 0) {
          onSubmit({ publish: false });
        }
      },
    },
  });

  const questionOrders = questionListUpdate?.map(({ id, order }) => ({
    questionId: id,
    order,
  }));

  const { handleSubmit: updateQuestionsOrder } = useHandleSubmit({
    method: 'patch',
    schemaValidator: questionOrderSchema,
    data: { questionOrders },
    url: `/question-library/${contentId}/content/order-questions`,
    authenticated: true,
    onSuccess: {
      message: 'Questões ordenadas com sucesso!',
      callback: fetchData,
    },
  });

  useEffect(() => {
    if (reorderQuestions && questionListUpdate.length) {
      updateQuestionsOrder();
      setReorderQuestions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reorderQuestions]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const updatedQuestions = reorder(questionList, result.source.index, result.destination.index);

    setQuestionListUpdate(updatedQuestions);
    setReorderQuestions(true);
  }

  const disablePublish = isSubmitting || isUpdating || !contentId || !response?.data?.length;

  async function onSubmit({ publish }: { publish: boolean }) {
    if (publish) {
      values.status = 'ATIVO';
    } else {
      values.status = 'EM_EDICAO';
    }

    if (contentId) {
      handleUpdate();
      return;
    }
    await handleSubmit();
  }

  return (
    <Box>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        justifyContent={{ base: 'center', md: 'start', lg: 'start' }}
        alignItems={{ base: 'center', md: 'baseline', lg: 'baseline' }}
        width="full"
        marginBottom={5}
        flex={1}
        gap={{ base: 2, md: 5, lg: 5 }}
      >
        <Heading fontSize="2xl" fontWeight={500}>
          {values?.title ? values?.title : 'Conteúdo tipo Questões'}
        </Heading>

        <Tag
          hidden={!contentId}
          size="lg"
          borderRadius="full"
          variant="subtle"
          width={{ base: '100%', md: 'auto', lg: 'auto' }}
          alignItems="center"
          justifyContent={{ base: 'center', md: 'start', lg: 'start' }}
          bgColor={bgTag(values?.status)}
          color="white"
        >
          {translateStatusTag(values?.status)}
        </Tag>
      </Stack>

      <Stack as="form" spacing={5}>
        <FormControl
          isInvalid={formValidation?.title?.isInvalid || formValidationUpdated?.title.isInvalid}
        >
          <FormLabel htmlFor="title">Título do questionário:</FormLabel>
          <Input
            id="title"
            name="title"
            type="text"
            size="sm"
            borderRadius={6}
            placeholder="Digite um título para o seu conteúdo"
            focusBorderColor="orange.500"
            onChange={handleChange}
            value={values.title}
            maxLength={255}
            minLength={1}
          />
          <FormErrorMessage>
            {formValidation?.title?.message || formValidationUpdated?.title.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <Stack direction="row" alignItems="center">
            <Switch size="sm" isChecked={checked} onChange={handleSwitch} colorScheme="orange" />
            <FormLabel htmlFor="order">Ativar opção de refazer o questionário</FormLabel>
          </Stack>
        </FormControl>

        <FormControl hidden={!checked}>
          <FormLabel htmlFor="order">Quantas vezes pode ser refeito?</FormLabel>
          <NumberInput
            step={1}
            defaultValue={0}
            size="sm"
            min={0}
            borderRadius={6}
            focusBorderColor="orange.500"
            value={values.numberOfTimesAnswerQuestion}
            onChange={valueString =>
              setValues({ ...values, numberOfTimesAnswerQuestion: parseInt(valueString) })
            }
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <Button
          as={Link}
          variant="outline"
          colorScheme="orange"
          to={`/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${contentId}/insert-questions?contentId=${contentId}`}
          type="submit"
          hidden={!contentId}
        >
          <Stack direction="row" alignItems="center">
            <BiPlus size={15} />
            <Text>Adicionar Questão</Text>
          </Stack>
        </Button>

        <Stack spacing={2} paddingTop={2}>
          {questionVisible && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="questions">
                {provided => (
                  <Stack {...provided.droppableProps} ref={provided.innerRef} spacing={4}>
                    {questionList?.sort(sortByOrder)?.map((question, index) => {
                      return (
                        <Draggable
                          key={String(question.id)}
                          draggableId={String(question.id)}
                          index={index}
                          isDragDisabled={questionList?.length < 2}
                        >
                          {provided => (
                            <Stack
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <QuestionCard
                                key={question.id}
                                question={question}
                                hiddenSelect={true}
                                setQuestionId={setQuestionId}
                                unlinkQuestion={unlinkQuestion}
                                isUnlinkQuestion={isUnlinkQuestion}
                              />
                            </Stack>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
          )}

          {loading && (
            <Stack width="full" alignItems="center" padding={10} color="orange.500">
              <Spinner size="lg" />
            </Stack>
          )}

          {shouldShowButton && (
            <Stack width="full" alignItems="center" paddingY={2} color="orange.500">
              <Heading fontSize="md" color="#20212380">
                O botão "Adicionar Questão" só aparecerá após salvar o conteúdo como rascunho.
              </Heading>
            </Stack>
          )}

          {noQuestion && (
            <Stack width="full" alignItems="center" padding={5} color="orange.500">
              <Heading fontSize="md" color="#20212380">
                Você não possui questões adicionadas. Clique em "Adicionar Questão"
              </Heading>
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        width={{ base: '100%', md: 'auto', lg: 'auto' }}
        justifyContent={{
          base: 'center',
          md: !contentId ? 'flex-end' : 'space-between',
          lg: !contentId ? 'flex-end' : 'space-between',
        }}
        paddingY={5}
        spacing={2}
      >
        <Button
          hidden={!contentId}
          onClick={onOpenDeleteContent}
          isDisabled={isDeleting}
          isLoading={isDeleting}
          colorScheme="red"
          size="sm"
          width={{ base: '100%', md: 'auto', lg: 'auto' }}
        >
          <Text>Excluir</Text>
        </Button>
        <Stack
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          width={{ base: '100%', md: 'auto', lg: 'auto' }}
          spacing={2}
        >
          <Button
            size="sm"
            width={{ base: '100%', md: 'auto', lg: 'auto' }}
            onClick={handleDiscardChanges}
            isDisabled={!hasChanges}
          >
            Descartar mudanças
          </Button>

          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<MdKeyboardArrowDown />}
              colorScheme="orange"
              size="sm"
              width={{ base: '100%', md: 'auto', lg: 'auto' }}
            >
              Salvar
            </MenuButton>
            <MenuList>
              <MenuItem
                isDisabled={isSubmitting || isUpdating}
                onClick={() => onSubmit({ publish: false })}
              >
                Salvar rascunho
              </MenuItem>
              <MenuItem isDisabled={disablePublish} onClick={() => onSubmit({ publish: true })}>
                Publicar conteúdo
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </Stack>

      <ModalDeleteContent
        isOpen={isOpenDeleteContent}
        onClose={onCloseDeleteContent}
        handleDeleteContent={handleDeleteContent}
      />
    </Box>
  );
}
