import { Flex, Text, VStack } from '@chakra-ui/react';
import { Promotion } from '../../../../types';

interface SquareProps {
  time: number;
  timeUnit: 'day' | 'hour' | 'min' | 'second';
  secondaryColor: Promotion['secondaryColor'];
  primaryColor: Promotion['primaryColor'];
}

function getTimeString(time: number) {
  return time?.toString().padStart(2, '0');
}

export default function Square({ time, timeUnit, primaryColor, secondaryColor }: SquareProps) {
  return (
    <VStack gap={0}>
      <Flex
        alignItems="center"
        direction="column"
        bg={secondaryColor}
        borderRadius="base"
        boxSize={{ base: 8, xl: 10 }}
      >
        <Text
          mt={{ base: 0.5, xl: 1 }}
          fontSize={{ base: 'xl', xl: '2xl' }}
          fontWeight="bold"
          color={primaryColor}
          as="span"
        >
          {getTimeString(time) ?? '00'}
        </Text>

        <Text
          mt={{ base: 0.5, xl: 1 }}
          fontSize={{ base: '10.75px', xl: 'xs' }}
          color={secondaryColor}
        >
          {timeUnit === 'day' && (time === 1 ? 'Dia' : 'Dias')}
          {timeUnit === 'hour' && (time === 1 ? 'Hora' : 'Horas')}
          {timeUnit === 'min' && 'Min'}
          {timeUnit === 'second' && 'Seg'}
        </Text>
      </Flex>
    </VStack>
  );
}
