import { Box, HStack, List, ListItem, Text } from '@chakra-ui/react';
import useDeviceDetect from '../../../../../hooks/useDeviceDetect';

export default function Pix() {
  const { isMobile } = useDeviceDetect();

  return (
    <HStack spacing={5} align="start">
      <Box flex={1}>
        <Box>
          <Text as="strong">
            Ok! Vamos lá. Você obterá acesso ao produto imediatamente após o pagamento.
          </Text>
        </Box>

        {isMobile ? (
          <>
            <Box mt={7}>
              <Text as="strong">Passo a passo pelo celular:</Text>
            </Box>
            <List mt={7} spacing={4}>
              <ListItem>1. Clique no botão gerar “Gerar Qr code”;</ListItem>
              <ListItem>2. Abre o aplicativo do seu banco;</ListItem>
              <ListItem>3. Selecione a opção “Pagar com Pix” ou “Pagar com QR Code”;</ListItem>
              <ListItem>4. Escaneie o QR Code ou copie o código e cole no seu aplicativo.</ListItem>
            </List>
          </>
        ) : (
          <>
            <List mt={5} spacing={4}>
              <Text as="strong" mt={12}>
                Passo a passo pelo computador:
              </Text>
              <ListItem>1. Clique no botão gerar “Gerar Qr code”;</ListItem>
              <ListItem>2. Abre o aplicativo do seu banco e vá na opção "QR CODE";</ListItem>
              <ListItem>
                3. Direcione a câmera do seu celular para o Qr Code que está do lado esquerdo desse
                texto;
              </ListItem>
              <ListItem>4. Confira o valor da transação e confirme o pagamento.</ListItem>
            </List>
          </>
        )}

        <Box mt={7}>
          <Text as="strong">Pronto! Agora você terá acesso ao seu produto.</Text>
        </Box>
      </Box>
    </HStack>
  );
}
