import ReactApexChart from 'react-apexcharts';
import { Box } from '@chakra-ui/react';
import options from './options';

function Chart({ rightQuestions, wrongQuestions }) {
  const series = [rightQuestions, wrongQuestions];

  return (
    <Box display="flex" justifyContent="center" mt="10">
      <ReactApexChart options={options} series={series} type="donut" width="256" />
    </Box>
  );
}

export default Chart;
