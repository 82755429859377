import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { WebhookView } from '../types';
import WebhooksTableRow from './WebhooksTableRow';

interface WebhooksTableProps extends TableContainerProps {
  webhooks: Partial<WebhookView>[];
  onDeleteWebhook: (webhookId: number) => void;
  onChangeStatus: (webhookId: number) => Promise<void>;
}

export default function WebhooksTable({
  webhooks,
  onDeleteWebhook,
  onChangeStatus,
  ...rest
}: WebhooksTableProps) {
  return (
    <TableContainer {...rest} border="1px solid #2021230D" borderRadius="5px">
      <Table>
        <Thead>
          <Tr sx={{ th: { color: '#202123BF' } }}>
            <Th>Tipo</Th>
            <Th>Webhook</Th>
            <Th>Eventos</Th>
            <Th>URL</Th>
            <Th>Status</Th>
            <Th textAlign="center">Ações</Th>
          </Tr>
        </Thead>

        <Tbody sx={{ 'tr:nth-of-type(odd)': { background: '#2021230D' } }}>
          {webhooks?.map(webhook => (
            <WebhooksTableRow
              key={webhook.id}
              webhook={webhook}
              onDeleteWebhook={onDeleteWebhook}
              onChangeStatus={onChangeStatus}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
