import { Box, Heading, List as ChakraList, ListItem, Spinner, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import convertToBrazilianCurrency from '../../../../../helpers/convertToBrazilianCurrency';
import { bgByTransaction, convertPaymentMethod, convertPaymentStatus } from '../../utils';

export default function PaymentHistoryCard({ transactions, isLoading }) {
  return (
    <>
      {isLoading && (
        <Stack w="100%" direction="row" justifyContent="center">
          <Spinner size="md" color="orange.500" />
        </Stack>
      )}

      {transactions?.length === 0 && !isLoading && (
        <Box
          marginY="4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Heading as="h2" fontSize={{ base: 'md', lg: 'lg' }}>
            Ainda não há informações disponíveis.
          </Heading>
        </Box>
      )}

      <Box
        hidden={isLoading || !transactions?.length}
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(15rem, 1fr))"
        gap="5"
      >
        {transactions?.map(transaction => (
          <Box
            key={transaction.transactionId}
            width="full"
            paddingX="4"
            paddingTop="5"
            paddingBottom="1.5"
            background="white"
            boxShadow="1px 1px 4px rgba(0, 0, 0, 0.25)"
            borderRadius="6px"
            position="relative"
          >
            <ChakraList
              color="dark.500"
              marginTop="4"
              sx={{ '& > li:not(first-child)': { padding: '0.75rem 0' } }}
            >
              <ListItem
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid rgba(32, 33, 35, 0.15)"
              >
                <Heading fontSize="sm" color="textColor">
                  ID:
                </Heading>
                <Text color="textColor" fontWeight={600}>
                  {transaction.transactionId}
                </Text>
              </ListItem>
              <ListItem
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid rgba(32, 33, 35, 0.15)"
              >
                <Heading fontSize="sm" color="textColor">
                  Status:
                </Heading>
                <Box
                  display="flex"
                  justifyContent="center"
                  p={1}
                  borderRadius={6}
                  fontWeight={600}
                  color="whiteAlpha.900"
                  bg={bgByTransaction(transaction.status)}
                  isTruncated
                >
                  {convertPaymentStatus(transaction.status)}
                </Box>
              </ListItem>
              <ListItem
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid rgba(32, 33, 35, 0.15)"
              >
                <Heading fontSize="sm" color="textColor">
                  Nome
                </Heading>
                <Text color="textColor">{transaction.name}</Text>
              </ListItem>
              <ListItem
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid rgba(32, 33, 35, 0.15)"
              >
                <Heading fontSize="sm" color="textColor">
                  Valor
                </Heading>
                <Text color="textColor">{convertToBrazilianCurrency(transaction.amount)}</Text>
              </ListItem>
              <ListItem
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid rgba(32, 33, 35, 0.15)"
              >
                <Heading fontSize="sm" color="textColor">
                  Pagamento
                </Heading>
                <Text color="textColor">{convertPaymentMethod(transaction.paymentMethod)}</Text>
              </ListItem>
              <ListItem display="flex" justifyContent="space-between">
                <Heading fontSize="sm" color="textColor">
                  Data de Criação
                </Heading>
                <Text color="textColor">{dayjs(transaction.createdAt).format('DD/MM/YYYY')}</Text>
              </ListItem>
            </ChakraList>
          </Box>
        ))}
      </Box>
    </>
  );
}
