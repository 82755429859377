import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Skeleton,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import dayjs from 'dayjs';
import { Suspense } from 'react';
import AvatarPreviewMembersArea from '../../../components/AvatarPreviewMembersArea';
import { onlyAlphaNumeric, onlyNumber } from '../../../hooks/useHandleChange';
import { useStudentProfile } from '../../../hooks/useStudentProfile';
import ChangePasswordModal from './ChangePasswordModal';
import CitySelect from './CitySelect';
import CountrySelect, { getCountryByDDI } from './CountrySelect';
import SelectImage from './SelectImage';
import StateSelect from './StateSelect';

export default function Profile() {
  const {
    form,
    formValidation,
    hasChange,
    preview,
    isUploading,
    isSubmitting,
    isFetching,
    isForeignUser,
    isChangePasswordModalOpen,
    handleChange,
    onCloseChangePasswordModal,
    onOpenChangePasswordModal,
    handleCountryChange,
    handleDropRejected,
    handleDiscardImage,
    handleDropFile,
    handleStateChange,
    onSubmit,
  } = useStudentProfile();

  if (isFetching) {
    return (
      <Center height="80vh">
        <Spinner color="secondary.500" size="xl" thickness="4px" speed="1s" emptyColor="gray.200" />
      </Center>
    );
  }

  return (
    <Box as="form" onSubmit={onSubmit} noValidate>
      <Box>
        <Heading fontSize={{ base: 'lg', xl: '3xl' }} fontWeight="500">
          Editar Perfil
        </Heading>
      </Box>

      <VStack spacing={6} marginTop={8}>
        <Box width="100%">
          <Text fontSize="16px" fontWeight="500" marginBottom={2}>
            Foto
          </Text>

          {!form?.photo ? (
            <SelectImage onDrop={handleDropFile} onDropRejected={handleDropRejected} />
          ) : (
            <Box>
              <AvatarPreviewMembersArea
                preview={preview}
                handlePhotoChange={handleDropFile}
                handleDiscardImage={handleDiscardImage}
              />
            </Box>
          )}
        </Box>

        <Stack width="full" direction={{ base: 'column', xl: 'row' }} spacing={{ base: 5 }}>
          <FormControl isInvalid={!!formValidation?.fullName?.isInvalid}>
            <FormLabel>Nome</FormLabel>

            <Input
              name="fullName"
              type="text"
              onChange={handleChange}
              value={form?.fullName}
              borderRadius={6}
              placeholder="Insira seu nome completo"
              focusBorderColor="gray.300"
              maxLength={80}
            />

            <FormErrorMessage>{formValidation?.fullName?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formValidation?.email?.isInvalid}>
            <FormLabel>E-mail</FormLabel>
            <Input
              name="email"
              type="email"
              value={form?.email}
              onChange={handleChange}
              focusBorderColor="gray.300"
              borderRadius={6}
              placeholder="Nome completo"
              maxLength={255}
            />

            <FormErrorMessage>{formValidation?.email?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        <FormControl>
          <FormLabel>Senha</FormLabel>

          <Button
            onClick={onOpenChangePasswordModal}
            size={{ base: 'md', xl: 'xs' }}
            variant="outline"
            colorScheme="primary"
            // color="primary.500"
            width={{ base: 'full', xl: 'auto' }}
          >
            Alterar senha
          </Button>
        </FormControl>

        <Stack width="full" direction={{ base: 'column', xl: 'row' }} spacing={{ base: 4, xl: 5 }}>
          <FormControl>
            <FormLabel htmlFor="country">País</FormLabel>

            <Suspense fallback={<Skeleton w="full" h={10} />}>
              <CountrySelect value={getCountryByDDI(form?.ddi)} onChange={handleCountryChange} />
            </Suspense>
          </FormControl>

          <FormControl
            isInvalid={formValidation?.ddd?.isInvalid || formValidation?.phone.isInvalid}
          >
            <FormLabel>Telefone</FormLabel>

            <HStack spacing={{ base: 4, xl: 5 }}>
              {!isForeignUser && (
                <Input
                  name="ddd"
                  type="tel"
                  width="150px"
                  value={form?.ddd}
                  onChange={handleChange}
                  focusBorderColor="gray.300"
                  borderRadius={6}
                  placeholder="Ex: 85"
                  onInput={onlyNumber}
                  maxLength={2}
                />
              )}

              <Input
                name="phone"
                type="tel"
                value={form?.phone}
                onChange={handleChange}
                focusBorderColor="gray.300"
                borderRadius={6}
                placeholder="----- ----"
                onInput={onlyNumber}
                maxLength={20}
              />
            </HStack>

            <FormErrorMessage>
              {formValidation?.ddd?.message || formValidation?.phone?.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>

        <Stack width="full" direction={{ base: 'column', xl: 'row' }} spacing={{ base: 4, xl: 5 }}>
          <FormControl isInvalid={!!formValidation?.dateOfBirth?.isInvalid}>
            <FormLabel>Data de nascimento</FormLabel>

            <Input
              name="dateOfBirth"
              type="date"
              value={dayjs(form?.dateOfBirth).utc().format('YYYY-MM-DD')}
              onChange={handleChange}
              focusBorderColor="gray.300"
              borderRadius={6}
              min="1900-01-01"
              max={dayjs().format('YYYY-MM-DD')}
            />

            <FormErrorMessage>{formValidation?.dateOfBirth?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formValidation?.documentNumber?.isInvalid}>
            <FormLabel>Documento</FormLabel>

            <Input
              name="documentNumber"
              type="text"
              value={form?.documentNumber}
              onChange={handleChange}
              focusBorderColor="gray.300"
              borderRadius={6}
              placeholder="Documento"
            />

            <FormErrorMessage>{formValidation?.documentNumber?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        {isForeignUser ? (
          <FormControl isInvalid={!!formValidation?.alternateAddress?.isInvalid}>
            <FormLabel>Endereço</FormLabel>

            <Input
              name="alternateAddress"
              value={form?.alternateAddress ?? ''}
              onChange={handleChange}
              focusBorderColor="gray.300"
              borderRadius={6}
              placeholder="Endereço"
            />

            <FormErrorMessage>{formValidation?.alternateAddress?.message}</FormErrorMessage>
          </FormControl>
        ) : (
          <>
            <FormControl isInvalid={!!formValidation?.zipCode?.isInvalid}>
              <FormLabel>CEP</FormLabel>

              <Input
                name="zipCode"
                value={form?.zipCode ?? ''}
                onChange={handleChange}
                placeholder="Digite somente números"
                focusBorderColor="gray.300"
                borderRadius={6}
                onInput={onlyNumber}
                maxLength={8}
              />

              <FormErrorMessage>{formValidation?.zipCode?.message}</FormErrorMessage>
            </FormControl>

            <Stack
              width="full"
              direction={{ base: 'column', xl: 'row' }}
              spacing={{ base: 4, xl: 5 }}
            >
              <FormControl isInvalid={!!formValidation?.streetAddress?.isInvalid}>
                <FormLabel>Rua</FormLabel>

                <Input
                  name="streetAddress"
                  value={form?.streetAddress ?? ''}
                  onChange={handleChange}
                  focusBorderColor="gray.300"
                  borderRadius={6}
                  placeholder="Rua"
                />

                <FormErrorMessage>{formValidation?.streetAddress?.message}</FormErrorMessage>
              </FormControl>

              <FormControl
                maxWidth={{ base: 'initial', xl: '33.33%' }}
                isInvalid={!!formValidation?.streetNumber?.isInvalid}
              >
                <FormLabel>Número</FormLabel>

                <Input
                  name="streetNumber"
                  value={form?.streetNumber ?? ''}
                  onChange={handleChange}
                  focusBorderColor="gray.300"
                  borderRadius={6}
                  placeholder="Número"
                  onInput={onlyAlphaNumeric}
                />

                <FormErrorMessage>{formValidation?.streetNumber?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              width="full"
              direction={{ base: 'column', xl: 'row' }}
              spacing={{ base: 4, xl: 5 }}
            >
              <FormControl isInvalid={!!formValidation?.neighborhood?.isInvalid}>
                <FormLabel>Bairro</FormLabel>

                <Input
                  name="neighborhood"
                  value={form?.neighborhood ?? ''}
                  onChange={handleChange}
                  focusBorderColor="gray.300"
                  borderRadius={6}
                  placeholder="Bairro"
                />

                <FormErrorMessage>{formValidation?.neighborhood?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formValidation?.complementary?.isInvalid}>
                <FormLabel>Complemento</FormLabel>

                <Input
                  name="complementary"
                  value={form?.complementary ?? ''}
                  onChange={handleChange}
                  focusBorderColor="gray.300"
                  borderRadius={6}
                  placeholder="Complemento"
                />

                <FormErrorMessage>{formValidation?.complementary?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack
              width="full"
              direction={{ base: 'column', xl: 'row' }}
              spacing={{ base: 4, xl: 5 }}
            >
              <FormControl isInvalid={!!formValidation?.state?.isInvalid}>
                <FormLabel>Estado</FormLabel>

                <StateSelect value={form?.state ?? ''} onChange={handleStateChange} />

                <FormErrorMessage>{formValidation?.state?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formValidation?.city?.isInvalid}>
                <FormLabel>Cidade</FormLabel>

                <CitySelect
                  state={form?.state ?? ''}
                  value={form?.city || ''}
                  onChange={handleChange}
                />

                <FormErrorMessage>{formValidation?.city?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </>
        )}

        <Button
          alignSelf="end"
          width={{ base: '100%', xl: 'initial' }}
          colorScheme="primary"
          color="secondary.500"
          type="submit"
          isDisabled={!hasChange}
          isLoading={isSubmitting || isUploading}
        >
          Salvar alterações
        </Button>
      </VStack>

      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={onCloseChangePasswordModal}
      />
    </Box>
  );
}
