import { useToast } from '@chakra-ui/react';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { axiosBackend } from '../../../../../api/Configuration';
import useHandleChange from '../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../utils/scrollToInvalidInput';
import { JoiSchemasType } from '../../../../../validation/EntitySchema';
import { EventName, Webhook, WebhookView } from '../types';

interface useInstructorFormProps {
  url?: string;
  method?: 'post' | 'patch';
  data?: WebhookView;
  successMessage?: string;
  schemaValidator: JoiSchemasType<Partial<Webhook>>;
}

interface SelectedProducts {
  value: number;
  label: string;
}

export default function useWebhookProductsForm({
  url,
  method,
  data,
  successMessage,
  schemaValidator,
}: useInstructorFormProps) {
  const [selectedProducts, setSelectedProducts] = useState<SelectedProducts[] | null>(null);

  const [products, setProducts] = useState([]);

  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    async function getProducts() {
      const { data: productsResponse } = await axiosBackend().get(
        '/courses/list?status=ATIVO_E_NLISTADO'
      );

      if (!productsResponse) {
        toast({
          status: 'error',
          title: 'Erro ao tentar cadastrar Webhook',
          description: 'Você não possui produtos com os status: Ativo ou Não listado',
        });

        return history.push('/integrations/webhook');
      }

      const mappedProducts = [
        {
          value: 0,
          label: 'TODOS',
        },
        ...(productsResponse?.data?.map(product => ({
          value: product.id,
          label: product.name,
        })) || []),
      ];

      setProducts(mappedProducts);
    }

    getProducts();
  }, [history, toast]);

  const {
    form,
    setForm,
    onChanged: hasChange,
    handleChange,
    setOnChanged: setHasChange,
    handleCancel,
  } = useHandleChange<Webhook>(
    method === 'patch'
      ? {
          webhookName: data.webhookName,
          events: data.events,
          webhookType: 'product',
          coursesIds: data.products.map(product => product.id),
          webhookUrl: data?.webhookUrl,
          status: data.status,
        }
      : {
          webhookName: '',
          events: [],
          webhookType: 'product',
          coursesIds: [],
          webhookUrl: '',
          status: 'ATIVO',
        }
  );

  useEffect(() => {
    if (method === 'patch') {
      setSelectedProducts(
        Array.isArray(data?.products) && data?.products.length
          ? data.products.map(product => ({ value: product.id, label: product.name }))
          : [{ value: 0, label: 'TODOS' }]
      );
    }
  }, [data?.products, method]);

  function handleEventsSelect(events: EventName[]) {
    setHasChange(true);

    setForm(prevForm => ({
      ...prevForm,
      events,
    }));
  }

  function handleSelectProducts(selectedProducts: SelectedProducts[]) {
    setHasChange(true);

    if (selectedProducts.map(({ value }) => value).includes(0)) {
      setForm(prevForm => ({
        ...prevForm,
        coursesIds: [0],
      }));

      setSelectedProducts([{ value: 0, label: 'TODOS' }]);

      return;
    }

    setSelectedProducts(selectedProducts);

    setForm(prevForm => ({
      ...prevForm,
      coursesIds: selectedProducts.map(({ value }) => value),
    }));
  }

  function onCancel() {
    if (!hasChange) {
      return history.push('/integrations/webhook');
    }

    handleCancel();
  }

  const payload = {
    ...form,
    coursesIds: form.coursesIds?.includes(0) ? null : form.coursesIds,
    ...(method === 'patch' && { status: data.status }),
  };

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: payload,
    schemaValidator,
    url,
    method,
    authenticated: true,
    onSuccess: {
      message: successMessage,
      callback: () => history.push('/integrations/webhook'),
    },
    removeNullProps: false,
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  return {
    form,
    formValidation,
    isSubmitting,
    hasChange,
    products,
    selectedProducts,
    handleChange,
    onSubmit,
    handleSelectProducts,
    handleCancel: onCancel,
    setSelectedProducts,
    handleEventsSelect,
  };
}
