import { Box, Grid, Heading } from '@chakra-ui/react';
import { Integration } from '..';
import IntegrationItem from '../IntegrationItem';

interface IntegrationsCategorySectionProps {
  category?: string;
  integrations?: Integration[];
}

export default function IntegrationsCategorySection({
  category,
  integrations,
}: IntegrationsCategorySectionProps) {
  return (
    <Box mt={8}>
      <Heading fontWeight="medium" fontSize="xl">
        {category}
      </Heading>

      <Grid
        gap={6}
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
          xl: 'repeat(4, 1fr)',
        }}
      >
        {integrations.map(integration => (
          <IntegrationItem key={integration.id} integration={integration} />
        ))}
      </Grid>
    </Box>
  );
}
