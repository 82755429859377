import { UtmItem } from '../useUtmGenerator';

export function generateUTMLink(utm: UtmItem) {
  const params = new URLSearchParams();

  const utmParams = {
    utm_campaign: utm?.utmCampaign,
    utm_source: utm?.utmSource,
    utm_medium: utm?.utmMedium,
    utm_id: utm?.utmId,
    utm_term: utm?.utmTerm,
    utm_content: utm?.utmContent,
  };

  for (const [key, value] of Object.entries(utmParams)) {
    if (value) {
      params.append(key, value);
    }
  }

  const paramString = params.toString();

  return `${utm?.link}?${paramString}`;
}
