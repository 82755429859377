export default function getDocumentType(country: string, value: string) {
  if (country !== 'br') {
    return 'other';
  }

  const size = value?.length;
  const isCpf = size <= 11;
  const isCnpj = size === 14;

  if (isCpf) {
    return 'cpf';
  }

  if (isCnpj) {
    return 'cnpj';
  }
}
