import { Center, Spinner } from '@chakra-ui/react';

export default function FullPageLoading() {
  return (
    <Center
      id="overlay"
      inset={0}
      position="fixed"
      zIndex={999}
      overflow="hidden"
      background="#202123"
    >
      <Spinner color="secondary.500" size="xl" thickness="4px" speed="1s" emptyColor="gray.200" />
    </Center>
  );
}
