import { Grid, GridProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserAPI from '../../../../../api/User';
import PathCardItem from '../PathCardItem';
import paths from './paths';
import Toast from '../../../../../components/Toast';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import RegisterBankDetails from '../../../../../assets/images/redirection/register-bank-details.svg';

const bankAccountsPath = {
  title: 'Cadastrar Dados Bancários',
  image: RegisterBankDetails,
  path: '/profile/bank-accounts',
  description: 'Cadastre seus dados bancários.',
};

export default function PathCards(props: GridProps) {
  const { push } = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getUserBankAccount() {
      try {
        const response = await UserAPI.showBankAccount();
        const hasBankAccount = !!response.data;

        if (!hasBankAccount) {
          paths.push(bankAccountsPath);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    }

    getUserBankAccount();
  }, []);

  function redirectToPath(path: string) {
    push(path);
  }

  return (
    <Grid
      as="section"
      templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(4, 1fr)' }}
      gap={4}
      {...props}
    >
      {paths.map(({ path, description, image, title }, index) => (
        <PathCardItem
          path={path}
          onClick={redirectToPath}
          key={index}
          image={image}
          description={description}
          title={title}
          isLoading={isLoading}
        />
      ))}
    </Grid>
  );
}
