import { createContext, useContext, useState } from 'react';

interface ISearchContext {
  search: string;
  onSearchChange: (value: string) => void;
}

const SearchContext = createContext<ISearchContext | null>(null);

export default function SearchContextProvider({ children }) {
  const [search, setSearch] = useState('');

  function onSearchChange(value) {
    setSearch(value);
  }

  return (
    <SearchContext.Provider value={{ search, onSearchChange }}>{children}</SearchContext.Provider>
  );
}

export function useSearch() {
  const context = useContext(SearchContext);

  if (!context) throw new Error('useSearch deve ser usada dentro de um SearchContext.Provider.');

  return {
    search: context.search,
    onSearchChange: context.onSearchChange,
  };
}
