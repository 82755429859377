import { axiosBackend } from './Configuration';

export default {
  store: async (course_id, module_id, newContent) => {
    try {
      const dataContentData = await axiosBackend().post(
        `/contents/${module_id}/module/${course_id}/course`,
        newContent
      );

      if (dataContentData && dataContentData?.data) {
        return dataContentData.data;
      }

      throw new Error('Erro inesperado');
    } catch (error) {
      throw error;
    }
  },

  progress: {
    show: async courseId => {
      try {
        const { data: contentData } = await axiosBackend().get(
          `/content-progress/${courseId}/course`
        );

        return contentData;
      } catch (error) {
        throw error;
      }
    },
    update: async (courseId, contentId, checkbox) => {
      try {
        const { data: contentData } = await axiosBackend().patch(
          `/content-progress/${courseId}/course/${contentId}/content`,
          { checkbox }
        );

        return contentData;
      } catch (error) {
        throw error;
      }
    },
  },

  video: {
    store: async (course_id, module_id, video_id, newContent) => {
      try {
        const { data: contentData } = await axiosBackend().post(
          `contents/${module_id}/module/${course_id}/course/${video_id}/video-library`,
          newContent
        );

        return contentData;
      } catch (error) {
        throw error;
      }
    },
    update: async (content_id, module_id, course_id, video_id, newContent) => {
      try {
        const { data: contentData } = await axiosBackend().patch(
          `contents/${content_id}/content/${module_id}/module/${course_id}/course/${video_id}/video-library`,
          newContent
        );

        return contentData;
      } catch (error) {
        throw error;
      }
    },
  },

  delete: async (course_id, module_id, content_id) => {
    try {
      await axiosBackend().delete(
        `/contents/${content_id}/content/${module_id}/module/${course_id}/course`
      );
    } catch (error) {
      throw error;
    }
  },

  update: async (course_id, module_id, content_id, contentToUpdate) => {
    try {
      const dataContentData = await axiosBackend().patch(
        `/contents/${content_id}/content/${module_id}/module/${course_id}/course`,
        contentToUpdate
      );

      if (dataContentData && dataContentData?.data) {
        return dataContentData.data;
      }

      throw new Error('Erro indesperado');
    } catch (error) {
      throw error;
    }
  },

  getStatistics: async (content_id, course_id) => {
    try {
      const { data: videStatistics } = await axiosBackend().get(
        `/contents/${content_id}/content/${course_id}/course/video-statistics`
      );

      return videStatistics.data;
    } catch (error) {
      throw error;
    }
  },

  sendStatistics: async (content_id, course_id, progress) => {
    try {
      const { data: videStatistics } = await axiosBackend().patch(
        `/contents/${content_id}/content/${course_id}/course/video-statistics`,
        { progress }
      );

      return videStatistics.data;
    } catch (error) {
      throw error;
    }
  },

  tasks: {
    index: async (
      content_id,
      module_id,
      course_id,
      page = 1,
      search = '',
      per_page,
      status = ''
    ) => {
      try {
        const { data: taskData } = await axiosBackend().get(
          `/contents/${module_id}/module/${course_id}/course/task?page=${page}${
            search && `&search=${search}`
          }${content_id && `&content_id=${content_id}`}${per_page && `&per_page=${per_page}`}${
            status && `&status=${status}`
          }`
        );

        return taskData;
      } catch (error) {
        throw error;
      }
    },
  },

  contentType: {
    index: async (module_id, course_id, content_type) => {
      try {
        const { data: contentData } = await axiosBackend().get(
          `/contents/${module_id}/module/${course_id}/course?content_type=${content_type}`
        );

        return contentData;
      } catch (error) {
        throw error;
      }
    },
  },

  updateContentsOrder: async (course_id, module_id, contentsOrders) => {
    try {
      await axiosBackend().patch(
        `/contents/${course_id}/course/${module_id}/module/order-contents`,
        {
          contentsOrders,
        }
      );
    } catch (error) {
      throw error;
    }
  },

  copyContent: async (courseId, moduleId, contentId, payload) => {
    try {
      const { data: contentData } = await axiosBackend().post(
        `/contents/${courseId}/course/${moduleId}/module/${contentId}/content/copy-contents`,
        payload
      );

      return contentData;
    } catch (error) {
      throw error;
    }
  },
};
