import {
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { HiDownload } from 'react-icons/hi';
import { MdArrowBackIos } from 'react-icons/md';
import { Link, useHistory, useParams } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { exportLeads } from '../../../../helpers/exportLeads';
import useFetch from '../../../../hooks/useFetch';
import useQuery from '../../../../hooks/useQuery';
import { TableLeadList } from './Table';

interface IParams {
  id: string;
}

export interface ILeadsBySegmentation {
  id: number;
  name: string;
  email: string;
  phone: string;
  numberOfConversions: number;
  lastConversion: string;
}

interface IResponse {
  leadsBySegmentation: ILeadsBySegmentation[];
  segmentationName: string;
  page: number;
  per_page: number;
  total: number;
}

export function LeadList() {
  const { id } = useParams<IParams>();
  const [leadsBySegmentation, setLeadsBySegmentation] = useState<ILeadsBySegmentation[]>([]);
  const [leadsBySegmentationFiltered, setLeadsBySegmentationFiltered] = useState<
    ILeadsBySegmentation[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [timer, setTimer] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [searchFiltered, setSearchFiltered] = useState<string>('');

  const history = useHistory();
  const query = useQuery();

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<IResponse>>({
    method: 'get',
    url: `/segmentation/${id}/leads-by-segmentation?page=${currentPage}${
      search ? `&search=${search}` : ''
    }`,
    authenticated: true,
    autoFetch: true,
  });

  useEffect(() => {
    if (response?.data?.leadsBySegmentation) {
      setLeadsBySegmentation(response?.data?.leadsBySegmentation);
      setLeadsBySegmentationFiltered(response?.data?.leadsBySegmentation);
      setPageCount(response?.data?.total / response?.data?.per_page);
    } else {
      setLeadsBySegmentationFiltered([]);
    }
  }, [response?.data?.leadsBySegmentation, response?.data?.per_page, response?.data?.total]);

  useEffect(() => {
    let page = query.get('page');

    if (page) {
      try {
        if (Number(page) < 1) {
          return history.push(`/segmentation/${id}/lead-list?page=1`);
        }

        setCurrentPage(Number(page));
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }
  }, [history, id, query]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        history.push(`/segmentation/${id}/lead-list?page=1`);
        setSearch(searchFiltered);

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
  }, [history, id, searchFiltered, timer]);

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchFiltered(value);
    setTimer(2);
  }

  async function handlePageChange({ selected: selectedPage }) {
    history.push(`/segmentation/${id}/lead-list?page=${selectedPage + 1}`);
  }

  return (
    <>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        width="full"
        justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
        alignItems="baseline"
        paddingBottom="20px"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Box as={Link} to="/segmentation">
            <MdArrowBackIos size={15} />
          </Box>
          <Heading
            fontSize={{ base: '20px', md: '24px', lg: '24px' }}
            noOfLines={1}
            fontWeight={600}
          >
            Lista de leads - {response?.data?.segmentationName}
          </Heading>
        </Stack>

        <Stack
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          width={{ base: 'full', md: 'auto', lg: 'auto' }}
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Button
            colorScheme="orange"
            size="sm"
            variant="outline"
            width={{ base: 'full', md: 'auto', lg: 'auto' }}
            onClick={() =>
              exportLeads({
                segmentationId: id,
                segmentationName: response?.data?.segmentationName,
              })
            }
          >
            <Stack direction="row" alignItems="center">
              <HiDownload size={15} />
              <Text>Exportar segmentação</Text>
            </Stack>
          </Button>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <InputGroup size="sm" width={{ base: 'full', md: '268px', lg: '268px' }} marginY="30px">
          <InputLeftElement pointerEvents="none">
            <AiOutlineSearch size={20} color="#20212380" />
          </InputLeftElement>
          <Input
            placeholder="Buscar leads"
            value={searchFiltered}
            onChange={handleChangeSearch}
            borderRadius={5}
            focusBorderColor="orange.500"
            isDisabled={leadsBySegmentation.length === 0}
          />
        </InputGroup>

        <Text
          fontWeight={600}
          color="#20212380"
          fontSize={{ base: '12px', md: '18px', lg: '18px' }}
        >
          {response?.data?.total > 0 ? response?.data?.total : 0}{' '}
          <Text fontWeight={500} as="span">
            leads
          </Text>
        </Text>
      </Stack>

      <Box
        display="flex"
        flexDirection="row"
        width="full"
        justifyContent="center"
        hidden={!loading}
      >
        <Spinner size="lg" color="orange.500" />
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        width="full"
        justifyContent="center"
        hidden={loading || leadsBySegmentation.length > 0 || search !== ''}
      >
        <Heading color="#20212380" fontSize="lg" textAlign="center">
          Essa segmentação ainda não possui registros de leads.
        </Heading>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        width="full"
        justifyContent="center"
        hidden={loading || leadsBySegmentationFiltered.length > 0 || search === ''}
      >
        <Heading color="#20212380" fontSize="lg" textAlign="center">
          Nenhum resultado encontrado para "{search}"
        </Heading>
      </Box>

      <Box hidden={loading || leadsBySegmentationFiltered.length === 0}>
        <TableLeadList leadsBySegmentation={leadsBySegmentationFiltered} />
      </Box>

      <Box marginY={5} hidden={loading || leadsBySegmentationFiltered.length === 0}>
        <Pagination
          onPageActive={currentPage - 1}
          pageCount={pageCount}
          onPageChange={handlePageChange}
        />
      </Box>
    </>
  );
}
