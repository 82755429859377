import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  TabPanel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import Pagination from '../../../../../components/Pagination';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import { CardArea } from '../components/CardArea';
import { ModalAddOrEditArea } from '../components/ModalAddOrEditArea';
import { ModalDeleteArea } from '../components/ModalDeleteArea';
import { areaSchemaValidator } from '../schemaValidator';

export interface IQuestionLibraryAreaResult {
  data: {
    id: number;
    name: string;
  }[];
  total: number;
  page: number;
  per_page: number;
}

export interface IQuestionLibraryAreaData {
  id: number;
  name: string;
}

interface IProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  tabIndex: number;
}

export function TabPanelArea({ isOpen, onOpen, onClose, tabIndex }: IProps) {
  const {
    isOpen: isOpenModalDeleteArea,
    onOpen: onOpenModalDeleteArea,
    onClose: onCloseModalDeleteArea,
  } = useDisclosure();

  const [timer, setTimer] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [searchFiltered, setSearchFiltered] = useState<string>('');
  const [areaData, setAreaData] = useState<IQuestionLibraryAreaData[]>();
  const [areaDataFiltered, setAreaDataFiltered] = useState<IQuestionLibraryAreaData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState(0);
  const [areaId, setAreaId] = useState<number>(0);
  const [areaName, setAreaName] = useState<string>('');

  const {
    data: getAreaData,
    loading: isLoading,
    fetchData: fetchQuestionLibraryArea,
  } = useFetch<UnificadaFront.ResponseJSON<IQuestionLibraryAreaResult>>({
    method: 'get',
    url: `/filter/question-library-area?page=${currentPage}&perPage=${24}${
      search ? `&search=${search}` : ''
    }`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (tabIndex === 0) {
      fetchQuestionLibraryArea();
    }
  }, [fetchQuestionLibraryArea, tabIndex]);

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit: handleSubmitArea,
  } = useHandleSubmit({
    data: { name: areaName },
    url: '/filter/question-library-area',
    method: 'post',
    authenticated: true,
    schemaValidator: areaSchemaValidator,
    onSuccess: {
      message: 'Área criada com sucesso!',
      callback: () => {
        fetchQuestionLibraryArea();
        setAreaId(0);
        setAreaName('');
      },
    },
  });

  const {
    isLoading: isEditingArea,
    formValidation: formValidationEditArea,
    handleSubmit: handleSubmitEditArea,
  } = useHandleSubmit({
    data: { name: areaName },
    url: `/filter/${areaId}/question-library-area`,
    method: 'patch',
    schemaValidator: areaSchemaValidator,
    authenticated: true,
    onSuccess: {
      message: 'Área atualizada com sucesso!',
      callback: () => {
        fetchQuestionLibraryArea();
        setAreaId(0);
        setAreaName('');
      },
    },
  });

  const { isLoading: isDeleting, handleSubmit: handleSubmitDeleteArea } = useHandleSubmit({
    url: `/filter/${areaId}/question-library-area`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Área deletada com sucesso!',
      callback: () => {
        setAreaDataFiltered(areaDataFiltered.filter(area => area.id !== areaId));
        setAreaId(0);
        setAreaName('');
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    let result: any;

    if (areaId) {
      result = await handleSubmitEditArea();
    } else {
      result = await handleSubmitArea();
    }

    if (result) {
      onClose();
    }
  }

  useEffect(() => {
    if (getAreaData?.data) {
      setAreaData(getAreaData?.data.data);
      setAreaDataFiltered(getAreaData?.data.data);
      setPageCount(getAreaData?.data?.total / getAreaData?.data?.per_page);
    } else {
      setAreaDataFiltered([]);
    }
  }, [getAreaData?.data]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        setSearch(searchFiltered);

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchFiltered, setSearch, timer]);

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchFiltered(value);
    setTimer(2);
  }

  function handlePageChange({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }

  return (
    <TabPanel padding={0}>
      <Box>
        {!areaData?.length && (
          <Text fontWeight={400} fontSize="14px" color="#20212380" marginY={5}>
            Cadastre as áreas de acordo com sua área de atuação, isso facilitará a busca por seus
            produtos, na criação de questões no banco, entre outros benefícios.
          </Text>
        )}

        <InputGroup size="sm" marginY={5} hidden={!areaData?.length}>
          <InputLeftElement pointerEvents="none">
            <BiSearch color="gray" size={15} />
          </InputLeftElement>
          <Input
            width={{ base: 'full', md: '320px', lg: '320px' }}
            placeholder="Buscar por título"
            borderRadius={6}
            focusBorderColor="orange.500"
            color="#20212380"
            value={searchFiltered}
            onChange={handleChangeSearch}
          />
        </InputGroup>

        <Stack direction="row" width="full" justifyContent="center" margin={10} hidden={!isLoading}>
          <Spinner color="orange.500" size="lg" />
        </Stack>

        {!areaDataFiltered.length && (
          <Heading
            fontWeight={400}
            padding={10}
            fontSize="lg"
            color="#20212380"
            marginY={5}
            textAlign="center"
            hidden={!areaData?.length}
          >
            Nenhum resultado encontrado para "{search}"
          </Heading>
        )}

        <Box
          display="flex"
          width="full"
          flexDirection="row"
          flexWrap="wrap"
          flex={1}
          gap={5}
          hidden={isLoading || !areaDataFiltered.length}
        >
          {areaDataFiltered?.map(area => (
            <CardArea
              key={area.id}
              area={area}
              setAreaId={setAreaId}
              setAreaName={setAreaName}
              onOpenModalAddOrEditArea={onOpen}
              onOpenModalDeleteArea={onOpenModalDeleteArea}
            />
          ))}
        </Box>

        {areaDataFiltered.length !== 0 && getAreaData?.data?.total > 24 && (
          <Stack w="100%" justifyContent="center" alignItems="center" my={10}>
            <Pagination
              pageCount={pageCount}
              onPageChange={handlePageChange}
              onPageActive={currentPage - 1}
            />
          </Stack>
        )}
      </Box>

      <ModalAddOrEditArea
        id={areaId}
        setAreaId={setAreaId}
        isOpen={isOpen}
        onClose={onClose}
        areaName={areaName}
        setAreaName={setAreaName}
        isSubmitting={isSubmitting}
        isEditingArea={isEditingArea}
        onSubmit={onSubmit}
        formValidation={formValidation || formValidationEditArea}
      />

      <ModalDeleteArea
        isOpenModalDeleteArea={isOpenModalDeleteArea}
        onCloseModalDeleteArea={onCloseModalDeleteArea}
        handleSubmitDeleteArea={handleSubmitDeleteArea}
        setAreaId={setAreaId}
        areaName={areaName}
        setAreaName={setAreaName}
        isDeleting={isDeleting}
      />
    </TabPanel>
  );
}
