import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { IoMdAlert } from 'react-icons/io';

export default function ModalConfirmReset({ isOpen, onClose, handleDelete }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        width="full"
        textAlign="center"
        margin="15px"
        padding={{ base: '15px', lg: '30px' }}
      >
        <Box width="full" display="flex" justifyContent="center" color="primary.500">
          <IoMdAlert fontSize="48px" />
        </Box>

        <ModalHeader fontSize="18px">Deseja redefinir as configurações?</ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingY={0}>
          Caso clique no botão “Redefinir”, todas as configurações serão redefinidas para as
          configurações padrão.
        </ModalBody>

        <ModalFooter>
          <HStack width="full">
            <Button size="sm" width="full" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              size="sm"
              width="full"
              colorScheme="primary"
              color="secondary.500"
              onClick={handleDelete}
            >
              Redefinir
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
