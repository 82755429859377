import { PlanManagementEditPageThanksProps } from './types';
import usePlanManagementEditPageThanks from './usePlanManagementEditPageThanks';

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';

export default function PlanManagementEditPageThanks({
  planData,
}: PlanManagementEditPageThanksProps) {
  const {
    form,
    isSubmitting,
    formValidation,
    handleThankPageTypeChange,
    handleChange,
    handleCancelButtonClick,
    onSubmit,
  } = usePlanManagementEditPageThanks({
    planData,
  });

  return (
    <Box mt={10}>
      <Heading fontSize="xl" fontWeight="medium" color="#202123">
        Página de obrigado
      </Heading>

      <VStack
        as="form"
        align="start"
        mt="1.125rem"
        spacing={8}
        sx={{
          '.chakra-form__label': {
            fontSize: 'sm',
            color: '#202123',
            fontWeight: 'medium',
          },
          '.chakra-form__helper-text': {
            color: '#20212380',
            mb: 2,
          },
          '.chakra-input::placeholder': {
            color: '#0000005C',
          },
        }}
        noValidate
        onSubmit={onSubmit}
      >
        <FormControl isInvalid={formValidation?.thankPageType.isInvalid}>
          <FormLabel>Escolha uma opção</FormLabel>

          <Select
            value={form?.thankPageType}
            name="thankPageType"
            onChange={handleThankPageTypeChange}
            focusBorderColor="gray.200"
          >
            <option value="DEFAULT">Página de obrigado padrão</option>
            <option value="EXTERNAL">Página de obrigado externa</option>
          </Select>

          <FormErrorMessage>{formValidation?.thankPageType?.message}</FormErrorMessage>
        </FormControl>

        {form.thankPageType === 'EXTERNAL' && (
          <FormControl isInvalid={formValidation?.thankPageForApprovedPurchases?.isInvalid}>
            <FormLabel>Compras aprovadas</FormLabel>

            <FormHelperText>
              Esta página é exibida quando a compra é feita com cartão de crédito e a aprovação é
              confirmada.
            </FormHelperText>

            <Input
              name="thankPageForApprovedPurchases"
              value={form.thankPageForApprovedPurchases}
              onChange={handleChange}
              placeholder="URL"
              focusBorderColor="gray.200"
            />

            <FormErrorMessage>
              {formValidation?.thankPageForApprovedPurchases?.message}
            </FormErrorMessage>
          </FormControl>
        )}

        <HStack as="footer" w="full" justify="end" spacing={1}>
          <Button
            onClick={handleCancelButtonClick}
            variant="ghost"
            mr={3}
            background="gray.200"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isDisabled={isSubmitting}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            colorScheme="primary"
            color="secondary.500"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}
