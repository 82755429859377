import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { IStatistics } from '..';

interface IProps {
  statistics: IStatistics[];
  setQuestionsIdsReset: (ids: number[]) => void;
  onOpen: () => void;
}

export default function TableView({ statistics, setQuestionsIdsReset, onOpen }: IProps) {
  const textColor = useColorModeValue('#20212380', '#FFFFFF');
  const bgColor = useColorModeValue('rgba(32, 33, 35, 0.05)', '#37393D');

  return (
    <TableContainer>
      <Table variant="unstyled" style={{ borderCollapse: 'separate', borderSpacing: '0 6px' }}>
        <Thead bgColor="#EB7129" color="white" borderRadius={5} height="45px">
          <Tr>
            <Th fontSize="14px" textTransform="none" fontWeight={500} borderLeftRadius={5}>
              Nome
            </Th>

            <Th fontSize="14px" textTransform="none" fontWeight={500} width="39px">
              Certo
            </Th>
            <Th fontSize="14px" textTransform="none" fontWeight={500} width="47px">
              Errado
            </Th>
            <Th fontSize="14px" textTransform="none" fontWeight={500} width="35px">
              Total
            </Th>
            <Th fontSize="14px" textTransform="none" fontWeight={500} width="80px">
              Rendimento
            </Th>

            <Th
              fontSize="14px"
              textTransform="none"
              fontWeight={500}
              width="75px"
              borderRightRadius={5}
            >
              Ações
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {statistics?.map((statistic, index) => (
            <Tr
              key={index}
              bgColor={bgColor}
              color="rgba(32, 33, 35, 0.50)"
              fontSize="16px"
              fontWeight="500"
              height="45px"
            >
              <Td borderLeftRadius={5} paddingY={0}>
                <Text color={textColor}>{statistic.name}</Text>
              </Td>
              <Td paddingY={0}>
                <Text color={textColor}>{statistic.correct}</Text>
              </Td>
              <Td paddingY={0}>
                <Text color={textColor}>{statistic.wrong}</Text>
              </Td>
              <Td paddingY={0}>
                <Text color={textColor}>{statistic.total}</Text>
              </Td>
              <Td paddingY={0}>
                <Text color={textColor}>{statistic.performance}%</Text>
              </Td>
              <Td borderRightRadius={5} paddingY={0}>
                <Button
                  size="sm"
                  bgColor="transparent"
                  borderColor="#ec7117"
                  color="#ec7117"
                  variant="outline"
                  onClick={() => {
                    setQuestionsIdsReset(statistic.questionId);
                    onOpen();
                  }}
                >
                  Zerar
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
