import { useEffect, useMemo, useState } from 'react';
import useFetch from '../../../../../../../hooks/useFetch';

type Filter = {
  id: number;
  name: string;
};

interface Area extends Filter {}

interface Discipline {
  id: number;
  name: string;
  area: Area;
}

interface Subject {
  id: number;
  name: string;
  area: Area;
  discipline: Discipline;
}

interface FiltersList {
  areas: Area[];
  disciplines: Discipline[];
  subjects: Subject[];
}

interface FilterResponse<T> {
  data: T[];
  page: number;
  total: number;
}

export default function useFilters() {
  const [selectedArea, setSelectedArea] = useState<Area | null>(null);
  const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline | null>(null);
  const [selectedSubject, setSelectedSubject] = useState<Subject | null>(null);
  const [isFetching, setIsFetching] = useState(true);

  const { data: areaResponse, fetchData: fetchAreas } = useFetch<
    UnificadaFront.ResponseJSON<FilterResponse<Area>>
  >({
    method: 'get',
    url: '/filter/question-library-area?hasPaginate=false',
    authenticated: true,
    autoFetch: false,
  });

  const { data: disciplineResponse, fetchData: fetchDisciplines } = useFetch<
    UnificadaFront.ResponseJSON<FilterResponse<Discipline>>
  >({
    method: 'get',
    url: '/filter/question-library-discipline?hasPaginate=false',
    authenticated: true,
    autoFetch: false,
  });

  const { data: subjectResponse, fetchData: fetchSubjects } = useFetch<
    UnificadaFront.ResponseJSON<FilterResponse<Subject>>
  >({
    method: 'get',
    url: '/filter/question-library-subject?hasPaginate=false',
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    async function fetchFilters() {
      await Promise.all([fetchAreas(), fetchDisciplines(), fetchSubjects()]);
      setIsFetching(false);
    }

    fetchFilters();
  }, [fetchAreas, fetchDisciplines, fetchSubjects]);

  const filtersList: FiltersList = useMemo(() => {
    return {
      areas: areaResponse?.data?.data,
      disciplines: disciplineResponse?.data?.data,
      subjects: subjectResponse?.data?.data,
    };
  }, [areaResponse?.data?.data, disciplineResponse?.data?.data, subjectResponse?.data?.data]);

  const disciplinesOptions: Discipline[] = selectedArea
    ? filtersList.disciplines.filter(discipline => discipline.area.id === selectedArea.id)
    : filtersList.disciplines;

  const subjectsOptions: Subject[] = selectedDiscipline
    ? filtersList.subjects.filter(subject => subject.discipline.id === selectedDiscipline.id)
    : selectedArea
    ? filtersList.subjects.filter(subject => subject.area.id === selectedArea.id)
    : filtersList.subjects;

  function handleAreaChange(areaId: number) {
    const selectedArea = filtersList.areas.find(area => area.id === areaId);

    setSelectedArea(selectedArea || null);
    setSelectedDiscipline(null);
    setSelectedSubject(null);
  }

  function handleDisciplineChange(disciplineId: number) {
    const selectedDiscipline = filtersList.disciplines.find(
      discipline => discipline.id === disciplineId
    );

    setSelectedDiscipline(selectedDiscipline || null);
    setSelectedArea(selectedDiscipline?.area || null);
    setSelectedSubject(null);
  }

  function handleSubjectChange(subjectId: number) {
    const selectedSubject = filtersList.subjects.find(subject => subject.id === subjectId);

    setSelectedArea(selectedSubject?.area || null);
    setSelectedDiscipline(selectedSubject?.discipline || null);
    setSelectedSubject(selectedSubject || null);
  }

  return {
    filtersList,
    isFetching,
    disciplinesOptions,
    subjectsOptions,
    selectedArea,
    selectedDiscipline,
    selectedSubject,
    handleAreaChange,
    handleDisciplineChange,
    handleSubjectChange,
  };
}
