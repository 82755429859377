import { History } from 'history';
import { toQueryString } from '../../../../helpers/ToQueryString';
import stringifyProductsToThanksPage from './formatProductsToThanksPage';
import { Address } from '../types';

interface IParams {
  paymentMethod: string;
  standardPaymentValue: number;
  products: any[];
  memberArea: string;
  emailSupport: string;
  affiliation?: string;
  purchaseId: number;
  coupon?: string;
  fullName?: string;
  email?: string;
  address?: Address;
  documentNumber?: string;
  documentType?: string;
  utm?: {
    utm_id?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
  };
}

export default function redirectToThanksPage(params: IParams, history: History) {
  const {
    purchaseId,
    coupon,
    paymentMethod,
    standardPaymentValue,
    products,
    memberArea,
    emailSupport,
    affiliation,
    fullName,
    email,
    address,
    documentNumber,
    documentType,
    utm,
  } = params;

  const productsString = stringifyProductsToThanksPage(products);

  const queryString = toQueryString({
    paymentMethod,
    standardPaymentValue,
    products: productsString,
    memberArea,
    emailSupport,
    affiliation,
    purchaseId,
    coupon,
    fullName,
    email,
    documentNumber,
    documentType,
    streetAddress: address?.streetAddress,
    neighborhood: address?.neighborhood,
    city: address?.city,
    state: address?.state,
    streetNumber: address?.streetNumber,
    country: address?.country,
    zipCode: address?.zipCode,
    complementary: address?.complementary,
    alternateAddress: address?.alternateAddress,
    utm_id: utm.utm_id,
    utm_source: utm.utm_source,
    utm_medium: utm.utm_medium,
    utm_campaign: utm.utm_campaign,
    utm_term: utm.utm_term,
    utm_content: utm.utm_content,
  });

  history.push(`/student-checkout/thanks?${queryString}`);
}
