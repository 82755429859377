import { HStack, Text, Image, Box } from '@chakra-ui/react';

import mastercard from '../../../../../../assets/icons/mastercard-xs.svg';
import visa from '../../../../../../assets/icons/visa-xs.png';

import { Container, FrontCart, BackCart } from './styled';

type ExpirationType = {
  month: string;
  year: string;
};

interface ISimulateCardProps {
  isInputClick: boolean;
  cardNumber: string;
  cardHolderName: string;
  cardExpirationDate: ExpirationType;
  cardCVV: string;
  flag?: string;
}

function SimulateCard({
  isInputClick,
  cardNumber,
  cardHolderName,
  cardExpirationDate,
  cardCVV,
  flag,
}: ISimulateCardProps) {
  const { month, year } = cardExpirationDate;

  const formattedCardNumber = cardNumber.split(' ');

  const firstGroup = formattedCardNumber[0];
  const secondGroup = formattedCardNumber[1];
  const thirdGroup = formattedCardNumber[2];
  const fourthGroup = formattedCardNumber[3];

  return (
    <Container
      width="100%"
      height="11.5rem"
      fontSize="sm"
      lineHeight="shorter"
      fontWeight="medium"
      position="relative"
    >
      <FrontCart
        position="absolute"
        width="100%"
        height="100%"
        paddingTop="5"
        paddingX="3.5"
        paddingBottom="7"
        zIndex="1"
        className={`front-cart ${isInputClick && 'front-rotate-click'}`}
      >
        <HStack
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{
            img: {
              width: '2rem',
            },
          }}
        >
          <Text>
            Cartão de <br />
            crédito
          </Text>

          {flag === 'visa' && (
            <Box padding="0.5rem 0.25rem" background="white" borderRadius="sm">
              <Image src={visa} alt="bandeira cartão visa" />
            </Box>
          )}

          {flag === 'master' && <Image src={mastercard} alt="bandeira cartão mastercard" />}

          {flag === 'other' && ''}
        </HStack>

        <HStack marginTop="2rem !important" marginBottom="2.5rem !important" gap="2">
          <Text>{firstGroup ? firstGroup : '****'}</Text>
          <Text>{secondGroup ? secondGroup : '****'}</Text>
          <Text>{thirdGroup ? thirdGroup : '****'}</Text>
          <Text>{fourthGroup ? fourthGroup : '****'}</Text>
        </HStack>

        <HStack alignItems="center" justifyContent="space-between">
          <Text>{cardHolderName ? cardHolderName : '******* ******* ******'}</Text>

          <Text>
            {month ? month : '**'}/{year ? year : '**'}
          </Text>
        </HStack>
      </FrontCart>

      <BackCart
        position="absolute"
        width="100%"
        height="100%"
        paddingTop="5"
        paddingX="3.5"
        paddingBottom="7"
        className={`back-cart ${isInputClick && 'back-rotate-click'}`}
      >
        <Box
          width="100%"
          height="2.5rem"
          paddingRight="2"
          background="white"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Text color="gray.900" fontWeight="semibold">
            {cardCVV ? cardCVV : 'CVV'}
          </Text>
        </Box>
      </BackCart>
    </Container>
  );
}

export default SimulateCard;
