import { HStack, IconButton, Image, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import path from 'path';
import { MdDeleteForever } from 'react-icons/md';
import pdf from '../../../../../../assets/icons/pdf.svg';

function PreviewPDFTask({ filePDF, setFilePDF, values, setValues }) {
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false });

  function handleDiscardFilePDF() {
    setFilePDF(null);
    setValues({
      ...values,
      answerFile: null,
      answerFileName: null,
    });
  }

  const iconSize = isMobile ? '22px' : '28px';

  return (
    <HStack
      width="full"
      padding="22px"
      borderRadius="5px"
      background="#37393D"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      style={{ textDecoration: 'none' }}
      cursor="pointer"
      justifyContent="space-between"
    >
      <HStack width="full">
        <Image src={pdf} alt="arquivo" boxSize={{ base: '43px', lg: '70px' }} />

        <Stack justifyContent="flex-start" spacing="8px">
          <Text
            title={filePDF?.name || path.basename(decodeURI(values.answerFile))}
            width={{ base: '200px', md: '400px', lg: '500px' }}
            fontSize={{ base: '11.072px', lg: '18px' }}
            fontWeight="500"
            color="#F3F3F3"
            noOfLines={2}
          >
            {filePDF?.name || path.basename(decodeURI(values.answerFile))}
          </Text>

          <Text color="#979CA6" fontSize={{ base: '8.612px', lg: '14px' }} fontWeight="500">
            {(filePDF?.size / (1024 * 1024)).toFixed(2)} MB
          </Text>
        </Stack>
      </HStack>

      <IconButton
        bgColor="transparent"
        _hover={{ bgColor: 'transparent', color: 'red.500', transition: '0.15s ease-in-out' }}
        onClick={handleDiscardFilePDF}
        fontSize={iconSize}
        aria-label="Remover anexo"
        color="#FFF"
        size="sm"
        icon={<MdDeleteForever />}
      />
    </HStack>
  );
}

export default PreviewPDFTask;
