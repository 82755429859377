import Joi from 'joi';

export type OrderBumpSchemaType = {
  name: string;
  description: string;
  enabled: boolean;
  coursePriceId: number;
  courseId: number;
  productIds: number[];
  buttonText: string;
  title: string;
  buttonColor: string;
  buttonTextColor: string;
};

const orderBumpSchema = {
  name: Joi.string().messages({
    'any.required': 'O nome é obrigatório.',
    'string.empty': 'O nome é obrigatório.',
  }),
  courseId: Joi.number().positive().messages({
    'any.required': 'O curso é obrigatório.',
    'number.positive': 'O curso é obrigatório.',
  }),
  productIds: Joi.array().items(Joi.number().positive()).min(1).messages({
    'any.required': 'Selecione pelo menos um produto para que seu order bump seja exibido.',
    'number.positive': 'Selecione pelo menos um produto para que seu order bump seja exibido.',
    'array.min': 'Selecione pelo menos um produto para que seu order bump seja exibido.',
    'array.empty': 'Selecione pelo menos um produto para que seu order bump seja exibido.',
    'array.base': 'Selecione pelo menos um produto para que seu order bump seja exibido.',
  }),
  coursePriceId: Joi.number().positive().messages({
    'any.required': 'O preço do curso é obrigatório.',
    'number.positive': 'O preço do curso é obrigatório.',
    'number.empty': 'O preço do curso é obrigatório.',
    'number.base': 'O preço do curso é obrigatório.',
  }),
  buttonColor: Joi.string().allow('', null),
  buttonTextColor: Joi.string().allow('', null),
  buttonText: Joi.string().max(30).messages({
    'any.required': 'O texto do botão é obrigatório.',
    'string.max': 'O texto do botão deve conter no máximo 30 caracteres.',
  }),
  description: Joi.string().max(150).messages({
    'any.required': 'A descrição é obrigatória.',
    'string.max': 'A descrição deve conter no máximo 150 caracteres.',
  }),
  enabled: Joi.boolean().default(false),
  title: Joi.string().max(50).messages({
    'any.required': 'O título é obrigatório.',
    'string.max': 'O título deve conter no máximo 50 caracteres.',
  }),
};

export const createOrderBumpSchemaValidator = Joi.object<OrderBumpSchemaType>().keys({
  name: orderBumpSchema.name.required(),
  courseId: orderBumpSchema.courseId.required(),
  coursePriceId: orderBumpSchema.coursePriceId.required(),
  productIds: orderBumpSchema.productIds.required(),
  buttonColor: orderBumpSchema.buttonColor,
  buttonTextColor: orderBumpSchema.buttonTextColor,
  buttonText: orderBumpSchema.buttonText.required(),
  description: orderBumpSchema.description.required(),
  enabled: orderBumpSchema.enabled,
  title: orderBumpSchema.title.required(),
});

export const updateOrderBumpSchemaValidator = Joi.object<OrderBumpSchemaType>().keys({
  name: orderBumpSchema.name,
  courseId: orderBumpSchema.courseId,
  coursePriceId: orderBumpSchema.coursePriceId,
  productIds: orderBumpSchema.productIds,
  buttonColor: orderBumpSchema.buttonColor,
  buttonTextColor: orderBumpSchema.buttonTextColor,
  buttonText: orderBumpSchema.buttonText,
  description: orderBumpSchema.description,
  enabled: orderBumpSchema.enabled,
  title: orderBumpSchema.title,
});
