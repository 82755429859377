import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { IModulePageResponse } from '..';
import { axiosBackend } from '../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import Module from './Module';

import { HStack, Heading, Select, Stack, Switch, TabPanel, Text, useToast } from '@chakra-ui/react';

interface IProps {
  showcaseId: string;
  showcaseCourseId: string;
  showcaseModules: IModulePageResponse;
  setShowcaseModules: (modules: IModulePageResponse) => void;
  productId: number;
  setIsViewModulesButtonVisible: Dispatch<SetStateAction<boolean>>;
}

export default function ModulePage({
  showcaseId,
  showcaseCourseId,
  showcaseModules,
  productId,
  setShowcaseModules,
  setIsViewModulesButtonVisible,
}: IProps) {
  const toast = useToast();

  async function onChangeSwitch(event: ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked;

    setIsViewModulesButtonVisible(checked);

    const payload = {
      hasActiveModulesPage: checked,
      typeViewModules: showcaseModules.typeViewModules,
    };

    try {
      await axiosBackend().patch(
        `/showcase-course/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/modules`,
        payload
      );

      setShowcaseModules({ ...showcaseModules, hasActiveModulesPage: checked });

      toast({
        title: `Página de módulos ${checked ? 'ativada' : 'desativada'} com sucesso!`,
        status: 'success',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    }
  }

  async function onChangeSelect(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    const payload = {
      hasActiveModulesPage: showcaseModules.hasActiveModulesPage,
      typeViewModules: value,
    };

    try {
      await axiosBackend().patch(
        `/showcase-course/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/modules`,
        payload
      );

      setShowcaseModules({ ...showcaseModules, typeViewModules: value });

      toast({
        title: 'Tipo de visualização atualizado com sucesso!',
        status: 'success',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    }
  }

  return (
    <TabPanel>
      <Stack spacing="30px">
        <Stack spacing="8px">
          <Heading fontSize="14px" fontWeight="500" lineHeight="17.07px">
            Página de módulos
          </Heading>

          <Stack spacing="8px">
            <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#00000080">
              Caso essa opção seja desativada o seu aluno não irá passar pela página de módulos,
              quando acessar o curso, e irá diretamente para a página de visualização de conteúdo.
            </Text>

            <HStack spacing="6px">
              <Switch
                size="sm"
                colorScheme="primary"
                isChecked={showcaseModules?.hasActiveModulesPage}
                onChange={onChangeSwitch}
              />
              <Text>Página de módulos</Text>
            </HStack>
          </Stack>
        </Stack>

        <Stack spacing="8px" hidden={!showcaseModules?.hasActiveModulesPage}>
          <Heading fontSize="14px" fontWeight="500" lineHeight="17.07px">
            Exibição dos módulos
          </Heading>

          <Stack spacing="8px">
            <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#00000080">
              Selecione o estilo de exibição dos seus módulos na página de módulos desse curso.
            </Text>

            <Select
              value={showcaseModules?.typeViewModules}
              focusBorderColor="primary.500"
              color="#21202380"
              onChange={onChangeSelect}
            >
              <option value="carrossel">Carrossel</option>
              <option value="grid">Grade</option>
            </Select>
          </Stack>
        </Stack>

        <Stack spacing="8px" hidden={!showcaseModules?.hasActiveModulesPage}>
          <Heading fontSize="14px" fontWeight="500" lineHeight="17.07px">
            Módulos
          </Heading>

          <Module
            modules={showcaseModules?.modules}
            showcaseId={showcaseId}
            showcaseCourseId={showcaseCourseId}
            showcaseModules={showcaseModules}
            setShowcaseModules={setShowcaseModules}
            productId={productId}
          />
        </Stack>
      </Stack>
    </TabPanel>
  );
}
