import process from 'process/browser';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button } from '@chakra-ui/react';
import { useEffect } from 'react';
import FacebookIntegration from '../../api/FacebookIntegration';
import Container from '../../components/ContainerSite/ContainerSite';
import { useAuth } from '../../contexts/AuthContext';
import facebookPixelHelper from '../../helpers/FacebookPixel';
import { IS_COOKIES_ACCEPTED, UTM_LOCAL_STORAGE } from '../../helpers/LocalStorageHelper';
import {
  googleTagManagerIds,
  purchaseEventGTM,
  removePurchaseEventGTM,
} from '../../helpers/ga-tag-custom';
import styles from './styles.module.css';

const IS_DEVELOPMENT_ENV = process.env.NODE_ENV === 'development';

function Thanks() {
  const { search } = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(search);
  const standardPaymentValue = query.get('standardPaymentValue');
  const paymentMethod = query.get('paymentMethod');
  const memberArea = query.get('memberArea');
  const emailSupport = query.get('emailSupport');
  const products = query.get('products');
  const affiliation = query.get('affiliation');
  const coupon = query.get('coupon');
  const purchaseId = query.get('purchaseId');
  const fullName = query.get('fullName');
  const email = query.get('email');
  const documentNumber = query.get('documentNumber');
  const documentType = query.get('documentType');
  const streetAddress = query.get('streetAddress');
  const neighborhood = query.get('neighborhood');
  const city = query.get('city');
  const state = query.get('state');
  const complementary = query.get('complementary');
  const country = query.get('country');
  const zipCode = query.get('zipCode');
  const streetNumber = query.get('streetNumber');
  const alternateAddress = query.get('alternateAddress');

  const { isAuthenticated } = useAuth();

  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const head = document.getElementsByTagName('head').item(0);

    async function insertPixels() {
      let purchasedProducts = [];

      if (products) {
        purchasedProducts = JSON.parse(products);
      }

      const { data: pixelData } = await FacebookIntegration.findMany(
        purchasedProducts.map(product => product.productId),
        affiliation
      );

      if (pixelData.length && purchasedProducts.length) {
        let currentPixel;
        let script;

        const purchasedProductPixels = pixelData.filter(pixel =>
          purchasedProducts.find(product => product.productId === pixel.courseId)
        );

        for (let pixel of purchasedProductPixels) {
          currentPixel = document.createElement('script');
          currentPixel.id = pixel.identifierPixel;
          currentPixel.setAttribute('prolunofbp', pixel.courseId);

          const product = purchasedProducts.find(product => product.productId === pixel.courseId);

          script = facebookPixelHelper.baseCode(
            currentPixel.id,
            facebookPixelHelper.purchaseEvent([pixel.courseId], product.productOriginalPrice)
          );

          const pixelExists = head.querySelectorAll(
            `[id="${pixel.identifier}"][prolunofbp="${pixel.courseId}"]`
          );

          if (!pixelExists.length) {
            currentPixel.innerHTML = script;

            head?.appendChild(currentPixel);
          }
        }
      }
    }

    if (!IS_DEVELOPMENT_ENV && isAuthenticated) {
      insertPixels();
    }

    return () => {
      const pixels = document.querySelectorAll('[prolunofbp]');

      if (pixels.length) {
        for (let pixel of Array.from(pixels)) {
          head?.removeChild(pixel);
        }
      }
    };
  }, [affiliation, isAuthenticated, isCookiesAccepted, products]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('popstate', () => history.go(1));
  }, [history]);

  if (!paymentMethod) {
    history.push('/');
  }

  function getTextsByPaymentMethod(paymentMethod) {
    switch (paymentMethod) {
      case 'boleto':
        return { link: '/members-area/v1/payments', button: 'Visualizar link do boleto' };
      default:
        return { link: '/members-area/v1/products', button: 'Visualizar meus cursos' };
    }
  }

  useEffect(() => {
    const removeUTMValues = () => {
      const utmInitialParams = {
        utm_id: null,
        utm_source: null,
        utm_medium: null,
        utm_campaign: null,
        utm_term: null,
        utm_content: null,
      };

      localStorage.setItem(UTM_LOCAL_STORAGE, JSON.stringify(utmInitialParams));
    };

    const handleRouteChange = () => {
      removeUTMValues();
    };

    const removeRouteChangeListener = history.listen(handleRouteChange);

    return () => {
      removeRouteChangeListener();
    };
  }, [history]);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const hostname = window.location.hostname;

    if (hostname)
      // === CURSOS_LIVIABRASIL.DOMINIO
      return;

    const idsGTM = googleTagManagerIds();

    let purchasedProducts = [];

    if (products) {
      purchasedProducts = JSON.parse(products);
    }

    for (const id of idsGTM) {
      purchaseEventGTM(id, {
        affiliation,
        coupon,
        currency: 'BRL',
        items: purchasedProducts.map(product => ({
          item_id: product.productId,
          item_name: product.productName,
          price: product.productOriginalPrice,
          quantity: 1,
        })),
        user: {
          fullName,
          email,
          documentNumber,
          documentType,
          streetAddress,
          neighborhood,
          city,
          state,
          complementary,
          country,
          zipCode,
          streetNumber,
          alternateAddress,
        },
        transaction_id: purchaseId,
        value: standardPaymentValue,
      });

      removePurchaseEventGTM(id);
    }
  }, [
    affiliation,
    alternateAddress,
    city,
    complementary,
    country,
    coupon,
    documentNumber,
    documentType,
    email,
    fullName,
    isCookiesAccepted,
    neighborhood,
    products,
    purchaseId,
    standardPaymentValue,
    state,
    streetAddress,
    streetNumber,
    zipCode,
  ]);

  return (
    <Container>
      <div className={styles.thanksContainer}>
        <h1>
          <span>Parabéns</span>
          <br /> por sua compra!
        </h1>
        {memberArea === 'cademi' && paymentMethod !== 'boleto' ? (
          <>
            <div>
              Já pode aproveitar todas as vantagens dos seu(s) produto(s). Você receberá um e-mail
              com instruções de acesso.
            </div>
            <Link to="/all-products">
              <button>Continuar comprando</button>
            </Link>
            <div className={styles.support}>
              <p>Tem alguma dúvida? Fale com a gente:</p>
              <p>{emailSupport}</p>
            </div>
          </>
        ) : (
          <>
            <div>
              Você já pode aproveitar todas as vantagens dos seus produtos. Clique no botão abaixo
              para iniciar a sua jornada de aprendizado.
            </div>
            <Button
              colorScheme="primary"
              onClick={() => (window.location.href = getTextsByPaymentMethod(paymentMethod).link)}
            >
              {getTextsByPaymentMethod(paymentMethod).button}
            </Button>
          </>
        )}
      </div>
    </Container>
  );
}

export default Thanks;
