import {
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import Paginate from '../../../../components/Paginate';
import Toast from '../../../../components/Toast';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import useWindowSize from '../../../../hooks/useWindowSize';
import { addAffiliateInProductSchemaValidator } from './addAffiliateInProductSchemaValidator';
import List from './components/List';
import { ModalAddAffiliate } from './components/ModalAddAffiliate';
import { ModalRemoveCourseAffiliate } from './components/ModalRemoveCourseAffiliate';
import { ModalWarning } from './components/ModalWarning';
import Table from './components/Table';

export type CourseAffiliateType = {
  amountOfAffiliates: number;
  courseAffiliationId: number;
  productId: number;
  amountOfSales: number;
  commissionValue: string;
  affiliationId: number;
  productName: string;
  productValue: string;
  thumbnail: string;
  winnings: number;
  invoicing: number;
};

export type AddAffiliateType = {
  courseAffiliationId: number;
  userId: number;
};

export type ContentViewAffiliateType = {
  courseAffiliates: CourseAffiliateType[];
  currentPage: number;
  pageCount: number;
  isLoading: boolean;
  handlePageChange: (selected: any) => void;
  setCourseAffiliates: <T>(courseAffiliates: T[]) => void;
  listProductsAffiliations: () => any;
};

interface IContentProps {
  isLoading: boolean;
  courseAffiliates: CourseAffiliateType[];
  onOpenAddAffiliate: () => void;
  onOpenRemoveProduct: () => void;
  setCourseAffiliationId: (id: number) => void;
  setProductName: (name: string) => void;
  setProductId: (id: number) => void;
}

const LARGE_SCREEN_SIZE = 992;

function Content({
  isLoading,
  courseAffiliates,
  onOpenAddAffiliate,
  onOpenRemoveProduct,
  setCourseAffiliationId,
  setProductName,
  setProductId,
}: IContentProps) {
  const { width } = useWindowSize();

  if (isLoading) {
    return (
      <Stack w="100%" justifyContent="center" alignItems="center" my={2}>
        <Spinner size="lg" color="#EB7129" />
      </Stack>
    );
  }

  if (courseAffiliates?.length === 0) {
    return (
      <Stack w="100%" justifyContent="center" alignItems="center" my={2}>
        <Heading fontSize="md" fontWeight={400}>
          Você não possui produtos para afiliação.
        </Heading>
      </Stack>
    );
  }

  return (
    <>
      {width <= LARGE_SCREEN_SIZE && (
        <List
          courseAffiliates={courseAffiliates}
          onOpenAddAffiliate={onOpenAddAffiliate}
          onOpenRemoveProduct={onOpenRemoveProduct}
          setCourseAffiliationId={setCourseAffiliationId}
          setProductName={setProductName}
          setProductId={setProductId}
        />
      )}

      {width > LARGE_SCREEN_SIZE && (
        <Table
          courseAffiliates={courseAffiliates}
          onOpenAddAffiliate={onOpenAddAffiliate}
          onOpenRemoveProduct={onOpenRemoveProduct}
          setCourseAffiliationId={setCourseAffiliationId}
          setProductName={setProductName}
          setProductId={setProductId}
        />
      )}
    </>
  );
}

export function ActionButton({
  courseAffiliateId,
  productId,
  productName,
  onOpenAddAffiliate,
  onOpenRemoveProduct,
  setCourseAffiliationId,
  setProductName,
  setProductId,
}) {
  return (
    <Menu size="sm" arrowPadding={0}>
      <MenuButton
        w="27px"
        h="24px"
        borderRadius={5}
        bgColor="#20212310"
        _hover={{ bgColor: '#20212350' }}
        _active={{ bgColor: '#20212350' }}
        style={{ boxShadow: 'none', outline: 'none' }}
      >
        <Stack justifyContent="center" alignItems="center">
          <HiDotsHorizontal size="18px" color="#20212350" />
        </Stack>
      </MenuButton>
      <MenuList>
        <MenuItem
          as={Link}
          to={`/affiliations/products/${courseAffiliateId}/edit`}
          fontWeight={500}
          color="#20212380"
          style={{ boxShadow: 'none', outline: 'none' }}
        >
          Editar
        </MenuItem>
        <MenuItem
          fontWeight={500}
          color="#20212380"
          style={{ boxShadow: 'none', outline: 'none' }}
          onClick={() => {
            onOpenAddAffiliate();
            setProductId(productId);
            setCourseAffiliationId(courseAffiliateId);
          }}
        >
          Adicionar afiliado
        </MenuItem>
        <MenuItem
          fontWeight={500}
          color="#20212380"
          style={{ boxShadow: 'none', outline: 'none' }}
          as={Link}
          to={`/affiliations/affiliates?courseId=${productId}`}
        >
          Visualizar Afiliados
        </MenuItem>
        <MenuItem
          fontWeight={500}
          color="#BB2124"
          style={{ boxShadow: 'none', outline: 'none' }}
          onClick={() => {
            setCourseAffiliationId(courseAffiliateId);
            setProductName(productName);
            onOpenRemoveProduct();
          }}
        >
          Remover produto das afiliações
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function ContentViewAffiliate({
  courseAffiliates,
  currentPage,
  pageCount,
  isLoading,
  handlePageChange,
  setCourseAffiliates,
  listProductsAffiliations,
}: ContentViewAffiliateType) {
  const {
    isOpen: isOpenAddAffiliate,
    onOpen: onOpenAddAffiliate,
    onClose: onCloseAddAffiliate,
  } = useDisclosure();

  const {
    isOpen: isOpenRemoveProduct,
    onOpen: onOpenRemoveProduct,
    onClose: onCloseRemoveProduct,
  } = useDisclosure();

  const {
    isOpen: isOpenWarningModal,
    onOpen: onOpenWarningModal,
    onClose: onCloseWarningModal,
  } = useDisclosure();

  const { width } = useWindowSize();

  const sizeAddAffiliateModal =
    width > 1400 ? '2xl' : width > 800 ? 'xl' : width > 500 ? 'md' : 'xs';
  const sizeRemoveAffiliateProductModal =
    width > 1400 ? 'xl' : width > 800 ? 'lg' : width > 500 ? 'md' : 'xs';

  const [courseAffiliationId, setCourseAffiliationId] = useState(null);
  const [productName, setProductName] = useState('');
  const [userId, setUserId] = useState('');
  const [productId, setProductId] = useState(null);

  const payload: AddAffiliateType = {
    courseAffiliationId: parseInt(courseAffiliationId),
    userId: parseInt(userId),
  };

  const {
    isLoading: isSubmitting,
    error,
    setError,
    handleSubmit,
  } = useHandleSubmit({
    data: payload,
    url: '/affiliations/create-affiliate',
    method: 'post',
    authenticated: true,
    getError: true,
    schemaValidator: addAffiliateInProductSchemaValidator,
    onSuccess: {
      message: 'Afiliado adicionado com sucesso!',
      callback: listProductsAffiliations,
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    const result = await handleSubmit();

    if (result) {
      onCloseAddAffiliate();
      setUserId('');
      setProductId(null);
    }
  }

  useEffect(() => {
    if (error && error === 'O usuário informado não possui conta bancária cadastrada.') {
      onCloseAddAffiliate();
      setUserId('');
      onOpenWarningModal();
    }
    if (error && error !== 'O usuário informado não possui conta bancária cadastrada.') {
      onCloseAddAffiliate();
      setUserId('');
      Toast(error, 'error');
    }
  }, [error, onCloseAddAffiliate, onOpenWarningModal]);

  function updateAfterRemoveAffiliateProduct() {
    const newArrayAffiliateProduct = courseAffiliates.filter(
      affiliateProduct => affiliateProduct.courseAffiliationId !== parseInt(courseAffiliationId)
    );

    setCourseAffiliates([...newArrayAffiliateProduct]);
  }

  function handleSelectUser(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setUserId(value);
  }

  return (
    <>
      <Content
        isLoading={isLoading}
        courseAffiliates={courseAffiliates}
        onOpenAddAffiliate={onOpenAddAffiliate}
        onOpenRemoveProduct={onOpenRemoveProduct}
        setCourseAffiliationId={setCourseAffiliationId}
        setProductName={setProductName}
        setProductId={setProductId}
      />

      {courseAffiliates?.length !== 0 && (
        <Stack w="100%" justifyContent="center" alignItems="center" my={2}>
          <Paginate
            pageCount={pageCount}
            onPageChange={handlePageChange}
            initialPage={currentPage}
          />
        </Stack>
      )}

      <ModalWarning
        isOpenWarningModal={isOpenWarningModal}
        onCloseWarningModal={onCloseWarningModal}
        setError={setError}
      />

      <ModalAddAffiliate
        isOpenAddAffiliate={isOpenAddAffiliate}
        onCloseAddAffiliate={onCloseAddAffiliate}
        handleSelectUser={handleSelectUser}
        productId={productId}
        isSubmitting={isSubmitting}
        sizeAddAffiliateModal={sizeAddAffiliateModal}
        userId={userId}
        onSubmit={onSubmit}
      />

      <ModalRemoveCourseAffiliate
        isOpenRemoveProduct={isOpenRemoveProduct}
        onCloseRemoveProduct={onCloseRemoveProduct}
        sizeRemoveAffiliateProductModal={sizeRemoveAffiliateProductModal}
        updateAfterRemoveAffiliateProduct={updateAfterRemoveAffiliateProduct}
        courseAffiliationId={courseAffiliationId}
        productName={productName}
      />
    </>
  );
}

export default ContentViewAffiliate;
