import { Stack, Text } from '@chakra-ui/react';
import Question from '../../../types/Question';

interface FiltersDisplayProps {
  question: Question;
}

export default function FiltersDisplay({ question }: FiltersDisplayProps) {
  let filters = [
    question.questionLibraryArea?.name,
    question.questionLibraryDiscipline?.name,
    question.questionLibrarySubject?.name,
    question.questionLibraryInstitution?.name,
  ];

  filters = filters.filter(filter => filter);

  return (
    <Stack direction="row" flexWrap="wrap" spacing={0} divider={<Text color="#AFAFAF">/</Text>}>
      {filters.map(filter => (
        <Text
          key={filter}
          color="#AFAFAF"
          fontSize="xs"
          mx={{ base: 1, md: 3, lg: 3 }}
          isTruncated
          maxW={120}
        >
          {filter}
        </Text>
      ))}
    </Stack>
  );
}
