import { Button, Flex, Heading, IconButton, Link as ChakraLink } from '@chakra-ui/react';
import { MdChevronLeft as ChevronLeftIcon, MdOutlineRemoveRedEye as EyeIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';

interface PlanManagementEditHeaderProps {
  planName: string;
  planId?: number;
}

const PLAN_MANAGEMENT_PATH = '/recurrence/plan-management';

export default function PlanManagementEditHeader({
  planName,
  planId,
}: PlanManagementEditHeaderProps) {
  const checkoutLink = `/recurrence-checkout/${planId}/personal-data`;

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      bg="#202123"
      pl={1}
      pr="1.125rem"
      py={3}
      position="relative"
    >
      <Button
        display={{ base: 'none', xl: 'flex' }}
        as={Link}
        to={PLAN_MANAGEMENT_PATH}
        leftIcon={<ChevronLeftIcon size="1.5rem" />}
        color="white"
        colorScheme="black"
        title="Voltar para a listagem de planos"
        fontSize="sm"
      >
        Retornar
      </Button>

      <IconButton
        display={{ base: 'flex', xl: 'none' }}
        as={Link}
        to={PLAN_MANAGEMENT_PATH}
        variant="ghost"
        color="white"
        icon={<ChevronLeftIcon size="1.5rem" />}
        aria-label={''}
        justifyContent="center"
        alignItems="center"
      />

      <Heading
        as="h1"
        fontSize={{ base: 'md', xl: 'xl' }}
        fontWeight="bold"
        color="white"
        marginLeft="auto"
        marginRight="auto"
        marginBottom={0}
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
      >
        {planName}
      </Heading>

      {planId && (
        <>
          <IconButton
            display={{ base: 'flex', xl: 'none' }}
            as={ChakraLink}
            href={checkoutLink}
            variant="outline"
            color="white"
            icon={<EyeIcon />}
            aria-label={''}
            isExternal
          />
          <Button
            display={{ base: 'none', xl: 'flex' }}
            as={ChakraLink}
            href={checkoutLink}
            leftIcon={<EyeIcon size="0.88rem" />}
            color="white"
            colorScheme="black"
            title="Voltar para a página inicial"
            fontSize="sm"
            variant="outline"
            isExternal
          >
            Visualizar
          </Button>
        </>
      )}
    </Flex>
  );
}
