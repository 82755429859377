import { Button, ButtonGroup, ButtonProps } from '@chakra-ui/react';
import { MouseEvent } from 'react';

interface FilterButtonsProps {
  value: number;
  onChange: (id: number) => void;
}

function getButtonProps(id: number, filter: number): ButtonProps {
  const isSelected = id === filter;

  return {
    variant: isSelected ? 'solid' : 'ghost',
    pointerEvents: isSelected ? 'none' : 'auto',
    colorScheme: isSelected ? 'primary' : 'gray',
    color: isSelected ? 'secondary.500' : 'gray.500',
  };
}

export default function FilterButtons({ value, onChange }: FilterButtonsProps) {
  function handleFilterChange(event: MouseEvent) {
    const { value } = event.currentTarget as HTMLButtonElement;

    onChange(Number(value));
  }

  return (
    <ButtonGroup
      mt={1}
      w={{ base: 'full', md: 'auto' }}
      justifyContent="space-between"
      spacing={0.5}
      sx={{
        button: {
          borderRadius: 'md',
          variant: 'ghost',
          outline: 'none',
          fontFamily: 'Montserrat',
          _focus: { boxShadow: 'none', outline: 'none' },
        },
      }}
    >
      <Button value="0" onClick={handleFilterChange} {...getButtonProps(0, value)} size="xs">
        Hoje
      </Button>
      <Button value="7" onClick={handleFilterChange} {...getButtonProps(7, value)} size="xs">
        7 dias
      </Button>
      <Button value="15" onClick={handleFilterChange} {...getButtonProps(15, value)} size="xs">
        15 dias
      </Button>
      <Button value="30" onClick={handleFilterChange} {...getButtonProps(30, value)} size="xs">
        30 dias
      </Button>
    </ButtonGroup>
  );
}
