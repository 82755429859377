import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { MdChevronLeft, MdOutlineFindInPage } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { useCourse } from '../../../../../contexts/CourseContext';
import { PRODUCTS_FILTER } from '../../../../../helpers/LocalStorageHelper';
import useFetch from '../../../../../hooks/useFetch';
import { useEffect, useState } from 'react';

interface CourseList {
  id: number;
  name: string;
}

const scrollbarStyle = {
  '::-webkit-scrollbar': { width: '0.5rem', background: 'transparent' },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: 'md',
    backgroundColor: 'gray.300',
  },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'gray.400',
  },
};

export default function HeaderCourseManager() {
  const { course } = useCourse();
  const { id } = useParams<{ id: string }>();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [courses, setCourses] = useState<CourseList[]>([]);

  const previousProductsFilters = localStorage.getItem(PRODUCTS_FILTER);

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<CourseList[]>>({
    method: 'get',
    url: `/courses/get-all-courses`,
    authenticated: true,
    autoFetch: true,
  });

  const allCourses = response?.data;

  useEffect(() => {
    if (allCourses) {
      setCourses(allCourses.slice(0, 10));
    }
  }, [allCourses]);

  function handleSearch(value: string) {
    if (!value.trim()) {
      setCourses(allCourses?.slice(0, 10) || []);
      return;
    }

    const filteredCourses = allCourses?.filter(course =>
      course.name.toLowerCase().includes(value.toLowerCase())
    );

    setCourses(filteredCourses || []);
  }

  function handleScroll(event: React.UIEvent<HTMLDivElement>) {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollTop + clientHeight >= scrollHeight) {
      if (allCourses && courses.length < allCourses.length) {
        const newCourses = allCourses.slice(courses.length, courses.length + 10);
        setCourses(prevCourses => [...prevCourses, ...newCourses]);
      }
    }
  }

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      bg="primary.500"
      py={3}
      position="relative"
    >
      <Button
        as={Link}
        to={`/courses?${previousProductsFilters}`}
        leftIcon={<MdChevronLeft size="1.5rem" />}
        color="secondary.500"
        colorScheme="primary"
        _hover={{ bgColor: 'transparent' }}
        bgColor="transparent"
        title="Voltar para a página de produtos"
        fontSize="sm"
      >
        {isMobile ? '' : 'Voltar'}
      </Button>

      <Menu>
        <MenuButton as={Button} variant="unstyled" color="secondary.500">
          <HStack>
            <Text isTruncated>{course?.name}</Text>

            <ChevronDownIcon />
          </HStack>
        </MenuButton>
        <MenuList>
          {loading ? (
            <MenuItem>Carregando...</MenuItem>
          ) : (
            <>
              <Box width="95%" m={2}>
                <Input
                  focusBorderColor="primary.500"
                  size="sm"
                  placeholder="Buscar curso"
                  onChange={e => handleSearch(e.target.value)}
                />
              </Box>

              <Box
                maxH="200px"
                overflowY="auto"
                overflowX="hidden"
                onScroll={handleScroll}
                sx={scrollbarStyle}
              >
                {courses.map(course => (
                  <MenuItem
                    as={Link}
                    key={course.id}
                    to={`/courses/${course.id}/course-manager/course`}
                  >
                    {course.name}
                  </MenuItem>
                ))}
              </Box>
            </>
          )}
        </MenuList>
      </Menu>

      <Button
        as={Link}
        to={`/product/${id}`}
        rightIcon={<MdOutlineFindInPage size="1.5rem" />}
        color="secondary.500"
        colorScheme="primary"
        _hover={{ bgColor: 'transparent' }}
        bgColor="transparent"
        title="Visualizar Página de Vendas"
        fontSize="sm"
      >
        {isMobile ? '' : 'Visualizar Página de Vendas'}
      </Button>
    </Flex>
  );
}
