import { Box, Heading, Spinner, Stack } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IS_REACT_APP_HOSTNAME } from '../../App';
import squad1 from '../../assets/images/squads/squad1.svg';
import squad2 from '../../assets/images/squads/squad2.svg';
import squad3 from '../../assets/images/squads/squad3.svg';
import squad4 from '../../assets/images/squads/squad4.svg';
import squad5 from '../../assets/images/squads/squad5.svg';
import Container from '../../components/ContainerSite/ContainerSite';
import Paginate from '../../components/Paginate';
import useFetch from '../../hooks/useFetch';
import Hero from './Hero';
import Card from './components/Card';
import Section from './components/Section';
import styles from './styles.module.css';

export interface IPost {
  id: number;
  articleName: string;
  subtitle: string;
  userName: string;
  userPhoto: string;
  status: string;
  category: string;
  content: string;
  banner: string;
  publicationDate: Date;
  createdAt: Date;
}

export interface IBlogPost {
  page: 1;
  per_page: 10;
  total: 17;
  posts: IPost[];
}

interface IShowBlog {
  showTitle: boolean;
  hideDate: boolean;
  banner: string;
}

function Blog() {
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const currentPage = Number(urlSearchParams.get('page')) || 1;

  const hostname = window.location.hostname;

  const queryParams = new URLSearchParams();

  const addQueryParam = (paramName: string, paramValue: string | undefined) => {
    if (paramValue) {
      queryParams.append(paramName, paramValue);
    }
  };

  addQueryParam('page', currentPage.toString());

  const searchParams = queryParams.toString();

  const { data: blogPostsResponse, loading } = useFetch<UnificadaFront.ResponseJSON<IBlogPost>>({
    url: `/marketplace/posts?${searchParams}`,
    method: 'get',
    autoFetch: true,
    authenticated: false,
  });

  const blogPosts = blogPostsResponse?.data?.posts;
  const pageCount = blogPostsResponse?.data?.total / blogPostsResponse?.data?.per_page || 0;

  const { data: blogSettingResponse } = useFetch<UnificadaFront.ResponseJSON<IShowBlog>>({
    url: '/blog-setting',
    method: 'get',
    autoFetch: true,
    authenticated: false,
  });

  const blogSetting = blogSettingResponse?.data;

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    history.push({
      search: urlSearchParams.toString(),
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <div>
        {IS_REACT_APP_HOSTNAME(hostname) ? (
          <Hero />
        ) : (
          <>
            <Box
              id="blogPage"
              width="full"
              display="flex"
              justifyContent="center"
              paddingTop={{ base: '50px', md: '50px', lg: '85px' }}
              height={{ base: '120px', md: '150px', lg: '250px' }}
              backgroundImage={`url('${blogSetting?.banner}')`}
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              backgroundSize="cover"
              marginTop="85px"
            >
              <Box
                hidden={!blogSetting?.showTitle}
                width="full"
                marginTop={{ base: '10px', md: '80px', lg: '80px' }}
                marginX={10}
                textAlign={{ base: 'center', md: 'left', lg: 'left' }}
              >
                <Heading fontSize={{ base: 'xl', md: 'xl', lg: '3xl' }}>Blog</Heading>
              </Box>
            </Box>
          </>
        )}

        {blogPosts?.length && !loading && (
          <Box display="flex" justifyContent="flex-start" flexWrap="wrap" gap="20px" margin={5}>
            {blogPosts?.map(blogPost => (
              <Card key={blogPost.id} post={blogPost} hideDate={blogSetting?.hideDate} />
            ))}
          </Box>
        )}

        {loading && (
          <Box
            margin={5}
            width="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="300px"
          >
            <Spinner size="lg" color="orange.500" />
          </Box>
        )}

        {!blogPosts?.length && !loading && (
          <Box width="full" display="flex" justifyContent="center" marginY={10}>
            <Heading fontSize="md" fontWeight={400} color="#20212380">
              Nenhuma publicação encontrada.
            </Heading>
          </Box>
        )}

        {blogPosts?.length > 0 && (
          <Stack width="full" justifyContent="center" alignItems="center" marginY={5}>
            <Paginate
              pageCount={pageCount}
              initialPage={currentPage}
              onPageChange={handlePageChange}
            />
          </Stack>
        )}

        {IS_REACT_APP_HOSTNAME(hostname) && (
          <Section background="#EB7129">
            <img
              src={squad1}
              alt="Imagem de um quadrado de bordas brancas"
              className={styles.squad1}
            />
            <img
              src={squad2}
              alt="Imagem de um quadrado de bordas brancas"
              className={styles.squad2}
            />
            <img
              src={squad3}
              alt="Imagem de um quadrado de bordas brancas"
              className={styles.squad3}
            />
            <img
              src={squad4}
              alt="Imagem de um quadrado de bordas brancas"
              className={styles.squad4}
            />
            <img
              src={squad5}
              alt="Imagem de um quadrado de bordas brancas"
              className={styles.squad5}
            />

            <div className={styles.signUpContainer}>
              <div>
                <h2>Inscreva-se em nosso blog</h2>
                <span>
                  Acesse, em primeira mão, nossos principais posts sobre educação, marketing, vendas
                  e muito mais, diretamente em seu email.
                </span>
              </div>

              <form>
                <input type="text" placeholder="Nome" />
                <input type="text" placeholder="E-mail" />

                <div className="w-100 d-flex justify-content-sm-center justify-content-md-end">
                  <button type="submit">Enviar</button>
                </div>
              </form>
            </div>
          </Section>
        )}
      </div>
    </Container>
  );
}

export default Blog;
