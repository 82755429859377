import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';

export default function ConfirmCancelModal({
  isOpenCancellation,
  onCloseCancellation,
  cancelSubmit,
  cancelId,
}) {
  return (
    <Modal isOpen={isOpenCancellation} onClose={onCloseCancellation} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent padding={5}>
        <ModalHeader textAlign="center" fontWeight={600}>
          Tem certeza que deseja cancelar?
        </ModalHeader>
        <ModalCloseButton />

        <ModalFooter justifyContent="center">
          <Stack direction="row" spacing={5}>
            <Button colorScheme="red" onClick={onCloseCancellation} size="sm" width={150}>
              Não
            </Button>
            <Button onClick={() => cancelSubmit(cancelId)} size="sm" width={150}>
              Sim
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
