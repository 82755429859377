import Joi from 'joi';
import { CheckoutSettingsEditPaymentForm } from './types';

const REQUIRED_MESSAGE = 'Você deve escolher pelo menos uma opção.';

export default Joi.object<CheckoutSettingsEditPaymentForm>().keys({
  paymentMethod: Joi.array()
    .items(Joi.string().valid('boleto', 'pix', 'credit_card'))
    .min(1)
    .required()
    .required()
    .messages({
      'array.min': REQUIRED_MESSAGE,
    }),
  daysThatCanGenerateBoleto: Joi.when('paymentMethod', {
    is: Joi.array().has('boleto'),
    then: Joi.array()
      .items(Joi.string().valid('1', '2', '3', '4', '5', '6', '7'))
      .min(1)
      .required()
      .messages({
        'array.min': REQUIRED_MESSAGE,
      }),
  }),
  userFormForPurchase: Joi.array()
    .items(Joi.string().valid('END', 'TEL', 'INS'))
    .min(1)
    .required()
    .messages({
      'array.min': REQUIRED_MESSAGE,
    }),
});
