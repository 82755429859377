import { BankDetails } from '..';

interface BiometricsStatus {
  name: 'approved' | 'denied' | 'pending' | 'inactive' | 'registration' | 'affiliation';
  label: string;
  color: string;
}

export function getBiometricsStatus(
  status: BankDetails['status'],
  kycDetailsStatus: BankDetails['kycDetailsStatus']
): BiometricsStatus {
  if (status === 'inactive') {
    return { name: 'inactive', label: 'Permanentemente desativado', color: '#613fc5' };
  }

  if (status === 'registration') {
    return { name: 'registration', label: 'Em credenciamento', color: '#202123' };
  }

  if (kycDetailsStatus === 'pending') {
    return { name: 'pending', label: 'Em análise', color: '#202123' };
  }

  if (status === 'active' && kycDetailsStatus === 'approved') {
    return { name: 'approved', label: 'Validação aprovada', color: '#22BB33' };
  }

  if (status === 'affiliation' && kycDetailsStatus === 'partially_denied') {
    return { name: 'affiliation', label: 'Em afiliação', color: '#055487' };
  }

  if ((status === 'refused' && kycDetailsStatus === 'denied') || status === 'refused') {
    return { name: 'denied', label: 'Validação não aprovada', color: '#BB2124' };
  }
}
