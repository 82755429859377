import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { axiosBackend } from '../../../../../api/Configuration';
import FullPageLoading from '../../../../../components/FullPageLoading';
import convertToBrazilianCurrency from '../../../../../helpers/convertToBrazilianCurrency';
import InfoCardItem from './components/InfoCardItem';
import InfoCardList from './components/InfoCardList';
import MostSoldPlansChart from './components/MostSoldPlansChart';
import SubscriptionAndCancellationChart from './components/SubscriptionAndCancellationChart';
import Filters from './Filters';

export type PlansBestSellingType = {
  planId: number;
  planName: string;
  numberOfRecurrences: number;
  invoicing: number;
  percentage: number;
};

export type PaymentEvolutionOfRecurrencesAndCancellationsType = {
  date: string;
  numberOfSales: number;
  numberOfRecurrencesCanceled: number;
};

interface RecurrenceDashboardResponse {
  sumValueOfTransactions: number;
  numberOfRecurrence: number;
  numberOfCanceledRecurrences: number;
  graphPlansBestSelling: PlansBestSellingType[];
  graphOfPaymentEvolutionOfRecurrencesAndCancellations: PaymentEvolutionOfRecurrencesAndCancellationsType[];
}

export type FiltersType = {
  startDate: string;
  endDate: string;
  planId?: string;
};

export interface Plan {
  id: number;
  name: string;
}

export default function DashboardTabPanel() {
  const [isFetching, setIsFetching] = useState(true);
  const [response, setResponse] = useState<RecurrenceDashboardResponse>();
  const [plans, setPlans] = useState<Plan[]>([]);

  const [filters, setFilters] = useState<FiltersType>({
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    planId: '',
  });

  const diffInDays = useMemo(() => {
    const startDate = dayjs(filters.startDate);
    const endDate = dayjs(filters.endDate);

    return endDate.diff(startDate, 'days');
  }, [filters.startDate, filters.endDate]);

  const urlSearchParams = useMemo(() => {
    const params = new URLSearchParams();

    return params;
  }, []);

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      urlSearchParams.set(key, value);
    }
  });

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    try {
      const { data: response } = await axiosBackend().get<
        UnificadaFront.ResponseJSON<RecurrenceDashboardResponse>
      >(`/recurrence-dashboard?${urlSearchParams}`);
      setResponse(response.data);
    } finally {
      setIsFetching(false);
    }
  }, [urlSearchParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    async function getPlans() {
      if (plans?.length === 0) {
        const { data: response } = await axiosBackend().get<UnificadaFront.ResponseJSON<any[]>>(
          '/recurrence-plan/list-plan'
        );

        setPlans(response.data);
      }
    }

    getPlans();
  }, [plans]);

  const handleFilterChange = (newFilters: Partial<FiltersType>) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const {
    sumValueOfTransactions = 0,
    numberOfRecurrence = 0,
    numberOfCanceledRecurrences = 0,
    graphPlansBestSelling = [],
    graphOfPaymentEvolutionOfRecurrencesAndCancellations = [],
  } = response || {};

  return (
    <>
      <Filters onFilterChange={handleFilterChange} onFilter={fetchData} plans={plans} />

      <InfoCardList mt={8}>
        <InfoCardItem
          title="Transações"
          icon="money"
          value={convertToBrazilianCurrency(sumValueOfTransactions)}
        />

        <InfoCardItem title="Qtd. assinaturas" icon="recurrence" value={numberOfRecurrence} />

        <InfoCardItem
          title="Qtd. cancelamentos"
          icon="cancellation"
          value={numberOfCanceledRecurrences}
        />
      </InfoCardList>

      <MostSoldPlansChart mt={5} data={graphPlansBestSelling} />

      <SubscriptionAndCancellationChart
        mt={5}
        data={graphOfPaymentEvolutionOfRecurrencesAndCancellations}
        diffInDays={diffInDays}
      />

      <FullPageLoading isLoading={isFetching} />
    </>
  );
}
