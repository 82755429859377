import { useLocation } from 'react-router-dom';
import useHasAffiliation from './useHasAffiliation';

const PATHS_TO_MODIFY_HEADER_LIST = ['/student-checkout', '/product'];

export default function useShouldModifyHeader() {
  const { pathname } = useLocation();
  const hasAffiliation = useHasAffiliation();

  const isPathToModifyHeader = PATHS_TO_MODIFY_HEADER_LIST.some(path => pathname?.includes(path));

  const shouldModifyHeader = hasAffiliation && isPathToModifyHeader;

  return shouldModifyHeader;
}
