import { IconButton, Menu, MenuButton, MenuItem, MenuList, Td, Text, Tr } from '@chakra-ui/react';
import { memo } from 'react';
import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { UtmItem } from '../../../useUtmGenerator';
import { generateUTMLink } from '../../../utils/generateUTMLink';
import CopyButton from '../../CopyButton';

interface UtmTableRowProps {
  utmItem: UtmItem;
  onDelete: (id: number) => void;
  onExportInformation: (id: number) => void;
}

function UtmTableRow({ utmItem, onDelete, onExportInformation }: UtmTableRowProps) {
  const utmLink = generateUTMLink(utmItem);

  function handleDeleteClick() {
    onDelete(utmItem.id);
  }

  function handleExportInformationClick() {
    onExportInformation(utmItem.id);
  }

  return (
    <Tr color="#202123" sx={{ td: { p: 6 } }}>
      {/* UTM_CAMPAIGN */}
      <Td width="11.4375rem">
        <Text isTruncated fontWeight="medium">
          {utmItem.utmCampaign}
        </Text>
      </Td>

      {/* UTM_SOURCE */}
      <Td width="11.4375rem">
        <Text isTruncated fontWeight="medium">
          {utmItem.utmSource}
        </Text>
      </Td>

      {/* UTM_MEDIUM */}
      <Td width="11.4375rem">
        <Text isTruncated fontWeight="medium">
          {utmItem.utmMedium}
        </Text>
      </Td>

      {/* URL com UTM */}
      <Td width="6.4375rem">
        <CopyButton textToCopy={utmLink} />
      </Td>

      {/* QNT. DE VENDAS */}
      <Td width="5.75rem">
        <Text isTruncated fontWeight="medium">
          {utmItem.salesQuantity}
        </Text>
      </Td>

      {/* Ações */}
      <Td textAlign="center" width="5.9375rem">
        <Menu size="sm" arrowPadding={0}>
          <MenuButton
            size="xs"
            as={IconButton}
            icon={<MoreIcon size="1.125rem" />}
            w="27px"
            h="24px"
            borderRadius={5}
            bgColor="#2021231F"
            _hover={{ bgColor: '#b6b5b5' }}
            _active={{ bgColor: '#b6b5b5' }}
          />

          <MenuList
            sx={{
              '.chakra-menu__menuitem': {
                fontWeight: 'medium',
                fontSize: 'xs',
                color: '#20212380',
                _hover: { bgColor: '#2021230D' },
                _focus: { bgColor: '#2021230D' },
              },
            }}
          >
            <MenuItem onClick={handleExportInformationClick} isDisabled={utmItem.salesQuantity < 1}>
              Exportar informações
            </MenuItem>

            <MenuItem as={Link} to={`/utm-generator/${utmItem.id}`}>
              Visualizar parâmetros
            </MenuItem>

            <MenuItem onClick={handleDeleteClick}>
              <Text color="#BB2124">Excluir</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default memo(UtmTableRow);
