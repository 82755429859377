import { toFixedPercentage } from '../../../../../../DashboardAdmin/Courses/CourseManager/Reporting/utils';
import Chart from './Chart';
import styles from './styles.module.css';

interface IStatistics {
  rightQuestions: number | string;
  wrongQuestions: number | string;
  studentUseStatistics?: number;
}

function Result({ rightQuestions, wrongQuestions, studentUseStatistics }: IStatistics) {
  return (
    <header className={styles.container}>
      <div className={styles.header}>
        <span className={styles.title}>Resultado</span>
      </div>

      <div className={styles.divider} />

      <Chart rightQuestions={rightQuestions ?? 0} wrongQuestions={wrongQuestions ?? 0} />

      <div className={styles.summary}>
        <span>Quantidade de acertos: {rightQuestions}</span>
        <span>Quantidade de erros: {wrongQuestions}</span>
      </div>

      <div className={styles.successRate}>
        <span>
          Taxa de aproveitamento
          {/* <Tooltip content="..." background="#202123" /> */}
        </span>

        <span>{`${toFixedPercentage(studentUseStatistics)}%`}</span>
      </div>
    </header>
  );
}

export default Result;
