import { Button, HStack, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft as ArrowLeft } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { Heading } from '../../../../components/Heading';
import useFetch from '../../../../hooks/useFetch';
import useWindowSize from '../../../../hooks/useWindowSize';
import { LARGE_SCREEN_SIZE } from '../constants';
import PaymentHistoryCard from './PaymentHistoryCard';
import PaymentHistoryTable from './PaymentHistoryTable';

function PaymentHistory() {
  const history = useHistory();
  const { width } = useWindowSize();

  const { id, subscription_id } = useParams<{ id: string; subscription_id: string }>();

  const count = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [transactions, setTransactions] = useState([]);

  const queryParams = new URLSearchParams();

  const addQueryParam = (paramName: string, paramValue: string | undefined) => {
    if (paramValue) {
      queryParams.append(paramName, paramValue);
    }
  };

  addQueryParam('page', currentPage.toString());
  addQueryParam('count', count.toString());

  const searchParams = queryParams.toString();

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<any[]>>({
    url: `/course-subscription/${subscription_id}/transactions?${searchParams}`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  useEffect(() => {
    if (response?.data) {
      setTransactions(prevState => [...prevState, ...response?.data]);
    }
  }, [response?.data]);

  return (
    <>
      <Stack direction="row" as="section" marginTop="4" marginBottom="9" alignItems="flex-start">
        <Heading
          onClick={() => history.push(`/signatures/${id}/subscribers`)}
          as="h3"
          fontSize="xl"
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          cursor="pointer"
        >
          <ArrowLeft size={30} color="#202123" />
          Histórico de Pagamentos
        </Heading>
      </Stack>

      {width >= LARGE_SCREEN_SIZE ? (
        <PaymentHistoryTable transactions={transactions} isLoading={loading} width={width} />
      ) : (
        <PaymentHistoryCard transactions={transactions} isLoading={loading} />
      )}

      <HStack width="full" justifyContent="center" marginY={5}>
        <Button
          size="sm"
          colorScheme="default"
          onClick={() => setCurrentPage(currentPage + 1)}
          hidden={loading || response?.data?.length < count}
        >
          Carregar mais
        </Button>
      </HStack>
    </>
  );
}

export default PaymentHistory;
