import React, { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import stylesInput from './styles.module.css';

function InputPassword({ onChange, value, ...rest }) {
  const [isHidden, setIsHidden] = useState(true);

  function showPassword() {
    setIsHidden(prevState => !prevState);
  }

  return (
    <div className={stylesInput.input__password}>
      <input
        type={isHidden && 'password'}
        onChange={onChange}
        value={value}
        required
        {...rest}
        autoComplete="current-password"
      />
      <button type="button" onClick={showPassword}>
        {isHidden ? <FiEyeOff /> : <FiEye />}
      </button>
    </div>
  );
}

export default InputPassword;
