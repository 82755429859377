import { useMediaQuery } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { Promotion } from '../../pages/DashboardAdmin/Promotions/types';

export type TimeLeftType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

interface PromotionBanner {
  isVisible: boolean;
  timeLeft: TimeLeftType;
  pathname: string;
  promotion: Promotion;
  isToStartImmediately: boolean;
  promotionStarted: boolean;
}

interface PromotionsResponse {
  promotions: Promotion[];
}

const INVALID_PATHS = [
  '/members-area/v1/profile',
  '/members-area/v1/payments',
  '/student-checkout',
  '/sign-up/account-users',
];

function calculateTimeLeft(isBetween, promotion): TimeLeftType {
  const today = dayjs();
  const timeToCalculate = dayjs(isBetween ? promotion?.endTime : promotion?.startTime);
  const difference = timeToCalculate.diff(today);

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
}

function calculateSectionMarginTop(PromotionBannerHeight: number, headerHeight: number) {
  return PromotionBannerHeight + headerHeight + 'px';
}

const SECTIONS = [
  '#bannerItem',
  '#homeSections',
  '#productsPage',
  '#aboutContainer',
  '#blogPage',
  '#questionPage',
];

const MILLISECONDS_IN_SECOND = 1000;

export default function usePromotionBanner(): PromotionBanner {
  const [timeLeft, setTimeLeft] = useState<TimeLeftType>();
  const [isMobileScreen] = useMediaQuery('(max-width: 992px)');
  const { pathname } = useLocation();

  const { data: response, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<PromotionsResponse>
  >({
    method: 'get',
    url: '/promotion/home/viewing-promotions',
    autoFetch: true,
  });

  const promotions = response?.data?.promotions || [];

  const currentDate = dayjs();

  const activePromotions = promotions.filter(promotion => {
    const endTime = dayjs(promotion.endTime);

    // Verifique se a promoção ainda não expirou (a data de término é maior ou igual que a data atual)
    return endTime.isSameOrAfter(currentDate);
  });

  const sortedPromotions = activePromotions.sort((a, b) => {
    const startTimeA = dayjs(a.startTime).valueOf(); // pega o valor numérico da data
    const startTimeB = dayjs(b.startTime).valueOf(); // pega o valor numérico da data

    return startTimeA - startTimeB;
  });

  // Encontrar a próxima promoção a ser exibida
  const currentPromotion = sortedPromotions?.[0];

  const promotionStarted = currentDate.isBetween(
    dayjs(currentPromotion?.startTime),
    dayjs(currentPromotion?.endTime)
  );

  const isToStartImmediately = currentPromotion?.indicateStartOfPromotion;

  const isValidPath = !INVALID_PATHS.some(invalidPath => pathname.includes(invalidPath));

  const isVisible = isValidPath && (promotionStarted || isToStartImmediately);

  const header: HTMLElement = document.querySelector('header');
  const headerHeight = header?.clientHeight;

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(promotionStarted, currentPromotion));
    }, MILLISECONDS_IN_SECOND);

    return () => clearTimeout(timer);
  });

  // Lida com a estilização do banner
  useEffect(() => {
    if (!isLoading && currentPromotion) {
      for (const section of SECTIONS) {
        const element: HTMLElement = document.querySelector(section);

        if (element) {
          const PromotionBanner: HTMLElement = document.querySelector('#PromotionBanner');

          const PromotionBannerHeight = PromotionBanner?.clientHeight;

          if (!isMobileScreen) {
            const newMargin = calculateSectionMarginTop(PromotionBannerHeight, headerHeight);

            element.style.marginTop = newMargin;

            return;
          }

          const mobileHeader: HTMLElement = document.querySelector('#mobileHeader');
          const mobileHeaderHeight = mobileHeader?.clientHeight;

          const newMargin = calculateSectionMarginTop(PromotionBannerHeight, mobileHeaderHeight);

          element.style.marginTop = newMargin;
        }
      }
    }
  }, [currentPromotion, headerHeight, isLoading, isMobileScreen]);

  useEffect(() => {
    function resetMarginTop() {
      for (const section of SECTIONS) {
        const element: HTMLElement = document.querySelector(section);
        if (element) {
          element.style.marginTop = headerHeight + 'px';
        }
      }
    }

    // Retorna marginTop original depois que o contador zera
    if (!isVisible) {
      resetMarginTop();
    }

    return resetMarginTop;
  }, [isVisible, headerHeight]);

  return {
    isVisible,
    timeLeft,
    pathname,
    promotion: currentPromotion,
    isToStartImmediately,
    promotionStarted,
  };
}
