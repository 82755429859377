import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FiAlertTriangle } from 'react-icons/fi';

export default function DeleteConfirmModal({ isOpen, onClose, onSubmit, isSubmitting }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.500" backdropFilter="blur(3px) " />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader fontSize="2xl">
          <Box width="full" display="flex" justifyContent="center" padding="10px">
            <FiAlertTriangle size={45} color="#C53030" />
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Text fontSize="lg" fontWeight={500}>
              Deseja realmente remover essa questão?
            </Text>
            <Text fontSize="xs" color="#20212390">
              Ao remover essa questão, ela voltará a ser listada como paga.
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button size="sm" colorScheme="red" mr={3} onClick={onClose} isDisabled={isSubmitting}>
            Não
          </Button>
          <Button type="submit" size="sm" isDisabled={isSubmitting} isLoading={isSubmitting}>
            Sim
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
