import './styles.css';

import { ReactComponent as ConcursoIcon } from '../../../assets/icons/home-categories-items/concurso.svg';
import { ReactComponent as EnemIcon } from '../../../assets/icons/home-categories-items/enem.svg';
import { ReactComponent as OABIcon } from '../../../assets/icons/home-categories-items/oab.svg';
import { ReactComponent as PraticasJuridicasIcon } from '../../../assets/icons/home-categories-items/praticasJuridicas.svg';
import { ReactComponent as SaudeIcon } from '../../../assets/icons/home-categories-items/saude.svg';

function ItemCategories() {
  return (
    <section className="categorias">
      <div className="container-fluid">
        <div className="row flex justify-content-center align-items-center">
          <div className="col-md-12 col-lg-10 col-xl-8 ">
            <div className="d-flex justify-content-between flex-md-column-2">
              <span className="d-flex flex-column align-items-center">
                <EnemIcon />
                <h5 className="text-center text-white mt-3">ENEM</h5>
              </span>

              <span className="d-flex flex-column align-items-center">
                <ConcursoIcon />
                <h5 className="text-center text-white mt-3">Concurso</h5>
              </span>

              <span className="d-flex flex-column align-items-center">
                <PraticasJuridicasIcon />
                <h5 className="text-center text-white mt-3">Práticas Jurídicas</h5>
              </span>

              <span className="d-flex flex-column align-items-center">
                <SaudeIcon />
                <h5 className="text-center text-white mt-3">Saúde</h5>
              </span>

              <span className="d-flex flex-column align-items-center">
                <OABIcon />
                <h5 className="text-center text-white mt-3">OAB</h5>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ItemCategories;
