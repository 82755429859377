export const PAYMENT_METHOD_OPTIONS = [
  { label: 'Cartão de crédito', value: 'credit_card' },
  { label: 'Boleto', value: 'boleto' },
  { label: 'Pix', value: 'pix' },
];

export const BOLETO_DAYS_OPTIONS = [
  { label: 'domingo', value: '1' },
  { label: 'segunda-feira', value: '2' },
  { label: 'terça-feira', value: '3' },
  { label: 'quarta-feira', value: '4' },
  { label: 'quinta-feira', value: '5' },
  { label: 'sexta-feira', value: '6' },
  { label: 'sábado', value: '7' },
];

export const MULTI_SELECT_CHAKRA_STYLES = {
  container: provided => ({
    ...provided,
    mt: 2,
    width: '100%',
    fontSize: 'sm',
    _focusWithin: {
      zIndex: 1,
      borderColor: 'gray.300',
      boxShadow: '0 0 0 1px var(--chakra-colors-primary-200)',
      borderRadius: 'md',
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: '#0000005C',
    fontSize: 'md',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    bg: 'transparent',
    color: '#202123',
    px: 2.5,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  option: provided => ({
    ...provided,
    color: '#20212380',
    fontSize: 'sm',
  }),
  valueContainer: provided => ({
    ...provided,
    px: 3,
    color: 'gray.800',
    fontSize: 'md ',
  }),
};
