import { Link, Route, Switch, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Divider,
  HStack,
  VStack,
} from '@chakra-ui/react';
import BasicSettings from './BasicSettings/BasicSettings';
import Certificate from './Certificate';
import ExternalSalesPage from './ExternalSalesPage';
import FrequentlyQuestions from './FrequentlyQuestions/FrequentlyQuestions';
import ImageDescription from './ImageDescription';
import MembersArea from './MembersArea';

export default function Configuration() {
  const { id } = useParams();

  const path = window.location.pathname;

  const isBasicConfiguration = path.includes(`/basic-configuration`);
  const isImageAndDescription = path.includes(`/image-and-description`);
  const isFrequentlyQuestions = path.includes(`/frequently-asked-questions`);
  const isExternalSalesPage = path.includes(`/external-sales-page`);
  const isMembersArea = path.includes(`/members-area`);
  const isCertificate = path.includes(`/certificate`);

  return (
    <HStack align="start" spacing="30px" m="30px">
      <Breadcrumb separator="">
        <VStack align="start" width="300px" ml="20px" gap="20px">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              fontSize="lg"
              fontWeight="semibold"
              color={isBasicConfiguration ? 'default.500' : 'gray.500'}
              borderBottom={isBasicConfiguration ? '2px solid' : 'none'}
              borderColor="default.500"
              to={`/courses/${id}/course-manager/configuration/basic-configuration`}
            >
              Configurações básicas
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              fontSize="lg"
              fontWeight="semibold"
              color={isImageAndDescription ? 'default.500' : 'gray.500'}
              borderBottom={isImageAndDescription ? '2px solid' : 'none'}
              borderColor="default.500"
              to={`/courses/${id}/course-manager/configuration/image-and-description`}
            >
              Imagens e descrição do curso
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              fontSize="lg"
              fontWeight="semibold"
              color={isFrequentlyQuestions ? 'default.500' : 'gray.500'}
              borderBottom={isFrequentlyQuestions ? '2px solid' : 'none'}
              borderColor="default.500"
              to={`/courses/${id}/course-manager/configuration/frequently-asked-questions`}
            >
              Perguntas frequentes
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              fontSize="lg"
              fontWeight="semibold"
              color={isExternalSalesPage ? 'default.500' : 'gray.500'}
              borderBottom={isExternalSalesPage ? '2px solid' : 'none'}
              borderColor="default.500"
              to={`/courses/${id}/course-manager/configuration/external-sales-page`}
            >
              Tipo de página de vendas
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              fontSize="lg"
              fontWeight="semibold"
              color={isMembersArea ? 'default.500' : 'gray.500'}
              borderBottom={isMembersArea ? '2px solid' : 'none'}
              borderColor="default.500"
              to={`/courses/${id}/course-manager/configuration/members-area`}
            >
              Área de membros
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              fontSize="lg"
              fontWeight="semibold"
              color={isCertificate ? 'default.500' : 'gray.500'}
              borderBottom={isCertificate ? '2px solid' : 'none'}
              borderColor="default.500"
              to={`/courses/${id}/course-manager/configuration/certificate`}
            >
              Certificado
            </BreadcrumbLink>
          </BreadcrumbItem>
        </VStack>
      </Breadcrumb>

      <Center height="400px">
        <Divider orientation="vertical" size="lg" />
      </Center>

      <VStack width="full">
        <Switch>
          <Route
            exact
            path="/courses/:id/course-manager/configuration/basic-configuration"
            component={BasicSettings}
          />
          <Route
            exact
            path="/courses/:id/course-manager/configuration/image-and-description"
            component={ImageDescription}
          />
          <Route
            exact
            path="/courses/:id/course-manager/configuration/frequently-asked-questions"
            component={FrequentlyQuestions}
          />
          <Route
            exact
            path="/courses/:id/course-manager/configuration/external-sales-page"
            component={ExternalSalesPage}
          />
          <Route
            exact
            path="/courses/:id/course-manager/configuration/members-area"
            component={MembersArea}
          />
          <Route
            exact
            path="/courses/:id/course-manager/configuration/certificate"
            component={Certificate}
          />
        </Switch>
      </VStack>
    </HStack>
  );
}
