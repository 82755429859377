import dayjs from 'dayjs';
import { Plan, Status } from './types';

export function getRecurrenceText(recurrence: Plan['recurrence']) {
  return {
    daily: 'Diário',
    biweekly: 'Quinzenal',
    monthly: 'Mensal',
    bimonthly: 'Bimestral',
    quarterly: 'Trimestral',
    semiannual: 'Semestral',
    yearly: 'Anual',
  }[recurrence];
}

export function getStatusText(status: Status) {
  return {
    ATIVO: 'Ativo',
    INATIVO: 'Inativo',
    EM_EDICAO: 'Em edição',
    BLOQUEADO: 'Bloqueado',
    NAO_LISTADO: 'Não Listado',
  }[status];
}

export function getPaymentMethodsText(paymentMethods: string[]) {
  const paymentMethodMap: { [key: string]: string } = {
    credit_card: 'Cartão',
    boleto: 'Boleto',
    pix: 'Pix',
  };

  return paymentMethods.map(method => paymentMethodMap[method]).join(', ');
}

export function formatCreatedAt(createdAt: string) {
  return dayjs(createdAt).format('DD/MM/YYYY');
}
