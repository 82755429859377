import { Flex, Heading, Skeleton, Text } from '@chakra-ui/react';
import { MoneyIcon, ShoppingCartIcon, StudentsIcon } from './icons';

type IconType = 'shoppingCart' | 'students' | 'billing';

interface CardProps {
  title: string;
  icon: IconType;
  value: string;
  isLoading: boolean;
}

function getIcon(icon: IconType) {
  const icons = {
    shoppingCart: <ShoppingCartIcon boxSize={6} />,
    billing: <MoneyIcon boxSize={6} />,
    students: <StudentsIcon boxSize={6} />,
  };

  return icons[icon] || <ShoppingCartIcon />;
}

export default function InformationsItem({ title, icon, value, isLoading }: CardProps) {
  return (
    <Flex
      flex={1}
      direction="column"
      bg="#fffff"
      border="1px solid rgba(32, 33, 35, 0.25)"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      borderRadius="6px"
      p={4}
      pr={5}
    >
      <Flex flex={1} justify="space-between" align="center" color="orange.500">
        <Heading as="h5" size="xs" fontWeight="semibold">
          {title}
        </Heading>

        {getIcon(icon)}
      </Flex>

      <Skeleton isLoaded={!isLoading} mt={2} startColor="orange.400" endColor="orange.600" h={12}>
        <Flex flex={1}>
          <Text as="span" fontWeight="semibold" fontSize="2rem">
            {value}
          </Text>
        </Flex>
      </Skeleton>
    </Flex>
  );
}
