import CouponItem from '../CouponItem';
import styles from '../styles.module.css';

export function TableList({
  coupons,
  currentPage,
  per_page,
  search,
  paginationCoupons,
  onOpen,
  setCouponId,
}) {
  return (
    <div className="table-responsive">
      <table className={`table ${styles.tableContainer}`}>
        <thead className={`table-borderless ${styles.tableHead}`}>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Valor</th>
            <th scope="col">Produtos</th>
            <th scope="col">Período</th>
            <th scope="col">Usos</th>
            <th scope="col">
              Quantidade <br />
              máxima de uso
            </th>
            <th scope="col">Faturamento</th>
            <th scope="col">Status</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>

        <tbody>
          {coupons
            .filter(
              coupon =>
                coupon?.name.toLowerCase().includes(search?.toLowerCase()) ||
                coupon?.status.toLowerCase().includes(search?.toLowerCase())
            )
            .map(coupon => (
              <CouponItem
                key={coupon.id}
                coupon={coupon}
                paginationCoupons={paginationCoupons}
                page={currentPage}
                perPage={per_page}
                search={search}
                onOpen={onOpen}
                setCouponId={setCouponId}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}
