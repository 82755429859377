import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  StackDivider,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import useFetch from '../../../../hooks/useFetch';
import useHandleChange from '../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';

type NotificationType =
  | 'NOTIFICATION_SALES'
  | 'NOTIFICATION_NEW_FEATURES'
  | 'NOTIFICATION_NEW_LEAD'
  | 'NOTIFICATION_AFFILIATE_OR_POLO_SALES'
  | 'NOTIFICATION_TASK_RECEIPT';

interface Notification {
  id: number;
  type: NotificationType;
  status: boolean;
}

const initialState = {
  NOTIFICATION_SALES: null,
  NOTIFICATION_NEW_FEATURES: null,
  NOTIFICATION_NEW_LEAD: null,
  NOTIFICATION_AFFILIATE_OR_POLO_SALES: null,
  NOTIFICATION_TASK_RECEIPT: null,
};

export default function Notifications() {
  const [notificationBeingUpdated, setNotificationBeingUpdated] = useState<Notification>();

  const { data: response } = useFetch<UnificadaFront.ResponseJSON<Notification[]>>({
    url: '/platforms/settings',
    method: 'get',
    authenticated: true,
    autoFetch: true,
  });

  function getNotificationStatus(notificationType: NotificationType) {
    return response?.data?.find(({ type }) => type === notificationType)?.status;
  }

  function getNotificationId(notificationType: NotificationType) {
    return response?.data?.find(({ type }) => type === notificationType).id;
  }

  const { handleSubmit: updateNotification } = useHandleSubmit({
    method: 'patch',
    url: '/platforms/update-settings',
    data: notificationBeingUpdated,
    authenticated: true,
  });

  useEffect(() => {
    if (notificationBeingUpdated) updateNotification();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationBeingUpdated]);

  const { form, handleChange } = useHandleChange(
    initialState,
    response?.data && {
      NOTIFICATION_SALES: getNotificationStatus('NOTIFICATION_SALES'),
      NOTIFICATION_NEW_FEATURES: getNotificationStatus('NOTIFICATION_NEW_FEATURES'),
      NOTIFICATION_NEW_LEAD: getNotificationStatus('NOTIFICATION_NEW_LEAD'),
      NOTIFICATION_AFFILIATE_OR_POLO_SALES: getNotificationStatus(
        'NOTIFICATION_AFFILIATE_OR_POLO_SALES'
      ),
      NOTIFICATION_TASK_RECEIPT: getNotificationStatus('NOTIFICATION_TASK_RECEIPT'),
    }
  );

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name as NotificationType;

    handleChange(event);

    setNotificationBeingUpdated({
      id: getNotificationId(name),
      type: name,
      status: event.target.checked,
    });
  }

  return (
    <Box>
      <Heading fontSize="xl" fontWeight="medium">
        Personalize suas notificações
      </Heading>

      <Text color="#0000007D" fontSize="sm">
        Você pode escolher que tipo de notificações você quer receber através do navegador:
      </Text>

      <VStack as="form" mt={8} spacing={12}>
        <VStack w="full" spacing={6} divider={<StackDivider />}>
          <VStack w="full" align="start" spacing={{ base: 1, lg: 0 }}>
            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel mb={0}>Vendas</FormLabel>
              <Switch
                name="NOTIFICATION_SALES"
                size={{ base: 'sm', lg: 'md' }}
                isChecked={form.NOTIFICATION_SALES}
                onChange={onChange}
                colorScheme="default"
              />
            </FormControl>
            <Text color="#0000007D" fontSize="sm">
              Todas as vendas da plataforma
            </Text>
          </VStack>

          <VStack w="full" align="start" spacing={{ base: 1, lg: 0 }}>
            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel mb={0}>Novo aluno</FormLabel>
              <Switch
                name="NOTIFICATION_NEW_LEAD"
                size={{ base: 'sm', lg: 'md' }}
                isChecked={form.NOTIFICATION_NEW_LEAD}
                onChange={onChange}
                colorScheme="default"
              />
            </FormControl>

            <Text color="#0000007D" fontSize="sm">
              Quando um novo aluno se cadastrar na plataforma.
            </Text>
          </VStack>

          <VStack w="full" align="start" spacing={{ base: 1, lg: 0 }}>
            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel mb={0}>Venda do Afiliado/Polo:</FormLabel>
              <Switch
                name="NOTIFICATION_AFFILIATE_OR_POLO_SALES"
                size={{ base: 'sm', lg: 'md' }}
                isChecked={form.NOTIFICATION_AFFILIATE_OR_POLO_SALES}
                onChange={onChange}
                colorScheme="default"
              />
            </FormControl>

            <Text color="#0000007D" fontSize="sm">
              Quando ocorrer uma venda de um afiliado ou polo.
            </Text>
          </VStack>

          <VStack w="full" align="start" spacing={{ base: 1, lg: 0 }}>
            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel mb={0}>Envio de Correção de Tarefas</FormLabel>

              <Switch
                name="NOTIFICATION_TASK_RECEIPT"
                size={{ base: 'sm', lg: 'md' }}
                isChecked={form.NOTIFICATION_TASK_RECEIPT}
                onChange={onChange}
                colorScheme="default"
              />
            </FormControl>

            <Text color="#0000007D" fontSize="sm">
              Quando uma nova correção for enviada pelos alunos.
            </Text>
          </VStack>

          <VStack w="full" align="start" spacing={{ base: 1, lg: 0 }}>
            <FormControl display="flex" alignItems="center" justifyContent="space-between">
              <FormLabel mb={0}>Funcionalidades/Avisos</FormLabel>
              <Switch
                isChecked={true}
                colorScheme="default"
                size={{ base: 'sm', lg: 'md' }}
                isDisabled
              />
            </FormControl>

            <Text color="#0000007D" fontSize="sm">
              Para que você sempre fique por dentro das novidades da Proluno, você sempre irá
              receber nossas notificações.
            </Text>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
}
