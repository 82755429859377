import { createIcon } from '@chakra-ui/react';

const CalendarIcon = createIcon({
  displayName: 'CalendarIcon',
  viewBox: '0 0 19 19',
  path: (
    <path
      d="M14.8333 2.375H14.0417V0.791664H12.4583V2.375H4.54168V0.791664H2.95834V2.375H2.16668C1.29584 2.375 0.583344 3.0875 0.583344 3.95833V16.625C0.583344 17.4958 1.29584 18.2083 2.16668 18.2083H14.8333C15.7042 18.2083 16.4167 17.4958 16.4167 16.625V3.95833C16.4167 3.0875 15.7042 2.375 14.8333 2.375ZM14.8333 16.625H2.16668V7.91666H14.8333V16.625ZM14.8333 6.33333H2.16668V3.95833H14.8333V6.33333Z"
      fill="currentColor"
    />
  ),
});

export default CalendarIcon;
