export function getStatusColor(status: string) {
  const statusColor = {
    processing: '#EDCA32',
    authorized: '#22BB33',
    paid: '#22BB33',
    refunded: '#BB2124',
    waiting_payment: '#EDCA32',
    pending_refund: '#EDCA32',
    refused: '#BB2124',
    chargedback: '#202123',
    analyzing: '#EDCA32',
    pending_review: '#EDCA32',
  };

  return statusColor[status];
}
