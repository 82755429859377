import Box from '../Box';
import PaymentMethod from '../PaymentMethod';
import convert from '../../../../../helpers/convertToBrazilianCurrency';
import styles from '../styles.module.css';

type Installment = {
  amount: number;
  anticipationFee: number;
  chargeRemainder: boolean;
  fee: number;
  splitAmount: number;
  splitDate: string;
  status: string;
};

type Recipient = {
  fullName: string;
  percentage: string;
  installmentsPayment: Installment[];
};

// type Transaction = {
//   costPurchase: number;
//   createdAt: string;
//   id: number;
//   installments: number;
//   mdr: number;
//   paymentMethod: string;
//   purchaseStatus: string;
//   purchaseValue: number;
//   recipients: Recipients[];
//   totalLiquid: number;
//   transactionId: number;
// };

// interface ITransactionProps {
//   transaction: Transaction;
// }

function GridBox({ transaction }) {
  const { paymentMethod } = transaction;

  const installments = transaction.recipients.map(
    (recipient: Recipient) => recipient.installmentsPayment
  );

  const rates = installments.map((installmentItem: Installment[]) => {
    return installmentItem.map(item => item.fee);
  });

  if (!rates.length) {
    return '';
  }

  let firstArrayRates = 0;
  let secondArrayRates = 0;

  if (rates.length[0]) {
    firstArrayRates = rates[0].reduce((acc: number, total: number) => {
      return (acc += total);
    }, paymentMethod === 'boleto' && []);
  }

  if (rates.length[1]) {
    secondArrayRates = rates[1].reduce((acc: number, total: number) => {
      return (acc += total);
    }, paymentMethod === 'boleto' && []);
  }

  // const firstArrayRates = rates[0].reduce((acc: number, total: number) => {
  //   return (acc += total);
  // }, paymentMethod === 'boleto' && []);

  // const secondArrayRates = rates[1].reduce((acc: number, total: number) => {
  //   return (acc += total);
  // }, paymentMethod === 'boleto' && []);

  const totalRates =
    parseFloat(firstArrayRates.toString()) + parseFloat(secondArrayRates.toString());

  return (
    <div className={styles.gridBox}>
      <Box>
        <PaymentMethod transaction={transaction} />
      </Box>

      <Box>
        <span className={styles.heading}>Valor capturado</span>
        <span className={styles.price}>
          {transaction.purchaseValue ? convert(transaction.purchaseValue) : '-'}
        </span>
      </Box>

      <Box>
        <span className={styles.heading}>Total de saídas</span>
        <span className={styles.price}>
          {transaction.paymentMethod === 'boleto' && totalRates ? convert(totalRates) : 'R$ -'}
          {transaction.paymentMethod === 'creditCard' && convert(transaction.mdr)}
        </span>
        <div className={styles.ratesGroup}>
          <span className={styles.rates}>
            MDR: {transaction.mdr ? convert(transaction.mdr) : '-'}
          </span>
          <span className={styles.rates}>
            Custo de processamento: {''}
            {transaction.costPurchase ? convert(transaction.costPurchase) : '-'}
          </span>
        </div>
      </Box>

      <Box>
        <span className={styles.heading}>Valor líquido</span>
        <span className={styles.price}>
          {transaction.totalLiquid ? convert(transaction.totalLiquid) : '-'}
        </span>
      </Box>
    </div>
  );
}

export default GridBox;
