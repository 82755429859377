import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { axiosBackend } from '../../../../../api/Configuration';
import FacebookIntegrationAPI from '../../../../../api/FacebookIntegration';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';
import Toast from '../../../../../components/Toast';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import Header from '../../components/Header';

type CourseType = {
  id: number;
  name: string;
};
interface IFacebookPixel {
  id?: number;
  name: string;
  identifierPixel: string;
  eventVisitsPaymentPage: boolean;
  eventSalesConversion: boolean;
  receiveSalesConfirmationPaymentMethod: string;
  receiveConfirmationAmount: string;
  paymentMethod: string;
  sendValue: string | number;
  courseId: number;
  courseName?: string;
  accessTokenPixel?: string;
}

const initialStateFacebookPixel = {
  id: 0,
  name: '',
  identifierPixel: '',
  eventVisitsPaymentPage: true,
  eventSalesConversion: true,
  receiveSalesConfirmationPaymentMethod: 'all_payment_method',
  receiveConfirmationAmount: 'real_sale_value',
  paymentMethod: '',
  sendValue: '',
  courseId: 0,
  courseName: '',
};

function Pixel() {
  // const [toggle, setToggle] = useBoolean();

  const { id: pixelId } = useParams<{ id: string }>();

  const history = useHistory();

  const [courses, setCourses] = useState([]);

  const [pixelFacebook, setPixelFacebook] = useState<IFacebookPixel>(initialStateFacebookPixel);

  const [confirmationPaymentMethod, setConfirmationPaymentMethod] = useState<string>(
    initialStateFacebookPixel.receiveSalesConfirmationPaymentMethod
  );

  const [confirmationAmount, setConfirmationAmount] = useState<string>(
    initialStateFacebookPixel.receiveConfirmationAmount
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const { data: coursesData } = await axiosBackend().get(`/courses/list/platform`);

        const response = coursesData.data;

        if (response) {
          setCourses(response);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    })();
  }, []);

  useEffect(() => {
    if (pixelId) {
      (async () => {
        try {
          const { data: pixelData } = await FacebookIntegrationAPI.show(pixelId);

          if (pixelData) {
            setPixelFacebook(pixelData);
            setConfirmationPaymentMethod(pixelData.receiveSalesConfirmationPaymentMethod);
            setConfirmationAmount(pixelData.receiveConfirmationAmount);
          }
        } catch (error) {
          Toast(ErrorResponse(error), 'error');
        }
      })();
    }
  }, [pixelId]);

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    setPixelFacebook({ ...pixelFacebook, [name]: value });
    setHasChanged(true);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    const payload = {
      ...pixelFacebook,
      receiveSalesConfirmationPaymentMethod: confirmationPaymentMethod,
      receiveConfirmationAmount: confirmationAmount,
    };

    if (confirmationAmount !== 'set_value') {
      delete payload.paymentMethod;
      delete payload.sendValue;
    }

    if (payload?.id && payload?.courseName) {
      delete payload.id;
      delete payload.courseName;
    }

    if (!pixelId) {
      try {
        await FacebookIntegrationAPI.store(payload);

        Toast('Pixel criado!');

        history.push('/integrations/facebook');
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }

      return;
    }

    try {
      await FacebookIntegrationAPI.update(pixelId, payload);

      Toast('Pixel editado!');

      history.push('/integrations/facebook');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Stack>
      <Header title={pixelId ? 'Editar pixel' : 'Adicionar pixel'} path="facebook" />

      <form onSubmit={handleSubmit}>
        <VStack
          gap="3"
          sx={{
            '& div div > label:first-child': {
              marginBottom: '0.25rem',
            },
          }}
        >
          <Box position="relative" width="full">
            <Input
              id="name"
              name="name"
              label="Nome do pixel"
              labelWeight="semibold"
              placeholder="Digite um nome"
              onChange={handleChange}
              value={pixelFacebook.name}
              isRequired
              maxLength={25}
            />

            <Text position="absolute" top="2" right="0" fontSize="sm" color="blackAlpha.500">
              Caracteres: {`${pixelFacebook.name.length}/25`}
            </Text>
          </Box>

          <Select
            id="courseId"
            name="courseId"
            label="Selecione um produto"
            labelWeight="semibold"
            description="Selecione o produto que o Pixel será vinculado."
            placeholder="Escolha um produto"
            onChange={handleChange}
            value={String(pixelFacebook.courseId)}
            isRequired
          >
            {courses?.map((course: CourseType) => (
              <option key={course.id} value={course.id}>
                {course.name}
              </option>
            ))}
          </Select>

          <Input
            id="identifierPixel"
            name="identifierPixel"
            label="ID do Pixel"
            labelWeight="semibold"
            placeholder="Cole o ID aqui"
            description="Cole o ID do pixel do facebook logo abaixo para configurar o rastreamento de seus clientes."
            onChange={handleChange}
            value={pixelFacebook.identifierPixel}
            isRequired
          />

          <Box
            width="full"
            marginTop="0rem !important"
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Text
              marginTop={{ base: '2', lg: '3.5' }}
              fontSize="xl"
              fontWeight="semibold"
              lineHeight="7"
              color="dark.500"
            >
              Configurações de Pixel
            </Text>

            <Text marginY="2" fontSize="md" fontWeight="medium" lineHeight="7" color="dark.500">
              Quais eventos você deseja receber?
            </Text>

            <Flex flexDirection={{ base: 'column', lg: 'row' }} gap={{ base: '3', lg: '6' }}>
              <Box maxWidth={{ md: '22.75rem' }}>
                <Checkbox
                  name="eventVisitsPaymentPage"
                  colorScheme="primary"
                  borderColor="dark.500"
                  onChange={event => {
                    setPixelFacebook({
                      ...pixelFacebook,
                      eventVisitsPaymentPage: event.target.checked,
                    });
                    setHasChanged(true);
                  }}
                  isChecked={pixelFacebook.eventVisitsPaymentPage}
                >
                  Visitas na página de pagamento.
                </Checkbox>
                <Text
                  fontSize="sm"
                  fontWeight="normal"
                  lineHeight="shorter"
                  color="rgba(32, 33, 35, 0.5)"
                >
                  Você saberá quantas pessoas visitaram a página de pagamento do seu produto.
                </Text>
              </Box>
              <Box maxWidth={{ md: '22.75rem' }}>
                <Checkbox
                  name="eventSalesConversion"
                  colorScheme="primary"
                  borderColor="dark.500"
                  onChange={event => {
                    setPixelFacebook({
                      ...pixelFacebook,
                      eventSalesConversion: event.target.checked,
                    });
                    setHasChanged(true);
                  }}
                  isChecked={pixelFacebook.eventSalesConversion}
                >
                  Conversão de vendas.
                </Checkbox>
                <Text
                  fontSize="sm"
                  fontWeight="normal"
                  lineHeight="shorter"
                  color="rgba(32, 33, 35, 0.5)"
                >
                  Você saberá quantas pessoas chegaram até a página de obrigado do seu produto.
                </Text>
              </Box>
            </Flex>
          </Box>

          <Accordion width="full" allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton borderRadius={5} paddingX={0}>
                  <Box as="span" flex="1" textAlign="left">
                    <Text
                      marginTop={{ base: '2', lg: '3.5' }}
                      fontSize="xl"
                      fontWeight="semibold"
                      lineHeight="7"
                      color="dark.500"
                    >
                      Configurar envio via API de Conversão
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} paddingX={0}>
                <Input
                  id="accessTokenPixel"
                  name="accessTokenPixel"
                  label="Access Token"
                  labelWeight="semibold"
                  placeholder="Cole seu access_token aqui"
                  onChange={handleChange}
                  value={pixelFacebook.accessTokenPixel}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </VStack>

        {/* <VStack marginY="1.875rem !important" alignItems="flex-start">
          <Button
            padding="0"
            leftIcon={<ChevronRightIcon />}
            variant="outline"
            fontFamily="Montserrat"
            onClick={() => setToggle.toggle()}
            marginLeft="-0.5rem"
            sx={
              toggle
                ? {
                    '& > span': {
                      marginRight: 0,
                      flexShrink: 0,
                      transform: 'rotate(90deg)',
                    },
                  }
                : {
                    '& > span': {
                      flexShrink: 0,
                      marginRight: 0,
                    },
                  }
            }
            _focus={{
              background: 'transparent',
            }}
            _hover={{
              background: 'transparent',
            }}
          >
            Opções avançadas
          </Button>

          {toggle && (
            <Stack paddingTop="5" width="100%">
              <Box>
                <Text fontSize="md" fontWeight="medium" lineHeight="6" color="darkblue.500">
                  Receber confirmação de venda de qual meio de pagamento?
                </Text>

                <RadioGroup
                  defaultValue="all_payment_method"
                  onChange={event => {
                    setConfirmationPaymentMethod(event);
                    setHasChanged(true);
                  }}
                  value={confirmationPaymentMethod}
                  marginY="3"
                >
                  <VStack alignItems="flex-start">
                    <Radio
                      colorScheme="primary"
                      borderColor="dark.500"
                      value="all_payment_method"
                      isRequired
                    >
                      Todos os meios de pagamento.
                    </Radio>
                    <Radio
                      colorScheme="primary"
                      borderColor="dark.500"
                      value="card_only"
                      isRequired
                    >
                      Somente cartões.
                    </Radio>
                  </VStack>
                </RadioGroup>
              </Box>
              <Box>
                <Text fontSize="md" fontWeight="medium" lineHeight="6" color="darkblue.500">
                  Receber confirmação com qual valor?
                </Text>

                <RadioGroup
                  defaultValue="all_payment_method"
                  onChange={event => {
                    setConfirmationAmount(event);
                    setHasChanged(true);
                  }}
                  value={confirmationAmount}
                  marginY="3"
                >
                  <VStack alignItems="flex-start">
                    <Radio
                      colorScheme="primary"
                      borderColor="dark.500"
                      value="real_sale_value"
                      isRequired
                    >
                      Valor real da venda.
                    </Radio>
                    <Radio
                      colorScheme="primary"
                      borderColor="dark.500"
                      value="set_value"
                      isRequired
                    >
                      Valor que eu definir.
                    </Radio>
                  </VStack>
                </RadioGroup>
              </Box>

              {confirmationAmount === 'set_value' && (
                <Box display="flex" flexDirection={{ base: 'column', md: 'row' }} gap="5">
                  <Select
                    id="paymentMethod"
                    name="paymentMethod"
                    label="Quando o meio de pagamento for..."
                    placeholder="Selecione uma opção"
                    onChange={handleChange}
                    value={pixelFacebook.paymentMethod}
                    isRequired
                  >
                    <option value="credit_card">Cartão de crédito</option>
                    <option value="boleto">Boleto</option>
                    <option value="pix">Pix</option>
                  </Select>
                  <Input
                    id="sendValue"
                    name="sendValue"
                    type="number"
                    label="Enviar o valor seguinte..."
                    placeholder="Digite um valor"
                    step="0.01"
                    min="1"
                    max="99999.99"
                    onChange={handleChange}
                    value={pixelFacebook.sendValue}
                    isRequired
                    isPrice
                  />
                </Box>
              )}
            </Stack>
          )}
        </VStack> */}

        <Flex width="full" gap="5" justifyContent={{ lg: 'flex-end' }}>
          <Button
            width={{ base: '100%', lg: 'auto' }}
            size="sm"
            variant="outline"
            color="dark.500"
            background="rgba(32, 33, 35, 0.1)"
            border="1px solid"
            borderColor="rgba(32, 33, 35, 0.25)"
            _hover={{
              background: 'rgba(32, 33, 35, 0.1)',
            }}
            onClick={() => {
              setPixelFacebook(initialStateFacebookPixel);
              setConfirmationPaymentMethod('all_payment_method');
              setConfirmationAmount('real_sale_value');
            }}
            isDisabled={!hasChanged || isLoading}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            width={{ base: '100%', lg: 'auto' }}
            size="sm"
            colorScheme="primary"
            isLoading={isLoading}
            isDisabled={!hasChanged || isLoading}
          >
            Salvar
          </Button>
        </Flex>
      </form>
    </Stack>
  );
}

export default Pixel;
