import styles from './styles.module.css';

function CategoriesSkeleton() {
  return (
    <div className={styles.containerSkeleton}>
      <div className={styles.imageSkeleton} />
      <div className={styles.contentSkeleton}>
        <span className={styles.titleSkeleton} />
      </div>
    </div>
  );
}

export default CategoriesSkeleton;
