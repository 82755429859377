import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import Pagination from '../../../../../components/Pagination';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import {
  IInitialFilters,
  IQuestionLibraryQuestionResult,
} from '../../../Courses/CourseManager/ModulesManager/QuestionLibrary/types';
import Question from '../../QuestionLibrary/types/Question';
import DeleteConfirmModal from '../DeleteConfirmModal';
import QuestionCardView from '../QuestionCardView';

const initialFilters = {
  search: '',
};

export default function TabOfFreeQuestions({ tabIndex }: { tabIndex: number }) {
  const [searchFiltered, setSearchFiltered] = useState('');
  const [filters, setFilters] = useState<IInitialFilters>(initialFilters);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [questionsFiltered, setQuestionsFiltered] = useState<Question[]>([]);
  const [timer, setTimer] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState(0);
  const [questionIdToDelete, setQuestionIdToDelete] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryParams = new URLSearchParams();

  const addQueryParam = (paramName: string, paramValue: string | undefined) => {
    if (paramValue) {
      queryParams.append(paramName, paramValue);
    }
  };

  addQueryParam('page', currentPage.toString());
  addQueryParam('search', filters.search);
  addQueryParam('isExternal', 'true');

  const searchParams = queryParams.toString();
  const url = searchParams
    ? `/question-library/question?${searchParams}`
    : '/question-library/question';

  const {
    data: questionLibraryQuestionData,
    loading: isLoading,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<IQuestionLibraryQuestionResult>>({
    url,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  useEffect(() => {
    if (tabIndex === 0) {
      fetchData();
    }
  }, [fetchData, tabIndex]);

  useEffect(() => {
    if (questionLibraryQuestionData?.data?.data) {
      setQuestions(questionLibraryQuestionData?.data?.data);
      setQuestionsFiltered(questionLibraryQuestionData?.data?.data);
      setPageCount(
        questionLibraryQuestionData?.data?.total / questionLibraryQuestionData?.data?.per_page
      );
    } else {
      setQuestionsFiltered([]);
    }
  }, [
    questionLibraryQuestionData?.data?.data,
    questionLibraryQuestionData?.data?.total,
    questionLibraryQuestionData?.data?.per_page,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        setFilters({ ...filters, search: searchFiltered });

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 700);

    return () => clearTimeout(timeout);
  }, [filters, searchFiltered, timer]);

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchFiltered(value);
  }

  function handlePageChange({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }

  function removeQuestionId(id: number) {
    if (id) {
      setQuestionIdToDelete(id);
      onOpen();
    }
  }

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    url: `/question-library/${questionIdToDelete}/question/remove-external-question`,
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Questão removida com sucesso!',
      callback() {
        const questionRemove = questions.filter(question => question.id !== questionIdToDelete);
        setQuestions(questionRemove);
        setQuestionsFiltered(questionRemove);
        if (questionsFiltered.length === 1) {
          setCurrentPage(1);
        }
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
    onClose();
  }

  return (
    // <TabPanel padding={0}>
    <>
      <Flex>
        <InputGroup size="sm" width="320px" marginBottom="18px" hidden={!questions.length}>
          <InputLeftElement pointerEvents="none" ml={2}>
            <BiSearch color="#2021235C" size={20} />
          </InputLeftElement>
          <Input
            value={searchFiltered}
            onChange={handleSearchChange}
            borderRadius={6}
            focusBorderColor="orange.500"
            placeholder="Buscar por questão"
            color="#20212380"
            pl="2.5rem"
            borderWidth="1px"
            borderColor="gray.300"
            _placeholder={{
              color: 'blackAlpha.500',
            }}
          />
        </InputGroup>
      </Flex>

      {questionsFiltered.length > 0 ? (
        <QuestionCardView questions={questionsFiltered} removeQuestionId={removeQuestionId} />
      ) : (
        ''
      )}

      {isLoading && (
        <Stack direction="row" width="full" justifyContent="center" margin={30}>
          <Spinner size="lg" color="orange.500" />
        </Stack>
      )}

      {questions.length && !questionsFiltered.length ? (
        <Text
          color="rgba(0, 0, 0, 0.49)"
          fontSize="18px"
          textAlign="center"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
          marginY="30px"
        >
          Nenhum resultado encontrado para "{searchFiltered}".
        </Text>
      ) : (
        ''
      )}

      <Text
        hidden={questions.length > 0}
        color="rgba(0, 0, 0, 0.49)"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="400"
        lineHeight="normal"
        marginY="30px"
      >
        Adicione as questões clicando no botão “Adicionar questões”.
      </Text>

      {questionsFiltered?.length > 0 && (
        <Box marginY={{ base: '15px', md: '40px', lg: '40px' }}>
          <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
        </Box>
      )}

      <DeleteConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    </>
    // </TabPanel>
  );
}
