import { useHistory, useRouteMatch } from 'react-router-dom';

import { Button as ChakraButton } from '@chakra-ui/react';
import { ButtonProps } from '@chakra-ui/react';
import { GrAdd as AddIcon } from 'react-icons/gr';


function Button({ ...rest }: ButtonProps) {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <ChakraButton
      width={{ base: 'full', md: 'auto', lg: 'auto' }}
      size="sm"
      fontSize="sm"
      fontWeight="semibold"
      leftIcon={<AddIcon />}
      colorScheme="primary"
      color="secondary.500"
      onClick={() => history.push(`${match.path}/new-pixel`)}
      {...rest}
    >
      {rest.title}
    </ChakraButton>
  );
}

export default Button;
