import classicMemberAreaIllustrationMobile from '../../../../assets/images/classic-member-area-mobile.png';
import classicMemberAreaIllustration from '../../../../assets/images/classic-member-area.png';
import netflixMemberAreaIllustrationMobile from '../../../../assets/images/netflix-member-area-mobile.png';
import netflixMemberAreaIllustration from '../../../../assets/images/netflix-member-area.png';
import { MemberArea } from '..';
import { Box, Image, Skeleton, useBreakpointValue } from '@chakra-ui/react';

interface MemberAreaImageProps {
  memberAreaType: MemberArea;
}

const memberAreaImages = {
  netflix: {
    desktop: netflixMemberAreaIllustration,
    mobile: netflixMemberAreaIllustrationMobile,
  },
  proluno: {
    desktop: classicMemberAreaIllustration,
    mobile: classicMemberAreaIllustrationMobile,
  },
};

export default function MemberAreaImage({ memberAreaType }: MemberAreaImageProps) {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const selectedImage = memberAreaImages[memberAreaType];

  return (
    <Box flex={2} boxShadow="0px 4px 4px 0px #00000040" borderRadius="0.625rem" overflow="hidden">
      <Skeleton isLoaded={!!memberAreaType}>
        <Image
          src={!isMobile ? selectedImage?.desktop : selectedImage?.mobile}
          boxSize="full"
          minHeight="19.5rem"
          objectFit="cover"
        />
      </Skeleton>
    </Box>
  );
}
