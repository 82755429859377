import {
  Box,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Progress,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import prettyBytes from 'pretty-bytes';
import { useEffect, useState } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { BsPlayFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { UpdateStatusTranscodeType, Video } from '..';
import VideoLibraryAPI from '../../../../../api/VideoLibrary';
import Toast from '../../../../../components/Toast';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { convertSecondsToHMS } from '../../../../../helpers/convertSecondsToHMS';
import { ModalDeleteVideo } from '../ModalDeleteVideo';
import ThumbnailDefault from '../ThumbnailDefault';

type VideoItemProps = {
  video: Video;
  updateStatusTranscode: ({ id, transcode, thumbnail }: UpdateStatusTranscodeType) => void;
  onDeleteVideoList: (id: number) => void;
  onOpenPreviewVideo: (id: number) => void;
};

function getStatus({ transcode, archive, status }) {
  if (status.state === 'starting' && status.progress === 0) {
    return <Text>Na fila para otimização</Text>;
  }

  if (status.state === 'active') {
    return <Text>Otimizando</Text>;
  }

  if (archive) {
    return <Text>Arquivado</Text>;
  }

  switch (transcode) {
    case 'complete':
      return <Text>Ativo</Text>;
    case 'error':
      return (
        <Text fontWeight="bold" color="red">
          Erro no processamento
        </Text>
      );
    default:
      return <Text>Processando</Text>;
  }
}

function VideoItem({
  video,
  updateStatusTranscode,
  onDeleteVideoList,
  onOpenPreviewVideo,
}: VideoItemProps) {
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState({
    state: '',
    progress: 0,
    time_left: 0,
  });

  useEffect(() => {
    let interval;

    async function getStatusTranscode() {
      VideoLibraryAPI.getTranscodeStatus(video.id)
        .then(({ data: transcodeData }) => {
          if (transcodeData?.status) {
            setStatus(transcodeData.status);
          }

          if (
            transcodeData?.transcode?.status === 'complete' ||
            transcodeData?.transcode?.status === 'error'
          ) {
            updateStatusTranscode({
              id: video.id,
              transcode: transcodeData.transcode.status,
              thumbnail: transcodeData.pictures?.base_link,
              duration: transcodeData.duration,
              name: video.name,
            });
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(ErrorResponse(error));
        })
        .finally(() => {
          if (video?.transcode === 'complete' && interval) {
            clearInterval(interval);
          }
        });
    }

    interval = setInterval(() => {
      if (video.transcode === 'complete' || video.transcode === 'error') {
        clearInterval(interval);
      } else {
        getStatusTranscode();
      }
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, [updateStatusTranscode, video.id, video.name, video.thumbnail, video.transcode]);

  async function downloadVideo() {
    try {
      const { data: videoData } = await VideoLibraryAPI.download(video?.id);

      if (videoData) {
        window.open(videoData);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function deleteVideo(id) {
    try {
      setIsLoading(true);

      await VideoLibraryAPI.delete(id);

      onDeleteVideoList(id);
      Toast('Vídeo excluído com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Tr key={nanoid()}>
        <Td padding={0}>
          <Box padding={3}>
            <Box overflow="hidden" zIndex={1}>
              {video.thumbnail ? (
                <Flex
                  width="98px"
                  height="56px"
                  _hover={{ cursor: 'pointer' }}
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => onOpenPreviewVideo(video.id)}
                  backgroundImage={`url(${video.thumbnail})`}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  position="relative"
                >
                  <Icon
                    as={BsPlayFill}
                    position="absolute"
                    height="35px"
                    width="35px"
                    zIndex={5}
                    color="textColor"
                    filter="drop-shadow(2px 2px 2px #666666)"
                  />
                </Flex>
              ) : (
                <ThumbnailDefault width="100%" height="auto" />
              )}
            </Box>
          </Box>
        </Td>

        <Td data-label="Name:" wordBreak="break-word">
          <Text>{video.name}</Text>
        </Td>

        <Td data-label="Tamanho:">{video.size ? prettyBytes(video.size) : 0}</Td>
        <Td data-label="Duração:">{convertSecondsToHMS(video.duration)}</Td>

        <Td data-label="Data:">{dayjs(video.uploadAt).format('DD/MM/YYYY')}</Td>

        <Td data-label="Status:">
          <HStack
            alignItems={
              video.transcode === 'complete' || video.transcode === 'error'
                ? 'self-start'
                : 'center'
            }
          >
            {video.transcode === 'complete' &&
              getStatus({
                transcode: video.transcode,
                archive: video.archive,
                status: status,
              })}

            {video.transcode !== 'complete' && status.progress < 100 && (
              <Stack direction="row" alignItems="center" height="10px" spacing={2}>
                <Progress
                  hidden={video.transcode === 'complete' || video.transcode === 'error'}
                  color="green.400"
                  width="86px"
                  height="10px"
                  borderRadius={6}
                  isIndeterminate={!status.progress}
                  value={status.progress}
                  size="45px"
                  colorScheme="orange"
                />
              </Stack>
            )}
          </HStack>
        </Td>

        <Td>
          <Menu>
            <MenuButton
              bgColor="rgba(32, 33, 35, 0.12)"
              width="27px"
              height="24px"
              borderRadius={6}
            >
              <Icon as={BiDotsHorizontalRounded} boxSize={5} />
            </MenuButton>
            <MenuList>
              <MenuItem
                isDisabled={video.transcode !== 'complete'}
                onClick={() => history.push(`/video-library/${video.id}`)}
              >
                Editar
              </MenuItem>
              <MenuItem isDisabled={video.transcode !== 'complete'} onClick={downloadVideo}>
                Download
              </MenuItem>
              <MenuDivider />
              <MenuItem color="red" onClick={onOpen}>
                Excluir
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      </Tr>

      <ModalDeleteVideo
        key={video.id}
        videoId={video.id}
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
        deleteVideo={deleteVideo}
      />
    </>
  );
}

export default VideoItem;
