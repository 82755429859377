import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BiImageAlt, BiPlayCircle } from 'react-icons/bi';
import { IoChevronBackOutline } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import VideoLibraryAPI from '../../../../api/VideoLibrary';
import DotFalling from '../../../../components/Loadings/DotsFalling';
import SelectImage from '../../../../components/SelectImage';
import Toast from '../../../../components/Toast';
import { useVideoLibrary } from '../../../../contexts/VideoLibraryContext';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { Video } from '../VideoList';
import ThumbnailDefault from '../VideoList/ThumbnailDefault';

const MAX_SIZE_THUMBNAIL = 3145728;

const initialStateVideo = {
  id: 0,
  name: '',
  thumbnail: '',
  size: 0,
  duration: 0,
  uploadAt: '',
  status: '',
  download: false,
  archive: false,
  downloadLink: '',
  description: '',
  link: '',
  player: '',
  transcode: '',
  uri: '',
};

export default function EditVideo() {
  const { id }: { id: string } = useParams();

  const { editVideoLibrary } = useVideoLibrary();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [video, setVideo] = useState<Video>(initialStateVideo);
  const [thumbnail, setThumbnail] = useState(initialStateVideo.thumbnail);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    async function getVideo(videoId: number) {
      try {
        const { data: videoData } = await VideoLibraryAPI.show(videoId);

        if (videoData) {
          setThumbnail(videoData.thumbnail);
          setVideo(videoData);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setLoading(false);
      }
    }

    getVideo(parseInt(id));
  }, [id]);

  function handlePhotoChange(acceptedFiles: any[]) {
    const file = acceptedFiles[0];

    if (file) {
      if (file.size > MAX_SIZE_THUMBNAIL) {
        Toast('O tamanho máximo do arquivo é de 3MB.', 'warn');
        return;
      }
      setVideo({ ...video, thumbnail: file });
      setThumbnail(URL.createObjectURL(file));
      setEdited(true);
    }
  }

  function handlerChange(event) {
    const { value, name, checked, type } = event.target;

    switch (type) {
      case 'checkbox':
        setVideo({ ...video, [name]: checked });
        break;
      case 'button':
        setVideo({ ...video, archive: !video.archive });
        break;
      default:
        setVideo({ ...video, [name]: value });
        break;
    }

    setEdited(true);
  }

  async function onSubmit(event) {
    event.preventDefault();

    try {
      await editVideoLibrary(id, video, thumbnail);
      setEdited(false);
      Toast('Vídeo atualizado com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  return loading ? (
    <div className="container d-flex justify-content-center mt-5">
      <DotFalling />
    </div>
  ) : (
    <>
      <Stack direction="row" width="full" alignItems="center" spacing={1}>
        <Box as={Link} to="/video-library">
          <IoChevronBackOutline size={20} />
        </Box>
        <Heading fontSize="24px" fontWeight={600}>
          Editar informações do vídeo
        </Heading>
      </Stack>

      <Box as="form" onSubmit={onSubmit} marginY={{ base: 0, md: 10, lg: 10 }} width="full">
        <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} spacing={5} marginY={5}>
          <Stack spacing={7}>
            <FormControl>
              <FormLabel>Título do vídeo</FormLabel>
              <Input
                name="name"
                autoComplete="off"
                minLength={5}
                size="sm"
                borderRadius={5}
                placeholder="Digite aqui"
                focusBorderColor="orange.500"
                color="#20212380"
                value={video.name}
                minW={{ base: 'full', md: 550, lg: 550 }}
                onChange={handlerChange}
                isRequired
              />
            </FormControl>

            <Box
              overflow="hidden"
              zIndex={1}
              borderRadius={5}
              minW={{ base: '345px', md: 550, lg: 550 }}
              height={{ base: '197.14px', md: 344, lg: 344 }}
            >
              {thumbnail ? (
                <Flex
                  minW={{ base: '345px', md: 550, lg: 550 }}
                  height={{ base: '197.14px', md: 344, lg: 344 }}
                  _hover={{ cursor: 'pointer' }}
                  justifyContent="center"
                  alignItems="center"
                  onClick={onOpen}
                  backgroundImage={`url(${thumbnail})`}
                  backgroundSize="cover"
                  backgroundPosition="center"
                  position="relative"
                >
                  <Icon
                    as={BiPlayCircle}
                    position="absolute"
                    width={{ base: '71.16px', md: '125px', lg: '125px' }}
                    height={{ base: '71.16px', md: '125px', lg: '125px' }}
                    zIndex={5}
                    color="rgba(255, 255, 255, 0.5)"
                    filter="drop-shadow(2px 2px 2px #666666)"
                  />
                </Flex>
              ) : (
                <ThumbnailDefault width={602} height={344} />
              )}
            </Box>
          </Stack>

          <Card
            minW={{ base: '345px', md: 413, lg: 413 }}
            height={{ base: '592px', md: 436, lg: 436 }}
            boxShadow="3px 3px 3px 3px rgba(0, 0, 0, 0.15)"
            padding={15}
          >
            <Stack spacing={5}>
              <Stack spacing={2}>
                <Heading fontSize="20px" fontWeight={500}>
                  Configurações:
                </Heading>

                <Text fontSize="16px" fontWeight={500}>
                  Liberar para download
                </Text>

                <Stack spacing={5}>
                  <Text fontSize="13px" color="#20212380" fontWeight={400}>
                    Essa função é usada para liberar ou não o download deste vídeo para os seus
                    alunos.
                  </Text>
                  <Text fontSize="13px" color="#20212380" fontWeight={400} fontStyle="italic">
                    OBS: todos os vídeos já são, como padrão, configurados para não autorizar o
                    download.
                  </Text>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Switch size="sm" isDisabled checked={video.download} colorScheme="orange" />
                  <Text fontSize="14px" color="#20212350" cursor="not-allowed">
                    Ativar download
                  </Text>
                </Stack>
              </Stack>

              <Stack spacing={2}>
                <Text fontSize="16px" fontWeight={500}>
                  Capa do vídeo
                </Text>

                <Stack
                  direction={{ base: 'column', md: 'row', lg: 'row' }}
                  spacing={{ base: 5, md: 2, lg: 2 }}
                  alignItems="center"
                  width="full"
                >
                  <Stack spacing={2}>
                    <Flex
                      minW={{ base: '319px', md: '214px', lg: '214px' }}
                      minH={{ base: '182.5px', md: '123px', lg: '123px' }}
                      borderRadius={5}
                      bgColor="#F6F5F5"
                      border={thumbnail ? 'none' : '1px dashed #AEADAD'}
                      justifyContent="center"
                      alignItems="center"
                      backgroundImage={`url(${thumbnail})`}
                      backgroundSize="cover"
                      backgroundPosition="center"
                      position="relative"
                    >
                      {!thumbnail && (
                        <Icon
                          as={BiImageAlt}
                          position="absolute"
                          height="25px"
                          width="25px"
                          zIndex={5}
                          color="rgba(32, 33, 35, 0.5)"
                        />
                      )}
                    </Flex>

                    <SelectImage
                      onDrop={handlePhotoChange}
                      isDisabled={false}
                      hiddenBox={true}
                      width={{ base: '319px', md: '214px', lg: '214px' }}
                      height={27}
                      size="sm"
                    />
                  </Stack>

                  <Stack
                    spacing={3}
                    paddingX={2}
                    fontWeight={500}
                    color="#20212380"
                    fontSize="12.6px"
                    justifyContent="center"
                    width="full"
                  >
                    <Text>
                      A imagem deve ter as dimensões 1920x1080, e as extensões válidas são: JPG, PNG
                      e JPEG.
                    </Text>
                    <Text>OBS: o tamanho máximo é de 5MB.</Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Stack>

        <Stack
          width="full"
          direction="row"
          spacing={3}
          justifyContent={{ base: 'center', md: 'flex-end', lg: 'flex-end' }}
          alignItems="center"
        >
          <Button
            size="sm"
            as={Link}
            to="/video-library"
            width={{ base: 'full', md: '100px', lg: '100px' }}
          >
            Cancelar
          </Button>
          <Button
            size="sm"
            width={{ base: 'full', md: '100px', lg: '100px' }}
            colorScheme="orange"
            type="submit"
            isDisabled={!edited}
          >
            Salvar
          </Button>
        </Stack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent padding={5}>
          <ModalCloseButton />

          <ModalHeader textAlign="center">{video?.name}</ModalHeader>

          <ModalBody>
            <Box
              as="iframe"
              width="full"
              height={300}
              src={video?.player}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title={video?.name}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
