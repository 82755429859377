import {
  Button,
  Card,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Heading } from '../../../../../components/Heading';
import DataLoading from '../../../../../components/Loadings/DataLoading';
import Toast from '../../../../../components/Toast';
import { useCourse } from '../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import './style.css';
import { useState } from 'react';

export default function Publish() {
  const { course, updateCourse, loading } = useCourse();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState(course.status);

  async function handlerStatus(event) {
    event.preventDefault();

    try {
      await updateCourse({ status });
      onClose();
      Toast('Curso atualizado com sucesso', 'success');
    } catch (error) {
      onClose();
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function BeforeHandleSubmit(event) {
    event.preventDefault();

    if (course.status === 'INATIVO') {
      return onOpen();
    }
    handlerStatus(event);
  }

  return (
    <VStack width="full" padding="15px">
      <Card as="form" padding="15px" maxW="700px" onSubmit={BeforeHandleSubmit}>
        <VStack align="start">
          <Heading as="h4" fontSize="2xl" fontWeight="600">
            Publicar
          </Heading>

          <Text>Status de publicação do curso</Text>
          <DataLoading className="mt-3" loading={loading}>
            <VStack align="start" mt={5}>
              <RadioGroup
                defaultValue={course.status}
                onChange={setStatus}
                value={status}
                colorScheme="default"
              >
                <VStack align="start" spacing={5}>
                  <VStack align="start">
                    <Radio value="EM_EDICAO">
                      <Text fontWeight="600" color="gray.500">
                        Em Edição
                      </Text>
                    </Radio>

                    <Text fontWeight={400} fontSize="sm">
                      <Text as="span" fontWeight={600}>
                        {' '}
                        Os alunos não podem comprar ou se inscrever neste curso.
                      </Text>{' '}
                      Para os alunos que já estão matriculados o acesso ainda irá permanecer.
                    </Text>
                  </VStack>

                  <VStack align="start">
                    <Radio value="NAO_LISTADO">
                      <Text fontWeight="600" color="gray.500">
                        Não Listado
                      </Text>
                    </Radio>

                    <Text fontWeight={400} fontSize="sm">
                      O produto não será listado na página principal e não aparecerá nas buscas.
                      Apenas quem tiver o link da página do produto poderá acessa-lo para
                      compra/inscrição.
                    </Text>
                  </VStack>

                  <VStack align="start">
                    <Radio value="ATIVO">
                      <Text fontWeight="600" color="gray.500">
                        Ativo
                      </Text>
                    </Radio>

                    <Text fontWeight={400} fontSize="sm">
                      Os alunos podem comprar, se inscrever e acessar o conteúdo deste curso. Para
                      os alunos que estão matriculados, este curso aparecerá em seu Painel do Aluno.
                    </Text>

                    <Text>
                      <Text as="b">Importante:</Text> Você só poderá publicar um produto pago após
                      cadastrar seus dados bancários.{' '}
                      <Link to="/profile/bank-accounts">Acessar dados bancários</Link>
                    </Text>
                  </VStack>

                  <VStack align="start">
                    <Radio value="INATIVO">
                      <Text fontWeight="600" color="gray.500">
                        Inativo
                      </Text>
                    </Radio>

                    <Text>
                      Os alunos não podem comprar ou se inscrever neste produto. Para os alunos que
                      já estão matriculados, este produto{' '}
                      <Text as="strong">não ficará mais disponível na área do aluno.</Text>
                    </Text>
                  </VStack>
                </VStack>
              </RadioGroup>
            </VStack>
          </DataLoading>

          <HStack width="full" justifyContent="flex-end" mt={5}>
            <Button type="submit" size="sm" colorScheme="default" isDisabled={loading}>
              Salvar
            </Button>
          </HStack>
        </VStack>
      </Card>

      <Modal isCentered onClose={onClose} isOpen={isOpen} size="lg" motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton style={{ outline: 'none' }} _focus="none" />
          <ModalBody>
            <HStack spacing={2} p={10} alignItems="center" justifyContent="center" w="100%">
              <Heading fontSize="2xl">
                Ao alterar para o status INATIVO, todos os alunos irão perder o acesso ao produto,
                está ciente dessa decisão?
              </Heading>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={2} p={3} alignItems="center" justifyContent="center" w="100%">
              <Button
                w={120}
                colorScheme="gray"
                onClick={handlerStatus}
                style={{ outline: 'none' }}
                isDisabled={loading}
                _focus="none"
              >
                Sim
              </Button>
              <Button
                w={120}
                colorScheme="red"
                onClick={onClose}
                style={{ outline: 'none' }}
                _focus="none"
                isDisabled={loading}
              >
                Não
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
