import { Accordion, AccordionItem, Text } from '@chakra-ui/react';
import Module from './Module';
import { Link } from 'react-router-dom';
import { useCourseStudent } from '../../../../../../contexts/CourseStudentContextV2';
import { sortByOrder } from '../../../../../../helpers/SortHelper';
import { IContent, IModuleListProps } from '../../types';

interface ModuleProps {
  id: number;
  name: string;
  order: number;
  contents: IContent[];
  releaseDateModule?: string;
}

export function ModuleList({ modulesFilters, highlightColor }: IModuleListProps) {
  const { course, currentModule } = useCourseStudent();

  let openIndex: number | null = null;

  const moduleOpenIndex = modulesFilters.findIndex(module => module.id === currentModule?.id) || 0;

  if (moduleOpenIndex !== -1) {
    openIndex = moduleOpenIndex;
  } else {
    openIndex = 0;
  }

  const showAccordion = openIndex !== null;

  return (
    <>
      {showAccordion && (
        <Accordion allowToggle defaultIndex={[openIndex]}>
          {modulesFilters?.sort(sortByOrder).map((module: ModuleProps) => (
            <Module
              key={`module-${module.id}`}
              name={module.name}
              contents={module.contents}
              courseId={course?.id}
              moduleId={module.id}
              highlightColor={highlightColor}
              releaseDate={module.releaseDateModule}
            />
          ))}

          {course?.certificate && course?.certificate.enable && (
            <AccordionItem
              border="none"
              as={Link}
              to={`/members-area/v2/course/${course?.id}/module/${currentModule?.id}/content-view/certificate`}
            >
              <Text
                noOfLines={1}
                color="#FFF"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="normal"
              >
                Certificado
              </Text>
            </AccordionItem>
          )}
        </Accordion>
      )}
    </>
  );
}
