import { useDisclosure, useToast } from '@chakra-ui/react';
import path from 'path';
import { FormEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../../../helpers/UploadHelper';
import useFetch from '../../../../../../hooks/useFetch';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../../utils/scrollToInvalidInput';
import { FIVE_MEGABYTES } from './constants';
import schemaValidator from './schemaValidator';

import { RecurrencePlanCustomizationForm, usePlanManagementEditCustomizationProps } from './types';

interface ImageFile {
  preview: string;
  file?: File;
}

export default function usePlanManagementEditCustomization({
  planData,
}: usePlanManagementEditCustomizationProps) {
  const [topImage, setTopImage] = useState<ImageFile>(null);
  const [bottomImage, setBottomImage] = useState<ImageFile>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageFieldToDelete, setImageFieldToDelete] = useState('');

  const { planId } = useParams<{ planId: string }>();
  const { push } = useHistory();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { fetchData: deleteImage, loading: isDeletingImage } = useFetch({
    method: 'patch',
    url: `/recurrence-plan/${planId}/remove-image`,
    authenticated: true,
    autoFetch: false,
    data: { imageToDelete: imageFieldToDelete },
  });

  const {
    form,
    setForm,
    onChanged: hasChange,
    setOnChanged: setHasChange,
    handleCancel,
  } = useHandleChange<RecurrencePlanCustomizationForm>(
    { userFormForPurchase: [] },
    !!planData && {
      userFormForPurchase: planData.userFormForPurchase,
      topImage: planData.topImage,
      bottomImage: planData.bottomImage,
    }
  );

  function handleChange(name: string, value: Array<string | number>) {
    setForm(prevForm => ({
      ...prevForm,
      [name]: value,
    }));

    setHasChange(true);
  }

  function handleCancelButtonClick() {
    if (!hasChange) {
      return push('/recurrence/plan-management');
    }

    handleCancel();
  }

  async function uploadFile(file: File) {
    return await UploadHelper.upload(file, 'banners');
  }

  function handleUploadError(error) {
    toast({
      title: ErrorResponse(error),
      status: 'error',
    });
  }

  async function handleDropFile(name: 'topImage' | 'bottomImage', acceptedFiles) {
    try {
      setHasChange(true);
      setIsUploading(true);

      const [file] = acceptedFiles;

      if (file.size > FIVE_MEGABYTES) {
        return toast({
          title: 'Imagem muito grande!',
          description: 'O tamanho máximo deve ser igual ou inferior a 5 MB.',
          status: 'error',
          position: 'top',
        });
      }

      const preview = URL.createObjectURL(file);

      const { newFileName } = await uploadFile(file);

      setForm({ ...form, [name]: newFileName });

      switch (name) {
        case 'topImage':
          setTopImage({ preview, file });
          break;

        case 'bottomImage':
          setBottomImage({ preview, file });
          break;

        default:
          break;
      }
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
    }
  }

  function handleDropRejected() {
    toast({
      title: 'Arquivo inválido!',
      description: 'Formatos suportados: JPG, PNG, JPEG',
      status: 'error',
    });
  }

  function handleSelectNewImage(name: 'topImage' | 'bottomImage') {
    setHasChange(true);

    setForm({ ...form, [name]: null });
  }

  function handleOpenImageDeleteModal(imageField: 'topImage' | 'bottomImage') {
    onOpen();
    setImageFieldToDelete(imageField);
  }

  async function handleDeleteImage() {
    try {
      await deleteImage();
      toast({
        title: 'Imagem excluída com sucesso!',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
      });
    }

    setForm({ ...form, [imageFieldToDelete]: null });

    onClose();
  }

  const payload = { ...form };

  payload.topImage && (payload.topImage = path.basename(payload.topImage));
  payload.bottomImage && (payload.bottomImage = path.basename(payload.bottomImage));

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: payload,
    url: `/recurrence-plan/${planId}/customization`,
    method: 'patch',
    authenticated: true,
    schemaValidator,
    onSuccess: {
      redirect: {
        to: `/recurrence/plan-management/edit/${planId}/page-thanks`,
      },
    },
  });

  const parsedValidation = formValidation as any;

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  return {
    form,
    isSubmitting,
    formValidation: parsedValidation,
    topImage,
    bottomImage,
    isUploading,
    isDeletingImage,
    isOpen,
    onClose,
    handleOpenImageDeleteModal,
    handleSelectNewImage,
    handleDeleteImage,
    handleChange,
    handleCancelButtonClick,
    handleDropRejected,
    handleDropFile,
    onSubmit,
  };
}
