import { ChangeEvent } from 'react';

import {
  FormLabel,
  FormControl,
  Select as ChakraSelect,
  InputGroup,
  SelectProps,
  Stack,
  Box,
  Image,
  Text,
} from '@chakra-ui/react';

interface ISelectProps extends SelectProps {
  label?: string;
  flag?: string;
  labelWeight?: string;
  description?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  children?: React.ReactNode;
}

function Select({
  id,
  name,
  label,
  labelWeight = 'medium',
  flag,
  description,
  onChange,
  value,
  children,
  ...rest
}: ISelectProps) {
  const idSelect = id ?? name;

  return (
    <FormControl id={idSelect}>
      <Stack spacing="1">
        {label && (
          <FormLabel marginBottom="0" fontSize="sm" fontWeight={labelWeight} htmlFor={idSelect}>
            {label}
          </FormLabel>
        )}

        {description && (
          <Text
            marginTop="0rem !important"
            fontSize="sm"
            fontWeight="500"
            color="rgba(32, 33, 35, 0.5)"
          >
            {description}
          </Text>
        )}

        <InputGroup position="relative">
          {flag && (
            <Box position="absolute" top="0.7rem" left="1rem">
              <Image src={flag} alt="Bandeira do país" width="1.75rem" />
            </Box>
          )}

          <ChakraSelect
            id={idSelect}
            name={name}
            onChange={onChange}
            value={value}
            size="md"
            variant="outline"
            color="blackAlpha.500"
            fontSize="md"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="borderInput.100"
            borderRadius="md"
            outline="2px solid transparent"
            outlineOffset="2px"
            focusBorderColor="primary.400"
            _placeholder={{ color: 'blackAlpha.500' }}
            _focus={{
              zIndex: 1,
              borderColor: '#ec7117',
            }}
            _hover={{
              '&:focus': {
                borderColor: 'none',
              },
            }}
            {...rest}
          >{children}</ChakraSelect>
        </InputGroup>
      </Stack>
    </FormControl>
  );
}

export default Select;
