import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Link, useRouteMatch } from 'react-router-dom';

interface WebhookTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function WebhookTypeModal({ isOpen, onClose }: WebhookTypeModalProps) {
  const match = useRouteMatch();

  const [type, setType] = useState<string>('products');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent padding="15px">
        <ModalHeader>
          <Box display="flex" alignItems="center" justifyContent="center" color="primary.500">
            <RiErrorWarningFill size={48} />
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack width="full" spacing={4}>
            <Text fontSize="18px" fontWeight="500" lineHeight="28px" textAlign="center">
              Selecione o tipo de Webhook que deseja criar.
            </Text>

            <Box width="full" px={8}>
              <Select onChange={e => setType(e.target.value)}>
                <option value="products">Produtos</option>
                <option value="recurrence">Recorrência (Assinatura)</option>
              </Select>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <HStack width="full">
            <Button width="full" size="sm" onClick={onClose}>
              Cancelar
            </Button>
            <Button
              width="full"
              size="sm"
              as={Link}
              colorScheme="primary"
              color="secondary.500"
              to={`${match.url}/${type}`}
            >
              Continuar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
