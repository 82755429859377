import { AspectRatio, Box, Heading, Stack } from '@chakra-ui/react';
import { useCourseStudent } from '../../../../../contexts/CourseStudentContextV2';
import { PagingContents } from '../../../components/PagingContents';
import FileDownload from '../../../../../components/FileDownload';

function ViewMultimedia() {
  const { highlightColor, currentContent: content } = useCourseStudent();

  return (
    <Box>
      <Heading
        color="#FFF"
        fontSize={{ base: '18px', md: '24px', lg: '24px' }}
        fontWeight="500"
        paddingBottom={{ base: '12px', lg: '20px' }}
      >
        {content?.title}
      </Heading>

      <Stack spacing={{ base: '9px', lg: '16px' }} marginTop={{ base: '-5', lg: '-6' }}>
        <AspectRatio maxW="full" ratio={16 / 9}>
          <iframe
            src={content && content?.link}
            title={content?.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </AspectRatio>
      </Stack>

      <Box marginTop={{ base: '10px', lg: '29px' }}>
        <PagingContents />
      </Box>

      {content?.content && (
        <Box
          color="#FFF"
          marginY={{ base: '22px', lg: '30px' }}
          fontSize={{ base: '14px', md: '18px', lg: '18px' }}
          dangerouslySetInnerHTML={{ __html: content?.content }}
          className="ql-editor"
          sx={{
            iframe: {
              width: '100%',
              aspectRatio: '16/9',
            },
            a: { color: '#06c', textDecoration: 'underline' },
            blockquote: { borderLeft: '5px solid #ccc', paddingLeft: '30px' }, // Estilo para citação
          }}
        />
      )}

      {content?.download && (
        <Box marginY={{ base: '22px', lg: '30px' }}>
          <FileDownload name={content?.download} highlightColor={highlightColor} />
        </Box>
      )}
    </Box>
  );
}

export default ViewMultimedia;
