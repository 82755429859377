import Joi from 'joi';
import { cnpj, cpf } from 'cpf-cnpj-validator';

export type BankAccountSchemaType = {
  bankCode: string;
  agencia: string;
  agenciaDv: string;
  conta: string;
  contaDv: string;
  type: string;
  documentType: string;
  documentNumber: string;
  legalName: string;
  ddi: string;
  ddd: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  neighborhood: string;
  streetAddress: string;
  streetNumber: string;
  complementary: string;
  zipCode: string;
  birthday: string;
  monthlyIncome: number;
  annualRevenue: number;
  professionalOccupation: string;
  companyName: string;
  tradingName: string;
  responsibilityDocument: string;
};

function validateCPF(value: string, helpers) {
  if (!cpf.isValid(value)) {
    return helpers.error('any.invalid');
  }

  return value;
}

function validateCNPJ(value: string, helpers) {
  if (!cnpj.isValid(value)) {
    return helpers.error('any.invalid');
  }

  return value;
}

export const createBankAccountSchema = Joi.object<BankAccountSchemaType>().keys({
  bankCode: Joi.string().required().messages({
    'any.required': 'O código do banco é obrigatório.',
    'string.empty': 'O código do banco não pode ser vazio.',
    'string.base': 'O código do banco não pode ser vazio.',
  }),
  agencia: Joi.string().required().messages({
    'any.required': 'A agência é obrigatória.',
    'string.empty': 'A agência não pode ser vazia.',
    'string.base': 'A agência não pode ser vazia.',
  }),
  agenciaDv: Joi.string().allow('', null),
  conta: Joi.string().min(5).max(13).required().messages({
    'any.required': 'O número da conta é obrigatório.',
    'string.empty': 'O número da conta não pode ser vazio.',
    'string.base': 'O número da conta não pode ser vazio.',
    'string.min': 'Mínimo 5 dígitos.',
    'string.max': 'Máximo 5 dígitos.',
  }),
  contaDv: Joi.string().required().messages({
    'any.required': 'O dígito da conta é obrigatório.',
    'string.empty': 'O dígito da conta não pode ser vazio.',
    'string.base': 'O dígito da conta não pode ser vazio.',
  }),
  type: Joi.string().required().messages({
    'any.required': 'O tipo da conta é obrigatório.',
    'string.empty': 'O tipo da conta não pode ser vazio.',
    'string.base': 'O tipo da conta não pode ser vazio.',
  }),
  legalName: Joi.string().required().messages({
    'any.required': 'Informe o responsável pela conta.',
    'string.empty': 'Informe o responsável pela conta.',
    'string.base': 'Informe o responsável pela conta.',
  }),
});

export const createRecipientSchema = createBankAccountSchema.keys({
  documentType: Joi.string().required().messages({
    'any.required': 'O tipo do documento é obrigatório.',
    'string.empty': 'O tipo do documento não pode ser vazio.',
    'string.base:': 'O tipo do documento não pode ser vazio.',
  }),
  documentNumber: Joi.when('documentType', {
    is: 'cpf',
    then: Joi.string().custom(validateCPF, 'validateCPF').messages({
      'string.empty': 'Digite o CPF para continuar',
      'string.base': 'Digite o CPF para continuar',
      'any.invalid': 'CPF inválido',
    }),
    otherwise: Joi.string().custom(validateCNPJ, 'validateCNPJ').messages({
      'string.empty': 'Digite o CNPJ para continuar',
      'string.base': 'Digite o CNPJ para continuar',
      'any.invalid': 'CNPJ inválido',
    }),
  }),
  ddi: Joi.string().valid('55').required().messages({
    'any.required': 'O DDI é obrigatório.',
    'any.only': 'O DDI deve ser "55".',
  }),
  birthday: Joi.date().iso().required().messages({
    'any.required': 'Data de nascimento é obrigatória.',
    'string.empty': 'Data de nascimento não pode ficar vazia.',
    'string.base': 'Data de nascimento não pode ficar vazia.',
    'date.format': 'A data de nascimento deve ser uma data válida.',
    'date.base': 'A data de nascimento deve ser uma data válida.',
  }),
  professionalOccupation: Joi.string().required().messages({
    'any.required': 'Ocupação profissional é obrigatória.',
    'string.empty': 'Ocupação profissional não pode ser vazia.',
    'string.base': 'Ocupação profissional não pode ser vazia.',
  }),
  monthlyIncome: Joi.number().required().messages({
    'any.required': 'Renda mensal é obrigatória.',
    'number.base': 'Renda mensal deve ser um número.',
  }),
  ddd: Joi.string().required().length(2).messages({
    'string.length': 'Deve ter 2 dígitos.',
    'any.required': 'DDD é obrigatório.',
    'string.empty': 'Informe o DDD.',
    'string.base': 'Informe o DDD.',
  }),
  phone: Joi.string()
    .regex(/^[0-9]+$/)
    .min(8)
    .max(20)
    .required()
    .messages({
      'string.min': 'Mínimo 8 dígitos.',
      'string.max': 'Máximo 20 dígitos.',
      'string.empty': 'Informe um telefone.',
      'string.base': 'Informe um telefone.',
      'string.pattern.base': 'Telefone deve conter apenas números.',
    }),
  country: Joi.string().valid('br').required().messages({
    'any.required': 'País é obrigatório.',
    'any.only': 'País deve ser "br".',
  }),
  zipCode: Joi.string().required().length(8).messages({
    'string.length': 'CEP deve conter 8 dígitos.',
    'string.empty': 'CEP é obrigatório.',
    'string.base': 'CEP é obrigatório.',
  }),
  state: Joi.string().required().messages({
    'any.required': 'Estado é obrigatório.',
    'string.empty': 'Estado não pode ser vazio.',
    'string.base': 'Estado não pode ser vazio.',
  }),
  city: Joi.string().required().messages({
    'any.required': 'Cidade é obrigatória.',
    'string.empty': 'Cidade não pode ser vazia.',
    'string.base': 'Cidade não pode ser vazia.',
  }),
  neighborhood: Joi.string().required().messages({
    'any.required': 'Bairro é obrigatório.',
    'string.empty': 'Bairro não pode ser vazio.',
    'string.base': 'Bairro não pode ser vazio.',
  }),
  streetAddress: Joi.string().required().messages({
    'any.required': 'Endereço é obrigatório.',
    'string.empty': 'Endereço não pode ser vazio.',
    'string.base': 'Endereço não pode ser vazio.',
  }),
  streetNumber: Joi.string().required().messages({
    'any.required': 'É obrigatório.',
    'string.empty': 'Não pode ser vazio.',
    'string.base': 'Não pode ser vazio.',
  }),
  complementary: Joi.string().allow(null),

  // Para CNPJ:
  companyName: Joi.when('documentType', {
    is: 'cnpj',
    then: Joi.string().required().messages({
      'any.required': 'Razão Social é obrigatório para CNPJ.',
      'string.empty': 'Razão Social não pode ser vazio.',
      'string.base': 'Razão Social não pode ser vazio.',
    }),
  }),
  tradingName: Joi.when('documentType', {
    is: 'cnpj',
    then: Joi.string().required().messages({
      'any.required': 'Nome Fantasia é obrigatório para CNPJ.',
      'string.empty': 'Nome Fantasia não pode ser vazio.',
      'string.base': 'Nome Fantasia não pode ser vazio.',
    }),
  }),
  annualRevenue: Joi.when('documentType', {
    is: 'cnpj',
    then: Joi.number().required().messages({
      'any.required': 'Informe o faturamento anual.',
      'number.base': 'Informe o faturamento anual.',
    }),
  }),
  responsibilityDocument: Joi.when('documentType', {
    is: 'cnpj',
    then: Joi.string().required().messages({
      'any.required': 'Informe o documento de responsabilidade.',
      'string.empty': 'Informe o documento de responsabilidade.',
      'string.base': 'Informe o documento de responsabilidade.',
    }),
  }),
});
