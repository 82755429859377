function getPaymentMethod(value: string) {
  const paymentMethods = {
    credit_card: 'Cartão de Crédito',
    boleto: 'Boleto',
    pix: 'Pix',
    free: 'Grátis',
    default: '-',
  };

  return paymentMethods[value] || paymentMethods.default;
}

export default getPaymentMethod;
