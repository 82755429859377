import {
  TabPanel,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  Switch,
  HStack,
  Button,
  Select,
} from '@chakra-ui/react';
import { onlyNumber } from '../../../../../hooks/useHandleChange';

const FORM_FIELD_STYLE = {
  '.chakra-input, .chakra-select': {
    borderRadius: 6,
    borderColor: '#E2E8F0',
    fontSize: 'md',
    _placeholder: { color: '#0000005C' },
  },
};

export default function ConfigurationsTabPanel() {
  return (
    <TabPanel>
      <VStack as="form" spacing={8} sx={FORM_FIELD_STYLE}>
        <FormControl>
          <FormLabel fontSize={{ base: 'sm', xl: 'md' }}>
            Chave API
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Input placeholder="Digite aqui" focusBorderColor="gray.300" />
        </FormControl>

        <FormControl>
          <FormLabel fontSize={{ base: 'sm', xl: 'md' }}>
            Quando emitir a nota fiscal?
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Select focusBorderColor="gray.300">
            <option>Na venda</option>
            <option>Após garantia (30 dias oficialmente)</option>
            <option>Não emitir</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel fontSize={{ base: 'sm', xl: 'md' }}>
            Delay de emissão em dias
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Input
            placeholder="Digite aqui"
            focusBorderColor="gray.300"
            onInput={onlyNumber}
            inputMode="numeric"
            maxLength={3}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize={{ base: 'sm', xl: 'md' }}>
            Incluir Juros do Parcelamento no Valor da Nota
          </FormLabel>

          <HStack spacing="0.375rem">
            <Switch id="status" colorScheme="primary" isChecked={true} />
            <Text
              htmlFor="status"
              as={FormLabel}
              m={0}
              fontWeight="normal"
              fontSize="sm"
              color="#2D3748"
            >
              Ativado
            </Text>
          </HStack>
        </FormControl>

        <FormControl>
          <FormLabel fontSize={{ base: 'sm', xl: 'md' }}>Retenção na fonte</FormLabel>

          <HStack spacing="0.375rem">
            <Switch id="status" colorScheme="primary" />
            <Text
              htmlFor="status"
              as={FormLabel}
              m={0}
              fontWeight="normal"
              fontSize="sm"
              color="#2D3748"
            >
              Desativado
            </Text>
          </HStack>
        </FormControl>

        <FormControl>
          <FormLabel fontSize={{ base: 'sm', xl: 'md' }}>Status</FormLabel>

          <HStack spacing="0.375rem">
            <Switch id="status" colorScheme="primary" />
            <Text
              htmlFor="status"
              as={FormLabel}
              m={0}
              fontWeight="normal"
              fontSize="sm"
              color="#2D3748"
            >
              Desativado
            </Text>
          </HStack>
        </FormControl>
      </VStack>

      <HStack as="footer" w="full" justify="end" mt={8} spacing={1}>
        <Button
          // onClick={handleCancelButtonClick}
          variant="ghost"
          mr={3}
          background="gray.200"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          // isDisabled={isSubmitting}
        >
          Cancelar
        </Button>

        <Button
          type="submit"
          colorScheme="primary"
          color="secondary.500"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          // isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </HStack>
    </TabPanel>
  );
}
