import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Authentication from '../../../../api/Authentication';
import Platform from '../../../../api/Platform';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import useQuery from '../../../../hooks/useQuery';
import NextButton from '../Buttons/NextButton';
import ConfirmationModal from './Modal';

const VALID_SUBDOMAIN_REGEX = /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;

const hostname = window.location.hostname;

export default function TabDomain({ onGoToNextTab }) {
  const [isLoading, setIsLoading] = useState(false);
  const [subdomain, setSubdomain] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [hasChangedSubDomain, setHasChangedSubDomain] = useState(false);

  const query = useQuery();
  const { isOpen, onOpen: openModal, onClose } = useDisclosure();

  const token = query.get('token');
  const previousSubdomain = hostname.replace('.proluno.com.br', '');

  useEffect(() => {
    async function logInAutomatically(token: string) {
      await Authentication.validateToken(token);
    }

    if (token) {
      logInAutomatically(token);
    }
  }, [token]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    const isValid = VALID_SUBDOMAIN_REGEX.test(value);

    setIsValid(isValid);

    setSubdomain(value);
  }

  async function onSubmitDomain(event: FormEvent<HTMLDivElement | HTMLFormElement>) {
    event.preventDefault();

    if (hasChangedSubDomain) return onGoToNextTab();

    if (!subdomain) return openModal();

    try {
      setIsLoading(true);

      const domain = '.proluno.com.br';

      const url = subdomain + domain;

      await Platform.integrationDomain(url);

      setIsLoading(false);
      onGoToNextTab();
      setHasChangedSubDomain(true);
      Toast('Domínio atualizado com sucesso!');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
      setIsLoading(false);
    }
  }

  function onConfirm() {
    onClose();
    onGoToNextTab();
  }

  return (
    <>
      <Flex direction="column" justify="space-between" as="form" onSubmit={onSubmitDomain} flex={1}>
        <Box>
          <Heading size="lg" fontWeight={{ base: 'semibold', md: 'bold' }}>
            Domínio Proluno
          </Heading>

          <Text mt={2} fontSize={{ base: 'sm', md: 'md' }} color="#20212350" fontWeight="medium">
            Digite o domínio que deseja que sua plataforma de ensino possua.
          </Text>

          <FormControl isInvalid={!isValid} colorScheme={'orange'} isDisabled={hasChangedSubDomain}>
            <InputGroup size="lg" mt={5}>
              <Input
                value={subdomain}
                onChange={handleChange}
                placeholder={previousSubdomain}
                focusBorderColor="#EB7129"
                maxLength={63}
              />
              <InputRightAddon bg="#e9e9e9" color="gray.700" children=".proluno.com.br" />
            </InputGroup>

            {subdomain && !isValid && (
              <FormErrorMessage>Insira um subdomínio válido.</FormErrorMessage>
            )}
          </FormControl>

          {hasChangedSubDomain ? (
            <Text my={2} fontSize="sm" color="#20212380" fontWeight="400">
              Caso precise alterar o domínio novamente, entre em contato com o nosso suporte{' '}
              <Text as="span" fontWeight="bold">
                suporte@proluno.com.br
              </Text>
            </Text>
          ) : (
            <Text my={2} fontSize="sm" color="#20212380" fontWeight="400">
              Se você já possuir um domínio próprio, entre em contato com o nosso suporte{' '}
              <Text as="line" fontWeight="bold">
                suporte@proluno.com.br
              </Text>
              , que você será orientado sobre o que deve ser feito. Enquanto isso, configure o seu
              domínio Proluno, até a alteração.
            </Text>
          )}
        </Box>

        <Flex justify="flex-end">
          <NextButton type="submit" isLoading={isLoading} />
        </Flex>
      </Flex>
      <ConfirmationModal onConfirm={onConfirm} isOpen={isOpen} onClose={onClose} />
    </>
  );
}
