import {
  Button,
  CloseButton,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { HiOutlineSearch } from 'react-icons/hi';
import { MdClose as CloseIcon } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import DateRangePicker from '../../../components/DateRangePicker';
import FullPageLoading from '../../../components/FullPageLoading';
import useFetch from '../../../hooks/useFetch';
import LeadListMobile from './LeadListMobile';
import TableLeadBase from './TableLeadBase';

export type Lead = {
  id: number;
  fullName: string;
  email: string;
  phone: string;
  createdAt: string;
  lastConversion: string;
  amountConversions: number;
};

type LeadsResponse = {
  data: Lead[];
  page: number;
  per_page: number;
  total: number;
};

export default function LeadBase() {
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const searchParamsString = searchParams.toString();

  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [startDate, setStartDate] = useState(searchParams.get('startDate') || '');
  const [endDate, setEndDate] = useState(searchParams.get('endDate') || '');
  const [type, setType] = useState(searchParams.get('type') || '');

  const {
    fetchData: getExportStudentsExcel,
    loading: isLoadingExportExcel,
    data: dataExcel,
  } = useFetch({
    method: 'get',
    url: '/leads/export?typeFile=Excel',
    authenticated: true,
    autoFetch: false,
    responseType: 'blob',
  });

  const {
    fetchData: getExportStudentsCsv,
    loading: isLoadingExportCsv,
    data: dataCsv,
  } = useFetch({
    method: 'get',
    url: '/leads/export?typeFile=CSV',
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (dataExcel) {
      const url = window.URL.createObjectURL(new Blob([dataExcel as unknown as BlobPart]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'leads.xlsx');
      document.body.appendChild(link);
      link.click();
    }
  }, [dataExcel]);

  useEffect(() => {
    if (dataCsv) {
      const url = window.URL.createObjectURL(new Blob([dataCsv as unknown as BlobPart]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'leads.csv');
      document.body.appendChild(link);
      link.click();
    }
  }, [dataCsv]);

  const {
    data: leadsResponse,
    loading,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<LeadsResponse>>({
    method: 'get',
    url: `/leads?${searchParamsString}&per_page=10`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParamsString]);

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  const handlePageChange = useCallback(
    ({ selected: selectedPage }) => {
      searchParams.set('page', selectedPage + 1);

      history.push({ search: searchParams.toString() });
    },
    [history, searchParams]
  );

  function handleFilter() {
    searchParams.set('page', '1');

    search ? searchParams.set('search', search) : searchParams.delete('search');
    startDate ? searchParams.set('startDate', startDate) : searchParams.delete('startDate');
    endDate ? searchParams.set('endDate', endDate) : searchParams.delete('endDate');
    type ? searchParams.set('type', type) : searchParams.delete('type');

    history.push({ search: searchParams.toString() });
  }

  function clearFilters() {
    setSearch('');
    setStartDate('');
    setEndDate('');
    setType('');

    searchParams.set('page', '1');
    searchParams.delete('search');
    searchParams.delete('startDate');
    searchParams.delete('endDate');
    searchParams.delete('type');

    history.push({ search: searchParams.toString() });
  }

  function handleRangeChange(startDate: string, endDate: string) {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  function handleTypeChange(event: ChangeEvent<HTMLSelectElement>) {
    setType(event.target.value);
  }

  const leads = leadsResponse?.data?.data;
  const total = leadsResponse?.data?.total;
  const pageCount = Math.ceil(total / 10);

  const hasFilters = useMemo(() => {
    return Boolean(search || startDate || endDate || type);
  }, [search, startDate, endDate, type]);

  return (
    <>
      <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} justifyContent="space-between">
        <Stack direction="column" spacing={5}>
          <Stack
            direction={{ base: 'column', md: 'row', lg: 'row' }}
            justifyContent="space-between"
            spacing={2}
          >
            <Heading fontSize="36px" fontWeight={600}>
              Base de leads
            </Heading>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<BiChevronDown />}
                colorScheme="primary"
                color="secondary.500"
                _hover={{ bgColor: 'orange.700' }}
                _active={{ bgColor: 'orange.700' }}
                style={{ outline: 'none', boxShadow: 'none' }}
                isLoading={isLoadingExportExcel || isLoadingExportCsv}
                isDisabled={isLoadingExportExcel || isLoadingExportCsv}
              >
                Ações
              </MenuButton>
              <MenuList>
                <MenuItem
                  style={{ outline: 'none', boxShadow: 'none' }}
                  _hover={{ color: 'orange.500', fontWeight: 600 }}
                  onClick={getExportStudentsExcel}
                >
                  Exportar Lista (Excel)
                </MenuItem>
                <MenuItem
                  style={{ outline: 'none', boxShadow: 'none' }}
                  _hover={{ color: 'orange.500', fontWeight: 600 }}
                  onClick={getExportStudentsCsv}
                >
                  Exportar Lista (CSV)
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>

          <Text fontSize="18px" color="#20212350">
            Encontre e acompanhe seus leads facilmente pelo nome ou email. Além disso, você pode
            visualizar as conversões realizadas por cada lead, fornecendo insights valiosos para
            suas estratégias de vendas e marketing.
          </Text>
        </Stack>
      </Stack>

      <Stack direction={{ base: 'column', md: 'row' }} justify="space-between" my={8} spacing={4}>
        <InputGroup w={{ base: '100%', md: '320px', lg: '320px' }} size="sm">
          <InputLeftElement pointerEvents="none" children={<HiOutlineSearch color="gray" />} />
          <Input
            type="text"
            value={search}
            onChange={handleSearchChange}
            placeholder="Buscar lead por nome ou e-mail"
            borderRadius={6}
            focusBorderColor="primary.500"
            onKeyDown={({ key }) => key === 'Enter' && handleFilter()}
          />

          {search && (
            <InputRightElement
              children={<CloseButton onClick={clearFilters} size="sm" color="gray.500" />}
            />
          )}
        </InputGroup>

        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
          <Select
            value={type}
            onChange={handleTypeChange}
            size="sm"
            color="gray.500"
            borderRadius="md"
            focusBorderColor="primary.500"
            w={{ base: '100%', md: '160px', lg: '160px' }}
          >
            <option hidden>Tipo</option>
            {type && <option value="">Todos</option>}
            <option value="lead">Lead</option>
            <option value="student">Aluno</option>
          </Select>

          <DateRangePicker
            startDate={startDate && dayjs(startDate).format('YYYY-MM-DD')}
            endDate={endDate && dayjs(endDate).format('YYYY-MM-DD')}
            onChange={handleRangeChange}
            iconColor="#20212350"
            selectPlaceholder="Período"
            hideIcon
          />

          <Button
            onClick={handleFilter}
            flexShrink={0}
            size="xs"
            colorScheme="primary"
            color="secondary.500"
          >
            Filtrar
          </Button>

          {hasFilters && (
            <Button
              onClick={clearFilters}
              variant="outline"
              flexShrink={0}
              size="xs"
              colorScheme="gray"
              color="gray.400"
              rightIcon={<CloseIcon size="1rem" />}
            >
              Limpar filtros
            </Button>
          )}
        </Stack>
      </Stack>

      <TableLeadBase
        leads={leads || []}
        currentPage={Number(searchParams.get('page')) || 1}
        pageCount={pageCount}
        handlePageChange={handlePageChange}
      />

      <LeadListMobile
        leads={leads}
        pageCount={pageCount}
        currentPage={Number(searchParams.get('page')) || 1}
        onPageChange={handlePageChange}
      />

      <FullPageLoading isLoading={loading} />
    </>
  );
}
