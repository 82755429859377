import { useEffect, useState } from 'react';

import APIPageBuilder from '../../../../api/PageBuilder';
import BannerInput from '../components/BannerInput';
import DropdownSection from '../components/DropdownSection';
import ErrorMessage from '../components/ErrorMessage';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { Heading } from '../../../../components/Heading';
import ImagePreview from '../components/ImagePreview';
import Line from '../components/Line';
import Section from '../Section';
import Spinner from '../../../../components/Loadings/Spinner';
import ThemeAPI from '../../../../api/Theme';
import Toast from '../../../../components/Toast';
import Tooltip from '../components/Tooltip';
import UploadHelper from '../../../../helpers/UploadHelper';
import styles from './styles.module.css';
import { useTheme } from '../../../../contexts/ThemeContext';

const FIRST_FILE = 0;
const MAX_SIZE_FILE_PHOTO = 3145728;

function About({ section }) {
  const [isActiveSection, setIsActiveSection] = useState(null);

  const [preview, setPreview] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [description, setDescription] = useState('');
  const [nameSection, setNameSection] = useState('');

  const [errorUploadPhoto, setErrorUploadPhoto] = useState(null);

  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { getTheme, getThemeMarketplace, theme, isLoading: isLoadingTheme } = useTheme();

  useEffect(() => {
    if (!isLoadingTheme) {
      const biographySection = section;

      setIsActiveSection(biographySection.active);
      const biographies = biographySection.themeBiography;
      const firstBiography = biographies[0];

      setPreview(firstBiography?.photo);
      setPhoto(firstBiography?.photo);
      setDescription(firstBiography?.description);

      setNameSection(biographySection.nameSection);
    }
  }, [isLoadingTheme, section, theme]);

  function handleDescriptionChange(event) {
    const { value } = event.target;

    setErrorMessage(null);
    setHasChanged(true);

    setDescription(value);
  }

  function handleNameSectionChange(event) {
    const { value } = event.target;

    setErrorMessage(null);
    setHasChanged(true);

    setNameSection(value);
  }

  function handlePhotoChange(acceptedFiles) {
    const file = acceptedFiles[FIRST_FILE];

    if (file?.size > MAX_SIZE_FILE_PHOTO) {
      return setErrorUploadPhoto('O tamanho da imagem deve ser de no máximo 3 MB.');
    }

    if (file) {
      setPreview(URL.createObjectURL(file));
      setPhoto(file);

      setErrorUploadPhoto(null);
      setErrorMessage(null);
      setHasChanged(true);
    }
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    setHasChanged(false);

    const biographySection = section;

    setIsActiveSection(biographySection.active);

    const biographies = biographySection.themeBiography;
    const firstBiography = biographies[0];

    setPreview(firstBiography?.photo);
    setPhoto(firstBiography?.photo);
    setDescription(firstBiography?.description);
    setNameSection(biographySection.nameSection);

    setErrorUploadPhoto(null);
    setErrorMessage(null);
  }

  function handleImageDropRejected() {
    setErrorUploadPhoto('Arquivo inválido!');
  }

  function handleImageChange() {
    setHasChanged(true);
    setPreview(null);
  }

  function toggleVisibilitySection(event) {
    const { checked } = event.target;

    setHasChanged(true);

    setIsActiveSection(!checked);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!photo) return setErrorUploadPhoto('Você deve escolher uma foto');

    setIsLoading(true);

    if (nameSection === '') {
      Toast('O nome da sua seção não pode ser vazio!', 'error');
      return;
    }

    const payload = {
      photo,
      description,
      activeSection: !!isActiveSection,
      nameSection,
    };

    const biographySection = section;
    const biographies = biographySection.themeBiography;
    const biographyId = biographies[0].id;

    try {
      if (typeof payload.photo !== 'string') {
        const { newFileName } = await UploadHelper.upload(payload.photo, 'biography-photos');

        payload.photo = encodeURI(newFileName);

        await ThemeAPI.updateAbout(theme.id, biographySection.id, biographyId, payload);

        setPhoto(payload.photo);
      } else {
        await ThemeAPI.updateAbout(theme.id, biographySection.id, biographyId, payload);
      }

      Toast('Biografia atualizada com sucesso');
      // await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
      setIsLoading(false);
    }
  }

  async function handleDuplicateBiographySection() {
    setErrorMessage('');

    try {
      const theme_id = theme.id;

      const section_id = section.id;

      await APIPageBuilder.biography.store(theme_id, section_id);

      Toast('Seção Biografia duplicada com sucesso!');

      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
    }
  }

  async function handleDeleteBiographySection() {
    setErrorMessage('');

    try {
      const theme_id = theme.id;

      const section_id = section.id;

      await APIPageBuilder.biography.delete(theme_id, section_id);

      Toast('Seção Biografia excluída com sucesso!');

      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
    }
  }

  return (
    <Section
      description="Escreva sobre você ou sobre sua instituição. Conte aos seus alunos as suas conquistas, sua formação e etc. Fale também sobre a sua tragetória e o que fez para chegar até aqui. Descreva suas paixões e porque escolheu determinada área de atuação. "
      actionName="Biografia"
    >
      <div className={styles.sectionHeader}>
        <div className="d-flex align-items-center">
          <Heading as="h4" fontSize="2xl">
            Sobre/Biografia
          </Heading>
          <Line />
          <Tooltip link="https://youtu.be/dK1HHBrVyHU" />
        </div>

        <DropdownSection
          onClickToDuplicate={handleDuplicateBiographySection}
          onClickToDelete={handleDeleteBiographySection}
        />
      </div>
      <div className={styles.aboutContainer}>
        <form onSubmit={handleSubmit}>
          <div>
            <section className={styles.aboutImage}>
              <div className="form-group">
                {!preview ? (
                  <BannerInput
                    preview={preview}
                    theme={theme}
                    onDrop={handlePhotoChange}
                    onDropRejected={handleImageDropRejected}
                    onChangeImage={handleImageChange}
                    imageDimensions="1080x1080"
                  />
                ) : (
                  <ImagePreview
                    preview={preview}
                    onClick={handleImageChange}
                    isLoading={isLoadingTheme}
                  />
                )}
              </div>

              {errorUploadPhoto && <ErrorMessage message={errorUploadPhoto} />}
            </section>

            <section className={styles.description}>
              <label>Nome da seção</label>
              <input
                minLength={1}
                maxLength={25}
                value={nameSection}
                name={nameSection}
                onChange={handleNameSectionChange}
                placeholder='Escreva como você quer renomear a seção "Sobre".'
              ></input>
              <label htmlFor={`description${section.id}`}>Descrição</label>
              <textarea
                id={`description${section.id}`}
                name={`description${section.id}`}
                onChange={handleDescriptionChange}
                value={description}
                placeholder="Escreva a descrição..."
                maxLength={1000}
              />
            </section>
          </div>

          <div className={styles.actions}>
            <button
              className={styles.cancelButton}
              onClick={handleDiscardChanges}
              disabled={!hasChanged || isLoading}
            >
              Cancelar
            </button>

            <button type="submit" disabled={!hasChanged || isLoading || nameSection === ''}>
              {isLoading ? <Spinner small /> : 'Salvar alterações'}
            </button>
          </div>

          <div className={styles.footer}>
            <div>
              <input
                type="checkbox"
                id={`isActiveSectionAbout${section.id}`}
                name={`isActiveSectionAbout${section.id}`}
                onChange={toggleVisibilitySection}
                checked={!isActiveSection}
              />
              <label htmlFor={`isActiveSectionAbout${section.id}`}>
                Ocultar seção da página inicial
              </label>
            </div>
          </div>
        </form>
      </div>

      {errorMessage && !hasChanged && (
        <div className="alert alert-danger mb-4" role="alert" style={{ borderRadius: '0' }}>
          <div className="text-center">{errorMessage}</div>
        </div>
      )}
    </Section>
  );
}

export default About;
