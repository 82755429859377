import { Button, Divider, HStack, Stack, useColorModeValue } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import DateRangePicker from '../../../../../components/DateRangePicker';
import DateRangePickerMobile from '../../../../../components/DateRangePickerMobile';
import useWindowSize from '../../../../../hooks/useWindowSize';

export default function FilterStatistics({ startDate, setStartDate, endDate, setEndDate }) {
  const color = useColorModeValue('#202123', '#FFFFFF');

  const { width } = useWindowSize();

  const styleButton = {
    width: '60px',
    height: '32px',
    padding: '8px 16px',
    borderRadius: '9999px',
    fontSize: 'sm',
    fontWeight: '600',
    lineHeight: '20px',
  };

  const buttonSelectColor = {
    color: '#FFFFFF',
    bgColor: '#EE6924',
  };

  const buttonDefaultColor = {
    color,
    bgColor: 'transparent',
  };

  const buttonInitialColors = {
    today: buttonDefaultColor,
    days7: buttonSelectColor,
    days15: buttonDefaultColor,
    days30: buttonDefaultColor,
  };

  const [buttonColors, setButtonColors] = useState(buttonInitialColors);
  const [filterDate, setFilterDate] = useState({ startDate, endDate });

  function onClickSelectDays(interval: string) {
    switch (interval) {
      case 'today':
        setButtonColors({
          today: buttonSelectColor,
          days7: buttonDefaultColor,
          days15: buttonDefaultColor,
          days30: buttonDefaultColor,
        });

        setFilterDate({
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        });

        break;
      case 'days7':
        setButtonColors({
          today: buttonDefaultColor,
          days7: buttonSelectColor,
          days15: buttonDefaultColor,
          days30: buttonDefaultColor,
        });

        setFilterDate({
          startDate: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        });
        break;
      case 'days15':
        setButtonColors({
          today: buttonDefaultColor,
          days7: buttonDefaultColor,
          days15: buttonSelectColor,
          days30: buttonDefaultColor,
        });

        setFilterDate({
          startDate: dayjs().subtract(15, 'days').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        });
        break;
      case 'days30':
        setButtonColors({
          today: buttonDefaultColor,
          days7: buttonDefaultColor,
          days15: buttonDefaultColor,
          days30: buttonSelectColor,
        });
        setFilterDate({
          startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        });
        break;
    }
  }

  function handleRangeChange(startDate: string, endDate: string) {
    setFilterDate({
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    });

    setButtonColors({
      today: buttonDefaultColor,
      days7: buttonDefaultColor,
      days15: buttonDefaultColor,
      days30: buttonDefaultColor,
    });
  }

  function handleFilter() {
    setStartDate(filterDate.startDate);
    setEndDate(filterDate.endDate);
  }

  return (
    <HStack
      spacing={{ base: '0px', md: '10px', lg: '13px' }}
      alignItems="center"
      marginBottom="30px"
    >
      <HStack spacing={0} alignItems="center">
        <Button
          color={buttonColors.today.color}
          bgColor={buttonColors.today.bgColor}
          _hover={{ bgColor: buttonColors.today.bgColor.concat('80') }}
          onClick={() => onClickSelectDays('today')}
          {...styleButton}
        >
          Hoje
        </Button>

        <Button
          color={buttonColors.days7.color}
          bgColor={buttonColors.days7.bgColor}
          _hover={{ bgColor: buttonColors.days7.bgColor.concat('80') }}
          onClick={() => onClickSelectDays('days7')}
          {...styleButton}
        >
          7 dias
        </Button>

        <Button
          color={buttonColors.days15.color}
          bgColor={buttonColors.days15.bgColor}
          _hover={{ bgColor: buttonColors.days15.bgColor.concat('80') }}
          onClick={() => onClickSelectDays('days15')}
          {...styleButton}
        >
          15 dias
        </Button>

        <Button
          color={buttonColors.days30.color}
          bgColor={buttonColors.days30.bgColor}
          _hover={{ bgColor: buttonColors.days30.bgColor.concat('80') }}
          onClick={() => onClickSelectDays('days30')}
          {...styleButton}
        >
          30 dias
        </Button>
      </HStack>

      <Stack direction="row" spacing="0" alignItems="center">
        {width > 700 ? (
          <>
            <Divider orientation="vertical" background={color} width="1px" height="22px" />
            <DateRangePicker hideSelect iconColor={color} onChange={handleRangeChange} />
            <Divider orientation="vertical" background={color} width="1px" height="22px" />
          </>
        ) : (
          <>
            <Divider orientation="vertical" background={color} width="1px" height="22px" />
            <DateRangePickerMobile onChange={handleRangeChange} />
          </>
        )}
      </Stack>

      <Button bgColor="#ec7117" color="#FFFFFF" size="xs" onClick={handleFilter}>
        Filtrar
      </Button>
    </HStack>
  );
}
