import { Box, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import convertToBrazilianCurrency from '../../../../../helpers/convertToBrazilianCurrency';
import { Affiliate } from '../types';

interface IProps {
  affiliates: Affiliate[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

export default function AffiliatesCard({ affiliates, onEdit, onDelete }: IProps) {
  return (
    <Stack spacing="10px">
      {affiliates.map(affiliate => (
        <Box
          display="flex"
          width="full"
          padding="12px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="15px"
          borderRadius="5px"
          background="#FFF"
          border="1px solid rgba(0, 0, 0, 0.05)"
          boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        >
          <Box display="flex" width="full" alignItems="self-start" justifyContent="space-between">
            <Stack spacing="4px">
              <Text
                color="#2D3748"
                width="240px"
                noOfLines={2}
                fontSize="16px"
                fontWeight="500"
                lineHeight="24px"
              >
                {affiliate.name}
              </Text>
              <Text
                color="#2D3748"
                width="240px"
                noOfLines={2}
                fontSize="14px"
                fontWeight="400"
                lineHeight="24px"
              >
                {affiliate.email}
              </Text>
            </Stack>
            <Menu>
              <MenuButton
                width="18.087px"
                height="16.078px"
                flexShrink="0"
                bgColor="#2021231F"
                _active={{ bgColor: '#2021233F' }}
                transition="ease-in-out 0.3s"
                borderRadius="3.35px"
              >
                <Box display="flex" justifyContent="center">
                  <BiDotsHorizontalRounded size="12.058px" color="#20212380" />
                </Box>
              </MenuButton>
              <MenuList>
                <MenuItem
                  color="#979CA6"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="normal"
                  onClick={() => onEdit(affiliate.affiliationId)}
                >
                  Editar afiliação
                </MenuItem>
                <MenuItem
                  color="#979CA6"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="normal"
                  hidden
                >
                  Ver como afiliado
                </MenuItem>
                <MenuItem
                  color="#BB2124"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="normal"
                  onClick={() => onDelete(affiliate.affiliationId)}
                >
                  Remover afiliado
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
          <Stack width="full" spacing="10px">
            <Stack direction="row" justifyContent="space-between">
              <Text color="#20212380" fontSize="14px" fontWeight="600" lineHeight="normal">
                Telefone:
              </Text>
              <Text color="#20212380" fontSize="14px" fontWeight="400" lineHeight="normal">
                {affiliate.phoneNumber ?? '-'}
              </Text>
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Text color="#20212380" fontSize="14px" fontWeight="600" lineHeight="normal">
                Produto:
              </Text>
              <Text
                color="#20212380"
                fontSize="14px"
                fontWeight="400"
                lineHeight="normal"
                width="200px"
                textAlign="end"
                noOfLines={2}
              >
                {affiliate.courseName}
              </Text>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Text color="#20212380" fontSize="14px" fontWeight="600" lineHeight="normal">
                Comissão:
              </Text>
              <Text color="#EB7129" fontSize="14px" fontWeight="500" lineHeight="normal">
                {affiliate.commissionValue}% de comissão
              </Text>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Text color="#20212380" fontSize="14px" fontWeight="600" lineHeight="normal">
                Ganhos:
              </Text>
              <Text color="#EB7129" fontSize="14px" fontWeight="500" lineHeight="normal">
                {convertToBrazilianCurrency(affiliate.winnings)}
              </Text>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Text color="#20212380" fontSize="14px" fontWeight="600" lineHeight="normal">
                Vendas:
              </Text>
              <Text color="#20212380" fontSize="14px" fontWeight="400" lineHeight="normal">
                {affiliate.amountOfSales} {affiliate.amountOfSales === 1 ? 'venda' : 'vendas'}
              </Text>
            </Stack>
          </Stack>
        </Box>
      ))}
    </Stack>
  );
}
