import './styles.css';

import { Box, Button, Heading, Link as LinkChakra } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import warning from '../../assets/images/warning.svg';
import { ROLE_LOCAL_STORAGE } from '../../helpers/LocalStorageHelper';

function WithoutAccess() {
  const history = useHistory();

  useEffect(() => {
    const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

    if (role === 'ALUNO') {
      history.push('/');
    }
  }, [history]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Box
        display="flex"
        flexDirection="column"
        width="full"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={warning}
          alt="Aviso de acesso não permitido"
          width="55%"
          height="240px"
          className="my-md-2 warning-img"
        />
        <Heading as="h6" fontSize="xl" textAlign="center">
          Você ainda não possui acesso a esse recurso. Entre em contato para saber como adquirir
          essa ferramenta incrível:{' '}
          <LinkChakra
            color="#0000FF"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5585992652741"
          >
            CLIQUE AQUI
          </LinkChakra>
        </Heading>
        <Link to="/">
          <Button colorScheme="primary" my={3}>
            Ir para o início
          </Button>
        </Link>
      </Box>
    </div>
  );
}

export default WithoutAccess;
