import { Box, Flex, Image, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthenticationAPI from '../../api/Authentication';
import freeRegistration from '../../assets/images/free-registration.png';
import logoProluno from '../../assets/images/logos/proluno_cor.png';
import useQuery from '../../hooks/useQuery';
import useWindowSize from '../../hooks/useWindowSize';
import { Redirection } from './Redirection';

export const MEDIUM_SCREEN_SIZE = 800;

export type FormDataType = {
  answers: { formQuestionId: string; formOptionId: string }[];
};

export default function TrialPeriodForm() {
  const { width } = useWindowSize();
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const token = query.get('token');
    if (!token) return;

    async function logInAutomatically(token: string) {
      const authentication = await AuthenticationAPI.validateToken(token);

      if (!authentication) {
        history.push('/login');
      }
    }

    if (token) {
      logInAutomatically(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack w="full" direction="row" minH="100vh">
      <Stack w="full" direction="column">
        <Box
          display="flex"
          width="full"
          marginX={{ base: 0, md: 10, lg: 10 }}
          marginTop={{ base: 0, md: 10, lg: 10 }}
          justifyContent={{ base: 'center', md: 'flex-start', lg: 'flex-start' }}
        >
          <Image src={logoProluno} width={150} />
        </Box>

        <Flex px={{ base: 4, md: 28 }}>
          <Redirection />
        </Flex>
      </Stack>

      <Stack
        w="full"
        minH="full"
        backgroundImage={freeRegistration}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        direction="row"
        hidden={width < MEDIUM_SCREEN_SIZE}
      />
    </Stack>
  );
}
