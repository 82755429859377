import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Authentication from '../../../api/Authentication';

import Container from '../../../components/ContainerSite/ContainerSite';
import styles from './styles.module.css';

function InstructorThanks() {
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const token = query.get('token');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function logInAutomatically(token) {
      await Authentication.validateToken(token);
    }

    if (token) {
      logInAutomatically(token);
    }
  }, [token]);

  return (
    <Container>
      <div className={styles.thanksContainer}>
        <h1>
          <span>Parabéns</span>
          <br /> por sua assinatura!
        </h1>
        <div>
          Você já pode aproveitar todas as vantagens da Proluno. Clique no botão abaixo para iniciar
          a digitalização do seu conhecimento.
        </div>
        <a href="/instructor-signup/integration-form">
          <button>Primeiros Passos</button>
        </a>
      </div>
    </Container>
  );
}

export default InstructorThanks;
