import {
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { IShowcase } from '../..';
import useFetch from '../../../../../../../hooks/useFetch';
import useHandleChange, { TargetType } from '../../../../../../../hooks/useHandleChange';
import useHandleSubmit, { Validation } from '../../../../../../../hooks/useHandleSubmit';
import { CHAKRA_REACT_SELECT_STYLES } from '../../constants';
import {
  CreateShowcaseSchemaType,
  createShowcaseSchemaValidator,
} from '../../CreateProductShowcaseModal/createShowcaseSchemaValidator';

const initialForm: CreateShowcaseSchemaType = {
  name: '',
  plansIds: [],
  showcaseType: 'products',
  showcaseViewType: 'standard',
};

interface CreateDefaultPlanShowcaseModalProps {
  isOpen: boolean;
  showcases: IShowcase[];
  onClose: () => void;
  setShowcases: (showcases: IShowcase[]) => void;
}
export function CreateDefaultPlanShowcaseModal({
  isOpen,
  showcases,
  onClose,
  setShowcases,
}: CreateDefaultPlanShowcaseModalProps) {
  const [options, setOptions] = useState<{ value: number; label: string }[]>([]);

  const { form, handleChange, setForm } = useHandleChange<CreateShowcaseSchemaType>(initialForm);

  function afterCreateConfirm(response) {
    if (!response.data.data) return;

    setShowcases([...showcases, response.data.data]);

    setForm(initialForm);

    onClose();
  }

  const { data: planListResponse, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<{ id: number; name: string }[]>
  >({
    method: 'get',
    url: `/showcase/plans`,
    authenticated: true,
    autoFetch: true,
  });

  useEffect(() => {
    const filteredPlanList = planListResponse?.data?.filter(
      course => !form?.plansIds?.includes(course.id)
    );

    const options = filteredPlanList?.map(course => ({
      value: course.id,
      label: course.name,
    }));

    setOptions(options);
  }, [planListResponse?.data, form?.plansIds]);

  function onChange(newValues) {
    if (!newValues || newValues.length === 0) return; // Verificar se há valores novos

    const selectedPlans = form?.plansIds;

    const newArrayValue = selectedPlans.concat(newValues.map(newValue => newValue.value));

    const event = {
      target: {
        name: 'plansIds',
        value: newArrayValue,
      },
    };

    handleChange(event as unknown as ChangeEvent<TargetType>);
  }

  function onClickButton(planId) {
    const planIds = form.plansIds?.filter(plan => plan !== planId);

    setForm({ ...form, plansIds: planIds });
  }

  const payload = { ...form, showcaseType: 'plans' };

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit({
    data: payload,
    schemaValidator: createShowcaseSchemaValidator,
    url: '/showcase',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Vitrine criada com sucesso!',
      callback(response) {
        afterCreateConfirm(response);
      },
    },
  });

  const isPlanSelectInvalid = (formValidation?.plansIds as unknown as Validation)?.isInvalid;

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="2xl">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit} maxWidth={{ base: '348px', md: '612px' }}>
        <ModalHeader color="#202123" fontWeight="bold" fontSize="lg">
          Adicionar uma vitrine
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing={4}>
            <FormControl isInvalid={formValidation?.name?.isInvalid}>
              <FormLabel color="#0000007D" fontWeight="normal" fontSize="sm">
                Nome da vitrine
              </FormLabel>

              <Input
                name="name"
                value={form.name}
                onChange={handleChange}
                placeholder="Digite aqui"
                focusBorderColor="gray.300"
                _placeholder={{ color: '#2D374880' }}
              />

              <FormErrorMessage>{formValidation?.name.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={(formValidation?.plansIds as unknown as Validation)?.isInvalid}>
              <FormLabel>Planos</FormLabel>

              <MultiSelect
                menuPlacement="bottom"
                placeholder="Selecione os planos"
                isMulti={true}
                focusBorderColor="gray.300"
                closeMenuOnSelect={true}
                noOptionsMessage={() => 'Nenhum produto encontrado.'}
                options={options}
                value={[]}
                isLoading={isLoading}
                menuPortalTarget={document.body}
                isInvalid={isPlanSelectInvalid}
                onChange={onChange}
                chakraStyles={CHAKRA_REACT_SELECT_STYLES}
                styles={{
                  menuPortal: (provided: Record<string, any>) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />

              <FormErrorMessage>
                {(formValidation?.plansIds as unknown as Validation)?.message}
              </FormErrorMessage>

              <HStack alignItems="center" flexWrap="wrap" flex={1} marginY={2} spacing={1}>
                {form?.plansIds?.length &&
                  form?.plansIds.map(planId => (
                    <Flex
                      key={planId}
                      justify="center"
                      align="center"
                      paddingX={2}
                      paddingY={0.5}
                      gap={1}
                      maxWidth="13rem"
                      backgroundColor="#20212340"
                      borderRadius={6}
                    >
                      <Text fontWeight="medium" fontSize="xs" color="#202123" isTruncated>
                        {planListResponse?.data?.find(course => course.id === planId)?.name}s{' '}
                      </Text>

                      <CloseButton
                        size="sm"
                        boxSize={4}
                        sx={{ svg: { boxSize: '6.36px' } }}
                        onClick={() => onClickButton(planId)}
                      />
                    </Flex>
                  ))}
              </HStack>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button size="sm" onClick={onClose} colorScheme="gray" isDisabled={isSubmitting}>
              Cancelar
            </Button>

            <Button
              size="sm"
              colorScheme="primary"
              color="secondary.500"
              type="submit"
              isLoading={isSubmitting}
            >
              Adicionar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
