import Amex from '../../../components/CardBrands/Amex';
import Diners from '../../../components/CardBrands/Diners';
import Elo from '../../../components/CardBrands/Elo';
import MasterCard from '../../../components/CardBrands/MasterCard';
import React from 'react';
import Visa from '../../../components/CardBrands/Visa';
import styles from './styles.module.css';

function CardsBrand() {
  return (
    <div className={styles.cardsContainer}>
      <Visa />
      <MasterCard />
      <Elo />
      <Amex />
      <Diners />
    </div>
  );
}

export default CardsBrand;
