import { Heading, HStack, IconButton, Stack } from '@chakra-ui/react';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

export default function Header() {
  const history = useHistory();

  function goBack() {
    history.push('/tools/question-statistics');
  }

  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      as="header"
      w="full"
      align={{ base: 'start', lg: 'center' }}
      justify="space-between"
      spacing={8}
    >
      <HStack spacing={0.5}>
        <IconButton
          onClick={goBack}
          icon={<ChevronLeftIcon size="1.75rem" />}
          aria-label="Voltar"
          variant="ghost"
          colorScheme="gray"
          size="sm"
        />

        <Heading as="h1" fontWeight="semibold" fontSize={{ base: 'lg', lg: '2xl' }} m={0}>
          Estatísticas de Questões
        </Heading>
      </HStack>

      {/* <Button
        onClick={() => alert('Implemente, viu 🫵')}
        colorScheme="primary"
        color="secondary.500"
        rightIcon={<ExportIcon size="1rem" />}
        size="sm"
        width={{ base: 'full', lg: '6.875rem' }}
      >
        Exportar
      </Button> */}
    </Stack>
  );
}
