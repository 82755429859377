import { MdChevronLeft as ChevronLeft, MdChevronRight as ChevronRight } from 'react-icons/md';

import styles from '../styles.module.css';

export function PrevArrow({ className, onClick }) {
  return (
    <div className={`${className} ${styles.prevArrow}`} onClick={onClick}>
      <ChevronLeft color="#202123" />
    </div>
  );
}

export function NextArrow({ className, onClick }) {
  return (
    <div className={`${className} ${styles.nextArrow}`} onClick={onClick}>
      <ChevronRight color="#202123" />
    </div>
  );
}

export function CustomPrevArrow({ className, onClick }) {
  return (
    <div className={`${className} ${styles.prevArrowCatProd}`} onClick={onClick}>
      <ChevronLeft color="#202123" />
    </div>
  );
}

export function CustomNextArrow({ className, onClick }) {
  return (
    <div className={`${className} ${styles.nextArrowCatProd}`} onClick={onClick}>
      <ChevronRight color="#202123" />
    </div>
  );
}
