import {
  Box,
  Flex,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { FiAlertCircle as AlertIcon } from 'react-icons/fi';
import QRCode from '../../../../../../SubscriptionCheckoutStudent/pages/PaymentMethod/Pix/components/QRCode';
import CopyButton from '../../../../../../SubscriptionCheckoutStudent/pages/PaymentMethod/Pix/components/CopyButton';

interface PixModalProps {
  isOpen: boolean;
  pixQrCode: string;
  onClose: any;
}

export default function PixModal({ pixQrCode, isOpen, onClose }: PixModalProps) {
  const bgColor = useColorModeValue('#fff', '#37393D');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />

      <ModalContent bg={bgColor}>
        <ModalHeader p={{ base: 4, md: 6 }}>Seu código PIX</ModalHeader>

        <ModalCloseButton />

        <ModalBody p={{ base: 4, md: 6 }}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            gap={6}
            align={{
              base: 'center',
              md: 'flex-start',
            }}
          >
            <VStack flex={1} w="full">
              <Box w="full" sx={{ svg: { width: '100%' } }}>
                <QRCode value={pixQrCode} />
              </Box>

              <CopyButton textToCopy={pixQrCode} isPurchaseByPixConfirmed={false} />
            </VStack>

            <Box order={{ base: -1, md: 1 }}>
              <Text as="strong">Siga os passos abaixo, para realizar o pagamento:</Text>

              <OrderedList mt={2} spacing={1}>
                <ListItem>Abra o aplicativo do seu banco no celular.</ListItem>
                <ListItem>
                  Selecione a opção "Ler QR code" e aponte a câmera do celular para o QR Code.
                </ListItem>
                <ListItem>
                  Você também pode clicar em "Copiar código" e Selecione a opção "Pix Copia e Cola"
                  no aplicativo do seu banco.
                </ListItem>
              </OrderedList>

              <HStack align="start" mt={5}>
                <AlertIcon style={{ marginTop: '2px', flexShrink: 0 }} color="#eb7129" />

                <VStack align="start" spacing={0}>
                  <Text as="span" color="#eb7129">
                    Observação
                  </Text>
                  <Text>
                    Você obterá acesso ao produto <span>imediatamente</span> após o pagamento.
                  </Text>
                </VStack>
              </HStack>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
