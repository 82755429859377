import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { axiosBackend } from '../api/Configuration';
import Toast from '../components/Toast';
import ErrorResponse from '../helpers/ErrorResponse';
import useFetch from '../hooks/useFetch';
import { IProgressResponse } from '../pages/NetflixMemberArea/pages/ContentsView/types';
import { useCourseStudent } from './CourseStudentContextV2';

type MarkAsDoneType = {
  contentId: string;
  moduleId: string;
  markAsDone: boolean;
};

type ProgressType = { progress: number; countContent: number };

type ContentType = {
  markAsDone: boolean;
};

interface IModule {
  contents: ContentType[];
}
interface IAuthContextData {
  amountContentViewed: number;
  markAsDoneHandleChange: (markAsDone: MarkAsDoneType) => void;
  progress: ProgressType;
}

const StudentProgressContext = createContext<IAuthContextData>({
  amountContentViewed: 0,
  markAsDoneHandleChange: () => '',
  progress: { progress: 0, countContent: 0 },
});

export default function StudentProgressContextProvider({ children }) {
  const [progress, setProgress] = useState<ProgressType>({ progress: 0, countContent: 0 });
  const [amountContentViewed, setAmountContentViewed] = useState(0);
  const { currentContent, onUpdateCurrentContent, course } = useCourseStudent();

  const { data: progressResponse, fetchData: getProgress } = useFetch<
    UnificadaFront.ResponseJSON<IProgressResponse>
  >({
    url: `/content-progress/${course?.id}/course`,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  const progressData = progressResponse?.data;

  useEffect(() => {
    if (course?.modules?.length) {
      const modulesWithContentViewed = course?.modules.map((module: IModule) =>
        module.contents?.filter((content: ContentType) => content?.markAsDone)
      );

      if (modulesWithContentViewed.length) {
        const totalContentViewed = modulesWithContentViewed?.map((content: any) => content?.length);

        if (totalContentViewed.length) {
          const amountContentViewed = totalContentViewed.reduce(
            (total: number, value: number) => (total += value)
          );

          setAmountContentViewed(amountContentViewed);
        }
      }
    }
  }, [course]);

  useEffect(() => {
    if (course?.id) {
      getProgress();
    }
  }, [course?.id, getProgress]);

  useEffect(() => {
    if (progressData) {
      setProgress({
        countContent: progressData.countContent,
        progress: parseFloat(progressData.progress?.toFixed(2)),
      });
    }
  }, [progressData]);

  const markAsDoneSubmit = useCallback(
    async (newMarkAsDone: MarkAsDoneType) => {
      const payload = { checkbox: newMarkAsDone.markAsDone };

      const { data: response } = await axiosBackend().patch(
        `/content-progress/${course?.id}/course/${newMarkAsDone.contentId}/content`,
        payload
      );

      return response;
    },

    [course?.id]
  );

  async function markAsDoneHandleChange(newMarkAsDone: MarkAsDoneType) {
    if (!course) return;

    const modulesWithContents = course?.modules.map(module => module.content?.length);

    if (!modulesWithContents.length) return;

    try {
      const { data: response } = await markAsDoneSubmit(newMarkAsDone);

      onUpdateCurrentContent(newMarkAsDone.moduleId, newMarkAsDone.contentId, {
        ...currentContent,
        markAsDone: newMarkAsDone.markAsDone,
      });

      setProgress({ ...progress, progress: response.progress });
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  return (
    <StudentProgressContext.Provider
      value={{
        progress,
        amountContentViewed,
        markAsDoneHandleChange,
      }}
    >
      {children}
    </StudentProgressContext.Provider>
  );
}

export function useStudentProgress() {
  const context = useContext(StudentProgressContext);

  if (!context)
    throw new Error('useProgressContext deve ser usada dentro de um ProgressContext.Provider.');

  return {
    progress: context.progress,
    amountContentViewed: context.amountContentViewed,
    markAsDoneHandleChange: context.markAsDoneHandleChange,
  };
}
