import { Flex } from '@chakra-ui/react';

export default function NetflixMemberAreaFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <Flex
      as="footer"
      align="center"
      py={4}
      textAlign="center"
      bgColor="#202123"
      width="full"
      justify="center"
      color="#ffffff"
      boxShadow="0px -4px 7px 0px rgba(0, 0, 0, 0.25)"
      mt={10}
    >
      © {currentYear} Proluno, ltda.
    </Flex>
  );
}
