import { Box, Button, Heading, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MdOutlineFilterAlt } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import Container from '../../../../components/ContainerSite/ContainerSite';
import CookieBanner from '../../../../components/CookieBanner';
import MainCard from '../../../../components/MainCard';
import Paginate from '../../../../components/Paginate';
import useFetch from '../../../../hooks/useFetch';
import useWindowSize from '../../../../hooks/useWindowSize';
import {
  IQuestionLibraryAreaData,
  IQuestionLibraryAreaHasPaginateResult,
  IQuestionLibraryDisciplineData,
  IQuestionLibraryDisciplineHasPaginateResult,
  IQuestionLibrarySubjectData,
  IQuestionLibrarySubjectHasPaginateResult,
} from '../../../DashboardAdmin/Courses/CourseManager/ModulesManager/QuestionLibrary/types';
import Filter from './components/Filter';

type FilterNameType = 'area' | 'discipline' | 'subject' | 'typeOfPayment' | 'price';

export interface IFilterParams {
  id: string;
  name: FilterNameType;
  displayName: string;
  isChecked: boolean;
}
export interface FilterProperties {
  id: string;
  name: string;
  displayName: string;
}

export interface IFilter {
  area: FilterProperties[];
  discipline: FilterProperties[];
  subject: FilterProperties[];
  typeOfPayment: FilterProperties[];
  price: FilterProperties[];
}

interface IQueryFilters {
  area: string[];
  discipline: string[];
  subject: string[];
  typeOfPayment: string[];
  price: string[];
}

const priorityFilters = ['area', 'discipline', 'subject'];

export async function populateFiltersByQuery(
  queryFilters: IQueryFilters,
  setFilters: React.Dispatch<React.SetStateAction<IFilter>>,
  availableAreas: IQuestionLibraryAreaData[],
  availableDisciplines: IQuestionLibraryDisciplineData[],
  availableSubjects: IQuestionLibrarySubjectData[]
) {
  // Função auxiliar para adicionar itens a um filtro específico, apenas se não existir
  const addToFilter = (filterName: string, itemId: string, itemName: string) => {
    setFilters(prevState => {
      // Verifique se o filtro já existe no estado
      if (!prevState[filterName]?.some(item => item.id === itemId)) {
        return {
          ...prevState,
          [filterName]: [
            ...(prevState[filterName] || []),
            {
              id: itemId,
              name: filterName,
              displayName: itemName,
            },
          ],
        };
      }
      return prevState; // Retorna o estado inalterado se o filtro já existir
    });
  };

  // Processar filtro de áreas
  for (const areaQuery of queryFilters.area) {
    const foundArea = availableAreas?.find(area => area.id === parseInt(areaQuery));
    if (foundArea) {
      addToFilter('area', foundArea.id.toString(), foundArea.name);
    }
  }

  // Processar filtro de disciplinas
  for (const disciplineQuery of queryFilters.discipline) {
    const foundDiscipline = availableDisciplines?.find(
      discipline => discipline.id === parseInt(disciplineQuery)
    );
    if (foundDiscipline) {
      addToFilter('discipline', foundDiscipline.id.toString(), foundDiscipline.name);
    }
  }

  // Processar filtro de assuntos
  for (const subjectQuery of queryFilters.subject) {
    const foundSubject = availableSubjects?.find(subject => subject.id === parseInt(subjectQuery));
    if (foundSubject) {
      addToFilter('subject', foundSubject.id.toString(), foundSubject.name);
    }
  }
  // Processar filtro de tipo de pagamento
  for (const typeOfPaymentQuery of queryFilters.typeOfPayment) {
    const displayName = typeOfPaymentQuery === 'free' ? 'Grátis' : 'paid' ? 'Pago' : 'Assinatura';
    addToFilter('typeOfPayment', typeOfPaymentQuery, displayName);
  }

  // Processar filtro de preços
  for (const priceQuery of queryFilters.price) {
    let displayName = '';

    switch (priceQuery) {
      case JSON.stringify({ gte: 0, lte: 100 }):
        displayName = 'R$0-R$100';
        break;
      case JSON.stringify({ gte: 100, lte: 200 }):
        displayName = 'R$100-R$200';
        break;
      case JSON.stringify({ gte: 200, lte: 300 }):
        displayName = 'R$200-R$300';
        break;
      case JSON.stringify({ gte: 300, lte: 400 }):
        displayName = 'R$300-R$400';
        break;
      case JSON.stringify({ gte: 400, lte: 500 }):
        displayName = 'R$400-R$500';
        break;
      case JSON.stringify({ gte: 500, lte: 1000 }):
        displayName = 'R$500-R$1000';
        break;
      case JSON.stringify({ gte: 1000, lte: null }):
        displayName = 'R$1000+';
        break;
      default:
        displayName = '';
    }
    addToFilter('price', priceQuery, displayName);
  }
}

function ProductsPage() {
  const history = useHistory();
  const location = useLocation();
  const { width } = useWindowSize();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const refreshURLSearchParams = () => new URLSearchParams(location.search);
  const urlSearchParams = useMemo(refreshURLSearchParams, [location.search]);
  const searchParams = urlSearchParams.toString();

  const queryFilters = useMemo(() => {
    return {
      area: JSON.parse(urlSearchParams.get('area')) || [],
      discipline: JSON.parse(urlSearchParams.get('discipline')) || [],
      subject: JSON.parse(urlSearchParams.get('subject')) || [],
      typeOfPayment: JSON.parse(urlSearchParams.get('typeOfPayment')) || [],
      price: JSON.parse(urlSearchParams.get('price')) || [],
    };
  }, [urlSearchParams]);

  const [filters, setFilters] = useState<IFilter>({
    area: [],
    discipline: [],
    subject: [],
    typeOfPayment: [],
    price: [],
  });

  const hasFilters = !!(
    filters.area.length ||
    filters.discipline.length ||
    filters.subject.length ||
    filters.typeOfPayment.length ||
    filters.price.length
  );

  const { data: productsData, loading: isFetching } = useFetch<UnificadaFront.ResponseJSON<any>>({
    url: `/marketplace/courses?${searchParams}`,
    method: 'get',
    autoFetch: true,
    authenticated: false,
  });

  const { data: areaData, loading: isLoadingAreaData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryAreaHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/home/question-library-area?hasPaginate=false&linkedToTheCourse=true`,
    autoFetch: true,
  });

  const { data: disciplineData, loading: isLoadingDisciplineData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryDisciplineHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/home/question-library-discipline?hasPaginate=false&linkedToTheCourse=true`,
    autoFetch: true,
  });

  const { data: subjectData, loading: isLoadingSubjectData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibrarySubjectHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/home/question-library-subject?hasPaginate=false&linkedToTheCourse=true`,
    autoFetch: true,
  });

  const response = productsData?.data;
  const products = response?.courses;
  const total = response?.total || 0;
  const perPage = response?.limit || 1;

  const isLoading = isFetching;
  const isProductsVisible = !isLoading && products?.length;

  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const category = urlSearchParams.get('category');
  const categoryId = urlSearchParams.get('categoryId');
  const search = urlSearchParams.get('search');
  const isSearching = !!searchParams;
  const showNameAllProducts =
    Object.values(filters).every(filter => filter.length === 0) && !categoryId && !search;
  const isNotFoundVisible = !isLoading && !products?.length && isSearching;
  const isEmpty = !isLoading && !products?.length && !isSearching;
  const pageCount = Math.ceil(total / perPage);
  const shouldShowPagination = !isLoading && pageCount > 1;

  const areas = areaData?.data?.data;
  const disciplines = disciplineData?.data?.data;
  const subjects = subjectData?.data?.data;
  const isLoadingFilters = isLoadingAreaData || isLoadingDisciplineData || isLoadingSubjectData;
  const loadedAllFilters =
    (areas?.length > 0 && disciplines?.length > 0 && subjects?.length > 0 && !!queryFilters) ||
    false;

  useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }));

  useEffect(() => {
    if (!isSearching || search) {
      setFilters({ area: [], discipline: [], subject: [], typeOfPayment: [], price: [] });
    }
  }, [isSearching, search]);

  // Converter cada array em uma string e juntá-los com '+'
  const searchFilter = Object.values(filters)
    .map(filter => {
      if (filter.length > 0) {
        return filter.map((name: { displayName: string }) => name.displayName).join(' + ');
      }
      return '';
    })
    .filter(str => str !== '')
    .join(' + ');

  const hiddenResults = !searchFilter && !categoryId && !search;

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({
      search: urlSearchParams.toString(),
    });
  }

  const handleCheckboxFilter = ({ id, name, displayName, isChecked }: IFilterParams) => {
    urlSearchParams.set('page', '1');

    if (name) {
      if (priorityFilters.includes(name)) {
        urlSearchParams.delete('search');
        urlSearchParams.delete('category');
        urlSearchParams.delete('categoryId');
      }

      const findFilterId = filters?.[name]?.find(filter => filter.id === id);

      const existingValues = urlSearchParams.get(name);

      if (!isChecked && !findFilterId) {
        if (existingValues) {
          // Se existirem valores, converta-os de volta em um array
          const existingArray = JSON.parse(existingValues);

          // Adicione o novo valor ao array existente, se ele ainda não estiver presente
          if (!existingArray.includes(id)) {
            existingArray.push(id);
          }

          // Defina o parâmetro da consulta com o array atualizado
          urlSearchParams.set(name, JSON.stringify(existingArray));
        } else {
          // Se não houver valores existentes, crie um novo array com o valor
          urlSearchParams.set(name, JSON.stringify([id]));
        }

        setFilters(prevState => {
          return {
            ...prevState,
            [name]: [...(prevState[name] || []), { id, name, displayName }],
          };
        });
      } else {
        const existingArray = JSON.parse(existingValues);

        // Verifique se o valor a ser removido está presente no array
        const indexToRemove = existingArray.indexOf(id);

        if (indexToRemove !== -1) {
          // O valor está no array, remova-o
          existingArray.splice(indexToRemove, 1);
        }

        if (existingArray.length === 0) {
          // Se o array estiver vazio, remova a chave do parâmetro de consulta
          urlSearchParams.delete(name);
        } else {
          // Caso contrário, atualize o parâmetro de consulta com o array modificado
          urlSearchParams.set(name, JSON.stringify(existingArray));
        }

        const removeFilter = filters?.[name]?.filter(filter => filter.id !== id);
        setFilters(prevState => ({ ...prevState, [name]: removeFilter }));
      }

      history.push({
        search: urlSearchParams.toString(),
      });
    }
  };

  useEffect(() => {
    if (loadedAllFilters) {
      populateFiltersByQuery(queryFilters, setFilters, areas, disciplines, subjects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedAllFilters, queryFilters]);

  return (
    <Container>
      <Box
        marginTop={{ base: '70px', md: '100px', lg: '100px' }}
        marginX={{ base: '15px', md: '108px', lg: '108px' }}
        id="productsPage"
      >
        <Stack
          direction={{ base: 'column', md: 'column', lg: 'row' }}
          spacing={{ base: '10px', md: 'auto', lg: 'auto' }}
          justifyContent={{ base: 'center', md: 'center', lg: 'space-between' }}
          margin={{
            base: '30px 0 15px 0',
            md: '40px 0 40px 0',
            lg: '40px 0 40px 0',
          }}
          alignItems={{ base: 'flex-start', md: 'center', lg: 'center' }}
        >
          <Text
            color="#202123"
            fontSize={{ base: '20px', md: '24px', lg: '24px' }}
            fontWeight="400"
            lineHeight={{ base: '20px', md: '40px', lg: '40px' }}
            hidden={!showNameAllProducts}
          >
            Todos os produtos
          </Text>

          <Text
            color="#202123"
            fontSize={{ base: '20px', md: '24px', lg: '24px' }}
            fontWeight="400"
            lineHeight={{ base: '20px', md: '40px', lg: '40px' }}
            width={{ base: 'full', md: '80%', lg: '80%' }}
            isTruncated
            hidden={hiddenResults}
          >
            Resultado para:{' '}
            <Text as="span" fontWeight="500">
              {category ? `"${category}"` : search ? `"${search}"` : ''}{' '}
              {searchFilter ? `"${searchFilter}"` : ''}
            </Text>
          </Text>

          <Text
            color="#202123"
            fontSize={{ base: '14px', md: '16px', lg: '16px' }}
            fontWeight="500"
            lineHeight={{ base: '20px', md: '40px', lg: '40px' }}
          >
            {total} {total === 1 ? 'resultado' : 'resultados'}
          </Text>
        </Stack>

        {width <= 700 && (
          <Stack direction="row" width="full" spacing={0} justifyContent="flex-end">
            <Button
              size="sm"
              ref={btnRef}
              variant="outline"
              bgColor="#FFF"
              borderColor="#8F8F90"
              _selected={{ bgColor: '#8F8F9050' }}
              color="#8F8F90"
              onClick={onOpen}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Text>Filtros</Text>
                <MdOutlineFilterAlt size="16px" color="#8F8F90" />
              </Stack>
            </Button>
          </Stack>
        )}

        <Filter
          filters={filters}
          handleCheckboxFilter={handleCheckboxFilter}
          areas={areas}
          disciplines={disciplines}
          subjects={subjects}
          isLoading={isLoadingFilters}
          btnRef={btnRef}
          isOpen={isOpen}
          onClose={onClose}
          setFilters={setFilters}
          total={total}
          drawer
        />

        <Stack
          direction="row"
          spacing={{ base: 0, md: '52px', lg: '52px' }}
          width="full"
          marginBottom="30px"
        >
          {width > 700 && (
            <Filter
              filters={filters}
              setFilters={setFilters}
              handleCheckboxFilter={handleCheckboxFilter}
              areas={areas}
              disciplines={disciplines}
              subjects={subjects}
              isLoading={isLoadingFilters}
              hasFilters={hasFilters}
              total={total}
            />
          )}

          {isNotFoundVisible && (
            <Box width="full" justifyContent="center" margin="15px" display="flex" flex={1}>
              <Heading fontSize="lg" color="#20212380">
                Nenhum resultado encontrado
              </Heading>
            </Box>
          )}

          {isEmpty && (
            <Box width="full" justifyContent="center" margin="15px" display="flex" flex={1}>
              <Heading fontSize="lg" color="#20212380">
                Nenhum curso cadastrado!
              </Heading>
            </Box>
          )}

          <Box
            width="full"
            display="flex"
            flexWrap="wrap"
            marginTop={{ base: '13px', md: '0', lg: '0' }}
            gap={{ base: '13px', md: '40px', lg: '40px' }}
            justifyContent="space-around"
            hidden={!isProductsVisible}
            marginBottom="30px"
          >
            {products?.map(product => (
              <MainCard
                key={product.id}
                course={product}
                loading={isLoading}
                checkoutType={product.checkoutType}
                grid={true}
              />
            ))}

            {shouldShowPagination && (
              <Stack width="full" justifyContent="center">
                <Paginate
                  marginBottom="30px"
                  pageCount={pageCount}
                  initialPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </Stack>
            )}
          </Box>
        </Stack>
      </Box>

      <CookieBanner />
    </Container>
  );
}

export default ProductsPage;
