import imageComingSoon from '../../assets/images/illustrations/undraw_coming_soon.svg';
import styles from './styles.module.css';

function ComingSoon() {
  return (
    <div className="nk-app-root">
      <div className="nk-main">
        <div className="column">
          {/* <div className={styles.eventImg}>
              <a
                href="https://materiais.proluno.com.br/evento-7-maneiras"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="d-lg-block d-md-none d-none" src={eventoDesktop} alt="..." />
                <img className="d-lg-none d-block" src={eventoMobile} alt="..." />
              </a>
            </div> */}

          {/* <a
              href="https://materiais.proluno.com.br/evento-7-maneiras"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.eventImg}></div>
            </a> */}

          <div className={styles.div_image}>
            <img src={imageComingSoon} alt="Acesso negado" className={styles.image} />
          </div>

          <div className={styles.div_text}>
            <h3 className={styles.text_coming_soon}>Em breve esse recurso estará disponível.</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
