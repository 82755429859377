import Joi from 'joi';
import { UserSchemaType } from '../../../validation/UserSchema';

export type RegisterSchemaType = Pick<UserSchemaType, 'email' | 'password' | 'fullName'> & {
  country: string;
  ddi: string;
  ddd: string;
  phone: string;
  isTermsAccepted: boolean;
};

const schema = Joi.object<RegisterSchemaType>().keys({
  fullName: Joi.string()
    .min(3)
    .max(50)
    .required()
    .messages({
      'string.min': 'Seu nome deve ter no mínimo 3 caracteres.',
      'string.max': 'Seu nome deve ter no máximo 50 caracteres.',
      'string.empty': 'Seu nome não pode ficar vazio.',
    } as JoiMessages.LanguageMessages),
  email: Joi.string()
    .email({ tlds: false })
    .max(256)
    .required()
    .messages({
      'string.empty': 'Seu e-mail não pode ficar vazio.',
      'string.email': 'Seu e-mail deve ser nesse formato “nome@email.com”.',
      'string.max': 'O e-mail deve ter no máximo 256 caracteres.',
    } as JoiMessages.LanguageMessages),
  password: Joi.string()
    .min(6)
    .max(60)
    .required()
    .messages({
      'string.empty': 'Sua senha não pode ficar vazia.',
      'string.min': 'Sua senha deve ter no mínimo 6 caracteres.',
      'string.max': 'Sua senha deve ter no máximo 60 caracteres.',
    } as JoiMessages.LanguageMessages),
  country: Joi.string().required(),
  ddi: Joi.string()
    .required()
    .messages({
      'string.empty': 'O código de DDI não pode ficar vazio.',
    } as JoiMessages.LanguageMessages),
  ddd: Joi.string(),
  phone: Joi.when('country', {
    is: 'br',
    then: Joi.string()
      .min(8)
      .max(20)
      .required()
      .messages({
        'string.empty': 'Seu telefone não pode ficar vazio.',
        'string.min': 'Seu telefone deve ter no mínimo 8 dígitos.',
        'string.max': 'Seu telefone deve ter no máximo 20 dígitos.',
      } as JoiMessages.LanguageMessages),
    otherwise: Joi.string()
      .max(20)
      .required()
      .messages({
        'string.empty': 'Seu telefone não pode ficar vazio.',
        'string.max': 'Seu telefone deve ter no máximo 20 dígitos.',
      } as JoiMessages.LanguageMessages),
  }),
  isTermsAccepted: Joi.boolean()
    .valid(true)
    .required()
    .messages({
      'any.only': 'Aceite o termos antes de continuar.',
    } as JoiMessages.LanguageMessages),
});

export default schema;
