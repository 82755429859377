import { Center, Spinner } from '@chakra-ui/react';

export default function Loading({ isLoading }: { isLoading: boolean }) {
  return (
    <Center
      inset={0}
      display={isLoading ? 'flex' : 'none'}
      position="absolute"
      zIndex={1}
      overflow="hidden"
      background="hsla(0,0%,100%,0.75)"
    >
      <Spinner color="default.500" boxSize="5rem" />
    </Center>
  );
}
