import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';

export default function ModalConfirmReset({ isOpen, onClose, onSubmit }) {
  const bgColor = useColorModeValue('#FFFFFF', '#202123');

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(3px)" />
      <ModalContent as="form" onSubmit={onSubmit} bgColor={bgColor}>
        <ModalHeader>Zerar estatísticas</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="md">Tem certeza que deseja zerar as estatísticas?</Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            Não
          </Button>
          <Button colorScheme="red" type="submit">
            Sim
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
