import { Box } from '@chakra-ui/react';
import { QuestionStatistic } from '../../..';
import PerformanceChart from './PerformanceChart';
import AnswersByAlternativeChart from './AnswersByAlternativeChart';

export default function QuestionStatistics({
  questionStatistic,
}: {
  questionStatistic: QuestionStatistic;
}) {
  const { correct, wrong, answersByAlternative } = questionStatistic;

  const total = correct + wrong;

  return (
    <Box>
      <PerformanceChart performance={{ correct, wrong }} />
      <AnswersByAlternativeChart answersByAlternativeList={answersByAlternative} total={total} />
    </Box>
  );
}
