import { useToast } from '@chakra-ui/react';
import path from 'path';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { getDDI } from '../../../../components/CountrySelect';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../helpers/UploadHelper';
import useHandleChange from '../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import useLocales from '../../../../hooks/useLocales';
import scrollToInvalidInput from '../../../../utils/scrollToInvalidInput';
import { JoiSchemasType } from '../../../../validation/EntitySchema';
import { UserProfile } from '../types';

interface useProfileFormProps {
  user?: UserProfile;
  successMessage?: string;
  schemaValidator: JoiSchemasType<Partial<UserProfile>>;
}

interface ImageFile {
  preview: string;
  file?: File;
}

export default function useProfileForm({
  user,
  successMessage,
  schemaValidator,
}: useProfileFormProps) {
  const [photo, setPhoto] = useState<ImageFile>({ preview: user?.photo });
  const [coverBanner, setCoverBanner] = useState<ImageFile>({ preview: user?.coverBanner });
  const [isUploading, setIsUploading] = useState(false);
  const [country, setCountry] = useState('br');
  const [ddi, setDDI] = useState('55');

  const toast = useToast();

  const isInstructor = user.role === 'INSTRUTOR';

  const {
    form,
    onChanged: hasChange,
    setForm,
    handleChange,
    setOnChanged: setHasChange,
    handleCancel,
  } = useHandleChange<UserProfile>({
    photo: user?.photo,
    fullName: user?.fullName,
    email: user?.email,
    ddd: user?.ddd,
    phone: user?.phone,
    biography: user?.biography,
    coverBanner: user?.coverBanner,
    documentNumber: user?.documentNumber,
    ...(!!user.socialNetwork.length && { socialNetwork: user.socialNetwork }),
    ...user.address,
  });

  useEffect(() => {
    if (user?.address?.country) {
      setCountry(user?.address?.country);
    }
  }, [user?.address?.country]);

  const { cities, states } = useLocales(form?.state);

  function handleSocialNetworkChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setHasChange(true);

    // Verifica se form.socialNetwork está definido e se já existe uma rede social com o mesmo tipo
    const networkExists =
      form?.socialNetwork && form?.socialNetwork?.some(network => network.type === name);

    if (networkExists) {
      // Se já existir uma rede social com o mesmo tipo, atualize-a
      setForm({
        ...form,
        socialNetwork: form.socialNetwork.map(network =>
          network.type === name ? { ...network, url: value } : network
        ),
      });
      return;
    }

    // Se não existir, adicione-a
    const newSocialNetwork = { type: name, url: value } as any;
    setForm({ ...form, socialNetwork: [...(form.socialNetwork || []), newSocialNetwork] });
  }

  async function uploadFile(file: File) {
    return await UploadHelper.upload(file, 'profiles');
  }

  function handleUploadError(error) {
    toast({
      title: ErrorResponse(error),
      status: 'error',
    });
  }

  async function handleDropFile(name: 'coverBanner' | 'photo', acceptedFiles) {
    try {
      setHasChange(true);
      setIsUploading(true);

      const [file] = acceptedFiles;
      const preview = URL.createObjectURL(file);

      const { newFileName } = await uploadFile(file);

      setForm({ ...form, [name]: newFileName });

      if (name === 'coverBanner') {
        setCoverBanner({ preview, file });
      } else {
        setPhoto({ preview, file });
      }
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
    }
  }

  function handleDropRejected() {
    toast({
      title: 'Arquivo inválido!',
      description: 'Formatos suportados: JPG, PNG, JPEG',
      status: 'error',
    });
  }

  function handleSelectNewImage(name: 'coverBanner' | 'photo') {
    setHasChange(true);

    setForm({ ...form, [name]: '' });
  }

  function handleStateChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    setForm(prevForm => ({
      ...prevForm,
      city: '',
      state: value,
    }));

    setHasChange(true);
  }

  const handleCountryChange = useCallback(
    (country: string) => {
      setHasChange(true);

      if (country !== 'br') {
        setForm(prevForm => ({
          ...prevForm,
          ddd: null,
          state: null,
          city: null,
          neighborhood: null,
          streetAddress: null,
          streetNumber: null,
          complementary: null,
          zipCode: null,
        }));
      }

      if (country === 'br') {
        setForm(prevForm => ({
          ...prevForm,
          alternateAddress: null,
        }));
      }

      setDDI(getDDI(country));
      setCountry(country);
    },
    [setForm, setHasChange]
  );

  function handleDescriptionChange(value: string) {
    setForm(prevForm => ({
      ...prevForm,
      biography: value,
    }));

    setHasChange(true);
  }

  const payload: Partial<UserProfile> = form
    ? Object.keys(form)
        .map(key => {
          const value = form[key];
          return [key, typeof value === 'string' && value.trim() === '' ? null : value];
        })
        .reduce((acc, [key, value]) => {
          acc[key as keyof UserProfile] = value;
          return acc;
        }, {} as Partial<UserProfile>)
    : {};

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: { ...payload, ddi, country },
    schemaValidator,
    url: 'users',
    method: 'patch',
    authenticated: true,
    removeNullProps: false,
    onSuccess: { message: successMessage },
    isDebug: {
      log: true,
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    setForm(prevForm => {
      prevForm.photo = prevForm.photo && path.basename(prevForm.photo);
      prevForm.coverBanner = prevForm.coverBanner && path.basename(prevForm.coverBanner);

      return prevForm;
    });

    scrollToInvalidInput();

    await handleSubmit();
  }
  return {
    form,
    formValidation,
    isSubmitting,
    isUploading,
    photo,
    country,
    coverBanner,
    hasChange,
    isInstructor,
    cities,
    states,
    handleChange,
    onSubmit,
    handleSelectNewImage,
    handleDropFile,
    handleDropRejected,
    handleSocialNetworkChange,
    handleCancel,
    handleStateChange,
    handleCountryChange,
    handleDescriptionChange,
  };
}
