import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu as ChakraMenu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { MdOutlineCheck, MdSearch as SearchIcon } from 'react-icons/md';
import FilterType from '../../../../types/Filter';
import Question from '../../../../types/Question';

const scrollbarStyle = {
  '::-webkit-scrollbar': { width: '0.5rem', background: 'transparent' },
  '::-webkit-scrollbar-thumb': {
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    borderRadius: 'md',
    backgroundColor: 'gray.500',
  },
  '::-webkit-scrollbar-thumb:hover': {
    bg: 'gray.600',
  },
};

const activeStyle = {
  bg: 'transparent',
  outline: '1px solid #EB7129',
  boxShadow: 'none',
  p: {
    color: '#EB7129 !important',
  },
};

interface MenuProps {
  name: string;
  label: string;
  value?: string | number;
  menuItems: any[];
  error?: string;
  onAddFilter?: (filter: Partial<FilterType>) => void;
  onQuestionBeingUpdatedChange: (question: Partial<Question>) => void;
}

export default function Menu({
  name,
  label,
  value,
  menuItems,
  error,
  onQuestionBeingUpdatedChange,
}: MenuProps) {
  const [searchText, setSearchText] = useState('');
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(!!error);

  function toggleTooltip() {
    setIsTooltipOpen(!isTooltipOpen);
  }

  const [selectedItem, setSelectedItem] = useState(
    menuItems?.find(item => item.id === Number(value))?.name
  );

  const selectedItemRef = useRef(null);

  function scrollToSelectedItem() {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({ block: 'nearest' });
    }
  }

  useEffect(() => {
    if (isMenuOpen) {
      const timeout = setTimeout(() => {
        scrollToSelectedItem();
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    setSelectedItem(menuItems?.find(item => item.id === Number(value))?.name);
  }, [menuItems, name, value]);

  useEffect(() => {
    const handleSearch = setTimeout(() => {
      const filteredItems = menuItems?.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );

      setFilteredMenuItems(filteredItems);
    }, 500);

    return () => clearTimeout(handleSearch);
  }, [searchText, menuItems]);

  function handleSelectItem(filterId: number) {
    const filterBeingAdded = menuItems?.find(option => option.id === Number(filterId));

    if (name === 'area') {
      onQuestionBeingUpdatedChange({
        questionLibraryArea: {
          id: filterId,
          name: filterBeingAdded?.name,
        },
        questionLibraryDiscipline: null,
        questionLibrarySubject: null,
      });
    }

    if (name === 'discipline') {
      onQuestionBeingUpdatedChange({
        questionLibraryArea: {
          id: filterBeingAdded?.area.id,
          name: filterBeingAdded?.area.name,
        },
        questionLibraryDiscipline: {
          id: filterId,
          name: filterBeingAdded?.name,
        },
        questionLibrarySubject: null,
      });
    }

    if (name === 'subject') {
      onQuestionBeingUpdatedChange({
        questionLibraryArea: {
          id: filterBeingAdded?.area.id,
          name: filterBeingAdded?.area.name,
        },
        questionLibraryDiscipline: {
          id: filterBeingAdded?.discipline.id,
          name: filterBeingAdded?.discipline.name,
        },
        questionLibrarySubject: {
          id: filterId,
          name: filterBeingAdded?.name,
        },
      });
    }

    if (name === 'institution') {
      onQuestionBeingUpdatedChange({
        questionLibraryInstitution: {
          id: filterId,
          name: filterBeingAdded?.name,
        },
      });
    }

    setSelectedItem(filterBeingAdded?.name);
  }

  const hasError = !selectedItem && !!error;

  return (
    <ChakraMenu onOpen={() => setIsMenuOpen(true)} onClose={() => setIsMenuOpen(false)}>
      <Tooltip
        label={hasError ? error : selectedItem || label}
        placement="top"
        hasArrow
        fontSize="xs"
        bg={hasError ? 'red.500' : 'default.500'}
        gutter={12}
        isOpen={hasError || isTooltipOpen}
      >
        <MenuButton
          display="flex"
          as={Button}
          isTruncated
          maxW={120}
          variant="ghost"
          borderRadius="md"
          size="xs"
          alignItems="center"
          fontWeight="normal"
          _hover={!hasError && activeStyle}
          _expanded={activeStyle}
          _focus={!hasError && activeStyle}
          mx={2}
          overflow="hidden"
          transition="all 0.2s"
          outlineColor={hasError && 'red.500'}
          outline={hasError && '1px solid red'}
          onMouseEnter={toggleTooltip}
          onMouseLeave={toggleTooltip}
        >
          <Text color={hasError ? 'red.500' : '#AFAFB0'} mr={2} isTruncated>
            {selectedItem || label}
          </Text>
        </MenuButton>
      </Tooltip>

      <MenuList
        p={2.5}
        border="none"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        minWidth="16rem"
      >
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} color="#2021235C" boxSize={8} mt={-1} ml={3} />
          </InputLeftElement>

          <Input
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            type="text"
            placeholder={`Buscar ${label.toLowerCase()}`}
            size="sm"
            borderRadius="md"
            mb={1.5}
            focusBorderColor="default.500"
            _placeholder={{ color: '#0000005C' }}
            borderColor="gray.200"
            pl={12}
          />
        </InputGroup>

        <VStack maxHeight="12rem" overflowY="auto" color="#AFAFB0" sx={scrollbarStyle}>
          {filteredMenuItems?.length === 0 ? (
            <Text>Nenhum resultado encontrado...</Text>
          ) : (
            <>
              {filteredMenuItems?.map(item => (
                <MenuItem
                  key={item.name}
                  onClick={() => handleSelectItem(item.id)}
                  ref={selectedItem === item.name ? selectedItemRef : null}
                >
                  <Flex align="center" w={5} mr={4}>
                    {selectedItem === item.name && (
                      <Icon as={MdOutlineCheck} color="#EB7129" boxSize={7} />
                    )}
                  </Flex>

                  <Text>{item.name}</Text>
                </MenuItem>
              ))}
            </>
          )}
        </VStack>
      </MenuList>
    </ChakraMenu>
  );
}
