import {
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { useRef } from 'react';
import { IoReload } from 'react-icons/io5';

export default function ResetQuestion({ isLoading, handleSubmit, rightAnswer }) {
  const bgColor = useColorModeValue('#ffffff', '#37393d');
  const colorScheme = useColorModeValue('blackAlpha', 'gray');

  const initRef = useRef();

  return (
    <Popover closeOnBlur={false} placement="bottom-start" initialFocusRef={initRef}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Stack
              direction="row"
              alignItems="center"
              spacing="2px"
              color="#EC7117"
              fontSize="14px"
              cursor="pointer"
              hidden={rightAnswer || isLoading}
            >
              <IoReload />
              <Text
                fontStyle="normal"
                fontWeight="500"
                lineHeight="20px"
                textDecorationLine="underline"
              >
                Refazer questão
              </Text>
            </Stack>
          </PopoverTrigger>

          <Portal>
            <PopoverContent bgColor={bgColor}>
              <PopoverHeader fontWeight="semibold">
                Tem certeza que deseja refazer essa questão?
              </PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <HStack justifyContent="flex-end">
                  <Button colorScheme="red" width="70px" size="sm" onClick={onClose} ref={initRef}>
                    Não
                  </Button>

                  <Button
                    width="70px"
                    colorScheme={colorScheme}
                    variant="outline"
                    size="sm"
                    isDisabled={isLoading}
                    onClick={() => {
                      handleSubmit();
                      onClose();
                    }}
                  >
                    Sim
                  </Button>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
}
