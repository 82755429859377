import Joi from 'joi';
import { ICheckoutSettingsEditCustomization } from './types';

export default Joi.object<ICheckoutSettingsEditCustomization>().keys({
  countdown: Joi.number().positive(),
  desktopTopImage: Joi.string().default(null).allow(null),
  mobileTopImage: Joi.string().default(null).allow(null),
  useTopDesktopImageOnMobile: Joi.boolean().default(false),
  desktopBottomImage: Joi.string().default(null).allow(null),
  mobileBottomImage: Joi.string().default(null).allow(null),
  useBottomDesktopImageOnMobile: Joi.boolean().default(false),
});
