import { Box } from '@chakra-ui/react';
import call from '../../../../../assets/icons/call.svg';
import styles from './styles.module.css';
import { useCourseStudent } from '../../../../../contexts/CourseStudentContextV2';

function NoContentView() {
  const { course } = useCourseStudent();

  if (!course?.modules?.length) return <div></div>;

  return (
    <Box className={styles.containerNoContent}>
      <div className={styles.content}>
        <img src={call} alt="Imagem de três bonecos na tela de um computador." />
        <h3>Visualize todos os conteúdos!</h3>
        <p>
          Aqui é onde você irá visualizar os conteúdos de todos os módulos do produto adquirido.
          Clique no módulo{' '}
          <b style={{ fontWeight: 600 }}>
            {course?.modules?.length ? course?.modules[0]?.name : '...'}
          </b>{' '}
          para começar.
        </p>
      </div>
    </Box>
  );
}

export default NoContentView;
