import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Modal } from 'react-bootstrap';
import ContentAPI from '../../../../../../api/Content';
import EditorRichText from '../../../../../../components/EditorRichText';
import DataLoading from '../../../../../../components/Loadings/DataLoading';
import Spinner from '../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';

const initialValues = {
  title: '',
  order: 1,
  contentType: 'AO_VIVO_YOUTUBE',
  status: 'ATIVO',
  link: '',
  content: '',
  broadcastDate: '',
};

const ADD_WITH_CURRENT_ORDER = 1;

const YouTubeLiveForm = () => {
  const [values, setValues] = useState(initialValues);
  const [editorState, setEditorState] = useState('');
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [changed, setChanged] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const history = useHistory();
  const { id, moduleId, contentId } = useParams();

  const { course, setCourse, getContent } = useCourse();

  useEffect(() => {
    const content = getContent(moduleId, contentId);

    if (!contentId) {
      return setValues(initialValues);
    }

    if (content) {
      setEditorState(content.content || '');

      setValues({
        title: content.title,
        order: content.order,
        contentType: content.contentType,
        status: content.status,
        link: content.link,
        content: content.content,
        broadcastDate: content.broadcastDate,
      });
    }
  }, [contentId, getContent, moduleId]);

  useEffect(() => {
    if (contentId) setIsDisabled(true);
  }, [contentId]);

  function handleChange(event) {
    const { name, value } = event.target;

    setIsDisabled(false);

    setErrorMessage('');

    setValues({ ...values, [name]: value });
  }

  function handleEditorStateChange(editorState) {
    setIsDisabled(false);
    setErrorMessage('');
    setChanged(true);
    setEditorState(editorState);
    setValues(prevValues => ({ ...prevValues, content: editorState }));
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setErrorMessage(null);
    setIsLoading(true);

    if (!contentId) {
      try {
        const currentModule = course.modules.find(module => module.id === Number(moduleId));
        const { contents } = currentModule;

        if (contents.length) {
          const orders = contents.map(content => content.order);
          values.order = values.order = orders.length
            ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
            : initialValues.order;
        }

        const newContent = {
          ...values,
          order: values.order,
        };

        const { data: createdContent } = await ContentAPI.store(id, moduleId, newContent);

        const newContents = [...currentModule.contents, createdContent];

        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({
          ...course,
          modules: newModules,
        });

        setIsLoading(false);
        Toast('Conteúdo cadastrado com sucesso');

        history.push(
          `/courses/${id}/course-manager/course/modules/${moduleId}/contents/${createdContent.id}/edit-live-youtube-content`
        );
      } catch (error) {
        setErrorMessage(ErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
      return;
    }

    try {
      const updatedContent = { ...values };

      await ContentAPI.update(id, moduleId, contentId, updatedContent);

      const currentModule = course.modules.find(module => module.id === Number(moduleId));

      const newContents = currentModule.contents.map(content =>
        content.id === Number(contentId) ? values : content
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      Toast('Conteúdo atualizado com sucesso');
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();

    try {
      setIsLoading(true);
      await ContentAPI.delete(id, moduleId, contentId);

      const currentModule = course.modules.find(module => module.id === Number(moduleId));

      const newContents = currentModule.contents.filter(module => module.id !== Number(contentId));

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      setIsShowModal(false);
      Toast('Conteúdo excluído com sucesso');
      history.push(`/courses/${id}/course-manager/course/modules/${moduleId}`);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    }
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    setIsDisabled(true);

    if (contentId) {
      const content = getContent(moduleId, contentId);

      setEditorState(content.content || '');

      setValues(content);
      return;
    }

    setEditorState(initialValues.content);

    setValues(initialValues);

    setErrorMessage('');
  }

  return (
    <Stack marginX={10} marginBottom={10}>
      <Heading fontSize="md">{values.title || 'Novo conteúdo ao vivo (YouTube)'}</Heading>

      <Stack
        as="form"
        onSubmit={handleSubmit}
        borderRadius="10px"
        border="1px solid #20212350"
        padding="30px"
        spacing="20px"
      >
        <FormControl>
          <FormLabel>Título</FormLabel>
          <Input
            size="sm"
            borderRadius={6}
            value={values.title}
            placeholder="Digite um título para o seu conteúdo"
            onChange={handleChange}
            name="title"
            id="title"
            type="text"
            minLength={1}
            maxLength={255}
            isRequired
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel>Link da aula</FormLabel>
          <Input
            size="sm"
            borderRadius={6}
            value={values.link}
            placeholder="Ex.: https://youtu.be/XqZsoesa55w"
            onChange={handleChange}
            name="link"
            id="link"
            type="url"
            minLength="1"
            maxLength="80"
            isRequired
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Descrição (opcional)</FormLabel>
          <EditorRichText value={editorState} onChange={handleEditorStateChange} />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>

        <Text>Quando será?</Text>

        <FormControl width="50%">
          <FormLabel>Data e hora</FormLabel>
          <Input
            name="broadcastDate"
            type="datetime-local"
            placeholder="Selecione a data e hora"
            value={dayjs(values.broadcastDate).format('YYYY-MM-DD HH:mm')}
            size="sm"
            onChange={handleChange}
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>

        <DataLoading loading={isLoading} className="d-flex justify-content-center mt-4">
          {errorMessage && !isLoading && (
            <div className="mt-4">
              <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
                <div className="text-center">{errorMessage}</div>
              </div>
            </div>
          )}

          <HStack width="full" justifyContent="space-between">
            {contentId && (
              <Button
                size="sm"
                variant="outline"
                colorScheme="red"
                onClick={event => {
                  event.preventDefault();
                  setIsShowModal(true);
                }}
              >
                Excluir conteúdo
              </Button>
            )}

            <HStack>
              {!isDisabled && (
                <Button size="sm" onClick={handleDiscardChanges} isDisabled={isDisabled}>
                  Descartar mudanças
                </Button>
              )}

              {!errorMessage && (
                <Button size="sm" type="submit" colorScheme="primary" isDisabled={!changed}>
                  Salvar
                </Button>
              )}
            </HStack>
          </HStack>
        </DataLoading>
      </Stack>

      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="mb-1 py-4">
            <h3 className="text-center">Você tem certeza que deseja excluir o conteúdo?</h3>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <button onClick={() => setIsShowModal(false)} className="btn btn-light mr-4">
              Cancelar
            </button>
            <button onClick={handleDelete} className="btn btn-danger" disabled={isLoading}>
              {isLoading ? <Spinner small /> : 'Excluir'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </Stack>
  );
};

export default YouTubeLiveForm;
