import { Box, Button, HStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setTimeout } from 'timers';
import CourseAPI from '../../../../../api/Course';
import QuestionsAPI from '../../../../../api/Question';
import Loader from '../../../../../components/Loadings/DotsFalling';
import Pagination from '../../../../../components/Pagination';
import Toast from '../../../../../components/Toast';
import { useStudentCourse } from '../../../../../contexts/CourseStudentContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import { sortByOrder } from '../../../../../helpers/SortHelper';
import List from './List';
import Modal from './Modal';
import Question from './Question';
import Report from './Report';
import styles from './styles.module.css';
import { scrollbarStyle } from '..';

interface IParams {
  courseId: string;
  moduleId: string;
  contentId: string;
}

const initialStateContent = {
  title: '...',
  numberOfTimesAnswerQuestion: 0,
  content: null,
  contentType: '',
  id: 0,
  markAsDone: false,
  order: 0,
  status: '',
};

function ViewQuestions() {
  const [questions, setQuestions] = useState([]);
  const [pageCount, setPageCount] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfTimesRegistrations, setNumberOfTimesRegistrations] = useState(0);
  const [numberOfTimesRemaining, setNumberOfTimesRemaining] = useState(0);
  const [content, setContent] = useState(initialStateContent);
  const [isStarted, setIsStarted] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCheckingAnswers, setIsCheckingAnswers] = useState(false);

  const { courseId, moduleId, contentId } = useParams<IParams>();
  const { studentCourse, getContent } = useStudentCourse();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    });

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (studentCourse) {
      const currentContent = getContent(studentCourse, moduleId, contentId);
      if (currentContent) {
        setContent(currentContent);
      }
    }
  }, [contentId, getContent, moduleId, studentCourse]);

  const updateLastVisit = useCallback(async () => {
    try {
      await CourseAPI.updateLastVisit(courseId, moduleId, contentId, content?.contentType);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }, [content?.contentType, contentId, courseId, moduleId]);

  useEffect(() => {
    const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

    if (role === 'ALUNO' && studentCourse) {
      updateLastVisit();
    }
  }, [updateLastVisit, studentCourse]);

  useEffect(() => {
    async function getQuestions() {
      try {
        setIsLoading(true);
        const response = await QuestionsAPI.student.verifyIsFinished(courseId, moduleId, contentId);

        const { finished, numberOfTimesRegistrations, numberOfTimesRemaining } = response?.data;

        setIsFinished(finished);

        setNumberOfTimesRegistrations(numberOfTimesRegistrations);
        setNumberOfTimesRemaining(numberOfTimesRemaining);

        const { data: studentQuestionData } = await QuestionsAPI.student.index(
          courseId,
          moduleId,
          contentId,
          currentPage + 1
        );

        if (studentQuestionData) {
          setQuestions(studentQuestionData.questions);
          setPageCount(studentQuestionData.total / studentQuestionData.limit);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
      setIsLoading(false);
    }

    if (content) {
      getQuestions();
    }
  }, [content, contentId, courseId, currentPage, moduleId]);

  function handlePageChange({ selected: selectedPage }) {
    setIsLoading(true);

    setCurrentPage(selectedPage);
  }

  async function startSurvey() {
    try {
      const response = await QuestionsAPI.student.start(courseId, moduleId, contentId);
      const { started } = response.data;
      setIsStarted(started);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  function toggleModal() {
    setIsShowModal(prevState => !prevState);
  }

  async function handleClick() {
    try {
      setIsCheckingAnswers(true);
      const response = await QuestionsAPI.student.checkAnswers(courseId, moduleId, contentId);
      const { isAllQuestionsAnswered } = response?.data;
      if (!isAllQuestionsAnswered) {
        Toast('Você precisa responder todas as questões antes de finalizar o conteúdo', 'error');
        return;
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsCheckingAnswers(false);
    }

    toggleModal();
  }

  async function finishSurvey() {
    try {
      const response = await QuestionsAPI.student.finish(courseId, moduleId, contentId);

      const { finished } = response?.data;

      setIsFinished(finished);

      setIsStarted(false);

      Toast('Questões finalizadas com sucesso!', 'success');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      toggleModal();
    }
  }

  async function answerQuestion(questionId, optionId) {
    if (!isStarted) await startSurvey();

    try {
      await QuestionsAPI.student.update(courseId, moduleId, contentId, questionId, optionId);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const shouldDisplayLoader = isLoading;
  const shouldDisplayReport = !isLoading && isFinished;
  const shouldDisplayQuestions = !isLoading && isFinished === false && questions.length > 0;

  return (
    <Box
      w="full"
      overflow="auto"
      sx={scrollbarStyle}
      margin={{ base: '0', md: '20px 70px', lg: '20px 70px' }}
    >
      {shouldDisplayLoader && (
        <div className="container d-flex justify-content-center my-5">
          <Loader />
        </div>
      )}

      {shouldDisplayReport && (
        <Report
          contentTitle={content.title}
          contentType={content.contentType}
          courseId={courseId}
          moduleId={moduleId}
          contentId={contentId}
        />
      )}

      {shouldDisplayQuestions && (
        <div className="container pb-5 px-5">
          <div className="row">
            <div className={`col ${styles.card}`}>
              <h3 style={{ fontSize: '30px', fontWeight: 600 }}>{content?.title}</h3>

              <List>
                {questions.sort(sortByOrder).map(question => (
                  <Question
                    key={question.id}
                    id={question.id}
                    order={question.order}
                    questionText={question.questionText}
                    options={question.options}
                    userOptionAnswer={question.userOptionAnswer}
                    onAnswerQuestion={answerQuestion}
                    isDisabled={isFinished}
                  />
                ))}
              </List>
            </div>

            {pageCount > 1 && (
              <div className="col-12 mb-5">
                <Pagination
                  onPageActive={currentPage}
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>

          {pageCount <= currentPage + 1 && !isFinished && (
            <HStack justify="end">
              <Button onClick={handleClick} colorScheme="default" isLoading={isCheckingAnswers}>
                Enviar respostas
              </Button>
            </HStack>
          )}
        </div>
      )}

      <Modal
        isShowModal={isShowModal}
        numberOfTimesRegistrations={numberOfTimesRegistrations}
        numberOfTimesRemaining={numberOfTimesRemaining}
        onHideModal={() => setIsShowModal(false)}
        onConfirmAction={() => finishSurvey()}
      />
    </Box>
  );
}

export default ViewQuestions;
