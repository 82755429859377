import styles from '../styles.module.css';

function DefaultImage({ title }) {
  return (
    <div
      className={styles.defaultImage}
      style={{
        background: `#EB7129`,
      }}
    >
      <span style={{ color: '#ffffff' }}>{title}</span>
    </div>
  );
}

export default DefaultImage;
