import { Th as ChakraTh, Td as ChakraTd } from '@chakra-ui/react';

function Th({ ...props }) {
  return (
    <ChakraTh
      py="2"
      px="0"
      textAlign="center"
      fontSize="lg"
      fontWeight="700"
      textTransform="capitalize"
      color="dark.500"
      borderColor="none"
      borderBottom="none"
      {...props}
    >
      {props.children}
    </ChakraTh>
  );
}

function Td({ ...props }) {
  return (
    <ChakraTd
      py="2"
      px="0"
      textAlign="center"
      fontSize="md"
      fontWeight="500"
      color="dark.500"
      borderColor="none"
      borderBottom="none"
      {...props}
    >
      {props.children}
    </ChakraTd>
  );
}

export { Td, Th };
