import Joi from 'joi';
import { EntitySchema } from './EntitySchema';

export type UserSchemaType = {
  fullName: string;
  email: string;
  password: string;
};

const UserSchema: EntitySchema<UserSchemaType> = {
  fullName: Joi.string().messages({
    'string.empty': 'O nome não pode ficar vazio.',
    'string.base': 'Informe um nome válido',
  } as JoiMessages.LanguageMessages),
  email: Joi.string()
    .email({ tlds: false })
    .messages({
      'string.empty': 'O e-mail não pode ficar vazio.',
      'string.email': 'Informe um e-mail válido.',
    } as JoiMessages.LanguageMessages),
  password: Joi.string()
    .min(6)
    .messages({
      'string.empty': 'A senha não pode ficar vazia.',
      'string.min': 'A senha deve conter no mínimo 6 carecteres',
    } as JoiMessages.LanguageMessages),
};

export default UserSchema;
