import { Container, Flex, useDisclosure } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { routesWithoutSidebar } from '../../routes/AdminRoutes';
import Header from './Header';
import Sidebar from './Sidebar';
import { TOKEN_ORIGIN_LOGIN } from '../../helpers/LocalStorageHelper';

const SIDEBAR_WIDTH = 15.0625;
const MAIN_MARGIN_LEFT = `${SIDEBAR_WIDTH + 1.25}rem`;

interface ILayoutProps {
  children: ReactNode;
  isLoading?: boolean;
}

export default function Layout({ children }: ILayoutProps) {
  const { isOpen, onClose, onToggle } = useDisclosure();

  const tokenOrigin = localStorage.getItem(TOKEN_ORIGIN_LOGIN);

  const withoutSidebar = routesWithoutSidebar.some(route =>
    window.location.pathname?.includes(route)
  );

  if (withoutSidebar) return <>{children}</>;

  return (
    <Flex overflow="hidden">
      <Sidebar onToggleSidebar={onToggle} isOpen={isOpen} onClose={onClose} />
      <Container
        as="main"
        maxWidth="8xl"
        pt={{ base: 16, lg: tokenOrigin ? '152px' : '130px' }}
        pb={{ base: 5 }}
        pr={{ lg: 8 }}
        pl={{ base: '14px', xl: 4 }}
      >
        <Header onToggleSidebar={onToggle} hidden={withoutSidebar} />
        <Flex direction="column" ml={{ lg: MAIN_MARGIN_LEFT }}>
          {children}
        </Flex>
      </Container>
    </Flex>
  );
}
