import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import process from 'process/browser';
import { FormEvent, useEffect, useState } from 'react';
import useFetch from '../../../../hooks/useFetch';
import useHandleChange from '../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import Header from '../components/Header';
import CopyButton from './CopyButton';
import schemaValidator from './schemaValidator';

export interface ICredentials {
  clientId: string;
  clientSecret: string;
  isIntegration?: boolean;
}

const IS_PRODUCTION = process?.env.NODE_ENV === 'production';
const NGROK_URL = process?.env.REACT_APP_NGROK_URL;
const BASE_URL = process?.env.REACT_APP_BASE_URL_API;

export default function Zoom() {
  const [isURICopied, setIsURICopied] = useState(false);
  const [hasCredentials, setHasCredentials] = useState(false);
  const [hasIntegration, setHasIntegration] = useState(false);

  const toast = useToast();

  const { data: credentialsResponse, loading: isFetchingCredentials } = useFetch<
    UnificadaFront.ResponseJSON<ICredentials>
  >({
    url: '/platforms/zoom-token',
    method: 'get',
    authenticated: true,
    autoFetch: true,
  });

  const credentialsData = credentialsResponse?.data;

  useEffect(() => {
    if (credentialsData) {
      setHasCredentials(true);
      setHasIntegration(credentialsData?.isIntegration);
    }
  }, [credentialsData]);

  const {
    form,
    onChanged: hasChange,
    handleChange,
  } = useHandleChange<ICredentials>(
    {
      clientId: '',
      clientSecret: '',
    },
    credentialsData && {
      clientId: credentialsData.clientId,
      clientSecret: credentialsData.clientSecret,
    }
  );

  const clientId = form?.clientId;
  const redirectURI = `${IS_PRODUCTION ? BASE_URL : NGROK_URL}/auth/zoom/${clientId}/callback`;
  const integrationURL = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectURI}`;

  const {
    formValidation,
    isLoading: isSubmitting,
    handleSubmit,
  } = useHandleSubmit({
    data: form,
    schemaValidator,
    url: '/platforms/zoom-token',
    method: 'post',
    authenticated: true,

    onSuccess: {
      message: 'Credenciais salvas com sucesso!',
      callback: () => {
        setHasCredentials(true);
      },
    },
  });

  function handleCopy(hasCopied: boolean) {
    if (!hasIntegration) {
      setIsURICopied(hasCopied);
    }
  }

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    if (!IS_PRODUCTION && !NGROK_URL) {
      toast({
        title: 'REACT_APP_NGROK_URL não configurada',
        description:
          'É necessário configurar a variável de ambiente REACT_APP_NGROK_URL para realizar a integração com o Zoom.',
        status: 'error',
      });
      return;
    }

    await handleSubmit();
  }

  return (
    <Flex direction="column" flex={1}>
      <Header
        title="Zoom"
        description="O Conteúdo Ao Vivo com o Zoom permite que você crie uma maneira mais envolvente de
          interação com o seu público. Você pode agendar uma aula ao vivo, diretamente de dentro da
          Proluno. Defina a hora, a data e a duração para que os alunos possam participar
          diretamente do seu produto."
      />

      {hasIntegration && (
        <Alert status="success" mt={4} px={2} borderRadius="md">
          <AlertIcon />
          Integração realizada com sucesso.
        </Alert>
      )}

      {isFetchingCredentials ? (
        <Spinner size="xl" m="4rem auto" />
      ) : (
        <VStack
          mt={6}
          align="start"
          w="full"
          sx={{
            label: {
              fontSize: 'sm',
            },
          }}
          spacing={4}
        >
          <VStack align="start" spacing={0}>
            <Heading fontWeight="medium" fontSize="lg" mb={1}>
              Credenciais
            </Heading>
            <Text color="#20212380" fontSize="lg" whiteSpace="pre-wrap">
              Cole abaixo as credenciais geradas pelo Zoom.
            </Text>
          </VStack>

          <VStack as="form" onSubmit={onSubmit} align="start" spacing={6} w="full">
            <FormControl isInvalid={formValidation?.clientId.isInvalid}>
              <FormLabel>Client ID</FormLabel>
              <Input
                name="clientId"
                value={form.clientId}
                onChange={handleChange}
                focusBorderColor="default.500"
                isDisabled={hasIntegration}
              />
              <FormErrorMessage>{formValidation?.clientId.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.clientSecret.isInvalid}>
              <FormLabel>Client Secret</FormLabel>
              <Input
                name="clientSecret"
                value={form.clientSecret}
                onChange={handleChange}
                focusBorderColor="default.500"
                isDisabled={hasIntegration}
              />
              <FormErrorMessage>{formValidation?.clientSecret.message}</FormErrorMessage>
            </FormControl>

            <HStack justify="end" w="full">
              <Button
                type="submit"
                colorScheme="default"
                size="sm"
                isLoading={isSubmitting}
                isDisabled={!hasChange}
              >
                Salvar
              </Button>
            </HStack>
          </VStack>

          {hasCredentials && (
            <VStack mt={6} align="start" spacing={4} w="full">
              <VStack align="start" spacing={0} w="full">
                <Heading fontWeight="medium" fontSize="lg" mb={1}>
                  URL de Redirecionamento
                </Heading>
                <Text color="#20212380" fontSize="lg" whiteSpace="pre-wrap">
                  Copie a URL que está logo no campo abaixo e cole no campo{' '}
                  <Text as="strong">"Redirect URL for OAuth"</Text> e no campo{' '}
                  <Text as="strong">"Add allow lists"</Text> no Zoom. Depois preencha todas os
                  requisitos que o Zoom exige para a integração.
                </Text>
              </VStack>

              <InputGroup color="gray.500">
                <Input
                  value={redirectURI}
                  isReadOnly
                  variant="filled"
                  focusBorderColor="transparent"
                  _hover={{ bgColor: 'gray.100' }}
                  _focus={{ bgColor: 'gray.100' }}
                />
                <InputRightAddon
                  children={<CopyButton textToCopy={redirectURI} onCopy={handleCopy} />}
                />
              </InputGroup>

              <HStack justify="end" w="full">
                <Button
                  as={Link}
                  href={!isURICopied ? '#' : integrationURL}
                  colorScheme="default"
                  size="sm"
                  px={6}
                  isDisabled={!isURICopied}
                >
                  Integrar
                </Button>
              </HStack>
            </VStack>
          )}
        </VStack>
      )}
    </Flex>
  );
}
