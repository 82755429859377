import { Box, BoxProps, Button, Text, Link } from '@chakra-ui/react';
import { MdCallMade as RedirectIcon } from 'react-icons/md';
import { MemberArea } from '..';

const WHATSAPP_LINK =
  'https://api.whatsapp.com/send/?phone=5585992652741&text&type=phone_number&app_absent=0';

interface HelperTextProps extends BoxProps {
  memberAreaType: MemberArea;
  hasMembersAreaNetflix: boolean;
}

export default function HelperText({
  memberAreaType,
  hasMembersAreaNetflix,
  ...rest
}: HelperTextProps) {
  return (
    <Box {...rest}>
      {memberAreaType === 'netflix' ? (
        <>
          <Text
            fontSize="md"
            color="#202123"
            lineHeight="1.2"
            maxWidth={{ base: 'unset', xl: '80%' }}
          >
            Quer saber como aproveitar ao máximo sua área de membros? Veja nossos tutoriais:
          </Text>

          <Button
            size="xs"
            colorScheme="primary"
            variant="link"
            color="#759AED"
            textDecoration="underline"
            rightIcon={<RedirectIcon />}
            sx={{ '.chakra-button__icon': { ml: '5px' } }}
          >
            Tutoriais
          </Button>
        </>
      ) : (
        <Box hidden={hasMembersAreaNetflix}>
          <Text
            fontSize="md"
            color="#202123"
            lineHeight="1.2"
            maxWidth={{ base: 'unset', xl: '88%' }}
          >
            Quer ter acesso a uma área de membros estilo Netflix e oferecer a melhor experiência
            para os seus alunos? Clique no botão abaixo:
          </Text>

          <Button
            as={Link}
            href={WHATSAPP_LINK}
            mt={10}
            size="sm"
            colorScheme="primary"
            color="secondary.500"
            isExternal
            _hover={{ textDecoration: 'none' }}
          >
            Fazer Upgrade
          </Button>
        </Box>
      )}
    </Box>
  );
}
