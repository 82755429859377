import * as S from './styles';

import convert from '../../../../../helpers/convertToBrazilianCurrency';

import Installments from './Installments';

type Installment = {
  fee: number;
  amount: number;
};

function InstallmentsBox({ index, purchaseStatus, paymentMethod, recipients }) {
  const { installmentsPayment } = recipients;

  const amounts = installmentsPayment.map((installment: Installment) => installment.amount);
  const fees = installmentsPayment.map((installment: Installment) => installment.fee);

  const totalAmount = amounts.reduce((acc: number, total: number) => {
    return (acc += total);
  }, paymentMethod === 'boleto' && []);

  const totalFee = fees.reduce((acc: number, total: number) => {
    return (acc += total);
  }, paymentMethod === 'boleto' && []);

  const splits = [totalAmount, totalFee];

  const totalLiquid = splits.reduce((acc: number, total: number) => {
    return (acc -= total);
  });

  return (
    <S.Container>
      <header>
        {index}. {recipients.fullName}
        <span>
          Total bruto: <b style={{ fontWeight: 600 }}>{convert(totalAmount)}</b>
        </span>
      </header>
      <S.Main>
        <div>
          <span>Responsável por: mdr</span>
          <span>
            {/* Total de saídas: <S.Space> - {convert(totalFee)} </S.Space> */}
            Total de saídas: -
          </span>
        </div>

        <div>
          <span>
            Total líquido: <S.Space> {convert(totalLiquid)} </S.Space>
          </span>
        </div>
      </S.Main>

      <Installments
        index={index}
        purchaseStatus={purchaseStatus}
        paymentMethod={paymentMethod}
        installments={recipients.installmentsPayment}
      />
    </S.Container>
  );
}

export default InstallmentsBox;
