import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  List,
  ListItem,
  Text,
  useDisclosure,
  VStack,
  useToast,
  Image,
} from '@chakra-ui/react';
import { useState } from 'react';
import { MdAdd as AddIcon } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { axiosBackend } from '../../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import useFetch from '../../../../../../../hooks/useFetch';
import TestimonyFormModal from './TestimonyFormModal';

export interface Testimony {
  id: number;
  thumbnail: string;
  name: string;
  testimony: string;
}

interface QuestionListResponse {
  testimony: Testimony[];
}

export default function Testimonials() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { planId } = useParams<{ planId: string }>();
  const [selectedTestimony, setSelectedTestimony] = useState<Testimony | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const toast = useToast();

  const {
    data: response,
    fetchData,
    loading: isFetching,
  } = useFetch<UnificadaFront.ResponseJSON<QuestionListResponse>>({
    method: 'get',
    url: `/recurrence-plan/${planId}/testimony`,
    authenticated: true,
    autoFetch: true,
  });

  async function handleTestimonyDelete(testimonyId: number) {
    setIsDeleting(true);

    try {
      await axiosBackend().delete(`/recurrence-plan/${planId}/testimony/${testimonyId}`);
      toast({
        title: 'Depoimento excluído com sucesso!',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
      });
    } finally {
      setIsDeleting(false);
      await fetchData();
    }
  }

  const testimonyListData = response?.data?.testimony || [];

  function handleTestimonyToEditClick(testimony: Testimony) {
    setSelectedTestimony(testimony);
    onOpen();
  }

  async function handleClose() {
    setSelectedTestimony(null);
    onClose();
  }

  const isLoading = isFetching || isDeleting;

  return (
    <>
      <VStack align="start" mt={10} w="100%" spacing={4}>
        <Box>
          <Heading as="h2" color="#202123" fontSize="xl" fontWeight="medium" m={0}>
            Depoimentos
          </Heading>

          <Text color="#20212380" fontSize="sm" mt={2}>
            Crie uma seção de depoimentos de antigos ou dos alunos atuais dos seus cursos ou planos
          </Text>
        </Box>

        <Button
          onClick={onOpen}
          w="100%"
          variant="outline"
          colorScheme="primary"
          leftIcon={<AddIcon />}
          size="sm"
          isLoading={isLoading}
        >
          Adicionar depoimento
        </Button>
      </VStack>

      {!isLoading && testimonyListData.length > 0 && (
        <Accordion mt={6} as={List} w="100%" allowToggle>
          {testimonyListData.map(testimony => (
            <AccordionItem key={testimony.id} as={ListItem}>
              <AccordionButton>
                <HStack w="100%" fontWeight="medium">
                  <Image src={testimony.thumbnail} objectFit="cover" boxSize={12} />

                  <Text fontWeight="medium">{testimony.name}</Text>
                </HStack>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Text>{testimony.testimony}</Text>

                <HStack justify="flex-end" mt={4}>
                  <ButtonGroup gap={0}>
                    <Button
                      onClick={() => handleTestimonyToEditClick(testimony)}
                      size="xs"
                      colorScheme="gray"
                      variant="link"
                      textDecoration="underline"
                      isDisabled={isLoading}
                    >
                      Editar
                    </Button>

                    <Button
                      onClick={() => handleTestimonyDelete(testimony.id)}
                      size="xs"
                      colorScheme="red"
                      variant="outline"
                      isLoading={isDeleting}
                    >
                      Excluir
                    </Button>
                  </ButtonGroup>
                </HStack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}

      <TestimonyFormModal
        testimony={selectedTestimony}
        isOpen={isOpen}
        onClose={handleClose}
        fetchData={fetchData}
        isLoading={false}
      />
    </>
  );
}
