import { Button, ButtonProps } from '@chakra-ui/react';
import { IoIosArrowForward } from 'react-icons/io';

interface NextButtonProps extends ButtonProps {
  text?: string;
}

export default function NextButton(props: NextButtonProps) {
  const { text } = props;

  return (
    <Button
      {...props}
      rightIcon={<IoIosArrowForward />}
      colorScheme="orange"
      px={{ base: 10, lg: 3 }}
      py={{ base: 4, lg: 5 }}
      size="sm"
      fontSize="sm"
      fontFamily="Montserrat"
      sx={{ outline: 'none' }}
      _focus={{ outline: 'none', boxShadow: 'none' }}
      _active={{ outline: 'none', boxShadow: 'none' }}
      width={{ base: 'full', md: 'initial' }}
      maxWidth={{ base: '50%', md: 'none' }}
    >
      {text || 'Próximo'}
    </Button>
  );
}
