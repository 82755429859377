import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  HStack,
  IconButton,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MdClose as CloseIcon } from 'react-icons/md';
import CustomDropzone from '../../../../components/CustomDropzone';
import { Heading } from '../../../../components/Heading';
import Spinner from '../../../../components/Loadings/Spinner';
import Toast from '../../../../components/Toast';
import { useTheme } from '../../../../contexts/ThemeContext';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import HandleInputPhoneMask from '../../../../helpers/HandleInputPhoneMask';
import MaskPhone from '../../../../helpers/MaskPhone';
import UploadHelper from '../../../../helpers/UploadHelper';
import Section from '../Section';
import ErrorMessage from '../components/ErrorMessage';
import Line from '../components/Line';
import Tooltip from '../components/Tooltip';
import DocumentInput from './DocumentInput';
import styles from './styles.module.css';

const FIRST_FILE = 0;

function getCurrentSection(theme) {
  const categoriesSection = theme.themeSections.find(section => section.name === 'footer');
  return categoriesSection;
}

const initialValues = {
  title: '',
  mission: '',
  urlSocialMediaInstagram: '',
  urlSocialMediaFacebook: '',
  urlSocialMediaWhatsapp: '',
  urlSocialMediaTelegram: '',
  urlSocialMediaYouTube: '',
  informationPhone: '',
  informationEmail: '',
  whatsappFloatingButton: false,
  documentNumber: null,
};

function Footer() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [footerValues, setFooterValues] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState(null);
  const [documentNumber, setDocumentNumber] = useState(null);
  const [termFile, setTermFile] = useState(null);

  const { theme, updateFooter, isLoading: isLoadingTheme } = useTheme();

  useEffect(() => {
    if (!isLoadingTheme) {
      setHasChanged(false);

      const footerSection = getCurrentSection(theme);

      const maskedPhone = MaskPhone(footerSection.themeFooter.informationPhone);

      setDocumentNumber(footerSection?.themeFooter?.documentNumber);

      setTermFile({
        name: footerSection?.themeFooter?.termsAndPolicies,
        size: footerSection?.themeFooter?.fileSize,
        link: footerSection?.themeFooter?.termsAndPoliciesLink,
      });

      const footerValues = {
        ...footerSection.themeFooter,
        informationPhone: maskedPhone,
      };

      setFooterValues(footerValues);
    }
  }, [isLoadingTheme, theme]);

  async function handleSwitchSubmit(event) {
    const { name, checked } = event.target;

    setHasChanged(true);

    setFooterValues(prevValues => ({ ...prevValues, [name]: checked }));
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setHasChanged(true);

    setFooterValues(prevValues => ({ ...prevValues, [name]: value }));
  }

  function formatValuesToSubmit() {
    const phoneNumber = footerValues.informationPhone.replace(/[\D+]/g, '');

    footerValues.informationPhone = phoneNumber;
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    setHasChanged(false);

    const footerSection = getCurrentSection(theme);
    const maskedPhone = MaskPhone(footerSection.themeFooter.informationPhone);

    const footerValues = {
      ...footerSection.themeFooter,
      informationPhone: maskedPhone,
    };

    setFooterValues(footerValues);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    setHasChanged(false);

    formatValuesToSubmit();

    const theme_id = theme.id;
    const footer_id = footerValues.id;
    const payload = { ...footerValues };

    payload.documentNumber = documentNumber || null;
    payload.termsAndPolicies = termFile instanceof File ? termFile : termFile.name;
    payload.fileSize = termFile.size || null;

    delete payload.id;
    delete payload.termsAndPoliciesLink;

    try {
      if (payload?.termsAndPolicies instanceof File) {
        const { newFileName } = await UploadHelper.upload(payload.termsAndPolicies, 'archives');

        payload.termsAndPolicies = newFileName;
      }

      await updateFooter(theme_id, footer_id, payload);

      Toast('Rodapé atualizado com sucesso');
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setIsLoading(false);
    }
  }

  const handleDocumentNumberChange = useCallback(value => {
    setHasChanged(true);

    setDocumentNumber(value);
  }, []);

  function handleFileDrop(acceptedFiles) {
    setHasChanged(true);

    const file = acceptedFiles[FIRST_FILE] || '';

    setTermFile(file);
  }

  function handleDiscardTermFile() {
    setTermFile('');
    setHasChanged(true);
  }

  return (
    <Section>
      <div className={styles.sectionHeader}>
        <div className="d-flex align-items-center">
          <Heading as="h4" fontSize="2xl">
            Rodapé
          </Heading>
          <Line />
          <Tooltip link="" />
        </div>
      </div>
      <div className={styles.footerContainer}>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputGroup}>
            <label htmlFor="title">Título:</label>
            <input
              name="title"
              onChange={handleChange}
              value={footerValues.title}
              id="title"
              type="text"
              minLength={1}
              maxLength={255}
            />
          </div>

          <div className={styles.inputGroup}>
            <label htmlFor="mission">Missão:</label>
            <textarea
              name="mission"
              onChange={handleChange}
              value={footerValues.mission}
              id="mission"
              cols="30"
              rows="3"
              minLength={5}
              maxLength={80}
            />
          </div>

          <div className={styles.inputGroup}>
            <label htmlFor="documentNumber">CPF/CNPJ:</label>

            <DocumentInput
              id="documentNumber"
              value={documentNumber}
              onChange={handleDocumentNumberChange}
              autoComplete="user-document"
            />
          </div>

          <div className={styles.inputGroup}>
            <label>Termo e Política:</label>

            {!termFile?.name ? (
              <CustomDropzone
                maxFileSize="25MB"
                onDrop={handleFileDrop}
                accept={['application/pdf']}
                extensions={['PDF']}
              />
            ) : (
              <HStack
                justify="space-between"
                w="full"
                borderRadius="base"
                boxShadow="0px 4px 4px 0px #00000040"
                px={2.5}
                py={2}
              >
                <VStack
                  spacing={0}
                  align="start"
                  sx={{ p: { mb: '0 !important', fontSize: 'sm !important' } }}
                >
                  {!termFile?.link ? (
                    <Text color="#202123 !important" fontWeight="medium !important">
                      {termFile?.name}
                    </Text>
                  ) : (
                    <Link
                      href={termFile.link}
                      textDecoration="underline"
                      color="#202123"
                      fontWeight="medium !important"
                      isExternal
                    >
                      {termFile?.name}
                    </Link>
                  )}

                  <Text>{(termFile.size / (1024 * 1024)).toFixed(2)}MB</Text>
                </VStack>

                <IconButton
                  onClick={handleDiscardTermFile}
                  variant="ghost"
                  bg="transparent !important"
                  color="#20212380 !important"
                  icon={<CloseIcon size="1rem" />}
                  p="0 !important"
                  size="sm"
                  sx={{ svg: { m: '0 !important' } }}
                  _hover={{ bg: 'gray.50 !important' }}
                />
              </HStack>
            )}
          </div>

          <div className={styles.rowInputGroup}>
            <div className={styles.inputGroup}>
              <label>Redes Sociais:</label>
              <p>
                Adicione o link das suas redes sociais. No rodapé terá um ícone para cada rede
                social.
              </p>

              <input
                name="urlSocialMediaInstagram"
                onChange={handleChange}
                value={footerValues?.urlSocialMediaInstagram}
                type="url"
                placeholder="Link do seu Instagram"
                minLength={5}
                maxLength={80}
              />

              <input
                name="urlSocialMediaFacebook"
                onChange={handleChange}
                value={footerValues?.urlSocialMediaFacebook}
                type="url"
                placeholder="Link do seu Facebook"
                minLength={5}
                maxLength={80}
              />

              <input
                name="urlSocialMediaWhatsapp"
                onChange={handleChange}
                value={footerValues?.urlSocialMediaWhatsapp}
                type="url"
                placeholder="Link do seu Whatsapp"
                minLength={5}
                maxLength={80}
              />

              {footerValues?.urlSocialMediaWhatsapp && (
                <HStack spacing={2} my={2} alignItems="flex-start">
                  <Switch
                    name="whatsappFloatingButton"
                    onChange={handleSwitchSubmit}
                    defaultChecked={footerValues?.whatsappFloatingButton}
                    checked={footerValues?.whatsappFloatingButton}
                    colorScheme="orange"
                    style={{ outline: 'none', boxShadow: 'none' }}
                  />
                  <Text as="line" fontSize="14px" fontWeight="500">
                    Ativar Botão Flutuante do WhatsApp?
                  </Text>
                  <Popover placement="top-start">
                    <PopoverTrigger>
                      <Box
                        border="1px"
                        borderRadius="full"
                        px={1}
                        color="orange.400"
                        fontSize="7px"
                        fontWeight="500"
                        cursor="pointer"
                      >
                        i
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent style={{ outline: 'none', boxShadow: 'none' }}>
                      <PopoverHeader fontWeight="semibold">Botão Flutuante</PopoverHeader>
                      <PopoverBody>
                        Ao ativar, ficará visível um botão flutuante do WhatsApp em sua plataforma.
                        (Em todas as páginas).
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </HStack>
              )}

              <input
                name="urlSocialMediaTelegram"
                onChange={handleChange}
                value={footerValues?.urlSocialMediaTelegram}
                type="url"
                placeholder="Link do seu Telegram"
                minLength={5}
                maxLength={80}
              />

              <input
                name="urlSocialMediaYouTube"
                onChange={handleChange}
                value={footerValues?.urlSocialMediaYouTube}
                type="url"
                placeholder="Link do seu Canal do Youtube"
                minLength={5}
                maxLength={80}
              />

              <input
                name="urlSocialMediaLinkedin"
                onChange={handleChange}
                value={footerValues?.urlSocialMediaLinkedin}
                type="url"
                placeholder="Link do seu Linkedin"
                minLength={5}
                maxLength={80}
              />
            </div>

            <div className={styles.inputGroup}>
              <label>Informações para contato:</label>
              <p>
                Coloque aqui suas informações para contato como por exemplo seu email ou telefone
              </p>

              <input
                name="informationPhone"
                type="tel"
                onChange={handleChange}
                value={footerValues.informationPhone}
                onKeyPress={HandleInputPhoneMask}
                placeholder="Digite seu telefone"
                minLength={5}
                maxLength={12}
              />

              <input
                name="informationEmail"
                type="email"
                onChange={handleChange}
                value={footerValues.informationEmail}
                placeholder="Digite seu email"
                minLength={5}
                maxLength={80}
              />
            </div>
          </div>

          <div className={styles.actions}>
            <div>
              <button
                onClick={handleDiscardChanges}
                className={styles.cancelButton}
                disabled={!hasChanged || isLoading}
              >
                Cancelar
              </button>

              <button type="submit" disabled={!hasChanged || isLoading}>
                {isLoading ? <Spinner small /> : 'Salvar alterações'}
              </button>
            </div>
          </div>
        </form>

        {errorMessage && !hasChanged && <ErrorMessage message={errorMessage} />}
      </div>
    </Section>
  );
}

export default Footer;
