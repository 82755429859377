import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Show,
  VStack,
  useOutsideClick,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { onlyAlphaAndSpace, onlyNumber } from '../../../../../hooks/useHandleChange';
import { FormValidation } from '../../../../../hooks/useHandleSubmit';
import { INPUT_STYLES } from '../../../constants';
import { CreditCardType } from '../../types';
import CreditCardIllustration from './CreditCardIllustration';
import CreditCardInput from './CreditCardInput';
import YearSelect from './YearSelect';

function getMonth(value: number) {
  return `${value + 1}`.padStart(2, '0');
}

interface CreditCardProps {
  creditCard: CreditCardType;
  formValidation?: FormValidation<Partial<CreditCardType>>;

  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export default function CreditCard({ creditCard, formValidation, onChange }: CreditCardProps) {
  const [isShowBackIllustrationCard, setIsShowBackIllustrationCard] = useState(false);

  const inputRef = useRef();

  function showBackIllustrationCard() {
    setIsShowBackIllustrationCard(true);
  }

  function hideBackIllustrationCard() {
    setIsShowBackIllustrationCard(false);
  }

  useOutsideClick({
    ref: inputRef,
    handler: hideBackIllustrationCard,
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    if (name === 'creditCard.cardNumber') {
      const unmaskedValue = value.replace(/\D/g, '');
      event.target.value = unmaskedValue;
      return onChange(event);
    }

    onChange(event);
  }

  return (
    <Flex gap={5} align="start">
      <VStack flex={1} spacing={7}>
        <FormControl isInvalid={formValidation?.cardNumber?.isInvalid}>
          <FormLabel>Número do cartão</FormLabel>
          <CreditCardInput
            name="creditCard.cardNumber"
            value={creditCard?.cardNumber}
            onChange={handleChange}
            placeholder="Digite somente números"
          />
          <FormErrorMessage>{formValidation?.cardNumber?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.cardHolderName?.isInvalid}>
          <FormLabel>Titular do cartão</FormLabel>
          <Input
            name="creditCard.cardHolderName"
            value={creditCard?.cardHolderName}
            onChange={handleChange}
            placeholder="Como no cartão"
            onInput={onlyAlphaAndSpace}
            maxLength={45}
            textTransform="uppercase"
            _placeholder={{ textTransform: 'none' }}
            {...INPUT_STYLES}
          />
          <FormErrorMessage>{formValidation?.cardHolderName?.message}</FormErrorMessage>
        </FormControl>

        <HStack align="start" w="full" spacing={5}>
          <Flex direction="column" align="flex-start" w="64%" flexShrink={0}>
            <FormLabel>Data de validade</FormLabel>
            <Flex w="full" gap={2}>
              <FormControl isInvalid={formValidation?.cardExpirationMonth?.isInvalid}>
                <Select
                  name="creditCard.cardExpirationMonth"
                  value={creditCard?.cardExpirationMonth}
                  onChange={handleChange}
                  {...INPUT_STYLES}
                >
                  <option value="" hidden>
                    MM
                  </option>
                  {Array.from({ length: 12 }, (_, i) => +i).map(month => (
                    <option key={month}>{getMonth(month)}</option>
                  ))}
                </Select>
                <FormErrorMessage>{formValidation?.cardExpirationMonth?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formValidation?.cardExpirationYear?.isInvalid}>
                <YearSelect
                  name="creditCard.cardExpirationYear"
                  value={creditCard?.cardExpirationYear}
                  onChange={handleChange}
                />
                <FormErrorMessage>{formValidation?.cardExpirationYear?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
          </Flex>

          <FormControl isInvalid={formValidation?.cardCVV?.isInvalid}>
            <FormLabel>CVV</FormLabel>
            <Input
              ref={inputRef}
              name="creditCard.cardCVV"
              value={creditCard?.cardCVV}
              onChange={handleChange}
              onFocus={showBackIllustrationCard}
              onBlur={hideBackIllustrationCard}
              placeholder="XXX"
              onInput={onlyNumber}
              maxLength={4}
              {...INPUT_STYLES}
            />
            <FormErrorMessage>{formValidation?.cardCVV?.message}</FormErrorMessage>
          </FormControl>
        </HStack>
      </VStack>

      <Show above="md">
        <Flex flex={1} pr={3}>
          <CreditCardIllustration
            cardCVV={creditCard?.cardCVV}
            isShowBack={isShowBackIllustrationCard}
            cardHolderName={creditCard?.cardHolderName}
            cardNumber={creditCard?.cardNumber}
            cardExpirationDate={{
              month: creditCard?.cardExpirationMonth,
              year: creditCard?.cardExpirationYear,
            }}
          />
        </Flex>
      </Show>
    </Flex>
  );
}
