import {
  Box,
  Button,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa6';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { Notification, NotificationType } from '../ContainerSite/NotificationsDropDown';
import FullPageLoading from '../FullPageLoading';

interface ProlunoNotificationProps {
  isOpen: boolean;
  isLoading: boolean;
  notification: Notification;
  onClose: () => void;
  viewNotification: () => void;
}

function getNotificationTypeName(type: NotificationType) {
  switch (type) {
    case 'news':
      return 'Novidades';
    case 'maintenance':
      return 'Manutenção';
    case 'disclosure_invitation':
      return 'Convite/Divulgação';
  }
}

export function ProlunoNotification({
  isOpen,
  isLoading,
  notification,
  onClose,
  viewNotification,
}: ProlunoNotificationProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={notification?.isRead}
      closeOnOverlayClick={notification?.isRead}
      isCentered
      size={{ base: 'sm', md: 'xl' }}
    >
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent as="form" padding="24px">
        <ModalHeader padding="0 0 15px 0">
          {notification?.isRead && <ModalCloseButton />}

          <HStack>
            <Circle bg="primary.500" size="30px">
              <IoMdNotificationsOutline color="white" />
            </Circle>

            <Text fontSize="18px" fontStyle="normal" fontWeight="600" lineHeight="28px">
              Notificação da Proluno
            </Text>
          </HStack>
        </ModalHeader>
        <ModalBody padding={0}>
          <Stack spacing="15px">
            <Stack spacing="5px" width="full">
              <Text fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="20px">
                Tipo:{' '}
                <Text as="span" fontWeight="400">
                  {getNotificationTypeName(notification?.type)}
                </Text>
              </Text>
              <Text fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="20px">
                Assunto:{' '}
                <Text as="span" fontWeight="400">
                  {notification?.title}
                </Text>
              </Text>
            </Stack>

            <Box
              dangerouslySetInnerHTML={{ __html: notification?.description }}
              sx={{
                a: { color: '#005EBC', wordBreak: 'break-word' },
                iframe: { width: '100%', aspectRatio: 16 / 9 },
                whiteSpace: 'pre-wrap',
                img: { width: '100%', height: 'auto', objectFit: 'cover' },
              }}
            />

            {!notification?.isRead && (
              <Box width="full" display="flex" justifyContent="center">
                <Button
                  leftIcon={<FaCheck />}
                  colorScheme="primary"
                  color="secondary.500"
                  onClick={viewNotification}
                >
                  Ciente
                </Button>
              </Box>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>

      <FullPageLoading isLoading={isLoading} />
    </Modal>
  );
}
