import { memo } from 'react';
import styles from './styles.module.css';

function NoData({ className, svg, title = '', message = '', width, height }) {
  return (
    <div className={`${styles.noDataContainer} ${className}`}>
      <img
        style={{ marginTop: 'calc(100px)' }}
        src={svg}
        width="300px"
        height="300px"
        alt="Sem dados"
      />
      <h6>{title}</h6>
      <p>{message}</p>
    </div>
  );
}

export default memo(NoData);
