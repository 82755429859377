import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cademiIntegrationAPI from '../../../../../../api/CademiIntegration';
import { Heading } from '../../../../../../components/Heading';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';

type InitialStateType = {
  memberArea: string;
};

export default function MembersArea() {
  const [memberArea, setMemberArea] = useState('');
  const [hasChanged, setHasChanged] = useState(false);
  const [isCademiDisabled, setIsCademiDisabled] = useState(true);
  const [initialState, setInitialState] = useState<InitialStateType>();
  const [isLoading, setIsLoading] = useState(false);

  const { course, updateCourse, loading } = useCourse();

  useEffect(() => {
    if (!loading && course) {
      setMemberArea(course.memberArea);

      setInitialState({
        memberArea: course.memberArea,
      });
    }
  }, [course, loading]);

  useEffect(() => {
    async function getIntegration() {
      try {
        const { data: integrationData } = await cademiIntegrationAPI.show();

        if (integrationData) {
          setIsCademiDisabled(false);
        }
      } catch (error) {
        setIsCademiDisabled(true);
      }
    }

    getIntegration();
  }, []);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;

    setMemberArea(checked ? 'cademi' : null);

    setHasChanged(true);
  }

  function handleDiscard() {
    setMemberArea(initialState.memberArea);
    setHasChanged(false);
  }

  async function handleSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsLoading(true);
    setHasChanged(false);

    try {
      await updateCourse({ memberArea });

      Toast('Curso atualizado com sucesso.');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box>
      <Card
        as="form"
        onSubmit={handleSubmit}
        border="1px solid #20212340"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <CardHeader>
          <Heading as="h2" fontSize="2xl">
            Área de Membros
          </Heading>
        </CardHeader>

        <CardBody>
          <VStack align="start">
            <FormControl display="flex" alignItems="center" gap={3} isDisabled={isCademiDisabled}>
              <Switch
                id="cademi"
                onChange={handleChange}
                isChecked={memberArea === 'cademi'}
                isDisabled={isCademiDisabled}
                colorScheme="default"
              />

              <FormLabel
                htmlFor="cademi"
                mb="0"
                fontWeight="bold"
                cursor={isCademiDisabled ? 'not-allowed' : 'pointer'}
              >
                Área de membros - Cademí
              </FormLabel>
            </FormControl>

            <Text fontSize="md">
              Utilize uma área de membros externa.{' '}
              <Text as="strong">A configuração é por produto,</Text> não é geral como as outras
              opções. Para liberar essa opção você deve ativar a integração da Cademí.{' '}
              <Button
                as={Link}
                to="/integrations"
                variant="link"
                colorScheme="blue"
                textDecoration="underline"
              >
                Clique aqui
              </Button>{' '}
              para mais informações sobre essa integração.
            </Text>
          </VStack>
        </CardBody>

        <CardFooter>
          <HStack justify="end" align="center" width="100%">
            <Button
              type="button"
              colorScheme="gray"
              size="sm"
              onClick={handleDiscard}
              isDisabled={isCademiDisabled || !hasChanged}
            >
              Cancelar
            </Button>

            <Button
              colorScheme="default"
              type="submit"
              size="sm"
              isDisabled={isCademiDisabled || !hasChanged}
              isLoading={isLoading}
            >
              Salvar
            </Button>
          </HStack>
        </CardFooter>
      </Card>
    </Box>
  );
}
