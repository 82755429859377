import { Redirect, Route, Switch } from 'react-router-dom';

import NewPlans from '../../DashboardAdmin/PlansInternalManager/NewPlans';
import PlansInternalManager from '../../DashboardAdmin/PlansInternalManager';

export default function InternalManager() {
  return (
    <Switch>
      <Route path="/internal-manager/plans" exact component={PlansInternalManager} />
      <Route path="/internal-manager/plans/new" exact component={NewPlans} />
      <Route path="/internal-manager/plans/:id/edit" exact component={NewPlans} />
      <Route path="*" component={() => <Redirect to="/" />} />
    </Switch>
  );
}
