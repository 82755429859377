import { Flex, HStack, Skeleton, VStack } from '@chakra-ui/react';

export default function CheckoutSkeleton() {
  return (
    <Flex align="start" direction="column" w="full">
      <Skeleton height="20px" w="50%" />

      <VStack w="full" mt={7}>
        <Skeleton height={5} w="full" />
        <Skeleton height={10} w="full" />
      </VStack>

      <HStack w="full" spacing={5} mt={8} justify="space-between">
        <VStack align="start" w="full">
          <Skeleton height={5} w="25%" />
          <Skeleton height={10} w="full" />
        </VStack>

        <VStack align="start" w="full">
          <Skeleton height={5} w="25%" />
          <Skeleton height={10} w="full" />
        </VStack>
      </HStack>

      <Skeleton height={5} w="full" mt={8} />

      <Skeleton height={5} w="20%" mt={8} />

      <VStack mt={8} align="start" w="full">
        <Skeleton height={5} w="33%" />
        <Skeleton height={10} w="full" />
      </VStack>

      <Skeleton w="full" height={10} mt={9} />
      <Skeleton height={3} w="75%" mt={1} />
    </Flex>
  );
}
