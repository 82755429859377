import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Hide,
  Input,
  Link,
  Select,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { onlyNumber } from '../../../../../hooks/useHandleChange';
import ZipCodeInput from '../../../Components/ZipcodeInput';
import { INPUT_STYLES } from '../../../constants';
import findState from '../../utils/findState';

export default function BoletoForm({
  isInternational,
  form,
  setForm,
  formValidation,
  userData,
  handleChange,
  handleZipCodeChange,
  handleStateChange,
  cities,
  states,
}) {
  useEffect(() => {
    if (userData?.address) {
      setForm(prevForm => ({
        ...prevForm,
        address: {
          country: 'br',
          zipCode: userData?.address?.zipCode,
          streetAddress: userData?.address?.streetAddress,
          streetNumber: userData?.address?.streetNumber,
          neighborhood: userData?.address?.neighborhood,
          state: userData?.address?.state,
          city: userData?.address?.city,
          complementary: userData?.address?.complementary,
          alternateAddress: userData?.address?.alternateAddress,
        },
      }));
    } else {
      setForm(prevForm => ({
        ...prevForm,
        address: {
          ...prevForm.address,
          country: 'br',
        },
      }));
    }
  }, [setForm, userData?.address]);

  return (
    <>
      {!isInternational ? (
        <>
          <Stack width="full">
            <FormControl isInvalid={formValidation?.address?.zipCode?.isInvalid}>
              <FormLabel>CEP</FormLabel>
              <ZipCodeInput
                name="address.zipCode"
                value={form?.address?.zipCode}
                onChange={handleZipCodeChange}
                {...INPUT_STYLES}
              />

              <FormErrorMessage>{formValidation?.address?.zipCode?.message}</FormErrorMessage>
            </FormControl>

            <Link
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              color=" #759AED"
              textDecoration="underline"
              isExternal
            >
              Não sabe o CEP e deseja preencher o endereço?
            </Link>
          </Stack>

          <VStack my={8} spacing={8}>
            <Stack spacing={5} direction={{ base: 'column', md: 'row', xl: 'row' }}>
              <FormControl isInvalid={formValidation?.address?.streetAddress?.isInvalid} w="full">
                <FormLabel>Rua</FormLabel>
                <Input
                  name="address.streetAddress"
                  value={form?.address?.streetAddress}
                  onChange={handleChange}
                  {...INPUT_STYLES}
                />
                <FormErrorMessage>
                  {formValidation?.address?.streetAddress?.message}
                </FormErrorMessage>
              </FormControl>

              <HStack spacing={5} w="full" align="start">
                <FormControl
                  isInvalid={formValidation?.address?.streetNumber?.isInvalid}
                  maxWidth="31%"
                >
                  <FormLabel>Nº</FormLabel>
                  <Input
                    name="address.streetNumber"
                    value={form?.address?.streetNumber}
                    onChange={handleChange}
                    onInput={onlyNumber}
                    inputMode="numeric"
                    placeholder="XXX"
                    {...INPUT_STYLES}
                  />
                  <FormErrorMessage>
                    {formValidation?.address?.streetNumber?.message}
                  </FormErrorMessage>
                </FormControl>

                <Hide above="xl">
                  <FormControl flex={1}>
                    <FormLabel>Complemento</FormLabel>
                    <Input
                      name="address.complementary"
                      value={form?.address?.complementary}
                      onChange={handleChange}
                      placeholder="XXX"
                      {...INPUT_STYLES}
                    />
                  </FormControl>
                </Hide>

                <Hide below="xl">
                  <FormControl isInvalid={formValidation?.address?.neighborhood?.isInvalid}>
                    <FormLabel>Bairro</FormLabel>
                    <Input
                      name="address.neighborhood"
                      value={form?.address?.neighborhood}
                      onChange={handleChange}
                      {...INPUT_STYLES}
                    />
                    <FormErrorMessage>
                      {formValidation?.address?.neighborhood?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Hide>
              </HStack>
            </Stack>

            <Stack spacing={5} direction={{ base: 'column', md: 'row', xl: 'row' }} w="full">
              <Hide below="xl">
                <FormControl flex={1.125}>
                  <FormLabel>Complemento</FormLabel>
                  <Input placeholder="XXX" {...INPUT_STYLES} />
                </FormControl>
              </Hide>

              <Hide above="xl">
                <FormControl isInvalid={formValidation?.address?.neighborhood?.isInvalid} flex={1}>
                  <FormLabel>Bairro</FormLabel>
                  <Input
                    name="address.neighborhood"
                    value={form?.address?.neighborhood}
                    onChange={handleChange}
                    {...INPUT_STYLES}
                  />
                  <FormErrorMessage>
                    {formValidation?.address?.neighborhood?.message}
                  </FormErrorMessage>
                </FormControl>
              </Hide>

              <FormControl isInvalid={formValidation?.address?.state?.isInvalid} flex={1}>
                <FormLabel>Estado</FormLabel>
                <Select
                  name="address.state"
                  value={findState(form?.address?.state, states)}
                  onChange={handleStateChange}
                  {...INPUT_STYLES}
                >
                  <option value="" hidden>
                    Selecione
                  </option>
                  {states?.map(state => (
                    <option key={state.id} value={state.sigla}>
                      {state.nome}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formValidation?.address?.state?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formValidation?.address?.city?.isInvalid} flex={1}>
                <FormLabel>Cidade</FormLabel>
                <Select
                  name="address.city"
                  value={form?.address?.city}
                  onChange={handleChange}
                  {...INPUT_STYLES}
                >
                  <option value="" hidden>
                    Selecione
                  </option>
                  {cities?.map(city => (
                    <option key={city.id}>{city.nome}</option>
                  ))}
                </Select>
                <FormErrorMessage>{formValidation?.address?.city?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </VStack>
        </>
      ) : (
        <VStack w="full" spacing={8}>
          <FormControl isInvalid={formValidation?.address?.alternateAddress?.isInvalid}>
            <FormLabel>Endereço</FormLabel>
            <Input
              name="address.alternateAddress"
              value={form.address?.alternateAddress}
              onChange={handleChange}
              placeholder="Digite o seu documento"
              {...INPUT_STYLES}
            />

            {!form.address?.alternateAddress &&
              !formValidation?.address?.alternateAddress?.isInvalid && (
                <FormHelperText>
                  Você pode digitar letras, número e caracteres especiais.
                </FormHelperText>
              )}

            <FormErrorMessage>
              {formValidation?.address?.alternateAddress?.message}
            </FormErrorMessage>
          </FormControl>
        </VStack>
      )}
    </>
  );
}
