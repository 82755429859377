import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormEvent } from 'react';
import { IPercentageAvailable } from '..';
import { RevenueSliptType } from '../..';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';

interface IFormData {
  id: string;
  percentage: string;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  courseId: number;
  recurrencePlanId: number;
  splitType: RevenueSliptType;
  fetchRevenueGroup: () => void;
  fetchGroupPercentage: () => void;
  availablePercentage: string;
  setAvailablePercentage: (value: string) => void;
  hasCoProducers: boolean;
  coProducers: any[];
  percentages: IPercentageAvailable;
  setPercentages: (value: IPercentageAvailable) => void;
  isLoading: boolean;
}

export default function NewSplitModal({
  isOpen,
  onClose,
  splitType,
  fetchRevenueGroup,
  fetchGroupPercentage,
  hasCoProducers,
  coProducers,
  percentages,
  isLoading,
  availablePercentage,
  setAvailablePercentage,
  courseId,
  recurrencePlanId,
}: IProps) {
  const { form, setForm } = useHandleChange<IFormData>({
    id: '',
    percentage: '',
  });

  function handleChange(event) {
    const { name, value } = event.target;

    setForm(prevPost => ({ ...prevPost, [name]: value }));

    if (name === 'percentage') {
      let numericValue =
        value !== '' ? parseInt(value.replace(/\D/g, '')).toString() : value.replace(/\D/g, '');

      let formattedValue = numericValue;

      if (numericValue.length === 1) {
        formattedValue = `0.0${numericValue[0]}`;
      } else if (numericValue.length === 2) {
        formattedValue = `0.${numericValue[0]}${numericValue[1]}`;
      } else if (numericValue.length === 3) {
        formattedValue = `${numericValue[0]}.${numericValue[1]}${numericValue[2]}`;
      } else if (numericValue.length > 3) {
        formattedValue = `${numericValue[0]}${numericValue[1]}.${numericValue[2]}${numericValue[3]}`;
      }

      setForm(prevPost => ({ ...prevPost, [name]: formattedValue }));

      if (numericValue === '') {
        setAvailablePercentage(percentages?.availablePercentage);
        setForm(prevPost => ({ ...prevPost, percentage: '' }));
        return;
      }

      // Regex para validar número flutuante entre 0.01 e 99.99
      const isValidPercentage = /^(\d{1,2}(\.\d{1,2})?|100)$/.test(formattedValue);

      if (!isValidPercentage) {
        // Se o valor não for válido, pode tratar o erro aqui se necessário
        return;
      }

      setAvailablePercentage(
        (parseFloat(percentages?.availablePercentage) - parseFloat(formattedValue)).toString()
      );

      if (parseFloat(formattedValue) > parseFloat(percentages?.availablePercentage)) {
        setForm(prevPost => ({
          ...prevPost,
          percentage: parseFloat(percentages?.availablePercentage).toFixed(2),
        }));
        setAvailablePercentage('0');
        return;
      }

      if (parseFloat(formattedValue) < 0.01) {
        setForm(prevPost => ({ ...prevPost, percentage: '1' }));
        setAvailablePercentage(percentages?.availablePercentage);
        return;
      }
    }
  }

  const { isLoading: isSubmittingStandardSplit, handleSubmit: newStandardSplit } = useHandleSubmit({
    data: {
      user_id: parseInt(form.id),
      course_id: courseId,
      percentage: parseFloat(form.percentage),
    },
    url: '/revenue-split/revenue-default',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Compartilhamento adicionado com sucesso!',
      callback() {
        fetchRevenueGroup();
        fetchGroupPercentage();
        onCloseModal();
      },
    },
  });

  const { isLoading: isSubmittingRecurrenceSplit, handleSubmit: newRecurrenceSplit } =
    useHandleSubmit({
      data: {
        user_id: parseInt(form.id),
        recurrence_plan_id: recurrencePlanId,
        percentage: parseFloat(form.percentage),
      },
      url: '/revenue-split/revenue-recurrence-plan-default',
      method: 'post',
      authenticated: true,
      onSuccess: {
        message: 'Compartilhamento adicionado com sucesso!',
        callback() {
          fetchRevenueGroup();
          onCloseModal();
        },
      },
    });

  const isDisabled = isSubmittingStandardSplit || isSubmittingRecurrenceSplit;

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();
    if (splitType === 'DEFAULT') {
      await newStandardSplit();
      return;
    }

    if (splitType === 'RECURRENCE_DEFAULT') {
      await newRecurrenceSplit();
      return;
    }
  }

  function onCloseModal() {
    setForm({
      id: '',
      percentage: '',
    });
    onClose();
  }

  return (
    <Modal isOpen={isOpen} size="2xl" onClose={onCloseModal}>
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />

      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader fontWeight={800} fontSize="xl" textAlign="start">
          Adicionar um usuário
        </ModalHeader>

        <ModalCloseButton onClick={onCloseModal} />

        <ModalBody>
          <Stack width="full" spacing="10px">
            <Stack width="full" spacing="10px">
              <Text fontWeight="600" fontSize="sm">
                Usuário
              </Text>
              <Select
                name="id"
                onChange={handleChange}
                focusBorderColor="primary.400"
                value={form.id}
                isRequired
                size="sm"
                borderRadius={5}
              >
                <option value="" hidden>
                  Selecione um usuário
                </option>
                {hasCoProducers ? (
                  coProducers.map(user => (
                    <option placeholder="Selecione um usuário" key={user.id} value={user.id}>
                      {user.fullName}
                    </option>
                  ))
                ) : (
                  <option value="">Você não cadastrou usuários.</option>
                )}
              </Select>

              <Text fontSize="sm" fontWeight="500" color="#20212380">
                OBS: Para selecionar um usuário é necessário cadastra-lo. Para isso vá no menu
                lateral em Usuários {'>'} Usuários da conta.
              </Text>
            </Stack>

            <Stack width="full" spacing="10px">
              <Text fontWeight="600" fontSize="sm">
                Porcentagem
              </Text>

              <InputGroup size="sm">
                <Input
                  isRequired
                  type="text"
                  name="percentage"
                  placeholder="Digite uma porcentagem"
                  focusBorderColor="primary.400"
                  borderRadius={5}
                  maxLength={5}
                  value={form.percentage}
                  onChange={handleChange}
                />

                <InputRightAddon children="%" height={8} />
              </InputGroup>

              <VStack alignItems="flex-start">
                <Text>
                  Porcentagem da proluno:{' '}
                  <Text as="span" fontWeight="500">
                    {!isLoading && percentages?.prolunoPercentage
                      ? `${parseFloat(percentages?.prolunoPercentage).toFixed(2)}%`
                      : '-'}
                  </Text>
                </Text>
                <Text>
                  Porcentagem disponível:{' '}
                  <Text as="span" fontWeight="500">
                    {`${
                      !isLoading && availablePercentage
                        ? `${parseFloat(availablePercentage).toFixed(2)}%`
                        : '-'
                    }`}
                  </Text>
                </Text>
              </VStack>
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button size="sm" colorScheme="gray" onClick={onCloseModal}>
              Cancelar
            </Button>
            <Button
              size="sm"
              type="submit"
              colorScheme="primary"
              color="secondary.500"
              isDisabled={isDisabled}
              isLoading={isDisabled}
            >
              Adicionar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
