import { useEffect, useState } from 'react';

import { Heading } from '../../../../components/Heading';
import styles from './styles.module.css';
import { useTheme } from '../../../../contexts/ThemeContext';
import { Box } from '@chakra-ui/react';

function MediasSection({ section }) {
  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  const [multimedia, setMultimedia] = useState([]);

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      setMultimedia(section);
    }
  }, [themeMarketplace, isLoadingThemeMarketplace, section]);

  if (!multimedia.active) return '';

  return (
    <>
      {multimedia.active && multimedia.themeMultimedia.length > 0 && (
        <Box as="section" padding={{ base: '24px 15px', md: '40px 107px', lg: '40px 107px' }}>
          {multimedia?.themeMultimedia.map(media => (
            <div
              key={media.id}
              className={media?.title || media?.description ? styles.aboutSection : ''}
            >
              <div
                className={styles.heading}
                style={{ display: !media?.title && !media?.description && 'none' }}
              >
                {media?.title && (
                  <Heading
                    fontSize={{ base: '24px', md: '32px', lg: '32px' }}
                    fontWeight={{ base: 500, md: 600, lg: 600 }}
                    alignSelf={media?.type === 'text' && 'center'}
                  >
                    {media?.title}
                  </Heading>
                )}
                {media?.description && <p>{media?.description.trim()}</p>}
              </div>
              {media?.type !== 'text' && (
                <Box className={styles.mediaWrapper}>
                  {media?.type === 'image' && <img src={media?.file} alt={media?.title} />}
                  {media?.type === 'video' && (
                    <iframe
                      style={{
                        aspectRatio: 16 / 9,
                        width: '100%',
                        display: 'flex',
                        flex: 1,
                        border: 'none',
                      }}
                      title="Tocador de vídeo do YouTube"
                      src={media?.link}
                      allowFullScreen
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                  )}
                </Box>
              )}
            </div>
          ))}
        </Box>
      )}
    </>
  );
}

export default MediasSection;
