import styled, { css } from 'styled-components';

import { Box } from '@chakra-ui/react';

const styles = css`
  color: white;
  background: linear-gradient(360deg, #426bb6 0%, #426bb6 0.01%, #26458c 100%);
  border-radius: 0.625rem;
  box-shadow: -3px -3px 12px rgba(255, 255, 255, 0.08), 3px 3px 12px rgba(0, 0, 0, 0.45);
  transition: 700ms;
`;

export const Container = styled(Box)`
  ${() => css`
    perspective: 43.75rem;
    cursor: pointer;

    &:hover .front-cart {
      transform: rotateY(180deg);
      z-index: 0;
    }

    &:hover .back-cart {
      transform: rotateY(0);
      z-index: 1;
    }
  `}
`;

export const FrontCart = styled(Box)`
  ${() => css`
    ${styles};
    z-index: 1;

    &.front-rotate-click {
      transform: rotateY(180deg);
      z-index: 0;
    }
  `}
`;

export const BackCart = styled(Box)`
  ${() => css`
    ${styles};
    transform: rotateY(-180deg);
    backface-visibility: hidden;

    display: flex;
    align-items: flex-end;

    &.back-rotate-click {
      transform: rotateY(0);
      z-index: 1;
    }
  `}
`;
