import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Skeleton,
  Stack,
  useBreakpointValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomDropzone from '../../../../../../../components/CustomDropzone';
import Editor from '../../../../../../../components/Editor';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../../../../helpers/UploadHelper';
import useFetch from '../../../../../../../hooks/useFetch';
import useHandleChange from '../../../../../../../hooks/useHandleChange';
import useHandleSubmit, { Validation } from '../../../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../../../utils/scrollToInvalidInput';
import { FIVE_MEGABYTES, TEXT_EDITOR_STYLES, TEXT_EDITOR_STYLES_INVALID } from '../../constants';
import { HighlightPlanShowcaseSchemaValidator } from './HighlightPlanShowcaseFormSchema';
import ImagePreview from './ImagePreview';
import ImageUploadLoading from './ImageUploadLoading';
import { HighlightPlanShowcaseType } from './types';
import path from 'path';

const FIELD_STYLES = {
  focusBorderColor: 'gray.300',
  _placeholder: {
    color: 'blackAlpha.500',
    fontSize: 'md',
  },
};

interface ImageFile {
  preview: string;
  file?: File;
}

interface HighlightPlanShowcaseFormProps {
  url: string;
  action: 'new' | 'edit' | 'add';
  data?: Partial<HighlightPlanShowcaseType>;
  showcaseId?: number;
}

export function HighlightPlanShowcaseForm({
  url,
  action,
  showcaseId,
  data,
}: HighlightPlanShowcaseFormProps) {
  const [isUploading, setIsUploading] = useState(false);

  const [imageDesktop, setDesktopImage] = useState<ImageFile | null>({
    preview: data?.imageDesktop,
  });

  const [imageMobile, setMobileImage] = useState<ImageFile | null>({
    preview: data?.imageMobile,
  });

  const toast = useToast();
  const history = useHistory();

  const customDropzoneHeight = useBreakpointValue({ base: '195px', md: '436.225px' });

  const INITIAL_FORM: Partial<HighlightPlanShowcaseType> = {
    showcaseType: 'plans',
    showcaseViewType: 'highlight',
    plansIds: [],
    titleHighlight: '',
    imageDesktop: null,
    imageMobile: null,
    description: '',
    status: 'ATIVO',
    showcaseId,
  };

  const {
    form,
    handleChange,
    setForm,
    onChanged: hasChange,
    setOnChanged: setHasChange,
    handleCancel,
  } = useHandleChange<Partial<HighlightPlanShowcaseType>>(
    INITIAL_FORM,
    action === 'edit' && {
      showcaseType: 'plans',
      showcaseViewType: 'highlight',
      plansIds: [data?.showcasePlanId],
      titleHighlight: data?.titleHighlight,
      imageDesktop: data?.imageDesktop ? path.basename(data?.imageDesktop) : null,
      imageMobile: data?.imageMobile ? path.basename(data?.imageMobile) : null,
      description: data?.description,
      status: data?.status,
    }
  );

  const { data: planListResponse, loading: isFetchingPlans } = useFetch<
    UnificadaFront.ResponseJSON<{ id: number; name: string }[]>
  >({
    method: 'get',
    url: `/showcase/plans`,
    authenticated: true,
    autoFetch: true,
  });

  const planList = planListResponse?.data;

  function handleSelectNewImage(name: 'imageDesktop' | 'imageMobile') {
    setHasChange(true);

    if (name === 'imageDesktop') {
      setDesktopImage({ preview: '', file: null });
      setForm({ ...form, imageDesktop: null });
    } else {
      setMobileImage({ preview: '', file: null });
      setForm({ ...form, imageMobile: null });
    }
  }

  function handleSelectPlan(event: React.ChangeEvent<HTMLSelectElement>) {
    const planId = Number(event.target.value);
    setForm({ ...form, plansIds: [planId] });
  }

  function handleDescriptionChange(description: string) {
    setForm({ ...form, description });
  }

  function handleDropRejected() {
    toast({
      title: 'Formato de arquivo não suportado!',
      description: 'Por favor, envie arquivos nos formatos JPG, PNG, JPEG ou WEBP.',
      status: 'error',
      position: 'top',
    });
  }

  async function uploadFile(file: File) {
    return await UploadHelper.upload(file, 'banners');
  }

  function handleUploadError(error) {
    toast({
      title: ErrorResponse(error),
      status: 'error',
    });
  }

  async function handleDropFile(name: 'imageDesktop' | 'imageMobile', acceptedFiles) {
    try {
      setHasChange(true);
      setIsUploading(true);

      const [file] = acceptedFiles;

      if (file.size > FIVE_MEGABYTES) {
        return toast({
          title: 'Imagem muito grande!',
          description: 'O tamanho máximo deve ser igual ou inferior a 5 MB.',
          status: 'error',
          position: 'top',
        });
      }

      const preview = URL.createObjectURL(file);

      const { newFileName } = await uploadFile(file);

      setForm({ ...form, [name]: newFileName });

      switch (name) {
        case 'imageDesktop':
          setDesktopImage({ preview, file });
          break;

        case 'imageMobile':
          setMobileImage({ preview, file });
          break;

        default:
          break;
      }
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
    }
  }

  const {
    handleSubmit,
    isLoading: isSubmitting,
    formValidation,
  } = useHandleSubmit({
    method: action !== 'edit' ? 'post' : 'patch',
    url,
    data: form,
    schemaValidator: HighlightPlanShowcaseSchemaValidator,
    authenticated: true,
    removeNullProps: true,
    onSuccess: {
      callback: () => {
        history.push('/members-area-management/netflix');
      },
    },
  });

  function onCancel() {
    if (!hasChange) {
      return history.push('/members-area-management/netflix');
    }

    handleCancel();
  }

  async function onSubmit(event: React.FormEvent) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  return (
    <VStack
      onSubmit={onSubmit}
      align="flex-start"
      as="form"
      noValidate
      mt={{ base: 8, xl: 14 }}
      spacing="1.875rem"
      sx={{
        '.chakra-form__label': {
          fontSize: 'sm',
          color: '#202123',
        },
      }}
    >
      <VStack spacing="0.875rem" w="100%" align="flex-start">
        <Heading fontSize="xl" fontWeight="medium" m={0} color="#202123">
          {action === 'new' ? 'Criar' : 'Editar'} plano
        </Heading>

        <Stack direction={{ base: 'column', xl: 'row' }} w="100%" spacing={4}>
          <FormControl isInvalid={formValidation?.titleHighlight?.isInvalid}>
            <FormLabel>Título Destaque</FormLabel>

            <Input
              name="titleHighlight"
              value={form.titleHighlight}
              onChange={handleChange}
              placeholder="Digite aqui"
              {...FIELD_STYLES}
            />

            <FormErrorMessage>{formValidation?.titleHighlight?.message}</FormErrorMessage>
          </FormControl>

          {action !== 'edit' && (
            <FormControl isInvalid={(formValidation?.plansIds as unknown as Validation)?.isInvalid}>
              <FormLabel>Plano</FormLabel>

              <Skeleton isLoaded={!isFetchingPlans} width="full" height="2.5rem">
                <Select
                  name="name"
                  value={form.plansIds?.[0]}
                  onChange={handleSelectPlan}
                  color={!form.plansIds?.[0] ? 'blackAlpha.500' : 'inherit'}
                  sx={{ option: { color: '#202123' } }}
                  {...FIELD_STYLES}
                >
                  <option hidden>Selecione o plano</option>

                  {planList?.map(plan => (
                    <option key={plan.id} value={plan.id}>
                      {plan.name}
                    </option>
                  ))}
                </Select>
              </Skeleton>

              <FormErrorMessage>
                {(formValidation?.plansIds as unknown as Validation)?.message}
              </FormErrorMessage>
            </FormControl>
          )}
        </Stack>

        <FormControl isInvalid={formValidation?.description?.isInvalid}>
          <FormLabel>Descrição</FormLabel>
          <Box
            mt={2}
            sx={
              formValidation?.description?.isInvalid
                ? TEXT_EDITOR_STYLES_INVALID
                : TEXT_EDITOR_STYLES
            }
          >
            <Editor value={form?.description} onChange={handleDescriptionChange} />
          </Box>

          <FormErrorMessage>{formValidation?.description?.message}</FormErrorMessage>
        </FormControl>
      </VStack>

      <Stack direction={{ base: 'column', xl: 'row' }} w="100%" spacing="1.375rem">
        <FormControl isInvalid={formValidation?.imageDesktop?.isInvalid}>
          <FormLabel>Imagem desktop</FormLabel>

          {!form.imageDesktop ? (
            <Box width="full" display="flex" justifyContent="center">
              <Box width="775.512px" maxHeight={{ base: '195px', md: '436.225px' }}>
                <CustomDropzone
                  file={imageDesktop?.file}
                  height={customDropzoneHeight}
                  isDisabled={isSubmitting}
                  accept={['image/jpg', 'image/png', 'image/jpeg']}
                  dimensions="1920x1080"
                  onDrop={files => handleDropFile('imageDesktop', files)}
                  onDropRejected={handleDropRejected}
                  extensions="JPG, PNG, JPEG e WEBP"
                />
                <ImageUploadLoading position="absolute" isLoading={isUploading} />
              </Box>
            </Box>
          ) : (
            <VStack width="full" justifyContent="center">
              <Box width={{ base: 'full', xl: '775.512px' }} height="436.225px">
                <ImagePreview
                  imageField="imageDesktop"
                  preview={imageDesktop?.preview ?? form.imageDesktop}
                  onSelectNewImage={() => handleSelectNewImage('imageDesktop')}
                />
              </Box>

              <Button
                display={{ base: 'flex', xl: 'none' }}
                onClick={() => handleSelectNewImage('imageDesktop')}
                w="full"
                variant="outline"
                colorScheme="primary"
                size="sm"
                zIndex="2"
              >
                Trocar Imagem
              </Button>
            </VStack>
          )}

          <FormErrorMessage>{formValidation?.imageDesktop?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.imageMobile?.isInvalid}>
          <FormLabel>Imagem mobile</FormLabel>

          {!form.imageMobile ? (
            <Box width="full" display="flex" justifyContent="center">
              <Box width="350px" height="438px">
                <CustomDropzone
                  file={imageMobile?.file}
                  height="438px"
                  isDisabled={isSubmitting}
                  accept={['image/jpg', 'image/png', 'image/jpeg']}
                  dimensions="350x438"
                  onDrop={files => handleDropFile('imageMobile', files)}
                  onDropRejected={handleDropRejected}
                  extensions="JPG, PNG, JPEG e WEBP"
                />
                <ImageUploadLoading position="absolute" isLoading={isUploading} />
              </Box>
            </Box>
          ) : (
            <VStack width="full" display="flex" justifyContent="center">
              <Box width="350px" height="438px">
                <ImagePreview
                  imageField="imageMobile"
                  preview={imageMobile?.preview ?? form.imageMobile}
                  onSelectNewImage={() => handleSelectNewImage('imageMobile')}
                />
              </Box>

              <Button
                display={{ base: 'flex', xl: 'none' }}
                onClick={() => handleSelectNewImage('imageMobile')}
                w="full"
                variant="outline"
                colorScheme="primary"
                size="sm"
                zIndex="2"
              >
                Trocar Imagem
              </Button>
            </VStack>
          )}

          <FormErrorMessage>{formValidation?.imageMobile?.message}</FormErrorMessage>
        </FormControl>
      </Stack>

      <HStack as="footer" w="100%" justifyContent="flex-end" mb={10}>
        <ButtonGroup spacing="5">
          <Button
            onClick={onCancel}
            colorScheme="gray"
            bgColor="#E5E5E5"
            border="1px solid rgba(32, 33, 35, 0.25)"
            disabled={isSubmitting}
          >
            Cancelar
          </Button>

          <Button
            colorScheme="primary"
            color="secondary.500"
            type="submit"
            isLoading={isSubmitting}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </HStack>
    </VStack>
  );
}
