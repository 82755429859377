import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  TabPanel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import Pagination from '../../../../../components/Pagination';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import { CardDiscipline } from '../components/CardDiscipline';
import { ModalAddOrEditDiscipline } from '../components/ModalAddOrEditDiscipline';
import { ModalDeleteDiscipline } from '../components/ModalDeleteDiscipline';
import { disciplineSchemaValidator } from '../schemaValidator';

export interface IQuestionLibraryDisciplineData {
  area: {
    id: number;
    name: string;
  };
  id: number;
  name: string;
}

export interface IQuestionLibraryDisciplineResult {
  data: IQuestionLibraryDisciplineData[];
  total: number;
  page: number;
  per_page: number;
}

interface IQuestionLibraryAreaHasPaginateResult {
  data: {
    id: number;
    name: string;
  }[];
  total: number;
  page: number;
}

interface IProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  tabIndex: number;
}

export function TabPanelDiscipline({ isOpen, onOpen, onClose, tabIndex }: IProps) {
  const {
    isOpen: isOpenModalDeleteDiscipline,
    onOpen: onOpenModalDeleteDiscipline,
    onClose: onCloseModalDeleteDiscipline,
  } = useDisclosure();

  const [searchFiltered, setSearchFiltered] = useState<string>('');
  const [disciplineData, setDisciplineData] = useState<IQuestionLibraryDisciplineData[]>();
  const [disciplineDataFiltered, setDisciplineDataFiltered] = useState<
    IQuestionLibraryDisciplineData[]
  >([]);
  const [areaId, setAreaId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState(0);
  const [timer, setTimer] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [disciplineId, setDisciplineId] = useState<number>(0);
  const [disciplineName, setDisciplineName] = useState<string>('');

  const {
    data: getDisciplineData,
    loading: isLoading,
    fetchData: fetchQuestionLibraryDiscipline,
  } = useFetch<UnificadaFront.ResponseJSON<IQuestionLibraryDisciplineResult>>({
    method: 'get',
    url: `/filter/question-library-discipline?page=${currentPage}&perPage=${12}${
      search ? `&search=${search}` : ''
    }`,
    authenticated: true,
    autoFetch: false,
  });

  const {
    data: getAreaData,
    loading: isLoadingAreaData,
    fetchData: fetchDataGetAreaData,
  } = useFetch<UnificadaFront.ResponseJSON<IQuestionLibraryAreaHasPaginateResult>>({
    method: 'get',
    url: `/filter/question-library-area?hasPaginate=false`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (tabIndex === 1) {
      fetchQuestionLibraryDiscipline();
      fetchDataGetAreaData();
    }
  }, [fetchDataGetAreaData, fetchQuestionLibraryDiscipline, tabIndex]);

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit: handleSubmitDiscipline,
  } = useHandleSubmit({
    data: {
      name: disciplineName,
      areaId,
    },
    url: '/filter/question-library-discipline',
    method: 'post',
    schemaValidator: disciplineSchemaValidator,

    authenticated: true,
    onSuccess: {
      message: 'Disciplina criada com sucesso!',
      callback: () => {
        setAreaId(0);
        setDisciplineId(0);
        setDisciplineName('');
        fetchQuestionLibraryDiscipline();
      },
    },
  });

  const {
    isLoading: isEditingDiscipline,
    formValidation: formValidationEditDiscipline,
    handleSubmit: handleSubmitEditDiscipline,
  } = useHandleSubmit({
    data: {
      name: disciplineName,
      areaId,
    },
    url: `/filter/${disciplineId}/question-library-discipline`,
    method: 'patch',
    authenticated: true,
    schemaValidator: disciplineSchemaValidator,
    onSuccess: {
      message: 'Disciplina atualizada com sucesso!',
      callback: () => {
        setAreaId(0);
        setDisciplineId(0);
        setDisciplineName('');
        fetchQuestionLibraryDiscipline();
      },
    },
  });

  const { isLoading: isDeleting, handleSubmit: handleSubmitDeleteDiscipline } = useHandleSubmit({
    url: `/filter/${disciplineId}/question-library-discipline`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Disciplina deletada com sucesso!',
      callback: () => {
        setDisciplineDataFiltered(
          disciplineDataFiltered.filter(discipline => discipline.id !== disciplineId)
        );
        setAreaId(0);
        setDisciplineId(0);
        setDisciplineName('');
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    let result: any;

    if (disciplineId) {
      result = await handleSubmitEditDiscipline();
    } else {
      result = await handleSubmitDiscipline();
    }

    if (result) {
      onClose();
    }
  }

  useEffect(() => {
    if (getDisciplineData?.data?.data) {
      setDisciplineData(getDisciplineData?.data.data);
      setDisciplineDataFiltered(getDisciplineData?.data.data);
      setPageCount(getDisciplineData?.data?.total / getDisciplineData?.data?.per_page);
    } else {
      setDisciplineDataFiltered([]);
    }
  }, [getDisciplineData?.data]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        setSearch(searchFiltered);

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchFiltered, setSearch, timer]);

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchFiltered(value);
    setTimer(2);
  }

  function handlePageChange({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }

  return (
    <TabPanel padding={0}>
      <Text
        fontWeight={400}
        fontSize="14px"
        color="#20212380"
        marginY={5}
        hidden={disciplineData?.length > 0}
      >
        Cadastre as disciplinas de acordo com sua área de atuação, isso facilitará a busca por seus
        produtos, na criação de questões no banco, entre outros benefícios.
      </Text>

      <InputGroup size="sm" marginY={5} hidden={!disciplineData?.length}>
        <InputLeftElement pointerEvents="none">
          <BiSearch color="gray" size={15} />
        </InputLeftElement>
        <Input
          width={{ base: 'full', md: '320px', lg: '320px' }}
          placeholder="Buscar por título"
          borderRadius={6}
          focusBorderColor="orange.500"
          color="#20212380"
          value={searchFiltered}
          onChange={handleChangeSearch}
        />
      </InputGroup>

      <Stack direction="row" width="full" justifyContent="center" margin={10} hidden={!isLoading}>
        <Spinner color="orange.500" size="lg" />
      </Stack>

      {!disciplineDataFiltered.length && (
        <Heading
          fontWeight={400}
          padding={10}
          fontSize="lg"
          color="#20212380"
          marginY={5}
          textAlign="center"
          hidden={!disciplineData?.length}
        >
          Nenhum resultado encontrado para "{search}"
        </Heading>
      )}

      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        flex={1}
        gap={5}
        hidden={isLoading || !disciplineDataFiltered.length}
      >
        {disciplineDataFiltered?.map(discipline => (
          <CardDiscipline
            key={discipline.id}
            discipline={discipline}
            setDisciplineId={setDisciplineId}
            setDisciplineName={setDisciplineName}
            onOpenModalAddOrEditDiscipline={onOpen}
            onOpenModalDeleteDiscipline={onOpenModalDeleteDiscipline}
            setAreaId={setAreaId}
          />
        ))}
      </Box>

      {disciplineDataFiltered?.length !== 0 && getDisciplineData?.data?.total > 12 && (
        <Stack w="100%" justifyContent="center" alignItems="center" my={10}>
          <Pagination
            pageCount={pageCount}
            onPageChange={handlePageChange}
            onPageActive={currentPage - 1}
          />
        </Stack>
      )}

      <ModalAddOrEditDiscipline
        isOpen={isOpen}
        onClose={onClose}
        areaId={areaId}
        setAreaId={setAreaId}
        id={disciplineId}
        disciplineName={disciplineName}
        setDisciplineName={setDisciplineName}
        isSubmitting={isSubmitting || isEditingDiscipline}
        areaData={getAreaData?.data?.data}
        loading={isLoadingAreaData}
        onSubmit={onSubmit}
        formValidation={formValidation || formValidationEditDiscipline}
      />

      <ModalDeleteDiscipline
        key={disciplineId}
        isDeleting={isDeleting}
        handleSubmit={handleSubmitDeleteDiscipline}
        disciplineName={disciplineName}
        isOpen={isOpenModalDeleteDiscipline}
        onClose={onCloseModalDeleteDiscipline}
      />
    </TabPanel>
  );
}
