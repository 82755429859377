import { Box } from '@chakra-ui/react';
import process from 'process/browser';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FacebookIntegrationAPI from '../../api/FacebookIntegration';
import MarketplaceAPI from '../../api/Marketplace';
import Accordion from '../../components/Accordion';
import Container from '../../components/ContainerSite/ContainerSite';
import CookieBanner from '../../components/CookieBanner';
import Toast from '../../components/Toast';
import ErrorResponse from '../../helpers/ErrorResponse';
import FacebookPixel from '../../helpers/FacebookPixel';
import { IS_COOKIES_ACCEPTED } from '../../helpers/LocalStorageHelper';
import { googleTagManagerIds, newEventGTM, removeEventGTM } from '../../helpers/ga-tag-custom';
import useQuery from '../../helpers/useQuery';
import Banner from './Banner';
import Module from './Module';
import Purchase from './Purchase';
import './style.css';

const initialState = {
  id: null,
  name: '',
  url: null,
  thumbnail: '',
  status: '',
  owner: '',
  freeAccess: null,
  limitedAccess: null,
  courseAccessDeadline: '',
  standardPaymentValue: '',
  coursePriceDefault: {
    purchasePrice: '',
    installments: null,
    formOfPayment: '',
    default: null,
    courseId: null,
  },
  area: null,
  subject: null,
  description: '',
  bannerToShow: null,
  banners: [],
  modules: [],
  frequentlyQuestion: [],
  externalLinkSale: '',
  installmentWithTariff: false,
  baseAmountFeesAndCommissions: [],
  plans: [],
  subscription: null,
  deadlineForReimbursement: null,
  instructorsNames: [],
};

function Sales() {
  const { id } = useParams();

  const [course, setCourse] = useState(initialState);

  const [externalLinkSale, setExternalLinkSale] = useState(true);

  const [errorMessage, setErrorMessage] = useState(false);

  const history = useHistory();

  const query = useQuery();

  const affiliation = query.get('affiliation');

  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const head = document.getElementsByTagName('head').item(0);

    async function insertPixels() {
      try {
        const { data: pixelData } = await FacebookIntegrationAPI.getPixelByProduct(id, affiliation);

        if (pixelData && pixelData.length) {
          let currentPixel;
          let script;

          for (let pixel of pixelData) {
            currentPixel = head.querySelector('prolunofbp');

            currentPixel = document.createElement('script');
            currentPixel.id = pixel.courseId;
            currentPixel.setAttribute('prolunofbp', pixel.courseId);

            currentPixel.id = pixel.identifier;
            script = FacebookPixel.baseCode(currentPixel.id);

            const pixelExists = head.querySelectorAll(
              `[id="${pixel.identifier}"][prolunofbp="${pixel.courseId}"]`
            );

            if (process?.env.NODE_ENV !== 'development' && !pixelExists.length) {
              currentPixel.innerHTML = script;
              head.appendChild(currentPixel);
            }
          }
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }

    if (head) {
      insertPixels();
    }

    return () => {
      const pixels = document.querySelectorAll('[prolunofbp]');

      if (pixels.length && !history.location.pathname.includes('student-checkout')) {
        for (let pixel of Array.from(pixels)) {
          head?.removeChild(pixel);
        }
      }
    };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const idsGTM = googleTagManagerIds();

    if (course.id) {
      for (const id of idsGTM) {
        newEventGTM({
          id,
          data: {
            event: 'view_item',
            items: {
              item_id: course.id,
              item_name: course.name,
              value: course?.standardPaymentValue,
              quantity: 1,
            },
          },
        });

        removeEventGTM(id, 'view_item');
      }
    }
  }, [course.id, course.name, course?.standardPaymentValue, isCookiesAccepted]);

  useEffect(() => {
    async function getCourse(id) {
      let externalLinkSale = null;

      try {
        const dataCourse = await MarketplaceAPI.show(id);

        if (dataCourse) {
          externalLinkSale = dataCourse.data?.externalLinkSale;

          if (externalLinkSale) {
            window.location.href = dataCourse.data.externalLinkSale;
            return;
          }

          setCourse(dataCourse.data);
        }
      } catch (error) {
        setErrorMessage(ErrorResponse(error));
        Toast(ErrorResponse(error), 'error');

        history.push('/all-products');
      } finally {
        setExternalLinkSale(!!externalLinkSale);
      }
    }

    getCourse(id);
  }, [history, id]);

  return (
    !externalLinkSale && (
      <Container>
        <>
          {/* BANNER */}
          <section className="section-banner d-flex justify-content-center ">
            {course.banners && <Banner course={course} />}
          </section>

          {/* SOBRE O CURSO */}
          <section className="section-description pb-5">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div
                    className={!course?.banners?.length ? 'spacing-heading-title' : 'heading-title'}
                  >
                    <span>{course.name || '...'}</span>
                  </div>
                  {course.description && (
                    <>
                      <Box
                        whiteSpace="pre-wrap"
                        className="content-course"
                        sx={{
                          '& > p:last-child': {
                            display: 'inline',
                          },
                        }}
                        dangerouslySetInnerHTML={{ __html: course.description }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>

          {/* ADQUIRIR AGORA OU PLANOS DE ASSINATURA*/}
          {!errorMessage && <Purchase course={course} id={id} />}

          {/* GRADE CURRICULAR */}
          {!errorMessage && (
            <section className="section-content-course pb-5">
              <div className="container-fluid">
                <div className="row justify-content-center aling-items-center">
                  <div className="container-heading">
                    <h2 className="heading-content">CONFIRA O CONTEÚDO</h2>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <div className="col-lg-10 col-md-12 col-sm-12">
                    {course.modules &&
                      course.modules.map(module => {
                        return (
                          <div key={module.id}>
                            <Module
                              id={`module${module.id}`}
                              title={module.name}
                              contents={module.contents}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
          )}

          {!errorMessage && (
            <section className="section-faq">
              <div className="container-fluid">
                <div className="flex-column align-items-center justify-center">
                  <h2 className="heading-faq">PERGUNTAS FREQUENTES</h2>
                  <div className="col-lg-10 col-md-12 col-sm-12">
                    {course.frequentlyQuestion &&
                      course.frequentlyQuestion.map(questions => (
                        <Accordion
                          key={questions.id}
                          title={questions.question}
                          content={questions.questionAnswer}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* ADQUIRIR AGORA OU PLANOS DE ASSINATURA*/}
          {!errorMessage && <Purchase course={course} id={id} extend />}
        </>

        <CookieBanner />
      </Container>
    )
  );
}

export default Sales;
