import { Box, Button } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

import './styles.css';

function SelectImageButton({ isDisabled, onDrop }) {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'],
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  function handleClick(event) {
    event.preventDefault();
    if (isDisabled) return;
    open();
  }

  return (
    <Box {...getRootProps()} w={{ base: 'full', md: 'auto', lg: 'auto' }}>
      <input {...getInputProps()} />
      <Button
        onClick={handleClick}
        disabled={isDisabled}
        colorScheme="primary"
        color="secondary.500"
        size="sm"
        p={{ base: 1, md: 2, lg: 2 }}
        w={{ base: '100%', md: 'auto', lg: 'auto' }}
        my={{ base: 2, md: 0, lg: 0 }}
      >
        Selecionar Imagem
      </Button>
    </Box>
  );
}

export default SelectImageButton;
