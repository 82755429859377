import { Box, Button, Heading, Spinner, Stack } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Paginate from '../../../../../../../components/Paginate';
import { useCourse } from '../../../../../../../contexts/CourseContext';
import useFetch from '../../../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../../../hooks/useHandleSubmit';
import { QuestionCard } from '../components/QuestionCard';
import {
  IQuestionLibraryAreaHasPaginateResult,
  IQuestionLibraryDisciplineHasPaginateResult,
  IQuestionLibraryInstitutionHasPaginateResult,
  IQuestionLibraryQuestionResult,
  IQuestionLibrarySubjectHasPaginateResult,
} from '../types';
import { HeaderFilter } from './components/HeaderFilter';

type Params = {
  id: string;
  moduleId: string;
  contentId: string;
};

export function InsertQuestions() {
  const { id: courseId, moduleId, contentId } = useParams<Params>();
  const { getContent } = useCourse();
  const [questionIdsToAdd, setQuestionIdsToAdd] = useState<number[]>([]);
  const [contentTitle, setContentTitle] = useState<string>('Conteúdo tipo Questões');
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const searchParams = urlSearchParams.toString();
  const currentPage = Number(urlSearchParams.get('page')) || 1;

  const url = searchParams
    ? `/question-library/question?${searchParams}`
    : '/question-library/question';

  const { data: questionLibraryQuestionData, loading: isFetching } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryQuestionResult>
  >({
    url,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const { isLoading: isSubmitting, handleSubmit: handleAddQuestions } = useHandleSubmit({
    url: `/question-library/${contentId}/content/add-questions`,
    data: { questionsLibraryIds: questionIdsToAdd },
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Questões adicionadas ao conteúdo!',
      redirect: {
        to: `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${contentId}/edit-question-library`,
      },
    },
  });

  const disabledAddButton = isSubmitting || !questionIdsToAdd.length;

  const response = questionLibraryQuestionData?.data;
  const questions = response?.data;
  const total = response?.total || 0;
  const perPage = response?.per_page || 1;

  const isLoading = isFetching || isSubmitting;
  const isSearching = !!searchParams;
  const isQuestionsVisible = !isLoading && questions?.length;
  const isNotFoundVisible = !isLoading && !questions?.length && isSearching;
  const isEmpty = !isLoading && !questions?.length && !isSearching;
  const pageCount = Math.ceil(total / perPage);
  const shouldShowPagination = !isLoading && pageCount > 1;

  useEffect(() => {
    const content = getContent(moduleId, Number(contentId));

    if (!contentId) {
      return setContentTitle('Conteúdo tipo Questões');
    }

    if (content) {
      setContentTitle(content.title);
    }
  }, [contentId, getContent, moduleId]);

  const { data: areaData, loading: isLoadingAreaData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryAreaHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-area?hasPaginate=false`,
    authenticated: true,
    autoFetch: true,
  });

  const { data: disciplineData, loading: isLoadingDisciplineData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryDisciplineHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-discipline?hasPaginate=false`,
    authenticated: true,
    autoFetch: true,
  });

  const { data: subjectData, loading: isLoadingSubjectData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibrarySubjectHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-subject?hasPaginate=false`,
    authenticated: true,
    autoFetch: true,
  });

  const { data: institutionData, loading: isLoadingInstitutionData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryInstitutionHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-institution?hasPaginate=false`,
    authenticated: true,
    autoFetch: true,
  });

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    queryParams.set('page', newPage.toString());

    const newSearch = queryParams.toString();

    history.push(`?${newSearch}`);
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} marginBottom={5}>
        <Box
          as={Link}
          to={`/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${contentId}/edit-question-library`}
        >
          <MdArrowBackIos size={18} />
        </Box>
        <Heading fontSize="2xl" fontWeight={500}>
          {contentTitle}
        </Heading>
      </Stack>

      <HeaderFilter
        areaListData={areaData?.data?.data}
        disciplineListData={disciplineData?.data?.data}
        subjectListData={subjectData?.data?.data}
        institutionData={institutionData?.data?.data}
        isLoadingAreaData={isLoadingAreaData}
        isLoadingDisciplineData={isLoadingDisciplineData}
        isLoadingSubjectData={isLoadingSubjectData}
        isLoadingInstitutionData={isLoadingInstitutionData}
      />

      <Stack spacing={2} paddingTop={2}>
        {isQuestionsVisible &&
          questions.map(question => (
            <QuestionCard
              key={question.id}
              question={question}
              questionIdsToAdd={questionIdsToAdd}
              setQuestionIdsToAdd={setQuestionIdsToAdd}
            />
          ))}

        {isLoading && (
          <Stack direction="row" width="full" justifyContent="center" margin={30}>
            <Spinner size="lg" color="orange.500" />
          </Stack>
        )}

        {isEmpty && (
          <Stack width="full" alignItems="center" margin={30}>
            <Heading fontSize="md" color="#20212380">
              Você não possui questões para adicionar nesse conteúdo.
            </Heading>
            <Heading fontSize="md" color="#20212380">
              {`Para cadastrar questões, acesse o menu "Ferramentas" > "Biblioteca de Questões"`}
            </Heading>
          </Stack>
        )}

        {isNotFoundVisible && (
          <Stack width="full" alignItems="center" margin={30}>
            <Heading fontSize="md" color="#20212380">
              Nenhum resultado encontrado
            </Heading>
          </Stack>
        )}

        {shouldShowPagination && (
          <Paginate
            my={6}
            pageCount={pageCount}
            initialPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}

        <Stack
          direction="row"
          width="full"
          justifyContent={{ base: 'center', md: 'flex-end', lg: 'flex-end' }}
          spacing={3}
          paddingY={5}
        >
          <Button
            size="sm"
            width={{ base: '100%', md: 'auto', lg: 'auto' }}
            as={Link}
            to={`/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${contentId}/edit-question-library`}
          >
            Cancelar
          </Button>
          <Button
            width={{ base: '100%', md: 'auto', lg: 'auto' }}
            size="sm"
            colorScheme="orange"
            isDisabled={disabledAddButton}
            isLoading={isSubmitting}
            onClick={handleAddQuestions}
          >
            Adicionar
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
