export const months = [
  {
    id: '01',
    abbreviation: 'Jan',
    name: 'Janeiro',
  },
  {
    id: '02',
    abbreviation: 'Fev',
    name: 'Fevereiro',
  },
  {
    id: '03',
    abbreviation: 'Mar',
    name: 'Março',
  },
  {
    id: '04',
    abbreviation: 'Abr',
    name: 'Abril',
  },
  {
    id: '05',
    abbreviation: 'Maio',
    name: 'Maio',
  },
  {
    id: '06',
    abbreviation: 'Jun',
    name: 'Junho',
  },
  {
    id: '07',
    abbreviation: 'Jul',
    name: 'Julho',
  },
  {
    id: '08',
    abbreviation: 'Ago',
    name: 'Agosto',
  },
  {
    id: '09',
    abbreviation: 'Set',
    name: 'Setembro',
  },
  {
    id: '10',
    abbreviation: 'Out',
    name: 'Outubro',
  },
  {
    id: '11',
    abbreviation: 'Nov',
    name: 'Novembro',
  },
  {
    id: '12',
    abbreviation: 'Dez',
    name: 'Dezembro',
  },
];

const currentYear = new Date().getFullYear();

export const years = Array.from({ length: 21 }, (_, i) => currentYear + i).map(year => ({
  id: Number(String(year).slice(-2)),
  name: String(year),
}));
