import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { BiDotsHorizontal } from 'react-icons/bi';
import { Link } from 'react-router-dom';

export default function ActionsButton({
  id,
  onOpenConfirmDelete,
  setCourseIdToDelete,
  onOpenCourseDuplicate,
  setCourseIdToDuplicate,
}) {
  return (
    <Menu>
      <MenuButton w="27px" h="24px" bgColor="#20212312" borderRadius={5} p={1} m={1}>
        <BiDotsHorizontal size="20px" color="#20212380" />
      </MenuButton>
      <MenuList>
        <Link to={`/courses/${id}/course-manager/course`}>
          <MenuItem fontWeight="500">Editar</MenuItem>
        </Link>
        <MenuItem
          fontWeight="500"
          onClick={() => {
            setCourseIdToDuplicate(id);
            onOpenCourseDuplicate();
          }}
        >
          Duplicar
        </MenuItem>

        <Link to={`/students-report/${id}/course?page=1`}>
          <MenuItem fontWeight="500">Lista de Alunos</MenuItem>
        </Link>
        <Link to={`/reporting/${id}/course`}>
          <MenuItem fontWeight="500">Relatório Questões</MenuItem>
        </Link>
        <Link to={`/courses/${id}/correction-task?page=1`}>
          <MenuItem fontWeight="500">Corrigir tarefas</MenuItem>
        </Link>
        <MenuItem
          fontWeight="500"
          onClick={() => {
            setCourseIdToDelete(id);
            onOpenConfirmDelete();
          }}
          color="red.500"
        >
          Excluir
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
