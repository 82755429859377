import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AuthenticationApi from '../../api/Authentication';
import StudentBaseApi from '../../api/StudentBase';
import Toast from '../../components/Toast';
import { useTheme } from '../../contexts/ThemeContext';
import ErrorResponse from '../../helpers/ErrorResponse';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  VStack,
} from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

export default function ForgetPasswordStudent() {
  const history = useHistory();
  const { getTheme, theme, isLoading } = useTheme();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [hasChange, setHasChange] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [form, setForm] = useState({
    email: searchParams.get('email'),
    token: searchParams.get('token'),
    password: '',
  });

  useEffect(() => {
    async function fetchTheme() {
      await getTheme();
    }

    fetchTheme();
  }, [getTheme]);

  useEffect(() => {
    if (!searchParams.has('email') || !searchParams.has('token')) {
      return history.push('/');
    }
  }, [history, searchParams]);

  function onChange(event) {
    const { name, value } = event.target;

    setForm({ ...form, [name]: value });
    setHasChange(true);
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    setHasChange(false);
    setIsSubmitting(true);

    try {
      await StudentBaseApi.forgotPassword(form.password, form.token);

      await AuthenticationApi.login(form.email, form.password);

      Toast('Senha alterada com sucesso');

      const from = searchParams.get('from');
      const productId = searchParams.get('productId');
      const affiliation = searchParams.get('affiliation');

      if (from && productId) {
        return history.push(
          `/${from}/${productId}/student-checkout/payment/credit-card${
            affiliation ? `?affiliation=${affiliation}` : ''
          }}`
        );
      }

      history.push('/login');

      setHasChange(true);
    } catch (error) {
      setHasChange(true);
      setErrorMessage(ErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }

  const isNewUser = searchParams.get('new-user') === 'true';

  return (
    <>
      {!isLoading && (
        <>
          <Flex direction="column" minHeight="100vh" bg="#f8f8f8">
            <Flex as="header" justify="center" align="center" background="primary.500" height={20}>
              <Image w={132} h={50} objectFit="contain" src={theme.logo} alt="Logo da plataforma" />
            </Flex>

            <VStack
              mt={{ xl: 16 }}
              align={{ base: 'start', xl: 'center' }}
              justify="center"
              flex={{ base: 1, xl: 'unset' }}
            >
              <VStack
                px={{ base: 4, xl: 10 }}
                py={{ base: 8 }}
                align="start"
                w="full"
                flex={1}
                maxWidth={{ base: 'unset', xl: '420px' }}
                background="#ffffff"
                boxShadow="0px 4px 4px 0px #00000040"
                borderRadius="5px"
                border="1px solid #0000000D"
                spacing={6}
              >
                <Heading
                  as="h1"
                  fontSize={{ base: 'xl', xl: '2xl' }}
                  color="#202123"
                  fontWeight="semibold"
                >
                  {!isNewUser ? 'Esqueceu a senha?' : 'Cadastre sua senha'}
                </Heading>

                <Box as="form" w="full" onSubmit={handleSubmit}>
                  <FormControl>
                    <FormLabel color="#202123">E-mail</FormLabel>

                    <Input
                      name="email"
                      id="email"
                      type="email"
                      value={form.email}
                      autoComplete="username"
                      readOnly
                      focusBorderColor="gray.200"
                      color="#202123"
                      isRequired
                    />
                  </FormControl>

                  <FormControl mt={6}>
                    <FormLabel color="#202123">Senha</FormLabel>

                    <InputGroup size="md">
                      <Input
                        type={show ? 'text' : 'password'}
                        name="password"
                        id="password"
                        value={form.password}
                        placeholder="Cadastre sua senha"
                        onChange={onChange}
                        minLength={6}
                        maxLength={60}
                        isRequired
                        focusBorderColor="gray.200"
                        color="#202123"
                      />
                      <InputRightElement
                        width="3rem"
                        _hover={{ cursor: 'pointer' }}
                        onClick={handleClick}
                      >
                        {!show ? <Icon as={MdVisibility} /> : <Icon as={MdVisibilityOff} />}
                      </InputRightElement>
                    </InputGroup>

                    <FormHelperText>Deve ter pelo menos 6 caracteres</FormHelperText>
                  </FormControl>

                  {!!errorMessage && (
                    <Alert status="error" mt={8}>
                      <AlertIcon />
                      <AlertDescription>{errorMessage}</AlertDescription>
                    </Alert>
                  )}

                  <HStack justify={{ xl: 'end' }} mt={8}>
                    <Button
                      type="submit"
                      colorScheme="primary"
                      color="secondary.500"
                      fontWeight="semibold"
                      width={{ base: 'full', xl: 'initial' }}
                      size="sm"
                      isDisabled={!hasChange}
                      isLoading={isSubmitting}
                    >
                      {!isNewUser ? 'Alterar senha' : 'Salvar senha'}
                    </Button>
                  </HStack>
                </Box>
              </VStack>
            </VStack>
          </Flex>
        </>
      )}
    </>
  );
}
