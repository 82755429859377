import { Box, Button, Link, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { ROLE_LOCAL_STORAGE, TOKEN_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';

import path from 'path';
import { HiDownload as DownloadIcon } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import { baseURLBackend } from '../../../../../api/Configuration';
import CourseAPI from '../../../../../api/Course';
import Toast from '../../../../../components/Toast';
import { useStudentCourse } from '../../../../../contexts/CourseStudentContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import styles from './styles.module.css';
import { scrollbarStyle } from '..';

function ViewContent({ content, moduleId, contentId, courseId, hasChange }) {
  return (
    <div className={styles.div_view_content}>
      <div className={styles.view_content}>
        <h3 style={{ fontSize: '30px' }}>{content.title}</h3>

        <Box
          my={4}
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: content.content }}
          sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
        />

        <Box
          my={5}
          mx={2}
          h="auto"
          border="2px"
          borderColor="#8091A7"
          borderRadius={10}
          display="flex"
          flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box m={2}>
            <Text>{path.basename(decodeURI(content.download)).slice(0, 56)}</Text>
          </Box>

          <Box m={2}>
            <Link
              _hover={{ textDecoration: 'none' }}
              href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${localStorage.getItem(
                TOKEN_LOCAL_STORAGE
              )}`}
              target="_blank"
              download={true}
            >
              <Button colorScheme="primary" size="sm" variant="outline" isDisabled={hasChange}>
                Fazer o Download
                <DownloadIcon ml={2} color="#d55411" />
              </Button>
            </Link>
          </Box>
        </Box>
      </div>
    </div>
  );
}

function ViewDownload() {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const { studentCourse, getContent } = useStudentCourse();
  const [content, setContent] = useState();
  const [hasChange, setHasChange] = useState(false);

  const { courseId, moduleId, contentId } = useParams();

  useEffect(() => {
    if (studentCourse) {
      const currentContent = getContent(studentCourse, moduleId, contentId);
      if (currentContent) {
        setContent(currentContent);
      }
    }
  }, [contentId, getContent, moduleId, studentCourse]);

  const updateLastVisit = useCallback(async () => {
    try {
      setHasChange(true);
      await CourseAPI.updateLastVisit(courseId, moduleId, contentId, 'DOWNLOAD');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setHasChange(false);
    }
  }, [contentId, courseId, moduleId]);

  useEffect(() => {
    if (role === 'ALUNO' && studentCourse) {
      updateLastVisit();
    }
  }, [updateLastVisit, role, studentCourse]);

  return (
    <Box
      w="full"
      overflow="auto"
      sx={scrollbarStyle}
      margin={{ base: '0', md: '20px 70px', lg: '20px 70px' }}
    >
      <section className={styles.section__download}>
        <div className="d-flex justify-content-md-center h-100">
          <div className="col-sm-12 col-md-10 col-lg-10 p-0">
            <div className={styles.container_view_text}>
              <div className="card shadow-sm pb-4">
                {content && (
                  <ViewContent
                    content={content}
                    moduleId={moduleId}
                    contentId={contentId}
                    courseId={courseId}
                    hasChange={hasChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Box>
  );
}

export default ViewDownload;
