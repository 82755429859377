import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  IconButton,
  List,
  ListItem,
  ListProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Plan } from '../..';
import convertToBrazilianCurrency from '../../../../../../../helpers/convertToBrazilianCurrency';

interface PlanListMobileProps extends ListProps {
  planList: Plan[];
}

export default function PlanListMobile({ planList, ...rest }: PlanListMobileProps) {
  return (
    <List spacing={2.5} {...rest}>
      {planList.map(plan => (
        <Card
          key={plan.id}
          as={ListItem}
          border="1px solid #2021230D"
          boxShadow="0px 4px 4px 0px #00000040"
        >
          <CardHeader p={4} pb={0}>
            <HStack justify="space-between" align="start" alignItems="start">
              <Text maxWidth="16.25rem" color="gray.700" lineHeight="normal" fontWeight="medium">
                {plan.name}
              </Text>

              <Menu size="sm" arrowPadding={0}>
                <MenuButton
                  size="xs"
                  as={IconButton}
                  icon={<MoreIcon size="0.75rem" />}
                  w="1.125rem"
                  h="1rem"
                  minW="unset"
                  borderRadius={5}
                  bgColor="#2021231F"
                  _hover={{ bgColor: '#b6b5b5' }}
                  _active={{ bgColor: '#b6b5b5' }}
                />

                <MenuList
                  sx={{
                    '.chakra-menu__menuitem': {
                      fontWeight: 'medium',
                      fontSize: 'xs',
                      color: '#20212380',
                      _hover: { bgColor: '#2021230D' },
                      _focus: { bgColor: '#2021230D' },
                    },
                  }}
                >
                  <MenuItem
                    as={Link}
                    to={{
                      pathname: `/recurrence/subscriber-management/${plan.id}`,
                      state: { planName: plan.name },
                    }}
                  >
                    Gestão de assinantes
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </CardHeader>

          <CardBody
            mt={4}
            p={4}
            pt={0}
            color="#20212380"
            sx={{ strong: { fontWeight: 'semibold' } }}
          >
            <List spacing={1}>
              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Assinaturas:</Text>
                <Text>{plan.numberOfSignatures}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Cancelamentos:</Text>
                <Text>{plan.numberOfCancellations}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Faturamento:</Text>
                <Text>{convertToBrazilianCurrency(plan.invoicing)}</Text>
              </HStack>
            </List>
          </CardBody>
        </Card>
      ))}
    </List>
  );
}
