import { MdEdit } from 'react-icons/md';
import styles from './styles.module.css';

interface IProps {
  mobilePreview: string;
  onClick: () => void;
}

function MobilePreview({ mobilePreview, onClick }: IProps) {
  return (
    <div className={styles.container}>
      <div style={{ backgroundImage: `url("${mobilePreview}")` }} className={styles.image} />
      <button onClick={onClick} className={`${styles.headerLogoButton}`}>
        <MdEdit />
        Alterar
      </button>
    </div>
  );
}

export default MobilePreview;
