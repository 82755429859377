import { useDisclosure, useToast } from '@chakra-ui/react';
import path from 'path';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { getCountryByDDI, getDDI } from '../../components/CountrySelect';
import ErrorResponse from '../../helpers/ErrorResponse';
import UploadHelper from '../../helpers/UploadHelper';
import scrollToInvalidInput from '../../utils/scrollToInvalidInput';
import useFetch from '../useFetch';
import useHandleSubmit from '../useHandleSubmit';
import { profileSchemaValidator } from './profileSchemaValidator';
import { ProfileForm, ProfileResponse } from './types';

const FIVE_MEGABYTES = 5 * 1024 * 1024;

export function useStudentProfile() {
  const [form, setForm] = useState<Partial<ProfileForm>>();
  const [hasChange, setHasChange] = useState(false);
  const [preview, setPreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const toast = useToast();

  const {
    isOpen: isChangePasswordModalOpen,
    onOpen: onOpenChangePasswordModal,
    onClose: onCloseChangePasswordModal,
  } = useDisclosure();

  const isForeignUser = form?.ddi !== '55';

  const { data: response, loading: isFetching } = useFetch<
    UnificadaFront.ResponseJSON<ProfileResponse>
  >({
    url: '/students',
    method: 'get',
    authenticated: true,
    autoFetch: true,
  });

  const profileData = response?.data;

  useEffect(() => {
    setForm({
      photo: profileData?.photo ?? '',
      fullName: profileData?.fullName ?? '',
      email: profileData?.email ?? '',
      phone: profileData?.phone ?? '',
      dateOfBirth: profileData?.dateOfBirth ?? '',
      documentNumber: profileData?.documentNumber ?? '',
      ddd: profileData?.ddd ?? '',
      ddi: profileData?.ddi ?? '',
      streetAddress: profileData?.address?.streetAddress ?? '',
      streetNumber: profileData?.address?.streetNumber ?? '',
      neighborhood: profileData?.address?.neighborhood ?? '',
      complementary: profileData?.address?.complementary ?? '',
      state: profileData?.address?.state ?? '',
      city: profileData?.address?.city ?? '',
      alternateAddress: profileData?.address?.alternateAddress ?? '',
      country: getCountryByDDI(profileData?.ddi) ?? '',
      zipCode: profileData?.address?.zipCode ?? '',
    });

    setPreview(profileData?.photo);
  }, [profileData]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;

    setHasChange(true);

    setForm(prevForm => ({ ...prevForm, [name]: value }));
  }, []);

  const handleStateChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    setHasChange(true);

    setForm(prevForm => ({ ...prevForm, state: value, city: '' }));
  }, []);

  async function uploadFile(file: File) {
    return await UploadHelper.upload(file, 'profiles');
  }

  function handleUploadError(error) {
    toast({
      title: ErrorResponse(error),
      status: 'error',
    });
  }

  async function handleDropFile(acceptedFiles) {
    try {
      setHasChange(true);
      setIsUploading(true);

      const [file] = acceptedFiles;

      if (file.size > FIVE_MEGABYTES) {
        return toast({
          title: 'Imagem muito grande!',
          description: 'O tamanho máximo deve ser igual ou inferior a 5 MB.',
          status: 'error',
          position: 'top',
        });
      }

      setPreview(URL.createObjectURL(file));

      const { newFileName } = await uploadFile(file);

      setForm({ ...form, photo: newFileName });
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
    }
  }

  function handleDiscardImage() {
    setPreview(null);
    setForm({ ...form, photo: null });
    setHasChange(true);
  }

  function handleDropRejected() {
    toast({
      title: 'Arquivo inválido!',
      description: 'Formatos suportados: JPG, PNG, JPEG',
      status: 'error',
    });
  }

  const handleCountryChange = useCallback((country: string) => {
    setForm(prevForm => ({ ...prevForm, ddi: getDDI(country), country, alternateAddress: '' }));
    setHasChange(true);
  }, []);

  const payload: Partial<ProfileForm> = form
    ? Object.keys(form)
        .map(key => {
          const value = form[key];
          return [key, typeof value === 'string' && value.trim() === '' ? null : value];
        })
        .reduce((acc, [key, value]) => {
          acc[key as keyof ProfileForm] = value;
          return acc;
        }, {} as Partial<ProfileForm>)
    : {};

  if (payload?.photo) {
    payload.photo = path.basename(payload?.photo);
  }

  const {
    formValidation,
    handleSubmit,
    isLoading: isSubmitting,
  } = useHandleSubmit({
    data: payload,
    schemaValidator: profileSchemaValidator,
    method: 'put',
    url: '/students',
    authenticated: true,
    removeNullProps: false,
    onSuccess: {
      message: 'Perfil atualizado com sucesso!',
      callback: () => setHasChange(false),
    },
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  const isPhoneInvalid = formValidation?.ddd.isInvalid || formValidation?.phone.isInvalid;

  return {
    form,
    formValidation,
    hasChange,
    preview,
    isUploading,
    isSubmitting,
    isFetching,
    isForeignUser,
    isPhoneInvalid,
    isChangePasswordModalOpen,
    handleChange,
    onCloseChangePasswordModal,
    onOpenChangePasswordModal,
    handleCountryChange,
    handleDropRejected,
    handleDiscardImage,
    handleDropFile,
    handleStateChange,
    onSubmit,
  };
}
