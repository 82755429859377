import { ReactNode } from 'react';
import { MdChevronLeft, MdOutlineSmartDisplay } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import {
  HStack,
  Heading,
  Icon,
  IconButton,
  Link,
  Show,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';

interface HeaderProps {
  tutorialLink?: string;
  description?: string;
  title?: string;
  path?: string;
  children?: ReactNode;
}

function Header({ title, tutorialLink, description, path, children }: HeaderProps) {
  const history = useHistory();

  function navigateToBack() {
    if (path) {
      history.push(`/integrations/${path}`);
      return;
    }

    history.push('/integrations');
  }

  return (
    <VStack align="start">
      <HStack justify="space-between" align="center" spacing={4} w="full">
        <HStack
          divider={
            !!tutorialLink && (
              <StackDivider h={6} alignSelf="center" borderWidth="1px" borderColor="#202113" />
            )
          }
        >
          <HStack spacing={0.5}>
            <IconButton
              onClick={navigateToBack}
              icon={<MdChevronLeft size="1.75rem" />}
              aria-label="Voltar"
              variant="ghost"
              colorScheme="gray"
              size="sm"
            />
            <Heading as="h1" fontSize={{ base: 'lg', xl: '2xl' }} fontWeight="semibold">
              {title}
            </Heading>
            H
          </HStack>

          {!!tutorialLink && (
            <Tooltip hasArrow label="Tutorial" placement="right">
              <Text as="span">
                <Link isExternal href={tutorialLink}>
                  <Icon as={MdOutlineSmartDisplay} boxSize={6} color="default.500" />
                </Link>
              </Text>
            </Tooltip>
          )}
        </HStack>

        <Show above="xl">{children}</Show>
      </HStack>

      <Text
        mx={0}
        my={{ base: 0, xl: '1.125rem' }}
        color="#20212380"
        fontSize={{ base: 'sm', xl: 'lg' }}
        whiteSpace="pre-wrap"
        lineHeight={1.25}
      >
        {description}
      </Text>

      <Show below="xl">{children}</Show>
    </VStack>
  );
}

export default Header;
