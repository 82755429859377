import { Box, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';

export default function FullPageLoading({ isLoading }: { isLoading: boolean }) {
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);

  return (
    <Box
      inset={0}
      display={isLoading ? 'flex' : 'none'}
      position="fixed"
      zIndex={999}
      alignItems="center"
      justifyContent="center"
      background="#ffffff"
      opacity={0.88}
    >
      <Spinner color="primary.500" size="xl" thickness="4px" speed="1s" emptyColor="gray.200" />
    </Box>
  );
}
