import {
  Button,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { FormEvent, useEffect, useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { HiDotsHorizontal } from 'react-icons/hi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { RevenueSliptType } from '..';
import FullPageLoading from '../../../../../components/FullPageLoading';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import ConfirmDeletionModal from '../ConfirmDeletionModal';
import EditPercentageModal from './EditPercentageModal';
import NewSplitModal from './NewSplitModal';

interface IPercentageAvailableByGroup {
  prolunoPercentage: string;
  availablePercentage: string;
}
export interface IPercentageAvailable {
  prolunoPercentage: string;
  availablePercentage: string;
}
interface IRevenueSplit {
  id: number;
  name: string;
  percentage: string;
  isOwner: boolean;
}

interface IRevenueSplitGroup {
  groupId: number;
  revenueType: RevenueSliptType;
  revenueSplit: IRevenueSplit[];
  courseId: number;
  courseName: string;
  recurrencePlanId: number;
  recurrencePlanName: string;
}

export default function EditSplit() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [splitId, setSplitId] = useState<number | null>(null);
  const [existingPercentage, setExistingPercentage] = useState<number>(0);
  const [splitIdToDelete, setSplitIdToDelete] = useState<number>(0);
  const [availablePercentage, setAvailablePercentage] = useState<string | null>(null);
  const [percentages, setPercentages] = useState({
    availablePercentage: '',
    prolunoPercentage: '',
  });

  const {
    isOpen: isOpenModalNewSplit,
    onOpen: onOpenModalNewSplit,
    onClose: onCloseModalNewSplit,
  } = useDisclosure();

  const {
    isOpen: isOpenModalEditPercentage,
    onOpen: onOpenModalEditPercentage,
    onClose: onCloseModalEditPercentage,
  } = useDisclosure();

  const {
    isOpen: isOpenModalDeleteSplit,
    onOpen: onOpenModalDeleteSplit,
    onClose: onCloseModalDeleteSplit,
  } = useDisclosure();

  const {
    data: revenueGroupData,
    loading: loadingRevenueGroup,
    fetchData: fetchRevenueGroup,
  } = useFetch<UnificadaFront.ResponseJSON<IRevenueSplitGroup>>({
    url: `/revenue-split/revenue-split-by-group/${id}`,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  const splitsGroup = revenueGroupData?.data;
  const split = revenueGroupData?.data?.revenueSplit;
  const splitType = splitsGroup?.revenueType;
  const isPole = splitType === 'POLE';
  const isRecurrence = splitType === 'RECURRENCE_DEFAULT';

  const {
    data: groupPercentageData,
    loading: loadingGroupPercentage,
    fetchData: fetchGroupPercentage,
  } = useFetch<UnificadaFront.ResponseJSON<IPercentageAvailableByGroup>>({
    url: `/revenue-split/percentage-available-by-group/${id}/revenue-split-group`,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  useEffect(() => {
    if (id) {
      fetchRevenueGroup();
      fetchGroupPercentage();
    }
  }, [fetchRevenueGroup, fetchGroupPercentage, id]);

  const { data: coProducersResponse, loading: isLoadingCoProducers } = useFetch<
    UnificadaFront.ResponseJSON<any>
  >({
    url: `/users/co-producers`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const coProducers = coProducersResponse?.data;
  const hasCoProducers = !isLoadingCoProducers && coProducers.length;

  const {
    data: coursePercentageData,
    loading: loadingCoursePercentage,
    fetchData: fetchCoursePercentage,
  } = useFetch<UnificadaFront.ResponseJSON<IPercentageAvailable>>({
    url: `/revenue-split/percentage-available-by-course/${splitsGroup?.courseId}/course?revenueType=DEFAULT`,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  useEffect(() => {
    if (splitsGroup?.courseId) {
      fetchCoursePercentage();
    }
  }, [splitsGroup?.courseId, fetchCoursePercentage]);

  const {
    data: planPercentageData,
    loading: loadingPlanPercentage,
    fetchData: fetchPlanPercentage,
  } = useFetch<UnificadaFront.ResponseJSON<IPercentageAvailable>>({
    url: `/revenue-split/percentage-available-by-recurrence-plan/${splitsGroup?.recurrencePlanId}/recurrence-plan?revenueType=RECURRENCE_DEFAULT`,
    method: 'get',
    autoFetch: false,
    authenticated: true,
  });

  useEffect(() => {
    if (splitsGroup?.recurrencePlanId) {
      fetchPlanPercentage();
    }
  }, [splitsGroup?.recurrencePlanId, fetchPlanPercentage]);

  useEffect(() => {
    const updatePercentages = (data: any) => {
      if (data?.data) {
        const availablePercentage = parseFloat(data.data.availablePercentage).toFixed(2);
        const prolunoPercentage = parseFloat(data.data.prolunoPercentage).toFixed(2);

        setPercentages({ availablePercentage, prolunoPercentage });
        setAvailablePercentage(availablePercentage);
      }
    };

    if (groupPercentageData?.data) {
      const newAvailablePercentage = (
        parseFloat(groupPercentageData.data.availablePercentage) + existingPercentage
      ).toFixed(2);

      setPercentages({
        availablePercentage: newAvailablePercentage,
        prolunoPercentage: parseFloat(groupPercentageData.data.prolunoPercentage).toFixed(2),
      });

      setAvailablePercentage(newAvailablePercentage);
    } else {
      updatePercentages(coursePercentageData);
      updatePercentages(planPercentageData);
    }
  }, [existingPercentage, groupPercentageData, coursePercentageData, planPercentageData]);

  const isLoading = loadingCoursePercentage || loadingPlanPercentage;

  async function onOpenConfirmDeletion(id: number) {
    setSplitIdToDelete(id);
    onOpenModalDeleteSplit();
  }

  const { isLoading: isSubmitting, handleSubmit: deleteSplitGroup } = useHandleSubmit({
    url: `/revenue-split/${splitIdToDelete}`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Compartilhamento excluído com sucesso!',
      callback() {
        fetchRevenueGroup();
        fetchGroupPercentage();
        setExistingPercentage(0);
        onCloseModalDeleteSplit();
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await deleteSplitGroup();
  }

  function goBack(event) {
    event.preventDefault();

    history.goBack();
  }

  return (
    <Stack>
      <Stack direction={{ base: 'column', md: 'row' }} width="full" justifyContent="space-between">
        <Link to="" onClick={goBack}>
          <AiOutlineLeft fontSize="20px" />
        </Link>

        <Heading width="full" fontWeight="600" fontSize={{ base: 'xl', md: '2xl' }}>
          {isRecurrence ? splitsGroup?.recurrencePlanName : splitsGroup?.courseName}
        </Heading>
      </Stack>

      <TableContainer>
        <Table size="lg" colorScheme="gray" width="full" variant="striped">
          <Thead>
            <Tr>
              <Th>Usuário</Th>
              <Th>Porcentagem</Th>
              <Th>Ações</Th>
            </Tr>
          </Thead>

          <Tbody>
            {split?.map(split => (
              <Tr key={split.id}>
                <Td fontWeight="400">{split?.name}</Td>
                <Td fontWeight="400">{parseFloat(split?.percentage).toFixed(2)}%</Td>
                <Td>
                  <Menu>
                    <MenuButton
                      hidden={split?.isOwner}
                      bgColor="#20212320"
                      p="2px"
                      borderRadius={5}
                      color="rgba(32, 33, 35, 0.5)"
                    >
                      <HiDotsHorizontal size="18px" />
                    </MenuButton>

                    <MenuList>
                      <MenuItem
                        fontWeight="500"
                        onClick={() => {
                          setSplitId(split?.id);
                          setExistingPercentage(parseFloat(split?.percentage));
                          onOpenModalEditPercentage();
                        }}
                      >
                        Editar
                      </MenuItem>

                      <MenuItem
                        hidden={isPole}
                        fontWeight="500"
                        color="red.500"
                        onClick={() => onOpenConfirmDeletion(split.id)}
                      >
                        Excluir
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Heading width="full" fontWeight="500" color="#20212350" fontSize="md" margin="5">
        * O restante da porcentagem pertence a Proluno.
      </Heading>

      <HStack width="full" direction="row" justifyContent="flex-end">
        <Button
          hidden={isPole}
          size="sm"
          colorScheme="primary"
          onClick={() => {
            setExistingPercentage(0);
            onOpenModalNewSplit();
          }}
        >
          + Adicionar usuário
        </Button>
      </HStack>

      <EditPercentageModal
        isOpen={isOpenModalEditPercentage}
        onClose={onCloseModalEditPercentage}
        splitId={splitId}
        fetchRevenueGroup={fetchRevenueGroup}
        availablePercentage={availablePercentage}
        setAvailablePercentage={setAvailablePercentage}
        percentages={percentages}
        fetchGroupPercentage={fetchGroupPercentage}
        loadingGroupPercentage={loadingGroupPercentage}
      />

      <NewSplitModal
        isOpen={isOpenModalNewSplit}
        onClose={onCloseModalNewSplit}
        fetchRevenueGroup={fetchRevenueGroup}
        fetchGroupPercentage={fetchGroupPercentage}
        splitType={splitType}
        hasCoProducers={hasCoProducers}
        coProducers={coProducers}
        percentages={percentages}
        setPercentages={setPercentages}
        isLoading={isLoading}
        availablePercentage={availablePercentage}
        setAvailablePercentage={setAvailablePercentage}
        courseId={splitsGroup?.courseId}
        recurrencePlanId={splitsGroup?.recurrencePlanId}
      />

      <ConfirmDeletionModal
        isOpen={isOpenModalDeleteSplit}
        onClose={onCloseModalDeleteSplit}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />

      <FullPageLoading isLoading={loadingRevenueGroup} />
    </Stack>
  );
}
