import { Box, Progress, Text, useBreakpointValue } from '@chakra-ui/react';

function ProgressBar({ progress, currentContent, total, highlightColor, showProgress }) {
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false });

  return (
    <Box width="full" hidden={!showProgress}>
      <Text fontSize="18px" color="#FFF" fontWeight="700" marginBottom={2} hidden={isMobile}>
        Progresso
      </Text>

      <Progress
        sx={{
          '> div': { backgroundColor: highlightColor ? highlightColor : 'primary.500' },
        }}
        value={progress}
        bgColor="#979CA6"
        size="12px"
        h="0.3rem"
        borderRadius="0.156rem"
      />

      <Box display="flex" justifyContent="space-between">
        <Text
          fontSize="12px"
          fontWeight="500"
          color={highlightColor ? highlightColor : 'primary.400'}
        >
          {progress}%
        </Text>

        <Text
          fontSize="12px"
          fontWeight="500"
          color={highlightColor ? highlightColor : 'primary.400'}
        >
          {currentContent}/{total} Conteúdos
        </Text>
      </Box>
    </Box>
  );
}

export default ProgressBar;
