import { Flex, Heading, Text, VStack } from '@chakra-ui/react';

export default function CheckoutSettingsHeader() {
  return (
    <VStack as="header" spacing={5}>
      <Flex justify="space-between" w="full" wrap="wrap">
        <Heading as="h1" fontSize={{ base: '2xl', xl: '4xl' }} fontWeight="semibold" m={0}>
          Gestão de checkout
        </Heading>

        <Text
          w="full"
          color="#20212380"
          fontSize={{ base: 'sm', xl: 'lg' }}
          mt={{ base: 2.5, xl: 5 }}
          lineHeight="1.25"
        >
          Gerencie e configure o seu checkout facilmente, garantindo uma experiência de compra mais
          fluida e agradável para seus clientes.
        </Text>
      </Flex>
    </VStack>
  );
}
