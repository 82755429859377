import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useRadioGroup,
  VStack,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import delay from '../../../../../../../utils/delay';
import { ShowcaseTypeRadioCard } from './ShowcaseTypeRadioCard';
import { OPTIONS } from './ShowcaseTypeRadioCard/constants';

interface SelectShowcaseTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreateDefaultPlanShowcaseModalOpen: () => void;
}

export function SelectPlanShowcaseTypeModal({
  isOpen,
  onClose,
  onCreateDefaultPlanShowcaseModalOpen,
}: SelectShowcaseTypeModalProps) {
  const { getRootProps, getRadioProps, value } = useRadioGroup({
    name: 'showcaseType',
    defaultValue: OPTIONS[0].value,
  });

  const { push } = useHistory();

  const group = getRootProps();

  async function handleClose(value: string) {
    onClose();

    await delay(500);

    if (value === 'default') {
      return onCreateDefaultPlanShowcaseModalOpen();
    }

    return push(`/members-area-management/netflix/showcase-higlight-type/new`);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
      <ModalOverlay />

      <ModalContent maxWidth={{ base: '348px', md: '612px' }}>
        <ModalHeader color="#202123" fontWeight="bold" fontSize="lg">
          Adicionar uma vitrine
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl>
            <FormLabel color="#0000007D" fontWeight="normal" fontSize="sm">
              Selecione o tipo de vitrine do tipo “Plano”.
            </FormLabel>

            <VStack {...group} spacing={4} align="flex-start">
              {OPTIONS.map(({ label, value, description }) => {
                const radio = getRadioProps({ value });

                return (
                  <ShowcaseTypeRadioCard key={value} {...radio} isChecked={radio.isChecked}>
                    <VStack align="flex-start" fontSize="sm" spacing={0}>
                      <Text as="strong" fontWeight="semibold">
                        {label}
                      </Text>

                      <Text color="gray.700">{description}</Text>
                    </VStack>
                  </ShowcaseTypeRadioCard>
                );
              })}
            </VStack>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            Cancelar
          </Button>

          <Button
            colorScheme="primary"
            color="secondary.500"
            onClick={() => handleClose(value.toString())}
          >
            Próximo
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
