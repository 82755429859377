import { Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FormEvent as MouseEvent } from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { useTheme } from '../../../../../contexts/ThemeContext';
import AlarmClockIcon from '../icons/AlarmClockIcon';
import DownArrowIcon from '../icons/DownArrowIcon';
import UpArrowIcon from '../icons/UpArrowIcon';

interface Filter {
  label: string;
  value: string;
  icon: JSX.Element;
}

interface FilterSelectProps {
  onChange: (value: string) => void;
  value: string;
}

const filtersList: Filter[] = [
  { label: 'Recentes', value: 'newer', icon: <AlarmClockIcon /> },
  { label: 'Ordem A-Z', value: 'ascAffiliates', icon: <UpArrowIcon /> },
  { label: 'Ordem Z-A', value: 'descAffiliates', icon: <DownArrowIcon /> },
  { label: 'Maior ganho', value: 'descWinnings', icon: <UpArrowIcon /> },
  { label: 'Menor ganho', value: 'ascWinnings', icon: <DownArrowIcon /> },
  { label: 'Maior venda', value: 'descSales', icon: <UpArrowIcon /> },
  { label: 'Menor venda', value: 'ascSales', icon: <DownArrowIcon /> },
];

export default function FilterSelect({ onChange, value }: FilterSelectProps) {
  const { themeMarketplace } = useTheme();

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    const { value } = event.currentTarget;
    onChange(value);
  }

  const primaryColor = themeMarketplace?.primaryColor;

  const activeFilter = filtersList.find(item => item.value === value);
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            pl={{ base: 3.5, md: 2 }}
            pr={2}
            py={2}
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="1px"
            color="#20212380"
            fontSize="sm"
            flexShrink={0}
            sx={{ svg: { mr: 1 } }}
            _expanded={{ borderColor: 'primary.500', boxShadow: `0 0 0 1px ${primaryColor}` }}
            _focus={{ borderColor: 'primary.500', boxShadow: `0 0 0 1px ${primaryColor}` }}
          >
            <Flex align="center" justify="space-between" gap={12}>
              <Flex align="center">
                {activeFilter?.icon}
                {activeFilter?.label}
              </Flex>
              {isOpen ? <IoChevronUp /> : <IoChevronDown />}
            </Flex>
          </MenuButton>

          <MenuList color="#20212380" sx={{ svg: { mr: 1, mt: '-2px' } }}>
            {filtersList.map(item => (
              <MenuItem key={item.value} value={item.value} onClick={handleClick} fontSize="xs">
                {item.icon} {item.label}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
}
