import { Button, Flex, Heading, VStack } from '@chakra-ui/react';
import { useHistory, useRouteMatch } from 'react-router-dom';

export default function PlanManagementHeader() {
  const { push } = useHistory();
  const { url } = useRouteMatch();

  return (
    <VStack as="header" spacing={5}>
      <Flex justify="space-between" w="full" wrap="wrap">
        <Heading as="h1" fontSize={{ base: '2xl', xl: '4xl' }} fontWeight="semibold" m={0}>
          Gestão de Planos
        </Heading>

        <Button
          onClick={() => push(`${url}/new`)}
          colorScheme="primary"
          color="secondary.500"
          size="sm"
        >
          Novo plano
        </Button>
      </Flex>
    </VStack>
  );
}
