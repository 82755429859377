import ReactApexChart from 'react-apexcharts';
import options from './options';
import styles from './styles.module.css';

function Chart({ rightQuestions, wrongQuestions }) {
  const series = [rightQuestions, wrongQuestions];

  return (
    <div className={styles.container}>
      <ReactApexChart options={options} series={series} type="donut" width="256" />
    </div>
  );
}

export default Chart;
