import { Input, InputProps } from '@chakra-ui/react';
import { useState } from 'react';
import InputMask from 'react-input-mask';

type DocumentInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (value: string) => void;
};

const CPF_MASK = '999.999.999-99';
const CNPJ_MASK = '99.999.999/9999-99';

function unmask(value: string) {
  return value.replace(/\D/g, '');
}

function getMask(value: string) {
  const unmaskedValue = unmask(value);
  const size = unmaskedValue.length;
  const shouldApplyCNPJMask = size > 11;
  return shouldApplyCNPJMask ? CNPJ_MASK : CPF_MASK;
}

export default function DocumentInput(props: DocumentInputProps) {
  const [mask, setMask] = useState(getMask((props.value as string) || ''));

  const { value, onChange, ...rest } = props;

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.value;
    const unmaskedValue = unmask(inputValue + event.key);
    const newMask = getMask(unmaskedValue);
    if (newMask !== mask) {
      setMask(newMask);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value;
    const unmaskedValue = unmask(inputValue);
    const newMask = getMask(unmaskedValue);
    if (newMask !== mask) {
      setMask(newMask);
    }
    onChange?.(unmaskedValue);
  }

  function handlePaste(event: React.ClipboardEvent<HTMLInputElement>) {
    const pasteValue = event.clipboardData.getData('Text');
    const unmaskedValue = unmask(pasteValue);
    const newMask = getMask(unmaskedValue);
    if (newMask !== mask) {
      setMask(newMask);
    }
  }

  return (
    <InputMask
      mask={mask}
      maskChar=""
      value={value}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onPaste={handlePaste}
      autoComplete="off"
    >
      {(inputProps: InputProps) => (
        <Input placeholder="Digite o CPF/CNPJ" {...rest} {...inputProps} />
      )}
    </InputMask>
  );
}
