import { useHistory } from 'react-router-dom';
import { MdSearch as SearchIcon } from 'react-icons/md';

import { useTheme } from '../../contexts/ThemeContext';
import { useSearch } from '../../contexts/SearchContext';
import { Flex, Input } from '@chakra-ui/react';
import { ChangeEvent } from 'react';

function SearchSite() {
  const { themeMarketplace } = useTheme();

  const { search, onSearchChange } = useSearch();
  const history = useHistory();

  function redirectToProductsPage() {
    if (!search) return;

    history.push(`/all-products?search=${search}`);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') redirectToProductsPage();
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onSearchChange(event.target.value);
  }

  return (
    <Flex
      align="center"
      margin="0 1.125rem"
      borderRadius="2rem"
      padding="0.348rem 1rem"
      flexGrow={1}
      background={themeMarketplace.textColor}
    >
      <SearchIcon
        size="1.8rem"
        color={themeMarketplace.primaryColor}
        style={{ cursor: search && 'pointer' }}
        onClick={redirectToProductsPage}
      />

      <Input
        value={search}
        onChange={handleChange}
        onKeyUp={handleKeyPress}
        placeholder="Pesquisar produto..."
        color={themeMarketplace.primaryColor}
        size="xs"
        fontSize="sm"
        border="none"
        _placeholder={{
          color: themeMarketplace.primaryColor,
        }}
        _focus={{
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        }}
      />
    </Flex>
  );
}

export default SearchSite;
