import { HStack, Skeleton, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { FormValidation } from '../../../../../../../hooks/useHandleSubmit';
import FilterType from '../../../types/Filter';
import Question from '../../../types/Question';
import { UploadQuestionPayload } from '../../../types/UploadQuestionPayload';
import Menu from './Menu';

interface FiltersMenuProps {
  isLoading: boolean;
  menuItems?: any;
  filters: FilterType[];
  formValidation?: FormValidation<UploadQuestionPayload>;
  question: Question;
  filtersList: any;
  onAddFilter: (filter: Partial<FilterType>) => void;
  onQuestionBeingUpdatedChange: (question: Partial<Question>) => void;
}

export default function FiltersMenu({
  isLoading,
  menuItems,
  formValidation,
  question,
  filtersList,
  onQuestionBeingUpdatedChange,
}: FiltersMenuProps) {
  const questionAreaId = question.questionLibraryArea?.id;
  const questionDisciplineId = question.questionLibraryDiscipline?.id;

  const filteredDisciplines = useMemo(() => {
    if (questionAreaId) {
      return filtersList?.disciplines?.filter(discipline => discipline.area.id === questionAreaId);
    }

    return filtersList?.disciplines;
  }, [filtersList?.disciplines, questionAreaId]);

  const filteredSubjects = useMemo(() => {
    if (questionAreaId) {
      return filtersList?.subjects?.filter(subject => subject.area.id === questionAreaId);
    }

    if (questionDisciplineId) {
      return filtersList?.subjects?.filter(
        subject => subject.discipline.id === questionDisciplineId
      );
    }

    return filtersList?.subjects;
  }, [filtersList?.subjects, questionAreaId, questionDisciplineId]);

  return (
    <Skeleton isLoaded={!isLoading}>
      <HStack spacing={0}>
        <HStack spacing={0} divider={<Text color="#AFAFAF">/</Text>}>
          <Menu
            name="area"
            label="Área"
            value={question.questionLibraryArea?.id}
            menuItems={menuItems?.areas}
            error={formValidation?.areaId?.message}
            onQuestionBeingUpdatedChange={onQuestionBeingUpdatedChange}
          />

          <Menu
            name="discipline"
            label="Disciplina"
            value={question.questionLibraryDiscipline?.id}
            menuItems={filteredDisciplines}
            error={formValidation?.disciplineId?.message}
            onQuestionBeingUpdatedChange={onQuestionBeingUpdatedChange}
          />

          <Menu
            name="subject"
            label="Assunto"
            value={question.questionLibrarySubject?.id}
            menuItems={filteredSubjects}
            error={formValidation?.subjectId?.message}
            onQuestionBeingUpdatedChange={onQuestionBeingUpdatedChange}
          />

          <Menu
            name="institution"
            label="Banca"
            value={question.questionLibraryInstitution?.id}
            menuItems={menuItems?.institutions}
            onQuestionBeingUpdatedChange={onQuestionBeingUpdatedChange}
          />
        </HStack>
      </HStack>
    </Skeleton>
  );
}
