import Joi from 'joi';
import { InstructorForm } from '../types';

export const createInstructorSchemaValidator = Joi.object<InstructorForm>().keys({
  photo: Joi.string(),
  fullName: Joi.string().required().messages({
    'string.empty': 'Nome não pode ficar vazio.',
    'any.required': 'Nome não pode ficar vazio.',
  }),
  email: Joi.string().email({ tlds: false }).required().messages({
    'string.email': 'Seu e-mail deve ser nesse formato “nome@email.com”.',
    'string.empty': 'O email não pode ficar vazio.',
    'any.required': 'O email não pode ficar vazio.',
  }),
  ddi: Joi.string()
    .required()
    .messages({
      'string.empty': 'O código de DDI não pode ficar vazio.',
    } as JoiMessages.LanguageMessages),
  ddd: Joi.when('ddi', {
    is: '55',
    then: Joi.string()
      .min(2)
      .max(5)
      .required()
      .messages({
        'string.empty': 'DDD não pode ficar vazio.',
        'string.min': 'DDD deve ter no mínimo 2 dígitos',
        'string.max': 'DDD deve ter no máximo 3 dígitos.',
      } as JoiMessages.LanguageMessages),
    otherwise: Joi.string().max(3).allow(null),
  }),
  phone: Joi.when('country', {
    is: 'br',
    then: Joi.string()
      .min(8)
      .max(20)
      .required()
      .messages({
        'string.empty': 'Seu telefone não pode ficar vazio.',
        'string.min': 'Seu telefone deve ter no mínimo 8 dígitos.',
        'string.max': 'Seu telefone deve ter no máximo 20 dígitos.',
      } as JoiMessages.LanguageMessages),
    otherwise: Joi.string()
      .max(20)
      .required()
      .messages({
        'string.empty': 'Seu telefone não pode ficar vazio.',
        'string.max': 'Seu telefone deve ter no máximo 20 dígitos.',
      } as JoiMessages.LanguageMessages),
  }),
  description: Joi.string(),
  coverBanner: Joi.string(),
  socialNetwork: Joi.array().items(
    Joi.object()
      .keys({
        type: Joi.string().valid('FACEBOOK', 'INSTAGRAM', 'YOUTUBE', 'LINKEDIN').required(),
        url: Joi.string().required().allow(''),
      })
      .required()
  ),
  disciplines: Joi.array().items(Joi.number().positive().required()),
});

export const updateInstructorSchemaValidator = Joi.object<InstructorForm>().keys({
  photo: Joi.string(),
  fullName: Joi.string().required().messages({
    'string.empty': 'Nome não pode ficar vazio.',
    'any.required': 'Nome não pode ficar vazio.',
  }),
  email: Joi.string().email({ tlds: false }).required().messages({
    'string.email': 'Seu e-mail deve ser nesse formato “nome@email.com”.',
    'string.empty': 'O email não pode ficar vazio.',
    'any.required': 'O email não pode ficar vazio.',
  }),
  ddi: Joi.string()
    .required()
    .messages({
      'string.empty': 'O código de DDI não pode ficar vazio.',
    } as JoiMessages.LanguageMessages),
  ddd: Joi.when('ddi', {
    is: '55',
    then: Joi.string()
      .min(2)
      .max(4)
      .required()
      .messages({
        'string.empty': 'DDD não pode ficar vazio.',
        'string.min': 'DDD deve ter no mínimo 2 dígitos',
        'string.max': 'DDD deve ter no máximo 3 dígitos.',
        'any.required': 'O email não pode ficar vazio.',
      } as JoiMessages.LanguageMessages),
    otherwise: Joi.string().max(3).allow(null),
  }),
  phone: Joi.when('ddi', {
    is: '55',
    then: Joi.string()
      .min(8)
      .max(20)
      .required()
      .messages({
        'string.empty': 'Seu telefone não pode ficar vazio.',
        'string.min': 'Seu telefone deve ter no mínimo 8 dígitos.',
        'string.max': 'Seu telefone deve ter no máximo 20 dígitos.',
      } as JoiMessages.LanguageMessages),
    otherwise: Joi.string()
      .max(20)
      .required()
      .messages({
        'string.empty': 'Seu telefone não pode ficar vazio.',
        'string.max': 'Seu telefone deve ter no máximo 20 dígitos.',
      } as JoiMessages.LanguageMessages),
  }),
  description: Joi.string(),
  coverBanner: Joi.string(),
  socialNetwork: Joi.array().items(
    Joi.object()
      .keys({
        type: Joi.string().valid('FACEBOOK', 'INSTAGRAM', 'YOUTUBE', 'LINKEDIN').required(),
        url: Joi.string().required().allow('').allow(null),
      })
      .required()
  ),
  disciplines: Joi.array().items(Joi.number().positive().required()),
});
