import { Button, ButtonGroup, ButtonProps } from '@chakra-ui/react';
import { MouseEvent } from 'react';

export type Filter = 'today' | 'week' | 'fortnight' | 'month';

interface FilterButtonsProps {
  value: Filter;
  onChange: (id: Filter) => void;
}

function getButtonProps(id: Filter, filter: Filter): ButtonProps {
  const isSelected = id === filter;

  return {
    variant: isSelected ? 'solid' : 'ghost',
    color: isSelected ? '#ffffff' : '#202123',
    pointerEvents: isSelected ? 'none' : 'auto',
    colorScheme: 'orange',
  };
}

export default function FilterButtons({ value, onChange }: FilterButtonsProps) {
  function handleFilterChange(event: MouseEvent) {
    const { id } = event.currentTarget;

    onChange(id as Filter);
  }

  return (
    <ButtonGroup
      sx={{
        button: {
          borderRadius: 'full',
          variant: 'ghost',
          variantColor: 'orange',
          outline: 'none',
          fontFamily: 'Montserrat',
          _focus: { boxShadow: 'none', outline: 'none' },
        },
      }}
    >
      <Button id="today" onClick={handleFilterChange} {...getButtonProps('today', value)}>
        Hoje
      </Button>
      <Button id="week" onClick={handleFilterChange} {...getButtonProps('week', value)}>
        7 dias
      </Button>
      <Button id="fortnight" onClick={handleFilterChange} {...getButtonProps('fortnight', value)}>
        15 dias
      </Button>
      <Button id="month" onClick={handleFilterChange} {...getButtonProps('month', value)}>
        30 dias
      </Button>
    </ButtonGroup>
  );
}
