import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import {
  NAME_LOCAL_STORAGE,
  PHOTO_LOCAL_STORAGE,
  ROLE_LOCAL_STORAGE,
  TOKEN_LOCAL_STORAGE,
} from '../../../helpers/LocalStorageHelper';

import { FormEvent, lazy, Suspense, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCountryByDDI, getDDI } from '../../../components/CountrySelect';
import onlyNumber from '../../../helpers/onlyNumber';
import useHandleChange from '../../../hooks/useHandleChange';
import useHandleSubmit from '../../../hooks/useHandleSubmit';
import useQuery from '../../../hooks/useQuery';
import { ResponseJSON } from '../../../utils/CreateToastAlert';
import scrollToInvalidInput from '../../../utils/scrollToInvalidInput';
import { FORM_STYLES, INPUT_STYLES, LINK_BUTTON_STYLES } from '../constants';
import PasswordInput from '../PasswordInput';
import schemaValidator, { RegisterSchemaType } from './schemaValidator';
const CountrySelect = lazy(() => import('../../../components/CountrySelect'));

function saveUserInfoToLocalStorage(response) {
  const authenticationData = response?.data?.data;

  const { fullName, token, role, photo } = authenticationData;

  localStorage.setItem(NAME_LOCAL_STORAGE, fullName);
  localStorage.setItem(TOKEN_LOCAL_STORAGE, token);
  localStorage.setItem(ROLE_LOCAL_STORAGE, role);
  localStorage.setItem(PHOTO_LOCAL_STORAGE, photo);
}

export default function Register() {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const redirectToPaymentPage = useCallback(async () => {
    urlSearchParams.set('added-to-cart-authentication', 'true');

    history.push({
      pathname: '/student-checkout/v3/payment',
      search: urlSearchParams.toString(),
    });
  }, [history, urlSearchParams]);

  const { form, handleChange, setForm } = useHandleChange<RegisterSchemaType>({
    fullName: query.get('name') ?? '',
    email: query.get('email') ?? '',
    password: '',
    country: 'br',
    ddi: query.get('ddi') ?? '55',
    ddd: query.get('ddd') ?? '',
    phone: query.get('phone') ?? '',
    isTermsAccepted: false,
  });

  const handleCountryChange = useCallback(
    (country: string) => {
      setForm(prevForm => ({
        ...prevForm,
        ddi: getDDI(country),
        ddd: null,
        country,
      }));
    },
    [setForm]
  );

  const { handleSubmit, formValidation, isLoading } = useHandleSubmit({
    data: form,
    schemaValidator: schemaValidator,
    method: 'post',
    url: '/users/v2/checkout',
    onSuccess: {
      callback(response) {
        redirectToPaymentPage();
        saveUserInfoToLocalStorage(response);
      },
    },
  });

  const isValidDDI = !!getCountryByDDI(form.ddi);

  const hasErrorInPhoneNumber =
    !isValidDDI ||
    formValidation?.ddi.isInvalid ||
    formValidation?.ddd?.isInvalid ||
    formValidation?.phone.isInvalid;

  function getPhoneNumberErrorMessage() {
    if (!isValidDDI) return 'DDI inválido';
    if (formValidation?.ddi.isInvalid) return formValidation?.ddi.message;
    if (formValidation?.ddd?.isInvalid) return formValidation?.ddd?.message;
    if (formValidation?.phone.isInvalid) return formValidation?.phone.message;
  }

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      scrollToInvalidInput();
      await handleSubmit<ResponseJSON<RegisterSchemaType>>();
    },
    [handleSubmit]
  );

  return (
    <Flex
      align="center"
      direction="column"
      w="full"
      as="form"
      onSubmit={onSubmit}
      autoComplete="off"
      noValidate
    >
      <Heading fontSize="lg" fontWeight="semibold">
        Cadastro
      </Heading>

      <VStack w="full" sx={FORM_STYLES} spacing={{ base: 8, xl: 10 }} mt={4}>
        <FormControl isInvalid={formValidation?.fullName.isInvalid}>
          <FormLabel>Nome</FormLabel>
          <Input
            name="fullName"
            value={form.fullName}
            onChange={handleChange}
            placeholder="Digite seu nome completo"
            autoComplete="new-name"
            maxLength={50}
            {...INPUT_STYLES}
          />
          <FormErrorMessage>{formValidation?.fullName.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.email.isInvalid}>
          <FormLabel>Email</FormLabel>
          <Input
            name="email"
            value={form.email}
            onChange={handleChange}
            placeholder="Digite seu melhor email"
            type="email"
            autoComplete="new-email"
            maxLength={256}
            {...INPUT_STYLES}
          />
          <FormErrorMessage>{formValidation?.email.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.password.isInvalid}>
          <FormLabel>Senha</FormLabel>
          <PasswordInput
            name="password"
            value={form.password}
            onChange={handleChange}
            placeholder="Cadastre uma senha"
            {...INPUT_STYLES}
          />

          {!formValidation?.password.isInvalid && form.password.length < 6 && (
            <FormHelperText color="#20212380">
              Sua senha deve ter no mínimo 6 caracteres.
            </FormHelperText>
          )}

          <FormErrorMessage>{formValidation?.password.message}</FormErrorMessage>
        </FormControl>

        <Stack direction={{ base: 'column', xl: 'row' }} w="full" spacing={5}>
          <FormControl>
            <FormLabel htmlFor="country">País</FormLabel>

            <Suspense fallback={<Skeleton w="full" h={10} />}>
              <CountrySelect value={getCountryByDDI(form?.ddi)} onChange={handleCountryChange} />
            </Suspense>
          </FormControl>

          <Stack w="full">
            <FormLabel htmlFor="ddd" mb={0}>
              Telefone
            </FormLabel>

            <VStack w="full" align="start">
              <HStack w="full">
                <FormControl hidden={form.ddi !== '55'} maxW="4.5rem">
                  <Input
                    name="ddd"
                    value={form.ddd}
                    onChange={handleChange}
                    placeholder="DDD"
                    onInput={onlyNumber}
                    maxLength={2}
                    type="tel"
                    {...INPUT_STYLES}
                  />
                </FormControl>

                <FormControl>
                  <Input
                    name="phone"
                    type="tel"
                    placeholder="Digite seu telefone"
                    value={form.phone}
                    onChange={handleChange}
                    onInput={onlyNumber}
                    maxLength={20}
                    {...INPUT_STYLES}
                  />
                </FormControl>
              </HStack>

              {hasErrorInPhoneNumber && (
                <Box aria-live="polite" className="chakra-form__error-message">
                  {getPhoneNumberErrorMessage()}
                </Box>
              )}
            </VStack>
          </Stack>
        </Stack>

        <HStack justify="center">
          <FormControl isInvalid={formValidation?.isTermsAccepted?.isInvalid}>
            <HStack align="center">
              <Checkbox
                name="isTermsAccepted"
                onChange={handleChange}
                checked={form.isTermsAccepted}
                colorScheme="orange"
                borderColor="gray.300"
              />

              <FormLabel fontSize="sm" margin={0} color="#20212380 !important">
                Li e aceito os{' '}
                <Link
                  href="https://checkout.proluno.me/privacy-policy"
                  isExternal
                  textDecoration="underline"
                  fontWeight="semibold"
                  color="default.500"
                >
                  termos de uso
                </Link>
              </FormLabel>
            </HStack>

            <FormErrorMessage>{formValidation?.isTermsAccepted?.message}</FormErrorMessage>
          </FormControl>
        </HStack>
      </VStack>

      <VStack w="full" spacing={5} mt={8}>
        <Button
          type="submit"
          colorScheme="orange"
          bg="#EC7117"
          px="4.5rem"
          fontSize="md"
          isLoading={isLoading}
          fontWeight="semibold"
        >
          Realizar cadastro
        </Button>

        <VStack spacing={0}>
          <Text color="#202123" fontWeight="medium">
            Já possui cadastro?
          </Text>

          <Button
            to={`/student-checkout/v3/login${window.location.search}`}
            colorScheme="orange"
            color="#eb7129"
            isDisabled={isLoading}
            {...LINK_BUTTON_STYLES}
          >
            Fazer login
          </Button>
        </VStack>
      </VStack>
    </Flex>
  );
}
