import { Avatar, Card, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { MdOutlinePermIdentity } from 'react-icons/md';
import { CurrentLeadType } from '..';

interface UserCardProps {
  lead: CurrentLeadType;
  totalConversions: number;
}

export default function LeadCardDesktop({ lead, totalConversions }: UserCardProps) {
  return (
    <Card width="full" boxShadow="0px 4px 4px 0px #00000040">
      <HStack spacing="0" width="full" justifyContent="space-between" alignItems="self-start">
        <HStack spacing="18px">
          <Avatar
            width="118px"
            height="118px"
            borderRadius="6.92px"
            background="#C4C4C4"
            color="#20212380"
            icon={<MdOutlinePermIdentity fontSize="36px" />}
            src={lead?.photo}
          />

          <VStack align="start" my="10px">
            <Heading fontSize="30px" fontWeight="600" lineHeight="36.57px">
              {lead?.fullName}
            </Heading>

            <VStack align="start" spacing="0">
              <HStack fontSize="14px">
                <Text fontWeight="600">Email:</Text>
                <Text fontWeight="400">{lead?.email}</Text>
              </HStack>

              <HStack fontSize="14px">
                <Text fontWeight="600">Telefone:</Text>
                <Text fontWeight="400">{lead?.phone}</Text>
              </HStack>
            </VStack>
          </VStack>
        </HStack>

        <VStack align="end" padding="18px" fontWeight="500" spacing="0">
          <Text fontSize="24px" lineHeight="29.26px" textAlign="right" color="default.500">
            {totalConversions}
          </Text>
          <Text fontSize="11px" color="#202123" lineHeight="13.41px" textAlign="right">
            Conversões
          </Text>
        </VStack>
      </HStack>
    </Card>
  );
}
