import { Circle, Icon as ChakraIcon } from '@chakra-ui/react';
import { NotificationType } from '..';
import { ReactComponent as TaskIcon } from '../../../../assets/icons/activityIcon.svg';
import { ReactComponent as ProlunoIcon } from '../../../../assets/icons/proluno-p-icon.svg';

import {
  MdNewReleases as FeatureIcon,
  MdOutlineAttachMoney as SaleIcon,
  MdOutlineGroupAdd as LeadIcon,
} from 'react-icons/md';

interface IconProps {
  type?: NotificationType;
}

function getIcon(type: NotificationType) {
  return (
    {
      feature: FeatureIcon,
      sale: SaleIcon,
      lead: LeadIcon,
      other: FeatureIcon,
      task: () => <TaskIcon />,
    }[type] || ProlunoIcon
  );
}

export default function NotificationIcon({ type }: IconProps) {
  return (
    <Circle size={{ base: 8, md: 10 }} bg="primary.500" sx={{ svg: { fill: 'secondary.500' } }}>
      <ChakraIcon as={getIcon(type)} boxSize={{ base: 4, md: 6 }} />
    </Circle>
  );
}
