import { Dropdown } from 'react-bootstrap';
import { MdMoreVert } from 'react-icons/md';
import styles from './styles.module.css';

interface IDropdownSectionProps {
  isDisabled: boolean;
  onClickToDuplicate: () => void;
  onClickToDelete: () => void;
}

function DropdownSection({ isDisabled, onClickToDuplicate, onClickToDelete }: IDropdownSectionProps) {
  return (
    <Dropdown style={{ outline: 0 }} className={styles.dropdown}>
      <Dropdown.Toggle variant="transparent" className={styles.dropdownToggle}>
        <MdMoreVert size="1.5rem" />
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ marginTop: '-2rem' }}>
        <Dropdown.Item onClick={onClickToDuplicate} disabled={isDisabled}>Duplicar seção</Dropdown.Item>

        <Dropdown.Item onClick={onClickToDelete} style={{ color: '#bb2124' }} disabled={isDisabled}>
          Excluir seção
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownSection;
