import { Box, Button, HStack, Image, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { MdOutlineShoppingCart } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ITransaction } from '../..';
import { IS_REACT_APP_HOSTNAME } from '../../../../../../App';
import convert from '../../../../../../helpers/convertToBrazilianCurrency';
import formatCPF from '../../../../../../helpers/MaskCpf';
import { translatePurchaseStatus } from '../../../helpers';
import recurrenceIcon from '../../../../../../assets/icons/recurrenceIcon.svg';

interface IProps {
  transactions: ITransaction[];
  modalReleaseAccess: (transactionId: number) => void;
}

function translatePaymentMethod(transaction) {
  if (transaction === 'credit_card') {
    return 'Cartão de Crédito';
  }

  if (transaction === 'boleto') {
    return 'Boleto';
  }

  if (transaction === 'pix') {
    return 'Pix';
  }

  if (transaction === 'free') {
    return 'Grátis';
  }

  if (transaction === 'external') {
    return 'Externo';
  }
}

const TH_STYLE = {
  color: '#2D3748',
  fontWeight: '400',
  whiteSpace: 'pre-wrap',
  padding: '0 2px',
  fontSize: '14px',
};

function getStatusColor(status) {
  switch (status) {
    case 'waiting_payment':
      return '#EDCA32';
    case 'paid':
      return '#2B3';
    case 'refused':
      return '#BB2124';
    case 'refunded':
      return '#2D3748';
    case 'chargedback':
      return '#202123	';
    case 'manual':
      return '#464688	';
    default:
      return '#778899	';
  }
}

function transactionId(transaction) {
  if (
    !transaction.transactionId &&
    !transaction.purchasePrice &&
    transaction.paymentMethod !== 'external'
  ) {
    return 'Grátis';
  } else if (transaction.transactionId && transaction.purchasePrice) {
    return transaction.transactionId;
  }

  return '-';
}

export default function TransactionItem({ transactions, modalReleaseAccess }: IProps) {
  const hostname = window.location.hostname;

  return (
    <Tbody>
      {transactions?.map((transaction, index) => (
        <Tr key={index}>
          <Td>
            <Box>
              {transaction.recurrencePlan ? (
                <Image src={recurrenceIcon} />
              ) : (
                <MdOutlineShoppingCart size="22px" color="#202123" />
              )}
            </Box>
          </Td>

          <Td sx={TH_STYLE}>
            <Text fontSize="12px" fontWeight="600">
              {transactionId(transaction) === 'Grátis' || transactionId(transaction) === '-' ? (
                transactionId(transaction)
              ) : (
                <Link to={`financial/${transaction.id}`}>
                  <Text color="#759aed" textDecoration="underline" whiteSpace="pre-wrap">
                    #{transaction.transactionId}
                  </Text>
                </Link>
              )}
            </Text>
          </Td>

          <Td
            title={transaction.products.map(item => item.product).join(', ')}
            hidden={!!transaction.recurrencePlan}
            sx={TH_STYLE}
          >
            <Text width="130px">
              {transaction.products?.length > 1
                ? transaction.products[0]?.product.concat(
                    ' + ',
                    (transaction.products?.length - 1).toString() +
                      `${transaction.products?.length > 2 ? ' itens' : ' item'}`
                  )
                : transaction.products[0]?.product}
            </Text>
          </Td>

          <Td title={transaction.recurrencePlan} hidden={!transaction.recurrencePlan} sx={TH_STYLE}>
            <HStack>
              <Text width="130px">{transaction.recurrencePlan && transaction.recurrencePlan}</Text>
            </HStack>
          </Td>

          <Td sx={TH_STYLE}>
            <Text width="80px" fontSize="12px">
              {dayjs(transaction.createdAt, { utc: true }).format('DD/MM/YYYY HH:mm')}
            </Text>
          </Td>
          <Td sx={TH_STYLE}>
            <Text fontWeight="500" width="120px">
              {transaction.buyer.fullName}
            </Text>
            <Text fontSize="12px"> {formatCPF(transaction.buyer.documentNumber)}</Text>
          </Td>

          <Td sx={TH_STYLE}>
            <Text width="82px" fontSize="12px">
              {transaction.buyer.email}
            </Text>
          </Td>

          <Td sx={TH_STYLE}>
            <Text width="65px" fontSize="12px">
              {translatePaymentMethod(transaction.paymentMethod)}
            </Text>
          </Td>

          <Td sx={TH_STYLE}>
            <Text
              fontWeight="400"
              fontSize="12px"
              color={getStatusColor(transaction.purchaseStatus)}
              width="82px"
            >
              {translatePurchaseStatus(transaction.purchaseStatus)}
            </Text>
          </Td>

          <Td sx={TH_STYLE}>
            <Text width="90px" fontWeight="400">
              {transaction.purchasePrice ? convert(transaction.purchasePrice) : '-'}
            </Text>
          </Td>

          {!IS_REACT_APP_HOSTNAME(hostname) ||
          transaction.purchaseStatus === 'paid' ||
          transaction.purchaseStatus !== 'waiting_payment' ? (
            <></>
          ) : (
            <Td sx={TH_STYLE}>
              <Button
                variant="outline"
                size="sm"
                onClick={() => modalReleaseAccess(transaction.id)}
              >
                <Text>Liberar acesso</Text>
              </Button>
            </Td>
          )}
        </Tr>
      ))}
    </Tbody>
  );
}
