import {
  NAME_LOCAL_STORAGE,
  PHOTO_LOCAL_STORAGE,
  ROLE_LOCAL_STORAGE,
  TOKEN_LOCAL_STORAGE,
  TOKEN_PURCHASE,
} from '../helpers/LocalStorageHelper';

import { axiosBackend } from './Configuration';

const UserAPI = {
  student: {
    store: async studentData => {
      try {
        const { data: response } = await axiosBackend().post('/users/students', studentData);

        return response;
      } catch (error) {
        throw error;
      }
    },
  },

  coProducers: {
    index: async () => {
      try {
        const { data: userData } = await axiosBackend().get('/users/co-producers');

        return userData;
      } catch (error) {
        throw error;
      }
    },
  },

  store: async (userData, free) => {
    try {
      const { data: response } = await axiosBackend().post('/users', userData, {
        params: free ? { free: 'true' } : {},
      });

      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  },

  show: async () => {
    try {
      const { data: response } = await axiosBackend().get('/users');

      return response;
    } catch (error) {
      throw error;
    }
  },
  findInstructorsToModule: async () => {
    try {
      const { data: response } = await axiosBackend().get('/users/module/instructors');

      return response;
    } catch (error) {
      throw error;
    }
  },

  indexInstructors: async () => {
    try {
      const { data: response } = await axiosBackend().get('/users/instructors');

      return response;
    } catch (error) {
      throw error;
    }
  },

  updateProfile: async newContent => {
    try {
      const { data: updateProfileData } = await axiosBackend().patch('/users', newContent);

      return updateProfileData;
    } catch (error) {
      throw error;
    }
  },

  storeBankAccount: async bankAccountData => {
    try {
      delete bankAccountData.id;

      if (!bankAccountData.agenciaDv) delete bankAccountData.agenciaDv;

      const { data: bankData } = await axiosBackend().post('/users/bank-accounts', bankAccountData);

      return bankData;
    } catch (error) {
      throw error;
    }
  },

  showBankAccount: async () => {
    try {
      const { data: bankData } = await axiosBackend().get('/users/bank-accounts');

      return bankData;
    } catch (error) {
      throw error;
    }
  },

  createUserByCheckoutV2: async data => {
    delete data.neighborhood;

    try {
      const {
        data: { data: response },
      } = await axiosBackend().post('/users/v2/checkout', data);

      localStorage.setItem(NAME_LOCAL_STORAGE, response.fullName);
      localStorage.setItem(PHOTO_LOCAL_STORAGE, response.photo);
      localStorage.setItem(TOKEN_LOCAL_STORAGE, response.token);
      localStorage.setItem(ROLE_LOCAL_STORAGE, response.roles);
    } catch (error) {
      throw error;
    }
  },

  showAvailableBalance: async () => {
    try {
      const { data: balanceData } = await axiosBackend().get('/report/balance-and-future-releases');

      return balanceData;
    } catch (error) {
      throw error;
    }
  },

  newStudent: async payload => {
    try {
      const { data: newUserData } = await axiosBackend().post(
        '/users/student-base/new-student',
        payload
      );

      return newUserData;
    } catch (error) {
      throw error;
    }
  },

  responsibleProfiles: {
    all: async () => {
      try {
        const { data: instructorsData } = await axiosBackend().get(
          `/users/responsible-profiles?page=1&per_page=100`
        );

        return instructorsData;
      } catch (error) {
        throw error;
      }
    },
    show: async id => {
      try {
        const { data: instructorData } = await axiosBackend().get(`/users/${id}/instructor`);

        return instructorData;
      } catch (error) {
        throw error;
      }
    },
  },

  creditCard: async () => {
    try {
      const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
      const tokenForPurchase = localStorage.getItem(TOKEN_PURCHASE);

      const { data: creditCardData } = await axiosBackend().get(`/purchases/cards/user`, {
        headers: { Authorization: `Bearer ${token || tokenForPurchase}` },
      });

      return creditCardData;
    } catch (error) {
      throw error;
    }
  },

  changeStatusInstructor: async instructorId => {
    const changeStatusData = await axiosBackend().patch(`/users/${instructorId}/change-status`);

    return changeStatusData;
  },
};

export default UserAPI;
