import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, DrawerBody, DrawerHeader, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useFilters, { Discipline, Subject } from '../../../useFilters';
import FiltersList from './FiltersList';
import FilterListItem from './FiltersList/FilterListItem';

interface ProductsMenuProps {
  onChangeToNavMenuTab: () => void;
  onToggle: () => void;
}
interface FilterParams {
  area?: number;
  discipline?: number;
  subject?: number;
}

export default function ProductsMenu({ onChangeToNavMenuTab, onToggle }: ProductsMenuProps) {
  const [tabIndex, setTabIndex] = useState(0);

  const history = useHistory();

  const {
    filtersList,
    disciplinesOptions,
    subjectsOptions,
    isFetching,
    handleAreaChange,
    handleDisciplineChange,
  } = useFilters();

  useEffect(() => {
    const hasSomeFilter = Object.values(filtersList).some(filter => Boolean(filter));

    if (!isFetching && !hasSomeFilter) {
      history.push('/all-products');
    }
  }, [filtersList, history, isFetching]);

  function handlePreviousTab() {
    setTabIndex(currentTab => currentTab - 1);
  }

  function handleRedirectToProductsPage(params: FilterParams) {
    const filterParams = { ...params };
    const queryParams = new URLSearchParams();

    for (const [key, value] of Object.entries(filterParams)) {
      if (value) {
        queryParams.append(`${key}`, `["${value}"]`);
      }
    }

    onToggle();

    history.push({
      pathname: '/all-products',
      search: `?${queryParams.toString()}`,
    });
  }

  function handleAreaClick(areaId: number) {
    handleAreaChange(areaId);

    if (!disciplinesOptions.length) {
      handleRedirectToProductsPage({ area: areaId });
    } else {
      setTabIndex(1);
    }
  }

  function handleDisciplineClick(discipline: Discipline) {
    handleDisciplineChange(discipline.id);

    const hasRelatedSubjects = subjectsOptions.some(
      subject => subject.discipline.id === discipline.id
    );

    if (!hasRelatedSubjects) {
      handleRedirectToProductsPage({ area: discipline.area.id, discipline: discipline.id });
    } else {
      setTabIndex(2);
    }
  }

  function handleSubjectClick(subject: Subject) {
    handleRedirectToProductsPage({
      area: subject.area.id,
      discipline: subject.discipline.id,
      subject: subject.id,
    });

    setTabIndex(0);
  }

  return (
    <Tabs index={tabIndex}>
      <TabPanels sx={{ '[role=tabpanel]': { p: 0 } }}>
        <TabPanel>
          <DrawerHeader p={0}>
            <Button
              onClick={onChangeToNavMenuTab}
              size="sm"
              color="#20212380"
              w="full"
              border="none"
              borderRadius={0}
              fontWeight="medium"
              leftIcon={<ChevronLeftIcon />}
              p={6}
              pl={4}
              justifyContent="start"
            >
              Menu
            </Button>
          </DrawerHeader>

          <DrawerBody bg="#ffffff" px={4} pt={2.5}>
            <FiltersList title="Áreas">
              {filtersList.areas?.map(area => (
                <FilterListItem
                  key={area.id}
                  filterId={area.id}
                  name={area.name}
                  onClick={() => handleAreaClick(area.id)}
                />
              ))}
            </FiltersList>
          </DrawerBody>
        </TabPanel>

        <TabPanel>
          <DrawerHeader p={0}>
            <Button
              onClick={handlePreviousTab}
              size="sm"
              color="#20212380"
              w="full"
              border="none"
              borderRadius={0}
              fontWeight="medium"
              leftIcon={<ChevronLeftIcon />}
              p={6}
              pl={4}
              justifyContent="start"
            >
              Áreas
            </Button>
          </DrawerHeader>

          <DrawerBody bg="#ffffff" px={4} pt={2.5}>
            <FiltersList title="Disciplinas">
              {disciplinesOptions?.map(discipline => (
                <FilterListItem
                  key={discipline.id}
                  filterId={discipline.id}
                  name={discipline.name}
                  onClick={() => handleDisciplineClick(discipline)}
                />
              ))}
            </FiltersList>
          </DrawerBody>
        </TabPanel>

        <TabPanel>
          <DrawerHeader p={0}>
            <Button
              onClick={handlePreviousTab}
              size="sm"
              color="#20212380"
              w="full"
              border="none"
              borderRadius={0}
              fontWeight="medium"
              leftIcon={<ChevronLeftIcon />}
              p={6}
              pl={4}
              justifyContent="start"
            >
              Disciplinas
            </Button>
          </DrawerHeader>

          <DrawerBody bg="#ffffff" px={4} pt={2.5}>
            <FiltersList title="Assuntos">
              {subjectsOptions?.map(subject => (
                <FilterListItem
                  key={subject.id}
                  filterId={subject.id}
                  name={subject.name}
                  onClick={() => handleSubjectClick(subject)}
                  hasIcon={false}
                />
              ))}
            </FiltersList>
          </DrawerBody>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
