import { Box, Button, Heading, Stack, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import QuestionsAPI from '../../../../../../api/Question';
import MaximumAmountReachedRedoContent from '../../../../../../components/MaximumAmountReachedRedoContent';
import RedoContent from '../../../../../../components/RedoContent';
import Toast from '../../../../../../components/Toast';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import getRouteToShow from '../../../../../../helpers/getRouteToShow';
import { toFixedPercentage } from '../../../../../DashboardAdmin/Courses/CourseManager/Reporting/utils';
import Card from './Card';
import Result from './Result';
import Table from './Table';

interface IReport {
  contentTitle: string;
  contentType: string;
  courseId: number | string;
  moduleId: number | string;
  contentId: number | string;
}

interface IStatistics {
  rightQuestions: number;
  wrongQuestions: number;
  studentUseStatistics: number;
}

function Report({ contentTitle, courseId, moduleId, contentId, contentType }: IReport) {
  const [statistics, setStatistics] = useState<IStatistics | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfTimesRemaining, setNumberOfTimesRemaining] = useState(0);

  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [isHiddenButton, setIsHiddenButton] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDisabledButton,
    onOpen: onOpenDisabledButton,
    onClose: onCloseDisabledButton,
  } = useDisclosure();

  async function startSurvey() {
    try {
      setIsLoading(true);

      const response = await QuestionsAPI.student.start(courseId, moduleId, contentId);

      if (response) {
        window.location.href = `/members-area/v1/products/${courseId}/content-view/module/${moduleId}/content/${contentId}/${getRouteToShow(
          contentType
        )}`;
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await QuestionsAPI.student.verifyIsFinished(courseId, moduleId, contentId);

        const { finished, numberOfTimesRemaining, numberOfTimesRegistrations } = response?.data;

        setNumberOfTimesRemaining(numberOfTimesRemaining);

        if (numberOfTimesRemaining === 0) {
          setIsDisabledButton(true);
        }

        if (numberOfTimesRegistrations === 0) {
          setIsHiddenButton(true);
        }

        if (finished === true) {
          const statistics = await QuestionsAPI.student.getStatistics(
            courseId,
            moduleId,
            contentId
          );

          setStatistics(statistics);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [contentId, courseId, moduleId]);

  return (
    <>
      {!isLoading && (
        <Box width="full">
          <Stack
            direction={{ base: 'column', md: 'row', lg: 'row' }}
            alignItems="center"
            justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
            margin={5}
          >
            <Heading fontWeight="medium" fontSize="2xl" maxWidth={400} isTruncated>
              {contentTitle}
            </Heading>

            <Button
              onClick={isDisabledButton ? onOpenDisabledButton : onOpen}
              colorScheme={isDisabledButton ? 'blackAlpha' : 'orange'}
              size="sm"
              width={{ base: 'full', md: 'auto', lg: 'auto' }}
              hidden={isHiddenButton}
            >
              Refazer conteúdo
            </Button>
          </Stack>

          <Box
            mx={{ base: '5', md: '3', lg: '3' }}
            display="flex"
            justifyContent="space-around"
            flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
            textAlign={{ base: 'center', md: 'left', lg: 'left' }}
          >
            <Card
              title="Questões certas"
              data={statistics?.rightQuestions}
              color="#22BB33"
              gridArea="correct"
            />

            <Card
              title="Questões erradas"
              data={statistics?.wrongQuestions}
              color="#BB2124BF"
              gridArea="incorrect"
            />

            <Card
              title="Aproveitamento"
              data={`${toFixedPercentage(statistics?.studentUseStatistics)}%`}
              color="#759AEDBF"
              gridArea="successRate"
            />
          </Box>
          <Box display="flex" mx={5} flexDirection={{ base: 'column', md: 'row', lg: 'row' }}>
            <Box width="full" mr={{ base: '0', md: '5', lg: '5' }} mb={5}>
              <Result
                rightQuestions={statistics?.rightQuestions}
                wrongQuestions={statistics?.wrongQuestions}
                studentUseStatistics={statistics?.studentUseStatistics}
              />
            </Box>
            <Box width="full">
              <Table courseId={courseId} moduleId={moduleId} contentId={contentId} />
            </Box>
          </Box>
        </Box>
      )}

      <RedoContent
        isOpen={isOpen}
        onClose={onClose}
        startSurvey={startSurvey}
        isLoading={isLoading}
        numberOfTimesRemaining={numberOfTimesRemaining}
      />

      <MaximumAmountReachedRedoContent
        isOpen={isOpenDisabledButton}
        onClose={onCloseDisabledButton}
      />
    </>
  );
}

export default Report;
