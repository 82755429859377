import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 10px;
  background: #2021231a;

  header {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    color: #202123;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      font-size: 16px;
      font-weight: 400;
      color: inherit;

      b {
        margin-left: 16px;
        color: #eb7129;
      }
    }
  }

  @media screen and (max-width: 576px) {
    margin-top: 8px;
  }
`;

export const Main = styled.div`
  margin: 16px 0;

  > div {
    font-size: 16px;
    font-weight: 400;
    color: #202123;

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;

      > b {
        color: #eb7129;
      }
    }

    &:last-child {
      margin: 16px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: 860px) {
    > div {
      margin: 4px 0;
      flex-direction: column;
      align-items: start;
      font-size: 14px;
    }
  }
`;

export const Group = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-left: 8px;
  }

  @media screen and (max-width: 860px) {
    flex-direction: column;
    margin-bottom: 4px;
    > span {
      margin-left: 0;
    }
  }
`;

export const Space = styled.div`
  margin-left: 16px;
`;
