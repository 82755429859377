import { Box, Divider, Stack, Text, useColorModeValue } from '@chakra-ui/react';

export default function Lines({ hiddenNumbers = true, hiddenBar = true }) {
  const textColor = useColorModeValue('#202123', '#FFFFFF');

  return (
    <>
      <Stack
        hidden={hiddenBar}
        width="full"
        direction="row"
        alignItems="center"
        height="16px"
        borderRadius={5}
        spacing={0}
        paddingRight={{ base: '10px', md: '25px', lg: '25px' }}
      >
        <Text
          width={{ base: '356px', md: '413px', lg: '413px' }}
          padding={{ base: '10px', md: '10px 30px', lg: '10px 30px' }}
        />

        <Box width="full" display="flex" justifyContent="space-between">
          <Stack alignItems="center" spacing={0}>
            <Divider
              orientation="vertical"
              width="1px"
              height="15px"
              bgColor="#202123"
              margin={0}
            />
          </Stack>
          <Stack alignItems="center" spacing={0}>
            <Divider orientation="vertical" width="1px" height="15px" bgColor="#CCC" margin={0} />
          </Stack>
          <Stack alignItems="center" spacing={0}>
            <Divider orientation="vertical" width="1px" height="15px" bgColor="#CCC" margin={0} />
          </Stack>
          <Stack alignItems="center" spacing={0}>
            <Divider orientation="vertical" width="1px" height="15px" bgColor="#CCC" margin={0} />
          </Stack>
          <Stack alignItems="center" spacing={0}>
            <Divider orientation="vertical" width="1px" height="15px" bgColor="#CCC" margin={0} />
          </Stack>
        </Box>
      </Stack>
      <Stack
        width="full"
        direction="row"
        alignItems="center"
        height="15px"
        borderRadius={5}
        spacing={0}
        hidden={hiddenNumbers}
      >
        <Text
          width={{ base: '336px', md: '400px', lg: '400px' }}
          padding={{ base: '10px', md: '10px 30px', lg: '10px 30px' }}
        />

        <Box width="full" display="flex" justifyContent="space-between">
          <Text
            color={textColor}
            fontSize={{ base: '12px', md: '16px', lg: '16px' }}
            fontWeight="500"
          >
            0
          </Text>
          <Text
            color={textColor}
            fontSize={{ base: '12px', md: '16px', lg: '16px' }}
            fontWeight="500"
          >
            25%
          </Text>
          <Text
            color={textColor}
            fontSize={{ base: '12px', md: '16px', lg: '16px' }}
            fontWeight="500"
          >
            50%
          </Text>
          <Text
            color={textColor}
            fontSize={{ base: '12px', md: '16px', lg: '16px' }}
            fontWeight="500"
          >
            75%
          </Text>
          <Text
            color={textColor}
            fontSize={{ base: '12px', md: '16px', lg: '16px' }}
            fontWeight="500"
          >
            100%
          </Text>
        </Box>
      </Stack>
    </>
  );
}
