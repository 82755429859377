import stylesCard from './styles.module.css';

interface ICardItemProps {
  content: string | React.ReactElement;
  image: string;
}

function CardItem({ content, image }: ICardItemProps) {
  return (
    <div className={stylesCard.cardContainer}>
      <div className={stylesCard.cardInner}>
        <div className={stylesCard.cardHeader}>
          <h4 className={stylesCard.title}>{content}</h4>
        </div>
      </div>
      <div className={stylesCard.cardBody}>
        <img src={image} alt="" className="w-min-100 img-card img-fluid" />
      </div>
    </div>
  );
}

export default CardItem;
