import {
  Button,
  HStack,
  Icon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IoMdCalendar as CalendarIcon } from 'react-icons/io';
import { IoChevronDown as ChevronDownIcon, IoChevronUp as ChevronUpIcon } from 'react-icons/io5';
import { MdSwapVert as OrdinationIcon, MdOutlineBallot as TypeIcon } from 'react-icons/md';
import { PreviewFilterOption } from '../FiltersDropDown';

interface PreviewFilterProps {
  name: 'calendar' | 'viewOrder' | 'viewType';
  value: string;
  label?: string;
  options?: PreviewFilterOption[];
  offset?: [number, number];
  onChange?: (previewFilterOption: PreviewFilterOption) => void;
}

function getIcon(icon: PreviewFilterProps['name']) {
  const icons = {
    calendar: CalendarIcon,
    viewOrder: OrdinationIcon,
    viewType: TypeIcon,
  };

  return icons[icon];
}

function getCurrentFilterLabel(value, options) {
  return options.find(option => option.value === value)?.label || 'Selecionar';
}

export default function PreviewFilter({
  name,
  value,
  label,
  options,
  offset,
  onChange,
}: PreviewFilterProps) {
  const [currentFilterLabel, setCurrentFilterLabel] = useState(
    getCurrentFilterLabel(value, options)
  );

  useEffect(() => {
    if (!value) {
      setCurrentFilterLabel('Selecionar');
    }
  }, [value]);

  function handleSelectItem(option: PreviewFilterOption) {
    setCurrentFilterLabel(option.label);
    onChange(option);
  }

  return (
    <ListItem>
      <HStack justify="space-between">
        <HStack color="#AFAFAF" spacing={0.5}>
          <Icon as={getIcon(name)} boxSize="14px" />
          <Text fontSize="xs" lineHeight={0}>
            {label}
          </Text>
        </HStack>

        <Menu placement="bottom-end" offset={offset}>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                variant="ghost"
                size="xs"
                gap={0}
                sx={{ '.chakra-button__icon': { ml: '3.5px' } }}
              >
                {currentFilterLabel}
              </MenuButton>

              <MenuList
                mt="10"
                border="0.5px solid #0000000D"
                boxShadow="0px 4px 4px 0px #00000040"
              >
                {options?.map(option => (
                  <MenuItem
                    onClick={() => handleSelectItem(option)}
                    key={option.value}
                    fontSize="xs"
                    color="#AFAFAF"
                    fontWeight="medium"
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </HStack>
    </ListItem>
  );
}
