import { Box, BoxProps } from '@chakra-ui/react';

interface IBoxProps extends BoxProps {}

function Card({ ...rest }: IBoxProps) {
  const { children } = rest;

  return (
    <Box
      borderRadius="md"
      border="1px solid rgba(32, 33, 35, 0.05)"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      overflow="hidden"
      {...rest}
    >
      {children}
    </Box>
  );
}

export default Card;
