import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import dayjs from 'dayjs';
import { memo } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Lead } from '..';
import Paginate from '../../../../components/Paginate';

type TableLeadBaseProps = {
  leads: Lead[];
  pageCount: number;
  currentPage: number;
  handlePageChange: (selected: { selected: number }) => void;
};

function TableLeadBase({ leads, currentPage, handlePageChange, pageCount }: TableLeadBaseProps) {
  return (
    <>
      <TableContainer display={{ base: 'none', md: 'initial' }}>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Nome</Th>
              <Th paddingX="2">E-mail</Th>
              <Th paddingX="2">Telefone</Th>
              <Th color="black" paddingX="2">
                Conversões
              </Th>
              <Th color="black" paddingX="2">
                <Text>Última</Text>
                <Text>conversão</Text>
              </Th>
              <Th paddingX="1">Ações</Th>
            </Tr>
          </Thead>
          <Tbody>
            {leads.map(lead => {
              return (
                <Tr key={lead.id}>
                  <Td maxW="200px" isTruncated>
                    {lead.fullName}
                  </Td>
                  <Td paddingX="2">{lead.email}</Td>
                  <Td paddingX="2">{lead.phone || '-'}</Td>
                  <Td textAlign="center" fontWeight="bold" paddingX="2">
                    {lead.amountConversions}
                  </Td>
                  <Td paddingX="2" fontWeight="bold">
                    {dayjs(lead.lastConversion, {
                      locale: 'pt-br',
                    }).fromNow()}
                  </Td>
                  <Td>
                    <Menu size="sm" arrowPadding={0}>
                      <MenuButton
                        w="27px"
                        h="24px"
                        borderRadius={5}
                        bgColor="#20212310"
                        _hover={{ bgColor: '#20212350' }}
                        _active={{ bgColor: '#20212350' }}
                        style={{ boxShadow: 'none', outline: 'none' }}
                      >
                        <Stack justifyContent="center" alignItems="center">
                          <HiDotsHorizontal size="18px" color="#20212350" />
                        </Stack>
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          as={Link}
                          to={`/lead-base/${lead.id}/conversions`}
                          fontWeight={500}
                          color="#20212380"
                          style={{ boxShadow: 'none', outline: 'none' }}
                        >
                          Ver conversões
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      {!!leads?.length && pageCount > 1 && (
        <Stack
          width="full"
          justifyContent="center"
          alignItems="center"
          my={6}
          display={{ base: 'none', md: 'initial', lg: 'initial' }}
        >
          <Paginate
            pageCount={pageCount}
            initialPage={currentPage}
            onPageChange={handlePageChange}
          />
        </Stack>
      )}
    </>
  );
}

export default memo(TableLeadBase);
