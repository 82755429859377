import { AccordionPanel, Badge, Button, Stack, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

export interface ISubmenuItemProps {
  key?: string;
  name: string;
  path?: string;
}

export default function SubmenuItem({ name, path }: ISubmenuItemProps) {
  const { pathname } = useLocation();

  const isActive = pathname.includes(path);

  const isNew = path === '/checkout-settings';

  return (
    <AccordionPanel px={0} py={0} as={Link} to={path}>
      <Button
        variant="unstyled"
        fontSize="sm"
        fontWeight="normal"
        fontFamily="Montserrat"
        borderRadius="none"
        whiteSpace="normal"
        width="100%"
        textAlign="left"
        justifyContent="flex-start"
        pl={{ base: '2.7rem !important', lg: '4.35rem !important' }}
        pr={8}
        bg={isActive && 'blackAlpha.50'}
        _focus={{ outline: 'none' }}
        opacity={isActive ? 1 : 0.64}
        _hover={{ opacity: 1 }}
      >
        <Stack direction="row" alignItems="center">
          <Text>{name}</Text>

          <Badge hidden={!isNew} colorScheme="green">
            Novo
          </Badge>
        </Stack>
      </Button>
    </AccordionPanel>
  );
}
