import { Box, Flex, Stack, Text, VStack } from '@chakra-ui/react';
import Input from '../../../../../components/Input';
import termOfUsePageUrl from '../utils';

function Boleto({ ...props }) {
  const { values: formData, handleChange, valueApply } = props;
  const disabledRequired = valueApply === 0;

  return (
    <Box marginTop="4" display="flex" flexDirection="column" gap="5">
      <VStack
        display="flex"
        justifyContent="space-between"
        flexDirection={{ base: 'column', lg: 'row' }}
        alignItems="baseline"
        gap="5"
      >
        <Box width="100%" display="flex" flexDirection="column" gap="5">
          <Input
            id="fullName"
            type="text"
            name="fullName"
            label="Nome"
            placeholder="Digite seu nome completo"
            onChange={handleChange}
            value={formData.fullName}
            isDisabled={disabledRequired}
          />

          <Input
            id="email"
            type="text"
            name="email"
            label="E-mail"
            placeholder="Digite o seu melhor e-mail"
            onChange={handleChange}
            value={formData.email}
            isDisabled={disabledRequired}
          />
        </Box>

        <Box width="100%" display="flex" flexDirection="column" gap="5">
          <Input
            id="phoneNumber"
            type="text"
            name="phoneNumber"
            label="Telefone"
            onChange={handleChange}
            value={formData.phoneNumber}
            minLength={11}
            maxLength={20}
            isDisabled={disabledRequired}
          />

          <Input
            name="number"
            id="number"
            type="text"
            label="CPF/CNPJ"
            placeholder="Digite seu CPF/CNPJ"
            onChange={handleChange}
            value={formData.document.number}
            data-inputgroup={'document'}
            isDisabled={disabledRequired}
          />
        </Box>
      </VStack>

      <Flex
        marginY="8"
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', lg: 'space-between' }}
      >
        <Stack fontWeight="400" color="dark.500" maxWidth={{ base: '100%', lg: '14rem' }}>
          <Text color="dark.500" fontSize={{ base: 'sm', lg: 'xs' }}>
            Ao concluir a compra você concorda com estes{' '}
            <Text
              href={termOfUsePageUrl(`term-of-use`)}
              color="primary.400"
              cursor="pointer"
              display="contents"
              as="a"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termos de Serviços
            </Text>
            .
          </Text>
        </Stack>
      </Flex>
    </Box>
  );
}

export default Boleto;
