import { AspectRatio, Box, HStack, Image, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { MdDragIndicator } from 'react-icons/md';
import { ICover } from '..';
import { FormatMediaType } from '../EditOrNewCovers';
import ActionButton from './ActionButton';
import { convertVideoLinkToEmbed } from '../../../../../../helpers/convertVideoLinkToEmbed';

export interface ICoverSelectProps {
  id: number;
  formatMedia: FormatMediaType;
  active: boolean;
}

interface IProps {
  cover: ICover;
  index: number;
  coversCount: number;
  onOpenEditStatus: () => void;
  onOpenRemoveCover: () => void;
  setCoverSelect: ({ id, formatMedia, active }: ICoverSelectProps) => void;
}

export default function Cover({
  index,
  cover,
  coversCount,
  onOpenEditStatus,
  onOpenRemoveCover,
  setCoverSelect,
}: IProps) {
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false });

  const isImage = cover.bannerType === 'simple-image';

  if (!isMobile) {
    return (
      <Box
        padding="28px 12px 28px 12px"
        background="#FFF"
        borderRadius="5px"
        border="1px solid rgba(32, 33, 35, 0.05)"
        boxShadow="0px 4px 0px 0px rgba(0, 0, 0, 0.10)"
        height="80px"
        display="flex"
        gap="5px"
        alignItems="center"
      >
        <Box width="50px">
          <MdDragIndicator fontSize="24px" color={coversCount <= 1 ? 'transparent' : '#202123'} />
        </Box>

        <Box hidden={!isImage} width="200px" padding="10px">
          <Image width="138px" height="60px" alt="Banner" src={cover.banner} />
        </Box>

        <Box hidden={isImage} width="200px" padding="10px">
          <AspectRatio width="130px" height="60px" ratio={16 / 9}>
            <iframe
              title={cover.name}
              src={`${convertVideoLinkToEmbed(
                cover.videoLink
              )}?rel=0&amp;autoplay=0&mute=1&showinfo=0&controls=0`}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </AspectRatio>
        </Box>

        <Text width="full" isTruncated fontSize="14px">
          {index + 1}. {cover.name}
        </Text>

        <HStack spacing="30px">
          <Box>
            <Text>{cover.active ? 'Ativo' : 'Inativo'}</Text>
          </Box>

          <Box width="60px">
            <ActionButton
              cover={cover}
              onOpenEditStatus={onOpenEditStatus}
              onOpenRemoveCover={onOpenRemoveCover}
              formatMedia={cover.bannerType as FormatMediaType}
              setCoverSelect={setCoverSelect}
            />
          </Box>
        </HStack>
      </Box>
    );
  }

  return (
    <Box
      padding="10px"
      background="#FFF"
      borderRadius="5px"
      border="1px solid rgba(32, 33, 35, 0.05)"
      boxShadow="0px 4px 0px 0px rgba(0, 0, 0, 0.10)"
      height="auto"
      display="flex"
      gap="5px"
      flexDirection="column"
      alignItems="start"
    >
      <HStack alignItems="start">
        <Box width="50px" marginY={5}>
          <MdDragIndicator fontSize="24px" color={coversCount <= 1 ? 'transparent' : '#202123'} />
        </Box>

        <Stack>
          <HStack hidden={!isImage}>
            <Image width="285px" height="124px" alt="Banner" src={cover.banner} />
          </HStack>

          <HStack hidden={isImage}>
            <AspectRatio width="280px" height="124px" ratio={16 / 9}>
              <iframe
                title={cover.name}
                src={`${convertVideoLinkToEmbed(
                  cover.videoLink
                )}?rel=0&amp;autoplay=0&mute=1&showinfo=0&controls=0`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </AspectRatio>
          </HStack>

          <HStack>
            <Stack width="full" alignItems="flex-start" spacing={0} textAlign="start">
              <Text
                color="#202123"
                fontSize="18px"
                fontWeight="600"
                lineHeight="normal"
                isTruncated
                width={{ base: '150px', lg: 'full' }}
              >
                {index + 1}. {cover.name}
              </Text>

              <Text
                color="#202123"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="normal"
              >
                {cover.active ? 'Ativo' : 'Inativo'}
              </Text>
            </Stack>

            <Box width="30px">
              <ActionButton
                cover={cover}
                onOpenEditStatus={onOpenEditStatus}
                onOpenRemoveCover={onOpenRemoveCover}
                formatMedia={cover.bannerType as FormatMediaType}
                setCoverSelect={setCoverSelect}
              />
            </Box>
          </HStack>
        </Stack>
      </HStack>
    </Box>
  );
}
