import { saveLastContent } from '../helpers/LocalStorageHelper';
import UploadHelper from '../helpers/UploadHelper';
import { axiosBackend } from './Configuration';
import Platform from './Platform';

const CourseAPI = {
  store: async nameCourse => {
    try {
      const { data: dataCourse } = await axiosBackend().post('/courses', {
        name: nameCourse,
      });

      return dataCourse;
    } catch (error) {
      throw error;
    }
  },

  show: async id => {
    try {
      const { data: dataCourse } = await axiosBackend().get(`/courses/${id}`);

      return dataCourse;
    } catch (error) {
      throw error;
    }
  },

  products: async ({ page, per_page, search = '', status, orderBy }) => {
    try {
      const { data: dataCourse } = await axiosBackend().get(
        `/products?page=${page}${per_page && `&per_page=${per_page}`}${
          search && `&search=${search}`
        }${status && `&status=${status}`}${orderBy && `&orderBy=${orderBy}`}`
      );

      if (dataCourse?.data) {
        return dataCourse;
      }
    } catch (error) {
      throw error;
    }
  },

  list: async ({ status, courseType }) => {
    try {
      const { data: coursesList } = await axiosBackend().get(
        `/courses/list${status ? `?status=${status}` : ''}${
          courseType && !status
            ? `?courseType=${courseType}`
            : courseType && status
            ? `&courseType=${courseType}`
            : ''
        }`
      );

      return coursesList;
    } catch (error) {
      throw error;
    }
  },
  getAllCourses: async () => {
    try {
      const { data: coursesList } = await axiosBackend().get(`/courses/get-all-courses`);

      return coursesList;
    } catch (error) {
      throw error;
    }
  },

  update: async (id, newDataCourse) => {
    let thumbnailKey = '';
    let bannerKey = '';

    try {
      if (newDataCourse.thumbnail && typeof newDataCourse.thumbnail !== 'string') {
        const { newFileName } = await UploadHelper.upload(newDataCourse.thumbnail, 'course');

        newDataCourse.thumbnail = newFileName;

        thumbnailKey = `course/${newFileName}`;
      }

      if (newDataCourse.banner && typeof newDataCourse.banner !== 'string') {
        const { newFileName } = await UploadHelper.upload(newDataCourse.banner, 'course');

        newDataCourse.banner = newFileName;

        bannerKey = `course/${newFileName}`;
      }

      const { data: curseData } = await axiosBackend().patch(`/courses/${id}`, newDataCourse);

      return curseData;
    } catch (error) {
      if (thumbnailKey) {
        await Platform.deleteFileByKey(thumbnailKey);
      }

      if (bannerKey) {
        await Platform.deleteFileByKey(thumbnailKey);
      }

      throw error;
    }
  },

  updateBasicSettings: async (id, payload) => {
    let termsOfUseKey = '';

    try {
      if (payload.termsOfUse && typeof payload.termsOfUse !== 'string') {
        const { newFileName } = await UploadHelper.upload(payload.termsOfUse, 'archives');

        payload.termsOfUse = newFileName;

        termsOfUseKey = `archives/${newFileName}`;
      }

      const { data: curseData } = await axiosBackend().patch(
        `/courses/${id}/basic-settings`,
        payload
      );

      return curseData;
    } catch (error) {
      if (termsOfUseKey) {
        await Platform.deleteFileByKey(termsOfUseKey);
      }

      throw error;
    }
  },

  acceptTerms: async id => {
    try {
      const { data: curseData } = await axiosBackend().patch(`/students/${id}/acceptTermsUse`);

      return curseData;
    } catch (error) {
      throw error;
    }
  },

  delete: async courseId => {
    try {
      await axiosBackend().delete(`/courses/${courseId}`);
    } catch (error) {
      throw error;
    }
  },

  indexProducts: async () => {
    try {
      const { data: dataProducts } = await axiosBackend().get('/students/courses');

      return dataProducts;
    } catch (error) {
      throw error;
    }
  },

  updateLastVisit: async (courseId, moduleId, contentId, contentType) => {
    try {
      saveLastContent(courseId, moduleId, contentId, contentType);
      // await axiosBackend().patch(
      //   `/students/${courseId}/course/${moduleId}/module/${contentId}/content/save-last-content`,
      //   {},
      //   {
      //     timeout: 5000,
      //   }
      // );
    } catch (error) {
      throw error;
    }
  },

  purchase: async (courseId, transactionValues, courseSubscriptionPlan) => {
    try {
      const { data: purchaseData } = await axiosBackend().post(
        `/purchases/${courseId}/course/purchase${
          courseSubscriptionPlan ? `?courseSubscriptionPlan=${courseSubscriptionPlan}` : ''
        }`,
        transactionValues
      );

      return purchaseData;
    } catch (error) {
      throw error;
    }
  },

  purchaseV2: async transactionValues => {
    delete transactionValues.paymentMethod;

    try {
      const { data: purchaseData } = await axiosBackend().post('/v2/purchases', transactionValues);

      return purchaseData;
    } catch (error) {
      throw error;
    }
  },

  purchaseSubscription: async (courseId, transactionValues) => {
    try {
      const { data: purchaseData } = await axiosBackend().post(
        `/purchases/${courseId}/course-subscription/purchase`,
        transactionValues
      );

      return purchaseData;
    } catch (error) {
      throw error;
    }
  },

  verifyPurchaseStatus: async purchaseId => {
    try {
      const { data: response } = await axiosBackend().get(`/purchases/${purchaseId}/checkout`);

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteBanner: async (courseId, bannerId) => {
    try {
      await axiosBackend().delete(`/courses/${courseId}/course/${bannerId}/banner-hero`);
    } catch (error) {
      throw error;
    }
  },

  filterUsers: {
    users: async ({ page = 1, search = '' }, courseId) => {
      try {
        const { data: usersData } = await axiosBackend().get(
          `/courses/${courseId}/course/users?page=${page}${search && `&search=${search}`}`
        );
        return usersData;
      } catch (error) {
        throw error;
      }
    },
    showAccepted: async courseId => {
      try {
        const { data: usersData } = await axiosBackend().get(`/courses/${courseId}/course/users`);
        return usersData;
      } catch (error) {
        throw error;
      }
    },
  },

  studentsReport: {
    index: async course_id => {
      try {
        const { data: reportData } = await axiosBackend().get(
          `/courses/${course_id}/course/users/export`
        );

        return reportData;
      } catch (error) {
        throw error;
      }
    },
    export: async ({ course_id, typeFile = '', search = '' }) => {
      const urlDownload = `/courses/${course_id}/exportStudentListByCourse${
        typeFile ? `?typeFile=${typeFile}` : ''
      }${search ? `&search=${search}` : ''}`;

      const axios = axiosBackend();

      const response = await axios({
        url: urlDownload,
        method: 'GET',
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        typeFile === 'CSV' ? 'Lista-de-Alunos.csv' : 'Lista-de-Alunos.xlsx'
      );
      document.body.appendChild(link);
      link.click();
    },
  },

  modulesContentsCourse: async course_id => {
    try {
      const { data: modulesContentsData } = await axiosBackend().get(
        `/courses/${course_id}/course/modules-contents-questions`
      );

      return modulesContentsData;
    } catch (error) {
      throw error;
    }
  },

  calculateInstallmentAmount: {
    index: async ({ amount, installments, installmentWithTariff = false }) => {
      try {
        const { data: installmentsData } = await axiosBackend().get(
          `courses/calculate-installments-amount${amount && `?amount=${amount}`}${
            installments && `&installments=${installments}`
          }${`&installmentWithTariff=${installmentWithTariff}`}`
        );

        return installmentsData;
      } catch (error) {
        throw error;
      }
    },
  },

  releaseAccess: async (purchase_id, courseId) => {
    try {
      return await axiosBackend().patch(`/courses/releaseAccess/${purchase_id}/purchase`, {
        courseId,
      });
    } catch (error) {
      throw error;
    }
  },

  removeAccess: async (purchase_id, courseId, courseUserId) => {
    try {
      return await axiosBackend().patch(`/courses/removeAccess/${purchase_id}/purchase`, {
        courseId,
        courseUserId,
      });
    } catch (error) {
      throw error;
    }
  },

  extendAccess: async (purchase_id, payload) => {
    try {
      return await axiosBackend().patch(`/courses/extendAccess/${purchase_id}/courseUser`, payload);
    } catch (error) {
      throw error;
    }
  },

  insertNewUser: async (courseId, emails) => {
    try {
      const { data: newUserData } = await axiosBackend().post(
        `/courses/${courseId}/course/insertNewUser`,
        { emails }
      );

      return newUserData;
    } catch (error) {
      throw error;
    }
  },

  certificate: {
    patch: async (courseId, payload) => {
      try {
        const { data: certificateData } = await axiosBackend().patch(
          `/courses/${courseId}/certificate`,
          payload
        );

        return certificateData;
      } catch (error) {
        throw error;
      }
    },

    generate: async (courseId, userId) => {
      try {
        const { data: certificateData } = await axiosBackend().get(
          `/certificate/${courseId}/course/${userId}/user`
        );

        return certificateData;
      } catch (error) {
        throw error;
      }
    },

    show: async courseId => {
      try {
        const { data: certificateData } = await axiosBackend().get(
          `/courses/${courseId}/certificate`
        );

        return certificateData;
      } catch (error) {
        throw error;
      }
    },
  },
};

export default CourseAPI;
