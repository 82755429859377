import { FormEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, BoxProps } from '@chakra-ui/react';

interface IForm extends BoxProps {
  productId: string;
  onSubmit: (event: FormEvent<HTMLDivElement>) => Promise<void>;
}

function Form({ productId, onSubmit, ...rest }: IForm) {
  const location = useLocation();

  return (
    <Box
      as="form"
      onSubmit={onSubmit}
      name={`checkoutStudent-${location.pathname.split('/').pop()}-${productId}`}
      display="grid"
      gridTemplateRows={{
        base: `
          2rem
          auto
          auto
        `,
        lg: `
          2rem
          auto,
        `,
      }}
      gridTemplateAreas={{
        // base: `
        //   'purchaseDetails'
        //   'pages'
        // `,
        base: `
          'breadcrumb'
          'purchaseDetails'
          'pages'
        `,
        // lg: `
        //   'pages purchaseDetails'
        // `,
        lg: `
          'breadcrumb purchaseDetails'
          'pages purchaseDetails'
        `,
      }}
      gridTemplateColumns={{
        base: `1fr`,
        lg: `
          44em 30em
        `,
      }}
      // gridAutoRows={{
      //   base: `auto`,
      //   lg: `auto`,
      // }}
      // gridAutoRows={{
      //   base: `2rem 1fr`,
      //   lg: `2rem 1fr`,
      // }}
      gap="5"
      width="full"
      marginBottom="8"
      padding="0"
      justifyContent="center"
      {...rest}
    />
  );
}

export default Form;
