import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import KursusAPI from '../../../../../api/Kursus';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import Spinner from '../../../../../components/Loadings/Spinner';
import Toast from '../../../../../components/Toast';
import Tooltip from '../../../../../components/Tooltip';
import styles from './styles.module.css';
import { useParams } from 'react-router-dom';
import {
  GetDefaultCheckoutPageUrl,
  GetDefaultSalesPageUrl,
} from '../../../../../components/AffiliationForm/utils/GetProductPages';

const initialState = {
  visibility: 'public',
  automaticAffiliation: true,
  typeOfAffiliateCommission: 'value',
  valueByTheAffiliateCommission: '',
  instructionsForAffiliates: '',
  hasRecruitmentCommission: false,
  typeOfRecruitmentCommission: 'percentage',
  valueByTheRecruitmentCommission: '',
  maximumQuantityOfSales: '1',
  salesPageUrl: '',
  checkoutPageUrl: '',
};

interface IAffiliation {
  visibility: string;
  automaticAffiliation: boolean;
  typeOfAffiliateCommission: string | number;
  valueByTheAffiliateCommission: string;
  instructionsForAffiliates: string;
  hasRecruitmentCommission: boolean;
  typeOfRecruitmentCommission: string | number;
  valueByTheRecruitmentCommission: string;
  maximumQuantityOfSales: string;
  salesPageUrl: string;
  checkoutPageUrl: string;
}

type IParams = {
  id: string;
};

function Affiliation() {
  const [affiliation, setAffiliation] = useState<IAffiliation>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [updateAffiliation, setUpdateAffiliation] = useState(false);

  const { id: courseId } = useParams<IParams>();

  useEffect(() => {
    if (courseId) {
      const fetchProduct = async function () {
        try {
          const { data: affiliationData } = await KursusAPI.findProductById(courseId);

          if (affiliationData) {
            setAffiliation(affiliationData);
            setUpdateAffiliation(true);
          } else {
            setAffiliation({
              ...affiliation,
              checkoutPageUrl: GetDefaultCheckoutPageUrl(courseId),
              salesPageUrl: GetDefaultSalesPageUrl(courseId),
            });
          }
        } catch (error) {
          Toast(ErrorResponse(error), 'error');
        }
      };

      fetchProduct();
    }
  }, [affiliation, courseId]);

  function handleCommissionTypeChange(event: ChangeEvent<HTMLSelectElement>) {
    let { name, value } = event.target;

    if (name === 'typeOfAffiliateCommission') {
      setAffiliation(prevState => ({
        ...prevState,
        valueByTheRecruitmentCommission: '',
      }));
    }

    if (name === 'typeOfRecruitmentCommission') {
      setAffiliation(prevState => ({
        ...prevState,
        valueByTheAffiliateCommission: '',
      }));
    }

    setAffiliation(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value, type } = event.target;

    if (name === 'visibility' && type === 'radio') {
      return setAffiliation({ ...affiliation, [name]: value === 'true' ? 'public' : 'private' });
    }

    if (type === 'radio') {
      return setAffiliation({ ...affiliation, [name]: value === 'true' ? true : false });
    }

    const data = { ...affiliation, [name]: value };

    setAffiliation(data);
    setHasChanged(false);
  }

  function handleDiscard() {
    setAffiliation(initialState);
  }

  const {
    visibility,
    automaticAffiliation,
    typeOfAffiliateCommission,
    valueByTheAffiliateCommission,
    instructionsForAffiliates,
    hasRecruitmentCommission,
    typeOfRecruitmentCommission,
    valueByTheRecruitmentCommission,
    maximumQuantityOfSales,
  } = affiliation;

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const payload = {
      visibility,
      automaticAffiliation,
      typeOfAffiliateCommission,
      valueByTheAffiliateCommission: Number(valueByTheAffiliateCommission),
      instructionsForAffiliates,
      hasRecruitmentCommission,
      typeOfRecruitmentCommission,
      valueByTheRecruitmentCommission: Number(valueByTheRecruitmentCommission),
      maximumQuantityOfSales: Number(maximumQuantityOfSales),
      salesPageUrl: affiliation.salesPageUrl,
      checkoutPageUrl: affiliation.checkoutPageUrl,
    };

    try {
      if (courseId && !updateAffiliation) {
        setIsLoading(true);

        await KursusAPI.createProduct(courseId, payload);

        setUpdateAffiliation(true);

        Toast('Produto cadastrado para afiliação');
      } else {
        await KursusAPI.updateProduct(courseId, payload);
        Toast('Produto de afiliação atualizado');
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col">
          <h2>Cadastrar produto para afiliação</h2>

          <div className="mt-4 card shadow-sm mb-5 pb-1">
            <div className="p-4">
              <form onSubmit={handleSubmit}>
                <section>
                  <h4>Configurações de afiliação</h4>

                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <div className="form-group mt-3">
                        <h6 className="d-flex">
                          Seu produto aparecerá na vitrine?{' '}
                          <Tooltip content="lorem ipsum..." background="#eb7129" />
                        </h6>

                        <div className="form-check form-check-inline">
                          <input
                            className={`form-check-input ${styles.inputRadio}`}
                            type="radio"
                            name="visibility"
                            id="visibility"
                            value="true"
                            checked={visibility === 'public'}
                            onChange={handleChange}
                          />

                          <label className="form-check-label" htmlFor="visibility">
                            Sim
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className={`form-check-input ${styles.inputRadio}`}
                            type="radio"
                            name="visibility"
                            id="NotVisibility"
                            value="false"
                            checked={visibility === 'private'}
                            onChange={handleChange}
                          />

                          <label className="form-check-label" htmlFor="NotVisibility">
                            Não
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group mt-3">
                        <h6 className="d-flex">
                          Afiliação automática?
                          <Tooltip content="lorem ipsum..." background="#eb7129" />
                        </h6>

                        <div className="form-check form-check-inline">
                          <input
                            className={`form-check-input ${styles.inputRadio}`}
                            type="radio"
                            name="automaticAffiliation"
                            id="automaticAffiliation"
                            value="true"
                            checked={automaticAffiliation}
                            onChange={handleChange}
                          />

                          <label className="form-check-label" htmlFor="automaticAffiliation">
                            Sim
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className={`form-check-input ${styles.inputRadio}`}
                            type="radio"
                            name="automaticAffiliation"
                            id="NotAutomaticAffiliation"
                            value="false"
                            checked={!automaticAffiliation}
                            onChange={handleChange}
                          />

                          <label className="form-check-label" htmlFor="NotAutomaticAffiliation">
                            Não
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4">
                      <div className="form-group mt-3">
                        <label htmlFor="valueByTheAffiliateCommission" className="mb-2">
                          <h6 className="d-flex">
                            Qual será a comissão do afiliado?
                            <Tooltip content="lorem ipsum..." background="#eb7129" />
                          </h6>
                        </label>

                        <div className="row">
                          <div className="col-9 m-0 pr-0">
                            <input
                              className={`form-control ${styles.textInput}`}
                              type="number"
                              name="valueByTheAffiliateCommission"
                              id="valueByTheAffiliateCommission"
                              style={{ borderRight: 0 }}
                              step="0.01"
                              min="1"
                              max="99999.99"
                              placeholder={
                                typeOfAffiliateCommission === 'percentage' ? '0' : '00.00'
                              }
                              maxLength={typeOfAffiliateCommission === 'percentage' ? 3 : 8}
                              value={valueByTheAffiliateCommission}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-3 m-0 pl-0">
                            <select
                              className={`form-control ${styles.selectInput}`}
                              name="typeOfAffiliateCommission"
                              value={typeOfAffiliateCommission}
                              onChange={handleCommissionTypeChange}
                            >
                              <option value="value">R$</option>
                              <option value="percentage">%</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="instructionsForAffiliates" className="mb-2">
                          <h6>Instruções para afiliados:</h6>
                        </label>

                        <textarea
                          className={`form-control ${styles.textAreaInput}`}
                          name="instructionsForAffiliates"
                          id="instructionsForAffiliates"
                          rows={5}
                          placeholder="Escreva suas instruções para divulgação de afiliados"
                          value={instructionsForAffiliates}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </section>

                <section className="mt-5">
                  <h4 className="d-flex">
                    Comissão de recrutamento
                    <Tooltip content="lorem ipsum..." background="#eb7129" />
                  </h4>

                  <div className="row">
                    <div className="col-12 col-lg-3">
                      <div className="form-group mt-3">
                        <h6>Seu produto terá comissão de recrutamento?</h6>

                        <div className="form-check form-check-inline">
                          <input
                            className={`form-check-input ${styles.inputRadio}`}
                            type="radio"
                            name="hasRecruitmentCommission"
                            id="hasRecruitmentCommission"
                            value="true"
                            onChange={handleChange}
                            checked={hasRecruitmentCommission}
                          />

                          <label className="form-check-label" htmlFor="hasRecruitmentCommission">
                            Sim
                          </label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className={`form-check-input ${styles.inputRadio}`}
                            type="radio"
                            name="hasRecruitmentCommission"
                            id="HasNotRecruitmentCommission"
                            value="false"
                            onChange={handleChange}
                            checked={!hasRecruitmentCommission}
                          />

                          <label className="form-check-label" htmlFor="HasNotRecruitmentCommission">
                            Não
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-5">
                      <fieldset disabled={!hasRecruitmentCommission}>
                        <div className="form-group mt-3">
                          <label
                            htmlFor="valueByTheRecruitmentCommission"
                            className="mb-2"
                            style={{ color: !hasRecruitmentCommission && '#20212380' }}
                          >
                            <h6>Qual será a comissão de recrutamento?</h6>
                          </label>

                          <div className="row">
                            <div className="col-9 m-0 pr-0">
                              <input
                                className={`form-control ${styles.textInput}`}
                                type="number"
                                name="valueByTheRecruitmentCommission"
                                id="valueByTheRecruitmentCommission"
                                style={{
                                  borderRight: 0,
                                  opacity: !hasRecruitmentCommission && '0.5',
                                }}
                                step="0.01"
                                min="1"
                                max="99999.99"
                                placeholder={
                                  typeOfRecruitmentCommission === 'percentage' ? '0' : '00.00'
                                }
                                maxLength={typeOfRecruitmentCommission === 'percentage' ? 3 : 8}
                                value={valueByTheRecruitmentCommission}
                                onChange={handleChange}
                                disabled={!hasRecruitmentCommission}
                              />
                            </div>

                            <div className="col-3 m-0 pl-0">
                              <select
                                className={`form-control ${styles.selectInput}`}
                                name="typeOfRecruitmentCommission"
                                value={typeOfRecruitmentCommission}
                                onChange={event => handleCommissionTypeChange(event)}
                                disabled={!hasRecruitmentCommission}
                                style={{ opacity: !hasRecruitmentCommission && '0.5' }}
                              >
                                <option value="value">R$</option>
                                <option value="percentage">%</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>

                    <div className="col-12 col-lg-4">
                      <fieldset disabled={!hasRecruitmentCommission}>
                        <div className="form-group mt-3">
                          <label htmlFor="maximumQuantityOfSales" className="mb-2">
                            <h6
                              className="d-flex"
                              style={{ color: !hasRecruitmentCommission && '#20212380' }}
                            >
                              Quantidade máxima de vendas{' '}
                              <Tooltip content="lorem ipsum..." background="#eb7129" />
                            </h6>
                          </label>

                          <input
                            className={`form-control ${styles.textInput}`}
                            type="text"
                            name="maximumQuantityOfSales"
                            id="maximumQuantityOfSales"
                            value={maximumQuantityOfSales}
                            onChange={handleChange}
                            disabled={!hasRecruitmentCommission}
                            style={{ opacity: !hasRecruitmentCommission && '0.5' }}
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </section>

                <section className="mt-5">
                  <h4 className="d-flex m-0">Configuração de links</h4>

                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="form-group mt-3">
                        <label className={styles.formTextLabel} htmlFor="salesPageUrl">
                          Link para página de vendas do produto
                        </label>
                        <input
                          className={`form-control ${styles.textInput}`}
                          type="text"
                          name="salesPageUrl"
                          id="salesPageUrl"
                          placeholder="Link aqui"
                          value={affiliation.salesPageUrl || GetDefaultSalesPageUrl(courseId)}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-lg-6">
                      <div className="form-group mt-3">
                        <label className={styles.formTextLabel} htmlFor="checkoutPageUrl">
                          Link para página de checkout do produto
                        </label>
                        <input
                          className={`form-control ${styles.textInput}`}
                          type="text"
                          name="checkoutPageUrl"
                          id="checkoutPageUrl"
                          placeholder="Link aqui"
                          value={affiliation.checkoutPageUrl || GetDefaultCheckoutPageUrl(courseId)}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </section>

                <section className="mt-4">
                  <div className="row justify-content-end">
                    <div className="col-12 col-md-2 order-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block .btn-disabled"
                        disabled={hasChanged}
                      >
                        {isLoading ? <Spinner /> : 'Salvar'}
                      </button>
                    </div>

                    <div className="col-12 col-md-2 order-md-1 mt-3 mt-md-0">
                      <button
                        type="button"
                        className="btn btn-light btn-block .btn-disabled"
                        disabled={hasChanged}
                        onClick={handleDiscard}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </section>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Affiliation;
