import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Select as ChakraSelect,
  Stack,
  Text,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, useRef, useState } from 'react';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';
import { months, years } from '../../../constants';
import termOfUsePageUrl from '../utils';
import InputMask from './InputMask';
import SimulateCard from './SimulateCard';

const isAmexCardRegex = /^3[47]/;

type FlagType = 'visa' | 'master' | 'other';
export interface IBaseInstallmentAmount {
  amount: number;
  fee: number;
  feeText: string;
  installment: number;
  installmentAmount: number;
}

interface ICreditCard {
  cardHolderName: string;
  id: string;
  expirationDate: string;
  firsDigits: number;
  lastDigits: number;
  brand: string;
}

export function subtactByPercentage(amount: number, percentage: number) {
  return amount - (percentage / 100) * amount;
}

function CreditCard({ ...props }) {
  const {
    handleCreditCard,
    values: formData,
    creditCards,
    handleChange,
    subscription,
    valueApply,
  } = props;

  const { creditCard } = formData;
  const { cardHolderName, cardCVV } = creditCard;
  const disabledRequired = valueApply === 0;

  const inputRef = useRef();

  const [cardMask, setCardMask] = useState<string>('');
  const [cardCVVMask, setCardCVVMask] = useState<string>('');
  const [cardNumber, setCardNumber] = useState<string>('');

  const [flag, setFlag] = useState<FlagType>('other');

  const [isInputClick, setIsInputClick] = useState<boolean>(false);

  useOutsideClick({
    ref: inputRef,
    handler: () => setIsInputClick(false),
  });

  const [cardExpirationDate, setCardExpirationDate] = useState({
    month: '',
    year: '',
  });

  function handleCardMask(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    const mask = isAmexCardRegex.test(value) ? '9999 999999 99999' : '9999 9999 9999 9999';

    if (value.startsWith('4')) {
      setFlag('visa');
    } else if (value.startsWith('5')) {
      setFlag('master');
    } else {
      setFlag('other');
    }

    setCardMask(mask);
    setCardNumber(value);

    handleCreditCard({
      ...formData.creditCard,
      cardNumber,
      [name]: value,
    });
  }

  function handleCardCVVCreditChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    const cvvMask = isAmexCardRegex.test(cardNumber) ? '9999' : '999';

    setCardCVVMask(cvvMask);

    handleCreditCard({
      ...formData.creditCard,
      cardCVV,
      [name]: value,
    });
  }

  function handleCardExpirationDateChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;

    setCardExpirationDate({
      ...cardExpirationDate,
      [name]: value,
    });

    handleCreditCard({
      ...formData.creditCard,
      ...cardExpirationDate,
      [name]: value,
    });
  }

  return (
    <>
      <HStack alignItems="flex-start" flexDirection={{ base: 'column', lg: 'row' }} gap="3">
        <Box
          width={{ base: '100%', lg: '50%' }}
          marginTop="4"
          display="flex"
          flexDirection="column"
          gap="7"
        >
          {creditCards?.length !== 0 && (
            <Select
              id="cardId"
              name="cardId"
              data-inputgroup={'creditCard'}
              label="Cartões salvos"
              color="dark.500"
              onChange={handleCardCVVCreditChange}
              value={formData?.creditCard.cardId}
              isDisabled={disabledRequired}
            >
              <option value="">Selecione um cartão</option>
              {creditCards?.map((creditCard: ICreditCard) => {
                return (
                  <option key={`${creditCard.id}-${creditCard.lastDigits}`} value={creditCard.id}>
                    {creditCard.cardHolderName} - {creditCard.lastDigits}
                  </option>
                );
              })}
            </Select>
          )}

          <InputMask
            id="cardNumber"
            name="cardNumber"
            label="Número do cartão"
            mask={cardMask}
            placeholder="**** **** **** 0001"
            onChange={handleCardMask}
            value={cardNumber}
            data-inputgroup={'creditCard'}
            isDisabled={formData.creditCard.cardId !== '' || disabledRequired}
            isRequired={!disabledRequired}
          />

          <Input
            focusBorderColor="orange.400"
            id="cardHolderName"
            name="cardHolderName"
            type="text"
            label="Titular do cartão"
            placeholder="Como no cartão"
            onChange={handleChange}
            value={formData.creditCard.cardHolderName}
            data-inputgroup={'creditCard'}
            minLength={2}
            maxLength={45}
            isDisabled={formData.creditCard.cardId !== '' || disabledRequired}
            isRequired={!disabledRequired}
          />

          <FormControl display="flex" alignItems="center" gap="5">
            <VStack alignItems="baseline">
              <FormLabel marginBottom="0" fontSize="sm" fontWeight="medium" htmlFor="month">
                Data de validade
              </FormLabel>
              <Flex gap="2" marginTop="0.25rem !important">
                <ChakraSelect
                  id="month"
                  name="month"
                  size="md"
                  variant="outline"
                  color="dark.500"
                  fontSize="md"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="borderInput.100"
                  borderRadius="md"
                  outline="2px solid transparent"
                  outlineOffset="2px"
                  focusBorderColor="primary.400"
                  _placeholder={{ color: 'blackAlpha.500' }}
                  _focus={{
                    zIndex: 1,
                    borderColor: '#ec7117',
                  }}
                  _hover={{
                    '&:focus': {
                      borderColor: 'none',
                    },
                  }}
                  placeholder="MM"
                  onChange={handleCardExpirationDateChange}
                  value={cardExpirationDate.month}
                  isDisabled={formData.creditCard.cardId !== '' || disabledRequired}
                  isRequired={!disabledRequired}
                >
                  {months.map(month => {
                    return (
                      <option key={month.name} value={month.id}>
                        {month.id}
                      </option>
                    );
                  })}
                </ChakraSelect>
                <ChakraSelect
                  id="year"
                  name="year"
                  size="md"
                  variant="outline"
                  color="dark.500"
                  fontSize="md"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor="borderInput.100"
                  borderRadius="md"
                  outline="2px solid transparent"
                  outlineOffset="2px"
                  focusBorderColor="primary.400"
                  _placeholder={{ color: 'blackAlpha.500' }}
                  _focus={{
                    zIndex: 1,
                    borderColor: '#ec7117',
                  }}
                  _hover={{
                    '&:focus': {
                      borderColor: 'none',
                    },
                  }}
                  placeholder="AA"
                  onChange={handleCardExpirationDateChange}
                  value={cardExpirationDate.year}
                  isDisabled={formData.creditCard.cardId !== '' || disabledRequired}
                  isRequired={!disabledRequired}
                >
                  {years.map(year => {
                    return (
                      <option key={year.name} value={year.id}>
                        {year.id}
                      </option>
                    );
                  })}
                </ChakraSelect>
              </Flex>
            </VStack>

            <Flex flex="2">
              <InputMask
                ref={inputRef}
                id="cardCVV"
                name="cardCVV"
                label="CVV"
                mask={cardCVVMask}
                placeholder="***"
                onChange={handleCardCVVCreditChange}
                value={formData.creditCard.cardCVV}
                onClick={() => setIsInputClick(true)}
                onFocus={() => setIsInputClick(true)}
                onBlur={() => setIsInputClick(false)}
                minLength={3}
                maxLength={4}
                data-inputgroup="creditCard"
                isDisabled={formData.creditCard.cardId !== '' || disabledRequired}
                isRequired={!disabledRequired}
              />
            </Flex>
          </FormControl>

          <Box hidden={subscription || disabledRequired}>
            <Select
              id="installments"
              name="installments"
              label="Opções de parcelamento"
              onChange={handleChange}
              value={formData.installments}
              color="dark.500"
              isRequired={!disabledRequired}
            >
              <option hidden>Selecione o número de parcelas</option>
            </Select>
          </Box>

          <Box display="flex" alignItems="flex-start">
            <input
              name="saveDataForFuturePurchases"
              id="saveDataForFuturePurchases"
              type="checkbox"
              checked={formData.saveDataForFuturePurchases}
              onChange={handleChange}
              data-inputgroup={'checkbox'}
              disabled={formData.creditCard.cardId !== '' || disabledRequired}
              style={{ accentColor: '#ee6924' }}
            />
            <label
              style={{ marginLeft: '0.25rem', marginTop: '-0.25rem' }}
              htmlFor="saveDataForFuturePurchases"
            >
              Salvar dados de pagamento para futuras compras.
            </label>
          </Box>
        </Box>
        <Box
          display={{ base: 'none', lg: 'flex' }}
          width="50%"
          marginTop="1.5rem !important"
          flexDirection="column"
        >
          <SimulateCard
            isInputClick={isInputClick}
            cardNumber={cardNumber}
            cardHolderName={cardHolderName}
            cardExpirationDate={cardExpirationDate}
            cardCVV={cardCVV}
            flag={flag}
          />
        </Box>
      </HStack>

      <Flex
        marginY="8"
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', lg: 'space-between' }}
      >
        <Stack fontWeight="400" color="dark.500" maxWidth={{ base: '100%', lg: '14rem' }}>
          <Text color="dark.500" fontSize={{ base: 'sm', lg: 'xs' }}>
            Ao concluir a compra você concorda com estes{' '}
            <Text
              href={termOfUsePageUrl(`term-of-use`)}
              color="primary.400"
              cursor="pointer"
              display="contents"
              as="a"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termos de Serviços
            </Text>
            .
          </Text>
        </Stack>
      </Flex>
    </>
  );
}

export default CreditCard;
