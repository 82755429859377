import {
  ButtonGroup,
  ContainerPreview,
  ContentHeader,
  DownloadButton,
  FileContent,
  Form,
  FormControl,
  FormGroup,
  Preview,
} from './styles';

import { BsTrash } from 'react-icons/bs';
import { ContentUserAPI } from '../../../../../../api/ContentUser';
import CustomDropzone from '../../../../../../components/CustomDropzone';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { Modal } from 'react-bootstrap';
import Platform from '../../../../../../api/Platform';
import Spinner from '../../../../../../components/Loadings/Spinner';
import { TOKEN_LOCAL_STORAGE } from '../../../../../../helpers/LocalStorageHelper';
import Toast from '../../../../../../components/Toast';
import Tooltip from '../../../../../../components/Tooltip';
import UploadHelper from '../../../../../../helpers/UploadHelper';
import { baseURLBackend } from '../../../../../../api/Configuration';
import fixIcon from '../../../../../../assets/icons/fixIcon.svg';
import path from 'path';
import pdfIcon from '../../../../../../assets/icons/pdfIconOrange.svg';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

const modal = {
  backdropFilter: 'blur(4px)',
};

const modalHeader = {
  borderBottom: 'none',
};

const modalTitle = {
  marginLeft: '0.5rem',
};

const modalBody = {
  paddingTop: '0',
};

const initialState = {
  feedback: '',
  testGrade: '',
  filePDF: '',
  fileNamePDF: '',
};

const FIRST_FILE = 0;

function FeedbackModal({ show, onHide, task, fetchTasks }) {
  const { id: courseId } = useParams();
  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

  const {
    contentId,
    moduleId,
    studentId,
    title,
    fileNameTest,
    testGrade,
    feedback,
    fileNameTestCorrection,
  } = task;

  const [form, setForm] = useState(initialState);
  const [file, setFile] = useState(null);
  const [fileCorrection, setFileCorrection] = useState(fileNameTestCorrection);

  const [oldFeedback, setOldFeedback] = useState(feedback);
  const currentFeedback = oldFeedback === null ? setOldFeedback('') : oldFeedback;

  const [isLoading, setIsLoading] = useState(false);

  function handleChange(event) {
    const { name, value } = event.target;

    if (name === 'archive') {
      const file = event.target.files[FIRST_FILE] || '';

      return setForm(prevForm => ({ ...prevForm, filePDF: file }));
    }

    setForm(prevForm => {
      return {
        ...prevForm,
        [name]: value,
      };
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    let key = '';

    try {
      setIsLoading(true);

      let newFilePDF;

      if (file) {
        const { newFileName } = await UploadHelper.upload(file, 'archives');
        newFilePDF = newFileName;

        key = `/archives/${newFileName}`;
      }

      const payload = {
        feedback: form.feedback || currentFeedback,
        testGrade: form.testGrade || testGrade,
        filePDF: encodeURI(newFilePDF) || null,
        fileNamePDF: encodeURI(file.path) || null,
      };

      await ContentUserAPI.taskCorrection.update(moduleId, courseId, contentId, studentId, payload);

      setForm('');

      Toast('Tarefa corrigida com sucesso!');
      onHide();
      fetchTasks(moduleId, courseId);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');

      if (key) {
        await Platform.deleteFileByKey(key);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleDropFilePDF(acceptedFiles) {
    const filePDF = acceptedFiles[FIRST_FILE] || '';
    setFile(filePDF);
  }

  function PreviewPDF() {
    function handleDiscardFile() {
      setFile('');
      setFileCorrection('');
    }

    return (
      <ContainerPreview>
        {fileCorrection ? (
          <span>{fileCorrection || path.basename(decodeURI(fileCorrection))}</span>
        ) : (
          <span>{file?.name || path.basename(decodeURI(file))}</span>
        )}
        <button type="button" onClick={handleDiscardFile}>
          <BsTrash size={18} color="#bb2124" />
        </button>
      </ContainerPreview>
    );
  }

  return (
    <Modal centered size="lg" show={show} onHide={onHide} style={modal}>
      <Modal.Header style={modalHeader} closeButton>
        <ContentHeader>
          <div>
            <img src={fixIcon} alt="ícone de um lápis e três linhas laranjas." />
            <Modal.Title style={modalTitle}>Correção de atividade</Modal.Title>
            <Tooltip content="O comentário e o envio de correção são itens opcionais. A nota só será obrigatória se a opção “Haverá nota” for marcada com um “Sim” na criação do conteúdos." />
          </div>
          <span>{title}</span>
        </ContentHeader>
      </Modal.Header>
      <Modal.Body style={modalBody}>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <FormControl>
              <label>Arquivo enviado pelo aluno:</label>
              <Preview>
                <FileContent>
                  <img src={pdfIcon} alt="Dois quadrados com nome PDF no centro; cor laranja." />
                  <span>{path.basename(decodeURI(fileNameTest))}</span>
                </FileContent>
                <DownloadButton>
                  <a
                    href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download/task?token=${token}&student_id=${studentId}`}
                    download={true}
                  >
                    Download
                  </a>
                </DownloadButton>
              </Preview>
            </FormControl>
            <FormControl>
              <label htmlFor="testGrade">Nota:</label>
              <input
                type="number"
                id="testGrade"
                name="testGrade"
                step="0.01"
                min="0.00"
                minLength="1"
                max="9999.99"
                maxLength="8"
                placeholder="Ex: (8.95)"
                onChange={handleChange}
                value={form.testGrade || testGrade}
              />
            </FormControl>
          </FormGroup>
          <FormGroup>
            <FormControl>
              <label htmlFor="feedback">Comentário:</label>
              <textarea
                name="feedback"
                id="feedback"
                placeholder="Adicione um comentário aqui..."
                value={form.feedback || feedback}
                onChange={handleChange}
                required
              />
            </FormControl>
          </FormGroup>
          <FormGroup>
            <FormControl>
              <label htmlFor="send">Enviar correção:</label>

              {!file && !fileCorrection ? (
                <CustomDropzone
                  file={file}
                  accept="application/pdf"
                  onDrop={handleDropFilePDF}
                  onDropRejected={() => Toast('Arquivo inválido!', 'error')}
                  extensions="PDF"
                  maxFileSize="25MB"
                  isDisabled={isLoading}
                />
              ) : (
                <PreviewPDF />
              )}
            </FormControl>
          </FormGroup>

          <ButtonGroup>
            <button
              className="cancel"
              onClick={event => {
                event.preventDefault();
                onHide();
              }}
            >
              Cancelar
            </button>
            <button type="submit" className="finish">
              {isLoading ? <Spinner /> : 'Finalizar'}
            </button>
          </ButtonGroup>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default FeedbackModal;
