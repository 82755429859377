import { Button, Flex } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

function SelectImage({ isDisabled, onDrop }) {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'],
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  function handleClick(event) {
    event.preventDefault();
    if (isDisabled) return;
    open();
  }

  return (
    <Flex
      {...getRootProps()}
      grow={1}
      align="center"
      justify="center"
      bg="#F6F5F5"
      border="1px dashed  #AEADAD"
      py={10}
      px={2}
    >
      <input {...getInputProps()} />
      <Button
        colorScheme="orange"
        style={{ outline: 'none', boxShadow: 'none' }}
        onClick={handleClick}
        disabled={isDisabled}
        fontFamily="Montserrat"
        size="sm"
      >
        Selecionar Imagem
      </Button>
    </Flex>
  );
}

export default SelectImage;
