import dayjs from 'dayjs';
import { SubscriptionPaymentType } from '../../..';
import convertToBrazilianCurrency from '../../../../../../../helpers/convertToBrazilianCurrency';
import getPaymentMethod from '../../../utils/getPaymentMethod';
import getStatusText from '../../../utils/getStatusText';
import Status from '../SubscriptionsTable/Status';

import {
  Card,
  CardBody,
  CardFooter,
  List,
  ListItem,
  ListProps,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

interface SubscriptionsListMobileProps extends ListProps {
  toggleModal: (pixQrCode: string) => void;
  paymentHistory: SubscriptionPaymentType[];
}

export default function SubscriptionsListMobile({
  paymentHistory,
  toggleModal,
  ...rest
}: SubscriptionsListMobileProps) {
  const textColor = useColorModeValue('#20212380', '#fff');
  const bgColor = useColorModeValue('#fff', '#37393D');

  return (
    <List flexDirection="column" gap={2.5} {...rest}>
      {paymentHistory.map(payment => (
        <ListItem key={payment.courseSubscriptionId}>
          <Card
            boxShadow="0.6699029207229614px 0.6699029207229614px 2.6796116828918457px 0px #00000040"
            background={bgColor}
          >
            <CardBody p={2.5} color={textColor} fontSize="sm">
              <Text>Pagamento: {payment.installment}</Text>
              <Text isTruncated>Nome do plano: {payment.planName}</Text>
              <Text>Tipo: {getPaymentMethod(payment.paymentMethod)}</Text>
              <Text>Data: {dayjs(payment.createdAt).format('DD/MM/YYYY')}</Text>
              <Text>Valor: {convertToBrazilianCurrency(payment.purchasePrice)}</Text>
              <Text>Status: {getStatusText(payment.purchaseStatus)}</Text>
            </CardBody>

            {payment.purchaseStatus === 'waiting_payment' && (
              <CardFooter p={2.5} pt={0} color="#20212380">
                <Status
                  paymentMethod={payment.paymentMethod}
                  paymentStatus={payment.purchaseStatus}
                  link={payment.link}
                  onClick={() => toggleModal(payment.link)}
                />
              </CardFooter>
            )}
          </Card>
        </ListItem>
      ))}
    </List>
  );
}
