import { useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import path from 'path';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IColorPickerChange } from '../../../../components/ColorPickChakra';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../helpers/UploadHelper';
import useHandleChange from '../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../utils/scrollToInvalidInput';
import { Promotion } from '../types';
import { getDate, getTime } from '../utils';
import { promotionSchemaValidator } from './schemaValidator';

interface usePromotionFormProps {
  url: string;
  method: 'post' | 'patch';
  promotion?: Promotion;
  successMessage?: string;
}

export default function usePromotionForm({
  url,
  method,
  promotion,
  successMessage,
}: usePromotionFormProps) {
  const [textHighlight, setTextHighlight] = useState(promotion?.textHighlight || '');
  const [textHighlightLength, setTextHighlightLength] = useState(0);
  const [file, setFile] = useState<File>(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [dateTime, setDateTime] = useState({
    startDate: promotion?.startTime ? getDate(promotion?.startTime) : '',
    startTime: getTime(promotion?.startTime),
    endDate: promotion?.endTime ? getDate(promotion?.endTime) : '',
    endTime: getTime(promotion?.endTime),
  });

  const {
    form,
    onChanged: hasChange,
    setForm,
    handleChange,
    setOnChanged: setHasChange,
  } = useHandleChange<Promotion>({
    title: promotion?.title ?? '',
    description: promotion?.description ?? '',
    primaryColor: promotion?.primaryColor ?? '#eb7129',
    secondaryColor: promotion?.secondaryColor ?? '#ffffff',
    typeMain: promotion?.typeMain ?? 'text',
    textMain: promotion?.textMain ?? '',
    textHighlight: promotion?.textHighlight ?? '',
    startTime: promotion?.startTime ?? '',
    endTime: promotion?.endTime ?? '',
    indicateStartOfPromotion: promotion?.indicateStartOfPromotion ?? false,
    ...(promotion?.typeMain === 'image' && { logoMain: promotion?.logoMain }),
  });

  const history = useHistory();
  const toast = useToast();

  function handleTypeMainChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;

    setHasChange(true);
    setForm({ ...form, [name]: value, logoMain: form.logoMain ?? '' });
  }

  function handleColorChange(props: IColorPickerChange) {
    const { name, value } = props;

    setHasChange(true);
    setForm({ ...form, [name]: value });
  }

  function handleTextHighlightChange(value: string, length: number) {
    setHasChange(true);
    setTextHighlight(value);
    setTextHighlightLength(length);
  }

  function handleDateTimeChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setHasChange(true);
    setDateTime(prevDateTime => ({
      ...prevDateTime,
      [name]: value,
    }));
  }

  async function handleDropFile(acceptedFiles) {
    setHasChange(true);

    try {
      setIsUploading(true);

      const [file] = acceptedFiles;
      const { newFileName } = await UploadHelper.upload(file, 'archives');

      setForm({ ...form, logoMain: newFileName });
      setFile(file);
      setImagePreview(URL.createObjectURL(file));
    } catch (error) {
      toast({ title: ErrorResponse(error), status: 'error' });
    } finally {
      setIsUploading(false);
    }
  }

  function handleDropRejected() {
    toast({
      title: 'Arquivo inválido!',
      description: 'Formatos suportados: JPG, PNG, JPEG',
      status: 'error',
    });
  }

  function handleSelectNewImage() {
    setHasChange(true);
    setForm({ ...form, logoMain: '' });
  }

  const payload = {
    ...form,
    textHighlight,
    startTime: dayjs(`${dateTime.startDate}T${dateTime.startTime}`).utc().format(),
    endTime: dayjs(`${dateTime.endDate}T${dateTime.endTime}`).utc().format(),
    logoMain: form?.logoMain && path.basename(form?.logoMain),
  };

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit({
    data: payload,
    schemaValidator: promotionSchemaValidator,
    url,
    method,
    authenticated: true,
    onSuccess: {
      message: successMessage,
      callback: () => history.push('/promotions'),
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    scrollToInvalidInput();

    if (!isTextHighlightInvalid) {
      await handleSubmit();
    }
  }

  const isTextHighlightInvalid = textHighlightLength > 70;

  return {
    form,
    formValidation,
    isSubmitting,
    isUploading,
    file,
    imagePreview: imagePreview ?? promotion?.logoMain,
    textHighlight,
    dateTime,
    payload,
    hasChange,
    textHighlightLength,
    isTextHighlightInvalid,
    handleChange,
    onSubmit,
    handleSelectNewImage,
    handleDropFile,
    handleDateTimeChange,
    handleTextHighlightChange,
    handleColorChange,
    handleDropRejected,
    handleTypeMainChange,
  };
}
