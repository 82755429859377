import { EXPIRES_THEME_IN_LOCAL_STORAGE, THEME_LOCAL_STORAGE } from '../helpers/LocalStorageHelper';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import ErrorResponse from '../helpers/ErrorResponse';
import Toast from '../components/Toast';
import { axiosBackend } from '../api/Configuration';
import baseTheme from '../theme';
import chroma from 'chroma-js';
import { createGlobalStyle } from 'styled-components';
import dayjs from 'dayjs';
import { extendTheme } from '@chakra-ui/react';
import logo from '../assets/images/logos/proluno_branca.png';

type ColorSchemaType = {
  [key: number]: string;
};

export interface ITheme {
  id?: number;
  primaryColor: string;
  secondaryColor: string;
  accentColor: string;
  textColor: string;
  logo: string;
  themeChakra?: Record<string, any>;
}

export const GlobalStyle = createGlobalStyle`
  body {
    background: #fff;
  }  
`;

export const initialState: ITheme = {
  primaryColor: '#202123',
  secondaryColor: '#CECECE',
  accentColor: '#D36625',
  textColor: '#CECECE',
  logo,
};

export const ThemeContext = createContext(initialState);

function changeHeader(themeData) {
  const favicon = document.getElementById('favicon') as HTMLLinkElement;

  favicon.href = themeData.favicon;

  document.title = themeData.title || 'Proluno';

  const metaThemeColor = document.querySelector('meta[name=theme-color]');
  metaThemeColor.setAttribute('content', themeData.primaryColor);

  const metaDescription = document.querySelector('meta[name=description]');
  metaDescription.setAttribute('content', themeData.description);
}

function generateColorSchema(color: string): ColorSchemaType {
  const colors = chroma.scale(['white', color, 'black']).colors(12);

  colors.shift();
  colors.pop();
  colors[5] = color;

  let weight = 0;

  return colors.reduce((colors, color) => {
    if (weight < 100) weight += 50;
    else weight += 100;

    return { ...colors, [weight]: color };
  }, {});
}

export default function ThemeProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState(initialState);
  const [themeMarketplace, setThemeMarketplace] = useState(initialState);
  const [themeChakra, setThemeChakra] = useState(baseTheme);
  const [isLoadingThemeMarketplace, setIsLoadingThemeMarketplace] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getTheme = useCallback(async () => {
    try {
      localStorage.removeItem(THEME_LOCAL_STORAGE);

      const { data: response } = await axiosBackend().get('/themes');

      const { data: themeData } = response;

      changeHeader(themeData);
      setTheme(themeData);
      setIsLoading(false);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }, []);

  const getThemeMarketplace = useCallback(async () => {
    try {
      setIsLoadingThemeMarketplace(true);

      const themeJSON = localStorage.getItem(THEME_LOCAL_STORAGE);

      let theme = themeJSON ? JSON.parse(themeJSON) : '';

      const expiresthemeInJSON = localStorage.getItem(EXPIRES_THEME_IN_LOCAL_STORAGE);

      let expiresthemeIn = expiresthemeInJSON ? JSON.parse(expiresthemeInJSON) : '';

      const themeExpired = dayjs(expiresthemeIn).isBefore(dayjs());

      if (!Boolean(theme) || !expiresthemeIn || themeExpired) {
        localStorage.removeItem(THEME_LOCAL_STORAGE);
        localStorage.removeItem(EXPIRES_THEME_IN_LOCAL_STORAGE);

        const { data: response } = await axiosBackend().get('/marketplace/themes');

        const { data: themeData } = response;

        theme = themeData;

        if (theme) {
          localStorage.setItem(THEME_LOCAL_STORAGE, JSON.stringify(theme));

          const expiresIn = dayjs().add(5, 'seconds').format('YYYY-MM-DD HH:mm:ss');

          localStorage.setItem(EXPIRES_THEME_IN_LOCAL_STORAGE, JSON.stringify(expiresIn));
        }
      }

      if (theme?.primaryColor && theme?.textColor) {
        const colorSchema = generateColorSchema(theme.primaryColor);
        const secondaryColorSchema = generateColorSchema(theme.textColor);

        setThemeChakra(
          extendTheme({
            ...baseTheme,
            colors: {
              default: {
                50: '#ffedde',
                100: '#fdcdb4',
                200: '#f8ad87',
                300: '#f38e58',
                400: '#EC7117',
                500: '#ee6924',
                600: '#d55411',
                700: '#a7400b',
                800: '#772d06',
                900: '#491a01',
              },
              primary: {
                ...colorSchema,
              },
              secondary: {
                ...secondaryColorSchema,
              },
              textColor: theme.textColor,
            },
          })
        );

        setThemeMarketplace(theme);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingThemeMarketplace(false);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname !== '/not-found') {
      getThemeMarketplace();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateSectionsOrder(theme_id, payload) {
    try {
      await axiosBackend().patch(`themes/${theme_id}/sections`, payload);
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
      throw error;
    }
  }

  async function updateColors(theme_id, primaryColor, textColor) {
    setIsLoading(true);

    try {
      await axiosBackend().patch(`/themes/${theme_id}/section-colors`, {
        primaryColor,
        textColor,
      });

      setTheme(prevTheme => {
        return { ...prevTheme, primaryColor, textColor };
      });

      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  async function updateHeader(theme_id, menus, logo) {
    setIsLoading(true);

    const menusObject = menus.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.active }), {});

    const payload = {
      ...menusObject,
      logo,
    };

    const formData = new FormData();
    Object.keys(payload).forEach(key => formData.append(key, JSON.stringify(payload[key])));
    formData.delete('logo');

    if (typeof payload.logo === 'object') formData.append('logo', payload.logo);

    try {
      await axiosBackend().patch(`/themes/${theme_id}/section-header`, formData);
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteBannerHero(theme_id, banner_id) {
    try {
      await axiosBackend().delete(`/themes/${theme_id}/theme/${banner_id}/banner-hero`);
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  async function createCategorySection(theme_id, payload) {
    try {
      await axiosBackend().post(`themes/${theme_id}/categories`, payload);
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  async function updateCategorySection(
    theme_id,
    theme_section_category_id,
    theme_category_id,
    payload
  ) {
    try {
      await axiosBackend().patch(
        `/themes/${theme_id}/theme/${theme_section_category_id}/section-category/${theme_category_id}/category`,
        payload
      );
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  async function deleteCategorySection(theme_id, section_id, category_id) {
    try {
      await axiosBackend().delete(
        `/themes/${theme_id}/theme/${section_id}/section/${category_id}/category`
      );
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  async function updateProductsSection(theme_id: string, payload) {
    try {
      await axiosBackend().patch(`/themes/${theme_id}/theme/products`, payload);
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  async function deleteMedia(theme_id, theme_section_multimedia_id, multimedia_id) {
    try {
      await axiosBackend().delete(
        `/themes/${theme_id}/theme/${theme_section_multimedia_id}/section-multimedia/${multimedia_id}/multimedia`
      );
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  async function updateFooter(theme_id: string, footer_id: string, payload) {
    try {
      await axiosBackend().patch(`/themes/${theme_id}/theme/${footer_id}/footer`, payload);
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  async function createHTMLSection(theme_id: number, payload: { html: string; style: string }) {
    try {
      await axiosBackend().post(`/themes/${theme_id}/html`, payload);
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  async function patchHTMLSection(
    theme_section_id: number,
    payload: { html: string; style: string; activeSection: boolean }
  ) {
    try {
      await axiosBackend().patch(`/themes/${theme_section_id}/html`, payload);
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  async function duplicateHTMLSection(theme_section_id: number) {
    try {
      await axiosBackend().post(`/themes/${theme_section_id}/html/duplicate`);
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  async function deleteHTMLSection(theme_section_id: number) {
    try {
      await axiosBackend().delete(`/themes/${theme_section_id}/html`);
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      throw error;
    }
  }

  const platformTheme = {
    theme,
    themeMarketplace,
    isLoadingThemeMarketplace,
    isLoading,
    errorMessage,
    getTheme,
    getThemeMarketplace,
    setErrorMessage,
    updateSectionsOrder,
    updateColors,
    updateHeader,
    deleteBannerHero,
    createCategorySection,
    updateCategorySection,
    deleteCategorySection,
    updateProductsSection,
    deleteMedia,
    updateFooter,
    createHTMLSection,
    patchHTMLSection,
    duplicateHTMLSection,
    deleteHTMLSection,
    themeChakra,
  };

  return (
    <ThemeContext.Provider value={{ ...platformTheme } as any}>{children}</ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext) as any;

  if (!context) throw new Error('useTheme deve ser usado dentro de um ThemeContext.Provider.');

  return {
    theme: context.theme,
    themeMarketplace: context.themeMarketplace,
    isLoadingThemeMarketplace: context.isLoadingThemeMarketplace,
    isLoading: context.isLoading,
    errorMessage: context.errorMessage,
    getTheme: context.getTheme,
    updateSectionsOrder: context.updateSectionsOrder,
    getThemeMarketplace: context.getThemeMarketplace,
    updateColors: context.updateColors,
    updateHeader: context.updateHeader,
    createBannerHero: context.createBannerHero,
    updateBannerHero: context.updateBannerHero,
    deleteBannerHero: context.deleteBannerHero,
    createCategorySection: context.createCategorySection,
    updateCategorySection: context.updateCategorySection,
    deleteCategorySection: context.deleteCategorySection,
    updateProductsSection: context.updateProductsSection,
    updateFooter: context.updateFooter,
    deleteMedia: context.deleteMedia,
    setErrorMessage: context.setErrorMessage,
    createHTMLSection: context.createHTMLSection,
    patchHTMLSection: context.patchHTMLSection,
    duplicateHTMLSection: context.duplicateHTMLSection,
    deleteHTMLSection: context.deleteHTMLSection,
  };
}
