import { Input, InputProps } from '@chakra-ui/react';
import { useState } from 'react';
import InputMask from 'react-input-mask';

type PhoneInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (value: string) => void;
};

const LANDLINE_MASK = '9999-9999';
const CELLPHONE_MASK = '9 9999-9999';

function unmask(value: string) {
  return value.replace(/\D/g, '');
}

function getMask(value: string) {
  const unmaskedValue = unmask(value);
  const firstDigit = unmaskedValue.charAt(0);

  if (firstDigit === '9') {
    return CELLPHONE_MASK;
  }
  return LANDLINE_MASK;
}

export default function PhoneInput(props: PhoneInputProps) {
  const [mask, setMask] = useState(getMask((props.value as string) || ''));

  const { value, onChange, ...rest } = props;

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.value;
    const unmaskedValue = unmask(inputValue + event.key);
    const newMask = getMask(unmaskedValue);
    if (newMask !== mask) {
      setMask(newMask);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.target.value;
    const unmaskedValue = unmask(inputValue);
    const newMask = getMask(unmaskedValue);
    if (newMask !== mask) {
      setMask(newMask);
    }
    onChange?.(unmaskedValue);
  }

  function handlePaste(event: React.ClipboardEvent<HTMLInputElement>) {
    const pasteValue = event.clipboardData.getData('Text');
    const unmaskedValue = unmask(pasteValue);
    const newMask = getMask(unmaskedValue);
    if (newMask !== mask) {
      setMask(newMask);
    }
  }

  return (
    <InputMask
      mask={mask}
      maskChar=""
      value={value}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onPaste={handlePaste}
      autoComplete="new-phone-number"
    >
      {(inputProps: InputProps) => (
        <Input placeholder="Digite o telefone" autoComplete="off" {...rest} {...inputProps} />
      )}
    </InputMask>
  );
}
