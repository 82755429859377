import { Box, Flex, Image, MenuItem } from '@chakra-ui/react';
import { memo } from 'react';

interface CountryProps {
  country: {
    country: string;
    initials: string;
    flag: string;
  };
  onClick?: (country: string) => void;
}

const Country = memo(({ country, onClick }: CountryProps) => {
  function handleClick() {
    onClick(country.initials);
  }

  return (
    <MenuItem onClick={handleClick} _hover={{ backgroundColor: '#f6f5f5' }}>
      <Flex align="center">
        {country.flag ? (
          <Image
            src={country?.flag}
            w="28px"
            h="18px"
            mr={2}
            alt={country.country}
            borderRadius="base"
            objectFit="cover"
          />
        ) : (
          <Box bg="#f6f5f5" w="28px" h="18px" mr={2} borderRadius="base" />
        )}

        {country.country}
      </Flex>
    </MenuItem>
  );
});

export default Country;
