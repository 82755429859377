import { Flex, Heading, HStack, IconButton } from '@chakra-ui/react';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import PromotionsForm from '../PromotionsForm';

export default function NewPromotion() {
  return (
    <>
      <Flex justify="space-between" w="full" wrap="wrap">
        <HStack spacing={{ base: 0.25, xl: 0.5 }} ml={{ base: -2, xl: 0 }}>
          <IconButton
            as={Link}
            to={'/promotions'}
            icon={<ChevronLeftIcon size="1.75rem" />}
            aria-label="Voltar"
            variant="ghost"
            colorScheme="gray"
            size="sm"
          />

          <Heading as="h1" fontSize={{ base: 'lg', lg: '2xl' }} fontWeight="semibold" m={0}>
            Nova Promoção
          </Heading>
        </HStack>
      </Flex>

      <PromotionsForm
        url="/promotion/create"
        method="post"
        mt={10}
        successMessage="Promoção criada com sucesso!"
      />
    </>
  );
}
