import Joi from 'joi';
import { Promotion } from '../types';

export const promotionSchemaValidator = Joi.object<Promotion>().keys({
  title: Joi.string().min(3).required().messages({
    'string.empty': 'Título não pode ficar vazio.',
  }),
  description: Joi.string().min(3).allow('').messages({
    'string.min': 'Descrição deve ter no mínimo 3 caracteres.',
  }),
  primaryColor: Joi.string()
    .pattern(/^#[0-9a-fA-F]{6}$/)
    .required()
    .messages({
      'string.empty': 'Cor primária não pode ficar vazia.',
      'string.pattern.base':
        'Deve ser um código hexadecimal de 6 caracteres, por exemplo, #EB7129.',
    }),
  secondaryColor: Joi.string()
    .pattern(/^#[0-9a-fA-F]{6}$/)
    .required()
    .messages({
      'string.empty': 'Cor secundária não pode ficar vazia.',
      'string.pattern.base':
        'Deve ser um código hexadecimal de 6 caracteres, por exemplo, #EB7129.',
    }),
  typeMain: Joi.string().valid('text', 'image').required(),
  logoMain: Joi.when('typeMain', {
    is: 'image',
    then: Joi.string().required().messages({
      'string.empty': 'Selecione uma imagem antes de continuar.',
    }),
    otherwise: Joi.string().allow(''),
  }),
  textMain: Joi.when('typeMain', {
    is: 'text',
    then: Joi.string().max(35).required().messages({
      'string.max': 'Deve ter no máximo 35 caracteres.',
      'string.empty': 'Texto principal não pode ficar vazio.',
    }),
    otherwise: Joi.string().allow(''),
  }),
  textHighlight: Joi.string()
    .required()
    .custom((value, helpers) => {
      // Verifica se a string contém apenas tags HTML vazias ou espaços em branco
      const cleanHtml = value.replace(/<\/?[^>]+(>|$)/g, ''); // Remove tags HTML
      if (/^\s*$/.test(cleanHtml)) {
        return helpers.error('string.empty');
      }
      return value;
    })
    .messages({
      'string.empty': 'Texto de destaque não pode ficar vazio.',
    }),
  startTime: Joi.date().iso().required().messages({
    'date.format': 'Data de início não pode ficar vazia.',
  }),
  endTime: Joi.date().iso().greater(Joi.ref('startTime')).required().messages({
    'date.greater': 'A data de término deve ser posterior à data de início.',
    'date.format': 'Data de término não pode ficar vazia.',
  }),
  indicateStartOfPromotion: Joi.boolean().default(false).required(),
});
