import { Avatar, Box, HStack, Stack, Text } from '@chakra-ui/react';
import { ITask } from '..';
import FileDownloadCorrection from '../../../../../../components/FileDownloadCorrection';
import { IContent } from '../../types';
import Status from '../Status';

interface IFeedbackProps {
  task: ITask;
  content: IContent;
  highlightColor: string;
}

function Feedback({ task, content, highlightColor }: IFeedbackProps) {
  return (
    <Box>
      {(task?.feedback || task?.fileNameTestCorrection) && (
        <Stack spacing="13px">
          <HStack width="full" alignItems="center" justifyContent="space-between">
            <Text color="#FFF" fontSize={{ base: '18px', lg: '24px' }} fontWeight="500">
              Correção realizada:
            </Text>

            <Status task={task} content={content} />
          </HStack>

          <Text hidden={!task?.feedback} color="#FFF">
            {task?.feedback}
          </Text>

          {task?.fileNameTestCorrection && (
            <FileDownloadCorrection
              name={task?.fileNameTestCorrection}
              highlightColor={highlightColor}
            />
          )}

          <HStack display="flex" alignItems="center" alignContent="center">
            <Avatar
              name={task?.instructorName}
              bgColor={highlightColor}
              src={task?.instructorPhoto}
            />
            <Stack spacing={0}>
              <Text color="#FFF">{task?.instructorName}</Text>
              <Text color={highlightColor}>Instrutor(a)</Text>
            </Stack>
          </HStack>
        </Stack>
      )}
    </Box>
  );
}

export default Feedback;
