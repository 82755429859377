import 'react-date-range/dist/styles.css';
import './styles.css';

import { Button, Menu, MenuButton, MenuList, useDisclosure } from '@chakra-ui/react';
import { ptBR } from 'date-fns/locale';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DateRangePicker as ReactDateRangePicker } from 'react-date-range';
import { IoChevronDown as ChevronDownIcon, IoChevronUp as ChevronUpIcon } from 'react-icons/io5';
import useDateRangePickerCustomStyles from './useDateRangePickerCustomStyles';

type GetRangeTextsType = {
  selection: {
    startDate: Date;
    endDate: Date;
  };
};

function formatDate(date: Date | string) {
  return dayjs(date).format('YYYY-MM-DD');
}

function getRangeText(ranges: GetRangeTextsType) {
  const startDate = dayjs(ranges.selection.startDate)?.format('DD/MM/YYYY');
  const endDate = dayjs(ranges.selection.endDate)?.format('DD/MM/YYYY');

  if (startDate === endDate) {
    return startDate;
  }

  return `${startDate} - ${endDate}` || 'Selecionar a data';
}

interface DateRangePickerProps {
  startDate?: string;
  endDate?: string;
  onChange: (startDate: string, endDate: string) => void;
}

function getInitialState(startDate: string, endDate: string) {
  return [
    {
      startDate: startDate && dayjs(startDate).toDate(),
      endDate: endDate && dayjs(endDate).toDate(),
      key: 'selection',
    },
  ];
}

export default function DateRangePicker({ startDate, endDate, onChange }: DateRangePickerProps) {
  const [state, setState] = useState(getInitialState(startDate, endDate));
  const [rangeText, setRangeText] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  useDateRangePickerCustomStyles();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (startDate && endDate) {
        setRangeText(
          getRangeText({
            selection: { startDate: dayjs(startDate).toDate(), endDate: dayjs(endDate).toDate() },
          })
        );
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [startDate, endDate]);

  useEffect(() => {
    setState([
      {
        startDate: startDate && dayjs(startDate).toDate(),
        endDate: endDate && dayjs(endDate).toDate(),
        key: 'selection',
      },
    ]);
  }, [startDate, endDate]);

  function handleDateRangePickerChange(ranges) {
    setState([ranges.selection]);
    setRangeText(getRangeText(ranges));
    onChange(formatDate(ranges.selection.startDate), formatDate(ranges.selection.endDate));
  }

  return (
    <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <Button
        as={MenuButton}
        variant="link"
        textDecoration="underline"
        color="#AFAFAF"
        size="xs"
        fontWeight="normal"
        alignSelf="end"
        mr={2}
        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        _active={{ color: '#AFAFAF' }}
        sx={{ '.chakra-button__icon': { ml: '3.5px' } }}
      >
        {rangeText || 'Selecionar data'}
      </Button>

      <MenuList boxShadow="0px 4px 4px 0px #00000040" p={0} overflow="hidden" position="relative">
        <ReactDateRangePicker
          onChange={handleDateRangePickerChange}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          direction="horizontal"
          showDateDisplay={false}
          locale={ptBR}
          weekdayDisplayFormat="EEEEE"
          monthDisplayFormat="MMMM yyyy"
          rangeColors={['#ec7117']}
          color="#ec7117"
        />
      </MenuList>
    </Menu>
  );
}
