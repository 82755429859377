import { Box, Button, HStack, Link, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import path from 'path';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import { MdDownload as DownloadIcon } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { scrollbarStyle } from '..';
import { baseURLBackend } from '../../../../../api/Configuration';
import { ContentUserAPI } from '../../../../../api/ContentUser';
import CourseAPI from '../../../../../api/Course';
import Platform from '../../../../../api/Platform';
import { ReactComponent as PdfIcon } from '../../../../../assets/icons/pdfIcon.svg';
import CustomDropzone from '../../../../../components/CustomDropzone';
import DataLoading from '../../../../../components/Loadings/DataLoading';
import Toast from '../../../../../components/Toast';
import { useStudentCourse } from '../../../../../contexts/CourseStudentContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE, TOKEN_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import UploadHelper from '../../../../../helpers/UploadHelper';
import styles from './styles.module.css';

const initialValues = {
  answerFile: '',
  answerFileName: '',
};

const initialContentUser = {
  instructorName: '',
  instructorPhoto: '',
  content: '',
  contentId: 0,
  feedback: '',
  fileNameTest: '',
  fileNameTestCorrection: '',
  fileTest: '',
  fileTestCorrection: '',
  id: 0,
  moduleId: 0,
  moduleName: '',
  status: 'ATIVO',
  studentName: '',
  testGrade: null,
};

const FIRST_FILE = 0;
const MAX_LENGTH_FILE_DOWNLOAD = 52428800;

function Task({ content, contentUser, setContentUser, params: { contentId, moduleId, courseId } }) {
  const [values, setValues] = useState(initialValues);
  const [filePDF, setFilePDF] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const errorMessageRef = useRef(null);
  const history = useHistory();

  function PreviewPDFTask({ filePDF, setFilePDF, values, setValues }) {
    function handleDiscardFilePDF() {
      setFilePDF(null);
      setValues({
        ...values,
        answerFile: null,
        answerFileName: null,
      });
    }

    return (
      <div className="d-flex align-center justify-content-between mt-2">
        <Text wordBreak="break-all">
          {filePDF?.name || path.basename(decodeURI(values.filePDF))}
        </Text>

        <div>
          <div>
            <button
              onClick={handleDiscardFilePDF}
              type="button"
              className="btn btn-outline-primary"
            >
              <BsTrash />
            </button>
          </div>
        </div>
      </div>
    );
  }

  function handleDropFilePDF(acceptedFiles) {
    setErrorMessage('');

    const filePDF = acceptedFiles[FIRST_FILE] || '';
    setFilePDF(filePDF);

    setValues({
      ...values,
      answerFile: filePDF,
    });

    setChanged(true);
  }

  function handleFocus() {
    errorMessageRef.current &&
      errorMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setErrorMessage('');

    setChanged(true);

    if (filePDF && !filePDF) {
      return setErrorMessage('O arquivo é obrigatório para este tipo de conteúdo.');
    }

    if (filePDF && filePDF.size > MAX_LENGTH_FILE_DOWNLOAD) {
      return setErrorMessage('O tamanho máximo do arquivo deve ser igual ou inferior a 50 MB.');
    }

    if (!values.answerFile) {
      handleFocus();
      return setErrorMessage('Por favor, preencha os campos!');
    }

    setLoading(true);

    let key = '';

    try {
      if (filePDF) {
        const { newFileName, link } = await UploadHelper.upload(filePDF, 'archives');

        key = `/archives/${newFileName}`;

        const payload = {
          ...values,
          answerFileName: encodeURI(filePDF.path),
          answerFile: encodeURI(newFileName),
        };

        await ContentUserAPI.store(contentId, moduleId, courseId, payload);

        setFilePDF(link);

        setContentUser({
          ...contentUser,
          fileTest: newFileName,
          fileNameTest: newFileName,
        });

        setValues({ answerFile: null });
      }

      setLoading(false);

      Toast(`Conteúdo Enviado com sucesso`);
      setChanged(false);

      history.push(
        `/members-area/v1/products/${courseId}/content-view/module/${moduleId}/content/${contentId}/view-task`
      );
    } catch (error) {
      handleFocus();
      Toast(ErrorResponse(error), 'error');

      if (key) {
        await Platform.deleteFileByKey(key);
      }
    } finally {
      setLoading(false);
    }
  }

  function status() {
    if (!contentUser?.fileTest) {
      return (
        <div className="align-items-center">
          <p className="fs-5 pr-2 pl-2 ">
            Enviar até:{' '}
            {content?.deliveryDate ? dayjs(content?.deliveryDate).format('DD/MM/YYYY') : '-'}
          </p>
        </div>
      );
    }

    if (contentUser?.fileTest) {
      if (contentUser?.fileTestCorrection || contentUser?.testGrade) {
        return (
          <div className="align-items-center">
            <p className="fs-5 pr-2 pl-2 text-white bg-success rounded-pill">Corrigida</p>
          </div>
        );
      }

      return (
        <div className="align-items-center">
          <p className="fs-5 pr-2 pl-2 text-white bg-warning rounded-pill">Aguardando Correção</p>
        </div>
      );
    }
  }

  return (
    <div className={styles.div_view_content}>
      <div className={styles.view_content}>
        <div className="d-flex justify-content-between">
          <h3 style={{ fontSize: '30px' }}>{content?.title}</h3>
          {status()}
        </div>

        <Box mt={4} className="ql-editor">
          <>{parse(String(content?.content))}</>
        </Box>
        <>
          {content?.download && (
            <HStack
              justify="space-between"
              border="2px solid #8091a7"
              borderRadius="8px"
              width="100%"
              marginRight="20px"
              p={4}
              spacing={4}
            >
              <Text wordBreak="break-all" noOfLines={2}>
                {path.basename(decodeURI(content?.download))}
              </Text>

              <Button
                as={Link}
                href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${localStorage.getItem(
                  TOKEN_LOCAL_STORAGE
                )}`}
                target="_blank"
                rel="noreferrer"
                leftIcon={<DownloadIcon />}
                flexShrink={0}
                colorScheme="orange"
                variant="outline"
                download
              >
                Download
              </Button>
            </HStack>
          )}

          <form onSubmit={handleSubmit}>
            <div className="my-4">
              <div className="d-flex justify-content-between">
                <h5>Enviar para correção:</h5>
                <h5 className="fs-4">
                  Nota: {`${contentUser?.testGrade !== null ? contentUser?.testGrade : '__.__'}`}
                </h5>
              </div>

              {contentUser?.fileTest && (
                <div className={styles.div_send_correction}>
                  <div className="ml-1 mr-2">
                    <PdfIcon fill="#202123" />
                  </div>
                  <div className={styles.div_show_file_correction}>
                    <Text wordBreak="break-all">
                      {path.basename(decodeURI(contentUser?.fileNameTest))}
                    </Text>
                  </div>
                </div>
              )}

              {!values.answerFile && !contentUser.fileTest && (
                <CustomDropzone
                  filePDF={filePDF}
                  isDisabled={loading}
                  accept="application/pdf"
                  onDrop={handleDropFilePDF}
                  onDropRejected={() => setErrorMessage('Arquivo inválido!')}
                  extensions="PDF"
                  maxFileSize="25 MB"
                />
              )}

              {values.answerFile && (
                <PreviewPDFTask
                  filePDF={filePDF}
                  setFilePDF={setFilePDF}
                  values={values}
                  setValues={setValues}
                />
              )}

              <DataLoading loading={loading} className="d-flex justify-content-center mt-4">
                {errorMessage && !loading && (
                  <div className="mt-4">
                    <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
                      <div className="text-center">{errorMessage}</div>
                    </div>
                  </div>
                )}
              </DataLoading>
            </div>

            {!contentUser.fileTest && (
              <div className="d-flex flex-row-reverse">
                <button type="submit" className="btn btn-primary ml-4" disabled={!changed}>
                  Enviar
                </button>
              </div>
            )}
          </form>

          {(contentUser?.feedback || contentUser?.fileNameTestCorrection) && (
            <div className={styles.div_correction_comment}>
              <div className="p-3 w-100">
                <div>
                  <h5>Correção do professor:</h5>
                </div>

                <div className="mt-3">
                  <p style={{ whiteSpace: 'pre-wrap' }}>{contentUser?.feedback}</p>
                </div>

                {contentUser?.fileNameTestCorrection && (
                  <HStack
                    justify="space-between"
                    border="2px solid #8091a7"
                    borderRadius="8px"
                    width="100%"
                    marginRight="20px"
                    p={4}
                    spacing={4}
                  >
                    <Text wordBreak="break-all" noOfLines={2}>
                      {path.basename(decodeURI(contentUser?.fileTestCorrection))}
                    </Text>

                    <Button
                      as={Link}
                      href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download/correction?token=${localStorage.getItem(
                        TOKEN_LOCAL_STORAGE
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                      leftIcon={<DownloadIcon />}
                      flexShrink={0}
                      colorScheme="orange"
                      variant="outline"
                      download
                    >
                      Download
                    </Button>
                  </HStack>
                )}

                <div className="mt-4 d-flex align-content-center align-items-center">
                  <span className="mr-2">
                    {!contentUser?.instructorPhoto ? (
                      <div className={styles.defaultImage}>
                        <h6 className="text-uppercase text-white">
                          {contentUser?.instructorName?.substring(0, 1)}
                        </h6>
                      </div>
                    ) : (
                      <Image
                        src={contentUser?.instructorPhoto}
                        height="40"
                        width="40"
                        roundedCircle
                      />
                    )}
                  </span>
                  <span>{contentUser?.instructorName}</span>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

function ViewTask() {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const { courseId, moduleId, contentId } = useParams();

  const { studentCourse, getContent } = useStudentCourse();
  const [content, setContent] = useState();
  const [contentUser, setContentUser] = useState({ ...content, ...initialContentUser });

  useEffect(() => {
    if (studentCourse) {
      const currentContent = getContent(studentCourse, moduleId, contentId);
      if (currentContent) {
        setContent(currentContent);
      }
    }
  }, [contentId, getContent, moduleId, studentCourse]);

  const updateLastVisit = useCallback(async () => {
    try {
      await CourseAPI.updateLastVisit(courseId, moduleId, contentId, 'TAREFA');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }, [contentId, courseId, moduleId]);

  useEffect(() => {
    if (role === 'ALUNO' && studentCourse) {
      updateLastVisit();
    }
  }, [updateLastVisit, role, studentCourse]);

  useEffect(() => {
    async function getTask() {
      if (content) {
        try {
          const { data: contentData } = await ContentUserAPI.show(contentId, moduleId, courseId);

          if (contentData) {
            setContentUser(contentData);
          } else {
            setContentUser(initialContentUser);
          }
        } catch (error) {
          Toast(ErrorResponse(error), 'error');
        }
      }
    }

    getTask();
  }, [content, contentId, courseId, moduleId]);

  return (
    <Box
      w="full"
      overflow="auto"
      sx={scrollbarStyle}
      margin={{ base: '0', md: '20px 70px', lg: '20px 70px' }}
    >
      <section className={styles.section__download}>
        <div className="d-flex justify-content-md-center h-100">
          <div className="col-sm-12 col-md-10 col-lg-10 p-0">
            <div className={styles.container_view_text}>
              <div className="card shadow-sm pb-4">
                {content?.title}
                {content && (
                  <Task
                    content={content}
                    contentUser={contentUser}
                    setContentUser={setContentUser}
                    params={{ contentId, moduleId, courseId }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Box>
  );
}

export default ViewTask;
