import { Button, Stack, Text } from '@chakra-ui/react';
import { CSSProperties, MouseEvent, useMemo } from 'react';

import { useDropzone } from 'react-dropzone';

interface IProps {
  onChangeImage: () => void;
  isDisabled: boolean;
  imageDimensions: string;
  onDrop: (acceptedFiles: File[]) => void;
  onDropRejected: () => void;
}

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1.25rem',
  borderWidth: 2,
  borderColor: '#AEADAD',
  borderStyle: 'dashed',
  backgroundColor: '#f6f5f5',
  outline: 'none',
  transition: 'all .24s ease-in-out',
};

const acceptStyle: CSSProperties = {
  borderColor: '#19bc90',
  backgroundColor: '#e4fbf5',
};

const rejectStyle: CSSProperties = {
  borderColor: '#c3463c',
  backgroundColor: '#fceae9',
  borderStyle: 'solid',
  color: '#c3463c',
};

function ImageInput({ isDisabled, imageDimensions, onDrop, onDropRejected }: IProps) {
  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    accept: ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'],
    noClick: true,
    noKeyboard: true,
    onDrop,
    onDropRejected,
  });

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (isDisabled) return;
    open();
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragReject, isDragAccept]
  );

  return (
    <Stack {...getRootProps({ style })}>
      <input {...getInputProps()} />

      <Text textAlign="center" color="primary.500">
        Arraste e solte um arquivo aqui ou
      </Text>

      <Button
        onClick={handleClick}
        colorScheme="primary"
        color="secondary.500"
        size="sm"
        disabled={isDisabled}
      >
        Selecionar arquivo
      </Button>

      <Text textAlign="center" color="primary.500">
        Extensões válidas: jpg, png ou jpeg. <br /> Dimensões: {imageDimensions}. <br />
      </Text>
    </Stack>
  );
}

export default ImageInput;
