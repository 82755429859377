import Joi from 'joi';
import { UTMData } from '../../../contexts/UTMContext';
import { CreditCardType, IPayment } from './types';

const creditCardSchema = Joi.object<Partial<CreditCardType>>().keys({
  cardNumber: Joi.string().regex(/^\d+$/).required().messages({
    'string.empty': 'Digite o número do cartão para continuar.',
    'string.pattern.base': 'Número de cartão inválido',
  }),
  cardHolderName: Joi.string()
    .required()
    .messages({ 'string.empty': 'Digite o titular do cartão para continuar.' }),
  cardExpirationMonth: Joi.string().required().messages({
    'string.empty': 'Selecione o mês para continuar.',
  }),
  cardExpirationYear: Joi.string().required().messages({
    'string.empty': 'Selecione o ano para continuar.',
  }),
  cardCVV: Joi.string()
    .regex(/^\d{3,4}$/)
    .required()
    .messages({
      'string.empty': 'Digite o CVV para continuar',
      'string.pattern.base': 'CVV inválido',
    }),
});

const schema = Joi.object<IPayment>().keys({
  idRecurrencePlan: Joi.number().positive().required(),
  paymentMethod: Joi.string().valid('credit_card').required(),
  useSavedCreditCard: Joi.boolean(),
  saveDataForFuturePurchases: Joi.boolean(),
  creditCard: creditCardSchema.required(),
  UTMData: Joi.object<UTMData>().keys({
    utm_id: Joi.string().required().allow(null),
    utm_source: Joi.string().required().allow(null),
    utm_medium: Joi.string().required().allow(null),
    utm_campaign: Joi.string().required().allow(null),
    utm_term: Joi.string().required().allow(null),
    utm_content: Joi.string().required().allow(null),
  }),
});

export default schema;
