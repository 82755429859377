import { Button, ButtonProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function MoreInformationsButton(props: ButtonProps) {
  return (
    <Button
      as={Link}
      to="/dashboard"
      variant="ghost"
      colorScheme="orange"
      bg="white"
      size="sm"
      pt={1.5}
      border="none"
      textDecoration="underline"
      _hover={{ textDecoration: 'underline', bg: 'orange.50' }}
      {...props}
    >
      Mais informações
    </Button>
  );
}

export default MoreInformationsButton;
