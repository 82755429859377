import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  IconButton,
  List,
  ListItem,
  ListProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';

import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { InstructorView } from '../types';
import Disciplines from './Disciplines';

interface InstructorsMobileListProps extends ListProps {
  instructors: InstructorView[];
  enableOrDisableInstructor(instructorId: number): void;
}

export default function InstructorsMobileList({
  instructors: promotions,
  enableOrDisableInstructor,
  ...rest
}: InstructorsMobileListProps) {
  return (
    <List {...rest} spacing={2.5}>
      {promotions.map(instructor => {
        const isActive = instructor.status === 'ATIVO';

        return (
          <Card
            key={instructor.id}
            as={ListItem}
            border="1px solid #2021230D"
            boxShadow="0px 4px 4px 0px #00000040"
          >
            <CardHeader p={4} pb={0}>
              <HStack justify="space-between" align="start">
                <VStack spacing={1} align="start">
                  <Heading
                    as="h2"
                    fontSize="md"
                    fontWeight="medium"
                    color="#202123"
                    m={0}
                    isTruncated
                  >
                    {instructor.fullName}
                  </Heading>

                  <Text color="gray.700">{instructor.email}</Text>
                </VStack>

                <Menu size="sm" arrowPadding={0}>
                  <MenuButton
                    size="xs"
                    as={IconButton}
                    icon={<MoreIcon size="0.75rem" />}
                    w="1.125rem"
                    h="1rem"
                    minW="unset"
                    borderRadius={5}
                    bgColor="#2021231F"
                    _hover={{ bgColor: '#b6b5b5' }}
                    _active={{ bgColor: '#b6b5b5' }}
                  />

                  <MenuList
                    sx={{
                      '.chakra-menu__menuitem': {
                        fontWeight: 'medium',
                        fontSize: 'xs',
                        color: '#20212380',
                        _hover: { bgColor: '#2021230D' },
                        _focus: { bgColor: '#2021230D' },
                      },
                    }}
                  >
                    <MenuItem onClick={() => enableOrDisableInstructor(instructor.id)}>
                      {isActive ? 'Inativar' : 'Ativar'}
                    </MenuItem>

                    <MenuItem as={Link} to={`/promotions/edit/${instructor.id}`}>
                      Editar
                    </MenuItem>

                    <MenuItem as={Link} to={`/internal-profile/${instructor.id}/instructor`}>
                      Visualizar página de instrutor
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </CardHeader>

            <CardBody
              mt={6}
              p={4}
              pt={0}
              color="#20212380"
              sx={{ strong: { fontWeight: 'semibold' } }}
            >
              <List spacing={2}>
                <HStack as={ListItem} justifyContent="space-between">
                  <Text as="strong">Telefone:</Text>
                  <Text>{instructor.phone ?? '-'}</Text>
                </HStack>

                <VStack as={ListItem} align="start">
                  <Text as="strong">Disciplinas:</Text>

                  <Disciplines instructorId={instructor.id} disciplines={instructor.disciplines} />
                </VStack>

                <HStack as={ListItem} justifyContent="space-between">
                  <Text as="strong">Status:</Text>
                  <Badge colorScheme={isActive ? 'green' : 'red'}>{instructor.status}</Badge>
                </HStack>
              </List>
            </CardBody>
          </Card>
        );
      })}
    </List>
  );
}
