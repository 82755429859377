import { Button, Flex } from '@chakra-ui/react';
import process from 'process/browser';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiPlus as Plus } from 'react-icons/fi';
import { HiDotsVertical as DotsVertical } from 'react-icons/hi';
import { MdBusinessCenter as Business, MdContentCopy } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import PlansApi from '../../../api/Plan';
import { Heading } from '../../../components/Heading';
import DotsFalling from '../../../components/Loadings/DotsFalling';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';
import Badge from './Badge';
import stylesPlans from './styles.module.css';

const PlansInternalManager = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const [message, setMessage] = useState('');

  useEffect(() => {
    async function getPlans() {
      try {
        const { data: plansData } = await PlansApi.internalManager.index('ATIVO');
        setPlans(plansData);
      } catch (err) {
        setMessage(ErrorResponse(err, history));
      } finally {
        setLoading(false);
      }
    }

    getPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCopy(text, result) {
    if (result) Toast(`Link copiado com sucesso`);
  }

  return (
    <>
      <Flex as="header" justifyContent="space-between">
        <Heading>Planos</Heading>
        <Button colorScheme="primary" leftIcon={<Plus size={22} color="#ffffff" />}>
          <Link to="/internal-manager/plans/new">Criar plano</Link>
        </Button>
      </Flex>
      {loading && !message ? (
        <div className="container d-flex justify-content-center mt-5">
          <DotsFalling color="white" />
        </div>
      ) : plans.length ? (
        <div className="table-responsive">
          <table className={`mt-5 ${stylesPlans.table__plans}`}>
            <thead>
              <tr>
                <th>Nome do plano</th>
                <th>Assinantes</th>
                <th>Homologado por:</th>
                <th>Homologação</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {plans.map(plan => (
                <tr key={plan.id}>
                  <td>
                    {plan.name} / {plan.recurrence}
                  </td>
                  <td>{plan.planSubscriptions}</td>
                  <td>{plan.homologatedBy || '-'}</td>
                  <td>{plan.homologation ? 'Homologado' : 'Aguardando'}</td>
                  <td>
                    <Badge status={plan.status}>{plan.status}</Badge>
                  </td>
                  <td className={stylesPlans.td__group}>
                    <div className="btn-group">
                      <Link
                        to={`/internal-manager/plans/${plan.id}/edit`}
                        className="btn btn-outline-gray border border-gray"
                        role="button"
                      >
                        Editar
                      </Link>
                      <button
                        type="button"
                        className="btn btn-outline-gray border border-gray dropdown-toggle px-1 dropdown-toggle-split"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ width: '30px' }}
                      >
                        <DotsVertical size="100%" />
                      </button>
                      <div className="dropdown-menu">
                        <CopyToClipboard
                          text={`${
                            process?.env?.NODE_ENV === 'development'
                              ? `http://${process?.env?.REACT_APP_HOSTNAME2}:3000/custom-instructor-signup`
                              : `https://${process?.env?.REACT_APP_HOSTNAME2}/custom-instructor-signup`
                          }?plan=${encodeURI(plan.name)}&recurrence=${encodeURI(plan.recurrence)}`}
                          onCopy={handleCopy}
                        >
                          <button type="button" className={`${stylesPlans.copyButton}`}>
                            <MdContentCopy size={14} />
                            Copiar link
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={`d-flex justify-content-center align-items-center ${stylesPlans.warning}`}>
          <div>
            <Business color="#202123" />
            <span>Nenhum plano encontrado.</span>
          </div>
          {message && (
            <div className="mt-4">
              <div className="alert alert-danger" role="alert">
                <div className="text-center">{message}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PlansInternalManager;
