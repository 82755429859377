import { memo } from 'react';

import quotes from '../../../assets/icons/quotes.svg';

import styles from './styles.module.css';

function Testimonials({ name, paragraph, avatar, job }) {
  return (
    <div className={styles.cardTestimonials}>
      <div className={styles.cardContainer}>
        <p>{paragraph}</p>

        <div className={styles.profile}>
          <img src={avatar} alt={`Imagem de perfil do(a) ${name}`} />
          <div className={styles.inforsProfile}>
            <span>{name}</span>
            <span>{job}</span>
          </div>
        </div>
      </div>

      <img src={quotes} alt="Aspas duplas" />
    </div>
  );
}

export default memo(Testimonials);
