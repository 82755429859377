import { memo } from 'react';
import { Image, Box, Text, Flex, Button } from '@chakra-ui/react';

import { useToggle } from '../../../hooks/useToggle';

import styles from './styles.module.css';
import Aspas from '../../../assets/images/quote-right.svg';

const MAX_LENGTH = 224;

const textEllipsis = {
  display: '-webkit-box',
  WebkitLineClamp: '5',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
};

function Testimonials({ testimonials }) {
  const [toggle, setToggle] = useToggle(false);

  function getText(id) {
    if (toggle && id === testimonials?.id) {
      return testimonials?.testimony;
    }

    return testimonials?.testimony?.slice(0, MAX_LENGTH);
  }

  return (
    <Flex justifyContent="space-between" w="100%" alignItems="-moz-initial">
      <Box w="80%" mt={4}>
        <Box mb={5}>
          <Text
            fontSize="lg"
            maxW="100%"
            style={!toggle ? textEllipsis : {}}
            title={testimonials?.testimony}
          >
            {getText(testimonials?.id)}
          </Text>

          <Box position="relative">
            {testimonials?.testimony.length > MAX_LENGTH && (
              <Button
                onClick={setToggle}
                colorScheme="transparent"
                textDecoration="underline"
                color="#3aa8ff"
                fontWeight="500"
                px="0"
              >
                {!toggle ? 'Ver mais' : 'Ver menos'}
              </Button>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="left" alignItems="center">
          {testimonials.photo ? (
            <Image
              mr={5}
              borderRadius="full"
              boxSize="150px"
              src={testimonials.photo}
              alt={testimonials.name}
            />
          ) : (
            <div className={styles.defaultImage} />
          )}
          <Box>
            <Text fontWeight="bold">{testimonials.name}</Text>
            <Text fontWeight="bold">{testimonials.job}</Text>
          </Box>
        </Box>
      </Box>
      <Box ml={2}>
        <Image boxSize={100} src={Aspas} alt="aspas" />
      </Box>
    </Flex>
  );
}

export default memo(Testimonials);
