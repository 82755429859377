import { Box, Button, Card, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { IRecurrencePlan } from '../..';

export default function PlanCard({ id, name, value, recurrenceDay }: IRecurrencePlan) {
  const history = useHistory();

  function handleClick() {
    history.push(`/recurrence-checkout/${id}/personal-data`);
  }

  function convertToMonthDay(day: number) {
    switch (day) {
      case 1:
        return 'diário';
      case 15:
        return 'quinzenal';
      case 30:
        return 'mensal';
      case 60:
        return 'bimestral';
      case 90:
        return 'trimestral';
      case 180:
        return 'semestral';
      case 365:
        return 'anual';
      default:
    }
  }

  return (
    <Card
      width={{ base: 'full', md: '500px' }}
      padding="20px"
      borderRadius="6px"
      border="1px solid rgba(32, 33, 35, 0.05)"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      background="#FFF"
      as={Stack}
      spacing="20px"
    >
      <Text
        fontSize={{ base: '16px', md: '24px' }}
        fontWeight="500"
        lineHeight="normal"
        textAlign="center"
      >
        {name}
      </Text>
      <VStack textAlign="center" spacing={0}>
        <HStack spacing={0}>
          <Text as="span" fontSize="18px" fontWeight="500">
            R$
          </Text>
          <Text as="span" fontSize="48px" fontWeight="600">
            {value?.split('.')[0]}
          </Text>
          <Text as="span" fontSize="18px" fontWeight="500">
            ,
            {value?.split('.')[1]?.length > 1
              ? value?.split('.')[1]
              : value?.split('.')[1]?.concat('0') || '00'}
          </Text>
        </HStack>

        <Text as="span" fontSize="18px" fontWeight="500" mt="-15px">
          /{convertToMonthDay(recurrenceDay)}
        </Text>
      </VStack>
      <Box display="flex" justifyContent="center">
        <Button colorScheme="primary" color="secondary.500" width="200px" onClick={handleClick}>
          Assinar
        </Button>
      </Box>
    </Card>
  );
}
