import { Box, Flex, Heading, HStack, IconButton } from '@chakra-ui/react';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import WebhookProductsForm from '../WebhookProductsForm';
import WebhookRecurrenceForm from '../WebhookRecurrenceForm';
import { webhookSchemaValidator } from '../schemaValidator';

export default function NewWebhook() {
  const history = useHistory();
  const location = useLocation();

  const pathname = location.pathname;
  const isProductsPage = pathname.includes('products');
  const isRecurrencePage = pathname.includes('recurrence');

  return (
    <Box>
      <Flex justify="space-between" w="full" wrap="wrap">
        <HStack spacing={{ base: 0.25, xl: 0.5 }} ml={{ base: -2, xl: 0 }}>
          <IconButton
            onClick={() => history.goBack()}
            icon={<ChevronLeftIcon size="1.75rem" />}
            aria-label="Voltar"
            variant="ghost"
            colorScheme="gray"
            size="sm"
          />

          <Heading as="h1" fontSize={{ base: 'lg', lg: '2xl' }} fontWeight="semibold" m={0}>
            Cadastro de Webhook
          </Heading>
        </HStack>
      </Flex>

      {isProductsPage && (
        <WebhookProductsForm
          url="/webhook-proluno"
          method="post"
          successMessage="Webhook cadastrado com sucesso!"
          schemaValidator={webhookSchemaValidator}
          mt={10}
        />
      )}

      {isRecurrencePage && (
        <WebhookRecurrenceForm
          url="/webhook-proluno"
          method="post"
          successMessage="Webhook cadastrado com sucesso!"
          schemaValidator={webhookSchemaValidator}
          mt={10}
        />
      )}
    </Box>
  );
}
