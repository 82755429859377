export default [
  {
    code: 'Pacific/Midway',
    name: '(GMT-11:00) Midway Island, Samoa',
  },
  {
    code: 'Pacific/Pago_Pago',
    name: '(GMT-11:00) Pago Pago',
  },
  {
    code: 'Pacific/Honolulu',
    name: '(GMT-10:00) Hawaii',
  },
  {
    code: 'America/Anchorage',
    name: '(GMT-08:00) Alaska',
  },
  {
    code: 'America/Vancouver',
    name: '(GMT-07:00) Vancouver',
  },
  {
    code: 'America/Los_Angeles',
    name: '(GMT-07:00) Pacific Time (US and Canada)',
  },
  {
    code: 'America/Tijuana',
    name: '(GMT-07:00) Tijuana',
  },
  {
    code: 'America/Edmonton',
    name: '(GMT-06:00) Edmonton',
  },
  {
    code: 'America/Denver',
    name: '(GMT-06:00) Mountain Time (US and Canada)',
  },
  {
    code: 'America/Phoenix',
    name: '(GMT-07:00) Arizona',
  },
  {
    code: 'America/Mazatlan',
    name: '(GMT-06:00) Mazatlan',
  },
  {
    code: 'America/Winnipeg',
    name: '(GMT-05:00) Winnipeg',
  },
  {
    code: 'America/Regina',
    name: '(GMT-06:00) Saskatchewan',
  },
  {
    code: 'America/Chicago',
    name: '(GMT-05:00) Central Time (US and Canada)',
  },
  {
    code: 'America/Mexico_City',
    name: '(GMT-05:00) Mexico City',
  },
  {
    code: 'America/Guatemala',
    name: '(GMT-06:00) Guatemala',
  },
  {
    code: 'America/El_Salvador',
    name: '(GMT-06:00) El Salvador',
  },
  {
    code: 'America/Managua',
    name: '(GMT-06:00) Managua',
  },
  {
    code: 'America/Costa_Rica',
    name: '(GMT-06:00) Costa Rica',
  },
  {
    code: 'America/Montreal',
    name: '(GMT-04:00) Montreal',
  },
  {
    code: 'America/New_York',
    name: '(GMT-04:00) Eastern Time (US and Canada)',
  },
  {
    code: 'America/Indianapolis',
    name: '(GMT-04:00) Indiana (East)',
  },
  {
    code: 'America/Panama',
    name: '(GMT-05:00) Panama',
  },
  {
    code: 'America/Bogota',
    name: '(GMT-05:00) Bogota',
  },
  {
    code: 'America/Lima',
    name: '(GMT-05:00) Lima',
  },
  {
    code: 'America/Halifax',
    name: '(GMT-03:00) Halifax',
  },
  {
    code: 'America/Puerto_Rico',
    name: '(GMT-04:00) Puerto Rico',
  },
  {
    code: 'America/Caracas',
    name: '(GMT-04:00) Caracas',
  },
  {
    code: 'America/Santiago',
    name: '(GMT-04:00) Santiago',
  },
  {
    code: 'America/St_Johns',
    name: '(GMT-02:30) Newfoundland and Labrador',
  },
  {
    code: 'America/Montevideo',
    name: '(GMT-03:00) Montevideo',
  },
  {
    code: 'America/Araguaina',
    name: '(GMT-03:00) Recife',
  },
  {
    code: 'America/Argentina/Buenos_Aires',
    name: '(GMT-03:00) Buenos Aires, Georgetown',
  },
  {
    code: 'America/Godthab',
    name: '(GMT-02:00) Greenland',
  },
  {
    code: 'America/Sao_Paulo',
    name: '(GMT-03:00) São Paulo',
  },
  {
    code: 'Atlantic/Azores',
    name: '(GMT+00:00) Azores',
  },
  {
    code: 'Canada/Atlantic',
    name: '(GMT-03:00) Atlantic Time (Canada)',
  },
  {
    code: 'Atlantic/Cape_Verde',
    name: '(GMT-01:00) Cape Verde Islands',
  },
  {
    code: 'UTC',
    name: '(GMT-00:00) Universal Time UTC',
  },
  {
    code: 'Etc/Greenwich',
    name: '(GMT+00:00) Greenwich Mean Time',
  },
  {
    code: 'Europe/Belgrade',
    name: '(GMT+02:00) Belgrade, Bratislava, Ljubljana',
  },
  {
    code: 'CET',
    name: '(GMT+02:00) Sarajevo, Skopje, Zagreb',
  },
  {
    code: 'Atlantic/Reykjavik',
    name: '(GMT+00:00) Reykjavik',
  },
  {
    code: 'Europe/Dublin',
    name: '(GMT+01:00) Dublin',
  },
  {
    code: 'Europe/London',
    name: '(GMT+01:00) London',
  },
  {
    code: 'Europe/Lisbon',
    name: '(GMT+01:00) Lisbon',
  },
  {
    code: 'Africa/Casablanca',
    name: '(GMT+01:00) Casablanca',
  },
  {
    code: 'Africa/Nouakchott',
    name: '(GMT+00:00) Nouakchott',
  },
  {
    code: 'Europe/Oslo',
    name: '(GMT+02:00) Oslo',
  },
  {
    code: 'Europe/Copenhagen',
    name: '(GMT+02:00) Copenhagen',
  },
  {
    code: 'Europe/Brussels',
    name: '(GMT+02:00) Brussels',
  },
  {
    code: 'Europe/Berlin',
    name: '(GMT+02:00) Amsterdam, Berlin, Rome, Stockholm, Vienna',
  },
  {
    code: 'Europe/Helsinki',
    name: '(GMT+03:00) Helsinki',
  },
  {
    code: 'Europe/Amsterdam',
    name: '(GMT+02:00) Amsterdam',
  },
  {
    code: 'Europe/Rome',
    name: '(GMT+02:00) Rome',
  },
  {
    code: 'Europe/Stockholm',
    name: '(GMT+02:00) Stockholm',
  },
  {
    code: 'Europe/Vienna',
    name: '(GMT+02:00) Vienna',
  },
  {
    code: 'Europe/Luxembourg',
    name: '(GMT+02:00) Luxembourg',
  },
  {
    code: 'Europe/Paris',
    name: '(GMT+02:00) Paris',
  },
  {
    code: 'Europe/Zurich',
    name: '(GMT+02:00) Zurich',
  },
  {
    code: 'Europe/Madrid',
    name: '(GMT+02:00) Madrid',
  },
  {
    code: 'Africa/Bangui',
    name: '(GMT+01:00) West Central Africa',
  },
  {
    code: 'Africa/Algiers',
    name: '(GMT+01:00) Algiers',
  },
  {
    code: 'Africa/Tunis',
    name: '(GMT+01:00) Tunis',
  },
  {
    code: 'Africa/Harare',
    name: '(GMT+02:00) Harare, Pretoria',
  },
  {
    code: 'Africa/Nairobi',
    name: '(GMT+03:00) Nairobi',
  },
  {
    code: 'Europe/Warsaw',
    name: '(GMT+02:00) Warsaw',
  },
  {
    code: 'Europe/Prague',
    name: '(GMT+02:00) Prague Bratislava',
  },
  {
    code: 'Europe/Budapest',
    name: '(GMT+02:00) Budapest',
  },
  {
    code: 'Europe/Sofia',
    name: '(GMT+03:00) Sofia',
  },
  {
    code: 'Europe/Istanbul',
    name: '(GMT+03:00) Istanbul',
  },
  {
    code: 'Europe/Athens',
    name: '(GMT+03:00) Athens',
  },
  {
    code: 'Europe/Bucharest',
    name: '(GMT+03:00) Bucharest',
  },
  {
    code: 'Asia/Nicosia',
    name: '(GMT+03:00) Nicosia',
  },
  {
    code: 'Asia/Beirut',
    name: '(GMT+03:00) Beirut',
  },
  {
    code: 'Asia/Damascus',
    name: '(GMT+03:00) Damascus',
  },
  {
    code: 'Asia/Jerusalem',
    name: '(GMT+03:00) Jerusalem',
  },
  {
    code: 'Asia/Amman',
    name: '(GMT+03:00) Amman',
  },
  {
    code: 'Africa/Tripoli',
    name: '(GMT+02:00) Tripoli',
  },
  {
    code: 'Africa/Cairo',
    name: '(GMT+02:00) Cairo',
  },
  {
    code: 'Africa/Johannesburg',
    name: '(GMT+02:00) Johannesburg',
  },
  {
    code: 'Europe/Moscow',
    name: '(GMT+03:00) Moscow',
  },
  {
    code: 'Asia/Baghdad',
    name: '(GMT+03:00) Baghdad',
  },
  {
    code: 'Asia/Kuwait',
    name: '(GMT+03:00) Kuwait',
  },
  {
    code: 'Asia/Riyadh',
    name: '(GMT+03:00) Riyadh',
  },
  {
    code: 'Asia/Bahrain',
    name: '(GMT+03:00) Bahrain',
  },
  {
    code: 'Asia/Qatar',
    name: '(GMT+03:00) Qatar',
  },
  {
    code: 'Asia/Aden',
    name: '(GMT+03:00) Aden',
  },
  {
    code: 'Asia/Tehran',
    name: '(GMT+04:30) Tehran',
  },
  {
    code: 'Africa/Khartoum',
    name: '(GMT+02:00) Khartoum',
  },
  {
    code: 'Africa/Djibouti',
    name: '(GMT+03:00) Djibouti',
  },
  {
    code: 'Africa/Mogadishu',
    name: '(GMT+03:00) Mogadishu',
  },
  {
    code: 'Asia/Dubai',
    name: '(GMT+04:00) Dubai',
  },
  {
    code: 'Asia/Muscat',
    name: '(GMT+04:00) Muscat',
  },
  {
    code: 'Asia/Baku',
    name: '(GMT+04:00) Baku, Tbilisi, Yerevan',
  },
  {
    code: 'Asia/Kabul',
    name: '(GMT+04:30) Kabul',
  },
  {
    code: 'Asia/Yekaterinburg',
    name: '(GMT+05:00) Yekaterinburg',
  },
  {
    code: 'Asia/Tashkent',
    name: '(GMT+05:00) Islamabad, Karachi, Tashkent',
  },
  {
    code: 'Asia/Calcutta',
    name: '(GMT+05:30) India',
  },
  {
    code: 'Asia/Kathmandu',
    name: '(GMT+05:45) Kathmandu',
  },
  {
    code: 'Asia/Novosibirsk',
    name: '(GMT+07:00) Novosibirsk',
  },
  {
    code: 'Asia/Almaty',
    name: '(GMT+06:00) Almaty',
  },
  {
    code: 'Asia/Dacca',
    name: '(GMT+06:00) Dacca',
  },
  {
    code: 'Asia/Krasnoyarsk',
    name: '(GMT+07:00) Krasnoyarsk',
  },
  {
    code: 'Asia/Dhaka',
    name: '(GMT+06:00) Astana, Dhaka',
  },
  {
    code: 'Asia/Bangkok',
    name: '(GMT+07:00) Bangkok',
  },
  {
    code: 'Asia/Saigon',
    name: '(GMT+07:00) Vietnam',
  },
  {
    code: 'Asia/Jakarta',
    name: '(GMT+07:00) Jakarta',
  },
  {
    code: 'Asia/Irkutsk',
    name: '(GMT+08:00) Irkutsk, Ulaanbaatar',
  },
  {
    code: 'Asia/Shanghai',
    name: '(GMT+08:00) Beijing, Shanghai',
  },
  {
    code: 'Asia/Hong_Kong',
    name: '(GMT+08:00) Hong Kong',
  },
  {
    code: 'Asia/Taipei',
    name: '(GMT+08:00) Taipei',
  },
  {
    code: 'Asia/Kuala_Lumpur',
    name: '(GMT+08:00) Kuala Lumpur',
  },
  {
    code: 'Asia/Singapore',
    name: '(GMT+08:00) Singapore',
  },
  {
    code: 'Australia/Perth',
    name: '(GMT+08:00) Perth',
  },
  {
    code: 'Asia/Yakutsk',
    name: '(GMT+09:00) Yakutsk',
  },
  {
    code: 'Asia/Seoul',
    name: '(GMT+09:00) Seoul',
  },
  {
    code: 'Asia/Tokyo',
    name: '(GMT+09:00) Osaka, Sapporo, Tokyo',
  },
  {
    code: 'Australia/Darwin',
    name: '(GMT+09:30) Darwin',
  },
  {
    code: 'Australia/Adelaide',
    name: '(GMT+09:30) Adelaide',
  },
  {
    code: 'Asia/Vladivostok',
    name: '(GMT+10:00) Vladivostok',
  },
  {
    code: 'Pacific/Port_Moresby',
    name: '(GMT+10:00) Guam, Port Moresby',
  },
  {
    code: 'Australia/Brisbane',
    name: '(GMT+10:00) Brisbane',
  },
  {
    code: 'Australia/Sydney',
    name: '(GMT+10:00) Canberra, Melbourne, Sydney',
  },
  {
    code: 'Australia/Hobart',
    name: '(GMT+10:00) Hobart',
  },
  {
    code: 'Asia/Magadan',
    name: '(GMT+11:00) Magadan',
  },
  {
    code: 'SST',
    name: '(GMT-00:00) Solomon Islands',
  },
  {
    code: 'Pacific/Noumea',
    name: '(GMT+11:00) New Caledonia',
  },
  {
    code: 'Asia/Kamchatka',
    name: '(GMT+12:00) Kamchatka',
  },
  {
    code: 'Pacific/Fiji',
    name: '(GMT+12:00) Fiji Islands, Marshall Islands',
  },
  {
    code: 'Pacific/Auckland',
    name: '(GMT+12:00) Auckland, Wellington',
  },
  {
    code: 'Asia/Kolkata',
    name: '(GMT+05:30) Mumbai, Kolkata, New Delhi',
  },
  {
    code: 'Europe/Kiev',
    name: '(GMT+03:00) Kiev',
  },
  {
    code: 'America/Tegucigalpa',
    name: '(GMT-06:00) Tegucigalpa',
  },
  {
    code: 'Pacific/Apia',
    name: '(GMT+13:00) Independent State of Samoa',
  },
];
