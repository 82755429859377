import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

import parse from 'html-react-parser';
import { memo } from 'react';

interface IModalProps {
  show: boolean;
  data: {
    question: string;
    questionText: string;
    commentary: string;
    status: string;
    correctItem: {
      option: string;
      optionText: string;
    };
    answerUser: {
      option: string;
      optionText: string;
    };
  };
  onHide: () => void;
}

function ModalQuestion(props: IModalProps) {
  const { question, questionText, status, correctItem, answerUser } = props.data;

  return (
    <Modal isOpen={props.show} onClose={props.onHide} size="2xl">
      <ModalOverlay />
      <ModalContent bgColor="#37393D">
        <ModalHeader>
          <Box display="flex" justifyContent="space-between" mr={10}>
            <Heading size="md" color="#FFF">
              {question}
            </Heading>
            <Heading size="md" color="#FFF">
              Status:
              {status ? (
                <Text mx={2} as="line" color="#239A4E">
                  Correto
                </Text>
              ) : (
                <Text mx={2} as="line" color="#9A272A">
                  Incorreto
                </Text>
              )}
            </Heading>
          </Box>
        </ModalHeader>
        <ModalCloseButton color="#FFF" onClick={props.onHide} />
        <ModalBody>
          <Stack>
            <Text as="h2" fontSize="md" fontWeight="700" color="#FFF">
              Pergunta
            </Text>
            <Text as="h3" fontSize="md" fontWeight="500" color="#FFF">
              {parse(String(questionText))}
            </Text>
          </Stack>
          <Stack mt="6" display="flex">
            <Text as="h4" fontSize="md" fontWeight="700" color="#FFF">
              Item Marcado
            </Text>
            <Box display="flex" alignItems="center">
              <Text p="1" color="#FFF">
                ({answerUser?.option})
              </Text>
              <Text color="#FFF" dangerouslySetInnerHTML={{ __html: answerUser?.optionText }} />
            </Box>
          </Stack>
          <Stack mt="6" display="flex">
            <Text color="#FFF" as="h4" fontSize="md" fontWeight="700">
              Item Correto
            </Text>
            <Box display="flex" alignItems="center">
              <Text color="#FFF" p="1">
                ({correctItem?.option})
              </Text>
              <Text color="#FFF" dangerouslySetInnerHTML={{ __html: correctItem?.optionText }} />
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default memo(ModalQuestion);
