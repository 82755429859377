import { Heading } from '@chakra-ui/react';
import { useSubscription } from '../../../contexts/SubscriptionContext';
import ExpirationAlert from './ExpirationAlert';
import Informations from './InformationsSection';
import PathCardsList from './ToDoTodaySection/PathCardsList';

export default function Home() {
  const { subscriptionStatus } = useSubscription();

  const isSubscribed = subscriptionStatus?.isSubscribed;
  const hasTrialPeriod = subscriptionStatus?.numberOfTrialDaysOfPlan > 0;

  const isAlertVisible = !isSubscribed && hasTrialPeriod;

  return (
    <>
      <ExpirationAlert isVisible={isAlertVisible} trial={subscriptionStatus?.trial} />

      <Heading
        fontSize={{ base: 'xl', md: '3xl' }}
        fontWeight="semibold"
        mt={isAlertVisible ? { base: 16, md: 8 } : {}}
      >
        O que você quer fazer hoje?
      </Heading>

      <PathCardsList mt={6} />

      <Informations my={10} />
    </>
  );
}
