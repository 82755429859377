import { Modal } from 'react-bootstrap';
import Spinner from '../../components/Loadings/Spinner';

export default function AlertModal({
  showModal = false,
  onHideModal,
  title = 'AlertModal',
  message,
  actionButton = {
    nameActionButton: 'ActionButton',
    onActionButton: () => alert('onActionButton'),
  },
  actionButtonClassName = 'btn-primary',
  cancelableButton = {
    nameCancelableButton: 'CancelableButton',
    onCancelableButton: () => alert('onCancelableButton'),
  },
  disabled,
}) {
  return (
    <Modal
      centered
      size="sm"
      show={showModal}
      onHide={onHideModal}
      style={{
        backdropFilter: 'blur(16px)',
      }}
    >
      <Modal.Body className="py-5">
        <h5 className="modal-title text-center mt-2" id="exampleModalLabel">
          {title}
        </h5>
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            onClick={cancelableButton.onCancelableButton}
            className="btn btn-light mr-4"
            disabled={disabled}
          >
            {cancelableButton.nameCancelableButton}
          </button>
          <button
            type="button"
            onClick={actionButton.onActionButton}
            className={`btn ${actionButtonClassName}`}
            disabled={disabled}
            style={{ fontWeight: 600 }}
          >
            {disabled ? <Spinner /> : actionButton.nameActionButton}
          </button>
        </div>
        <div className="text-danger mt-2">{message}</div>
      </Modal.Body>
    </Modal>
  );
}
