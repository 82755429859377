import { Container as ChakraContainer } from '@chakra-ui/react';
import {
  HEADER_DESKTOP_HEIGHT,
  HEADER_MOBILE_HEIGHT,
} from '../../../components/ContainerSite/constants';
import ContainerSite from '../../../components/ContainerSite/ContainerSite';
import { routesWithoutHeaderAndFooter } from '../../../routes/AdminRoutes';

export default function Container({ children }: { children: React.ReactNode }) {
  const withoutSidebar = routesWithoutHeaderAndFooter.some(route =>
    window.location.pathname?.includes(route)
  );

  return (
    <ContainerSite>
      <ChakraContainer
        maxW="63.125rem"
        my={{
          base: withoutSidebar ? 0 : HEADER_MOBILE_HEIGHT,
          xl: withoutSidebar ? 0 : HEADER_DESKTOP_HEIGHT,
        }}
        px={{ base: withoutSidebar ? 0 : 4, xl: 0 }}
      >
        {children}
      </ChakraContainer>
    </ContainerSite>
  );
}
