import {
  Box,
  Progress,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import Lines from './Lines';

type AnswersByAlternative = {
  item: string;
  count: number;
};

interface AnswersByAlternativeChartProps {
  answersByAlternativeList?: AnswersByAlternative[];
  total: number;
}

export default function AnswersByAlternativeChart({
  answersByAlternativeList,
  total,
}: AnswersByAlternativeChartProps) {
  const bgColorTooltip = useColorModeValue('#202123', '#FFFFFF');
  const textColorTooltip = useColorModeValue('#FFFFFF', '#202123');
  const isMobile = useBreakpointValue({ base: true, lg: false });

  function getPercentage(value: number) {
    const percentage = (value / total) * 100;
    const parsedPercentage = percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(2);

    return `${parsedPercentage}%`;
  }

  return (
    <Box mt="1.125rem">
      <Text fontSize="14px" fontWeight="500" mb={2.5}>
        Respostas por alternativas
      </Text>

      <Lines hiddenBar={false} />

      {answersByAlternativeList?.map((answersByAlternativeList, index) => (
        <Box key={index}>
          <Box display="flex" gap="10px" flexDirection="column">
            <Stack
              width="full"
              direction="row"
              alignItems="center"
              height={5}
              bgColor="#ffffff"
              borderRadius={5}
              spacing={0}
              paddingRight={{ base: '10px', lg: '25px' }}
            >
              <Text
                color="#202123"
                width={{ base: '1.25rem', lg: '80px' }}
                fontSize="16px"
                fontWeight="500"
                isTruncated
              >
                {isMobile
                  ? `${answersByAlternativeList.item}`
                  : `Item ${answersByAlternativeList.item}`}
              </Text>

              <Tooltip
                hasArrow
                label={
                  <Text as="span" fontWeight="light">
                    Item {answersByAlternativeList.item}:{' '}
                    <Text as="strong" fontWeight="semibold">
                      {answersByAlternativeList.count} |{' '}
                      {getPercentage(answersByAlternativeList.count)}
                    </Text>
                  </Text>
                }
                bg={bgColorTooltip}
                color={textColorTooltip}
                placement="top"
                borderRadius="base"
              >
                <Progress
                  pl={1}
                  width="full"
                  height={5}
                  colorScheme="default"
                  bg="#ffffff"
                  cursor="pointer"
                  value={answersByAlternativeList.count}
                />
              </Tooltip>
            </Stack>
          </Box>
          <Lines hiddenBar={false} />
        </Box>
      ))}

      <Lines hiddenNumbers={false} />
    </Box>
  );
}
