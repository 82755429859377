import Joi from 'joi';
import { ActiveCampaignConfiguration } from '.';

const INVALID_URI_MESSAGE = 'Por favor, forneça uma URL válida. Exemplo: https://www.example.com';

export const activeCampaignConfigurationSchemaValidator =
  Joi.object<ActiveCampaignConfiguration>().keys({
    url: Joi.string().uri().min(3).max(1000).required().messages({
      'string.uri': INVALID_URI_MESSAGE,
      'string.empty': 'URL não pode ficar vazia.',
    }),
    token: Joi.string().min(3).max(255).required().messages({
      'string.empty': 'Token não pode ficar vazia.',
    }),
    key: Joi.string().min(3).max(255).required().messages({
      'string.empty': 'A Chave não pode ficar vazia.',
    }),
    actid: Joi.string().min(3).max(255).required().messages({
      'string.empty': 'ActID não pode ficar vazio.',
    }),
    status: Joi.boolean().required(),
  });
