import {
  Button,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

interface IModal {
  isShowModal: boolean;
  onHideModal: () => void;
  onConfirmAction: () => void;
  numberOfTimesRegistrations: number;
  numberOfTimesRemaining: number;
}

function Modal({
  isShowModal,
  onHideModal,
  onConfirmAction,
  numberOfTimesRegistrations,
  numberOfTimesRemaining,
}: IModal) {
  return (
    <ChakraModal isOpen={isShowModal} onClose={onHideModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" fontWeight={700}>
          Enviar respostas?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" fontWeight={500}>
          {numberOfTimesRegistrations > 0 && numberOfTimesRemaining - 1 >= 1 && (
            <Text>
              Após o envio, você poderá responder o conteúdo mais{' '}
              {numberOfTimesRemaining - 1 === 1 ? 'uma vez' : `${numberOfTimesRemaining - 1} vezes`}
              .
            </Text>
          )}

          {numberOfTimesRegistrations > 0 && numberOfTimesRemaining - 1 === 0 && (
            <Text>
              Após o envio, não será mais possível responder o conteúdo, você chegou no limite.
            </Text>
          )}

          {numberOfTimesRegistrations === 0 && (
            <Text>
              Essa ação não poderá ser desfeita, após o envio, não será mais possível responder o
              conteúdo.
            </Text>
          )}
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Stack direction="row" spacing={2}>
            <Button onClick={() => onHideModal()} size="sm">
              Cancelar
            </Button>
            <Button onClick={() => onConfirmAction()} size="sm" colorScheme="orange">
              Enviar
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}

export default Modal;
