import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
} from '@chakra-ui/react';
import { IRecurrencePlanFrequentlyQuestions } from '../..';

export default function FrequentlyAskedQuestions({
  question,
  answer,
}: IRecurrencePlanFrequentlyQuestions) {
  return (
    <AccordionItem>
      <AccordionButton
        padding="15px"
        bgColor="primary.400"
        _hover={{ bgColor: 'primary.500' }}
        borderRadius="10px"
      >
        <HStack>
          <AccordionIcon fontSize={{ base: '20px', md: '25px' }} color="secondary.500" />
          <Text
            textAlign="start"
            color="secondary.500"
            fontSize={{ base: '14px', md: '18px' }}
            fontWeight="500"
            lineHeight="normal"
          >
            {question}
          </Text>
        </HStack>
      </AccordionButton>
      <AccordionPanel
        pb={4}
        border="1px"
        borderTop="0px"
        borderRadius="0px 0px 10px 10px"
        borderColor="primary.500"
        fontSize={{ base: '12px', md: '16px' }}
      >
        {answer}
      </AccordionPanel>
    </AccordionItem>
  );
}
