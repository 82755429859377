import ReactPaginate from 'react-paginate';

import {
  MdKeyboardArrowLeft as ArrowLeft,
  MdKeyboardArrowRight as ArrowRight,
} from 'react-icons/md';

import styles from './styles.module.css';

interface PaginationProps {
  pageCount: number;
  onPageChange?(selectedItem: { selected: number }): void;
  initialPage?: number;
}

export default function Pagination({ pageCount, onPageChange, initialPage }: PaginationProps) {
  return (
    <ReactPaginate
      breakLabel="..."
      onPageChange={onPageChange}
      pageRangeDisplayed={10}
      marginPagesDisplayed={5}
      pageCount={pageCount}
      nextLabel={<ArrowRight size={26} color="#202123" />}
      previousLabel={<ArrowLeft size={26} color="#202123" />}
      initialPage={initialPage ? initialPage - 1 : 0}
      disableInitialCallback
      containerClassName={styles.container}
      activeClassName={styles.activePagination}
    />
  );
}
