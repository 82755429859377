import process from 'process/browser';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { IS_COOKIES_ACCEPTED, ROLE_LOCAL_STORAGE } from '../../helpers/LocalStorageHelper';

import Cookie from 'js-cookie';

export default function CookieBanner() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function removeAllCookies() {
    const all = Cookie.get();

    Object.keys(all).forEach(cookieName => {
      Cookie.remove(cookieName);
    });
  }

  useEffect(() => {
    const role = localStorage.getItem(ROLE_LOCAL_STORAGE);
    const roleExists = !!role;

    if (roleExists && role !== 'ALUNO') return;

    const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

    if (!isCookiesAccepted && process?.env.NODE_ENV !== 'development') {
      onOpen();
      return;
    }

    if (isCookiesAccepted === 'true') {
      return;
    }

    removeAllCookies();
  }, [onOpen]);

  function acceptCookies() {
    localStorage.setItem(IS_COOKIES_ACCEPTED, 'true');

    onClose();
  }

  function declineCookies() {
    removeAllCookies();

    localStorage.setItem(IS_COOKIES_ACCEPTED, 'false');

    onClose();
  }

  return (
    <Drawer
      placement="bottom"
      onClose={onClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <DrawerOverlay />

      <DrawerContent>
        <DrawerBody p="0.875rem">
          <HStack justify="center" p={0}>
            <Stack direction={{ base: 'column', lg: 'row' }} maxW={{ lg: '85%' }}>
              <Text fontSize={{ base: 'xs', lg: 'md' }} color="#20212380">
                Este site armazena cookies, Clicando em "Aceito", você concorda com o armazenamento
                de cookies no seu dispositivo para melhorar a experiência e navegação no site.
              </Text>

              <HStack spacing={3}>
                <Button onClick={declineCookies} colorScheme="gray" w="full">
                  Recusar
                </Button>

                <Button onClick={acceptCookies} colorScheme="default" w="full">
                  Aceitar
                </Button>
              </HStack>
            </Stack>
          </HStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
