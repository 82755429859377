import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { routesWithoutValidation } from '../../api/Configuration';
import { ROLE_LOCAL_STORAGE } from '../../helpers/LocalStorageHelper';
import process from 'process/browser';

const isDevelopment = process?.env?.NODE_ENV === 'development';

const CHAT_BASE_DEFAULT_STYLE =
  'display: block; position: fixed; right: 1rem; bottom: 1rem; z-index: 99999;';

function enableChatBase(isChatBaseEnabled: boolean) {
  const chatBaseButton = document.querySelector('#chatbase-bubble-button') as HTMLElement;

  if (chatBaseButton) {
    chatBaseButton.style.cssText = CHAT_BASE_DEFAULT_STYLE;
  }

  if (isChatBaseEnabled) {
    if (!document.getElementById('chatBaseScript')) {
      const chatBaseEmbed = document.createElement('script');
      chatBaseEmbed.id = 'cm5ndWZ3gLm91eapkxTXe';
      chatBaseEmbed.src = 'https://www.chatbase.co/embed.min.js';
      chatBaseEmbed.defer = true;
      chatBaseEmbed.async = true;

      document.body?.appendChild(chatBaseEmbed);
    }

    if (!document.getElementById('chatBaseScript')) {
      const chatBaseScript = document.createElement('script');
      chatBaseScript.id = 'chatBaseScript';
      chatBaseScript.innerHTML = `window.chatbaseConfig = {
        chatbotId: "cm5ndWZ3gLm91eapkxTXe",
      }`;

      document.body?.appendChild(chatBaseScript);
    }
  }

  if (!isChatBaseEnabled && chatBaseButton) {
    chatBaseButton.style.display = 'none';
  }
}

export default function ChatBase() {
  const location = useLocation();

  useEffect(() => {
    if (isDevelopment) return;

    const isChatInvisible = ['ALUNO', 'AFILIADO'].includes(
      localStorage.getItem(ROLE_LOCAL_STORAGE)
    );

    if (isChatInvisible) return;

    const pathname = location.pathname.split('/')[1] || '/';

    const isRouteWithoutAuthentication = routesWithoutValidation.includes(pathname);
    const isChatBaseEnabled = !isRouteWithoutAuthentication;

    enableChatBase(isChatBaseEnabled);
  }, [location.pathname]);

  return null;
}
