import { Badge, IconButton, Menu, MenuButton, MenuItem, MenuList, Td, Tr } from '@chakra-ui/react';
import { memo } from 'react';

import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { InstructorView } from '../../types';
import Disciplines from './Disciplines';

interface InstructorsTableRowProps {
  instructor: InstructorView;
  enableOrDisableInstructor(instructorId: number): void;
}

function InstructorsTableRow({ instructor, enableOrDisableInstructor }: InstructorsTableRowProps) {
  const isActive = instructor.status === 'ATIVO';

  return (
    <Tr color="gray.700" sx={{ td: { p: 6 } }}>
      <Td maxWidth="16.125rem" whiteSpace="normal">
        {instructor.fullName}
      </Td>
      <Td maxWidth="17.375rem">{instructor.email}</Td>
      <Td>{instructor.phone ?? '-'}</Td>
      <Td maxWidth="14.125rem">
        <Disciplines instructorId={instructor.id} disciplines={instructor.disciplines} />
      </Td>
      <Td maxWidth="14.125rem">
        <Badge colorScheme={isActive ? 'green' : 'red'}>{instructor.status}</Badge>
      </Td>

      <Td textAlign="center">
        <Menu size="sm" arrowPadding={0}>
          <MenuButton
            size="xs"
            as={IconButton}
            icon={<MoreIcon size="1.125rem" />}
            w="27px"
            h="24px"
            borderRadius={5}
            bgColor="#2021231F"
            _hover={{ bgColor: '#b6b5b5' }}
            _active={{ bgColor: '#b6b5b5' }}
          />

          <MenuList
            sx={{
              '.chakra-menu__menuitem': {
                fontWeight: 'medium',
                fontSize: 'xs',
                color: '#20212380',
                _hover: { bgColor: '#2021230D' },
                _focus: { bgColor: '#2021230D' },
              },
            }}
          >
            <MenuItem onClick={() => enableOrDisableInstructor(instructor.id)}>
              {isActive ? 'Inativar' : 'Ativar'}
            </MenuItem>

            <MenuItem as={Link} to={`/instructors/edit/${instructor.id}`}>
              Editar
            </MenuItem>

            <MenuItem as={Link} to={`/internal-profile/${instructor.id}/instructor`}>
              Visualizar página de instrutor
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default memo(InstructorsTableRow);
