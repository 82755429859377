import { Box, Flex, FormLabel, ListItem, Text } from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';

type OptionsType = {
  id: string;
  option: string;
  answerOption: string;
};

interface IQuestionProps {
  id: string;
  order: number;
  questionText: string;
  questionNumber: number;
  options: OptionsType[];
  userOptionAnswer: number;
  isDisabled: boolean;
  onAnswerQuestion: (questionId: number, optionId: number) => void;
}

function Question({
  id: questionId,
  questionText,
  questionNumber,
  options,
  userOptionAnswer,
  isDisabled,
  onAnswerQuestion,
}: IQuestionProps) {
  const [answer, setAnswer] = useState(userOptionAnswer || null);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    onAnswerQuestion(Number(questionId), Number(value));

    setAnswer(Number(value));
  }

  return (
    <>
      <ListItem sx={{ '& + li': { marginTop: '2.5rem' } }}>
        <Text display="flex" color="#FFF" fontSize="lg" fontWeight="500">
          {questionNumber}.{' '}
          <Box
            w="full"
            ml="1"
            sx={{ 'p > img': { marginTop: '4' }, iframe: { width: '100%', aspectRatio: '16/9' } }}
            dangerouslySetInnerHTML={{ __html: questionText }}
          />
        </Text>

        {options.map(option => (
          <Flex key={option.id} align="start" gap="2" mt="4">
            <input
              value={option.id}
              id={option.id}
              type="radio"
              checked={Number(answer) === Number(option.id)}
              onChange={handleChange}
              disabled={isDisabled}
              style={{
                cursor: isDisabled && 'not-allowed',
                accentColor: '#eb7129',
                marginTop: '6px',
              }}
            />

            <FormLabel
              display="flex"
              alignItems="start"
              htmlFor={option.id}
              w="full"
              color="#FFF"
              gap="2"
              m={0}
              fontWeight="medium"
            >
              <Text>{`${option.option})`}</Text>

              <Box
                dangerouslySetInnerHTML={{ __html: option.answerOption }}
                sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
                color="#FFF"
              />
            </FormLabel>
          </Flex>
        ))}
      </ListItem>
    </>
  );
}

export default Question;
