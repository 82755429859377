import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  VStack,
} from '@chakra-ui/react';

export default function EditQuestionModal({
  isOpen,
  onClose,
  form,
  handleChange,
  isEditing,
  updateQuestion,
}) {
  return (
    <Modal isCentered size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar pergunta</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="start" width="full">
            <FormControl>
              <FormLabel>Pergunta</FormLabel>
              <Input
                name="question"
                value={form.question}
                onChange={handleChange}
                focusBorderColor="default.500"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Resposta</FormLabel>
              <Textarea
                name="questionAnswer"
                focusBorderColor="default.500"
                value={form.questionAnswer}
                onChange={handleChange}
                rows={10}
              />
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <HStack width="full">
            <Button
              width="full"
              onClick={onClose}
              colorScheme="default"
              size="sm"
              variant="outline"
            >
              Descartar alterações
            </Button>
            <Button
              width="full"
              onClick={updateQuestion}
              isLoading={isEditing}
              isDisabled={isEditing}
              size="sm"
              colorScheme="red"
            >
              Confirmar edição
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
