import {
  Button,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { HiOutlineSearch } from 'react-icons/hi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { QueryFilter } from '../Tools/QuestionStatistics/QuestionStatisticsPage/SearchContainer';
import ContentViewAffiliate from './ContentViewAffiliate';
import FiltersDropDown from './components/FiltersDropDown';
import { ModalAddAffiliatesInBulk } from './components/ModalAddAffiliatesInBulk';

export type AffiliationProductsType = {
  data: [];
  page: number;
  per_page: number;
  total: number;
};

type UrlParams = Record<string, string | number | undefined>;

function Affiliations() {
  const history = useHistory();
  const location = useLocation();
  const [courseAffiliates, setCourseAffiliates] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const [search, setSearch] = useState(urlSearchParams.get('search'));
  const [timer, setTimer] = useState(0);

  const {
    isOpen: isOpenAddAffiliatesInBulk,
    onOpen: onOpenAddAffiliatesInBulk,
    onClose: onCloseAddAffiliatesInBulk,
  } = useDisclosure();

  const {
    data: response,
    loading,
    fetchData: listProductsAffiliations,
  } = useFetch<UnificadaFront.ResponseJSON<AffiliationProductsType>>({
    method: 'get',
    url: `/affiliations/products?${urlSearchParams}`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        listProductsAffiliations();

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
  }, [currentPage, listProductsAffiliations, timer]);

  useEffect(() => {
    if (response?.data) {
      setCourseAffiliates(response?.data?.data);
      setPageCount(response?.data?.total / response?.data?.per_page);
    }
  }, [response?.data]);

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearch(value);
    setTimer(2);
  }

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({
      search: urlSearchParams.toString(),
    });
  }

  const updateUrlSearchParams = useCallback(
    (params: UrlParams) => {
      for (const [key, value] of Object.entries(params)) {
        value ? urlSearchParams.set(key, value.toString()) : urlSearchParams.delete(key);
      }

      history.push({ search: urlSearchParams.toString() });
    },

    [history, urlSearchParams]
  );

  useEffect(() => {
    updateUrlSearchParams({ search });
  }, [search, updateUrlSearchParams]);

  function handleFilter(filters?: QueryFilter[]) {
    const params: Record<string, string | number> = {
      search,
      page: '1',
      viewOrder: filters?.find(filter => filter.name === 'viewOrder')?.value || '',
      startDate: filters?.find(filter => filter.name === 'startDate')?.value || '',
      endDate: filters?.find(filter => filter.name === 'endDate')?.value || '',
    };

    updateUrlSearchParams(params);
  }

  function clearFilters() {
    urlSearchParams.forEach((_, key) => {
      urlSearchParams.delete(key);
    });

    history.replace(location.pathname);
  }

  return (
    <Stack spacing="20px">
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        alignItems={{ base: 'self-start', md: 'center', lg: 'center' }}
        justifyContent="space-between"
      >
        <Heading fontSize={{ base: '24px', md: '36px', lg: '36px' }} fontWeight="600">
          Produtos para afiliação
        </Heading>

        <Stack direction="row" alignItems="center">
          <Button
            variant="outline"
            colorScheme="primary"
            size="sm"
            onClick={onOpenAddAffiliatesInBulk}
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <BiPlus size="18px" />
              <Text>Adicionar afiliados</Text>
            </Stack>
          </Button>
          <Button
            as={Link}
            to="/affiliations/products/new"
            colorScheme="primary"
            color="textColor"
            size="sm"
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <BiPlus size="18px" />
              <Text>Adicionar produto</Text>
            </Stack>
          </Button>
        </Stack>
      </Stack>

      <Text fontSize="18px" color="#20212350">
        Faça o cadastro de seus produtos para afiliação, visualize os detalhes e adicione novos
        afiliados com facilidade.
      </Text>

      <Stack direction="row" width="full" alignItems="center" justifyContent="space-between">
        <InputGroup w={{ base: '100%', md: '320px', lg: '320px' }} size="sm">
          <InputLeftElement pointerEvents="none" children={<HiOutlineSearch color="gray" />} />
          <Input
            type="text"
            value={search}
            onChange={handleChangeSearch}
            placeholder="Buscar produto"
            borderRadius={6}
            focusBorderColor="#EB7129"
          />
        </InputGroup>

        <FiltersDropDown
          onFilter={handleFilter}
          onClearFilters={clearFilters}
          urlSearchParams={urlSearchParams}
        />
      </Stack>

      <ContentViewAffiliate
        courseAffiliates={courseAffiliates}
        currentPage={currentPage}
        pageCount={pageCount}
        isLoading={loading}
        handlePageChange={handlePageChange}
        setCourseAffiliates={setCourseAffiliates}
        listProductsAffiliations={listProductsAffiliations}
      />

      <ModalAddAffiliatesInBulk
        isOpenAddAffiliatesInBulk={isOpenAddAffiliatesInBulk}
        onCloseAddAffiliatesInBulk={onCloseAddAffiliatesInBulk}
        listProductsAffiliations={listProductsAffiliations}
      />
    </Stack>
  );
}

export default Affiliations;
