import { Box, Text as ChakraText, TextProps } from '@chakra-ui/react';
import styled, { css } from 'styled-components';
import { toFixedPercentage } from '../../../../../../DashboardAdmin/Courses/CourseManager/Reporting/utils';
import Chart from './Chart';

interface IStatistics {
  rightQuestions: number | string;
  wrongQuestions: number | string;
  studentUseStatistics?: number;
}

interface ITextProps extends TextProps {}

const Text = styled(ChakraText)<ITextProps>`
  ${() => css`
    position: relative;
    margin-top: 0.5rem;

    :first-child::before {
      content: '';
      position: absolute;
      top: 2px;
      left: -32px;
      width: 1rem;
      height: 1rem;
      background: #4bbe58;
      border-radius: 100%;
    }

    :last-child::before {
      content: '';
      position: absolute;
      top: 2px;
      left: -32px;
      width: 1rem;
      height: 1rem;
      background: #cc595b;
      border-radius: 100%;
    }
  `}
`;

function Result({ rightQuestions, wrongQuestions, studentUseStatistics }: IStatistics) {
  return (
    <Box
      as="header"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex="1"
      width="full"
      gridArea="chart"
      borderRadius="lg"
      border="1px solid rgba(32, 33, 35, 0.25)"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      p="4"
      bg="#37393D"
    >
      <Box display="flex" w="full" justifyContent="flex-start">
        <ChakraText fontSize="xl" fontWeight="700" color="#FFF">
          Resultado
        </ChakraText>
      </Box>

      <Chart rightQuestions={rightQuestions ?? 0} wrongQuestions={wrongQuestions ?? 0} />

      <Box display="flex" flexDirection="column" mt="10">
        <Text fontSize="sm" color="#FFF">
          Quantidade de acertos: {rightQuestions}
        </Text>
        <Text fontSize="sm" color="#FFF">
          Quantidade de erros: {wrongQuestions}
        </Text>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" mt="8">
        <ChakraText display="flex" fontSize="xl" color="#FFF" fontWeight="700">
          Taxa de aproveitamento
        </ChakraText>

        <ChakraText fontSize="4xl" color="#FFF" fontWeight="700">
          {toFixedPercentage(studentUseStatistics)}%
        </ChakraText>
      </Box>
    </Box>
  );
}

export default Result;
