import './styles.css';

import Container from '../../components/ContainerSite/ContainerSite';
// import SectionCourses from '../../components/SectionCourses/SectionCourses';
import CardDestaques from './CardDestaques';
import ItemCategories from './ItemCategories';
// import Users from './Users';
import { useColorMode } from '@chakra-ui/react';
import { useEffect } from 'react';

function Home() {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  }, [setColorMode]);

  return (
    <Container>
      <section className="background-image-header p-0">
        <div className="linear-gradient" />
        <div className="container-fluid">
          {/* <div className="content">
            <h2 className="heading">Os melhores cursos online em um único lugar.</h2>
            <div>
              <a
                className="btn btn-primary mt-2 py-3"
                href="https://proluno.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ACESSAR VERSÃO 2019
              </a>
            </div>
          </div> */}

          <CardDestaques />
        </div>
      </section>

      <section className="my-5 container-fluid">
        {/* <div className="container-fluid"> */}
        {/* <SectionCourses title="novos cursos" /> */}
        {/* </div> */}
      </section>

      <ItemCategories />

      <section className="my-4 container-fluid">
        {/* <Users title="instrutores" role="instrutor" /> */}
      </section>

      <section className="my-4 container-fluid">
        {/* <Users title="instituições" role="dono" /> */}
      </section>
    </Container>
  );
}

export default Home;
