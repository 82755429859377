import { BiDotsVertical } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import CashIcon from '../../../../assets/icons/cash-icon.svg';
import convertToBrazilianCurrency from '../../../../helpers/convertToBrazilianCurrency';

import {
  Box,
  Card,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

type OrderBumpCardProps = {
  id: number;
  name: string;
  enabled: boolean;
  courseName: string;
  coursePrice: number;
};

export default function OrderBumpCard({
  orderBump,
  seOrderBumpId,
  setOrderBumpActive,
  isDeletingOrderBump,
  onOpenModalDeleteOrderBump,
  onOpenModalActiveOrderBump,
}: {
  orderBump: OrderBumpCardProps;
  width: number;
  seOrderBumpId: (orderBumpId: number) => void;
  setOrderBumpActive: (orderBumpActive: boolean) => void;
  isDeletingOrderBump: boolean;
  onOpenModalDeleteOrderBump: () => void;
  onOpenModalActiveOrderBump: () => void;
}) {
  return (
    <Card
      height="96px"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)"
      padding={1}
      flex={{
        base: '1 1 100%',
        md: '1 1 48%',
        lg: '1 1 23.5%',
      }}
    >
      <Flex flex={1} align="start" gap={1} px={3} py={1}>
        <Flex flex={1} direction="row" gap={3} maxWidth="100%" w="full" overflow="hidden">
          <Image src={CashIcon} width="16px" height="20px" />

          <Box maxWidth="100%" w="full" overflow="hidden">
            <Text fontWeight={600} fontSize="12px" width={165} isTruncated>
              {orderBump.name}
            </Text>

            <Text color="#20212380" fontSize="12px" isTruncated maxWidth="100%">
              {orderBump.courseName}
            </Text>

            <Text color="#20212380" fontSize="12px">
              {convertToBrazilianCurrency(orderBump.coursePrice)}
            </Text>
            <Text color="#20212380" fontSize="12px">
              {orderBump.enabled ? 'Ativo' : 'Desativado'}
            </Text>
          </Box>
        </Flex>

        <Menu>
          <MenuButton>
            <BiDotsVertical size="12px" />
          </MenuButton>
          <MenuList>
            <MenuItem
              as={Link}
              to={`order-bump/edit/${orderBump.id}`}
              color="#979CA6"
              fontWeight={500}
            >
              Editar
            </MenuItem>
            <MenuItem
              color="#979CA6"
              fontWeight={500}
              onClick={() => {
                seOrderBumpId(orderBump.id);
                setOrderBumpActive(orderBump.enabled);
                onOpenModalActiveOrderBump();
              }}
            >
              {orderBump.enabled ? 'Desativar' : 'Ativar'}
            </MenuItem>
            <MenuItem
              color="#BB2124"
              fontWeight={500}
              onClick={() => {
                seOrderBumpId(orderBump.id);
                onOpenModalDeleteOrderBump();
              }}
              isDisabled={isDeletingOrderBump}
            >
              Excluir
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Card>
  );
}
