import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import PurchaseAPI from '../../../../api/Purchase';
import TransactionAPI from '../../../../api/Transaction';
import exportIcon from '../../../../assets/icons/export.svg';
import DotsFalling from '../../../../components/Loadings/DotsFalling';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import Badge from '../Badge';
import { translatePurchaseStatus } from '../helpers';
import { useToggle } from '../hooks';
import Alert from './Alert';
import Box from './Box';
import GridBox from './GridBox';
import InstallmentsBox from './InstallmentsBox';
import { cardBrand } from './PaymentMethod';
import styles from './styles.module.css';

import {
  default as convert,
  default as convertToBrazilianCurrency,
} from '../../../../helpers/convertToBrazilianCurrency';

import {
  Box as ChakraBox,
  HStack,
  Heading,
  IconButton,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

const role = localStorage.getItem(ROLE_LOCAL_STORAGE);
const isAffiliate = role === 'AFILIADO';

const initialState = {
  transactionId: '',
  createdAt: '',
  purchaseValue: 0,
  costPurchase: 0,
  mdr: 0,
  purchaseStatus: '',
  installments: 0,
  paymentMethod: '',
  items: [],
  pix: {
    pixExpirationDate: '',
    pixQrCode: '',
  },
  card: {
    holderName: '',
    firstDigits: '',
    lastDigits: '',
    expirationDate: '',
    brand: '',
  },
  boleto: {
    boletoBarcode: '',
    boletoExpirationDate: '',
    boletoUrl: '',
  },
  recurrencePlan: {
    name: '',
    courses: [],
  },
  recipients: [],
};

const recipientDefault = {
  fullName: 'Não encontrado (Possivelmente o recebedor foi excluído)',
  installmentsPayment: [
    {
      amount: 0,
      anticipationFee: 0,
      chargeRemainder: 0,
      fee: 0,
      splitAmount: 0,
      splitDate: '',
      status: '',
    },
  ],
  percentage: 0,
};

function payment(transaction) {
  if (transaction?.paymentMethod === 'boleto' || transaction?.installments === 1) {
    return 'À vista';
  }

  return `Parcelado ${transaction?.installments}x`;
}

export default function TransactionDetail() {
  const { id } = useParams();
  const history = useHistory();

  const [transaction, setTransaction] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [isShowModal, setIsShowModal] = useToggle(false);

  if (!id) history.push('/financial');

  useEffect(() => {
    async function getTransactionById() {
      try {
        const { data: transactionData } = await TransactionAPI.show(id);

        if (transactionData) {
          setTransaction(transactionData);
        }
      } catch (error) {
        setMessage(ErrorResponse(error));
      } finally {
        setLoading(false);
      }
    }

    getTransactionById();
  }, [history, id]);

  async function getRefundTransaction() {
    try {
      setLoading(true);
      await PurchaseAPI.purchaseRefund(id);

      setTransaction({
        ...transaction,
        purchaseStatus: 'refunded',
      });

      Toast('Transação estornada com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setLoading(false);
      setIsShowModal(false);
    }
  }

  function AlertPayment({ status, paymentMethod }) {
    if (status === 'waiting_payment' && paymentMethod !== 'credit_card') {
      return <Alert message="O demonstrativo de parcelas fica disponível após o pagamento." />;
    }

    return '';
  }

  function addSpaceString(string) {
    return string.substr(0, 4) + ' ' + string.substr(4, 5);
  }

  const isRefundButtonShow = transaction?.purchaseStatus === 'paid' && !isAffiliate;

  function Details() {
    if (loading) {
      return (
        <div className="container d-flex justify-content-center mt-5">
          <DotsFalling />
        </div>
      );
    }

    return (
      <div>
        {transaction?.transactionId && (
          <div className="d-flex justify-content-center h-100 mt-5">
            <div className="col-md-12">
              <Box>
                <div className={styles.gridTransactionDetail}>
                  <div className={styles.colDetail}>
                    <span className={styles.heading}>Transação</span>

                    <span className={styles.texting}>#{transaction?.transactionId}</span>
                  </div>

                  <div className={styles.colDetail}>
                    <span className={styles.heading}>Status</span>

                    <div className={styles.badgeContainer}>
                      <Badge status={transaction?.purchaseStatus}>
                        {translatePurchaseStatus(transaction?.purchaseStatus)}
                      </Badge>
                    </div>
                  </div>

                  <div className={styles.colDetail}>
                    <span className={styles.heading}>Pagamento</span>

                    <span className={styles.texting}>{payment(transaction)}</span>
                  </div>

                  <div className={styles.colDetail}>
                    <span className={styles.heading}>Valor autorizado</span>

                    <span className={styles.texting}>{convert(transaction?.purchaseValue)}</span>
                  </div>

                  <div
                    className={styles.colDetail}
                    style={{
                      cursor: transaction?.purchaseStatus === 'refunded' ? 'not-allowed' : '',
                    }}
                  >
                    <div className={styles.buttonGroup}>
                      {isRefundButtonShow && (
                        <button
                          className={styles.button}
                          onClick={() => setIsShowModal(true)}
                          disabled={transaction?.purchaseStatus === 'refunded'}
                        >
                          <img src={exportIcon} alt="Ícone flecha para baixo" />
                          Estornar
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Box>

              <ChakraBox my={5} hidden={!!transaction?.recurrencePlan}>
                <Box>
                  {/* <Text fontSize="lg">Itens do Carrinho</Text> */}

                  <TableContainer>
                    <Table variant="simple" colorScheme="orange">
                      <TableCaption placement="top" textAlign="start" px={0} pt={0} m={0}>
                        <Text fontSize="lg" color="#000000" fontWeight={300}>
                          Itens do Carrinho
                        </Text>
                      </TableCaption>
                      <Thead>
                        <Tr>
                          <Th color="#1A202C" px={{ base: 2, md: 0, lg: 0 }}>
                            Nome
                          </Th>
                          <Th color="#1A202C" px={{ base: 2, md: 0, lg: 0 }}>
                            Valor
                          </Th>
                          <Th color="#1A202C" px={{ base: 2, md: 0, lg: 0 }}>
                            Quantidade
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {transaction?.items.length > 0 &&
                          transaction?.items?.map((item, index) => (
                            <Tr key={index}>
                              <Td px={{ base: 2, md: 0, lg: 0 }}>{item.name}</Td>
                              <Td px={{ base: 2, md: 0, lg: 0 }}>
                                {convertToBrazilianCurrency(item.price)}
                              </Td>
                              <Td px={{ base: 2, md: 0, lg: 0 }}>{item.amount}</Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </ChakraBox>

              <ChakraBox my={5} hidden={!transaction?.recurrencePlan}>
                <Box>
                  <TableContainer>
                    <Table variant="simple" colorScheme="orange">
                      <TableCaption placement="top" textAlign="start" px={0} pt={0} m={0}>
                        <Text fontSize="lg" color="#000000" fontWeight={300}>
                          Itens do Plano
                        </Text>
                      </TableCaption>
                      <Thead>
                        <Tr>
                          <Th color="#1A202C" px={{ base: 2, md: 0, lg: 0 }}>
                            Nome
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {transaction?.recurrencePlan?.courses.length > 0 &&
                          transaction?.recurrencePlan?.courses?.map((item, index) => (
                            <Tr key={index}>
                              <Td px={{ base: 2, md: 0, lg: 0 }}>{item.name}</Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </ChakraBox>

              <GridBox transaction={transaction} />

              <AlertPayment
                status={transaction?.purchaseStatus}
                paymentMethod={transaction?.paymentMethod}
              />

              <Stack direction="column" spacing={5}>
                <Box>
                  <div className={styles.header}>
                    <div className={styles.heading}>
                      <span>Divisão entre recebedores</span>
                    </div>
                    <span className={styles.heading}>Valor total bruto:</span>
                  </div>
                  <div className={styles.subHeader}>
                    <span className={styles.subHeading}>
                      {transaction?.recipients.length}{' '}
                      {transaction?.recipients.length <= 1 ? 'recebedor' : 'recebedores'}
                    </span>
                    <span className={styles.priceHeader}>
                      {convert(transaction?.purchaseValue)}
                    </span>
                  </div>

                  {transaction?.recipients.length === 1 && (
                    <InstallmentsBox
                      key={1000}
                      purchaseStatus={transaction?.purchaseStatus}
                      paymentMethod={transaction?.paymentMethod}
                      recipients={recipientDefault}
                      purchaseValue={0}
                      index={'X'}
                    />
                  )}

                  {transaction?.recipients.map((recipient, index) => {
                    const takePositionOneArray = index + 1;
                    return (
                      <InstallmentsBox
                        key={index}
                        purchaseStatus={transaction?.purchaseStatus}
                        paymentMethod={transaction?.paymentMethod}
                        recipients={recipient}
                        purchaseValue={transaction?.purchaseValue}
                        index={takePositionOneArray}
                      />
                    );
                  })}
                </Box>

                {/* Dados do Cliente */}
                <Box>
                  <Stack direction="column" spacing={5}>
                    <Text fontSize="lg">Detalhes do cliente</Text>

                    <Stack
                      direction={{ base: 'column', md: 'row', lg: 'row' }}
                      w="100%"
                      justifyContent="flex-start"
                      spacing={5}
                    >
                      <Stack direction="column" w={{ base: '100%', md: 260, lg: 260 }}>
                        <Text fontWeight="600">Nome do cliente</Text>
                        <Text>{transaction?.user?.name ?? 'Não Informado'}</Text>
                      </Stack>

                      <Stack direction="column" w={{ base: '100%', md: 260, lg: 260 }}>
                        <Text fontWeight="600">CPF/CNPJ</Text>
                        <Text>{transaction?.user?.documentNumber ?? 'Não Informado'}</Text>
                      </Stack>

                      <Stack direction="column" w={{ base: '100%', md: 260, lg: 260 }}>
                        <Text fontWeight="600">Data de nascimento:</Text>
                        <Text>
                          {transaction?.user?.dateOfBirth
                            ? dayjs(transaction?.user?.dateOfBirth).format('DD/MM/YYYY')
                            : 'Não Informado'}
                        </Text>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ base: 'column', md: 'row', lg: 'row' }}
                      w="100%"
                      justifyContent="flex-start"
                      spacing={5}
                    >
                      <Stack direction="column" w={{ base: '100%', md: 260, lg: 260 }}>
                        <Text fontWeight="600">Telefone</Text>
                        <Text>{transaction?.phoneNumber ?? 'Não Informado'}</Text>
                      </Stack>
                      <Stack direction="column" w={{ base: '100%', md: 260, lg: 260 }}>
                        <Text fontWeight="600">E-mail</Text>
                        <Text>{transaction?.user?.email ?? 'Não Informado'}</Text>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ base: 'column', md: 'row', lg: 'row' }}
                      w="100%"
                      justifyContent="flex-start"
                      spacing={5}
                    >
                      <Stack direction="column" w={{ base: '100%', md: 260, lg: 260 }}>
                        <Text fontWeight="600">Rua</Text>
                        <Text>{transaction?.user?.address?.street_address ?? 'Não Informado'}</Text>
                      </Stack>
                      <Stack direction="column" w={{ base: '100%', md: 260, lg: 260 }}>
                        <Text fontWeight="600">Número</Text>
                        <Text>{transaction?.user?.address?.street_number ?? 'Não Informado'}</Text>
                      </Stack>
                      <Stack direction="column" w={{ base: '100%', md: 260, lg: 260 }}>
                        <Text fontWeight="600">Complemento</Text>
                        <Text>{transaction?.user?.address?.complementary ?? 'Não Informado'}</Text>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={{ base: 'column', md: 'row', lg: 'row' }}
                      w="100%"
                      justifyContent="flex-start"
                      spacing={5}
                    >
                      <Stack direction="column" w={{ base: '100%', md: 260, lg: 260 }}>
                        <Text fontWeight="600">Bairro</Text>
                        <Text>{transaction?.user?.address?.neighborhood ?? 'Não Informado'}</Text>
                      </Stack>
                      <Stack direction="column" w={{ base: '100%', md: 260, lg: 260 }}>
                        <Text fontWeight="600">Cidade</Text>
                        <Text>{transaction?.user?.address?.city ?? 'Não Informado'}</Text>
                      </Stack>
                      <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} spacing={5}>
                        <Stack direction="column" w={130}>
                          <Text fontWeight="600">Estado</Text>
                          <Text>{transaction?.user?.address?.state ?? 'Não Informado'}</Text>
                        </Stack>
                        <Stack direction="column" w={130}>
                          <Text fontWeight="600">CEP</Text>
                          <Text>{transaction?.user?.address?.zip_code ?? 'Não Informado'}</Text>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </div>
          </div>
        )}
        <Modal
          centered
          size="lg"
          show={isShowModal}
          onHide={setIsShowModal}
          style={{ backdropFilter: 'blur(4px)' }}
        >
          <Modal.Body>
            <div className={styles.modalHeader}>
              <h3 className={styles.headingModal}>Estornar transação</h3>
              <div className={styles.detailsTransaction}>
                <div className={styles.boxModal}>
                  <span className={styles.heading}>Nome do cliente</span>
                  <span className={styles.texting}>{transaction?.card.holderName}</span>
                </div>
                <div className={styles.boxModal}>
                  <span className={styles.heading}>Transação</span>
                  <span className={styles.texting}>#{transaction?.transactionId}</span>
                </div>
                <div className={styles.boxModal}>
                  <span className={styles.heading}>Valor da transação</span>
                  <span className={styles.texting}>{convert(transaction?.purchaseValue)}</span>
                </div>
              </div>
              <div className={styles.detailsTransaction}>
                <div className={styles.boxModal}>
                  <span className={styles.heading}>Número do cartão</span>
                  <span className={styles.texting}>
                    {transaction?.paymentMethod === 'creditCard' && (
                      <>
                        {`${addSpaceString(transaction?.card.firstDigits)}** **** ${
                          transaction?.card.lastDigits
                        }`}
                      </>
                    )}
                  </span>
                </div>
                <div className={styles.boxModal}>
                  <span className={styles.heading}>Bandeira</span>
                  <span className={styles.imgBrand}>{cardBrand(transaction?.card.brand)}</span>
                </div>
                <div className={styles.boxModal}>
                  <span className={styles.heading}>Parcelas</span>
                  <span className={styles.texting}>{payment(transaction)}</span>
                </div>
              </div>
              <div className={styles.modalFooterButton}>
                <button className={styles.buttonModalCancel} onClick={() => setIsShowModal(false)}>
                  Cancelar
                </button>
                <button className={styles.buttonModal} onClick={() => getRefundTransaction()}>
                  Confirmar estorno
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  return (
    <>
      {message && (
        <div className="mt-4">
          <div
            className="alert alert-danger"
            role="alert"
            style={{ borderRadius: '0', wordBreak: 'break-all' }}
          >
            <div className="text-center">{message}</div>
          </div>
        </div>
      )}

      <HStack spacing={2}>
        <IconButton
          onClick={() => history.goBack()}
          icon={<ChevronLeftIcon size="1.75rem" />}
          aria-label="Voltar"
          variant="ghost"
          colorScheme="gray"
          size="sm"
        />

        <Heading fontSize="2xl" fontWeight={600}>
          Detalhes da transação
        </Heading>
      </HStack>

      <Details />
    </>
  );
}
