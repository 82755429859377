import { useEffect, useState } from 'react';

import Container from '../../../../components/ContainerSite/ContainerSite';
import { Heading } from '../../../../components/Heading';
import styles from './styles.module.css';
import { useTheme } from '../../../../contexts/ThemeContext';
import CookieBanner from '../../../../components/CookieBanner';

interface IBiography {
  id: number;
  description: string;
  nameSection: string;
  photo: string;
}

export function getBiography(themeMarketplace) {
  return themeMarketplace?.themeSections?.find(section => section.name === 'biography');
}

function About() {
  const [biography, setBiography] = useState<IBiography>({} as IBiography);

  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      const biography = getBiography(themeMarketplace);

      if (biography) {
        setBiography(biography.themeBiography[0]);
      }
    }
  }, [isLoadingThemeMarketplace, themeMarketplace]);

  return (
    <Container>
      <div id="aboutContainer" className={styles.aboutContainer}>
        {biography.photo && (
          <div className={styles.imageWrapper}>
            <img src={biography.photo} alt="Sobre" />
          </div>
        )}

        <div className={styles.aboutContent}>
          <Heading fontSize="3xl">{biography.nameSection}</Heading>
          <p>{biography.description}</p>
        </div>
      </div>

      <CookieBanner />
    </Container>
  );
}

export default About;
