import styled from 'styled-components';
import { NavLink as NavLinkDom } from 'react-router-dom';

export const NavLink = styled(NavLinkDom)`
  width: 6.5rem;
  height: 3rem;
  margin: 0 auto;
  padding: 0.5rem;

  color: #8f9091;
  border: 1px solid rgba(32, 33, 35, 0.25);
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    font-size: 0.75rem;
    line-height: 1.2;
  }

  & > svg {
    font-size: 1.25rem;
    margin-right: 0.25rem;
    flex-shrink: 0;
  }

  &.paymentMethodActive svg,
  &.paymentMethodActive .creditCard {
    color: #eb7129;
    fill: #eb7129;
  }

  &.paymentMethodActive .creditCard {
    max-width: 26%;
  }

  &.paymentMethodActive p {
    color: #eb7129;
  }

  &.paymentMethodActive {
    border: 1px solid #eb7129;
  }

  @media only screen and (min-width: 62em) {
    width: 8.85rem;
    height: 3.8rem;

    & > p {
      font-size: 0.875rem;
    }

    & > svg {
      margin-right: 0.5rem;
    }
  }
`;
