import dayjs from 'dayjs';
import { IPayment } from '../../..';
import convertToBrazilianCurrency from '../../../../../../../helpers/convertToBrazilianCurrency';
import getPaymentMethod from '../../../utils/getPaymentMethod';
import getStatusText from '../../../utils/getStatusText';
import Status from '../ProductsTable/Status';

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  List,
  ListItem,
  ListProps,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { IItemType } from '../../../../../../DashboardStudent/Payments/ProductsList';

interface ProductsListMobileProps extends ListProps {
  paymentList: IPayment[];
  toggleModal?: (pixQrCode: string) => void;
  setTransactionFiltered?: (products: IItemType[]) => void;
  onOpen?: () => void;
}

export default function ProductsListMobile({
  paymentList,
  toggleModal,
  setTransactionFiltered,
  onOpen,
  ...rest
}: ProductsListMobileProps) {
  const textColor = useColorModeValue('#20212380', '#fff');
  const bgColor = useColorModeValue('#fff', '#37393D');

  function filterTransactionId(products: IItemType[]) {
    setTransactionFiltered(products);
    onOpen();
  }

  return (
    <List flexDirection="column" gap={2.5} {...rest}>
      {paymentList.map(payment => (
        <ListItem key={payment.id}>
          <Card
            boxShadow="0.6699029207229614px 0.6699029207229614px 2.6796116828918457px 0px #00000040"
            background={bgColor}
          >
            <CardHeader p={2.5}>
              <Heading fontSize="sm" fontWeight="medium">
                #{payment.id}
              </Heading>
            </CardHeader>

            <CardBody
              px={2.5}
              py={0}
              color={textColor}
              fontSize="sm"
              onClick={() => filterTransactionId(payment.products)}
            >
              <Text>Valor: {convertToBrazilianCurrency(payment.value)}</Text>
              <Text>Forma de Pagamento: {getPaymentMethod(payment.paymentMethod)}</Text>
              <Text>Data: {dayjs(payment.date).format('DD/MM/YYYY')}</Text>
              <Text>Parcelas: {payment.installments}</Text>
              <Text>Status: {getStatusText(payment.status)}</Text>
            </CardBody>

            <CardFooter p={2.5} pt={0} color="#20212380">
              {payment.status === 'waiting_payment' && (
                <Status
                  paymentMethod={payment.paymentMethod}
                  paymentStatus={payment.status}
                  link={payment.link}
                  onClick={() => toggleModal(payment.pixQrCode)}
                />
              )}
            </CardFooter>
          </Card>
        </ListItem>
      ))}
    </List>
  );
}
