import { List, Text, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface FiltersListProps {
  title: 'Áreas' | 'Disciplinas' | 'Assuntos';
  children?: ReactNode;
}

export default function FiltersList({ title, children }: FiltersListProps) {
  return (
    <VStack align="start" spacing={2.5} width="14rem" flex={1}>
      <Text as="h3" color="#20212380" fontSize="sm" m={0}>
        {title}
      </Text>

      <List color="#202123" spacing={1} w="full">
        {children}
      </List>
    </VStack>
  );
}
