import { Box, Input, InputGroup, InputLeftElement, Stack, Text } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { Breadcrumb } from '../../../../components/Breadcrumb';
import ProgressBar from '../../../../../../components/ProgressBar';
import { ModuleList } from '../ModuleList';

export default function ModuleDesktopSidebar({
  title,
  showProgress,
  highlightColor,
  modulesFilters,
  search,
  handleInputChange,
  progress,
  amountContentViewed,
  countContent,
  hasActiveModulesPage,
}) {
  return (
    <Stack
      width="full"
      height="100%"
      maxHeight="82vh"
      overflow="auto"
      sx={{ '::-webkit-scrollbar': { width: '0', background: 'transparent' } }}
    >
      <Box width="full" marginBottom="20px">
        <Breadcrumb title={title} hasActiveModulesPage={hasActiveModulesPage} />
      </Box>

      <Box width="full" marginBottom="20px" hidden={!showProgress}>
        <ProgressBar
          showProgress={showProgress}
          highlightColor={highlightColor}
          progress={progress}
          currentContent={amountContentViewed}
          total={countContent}
        />
      </Box>

      <Box width="250px">
        <InputGroup width="full" size="sm" color="#FFF">
          <InputLeftElement pointerEvents="none">
            <Search2Icon fontSize="15px" />
          </InputLeftElement>
          <Input
            type="text"
            focusBorderColor="primary.500"
            borderRadius="6px"
            value={search}
            onChange={handleInputChange}
            placeholder="Buscar módulo/conteúdo"
          />
        </InputGroup>

        {!modulesFilters?.length && (
          <Text
            margin="15px 18px"
            color="#20212380"
            fontWeight={600}
            width={{ base: 'full', md: '250px', lg: '250px' }}
          >
            Nenhum resultado encontrado...
          </Text>
        )}

        <ModuleList modulesFilters={modulesFilters} highlightColor={highlightColor} />
      </Box>
    </Stack>
  );
}
