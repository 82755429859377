import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import Alert from '../../components/Alerts/Alerts';
import AutenticationApi from '../../api/Authentication';
import Container from '../../components/ContainerSite/ContainerSite';
import ErrorResponse from '../../helpers/ErrorResponse';
import { FaUserEdit } from 'react-icons/fa';
import InputPassword from '../../components/InputPassword';
import RolesAPI from '../../api/Roles';
import Toast from '../../components/Toast';
import styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';

const initalFormValues = {
  fullName: '',
  email: '',
  dateOfBirth: '',
  password: '',
  token: '',
};

function Signin() {
  const [form, setForm] = useState(initalFormValues);

  const history = useHistory();

  const [hasError, setHasError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const query = useQuery();

  useEffect(() => {
    const email = query.get('email');
    const token = query.getAll('token').slice(-1);

    setForm({ ...form, email, token: token[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handlerChange(event) {
    const { name, value } = event.target;

    setForm({
      ...form,
      [name]: value,
    });
  }

  async function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const payload = {
        ...form,
        dateOfBirth: dayjs(form.dateOfBirth).format('YYYY-MM-DD'),
      };

      await RolesAPI.storeNewUser(payload);

      await AutenticationApi.login(form.email, form.password);
      Toast('Cadastro realizado com sucesso');

      history.push('/login');
    } catch (error) {
      setHasError(ErrorResponse(error));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Container header={false}>
        <div className="nk-content ">
          <div className="container-fluid">
            <div className="row justify-content-center mt-5">
              <div className="col-lg-7 border-bottom pb-1">
                <h4>
                  <FaUserEdit className="" /> Cadastro de usuário
                </h4>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-xl-4">
                <div className="card">
                  <div className="card-inner">
                    <form onSubmit={onSubmit}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-control-wrap mb-3 mt-3">
                            {hasError && (
                              <Alert class_name="danger mb-2 text-center" text={hasError} />
                            )}
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              name="fullName"
                              maxLength="60"
                              placeholder="Nome completo"
                              autoComplete="off"
                              onChange={handlerChange}
                              required
                            />
                          </div>
                          <div className="form-control-wrap mb-3">
                            <input
                              type="email"
                              className={`form-control form-control-lg ${styles.email}`}
                              name="email"
                              value={form.email}
                              placeholder="E-mail"
                              onChange={handlerChange}
                              required
                              disabled
                            />
                          </div>

                          <div className="form-control-wrap">
                            <input
                              className="form-control form-control-lg"
                              type="date"
                              name="dateOfBirth"
                              placeholder="Data de nascimento"
                              onChange={handlerChange}
                              value={form.dateOfBirth}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 mt-3">
                          <div className="form-control-wrap">
                            <InputPassword
                              name="password"
                              className="form-control form-control-lg is-hidden"
                              id="password"
                              placeholder="Insira sua senha"
                              onChange={handlerChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className={`btn btn-primary ${styles.email}`}
                          type="submit"
                          disabled={isLoading}
                        >
                          Salvar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
export default Signin;
