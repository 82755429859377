import styles from './styles.module.css';

function Skeleton() {
  return (
    <div className={styles.containerSkeleton}>
      <div className={styles.imageSkeleton} />
      <div className={styles.contentSkeleton}>
        <span className={styles.titleSkeleton} />
        <span className={styles.titleSkeleton} />
        <div className={styles.tagsGroupSkeleton}>
          <span className={styles.tagSkeleton} />
          <span className={styles.tagSkeleton} />
        </div>
      </div>
    </div>
  );
}

export default Skeleton;
