import { memo } from 'react';
import { Button, Link } from '@chakra-ui/react';

function getStatusText(status: string) {
  const statusText = {
    paid: 'Pago',
    canceled: 'Cancelado',
    refunded: 'Estornado',
  };

  return statusText[status] || 'Aguardando pagamento';
}

function Status({ paymentMethod, paymentStatus, link, onClick }) {
  if (paymentStatus === 'waiting_payment') {
    return (
      <div>
        {paymentMethod === 'pix' && (
          <Button
            variant="link"
            onClick={onClick}
            color="#759AED"
            size="xs"
            fontWeight="normal"
            fontSize="sm"
            textDecoration="underline"
          >
            Visualizar código
          </Button>
        )}

        {paymentMethod === 'boleto' && (
          <Link href={link} isExternal textDecoration="underline" color="#759AED">
            Link do boleto
          </Link>
        )}
      </div>
    );
  }

  return getStatusText(paymentStatus);
}

export default memo(Status);
