import { useEffect, useState } from 'react';

import AlertModal from '../../../../../../../components/AlertModal';
import ErrorMessage from '../components/ErrorMessage';
import { Heading } from '../../../../../../../components/Heading';
import ImageInput from '../components/ImageInput';
import ImagePreview from '../components/ImagePreview';
import { MdAddCircleOutline } from 'react-icons/md';
import Section from '../components/Section';
import Spinner from '../../../../../../../components/Loadings/Spinner';
import { nanoid } from 'nanoid';
import { sortByOrder } from '../../../../../../../helpers/SortHelper';
import styles from './styles.module.css';

const FIRST_FILE = 0;
const MAX_SIZE_FILE_PHOTO = 3145728;

function Banner({
  data,
  onChange,
  onChangeOrder,
  onChangeAmountBannersToShow,
  onChangeVisibilityBanner,
  onDeleteBanner,
  amountBannersToShow,
  activeBannersNumber,
  isLoadingCourse,
}) {
  const [preview, setPreview] = useState(null);
  const [banners, setBanners] = useState([]);

  const [bannerToShow, setBannerToShow] = useState(1);

  const [currentBanner, setCurrentBanner] = useState(null);

  const [selectedBanner, setSelectedBanner] = useState(0);

  const [hasChanged, setHasChanged] = useState(false);

  const [isAdding, setIsAdding] = useState(false);

  const [errorUploadPhoto, setErrorUploadPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  useEffect(() => {
    const currentBanner = banners[selectedBanner];

    if (currentBanner) {
      setCurrentBanner(currentBanner);
      setPreview(currentBanner.banner);
    }
  }, [banners, selectedBanner]);

  useEffect(() => {
    if (data) {
      const banners = data;
      setBanners(banners);

      const firstBanner = banners[0];
      setCurrentBanner(firstBanner);
      setPreview(firstBanner?.banner);
      setBannerToShow(amountBannersToShow);

      setIsAdding(false);
      setHasChanged(false);
    }
  }, [amountBannersToShow, data]);

  useEffect(() => {
    const currentBanner = banners[selectedBanner];

    const existingOrder = banners.find(banner => banner.order === selectedBanner);

    if (currentBanner && existingOrder && existingOrder.banner) {
      setCurrentBanner(currentBanner);
      setPreview(currentBanner.banner);
    }
  }, [selectedBanner, banners]);

  function handleOrderChange(event) {
    const { value } = event.target;

    setErrorMessage(null);
    setHasChanged(true);

    setCurrentBanner(prevBanner => ({ ...prevBanner, order: parseInt(value) }));

    onChangeOrder(parseInt(value), currentBanner);
  }

  function toggleVisibilityBanner(event) {
    const { checked } = event.target;

    setErrorMessage(null);
    setHasChanged(true);

    setCurrentBanner(prevBanner => ({ ...prevBanner, active: checked }));

    onChangeVisibilityBanner(checked, currentBanner);
  }

  function handlePhotoChange(acceptedFiles) {
    const file = acceptedFiles[FIRST_FILE];

    if (file?.size > MAX_SIZE_FILE_PHOTO) {
      return setErrorUploadPhoto('O tamanho da imagem deve ser de no máximo 3 MB.');
    }

    if (file) {
      setPreview(URL.createObjectURL(file));

      setErrorUploadPhoto(null);
      setErrorMessage(null);
      setHasChanged(true);

      setCurrentBanner(prevBanner => ({ ...prevBanner, banner: file }));

      onChange(file, currentBanner);
    }
  }

  function handleDiscardChanges(event, bannersArray) {
    event.preventDefault();

    if (isAdding) {
      bannersArray.pop();
    }

    setHasChanged(false);

    const banners = data;

    setBanners(banners);

    const firstBanner = banners[0];
    setCurrentBanner(firstBanner);

    setPreview(firstBanner?.banner);
    setErrorUploadPhoto(null);
    setErrorMessage(null);

    setIsAdding(false);
  }

  function handleBannerToShowChange(event) {
    const { value } = event.target;

    setErrorUploadPhoto(null);
    setErrorMessage(null);
    setHasChanged(true);

    setBannerToShow(value);

    onChangeAmountBannersToShow(parseInt(value), currentBanner);
  }

  function handleSelectedBannerChange(event) {
    const { value } = event.target;

    setErrorUploadPhoto(null);
    setErrorMessage(null);
    setSelectedBanner(value);
  }

  function handleImageDropRejected() {
    setErrorUploadPhoto('Arquivo inválido!');
  }

  function handleImageChange() {
    setHasChanged(true);
    setPreview(null);
  }

  function addBanner(event) {
    event.preventDefault();

    setIsAdding(true);
    setPreview(null);

    const orders = banners.map(banner => banner.order);

    const missingNumbers = [];

    for (let index = 0; index < orders.length; index++) {
      const element = orders[index];

      const nextElement = index + 1 + missingNumbers.length;

      const existingElement = orders.find(element => element === nextElement);

      if (element !== nextElement && !existingElement) {
        missingNumbers.push(nextElement);
      }
    }

    let order = 1;

    const minOrder = Math.min(...missingNumbers);

    if (minOrder !== Infinity) {
      order = minOrder;
    } else {
      order = [...orders, ...missingNumbers].length + 1;
    }

    const currentBanner = { banner: null, order, link: '', active: true };

    banners.push(currentBanner);

    setSelectedBanner(order);

    setCurrentBanner(currentBanner);
  }

  async function handleDelete(event) {
    event.preventDefault();

    setErrorMessage(null);
    setErrorUploadPhoto(null);

    onDeleteBanner(currentBanner.id);

    toggleModal(event);
  }

  function toggleModal(event) {
    event.preventDefault();

    setIsShowDeleteModal(!isShowDeleteModal);
  }

  const shouldDisplayForm = isAdding || !!banners?.length;

  return (
    <Section>
      <div className={styles.sectionHeader}>
        <div>
          <Heading as="h4" fontSize="2xl">
            Banner
          </Heading>
        </div>

        {!isAdding && (
          <div className={styles.actionsHeaderContainer}>
            <button onClick={addBanner} disabled={isAdding} style={{ fontSize: '0.75rem' }}>
              <MdAddCircleOutline size="1.125rem" /> Adicionar banner
            </button>
          </div>
        )}
      </div>

      <div>
        <div className={styles.headerText}>
          <p>
            Você pode realizar várias ações com os banners, por exemplo, adicionar um link que
            direciona para algum produto, definir a ordem de exibição ou exibir mais de uma por vez.
            Caso não deseje mais que determinado banner apareça basta ocultá-lo ou simplesmente
            excluí-lo.
          </p>
        </div>

        <div className={styles.imageDimensions}>
          <p>
            Dimensões recomendadas:<strong> 1920 x 840 </strong> pixels;
          </p>
        </div>
      </div>

      <div className={styles.container}>
        {shouldDisplayForm && (
          <section className={styles.formSection}>
            {!isAdding && (
              <div className={styles.inputGroup}>
                <label htmlFor="selectedBanner">Selecione um banner para editar:</label>
                <select
                  id="selectedBanner"
                  name="selectedBanner"
                  onChange={handleSelectedBannerChange}
                  disabled={isAdding}
                  value={selectedBanner}
                >
                  {banners?.map((_, index) => (
                    <option key={nanoid()} value={index}>
                      Banner {index + 1}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <section className={styles.bannerImage}>
              {!preview ? (
                <ImageInput
                  preview={preview}
                  onDrop={handlePhotoChange}
                  onDropRejected={handleImageDropRejected}
                  onChangeImage={handleImageChange}
                  isDisabled={!banners?.length > 0}
                  imageDimensions="1920x840"
                />
              ) : (
                <ImagePreview preview={preview} onClick={handleImageChange} />
              )}

              {errorUploadPhoto && <ErrorMessage message={errorUploadPhoto} />}
            </section>

            {banners?.length > 1 && (
              <div className={styles.inputGroup}>
                <label htmlFor="sectionsToShow">
                  Em que ordem você quer que este banner apareça na sua página de vendas?
                </label>
                <select
                  id="order"
                  name="order"
                  onChange={handleOrderChange}
                  value={currentBanner?.order}
                >
                  {banners?.sort(sortByOrder).map(banner => (
                    <option key={nanoid()} value={banner.order}>
                      Ordem {banner.order}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {!isAdding && activeBannersNumber > 1 && (
              <div className={styles.inputGroup}>
                <label htmlFor="sectionsToShow">
                  Quantos banners você quer exibir em sua página de vendas?
                </label>

                <select
                  id="sectionsToShow"
                  name="sectionsToShow"
                  onChange={handleBannerToShowChange}
                  disabled={isAdding}
                  value={bannerToShow}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </div>
            )}
          </section>
        )}

        {banners?.length > 0 && (
          <div className={styles.actions}>
            <div className={styles.inputCheckboxGroup}>
              <input
                type="checkbox"
                id="isBannerActive"
                name="isBannerActive"
                onChange={toggleVisibilityBanner}
                checked={currentBanner?.active}
              />

              <label
                htmlFor="isBannerActive"
                data-toggle="tooltip"
                data-placement="top"
                title="Para exibir 2 banners de uma só vez, você precisar ter pelo menos 2 banners marcados para exibir em sua página de vendas."
              >
                Exibir este banner na página de vendas
              </label>
            </div>

            <div className="d-flex align-items-end">
              {!isAdding && (
                <button className={styles.deleteButton} onClick={toggleModal}>
                  Excluir banner
                </button>
              )}

              {(hasChanged || isAdding) && (
                <button
                  className={styles.cancelButton}
                  onClick={event => handleDiscardChanges(event, banners)}
                >
                  Cancelar
                </button>
              )}
              {hasChanged && (
                <button
                  className="btn btn-primary"
                  style={{ fontSize: '0.875rem' }}
                  disabled={isLoadingCourse}
                >
                  {isLoadingCourse ? <Spinner small /> : 'Salvar alterações'}
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      {errorMessage && !hasChanged && <ErrorMessage message={errorMessage} />}

      <AlertModal
        showModal={isShowDeleteModal}
        onHideModal={() => setIsShowDeleteModal(false)}
        title={`Você tem certeza que deseja excluir este banner?`}
        actionButton={{ nameActionButton: 'Excluir', onActionButton: handleDelete }}
        actionButtonClassName="btn-danger"
        cancelableButton={{
          nameCancelableButton: 'cancelar',
          onCancelableButton: () => setIsShowDeleteModal(false),
        }}
      />
    </Section>
  );
}

export default Banner;
