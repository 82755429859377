import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { Payment } from '../..';
import PaymentTableRow from './PaymentTableRow';

interface SubscriberTableProps extends TableContainerProps {
  paymentHistory: Payment[];
}

export default function PaymentTable({ paymentHistory, ...rest }: SubscriberTableProps) {
  return (
    <TableContainer
      border="1px solid rgba(32, 33, 35, 0.05)"
      mt={4}
      p={3}
      borderRadius="base"
      {...rest}
    >
      <Table>
        <Thead>
          <Tr sx={{ th: { color: '#202123BF', whiteSpace: 'break-spaces', py: 2 } }}>
            <Th maxWidth="173 px">Id</Th>

            <Th maxWidth="190px">Status</Th>

            <Th maxWidth="246 px">Nome</Th>

            <Th maxWidth="189px">
              Forma de <br />
              pagamento
            </Th>

            <Th maxWidth="223px">Data de criação</Th>
          </Tr>
        </Thead>

        <Tbody sx={{ 'tr:nth-of-type(odd)': { background: '#2021230D' } }}>
          {paymentHistory.map(payment => (
            <PaymentTableRow key={payment.transactionId} payment={payment} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
