import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormEvent } from 'react';
import { axiosBackend } from '../../../../../../api/Configuration';
import Toast from '../../../../../../components/Toast';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import useHandleChange from '../../../../../../hooks/useHandleChange';

export default function EditPercentageModal({
  isOpen,
  onClose,
  splitId,
  fetchRevenueGroup,
  percentages,
  availablePercentage,
  setAvailablePercentage,
  fetchGroupPercentage,
  loadingGroupPercentage,
}) {
  function handleChange(event) {
    const { name, value } = event.target;

    setForm(prevPost => ({ ...prevPost, [name]: value }));

    if (name === 'percentage') {
      let numericValue =
        value !== '' ? parseInt(value.replace(/\D/g, '')).toString() : value.replace(/\D/g, '');

      let formattedValue = numericValue;

      if (numericValue.length === 1) {
        formattedValue = `0.0${numericValue[0]}`;
      } else if (numericValue.length === 2) {
        formattedValue = `0.${numericValue[0]}${numericValue[1]}`;
      } else if (numericValue.length === 3) {
        formattedValue = `${numericValue[0]}.${numericValue[1]}${numericValue[2]}`;
      } else if (numericValue.length > 3) {
        formattedValue = `${numericValue[0]}${numericValue[1]}.${numericValue[2]}${numericValue[3]}`;
      }

      setForm(prevPost => ({ ...prevPost, [name]: formattedValue }));

      if (numericValue === '') {
        setAvailablePercentage(percentages?.availablePercentage);
        setForm(prevPost => ({ ...prevPost, percentage: '' }));
        return;
      }

      // Regex para validar número flutuante entre 0.01 e 99.99
      const isValidPercentage = /^(\d{1,2}(\.\d{1,2})?|100)$/.test(formattedValue);

      if (!isValidPercentage) {
        // Se o valor não for válido, pode tratar o erro aqui se necessário
        return;
      }

      setAvailablePercentage(
        (parseFloat(percentages?.availablePercentage) - parseFloat(formattedValue)).toString()
      );

      if (parseFloat(formattedValue) > parseFloat(percentages?.availablePercentage)) {
        setForm(prevPost => ({
          ...prevPost,
          percentage: parseFloat(percentages?.availablePercentage).toFixed(2),
        }));
        setAvailablePercentage('0');
        return;
      }

      if (parseFloat(formattedValue) < 0.01) {
        setForm(prevPost => ({ ...prevPost, percentage: '1' }));
        setAvailablePercentage(percentages?.availablePercentage);
        return;
      }
    }
  }

  const { form, setForm } = useHandleChange<{ percentage: string }>({
    percentage: '',
  });

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      const payload = {
        percentage: parseFloat(form?.percentage),
      };

      await axiosBackend().patch(`/revenue-split/${splitId}`, payload);
      Toast('Compartilhamento atualizado com sucesso!', 'success');
      fetchRevenueGroup();
      fetchGroupPercentage();
      setForm({
        percentage: '',
      });
      onClose();
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  function onCloseModal() {
    setForm({
      percentage: '',
    });
    setAvailablePercentage(percentages.availablePercentage);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size="2xl">
      <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
      <ModalContent as="form" onSubmit={handleSubmit}>
        <ModalHeader fontWeight="700" fontSize={{ base: 'md', md: 'xl' }}>
          Editar porcentagem
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Stack spacing="10px">
            <Text fontWeight="600" fontSize="sm">
              Porcentagem
            </Text>

            <InputGroup size="sm">
              <Input
                isRequired
                type="text"
                name="percentage"
                placeholder="Digite uma porcentagem"
                focusBorderColor="primary.500"
                borderRadius={5}
                maxLength={5}
                value={form.percentage}
                onChange={handleChange}
              />

              <InputRightAddon children="%" height={8} />
            </InputGroup>

            <VStack alignItems="flex-start">
              <Text>
                Porcentagem da proluno:{' '}
                <Text as="span" fontWeight="500">
                  {!loadingGroupPercentage && percentages?.prolunoPercentage
                    ? `${parseFloat(percentages?.prolunoPercentage).toFixed(2)}%`
                    : '-'}
                </Text>
              </Text>
              <Text>
                Porcentagem disponível:{' '}
                <Text as="span" fontWeight="500">
                  {`${
                    !loadingGroupPercentage && availablePercentage
                      ? `${parseFloat(availablePercentage).toFixed(2)}%`
                      : '-'
                  }`}
                </Text>
              </Text>
            </VStack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack width="full" justifyContent="flex-end">
            <Button size="sm" colorScheme="gray" onClick={onCloseModal}>
              Cancelar
            </Button>
            <Button size="sm" colorScheme="primary" color="secondary.500" type="submit">
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
