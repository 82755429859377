import { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useTheme } from '../../../../contexts/ThemeContext';

export default function HTMLSection({ section }) {
  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  const [htmlSection, setHtmlSection] = useState(null);

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      setHtmlSection(section);

      const head = document.getElementsByTagName('head').item(0);

      const style = htmlSection?.themeHTML?.style;

      if (style) {
        const styleTag = document.createElement('style');
        styleTag.innerHTML = JSON.parse(style);
        head.appendChild(styleTag);
      }
    }
  }, [themeMarketplace, isLoadingThemeMarketplace, section, htmlSection?.themeHTML?.style]);

  if (!htmlSection) return <h1>...</h1>;

  return <Box dangerouslySetInnerHTML={{ __html: JSON.parse(htmlSection.themeHTML.html) }}></Box>;
}
