import { Box } from '@chakra-ui/react';
import { CourseAffiliateType } from '../..';

import Card from './components/Card';

export type ListType = {
  courseAffiliates: CourseAffiliateType[];
  onOpenAddAffiliate: () => void;
  onOpenRemoveProduct: () => void;
  setCourseAffiliationId: (id: number) => void;
  setProductName: (name: string) => void;
  setProductId: (id: number) => void;
};

function List({
  courseAffiliates,
  onOpenAddAffiliate,
  onOpenRemoveProduct,
  setCourseAffiliationId,
  setProductName,
  setProductId,
}: ListType) {
  return (
    <Box>
      {courseAffiliates &&
        courseAffiliates?.map((courseAffiliate: CourseAffiliateType, index) => (
          <Card
            key={index}
            courseAffiliate={courseAffiliate}
            onOpenAddAffiliate={onOpenAddAffiliate}
            onOpenRemoveProduct={onOpenRemoveProduct}
            setCourseAffiliationId={setCourseAffiliationId}
            setProductName={setProductName}
            setProductId={setProductId}
          />
        ))}
    </Box>
  );
}

export default List;
