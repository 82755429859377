import { ChangeEvent, useState } from 'react';

import { Box, Flex, FormLabel } from '@chakra-ui/react';

type OptionsType = {
  id: string;
  option: string;
  answerOption: string;
};

interface IQuestion {
  id: string;
  order: number;
  questionText: string;
  options: OptionsType[];
  userOptionAnswer: number;
  isDisabled: boolean;
  onAnswerQuestion: (questionId: number, optionId: number) => void;
}

function Question({
  id: questionId,
  questionText,
  options,
  userOptionAnswer,
  isDisabled,
  onAnswerQuestion,
}: IQuestion) {
  const [answer, setAnswer] = useState(userOptionAnswer || null);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    onAnswerQuestion(Number(questionId), Number(value));

    setAnswer(Number(value));
  }

  return (
    <li>
      <Box
        w="full"
        dangerouslySetInnerHTML={{ __html: questionText }}
        sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
        fontSize="lg"
        fontWeight="medium"
      />

      {options.map(option => (
        <Flex key={option.id} align="start" gap="2" mt="4">
          <input
            value={option.id}
            id={option.id}
            type="radio"
            checked={Number(answer) === Number(option.id)}
            onChange={handleChange}
            disabled={isDisabled}
            style={{ cursor: isDisabled && 'not-allowed', marginTop: '6px' }}
          />

          <FormLabel
            htmlFor={option.id}
            w="full"
            fontWeight="medium"
            display="flex"
            alignItems="start"
            gap="2"
            m={0}
          >
            {`${option.option})`}

            <Box
              w="full"
              dangerouslySetInnerHTML={{ __html: option.answerOption }}
              fontWeight="normal"
              sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
            />
          </FormLabel>
        </Flex>
      ))}
    </li>
  );
}

export default Question;
