import { Box, Button, Stack } from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';

interface IProps {
  preview: string;
  onClick: () => void;
}

function ImagePreview({ preview, onClick }: IProps) {
  return (
    <Stack>
      <Box
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        height="244px"
        maxHeight="244px"
        backgroundImage={`url("${preview}")`}
      />

      <Box width="full" display="flex" justifyContent="center">
        <Button
          colorScheme="primary"
          color="secondary.500"
          size="sm"
          onClick={onClick}
          leftIcon={<MdEdit />}
        >
          Alterar
        </Button>
      </Box>
    </Stack>
  );
}

export default ImagePreview;
