import { Box, Button, HStack, Heading, Stack, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FiCheck as CheckIcon } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { ContentUserAPI } from '../../../../../api/ContentUser';
import Platform from '../../../../../api/Platform';
import CustomDropzoneNetflixMembersArea from '../../../../../components/CustomDropzoneNetflixMembersArea';
import FileDownload from '../../../../../components/FileDownload';
import FileDownloadStudent from '../../../../../components/FileDownloadStudent';
import Toast from '../../../../../components/Toast';
import { useCourseStudent } from '../../../../../contexts/CourseStudentContextV2';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../../helpers/UploadHelper';
import useFetch from '../../../../../hooks/useFetch';
import { PagingContents } from '../../../components/PagingContents';
import { IParams } from '../types';
import Feedback from './Feedback';
import PreviewPDFTask from './PreviewPDFTask';
import Status from './Status';

const initialValues = {
  answerFile: '',
  answerFileName: '',
};

export interface ITask {
  instructorName: string;
  instructorPhoto: string;
  content: string;
  contentId: number;
  feedback: string;
  fileNameTest: string;
  fileNameTestCorrection: string;
  fileTest: string;
  fileTestCorrection: string;
  id: number;
  moduleId: number;
  moduleName: string;
  status: string;
  studentName: string;
  testGrade: string;
}

const MAX_LENGTH_FILE_DOWNLOAD = 52428800;

function ViewTask() {
  const { courseId, moduleId, contentId } = useParams<IParams>();
  const { currentContent: content, highlightColor } = useCourseStudent();
  const [values, setValues] = useState(initialValues);
  const [filePDF, setFilePDF] = useState(null);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [changed, setChanged] = useState(false);
  const errorMessageRef = useRef(null);

  const history = useHistory();

  const {
    data: taskResponse,
    loading,
    fetchData: taskFetch,
  } = useFetch<UnificadaFront.ResponseJSON<ITask>>({
    url: `/contents/${moduleId}/module/${courseId}/course/${contentId}/content/user`,
    method: 'get',
    authenticated: true,
    autoFetch: false,
  });

  const task = taskResponse?.data;
  const canMarkAsCompleted = !!(task?.fileTestCorrection || task?.testGrade);

  const hasCorrection = task?.testGrade !== null && task?.fileNameTestCorrection;

  useEffect(() => {
    if (contentId && courseId && moduleId) {
      taskFetch();
    }
  }, [contentId, courseId, moduleId, taskFetch]);

  function handleDropFilePDF(acceptedFiles) {
    const [filePDF] = acceptedFiles || '';

    setFilePDF(filePDF);
    setValues({ ...values, answerFile: filePDF });
    setChanged(true);
  }

  function handleFocus() {
    errorMessageRef.current &&
      errorMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (filePDF && filePDF.size > MAX_LENGTH_FILE_DOWNLOAD) {
      return Toast('O tamanho máximo do arquivo deve ser igual ou inferior a 50 MB.', 'error');
    }

    if (!values.answerFile) {
      handleFocus();
      return Toast('Por favor, preencha os campos!', 'error');
    }

    let key = '';

    try {
      setIsLoadingSubmit(true);

      if (filePDF) {
        const { newFileName } = await UploadHelper.upload(filePDF, 'archives');

        key = `/archives/${newFileName}`;

        const payload = {
          ...values,
          answerFileName: encodeURI(filePDF.path),
          answerFile: encodeURI(newFileName),
        };

        await ContentUserAPI.store(contentId, moduleId, courseId, payload);
      }

      Toast(`Conteúdo Enviado com sucesso`, 'success');
      setChanged(false);

      history.push(
        `/members-area/v2/course/${courseId}/module/${moduleId}/content/${contentId}/content-view/view-task`
      );
    } catch (error) {
      handleFocus();
      Toast(ErrorResponse(error), 'error');

      if (key) {
        await Platform.deleteFileByKey(key);
      }
    } finally {
      setIsLoadingSubmit(false);
    }
  }

  return (
    <Stack spacing={{ base: '9px', md: '55px', lg: '55px' }}>
      <Heading
        color="#FFF"
        fontSize={{ base: '18px', md: '24px', lg: '24px' }}
        margin="0"
        fontWeight="500"
      >
        {content?.title}
      </Heading>

      <Stack spacing={{ base: '30px', md: '30px', lg: '30px' }}>
        <Box
          color="#FFF"
          dangerouslySetInnerHTML={{ __html: content?.content }}
          sx={{
            iframe: {
              width: '100%',
              aspectRatio: '16/9',
            },
            a: { color: '#06c', textDecoration: 'underline' },
            p: { margin: '10px 0' }, // Estilo para parágrafos
            strong: { fontWeight: 'bold' }, // Estilo para texto em negrito
            em: { fontStyle: 'italic' }, // Estilo para texto em itálico
            ul: { listStyleType: 'disc', paddingLeft: '40px' }, // Estilo para lista não ordenada
            ol: { listStyleType: 'decimal', paddingLeft: '40px' }, // Estilo para lista ordenada
            li: { marginBottom: '5px' }, // Estilo para itens de lista
            blockquote: { borderLeft: '5px solid #ccc', paddingLeft: '30px' }, // Estilo para citação
            img: { maxWidth: '100%' }, // Estilo para imagens
          }}
        />

        <FileDownload highlightColor={highlightColor} name={content?.download} />

        <Stack as="form" onSubmit={handleSubmit}>
          {!hasCorrection ? (
            <Stack>
              <HStack width="full" justifyContent="space-between" alignItems="center">
                <Text color="#FFF" fontSize={{ base: '18px', lg: '24px' }} fontWeight="500">
                  {!task?.fileTest ? 'Enviar para correção:' : 'Enviado para correção:'}
                </Text>
                <Status task={task} content={content} />
              </HStack>

              {task?.fileTest && (
                <FileDownloadStudent highlightColor={highlightColor} name={task?.fileNameTest} />
              )}
            </Stack>
          ) : (
            <Feedback task={task} content={content} highlightColor={highlightColor} />
          )}

          {!values.answerFile && !task?.fileTest && (
            <CustomDropzoneNetflixMembersArea
              file={filePDF}
              isDisabled={loading}
              accept={['application/pdf']}
              onDrop={handleDropFilePDF}
              onDropRejected={() => Toast('Arquivo inválido!', 'error')}
              extensions="PDF"
              maxFileSize="25 MB"
              highlightColor={highlightColor}
            />
          )}

          {values.answerFile && (
            <PreviewPDFTask
              filePDF={filePDF}
              setFilePDF={setFilePDF}
              values={values}
              setValues={setValues}
            />
          )}

          {!task?.fileTest && (
            <HStack justifyContent="flex-end" mt="22px">
              <Button
                type="submit"
                w={{ base: '100%', md: 'auto' }}
                size="sm"
                rightIcon={<CheckIcon />}
                bgColor={highlightColor}
                _hover={{ bgColor: highlightColor.concat('80') }}
                color="#FFF"
                isLoading={isLoadingSubmit}
                isDisabled={!changed}
              >
                Enviar arquivo
              </Button>
            </HStack>
          )}
        </Stack>

        <PagingContents canMarkAsCompleted={canMarkAsCompleted} />
      </Stack>
    </Stack>
  );
}

export default ViewTask;
