import { Button, ButtonProps } from '@chakra-ui/react';
import { IoIosArrowBack } from 'react-icons/io';

export default function BackButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      colorScheme="gray"
      variant="solid"
      leftIcon={<IoIosArrowBack />}
      px={{ base: 10, lg: 3 }}
      py={{ base: 4, lg: 5 }}
      size="sm"
      fontSize="sm"
      fontFamily="Montserrat"
      sx={{ outline: 'none' }}
      _focus={{ outline: 'none', boxShadow: 'none' }}
      _active={{ outline: 'none', boxShadow: 'none' }}
      border="1px solid #20212340"
      width={{ base: 'full', md: 'initial' }}
      maxWidth={{ base: '50%', md: 'none' }}
    >
      Voltar
    </Button>
  );
}
