import dayjs from 'dayjs';
import { MdInfoOutline as InfoOutlineIcon } from 'react-icons/md';
import ColorPicker from '../../../../components/ColorPickChakra';
import CustomDropzone from '../../../../components/CustomDropzone';
import PromotionBanner from '../../../../components/PromotionBanner';
import ImageUploadLoading from '../ImageUploadLoading';
import { Promotion } from '../types';
import Editor from './Editor';
import ImagePreview from './ImagePreview';
import Preview from './Preview';
import usePromotionForm from './usePromotionForm';

import {
  Box,
  BoxProps,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Stack,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';

const TODAY = dayjs().format('YYYY-MM-DD');
interface PromotionsFormProps extends BoxProps {
  url: string;
  method: 'post' | 'patch';
  promotion?: Promotion;
  successMessage?: string;
}

export default function PromotionsForm({
  promotion,
  url,
  method,
  successMessage,
  ...rest
}: PromotionsFormProps) {
  const {
    form,
    formValidation,
    isSubmitting,
    isUploading,
    file,
    imagePreview,
    textHighlight,
    dateTime,
    payload,
    hasChange,
    textHighlightLength,
    isTextHighlightInvalid,
    handleChange,
    onSubmit,
    handleSelectNewImage,
    handleDropFile,
    handleDateTimeChange,
    handleTextHighlightChange,
    handleColorChange,
    handleDropRejected,
    handleTypeMainChange,
  } = usePromotionForm({
    method,
    url,
    promotion,
    successMessage,
  });

  return (
    <Box
      as="form"
      onSubmit={onSubmit}
      {...rest}
      sx={{
        label: { fontSize: 'sm', color: '#202123' },
        'input::placeholder': { color: '#2D374880' },
      }}
    >
      <Box as="section">
        <Heading fontSize="xl" fontWeight="medium">
          Informações da Promoção
        </Heading>

        <FormControl isInvalid={formValidation?.title?.isInvalid} mt={4}>
          <FormLabel>
            Título da Promoção{' '}
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>
          <Text color="#20212380">Defina um título para a sua promoção.</Text>
          <Input
            name="title"
            value={form?.title}
            onChange={handleChange}
            placeholder="Digite aqui"
            mt={2}
            focusBorderColor="gray.200"
          />

          <FormErrorMessage>{formValidation?.title?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.description?.isInvalid} mt={8}>
          <FormLabel>Descrição da promoção</FormLabel>
          <Text color="#20212380">Digite uma pequena descrição para a sua promoção.</Text>
          <Input
            name="description"
            value={form?.description}
            onChange={handleChange}
            placeholder="Digite aqui"
            mt={2}
            focusBorderColor="gray.200"
          />

          <FormErrorMessage>{formValidation?.description?.message}</FormErrorMessage>
        </FormControl>
      </Box>

      <Box as="section" mt={8}>
        <Heading fontSize="xl" fontWeight="medium">
          Configuração da Promoção
        </Heading>
        <Text color="#20212380">
          Configure as principais características da faixa da sua promoção e preencha todas as
          informações que serão exibidas nela. Clique aqui e acesse um exemplo de faixa.
        </Text>

        <Stack direction={{ base: 'column', xl: 'row' }} spacing={4} mt={4}>
          <FormControl isInvalid={formValidation?.primaryColor?.isInvalid}>
            <FormLabel>
              Cor primária{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>

            <Text color="#20212380" mb={2}>
              Cor de fundo da faixa que irá conter os detalhes da sua promoção.
            </Text>

            <ColorPicker
              name="primaryColor"
              value={form?.primaryColor}
              onChange={handleColorChange}
              inputSize="full"
              hasInput
            />

            <FormErrorMessage>{formValidation?.primaryColor?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.secondaryColor?.isInvalid}>
            <FormLabel>
              Cor secundária{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>
            <Text color="#20212380" mb={2}>
              Cor do restante das informações da sua faixa de promoção.
            </Text>

            <ColorPicker
              name="secondaryColor"
              value={form?.secondaryColor}
              onChange={handleColorChange}
              inputSize="full"
              hasInput
            />

            <FormErrorMessage>{formValidation?.secondaryColor?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        <FormControl mt={8}>
          <FormLabel>
            Formato de destaque da promoção{' '}
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>
          <Text color="#20212380">
            Selecione o formato do seu destaque da promoção. Você pode selecionar entre uma imagem e
            um texto.
          </Text>

          <Select
            name="typeMain"
            value={form?.typeMain}
            onChange={handleTypeMainChange}
            color="#2D374880"
            focusBorderColor="gray.200"
            mt={2}
          >
            <option value="text">Texto</option>
            <option value="image">Imagem</option>
          </Select>
        </FormControl>

        {form.typeMain === 'text' ? (
          <FormControl isInvalid={formValidation?.textMain?.isInvalid} mt={8}>
            <FormLabel>
              Texto principal{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>
            <Text color="#20212380">Texto em destaque que irá ficar a direita da sua faixa.</Text>
            <Input
              name="textMain"
              value={form?.textMain}
              onChange={handleChange}
              placeholder="Digite aqui"
              mt={2}
              focusBorderColor="gray.200"
              maxLength={35}
            />

            <Stack direction={{ base: 'column', xl: 'row' }} justify="space-between" mt={2}>
              <FormErrorMessage mt={0}>{formValidation?.textMain?.message}</FormErrorMessage>

              <FormHelperText textAlign="end" ml="auto" color="#20212380">
                {form?.textMain?.length}/35 caracteres
              </FormHelperText>
            </Stack>
          </FormControl>
        ) : (
          <>
            <FormControl isInvalid={formValidation?.logoMain?.isInvalid} mt={8}>
              <FormLabel>
                Imagem principal{' '}
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>
              <Text color="#20212380" mb={2}>
                Imagem em destaque que irá ficar a direita da sua faixa.
              </Text>

              {!form?.logoMain ? (
                <Box position="relative">
                  <CustomDropzone
                    file={file}
                    isDisabled={isSubmitting}
                    accept={['image/jpg', 'image/png', 'image/jpeg']}
                    dimensions="360x120"
                    onDrop={handleDropFile}
                    onDropRejected={handleDropRejected}
                    extensions="JPG, PNG, JPEG"
                    maxFileSize="5mb"
                  />

                  <ImageUploadLoading position="absolute" isLoading={isUploading} />
                </Box>
              ) : (
                <>
                  <ImagePreview preview={imagePreview} />

                  <Stack direction={{ base: 'column', xl: 'row' }} mt={2}>
                    <Button
                      onClick={handleSelectNewImage}
                      variant="outline"
                      colorScheme="red"
                      size="sm"
                    >
                      Excluir imagem
                    </Button>

                    <Button onClick={handleSelectNewImage} colorScheme="primary" size="sm">
                      Selecionar nova imagem
                    </Button>
                  </Stack>
                </>
              )}

              <FormErrorMessage>{formValidation?.logoMain?.message}</FormErrorMessage>
            </FormControl>
          </>
        )}

        <FormControl
          isInvalid={formValidation?.textHighlight?.isInvalid || isTextHighlightInvalid}
          mt={8}
        >
          <FormLabel>
            Texto secundário{' '}
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>
          <Text mb={2} color="#20212380">
            Texto auxiliar que ficará ao centro da sua faixa.
          </Text>

          <Editor value={textHighlight} onChange={handleTextHighlightChange} />

          <FormHelperText textAlign="end" color="#20212380"></FormHelperText>

          <Stack direction={{ base: 'column', xl: 'row' }} justify="space-between" mt={2}>
            <FormErrorMessage mt={0}>{formValidation?.textHighlight?.message}</FormErrorMessage>

            {textHighlightLength <= 70 ? (
              <FormHelperText textAlign="end" ml="auto" color="#20212380">
                {!!textHighlightLength && <>{textHighlightLength}/70 caracteres</>}
              </FormHelperText>
            ) : (
              <FormErrorMessage mt={0}>
                Texto secundário deve ter no máximo 70 caracteres.
              </FormErrorMessage>
            )}
          </Stack>
        </FormControl>

        <Stack direction={{ base: 'column', xl: 'row' }} spacing={4} mt={8}>
          <FormControl isInvalid={formValidation?.startTime?.isInvalid}>
            <FormLabel>
              Início{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>
            <Text color="#20212380" mb={2}>
              Defina a data de horário do início da sua promoção.
            </Text>

            <HStack color="#20212380" spacing={1}>
              <Input
                name="startDate"
                value={dateTime?.startDate}
                onChange={handleDateTimeChange}
                type="date"
                focusBorderColor="gray.200"
                min={TODAY}
              />

              <Input
                name="startTime"
                value={dateTime?.startTime}
                onChange={handleDateTimeChange}
                type="time"
                focusBorderColor="gray.200"
              />
            </HStack>

            <FormErrorMessage>{formValidation?.startTime?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formValidation?.endTime?.isInvalid}>
            <FormLabel>
              Fim{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </FormLabel>
            <Text color="#20212380" mb={2}>
              Defina a data de horário do fim da sua promoção.
            </Text>

            <HStack color="#20212380" spacing={1}>
              <Input
                name="endDate"
                value={dateTime?.endDate}
                onChange={handleDateTimeChange}
                type="date"
                focusBorderColor="gray.200"
                min={dayjs().format('YYYY-MM-DD')}
              />

              <Input
                name="endTime"
                value={dateTime?.endTime}
                onChange={handleDateTimeChange}
                type="time"
                focusBorderColor="gray.200"
              />
            </HStack>

            <FormErrorMessage>{formValidation?.endTime?.message}</FormErrorMessage>
          </FormControl>
        </Stack>

        <Preview imagePreview={imagePreview} promotion={payload} mt={8} />

        <PromotionBanner />

        <FormControl mt={2}>
          <HStack align="center" spacing={3}>
            <HStack spacing={1}>
              <FormLabel m={0}>Indicar início da promoção</FormLabel>
              <Tooltip
                placement="top"
                label="Ao ativar a promoção, a faixa com informações cadastradas será exibida imediatamente,
            contendo uma contagem regressiva para o início da promoção. Quando a promoção iniciar, a
            contagem será ajustada para indicar o fim do período promocional."
                hasArrow
                fontSize="xs"
              >
                <Box>
                  <InfoOutlineIcon width="3" height="3" color="#202123" opacity="0.5" />
                </Box>
              </Tooltip>
            </HStack>

            <Switch
              name="indicateStartOfPromotion"
              isChecked={form?.indicateStartOfPromotion}
              onChange={handleChange}
              colorScheme="primary"
            />
          </HStack>
        </FormControl>
      </Box>

      <HStack justify="end" my={10} spacing={5}>
        <Button
          bgColor="#e5e5e5"
          border="1px solid #20212340"
          _hover={{ bgColor: '#c2c2c2' }}
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isDisabled={isSubmitting || isUploading}
        >
          Cancelar
        </Button>

        <Button
          colorScheme="primary"
          type="submit"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isLoading={isSubmitting}
          isDisabled={isUploading || !hasChange}
        >
          Salvar
        </Button>
      </HStack>
    </Box>
  );
}
