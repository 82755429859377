import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Divider,
  Flex,
  HStack,
} from '@chakra-ui/react';
import LoginAsUser from '../../../../components/LoginAsUser';
import CourseProvider from '../../../../contexts/CourseContext';
import AccessCourse from './AccessCourse';
import Affiliation from './Affiliation';
import Configuration from './Configuration';
import HeaderCourseManager from './HeaderCourseManager';
import ModulesManager from './ModulesManager';
import Price from './Price';
import Publish from './Publish';
import ViewAsStudent from './ViewAsStudent';

export default function CourseManager() {
  const { id } = useParams();

  const path = window.location.pathname;

  const isCourseManagerActive = path.includes(`/course-manager/course`);
  const isConfigurationActive = path.includes(`/configuration`);
  const isAccessCourseActive = path.includes(`/access-course`);
  const isPriceActive = path.includes(`/price`);
  const isPublishActive = path.includes(`/publication`);

  return (
    <CourseProvider>
      <Box>
        <Switch>
          <Route exact path="/courses/:id/course-manager*">
            <HeaderCourseManager />

            <LoginAsUser />

            <HStack width="full" justifyContent="space-between" padding="15px">
              <Breadcrumb
                spacing="10px"
                separator={
                  <Center height="25px" width="2px">
                    <Divider orientation="vertical" />
                  </Center>
                }
              >
                <BreadcrumbItem>
                  <BreadcrumbLink
                    as={Link}
                    fontSize="xl"
                    fontWeight="bold"
                    color={isCourseManagerActive ? 'default.500' : 'gray.500'}
                    to={`/courses/${id}/course-manager/course`}
                  >
                    Curso
                  </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink
                    as={Link}
                    fontSize="xl"
                    fontWeight="bold"
                    color={isConfigurationActive ? 'default.500' : 'gray.500'}
                    to={`/courses/${id}/course-manager/configuration/basic-configuration`}
                  >
                    Configurações
                  </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink
                    as={Link}
                    fontSize="xl"
                    fontWeight="bold"
                    color={isAccessCourseActive ? 'default.500' : 'gray.500'}
                    to={`/courses/${id}/course-manager/access-course/access-duration`}
                  >
                    Acesso do produto
                  </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink
                    as={Link}
                    fontSize="xl"
                    fontWeight="bold"
                    color={isPriceActive ? 'default.500' : 'gray.500'}
                    to={`/courses/${id}/course-manager/price/primary-prices`}
                  >
                    Preço
                  </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink
                    as={Link}
                    fontSize="xl"
                    fontWeight="bold"
                    color={isPublishActive ? 'default.500' : 'gray.500'}
                    to={`/courses/${id}/course-manager/publication`}
                  >
                    Publicar
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <ViewAsStudent productId={id} />
            </HStack>
          </Route>
        </Switch>
      </Box>

      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Box width="full">
          <Switch>
            <Route exact path="/courses/:id/course-manager/course*" component={ModulesManager} />
            <Route
              exact
              path="/courses/:id/course-manager/configuration/*"
              component={Configuration}
            />

            <Route
              exact
              path="/courses/:id/course-manager/access-course/*"
              component={AccessCourse}
            />

            <Route exact path="/courses/:id/course-manager/price*" component={Price} />

            <Route exact path="/courses/:id/course-manager/affiliation" component={Affiliation} />

            <Route exact path="/courses/:id/course-manager/publication" component={Publish} />

            <Route exact path={`/courses/${id}/course-manager/configuration*`}>
              <Redirect to={`/courses/${id}/course-manager/configuration/basic-configuration`} />
            </Route>

            <Route exact path={`/courses/${id}/course-manager/access-course*`}>
              <Redirect to={`/courses/${id}/course-manager/access-course/access-duration`} />
            </Route>

            <Route exact path={`/courses/${id}/course-manager/price*`}>
              <Redirect to={`/courses/${id}/course-manager/price/primary-prices`} />
            </Route>

            <Route exact path={`/courses/${id}/course-manager/publication*`}>
              <Redirect to={`/courses/${id}/course-manager/publication`} />
            </Route>

            <Route exact path={`/courses/${id}/course-manager*`}>
              <Redirect to={`/courses/${id}/course-manager/course`} />
            </Route>

            <Route exact path={`/courses/${id}/*`}>
              <Redirect to={`/courses/${id}/course-manager/course`} />
            </Route>

            <Route exact path={`/courses/${id}/*`}>
              <Redirect to={`/courses/${id}/course-manager/course`} />
            </Route>
          </Switch>
        </Box>
      </Flex>
    </CourseProvider>
  );
}
