import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import StudentSignUp from '../../../../StudentSignUp';

export function ModalLogin({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.700" backdropFilter="blur(3px)" />
      <ModalContent overflow="hidden">
        <ModalCloseButton />
        <ModalBody padding={0}>
          <StudentSignUp loginModalQuestions={true} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
