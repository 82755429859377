import {
  Box,
  Drawer,
  DrawerContent,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';

import { Search2Icon } from '@chakra-ui/icons';
import { useRef } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import ProgressBar from '../../../../../../components/ProgressBar';
import { ModuleList } from '../ModuleList';

export default function ModuleMobileSidebar({
  highlightColor,
  showProgress,
  modulesFilters,
  search,
  handleInputChange,
  progress,
  amountContentViewed,
  countContent,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const isDesktop = useBreakpointValue({ base: false, md: true, lg: true });

  return (
    <>
      <Box
        position="fixed"
        hidden={isDesktop}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        bottom={-1}
        right={0}
        borderRadius="15px 15px 0px 0px"
        boxShadow="0px -4px 7px 0px rgba(0, 0, 0, 0.25)"
        width="full"
        height="60px"
        background="#202123"
        ref={btnRef}
        onClick={onOpen}
      >
        <Stack spacing={0} margin="15px" alignItems="center">
          <Icon as={MdKeyboardArrowUp} fontSize="22px" color="#BEC5D2" />
          <Text as="span" fontSize="16px" fontWeight="600" color="#FFFFFF">
            Módulos
          </Text>
        </Stack>
      </Box>

      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} finalFocusRef={btnRef}>
        {/* <DrawerOverlay /> */}
        <DrawerContent
          width="full"
          height="50%"
          color="#FFF"
          background="#202123"
          borderRadius="15px 15px 0px 0px"
          boxShadow="0px -4px 7px 0px rgba(0, 0, 0, 0.25)"
        >
          <Box width="full" padding="15px">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              borderTopRadius={6}
              gap={1}
              marginBottom="35px"
              width="full"
              height="60px"
              ref={btnRef}
              onClick={onClose}
            >
              <Icon as={MdKeyboardArrowDown} fontSize="22px" color="#BEC5D2" />
              <Text as="span" fontSize="16px" fontWeight="600" color="#FFFFFF">
                Módulos
              </Text>

              <Box width="full" background="rgba(175, 175, 175, 0.50)" height="0.5px" />
            </Box>

            <ProgressBar
              showProgress={showProgress}
              highlightColor={highlightColor}
              progress={progress}
              currentContent={amountContentViewed}
              total={countContent}
            />

            <InputGroup marginY="10px">
              <InputLeftElement pointerEvents="none">
                <Search2Icon fontSize="20px" color="#FFFFFF" />
              </InputLeftElement>
              <Input
                type="text"
                focusBorderColor="gray.500"
                borderRadius="6px"
                value={search}
                onChange={handleInputChange}
                placeholder="Buscar módulo/conteúdo"
              />
            </InputGroup>
          </Box>

          <Box
            overflowY="scroll"
            padding="15px"
            h="23.5rem"
            sx={{
              '::-webkit-scrollbar': { width: '0px', background: 'transparent' },
            }}
          >
            <ModuleList modulesFilters={modulesFilters} highlightColor={highlightColor} />
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
}
