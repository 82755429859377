import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';

import { ChangeEvent, FormEvent, useState } from 'react';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import PasswordInput from './PasswordInput';

interface ChangePasswordModalProps extends Partial<ModalProps> {}

interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const initialFormValues: ChangePasswordForm = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export default function ChangePasswordModal({ isOpen, onClose }: ChangePasswordModalProps) {
  const [form, setForm] = useState<ChangePasswordForm>(initialFormValues);
  const [isDirty, setIsDirty] = useState(false);
  const [isConfirmPasswordInValid, setIsConfirmPasswordInValid] = useState(false);

  const isCentered = useBreakpointValue({ base: true, md: false });

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setForm(prevForm => ({ ...prevForm, [name]: value }));
    setIsDirty(true);
  }

  const { handleSubmit, isLoading: isSubmitting } = useHandleSubmit({
    data: form,
    method: 'patch',
    url: '/students/redefine-password',
    authenticated: true,
    removeNullProps: false,
    onSuccess: {
      message: 'Senha alterada com sucesso!',
      callback: () => {
        setForm(initialFormValues);
        onClose();
      },
    },
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    if (form?.newPassword !== form?.confirmPassword) {
      return setIsConfirmPasswordInValid(true);
    }

    await handleSubmit();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered={isCentered}
      closeOnEsc={!isSubmitting}
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay
        bg="none"
        backdropFilter="auto"
        backdropBlur="2px"
        cursor={isSubmitting ? 'not-allowed' : 'default'}
      />

      <ModalContent
        as="form"
        onSubmit={onSubmit}
        backgroundColor="#202123"
        color="white"
        border="1px solid #343639"
        maxWidth={{ base: '94%', md: '38.25rem' }}
        sx={{
          '.chakra-input': {
            backgroundColor: '#37393D',
            border: '1px solid #343639',
            _placeholder: { color: 'white' },
          },
        }}
      >
        <ModalHeader>Alterar a senha</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={false}>
            <FormLabel>Senha antiga</FormLabel>

            <PasswordInput name="oldPassword" value={form?.oldPassword} onChange={handleChange} />
          </FormControl>

          <FormControl mt={4} isInvalid={isConfirmPasswordInValid}>
            <FormLabel>Nova senha</FormLabel>

            <VStack>
              <PasswordInput name="newPassword" value={form?.newPassword} onChange={handleChange} />

              <PasswordInput
                name="confirmPassword"
                value={form?.confirmPassword}
                onChange={handleChange}
              />
            </VStack>

            <FormErrorMessage>As senhas não coincidem</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose} isDisabled={isSubmitting}>
            Cancelar
          </Button>

          <Button
            type="submit"
            colorScheme="primary"
            color="secondary.500"
            isDisabled={!isDirty}
            isLoading={isSubmitting}
          >
            Alterar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
