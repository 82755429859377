import Joi from 'joi';

export const schemaSignUp = Joi.object().keys({
  fullName: Joi.string().required().min(2).max(80).messages({
    'string.empty': `O seu nome não pode estar vazio.`,
    'string.min': `O seu nome deve ter pelo menos 2 caracteres.`,
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .min(2)
    .max(255)
    .messages({
      'string.empty': `O campo "e-mail" não pode ser vazio.`,
      'string.email': `Você deve digitar um e-mail válido. Digite o seu melhor e-mail`,
      'string.min': `O seu e-mail deve ter pelo menos 2 caracteres.`,
    }),
  password: Joi.string().required().min(6).max(60).messages({
    'string.empty': `A sua senha não pode ser vazia.`,
    'string.min': `A sua senha dever ter pelo menos 6 caracteres.`,
  }),
  country: Joi.string().required().messages({
    'string.empty': `O campo país não pode ser vazio.`,
  }),
  ddd: Joi.string()
    .min(1)
    .max(5)
    .messages({
      'string.empty': 'Seu telefone não pode ficar vazio.',
      'string.min': 'Seu telefone deve ter no mínimo 1 dígitos.',
      'string.max': 'Seu telefone deve ter no máximo 5 dígitos.',
    } as JoiMessages.LanguageMessages),
  phone: Joi.string().max(20).required().messages({
    'string.empty': `Seu telefone não pode ser vazio.`,
    'string.max': `O seu telefone deve ter no máximo 20 números.`,
  }),
  isTermsAccepted: Joi.boolean().valid(true).required().messages({
    'any.only': 'Aceite o termos antes de continuar.',
  }),
  ddi: Joi.string().min(1).max(4).required().messages({
    'string.empty': `O campo DDI não pode ser vazio.`,
    'string.min': `O seu DDI deve ter pelo menos 1 número.`,
  }),
});
