import { Avatar, Box, Card, Image, Stack, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../../../../../contexts/ThemeContext';

interface IInstructor {
  id: number;
  fullName: string;
  photo: string;
}

interface IProps {
  instructor: IInstructor;
}

export default function CardInstructs({ instructor }: IProps) {
  const [isHovered, setHovered] = useState(false);
  const { themeMarketplace } = useTheme();
  const { primaryColor, textColor } = themeMarketplace;

  return (
    <Card
      width={{ base: '164px', md: '275px', lg: '275px' }}
      height={{ base: '255px', md: '365px', lg: '365px' }}
      borderRadius="11px 17px 17px 11px"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        transition: 'transform 0.3s ease-in-out',
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
      }}
      overflow="hidden"
    >
      <Stack
        width={{ base: '164px', md: '275px', lg: '275px' }}
        height="100%"
        spacing={0}
        position="relative"
      >
        <Box boxSize={{ base: '164px', md: '275px', lg: '275px' }}>
          {instructor.photo ? (
            <Image
              objectFit="cover"
              src={instructor.photo}
              alt={`Foto de ${instructor.fullName}`}
              borderRadius="11px 17px 0px 0px"
            />
          ) : (
            <Avatar
              boxSize={{ base: '164px', md: '275px', lg: '275px' }}
              borderRadius="11px 17px 17px 11px"
            />
          )}
        </Box>

        <VStack
          flexShrink="0"
          width="full"
          height={{ base: '144px', md: '160px' }}
          position="absolute"
          bottom={0}
          justifyContent="end"
          borderRadius="0px 0px 10.996px 10.996px"
          spacing="14px"
          background={`linear-gradient(0deg, ${primaryColor} 63.25%, rgba(32, 33, 35, 0.00) 96.08%)`}
          px="0.75rem"
          pb={{ base: 5, md: '1.125rem' }}
        >
          <Text
            fontSize={{ base: '14px', md: '16px', lg: '16px' }}
            color={textColor}
            textAlign="start"
            fontWeight={500}
          >
            {instructor.fullName}
          </Text>
          <Text
            fontSize={{ base: '12px', md: '14px', lg: '14px' }}
            color={textColor}
            fontWeight={500}
            cursor="pointer"
            textDecorationLine="underline"
            as={Link}
            to={`/internal-profile/${instructor.id}/instructor`}
          >
            Ver perfil
          </Text>
        </VStack>
      </Stack>
    </Card>
  );
}
