import { useEffect, useState } from 'react';

import APIPageBuilder from '../../../../api/PageBuilder';
import AlertModal from '../../../../components/AlertModal';
import BannerInput from '../components/BannerInput';
import DropdownSection from '../components/DropdownSection';
import ErrorMessage from '../components/ErrorMessage';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { Heading } from '../../../../components/Heading';
import ImagePreview from '../components/ImagePreview';
import Line from '../components/Line';
import { MdAddCircleOutline } from 'react-icons/md';
import Section from '../Section';
import Spinner from '../../../../components/Loadings/Spinner';
import ThemeAPI from '../../../../api/Theme';
import Toast from '../../../../components/Toast';
import Tooltip from '../components/Tooltip';
import UploadHelper from '../../../../helpers/UploadHelper';
import { nanoid } from 'nanoid';
import styles from './styles.module.css';
import { useTheme } from '../../../../contexts/ThemeContext';

const FIRST_FILE = 0;
const MAX_SIZE_FILE_PHOTO = 3145728;

function Testimonials({ section }) {
  const [isActiveSection, setIsActiveSection] = useState(null);
  const [preview, setPreview] = useState(null);
  const [testimonials, setTestimonials] = useState([]);
  const [nameSection, setNameSection] = useState('');

  const [currentTestimony, setCurrentTestimony] = useState(null);
  const [selectedTestimony, setSelectedTestimony] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [errorUploadPhoto, setErrorUploadPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const { theme, getTheme, getThemeMarketplace, isLoading: isLoadingTheme } = useTheme();

  useEffect(() => {
    if (!isLoadingTheme) {
      const testimonialsSection = section;
      setIsActiveSection(!!testimonialsSection.active);

      const testimonials = testimonialsSection.themeTestimonials;
      setTestimonials(testimonials);

      const firstTestimony = testimonials[0];
      setCurrentTestimony(firstTestimony);
      setPreview(firstTestimony?.photo);
      setNameSection(testimonialsSection.nameSection);
    }
  }, [isLoadingTheme, section]);

  useEffect(() => {
    const currentTestimony = testimonials[selectedTestimony];

    if (currentTestimony) {
      setCurrentTestimony(currentTestimony);
      setPreview(currentTestimony.photo);
    }
  }, [selectedTestimony, testimonials]);

  useEffect(() => {
    if (errorMessage && !hasChanged) {
      Toast(errorMessage, 'error');
    }
  }, [errorMessage, hasChanged]);

  function handleInputChange(event) {
    let { name, value } = event.target;

    setErrorMessage(null);
    setHasChanged(true);

    if (name === 'order') value = parseInt(value);

    setCurrentTestimony(prevTestimony => ({ ...prevTestimony, [name]: value }));
  }
  function handleNameSectionChange(event) {
    const { value } = event.target;

    setErrorMessage(null);
    setHasChanged(true);

    setNameSection(value);
  }

  function handlePhotoChange(acceptedFiles) {
    const file = acceptedFiles[FIRST_FILE];

    if (file?.size > MAX_SIZE_FILE_PHOTO) {
      return setErrorUploadPhoto('O tamanho da imagem deve ser de no máximo 3 MB.');
    }

    if (file) {
      setPreview(URL.createObjectURL(file));

      setErrorUploadPhoto(null);
      setErrorMessage(null);
      setHasChanged(true);

      setCurrentTestimony(prevTestimony => ({ ...prevTestimony, photo: file }));
    }
  }

  function handleDiscardChanges(event, testimonialsArray) {
    event.preventDefault();

    if (isAdding) {
      testimonialsArray.pop();
    }

    setHasChanged(false);

    const testimonialsSection = section;
    const testimonials = testimonialsSection.themeTestimonials;

    setTestimonials(testimonials);

    const firstTestimony = testimonials[0];
    setCurrentTestimony(firstTestimony);
    setNameSection(testimonialsSection.nameSection);

    setPreview(firstTestimony?.photo);
    setErrorUploadPhoto(null);
    setErrorMessage(null);
    setIsAdding(false);
  }

  function handleSelectedTestimonyChange(event) {
    const { value } = event.target;

    setErrorUploadPhoto(null);
    setErrorMessage(null);

    setSelectedTestimony(value);
  }

  function handleImageDropRejected() {
    setErrorUploadPhoto('Arquivo inválido!');
  }

  function handleImageChange() {
    setHasChanged(true);
    setPreview(null);
  }

  function addTestimony() {
    setIsAdding(true);
    setHasChanged(true);
    setPreview(null);

    testimonials.push({ name: '', order: testimonials.length + 1, testimony: '', photo: null });
    const recentAddedTestimony = testimonials[testimonials.length - 1];

    setSelectedTestimony(testimonials.length - 1);

    setCurrentTestimony(recentAddedTestimony);
  }

  async function handleDelete(event) {
    event.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);
    setErrorUploadPhoto(null);

    try {
      await ThemeAPI.deleteTestimony(theme.id, section.id, currentTestimony.id);

      setCurrentTestimony(testimonials[0]);
      setSelectedTestimony(testimonials[0]);
      setPreview(testimonials[0].photo);

      Toast(`Depoimento excluído com sucesso`);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
      setIsLoading(false);
      setIsShowDeleteModal(false);
    }
  }

  function toggleModal(event) {
    event.preventDefault();
    setIsShowDeleteModal(!isShowDeleteModal);
  }

  function toggleVisibilitySection(event) {
    const { checked } = event.target;

    setHasChanged(true);

    setIsActiveSection(!checked);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    let payload;

    const testimonyId = currentTestimony?.id;

    let method = testimonyId ? 'update' : 'store';

    if (nameSection === '') {
      Toast('O nome da sua seção não pode ser vazio!', 'error');
      return;
    }

    if (currentTestimony) {
      payload = {
        ...currentTestimony,
        activeSection: isActiveSection,
        nameSection,
      };
    } else {
      payload = {
        activeSection: isActiveSection,
        nameSection,
      };

      method = 'update';
    }

    try {
      if (currentTestimony?.photo && typeof currentTestimony?.photo !== 'string') {
        const { newFileName, link } = await UploadHelper.upload(
          currentTestimony.photo,
          'testimony-photos'
        );

        payload.photo = newFileName;

        setCurrentTestimony({
          ...currentTestimony,
          photo: link,
        });
      }

      if (method === 'store') {
        if (!currentTestimony.photo) {
          Toast('Você deve escolher uma foto', 'warn');
          return;
        }

        await ThemeAPI.storeTestimony(theme.id, section.id, payload);
      }

      if (method === 'update') {
        await ThemeAPI.updateTestimonials(theme.id, section.id, currentTestimony.id, payload);
      }

      const successMessage = method === 'store' ? 'criado' : 'atualizado';

      Toast(`Depoimento ${successMessage} com sucesso`);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setHasChanged(false);
      setIsLoading(false);
      setIsAdding(false);
    }
  }

  async function handleDuplicateSectionTestimonials() {
    setErrorMessage('');

    try {
      const theme_id = theme.id;

      const section_id = section.id;

      await APIPageBuilder.testimony.store(theme_id, section_id);

      Toast('Seção Depoimentos duplicada com sucesso!');

      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
    }
  }

  async function handleDeleteSectionTestimonials() {
    setErrorMessage('');

    try {
      const theme_id = theme.id;

      const section_id = section.id;

      await APIPageBuilder.testimony.delete(theme_id, section_id);

      Toast('Sessão Depoimentos deletada com sucesso!');

      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
    }
  }

  return (
    <Section
      description="Compartilhe os melhores comentários de seus alunos sobre seus produtos ou experiências. Lembre-se de colocar imagens de qualidade e padronizadas. Um dica: caso possua materiais com sua logo sempre peça para os alunos tirarem fotos com eles, isso vai gerar ainda mais autoridade para a sua marca."
      actionName="Depoimento"
    >
      <div className={styles.sectionHeader}>
        <div className="d-flex align-items-center">
          <Heading as="h4" fontSize="2xl">
            Depoimentos
          </Heading>
          <Line />
          <Tooltip link="https://youtu.be/vSx0yiR8rHU" />
        </div>

        <div className="d-flex align-items-center">
          <div className={styles.actionsHeaderContainer}>
            <p>Depoimento:</p>
            <select
              onChange={handleSelectedTestimonyChange}
              disabled={isAdding}
              value={selectedTestimony}
            >
              {testimonials?.map((_testimony, index) => (
                <option key={nanoid()} value={index}>
                  {index + 1}
                </option>
              ))}
            </select>
            <p>Ordem:</p>

            <select
              name="order"
              onChange={handleInputChange}
              value={currentTestimony?.order}
              disabled={isAdding}
            >
              {testimonials?.map(testimony => (
                <option key={`#testimony${testimony.id}`} value={testimony.order}>
                  {testimony.order}
                </option>
              ))}
            </select>
            <button onClick={addTestimony} disabled={isAdding} style={{ fontSize: '0.75rem' }}>
              <MdAddCircleOutline size="1.125rem" /> Adicionar depoimento
            </button>
          </div>

          <DropdownSection
            onClickToDuplicate={handleDuplicateSectionTestimonials}
            onClickToDelete={handleDeleteSectionTestimonials}
          />
        </div>
      </div>
      <div className={styles.testimonialsContainer}>
        <form onSubmit={handleSubmit}>
          <div>
            <section className={styles.testimonialsImage}>
              <label htmlFor="photo">Foto</label>

              {!preview ? (
                <BannerInput
                  preview={preview}
                  theme={theme}
                  onDrop={handlePhotoChange}
                  onDropRejected={handleImageDropRejected}
                  onChangeImage={handleImageChange}
                  isDisabled={!testimonials?.length > 0}
                  imageDimensions="1080x1080"
                />
              ) : (
                <ImagePreview
                  preview={preview}
                  onClick={handleImageChange}
                  isLoading={isLoadingTheme}
                />
              )}

              {errorUploadPhoto && <ErrorMessage message={errorUploadPhoto} />}
            </section>

            <section className={styles.formSection}>
              {/* <label htmlFor="nameSection">Nome da seção</label>
              <input
                id="nameSection"
                name="nameSection"
                onChange={handleInputChange}
                value={currentTestimony?.name}
                placeholder="Escreva o nome."
                minLength={5}
                maxLength={255}
                disabled={!testimonials?.length > 0}
                required
              /> */}
              <label>Nome da seção</label>
              <input
                minLength={1}
                maxLength={25}
                value={nameSection}
                name={nameSection}
                onChange={handleNameSectionChange}
                placeholder='Escreva como você quer renomear a seção "Depoimentos".'
              ></input>
              <label htmlFor="name">Nome</label>
              <input
                id="name"
                name="name"
                onChange={handleInputChange}
                value={currentTestimony?.name}
                placeholder="Escreva o nome."
                minLength={5}
                maxLength={255}
                disabled={!testimonials?.length > 0}
                required
              />

              <label htmlFor="testimony">Depoimento</label>
              <textarea
                id="testimony"
                name="testimony"
                onChange={handleInputChange}
                value={currentTestimony?.testimony}
                placeholder="Escreva o depoimento"
                rows={20}
                minLength={5}
                maxLength={1000}
                disabled={!testimonials?.length > 0}
                required
              />
              {isAdding && (
                <p>
                  Os depoimentos são cadastrados com o status "Inativo". Para exibir na página
                  inicial, altere o status para "Ativo".
                </p>
              )}
            </section>
          </div>

          <div className={styles.actions}>
            {!isAdding && testimonials?.length ? (
              <select
                name="status"
                value={currentTestimony?.status}
                onChange={handleInputChange}
                className={styles.select}
              >
                <option value="ATIVO">ATIVO</option>
                <option value="INATIVO">INATIVO</option>
              </select>
            ) : (
              ''
            )}

            {!isAdding && testimonials?.length ? (
              <button className={styles.deleteButton} onClick={toggleModal}>
                Excluir
              </button>
            ) : (
              ''
            )}

            <button
              className={styles.cancelButton}
              onClick={event => handleDiscardChanges(event, testimonials)}
              disabled={!hasChanged || isLoading}
            >
              Cancelar
            </button>

            <button type="submit" disabled={!hasChanged || isLoading || nameSection === ''}>
              {isLoading ? <Spinner small /> : 'Salvar alterações'}
            </button>
          </div>

          <div className={styles.footer}>
            <div>
              <input
                type="checkbox"
                id="isActiveSectionTestimonials"
                name="isActiveSectionTestimonials"
                onChange={toggleVisibilitySection}
                checked={!isActiveSection}
              />
              <label htmlFor="isActiveSectionTestimonials">Ocultar seção da página inicial</label>
            </div>
          </div>
        </form>
      </div>

      <AlertModal
        showModal={isShowDeleteModal}
        onHideModal={() => setIsShowDeleteModal(false)}
        title={`Você tem certeza que deseja excluir este depoimento?`}
        actionButton={{ nameActionButton: 'Excluir', onActionButton: handleDelete }}
        actionButtonClassName="btn-danger"
        cancelableButton={{
          nameCancelableButton: 'cancelar',
          onCancelableButton: () => setIsShowDeleteModal(false),
        }}
      />
    </Section>
  );
}

export default Testimonials;
