import styles from './styles.module.css';

interface Props {
  title: string;
  className?: string;
}

function DefaultImageProduct({ title = 'Sem título', className = '' }: Props) {
  return (
    <div
      className={`${styles.defaultImageProduct} ${className}`}
      style={{
        background: 'primary.500',
      }}
    >
      <div>
        <span style={{ color: 'secondary.500' }}>{title.substring(0, 1).toUpperCase()}</span>
      </div>
    </div>
  );
}

export default DefaultImageProduct;
