import Joi from 'joi';

export type EditModuleSchemaType = {
  name: string;
  status: string;
};

export const editModuleSchemaValidator = Joi.object<EditModuleSchemaType>().keys({
  name: Joi.string()
    .min(5)
    .required()
    .messages({
      'string.min': 'O nome do deve ter pelo menos 5 caracteres.',
      'string.base': 'O nome do módulo é obrigatório.',
      'string.empty': 'O nome do módulo é obrigatório',
      'any.required': 'O nome do módulo é obrigatório',
    } as JoiMessages.LanguageMessages),
  status: Joi.string()
    .min(5)
    .required()
    .messages({
      'string.min': 'O status do deve ter pelo menos 5 caracteres.',
      'string.base': 'O status do módulo é obrigatório.',
      'string.empty': 'O status do módulo é obrigatório',
      'any.required': 'O status do módulo é obrigatório',
    } as JoiMessages.LanguageMessages),
});
