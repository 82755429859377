import { useRef, useState } from 'react';

import { IoIosArrowForward as ArrowRigth } from 'react-icons/io';
import styles from './styles.module.css';

function AccordionMarketplace({ title, content }) {
  const [active, setActive] = useState('');
  const [height, setHeight] = useState('0px');

  const containerRef = useRef(null);

  function toggleAccordion() {
    setActive(active === '' ? 'active' : '');
    setHeight(active === 'active' ? '0px' : `${containerRef.current.scrollHeight}px`);
  }

  return (
    <div className={styles.accordionItem}>
      <button
        type="button"
        className={`${styles.accordionButton} ${active}`}
        onClick={toggleAccordion}
      >
        <ArrowRigth size={24} color="#fff" className={active && `${styles.rotate}`} />
        <span>{title}</span>
      </button>
      <div
        ref={containerRef}
        className={styles.accordionItemContainer}
        style={{ maxHeight: height }}
      >
        <div className={styles.accordionContent}>
          <div>{content}</div>
        </div>
      </div>
    </div>
  );
}

export default AccordionMarketplace;
