import { CSSProperties, MouseEvent, useMemo } from 'react';
import { imageFileTypes, videoFileTypes } from './mediaAcceptedFileTypes';

import getAllowedFileFormats from './getAllowedFileFormats';
import { useDropzone } from 'react-dropzone';

type MediaType = 'image' | 'video';

interface IProps {
  isDisabled: boolean;
  mediaDimensions: string;
  mediaType: MediaType;
  onDrop: () => void;
  onDropRejected: () => void;
}

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1.25rem',
  borderWidth: 2,
  borderColor: '#AEADAD',
  borderStyle: 'dashed',
  backgroundColor: '#f6f5f5',
  outline: 'none',
  transition: 'all .24s ease-in-out',
};

const acceptStyle: CSSProperties = {
  borderColor: '#19bc90',
  backgroundColor: '#e4fbf5',
};

const rejectStyle: CSSProperties = {
  borderColor: '#c3463c',
  backgroundColor: '#fceae9',
  borderStyle: 'solid',
  color: '#c3463c',
};

function MediaInput({ isDisabled, mediaType, onDrop, onDropRejected }: IProps) {
  const acceptedFilesTypes = mediaType === 'image' ? imageFileTypes : videoFileTypes;

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    accept: acceptedFilesTypes,
    noClick: true,
    noKeyboard: true,
    onDrop,
    onDropRejected,
  });

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (isDisabled) return;
    open();
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragReject, isDragAccept]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />

      <p className="text-center">Arraste e solte um arquivo aqui ou</p>

      <button
        onClick={handleClick}
        className="mb-2"
        style={{ fontSize: '0.75rem' }}
        disabled={isDisabled}
      >
        Selecionar arquivo
      </button>

      <p className="text-center" style={{ color: '#EB7129' }}>
        Extensões válidas: <br /> {getAllowedFileFormats(mediaType)} <br /> Dimensões:{' '}
        {mediaType === 'image' ? '1920 x 840 pixels' : '1920 x 1080 pixels'}. <br /> Tamanho máx.{' '}
        {mediaType === 'image' ? '3' : '500'} MB
      </p>
    </div>
  );
}

export default MediaInput;
