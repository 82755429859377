import Joi from 'joi';

export type AddPlanSchemaType = {
  showcaseId: number;
  plansIds: number[];
  showcaseType: 'products' | 'plans';
  showcaseViewType?: 'standard' | 'highlight';
  titleHighlight?: string;
  description?: string;
  imageDesktop?: string;
  imageMobile?: string;
};

export const AddPlanSchemaValidator = Joi.object<AddPlanSchemaType>().keys({
  showcaseId: Joi.number().integer().required(),
  showcaseType: Joi.string().valid('products', 'plans').required(),
  plansIds: Joi.array().items(Joi.number().integer().positive()).min(1).required().messages({
    'array.min': 'Selecione pelo menos um plano.',
  }),
  showcaseViewType: Joi.when('showcaseType', {
    is: 'plans',
    then: Joi.string().valid('standard', 'highlight').required(),
    otherwise: Joi.forbidden(),
  }),
  titleHighlight: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().required(),
    otherwise: Joi.forbidden(),
  }),
  description: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().required(),
    otherwise: Joi.forbidden(),
  }),
  imageDesktop: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().required(),
    otherwise: Joi.forbidden(),
  }),
  imageMobile: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().optional(),
    otherwise: Joi.forbidden(),
  }),
});
