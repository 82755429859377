import { Flex, Heading, HStack, IconButton } from '@chakra-ui/react';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import Loading from '../Loading';
import PromotionsForm from '../PromotionsForm';
import { Promotion } from '../types';

export default function EditPromotion() {
  const { id: promotionId } = useParams<{ id: string }>();

  const { data: response, loading: isLoading } = useFetch<UnificadaFront.ResponseJSON<Promotion>>({
    method: 'get',
    url: `/promotion/${promotionId}`,
    authenticated: true,
    autoFetch: true,
  });

  const promotion = response?.data;
  const url = `/promotion/${promotionId}`;

  const isFormVisible = !isLoading && response?.data;

  return (
    <>
      <Flex justify="space-between" w="full" wrap="wrap">
        <HStack spacing={{ base: 0.25, xl: 0.5 }} ml={{ base: -2, xl: 0 }}>
          <IconButton
            as={Link}
            to={'/promotions'}
            icon={<ChevronLeftIcon size="1.75rem" />}
            aria-label="Voltar"
            variant="ghost"
            colorScheme="gray"
            size="sm"
          />

          <Heading as="h1" fontSize={{ base: 'lg', lg: '2xl' }} fontWeight="semibold" m={0}>
            Editar Promoção
          </Heading>
        </HStack>
      </Flex>

      {isLoading && <Loading />}

      {isFormVisible && (
        <PromotionsForm
          url={url}
          method="patch"
          successMessage="Promoção atualizada com sucesso!"
          promotion={promotion}
          mt={10}
        />
      )}
    </>
  );
}
