import { ApexOptions } from 'apexcharts';

const options = {
  labels: ['Acertos', 'Erros'],
  colors: ['#4BBE58', '#CC595B'],
  legend: {
    show: false,
    position: 'bottom',
    horizontalAlign: 'center',
    fontFamily: 'Montserrat',
    itemMargin: {
      horizontal: 8,
      vertical: 8,
    },
  },
  plotOptions: {
    pie: {
      donut: {
        size: '40%',
      },
    },
  },
  stroke: {
    width: 0,
  },
  dataLabels: {
    enabled: false,
  },
} as ApexOptions;

export default options;
