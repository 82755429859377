import {
  Avatar,
  Button,
  DrawerBody,
  DrawerHeader,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';

import { MdPersonOutline as LoginIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { NAME_LOCAL_STORAGE, PHOTO_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import NavMenu from '../NavMenu';

interface SidebarProps {
  isAuthenticated: boolean;
  onChangeToProductsMenusTab: () => void;
}

export default function Sidebar({ isAuthenticated, onChangeToProductsMenusTab }: SidebarProps) {
  const fullName = localStorage.getItem(NAME_LOCAL_STORAGE);
  const photo = localStorage.getItem(PHOTO_LOCAL_STORAGE);

  return (
    <>
      <DrawerHeader boxShadow="lg" display="flex" pl={4} pr={7} bg="primary.500">
        <VStack spacing={3}>
          <HStack spacing={3} align="start">
            <Avatar
              bg="secondary.500"
              color="primary.500"
              src={photo}
              icon={<Icon as={LoginIcon} boxSize="22px" />}
            />

            <VStack color="secondary.500" align="start" spacing={!isAuthenticated ? 1 : 0}>
              <Text as="h2" fontSize="xs" fontWeight="semibold" mb={0} isTruncated noOfLines={2}>
                {!isAuthenticated ? 'Bem-vindo(a)' : fullName}
              </Text>

              <Text fontSize="0.625rem" fontWeight="normal">
                {!isAuthenticated
                  ? 'Realize login ou se cadastre clicando nos botões abaixo.'
                  : 'Bem-vindo(a)'}
              </Text>
            </VStack>
          </HStack>

          {!isAuthenticated && (
            <HStack w="full" sx={{ button: { borderRadius: '2px', w: 'full' } }} spacing={3}>
              <Button
                as={Link}
                to="/cadastre-se"
                size="sm"
                color="primary.500"
                bg="secondary.500"
                w="full"
              >
                Cadastrar
              </Button>

              <Button
                as={Link}
                to="/login"
                variant="outline"
                bg="transparent"
                border="1px solid"
                borderColor="secondary.500"
                size="sm"
                color="secondary.500"
                w="full"
                _focus={{ bg: 'transparent' }}
              >
                Entrar
              </Button>
            </HStack>
          )}
        </VStack>
      </DrawerHeader>

      <DrawerBody bg="#ffffff" px={4} pt="1.875rem">
        <NavMenu
          isAuthenticated={isAuthenticated}
          onChangeToProductsMenusTab={onChangeToProductsMenusTab}
        />
      </DrawerBody>
    </>
  );
}
