import { Box, Button, FormControl, FormLabel, HStack, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { IoChevronForward } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import {
  IInitialFilters,
  IQuestionLibraryAreaData,
  IQuestionLibraryDisciplineData,
  IQuestionLibrarySubjectData,
} from '../../../DashboardAdmin/Courses/CourseManager/ModulesManager/QuestionLibrary/types';
import SelectList from './SelectList';

interface IParams {
  areaListData: IQuestionLibraryAreaData[];
  disciplineListData: IQuestionLibraryDisciplineData[];
  subjectListData: IQuestionLibrarySubjectData[];
  institutionData: { id: number; name: string }[];
  filters: IInitialFilters;
  questionsLength?: number;
  buttonVariant?: string;
  alternateTextColor?: string;
  setFilters?: ({ areaId, disciplineId, subjectId }: IInitialFilters) => void;
  setCurrentPage?: (page: number) => void;
}

export default function FilterQuestions({
  areaListData,
  disciplineListData,
  subjectListData,
  institutionData,
  questionsLength,
  buttonVariant,
  alternateTextColor,
}: IParams) {
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);

  const initialFilters = {
    area: {
      id: urlSearchParams.get('filterAreaId') || '',
      name: getFilterNameById(urlSearchParams.get('filterAreaId') || '', 'area'),
    },
    discipline: {
      id: urlSearchParams.get('filterDisciplineId') || '',
      name: getFilterNameById(urlSearchParams.get('filterDisciplineId') || '', 'discipline'),
    },
    subject: {
      id: urlSearchParams.get('filterSubjectId') || '',
      name: getFilterNameById(urlSearchParams.get('filterSubjectId') || '', 'subject'),
    },
    institution: {
      id: urlSearchParams.get('filterInstitutionId') || '',
      name: getFilterNameById(urlSearchParams.get('filterInstitutionId') || '', 'institution'),
    },
  };

  const hasAreaFilter = !!initialFilters.area.id;
  const hasDisciplineFilter = !!initialFilters.discipline.id;

  function getInitialDisciplineListDataFiltered() {
    return hasAreaFilter
      ? disciplineListData.filter(
          discipline => discipline.area.id === parseInt(initialFilters?.area.id)
        )
      : disciplineListData;
  }

  function getInitialSubjectListDataFiltered() {
    return hasDisciplineFilter
      ? subjectListData.filter(
          subject => subject.discipline.id === parseInt(initialFilters?.discipline.id)
        )
      : subjectListData;
  }

  const [selectFilters, setSelectFilters] = useState(initialFilters);

  const [disciplineListDataFiltered, setDisciplineListDataFiltered] = useState(() =>
    getInitialDisciplineListDataFiltered()
  );

  const [subjectListDataFiltered, setSubjectListDataFiltered] = useState(() =>
    getInitialSubjectListDataFiltered()
  );

  const history = useHistory();

  function onFilterChange(name: string, value: string) {
    if (name === 'area') {
      if (!value) {
        setDisciplineListDataFiltered(subjectListData);
        setSubjectListDataFiltered(subjectListData);
        return;
      }

      const area = areaListData.find(area => area.id === parseInt(value));

      const disciplineList = disciplineListData.filter(
        discipline => discipline.area.id === parseInt(value)
      );

      if (disciplineList) {
        const subjectList = subjectListData.filter(subject => subject.area.id === parseInt(value));
        setDisciplineListDataFiltered(disciplineList);
        setSubjectListDataFiltered(subjectList);
      }

      setSelectFilters({
        ...selectFilters,
        area: {
          ...selectFilters.area,
          id: value,
          name: area.name,
        },
        discipline: {
          ...selectFilters.area,
          id: '',
          name: '',
        },
        subject: {
          ...selectFilters.area,
          id: '',
          name: '',
        },
      });
    }

    if (name === 'discipline') {
      if (!value) {
        setSubjectListDataFiltered(subjectListData);
        return;
      }

      const discipline = disciplineListData.find(discipline => discipline.id === parseInt(value));

      const area = areaListData.find(areaId => areaId.id === discipline.area.id);

      const subjectList = subjectListData.filter(
        subject => subject.discipline.id === parseInt(value)
      );

      setSubjectListDataFiltered(subjectList);

      setSelectFilters({
        ...selectFilters,
        area: {
          id: area.id.toString(),
          name: area.name,
        },
        discipline: {
          id: value,
          name: discipline.name,
        },
      });
    }

    if (name === 'subject') {
      const subject = subjectListData.find(subject => subject.id === parseInt(value));

      const discipline = disciplineListData.find(
        discipline => discipline.id === subject.discipline.id
      );

      const area = areaListData.find(areaId => areaId.id === discipline.area.id);

      setSelectFilters({
        ...selectFilters,
        area: {
          id: area.id.toString(),
          name: area.name,
        },
        discipline: {
          id: discipline.id.toString(),
          name: discipline.name,
        },
        subject: {
          id: value,
          name: subject.name,
        },
      });

      setDisciplineListDataFiltered([discipline]);
    }
  }

  function getFilterNameById(id: string, filter: string) {
    if (filter === 'area') {
      const area = areaListData?.find(area => area.id === parseInt(id));
      return area?.name;
    }

    if (filter === 'discipline') {
      const discipline = disciplineListData?.find(discipline => discipline.id === parseInt(id));
      return discipline?.name;
    }

    if (filter === 'subject') {
      const subject = subjectListData?.find(subject => subject.id === parseInt(id));
      return subject?.name;
    }

    if (filter === 'institution') {
      const institution = institutionData?.find(institution => institution.id === parseInt(id));
      return institution?.name;
    }

    return '';
  }

  function handleFilter() {
    const params: Record<string, string> = {
      page: '1',
      filterAreaId: selectFilters?.area?.id,
      filterDisciplineId: selectFilters?.discipline?.id,
      filterSubjectId: selectFilters?.subject?.id,
      filterInstitutionId: selectFilters?.institution?.id,
    };

    for (const [key, value] of Object.entries(params)) {
      if (!value) {
        urlSearchParams.delete(key);
        continue;
      }

      if (value) {
        urlSearchParams.set(key, value);
      }
    }

    history.push({ search: urlSearchParams.toString() });
  }

  function removeFilter(filter: string) {
    const propertiesToUpdate = {
      area: ['area', 'discipline', 'subject'],
      discipline: ['discipline', 'subject'],
      subject: ['subject'],
      institution: ['institution'],
    };

    const properties = propertiesToUpdate[filter];

    if (!properties) return;

    const updatedFilters = { ...selectFilters };

    properties.forEach(property => {
      updatedFilters[property] = { id: '', name: '' };
    });

    setSelectFilters(updatedFilters);

    if (filter === 'area') {
      history.push({ search: '' });
    }
  }

  return (
    <Box>
      <Stack direction={{ base: 'column', lg: 'row' }} width="full" alignItems="center">
        <HStack width="full" align="center">
          <FormControl>
            <FormLabel fontSize="14px">Área</FormLabel>
            <SelectList
              title="Selecione"
              selectName="area"
              filterData={areaListData}
              selectFilters={selectFilters}
              setSelectFilters={setSelectFilters}
              onFilterChange={onFilterChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontSize="14px">Disciplina</FormLabel>
            <SelectList
              title="Selecione"
              selectName="discipline"
              filterData={disciplineListDataFiltered}
              selectFilters={selectFilters}
              setSelectFilters={setSelectFilters}
              onFilterChange={onFilterChange}
            />
          </FormControl>
        </HStack>

        <HStack width="full" alignItems="center">
          <FormControl>
            <FormLabel fontSize="14px">Assunto</FormLabel>
            <SelectList
              title="Selecione"
              selectName="subject"
              filterData={subjectListDataFiltered}
              selectFilters={selectFilters}
              setSelectFilters={setSelectFilters}
              onFilterChange={onFilterChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel fontSize="14px">Banca</FormLabel>
            <SelectList
              title="Selecione"
              selectName="institution"
              filterData={institutionData}
              selectFilters={selectFilters}
              setSelectFilters={setSelectFilters}
              onFilterChange={onFilterChange}
            />
          </FormControl>
        </HStack>
      </Stack>

      <Box marginY="18px">
        <Stack direction="row" justifyContent="space-between" spacing={1} alignItems="center">
          <Stack
            direction="row"
            flex={1}
            spacing={0}
            gap={2}
            alignItems="center"
            width="full"
            flexWrap="wrap"
          >
            <Text fontWeight={600} fontSize="14px">
              Filtrar por:
            </Text>

            {!!selectFilters.area.name && (
              <Stack
                direction="row"
                alignItems="center"
                maxWidth={250}
                onClick={() => removeFilter('area')}
                color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
                fontWeight={400}
                paddingY="2px"
                paddingX="6px"
                fontSize="12px"
                borderRadius={7}
                border="1px solid #20212380"
                _hover={{
                  fontWeight: 500,
                  color: '#EC7117',
                  border: '1px solid #EC7117',
                  cursor: 'pointer',
                  transition: 'all 0.4s ease',
                }}
              >
                <Text isTruncated>{selectFilters.area.name}</Text>
                <IoIosClose size={20} />
              </Stack>
            )}

            {!!selectFilters.area.name &&
              (!!selectFilters.discipline.name || !!selectFilters.subject.name) && (
                <Text
                  color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
                  fontWeight={400}
                  fontSize="12px"
                >
                  <IoChevronForward size={15} />
                </Text>
              )}

            {!!selectFilters.discipline.name && (
              <Stack
                direction="row"
                alignItems="center"
                maxWidth={250}
                onClick={() => removeFilter('discipline')}
                color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
                fontWeight={400}
                paddingY="2px"
                paddingX="6px"
                fontSize="12px"
                border="1px solid #20212380"
                borderRadius={7}
                _hover={{
                  fontWeight: 500,
                  color: '#EC7117',
                  border: '1px solid #EC7117',
                  cursor: 'pointer',
                  transition: 'all 0.4s ease',
                }}
              >
                <Text isTruncated>{selectFilters.discipline.name}</Text>
                <IoIosClose size={20} />
              </Stack>
            )}

            {!!selectFilters.discipline.name &&
              (!!selectFilters.subject.name || !!selectFilters.institution.name) && (
                <Text
                  color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
                  fontWeight={400}
                  fontSize="12px"
                >
                  <IoChevronForward size={15} />
                </Text>
              )}

            {!!selectFilters.subject.name && (
              <Stack
                direction="row"
                alignItems="center"
                maxWidth={250}
                onClick={() => removeFilter('subject')}
                color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
                fontWeight={400}
                paddingY="2px"
                paddingX="6px"
                fontSize="12px"
                borderRadius={7}
                border="1px solid #20212380"
                _hover={{
                  fontWeight: 500,
                  color: '#EC7117',
                  border: '1px solid #EC7117',
                  cursor: 'pointer',
                  transition: 'all 0.4s ease',
                }}
              >
                <Text isTruncated>{selectFilters.subject.name}</Text>
                <IoIosClose size={20} />
              </Stack>
            )}

            {!!selectFilters.subject.name && !!selectFilters.institution.name && (
              <Text
                color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
                fontWeight={400}
                fontSize="12px"
              >
                <IoChevronForward size={15} />
              </Text>
            )}

            {!!selectFilters.institution.name && (
              <Stack
                direction="row"
                maxWidth={250}
                alignItems="center"
                onClick={() => removeFilter('institution')}
                color={alternateTextColor ? alternateTextColor : '#AFAFAF'}
                fontWeight={400}
                paddingY="2px"
                paddingX="6px"
                fontSize="12px"
                border="1px solid #20212380"
                borderRadius={7}
                hidden={selectFilters.institution.name === ''}
                _hover={{
                  fontWeight: 500,
                  color: '#EC7117',
                  border: '1px solid #EC7117',
                  cursor: 'pointer',
                  transition: 'all 0.4s ease',
                }}
              >
                <Text isTruncated>{selectFilters.institution.name}</Text>
                <IoIosClose size={20} />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>

      <Box display="flex" justifyContent="flex-end" width="full">
        <Button
          bgColor="#EC7117"
          color="#FFFFFF"
          size="sm"
          fontSize="16px"
          fontWeight="600"
          lineHeight="24px"
          variant={buttonVariant ? buttonVariant : 'solid'}
          isDisabled={questionsLength === 0}
          onClick={handleFilter}
        >
          Filtrar
        </Button>
      </Box>
    </Box>
  );
}
