import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { Select as MultiSelect } from 'chakra-react-select';
import { lazy, Suspense } from 'react';
import CustomDropzone from '../../../../components/CustomDropzone';
import Editor from '../../../../components/Editor';
import { onlyNumber } from '../../../../hooks/useHandleChange';
import { JoiSchemasType } from '../../../../validation/EntitySchema';
import { Instructor, InstructorForm as InstructorSchemaValidatorProps } from '../types';
import { getCountryByDDI } from './CountrySelect';
import ImagePreview from './ImagePreview';
import ImageUploadLoading from './ImageUploadLoading';
import useInstructorForm from './useInstructorForm';
const CountrySelect = lazy(() => import('./CountrySelect'));

const TEXT_EDITOR_STYLES = {
  '.ql-toolbar': {
    borderRadius: '6px 6px 0px 0px',
    borderColor: '#D2D2D2 !important',
  },
  '.ql-container': {
    borderRadius: '0px 0px 6px 6px',
    borderColor: '#D2D2D2 !important',
  },
};

interface InstructorFormProps extends BoxProps {
  url: string;
  method: 'post' | 'patch';
  instructor?: Instructor;
  successMessage?: string;
  schemaValidator: JoiSchemasType<Partial<InstructorSchemaValidatorProps>>;
}

export default function InstructorForm({
  instructor,
  url,
  method,
  successMessage,
  schemaValidator,
  ...rest
}: InstructorFormProps) {
  const {
    form,
    formValidation,
    isSubmitting,
    isUploading,
    coverBanner,
    photo,
    hasChange,
    disciplines,
    selectedDisciplines,
    handleChange,
    onSubmit,
    handleSelectNewImage,
    handleDropFile,
    handleDropRejected,
    handleDescriptionChange,
    handleSelectDiscipline,
    handleDisciplineRemove,
    handleSocialNetworkChange,
    handleCancel,
    handleCountryChange,
  } = useInstructorForm({
    method,
    url,
    instructor,
    successMessage,
    schemaValidator,
  });

  return (
    <Box
      as="form"
      onSubmit={onSubmit}
      {...rest}
      sx={{
        label: { fontSize: 'sm', color: '#202123' },
        'input::placeholder,textarea::placeholder': { color: '#2D374880' },
      }}
      noValidate
    >
      <Box as="section">
        <Stack direction={{ base: 'column', xl: 'row' }} spacing={5}>
          <FormControl
            isInvalid={formValidation?.photo?.isInvalid}
            maxWidth={{ base: 'unset', xl: '16.75rem' }}
          >
            <Flex position="relative" height={{ base: '23.5625rem', xl: '16.75rem' }}>
              {!form?.photo ? (
                <>
                  <CustomDropzone
                    file={photo?.file}
                    isDisabled={isSubmitting}
                    accept={['image/jpg', 'image/png', 'image/jpeg']}
                    dimensions="1080x1080"
                    onDrop={acceptedFiles => handleDropFile('photo', acceptedFiles)}
                    onDropRejected={handleDropRejected}
                    extensions="JPG, PNG, JPEG"
                    maxFileSize="5MB"
                  />

                  <ImageUploadLoading position="absolute" isLoading={isUploading} />
                </>
              ) : (
                <VStack>
                  <Box height={{ base: '21.5625rem', xl: '16.75rem' }}>
                    <ImagePreview
                      name="photo"
                      preview={photo?.preview}
                      onSelectNewImage={handleSelectNewImage}
                    />
                  </Box>

                  <Button
                    display={{ base: 'flex', xl: 'none' }}
                    onClick={() => handleSelectNewImage('photo')}
                    w="full"
                    variant="outline"
                    colorScheme="primary"
                    size="sm"
                    zIndex="2"
                  >
                    Trocar Imagem
                  </Button>
                </VStack>
              )}
            </Flex>

            <FormErrorMessage>{formValidation?.photo?.message}</FormErrorMessage>
          </FormControl>

          <VStack w="full" justify="space-between" spacing={5}>
            <FormControl isInvalid={formValidation?.fullName?.isInvalid}>
              <FormLabel>
                Nome do instrutor{' '}
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <Input
                name="fullName"
                value={form?.fullName}
                onChange={handleChange}
                placeholder="Digite aqui"
                mt={2}
                focusBorderColor="gray.300"
              />

              <FormErrorMessage>{formValidation?.fullName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.email?.isInvalid} mt={4}>
              <FormLabel>
                Email{' '}
                <Text as="span" color="red.500">
                  *
                </Text>
              </FormLabel>

              <Input
                name="email"
                value={form?.email}
                onChange={handleChange}
                placeholder="Digite aqui"
                mt={2}
                focusBorderColor="gray.300"
                type="email"
              />

              <FormErrorMessage>{formValidation?.email?.message}</FormErrorMessage>
            </FormControl>

            <Stack direction={{ base: 'column', xl: 'row' }} w="full" spacing={5}>
              <FormControl>
                <FormLabel htmlFor="country">País</FormLabel>

                <Suspense fallback={<Skeleton w="full" h={10} />}>
                  <CountrySelect value={getCountryByDDI(form.ddi)} onChange={handleCountryChange} />
                </Suspense>
              </FormControl>

              <FormControl
                isInvalid={!!formValidation?.phone?.isInvalid || !!formValidation?.ddd?.isInvalid}
              >
                <VStack w="full" align="start">
                  <FormLabel htmlFor="ddd" mb={0}>
                    Telefone
                  </FormLabel>

                  <HStack w="full">
                    <Input
                      name="ddd"
                      value={form.ddd}
                      onChange={handleChange}
                      placeholder="DDD"
                      type="tel"
                      focusBorderColor="gray.300"
                      hidden={form.ddi !== '55'}
                      maxW="33.33%"
                      onInput={onlyNumber}
                      maxLength={2}
                    />

                    <Input
                      name="phone"
                      value={form.phone}
                      onChange={handleChange}
                      type="tel"
                      placeholder="Digite seu telefone"
                      focusBorderColor="gray.300"
                      onInput={onlyNumber}
                      maxLength={20}
                    />
                  </HStack>
                </VStack>

                <FormErrorMessage>
                  {formValidation?.ddd?.message || formValidation?.phone?.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </VStack>
        </Stack>

        <FormControl isInvalid={formValidation?.description?.isInvalid} mt={4}>
          <FormLabel>Descrição</FormLabel>

          <Text color="#20212380" mb={2}>
            Faça uma breve descrição sobre o seu instrutor.
          </Text>

          <Box mt={2} sx={TEXT_EDITOR_STYLES}>
            <Editor value={form?.description} onChange={handleDescriptionChange} />
          </Box>

          <FormErrorMessage>{formValidation?.description?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.coverBanner?.isInvalid} mt={8}>
          <FormLabel>Capa</FormLabel>

          <Text color="#20212380" mb={2}>
            Imagem que será adicionada como capa na página de instrutores.
          </Text>

          <Flex position="relative">
            {!form?.coverBanner ? (
              <>
                <CustomDropzone
                  file={coverBanner?.file}
                  isDisabled={isSubmitting}
                  accept={['image/jpg', 'image/png', 'image/jpeg']}
                  dimensions="1366x762"
                  onDrop={acceptedFiles => handleDropFile('coverBanner', acceptedFiles)}
                  onDropRejected={handleDropRejected}
                  extensions="JPG, PNG, JPEG"
                  maxFileSize="5MB"
                />

                <ImageUploadLoading position="absolute" isLoading={isUploading} />
              </>
            ) : (
              <VStack w="full">
                <Box height={{ base: '4.5rem', xl: '13rem' }} w="full">
                  <ImagePreview
                    name="coverBanner"
                    preview={coverBanner?.preview}
                    onSelectNewImage={handleSelectNewImage}
                  />
                </Box>

                <Button
                  display={{ base: 'flex', xl: 'none' }}
                  onClick={() => handleSelectNewImage('coverBanner')}
                  w="full"
                  variant="outline"
                  colorScheme="primary"
                  size="sm"
                  zIndex="2"
                >
                  Trocar Imagem
                </Button>
              </VStack>
            )}
          </Flex>

          <FormErrorMessage>{formValidation?.coverBanner?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.fullName?.isInvalid} mt={8}>
          <FormLabel>Disciplinas</FormLabel>

          <MultiSelect
            menuPlacement="bottom"
            placeholder="Selecione"
            isMulti
            focusBorderColor="gray.300"
            hasStickyGroupHeaders
            selectedOptionColor="primary"
            noOptionsMessage={() => 'Nenhum instrutor encontrado.'}
            value={[]}
            options={disciplines}
            onChange={handleSelectDiscipline}
            chakraStyles={{
              container: provided => ({
                ...provided,
                width: '100%',
                color: '#20212380',
                fontSize: 'sm',
                _focusWithin: {
                  zIndex: 1,
                  borderColor: 'gray.300',
                  boxShadow: '0 0 0 1px var(--chakra-colors-primary-200)',
                  borderRadius: 'md',
                },
              }),
              placeholder: provided => ({
                ...provided,
                color: '#20212380',
                fontSize: 'sm',
              }),
              dropdownIndicator: provided => ({
                ...provided,
                bg: 'transparent',
                color: '#202123',
                px: 2.5,
              }),
              indicatorSeparator: provided => ({
                ...provided,
                display: 'none',
              }),
              option: provided => ({
                ...provided,
                color: '#20212380',
                fontSize: 'sm',
              }),
              valueContainer: provided => ({
                ...provided,
                px: 3,
                color: '#20212380',
                fontSize: 'sm',
              }),
            }}
          />

          <HStack align="center" flexWrap="wrap" flex={1} marginTop={1} spacing={1} gap={1}>
            {selectedDisciplines?.map(discipline => (
              <Flex
                key={discipline?.id}
                justify="center"
                align="center"
                paddingX={2}
                paddingY={0.5}
                gap={1}
                maxWidth="13rem"
                backgroundColor="#20212325"
                borderRadius={6}
              >
                <Text fontWeight="medium" fontSize="xs" color="#202123" isTruncated>
                  {discipline.name}
                </Text>

                <CloseButton
                  onClick={() => handleDisciplineRemove(discipline?.id)}
                  size="sm"
                  sx={{ svg: { boxSize: '6.36px' } }}
                  boxSize={4}
                />
              </Flex>
            ))}
          </HStack>
        </FormControl>
      </Box>

      <Accordion mt={8} allowToggle gap={8} sx={{ '.chakra-accordion__item': { border: 'none' } }}>
        {/* REDES SOCIAIS */}
        <AccordionItem as="section">
          <AccordionButton px={0} _hover={{ bg: 'transparent' }}>
            <VStack align="start" w="full">
              <HStack spacing={0}>
                <Text fontSize="xl" fontWeight="medium">
                  Redes Sociais
                </Text>
                <AccordionIcon />
              </HStack>
            </VStack>
          </AccordionButton>

          <AccordionPanel p={0}>
            <Text color="#20212380" textAlign="left">
              Essas informações irão aparecer na página de instrutor desse instrutor.
            </Text>

            <Stack direction={{ base: 'column', xl: 'row' }} spacing={{ base: 8, xl: 5 }} mt={8}>
              <FormControl>
                <FormLabel>Instagram</FormLabel>

                <Input
                  name="INSTAGRAM"
                  value={form.socialNetwork?.find(({ type }) => type === 'INSTAGRAM')?.url}
                  onChange={handleSocialNetworkChange}
                  placeholder="Digite aqui"
                  mt={2}
                  focusBorderColor="gray.300"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Facebook</FormLabel>

                <Input
                  name="FACEBOOK"
                  value={form.socialNetwork?.find(({ type }) => type === 'FACEBOOK')?.url}
                  onChange={handleSocialNetworkChange}
                  placeholder="Digite aqui"
                  mt={2}
                  focusBorderColor="gray.300"
                />
              </FormControl>
            </Stack>

            <Stack direction={{ base: 'column', xl: 'row' }} spacing={{ base: 8, xl: 5 }} mt={8}>
              <FormControl>
                <FormLabel>YouTube</FormLabel>

                <Input
                  name="YOUTUBE"
                  value={form.socialNetwork?.find(({ type }) => type === 'YOUTUBE')?.url}
                  onChange={handleSocialNetworkChange}
                  placeholder="Digite aqui"
                  mt={2}
                  focusBorderColor="gray.300"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Linkedin</FormLabel>

                <Input
                  name="LINKEDIN"
                  value={form.socialNetwork?.find(({ type }) => type === 'LINKEDIN')?.url}
                  onChange={handleSocialNetworkChange}
                  placeholder="Digite aqui"
                  mt={2}
                  focusBorderColor="gray.300"
                />
              </FormControl>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* RODAPÉ */}
      <HStack as="footer" justify="end" my={10} spacing={5}>
        <Button
          onClick={handleCancel}
          bgColor="#e5e5e5"
          border="1px solid #20212340"
          _hover={{ bgColor: '#c2c2c2' }}
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isDisabled={isSubmitting || isUploading}
        >
          Cancelar
        </Button>

        <Button
          colorScheme="primary"
          color="secondary.500"
          type="submit"
          size={{ base: 'sm', xl: 'md' }}
          w={{ base: 'full', xl: 'initial' }}
          isLoading={isSubmitting}
          isDisabled={isUploading || !hasChange}
        >
          Salvar
        </Button>
      </HStack>
    </Box>
  );
}
