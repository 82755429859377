import { Button, Heading, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PublicationsTab from './PublicationsTab';
import SettingsTab from './SettingsTab';

function Blog() {
  return (
    <Stack>
      <Stack direction="row" width="full" alignItems="center" justifyContent="space-between">
        <Heading fontSize="4xl">Blog</Heading>

        <Button
          as={Link}
          to="/internal-blog/post/new"
          colorScheme="primary"
          size="sm"
          color="secondary.500"
          leftIcon={<FiPlus />}
        >
          Nova Publicação
        </Button>
      </Stack>

      <Tabs colorScheme="primary" variant="solid-rounded" isLazy>
        <TabList>
          <Stack direction="row">
            <Tab>Publicações</Tab>
            <Tab>Configurações</Tab>
          </Stack>
        </TabList>

        <TabPanels>
          <TabPanel>
            <PublicationsTab />
          </TabPanel>
          <TabPanel>
            <SettingsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export default Blog;
