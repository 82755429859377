import { Flex, Stack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import Authentication from '../../api/Authentication';
import { TOKEN_ORIGIN_LOGIN } from '../../helpers/LocalStorageHelper';

export default function LoginAsUser() {
  const tokenOrigin = localStorage.getItem(TOKEN_ORIGIN_LOGIN);
  const history = useHistory();

  return (
    <Flex
      left={0}
      w="full"
      bg="#E85347"
      py="0.344rem"
      pl={{ lg: '18.25rem', '2xl': '36rem' }}
      hidden={!tokenOrigin}
    >
      <Stack
        direction="row"
        spacing="30px"
        justifyContent="center"
        width="full"
        alignItems="center"
        marginX="10px"
      >
        <Text
          flexShrink="0"
          color="#FFF"
          fontSize={{ base: '10px', md: '14px', lg: '14px' }}
          width={{ base: '300px', md: 'full', lg: 'full' }}
          whiteSpace="pre-wrap"
          fontWeight="500"
          lineHeight="normal"
          border="2px solid #E85347"
          borderRadius="6px"
          filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
        >
          Você está acessando como um outro usuário. Para retornar ao seu acesso,{' '}
          <Text
            as="span"
            cursor="pointer"
            textDecoration="underline"
            fontWeight={700}
            onClick={() => {
              Authentication.logoutAsUser(history);
            }}
          >
            clique aqui.
          </Text>
        </Text>
      </Stack>
    </Flex>
  );
}
