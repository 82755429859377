import { Icon, IconProps } from '@chakra-ui/react';

export default function CouponIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path
        d="M13.274 6.71967L7.27398 0.719674C7.03398 0.479674 6.70065 0.333008 6.33398 0.333008H1.66732C0.933984 0.333008 0.333984 0.933008 0.333984 1.66634V6.33301C0.333984 6.69967 0.480651 7.03301 0.727318 7.27967L6.72732 13.2797C6.96732 13.5197 7.30065 13.6663 7.66732 13.6663C8.03398 13.6663 8.36732 13.5197 8.60732 13.273L13.274 8.60634C13.5207 8.36634 13.6673 8.03301 13.6673 7.66634C13.6673 7.29967 13.514 6.95967 13.274 6.71967ZM7.66732 12.3397L1.66732 6.33301V1.66634H6.33398V1.65967L12.334 7.65967L7.66732 12.3397V12.3397Z"
        fill="#EC7117"
      />
      <path
        d="M3.33398 4.33301C3.88627 4.33301 4.33398 3.88529 4.33398 3.33301C4.33398 2.78072 3.88627 2.33301 3.33398 2.33301C2.7817 2.33301 2.33398 2.78072 2.33398 3.33301C2.33398 3.88529 2.7817 4.33301 3.33398 4.33301Z"
        fill="#EC7117"
      />
    </Icon>
  );
}
