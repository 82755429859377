import { HStack, List, ListItem, VStack, Text, Box, Stack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { AnswerHistoric as AnswerHistoricType } from '../..';
import ChartTitle from '../ChartTitle';

interface StatisticsTabPanelProps {
  historic: AnswerHistoricType[];
}

export default function AnswerHistoric({ historic }: StatisticsTabPanelProps) {
  const sortedHistoric = historic?.sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1;
    if (a.createdAt < b.createdAt) return 1;
    return 0;
  });

  return (
    <VStack align="start" spacing={2.5} w="full">
      <ChartTitle title="Histórico de respostas" />

      <List w="full" spacing={2.5}>
        {sortedHistoric?.map(item => (
          <ListItem key={item.createdAt} w="full">
            <HStack align={{ base: 'start', lg: 'center' }}>
              <Box
                bg={item.answerResult ? '#4BBE58' : '#BB2124'}
                boxSize={3}
                borderRadius="sm"
                mt={{ base: 1.5, lg: 0 }}
                flexShrink={0}
              />

              <Stack
                direction={{ base: 'column', lg: 'row' }}
                spacing={0}
                justify="space-between"
                align={{ base: 'start', lg: 'center' }}
                w="full"
              >
                <Text as="span" fontSize="md">
                  Você {item.answerResult ? 'acertou' : 'errou'}, marcou o{' '}
                  <Text as="strong" fontWeight="semibold">
                    item {item.option}
                  </Text>
                </Text>

                <Text color="#20212380" fontWeight="medium">
                  {dayjs(item.createdAt).format('DD/MM/YYYY H:mm')}
                </Text>
              </Stack>
            </HStack>
          </ListItem>
        ))}
      </List>
    </VStack>
  );
}
