import { VStack, Flex, Heading, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export default function PromotionsHeader() {
  return (
    <VStack as="header" spacing={5}>
      <Flex justify="space-between" w="full" wrap="wrap">
        <Heading as="h1" fontSize={{ base: '2xl', lg: '4xl' }} fontWeight="semibold" m={0}>
          Promoções
        </Heading>

        <Button
          as={Link}
          to="/promotions/new"
          colorScheme="primary"
          color="secondary.500"
          order={{ base: 2, lg: 0 }}
          w={{ base: 'full', lg: 'initial' }}
          mt={{ base: 8, lg: 'initial' }}
          size="sm"
        >
          Nova promoção
        </Button>

        <Text
          color="#20212380"
          fontSize={{ base: 'sm', lg: 'lg' }}
          mt={{ base: 2.5, lg: 5 }}
          lineHeight="1.25"
        >
          Desenvolva promoções para abranger todo o seu site. Estabeleça datas de início e término
          para as promoções. Configure uma faixa com uma contagem regressiva para destacar suas
          ofertas especiais.
        </Text>
      </Flex>
    </VStack>
  );
}
