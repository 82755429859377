export const TEXT_EDITOR_STYLES = {
  '.ql-toolbar': {
    borderRadius: '6px 6px 0px 0px',
    borderColor: '#D2D2D2 !important',
  },
  '.ql-container': {
    borderRadius: '0px 0px 6px 6px',
    borderColor: '#D2D2D2 !important',
  },
};

export const THREE_MEGABYTES = 3 * 1024 * 1024;
