import { Box, Text, useMediaQuery } from '@chakra-ui/react';
import { MouseEvent } from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import useShoppingCart from '../../hooks/useShoppingCart';
import Tooltip from './Tooltip';

function ShoppingCartIcon({ blockMarginLeft = false }) {
  const location = useLocation();
  const { themeMarketplace } = useTheme();
  const { totalItemsInShoppingCart } = useAuth();
  const [isMobileScreen] = useMediaQuery('(max-width: 992px)');

  const { getShoppingCartFromLocalStorage } = useShoppingCart();

  const path = location.pathname;

  function handleClick(event: MouseEvent<HTMLAnchorElement>) {
    if (
      !getShoppingCartFromLocalStorage() ||
      !totalItemsInShoppingCart ||
      path.includes('checkout')
    ) {
      event.preventDefault();
      return;
      // onUpdateTotalItemsInShoppingCart(0);
    }
  }

  return (
    <Tooltip isDisabled={totalItemsInShoppingCart > 0}>
      <Link
        to="/student-checkout/v3"
        onClick={handleClick}
        style={{ marginLeft: blockMarginLeft ? 0 : 8 }}
      >
        <Box position="relative" width={isMobileScreen ? '26px' : '30px'}>
          <AiOutlineShoppingCart
            size={isMobileScreen ? '26px' : '30px'}
            color={themeMarketplace.textColor}
          />
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            position="absolute"
            top={{ base: -1.5, lg: -2.5 }}
            left="75%"
            boxSize={4}
            borderRadius="full"
            bgColor={themeMarketplace.textColor}
            color={themeMarketplace.primaryColor}
            p={{ base: 1, lg: 2.5 }}
          >
            {totalItemsInShoppingCart > 99 ? (
              <Text fontSize={7} fontWeight={600}>
                99+
              </Text>
            ) : (
              <Text fontSize={{ base: 8, lg: 10 }} fontWeight={600}>
                {totalItemsInShoppingCart || '0'}
              </Text>
            )}
          </Box>
        </Box>
      </Link>
    </Tooltip>
  );
}

export default ShoppingCartIcon;
