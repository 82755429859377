import { axiosBackend } from '../../../../../api/Configuration';

interface IUpdateOptionsOrderParams {
  questionId: number;
  payload: {
    optionOrders: { optionId: number; option: number }[];
  };
}

export default {
  updateOptionsOrder: async ({ questionId, payload }: IUpdateOptionsOrderParams) => {
    const { data } = await axiosBackend().patch(
      `/question-library/${questionId}/question/order-options`,
      payload
    );

    return data;
  },

  duplicateQuestion: async (questionId: number) => {
    const { data } = await axiosBackend().post(
      `/question-library/${questionId}/question/duplicate-question`
    );

    return data;
  },
};
