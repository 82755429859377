import { MdChevronLeft } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Paginate from '../../../../components/Paginate';
import SearchInput from './SearchInput';
import WebhooksMobileList from './WebhooksMobileList';
import WebhooksTable from './WebhooksTable';
import { useWebhook } from './useWebhook';

import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  IconButton,
  Link,
  Show,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import WebhookTypeModal from './WebhookTypeModal';

const SUPPORT_LINK =
  'https://proluno.freshdesk.com/support/solutions/articles/150000177022-como-realizar-a-integrac%C3%A3o-com-o-webhook-proluno';

export default function Webhook() {
  const { push } = useHistory();

  const {
    isOpen: isAddWebhookModalOpen,
    onOpen: onAddWebhookModalOpen,
    onClose: onAddWebhookModalClose,
  } = useDisclosure();

  const {
    webhooks,
    urlSearchParams,
    isNotFoundVisible,
    isLoading,
    isPaginationVisible,
    isWebhookListVisible,
    isEmpty,
    currentPage,
    pageCount,
    handleSearchChange,
    handlePageChange,
    handleDeleteWebhook,
    handleStatusWebhook,
  } = useWebhook();

  return (
    <Box>
      <Box>
        <HStack justify="space-between" align="center" spacing={4} w="full">
          <HStack align="center" spacing={{ base: 0, xl: 0.5 }} ml={-2}>
            <IconButton
              onClick={() => push('/integrations')}
              icon={<MdChevronLeft size="1.75rem" />}
              aria-label="Voltar"
              variant="ghost"
              colorScheme="gray"
              size="sm"
            />

            <Heading
              as="h1"
              fontSize={{ base: 'lg', xl: '2xl' }}
              fontWeight="semibold"
              m={0}
              color="#202123"
            >
              Webhook
            </Heading>
          </HStack>

          <Show above="xl">
            <Button
              onClick={onAddWebhookModalOpen}
              mt={{ base: 8, xl: 'unset' }}
              w={{ base: 'full', xl: 'initial' }}
              colorScheme="primary"
              color="secondary.500"
            >
              + Novo Webhook
            </Button>
          </Show>
        </HStack>

        <Text
          mt={{ base: 1, xl: 4 }}
          color="#20212380"
          fontSize={{ base: 'sm', xl: 'lg' }}
          whiteSpace="pre-wrap"
        >
          Integre seus sistemas externos e fique sempre atualizado sobre suas vendas com o Webhook
          da Proluno.{' '}
          <Link href={SUPPORT_LINK} isExternal textDecoration="underline">
            Clique aqui
          </Link>{' '}
          para entender melhor o funcionamento.
        </Text>

        <Show below="xl">
          <Button
            onClick={onAddWebhookModalOpen}
            mt={{ base: 8, xl: 'unset' }}
            w={{ base: 'full', xl: 'initial' }}
            colorScheme="primary"
            color="secondary.500"
          >
            + Novo Webhook
          </Button>
        </Show>
      </Box>

      {!isEmpty && (
        <SearchInput
          value={urlSearchParams.get('search')}
          onSearch={handleSearchChange}
          mt="1.875rem"
        />
      )}

      {isLoading && (
        <Center my={20}>
          <Spinner size="xl" color="primary.500" />
        </Center>
      )}

      {isNotFoundVisible && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.300">
            Nenhum resultado encontrado...
          </Heading>
        </Center>
      )}

      {isEmpty && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.300" textAlign="center">
            Você não possui nenhum Webhook cadastrado.
          </Heading>
        </Center>
      )}

      {isWebhookListVisible && (
        <>
          <WebhooksTable
            display={{ base: 'none', xl: 'block' }}
            webhooks={webhooks}
            mt={1}
            onDeleteWebhook={handleDeleteWebhook}
            onChangeStatus={handleStatusWebhook}
          />

          <WebhooksMobileList
            webhooks={webhooks}
            onDeleteWebhook={handleDeleteWebhook}
            onChangeStatus={handleStatusWebhook}
            display={{ base: 'block', xl: 'none' }}
            mt={2.5}
          />
        </>
      )}

      {isPaginationVisible && (
        <Paginate
          pageCount={pageCount}
          initialPage={currentPage}
          onPageChange={handlePageChange}
          mt={{ base: 8, xl: 4 }}
        />
      )}

      <WebhookTypeModal isOpen={isAddWebhookModalOpen} onClose={onAddWebhookModalClose} />
    </Box>
  );
}
