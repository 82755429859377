import { Box, Button, Card, Stack, Text } from '@chakra-ui/react';
import convertToBrazilianCurrency from '../../../../../helpers/convertToBrazilianCurrency';
import { OrderBumpType, ProductListType, ProductPriceType } from '..';

export default function CardPreview({
  form,
  courseListData,
  courseId,
  coursePriceData,
  coursePriceId,
}: {
  form: OrderBumpType;
  courseId: number;
  coursePriceId: number;
  courseListData: ProductListType[];
  coursePriceData: ProductPriceType[];
}) {
  const coursePrice = coursePriceData?.find(
    coursePrice => parseInt(coursePrice.id.toString()) === parseInt(coursePriceId?.toString())
  );

  const courseSelect = courseListData?.find(
    course => parseInt(course.id.toString()) === parseInt(courseId?.toString())
  );

  return (
    <Card boxShadow="0px 4px 4px rgba(0, 0, 0, 0.1)" padding={5}>
      <Stack direction="column" spacing={1}>
        <Text fontWeight={600} fontSize="18px" width={400}>
          {form.title ? form.title : 'Título de destaque'}
        </Text>
        <Text fontWeight={500} fontSize="16px" paddingY={4} width={400}>
          {form.description ? form.description : 'Texto auxiliar'}
        </Text>
      </Stack>
      <Stack direction="row" spacing={7}>
        {courseSelect?.thumbnail ? (
          <Box
            boxSize="130px"
            backgroundImage={`url("${courseSelect.thumbnail}")`}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            borderRadius={4}
          />
        ) : (
          <Box boxSize={132} bgColor="#D9D9D9" display="flex" alignItems="center">
            <Text fontWeight={500} textAlign="center" fontSize="18px">
              Imagem do produto
            </Text>
          </Box>
        )}

        <Stack direction="column" spacing={5} justifyContent="space-between">
          <Stack direction="column" spacing={2}>
            <Text fontWeight={500} fontSize={{ base: '16px', md: '16px', lg: '18px' }}>
              {courseSelect ? courseSelect.name : 'Nome do produto'}
            </Text>
            <Text fontWeight={500} fontSize="16px">
              {coursePrice?.purchasePrice
                ? convertToBrazilianCurrency(coursePrice?.purchasePrice)
                : 'R$ --,--'}
            </Text>
          </Stack>
          <Button
            size="sm"
            _hover={{ backgroundColor: form.buttonColor.concat('80') }}
            padding={2}
            backgroundColor={form.buttonColor}
            color={form.buttonTextColor}
          >
            {form.buttonText ? form.buttonText : 'Texto do botão'}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
