import {
  Badge,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';

export default function ModalNewUser({
  isOpen,
  onClose,
  setUserId,
  setRoleName,
  size,
  handleNewUser,
  onChange,
  newUser,
  setNewUser,
  loadingModal,
  initialValues,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleNewUser}>
        <ModalHeader fontSize="18px" fontWeight={700}>
          Cadastrar usuário
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={2} marginBottom={5}>
            <Text fontSize="14px" fontWeight={500}>
              E-mail
            </Text>
            <Input
              type="email"
              focusBorderColor="orange.400"
              placeholder="Coloque aqui o e-mail para qual deseja enviar o convite"
              value={newUser?.email}
              name="email"
              onChange={onChange}
              isRequired
            />
          </Stack>

          <RadioGroup onChange={setRoleName} value={newUser?.role?.name} colorScheme="orange">
            <Stack direction="column" spacing={5}>
              <Radio onChange={onChange} name="role" value="AFILIADO" size="md">
                <Text fontWeight={700} fontSize="14px">
                  Afiliado:{' '}
                  <Text as="span" fontWeight={400}>
                    Acesso ao Extratos, Transações, Edição de Perfil, Dados Bancários e menu de
                    afiliados.
                  </Text>
                  <Badge marginX="2" colorScheme="green">
                    Novo
                  </Badge>
                </Text>
              </Radio>

              <Radio onChange={onChange} name="role" value="GESTOR" size="md">
                <Text fontWeight={700} fontSize="14px">
                  Gestor:{' '}
                  <Text as="span" fontWeight={400}>
                    Acesso e liberdade de criação e edição total em todas as funcionalidades da
                    ferramenta. Com exceção da exclusão de usuários do tipo Dono.
                  </Text>
                </Text>
              </Radio>

              <Radio onChange={onChange} name="role" value="FINANCEIRO" size="md">
                <Text fontWeight={700} fontSize="14px">
                  Financeiro:{' '}
                  <Text as="span" fontWeight={400}>
                    Acesso à Dashboard, todas as funções do Financeiro & Vendas e Histórico de
                    Pagamentos.
                  </Text>
                </Text>
              </Radio>

              <Radio onChange={onChange} name="role" value="MARKETING" size="md">
                <Text fontWeight={700} fontSize="14px">
                  Marketing:{' '}
                  <Text as="span" fontWeight={400}>
                    Acesso a todas as funções do Marketing, ao Gerenciamento de Produtos e Construa
                    Seu Site.
                  </Text>
                </Text>
              </Radio>

              <Radio onChange={onChange} name="role" value="SUPORTE" size="md">
                <Text fontWeight={700} fontSize="14px">
                  Suporte:{' '}
                  <Text as="span" fontWeight={400}>
                    Acesso a todas funções do Gerenciamento de Produtos, Transações, Todas as
                    funções do Marketing, Base de Alunos.
                  </Text>
                </Text>
              </Radio>

              <Radio onChange={onChange} name="role" value="BLOGUEIRO" size="md">
                <Text fontWeight={700} fontSize="14px">
                  Blogueiro:{' '}
                  <Text as="span" fontWeight={400}>
                    Acesso ao Blog
                  </Text>
                </Text>
              </Radio>

              <Radio onChange={onChange} name="role" value="INSTRUTOR" size="md">
                <Text fontWeight={700} fontSize="14px">
                  Instrutor:{' '}
                  <Text as="span" fontWeight={400}>
                    Acesso a um produto na qual ele foi selecionado como responsável e
                    compartilhamento de receitas.
                  </Text>
                </Text>
              </Radio>

              <Radio onChange={onChange} name="role" value="SEDE" size="md">
                <Text fontWeight={700} fontSize="14px">
                  Gestor de Polo/Sede:{' '}
                  <Text as="span" fontWeight={400}>
                    Acesso aos produtos no qual foi definido como responsável, Extratos, Transações,
                    Edição de Perfil, Dados Bancários e compartilhamento de receitas.
                  </Text>
                </Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </ModalBody>

        <ModalFooter>
          <HStack marginTop={10} justifyContent={{ base: 'center', md: 'end', lg: 'end' }}>
            <Button
              size="sm"
              width={{ base: 'full', md: 'auto', lg: 'auto' }}
              colorScheme="gray"
              onClick={() => {
                setNewUser(initialValues);
                setRoleName(null);
                setUserId(null);
                onClose();
              }}
              isDisabled={loadingModal}
            >
              Cancelar
            </Button>
            <Button
              size="sm"
              width={{ base: 'full', md: 'auto', lg: 'auto' }}
              colorScheme="primary"
              type="submit"
              isDisabled={loadingModal}
              color="secondary.500"
              isLoading={loadingModal}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
