import { ReactNode } from 'react';

import styles from './styles.module.css';

interface ISectionProps {
  children: ReactNode;
  background: string;
}

function Section({ background, children }: ISectionProps) {
  return (
    <section className={styles.sectionContainer} style={{ background: background }}>
      <div className={styles.sectionContent}>{children}</div>
    </section>
  );
}

export default Section;
