import { IconButton, Menu, MenuButton, MenuItem, MenuList, Td, Text, Tr } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { memo } from 'react';
import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Subscriber } from '../../..';
import convertToBrazilianCurrency from '../../../../../../../../../helpers/convertToBrazilianCurrency';
import { getPaymentMethodText } from '../../../utils/getPaymentMethodText';
import { getStatusText } from '../../../utils/getStatusText';
import { getStatusColor } from '../../../utils/getStatusColor';

interface SubscriberTableRowProps {
  subscriber: Subscriber;
  onSelectSubscriberToBeCanceled: (subscriber: Subscriber) => void;
}

function SubscriberTableRow({
  subscriber,
  onSelectSubscriberToBeCanceled,
}: SubscriberTableRowProps) {
  return (
    <Tr color="#202123" sx={{ td: { p: 6 } }}>
      {/* Nome */}
      <Td maxWidth="264px">
        <Text isTruncated fontWeight="medium">
          {subscriber.fullName}
        </Text>
        <Text isTruncated>{subscriber.email}</Text>
      </Td>

      {/* Forma de pagamento */}
      <Td maxWidth="168px">
        <Text>{getPaymentMethodText(subscriber.paymentMethod)}</Text>
        <Text>{convertToBrazilianCurrency(subscriber.price)}</Text>
      </Td>

      {/* Período */}
      <Td maxWidth="221px">
        <Text>
          De{' '}
          <Text as="strong" fontWeight="medium">
            {dayjs(subscriber.currentPeriodStart).format('DD/MM/YYYY')}
          </Text>
        </Text>
        <Text>
          até{' '}
          <Text as="strong" fontWeight="medium">
            {dayjs(subscriber.currentPeriodEnd).format('DD/MM/YYYY')}
          </Text>
        </Text>
      </Td>

      {/* Atualização */}
      <Td maxWidth="139px">{dayjs(subscriber.updatedAt).format('DD/MM/YYYY HH:mm')}</Td>

      {/* Status */}
      <Td
        maxWidth="131px"
        fontWeight="medium"
        color={getStatusColor(subscriber.status)}
        whiteSpace="break-spaces"
      >
        {getStatusText(subscriber.status)}
      </Td>

      {/* Ações */}
      <Td textAlign="center" maxWidth="95px">
        <Menu size="sm" arrowPadding={0}>
          <MenuButton
            size="xs"
            as={IconButton}
            icon={<MoreIcon size="1.125rem" />}
            w="27px"
            h="24px"
            borderRadius={5}
            bgColor="#2021231F"
            _hover={{ bgColor: '#b6b5b5' }}
            _active={{ bgColor: '#b6b5b5' }}
          />

          <MenuList
            sx={{
              '.chakra-menu__menuitem': {
                fontWeight: 'medium',
                fontSize: 'xs',
                color: '#20212380',
                _hover: { bgColor: '#2021230D' },
                _focus: { bgColor: '#2021230D' },
              },
            }}
          >
            <MenuItem
              as={Link}
              to={{
                pathname: `/recurrence/subscriber-management/payment-history/${subscriber.id}`,
                state: {
                  subscriberName: subscriber.fullName,
                },
              }}
            >
              Histórico de Pagamento
            </MenuItem>

            <MenuItem onClick={() => onSelectSubscriberToBeCanceled(subscriber)}>
              Cancelar assinatura
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default memo(SubscriberTableRow);
