import {
  Avatar,
  Button,
  HStack,
  Select,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import convertToBrazilianCurrency from '../../../../helpers/convertToBrazilianCurrency';
import { IProductCart } from '../../../../hooks/useShoppingCart';

interface ProductProps {
  product: IProductCart;
  isLoading: boolean;
  isPurchaseByPixConfirmed?: boolean;
  onDelete: (productId: number) => void;
  onPoleChange: (productId: number, poleId: number) => void;
}

export default function Product({
  product,
  isLoading,
  isPurchaseByPixConfirmed,
  onDelete,
  onPoleChange,
}: ProductProps) {
  const isCouponAccepted = product.couponAccepted;
  const priceWithDiscount = convertToBrazilianCurrency(product.productPrice);
  const originalPrice = convertToBrazilianCurrency(product.productPrice + product.couponDiscount);
  const isLoaded = !isLoading;

  function handleDelete() {
    onDelete(product.productId);
  }

  function handlePoleChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    onPoleChange(product.productId, Number(value));
  }

  return (
    <HStack w="full" align="flex-start" spacing={{ base: 3, xl: 4 }}>
      <Skeleton startColor="default.400" endColor="default.400" isLoaded={isLoaded}>
        <Avatar
          src={product.productThumbnail}
          name={product.productName}
          borderRadius="base"
          background="default.500"
          color="white"
          boxSize={{ base: '4.25rem', xl: '5.875rem' }}
        />
      </Skeleton>

      <SkeletonText noOfLines={2} isLoaded={isLoaded} w="full" skeletonHeight={4}>
        <VStack w="full" align="start" spacing={5}>
          <HStack w="full" justify="space-between" align="baseline" fontWeight="medium">
            <VStack spacing={0} justify="start" align="start">
              <Text
                as="span"
                fontSize={{ xl: 'md' }}
                color="dark.500"
                noOfLines={2}
                maxWidth={{ base: '16ch', xl: '18ch' }}
              >
                {product.productName}
              </Text>

              {!isPurchaseByPixConfirmed && (
                <Skeleton isLoaded={isLoaded}>
                  <Button
                    onClick={handleDelete}
                    size="sm"
                    variant="link"
                    colorScheme="red"
                    fontSize="0.625rem"
                    color="#BB2124BF"
                    textDecoration="underLine"
                  >
                    Remover produto
                  </Button>
                </Skeleton>
              )}
            </VStack>

            <VStack spacing={0} align="end">
              {isCouponAccepted && (
                <Text
                  fontSize="sm"
                  color={isCouponAccepted ? 'gray.300' : '#20212380'}
                  textDecoration={isCouponAccepted ? 'line-through' : ''}
                >
                  {originalPrice}
                </Text>
              )}

              <Skeleton isLoaded={isLoaded}>
                <Text as="span" fontSize="sm">
                  {isCouponAccepted ? priceWithDiscount : originalPrice}
                </Text>
              </Skeleton>
            </VStack>
          </HStack>

          {!isPurchaseByPixConfirmed && !!product.splitTypePole.length && (
            <Select
              mt={5}
              size="sm"
              focusBorderColor="orange.400"
              borderRadius="md"
              cursor="pointer"
              flex={1}
              display="flex"
              required
              onChange={handlePoleChange}
            >
              <option value="" hidden>
                Selecione o polo
              </option>
              {product.splitTypePole?.map(pole => (
                <option key={pole.poleId} value={pole.poleId}>
                  {pole.poleName}
                </option>
              ))}
            </Select>
          )}
        </VStack>
      </SkeletonText>
    </HStack>
  );
}
