import { axiosBackend } from './Configuration';

interface IQuery {
  page: number;
  per_page: number;
}

interface IPayload {
  id?: number;
  name: string;
  identifierPixel: string;
  eventVisitsPaymentPage: boolean;
  eventSalesConversion: boolean;
  receiveSalesConfirmationPaymentMethod: string;
  receiveConfirmationAmount: string;
  paymentMethod: string;
  sendValue: string | number;
  courseId: number;
  courseName?: string;
}

export default {
  index: async ({ page, per_page }: IQuery) => {
    try {
      const { data: pixelData } = await axiosBackend().get(
        `/facebook/pixel?page=${page}${per_page && `&per_page=${per_page}`}`
      );

      return pixelData;
    } catch (error: any) {
      throw error;
    }
  },

  show: async (pixelId: string) => {
    try {
      const { data: pixelData } = await axiosBackend().get(`/facebook/pixel/${pixelId}`);

      return pixelData;
    } catch (error: any) {
      throw error;
    }
  },

  store: async (payload: IPayload) => {
    delete payload.id;
    delete payload.courseName;

    try {
      const { data: pixelData } = await axiosBackend().post(`/facebook/pixel`, payload);

      return pixelData;
    } catch (error: any) {
      throw error;
    }
  },

  update: async (pixelId: number | string, payload: IPayload) => {
    try {
      const { data: pixelData } = await axiosBackend().patch(`/facebook/pixel/${pixelId}`, payload);

      return pixelData;
    } catch (error: any) {
      throw error;
    }
  },

  delete: async (pixelId: number) => {
    try {
      const { data: pixelData } = await axiosBackend().delete(`/facebook/pixel/${pixelId}`);

      return pixelData;
    } catch (error: any) {
      throw error;
    }
  },

  integration: {
    index: async () => {
      try {
        const { data: pixelData } = await axiosBackend().get(`/integration/facebook/pixel`);

        return pixelData;
      } catch (error: any) {
        throw error;
      }
    },
  },

  getPixelByProduct: async (courseId: number, affiliation?: string) => {
    try {
      const { data: pixelData } = await axiosBackend().get(
        `/facebook/pixel/${courseId}/course${affiliation ? `?affiliation=${affiliation}` : ''}`
      );

      return pixelData;
    } catch (error) {
      throw error;
    }
  },

  findMany: async (courseIds: number[], affiliation?: string) => {
    try {
      const { data: pixelData } = await axiosBackend().post(
        `/facebook/pixel/pixels-by-products${affiliation ? `?affiliation=${affiliation}` : ''}`,
        {
          courseIds,
        }
      );

      return pixelData;
    } catch (error) {
      throw error;
    }
  },
};
