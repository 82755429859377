import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { Modal } from 'react-bootstrap';
import { useCourse } from '../../../../../../contexts/CourseContext';
import {
  Box,
  Button,
  Card,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';

function PreviewControl() {
  const { course, updateCourse, loading } = useCourse();
  const [hasLimitedContent, setHasLimitedContent] = useState('free');
  const [limitingContentView, setLimitingContentView] = useState(3);

  const [isShowModal, setIsShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  useEffect(() => {
    if (!loading) {
      setHasLimitedContent(course.hasLimitedContent ? 'limited' : 'free');
      setLimitingContentView(course.limitingContentView);
    }
  }, [course.hasLimitedContent, course.limitingContentView, loading]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setLimitingContentView(parseInt(value));
  }

  function toggleModal() {
    setIsShowModal(!isShowModal);
  }

  async function handleSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    const limitedContent = hasLimitedContent === 'free' ? false : true;

    try {
      await updateCourse({ hasLimitedContent: limitedContent, limitingContentView });

      setMessage('Curso alterado com sucesso !');
      setTypeMessage('sucesso');
      setIsShowModal(true);
    } catch (error) {
      setMessage(ErrorResponse(error));
      setTypeMessage('erro');
      setIsShowModal(true);
    }
  }

  return (
    <VStack width="full">
      <Card width="full" as="form" onSubmit={handleSubmit} p={6}>
        <Heading as="h4" fontWeight="600" fontSize="2xl">
          Controle de visualizações
        </Heading>

        <VStack align="start" spacing={5} mt={5}>
          <RadioGroup
            defaultValue={hasLimitedContent}
            onChange={setHasLimitedContent}
            value={hasLimitedContent}
            colorScheme="default"
          >
            <VStack align="start" spacing={5}>
              <VStack align="start">
                <Radio value="free">
                  <Text fontWeight="600" color="gray.500">
                    Livre
                  </Text>
                </Radio>

                <Text>Os alunos terão acesso ilimitados dos seus vídeos</Text>
              </VStack>
              <VStack align="start">
                <Radio value="limited">
                  <Text fontWeight="600" color="gray.500">
                    Acesso limitado
                  </Text>
                </Radio>

                <Text>
                  Os vídeos poderão ser visualizados por padrão até{' '}
                  <Text as="b" fontWeight="600">
                    3 vezes
                  </Text>
                  . Será computada como uma visualização quando o aluno ultrapassar a visualização
                  de{' '}
                  <Text as="b" fontWeight="600">
                    80%
                  </Text>{' '}
                  do vídeo.
                </Text>
              </VStack>
            </VStack>
          </RadioGroup>

          {hasLimitedContent === 'limited' && (
            <Input
              onChange={handleChange}
              name="limitingContentView"
              value={limitingContentView}
              type="number"
              isRequired
              min={1}
            />
          )}

          <Box width="full" display="flex" justifyContent="flex-end">
            <Button type="submit" size="sm" isLoading={loading} colorScheme="default">
              Salvar
            </Button>
          </Box>
        </VStack>
      </Card>

      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={toggleModal}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="row d-flex justify-content-center mb-1 py-4">
            {typeMessage === 'sucesso' && (
              <FaCheckCircle
                className="d-flex justify-content-center mb-2"
                style={{ color: 'green', height: '50px', width: '50px' }}
              />
            )}
            {typeMessage === 'aviso' && (
              <FaExclamationCircle
                className="d-flex justify-content-center mb-2"
                style={{
                  color: '#EB7129',
                  height: '50px',
                  width: '50px',
                }}
              />
            )}
            {typeMessage === 'erro' && (
              <FaExclamationCircle
                className="d-flex justify-content-center mb-2"
                style={{
                  color: 'red',
                  height: '50px',
                  width: '50px',
                }}
              />
            )}
            <h3 className="text-center">{message}</h3>
          </div>
          <div className="d-flex justify-content-center mb-4 ">
            <Button onClick={toggleModal} colorScheme="default">
              Voltar ao controle de visualização
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </VStack>
  );
}

export default PreviewControl;
