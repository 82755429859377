import { Card, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { BiDotsVertical } from 'react-icons/bi';
import { IQuestionLibrarySubjectData } from '../../TabPanelSubject';

interface IProps {
  subject: IQuestionLibrarySubjectData;
  setSubjectId: (id: number) => void;
  setSubjectName: (name: string) => void;
  onOpenModalAddOrEditSubject: () => void;
  onOpenModalDeleteSubject: () => void;
  setDisciplineId: (id: number) => void;
}

export function CardSubject({
  subject,
  setSubjectId,
  setSubjectName,
  onOpenModalAddOrEditSubject,
  onOpenModalDeleteSubject,
  setDisciplineId,
}: IProps) {
  function handleClick(action: string): void {
    setSubjectId(subject?.id);
    setSubjectName(subject?.name);
    setDisciplineId(subject?.discipline?.id);
    if (action === 'delete') {
      onOpenModalDeleteSubject();
      return;
    }

    onOpenModalAddOrEditSubject();
  }

  return (
    <Card
      width={{ base: 'full', md: '242px', lg: '242px' }}
      height="130px"
      borderRadius={5}
      padding={3}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 4px 4px 0px #00000040"
      border="1px solid #2021230D"
    >
      <Stack spacing={2} alignItems="center" height="full">
        <Text fontSize="12px" fontWeight={600} width="150px" lineHeight="1.1" height="32px">
          {subject?.name}
        </Text>
        <Text
          fontSize="12px"
          fontWeight={500}
          width="150px"
          lineHeight="1.1"
          height="32px"
          color="#20212380"
        >
          Área: {subject?.area?.name}
        </Text>
        <Text
          fontSize="12px"
          fontWeight={500}
          width="150px"
          lineHeight="1.1"
          height="32px"
          color="#20212380"
        >
          Disciplina: {subject?.discipline?.name}
        </Text>
      </Stack>

      <Stack height="full">
        <Menu>
          <MenuButton>
            <BiDotsVertical size={14} />
          </MenuButton>
          <MenuList>
            <MenuItem
              fontSize="12px"
              fontWeight={500}
              color="#20212380"
              onClick={() => handleClick('edit')}
            >
              Editar
            </MenuItem>
            <MenuItem
              fontSize="12px"
              fontWeight={500}
              color="#BB2124"
              onClick={() => handleClick('delete')}
            >
              Excluir
            </MenuItem>
          </MenuList>
        </Menu>
      </Stack>
    </Card>
  );
}
