import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Flex,
  Heading,
  HStack,
  Image,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import Proluno from '../../../assets/icons/proluno-icon-bg-white.png';
import Verified from '../../../assets/icons/verified.png';
import brands from '../../../assets/images/brands.png';
import { useAuth } from '../../../contexts/AuthContext';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import useShoppingCart, {
  IPayment,
  IProductCart,
  IShoppingCart,
} from '../../../hooks/useShoppingCart';
import Product from './Product';

interface PurchaseSummaryProps extends CardProps {
  products: IProductCart[];
  isLoading: boolean;
  payments: IPayment[];
  isPurchaseByPixConfirmed: boolean;
  installment: number;
  mainProductId: number;
  onChangeShoppingCart: () => Promise<IShoppingCart>;
  onPoleChange: (productId: number, poleId: number) => void;
  onIsFreePurchaseChange: (value: boolean) => void;
  onStandardPaymentValueChange: (value: number) => void;
}

export default function PurchaseSummary({
  products,
  isLoading,
  payments,
  isPurchaseByPixConfirmed,
  installment,
  mainProductId,
  onChangeShoppingCart,
  onPoleChange,
  onIsFreePurchaseChange,
  onStandardPaymentValueChange,
  ...rest
}: PurchaseSummaryProps) {
  const { deleteProductFromLocalStorage, getShoppingCartFromLocalStorage } = useShoppingCart();
  const { onUpdateTotalItemsInShoppingCart } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();

  const shoppingCartInLocalStorage = getShoppingCartFromLocalStorage();

  const methodPaymentTypeInLocalStorage =
    shoppingCartInLocalStorage?.methodPaymentType as IShoppingCart['methodPaymentType'];

  const hiddenInstallment = methodPaymentTypeInLocalStorage !== 'credit_card';

  async function deleteProduct(productId: number) {
    deleteProductFromLocalStorage(productId);

    const hasProductsInShoppingCart = !!getShoppingCartFromLocalStorage();

    if (!hasProductsInShoppingCart) {
      onUpdateTotalItemsInShoppingCart(0);
      return history.push('/all-products');
    }

    await onChangeShoppingCart();
  }

  // Caso esteja na aba de pagamento é exibido a parcela atual,
  // caso esteja na aba de dados pessoais é exibido a parcela mais alta
  const currentInstallment =
    pathname.includes('payment') && methodPaymentTypeInLocalStorage === 'credit_card'
      ? payments?.find(payment => payment.installment === installment)
      : payments?.slice(-1)[0];

  const totalOnePayment: IPayment = payments?.find(payment => payment.installment === 1);

  let sortedProducts = [];

  if (products?.length) {
    sortedProducts = [...products];

    sortedProducts?.sort((a, b) => {
      if (a.productId === mainProductId) {
        return -1; // Coloca a no início se a.productId é igual a mainProductId
      } else if (b.productId === mainProductId) {
        return 1; // Coloca b no início se b.productId é igual a mainProductId
      }
      return 0; // Mantém a ordem original se nenhum dos dois é mainProductId
    });
  }

  return (
    <Card
      minW="full"
      minH="493px"
      maxWidth="26rem"
      pb={4}
      boxShadow="0px 4px 4px 0px #00000040"
      border="1px solid #2021230D"
      color="#202123"
      bgColor="#FFF"
      {...rest}
    >
      <CardHeader textAlign="center">
        <Heading
          color="#202123"
          textAlign="center"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
        >
          Resumo da compra
        </Heading>
      </CardHeader>

      <CardBody px={{ base: 4, xl: 5 }}>
        <Stack spacing="32px">
          <VStack spacing={3} divider={<StackDivider borderColor="gray.300" />}>
            {sortedProducts?.map(product => (
              <Product
                key={product.productId}
                product={product}
                isLoading={isLoading}
                onDelete={deleteProduct}
                isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
                mainProductId={mainProductId}
              />
            ))}
          </VStack>

          <Flex direction="column" w="full" gap="12px" sx={{ strong: { fontWeight: 'semibold' } }}>
            <Skeleton
              w="full"
              isLoaded={!isLoading}
              startColor="default.400"
              endColor="default.400"
              maxH={8}
            >
              <HStack
                w="full"
                justify="space-between"
                fontSize={{ base: 'md', xl: 'xl' }}
                color="#EB7129"
                hidden={hiddenInstallment}
              >
                <Text as="span" fontWeight="medium">
                  Total a pagar
                </Text>
                <Text as="strong">
                  {currentInstallment?.installment}x de{' '}
                  {convertToBrazilianCurrency(currentInstallment?.installmentPrice)}
                </Text>
              </HStack>
            </Skeleton>
            <Skeleton
              w="full"
              isLoaded={!isLoading}
              startColor="default.400"
              endColor="default.400"
              maxH={8}
            >
              <HStack w="full" justify="space-between" fontSize={{ base: '14px', xl: '14px' }}>
                <Text as="span" fontWeight="400">
                  Total a vista
                </Text>
                <Text as="strong">{convertToBrazilianCurrency(totalOnePayment?.total)}</Text>
              </HStack>
            </Skeleton>
          </Flex>
        </Stack>
      </CardBody>

      <CardFooter padding={0}>
        <Box
          width="full"
          display="flex"
          flexDirection="column"
          gap="32px"
          paddingBottom="10px"
          alignItems="center"
        >
          <Stack
            width="full"
            height="auto"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            bgColor="#F4F4F4"
            spacing="16px"
            paddingY="10px"
          >
            <Text color="#202123" textAlign="center" fontSize="14px" fontWeight="500">
              Formas de pagamento
            </Text>

            <Stack spacing="16px" alignItems="center" justifyContent="center">
              <Image
                width="258px"
                height="38px"
                src={brands}
                alt="Imagem com cartões aceitos para compra(visa, mastercard, elo, american express e diners club)"
              />

              <Box
                display="flex"
                padding="3px"
                alignItems="center"
                justifyContent="center"
                gap="2px"
                width="170px"
                borderRadius="3px"
                background="#4BBE58"
              >
                <Image src={Verified} boxSize="14px" alt="compra verificada" />

                <Text
                  color="#FFF"
                  textAlign="center"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="normal"
                >
                  Compra 100% segura
                </Text>
              </Box>
            </Stack>
          </Stack>

          <Box display="flex" gap="2px" alignItems="center">
            <Text
              color="#202123"
              textAlign="center"
              fontSize="14px"
              fontWeight="400"
              lineHeight="normal"
            >
              Powered by{' '}
              <Text as="span" fontSize="14px" fontWeight="600">
                Proluno
              </Text>
            </Text>
            <Image src={Proluno} boxSize="14px" alt="compra verificada" />
          </Box>
        </Box>
      </CardFooter>
    </Card>
  );
}
