import { Button, Container, FormControl, FormLabel, HStack, Input, VStack } from '@chakra-ui/react';
import { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CourseAPI from '../../../../api/Course';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import useHandleChange from '../../../../hooks/useHandleChange';

export default function NewCourse() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const { form, handleChange } = useHandleChange<{ name: string }>({
    name: '',
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const { data: courseCreated } = await CourseAPI.store(form.name);

      if (courseCreated) {
        history.push(`/courses/${courseCreated.id}/course-manager/course`);
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container maxW="container.sm">
      <VStack as="form" justifyContent="center" height="400px" onSubmit={onSubmit}>
        <FormControl>
          <FormLabel htmlFor="name" fontSize="xl" fontWeight="600">
            Nome do Produto
          </FormLabel>
          <Input
            id="name"
            name="name"
            placeholder="Digite o nome do seu produto"
            focusBorderColor="primary.500"
            value={form.name}
            onChange={handleChange}
            autoComplete="off"
            isRequired
            minLength={2}
            maxLength={90}
          />
        </FormControl>

        <HStack width="full" justifyContent="flex-end">
          <Button size="sm" onClick={() => history.push('/courses')}>
            Cancelar
          </Button>

          <Button
            type="submit"
            size="sm"
            isLoading={isLoading}
            colorScheme="primary"
            color="secondary.500"
          >
            Criar Produto
          </Button>
        </HStack>
      </VStack>
    </Container>
  );
}
