import {
  Box,
  Button,
  CardProps,
  Flex,
  HStack,
  Skeleton,
  SkeletonText,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { OrderBump, OrderBumpType } from '../../../components/OrderBump';
import { useAuth } from '../../../contexts/AuthContext';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import useShoppingCart, {
  IParams,
  IPayment,
  IProductCart,
  IShoppingCart,
} from '../../../hooks/useShoppingCart';

interface PurchaseSummaryProps extends CardProps {
  products: IProductCart[];
  isLoading: boolean;
  payments: IPayment[];
  isPurchaseByPixConfirmed?: boolean;
  onChangeShoppingCart: () => Promise<IShoppingCart>;
  onPoleChange: (productId: number, poleId: number) => void;
  onIsFreePurchaseChange: (value: boolean) => void;
  onStandardPaymentValueChange: (value: number) => void;
  orderBumps: OrderBumpType[];
  isOrderBumpVisible: boolean;
  updateShoppingCartInLocalStorage: (params: Partial<IParams>, orderBumpId?: number) => void;
  mainProductId: number;
  installment: number;
}

export default function PurchaseSummaryMobile({
  products,
  isLoading,
  payments,
  isPurchaseByPixConfirmed,
  onChangeShoppingCart,
  onPoleChange,
  onIsFreePurchaseChange,
  onStandardPaymentValueChange,
  isOrderBumpVisible,
  orderBumps,
  updateShoppingCartInLocalStorage,
  installment,
  mainProductId,
  ...rest
}: PurchaseSummaryProps) {
  const { deleteProductFromLocalStorage, getShoppingCartFromLocalStorage } = useShoppingCart();
  const { onUpdateTotalItemsInShoppingCart } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();

  const shoppingCartInLocalStorage = getShoppingCartFromLocalStorage();

  const methodPaymentTypeInLocalStorage =
    shoppingCartInLocalStorage?.methodPaymentType as IShoppingCart['methodPaymentType'];

  const hiddenInstallment = methodPaymentTypeInLocalStorage !== 'credit_card';

  async function deleteProduct(productId: number) {
    deleteProductFromLocalStorage(productId);

    const hasProductsInShoppingCart = !!getShoppingCartFromLocalStorage();

    if (!hasProductsInShoppingCart) {
      onUpdateTotalItemsInShoppingCart(0);
      return history.push('/all-products');
    }

    await onChangeShoppingCart();
  }

  // Caso esteja na aba de pagamento é exibido a parcela atual,
  // caso esteja na aba de dados pessoais é exibido a parcela mais alta
  const currentInstallment = pathname.includes('payment')
    ? payments?.find(payment => payment.installment === installment)
    : payments?.slice(-1)[0];

  const totalOnePayment: IPayment = payments?.find(payment => payment.installment === 1);

  let sortedProducts = [];

  if (products?.length) {
    sortedProducts = [...products];

    sortedProducts?.sort((a, b) => {
      if (a.productId === mainProductId) {
        return -1; // Coloca a no início se a.productId é igual a mainProductId
      } else if (b.productId === mainProductId) {
        return 1; // Coloca b no início se b.productId é igual a mainProductId
      }
      return 0; // Mantém a ordem original se nenhum dos dois é mainProductId
    });
  }

  return (
    <Box width="full" minH="153px" height="auto" padding="10px 15px" bgColor="#F4F4F4" {...rest}>
      <Stack spacing="32px" paddingBottom={isOrderBumpVisible ? '32px' : 'auto'}>
        <VStack spacing={3} divider={<StackDivider borderColor="gray.300" />}>
          <SkeletonText noOfLines={2} isLoaded={true} w="full" skeletonHeight={4}>
            <VStack spacing={0} justify="start" align="start">
              {sortedProducts?.map((product, index) => {
                const isMainProduct = product.productId === mainProductId;
                const withoutBorder = index === products.length - 1;

                const borderBottom = `1px solid ${
                  withoutBorder ? 'transparent' : 'rgba(0, 0, 0, 0.1)'
                }`;

                return (
                  <Stack
                    key={index}
                    width="full"
                    height="auto"
                    paddingY="10px"
                    borderBottom={borderBottom}
                  >
                    <Text
                      color="#202123"
                      fontSize="20px"
                      width="full"
                      fontStyle="normal"
                      fontWeight="500"
                      lineHeight="normal"
                    >
                      {product.productName}
                    </Text>

                    {!isPurchaseByPixConfirmed && !isMainProduct && (
                      <Box width="full" display="flex" justifyContent="start">
                        <Button
                          onClick={() => deleteProduct(product.productId)}
                          width="95px"
                          size="sm"
                          variant="link"
                          colorScheme="red"
                          fontSize="0.625rem"
                          fontWeight="800"
                          color="#BB2124BF"
                          textDecoration="underLine"
                        >
                          Remover produto
                        </Button>
                      </Box>
                    )}
                  </Stack>
                );
              })}
            </VStack>
          </SkeletonText>
        </VStack>

        <Flex direction="column" w="full" gap="12px" sx={{ strong: { fontWeight: 'semibold' } }}>
          <Skeleton
            w="full"
            isLoaded={!false}
            startColor="default.400"
            endColor="default.400"
            maxH={8}
          >
            <HStack
              w="full"
              justify="space-between"
              fontSize={{ base: '20px', xl: 'xl' }}
              color="#EB7129"
              hidden={hiddenInstallment}
            >
              <Text as="span" fontWeight="medium">
                Total a pagar
              </Text>
              <Text as="strong">
                {currentInstallment?.installment}x de{' '}
                {convertToBrazilianCurrency(currentInstallment?.installmentPrice)}
              </Text>
            </HStack>
          </Skeleton>
          <Skeleton
            w="full"
            isLoaded={!false}
            startColor="default.400"
            endColor="default.400"
            maxH={8}
          >
            <HStack w="full" justify="space-between" fontSize="14px">
              <Text as="span" fontWeight="400">
                Total a vista
              </Text>
              <Text as="strong">{convertToBrazilianCurrency(totalOnePayment?.total)}</Text>
            </HStack>
          </Skeleton>
        </Flex>
      </Stack>

      {isOrderBumpVisible && (
        <OrderBump
          orderBumps={orderBumps}
          updateShoppingCartInLocalStorage={updateShoppingCartInLocalStorage}
          onChangeShoppingCart={onChangeShoppingCart}
        />
      )}
    </Box>
  );
}
