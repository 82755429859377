import { Input } from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { InputProps } from 'react-select';
import { FocusEvent } from 'react';
import { INPUT_STYLES } from '../../../../constants';

const isAmexCard = /^3[47]/;
const isDinersClub = /^3[068]/;

function getCardMask(value: string) {
  if (isAmexCard.test(value)) {
    return 'amex';
  }

  if (isDinersClub.test(value)) {
    return 'dinersClub';
  }

  return 'other';
}

const cardMasks = {
  amex: '9999 999999 99999',
  dinersClub: '9999 999999 9999',
  other: '9999 9999 9999 9999',
};

export default function CreditCardInput(props: Partial<InputProps>) {
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <InputMask
      onFocus={handleFocus}
      mask={cardMasks[getCardMask(props.value as string)] || '9999 9999 9999 9999'}
      maskChar=""
      autoComplete="new-credit-card-number"
      {...props}
    >
      {inputProps => (
        <Input placeholder="Digite somente números" {...inputProps} {...INPUT_STYLES} />
      )}
    </InputMask>
  );
}
