import { Box, Flex, Image } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import CourseAPI from '../../../../../api/Course';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';

import { useParams } from 'react-router-dom';
import Toast from '../../../../../components/Toast';
import { useStudentCourse } from '../../../../../contexts/CourseStudentContext';
import Player from './Player';
import { scrollbarStyle } from '..';

function ViewAudio() {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const { studentCourse, getContent } = useStudentCourse();
  const [content, setContent] = useState();

  const { courseId, moduleId, contentId } = useParams();

  useEffect(() => {
    if (studentCourse) {
      const currentContent = getContent(studentCourse, moduleId, contentId);
      if (currentContent) {
        setContent(currentContent);
      }
    }
  }, [contentId, getContent, moduleId, studentCourse]);

  const updateLastVisit = useCallback(async () => {
    try {
      await CourseAPI.updateLastVisit(courseId, moduleId, contentId, 'AUDIO');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }, [contentId, courseId, moduleId]);

  useEffect(() => {
    if (role === 'ALUNO' && studentCourse) {
      updateLastVisit();
    }
  }, [updateLastVisit, role, studentCourse]);

  return (
    <Box
      w="full"
      overflow="auto"
      sx={scrollbarStyle}
      margin={{ base: '0', md: '20px 70px', lg: '20px 70px' }}
    >
      <div className="container">
        <div className="d-flex flex-column justify-content-md-center">
          <div className="px-md-3 px-lg-5">
            <h3 style={{ fontSize: '30px' }}>{content?.title || 'Conteúdo sem título'}</h3>
            <Flex display={{ base: 'flex', md: 'none', lg: 'none' }} justifyContent="center">
              <Image
                height="20rem"
                width="20rem"
                objectFit="cover"
                src={content?.thumbnail}
                zIndex="99"
              />
            </Flex>
            <Box
              display={{ base: 'none', md: 'flex', lg: 'flex' }}
              justifyContent="center"
              backgroundImage={content?.thumbnail}
              alignItems="center"
              position="relative"
            >
              <Box
                maxHeight="18rem"
                display="flex"
                height="16rem"
                width="100%"
                backgroundImage={content?.thumbnail}
                backgroundPosition="center"
                justifyContent="center"
                alignItems="center"
                style={{ filter: 'blur(5px)' }}
              />
              <Image
                position="absolute"
                height="12rem"
                width="12rem"
                objectFit="cover"
                src={content?.thumbnail}
              />
            </Box>

            <Box mt={3}>
              <Player src={content?.download} />
            </Box>

            <Box textAlign="center">
              {content?.content ? (
                <Box
                  my={4}
                  dangerouslySetInnerHTML={{ __html: content.content }}
                  className="ql-editor"
                  sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
                />
              ) : (
                <h4>Conteúdo sem descrição</h4>
              )}
            </Box>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default ViewAudio;
