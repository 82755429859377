import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import FullPageLoading from '../../../../components/FullPageLoading';
import useFetch from '../../../../hooks/useFetch';
import Filters from './Filters';
import LeadCardDesktop from './LeadCardDesktop';
import LeadList from './LeadList';
import LeadCardMobile from './LeadCardMobile';
import { CloseIcon } from '@chakra-ui/icons';

export type ConversionType =
  | 'ADD_TO_CART'
  | 'PAGE_VIEW'
  | 'PURCHASE'
  | 'FREE_SIGN_UP'
  | 'EXTERNAL_FORM'
  | 'REGISTER_DEFAULT'
  | 'REGISTER_CHECKOUT'
  | 'REGISTER_QUESTION'
  | 'ISSUANCE_BOLETO'
  | 'ISSUANCE_PIX'
  | 'PURCHASE_REFUSED'
  | 'BEGIN_CHECKOUT';
export type CurrentLeadType = {
  id: number;
  fullName: string;
  email: string;
  phone: string;
  photo: string;
};

type Conversion = {
  id: number;
  name: string;
  type: ConversionType;
  createdAt: string;
  product: string;
};

interface IConversion {
  totalConversions: number;
  purchaseConversions: number;
  pageViewConversions: number;
  addToCartConversions: number;
  externalFormConversions: number;
  registerDefaultConversions: number;
  registerCheckoutConversions: number;
  registerQuestionConversions: number;
  issuanceBoletoConversions: number;
  issuancePixConversions: number;
  purchaseRefusedConversions: number;
  beginCheckoutConversions: number;
  freeSignUpConversions: number;
  recurrencePaidConversions: number;
  recurrenceCanceledConversions: number;
  recurrenceEndedConversions: number;
  recurrencePendingPaymentConversions: number;
  recurrenceUnpaidConversions: number;
  beginCheckoutRecurrenceConversions: number;
  user: CurrentLeadType;
  conversions: Conversion[];
}

export default function Conversions() {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const location = useLocation();
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });
  const urlSearchParams: URLSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [conversions, setConversions] = useState<Conversion[]>([]);

  const params = JSON.parse(urlSearchParams.get('conversionsName'));

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<IConversion>>({
    url: `/conversions/${id}/user?${urlSearchParams.toString()}`,
    method: 'get',
    authenticated: true,
    autoFetch: true,
  });

  const conversionsData = response?.data;
  const allConversions = conversionsData?.conversions;
  const lead = conversionsData?.user;
  const totalConversions = conversionsData?.totalConversions;
  const purchaseConversions = conversionsData?.purchaseConversions;
  const pageViewConversions = conversionsData?.pageViewConversions;
  const addToCartConversions = conversionsData?.addToCartConversions;
  const externalFormConversions = conversionsData?.externalFormConversions;
  const freeSignUpConversions = conversionsData?.freeSignUpConversions;
  const registerDefaultConversions = conversionsData?.registerDefaultConversions;
  const registerCheckoutConversions = conversionsData?.registerCheckoutConversions;
  const registerQuestionConversions = conversionsData?.registerQuestionConversions;
  const issuanceBoletoConversions = conversionsData?.issuanceBoletoConversions;
  const issuancePixConversions = conversionsData?.issuancePixConversions;
  const purchaseRefusedConversions = conversionsData?.purchaseRefusedConversions;
  const beginCheckoutConversions = conversionsData?.beginCheckoutConversions;
  const recurrencePaidConversions = conversionsData?.recurrencePaidConversions;
  const recurrenceCanceledConversions = conversionsData?.recurrenceCanceledConversions;
  const recurrenceEndedConversions = conversionsData?.recurrenceEndedConversions;
  const recurrencePendingPaymentConversions = conversionsData?.recurrencePendingPaymentConversions;
  const recurrenceUnpaidConversions = conversionsData?.recurrenceUnpaidConversions;
  const beginCheckoutRecurrenceConversions = conversionsData?.beginCheckoutRecurrenceConversions;

  useEffect(() => {
    if (allConversions) {
      const conversions = allConversions?.slice(0, 10);
      setConversions(conversions);
    }
  }, [allConversions]);

  function handleLoadMore() {
    const loadMoreConversions = allConversions?.slice(0, conversions.length + 10);
    setConversions(loadMoreConversions);
  }

  const isLoadMoreHidden =
    loading || conversions.length >= allConversions?.length || !conversions.length;

  function handleFilter(filters: string[]) {
    if (!filters.length) {
      urlSearchParams.delete('conversionsName');
      return history.push({ search: urlSearchParams.toString() });
    }

    urlSearchParams.set('conversionsName', JSON.stringify(filters));

    return history.push({ search: urlSearchParams.toString() });
  }

  function handleClearFilters() {
    urlSearchParams.delete('conversionsName');
    history.push({ search: urlSearchParams.toString() });
  }

  return (
    <Container maxW="container.xl" padding={0}>
      <VStack align="start" spacing="40px">
        <HStack
          spacing={0}
          width="full"
          ml={{ base: -1, md: -2 }}
          style={{ cursor: 'pointer' }}
          onClick={() => history.goBack()}
        >
          <Box fontSize={{ base: '18px', md: '24px' }}>
            <ChevronLeftIcon size="24px" />
          </Box>

          <Heading fontSize={{ base: '18px', md: '24px' }} fontWeight="600" flexShrink="0">
            Voltar para a base de leads
          </Heading>
        </HStack>

        {isMobile && <LeadCardMobile lead={lead} totalConversions={totalConversions} />}

        {!isMobile && <LeadCardDesktop lead={lead} totalConversions={totalConversions} />}

        <VStack align="start" spacing={{ base: '15px', md: '32px' }} width="full">
          <HStack width="full" justifyContent="space-between">
            <Heading fontSize="20px" fontWeight="500">
              Atividades
            </Heading>

            <Button
              onClick={handleClearFilters}
              rightIcon={<CloseIcon fontSize="10px" />}
              hidden={!params}
              size="xs"
              variant="unstyled"
              color="gray.500"
            >
              Limpar filtros
            </Button>
          </HStack>

          <Filters
            handleFilter={handleFilter}
            params={params}
            pageViewConversions={pageViewConversions}
            purchaseConversions={purchaseConversions}
            addToCartConversions={addToCartConversions}
            externalFormConversions={externalFormConversions}
            freeSignUpConversions={freeSignUpConversions}
            registerDefaultConversions={registerDefaultConversions}
            registerCheckoutConversions={registerCheckoutConversions}
            registerQuestionConversions={registerQuestionConversions}
            issuanceBoletoConversions={issuanceBoletoConversions}
            issuancePixConversions={issuancePixConversions}
            purchaseRefusedConversions={purchaseRefusedConversions}
            beginCheckoutConversions={beginCheckoutConversions}
            recurrencePaidConversions={recurrencePaidConversions}
            recurrenceCanceledConversions={recurrenceCanceledConversions}
            recurrenceEndedConversions={recurrenceEndedConversions}
            recurrencePendingPaymentConversions={recurrencePendingPaymentConversions}
            recurrenceUnpaidConversions={recurrenceUnpaidConversions}
            beginCheckoutRecurrenceConversions={beginCheckoutRecurrenceConversions}
          />

          {conversions && <LeadList conversions={conversions} isMobile={isMobile} />}

          {!conversions?.length && (
            <HStack
              width="full"
              fontSize="16px"
              color="#20212380"
              fontWeight="500"
              justifyContent="center"
            >
              <Text>Nenhuma atividade encontrada</Text>
            </HStack>
          )}
        </VStack>
      </VStack>

      <HStack width="full" justifyContent="center" my={{ base: '32px', md: '40px' }}>
        <Text
          fontSize="20px"
          fontWeight="600"
          lineHeight="28px"
          textAlign="center"
          color="#759AED"
          textDecoration="underline"
          cursor="pointer"
          onClick={handleLoadMore}
          hidden={isLoadMoreHidden}
        >
          Carregar mais
        </Text>
      </HStack>

      <FullPageLoading isLoading={loading} />
    </Container>
  );
}
