import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

import { memo } from 'react';

interface CommentModalProps {
  show: boolean;
  data: {
    question: string;
    questionText: string;
    commentary: string;
  };
  onHide: () => void;
}

function CommentModal(props: CommentModalProps) {
  const { question, questionText, commentary } = props.data;

  return (
    <Modal isOpen={props.show} onClose={props.onHide} size="xl">
      <ModalOverlay />

      <ModalContent bg="#37393D" color="#FFF">
        <ModalHeader fontWeight="bold">{question}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack>
            <Text as="h5" fontSize="md" fontWeight="semibold">
              Pergunta
            </Text>

            <Box
              w="full"
              dangerouslySetInnerHTML={{ __html: questionText }}
              sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
              className="ql-editor"
            />
          </Stack>

          <Stack className="mt-4">
            <Text as="h5" fontSize="md" fontWeight="semibold">
              Comentário
            </Text>

            <Box
              w="full"
              dangerouslySetInnerHTML={{ __html: commentary }}
              sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
              className="ql-editor"
            />
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
export default memo(CommentModal);
