import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { IFilter, IFilterParams } from '../../..';

interface IFiltersData {
  id: number;
  name: string;
}

interface IFilterBoxProps {
  filters: IFilter;
  areas: IFiltersData[];
  disciplines: IFiltersData[];
  subjects: IFiltersData[];
  isLoading: boolean;
  handleCheckboxFilter: (params: IFilterParams) => void;
  clearFilters?: () => void;
  hasFilters?: boolean;
}

// A const openInitially abre os index setados. Cada index representa um AccordionButton, respectivamente.
const openInitially = [0, 1, 2, 3, 4];

const checkBoxStyle = {
  size: 'sm',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  colorScheme: 'primary',
};

function ShowFilters({ items, initialCount = 5, increment = 5, renderItem }) {
  const [showCount, setShowCount] = useState(initialCount);

  const itemsToShow = items?.slice(0, showCount);

  const handleShowMore = () => {
    setShowCount(showCount + increment);
  };

  const handleShowLess = () => {
    setShowCount(initialCount);
  };

  return (
    <>
      <Stack spacing="8px">{itemsToShow?.map((item, index) => renderItem(item, index))}</Stack>

      {items?.length > showCount && (
        <Text
          marginTop="8px"
          color="#759AED"
          fontSize="14px"
          fontWeight="400"
          lineHeight="20px"
          textDecorationLine="underline"
          cursor="pointer"
          onClick={handleShowMore}
        >
          Mostrar mais
        </Text>
      )}

      {items?.length > 5 && showCount >= items?.length && (
        <Text
          marginTop="8px"
          color="#759AED"
          fontSize="14px"
          fontWeight="400"
          lineHeight="20px"
          textDecorationLine="underline"
          cursor="pointer"
          onClick={handleShowLess}
        >
          Mostrar menos
        </Text>
      )}
    </>
  );
}

export default function FilterBox({
  filters,
  areas,
  disciplines,
  subjects,
  isLoading,
  handleCheckboxFilter,
  clearFilters,
  hasFilters,
}: IFilterBoxProps) {
  return (
    <Accordion width="315px" defaultIndex={openInitially} allowMultiple>
      <Text
        color="#759AED"
        textAlign="left"
        fontSize="14px"
        fontWeight="400"
        lineHeight="28px"
        cursor="pointer"
        textDecorationLine="underline"
        onClick={clearFilters}
        hidden={!hasFilters}
      >
        Limpar filtros
      </Text>
      <AccordionItem hidden={!areas?.length || isLoading}>
        <AccordionButton padding="8px 0">
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontSize="16px"
            fontWeight="500"
            lineHeight="28px"
          >
            Área
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel padding="8px 0">
          <ShowFilters
            items={areas}
            renderItem={(area, index) => (
              <Checkbox
                key={index}
                {...checkBoxStyle}
                isChecked={filters.area.some(filter => filter.id === area.id.toString())}
                onChange={() => {
                  handleCheckboxFilter({
                    id: area.id.toString(),
                    name: 'area',
                    displayName: area.name.toString(),
                    isChecked: filters.area.some(filter => filter.id === area.id.toString()),
                  });
                }}
              >
                {area.name}
              </Checkbox>
            )}
          />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem hidden={!disciplines?.length || isLoading}>
        <h2>
          <AccordionButton padding="8px 0">
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontSize="16px"
              fontWeight="500"
              lineHeight="28px"
            >
              Disciplina
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel padding="8px 0">
          <ShowFilters
            items={disciplines}
            renderItem={(discipline, index) => (
              <Checkbox
                key={index}
                {...checkBoxStyle}
                isChecked={filters.discipline.some(
                  filter => filter.id === discipline.id.toString()
                )}
                onChange={() => {
                  handleCheckboxFilter({
                    id: discipline.id.toString(),
                    name: 'discipline',
                    displayName: discipline.name.toString(),
                    isChecked: filters.discipline.some(
                      filter => filter.id === discipline.id.toString()
                    ),
                  });
                }}
              >
                {discipline.name}
              </Checkbox>
            )}
          />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem hidden={!subjects?.length || isLoading}>
        <h2>
          <AccordionButton padding="8px 0">
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontSize="16px"
              fontWeight="500"
              lineHeight="28px"
            >
              Assunto
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel padding="8px 0">
          <ShowFilters
            items={subjects}
            renderItem={(subject, index) => (
              <Checkbox
                key={index}
                {...checkBoxStyle}
                isChecked={filters.subject.some(filter => filter.id === subject.id.toString())}
                onChange={() => {
                  handleCheckboxFilter({
                    id: subject.id.toString(),
                    name: 'subject',
                    displayName: subject.name.toString(),
                    isChecked: filters.subject.some(filter => filter.id === subject.id.toString()),
                  });
                }}
              >
                {subject.name}
              </Checkbox>
            )}
          />
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <h2>
          <AccordionButton padding="8px 0">
            <Box
              as="span"
              flex="1"
              textAlign="left"
              fontSize="16px"
              fontWeight="500"
              lineHeight="28px"
            >
              Qtd de cursos
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel padding="8px 0">
          <Stack spacing="8px">
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.quantityOfProducts.some(
                filter => filter.id === JSON.stringify({ gte: 0, lte: 3 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 0, lte: 3 }),
                  name: 'quantityOfProducts',
                  displayName: 'Até 3 cursos',
                  isChecked: filters.quantityOfProducts.some(
                    filter => filter.id === JSON.stringify({ gte: 0, lte: 3 })
                  ),
                });
              }}
            >
              Até 3 cursos
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.quantityOfProducts.some(
                filter => filter.id === JSON.stringify({ gte: 3, lte: 5 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 3, lte: 5 }),
                  name: 'quantityOfProducts',
                  displayName: '3 a 5 cursos',
                  isChecked: filters.quantityOfProducts.some(
                    filter => filter.id === JSON.stringify({ gte: 3, lte: 5 })
                  ),
                });
              }}
            >
              3 a 5 cursos
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.quantityOfProducts.some(
                filter => filter.id === JSON.stringify({ gte: 5, lte: 7 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 5, lte: 7 }),
                  name: 'quantityOfProducts',
                  displayName: '5 a 7 cursos',
                  isChecked: filters.quantityOfProducts.some(
                    filter => filter.id === JSON.stringify({ gte: 5, lte: 7 })
                  ),
                });
              }}
            >
              5 a 7 cursos
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.quantityOfProducts.some(
                filter => filter.id === JSON.stringify({ gte: 7, lte: 9 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 7, lte: 9 }),
                  name: 'quantityOfProducts',
                  displayName: '7 a 9 cursos',
                  isChecked: filters.quantityOfProducts.some(
                    filter => filter.id === JSON.stringify({ gte: 7, lte: 9 })
                  ),
                });
              }}
            >
              7 a 9 cursos
            </Checkbox>
            <Checkbox
              {...checkBoxStyle}
              isChecked={filters?.quantityOfProducts.some(
                filter => filter.id === JSON.stringify({ gte: 9, lte: 9999 })
              )}
              onChange={() => {
                handleCheckboxFilter({
                  id: JSON.stringify({ gte: 9, lte: 9999 }),
                  name: 'quantityOfProducts',
                  displayName: 'Acima de 9',
                  isChecked: filters.quantityOfProducts.some(
                    filter => filter.id === JSON.stringify({ gte: 9, lte: 9999 })
                  ),
                });
              }}
            >
              Acima de 9
            </Checkbox>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
