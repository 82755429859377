import { Flex, Heading, Link, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import backgroundImage from '../../../assets/images/backgrounds/box_colors.png';
import { TOKEN_PURCHASE, UTM_LOCAL_STORAGE } from '../../../helpers/LocalStorageHelper';

export default function LaunchCheckoutThanksPage() {
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const purchaseToken = localStorage.getItem(TOKEN_PURCHASE);

  const payment_method = searchParams.get('payment_method');

  const isPix = payment_method === 'pix';

  const fontSize: string = useBreakpointValue({
    base: '70px',
    lg: '80px',
  });

  useEffect(() => {
    if (purchaseToken) {
      localStorage.removeItem(TOKEN_PURCHASE);
    }
  }, [purchaseToken]);

  useEffect(() => {
    const utmInitialParams = {
      utm_id: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null,
      utm_content: null,
    };

    localStorage.setItem(UTM_LOCAL_STORAGE, JSON.stringify(utmInitialParams));
  }, []);

  return (
    <Stack minHeight="100dvh">
      <Flex
        flex={1}
        px={5}
        paddingTop={{ base: '15px' }}
        backgroundImage={backgroundImage}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        backgroundSize={{ base: 'cover', lg: 'contain' }}
        justify="center"
        align="center"
      >
        <Stack
          width="full"
          alignItems="center"
          spacing="30px"
          maxWidth={{ base: 'container.sm', lg: 'container.sm' }}
        >
          <Stack alignItems="center">
            <FaRegCheckCircle fontSize={fontSize} color="#EC7117" />

            <Heading
              as="h1"
              color="#EC7117"
              fontSize={{ base: '32px', lg: '40px' }}
              textAlign="center"
              mx={{ base: 5 }}
            >
              Parabéns{isPix ? ', pagamento confirmado' : ' por sua compra'}!
            </Heading>
          </Stack>

          <Stack fontWeight="500" spacing="15px" fontSize="22px" textAlign="center">
            <Text>
              <Text as="span" fontWeight="600">
                Acesse o seu e-mail{' '}
              </Text>{' '}
              para verificar as informações de{' '}
              <Text as="span" fontWeight="600">
                confirmação de compra e orientações de acesso ao seu produto.{' '}
              </Text>
            </Text>
          </Stack>
        </Stack>
      </Flex>

      {/* FOOTER */}
      <Flex as="footer" justify="center" w="full">
        <Link
          fontSize="md"
          w="full"
          h="full"
          textAlign="center"
          py={5}
          href="https://proluno.me/"
          target="_blank"
          isExternal
        >
          Tecnologia 🧡 Proluno - Ltda.
        </Link>
      </Flex>
    </Stack>
  );
}
