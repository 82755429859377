import { Redirect, Route, Switch } from 'react-router-dom';
import VideoLibraryProvider from '../../../contexts/VideoLibraryContext';
import EditVideo from './EditVideo';
import VideoList from './VideoList';

function VideoLibrary() {
  return (
    <VideoLibraryProvider>
      <Switch>
        <Route exact path="/video-library" component={VideoList} />
        <Route exact path="/video-library/:id" component={EditVideo} />
        <Route
          exact
          path="/video-library/:id/*"
          component={() => <Redirect to="/video-library" />}
        />
      </Switch>
    </VideoLibraryProvider>
  );
}

export default VideoLibrary;
