import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';

interface InfoCardListProps extends SimpleGridProps {
  children: React.ReactNode;
}

export default function InfoCardList({ children, ...rest }: InfoCardListProps) {
  return (
    <SimpleGrid
      gap={5}
      templateColumns={{
        base: '1fr',
        md: 'repeat(2, 1fr)',
        xl: 'repeat(3, 1fr)',
      }}
      {...rest}
    >
      {children}
    </SimpleGrid>
  );
}
