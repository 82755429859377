export default function BiometricsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clip-path="url(#clip0_15827_17264)">
        <path
          d="M1.75 2.91667V5.25H2.91667V2.91667H5.25V1.75H2.91667C2.275 1.75 1.75 2.275 1.75 2.91667ZM2.91667 8.75H1.75V11.0833C1.75 11.725 2.275 12.25 2.91667 12.25H5.25V11.0833H2.91667V8.75ZM11.0833 11.0833H8.75V12.25H11.0833C11.725 12.25 12.25 11.725 12.25 11.0833V8.75H11.0833V11.0833ZM11.0833 1.75H8.75V2.91667H11.0833V5.25H12.25V2.91667C12.25 2.275 11.725 1.75 11.0833 1.75Z"
          fill="currentColor"
        />
        <line x1="1.75" y1="7.0835" x2="12.25" y2="7.0835" stroke="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_15827_17264">
          <rect width="14" height="14" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
