interface IErrorMessageProps {
  message: String
}

function ErrorMessage({ message }: IErrorMessageProps) {
  return (
    <div className="my-4">
      <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
        <div className="text-center">{message}</div>
      </div>
    </div>
  );
}

export default ErrorMessage;
