import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  useBreakpointValue,
} from '@chakra-ui/react';

import { MdError as AlertIcon } from 'react-icons/md';

const ALERT_ICON_SIZE = '3rem'; // 48px

interface SubscriptionCancellationModalProps {
  subscriberName: string;
  subscriberEmail: string;
  isOpen?: ModalProps['isOpen'];
  isLoading?: boolean;
  onConfirm?: () => void;
  onClose?: ModalProps['onClose'];
}

export default function SubscriptionCancellationModal({
  subscriberName,
  subscriberEmail,
  isOpen,
  isLoading,
  onConfirm,
  onClose,
}: SubscriptionCancellationModalProps) {
  const isCentered = useBreakpointValue({ base: true, md: false });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />

      <ModalContent px={{ base: 4, md: 14 }} maxW={{ base: '94%', md: '38.25rem' }}>
        <ModalHeader display="flex" justifyContent="center">
          <AlertIcon color="#E85347" width="40px" height="40px" fontSize={ALERT_ICON_SIZE} />
        </ModalHeader>

        <ModalCloseButton _focus={{ boxShadow: 'none' }} mr={4} isDisabled={isLoading} />

        <ModalBody textAlign="center" px={0}>
          <Heading fontSize="lg" fontWeight="medium" lineHeight="28px">
            Deseja cancelar a assinatura do usuário {subscriberName.toUpperCase()} com e-mail{' '}
            {subscriberEmail.toUpperCase()}?
          </Heading>
        </ModalBody>

        <ModalFooter justifyContent="center" gap={3} px={0} pb={8}>
          <Button
            width={{ base: 'full', xl: 'initial' }}
            size="sm"
            onClick={onClose}
            bg="#e9e9e9"
            isDisabled={isLoading}
          >
            Não cancelar
          </Button>

          <Button
            flexShrink={0}
            size="sm"
            bg="#E85347"
            colorScheme="red"
            onClick={onConfirm}
            isLoading={isLoading}
          >
            Sim, cancelar assinatura
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
