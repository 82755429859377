import { useState } from 'react';
import { MdKeyboardArrowRight as ArrowRight } from 'react-icons/md';

import styles from './styles.module.css';

import Table from '../Table';

function Installments({ index, purchaseStatus, paymentMethod, installments }) {
  const [rotate, setRotate] = useState(false);

  function rotateArrow() {
    setRotate(prevRotate => !prevRotate);
  }

  return (
    <div className={styles.accordion}>
      {(purchaseStatus === 'paid' || paymentMethod !== 'boleto') && (
        <div className="card">
          <div className={styles.cardHeaderInstallments}>
            <button
              className={styles.accordionButton}
              type="button"
              onClick={rotateArrow}
              data-toggle="collapse"
              data-target={`#accordionInstallment${index}`}
              aria-expanded="false"
              aria-controls={`accordionInstallment${index}`}
            >
              <h2>Visualizar todas as parcelas</h2>
              <ArrowRight size={28} color="#202123" className={rotate && styles.rotateIcon} />
            </button>
          </div>

          <div id={`accordionInstallment${index}`} className="collapse multi-collapse">
            <div className={styles.cardBodyInstallments}>
              <Table installments={installments} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Installments;
