import { useEffect, useState } from 'react';

import { Alert } from 'react-bootstrap';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { Link } from 'react-router-dom';
import MarketplaceAPI from '../../../../api/Marketplace';
import { Heading } from '../../../../components/Heading';
import { settingsCategories } from '../../Arrows/slickSettings';
import { useTheme } from '../../../../contexts/ThemeContext';
import MainCard from '../../../../components/MainCard';
import { Box, Button, Flex, Stack } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

function getProductsSection(themeMarketplace) {
  return themeMarketplace?.themeSections?.find(section => section.name === 'products');
}

function ProductsSection({ section }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(null);

  const [products, setProducts] = useState([]);

  const [mounted, setMounted] = useState(false);

  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted && !isLoadingThemeMarketplace) {
      const productSection = getProductsSection(themeMarketplace);

      setIsActive(productSection.active);
    }
  }, [isLoadingThemeMarketplace, mounted, themeMarketplace]);

  useEffect(() => {
    async function getProducts() {
      try {
        setIsLoading(true);

        const { data: response } = await MarketplaceAPI.index({
          page: 1,
          search: '',
          category: '',
        });

        if (response) {
          setProducts(response.courses);
        }
      } catch (error) {
        setErrorMessage(ErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
    }

    if (mounted) {
      getProducts();
    }
  }, [mounted]);

  if (!mounted || !isActive || !products.length) return '';

  return (
    <Box as="section" p={3} padding={{ base: '24px 15px', md: '40px 107px', lg: '40px 107px' }}>
      <Link to="/all-products?page=1">
        <Heading
          fontSize={{ base: '24px', md: '32px', lg: '32px' }}
          fontWeight={{ base: 500, md: 600, lg: 600 }}
          paddingBottom={5}
        >
          Todos os produtos
        </Heading>
      </Link>

      {section.viewMode === 'list' ? (
        <Slider
          {...settingsCategories}
          responsive={[
            {
              breakpoint: 1366,
              settings: {
                swipeToSlide: 4,
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1270,
              settings: {
                swipeToSlide: 3,
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 990,
              settings: {
                swipeToSlide: 2,
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                swipeToSlide: 1,
                slidesToShow: 1,
                centerMode: true,
              },
            },
            {
              breakpoint: 450,
              settings: {
                swipeToSlide: 1,
                slidesToShow: 1,
                centerMode: true,
                nextArrow: <></>,
                prevArrow: <></>,
              },
            },
          ]}
        >
          {products.map(course => (
            <Stack direction="row" key={nanoid()} marginY={5}>
              <MainCard
                key={course.id}
                course={course}
                checkoutType={course.checkoutType}
                loading={isLoading}
              />
            </Stack>
          ))}
        </Slider>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="row"
            gap={{ base: 2, md: 8, lg: 8 }}
            alignItems="center"
            width="full"
            justifyContent={{ base: 'center', md: 'space-evenly', lg: 'space-evenly' }}
            marginY={5}
            flexWrap="wrap"
          >
            {products.slice(0, 4).map(course => {
              return (
                <MainCard
                  key={course.id}
                  course={course}
                  loading={isLoadingThemeMarketplace}
                  checkoutType={course.checkoutType}
                  grid={true}
                />
              );
            })}
          </Box>
          <Flex justifyContent="center">
            <Button
              as={Link}
              to="/all-products"
              background={themeMarketplace.primaryColor}
              color={themeMarketplace.textColor}
              _hover={{ background: themeMarketplace.primaryColor.concat('80') }}
            >
              Ver mais
            </Button>
          </Flex>
        </>
      )}

      <div className="d-flex align-items-center justify-content-center">
        {errorMessage && (
          <Alert className="my-3" variant={errorMessage ? 'danger' : ''}>
            <span>{errorMessage}</span>
          </Alert>
        )}
      </div>
    </Box>
  );
}

export default ProductsSection;
