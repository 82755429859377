import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, MenuList, MenuItem, Button, Text } from '@chakra-ui/react';
import { MemberArea } from '..';

interface MemberAreaSelectProps {
  value: MemberArea;
  lastUpdated: string;
  onChange: (value: string) => void;
}

export default function MemberAreaSelect({ value, lastUpdated, onChange }: MemberAreaSelectProps) {
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    const selectedValue = event.currentTarget.getAttribute('data-value');

    if (selectedValue) {
      onChange(selectedValue);
    }
  }

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          bg="transparent"
          px={0}
          mt={{ base: 4, xl: 10 }}
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'gray.100' }}
          sx={{
            '.chakra-button__icon': { ml: 0 },
            '.chakra-button__icon svg': { boxSize: '1.375rem' },
          }}
        >
          <Text fontSize={{ base: 'xl', xl: '2rem' }} fontWeight="medium">
            {value === 'netflix' ? 'Estilo Netflix' : 'Clássica'}
          </Text>
        </MenuButton>

        <MenuList>
          <MenuItem data-value="proluno" onClick={handleClick}>
            Clássica
          </MenuItem>
          <MenuItem data-value="netflix" onClick={handleClick}>
            Estilo Netflix
          </MenuItem>
        </MenuList>
      </Menu>

      <Text fontSize="sm" fontWeight="medium" color="#20212380">
        Última edição em: {lastUpdated}
      </Text>
    </>
  );
}
