import { Link } from 'react-router-dom';

import styles from '../../styles.module.css';

import logo from '../../../../assets/images/logos/proluno_cor.png';

function ProfessionalPlan() {
  return (
    <div className={styles.container}>
      <div className={styles.policyHead}>
        <div className={styles.policyContentHead}>
          <img src={logo} alt="Logo proluno" />
          <h3>Termos de Uso do Plano Profissional</h3>
        </div>
        <p>
          Esta política se aplica a todos os contratantes do <b>PLANO PROFISSIONAL</b> fornecidos
          pela <b>PROLUNO TECNOLOGIA PARA EDUCACAO LTDA</b> (CNPJ 24.641.922/0001-46).
          <br />
          <br />
          Os presentes termos tem valor de cláusulas contratuais para você ao assinar o plano.
        </p>
      </div>

      <ul>
        <h3 className={styles.heading}>1. DAS CONSIDERAÇÕES INICIAIS</h3>
        <div className="mb-4">
          <span>
            É importante que leia com atenção as considerações iniciais, para você poder conhecer
            mais sobre a sua relação com a Proluno.
          </span>
        </div>
        <li>
          <b>1.1.</b> A contratação do <b>PLANO PROFISSIONAL</b> pressupõe a aceitação pelo
          contratante dos
          <Link
            to="/term-of-use"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Termos Geral de Uso da Proluno
          </Link>
        </li>
        <li>
          <b>1.2.</b> O presente documento disciplina as regras aplicáveis ao assinante que,
          mediante pagamento da assinatura, em formato mensal ou anual, passa a ter a licença de uso
          a plataforma digital para o ensino e comercialização de produtos digitais pelo tempo que
          permanecer adimplente.
        </li>
        <li>
          <b>1.3.</b> O acesso à Plataforma Proluno funciona na modalidade de “SAAS”{' '}
          <i>(Software as a Service)</i>. A Plataforma Proluno, e todos os elementos que a compõem,
          é de propriedade da <b>PROLUNO</b>, tendo o <b>Contratante</b> apenas o direito de uso
          através do pagamento de assinatura.
        </li>
        <li>
          <b>1.4.</b> A Proluno poderá alterar a qualquer momento, unilateralmente e sem prévio
          aviso, o teor deste documento e os respectivos serviços fornecidos no plano de assinatura,
          sendo, todavia, garantido ao assinante discordante o direito de cessar o uso da plataforma
          imediatamente após o conhecimento da modificação, sem qualquer ônus para as partes.
        </li>
        <li>
          <b>1.5.</b> Por se tratar de contratação de Software como Serviço, as atualizações são
          aplicadas de forma automática a todos os clientes que utilizam a Plataforma, não sendo
          possível escolher utilizar versões anteriores.
        </li>
        <li>
          <b>1.6.</b> O assinante tem largura de banda ilimitada, mas deve estar dentro do uso
          normal dos 99% de nossos clientes, para que possamos garantir a qualidade das transmissões
          (acesso aos vídeos) realizadas. Caso seja ultrapassado o chamado uso justo da largura de
          banda, a Proluno entrará em contato com a contratante para ajustar os valores da
          mensalidade da assinatura conforme o uso que desejar fazer.
        </li>
      </ul>

      <ul>
        <h3 className={styles.heading}>2. DOS SERVIÇOS CONTRATADOS</h3>
        <div className="mb-4">
          <span>
            A contratação do <b>PLANO PROFISSIONAL</b> dará a você acesso à plataforma digital da
            Proluno, com as seguintes funcionalidades:
          </span>
        </div>
        <table className={styles.table}>
          <tr className={styles.tableLine}>
            <th>
              <b>PLANO PROFISSIONAL</b>
            </th>
          </tr>
          <tr className={styles.tableLine}>
            <th>
              <b>PRODUTOS</b>
            </th>
          </tr>

          <tbody>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center justify-content-between">
                <span className="w-50 text-center text-center">Website</span>
                <span className="w-50 text-center text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Área de membros</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Produtos</span>
                <span className="w-50 text-center">10</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Cursos em vídeo</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Cursos em PDF</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Curso ao Vivo com YouTube</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Curso ao Vivo com Zoom</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Simulados</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Tarefas</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
          </tbody>

          <tr className={styles.tableLine}>
            <th>
              <b>ARMAZENAMENTO EM NUVEM</b>
            </th>
          </tr>

          <tbody>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Proluno Cloud</span>
                <span className="w-50 text-center">20GB</span>
              </div>
            </tr>
          </tbody>

          <tr className={styles.tableLine}>
            <th>
              <b>FUNCIONALIDADES DE MARKETING</b>
            </th>
          </tr>

          <tbody>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Domínio Personalizado</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Cupom de Desconto</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Depoimentos</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Base de Usuários</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Número de Alunos Cadastrados (Leads)</span>
                <span className="w-50 text-center">3.000</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Integração com RD Station</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Remoção da Marca Proluno</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
          </tbody>

          <tr className={styles.tableLine}>
            <th>
              <b>FUNCIONALIDADES DE VENDA</b>
            </th>
          </tr>

          <tbody>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Recebimento Cartão</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Recebimento Boleto</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Pagamentos Internacionais</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Splits Automatizados</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Saques (quando quiser)</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Antecipações</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
          </tbody>

          <tr className={styles.tableLine}>
            <th>
              <b>FUNCIONALIDADES DE SEGURANÇA</b>
            </th>
          </tr>

          <tbody>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Limitador de Visualizações</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Limitador do período de acesso </span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Marca D'água no PDF</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
          </tbody>

          <tr className={styles.tableLine}>
            <th>
              <b>VENDAS POR MARKETPLACE</b>
            </th>
          </tr>

          <tbody>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Taxa de transação</span>
                <span className="w-50 text-center">40%</span>
              </div>
            </tr>
          </tbody>

          <tr className={styles.tableLine}>
            <th>
              <b>ALUNOS</b>
            </th>
          </tr>

          <tbody>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Alunos ativos</span>
                <span className="w-50 text-center">300</span>
              </div>
            </tr>
          </tbody>

          <tr className={styles.tableLine}>
            <th>
              <b>SERVIÇOS</b>
            </th>
          </tr>

          <tbody>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Customer Success</span>
                <span className="w-50 text-center">Sim</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">ProMarketing</span>
                <span className="w-50 text-center">Desconto 15%</span>
              </div>
            </tr>
          </tbody>

          <tr className={styles.tableLine}>
            <th>
              <b>CONDIÇÕES COMERCIAIS</b>
            </th>
          </tr>

          <tbody>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Taxa de Transação</span>
                <span className="w-50 text-center">7%</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Periodo Gratuito</span>
                <span className="w-50 text-center">14 dias</span>
              </div>
            </tr>
            <tr className={styles.tableLineBody}>
              <div className="d-flex align-items-center jsutify-content-between">
                <span className="w-50 text-center">Mensalidade</span>
                <span className="w-50 text-center">R$ 397,00</span>
              </div>
            </tr>
          </tbody>
        </table>
      </ul>

      <ul>
        <h3 className={styles.heading}>3. DA SUA ASSINATURA</h3>
        <div className="mb-4">
          <span>
            Você poderá assinar a plataforma com pagamentos mensais ou anuais, e ela observará as
            seguintes regras:.
          </span>
        </div>
        <li>
          <b>3.1.</b> Nosso produto é cobrado por assinatura. Isso significa que você será cobrado
          antecipadamente em uma base recorrente e periódica (cada período é chamado de “ciclo de
          faturamento”). Os ciclos de cobrança são normalmente mensais ou anuais, dependendo de qual
          plano de assinatura você seleciona ao adquirir uma assinatura. O pagamento será cobrado de
          acordo com o método de pagamento escolhido na confirmação da compra e no início de cada
          novo ciclo de faturamento. Sua assinatura será renovada automaticamente no final de cada
          ciclo de faturamento, a menos que você cancele sua conta através da página de
          gerenciamento de conta online ou entrando em contato conosco em
          <span className={styles.link}> financeiro@proluno.com.br</span>. Caso opte pelo
          cancelamento, sua assinatura continuará até o final do ciclo de faturamento antes de ser
          encerrada.
        </li>
        <li>
          <b>3.2.</b> Ao cancelar a conta após o período gratuito, você será cobrado pelo preço
          total do plano. Se você cancelar antes do processamento de sua primeira fatura, você não
          será cobrado.
        </li>
        <li>
          <b>3.3.</b> Ao assinar no formato anual, você ganhará um desconto conforme a política
          comercial vigente à época da adesão. Em caso de cancelamento antes de decorrido o ano de
          assinatura, você não fará jus a devolução de qualquer recurso, podendo usar a plataforma
          até o termo final inicialmente contratado. O cancelamento ocorrerá para o próximo ciclo de
          cobrança.
        </li>
        <li>
          <b>3.4.</b> Por meio de nosso gateway de pagamento terceirizado, mantemos em arquivo suas
          informações detalhadas de pagamento, como números parciais de cartão de crédito não
          confidenciais e data de validade. Não acessamos essas informações, exceto por meio de
          métodos programáticos fornecidos pelo provedor. A contratante é responsável por manter
          seus detalhes de pagamento atualizados, alterando os detalhes nas configurações de sua
          conta. Quando seus detalhes forem alterados ou estiverem prestes a expirar, podemos obter
          ou receber de seu provedor de pagamento os detalhes de pagamento atualizados, incluindo o
          número do seu cartão, data de validade e CVV (ou equivalente). Isso nos permite fornecer a
          você acesso ao Produto. A contratante nos autoriza a continuar cobrando seu cartão de
          crédito usando as informações atualizadas. Se um pagamento não for autorizado com sucesso
          devido a expiração, fundos insuficientes ou de outra forma, podemos suspender ou encerrar
          sua assinatura até que as alterações aplicáveis ​​ocorram.
        </li>
        <li>
          <b>3.5.</b> Não se aplicam períodos gratuitos de acesso à plataforma em caso de upgrade ou
          downgrade de plano pelo assinante. Em caso da ocorrência de alguma das alterações, será
          gerada uma fatura já considerando o novo valor, proporcionalmente ao tempo de uso no ciclo
          de cobrança.
        </li>
        <li>
          <b>3.6.</b> Em caso de atraso da mensalidade, será cobrado do assinante encargos no valor
          de 1% ao mês de juros moratórios e 2% de multa sobre o valor da mensalidade.
        </li>
        <li>
          <b>3.7.</b> A Proluno poderá suspender o acesso à plataforma em caso de inadimplemento da
          mensalidade superior a 15 (quinze) dias após o vencimento, independente de qualquer outra
          formalidade.
        </li>
      </ul>
      <ul>
        <h3 className={styles.heading}>4. DO MARKETPLACE</h3>
        <div className="mb-4">
          <span>
            O marketplace é o espaço digital, sob a gestão da Proluno, disponibilizado ao assinante
            para facilitar a venda de seus produtos ou serviços.
          </span>
        </div>
        <li>
          <b> 4.1.</b> As condições de uso e comercialização do Marketplace estão previstas na
          <Link
            to="/term-of-use"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
            style={{ opacity: 0.6, cursor: 'not-allowed' }}
          >
            {'Política de Marketplace '}
          </Link>
          da Proluno, e se incorporam ao contrato de todos os membros que utilizam o serviço.
        </li>
        <li>
          <b> 4.2.</b> A plataforma Proluno disponibilizará automaticamente todos os seus produtos
          em nosso Marketplace. Caso não queira disponibilizá-lo nesse canal de vendas, basta
          restringir a exibição dos produtos na sua área de administrador.
        </li>
        <li>
          <b> 4.3.</b> Será cobrada pela Proluno, em caso de venda no seu marketplace de produto ou
          serviço do assinante de <b>PLANO INICIANTE</b>, o percentual de 50% sobre o valor da
          transação. Neste caso, a Proluno arcará proporcionalmente com as despesas da transação.
        </li>
      </ul>
      <ul>
        <h3 className={styles.heading}>5. DA TAXA DE TRANSAÇÃO</h3>

        <li>
          <b>5.1.</b> A Proluno cobra, de forma automática, uma taxa sobre o valor captado na venda
          de produtos ou serviços disponibilizados na plataforma. Para os assinantes do PLANO
          INICIANTE, o percentual cobrado é de 10% (dez por cento).
        </li>
        <li>
          <b>5.2.</b> A Proluno arcará proporcionalmente com os custos da transação cobrado pelo
          Gateway de pagamento parceiro.
        </li>
        <li>
          <b>5.3.</b> Para maiores informações sobre o pagamento, você deve consultar a nossa
          Política de Intermediação de Pagamento.
        </li>
      </ul>
      <ul>
        <h3 className={styles.heading}>6. DO PROLUNO CLOUND:</h3>

        <div className="mb-4">
          <span>
            A Proluno disponibiliza espaço em nuvem para o assinante armazenar o seu conteúdo que
            usará na plataforma. O assinante deve ficar atento às regras do serviço abaixo
            transcritas:
          </span>
        </div>

        <li>
          <b>6.1.</b> O armazenamento em nuvem dos arquivos utilizados na plataforma Proluno pelo
          assinante não exime o mesmo da sua responsabilidade de fazer backups e guardar os
          originais.O referido armazenamento serve exclusivamente para facilitar o uso da plataforma
          Proluno, não servindo como banco de dados para proteção de conteúdo.
        </li>
        <li>
          <b>6.2.</b> A Proluno não se responsabiliza por perda de conteúdo armazenado por assinante
          em eventual problema técnico, atualização, ataque hacker ou qualquer outro motivo.
        </li>
        <li>
          <b>6.3.</b> Em caso de suspensão das contas do assinante, por qualquer motivo, a Proluno
          manterá os dados relacionados ao mesmo por 45 (quarenta e cinco) dias, podendo, se for do
          seu interesse, solicitar o backup das informações. Após esse prazo, os dados serão
          deletados dos bancos de dados da Proluno.
        </li>
        <li>
          <b>6.4.</b> O backup observará os procedimentos técnicos e operacionais da Proluno
          vigentes à época da solicitação.
        </li>
      </ul>
    </div>
  );
}

export default ProfessionalPlan;
