import { Box, Table as ChakraTable, Link, Tbody, Text, Thead, Tr } from '@chakra-ui/react';
import { MouseEvent, memo, useEffect, useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import QuestionsAPI from '../../../../../../../api/Question';
import Loader from '../../../../../../../components/Loadings/DotsFalling';
import Pagination from '../../../../../../../components/Pagination';
import Toast from '../../../../../../../components/Toast';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import CommentModal from './CommentModal';
import ModalQuestion from './ModalQuestion';
import { Td, Th } from './styled';

interface ITable {
  courseId: number | string;
  moduleId: number | string;
  contentId: number | string;
}

interface IQuestion {
  id: number;
  question: string;
  order: number;
  isCorrect: boolean;
  questionText: string;
  commentary: string;
  status: string;
  correctItem: {
    option: string;
    optionText: string;
  };
  answerUser: {
    option: string;
    optionText: string;
  };
}

function Table({ courseId, moduleId, contentId }: ITable) {
  const [resultByQuestion, setResultByQuestion] = useState<IQuestion[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState({} as IQuestion);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModaQuestion, setIsShowModalQuestion] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const { reportIndividualStudents, total, limit } =
          await QuestionsAPI.student.getResultByQuestions(
            courseId,
            moduleId,
            contentId,
            currentPage + 1
          );

        setResultByQuestion(reportIndividualStudents);
        setPageCount(total / limit);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    })();
  }, [contentId, courseId, currentPage, moduleId]);

  function toggleModal(event: MouseEvent<HTMLAnchorElement>, question: IQuestion) {
    event.preventDefault();

    setIsShowModal(prevState => !prevState);
    setSelectedQuestion(question);
  }
  function toggleModalQuestion(e: MouseEvent, question: IQuestion) {
    e.preventDefault();

    setIsShowModalQuestion(prevState => !prevState);
    setSelectedQuestion(question);
  }

  function handlePageChange({ selected: selectedPage }) {
    setIsLoading(true);

    setCurrentPage(selectedPage);
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        gridArea="table"
        width="100%"
        borderRadius="lg"
        border="1px solid  rgba(32, 33, 35, 0.25)"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        p="4"
        bg="#37393D"
      >
        <Box as="header" display="flex" width="100%" justifyContent="space-between">
          <Text position="relative" fontSize="xl" fontWeight="700" color="#FFF">
            Resultado por questões
          </Text>
        </Box>

        {isLoading && (
          <Box display="flex" justifyContent="center" my="10" mx="0">
            <Loader />
          </Box>
        )}

        {!isLoading && (
          <ChakraTable mt="6" mb="8" mx="0">
            <Thead>
              <Tr borderBottom="1px" borderColor="#20212340">
                <Th color="#FFF">Questão</Th>
                <Th color="#FFF">Status</Th>
                <Th color="#FFF">Comentário</Th>
              </Tr>
            </Thead>

            <Tbody>
              {resultByQuestion.map((question, index) => (
                <Tr key={`question${question.id}`}>
                  <Td display="flex" justifyContent="center" borderColor="none" borderBottom="none">
                    <Text mx="1" color="#FFF" cursor="pointer">
                      <AiFillEye onClick={e => toggleModalQuestion(e, question)} to="" />
                    </Text>
                    <Text mx={1} fontSize="sm" color="#FFF">
                      Questão {index + 1}
                    </Text>
                  </Td>

                  <Td
                    color={question.status ? '#22BB33' : '#BB2124BF'}
                    display={{ base: 'none', md: 'revert' }}
                  >
                    <Text mx={1} fontSize="sm">
                      {question.status ? 'Correto' : 'Incorreto'}
                    </Text>
                  </Td>

                  <Td display={{ base: '', md: 'none' }}>
                    <Box
                      h="1rem"
                      w="1rem"
                      m="auto"
                      bg={question.status ? '#22BB33' : '#BB2124BF'}
                      borderRadius="full"
                      flexShrink={0}
                    />
                  </Td>

                  <Td color="#FFF">
                    {!question.commentary && '-'}

                    {question.commentary && (
                      <>
                        <Link
                          textDecoration="underline !important"
                          fontWeight="500"
                          color="#8eaae9 !important"
                          onClick={event => toggleModal(event, question)}
                          display={{ base: 'none', md: 'block' }}
                        >
                          Visualizar
                        </Link>
                        <Text
                          m="auto"
                          display={{ base: 'flex', md: 'none' }}
                          justifyContent="center"
                        >
                          <AiFillEye
                            onClick={e => toggleModalQuestion(e, question)}
                            color="#ea7129"
                          />
                        </Text>
                      </>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </ChakraTable>
        )}

        {pageCount > 1 && (
          <Pagination
            onPageActive={currentPage}
            pageCount={pageCount}
            onPageChange={handlePageChange}
          />
        )}
      </Box>

      <CommentModal
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        data={selectedQuestion}
      />

      <ModalQuestion
        show={isShowModaQuestion}
        onHide={() => setIsShowModalQuestion(false)}
        data={selectedQuestion}
      />
    </>
  );
}

export default memo(Table);
