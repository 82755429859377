import Joi from 'joi';
import { ISegmentation } from '.';

const segmentationSchema = {
  id: Joi.number().messages({
    'number.base': 'O id não pode ficar vazia.',
  } as JoiMessages.LanguageMessages),
  name: Joi.string().messages({
    'any.required': 'O nome é obrigatório.',
    'string.empty': 'O nome não pode ser vazio.',
  }),
  operator: Joi.string().messages({
    'any.required': 'A condição é obrigatória.',
    'string.empty': 'A condição não pode ser vazia.',
  }),
  runOrStatistics: Joi.string().valid('runAll', 'statistics').messages({
    'any.required': 'O campo `runOrStatistics` é obrigatório.',
    'string.empty': 'O campo `runOrStatistics` não pode ser vazio.',
  }),
  rules: Joi.array(),
  runLeadSegmentationAnalysis: Joi.boolean(),
};

export const createOrUpdateSegmentationSchemaValidator = Joi.object<Partial<ISegmentation>>().keys({
  id: segmentationSchema.id,
  name: segmentationSchema.name.required(),
  operator: segmentationSchema.operator.required(),
  rules: segmentationSchema.rules.required(),
  runLeadSegmentationAnalysis: segmentationSchema.runLeadSegmentationAnalysis.required(),
});

export const segmentationAnalyzing = Joi.object<Partial<ISegmentation>>().keys({
  operator: segmentationSchema.operator.required(),
  rules: segmentationSchema.rules.required(),
  runOrStatistics: segmentationSchema.runOrStatistics.required(),
});
