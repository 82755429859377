import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';

export default function useDateRangePickerCustomStyles() {
  useEffect(() => {
    const nextButton = document.querySelector('.rdrNextButton i');
    const prevButton = document.querySelector('.rdrPprevButton i');

    if (nextButton) {
      const nextIcon = document.createElement('div');
      const nextRoot = createRoot(nextIcon);
      nextRoot.render(<MdOutlineChevronRight />);
      nextButton.innerHTML = '';
      nextButton.appendChild(nextIcon);
    }

    if (prevButton) {
      const prevIcon = document.createElement('div');
      const prevRoot = createRoot(prevIcon);
      prevRoot.render(<MdOutlineChevronLeft />);
      prevButton.innerHTML = '';
      prevButton.appendChild(prevIcon);
    }
  }, []);
}
