import { ILocalStorageShoppingCart } from '../hooks/useShoppingCart';
import { axiosBackend } from './Configuration';

export default {
  add: async (payload: ILocalStorageShoppingCart) => {
    const { data } = await axiosBackend().post('/v2/purchases/add-to-cart', payload);

    return data;
  },
};
