import { InputHTMLAttributes, useEffect, useState, KeyboardEvent } from 'react';
import InputMask from 'react-input-mask';
import styles from '../styles.module.css';
import useDebounce from '../../../../../hooks/useDebounce';

const CPF_MASK = '999.999.999-99';
const CNPJ_MASK = '99.999.999/9999-99';

function unmask(value: string) {
  return value.replace(/\D/g, '');
}

function getMask(value: string) {
  const unmaskedValue = unmask(value);
  const size = unmaskedValue.length;
  const shouldApplyCNPJMask = size >= 12;
  return shouldApplyCNPJMask ? CNPJ_MASK : CPF_MASK;
}

type DocumentInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange?: (value: string) => void;
};

export default function DocumentInput(props: DocumentInputProps) {
  const [documentNumber, setDocumentNumber] = useState('');
  const [mask, setMask] = useState(CNPJ_MASK);

  const { value, onChange, ...rest } = props;

  useEffect(() => {
    setDocumentNumber(String(value));
    setMask(getMask(String(value)));
  }, [value]);

  const debouncedValue = useDebounce(documentNumber, 500);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  function applyMask(size: number) {
    const shouldApplyCNPJMask = size >= 12;
    shouldApplyCNPJMask ? setMask(CNPJ_MASK) : setMask(CPF_MASK);
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    const value = unmask(event.currentTarget.value);
    const digit = event.key.replace(/\D/g, '');
    const size = value.concat(digit).length;

    setDocumentNumber(value);
    applyMask(size);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const unmaskedValue = unmask(event.target.value);
    const size = unmaskedValue.length;

    setDocumentNumber(unmaskedValue);
    applyMask(size);
  }

  return (
    <InputMask
      mask={mask}
      maskChar=""
      value={documentNumber}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
    >
      {(inputProps: InputHTMLAttributes<HTMLInputElement>) => (
        <div className={styles.inputGroup}>
          <input {...rest} {...inputProps} />
        </div>
      )}
    </InputMask>
  );
}
