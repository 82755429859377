import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormEvent, useEffect } from 'react';
import useHandleChange from '../../../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../../../hooks/useHandleSubmit';
import { EditModuleSchemaType, editModuleSchemaValidator } from './editModuleSchemaValidator';

export default function EditModuleModal({
  isOpen,
  onClose,
  showcaseId,
  showcaseCourseId,
  moduleSelect,
  modules,
  showcaseModules,
  setShowcaseModules,
}) {
  const { form, handleChange, setForm, onChanged, setOnChanged } =
    useHandleChange<EditModuleSchemaType>({
      name: '',
      status: '',
    });

  useEffect(() => {
    if (moduleSelect.name) {
      setForm({ name: moduleSelect.name, status: moduleSelect.status });
    }
  }, [moduleSelect.name, moduleSelect.status, setForm]);

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit({
    data: form,
    schemaValidator: editModuleSchemaValidator,
    url: `/showcase-course/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/${moduleSelect.id}/module`,
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Módulo atualizado com sucesso!',
      callback: afterUpdateConfirm,
    },
  });

  async function afterUpdateConfirm() {
    const modulesUpdate = modules.find(module => module.id === moduleSelect.id);

    if (!modulesUpdate) return;

    modulesUpdate.name = form.name;

    setShowcaseModules({ ...showcaseModules, modules: [...modules] });

    setOnChanged(false);

    onClose();
  }

  const isInvalid = formValidation?.name?.isInvalid;
  const errorMessage = formValidation?.name?.message;

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Editar módulo ({moduleSelect.name})</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={isInvalid}>
            <FormLabel>Nome do módulo</FormLabel>

            <Input
              id="name"
              name="name"
              value={form.name}
              focusBorderColor="primary.500"
              onChange={handleChange}
            />

            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button size="sm" onClick={onClose} colorScheme="gray">
              Cancelar
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              color="secondary.500"
              isDisabled={isSubmitting || !onChanged}
              isLoading={isSubmitting}
              type="submit"
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
