import { Select } from 'chakra-react-select';

export interface Courses {
  id: number;
  name: string;
}

interface CourseFilterSelectProps {
  onChange: (courseId: number) => void;
  courses: Courses[];
  isLoading: boolean;
}

export default function CourseFilterSelect({
  onChange,
  courses,
  isLoading,
}: CourseFilterSelectProps) {
  const options = courses?.map(course => ({
    value: course.id,
    label: course.name,
  }));

  options?.unshift({ value: null, label: 'Todos os produtos' });

  function handleChange({ value, label }: { value: number; label: string }) {
    onChange(value);
  }

  return (
    <Select
      onChange={handleChange}
      placeholder="Selecione um produto"
      focusBorderColor="primary.500"
      hasStickyGroupHeaders
      closeMenuOnSelect={true}
      selectedOptionColor="none"
      noOptionsMessage={() => 'Nenhum produto encontrado.'}
      options={options}
      isLoading={isLoading}
      menuPortalTarget={document.body}
      chakraStyles={{
        container: provided => ({
          ...provided,
          width: '100%',
          color: '#20212380',
          fontSize: 'sm',
        }),
        placeholder: provided => ({
          ...provided,
          color: '#20212380',
          fontSize: 'sm',
        }),
        dropdownIndicator: provided => ({
          ...provided,
          bg: 'transparent',
          px: 2,
        }),
        indicatorSeparator: provided => ({
          ...provided,
          display: 'none',
        }),
        option: provided => ({
          ...provided,
          color: '#20212380',
          fontSize: 'sm',
        }),
        valueContainer: provided => ({
          ...provided,
          px: 3,
          color: '#20212380',
          fontSize: 'sm',
        }),
      }}
    />
  );
}
