import { HStack, Image, Stack, Text } from '@chakra-ui/react';

import Dropdown from '../Dropdown';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import FacebookIntegrationAPI from '../../../../../api/FacebookIntegration';
import { PixelType } from '../../types';
import Toast from '../../../../../components/Toast';
import facebookIcon from '../../../../../assets/icons/facebookIcon.svg';

interface ICardProps {
  pixel: PixelType;
  deletePixel: (id: number) => void;
}

function Card({ pixel, deletePixel }: ICardProps) {
  async function handleDeletePixel() {
    try {
      await FacebookIntegrationAPI.delete(pixel.id);

      deletePixel(pixel.id);

      Toast('Pixel excluído!');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  return (
    <HStack
      position="relative"
      padding="3.5"
      display="flex"
      background="white"
      border="1px solid rgba(32, 33, 35, 0.05)"
      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
      borderRadius="base"
      gap="3"
      zIndex={0}
    >
      <Dropdown handleDeletePixel={handleDeletePixel} pixelId={pixel.id} />

      <Image src={facebookIcon} width="1.5rem" height="1.5rem" alt="ícone facebook; cor preta;" />
      <Stack>
        <Text fontSize="sx" fontWeight="semibold" color="dark.500">
          {pixel?.name}
        </Text>
        <Text
          marginTop="0.25rem !important"
          fontSize="sx"
          fontWeight="medium"
          color="rgba(32, 33, 35, 0.5)"
        >
          {pixel?.courseName}
        </Text>
      </Stack>
    </HStack>
  );
}

export default Card;
