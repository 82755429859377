import { ChangeEvent, FormEvent, useState, useEffect } from 'react';

export type TargetType = (EventTarget & HTMLInputElement) | HTMLSelectElement | HTMLTextAreaElement;

export function onlyAlphaNumeric(event: FormEvent<HTMLInputElement>) {
  const { currentTarget } = event;

  currentTarget.value = currentTarget.value.replace(/[^\w]/g, '');
}

export function onlyNumber(event: FormEvent<HTMLInputElement>) {
  const { currentTarget } = event;

  currentTarget.value = currentTarget.value.replace(/[^0-9]/g, '');
}

export function onlyAlpha(event: FormEvent<HTMLInputElement>) {
  const { currentTarget } = event;

  currentTarget.value = currentTarget.value.replace(/[^a-zA-Z]/g, '');
}

export function onlyAlphaAndSpace(event: FormEvent<HTMLInputElement>) {
  const { currentTarget } = event;

  currentTarget.value = currentTarget.value.replace(/[^a-zA-Z\s]/g, '');
}

type SideEffectType = { name: string; value: string | boolean | number };

export default function useHandleChange<DataType>(
  initialValue: Partial<DataType>,
  data?: DataType
) {
  const [form, setForm] = useState(initialValue);

  const [onChanged, setOnChanged] = useState(false);

  const [initialFormValue, setInitialFormValue] = useState(initialValue);

  const [dataUpdated, setDataUpdated] = useState(false);

  useEffect(() => {
    if (!dataUpdated && data) {
      const values = {} as DataType;

      const valuesKeys = Object.keys(data);

      for (const key of valuesKeys) {
        const value = data[key as keyof DataType];

        if (value) {
          values[key as keyof DataType] = value;
        }
      }

      const formData = { ...form, ...values };

      setForm(formData);
      setInitialFormValue(formData);
      setDataUpdated(true);
      setOnChanged(false);
    }
  }, [data, dataUpdated, form]);

  function onChange<T>(name: string, value: string | boolean | number, currentData: T): T {
    if (name.match(/\w+\.\w+/g)) {
      const [subObjectKey, subKey] = name.toString().split('.', 2);

      const oldDataSubObject = (currentData as any)[subObjectKey];

      return {
        ...currentData,
        [subObjectKey]: { ...oldDataSubObject, [subKey]: value },
      } as T;
    }

    return { ...currentData, [name]: value } as T;
  }

  function handleChange(event: ChangeEvent<TargetType>, sideEffect?: SideEffectType) {
    const { name, value, type } = event.target as TargetType;

    let inputValue: string | boolean = value || '';

    if (type === 'checkbox') {
      inputValue = (event.target as HTMLInputElement).checked;
    }

    let data = onChange(name, inputValue, form);

    if (sideEffect) {
      data = onChange(sideEffect.name, sideEffect.value, data);
    }

    setForm(data);

    setOnChanged(true);
  }

  function handleCancel() {
    setForm(initialFormValue);
    setOnChanged(false);
  }

  return {
    form,
    setForm,
    handleChange,
    handleCancel,
    setOnChanged,
    onChanged,
  };
}
