import { Box, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import PlatformAPI from '../../api/Platform';
import LoadingPage from '../../assets/images/backgrounds/loading-page.png';
import logoImage from '../../assets/images/logos/proluno_branca.png';
import Toast from '../../components/Toast';
import ErrorResponse from '../../helpers/ErrorResponse';
import useQuery from '../../hooks/useQuery';
import AnimatedBoxesLoading from './AnimatedBoxesLoading';
import { SCRIPTS_PROLUNO } from '../../ExternalScripts';
import { IS_COOKIES_ACCEPTED } from '../../helpers/LocalStorageHelper';

const head = document.getElementsByTagName('head').item(0);

function getBgGradient(degree: number) {
  return `linear-gradient(${degree}deg, #202123 49.48%, rgba(34, 35, 37, 0.988666) 57.29%, rgba(53, 54, 56, 0.883959) 66.15%, rgba(77, 78, 80, 0.754755) 76.56%, rgba(217, 217, 217, 0) 100%), url(${LoadingPage})`;
}

const bgGradient = {
  base: getBgGradient(180),
  lg: getBgGradient(90),
};

const TrialPeriodLoading = () => {
  const [index, setIndex] = useState(0);
  const query = useQuery();

  const isCookiesAccepted = localStorage.getItem(IS_COOKIES_ACCEPTED);

  useEffect(() => {
    if (isCookiesAccepted === 'false') return;

    const googleTagManagerLink = document.createElement('script');

    googleTagManagerLink.id = 'googleTagManagerLink';

    googleTagManagerLink.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${SCRIPTS_PROLUNO.GOOGLE_TAG}');
    `;

    if (!document.getElementById('googleTagManagerLink')) {
      head.appendChild(googleTagManagerLink);
    }

    const googleTagManager = document.createElement('script');

    googleTagManager.id = 'googleTagManager';

    googleTagManager.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${SCRIPTS_PROLUNO.GOOGLE_TAG}', {
      page_path: window.location.pathname,
    });
    `;

    if (!document.getElementById('googleTagManager')) {
      head.appendChild(googleTagManager);
    }

    const googleTagConversion = document.createElement('script');

    googleTagConversion.id = 'googleTagConversion';

    let AW_PARAMS = '';

    const isFreeTest = query.get('plan') === 'iniciante---mensal---teste-gratuito';

    if (isFreeTest) {
      AW_PARAMS = 'AW-11126503745/9be3CMrZzZUYEMHyw7kp';
    } else {
      AW_PARAMS = 'AW-11126503745/ZS8WCNGly5YYEMHyw7kp';
    }

    const googleTagConversionScript = `gtag('event', 'conversion', {'send_to': '${AW_PARAMS}'${
      isFreeTest ? '' : `, 'value': ${query.get('value')}, 'currency': 'BRL'`
    }});`;

    if (!googleTagConversionScript) return;

    googleTagConversion.innerHTML = googleTagConversionScript;

    if (!document.getElementById('googleTagConversion')) {
      head.appendChild(googleTagConversion);
    }

    const fbpPurchase = document.createElement('script');

    fbpPurchase.id = 'fbpPurchase';

    fbpPurchase.innerHTML = `fbq('track', 'Purchase', {value: ${query.get(
      'value'
    )}, currency: 'BRL'});`;

    if (!document.getElementById('fbpPurchase') && !isFreeTest) {
      head.appendChild(fbpPurchase);
    }

    return () => {
      const googleTagConversion = document.getElementById('googleTagConversion');

      if (googleTagConversion) {
        head.removeChild(googleTagConversion);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkDomainAvailability(domain: string) {
    try {
      const { data: checkDomain } = await PlatformAPI.checkDomain(domain);

      if (checkDomain) {
        return true;
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  useEffect(() => {
    (async () => {
      const token = query.get('token');
      const url = query.get('platform');

      if (!token || !url) return;

      try {
        let intervalId: any;

        const check = async () => {
          if (url) {
            const domainChecked = await checkDomainAvailability(url);

            if (domainChecked) {
              if (intervalId) {
                clearInterval(intervalId);
              }

              setTimeout(() => {
                window.location.href = `https://${url}/trial-period-form?token=${token}`;
              }, 2000);
            }
          }
        };

        intervalId = setInterval(check, 1500);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    })();
  }, [query]);

  const loadingMessages = [
    'Preparando o ambiente...',
    'Por favor, aguarde um momento...',
    'Carregando as informações...',
    'Estamos quase lá!',
    'Isso só vai levar alguns segundos...',
    'Aproveite este tempo para tomar um café!',
    'Finalizando as operações...',
    'Obrigado por sua paciência!',
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % loadingMessages.length);
    }, 1500);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      minH="100vh"
      backgroundRepeat="no-repeat"
      bgSize="contain"
      bgGradient={bgGradient}
      backgroundPosition={{ base: 'bottom', lg: 'right' }}
    >
      <Flex grow={1} pt={{ base: 10, lg: 10 }} pb={{ base: 20, lg: 20 }} px={{ base: 16, lg: 24 }}>
        <Flex
          grow={1}
          direction="column"
          justify={{ base: 'flex-start', lg: 'space-between' }}
          align={{ base: 'center', lg: 'flex-start' }}
        >
          <Flex justify={{ base: 'center', lg: 'flex-start' }}>
            <Image src={logoImage} w={172} h={90} />
          </Flex>

          <VStack align={{ base: 'center', lg: 'flex-start' }} mt={{ base: 16, lg: 0 }} maxW="100%">
            <Box mb={16} textAlign={{ base: 'center', lg: 'left' }}>
              <Heading color="#FFF">Aguarde</Heading>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                py={10}
              >
                <Text
                  as="span"
                  my={5}
                  color="#FFF"
                  fontSize={{ base: 'md', md: '2xl' }}
                  maxW="100%"
                >
                  {loadingMessages[index]}
                </Text>
              </Box>
            </Box>
          </VStack>
          <AnimatedBoxesLoading />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TrialPeriodLoading;
