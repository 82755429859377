import { Box, Flex, Heading, ModalBody, Text, VStack } from '@chakra-ui/react';
import { MdCheck } from 'react-icons/md';

export default function ModalContentSuccess() {
  return (
    <>
      <ModalBody pb={6}>
        <VStack mt={4} spacing={5} textAlign="center">
          <Flex justify="center" align="center" rounded="full" boxSize="12" bg="#C6F6D5">
            <MdCheck size="1.5rem" color="#25855A" />
          </Flex>

          <Box>
            <Heading as="h6" fontSize="lg" fontWeight="medium">
              Parabéns por sua assinatura!!
            </Heading>

            <Text mt={4} fontSize="sm" fontWeight="normal" color="gray.500">
              Você já pode aproveitar todas as vantagens da Proluno.
            </Text>
          </Box>
        </VStack>
      </ModalBody>
    </>
  );
}
