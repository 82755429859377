import { Box, Flex, List, ListItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdCreditCard as CreditCardIcon, MdInsertDriveFile as BoletoIcon } from 'react-icons/md';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import AuthenticationAPI from '../../../../api/Authentication';
import { ReactComponent as PixIcon } from '../../../../assets/icons/pixIcon.svg';
import { TOKEN_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import CreditCard from './CreditCard';
import UserAPI from '../../../../api/User';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import Boleto from './Boleto';
import Pix from './Pix';
import { NavLink } from './styled';
import { ParamsType } from '../../types';

function PaymentMethod({ ...props }) {
  const history = useHistory();
  const { payment_method } = useParams<ParamsType>();
  const [paymentMethodSubscription, setPaymentMethodSubscription] = useState([]);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

      if (!token) {
        return history.push(
          `/product/${props?.productId}/subscription-checkout/sign-up${
            props?.courseSubscriptionPlan
              ? `?courseSubscriptionPlan=${props?.courseSubscriptionPlan}`
              : ''
          }`
        );
      }

      const { data } = await AuthenticationAPI.validateToken(token);

      if (!data) {
        history.push(
          `/product/${props?.productId}/subscription-checkout/sign-up${
            props?.courseSubscriptionPlan
              ? `?courseSubscriptionPlan=${props?.courseSubscriptionPlan}`
              : ''
          }`
        );
        return;
      }
    })();
  }, [history, props?.productId, props?.courseSubscriptionPlan]);

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

    if (token) {
      (async () => {
        try {
          const { data: creditCardData } = await UserAPI.creditCard();

          if (creditCardData) {
            props?.setCreditCards(creditCardData);
          }
        } catch (error) {
          Toast(ErrorResponse(error), 'error');
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const paymentMethod = payment_method === 'credit-card' ? 'creditCard' : payment_method;

    props?.handlePaymentMethod(paymentMethod);
  }, [payment_method, props]);

  useEffect(() => {
    if (props?.subscription && !props?.product?.plans?.length) {
      return history.push(
        `/product/${props?.productId}/subscription-checkout/sign-up${
          props?.courseSubscriptionPlan
            ? `?courseSubscriptionPlan=${props?.courseSubscriptionPlan}`
            : ''
        }`
      );
    }

    if (props?.courseSubscriptionPlan) {
      const plan = props?.product?.plans?.find(
        plan => plan.id === parseInt(props?.courseSubscriptionPlan)
      );

      if (!paymentMethodSubscription.length && plan) {
        const paymentMethods = JSON.parse(plan.paymentMethod);

        setPaymentMethodSubscription(paymentMethods);
      }
    }
  }, [
    history,
    paymentMethodSubscription.length,
    props?.courseSubscriptionPlan,
    props?.product?.plans,
    props?.productId,
    props?.queryParams,
    props?.subscription,
  ]);

  return (
    <Box>
      <Flex
        marginTop="3"
        marginBottom="6"
        width={{ base: '24rem', sm: '100%', md: '100%', lg: '100%' }}
        overflow="auto"
        sx={{ '::-webkit-scrollbar': { width: '0px', background: 'transparent' } }}
      >
        {!props?.isPurchaseByPixConfirmed && (
          <List display="flex" alignContent="center" gap="4">
            {!props?.subscription || paymentMethodSubscription.includes('credit_card') ? (
              <ListItem>
                <NavLink
                  to={`/product/${props?.productId}/subscription-checkout/payment/credit-card${
                    props?.courseSubscriptionPlan
                      ? `?courseSubscriptionPlan=${props?.courseSubscriptionPlan}`
                      : ''
                  }`}
                  title="Cartão de crédito"
                  activeClassName="paymentMethodActive"
                >
                  <CreditCardIcon />
                  <p>Cartão de crédito</p>
                </NavLink>
              </ListItem>
            ) : (
              ''
            )}

            {/* <ListItem>
            <NavLink
              to={`/product/${props.productId}/student-checkout/payment/two-credit-card${
                props?.affiliation ? `?affiliation=${props?.affiliation}` : ''
              }`}
              title="Dois cartões"
              activeClassName="paymentMethodActive"
            >
              <CreditCardIcon />
              <p>
                Dois <br />
                cartões
              </p>
            </NavLink>
          </ListItem> */}

            {!props?.subscription || paymentMethodSubscription.includes('boleto') ? (
              <ListItem>
                <NavLink
                  to={`/product/${props?.productId}/subscription-checkout/payment/boleto${
                    props?.courseSubscriptionPlan
                      ? `?courseSubscriptionPlan=${props?.courseSubscriptionPlan}`
                      : ''
                  }`}
                  title="Boleto"
                  activeClassName="paymentMethodActive"
                >
                  <BoletoIcon />
                  <p>Boleto bancário</p>
                </NavLink>
              </ListItem>
            ) : (
              ''
            )}

            {!props?.subscription && (
              <ListItem>
                <NavLink
                  to={`/product/${props?.productId}/subscription-checkout/payment/pix${
                    props?.courseSubscriptionPlan
                      ? `?courseSubscriptionPlan=${props?.courseSubscriptionPlan}`
                      : ''
                  }`}
                  title="Pix"
                  activeClassName="paymentMethodActive"
                >
                  <PixIcon height="1.25rem" width="1.25rem" />
                  <p>Pix</p>
                </NavLink>
              </ListItem>
            )}
          </List>
        )}
      </Flex>

      <Switch>
        <Route path={`/product/${props?.productId}/subscription-checkout/payment/credit-card`}>
          <CreditCard {...props} />
        </Route>

        {/* <Route path={`/product/${props.productId}/student-checkout/payment/two-credit-card`}>
          <TwoCreditCard {...props} />
        </Route> */}

        <Route path={`/product/${props?.productId}/subscription-checkout/payment/boleto`}>
          <Boleto {...props} />
        </Route>

        <Route path={`/product/${props?.productId}/subscription-checkout/payment/pix`}>
          <Pix {...props} />
        </Route>
      </Switch>
    </Box>
  );
}

export default PaymentMethod;
