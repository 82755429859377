import { Box, Button, Center, Heading, Spinner, Stack, Text } from '@chakra-ui/react';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import FilterQuestions from '../../../../../components/FilterQuestions';
import Pagination from '../../../../../components/Pagination';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import useWindowSize from '../../../../../hooks/useWindowSize';
import {
  IInitialFilters,
  IQuestionLibraryAreaHasPaginateResult,
  IQuestionLibraryDisciplineHasPaginateResult,
  IQuestionLibraryInstitutionHasPaginateResult,
  IQuestionLibraryQuestionResult,
  IQuestionLibrarySubjectHasPaginateResult,
} from '../../../Courses/CourseManager/ModulesManager/QuestionLibrary/types';
import QuestionCardToAdd from '../QuestionCardToAdd';

const initialFilters = {
  areaId: '',
  disciplineId: '',
  subjectId: '',
  institutionId: '',
};

export default function AddQuestions() {
  const [filters, setFilters] = useState<IInitialFilters>(initialFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsIds, setQuestionsIds] = useState<number[]>([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const queryParams = new URLSearchParams();
  const { width } = useWindowSize();
  const hiddenButton = width < 767;

  const addQueryParam = (paramName: string, paramValue: string | undefined) => {
    if (paramValue) {
      queryParams.append(paramName, paramValue);
    }
  };

  addQueryParam('page', currentPage.toString());
  addQueryParam('isExternal', 'false');
  addQueryParam('filterAreaId', filters.areaId);
  addQueryParam('filterDisciplineId', filters.disciplineId);
  addQueryParam('filterSubjectId', filters.subjectId);
  addQueryParam('filterInstitutionId', filters.institutionId);

  const searchParams = queryParams.toString();
  const url = searchParams
    ? `/question-library/question?${searchParams}`
    : '/question-library/question';

  const {
    data,
    loading: isFetching,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<IQuestionLibraryQuestionResult>>({
    url,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const { data: areaData, loading: isLoadingAreaData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryAreaHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-area?hasPaginate=false`,
    authenticated: true,
    autoFetch: true,
  });

  const { data: disciplineData, loading: isLoadingDisciplineData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryDisciplineHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-discipline?hasPaginate=false`,
    authenticated: true,
    autoFetch: true,
  });

  const { data: subjectData, loading: isLoadingSubjectData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibrarySubjectHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-subject?hasPaginate=false`,
    authenticated: true,
    autoFetch: true,
  });

  const { data: institutionData, loading: isLoadingInstitutionData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryInstitutionHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/question-library-institution?hasPaginate=false`,
    authenticated: true,
    autoFetch: true,
  });

  function handlePageChange({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const handleCheckboxChange = (questionIdChecked: number) => {
    const findQuestionId = questionsIds?.find(questionId => questionId === questionIdChecked);

    if (!findQuestionId) {
      setQuestionsIds([...questionsIds, questionIdChecked]);
      return;
    }

    const removeQuestion = questionsIds?.filter(questionId => questionId !== questionIdChecked);
    setQuestionsIds(removeQuestion);
  };

  function allCheckedOrUnchecked(ids: number[]) {
    const allChecked = ids.every(id => questionsIds.includes(id));

    if (allChecked) {
      const filteredIds = questionsIds.filter(id => !ids.includes(id));
      setQuestionsIds(filteredIds);
    } else {
      const newIdsSet = new Set(questionsIds);
      ids.forEach(id => newIdsSet.add(id));
      setQuestionsIds(Array.from(newIdsSet));
    }
  }

  const calculateSelectedCount = useCallback(() => {
    setSelectedCount(questionsIds?.length);
  }, [questionsIds?.length]);

  useEffect(() => {
    calculateSelectedCount();
  }, [calculateSelectedCount]);

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    data: { questionsIds },
    url: '/question-library/add-external-question',
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Questões atualizadas com sucesso!',
      callback() {
        setQuestionsIds([]);
        setSelectedCount(0);
        if (currentPage === 1) {
          fetchData();
          return;
        }
        setCurrentPage(1);
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  const response = data?.data;
  const questions = response?.data;
  const pageCount = response?.total / response?.per_page;
  const isLoading = isFetching || isSubmitting;
  const isSearching = !!searchParams;
  const isQuestionsVisible = !isLoading && questions?.length;
  const isNotFoundVisible = !isLoading && !questions?.length && isSearching;
  const isEmpty = !isLoading && !questions?.length && !isSearching;
  const allChecked = questions?.every(question => questionsIds.includes(question.id)) || false;
  const isIndeterminate =
    questionsIds.length > 0 && questions?.some(question => !questionsIds.includes(question.id));

  return (
    <Box as="form" onSubmit={onSubmit}>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
        alignItems={{ base: 'self-start', md: 'center', lg: 'center' }}
        width="full"
      >
        <Stack direction="row" alignItems="center" as={Link} to="/tools/external-question">
          <IoIosArrowBack size={20} />
          <Heading color="#202123" fontSize="24px" fontWeight="600" lineHeight="normal">
            Adicionar questões
          </Heading>
        </Stack>

        <Button
          size="sm"
          hidden={hiddenButton}
          colorScheme="orange"
          type="submit"
          isDisabled={isSubmitting || isEmpty || selectedCount === 0}
          isLoading={isSubmitting}
        >
          Adicionar questões
        </Button>
      </Stack>

      <Box marginY={{ base: '32px', md: '40px', lg: '40px' }}>
        <FilterQuestions
          areaListData={areaData?.data?.data}
          disciplineListData={disciplineData?.data?.data}
          subjectListData={subjectData?.data?.data}
          institutionData={institutionData?.data?.data}
          isLoadingAreaData={isLoadingAreaData}
          isLoadingDisciplineData={isLoadingDisciplineData}
          isLoadingSubjectData={isLoadingSubjectData}
          isLoadingInstitutionData={isLoadingInstitutionData}
          filters={filters}
          setFilters={setFilters}
          questionsLength={questions?.length}
          buttonVariant="outline"
          setCurrentPage={setCurrentPage}
        />
      </Box>

      <Button
        size="sm"
        hidden={!hiddenButton}
        width="full"
        colorScheme="orange"
        type="submit"
        isDisabled={isSubmitting || isEmpty || selectedCount === 0}
        isLoading={isSubmitting}
      >
        Adicionar questões
      </Button>

      {isEmpty && (
        <Text
          color="rgba(0, 0, 0, 0.49)"
          fontSize="14px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
          marginY="30px"
          textAlign="center"
        >
          Não foram encontradas questões para ser adicionadas.
        </Text>
      )}

      {isNotFoundVisible && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.300">
            Nenhuma questão encontrada...
          </Heading>
        </Center>
      )}

      {isQuestionsVisible && (
        <QuestionCardToAdd
          questions={questions}
          allChecked={allChecked}
          allCheckedOrUnchecked={allCheckedOrUnchecked}
          isIndeterminate={isIndeterminate}
          selectedCount={selectedCount}
          handleCheckboxChange={handleCheckboxChange}
          questionsIds={questionsIds}
        />
      )}

      {isLoading && (
        <Stack direction="row" width="full" justifyContent="center" margin={30}>
          <Spinner size="lg" color="orange.500" />
        </Stack>
      )}

      {isQuestionsVisible && (
        <Box marginY={{ base: '15px', md: '40px', lg: '40px' }}>
          <Pagination
            pageCount={pageCount}
            onPageActive={currentPage - 1}
            onPageChange={handlePageChange}
          />
        </Box>
      )}
    </Box>
  );
}
