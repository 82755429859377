import { Container, Heading, Text, VStack } from '@chakra-ui/react';
import { FcEditImage } from 'react-icons/fc';

export default function ModuleNotSelected() {
  return (
    <Container maxW="container.md">
      <VStack spacing="20px">
        <FcEditImage size="120px" />
        <Heading as="h3" size="lg" fontWeight="400">
          Vamos construir seu produto!
        </Heading>
        <Text fontSize="md">
          Aqui é onde você irá cadastrar o produto, criando todos os módulos, adicionando o conteúdo
          que irá disponibilizar em cada módulo, cadastrando as áreas e disciplinas a que ele
          pertence e definindo a duração de acesso e também o preço! Clique no botão "Adicionar
          Módulo" à esquerda para começar.
        </Text>
      </VStack>
    </Container>
  );
}
