import { Box, Image, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { GoArrowRight } from 'react-icons/go';
import { HiDotsHorizontal } from 'react-icons/hi';
import { MdDragIndicator, MdOutlineImageNotSupported } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { IPlan, IShowcase } from '../../..';
import { StatusSelect } from '../StatusSelect';

export function getStatusColorByName(status: string) {
  switch (status) {
    case 'ATIVO':
      return '#22BB33';
    case 'INATIVO':
      return '#BB2124';
    case 'EM_EDICAO':
      return '#E8C536';
    default:
      return '#202123';
  }
}

interface ShowCasePlanListItemProps {
  plans: IPlan[];
  showcase: IShowcase;
  handleOnDragEnd: (result: DropResult) => void;
  setShowcaseItemId: (id: number) => void;
  onAddBannerClick(showcaseItemId: number): void;
  removeBanner(showcaseCourseId: number): void;
  editModules(productId: number, showcaseId: number, showcaseCourseId: number): void;
  deleteProduct(showcaseCourseId: number, showcase: IShowcase): void;
  onStatusChange: (
    event: ChangeEvent<HTMLSelectElement>,
    id: number,
    showcaseType: IShowcase['showcaseType']
  ) => void;
}

export function ShowCasePlanListItem({
  plans,
  showcase,
  handleOnDragEnd,
  setShowcaseItemId,
  onStatusChange,
  onAddBannerClick,
  deleteProduct,
}: ShowCasePlanListItemProps) {
  const { push } = useHistory();

  function handleEditPlanClick(planId: number) {
    push(
      `/members-area-management/netflix/showcase-higlight-type/${showcase.showcaseId}/edit/${planId}`
    );
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="showcase-plans">
        {provided => (
          <Box hidden={!plans.length} {...provided.droppableProps} ref={provided.innerRef}>
            {plans?.map((plan, index) => {
              const bgColor = index % 2 === 0 ? '#f4f4f4' : '#ffffff';

              return (
                <Draggable
                  key={String(plan.showcasePlanId)}
                  draggableId={String(plan.showcasePlanId)}
                  index={index}
                  isDragDisabled={plans?.length < 2}
                >
                  {provided => (
                    <Box
                      padding={{ base: '24px 20px 24px 20px', lg: '24px 30px 24px 30px' }}
                      height="67px"
                      display="flex"
                      gap={{ base: '10px', lg: '30px' }}
                      bgColor={bgColor}
                      alignItems="center"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onMouseEnter={() => setShowcaseItemId(plan.showcasePlanId)}
                    >
                      <Box flexShrink={0}>
                        <MdDragIndicator
                          fontSize="24px"
                          color={plans.length <= 1 ? 'transparent' : '#202123'}
                          style={{ flexShrink: 0 }}
                        />
                      </Box>

                      <Box
                        hidden={!!plan.imageDesktop || !!plan.imageMobile}
                        width="49px"
                        height="62px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="#D9D9D9"
                        flexShrink={0}
                      >
                        <MdOutlineImageNotSupported color="#757475" fontSize="20px" />
                      </Box>

                      {plan.imageDesktop && (
                        <Image
                          width="49px"
                          height="62px"
                          alt="Banner"
                          src={plan.imageDesktop}
                          objectFit="cover"
                        />
                      )}

                      <Stack width="full" alignItems="flex-start" spacing={0} textAlign="start">
                        <Text
                          width={{ base: '150px', lg: 'full' }}
                          isTruncated
                          fontSize="14px"
                          fontWeight={{ base: 'semibold', xl: 'medium' }}
                        >
                          {plan.recurrencePlanName}
                        </Text>

                        <Box display={{ base: 'block', md: 'none' }}>
                          <StatusSelect
                            showcaseItemId={plan.showcasePlanId}
                            value={plan.statusName}
                            onChange={onStatusChange}
                            showcaseType="plans"
                          />
                        </Box>
                      </Stack>

                      <Box display={{ base: 'none', md: 'block' }} mr="3.25rem">
                        <StatusSelect
                          showcaseItemId={plan.showcasePlanId}
                          value={plan.statusName}
                          onChange={onStatusChange}
                          showcaseType="plans"
                        />
                      </Box>

                      <Box>
                        <Menu>
                          <MenuButton
                            w="27px"
                            h="24px"
                            borderRadius={5}
                            bgColor="#20212312"
                            _hover={{ bgColor: '#20212330' }}
                            _active={{ bgColor: '#20212330' }}
                          >
                            <Stack justifyContent="center" alignItems="center">
                              <HiDotsHorizontal size="18px" color="#00000080" />
                            </Stack>
                          </MenuButton>
                          <MenuList>
                            {showcase.viewType === 'standard' && (
                              <MenuItem
                                color="#979CA6"
                                fontWeight={500}
                                onClick={() => onAddBannerClick(plan.showcasePlanId)}
                              >
                                Adicionar banner
                              </MenuItem>
                            )}

                            {showcase.viewType === 'highlight' && (
                              <MenuItem
                                color="#979CA6"
                                fontWeight={500}
                                onClick={() => handleEditPlanClick(plan.showcasePlanId)}
                              >
                                Editar plano
                              </MenuItem>
                            )}

                            <MenuItem
                              color="#BB2124"
                              fontWeight={500}
                              onClick={() => deleteProduct(plan.showcasePlanId, showcase)}
                            >
                              Excluir
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Box>

                      <Box
                        visibility={showcase.viewType !== 'highlight' ? 'hidden' : 'initial'}
                        flexShrink={0}
                      >
                        <GoArrowRight
                          fontSize="24px"
                          color="#20212380"
                          cursor="pointer"
                          onClick={() => handleEditPlanClick(plan.showcasePlanId)}
                        />
                      </Box>
                    </Box>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}
