import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  CardBody,
  CardFooter,
  Center,
  Checkbox,
  HStack,
  IconButton,
  Text,
  Textarea,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MdClose, MdDragIndicator } from 'react-icons/md';
import Editor from '../../../../../../../components/Editor';
import EditorRichText from '../../../../../../../components/EditorRichText';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import useHandleSubmit from '../../../../../../../hooks/useHandleSubmit';
import QuestionService from '../../../services/QuestionService';
import Question, { Option } from '../../../types/Question';

const addOptionPlaceholder = {
  id: 0,
  correct: false,
  optionText: 'Adicionar Item',
};

interface QuestionFormProps {
  question: Question;
  isLoading: boolean;
  onUpdate: () => void;
  onQuestionBeingUpdatedChange: (question: Partial<Question>) => void;
  onQuestionsListChange: (question: Question) => void;
}

export default function QuestionForm({
  question,
  isLoading,
  onUpdate,
  onQuestionBeingUpdatedChange,
  onQuestionsListChange,
}: QuestionFormProps) {
  const [questionText, setQuestionText] = useState(question.questionText);
  const [commentary, setCommentary] = useState(question.commentary || '');
  const [optionBeingUpdated, setOptionBeingUpdated] = useState<Partial<Option> | null>(null);
  const [optionBeingDeleted, setOptionBeingDeleted] = useState<Option['id'] | null>(null);
  const [options, setOptions] = useState<Partial<Option>[]>(question.options);
  const [previousOptions, setPreviousOptions] = useState<Partial<Option>[]>(question.options);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const toast = useToast();

  const questionId = question?.id;

  useEffect(() => {
    onQuestionBeingUpdatedChange({
      questionText,
      commentary,
    });
  }, [commentary, onQuestionBeingUpdatedChange, questionText]);

  const hasSomeOptionCorrect = options?.some(option => option?.correct);

  const createOptionPayload = {
    correct: hasSomeOptionCorrect ? false : true,
    optionText: `Item ${options?.length + 1}`,
  };

  const { handleSubmit: createOption } = useHandleSubmit({
    data: createOptionPayload,
    method: 'post',
    url: `/question-library/${questionId}/question/option`,
    authenticated: true,
    onSuccess: {
      callback: async (response: any) => {
        const createdOption = response.data.data as Option;

        setOptions(prevState => {
          const optionWithIdZero = prevState?.find(option => option?.id === 0);
          const previousOptions = prevState?.filter(option => option?.id !== 0);

          return [
            ...previousOptions,
            createdOption,
            ...(optionWithIdZero ? [optionWithIdZero] : []),
          ];
        });

        setOptionBeingUpdated(createdOption);

        onQuestionsListChange({
          ...question,
          options: [...question.options, createdOption],
        });

        setIsSaveButtonDisabled(false);
      },
    },
  });

  const updateOptionPayload = {
    optionText: optionBeingUpdated?.optionText,
    correct: optionBeingUpdated?.correct,
  };

  const { isLoading: isUpdating, handleSubmit: updateOption } = useHandleSubmit({
    data: updateOptionPayload,
    method: 'patch',
    url: `/question-library/${questionId}/question/${optionBeingUpdated?.id}/option`,
    authenticated: true,
    onSuccess: {
      callback: () => {
        setIsSaveButtonDisabled(false);
      },
    },
  });

  async function handleUpdateOptionsOrder(options: Option[]) {
    const payload = {
      optionOrders: options?.map((option, index) => ({ optionId: option?.id, option: index })),
    };

    try {
      await QuestionService.updateOptionsOrder({ questionId, payload });
    } catch (error) {
      setOptions(previousOptions);

      const errorMessage = ErrorResponse(error);

      toast({
        title: 'Erro ao atualizar ordem dos itens',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const { isLoading: isDeleting, handleSubmit: deleteOption } = useHandleSubmit({
    method: 'delete',
    url: `/question-library/${questionId}/question/${optionBeingDeleted}/option`,
    authenticated: true,
    onSuccess: {
      callback: () => {
        const updatedOptions = options?.filter(option => option?.id !== optionBeingDeleted);

        onQuestionsListChange({
          ...question,
          options: updatedOptions,
        });

        setOptions(prevState => prevState?.filter(option => option?.id !== optionBeingDeleted));

        onQuestionBeingUpdatedChange({
          options: updatedOptions,
        });

        setIsSaveButtonDisabled(false);
      },
    },
  });

  useEffect(() => {
    (async () => {
      if (optionBeingDeleted) {
        await deleteOption();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionBeingDeleted]);

  useEffect(() => {
    if (!optionBeingUpdated?.optionText) return;

    const previousOptions = options?.filter(option => option?.id !== 0);

    const updatedOptions = previousOptions.map(option =>
      option?.id === optionBeingUpdated?.id ? optionBeingUpdated : option
    );

    onQuestionBeingUpdatedChange({
      options: updatedOptions,
    });

    onQuestionsListChange({
      ...question,
      options: updatedOptions,
    });

    setOptions(updatedOptions);

    const timer = setTimeout(updateOption, 500);

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionBeingUpdated?.optionText, optionBeingUpdated?.correct]);

  function handleTextAreaFocus(event) {
    event.target.select();
  }

  async function handleTextAreaKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
      await createOption();
    }
  }

  function handleOptionTextChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = event?.target;

    setOptionBeingUpdated(prevState => ({
      ...prevState,
      optionText: value,
    }));

    setOptions(prevState => {
      const optionIndex = prevState?.findIndex(option => option?.id === optionBeingUpdated?.id);
      const updatedOption = {
        ...prevState[optionIndex],
        optionText: value,
      };
      return [
        ...prevState.slice(0, optionIndex),
        updatedOption,
        ...prevState.slice(optionIndex + 1),
      ];
    });
  }

  function handleMarkOptionAsCorrect(option: Partial<Option>) {
    setIsSaveButtonDisabled(true);
    setOptionBeingUpdated(null);

    setOptionBeingUpdated({
      ...option,
      correct: !option.correct,
    });
  }

  async function handleOptionClick(option: Partial<Option>) {
    setOptionBeingUpdated(option);

    if (!option.id) {
      await createOption();

      onQuestionBeingUpdatedChange({
        options: [...options, optionBeingUpdated],
      });
    }
  }

  function handleQuestionTextChange(value: string) {
    if (!value) return;
    setQuestionText(value);
    onQuestionBeingUpdatedChange({
      questionText: value,
    });
  }

  function handleCommentaryChange(value: string) {
    if (value) {
      setCommentary(value);
      onQuestionBeingUpdatedChange({
        commentary: value,
      });
    }
  }

  async function onDragEnd(result) {
    if (!result.destination) return;

    setPreviousOptions(options);

    const newOptions = [...options];
    const [removed] = newOptions.splice(result.source.index, 1);
    newOptions.splice(result.destination.index, 0, removed);

    setOptions(newOptions);

    await handleUpdateOptionsOrder(newOptions);
  }

  const isDisabled = isLoading || isSaveButtonDisabled || isUpdating || isDeleting;

  return (
    <>
      <CardBody
        pt={2.5}
        sx={{
          '.ql-toolbar': {
            borderRadius: '6px 6px 0px 0px',
            borderColor: '#D2D2D2 !important',
          },
          '.ql-container': {
            borderRadius: '0px 0px 6px 6px',
            borderColor: '#D2D2D2 !important',
          },
        }}
      >
        <EditorRichText value={questionText} onChange={handleQuestionTextChange} />

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <VStack
                w="full"
                align="flex-start"
                spacing={2.5}
                mt={5}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Text color="#20212380">Marque pelo menos um item como correto.</Text>

                {options.map((option, index) => {
                  return (
                    <Draggable
                      key={option?.id}
                      draggableId={String(option?.id)}
                      index={index}
                      isDragDisabled={options.length < 2}
                    >
                      {provided => (
                        <HStack
                          w="full"
                          key={option?.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          spacing={0}
                          onMouseEnter={() => setHoveredIndex(index)}
                          onMouseLeave={() => setHoveredIndex(null)}
                        >
                          <Box {...provided.dragHandleProps} ml="-18px" w="18px" cursor="move">
                            {hoveredIndex === index && (
                              <MdDragIndicator color="#20212380" size="1.25rem" />
                            )}
                          </Box>

                          <HStack align="center" onClick={() => handleOptionClick(option)} w="full">
                            <Tooltip
                              hasArrow
                              placement="top"
                              label={
                                !option.correct ? 'Marcar como correta' : 'Desmarcar como correta'
                              }
                              fontSize="xs"
                            >
                              <Center>
                                <Checkbox
                                  onChange={() => handleMarkOptionAsCorrect(option)}
                                  isChecked={option?.correct}
                                  size="md"
                                  colorScheme="default"
                                  isDisabled={isDisabled}
                                />
                              </Center>
                            </Tooltip>

                            {optionBeingUpdated?.id === option?.id ? (
                              <Textarea
                                value={optionBeingUpdated?.optionText}
                                resize="none"
                                variant="flushed"
                                focusBorderColor="default.500"
                                rows={1}
                                size="sm"
                                autoFocus
                                onChange={handleOptionTextChange}
                                onFocus={handleTextAreaFocus}
                                onKeyDown={handleTextAreaKeyDown}
                                isDisabled={isDisabled}
                              />
                            ) : (
                              <Text
                                w="full"
                                color={option?.id ? '#202123' : '#AFAFAF'}
                                cursor="text"
                              >
                                {option?.optionText}
                              </Text>
                            )}
                          </HStack>

                          <HStack align="center" spacing={0}>
                            <Tooltip hasArrow placement="top" label="Excluir Item" fontSize="xs">
                              <IconButton
                                onClick={() => setOptionBeingDeleted(option?.id)}
                                m={0}
                                variant="ghost"
                                aria-label="Excluir Item"
                                icon={<MdClose size="1.25rem" color="#AFAFAF" />}
                                size="xs"
                                isDisabled={isDisabled}
                              />
                            </Tooltip>
                          </HStack>
                        </HStack>
                      )}
                    </Draggable>
                  );
                })}

                {provided.placeholder}

                <HStack w="full">
                  <HStack onClick={() => handleOptionClick(addOptionPlaceholder)} w="full">
                    <Center>
                      <Checkbox size="md" colorScheme="default" pointerEvents="none" />
                    </Center>

                    <Text w="full" color="#AFAFAF" cursor="text">
                      {addOptionPlaceholder?.optionText}
                    </Text>
                  </HStack>
                </HStack>
              </VStack>
            )}
          </Droppable>
        </DragDropContext>

        <Accordion w="full" allowToggle pb={2} px={0} border="none" mt={5}>
          <AccordionItem border="none" w="full">
            <h2>
              <AccordionButton
                _focus={{ boxShadow: 'none', outline: 'none' }}
                _hover={{ bg: 'transparent' }}
                border="none"
                p={0}
                color="#20212380"
                fontSize="sm"
                fontWeight="medium"
                lineHeight="20px"
              >
                <Box as="span" flex="1" textAlign="left">
                  Comentário da questão
                  <AccordionIcon />
                </Box>
              </AccordionButton>
            </h2>

            <AccordionPanel px={0} py={5}>
              <Editor value={commentary} onChange={handleCommentaryChange} hasVideoLibrary />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </CardBody>

      <CardFooter>
        <HStack w="full" justify="flex-end">
          <Button
            onClick={onUpdate}
            isLoading={isSaveButtonDisabled || isDisabled}
            colorScheme="default"
            size="sm"
          >
            Salvar
          </Button>
        </HStack>
      </CardFooter>
    </>
  );
}
