import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
} from '@chakra-ui/react';
import { FormEvent } from 'react';

interface ModalAddOrEditSubjectProps {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
  disciplineId: number;
  setDisciplineId: (value: number) => void;
  subjectName: string;
  setSubjectName: (value: string) => void;
  isSubmitting: boolean;
  isLoading: boolean;
  disciplineData: { id: number; name: string }[];
  onSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  formValidation?: {
    name: {
      isInvalid: boolean;
      message: string;
    };
    disciplineId: {
      isInvalid: boolean;
      message: string;
    };
  };
}

export function ModalAddOrEditSubject({
  id,
  isOpen,
  onClose,
  disciplineId,
  setDisciplineId,
  subjectName,
  setSubjectName,
  isSubmitting,
  onSubmit,
  formValidation,
  isLoading,
  disciplineData,
}: ModalAddOrEditSubjectProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setDisciplineId(0);
        setSubjectName('');
        onClose();
      }}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader fontWeight={700} fontSize="18px">
          {id ? 'Editar assunto' : 'Adicionar um assunto'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody marginX={1}>
          <Stack spacing={2}>
            <FormControl isInvalid={formValidation?.disciplineId?.isInvalid}>
              <FormLabel fontWeight={600} fontSize="14px">
                Disciplina
              </FormLabel>
              <FormHelperText fontSize="14px">
                Selecione uma disciplina que seu assunto será vinculado
              </FormHelperText>
              <Select
                placeholder="Selecione uma disciplina"
                size="sm"
                name="disciplineId"
                borderRadius={6}
                focusBorderColor="orange.500"
                color="#20212380"
                isDisabled={isLoading}
                value={disciplineId}
                onChange={event => setDisciplineId(Number(event.target.value))}
              >
                {disciplineData?.map(discipline => (
                  <option key={discipline.id} value={discipline.id}>
                    {discipline.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {formValidation?.disciplineId?.isInvalid && formValidation?.disciplineId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.name?.isInvalid}>
              <FormLabel fontWeight={600} fontSize="14px">
                Nome da assunto
              </FormLabel>
              <Input
                placeholder="Digite aqui"
                size="sm"
                name="name"
                borderRadius={6}
                focusBorderColor="orange.500"
                color="#20212380"
                isDisabled={isLoading}
                value={subjectName}
                maxLength={50}
                onChange={event => setSubjectName(event.target.value)}
              />
              <FormErrorMessage>
                {formValidation?.name?.isInvalid && formValidation?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            size="sm"
            mr={3}
            onClick={() => {
              setDisciplineId(0);
              setSubjectName('');
              onClose();
            }}
          >
            Cancelar
          </Button>
          <Button
            colorScheme="orange"
            size="sm"
            type="submit"
            isDisabled={isSubmitting}
            isLoading={isLoading || isSubmitting}
          >
            {id ? 'Salvar' : 'Adicionar'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
