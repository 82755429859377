import { Icon, IconProps } from '@chakra-ui/react';

export default function CheckIcon({ ...props }: IconProps) {
  return (
    <Icon viewBox="0 0 17 14" {...props}>
      <path
        d="M6.99056 8.16861C6.21415 8.91199 4.989 8.90871 4.21657 8.1612L3.65976 7.62235C3.00864 6.99223 1.9791 6.97923 1.31227 7.59271C0.596535 8.25119 0.57317 9.37317 1.26088 10.0609L4.18579 12.9858C4.96684 13.7668 6.23317 13.7668 7.01421 12.9858L16.2742 3.72576C16.9709 3.02908 16.8817 1.87509 16.0862 1.29378C15.4578 0.83453 14.5905 0.892109 14.0282 1.4304L6.99056 8.16861Z"
        fill="currentColor"
      />
    </Icon>
  );
}
