import { AspectRatio, Box, Flex, Heading, Text, Button, Link } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { scrollbarStyle } from '..';
import CourseAPI from '../../../../../api/Course';
import Toast from '../../../../../components/Toast';
import { useStudentCourse } from '../../../../../contexts/CourseStudentContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import path from 'path';
import { HiDownload as DownloadIcon } from 'react-icons/hi';
import { TOKEN_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import { baseURLBackend } from '../../../../../api/Configuration';

function ViewMultimedia() {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const { studentCourse, getContent } = useStudentCourse();
  const [content, setContent] = useState();

  const { courseId, moduleId, contentId } = useParams();

  useEffect(() => {
    if (studentCourse) {
      const currentContent = getContent(studentCourse, moduleId, contentId);
      if (currentContent) {
        setContent(currentContent);
      }
    }
  }, [contentId, getContent, moduleId, studentCourse]);

  const updateLastVisit = useCallback(async () => {
    try {
      await CourseAPI.updateLastVisit(courseId, moduleId, contentId, 'MULTIMIDIA');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }, [contentId, courseId, moduleId]);

  useEffect(() => {
    if (role === 'ALUNO' && studentCourse) {
      updateLastVisit();
    }
  }, [updateLastVisit, role, studentCourse]);

  return (
    <Box
      w="full"
      overflow="auto"
      sx={scrollbarStyle}
      margin={{ base: '0', md: '20px 70px', lg: '20px 70px' }}
    >
      <Box px={2}>
        <Heading size="xl" mb={5}>
          {content && content.title}
        </Heading>
        <AspectRatio ratio={16 / 9}>
          <iframe
            src={content && content.link}
            title={'content.title'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </AspectRatio>

        {content && (
          <Box
            my={5}
            className="ql-editor"
            dangerouslySetInnerHTML={{ __html: content.content }}
            sx={{ iframe: { width: '100%', aspectRatio: '16/9' } }}
          />
        )}

        {content?.download && (
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            my={5}
            px={{ base: 3, lg: 2 }}
            py={{ base: 2, lg: 2 }}
            gap={2}
            h="auto"
            border="2px"
            borderColor="#8091A7"
            borderRadius={10}
            alignItems={{ base: 'center' }}
            justifyContent="space-between"
          >
            <Box>
              <Text fontSize={{ base: 'smaller', sm: 'md' }} noOfLines={1}>
                {path.basename(decodeURI(content?.download)).slice(0, 56)}
              </Text>
            </Box>

            <Flex>
              <Button
                rightIcon={<DownloadIcon />}
                colorScheme="primary"
                size="sm"
                variant="outline"
                width="100%"
              >
                <Link
                  _hover={{ textDecoration: 'none' }}
                  href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${localStorage.getItem(
                    TOKEN_LOCAL_STORAGE
                  )}`}
                  download={true}
                >
                  Fazer o Download
                </Link>
              </Button>
            </Flex>
          </Flex>
        )}
      </Box>
    </Box>
  );
}

export default ViewMultimedia;
