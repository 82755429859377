import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { IoDuplicate } from 'react-icons/io5';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isDisabled: boolean;
  onSubmit: () => void;
}

export default function DuplicateCourseModal({ isOpen, onClose, isDisabled, onSubmit }: Props) {
  return (
    <Modal isCentered size="sm" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="0%" backdropBlur="2px" />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>
          <HStack width="full" justifyContent="center" padding="5px" color="primary.500">
            <IoDuplicate size="5rem" />
          </HStack>
        </ModalHeader>

        <ModalBody>
          <Text fontSize="lg" textAlign="center" fontWeight="500">
            Deseja duplicar esse curso?
          </Text>
        </ModalBody>

        <ModalFooter>
          <HStack width="full" justifyContent="center">
            <Button width="full" size="sm" isDisabled={isDisabled} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              width="full"
              size="sm"
              colorScheme="primary"
              color="secondary.500"
              isDisabled={isDisabled}
              onClick={onSubmit}
            >
              Sim
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
