import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Link as ChakraLink,
  Flex,
  Text,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import IconContainer from '../../Icons/IconContainer';
import SubmenuItem, { ISubmenuItemProps } from './SubmenuItem';

export interface IMenuItemProps {
  key?: string;
  name: string;
  icon?: string | React.ReactNode;
  path?: string;
  href?: string;
  submenuItems?: ISubmenuItemProps[];
}

function SubitemList(props: { submenuItems?: ISubmenuItemProps[] }) {
  const { submenuItems } = props;
  if (submenuItems?.length) {
    return (
      <>
        {submenuItems.map(function (submenuItem, index) {
          return <SubmenuItem key={`${submenuItem.name}${index}`} {...submenuItem} />;
        })}
      </>
    );
  }
  return null;
}

export default function MenuItem(props: IMenuItemProps) {
  const { name, path, href, submenuItems, icon } = props;
  const { pathname } = useLocation();

  const isDisabled = !path && !href && !submenuItems?.length;
  const isActive = pathname.includes(path);

  return (
    <AccordionItem
      border="none"
      isDisabled={isDisabled}
      color="secondary.500"
      _hover={{ filter: 'none', bg: 'transparent' }}
    >
      <AccordionButton
        as={path ? Link : ChakraLink}
        display="flex"
        justifyContent="space-between"
        opacity={isActive ? 1 : 0.64}
        bg={isActive ? 'blackAlpha.50' : null}
        pl={{ base: 0, lg: 7 }}
        py={4}
        _focus={{ outline: 'none' }}
        _hover={{ opacity: 1, textDecoration: 'none' }}
        _expanded={{ opacity: 1, bg: 'blackAlpha.50' }}
        {...(path && { to: path })}
        {...(href && { href, isExternal: true })}
      >
        <Flex gap="18px" align="center" textAlign="left">
          {icon && <IconContainer name={icon} />}
          <Text as="span" fontSize="sm" fontWeight="semibold">
            {name}
          </Text>
        </Flex>
        <AccordionIcon display={submenuItems?.length ? 'flex' : 'none'} />
      </AccordionButton>
      <SubitemList submenuItems={submenuItems} />
    </AccordionItem>
  );
}
