import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react';

interface IButtonProps extends ButtonProps {}

function Button({ ...rest }: IButtonProps) {
  return (
    <ChakraButton
      minWidth="11rem"
      fontSize="md"
      fontWeight="medium"
      colorScheme="primary"
      borderRadius="md"
      {...rest}
    />
  );
}

export default Button;
