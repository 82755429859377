import { axiosBackend } from '../api/Configuration';

export async function exportLeads({ segmentationId, segmentationName }) {
  const urlDownload = `/segmentation/${segmentationId}/segmentation/export-lead`;

  const axios = axiosBackend();

  const response = await axios({
    url: urlDownload,
    method: 'GET',
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${segmentationName}.xlsx`);
  document.body.appendChild(link);
  link.click();
}
