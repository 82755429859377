import { memo } from 'react';

import { useToggle } from '../../../../../../hooks/useToggle';

import { Container, Column, DefaultImage } from './styles';
import FeedbackModal from '../FeedbackModal';

function TaskItem({ task, fetchTasks }) {
  const [isShowModal, setIsShowModal] = useToggle(false);

  return (
    <>
      <Container status={task.status}>
        <Column>
          {task.photoStudent ? (
            <img
              src={task.photoStudent}
              alt="Avatar do usuário"
              style={{ height: 'auto', width: '80px', objectFit: 'cover' }}
            />
          ) : (
            <DefaultImage status={task.status}>
              {task.studentName.substr(0, 1).toUpperCase()}
            </DefaultImage>
          )}
          <span className="fullName">{task.studentName}</span>
        </Column>
        <Column>
          <span>{task.moduleName}</span>
        </Column>
        <Column>
          <span>{task.title}</span>
        </Column>
        <Column>
          {task.status === 'ATIVO' ? (
            <span className="corrected">Corrigida</span>
          ) : (
            <span className="pending">Pendente</span>
          )}
        </Column>
        <Column>
          {task.status === 'ATIVO' ? (
            <button onClick={setIsShowModal}>Editar</button>
          ) : (
            <button onClick={setIsShowModal}>Corrigir</button>
          )}
        </Column>
      </Container>

      <FeedbackModal
        show={isShowModal}
        onHide={setIsShowModal}
        task={task}
        fetchTasks={fetchTasks}
      />
    </>
  );
}

export default memo(TaskItem);
