interface IDataLog {
  identifier: string;
  hostname: string;
  pathname: string;
  href: string;
  method: string;
  statusCode: number;
  statusText: string;
  configData: any;
  message: string;
  url: string;
  startTime: number;
  endTime: number;
  duration: number;
  timeout: number;
  data: any;
  headers: any;
  browser: string;
  appVersion: string;
}

export default class LogRequest {
  private static _instance = null;
  private logsSent: IDataLog[];

  private constructor() {
    this.logsSent = [];
  }

  static get instance() {
    if (!this._instance) {
      //@ts-ignore
      this._instance = new LogRequest();
    }

    return this._instance;
  }

  send(data: IDataLog) {
    const logExists = this.logsSent.find(log => log.identifier === data.identifier);

    if (logExists && logExists.startTime >= data.startTime && logExists.endTime <= data.endTime) {
      return;
    }

    this.logsSent.push(data);

    return data;
  }

  remove(identifier: string) {
    this.logsSent = this.logsSent.filter(log => log.identifier !== identifier);
  }
}
