import { HStack, Text } from '@chakra-ui/react';
import { IoMdAlarm as AlarmIcon } from 'react-icons/io';
import { MdHighlightOff as CloseIcon } from 'react-icons/md';

export type Status = 'waiting_payment' | 'paid' | 'expired' | 'default';

function getMessageByStatus(status: Status) {
  const alert = {
    waiting_payment: (
      <Text>
        O Qr Code expira <Text as="strong">em 20 minutos.</Text>
      </Text>
    ),
    expired: <Text>Código do pix expirado. Gere um novo código para continuar.'</Text>,
  };

  return alert[status];
}

function getIconToShow(status: Status) {
  const alert = {
    waiting_payment: <AlarmIcon />,
    paid: <CloseIcon />,
    expired: <CloseIcon />,
  };

  return alert[status];
}

function getStyleByStatus(status: Status) {
  const alert = {
    waiting_payment: { bg: '#f5b88b', px: 3, py: 4, borderColor: '#EC7117', fontSize: 'sm' },
    expired: {
      bg: '#BB212480',
      px: 1,
      py: 1,
      borderColor: '#BB2124BF',
      fontSize: 'xs',
    },
  };

  return alert[status];
}

function AlertMessage({ status }: { status: Status }) {
  return (
    <HStack
      w="full"
      spacing={3}
      fontWeight="medium"
      lineHeight="1rem"
      color="#202123"
      borderRadius="md"
      borderWidth={1}
      sx={{ svg: { width: '23px', flexShrink: 0 } }}
      {...getStyleByStatus(status)}
    >
      {getIconToShow(status)}
      {getMessageByStatus(status)}
    </HStack>
  );
}

export default AlertMessage;
