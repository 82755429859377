import { Heading } from '../../../../components/Heading';

import Section from '../Section';
import styles from './styles.module.css';

function Faq() {
  return (
    <Section background="#f6f5f5">
      <div className={styles.productsContainer}>
        <div className={styles.sectionHeader}>
          <Heading as="h4" fontSize="2xl">
            Perguntas frequentes
          </Heading>

          <p className={styles.headerText}>
            Insira as principais dúvidas apresentadas por seus alunos. Essa seção ajudará a
            solucionar dúvidas frequentes de seus alunos.
          </p>
        </div>

        <button disabled>Em breve...</button>
      </div>
    </Section>
  );
}

export default Faq;
