import { CheckIcon } from '@chakra-ui/icons';
import { CloseButton, HStack, ListItem, Stack, Text, VStack } from '@chakra-ui/react';
import prettyBytes from 'pretty-bytes';
import { memo } from 'react';
import { ActionType, ListItemFileType } from '../..';

type Params = {
  listItemFile: ListItemFileType;
  dispatch: (action: ActionType) => void;
};

function ListItemFile({ listItemFile, dispatch }: Params) {
  return listItemFile?.file ? (
    <ListItem>
      <HStack width="full" backgroundColor="#FFFFFF" padding={2} borderRadius={6}>
        <HStack width="full" justifyContent="space-between">
          <VStack width="80%" spacing={0} alignItems="self-start">
            <Text fontSize="14px" fontWeight={500} noOfLines={1}>
              {listItemFile.file.name}
            </Text>
            <HStack>
              <Text fontSize="12px" fontWeight={400}>
                {listItemFile.file.size ? prettyBytes(listItemFile.file.size) : 0}
              </Text>
              <Text
                hidden={!listItemFile?.upload.fail.isError}
                color="red"
                textAlign="center"
                fontWeight="bold"
              >
                Falha no upload
              </Text>
            </HStack>
          </VStack>

          <Text fontSize="14px" fontWeight={600} noOfLines={1}>
            <Text as="span" fontSize="14px" color="orange.500" fontWeight={600} noOfLines={1}>
              {listItemFile.upload.progress > 0 ? `${listItemFile.upload.progress}%` : ''}
            </Text>
            {listItemFile.upload.isComplete ? <CheckIcon fontSize="20px" color="green.500" /> : ''}
          </Text>

          <Stack>
            <CloseButton
              hidden={listItemFile.upload.isComplete || listItemFile.upload.inProgress}
              size="sm"
              color="#20212380"
              onClick={() => dispatch({ listItemFile, type: 'remove' })}
            />
          </Stack>
        </HStack>
      </HStack>
    </ListItem>
  ) : (
    <ListItem></ListItem>
  );
}

export default memo(ListItemFile);
