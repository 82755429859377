import { Button, ButtonProps, Tooltip, useClipboard } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdOutlineContentCopy } from 'react-icons/md';

interface CopyButtonProps extends ButtonProps {
  textToCopy: string;
}

export default function CopyButton({ textToCopy, ...rest }: CopyButtonProps) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const { onCopy } = useClipboard(textToCopy);

  // Fecha o tooltip após 3 segundos  `
  useEffect(() => {
    if (isTooltipOpen) {
      const timeout = setTimeout(() => {
        setIsTooltipOpen(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [isTooltipOpen]);

  function handleClick() {
    onCopy();
    setIsTooltipOpen(true);
  }

  return (
    <Tooltip
      label="Link copiado"
      placement="top"
      hasArrow
      isOpen={isTooltipOpen}
      closeOnPointerDown
    >
      <Button
        {...rest}
        onClick={handleClick}
        variant="ghost"
        leftIcon={<MdOutlineContentCopy size="1.25rem" />}
        borderColor="transparent"
        color="#202123"
        fontWeight="normal"
        bg="#e9e9e9"
        _hover={{ bg: 'transparent' }}
        _disabled={{ bg: '#e9e9e9', cursor: 'not-allowed' }}
      >
        Copiar link
      </Button>
    </Tooltip>
  );
}
