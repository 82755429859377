import { Box, Center, Flex, Heading, HStack, IconButton, Spinner } from '@chakra-ui/react';
import { MdChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import { WebhookView } from '../types';
import WebhookProductsForm from '../WebhookProductsForm';
import WebhookRecurrenceForm from '../WebhookRecurrenceForm';
import { webhookSchemaValidator } from '../schemaValidator';

export default function EditWebhook() {
  const { id: webhookId } = useParams<{ id: string }>();
  const history = useHistory();

  const url = `/webhook-proluno/${webhookId}/webhook`;

  const { data: response, loading: isLoading } = useFetch<UnificadaFront.ResponseJSON<WebhookView>>(
    {
      method: 'get',
      url,
      authenticated: true,
      autoFetch: true,
    }
  );

  const webhook = response?.data;
  const webhookType = webhook?.webhookType;
  const isProductWebhook = webhookType === 'product';
  const isRecurrencePlanWebhook = webhookType === 'recurrence_plan';
  const isFormVisible = !isLoading && webhook;

  return (
    <Box>
      <Flex justify="space-between" w="full" wrap="wrap">
        <HStack spacing={{ base: 0.25, xl: 0.5 }} ml={{ base: -2, xl: 0 }}>
          <IconButton
            onClick={() => history.goBack()}
            icon={<ChevronLeftIcon size="1.75rem" />}
            aria-label="Voltar"
            variant="ghost"
            colorScheme="gray"
            size="sm"
          />

          <Heading as="h1" fontSize={{ base: 'lg', lg: '2xl' }} fontWeight="semibold" m={0}>
            Editar Webhook
          </Heading>
        </HStack>
      </Flex>

      {isLoading && (
        <Center my={20}>
          <Spinner size="xl" color="primary.500" />
        </Center>
      )}

      {isFormVisible && isProductWebhook && (
        <WebhookProductsForm
          data={webhook}
          url={url}
          method="patch"
          successMessage="Webhook atualizado com sucesso!"
          schemaValidator={webhookSchemaValidator}
          mt={10}
        />
      )}

      {isFormVisible && isRecurrencePlanWebhook && (
        <WebhookRecurrenceForm
          data={webhook}
          url={url}
          method="patch"
          successMessage="Webhook atualizado com sucesso!"
          schemaValidator={webhookSchemaValidator}
          mt={10}
        />
      )}
    </Box>
  );
}
