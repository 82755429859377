import { theme as base, extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  useSystemColorMode: false,
  initialColorMode: 'light',

  components: {
    Button: {
      baseStyle: {
        _focus: { boxShadow: 'none', outline: 'none' },
      },
    },
    Switch: {
      baseStyle: {
        _focus: { boxShadow: 'none', outline: 'none' },
      },
    },
  },

  fonts: {
    body: `Montserrat, ${base.fonts.body}`,
    heading: `Montserrat, ${base.fonts.heading}`,
    mono: `monospace, ${base.fonts.mono}`,
  },

  colors: {
    primary: {
      50: '#ffedde',
      100: '#fdcdb4',
      200: '#f8ad87',
      300: '#f38e58',
      400: '#EC7117',
      500: '#ee6924',
      600: '#d55411',
      700: '#a7400b',
      800: '#772d06',
      900: '#491a01',
    },

    dark: {
      500: '#202123',
      600: '#2021237f',
    },
    gray: {
      200: '#F6F5F5',
      500: '#718096',
    },

    borderInput: {
      100: 'rgba(32, 33, 35, 0.15)',
    },

    light: {
      bg: 'white',
    },

    textColor: 'rgba(32, 33, 35, 0.5)',
  },
});

export default theme;
