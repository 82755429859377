import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  Toast,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IUserResponse } from '..';
import { GTMEventType } from '../../../ExternalScripts';
import UserAPI from '../../../api/User';
import ZipCodeAPI from '../../../api/ZipCode';
import { useAuth } from '../../../contexts/AuthContext';
import ErrorResponse from '../../../helpers/ErrorResponse';
import {
  FILLED_PERSONAL_INFORMATION_TIME,
  ROLE_LOCAL_STORAGE,
  TOKEN_PURCHASE,
} from '../../../helpers/LocalStorageHelper';
import { googleTagManagerIds, newEventGTM, removeEventGTM } from '../../../helpers/ga-tag-custom';
import useHandleChange, { TargetType } from '../../../hooks/useHandleChange';
import useHandleSubmit, { ResponseJSON } from '../../../hooks/useHandleSubmit';
import useShoppingCart, {
  IPayment as IInstallment,
  IProductCart,
  IShoppingCart,
} from '../../../hooks/useShoppingCart';
import scrollToInvalidInput from '../../../utils/scrollToInvalidInput';
import { RegisterSchemaType } from '../Register/schemaValidator';
import { FORM_STYLES, INPUT_STYLES } from '../constants';
import CouponBannerApplied from './CouponBannerApplied';
import CouponIcon from './CouponIcon';
import PaymentMethodsTabs from './PaymentMethods/PaymentMethodsTabs';
import schemaValidator from './schemaValidator';
import { IPayment, MethodPaymentType, UserCard } from './types';
import useLocales from './useLocales';
import getBuyButtonText from './utils/getBuyButtonText';
import getDocumentType from './utils/getDocumentType';
import redirectToThanksPage from './utils/redirectToThanksPage';
import { isCouponAllowedInSomeProduct } from './utils/isCouponAllowedInSomeProduct';

const origin = window.location.origin;
const termOfUseLink = `${origin}/term-of-use`;

interface PaymentProps {
  standardPaymentValue?: number;
  products: IProductCart[];
  payments: IInstallment[];
  country: string;
  userData: IUserResponse;
  availablePaymentMethods: MethodPaymentType[];
  thankPageType: string;
  thankPageForApprovedPurchases: string;
  thankPageForPurchasesAwaitingPayment: string;
  thankPageForPurchasesAwaitingAnalysis: string;
  isFreePurchase?: boolean;
  isLoading?: boolean;
  isPurchaseByPixConfirmed?: boolean;
  onIsLoadingChange?: (isLoading: boolean) => void;
  onChangeShoppingCart: () => Promise<IShoppingCart>;
  onPurchaseByPixConfirmedChange?: (isPurchaseByPixConfirmed: boolean) => void;
  utmData?: {
    utm_id?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
  };
  isInternational: boolean;
  setPaymentMethod?: (paymentMethod: string) => void;
  hidePixQRCode: boolean;
  setInstallment: (number: number) => void;
  shoppingCartData: IShoppingCart;
  personalData: Partial<RegisterSchemaType>;
}

interface IPurchaseResponse {
  data: {
    data: {
      purchaseId?: number;
      pixExpirationDate?: string;
      pixQrCode?: string;
      boletoCode?: string;
      boletoExpirationDate?: string;
      boletoLink?: string;
      totalValue?: number;
    };
  };
}

export default function Payment({
  products,
  payments,
  country,
  userData,
  thankPageType,
  thankPageForApprovedPurchases,
  thankPageForPurchasesAwaitingPayment,
  thankPageForPurchasesAwaitingAnalysis,
  availablePaymentMethods,
  isLoading,
  isFreePurchase,
  isPurchaseByPixConfirmed,
  utmData,
  isInternational,
  hidePixQRCode,
  shoppingCartData,
  personalData,
  onIsLoadingChange,
  onChangeShoppingCart,
  setPaymentMethod,
  setInstallment,
}: PaymentProps) {
  const [userCreditCardsList, setUserCreditCardsList] = useState<UserCard[]>([]);
  const [isLoadingZipCode, setIsLoadingZipCode] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [isCouponInvalid, setIsCouponInvalid] = useState(false);
  const [couponErrorMessage, setCouponErrorMessage] = useState('' || 'Cupom inválido');

  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const {
    updateShoppingCartInLocalStorage,
    clearShoppingCart,
    deleteCouponFromLocalStorage,
    getShoppingCartFromLocalStorage,
  } = useShoppingCart();

  const shoppingCartInLocalStorage = getShoppingCartFromLocalStorage();

  const methodPaymentTypeInLocalStorage =
    shoppingCartInLocalStorage?.methodPaymentType as IPayment['methodPaymentType'];

  const { onUpdateTotalItemsInShoppingCart } = useAuth();
  const history = useHistory();

  const toast = useToast();

  const initialInstallment = payments?.slice(-1)[0];

  const initialForm: Partial<IPayment> = useMemo(
    () => ({
      methodPaymentType: methodPaymentTypeInLocalStorage,
      address: {
        country,
        zipCode: '',
        streetAddress: '',
        streetNumber: '',
        neighborhood: '',
        state: '',
        city: '',
        complementary: '',
        alternateAddress: '',
      },
      creditCard: {
        cardNumber: '',
        cardHolderName: '',
        cardExpirationDate: '',
        cardCVV: '',
        month: '',
        year: '',
      },
      UTMData: {
        utm_id: utmData?.utm_id,
        utm_source: utmData?.utm_source,
        utm_medium: utmData?.utm_medium,
        utm_campaign: utmData?.utm_campaign,
        utm_term: utmData?.utm_term,
        utm_content: utmData?.utm_content,
      },
    }),
    [
      country,
      utmData?.utm_campaign,
      utmData?.utm_content,
      utmData?.utm_id,
      utmData?.utm_medium,
      utmData?.utm_source,
      utmData?.utm_term,
      methodPaymentTypeInLocalStorage,
    ]
  );

  const { form, setForm, handleChange } = useHandleChange<Partial<IPayment>>(initialForm, {
    saveCreditCardForFuturePurchases: false,
    installments: initialInstallment?.installment,
  });

  const { cities, states } = useLocales(form?.address?.state);

  async function getAddressByZipCode(zipCode: string) {
    try {
      setIsLoadingZipCode(true);
      const { data: response } = await ZipCodeAPI.index(zipCode);

      if (response) {
        setForm({
          ...form,
          address: {
            ...form.address,
            state: response.state,
            city: response.city,
            neighborhood: response.neighborhood,
            streetAddress: response.street,
            zipCode,
            country,
          },
        });
      }

      if (formValidation?.address?.zipCode) {
        formValidation.address.zipCode.isInvalid = false;
        formValidation.address.zipCode.message = '';
      }
    } catch (error) {
      toast({
        title: 'Não foi possível localizar seu endereço...',
        description: 'Por favor, preencha todos os campos manualmente.',
        status: 'info',
        position: 'bottom-right',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingZipCode(false);
    }
  }

  useEffect(() => {
    const maxInstallment = payments?.slice(-1)[0];

    if (maxInstallment) {
      setInstallment(maxInstallment?.installment);

      setForm({
        ...form,
        installments: maxInstallment?.installment,
      });

      updateShoppingCartInLocalStorage({
        installments: maxInstallment?.installment,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleZipCodeChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (value.length === 8) getAddressByZipCode(value);
    updateField('address.zipCode', value);
  }

  function handleStateChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    setForm(prevForm => ({
      ...prevForm,
      address: {
        ...prevForm.address,
        state: value,
      },
    }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    setPaymentMethod(form?.methodPaymentType);
  }, [form?.methodPaymentType, setPaymentMethod]);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    setForm(prevForm => ({
      ...prevForm,
      coupon: shoppingCartInLocalStorage?.coupon,
      products: products.map(product => ({
        id: Number(product.productId),
        amount: 1,
        poleId: product?.poleId,
        orderBumpId: product?.orderBumpId,
      })),
      affiliation: shoppingCartInLocalStorage?.affiliation,
    }));
  }, [
    products,
    setForm,
    shoppingCartInLocalStorage?.affiliation,
    shoppingCartInLocalStorage?.coupon,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const { data: creditCardData } = await UserAPI.creditCard();
        setUserCreditCardsList(creditCardData);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    })();
  }, []);

  const updateField = useCallback(
    (name: string, value: string) => {
      const event = {
        target: {
          name,
          value,
        },
      };

      handleChange(event as unknown as ChangeEvent<TargetType>);
    },
    [handleChange]
  );

  const GTMEventName = useCallback(
    async (event: GTMEventType, transaction_id?: number) => {
      const idsGTM = googleTagManagerIds();

      let cartProducts = [];

      if (shoppingCartData?.productCart?.length) {
        cartProducts = shoppingCartData?.productCart;

        for (const id of idsGTM) {
          newEventGTM({
            id,
            data: {
              currency: 'BRL',
              event,
              transaction_id,
              name: personalData?.fullName,
              email: personalData?.email,
              phone: personalData?.phone ?? null,
              zip_code: form.address?.zipCode ?? null,
              payment_method: form.methodPaymentType,
              items: cartProducts.map(product => ({
                item_id: product.productId,
                item_name: product.productName,
                value: product.productPrice,
                quantity: 1,
              })),
              total_value: cartProducts.reduce((total, product) => total + product.productPrice, 0),
            },
          });

          removeEventGTM(id, event);
        }
      }
    },
    [
      form.address?.zipCode,
      form.methodPaymentType,
      personalData?.email,
      personalData?.fullName,
      personalData?.phone,
      shoppingCartData?.productCart,
    ]
  );

  const payload = {
    ...form,
    document: {
      type: country ? getDocumentType(country, userData?.documentNumber) : 'other',
      number: userData?.documentNumber || '',
    },
    typeCheckout: 'LAUNCH',
    methodPaymentType: isFreePurchase ? 'free' : form.methodPaymentType,
    creditCard: {
      ...form.creditCard,
      cardExpirationDate: `${form?.creditCard?.month}${form?.creditCard?.year}`,
    },
    installments: Number(form.installments),
    useSavedCreditCard: !!form.creditCard?.cardId,
  };

  if (payload.methodPaymentType !== 'boleto') {
    delete payload.address;
  }

  if (payload.methodPaymentType !== 'credit_card') {
    delete payload.creditCard;
  }

  if (
    payload?.creditCard &&
    Object.hasOwn(payload?.creditCard, 'cardId') &&
    !payload.creditCard.cardId
  ) {
    delete payload.creditCard.cardId;
  }

  if (payload?.creditCard?.cardId && payload?.saveCreditCardForFuturePurchases) {
    payload.saveCreditCardForFuturePurchases = false;
  }

  if (payload.useSavedCreditCard) {
    delete payload.creditCard?.cardCVV;
    delete payload.creditCard?.cardExpirationDate;
    delete payload.creditCard?.cardHolderName;
    delete payload.creditCard?.cardNumber;
    delete payload.creditCard?.month;
    delete payload.creditCard?.year;
  }

  const handleInstallmentChange = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target;

      updateField('installments', value);

      setInstallment(parseInt(value));

      updateShoppingCartInLocalStorage({
        installments: Number(value),
      });

      await onChangeShoppingCart();
    },
    [onChangeShoppingCart, setInstallment, updateField, updateShoppingCartInLocalStorage]
  );

  const handlePaymentMethodChange = useCallback(
    async (value: MethodPaymentType) => {
      setInstallment(1);

      setForm({
        ...form,
        methodPaymentType: value,
        installments: 1,
      });

      updateShoppingCartInLocalStorage({
        installments: 1,
        methodPaymentType: value,
      });

      await onChangeShoppingCart();
    },
    [form, onChangeShoppingCart, setForm, setInstallment, updateShoppingCartInLocalStorage]
  );

  const handleCouponChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setIsCouponInvalid(false);
    setCoupon(value);
  }, []);

  async function redeemCoupon() {
    if (!coupon?.trim()) return;

    updateShoppingCartInLocalStorage({
      coupon: coupon,
    });

    try {
      const response = await onChangeShoppingCart();

      if (!isCouponAllowedInSomeProduct(response?.productCart)) {
        setIsCouponInvalid(true);
        setCoupon('');
      }
    } catch (error) {
      setIsCouponInvalid(true);
      setCouponErrorMessage(error?.response?.data?.message || 'Cupom inválido');
    }
  }

  async function clearCouponInput() {
    deleteCouponFromLocalStorage();
    await onChangeShoppingCart();
    setCoupon('');
  }

  const {
    handleSubmit,
    formValidation,
    isLoading: isSubmitting,
  } = useHandleSubmit({
    schemaValidator: schemaValidator,
    data: payload,
    method: 'post',
    url: '/v2/purchases',
    authenticated: true,
    onSuccess: {
      callback: (response: IPurchaseResponse) => {
        //envio de eventos
        if (payload.methodPaymentType === 'credit_card') {
          GTMEventName('purchase', response?.data?.data?.purchaseId);
        }

        if (payload.methodPaymentType === 'boleto') {
          GTMEventName('billet_payment', response?.data?.data?.purchaseId);
        }

        if (payload.methodPaymentType === 'pix') {
          GTMEventName('pending_payment', response?.data?.data?.purchaseId);
        }

        clearShoppingCart();
        localStorage.removeItem(FILLED_PERSONAL_INFORMATION_TIME);
        onUpdateTotalItemsInShoppingCart(0);

        window.scrollTo({ top: 0 });

        if (thankPageType === 'EXTERNAL') {
          if (thankPageForPurchasesAwaitingPayment && payload.methodPaymentType === 'boleto') {
            localStorage.removeItem(TOKEN_PURCHASE);

            const boletoCode = response?.data?.data?.boletoCode;
            const boletoLink = response?.data?.data?.boletoLink;
            const queryParams = `?boletoCode=${boletoCode}&boletoLink=${boletoLink}`;

            return (window.location.href = `${thankPageForPurchasesAwaitingPayment}${queryParams}`);
          }

          if (thankPageForPurchasesAwaitingAnalysis && payload.methodPaymentType === 'pix') {
            localStorage.removeItem(TOKEN_PURCHASE);

            const pixQrCode = response?.data?.data?.pixQrCode;
            const queryParams = `?pixQrCode=${pixQrCode}`;

            return (window.location.href = `${thankPageForPurchasesAwaitingAnalysis}${queryParams}`);
          }

          if (thankPageForApprovedPurchases) {
            localStorage.removeItem(TOKEN_PURCHASE);

            return (window.location.href = thankPageForApprovedPurchases);
          }
        }

        if (payload.methodPaymentType === 'pix') {
          redirectToThanksPage({
            purchase_id: String(response.data.data.purchaseId),
            payment_method: form.methodPaymentType,
            pix_expiration_date: response.data.data.pixExpirationDate,
            pix_qr_code: response.data.data.pixQrCode,
            boleto_code: response.data.data.boletoCode,
            boleto_expiration_date: response.data.data.boletoExpirationDate,
            boleto_link: response.data.data.boletoLink,
            total_value: String(response.data.data.totalValue),
            utm_id: utmData.utm_id,
            utm_source: utmData.utm_source,
            utm_medium: utmData.utm_medium,
            utm_campaign: utmData.utm_campaign,
            utm_term: utmData.utm_term,
            utm_content: utmData.utm_content,
            history,
          });
          return;
        }

        if (payload.methodPaymentType === 'free') {
          history.push('/members-area/v1/products');
          return;
        }

        redirectToThanksPage({
          purchase_id: String(response.data.data.purchaseId),
          payment_method: form.methodPaymentType,
          pix_expiration_date: response.data.data.pixExpirationDate,
          pix_qr_code: response.data.data.pixQrCode,
          boleto_code: response.data.data.boletoCode,
          boleto_expiration_date: response.data.data.boletoExpirationDate,
          boleto_link: response.data.data.boletoLink,
          total_value: String(response.data.data.totalValue),
          utm_id: utmData.utm_id,
          utm_source: utmData.utm_source,
          utm_medium: utmData.utm_medium,
          utm_campaign: utmData.utm_campaign,
          utm_term: utmData.utm_term,
          utm_content: utmData.utm_content,
          history,
        });
      },
    },
  });

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      onIsLoadingChange(true);

      scrollToInvalidInput();

      try {
        GTMEventName('processing_payment');

        await handleSubmit<ResponseJSON<any>>();
      } catch (error) {
        GTMEventName('cancelled_payment');
      }

      onIsLoadingChange(false);
    },
    [onIsLoadingChange, GTMEventName, handleSubmit]
  );

  return (
    <VStack
      align="center"
      w="full"
      as="form"
      autoComplete="off"
      spacing="32px"
      position="relative"
      onSubmit={onSubmit}
      noValidate
    >
      {!isFreePurchase && (
        <Box as="section" w="full" sx={FORM_STYLES}>
          {isCouponAllowedInSomeProduct(products) ? (
            <CouponBannerApplied code={coupon} onClose={clearCouponInput} />
          ) : (
            <HStack mt={7} align={isCouponInvalid ? 'center' : 'end'}>
              <FormControl isInvalid={isCouponInvalid}>
                <FormLabel>Cupom de desconto</FormLabel>

                <Input
                  name="coupon"
                  value={coupon}
                  onChange={handleCouponChange}
                  placeholder="Digite aqui"
                  onKeyDown={({ key }) => key === 'Enter' && redeemCoupon()}
                  {...INPUT_STYLES}
                />

                <FormErrorMessage>{couponErrorMessage}</FormErrorMessage>
              </FormControl>

              <Box>
                <Button
                  onClick={redeemCoupon}
                  colorScheme="default"
                  borderColor="default.500"
                  color="default.500"
                  variant="outline"
                  fontSize="md"
                  fontWeight="semibold"
                  px={7}
                  rightIcon={<CouponIcon ml={1} />}
                >
                  Aplicar
                </Button>
              </Box>
            </HStack>
          )}

          <PaymentMethodsTabs
            payments={payments}
            availablePaymentMethods={availablePaymentMethods}
            isPurchaseByPixConfirmed={isPurchaseByPixConfirmed}
            saveCreditCardForFuturePurchases={form.saveCreditCardForFuturePurchases}
            value={form.methodPaymentType}
            creditCard={form.creditCard}
            form={form}
            setForm={setForm}
            handleChange={handleChange}
            handleZipCodeChange={handleZipCodeChange}
            isLoadingZipCode={isLoadingZipCode}
            formValidation={formValidation}
            installments={form.installments}
            userCreditCardsList={userCreditCardsList}
            onCreditCardChange={handleChange}
            onPaymentMethodChange={handlePaymentMethodChange}
            onInstallmentChange={handleInstallmentChange}
            handleStateChange={handleStateChange}
            userData={userData}
            isInternational={isInternational}
            cities={cities}
            states={states}
            hidePixQRCode={hidePixQRCode}
          />
        </Box>
      )}

      <VStack w="full" spacing={1} align="start">
        {role && role !== 'ALUNO' && (
          <Text fontWeight="bold" textAlign="center" color="red.500">
            Seu perfil de usuário não permite a compra de produtos, para continuar, acesse como
            Aluno.
          </Text>
        )}

        <Button
          type="submit"
          colorScheme="green"
          px="4.5rem"
          fontSize="md"
          fontWeight="bold"
          w="full"
          bg="#7fcf69"
          _hover={{
            bgColor: '#66c64c',
            borderColor: '#5ec342',
          }}
          isDisabled={isLoading}
          isLoading={isSubmitting}
        >
          {getBuyButtonText(form?.methodPaymentType)}
        </Button>

        <Text as="small" fontSize="xs" color="#202123">
          Ao concluir a compra você concorda com estes{' '}
          <Text
            as="a"
            href={termOfUseLink}
            color="default.500"
            target="_blank"
            rel="noopener noreferrer"
            textDecoration="underline"
            _hover={{ textDecoration: 'underline' }}
          >
            Termos de Serviços
          </Text>
          .
        </Text>
      </VStack>
    </VStack>
  );
}
