import { useEffect, useState } from 'react';

import { useColorMode } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Container from '../../components/ContainerSite/ContainerSite';
import { useTheme } from '../../contexts/ThemeContext';
import { sortByOrder } from '../../helpers/SortHelper';
import AboutSection from './Sections/AboutSection';
import BannerSection from './Sections/BannerSection';
import CategoriesSection from './Sections/CategoriesSection';
import HTMLSection from './Sections/HTMLSection';
import MediasSection from './Sections/MediasSection';
import ProductsSection from './Sections/ProductsSection';
import TestimonialsSection from './Sections/TestimonialsSection';
import styles from './styles.module.css';
import CookieBanner from '../../components/CookieBanner';
import useExternalLinkHeader from '../../useExternalLinkHeader';
import { useHistory } from 'react-router-dom';

function HomeAdmin() {
  const [sections, setSections] = useState([]);
  const { themeMarketplace } = useTheme();
  const history = useHistory();
  const externalLink = useExternalLinkHeader();

  const { setColorMode } = useColorMode();

  useEffect(() => {
    if (externalLink) {
      history.push('/question');
    }
  }, [externalLink, history]);

  useEffect(() => {
    setColorMode('light');
  }, [setColorMode]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const { themeSections } = themeMarketplace;
    setSections(themeSections);
  }, [themeMarketplace]);

  function AvailableSections(section) {
    return {
      bannerHero: <BannerSection section={section} />,
      categories: <CategoriesSection section={section} />,
      biography: <AboutSection section={section} />,
      products: <ProductsSection section={section} />,
      testimonials: <TestimonialsSection section={section} />,
      multimedia: <MediasSection section={section} />,
      html: <HTMLSection section={section} />,
    }[section.name];
  }

  return (
    <Container>
      <ul id="homeSections" className={styles.homeSections}>
        {sections?.sort(sortByOrder).map(section => (
          <li key={nanoid()}>{section.active && AvailableSections(section)}</li>
        ))}
      </ul>

      <CookieBanner />
    </Container>
  );
}

export default HomeAdmin;
