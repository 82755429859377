import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Divider,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import AccessDuration from './AccessDuration';
import PreviewControl from './PreviewControl';

export default function AccessCourse() {
  const { id } = useParams();

  const path = window.location.pathname;

  const isAccessDuration = path.includes(`/access-duration`);
  const isPreviewControl = path.includes(`/preview-control`);

  return (
    <HStack align="start" spacing="30px" m="30px">
      <Breadcrumb separator="">
        <VStack align="start" width="300px" ml="20px" gap="20px">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              fontSize="lg"
              fontWeight="semibold"
              color={isAccessDuration ? 'default.500' : 'gray.500'}
              borderBottom={isAccessDuration ? '2px solid' : 'none'}
              borderColor="default.500"
              to={`/courses/${id}/course-manager/access-course/access-duration`}
            >
              Duração de acesso
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              fontSize="lg"
              fontWeight="semibold"
              color={isPreviewControl ? 'default.500' : 'gray.500'}
              borderBottom={isPreviewControl ? '2px solid' : 'none'}
              borderColor="default.500"
              to={`/courses/${id}/course-manager/access-course/preview-control`}
            >
              Controle de visualização
            </BreadcrumbLink>
          </BreadcrumbItem>
        </VStack>
      </Breadcrumb>

      <Center height="400px">
        <Divider orientation="vertical" size="lg" />
      </Center>

      <VStack width="full">
        <Switch>
          <Route
            exact
            path="/courses/:id/course-manager/access-course/access-duration"
            component={AccessDuration}
          />

          <Route
            exact
            path="/courses/:id/course-manager/access-course/preview-control"
            component={PreviewControl}
          />
        </Switch>
      </VStack>
    </HStack>
  );
}
