import {
  Box,
  Container,
  HStack,
  Heading,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import Header from '../Header';
import Appearance from './Appearance';
import ModulePage from './ModulePage';

const TAB_STYLE = {
  color: '#20212380',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  _active: { color: 'primary.500' },
  _selected: { color: 'primary.500' },
};

interface IParams {
  showcaseId: string;
  showcaseCourseId: string;
}
export interface IModule {
  id: number;
  name: string;
  status: string;
  thumbnail: string | null;
}
export interface IModulePageResponse {
  showcaseCourseId: number;
  hasActiveModulesPage: boolean;
  typeViewModules: string;
  productId: number;
  productName: string;
  modules: IModule[];
}

export default function ModulesEdit() {
  const [isViewModulesButtonVisible, setIsViewModulesButtonVisible] = useState(true);

  const history = useHistory();
  const { showcaseId, showcaseCourseId } = useParams<IParams>();

  const [showcaseModules, setShowcaseModules] = useState<IModulePageResponse>();

  const { data: response, fetchData } = useFetch<UnificadaFront.ResponseJSON<IModulePageResponse>>({
    method: 'get',
    url: `/showcase-course/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/modules`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (showcaseId && showcaseCourseId) {
      fetchData();
    }
  }, [fetchData, showcaseCourseId, showcaseId]);

  const showcaseModulesData = response?.data;

  useEffect(() => {
    if (showcaseModulesData) {
      setIsViewModulesButtonVisible(showcaseModulesData.hasActiveModulesPage);
      setShowcaseModules(showcaseModulesData);
    }
  }, [showcaseModulesData]);

  return (
    <Box minH="100vh">
      <Header isViewModulesButtonVisible={isViewModulesButtonVisible} />

      <Container
        as="main"
        w="full"
        maxW="container.xl"
        padding={{ base: '30px 30px 30px 30px', lg: '56px 0px 40px 0px' }}
      >
        <HStack
          spacing={1}
          fontSize="12px"
          fontWeight="400"
          color="rgba(32, 33, 35, 0.50)"
          cursor="pointer"
          width={{ base: 'full', lg: '130px' }}
          onClick={() => history.push('/members-area-management/netflix')}
        >
          <IoIosArrowBack />
          <Text fontStyle="normal" lineHeight="20px" textDecorationLine="underline">
            Voltar para vitrines
          </Text>
        </HStack>
        <Stack spacing="40px" marginTop="21px">
          <Heading
            color="#202123"
            fontSize="20px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
          >
            Módulos ({showcaseModules?.productName})
          </Heading>
          <Tabs isLazy>
            <TabList>
              <Tab sx={TAB_STYLE}>Módulos</Tab>
              <Tab sx={TAB_STYLE}>Aparência</Tab>
            </TabList>

            <TabIndicator mt="-1.5px" height="3px" bg="primary.500" borderRadius="2px" />

            <TabPanels>
              <ModulePage
                showcaseModules={showcaseModules}
                setShowcaseModules={setShowcaseModules}
                showcaseId={showcaseId}
                showcaseCourseId={showcaseCourseId}
                productId={showcaseModules?.productId}
                setIsViewModulesButtonVisible={setIsViewModulesButtonVisible}
              />

              <Appearance showcaseId={showcaseId} showcaseCourseId={showcaseCourseId} />
            </TabPanels>
          </Tabs>
        </Stack>
      </Container>
    </Box>
  );
}
