import { Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { ICourse, StatusType } from '../..';
import DefaultImageProductList from '../../../../../components/DefaultImageProductList';
import ActionsButton from '../ActionsButton';

interface Props {
  course: ICourse;
  onOpenConfirmDelete: () => void;
  setCourseIdToDelete: (id: number) => void;
  onOpenCourseDuplicate: () => void;
  setCourseIdToDuplicate: (id: number) => void;
}

function setClassesStatus(status: StatusType) {
  const STATUS = {
    ATIVO: 'h7 text-success pt-1',
    INATIVO: 'h7 text-secondary pt-1',
    EM_EDICAO: 'h7 text-warning pt-1',
    BLOQUEADO: 'h7 text-danger pt-1',
    NAO_LISTADO: 'h7 text-dark pt-1',
  };

  return STATUS[status] || 'h6 text-warning pt-1';
}

function formatNameStatus(status: StatusType) {
  const STATUS_VALUE = {
    ATIVO: 'Ativo',
    INATIVO: 'Inativo',
    EM_EDICAO: 'Em edição',
    BLOQUEADO: 'Bloqueado',
    NAO_LISTADO: 'Não Listado',
  };

  return STATUS_VALUE[status] || '...';
}

const CardList = ({
  course,
  setCourseIdToDelete,
  onOpenConfirmDelete,
  onOpenCourseDuplicate,
  setCourseIdToDuplicate,
}: Props) => {
  const history = useHistory();

  function redirectToEditPage() {
    history.push(`/courses/${course.productId}/course-manager/course`);
  }

  return (
    <>
      <Flex
        my={5}
        borderRadius="lg"
        boxShadow="0px 0px 2.8298280239105225px 0px #00000040"
        overflow="hidden"
      >
        <Flex onClick={redirectToEditPage} cursor="pointer">
          {course.productThumbnail ? (
            <Image
              src={course.productThumbnail}
              alt="Imagem do curso"
              objectFit="cover"
              borderRadius="lg"
              boxSize={{ base: '75px', md: '106px' }}
            />
          ) : (
            <DefaultImageProductList title={course.productName} />
          )}
        </Flex>

        <Flex
          flex={{ base: 2.725, md: 5, lg: 4.5, xl: 8.5 }}
          direction="column"
          align="flex-start"
          justify="space-between"
          gap={3}
          py={{ base: 2, lg: '1.125rem' }}
          onClick={redirectToEditPage}
          cursor="pointer"
          pl={{ base: 4, lg: 6 }}
        >
          <Text
            fontSize={{ base: 'smaller', lg: '18px' }}
            color={'#202123'}
            fontWeight={600}
            noOfLines={2}
          >
            {course.productName}
          </Text>
          <Heading fontSize={{ base: '11px', lg: 'sm' }} fontWeight={600} color="#20212350">
            Status:{' '}
            <Text className={`${setClassesStatus(course.productStatus)}`} as="span">
              {formatNameStatus(course.productStatus)}
            </Text>
          </Heading>
        </Flex>

        <Flex justify="flex-start" h="100%" p={{ base: 2, lg: '1.125rem' }}>
          <ActionsButton
            id={course.productId}
            onOpenConfirmDelete={onOpenConfirmDelete}
            setCourseIdToDelete={setCourseIdToDelete}
            onOpenCourseDuplicate={onOpenCourseDuplicate}
            setCourseIdToDuplicate={setCourseIdToDuplicate}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default CardList;
