import Toast from '../components/Toast';
import UploadHelper from '../helpers/UploadHelper';
import { axiosBackend } from './Configuration';
import Platform from './Platform';

const MAX_LENGTH_IMAGE = 3145728;
const TEN_MINUTES = 60 * 10000;

const VideoLibraryAPI = {
  getTranscodeStatus: async id => {
    try {
      const { data: transcodeData } = await axiosBackend().get(
        `/video-libraries/${id}/get-transcode-status`
      );

      return transcodeData;
    } catch (error) {
      throw error;
    }
  },
  getUploadLink: async ({ name }) => {
    try {
      const { data: linksData } = await axiosBackend().get(
        `/video-libraries/get-upload-link?name=${encodeURI(name)}`
      );

      return linksData;
    } catch (error) {
      throw error;
    }
  },
  uploadVideo: async ({ name, size, link }) => {
    try {
      const { data: linksData } = await axiosBackend(TEN_MINUTES).post('/video-libraries/upload', {
        name,
        size,
        link,
      });

      return linksData;
    } catch (error) {
      throw error;
    }
  },
  deleteRemoteVideo: async id => {
    try {
      await axiosBackend().delete(`/video-libraries/delete-remote-video/${id}`);
    } catch (error) {
      throw error;
    }
  },

  index: async ({ page, archive, per_page }) => {
    try {
      let endPoint = `/video-libraries?page=${page}&per_page=${per_page}`;

      if (archive) {
        endPoint = endPoint.concat('&archive=true');
      }
      const { data: videosData } = await axiosBackend().get(endPoint);

      return videosData;
    } catch (error) {
      throw error;
    }
  },

  indexByName: async ({ page = 1, search, per_page = 10, orderBy = 'desc' }) => {
    const searchParams = new URLSearchParams({ page, search, per_page, orderBy }).toString();

    try {
      const { data: videosData } = await axiosBackend().get(`/video-libraries?${searchParams}`);

      return videosData;
    } catch (error) {
      throw error;
    }
  },

  indexByPlayer: async link => {
    try {
      const player = link.replace(/.*((video|videos)\/(\d+))$/g, '$3');

      const { data: videosData } = await axiosBackend().get(`/video-libraries?player=${player}`);

      return videosData;
    } catch (error) {
      throw error;
    }
  },

  show: async id => {
    try {
      const { data: videosData } = await axiosBackend().get(`/video-libraries/${id}`);

      return videosData;
    } catch (error) {
      throw error;
    }
  },

  patch: async (id, videoData) => {
    let thumbnailKey = '';

    try {
      const fileThumbnail = videoData.thumbnail;

      if (fileThumbnail && typeof fileThumbnail !== 'string') {
        if (fileThumbnail && fileThumbnail.size > MAX_LENGTH_IMAGE) {
          return Toast('O tamanho máximo da imagem deve ser igual ou inferior a 3 MB.', 'error');
        }

        if (fileThumbnail) {
          const { newFileName } = await UploadHelper.upload(fileThumbnail, 'thumbnails');

          videoData.thumbnail = newFileName;

          thumbnailKey = `thumbnails/${newFileName}`;
        }
      }

      const payload = {
        name: videoData.name,
        archive: videoData.archive,
        download: videoData.download,
        thumbnail: videoData.thumbnail,
      };

      const { data: updatedVideoData } = await axiosBackend().patch(
        `/video-libraries/${id}`,
        payload
      );

      return updatedVideoData;
    } catch (error) {
      if (thumbnailKey) {
        Platform.deleteFileByKey(thumbnailKey);
      }

      throw error;
    }
  },

  download: async id => {
    try {
      const { data: videosData } = await axiosBackend().get(`/video-download/${id}`);
      return videosData;
    } catch (error) {
      throw error;
    }
  },
  delete: async id => {
    try {
      return await axiosBackend().delete(`/video-libraries/${id}`);
    } catch (error) {
      throw error;
    }
  },
  getPlatformStorage: async () => {
    try {
      const { data } = await axiosBackend().get('/video-libraries/videos/size-usage');

      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default VideoLibraryAPI;
