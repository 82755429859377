import {
  CardFooter,
  Center,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import StatisticsTabPanel from './StatisticsTabPanel';

export interface Performance {
  correct: number;
  wrong: number;
}
export interface AnswersByAlternative {
  item: string;
  count: number;
}

export interface AnswerHistoric {
  answerResult: boolean;
  option: string;
  createdAt: string;
}

export interface Statistics {
  performance: Performance;
  answersByAlternative: AnswersByAlternative[];
  answerHistoric: AnswerHistoric[];
}

interface QuestionDataResponse {
  commentary: string;
  statistics: Statistics;
}

const TAB_STYLE = {
  color: '#20212380',
  borderBottom: '0.5px solid',
  borderColor: '#0000001A',
  '.chakra-tabs__tab': {
    fontSize: 'lg',
    pt: 0,
    pb: 1,
    _selected: {
      borderBottomWidth: '3px',
      borderBottomColor: 'default.500',
    },
  },
};

interface QuestionCardFooterProps {
  questionId: number;
}

export default function QuestionCardFooter({ questionId }: QuestionCardFooterProps) {
  const [tabIndex, setTabIndex] = useState(null);
  const [isTabsDisabled, setIsTabsDisabled] = useState(true);
  const alternateTextColor = useColorModeValue('#20212380', '#AFAFAF');

  function handleTabsChange(index: number) {
    setIsTabsDisabled(false);
    setTabIndex(index);
  }

  const { data: response, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<QuestionDataResponse>
  >({
    method: 'get',
    url: `/question-library-student/members-area/question-external/${questionId}/question/answer-user-statistic`,
    authenticated: true,
    autoFetch: !isTabsDisabled,
  });

  const { commentary, statistics } = response?.data || {};

  return (
    <CardFooter p={0}>
      <Tabs index={tabIndex} onChange={handleTabsChange} colorScheme="default" w="full" isLazy>
        <TabList sx={TAB_STYLE}>
          <Tab color={alternateTextColor} _selected={{ color: '#EC7117' }}>
            Comentário
          </Tab>
          <Tab color={alternateTextColor} _selected={{ color: '#EC7117' }}>
            Estatísticas
          </Tab>
        </TabList>

        <TabPanels>
          {isLoading ? (
            <Center my={5}>
              <Spinner size="md" color="default.500" />
            </Center>
          ) : (
            <TabPanel
              // color={alternateTextColor}
              sx={{
                iframe: {
                  width: '100%',
                  aspectRatio: '16/9',
                },
                a: {
                  color: '#06c',
                  textDecoration: 'underline',
                },
              }}
              dangerouslySetInnerHTML={{
                __html:
                  !commentary || commentary.replace(/<[^>]*>/g, '') === ''
                    ? 'Essa questão não possui comentário.'
                    : commentary,
              }}
              py="1.125rem"
              px={0}
              _focus={{ boxShadow: 'none' }}
            />
          )}

          {!isLoading && <StatisticsTabPanel statistics={statistics} />}
        </TabPanels>
      </Tabs>
    </CardFooter>
  );
}
