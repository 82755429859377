import path from 'path';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { platformsWith200MbUploadLimit } from '../../../../../../App';
import { baseURLBackend } from '../../../../../../api/Configuration';
import ContentAPI from '../../../../../../api/Content';
import CustomDropzone from '../../../../../../components/CustomDropzone';
import EditorRichText from '../../../../../../components/EditorRichText';
import DataLoading from '../../../../../../components/Loadings/DataLoading';
import Spinner from '../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { TOKEN_LOCAL_STORAGE } from '../../../../../../helpers/LocalStorageHelper';
import UploadHelper from '../../../../../../helpers/UploadHelper';

const initialValues = {
  title: '',
  contentType: 'DOWNLOAD',
  order: 0,
  fileDownload: '',
  content: '',
};

const MB = 1024 * 1024; // 1 megabyte em bytes
const SIZE_50_MB = 50 * MB; // 50 megabytes em bytes
const SIZE_200_MB = 200 * MB; // 200 megabytes em bytes
const ADD_WITH_CURRENT_ORDER = 1;

function DownloadForm() {
  const [editorState, setEditorState] = useState('');
  const [fileDownload, setFileDownload] = useState(null);
  const [changed, setChanged] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [form, setForm] = useState(initialValues);

  const history = useHistory();
  const { id: courseId, moduleId, contentId } = useParams();
  const { course, setCourse, getContent } = useCourse();
  const errorMessageRef = useRef(null);

  const hostname = window.location.hostname;
  const isPlatformWith200MbUploadLimit = platformsWith200MbUploadLimit.includes(hostname);

  useEffect(() => {
    const content = getContent(moduleId, contentId);

    if (!contentId) {
      return setForm(initialValues);
    }

    if (content) {
      const currentEditorState = content.content || '';
      setEditorState(currentEditorState);

      setForm({
        title: content.title,
        contentType: content.contentType,
        order: content.order,
        fileDownload: content.download,
        content: content.content,
      });
    }
  }, [contentId, getContent, moduleId]);

  function handleFocus() {
    errorMessageRef.current &&
      errorMessageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
  }

  function handleChange(event) {
    const { name, value } = event.target;

    if (name === 'archive') {
      const [file] = event.target.files || '';
      return setForm({ ...form, fileDownload: file });
    }

    setForm({
      ...form,
      [name]: value,
    });

    setChanged(true);
  }

  function handleEditorStateChange(editorState) {
    setErrorMessage('');
    setChanged(true);
    setEditorState(editorState);
    setForm(prevForm => ({ ...prevForm, content: editorState }));
  }

  function handleDiscardChanges(event) {
    event.preventDefault();
    setErrorMessage('');

    if (contentId) {
      const content = getContent(moduleId, contentId);

      setForm(content);
      setFileDownload(content.download);
      return;
    }

    const currentEditorState = initialValues.content;

    setEditorState(currentEditorState);
    setForm(initialValues);
    setFileDownload('');
    setErrorMessage('');
  }

  async function handleCreate() {
    const currentModule = course.modules.find(module => module.id === parseInt(moduleId));
    const { contents } = currentModule;

    if (contents.length) {
      const orders = contents.map(content => content.order);
      form.order = form.order = orders.length
        ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
        : initialValues.order;
    }

    let nameDownload;

    if (fileDownload) {
      const { newFileName } = await UploadHelper.upload(fileDownload, 'archives');

      setForm({ ...form, fileDownload: encodeURI(newFileName) });

      nameDownload = newFileName;
    }

    const payload = {
      ...form,
      fileDownload: nameDownload ? nameDownload : form.fileDownload ? form.fileDownload : null,
    };

    const { data: createdContent } = await ContentAPI.store(courseId, moduleId, payload);

    const newContents = [...currentModule.contents, createdContent];
    const moduleUpdated = { ...currentModule, contents: newContents };

    const newModules = course.modules.map(module =>
      module.id === moduleUpdated.id ? moduleUpdated : module
    );

    setCourse({
      ...course,
      modules: newModules,
    });
  }

  async function handleUpdate() {
    let nameDownload;

    if (fileDownload) {
      const { newFileName, link } = await UploadHelper.upload(fileDownload, 'archives');

      setForm({ ...form, fileDownload: link });

      nameDownload = newFileName;
    }

    const payload = {
      ...form,
      fileDownload: nameDownload ? nameDownload : form.fileDownload ? form.fileDownload : null,
    };

    const { data: updatedContent } = await ContentAPI.update(
      courseId,
      moduleId,
      contentId,
      payload
    );

    const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

    const newContents = currentModule.contents.map(content => {
      if (content.id === parseInt(contentId)) {
        return {
          ...content,
          title: updatedContent.title,
          contentType: updatedContent.contentType,
          order: updatedContent.order,
          download: updatedContent.download,
          content: updatedContent.content,
        };
      }

      return content;
    });

    const moduleUpdated = { ...currentModule, contents: newContents };

    const newModules = course.modules.map(module =>
      module.id === moduleUpdated.id ? moduleUpdated : module
    );

    setCourse({
      ...course,
      modules: newModules,
    });

    history.push(`/courses/${courseId}/course-manager/course`);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setErrorMessage('');

    if (isPlatformWith200MbUploadLimit && fileDownload && fileDownload.size > SIZE_200_MB) {
      return setErrorMessage('O tamanho máximo do arquivo deve ser igual ou inferior a 200 MB.');
    }

    if (!isPlatformWith200MbUploadLimit && fileDownload && fileDownload.size > SIZE_50_MB) {
      return setErrorMessage('O tamanho máximo do arquivo deve ser igual ou inferior a 50 MB.');
    }

    const method = contentId ? 'update' : 'create';

    if (!form.title || !form.content || form.content === '<p></p>↵') {
      handleFocus();
      return setErrorMessage('Por favor, preencha os campos');
    }

    setIsLoading(true);

    try {
      if (method === 'create') await handleCreate();
      if (method === 'update') await handleUpdate();

      setIsLoading(false);
      setFileDownload('');
      setEditorState('');
      setForm({ title: '', content: '' });

      Toast(`Conteúdo ${!contentId ? 'cadastrado' : 'atualizado'} com sucesso`);
    } catch (error) {
      handleFocus();
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();

    try {
      setIsLoading(true);
      await ContentAPI.delete(courseId, moduleId, contentId);

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      const newContents = currentModule.contents.filter(
        module => module.id !== parseInt(contentId)
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      history.push(`/courses/${courseId}/course-manager/course`);
      setIsLoading(false);
      Toast(`Conteúdo excluído com sucesso`);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    }
  }

  function handleDropFileDownload(acceptedFiles) {
    setErrorMessage('');

    const [fileDownload] = acceptedFiles || '';

    setFileDownload(fileDownload);
    setForm({
      ...form,
      fileDownload: fileDownload,
    });
  }

  function PreviewDownload({ fileDownload }) {
    function handleDiscardFileDownload() {
      setFileDownload('');
      setForm({
        ...form,
        fileDownload: '',
      });
    }

    return (
      <div className="d-flex align-center justify-content-between mt-2">
        <div className="text-ellipsis w-75">
          {fileDownload?.name || path.basename(decodeURI(form.fileDownload))}
        </div>

        <div className="flex-shrink-0">
          <div>
            {!fileDownload && (
              <a
                href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${courseId}/course/download?token=${localStorage.getItem(
                  TOKEN_LOCAL_STORAGE
                )}`}
                download
                target="_blank"
                rel="noreferrer"
              >
                Download
              </a>
            )}

            <button
              onClick={handleDiscardFileDownload}
              type="button"
              className="btn btn-outline-primary"
            >
              <BsTrash />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-5" style={{ wordBreak: 'break-all' }}>
      <h4 className="mt-1 mb-4">{form.title || 'Novo conteúdo para download'}</h4>

      <form onSubmit={handleSubmit}>
        <div className="form-background p-5 round-xl shadow-sm border border-gray bg-white mb-5">
          <div className="mb-4">
            <p className="info" style={{ wordBreak: 'break-word', lineHeight: '1.5' }}>
              Ofereça qualquer tipo de arquivo para os seus alunos, tais como
              <b style={{ fontWeight: 600 }}> checklist, planilhas, apresentações, e-books </b>,
              etc, sejam elas em formatos de
              <b style={{ fontWeight: 600 }}> arquivos de texto, pdf, excel, power point</b>, e que
              estarão disponíveis para download.
            </p>
          </div>

          <div className="form-group">
            <label className="h6" htmlFor="title">
              Título - Downloads Digitais
            </label>
            <input
              value={form.title}
              placeholder="Digite um título para o seu conteúdo"
              onChange={handleChange}
              name="title"
              id="title"
              type="text"
              className="form-control input-content"
              required
              minLength={1}
              maxLength={255}
            ></input>
          </div>

          <div className="form-group mb-4">
            <label className="h6">Descrição:</label>
            <EditorRichText value={editorState} onChange={handleEditorStateChange} />
          </div>

          {!form.fileDownload ? (
            <div className="form-group">
              {!contentId && <label className="h6">Carregar arquivo:</label>}
              <CustomDropzone
                file={fileDownload}
                isDisabled={isLoading}
                accept={[
                  'text/plain charset=us-ascii',
                  'image/vnd.adobe.photoshop',
                  'image/vnd.adobe.premiere',
                  'image/jpeg',
                  'image/png',
                  'image/webp',
                  'image/bmp',
                  'image/svg+xml',
                  'application/vnd.mspowerpoint',
                  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  'application/msword',
                  'application/vnd.ms-excel',
                  'application/excel',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/pdf',
                  'application/postscript',
                  'chemical/x-cubeGaussian',
                  'application/x-zip',
                  'application/zip',
                  'application/octet-stream',
                  'application/x-zip-compressed',
                  'multipart/x-zip',
                  'chemical/x-gaussian-cube',
                ]}
                onDrop={handleDropFileDownload}
                onDropRejected={() => setErrorMessage('Arquivo inválido!')}
                extensions="zip, doc, ppt, xlsx, xls, pdf, e todos os
              arquivos adobe."
                maxFileSize="50 MB"
              />
            </div>
          ) : (
            <PreviewDownload fileDownload={fileDownload} />
          )}

          <DataLoading loading={isLoading} className="d-flex justify-content-center mt-4">
            {errorMessage && !isLoading && (
              <div className="mt-4">
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{ borderRadius: '0', wordBreak: 'break-all' }}
                >
                  <div className="text-center" ref={errorMessageRef}>
                    {errorMessage}
                  </div>
                </div>
              </div>
            )}

            <div className="d-flex justify-content-between mt-4">
              <div>
                {contentId && (
                  <button
                    onClick={event => {
                      event.preventDefault();
                      setIsShowModal(true);
                    }}
                    className="btn btn-outline-danger"
                  >
                    Excluir conteúdo
                  </button>
                )}
              </div>
              <div className="d-flex align-center justify-content-end">
                <button onClick={handleDiscardChanges} className="btn btn-light ml-3">
                  {contentId ? 'Descartar mudanças' : 'Descartar'}
                </button>
                <button type="submit" className="btn btn-primary ml-3" disabled={!changed}>
                  Salvar
                </button>
              </div>
            </div>
          </DataLoading>
        </div>
      </form>
      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="mb-1 py-4">
            <h3 className="text-center">Você tem certeza que deseja excluir o conteúdo?</h3>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <button onClick={() => setIsShowModal(false)} className="btn btn-light mr-4">
              Cancelar
            </button>
            <button onClick={handleDelete} className="btn btn-danger" disabled={isLoading}>
              {isLoading ? <Spinner small /> : 'Excluir'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DownloadForm;
