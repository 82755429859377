import { useCallback, useState } from 'react';
import ShoppingCartApi from '../api/ShoppingCart';
import { OrderBumpType } from '../components/OrderBump';
import { useAuth } from '../contexts/AuthContext';
import { SHOPPING_CART } from '../helpers/LocalStorageHelper';
import { useHistory } from 'react-router-dom';

export type MethodPaymentType = 'credit_card' | 'boleto' | 'pix' | 'free';

type CheckoutType = 'DEFAULT' | 'LAUNCH';

export interface ILocalStorageProduct {
  id: number;
  amount: number;
  orderBumpId?: number;
}

export interface IProductCart {
  productId: number;
  productInstallments: number;
  productInstallmentWithTariff: boolean;
  productName: string;
  productThumbnail: string;
  instructorOrOwner: string;
  productOriginalPrice: number;
  productPrice: number;
  productAmount: number;
  couponAccepted: boolean;
  splitTypePole?: {
    poleId: number;
    poleName: string;
  }[];
  couponDiscount: number;
  orderBumpId?: number;
  poleId?: number;
}
export interface IPayment {
  installment: number;
  installmentPrice: number;
  total: number;
}
export interface IShoppingCart {
  orderBumps: OrderBumpType[];
  methodPaymentType: MethodPaymentType;
  coupon?: string;
  installments: number;
  productCart: IProductCart[];
  payments: IPayment[];
  hasUserSelectedInstallment: boolean;
}

export interface ILocalStorageShoppingCart {
  methodPaymentType: MethodPaymentType;
  products: ILocalStorageProduct[];
  installments: number;
  coupon?: string;
  affiliation?: string;
  typeCheckout?: string;
  hasUserSelectedInstallment: boolean;
}

export interface IParams {
  id: number;
  coupon: string;
  methodPaymentType: MethodPaymentType;
  installments: number;
  affiliation?: string;
  typeCheckout?: CheckoutType;
  hasUserSelectedInstallment: boolean;
}

interface IReturn {
  updateShoppingCartInLocalStorage: (params: Partial<IParams>, orderBumpId?: number) => void;
  deleteProductFromLocalStorage: (id: number) => void;
  deleteCouponFromLocalStorage: () => void;
  addToShoppingCart: (typeCheckout?: CheckoutType) => Promise<IShoppingCart>;
  clearShoppingCart: () => void;
  getShoppingCartFromLocalStorage: () => ILocalStorageShoppingCart;
  productExistsInShoppingCart: (productId: number, productsInShoppingCart: number[]) => boolean;
  isLoading: boolean;
}

const VALID_PAYMENT_METHODS = ['credit_card', 'boleto', 'pix', 'free'];

function getShoppingCartFromLocalStorage(): ILocalStorageShoppingCart {
  return JSON.parse(localStorage.getItem(SHOPPING_CART));
}

function setLocalStorage(values) {
  localStorage.setItem(SHOPPING_CART, JSON.stringify(values));
}

function clearShoppingCart() {
  localStorage.removeItem(SHOPPING_CART);
}

function productExistsInShoppingCart(productId: number, productsInShoppingCart: number[]) {
  return productsInShoppingCart?.includes(parseInt(productId.toString()));
}

function useShoppingCart(): IReturn {
  const [isLoading, setIsLoading] = useState(false);

  const { onUpdateTotalItemsInShoppingCart } = useAuth();

  const history = useHistory();

  function deleteProductFromLocalStorage(id: number) {
    const values = getShoppingCartFromLocalStorage();

    let updatedProducts: ILocalStorageProduct[];

    updatedProducts = values.products.filter(product => product.id !== parseInt(id.toString()));

    if (!updatedProducts.length) return clearShoppingCart();

    setLocalStorage({ ...values, products: updatedProducts });
  }

  function deleteCouponFromLocalStorage() {
    const values = getShoppingCartFromLocalStorage();

    delete values.coupon;

    setLocalStorage({ ...values });
  }

  function getValidPaymentMethod(methodPaymentType: string) {
    if (!VALID_PAYMENT_METHODS.includes(methodPaymentType)) {
      return 'credit_card';
    }

    return methodPaymentType;
  }

  function updateShoppingCartInLocalStorage(params: Partial<IParams>, orderBumpId?: number) {
    const { id, affiliation, coupon, methodPaymentType, installments } = params;

    const shoppingCartFromLocalStorage = getShoppingCartFromLocalStorage();

    // Caso ainda não exista nada no localStorage, é criado novos itens.
    if (!shoppingCartFromLocalStorage) {
      onUpdateTotalItemsInShoppingCart(1);

      return localStorage.setItem(
        SHOPPING_CART,
        JSON.stringify({
          methodPaymentType: methodPaymentType ?? 'credit_card',
          products: [{ id, amount: 1, orderBumpId }],
          installments: installments ?? 1,
          affiliation: affiliation,
          coupon,
        })
      );
    }

    if (id) {
      const productExists = !!shoppingCartFromLocalStorage.products.find(
        product => product.id === parseInt(id.toString())
      );

      if (productExists) return;

      const hasAffiliation = !!shoppingCartFromLocalStorage.affiliation;

      if (hasAffiliation) return;

      const updatedProducts = [
        ...shoppingCartFromLocalStorage.products,
        { id, amount: 1, orderBumpId },
      ];

      onUpdateTotalItemsInShoppingCart(updatedProducts.length);

      const methodPaymentType = getValidPaymentMethod(
        shoppingCartFromLocalStorage.methodPaymentType
      );

      return localStorage.setItem(
        SHOPPING_CART,
        JSON.stringify({
          ...shoppingCartFromLocalStorage,
          products: updatedProducts,
          methodPaymentType,
          affiliation,
        })
      );
    }

    onUpdateTotalItemsInShoppingCart(shoppingCartFromLocalStorage.products.length);

    return localStorage.setItem(
      SHOPPING_CART,
      JSON.stringify({ ...shoppingCartFromLocalStorage, ...params })
    );
  }

  const addToShoppingCart = useCallback(
    async (typeCheckout: CheckoutType = 'DEFAULT') => {
      const previousItems = getShoppingCartFromLocalStorage();

      const installments =
        previousItems.methodPaymentType === 'credit_card' ? previousItems.installments : 1;

      const methodPaymentType = getValidPaymentMethod(
        previousItems.methodPaymentType
      ) as MethodPaymentType;

      try {
        setIsLoading(true);

        const payload = {
          ...previousItems,
          installments,
          methodPaymentType,
          typeCheckout,
        };

        delete payload.hasUserSelectedInstallment;

        const { data } = await ShoppingCartApi.add(payload);

        const updatedShoppingCart = {
          ...previousItems,
          products: data.productCart.map(product => {
            return {
              id: product.productId,
              amount: product.productAmount,
              orderBumpId: product?.orderBumpId,
            } as ILocalStorageProduct;
          }),
          installments,
        };

        localStorage.setItem(SHOPPING_CART, JSON.stringify(updatedShoppingCart));

        onUpdateTotalItemsInShoppingCart(data.productCart.length);

        return data;
      } catch (error) {
        clearShoppingCart();

        if (error?.response?.data?.message === 'Nenhum produto foi encontrado.') {
          return history.push('/');
        }

        // Retorna para o estado anterior do carrinho que estava sem o erro.
        localStorage.setItem(
          SHOPPING_CART,
          JSON.stringify({
            methodPaymentType: previousItems.methodPaymentType,
            products: previousItems.products,
            affiliation: previousItems.affiliation,
            installments,
          })
        );

        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [history, onUpdateTotalItemsInShoppingCart]
  );

  return {
    isLoading,
    deleteProductFromLocalStorage,
    deleteCouponFromLocalStorage,
    updateShoppingCartInLocalStorage,
    addToShoppingCart,
    getShoppingCartFromLocalStorage,
    clearShoppingCart,
    productExistsInShoppingCart,
  };
}

export default useShoppingCart;
