import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import Joi from 'joi';
import { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Testimony } from '..';
import CustomDropzone from '../../../../../../../../components/CustomDropzone';
import ErrorResponse from '../../../../../../../../helpers/ErrorResponse';
import UploadHelper from '../../../../../../../../helpers/UploadHelper';
import useHandleChange from '../../../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../../../hooks/useHandleSubmit';
import ImagePreview from '../../ImagePreview';
import ImageUploadLoading from '../../ImageUploadLoading';
import path from 'path';

interface ImageFile {
  preview: string;
  file?: File;
}

const THREE_MEGABYTES = 1024 * 1024 * 3;

const initialState: Partial<Testimony> = {
  name: '',
  testimony: '',
  thumbnail: '',
};

const schemaValidator = Joi.object<Testimony>().keys({
  name: Joi.string().required().messages({
    'any.required': 'Digite o nome.',
    'string.base': 'Ops, parece que você informou um nome inválido.',
    'string.empty': 'Por favor, digite o nome.',
  }),
  testimony: Joi.string().required().messages({
    'any.required': 'Digite o depoimento.',
    'string.base': 'Ops, parece que você informou um depoimento inválido.',
    'string.empty': 'Por favor, digite o depoimento.',
  }),
  thumbnail: Joi.string().required().messages({
    'any.required': 'Escolha uma imagem para o depoimento.',
    'string.base': 'Ops, parece que você informou uma imagem inválida.',
    'string.empty': 'Por favor, escolha uma imagem para o depoimento.',
  }),
});

interface TestimonyFormModalProps {
  isOpen: ModalProps['isOpen'];
  isLoading: boolean;
  testimony?: Testimony;
  onClose: ModalProps['onClose'];
  fetchData: () => Promise<void>;
}

export default function TestimonyFormModal({
  testimony,
  isOpen,
  onClose,
  fetchData,
}: TestimonyFormModalProps) {
  const [thumbnail, setThumbnail] = useState<ImageFile>(null);
  const [isUploading, setIsUploading] = useState(false);

  const toast = useToast();

  const {
    form,
    setForm,
    handleChange,
    setOnChanged: setHasChange,
  } = useHandleChange<Testimony>(
    initialState,
    !!testimony && {
      ...testimony,
    }
  );

  useEffect(() => {
    if (testimony) {
      setForm({
        name: testimony.name,
        testimony: testimony.testimony,
        thumbnail: testimony.thumbnail,
      });
    }
  }, [setForm, testimony]);

  function handleClose() {
    setForm(initialState);
    onClose();
  }

  const { planId } = useParams<{ planId: string }>();

  const isUpdating = !!testimony?.id;

  form?.thumbnail && (form.thumbnail = path.basename(form.thumbnail));

  const {
    handleSubmit,
    isLoading: isSubmitting,
    formValidation,
  } = useHandleSubmit({
    method: isUpdating ? 'patch' : 'post',
    url: isUpdating
      ? `/recurrence-plan/${planId}/testimony/${testimony.id}`
      : `/recurrence-plan/${planId}/testimony`,
    authenticated: true,
    data: form,
    schemaValidator,
    onSuccess: {
      callback: async () => {
        handleClose();
        await fetchData();
      },
    },
  });

  async function uploadFile(file: File) {
    return await UploadHelper.upload(file, 'banners');
  }

  function handleUploadError(error) {
    toast({
      title: ErrorResponse(error),
      status: 'error',
    });
  }

  async function handleDropFile(acceptedFiles) {
    setHasChange(true);
    setIsUploading(true);

    const [file] = acceptedFiles;

    if (file.size > THREE_MEGABYTES) {
      return toast({
        title: 'Imagem muito grande!',
        description: 'O tamanho máximo deve ser igual ou inferior a 5 MB.',
        status: 'error',
        position: 'top',
      });
    }

    const preview = URL.createObjectURL(file);

    try {
      const { newFileName } = await uploadFile(file);

      setForm({ ...form, thumbnail: newFileName });

      setThumbnail({ preview, file });
    } catch (error) {
      handleUploadError(error);
    } finally {
      setIsUploading(false);
    }
  }

  function handleDropRejected() {
    toast({
      title: 'Arquivo inválido!',
      description: 'Formatos suportados: JPG, PNG, JPEG',
      status: 'error',
    });
  }

  function handleSelectNewImage(name: 'thumbnail' | 'mainBanner') {
    setHasChange(true);

    setForm({ ...form, [name]: null });
  }

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      closeOnEsc={!isSubmitting}
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={onSubmit}
        noValidate
        maxWidth={{ base: '94%', md: '790px' }}
        px={{ base: 4, xl: 0 }}
      >
        <ModalHeader fontSize="xl" fontWeight="medium">
          Adicionando um depoimento
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody py={0}>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={5}>
            <Box
              as="section"
              maxWidth={{ base: '100%', md: '21.625rem' }}
              w="100%"
              height={{ base: '23.5625rem', xl: '17.5rem' }}
            >
              <FormControl isInvalid={formValidation?.thumbnail?.isInvalid}>
                <FormLabel fontSize="sm" m={0}>
                  Thumbnail
                </FormLabel>

                <FormHelperText fontSize="sm" color="#20212380">
                  Imagem que irá ficar no depoimento do seu aluno.
                </FormHelperText>

                <Flex position="relative" height={{ base: '23.5625rem', xl: '17.5rem' }} mt={2}>
                  {!form.thumbnail ? (
                    <>
                      <CustomDropzone
                        file={thumbnail?.file}
                        isDisabled={isSubmitting}
                        accept={['image/jpg', 'image/png', 'image/jpeg', 'image/webp']}
                        dimensions="1080x1080"
                        onDrop={handleDropFile}
                        onDropRejected={handleDropRejected}
                        extensions="JPG, PNG, JPEG e WEBP"
                        maxFileSize="3 MB"
                        height="17.5rem"
                      />

                      <ImageUploadLoading position="absolute" isLoading={isUploading} />
                    </>
                  ) : (
                    <VStack w="full">
                      <Box height={{ base: '21.5625rem', xl: '17.5rem' }} w="100%">
                        <ImagePreview
                          imageField="thumbnail"
                          preview={thumbnail?.preview ?? form.thumbnail}
                          onSelectNewImage={handleSelectNewImage}
                        />
                      </Box>

                      <Button
                        display={{ base: 'flex', xl: 'none' }}
                        onClick={() => handleSelectNewImage('thumbnail')}
                        w="full"
                        variant="outline"
                        colorScheme="primary"
                        size="sm"
                        zIndex="2"
                      >
                        Trocar Imagem
                      </Button>
                    </VStack>
                  )}
                </Flex>

                <FormErrorMessage>{formValidation?.thumbnail?.message}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box as="section" w="100%">
              <FormControl isRequired isInvalid={formValidation?.name?.isInvalid}>
                <FormLabel fontSize="sm">Nome</FormLabel>

                <Input
                  name="name"
                  value={form?.name}
                  onChange={handleChange}
                  focusBorderColor="gray.300"
                  maxLength={200}
                  placeholder="Digite o nome"
                  _placeholder={{ color: 'blackAlpha.500' }}
                />

                <FormErrorMessage>{formValidation?.name?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired mt={5} isInvalid={formValidation?.testimony?.isInvalid}>
                <HStack justify="space-between">
                  <FormLabel fontSize="sm">Depoimento</FormLabel>

                  <Text color={form?.testimony?.length > 450 ? 'red.500' : 'blackAlpha.500'}>
                    Caracteres: {form?.testimony?.length}/500
                  </Text>
                </HStack>

                <Textarea
                  name="testimony"
                  value={form?.testimony}
                  onChange={handleChange}
                  focusBorderColor="gray.300"
                  maxLength={500}
                  placeholder="Digite a resposta"
                  _placeholder={{ color: 'blackAlpha.500' }}
                  rows={10}
                />

                <FormErrorMessage>{formValidation?.testimony?.message}</FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter justifyContent="end" gap={3}>
          <Button
            size="sm"
            onClick={onClose}
            bg="#e9e9e9"
            border="1px solid #20212340"
            isDisabled={isSubmitting}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            size="sm"
            colorScheme="primary"
            color="secondary.500"
            isLoading={isSubmitting}
          >
            Adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
