import { Button, HStack, Input, InputGroup, InputRightAddon, Text, VStack } from '@chakra-ui/react';
import { MdContentCopy as CopyIcon } from 'react-icons/md';

export default function BoletoPreviewWidget() {
  return (
    <VStack w="full" minHeight="9rem" bg="#202123" p={5} pointerEvents="none">
      <Text color="#ffffff">
        Sua compra foi confirmada com sucesso! Segue o código para pagamento do boleto.
      </Text>

      <InputGroup bg="#ffffff" borderRadius="base">
        <Input placeholder="001 9 337370000000100 05009 401448 16060680935031" />

        <InputRightAddon>
          <HStack color="#20212380">
            <CopyIcon size={14} />
            <Text>Copiar</Text>
          </HStack>
        </InputRightAddon>
      </InputGroup>

      <Button size="lg" background="#7fcf69" color="#ffffff">
        Baixar boleto
      </Button>
    </VStack>
  );
}
