import {
  MdKeyboardArrowLeft as ArrowLeft,
  MdKeyboardArrowRight as ArrowRight,
} from 'react-icons/md';

import Paginate from 'react-paginate';
import styles from './styles.module.css';

interface IPaginationProps {
  onPageActive?: number;
  pageCount?: number;
  color?: string;
  onPageChange?: (props: any) => void;
}

function Pagination({ onPageActive = 0, pageCount, onPageChange }: IPaginationProps) {
  if (!pageCount) return <div></div>;
  return (
    <Paginate
      pageCount={pageCount}
      onPageChange={onPageChange}
      pageRangeDisplayed={10}
      marginPagesDisplayed={5}
      pageLinkClassName="px-2"
      containerClassName={styles.container}
      activeClassName={styles.activePagination}
      nextLabel={<ArrowRight size={26} color="#909091" />}
      previousLabel={<ArrowLeft size={26} color="#909091" />}
      initialPage={onPageActive}
      disableInitialCallback
    />
  );
}

export default Pagination;
