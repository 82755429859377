import { FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import useHandleChange from '../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../utils/scrollToInvalidInput';
import { activeCampaignConfigurationSchemaValidator } from './schemaValidator';

import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Spinner,
  Switch,
  TabPanel,
  Text,
  VStack,
} from '@chakra-ui/react';

const INPUT_STYLE = {
  '.chakra-input': {
    borderRadius: 6,
    focusBorderColor: 'gray.300',
    borderColor: '#E2E8F0',
    fontSize: 'sm',
    _placeholder: { color: '#0000005C' },
  },
};

export interface ActiveCampaignConfiguration {
  id?: number;
  url: string;
  token: string;
  key: string;
  actid: string;
  status: boolean;
}

export default function ConfigurationsTabPanel() {
  const { goBack } = useHistory();

  const { data, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<ActiveCampaignConfiguration | null>
  >({
    method: 'get',
    authenticated: true,
    url: '/active-campaign',
    autoFetch: true,
  });

  const isNew = !data?.data?.id;
  const method = isNew ? 'post' : 'patch';
  const url = isNew ? '/active-campaign' : `/active-campaign/${data?.data?.id}/update`;
  const successMessage = isNew ? 'cadastradas' : 'atualizadas';

  const {
    form,
    handleChange,
    onChanged: hasChange,
    handleCancel,
  } = useHandleChange<ActiveCampaignConfiguration>(
    { status: !!data?.data?.status },
    data && {
      url: data?.data?.url ?? '',
      token: data?.data?.token ?? '',
      key: data?.data?.key ?? '',
      actid: data?.data?.actid ?? '',
      status: !!data?.data?.status,
    }
  );

  function handleCancelButtonClick() {
    if (!hasChange) {
      return goBack();
    }

    handleCancel();
  }

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit({
    data: form,
    schemaValidator: activeCampaignConfigurationSchemaValidator,
    url: url,
    method: method,
    authenticated: true,
    onSuccess: {
      message: `Configurações de Active Campaign ${successMessage} com sucesso!`,
      callback: () => goBack(),
    },
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();
    scrollToInvalidInput();

    await handleSubmit();
  }

  if (isLoading) {
    return (
      <Center my={20}>
        <Spinner size="xl" color="primary.500" />
      </Center>
    );
  }

  return (
    <TabPanel>
      <VStack as="form" onSubmit={onSubmit} spacing={8} sx={INPUT_STYLE} noValidate>
        <FormControl isInvalid={formValidation?.url?.isInvalid}>
          <FormLabel>
            URL API
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Input
            name="url"
            value={form.url}
            onChange={handleChange}
            placeholder="Digite aqui"
            focusBorderColor="gray.300"
            maxLength={1000}
          />

          <FormErrorMessage>{formValidation?.url?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.token?.isInvalid}>
          <FormLabel>
            Key (Token)
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Input
            name="token"
            value={form.token}
            onChange={handleChange}
            placeholder="Digite aqui"
            focusBorderColor="gray.300"
            maxLength={255}
          />

          <FormErrorMessage>{formValidation?.token?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.actid?.isInvalid}>
          <FormLabel>
            ActID
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Input
            name="actid"
            value={form.actid}
            onChange={handleChange}
            placeholder="Digite aqui"
            focusBorderColor="gray.300"
            maxLength={255}
          />

          <FormErrorMessage>{formValidation?.actid?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.key?.isInvalid}>
          <FormLabel fontSize={{ base: 'sm', xl: 'md' }}>
            Key Event
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>

          <Input
            name="key"
            value={form.key}
            onChange={handleChange}
            placeholder="Digite aqui"
            focusBorderColor="gray.300"
            maxLength={255}
          />

          <FormErrorMessage>{formValidation?.key?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formValidation?.status?.isInvalid}>
          <FormLabel fontSize={{ base: 'sm', xl: 'md' }}>Status</FormLabel>

          <HStack spacing="0.375rem">
            <Switch
              name="status"
              isChecked={form.status}
              onChange={handleChange}
              id="status"
              colorScheme="primary"
              sx={{
                '.chakra-switch__track': {
                  _focusVisible: { boxShadow: 'none', outline: 'none' },
                },
              }}
            />
            <Text
              htmlFor="status"
              as={FormLabel}
              m={0}
              fontWeight="normal"
              fontSize="sm"
              color="#2D3748"
            >
              {`${form.status ? 'Ativado' : 'Desativado'}`}
            </Text>
          </HStack>

          <FormErrorMessage>{formValidation?.status?.message}</FormErrorMessage>
        </FormControl>

        <HStack as="footer" w="full" justify="end" mt={8} spacing={1}>
          <Button
            onClick={handleCancelButtonClick}
            variant="ghost"
            mr={3}
            background="gray.200"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isDisabled={isSubmitting}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            colorScheme="primary"
            color="secondary.500"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isLoading={isSubmitting}
            isDisabled={!hasChange}
          >
            Salvar
          </Button>
        </HStack>
      </VStack>
    </TabPanel>
  );
}
