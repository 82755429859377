import { Box, Progress, Stack, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { IStatistics } from '..';
import Lines from './Lines';

interface IProps {
  statistics: IStatistics[];
}

export default function PerformanceView({ statistics }: IProps) {
  const textColor = useColorModeValue('#20212380', '#FFFFFF');
  const bgColor = useColorModeValue('rgba(32, 33, 35, 0.05)', '#37393D');
  const bgColorTooltip = useColorModeValue('#202123', '#FFFFFF');
  const textColorTooltip = useColorModeValue('#FFFFFF', '#202123');
  return (
    <Box paddingBottom="50px">
      <Text fontSize="14px" fontWeight="500" marginY="30px">
        Rendimento
      </Text>

      <Lines hiddenBar={false} />
      {statistics?.map((statistic, index) => (
        <Box key={index}>
          <Box display="flex" gap="10px" flexDirection="column">
            <Stack
              width="full"
              direction="row"
              alignItems="center"
              height="40px"
              bgColor={bgColor}
              borderRadius={5}
              spacing={0}
              paddingRight={{ base: '10px', md: '25px', lg: '25px' }}
            >
              <Text
                color={textColor}
                width={{ base: '356px', md: '413px', lg: '413px' }}
                fontSize="16px"
                fontWeight="500"
                padding={{ base: '10px', md: '10px 30px', lg: '10px 30px' }}
                isTruncated
              >
                {statistic.name}
              </Text>

              <Tooltip
                hasArrow
                label={`Rendimento: ${statistic.performance}%`}
                bg={bgColorTooltip}
                color={textColorTooltip}
                placement="top"
                borderRadius="4px"
              >
                <Progress
                  width="full"
                  height="40px"
                  sx={{
                    '> div': { backgroundColor: '#ec7117' },
                  }}
                  cursor="pointer"
                  value={statistic.performance}
                />
              </Tooltip>
            </Stack>
          </Box>
          <Lines hiddenBar={false} />
        </Box>
      ))}

      <Lines hiddenNumbers={false} />
    </Box>
  );
}
