import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  Flex,
  Heading,
  HStack,
  Image,
  Skeleton,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { IRecurrencePlanResponse } from '..';
import Proluno from '../../../assets/icons/proluno-icon-bg-white.png';
import Verified from '../../../assets/icons/verified.png';
import brands from '../../../assets/images/brands.png';
import convertToBrazilianCurrency from '../../../helpers/convertToBrazilianCurrency';
import Plan from './Plan';
import { recurrenceText } from '../../../helpers/recurrenceText';

interface PurchaseSummaryProps extends CardProps {
  plan: IRecurrencePlanResponse;
  isLoading: boolean;
}

export default function PurchaseSummary({ plan, isLoading, ...rest }: PurchaseSummaryProps) {
  return (
    <Card
      minW="full"
      minH="493px"
      maxWidth="26rem"
      pb={4}
      boxShadow="0px 4px 4px 0px #00000040"
      border="1px solid #2021230D"
      color="#202123"
      bgColor="#FFF"
      {...rest}
    >
      <CardHeader textAlign="center">
        <Heading
          color="#202123"
          textAlign="center"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
        >
          Resumo da compra
        </Heading>
      </CardHeader>

      <CardBody px={{ base: 4, xl: 5 }}>
        <Stack spacing="32px">
          <VStack spacing={3} divider={<StackDivider borderColor="gray.300" />}>
            <Plan key={plan?.idRecurrencePlan} plan={plan} isLoading={isLoading} />
          </VStack>

          <Flex direction="column" w="full" gap="12px" sx={{ strong: { fontWeight: 'semibold' } }}>
            <Skeleton
              w="full"
              isLoaded={!isLoading}
              startColor="default.400"
              endColor="default.400"
              maxH={8}
            >
              <HStack
                w="full"
                justify="space-between"
                fontSize={{ base: 'md', xl: 'xl' }}
                color="#EB7129"
              >
                <Text as="span" fontWeight="medium">
                  Total a pagar
                </Text>
                <Text as="strong">
                  {convertToBrazilianCurrency(plan?.value)}
                  <Text as="span" fontWeight="400">
                    /{recurrenceText(plan?.recurrence)}
                  </Text>
                </Text>
              </HStack>
            </Skeleton>
          </Flex>
        </Stack>
      </CardBody>

      <CardFooter padding={0}>
        <Box
          width="full"
          display="flex"
          flexDirection="column"
          gap="32px"
          paddingBottom="10px"
          alignItems="center"
        >
          <Stack
            width="full"
            height="auto"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            bgColor="#F4F4F4"
            spacing="16px"
            paddingY="10px"
          >
            <Text color="#202123" textAlign="center" fontSize="14px" fontWeight="500">
              Formas de pagamento
            </Text>

            <Stack spacing="16px" alignItems="center" justifyContent="center">
              <Image
                width="258px"
                height="38px"
                src={brands}
                alt="Imagem com cartões aceitos para compra(visa, mastercard, elo, american express e diners club)"
              />

              <Box
                display="flex"
                padding="3px"
                alignItems="center"
                justifyContent="center"
                gap="2px"
                width="170px"
                borderRadius="3px"
                background="#4BBE58"
              >
                <Image src={Verified} boxSize="14px" alt="compra verificada" />

                <Text
                  color="#FFF"
                  textAlign="center"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="normal"
                >
                  Compra 100% segura
                </Text>
              </Box>
            </Stack>
          </Stack>

          <Box display="flex" gap="2px" alignItems="center">
            <Text
              color="#202123"
              textAlign="center"
              fontSize="14px"
              fontWeight="400"
              lineHeight="normal"
            >
              Powered by{' '}
              <Text as="span" fontSize="14px" fontWeight="600">
                Proluno
              </Text>
            </Text>
            <Image src={Proluno} boxSize="14px" alt="compra verificada" />
          </Box>
        </Box>
      </CardFooter>
    </Card>
  );
}
