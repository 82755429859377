import { Box, Input, InputGroup, InputLeftAddon, Stack, Text, VStack } from '@chakra-ui/react';
import { FormOfPaymentType } from '../../../../../../../../validation/CoursePriceSchema';

type InstallmentsFee = {
  installment: number;
  amount: number;
  installmentAmount: number;
  fee: number;
  feeText: string;
  baseFeesCommissions: number;
};

type AmountInstallmentsProps = {
  data: InstallmentsFee[];
  loading: boolean;
  error: string;
  formOfPaymentType: FormOfPaymentType;
};

export default function AmountInstallments({
  data,
  loading,
  error,
  formOfPaymentType,
}: AmountInstallmentsProps) {
  if (!data) return <></>;

  if (loading) return <Text>Carregando...</Text>;

  if (error) return <Text>{error}</Text>;

  return (
    <Box marginTop={4}>
      <Text fontSize="md" fontWeight="medium">
        Plano de parcelamento
      </Text>
      <Text color="blackAlpha.500">
        Os valores informados abaixo foram baseados na bandeira de cartão com as taxas mais altas.
        Para saber o valor das taxas de cada bandeira <Text as="b">clique aqui.</Text>
      </Text>
      <Stack direction={{ base: 'column', md: 'row' }} marginTop={8}>
        <VStack flex={1} alignItems="flex-start" justifyContent="flex-end">
          <Text fontWeight="semibold" fontSize="xs">
            Preço e parcelas
          </Text>
          {data.map(item => {
            return (
              <InputGroup key={item.installment}>
                <InputLeftAddon children={`${item.installment}x R$`} />
                <Input
                  readOnly
                  name="name"
                  value={item.installmentAmount}
                  type="text"
                  focusBorderColor="primary.500"
                />
              </InputGroup>
            );
          })}
        </VStack>
        <VStack flex={1} alignItems="flex-start" justifyContent="flex-end">
          <Text fontWeight="semibold" fontSize="xs">
            Preço total do parcelamento
          </Text>
          {data.map(item => {
            return (
              <InputGroup>
                <InputLeftAddon children="R$" />
                <Input
                  readOnly
                  name="name"
                  value={item.amount}
                  type="text"
                  focusBorderColor="primary.500"
                />
              </InputGroup>
            );
          })}
        </VStack>
        <VStack flex={1} alignItems="flex-start" justifyContent="flex-end">
          <Text fontWeight="semibold" fontSize="xs">
            Preço base para tarifas e comissões
          </Text>
          {data.map((item, index) => {
            let value = item.baseFeesCommissions;

            if (index > 0 && formOfPaymentType === 'INSTALLMENTS_WITHOUT_FEE') {
              value = item.baseFeesCommissions;
            }

            return (
              <InputGroup key={item.installment}>
                <InputLeftAddon children="R$" />
                <Input
                  readOnly
                  name="name"
                  value={value}
                  type="text"
                  focusBorderColor="primary.500"
                />
              </InputGroup>
            );
          })}
        </VStack>
      </Stack>
    </Box>
  );
}
