export function getStatusColor(status: string) {
  return (
    {
      paid: '#22BB33',
      pending_payment: '#EDCA32',
      unpaid: '#E85347',
      canceled: '#BB2124',
    }[status] || '#E85347'
  );
}
