import styles from '../styles.module.css';

import logo from '../../../assets/images/logos/proluno_cor.png';

function PrivacyPolicy() {
  return (
    <div className="container">
      <div className={styles.policyHead}>
        <div className={styles.policyContentHead}>
          <img src={logo} alt="Logo proluno" />
          <h3>Política de Privacidade</h3>
        </div>
        <p>
          Proluno Esta política de privacidade se aplica a todos os produtos e serviços fornecidos
          pela PROLUNO TECNOLOGIA PARA EDUCACAO LTDA (CNPJ 24.641.922/0001-46).
        </p>
      </div>

      <div className={styles.content}>
        <ul>
          <h3>1. DAS CONSIDERAÇÕES PRELIMINARES:</h3>

          <li>
            <b>1.1</b> A presente política regerá o tratamento da privacidade e da proteção dos
            dados pessoais fornecidos à Proluno, prevalecendo sobre os demais documentos e termos da
            empresa que tratem do tema;
          </li>
          <li>
            <b>1.2</b> A utilização dos serviços digitais da Proluno necessita da aceitação desta
            Política de Privacidade. Em caso de não aceitação pelo usuário, ele deverá interromper
            imediatamente o uso dos serviços da empresa.
          </li>
          <li>
            <b>1.3</b> Esta política de privacidade poderá ser alterada, a qualquer momento, pela
            Proluno, independente do prévio consentimento dos usuários. Em caso de alteração da
            política, o usuário será notificado na sua dashboard, para manifestar o aceite dos novos
            termos.
          </li>
          <li>
            <b>1.4</b> A presente política disciplinará como serão tratados os dados pessoais pela
            Proluno, com quem os dados pessoais podem ser compartilhados e como os usuários podem
            gerenciar os seus dados pessoais.
          </li>
        </ul>
        <ul>
          <h3>2. DA FORMA DE TRATAMENTO DE DADOS:</h3>

          <li>
            <b>2.1</b> O dados pessoais e genéricos coletados são utilizados para a prestação dos
            serviços que a Proluno oferece aos usuários, sendo eventualmente compartilhado com
            plataformas parceiras, somente quando indispensáveis para a prestação dos serviços
            contratados.
          </li>
          <li>
            <b>2.2</b> Os dados pessoais dos usuários serão mantidos pelo tempo que for necessário
            para as respectivas finalidades e de forma legítima pela Proluno.
          </li>
          <li>
            <b>2.3</b> O usuário poderá excluir, a qualquer momento, a sua conta nos serviços
            digitais da Proluno, podendo, todavia, a empresa manter dados com finalidade legítima,
            quando exigidos por lei, regulamento, ou ainda para resguardar direitos próprios ou de
            terceiros.
          </li>
        </ul>
        <ul>
          <h3>3. DOS DADOS SOLICITADOS E MANTIDOS:</h3>

          <li>
            <b>3.1</b> Os dados pessoais solicitados e mantidos pela Proluno são os essenciais para
            a boa realização dos serviços a que se propõe, os quais são contratados voluntariamente.
          </li>
          <div className={styles.spacing}>
            <li>
              <b>3.1.1</b> Os dados solicitados para a compra de produtos na plataforma Proluno são:
              <ol>
                <li>Nome Completo;</li>
                <li>E-mail;</li>
                <li>Senha de cadastro na plataforma ou serviço;</li>
                <li>
                  Dados do cartão de crédito (nome do titular, CPF, endereço de cobrança, código de
                  segurança e data de validade);
                </li>
                <li>Informações adicionais voluntariamente adicionadas.</li>
              </ol>
            </li>
            <li>
              <b>3.1.2</b> Os dados solicitados para a compra de produtos na plataforma Proluno são:
              <ol>
                <li>Nome completo e de sócios, conforme o caso;</li>
                <li>Endereço completo, com CEP;</li>
                <li>E-mail comercial, pessoal e de sócios, conforme o caso;</li>
                <li>Senha;</li>
                <li>Telefones de contato;</li>
                <li> Cópias do CPF ou CNPJ, conforme o caso;</li>
                <li>Estado e País de residência; </li>
                <li>
                  Dados bancários para recebimento (contendo código de banco, tipo de conta, números
                  da agência e da conta bancária);
                </li>
                <li>Demais informações acrescidas voluntariamente.</li>
              </ol>
            </li>
            <li>
              <b>3.1.3</b> Para as atividades de suporte ou Customer Success, a Proluno pode
              solicitar informações adicionais, como:
              <ol>
                <li>
                  Telefones adicionais de parceiros ou clientes do instrutor ou empresa cadastrada;
                </li>
                <li>Comprovantes bancários;</li>
                <li>Ou ainda, outros documentos necessários para o atendimento.</li>
              </ol>
            </li>
            <li>
              <b>3.1.1</b> Os dados solicitados para a compra de produtos na plataforma Proluno são:
              <ol>
                <li>Nome Completo;</li>
                <li>E-mail;</li>
                <li>Senha de cadastro na plataforma ou serviço;</li>
                <li>
                  Dados do cartão de crédito (nome do titular, CPF, endereço de cobrança, código de
                  segurança e data de validade);
                </li>
                <li>Informações adicionais voluntariamente adicionadas.</li>
              </ol>
            </li>
            <li>
              <b>3.1.4</b> A Proluno também poderá solicitar informações nas suas ações de marketing
              e vendas, as quais serão disponibilizadas voluntariamente pelo usuário, tais como:
              <ol>
                <li>Nome completo;</li>
                <li>E-mail;</li>
                <li>Telefone;</li>
                <li>
                  Informações adicionais legítimas, e compatíveis com a finalidade do
                  relacionamento.
                </li>
              </ol>
            </li>
            <li>
              <b>3.2</b> A Proluno manterá os seguintes dados dos usuários dos seus serviços
              digitais:
              <ol>
                <li> Frequência de utilização da plataforma;</li>
                <li>Informações sobre o navegador e equipamentos de acesso;</li>
                <li>Dados da conexão;</li>
                <li>Histórico de navegação;</li>
                <li>Ações realizadas no ambiente da plataforma Proluno.</li>
              </ol>
            </li>
          </div>
          <li>
            <b>3.1</b> Os dados pessoais solicitados e mantidos pela Proluno são os essenciais para
            a boa realização dos serviços a que se propõe, os quais são contratados voluntariamente.
          </li>
        </ul>
        <ul>
          <h3>4. DA UTILIZAÇÃO DOS COOKIES:</h3>

          <li>
            <b>4.1</b> A Proluno utiliza cookies em seus serviços digitais, de forma a melhorar a
            experiência do usuário. Caso o usuário recuse a instalação dos cookies, algumas
            funcionalidades dos serviços fornecidos podem ficar inoperantes, ou em casos mais
            extremos, impossibilitar o uso do serviço.
          </li>
          <li>
            <b>4.2</b> Cookies é um pequeno arquivo de texto depositado no seu computador, com
            informações armazenadas sobre a sua utilização de determinada plataforma ou site,
            podendo conter ainda dados pessoais fornecidos voluntariamente por você.
          </li>
        </ul>
        <ul>
          <h3>5. DA SEGURANÇA DAS INFORMAÇÕES :</h3>

          <li>
            <b>5.1</b> Os dados pessoais coletados pela Proluno serão armazenados em bancos de dados
            seguros, sendo o acesso aos mesmos restritos e vedado o seu uso em desconformidade com a
            legislação aplicável.
          </li>
        </ul>
        <ul>
          <h3>6. CENTRAL DE DÚVIDAS :</h3>

          <li>
            <b>6.1</b> Caso tenha dúvidas sobre a Política de Privacidade da Proluno, você pode
            enviar um e-mail para <span className={styles.link}>suporte@proluno.com.br</span>.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
