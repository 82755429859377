import { Table, TableContainer, TableContainerProps, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { Plan } from '../types';
import PlanTableRow from './PlanTableRow';

interface PlanTableProps extends TableContainerProps {
  planList: Plan[];
}

export default function CheckoutSettingsTable({ planList, ...rest }: PlanTableProps) {
  return (
    <TableContainer {...rest}>
      <Table>
        <Thead>
          <Tr sx={{ th: { color: '#202123BF', whiteSpace: 'break-spaces' } }}>
            <Th maxWidth="120px">ID</Th>

            <Th maxWidth="156px">Nome</Th>

            <Th maxWidth="166px">Data de criação</Th>

            <Th maxWidth="148px">Recorrência</Th>

            <Th maxWidth="156px">Forma de pagamento</Th>

            <Th maxWidth="110px">Valor</Th>

            <Th maxWidth="124px">Status</Th>

            <Th maxWidth="95px" textAlign="center">
              Ações
            </Th>
          </Tr>
        </Thead>

        <Tbody sx={{ 'tr:nth-of-type(odd)': { background: '#2021230D' } }}>
          {planList?.map(plan => (
            <PlanTableRow key={plan.id} plan={plan} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
