import { axiosBackend } from './Configuration';

export default {
  store: async (courseId, question, questionAnswer) => {
    try {
      await axiosBackend().post(`/frequently-questions/${courseId}/course`, {
        question,
        questionAnswer,
      });
    } catch (error) {
      throw error;
    }
  },

  index: async courseId => {
    try {
      const { data: questions } = await axiosBackend().get(
        `/frequently-questions/${courseId}/course`
      );

      return questions.data;
    } catch (error) {
      throw error;
    }
  },

  update: async (courseId, questionId, question, questionAnswer) => {
    try {
      await axiosBackend().put(
        `/frequently-questions/${courseId}/course/${questionId}/frequently-question`,
        {
          question,
          questionAnswer,
        }
      );
    } catch (error) {
      throw error;
    }
  },

  delete: async (courseId, frequentlyQuestionsId) => {
    try {
      await axiosBackend().delete(
        `/frequently-questions/${courseId}/course/${frequentlyQuestionsId}/frequently-question`
      );
    } catch (error) {
      throw error;
    }
  },
};
