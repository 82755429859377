import { Box, Heading, Spinner, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FormEvent, useState } from 'react';
import useFetch from '../../../../hooks/useFetch';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import useWindowSize from '../../../../hooks/useWindowSize';
import CardView from './CardView';
import FilterStatistics from './FilterStatistics';
import ModalConfirmReset from './ModalConfirmReset';
import PerformanceView from './PerformanceView';
import TableView from './TableView';
import TabsStatistics from './TabsStatistics';

export interface IStatistics {
  name: string;
  correct: number;
  wrong: number;
  total: number;
  performance: number;
  questionId: number[];
}
interface IStatisticsResponse {
  data: IStatistics[];
  total: number;
  page: number;
  per_page?: number;
}

export default function StatisticsView() {
  const textColor = useColorModeValue('#202123', '#FFFFFF');
  const alternateTextColor = useColorModeValue('#20212390', '#AFAFAF');
  const { width } = useWindowSize();
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [filterCategories, setFilterCategories] = useState('area');
  const [currentPage, setCurrentPage] = useState(1);
  const [questionsIdsReset, setQuestionsIdsReset] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  function filterCategoriesBy(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        setFilterCategories('area');
        break;
      case 1:
        setFilterCategories('discipline');
        break;
      case 2:
        setFilterCategories('subject');
        break;
      case 3:
        setFilterCategories('institution');
        break;
    }
  }

  const queryParams = new URLSearchParams();

  const addQueryParam = (paramName: string, paramValue: string | undefined) => {
    if (paramValue) {
      queryParams.append(paramName, paramValue);
    }
  };

  addQueryParam('filterCategories', filterCategories);
  addQueryParam('startDate', startDate);
  addQueryParam('endDate', endDate);
  addQueryParam('hasPaginate', 'false');

  const searchParams = queryParams.toString();

  const {
    data,
    loading: isFetching,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<IStatisticsResponse>>({
    url: `/question-library-student/members-area/question-external/statistics?${searchParams}`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    data: { questionsLibraryIds: questionsIdsReset },
    url: '/question-library-student/members-area/question-external/reset-answers',
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Estatísticas zeradas com sucesso!',
      callback: () => {
        fetchData();
        onClose();
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  const response = data?.data;
  const statistics = response?.data;
  const total = response?.total || 0;
  const isLoading = isFetching || isSubmitting;
  const isSearching = !!searchParams;
  const isStatisticVisible = total > 0 && isSearching;
  const isNotFoundVisible = !isLoading && !statistics?.length && isSearching;

  const itemsPerPage = 5;
  const totalPages = Math.ceil(statistics?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems = statistics?.slice(0, endIndex);

  const hiddenLoadMore = displayedItems?.length === total;

  function handleLoadMore() {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <>
      <Heading
        fontSize={{ base: '2xl', lg: '4xl' }}
        marginY="18px"
        color={textColor}
        fontWeight="500"
      >
        Estatísticas
      </Heading>

      <Text color={alternateTextColor} fontSize="18px" fontWeight="400" lineHeight="normal">
        Veja seus resultados, acompanhe suas respostas e aprimore seus conhecimentos com análises
        detalhadas. Analise questões corretas, erros, total respondido e desempenho.
      </Text>

      <TabsStatistics filterCategoriesBy={filterCategoriesBy} />

      <FilterStatistics
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      {isLoading && (
        <Box width="full" textAlign="center">
          <Spinner size="lg" color="orange.500" />
        </Box>
      )}

      {isNotFoundVisible && (
        <Box width="full" textAlign="center">
          <Heading fontSize="md" color={textColor}>
            Nenhum resultado encontrado
          </Heading>
        </Box>
      )}

      {width > 700 && isStatisticVisible && (
        <TableView
          statistics={displayedItems}
          setQuestionsIdsReset={setQuestionsIdsReset}
          onOpen={onOpen}
        />
      )}

      {width <= 700 && isStatisticVisible && (
        <CardView
          statistics={displayedItems}
          setQuestionsIdsReset={setQuestionsIdsReset}
          onOpen={onOpen}
        />
      )}

      {isStatisticVisible && <PerformanceView statistics={displayedItems} />}

      <Text
        hidden={isLoading || isNotFoundVisible || hiddenLoadMore}
        color="#759AED"
        textAlign="center"
        fontFamily="Montserrat"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="600"
        lineHeight="28px"
        textDecorationLine="underline"
        paddingBottom="10px"
        cursor="pointer"
        onClick={handleLoadMore}
      >
        Carregar mais
      </Text>

      <ModalConfirmReset isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} />
    </>
  );
}
