import {
  Button,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { HiDotsHorizontal, HiOutlineSearch } from 'react-icons/hi';
import { IoMdAlert } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';
import { AffiliationProductsType } from '..';
import Pagination from '../../../../components/Pagination';
import Toast from '../../../../components/Toast';
import { ITheme, useTheme } from '../../../../contexts/ThemeContext';
import useFetch from '../../../../hooks/useFetch';
import useQuery from '../../../../hooks/useQuery';
import useWindowSize from '../../../../hooks/useWindowSize';
import CardList from './components/CardList';
import Table from './components/Table';

const LARGE_SCREEN_SIZE = 750;

export type ProductsAffiliate = {
  affiliationId: number;
  courseAffiliationId: number;
  thumbnail: string;
  productName: string;
  productValue: string;
  commissionValue: string;
  amountOfSales: number;
  winnings: number;
};

export type ParamsType = {
  themeMarketplace: ITheme;
  affiliateProducts: ProductsAffiliate[];
  setAffiliationId: (affiliationId: string) => void;
  setCourseAffiliationName: (productName: string) => void;
  onOpenRemoveProduct: () => void;
};

export function ActionButton({
  courseAffiliationId,
  affiliationId,
  productName,
  setAffiliationId,
  setCourseAffiliationName,
  onOpenRemoveProduct,
}) {
  return (
    <Menu size="sm" arrowPadding={0}>
      <MenuButton
        w="27px"
        h="24px"
        borderRadius={5}
        bgColor="#20212310"
        _hover={{ bgColor: '#20212350' }}
        _active={{ bgColor: '#20212350' }}
        style={{ boxShadow: 'none', outline: 'none' }}
      >
        <Stack justifyContent="center" alignItems="center">
          <HiDotsHorizontal size="18px" color="#20212350" />
        </Stack>
      </MenuButton>
      <MenuList>
        <MenuItem
          as={Link}
          to={`/affiliations/my-affiliations/${courseAffiliationId}`}
          fontWeight={500}
          color="#20212380"
          style={{ boxShadow: 'none', outline: 'none' }}
        >
          Detalhamento do produto
        </MenuItem>

        <MenuItem
          fontWeight={500}
          color="#BB2124"
          style={{ boxShadow: 'none', outline: 'none' }}
          onClick={() => {
            setAffiliationId(affiliationId);
            setCourseAffiliationName(productName);
            onOpenRemoveProduct();
          }}
        >
          Remover produto das afiliações
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default function MyAffiliations() {
  const { themeMarketplace } = useTheme();
  const { width } = useWindowSize();
  const history = useHistory();
  const query = useQuery();
  const [affiliateProducts, setAffiliateProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const [timer, setTimer] = useState(0);
  const [affiliationId, setAffiliationId] = useState('');
  const [courseAffiliationName, setCourseAffiliationName] = useState('');

  const sizeRemoveAffiliateProductModal =
    width > 1400 ? 'xl' : width > 800 ? 'lg' : width > 500 ? 'md' : 'xs';

  const {
    isOpen: isOpenRemoveAffiliate,
    onOpen: onOpenRemoveAffiliate,
    onClose: onCloseRemoveAffiliate,
  } = useDisclosure();

  const { data, loading, fetchData } = useFetch<
    UnificadaFront.ResponseJSON<AffiliationProductsType>
  >({
    method: 'get',
    url: `/affiliations/products/my-affiliations?page=${currentPage}&per_page=${10}${
      search ? `&search=${search}` : ''
    }`,
    authenticated: true,
    autoFetch: false,
  });

  const {
    loading: isLoadingDeleteAffiliate,
    data: dataDeleteAffiliate,
    fetchData: disaffiliate,
  } = useFetch<UnificadaFront.ResponseJSON>({
    method: 'delete',
    url: `/affiliations/affiliates/${affiliationId}/disaffiliate`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    let page = query.get('page');

    if (page) {
      try {
        if (Number(page) < 1) {
          return history.push(`/affiliations/my-affiliations?page=1`);
        }

        setCurrentPage(Number(page));
      } catch (error) {
        history.push(`/affiliations/my-affiliations?page=1`);
      }
    }
  }, [history, query]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        fetchData();

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
  }, [currentPage, fetchData, timer]);

  useEffect(() => {
    if (data?.data) {
      setAffiliateProducts(data?.data?.data);
      setPageCount(data?.data?.total / data?.data?.per_page);
    }
  }, [data?.data]);

  useEffect(() => {
    if (dataDeleteAffiliate) {
      Toast('Afiliação removida com sucesso!', 'success');
    }
  }, [dataDeleteAffiliate]);

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearch(value);
    setTimer(2);
  }

  function handlePageChange({ selected: selectedPage }) {
    history.push(`/affiliations/my-affiliations?page=${selectedPage + 1}`);
  }

  function updateAfterRemoveAffiliate() {
    const newArrayAffiliateProduct = affiliateProducts.filter(
      affiliateProduct => affiliateProduct.affiliationId !== parseInt(affiliationId)
    );

    if (newArrayAffiliateProduct) {
      setAffiliateProducts([...newArrayAffiliateProduct]);
    }
  }

  return (
    <>
      <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} justifyContent="space-between">
        <Stack direction="column" spacing={5}>
          <Heading fontSize="36px" fontWeight={600}>
            Minhas afiliações
          </Heading>
          <Text fontSize="18px" color="#20212350">
            Gerencie todas as suas afiliações. Clique em um dos produtos para ver seus detalhes ou
            para acessar o seu link de afiliado.
          </Text>
        </Stack>
      </Stack>
      <Stack direction="row" w="100%" my={5} justifyContent="start">
        <InputGroup w={{ base: '100%', md: '320px', lg: '320px' }} size="sm">
          <InputLeftElement pointerEvents="none" children={<HiOutlineSearch color="gray" />} />
          <Input
            type="text"
            value={search}
            onChange={handleChangeSearch}
            placeholder="Buscar produto"
            borderRadius={6}
            focusBorderColor="#EB7129"
          />
        </InputGroup>
      </Stack>

      {loading && (
        <Stack w="100%" justifyContent="center" alignItems="center" my={2}>
          <Spinner size="lg" color="#EB7129" />
        </Stack>
      )}

      {!loading && !affiliateProducts.length && (
        <Stack w="100%" justifyContent="center" alignItems="center" my={2}>
          <Heading fontSize="md" fontWeight={400}>
            Você não possui produtos afiliados.
          </Heading>
        </Stack>
      )}

      {width <= LARGE_SCREEN_SIZE && affiliateProducts.length > 0 && (
        <CardList
          affiliateProducts={affiliateProducts}
          themeMarketplace={themeMarketplace}
          onOpenRemoveProduct={onOpenRemoveAffiliate}
          setAffiliationId={setAffiliationId}
          setCourseAffiliationName={setCourseAffiliationName}
        />
      )}

      {width > LARGE_SCREEN_SIZE && affiliateProducts.length > 0 && (
        <Table
          affiliateProducts={affiliateProducts}
          themeMarketplace={themeMarketplace}
          onOpenRemoveProduct={onOpenRemoveAffiliate}
          setAffiliationId={setAffiliationId}
          setCourseAffiliationName={setCourseAffiliationName}
        />
      )}

      {affiliateProducts?.length !== 0 && (
        <Stack w="100%" justifyContent="center" alignItems="center" my={2}>
          <Pagination
            pageCount={pageCount}
            onPageChange={handlePageChange}
            onPageActive={currentPage - 1}
          />
        </Stack>
      )}

      <Modal
        isOpen={isOpenRemoveAffiliate}
        onClose={onCloseRemoveAffiliate}
        size={sizeRemoveAffiliateProductModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <Stack direction="row" w="100%" alignItems="center" justifyContent="center" p={5}>
            <IoMdAlert color="#BB2124" size="50px" />
          </Stack>
          <ModalHeader textAlign="center" fontWeight={500}>
            Você deseja remover o produto {courseAffiliationName} dos seus produtos para afiliação?
          </ModalHeader>
          <ModalCloseButton style={{ boxShadow: 'none', outline: 'none' }} />
          <ModalFooter>
            <Stack direction="row" w="100%" alignItems="center" justifyContent="center" spacing={3}>
              <Button
                size="sm"
                w={150}
                colorScheme="gray"
                variant="solid"
                onClick={onCloseRemoveAffiliate}
              >
                Cancelar
              </Button>
              <Button
                size="sm"
                w={150}
                onClick={() => {
                  disaffiliate();
                  onCloseRemoveAffiliate();
                  updateAfterRemoveAffiliate();
                }}
                isLoading={isLoadingDeleteAffiliate}
                isDisabled={isLoadingDeleteAffiliate}
                bgColor="#BB2124"
                color="#FFFFFF"
                _hover={{ bgColor: '#BB212480' }}
                _active={{ bgColor: '#BB212480' }}
                variant="solid"
              >
                Remover
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
