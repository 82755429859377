import { AnchorHTMLAttributes } from 'react';
import { Container, Content } from './styles';

import videoYoutube from '../../../../../assets/icons/videoYoutube.svg';

type ITooltipProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  link: string;
};

const Tooltip = ({ link }: ITooltipProps) => {
  return (
    <Container href={link} target="_blank" rel="noopener noreferrer">
      <img src={videoYoutube} alt="Ícone clicavel para redirecionamento ao YouTube" />

      <Content>
        <span>Tutorial</span>
      </Content>
    </Container>
  );
};

export default Tooltip;
