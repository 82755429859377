import { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useTheme } from '../../../contexts/ThemeContext';
import { Link } from 'react-router-dom';

import {
  Box,
  Center,
  Container,
  HStack,
  IconButton,
  Image,
  Spinner,
  Text,
  Link as ChakraLink,
} from '@chakra-ui/react';

import {
  MdAdd as AddIcon,
  MdKeyboardArrowLeft as ArrowLeftIcon,
  MdKeyboardArrowRight as ArrowRightIcon,
  MdRemove as MinusIcon,
} from 'react-icons/md';

export default function Policies() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  const { themeMarketplace: theme, isLoadingThemeMarketplace: isLoading } = useTheme();

  const { logo } = theme;
  const { themeSections } = theme;
  const footerSection = themeSections?.find(section => section.name === 'footer')?.themeFooter;
  const term = footerSection?.termsAndPoliciesLink;
  const iconColor = '#323232';
  const textColor = '#0000005C';
  const containerRef = useRef(null);
  const pageWidth = containerRef.current?.clientWidth;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function handleZoomIn() {
    setScale(prevScale => Math.min(prevScale + 0.1, 2)); // Limita zoom máximo em 2x
  }

  function handleZoomOut() {
    setScale(prevScale => Math.max(prevScale - 0.1, 1)); // Limita zoom mínimo em 1x
  }

  function handlePrevPage() {
    setScale(1);
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  }

  function handleNextPage() {
    setScale(1);
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
  }

  return (
    <Container
      ref={containerRef}
      maxWidth="1440px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      bg="gray.50"
      overflow="hidden"
      height="100vh"
      py={4}
      px={{ base: 4, md: 8 }}
    >
      {isLoading ? (
        <Center h="480px" w="full">
          <Spinner size="xl" color="secondary.500" />
        </Center>
      ) : (
        <>
          <ChakraLink as={Link} to="/">
            <Image src={logo} boxSize={{ base: '80px', md: '200px' }} objectFit="contain" />
          </ChakraLink>

          <HStack
            w="full"
            px={{ base: 2, xl: 5 }}
            py={3}
            boxShadow="0px 0px 10px 0px #0000001A"
            borderRadius="base"
            justify="space-between"
            position="relative"
            spacing={0}
            bg="#ffffff"
          >
            <HStack spacing={{ base: 0.25, xl: 2 }} bg="#ffffff">
              <IconButton
                aria-label="Diminuir zoom"
                onClick={handleZoomOut}
                icon={<MinusIcon size={8.75} />}
                size="xs"
                variant="ghost"
                color={iconColor}
                isDisabled={scale === 1}
              />

              <Box
                border="1px solid"
                borderColor="#E2E8F0"
                px={4}
                py="2px"
                borderRadius="base"
                color={textColor}
                fontSize={{ base: '0.6875rem', xl: '0.8125rem' }}
                w="full"
              >
                {(scale * 100).toFixed(0)}%
              </Box>

              <IconButton
                aria-label="Aumentar zoom"
                onClick={handleZoomIn}
                icon={<AddIcon size={15} />}
                size="xs"
                variant="ghost"
                color={iconColor}
                isDisabled={scale === 2}
              />
            </HStack>

            <HStack
              justify="center"
              my={4}
              spacing={{ base: 0.5, xl: 4 }}
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
            >
              <IconButton
                aria-label="Página anterior"
                onClick={handlePrevPage}
                icon={<ArrowLeftIcon size={26} />}
                size="xs"
                variant="ghost"
                color={iconColor}
              />

              {/* Mobile */}
              <Text fontSize="xs" display={{ base: 'block', xl: 'none' }}>
                {pageNumber} de {numPages}
              </Text>

              {/* Desktop */}
              <Text fontSize="xs" display={{ base: 'none', xl: 'block' }}>
                Página {pageNumber} de {numPages}
              </Text>

              <IconButton
                aria-label="Próxima página"
                onClick={handleNextPage}
                icon={<ArrowRightIcon size={26} />}
                size="xs"
                variant="ghost"
                color={iconColor}
              />
            </HStack>
          </HStack>

          <Box
            mt={2}
            px={{ base: 2, xl: 5 }}
            py={{ base: '3px', xl: 2 }}
            boxShadow="0px 0px 10px 0px #0000001A"
            borderRadius="base"
            bg="#ffffff"
            overflow="auto"
            maxWidth="100%"
            height="full"
            sx={{
              canvas: { maxWidth: 'none !important' },
              '.react-pdf__Document': {
                minHeight: 'unset',
              },
              '::-webkit-scrollbar': {
                width: { base: '0.5rem', md: '0.75rem' },
                height: { base: '0.5rem', md: '0.75rem' },
                background: 'transparent',
              },
              '::-webkit-scrollbar-thumb': {
                paddingRight: '0.5rem',
                paddingLeft: '0.5rem',
                borderRadius: 'md',
                backgroundColor: 'gray.300',
              },
              '::-webkit-scrollbar-thumb:hover': {
                bg: 'gray.400',
              },
            }}
          >
            <Document
              file={term}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <Center h="480px" w="full">
                  <Spinner size="xl" color="secondary.500" />
                </Center>
              }
            >
              <Page pageNumber={pageNumber} width={pageWidth} scale={scale} />
            </Document>
          </Box>
        </>
      )}
    </Container>
  );
}
