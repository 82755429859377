import dayjs from 'dayjs';
import { createContext, useContext, useState, useCallback, useEffect } from 'react';
import VideoLibraryAPI from '../api/VideoLibrary';
import { axiosBackend } from '../api/Configuration';
import ErrorResponse from '../helpers/ErrorResponse';
import { hostname, platformsWithoutVideoLibrary } from '../App';
import { useHistory } from 'react-router-dom';
import Toast from '../components/Toast';

const VideoLibraryContext = createContext();

function ordenarVideos(videoA, videoB) {
  return dayjs(videoA.uploadAt).isAfter(dayjs(videoB.uploadAt))
    ? -1
    : dayjs(videoA.uploadAt).isBefore(dayjs(videoB.uploadAt))
    ? 1
    : 0;
}

export default function VideoLibraryProvider({ children }) {
  const [isLoadingVideoLibrary, setIsLoadingVideoLibrary] = useState(false);
  const [videoLibrary, setVideoLibrary] = useState([]);
  const [messageVideoLibrary, setMessageVideoLibrary] = useState('');

  const [processUpload, setProcessUpload] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(0);

  const history = useHistory();

  useEffect(() => {
    if (platformsWithoutVideoLibrary.includes(hostname)) {
      history.push('/user-without-access');
      return;
    }
  }, [history]);

  const getVideos = useCallback(async ({ page = 1, per_page = 10 }) => {
    try {
      setIsLoadingVideoLibrary(true);
      const { data: videoLibraryData } = await VideoLibraryAPI.index({ page, per_page });
      if (videoLibraryData) {
        setVideoLibrary(videoLibraryData.videos);
        setMessageVideoLibrary('');
        setTotal(videoLibraryData.total);
        setLimit(videoLibraryData.limit);
      }
    } catch (error) {
      setMessageVideoLibrary(ErrorResponse(error));
    } finally {
      setIsLoadingVideoLibrary(false);
    }
  }, []);

  function onUploadProgress(event) {
    const progress = parseInt(Math.round((event.loaded * 100) / event.total));

    setProcessUpload(progress);
  }

  async function createVideoLibrary(file) {
    const data = new FormData();

    data.append('video', file);

    setProcessUpload(0);
    setUploadComplete(false);

    axiosBackend()
      .post('/video-libraries', data, {
        onUploadProgress,
      })
      .then(({ data: response }) => {
        const video = response.data;

        onCreatedVideoLibrary({
          ...video,
          transcode: 'loading',
          uploadAt: video.createdAt,
        });

        setUploadComplete(true);
        return response;
      })
      .catch(error => {
        setProcessUpload(0);
        setUploadComplete(false);
        setIsLoadingVideoLibrary(false);
        throw error;
      })
      .finally(() => {
        setProcessUpload(0);
        setIsLoadingVideoLibrary(false);
        getVideos({ page: 1 });
      });
  }

  function onCreatedVideoLibrary(video) {
    const newVideoLibrary = [...videoLibrary, video];
    setVideoLibrary(newVideoLibrary.sort(ordenarVideos));
  }

  async function editVideoLibrary(id, videoData, thumbnail) {
    try {
      await VideoLibraryAPI.patch(id, videoData);
      onUpdatedVideoLibrary(videoData, thumbnail);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoadingVideoLibrary(false);
    }
  }

  function onUpdatedVideoLibrary(updatedVideoLibrary, thumbnail) {
    const copiedVideoLibrary = [...videoLibrary];

    for (const videoLibrary of copiedVideoLibrary) {
      if (videoLibrary.id === updatedVideoLibrary.id) {
        videoLibrary.name = updatedVideoLibrary.name;
        videoLibrary.thumbnail = thumbnail;
        videoLibrary.archive = updatedVideoLibrary.archive;
        videoLibrary.download = updatedVideoLibrary.download;
        break;
      }
    }
  }

  async function deleteVideoLibrary(id) {
    try {
      await VideoLibraryAPI.delete(id);
      onDeletedVideoLibrary(id);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingVideoLibrary(false);
    }
  }

  function onDeletedVideoLibrary(deletedVideoLibraryId) {
    const newVideoLibrary = videoLibrary.filter(
      videoLibrary => videoLibrary.id !== parseInt(deletedVideoLibraryId)
    );

    setVideoLibrary(newVideoLibrary);
  }

  return (
    <VideoLibraryContext.Provider
      value={{
        getVideos,
        total,
        limit,
        videoLibrary,
        processUpload,
        uploadComplete,
        createVideoLibrary,
        editVideoLibrary,
        deleteVideoLibrary,
        messageVideoLibrary,
        isLoadingVideoLibrary,
      }}
    >
      {children}
    </VideoLibraryContext.Provider>
  );
}

export function useVideoLibrary() {
  const context = useContext(VideoLibraryContext);

  if (!context)
    throw new Error('useVideoLibrary deve ser usado dentro de um VideoLibraryProvider.');

  return {
    getVideos: context.getVideos,
    total: context.total,
    limit: context.limit,
    videoLibrary: context.videoLibrary,
    processUpload: context.processUpload,
    uploadComplete: context.uploadComplete,
    createVideoLibrary: context.createVideoLibrary,
    editVideoLibrary: context.editVideoLibrary,
    deleteVideoLibrary: context.deleteVideoLibrary,
    messageVideoLibrary: context.messageVideoLibrary,
    isLoadingVideoLibrary: context.isLoadingVideoLibrary,
  };
}
