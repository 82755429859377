import { Icon, IconProps } from '@chakra-ui/react';

export default function AlarmClockIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M7.31396 4.64073H6.37113V8.41204L9.35675 10.2034L9.82817 9.43029L7.31396 7.94063V4.64073ZM10.3543 0.75L13.25 3.16678L12.4455 4.13161L9.54783 1.71608L10.3543 0.75ZM3.64511 0.75L4.45091 1.71545L1.55455 4.13161L0.75 3.16615L3.64511 0.75ZM6.99969 2.12653C3.87579 2.12653 1.34272 4.65959 1.34272 7.78349C1.34272 10.9074 3.87579 13.4405 6.99969 13.4405C10.1236 13.4405 12.6566 10.9074 12.6566 7.78349C12.6566 4.65959 10.1236 2.12653 6.99969 2.12653ZM6.99969 12.1833C4.57348 12.1833 2.59983 10.2097 2.59983 7.78349C2.59983 5.35728 4.57348 3.38363 6.99969 3.38363C9.42589 3.38363 11.3995 5.35728 11.3995 7.78349C11.3995 10.2097 9.42589 12.1833 6.99969 12.1833Z"
        fill="currentColor"
      />
    </Icon>
  );
}
