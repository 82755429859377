import { Select, SelectProps } from '@chakra-ui/react';

const options = [
  { label: 'Trial', value: 'trialing' },
  { label: 'Pago', value: 'paid' },
  { label: 'Pendente de pagamento', value: 'pending_payment' },
  { label: 'Não pago', value: 'unpaid' },
  { label: 'Cancelado', value: 'canceled' },
  { label: 'Finalizados', value: 'ended' },
];

interface StatusSelectProps extends SelectProps {}

export default function StatusSelect({ ...rest }: StatusSelectProps) {
  return (
    <Select
      flexShrink={0}
      maxWidth={{ base: 'unset', xl: '14rem' }}
      size="sm"
      borderRadius="md"
      focusBorderColor="gray.300"
      color="#20212380"
      {...rest}
    >
      <option value="">Todos</option>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}
