import { Box, Button, Link as ChakraLink, Stack, Text, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Heading } from '../../../../../../../components/Heading';
import template from '../assets/template.png';
import templateFront from '../assets/template_front.png';
import templateBack from '../assets/template_back.png';
import SelectTemplateImage from './SelectTemplateImage';
import TemplateImagemButton from './TemplateImageButton';

function TemplateImage({ name, preview, onDrop, to }) {
  return (
    <VStack
      width="100%"
      alignItems="flex-start"
      marginTop="2rem !important"
      marginBottom="2rem !important"
    >
      <Heading fontSize="lg" fontWeight="500" marginBottom="2">
        {name}
      </Heading>

      <Box
        display="flex"
        flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
        alignItems="flex-start"
        gap="5"
      >
        <Stack minWidth="472px" minHeight="333px">
          {preview ? (
            <Box
              minWidth="472px"
              minHeight="333px"
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
              backgroundImage={`url("${preview}")`}
            />
          ) : (
            <SelectTemplateImage onDrop={onDrop} isDisabled={false} active={true} />
          )}
        </Stack>

        <Stack width="100%">
          <Box display="flex" flexDirection="column" gap="4">
            <Text fontSize="sm" fontWeight="normal" lineHeight="5" color="rgba(32, 33, 35, 0.5)">
              Adicione uma imagem de fundo ao seu certificado.{' '}
              <Text as="span" hidden={name === 'Verso'}>
                Lembre-se de respeitar o espaço ocupado,
              </Text>{' '}
              <ChakraLink
                href={
                  name === 'Imagem frente'
                    ? templateFront
                    : name === 'Verso'
                    ? templateBack
                    : template
                }
                target="_blank"
                rel="noopener noreferrer"
                color="dark.500"
                textDecoration="underline"
              >
                Clique aqui
              </ChakraLink>{' '}
              e baixe a referência.
            </Text>
            <Text
              fontSize="sm"
              fontWeight="normal"
              lineHeight="5"
              color="rgba(32, 33, 35, 0.5)"
              hidden={name === 'Verso'}
            >
              *Não é possível aterar o posicionamento das informações.
            </Text>
            <Text fontSize="sm" fontWeight="normal" lineHeight="5" color="rgba(32, 33, 35, 0.5)">
              *Siga a dimensão de{' '}
              <Text color="dark.500" display="inline">
                1120 x 780
              </Text>{' '}
              do template para o melhor aproveitamente do certificado.
            </Text>
            <Text fontSize="sm" fontWeight="normal" lineHeight="5" color="rgba(32, 33, 35, 0.5)">
              Clique no botão “Visualizar certificado” para ver um certificado de teste ou no botão
              “Adicionar template” para inserir um novo template.
            </Text>
          </Box>
          <Box
            marginTop="1.5rem !important"
            display="flex"
            flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
            gap="3"
          >
            <Button
              as={Link}
              to={to}
              size="sm"
              colorScheme="primary"
              variant="outline"
              fontSize="xs"
              lineHeight="4"
              isDisabled={!preview}
              target="_blank"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              Visualizar certificado
            </Button>

            <TemplateImagemButton preview={preview} onDrop={onDrop} />
          </Box>
        </Stack>
      </Box>
    </VStack>
  );
}

export default TemplateImage;
