import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChangeEvent, CSSProperties, useEffect, useState } from 'react';
import {
  FiCheck as CheckIcon,
  FiChevronDown as ChevronDownIcon,
  FiEdit as EditIcon,
} from 'react-icons/fi';
import { GoTriangleDown as TriangleDownIcon, GoTriangleUp as TriangleUpIcon } from 'react-icons/go';
import { useHistory, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import ModuleAPI from '../../../../../../api/Module';
import UserAPI from '../../../../../../api/User';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';

const hightLightStyle: CSSProperties = { color: '#EB7129', fontWeight: 'bold' };

type InstructorsType = {
  id: number;
  fullName: string;
};

type ModuleParams = {
  id: string;
  moduleId: string;
};

type ModuleType = {
  id?: number;
  instructor?: { id: number; fullName: number };
  name?: string;
  order?: number;
  startDateAccess?: string | null;
  status?: string;
  timeToAccess?: number;
};

type ModuleDataType = {
  id?: number;
  instructor?: number;
  name: string;
  startDateAccess?: string;
  status?: string;
  timeToAccess?: number;
};

type ModuleStatusParams = { status: string };

type GotejamentoType = 'default' | 'timeToAccess' | 'startDateAccess';

function ModuleStatus({ status }: ModuleStatusParams) {
  const statusParams = {
    color: '#A0AEC0',
    text: '-',
  };

  if (status === 'EM_EDICAO') {
    statusParams.color = '#EDCA32';
    statusParams.text = 'Rascunho';
  } else if (status === 'ATIVO') {
    statusParams.color = '#22BB33';
    statusParams.text = 'Publicado';
  } else {
    statusParams.text = status;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius="full"
      height="1rem"
      p="0.8rem"
      bg={statusParams.color}
      color="white"
      fontSize="md"
    >
      {statusParams.text}
    </Box>
  );
}

const moduleInitialState: ModuleDataType = {
  name: '',
  status: 'EM_EDICAO',
};

export default function ModuleForm({ onGetModules }: { onGetModules: () => void }) {
  const { getModules, course, setIsAddingModule, setCourse } = useCourse();

  const { id, moduleId } = useParams<ModuleParams>();

  const [moduleData, setModuleData] = useState<ModuleDataType>(moduleInitialState);

  const [tempModuleData, setTempModuleData] = useState<ModuleDataType>(moduleData);

  const [onChanged, setOnChanged] = useState(false);

  const [loading, setLoading] = useState(false);

  const [loadingInstructors, setLoadingInstructors] = useState(true);

  const [instructors, setInstructors] = useState<InstructorsType[]>([]);

  const [gotejamento, setGotejamento] = useState<GotejamentoType>('default');

  const [tempGotejamento, setTempGotejamento] = useState<GotejamentoType>(gotejamento);

  const [timeToAccess, setTimeToAccess] = useState<number>(0);

  function numberIncrementStepper() {
    setTimeToAccess(timeToAccess + 1);
  }
  function numberDecrementStepper() {
    setTimeToAccess(timeToAccess - 1);
  }

  const history = useHistory();

  useEffect(() => {
    if (moduleId) {
      setLoading(true);

      const currentModule: ModuleType = getModules(moduleId);

      if (currentModule?.startDateAccess) {
        setGotejamento('startDateAccess');
        setTempGotejamento('startDateAccess');
      } else if (currentModule?.timeToAccess > 0) {
        setGotejamento('timeToAccess');
        setTempGotejamento('timeToAccess');
      } else {
        setGotejamento('default');
        setTempGotejamento('default');
      }

      const currentModuleData: ModuleDataType = {
        name: currentModule?.name,
        status: currentModule?.status,
        instructor: currentModule?.instructor?.id,
        startDateAccess:
          currentModule?.startDateAccess &&
          dayjs(currentModule.startDateAccess).add(1, 'day').format('YYYY-MM-DD'),
        timeToAccess: currentModule?.timeToAccess,
      };

      setTimeToAccess(currentModule?.timeToAccess);

      setModuleData(currentModuleData);
      setTempModuleData(currentModuleData);
      setLoading(false);
    } else {
      setModuleData(moduleInitialState);
    }
  }, [getModules, moduleId]);

  useEffect(() => {
    async function getInstructors() {
      try {
        const { data } = await UserAPI.findInstructorsToModule();

        setInstructors(data);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setLoadingInstructors(false);
      }
    }

    getInstructors();
  }, []);

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    if (moduleId) {
      setOnChanged(true);
    }

    setModuleData({ ...moduleData, [name]: value });
  }

  function RadioHandleChange(value: GotejamentoType) {
    setGotejamento(value);

    if (moduleId) {
      setOnChanged(true);
    }
  }

  function inputNumberHandleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    const timeToAccess = Number(value);

    if (moduleId) {
      setOnChanged(true);
    }

    setTimeToAccess(timeToAccess);
  }

  async function handleSubmit(
    event: React.MouseEvent<HTMLButtonElement> & {
      target: { dataset: { status: string } };
    }
  ) {
    setLoading(true);

    const {
      dataset: { status },
    } = event.target;

    moduleData.status = status;

    if (gotejamento === 'timeToAccess') {
      moduleData.timeToAccess = timeToAccess;
      moduleData.startDateAccess = '';
    } else if (gotejamento === 'startDateAccess') {
      moduleData.timeToAccess = null;
    } else {
      moduleData.startDateAccess = '';
      moduleData.timeToAccess = null;
    }

    if (!moduleData.name) moduleData.name = 'Novo módulo';

    try {
      setLoading(true);

      if (moduleId) {
        const { data } = await ModuleAPI.update(id, moduleId, moduleData);

        const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

        const currentInstructor = moduleData.instructor
          ? instructors.find(
              instructor => instructor.id === parseInt(moduleData.instructor.toString())
            )
          : null;

        const moduleUpdated = {
          ...currentModule,
          name: data.name,
          timeToAccess: data.timeToAccess,
          startDateAccess: data.startDateAccess,
          status: data.status,
          instructor: currentInstructor,
        };

        const newModules = course.modules.map(module =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({
          ...course,
          modules: newModules,
        });
        Toast('Conteúdo atualizado com sucesso');

        return;
      }

      const { data } = await ModuleAPI.store(id, moduleData);

      const currentInstructor = moduleData.instructor
        ? instructors.find(
            instructor => instructor.id === parseInt(moduleData.instructor.toString())
          )
        : null;

      const newModule = {
        ...data,
        instructor: currentInstructor,
        contents: [],
      };

      const modules = course.modules;
      modules.pop();

      modules.push(newModule);

      setCourse({
        ...course,
        modules,
      });

      setIsAddingModule(false);

      Toast('Conteúdo criado com sucesso');

      history.push(`/courses/${id}/course-manager/course/modules/${newModule.id}/edit`);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function handleDiscard() {
    if (moduleId) {
      setOnChanged(false);
    }

    setModuleData(tempModuleData);
    setGotejamento(tempGotejamento);
  }

  async function handleDelete() {
    try {
      setLoading(true);

      await ModuleAPI.delete(id, moduleId);

      const newModules = course.modules.filter(module => module.id !== parseInt(moduleId));

      const courseUpdated = {
        ...course,
        modules: newModules,
      };

      setCourse(courseUpdated);

      Toast('Conteúdo excluído com sucesso');

      history.push(`/courses/${id}/course-manager/course`);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  function handleCancel() {
    course.modules.pop();

    setIsAddingModule(false);

    history.push(`/courses/${id}/course-manager/course`);
  }

  return (
    <Box px="10" mb="10">
      <Heading fontWeight="light">
        {moduleId ? 'Editar:' : 'Novo módulo:'} {moduleData?.name}
      </Heading>
      <Box
        shadow="lg"
        borderColor="rgba(32, 33, 35, 0.5)"
        borderWidth="thin"
        borderRadius="lg"
        marginTop="5"
        px="5"
        pb="5"
        w="full"
      >
        <Stack spacing={5}>
          <Stack>
            <Flex justifyContent="space-between" alignItems="baseline" mt="5">
              <Heading size="md">Título do módulo:</Heading>
              <ModuleStatus status={moduleData?.status} />
            </Flex>

            <Text fontSize="md">
              Aqui você irá <span style={hightLightStyle}>definir o título do seu módulo.</span>
            </Text>
            <Input
              name="name"
              borderRadius="none"
              borderColor="gray"
              _focus={{ borderColor: '#d55411', borderWidth: '2px' }}
              colorScheme="primary"
              placeholder="Meu módulo"
              value={moduleData?.name}
              onChange={handleChange}
            />
          </Stack>
          <Stack>
            <Heading size="md">Responsável pelo módulo:</Heading>
            <Text fontSize="md">
              Você pode{' '}
              <span style={hightLightStyle}>
                definir um usuário como responsável por esse módulo
              </span>
              , dessa forma o ele poderá adicionar conteúdos e até mesmo realizar edições no módulo.
            </Text>
            <Stack
              direction="row"
              display="flex"
              alignItems="baseline"
              flexWrap={{ base: 'wrap', md: 'nowrap', lg: 'nowrap' }}
            >
              <Select
                name="instructor"
                onChange={handleChange}
                placeholder="Sem responsável"
                borderColor="gray"
                borderRadius="none"
                _focus={{ borderColor: '#d55411', borderWidth: '2px' }}
                disabled={loadingInstructors}
                value={moduleData?.instructor}
              >
                {instructors.length
                  ? instructors.map(instructor => (
                      <option key={instructor.id} value={instructor.id}>
                        {instructor.fullName}
                      </option>
                    ))
                  : ''}
              </Select>
            </Stack>
          </Stack>
          <Stack>
            <Heading size="md">Gotejamento de conteúdo:</Heading>
            <Text fontSize="md">Determine a forma que seu módulo será lançado.</Text>
            <Stack marginTop="5">
              <RadioGroup
                value={gotejamento}
                colorScheme="primary"
                name="gotejamento"
                onChange={RadioHandleChange}
              >
                <Stack fontWeight="bold" display="flex" justifyContent="space-between" spacing={4}>
                  <Stack h="full">
                    <Radio value="default">Padrão</Radio>
                    <Text fontWeight="normal">Os alunos terão acesso irrestrito.</Text>
                  </Stack>
                  <Stack h="full" display="flex" justifyContent="space-between">
                    <Radio value="timeToAccess">Por tempo de acesso</Radio>
                    <Text fontWeight="normal" textAlign="justify">
                      O aluno terá acesso de acordo com o tempo de acesso dele, por exemplo, o aluno
                      compra o produto hoje e você quer que o módulo seguinte seja liberado 7 dias
                      depois da compra. Vai ser uma liberação aos poucos para cada um, de acordo com
                      o dia da compra do aluno.
                    </Text>
                    <Box
                      position="relative"
                      border="1px solid"
                      borderColor="gray"
                      borderRadius={0}
                      outline="2px solid transparent"
                      outlineOffset="2px"
                      opacity={gotejamento !== 'timeToAccess' && 0.4}
                      cursor={gotejamento !== 'timeToAccess' && 'not-allowed'}
                    >
                      <Input
                        name="timeToAccess"
                        paddingRight="8"
                        borderRadius={0}
                        border={0}
                        onChange={inputNumberHandleChange}
                        value={timeToAccess}
                        min={1}
                        _focus={{
                          border: 0,
                          outline: 0,
                        }}
                        isDisabled={gotejamento !== 'timeToAccess'}
                      />

                      <Stack
                        position="absolute"
                        top={0}
                        right={0}
                        margin={0}
                        width="6"
                        height="100%"
                        borderLeftWidth="1px"
                        borderLeftStyle="solid"
                        borderLeftColor="gray"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        zIndex={1}
                      >
                        <Button
                          minWidth="1rem"
                          margin={0}
                          padding={0}
                          paddingTop="0.5"
                          background="transparent"
                          onClick={numberIncrementStepper}
                          _hover={{
                            background: 'none',
                          }}
                          _focus={{
                            background: 'none',
                            outline: 'none',
                          }}
                          _active={{
                            background: 'none',
                          }}
                        >
                          <Icon as={TriangleUpIcon} boxSize={3} />
                        </Button>

                        <Button
                          minWidth="1rem"
                          margin={0}
                          padding={0}
                          paddingBottom="0.5"
                          background="transparent"
                          onClick={numberDecrementStepper}
                          isDisabled={timeToAccess < 1}
                          _hover={{
                            background: 'none',
                          }}
                          _focus={{
                            background: 'none',
                            outline: 'none',
                          }}
                          _active={{
                            background: 'none',
                          }}
                        >
                          <Icon as={TriangleDownIcon} boxSize={3} />
                        </Button>
                      </Stack>
                    </Box>
                  </Stack>
                  <Stack h="full" display="flex" justifyContent="space-between">
                    <Radio value="startDateAccess">Por data</Radio>
                    <Text fontWeight="normal" textAlign="justify">
                      Indicada para utilização em lançamentos de produtos, você irá selecionar a
                      data que o módulo será liberado para os alunos (Ex: 25/12/2021), o módulo
                      estará cadastrado no gerenciador, mas só será liberado na data informada.
                    </Text>
                    <Input
                      value={moduleData?.startDateAccess || ''}
                      name="startDateAccess"
                      type="date"
                      borderColor="gray"
                      borderRadius="none"
                      isDisabled={gotejamento !== 'startDateAccess'}
                      onChange={handleChange}
                      _focus={{ borderColor: '#d55411', borderWidth: '2px' }}
                    />
                  </Stack>
                </Stack>
              </RadioGroup>
            </Stack>
          </Stack>
          <Box>
            <HStack mt="5" display="flex" justifyContent={!moduleId ? 'flex-end' : 'space-between'}>
              {moduleId && (
                <Button
                  type="button"
                  colorScheme="red"
                  onClick={() => handleDelete()}
                  isLoading={loading}
                >
                  Excluir módulo
                </Button>
              )}
              {!moduleId && (
                <Button type="button" colorScheme="red" onClick={() => handleCancel()}>
                  Cancelar
                </Button>
              )}
              <HStack display="flex">
                {moduleId && onChanged && (
                  <Button onClick={() => handleDiscard()}>Descartar alterações</Button>
                )}
                <Menu>
                  <MenuButton
                    as={Button}
                    type="button"
                    w="40"
                    background="#eb7129"
                    color="white"
                    _hover={{
                      background: '#eb712980',
                    }}
                    rightIcon={<ChevronDownIcon />}
                    disabled={loading}
                  >
                    Salvar
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      icon={<EditIcon />}
                      fontSize="md"
                      data-status="EM_EDICAO"
                      onClick={handleSubmit}
                      disabled={loading}
                      type="button"
                    >
                      Rascunho
                    </MenuItem>
                    <MenuItem
                      icon={<CheckIcon />}
                      fontSize="md"
                      data-status="ATIVO"
                      onClick={handleSubmit}
                      disabled={loading}
                      type="button"
                    >
                      Publicar
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </HStack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
