import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PathCards from './Path';

export default function TabFirstSteps({ goToPreviousTab }) {
  const [path, setPath] = useState(null);

  const history = useHistory();
  const { url } = useRouteMatch();

  function handlePathChange(path: string) {
    setPath(path);
  }

  function redirectToSelectedPath() {
    history.push(`${url}/loading?path=${path}`);
  }

  return (
    <Flex
      direction="column"
      justify="space-between"
      flex={1}
      overflow={'scroll'}
      maxHeight={{ base: '72vh', md: '64vh' }}
      sx={{ '::-webkit-scrollbar': { display: 'none' } }}
      pb={4}
    >
      <Box>
        <Heading size="lg" fontWeight={{ base: 'semibold', md: 'bold' }}>
          Primeiros Passos
        </Heading>

        <Text mt={2} fontSize={{ base: 'sm', md: 'md' }} color="#20212350" fontWeight="medium">
          Escolha a opção por onde você deseja começar!
        </Text>

        <PathCards mt={8} onPathChange={handlePathChange} />
      </Box>

      <Flex as="footer" mt={8} justify={{ base: 'flex-start', md: 'flex-end' }}>
        <Flex align="center" gap={{ base: 2, md: 4 }} w={{ base: 'full', md: '66.66%' }}>
          <Flex flex={{ base: 5, md: 4 }}>
            <Button
              onClick={goToPreviousTab}
              width="full"
              colorScheme="gray"
              variant="solid"
              leftIcon={<IoIosArrowBack />}
              py={{ base: 4, lg: 5 }}
              size="sm"
              fontSize="sm"
              fontFamily="Montserrat"
              sx={{ outline: 'none' }}
              border="1px solid #20212340"
              _focus={{ outline: 'none', boxShadow: 'none' }}
              _active={{ outline: 'none', boxShadow: 'none' }}
            >
              Voltar
            </Button>
          </Flex>

          <Flex flex={{ base: 7, md: 8 }}>
            <Button
              onClick={redirectToSelectedPath}
              width="full"
              rightIcon={<IoIosArrowForward />}
              colorScheme="orange"
              py={{ base: 4, lg: 5 }}
              size="sm"
              fontSize="sm"
              fontFamily="Montserrat"
              sx={{ outline: 'none' }}
              pl={{ base: 4, md: 'initial' }}
              isDisabled={!path}
              _focus={{ outline: 'none', boxShadow: 'none' }}
              _active={{ outline: 'none', boxShadow: 'none' }}
            >
              Criar minha plataforma
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
