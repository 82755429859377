import React from 'react';
import styles from './styles.module.css';
import { AiFillPlayCircle } from 'react-icons/ai';

export default function ThumbnailDefault({ width, height }) {
  return (
    <div style={{ width, height }} className={styles.videoLibraryThumbnails}>
      <AiFillPlayCircle fill="orange.500" size={45} />
    </div>
  );
}
