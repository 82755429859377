import { Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react';

export default function Filters({
  handleFilter,
  params,
  pageViewConversions,
  purchaseConversions,
  addToCartConversions,
  freeSignUpConversions,
  externalFormConversions,
  registerDefaultConversions,
  registerCheckoutConversions,
  registerQuestionConversions,
  issuanceBoletoConversions,
  issuancePixConversions,
  purchaseRefusedConversions,
  beginCheckoutConversions,
  recurrencePaidConversions,
  recurrenceCanceledConversions,
  recurrenceEndedConversions,
  recurrencePendingPaymentConversions,
  recurrenceUnpaidConversions,
  beginCheckoutRecurrenceConversions,
}) {
  return (
    <CheckboxGroup colorScheme="default" onChange={handleFilter} value={params || []}>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: 1, md: 3 }}
        width="full"
        flexWrap="wrap"
      >
        <Checkbox size={{ base: 'sm', md: 'md' }} hidden={!pageViewConversions} value="PAGE_VIEW">
          Visitas as páginas({pageViewConversions})
        </Checkbox>

        <Checkbox size={{ base: 'sm', md: 'md' }} hidden={!purchaseConversions} value="PURCHASE">
          Compras({purchaseConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!addToCartConversions}
          value="ADD_TO_CART"
        >
          Adicionou ao carrinho({addToCartConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!freeSignUpConversions}
          value="FREE_SIGN_UP"
        >
          Inscrição gratuita({freeSignUpConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!externalFormConversions}
          value="EXTERNAL_FORM"
        >
          Formulários externos({externalFormConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!registerDefaultConversions}
          value="REGISTER_DEFAULT"
        >
          Cadastro padrão({registerDefaultConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!registerCheckoutConversions}
          value="REGISTER_CHECKOUT"
        >
          Cadastro via checkout({registerCheckoutConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!registerQuestionConversions}
          value="REGISTER_QUESTION"
        >
          Cadastro via questões({registerQuestionConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!issuanceBoletoConversions}
          value="ISSUANCE_BOLETO"
        >
          Emissão de boleto({issuanceBoletoConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!issuancePixConversions}
          value="ISSUANCE_PIX"
        >
          Emissão de Pix({issuancePixConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!purchaseRefusedConversions}
          value="PURCHASE_REFUSED"
        >
          Compras recusadas({purchaseRefusedConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!beginCheckoutConversions}
          value="BEGIN_CHECKOUT"
        >
          Início de checkout({beginCheckoutConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!recurrencePaidConversions}
          value="RECURRENCE_PAID"
        >
          Recorrência paga({recurrencePaidConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!recurrenceCanceledConversions}
          value="RECURRENCE_CANCELED"
        >
          Recorrência cancelada({recurrenceCanceledConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!recurrenceEndedConversions}
          value="RECURRENCE_ENDED"
        >
          Recorrência encerrada({recurrenceEndedConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!recurrencePendingPaymentConversions}
          value="RECURRENCE_PENDING_PAYMENT"
        >
          Recorrência pendente({recurrencePendingPaymentConversions})
        </Checkbox>

        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!recurrenceUnpaidConversions}
          value="RECURRENCE_UNPAID"
        >
          Recorrência não paga({recurrenceUnpaidConversions})
        </Checkbox>
        <Checkbox
          size={{ base: 'sm', md: 'md' }}
          hidden={!beginCheckoutRecurrenceConversions}
          value="BEGIN_CHECKOUT_RECURRENCE"
        >
          Inicio de checkout recorrência({beginCheckoutRecurrenceConversions})
        </Checkbox>
      </Stack>
    </CheckboxGroup>
  );
}
