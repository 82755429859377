import { useDisclosure } from '@chakra-ui/react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SubscriptionBlockModal } from '../components/SubscriptionModal';
import Toast from '../components/Toast';
import ErrorResponse from '../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE, SUBSCRIPTION_LOCAL_STORAGE } from '../helpers/LocalStorageHelper';

export interface ISubscriptionStatus {
  suspended: boolean;
  plan: string;
  recurrence: string;
  expiresIn: Date;
  trial: number;
  planPaymentType: 'payable' | 'not-payable';
  immediatePurchase: boolean;
  numberOfTrialDaysOfPlan: number;
  planIdentifier: string;
  isLoading: boolean;
  isSubscribed: boolean;
}

export type ActiveModalContentType = 'block' | 'subscription' | 'success';
interface SubscriptionContextProps {
  subscriptionStatus: ISubscriptionStatus;
  openModal: () => void;
  onChangeActiveModalContent(activeModalContent: ActiveModalContentType): void;
  getSubscriptionStatus: () => Promise<void>;
}

export const SubscriptionContext = createContext<SubscriptionContextProps | null>(null);

interface SubscriptionProviderProps {
  children: React.ReactNode;
}

function shouldOpenModal(subscriptionStatus: ISubscriptionStatus) {
  const hasTrialExpired = subscriptionStatus.trial <= 0;
  const isPaidPlan = subscriptionStatus.planPaymentType === 'payable';
  const hasTrialPeriod = subscriptionStatus.numberOfTrialDaysOfPlan > 0;
  const isSubscribed = subscriptionStatus.isSubscribed;

  return !isSubscribed && hasTrialPeriod && isPaidPlan && hasTrialExpired;
}

function SubscriptionProvider({ children }: SubscriptionProviderProps) {
  const [subscriptionStatus, setSubscriptionStatus] = useState<ISubscriptionStatus>();
  const [activeModalContent, setActiveModalContent] = useState<ActiveModalContentType>('block');

  const [isLoading, setIsLoading] = useState(true);

  const { isOpen, onOpen: openModal, onClose } = useDisclosure();

  const { pathname } = useLocation();

  const getSubscriptionStatus = useCallback(async () => {
    try {
      const subscription = localStorage.getItem(SUBSCRIPTION_LOCAL_STORAGE);

      if (subscription) {
        const subscriptionStatus = JSON.parse(subscription);

        if (shouldOpenModal(subscriptionStatus)) openModal();

        setSubscriptionStatus(subscriptionStatus);
      }
    } catch (error) {
      Toast(ErrorResponse(error));
    } finally {
      setIsLoading(false);
    }
  }, [openModal]);

  useEffect(() => {
    const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

    if (role === 'DONO') {
      getSubscriptionStatus();
    }
  }, [getSubscriptionStatus, pathname]);

  function onChangeActiveModalContent(activeModalContent: ActiveModalContentType) {
    setActiveModalContent(activeModalContent);
  }

  const contextValue = useMemo(
    () => ({
      subscriptionStatus,
      isLoading,
      openModal,
      onChangeActiveModalContent,
      getSubscriptionStatus,
    }),
    [getSubscriptionStatus, isLoading, openModal, subscriptionStatus]
  );

  return (
    <SubscriptionContext.Provider value={contextValue}>
      <>
        {children}
        <SubscriptionBlockModal
          activeModalContent={activeModalContent}
          isOpen={isOpen}
          onClose={onClose}
          onChangeActiveModalContent={onChangeActiveModalContent}
        />
      </>
    </SubscriptionContext.Provider>
  );
}

function useSubscription() {
  const context = useContext(SubscriptionContext);
  return context;
}

export { SubscriptionProvider, useSubscription };
