import styled, { css } from 'styled-components';

type Props = {
  status: 'ATIVO' | 'EM_EDICAO';
};

export const Container = styled.tr<Props>`
  position: relative;
  height: 3.2rem;
  padding: 0 1rem;

  background: #2021231a;
  border-radius: 5px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    ${props =>
      props.status === 'ATIVO' &&
      css`
        background: #22bb33;
      `}

    ${props =>
      props.status === 'EM_EDICAO' &&
      css`
        background: #bb2124; ;
      `}

    border-radius: 5px 0 0 5px;
    width: 0.6rem;
    height: 100%;
  }
`;

export const Column = styled.td`
  min-width: 16rem;

  > img {
    width: 2.3rem;
    height: 2.3rem;
    margin: 0 0.5rem 0 0;
    border-radius: 50%;
    flex-shrink: 0;
  }

  > span {
    font-size: 1rem;
    color: #20212380;

    &.fullName {
      font-weight: 600;
    }

    &.corrected {
      color: #22bb33;
    }

    &.pending {
      color: #bb2124;
    }
  }

  > button {
    min-width: 7rem;
    background: transparent;
    color: #eb7129;
    border: 1px solid #eb7129;
    border-radius: 2px;
  }

  &:first-child {
    min-height: 50px;
    padding: 0 0 0 1rem;

    display: flex;
    align-items: center;
  }

  @media only screen and (min-width: 1270px) {
    min-width: 12rem;
  }
`;

export const DefaultImage = styled.div<Props>`
  width: 2.3rem;
  height: 2.3rem;
  margin: 0 0.5rem 0 0;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 50%;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  ${props =>
    props.status === 'ATIVO' &&
    css`
      background: #22bb33;
    `}

  ${props =>
    props.status === 'EM_EDICAO' &&
    css`
      background: #bb2124; ;
    `}
`;
