import { responsiveBannerToShow, settingsBanner } from '../slickSettings';

import Carousel from 'react-slick';
import { sortByOrder } from '../../../../../helpers/SortHelper';
import styles from '../styles.module.css';

export default function BannerDesktop({ bannerHeroSection, themeMarketplace }) {
  const hasCarousel = bannerHeroSection?.themeBannerHero.length > 1;
  const firstBannerSection = bannerHeroSection?.themeBannerHero[0];

  const bannerProps = {
    key: firstBannerSection?.id,
    src: firstBannerSection?.banner,
    style: { backgroundColor: themeMarketplace.primaryColor },
    className: styles.bannerItem,
  };

  if (!hasCarousel) {
    return (
      <section className={styles.bannerSection}>
        {firstBannerSection?.link ? (
          <a
            href={firstBannerSection.link}
            target="_blank"
            rel="noopener noreferrer"
            role="button"
            title={firstBannerSection?.title}
            style={{ display: 'inline' }}
            className={styles.bannerLink}
          >
            <img {...bannerProps} alt="Banner" />
          </a>
        ) : (
          <img {...bannerProps} alt="Banner" />
        )}
      </section>
    );
  }

  return (
    <>
      {bannerHeroSection.active && (
        <section className={styles.bannerSection}>
          <Carousel
            {...settingsBanner}
            responsive={responsiveBannerToShow(themeMarketplace.bannerToShow)}
            slidesToShow={bannerHeroSection.sectionsToShow}
            slidesToScroll={bannerHeroSection.sectionsToShow}
            dots={true}
          >
            {bannerHeroSection?.themeBannerHero
              .sort(sortByOrder)
              .map((bannerHeroItem, index: number) => {
                return bannerHeroItem.link ? (
                  <a
                    key={bannerHeroItem.id}
                    href={bannerHeroItem.link || null}
                    target="_blank"
                    rel="noopener noreferrer"
                    role="button"
                    title={bannerHeroItem.title}
                    style={{ display: 'inline' }}
                    className={styles.bannerLink}
                  >
                    <img
                      src={bannerHeroItem.banner}
                      style={{
                        backgroundColor: themeMarketplace.primaryColor,
                      }}
                      className={styles.bannerItem}
                      alt={`Banner ${index + 1}`}
                    />
                  </a>
                ) : (
                  <img
                    key={bannerHeroItem.id}
                    src={bannerHeroItem.banner}
                    style={{ backgroundColor: themeMarketplace.primaryColor }}
                    className={styles.bannerItem}
                    alt=""
                  />
                );
              })}
          </Carousel>
        </section>
      )}
    </>
  );
}
