import { Alert, Box, Text } from '@chakra-ui/react';
import { MdAccessTime } from 'react-icons/md';
import { IoMdCheckboxOutline as CheckIcon } from 'react-icons/io';

function getMessageByStatus(status: string) {
  const messages = {
    waiting_payment: 'Aguardando pagamento!',
    paid: 'Pagamento realizado com sucesso!',
    default: 'Pedido realizado com sucesso!',
  };

  return messages[status] || messages.default;
}

function getByStatus(status: string) {
  const alert = {
    waiting_payment: 'warning',
    paid: 'success',
    default: 'success',
  };

  return alert[status] || alert.default;
}

function getTextColor(status: string) {
  const colors = {
    waiting_payment: '#664d03',
    paid: '#0f5132',
    default: '#0f5132',
  };

  return colors[status] || colors.default;
}

function getIconToShow(status: string) {
  const alert = {
    waiting_payment: (
      <MdAccessTime size={48} color={getTextColor(status)} style={{ flexShrink: 0 }} />
    ),
    paid: <CheckIcon size={48} color={getTextColor(status)} style={{ flexShrink: 0 }} />,
    default: <CheckIcon size={48} color={getTextColor(status)} style={{ flexShrink: 0 }} />,
  };

  return alert[status] || alert.default;
}

function ConfirmationMessage({ status }) {
  return (
    <Alert display="flex" status={getByStatus(status)} borderRadius="sm" role="alert">
      <Box display="flex" alignItems="center">
        {getIconToShow(status)}

        <Text
          as="h3"
          marginLeft="2"
          fontSize="clamp(1.2rem, 1rem, 2rem)"
          color={getTextColor(status)}
          textTransform="uppercase"
        >
          {getMessageByStatus(status)}
        </Text>
      </Box>
    </Alert>
  );
}

export default ConfirmationMessage;
