import { Box, Button, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { CSSProperties, MouseEvent, memo, useMemo } from 'react';

import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

interface IProps {
  file: File | null;
  isDisabled?: boolean;
  accept: string[];
  extensions?: string;
  maxFileSize?: string;
  Preview?: React.FC;
  dimensions?: string;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  onDropRejected?: () => void;
  height?: string;
}

const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.625rem',
  borderWidth: 2,
  borderColor: '#AEADAD',
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'all .24s ease-in-out',
};

const acceptStyle: CSSProperties = {
  borderColor: '#19bc90',
  backgroundColor: '#e4fbf5',
};

const rejectStyle: CSSProperties = {
  borderColor: '#c3463c',
  backgroundColor: '#fceae9',
  borderStyle: 'solid',
  color: '#c3463c',
};

function CustomDropzone({
  file,
  isDisabled,
  accept,
  extensions,
  maxFileSize,
  Preview,
  dimensions,
  onDrop,
  onDropRejected,
  height = 'auto',
}: IProps) {
  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    accept,
    noClick: true,
    noKeyboard: true,
    onDrop,
    onDropRejected,
  });

  const bgColor = useColorModeValue('#F6F5F5', '#202123');

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (isDisabled) return;
    open();
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragReject, isDragAccept]
  );

  if (file && Preview) return <Preview />;

  return (
    <Box {...getRootProps({ style })} borderRadius="base" bg={bgColor} height={height}>
      <input {...getInputProps()} />
      <Box display="flex" flexDirection="column" textAlign="center" color="#0000007D">
        <Text color="blackAlpha.600">
          Arraste e solte um arquivo <br /> aqui ou
        </Text>
      </Box>

      <Button
        minW="8rem"
        minH="2rem"
        my="4"
        colorScheme="primary"
        color="secondary.500"
        size="sm"
        isDisabled={isDisabled}
        onClick={handleClick}
      >
        Selecionar arquivo
      </Button>

      <Stack color="blackAlpha.900" textAlign="center">
        <Text>Você pode enviar arquivos com a extensão: {extensions}</Text>
        {dimensions && <Text mt="0rem !important">Dimensões: {dimensions}</Text>}
        {maxFileSize && <Text mt="0rem !important">Tamanho máximo do arquivo: {maxFileSize}</Text>}
      </Stack>
    </Box>
  );
}

export default memo(CustomDropzone);
