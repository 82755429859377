import { Container, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import FullPageLoading from '../../../../../../../components/FullPageLoading';
import useFetch from '../../../../../../../hooks/useFetch';
import Header from '../../../Header';
import { HighlightPlanShowcaseForm } from '../HighlightPlanShowcaseForm';

interface PlanResponse {
  showcasePlanId: number;
  planId: number;
  recurrencePlanName: string;
  titleHighlight: string;
  description: string;
  imageDesktop: string;
  imageMobile: string | null;
}

export function AddOrUpdateHighlightPlanShowcase() {
  const { showcaseId, action, showcasePlanId } = useParams<{
    action: 'new' | 'edit' | 'add';
    showcaseId: string;
    showcasePlanId: string;
  }>();

  const { data: planResponse, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<PlanResponse>
  >({
    url: `/showcase-course/${showcaseId}/showcase/${showcasePlanId}/showcase-plan-highlight`,
    method: 'get',
    autoFetch: action === 'edit',
    authenticated: true,
  });

  const currentPlan = planResponse?.data;

  return (
    <Flex minHeight="100vh" flexDirection="column">
      <Header backUrl="/members-area-management/netflix" />

      <Container maxWidth="1150px" px={{ base: 4, xl: 0 }}>
        {!isLoading && (
          <HighlightPlanShowcaseForm
            data={action === 'edit' ? currentPlan : null}
            url={action === 'add' ? '/showcase/add-product' : `/showcase-course/${showcasePlanId}`}
            action={action}
            showcaseId={Number(showcaseId)}
          />
        )}
      </Container>

      <FullPageLoading isLoading={isLoading} />
    </Flex>
  );
}
