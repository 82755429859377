import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  ModalProps,
} from '@chakra-ui/react';

import { IoMdAlert } from 'react-icons/io';

const ALERT_ICON_SIZE = '3.077rem'; // 40px

interface DeleteImageModalProps {
  isOpen: ModalProps['isOpen'];
  isLoading: boolean;
  onConfirm: () => void;
  onClose: ModalProps['onClose'];
}

export default function DeleteImageModal({
  isOpen,
  isLoading,
  onConfirm,
  onClose,
}: DeleteImageModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent px={{ base: 4, md: 14 }} maxW={{ base: '94%', md: '430px' }}>
        <ModalHeader display="flex" justifyContent="center">
          <IoMdAlert color="#BB2124" width="40px" height="40px" fontSize={ALERT_ICON_SIZE} />
        </ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} mr={4} />
        <ModalBody textAlign="center" px={0}>
          <Heading fontSize="lg" fontWeight="medium" lineHeight="28px">
            Você deseja excluir a imagem?
          </Heading>
        </ModalBody>
        <ModalFooter justifyContent="center" gap={3} px={0} pb={8}>
          <Button
            width="full"
            size="sm"
            onClick={onClose}
            bg="#e9e9e9"
            border="1px solid #20212340"
            // isDisabled={isLoading}
          >
            Cancelar
          </Button>

          <Button
            size="sm"
            width="full"
            bg="#BB2124"
            colorScheme="red"
            onClick={onConfirm}
            isLoading={isLoading}
          >
            Remover
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
