import { TableContainer, Thead, Tr, Th, Tbody, Td, Table, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ILeadsBySegmentation } from '..';

interface IProps {
  leadsBySegmentation: ILeadsBySegmentation[];
}

export function TableLeadList({ leadsBySegmentation }: IProps) {
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Email</Th>
            <Th>Telefone</Th>
            <Th>Conversões</Th>
            <Th>Última conversão</Th>
          </Tr>
        </Thead>
        <Tbody>
          {leadsBySegmentation?.length > 0 &&
            leadsBySegmentation.map(leads => (
              <Tr>
                <Td>
                  <Text maxW={200} isTruncated>
                    {leads.name}
                  </Text>
                </Td>
                <Td>
                  <Text maxW={250} isTruncated>
                    {leads.email}
                  </Text>
                </Td>
                <Td>{leads.phone}</Td>
                <Td>{leads.numberOfConversions}</Td>
                <Td>{dayjs(leads.lastConversion).format('DD/MM/YYYY - HH:mm')}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
