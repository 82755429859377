import { Center, Heading, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Paginate from '../../../../../components/Paginate';
import useFetch from '../../../../../hooks/useFetch';
import hasFilterParams from '../../../../../utils/hasFilterParams';
import Loading from './components/Loading';
import PlanListMobile from './components/PlanListMobile';
import PlanListTable from './components/PlanListTable';
import SearchInput from './components/SearchInput';

export interface Plan {
  id: number;
  name: string;
  numberOfSignatures: number;
  numberOfCancellations: number;
  invoicing: number;
}

interface ListPlanResponse {
  recurrencePlans: Plan[];
  page: number;
  per_page: number;
  total: number;
}

export default function PlanListTabPanel() {
  const location = useLocation();
  const history = useHistory();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { data: response, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<ListPlanResponse>
  >({
    method: 'get',
    url: `/recurrence/list-plans?${urlSearchParams.toString()}`,
    autoFetch: true,
    authenticated: true,
  });

  function handleSearchChange(value: string) {
    value ? urlSearchParams.set('search', value) : urlSearchParams.delete('search');

    history.push({ search: urlSearchParams.toString() });
  }

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  const planListData = response?.data?.recurrencePlans || [];
  const isFiltering = hasFilterParams(urlSearchParams);
  const isNotFoundVisible = !isLoading && !planListData?.length && isFiltering;
  const isEmpty = !isLoading && !planListData?.length && !isFiltering;
  const isPlanListVisible = !isLoading && !!planListData?.length;
  const total = response?.data?.total;
  const perPage = response?.data?.per_page || 1;
  const pageCount = Math.ceil(total / perPage);
  const isPaginationVisible = !isLoading && pageCount > 1;
  const currentPage = Number(urlSearchParams.get('page')) || 1;

  return (
    <>
      {!isEmpty && (
        <SearchInput
          value={urlSearchParams.get('search')}
          onSearch={handleSearchChange}
          isDisabled={isLoading}
        />
      )}

      {isLoading && <Loading />}

      {isNotFoundVisible && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.300">
            Nenhum resultado encontrado...
          </Heading>
        </Center>
      )}

      {isEmpty && (
        <Text fontSize="sm" color="#0000007D">
          Você ainda não tem planos cadastrados. Para cadastrar um plano vá em Assinatura &gt;
          Gestão de planos.
        </Text>
      )}

      {isPlanListVisible && (
        <>
          <PlanListTable display={{ base: 'none', xl: 'block' }} planList={planListData} />

          <PlanListMobile
            display={{ base: 'block', xl: 'none' }}
            planList={planListData}
            mt={2.5}
          />
        </>
      )}

      {isPaginationVisible && (
        <Paginate
          pageCount={pageCount}
          initialPage={currentPage}
          mt={{ base: 8, xl: 4 }}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
