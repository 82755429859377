import { Box, Heading, Image, Stack } from '@chakra-ui/react';
import Player from './Player';
import { useCourseStudent } from '../../../../../contexts/CourseStudentContextV2';
import { PagingContents } from '../../../components/PagingContents';

function ViewAudio() {
  const { currentContent: content, highlightColor } = useCourseStudent();

  return (
    <Stack spacing={{ base: '9px', md: '55px', lg: '55px' }}>
      <Stack spacing={{ base: '30px', md: '16px', lg: '16px' }}>
        <Heading
          color="#FFF"
          fontSize={{ base: '18px', md: '24px', lg: '24px' }}
          margin="0"
          fontWeight="500"
        >
          {content?.title}
        </Heading>

        <Box
          width="full"
          display="flex"
          justifyContent="center"
          marginBottom={{ base: '30px', lg: 'auto' }}
        >
          <Image
            display={{ base: 'block', md: 'none', lg: 'none' }}
            height={{ base: '293px', lg: '249px' }}
            width={{ base: '293px', lg: '249px' }}
            objectFit="cover"
            src={content?.thumbnail}
          />
        </Box>

        <Box
          position="relative"
          width="full"
          height="386px"
          display={{ base: 'none', md: 'flex', lg: 'flex' }}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            maxHeight="386px"
            height="386px"
            width="100%"
            backgroundImage={content?.thumbnail}
            backgroundPosition="center"
            display={{ base: 'none', md: 'flex', lg: 'flex' }}
            justifyContent="center"
            alignItems="center"
            style={{ filter: 'blur(15px)' }}
          />

          <Image
            position="absolute"
            height="250px"
            width="250px"
            objectFit="cover"
            src={content?.thumbnail}
          />
        </Box>

        <Player src={content?.download} highlightColor={highlightColor} />

        <Stack spacing={{ base: '10px', md: '30px', lg: '30px' }}>
          <PagingContents />

          <Box
            color="#FFF"
            dangerouslySetInnerHTML={{ __html: content?.content }}
            className="ql-editor"
            sx={{
              iframe: {
                width: '100%',
                aspectRatio: '16/9',
              },
              a: { color: '#06c', textDecoration: 'underline' },
              blockquote: { borderLeft: '5px solid #ccc', paddingLeft: '30px' }, // Estilo para citação
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ViewAudio;
