/* eslint-disable no-console */
import { ToastId, useToast, UseToastOptions } from '@chakra-ui/react';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { error, request, requestError, success } from '../api/Configuration';
import ErrorResponse from '../helpers/ErrorResponse';
import { TOKEN_LOCAL_STORAGE, TOKEN_PURCHASE } from '../helpers/LocalStorageHelper';
import CreateToastAlert from '../utils/CreateToastAlert';
import { JoiSchemasType } from '../validation/EntitySchema';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
  timeout: 60 * 1000, // 1 minuto
});

api.interceptors.request.use(request, requestError);
api.interceptors.response.use(success, error);

export type ResponseJSON<T = any> = {
  statusCode: number;
  message: string;
  data?: T;
  error?: string;
};

export type UseHandleSubmitPropsType<T extends object, U = any> = {
  url: string;
  method: 'get' | 'post' | 'patch' | 'put' | 'delete';
  data?: T;
  schemaValidator?: JoiSchemasType<T>;
  isDebug?: {
    preventSubmit?: boolean;
    log?: boolean;
    bypassOnSuccess?: boolean;
    bypassValidator?: boolean;
  };
  getError?: boolean;
  authenticated?: boolean;
  isLoading?: boolean;
  onSuccess?: {
    message?: string;
    redirect?: {
      to: string;
    };
    callback?: (response: AxiosResponse<U>) => void;
  };
  removeNullProps?: boolean;
};

export type Validation = {
  isInvalid: boolean;
  message: string;
};

type OnSuccessProps<T> = {
  onSuccessData?: {
    message?: string;
    redirect?: {
      to: string;
    };
    callback?: (response: AxiosResponse<T>) => void;
  };
  history: {
    push: (location: string, state?: unknown) => void;
  };
  toast: (options?: UseToastOptions) => ToastId;
};

type SubObjetType<T, K extends keyof T> = {
  // eslint-disable-next-line no-unused-vars
  [P in keyof T[K]]: Validation;
};

export type FormValidation<T> = {
  [K in keyof T]: T[K] extends string | number | boolean ? Validation : SubObjetType<T, K>;
};

const IS_SUBOBJECT_KEY = /^\w+\.\w+$/;

type NonNullableProps<T> = {
  [K in keyof T]: NonNullable<T[K]>;
};

function removeNullProps<T>(obj: T, removeNullProps: boolean = true): NonNullableProps<T> {
  const result = {} as NonNullableProps<T>;

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null) {
      result[key] = obj[key] as NonNullable<T[typeof key]>;
    }
  }

  return removeNullProps ? result : obj;
}

function generateFormValidation<T>(data: T) {
  return Object.keys(data as object).reduce((result, key) => {
    if (
      data[key as keyof T] &&
      typeof data[key as keyof T] === 'object' &&
      !Array.isArray(data[key as keyof T])
    ) {
      const subObjectKeys = Object.keys((data as any)[key]);

      const subObject = {};

      for (const subObjectKey of subObjectKeys) {
        (subObject as any)[subObjectKey] = { isInvalid: false, message: '' };
      }

      return { ...result, [key]: { ...subObject } };
    }

    return { ...result, [key]: { isInvalid: false, message: '' } };
  }, {} as FormValidation<T>);
}

function onSuccess<R>(
  { onSuccessData, history, toast }: OnSuccessProps<R>,
  response: AxiosResponse<R>
) {
  if (onSuccessData.message) {
    toast(
      CreateToastAlert({
        status: 'success',
        title: onSuccessData.message,
      })
    );
  }

  if (onSuccessData?.callback) {
    onSuccessData.callback(response);
  }

  if (onSuccessData.redirect) {
    history.push(onSuccessData.redirect.to);
    return;
  }
}

export default function useHandleSubmit<T extends object, Response>(
  props: UseHandleSubmitPropsType<T, Response>
) {
  const [formValidation, setFormValidation] = useState<FormValidation<T> | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);

  const toast = useToast();

  const history = useHistory();

  useEffect(() => {
    setIsLoading(props.isLoading ?? false);
  }, [props.isLoading]);

  async function handleSubmit<R = unknown>(): Promise<AxiosResponse<R> | void> {
    try {
      setIsLoading(true);

      let Authorization = '';

      if (props.authenticated) {
        const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
        const tokenForPurchase = localStorage.getItem(TOKEN_PURCHASE);
        Authorization = `Bearer ${token ? token : tokenForPurchase}`;
      }

      if (!props.schemaValidator) {
        const result = await api({
          method: props.method,
          url: props.url,
          data: props.data,
          headers: props.authenticated ? { Authorization } : {},
        });

        if (props.onSuccess) {
          onSuccess({ onSuccessData: props.onSuccess, history, toast }, result);
        }

        return result;
      }

      const initialFormValidation = generateFormValidation(props.data);

      const dataWithoutNullProps = removeNullProps(props.data, props.removeNullProps);

      const { error, value } = props.schemaValidator
        .messages({
          'object.unknown': 'O atributo {{#label}} não é permitido.',
          'any.required': 'O atributo {{#label}} é obrigatório.',
          'string.empty': 'O atributo {{#label}} não pode ficar vazio.',
          'string.base': 'O atributo {{#label}} deve ser uma string.',
          'string.max': 'O atributo {{#label}} deve conter no máximo {{#limit}} caracteres.',
          'string.min': 'O atributo {{#label}} deve conter no mínimo {{#limit}} caracteres.',
          'number.base': 'O atributo {{#label}} deve ser um number.',
          'number.max': 'O atributo {{#label}} deve conter no máximo {{#limit}} caracteres.',
          'number.min': 'O atributo {{#label}} deve conter no mínimo {{#limit}} caracteres.',
          'number.positive': 'O atributo {{#label}} deve ser um número positivo.',
          'number.integer': 'O atributo {{#label}} deve ser um número inteiro.',
          'boolean.base': 'O atributo {{#label}} deve ser boolean.',
          'array.min': 'O atributo {{#label}} deve conter no mínimo {{#limit}} item(s).',
          'array.max': 'O atributo {{#label}} deve conter no mínimo {{#limit}} item(s).',
        })
        .validate(dataWithoutNullProps);

      if (error && !props.isDebug?.bypassValidator) {
        for (const detail of error.details) {
          if (detail.context) {
            const key = detail.context.label as keyof T;

            if (key.toString().match(IS_SUBOBJECT_KEY)) {
              const [subObjectKey, subKey] = key.toString().split('.', 2);

              const subObject = initialFormValidation[subObjectKey as keyof T] as SubObjetType<
                T,
                keyof T
              >;

              const validation = subObject[subKey as keyof SubObjetType<T, keyof T>];

              validation.isInvalid = true;
              validation.message = detail.message;
            } else {
              const validation = initialFormValidation[key] as Validation;

              if (validation) {
                validation.isInvalid = true;
                validation.message = detail.message;
              }
            }
          }
        }

        setFormValidation(initialFormValidation);

        return;
      }

      setFormValidation(initialFormValidation);

      if (props.isDebug) {
        if (props.isDebug?.log) {
          console.log('Log:', { props, payload: value });
        }

        if (props.isDebug?.preventSubmit) {
          console.log('Prevent submit');
          return;
        }

        if (props.isDebug?.bypassOnSuccess && props.onSuccess) {
          if (props.onSuccess.message) {
            toast(
              CreateToastAlert({
                status: 'success',
                title: props.onSuccess.message,
              })
            );
          }

          if (props.onSuccess.redirect) {
            history.push(props.onSuccess.redirect.to);
            return;
          }
        }
      }

      const result = await api({
        method: props.method,
        url: props.url,
        data: value,
        headers: props.authenticated ? { Authorization } : {},
      });

      if (props.onSuccess) {
        onSuccess({ onSuccessData: props.onSuccess, history, toast }, result);
      }

      return result;
    } catch (error: any) {
      if (props.getError) {
        setError(ErrorResponse(error));

        return;
      }

      if (props.isDebug?.log) {
        console.error(error);
      }

      toast(
        CreateToastAlert({
          error,
          title: error.message,
        })
      );
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    error,
    setError,
    handleSubmit,
    formValidation,
  };
}
