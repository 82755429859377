import { Box, Button, Flex, Heading, Icon, Image, Text, VStack } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { MdOutlineChevronLeft as ChevronLeftIcon } from 'react-icons/md';
import { Link, useHistory, useParams } from 'react-router-dom';
import { IBlogPost } from '..';
import { IS_REACT_APP_HOSTNAME } from '../../../App';
import MarketplaceAPI from '../../../api/Marketplace';
import squad1 from '../../../assets/images/squads/squad1.svg';
import squad2 from '../../../assets/images/squads/squad2.svg';
import squad3 from '../../../assets/images/squads/squad3.svg';
import squad4 from '../../../assets/images/squads/squad4.svg';
import squad5 from '../../../assets/images/squads/squad5.svg';
import Container from '../../../components/ContainerSite/ContainerSite';
import DotsFalling from '../../../components/Loadings/DotsFalling';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';
import useFacebookPixel from '../../../hooks/useFacebookPixel';
import useFetch from '../../../hooks/useFetch';
import Card from '../components/Card';
import Section from '../components/Section';
import AuthorCard from './AuthorCard';
import RelatedPosts from './RelatedPosts';
import SocialLinks from './SocialLinks';
import './styles.css';

interface IParams {
  id: string;
}

interface IPost {
  id?: number;
  articleName: string;
  subtitle: string;
  userName: string;
  userPhoto: string;
  status: string;
  category: string;
  content: string;
  banner: string;
  createdAt: Date;
  publicationDate: Date;
}

const postInitialState: IPost = {
  articleName: '',
  subtitle: '',
  userName: '',
  userPhoto: '',
  status: '',
  category: '',
  content: '',
  banner: '',
  publicationDate: null,
  createdAt: null,
};

function Post() {
  const [post, setPost] = useState<IPost>(postInitialState);
  const [isLoading, setIsLoading] = useState(false);
  const [blogPosts, setBlogPosts] = useState<IPost[]>([]);

  const history = useHistory();
  const hostname = window.location.hostname;
  const { id: postId } = useParams<IParams>();

  const postContentWithListStyles = `
  <style>
    ul {
      list-style-type: disc;
      margin-left: 1.5rem;
    }
    ol {
      list-style-type: decimal;
      margin-left: 1.5rem;
    }
  </style>
  ${post.content}
`;
  const { data: blogPostsResponse, loading } = useFetch<UnificadaFront.ResponseJSON<IBlogPost>>({
    url: `/marketplace/posts`,
    method: 'get',
    autoFetch: true,
    authenticated: false,
  });

  useEffect(() => {
    if (blogPostsResponse?.data) {
      setBlogPosts(blogPostsResponse?.data?.posts);
    }
  }, [blogPostsResponse?.data]);

  useEffect(() => {
    async function getPost() {
      try {
        setIsLoading(true);
        const { data: postData } = await MarketplaceAPI.blog.show(postId);

        if (postData) {
          setPost(postData);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
        history.push('/blog');
      } finally {
        setIsLoading(false);
      }
    }

    getPost();
  }, [history, postId]);

  function PostView() {
    if (isLoading) {
      return (
        <div className="container w-100 d-flex justify-content-center mt-5">
          <DotsFalling />
        </div>
      );
    }

    return (
      <>
        <Button
          as={Link}
          to="/blog"
          variant="link"
          color="#202123"
          my={{ base: 0, md: 4 }}
          ml={{ base: -3, md: -2.5 }}
        >
          <Icon boxSize={8} as={ChevronLeftIcon} />
          Todas publicações
        </Button>

        <Flex
          align={{ base: 'center', md: 'normal', lg: 'normal' }}
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          justifyContent="center"
          gap={10}
        >
          <Box>
            <Heading mb={2}>{post.articleName}</Heading>
            <Text mb={2}>{post.subtitle}</Text>

            <Box h="auto" justifyContent="center" display="flex">
              <Image
                h={{ base: '200', md: '300', lg: '400' }}
                objectFit="cover"
                src={post.banner}
                alt={`banner principal do post ${post.articleName}`}
                mb={5}
              />
            </Box>

            <Box
              sx={{
                a: { color: '#005EBC', wordBreak: 'break-word' },
                iframe: { width: '100%', aspectRatio: 16 / 9 },
                h2: { fontSize: { base: '1.25rem', md: '1.75rem' } },
              }}
              dangerouslySetInnerHTML={{ __html: postContentWithListStyles }}
              my={5}
            />
          </Box>

          <Flex as="aside" align="center" direction="column">
            <AuthorCard
              userName={post.userName}
              avatar={post.userPhoto}
              date={post.createdAt?.toString()}
            />

            <SocialLinks />

            {blogPosts.length ? <RelatedPosts listPosts={blogPosts} postId={postId} /> : ''}
          </Flex>
        </Flex>
      </>
    );
  }

  const listPosts = useMemo(() => {
    return blogPosts.filter(post => String(post.id) !== postId).slice(0, 2);
  }, [blogPosts, postId]);

  useFacebookPixel([
    { pixelId: '244704928319202', event: 'PageView', hostname: 'pollylyra.proluno.com.br' },
  ]);

  return (
    <Container>
      <Box
        as="main"
        marginTop={{ base: '3.5rem', md: '6.25rem' }}
        padding={{ base: '1rem', md: '0 5.5rem' }}
      >
        <PostView />

        {IS_REACT_APP_HOSTNAME(hostname) ? (
          <Section background="#EB7129">
            <img src={squad1} alt="Imagem de um quadrado de bordas brancas" className="squad1" />
            <img src={squad2} alt="Imagem de um quadrado de bordas brancas" className="squad2" />
            <img src={squad3} alt="Imagem de um quadrado de bordas brancas" className="squad3" />
            <img src={squad4} alt="Imagem de um quadrado de bordas brancas" className="squad4" />
            <img src={squad5} alt="Imagem de um quadrado de bordas brancas" className="squad5" />

            <div className="signup__container">
              <div>
                <h2>
                  Inscreva-se em nosso <span className="fw-700">blog</span>
                </h2>
                <span>
                  Acesse, em primeira mão, nossos principais blogPosts sobre educação, marketing,
                  vendas e muito mais, diretamente em seu email.
                </span>
              </div>

              <form>
                <input type="text" placeholder="Nome" />
                <input type="text" placeholder="E-mail" />

                <div className="w-100 d-flex justify-content-sm-center justify-content-md-end">
                  <button type="submit">Enviar</button>
                </div>
              </form>
            </div>
          </Section>
        ) : (
          ''
        )}

        <VStack align="start">
          <Box width="175px" height="8px" background="primary.500" borderRadius="100px" my={12} />

          {!!listPosts.length && (
            <>
              <Heading size="xl">Posts recomendados</Heading>

              <Box display="flex" gap="20px" flexWrap="wrap" py={7}>
                {listPosts.map(post => (
                  <Card key={post.id} post={post} isLoading={loading} />
                ))}
              </Box>
            </>
          )}
        </VStack>
      </Box>
    </Container>
  );
}

export default Post;
