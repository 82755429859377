import { axiosBackend } from './Configuration';

export default {
  index: async zip_code => {
    try {
      const { data: zipCodeData } = await axiosBackend().get(`/cep/${zip_code}`);

      return zipCodeData;
    } catch (error) {
      throw error;
    }
  },
};
