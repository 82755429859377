import { ReactComponent as DragIcon } from '../../../../../../assets/icons/dragIcon.svg';
import styles from './styles.module.css';

function Section({ draggable, title, isDisabled, order }) {
  return (
    <div style={{ opacity: !draggable && '0.32' }} className={styles.sectionContainer}>
      <div className={styles.dragContainer} style={{ visibility: !draggable && 'hidden' }}>
        <DragIcon fill="#202123" />
      </div>

      <div className="d-flex align-items-center justify-content-center">
        {order && <h6 className={styles.orderTitle}>{`${order}.`}</h6>}
        <h6>{title}</h6>
        {isDisabled && (
          <span
            className="badge ml-3 my-0"
            style={{
              pointerEvents: 'none',
              background: 'transparent',
              borderColor: '#eb7129',
              color: '#eb7129',
              fontSize: '0.75rem',
            }}
          >
            EM BREVE
          </span>
        )}
      </div>
    </div>
  );
}

export default Section;
