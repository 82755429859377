import { Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';

type ProductCardProps = {
  productName: string;
  productThumbnail: string;
  productCommissionValue: string;
};

export default function ProductCard({
  productName,
  productThumbnail,
  productCommissionValue,
}: ProductCardProps) {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      overflow="hidden"
      width="full"
      boxShadow="1px 1px 4px rgba(0, 0, 0, 0.25)"
      borderRadius="2xl"
      gap={{ base: 0, md: 10 }}
    >
      <Image
        objectFit="cover"
        maxH={{ base: '23.0625rem', md: '13.75rem' }}
        src={productThumbnail}
        alt={productName}
        flex={2.64}
        borderRadius="2xl"
      />

      <Stack gap={{ base: 5, md: 7 }} flex={9} py={4} px={{ base: 3, md: 0 }}>
        <Heading fontSize={{ base: '3xl', md: '4xl' }} fontWeight="semibold" maxW={{ md: '88%' }}>
          {productName}
        </Heading>

        <Stack direction={{ base: 'column', md: 'row' }}>
          <Flex direction="column">
            <Text as="strong" color="primary.500" fontSize="3xl" lineHeight={0}>
              {Intl.NumberFormat('pt-br', { minimumFractionDigits: 1 }).format(
                Number(productCommissionValue)
              )}{' '}
              %
            </Text>
            <Text fontSize="lg" mt={3} fontWeight="medium">
              De comissão por venda
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Flex>
  );
}
