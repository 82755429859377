import { HStack, Heading, Stack, Switch, TabPanel, Text, useToast } from '@chakra-ui/react';
import ColorPicker from '../../../../../../components/ColorPickChakra';
import useFetch from '../../../../../../hooks/useFetch';
import { ChangeEvent, useEffect, useState } from 'react';
import { axiosBackend } from '../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';

interface IAppearanceResponse {
  highlightColor: string;
  showModulesAndContentsBar: boolean;
  showProgressBar: boolean;
}

export default function Appearance({ showcaseId, showcaseCourseId }) {
  const toast = useToast();

  const [appearance, setAppearance] = useState<IAppearanceResponse>();

  const { data: response, fetchData } = useFetch<UnificadaFront.ResponseJSON<IAppearanceResponse>>({
    method: 'get',
    url: `/showcase-course/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/appearance`,
    authenticated: true,
    autoFetch: false,
  });

  const appearanceData = response?.data;

  useEffect(() => {
    if (showcaseId && showcaseCourseId) {
      fetchData();
    }
  }, [fetchData, showcaseCourseId, showcaseId]);

  useEffect(() => {
    if (appearanceData) {
      setAppearance(appearanceData);
    }
  }, [appearanceData]);

  function onChangeHighlightColor(event) {
    const { value } = event;

    setAppearance({ ...appearance, highlightColor: value });
  }

  async function onSubmitHighlightColor() {
    const payload = {
      highlightColor: appearance?.highlightColor || '#EB7129',
      showModulesAndContentsBar: appearance?.showModulesAndContentsBar,
      showProgressBar: appearance?.showProgressBar,
    };

    try {
      await axiosBackend().patch(
        `/showcase-course/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/appearance`,
        payload
      );

      setAppearance({ ...appearance, highlightColor: payload.highlightColor });

      toast({
        title: 'Cor de destaque atualizada com sucesso!',
        status: 'success',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    }
  }
  async function onClickHighlightColor(color) {
    const payload = {
      highlightColor: color,
      showModulesAndContentsBar: appearance?.showModulesAndContentsBar,
      showProgressBar: appearance?.showProgressBar,
    };

    try {
      await axiosBackend().patch(
        `/showcase-course/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/appearance`,
        payload
      );

      setAppearance({ ...appearance, highlightColor: payload.highlightColor });

      toast({
        title: 'Cor de destaque atualizada com sucesso!',
        status: 'success',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    }
  }

  async function onChangeModulesAndContent(event: ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked;

    const payload = {
      highlightColor: appearance?.highlightColor,
      showModulesAndContentsBar: checked,
      showProgressBar: appearance?.showProgressBar,
    };

    try {
      await axiosBackend().patch(
        `/showcase-course/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/appearance`,
        payload
      );

      setAppearance({ ...appearance, showModulesAndContentsBar: checked });

      toast({
        title: `Visualização de módulos e conteúdos ${
          checked ? 'ativada' : 'desativada'
        } com sucesso!`,
        status: 'success',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    }
  }

  async function onChangeProgressBar(event: ChangeEvent<HTMLInputElement>) {
    const checked = event.target.checked;

    const payload = {
      highlightColor: appearance?.highlightColor ?? '#ec7117',
      showModulesAndContentsBar: appearance?.showProgressBar,
      showProgressBar: checked,
    };

    try {
      await axiosBackend().patch(
        `/showcase-course/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/appearance`,
        payload
      );

      setAppearance({ ...appearance, showProgressBar: checked });

      toast({
        title: `Visualização da barra de progresso ${
          checked ? 'ativada' : 'desativada'
        } com sucesso!`,
        status: 'success',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    }
  }

  return (
    <TabPanel>
      <Stack spacing="30px">
        <Stack spacing="8px">
          <Heading fontSize="20px" fontWeight="400" lineHeight="28px">
            Página de exibição de conteúdos
          </Heading>
        </Stack>

        <Stack spacing="8px">
          <Heading fontSize="14px" fontWeight="500" lineHeight="17.07px">
            Cor de destaque
          </Heading>

          <Stack spacing="8px">
            <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#00000080">
              A cor que irá destacar alguns elementos da sua área de membros, como por exemplo: a
              barra de progresso, o ícone de “check” e etc.
            </Text>

            <ColorPicker
              name="color"
              inputSize="full"
              value={appearance?.highlightColor}
              onChange={onChangeHighlightColor}
              handleBlur={onSubmitHighlightColor}
              onClick={onClickHighlightColor}
              hasInput
            />
          </Stack>
        </Stack>

        <Stack spacing="8px">
          <Heading fontSize="14px" fontWeight="500" lineHeight="17.07px">
            Módulos
          </Heading>

          <Stack spacing="8px">
            <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#00000080">
              Módulos com os conteúdos que aparecem do lado esquerdo da página de visualização dos
              conteúdos.
            </Text>

            <HStack spacing="6px">
              <Switch
                size="sm"
                colorScheme="primary"
                isChecked={appearance?.showModulesAndContentsBar}
                onChange={onChangeModulesAndContent}
              />
              <Text>Mostrar Módulos e Conteúdos</Text>
            </HStack>
          </Stack>
        </Stack>
        <Stack spacing="8px">
          <Heading fontSize="14px" fontWeight="500" lineHeight="17.07px">
            Barra de progresso
          </Heading>

          <Stack spacing="8px">
            <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#00000080">
              Barra que exibi o progresso de seus alunos no produto.
            </Text>

            <HStack spacing="6px">
              <Switch
                size="sm"
                colorScheme="primary"
                isChecked={appearance?.showProgressBar}
                onChange={onChangeProgressBar}
              />
              <Text>Mostrar Barra de progresso</Text>
            </HStack>
          </Stack>
        </Stack>
      </Stack>
    </TabPanel>
  );
}
