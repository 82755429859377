import {
  ModalOverlay,
  ModalContent,
  Stack,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Modal,
  ModalFooter,
} from '@chakra-ui/react';
import { FormEvent } from 'react';
import { IoMdAlert } from 'react-icons/io';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';

export function ModalRemoveCourseAffiliate({
  isOpenRemoveProduct,
  onCloseRemoveProduct,
  sizeRemoveAffiliateProductModal,
  updateAfterRemoveAffiliateProduct,
  courseAffiliationId,
  productName,
}) {
  const { isLoading: isLoadingDeleteCourseAffiliate, handleSubmit: handleDeleteCourseAffiliate } =
    useHandleSubmit({
      url: `/affiliations/products/${courseAffiliationId}/course-affiliation`,
      method: 'delete',
      authenticated: true,
      onSuccess: {
        message: 'Curso removido com sucesso!',
        callback: updateAfterRemoveAffiliateProduct,
      },
    });

  async function onSubmitDeleteCourseAffiliate<T>(event: FormEvent<T>) {
    event.preventDefault();

    const result = await handleDeleteCourseAffiliate();

    if (result) {
      onCloseRemoveProduct();
    }
  }

  return (
    <Modal
      isOpen={isOpenRemoveProduct}
      onClose={onCloseRemoveProduct}
      size={sizeRemoveAffiliateProductModal}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmitDeleteCourseAffiliate}>
          <Stack direction="row" w="100%" alignItems="center" justifyContent="center" p={5}>
            <IoMdAlert color="#BB2124" size="50px" />
          </Stack>

          <ModalHeader textAlign="center" fontWeight={500}>
            Você deseja remover o produto {productName} dos seus produtos para afiliação?
          </ModalHeader>
          <ModalCloseButton style={{ boxShadow: 'none', outline: 'none' }} />
          <ModalBody textAlign="center" color="#718096">
            Lembra-se que todas as afiliações vinculadas a este produto também serão removidas.{' '}
          </ModalBody>

          <ModalFooter>
            <Stack direction="row" w="100%" alignItems="center" justifyContent="center" spacing={3}>
              <Button
                size="sm"
                w={150}
                colorScheme="gray"
                variant="solid"
                onClick={onCloseRemoveProduct}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                size="sm"
                w={150}
                isLoading={isLoadingDeleteCourseAffiliate}
                isDisabled={isLoadingDeleteCourseAffiliate || !courseAffiliationId}
                bgColor="#BB2124"
                color="#FFFFFF"
                _hover={{ bgColor: '#BB212480' }}
                _active={{ bgColor: '#BB212480' }}
                variant="solid"
              >
                Remover
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
