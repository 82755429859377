import {
  Box,
  Heading as ChakraHeading,
  Flex,
  Image,
  Select,
  Tab,
  TabList,
  Tabs,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import APIPageBuilder from '../../../../api/PageBuilder';
import AlertModal from '../../../../components/AlertModal';
import Carousel from 'react-slick';
import DropdownSection from '../components/DropdownSection';
import ErrorMessage from '../components/ErrorMessage';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import GridView from '../../../../assets/images/buttonsView/GridView.svg';
import { Heading } from '../../../../components/Heading';
import Line from '../components/Line';
import ListView from '../../../../assets/images/buttonsView/ListView.svg';
import { MdAddCircleOutline } from 'react-icons/md';
import NoData from '../components/NoData';
import Products from '../components/Carousel/Products';
import Section from '../Section';
import Spinner from '../../../../components/Loadings/Spinner';
import Toast from '../../../../components/Toast';
import Tooltip from '../components/Tooltip';
import { settingsCatProd } from '../components/Carousel/Arrows/slickSettings';
import styles from './styles.module.css';
import { useTheme } from '../../../../contexts/ThemeContext';

function Categories({ section, categories }) {
  const [isActiveSection, setIsActiveSection] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]?.id);
  const [categoriesSection, setCategoriesSection] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [viewMode, setViewMode] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const {
    theme,
    getTheme,
    getThemeMarketplace,
    createCategorySection,
    updateCategorySection,
    deleteCategorySection,
    isLoading: isLoadingTheme,
  } = useTheme();

  useEffect(() => {
    if (section?.themeCategory?.categoryId) {
      setCurrentCategory(section.themeCategory);
      setCategoriesSection(section.themeCategory);
      setSelectedCategory(section.themeCategory.categoryId);
      setIsActiveSection(!!section.active);
      setTabIndex(section.themeCategory.viewMode === 'grid' ? 1 : 0);
      setViewMode(section.themeCategory.viewMode);
    }
  }, [section]);

  function viewModeHandleChange(index) {
    if (index === 1) {
      setViewMode('grid');
      setTabIndex(index);
      setHasChanged(true);
      return;
    }

    setViewMode('list');
    setTabIndex(index);
    setHasChanged(true);
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    setHasChanged(false);
    setIsAdding(false);

    setIsActiveSection(!!section.active);

    if (categoriesSection) {
      setSelectedCategory(categoriesSection.categoryId);
      setCurrentCategory(categoriesSection.themeCategories);
      return;
    }

    setSelectedCategory(categories[0].id);
    setCurrentCategory(categories[0]);
  }

  function handleSelectedCategoryChange(event) {
    const { value } = event.target;
    const currentCategory = categories.find(category => category.id === Number(value));

    setHasChanged(true);
    setSelectedCategory(value);
    setCurrentCategory(currentCategory);
    setErrorMessage(null);
  }

  function addCategory() {
    setHasChanged(true);
    setIsActiveSection(true);
    setIsAdding(true);
  }

  async function handleDelete(event) {
    event.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);

    const categoriesSection = section;
    const theme_id = theme.id;
    const section_id = categoriesSection.id;
    const category_id = categoriesSection?.themeCategory?.id;
    try {
      await deleteCategorySection(theme_id, section_id, category_id);

      Toast('Categoria excluída com sucesso');
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
      setIsLoading(false);
      setIsShowDeleteModal(false);
    }
  }

  function toggleModal(event) {
    event.preventDefault();
    setIsShowDeleteModal(!isShowDeleteModal);
  }

  function toggleVisibilitySection(event) {
    const { checked } = event.target;

    setHasChanged(true);

    setIsActiveSection(!checked);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    setErrorMessage('');

    const category_id = selectedCategory;
    const theme_id = theme.id;
    const theme_category_id = categoriesSection?.id;

    const payload = {
      categoryId: category_id,
      activeSection: isActiveSection,
    };

    const method = categoriesSection ? 'update' : 'store';

    try {
      if (method === 'store') await createCategorySection(theme_id, payload);

      if (method === 'update') {
        payload.viewMode = viewMode;
        await updateCategorySection(theme_id, section.id, theme_category_id, payload);
      }

      const successMessage = method === 'store' ? 'criada' : 'atualizada';

      Toast(`Categoria ${successMessage} com sucesso`);
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
      setIsLoading(false);
      setIsAdding(false);
    }
  }

  async function handleDuplicateCategoriesSection() {
    const theme_id = theme.id;
    const section_id = section.id;

    setErrorMessage('');
    setIsLoading(true);

    try {
      await APIPageBuilder.category.store(theme_id, section_id);
      Toast('Sessão Categorias duplicada com sucesso!');
      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
      setIsLoading(false);
    }
  }

  async function handleDeleteCategoriesSection() {
    const theme_id = theme.id;
    const section_id = section.id;

    setErrorMessage('');

    try {
      await APIPageBuilder.category.delete(theme_id, section_id);

      Toast('Sessão de categorias excluída com sucesso!');

      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
    }
  }

  return (
    <Section
      description="Compartilhe os melhores comentários de seus alunos sobre seus produtos ou experiências. Lembre-se de colocar imagens de qualidade e padronizadas. Um dica: caso possua materiais com sua logo sempre peça para os alunos tirarem fotos com eles, isso vai gerar ainda mais autoridade para a sua marca."
      actionName="Categorias"
      handleDuplicate={handleDuplicateCategoriesSection}
      handleDelete={handleDeleteCategoriesSection}
      buttonsVisibility={true}
      hasChanged={hasChanged}
    >
      <div className={styles.sectionHeader}>
        <div className="d-flex align-items-center">
          <Heading as="h4" fontSize="2xl">
            {categoriesSection || isAdding ? `${currentCategory?.name}` : 'Categorias'}
          </Heading>
          <Line />
          <Tooltip link="" />
        </div>

        <Flex>
          <Flex alignItems="center" w={300}>
            <ChakraHeading as="h5" size="sm" pb={2}>
              Modo de exibição:
            </ChakraHeading>
            <Box className={styles.cardView} mx="5">
              <Tabs
                index={tabIndex}
                onChange={index => viewModeHandleChange(index)}
                align="center"
                variant="unstyled"
              >
                <TabList>
                  <Tab _selected={{ bg: 'rgb(235, 113, 41);' }}>
                    <Image src={ListView} boxSize="20px" alt="ListView" />
                  </Tab>
                  <Tab _selected={{ bg: 'rgb(235, 113, 41);' }}>
                    <Image src={GridView} boxSize="20px" alt="GridView" />
                  </Tab>
                </TabList>
              </Tabs>
            </Box>
          </Flex>

          <DropdownSection
            onClickToDuplicate={handleDuplicateCategoriesSection}
            onClickToDelete={handleDeleteCategoriesSection}
          />
        </Flex>
      </div>

      {!categoriesSection && !isAdding && (
        <div>
          <p className={styles.headerText}>
            Reúna seus melhores produtos em um único ambiente, para que seus alunos achem, com mais
            facilidades, cursos específicos de suas áreas de interesse. Você pode criar diversas
            categorias, clique no botão abaixo e adicione uma categoria ao seu site. Lembre-se,
            sempre escolha nomes pequenos e específicos.
          </p>
        </div>
      )}

      <div className={styles.container}>
        {!categories && <NoData />}

        {!categoriesSection && !isAdding && (
          <button onClick={addCategory} className="my-4" style={{ alignSelf: 'center' }}>
            <MdAddCircleOutline size="1.125rem" /> Adicionar seção de categoria
          </button>
        )}

        {(categoriesSection || isAdding) && (
          <form onSubmit={handleSubmit}>
            <section className={styles.formSection}>
              <Box display="flex" w="100%" justifyContent="space-between" alignItems="center">
                <Box w="100%" mr={12}>
                  <ChakraHeading pb={2} as="h5" size="sm" htmlFor={`selectedCategory${section.id}`}>
                    Selecione uma categoria:
                  </ChakraHeading>
                  <Select
                    id={`selectedCategory${section.id}`}
                    name="selectedCategory"
                    onChange={handleSelectedCategoryChange}
                    value={selectedCategory}
                  >
                    {categories?.map(category => (
                      <option key={`category${category.id}`} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Box>
            </section>

            <section className={styles.productsSection}>
              <h6>Produtos que fazem parte dessa categoria:</h6>

              <div>
                <Carousel {...settingsCatProd}>
                  {currentCategory?.courses?.map(course => (
                    <Products
                      key={course.id}
                      course={course}
                      loading={isLoading || isLoadingTheme}
                    />
                  ))}
                </Carousel>
              </div>
            </section>

            <div className={styles.actions}>
              {!isAdding && categoriesSection && (
                <button className={styles.deleteButton} onClick={toggleModal} disabled={isLoading}>
                  Excluir categoria
                </button>
              )}

              <button
                className={styles.cancelButton}
                onClick={event => handleDiscardChanges(event)}
                disabled={!hasChanged || isLoading}
              >
                Cancelar
              </button>

              <button type="submit" disabled={!hasChanged || isLoading}>
                {isLoading ? <Spinner small /> : 'Salvar alterações'}
              </button>
            </div>

            <div className={styles.footer}>
              <div>
                <input
                  type="checkbox"
                  id="isActiveSectionCategories"
                  name="isActiveSectionCategories"
                  onChange={toggleVisibilitySection}
                  checked={!isActiveSection}
                />
                <label htmlFor="isActiveSectionCategories">Ocultar seção da página inicial</label>
              </div>
            </div>
          </form>
        )}
      </div>

      {errorMessage && !hasChanged && <ErrorMessage message={errorMessage} />}

      <AlertModal
        showModal={isShowDeleteModal}
        onHideModal={() => setIsShowDeleteModal(false)}
        title={`Você tem certeza que deseja excluir esta categoria?`}
        actionButton={{ nameActionButton: 'Excluir', onActionButton: handleDelete }}
        actionButtonClassName="btn-danger"
        cancelableButton={{
          nameCancelableButton: 'cancelar',
          onCancelableButton: () => setIsShowDeleteModal(false),
        }}
        disabled={isLoading}
      />
    </Section>
  );
}

export default Categories;
