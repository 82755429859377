import { Input, InputProps } from '@chakra-ui/react';
import { useState } from 'react';
import InputMask from 'react-input-mask';

type DocumentInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (value: string) => void;
  isInternational: boolean;
};

const CPF_MASK = '999.999.999-99';
const CNPJ_MASK = '99.999.999/9999-99';
const OTHER_MASK = '*********************';

function unmask(value: string) {
  return value.replace(/\D/g, '');
}

function getMask(value: string) {
  const unmaskedValue = unmask(value);
  const size = unmaskedValue.length;
  const shouldApplyCNPJMask = size >= 12;
  return shouldApplyCNPJMask ? CNPJ_MASK : CPF_MASK;
}

export default function DocumentInput(props: DocumentInputProps) {
  const [mask, setMask] = useState(getMask((props.value as string) || ''));

  const { value, onChange, isInternational, ...rest } = props;

  function applyMask(size: number) {
    if (isInternational) {
      setMask(OTHER_MASK);
    } else {
      const shouldApplyCNPJMask = size >= 12;
      shouldApplyCNPJMask ? setMask(CNPJ_MASK) : setMask(CPF_MASK);
    }
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const value = unmask(event.currentTarget.value);
    const digit = event.key.replace(/\D/g, '');
    const size = value.concat(digit).length;
    applyMask(size);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const unmaskedValue = isInternational ? event.target.value : unmask(event.target.value);
    const size = unmaskedValue.length;
    applyMask(size);
    onChange?.(unmaskedValue);
  }

  return (
    <InputMask
      mask={mask}
      maskChar=""
      value={value}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
    >
      {(inputProps: InputProps) => (
        <Input
          placeholder={`Digite o seu ${isInternational ? 'Documento' : 'CPF/CNPJ'}`}
          {...rest}
          {...inputProps}
        />
      )}
    </InputMask>
  );
}
