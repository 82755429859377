import Joi from 'joi';

export type AddProductSchemaType = {
  showcaseId: number;
  productsIds: number[];
  showcaseType?: 'plans' | 'products';
};

export const addProductSchemaValidator = Joi.object<AddProductSchemaType>().keys({
  showcaseId: Joi.number().integer().required(),
  productsIds: Joi.array().items(Joi.number().integer().positive()).min(1).required().messages({
    'array.min': 'Selecione pelo menos um produto.',
  }),
  showcaseType: Joi.string().valid('products', 'plans').required(),
});
