import { toast } from 'react-toastify';
import process from 'process/browser';
import Toasty from '../../assets/images/toasty.png';
import ManoelGomes from '../../assets/images/manoel-gomes.png';

const Toast = (message: string, type: 'success' | 'warn' | 'error' = 'success') => {
  switch (type) {
    case 'success':
      return toast.success(
        message,
        process.env.NODE_ENV === 'development'
          ? {
              style: {
                backgroundImage: `url(${ManoelGomes})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'right',
              },
            }
          : {}
      );
    case 'warn':
      return toast.warn(message);
    case 'error':
      return toast.error(
        message,
        process.env.NODE_ENV === 'development'
          ? {
              style: {
                backgroundImage: `url(${Toasty})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'right',
              },
            }
          : {}
      );
    default:
      return toast.success(message);
  }
};

export default Toast;
