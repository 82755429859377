import { useState, ChangeEvent, FormEvent } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { useTheme } from '../../../../contexts/ThemeContext';
import ResetPasswordAPI from '../../../../api/ResetPassword';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import Toast from '../../../../components/Toast';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  ButtonSpinner,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';

export default function ResetPasswordV2() {
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [hasChange, setHasChange] = useState<boolean>(false);

  const history = useHistory();

  const { themeMarketplace } = useTheme();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    setHasChange(true);

    try {
      await ResetPasswordAPI.sendEmail({ email });

      Toast('E-email enviado! Verifique sua caixa de entrada.');
      setHasChange(false);
      history.push('/login');
    } catch (error) {
      setHasChange(false);
      setErrorMessage(ErrorResponse(error));
    } finally {
      setLoading(false);
    }
  }

  function handleChangeEmail(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }
  return (
    <Flex
      height="full"
      justifyContent="center"
      alignItems="center"
      bgGradient="linear(to-r, primary.500, primary.200)"
      color="secondary.500"
      overflowY="auto"
      paddingY="8"
      padding={{ base: 16, md: 'initial' }}
    >
      <form onSubmit={handleSubmit}>
        <Stack
          spacing={7}
          display="flex"
          justifyContent="center"
          height="561px"
          width="26rem"
          px={8}
        >
          <Flex justifyContent="center">
            <Link to="/">
              <Image src={themeMarketplace?.logo} maxWidth="264px" />
            </Link>
          </Flex>
          <FormControl>
            <FormLabel htmlFor="email" color="textColor">
              Email
            </FormLabel>
            <Input
              id="email"
              type="email"
              name="email"
              placeholder="Insira seu e-mail"
              _placeholder={{ color: 'secondary.600' }}
              required
              onChange={handleChangeEmail}
              value={email}
            />
          </FormControl>
          <Button
            colorScheme="primary"
            borderRadius="none"
            borderWidth={1}
            bg="textColor"
            fontWeight="bold"
            color="primary.500"
            borderColor="textColor"
            isLoading={loading}
            type="submit"
            cursor={hasChange ? 'not-allowed' : 'pointer'}
            opacity={hasChange ? '0.5' : '1'}
            disabled={hasChange}
          >
            {loading ? <ButtonSpinner color="textColor" /> : 'Redefinir senha'}
          </Button>

          {errorMessage && (
            <Alert
              status="error"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <AlertTitle>Erro ao enviar e-mail</AlertTitle>
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}
          <Text textAlign="center" color="textColor">
            Ao clicar em
            <Text as="line" fontWeight="bold" ml={1}>
              Redefinir senha
            </Text>
            , será enviado um e-mail para redefinir sua senha.
          </Text>
          <Text textAlign="center" color="textColor">
            Já possui cadastro?
          </Text>
          <Link
            to="/login"
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
              pointerEvents: loading ? 'none' : 'all',
            }}
          >
            <Text textAlign="center" color="textColor">
              Entrar
            </Text>
          </Link>
        </Stack>
      </form>
    </Flex>
  );
}
