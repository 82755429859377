import { Select, Text, VStack } from '@chakra-ui/react';
import FilterType from '../../../types/Filter';

interface FilterProps {
  name: FilterType['name'];
  value?: string | number | readonly string[];
  label: FilterType['label'];
  filtersList: any;
  onAddFilter: (filter: FilterType) => void;
}

export default function Filter({ name, value, label, filtersList, onAddFilter }: FilterProps) {
  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value: filterId } = event.target;

    const filterBeingAdded = filtersList.find(option => option.id === Number(filterId));

    const filter = {
      id: Number(filterId),
      name,
      value: filterBeingAdded?.name,
    } as FilterType;

    onAddFilter(filter);
  }

  return (
    <VStack
      align="start"
      width={{ base: '100%', md: '50%', xl: '25%' }}
      sx={{
        '.chakra-select__icon': {
          color: '#2D3748',
        },
      }}
    >
      <Text color="#202123">{label}</Text>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        focusBorderColor="default.500"
        bg="#ffffff"
        color="#2D374880"
      >
        <option hidden>Selecione</option>

        {filtersList?.map(option => (
          <option key={option.name + option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Select>
    </VStack>
  );
}
