import SearchInput from './SearchInput';
import { Box, Center, Heading } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Paginate from '../../../components/Paginate';
import useFetch from '../../../hooks/useFetch';
import useHandleSubmit from '../../../hooks/useHandleSubmit';
import hasFilterParams from '../../../utils/hasFilterParams';
import Loading from './Loading';
import PromotionsHeader from './PromotionsHeader';
import PromotionsMobileList from './PromotionsMobileList';
import PromotionsTable from './PromotionsTable';
import { PromotionView } from './types';

interface PromotionsResponse {
  promotions: PromotionView[];
  page: number;
  per_page: number;
  total: number;
}

function getStatusToEdit(status: PromotionView['status']) {
  if (status === 'ATIVO') return 'INATIVO';

  return 'ATIVO';
}

export default function Promotions() {
  const [promotionToEdit, setPromotionToEdit] = useState<PromotionView>({} as PromotionView);
  const location = useLocation();
  const history = useHistory();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryString = urlSearchParams.toString();

  const {
    fetchData: fetchPromotions,
    data: response,
    loading: isLoading,
  } = useFetch<UnificadaFront.ResponseJSON<PromotionsResponse>>({
    method: 'get',
    url: `/promotion?${queryString}`,
    authenticated: true,
    autoFetch: true,
  });

  const { handleSubmit: updatePromotionStatus } = useHandleSubmit({
    method: 'patch',
    data: { status: getStatusToEdit(promotionToEdit?.status) },
    url: `/promotion/${promotionToEdit?.id}/update-status`,
    authenticated: true,
    onSuccess: {
      message: 'Status da promoção alterado com sucesso!',
      callback: fetchPromotions,
    },
  });

  const handleSelectPromotionToEdit = useCallback(async (promotion: PromotionView) => {
    setPromotionToEdit(promotion);
  }, []);

  const handlePromotionStatusChange = useCallback(async () => {
    await updatePromotionStatus();
  }, [updatePromotionStatus]);

  const handleSearchChange = useCallback(
    (search: string) => {
      if (!search) {
        return history.push({
          pathname: '/promotions',
          search: '?page=1',
        });
      }

      urlSearchParams.set('page', '1');
      urlSearchParams.set('search', search);

      history.push({
        pathname: '/promotions',
        search: queryString,
      });
    },
    [history, queryString, urlSearchParams]
  );

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({
      search: urlSearchParams.toString(),
    });
  }

  const promotions = response?.data?.promotions;
  const isFiltering = hasFilterParams(urlSearchParams);
  const isNotFoundVisible = !isLoading && !promotions?.length && isFiltering;
  const isEmpty = !isLoading && !promotions?.length && !isFiltering;
  const total = response?.data?.total;
  const perPage = response?.data?.per_page || 1;
  const pageCount = Math.ceil(total / perPage);
  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const isPaginationVisible = !isLoading && pageCount > 1;
  const isPromotionsListVisible = !isLoading && !!promotions?.length;

  return (
    <Box>
      <PromotionsHeader />

      <SearchInput mt={8} onSearch={handleSearchChange} />

      {isLoading && <Loading />}

      {isNotFoundVisible && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.300">
            Nenhum resultado encontrado...
          </Heading>
        </Center>
      )}

      {isEmpty && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.300">
            Você não possui nenhuma promoção cadastrada.
          </Heading>
        </Center>
      )}

      {isPromotionsListVisible && (
        <>
          <PromotionsTable
            promotions={promotions}
            onPromotionStatusChange={handlePromotionStatusChange}
            onSelectPromotionToEdit={handleSelectPromotionToEdit}
            mt="0.875rem"
            display={{ base: 'none', xl: 'block' }}
          />

          <PromotionsMobileList
            promotions={promotions}
            onPromotionStatusChange={handlePromotionStatusChange}
            onSelectPromotionToEdit={handleSelectPromotionToEdit}
            mt={2.5}
            display={{ base: 'block', xl: 'none' }}
          />
        </>
      )}

      {isPaginationVisible && (
        <Paginate
          pageCount={pageCount}
          initialPage={currentPage}
          onPageChange={handlePageChange}
          mt={{ base: 8, xl: 4 }}
        />
      )}
    </Box>
  );
}
