import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: auto;
  width: 100%;

  padding: 24px 32px;

  background: #ffffff;
  border: 1px solid rgba(32, 33, 35, 0.25);
  border-radius: 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
`;
