import './styles.css';

import { Modal } from 'react-bootstrap';
import stylesSupport from './styles.module.css';
import { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const date = new Date().getFullYear();

function FooterSite() {
  const { themeMarketplace } = useTheme();
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <footer className="nk-footer d-flex flex-column" style={{ border: 0 }}>
        <div className="row px-5 justify-content-between footer__logo">
          <div className="col-sm-12 col-md">
            <img src={themeMarketplace.logo} width="140px" className="img-fluid" alt="" />
          </div>

          <div className="col-sm-12 col-md d-flex align-items-center justify-content-md-end">
            <h2 className="description-footer footer__subtitle">Liberdade para ensinar.</h2>
          </div>
        </div>

        <div className="row px-5 mt-4 footer__navigation">
          <div className="col">
            <div className="d-flex justify-content-between flex-md-column-2 justify-content-start-md">
              <ul>
                <h3 className="footer__section">Recursos</h3>
                <li>
                  <a
                    href="https://ensine.proluno.com.br/"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link para página ensine.proluno.com.br"
                  >
                    Cursos Online
                  </a>
                </li>
                <li>
                  <a
                    href="https://ensine.proluno.com.br/"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link para página ensine.proluno.com.br"
                  >
                    Downloads digitais
                  </a>
                </li>
                <li>
                  <a
                    href="https://ensine.proluno.com.br/"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link para página ensine.proluno.com.br"
                  >
                    Cursos ao vivo
                  </a>
                </li>
                <li>
                  <a
                    href="https://ensine.proluno.com.br/"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link para página ensine.proluno.com.br"
                  >
                    Simulados
                  </a>
                </li>
                <li>
                  <a
                    href="https://ensine.proluno.com.br/"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link para página ensine.proluno.com.br"
                  >
                    Construa seu site
                  </a>
                </li>
              </ul>

              <ul>
                <h3 className="footer__section">Empresa</h3>
                <li>
                  <a
                    href="https://ensine.proluno.com.br/#clients"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link para página ensine.proluno.com.br"
                  >
                    Clientes
                  </a>
                </li>
                {/* <li>
                  <Link to="/nossa-historia">Nossa história</Link>
                </li>
                <li>
                  <Link to="cursos-ao-vivo">Preços</Link>
                </li>
                <li>
                  <Link to="/integracoes">Integrações</Link>
                </li>
                <li>
                  <Link to="/programa-indicacao">Programa de indicação</Link>
                </li> */}
              </ul>

              {/* <ul>
                <h3 className="footer__section">Comparar</h3>
                <li>
                  <Link to="/hotmart">Hotmart</Link>
                </li>
                <li>
                  <Link to="/eadbox">EadBox</Link>
                </li>
                <li>
                  <Link to="udemy">Udemy</Link>
                </li>
                <li>
                  <Link to="/por-que-proluno">Por que escolher a Proluno</Link>
                </li>
              </ul> */}

              <ul>
                <h3 className="footer__section">Contato</h3>
                <li>
                  <a
                    href="https://api.whatsapp.com/message/SM5OFJF5TQVTL1"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link para whatsapp"
                  >
                    Converse com a gente
                  </a>
                </li>
                {/* <li>
                  <Link to="/quem-ajuda">Quem irá te ajudar</Link>
                </li> */}
                {/* <li>
                  <Link title="link para o suporte" onClick={() => setIsShowModal(true)}>
                    Documentos de ajuda
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/por-que-proluno">Por que escolher a Proluno</Link>
                </li> */}
              </ul>

              <ul>
                <h3 className="footer__section">Ferramentas de ajuda</h3>
                <li>
                  <a
                    href="https://materiais.proluno.com.br/guia-completo-de-como-vender-cursos-online"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link para guia de vendas"
                  >
                    Como criar e vender um curso
                  </a>
                </li>
                <li>
                  <a
                    href="https://materiais.proluno.com.br/mapa-da-empatia"
                    role="button"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="link para mapa empatia"
                  >
                    Mapa da empatia
                  </a>
                </li>
                {/* <li>
                  <Link to="/artigos">Artigos</Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-sm-start justify-content-md-center my-2 ml-5">
          <div>
            <h5 className="text-white ">&copy;{` ${date} Proluno, Ltda`}</h5>
          </div>
        </div>
      </footer>
      <Modal
        centered
        size="lg"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="h-auto">
            <p className={stylesSupport.text}>Opa, está precisando de ajuda?</p>
            <br />
            <p className={stylesSupport.text}>
              Não se preocupe, estamos sempre atentos para lhe ajudar da melhor forma.
            </p>
            <br />
            <p className={stylesSupport.text}>
              Para nos chamar basta enviar um simples e-mail para:
            </p>

            <div className={stylesSupport.bold}>
              <b style={{ fontWeight: 600 }}>
                support@proluno.zendesk.com <br />
              </b>
              <br />
            </div>

            <p className={stylesSupport.text}>
              Siga esses passos para garantir que saberemos exatamente do que está precisando:
            </p>
            <ol className={stylesSupport.listItems}>
              <br />
              <li> Envie um e-mail para o endereço informado acima.</li>
              <li>
                No assunto, informe o problema que está acontecendo/dúvida ou tipo de solicitação.
              </li>
              <li>
                No corpo do e-mail, relate o seu problema com o máximo de informações possíveis e
                anexe prints se necessário, e no caso de solicitações adicione exemplos.
              </li>
            </ol>
            <br />
            <p className={stylesSupport.text}>
              Em pouco tempo receberá a ajuda que está precisando.
            </p>
            <br />
            <span className={stylesSupport.text}>Conte sempre com a gente.</span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FooterSite;
