import Joi from 'joi';

export type IntegrationGTMSchemaType = {
  code: string;
  status: boolean;
};

export const integrationGTMSchemaValidator = Joi.object<IntegrationGTMSchemaType>().keys({
  code: Joi.string()
    .regex(/^GTM-[A-Za-z0-9]*$/)
    .required()
    .messages({
      'string.pattern.base': 'O código GTM fornecido não está em um formato válido.',
      'any.required': 'O código GTM é obrigatório.',
      'string.empty': 'O código GTM é obrigatório.',
    } as JoiMessages.LanguageMessages),
  status: Joi.boolean()
    .required()
    .messages({
      'any.required': 'O status é obrigatório.',
      'boolean.base': 'O status é obrigatório.',
    } as JoiMessages.LanguageMessages),
});
