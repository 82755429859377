import {
  Box,
  Button,
  Center,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
} from '@chakra-ui/react';

export type IColorPickerChange = {
  name: string;
  value: string;
};

interface ColorPickerProps {
  name?: string;
  defaultColor?: string;
  value?: string;
  isDisabled?: boolean;
  hasInput?: boolean;
  inputSize?: string;
  buttonSize?: string;
  onChange?: (props: IColorPickerChange) => void;
  handleBlur?: () => void;
  onClick?: (value: string) => void;
}

function ColorPicker({
  name,
  value,
  hasInput = false,
  inputSize = '12.5rem',
  buttonSize = '2rem',
  onChange,
  handleBlur,
  onClick,
}: ColorPickerProps) {
  const defaultColors = [
    '#38A169',
    '#E53E3E',
    '#DD6B20',
    '#D69E2E',
    '#805AD5',
    '#3182CE',
    '#00B5D8',
    '#D53F8C',
    '#202123',
  ];

  return (
    <Box display="flex" gap="2">
      <Popover variant="picker">
        <PopoverTrigger>
          <Button
            id="labelColor"
            aria-label={value}
            background={value}
            width={buttonSize}
            height="2rem"
            padding={0}
            minWidth="unset"
            borderRadius="md"
            boxShadow="inner"
            borderColor="gray.100"
            borderWidth="thin"
          />
        </PopoverTrigger>
        <PopoverContent width="10.625rem">
          <PopoverArrow bg={value} />
          <PopoverCloseButton color="white" />
          <PopoverHeader
            height="6.25rem"
            backgroundColor={value}
            borderTopLeftRadius={5}
            borderTopRightRadius={5}
            color={value === 'white' ? 'black' : 'white'}
          >
            <Center height="100%">{value}</Center>
          </PopoverHeader>
          <PopoverBody height="7.5rem">
            <SimpleGrid columns={5} spacing={2}>
              {defaultColors.map(defaultColor => {
                return (
                  <Button
                    key={defaultColor}
                    aria-label={defaultColor}
                    background={defaultColor}
                    height="1.375rem"
                    width="1.375rem"
                    padding={0}
                    minWidth="unset"
                    borderRadius={3}
                    _hover={{ background: defaultColor }}
                    onClick={() => {
                      onChange({ name, value: defaultColor });
                      onClick(defaultColor);
                    }}
                  />
                );
              })}
            </SimpleGrid>
            <Input
              name={name}
              size="sm"
              placeholder={value}
              borderRadius={3}
              marginTop={3}
              value={value}
              onBlur={handleBlur}
              onChange={event => onChange({ name, value: event.target.value })}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>

      {hasInput && (
        <Input
          value={value}
          onChange={event => onChange({ name, value: event.target.value })}
          name={name}
          size="md"
          width={inputSize}
          height="2rem"
          placeholder="#000000"
          variant="outline"
          fontSize="sm"
          fontWeight="medium"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="md"
          focusBorderColor="gray.200"
          onBlur={handleBlur}
          _placeholder={{ color: 'blackAlpha.500' }}
        />
      )}
    </Box>
  );
}

export default ColorPicker;
