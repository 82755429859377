import { useToast } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { axiosBackend } from '../../../api/Configuration';
import ErrorResponse from '../../../helpers/ErrorResponse';
import useFetch from '../../../hooks/useFetch';
import hasFilterParams from '../../../utils/hasFilterParams';

export interface UtmItem {
  id: number;
  link: string;
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  salesQuantity: number;
  utmId: string;
  utmTerm: string;
  utmContent: string;
}

interface UtmListResponse {
  data: UtmItem[];
  page: number;
  per_page: number;
  total: number;
}

export function useUtmGenerator() {
  const [isDeleting, setIsDeleting] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const toast = useToast();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { data: response, loading: isFetching } = useFetch<
    UnificadaFront.ResponseJSON<UtmListResponse>
  >({
    method: 'get',
    url: `/utm/list?${urlSearchParams.toString()}`,
    authenticated: true,
    autoFetch: true,
  });

  const handleSearchChange = useCallback(
    (search: string) => {
      if (!search) return history.push('/utm-generator');

      urlSearchParams.set('page', '1');
      urlSearchParams.set('search', search);

      return history.push({
        pathname: '/utm-generator',
        search: urlSearchParams.toString(),
      });
    },
    [history, urlSearchParams]
  );

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  async function handleDelete(id: number) {
    try {
      setIsDeleting(true);

      await axiosBackend().delete(`/webhook-proluno/${id}/webhook`);

      toast({
        title: 'Webhook removido com sucesso!',
        status: 'success',
        position: 'top',
      });

      history.push({
        pathname: location.pathname,
        search: location.pathname,
      });
    } catch (error) {
      toast({
        status: 'error',
        title: ErrorResponse(error),
      });
    } finally {
      setIsDeleting(false);
    }
  }

  async function handleExportInformation(utmId: number) {
    try {
      let urlDownload = `/utm/${utmId}/export?typeFile=CSV`;

      const axios = axiosBackend();

      const response = await axios({
        url: urlDownload,
        method: 'GET',
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');

      link.href = url;

      link.setAttribute('download', 'utm-informacoes.csv');

      document.body.appendChild(link);

      link.click();
    } catch (error) {
      throw error;
    }
  }

  const utmList = response?.data?.data;
  const total = response?.data?.total;
  const perPage = response?.data?.per_page || 1;
  const pageCount = Math.ceil(total / perPage);
  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const isFiltering = hasFilterParams(urlSearchParams);
  const isLoading = isFetching || isDeleting;
  const isEmpty = !isLoading && !utmList?.length && !isFiltering;
  const isNotFoundVisible = false;
  const isUtmListVisible = !isLoading && !!utmList?.length;
  const isPaginationVisible = !isLoading && pageCount > 1;

  return {
    utmList,
    urlSearchParams,
    isNotFoundVisible,
    isLoading,
    isPaginationVisible,
    isUtmListVisible,
    isEmpty,
    currentPage,
    pageCount,
    handleSearchChange,
    handlePageChange,
    handleDelete,
    handleExportInformation,
  };
}
