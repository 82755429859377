import { AccordionButton, AccordionItem, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { memo } from 'react';
import ModuleSubitemList from './ModuleSubItemList';
import { IModuleProps } from '../../../types';
import { isModuleAvailable } from '../../../../../utils/isModuleAvailable';
import { MdLockOutline as LockIcon } from 'react-icons/md';
import dayjs from 'dayjs';

function getReleaseDateText(releaseDate) {
  return `Liberação: ${dayjs(releaseDate).utc().format('DD/MM/YYYY')}`;
}

function getContentsText(contents) {
  if (!contents.length) {
    return 'Módulo sem conteúdos';
  }
  return `${contents.length} ${contents.length > 1 ? 'conteúdos' : 'conteúdo'}`;
}

function Module({ name, contents, courseId, moduleId, highlightColor, releaseDate }: IModuleProps) {
  const hasProducts = !!contents.length;

  return (
    <AccordionItem border="none" isDisabled={!hasProducts || !isModuleAvailable(releaseDate)}>
      {({ isExpanded }) => (
        <>
          <AccordionButton pt="10px" pb={isExpanded ? '0' : '25px'} px={0}>
            <VStack alignItems="flex-start" textAlign="start">
              <HStack spacing={1}>
                {!isModuleAvailable(releaseDate) && (
                  <Icon as={LockIcon} boxSize={{ base: 4, xl: 4 }} color="white" />
                )}

                <Text
                  noOfLines={1}
                  color="#FFF"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="normal"
                  title={name}
                >
                  {name}
                </Text>
              </HStack>

              <Text
                hidden={isExpanded}
                color="#979CA6"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
              >
                {isModuleAvailable(releaseDate)
                  ? getContentsText(contents)
                  : getReleaseDateText(releaseDate)}
              </Text>
            </VStack>
          </AccordionButton>

          {ModuleSubitemList({ courseId, moduleId, contents, highlightColor })}
        </>
      )}
    </AccordionItem>
  );
}

export default memo(Module);
