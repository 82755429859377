import { Box, Icon, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { MdInfoOutline as InfoIcon } from 'react-icons/md';

const CLOSE_TOOLTIP_DELAY = 5000;

interface RecurrencePlanTooltipProps {
  recurrencePlanName: string;
}

export function RecurrencePlanTooltip({ recurrencePlanName }: RecurrencePlanTooltipProps) {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  function handleTooltipToggle() {
    setTooltipOpen(!isTooltipOpen);
    if (!isTooltipOpen) {
      setTimeout(() => {
        setTooltipOpen(false);
      }, CLOSE_TOOLTIP_DELAY);
    }
  }

  function handleMouseEnter() {
    setTooltipOpen(true);
  }

  function handleMouseLeave() {
    setTooltipOpen(false);
  }

  return (
    <>
      <Tooltip
        isOpen={isTooltipOpen}
        label={
          <>
            Esse produto está inserido
            <br />
            no plano: {recurrencePlanName}
          </>
        }
        aria-label="Plano de recorrência"
        hasArrow
        bg="#171923"
        placement="top"
        color="#ffffff"
        textAlign="center"
        marginBottom={3}
        padding={2}
        borderRadius="4px"
      >
        <Box
          position="absolute"
          top={{ base: 1, lg: 2.5 }}
          right={{ base: 2, lg: 7 }}
          zIndex="popover"
          onClick={handleTooltipToggle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          cursor="pointer"
        >
          <Icon as={InfoIcon} boxSize={{ base: 4, lg: 5 }} color="white" />
        </Box>
      </Tooltip>
    </>
  );
}
