import { useEffect, useState } from 'react';

import { nanoid } from 'nanoid';
import CategoryApi from '../../../../../api/Category';
import Toast from '../../../../../components/Toast';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { sortByOrder } from '../../../../../helpers/SortHelper';
import About from '../../About';
import Categories from '../../Categories';
import Faq from '../../Faq';
import MainBanner from '../../MainBanner';
import Medias from '../../Medias';
import Products from '../../Products';
import Testimonials from '../../Testimonials';

function draggableSections(section, categories) {
  return {
    bannerHero: <MainBanner section={section} />,
    categories: <Categories section={section} categories={categories} />,
    biography: <About section={section} />,
    frequentlyQuestions: <Faq />,
    products: <Products section={section} />,
    multimedia: <Medias section={section} />,
    testimonials: <Testimonials section={section} />,
    // html: <HTMLSection section={section} />,
  }[section.name];
}

function DraggableSectionsList({ sections = [] }) {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function getCategories() {
      try {
        const { data: categoriesData } = await CategoryApi.index();

        if (categoriesData?.length) {
          setCategories(categoriesData);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }

    getCategories();
  }, []);

  return (
    <ul>
      {sections.length
        ? sections
            .sort(sortByOrder)
            .map(section => (
              <li key={nanoid()}>{draggableSections(section, categories) || null}</li>
            ))
        : ''}
    </ul>
  );
}

export default DraggableSectionsList;
