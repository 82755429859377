import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FormEvent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import useHandleChange from '../../../../hooks/useHandleChange';
import useHandleSubmit, { ResponseJSON } from '../../../../hooks/useHandleSubmit';
import Header from './Header';
import ModalConfirmReset from './ModalConfirmReset';
import { integrationGTMSchemaValidator } from './integrationGTMSchemaValidator';

interface IGTM {
  id?: number;
  code: string;
  status: boolean;
}
interface IResponseGTM {
  id: number;
  code: string;
  status: boolean;
}

export default function GoogleTagManager() {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { id } = useParams<{ id: string }>();

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<IGTM>>({
    url: '/google-tag-manager',
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  const googleTagManagerData = response?.data;

  const { form, setForm, onChanged, setOnChanged, handleChange } = useHandleChange<IGTM>({
    code: '',
    status: false,
  });

  useEffect(() => {
    if (googleTagManagerData) {
      setForm(googleTagManagerData);
    }
  }, [googleTagManagerData, setForm]);

  useEffect(() => {
    if (googleTagManagerData?.id) {
      const pathName = window.location.pathname;

      if (pathName !== `/integrations/google-tag-manager/${googleTagManagerData.id}/gtm`) {
        history.push(`/integrations/google-tag-manager/${googleTagManagerData.id}/gtm`);
      }
    }
  }, [googleTagManagerData?.id, history]);

  const payload = {
    code: form.code,
    status: form.status,
  };

  const { isLoading: isDeleting, handleSubmit: handleDelete } = useHandleSubmit({
    data: payload,
    schemaValidator: integrationGTMSchemaValidator,
    url: `/google-tag-manager/${id}/gtm`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Google Tag Manager deletado com sucesso!',
      callback() {
        setOnChanged(false);
        setForm({ code: '', status: false });
        history.push(`/integrations/google-tag-manager`);
      },
    },
  });

  const {
    isLoading: isUpdating,
    handleSubmit: handleUpdate,
    formValidation: formValidationUpdate,
  } = useHandleSubmit<IGTM, ResponseJSON<IResponseGTM>>({
    data: payload,
    schemaValidator: integrationGTMSchemaValidator,
    url: `/google-tag-manager/${id}/gtm`,
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Google Tag Manager atualizado com sucesso!',
      callback() {
        setOnChanged(false);
      },
    },
  });

  const {
    isLoading: isSubmitting,
    handleSubmit,
    formValidation,
  } = useHandleSubmit<IGTM, ResponseJSON<IResponseGTM>>({
    data: payload,
    schemaValidator: integrationGTMSchemaValidator,
    url: '/google-tag-manager',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Google Tag Manager integrado com sucesso!',
      callback(response) {
        if (response?.data?.data?.id) {
          setOnChanged(false);

          const idGTM = response?.data?.data?.id;
          history.push(`/integrations/google-tag-manager/${idGTM}/gtm`);
        }
      },
    },
  });

  const isDisabled = loading || isDeleting || isUpdating || isSubmitting;

  async function onCancel() {
    if (googleTagManagerData) {
      setForm(googleTagManagerData);
    } else {
      setForm({ code: '', status: false });
    }
  }

  async function onDelete<T>(event: FormEvent<T>) {
    event.preventDefault();

    if (id) {
      await handleDelete();
      onClose();
    }
  }

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    if (id) {
      return await handleUpdate();
    }

    await handleSubmit();
  }

  const codeIsInvalid = formValidation?.code?.isInvalid || formValidationUpdate?.code?.isInvalid;
  const codeErrorMessage = formValidation?.code?.message || formValidationUpdate?.code?.message;
  const statusIsInvalid =
    formValidation?.status?.isInvalid || formValidationUpdate?.status?.isInvalid;
  const statusErrorMessage =
    formValidation?.status?.message || formValidationUpdate?.status?.message;

  return (
    <Stack spacing={10} as="form" onSubmit={onSubmit}>
      <Header />

      <Stack spacing={10}>
        <FormControl isInvalid={codeIsInvalid}>
          <FormLabel fontSize="sm">
            Tag{' '}
            <Text as="span" color="red.500">
              *
            </Text>
          </FormLabel>
          <Input
            size="sm"
            borderRadius={5}
            placeholder="Cole aqui"
            name="code"
            value={form.code.toLocaleUpperCase()}
            onChange={handleChange}
            focusBorderColor="primary.500"
          />

          <FormErrorMessage>{codeErrorMessage}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={statusIsInvalid}>
          <FormLabel fontSize="sm">Status</FormLabel>
          <Switch
            size="sm"
            name="status"
            display="flex"
            colorScheme="primary"
            alignItems="center"
            isChecked={form.status}
            onChange={handleChange}
          >
            {form.status ? 'Ativado' : 'Desativado'}
          </Switch>
          <FormErrorMessage>{statusErrorMessage}</FormErrorMessage>
        </FormControl>
      </Stack>

      <HStack width="full" spacing="15px" justifyContent={{ base: 'center', lg: 'flex-end' }}>
        <Box width={{ base: 'full', lg: 'auto' }}>
          <Button
            width="full"
            size="sm"
            colorScheme="gray"
            isDisabled={isDisabled || !onChanged}
            onClick={onCancel}
          >
            Cancelar
          </Button>
        </Box>
        <Box width={{ base: 'full', lg: 'auto' }}>
          <Button
            width="full"
            size="sm"
            colorScheme="primary"
            variant="outline"
            isDisabled={isDisabled || !form.code}
            onClick={onOpen}
          >
            Redefinir
          </Button>
        </Box>
        <Box width={{ base: 'full', lg: 'auto' }}>
          <Button
            width="full"
            type="submit"
            color="secondary.500"
            size="sm"
            colorScheme="primary"
            isDisabled={isDisabled || !onChanged || !form.code}
          >
            Salvar
          </Button>
        </Box>
      </HStack>

      <ModalConfirmReset isOpen={isOpen} onClose={onClose} handleDelete={onDelete} />
    </Stack>
  );
}
