import { Badge, Box, Breadcrumb, BreadcrumbItem, Image, Stack, Text } from '@chakra-ui/react';
import Question from '../../QuestionLibrary/types/Question';
import Trash from '../../../../../assets/icons/trash.svg';

interface IProps {
  questions: Question[];
  hiddenTrash?: boolean;
  removeQuestionId?: (id: number) => void;
}

export default function QuestionCardView({
  questions,
  hiddenTrash = false,
  removeQuestionId,
}: IProps) {
  return (
    <Stack spacing="10px">
      {questions?.length > 0 &&
        questions?.map((question, index) => (
          <Stack
            key={index}
            padding="20px"
            direction="column"
            alignItems="flex-start"
            gap="10px"
            borderRadius="10px"
            border="1px solid rgba(32, 33, 35, 0.05)"
            background="#FFF"
            boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
          >
            <Stack
              direction="row"
              width="full"
              justifyContent="space-between"
              alignItems={{ base: 'self-start', md: 'center', lg: 'center' }}
            >
              <Stack direction="row" spacing={0} alignItems="center" gap="8px" flexWrap="wrap">
                <Badge
                  display="flex"
                  height="20px"
                  padding="0px 8px"
                  alignItems="center"
                  gap="6px"
                  borderRadius="6px"
                  color="#fff"
                  background="#EB7129"
                >
                  <Text
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="500"
                    lineHeight="16px"
                    color="#FFF"
                  >
                    ID: {question.id}
                  </Text>
                </Badge>

                <Breadcrumb
                  spacing="8px"
                  separator={'/'}
                  color="#AFAFAF"
                  fontFamily="Inter"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="24px"
                >
                  <BreadcrumbItem hidden={!question?.questionLibraryArea}>
                    <Text noOfLines={1}>{question?.questionLibraryArea?.name}</Text>
                  </BreadcrumbItem>
                  <BreadcrumbItem hidden={!question?.questionLibraryDiscipline}>
                    <Text noOfLines={1}>{question?.questionLibraryDiscipline?.name}</Text>
                  </BreadcrumbItem>
                  <BreadcrumbItem hidden={!question?.questionLibrarySubject}>
                    <Text noOfLines={1}>{question?.questionLibrarySubject?.name}</Text>
                  </BreadcrumbItem>

                  {question?.questionLibraryInstitution && (
                    <BreadcrumbItem>
                      <Text noOfLines={1}>{question?.questionLibraryInstitution?.name}</Text>
                    </BreadcrumbItem>
                  )}
                </Breadcrumb>
              </Stack>

              <Box
                cursor="pointer"
                hidden={hiddenTrash}
                minWidth="16px"
                onClick={() => removeQuestionId(question.id)}
              >
                <Image src={Trash} />
              </Box>
            </Stack>

            <Text
              color="#202123"
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              height="40px"
              noOfLines={2}
              dangerouslySetInnerHTML={{ __html: question.questionText }}
            />
          </Stack>
        ))}
    </Stack>
  );
}
