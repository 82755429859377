import { axiosBackend } from './Configuration';

export default {
  index: async () => {
    try {
      const { data: banks } = await axiosBackend().get('/banks');

      return banks;
    } catch (error) {
      throw error;
    }
  },
};
