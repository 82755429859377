import { toQueryString } from '../../../../helpers/ToQueryString';
import { History } from 'history';

export interface IRedirectToThanksPageParams {
  purchase_id?: string;
  payment_method?: string;
  boleto_code?: string;
  boleto_expiration_date?: string;
  boleto_link?: string;
  pix_expiration_date?: string;
  pix_qr_code?: string;
  total_value?: string;
  utm_id?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  history: History;
}

export default function redirectToThanksPage(params: IRedirectToThanksPageParams) {
  const {
    purchase_id,
    payment_method,
    boleto_code,
    boleto_expiration_date,
    boleto_link,
    pix_expiration_date,
    pix_qr_code,
    total_value,
    utm_id,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
    history,
  } = params;

  let paymentMethodPage = 'payment-completed';

  if (payment_method === 'boleto') {
    paymentMethodPage = 'boleto-generated';
  } else if (payment_method === 'pix') {
    paymentMethodPage = 'pix-generated';
  }

  const queryString = toQueryString({
    purchase_id,
    boleto_code,
    boleto_expiration_date,
    boleto_link,
    pix_expiration_date,
    pix_qr_code,
    total_value,
    utm_id,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
  });

  return history.push(`/launch-checkout-thanks/${paymentMethodPage}?${queryString}`);
}
