import { Box, Flex, List, ListItem, Skeleton, Stack, Text, VStack } from '@chakra-ui/react';
import { FiAlertCircle as AlertIcon } from 'react-icons/fi';

import { Heading } from '../../../../../components/Heading';

import termOfUsePageUrl from '../utils';

import ConfirmationMessage from './components/ConfirmationMessage';
import CopyButton from './components/CopyButton';
import NoCode from './components/NoCode';
import QRCode from './components/QRCode';

function Pix({ ...props }) {
  const { pixCode, isPurchaseByPixConfirmed, shouldDisplayAlert, isLoadingStatus, status } = props;

  return (
    <>
      <Box
        marginTop="4"
        display="grid"
        gridTemplateAreas={{
          base: `
          'Alert Alert'
          'QR STEPS'
          'Notes Notes'
        `,
          lg: `
          'Alert Alert'
          'QR STEPS'
          'QR Notes'
        `,
        }}
        gap="5"
        width="100%"
      >
        <VStack gridArea="Alert">
          {shouldDisplayAlert && <ConfirmationMessage status={status} />}

          {isLoadingStatus && <Skeleton width="full" height="72px" borderRadius="sm" />}
        </VStack>

        <VStack
          gridArea="QR"
          alignItems="flex-start"
          minWidth={{ base: '10rem', lg: '12rem' }}
          sx={{ '& > svg': { width: '12rem', height: '12rem' } }}
        >
          {!isPurchaseByPixConfirmed ? <NoCode /> : <QRCode value={pixCode} />}
          <CopyButton textToCopy={pixCode} isPurchaseByPixConfirmed={isPurchaseByPixConfirmed} />
        </VStack>

        <VStack gridArea="STEPS" alignItems="flex-start">
          <Heading fontSize="md" fontWeight="semibold">
            Siga os passos abaixo, para realizar o pagamento.
          </Heading>

          <List marginLeft="4" fontSize={{ base: 'sm', lg: 'md' }}>
            <ListItem>1. Clique no botão gerar “Gerar Qr code”.</ListItem>
            <ListItem>2. Abre o aplicativo do seu banco.</ListItem>
            <ListItem>
              3. Selecione a opção de pagar com Pix e escanear QR code ou clique em copiar código e
              cole em seu aplicativo.
            </ListItem>
          </List>
        </VStack>

        <VStack gridArea="Notes">
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            gap="2"
            fontSize={{ base: 'sm', lg: 'md' }}
            sx={{ svg: { flexShrink: 0 } }}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <AlertIcon width="1rem" height="1rem" color="#EC7117" />
              <Text as="span" marginLeft="0.5rem" fontWeight="normal" color="primary.500">
                Observação
              </Text>
            </Box>

            <Text
              as="span"
              marginLeft="1.6rem"
              fontWeight="normal"
              lineHeight="shorter"
              color="dark.500"
            >
              Você obterá acesso ao produto{' '}
              <Text as="p" display="contents" color="primary.500">
                imediatamente
              </Text>{' '}
              após o pagamento.
            </Text>
          </Box>
        </VStack>
      </Box>
      <Flex
        marginY="8"
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', lg: 'space-between' }}
      >
        <Stack fontWeight="400" color="dark.500" maxWidth={{ base: '100%', lg: '14rem' }}>
          <Text color="dark.500" fontSize={{ base: 'sm', lg: 'xs' }}>
            Ao concluir a compra você concorda com estes{' '}
            <Text
              href={termOfUsePageUrl(`term-of-use`)}
              color="primary.400"
              cursor="pointer"
              display="contents"
              as="a"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termos de Serviços
            </Text>
            .
          </Text>
        </Stack>
      </Flex>
    </>
  );
}

export default Pix;
