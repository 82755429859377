import {
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';

import {
  MdOutlineVisibility as EyeIcon,
  MdOutlineVisibilityOff as EyeOffIcon,
} from 'react-icons/md';

export default function PasswordInput(props: InputProps) {
  const [isShow, setIsShow] = useBoolean();

  function handleClick() {
    setIsShow.toggle();
  }

  return (
    <InputGroup size="md">
      <Input
        pr={10}
        type={isShow ? 'text' : 'password'}
        borderRadius={6}
        color="white"
        placeholder="Digite aqui"
        focusBorderColor="gray.600"
        _hover={{ borderColor: 'gray.600' }}
        {...props}
      />

      <InputRightElement>
        <IconButton
          onClick={handleClick}
          variant="ghost"
          colorScheme="white"
          aria-label="Mudar visibilidade da senha"
          icon={isShow ? <EyeOffIcon /> : <EyeIcon />}
          sx={{ svg: { boxSize: 6 } }}
        />
      </InputRightElement>
    </InputGroup>
  );
}
