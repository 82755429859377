import { Box, Center, Heading, useToast } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import UserAPI from '../../../api/User';
import Paginate from '../../../components/Paginate';
import ErrorResponse from '../../../helpers/ErrorResponse';
import useFetch from '../../../hooks/useFetch';
import hasFilterParams from '../../../utils/hasFilterParams';
import InstructorsHeader from './InstructorsHeader';
import InstructorsMobileList from './InstructorsMobileList';
import InstructorsTable from './InstructorsTable';
import Loading from './Loading';
import SearchInput from './SearchInput';
import { InstructorView } from './types';

const INSTRUCTORS_PAGE_PATH = '/instructors';

interface PromotionsResponse {
  instructors: InstructorView[];
  page: number;
  per_page: number;
  total: number;
}

export default function Instructors() {
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const {
    data: response,
    loading: isLoading,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<PromotionsResponse>>({
    method: 'get',
    url: `/users/list-instructor?${urlSearchParams.toString()}`,
    authenticated: true,
    autoFetch: true,
  });

  const handleSearchChange = useCallback(
    (search: string) => {
      if (!search) return history.push(INSTRUCTORS_PAGE_PATH);

      urlSearchParams.set('page', '1');
      urlSearchParams.set('search', search);

      return history.push({
        pathname: INSTRUCTORS_PAGE_PATH,
        search: urlSearchParams.toString(),
      });
    },
    [history, urlSearchParams]
  );

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  async function enableOrDisableInstructor(instructorId: number) {
    if (instructorId) {
      try {
        const response = await UserAPI.changeStatusInstructor(instructorId);

        if (response) {
          toast({
            title: 'Status do usuário alterado com sucesso!',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title: ErrorResponse(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    }
  }

  const instructors = response?.data?.instructors;
  const isFiltering = hasFilterParams(urlSearchParams);
  const isNotFoundVisible = !isLoading && !instructors?.length && isFiltering;
  const isEmpty = !isLoading && !instructors?.length && !isFiltering;
  const total = response?.data?.total;
  const perPage = response?.data?.per_page || 1;
  const pageCount = Math.ceil(total / perPage);
  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const isPaginationVisible = !isLoading && pageCount > 1;
  const isInstructorsListVisible = !isLoading && !!instructors?.length;

  return (
    <Box>
      <InstructorsHeader />

      <SearchInput value={urlSearchParams.get('search')} mt={8} onSearch={handleSearchChange} />

      {isLoading && <Loading />}

      {isNotFoundVisible && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.200">
            Nenhum resultado encontrado...
          </Heading>
        </Center>
      )}

      {isEmpty && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.200" textAlign="center">
            Você não possui nenhum instrutor cadastrado.
          </Heading>
        </Center>
      )}

      {isInstructorsListVisible && (
        <>
          <InstructorsTable
            display={{ base: 'none', xl: 'block' }}
            instructors={instructors}
            enableOrDisableInstructor={enableOrDisableInstructor}
            mt="0.875rem"
          />

          <InstructorsMobileList
            display={{ base: 'block', xl: 'none' }}
            instructors={instructors}
            enableOrDisableInstructor={enableOrDisableInstructor}
            mt={2.5}
          />
        </>
      )}

      {isPaginationVisible && (
        <Paginate
          pageCount={pageCount}
          initialPage={currentPage}
          onPageChange={handlePageChange}
          mt={{ base: 8, xl: 4 }}
        />
      )}
    </Box>
  );
}
