import { Button, VStack } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

type SelectImageProps = {
  onDrop: (event) => void;
  isDisabled?: boolean;
  onDropRejected?: () => void;
};

export default function SelectImage({
  isDisabled = false,
  onDrop,
  onDropRejected,
}: SelectImageProps) {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'],
    noClick: true,
    noKeyboard: true,
    onDrop,
    onDropRejected,
  });

  function handleClick(event) {
    event.preventDefault();
    if (isDisabled) return;
    open();
  }

  return (
    <VStack
      {...getRootProps()}
      border="1px dashed #AEADAD"
      width={{ base: 'full', md: '100%' }}
      height={{ base: '344px', md: '132px' }}
      justifyContent="center"
      backgroundColor="rgba(55, 57, 61, 0.35)"
    >
      <input {...getInputProps()} />
      <Button
        colorScheme="primary"
        color="secondary.500"
        onClick={handleClick}
        disabled={isDisabled}
        size="sm"
      >
        Selecionar imagem
      </Button>
    </VStack>
  );
}
