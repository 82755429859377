import Joi from 'joi';
import { HighlightPlanShowcaseType } from './types';

const PLANS_IDS_MESSAGES = {
  'array.min': 'Selecione pelo menos um plano.',
  'any.required': 'A lista de planos é obrigatória.',
};

const TITLE_HIGHLIGHT_MESSAGES = {
  'any.required': 'O título do destaque é obrigatório.',
  'string.empty': 'O título do destaque não pode estar vazio.',
};

const DESCRIPTION_MESSAGES = {
  'any.required': 'A descrição do destaque é obrigatória.',
  'string.empty': 'A descrição do destaque não pode estar vazia.',
};

const IMAGE_DESKTOP_MESSAGES = {
  'string.empty': 'A imagem para desktop é obrigatória.',
  'any.required': 'A imagem para desktop é obrigatória.',
};

export const HighlightPlanShowcaseSchemaValidator = Joi.object<HighlightPlanShowcaseType>().keys({
  showcaseId: Joi.number().integer().positive().optional(),
  showcaseType: Joi.string().valid('products', 'plans').required(),
  showcaseViewType: Joi.when('showcaseType', {
    is: 'plans',
    then: Joi.string().valid('standard', 'highlight').required(),
    otherwise: Joi.forbidden(),
  }),
  status: Joi.string().valid('ATIVO', 'INATIVO').required(),
  titleHighlight: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().required().messages(TITLE_HIGHLIGHT_MESSAGES),
    otherwise: Joi.forbidden(),
  }),
  plansIds: Joi.when('showcaseType', {
    is: 'plans',
    then: Joi.array()
      .items(Joi.number().integer().positive())
      .min(1)
      .required()
      .messages(PLANS_IDS_MESSAGES),
    otherwise: Joi.forbidden(),
  }),
  description: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().required().messages(DESCRIPTION_MESSAGES),
    otherwise: Joi.forbidden(),
  }),
  imageDesktop: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().required().messages(IMAGE_DESKTOP_MESSAGES),
    otherwise: Joi.forbidden(),
  }),
  imageMobile: Joi.when('showcaseViewType', {
    is: 'highlight',
    then: Joi.string().optional(),
    otherwise: Joi.forbidden(),
  }),
});
