import {
  Box,
  Button,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormEvent } from 'react';
import { IoMdAlert } from 'react-icons/io';
import { ICover } from '..';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import { FormatMediaType } from '../EditOrNewCovers';

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  covers: ICover[];
  setCovers: (covers: ICover[]) => void;
  coverSelect: {
    id: number;
    formatMedia: FormatMediaType;
    active: boolean;
  };
}

export default function RemoveCoverModal({
  isOpen,
  onClose,
  covers,
  setCovers,
  coverSelect,
}: IProps) {
  const isImage = coverSelect.formatMedia === 'simple-image';
  const isActive = coverSelect.active;

  const payload = { active: isActive ? 0 : 1 };

  function afterRemoveCover() {
    const coversFilter = covers.filter(cover => cover.id !== coverSelect.id);

    if (!coversFilter.length) {
      setCovers([]);
      return onClose();
    }

    setCovers(coversFilter);

    onClose();
  }

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    url: `/banner-hero-members-area/${coverSelect.id}`,
    data: payload,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Capas removida com sucesso!',
      callback: afterRemoveCover,
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(4px)" />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader textAlign="center">
          <Box width="full" display="flex" justifyContent="center" color="red.500">
            <IoMdAlert fontSize="48px" />
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center">
          <Heading fontSize="18px" fontStyle="normal" fontWeight="500" lineHeight="28px">
            Tem certeza que deseja remover esse {isImage ? 'banner' : 'video'}?
          </Heading>
        </ModalBody>

        <ModalFooter>
          <HStack width="full" spacing="20px">
            <Button width="full" colorScheme="red" size="sm" onClick={onClose}>
              Não
            </Button>

            <Button width="full" size="sm" type="submit" isDisabled={isSubmitting}>
              Sim
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
