import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  IconButton,
  List,
  ListItem,
  ListProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { Link, useRouteMatch } from 'react-router-dom';
import convertToBrazilianCurrency from '../../../../../helpers/convertToBrazilianCurrency';
import { Plan } from '../types';
import { formatCreatedAt, getPaymentMethodsText, getRecurrenceText } from '../utils';

interface CheckoutSettingsMobileListProps extends ListProps {
  planList: Plan[];
}

export default function CheckoutSettingsMobileList({
  planList,
  ...rest
}: CheckoutSettingsMobileListProps) {
  const { url } = useRouteMatch();

  return (
    <List {...rest} spacing={2.5}>
      {planList.map(plan => (
        <Card
          key={plan.pagarMePlanId}
          as={ListItem}
          border="1px solid #2021230D"
          boxShadow="0px 4px 4px 0px #00000040"
        >
          <CardHeader p={4} pb={0}>
            <HStack justify="space-between" align="start" alignItems="start">
              <Text maxWidth="16.25rem" color="gray.700" lineHeight="normal" fontWeight="medium">
                {plan.name}
              </Text>

              <Menu size="sm" arrowPadding={0}>
                <MenuButton
                  size="xs"
                  as={IconButton}
                  icon={<MoreIcon size="0.75rem" />}
                  w="1.125rem"
                  h="1rem"
                  minW="unset"
                  borderRadius={5}
                  bgColor="#2021231F"
                  _hover={{ bgColor: '#b6b5b5' }}
                  _active={{ bgColor: '#b6b5b5' }}
                />

                <MenuList
                  sx={{
                    '.chakra-menu__menuitem': {
                      fontWeight: 'medium',
                      fontSize: 'xs',
                      color: '#20212380',
                      _hover: { bgColor: '#2021230D' },
                      _focus: { bgColor: '#2021230D' },
                    },
                  }}
                >
                  <MenuItem as={Link} to={`${url}/edit/${plan.id}/payment`}>
                    Editar
                  </MenuItem>

                  <MenuItem as={Link} to={`${url}/edit/${plan.id}/payment?duplicate=true`}>
                    Duplicar
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </CardHeader>

          <CardBody
            mt={4}
            p={4}
            pt={0}
            color="#20212380"
            sx={{ strong: { fontWeight: 'semibold' } }}
          >
            <List spacing={1}>
              <HStack as={ListItem} justify="space-between">
                <Text as="strong">ID:</Text>
                <Text>{plan.pagarMePlanId}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Data de criação:</Text>
                <Text>{formatCreatedAt(plan.createdAt)}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Dias de trial:</Text>
                <Text>{plan.trialDays}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Recorrência:</Text>
                <Text>{getRecurrenceText(plan.recurrence)}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Forma de pagamento:</Text>
                <Text>{getPaymentMethodsText(plan.paymentMethod)}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Valor:</Text>
                <Text>{convertToBrazilianCurrency(plan.value)}</Text>
              </HStack>

              <HStack as={ListItem} justify="space-between">
                <Text as="strong">Status:</Text>
                <Text textTransform="capitalize">{plan.status.toLowerCase()}</Text>
              </HStack>
            </List>
          </CardBody>
        </Card>
      ))}
    </List>
  );
}
