import { axiosBackend } from './Configuration';

interface IResetPasswordProps {
  email: string;
}

export default {
  sendEmail: async ({ email }: IResetPasswordProps) => {
    try {
      await axiosBackend().post(`/users/${email}/forgot-password`);
    } catch (error) {
      throw error;
    }
  },
};
