import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { AspectRatio, Box, Link as ChakraLink, Image, useBreakpointValue } from '@chakra-ui/react';

import { Banner } from '../../types';

const SETTINGS: Settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  nextArrow: <></>,
  prevArrow: <></>,
};

interface SimpleImageSliderProps {
  banners: Banner[];
}

export default function SimpleImageSlider({ banners }: SimpleImageSliderProps) {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Slider {...SETTINGS}>
      {banners.map(banner => (
        <Box key={banner.id} position="relative">
          <AspectRatio ratio={{ base: 9 / 13, md: 9 / 11, lg: 683 / 250 }}>
            {banner.link ? (
              <ChakraLink href={banner.link} target={banner.openLinkIn} isExternal>
                <Image
                  src={isMobile ? banner.bannerMobile || banner.banner : banner.banner}
                  objectFit="cover"
                  boxSize="full"
                />
              </ChakraLink>
            ) : (
              <Image
                src={isMobile ? banner.bannerMobile || banner.banner : banner.banner}
                objectFit="cover"
                h="full"
              />
            )}
          </AspectRatio>

          {/* Degradê da parte de baixo do banner */}
          <Box
            bg="linear-gradient(0deg, #202123 0%, rgba(32, 33, 35, 0.00) 100%)"
            height="10%"
            position="absolute"
            left={0}
            right={0}
            bottom={-1}
          />
        </Box>
      ))}
    </Slider>
  );
}
