import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ContentAPI from '../../../../../../api/Content';
import Badges from '../../../../../../components/Badges/Badges';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import DragDropQuestions from './DragDropQuestions';
import DropdownSubmit from './components/DropdownSubmit';
import styles from './styles.module.css';
import { Content, Module } from './types';

const initialValues = {
  title: '',
  order: 1,
  contentType: 'QUESTOES',
  status: 'EM_EDICAO',
  numberOfTimesAnswerQuestion: 0,
};

const ADD_WITH_CURRENT_ORDER = 1;

type Params = {
  id: string;
  moduleId: string;
  contentId: string;
};

function QuestionsForm() {
  const { course, setCourse, getContent } = useCourse();
  const { id: courseId, moduleId, contentId } = useParams<Params>();
  const history = useHistory();

  const [checked, setChecked] = useState(false);

  const [values, setValues] = useState(initialValues);

  const [isLoading, setIsLoading] = useState(false);

  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const content = getContent(moduleId, Number(contentId));

    if (!contentId) {
      return setValues(initialValues);
    }

    if (content) {
      setValues({
        title: content.title,
        order: content.order,
        contentType: content.contentType,
        numberOfTimesAnswerQuestion: content.numberOfTimesAnswerQuestion,
        status: content.status,
      });

      setChecked(content.numberOfTimesAnswerQuestion > 0);
    }
  }, [contentId, getContent, moduleId]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setValues({ ...values, [name]: value });
  }

  function handleDiscardChanges(event: any): void {
    event.preventDefault();

    setValues(initialValues);
  }

  async function handleSubmit(event: SyntheticEvent<EventTarget>): Promise<void> {
    if (event instanceof HTMLFormElement) {
      event.preventDefault();
    }

    let status: string = '';

    if (event.target instanceof HTMLButtonElement || event.target instanceof HTMLDivElement) {
      const dataset = event.target.dataset;

      status = dataset?.status;
    }

    if (!contentId) {
      try {
        setIsLoading(true);

        if (!values.title) {
          throw new Error('Informe o título do simulado.');
        }

        const currentModule = course.modules.find(
          (module: Module) => module.id === Number(moduleId)
        );

        const { contents } = currentModule;

        if (contents.length) {
          const orders = contents.map((content: Content) => content.order);

          values.order = orders.length
            ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
            : initialValues.order;
        }

        const payload = {
          title: values.title,
          order: values.order,
          numberOfTimesAnswerQuestion: values.numberOfTimesAnswerQuestion,
          contentType: values.contentType,
          status,
        };

        const { data: createdContent } = await ContentAPI.store(courseId, moduleId, payload);

        const newContents = [...currentModule.contents, createdContent];

        const moduleUpdated = { ...currentModule, contents: newContents };

        const newModules = course.modules.map((module: Module) =>
          module.id === moduleUpdated.id ? moduleUpdated : module
        );

        setCourse({ ...course, modules: newModules });

        history.push(
          `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${createdContent.id}/edit-question`
        );
        Toast('Conteúdo cadastrado com sucesso');
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }

      return;
    }

    try {
      setIsLoading(true);

      const payload = {
        title: values.title,
        order: values.order,
        contentType: values.contentType,
        numberOfTimesAnswerQuestion: values.numberOfTimesAnswerQuestion,
        status,
      };

      const { data: updatedContent } = await ContentAPI.update(
        courseId,
        moduleId,
        contentId,
        payload
      );

      const currentModule = course.modules.find((module: Module) => module.id === Number(moduleId));

      const newContents = currentModule.contents.map((content: Content) => {
        if (content.id === Number(contentId)) {
          return {
            ...content,
            title: updatedContent.title,
            order: updatedContent.order,
            numberOfTimesAnswerQuestion: updatedContent.numberOfTimesAnswerQuestion,
            contentType: updatedContent.contentType,
            status: updatedContent.status,
          };
        }

        return content;
      });

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map((module: Module) =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({ ...course, modules: newModules });

      Toast('Conteúdo atualizado com sucesso');

      history.push(
        `/courses/${courseId}/course-manager/course/modules/${moduleId}/contents/${contentId}/edit-question`
      );
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDelete(event: any): Promise<void> {
    event.preventDefault();

    try {
      setIsLoading(true);

      const currentModule = course.modules.find((module: Module) => module.id === Number(moduleId));

      const newContents = currentModule.contents.filter(
        (module: Module) => module.id !== Number(contentId)
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map((module: Module) =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      await ContentAPI.delete(courseId, moduleId, contentId);

      onOpen();
      history.push(`/courses/${courseId}/course-manager/course/modules/${moduleId}`);
      Toast('Conteúdo excluído com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
      onOpen();
    }
  }

  function StatusContent({ status }) {
    switch (status) {
      case 'ATIVO':
        return (
          <Badges title="Publicado" background="#22bb33" color="#FFFFFF" className={styles.badge} />
        );
      case 'EM_EDICAO':
        return (
          <Badges title="Rascunho" background="#EDCA32" color="#FFFFFF" className={styles.badge} />
        );
      default:
        return <Box></Box>;
    }
  }

  function handleSwitch(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;
    setChecked(checked);

    if (!checked) {
      setValues({ ...values, numberOfTimesAnswerQuestion: 0 });
    }
  }

  return (
    <Box paddingX={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop={2}
        marginBottom={5}
      >
        <Heading fontSize="2xl">{values.title || 'Conteúdo tipo simulado'}</Heading>
        {contentId && <StatusContent status={values.status} />}
      </Stack>

      <Stack as="form" spacing={5} onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel htmlFor="title">Título do simulado:</FormLabel>
          <Input
            id="title"
            name="title"
            type="text"
            size="sm"
            borderRadius={6}
            placeholder="Digite um título para o seu conteúdo"
            focusBorderColor="orange.500"
            onChange={handleChange}
            value={values.title}
            maxLength={255}
            minLength={1}
            isRequired
          />
        </FormControl>

        <FormControl>
          <Stack direction="row" alignItems="center">
            <Switch size="sm" isChecked={checked} onChange={handleSwitch} colorScheme="orange" />
            <FormLabel htmlFor="order">Ativar opção de refazer o simulado.</FormLabel>
          </Stack>
        </FormControl>

        <FormControl hidden={!checked}>
          <FormLabel htmlFor="order">Quantas vezes pode ser refeito?</FormLabel>
          <NumberInput
            step={1}
            defaultValue={0}
            size="sm"
            borderRadius={6}
            focusBorderColor="orange.500"
            value={values.numberOfTimesAnswerQuestion}
            onChange={valueString =>
              setValues({ ...values, numberOfTimesAnswerQuestion: parseInt(valueString) })
            }
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="questions">Questões do Simulado:</FormLabel>

          <DragDropQuestions values={values} setIsDisabledSubmit={setIsDisabledSubmit} />
        </FormControl>

        <Stack
          direction={{ base: 'column', md: 'row', lg: 'row' }}
          justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
          alignItems="center"
          spacing={2}
          paddingBottom={5}
        >
          <Box>
            <Button
              size="sm"
              width={{ base: '100%', md: 'auto', lg: 'auto' }}
              colorScheme="red"
              onClick={() => onOpen()}
              hidden={!contentId}
            >
              Excluir conteúdo
            </Button>
          </Box>

          <Stack
            direction={{ base: 'column', md: 'row', lg: 'row' }}
            width={{ base: '100%', md: 'auto', lg: 'auto' }}
            spacing={2}
          >
            <Button
              size="sm"
              width={{ base: '100%', md: 'auto', lg: 'auto' }}
              onClick={handleDiscardChanges}
            >
              Descartar mudanças
            </Button>

            <DropdownSubmit
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              isDisabledSubmit={isDisabledSubmit}
            />
          </Stack>
        </Stack>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody fontWeight={600} fontSize="lg" padding={5}>
            Você tem certeza que deseja excluir o conteúdo?
          </ModalBody>

          <ModalFooter>
            <Stack direction="row" spacing={2}>
              <Button colorScheme="gray" onClick={onClose} size="sm">
                Cancelar
              </Button>
              <Button
                size="sm"
                colorScheme="red"
                onClick={handleDelete}
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Excluir
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default QuestionsForm;
