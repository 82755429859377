import { Tab, TabIndicator, TabList, Tabs, useColorModeValue } from '@chakra-ui/react';

export default function TabsStatistics({ filterCategoriesBy }) {
  const textColor = useColorModeValue('#20212380', '#FFFFFF');

  return (
    <Tabs margin="40px 0" colorScheme="orange" onChange={index => filterCategoriesBy(index)}>
      <TabList color="#20212380">
        <Tab
          color={textColor}
          _active={{ color: '#EB7129' }}
          _selected={{ color: '#EB7129' }}
          fontSize="18px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
          paddingX={{ base: '8px', md: '15px', lg: '20px' }}
        >
          Áreas
        </Tab>

        <Tab
          color={textColor}
          _active={{ color: '#EB7129' }}
          _selected={{ color: '#EB7129' }}
          fontSize="18px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
          paddingX={{ base: '8px', md: '15px', lg: '20px' }}
        >
          Disciplinas
        </Tab>

        <Tab
          color={textColor}
          _active={{ color: '#EB7129' }}
          _selected={{ color: '#EB7129' }}
          fontSize="18px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
          paddingX={{ base: '8px', md: '15px', lg: '20px' }}
        >
          Assunto
        </Tab>

        <Tab
          color={textColor}
          _active={{ color: '#EB7129' }}
          _selected={{ color: '#EB7129' }}
          fontSize="18px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
          paddingX={{ base: '8px', md: '15px', lg: '20px' }}
        >
          Banca
        </Tab>
      </TabList>

      <TabIndicator mt="-1.5px" height="3px" bg="#EB7129" borderRadius="2px" />
    </Tabs>
  );
}
