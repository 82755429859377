import { useCallback, useMemo, useState } from 'react';
import useFetch from '../../../../../../hooks/useFetch';
import FilterType from '../../types/Filter';

interface Area {
  id: number;
  name: string;
}

interface Discipline {
  id: number;
  name: string;
  area: Area;
}

interface Subject {
  id: number;
  name: string;
  area: Area;
  discipline: Discipline;
}

interface Institution {
  id: number;
  name: string;
}

export interface FiltersList {
  areas: Area[];
  disciplines: Discipline[];
  subjects: Subject[];
  institutions: Institution[];
}

export default function useFilters() {
  const [filters, setFilters] = useState<Partial<FilterType>[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<Partial<FilterType>>(null);
  const [isFetching, setIsFetching] = useState(false);

  const { data: areaResponse, fetchData: fetchAreas } = useFetch<UnificadaFront.ResponseJSON<any>>({
    method: 'get',
    url: '/filter/question-library-area?hasPaginate=false',
    authenticated: true,
    autoFetch: false,
  });

  const { data: disciplineResponse, fetchData: fetchDisciplines } = useFetch<
    UnificadaFront.ResponseJSON<any>
  >({
    method: 'get',
    url: '/filter/question-library-discipline?hasPaginate=false',
    authenticated: true,
    autoFetch: false,
  });

  const { data: subjectResponse, fetchData: fetchSubjects } = useFetch<
    UnificadaFront.ResponseJSON<any>
  >({
    method: 'get',
    url: '/filter/question-library-subject?hasPaginate=false',
    authenticated: true,
    autoFetch: false,
  });

  const { data: institutionResponse, fetchData: fetchInstitution } = useFetch<
    UnificadaFront.ResponseJSON<any>
  >({
    method: 'get',
    url: '/filter/question-library-institution?hasPaginate=false',
    authenticated: true,
    autoFetch: false,
  });

  const fetchFilters = useCallback(async () => {
    setIsFetching(true);

    await Promise.all([fetchAreas(), fetchDisciplines(), fetchSubjects(), fetchInstitution()]);

    setIsFetching(false);
  }, [fetchAreas, fetchDisciplines, fetchInstitution, fetchSubjects]);

  const fetchedFilters: FiltersList = useMemo(() => {
    return {
      areas: areaResponse?.data?.data,
      disciplines: disciplineResponse?.data?.data,
      subjects: subjectResponse?.data?.data,
      institutions: institutionResponse?.data?.data,
    };
  }, [
    areaResponse?.data?.data,
    disciplineResponse?.data?.data,
    institutionResponse?.data?.data,
    subjectResponse?.data?.data,
  ]);

  const filtersList = useMemo(() => {
    let filteredAreas = fetchedFilters.areas;
    let filteredDisciplines = fetchedFilters.disciplines;
    let filteredSubjects = fetchedFilters.subjects;
    let filteredInstitutions = fetchedFilters.institutions;

    const { id, name, value } = selectedFilter || {};

    if (name === 'area') {
      setFilters([{ id, name, value }]);

      filteredDisciplines = fetchedFilters.disciplines?.filter(
        discipline => discipline.area.id === id
      );

      filteredSubjects = fetchedFilters.subjects?.filter(subject => subject.area.id === id);
    }

    if (name === 'discipline') {
      const currentArea = fetchedFilters.disciplines?.find(
        discipline => discipline.id === id
      )?.area;

      setFilters([
        { id, name, value },
        { id: currentArea.id, name: 'area', value: currentArea.name },
      ]);

      filteredSubjects = fetchedFilters.subjects?.filter(subject => subject.discipline.id === id);
    }

    if (name === 'subject') {
      const currentArea = fetchedFilters.subjects?.find(subject => subject.id === id)?.area;

      const currentDiscipline = fetchedFilters.subjects?.find(
        subject => subject.id === id
      )?.discipline;

      setFilters([
        { id, name, value },
        { id: currentArea?.id, name: 'area', value: currentArea?.name },
        { id: currentDiscipline?.id, name: 'discipline', value: currentDiscipline?.name },
      ]);
    }

    if (name === 'institution') {
      setFilters([...filters, { id, name, value }]);
    }

    return {
      areas: filteredAreas,
      disciplines: filteredDisciplines,
      subjects: filteredSubjects,
      institutions: filteredInstitutions,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchedFilters.areas,
    fetchedFilters.disciplines,
    fetchedFilters.institutions,
    fetchedFilters.subjects,
    selectedFilter,
  ]);

  const handleAddFilter = useCallback(
    (filter: FilterType) => {
      setSelectedFilter({
        id: Number(filter.id),
        name: filter.name,
        value: filter.value,
      });

      if (filters.length > 0) {
        const filterExists = filters.find(item => item.name === filter.name);

        if (filterExists) {
          const newFilters = filters.map(item => {
            if (item.name === filter.name) {
              return filter;
            }

            return item;
          });

          return setFilters(newFilters);
        }

        return setFilters([...filters, filter]);
      }

      setFilters([...filters, filter]);
    },
    [filters]
  );

  function handleRemoveFilter(filterName: string) {
    const newFilters = filters.filter(filter => filter.name !== filterName);

    setFilters(newFilters);
  }

  function clearFilters() {
    setFilters([]);
    setSelectedFilter(null);
  }

  return {
    filters,
    filtersList,
    fetchedFilters,
    isFetching,
    fetchFilters,
    handleAddFilter,
    handleRemoveFilter,
    clearFilters,
  };
}
