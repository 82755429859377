import { Container as ChakraContainer } from '@chakra-ui/react';
import {
  HEADER_DESKTOP_HEIGHT,
  HEADER_MOBILE_HEIGHT,
} from '../../../components/ContainerSite/constants';
import ContainerSite from '../../../components/ContainerSite/ContainerSite';

export default function Container({ children }: { children: React.ReactNode }) {
  return (
    <ContainerSite>
      <ChakraContainer
        maxW="63.125rem"
        my={{ base: HEADER_MOBILE_HEIGHT, xl: HEADER_DESKTOP_HEIGHT }}
        px={{ base: 4, xl: 0 }}
      >
        {children}
      </ChakraContainer>
    </ContainerSite>
  );
}
