import { Select, SelectProps } from '@chakra-ui/react';

function getLastDigits(number, n) {
  return Number(String(number).slice(-n));
}

const currentYear = new Date().getFullYear();

const years = Array.from({ length: 10 }, (_, i) => currentYear + i).map(year =>
  getLastDigits(year, 2)
);

export default function YearSelect(props: SelectProps) {
  return (
    <Select placeholder="AA" {...props}>
      {years.map(year => (
        <option key={year}>{year}</option>
      ))}
    </Select>
  );
}
