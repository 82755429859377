import { Center, Heading, useDisclosure } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Paginate from '../../../../../../components/Paginate';
import useFetch from '../../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import hasFilterParams from '../../../../../../utils/hasFilterParams';
import Loading from '../components/Loading';
import MobileSubscriberList from './components/MobileSubscriberList';
import SubscriberTable from './components/SubscriberTable';
import SubscriptionCancellationModal from './components/SubscriptionCancellationModal';
import SubscriberManagementHeader from './SubscriberManagementHeader';

export interface Subscriber {
  id: number;
  fullName: string;
  email: string;
  status: string;
  paymentMethod: string;
  price: number;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  updatedAt: string;
}

interface SubscriberListResponse {
  subscriberList: Subscriber[];
  page: number;
  per_page: number;
  total: number;
}

export default function SubscriberManagement() {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [subscriberToBeCanceled, setSubscriberToBeCanceled] = useState<Subscriber | null>(null);

  const location = useLocation();
  const history = useHistory();
  const { planId } = useParams<{ planId: string }>();
  const planName = (location.state as { planName: string })?.planName;

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const {
    data: response,
    loading: isLoading,
    fetchData: fetchSubscriberList,
  } = useFetch<UnificadaFront.ResponseJSON<SubscriberListResponse>>({
    method: 'get',
    url: `/recurrence/${planId}/subscriber-list?${urlSearchParams.toString()}`,
    autoFetch: true,
    authenticated: true,
  });

  const { handleSubmit: handleSubscriberCancel, isLoading: isCancelling } = useHandleSubmit({
    method: 'patch',
    url: `/recurrence/${subscriberToBeCanceled?.id}/cancellation`,
    authenticated: true,
    onSuccess: {
      message: 'Assinatura cancelada com sucesso',
      callback: async () => {
        await fetchSubscriberList();
        setSubscriberToBeCanceled(null);
        onClose();
      },
    },
  });

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  function handleSelectSubscriberToBeCanceled(subscriber: Subscriber) {
    setSubscriberToBeCanceled(subscriber);
    onToggle();
  }

  const subscriberListData = response?.data?.subscriberList || [];
  const isFiltering = hasFilterParams(urlSearchParams);
  const isNotFoundVisible = !isLoading && !subscriberListData?.length && isFiltering;
  const isEmpty = !isLoading && !subscriberListData?.length && !isFiltering;
  const isSubscriberListVisible = !isLoading && !!subscriberListData?.length;
  const total = response?.data?.total;
  const perPage = response?.data?.per_page || 1;
  const pageCount = Math.ceil(total / perPage);
  const isPaginationVisible = !isLoading && pageCount > 1;
  const currentPage = Number(urlSearchParams.get('page')) || 1;

  return (
    <>
      <SubscriberManagementHeader planName={planName} isEmpty={isEmpty} />

      {isLoading && <Loading />}

      {isNotFoundVisible && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.300">
            Nenhum resultado encontrado...
          </Heading>
        </Center>
      )}

      {isSubscriberListVisible && (
        <>
          <SubscriberTable
            display={{ base: 'none', xl: 'block' }}
            mt={5}
            onSelectSubscriberToBeCanceled={handleSelectSubscriberToBeCanceled}
            subscriberList={subscriberListData}
          />

          <MobileSubscriberList
            display={{ base: 'block', xl: 'none' }}
            mt={2.5}
            onSelectSubscriberToBeCanceled={handleSelectSubscriberToBeCanceled}
            subscriberList={subscriberListData}
          />
        </>
      )}

      {isPaginationVisible && (
        <Paginate
          pageCount={pageCount}
          initialPage={currentPage}
          mt={{ base: 8, xl: 4 }}
          onPageChange={handlePageChange}
        />
      )}

      <SubscriptionCancellationModal
        isOpen={isOpen}
        onClose={onClose}
        subscriberName={subscriberToBeCanceled?.fullName || ''}
        subscriberEmail={subscriberToBeCanceled?.email || ''}
        onConfirm={handleSubscriberCancel}
        isLoading={isCancelling}
      />
    </>
  );
}
