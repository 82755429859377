import { Box, Heading, Spinner, Stack, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '../../../../components/ContainerSite/ContainerSite';
import FilterQuestions from '../../../../components/FilterQuestions';
import Pagination from '../../../../components/Pagination';
import { useAuth } from '../../../../contexts/AuthContext';
import { ROLE_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import useFetch from '../../../../hooks/useFetch';
import {
  IInitialFilters,
  IQuestionLibraryAreaHasPaginateResult,
  IQuestionLibraryDisciplineHasPaginateResult,
  IQuestionLibraryInstitutionHasPaginateResult,
  IQuestionLibraryQuestionResult,
  IQuestionLibrarySubjectHasPaginateResult,
  Question,
} from '../../../DashboardAdmin/Courses/CourseManager/ModulesManager/QuestionLibrary/types';
import { ModalLogin } from './ModalLogin';
import QuestionCard from './QuestionCard';

const initialFilters = {
  areaId: '',
  disciplineId: '',
  subjectId: '',
  institutionId: '',
};

export default function QuestionPage() {
  const [filters, setFilters] = useState<IInitialFilters>(initialFilters);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [questionsFiltered, setQuestionsFiltered] = useState<Question[]>([]);
  const [membersAreaVersion, setMembersAreaVersion] = useState<string>('v1');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const { membersArea } = useAuth();
  const isStudent = localStorage.getItem(ROLE_LOCAL_STORAGE) === 'ALUNO';
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (membersArea === 'netflix') {
      setMembersAreaVersion('v2');
    }
  }, [membersArea]);

  useEffect(() => {
    if (isStudent) {
      history.push(`/members-area/${membersAreaVersion}/questions`);
    }
  }, [history, isStudent, membersAreaVersion]);

  const queryParams = new URLSearchParams();

  const addQueryParam = (paramName: string, paramValue: string | undefined) => {
    if (paramValue) {
      queryParams.append(paramName, paramValue);
    }
  };

  addQueryParam('page', currentPage.toString());
  addQueryParam('filterAreaId', filters.areaId);
  addQueryParam('filterDisciplineId', filters.disciplineId);
  addQueryParam('filterSubjectId', filters.subjectId);
  addQueryParam('filterInstitutionId', filters.institutionId);

  const searchParams = queryParams.toString();
  const url = searchParams
    ? `/question-library-student/home/question-external?${searchParams}`
    : '/question-library-student/home/question-external';

  const { data: questionLibraryQuestionData, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryQuestionResult>
  >({
    url,
    method: 'get',
    autoFetch: true,
  });

  useEffect(() => {
    if (questionLibraryQuestionData?.data?.data) {
      setQuestions(questionLibraryQuestionData?.data?.data);
      setQuestionsFiltered(questionLibraryQuestionData?.data?.data);
      setPageCount(
        questionLibraryQuestionData?.data?.total / questionLibraryQuestionData?.data?.per_page
      );
    } else {
      setQuestionsFiltered([]);
    }
  }, [
    questionLibraryQuestionData?.data?.data,
    questionLibraryQuestionData?.data?.total,
    questionLibraryQuestionData?.data?.per_page,
  ]);

  const { data: areaData, loading: isLoadingAreaData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryAreaHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/home/question-library-area?hasPaginate=false`,
    autoFetch: true,
  });

  const { data: disciplineData, loading: isLoadingDisciplineData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryDisciplineHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/home/question-library-discipline?hasPaginate=false`,
    autoFetch: true,
  });

  const { data: subjectData, loading: isLoadingSubjectData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibrarySubjectHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/home/question-library-subject?hasPaginate=false`,
    autoFetch: true,
  });

  const { data: institutionData, loading: isLoadingInstitutionData } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionLibraryInstitutionHasPaginateResult>
  >({
    method: 'get',
    url: `/filter/home/question-library-institution?hasPaginate=false`,
    autoFetch: true,
  });

  function handlePageChange({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }

  return (
    <Container>
      <Box
        id="questionPage"
        margin={{ base: '50px 20px 30px 20px', md: '50px', lg: '120px 108px 80px 108px' }}
      >
        <Heading marginY={{ base: '20px', md: '30px', lg: '40px' }} color="#000" fontWeight="500">
          Questões
        </Heading>

        <FilterQuestions
          areaListData={areaData?.data?.data}
          disciplineListData={disciplineData?.data?.data}
          subjectListData={subjectData?.data?.data}
          institutionData={institutionData?.data?.data}
          isLoadingAreaData={isLoadingAreaData}
          isLoadingDisciplineData={isLoadingDisciplineData}
          isLoadingSubjectData={isLoadingSubjectData}
          isLoadingInstitutionData={isLoadingInstitutionData}
          filters={filters}
          setFilters={setFilters}
          questionsLength={questions?.length}
          setCurrentPage={setCurrentPage}
        />
      </Box>
      <Box
        margin={{
          base: '0px 20px 80px 20px',
          md: '0px 50px 80px 50px',
          lg: '0px 108px 80px 108px',
        }}
      >
        {!isLoading &&
          questionsFiltered?.length > 0 &&
          questionsFiltered.map((question, index) => (
            <Box marginBottom="32px" key={index}>
              <QuestionCard question={question} onOpen={onOpen} />
            </Box>
          ))}

        {isLoading && (
          <Stack direction="row" width="full" justifyContent="center">
            <Spinner size="lg" color="orange.500" />
          </Stack>
        )}

        {!isLoading && !questions?.length && (
          <Heading fontSize="md" color="#20212380" width="full" textAlign="center">
            Ainda não foram adicionadas questões públicas.
          </Heading>
        )}

        {!isLoading && questions?.length > 0 && !questionsFiltered?.length && (
          <Stack width="full" alignItems="center">
            <Heading fontSize="md" color="#20212380">
              Nenhum resultado encontrado
            </Heading>
          </Stack>
        )}

        {questionsFiltered?.length > 0 && (
          <Box paddingY={2}>
            <Pagination
              pageCount={pageCount}
              onPageActive={currentPage - 1}
              onPageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>

      <ModalLogin isOpen={isOpen} onClose={onClose} />
    </Container>
  );
}
