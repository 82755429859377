import { Button, Text } from '@chakra-ui/react';
import { MdContentCopy as CopyIcon } from 'react-icons/md';
import CopyToClipboard from 'react-copy-to-clipboard';
import Toast from '../../../../../../../components/Toast';

interface ICopyButton {
  textToCopy: string;
  onCopy?(text: string, result: boolean): void;
  isPurchaseByPixConfirmed: boolean;
}

function handleCopy(text: string, result: boolean) {
  if (result && text) Toast(`Código copiado com sucesso`);
}

function CopyButton({ textToCopy, isPurchaseByPixConfirmed }: ICopyButton) {
  return (
    <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
      <Button
        type="button"
        width="100%"
        size="sm"
        fontSize="sm"
        fontWeight="500"
        background={!isPurchaseByPixConfirmed ? '#2021237f' : 'transparent'}
        color={!isPurchaseByPixConfirmed ? '#ffffff' : '#EB7129'}
        borderColor={!isPurchaseByPixConfirmed && '#EB7129'}
        borderRadius="base"
        border={!isPurchaseByPixConfirmed ? '0' : '1px solid currentColor'}
        sx={{ '& > svg': { flexShrink: 0 } }}
        _hover={{
          opacity: 'none',
        }}
        isDisabled={!isPurchaseByPixConfirmed}
      >
        <CopyIcon size={18} color={!isPurchaseByPixConfirmed ? '#ffffff' : '#EB7129'} />
        <Text ml="0.2rem">Copiar código</Text>
      </Button>
    </CopyToClipboard>
  );
}

export default CopyButton;
