import {
  Button,
  Container,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import PurchaseAPI from '../../../../api/Purchase';
import TransactionAPI from '../../../../api/Transaction';
import { platformsToRemoveInstructorTransactionsMenu } from '../../../../App';
import FullPageLoading from '../../../../components/FullPageLoading';
import { Heading } from '../../../../components/Heading';
import Paginate from '../../../../components/Paginate';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { ROLE_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import useFetch from '../../../../hooks/useFetch';
import TableList from './TableList';

interface IProduct {
  id: number;
  product: string;
  price: string;
  amount: number;
}

export interface ITransaction {
  id: number;
  transactionId: string;
  products: IProduct[];
  recurrencePlan: string;
  createdAt: string;
  buyer: {
    fullName: string;
    email: string;
    documentNumber: string;
  };
  idRecurrencePagarme: number;
  paymentMethod: string;
  purchasePrice: string;
  purchaseStatus: string;
}

interface IPurchasesResponse {
  transactions: ITransaction[];
  limit: number;
  total: number;
}

export interface IRecurrencePayload {
  idRecurrencePagarme: number;
  transactionIds: number[];
}

function Transaction() {
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);
  const hostname = window.location.hostname;

  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);

  const toast = useToast();

  useEffect(() => {
    if (role === 'INSTRUTOR' && platformsToRemoveInstructorTransactionsMenu.includes(hostname)) {
      history.push('/');
    }
  }, [history, hostname, role]);

  function getQueryValue(queryName) {
    return (
      urlSearchParams
        .get(queryName)
        ?.split(',')
        .map(query => query.trim()) ?? []
    );
  }

  const currentPage = urlSearchParams.get('page') ?? '1';

  const today = dayjs().format('YYYY-MM-DD');
  const lastMonth = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
  const startDateQuery = urlSearchParams.get('startDate');
  const endDateQuery = urlSearchParams.get('endDate');
  const searchQuery = urlSearchParams.get('search');

  const [search, setSearch] = useState(searchQuery);
  const startDate = startDateQuery ?? lastMonth;
  const endDate = endDateQuery ?? today;
  const paymentMethod = getQueryValue('paymentMethod');
  const status = getQueryValue('status');
  const checkoutType = getQueryValue('checkoutType');

  const {
    data: response,
    loading,
    fetchData,
  } = useFetch<UnificadaFront.ResponseJSON<IPurchasesResponse>>({
    method: 'get',
    url: `/purchases?${urlSearchParams.toString()}`,
    autoFetch: true,
    authenticated: true,
  });

  const transactions = response?.data?.transactions;
  const pageCount = response?.data?.total / response?.data?.limit || 0;
  const shouldShowPagination = !loading && pageCount > 1;

  async function getExportTransactionExcel() {
    try {
      await PurchaseAPI.purchaseExportTransaction({
        typeFile: 'Excel',
        search,
        startDate,
        endDate,
        paymentMethod,
        status,
        checkoutType,
      });
    } catch (error) {
      toast({
        title: 'Erro',
        description: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  async function getExportTransactionCsv() {
    try {
      await PurchaseAPI.purchaseExportTransaction({
        typeFile: 'CSV',
        search,
        startDate,
        endDate,
        paymentMethod,
        status,
        checkoutType,
      });
    } catch (error) {
      toast({
        title: 'Erro',
        description: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      if (search) {
        urlSearchParams.set('search', search);
        urlSearchParams.set('page', '1');

        history.push({ search: urlSearchParams.toString() });
      } else {
        urlSearchParams.delete('search');
        history.push({ search: urlSearchParams.toString() });
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };

    // Não coloque o urlSearchParams dentro do array de dependências para que o useEffect seja chamado apenas uma vez
    // Evita uma demanda na central de serviços
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, search]);

  function handlePageChange({ selected: selectedPage }) {
    const currentPage = selectedPage + 1;
    urlSearchParams.set('page', currentPage);
    history.push({ search: urlSearchParams.toString() });
  }

  async function verifyAllTransactionStatus() {
    try {
      setIsLoadingVerify(true);
      // Combinar transações com o mesmo idRecurrencePagarme, ignorando aquelas que não têm idRecurrencePagarme
      const recurrencesMap = {};

      transactions.forEach(transaction => {
        const { idRecurrencePagarme, transactionId } = transaction;

        // Ignorar transações sem idRecurrencePagarme
        if (!idRecurrencePagarme) return;

        if (!recurrencesMap[idRecurrencePagarme]) {
          recurrencesMap[idRecurrencePagarme] = {
            idRecurrencePagarme,
            transactionIds: [parseInt(transactionId)],
          };
        } else {
          recurrencesMap[idRecurrencePagarme].transactionIds.push(parseInt(transactionId));
        }
      });

      // Transformar o objeto em array para enviar para a API
      const recurrences: IRecurrencePayload[] = Object.values(recurrencesMap);

      if (recurrences.length > 0) {
        await TransactionAPI.verifyStatus(recurrences);

        setIsLoadingVerify(false);

        fetchData();

        toast({
          title: 'Status da transação verificado com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        });
      } else {
        toast({
          title: 'Nenhuma transação para verificar',
          status: 'warning',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    } catch (error) {
      toast({
        title: 'Erro ao verificar status da transação',
        description: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } finally {
      setIsLoadingVerify(false);
    }
  }

  return (
    <Container maxWidth="container.xl">
      <HStack justifyContent="space-between" alignItems="flex-start" mb="20px">
        <Heading fontSize={{ base: '24px', md: '36px' }}>Transações</Heading>

        <Menu>
          <MenuButton
            size="sm"
            color="secondary.500"
            colorScheme="primary"
            as={Button}
            rightIcon={<BsChevronDown />}
          >
            Exportar
          </MenuButton>
          <MenuList>
            <MenuItem onClick={getExportTransactionExcel}>Download Excel</MenuItem>
            <MenuItem onClick={getExportTransactionCsv}>Download CSV</MenuItem>
          </MenuList>
        </Menu>
      </HStack>

      <Text fontSize={{ base: '14px', md: '18px' }} color="#20212380" mb="30px">
        Gerencie suas vendas nesta seção, visualizando todas as compras (pagas ou gratuitas) na sua
        plataforma.
      </Text>

      <TableList
        transactions={transactions}
        loading={loading}
        isLoadingVerify={isLoadingVerify}
        onVerifyAllTransactionStatus={verifyAllTransactionStatus}
        search={search}
        setSearch={setSearch}
        fetchData={fetchData}
        toast={toast}
        paymentMethod={paymentMethod}
        hostname={hostname}
        startDate={startDate}
        endDate={endDate}
        status={status}
        checkoutType={checkoutType}
      />

      {shouldShowPagination && (
        <Paginate
          pageCount={pageCount}
          initialPage={parseInt(currentPage)}
          onPageChange={handlePageChange}
          mt={{ base: 2, md: 4 }}
        />
      )}

      <FullPageLoading isLoading={loading} />
    </Container>
  );
}

export default Transaction;
