import { FiAlertCircle as Alert } from 'react-icons/fi';

import stylesTooltip from './styles.module.css';
interface ITooltipProps {
  title?: string;
  content?: string | React.ReactElement | React.ReactNode;
  background?: string;
}

function Tooltip({ content, background }: ITooltipProps) {
  return (
    <div className={stylesTooltip.container}>
      <Alert size={14} color="#2021237f" />

      <div className={stylesTooltip.content} style={{ background: background }}>
        <div className={stylesTooltip.arrow} style={{ borderBottom: `8px solid ${background}` }} />
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Tooltip;
