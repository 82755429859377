import { CloseButton, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { MdGetApp as DownloadIcon } from 'react-icons/md';

interface PdfFilePreviewProps {
  name: string;
  file: string;
  handleDiscardFile: () => void;
}

function PDFFilePreview({ name, file, handleDiscardFile }: PdfFilePreviewProps) {
  function handleOpenInNewTab() {
    window.open(file, '_blank');
  }

  return (
    <Flex
      padding="4"
      borderRadius="md"
      border="1px solid #0000000D"
      boxShadow="0px 4px 4px 0px #00000040"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text fontSize="sm" fontWeight="medium" color="#202123" isTruncated maxWidth="80%">
        {name}
      </Text>

      <HStack spacing={1}>
        {file && (
          <IconButton
            aria-label="Abrir"
            onClick={handleOpenInNewTab}
            icon={<DownloadIcon size={16} color="#20212380" />}
            size="xs"
            variant="ghost"
            color="gray.500"
          />
        )}

        <CloseButton
          onClick={handleDiscardFile}
          color="#20212380"
          size="sm"
          sx={{ svg: { boxSize: '0.5625rem' } }}
        />
      </HStack>
    </Flex>
  );
}

export default PDFFilePreview;
