import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  ButtonProps,
} from '@chakra-ui/react';

const resetButtonStyle: ButtonProps = {
  _active: { outline: 'none', boxShadow: 'none' },
  _focus: { outline: 'none', boxShadow: 'none' },
};

export default function ConfirmationModal({ isOpen, onClose, onConfirm }) {
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Seguir com um domínio padrão?</ModalHeader>
          <ModalCloseButton {...resetButtonStyle} />

          <ModalBody pb={6}>
            <Text>
              Ao não digitar o seu domínio será criado um domínio padrão. Mas não se preocupe, você
              poderá alterar em outro momento.
            </Text>
            <Text mt={4}>Deseja continuar?</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onConfirm}
              {...resetButtonStyle}
              variant="outline"
              colorScheme="red"
              mr={3}
            >
              Criar domínio padrão
            </Button>
            <Button {...resetButtonStyle} colorScheme="red" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
