import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Modal,
  ModalBody,
  Table,
} from '@chakra-ui/react';
import convertToBrazilianCurrency from '../../../../../helpers/convertToBrazilianCurrency';

export default function PurchaseDetailsModal({ isOpen, onClose, transactionFiltered }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent p={5}>
        <ModalHeader>Resumo da Compra</ModalHeader>
        <ModalCloseButton style={{ outline: 'none', boxShadow: 'none' }} />
        <ModalBody>
          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Produto</Th>
                  <Th>Valor</Th>
                  <Th>Quantidade</Th>
                </Tr>
              </Thead>
              {transactionFiltered.length > 0 &&
                transactionFiltered?.map(item => (
                  <Tbody>
                    <Tr>
                      <Td>{item?.name}</Td>
                      <Td> {convertToBrazilianCurrency(item?.price)}</Td>
                      <Td>{item?.amount}</Td>
                    </Tr>
                  </Tbody>
                ))}
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
