import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import path from 'path';
import { Modal } from 'react-bootstrap';
import ContentAPI from '../../../../../../api/Content';
import EditorRichText from '../../../../../../components/EditorRichText';
import DataLoading from '../../../../../../components/Loadings/DataLoading';
import Spinner from '../../../../../../components/Loadings/Spinner';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';
import { TOKEN_LOCAL_STORAGE } from '../../../../../../helpers/LocalStorageHelper';
import { baseURLBackend } from '../../../../../../api/Configuration';
import { BsChevronDown as ArrowDown, BsTrash } from 'react-icons/bs';
import CustomDropzone from '../../../../../../components/CustomDropzone';
import UploadHelper from '../../../../../../helpers/UploadHelper';

const initialValues = {
  title: '',
  contentType: 'MULTIMIDIA',
  fileDownload: '',
  link: '',
  order: 1,
  content: '',
};

const ADD_WITH_CURRENT_ORDER = 1;

const MultimediaAddForm = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasChanged, setHasChanged] = useState(false);
  const [fileDownload, setFileDownload] = useState('');

  const history = useHistory();
  const { course, setCourse, getContent } = useCourse();
  const { id, moduleId, contentId } = useParams();
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    const content = getContent(moduleId, contentId);

    if (!contentId) {
      return setValues(initialValues);
    }

    if (content) {
      setValues({ ...content, fileDownload: content.download });
    }
  }, [contentId, course, getContent, id, moduleId]);

  function handleChange(event) {
    const { name, value } = event.target;

    setValues({ ...values, [name]: value });

    setHasChanged(true);

    setErrorMessage('');
  }

  function onEditorStateChange(value) {
    setValues(prevPost => ({ ...prevPost, content: value }));

    setHasChanged(true);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!contentId) {
      return await handleCreate();
    }

    await handleUpdate();
  }

  async function handleUpdate() {
    try {
      setIsLoading(true);

      let fileName = '';

      const isUploadingFile = values.fileDownload && typeof values.fileDownload !== 'string';

      if (isUploadingFile) {
        const { newFileName } = await UploadHelper.upload(values.fileDownload, 'archives');
        fileName = newFileName;
      } else {
        fileName = values.download;
      }

      const contentToUpdate = {
        title: values.title,
        link: values.link,
        order: values.order,
        contentType: values.contentType,
        fileDownload: fileName,
        content: values.content,
      };

      await ContentAPI.update(id, moduleId, contentId, contentToUpdate);

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      const newContents = currentModule.contents.map(content =>
        content.id === parseInt(contentId) ? values : content
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      Toast('Conteúdo atualizado com sucesso');
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      setIsLoading(false);
    }
  }

  async function handleCreate() {
    try {
      setErrorMessage('');
      setIsLoading(true);

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      if (!currentModule)
        return setErrorMessage('Falha na operação. Recarregue a página e tente novamente.');

      const orders = currentModule.contents.map(content => content.order);

      values.order = orders.length
        ? Math.max(...orders) + ADD_WITH_CURRENT_ORDER
        : initialValues.order;

      const courseId = parseInt(id);

      delete values.id;

      const isUploadingFile = values.fileDownload && typeof values.fileDownload !== 'string';

      let fileName = '';

      if (isUploadingFile) {
        const { newFileName } = await UploadHelper.upload(values.fileDownload, 'archives');
        fileName = newFileName;
      } else {
        fileName = values.download;
      }

      const { data: createdContent } = await ContentAPI.store(courseId, moduleId, {
        ...values,
        fileDownload: fileName,
      });

      const newContents = [...currentModule.contents, createdContent];

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      Toast('Conteúdo cadastrado com sucesso');

      history.push(
        `/courses/${id}/course-manager/course/modules/${moduleId}/contents/${createdContent.id}/edit-multimedia`
      );
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDelete(event) {
    event.preventDefault();

    try {
      setErrorMessage('');
      setIsLoading(true);
      await ContentAPI.delete(id, moduleId, contentId);

      const currentModule = course.modules.find(module => module.id === parseInt(moduleId));

      const newContents = currentModule.contents.filter(
        module => module.id !== parseInt(contentId)
      );

      const moduleUpdated = { ...currentModule, contents: newContents };

      const newModules = course.modules.map(module =>
        module.id === moduleUpdated.id ? moduleUpdated : module
      );

      setCourse({
        ...course,
        modules: newModules,
      });

      setIsLoading(false);
      setIsShowModal(false);
      Toast('Conteúdo excluído com sucesso');
      history.push(`/courses/${id}/course-manager/course/modules/${moduleId}`);
    } catch (error) {
      if (!error.response) {
        return setErrorMessage(ErrorResponse(error));
      }

      if (error.response.data.validation) {
        setErrorMessage(ErrorResponse(error));
      }
      setIsLoading(false);
    }
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    if (contentId) {
      const content = getContent(moduleId, contentId);

      return setValues(content);
    }

    setValues(initialValues);
    setErrorMessage('');
  }

  function handleDropFileDownload(acceptedFiles) {
    setErrorMessage('');

    const [fileDownload] = acceptedFiles || '';

    setFileDownload(fileDownload);

    setValues({
      ...values,
      fileDownload: fileDownload,
    });

    setHasChanged(true);
  }

  function PreviewDownload({ fileDownload }) {
    function handleDiscardFileDownload() {
      setFileDownload('');

      setValues({
        ...values,
        fileDownload: '',
      });
    }

    return (
      <div className="d-flex align-center justify-content-between mt-2">
        <div>{fileDownload.name || path.basename(decodeURI(values.fileDownload))}</div>
        <div>
          <div>
            {!fileDownload && (
              <a
                href={`${baseURLBackend}/contents/${contentId}/content/${moduleId}/module/${id}/course/download?token=${localStorage.getItem(
                  TOKEN_LOCAL_STORAGE
                )}`}
                download={true}
              >
                Download
              </a>
            )}

            <button
              onClick={handleDiscardFileDownload}
              type="button"
              className="btn btn-outline-primary"
            >
              <BsTrash />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-5" style={{ wordBreak: 'break-all' }}>
      <h4 className="mt-1 mb-4">{values.title || 'Novo conteúdo de multimídia'}</h4>

      <form onSubmit={handleSubmit}>
        <div className="form-background mt-4 p-5 round-xl shadow-sm border border-gray bg-white">
          <div className="mb-4">
            <p className="info" style={{ wordBreak: 'break-word', lineHeight: '1.5' }}>
              Disponibilize vídeos hospedados externamente{' '}
              <b style={{ fontWeight: 600 }}>(Youtube ou Vimeo)</b>, auxiliando a compreensão e
              assimilação dos conteúdos pelos alunos, agregando assim, mais conhecimento.
            </p>
          </div>
          <div className="form-group">
            <label className="h6" htmlFor="title">
              Título - Multimídia
            </label>
            <input
              value={values.title}
              placeholder="Digite um título para o seu conteúdo"
              onChange={handleChange}
              name="title"
              id="title"
              type="text"
              className="form-control input-content"
              required
              minLength={1}
              maxLength={255}
            ></input>
          </div>
          <label className="h6" htmlFor="link">
            Conteúdo:
          </label>
          <p style={{ color: '#000000' }}>Use conteúdo hospedado externamente</p>
          <div className="w-100 p-3 input-content">
            <div className="form-group">
              <label className="font-weight-bold mb-1" htmlFor="link">
                Link:
              </label>
              <input
                value={values.link}
                onChange={handleChange}
                name="link"
                id="link"
                type="text"
                className="form-control mb-2 input-content"
                required
                placeholder="exemplo: https://www.youtube.com/xxxxxx ou https://www.vimeo.com/xxxxxx"
              ></input>
              <small id="lessonLink" className="form-text text-muted">
                Nas lições de multimídia você pode adicionar vídeos do{' '}
                <strong className="font-weight-bold">YouTube</strong> ou{' '}
                <strong className="font-weight-bold">Vimeo.</strong>
              </small>
            </div>
          </div>
          <Box my={5}>
            <EditorRichText value={values?.content} onChange={onEditorStateChange} />
          </Box>

          <label
            className="form-control d-flex my-2 border-0 pl-0"
            htmlFor="optionalFile"
            data-toggle="collapse"
            data-target="#optionalFile"
            aria-expanded="false"
            aria-controls="optionalFile"
          >
            <span className="h6">
              Adicionar download <span className="text-gray font-italic">(opcional)</span>
            </span>
            <ArrowDown className="ml-2" />
          </label>

          {!values.fileDownload && (
            <div id="optionalFile" className="collapse">
              <div className="form-group">
                <CustomDropzone
                  file={fileDownload}
                  isDisabled={isLoading}
                  accept="application/pdf"
                  onDrop={handleDropFileDownload}
                  onDropRejected={() => setErrorMessage('Arquivo inválido!')}
                  extensions="PDF"
                  maxFileSize="25 MB"
                />
              </div>
            </div>
          )}

          {values.fileDownload && <PreviewDownload fileDownload={fileDownload} />}

          <DataLoading loading={isLoading} className="d-flex justify-content-center mt-4">
            {errorMessage && !isLoading && (
              <div className="mt-4">
                <div className="alert alert-danger" role="alert" style={{ borderRadius: '0' }}>
                  <div className="text-center">{errorMessage}</div>
                </div>
              </div>
            )}

            <div className="d-flex align-center justify-content-between mt-4">
              <div>
                {contentId && (
                  <button
                    onClick={event => {
                      event.preventDefault();
                      setIsShowModal(true);
                    }}
                    className="btn btn-outline-danger"
                  >
                    Excluir conteúdo
                  </button>
                )}
              </div>

              <div>
                <button onClick={handleDiscardChanges} className="btn btn-light">
                  Descartar mudanças
                </button>
                <button type="submit" className="btn btn-primary ml-3" disabled={!hasChanged}>
                  Salvar
                </button>
              </div>
            </div>
          </DataLoading>
        </div>
      </form>

      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="mb-1 py-4">
            <h3 className="text-center">Você tem certeza que deseja excluir o conteúdo?</h3>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <button onClick={() => setIsShowModal(false)} className="btn btn-light mr-4">
              Cancelar
            </button>
            <button onClick={handleDelete} className="btn btn-danger" disabled={isLoading}>
              {isLoading ? <Spinner small /> : 'Excluir'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MultimediaAddForm;
