import { Button, ModalBody, Stack, Text } from '@chakra-ui/react';
import { memo } from 'react';

function UploadDropZone({ getInputProps, getRootProps, open, isDragReject, isLoading, hasItem }) {
  const uploadError = hasItem && isDragReject;

  return (
    <ModalBody
      borderColor="#20212350"
      borderStyle="dashed"
      borderRadius={6}
      borderWidth="0.5px"
      backgroundColor="#20212310"
      textAlign="center"
      cursor="default"
      {...getRootProps({
        style: uploadError
          ? {
              borderColor: '#c3463c',
              backgroundColor: '#fceae9',
              borderStyle: 'solid',
              color: '#c3463c',
            }
          : {},
      })}
    >
      <Stack spacing={4} flexDirection="column" alignItems="center" justifyContent="center">
        <input {...getInputProps()} id="file" name="file_data" />

        <Text fontSize="14px">
          Arraste e solte seus vídeos aqui <br /> ou
        </Text>
        {uploadError ? (
          <Text fontSize="lg" fontWeight="bold">
            Tipo de arquivo inválido.
          </Text>
        ) : (
          <Button
            minH="2rem"
            size="sm"
            my="4"
            bgColor="primary.500"
            _hover={{ bgColor: 'primary.600' }}
            color="secondary.500"
            borderColor="primary.600"
            borderWidth="thin"
            onClick={() => open()}
            isLoading={isLoading}
          >
            Selecionar arquivo
          </Button>
        )}

        <Text fontSize="14px">
          Você pode enviar arquivos apenas com a extensão{' '}
          <Text as="span" fontWeight={600}>
            MP4
          </Text>
        </Text>

        <Text fontSize="12px">
          *Tamanho máximo do arquivo: 1 GB. Sugerimos compactar seu vídeo, caso ele seja maior que o
          tamanho máximo.
        </Text>
      </Stack>
    </ModalBody>
  );
}

export default memo(UploadDropZone);
