import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Stack,
} from '@chakra-ui/react';
import CourseFilterSelect, { Courses } from '../CourseFilterSelect';
import FilterSelect from '../FilterSelect';

interface FiltersMobileProps {
  filter: string;
  onFilterChange: (value: string) => void;
  onFilterCourseChange: (courseId: number) => void;
  onFilterButtonClick: () => Promise<void>;
  courses: Courses[];
  isLoading: boolean;
}

export default function FiltersMobile({
  filter,
  courses,
  isLoading,
  onFilterChange,
  onFilterCourseChange,
  onFilterButtonClick,
}: FiltersMobileProps) {
  return (
    <Accordion
      display={{ base: 'flex', lg: 'none' }}
      w="full"
      allowToggle
      pb={2}
      px={0}
      border="none"
    >
      <AccordionItem border="none" w="full">
        <h2>
          <AccordionButton
            _focus={{ boxShadow: 'none', outline: 'none' }}
            _hover={{ bg: 'transparent' }}
            border="none"
            p={0}
            color="#20212380"
            fontSize="sm"
            fontWeight="semibold"
          >
            <Box as="span" flex="1" textAlign="left">
              Filtros
              <AccordionIcon />
            </Box>
          </AccordionButton>
        </h2>

        <AccordionPanel px={0} pb={4}>
          <Stack>
            <FilterSelect value={filter} onChange={onFilterChange} />
            <CourseFilterSelect
              onChange={onFilterCourseChange}
              courses={courses}
              isLoading={isLoading}
            />
            <Button
              onClick={onFilterButtonClick}
              width="full"
              flexShrink={0}
              size="xs"
              colorScheme="primary"
              color="secondary.500"
            >
              Filtrar
            </Button>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
