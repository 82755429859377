import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdKeyboardArrowDown as ArrowDown } from 'react-icons/md';

import Spinner from '../../../../../../../../components/Loadings/Spinner';

function DropdownSubmit({ isLoading, handleSubmit, isDisabledSubmit }) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ArrowDown />}
        colorScheme="orange"
        size="sm"
        width={{ base: '100%', md: 'auto', lg: 'auto' }}
      >
        {isLoading ? <Spinner /> : 'Salvar'}
      </MenuButton>
      <MenuList>
        <MenuItem data-status="EM_EDICAO" onClick={handleSubmit}>
          Salvar rascunho
        </MenuItem>
        <MenuItem data-status="ATIVO" isDisabled={isDisabledSubmit} onClick={handleSubmit}>
          Publicar conteúdo
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default DropdownSubmit;
