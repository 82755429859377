import {
  Box,
  Button,
  HStack,
  List,
  Menu,
  MenuButton,
  MenuList,
  Select,
  Text,
  useBreakpointValue,
  useDisclosure,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MdOutlineFilterAlt as FilterIcon } from 'react-icons/md';
import useFilters from '../../../../QuestionLibrary/SearchContainer/Filters/useFilters';
import PreviewFilter from '../PreviewFilter';
import FilterType from '../../../../QuestionLibrary/types/Filter';
import { QueryFilter } from '..';
import dayjs from 'dayjs';

import DateRangePicker from './DateRangePicker';
import DateRangePickerMobile from './DateRangePickerMobile';
import DateFilter from './DateFilter';

export type PreviewFilterOption = {
  label: string;
  value: string;
  name?: 'date' | 'viewOrder' | 'viewType';
};

interface FiltersDropDownProps {
  urlSearchParams?: URLSearchParams;
  onFilter: (filters: Partial<QueryFilter>[]) => void;
  onClearFilters: () => void;
}

interface PreviewFilters {
  startDate?: string;
  endDate?: string;
  viewOrder?: 'biggest_first' | 'smallest_first' | undefined;
  viewType?: 'wrong' | 'correct' | 'performance' | 'id' | undefined;
}

function getValueByName(name: FilterType['name'], filters: Partial<FilterType>[]) {
  return filters.find(item => item.name === name)?.id || '';
}

export default function FiltersDropDown({
  urlSearchParams,
  onFilter,
  onClearFilters,
}: FiltersDropDownProps) {
  const [previewFilters, setPreviewFilters] = useState<PreviewFilters | null>({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isCustomDateFilter, setIsCustomDateFilter] = useState(false);
  const [currentFilterDate, setCurrentFilterDate] = useState('');

  const { filtersList, filters, fetchFilters, handleAddFilter, clearFilters } = useFilters();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const menuRef = useRef();

  useOutsideClick({
    ref: menuRef,
    handler: onClose,
    enabled: !isCustomDateFilter,
  });

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value: filterId, name } = event.target;

    const filter = {
      id: Number(filterId),
      name,
    } as FilterType;

    handleAddFilter(filter);
  }

  function handlePreviewFilterChange(previewFilterOption: Partial<PreviewFilterOption>) {
    urlSearchParams.set(previewFilterOption.name, previewFilterOption.value);

    setPreviewFilters(prevState => ({
      ...prevState,
      [previewFilterOption.name]: previewFilterOption.value,
    }));
  }

  const handleDateFilterChange = useCallback((dateFilter: Partial<PreviewFilterOption>) => {
    const today = dayjs().format('YYYY-MM-DD');
    const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    const lastSevenDays = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
    const lastMonth = dayjs().subtract(30, 'day').format('YYYY-MM-DD');

    setCurrentFilterDate(dateFilter.value);

    if (!dateFilter.value) {
      setStartDate('');
      setEndDate('');
      return;
    }

    if (dateFilter.value === 'custom') {
      return setIsCustomDateFilter(true);
    }

    setIsCustomDateFilter(false);

    if (dateFilter.value === 'today') {
      setStartDate(today);
      setEndDate(today);
      return;
    }

    if (dateFilter.value === 'yesterday') {
      setStartDate(yesterday);
      setEndDate(yesterday);
      return;
    }

    if (dateFilter.value === 'lastSevenDays') {
      setStartDate(lastSevenDays);
      setEndDate(today);
      return;
    }

    setStartDate(lastMonth);
    setEndDate(today);
  }, []);

  function handleFilter() {
    onFilter([
      ...filters,
      { name: 'viewOrder', value: previewFilters.viewOrder },
      { name: 'viewType', value: previewFilters.viewType },
      { name: 'startDate', value: startDate },
      { name: 'endDate', value: endDate },
    ]);

    onToggle();
  }

  function handleClearFilters() {
    onClearFilters();
    clearFilters();
    setCurrentFilterDate('');

    onToggle();
  }

  function handleCustomRangeChange(startDate: string, endDate: string) {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Menu placement="bottom-end" isOpen={isOpen}>
      <MenuButton
        onClick={onToggle}
        as={Button}
        variant="outline"
        border="1px solid #8F8F90"
        color="#8F8F90"
        size="sm"
        minWidth="92px"
        alignItems="stretch"
      >
        <HStack h="full" sx={{ svg: { fontSize: '16px' } }}>
          <Text fontSize="sm">Filtros</Text>

          <FilterIcon />
        </HStack>
      </MenuButton>

      <MenuList
        p={2.5}
        borderRadius="5px"
        border="0.5px solid #0000000D"
        boxShadow="0px 4px 4px 0px #00000040"
        color="#202123"
        w="15.625rem"
        ref={menuRef}
      >
        <Text>Visualização</Text>

        <List mt={2.5} display="flex" flexDirection="column">
          <DateFilter
            name="calendar"
            value={currentFilterDate}
            onChange={handleDateFilterChange}
            label="Data"
            options={[
              { label: 'Período total', value: '' },
              { label: 'Hoje', value: 'today' },
              { label: 'Ontem', value: 'yesterday' },
              { label: 'Últimos 7 dias', value: 'lastSevenDays' },
              { label: 'Últimos 30 dias', value: 'lastMonth' },
              { label: 'Personalizado', value: 'custom' },
            ]}
            offset={[0, -32]}
          />

          {isCustomDateFilter && (
            <>
              {isMobile ? (
                <DateRangePickerMobile onChange={handleCustomRangeChange} />
              ) : (
                <DateRangePicker onChange={handleCustomRangeChange} />
              )}
            </>
          )}

          <Box>
            <PreviewFilter
              name="viewOrder"
              value={urlSearchParams.get('viewOrder')}
              onChange={handlePreviewFilterChange}
              label="Ordenar"
              offset={[0, -32]}
              options={[
                { label: 'Maior primeiro', value: 'biggest_first', name: 'viewOrder' },
                { label: 'Menor primeiro', value: 'smallest_first', name: 'viewOrder' },
              ]}
            />

            <PreviewFilter
              name="viewType"
              value={urlSearchParams.get('viewType')}
              onChange={handlePreviewFilterChange}
              label="Tipo"
              offset={[0, -32]}
              options={[
                { label: 'Acerto', value: 'correct', name: 'viewType' },
                { label: 'Erro', value: 'wrong', name: 'viewType' },
                { label: 'Aproveitamento', value: 'performance', name: 'viewType' },
                { label: 'ID', value: 'id', name: 'viewType' },
              ]}
            />
          </Box>
        </List>

        <Text mt={4}>Filtros de questões</Text>

        <VStack mt={2.5}>
          <Select
            name="area"
            value={getValueByName('area', filters)}
            onChange={handleChange}
            placeholder="Área"
            focusBorderColor="primary.500"
            color="#2D374880"
          >
            {filtersList.areas?.map(area => (
              <option key={`area${area.id}`} value={area.id}>
                {area.name}
              </option>
            ))}
          </Select>

          <Select
            name="discipline"
            value={getValueByName('discipline', filters)}
            onChange={handleChange}
            placeholder="Disciplina"
            focusBorderColor="primary.500"
            color="#2D374880"
          >
            {filtersList.disciplines?.map(discipline => (
              <option key={`discipline${discipline.id}`} value={discipline.id}>
                {discipline.name}
              </option>
            ))}
          </Select>

          <Select
            name="subject"
            value={getValueByName('subject', filters)}
            onChange={handleChange}
            placeholder="Assunto"
            focusBorderColor="primary.500"
            color="#2D374880"
          >
            {filtersList.subjects?.map(subject => (
              <option key={`subject${subject.id}`} value={subject.id}>
                {subject.name}
              </option>
            ))}
          </Select>

          <Select
            name="institution"
            value={getValueByName('institution', filters)}
            onChange={handleChange}
            placeholder="Banca"
            focusBorderColor="primary.500"
            color="#2D374880"
          >
            {filtersList.institutions?.map(institution => (
              <option key={`institution${institution.id}`} value={institution.id}>
                {institution.name}
              </option>
            ))}
          </Select>
        </VStack>

        <HStack as="footer" justify="end" mt={4}>
          <HStack>
            <Button
              onClick={handleClearFilters}
              size="xs"
              variant="link"
              textDecoration="underline"
              fontWeight="normal"
              color="#AFAFAF"
            >
              Limpar filtro
            </Button>

            <Button onClick={handleFilter} size="xs" colorScheme="primary">
              Filtrar
            </Button>
          </HStack>
        </HStack>
      </MenuList>
    </Menu>
  );
}
