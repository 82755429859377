import { Box, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';

import ConvertToBrazilianCurrency from '../../helpers/convertToBrazilianCurrency';
import PointsOpitions from '../../assets/icons/pointsOptions.svg';

type CoursePlanType = {
  removePlan: (id: number) => void;
  courseId: string;
  plan: {
    id: number;
    name: string;
    description: string;
    recurrence: string;
    value: number;
    paymentMethod: string;
    planDuration: string;
    freeTest: string;
  };
};

export default function CardPlan({ plan, removePlan }: CoursePlanType) {
  function getPlanDuration(planDuration: string) {
    if (planDuration === 'ATE_O_CANCELAMENTO') {
      return 'Até o cancelamento';
    } else if (planDuration === 'NUMERO_MAXIMO_DE_PAGAMENTOS') {
      return 'Número máximo de pagamentos';
    }

    return 'Não indentificado';
  }

  function getFreeTest(freeTest: string) {
    if (freeTest === 'MOMENTO_DA_ASSINATURA') {
      return 'Momento da Assinatura';
    } else if (freeTest === 'APOS_O_TESTE') {
      return 'Após o teste';
    }
    return 'Não indentificado';
  }

  function getPaymentMethod(paymentMethod: string) {
    if (paymentMethod === '["credit_card"]') {
      return 'Cartão de crédito';
    } else if (paymentMethod === '["boleto"]') {
      return 'Boleto';
    } else if (paymentMethod === '["boleto","credit_card"]' || paymentMethod === '["credit_card","boleto"]') {
      return 'Cartão de Crédito e Boleto';
    }
    return 'Não indentificado';
  }

  function getRecurrence(recurrence: string) {
    if (recurrence === 'MÊS') {
      return 'Mensal';
    } else if (recurrence === 'BIMESTRE') {
      return 'Bimestral';
    } else if (recurrence === 'TRIMESTRE') {
      return 'Trimestral';
    } else if (recurrence === 'SEMESTRE') {
      return 'Semestral';
    } else if (recurrence === 'ANO') {
      return 'Anual';
    }
    return 'Não indentificado';
  }

  return (
    <>
      <Box
        border="1px solid"
        borderColor="rgba(0, 0, 0, 0.10)"
        p={3}
        mb={2}
        borderRadius={10}
        boxSizing="border-box"
        boxShadow="0px 3px 0px rgba(0, 0, 0, 0.20)"
      >
        <Box display="flex" justifyContent="space-between" py={1}>
          <Text fontWeight="bold" fontSize="lg">
            {plan.name}
          </Text>
          <Menu>
            <MenuButton>
              <Image src={PointsOpitions} p={2} bg="rgba(32, 33, 35, 0.12)" borderRadius={5} />
            </MenuButton>
            <MenuList>
              {/* <MenuItem
                as={Link}
                to={`/courses/${courseId}/course-manager/price/subscription/${plan.id}/edit`}
                fontWeight="bold"
                color="gray"
              >
                Editar
              </MenuItem> */}
              <MenuItem fontWeight="bold" color="red" onClick={() => removePlan(plan.id)}>
                Excluir
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Text mb={5} fontSize="xs" color="rgba(32, 33, 35, 0.5)">
          {plan.description}
        </Text>
        <Box display="flex" fontSize="xs">
          <Text mr={2} fontWeight="bold">
            Recorrência:{' '}
          </Text>
          <Text>{getRecurrence(plan.recurrence)}</Text>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" fontSize="xs">
          <Box display="flex">
            <Text mr={2} fontWeight="bold">
              Forma de pagamento:{' '}
            </Text>
            <Text>{getPaymentMethod(plan.paymentMethod)}</Text>
          </Box>
          <Box display="flex">
            <Text mr={2} color="orange.400" fontWeight="bold" fontSize="xl">
              Valor:{' '}
            </Text>
            <Text color="orange.400" fontWeight="bold" fontSize="xl">
              {' '}
              {ConvertToBrazilianCurrency(plan.value)}
            </Text>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1} fontSize="xs">
          <Box display="flex">
            <Text mr={2} fontWeight="bold">
              Duração do plano:{' '}
            </Text>
            <Text>{getPlanDuration(plan.planDuration)}</Text>
          </Box>
          <Box display="flex">
            <Text color="rgba(32, 33, 35, 0.5)">{getFreeTest(plan.freeTest)}</Text>
          </Box>
        </Box>
      </Box>
    </>
  );
}
