import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import UploadHelper from '../../../../../../helpers/UploadHelper';
import useFetch from '../../../../../../hooks/useFetch';
import useHandleChange from '../../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../../hooks/useHandleSubmit';
import scrollToInvalidInput from '../../../../../../utils/scrollToInvalidInput';
import { Course, PlanResponse } from '../types';
import {
  RecurrencePlanDuplicateSchemaValidator,
  RecurrencePlanPaymentFormSchemaValidator,
} from './schemaValidator';
import { DuplicateRecurrencePlanPaymentForm, RecurrencePlanPaymentForm } from './types';
import { useToast } from '@chakra-ui/react';

const INITIAL_STATE = {
  paymentMethod: ['credit_card'],
  hasTestPeriod: false,
  recurrence: 'monthly',
  status: 'ATIVO',
  planDuration: 'ATE_O_CANCELAMENTO',
  name: '',
  courses: [],
  value: null,
  maxPayments: null,
  trialDays: 0,
  losingAccessAfterLastPayment: false,
} as RecurrencePlanPaymentForm;

interface SelectedCourse {
  label: string;
  value: number;
}

export interface UsePlanManagementEditPaymentProps {
  planData: PlanResponse;
  onIsLoadingChange: (isLoading: boolean) => void;
}

export default function usePlanManagementEditPayment({
  planData,
  onIsLoadingChange,
}: UsePlanManagementEditPaymentProps) {
  const [selectedCourseList, setSelectedCourseList] = useState<SelectedCourse[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const toast = useToast();
  const { planId } = useParams<{ planId: string }>();
  const { search } = useLocation();
  const { push } = useHistory();
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    setSelectedCourseList(
      planData?.courses.map(({ id, name }) => ({ value: id, label: name })) ?? []
    );
  }, [planData?.courses]);

  const { data: response, loading: isLoading } = useFetch<UnificadaFront.ResponseJSON<Course[]>>({
    method: 'get',
    url: '/recurrence-plan/list-course',
    authenticated: true,
    autoFetch: true,
  });

  const courseListOptions = response?.data?.map(course => ({
    value: course.id,
    label: course.name,
  }));

  useEffect(() => {
    onIsLoadingChange(isLoading);
  }, [isLoading, onIsLoadingChange]);

  const isDuplicating = searchParams.get('duplicate') === 'true';
  const isUpdating = !!planId && !!planData && !isDuplicating;

  const shouldFillForm = (isDuplicating || isUpdating) && !isLoading;

  const initialForm = useMemo(() => {
    return isDuplicating
      ? {
          name: planData?.name + ' (Cópia)',
          courses: planData?.courses.map(({ id, name }, index) => ({
            id,
            name,
            order: index + 1,
          })),
          value: Number(planData?.value),
          paymentMethod: planData?.paymentMethod || ['credit_card'],
          hasTestPeriod: planData?.hasTestPeriod || false,
          trialDays: planData?.trialDays || 0,
          recurrence: planData?.recurrence || 'monthly',
          planDuration: planData?.planDuration || 'ATE_O_CANCELAMENTO',
          maxPayments: planData?.maxPayments || null,
          losingAccessAfterLastPayment: planData?.losingAccessAfterLastPayment || false,
          status: planData?.status || 'ATIVO',
          willUsePlanPage: planData?.willUsePlanPage || false,
          pageType: planData?.pageType || 'INTERNAL',
          linkToYourPlan: planData?.linkToYourPlan || null,
          thumbnail: planData?.thumbnail || null,
          mainBanner: planData?.mainBanner || null,
          description: planData?.description || 'Escreva uma descrição para o plano...',
          userFormForPurchase: planData?.userFormForPurchase || ['TEL'],
          topImage: planData?.topImage || null,
          bottomImage: planData?.bottomImage || null,
          thankPageType: planData?.thankPageType || 'DEFAULT',
          thankPageForApprovedPurchases: planData?.thankPageForApprovedPurchases || null,
          thankPageForPurchasesAwaitingPayment:
            planData?.thankPageForPurchasesAwaitingPayment || null,
          thankPageForPurchasesAwaitingAnalysis:
            planData?.thankPageForPurchasesAwaitingAnalysis || null,
          recurrencePlanFrequentlyQuestions: planData?.recurrencePlanFrequentlyQuestions || [],
        }
      : {
          paymentMethod: planData?.paymentMethod,
          hasTestPeriod: planData?.hasTestPeriod,
          recurrence: planData?.recurrence,
          status: planData?.status,
          planDuration: planData?.planDuration,
          name: isDuplicating ? planData?.name + '(Cópia)' : planData?.name,
          courses: planData?.courses.map(({ id, name }, index) => ({
            id,
            name,
            order: index + 1,
          })),
          value: Number(planData?.value),
          maxPayments: planData?.maxPayments,
          trialDays: planData?.trialDays || 0,
          losingAccessAfterLastPayment: planData?.losingAccessAfterLastPayment,
        };
  }, [isDuplicating, planData]);

  const {
    form,
    setForm,
    handleChange,
    handleCancel,
    onChanged: hasChange,
    setOnChanged: setHasChange,
  } = useHandleChange<RecurrencePlanPaymentForm & DuplicateRecurrencePlanPaymentForm>(
    INITIAL_STATE,
    shouldFillForm &&
      (initialForm as RecurrencePlanPaymentForm & DuplicateRecurrencePlanPaymentForm)
  );

  useEffect(() => {
    if (isDuplicating || isUpdating) {
      return setForm(initialForm as DuplicateRecurrencePlanPaymentForm);
    }

    setForm(prevForm => ({
      ...prevForm,
      ...INITIAL_STATE,
    }));
  }, [initialForm, isDuplicating, isUpdating, setForm]);

  function handlePlanValueChange(planValue: number) {
    setForm(prevForm => ({
      ...prevForm,
      value: planValue,
    }));

    setHasChange(true);
  }

  function handlePlanDurationRadioChange(value: string) {
    setForm(prevForm => ({
      ...prevForm,
      planDuration: value as RecurrencePlanPaymentForm['planDuration'],
      maxPayments: value === 'ATE_O_CANCELAMENTO' ? null : prevForm.maxPayments,
    }));

    setHasChange(true);
  }

  function handleStatusRadioChange(value: string) {
    setForm(prevForm => ({ ...prevForm, status: value as RecurrencePlanPaymentForm['status'] }));

    setHasChange(true);
  }

  function handleCourseListChange(newValue: SelectedCourse[]) {
    setSelectedCourseList(newValue);

    setForm(prevForm => ({
      ...prevForm,
      courses: newValue.map(({ value }) => ({ id: value, name: '', order: 0 })),
    }));

    setHasChange(true);
  }

  function handleLosingAccessAfterLastPaymentChange(event: ChangeEvent<HTMLInputElement>) {
    setForm(prevForm => ({
      ...prevForm,
      losingAccessAfterLastPayment: event.target.checked,
    }));

    setHasChange(true);
  }

  function handleCancelButtonClick() {
    if (!hasChange) {
      return push('/recurrence/plan-management');
    }

    handleCancel();
  }

  function getUrl() {
    if (isDuplicating) {
      return '/recurrence-plan/duplicate';
    }

    if (isUpdating) {
      return `/recurrence-plan/${planId}/payment`;
    }

    return '/recurrence-plan';
  }

  function getMethod() {
    if (isUpdating) {
      return 'patch';
    }

    return 'post';
  }

  async function uploadFile(file: File) {
    return await UploadHelper.upload(file, 'banners');
  }

  async function uploadImage(imageUrl: string, imageName: string) {
    setIsUploading(true);

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], `${form?.name}_${imageName}.jpg`, { type: blob.type });
      const { newFileName } = await uploadFile(file);
      return newFileName;
    } catch (error) {
      toast({
        title: 'Não foi possível duplicar o plano com as imagens',
        description: 'Você pode adicionar as imagens manualmente após a duplicação.',
        status: 'error',
        duration: 7000,
        isClosable: false,
      });

      return null;
    } finally {
      setIsUploading(false);
    }
  }

  let payload = {
    ...form,
    courses: selectedCourseList.map(({ value }, index) => ({ id: value, order: index + 1 })),
  };

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: payload,
    url: getUrl(),
    method: getMethod(),
    authenticated: true,
    schemaValidator: isDuplicating
      ? RecurrencePlanDuplicateSchemaValidator
      : RecurrencePlanPaymentFormSchemaValidator,
    removeNullProps: false,
    onSuccess: {
      callback(response: any) {
        if (isUpdating) {
          return push(`/recurrence/plan-management/edit/${planId}/description`);
        }

        const createdPlanId = response.data.data.recurrencePlanId;

        return push(`/recurrence/plan-management/edit/${createdPlanId}/description`);
      },
    },
  });

  async function onSubmit(event: FormEvent<HTMLDivElement>) {
    event.preventDefault();

    scrollToInvalidInput();

    if (isDuplicating) {
      if (payload?.topImage) {
        payload.topImage = await uploadImage(planData.topImage, 'topImage');
      }

      if (payload?.bottomImage) {
        payload.bottomImage = await uploadImage(planData.bottomImage, 'bottomImage');
      }

      if (payload?.thumbnail) {
        payload.thumbnail = await uploadImage(planData.thumbnail, 'thumbnail');
      }

      if (payload?.mainBanner) {
        payload.mainBanner = await uploadImage(planData.mainBanner, 'mainBanner');
      }
    }

    await handleSubmit();
  }

  return {
    form,
    isSubmitting,
    formValidation,
    hasChange,
    courseListOptions,
    selectedCourseList,
    isUploading,
    isUpdating,
    handleCourseListChange,
    handleChange,
    handlePlanValueChange,
    handlePlanDurationRadioChange,
    handleStatusRadioChange,
    handleLosingAccessAfterLastPaymentChange,
    handleCancelButtonClick,
    onSubmit,
  };
}
