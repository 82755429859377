import { Box, Button } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

function SelectTemplateImage({ isDisabled, onDrop, active = false }) {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ['image/jpg', 'image/png', 'image/jpeg', 'image/webp'],
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  function handleClick(event) {
    event.preventDefault();
    if (isDisabled) return;
    open();
  }

  return (
    <Box
      {...getRootProps()}
      height="333px"
      border="1px dashed #AEADAD"
      borderRadius="base"
      background="#F6F5F5"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <input {...getInputProps()} />
      <Button
        size="xs"
        colorScheme="orange"
        onClick={handleClick}
        disabled={isDisabled}
        hidden={active}
      >
        Selecionar imagem
      </Button>
    </Box>
  );
}

export default SelectTemplateImage;
