import './style.css';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FixedSizeList, areEqual } from 'react-window';
import { memo, useEffect, useState } from 'react';

import ErrorMessage from '../ErrorMessage';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { Modal } from 'react-bootstrap';
import Section from './Section';
import Spinner from '../../../../../components/Loadings/Spinner';
import Toast from '../../../../../components/Toast';
import { useTheme } from '../../../../../contexts/ThemeContext';

const nonDraggableSections = ['header', 'footer'];

function getDraggableSections(sections) {
  return sections?.filter(section => !nonDraggableSections.includes(section.name));
}

function getSectionsItems(item, order) {
  return {
    bannerHero: <Section title="Banner principal" draggable order={order} />,
    biography: <Section title="Sobre/Biografia" draggable order={order} />,
    categories: <Section title="Categorias" draggable order={order} />,
    instructors: <Section title="Instrutores" isDisabled order={order} />,
    multimedia: <Section title="Multimídia" draggable order={order} />,
    frequentlyQuestions: <Section title="Perguntas frequentes" isDisabled order={order} />,
    products: <Section title="Produtos" draggable order={order} />,
    testimonials: <Section title="Depoimentos" draggable order={order} />,
    html: <Section title="Personalizada" draggable order={order} />,
  }[item.name];
}

const getItemStyle = ({ provided, style, isDragging }) => {
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };

  const marginBottom = 12;
  const withSpacing = {
    ...combined,
    height: isDragging ? combined.height : combined.height - marginBottom,
    marginBottom,
  };

  return withSpacing;
};

const Item = ({ provided, item, order, style, isDragging }) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getItemStyle({ provided, style, isDragging })}
      className="item"
    >
      {getSectionsItems(item, order)}
    </div>
  );
};

const Row = memo(function Row(props) {
  const { data: items, index, style } = props;

  const item = items[index];
  const order = Number(index) + 1;

  return (
    <Draggable key={String(item.id)} draggableId={String(item.id)} index={index} is>
      {provided => <Item provided={provided} item={item} order={order} style={style} />}
    </Draggable>
  );
}, areEqual);

const getListStyle = isDraggingOver => ({
  border: isDraggingOver ? '2px dashed #19bc90' : '2px dashed #EB7129',
  padding: '16px',
  borderRadius: '4px',
  width: '100%',
});

function ReorderingModal({ showModal, onHideModal, cancelableButton, disabled, sections }) {
  const [orderedSections, setOrderedSections] = useState([]);

  const { theme, updateSectionsOrder } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setOrderedSections(getDraggableSections(sections));
  }, [sections]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(orderedSections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const orderedItems = items.map((item, index) => ({ ...item, order: index + 2 }));

    setOrderedSections(orderedItems);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    setHasChanged(false);

    const fixedSections = sections
      .filter(section => nonDraggableSections.includes(section.name))
      .map(({ id, order, name }) => ({
        id,
        order,
        name,
      }));

    const draggableSections = orderedSections.map(({ id, order, name }) => ({
      id,
      order,
      name,
    }));

    const payload = [...fixedSections, ...draggableSections];

    const theme_id = theme.id;

    try {
      await updateSectionsOrder(theme_id, payload);

      Toast('Ordem das seções alterada com sucesso');
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setIsLoading(false);
      cancelableButton.onCancelableButton();
    }
  }

  return (
    <Modal
      centered
      size="lg"
      show={showModal}
      onHide={onHideModal}
      style={{ backdropFilter: 'blur(4px)' }}
    >
      <Modal.Header className="mb-4" closeButton>
        <Modal.Title>
          <h2
            className="modal-title text-center my-4 sm-px-2 md-px-5"
            id="exampleModalLabel"
            style={{ fontSize: '1.8rem' }}
          >
            Ordene suas seções de acordo com a sua preferência
          </h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="py-4 text-center">
        <p style={{ fontSize: '1rem', color: '#202123' }}>Arraste os items apenas na área ativa.</p>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="app">
            <Droppable
              droppableId="droppable"
              mode="virtual"
              renderClone={(provided, snapshot, rubric) => (
                <Item
                  provided={provided}
                  isDragging={snapshot.isDragging}
                  item={orderedSections[rubric.source.index]}
                />
              )}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <Section title="Menu superior" />
                  <FixedSizeList
                    height={576}
                    itemCount={orderedSections.length}
                    itemSize={72}
                    width="100%"
                    outerRef={provided.innerRef}
                    itemData={orderedSections}
                  >
                    {Row}
                  </FixedSizeList>
                  <Section title="Rodapé" />
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>

        {errorMessage && !hasChanged && <ErrorMessage message={errorMessage} />}

        <div className="d-flex justify-content-end mt-5 mb-3" style={{ gap: '1rem' }}>
          <button
            type="button"
            onClick={cancelableButton.onCancelableButton}
            className="btn btn-light btn-block"
            disabled={disabled}
            style={{ maxWidth: '11rem', borderWidth: 0 }}
          >
            Cancelar
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className={`btn btn-primary btn-block`}
            disabled={disabled}
            style={{ maxWidth: '11rem', fontSize: 'inherit' }}
          >
            {isLoading ? <Spinner /> : 'Salvar alterações'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ReorderingModal;
