import { IconButton, Menu, MenuButton, MenuItem, MenuList, Td, Tr } from '@chakra-ui/react';
import { memo } from 'react';

import { MdOutlineMoreHoriz as MoreIcon } from 'react-icons/md';
import { formatDate, getActionTextByStatus, getStatusColor } from '../../utils';
import { Link } from 'react-router-dom';
import { PromotionView } from '../../types';

interface PromotionsTableRowProps {
  promotion: PromotionView;
  onPromotionStatusChange: () => void;
  onSelectPromotionToEdit: (promotion: PromotionView) => void;
}

function PromotionsTableRow({
  promotion,
  onPromotionStatusChange,
  onSelectPromotionToEdit,
}: PromotionsTableRowProps) {
  function handlePromotionStatusChange() {
    onPromotionStatusChange();
  }

  function handleSelectPromotionToEdit() {
    onSelectPromotionToEdit(promotion);
  }

  return (
    <Tr color="gray.700" sx={{ td: { p: 6 } }}>
      <Td>{promotion.name}</Td>
      <Td>{promotion.numberOfPurchases}</Td>
      <Td>{formatDate(promotion.startTime)}</Td>
      <Td>{formatDate(promotion.endTime)}</Td>
      <Td color={getStatusColor(promotion.status)} textTransform="capitalize">
        {promotion.status.toLowerCase()}
      </Td>

      <Td>
        <Menu size="sm" arrowPadding={0}>
          <MenuButton
            onClick={handleSelectPromotionToEdit}
            size="xs"
            as={IconButton}
            icon={<MoreIcon size="1.125rem" />}
            w="27px"
            h="24px"
            borderRadius={5}
            bgColor="#2021231F"
            _hover={{ bgColor: '#b6b5b5' }}
            _active={{ bgColor: '#b6b5b5' }}
          />

          <MenuList
            sx={{
              '.chakra-menu__menuitem': {
                fontWeight: 'medium',
                fontSize: 'xs',
                color: '#20212380',
                _hover: { bgColor: '#2021230D' },
                _focus: { bgColor: '#2021230D' },
              },
            }}
          >
            <MenuItem as={Link} to={`/promotions/edit/${promotion.id}`}>
              Editar
            </MenuItem>
            <MenuItem onClick={handlePromotionStatusChange}>
              {getActionTextByStatus(promotion.status)}
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

export default memo(PromotionsTableRow);
