import { Box, Center, Heading, Select, Stack } from '@chakra-ui/react';
import Paginate from '../../../components/Paginate';
import CheckoutSettingsHeader from './CheckoutSettingsHeader';
import CheckoutSettingsMobileList from './CheckoutSettingsMobileList';
import CheckoutSettingsTable from './CheckoutSettingsTable';
import Loading from './Loading';
import SearchInput from './SearchInput';
import TypeChangeModal from './TypeChangeModal';
import useCheckoutSettings from './useCheckoutSettings';

export default function CheckoutSettingsPage() {
  const {
    isChangeTypeModalOpen,
    isSubmitting,
    isNotFoundVisible,
    isEmpty,
    currentPage,
    isPaginationVisible,
    isCheckoutSettingsListVisible,
    isLoading,
    checkoutSettingsList,
    pageCount,
    checkoutTypeToUpdate,
    searchQuery,
    checkoutTypeFilterQuery,
    handleCheckoutTypeChangeConfirm,
    handleSearchChange,
    handlePageChange,
    handleTypeChange,
    handleGetCheckoutLink,
    handleCheckoutLinkCopy,
    handleProductToChangeCheckoutTypeClick,
    handleCheckoutTypeToUpdateChange,
    onCloseChangeTypeModal,
  } = useCheckoutSettings();

  return (
    <>
      <Box>
        <CheckoutSettingsHeader />

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          justify={{ base: 'initial', lg: 'space-between' }}
          align="center"
          mt={8}
        >
          <SearchInput
            value={searchQuery}
            onSearch={handleSearchChange}
            marginTop={{ base: 2.5, xl: 'unset' }}
          />

          <Select
            value={checkoutTypeFilterQuery}
            onChange={handleTypeChange}
            maxWidth={{ base: 'unset', xl: '15.6875rem' }}
            order={{ base: '-1', xl: 2 }}
            size="sm"
            borderRadius="md"
            focusBorderColor="gray.300"
            color="#20212380"
          >
            <option value="">Todos os checkouts</option>
            <option value="DEFAULT">Padrão</option>
            <option value="LAUNCH">Lançamento</option>
          </Select>
        </Stack>

        {isLoading && <Loading />}

        {isNotFoundVisible && (
          <Center mt={32}>
            <Heading size="md" fontWeight="semibold" color="gray.200">
              Nenhum resultado encontrado...
            </Heading>
          </Center>
        )}

        {isEmpty && (
          <Center mt={32}>
            <Heading size="md" fontWeight="semibold" color="gray.200" textAlign="center">
              Você não possui nenhum produto cadastrado.
            </Heading>
          </Center>
        )}

        {isCheckoutSettingsListVisible && (
          <>
            <CheckoutSettingsTable
              display={{ base: 'none', xl: 'block' }}
              checkoutSettingsList={checkoutSettingsList}
              onGetCheckoutLink={handleGetCheckoutLink}
              onCheckoutLinkCopy={handleCheckoutLinkCopy}
              onProductToChangeCheckoutTypeClick={handleProductToChangeCheckoutTypeClick}
              mt="0.875rem"
            />

            <CheckoutSettingsMobileList
              display={{ base: 'block', xl: 'none' }}
              checkoutSettingsList={checkoutSettingsList}
              onGetCheckoutLink={handleGetCheckoutLink}
              onCheckoutLinkCopy={handleCheckoutLinkCopy}
              onProductToChangeCheckoutTypeClick={handleProductToChangeCheckoutTypeClick}
              mt={2.5}
            />
          </>
        )}

        {isPaginationVisible && (
          <Paginate
            pageCount={pageCount}
            initialPage={currentPage}
            onPageChange={handlePageChange}
            mt={{ base: 8, xl: 4 }}
          />
        )}
      </Box>

      <TypeChangeModal
        isOpen={isChangeTypeModalOpen}
        isLoading={isSubmitting}
        checkoutTypeToUpdate={checkoutTypeToUpdate}
        onClose={onCloseChangeTypeModal}
        onCheckoutTypeToUpdateChange={handleCheckoutTypeToUpdateChange}
        onConfirm={handleCheckoutTypeChangeConfirm}
      />
    </>
  );
}
