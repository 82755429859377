import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { IoMdAlert } from 'react-icons/io';

export function ModalWarning({ isOpenWarningModal, setError, onCloseWarningModal }) {
  return (
    <Modal
      isOpen={isOpenWarningModal}
      onClose={() => {
        setError(null);
        onCloseWarningModal();
      }}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={5}>
        <Stack direction="row" w="100%" justifyContent="center">
          <IoMdAlert size="40px" color="#DD6B20" />
        </Stack>
        <ModalHeader textAlign="center">
          Esse usuário não cadastrou os seus dados bancários!
        </ModalHeader>
        <ModalCloseButton style={{ boxShadow: 'none', outline: 'none' }} />
        <ModalBody textAlign="center">
          Para ser adicionado como afiliado de um produto o usuário precisa cadastrar os seus dados
          bancários. Entre em contato com esse usuário e peça para ele realizar o cadastro dos dados
          bancários.
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
