import {
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BiPlus } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';
import Pagination from '../../../components/Pagination';
import useFetch from '../../../hooks/useFetch';
import useHandleSubmit from '../../../hooks/useHandleSubmit';
import useQuery from '../../../hooks/useQuery';
import { ModalDeleteSegmentation } from './components/ModalDeleteSegmentation';
import { SegmentationCard } from './components/SegmentationCard';

export interface ISegmentationResponse {
  id: number;
  name: string;
  updatedAt: string;
  numberOfLeads: number;
}
interface IResponse {
  segmentations: ISegmentationResponse[];
  page: number;
  per_page: number;
  total: number;
}
export interface ISegmentationSelect {
  id: number | null;
  name: string;
}

export function Segmentation() {
  const [segmentations, setSegmentations] = useState<ISegmentationResponse[]>([]);
  const [filteredSegmentations, setFilteredSegmentations] = useState<ISegmentationResponse[]>([]);
  const [segmentationSelect, setSegmentationSelect] = useState<ISegmentationSelect>({
    id: null,
    name: '',
  });

  const [timer, setTimer] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [searchFiltered, setSearchFiltered] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    let page = query.get('page');

    if (page) {
      try {
        if (Number(page) < 1) {
          return history.push(`/segmentation?page=1`);
        }

        setCurrentPage(Number(page));
      } catch (error) {
        history.push(`/segmentation?page=1`);
      }
    }
  }, [history, query]);

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<IResponse>>({
    method: 'get',
    url: `/segmentation?page=${currentPage}${search ? `&search=${search}` : ''}`,
    authenticated: true,
    autoFetch: true,
  });

  useEffect(() => {
    if (response?.data?.segmentations) {
      setSegmentations(response?.data?.segmentations);
      setFilteredSegmentations(response?.data?.segmentations);
      setPageCount(response?.data?.total / response?.data?.per_page);
    } else {
      setFilteredSegmentations([]);
    }
  }, [response?.data?.per_page, response?.data?.segmentations, response?.data?.total]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        history.push(`/segmentation?page=1`);
        setSearch(searchFiltered);

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
  }, [history, searchFiltered, timer]);

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchFiltered(value);
    setTimer(2);
  }

  function handlePageChange({ selected: selectedPage }) {
    history.push(`/segmentation?page=${selectedPage + 1}`);
  }

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    url: `/segmentation/${segmentationSelect?.id}`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Segmentação excluída com sucesso!',
      callback() {
        const newArraySegmentation = filteredSegmentations.filter(
          segmentation => segmentation.id !== segmentationSelect.id
        );

        setFilteredSegmentations(newArraySegmentation);
        onClose();
      },
    },
  });

  useEffect(() => {
    if (segmentationSelect.id) {
      onOpen();
    }
  }, [onOpen, segmentationSelect.id]);

  return (
    <>
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        width="full"
        justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
        alignItems={{ base: 'start', md: 'center', lg: 'center' }}
        paddingBottom="20px"
      >
        <Heading fontSize={{ base: '36px', md: '36px', lg: '36px' }} fontWeight={600}>
          Segmentações
        </Heading>
        <Button
          colorScheme="orange"
          size="sm"
          width={{ base: 'full', md: 'auto', lg: 'auto' }}
          as={Link}
          to="/segmentation/new"
        >
          <Stack direction="row" alignItems="center">
            <BiPlus size={15} />
            <Text>Adicionar segmentação</Text>
          </Stack>
        </Button>
      </Stack>

      <Text color="rgba(32, 33, 35, 0.50)" fontSize={{ base: '18px', md: '18px', lg: '18px' }}>
        Divida seus leads em segmentações.
      </Text>

      <InputGroup size="sm" width={{ base: 'full', md: '268px', lg: '268px' }} marginY="30px">
        <InputLeftElement pointerEvents="none">
          <AiOutlineSearch size={20} color="#20212380" />
        </InputLeftElement>
        <Input
          placeholder="Buscar segmentação"
          value={searchFiltered}
          isDisabled={segmentations.length === 0}
          onChange={handleChangeSearch}
          borderRadius={5}
          focusBorderColor="orange.500"
        />
      </InputGroup>

      <Box
        display="flex"
        flexDirection="row"
        width="full"
        justifyContent="center"
        hidden={!loading}
      >
        <Spinner size="lg" color="orange.500" />
      </Box>

      <Box display="flex" flexDirection="row" flex={1} flexWrap="wrap" gap="14px">
        {!loading &&
          filteredSegmentations.length > 0 &&
          filteredSegmentations.map(segmentation => (
            <SegmentationCard
              segmentation={segmentation}
              setSegmentationSelect={setSegmentationSelect}
            />
          ))}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        width="full"
        justifyContent="center"
        hidden={loading || filteredSegmentations.length > 0 || search !== ''}
      >
        <Heading color="#20212380" fontSize="lg" textAlign="center">
          Você não possui segmentações. <br /> Clique no botão "Adicionar segmentação" para criar
          uma nova segmentação.
        </Heading>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        width="full"
        justifyContent="center"
        hidden={loading || filteredSegmentations.length > 0 || search === ''}
      >
        <Heading color="#20212380" fontSize="lg" textAlign="center">
          Nenhum resultado encontrado para "{search}"
        </Heading>
      </Box>

      <Box marginY={5} hidden={loading || filteredSegmentations.length === 0}>
        <Pagination
          onPageActive={currentPage - 1}
          pageCount={pageCount}
          onPageChange={handlePageChange}
        />
      </Box>

      <ModalDeleteSegmentation
        isOpen={isOpen}
        onClose={onClose}
        segmentationSelect={segmentationSelect}
        setSegmentationSelect={setSegmentationSelect}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </>
  );
}
