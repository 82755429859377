import Joi from 'joi';
import {
  IAddingQuestionsSchema,
  IQuestionLibrarySchemaPayload,
  IQuestionOrderSchema,
} from './types';

export const questionLibrarySchema = Joi.object<IQuestionLibrarySchemaPayload>({
  title: Joi.string()
    .required()
    .messages({
      'string.empty': 'O título do conteúdo não pode ficar vazio.',
      'string.base': 'Informe um título do conteúdo válido',
      'any.required': 'O título do conteúdo é obrigatório',
    } as JoiMessages.LanguageMessages),
  contentType: Joi.string().messages({
    'string.empty': 'O tipo do conteúdo não pode ficar vazio.',
    'string.base': 'Informe um tipo do conteúdo válido',
  } as JoiMessages.LanguageMessages),
  status: Joi.string().messages({
    'string.empty': 'O status não pode ficar vazio.',
    'string.base': 'Informe um status válido',
  } as JoiMessages.LanguageMessages),
  order: Joi.number().messages({
    'number.base': 'A ordem não pode ficar vazio.',
  } as JoiMessages.LanguageMessages),
  numberOfTimesAnswerQuestion: Joi.number().messages({
    'number.base': 'O número de vezes para refazer não pode ficar vazio.',
  } as JoiMessages.LanguageMessages),
});

export const questionOrderSchema = Joi.object<IQuestionOrderSchema>().keys({
  questionOrders: Joi.array().items(
    Joi.object().keys({
      questionId: Joi.number().messages({
        'number.base': 'A ordem não pode ficar vazio.',
      } as JoiMessages.LanguageMessages),
      order: Joi.number().messages({
        'number.base': 'A ordem não pode ficar vazio.',
      } as JoiMessages.LanguageMessages),
    })
  ),
});
export const addingQuestionsSchema = Joi.object<IAddingQuestionsSchema>().keys({
  questionsLibraryIds: Joi.array().items(Joi.number()).required(),
});
