import useHandleChange from '../../../../hooks/useHandleChange';
import { FormEvent, KeyboardEvent } from 'react';
import useHandleSubmit from '../../../../hooks/useHandleSubmit';
import { useHistory } from 'react-router-dom';
import scrollToInvalidInput from '../../../../utils/scrollToInvalidInput';
import { UTM } from './types';
import schemaValidator from './schemaValidator';

const initialState = {
  link: '',
  utmCampaign: null,
  utmSource: '',
  utmMedium: '',
  utmId: null,
  utmTerm: null,
  utmContent: null,
};

export default function useNewUtm() {
  const {
    form,
    handleChange,
    onChanged: hasChange,
    handleCancel,
  } = useHandleChange<UTM>(initialState);
  const { push } = useHistory();

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit,
  } = useHandleSubmit({
    data: form,
    url: '/utm/new',
    method: 'post',
    onSuccess: {
      message: 'UTM criado com sucesso!',
      callback: () => push('/utm-generator'),
    },
    removeNullProps: false,
    authenticated: true,
    schemaValidator,
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    scrollToInvalidInput();

    await handleSubmit();
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  function generateUTMLink() {
    if (!form.link || formValidation?.link?.isInvalid) {
      return 'https://exemplo.com.br';
    }

    const params = new URLSearchParams();

    if (form.utmCampaign) params.append('utm_campaign', form.utmCampaign);
    if (form.utmSource) params.append('utm_source', form.utmSource);
    if (form.utmMedium) params.append('utm_medium', form.utmMedium);
    if (form.utmId) params.append('utm_id', form.utmId);
    if (form.utmTerm) params.append('utm_term', form.utmTerm);
    if (form.utmContent) params.append('utm_content', form.utmContent);

    const paramString = params.toString();

    return paramString ? `${form.link}?${paramString}` : form.link;
  }

  const utmLink = generateUTMLink();

  function onCancel() {
    if (!hasChange) {
      return push('/utm-generator');
    }

    handleCancel();
  }

  return {
    form,
    isSubmitting,
    utmLink,
    hasChange,
    formValidation,
    onSubmit,
    handleChange,
    onCancel,
    handleKeyDown,
  };
}
