import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';

import Joi from 'joi';
import { FormEvent, lazy, Suspense, useCallback } from 'react';
import { Link as ReactRouterDomLink, useHistory, useLocation } from 'react-router-dom';
import FacebookIntegration from '../../api/FacebookIntegration';
import { getCountryByDDI, getDDI } from '../../components/CountrySelect';
import PasswordInput from '../../components/Inputs/PasswordInput';
import { useTheme } from '../../contexts/ThemeContext';
import useHandleChange, { onlyNumber } from '../../hooks/useHandleChange';
import useHandleSubmit from '../../hooks/useHandleSubmit';
import { ResponseJSON } from '../../utils/CreateToastAlert';
import UserSchema, { UserSchemaType } from '../../validation/UserSchema';
const CountrySelect = lazy(() => import('./CountrySelect'));

type SignUpResultType = {
  fullName: string;
  phoneNumber: string;
  role: string;
  token: string;
};

declare global {
  interface Window {
    fbq?: Function;
  }
}

export default function StudentSignUp({ loginModalQuestions = false }) {
  const { themeMarketplace } = useTheme();
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const searchParams = queryParams.toString();
  const isPriceFree = !!queryParams.get('priceFree');
  const productId = queryParams.get('productId');
  const affiliation = queryParams.get('affiliation');

  type SignUpSchemaType = Pick<UserSchemaType, 'fullName' | 'email' | 'password'> & {
    ddi: string;
    ddd: string;
    phone: string;
    isTermsAccepted: boolean;
    type: string;
  };

  const SignUpSchema = Joi.object<SignUpSchemaType>().keys({
    fullName: UserSchema.fullName.required(),
    email: UserSchema.email.required(),
    ddi: Joi.string()
      .max(5)
      .required()
      .messages({
        'string.max': 'O DDI deve conter no máximo 5 dígitos.',
        'any.required': 'O DDI é obrigatório.',
        'string.empty': 'Informe um DDI.',
      } as JoiMessages.LanguageMessages),
    ddd: Joi.when('ddi', {
      is: '55',
      then: Joi.string()
        .length(2)
        .required()
        .messages({
          'string.length': 'Deve ser 2 dígitos.',
          'any.required': 'DDD é obrigatório.',
          'string.empty': 'Informe o DDD.',
        } as JoiMessages.LanguageMessages),
      otherwise: Joi.string().optional().allow(null, ''),
    }),
    phone: Joi.when('ddi', {
      is: '55',
      then: Joi.string()
        .min(8)
        .max(20)
        .required()
        .messages({
          'string.min': 'Deve conter no mínimo 8 dígitos.',
          'string.max': 'Deve conter no máximo 20 dígitos.',
          'any.required': 'Telefone é obrigatório.',
          'string.empty': 'Informe um telefone.',
        } as JoiMessages.LanguageMessages),
      otherwise: Joi.string()
        .max(20)
        .required()
        .messages({
          'string.max': 'Deve conter no máximo 20 dígitos.',
          'any.required': 'Telefone é obrigatório.',
          'string.empty': 'Informe um telefone.',
        } as JoiMessages.LanguageMessages),
    }),
    password: UserSchema.password.required(),
    isTermsAccepted: Joi.boolean()
      .valid(true)
      .required()
      .messages({
        'any.only': 'Aceite o termos antes de continuar.',
      } as JoiMessages.LanguageMessages),
    type: Joi.string()
      .valid('REGISTER_DEFAULT', 'REGISTER_QUESTION')
      .messages({
        'string.base': 'O tipo deve ser uma string',
        'string.empty': 'O tipo não pode ser vazio',
      } as JoiMessages.LanguageMessages),
  });

  const { form, handleChange, setForm } = useHandleChange<SignUpSchemaType>({
    fullName: '',
    email: '',
    ddi: '55',
    ddd: null,
    phone: '',
    password: '',
    isTermsAccepted: false,
    type: !loginModalQuestions ? 'REGISTER_DEFAULT' : 'REGISTER_QUESTION',
  });

  const { isLoading, handleSubmit, formValidation } = useHandleSubmit({
    data: form,
    schemaValidator: SignUpSchema,
    method: 'post',
    url: '/users/students',
    onSuccess: {
      message: 'Usuário cadastro com sucesso',
    },
  });

  const handleCountryChange = useCallback(
    (country: string) => {
      setForm(prevForm => ({
        ...prevForm,
        ddi: getDDI(country),
        ddd: null,
      }));
    },
    [setForm]
  );

  async function registersFreeProductPurchasePixelEvent() {
    const { data } = await FacebookIntegration.getPixelByProduct(Number(productId), affiliation);

    if (data.length) {
      // Criação do script do Facebook Pixel
      const facebookPixelScript = document.createElement('script');

      const pixelId = data[0].identifier;

      facebookPixelScript.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', ${pixelId});
    fbq('track', 'PageView');
  `;

      // Adiciona o script ao final do body
      document.body.appendChild(facebookPixelScript);

      window.fbq('track', 'Purchase', {
        value: 0,
        currency: 'BRL',
        content_ids: [`${productId}`],
      });

      // Remove o script após a execução
      document.body.removeChild(facebookPixelScript);
    }
  }

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    const response = await handleSubmit<ResponseJSON<SignUpResultType>>();

    if (!response) return;

    if (isPriceFree) {
      await registersFreeProductPurchasePixelEvent();
    }

    return history.push(
      `/login?${searchParams}&token=${response.data.data.token}${
        loginModalQuestions ? '&path=/question' : ''
      }`
    );
  }

  const isForeignUser = form.ddi !== '55';

  return (
    <Flex
      height={loginModalQuestions ? '100%' : '100vh'}
      justify="center"
      bgGradient="linear(to-r, primary.500, primary.200)"
      color="secondary.500"
      overflowY={loginModalQuestions ? '-moz-hidden-unscrollable' : 'auto'}
      paddingY={4}
      px={{ base: 4, xl: loginModalQuestions ? 8 : 4 }}
    >
      <Flex
        direction="column"
        justify="center"
        flex={1}
        as="form"
        onSubmit={onSubmit}
        alignItems="center"
        paddingY={loginModalQuestions ? '5' : 'auto'}
        maxWidth={!loginModalQuestions ? '30rem' : 'none'}
      >
        <Image
          margin="0 auto"
          height="5rem"
          src={themeMarketplace.logo}
          alt="Logo"
          objectFit="cover"
        />

        <Flex width="100%" alignItems="center" flexDirection="column">
          <Heading as="h1" size="md" marginTop={{ base: 0, xl: 4 }}>
            Cadastro de aluno
          </Heading>

          <VStack w="full" spacing={5}>
            <FormControl isInvalid={formValidation?.fullName.isInvalid}>
              <FormLabel>Nome</FormLabel>
              <Input
                borderColor="secondary.500"
                name="fullName"
                onChange={handleChange}
                value={form.fullName}
                placeholder="Digite seu nome completo"
                focusBorderColor="secondary.500"
                _placeholder={{ color: 'secondary.600' }}
              />
              <FormErrorMessage>{formValidation?.fullName.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.email.isInvalid}>
              <FormLabel>E-mail</FormLabel>
              <Input
                borderColor="secondary.500"
                name="email"
                onChange={handleChange}
                value={form.email}
                placeholder="Digite seu e-mail"
                focusBorderColor="secondary.500"
                _placeholder={{ color: 'secondary.600' }}
              />
              <FormErrorMessage>{formValidation?.email.message}</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="country">País</FormLabel>
              <Suspense fallback={<Skeleton w="full" h={10} />}>
                <CountrySelect value={getCountryByDDI(form?.ddi)} onChange={handleCountryChange} />
              </Suspense>
            </FormControl>

            <FormControl
              isInvalid={formValidation?.ddd.isInvalid || formValidation?.phone.isInvalid}
              width="100px"
              w="full"
            >
              <HStack width="full" spacing={4}>
                {!isForeignUser && (
                  <FormControl
                    maxWidth="33.33%"
                    isInvalid={formValidation?.ddd.isInvalid || formValidation?.phone.isInvalid}
                  >
                    <FormLabel>DDD</FormLabel>
                    <Input
                      type="tel"
                      name="ddd"
                      borderColor="secondary.500"
                      onChange={handleChange}
                      value={form.ddd}
                      placeholder="DDD"
                      focusBorderColor="secondary.500"
                      _placeholder={{ color: 'secondary.600' }}
                      onInput={onlyNumber}
                      maxLength={2}
                    />
                  </FormControl>
                )}

                <FormControl
                  isInvalid={formValidation?.ddd.isInvalid || formValidation?.phone.isInvalid}
                >
                  <FormLabel>Telefone</FormLabel>
                  <Input
                    type="tel"
                    borderColor="secondary.500"
                    name="phone"
                    onChange={handleChange}
                    value={form.phone}
                    placeholder="Digite seu telefone"
                    focusBorderColor="secondary.500"
                    _placeholder={{ color: 'secondary.600' }}
                    onInput={onlyNumber}
                    maxLength={20}
                  />
                </FormControl>
              </HStack>

              <FormErrorMessage>
                {formValidation?.ddd.message || formValidation?.phone.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.password.isInvalid}>
              <FormLabel>Senha</FormLabel>
              <PasswordInput
                name="password"
                onChange={handleChange}
                value={form.password}
                placeholder="Digite uma senha"
                borderColor="secondary.500"
                focusBorderColor="secondary.500"
                _placeholder={{ color: 'secondary.600' }}
              />
              <FormErrorMessage>{formValidation?.password?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formValidation?.isTermsAccepted?.isInvalid}>
              <HStack justifyContent="center">
                <Checkbox
                  name="isTermsAccepted"
                  onChange={handleChange}
                  checked={form.isTermsAccepted}
                  colorScheme="secondary"
                  borderColor="secondary.500"
                  color="red"
                  sx={{
                    svg: {
                      color: 'primary.500',
                    },
                  }}
                />
                <FormLabel fontSize="sm" margin={0}>
                  Li e aceito os{' '}
                  <Link
                    href="https://checkout.proluno.me/privacy-policy"
                    isExternal
                    textDecoration="underline"
                    fontWeight="semibold"
                  >
                    termos de uso
                  </Link>
                </FormLabel>
              </HStack>

              <FormErrorMessage justifyContent="center">
                {formValidation?.isTermsAccepted?.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>

          <VStack spacing={4} mt="5" width="full">
            <Button
              colorScheme="secondary"
              type="submit"
              isLoading={isLoading}
              width="full"
              color="primary.500"
            >
              Realizar cadastro
            </Button>

            <HStack fontSize="sm" fontWeight="bold" spacing={1}>
              <Text color="secondary.500">Já tem uma conta?</Text>

              <Text
                as={ReactRouterDomLink}
                color="secondary.500"
                textDecoration="underline"
                to={`/login?${searchParams}`}
              >
                Entrar
              </Text>
            </HStack>
          </VStack>
        </Flex>
      </Flex>
    </Flex>
  );
}
