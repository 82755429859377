import { Link, Route, Switch } from 'react-router-dom';

import { MdAddCircleOutline } from 'react-icons/md';
import styles from './styles.module.css';

function Section({
  description  ='',
  actionName,
  path = '',
  children,
  ...props
}) {
  return (
    <div style={{ ...props }} className={styles.sectionContainer}>
      <Switch>
        <Route path={`/page-builder/${path}`}>
          <div>{children}</div>
        </Route>

        <Route path={`/page-builder`}>
          {description && (
            <div className={styles.sectionDescription}>
              <div>
                <p>{description}</p>
              </div>
            </div>
          )}

          {actionName && (
            <div className={styles.sectionAction}>
              <Link to={`/page-builder/${path}`}>
                <button className="btn btn-primary">
                  <MdAddCircleOutline size="1.125rem" /> {actionName}
                </button>
              </Link>
            </div>
          )}
        </Route>
      </Switch>
    </div>
  );
}

export default Section;
