import { Box, Button, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState } from 'react';

type ImageField = 'imageDesktop' | 'imageMobile';

interface ImagePreviewProps {
  preview: string;
  imageField: ImageField;
  onSelectNewImage?: () => void;
}

export default function ImagePreview({ preview, onSelectNewImage }: ImagePreviewProps) {
  const [isButtonVisible, setButtonVisible] = useState(false);

  function handleSelectNewImage() {
    onSelectNewImage();
  }

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <Box
      position="relative"
      onMouseEnter={() => setButtonVisible(true)}
      onMouseLeave={() => setButtonVisible(false)}
      h="full"
      id="box"
    >
      <Image src={preview} objectFit="cover" boxSize="full" maxHeight="100%" />

      {isButtonVisible && (
        <>
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            transition={{ duration: 0.3 }}
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              background: '#20212380',
              flexDirection: 'column',
              gap: '8px',
              zIndex: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={handleSelectNewImage}
              colorScheme="primary"
              zIndex="2"
              color="secondary.500"
            >
              Trocar Imagem
            </Button>
          </motion.div>
        </>
      )}
    </Box>
  );
}
