import { Card, Image, Stack, Text, HStack, Link } from '@chakra-ui/react';

import FacebookIcon from '../../../../../../assets/icons/social/facebook.png';
import InstagramIcon from '../../../../../../assets/icons/social/instagram.png';
import LinkedInIcon from '../../../../../../assets/icons/social/linkedin.png';
import YouTubeIcon from '../../../../../../assets/icons/social/youtube.png';

import { ISocialNetwork } from '..';

interface IISocialNetworkProp {
  socialNetworks: ISocialNetwork[];
  hidden: boolean;
}

function getSocialIcon(typeName: string) {
  switch (typeName) {
    case 'FACEBOOK':
      return FacebookIcon;
    case 'INSTAGRAM':
      return InstagramIcon;
    case 'LINKEDIN':
      return LinkedInIcon;
    case 'YOUTUBE':
      return YouTubeIcon;
  }
}

export default function SocialNetworkCard({ socialNetworks, hidden }: IISocialNetworkProp) {
  return (
    <Card
      width={{ base: 'full', md: '182px', lg: '182px' }}
      height="auto"
      flexShrink="0"
      borderRadius="6px"
      border="1px solid rgba(0, 0, 0, 0.05)"
      background="#FFF"
      boxShadow="1px 1px 4px 0px rgba(0, 0, 0, 0.25)"
      padding="16px 13px"
      hidden={hidden}
    >
      <Stack spacing="14px">
        <Text fontSize="18px" fontWeight={600} lineHeight="20px">
          Redes Sociais
        </Text>

        <Stack
          direction={{ base: 'row', md: 'column', lg: 'column' }}
          spacing={0}
          gap="14px"
          flexWrap="wrap"
        >
          {socialNetworks?.map(socialNetwork => (
            <>
              <HStack width="150px" hidden={!socialNetwork?.url}>
                <Image src={getSocialIcon(socialNetwork?.type)} boxSize="17px" />
                <Text
                  as={Link}
                  target="_blank"
                  href={socialNetwork?.url}
                  textTransform="capitalize"
                >
                  {socialNetwork?.type.toLowerCase()}
                </Text>
              </HStack>
            </>
          ))}
        </Stack>
      </Stack>
    </Card>
  );
}
