import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState } from 'react';
import FullPageLoading from '../../../../components/FullPageLoading';
import convertToBrazilianCurrency from '../../../../helpers/convertToBrazilianCurrency';
import useFetch from '../../../../hooks/useFetch';
import PurchaseDetailsModal from '../components/PurchaseDetailsModal';
import Status from '../components/Status';
import getPaymentMethod from '../utils/getPaymentMethod';

interface IProduct {
  id: number;
  name: string;
  price: string;
  amount: number;
}
interface IPayment {
  id: number;
  products: IProduct[];
  value: number;
  paymentMethod: string;
  installments: number;
  date: Date;
  status: string;
  link: string;
  pixQrCode: string;
}

export type IItemType = {
  id: number;
  name: string;
  price: string;
  amount: number;
};

export default function ProductsList({ toggleModal }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [transactionFiltered, setTransactionFiltered] = useState([]);

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<IPayment[]>>({
    method: 'get',
    url: `/students/purchases-historic`,
    authenticated: true,
    autoFetch: true,
  });

  const paymentList = response?.data;

  function filterTransactionId(products: IItemType[]) {
    setTransactionFiltered(products);
    onOpen();
  }

  return (
    <TableContainer
      hidden={!paymentList?.length}
      border="1px solid rgba(0, 0, 0, 0.1)"
      borderRadius="5px"
    >
      <Table variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th>N° do Pedido</Th>
            <Th>Valor</Th>
            <Th>Forma de pagamento</Th>
            <Th>Parcelas</Th>
            <Th>Data</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>

        <Tbody>
          {paymentList?.map(payment => (
            <Tr key={payment.id} cursor="pointer">
              <Td onClick={() => filterTransactionId(payment.products)}>
                <Box color="primary.400" fontWeight="semibold">
                  #{payment.id}
                </Box>
              </Td>
              <Td onClick={() => filterTransactionId(payment.products)}>
                {convertToBrazilianCurrency(payment.value)}
              </Td>
              <Td onClick={() => filterTransactionId(payment.products)}>
                {getPaymentMethod(payment.paymentMethod)}
              </Td>
              <Td onClick={() => filterTransactionId(payment.products)}>
                {payment.installments === 1 ? 'À vista' : payment.installments}
              </Td>
              <Td onClick={() => filterTransactionId(payment.products)}>
                {dayjs(payment.date).format('DD/MM/YYYY')}
              </Td>
              <Td>
                <Status
                  paymentMethod={payment.paymentMethod}
                  paymentStatus={payment.status}
                  link={payment.link}
                  onClick={() => toggleModal(payment.pixQrCode)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <FullPageLoading isLoading={loading} />

      <PurchaseDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        transactionFiltered={transactionFiltered}
      />
    </TableContainer>
  );
}
