import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { GrAdd as AddIcon } from 'react-icons/gr';
import { Link, useHistory, useParams } from 'react-router-dom';
import CourseSubscriptionPlanAPI from '../../../../../../api/CourseSubscriptionPlan';
import CardPlan from '../../../../../../components/CardPlan';
import { Heading } from '../../../../../../components/Heading';
import Toast from '../../../../../../components/Toast';
import { useCourse } from '../../../../../../contexts/CourseContext';
import differenceBy from '../../../../../../helpers/differenceBy';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';

type RecurrenceType = 'MÊS' | 'BIMESTRE' | 'TRIMESTRE' | 'SEMESTRE' | 'ANO';
type PaymentMethodType = 'credit_card' | 'boleto' | 'credit_card, boleto';
type FreeTestType = 'MOMENTO_DA_ASSINATURA' | 'APOS_O_TESTE';
type PlanDurationType = 'ATE_O_CANCELAMENTO' | 'NUMERO_MAXIMO_DE_PAGAMENTOS';

export interface ISubscriptionPlan {
  id: number;
  name: string;
  value: string;
  description: string;
  recurrence: RecurrenceType;
  paymentMethod: PaymentMethodType;
  planDuration: PlanDurationType;
  maxPayments: number;
  hasTestPeriod: boolean;
  freeTest: FreeTestType;
  trialDays: number;
  pagarMePlanIdentifier: number;
}

const initialState = {
  subscription: false,
  deadlineForReimbursement: '',
};

export default function SubscriptionPlan() {
  const [isLoading, setIsLoading] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [allPlans, setAllPlans] = useState<ISubscriptionPlan[]>([]);
  const [currentPlans, setCurrentPlans] = useState([]);
  const [currentPlanId, setCurrentPlanId] = useState([]);
  const [planSelected, setPlanSelected] = useState('');
  const [subscriptionPlan, setSubscriptionPlan] = useState(initialState);

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { course, onChangeCourse, updateCourse } = useCourse();

  useEffect(() => {
    setSubscriptionPlan({
      subscription: course.subscription,
      deadlineForReimbursement: subscriptionPlan.deadlineForReimbursement,
    });

    setCurrentPlans(course.plans);

    plansId(course.plans);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course.deadlineForReimbursement, course.plans, course.subscription]);

  function plansId(plans: ISubscriptionPlan[]) {
    const planId = plans?.map(plan => plan.id);
    return setCurrentPlanId(planId);
  }

  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setPlanSelected(value);
  }

  function addPlan() {
    const currentPlan = allPlans.find(plan => plan.id === parseInt(planSelected));
    setCurrentPlans([...currentPlans, currentPlan]);
    setCurrentPlanId([...currentPlanId, currentPlan.id]);
    setHasChanged(true);
    onClose();
  }

  function removePlan(id: number) {
    const currentPlanFiltered = currentPlans.filter(currentPlan => currentPlan.id !== id);
    setCurrentPlans(currentPlanFiltered);
    setHasChanged(true);
  }

  function handleSwitchChange(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;

    const hasPlan = !!currentPlans.length;

    const subscription = hasPlan ? checked : false;

    if (!hasPlan) {
      Toast('Adicione um plano antes de ativar a assinatura', 'error');
      return;
    }

    setSubscriptionPlan({ ...subscriptionPlan, subscription });

    setHasChanged(true);
  }

  useEffect(() => {
    async function getAllPlans() {
      try {
        const { data: planData } = await CourseSubscriptionPlanAPI.subscriptionPlans();
        setAllPlans(planData);
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    }
    getAllPlans();
  }, []);

  function handleClick() {
    const hasPlan = !!allPlans.length;

    if (!hasPlan) {
      history.push(`/courses/${course.id}/course-manager/price/subscription/new-plan`);
      return;
    }

    onOpen();
  }

  async function handleSubmit() {
    try {
      setIsLoading(true);

      let message = 'Assinatura salva com sucesso!';

      const payload = {
        subscription: subscriptionPlan.subscription,
        courseSubscriptionPlan: currentPlans.map(currentPlan => currentPlan.id),
        deadlineForReimbursement: subscriptionPlan.deadlineForReimbursement,
        status: course.status,
      };

      if (!currentPlans.length) {
        payload.subscription = false;

        message = 'Assinatura desativada sucesso!';
        onChangeCourse({
          ...course,
          subscriptionPlan: false,
        });
      }

      if (payload.deadlineForReimbursement === '') {
        payload.deadlineForReimbursement = null;
      }

      setSubscriptionPlan({
        ...subscriptionPlan,
        subscription: payload.subscription,
        deadlineForReimbursement: payload.deadlineForReimbursement,
      });

      await CourseSubscriptionPlanAPI.update(id, payload);

      onChangeCourse({
        ...course,
        plans: [...currentPlans],
        subscription: payload.subscription,
      });

      if (subscriptionPlan.subscription === false) {
        payload.status = 'EM_EDICAO';

        await updateCourse({ status: 'EM_EDICAO' });
      }

      Toast(message);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const plansToSelect = differenceBy(allPlans, currentPlans, 'id');

  return (
    <Box>
      <Heading as="h4" fontWeight="600" fontSize="2xl">
        Assinatura:
      </Heading>
      <Box
        borderRadius={10}
        border="solid 1px rgba(32, 33, 35, 0.5)"
        boxShadow="0px 4px 0px rgba(0, 0, 0, 0.20)"
        boxSizing="border-box"
        p={5}
        mb={5}
      >
        <Box mb={5}>
          <Text fontSize="md" mb={1} fontWeight="bold">
            Deseja que seu produto tenha assinatura?
          </Text>
          <Switch
            colorScheme="default"
            size="sm"
            onChange={handleSwitchChange}
            name="subscription"
            isChecked={subscriptionPlan.subscription}
          >
            Ativar Assinatura
          </Switch>
        </Box>
        <Box display="flex" justifyContent="space-between" my={5}>
          <Text fontSize="lg" fontWeight="bold">
            Planos
          </Text>
          <Link to={`/courses/${course.id}/course-manager/price/subscription/new-plan`}>
            <Button variant="outline" colorScheme="default" leftIcon={<AddIcon />} size="xs">
              Novo plano
            </Button>
          </Link>
        </Box>

        {isLoading ? (
          <Flex justifyContent="center" marginY={16}>
            <Spinner color="default.500" size="xl" justifySelf={'center'} margin="0 auto" />
          </Flex>
        ) : (
          currentPlans?.map(plan => (
            <CardPlan key={plan.id} plan={plan} removePlan={removePlan} courseId={course.id} />
          ))
        )}

        <Box
          onClick={handleClick}
          display="flex"
          justifyContent="center"
          my={5}
          p={2}
          border="1px"
          borderStyle="dashed"
          borderRadius="6px"
          borderColor="rgba(32, 33, 35, 0.5)"
          color="#20212380"
          cursor="pointer"
        >
          <Text fontSize="md" fontWeight="bold" color="rgba(32, 33, 35, 0.5)">
            Adicionar plano existente
          </Text>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Adicione um plano existente</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Selecione um plano existe para adicionar ao seu produto.</Text>
              <Select
                mt={4}
                mb={10}
                placeholder="Selecione um plano"
                size="sm"
                onChange={handleChange}
              >
                {allPlans.length
                  ? plansToSelect?.map(plan => (
                      <option key={plan.id} value={plan.id}>
                        {plan.name}
                      </option>
                    ))
                  : null}
              </Select>

              <HStack width="full" py={5}>
                <Button
                  size="sm"
                  width="full"
                  colorScheme="default"
                  color="secondary.500"
                  variant="outline"
                  onClick={onClose}
                >
                  Cancelar
                </Button>
                <Button size="sm" width="full" colorScheme="default" onClick={addPlan}>
                  Adicionar
                </Button>
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Box width="full" display="flex" justifyContent="flex-end">
          <Button
            size="sm"
            colorScheme="default"
            type="submit"
            onClick={handleSubmit}
            disabled={!hasChanged}
            isLoading={isLoading}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
