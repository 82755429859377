import {
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumb as ChakraBreadcrumb,
  Text,
} from '@chakra-ui/react';

import { useParams } from 'react-router-dom';
import { IParams } from '../../pages/ContentsView/types';

interface IBreadcrumbProps {
  title: string;
  hasActiveModulesPage: boolean;
}

export function Breadcrumb({ title, hasActiveModulesPage }: IBreadcrumbProps) {
  const { courseId } = useParams<IParams>();

  return (
    <ChakraBreadcrumb width="250px" separator=">" spacing="4px" fontSize="14px" color="#FFF">
      <BreadcrumbItem width="full">
        <BreadcrumbLink href="/members-area/v2">
          <Text noOfLines={1}>Meus produtos</Text>
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem width="full" isTruncated>
        <BreadcrumbLink
          href={hasActiveModulesPage ? `/members-area/v2/course/${courseId}/modules` : '#'}
        >
          <Text>{title}</Text>
        </BreadcrumbLink>
      </BreadcrumbItem>
    </ChakraBreadcrumb>
  );
}
