import { Modal } from 'react-bootstrap';
import { BsChevronDown } from 'react-icons/bs';
import { FaExclamationCircle } from 'react-icons/fa';
import { IS_REACT_APP_HOSTNAME } from '../../../../../App';
import NoData from '../../NoData';
import TransactionItem from './TransactionItem';

import { SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  Heading,
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Table,
  TableContainer,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ChangeEvent, useMemo, useState } from 'react';
import { MdOutlineReplay, MdOutlineShoppingCart } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import { ITransaction } from '..';
import AdminAPI from '../../../../../api/Admin';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import hasFilterParams from '../../../../../utils/hasFilterParams';
import DateRangePicker from '../../../../../components/DateRangePicker';
import DateRangePickerMobile from '../../../../../components/DateRangePickerMobile';

const TH_STYLE = {
  backgroundColor: 'white',
  color: '#202123BF',
  fontSize: '12px',
  fontWeight: '700',
  padding: '0 2px',
};

export default function TableList({
  status,
  search,
  setSearch,
  transactions,
  onVerifyAllTransactionStatus,
  loading,
  isLoadingVerify,
  fetchData,
  toast,
  paymentMethod,
  hostname,
  startDate,
  endDate,
  checkoutType,
}) {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [transactionTemp, setTransactionTemp] = useState({} as ITransaction);
  const [isShowModal, setIsShowModal] = useState(false);
  const [disable, setDisable] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const isFiltering = hasFilterParams(urlSearchParams);
  const isNotFoundVisible = !loading && !transactions?.length && isFiltering;
  const isEmpty = !loading && !transactions?.length && !isFiltering;
  function onChangeFilter(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    urlSearchParams.set('page', '1');

    setSearch(value);
  }

  function onChangeFilterPurchaseStatus(status: string[]) {
    const hasFilter = status.join(',');

    if (!hasFilter) {
      urlSearchParams.delete('status');
      urlSearchParams.set('page', '1');
      return history.push({ search: urlSearchParams.toString() });
    }

    urlSearchParams.set('status', status.toString());
    urlSearchParams.set('page', '1');
    history.push({ search: urlSearchParams.toString() });
  }

  function onChangeFilterPaymentMethod(paymentsType: string[]) {
    const hasFilter = paymentsType.join(',');

    if (!hasFilter) {
      urlSearchParams.delete('paymentMethod');
      urlSearchParams.set('page', '1');
      return history.push({ search: urlSearchParams.toString() });
    }

    urlSearchParams.set('paymentMethod', paymentsType.toString());
    urlSearchParams.set('page', '1');
    history.push({ search: urlSearchParams.toString() });
  }

  function onChangeFilterCheckoutType(checkoutType: string[]) {
    const hasFilter = checkoutType.join(',');

    if (!hasFilter) {
      urlSearchParams.delete('checkoutType');
      urlSearchParams.set('page', '1');
      return history.push({ search: urlSearchParams.toString() });
    }

    urlSearchParams.set('checkoutType', checkoutType.toString());
    urlSearchParams.set('page', '1');
    history.push({ search: urlSearchParams.toString() });
  }

  function modalReleaseAccess(id: number) {
    setIsShowModal(true);

    const findTransactionId = transactions.find(transaction => transaction.id === id);

    if (findTransactionId) {
      setTransactionTemp(findTransactionId);
    }
  }

  async function handleReleaseAccess() {
    try {
      setDisable(true);

      await AdminAPI.releaseAccess(transactionTemp.id);

      fetchData();

      setDisable(false);
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } finally {
      setIsShowModal(false);
    }
  }

  function handleRangeChange(startDate: string, endDate: string) {
    urlSearchParams.set('startDate', dayjs(startDate).format('YYYY-MM-DD'));
    urlSearchParams.set('endDate', dayjs(endDate).format('YYYY-MM-DD'));
    history.push({ search: urlSearchParams.toString() });
  }

  return (
    <>
      <Stack direction={{ base: 'column', md: 'row', lg: 'row' }} mb="30px" alignItems="center">
        <InputGroup size="sm" width={{ base: 'full', md: 'full' }}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            value={search}
            focusBorderColor="primary.500"
            onChange={onChangeFilter}
            borderRadius={6}
            placeholder="Buscar"
            // placeholder="Pesquise por comprador, CPF, e-mail ou produto"
          />
        </InputGroup>

        {!isMobile && (
          <DateRangePicker
            startDate={dayjs(startDate).format('YYYY-MM-DD')}
            endDate={dayjs(endDate).format('YYYY-MM-DD')}
            onChange={handleRangeChange}
            hideSelect
          />
        )}

        {isMobile && <DateRangePickerMobile onChange={handleRangeChange} />}

        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            bg="transparent"
            size="sm"
            minW={{ base: 'full', md: '150px' }}
            border="1px solid #20212330"
          >
            <HStack
              justifyContent="space-between"
              color="#20212380"
              fontWeight="500"
              fontSize="14px"
            >
              <Text>Transações</Text>
              <BsChevronDown />
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuOptionGroup
              type="checkbox"
              onChange={onChangeFilterCheckoutType}
              value={checkoutType}
            >
              <MenuItemOption value="PRODUCTS">Produtos</MenuItemOption>
              <MenuItemOption value="RECURRENCE">Planos</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>

        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            bg="transparent"
            size="sm"
            minW={{ base: 'full', md: '150px' }}
            border="1px solid #20212330"
          >
            <HStack
              justifyContent="space-between"
              color="#20212380"
              fontWeight="500"
              fontSize="14px"
            >
              <Text>Status</Text>
              <BsChevronDown />
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuOptionGroup type="checkbox" onChange={onChangeFilterPurchaseStatus} value={status}>
              <MenuItemOption value="paid">Pagas</MenuItemOption>
              <MenuItemOption value="waiting_payment">Aguardando Pagamento</MenuItemOption>
              <MenuItemOption value="refused">Recusadas</MenuItemOption>
              <MenuItemOption value="refunded">Estornadas</MenuItemOption>
              <MenuItemOption value="chargedback">Chargedback</MenuItemOption>
              <MenuItemOption value="canceled">Canceladas</MenuItemOption>
              <MenuItemOption value="pending_refund">Estorno Pendente</MenuItemOption>
              <MenuItemOption value="manual">Inserido</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>

        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            bg="transparent"
            size="sm"
            minW={{ base: 'full', md: '150px' }}
            border="1px solid #20212330"
          >
            <HStack
              justifyContent="space-between"
              color="#20212380"
              fontWeight="500"
              fontSize="14px"
            >
              <Text>Pagamentos</Text>
              <BsChevronDown />
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuOptionGroup
              type="checkbox"
              onChange={onChangeFilterPaymentMethod}
              value={paymentMethod}
            >
              <MenuItemOption value="credit_card">Cartão de Crédito</MenuItemOption>
              <MenuItemOption value="boleto">Boleto</MenuItemOption>
              <MenuItemOption value="pix">Pix</MenuItemOption>
              <MenuItemOption value="free">Grátis</MenuItemOption>
              <MenuItemOption value="external">Externo</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>

        <Tooltip
          label="Verificar todos os status de compras por assinatura dessa página"
          placement="left"
          isDisabled={!transactions?.length}
          fontSize="xs"
        >
          <Button
            variant="outline"
            colorScheme="primary"
            size="sm"
            minWidth={{ base: 'full', md: '50px' }}
            onClick={onVerifyAllTransactionStatus}
            hidden={!transactions?.length}
            isDisabled={isLoadingVerify}
            isLoading={isLoadingVerify}
          >
            <MdOutlineReplay width="11px" height="9px" color="primary.500" />
          </Button>
        </Tooltip>
      </Stack>

      <HStack mb="12px" fontSize="14px" color="#20212380">
        <HStack>
          <MdOutlineShoppingCart size="22px" color="#202123" />
          <Text>Transação de produtos</Text>
        </HStack>
        <HStack>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M6.73377 12.2338L5.50001 11L2 14.5L5.50001 18L6.73377 16.7663L5.35126 15.375H18.6251V13.625H5.35126L6.73377 12.2338Z"
              fill="#202123"
            />
            <path
              d="M14.7673 9.76627L16.001 11L19.501 7.50001L16.001 4L14.7673 5.23375L16.1498 6.62501H2.87598V8.37502H16.1498L14.7673 9.76627Z"
              fill="#202123"
            />
          </svg>
          <Text>Transação de planos</Text>
        </HStack>
      </HStack>

      <TableContainer overflowX="auto" overflowY="hidden" borderRadius="5px">
        <Table
          colorScheme="gray"
          size="sm"
          variant="striped"
          border="1px solid rgba(32, 33, 35, 0.05)"
        >
          <Thead height="50px">
            <Tr>
              <Th></Th>
              <Th sx={TH_STYLE}>Transação</Th>
              <Th sx={TH_STYLE}>Item/Plano</Th>
              <Th sx={TH_STYLE}>Data</Th>
              <Th sx={TH_STYLE}>Cliente/CPF</Th>
              <Th sx={TH_STYLE}>E-mail</Th>
              <Th sx={TH_STYLE}>PGT.</Th>
              <Th sx={TH_STYLE}>Status</Th>
              <Th sx={TH_STYLE}>Valor</Th>
              {IS_REACT_APP_HOSTNAME(hostname) && <Th sx={TH_STYLE}>Ação</Th>}
            </Tr>
          </Thead>

          <TransactionItem modalReleaseAccess={modalReleaseAccess} transactions={transactions} />
        </Table>
      </TableContainer>

      {isEmpty && <NoData />}

      {isNotFoundVisible && (
        <HStack width="full" justifyContent="center" padding="30px">
          <Heading size="sm" fontWeight="400" color="#20212380">
            Nenhuma transação foi encontrada.
          </Heading>
        </HStack>
      )}

      <Modal
        centered
        size="sm"
        show={isShowModal}
        onHide={() => setIsShowModal(false)}
        style={{
          backdropFilter: 'blur(4px)',
        }}
      >
        <Modal.Body>
          <div className="row d-flex flex-column align-items-center justify-content-center mb-1 px-2">
            <FaExclamationCircle size="4rem" color="primary.500" />
            <h3 className="text-center mt-2">Liberar acesso ao produto?</h3>
            <p className="text-center mt-2">
              Deseja liberar o acesso do produto{' '}
              <b style={{ fontWeight: 600 }}>{transactionTemp?.products}</b> antes da confirmação do
              pagamento?
            </p>
          </div>
          <div className="row d-flex mb-1 mt-4 px-2" style={{ gap: '1rem' }}>
            <button
              onClick={() => setIsShowModal(false)}
              disabled={disable}
              className="btn btn-primary col d-flex justify-content-center"
            >
              Cancelar
            </button>
            <button
              onClick={handleReleaseAccess}
              disabled={disable}
              className="btn btn-outline-danger col d-flex justify-content-center"
              style={{
                backgroundColor: 'transparent',
              }}
            >
              Sim
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
