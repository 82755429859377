export default function useExternalLinkHeader() {
  const hostname = window.location.hostname;

  let externalLink = null;

  const platformUrls = ['questoes.qblitz.com.br'];

  if (platformUrls.includes(hostname)) {
    externalLink = 'https://qblitz.com.br';
  }

  return externalLink;
}
