import { Box, Center, Heading, Spinner, Text } from '@chakra-ui/react';
import Paginate from '../../../components/Paginate';
import SearchInput from './components/SearchInput';
import UtmGeneratorHeader from './components/UtmGeneratorHeader';
import UtmTable from './components/UtmTable';
import { useUtmGenerator } from './useUtmGenerator';
import UtmTableRow from './components/UtmTable/UtmTableRow';
import MobileUtmList from './components/MobileUtmList';

export default function UtmGenerator() {
  const {
    utmList,
    urlSearchParams,
    isNotFoundVisible,
    isLoading,
    isPaginationVisible,
    isUtmListVisible,
    isEmpty,
    currentPage,
    pageCount,
    handleSearchChange,
    handlePageChange,
    handleDelete,
    handleExportInformation,
  } = useUtmGenerator();

  return (
    <Box>
      <UtmGeneratorHeader />

      {!isEmpty && (
        <SearchInput
          value={urlSearchParams.get('search')}
          onSearch={handleSearchChange}
          mt="1.875rem"
          placeholder="Buscar pelo UTM"
        />
      )}

      {isLoading && (
        <Center my={20}>
          <Spinner size="xl" color="primary.500" />
        </Center>
      )}

      {isNotFoundVisible && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.300">
            Nenhum resultado encontrado...
          </Heading>
        </Center>
      )}

      {isEmpty && (
        <Text color="#20212380" fontSize="lg" mt={{ base: 8, xl: 7 }} lineHeight="normal">
          Você não possui nenhum UTM gerado. Para gerar seu primeiro UTM clique no botão “Gerar
          UTM”.
        </Text>
      )}

      {isUtmListVisible && (
        <>
          <UtmTable display={{ base: 'none', xl: 'block' }}>
            {utmList.map(utmItem => (
              <UtmTableRow
                key={utmItem.id}
                utmItem={utmItem}
                onDelete={handleDelete}
                onExportInformation={handleExportInformation}
              />
            ))}
          </UtmTable>

          <MobileUtmList
            display={{ base: 'block', xl: 'none' }}
            mt={2}
            utmList={utmList}
            onDelete={handleDelete}
            onExportInformation={handleExportInformation}
          />
        </>
      )}

      {isPaginationVisible && (
        <Paginate
          pageCount={pageCount}
          initialPage={currentPage}
          onPageChange={handlePageChange}
          mt={{ base: 8, xl: 4 }}
        />
      )}
    </Box>
  );
}
