import { useDisclosure, useToast } from '@chakra-ui/react';
import { useState, useMemo, useCallback, ChangeEvent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import useHandleSubmit from '../../../hooks/useHandleSubmit';
import hasFilterParams from '../../../utils/hasFilterParams';
import { CheckoutSettings } from './types';

const CHECKOUT_SETTINGS_PAGE_PATH = '/checkout-settings';

interface CheckoutSettingsResponse {
  courseCheckoutSettingsIndexResult: CheckoutSettings[];
  page: number;
  per_page: number;
  total: number;
}

export default function useCheckoutSettings() {
  const [productToEdit, setProductToEdit] = useState(null);

  const [checkoutTypeToUpdate, setCheckoutTypeToUpdate] =
    useState<CheckoutSettings['checkoutType']>('DEFAULT');

  const location = useLocation();
  const history = useHistory();
  const toast = useToast();

  const {
    isOpen: isChangeTypeModalOpen,
    onOpen: onOpenChangeTypeModal,
    onClose: onCloseChangeTypeModal,
  } = useDisclosure();

  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const { data: response, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<CheckoutSettingsResponse>
  >({
    method: 'get',
    url: `/course-checkout-settings?${urlSearchParams.toString()}`,
    authenticated: true,
    autoFetch: true,
  });

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    data: { typeCheckout: checkoutTypeToUpdate },
    url: `/course-checkout-settings/${productToEdit}/type-checkout`,
    method: 'patch',
    authenticated: true,
    onSuccess: {
      message: 'Tipo de checkout alterado com sucesso!',
      callback: () => {
        onCloseChangeTypeModal();

        if (checkoutTypeToUpdate === 'DEFAULT') {
          return history.push(location.pathname);
        }

        history.push(`/checkout-settings/edit/${productToEdit}/payment`);
      },
    },
  });

  async function handleCheckoutTypeChangeConfirm() {
    await handleSubmit();
  }

  const handleSearchChange = useCallback(
    (search: string) => {
      if (!search) return history.push(CHECKOUT_SETTINGS_PAGE_PATH);

      urlSearchParams.set('page', '1');
      urlSearchParams.set('search', search);

      return history.push({
        pathname: CHECKOUT_SETTINGS_PAGE_PATH,
        search: urlSearchParams.toString(),
      });
    },

    [history, urlSearchParams]
  );

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    history.push({ search: urlSearchParams.toString() });
  }

  function handleTypeChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    urlSearchParams.set('page', '1');

    if (!value) {
      urlSearchParams.delete('checkoutTypeFilter');
      return history.push({ search: urlSearchParams.toString() });
    }

    urlSearchParams.set('checkoutTypeFilter', value);
    history.push({ search: urlSearchParams.toString() });
  }

  function handleGetCheckoutLink(
    productId: number,
    checkoutType: CheckoutSettings['checkoutType']
  ) {
    const location = window.location;

    if (checkoutType === 'DEFAULT') {
      return `${location.origin}/student-checkout/v3?productId=${productId}`;
    }

    return `${location.origin}/launch-checkout/${productId}/personal-data`;
  }

  function handleCheckoutLinkCopy(_, result: boolean) {
    if (result) {
      toast({ title: 'Link copiado com sucesso!', position: 'top', status: 'success' });
    }
  }

  function handleProductToChangeCheckoutTypeClick(productId: number) {
    setProductToEdit(productId);
    onOpenChangeTypeModal();
  }

  function handleCheckoutTypeToUpdateChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    setCheckoutTypeToUpdate(value as CheckoutSettings['checkoutType']);
  }

  const checkoutSettingsList = response?.data?.courseCheckoutSettingsIndexResult;
  const isFiltering = hasFilterParams(urlSearchParams);
  const isNotFoundVisible = !isLoading && !checkoutSettingsList?.length && isFiltering;
  const isEmpty = !isLoading && !checkoutSettingsList?.length && !isFiltering;
  const total = response?.data?.total;
  const perPage = response?.data?.per_page || 1;
  const pageCount = Math.ceil(total / perPage);
  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const isPaginationVisible = !isLoading && pageCount > 1;
  const isCheckoutSettingsListVisible = !isLoading && !!checkoutSettingsList?.length;
  const searchQuery = urlSearchParams.get('search');
  const checkoutTypeFilterQuery = urlSearchParams.get('checkoutTypeFilter');

  return {
    isChangeTypeModalOpen,
    isSubmitting,
    isNotFoundVisible,
    isEmpty,
    currentPage,
    isPaginationVisible,
    isCheckoutSettingsListVisible,
    isLoading,
    checkoutSettingsList,
    pageCount,
    checkoutTypeToUpdate,
    searchQuery,
    checkoutTypeFilterQuery,
    handleCheckoutTypeChangeConfirm,
    handleSearchChange,
    handlePageChange,
    handleTypeChange,
    handleGetCheckoutLink,
    handleCheckoutLinkCopy,
    handleProductToChangeCheckoutTypeClick,
    handleCheckoutTypeToUpdateChange,
    onCloseChangeTypeModal,
  };
}
