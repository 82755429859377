import { Icon, IconProps } from '@chakra-ui/react';

export default function DownArrowIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 7 10" {...props}>
      <path
        d="M2.91667 3.47809e-08L2.91667 7.87222L0.8225 5.88333L3.97496e-08 6.66667L3.5 10L7 6.66667L6.1775 5.88333L4.08333 7.87222L4.08333 4.86933e-08L2.91667 3.47809e-08Z"
        fill="currentColor"
      />
    </Icon>
  );
}
