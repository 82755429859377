import { UseToastOptions } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import ErrorResponse from '../helpers/ErrorResponse';

export type ResponseJSON<T = any> = {
  statusCode: number;
  message: string;
  data?: T;
  error?: string;
};

type ToastAlertType<T = unknown> = {
  title?: string;
  status?: 'info' | 'success' | 'error' | 'warning';
  error?: T;
};

const CreateToastAxiosError = (error: AxiosError<ResponseJSON>) => {
  const toastProps: UseToastOptions = {
    title: 'Erro inesperado.',
    status: 'error',
    position: 'bottom-right',
  };

  const response = error?.response;

  if (response && response?.data) {
    const status = response.status;

    toastProps.title = ErrorResponse(error);

    if (status >= 400 && status <= 499) {
      toastProps.status = 'warning';
    } else if (status >= 500) {
      toastProps.status = 'error';
    }
  } else if (error.code === 'ERR_NETWORK') {
    toastProps.status = 'error';
    toastProps.title = 'Sem conexão com o servidor';
  }

  if (!toastProps.title) {
    toastProps.title = 'Erro inesperado, tende novamente mais tarde.';
    toastProps.status = 'error';
  }

  return toastProps;
};

const CreateToastDefaultError = (props: ToastAlertType) => {
  const toastProps: UseToastOptions = {
    title: 'Informe uma mensagem',
    status: 'warning',
    position: 'bottom-right',
  };

  if (props.status) {
    toastProps.status = props.status;
  }

  if (props.title) {
    toastProps.title = props.title;
  }

  return toastProps;
};

export default function CreateToastAlert(props: ToastAlertType) {
  if ((props?.error as AxiosError)?.isAxiosError) {
    return CreateToastAxiosError(props?.error as AxiosError);
  }

  return CreateToastDefaultError({
    title: props.title,
    status: props.status,
  });
}
