import { FiAlertCircle as AlertIcon } from 'react-icons/fi';
import { Modal as BootStrapModal } from 'react-bootstrap';
import styles from './styles.module.css';
import QRCode from '../../../../SubscriptionCheckoutStudent/pages/PaymentMethod/Pix/components/QRCode';
import CopyButton from '../../../../SubscriptionCheckoutStudent/pages/PaymentMethod/Pix/components/CopyButton';

function Modal({ isShow, onHide, pixCode }) {
  return (
    <BootStrapModal
      show={isShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-BootStrapModal-title-vcenter"
      centered
    >
      <div className={styles.container}>
        <BootStrapModal.Header closeButton>
          <BootStrapModal.Title id="contained-BootStrapModal-title-vcenter">
            Seu código PIX
          </BootStrapModal.Title>
        </BootStrapModal.Header>

        <BootStrapModal.Body>
          <div className={styles.pixContainer}>
            <div>
              <div className={styles.QRCodeWrapper}>
                <QRCode value={pixCode} />

                <CopyButton textToCopy={pixCode} isPurchaseByPixConfirmed={false} />
              </div>

              <div className={styles.informationsWrapper}>
                <strong>Siga os passos abaixo, para realizar o pagamento:</strong>

                <ol>
                  <li>Abra o aplicativo do seu banco no celular.</li>
                  <li>
                    Selecione a opção "Ler QR code" e aponte a câmera do celular para o QR Code.
                  </li>
                  <li>
                    Você também pode clicar em "Copiar código" e Selecione a opção "Pix Copia e
                    Cola" no aplicativo do seu banco.
                  </li>
                </ol>

                <div className={styles.observations}>
                  <div>
                    <AlertIcon />
                    <span>Observação</span>
                  </div>

                  <p>
                    Você obterá acesso ao produto <span>imediatamente</span> após o pagamento.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </BootStrapModal.Body>
      </div>
    </BootStrapModal>
  );
}

export default Modal;
