import {
  Box,
  Button as ChakraButton,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, MouseEvent, Suspense, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthenticationAPI from '../../../../api/Authentication';
import UserAPI from '../../../../api/User';
import Button from '../../../../components/Button';
import CountrySelect, { getDDI } from '../../../../components/CountrySelect';
import { Heading } from '../../../../components/Heading';
import Toast from '../../../../components/Toast';
import DDIHelper from '../../../../helpers/DDIHelper';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { TOKEN_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import { onlyNumber } from '../../../../hooks/useHandleChange';
import { ISignProps } from '../../types';
import { schemaSignUp } from './signUpSchema';

interface IForm {
  fullName: string;
  email: string;
  password: string;
  ddi: string;
  ddd: string;
  phone: string;
  country: string;
  isTermsAccepted?: boolean;
}

const initialFormValues: IForm = {
  fullName: '',
  email: '',
  password: '',
  ddi: '',
  ddd: '',
  phone: '',
  country: 'br',
  isTermsAccepted: false,
};

function SignUp({ productId, courseSubscriptionPlan, verifyAuthentication }: ISignProps) {
  const history = useHistory();

  const [form, setForm] = useState<IForm>(initialFormValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ddi, setDDI] = useState('55');
  const [country, setCountry] = useState('br');

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

      const authentication = await AuthenticationAPI.validateToken(token);

      if (authentication) {
        history.push(
          `/product/${productId}/subscription-checkout/personal-data${
            courseSubscriptionPlan ? `?courseSubscriptionPlan=${courseSubscriptionPlan}` : ''
          }`
        );
      }
    })();
  }, [courseSubscriptionPlan, history, productId]);

  function getDDIByInitials(initials: string) {
    return DDIHelper.find(
      ({ initials: countryInitials }) => countryInitials.toLowerCase() === initials.toLowerCase()
    )?.ddi;
  }

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    if (name === 'isTermsAccepted') {
      return setForm(prevState => ({ ...prevState, [name]: !prevState.isTermsAccepted }));
    }

    if (name === 'country') {
      const ddi = getDDIByInitials(value);
      setDDI(ddi);
    }

    setForm(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleCountryChange = useCallback((country: string) => {
    if (country !== 'br') {
      setForm(prevState => ({ ...prevState, ddd: null }));
    }

    if (country === 'br') {
      setForm(prevState => ({ ...prevState, ddd: '' }));
    }

    setDDI(getDDI(country));
    setCountry(country);
  }, []);

  async function handleSubmit(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    try {
      setIsLoading(true);

      const formValidate = {
        fullName: form.fullName,
        email: form.email,
        password: form.password,
        ddd: form.ddd,
        phone: form.phone,
        country,
        ddi,
        isTermsAccepted: form.isTermsAccepted,
      };

      if (formValidate.ddi !== '55') {
        delete formValidate.ddd;
      }

      const validateValues = schemaSignUp.validate(formValidate);

      if (validateValues.error) throw validateValues.error;

      await UserAPI.createUserByCheckoutV2(formValidate);
      verifyAuthentication();

      history.push(
        `/product/${productId}/subscription-checkout/personal-data${
          courseSubscriptionPlan ? `?courseSubscriptionPlan=${courseSubscriptionPlan}` : ''
        }`
      );
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box as="section" display="flex" flexDirection="column" alignItems="center">
      <Heading as="h2" fontSize="lg" marginBottom="4">
        Cadastro
      </Heading>

      <VStack w="100%" align="start" spacing={8}>
        <VStack w="100%" align="start" spacing={4}>
          <VStack w="100%" align="start">
            <Text fontWeight="500">
              Nome{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </Text>
            <Input
              borderColor="blackAlpha.400"
              id="fullName"
              type="text"
              name="fullName"
              placeholder="Digite seu nome completo"
              onChange={handleChange}
              value={form.fullName}
              focusBorderColor="orange.400"
              isRequired
            />
          </VStack>

          <VStack w="100%" align="start">
            <Text fontWeight="500">
              E-mail{' '}
              <Text as="span" color="red.500">
                *
              </Text>
            </Text>
            <Input
              borderColor="blackAlpha.400"
              id="email"
              type="email"
              name="email"
              placeholder="Digite seu e-mail"
              onChange={handleChange}
              value={form.email}
              focusBorderColor="orange.400"
              isRequired
            />
          </VStack>

          <FormControl>
            <FormLabel htmlFor="country">País</FormLabel>
            <Suspense fallback={<Skeleton w="full" h={10} />}>
              <CountrySelect value={country} onChange={handleCountryChange} />
            </Suspense>
          </FormControl>

          <HStack width="full">
            <FormControl hidden={ddi !== '55'} width="100px">
              <FormLabel>DDD</FormLabel>
              <Input
                type="tel"
                name="ddd"
                borderColor="blackAlpha.400"
                onChange={handleChange}
                value={form.ddd}
                placeholder="DDD"
                focusBorderColor="orange.400"
                _placeholder={{ color: 'secondary.600' }}
                maxLength={2}
                onInput={onlyNumber}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Telefone</FormLabel>
              <Input
                type="tel"
                borderColor="blackAlpha.400"
                name="phone"
                onChange={handleChange}
                value={form.phone}
                placeholder="Digite seu telefone"
                focusBorderColor="orange.400"
                _placeholder={{ color: 'secondary.600' }}
                maxLength={20}
                onInput={onlyNumber}
              />
            </FormControl>
          </HStack>
        </VStack>

        <VStack w="100%" align="start">
          <Text fontWeight="500">
            Senha{' '}
            <Text as="span" color="red.500">
              *
            </Text>
          </Text>
          <Input
            borderColor="blackAlpha.400"
            id="password"
            type="password"
            name="password"
            placeholder="Digite sua senha"
            onChange={handleChange}
            value={form.password}
            focusBorderColor="orange.400"
            autoComplete="current-password"
            isRequired
          />
        </VStack>

        <VStack w="100%" align="start">
          <HStack justify="center" w="100%">
            <Checkbox
              name="isTermsAccepted"
              id="isTermsAccepted"
              onChange={handleChange}
              checked={form.isTermsAccepted}
              colorScheme="orange"
              borderColor="gray.300"
            />

            <HStack spacing={1}>
              <FormLabel fontSize="sm" color="#20212380 !important" htmlFor="isTermsAccepted" m={0}>
                Li e aceito os
              </FormLabel>
              <Link
                href="https://checkout.proluno.me/privacy-policy"
                isExternal
                textDecoration="underline"
                fontWeight="semibold"
                color="default.500"
              >
                termos de uso
              </Link>
            </HStack>
          </HStack>

          <VStack alignItems="center" w="100%" justifyContent="center">
            <Button
              style={{ outline: 'none', boxShadow: 'none' }}
              colorScheme="orange"
              marginBottom="3"
              onClick={handleSubmit}
              isDisabled={isLoading}
              isLoading={isLoading}
              width="180px"
            >
              Realizar cadastro
            </Button>
            <Text fontSize="sm" fontWeight="medium" color="dark.500">
              Já possui cadastro?
            </Text>
            <ChakraButton
              style={{ outline: 'none', boxShadow: 'none' }}
              marginLeft="1"
              fontSize="sm"
              fontWeight="medium"
              fontFamily="Montserrat"
              colorScheme="orange"
              variant="link"
              textDecoration="underline"
              onClick={() =>
                history.push(
                  `/product/${productId}/subscription-checkout/sign-in${
                    courseSubscriptionPlan
                      ? `?courseSubscriptionPlan=${courseSubscriptionPlan}`
                      : ''
                  }`
                )
              }
            >
              Fazer Login
            </ChakraButton>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
}

export default SignUp;
