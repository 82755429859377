import {
  Box,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { HiDotsHorizontal } from 'react-icons/hi';
import { MdDragIndicator, MdOutlineImageNotSupported } from 'react-icons/md';
import { IModule, IModulePageResponse } from '../..';
import { axiosBackend } from '../../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import reorder from '../../../../../Courses/CourseManager/ModulesManager/utils/reorder';
import { getStatusColorByName } from '../../../Showcases/Showcase';
import { AddBannerModuleModal } from './AddBannerModuleModal';
import EditModuleModal from './EditModuleModal';
import { RemoveBannerModuleModal } from './RemoveBannerModuleModal';

interface IProps {
  modules: IModule[];
  showcaseId: string;
  showcaseCourseId: string;
  showcaseModules: IModulePageResponse;
  setShowcaseModules: (modules: IModulePageResponse) => void;
  productId: number;
}

export default function Module({
  modules,
  showcaseId,
  showcaseCourseId,
  showcaseModules,
  setShowcaseModules,
  productId,
}: IProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();

  const {
    isOpen: isOpenEditModule,
    onOpen: onOpenEditModule,
    onClose: onCloseEditModule,
  } = useDisclosure();

  const {
    isOpen: isOpenAddBannerModule,
    onOpen: onOpenAddBannerModule,
    onClose: onCloseAddBannerModule,
  } = useDisclosure();

  const {
    isOpen: isOpenRemoveBannerModule,
    onOpen: onOpenRemoveBannerModule,
    onClose: onCloseRemoveBannerModule,
  } = useDisclosure();

  const [moduleSelect, setModuleSelect] = useState({ id: 0, name: '', status: '' });

  async function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const reorderModules = reorder(modules, result.source.index, result.destination.index);

    const modulesOrders = reorderModules.map(module => {
      return { moduleId: module.id, order: module.order };
    });

    const newModulesOrder = { modulesOrders: modulesOrders };

    try {
      await axiosBackend().patch(`/modules/${productId}/course/order-modules`, newModulesOrder);

      toast({
        title: 'Ordem dos módulos atualizada com sucesso!',
        status: 'success',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });

      setShowcaseModules({ ...showcaseModules, modules: reorderModules });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    }
  }

  async function editModule(module) {
    setModuleSelect({
      id: module.id,
      name: module.name,
      status: module.status,
    });

    onOpenEditModule();
  }

  async function addBanner(module) {
    setModuleSelect({
      id: module.id,
      name: module.name,
      status: module.status,
    });

    onOpenAddBannerModule();
  }

  async function removeBanner(module) {
    setModuleSelect({
      id: module.id,
      name: module.name,
      status: module.status,
    });

    onOpenRemoveBannerModule();
  }

  async function handleStatusChange(
    event,
    showcaseId: string,
    showcaseCourseId: string,
    moduleId: number
  ) {
    const { value } = event.target;

    const modulesUpdate = modules.find(module => module.id === moduleId);

    if (!modulesUpdate) return;

    const modulesUpdated = {
      name: modulesUpdate.name,
      status: value,
    };

    try {
      await axiosBackend().patch(
        `/showcase-course/${showcaseId}/showcase/${showcaseCourseId}/showcase-course/${moduleId}/module`,
        modulesUpdated
      );

      modulesUpdate.status = value;

      setShowcaseModules({ ...showcaseModules, modules: [...modules] });

      toast({
        title: 'Status atualizado com sucesso!',
        status: 'success',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        position: 'bottom-right',
        isClosable: true,
      });
    }
  }

  function SelectStatus({
    module,
    showcaseId,
    showcaseCourseId,
  }: {
    module: IModule;
    showcaseId: string;
    showcaseCourseId: string;
  }) {
    return (
      <Select
        fontSize="14px"
        fontWeight={400}
        focusBorderColor="transparent"
        border="none"
        width="115px"
        size="xs"
        ml={-2}
        color={getStatusColorByName(module.status)}
        value={module.status}
        onChange={event => handleStatusChange(event, showcaseId, showcaseCourseId, module.id)}
      >
        <option value="ATIVO">Publicado</option>
        <option value="EM_EDICAO">Rascunho</option>
      </Select>
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="modules">
          {provided => (
            <Box
              hidden={!modules?.length}
              {...provided.droppableProps}
              ref={provided.innerRef}
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            >
              {modules?.map((module, index) => {
                const bgColor = index % 2 === 0 ? '#20212310' : 'auto';
                return (
                  <Draggable
                    key={String(module.id)}
                    draggableId={String(module.id)}
                    index={index}
                    isDragDisabled={modules?.length < 2}
                  >
                    {provided => (
                      <Box
                        padding={{ base: '20px 12px 20px 12px', lg: '24px 30px 24px 30px' }}
                        height="67px"
                        display="flex"
                        bgColor={bgColor}
                        alignItems="center"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Box width="80px">
                          <MdDragIndicator
                            fontSize="24px"
                            color={modules?.length <= 1 ? 'transparent' : '#202123'}
                          />
                        </Box>
                        <Box width="150px" padding={0} margin={0}>
                          <Box
                            hidden={!!module.thumbnail}
                            width="49px"
                            height="62px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bgColor="#D9D9D9"
                          >
                            <MdOutlineImageNotSupported color="#757475" fontSize="20px" />
                          </Box>

                          {module.thumbnail && (
                            <Image width="49px" height="62px" alt="Banner" src={module.thumbnail} />
                          )}
                        </Box>
                        <Stack width="full" alignItems="flex-start" spacing={0} textAlign="start">
                          <Text width={{ base: '150px', lg: 'full' }} isTruncated fontSize="14px">
                            {module.name}
                          </Text>
                          <Box hidden={!isMobile}>
                            <SelectStatus
                              module={module}
                              showcaseId={showcaseId}
                              showcaseCourseId={showcaseCourseId}
                            />
                          </Box>
                        </Stack>
                        <Box width="200px" hidden={isMobile}>
                          <SelectStatus
                            module={module}
                            showcaseId={showcaseId}
                            showcaseCourseId={showcaseCourseId}
                          />
                        </Box>
                        <Box width={isMobile ? '80px' : '60px'}>
                          <Menu>
                            <MenuButton
                              w="27px"
                              h="24px"
                              borderRadius={5}
                              bgColor="#20212312"
                              _hover={{ bgColor: '#20212330' }}
                              _active={{ bgColor: '#20212330' }}
                            >
                              <Stack justifyContent="center" alignItems="center">
                                <HiDotsHorizontal size="18px" color="#00000080" />
                              </Stack>
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                color="#979CA6"
                                fontWeight={500}
                                onClick={() => {
                                  editModule(module);
                                }}
                              >
                                Editar módulo
                              </MenuItem>
                              <MenuItem
                                color="#979CA6"
                                fontWeight={500}
                                onClick={() => addBanner(module)}
                              >
                                Adicionar banner
                              </MenuItem>
                              <MenuItem
                                hidden={!module.thumbnail}
                                color="#BB2124"
                                fontWeight={500}
                                onClick={() => removeBanner(module)}
                              >
                                Remover banner
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Box>
                      </Box>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      <EditModuleModal
        isOpen={isOpenEditModule}
        onClose={onCloseEditModule}
        showcaseId={showcaseId}
        showcaseCourseId={showcaseCourseId}
        moduleSelect={moduleSelect}
        modules={modules}
        showcaseModules={showcaseModules}
        setShowcaseModules={setShowcaseModules}
      />

      <AddBannerModuleModal
        isOpen={isOpenAddBannerModule}
        onClose={onCloseAddBannerModule}
        showcaseId={showcaseId}
        showcaseCourseId={showcaseCourseId}
        showcaseModules={showcaseModules}
        modules={modules}
        setShowcaseModules={setShowcaseModules}
        moduleSelect={moduleSelect}
      />

      <RemoveBannerModuleModal
        isOpen={isOpenRemoveBannerModule}
        onClose={onCloseRemoveBannerModule}
        showcaseId={showcaseId}
        showcaseCourseId={showcaseCourseId}
        showcaseModules={showcaseModules}
        modules={modules}
        setShowcaseModules={setShowcaseModules}
        moduleSelect={moduleSelect}
      />
    </>
  );
}
