import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import CustomDropzone from '../../../../../../components/CustomDropzone';

import { FORM_OPTIONS } from './constants';
import DeleteImageModal from './DeleteImageModal';
import ImagePreview from './ImagePreview';
import ImageUploadLoading from './ImageUploadLoading';
import { usePlanManagementEditCustomizationProps } from './types';
import usePlanManagementEditCustomization from './usePlanManagementEditCustomization';

export default function PlanManagementEditCustomization({
  planData,
}: usePlanManagementEditCustomizationProps) {
  const {
    form,
    isSubmitting,
    formValidation,
    topImage,
    bottomImage,
    isUploading,
    isDeletingImage,
    isOpen,
    onClose,
    handleOpenImageDeleteModal,
    handleSelectNewImage,
    handleDeleteImage,
    handleChange,
    handleCancelButtonClick,
    handleDropRejected,
    handleDropFile,
    onSubmit,
  } = usePlanManagementEditCustomization({
    planData,
  });

  return (
    <Box mt={10}>
      <VStack
        as="form"
        align="start"
        mt="1.125rem"
        spacing={10}
        sx={{
          '.chakra-form__label': {
            fontSize: 'sm',
            color: '#20212380',
            fontWeight: 'normal',
          },
          '.chakra-form__helper-text': {
            color: '#20212380',
            mb: 2,
          },
          '.chakra-input, .chakra-input::placeholder': {
            color: '#0000005C',
          },
        }}
        noValidate
        onSubmit={onSubmit}
      >
        <Box as="section" w="full">
          <VStack align="start">
            <Heading fontSize="xl" fontWeight="medium" color="#202123">
              Opções de formulário
            </Heading>

            <FormControl as="fieldset" isInvalid={formValidation?.userFormForPurchase?.isInvalid}>
              <FormLabel as="legend">Formulário de compra</FormLabel>

              <CheckboxGroup
                value={form?.userFormForPurchase}
                onChange={value => handleChange('userFormForPurchase', value)}
              >
                <VStack spacing={2} align="start">
                  {FORM_OPTIONS.map(option => (
                    <Checkbox key={option.value} colorScheme="primary" value={option.value}>
                      {option.label}
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>

              <FormErrorMessage>{formValidation?.userFormForPurchase?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </Box>

        <Box as="section" w="full">
          <Heading fontSize="xl" fontWeight="medium" color="#202123">
            Imagem superior
          </Heading>

          <Text className="chakra-form__helper-text">
            Imagem localizada na parte de cima da página, antes do checkout.
          </Text>

          <Stack direction={{ base: 'column', xl: 'row' }} spacing={4}>
            <FormControl isInvalid={formValidation?.topImage?.isInvalid}>
              <Flex position="relative" height={{ base: '23.5625rem', xl: '16.75rem' }}>
                {!form.topImage ? (
                  <>
                    <CustomDropzone
                      file={topImage?.file}
                      isDisabled={isSubmitting}
                      accept={['image/jpg', 'image/png', 'image/jpeg', 'image/webp']}
                      dimensions="1010x440"
                      onDrop={acceptedFiles => handleDropFile('topImage', acceptedFiles)}
                      onDropRejected={handleDropRejected}
                      extensions="JPG, PNG, JPEG e WEBP"
                      maxFileSize="5MB"
                    />

                    <ImageUploadLoading position="absolute" isLoading={isUploading} />
                  </>
                ) : (
                  <VStack w="full">
                    <Box height={{ base: '21.5625rem', xl: '16.75rem' }} w="full">
                      <ImagePreview
                        imageField="topImage"
                        preview={topImage?.preview ?? form.topImage}
                        onSelectNewImage={handleSelectNewImage}
                        onOpenDeleteImageModal={handleOpenImageDeleteModal}
                      />
                    </Box>

                    <Button
                      display={{ base: 'flex', xl: 'none' }}
                      onClick={() => handleSelectNewImage('topImage')}
                      w="full"
                      variant="outline"
                      colorScheme="primary"
                      size="sm"
                      zIndex="2"
                    >
                      Trocar Imagem
                    </Button>
                  </VStack>
                )}
              </Flex>

              <FormErrorMessage>{formValidation?.topImage?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </Box>

        <Box as="section" w="full">
          <Heading fontSize="xl" fontWeight="medium" color="#202123">
            Imagem inferior
          </Heading>

          <Text className="chakra-form__helper-text">
            Imagem localizada na parte de baixo da página, após o checkout.
          </Text>

          <Stack direction={{ base: 'column', xl: 'row' }} spacing={4}>
            <FormControl isInvalid={formValidation?.topImage?.isInvalid}>
              <Flex position="relative" height={{ base: '23.5625rem', xl: '16.75rem' }}>
                {!form.bottomImage ? (
                  <>
                    <CustomDropzone
                      file={bottomImage?.file}
                      isDisabled={isSubmitting}
                      accept={['image/jpg', 'image/png', 'image/jpeg', 'image/webp']}
                      dimensions="1010x440"
                      onDrop={acceptedFiles => handleDropFile('bottomImage', acceptedFiles)}
                      onDropRejected={handleDropRejected}
                      extensions="JPG, PNG, JPEG e WEBP"
                      maxFileSize="5MB"
                    />

                    <ImageUploadLoading position="absolute" isLoading={isUploading} />
                  </>
                ) : (
                  <VStack w="full">
                    <Box height={{ base: '21.5625rem', xl: '16.75rem' }} w="full">
                      <ImagePreview
                        imageField="bottomImage"
                        preview={bottomImage?.preview ?? form.bottomImage}
                        onSelectNewImage={handleSelectNewImage}
                        onOpenDeleteImageModal={handleOpenImageDeleteModal}
                      />
                    </Box>

                    <Button
                      display={{ base: 'flex', xl: 'none' }}
                      onClick={() => handleSelectNewImage('bottomImage')}
                      w="full"
                      variant="outline"
                      colorScheme="primary"
                      size="sm"
                      zIndex="2"
                    >
                      Trocar Imagem
                    </Button>
                  </VStack>
                )}
              </Flex>

              <FormErrorMessage>{formValidation?.bottomImage?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </Box>

        <HStack as="footer" w="full" justify="end" spacing={1}>
          <Button
            onClick={handleCancelButtonClick}
            variant="ghost"
            mr={3}
            background="gray.200"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isDisabled={isSubmitting}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            colorScheme="primary"
            color="secondary.500"
            size={{ base: 'sm', xl: 'md' }}
            w={{ base: 'full', xl: 'initial' }}
            isLoading={isSubmitting}
          >
            Avançar
          </Button>
        </HStack>
      </VStack>

      <DeleteImageModal
        isOpen={isOpen}
        isLoading={isDeletingImage}
        onConfirm={handleDeleteImage}
        onClose={onClose}
      />
    </Box>
  );
}
