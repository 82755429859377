import { Box, Flex, Text } from '@chakra-ui/react';
import { Promotion } from '../../../types';
import { TimeLeftType } from '../usePreview';
import Square from './Square';

interface TimerProps {
  indicateStartOfPromotion: boolean;
  secondaryColor: Promotion['secondaryColor'];
  primaryColor: Promotion['primaryColor'];
  timeLeft: TimeLeftType;
}

export default function Timer({
  indicateStartOfPromotion,
  secondaryColor,
  primaryColor,
  timeLeft,
}: TimerProps) {
  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      justifyContent={{ base: 'center', xl: 'flex-end' }}
      align={{ base: 'end', xl: 'center' }}
      gap={{ base: 1.5, xl: 4 }}
      pb={6}
      flexShrink={0}
    >
      <Box textAlign={{ base: 'center', xl: 'end' }} w="full">
        <Text fontSize={{ base: 'sm', xl: 'md' }} fontWeight="normal" color={secondaryColor}>
          {indicateStartOfPromotion ? 'Começa em:' : 'Termina em:'}
        </Text>
      </Box>

      <Flex color="#2b2b2b" gap={{ base: 1, sm: 1.5 }} align="center">
        {!!timeLeft?.days && (
          <>
            <Square
              time={timeLeft?.days}
              timeUnit="day"
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
            />
            <Text fontSize={20} as="span" fontWeight="bold" color={secondaryColor}>
              :
            </Text>
          </>
        )}

        <Square
          time={timeLeft?.hours}
          timeUnit="hour"
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
        <Text fontSize={20} as="span" fontWeight="bold" color={secondaryColor}>
          :
        </Text>

        <Square
          time={timeLeft?.minutes}
          timeUnit="min"
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
        <Text fontSize={20} as="span" fontWeight="bold" color={secondaryColor}>
          :
        </Text>

        <Square
          time={timeLeft?.seconds}
          timeUnit="second"
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />
      </Flex>
    </Flex>
  );
}
