import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';

export default function useDateRangePickerCustomStyles() {
  function renderNextIcon() {
    const el = document.createElement('div');
    const root = createRoot(el);
    root.render(<MdOutlineChevronRight />);
    return el;
  }

  function renderPrevIcon() {
    const el = document.createElement('div');
    const root = createRoot(el);
    root.render(<MdOutlineChevronLeft />);
    return el;
  }

  useEffect(() => {
    const rdrMonthNames = document.querySelectorAll('.rdrMonthName');
    const rdrMonthAndYearWrapper = document.querySelector('.rdrMonthAndYearWrapper');

    if (rdrMonthAndYearWrapper) {
      rdrMonthAndYearWrapper.appendChild(rdrMonthNames[0]);
      rdrMonthAndYearWrapper.appendChild(rdrMonthNames[1]);
    }
  }, []);

  useEffect(() => {
    document?.querySelector('.rdrNextButton i')?.replaceWith(renderNextIcon());
  }, []);

  useEffect(() => {
    document?.querySelector('.rdrPprevButton i')?.replaceWith(renderPrevIcon());
  }, []);
}
