import { Box } from '@chakra-ui/react';
import { useRef } from 'react';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { formats, modules } from './EditorToolbar';
import './styles.css';

interface EditorProps {
  value: string;
  onChange?: (value: string, length: number) => void;
}

export default function Editor({ value, onChange }: EditorProps) {
  function handleChange(value: string, _delta: any, _source: any, editor: UnprivilegedEditor) {
    const length = editor.getLength();

    onChange(value, length - 1);
  }

  const quillRef = useRef(null);

  return (
    <Box
      className="text-editor"
      sx={{
        '.ql-toolbar': {
          borderRadius: '6px 6px 0px 0px',
          borderColor: '#D2D2D2 !important',
        },
        '.ql-container': {
          borderRadius: '0px 0px 6px 6px',
          borderColor: '#D2D2D2 !important',
        },
      }}
    >
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        ref={quillRef}
      />
    </Box>
  );
}
