import { useState } from 'react';
import { MdChevronLeft as LeftIcon, MdChevronRight as RightIcon } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import useFetch from '../../../../hooks/useFetch';
import delay from '../../../../utils/delay';
import FullPageLoading from '../../components/FullPageLoading';
import { Course, Cover, Module } from '../../types';
import { getContentType } from '../../utils/getContentType';
import ModuleCard from './ModuleCard';

import { Box, Center, Circle, Heading, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { useScrollToTop } from '../../hooks/useScrollToTop';
import { isModuleAvailable } from '../../utils/isModuleAvailable';

function getPathToRedirect(currentProduct: Course, module: Module) {
  const isLastModuleAccessed = module.id === currentProduct?.lastModuleId;
  const contentId = isLastModuleAccessed ? currentProduct.lastContentId : module.firstContent?.id;

  const contentType = getContentType(
    isLastModuleAccessed ? currentProduct.lastContentType : module.firstContent?.contentType
  );

  return `/members-area/v2/course/${currentProduct.id}/module/${module.id}/content/${contentId}/content-view/${contentType}`;
}

export interface ShowcaseProductModuleView {
  courseName: string;
  highlightColor: string;
  typeViewModules: string;
  modules: Module[];
}
export interface ModulesPreviewResponse {
  covers: Cover[];
  showcaseProductModuleView: ShowcaseProductModuleView;
}

interface ModulesProps {
  hasBanner: boolean;
  products: Course[];
}

export default function Modules({ hasBanner, products }: ModulesProps) {
  const { search } = useLocation();
  const { courseId } = useParams<{ courseId: string }>();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [isToAdjustLastSlide, setIsToAdjustLastSlide] = useState(false);

  const urlSearchParams = new URLSearchParams(search);
  const showcaseId = urlSearchParams.get('showcaseId');
  const showcaseCourseId = urlSearchParams.get('showcaseCourseId');
  const isPreview = urlSearchParams.get('preview') === 'true';

  const currentProduct = products.find(product => product.id === Number(courseId));
  const productName = currentProduct?.name;
  const visualizationType = currentProduct?.courseTypeViewModules;

  useScrollToTop();

  const { data: modulesPreviewResponse, loading: isFetchingModules } = useFetch<
    UnificadaFront.ResponseJSON<ModulesPreviewResponse>
  >({
    method: 'get',
    url: `/showcase/${showcaseId}/showcase-course/${showcaseCourseId}/members-area-netflix-module-preview`,
    autoFetch: isPreview,
    authenticated: true,
  });

  const modules = isPreview
    ? modulesPreviewResponse?.data.showcaseProductModuleView.modules
    : products?.find(product => product.id === Number(courseId))?.modules;

  const PrevArrow = props => {
    const { onClick, currentSlide } = props;

    if (currentSlide === 0) return null;

    return (
      <Center
        height="80%"
        position="absolute"
        top="50%"
        left="0"
        zIndex="1"
        transform="translateY(-50%)"
        onClick={onClick}
        cursor="pointer"
        _hover={{
          '& > :first-child': { bg: 'blackAlpha.500' },
          '& svg': { transform: 'scale(1.2)' },
        }}
      >
        <Circle p="3px" transition="background-color 0.3s">
          <LeftIcon
            size={32}
            color="#ffffff"
            style={{ transition: 'transform 0.3s ease-in-out' }}
          />
        </Circle>
      </Center>
    );
  };

  const NextArrow = props => {
    const { onClick } = props;

    async function adjustLastSlide() {
      await delay(150);
      setIsToAdjustLastSlide(true);
    }

    if (!onClick) {
      adjustLastSlide();
      return null;
    }

    setIsToAdjustLastSlide(false);

    return (
      <Center
        height="80%"
        position="absolute"
        top="50%"
        right="0"
        zIndex="1"
        transform="translateY(-50%)"
        onClick={onClick}
        cursor="pointer"
        _hover={{
          '& > :first-child': { bg: 'blackAlpha.500' },
          '& svg': { transform: 'scale(1.2)' },
        }}
      >
        <Circle p="3px">
          <RightIcon
            size={32}
            color="#ffffff"
            style={{ transition: 'transform 0.3s ease-in-out' }}
          />
        </Circle>
      </Center>
    );
  };

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 750,
    slidesToShow: 5.25,
    slidesToScroll: 4,
    autoplay: false,
    prevArrow: !isMobile && <PrevArrow />,
    nextArrow: !isMobile && <NextArrow />,
    useTransform: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4.75,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1268,
        settings: {
          slidesToShow: 3.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.55,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (isFetchingModules) return <FullPageLoading />;

  return (
    <>
      <Heading
        mt={{ base: hasBanner ? 6 : '5rem', xl: hasBanner ? 6 : '7rem' }}
        ml={{ base: 4, lg: '5.5rem' }}
        mb={3}
        color="#ffffff"
        fontSize={{ base: 'xl', md: '3xl' }}
        fontWeight="medium"
      >
        {productName}
      </Heading>

      {visualizationType === 'grid' ? (
        <SimpleGrid
          columns={{ base: 2, lg: 3, xl: 4 }}
          spacing={{ base: 4, lg: 8 }}
          px={{ base: 4, lg: '5.5rem' }}
          pointerEvents={isPreview ? 'none' : 'initial'}
        >
          {modules?.map(module => (
            <Box
              key={module.id}
              as={Link}
              to={getPathToRedirect(currentProduct, module)}
              pointerEvents={isModuleAvailable(module.releaseDateModule) ? 'initial' : 'none'}
            >
              <ModuleCard
                module={module}
                isAvailable={isModuleAvailable(module.releaseDateModule)}
              />
            </Box>
          ))}
        </SimpleGrid>
      ) : (
        <Box as="section" sx={{ '.slick-track': { marginLeft: 0, marginRight: 0 } }}>
          <Slider {...settings}>
            {modules?.map(module => (
              <Box
                key={module.id}
                as={Link}
                to={getPathToRedirect(currentProduct, module)}
                ml={{ base: 2.5, lg: isToAdjustLastSlide ? '-0.25rem' : '5.5rem' }}
                pr={{ base: 3.5, lg: 5 }}
                pointerEvents={isPreview ? 'none' : 'initial'}
              >
                <ModuleCard
                  module={module}
                  isAvailable={isModuleAvailable(module.releaseDateModule)}
                />
              </Box>
            ))}
          </Slider>
        </Box>
      )}
    </>
  );
}
