import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
} from '@chakra-ui/react';

import { Box, Button, Heading, Text } from '@chakra-ui/react';

interface IModal {
  isShowModal: boolean;
  onHideModal: () => void;
  onConfirmAction: () => void;
}

function Modal({ isShowModal, onHideModal, onConfirmAction }: IModal) {
  const textColor = useColorModeValue('#202123', '#FFF');
  const bgColor = useColorModeValue('#FFF', '#37393D');

  return (
    <ChakraModal isOpen={isShowModal} onClose={onHideModal} isCentered>
      <ModalOverlay />
      <ModalContent bgColor={bgColor}>
        <ModalCloseButton color={textColor} />
        <ModalBody textAlign="center" p="10">
          <Heading fontSize="3xl" color={textColor}>
            Enviar respostas?
          </Heading>
          <Text textAlign="center" my="6" fontSize="lg" color={textColor}>
            Esta ação não pode ser desfeita.
            <br />
            Após o envio não será mais possivel editar suas respostas.
          </Text>
          <Box display="flex" justifyContent="center">
            <Button
              type="button"
              onClick={() => onHideModal()}
              mr="4"
              bg={bgColor}
              color="dark.500"
              border="1px solid rgba(32, 35, 33, 0.5)"
            >
              Cancelar
            </Button>
            <Button type="button" onClick={() => onConfirmAction()} color={bgColor} bg={textColor}>
              Enviar
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
}

export default Modal;
