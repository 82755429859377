import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CoursePriceType } from '../../../../../../../../validation/CoursePriceSchema';
import { FormValidation } from '../../../../../../../../hooks/useHandleSubmit';
import { TargetType } from '../../../../../../../../hooks/useHandleChange';

interface VariedPricesProps {
  form: Pick<CoursePriceType, 'pricesVariedStatus' | 'purchasePricePix' | 'purchasePriceBoleto'>;
  formValidation?: Pick<
    FormValidation<Partial<CoursePriceType>>,
    'pricesVariedStatus' | 'purchasePricePix' | 'purchasePriceBoleto'
  >;
  onChange: (event: React.ChangeEvent<TargetType>) => void;
}

export default function VariedPrices({ form, formValidation, onChange }: VariedPricesProps) {
  return (
    <VStack mt={5}>
      <VStack>
        <FormControl display="flex" alignItems="center" gap={2}>
          <Switch
            id="pricesVariedStatus"
            name="pricesVariedStatus"
            colorScheme="default"
            isChecked={form.pricesVariedStatus}
            onChange={onChange}
          />
          <FormLabel htmlFor="pricesVariedStatus" mb="0">
            Preços diversos
          </FormLabel>
        </FormControl>

        <Text fontSize="sm" color="#20212380">
          Ao clicar a opção de preços diversos, você poderá precificar o seu produto por tipo de
          pagamento (Boleto e PIX).O preço acima ficará identificado como preço de cartão e de
          visualização oficial.
        </Text>
      </VStack>

      {form.pricesVariedStatus && (
        <HStack w="full" align="start" spacing={4}>
          <FormControl flex={6} isInvalid={formValidation.purchasePriceBoleto?.isInvalid}>
            <FormLabel>Boleto</FormLabel>
            <InputGroup>
              <InputLeftAddon children="R$" />
              <Input
                name="purchasePriceBoleto"
                value={form.purchasePriceBoleto || ''}
                onChange={onChange}
                placeholder="Informe o preço do produto"
                type="number"
                focusBorderColor="primary.500"
                isDisabled={!form.pricesVariedStatus}
              />
            </InputGroup>

            <FormErrorMessage>{formValidation.purchasePriceBoleto?.message}</FormErrorMessage>
          </FormControl>

          <FormControl flex={6} isInvalid={formValidation?.purchasePricePix?.isInvalid}>
            <FormLabel>Pix</FormLabel>
            <InputGroup>
              <InputLeftAddon children="R$" />
              <Input
                name="purchasePricePix"
                value={form.purchasePricePix || ''}
                onChange={onChange}
                placeholder="Informe o preço do produto"
                type="number"
                focusBorderColor="primary.500"
                isDisabled={!form.pricesVariedStatus}
              />
            </InputGroup>
            <FormErrorMessage>{formValidation.purchasePricePix?.message}</FormErrorMessage>
          </FormControl>
        </HStack>
      )}
    </VStack>
  );
}
