import { Flex, Spacer, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { LastRegisteredProductProps } from '..';
import formatStatusName from '../../../../../utils/FormatStatusName';
import Card from '../Card';

export default function LastRegisteredProduct({
  id,
  productName,
  thumbnail,
  description,
  productStatus,
}: LastRegisteredProductProps) {
  return (
    <Card
      as={Link}
      to={`/courses/${id}/course-manager/course`}
      title="Último produto cadastrado"
      subtitle={productName}
      image={thumbnail}
      gradientsize="large"
      bgSize="contain"
      _hover={{
        outline: '2px solid #EB7129',
        cursor: 'pointer',
      }}
    >
      <Flex flex={1} direction="column">
        <Text mt={3} noOfLines={4} fontSize="md" color="#202123" lineHeight="1.25" maxW={80}>
          {description}
        </Text>

        <Spacer />

        <Text noOfLines={4} color="rgba(32, 33, 35, 0.5)" mt={10}>
          Status:{' '}
          <Text fontWeight="bold" as="span">
            {formatStatusName(productStatus)}
          </Text>
        </Text>
      </Flex>
    </Card>
  );
}
