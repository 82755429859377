import { useEffect, useState } from 'react';

import APIPageBuilder from '../../../../api/PageBuilder';
import AlertModal from '../../../../components/AlertModal';
import DropdownSection from '../components/DropdownSection';
import ErrorMessage from '../components/ErrorMessage';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import { Heading } from '../../../../components/Heading';
import Line from '../components/Line';
import { MdAddCircleOutline } from 'react-icons/md';
import MediaInput from './components/MediaInput';
import MediaPreview from './components/MediaPreview';
import Section from '../Section';
import Spinner from '../../../../components/Loadings/Spinner';
import ThemeAPI from '../../../../api/Theme';
import Toast from '../../../../components/Toast';
import Tooltip from '../components/Tooltip';
import UploadHelper from '../../../../helpers/UploadHelper';
import styles from './styles.module.css';
import { useTheme } from '../../../../contexts/ThemeContext';

const FIRST_FILE = 0;
const MAX_SIZE_FILE_PHOTO = 3145728;

function Medias({ section }) {
  const [isActiveSection, setIsActiveSection] = useState(null);
  const [preview, setPreview] = useState(null);
  const [media, setMedia] = useState(null);
  const [currentMedia, setCurrentMedia] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [errorUploadPhoto, setErrorUploadPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState('');

  const {
    theme,
    getTheme,
    getThemeMarketplace,
    deleteMedia,
    isLoading: isLoadingTheme,
  } = useTheme();

  useEffect(() => {
    if (!isLoadingTheme) {
      const mediasSection = section;

      setIsActiveSection(!!mediasSection.active);

      const medias = mediasSection.themeMultimedia[0];

      if (medias) {
        setMedia(medias);
        setSelectedMediaType(medias?.type);

        setCurrentMedia(medias);
        setPreview(medias?.file);
      }
    }
  }, [isLoadingTheme, section, theme]);

  function addMedia() {
    setHasChanged(true);
    setIsActiveSection(true);
    setIsAdding(true);
    setSelectedMediaType('image');
  }

  function handleInputChange(event) {
    let { name, value } = event.target;

    setErrorMessage(null);
    setHasChanged(true);

    if (name === 'order') value = Number(value);

    setCurrentMedia(prevMedia => ({ ...prevMedia, [name]: value }));
  }

  function handlePhotoChange(acceptedFiles) {
    const file = acceptedFiles[FIRST_FILE];

    if (file?.size > MAX_SIZE_FILE_PHOTO) {
      return setErrorUploadPhoto('O tamanho da imagem deve ser de no máximo 3 MB.');
    }

    if (file) {
      setPreview(URL.createObjectURL(file));

      setErrorUploadPhoto(null);
      setErrorMessage(null);
      setHasChanged(true);

      setCurrentMedia(prevMedia => ({ ...prevMedia, file }));
    }
  }

  function handleDiscardChanges(event) {
    event.preventDefault();

    setHasChanged(false);
    setIsAdding(false);

    const mediasSection = section;

    const media = mediasSection.themeMultimedia[0];

    setMedia(media);
    setCurrentMedia(media);
    setSelectedMediaType(media?.type);

    setErrorUploadPhoto(null);
    setErrorMessage(null);
    setIsAdding(false);
  }

  function handleSelectedMediaType(event) {
    const { value } = event.target;

    setHasChanged(true);

    setErrorUploadPhoto(null);
    setErrorMessage(null);
    setSelectedMediaType(value);
  }

  function handleImageDropRejected() {
    setErrorUploadPhoto('Arquivo inválido!');
  }

  function handleImageChange() {
    setHasChanged(true);
    setPreview(null);
  }

  async function handleDelete(event) {
    event.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);
    setErrorUploadPhoto(null);

    try {
      await deleteMedia(theme.id, section.id, currentMedia.id);

      Toast('Mídia excluída com sucesso');
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
      setIsLoading(false);
      setIsShowDeleteModal(false);
      setCurrentMedia(null);
      setMedia(null);
      setPreview(null);
    }
  }

  function toggleModal(event) {
    event.preventDefault();
    setIsShowDeleteModal(!isShowDeleteModal);
  }

  function toggleVisibilitySection(event) {
    const { checked } = event.target;

    setHasChanged(true);

    setIsActiveSection(!checked);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const shouldDisplayErrorMessage = selectedMediaType === 'image' && !currentMedia.file;

    if (shouldDisplayErrorMessage) return setErrorUploadPhoto('Você deve escolher uma imagem');

    setIsLoading(true);

    const { id } = currentMedia;
    const method = id ? 'update' : 'store';

    const payload = {
      ...currentMedia,
      activeSection: isActiveSection,
      type: selectedMediaType,
    };

    try {
      if (currentMedia?.file && typeof currentMedia?.file !== 'string') {
        const { newFileName } = await UploadHelper.upload(currentMedia.file, 'multimedia');

        payload.file = newFileName;
      } else {
        delete payload.file;
      }

      if (method === 'store') await ThemeAPI.storeMedia(theme.id, section.id, payload);

      if (method === 'update') {
        await ThemeAPI.updateMedias(theme.id, section.id, currentMedia.id, payload);
      }

      setCurrentMedia({
        ...currentMedia,
        file: payload.file,
      });

      const successMessage = method === 'store' ? 'criada' : 'atualizada';

      Toast(`Mídia ${successMessage} com sucesso!`);
      setHasChanged(false);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDuplicateMediaSection() {
    setErrorMessage('');

    try {
      const theme_id = theme.id;

      const section_id = section.id;

      await APIPageBuilder.multimedia.store(theme_id, section_id);

      Toast('Secção de mídias duplicada com sucesso!');

      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
    }
  }

  async function handleDeleteMediaSection() {
    setErrorMessage('');

    try {
      const theme_id = theme.id;

      const section_id = section.id;

      await APIPageBuilder.multimedia.delete(theme_id, section_id);

      Toast('Secção de mídias excluída com sucesso!');

      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
    }
  }

  return (
    <Section
      description="Adicione imagens, textos ou vídeos. Crie seções com mídias e apresente um produto novo, uma promoção ou até mesmo uma curso em destaque. Essa seção pode ser duplicada e espalhada pelo site. Pode ser usada de diversas formas para tornar seu site ainda mais rico."
      actionName="Mídias"
    >
      <div className={styles.sectionHeader}>
        <div className="d-flex align-items-center">
          <Heading as="h4" fontSize="2xl">
            Seção de mídia
          </Heading>
          <Line />
          <Tooltip link="" />
        </div>

        <div className="d-flex align-items-center">
          <div
            className={styles.actionsHeaderContainer}
            style={{ visibility: !media && !isAdding && 'hidden' }}
          >
            <p>Tipo de mídia</p>
            <select
              name={`mediaType${currentMedia?.id}`}
              onChange={handleSelectedMediaType}
              value={selectedMediaType}
            >
              <option value={'image'}>Imagem</option>
              <option value={'video'}>Vídeo</option>
              <option value={'text'}>Texto</option>
            </select>
          </div>

          <DropdownSection
            onClickToDuplicate={handleDuplicateMediaSection}
            onClickToDelete={handleDeleteMediaSection}
          />
        </div>
      </div>

      {!media && !isAdding && (
        <div>
          <p className={styles.headerText}>
            Adicione imagens, textos ou vídeos do <strong>YouTube</strong>. Crie seções com mídias e
            apresente um produto novo, uma promoção ou até mesmo uma curso em destaque. Essa seção
            pode ser duplicada e espalhada pelo site. Pode ser usada de diversas formas para tornar
            seu site ainda mais rico.
          </p>
        </div>
      )}

      <div className={styles.mediasContainer}>
        {!media && !isAdding && (
          <button onClick={addMedia} className="my-4">
            <MdAddCircleOutline size="1.125rem" /> Adicionar seção de mídia
          </button>
        )}

        {(media || isAdding) && (
          <form onSubmit={handleSubmit}>
            <div>
              {selectedMediaType === 'image' && (
                <section className={styles.testimonialsImage}>
                  {!preview ? (
                    <MediaInput
                      preview={preview}
                      theme={theme}
                      onDrop={handlePhotoChange}
                      onDropRejected={handleImageDropRejected}
                      onChangeImage={handleImageChange}
                      mediaType={selectedMediaType}
                    />
                  ) : (
                    <MediaPreview
                      preview={preview}
                      onClick={handleImageChange}
                      isLoading={isLoadingTheme}
                      mediaType={selectedMediaType}
                    />
                  )}

                  {errorUploadPhoto && <ErrorMessage message={errorUploadPhoto} />}
                </section>
              )}
              <section className={styles.formSection}>
                <label htmlFor={`mediaTitle${currentMedia?.id}`}>Título</label>
                <input
                  id={`mediaTitle${currentMedia?.id}`}
                  name="title"
                  onChange={handleInputChange}
                  value={currentMedia?.title}
                  placeholder="Escreva o título."
                  minLength={1}
                  maxLength={255}
                />

                <label htmlFor={`mediaDescription${currentMedia?.id}`}>Descrição</label>
                <textarea
                  id={`mediaDescription${currentMedia?.id}`}
                  name="description"
                  onChange={handleInputChange}
                  value={currentMedia?.description}
                  placeholder="Escreva a descrição"
                  rows={5}
                  required={selectedMediaType === 'text'}
                />

                <div className={styles.characterCounter}>
                  Quantidade máxima de caracteres:
                  <span>
                    {currentMedia?.description?.length > 1000 ? (
                      <p className={styles.warning}>{currentMedia?.description?.length}/1000</p>
                    ) : (
                      <p>{currentMedia?.description?.length}/1000</p>
                    )}
                  </span>
                </div>

                {selectedMediaType === 'video' && (
                  <>
                    <label className="mt-5" htmlFor={`link${currentMedia.id}`}>
                      Link do vídeo
                    </label>
                    <input
                      id={`link${currentMedia?.id}`}
                      name="link"
                      onChange={handleInputChange}
                      value={currentMedia?.link}
                      placeholder="exemplo: https://www.youtube.com/xxxxxx"
                      type="url"
                      pattern="https://.*"
                      required
                    />
                  </>
                )}
              </section>
            </div>

            <div className={styles.actions}>
              {!isAdding && (
                <button className={styles.deleteButton} onClick={toggleModal}>
                  Excluir
                </button>
              )}

              {hasChanged && (
                <button onClick={handleDiscardChanges} className={styles.cancelButton}>
                  Cancelar
                </button>
              )}

              <button
                type="submit"
                disabled={!hasChanged || isLoading || currentMedia?.description?.length > 1000}
              >
                {isLoading ? <Spinner small /> : 'Salvar alterações'}
              </button>
            </div>

            <div className={styles.footer}>
              <div>
                <input
                  type="checkbox"
                  id="isActiveMediasSection"
                  name="isActiveMediasSection"
                  onChange={toggleVisibilitySection}
                  checked={!isActiveSection}
                />
                <label htmlFor="isActiveMediasSection">Ocultar seção da página inicial</label>
              </div>
            </div>
          </form>
        )}
      </div>

      {errorMessage && !hasChanged && <ErrorMessage message={errorMessage} />}

      <AlertModal
        showModal={isShowDeleteModal}
        onHideModal={() => setIsShowDeleteModal(false)}
        title={`Você tem certeza que deseja excluir esta mídia?`}
        actionButton={{ nameActionButton: 'Excluir', onActionButton: handleDelete }}
        actionButtonClassName="btn-danger"
        cancelableButton={{
          nameCancelableButton: 'cancelar',
          onCancelableButton: () => setIsShowDeleteModal(false),
        }}
      />
    </Section>
  );
}

export default Medias;
