/* eslint-disable jsx-a11y/anchor-is-valid */

import './styles.css';

import { ReactComponent as AccountKey } from '../../assets/icons/account-key.svg';
import { ReactComponent as FinancialIcon } from '../../assets/icons/sidebar/financialIcon.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as MarketingIcon } from '../../assets/icons/sidebar/marketingIcon.svg';
import { ReactComponent as MembersAreaIcon } from '../../assets/icons/sidebar/members-area-icon.svg';
import MenuItem from './MenuItem';
import { ReactComponent as PageBuilderIcon } from '../../assets/icons/sidebar/pageBuilderIcon.svg';
import { ReactComponent as ProductManagerIcon } from '../../assets/icons/sidebar/productsManagerIcon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/sidebar/profileIcon.svg';
import { ReactComponent as PoleManagement } from '../../assets/icons/sidebar/pole-management.svg';
import { ReactComponent as ConfigIcon } from '../../assets/icons/sidebar/configIcon.svg';
import { ROLE_LOCAL_STORAGE } from '../../helpers/LocalStorageHelper';
import SectionMenuItem from './SectionMenuItem';
import { ReactComponent as SupportIcon } from '../../assets/icons/sidebar/supportIcon.svg';
// import { ReactComponent as TrainingIcon } from '../../assets/icons/sidebar/trainingIcon.svg';
// import { ReactComponent as UpgradeIcon } from '../../assets/icons/sidebar/upgradeIcon.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/sidebar/usersIcon.svg';
import { memo } from 'react';
import { useTheme } from '../../contexts/ThemeContext';

function Sidebar() {
  const { themeMarketplace } = useTheme();
  const role = localStorage.getItem(ROLE_LOCAL_STORAGE);

  return (
    <div
      className="nk-sidebar nk-sidebar-fixed is-dark shadow"
      data-content="sidebarMenu"
      style={{
        background: themeMarketplace.primaryColor,
        borderColor: themeMarketplace.primaryColor,
      }}
    >
      <div
        className="nk-sidebar-element nk-sidebar-head"
        style={{
          background: themeMarketplace.primaryColor,
          height: '4.907rem',
          borderColor: themeMarketplace.primaryColor,
        }}
      >
        <div className="nk-sidebar-brand d-none d-md-block d-lg-block d-xl-block">
          <Link to="/" className="logo-link nk-sidebar-logo">
            <img className="logo-size" src={themeMarketplace.logo} alt="logo" />
          </Link>
        </div>
        <div className="nk-menu-trigger mr-n2 d-lg-none d-xl-none">
          <a href="" className="nk-nav-toggle nk-quick-nav-icon nav-icon" data-target="sidebarMenu">
            <em
              className="icon ni ni-arrow-left"
              style={{ color: themeMarketplace.textColor }}
            ></em>
          </a>
        </div>
      </div>
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-content pb-4">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu nk-menu-trigger">
              <SectionMenuItem
                title="Construa seu site"
                route="/page-builder"
                icon={<PageBuilderIcon fill={themeMarketplace.textColor} />}
              />

              <SectionMenuItem
                title="Gerenciamento de Produto"
                icon={<ProductManagerIcon fill={themeMarketplace.textColor} />}
              />
              <MenuItem title="Produtos online" route="/courses" />
              <MenuItem title={'Biblioteca de vídeos'} route="/video-library" />
              <MenuItem title={'Categorias'} route={'/categories'} />
              <MenuItem title={'Área de Membros'} route={'/management-members-area'} />

              <SectionMenuItem
                title="Area de Membros"
                icon={<MembersAreaIcon fill={themeMarketplace.textColor} />}
              />
              <MenuItem title={'Produtos'} route={'/management-members-area/showcases'} />
              <MenuItem title={'Banners'} route={'/management-members-area/banners'} />

              <SectionMenuItem
                title="Financeiro e vendas"
                icon={<FinancialIcon fill={themeMarketplace.textColor} />}
              />
              <MenuItem title="Dashboard" route="/dashboard" />
              <MenuItem title={'Transações'} route="/financial" />
              <MenuItem title={'Compartilhamento de receita'} route="/coming-soon" />
              <MenuItem title={'Extrato'} route="/coming-soon" />
              <MenuItem title={'Relatórios'} route="/coming-soon" />

              <SectionMenuItem
                title="Marketing"
                icon={<MarketingIcon fill={themeMarketplace.textColor} />}
              />
              <MenuItem title="Cupons" route="/coupons" />
              <MenuItem title="Blog" route="/coming-soon" />
              <MenuItem title="Depoimentos" route="/coming-soon" />

              <SectionMenuItem
                title="Usuários"
                icon={<UsersIcon fill={themeMarketplace.textColor} />}
              />
              <MenuItem title={'Usuários da conta'} route="/user-base" />
              <MenuItem title={'Base de alunos'} route="/student-base" />

              <SectionMenuItem
                title="Gestão de Polos"
                icon={<PoleManagement fill={themeMarketplace.textColor} route="/pole-management" />}
              />
              <SectionMenuItem
                title="Perfil"
                icon={<ProfileIcon fill={themeMarketplace.textColor} />}
              />
              <MenuItem title={'Editar Perfil'} route="/profile/user" />
              <MenuItem title={'Dados bancários'} route="/profile/bank-accounts" />

              {/* <MenuItem title={'Histórico de Pagamentos'} route="/coming-soon" /> */}

              <SectionMenuItem
                title="Configurações"
                route="/platform-settings"
                icon={<ConfigIcon fill={themeMarketplace.textColor} />}
              />

              {/* <SectionMenuItem
                title={'Upgrade'}
                route="/coming-soon"
                icon={<UpgradeIcon fill={themeMarketplace.textColor} />}
              /> */}

              {/* <SectionMenuItem
                title={'Treinamentos'}
                route="/coming-soon"
                icon={<TrainingIcon fill={themeMarketplace.textColor} />}
              /> */}

              <SectionMenuItem
                title={'Suporte'}
                route="/support"
                icon={<SupportIcon fill={themeMarketplace.textColor} />}
              />

              {role === 'ADMIN' && (
                <>
                  <SectionMenuItem
                    icon={<AccountKey fill={themeMarketplace.textColor} />}
                    title="Admin"
                  />
                  <MenuItem title={'Planos'} route="/internal-manager/plans" />
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Sidebar);
