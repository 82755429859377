import { Box, Center, Heading, List, Spinner, Text } from '@chakra-ui/react';
import Header from './Header';
import SearchContainer from './SearchContainer';
import Question from './Question';
import useFetch from '../../../../../hooks/useFetch';
import { useHistory, useLocation } from 'react-router-dom';
import Paginate from '../../../../../components/Paginate';

interface AnswersByAlternative {
  item: string;
  count: number;
}

export interface QuestionStatistic {
  id: number;
  questionText: string;
  area: string;
  discipline: string;
  subject: string;
  institution: string;
  correct: number;
  wrong: number;
  performance: number;
  answersByAlternative: AnswersByAlternative[];
}

interface IQuestionStatisticsResponse {
  data: QuestionStatistic[];
  total: number;
  page: number;
  per_page: number;
}

function hasFilterParams(urlSearchParams) {
  // Obtém todos os parâmetros da URLSearchParams
  const params = new Set(urlSearchParams.keys());

  // Remove o parâmetro "page" do conjunto de parâmetros
  params.delete('page');

  // Verifica se ainda existem outros parâmetros além de "page"
  return params.size > 0;
}

export default function QuestionStatisticsPage() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { data, loading: isLoading } = useFetch<
    UnificadaFront.ResponseJSON<IQuestionStatisticsResponse>
  >({
    url: `/question-library/question/statistics?${searchParams}`,
    method: 'get',
    autoFetch: true,
    authenticated: true,
  });

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    searchParams.set('page', newPage.toString());

    history.push({
      search: searchParams.toString(),
    });
  }

  const ìsFiltering = hasFilterParams(searchParams);
  const response = data?.data;
  const questionStatisticsList = response?.data;
  const isNotFoundVisible = !isLoading && !questionStatisticsList?.length && ìsFiltering;
  const isEmpty = !isLoading && !questionStatisticsList?.length && !ìsFiltering;
  const total = response?.total || 0;
  const perPage = response?.per_page || 1;
  const pageCount = Math.ceil(total / perPage);
  const currentPage = Number(searchParams.get('page')) || 1;
  const shouldShowPagination = !isLoading && pageCount > 1;

  return (
    <Box>
      <Header />
      <SearchContainer />

      {isNotFoundVisible && (
        <Center mt={32}>
          <Heading size="md" fontWeight="semibold" color="gray.300">
            Nenhum resultado encontrado...
          </Heading>
        </Center>
      )}

      {isEmpty && (
        <Text color="#0000007D" lineHeight="17px">
          Você não possui nenhum questão cadastrada.
        </Text>
      )}

      {isLoading ? (
        <Center h="50vh" w="full">
          <Spinner size="xl" color="default.500" />
        </Center>
      ) : (
        <List mt={8} spacing={2.5}>
          {questionStatisticsList?.map(questionStatistic => (
            <Question
              key={questionStatistic.id}
              statistics={questionStatistic}
              isFiltering={ìsFiltering}
            />
          ))}
        </List>
      )}

      {shouldShowPagination && (
        <Paginate
          my={10}
          pageCount={pageCount}
          initialPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </Box>
  );
}
