import { IGTMParams } from '../ExternalScripts';

export function install(id = '') {
  if (!id) return;

  const scriptId = `ga-gtag-${id}`;

  if (document.getElementById(scriptId)) return;

  const script = document.createElement('script');
  script.id = scriptId;
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

  const head = document.head;
  head.insertBefore(script, head.firstChild);

  const scriptConfig = document.createElement('script');
  scriptConfig.id = `ga-gtag-config-${id}`;

  scriptConfig.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${id}');
  `;

  head.appendChild(scriptConfig);
}

type NewEventGTMProps = { id: string; data: Partial<IGTMParams> };

type PurchaseItemType = {
  item_name: string;
  item_id: string;
  price: string;
  quantity: number;
};

type PurchaseType = {
  transaction_id: string;
  affiliation: string;
  value: string;
  currency: 'BRL';
  coupon: string;
  items: PurchaseItemType[];
  user?: {
    fullName?: string;
    email?: string;
    documentNumber?: string;
    documentType?: string;
    streetAddress?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
    complementary?: string;
    country?: string;
    zipCode?: string;
    streetNumber?: string;
    alternateAddress?: string;
  };
};

export function purchaseEventGTM(id: string, data: PurchaseType) {
  let scriptAddPurchaseEventExists = document.getElementById(`ga-gtag-purchase-${id}`);

  if (scriptAddPurchaseEventExists) return;

  const scriptAddPurchaseEvent = document.createElement('script');
  scriptAddPurchaseEvent.id = `ga-gtag-purchase-${id}`;

  scriptAddPurchaseEvent.innerHTML = `
  dataLayer.push({
    event: 'purchase',
    ecommerce: ${JSON.stringify(data)},
  });
`;

  const head = document.head;

  head.appendChild(scriptAddPurchaseEvent);
}

export function removePurchaseEventGTM(id: string) {
  const scriptAddPurchaseEventExists = document.getElementById(`ga-gtag-purchase-${id}`);

  if (scriptAddPurchaseEventExists) {
    scriptAddPurchaseEventExists.remove();
  }
}

export function newEventGTM({ id, data }: NewEventGTMProps) {
  const scriptAddPurchaseEvent = document.createElement('script');

  scriptAddPurchaseEvent.id = `gtm-event-${data.event}-${id}`;
  scriptAddPurchaseEvent.innerHTML = `dataLayer.push(${JSON.stringify(data)});`;

  const head = document.head;

  head.appendChild(scriptAddPurchaseEvent);
}

export function googleTagManagerIds(): string[] {
  const scriptGTM = document.querySelectorAll('script');

  const gtmCodes = new Set<string>();

  scriptGTM.forEach(script => {
    const scriptContent = script.textContent;
    if (scriptContent && scriptContent.includes('googletagmanager.com/gtm.js')) {
      const matches = scriptContent.match(/GTM-[A-Za-z0-9]+/g);
      if (matches) {
        matches.forEach(match => gtmCodes.add(match));
      }
    }
  });

  return Array.from(gtmCodes);
}

export function removeEventGTM(id: string, eventName: string) {
  const scriptAddPurchaseEventExists = document.getElementById(`gtm-event-${eventName}-${id}`);

  if (scriptAddPurchaseEventExists) {
    scriptAddPurchaseEventExists.remove();
  }
}
