import styles from './styles.module.css';

function Section({ title, children }) {
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionHeader}>
        <div>
          <h4>{title}</h4>
        </div>
      </div>

      <div>{children}</div>
    </div>
  );
}

export default Section;
