import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Grid, Tag, Text, Tooltip, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Webhook } from '../../../types';
// import { InstructorView } from '../../../types';

interface EventsProps {
  webhookId: number;
  events: Webhook['events'];
}

function getGridColumn(visibleEventsLength: number, currentIndex: number): string {
  if (visibleEventsLength === 4) {
    return 'span 1';
  }

  if (visibleEventsLength === 3) {
    return currentIndex === 0 ? 'span 2' : 'span 1';
  }

  return 'span 2';
}

export default function Events({ webhookId, events }: EventsProps) {
  const visibleEvents = events.slice(0, 3);

  return (
    <VStack align="start" spacing={3} w="full" maxWidth="10.3125rem">
      <Grid templateColumns="1fr 1fr" gap={2.5} w="full">
        {visibleEvents.map((event, index) => (
          <Tooltip key={event} label={event} placement="top">
            <Tag
              size="sm"
              variant="solid"
              bg="#20212340"
              color="#202123"
              justifyContent="center"
              gridColumn={getGridColumn(visibleEvents.length, index)}
            >
              <Text isTruncated fontWeight="medium">
                {event}
              </Text>
            </Tag>
          </Tooltip>
        ))}
      </Grid>

      {events.length > 3 && (
        <Button
          as={Link}
          to={`/integrations/webhook/edit/${webhookId}`}
          size="xs"
          variant="ghost"
          color="#759AED"
          fontWeight="normal"
          rightIcon={<ChevronDownIcon />}
          px={0}
          mt={0}
          sx={{ '.chakra-button__icon': { ml: 1 } }}
        >
          Mostrar mais
        </Button>
      )}
    </VStack>
  );
}
