import call from '../../../../assets/icons/call.svg';
import styles from './styles.module.css';
import { useStudentCourse } from '../../../../contexts/CourseStudentContext';
import { Box } from '@chakra-ui/react';
import { scrollbarStyle } from '../ContentsView';

function NoContentView() {
  const { studentCourse } = useStudentCourse();

  return (
    <Box
      w="full"
      overflow="auto"
      sx={scrollbarStyle}
      margin={{ base: '0', md: '20px 70px', lg: '20px 70px' }}
    >
      <div className={styles.containerNoContent}>
        <div className={styles.content}>
          <img src={call} alt="Imagem de três bonecos na tela de um computador." />
          <h3>Visualize todos os conteúdos!</h3>
          <p>
            Aqui é onde você irá visualizar os conteúdos de todos os módulos do produto adquirido.
            Clique no módulo{' '}
            <b style={{ fontWeight: 600 }}>
              {studentCourse?.modules?.length ? studentCourse?.modules[0]?.name : '...'}
            </b>{' '}
            para começar.
          </p>
        </div>
      </div>
    </Box>
  );
}

export default NoContentView;
