import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react';

import { useAuth } from '../../../contexts/AuthContext';
import { useTheme } from '../../../contexts/ThemeContext';
import { TOKEN_ORIGIN_LOGIN } from '../../../helpers/LocalStorageHelper';
import MenuList from './MenuList';
import UserProfile from './UserProfile';

interface ISidebarProps {
  isOpen: boolean;
  onClose: () => void;
  onToggleSidebar: () => void;
}

export default function Sidebar({ isOpen, onClose, onToggleSidebar }: ISidebarProps) {
  const { themeMarketplace } = useTheme();
  const { signOut } = useAuth();
  const tokenOrigin = localStorage.getItem(TOKEN_ORIGIN_LOGIN);
  const { textColor } = themeMarketplace;

  const scrollbarStyle = {
    '::-webkit-scrollbar': { width: '0.5rem', background: 'transparent' },
    '::-webkit-scrollbar-thumb': {
      paddingRight: '0.5rem',
      paddingLeft: '0.5rem',
      borderRadius: '0.25rem',
      backgroundColor: `${textColor}20`,
    },
  };

  function handleSignOut() {
    signOut();
  }

  return (
    <>
      {/* MOBILE */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          bg="primary.500"
          color="secondary.500"
          maxWidth={{ base: '72.5%', md: '33.33%' }}
        >
          <DrawerHeader boxShadow="lg" display="flex" py="24px">
            <UserProfile onToggleSidebar={onToggleSidebar} />
          </DrawerHeader>

          <DrawerBody pl={4} pr={0} sx={scrollbarStyle}>
            <MenuList />
          </DrawerBody>

          <DrawerFooter>
            <Button
              onClick={handleSignOut}
              w="full"
              borderRadius="none"
              variant="outline"
              _hover={{
                background: 'transparent',
              }}
              _focus={{ boxShadow: 'none', outline: 'none' }}
              borderColor={textColor}
            >
              Encerrar sessão
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* DESKTOP */}
      <Flex
        direction="column"
        display={{ base: 'none', lg: 'flex' }}
        bg="primary.500"
        minH="100vh"
        zIndex="10"
        width="15.0625rem" // 241px
        position="fixed"
        h="full"
        boxShadow="rgba(0, 0, 0, 0.6) 0px 0px 15px"
      >
        <Flex pt={tokenOrigin ? '135px' : '102px'} overflow="auto" sx={scrollbarStyle}>
          <MenuList />
        </Flex>
      </Flex>
    </>
  );
}
