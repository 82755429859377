import {
  Box,
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { Heading } from '../../../../components/Heading';
import PlanSplit from './PlanSplit';
import PoleSplit from './PoleSplit';
import StandardSplit from './StandardSplit';
import { IoMdAdd } from 'react-icons/io';

export type RevenueSliptType = 'DEFAULT' | 'POLE' | 'AFFILIATE' | 'RECURRENCE_DEFAULT';
type SplitType = 'standard' | 'plan' | 'pole';

function getDefaultIndex(splitType: SplitType): number {
  switch (splitType) {
    case 'standard':
      return 0;
    case 'plan':
      return 1;
    case 'pole':
      return 2;
    default:
      return 0;
  }
}

export default function RevenueSplit() {
  const history = useHistory();
  const { type } = useParams<{ type: SplitType }>();

  const {
    isOpen: isOpenAddStandardSplit,
    onOpen: onOpenAddStandardSplit,
    onClose: onCloseAddStandardSplit,
  } = useDisclosure();

  const {
    isOpen: isOpenAddPlanSplit,
    onOpen: onOpenAddPlanSplit,
    onClose: onCloseAddPlanSplit,
  } = useDisclosure();

  const {
    isOpen: isOpenAddPoleSplit,
    onOpen: onOpenAddPoleSplit,
    onClose: onCloseAddPoleSplit,
  } = useDisclosure();

  function handleTabsChange(index: number) {
    switch (index) {
      case 0:
        return history.push(`/revenue-split/standard`);
      case 1:
        return history.push('/revenue-split/plan');
      case 2:
        return history.push('/revenue-split/pole');
      default:
        return history.push(`/revenue-split/standard`);
    }
  }

  function onOpenModal(index: number) {
    switch (index) {
      case 0:
        return onOpenAddStandardSplit();
      case 1:
        return onOpenAddPlanSplit();
      case 2:
        return onOpenAddPoleSplit();
      default:
        return onOpenAddStandardSplit();
    }
  }

  return (
    <Box>
      <Stack width="full">
        <Stack
          width="full"
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          <Heading fontSize={{ base: 'xl', lg: '2xl' }}>Compartilhamento de Receita</Heading>

          <Button
            size="sm"
            colorScheme="primary"
            color="secondary.500"
            leftIcon={<IoMdAdd />}
            onClick={() => onOpenModal(getDefaultIndex(type))}
          >
            Novo compartilhamento
          </Button>
        </Stack>

        <Tabs
          isLazy
          colorScheme="primary"
          onChange={handleTabsChange}
          defaultIndex={getDefaultIndex(type)}
        >
          <TabList>
            <Tab>Padrão</Tab>
            <Tab>Planos</Tab>
            <Tab>Polos</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <StandardSplit isOpen={isOpenAddStandardSplit} onClose={onCloseAddStandardSplit} />
            </TabPanel>
            <TabPanel>
              <PlanSplit isOpen={isOpenAddPlanSplit} onClose={onCloseAddPlanSplit} />
            </TabPanel>
            <TabPanel>
              <PoleSplit isOpen={isOpenAddPoleSplit} onClose={onCloseAddPoleSplit} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  );
}
