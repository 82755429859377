import { AxiosError } from 'axios';

export default function ErrorResponse(error: AxiosError) {
  if (error?.code === 'ECONNABORTED') {
    return 'Requisição expirou, tente novamente.';
  }

  if (!error.response) {
    if (error.message === 'Network Error') {
      return 'O serviço indisponível. Tente novamente em instantes.';
    }

    return error.message;
  }

  if (error.request.status === 429) {
    return 'Rota bloqueada temporariamente pela quantidade de requisições!';
  }

  if (error.response) {
    if (error.response.data && error.response.data.validation) {
      if (error.response.data.validation.params) {
        return error.response.data.validation.params.message;
      }

      if (error.response.data.validation.body) {
        return error.response.data.validation.body.message;
      }

      // eslint-disable-next-line no-console
      console.error(error.response.data);

      return 'Erro desconhecido.';
    }

    if (typeof error.response.data === 'string') {
      return error.response.data;
    }

    if (error.response.data && error.response.data.message) {
      return error.response.data.message;
    }

    if (error.response.data) {
      return error.response.data;
    }

    if (error.response.status === 404) {
      const config = error.response.config;

      return `Rota: "${config.method}" "${config.url}" não encontrada.`;
    }
  }

  return `Erro: ${error.request.statusText}`;
}
