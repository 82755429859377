export default function getBuyButtonText(paymentMethod: string, isPurchaseByPixConfirmed: boolean) {
  if (isPurchaseByPixConfirmed) {
    return 'Verificar status';
  }

  if (paymentMethod === 'pix') {
    return 'Gerar QR Code';
  }

  if (paymentMethod === 'boleto') {
    return 'Gerar boleto';
  }

  return 'Confirmar compra';
}
