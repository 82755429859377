import {
  Box,
  Button,
  Card,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { useCourse } from '../../../../../../contexts/CourseContext';
import ErrorResponse from '../../../../../../helpers/ErrorResponse';

const accessDurationInitialValues = {
  freeAccess: false,
  limitedAccess: false,
  accessPeriod: 7,
  accessDeadline: false,
  courseAccessDeadline: dayjs().utc().format('YYYY-MM-DD'),
};

export default function AccessCourse() {
  const { course, updateCourse, loading } = useCourse();
  const [accessDuration, setAccessDuration] = useState(accessDurationInitialValues);
  const [accessType, setAccessType] = useState('freeAccess');
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!loading) {
      setAccessDuration({
        freeAccess: course.freeAccess,
        limitedAccess: course.limitedAccess,
        accessPeriod: course.accessPeriod || accessDurationInitialValues.accessPeriod,
        accessDeadline: course.accessDeadline,
        courseAccessDeadline:
          dayjs(course.courseAccessDeadline).utc().format('YYYY-MM-DD') ||
          accessDurationInitialValues.courseAccessDeadline,
      });

      if (course?.freeAccess) {
        setAccessType('freeAccess');
      } else if (course?.limitedAccess) {
        setAccessType('limitedAccess');
      } else {
        setAccessType('accessDeadline');
      }
    }
  }, [course, loading]);

  function handleChange(event) {
    const { value, name, type } = event.target;

    const values = {
      ...accessDuration,
    };

    if (type === 'date') {
      values.courseAccessDeadline = dayjs(value).utc().format('YYYY-MM-DD');
    } else {
      values[name] = value;
    }

    setAccessDuration(values);
  }

  async function onSubmit(event) {
    event.preventDefault();

    const freeAccess = accessType === 'freeAccess';
    const limitedAccess = accessType === 'limitedAccess';
    const accessDeadline = accessType === 'accessDeadline';

    const accessDurationData = {
      freeAccess,
      limitedAccess,
      accessPeriod: accessDuration.accessPeriod,
      accessDeadline,
      courseAccessDeadline: accessDuration.courseAccessDeadline,
    };

    try {
      await updateCourse(accessDurationData);

      setMessage('Curso alterado com sucesso !');
      setTypeMessage('sucesso');
      onOpen();
    } catch (error) {
      setMessage(ErrorResponse(error));
      setTypeMessage('erro');
      onOpen();
    }
  }

  return (
    <VStack width="full">
      <Card as="form" onSubmit={onSubmit} width="full" p={6}>
        <Heading as="h4" fontWeight="600" fontSize="2xl">
          Duração de acesso
        </Heading>

        <Stack direction="column" spacing={2} mt={5}>
          <Text fontWeight="500" fontSize="md">
            Selecione qual será o formato de duração de acesso dos seus alunos a esse produto.
          </Text>
          <Text fontWeight="300" fontSize="sm" fontStyle="italic">
            Obs: Se você já possui alunos cadastrados no produto e realizou a mudança de duração, a
            nova configuração só fica válida para novos alunos, os antigos continuam com a
            configuração que eles adquiriram o produto.
          </Text>
        </Stack>

        <VStack align="start" mt={5}>
          <RadioGroup
            defaultValue={accessType}
            onChange={setAccessType}
            value={accessType}
            colorScheme="default"
          >
            <VStack align="start" spacing={5}>
              <VStack align="start">
                <Radio value="freeAccess">
                  <Text fontWeight="600" color="gray.500">
                    Livre
                  </Text>
                </Radio>

                <Text fontWeight={400} fontSize="sm">
                  Os alunos terão acesso permanente
                </Text>
              </VStack>

              <VStack align="start">
                <Radio value="limitedAccess">
                  <Text fontWeight="600" color="gray.500">
                    Acesso limitado
                  </Text>
                </Radio>

                <Text fontWeight={400} fontSize="sm">
                  Os alunos terão um período de acesso limitado em{' '}
                  <Text as="span" fontWeight={600}>
                    DIAS
                  </Text>{' '}
                  estipulado por você, iniciando a partir da compra do produto.
                </Text>
              </VStack>

              {accessType === 'limitedAccess' && (
                <Input
                  mt={5}
                  focusBorderColor="orange.400"
                  className="form-control"
                  onChange={handleChange}
                  name="accessPeriod"
                  value={accessDuration.accessPeriod}
                  type="number"
                  placeholder="Quantidade em dias"
                />
              )}

              <VStack align="start">
                <Radio value="accessDeadline">Limite para ficar ativo</Radio>

                <Text fontWeight={400} fontSize="sm">
                  O curso terá um prazo limite para ficar ativo, após a data limite escolhida por
                  você e o curso ficará inativo
                </Text>

                {accessType === 'accessDeadline' && (
                  <Input
                    focusBorderColor="default.500"
                    type="date"
                    value={dayjs(accessDuration.courseAccessDeadline).utc().format('YYYY-MM-DD')}
                    onChange={handleChange}
                  />
                )}
              </VStack>
            </VStack>
          </RadioGroup>
        </VStack>

        <Stack direction="row" w="full" justifyContent="flex-end">
          <Button colorScheme="default" size="sm" type="submit">
            Salvar
          </Button>
        </Stack>
      </Card>

      <Modal
        size="md"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
        <ModalContent>
          <ModalCloseButton onClick={onClose} />
          <ModalBody w="100%" textAlign="center">
            {typeMessage === 'sucesso' && (
              <Stack w="100%" direction="column" alignItems="center">
                <ModalHeader>Alterações realizadas com sucesso!</ModalHeader>
                <FaCheckCircle
                  style={{
                    color: 'green',
                    height: '50px',
                    width: '50px',
                    textAlign: 'center',
                  }}
                />
                <Text>
                  As alterações realizadas ficarão vigentes apenas para novos aluno, os antigos
                  permanecem com as configurações anteriores.
                </Text>
              </Stack>
            )}
            {typeMessage === 'aviso' && (
              <Stack w="100%" direction="column" alignItems="center" my={10}>
                <FaExclamationCircle
                  style={{
                    marginBottom: 10,
                    color: '#EB7129',
                    height: '50px',
                    width: '50px',
                  }}
                />
                <Text>{message}</Text>
              </Stack>
            )}
            <Box>
              {typeMessage === 'erro' && (
                <Stack w="100%" direction="column" alignItems="center" my={10}>
                  <FaExclamationCircle
                    style={{
                      marginBottom: 10,
                      color: 'red',
                      height: '50px',
                      width: '50px',
                    }}
                  />
                  <Text>{message}</Text>
                </Stack>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
