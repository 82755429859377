import { axiosBackend } from './Configuration';

const StudentAPI = {
  show: async courseId => {
    try {
      const { data: dataStudent } = await axiosBackend().get(
        `/members-area/${courseId}/course/modules-and-contents`
      );

      return dataStudent;
    } catch (error) {
      throw error;
    }
  },

  showModules: async courseId => {
    try {
      const { data: dataStudent } = await axiosBackend().get(
        `/students/${courseId}/course/modules`
      );
      return dataStudent;
    } catch (error) {
      throw error;
    }
  },

  getStudentsDataCheckout: async () => {
    try {
      const { data: dataStudent } = await axiosBackend().get('/students/checkout');

      return dataStudent;
    } catch (error) {
      throw error;
    }
  },

  updateStudentsDataCheckout: async payload => {
    try {
      const { data: dataStudent } = await axiosBackend().post('/students/checkout', payload);

      return dataStudent;
    } catch (error) {
      throw error;
    }
  },

  index: async () => {
    try {
      const { data: dataStudent } = await axiosBackend().get('/students');

      return dataStudent;
    } catch (error) {
      throw error;
    }
  },

  update: async newDataStudent => {
    try {
      return await axiosBackend().put('/students', newDataStudent);
    } catch (error) {
      throw error;
    }
  },

  redefinePassword: async newPassword => {
    try {
      return await axiosBackend().patch('/students/redefine-password', newPassword);
    } catch (error) {
      throw error;
    }
  },
};

export default StudentAPI;
