import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { FIlteredVideosType } from '..';
import { CardVideoLibrary } from './CardVideoLibrary';

type Params = {
  videos: FIlteredVideosType[];
  updateStatusTranscode: ({ id, transcode, thumbnail }) => void;
  onDeleteVideoList: (id: number) => void;
  onOpenPreviewVideo: (id: number) => void;
};

export function CardVideoLibraryView({
  onDeleteVideoList,
  updateStatusTranscode,
  videos,
  onOpenPreviewVideo,
}: Params) {
  if (!videos.length) return <></>;

  return (
    <Box display="flex" flexWrap="wrap" gap="15px">
      {videos.map(video => (
        <CardVideoLibrary
          key={nanoid()}
          video={video}
          updateStatusTranscode={updateStatusTranscode}
          onDeleteVideoList={onDeleteVideoList}
          onOpenPreviewVideo={onOpenPreviewVideo}
        />
      ))}
    </Box>
  );
}
