import { Box, Button, Heading, List, Stack, useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { FiCheck as CheckIcon } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IParams } from '../types';
import Modal from './Modal';
import Question from './Question';
import Report from './Report';
import MaximumAmountReachedRedoContent from '../../../../../components/MaximumAmountReachedRedoContent';
import Paginate from '../../../../../components/Paginate';
import RedoContent from '../../../../../components/RedoContent';
import { useCourseStudent } from '../../../../../contexts/CourseStudentContextV2';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { sortByOrder } from '../../../../../helpers/SortHelper';
import getRouteToShow from '../../../../../helpers/getRouteToShow';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import { PagingContents } from '../../../components/PagingContents';
import QuestionsAPI from '../../../../../api/Question';
import Toast from '../../../../../components/Toast';
import Loader from '../../../../../components/Loadings/DotsFalling';
import { useStudentProgress } from '../../../../../contexts/ProgressContextV2';

function ViewQuestions() {
  const [isFinished, setIsFinished] = useState(false);

  const [isStarted, setIsStarted] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isCheckingAnswers, setIsCheckingAnswers] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const { currentContent: content } = useCourseStudent();

  const queryParams = new URLSearchParams(location.search);
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const currentPage = Number(urlSearchParams.get('page')) || 1;
  const searchParams = queryParams.toString();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDisabledButton,
    onOpen: onOpenDisabledButton,
    onClose: onCloseDisabledButton,
  } = useDisclosure();

  const { courseId, moduleId, contentId } = useParams<IParams>();

  const { markAsDoneHandleChange } = useStudentProgress();

  const { data: response, loading } = useFetch<UnificadaFront.ResponseJSON<any>>({
    method: 'get',
    url: `/questions/${courseId}/course/${moduleId}/module/${contentId}/content/status-question`,
    autoFetch: true,
    authenticated: true,
  });

  const { data: studentQuestionResponse, loading: studentQuestionIsLoading } = useFetch<
    UnificadaFront.ResponseJSON<any>
  >({
    method: 'get',
    url: `/questions/${courseId}/course/${moduleId}/module/${contentId}/content/student?${searchParams}`,
    autoFetch: true,
    authenticated: true,
  });

  useEffect(() => {
    setIsFinished(response?.data?.finished || false);
  }, [response?.data?.finished]);

  const numberOfTimesRemaining = response?.data?.numberOfTimesRemaining;
  const numberOfTimesRegistrations = response?.data?.numberOfTimesRegistrations;
  const isDisabledButton = numberOfTimesRemaining === 0;
  const isHiddenButton = numberOfTimesRegistrations === 0;

  const questions = studentQuestionResponse?.data?.questions;
  const pageCount = Math.ceil(
    studentQuestionResponse?.data?.total / studentQuestionResponse?.data?.limit
  );

  const isLoading = loading || studentQuestionIsLoading;

  const pathRedirect = `/members-area/v2/course/${courseId}/module/${moduleId}/content/${contentId}/content-view/${getRouteToShow(
    content?.contentType
  )}`;

  const { isLoading: isSubmitting, handleSubmit: startSurvey } = useHandleSubmit({
    url: `/questions/${courseId}/course/${moduleId}/module/${contentId}/content/start-question`,
    method: 'post',
    authenticated: true,
    onSuccess: {
      callback(response: { data: { data: { started: boolean } } }) {
        const { started } = response?.data?.data;
        setIsStarted(started);

        if (isFinished) {
          window.location.href = pathRedirect;
        }
      },
    },
  });

  function toggleModal() {
    setIsShowModal(prevState => !prevState);
  }

  async function handleClick() {
    try {
      setIsCheckingAnswers(true);
      const response = await QuestionsAPI.student.checkAnswers(courseId, moduleId, contentId);
      const { isAllQuestionsAnswered } = response?.data;
      if (!isAllQuestionsAnswered) {
        Toast('Você precisa responder todas as questões antes de finalizar o conteúdo', 'error');
        return;
      }
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setIsCheckingAnswers(false);
    }

    toggleModal();
  }

  async function finishSurvey() {
    try {
      const markAsDone = true;

      const response = await QuestionsAPI.student.finish(courseId, moduleId, contentId);

      const { finished } = response?.data;

      setIsFinished(finished);

      setIsStarted(false);

      markAsDoneHandleChange({ contentId, moduleId, markAsDone });

      Toast('Questões finalizadas com sucesso!', 'success');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      toggleModal();
    }
  }

  async function answerQuestion(questionId, optionId) {
    if (!isStarted) await startSurvey();

    try {
      await QuestionsAPI.student.update(courseId, moduleId, contentId, questionId, optionId);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  const shouldDisplayLoader = isLoading;
  const shouldDisplayReport = !isLoading && isFinished;
  const shouldDisplayQuestions = !isLoading && isFinished === false && questions?.length > 0;
  const hiddenButtonRedoContent = shouldDisplayQuestions || isHiddenButton;

  function handlePageChange(selectedItem: { selected: number }) {
    const newPage = selectedItem.selected + 1;

    queryParams.set('page', newPage.toString());

    const newSearch = queryParams.toString();

    history.push(`?${newSearch}`);
  }

  return (
    <>
      {shouldDisplayLoader && (
        <Box display="flex" alignItems="center" justifyContent="center" my="10">
          <Loader />
        </Box>
      )}

      {shouldDisplayReport && (
        <Report
          contentTitle={content.title}
          courseId={courseId}
          moduleId={moduleId}
          contentId={contentId}
          hiddenButtonRedoContent={hiddenButtonRedoContent}
          isDisabledButton={isDisabledButton}
          onOpen={onOpen}
          onOpenDisabledButton={onOpenDisabledButton}
        />
      )}

      {shouldDisplayQuestions && (
        <Stack spacing={{ base: '9px', md: '55px', lg: '55px' }}>
          <Heading
            color="#FFF"
            fontSize={{ base: '18px', md: '24px', lg: '24px' }}
            margin="0"
            fontWeight="500"
          >
            {content?.title}
          </Heading>

          <List>
            {questions.sort(sortByOrder).map((question, index) => {
              const questionNumber =
                currentPage > 1 ? (currentPage - 1) * 10 + index + 1 : index + 1;

              return (
                <Question
                  key={question.id}
                  id={question.id}
                  order={question.order}
                  questionText={question.questionText}
                  options={question.options}
                  userOptionAnswer={question.userOptionAnswer}
                  onAnswerQuestion={answerQuestion}
                  isDisabled={isFinished}
                  questionNumber={questionNumber}
                />
              );
            })}
          </List>

          {pageCount > 1 && (
            <Box w="full" my="8">
              <Paginate
                initialPage={currentPage}
                pageCount={pageCount}
                onPageChange={handlePageChange}
              />
            </Box>
          )}

          {pageCount === currentPage && !isFinished && (
            <Box display="flex" justifyContent="end" my={pageCount <= 1 && '10'}>
              <Button
                onClick={handleClick}
                isLoading={isCheckingAnswers}
                isDisabled={isSubmitting}
                colorScheme="primary"
                size="sm"
                w={{ base: '100%', md: 'auto' }}
                rightIcon={<CheckIcon width="3" height="3" />}
              >
                Enviar respostas
              </Button>
            </Box>
          )}
        </Stack>
      )}

      <Modal
        isShowModal={isShowModal}
        onHideModal={() => setIsShowModal(false)}
        onConfirmAction={() => finishSurvey()}
      />

      {isFinished && <PagingContents />}

      <RedoContent
        isOpen={isOpen}
        onClose={onClose}
        startSurvey={startSurvey}
        isLoading={isLoading}
        numberOfTimesRemaining={numberOfTimesRemaining}
      />

      <MaximumAmountReachedRedoContent
        isOpen={isOpenDisabledButton}
        onClose={onCloseDisabledButton}
      />
    </>
  );
}

export default ViewQuestions;
