import { Link } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext';

function SectionMenuItem(props) {
  const { themeMarketplace } = useTheme();

  return (
    <li className="mt-4">
      <Link
        to={props.route || '#'}
        className="nk-menu-link"
        style={!props.route ? { pointerEvents: 'none' } : {}}
      >
        <span className="nk-menu-icon" style={{ maxWidth: '18px', height: '18px', flexShrink: 0 }}>
          {props.icon}
        </span>
        <span className="ml-4" style={{ color: themeMarketplace.textColor, fontSize: '16px' }}>
          <h6>{props.title}</h6>
        </span>

        {props.route === '/coming-soon' && (
          <span
            className="badge ml-3"
            style={{
              pointerEvents: 'none',
              background: 'transparent',
              borderColor: themeMarketplace.textColor,
              color: themeMarketplace.textColor,
              fontSize: '0.5rem',
            }}
          >
            EM BREVE
          </span>
        )}
      </Link>
    </li>
  );
}

export default SectionMenuItem;
