import { Box, Text, useBreakpointValue, useColorModeValue, VStack } from '@chakra-ui/react';
import { ResponsiveBar } from '@nivo/bar';
import { useMemo } from 'react';
import { AnswersByAlternative } from '../..';
import ChartTitle from '../ChartTitle';
import CustomTooltip from '../CustomTooltip';

interface AnswersByAlternativeChartProps {
  answersByAlternative: AnswersByAlternative[];
}

export default function AnswersByAlternativeChart({
  answersByAlternative,
}: AnswersByAlternativeChartProps) {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const textColor = useColorModeValue('#202123', '#FFFFFF');

  const total = useMemo(() => {
    return answersByAlternative?.reduce((acc, curr) => acc + curr.count, 0);
  }, [answersByAlternative]);

  const mappedData = useMemo(() => {
    return answersByAlternative
      ?.map(answer => ({
        label: isMobile ? `${answer.item}` : `Item ${answer.item}`,
        value: answer.count,
      }))
      .reverse();
  }, [answersByAlternative, isMobile]);

  function getPercentage(value: number) {
    const percentage = (value / total) * 100;
    const parsedPercentage = percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(2);

    return `${parsedPercentage}%`;
  }

  const chartMargin = isMobile
    ? { top: 0, right: 20, bottom: 44, left: 24 }
    : { top: 0, right: 20, bottom: 44, left: 88 };

  const tickValues = isMobile ? 3 : 5;

  const CustomLeftTick = data => {
    const transform = isMobile ? `translate(-12px,0) rotate(0)` : `translate(-32px,0) rotate(0)`;

    return (
      <g transform={`translate(0,${data.y})`}>
        <Text
          as="text"
          fontSize="md"
          fill={textColor}
          fontWeight="medium"
          fontFamily="Montserrat"
          transform={transform}
          textAnchor="end"
          dominantBaseline="central"
        >
          {data.value}
        </Text>
      </g>
    );
  };

  const CustomBottomTick = data => {
    return (
      <g transform={`translate(${data.x},${data.y - 8})`}>
        <Text
          as="text"
          fontSize="md"
          fill={textColor}
          fontWeight="medium"
          fontFamily="Montserrat"
          transform={`translate(0,10px) rotate(0)`}
          textAnchor="middle"
          dominantBaseline="text-before-edge"
        >
          {getPercentage(data.value)}
        </Text>
      </g>
    );
  };

  return (
    <VStack align="start" spacing={2.5} w="full">
      <ChartTitle title="Respostas por alternativa" />

      <Box width="100%" height={200}>
        <ResponsiveBar
          data={mappedData}
          indexBy="label"
          layout="horizontal"
          padding={0.28}
          colors={['#EC7117']}
          enableLabel={false}
          enableGridX={true}
          enableGridY={false}
          gridYValues={5}
          margin={chartMargin}
          axisLeft={{ renderTick: CustomLeftTick }}
          axisBottom={{ renderTick: CustomBottomTick, tickValues }}
          gridXValues={5}
          maxValue={total}
          tooltip={data => {
            return (
              <CustomTooltip>
                <Text as="span">
                  {data.indexValue}:
                  <Text as="strong" ml={1}>
                    {data.value} | {getPercentage(data.value)}{' '}
                  </Text>
                </Text>
              </CustomTooltip>
            );
          }}
        />
      </Box>
    </VStack>
  );
}
