import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';

interface IPropsModal {
  isOpen: boolean;
  numberOfTimesRemaining: number;
  isLoading: boolean;
  onClose: () => void;
  startSurvey: () => void;
}

export default function RedoContent({
  isOpen,
  numberOfTimesRemaining,
  isLoading,
  onClose,
  startSurvey,
}: IPropsModal) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bgColor="#37393D" color="#FFF">
        <ModalHeader textAlign="center" fontWeight={700}>
          Refazer conteúdo?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" fontWeight={400}>
          Você pode refazer o conteúdo mais{' '}
          {numberOfTimesRemaining > 1 ? `${numberOfTimesRemaining} vezes` : 'uma vez'}.
        </ModalBody>

        <ModalFooter>
          <Stack
            width="full"
            direction="row"
            justifyContent="center"
            spacing={3}
            alignItems="center"
          >
            <Button size="sm" width={150} colorScheme="gray" onClick={onClose}>
              Não
            </Button>
            <Button
              size="sm"
              width={150}
              colorScheme="orange"
              onClick={startSurvey}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              Sim
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
