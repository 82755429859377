import { useBreakpointValue, useToast } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { IPlan, IProduct, IShowcase } from '../..';
import { axiosBackend } from '../../../../../../../api/Configuration';
import ErrorResponse from '../../../../../../../helpers/ErrorResponse';
import useHandleSubmit from '../../../../../../../hooks/useHandleSubmit';
import reorder from '../../../../../Courses/CourseManager/ModulesManager/utils/reorder';
import { ShowCasePlanListItem } from './ShowCasePlanListItem';
import { ShowCaseProductListItem } from './ShowCaseProductListItem';
import path from 'path';

interface Payload {
  status: string;
  showcaseType: IShowcase['showcaseType'];
  showcaseViewType?: string;
  showcasePlanId?: number;
  planId?: number;
  recurrencePlanName?: string;
  titleHighlight?: string;
  description?: string;
  imageDesktop?: string;
  imageMobile?: string;
  order?: number;
  statusName?: string;
}

interface OrderItem {
  showcaseItemId: number;
  order: number;
}

function mapOrders(
  items: Array<{ [key: string]: any }>,
  idKey: string,
  orderKey: string
): OrderItem[] {
  return items.map(item => ({
    showcaseItemId: item[idKey],
    order: item[orderKey],
  }));
}

interface IProps {
  products: IProduct[];
  plans: IPlan[];
  showcase: IShowcase;
  showcases: IShowcase[];
  showcaseItemId: number;
  setShowcases: (showcases: IShowcase[]) => void;
  onAddBannerModalOpen: () => void;
  onAddPlanModalOpen: () => void;
  onOpenRemoveShowcaseItemModal: () => void;
  onOpenDeleteProductBannerModal: () => void;
  setShowcaseItemId: (id: number) => void;

  setselectedShowcase: (showcaseInfo: {
    id: number;
    name: string;
    status: string;
    showcaseViewType: string;
    showcaseType: string;
  }) => void;
}

export default function TableShowcase({
  products,
  plans,
  showcase,
  showcases,
  setShowcases,
  onAddBannerModalOpen,
  onOpenRemoveShowcaseItemModal,
  onOpenDeleteProductBannerModal,
  setselectedShowcase,
  setShowcaseItemId,
}: IProps) {
  const history = useHistory();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const toast = useToast();

  const payload = {
    showcaseType: showcase.showcaseType,
    showcaseItensOrders:
      showcase.showcaseType === 'products'
        ? mapOrders(products, 'showcaseCourseId', 'order')
        : mapOrders(plans, 'showcasePlanId', 'order'),
  };

  const { handleSubmit: handleUpdateShowcaseItemsOrder } = useHandleSubmit({
    method: 'patch',
    data: payload,
    url: `/showcase-course/${showcase.showcaseId}/showcase/order-showcase-course`,
    authenticated: true,
    onSuccess: {
      message: `A ordenação dos ${
        showcase.showcaseType === 'products' ? 'produtos' : 'planos'
      } foi realizada com sucesso.`,
    },
  });

  async function handleStatusChange(
    event: ChangeEvent<HTMLSelectElement>,
    itemId: number,
    showcaseType: IShowcase['showcaseType']
  ) {
    const newStatus = event.target.value;
    if (!newStatus) return;

    const index = showcases.findIndex(
      showcaseData => showcaseData.showcaseId === showcase.showcaseId
    );
    if (index === -1) return;

    const updatedShowcases = [...showcases];
    const currentShowcase = updatedShowcases[index];

    const item =
      showcaseType === 'products'
        ? currentShowcase.products.find(product => product.showcaseCourseId === itemId)
        : currentShowcase.plans.find(plan => plan.showcasePlanId === itemId);

    if (!item) return;

    const payload: Payload = {
      status: newStatus,
      showcaseType,
      ...(showcaseType === 'plans' && { showcaseViewType: showcase.viewType }),
      ...(currentShowcase.viewType === 'highlight' &&
        'titleHighlight' in item && {
          titleHighlight: item.titleHighlight,
          description: item.description,
          imageDesktop: path.basename(item.imageDesktop),
          ...(item.imageMobile && { imageMobile: path.basename(item.imageMobile) }),
        }),
    };

    try {
      await axiosBackend().patch(`/showcase-course/${itemId}`, payload);
      item.statusName = newStatus;
      setShowcases(updatedShowcases);
      toast({
        title: 'O status do produto foi atualizado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    } catch (error) {
      toast({
        title: ErrorResponse(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      });
    }
  }

  async function handleOnDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const updatedItems =
      showcase.showcaseType === 'products'
        ? reorder(products, result.source.index, result.destination.index)
        : reorder(plans, result.source.index, result.destination.index);

    const showcaseIndex = showcases.findIndex(
      showcaseData => showcaseData.showcaseId === showcase.showcaseId
    );

    if (showcaseIndex === -1) return;

    const updatedShowcases = [...showcases];

    updatedShowcases[showcaseIndex][showcase.showcaseType] = updatedItems;

    setShowcases(updatedShowcases);

    await handleUpdateShowcaseItemsOrder();
  }

  function addBanner(showcaseItemId: number) {
    setShowcaseItemId(showcaseItemId);
    onAddBannerModalOpen();
  }

  function removeBanner(showcaseItemId: number) {
    setShowcaseItemId(showcaseItemId);
    onOpenDeleteProductBannerModal();
  }

  function deleteProduct(showcaseItemId: number, showcase: IShowcase) {
    setShowcaseItemId(showcaseItemId);
    setselectedShowcase({
      id: showcase.showcaseId,
      name: showcase.name,
      status: showcase.statusName,
      showcaseViewType: showcase.viewType ?? '',
      showcaseType: showcase.showcaseType ?? '',
    });
    onOpenRemoveShowcaseItemModal();
  }

  function editModules(productId: number, showcaseId: number, showcaseItemId: number) {
    return history.push({
      pathname: `/members-area-management/netflix/${showcaseId}/showcase/${showcaseItemId}/showcase-course/modules`,
      search: `productId=${productId}`,
    });
  }

  if (showcase.showcaseType === 'products') {
    return (
      <ShowCaseProductListItem
        showcase={showcase}
        products={products}
        onAddBannerClick={addBanner}
        deleteProduct={deleteProduct}
        editModules={editModules}
        handleOnDragEnd={handleOnDragEnd}
        onStatusChange={handleStatusChange}
        isMobile={isMobile}
        removeBanner={removeBanner}
        setShowcaseItemId={setShowcaseItemId}
      />
    );
  }

  return (
    <ShowCasePlanListItem
      showcase={showcase}
      plans={plans}
      onAddBannerClick={addBanner}
      deleteProduct={deleteProduct}
      editModules={editModules}
      handleOnDragEnd={handleOnDragEnd}
      onStatusChange={handleStatusChange}
      removeBanner={removeBanner}
      setShowcaseItemId={setShowcaseItemId}
    />
  );
}
