import { Avatar, Box, HStack, Text } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';

import {
  EMAIL_LOCAL_STORAGE,
  NAME_LOCAL_STORAGE,
  PHOTO_LOCAL_STORAGE,
} from '../../../../../helpers/LocalStorageHelper';

import { LinkBox, LinkOverlay } from '@chakra-ui/react';

const fullName = localStorage.getItem(NAME_LOCAL_STORAGE);
const email = localStorage.getItem(EMAIL_LOCAL_STORAGE);
const photo = localStorage.getItem(PHOTO_LOCAL_STORAGE);

export default function UserProfile({ onToggleSidebar }) {
  const history = useHistory();

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();

    onToggleSidebar();

    history.push('/profile/user');
  }

  return (
    <LinkBox as="div">
      <LinkOverlay as={Link} to="/profile/user" onClick={handleClick}>
        <HStack spacing={4}>
          <Avatar src={photo} bg="#FFFFFF80" color="#FFFFFF" name={fullName} />
          <Box>
            <Text color="#FFFFFF" fontSize="sm">
              {fullName}
            </Text>

            <Text color="#FFFFFF80" fontWeight="normal" fontSize="small" noOfLines={1}>
              {email}
            </Text>
          </Box>
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
}
