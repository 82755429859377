import {
  Box,
  Button,
  CloseButton,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import useHandleChange, { TargetType } from '../../../../../hooks/useHandleChange';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';

interface IForm {
  usersIds: number[];
  coursesAffiliationIds: number[];
}

export function ModalAddAffiliatesInBulk({
  isOpenAddAffiliatesInBulk,
  onCloseAddAffiliatesInBulk,
  listProductsAffiliations,
}) {
  const { form, setForm, handleChange } = useHandleChange<IForm>({
    usersIds: [],
    coursesAffiliationIds: [],
  });

  const [affiliateOptions, setAffiliateOptions] = useState<{ value: number; label: string }[]>([]);
  const [courseOptions, setCourseOptions] = useState<{ value: number; label: string }[]>([]);

  const chakraStyles = {
    container: provided => ({
      ...provided,
      width: '100%',
      color: '#20212380',
      fontSize: 'sm',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#20212380',
      fontSize: 'sm',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      bg: 'transparent',
      color: '#202123',
      px: 2.5,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    option: provided => ({
      ...provided,
      color: '#20212380',
      fontSize: 'sm',
    }),
    valueContainer: provided => ({
      ...provided,
      px: 3,
      color: '#20212380',
      fontSize: 'sm',
    }),
  };

  const {
    loading: affiliateLoading,
    data: response,
    fetchData: getAffiliateUsers,
  } = useFetch<UnificadaFront.ResponseJSON>({
    method: 'get',
    url: '/affiliations/list-users',
    authenticated: true,
    autoFetch: false,
  });

  const {
    loading: productsAvailableLoading,
    data: productsAvailableResponse,
    fetchData: getProductsAvailable,
  } = useFetch<UnificadaFront.ResponseJSON>({
    method: 'get',
    url: '/affiliations/list-of-products-available-for-affiliation',
    authenticated: true,
    autoFetch: false,
  });

  const affiliateRoles = response?.data;

  const productsAvailableForAffiliation = productsAvailableResponse?.data;

  const fetching = affiliateLoading || productsAvailableLoading;

  const isDisabled = fetching || !form.coursesAffiliationIds.length || !form.usersIds.length;

  useEffect(() => {
    const affiliateListFiltered = affiliateRoles?.filter(
      affiliate => !form.usersIds.includes(affiliate.id)
    );

    const options = affiliateListFiltered?.map(affiliate => ({
      value: affiliate.id,
      label: affiliate.fullName,
    }));

    setAffiliateOptions(options);
  }, [affiliateRoles, form.usersIds]);

  useEffect(() => {
    const courseListFiltered = productsAvailableForAffiliation?.filter(
      product => !form.coursesAffiliationIds.includes(product.courseAffiliationId)
    );

    const options = courseListFiltered?.map(course => ({
      value: course.courseAffiliationId,
      label: course.courseName,
    }));

    setCourseOptions(options);
  }, [form.coursesAffiliationIds, productsAvailableForAffiliation]);

  const shouldSearchLists =
    isOpenAddAffiliatesInBulk && !affiliateRoles && !productsAvailableForAffiliation;

  useEffect(() => {
    if (shouldSearchLists) {
      getAffiliateUsers();
      getProductsAvailable();
    }
  }, [getAffiliateUsers, getProductsAvailable, shouldSearchLists]);

  function removeAffiliate(affiliateId) {
    const newArrayValues = form.usersIds?.filter(id => id !== affiliateId);
    setForm({ ...form, usersIds: newArrayValues });
  }

  function removeProduct(productId) {
    const newArrayValues = form.coursesAffiliationIds?.filter(id => id !== productId);
    setForm({ ...form, coursesAffiliationIds: newArrayValues });
  }

  const { isLoading: isSubmitting, handleSubmit } = useHandleSubmit({
    data: form,
    url: '/affiliations/add-affiliate-in-bulk',
    method: 'post',
    authenticated: true,
    onSuccess: {
      message: 'Afiliados adicionados com sucesso!',
      callback() {
        onCloseAddAffiliatesInBulk();
        listProductsAffiliations();
        setForm({
          usersIds: [],
          coursesAffiliationIds: [],
        });
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    await handleSubmit();
  }

  return (
    <Modal
      isOpen={isOpenAddAffiliatesInBulk}
      onClose={onCloseAddAffiliatesInBulk}
      size={{ sm: 'md', md: 'lg', lg: 'xl' }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent margin="10px" as="form" onSubmit={onSubmit}>
        <ModalHeader paddingBottom={0}>Adicionar afiliado</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            fontWeight={400}
            fontSize="14px"
            color="#20212380"
            marginTop="5px"
            marginBottom="15px"
          >
            Você poderá selecionar a quantidade de afiliados que pretende adicionar, e a quantidade
            de produtos onde esses afiliados serão inseridos.
          </Text>

          <FormControl>
            <FormLabel fontWeight={500} fontSize="14px">
              Afiliado
            </FormLabel>
            <FormHelperText fontWeight={500} fontSize="14px" color="#20212380" marginY={2}>
              Selecione um ou mais afiliados
            </FormHelperText>
            <Select
              menuPlacement="auto"
              placeholder="Selecione"
              isMulti={true}
              focusBorderColor="primary.500"
              hasStickyGroupHeaders
              closeMenuOnSelect={true}
              selectedOptionColor="primary"
              noOptionsMessage={() => 'Nenhum afiliados encontrado.'}
              options={affiliateOptions}
              value={[]}
              isLoading={affiliateLoading}
              onChange={newValues => {
                const newArray = newValues.map(newValue => newValue.value);

                const newArrayValue = [...form.usersIds, ...newArray];

                const event = {
                  target: {
                    name: 'usersIds',
                    value: newArrayValue,
                  },
                };
                handleChange(event as unknown as ChangeEvent<TargetType>);
              }}
              chakraStyles={chakraStyles}
            />

            <Stack
              direction="row"
              alignItems="center"
              spacing={0}
              flexWrap="wrap"
              paddingY="10px"
              gap="4px"
            >
              {form.usersIds.length &&
                form.usersIds.map(affiliateId => (
                  <Box
                    key={affiliateId}
                    padding="8px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    maxWidth="212px"
                    height="20px"
                    fontSize="12px"
                    gap="4px"
                    backgroundColor="#20212325"
                    borderRadius={6}
                    fontWeight={500}
                  >
                    <Text isTruncated>
                      {affiliateRoles?.find(affiliate => affiliate.id === affiliateId)?.fullName}
                    </Text>

                    <CloseButton size="sm" onClick={() => removeAffiliate(affiliateId)} />
                  </Box>
                ))}
            </Stack>
          </FormControl>

          <FormControl>
            <FormLabel fontWeight={500} fontSize="14px">
              Produto
            </FormLabel>
            <FormHelperText fontWeight={500} fontSize="14px" color="#20212380" marginY={2}>
              Selecione um ou mais produtos
            </FormHelperText>
            <Select
              menuPlacement="auto"
              placeholder="Selecione"
              isMulti={true}
              focusBorderColor="primary.500"
              hasStickyGroupHeaders
              closeMenuOnSelect={true}
              selectedOptionColor="primary"
              noOptionsMessage={() => 'Nenhum produto encontrado.'}
              options={courseOptions}
              value={[]}
              isLoading={affiliateLoading}
              onChange={newValues => {
                const newCourse = newValues.map(newValue => newValue.value);

                const newArrayValue = [...form.coursesAffiliationIds, ...newCourse];

                const event = {
                  target: {
                    name: 'coursesAffiliationIds',
                    value: newArrayValue,
                  },
                };
                handleChange(event as unknown as ChangeEvent<TargetType>);
              }}
              chakraStyles={chakraStyles}
            />

            <Stack
              direction="row"
              alignItems="center"
              spacing={0}
              flexWrap="wrap"
              paddingY="10px"
              gap="4px"
            >
              {form.coursesAffiliationIds.length &&
                form.coursesAffiliationIds.map(productId => (
                  <Box
                    key={productId}
                    padding="8px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    maxWidth="212px"
                    height="20px"
                    fontSize="12px"
                    gap="4px"
                    backgroundColor="#20212325"
                    borderRadius={6}
                    fontWeight={500}
                  >
                    <Text isTruncated>
                      {
                        productsAvailableForAffiliation?.find(
                          product => product.courseAffiliationId === productId
                        )?.courseName
                      }
                    </Text>

                    <CloseButton size="sm" onClick={() => removeProduct(productId)} />
                  </Box>
                ))}
            </Stack>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Stack direction="row" alignItems="center" justifyContent="end" spacing={3}>
            <Button
              colorScheme="gray"
              variant="solid"
              isDisabled={isSubmitting}
              onClick={onCloseAddAffiliatesInBulk}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              isLoading={fetching}
              isDisabled={isDisabled}
              colorScheme="primary"
              color="textColor"
            >
              Adicionar
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
