import React from 'react';
import NotAccess from '../../assets/images/empty.svg';
import styles from './styles.module.css';

function NotFound({ message }) {
  return (
    <div className='column'>
      <div className={styles.div_imagem}>
        <img src={NotAccess} alt='Acesso negado' className={styles.imagem} />
      </div>

      <div className={styles.div_text}>
        <h3 className={styles.text_not_access}>
          {message}
        </h3>
      </div>
    </div>
  );
}

export default NotFound;
