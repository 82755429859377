export const ReactSelectStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? '' : isSelected ? '#e06721' : isFocused ? '#e0672110' : '',
      color: isDisabled ? '#ccc' : isSelected ? 'white' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? '#e06721' : '#e0672130') : '',
      },
    };
  },
  control: styles => ({
    ...styles,
    background: '#FFFFFF',
    borderRadius: '6px',
    border: '2px solid #CBD5E080',
    ':focus-within': {
      ...styles[':focus-within'],
      borderColor: '#e06721',
      boxShadow: 'none',
    },
  }),
  multiValue: styles => ({
    ...styles,
    background: '#20212330',
    borderRadius: '6px',
  }),
  multiValueLabel: styles => ({
    ...styles,
    padding: 0,
    fontWeight: 600,
  }),
};
