import { Button, Spinner, Stack } from '@chakra-ui/react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import ProductCard from './ProductCard';
import ProductTabs from './ProductTabs';

export interface AffiliationDetails {
  automaticAffiliation: boolean;
  commissionValue: string;
  courseId: number;
  productName: string;
  productThumbnail: string;
  informationForAffiliates: string;
  identifier: string;
  checkoutType: string;
}

export default function MyAffiliationsDetails() {
  const history = useHistory();

  const { id: courseAffiliationId } = useParams<{ id: string }>();

  const { data: response } = useFetch<UnificadaFront.ResponseJSON<AffiliationDetails>>({
    url: `/affiliations/products/${courseAffiliationId}/product-detail`,
    method: 'get',
    authenticated: true,
    autoFetch: true,
  });

  const affiliationDetails = response?.data;

  function goBack() {
    history.push('/affiliations/my-affiliations');
  }

  return (
    <>
      {!affiliationDetails ? (
        <Spinner size="xl" color="primary" position="fixed" inset={0} margin="auto" />
      ) : (
        <Stack width="full" alignItems="flex-start" spacing={4}>
          <Button
            paddingLeft="0"
            leftIcon={<MdKeyboardArrowLeft size="24" />}
            variant="ghost"
            color="dark.500"
            onClick={goBack}
            fontSize="lg"
          >
            Voltar para minhas afiliações
          </Button>

          <ProductCard
            productName={affiliationDetails?.productName}
            productThumbnail={affiliationDetails?.productThumbnail}
            productCommissionValue={affiliationDetails?.commissionValue}
          />

          <ProductTabs
            courseId={affiliationDetails?.courseId}
            identifier={affiliationDetails?.identifier}
            informationForAffiliates={affiliationDetails?.informationForAffiliates}
            checkoutType={affiliationDetails?.checkoutType}
          />
        </Stack>
      )}
    </>
  );
}
