import { Container, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FilterType from '../types/Filter';
import Filters from './Filters';
import SearchInput from './SearchInput';

export default function SearchContainer() {
  const [search, setSearch] = useState('');
  const history = useHistory();

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  function handleFilter(filters?: FilterType[]) {
    const params: Record<string, string> = {
      search,
      page: '1',
      filterAreaId: '',
      filterDisciplineId: '',
      filterSubjectId: '',
      filterInstitutionId: '',
    };

    if (!!filters.length) {
      const areaFilter = filters?.find(filter => filter.name === 'area');
      if (areaFilter) {
        params.filterAreaId = areaFilter.id.toString();
      }

      const disciplineFilter = filters?.find(filter => filter.name === 'discipline');
      if (disciplineFilter) {
        params.filterDisciplineId = disciplineFilter.id.toString();
      }

      const subjectFilter = filters?.find(filter => filter.name === 'subject');
      if (subjectFilter) {
        params.filterSubjectId = subjectFilter.id.toString();
      }

      const institutionFilter = filters?.find(filter => filter.name === 'institution');
      if (institutionFilter) {
        params.filterInstitutionId = institutionFilter.id.toString();
      }
    }

    const paramsWithValues = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== '')
    );

    const updatedSearch = new URLSearchParams(paramsWithValues).toString();

    history.push({ search: updatedSearch });
  }

  return (
    <Flex justify="center" bg="#2021230D" py={12}>
      <Container w="full" maxW="790px" px={{ base: 4, xl: 'none' }}>
        <SearchInput onChange={handleSearchChange} onFilter={handleFilter} />
        <Filters onFilter={handleFilter} />
      </Container>
    </Flex>
  );
}
