import { axiosBackend } from './Configuration';

const CourseSubscriptionPlanAPI = {
  subscriptionPlans: async () => {
    try {
      const { data: plansData } = await axiosBackend().get('/course-subscription-plan/all-plans');
      return plansData;
    } catch (error) {
      throw error;
    }
  },

  store: async payload => {
    try {
      const { data: dataCourseSubscription } = await axiosBackend().post(
        '/course-subscription-plan',
        payload
      );

      return dataCourseSubscription;
    } catch (error) {
      throw error;
    }
  },

  show: async id => {
    try {
      const { data: dataCourseSubscription } = await axiosBackend().get(
        `/course-subscription-plan/${id}`
      );

      return dataCourseSubscription;
    } catch (error) {
      throw error;
    }
  },

  update: async (id, payload) => {
    try {
      const { data: dataCourseSubscription } = await axiosBackend().patch(
        `/courses/${id}`,
        payload
      );

      return dataCourseSubscription;
    } catch (error) {
      throw error;
    }
  },

  getSubscriptionsTransactions: async ({ subscription_id, page, count }) => {
    try {
      const { data: transactionsData } = await axiosBackend().get(
        `/course-subscription/${subscription_id}/transactions${page ? `?page=${page}` : ''}${
          count ? `&count=${count}` : ''
        }`
      );

      return transactionsData;
    } catch (error) {
      throw error;
    }
  },
};

export default CourseSubscriptionPlanAPI;
