import { Button } from '@chakra-ui/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdContentCopy as CopyIcon } from 'react-icons/md';
import { useToast } from '@chakra-ui/react';

interface CopyButtonProps {
  textToCopy: string;
  onCopy?(text: string, result: boolean): void;
  isPurchaseByPixConfirmed: boolean;
}

function CopyButton({ textToCopy, isPurchaseByPixConfirmed }: CopyButtonProps) {
  const toast = useToast();

  function handleCopy(text: string, result: boolean) {
    if (result && text)
      toast({
        title: 'Código PIX copiado com sucesso!',
        status: 'success',
        position: 'top',
      });
  }

  const isDisabled = !isPurchaseByPixConfirmed || !textToCopy;

  return (
    <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
      <Button
        w="full"
        size="sm"
        colorScheme="default"
        variant="outline"
        leftIcon={<CopyIcon />}
        isDisabled={isDisabled}
        _disabled={{
          color: 'white',
          bg: '#8f9091',
          pointerEvents: 'none',
        }}
      >
        Copiar código
      </Button>
    </CopyToClipboard>
  );
}

export default CopyButton;
