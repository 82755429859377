import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ISegmentationSelect } from '../..';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  segmentationSelect: ISegmentationSelect;
  setSegmentationSelect: (currentSegmentation: ISegmentationSelect) => void;
  isSubmitting: boolean;
  handleSubmit: () => void;
}

export function ModalDeleteSegmentation({
  isOpen,
  onClose,
  segmentationSelect,
  setSegmentationSelect,
  isSubmitting,
  handleSubmit,
}: IProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSegmentationSelect({ id: null, name: '' });
        onClose();
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="xl">Excluir segmentação</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="md" color="#202123">
            Você tem certeza que deseja excluir a segmentação{' '}
            <Text as="span" fontWeight={600}>
              {segmentationSelect?.name}
            </Text>
            ?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            size="sm"
            onClick={() => {
              setSegmentationSelect({ id: null, name: '' });
              onClose();
            }}
          >
            Não
          </Button>
          <Button
            size="sm"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Sim
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
