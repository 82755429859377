import { Avatar, Card, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { MdOutlinePermIdentity } from 'react-icons/md';
import { CurrentLeadType } from '..';

interface UserCardProps {
  lead: CurrentLeadType;
  totalConversions: number;
}

export default function LeadCardMobile({ lead, totalConversions }: UserCardProps) {
  return (
    <Card width="full" boxShadow="0px 4px 4px 0px #00000040">
      <VStack padding="10px">
        <VStack gap="5px">
          <Avatar
            width="87px"
            height="87px"
            borderRadius="6.92px"
            background="#C4C4C4"
            color="#20212380"
            icon={<MdOutlinePermIdentity fontSize="36px" />}
            src={lead?.photo}
          />

          <Heading fontSize="16px" fontWeight="600" lineHeight="19.5px">
            {lead?.fullName}
          </Heading>
        </VStack>

        <HStack fontSize="14px" spacing="2px">
          <Text fontWeight="600" color="default.500">
            {totalConversions}
          </Text>
          <Text color="#202123" fontWeight="500">
            Conversões
          </Text>
        </HStack>

        <VStack gap="3px">
          <HStack fontSize="14px">
            <Text fontWeight="600">Telefone:</Text>
            <Text fontWeight="400">{lead?.phone}</Text>
          </HStack>

          <HStack fontSize="14px">
            <Text fontWeight="600">Email:</Text>
            <Text fontWeight="400">{lead?.email}</Text>
          </HStack>
        </VStack>
      </VStack>
    </Card>
  );
}
