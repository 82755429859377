import { useCallback, useEffect, useMemo, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { useHistory, useLocation } from 'react-router-dom';
import CourseAPI from '../../../api/Course';
import { Heading } from '../../../components/Heading';
import Paginate from '../../../components/Paginate';
import Toast from '../../../components/Toast';
import ErrorResponse from '../../../helpers/ErrorResponse';
import { PRODUCTS_FILTER } from '../../../helpers/LocalStorageHelper';
import SearchInput from './components/SearchInput';

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Select,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { FaThList } from 'react-icons/fa';
import { axiosBackend } from '../../../api/Configuration';
import CardGrid from './components/CardGrid';
import CardList from './components/CardList';
import DeleteCourseModal from './components/DeleteCourseModal';
import DuplicateCourseModal from './components/DuplicateCourseModal';

const PER_PAGE = 10;

export type StatusType = 'ATIVO' | 'INATIVO' | 'EM_EDICAO';

export interface ICourse {
  productId: number;
  productName: string;
  productThumbnail: string;
  productStatus: StatusType;
}

export default function Courses() {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  localStorage.setItem(PRODUCTS_FILTER, urlSearchParams.toString());

  const statusQuery = urlSearchParams.get('status');
  const searchQuery = urlSearchParams.get('search') ?? '';
  const orderByQuery = urlSearchParams.get('orderBy');
  const currentPage = Number(urlSearchParams.get('page')) || 1;

  const [filter, setFilter] = useState({
    search: searchQuery ?? '',
    status: statusQuery ?? '',
    orderBy: orderByQuery ?? 'most-recent',
  });

  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [viewMode, setViewMode] = useState('list');
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [courseIdToDelete, setCourseIdToDelete] = useState(0);
  const [courseIdToDuplicate, setCourseIdToDuplicate] = useState(0);

  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();

  const {
    isOpen: isOpenCourseDuplicate,
    onOpen: onOpenCourseDuplicate,
    onClose: onCloseCourseDuplicate,
  } = useDisclosure();

  const getCourses = useCallback(
    async (page, per_page) => {
      if (!page) return;

      setIsLoading(true);

      try {
        const coursesResponse = await CourseAPI.products({
          page,
          per_page,
          search: filter.search,
          status: filter.status,
          orderBy: filter.orderBy,
        });

        const coursesData = coursesResponse?.data;

        if (coursesData?.data) {
          setCourses(coursesData?.data);
          setFilteredCourses(coursesData?.data);
          setPageCount(coursesData?.total / coursesData?.per_page);
        } else {
          setFilteredCourses([]);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    },
    [filter.orderBy, filter.search, filter.status]
  );

  useEffect(() => {
    getCourses(currentPage, PER_PAGE);
  }, [currentPage, urlSearchParams, getCourses]);

  function handlePageChange(selectedItem) {
    const newPage = selectedItem.selected + 1;

    urlSearchParams.set('page', newPage.toString());

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    history.push({
      search: urlSearchParams.toString(),
    });
  }

  function handleFilterChange(event) {
    const { name, value } = event.target;

    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));

    urlSearchParams.set(name, value);
    urlSearchParams.set('page', '1');

    // Remove queris aquelas cujos valores são vazios ou nulos
    urlSearchParams.forEach((paramValue, paramName) => {
      if (!paramValue) {
        urlSearchParams.delete(paramName);
      }
    });

    history.push({
      pathname: '/products',
      search: urlSearchParams.toString(),
    });
  }

  const handleSearchChange = useCallback(
    search => {
      setFilter(prevFilter => ({ ...prevFilter, search }));

      urlSearchParams.set('page', '1');

      if (search) {
        urlSearchParams.set('search', search);
      } else {
        urlSearchParams.delete('search');
      }

      history.push({
        pathname: '/products',
        search: urlSearchParams.toString(),
      });
    },

    [history, urlSearchParams]
  );

  function onDelete(id: number) {
    setFilteredCourses(prevCourses => prevCourses.filter(course => course.productId !== id));
  }

  async function onDuplicateCourse() {
    try {
      setIsDisabled(true);
      await axiosBackend().post(`/courses/${courseIdToDuplicate}/duplicate`);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      onCloseCourseDuplicate();
      setIsDisabled(false);

      if (urlSearchParams.get('page') !== '1') {
        urlSearchParams.set('page', '1');

        return history.push({
          pathname: '/courses',
          search: urlSearchParams.toString(),
        });
      }

      getCourses(1, PER_PAGE);
    }
  }

  async function onConfirmDelete() {
    try {
      setIsDisabled(true);
      await CourseAPI.delete(courseIdToDelete);
      onDelete(courseIdToDelete);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      onCloseConfirmDelete();
      setIsDisabled(false);
    }
  }

  function redirectToNewProductPage() {
    history.push('/courses/new');
  }

  function toggleViewMode() {
    setViewMode(prevMode => (prevMode === 'list' ? 'grid' : 'list'));
  }

  const hasRegisteredCourses = courses?.length > 0 && !isLoading;
  const hasFilteredCourses = filteredCourses?.length > 0 && !isLoading;
  const isGridMode = viewMode === 'grid';
  const isListMode = viewMode === 'list';
  const isHiddenPagination = !hasFilteredCourses;

  return (
    <>
      <Flex align="center" justify="space-between" flexDirection={{ base: 'column', lg: 'row' }}>
        <Heading
          my={{ base: 0, md: 2, lg: 2 }}
          fontSize={{ base: '2xl', md: '2xl', lg: '4xl' }}
          display="flex"
          width="full"
        >
          Produtos Online
        </Heading>
        <Button
          onClick={redirectToNewProductPage}
          leftIcon={<BiPlus size="1rem" />}
          colorScheme="primary"
          color="secondary.500"
          width={{ base: 'full', lg: 44 }}
          mt={{ base: 8, lg: 0 }}
          size="sm"
        >
          Criar Produto
        </Button>
      </Flex>

      {!hasRegisteredCourses && (
        <Flex width="full" justifyContent="center" my={10}>
          <Heading fontSize="18px" color="#20212350">
            Aqui você pode criar, editar e gerenciar seus produtos, de forma eficiente e objetiva,
            em um só lugar! Clique no botão “criar produto” e inicie seu sucesso no online com um
            produto completo.
          </Heading>
        </Flex>
      )}

      {hasRegisteredCourses && (
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ base: 'column-reverse', lg: 'row' }}
          mt={{ base: 0, lg: 8 }}
        >
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: 2, lg: 3 }}
            mt={{ base: 4, lg: 0 }}
          >
            <Text
              width={{ base: 'full', lg: 'auto' }}
              alignSelf="center"
              textAlign={{ base: 'start', lg: 'center' }}
              fontWeight={600}
            >
              Filtros:
            </Text>

            <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 2, md: 3 }}>
              <Select
                size="sm"
                width={{ base: 'full', lg: 'auto' }}
                borderRadius={6}
                placeholder="Todos os Status"
                color="#20212350"
                focusBorderColor="primary.500"
                name="status"
                id="status"
                onChange={handleFilterChange}
                value={filter.status}
                isDisabled={isLoading}
              >
                <option value="ATIVO">Ativo</option>
                <option value="EM_EDICAO">Em edição</option>
                <option value="INATIVO">Inativo</option>
                <option value="NAO_LISTADO">Não Listado</option>
              </Select>
              <Select
                size="sm"
                width={{ base: 'full', lg: 'auto' }}
                borderRadius={6}
                placeholder="Ordem"
                color="#20212350"
                focusBorderColor="primary.500"
                name="orderBy"
                id="orderBy"
                onChange={handleFilterChange}
                value={filter.orderBy}
                my={{ base: 1, md: 0, lg: 0 }}
                isDisabled={isLoading}
              >
                <option value="most-recent">Mais Recentes</option>
                <option value="older">Mais Antigos</option>
                <option value="alphabetical-a-z">Alfabética A-Z</option>
                <option value="alphabetical-z-a">Alfabética Z-A</option>
              </Select>
            </Stack>
          </Stack>

          <Flex
            align="center"
            direction={{ base: 'row-reverse', lg: 'row' }}
            mt={{ base: 8, lg: 0 }}
            gap={5}
          >
            <HStack spacing="2" cursor={isLoading ? 'not-allowed' : 'default'}>
              <Box display="flex" gap="13px" marginX={{ base: 0, md: 0, lg: 5 }}>
                <Icon
                  as={FaThList}
                  boxSize="20px"
                  onClick={toggleViewMode}
                  cursor="pointer"
                  color={isListMode ? 'primary.500' : '#20212350'}
                />
                <Icon
                  as={BsFillGrid3X3GapFill}
                  boxSize="20px"
                  onClick={toggleViewMode}
                  cursor="pointer"
                  color={isGridMode ? 'primary.500' : '#20212350'}
                />
              </Box>
            </HStack>

            <SearchInput value={searchQuery} onChange={handleSearchChange} />
          </Flex>
        </Box>
      )}

      {isLoading && (
        <Flex mt="10" flexDirection="column" alignItems="center">
          <Spinner size="xl" color="primary.500" />
        </Flex>
      )}

      {!hasFilteredCourses && (
        <Box display="flex" width="full" justifyContent="center" my={10}>
          <Heading fontSize="lg">Nenhum produto encontrado.</Heading>
        </Box>
      )}

      {hasFilteredCourses && (
        <>
          {isListMode &&
            filteredCourses.map(course => (
              <CardList
                key={course.productId}
                course={course}
                setCourseIdToDelete={setCourseIdToDelete}
                onOpenConfirmDelete={onOpenConfirmDelete}
                setCourseIdToDuplicate={setCourseIdToDuplicate}
                onOpenCourseDuplicate={onOpenCourseDuplicate}
              />
            ))}

          {isGridMode && (
            <Grid
              mt={8}
              mb={2}
              templateColumns={{
                base: 'repeat(2, 1fr)',
                sm: 'repeat(3, 1fr)',
                xl: 'repeat(4, 1fr)',
              }}
              gap={{ base: 3, lg: 6 }}
            >
              {filteredCourses.map(course => (
                <GridItem key={course.productId}>
                  <CardGrid
                    course={course}
                    setCourseIdToDelete={setCourseIdToDelete}
                    onOpenConfirmDelete={onOpenConfirmDelete}
                    setCourseIdToDuplicate={setCourseIdToDuplicate}
                    onOpenCourseDuplicate={onOpenCourseDuplicate}
                  />
                </GridItem>
              ))}
            </Grid>
          )}
        </>
      )}

      <Box mt={4} hidden={isHiddenPagination}>
        <Paginate pageCount={pageCount} initialPage={currentPage} onPageChange={handlePageChange} />
      </Box>

      <DuplicateCourseModal
        isOpen={isOpenCourseDuplicate}
        onClose={onCloseCourseDuplicate}
        isDisabled={isDisabled}
        onSubmit={onDuplicateCourse}
      />

      <DeleteCourseModal
        isOpen={isOpenConfirmDelete}
        onClose={onCloseConfirmDelete}
        isDisabled={isDisabled}
        onSubmit={onConfirmDelete}
      />
    </>
  );
}
