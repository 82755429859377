import Joi from 'joi';

export const areaSchemaValidator = Joi.object({
  id: Joi.number().messages({
    'number.empty': 'O ID não pode ficar vazio.',
    'number.base': 'O ID deve ser um número.',
  }),
  name: Joi.string().max(50).required().messages({
    'string.empty': 'O nome não pode ficar vazio.',
    'string.base': 'O nome deve ser um texto.',
    'string.max': 'O nome deve ter no máximo 30 caracteres.',
  }),
});

export const disciplineSchemaValidator = Joi.object({
  id: Joi.number().messages({
    'number.empty': 'O ID não pode ficar vazio.',
    'number.base': 'O ID deve ser um número.',
  }),
  name: Joi.string().max(50).required().messages({
    'string.empty': 'O nome não pode ficar vazio.',
    'string.base': 'O nome deve ser um texto.',
    'string.max': 'O nome deve ter no máximo 30 caracteres.',
  }),
  areaId: Joi.number().min(1).required().messages({
    'number.empty': 'A Área não pode ficar vazia.',
    'number.base': 'Selecione uma Área válida.',
    'number.min': 'Selecione uma Área válida.',
    'any.required': 'A Área é obrigatória.',
  }),
});

export const subjectSchemaValidator = Joi.object({
  id: Joi.number().messages({
    'number.empty': 'O ID não pode ficar vazio.',
    'number.base': 'O ID deve ser um número.',
  }),
  name: Joi.string().max(50).required().messages({
    'string.empty': 'O nome não pode ficar vazio.',
    'string.base': 'O nome deve ser um texto.',
    'string.max': 'O nome deve ter no máximo 30 caracteres.',
  }),
  disciplineId: Joi.number().min(1).required().messages({
    'number.empty': 'A disciplina não pode ficar vazia.',
    'number.base': 'Selecione uma disciplina válida.',
    'number.min': 'Selecione uma disciplina válida.   ',
    'any.required': 'A disciplina é obrigatória.',
  }),
});

export const institutionSchemaValidator = Joi.object({
  id: Joi.number().messages({
    'number.empty': 'O ID não pode ficar vazio.',
    'number.base': 'O ID deve ser um número.',
  }),
  name: Joi.string().max(100).required().messages({
    'string.empty': 'O nome não pode ficar vazio.',
    'string.base': 'O nome deve ser um texto.',
    'string.max': 'O nome deve ter no máximo 100 caracteres.',
  }),
});
