import { axiosBackend } from './Configuration';

type Question = {
  questionText: string;
  commentary: string;
  order?: number;
};

type Option = {
  answerOption: string;
  correct: boolean;
};

type QuestionOrders = {
  question_id: number;
  order: number;
};

export default {
  index: async (course_id: number, module_id: number, content_id: number) => {
    try {
      const { data: questionsData } = await axiosBackend().get(
        `/questions/${course_id}/course/${module_id}/module/${content_id}/content`
      );

      return questionsData;
    } catch (error) {
      throw error;
    }
  },

  show: async (course_id: number, module_id: number, content_id: number, question_id: number) => {
    try {
      const { data: questionData } = await axiosBackend().get(
        `/questions/${course_id}/course/${module_id}/module/${content_id}/content/${question_id}/question`
      );

      return questionData;
    } catch (error) {
      throw error;
    }
  },

  store: async (
    course_id: number,
    module_id: number,
    content_id: number,
    newQuestion: Question
  ) => {
    try {
      const { data: questionData } = await axiosBackend().post(
        `/questions/${course_id}/course/${module_id}/module/${content_id}/content`,
        newQuestion
      );

      return questionData;
    } catch (error) {
      throw error;
    }
  },

  update: async (
    course_id: number,
    module_id: number,
    content_id: number,
    question_id: number,
    payload: Question
  ) => {
    try {
      const { data: questionData } = await axiosBackend().patch(
        `/questions/${course_id}/course/${module_id}/module/${content_id}/content/${question_id}/question`,
        payload
      );

      return questionData;
    } catch (error) {
      throw error;
    }
  },

  delete: async (course_id: number, module_id: number, content_id: number, question_id: number) => {
    try {
      const { data: questionData } = await axiosBackend().delete(
        `/questions/${course_id}/course/${module_id}/module/${content_id}/content/${question_id}/question`
      );

      return questionData;
    } catch (error) {
      throw error;
    }
  },

  duplicate: async (
    course_id: number,
    module_id: number,
    content_id: number,
    question_id: number
  ) => {
    try {
      const { data: questionData } = await axiosBackend().post(
        `/questions/${course_id}/course/${module_id}/module/${content_id}/content/${question_id}/question/duplicate-question`
      );
      return questionData;
    } catch (error) {
      throw error;
    }
  },
  updateQuestionsOrder: async (
    courseId: number,
    moduleId: number,
    contentId: number,
    questionOrders: QuestionOrders
  ) => {
    try {
      await axiosBackend().patch(
        `/questions/${courseId}/course/${moduleId}/module/${contentId}/content/order-questions`,
        { questionOrders }
      );
    } catch (error) {
      throw error;
    }
  },
  importQuestions: async (
    courseId: number,
    moduleId: number,
    contentId: number,
    payload: any,
    content: any,
    contentData: any
  ) => {
    const formData = new FormData();
    formData.append('file', payload);

    if (content && !content.id) {
      Object.keys(contentData).forEach(key => formData.append(key, contentData[key]));
    }

    try {
      const { data: contentData } = await axiosBackend().post(
        `/questions/${courseId}/course/${moduleId}/module/${contentId}/content/import-questions`,
        formData
      );

      return contentData;
    } catch (error) {
      throw error;
    }
  },
  student: {
    index: async (
      course_id: number | string,
      module_id: number | string,
      content_id: number | string,
      page: number
    ) => {
      try {
        const { data: questionsData } = await axiosBackend().get(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/student${
            page ? `?page=${page}` : ``
          }`
        );

        return questionsData;
      } catch (error) {
        throw error;
      }
    },

    start: async (
      course_id: number | string,
      module_id: number | string,
      content_id: number | string
    ) => {
      try {
        const { data: questionsData } = await axiosBackend().post(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/start-question`
        );

        return questionsData;
      } catch (error) {
        throw error;
      }
    },

    verifyIsFinished: async (
      course_id: number | string,
      module_id: number | string,
      content_id: number | string
    ) => {
      try {
        const { data: questionsData } = await axiosBackend().get(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/status-question`
        );

        return questionsData;
      } catch (error) {
        throw error;
      }
    },

    finish: async (
      course_id: number | string,
      module_id: number | string,
      content_id: number | string
    ) => {
      try {
        const { data: questionsData } = await axiosBackend().patch(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/finish-question`
        );

        return questionsData;
      } catch (error) {
        throw error;
      }
    },

    update: async (
      course_id: number | string,
      module_id: number | string,
      content_id: number | string,
      question_id: number | string,
      option_id: number
    ) => {
      try {
        const { data: questionData } = await axiosBackend().patch(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/${question_id}/question/answer`,
          { option_id }
        );

        return questionData;
      } catch (error) {
        throw error;
      }
    },

    getStatistics: async (
      course_id: number | string,
      module_id: number | string,
      content_id: number | string
    ) => {
      try {
        const { data: response } = await axiosBackend().get(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/student-use-statistics`
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getResultByQuestions: async (
      course_id: number | string,
      module_id: number | string,
      content_id: number | string,
      page: number
    ) => {
      try {
        const { data: response } = await axiosBackend().get(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/report-individual-student${
            page ? `?page=${page}` : ``
          }`
        );

        return response.data;
      } catch (error) {
        throw error;
      }
    },

    checkAnswers: async (
      course_id: number | string,
      module_id: number | string,
      content_id: number | string
    ) => {
      try {
        const { data: isAllQuestionsAnswered } = await axiosBackend().get(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/student/check-answers`
        );

        return isAllQuestionsAnswered;
      } catch (error) {
        throw error;
      }
    },
  },

  optionQuestion: {
    store: async (
      course_id: number,
      module_id: number,
      content_id: number,
      question_id: number,
      newOption: Option
    ) => {
      try {
        const { data: optionData } = await axiosBackend().post(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/${question_id}/question/option`,
          newOption
        );

        return optionData;
      } catch (error) {
        throw error;
      }
    },

    update: async (
      course_id: number,
      module_id: number,
      content_id: number,
      question_id: number,
      option_id: number,
      payload: Option
    ) => {
      try {
        const { data: optionData } = await axiosBackend().patch(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/${question_id}/question/${option_id}/option`,
          payload
        );

        return optionData;
      } catch (error) {
        throw error;
      }
    },

    delete: async (
      course_id: number,
      module_id: number,
      content_id: number,
      question_id: number,
      option_id: number
    ) => {
      try {
        const { data: optionData } = await axiosBackend().delete(
          `/questions/${course_id}/course/${module_id}/module/${content_id}/content/${question_id}/question/${option_id}/option`
        );

        return optionData;
      } catch (error) {
        throw error;
      }
    },
  },

  reportPerformanceStudent: async (
    course_id: number,
    module_id: number,
    content_id: number,
    page: number,
    per_page: number,
    search: string
  ) => {
    try {
      const { data: performanceStudentData } = await axiosBackend().get(
        `/questions/${course_id}/course/${module_id}/module/${content_id}/content/report-performance-student${
          page ? `?page=${page}` : `?page=${1}`
        }${per_page && `&per_page=${per_page}`}${search && `&search=${search}`}`
      );

      return performanceStudentData;
    } catch (error) {
      throw error;
    }
  },

  reportPerformanceQuestions: async (
    course_id: number,
    module_id: number,
    content_id: number,
    page: number,
    per_page: number
  ) => {
    try {
      const { data: performanceQuestionsData } = await axiosBackend().get(
        `/questions/${course_id}/course/${module_id}/module/${content_id}/content/report-performance-questions${
          page ? `?page=${page}` : `?page=${1}`
        }${per_page && `&per_page=${per_page}`}`
      );

      return performanceQuestionsData;
    } catch (error) {
      throw error;
    }
  },

  exportQuestionPerformance: async (
    course_id: number,
    module_id: number,
    content_id: number,
    type_file: 'CSV' | 'Excel'
  ) => {
    try {
      const urlDownload = `/questions/${course_id}/course/${module_id}/module/${content_id}/content/export-question-performance?typeFile=${type_file}`;

      const axios = axiosBackend();

      const response = await axios({
        url: urlDownload,
        method: 'GET',
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        type_file === 'CSV' ? 'relatório-de-questões.csv' : 'relatório-de-questões.xlsx'
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw error;
    }
  },

  exportPerformance: async (
    course_id: number,
    module_id: number,
    content_id: number,
    type_file: 'CSV' | 'Excel',
    search?: string
  ) => {
    try {
      let urlDownload = `/questions/${course_id}/course/${module_id}/module/${content_id}/content/export-student-performance?typeFile=${type_file}`;

      if (search) {
        urlDownload = `/questions/${course_id}/course/${module_id}/module/${content_id}/content/export-student-performance?typeFile=${type_file}&search=${search}`;
      }

      const axios = axiosBackend();

      const response = await axios({
        url: urlDownload,
        method: 'GET',
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        type_file === 'CSV' ? 'relatório-de-desempenho.csv' : 'relatório-de-desempenho.xlsx'
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw error;
    }
  },
};
