import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';

import { MdAddCircleOutline } from 'react-icons/md';
import APIPageBuilder from '../../../../api/PageBuilder';
import ThemeAPI from '../../../../api/Theme';
import AlertModal from '../../../../components/AlertModal';
import { Heading } from '../../../../components/Heading';
import Spinner from '../../../../components/Loadings/Spinner';
import Toast from '../../../../components/Toast';
import { useTheme } from '../../../../contexts/ThemeContext';
import ErrorResponse from '../../../../helpers/ErrorResponse';
import scrollIntoView from '../../../../helpers/scrollIntoView';
import { sortByOrder } from '../../../../helpers/SortHelper';
import UploadHelper from '../../../../helpers/UploadHelper';
import BannerInput from '../components/BannerInput';
import BannerMobileInput from '../components/BannerMobileInput';
import DropdownSection from '../components/DropdownSection';
import ErrorMessage from '../components/ErrorMessage';
import ImagePreview from '../components/ImagePreview';
import Line from '../components/Line';
import MobilePreview from '../components/MobilePreview';
import Tooltip from '../components/Tooltip';
import Section from '../Section';
import styles from './styles.module.css';

const FIRST_FILE = 0;
const MAX_SIZE_FILE_PHOTO = 3145728;

function MainBanner({ section }) {
  const [isActiveSection, setIsActiveSection] = useState(null);

  const [desktopPreview, setDesktopPreview] = useState(null);
  const [mobilePreview, setMobilePreview] = useState(null);

  const [banners, setBanners] = useState([]);

  // const [bannerToShow, setBannerToShow] = useState(1);

  const [currentBanner, setCurrentBanner] = useState(null);

  const [selectedBanner, setSelectedBanner] = useState(0);

  const [hasChanged, setHasChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [errorUploadPhoto, setErrorUploadPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

  const {
    theme,
    getTheme,
    getThemeMarketplace,
    deleteBannerHero,
    isLoading: isLoadingTheme,
  } = useTheme();

  useEffect(() => {
    if (!isLoadingTheme) {
      const mainBannerSection = section;

      setIsActiveSection(!!mainBannerSection.active);

      const banners = mainBannerSection.themeBannerHero;
      setBanners(banners);

      const firstBanner = banners[0];

      setCurrentBanner(firstBanner);

      setDesktopPreview(firstBanner?.banner);
      setMobilePreview(firstBanner?.bannerMobile);
    }
  }, [isLoadingTheme, section]);

  useEffect(() => {
    const currentBanner = banners[selectedBanner];

    const existingOrder = banners.find(banner => banner.order === selectedBanner);

    if (currentBanner && existingOrder && existingOrder.banner) {
      setCurrentBanner(currentBanner);
      setDesktopPreview(currentBanner.banner);
      setMobilePreview(currentBanner.bannerMobile);
    }
  }, [selectedBanner, banners]);

  function handleInputChange(event) {
    let { name, value } = event.target;

    setErrorMessage(null);
    setHasChanged(true);

    if (name === 'order') value = Number(value);

    setCurrentBanner(prevBanner => ({ ...prevBanner, [name]: value }));
  }

  function toggleVisibilityBanner(event) {
    const { checked } = event.target;

    setErrorMessage(null);
    setHasChanged(true);

    setCurrentBanner(prevBanner => ({ ...prevBanner, active: checked }));
  }

  function handlePhotoChange(acceptedFiles) {
    const file = acceptedFiles[FIRST_FILE];

    if (file?.size > MAX_SIZE_FILE_PHOTO) {
      return setErrorUploadPhoto('O tamanho da imagem deve ser de no máximo 3 MB.');
    }

    if (file) {
      setDesktopPreview(URL.createObjectURL(file));

      setErrorUploadPhoto(null);
      setErrorMessage(null);
      setHasChanged(true);

      setCurrentBanner(prevBanner => ({ ...prevBanner, banner: file }));
    }
  }

  function handleMobilePhotoChange(acceptedFiles) {
    const mobileFile = acceptedFiles[FIRST_FILE];

    if (mobileFile?.size > MAX_SIZE_FILE_PHOTO) {
      return setErrorUploadPhoto('O tamanho da imagem deve ser de no máximo 3 MB.');
    }

    if (mobileFile) {
      setMobilePreview(URL.createObjectURL(mobileFile));
      setErrorUploadPhoto(null);
      setErrorMessage(null);
      setHasChanged(true);

      setCurrentBanner(prevBanner => ({ ...prevBanner, bannerMobile: mobileFile }));
    }
  }

  function handleDiscardChanges(event, bannersArray) {
    event.preventDefault();

    if (isAdding) {
      bannersArray.pop();
    }

    setHasChanged(false);

    const mainBannerSection = section;
    const banners = mainBannerSection.themeBannerHero;

    setBanners(banners);

    const firstBanner = banners[0];
    setCurrentBanner(firstBanner);

    setDesktopPreview(firstBanner?.banner);
    setMobilePreview(firstBanner?.bannerMobile);

    setErrorUploadPhoto(null);
    setErrorMessage(null);

    setIsAdding(false);
  }

  function handleSelectedBannerChange(event) {
    const { value } = event.target;

    setErrorUploadPhoto(null);
    setErrorMessage(null);

    const currentBanner = banners[value];

    if (currentBanner) {
      setCurrentBanner(currentBanner);
      setDesktopPreview(currentBanner.banner);
      setMobilePreview(currentBanner.bannerMobile);
    }

    setSelectedBanner(value);
  }

  function handleImageDropRejected() {
    setErrorUploadPhoto('Arquivo inválido!');
  }

  function handleImageChange() {
    setHasChanged(true);
    setDesktopPreview(null);
  }

  function handleMobileImageChange() {
    setHasChanged(true);
    setMobilePreview(null);
  }

  function addBanner() {
    setHasChanged(true);

    setIsAdding(true);
    setDesktopPreview(null);
    setMobilePreview(null);

    const orders = banners.map(banner => banner.order);

    const missingNumbers = [];

    for (let index = 0; index < orders.length; index++) {
      const element = orders[index];

      const nextElement = index + 1 + missingNumbers.length;

      const existingElement = orders.find(element => element === nextElement);

      if (element !== nextElement && !existingElement) {
        missingNumbers.push(nextElement);
      }
    }

    let order = 1;

    const minOrder = Math.min(...missingNumbers);

    if (minOrder !== Infinity) {
      order = minOrder;
    } else {
      order = [...orders, ...missingNumbers].length + 1;
    }

    const currentBanner = { banner: null, order, link: '', active: true };

    banners.push(currentBanner);

    setSelectedBanner(order);

    setCurrentBanner(currentBanner);
  }

  async function handleDelete(event) {
    event.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);
    setErrorUploadPhoto(null);

    try {
      await deleteBannerHero(theme.id, currentBanner.id, currentBanner.id);

      Toast('Banner excluído com sucesso');
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
    } finally {
      setHasChanged(false);
      setIsLoading(false);
      setIsShowDeleteModal(false);
    }
  }

  function toggleModal(event) {
    event.preventDefault();
    setIsShowDeleteModal(!isShowDeleteModal);
  }

  function toggleVisibilitySection(event) {
    const { checked } = event.target;

    setHasChanged(true);

    setIsActiveSection(!checked);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!currentBanner.banner) return setErrorUploadPhoto('Você deve escolher uma foto');

    setIsLoading(true);

    const { id } = currentBanner;
    const method = id ? 'update' : 'create';

    const payload = {
      ...currentBanner,
      active: !!currentBanner.active,
      activeSection: isActiveSection,
      sectionsToShow: 1, //bannerToShow,
    };

    try {
      if (payload.banner && typeof payload.banner !== 'string') {
        const { newFileName: newFileNameBanner, link } = await UploadHelper.upload(
          payload.banner,
          'banners'
        );

        payload.banner = encodeURI(newFileNameBanner);

        setDesktopPreview(link);
      }

      if (payload.bannerMobile && typeof payload.bannerMobile !== 'string') {
        const { newFileName: newFileNameBanner, link } = await UploadHelper.upload(
          payload.bannerMobile,
          'banners'
        );

        payload.bannerMobile = encodeURI(newFileNameBanner);

        setMobilePreview(link);
      }

      if (method === 'create') {
        await ThemeAPI.createBannerHero(theme.id, { ...payload, sectionId: section.id });
      }

      if (method === 'update') {
        await ThemeAPI.updateBannerHero(theme.id, section.id, currentBanner.id, payload);
      }

      const successMessage = method === 'create' ? 'criado' : 'atualizado';

      Toast(`Banner ${successMessage} com sucesso`);
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      setHasChanged(false);
      setIsLoading(false);
      setIsAdding(false);
    }
  }

  async function handleDuplicateSectionBannerHero() {
    setErrorMessage('');

    try {
      const theme_id = theme.id;

      const section_id = section.id;

      await APIPageBuilder.bannerHero.store(theme_id, section_id);

      Toast('Seção Banner Principal duplicada com sucesso!');

      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      scrollIntoView('#errorMessage', true);
    } finally {
      setHasChanged(false);
    }
  }

  async function handleDeleteSectionBannerHero() {
    setErrorMessage('');

    try {
      const theme_id = theme.id;

      const section_id = section.id;

      await APIPageBuilder.bannerHero.delete(theme_id, section_id);

      Toast('Seção Banner Principal deletada com sucesso!');

      await getTheme();
      await getThemeMarketplace();
    } catch (error) {
      setErrorMessage(ErrorResponse(error));
      scrollIntoView('#errorMessage', false);
    } finally {
      setHasChanged(false);
    }
  }

  const currentBannerLinkId = nanoid();
  const currentBannerOrderId = nanoid();

  return (
    <Section>
      <div className={styles.sectionHeader}>
        <div className="d-flex align-items-center">
          <Heading as="h4" fontSize="2xl">
            Banner principal
          </Heading>
          <Line />
          <Tooltip link="" />
        </div>

        <div className="d-flex align-items-center">
          <div className={styles.actionsHeaderContainer}>
            <button onClick={addBanner} disabled={isAdding} style={{ fontSize: '0.75rem' }}>
              <MdAddCircleOutline size="1.125rem" /> Adicionar banner
            </button>
          </div>

          <DropdownSection
            onClickToDuplicate={handleDuplicateSectionBannerHero}
            onClickToDelete={handleDeleteSectionBannerHero}
          />
        </div>
      </div>

      <div>
        <div className={styles.headerText}>
          <p>
            Você pode realizar várias ações com os banners, por exemplo, adicionar um link que
            direciona para algum produto, definir a ordem de exibição ou exibir mais de uma por vez.
            Caso não deseje mais que determinado banner apareça basta ocultá-lo ou simplesmente
            excluí-lo.
          </p>
        </div>

        <div className={styles.imageDimensions}>
          <p>
            Dimensões recomendadas:<strong> 1920 x 840 </strong> pixels;
          </p>
        </div>
      </div>

      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <section className={styles.formSection}>
            {!isAdding && (
              <div className={styles.inputGroup}>
                <label htmlFor="selectedBanner">Selecione um banner para editar:</label>
                <select
                  id="selectedBanner"
                  name="selectedBanner"
                  onChange={handleSelectedBannerChange}
                  disabled={isAdding}
                  value={selectedBanner}
                >
                  {banners?.map((banner, index) => (
                    <option key={`banner${banner.id}`} value={index}>
                      Banner {index + 1}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <section className={styles.bannerImage}>
              <div className={styles.banner}>
                <label>Versão desktop:</label>
                {!desktopPreview ? (
                  <BannerInput
                    preview={desktopPreview}
                    theme={theme}
                    onDrop={handlePhotoChange}
                    onDropRejected={handleImageDropRejected}
                    onChangeImage={handleImageChange}
                    isDisabled={!banners?.length > 0}
                    imageDimensions="1920x840"
                  />
                ) : (
                  <ImagePreview
                    preview={desktopPreview}
                    onClick={handleImageChange}
                    isLoading={isLoadingTheme}
                  />
                )}
              </div>
              <div className={styles.bannerMobile}>
                <label>Versão mobile:</label>
                {!mobilePreview ? (
                  <BannerMobileInput
                    mobilePreview={mobilePreview}
                    theme={theme}
                    onDrop={handleMobilePhotoChange}
                    onDropRejected={handleImageDropRejected}
                    onChangeImage={handleMobileImageChange}
                    isDisabled={!banners?.length > 0}
                    imageDimensions="1080x1920"
                  />
                ) : (
                  <MobilePreview
                    mobilePreview={mobilePreview}
                    onClick={handleMobileImageChange}
                    isLoading={isLoadingTheme}
                  />
                )}
              </div>

              {errorUploadPhoto && <ErrorMessage message={errorUploadPhoto} />}
            </section>

            <div className={styles.inputGroup}>
              <label htmlFor={currentBannerLinkId}>
                Para onde você quer que o usuário da sua plataforma seja redirecionado ao clicar em
                um banner?
              </label>
              <p>
                Forneça um link válido como por exemplo: cursos, palestras, landing pages, vídeos do
                YouTube, etc.
              </p>
              <input
                id={currentBannerLinkId}
                name="link"
                onChange={handleInputChange}
                value={currentBanner?.link}
                type="url"
                placeholder="Cole o link aqui"
                minLength={4}
                maxLength={255}
                disabled={!banners?.length > 0}
              />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor={currentBannerOrderId}>
                Em que ordem você quer que este banner apareça na sua página inicial?
              </label>
              <select
                id={currentBannerOrderId}
                name="order"
                onChange={handleInputChange}
                value={currentBanner?.order}
              >
                {banners?.sort(sortByOrder).map(banner => (
                  <option key={nanoid()} value={banner.order}>
                    Ordem {banner.order}
                  </option>
                ))}
              </select>
            </div>

            {/* {!isAdding && (
            <div className={styles.inputGroup}>
                <label htmlFor="sectionsToShow">
                  Quantos banners você quer exibir em sua página inicial?
                </label>

                <select
                  id="sectionsToShow"
                  name="sectionsToShow"
                  onChange={handleBannerToShowChange}
                  disabled={isAdding}
                  value={bannerToShow}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
            )} */}
          </section>

          {banners?.length > 0 && (
            <div className={styles.actions}>
              <div className={styles.inputCheckboxGroup}>
                <input
                  type="checkbox"
                  id={`isBannerActive`}
                  name={`isBannerActive`}
                  onChange={toggleVisibilityBanner}
                  checked={!!currentBanner?.active}
                />

                <label htmlFor="isBannerActive">Exibir banner na página inicial</label>
              </div>

              <div>
                {!isAdding && (
                  <button className={styles.deleteButton} onClick={toggleModal}>
                    Excluir banner
                  </button>
                )}

                <button
                  className={styles.cancelButton}
                  onClick={event => handleDiscardChanges(event, banners)}
                  disabled={!hasChanged || isLoading}
                >
                  Cancelar
                </button>

                <button type="submit" disabled={!hasChanged || isLoading}>
                  {isLoading ? <Spinner small /> : 'Salvar alterações'}
                </button>
              </div>
            </div>
          )}

          <div className={styles.footer}>
            <div>
              <input
                type="checkbox"
                id={`isActiveSectionMainBanner${section.id}`}
                name={`isActiveSectionMainBanner${section.id}`}
                onChange={toggleVisibilitySection}
                checked={!isActiveSection}
              />
              <label htmlFor={`isActiveSectionMainBanner${section.id}`}>
                Ocultar seção da página inicial
              </label>
            </div>
          </div>
        </form>
      </div>

      {errorMessage && !hasChanged && <ErrorMessage message={errorMessage} />}

      <AlertModal
        showModal={isShowDeleteModal}
        onHideModal={() => setIsShowDeleteModal(false)}
        title={`Você tem certeza que deseja excluir este banner?`}
        actionButton={{ nameActionButton: 'Excluir', onActionButton: handleDelete }}
        actionButtonClassName="btn-danger"
        cancelableButton={{
          nameCancelableButton: 'cancelar',
          onCancelableButton: () => setIsShowDeleteModal(false),
        }}
      />
    </Section>
  );
}

export default MainBanner;
