import { Heading, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import DashboardTabPanel from './DashboardTabPanel';
import PlanListTabPanel from './PlanListTabPanel';

export default function RecurrenceDashboard() {
  const location = useLocation();
  const history = useHistory();

  const activeIndex = location.pathname === '/recurrence/dashboard' ? 0 : 1;

  function handleTabsChange(index: number) {
    if (index === 0) {
      history.push('/recurrence/dashboard');
    } else {
      history.push('/recurrence/dashboard/plan-list');
    }
  }

  return (
    <>
      <Heading as="h1" fontSize={{ base: '2xl', xl: '4xl' }} fontWeight="semibold" m={0}>
        Dashboard de assinatura
      </Heading>

      <Tabs
        position="relative"
        variant="unstyled"
        mt={8}
        colorScheme="primary"
        index={activeIndex}
        onChange={handleTabsChange}
        isLazy
      >
        <TabList
          sx={{
            '.chakra-tabs__tab': {
              color: '#20212380',
              fontSize: 'lg',
              _selected: { color: 'primary.500' },
            },
          }}
        >
          <Tab>Dashboard</Tab>
          <Tab>Lista de planos</Tab>
        </TabList>

        <TabIndicator mt="-3px" height="3px" bg="primary.500" borderRadius="2px" />

        <TabPanels sx={{ '.chakra-tabs__tab-panel': { px: 0, py: 8 } }}>
          <TabPanel height="100%">
            <DashboardTabPanel />
          </TabPanel>
          <TabPanel>
            <PlanListTabPanel />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
