import { Card, Heading, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { UserType } from '..';
import RolesAPI from '../../../../api/Roles';
import Toast from '../../../../components/Toast';
import ErrorResponse from '../../../../helpers/ErrorResponse';

type propsType = {
  users: UserType[];
  onOpenEditModal: () => void;
  setUserId: (id: number) => void;
  deleteUser: (id: number) => void;
  loginAs: (id: number) => void;
  setRoleName: (roleName: string) => void;
  isUnificada: boolean;
  subscriberSuspended: boolean;
  handleDeleteUser: (id: number) => void;
  getUsers: () => UserType[];
  isEmpty: boolean;
  isNotFoundVisible: boolean;
  isUsersVisible: boolean;
};

function setBadgeStatus(status, subscriberSuspended = false) {
  const STATUS = {
    ATIVO: {
      color: '#22BB33',
      text: 'ATIVO',
    },
    BLOQUEADO: {
      color: '#BB212450',
      text: 'BLOQUEADO',
    },
    INATIVO: {
      color: '#BB2124',
      text: 'Inativo',
    },
    CONVIDADO: {
      color: '#0000ff80',
      text: 'CONVITE ENVIADO',
    },
  };

  if (subscriberSuspended) {
    return STATUS.INATIVO;
  }

  return (
    STATUS[status] || {
      colorScheme: 'blue',
      text: status,
    }
  );
}

export function CardUsers({
  users,
  onOpenEditModal,
  setUserId,
  setRoleName,
  isUnificada,
  subscriberSuspended,
  handleDeleteUser,
  getUsers,
  isEmpty,
  isNotFoundVisible,
  isUsersVisible,
  deleteUser,
  loginAs,
}: propsType) {
  async function deleteInvite(id) {
    try {
      await RolesAPI.deleteInvite(id);
      handleDeleteUser(id);
      Toast('Convite excluído com sucesso.');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    }
  }

  async function reinviteUser(email, role) {
    try {
      await RolesAPI.store(email, role);
      Toast('Convite reenviado com sucesso');
    } catch (error) {
      Toast(ErrorResponse(error), 'error');
    } finally {
      getUsers();
    }
  }

  return (
    <>
      {isEmpty && (
        <Stack direction="row" justifyContent="center" width="full">
          <Heading fontSize="16px" color="#20212380" margin="20px 10px">
            Você não tem usuários cadastrados
          </Heading>
        </Stack>
      )}

      {isNotFoundVisible && (
        <Stack direction="row" my={5} justifyContent="center" width="full">
          <Heading fontSize="16px" color="#20212380" margin="20px 10px">
            Não foi encontrado resultado para essa busca.
          </Heading>
        </Stack>
      )}

      {isUsersVisible &&
        users?.map(user => {
          const statusBadge = setBadgeStatus(user?.status, subscriberSuspended);

          return (
            <Card padding="15px" marginY="17px">
              <Stack direction="row" justifyContent="space-between" marginBottom="10px" height={35}>
                <Stack justifyContent="center">
                  <Text fontSize="16px" fontWeight={600} width={300} isTruncated>
                    {user?.email}
                  </Text>
                </Stack>

                <Stack
                  hidden={isUnificada || user?.role.name === 'DONO' || user?.role.name === 'ADMIN'}
                >
                  <Menu>
                    <MenuButton padding="3px" backgroundColor="#20212320" borderRadius={6}>
                      <BiDotsHorizontalRounded size="15px" color="rgba(32, 33, 35, 0.5)" />
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        color="#20212380"
                        fontWeight={500}
                        onClick={() => {
                          setRoleName(user?.role?.name);
                          setUserId(user?.id);
                          onOpenEditModal();
                        }}
                      >
                        Editar
                      </MenuItem>

                      {user?.status === 'CONVIDADO' ? (
                        <>
                          <MenuItem
                            color="#20212380"
                            fontWeight={500}
                            onClick={() => deleteInvite(user?.id)}
                          >
                            Deletar convite
                          </MenuItem>
                          <MenuItem
                            color="#20212380"
                            fontWeight={500}
                            onClick={() => reinviteUser(user?.email, user?.role.name)}
                          >
                            Reenviar convite
                          </MenuItem>
                        </>
                      ) : (
                        <>
                          <MenuItem
                            color="#20212380"
                            fontWeight={500}
                            onClick={() => loginAs(user?.id)}
                          >
                            Acessar como usuário
                          </MenuItem>
                          <MenuItem
                            color="#BB2124"
                            fontWeight={500}
                            onClick={() => deleteUser(user?.id)}
                          >
                            Excluir
                          </MenuItem>
                        </>
                      )}
                    </MenuList>
                  </Menu>
                </Stack>
              </Stack>

              <Stack spacing="8px">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  fontSize="14px"
                  color="#20212380"
                >
                  <Text fontWeight={600}>Tipo de usuário:</Text>
                  <Text fontWeight={400}>{user?.role?.name}</Text>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  fontSize="14px"
                  color="#20212380"
                >
                  <Text fontWeight={600}>Último acesso:</Text>
                  <Text fontWeight={400}>{dayjs(user?.lastAccess).format('DD/MM/YYYY')}</Text>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  fontSize="14px"
                  color="#20212380"
                >
                  <Text fontWeight={600}>Status:</Text>
                  <Text fontWeight={400} color={statusBadge.color}>
                    {statusBadge.text}
                  </Text>
                </Stack>
              </Stack>
            </Card>
          );
        })}
    </>
  );
}
