import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import backgroundImage from '../../../../../../assets/images/bg/bg-profile.png';

interface ILayoutProps {
  children: ReactNode;
  hidden: boolean;
  coverBanner: string;
}

export default function HeaderDesktopContainer({ children, hidden, coverBanner }: ILayoutProps) {
  return (
    <Box
      hidden={hidden}
      marginTop={{ base: '70px', md: '100px', lg: '100px' }}
      backgroundImage={coverBanner ? `url("${coverBanner}")` : backgroundImage}
      position="relative"
      width="full"
      height="272px"
      backgroundPosition="75% 85%"
      backgroundSize="cover"
    >
      <Box
        content=""
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        backgroundColor={coverBanner ? 'transparent' : 'rgba(32, 33, 35, 0.5)'}
      />

      {children}
    </Box>
  );
}
