import { Avatar, Box, HStack, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
interface IAuthorCardProps {
  userName: string;
  avatar: string;
  date: string;
}

function AuthorCard({ userName, avatar, date }: IAuthorCardProps) {
  return (
    <>
      <VStack
        bg="#FFFFFF"
        borderRadius={15}
        boxShadow="0px 4px 4px 0px #00000040"
        align="start"
        padding={4}
        minWidth="15.625rem"
      >
        <HStack spacing={3}>
          <Avatar
            size="lg"
            boxSize="4.5rem"
            src={avatar}
            name={userName}
            bgColor="secondary.500"
            color="primary.500"
            fontWeight="bold"
          />

          <Box>
            <Text fontSize="lg" color="black" fontWeight="semibold">
              {userName}
            </Text>
            <Text fontSize="md" color="orange.400" fontWeight="normal">
              Instrutor(a)
            </Text>
          </Box>
        </HStack>

        <Text fontSize="md">Postado em: {dayjs(date).format('DD/MM hh:mm')}</Text>
      </VStack>
    </>
  );
}

export default AuthorCard;
