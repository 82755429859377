import { useEffect, useState } from 'react';

import Carousel from 'react-slick';
import { Heading } from '../../../../components/Heading';
import Testimonials from '../../Testimonials';
import { settingsTestimonials } from '../../Arrows/slickSettings';
import { sortByOrder } from '../../../../helpers/SortHelper';
import styles from './styles.module.css';
import { useTheme } from '../../../../contexts/ThemeContext';
import { Box } from '@chakra-ui/react';

function TestimonialsSection({ section }) {
  const { themeMarketplace, isLoadingThemeMarketplace } = useTheme();

  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    if (!isLoadingThemeMarketplace) {
      setTestimonials(section);
    }
  }, [themeMarketplace, isLoadingThemeMarketplace, section, testimonials]);

  if (!testimonials.active) return '';

  return (
    <>
      {testimonials.active && (
        <Box
          as="section"
          padding={{ base: '24px 15px', md: '40px 107px', lg: '40px 107px' }}
          className={styles.testimonialsSection}
          style={{ background: `${themeMarketplace.primaryColor}` }}
        >
          <Heading
            fontSize={{ base: '24px', md: '32px', lg: '32px' }}
            fontWeight={{ base: 500, md: 600, lg: 600 }}
            color={`${themeMarketplace.textColor}`}
          >
            {testimonials.nameSection}
          </Heading>

          <div className={styles.cardCarousel}>
            <Carousel {...settingsTestimonials}>
              {testimonials?.themeTestimonials
                .sort(sortByOrder)
                .slice(0, 15)
                .map(testimony => (
                  <Testimonials
                    key={testimony.id}
                    testimonials={testimony}
                    color={themeMarketplace.primaryColor}
                  />
                ))}
            </Carousel>
          </div>
        </Box>
      )}
    </>
  );
}

export default TestimonialsSection;
