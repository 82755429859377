import { Box, Heading, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ITask } from '..';
import { IContent } from '../../types';

interface IStatusProps {
  task: ITask;
  content: IContent;
}

function Status({ task, content }: IStatusProps) {
  if (!task?.fileTest) {
    return (
      <Text color="#FFF" textAlign="right" fontSize={{ base: '12px', lg: '14px' }} fontWeight="400">
        Enviar até: {content?.deliveryDate ? dayjs(content.deliveryDate).format('DD/MM/YYYY') : ''}
      </Text>
    );
  }

  if (task?.fileTest) {
    if (task?.fileTestCorrection || task?.testGrade) {
      return (
        <Box display="flex" alignItems="center" hidden={!task?.testGrade}>
          <Heading
            color="#FFF"
            textAlign="right"
            fontFamily="Montserrat"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
          >
            Nota: {task?.testGrade}
          </Heading>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius="16px"
        background="#EDCA32"
        width={{ base: '118.269px', md: '205px' }}
        padding="2px 5px"
      >
        <Text
          color="#FFF"
          textAlign="center"
          fontSize={{ base: '9.231px', lg: '16px' }}
          fontWeight="500"
        >
          Aguardando correção
        </Text>
      </Box>
    );
  }
}

export default Status;
