import dayjs from 'dayjs';
import { MdEdit as PencilIcon } from 'react-icons/md';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Circle,
  HStack,
  Heading,
  Icon,
  List,
  ListItem,
  Stack,
  Text,
  useTheme,
  Button,
  Center,
} from '@chakra-ui/react';

interface UpdateItem {
  id: number;
  configType: string;
  name: string;
  email: string;
  createdAt: string;
}

interface MonitoringProps {
  items: UpdateItem[];
  total: number;
  onLoadMore?: () => void;
}

export default function Monitoring({ items, total, onLoadMore }: MonitoringProps) {
  const { colors } = useTheme();

  const primaryColor = colors.primary[500];
  const listSize = items.length;
  const hasMoreItemsToLoad = listSize < total;

  return (
    <>
      <List px={{ base: 0, xl: 3 }}>
        {items.map((updateItem, index) => (
          <HStack
            key={updateItem.id}
            as={ListItem}
            spacing={2.5}
            align="start"
            borderLeft={index !== listSize - 1 ? `1px solid ${primaryColor}` : 'none'}
            pb={index !== listSize - 1 ? 5 : 0}
          >
            <Circle size="40px" bg="primary.500" color="secondary.500" ml={-5}>
              <Icon as={PencilIcon} boxSize="1.125rem" />
            </Circle>

            <Box w="full">
              <Stack direction={{ base: 'column', xl: 'row' }} justify="space-between" spacing={1}>
                <Heading fontSize="md" fontWeight="semibold" m={0}>
                  Configuração Active Campaign Atualizada
                </Heading>

                <Text color="#20212380" fontSize={{ base: 'sm', xl: 'md' }}>
                  {dayjs(updateItem.createdAt).format('DD/MM/YYYY HH:mm')}
                </Text>
              </Stack>

              <Accordion allowToggle border="transparent">
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        p={0}
                        color="#20212380"
                        _hover={{ bg: 'none' }}
                        textDecoration={isExpanded && 'underline'}
                        display={isExpanded ? 'none' : 'flex'}
                        fontSize={{ base: 'sm', xl: 'md' }}
                      >
                        <Text>Mais detalhes</Text>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel p={0} mt={1.5}>
                        <List
                          sx={{ color: '#20212380', strong: { fontWeight: 'semibold' } }}
                          spacing={2.5}
                        >
                          <ListItem>
                            <Text as="strong">ID da atualização: </Text>
                            {updateItem.id}
                          </ListItem>

                          <ListItem>
                            <Text as="strong">Tipo de Configuração: </Text>
                            {updateItem.configType}
                          </ListItem>

                          <ListItem>
                            <Text as="strong">Criado em: </Text>
                            {dayjs(updateItem.createdAt).format('DD/MM/YYYY HH:mm')}
                          </ListItem>

                          <ListItem>
                            <Text as="strong">Usuário: </Text>
                            {updateItem.name}
                          </ListItem>

                          <ListItem>
                            <Text as="strong">Email: </Text>
                            {updateItem.email}
                          </ListItem>

                          <ListItem>
                            <AccordionButton
                              p={0}
                              color="#20212380"
                              _hover={{ bg: 'none' }}
                              textDecoration={isExpanded && 'underline'}
                              fontSize={{ base: 'sm', xl: 'md' }}
                            >
                              <Text>Menos detalhes</Text>
                              <AccordionIcon />
                            </AccordionButton>
                          </ListItem>
                        </List>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </Box>
          </HStack>
        ))}
      </List>

      {hasMoreItemsToLoad && (
        <Center mt={10}>
          <Button
            onClick={onLoadMore}
            size="lg"
            fontSize="xl"
            fontWeight="semibold"
            variant="link"
            textDecoration="underline"
            color="#759AED"
            colorScheme="purple"
          >
            Carregar Mais
          </Button>
        </Center>
      )}
    </>
  );
}
