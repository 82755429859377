import {
  Box,
  Button as ChakraButton,
  FormControl,
  FormLabel,
  Input,
  Skeleton,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';

import { ChangeEvent, FormEvent, Suspense, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthenticationAPI from '../../../../api/Authentication';
import ZipCodeAPI from '../../../../api/ZipCode';
import CountrySelect, { getDDI, getCountryByDDI } from '../../../../components/CountrySelect';
import { TOKEN_LOCAL_STORAGE } from '../../../../helpers/LocalStorageHelper';
import { ISignProps } from '../../types';

function PersonalData({
  productId,
  formData,
  courseSubscriptionPlan,
  inputsRequired,
  ddi,
  handleSubmitPersonalData,
  setFormData,
  handleRestored,
  setDDI,
}: ISignProps) {
  const [isLoadingZipCode, setIsLoadingZipCode] = useState<boolean>(false);
  const [zipCode, setZipCode] = useState(formData?.address?.zipCode);
  const [country, setCountry] = useState('');

  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    setCountry(getCountryByDDI(formData.ddi));
  }, [formData.ddi]);

  function onlyNumber(event: FormEvent<HTMLInputElement>) {
    const { currentTarget } = event;

    currentTarget.value = currentTarget.value.replace(/[^0-9]/g, '');
  }

  async function getZipCode() {
    if (zipCode?.length !== 8) return;

    try {
      setIsLoadingZipCode(true);

      const zipCodeFormatted = zipCode.replace(/[\D+]/g, '');

      setZipCode(zipCodeFormatted);

      const { data: zipCodeData } = await ZipCodeAPI.index(zipCode);

      if (zipCodeData) {
        setFormData({
          ...formData,
          address: {
            ...formData.address,
            state: zipCodeData.state,
            city: zipCodeData.city,
            neighborhood: zipCodeData.neighborhood,
            street: zipCodeData.street,
            zipCode: zipCodeFormatted,
            country: 'br',
          },
        });
      }
    } catch (error) {
      toast({
        title: 'Não foi possível localizar seu endereço...',
        description: 'Por favor, preencha todos os campos manualmente.',
        status: 'info',
        position: 'bottom-right',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingZipCode(false);
    }
  }

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

      const authentication = await AuthenticationAPI.validateToken(token);

      if (!authentication) {
        history.push({
          pathname: `/product/${productId}/subscription-checkout/sign-in`,
          search: window.location.search,
        });
      }
    })();
  }, [courseSubscriptionPlan, history, productId]);

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    const values = {
      ...formData,
      [name]: value,
    };

    setFormData(values);

    handleRestored();
  }

  function handleDocumentChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const value = event.target.value;

    const newDocument = {
      ...formData,
      document: {
        number: value,
      },
    };

    setFormData(newDocument);

    handleRestored();
  }

  const handleCountryChange = useCallback(
    (country: string) => {
      setDDI(getDDI(country));
      setCountry(country);

      setFormData(prevState => ({
        ...prevState,
        address: { ...prevState.address, country: country },
      }));
    },
    [setDDI, setFormData]
  );

  function handleAddressChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = event.target;

    if (name === 'zipCode') {
      const zipCodeFormatted = value.replace(/[\D+]/g, '');
      setZipCode(zipCodeFormatted);
    }

    const newAddress = {
      ...formData,
      address: {
        ...formData?.address,
        [name]: value,
      },
    };

    setFormData(newAddress);

    handleRestored();
  }

  return (
    <Stack direction="column" spacing={5} alignItems="center" as="form">
      <Stack direction="column" width="full">
        <Text fontWeight="semibold">Nome</Text>
        <Input
          id="fullName"
          type="text"
          name="fullName"
          focusBorderColor="orange.400"
          errorBorderColor="red.500"
          placeholder="Digite seu nome completo"
          onChange={handleChange}
          value={formData?.fullName}
          isInvalid={inputsRequired?.fullName?.isInvalid}
        />
      </Stack>

      <Stack direction="row" width="full" alignItems="center">
        <Stack direction="column" width="full">
          <Text fontWeight="semibold">Email</Text>
          <Input
            id="email"
            type="email"
            name="email"
            focusBorderColor="orange.400"
            errorBorderColor="red.500"
            placeholder="Digite seu e-mail"
            onChange={handleChange}
            value={formData?.email}
            isInvalid={inputsRequired?.email?.isInvalid}
          />
        </Stack>

        <Stack direction="column" width="120px" hidden={ddi !== '55' || country !== 'br'}>
          <Text fontWeight="semibold">DDD</Text>
          <Input
            id="ddd"
            type="tel"
            name="ddd"
            placeholder="DDD"
            onChange={handleChange}
            focusBorderColor="orange.400"
            errorBorderColor="red.500"
            value={formData?.ddd}
            isInvalid={inputsRequired?.ddd?.isInvalid}
            maxLength={2}
            onInput={onlyNumber}
          />
        </Stack>

        <Stack direction="column" width="full">
          <Text fontWeight="semibold">Telefone</Text>
          <Input
            id="phone"
            type="tel"
            name="phone"
            placeholder="Digite seu telefone"
            onChange={handleChange}
            focusBorderColor="orange.400"
            errorBorderColor="red.500"
            value={formData?.phone}
            isInvalid={inputsRequired?.phone?.isInvalid}
            onInput={onlyNumber}
            maxLength={20}
          />
        </Stack>
      </Stack>

      <Stack direction="row" width="full" alignItems="center">
        <FormControl>
          <FormLabel htmlFor="country">País</FormLabel>
          <Suspense fallback={<Skeleton w="full" h={10} />}>
            <CountrySelect value={country} onChange={handleCountryChange} />
          </Suspense>
        </FormControl>

        <Stack direction="column" width="full" hidden={country === 'br'}>
          <Text fontWeight="semibold">Documento</Text>
          <Input
            id="documentNumber"
            type="text"
            name="documentNumber"
            focusBorderColor="orange.400"
            errorBorderColor="red.500"
            minLength={1}
            maxLength={14}
            placeholder="Documento"
            onKeyPress={event => !/[0-9]/.test(event.key) && event.preventDefault()}
            onChange={handleDocumentChange}
            value={formData?.document?.number}
            isInvalid={inputsRequired?.document?.number?.isInvalid}
          />
        </Stack>

        <Box hidden={country !== 'br'} width="full">
          <Stack direction="column" width="full">
            <Text fontWeight="semibold">CFP/CNPJ</Text>
            <Input
              id="documentNumber"
              type="text"
              name="documentNumber"
              focusBorderColor="orange.400"
              errorBorderColor="red.500"
              minLength={1}
              maxLength={14}
              placeholder="Digite seu CPF/CNPJ"
              onKeyPress={event => !/[0-9]/.test(event.key) && event.preventDefault()}
              onChange={handleDocumentChange}
              value={formData?.document?.number}
              isInvalid={inputsRequired?.document?.number?.isInvalid}
            />
          </Stack>
        </Box>

        <Stack direction="column" width="full" hidden={country !== 'br'}>
          <Text fontWeight="semibold">CEP</Text>
          <Input
            id="zipCode"
            type="text"
            name="zipCode"
            focusBorderColor="orange.400"
            errorBorderColor="red.500"
            onBlur={async () => await getZipCode()}
            placeholder="Digite aqui seu CEP"
            value={formData?.address?.zipCode}
            onInput={onlyNumber}
            onChange={handleAddressChange}
            isDisabled={isLoadingZipCode}
            isInvalid={inputsRequired?.address?.zipCode?.isInvalid}
          />
        </Stack>
      </Stack>

      <Stack direction="column" width="full" hidden={country === 'br'}>
        <Text fontWeight="semibold">Endereço</Text>
        <Input
          id="alternateAddress"
          name="alternateAddress"
          type="text"
          focusBorderColor="orange.400"
          errorBorderColor="red.500"
          placeholder="Digite aqui o seu endereço principal"
          value={formData?.address?.alternateAddress}
          onChange={handleAddressChange}
          isInvalid={inputsRequired.address.alternateAddress.isInvalid}
        />
      </Stack>

      <Box hidden={country !== 'br'} width="full">
        <Stack direction="row" width="full" alignItems="center">
          <Stack direction="column" width="full">
            <Text fontWeight="semibold">Rua</Text>
            <Input
              id="street"
              type="text"
              name="street"
              focusBorderColor="orange.400"
              errorBorderColor="red.500"
              placeholder="Qual sua rua?"
              onChange={handleAddressChange}
              value={formData?.address?.street}
              isInvalid={inputsRequired.address.street.isInvalid}
            />
          </Stack>

          <Stack direction="column" width="full">
            <Text fontWeight="semibold">Número</Text>
            <Input
              id="streetNumber"
              type="text"
              focusBorderColor="orange.400"
              errorBorderColor="red.500"
              name="streetNumber"
              placeholder="Número"
              onChange={handleAddressChange}
              value={formData?.address?.streetNumber}
              isInvalid={inputsRequired.address.streetNumber.isInvalid}
            />
          </Stack>

          <Stack direction="column" width="full">
            <Text fontWeight="semibold">Complemento</Text>
            <Input
              id="complementary"
              type="text"
              name="complementary"
              focusBorderColor="orange.400"
              errorBorderColor="red.500"
              placeholder="Ex: Casa"
              onChange={handleAddressChange}
              value={formData?.address?.complementary}
            />
          </Stack>
        </Stack>
        <Stack direction="row" width="full" alignItems="center">
          <Stack direction="column" width="full">
            <Text fontWeight="semibold">Cidade</Text>
            <Input
              id="city"
              name="city"
              type="text"
              focusBorderColor="orange.400"
              errorBorderColor="red.500"
              placeholder="Sua Cidade"
              onChange={handleAddressChange}
              value={formData?.address?.city}
              isInvalid={inputsRequired.address.city.isInvalid}
            />
          </Stack>

          <Stack direction="column" width="full">
            <Text fontWeight="semibold">Estado</Text>
            <Input
              id="state"
              name="state"
              type="text"
              focusBorderColor="orange.400"
              errorBorderColor="red.500"
              placeholder="Seu Estado"
              onChange={handleAddressChange}
              value={formData?.address?.state}
              isInvalid={inputsRequired.address.state.isInvalid}
            />
          </Stack>

          <Stack direction="column" width="full">
            <Text fontWeight="semibold">Bairro</Text>
            <Input
              id="neighborhood"
              type="text"
              name="neighborhood"
              focusBorderColor="orange.400"
              errorBorderColor="red.500"
              placeholder="Qual o bairro?"
              onChange={handleAddressChange}
              value={formData?.address?.neighborhood}
              isInvalid={inputsRequired.address.neighborhood.isInvalid}
            />
          </Stack>
        </Stack>
      </Box>

      <Stack my={5} width="full" direction="row" display="flex" justifyContent="flex-end">
        <ChakraButton
          type="button"
          onClick={handleSubmitPersonalData}
          colorScheme="orange"
          variant="solid"
          style={{ outline: 'none', boxShadow: 'none' }}
        >
          Próximo Passo
        </ChakraButton>
      </Stack>
    </Stack>
  );
}

export default PersonalData;
