import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  TabPanel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import Pagination from '../../../../../components/Pagination';
import useFetch from '../../../../../hooks/useFetch';
import useHandleSubmit from '../../../../../hooks/useHandleSubmit';
import { CardSubject } from '../components/CardSubject';
import { ModalAddOrEditSubject } from '../components/ModalAddOrEditSubject';
import { ModalDeleteSubject } from '../components/ModalDeleteSubject';
import { subjectSchemaValidator } from '../schemaValidator';

export interface IQuestionLibrarySubjectData {
  area: {
    id: number;
    name: string;
  };
  discipline: {
    id: number;
    name: string;
  };
  id: number;
  name: string;
}

export interface IQuestionLibrarySubjectResult {
  data: IQuestionLibrarySubjectData[];
  total: number;
  page: number;
  per_page: number;
}

interface IProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  tabIndex: number;
}

export function TabPanelSubject({ isOpen, onOpen, onClose, tabIndex }: IProps) {
  const {
    isOpen: isOpenModalDeleteSubject,
    onOpen: onOpenModalDeleteSubject,
    onClose: onCloseModalDeleteSubject,
  } = useDisclosure();

  const [searchFiltered, setSearchFiltered] = useState<string>('');
  const [subjectData, setSubjectData] = useState<IQuestionLibrarySubjectData[]>();
  const [subjectDataFiltered, setSubjectDataFiltered] = useState<IQuestionLibrarySubjectData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [disciplineId, setDisciplineId] = useState<number>(0);
  const [subjectId, setSubjectId] = useState<number>(0);
  const [subjectName, setSubjectName] = useState<string>('');

  const [pageCount, setPageCount] = useState(0);
  const [timer, setTimer] = useState<number>(0);
  const [search, setSearch] = useState<string>('');

  const {
    data: getSubjectData,
    loading: isLoading,
    fetchData: fetchQuestionLibrarySubject,
  } = useFetch<UnificadaFront.ResponseJSON<IQuestionLibrarySubjectResult>>({
    method: 'get',
    url: `/filter/question-library-subject?page=${currentPage}&perPage=${12}${
      search ? `&search=${search}` : ''
    }`,
    authenticated: true,
    autoFetch: false,
  });

  const {
    data: getDisciplineData,
    loading: isLoadingDisciplineData,
    fetchData: fetchDataGetDiscipline,
  } = useFetch<UnificadaFront.ResponseJSON<any>>({
    method: 'get',
    url: `/filter/question-library-discipline?hasPaginate=false`,
    authenticated: true,
    autoFetch: false,
  });

  useEffect(() => {
    if (tabIndex === 2) {
      fetchQuestionLibrarySubject();
      fetchDataGetDiscipline();
    }
  }, [fetchDataGetDiscipline, fetchQuestionLibrarySubject, tabIndex]);

  const {
    isLoading: isSubmitting,
    formValidation,
    handleSubmit: handleSubmitSubject,
  } = useHandleSubmit({
    data: {
      name: subjectName,
      disciplineId,
    },
    url: '/filter/question-library-subject',
    method: 'post',
    schemaValidator: subjectSchemaValidator,
    authenticated: true,
    onSuccess: {
      message: 'Assunto criado com sucesso!',
      callback: () => {
        setDisciplineId(0);
        setSubjectId(0);
        setSubjectName('');
        fetchQuestionLibrarySubject();
      },
    },
  });

  const {
    isLoading: isEditingSubject,
    formValidation: formValidationEditSubject,
    handleSubmit: handleSubmitEditSubject,
  } = useHandleSubmit({
    data: {
      name: subjectName,
      disciplineId,
    },
    url: `/filter/${subjectId}/question-library-subject`,
    method: 'patch',
    authenticated: true,
    schemaValidator: subjectSchemaValidator,
    onSuccess: {
      message: 'Assunto atualizado com sucesso!',
      callback: () => {
        setDisciplineId(0);
        setSubjectId(0);
        setSubjectName('');
        fetchQuestionLibrarySubject();
      },
    },
  });

  const { isLoading: isDeleting, handleSubmit: handleSubmitDeleteSubject } = useHandleSubmit({
    url: `/filter/${subjectId}/question-library-subject`,
    method: 'delete',
    authenticated: true,
    onSuccess: {
      message: 'Assunto excluído com sucesso!',
      callback: () => {
        setSubjectDataFiltered(subjectDataFiltered?.filter(subject => subject.id !== subjectId));
        setDisciplineId(0);
        setSubjectId(0);
        setSubjectName('');
      },
    },
  });

  async function onSubmit<T>(event: FormEvent<T>) {
    event.preventDefault();

    let result: any;

    if (subjectId) {
      result = await handleSubmitEditSubject();
    } else {
      result = await handleSubmitSubject();
    }

    if (result) {
      onClose();
    }
  }

  useEffect(() => {
    if (getSubjectData?.data) {
      setSubjectData(getSubjectData?.data.data);
      setSubjectDataFiltered(getSubjectData?.data.data);
      setPageCount(getSubjectData?.data?.total / getSubjectData?.data?.per_page);
    } else {
      setSubjectDataFiltered([]);
    }
  }, [getSubjectData?.data]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (timer < 1) {
        setSearch(searchFiltered);

        return () => clearTimeout(timeout);
      }

      setTimer(prevState => (prevState < 0 ? 0 : --prevState));
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchFiltered, setSearch, timer]);

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setSearchFiltered(value);
    setTimer(2);
  }

  function handlePageChange({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  return (
    <TabPanel padding={0}>
      <Text
        fontWeight={400}
        fontSize="14px"
        color="#20212380"
        marginY={5}
        hidden={subjectData?.length > 0}
      >
        Cadastre os assuntos de acordo com sua área de atuação, isso facilitará a busca por seus
        produtos, na criação de questões no banco, entre outros benefícios.
      </Text>

      <InputGroup size="sm" marginY={5} hidden={!subjectData?.length}>
        <InputLeftElement pointerEvents="none">
          <BiSearch color="gray" size={15} />
        </InputLeftElement>
        <Input
          width={{ base: 'full', md: '320px', lg: '320px' }}
          placeholder="Buscar por título"
          borderRadius={6}
          focusBorderColor="orange.500"
          color="#20212380"
          value={searchFiltered}
          onChange={handleChangeSearch}
        />
      </InputGroup>

      <Stack direction="row" width="full" justifyContent="center" margin={10} hidden={!isLoading}>
        <Spinner color="orange.500" size="lg" />
      </Stack>

      {!subjectDataFiltered?.length && (
        <Heading
          fontWeight={400}
          padding={10}
          fontSize="lg"
          color="#20212380"
          marginY={5}
          textAlign="center"
          hidden={!subjectData?.length}
        >
          Nenhum resultado encontrado para "{search}"
        </Heading>
      )}

      <Box display="flex" flexDirection="row" flexWrap="wrap" flex={1} gap={5}>
        {subjectDataFiltered?.map(subject => (
          <CardSubject
            key={subject.id}
            subject={subject}
            onOpenModalAddOrEditSubject={onOpen}
            onOpenModalDeleteSubject={onOpenModalDeleteSubject}
            setDisciplineId={setDisciplineId}
            setSubjectId={setSubjectId}
            setSubjectName={setSubjectName}
          />
        ))}
      </Box>

      {subjectDataFiltered?.length !== 0 && getSubjectData?.data?.total > 12 && (
        <Stack w="100%" justifyContent="center" alignItems="center" my={10}>
          <Pagination
            pageCount={pageCount}
            onPageChange={handlePageChange}
            onPageActive={currentPage - 1}
          />
        </Stack>
      )}

      <ModalAddOrEditSubject
        isOpen={isOpen}
        onClose={onClose}
        disciplineId={disciplineId}
        setDisciplineId={setDisciplineId}
        id={subjectId}
        subjectName={subjectName}
        setSubjectName={setSubjectName}
        isSubmitting={isSubmitting || isEditingSubject}
        onSubmit={onSubmit}
        formValidation={formValidation || formValidationEditSubject}
        disciplineData={getDisciplineData?.data?.data}
        isLoading={isLoadingDisciplineData}
      />

      <ModalDeleteSubject
        key={subjectId}
        isDeleting={isDeleting}
        handleSubmit={handleSubmitDeleteSubject}
        subjectName={subjectName}
        isOpen={isOpenModalDeleteSubject}
        onClose={onCloseModalDeleteSubject}
      />
    </TabPanel>
  );
}
